import React, { Component } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { SelectBox } from 'devextreme-react/select-box';
import { MasterHistoryComponent } from '../Shared/MasterHistoryComponent';
import { DocumentsComponent } from '../Shared/DocumentsComponent';
import { CommissionSettingComponent } from './CommissionSettingComponent';
import { VehicleOwnerListComponent } from './VehicleOwnerListComponent';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import QRCode from 'qrcode.react'; //npm i qrcode.react
import { LoadPanel } from 'devextreme-react/load-panel';
import { VehicleDeviceMappingComponent } from './VehicleDeviceMappingComponent';
import { VehicleDocumentComponent } from '../Reimbursment/VehicleDocumentComponent';

const customStyles = {
    content: {
        width: '60%',
    }
};
const qrCodeCustomStyles = {
    content: {
        width: '40%',
    }
};
let currentYear = (new Date()).getFullYear();
let maxYear = 10;
let minYear = -10
let allYears = [];
for (let i = minYear; i <= maxYear; i++) {
    allYears.push(currentYear - i)
}

let vehicleMakeYearList = allYears.map((y) => { return (<option key={y} value={y}>{y}</option>) });
const sessionUserName = sessionStorage.getItem("session_userName");
const staticUserName = "praveen";

export class VehicleListComponent extends Component {
    constructor() {
        super();
        this.state = {
            isGotoVehicleOwnerComponent: false,
            isSubmited: false,
            vehicleModalIsOpen: false,
            qrCodeModalIsOpen: false,
            loadPanelVisible: false,
            vehicleActivatePayrollModelIsOpen: false,
            vehicleGridDataSource: null,
            vehicleData: null,
            dropdownVehicleType: [],
            dropdownOwnerType: [],
            dropdownTrackingSource: [],
            dropdownTrackingVehicle: [],
            vehicleId: 0,
            vehicleNumber: '',
            vehicleTypeId: 0,
            vehicleType: '',
            model: '',
            vinNo: '',
            makeYear: currentYear.toString(),
            ownerTypeId: 0,
            ownerType: '',
            plateNo: '',
            permitNo: '',
            inService: true,
            serviceNote: '',
            isPrevent: false,
            preventNote: '',
            trackingSourceId: 0,
            trackingSource: '',
            trackingVehicleNumber: '',
            trackingVehicleName: '',
            trackingVehicleId :0,
            seat: 0,
            ownerId: 0,
            ownerName: '',
            isPayrollActive: false,
            doNotDeleteVehicle: sessionStorage.getItem("session_doNotDeleteVehicle") === "true" ? true : false,
            vehicleActivatePayroll: {
                note: '',
                password: '',
                username: '',
                usernameRequired: false,
                errors: {
                    note: '',
                    password: '',
                    username: ''
                }
            },
            errors: {
                vehicleNumber: '',
                vehicleType: '',
                model: '',
                vinNo: '',
                makeYear: '',
                ownerType: '',
                plateNo: '',
                permitNo: '',
                serviceNote: '',
                preventNote: '',
                trackingSource: '',
                trackingVehicleNumber: '',
                seat: ''
            }
        }

        this.handleQRCodeOpenState = this.handleQRCodeOpenState.bind(this);
        this.handleDownloadVehicleQRCode = this.handleDownloadVehicleQRCode.bind(this);
        this.handlVehicleTypeChange = this.handlVehicleTypeChange.bind(this);
        this.handleDeleteVehicle = this.handleDeleteVehicle.bind(this);
        this.openVehicleDeviceModel = this.openVehicleDeviceModel.bind(this);
        this.handleVehicleDocumentModal = this.handleVehicleDocumentModal.bind(this);
    }

    componentDidMount() {
        this.fetchApiData();
    }

    getVehicleList = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            var request = new Object();
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('vehicle-api/list', requestParams);
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    vehicleGridDataSource: data.vehicles
                });
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    };
    getVehicleTypeDropdown = async () => {
        var request = new Object();
        try {
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('vehicletype-api/vehicletype', requestParams);
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    dropdownVehicleType: data.apiVehicleType
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    getOwnerTypeDropdown = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('owner-type-api/dropdown-list', requestParams);
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    dropdownOwnerType: data.ownerType
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    getTrackingSourceDropdown = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('tracking-source/dropdown-list', requestParams);
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    dropdownTrackingSource: data.trackingSource
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    getTrackingVehicleDropdown = async (trackingSourceId) => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('fleetmatics-vehicles-last-location/dropdown-list/' + trackingSourceId, requestParams);
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    dropdownTrackingVehicle: data.fleetmaticsVehiclesLastLocation
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    fetchApiData = async () => {
        try {
            await this.getVehicleList();
            this.setState({ loadPanelVisible: true });
            await this.getVehicleTypeDropdown();
            await this.getOwnerTypeDropdown();
            await this.getTrackingSourceDropdown();
           // await this.getTrackingVehicleDropdown();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getVehicleList.bind(this)
            }
        });
    }
    handlVehicleTypeChange(selectedOption) {        
        let errors = this.state.errors;
        this.setState({
            vehicleTypeId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.rowIndex : 0),
            seat: (selectedOption.selectedItem != null ? selectedOption.selectedItem.apiPaxNumber : 0),
        })
        if (selectedOption != null && selectedOption.selectedItem != null) {            
            errors.vehicleType = "";
            this.setState({ errors: errors });
        }
    }

    handleSourceVehicleChanges = (selectedOption) => {
        let errors = this.state.errors;
        this.setState({
            trackingVehicleId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.vehicleId : 0),
            trackingVehicleNumber: (selectedOption.selectedItem != null ? selectedOption.selectedItem.vehicleName : ''),
        })
        if (selectedOption != null && selectedOption.selectedItem != null) {
            errors.trackingVehicleNumber = "";
            this.setState({ errors: errors });
        }
    }
    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let errors = this.state.errors;
        switch (name) {
            case 'vehicleNumber':
                errors.vehicleNumber = "";
                if (this.vehicleNumberInputValidator.value.trim().length === 0) {
                    errors.vehicleNumber = "Please enter vehicle number.";
                }
                this.setState({ vehicleNumber: value });
                break;
            //case 'vehicleType':
            //    if (Number(this.vehicleTypeSelectValidator.value) > 0) {
            //        errors.vehicleType = '';
            //    }
            //    else {
            //        errors.vehicleType = 'Please select vehicle type.';
            //    }
            //    const apiPax = this.state.dropdownVehicleType.filter(item => item.rowIndex === Number(event.target.value));
            //    let apiVehicleTypePaxNumber = 0;
            //    if (apiPax.length > 0) {
            //        apiVehicleTypePaxNumber = apiPax[0].apiPaxNumber;
            //    }
            //    this.setState({
            //        vehicleTypeId: value,
            //        vehicleType: event.target.options[event.target.selectedIndex].text,
            //        seat: apiVehicleTypePaxNumber
            //    });
            //    break;
            case 'model':
                errors.model = "";
                if (this.modelInputValidator.value.trim() !== '' && this.modelInputValidator.value.trim().length < 3) {
                    errors.model = "Please enter model minimum length is 3 characters.";
                }
                this.setState({ model: value });
                break;
            case 'plateNo':
                errors.plateNo = "";
                if (this.plateNoInputValidator.value.trim() !== '' && this.plateNoInputValidator.value.trim().length < 4) {
                    errors.plateNo = "Please enter plate number minimum length is 4 characters.";
                }
                this.setState({ plateNo: value });
                break;
            case 'permitNo':
                errors.permitNo = "";
                if (this.permitNoInputValidator.value.trim() !== '' && this.permitNoInputValidator.value.trim().length < 4) {
                    errors.permitNo = "Please enter permit number minimum length is 4 characters.";
                }
                this.setState({ permitNo: value });
                break;
            case 'seat':
                errors.seat = "";
                const re = /^[0-9\b]+$/;
                if (value.trim() === '' || re.test(value)) {
                    if (Number(this.seatInputValidator.value) > 0) {
                        const apiPax = this.state.dropdownVehicleType.filter(item => item.rowIndex === Number(this.state.vehicleTypeId));
                        if (apiPax.length > 0) {
                            if (Number(this.seatInputValidator.value) > Number(apiPax[0].apiPaxNumber)) {
                                errors.seat = "Please enter number of seat less than or equal to " + apiPax[0].apiPaxNumber + " for " + this.state.vehicleType + "!";
                            }
                        }
                    }
                    this.setState({ seat: value });
                }
                break;
            case 'inService':
                errors.serviceNote = "";
                if (Number(this.state.vehicleId) > 0) {
                    if (!value) {
                        if (this.state.serviceNote.trim() === '') {
                            errors.serviceNote = "Please enter service note.";
                            this.serviceNoteInputValidator.focus();
                        }
                    }
                }
                this.setState({ inService: value });
                break;
            case 'isPrevent':
                errors.preventNote = "";
                if (Number(this.state.vehicleId) > 0) {
                    if (value) {
                        if (this.state.preventNote.trim() === '') {
                            errors.preventNote = "Please enter prevent reason.";
                            this.preventNoteInputValidator.focus();
                        }
                    }
                }
                this.setState({ isPrevent: value });
                break;
            case 'serviceNote':
                errors.serviceNote = "";
                if (this.serviceNoteInputValidator.value.trim() === '' && this.state.inService === false) {
                    errors.serviceNote = "Please enter service note.";
                }
                this.setState({ serviceNote: value });
                break;
            case 'preventNote':
                errors.preventNote = "";
                if (this.preventNoteInputValidator.value.trim() === '' && this.state.isPrevent === true) {
                    errors.preventNote = "Please enter prevent reason.";
                }
                this.setState({ preventNote: value });
                break;
            case 'vinNo':
                errors.vinNo = "";
                if (this.vinNoInputValidator.value.trim() !== '' && this.vinNoInputValidator.value.trim().length < 4) {
                    errors.vinNo = "Please enter vin number minimum lenght is 4 characters.";
                }
                this.setState({ vinNo: value });
                break;
                ;
            case 'trackingSource':
                //errors.trackingVehicleNumber = '';
                //if (Number(value) !== 1001 && Number(value) == 1005 && Number(value) !== 0) {
                //    if (this.state.trackingVehicleNumber.trim() === '') {
                //        errors.trackingVehicleNumber = "Please select tracking vehicle.";
                //        this.trackingVehicleSelectValidator.focus()
                //    }
                //}
                const trackingVehicleNumber = (Number(value) === 0 || Number(value) === 1001) ? '' : this.state.trackingVehicleNumber
                this.setState({
                    trackingSourceId: value,
                    trackingSource: event.target.options[event.target.selectedIndex].text,
                    trackingVehicleNumber: trackingVehicleNumber
                });
                this.getTrackingVehicleDropdown(value);
                break;
            case 'trackingVehicle':
                ;
                //errors.trackingVehicleNumber = '';
                //if (Number(this.state.trackingSourceId) !== 1001 && Number(this.state.trackingSourceId) !== 1005 && Number(this.state.trackingSourceId) !== 0) {
                //    if (this.trackingVehicleSelectValidator.value.trim() === '') {
                //        errors.trackingVehicleNumber = "Please select tracking vehicle.";
                //    }
                //}
                //;
                this.setState({
                    trackingVehicleNumber: value,
                    trackingVehicleName: event.target.options[event.target.selectedIndex].text,
                });
                break;
            default:
                this.setState({
                    [name]: value
                });
        }
        this.setState({ errors: errors });
    }
    handleAddVehicle = (e) => {
        e.preventDefault();
        this.setState({
            vehicleModalIsOpen: true,
            vehicleData: null,
        });
    }
    handleEditVehicle(e, data) {
        e.preventDefault();
        if (data != null) {
            this.setState({
                vehicleModalIsOpen: true,
                vehicleData: data,
                trackingSourceId: data.trackingSourceId === null ? 0 : Number(data.trackingSourceId)
            });
            this.getTrackingVehicleDropdown(data.trackingSourceId)
        }
    }
    handleGenerateQRCode(e, data) {
        e.preventDefault();
        this.setState({
            qrCodeModalIsOpen: true,
            qrCodeVehicleNumber: data.vehicleNo,
        });
    }
    handleSetVehicleCommissionSetting(e, data) {
        e.preventDefault();
        this.refs.vehicleCommSettingComponent.handleShowModal("Vehicle Commission Setting", 'VC', data.vehicleOwnerName === null ? '' : data.vehicleOwnerName.trim(), 0, data.id === null ? 0 : Number(data.id), data.vehicleNo === null ? '' : data.vehicleNo.trim());
    }
    handleViewSetVehicleOwner(e, data) {
        e.preventDefault();
        this.setState({
            isGotoVehicleOwnerComponent: true,
            vehicleId: data.id === null ? 0 : Number(data.id),
            vehicleNumber: data.vehicleNo === null ? '' : data.vehicleNo.trim(),
            isPayrollActive: data.isPayrollActivated || false
        });
        //this.refs.vehicleOwnerListComponent.handleShowModal(data.vehicleId, data.vehicleNumber);
    }
    handleActiveVehiclePayroll(e, data) {
        e.preventDefault();
        this.setState({
            vehicleActivatePayrollModelIsOpen: true,
            vehicleData: data
        });
    }
    handleMasterHistoryModal(e, data) {
        e.preventDefault();
        this.refs.masterHistoryComponent.showModal('vehicle-api', 'Vehicle', data.vehicleNo === null ? '' : data.vehicleNo.trim(), data.id === null ? 0 : Number(data.id));
    }
    handleVehicleImageModal(e, data) {
        e.preventDefault();
        var vehicleArray = []
        vehicleArray.push(Number(data.id));
        this.refs.documentsComponent.showModal('Vehicle', vehicleArray, data.vehicleNo);
    }
    handleVehicleDocumentModal(e, data) {
        e.preventDefault();
        this.refs.vehicleDocumentComponent.showModal(data);
    }

    handleQRCodeOpenState(e, data) {
        //e.preventDefault();
    }
    handleAssignDataToState = async () => {        
        if (this.state.vehicleData === null) {
            this.setState({
                vehicleId: 0,
                vehicleNumber: '',
                vehicleTypeId: 0,
                vehicleType: '',
                model: '',
                vinNo: '',
                makeYear: currentYear.toString(),
                ownerTypeId: 0,
                ownerType: '',
                plateNo: '',
                permitNo: '',
                inService: true,
                serviceNote: '',
                isPrevent: false,
                preventNote: '',
                trackingSourceId: 0,
                trackingSource: '',
                trackingVehicleId :0,
                trackingVehicleNumber: '',
                trackingVehicleName: '',
                seat: 0,
                ownerId: 0,
                ownerName: '',
                isPayrollActive: false,
            });
        } else {
            const vehicle = this.state.vehicleData;
            this.setState({
                vehicleId: vehicle.id === null ? 0 : Number(vehicle.id),
                vehicleNumber: vehicle.vehicleNo === null ? '' : vehicle.vehicleNo.trim(),
                vehicleTypeId: vehicle.apiVehicleTypeId === null ? 0 : Number(vehicle.apiVehicleTypeId),
                vehicleType: vehicle.apiVehicleTypeDescription === null ? '' : vehicle.apiVehicleTypeDescription.trim(),
                model: vehicle.vehicleModel === null ? '' : vehicle.vehicleModel.trim(),
                vinNo: vehicle.vin === null ? '' : vehicle.vin.trim(),
                makeYear: vehicle.vehicleYear === null ? currentYear.toString() : vehicle.vehicleYear.trim(),
                ownerTypeId: vehicle.ownerTypeId === null ? 0 : Number(vehicle.ownerTypeId),
                ownerType: vehicle.ownerTypeName === null ? '' : vehicle.ownerTypeName.trim(),
                plateNo: vehicle.plateNo === null ? '' : vehicle.plateNo.trim(),
                permitNo: vehicle.permitNumber === null ? '' : vehicle.permitNumber.trim(),
                inService: vehicle.enable || false,
                serviceNote: vehicle.inOutServiceNote === null ? '' : vehicle.inOutServiceNote.trim(),
                isPrevent: vehicle.isPreventAccess || false,
                preventNote: vehicle.preventAccessNote === null ? '' : vehicle.preventAccessNote.trim(),
                trackingSourceId: vehicle.trackingSourceId === null ? 0 : Number(vehicle.trackingSourceId),
                trackingSource: vehicle.trackingSource === null ? '' : vehicle.trackingSource.trim(),
                trackingVehicleNumber: (vehicle.fmVehicleNo !== null && Number(vehicle.trackingSourceId) !== 0 && Number(vehicle.trackingSourceId) !== 1001) ? vehicle.fmVehicleNo.trim() : '',
                trackingVehicleName: (vehicle.fmVehicleName !== null && Number(vehicle.trackingSourceId) !== 0 && Number(vehicle.trackingSourceId) !== 1001) ? vehicle.fmVehicleName.trim() : '',
                 trackingVehicleId: (vehicle.fmVehicleId !== null && Number(vehicle.trackingSourceId) !== 0 && Number(vehicle.trackingSourceId) !== 1001) ? vehicle.fmVehicleId : 0,
                seat: vehicle.vehicleSeats === null ? 0 : Number(vehicle.vehicleSeats),
                ownerId: vehicle.vehicleOwnerId === null ? 0 : Number(vehicle.vehicleOwnerId),
                ownerName: vehicle.vehicleOwnerName === null ? '' : vehicle.vehicleOwnerName.trim(),
                isPayrollActive: vehicle.isPayrollActivated || false,
            });
        }
        if (this.state.vehicleModalIsOpen) {
            this.submitVehicleButtonValidator.focus();
        }
        if (this.state.vehicleActivatePayrollModelIsOpen) {
            this.submitActivatePayrollButtonValidator.focus();
        }
    }
    handleQRCodeCloseState = () => {
        this.setState({
            qrCodeModalIsOpen: false,
        });
    }
    handleResetStateData = async () => {
        this.setState({
            isGotoVehicleOwnerComponent: false,
            isSubmited: false,
            vehicleModalIsOpen: false,
            loadPanelVisible: false,
            vehicleActivatePayrollModelIsOpen: false,
            vehicleData: null,
            vehicleId: 0,
            vehicleNumber: '',
            vehicleTypeId: 0,
            vehicleType: '',
            model: '',
            vinNo: '',
            makeYear: currentYear.toString(),
            ownerTypeId: 0,
            ownerType: '',
            plateNo: '',
            permitNo: '',
            inService: true,
            serviceNote: '',
            isPrevent: false,
            preventNote: '',
            trackingSourceId: 0,
            trackingSource: '',
            trackingVehicleNumber: '',
            trackingVehicleName: '',
            seat: 0,
            ownerId: 0,
            ownerName: '',
            isPayrollActive: false,
            vehicleActivatePayroll: {
                note: '',
                password: '',
                username: '',
                usernameRequired: false,
                errors: {
                    note: '',
                    password: '',
                    username: ''
                }
            },
            errors: {
                vehicleNumber: '',
                vehicleType: '',
                model: '',
                vinNo: '',
                makeYear: '',
                plateNo: '',
                serviceNote: '',
                preventNote: '',
                trackingSource: '',
                trackingVehicleNumber: '',
                permitNo: '',
                ownerType: '',
                seat: ''
            }
        });
    }
    handleDownloadVehicleQRCode(e) {
        e.preventDefault();
        try {
            const canvas = document.getElementById(this.state.qrCodeVehicleNumber);
            const pngUrl = canvas
                .toDataURL("image/png")
                .replace("image/png", "image/octet-stream");
            let downloadLink = document.createElement("a");
            downloadLink.href = pngUrl;
            downloadLink.download = "VehicleQRCode-" + this.state.qrCodeVehicleNumber + ".png";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        } catch (ex) {
            console.log(ex);
        }
    }
    handleVehicleSubmit = async event => {
        event.preventDefault();
        try {
            ;
            if (this.isFormValid()) {
                this.setState({ isSubmited: true });
                var request = new Object();
                request.VehicleId = Number(this.state.vehicleId);
                request.VehicleNo = this.state.vehicleNumber.trim();
                request.ApiVehicleTypeId = Number(this.state.vehicleTypeId);
                request.VehicleModel = this.state.model.trim();
                request.Vin = this.state.vinNo.trim();
                request.VehicleYear = this.state.makeYear.trim();
                request.OwnerTypeId = Number(this.state.ownerTypeId) === 0 ? 1 : Number(this.state.ownerTypeId);
                request.PlateNo = this.state.plateNo.trim();
                request.PermitNumber = this.state.permitNo.trim();
                request.Enable = this.state.inService;
                request.InOutServiceNote = (this.state.inService) ? '' : this.state.serviceNote.trim();
                request.IsPreventAccess = this.state.isPrevent;
                request.PreventAccessNote = (this.state.isPrevent) ? this.state.preventNote.trim() : '';
                request.TrackingSourceId = Number(this.state.trackingSourceId) === 0 ? 1001 : Number(this.state.trackingSourceId);
                request.SourceVehicleNo = (Number(this.state.trackingSourceId) !== 0 && Number(this.state.trackingSourceId) !== 1001) ? this.state.trackingVehicleNumber.trim() : '';
                request.SourceVehicleId = (Number(this.state.trackingSourceId) !== 0 && Number(this.state.trackingSourceId) !== 1001) ? this.state.trackingVehicleId : 0;
                request.VehicleSeats = Number(this.state.seat);
                request.Description = this.setAddEditHistory();
                request.AllowVehicleDocumentVerification = sessionStorage.getItem('session_allowVehicleDocumentVerification')
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('vehicle-api', requestParams);
                const data = await response.json();
                
                if (data.isSuccess) {
                    const mdsvehicleresponse = await fetch('mds-api/' + data.retValue + '/vehicle-register', requestParams);
                    const mdsvehicledata = await mdsvehicleresponse.json();

                    await Swal.fire({
                        allowOutsideClick: false,
                        icon: data.isSuccess ? 'success' : 'error',
                        title: data.isSuccess ? 'Success' : 'Oops...',
                        text: (data.isSuccess === false && (data.resultMessage === null || data.resultMessage === "")) ? 'Unable to process the request.' : data.resultMessage,
                    }).then(async (result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (data.isSuccess) {
                            if (result.isConfirmed) {
                                await this.handleResetStateData();
                                await this.getVehicleList();
                            }
                        }
                    });
                }
            }
        } catch (ex) {
            console.log(ex);
            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Unable to process the request.',
            })
        }
        this.setState({ isSubmited: false })
    }
    async isAllowConfirm() {
        let isResult = false;
        await Swal.fire({
            icon: 'question',
            title: 'Delete Vehicle',
            html: "Are you sure want to delete vehicle?",
            allowOutsideClick: false,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Delete',
            cancelButtonText: 'No',
            allowOutsideClick: false,
        }).then(async (result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        });
        return isResult;
    }
    onfailure(title, resultMessage) {
        Swal.fire({
            allowOutsideClick: false,
            showCloseButton: true,
            icon: 'error',
            title: (title === null || title.trim() === '') ? 'Oops...' : title,
            text: (resultMessage === null || resultMessage.trim() === '') ? 'Something went wrong!' : resultMessage.trim(),
        })
    }
    handleDeleteVehicle = async (e, dataresult) => {
        e.preventDefault();
        try {
            const isConfirm = await this.isAllowConfirm();
            if (!isConfirm) {
                return;
            }
            var deleteVehicleRequest = new Object();
            deleteVehicleRequest.VehicleId = parseInt(dataresult.id);
            const requestParams = getRequestParams('POST', deleteVehicleRequest);
            const response = await fetch("vehicle-api/delete", requestParams);
            const data = await response.json();
            if (data.isSuccess) {
                await Swal.fire({
                    allowOutsideClick: false,
                    icon: data.isSuccess ? 'success' : 'error',
                    title: data.isSuccess ? 'success' : 'Oops...',
                    text: data.resultMessage,
                }).then(async (result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            await this.handleResetStateData();
                            await this.getVehicleList();
                        }
                    }
                });
            } else {
                this.onfailure('Failed to Delete vehicle.', data.resultMessage);
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    isFormValid() {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            let isFocus = false;
            errors.vehicleNumber = "";
            errors.vehicleType = '';
            errors.model = "";
            errors.vinNo = "";
            errors.makeYear = "";
            errors.ownerType = '';
            errors.plateNo = "";
            errors.permitNo = "";
            errors.serviceNote = "";
            errors.preventNote = "";
            errors.trackingSource = '';
            errors.trackingVehicleNumber = '';
            errors.seat = '';
            ;
            if (this.state.vehicleNumber.trim() === '') {
                formIsValid = false;
                errors.vehicleNumber = "Please enter vehicle number.";
                if (isFocus === false) { this.vehicleNumberInputValidator.focus(); isFocus = true; }
            }
            if (Number(this.state.vehicleTypeId) === 0 || this.vehicleTypeSelectValidator.instance.option('selectedItem') === null) {
                formIsValid = false;
                errors.vehicleType = "Please select vehicle type.";
                if (isFocus === false) { this.vehicleTypeSelectValidator.instance.focus(); isFocus = true; }
            }
            if (this.state.model.trim() !== '' && this.state.model.trim().length < 3) {
                formIsValid = false;
                errors.model = "Please enter model minimum length is 3 characters.";
                if (isFocus === false) { this.modelInputValidator.focus(); isFocus = true; }
            }
            if (this.state.plateNo.trim() !== '' && this.state.plateNo.trim().length < 4) {
                formIsValid = false;
                errors.plateNo = "Please enter plate number minimum length is 4 characters.";
                if (isFocus === false) { this.plateNoInputValidator.focus(); isFocus = true; }
            }
            if (this.state.permitNo.trim() !== '' && this.state.permitNo.trim().length < 4) {
                formIsValid = false;
                errors.permitNo = "Please enter permit number minimum length is 4 characters.";
                if (isFocus === false) { this.permitNoInputValidator.focus(); isFocus = true; }
            }
            if (Number(this.state.seat) > 0) {
                const apiPax = this.state.dropdownVehicleType.filter(item => item.rowIndex === Number(this.state.vehicleTypeId));
                if (apiPax.length > 0) {
                    if (Number(this.state.seat) > Number(apiPax[0].apiPaxNumber)) {
                        formIsValid = false;
                        errors.seat = "Please enter number of seat less than or equal to " + apiPax[0].apiPaxNumber + " for " + this.state.vehicleType + "!";
                        if (isFocus === false) { this.seatInputValidator.focus(); isFocus = true; }
                    }
                }
            }
            if (Number(this.state.vehicleId) > 0) {
                if (!this.state.inService) {
                    if (this.state.serviceNote.trim() === '') {
                        formIsValid = false;
                        errors.serviceNote = "Please enter service note.";
                        if (isFocus === false) { this.serviceNoteInputValidator.focus(); isFocus = true; }
                    }
                }
                if (this.state.isPrevent) {
                    if (this.state.preventNote.trim() === '') {
                        formIsValid = false;
                        errors.preventNote = "Please enter prevent reason.";
                        if (isFocus === false) { this.preventNoteInputValidator.focus(); isFocus = true; }
                    }
                }
            }
            if (this.state.vinNo.trim() !== '' && this.state.vinNo.trim().length < 4) {
                formIsValid = false;
                errors.vinNo = "Please enter vin number minimum lenght is 4 characters.";
                if (isFocus === false) { this.vinNoInputValidator.focus(); isFocus = true; }
            }
            ;
            if (Number(this.state.trackingSourceId) !== 1001 && Number(this.state.trackingSourceId) == 1005 && Number(this.state.trackingSourceId) !== 0) {
                if (this.state.trackingVehicleNumber.trim() === '') {
                    formIsValid = false;
                    errors.trackingVehicleNumber = "Please select tracking vehicle.";
                    //isFocus === false
                    if (isFocus === false) { isFocus = false; }
                }
            }
            if (isFocus === false) { this.submitVehicleButtonValidator.focus(); }

            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
            formIsValid = false;
        }
        return formIsValid;
    }
    setAddEditHistory() {
        let history = '';
        try {
            let sep = '. ';
            let oldData = this.state.vehicleData;
            if (Number(this.state.vehicleId) === 0) {
                history = 'New vehicle [' + this.state.vehicleNumber + '] is added' + sep;
            }
            else {
                let oldvehicleNo = oldData.vehicleNo === null ? '' : oldData.vehicleNo.trim();
                if (oldvehicleNo !== this.state.vehicleNumber.trim()) {
                    if (oldvehicleNo.length > 0 && this.state.vehicleNumber.trim().length > 0) {
                        history += 'Vehicle number is changed from ' + oldvehicleNo + ' To ' + this.state.vehicleNumber.trim() + sep;
                    }
                    else {
                        history += 'Vehicle number is updated ' + this.state.vehicleNumber.trim() + sep;
                    }
                }
                if (Number(oldData.apiVehicleTypeId) !== Number(this.state.vehicleTypeId)) {
                    if (Number(oldData.apiVehicleTypeId) > 0 && Number(this.state.vehicleTypeId) > 0) {
                        history += 'Vehicle type is changed from ' + oldData.apiVehicleTypeDescription + ' To ' + this.state.vehicleType.trim() + sep;
                    }
                    else {
                        history += 'Vehicle type is updated ' + this.state.vehicleType.trim() + sep;
                    }
                }
                let oldvehicleModel = oldData.vehicleModel === null ? '' : oldData.vehicleModel.trim();
                if (oldvehicleModel !== this.state.model.trim()) {
                    if (oldvehicleModel.length > 0 && this.state.model.trim().length > 0) {
                        history += 'Model is changed from ' + oldvehicleModel + ' To ' + this.state.model.trim() + sep;
                    }
                    else {
                        history += 'Model is updated ' + this.state.model.trim() + sep;
                    }
                }
                let oldvin = oldData.vin === null ? '' : oldData.vin.trim();
                if (oldvin !== this.state.vinNo.trim()) {
                    if (oldvin.length > 0 && this.state.vinNo.trim().length > 0) {
                        history += 'VIN No is changed from ' + oldvin + ' To ' + this.state.vinNo.trim() + sep;
                    }
                    else {
                        history += 'VIN No is updated ' + this.state.vinNo.trim() + sep;
                    }

                }
                let oldvehicleYear = oldData.vehicleYear === null ? '' : oldData.vehicleYear.trim();
                if (oldvehicleYear !== this.state.makeYear.trim()) {
                    if (oldvehicleYear.length > 0 && this.state.makeYear.trim().length > 0) {
                        history += 'Year is changed from ' + oldvehicleYear + ' To ' + this.state.makeYear.trim() + sep;
                    }
                    else {
                        history += 'Year is updated ' + this.state.makeYear.trim() + sep;
                    }
                }
                if (Number(oldData.ownerTypeId) !== Number(this.state.ownerTypeId)) {
                    if (Number(oldData.ownerTypeId) > 0 && Number(this.state.ownerTypeId) > 0) {
                        history += 'Owner type is changed from ' + oldData.ownerTypeName + ' To ' + this.state.ownerType.trim() + sep;
                    }
                    else {
                        history += 'Owner type is updated ' + this.state.ownerType.trim() + sep;
                    }
                }
                let oldplateNo = oldData.plateNo === null ? '' : oldData.plateNo.trim();
                if (oldplateNo !== this.state.plateNo.trim()) {
                    if (oldplateNo.length > 0 && this.state.plateNo.trim().length > 0) {
                        history += 'Plate No is changed from ' + oldplateNo + ' To ' + this.state.plateNo.trim() + sep;
                    }
                    else {
                        history += 'Plate No is updated ' + this.state.plateNo.trim() + sep;
                    }
                }
                let oldpermitNumber = oldData.permitNumber === null ? '' : oldData.permitNumber.trim();
                if (oldpermitNumber !== this.state.permitNo.trim()) {
                    if (oldpermitNumber.length > 0 && this.state.permitNo.trim().length > 0) {
                        history += 'Permit No is changed from ' + oldpermitNumber + ' To ' + this.state.permitNo.trim() + sep;
                    }
                    else {
                        history += 'Permit No is updated ' + this.state.permitNo.trim() + sep;
                    }
                }
                if (oldData.enable !== this.state.inService) {
                    history += 'Vehicle is ' + (this.state.inService === true ? 'in service' : 'not in service') + sep;
                }
                let oldinOutServiceNote = oldData.inOutServiceNote === null ? '' : oldData.inOutServiceNote.trim();
                if (oldinOutServiceNote !== this.state.serviceNote.trim()) {
                    history += 'Service note: ' + this.state.serviceNote.trim() + sep;
                }
                if (oldData.isPreventAccess !== this.state.isPrevent) {
                    history += ' Vehicle prevent is ' + (this.state.isPrevent === true ? 'Activated' : 'Inactivated') + sep;
                }
                let oldpreventAccessNote = oldData.preventAccessNote === null ? '' : oldData.preventAccessNote.trim();
                if (oldpreventAccessNote !== this.state.preventNote.trim()) {
                    history += ' Prevent note: ' + this.state.preventNote.trim() + sep;
                }
                if (Number(oldData.trackingSourceId) !== Number(this.state.trackingSourceId)) {
                    if (Number(oldData.trackingSourceId) > 0 && Number(this.state.trackingSourceId) > 0) {
                        history += 'Tracking source is changed from ' + oldData.trackingSource + ' To ' + this.state.trackingSource.trim() + sep;
                    }
                    else {
                        history += 'Tracking source is updated ' + this.state.trackingSource.trim() + sep;
                    }
                }
                let oldfmVehicleNo = oldData.fmVehicleNo === null ? '' : oldData.fmVehicleNo.trim();
                if (oldfmVehicleNo !== this.state.trackingVehicleNumber.trim()) {
                    if (oldfmVehicleNo.length > 0 && this.state.trackingVehicleNumber.trim().length > 0) {
                        history += 'Tracking vehicle is changed from ' + oldfmVehicleNo + ' To ' + this.state.trackingVehicleName.trim() + sep;
                    }
                    else {
                        history += 'Tracking vehicle is updated ' + this.state.trackingVehicleName.trim() + sep;
                    }
                }
                if (Number(oldData.vehicleSeats) !== Number(this.state.seat)) {
                    if (Number(oldData.vehicleSeats) > 0 && Number(this.state.seat) > 0) {
                        history += 'Seat is changed from ' + oldData.vehicleSeats + ' To ' + this.state.seat.toString() + sep;
                    }
                    else {
                        history += 'Seat is updated ' + this.state.seat.toString() + sep;
                    }
                }
                if (history === '') {
                    history += 'Vehicles [' + this.state.vehicleNumber + '] has been successfully updated' + sep;
                }
            }
        } catch (ex) {
            if (history === '') {
                history += 'Vehicles [' + this.state.vehicleNumber + '] has been successfully updated.';
            }
            console.log(ex);
        }
        return history;
    };
    handleOnChangeInputActivatePayroll = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        let vehicleActivatePayroll = this.state.vehicleActivatePayroll;
        let errors = this.state.vehicleActivatePayroll.errors;
        switch (name) {
            case "note":
                vehicleActivatePayroll.note = value;
                errors.note = "";
                if (this.activatePayrollNoteInputValidator.value.trim() === '') {
                    errors.note = "Please enter authorization note.";
                }
                break;
            case "password":
                errors.password = "";
                vehicleActivatePayroll.password = value;
                if (this.activatePayrollPasswordInputValidator.value.trim() === '') {
                    errors.password = "Please enter password.";
                }
                break;
            case "username":
                errors.username = "";
                vehicleActivatePayroll.username = value;
                if (this.state.vehicleActivatePayroll.usernameRequired) {
                    if (this.activatePayrollUsernameInputValidator.value.trim() === '') {
                        errors.username = "Please enter username.";
                    }
                }
                break;
            default:
                break;
        }
        vehicleActivatePayroll.errors = errors;
        this.setState({
            vehicleActivatePayroll: vehicleActivatePayroll
        });
    }
    handleActivatePayrollSubmit = async event => {
        event.preventDefault();
        try {
            if (this.isActivatePayrollFormValid()) {
                this.setState({ isSubmited: true });
                var request = new Object();
                request.IsActivate = this.state.isPayrollActive ? false : true;
                request.Note = this.state.vehicleActivatePayroll.note.trim();
                request.Password = this.state.vehicleActivatePayroll.password.trim();
                request.Username = this.state.vehicleActivatePayroll.username.trim();
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('vehicle-api/' + Number(this.state.vehicleId) + '/activate-deactivate-payroll', requestParams);
                const data = await response.json();
                await Swal.fire({
                    allowOutsideClick: false,
                    icon: data.isSuccess ? 'success' : 'error',
                    title: data.isSuccess ? 'Success' : 'Oops...',
                    text: (data.isSuccess === false && (data.resultMessage === null || data.resultMessage === "")) ? 'Unable to process the request.' : (data.resultMessage === null || data.resultMessage === "") ? 'This vehicle has been ' + (request.IsActivate ? 'activated' : 'deactivated') + ' for payroll successfully.' : data.resultMessage,
                }).then(async (result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            await this.handleResetStateData();
                            await this.getVehicleList();
                        }
                    } else {
                        if (data.retValue === -1) {
                            let vehicleActivatePayroll = this.state.vehicleActivatePayroll;
                            if (this.activatePayrollUsernameInputValidator.value.trim() === "") {
                                let errors = this.state.vehicleActivatePayroll.errors;
                                errors.username = "Please enter username.";
                                vehicleActivatePayroll.errors = errors;
                                this.activatePayrollUsernameInputValidator.focus();
                            }
                            vehicleActivatePayroll.usernameRequired = true;
                            this.setState({
                                vehicleActivatePayroll: vehicleActivatePayroll
                            });
                        }
                    }
                })
            }
        } catch (ex) {
            console.log(ex);
            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Unable to process the request.',
            })
        }
        this.setState({ isSubmited: false })
    }

    openVehicleDeviceModel(e, data) {
        e.preventDefault();
         this.refs.vehicleDeviceMappingComponent.showModal(data.id, data.vehicleNo);
    }

    isActivatePayrollFormValid() {
        let formIsValid = true;
        try {
            let errors = this.state.vehicleActivatePayroll.errors;
            let isFocus = false;
            errors.note = "";
            errors.password = "";
            errors.username = "";
            if (this.activatePayrollNoteInputValidator.value.trim() === '') {
                formIsValid = false;
                errors.note = "Please enter authorization note.";
                if (isFocus === false) { this.activatePayrollNoteInputValidator.focus(); isFocus = true; }
            }
            if (this.activatePayrollPasswordInputValidator.value.trim() === '') {
                formIsValid = false;
                //errors.password = "Please enter authorised password.";
                errors.password = "Please enter password.";
                if (isFocus === false) { this.activatePayrollPasswordInputValidator.focus(); isFocus = true; }
            }
            if (this.state.vehicleActivatePayroll.usernameRequired) {
                if (this.activatePayrollUsernameInputValidator.value.trim() === '') {
                    formIsValid = false;
                    errors.username = "Please enter username.";
                    if (isFocus === false) { this.activatePayrollUsernameInputValidator.focus(); isFocus = true; }
                }
            }
            if (isFocus === false) { this.submitActivatePayrollButtonValidator.focus(); }
            let vehicleActivatePayroll = this.state.vehicleActivatePayroll;
            vehicleActivatePayroll.errors = errors;
            this.setState({
                vehicleActivatePayroll: vehicleActivatePayroll
            });
        } catch (ex) {
            formIsValid = false;
            console.log(ex);
        }
        return formIsValid
    }
    render() {
        if (this.state.isGotoVehicleOwnerComponent) {
            return <VehicleOwnerListComponent vehicleId={this.state.vehicleId} vehicleNumber={this.state.vehicleNumber} isPayrollActive={this.state.isPayrollActive} />
        }
        const renderGridCell = (data) => {
            let isAffiliateVehicle = (data.data.ownerTypeId || 0) === 1;
            let isPayrollActive = (data.data.isPayrollActivated || false);
            let editLink = <a href="#" title="Edit" onClick={(e) => { this.handleEditVehicle(e, data.data) }} ><i className="fas fa-pen text-info"></i></a>;
            let qrCodeLink = <a href="#" title="Download QR Code" style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleGenerateQRCode(e, data.data) }} ><i className="fas fa-qrcode text-info"></i></a>;
            let commLink = (Number(data.data.vehicleOwnerId) > 0 && !isAffiliateVehicle) ? <a href="#" title="Set Commission" style={{ paddingLeft: '6px' }}
                    onClick={(e) => { this.handleSetVehicleCommissionSetting(e, data.data) }} ><i className="fas fa-dollar-sign text-info"></i></a> : '';
            let ownerLink = !isAffiliateVehicle ? <a href="#" title="Vehicle Owner" style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleViewSetVehicleOwner(e, data.data) }} ><i className="fas fa-user text-info"></i></a> : '';
            let payrollActiveLink = (!isPayrollActive && !isAffiliateVehicle) ? <a href="#" title="Activate Vehicle Payroll" style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleActiveVehiclePayroll(e, data.data) }}><i className="fas fa-user-lock text-danger"></i></a> : '';
            let payrollDeactiveLink = (isPayrollActive && !isAffiliateVehicle) ? <a href="#" title="Deactivate Vehicle Payroll" style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleActiveVehiclePayroll(e, data.data) }}><i className="fas fa-user-lock text-info"></i></a> : '';
            let doNotDeleteVehicle = this.state.doNotDeleteVehicle;
            let vehicleDocument = <a href="#" title="vehicle document" style={{ paddingLeft: '6px' }}
                onClick={(e) => { this.handleVehicleDocumentModal(e, data.data) }}><i className="fas fa-file-alt text-info"></i></a>

            return (<div style={{ textAlign: 'left' }}>
                {sessionUserName === staticUserName ? "" : editLink}
                {qrCodeLink}
                <a href="#" title="Vehicle Device Details" style={{ paddingLeft: '6px' }} onClick={(e) => { this.openVehicleDeviceModel(e, data.data) }}><i className="fas fa-list icon text-info"  ></i></a>                
                {
                    sessionUserName === staticUserName ? "" :
                    doNotDeleteVehicle === true ? "" :
                        <a href="#" title="Delete Vehicle" style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleDeleteVehicle(e, data.data) }}><i className="fas fa-trash-alt text-info"></i></a>
                }
                {/*<a href="#" title="Delete Vehicle" style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleDeleteVehicle(e, data.data) }}><i className="fas fa-trash-alt text-info"></i></a>*/}
                <a href="#" title="History" style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleMasterHistoryModal(e, data.data) }}><i className="fas fa-history text-info"></i></a>
                <a href="#" title="Images" style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleVehicleImageModal(e, data.data) }}><i className="fas fa-image text-info"></i></a>
                {vehicleDocument === '' || vehicleDocument}
                {commLink === '' || commLink}
                {ownerLink === '' || ownerLink}
                {payrollActiveLink === '' || payrollActiveLink}
                {payrollDeactiveLink === '' || payrollDeactiveLink}
            </div>
            );
        }
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <h1 className="m-0 text-dark">Vehicle</h1>
                            </div>
                            {
                                sessionUserName != staticUserName ?
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <NavItem><NavLink tag={Link} to="#" className="btn btn-block btn-outline-secondary btn-sm" onClick={(e) => { this.handleAddVehicle(e, null) }} title="Add Vehicle"><i className="fas fa-plus"></i> Add Vehicle</NavLink></NavItem>
                                        </ol>
                                        <ol className="breadcrumb float-sm-right">
                                            <NavItem><NavLink tag={Link} to="/vehicle/bulk" className="btn btn-block btn-outline-secondary btn-sm" title="Import Vehicle"><i className="fas fa-upload"></i> Import Vehicle</NavLink></NavItem>
                                        </ol>
                                    </div> : null                                    
                            }
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="card card-grid">
                        <div className="card-body">
                            <DataGrid
                                dataSource={this.state.vehicleGridDataSource}
                                keyExpr="id"
                                selection={{ mode: 'single' }}
                                columnsAutoWidth={true}
                                showColumnLines={false}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                onToolbarPreparing={this.handleToolbarPreparing}>
                                <Column dataField="id" width={170} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                <Column dataField="vehicleNo" caption="Vehicle #" />
                                <Column dataField="apiVehicleTypeDescription" caption="Vehicle Type" />
                                <Column dataField="ownerTypeName" caption="Owner Type" />
                                <Column dataField="vehicleModel" caption="Model" />
                                <Column dataField="vehicleSeats" caption="Seats" />
                                <Column dataField="trackingSource" caption="Tracking Source" />
                                <Column dataField="fmVehicleNo" caption="Tracking Vehicle No" />
                                <Column dataField="plateNo" caption="Plate #" />
                                <Column dataField="status" caption="In Service" />
                                <Column dataField="preventAccess" caption="Prevent Access" />
                                <Column dataField="preventAccessNote" caption="Prevent Note" />
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                <Export enabled={true} allowExportSelectedData={false} />
                                <ColumnChooser enabled={true} />
                                <ColumnFixing enabled={true} />
                            </DataGrid>
                        </div>
                    </div>
                </div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.vehicleModalIsOpen}
                    style={customStyles}
                    className={"react-modal"}
                    onAfterOpen={this.handleAssignDataToState}
                    onRequestClose={this.handleResetStateData}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleVehicleSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{Number(this.state.vehicleId) > 0 ? "Modify Vehicle" : "Add Vehicle"}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleResetStateData}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>Vehicle Number<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-textbox form-control-sm" name="vehicleNumber" title="Vehicle No" placeholder="Enter vehicle number" maxLength="20" disabled={Number(this.state.vehicleId) > 0 ? true : false} ref={input => this.vehicleNumberInputValidator = input} value={this.state.vehicleNumber} onChange={this.handleInputChange} />
                                        {this.state.errors["vehicleNumber"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["vehicleNumber"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>Vehicle Type <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.dropdownVehicleType}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select vehicle type"
                                            name="rowIndex"
                                            defaultValue={0}
                                            value={this.state.vehicleTypeId}
                                            displayExpr='apiVehicleTypeDescription'
                                            valueExpr='rowIndex'
                                            onSelectionChanged={this.handlVehicleTypeChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                            ref={select => this.vehicleTypeSelectValidator = select}
                                        />
                                        {this.state.errors["vehicleType"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["vehicleType"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>Model</label>
                                        <input type="text" className="form-control form-textbox form-control-sm" name="model" title="Model" placeholder="Enter model" maxLength="15" ref={input => this.modelInputValidator = input} value={this.state.model} onChange={this.handleInputChange} />
                                        {this.state.errors["model"].length > 0 && <span className="error invalid-feedback">{this.state.errors["model"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>Year</label>
                                        <select className="form-control form-control-sm" name="makeYear" title="Year" placeholder="Select year"
                                            ref={select => this.makeYearSelectValidator = select} value={this.state.makeYear} onChange={(e) => { this.setState({ makeYear: e.target.value }) }}>
                                            {
                                                vehicleMakeYearList
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Owner Type </label>
                                        <select name="ownerType" className="form-control form-control-sm" title="Owner Type" placeholder="Owner Type"
                                            ref={select => this.ownerTypeSelectValidator = select} value={this.state.ownerTypeId} onChange={(e) => { this.setState({ ownerTypeId: e.target.value, ownerType: e.target.options[e.target.selectedIndex].text }) }}>
                                            <option value="0">Select owner type</option>
                                            {
                                                this.state.dropdownOwnerType &&
                                                this.state.dropdownOwnerType.map((item, i) =>
                                                    (<option key={i} value={item.id}>{item.name}</option>))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Plate Number</label>
                                        <input type="text" className="form-control form-textbox form-control-sm" name="plateNo" title="Plate No" placeholder="Enter plate number" maxLength="15" ref={input => this.plateNoInputValidator = input} value={this.state.plateNo} onChange={this.handleInputChange} />
                                        {this.state.errors["plateNo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["plateNo"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-8 col-xs-8">
                                    <div className="form-group">
                                        <label>Permit No </label>
                                        <input type="text" className="form-control form-textbox form-control-sm" name="permitNo" title="Permit No" placeholder="Enter permit number" maxLength="15" ref={input => this.permitNoInputValidator = input} value={this.state.permitNo} onChange={this.handleInputChange} />
                                        {this.state.errors["permitNo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["permitNo"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-1 col-md-1 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>Seat </label>
                                        <input type="text" className="form-control form-textbox form-control-sm" name="seat" title="Seat" placeholder="Enter seat" maxLength="2" ref={input => this.seatInputValidator = input} value={this.state.seat} onChange={this.handleInputChange} />
                                        {this.state.errors["seat"].length > 0 && <span className="error invalid-feedback">{this.state.errors["seat"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12" style={{ display: Number(this.state.vehicleId) > 0 ? 'block' : 'none' }}>
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="inService" name="inService" checked={this.state.inService} onChange={this.handleInputChange} />
                                            <label className="form-check-label" htmlFor="inService" style={{ fontSize: "inherit" }}> In Service &nbsp;<i>(If not in service, please enter reason)</i></label>
                                        </div>
                                        <textarea className="form-control form-textbox form-control-sm" name="serviceNote" title="Service Note" placeholder="Service Note" maxLength="100" ref={input => this.serviceNoteInputValidator = input} disabled={this.state.inService} value={this.state.inService ? '' : this.state.serviceNote} onChange={this.handleInputChange} />
                                        {this.state.errors["serviceNote"].length > 0 && <span className="error invalid-feedback">{this.state.errors["serviceNote"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12" style={{ display: Number(this.state.vehicleId) > 0 ? 'block' : 'none' }}>
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="isPrevent" name="isPrevent" checked={this.state.isPrevent} onChange={this.handleInputChange} />
                                            <label className="form-check-label" htmlFor="isPrevent" style={{ fontSize: "inherit" }}> Is Prevent &nbsp;&nbsp;<i>(If Prevent, please enter reason)</i></label>
                                        </div>
                                        <textarea className="form-control form-textbox form-control-sm" name="preventNote" placeholder="Prevent Reason" maxLength="100" title="Prevent Reason" ref={input => this.preventNoteInputValidator = input} disabled={!this.state.isPrevent} value={this.state.isPrevent ? this.state.preventNote : ''} onChange={this.handleInputChange} />
                                        {this.state.errors["preventNote"].length > 0 && <span className="error invalid-feedback">{this.state.errors["preventNote"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>VIN Number</label>
                                        <input type="text" className="form-control form-textbox form-control-sm" name="vinNo" title="VIN No" placeholder="Enter VIN number" maxLength="20" ref={input => this.vinNoInputValidator = input} value={this.state.vinNo} onChange={this.handleInputChange} />
                                        {this.state.errors["vinNo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["vinNo"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Tracking Source </label>
                                        <select className="form-control form-control-sm" name="trackingSource" placeholder="Tracking Source" ref={select => this.trackingSourceSelectValidator = select} value={this.state.trackingSourceId} onChange={this.handleInputChange}>
                                            <option value="0">Select tracking source</option>
                                            {
                                                this.state.dropdownTrackingSource &&
                                                this.state.dropdownTrackingSource.map((item, i) =>
                                                    (<option key={i} value={item.id}>{item.name}</option>))
                                            }
                                        </select>
                                    </div>
                                </div>
                                {/*<div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">*/}
                                {/*    <div className="form-group">*/}
                                {/*        <label>Tracking Vehicle <span className="text-danger" style={{ display: (Number(this.state.trackingSourceId) !== 1001 && Number(this.state.trackingSourceId) !== 0) || 'none' }}>*</span></label>*/}
                                {/*        <select className="form-control form-control-sm" name="trackingVehicle" placeholder="Tracking Vehicle" disabled={Number(this.state.trackingSourceId) > 1001 ? false : true} ref={select => this.trackingVehicleSelectValidator = select} value={this.state.trackingVehicleNumber} onChange={this.handleInputChange}>*/}
                                {/*            <option value="">Select tracking vehicle</option>*/}
                                {/*            {*/}
                                {/*                this.state.dropdownTrackingVehicle &&*/}
                                {/*                this.state.dropdownTrackingVehicle.map((item, i) =>*/}
                                {/*                    (<option key={i} value={item.vehicleNo}>{item.vehicleName}</option>))*/}
                                {/*            }*/}
                                {/*        </select>*/}
                                {/*        {this.state.errors["trackingVehicleNumber"].length > 0 && <span className="error invalid-feedback">{this.state.errors["trackingVehicleNumber"]}</span>}*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Tracking Vehicle <span className="text-danger" style={{ display: (Number(this.state.trackingSourceId) !== 1001 && Number(this.state.trackingSourceId) !== 0) || 'none' }}>*</span></label>
                                        <SelectBox
                                            dataSource={this.state.dropdownTrackingVehicle}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select vehicle type"
                                            name="vehicleName"
                                            defaultValue={0}
                                            value={this.state.trackingVehicleNumber}
                                            displayExpr='vehicleName'
                                            valueExpr='vehicleName'
                                            onSelectionChanged={this.handleSourceVehicleChanges}
                                            showClearButton={true}
                                            disabled={Number(this.state.trackingSourceId) > 1001 ? false : true}
                                            searchEnabled={true}
                                           // ref={select => this.trackingVehicleSelectValidator = select}
                                        />
                                        {this.state.errors["trackingVehicleNumber"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["trackingVehicleNumber"]}</span>}
                                    </div>
                            </div></div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success btn-sm" ref={button => this.submitVehicleButtonValidator = button}>
                                {this.state.isSubmited && <span title="In Process"><i className="fas fa-spinner fa-spin"></i> In Process</span>}
                                {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleResetStateData}><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <Modal ariaHideApp={false}
                    isOpen={this.state.vehicleActivatePayrollModelIsOpen}
                    style={{ content: { width: '45%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleAssignDataToState}
                    onRequestClose={this.handleResetStateData}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{"Vehicle Payroll Authorization"}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleResetStateData}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <label>Authorization Note <span className="text-danger">*</span></label>
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                    <div className="form-group">
                                        <textarea className="form-control form-textbox form-control-sm" rows="5" name="note" placeholder="Note..." maxLength="200" title="Authorization Note" ref={input => this.activatePayrollNoteInputValidator = input} onChange={this.handleOnChangeInputActivatePayroll} />
                                        {this.state.vehicleActivatePayroll.errors["note"].length > 0 && <span className='error invalid-feedback'>{this.state.vehicleActivatePayroll.errors["note"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <label>Authorized Password <span className="text-danger">*</span></label>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <input type="password" autocomplete="new-password" className="form-control form-textbox form-control-sm" name="password" title="Authorized Password" placeholder="password" maxLength="30" ref={input => this.activatePayrollPasswordInputValidator = input} onChange={this.handleOnChangeInputActivatePayroll} />
                                        {this.state.vehicleActivatePayroll.errors["password"].length > 0 && <span className='error invalid-feedback'>{this.state.vehicleActivatePayroll.errors["password"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4" style={{ display: this.state.vehicleActivatePayroll.usernameRequired ? 'block' : 'none' }}>
                                    <div className="form-group">
                                        <input type="text" className="form-control form-textbox form-control-sm" name="username" title="Authorized User Name" placeholder="username" maxLength="30" ref={input => this.activatePayrollUsernameInputValidator = input} onChange={this.handleOnChangeInputActivatePayroll} />
                                        {this.state.vehicleActivatePayroll.errors["username"].length > 0 && <span className='error invalid-feedback'>{this.state.vehicleActivatePayroll.errors["username"]}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" ref={button => this.submitActivatePayrollButtonValidator = button} onClick={this.handleActivatePayrollSubmit}>
                                {this.state.isSubmited && <span title="In Process"><i className="fas fa-spinner fa-spin"></i> In Process</span>}
                                {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleResetStateData} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <Modal ariaHideApp={false}
                    isOpen={this.state.qrCodeModalIsOpen}
                    style={qrCodeCustomStyles}
                    className={"react-modal"}
                    onAfterOpen={this.handleQRCodeOpenState}
                    onRequestClose={this.handleQRCodeCloseState}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleDownloadVehicleQRCode} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">QR Code {this.state.qrCodeVehicleNumber}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleQRCodeCloseState}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body text-center">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <QRCode id={this.state.qrCodeVehicleNumber} value={this.state.qrCodeVehicleNumber} size={350} level={"H"} includeMargin={true} />
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <b>{this.state.qrCodeVehicleNumber}</b>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleDownloadVehicleQRCode}>
                                {this.state.isSubmited && <span title="In Process"><i className="fas fa-spinner fa-spin"></i> In Process</span>}
                                {!this.state.isSubmited && <span title="Download QR Code"><i className="fas fa-check"></i> Download QR Code</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleQRCodeCloseState} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <MasterHistoryComponent ref="masterHistoryComponent" />
                <CommissionSettingComponent ref="vehicleCommSettingComponent" />
                <DocumentsComponent ref="documentsComponent" />
                <VehicleDeviceMappingComponent ref="vehicleDeviceMappingComponent" />
                <VehicleDocumentComponent ref="vehicleDocumentComponent" isDisplayVehicleList="false" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        )
    }
}