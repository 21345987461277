import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
}
    from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { SendMessageComponent } from '../Shared/SendMessageComponent';
import { getRequestParams } from '../../helpers/globalfunctions.js';

export class DriverMessageComponent extends Component {
    static displayName = DriverMessageComponent.name;

    constructor(props) {
        super(props);

        this.state = {
            isSubmited: false,
            driverMessageGridDataSource: null,
            vehicleList: null,
            cannedMessageList: null,
            vehicleId: "",
            messageId: "",
        }
        this.getDriverMessageList = this.getDriverMessageList.bind(this);
        this.sendDriverMessageControl = this.sendDriverMessageControl.bind(this);
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
    }

    componentDidMount() {
        this.getDriverMessageList();
    }
    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getDriverMessageList.bind(this)
            }
        });
    }

    sendDriverMessageControl(e, data) {
        e.preventDefault();
        this.refs.SendMessageComponentModel.showModal(data);
    }

    async getDriverMessageList() {        
        var request = {};
        request.VehicleId = 0;
        request.MessageId = 0;
        request.VehicleTypeId = 0;

        const requestParams = getRequestParams('POST', request);
        const response = await fetch('driver-api/message', requestParams);
        const data = await response.json();
        if (data !== null && data.driverMessage.length > 0) {
            this.setState({ driverMessageGridDataSource: data.driverMessage });
        }
    }

    render() {
        const renderGridCell = (data) => {

            return <div>
                <a href="#" className="fas fa-reply icon text-info" title="Reply" onClick={(e) => { this.sendDriverMessageControl(e, data.data) }}></a>
            </div>;
        }

        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <h1 className="m-0 text-dark">Driver Message</h1>
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem><NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="#" onClick={this.getDriverMessageList} title="Refresh"><i className="fas fa-sync-alt"></i> Refresh</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="#" className="btn btn-block btn-outline-secondary btn-sm" onClick={(e) => { this.sendDriverMessageControl
                                        (e, null) }} data-toggle="modal" title="Send Message"><i className="fas fa-sms"></i> Send Message</NavLink></NavItem>
                                    <NavItem><NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="/dispatch/list" title="Dispatch"><i className="fas fa-comment-alt"></i> Dispatch</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/noshow/list" className="btn btn-block btn-outline-secondary btn-sm" title="No Show Request"><i className="fas fa-eye-slash"></i> No Show Request</NavLink></NavItem>
                                    <NavItem><NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="/loggeddrivers/list" title="Logged Drivers"><i className="fas fa-user"></i> Logged Drivers</NavLink></NavItem>
                                    <NavItem><NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="/loggedvehicles/list" title="Logged Vehicles"><i className="fas fa-car"></i> Logged Vehicles</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="card card-grid">
                        <div className="card-body">
                            {/*<div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <button type="submit" className="btn btn-success btn-sm" onClick={(e) => { this.sendDriverMessageControl(e, null) }}>
                                        {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                        {!this.state.isSubmited && <span title="Send Message"><i className="fas fa-sms"></i> Send Message</span>}
                                    </button>
                                </div>
                            </div>*/}
                            <DataGrid
                                dataSource={this.state.driverMessageGridDataSource}
                                keyExpr="rowIndex"
                                selection={{ mode: 'single' }}
                                columnsAutoWidth={false}
                                showColumnLines={false}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                showBorders={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                onToolbarPreparing={this.onToolbarPreparing}>
                                <Column dataField="rowIndex" width={50} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                <Column dataField="vehicleNo" width={100} caption="Vehicle Number" allowFiltering={false} allowSorting={false} />
                                <Column dataField="driverName" caption="Driver Name" allowFiltering={false} allowSorting={false} />
                                <Column dataField="messageDateStr" caption="Message Date" allowFiltering={false} allowSorting={false} />
                                <Column dataField="messageText" caption="Message" allowFiltering={false} allowSorting={false} />
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                <Export enabled={true} allowExportSelectedData={false} />
                                <ColumnChooser enabled={true} />
                                <ColumnFixing enabled={true} />

                            </DataGrid>
                        </div>
                    </div>
                </div>
                <SendMessageComponent ref="SendMessageComponentModel" onSendMessageClose={this.getDriverMessageList} />
            </div>
        );
    }
}