import DataGrid, {
    Column,


    Scrolling, Selection
} from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import { LoadPanel } from 'devextreme-react/load-panel';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { getRequestParams } from '../../helpers/globalfunctions.js';

export const mailPriorityDropdownList = [{
    'id': 0,
    'name': 'Normal',
}, {
    'id': 1,
    'name': 'Low',
}, {
    'id': 2,
    'name': 'High',
}]

const customStylesEmail = {
    content: {
        width: '20%'
    }
};

export class VoucherEmailComponent extends Component {
    constructor() {
        super()
        this.state = {
            isSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            isEmailBCc: false,
            emailTo: '',
            emailCc: '',
            emailBcc: '',
            emailSubject: '',
            emailBody: '',
            emailNotes: '',
            attachments: [],
            isSendAttachment: false,
            mailPriority: 0,
            fileNameDisplay: '',

            rideId: 0,
            quoteId: 0,

            isOpenEmailListModal: false,
            isEmailListSubmited: false,
            selectedRowKeys: [],
            emailList: [],

            wireInstructionList: [],

            errors: {
                emailTo: '',
                emailCc: '',
                emailBcc: '',
                emailSubject: '',
            }
        }

        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.closeEmailListModal = this.closeEmailListModal.bind(this);
        this.handleEmailListSubmit = this.handleEmailListSubmit.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.getEmailList = this.getEmailList.bind(this);
    }
    showModal = async (_rideId, _quoteId, emailTo, emailCc, emailSubject, emailBody, attachments) => {
        try {
            this.setState({ isOpenModal: true });
            this.setState({
                rideId: _rideId,
                quoteId: _quoteId,
                emailTo: emailTo,
                emailCc: emailCc,
                emailBody: emailBody,
                emailSubject: emailSubject,
                attachments: attachments,
                isSendAttachment: (attachments !== null && attachments.length !== 0)
            });
            let fileNameDisplay = '';
            if (attachments !== null && attachments.length !== 0) {
                attachments.forEach((file) => {
                    fileNameDisplay += (fileNameDisplay.trim().length === 0) ? file.fileName : ' , ' + file.fileName;
                });
            }
            this.setState({ fileNameDisplay: fileNameDisplay });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isOpenModal: false,
                loadPanelVisible: false,
                isEmailBCc: false,
                emailTo: '',
                emailCc: '',
                emailBcc: '',
                emailSubject: '',
                emailBody: '',
                emailNotes: '',
                attachments: [],
                isSendAttachment: false,
                mailPriority: 0,
                fileNameDisplay: '',

                rideId: 0,
                quoteId: 0,

                isOpenEmailListModal: false,
                isEmailListSubmited: false,
                selectedRowKeys: [],
                emailList: [],

                errors: {
                    emailTo: '',
                    emailCc: '',
                    emailBcc: '',
                    emailSubject: '',
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnClearSearch = async () => {
        try {
            this.setState({
                isSubmitted: false,
                loadPanelVisible: false,
                isEmailBCc: false,
                emailTo: '',
                emailCc: '',
                emailBcc: '',
                emailSubject: '',
                emailBody: '',
                emailNotes: '',
                isSendAttachment: (this.state.attachments !== null && this.state.attachments.length !== 0),
                mailPriority: 0,
                fileNameDisplay: '',

                rideId: 0,
                quoteId: 0,

                isOpenEmailListModal: false,
                isEmailListSubmited: false,
                selectedRowKeys: [],
                emailList: [],

                errors: {
                    emailTo: '',
                    emailCc: '',
                    emailBcc: '',
                    emailSubject: '',
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnInputChange = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
            let errors = this.state.errors;
            let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            switch (name) {
                case 'emailTo':
                    errors.emailTo = '';
                    if (value.length < 501) {
                        //if (value.trim() !== '') {
                        //    let email = value.split(',');
                        //    if (email.length > 0) {
                        //        for (let i = 0; i < email.length; i++) {
                        //            if ((email[i]).trim() !== "") {
                        //                if (!regEmail.test(String(email[i].trim()).toLowerCase())) {
                        //                    errors.emailTo = "Please enter valid email.";
                        //                }
                        //            }
                        //        }
                        //    }
                        //}
                        this.setState({ emailTo: value })
                    }
                    break;
                case 'emailCc':
                    errors.emailCc = '';
                    if (value.length < 501) {
                        //if (value.trim() !== '') {
                        //    let email = value.split(',');
                        //    if (email.length > 0) {
                        //        for (let i = 0; i < email.length; i++) {
                        //            if ((email[i]).trim() !== "") {
                        //                if (!regEmail.test(String(email[i].trim()).toLowerCase())) {
                        //                    errors.emailCc = "Please enter valid email.";
                        //                }
                        //            }
                        //        }
                        //    }
                        //}
                        this.setState({ emailCc: value })
                    }
                    break;
                case 'emailBcc':
                    errors.emailBcc = '';
                    if (value.length < 501) {
                        //if (value.trim() !== '') {
                        //    let email = value.split(',');
                        //    if (email.length > 0) {
                        //        for (let i = 0; i < email.length; i++) {
                        //            if ((email[i]).trim() !== "") {
                        //                if (!regEmail.test(String(email[i].trim()).toLowerCase())) {
                        //                    errors.emailBcc = "Please enter valid email.";
                        //                }
                        //            }
                        //        }
                        //    }
                        //}
                        this.setState({ emailBcc: value })
                    }
                    break;
                case 'emailSubject':
                    errors.emailSubject = '';
                    if (value.length < 151) {
                        this.setState({ emailSubject: value });
                    }
                    break;
                case 'emailBody':
                    if (value.length < 501) {
                        this.setState({ emailBody: value });
                    }
                    break;
                case 'emailNotes':
                    if (value.length < 501) {
                        this.setState({ emailNotes: value });
                    }
                    break;
                case 'isSendAttachment':
                    this.setState({ isSendAttachment: value });
                    break;
                case 'mailPriority':
                    this.setState({ mailPriority: value });
                    break;
                default:
                    break;
            }
            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    isFormValid = async () => {
        let isValid = true;
        try {
            let errors = this.state.errors;
            errors.emailTo = '';
            errors.emailCc = '';
            errors.emailBcc = '';
            errors.emailSubject = '';
            let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (this.state.emailTo.trim() === '') {
                isValid = false;
                errors.emailTo = "Please enter email.";
            }
            if (this.state.emailTo.trim() !== '') {
                let email = this.state.emailTo.split(',');
                if (email.length > 0) {
                    for (let i = 0; i < email.length; i++) {
                        if ((email[i]).trim() !== "") {
                            if (!regEmail.test(String(email[i].trim()).toLowerCase())) {
                                isValid = false;
                                errors.emailTo = "Please enter valid email.";
                            }
                        }
                    }
                }
            }
            if (this.state.emailCc.trim() !== '') {
                let email = this.state.emailCc.split(',');
                if (email.length > 0) {
                    for (let i = 0; i < email.length; i++) {
                        if ((email[i]).trim() !== "") {
                            if (!regEmail.test(String(email[i].trim()).toLowerCase())) {
                                isValid = false;
                                errors.emailCc = "Please enter valid email.";
                            }
                        }
                    }
                }
            }
            if (this.state.emailBcc.trim() !== '') {
                let email = this.state.emailBcc.split(',');
                if (email.length > 0) {
                    for (let i = 0; i < email.length; i++) {
                        if ((email[i]).trim() !== "") {
                            if (!regEmail.test(String(email[i].trim()).toLowerCase())) {
                                isValid = false;
                                errors.emailBcc = "Please enter valid email.";
                            }
                        }
                    }
                }
            }
            if (this.state.emailSubject.trim() === '') {
                isValid = false;
                errors.emailSubject = "Please enter subject.";
            }
            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    handleOnSendEmail = async () => {
       
        try {
            if (await this.isFormValid()) {
                this.setState({ isSubmitted: true });
                let fileGuid = [];
                var request = new Object();
                if (this.state.isSendAttachment === true) {
                    if (this.state.attachments !== null && this.state.attachments.length !== 0) {
                        for (var i = 0; i < this.state.attachments.length; i++) {
                            request = new Object();
                            request.FileData = this.state.attachments[i].fileData;
                            request.FileName = this.state.attachments[i].fileName;
                            request.Extension = this.state.attachments[i].fileExtension;
                            let requestFileParams = getRequestParams('POST', request);
                            let responseFile = await fetch('report-api/upload-notification-attachment', requestFileParams);
                            let dataResponseFile = await responseFile.json();
                            if (dataResponseFile.isSuccess) {
                                fileGuid.push(dataResponseFile.fileGuid);
                            }
                        }
                    }
                }
                request = new Object();
                request.EmailTo = this.state.emailTo.trim();
                request.EmailCC = this.state.emailCc.trim();
                request.EmailBCC = this.state.emailBcc.trim();
                request.Subject = this.state.emailSubject.trim();
                request.Body = this.getEmailBody();
                request.MobileNo = '';
                request.Header = '';
                request.Footer = '';
                request.NotificationTypeId = 1;
                request.NotificationPriority = Number(this.state.mailPriority);
                request.FileGuid = fileGuid;
                request.InvoiceNo = Number(0);
                if (this.state.quoteId > 0) {
                    request.QuoteId = Number(this.state.quoteId);
                }
                if (this.state.rideId > 0) {
                    request.RideId = Number(this.state.rideId);
                }
                let requestParams = getRequestParams('POST', request);
                let response = await fetch('report-api/send-notification', requestParams);
                if (response.ok) {
                    const data = await response.json();
                    this.setState({ isSubmitted: false });
                    await Swal.fire({
                        allowOutsideClick: false,
                        icon: data.isSuccess ? 'success' : 'error',
                        title: data.isSuccess ? 'E-mail Sent' : 'Oops...',
                        text: (data.isSuccess === false && (data.resultMessage === null || data.resultMessage === "")) ? 'Unable to process the email request.' : 'The email request has been sent and you will receive it shortly.',
                        confirmButtonText: 'Thank you!',
                    }).then(async (result) => {
                        if (data.isSuccess) {
                            if (result.isConfirmed) {
                                await this.handleOnCloseModal();
                                if (this.state.quoteId > 0) {
                                    this.props.goToBack();
                                }
                            }
                        }
                    })
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false });
    }

    getEmailBody() {

        var strMailBody = "";

        if (this.state.rideId > 0) {

            strMailBody = strMailBody + "<HTML>" + "\r\n";
            strMailBody = strMailBody + "<HEAD>" + "\r\n";
            strMailBody = strMailBody + "</HEAD>" + "\r\n";
            strMailBody = strMailBody + "<BODY>" + "\r\n";
            strMailBody = strMailBody + "<TABLE>";
            strMailBody = strMailBody + "<TR>";
            strMailBody = strMailBody + "<TD style='font-family:verdana; font-size:12px'><BR>";
            strMailBody = strMailBody + "Dear Valued Customer,<BR><BR>";
            strMailBody = strMailBody + "Thank you for choosing " + sessionStorage.getItem("session_accountname") + ". The attached file contains a copy of your reservation.<BR><BR>";
            strMailBody = strMailBody + "Should you need to make any changes, please notify our office as soon as possible by calling +" + sessionStorage.getItem("session_accountCountryCode") + " " + sessionStorage.getItem("session_accountPhone") + " or " + sessionStorage.getItem("session_accountMobile") + ".<BR><BR>";
            strMailBody = strMailBody + "Please don’t hesitate to contact us if you need further assistance.<BR><BR>";
            strMailBody = strMailBody + "<B>Note:</B> " + this.state.emailNotes + "<BR><BR>";
            strMailBody = strMailBody + "Sincerely,<BR><BR>";
            strMailBody = strMailBody + "<B>" + sessionStorage.getItem("session_accountname") + "</B>";
            strMailBody = strMailBody + "</TD>";
            strMailBody = strMailBody + "</TR>";
            strMailBody = strMailBody + "</TABLE>";
            strMailBody = strMailBody + "</BODY>" + "\r\n";
            strMailBody = strMailBody + "</HTML>" + "\r\n";
        }

        if (this.state.quoteId > 0) {

            strMailBody = strMailBody + "<HTML>" + "\r\n";
            strMailBody = strMailBody + "<HEAD>" + "\r\n";
            strMailBody = strMailBody + "</HEAD>" + "\r\n";
            strMailBody = strMailBody + "<BODY>" + "\r\n";
            strMailBody = strMailBody + "<TABLE>";
            strMailBody = strMailBody + "<TR>";
            strMailBody = strMailBody + "<TD style='font-family:verdana; font-size:12px'><BR>";
            strMailBody = strMailBody + "Dear Valued Customer,<BR><BR>";
            strMailBody = strMailBody + "Thank you for choosing " + sessionStorage.getItem("session_accountname") + ". The attached file contains a copy of your quotation.<BR><BR>";
            strMailBody = strMailBody + "Your Quote Id number is GT" + this.state.quoteId + "<BR>"
            strMailBody = strMailBody + "To view and confirm your ride please click on the Attachment.<BR>"
            strMailBody = strMailBody + "If you have any questions, please feel free to contact our customer service agents.<BR>"
            strMailBody = strMailBody + "Should you need to make any changes, please notify our office as soon as possible by calling +" + sessionStorage.getItem("session_accountCountryCode") + " " + sessionStorage.getItem("session_accountPhone") + " or you may email us at " + sessionStorage.getItem("session_accountEmail") + ".<BR><BR>";
            strMailBody = strMailBody + "Please don’t hesitate to contact us if you need further assistance.<BR><BR>";
            strMailBody = strMailBody + "<B>Note:</B> " + this.state.emailNotes + "<BR>";
            strMailBody = strMailBody + "We thank you for your business!<BR><BR>"
            strMailBody = strMailBody + "Sincerely,<BR><BR>";
            strMailBody = strMailBody + "<B>" + sessionStorage.getItem("session_accountname") + "</B>";
            strMailBody = strMailBody + "</TD>";
            strMailBody = strMailBody + "</TR>";
            strMailBody = strMailBody + "</TABLE>";
            strMailBody = strMailBody + "</BODY>" + "\r\n";
            strMailBody = strMailBody + "</HTML>" + "\r\n";
        }

        return strMailBody;
    }

    onBodyChange = (value) => {
        this.setState({ emailBody: value });

    };

    async getWireInstructionList() {
        var request = new Object();
        request.isListView = false;
        request.accountCustomerSettingWireInstructionId = 0;

        const requestParams = getRequestParams('POST', request);
        const response = await fetch('customer-api/wiringInstruction/' + this.state.customerId, requestParams);
        const data = await response.json();
        this.setState({
            wireInstructionList: data.wiringInstructions
        });
    }

    closeEmailListModal() {
        this.setState({
            isOpenEmailListModal: false,
            isEmailListSubmited: false,
            selectedRowKeys: [],
            emailList: [],
        });
    }

    async handleSearchClick(e) {
        e.preventDefault();

        try {
            this.setState({ loadPanelVisible: true });
            await this.getEmailList();

        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });

    }

    async getEmailList() {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('customer-api/' + this.state.customerId, requestParams);

            const data = await response.json();
            this.setState({
                isOpenEmailListModal: true,
                emailList: data.accountLocationEmail.length > 0 ? data.accountLocationEmail : []
            });
        } catch (e) {
            console.log(e);
        }
    }

    onSelectionChanged({ selectedRowKeys }) {
        this.setState({
            selectedRowKeys: selectedRowKeys
        });
    }

    async handleEmailListSubmit(e) {
        e.preventDefault();
        const selectedRowsData = await this.dataGrid.instance.getDataByKeys(this.state.selectedRowKeys);

        if (selectedRowsData.length <= 0) {
            const isValid = await Swal.fire({
                title: "<small>Email Notification</small>",
                text: "Please select atleast one email.",
                icon: "info",
                dangerMode: false,
                width: "25%",
                dangerMode: false,
                allowOutsideClick: false
            });
            return;
        }
        var emailStr = '';
        for (var i = 0; i < selectedRowsData.length; i++) {
            emailStr = emailStr + selectedRowsData[i].notification + ",";
        }

        emailStr = emailStr.replace(/,\s*$/, "");

        this.setState({
            isOpenEmailListModal: false,
            toEmail: emailStr
        })
    }

    render() {
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' }
            ],
            BLOCK_TYPE_DROPDOWN: [
                { label: 'Normal', style: 'unstyled' },
                { label: 'Heading Large', style: 'header-one' },
                { label: 'Heading Medium', style: 'header-two' },
                { label: 'Heading Small', style: 'header-three' }
            ],
            BLOCK_TYPE_BUTTONS: [
                { label: 'UL', style: 'unordered-list-item' },
                { label: 'OL', style: 'ordered-list-item' }
            ]
        };

        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '45%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form style={this.state.isSubmitted ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Send E-mail</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>To &nbsp;&nbsp;<i style={{ color: '#C04000' }}>( For more than one, enter a comma separated email address )</i></label>
                                        <input type="text" className="form-control form-control-sm"
                                            name="emailTo"
                                            placeholder="example@domain.com"
                                            title="To"
                                            value={this.state.emailTo}
                                            onChange={this.handleOnInputChange} />
                                        {this.state.errors["emailTo"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["emailTo"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" style={{ display: 'none' }}>
                                    <div className="form-group">
                                        <label className="btn-block" style={{ paddingTop: 7 + 'px' }}>&nbsp;</label>
                                        <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleSearchClick}><i className="fas fa-search"></i> Search</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>CC &nbsp;&nbsp;<i style={{ color: '#C04000' }}>( For more than one, enter a comma separated email address )</i></label>
                                        <input type="text" className="form-control form-control-sm"
                                            name="emailCc"
                                            placeholder="example@domain.com"
                                            title="Cc"
                                            value={this.state.emailCc}
                                            onChange={this.handleOnInputChange} />
                                        {this.state.errors["emailCc"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["emailCc"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" style={{ display: (!this.state.isEmailBCc) || 'none' }}>
                                    <div className="form-group">
                                        <label className="btn-block" style={{ paddingTop: 7 + 'px' }}>&nbsp;</label>
                                        <button type="button" className="btn btn-custom-gray btn-sm" onClick={() => this.setState({ isEmailBCc: true })}>
                                            <i className="fas fa-plus"></i> BCC
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ display: this.state.isEmailBCc ? 'flex' : 'none' }}>
                                <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>BCC &nbsp;&nbsp;<i style={{ color: '#C04000' }}>( For more than one, enter a comma separated email address )</i></label>
                                        <input type="text" className="form-control form-control-sm"
                                            name="emailBcc"
                                            placeholder="example@domain.com"
                                            title="Bcc"
                                            value={this.state.emailBcc}
                                            onChange={this.handleOnInputChange} />
                                        {this.state.errors["emailBcc"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["emailBcc"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" style={{ display: (this.state.isEmailBCc) || 'none' }}>
                                    <div className="form-group">
                                        <label className="btn-block" style={{ paddingTop: 7 + 'px' }}>&nbsp;</label>
                                        <button type="button" className="btn btn-custom-gray btn-sm" onClick={() => this.setState({ isEmailBCc: false, emailBcc: '' })}>
                                            <i className="fas fa-minus"></i> Hide
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Subject</label>
                                        <input type="text" className="form-control form-control-sm"
                                            name="emailSubject"
                                            placeholder=""
                                            title="Subject"
                                            value={this.state.emailSubject}
                                            onChange={this.handleOnInputChange} />
                                        {this.state.errors["emailSubject"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["emailSubject"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Mail Priority</label>
                                        <select name="mailPriority" className="form-control form-control-sm"
                                            value={this.state.mailPriority} onChange={this.handleOnInputChange}>
                                            {
                                                mailPriorityDropdownList &&
                                                mailPriorityDropdownList.map((item, i) =>
                                                    (<option key={i} value={item.id}>{item.name}</option>))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4" style={{ display: (this.state.fileNameDisplay.trim().length > 0) || 'none' }}>
                                    <div className="form-group">
                                        <label></label><br />
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                name="isSendAttachment"
                                                checked={this.state.isSendAttachment}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isSendAttachment"> Send Attachment</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>
                                            <span style={{ display: (this.state.fileNameDisplay.trim().length > 0) || 'none' }} >
                                                <i className="fas fa-paperclip">&nbsp;{this.state.fileNameDisplay}</i> <br />
                                            </span> Notes (if required)
                                        </label>
                                        <textarea className="form-control form-control-sm" rows="5" name="emailNotes" placeholder="Enter invoice notes." defaultValue={this.state.emailNotes} maxLength="500" title="Email Note" onChange={this.handleOnInputChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" title="Send" onClick={this.handleOnSendEmail}>
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Send"><i className="fas fa-paper-plane"></i> Send</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleOnClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleOnCloseModal}><i className="fas fa-times"></i> Cancel</button>
                        </div>
                    </form>
                </Modal>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenEmailListModal}
                    style={customStylesEmail}
                    className={"react-modal"}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form>
                        <div className="modal-header">
                            <h6 className="modal-title">Email List</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeEmailListModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="widget-container" style={{ maxHeight: 350 + 'px', overflowY: "auto" }}>
                                <DataGrid
                                    dataSource={this.state.emailList}
                                    id="grid-container"
                                    keyExpr="rowId"
                                    showBorders={true}
                                    selectedRowKeys={this.state.selectedRowKeys}
                                    onSelectionChanged={this.onSelectionChanged}
                                    ref={ref => this.dataGrid = ref}>
                                    <Selection
                                        mode="multiple"
                                        selectAllMode={"allPages"}
                                        showCheckBoxesMode={"always"}
                                    />
                                    <Column dataField="notification" caption="Email" />
                                    <Scrolling mode="infinite" />
                                </DataGrid>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success btn-sm" onClick={this.handleEmailListSubmit}>
                                {this.state.isEmailListSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailListSubmited && <span title="Submit"><i className="fas fa-check"></i> Ok</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeEmailListModal} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        );
    }
}
