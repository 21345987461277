import React, { Component } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { DateFormat } from '../../helpers/fixcodes';
import { LoadPanel } from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import Swal from 'sweetalert2';
import exportFromJSON from 'export-from-json';  //npm i --save export-from-json
import { SendEmailComponent } from '../Shared/SendEmailComponent';

let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class ExportARReceiptComponent extends Component {
    constructor() {
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        super()
        this.state = {
            isSearchSubmitted: false,
            isEmailSubmitted: false,
            isExportToJDE: false,
            isExportToExcel: false,
            isExportToXML: false,
            isExportToPdf: false,
            loadPanelVisible: false,
            isInvoiceReceiptPayment: true,
            isInvoiceAdvancePayment: false,
            invoiceDetailsGridDataSource: null,
            isInvoiceDataFound: false,
            fromDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
            toDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
            errors: {
                fromDate: '',
                toDate: '',
            }
        }
    }
    componentDidMount() { };
    handleOnInputChange = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
            this.dataGrid.instance.repaint();
            switch (name) {
                case 'isInvoiceReceiptPayment':
                    this.setState({
                        isInvoiceReceiptPayment: value,
                        isInvoiceAdvancePayment: false,
                        invoiceDetailsGridDataSource: null,
                        isInvoiceDataFound: false,
                    });
                    break;
                case 'isInvoiceAdvancePayment':
                    this.setState({
                        isInvoiceReceiptPayment: false,
                        isInvoiceAdvancePayment: value,
                        invoiceDetailsGridDataSource: null,
                        isInvoiceDataFound: false,
                    });
                    break;
                default:
                    break;
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnSearchSubmit = async event => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isSearchSubmitted: true, loadPanelVisible: true });
                let fromDate = '';
                let toDate = '';
                if (this.state.fromDate.trim() !== '') {
                    fromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00';
                }
                if (this.state.toDate.trim() !== '') {
                    toDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59'
                }
                let request = new Object();
                request.FromDate = fromDate;
                request.ToDate = toDate;
                const requestParams = getRequestParams('POST', request);
                let url = (this.state.isInvoiceAdvancePayment) ? 'invoice-api/arexport-advance-payments' : 'invoice-api/arexport-only-payments';
                const response = await fetch(url, requestParams);
                const data = await response.json();
                this.setState({
                    invoiceDetailsGridDataSource: data.invoices,
                    isInvoiceDataFound: (data.invoices !== null && data.invoices.length > 0) || false
                });
                this.dataGrid.instance.repaint();
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSearchSubmitted: false, loadPanelVisible: false });
    }
    handleOnRefreshGrid = async () => {
        try {
            this.dataGrid.instance.refresh();
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnToolbarPreparing = async (e) => {
        try {
            let isButtonDisable = !this.state.isInvoiceDataFound;
            e.toolbarOptions.items.unshift({
                location: 'after',
                widget: 'dxButton',
                showText: 'always',
                options: {
                    disabled: isButtonDisable,
                    text: 'Export to JDE',
                    //icon: 'export-excel-button',//'export',
                    //elementAttr: {
                    //    "class": "dx-datagrid-export-button"
                    //},
                    icon: 'export',
                    onClick: this.handleOnExportToJDe.bind(this),
                    hint: 'Export to JDE'
                }
            }, {
                location: 'after',
                widget: 'dxButton',
                showText: 'always',
                options: {
                    disabled: isButtonDisable,
                    text: 'Export to Excel',
                    icon: 'export-excel-button',//'export',
                    elementAttr: {
                        "class": "dx-datagrid-export-button"
                    },
                    onClick: this.handleOnExportToExcel.bind(this),
                    //onClick: function () {
                    //    e.component.option("export.fileName", "InvoicePayments");
                    //    e.component.exportToExcel(false);
                    //},
                    hint: 'Export to Excel'
                }
            }, {
                location: 'after',
                widget: 'dxButton',
                options: {
                    disabled: isButtonDisable,
                    text: 'Export to Xml',
                    icon: 'export',
                    //items: ['pdf', 'xml'],
                    onClick: this.handleOnExportToXML.bind(this),
                    hint: 'Export to Xml'
                }
            }, {
                location: 'after',
                widget: 'dxButton',
                options: {
                    disabled: isButtonDisable,
                    text: 'Export to Pdf',
                    icon: 'exportpdf',
                    onClick: this.handleOnExportToPdf.bind(this),
                    hint: 'Export to Pdf'
                }
            }, {
                location: 'after',
                widget: 'dxButton',
                options: {
                    disabled: isButtonDisable,
                    icon: 'email',
                    onClick: this.handleOnSendEmail.bind(this),
                    hint: 'Email'
                }
            }, {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    onClick: this.handleOnRefreshGrid.bind(this),
                    hint: 'Refresh'
                }
            });
            {
                //const exportBtnItem = e.toolbarOptions.items.find(
                //    item => item.name === "exportButton"
                //);
                //const exportBtnIndex = e.toolbarOptions.items.indexOf(exportBtnItem);
                //e.toolbarOptions.items[exportBtnIndex] = {
                //    location: "after",
                //    locateInMenu: "auto",
                //    // sortIndex: 30,
                //    widget: "dxButton",
                //    options: {
                //        disabled: isButtonDisable,
                //        text: "Export to Excel",
                //        icon: "export",//"export-excel-button",
                //        hint: "Export all data",
                //        elementAttr: {
                //            "class": "dx-datagrid-export-button"
                //        },
                //        onClick: function () {
                //            e.component.exportToExcel(false);
                //        }
                //    }
                //};
                //e.toolbarOptions.items.push({
                //    location: "before",
                //    locateInMenu: "auto",
                //    widget: "dxButton",
                //    options: {
                //        elementAttr: { id: "addButtonName" },
                //        text: "Add",
                //        type: "default",
                //        icon: "plus"
                //    }
                //});
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnClearSearch = async (event) => {
        event.preventDefault();
        try {
            this.setState({
                isSearchSubmitted: false,
                isEmailSubmitted: false,
                isExportToJDE: false,
                isExportToExcel: false,
                isExportToXML: false,
                isExportToPdf: false,
                loadPanelVisible: false,
                isInvoiceReceiptPayment: true,
                isInvoiceAdvancePayment: false,
                invoiceDetailsGridDataSource: null,
                isInvoiceDataFound: false,
                fromDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
                toDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
                errors: {
                    fromDate: '',
                    toDate: '',
                }
            });
            await new Promise(resolve => setTimeout(resolve, 1000)); // 1 sec
            this.dataGrid.instance.repaint();
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnFromDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.fromDate = '';
            let fromDate = '';
            if (event.value !== null && event.value !== '') {
                fromDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.fromDate = 'Please select date.';
            }
            if (this.state.toDate.trim() !== '') {
                errors.toDate = '';
            }
            this.setState({ fromDate: fromDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnToDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.toDate = '';
            let toDate = '';
            if (event.value !== null && event.value !== '') {
                toDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.toDate = 'Please select date.';
            }
            if (this.state.fromDate.trim() !== '') {
                errors.fromDate = '';
            }
            this.setState({ toDate: toDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    isFormValid = async () => {
        let isValid = true;
        let errors = this.state.errors;
        errors.fromDate = '';
        errors.toDate = '';
        try {
            if (this.state.fromDate.trim() === '') {
                errors.fromDate = 'Please select date.';
                isValid = false;
            }
            if (this.state.toDate.trim() === '') {
                errors.toDate = 'Please select date.';
                isValid = false;
            }
            if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                if (moment(this.state.fromDate, 'MM/DD/YYYY').isAfter(this.state.toDate, 'MM/DD/YYYY')) {
                    errors.fromDate = 'Please select a valid date range.';
                    errors.toDate = 'Please select a valid date range.';
                    isValid = false;
                }
            }
            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    handleOnExportToJDe = async (e) => {
        try {
            this.setState({ isExportToJDE: true });
            e.component.option('icon', 'fas fa-spinner fa-spin');
            e.component.option('text', 'In-process');
            e.component.option('hint', 'In-process');
            let fileName = (this.state.isInvoiceAdvancePayment) ? 'InvoiceAdvancePayments' : 'InvoicePaymentReceipt';
            let exportType = 'xls';
            let data = this.state.invoiceDetailsGridDataSource;
            exportFromJSON({ data, fileName, exportType });
        } catch (ex) {
            console.log(ex);
        }
        e.component.option('icon', 'export');
        e.component.option('text', 'Export to JDE');
        e.component.option('hint', 'Export to JDE');
        this.setState({ isExportToJDE: false });
    }
    handleOnExportToExcel = async () => {
        try {
            this.setState({ isExportToExcel: true });
            let fileName = (this.state.isInvoiceAdvancePayment) ? 'InvoiceAdvancePayments' : 'InvoicePaymentReceipt';
            this.dataGrid.instance.option("export.fileName", fileName);
            this.dataGrid.instance.exportToExcel(false);
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isExportToExcel: false });
    }
    handleOnExportToXML = async (e) => {
        try {
            this.setState({ isExportToXML: true });
            e.component.option('icon', 'fas fa-spinner fa-spin');
            e.component.option('text', 'In-process');
            e.component.option('hint', 'In-process');
            let fileName = (this.state.isInvoiceAdvancePayment) ? 'InvoiceAdvancePayments' : 'InvoicePaymentReceipt';
            let exportType = 'xml';
            let data = this.state.invoiceDetailsGridDataSource;
            exportFromJSON({ data, fileName, exportType });
        } catch (ex) {
            console.log(ex);
        }
        e.component.option('icon', 'export');
        e.component.option('text', 'Export to Xml');
        e.component.option('hint', 'Export to Xml');
        this.setState({ isExportToXML: false });
    }
    handleOnExportToPdf = async (e) => {
        try {            
            this.setState({ isExportToPdf: true });
            e.component.option('icon', 'fas fa-spinner fa-spin');
            e.component.option('text', 'In-process');
            e.component.option('hint', 'In-process');
            let fromDate = '';
            let toDate = '';
            if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                fromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00';
                toDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59';
            }
            var request = new Object();
            request.LocationId = Number(sessionStorage.getItem("session_locationId"));
            request.StartDate = fromDate;
            request.EndDate = toDate;
            request.SelectionCrieteria = await this.setSelectionCrieteria();
            request.fileFormat = 'pdf';
            request.MethodName = (this.state.isInvoiceAdvancePayment) ? 'GetARExportInvoiceAdvancePayments' : 'GetARExportInvoiceOnlyPayments';
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            let fileData = dataResponse.reportBase64String;
            if (fileData !== null && fileData !== '') {
                let a = document.createElement('a');
                a.href = fileData;
                a.download = (this.state.isInvoiceAdvancePayment) ? 'InvoiceAdvancePayments.pdf' : 'InvoicePaymentReceipt.pdf';
                a.click();
            } else {
                Swal.fire({
                    allowOutsideClick: false,
                    showCloseButton: true,
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong! Unable to view report.',
                })
            }
        } catch (ex) {
            console.log(ex);
        }
        e.component.option('icon', 'exportpdf');
        e.component.option('text', 'Export to Pdf');
        e.component.option('hint', 'Export to Pdf');
        this.setState({ isExportToPdf: false, loadPanelVisible: false });
    }
    setSelectionCrieteria = async () => {
        let selectionCrieteria = 'Invoice details for';
        try {

            if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                selectionCrieteria += ' date from ' + this.state.fromDate + ' to ' + this.state.toDate;
            }
            else {
                selectionCrieteria += ' all date';
            }
        } catch (ex) {
            selectionCrieteria = '';
            console.log(ex);
        }
        return selectionCrieteria;
    }
    handleOnSendEmail = async (e) => {
        try {
            this.setState({ isEmailSubmitted: true });
            e.component.option('icon', 'fas fa-spinner fa-spin');
            e.component.option('hint', 'In-process');
            let fromDate = '';
            let toDate = '';
            if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                fromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00';
                toDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59';
            }
            var request = new Object();
            request.LocationId = Number(sessionStorage.getItem("session_locationId"));
            request.StartDate = fromDate;
            request.EndDate = toDate;
            request.SelectionCrieteria = await this.setSelectionCrieteria();
            request.fileFormat = 'pdf';
            request.MethodName = (this.state.isInvoiceAdvancePayment) ? 'GetARExportInvoiceAdvancePayments' : 'GetARExportInvoiceOnlyPayments';
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            this.setState({ isEmailSubmitted: false });
            e.component.option('icon', 'email');
            e.component.option('hint', 'Email');
            let fileData = dataResponse.reportBase64String;
            if (fileData !== null && fileData !== '') {
                currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
                let emailSubject = ((this.state.isInvoiceAdvancePayment) ? 'Invoice Advance Payments' : 'Invoice Payment Receipt') + moment(currentTime).format("DD MMMM YYYY HH:mm");
                let attachments = [];
                let file = new Object();
                file.fileData = fileData;
                file.fileName = (this.state.isInvoiceAdvancePayment) ? 'InvoiceAdvancePayments.pdf' : 'InvoicePaymentReceipt.pdf';
                file.fileExtension = '.pdf';
                attachments.push(file);
                this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
            }
            else {
                Swal.fire({
                    allowOutsideClick: false,
                    showCloseButton: true,
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong! Unable to process the email request.',
                })
            }
        } catch (ex) {
            console.log(ex);
        }
        e.component.option('icon', 'email');
        e.component.option('hint', 'Email');
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-sm-6 col-md-6 col-lg-6">
                                <h1 className="m-0 text-dark">Account Receivable Receipt</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem><NavLink tag={Link} to="/billing/export/arinvoice" className="btn btn-block btn-outline-secondary btn-sm" title="Export Invoice" >
                                        <i className="fas fa-file-export"></i> Export Invoice</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/invoice/generate" className="btn btn-block btn-outline-secondary btn-sm" title="Generate Invoice" >
                                        <i className="fas fa-file-invoice"></i> Generate Invoice</NavLink></NavItem>
                                    <NavItem> <NavLink tag={Link} to="/invoice/draft" className="btn btn-block btn-outline-secondary btn-sm" title="Draft Invoice" >
                                        <i className="fas fa-tasks"></i> Draft Invoice</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <form style={
                    (this.state.isSearchSubmitted || this.state.isExportToJDE || this.state.isExportToExcel || this.state.isExportToXML
                        || this.state.isExportToPdf || this.state.isEmailSubmitted
                    ) ?
                        { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="rez-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Paymet Type</label><br />
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isInvoiceReceiptPayment"
                                                checked={this.state.isInvoiceReceiptPayment}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isInvoiceReceiptPayment">Invoice Receipt Payment</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isInvoiceAdvancePayment"
                                                checked={this.state.isInvoiceAdvancePayment}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isInvoiceAdvancePayment">Invoice Advance Payment</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>From Date (Invoice)</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            defaultValue={this.state.fromDate}
                                            value={this.state.fromDate}
                                            onValueChanged={this.handleOnFromDateChange}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["fromDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["fromDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>To Date (Invoice)</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            defaultValue={this.state.toDate}
                                            value={this.state.toDate}
                                            min={this.state.fromDate}
                                            onValueChanged={this.handleOnToDateChange}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["toDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["toDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label className="btn-block">&nbsp;</label>
                                        <button type="button" className="btn btn-success btn-sm" title="Search" onClick={this.handleOnSearchSubmit} >
                                            {this.state.isSearchSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSearchSubmitted && <span title="Search"><i className="fas fa-search"></i> Search</span>}
                                        </button>&nbsp;&nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                            onClick={this.handleOnClearSearch}>
                                            <i className="fas fa-eraser"></i> Clear
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="content">
                    <div className="card">
                        <div className="card-body">
                            <DataGrid
                                ref={(ref) => this.dataGrid = ref}
                                dataSource={this.state.invoiceDetailsGridDataSource}
                                keyExpr="invoiceNo"
                                selection={{ mode: 'single' }}
                                columnsAutoWidth={true}
                                showColumnLines={false}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                showBorders={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                width='100%'
                                onToolbarPreparing={this.handleOnToolbarPreparing}
                            >
                                <Column dataField="invoiceNo" caption="Invoice#" alignment="center" width={'auto'} fixed={true} />
                                <Column dataField="invoiceDate" caption="Inv. Date" alignment="center" width={'auto'} format={DateFormat.DefaultDateTimeFormat} dataType="datetime" />
                                <Column dataField="customerName" caption="Customer"/>
                                <Column dataField="customerCode" caption="Customer Code"/>
                                <Column dataField={this.state.isInvoiceAdvancePayment ? "rideId" : "receiptNo"}
                                    caption={this.state.isInvoiceAdvancePayment ? "Ride Id" : "Receipt No"} alignment="center" width={'auto'} />
                                <Column dataField="transactionDate" caption="Trans. Date" alignment="center" width={'auto'} format={DateFormat.DefaultDateTimeFormat} dataType="datetime" />
                                <Column dataField="tokenId" caption="Token" />
                                <Column dataField="paymentMode" caption="Payment Mode"/>
                                <Column dataField="paidAmount" caption="Paid Amount" alignment="center" width={'auto'} />
                                <Column dataField="processCycleDate" caption="Process Date" alignment="center" width={'auto'} format={DateFormat.DefaultDateTimeFormat} dataType="datetime" />
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={false} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                <Export enabled={false} allowExportSelectedData={false} />
                                <ColumnChooser enabled={false} />
                                <ColumnFixing enabled={false} />
                            </DataGrid>
                        </div>
                    </div>
                </div>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}