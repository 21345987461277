import React, { Component } from 'react';
import DataGrid, {
    Column, Grouping, GroupPanel, Pager, Paging, SearchPanel, Export, SortByGroupSummaryInfo, ColumnChooser, ColumnFixing, HeaderFilter,
    Button
} from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import Modal from 'react-modal'; //npm install --save react-modal

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { getRequestParams, formatDate} from '../../helpers/globalfunctions.js';
import { SelectBox } from 'devextreme-react/select-box';
import NumberFormat from 'react-number-format';
import { ModuleCode, CustomerType } from '../../helpers/fixcodes.js'
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react/load-panel';

const customStyles = {
    content: {
        width: '50%'
    }
};
let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class AreaWiseTaxApplicableComponent extends Component {
    constructor(props) {
        super(props);
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        this.state = {
            isSubmited: false,
            locationList: [],
            locationId: null,
            taxTypeList: [],
            taxTypeId: null,
            serviceAreaList: [],
            mappedServiceAreaList: [],
            selectServiceAreaList: "",
            selectMapServiceAreaList: "",
            errors: {
                locationId: '',
                taxTypeId: '',
                mappedServiceAreaList: ''
            }
        }
        this.LocationList = this.LocationList.bind(this);
        this.getTaxTypeList = this.getTaxTypeList.bind(this);
        this.getServiceAreaList = this.getServiceAreaList.bind(this);
        this.getMappedServiceAreaList = this.getMappedServiceAreaList.bind(this);
        this.handletaxTypeInputChange = this.handletaxTypeInputChange.bind(this);
        this.handleAreaWiseTaxApplicableSubmit = this.handleAreaWiseTaxApplicableSubmit.bind(this);
        this.handleLocationInputChange = this.handleLocationInputChange.bind(this);
    }
    componentDidMount = () => {
        this.LocationList();
        this.getTaxTypeList();
        this.getServiceAreaList();
    }

    async LocationList() {
        let locationId = parseInt(sessionStorage.getItem("session_locationId"));
        let locationName = sessionStorage.getItem("session_locationName");

        const requestParams = getRequestParams('GET', '');
        const response = await fetch('account-api/locations', requestParams);
        const data = await response.json();
        if (data !== null && data.locations.length > 0) {
            this.setState({
                locationList: data.locations,
                locationId: { locationId: locationId, locationName: locationName }
            });
        }
    }
    async getTaxTypeList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('tax-api', requestParams);
        const data = await response.json();
        if (data !== null && data.taxDropdownList.length > 0) {
            this.setState({
                taxTypeList: data.taxDropdownList,
                taxTypeId: data.taxDropdownList[0]
            });
            this.getMappedServiceAreaList(data.taxDropdownList[0].id);
        }
    }

    async getServiceAreaList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('serviceArea-api/0', requestParams);
        const data = await response.json();
        if (data !== null && data.locationServiceArea.length > 0) {
            this.setState({
                serviceAreaList: data.locationServiceArea.filter(x => x.enable === true)
            });
        }
    }

    async getMappedServiceAreaList(taxid) {
        let locationId = this.state.locationId !== null && this.state.locationId.length > 0 ? this.state.locationId.locationId : 0;
        let taxId = taxid;
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('area-wise-tax-applicable-api/getMappedServiceArea/' + locationId + '/0/0/' + taxId, requestParams);
        const data = await response.json();
        if (data !== null && data.affAreaWiseTaxApplicable.length > 0) {
            this.setState({
                mappedServiceAreaList: data.affAreaWiseTaxApplicable
            });
            this.RemoveDuplicate();
        } else {
            this.setState({
                mappedServiceAreaList: []
            });
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = Array.from(event.target.selectedOptions, option => option.value);
        const name = target.name;
        this.setState({
            [name]: value
        });
    }
    handleLocationInputChange(selectedOption) {
        this.setState({ locationId: (selectedOption.selectedItem != null ? selectedOption.selectedItem : null)})
        this.state.errors["locationId"] = "";
    }
   
    async handletaxTypeInputChange(data) {
       
        if (data.selectedItem === null) {
            this.setState({ taxTypeId: null })
            this.state.errors["taxTypeId"] = "Please select tax type.";
        }
        else {
            this.setState({ taxTypeId: data.selectedItem })
            await this.getServiceAreaList();
            this.getMappedServiceAreaList(data.selectedItem.id);
            this.state.errors["taxTypeId"] = "";
        }
    }

    AddServiceAreaControl = () => {
        var mappedServiceArea = this.state.selectServiceAreaList;
        const finalServiceAreamap = this.state.mappedServiceAreaList.slice();
        if (mappedServiceArea.length > 0) {
            for (var i = 0; i < mappedServiceArea.length; i++) {
                const addfilteredItems = this.state.serviceAreaList.splice(this.state.serviceAreaList.findIndex(x => x.id === Number(mappedServiceArea[i] != null ? mappedServiceArea[i] : "0")), 1)
                finalServiceAreamap.push(addfilteredItems[0]);
            }
            this.setState({ mappedServiceAreaList: finalServiceAreamap })
            for (var j = 0; j < mappedServiceArea.length; j++) {
                const removeData = this.state.serviceAreaList.filter(item => item.id !== Number(mappedServiceArea[j] != null ? mappedServiceArea[j] : "0"))
                this.setState({ serviceAreaList: removeData })
            }
            this.setState({ selectServiceAreaList: "" });
        }
        else {
            Swal.fire({
                icon: 'warning',
                title: 'Area wise tax applicable',
                text: "Please select service area",
            })
        }
    }

    RemoveServiceAreaControl = () => {
        var removeMapServiceArea = this.state.selectMapServiceAreaList;
        const finalUnMapServiceArea = this.state.serviceAreaList.slice();
        if (removeMapServiceArea.length > 0) {
            for (var i = 0; i < removeMapServiceArea.length; i++) {
                const addfilteredItems = this.state.mappedServiceAreaList.splice(this.state.mappedServiceAreaList.findIndex(x => x.id === Number(removeMapServiceArea[i] != null ? removeMapServiceArea[i] : "0")), 1)

                finalUnMapServiceArea.push(addfilteredItems[0]);

            }
            this.setState({ serviceAreaList: finalUnMapServiceArea })
            for (var j = 0; j < removeMapServiceArea.length; j++) {
                const removeData = this.state.mappedServiceAreaList.filter(item => item.id !== Number(removeMapServiceArea[j] != null ? removeMapServiceArea[j] : "0"))
                this.setState({ mappedServiceAreaList: removeData })
            }
            this.setState({ selectMapServiceAreaList: "" });
        }
        else {
            Swal.fire({
                icon: 'warning',
                title: 'Area wise tax applicable',
                text: "Please select service area",
            })
        }
    }

    RemoveDuplicate = () => {
        let mappedlist = this.state.mappedServiceAreaList;
        let unmappedlist = this.state.serviceAreaList;
        for (let i = 0; i < mappedlist.length; i++) {
            unmappedlist = unmappedlist.filter(x => x.id !== mappedlist[i].id)
        }
        this.setState({ serviceAreaList: unmappedlist });
    }

    handleValidation = () => {
        let errors = this.state.errors;
        errors.locationId = '';
        errors.taxTypeId = '';
        errors.mappedServiceAreaList = '';

        let formIsValid = true;
        if (this.state.locationId === null || this.state.locationId.locationId === 0) {
            formIsValid = false;
            errors.locationId = "Please select location.";
        }
        if (this.state.taxTypeId === null || this.state.taxTypeId.id === 0) {
            formIsValid = false;
            errors.taxTypeId = "Please select location.";
        }
        if (this.state.mappedServiceAreaList.length === 0) {
            formIsValid = false;
            errors.mappedServiceAreaList = "Please select mapped service area.";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    async handleAreaWiseTaxApplicableSubmit(e) {
        this.setState({ isSubmited: true })
        e.preventDefault();
        if (this.state.mappedServiceAreaList.length > 0) {

        }
        else {
            Swal.fire({
                icon: 'warning',
                title: 'Area wise tax applicable',
                text: "Please select service area",
            })
        }
        if (this.handleValidation()) {
            let SelectedServiceAreaId = [];
            for (let i = 0; i < this.state.mappedServiceAreaList.length; i++) {
                SelectedServiceAreaId.push(parseInt(this.state.mappedServiceAreaList[i].id))
            }

            var newObject = new Object();
            newObject.LocationId = this.state.locationId.locationId;
            newObject.AffAreaWiseTaxApplicable = new Object();
            newObject.AffAreaWiseTaxApplicable.RowId = 0;
            newObject.AffAreaWiseTaxApplicable.LocationId = this.state.locationId.locationId;
            newObject.AffAreaWiseTaxApplicable.LocationName = this.state.locationId.locationName;
            newObject.AffAreaWiseTaxApplicable.TaxId = this.state.taxTypeId.id;
            newObject.AffAreaWiseTaxApplicable.TaxName = this.state.taxTypeId.name;
            newObject.AffAreaWiseTaxApplicable.IsActive = true;
            newObject.AffAreaWiseTaxApplicable.CreatedBy = parseInt(sessionStorage.getItem("session_userId"));
            newObject.AffAreaWiseTaxApplicable.UpdatedBy = parseInt(sessionStorage.getItem("session_userId"));
            newObject.AffAreaWiseTaxApplicable.SelectedServiceAreaId = SelectedServiceAreaId;

            const requestParams = getRequestParams('POST', newObject);
            const response = await fetch('area-wise-tax-applicable-api/SaveUpdate', requestParams);
            const dataResult = await response.json();
            Swal.fire({
                icon: dataResult.isSuccess ? 'success' : 'error',
                title: dataResult.isSuccess ? 'Success' : 'Oops...',
                text: dataResult.resultMessage,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (dataResult.isSuccess) {
                    if (result.isConfirmed) {
                        this.LocationList();
                        this.getTaxTypeList();
                        this.getServiceAreaList();
                        this.getMappedServiceAreaList(this.state.taxTypeId.id);
                        this.setState({ isSubmited: false })
                    }
                } else {
                    this.setState({ isSubmited: false })
                }
            })
        } else {
            this.setState({ isSubmited: false })
        }
    }
    render() {
        return (<div className="content-wrapper">
            <div className="content-header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <h1 className="m-0 text-dark">Area wise tax applicable</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="card card-grid">
                    <div className="card-body">
                        <form method="post" onSubmit={this.handleAreaWiseTaxApplicableSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>Tax Type</label>
                                        <SelectBox
                                            dataSource={this.state.taxTypeList.length > 0 ? this.state.taxTypeList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select taxtype"
                                            name="taxTypeId"
                                            defaultValue={0}
                                            value={this.state.taxTypeId != null ? this.state.taxTypeId.id : 0}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handletaxTypeInputChange}
                                            showClearButton={false}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["taxTypeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["taxTypeId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>Location</label>
                                        <SelectBox
                                            dataSource={this.state.locationList.length > 0 ? this.state.locationList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select location"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.locationId != null ? this.state.locationId.locationId : 0}
                                            displayExpr='locationName'
                                            valueExpr='locationId'
                                            onSelectionChanged={this.handleLocationInputChange}
                                            showClearButton={false}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["locationId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["locationId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <br />
                                    <button type="submit" className="btn btn-success btn-sm" onClick={this.handleAreaWiseTaxApplicableSubmit} >
                                        {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                        {!this.state.isSubmited && <span title="Save"><i className="fas fa-save"></i> Save</span>}
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-5 col-sm-5 col-md-5 col-lg-5">
                                    <div className="form-group">
                                        <label>Select Service Area</label>
                                        <select name="selectServiceAreaList" className="form-control" multiple="true" style={{ width: "100%", height: "200px", margin: "0px 2px 0px 3px", }} value={this.state.selectServiceAreaList} onChange={this.handleInputChange}>
                                            {
                                                this.state.serviceAreaList &&
                                                this.state.serviceAreaList.map((item, i) =>
                                                    (<option key={i} value={item.id}>{item.name}</option>))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 text-center">
                                    <br /><br /><br /><a onClick={() => this.AddServiceAreaControl()} id="addPop" className="btn btn-custom-gray" ><i className="fas fa-chevron-right" /></a><br /><br />
                                    <a onClick={() => this.RemoveServiceAreaControl()} id="removePop" className="btn btn-custom-gray"><i className="fas fa-chevron-left" /></a>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label>Mapped Service Area</label>
                                        <select name="selectMapServiceAreaList" className="form-control" multiple="true" style={{ width: "100%", height: "200px", margin: "0px 2px 0px 3px", }} value={this.state.selectMapServiceAreaList} onChange={this.handleInputChange}>
                                            {
                                                this.state.mappedServiceAreaList &&
                                                this.state.mappedServiceAreaList.map((item, i) =>
                                                    (<option key={i} value={item.id}>{item.name}</option>))
                                            }
                                        </select>
                                        {this.state.errors["mappedServiceAreaList"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["mappedServiceAreaList"]}</span>}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>)
    }
}