import DataGrid, {
    Column,
    FilterRow, Paging, Scrolling, Selection
} from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import DropDownBox from 'devextreme-react/drop-down-box';
import { SelectBox } from 'devextreme-react/select-box';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { getRequestParams } from '../../helpers/globalfunctions.js';
const stylevehicleheight = {
    height: '350px'
}

const styledeviceheight = {
    height: '200px'
}

const styleginfoheight = {
    height: '350px'
}

const stylegserviceheight = {
    height: '250px'
}

export const locationairports = [];
export const vehicleTypes = [];
export var vehicleTypes1 = [];
export var vehicleTypes2 = [];
export var vehicleTypes3 = [];
export var vehicleTypes4 = [];
export var vehicleTypes5 = [];
export const selVehicleTypeList = [];

class Steps {
    constructor(wizard) {
        this.wizard = wizard;
        this.steps = this.getSteps();
        this.stepsQuantity = this.getStepsQuantity();
        this.currentStep = 0;
    }

    setCurrentStep(currentStep) {
        this.currentStep = currentStep;
    }

    getSteps() {
        return this.wizard.getElementsByClassName('step');
    }

    getStepsQuantity() {
        return this.getSteps().length;
    }

    handleConcludeStep() {
        if (window.ParentComponent.handleStep4Validation() == true) {
            this.steps[this.currentStep].classList.add('-completed');

            var locationIdList = [];
            var vehicleTypeIdList = [];
            var serviceAirports = [];
            var vehicleList = [];
            var deviceList = [];

            var AccountSetupRequest = new Object();

            AccountSetupRequest.MachineIp = "Web";
            AccountSetupRequest.Enable = true;
            AccountSetupRequest.TransferTypeId = 1;
            AccountSetupRequest.ServiceTypeId = 2;

            //debugger;
            AccountSetupRequest.LocationIdList = new Object();
            for (let j = 0; j < window.ParentComponent.state.serviceLocations.length; j++) {
                locationIdList.push(window.ParentComponent.state.serviceLocations[j]);
            }
            AccountSetupRequest.LocationIdList = locationIdList;
            //debugger;

            AccountSetupRequest.VehicleTypeIdList = new Object();
            for (let j = 0; j < window.ParentComponent.state.serviceVehicleType.length; j++) {
                vehicleTypeIdList.push(parseInt(window.ParentComponent.state.serviceVehicleType[j]));
            }
            AccountSetupRequest.VehicleTypeIdList = vehicleTypeIdList;
            //debugger;

            AccountSetupRequest.Airports = new Object();
            for (let j = 0; j < window.ParentComponent.state.serviceAirports.length; j++) {
                var row = new Object();            
                const airportLocation = window.ParentComponent.state.airportList.find(f => f.airportId === window.ParentComponent.state.serviceAirports[j]);
                row.LocationId = parseInt(airportLocation.locationId);
                row.AirportId = parseInt(airportLocation.airportId);
                serviceAirports.push(row);
            }
            AccountSetupRequest.Airports = serviceAirports;
            //debugger;

            AccountSetupRequest.Vehicles = new Object();
            for (let j = 1; j <= window.ParentComponent.state.selectedVehicleCount; j++) {
                var row = new Object();
                row.VehicleNo = document.getElementById("vehicleName" + j).value;
                switch (j) {
                    case 1:
                        row.VehicleTypeId = parseInt(window.ParentComponent.state.serviceVehicleType1);
                        break;
                    case 2:
                        row.VehicleTypeId = parseInt(window.ParentComponent.state.serviceVehicleType2);
                        break;
                    case 3:
                        row.VehicleTypeId = parseInt(window.ParentComponent.state.serviceVehicleType3);
                        break;
                    case 4:
                        row.VehicleTypeId = parseInt(window.ParentComponent.state.serviceVehicleType4);
                        break;
                    case 5:
                        row.VehicleTypeId = parseInt(window.ParentComponent.state.serviceVehicleType5);
                        break;
                }
                row.Vin = "";
                row.DOTNumber = "";
                row.PlateNo = document.getElementById("licensePlateNumber" + j).value;
                row.VehicleSeats = parseInt(document.getElementById("numberOfSeats" + j).value);
                vehicleList.push(row);
            }
            AccountSetupRequest.Vehicles = vehicleList;
            //debugger;

            AccountSetupRequest.Devices = new Object();
            for (let j = 1; j <= window.ParentComponent.state.selectedDeviceCount; j++) {
                var row = new Object();
                row.CountryCode = document.getElementById("countryCode" + j).value;
                row.MobileNo = document.getElementById("mobile" + j).value;
                deviceList.push(row);
            }
            AccountSetupRequest.Devices = deviceList;
            //debugger;

            AccountSetupRequest.AirfieldSettings = new Object();
            AccountSetupRequest.AirfieldSettings.AirfieldMaxPaxPerBus = 0;
            AccountSetupRequest.AirfieldSettings.AirfieldMaxAdaPerEmptyBus = 0;
            AccountSetupRequest.AirfieldSettings.AirfieldDepCrewMinAvdMinutes = 0;
            AccountSetupRequest.AirfieldSettings.AirfieldDepPaxMinAvdMinutes = 0;
            AccountSetupRequest.AirfieldSettings.AirfieldArrCrewMinAvdMinutes = 0;
            AccountSetupRequest.AirfieldSettings.AirfieldArrPaxMinAvdMinutes = 0;

            AccountSetupRequest.SaveGeneralInformation = new Object();
            AccountSetupRequest.SaveGeneralInformation.TimeZoneId = parseInt(window.ParentComponent.state.timezoneId);
            AccountSetupRequest.SaveGeneralInformation.CurrencyId = parseInt(window.ParentComponent.state.currencyId);
            AccountSetupRequest.SaveGeneralInformation.DoTripDuration = parseInt(window.ParentComponent.state.doTripDuration);
            AccountSetupRequest.SaveGeneralInformation.DistanceUnitType = parseInt(window.ParentComponent.state.distanceUnit);
            AccountSetupRequest.SaveGeneralInformation.NonAirportTransfer = window.ParentComponent.state.nonAirportTransfer;
            AccountSetupRequest.SaveGeneralInformation.DomesticAirportTransfer = window.ParentComponent.state.airportTransferDomestic;
            AccountSetupRequest.SaveGeneralInformation.InternationalAirportTransfer = window.ParentComponent.state.airportTransferInternational;

            window.ParentComponent.setState({ setupRequest: AccountSetupRequest })
            window.ParentComponent.saveAccountSetup();
        }
    }

    handleStepsClasses(movement) {
        if (movement > 0)
            this.steps[this.currentStep - 1].classList.add('-completed');
        else if (movement < 0)
            this.steps[this.currentStep].classList.remove('-completed');
    }
}

class Panels {
    constructor(wizard) {
        this.wizard = wizard;
        this.panelWidth = this.wizard.offsetWidth;
        this.panelsContainer = this.getPanelsContainer();
        this.panels = this.getPanels();
        this.currentStep = 0;
        this.previousStep = false;

        this.updatePanelsPosition(this.currentStep);
        this.updatePanelsContainerHeight();
    }

    getCurrentPanelHeight() {
        return `${this.getPanels()[this.currentStep].offsetHeight}px`;
    }

    getPanelsContainer() {
        return this.wizard.querySelector('.panels');
    }

    getPanels() {
        return this.wizard.getElementsByClassName('panel');
    }

    updatePanelsContainerHeight() {
        this.panelsContainer.style.height = this.getCurrentPanelHeight();
    }

    updatePanelsPosition(currentStep) {
        const panels = this.panels;
        const panelWidth = this.panelWidth;

        for (let i = 0; i < panels.length; i++) {
            panels[i].classList.remove(
                'movingIn',
                'movingOutBackward',
                'movingOutFoward'
            );

            if (i !== currentStep) {
                if (i < currentStep) panels[i].classList.add('movingOutBackward');
                else if (i > currentStep) panels[i].classList.add('movingOutFoward');
            } else {
                panels[i].classList.add('movingIn');
            }
        }

        this.updatePanelsContainerHeight();
    }

    setCurrentStep(currentStep) {
        this.currentStep = currentStep;
        this.updatePanelsPosition(currentStep);
    }
}

class Wizard {
    constructor(obj) {
        this.wizard = obj;
        this.panels = new Panels(this.wizard);
        this.steps = new Steps(this.wizard);
        this.stepsQuantity = this.steps.getStepsQuantity();
        this.currentStep = this.steps.currentStep;

        this.concludeControlMoveStepMethod = this.steps.handleConcludeStep.bind(this.steps);
        this.wizardConclusionMethod = this.handleWizardConclusion.bind(this);
    }

    updateButtonsStatus() {
        if (this.currentStep === 0)
            this.previousControl.classList.add('disabled');
        else
            this.previousControl.classList.remove('disabled');
    }

    updtadeCurrentStep(movement) {
        this.currentStep += movement;
        this.steps.setCurrentStep(this.currentStep);
        this.panels.setCurrentStep(this.currentStep);

        this.handleNextStepButton();
        this.updateButtonsStatus();
    }

    handleNextStepButton() {
        if (this.currentStep === this.stepsQuantity - 1) {
            this.nextControl.innerHTML = '<i className="fas fa-check"></i>&nbsp;' + 'Finish!';

            this.nextControl.removeEventListener('click', this.nextControlMoveStepMethod);
            this.nextControl.addEventListener('click', this.concludeControlMoveStepMethod);
            this.nextControl.addEventListener('click', this.wizardConclusionMethod);
        } else {
            this.nextControl.innerHTML = 'Next' + '&nbsp;<i className="fas fa-chevron-right"></i>';

            this.nextControl.addEventListener('click', this.nextControlMoveStepMethod);
            this.nextControl.removeEventListener('click', this.concludeControlMoveStepMethod);
            this.nextControl.removeEventListener('click', this.wizardConclusionMethod);
        }
    }

    async handleWizardConclusion() {

    };

    addControls(previousControl, nextControl) {
        this.previousControl = previousControl;
        this.nextControl = nextControl;
        this.previousControlMoveStepMethod = this.moveStep.bind(this, -1);
        this.nextControlMoveStepMethod = this.moveStep.bind(this, 1);

        previousControl.addEventListener('click', this.previousControlMoveStepMethod);
        nextControl.addEventListener('click', this.nextControlMoveStepMethod);

        this.updateButtonsStatus();
    }

    moveStep(movement) {
        const backMov = movement < 0 && this.currentStep > 0;
        this.previousStep = backMov;

        if (this.previousStep == true) {
            if (this.validateMovement(movement)) {
                this.updtadeCurrentStep(movement);
                this.steps.handleStepsClasses(movement);
            } else {
                throw ('This was an invalid movement');
            }
        }
        else if (this.currentStep == 0) {
            if (window.ParentComponent.handleStep1Validation() == true) {
                if (this.validateMovement(movement)) {
                    this.updtadeCurrentStep(movement);
                    this.steps.handleStepsClasses(movement);
                } else {
                    throw ('This was an invalid movement');
                }
            }
        }
        else if (this.currentStep == 1) {
            if (window.ParentComponent.handleStep2Validation() == true) {
                if (this.validateMovement(movement)) {
                    this.updtadeCurrentStep(movement);
                    this.steps.handleStepsClasses(movement);
                } else {
                    throw ('This was an invalid movement');
                }
            }
        }
        else if (this.currentStep == 2) {
            if (window.ParentComponent.handleStep3Validation() == true) {
                if (this.validateMovement(movement)) {
                    this.updtadeCurrentStep(movement);
                    this.steps.handleStepsClasses(movement);
                } else {
                    throw ('This was an invalid movement');
                }
            }
        }
        //else if (this.currentStep == 3) {
        //    if (window.ParentComponent.handleStep4Validation() == true) {
        //        if (this.validateMovement(movement)) {
        //            this.updtadeCurrentStep(movement);
        //            this.steps.handleStepsClasses(movement);
        //        } else {
        //            throw ('This was an invalid movement');
        //        }
        //    }
        //}
    }

    validateMovement(movement) {
        const fowardMov = movement > 0 && this.currentStep < this.stepsQuantity - 1;
        const backMov = movement < 0 && this.currentStep > 0;
        return fowardMov || backMov;
    }
}

export class AccountSetupComponent extends Component {
    static displayName = AccountSetupComponent.name;

    constructor(props) {
        super(props);
        const queryString = require('query-string');
        var parsed = queryString.parse(this.props.location.search);
        const ngid = parsed.ngid;

        this.state = {
            isVehicleRowDisabled: true,
            isDeviceRowDisabled: true,
            newaccountId: ngid,
            isSetupCompleted: false,
            timezone: '',
            currency: '',
            doTripDuration: '',
            distanceUnit: '',
            nonAirportTransfer: '',
            airportTransferDomestic: '',
            airportTransferInternational: '',
            serviceLocations: [],
            serviceAirports: [],
            serviceVehicleType: [],
            serviceVehicleType1: [],
            serviceVehicleType2: '',
            serviceVehicleType3: '',
            serviceVehicleType4: '',
            serviceVehicleType5: '',
            defaultAccountSettings: [],

            vehicleName1: '',
            vehicleName2: '',
            vehicleName3: '',
            vehicleName4: '',
            vehicleName5: '',
            licensePlateNumber1: '',
            licensePlateNumber2: '',
            licensePlateNumber3: '',
            licensePlateNumber4: '',
            licensePlateNumber5: '',
            errVehicleType1: '',
            errVehicleType2: '',
            errVehicleType3: '',
            errVehicleType4: '',
            errVehicleType5: '',
            numberOfSeats1: '',
            numberOfSeats2: '',
            numberOfSeats3: '',
            numberOfSeats4: '',
            numberOfSeats5: '',
            countryCode1: '',
            countryCode2: '',
            countryCode3: '',
            countryCode4: '',
            countryCode5: '',
            mobile1: '',
            mobile2: '',
            mobile3: '',
            mobile4: '',
            mobile5: '',
            vehicleSeats1: 0,
            vehicleSeats2: 0,
            vehicleSeats3: 0,
            vehicleSeats4: 0,
            vehicleSeats5: 0,

            isMobile1Validation: false,
            isMobile2Validation: false,
            isMobile3Validation: false,
            isMobile4Validation: false,
            isMobile5Validation: false,

            vehicleName1: '',
            vehicleName2: '',
            vehicleName3: '',
            vehicleName4: '',
            vehicleName5: '',
            licensePlateNumber1: '',
            licensePlateNumber2: '',
            licensePlateNumber3: '',
            licensePlateNumber4: '',
            licensePlateNumber5: '',
            numberOfSeats1: '',
            numberOfSeats2: '',
            numberOfSeats3: '',
            numberOfSeats4: '',
            numberOfSeats5: '',

            countryCode1: '1',
            countryCode2: '1',
            countryCode3: '1',
            countryCode4: '1',
            countryCode5: '1',
            mobile1: '',
            mobile2: '',
            mobile3: '',
            mobile4: '',
            mobile5: '',

            timezoneId: 0,
            currencyId: 0,

            gridBoxVehicleTypeValue: [],
            gridBoxVehicleTypeValue1: [],
            gridBoxVehicleTypeValue2: [],
            gridBoxVehicleTypeValue3: [],
            gridBoxVehicleTypeValue4: [],
            gridBoxVehicleTypeValue5: [],

            vinNumber: '',
            mobile: '',
            countryId: 0,
            timezoneList: [],
            currencyList: [],
            distanceUnitList: [],
            locationList: [],
            airportList: [],
            vehicleTypeList: [],
            vehicleTypeList1: '',
            vehicleTypeList2: '',
            vehicleTypeList3: '',
            vehicleTypeList4: '',
            vehicleTypeList5: '',
            selectionMode: 'all',
            selectAllMode: 'page',
            selectedItemKeys: [],
            selectedServiceLocation: '',
            selectedVehicleCount: 1,
            selectedDeviceCount: 1,
            setupRequest: null,
            message: '',
            text: "Initial State",
            errors: {
                timezone: '',
                currency: '',
                doTripDuration: '',
                distanceUnit: '',
                nonAirportTransfer: '',
                airportTransferDomestic: '',
                airportTransferInternational: '',
                serviceLocations: '',
                serviceAirports: '',
                serviceVehicleType: '',
                serviceVehicleType1: '',
                serviceVehicleType2: '',
                serviceVehicleType3: '',
                serviceVehicleType4: '',
                serviceVehicleType5: '',
                timezoneId: 0,
                vehicleName1: '',
                vehicleName2: '',
                vehicleName3: '',
                vehicleName4: '',
                vehicleName5: '',
                licensePlateNumber1: '',
                licensePlateNumber2: '',
                licensePlateNumber3: '',
                licensePlateNumber4: '',
                licensePlateNumber5: '',
                errVehicleType1: '',
                errVehicleType2: '',
                errVehicleType3: '',
                errVehicleType4: '',
                errVehicleType5: '',
                numberOfSeats1: '',
                numberOfSeats2: '',
                numberOfSeats3: '',
                numberOfSeats4: '',
                numberOfSeats5: '',
                countryCode1: '',
                countryCode2: '',
                countryCode3: '',
                countryCode4: '',
                countryCode5: '',
                mobile1: '',
                mobile2: '',
                mobile3: '',
                mobile4: '',
                mobile5: '',

                vehicleName1: '',
                vehicleName2: '',
                vehicleName3: '',
                vehicleName4: '',
                vehicleName5: '',
                licensePlateNumber1: '',
                licensePlateNumber2: '',
                licensePlateNumber3: '',
                licensePlateNumber4: '',
                licensePlateNumber5: '',
                numberOfSeats1: '',
                numberOfSeats2: '',
                numberOfSeats3: '',
                numberOfSeats4: '',
                numberOfSeats5: '',

                countryCode1: '',
                countryCode2: '',
                countryCode3: '',
                countryCode4: '',
                countryCode5: '',
                mobile1: '',
                mobile2: '',
                mobile3: '',
                mobile4: '',
                mobile5: '',

                vinNumber: '',
                mobile: '',
            }
        };

        window.ParentComponent = this;
        this.selectedServiceLocation = window.servicelocation;

        this.handleInputChangeStep1 = this.handleInputChangeStep1.bind(this);
        this.handleInputChangeStep3 = this.handleInputChangeStep3.bind(this);
        this.handleInputChangeStep4 = this.handleInputChangeStep4.bind(this);
        this.handleVehicleChange = this.handleVehicleChange.bind(this);
        this.handleDeviceChange = this.handleDeviceChange.bind(this);

        this.onSelectionModeChange = this.onSelectionModeChange.bind(this);
        this.onSelectAllModeChange = this.onSelectAllModeChange.bind(this);
        this.onSelectedItemKeysChange = this.onSelectedItemKeysChange.bind(this);

        this.handleMobileValidation = this.handleMobileValidation.bind(this);

        this.getDefaultSettings = this.getDefaultSettings.bind(this);
        this.getTimezone = this.getTimezone.bind(this);
        this.getCurrency = this.getCurrency.bind(this);
        this.getDistanceUnit = this.getDistanceUnit.bind(this);
        this.getAirports = this.getAirports.bind(this);
        this.getLocations = this.getLocations.bind(this);
        this.getVehicleType = this.getVehicleType.bind(this);
        this.removeLastVehicle = this.removeLastVehicle.bind(this);
        this.removeLastDevice = this.removeLastDevice.bind(this);
        this.saveAccountSetup = this.saveAccountSetup.bind(this);

        this.handleTimezoneChange = this.handleTimezoneChange.bind(this);
        this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
        this.handleDistanceunitChange = this.handleDistanceunitChange.bind(this);
        this.handleVehicleTypeChange = this.handleVehicleTypeChange.bind(this);
        this.handleVehicleType1Change = this.handleVehicleType1Change.bind(this);
        this.handleVehicleType2Change = this.handleVehicleType2Change.bind(this);
        this.handleVehicleType3Change = this.handleVehicleType3Change.bind(this);
        this.handleVehicleType4Change = this.handleVehicleType4Change.bind(this);
        this.handleVehicleType5Change = this.handleVehicleType5Change.bind(this);

        this.submitLogin = this.submitLogin.bind(this);

        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.syncDataGridSelection = this.syncDataGridSelection.bind(this);
        this.dataGridRender = this.dataGridRender.bind(this);

        this.onSelectionAirportChanged = this.onSelectionAirportChanged.bind(this);
        this.syncDataGridAirportSelection = this.syncDataGridAirportSelection.bind(this);
        this.dataGridAirportRender = this.dataGridAirportRender.bind(this);

        this.syncDataGridVehicleTypeSelection = this.syncDataGridVehicleTypeSelection.bind(this);
        this.dataGridVehicleTypeRender = this.dataGridVehicleTypeRender.bind(this);
    }

    async componentDidMount() {
        await this.getDefaultSettings();
        await this.getDistanceUnit();
        await this.getLocations();
        await this.getVehicleType();

        let wizardElement = document.getElementById('wizard');
        let wizard = new Wizard(wizardElement);
        let buttonNext = document.querySelector('.next');
        let buttonPrevious = document.querySelector('.previous');

        wizard.addControls(buttonPrevious, buttonNext);
    }

    handleTimezoneChange(selectedValue) {
        this.setState({ timezoneId: (selectedValue.selectedItem != null ? selectedValue.selectedItem.id : 0), })
        this.state.errors["timezone"] = "";
    }

    handleCurrencyChange(selectedValue) {
        this.setState({ currencyId: (selectedValue.selectedItem != null ? selectedValue.selectedItem.currencyId : 0), })
        this.state.errors["currency"] = "";
    }

    handleDistanceunitChange(selectedValue) {
        this.setState({ distanceUnit: (selectedValue.selectedItem != null ? selectedValue.selectedItem.id : 0), })
        this.state.errors["distanceUnit"] = "";
    }

    handleVehicleTypeChange(selectedValue) {
        vehicleTypes1 = selectedValue.selectedRowsData;
        vehicleTypes2 = selectedValue.selectedRowsData;
        vehicleTypes3 = selectedValue.selectedRowsData;
        vehicleTypes4 = selectedValue.selectedRowsData;
        vehicleTypes5 = selectedValue.selectedRowsData;

        this.setState({
            //vehicleTypeList: (selectedValue.selectedRowKeys != null ? selectedValue.selectedRowKeys : []),
            serviceVehicleType: selectedValue.selectedRowKeys.length && selectedValue.selectedRowKeys || [],
            //vehicleTypeList1: selectedValue.selectedRowKeys.length && selectedValue.selectedRowsData[0].id || [],
        });
        this.state.errors["serviceVehicleType"] = "";
    }

    syncDataGridVehicleTypeSelection(e) {
        this.setState({
            serviceVehicleType: e.value || []
        });
    }

    handleVehicleType1Change(selectedValue) {
        this.setState({
            vehicleTypeList1: [], serviceVehicleType1: [], numberOfSeats1: 0, vehicleSeats1: 0,
        });

        if (selectedValue.selectedItem != null) {
            this.setState({
                vehicleTypeList1: (selectedValue.selectedItem != null ? selectedValue.selectedItem.id : 0),
                serviceVehicleType1: (selectedValue.selectedItem != null ? selectedValue.selectedItem.id : 0),
                numberOfSeats1: selectedValue.selectedItem.paxCount,
                vehicleSeats1: selectedValue.selectedItem.paxCount,
            })
        }

        this.state.errors["errVehicleType1"] = "";
    }

    handleVehicleType2Change(selectedValue) {
        this.setState({
            vehicleTypeList2: [], serviceVehicleType2: [], numberOfSeats2: 0, vehicleSeats2: 0,
        });
        if (selectedValue.selectedItem != null) {
            this.setState({
                vehicleTypeList2: (selectedValue.selectedItem != null ? selectedValue.selectedItem.id : 0),
                serviceVehicleType2: (selectedValue.selectedItem != null ? selectedValue.selectedItem.id : 0),
                numberOfSeats2: selectedValue.selectedItem.paxCount,
                vehicleSeats2: selectedValue.selectedItem.paxCount,
            })
        }
        this.state.errors["errVehicleType2"] = "";
    }

    handleVehicleType3Change(selectedValue) {
        this.setState({
            vehicleTypeList3: [], serviceVehicleType3: [], numberOfSeats3: 0, vehicleSeats3: 0,
        });
        if (selectedValue.selectedItem != null) {
            this.setState({
                vehicleTypeList3: (selectedValue.selectedItem != null ? selectedValue.selectedItem.id : 0),
                serviceVehicleType3: (selectedValue.selectedItem != null ? selectedValue.selectedItem.id : 0),
                numberOfSeats3: selectedValue.selectedItem.paxCount,
                vehicleSeats3: selectedValue.selectedItem.paxCount,
            })
        }
        this.state.errors["errVehicleType3"] = "";
    }

    handleVehicleType4Change(selectedValue) {
        this.setState({
            vehicleTypeList4: [], serviceVehicleType4: [], numberOfSeats4: 0, vehicleSeats4: 0,
        });
        if (selectedValue.selectedItem != null) {
            this.setState({
                vehicleTypeList4: (selectedValue.selectedItem != null ? selectedValue.selectedItem.id : 0),
                serviceVehicleType4: (selectedValue.selectedItem != null ? selectedValue.selectedItem.id : 0),
                numberOfSeats4: selectedValue.selectedItem.paxCount,
                vehicleSeats4: selectedValue.selectedItem.paxCount,
            })
        }
        this.state.errors["errVehicleType4"] = "";
    }

    handleVehicleType5Change(selectedValue) {
        this.setState({
            vehicleTypeList5: [], serviceVehicleType5: [], numberOfSeats5: 0, vehicleSeats5: 0,
        });
        if (selectedValue.selectedItem != null) {
            this.setState({
                vehicleTypeList5: (selectedValue.selectedItem != null ? selectedValue.selectedItem.id : 0),
                serviceVehicleType5: (selectedValue.selectedItem != null ? selectedValue.selectedItem.id : 0),
                numberOfSeats5: selectedValue.selectedItem.paxCount,
                vehicleSeats5: selectedValue.selectedItem.paxCount,
            })
        }
        this.state.errors["errVehicleType5"] = "";
    }

    async saveAccountSetup() {
        try {
            const requestParams = getRequestParams('POST', this.state.setupRequest);
            const response = await fetch('/account-api/' + window.ParentComponent.state.newaccountId + '/setup', requestParams);
            const data = await response.json();
            if (data.isSuccess) {
                window.ParentComponent.setState({ isSetupCompleted: data.isSuccess });
                window.wizard.classList.add('completed');
            }
        } catch (e) {
            debugger;
        }
    }

    submitLogin() {
        window.location.href = "/";
    }

    removeLastVehicle() {
        var element = document.getElementById("vehicle_" + this.state.selectedVehicleCount);
        element.classList.remove("displayBlock");
        element.classList.add("displayNone");
        if (this.state.selectedVehicleCount == 2) {
            this.state.vehicleName2 = "";
            this.state.numberOfSeats2 = "";
            this.state.licensePlateNumber2 = "";
            this.state.serviceVehicleType2 = "";
        }
        else if (this.state.selectedVehicleCount == 3) {
            this.state.vehicleName3 = "";
            this.state.numberOfSeats3 = "";
            this.state.licensePlateNumber3 = "";
            this.state.serviceVehicleType3 = "";
        }
        else if (this.state.selectedVehicleCount == 4) {
            this.state.vehicleName4 = "";
            this.state.numberOfSeats4 = "";
            this.state.licensePlateNumber4 = "";
            this.state.serviceVehicleType4 = "";
        }
        else if (this.state.selectedVehicleCount == 5) {
            this.state.vehicleName5 = "";
            this.state.numberOfSeats5 = "";
            this.state.licensePlateNumber5 = "";
            this.state.serviceVehicleType5 = "";
        }

        this.setState({ selectedVehicleCount: this.state.selectedVehicleCount - 1 });

        if (this.state.selectedVehicleCount > 2) {
            this.setState({ isVehicleRowDisabled: false });
        }
        else {
            this.setState({ isVehicleRowDisabled: true });
        }
    }

    removeLastDevice() {
        var element = document.getElementById("device_" + this.state.selectedDeviceCount);
        element.classList.remove("displayBlock");
        element.classList.add("displayNone");

        if (this.state.selectedDeviceCount == 2) {
            this.state.mobile2 = "";
        }
        else if (this.state.selectedDeviceCount == 3) {
            this.state.mobile3 = "";
        }
        else if (this.state.selectedDeviceCount == 4) {
            this.state.mobile4 = "";
        }
        else if (this.state.selectedDeviceCount == 5) {
            this.state.mobile5 = "";
        }

        this.setState({ selectedDeviceCount: this.state.selectedDeviceCount - 1 });

        if (this.state.selectedDeviceCount > 2) {
            this.setState({ isDeviceRowDisabled: false });
        }
        else {
            this.setState({ isDeviceRowDisabled: true });
        }
    }

    handleVehicleChange(event) {
        let errors = this.state.errors;
        var vehiclecount = event.target.value;
        if (vehiclecount > 1) {
            this.setState({ isVehicleRowDisabled: false });
        }
        else {
            this.setState({ isVehicleRowDisabled: true });
        }

        this.setState({ selectedVehicleCount: vehiclecount });

        for (var count = 1; count <= 5; count++) {
            var element = document.getElementById("vehicle_" + count);
            element.classList.remove("displayBlock");
            element.classList.add("displayNone");
        }

        for (var count = 1; count <= vehiclecount; count++) {
            var element = document.getElementById("vehicle_" + count);
            element.classList.remove("displayNone");
            element.classList.add("displayBlock");
        }

        //if (vehiclecount == 1) {
        //    this.state.vehicleName2 = "";
        //    this.state.numberOfSeats2 = "";
        //    this.state.licensePlateNumber2 = "";
        //    this.state.serviceVehicleType2 = "";

        //    this.state.vehicleName3 = "";
        //    this.state.numberOfSeats3 = "";
        //    this.state.licensePlateNumber3 = "";
        //    this.state.serviceVehicleType3 = "";

        //    this.state.vehicleName4 = "";
        //    this.state.numberOfSeats4 = "";
        //    this.state.licensePlateNumber4 = "";
        //    this.state.serviceVehicleType4 = "";

        //    this.state.vehicleName5 = "";
        //    this.state.numberOfSeats5 = "";
        //    this.state.licensePlateNumber5 = "";
        //    this.state.serviceVehicleType5 = "";
        //}
        //else if (vehiclecount == 2) {
        //    this.state.vehicleName3 = "";
        //    this.state.numberOfSeats3 = "";
        //    this.state.licensePlateNumber3 = "";
        //    this.state.serviceVehicleType3 = "";

        //    this.state.vehicleName4 = "";
        //    this.state.numberOfSeats4 = "";
        //    this.state.licensePlateNumber4 = "";
        //    this.state.serviceVehicleType4 = "";

        //    this.state.vehicleName5 = "";
        //    this.state.numberOfSeats5 = "";
        //    this.state.licensePlateNumber5 = "";
        //    this.state.serviceVehicleType5 = "";
        //}
        //else if (vehiclecount == 3) {
        //    this.state.vehicleName4 = "";
        //    this.state.numberOfSeats4 = "";
        //    this.state.licensePlateNumber4 = "";
        //    this.state.serviceVehicleType4 = "";

        //    this.state.vehicleName5 = "";
        //    this.state.numberOfSeats5 = "";
        //    this.state.licensePlateNumber5 = "";
        //    this.state.serviceVehicleType5 = "";
        //}
        //else if (vehiclecount == 4) {
        //    this.state.vehicleName5 = "";
        //    this.state.numberOfSeats5 = "";
        //    this.state.licensePlateNumber5 = "";
        //    this.state.serviceVehicleType5 = "";
        //}

        errors.vehicleName1 = '';
        errors.vehicleName2 = '';
        errors.vehicleName3 = '';
        errors.vehicleName4 = '';
        errors.vehicleName5 = '';

        errors.licensePlateNumber1 = '';
        errors.licensePlateNumber2 = '';
        errors.licensePlateNumber3 = '';
        errors.licensePlateNumber4 = '';
        errors.licensePlateNumber5 = '';

        errors.errVehicleType1 = '';
        errors.errVehicleType2 = '';
        errors.errVehicleType3 = '';
        errors.errVehicleType4 = '';
        errors.errVehicleType5 = '';

        errors.numberOfSeats1 = '';
        errors.numberOfSeats2 = '';
        errors.numberOfSeats3 = '';
        errors.numberOfSeats4 = '';
        errors.numberOfSeats5 = '';
    }

    handleDeviceChange(event) {
        let errors = this.state.errors;
        var devicecount = event.target.value;

        if (devicecount > 1) {
            this.setState({ isDeviceRowDisabled: false });
        }
        else {
            this.setState({ isDeviceRowDisabled: true });
        }

        this.setState({ selectedDeviceCount: devicecount });

        for (var count = 1; count <= 5; count++) {
            var element = document.getElementById("device_" + count);
            element.classList.remove("displayBlock");
            element.classList.add("displayNone");
        }

        for (var count = 1; count <= devicecount; count++) {
            var element = document.getElementById("device_" + count);
            element.classList.remove("displayNone");
            element.classList.add("displayBlock");
        }

        //if (devicecount == 1) {
        //    this.state.mobile2 = "";
        //    this.state.mobile3 = "";
        //    this.state.mobile4 = "";
        //    this.state.mobile5 = "";
        //}
        //else if (devicecount == 2) {
        //    this.state.mobile3 = "";
        //    this.state.mobile4 = "";
        //    this.state.mobile5 = "";
        //}
        //else if (devicecount == 3) {
        //    this.state.mobile4 = "";
        //    this.state.mobile5 = "";
        //}
        //else if (devicecount == 4) {
        //    this.state.mobile5 = "";
        //}

        errors.countryCode1 = '';
        errors.countryCode2 = '';
        errors.countryCode3 = '';
        errors.countryCode4 = '';
        errors.countryCode5 = '';

        errors.mobile1 = '';
        errors.mobile2 = '';
        errors.mobile3 = '';
        errors.mobile4 = '';
        errors.mobile5 = '';
    }

    async getTimezone() {        
        const requestParams = getRequestParams('GET', "{}");
        const response = await fetch('/timezone-api/time-zones/' + this.state.countryId, requestParams);
        const data = await response.json();
        if (data.isSuccess) {
            this.setState({
                timezoneList: data.timeZones
            });
        }
    }

    async getCurrency() {
        let currencyId = 0;
        const requestParams = getRequestParams('GET', "{}");
        const response = await fetch('/currency-api/list/' + currencyId + '/' + this.state.countryId, requestParams);
        const data = await response.json();
        if (data.isSuccess) {
            this.setState({
                currencyList: data.currencyList
            });
        }
    }

    async getLocations() {
        const requestParams = getRequestParams('GET', "{}");
        const response = await fetch('/location-api/locations-dropdownlist/0', requestParams);
        const data = await response.json();
        if (data.isSuccess) {
            this.setState({
                locationList: data.locations
            });
        }
    }

    async getDefaultSettings() {
        const response = await fetch('/account-api/' + this.state.newaccountId + '/default/');
        const data = await response.json();
        var sLocation = [];
        if (data.isSuccess) {
            sLocation.push(data.locationId);
            this.setState({ defaultAccountSettings: data, serviceLocations: sLocation, countryId: data.countryId });
            this.getTimezone();
            this.getCurrency();
            this.getAirports();
        }
    }

    async getVehicleType() {
        const response = await fetch('/vehicletype-api/dropdown/');
        const data = await response.json();
        if (data.isSuccess) {
            vehicleTypes.push(data.vehicleTypeDropdownList);
            this.setState({
                vehicleTypeList: data.vehicleTypeDropdownList
            });
        }
    }

    getDistanceUnit() {
        const response = [
            { name: 'Miles', id: 1 },
            { name: 'Kilometers', id: 2 }
        ];

        this.setState({
            distanceUnitList: response
        });
    }

    async getAirports() {
        var airportsDropdownListRequest = new Object();
        airportsDropdownListRequest.LocationIdList = this.state.serviceLocations;
        //if (flag) {
        //    airportsDropdownListRequest.LocationIdList = locIdList;
        //}
        const requestParams = getRequestParams('POST', airportsDropdownListRequest);
        const response = await fetch('/airports-api/airports-dropdown-list/', requestParams);
        const data = await response.json();
        if (data.isSuccess) {
            this.setState({
                airportList: data.airportsDropdownList
            });
        }
        else {
            this.setState({ airportList: [] });
        }
    }

    onSelectionModeChange(args) {
        this.setState({
            selectionMode: args.value
        });
    }

    onSelectAllModeChange(args) {
        this.setState({
            selectAllMode: args.value
        });
    }

    onSelectedItemKeysChange(args) {
        if (args.name === 'selectedItemKeys') {
            this.setState({
                selectedItemKeys: args.value
            });
        }
    }

    handleInputChangeStep1(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name == "doTripDuration") {
            const re = /^[0-9-\b]+$/;
            if (event.target.value === '' || re.test(event.target.value)) {
                this.setState({ doTripDuration: event.target.value })
                if (event.target.value == "") {
                    this.state.errors[name] = "Please enter DO trip duration";
                }
                else if (event.target.value < 10 || event.target.value > 90) {
                    this.state.errors[name] = "DO trip duration should be between 10 to 90";
                }
                else {
                    this.state.errors[name] = "";
                }
            }
            else {
                this.setState({ doTripDuration: "" });
                this.state.errors[name] = "Please enter DO trip duration";
            }
        }
        else if (name == "nonAirportTransfer") {
            const re = /^[0-9-\b]+$/;
            if (event.target.value === '' || re.test(event.target.value)) {
                this.setState({ nonAirportTransfer: event.target.value })
                if (event.target.value == "") {
                    this.state.errors[name] = "Please enter non airport transfer in minutes";
                }
                else if (event.target.value < 0 || event.target.value > 120) {
                    this.state.errors[name] = "DO trip duration should be between 0 to 120";
                }
                else {
                    this.state.errors[name] = "";
                }
            }
            else {
                this.setState({ nonAirportTransfer: "" });
                this.state.errors[name] = "Please enter non airport transfer in minutes";
            }
        }
        else if (name == "airportTransferDomestic") {
            const re = /^[0-9-\b]+$/;
            if (event.target.value === '' || re.test(event.target.value)) {
                this.setState({ airportTransferDomestic: event.target.value })
                if (event.target.value == "") {
                    this.state.errors[name] = "Please enter non airport domestic transfer minutes";
                }
                else if (event.target.value < 0 || event.target.value > 60) {
                    this.state.errors[name] = "Non airport transfer domestic should be between 0 to 60";
                }
                else {
                    this.state.errors[name] = "";
                }
            }
            else {
                this.setState({ airportTransferDomestic: "" });
                this.state.errors[name] = "Please enter non airport domestic transfer minutes";
            }
        }
        else if (name == "airportTransferInternational") {
            const re = /^[0-9-\b]+$/;
            if (event.target.value === '' || re.test(event.target.value)) {
                this.setState({ airportTransferInternational: event.target.value })
                if (event.target.value == "") {
                    this.state.errors[name] = "Please enter non airport international transfer minutes";
                }
                else if (event.target.value < 0 || event.target.value > 120) {
                    this.state.errors[name] = "Non airport transfer international should be between 0 to 120";
                }
                else {
                    this.state.errors[name] = "";
                }
            }
            else {
                this.setState({ airportTransferInternational: "" });
                this.state.errors[name] = "Please enter non airport international transfer minutes";
            }
        }
        else {
            this.setState({
                [name]: value
            });
            this.state.errors[name] = "";
        }
    }

    handleInputChangeStep3(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name == "numberOfSeats1") {
            const re = /^[0-9-\b]+$/;
            this.state.errors[name] = "";
            if (event.target.value === '' || re.test(event.target.value)) {
                this.setState({ numberOfSeats1: event.target.value });
                if (this.state.serviceVehicleType1 > 0) {
                    if (parseInt(event.target.value) > this.state.vehicleSeats1) {
                        this.state.errors[name] = "Number of seats should not be greater than vehicle capacity [" + this.state.vehicleSeats1 + "]";
                    }
                }
            }
        }
        else if (name == "numberOfSeats2") {
            const re = /^[0-9-\b]+$/;
            this.state.errors[name] = "";
            if (event.target.value === '' || re.test(event.target.value)) {
                this.setState({ numberOfSeats2: event.target.value });
                if (this.state.serviceVehicleType2 > 0) {
                    if (parseInt(event.target.value) > this.state.vehicleSeats2) {
                        this.state.errors[name] = "Number of seats should not be greater than vehicle capacity [" + this.state.vehicleSeats2 + "]";
                    }
                }
            }
        }
        else if (name == "numberOfSeats3") {
            const re = /^[0-9-\b]+$/;
            this.state.errors[name] = "";
            if (event.target.value === '' || re.test(event.target.value)) {
                this.setState({ numberOfSeats3: event.target.value });
                if (this.state.serviceVehicleType3 > 0) {
                    if (parseInt(event.target.value) > this.state.vehicleSeats3) {
                        this.state.errors[name] = "Number of seats should not be greater than vehicle capacity [" + this.state.vehicleSeats2 + "]";
                    }
                }
            }
        }
        else if (name == "numberOfSeats4") {
            const re = /^[0-9-\b]+$/;
            this.state.errors[name] = "";
            if (event.target.value === '' || re.test(event.target.value)) {
                this.setState({ numberOfSeats4: event.target.value });
                if (this.state.serviceVehicleType4 > 0) {
                    if (parseInt(event.target.value) > this.state.vehicleSeats4) {
                        this.state.errors[name] = "Number of seats should not be greater than vehicle capacity [" + this.state.vehicleSeats2 + "]";
                    }
                }
            }
        }
        else if (name == "numberOfSeats5") {
            const re = /^[0-9-\b]+$/;
            this.state.errors[name] = "";
            if (event.target.value === '' || re.test(event.target.value)) {
                this.setState({ numberOfSeats5: event.target.value });
                if (this.state.serviceVehicleType5 > 0) {
                    if (parseInt(event.target.value) > this.state.vehicleSeats5) {
                        this.state.errors[name] = "Number of seats should not be greater than vehicle capacity [" + this.state.vehicleSeats2 + "]";
                    }
                }
            }
        }
        else {
            this.setState({
                [name]: value
            });
            this.state.errors[name] = "";
        }
    }

    handleInputChangeStep4(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name

        if (name == "countryCode1") {
            const re = /^[a-z0-9]+$/i;
            if (event.target.value === '' || re.test(event.target.value)) {
                this.setState({ countryCode1: event.target.value })
                this.state.errors[name] = "";
            }
            else {
                this.state.errors[name] = "Please enter valid country code";
            }
        }
        else if (name == "countryCode2") {
            const re = /^[a-z0-9]+$/i;
            if (event.target.value === '' || re.test(event.target.value)) {
                this.setState({ countryCode2: event.target.value })
                this.state.errors[name] = "";
            }
            else {
                this.state.errors[name] = "Please enter valid country code";
            }
        }
        else if (name == "countryCode3") {
            const re = /^[a-z0-9]+$/i;
            if (event.target.value === '' || re.test(event.target.value)) {
                this.setState({ countryCode3: event.target.value })
                this.state.errors[name] = "";
            }
            else {
                this.state.errors[name] = "Please enter valid country code";
            }
        }
        else if (name == "countryCode4") {
            const re = /^[a-z0-9]+$/i;
            if (event.target.value === '' || re.test(event.target.value)) {
                this.setState({ countryCode4: event.target.value })
                this.state.errors[name] = "";
            }
            else {
                this.state.errors[name] = "Please enter valid country code";
            }
        }
        else if (name == "countryCode5") {
            const re = /^[a-z0-9]+$/i;
            if (event.target.value === '' || re.test(event.target.value)) {
                this.setState({ countryCode5: event.target.value })
                this.state.errors[name] = "";
            }
            else {
                this.state.errors[name] = "Please enter valid country code";
            }
        }
        else if (name == "mobile1") {
            if (event.target.value.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length >= 10) {
                this.handleMobileValidation(1, this.state.countryCode1, event.target.value);
            }
        }
        else if (name == "mobile2") {
            if (event.target.value.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length >= 10) {
                this.handleMobileValidation(2, this.state.countryCode2, event.target.value);
            }
        }
        else if (name == "mobile3") {
            if (event.target.value.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length >= 10) {
                this.handleMobileValidation(3, this.state.countryCode3, event.target.value);
            }
        }
        else if (name == "mobile4") {
            if (event.target.value.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length >= 10) {
                this.handleMobileValidation(4, this.state.countryCode4, event.target.value);
            }
        }
        else if (name == "mobile5") {
            if (event.target.value.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length >= 10) {
                this.handleMobileValidation(5, this.state.countryCode5, event.target.value);
            }
        }
        else {
            this.setState({
                [name]: value
            });
            this.state.errors[name] = "";
        }
    }

    async handleMobileValidation(count, countrycode, mobileno) {
        var mobileValidationRequest = new Object();
        mobileValidationRequest.CountryCode = countrycode;
        mobileValidationRequest.MobileNo = mobileno;

        const requestParams = getRequestParams('POST', mobileValidationRequest);
        const response = await fetch('/device-mobile/validation', requestParams);
        const data = await response.json();

        if (count == 1) {
            this.setState({ mobile1: mobileno, isMobile1Validation: data.isSuccess });
            if (data.isSuccess) {
                this.state.errors["mobile1"] = "";
            }
        } else if (count == 2) {
            this.setState({ mobile2: mobileno, isMobile2Validation: data.isSuccess });
            if (data.isSuccess) {
                this.state.errors["mobile2"] = "";
            }
        } else if (count == 3) {
            this.setState({ mobile3: mobileno, isMobile3Validation: data.isSuccess });
            if (data.isSuccess) {
                this.state.errors["mobile3"] = "";
            }
        } else if (count == 4) {
            this.setState({ mobile4: mobileno, isMobile4Validation: data.isSuccess });
            if (data.isSuccess) {
                this.state.errors["mobile4"] = "";
            }
        } else if (count == 5) {
            this.setState({ mobile5: mobileno, isMobile5Validation: data.isSuccess });
            if (data.isSuccess) {
                this.state.errors["mobile5"] = "";
            }
        }
    }

    handleStep1Validation() {
        let errors = this.state.errors;
        let formIsValid = true;
        //return true;
        errors.doTripDuration = "";
        errors.timezone = "";
        errors.currency = "";
        errors.distanceUnit = "";
        errors.nonAirportTransfer = "";
        errors.airportTransferDomestic = "";
        errors.airportTransferInternational = "";

        this.setState({ message: '' });

        if (this.state.doTripDuration == '') {
            formIsValid = false;
            errors.doTripDuration = "Please enter DO trip duration";
        }
        if (parseInt(this.state.doTripDuration) < 10 || parseInt(this.state.doTripDuration) > 90) {
            formIsValid = false;
            errors.doTripDuration = "DO trip duration should be between 10 to 90";
        }

        if (this.state.timezoneId == '') {
            formIsValid = false;
            errors.timezone = "Please select time zone";
        }

        if (this.state.currencyId == '') {
            formIsValid = false;
            errors.currency = "Please select currency";
        }

        if (this.state.distanceUnit == '') {
            formIsValid = false;
            errors.distanceUnit = "Please select distance unit";
        }

        if (this.state.nonAirportTransfer == '') {
            formIsValid = false;
            errors.nonAirportTransfer = "Please enter non airport transfer minutes";
        }
        if (parseInt(this.state.nonAirportTransfer) < 0 || parseInt(this.state.nonAirportTransfer) > 120) {
            formIsValid = false;
            errors.nonAirportTransfer = "Non airport transfer should be between 0 to 120";
        }

        if (this.state.airportTransferDomestic == '') {
            formIsValid = false;
            errors.airportTransferDomestic = "Please enter airport transfer domestic minutes";
        }
        if (parseInt(this.state.airportTransferDomestic) < 0 || parseInt(this.state.airportTransferDomestic) > 60) {
            formIsValid = false;
            errors.airportTransferDomestic = "Non airport transfer domestic should be between 0 to 60";
        }

        if (this.state.airportTransferInternational == '') {
            formIsValid = false;
            errors.airportTransferInternational = "Please enter airport transfer international minutes";
        }
        if (parseInt(this.state.airportTransferInternational) < 0 || parseInt(this.state.airportTransferInternational) > 120) {
            formIsValid = false;
            errors.airportTransferInternational = "Non airport transfer international should be between 0 to 120";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleStep2Validation() {
        let errors = this.state.errors;
        let formIsValid = true;
        //return true;
        errors.serviceLocations = "";
        errors.serviceAirports = "";
        errors.serviceVehicleType = "";

        this.setState({ message: '' });

        if (this.state.serviceLocations.length == 0) {
            formIsValid = false;
            errors.serviceLocations = "Please select service locations";
        }

        if (this.state.serviceAirports.length == 0) {
            formIsValid = false;
            errors.serviceAirports = "Please select service airport";
        }

        if (this.state.serviceVehicleType == '') {
            formIsValid = false;
            errors.serviceVehicleType = "Please select vehicle type";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleStep3Validation() {
        let errors = this.state.errors;
        let formIsValid = true;
        //return true;
        errors.vehicleName1 = '';
        errors.vehicleName2 = '';
        errors.vehicleName3 = '';
        errors.vehicleName4 = '';
        errors.vehicleName5 = '';

        errors.licensePlateNumber1 = '';
        errors.licensePlateNumber2 = '';
        errors.licensePlateNumber3 = '';
        errors.licensePlateNumber4 = '';
        errors.licensePlateNumber5 = '';

        errors.errVehicleType1 = '';
        errors.errVehicleType2 = '';
        errors.errVehicleType3 = '';
        errors.errVehicleType4 = '';
        errors.errVehicleType5 = '';

        errors.numberOfSeats1 = '';
        errors.numberOfSeats2 = '';
        errors.numberOfSeats3 = '';
        errors.numberOfSeats4 = '';
        errors.numberOfSeats5 = '';

        this.setState({ message: '' });

        if (parseInt(this.state.selectedVehicleCount) >= 1) {
            if (this.state.vehicleName1 == '') {
                formIsValid = false;
                errors.vehicleName1 = "Please enter first vehicle name";
            }
            if (this.state.serviceVehicleType1 == '') {
                formIsValid = false;
                errors.errVehicleType1 = "Please select first vehicle type";
            }
            if (this.state.licensePlateNumber1 == '') {
                formIsValid = false;
                errors.licensePlateNumber1 = "Please enter first license plate number";
            }
            if (this.state.numberOfSeats1 == '' || this.state.numberOfSeats1 == 0 || this.state.numberOfSeats1 == undefined) {
                formIsValid = false;
                errors.numberOfSeats1 = "Please enter first number of seats";
            }
            else if (this.state.numberOfSeats1 > this.state.vehicleSeats1) {
                formIsValid = false;
                errors.numberOfSeats1 = "Number of seats should not be greater than vehicle capacity [" + this.state.vehicleSeats1 + "]";
            }
        }

        if (parseInt(this.state.selectedVehicleCount) >= 2) {
            if (this.state.vehicleName2 != '' || this.state.gridBoxVehicleTypeValue2.length > 0 || this.state.licensePlateNumber2 != '' || this.state.numberOfSeats2 != '') {
                if (this.state.vehicleName2 == '') {
                    formIsValid = false;
                    errors.vehicleName2 = "Please enter second vehicle name";
                }
                if (this.state.serviceVehicleType2 === '') {
                    formIsValid = false;
                    errors.errVehicleType2 = "Please select second vehicle type";
                }
                if (this.state.licensePlateNumber2 == '') {
                    formIsValid = false;
                    errors.licensePlateNumber2 = "Please enter second license plate number";
                }
                if (this.state.numberOfSeats2 == '' || this.state.numberOfSeats2 == 0 || this.state.numberOfSeats2 == undefined) {
                    formIsValid = false;
                    errors.numberOfSeats2 = "Please enter second number of seats";
                }
                else if (this.state.numberOfSeats2 > this.state.vehicleSeats2) {
                    formIsValid = false;
                    errors.numberOfSeats2 = "Number of seats should not be greater than vehicle capacity [" + this.state.vehicleSeats2 + "]";
                }
            }
        }

        if (parseInt(this.state.selectedVehicleCount) >= 3) {
            if (this.state.vehicleName3 != '' || this.state.gridBoxVehicleTypeValue3.length > 0 || this.state.licensePlateNumber3 != '' || this.state.numberOfSeats3 != '') {
                if (this.state.vehicleName3 == '') {
                    formIsValid = false;
                    errors.vehicleName3 = "Please enter third vehicle name";
                }
                if (this.state.serviceVehicleType3 === '') {
                    formIsValid = false;
                    errors.errVehicleType3 = "Please select third vehicle type";
                }
                if (this.state.licensePlateNumber3 == '') {
                    formIsValid = false;
                    errors.licensePlateNumber3 = "Please enter third license plate number";
                }

                if (this.state.numberOfSeats3 == '' || this.state.numberOfSeats3 == 0 || this.state.numberOfSeats3 == undefined) {
                    formIsValid = false;
                    errors.numberOfSeats3 = "Please enter third number of seats";
                }
                else if (this.state.numberOfSeats3 > this.state.vehicleSeats3) {
                    formIsValid = false;
                    errors.numberOfSeats3 = "Number of seats should not be greater than vehicle capacity [" + this.state.vehicleSeats3 + "]";
                }
            }
        }

        if (parseInt(this.state.selectedVehicleCount) >= 4) {
            if (this.state.vehicleName4 != '' || this.state.gridBoxVehicleTypeValue4.length > 0 || this.state.licensePlateNumber4 != '' || this.state.numberOfSeats4 != '') {
                if (this.state.vehicleName4 == '') {
                    formIsValid = false;
                    errors.vehicleName4 = "Please enter fourth vehicle name";
                }

                if (this.state.serviceVehicleType1 === '') {
                    formIsValid = false;
                    errors.errVehicleType4 = "Please select fourth vehicle type";
                }
                if (this.state.licensePlateNumber4 == '') {
                    formIsValid = false;
                    errors.licensePlateNumber4 = "Please enter fourth license plate number";
                }

                if (this.state.numberOfSeats4 == '' || this.state.numberOfSeats4 == 0 || this.state.numberOfSeats4 == undefined) {
                    formIsValid = false;
                    errors.numberOfSeats4 = "Please enter fourth number of seats";
                }
                else if (this.state.numberOfSeats4 > this.state.vehicleSeats4) {
                    formIsValid = false;
                    errors.numberOfSeats4 = "Number of seats should not be greater than vehicle capacity [" + this.state.vehicleSeats4 + "]";
                }
            }
        }

        if (parseInt(this.state.selectedVehicleCount) >= 5) {
            if (this.state.vehicleName5 != '' || this.state.gridBoxVehicleTypeValue5.length > 0 || this.state.licensePlateNumber5 != '' || this.state.numberOfSeats5 != '') {
                if (this.state.vehicleName5 == '') {
                    formIsValid = false;
                    errors.vehicleName5 = "Please enter fifth vehicle name";
                }
                if (this.state.serviceVehicleType5 === '') {
                    formIsValid = false;
                    errors.errVehicleType5 = "Please select fifth vehicle type";
                }
                if (this.state.licensePlateNumber5 == '') {
                    formIsValid = false;
                    errors.licensePlateNumber5 = "Please enter fifth license plate number";
                }

                if (this.state.numberOfSeats5 == '' || this.state.numberOfSeats5 == 0 || this.state.numberOfSeats5 == undefined) {
                    formIsValid = false;
                    errors.numberOfSeats5 = "Please enter fifth number of seats";
                }
                else if (this.state.numberOfSeats5 > this.state.vehicleSeats5) {
                    formIsValid = false;
                    errors.numberOfSeats5 = "Number of seats should not be greater than vehicle capacity [" + this.state.vehicleSeats5 + "]";
                }
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleStep4Validation() {
        //debugger;
        let errors = this.state.errors;
        let formIsValid = true;
        //return true;
        errors.countryCode1 = '';
        errors.countryCode2 = '';
        errors.countryCode3 = '';
        errors.countryCode4 = '';
        errors.countryCode5 = '';

        errors.mobile1 = '';
        errors.mobile2 = '';
        errors.mobile3 = '';
        errors.mobile4 = '';
        errors.mobile5 = '';

        this.setState({ message: '' });

        if (parseInt(this.state.selectedDeviceCount) >= 1) {
            if (this.state.countryCode1 == '') {
                formIsValid = false;
                errors.countryCode1 = "Required";
            }
            if (this.state.mobile1 == '') {
                formIsValid = false;
                errors.mobile1 = "Please enter first mobile number";
            } else if (this.state.mobile1.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length != 10) {
                formIsValid = false;
                errors.mobile1 = "InvalidInvalid  mobile number";
            } else if (this.state.isMobile1Validation == false) {
                formIsValid = false;
                errors.mobile1 = "Mobile number is already exists.";
            }
        }

        if (parseInt(this.state.selectedDeviceCount) >= 2) {
            if (this.state.countryCode2 == '') {
                formIsValid = false;
                errors.countryCode2 = "Required";
            }
            if (this.state.mobile2 == '') {
                formIsValid = false;
                errors.mobile2 = "Please enter second mobile number";
            } else if (this.state.mobile2.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length != 10) {
                formIsValid = false;
                errors.mobile2 = "Invalid mobile number";
            } else if (this.state.isMobile2Validation == false) {
                formIsValid = false;
                errors.mobile2 = "Mobile number is already exists.";
            }
        }

        if (parseInt(this.state.selectedDeviceCount) >= 3) {
            if (this.state.countryCode3 == '') {
                formIsValid = false;
                errors.countryCode3 = "Required";
            }
            if (this.state.mobile3 == '') {
                formIsValid = false;
                errors.mobile3 = "Please enter third mobile number";
            } else if (this.state.mobile3.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length != 10) {
                formIsValid = false;
                errors.mobile3 = "Invalid mobile number";
            } else if (this.state.isMobile3Validation == false) {
                formIsValid = false;
                errors.mobile3 = "Mobile number is already exists.";
            }
        }

        if (parseInt(this.state.selectedDeviceCount) >= 4) {
            if (this.state.countryCode4 == '') {
                formIsValid = false;
                errors.countryCode4 = "Required";
            }
            if (this.state.mobile4 == '') {
                formIsValid = false;
                errors.mobile4 = "Please enter fourth mobile number";
            } else if (this.state.mobile4.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length != 10) {
                formIsValid = false;
                errors.mobile4 = "Invalid mobile number";
            } else if (this.state.isMobile4Validation == false) {
                formIsValid = false;
                errors.mobile4 = "Mobile number is already exists.";
            }
        }

        if (parseInt(this.state.selectedDeviceCount) >= 5) {
            if (this.state.countryCode5 == '') {
                formIsValid = false;
                errors.countryCode5 = "Required";
            }
            if (this.state.mobile5 == '') {
                formIsValid = false;
                errors.mobile5 = "Please enter fifth mobile number";
            } else if (this.state.mobile5.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length != 10) {
                formIsValid = false;
                errors.mobile5 = "Invalid  mobile number";
            } else if (this.state.isMobile5Validation == false) {
                formIsValid = false;
                errors.mobile5 = "Mobile number is already exists.";
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    async onSelectionChanged(e) {
        this.setState({
            serviceLocations: e.selectedRowKeys.length && e.selectedRowKeys || []
        });
        await this.getAirports();
    }
    async syncDataGridSelection(e) {
        this.setState({
            serviceLocations: e.value || []
        });
        await this.getAirports();
    }

    onSelectionAirportChanged(e) {
        this.setState({
            serviceAirports: e.selectedRowKeys.length && e.selectedRowKeys || []
        });
    }
    syncDataGridAirportSelection(e) {
        this.setState({
            serviceAirports: e.value || []
        });
    }

    render() {
        document.querySelector('body').classList.remove('login-page');

        return (
            <div className="setup-account-page">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center p-0 mt-3 mb-2">
                            <div id="wizard" className="wizard">
                                <div className="wizard__content">
                                    <header className="wizard__header">
                                        <div className="wizard__header-overlay"></div>
                                        <div className="wizard__header-content">
                                            <h1 className="wizard__title"><img src="../../dist/img/logo_utwiz.png" alt="UTWiz" /> - Set Up User Account</h1>
                                            <p className="wizard__subheading">Fill all form field to go to next step</p>
                                        </div>
                                        <div className="wizard__steps">
                                            <nav className="steps">
                                                <div className="step">
                                                    <div className="step__content">
                                                        <p className="step__number"><i className="fas fa-globe"></i></p>
                                                        <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                        </svg>

                                                        <div className="lines">
                                                            <div className="line -start">
                                                            </div>

                                                            <div className="line -background">
                                                            </div>

                                                            <div className="line -progress">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="step">
                                                    <div className="step__content">
                                                        <p className="step__number"><i className="fas fa-file-alt"></i></p>
                                                        <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                        </svg>

                                                        <div className="lines">
                                                            <div className="line -background">
                                                            </div>

                                                            <div className="line -progress">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="step">
                                                    <div className="step__content">
                                                        <p className="step__number"><i className="fas fa-car"></i></p>
                                                        <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                        </svg>

                                                        <div className="lines">
                                                            <div className="line -background">
                                                            </div>

                                                            <div className="line -progress">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="step">
                                                    <div className="step__content">
                                                        <p className="step__number"><i className="fas fa-mobile"></i></p>
                                                        <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                        </svg>

                                                        <div className="lines">
                                                            <div className="line -background">
                                                            </div>

                                                            <div className="line -progress">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </nav>
                                        </div>
                                    </header>
                                    <div className="panels">
                                        <div className="panel">
                                            <div className="row">
                                                <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                    <h2 className="fs-title">General Information</h2>
                                                </div>
                                                <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5 text-right">
                                                    <h2 className="stepin">Step 1 - 4</h2>
                                                </div>
                                            </div>
                                            <div style={styleginfoheight}>
                                                <label className="text-info">Time Zone and Currency</label>
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                        <div className="form-group">
                                                            <label>Time Zone</label>
                                                            <SelectBox
                                                                dataSource={this.state.timezoneList.length > 0 ? this.state.timezoneList : []}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                placeholder="Select time zone"
                                                                name="id"
                                                                defaultValue={0}
                                                                value={this.state.timezoneId}
                                                                displayExpr='shortName'
                                                                valueExpr='id'
                                                                onSelectionChanged={this.handleTimezoneChange}
                                                                showClearButton={true}
                                                                disabled={false}
                                                                searchEnabled={true}
                                                            />
                                                            {this.state.errors["timezone"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["timezone"]}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                        <div className="form-group">
                                                            <label>Default Currency</label>

                                                            <SelectBox
                                                                dataSource={this.state.currencyList.length > 0 ? this.state.currencyList : []}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                placeholder="Select currency"
                                                                name="currencyId"
                                                                defaultValue={0}
                                                                value={this.state.currencyId}
                                                                displayExpr='currencyName'
                                                                valueExpr='currencyId'
                                                                onSelectionChanged={this.handleCurrencyChange}
                                                                showClearButton={true}
                                                                disabled={false}
                                                                searchEnabled={true}
                                                            />
                                                            {this.state.errors["currency"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["currency"]}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <label className="text-info">Reservation</label>
                                                <div className="row">
                                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                        <div className="form-group">
                                                            <label>DO Trip Duration (in mins)</label>
                                                            <input className="form-control form-control-sm" type="text" name="doTripDuration" maxLength="3" value={this.state.doTripDuration} onChange={this.handleInputChangeStep1} placeholder="Enter DO trip duration" />
                                                        </div>
                                                        {this.state.errors["doTripDuration"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["doTripDuration"]}</span>}
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                        <div className="form-group">
                                                            <label>Miles or Kilometers</label>
                                                            <SelectBox
                                                                dataSource={this.state.distanceUnitList.length > 0 ? this.state.distanceUnitList : []}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                placeholder="Select distance unit"
                                                                name="id"
                                                                defaultValue={0}
                                                                value={this.state.distanceUnit}
                                                                displayExpr='name'
                                                                valueExpr='id'
                                                                onSelectionChanged={this.handleDistanceunitChange}
                                                                showClearButton={true}
                                                                disabled={false}
                                                                searchEnabled={true}
                                                            />
                                                            {this.state.errors["distanceUnit"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["distanceUnit"]}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <label className="text-info">Default Pick-up Departure (in mins)</label>
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                        <div className="form-group">
                                                            <label>Non Airport Transfer</label>
                                                            <input className="form-control form-control-sm" type="text" name="nonAirportTransfer" maxLength="3" value={this.state.nonAirportTransfer} onChange={this.handleInputChangeStep1} placeholder="Enter non airport transfer in minutes" />
                                                        </div>
                                                        {this.state.errors["nonAirportTransfer"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["nonAirportTransfer"]}</span>}
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                        <div className="form-group">
                                                            <label>Airport Transfer - Domestic</label>
                                                            <input className="form-control form-control-sm" type="text" name="airportTransferDomestic" maxLength="3" value={this.state.airportTransferDomestic} onChange={this.handleInputChangeStep1} placeholder="Enter airport transfer domestic in minutes" />
                                                        </div>
                                                        {this.state.errors["airportTransferDomestic"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["airportTransferDomestic"]}</span>}
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                        <div className="form-group">
                                                            <label>Airport Transfer - International</label>
                                                            <input className="form-control form-control-sm" type="text" name="airportTransferInternational" maxLength="3" value={this.state.airportTransferInternational} onChange={this.handleInputChangeStep1} placeholder="Enter airport transfer international in minutes" />
                                                        </div>
                                                        {this.state.errors["airportTransferInternational"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["airportTransferInternational"]}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="panel">
                                            <div className="row">
                                                <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                    <h2 className="fs-title">Service Information</h2>
                                                </div>
                                                <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5 text-right">
                                                    <h2 className="stepin">Step 2 - 4</h2>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                    <div className="form-group">
                                                        <label>Service Location(s)</label>
                                                        <DropDownBox
                                                            value={this.state.serviceLocations}
                                                            valueExpr="id"
                                                            deferRendering={false}
                                                            displayExpr="name"
                                                            placeholder="Select locations..."
                                                            showClearButton={true}
                                                            dataSource={this.state.locationList}
                                                            onValueChanged={this.syncDataGridSelection}
                                                            contentRender={this.dataGridRender}
                                                        />
                                                    </div>
                                                    {this.state.errors["serviceLocations"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["serviceLocations"]}</span>}
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                    <div className="form-group">
                                                        <label>Service Airport(s)</label>
                                                        <DropDownBox
                                                            value={this.state.serviceAirports}
                                                            valueExpr="airportId"
                                                            deferRendering={false}
                                                            displayExpr="code"
                                                            placeholder="Select airports..."
                                                            showClearButton={true}
                                                            dataSource={this.state.airportList}
                                                            onValueChanged={this.syncDataGridAirportSelection}
                                                            contentRender={this.dataGridAirportRender}
                                                        />
                                                    </div>
                                                    {this.state.errors["serviceAirports"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["serviceAirports"]}</span>}
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8" style={stylegserviceheight}>
                                                    <div className="form-group">
                                                        <label>Select Vehicle Type</label>
                                                        {/*<SelectBox*/}
                                                        {/*    dataSource={vehicleTypes.length > 0 ? vehicleTypes[0] : []}*/}
                                                        {/*    className="basic-multi-select"*/}
                                                        {/*    classNamePrefix="select"*/}
                                                        {/*    placeholder="Select vehicle type"*/}
                                                        {/*    name="id"*/}
                                                        {/*    defaultValue={0}*/}
                                                        {/*    value={this.state.serviceVehicleType}*/}
                                                        {/*    displayExpr='vehicleType'*/}
                                                        {/*    valueExpr='id'*/}
                                                        {/*    onSelectionChanged={this.handleVehicleTypeChange}*/}
                                                        {/*    showClearButton={true}*/}
                                                        {/*    disabled={false}*/}
                                                        {/*    searchEnabled={true}*/}
                                                        {/*/>*/}
                                                        <DropDownBox
                                                            value={this.state.serviceVehicleType}
                                                            valueExpr="id"
                                                            deferRendering={false}
                                                            displayExpr="vehicleType"
                                                            placeholder="Select vehicle type..."
                                                            showClearButton={true}
                                                            dataSource={this.state.vehicleTypeList}
                                                            onValueChanged={this.syncDataGridVehicleTypeSelection}
                                                            contentRender={this.dataGridVehicleTypeRender}
                                                        />
                                                    </div>
                                                    {this.state.errors["serviceVehicleType"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["serviceVehicleType"]}</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="panel">
                                            <div className="row">
                                                <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                    <h2 className="fs-title">Vehicle Information</h2>
                                                </div>
                                                <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5 text-right">
                                                    <h2 className="stepin">Step 3 - 4</h2>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                    <div className="form-group">
                                                        <label>Number of Vehicles</label>
                                                        <select className="form-control form-control-sm" value={this.state.selectedVehicleCount} onChange={this.handleVehicleChange}>
                                                            <option>1</option>
                                                            <option>2</option>
                                                            <option>3</option>
                                                            <option>4</option>
                                                            <option>5</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                                                    <div className="form-group float-right">
                                                        <label>&nbsp;</label>
                                                        <button type="submit" className="btn btn-md btn-danger btn-sm" disabled={this.state.isVehicleRowDisabled} onClick={this.removeLastVehicle}>Remove last vehicle record</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={stylevehicleheight}>
                                                <div id="vehicle_1">
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label><b>1.</b></label>&nbsp;<label>Vehicle Name</label>
                                                                <input className="form-control form-control-sm" type="text" id="vehicleName1" name="vehicleName1" maxLength="25" value={this.state.vehicleName1} onChange={this.handleInputChangeStep3} placeholder="Enter vehicle name" />
                                                            </div>
                                                            {this.state.errors["vehicleName1"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["vehicleName1"]}</span>}
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>Vehicle Type</label>
                                                                <SelectBox
                                                                    dataSource={vehicleTypes1.length > 0 ? vehicleTypes1 : []}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    placeholder="Select vehicle type"
                                                                    name="id"
                                                                    defaultValue={0}
                                                                    value={this.state.serviceVehicleType1}
                                                                    displayExpr='vehicleType'
                                                                    valueExpr='id'
                                                                    onSelectionChanged={this.handleVehicleType1Change}
                                                                    showClearButton={true}
                                                                    disabled={false}
                                                                    searchEnabled={true}
                                                                />
                                                            </div>
                                                            {this.state.errors["errVehicleType1"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["errVehicleType1"]}</span>}
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>Number of Seats</label>
                                                                <input className="form-control form-control-sm" type="text" id="numberOfSeats1" name="numberOfSeats1" maxLength="2" value={this.state.numberOfSeats1} onChange={this.handleInputChangeStep3} placeholder="Enter number of seats" />
                                                            </div>
                                                            {this.state.errors["numberOfSeats1"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["numberOfSeats1"]}</span>}
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>License Plate Number</label>
                                                                <input className="form-control form-control-sm" type="text" id="licensePlateNumber1" name="licensePlateNumber1" maxLength="25" value={this.state.licensePlateNumber1} onChange={this.handleInputChangeStep3} placeholder="Enter license plate number" />
                                                            </div>
                                                            {this.state.errors["licensePlateNumber1"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["licensePlateNumber1"]}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="vehicle_2" className="displayNone">
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label><b>2.</b></label>&nbsp;<label>Vehicle Name</label>
                                                                <input className="form-control form-control-sm" type="text" id="vehicleName2" name="vehicleName2" maxLength="25" value={this.state.vehicleName2} onChange={this.handleInputChangeStep3} placeholder="Enter vehicle name" />
                                                            </div>
                                                            {this.state.errors["vehicleName2"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["vehicleName2"]}</span>}
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>Vehicle Type</label>
                                                                <SelectBox
                                                                    dataSource={vehicleTypes2.length > 0 ? vehicleTypes2 : []}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    placeholder="Select vehicle type"
                                                                    name="id"
                                                                    defaultValue={0}
                                                                    value={this.state.serviceVehicleType2}
                                                                    displayExpr='vehicleType'
                                                                    valueExpr='id'
                                                                    onSelectionChanged={this.handleVehicleType2Change}
                                                                    showClearButton={true}
                                                                    disabled={false}
                                                                    searchEnabled={true}
                                                                />
                                                            </div>
                                                            {this.state.errors["errVehicleType2"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["errVehicleType2"]}</span>}
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>Number of Seats</label>
                                                                <input className="form-control form-control-sm" type="text" id="numberOfSeats2" name="numberOfSeats2" maxLength="2" value={this.state.numberOfSeats2} onChange={this.handleInputChangeStep3} placeholder="Enter number of seats" />
                                                            </div>
                                                            {this.state.errors["numberOfSeats2"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["numberOfSeats2"]}</span>}
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>License Plate Number</label>
                                                                <input className="form-control form-control-sm" type="text" id="licensePlateNumber2" name="licensePlateNumber2" maxLength="25" value={this.state.licensePlateNumber2} onChange={this.handleInputChangeStep3} placeholder="Enter license plate number" />
                                                            </div>
                                                            {this.state.errors["licensePlateNumber2"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["licensePlateNumber2"]}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="vehicle_3" className="displayNone">
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label><b>3.</b></label>&nbsp;<label>Vehicle Name</label>
                                                                <input className="form-control form-control-sm" type="text" id="vehicleName3" name="vehicleName3" maxLength="25" value={this.state.vehicleName3} onChange={this.handleInputChangeStep3} placeholder="Enter vehicle name" />
                                                            </div>
                                                            {this.state.errors["vehicleName3"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["vehicleName3"]}</span>}
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>Vehicle Type</label>
                                                                <SelectBox
                                                                    dataSource={vehicleTypes3.length > 0 ? vehicleTypes3 : []}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    placeholder="Select vehicle type"
                                                                    name="id"
                                                                    defaultValue={0}
                                                                    value={this.state.serviceVehicleType3}
                                                                    displayExpr='vehicleType'
                                                                    valueExpr='id'
                                                                    onSelectionChanged={this.handleVehicleType3Change}
                                                                    showClearButton={true}
                                                                    disabled={false}
                                                                    searchEnabled={true}
                                                                />
                                                            </div>
                                                            {this.state.errors["errVehicleType3"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["errVehicleType3"]}</span>}
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>Number of Seats</label>
                                                                <input className="form-control form-control-sm" type="text" id="numberOfSeats3" name="numberOfSeats3" maxLength="2" value={this.state.numberOfSeats3} onChange={this.handleInputChangeStep3} placeholder="Enter number of seats" />
                                                            </div>
                                                            {this.state.errors["numberOfSeats3"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["numberOfSeats3"]}</span>}
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>License Plate Number</label>
                                                                <input className="form-control form-control-sm" type="text" id="licensePlateNumber3" name="licensePlateNumber3" maxLength="25" value={this.state.licensePlateNumber3} onChange={this.handleInputChangeStep3} placeholder="Enter license plate number" />
                                                            </div>
                                                            {this.state.errors["licensePlateNumber3"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["licensePlateNumber3"]}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="vehicle_4" className="displayNone">
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label><b>4.</b></label>&nbsp;<label>Vehicle Name</label>
                                                                <input className="form-control form-control-sm" type="text" id="vehicleName4" name="vehicleName4" maxLength="25" value={this.state.vehicleName4} onChange={this.handleInputChangeStep3} placeholder="Vehicle name" />
                                                            </div>
                                                            {this.state.errors["vehicleName4"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["vehicleName4"]}</span>}
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>Vehicle Type</label>
                                                                <SelectBox
                                                                    dataSource={vehicleTypes4.length > 0 ? vehicleTypes4 : []}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    placeholder="Select vehicle type"
                                                                    name="id"
                                                                    defaultValue={0}
                                                                    value={this.state.serviceVehicleType4}
                                                                    displayExpr='vehicleType'
                                                                    valueExpr='id'
                                                                    onSelectionChanged={this.handleVehicleType4Change}
                                                                    showClearButton={true}
                                                                    disabled={false}
                                                                    searchEnabled={true}
                                                                />
                                                            </div>
                                                            {this.state.errors["errVehicleType4"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["errVehicleType4"]}</span>}
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>Number of Seats</label>
                                                                <input className="form-control form-control-sm" type="text" id="numberOfSeats4" name="numberOfSeats4" maxLength="2" value={this.state.numberOfSeats4} onChange={this.handleInputChangeStep3} placeholder="Enter number of seats" />
                                                            </div>
                                                            {this.state.errors["numberOfSeats4"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["numberOfSeats4"]}</span>}
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>License Plate Number</label>
                                                                <input className="form-control form-control-sm" type="text" id="licensePlateNumber4" name="licensePlateNumber4" maxLength="25" value={this.state.licensePlateNumber4} onChange={this.handleInputChangeStep3} placeholder="Enter license plate number" />
                                                            </div>
                                                            {this.state.errors["licensePlateNumber4"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["licensePlateNumber4"]}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="vehicle_5" className="displayNone">
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label><b>5.</b></label>&nbsp;<label>Vehicle Name</label>
                                                                <input className="form-control form-control-sm" type="text" id="vehicleName5" name="vehicleName5" maxLength="25" value={this.state.vehicleName5} onChange={this.handleInputChangeStep3} placeholder="Enter vehicle name" />
                                                            </div>
                                                            {this.state.errors["vehicleName5"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["vehicleName5"]}</span>}
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>Vehicle Type</label>
                                                                <SelectBox
                                                                    dataSource={vehicleTypes5.length > 0 ? vehicleTypes5 : []}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    placeholder="Select vehicle type"
                                                                    name="id"
                                                                    defaultValue={0}
                                                                    value={this.state.serviceVehicleType5}
                                                                    displayExpr='vehicleType'
                                                                    valueExpr='id'
                                                                    onSelectionChanged={this.handleVehicleType5Change}
                                                                    showClearButton={true}
                                                                    disabled={false}
                                                                    searchEnabled={true}
                                                                />
                                                            </div>
                                                            {this.state.errors["errVehicleType5"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["errVehicleType5"]}</span>}
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>Number of Seats</label>
                                                                <input className="form-control form-control-sm" type="text" id="numberOfSeats5" name="numberOfSeats5" maxLength="2" value={this.state.numberOfSeats5} onChange={this.handleInputChangeStep3} placeholder="Enter number of seats" />
                                                            </div>
                                                            {this.state.errors["numberOfSeats5"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["numberOfSeats5"]}</span>}
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>License Plate Number</label>
                                                                <input className="form-control form-control-sm" type="text" id="licensePlateNumber5" name="licensePlateNumber5" maxLength="25" value={this.state.licensePlateNumber5} onChange={this.handleInputChangeStep3} placeholder="Enter license plate number" />
                                                            </div>
                                                            {this.state.errors["licensePlateNumber5"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["licensePlateNumber5"]}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="panel">
                                            <div className="row">
                                                <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                    <h2 className="fs-title">Device Information</h2>
                                                </div>
                                                <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                    <h2 className="stepin">Step 4 - 4</h2>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                    <div className="form-group">
                                                        <label>Number of Devices</label>
                                                        <select className="form-control form-control-sm" onChange={this.handleDeviceChange} value={this.state.selectedDeviceCount}>
                                                            <option>1</option>
                                                            <option>2</option>
                                                            <option>3</option>
                                                            <option>4</option>
                                                            <option>5</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                                                    <div className="form-group float-right">
                                                        <label>&nbsp;</label>
                                                        <button type="submit" className="btn btn-md btn-danger btn-sm" disabled={this.state.isDeviceRowDisabled} onClick={this.removeLastDevice} title="Remove last device record">Remove last device record</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={styledeviceheight}>
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                        <div id="device_1">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                                    <div className="form-group">
                                                                        <label>Code</label>
                                                                        <div className="row">
                                                                            <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">+</div>
                                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8"><input className="form-control form-control-sm" type="text" placeholder="Code" maxLength="3" id="countryCode1" name="countryCode1" value={this.state.countryCode1} onChange={this.handleInputChangeStep4} /></div>
                                                                        </div>
                                                                    </div>
                                                                    {this.state.errors["countryCode1"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["countryCode1"]}</span>}
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                                    <div className="form-group">
                                                                        <label>Mobile</label>
                                                                        <NumberFormat className="form-control form-control-sm" id="mobile1" name="mobile1" title="mobile" value={this.state.mobile1} onChange={this.handleInputChangeStep4} format="(###) ###-####" />
                                                                    </div>
                                                                    {this.state.errors["mobile1"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["mobile1"]}</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                        <div id="device_2" className="displayNone">
                                                            <div className="row">
                                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                                    <div className="form-group">
                                                                        <label>Code</label>
                                                                        <div className="row">
                                                                            <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">+</div>
                                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8"><input className="form-control form-control-sm" type="text" placeholder="Code" maxLength="3" id="countryCode2" name="countryCode2" value={this.state.countryCode2} onChange={this.handleInputChangeStep4} /></div>
                                                                        </div>
                                                                    </div>
                                                                    {this.state.errors["countryCode2"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["countryCode2"]}</span>}
                                                                </div>
                                                                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                                                                    <div className="form-group">
                                                                        <label>Mobile</label>
                                                                        <NumberFormat className="form-control form-control-sm" id="mobile2" name="mobile2" title="mobile" value={this.state.mobile2} onChange={this.handleInputChangeStep4} format="(###) ###-####" />
                                                                    </div>
                                                                    {this.state.errors["mobile2"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["mobile2"]}</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                        <div id="device_3" className="displayNone">
                                                            <div className="row">
                                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                                    <div className="form-group">
                                                                        <label>Code</label>
                                                                        <div className="row">
                                                                            <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">+</div>
                                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8"><input className="form-control form-control-sm" type="text" placeholder="Code" maxLength="3" id="countryCode3" name="countryCode3" value={this.state.countryCode3} onChange={this.handleInputChangeStep4} /></div>
                                                                        </div>
                                                                    </div>
                                                                    {this.state.errors["countryCode3"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["countryCode3"]}</span>}
                                                                </div>
                                                                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                                                                    <div className="form-group">
                                                                        <label>Mobile</label>
                                                                        <NumberFormat className="form-control form-control-sm" id="mobile3" name="mobile3" title="mobile" value={this.state.mobile3} onChange={this.handleInputChangeStep4} format="(###) ###-####" />
                                                                    </div>
                                                                    {this.state.errors["mobile3"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["mobile3"]}</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                        <div id="device_4" className="displayNone">
                                                            <div className="row">
                                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                                    <div className="form-group">
                                                                        <label>Code</label>
                                                                        <div className="row">
                                                                            <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">+</div>
                                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8"><input className="form-control form-control-sm" type="text" placeholder="Code" maxLength="3" id="countryCode4" name="countryCode4" value={this.state.countryCode4} onChange={this.handleInputChangeStep4} /></div>
                                                                        </div>
                                                                    </div>
                                                                    {this.state.errors["countryCode4"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["countryCode4"]}</span>}
                                                                </div>
                                                                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                                                                    <div className="form-group">
                                                                        <label>Mobile</label>
                                                                        <NumberFormat className="form-control form-control-sm" id="mobile4" name="mobile4" title="mobile" value={this.state.mobile4} onChange={this.handleInputChangeStep4} format="(###) ###-####" />
                                                                    </div>
                                                                    {this.state.errors["mobile4"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["mobile4"]}</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                        <div id="device_5" className="displayNone">
                                                            <div className="row">
                                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                                    <div className="form-group">
                                                                        <label>Code</label>
                                                                        <div className="row">
                                                                            <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">+</div>
                                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8"><input className="form-control form-control-sm" type="text" placeholder="Code" maxLength="3" id="countryCode5" name="countryCode5" value={this.state.countryCode5} onChange={this.handleInputChangeStep4} /></div>
                                                                        </div>
                                                                    </div>
                                                                    {this.state.errors["countryCode5"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["countryCode5"]}</span>}
                                                                </div>
                                                                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                                                                    <div className="form-group">
                                                                        <label>Mobile</label>
                                                                        <NumberFormat className="form-control form-control-sm" id="mobile5" name="mobile5" title="mobile" value={this.state.mobile5} onChange={this.handleInputChangeStep4} format="(###) ###-####" />
                                                                    </div>
                                                                    {this.state.errors["mobile5"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["mobile5"]}</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                    <div className="form-group">
                                                        <label>&nbsp;</label>
                                                        <span className="badge badge-danger">Already Exist</span> <span className="badge badge-success">New</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <small>Note: Mobile number is valid with country code.</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="wizard__footer">
                                        <hr />
                                        <button className="btn btn-custom-gray btn-sm previous"><i className="fas fa-chevron-left"></i> Previous</button>&nbsp;&nbsp;
                                        <button className="btn btn-custom-gray btn-sm next">Next <i className="fas fa-chevron-right"></i></button>
                                    </div>
                                </div>
                                <div className="wizard__congrats-message">
                                    <div className="">
                                        <div className="card-body login-card-body">
                                            <p className="text-center"><i className="far fa-envelope fa-5x text-m"></i></p>
                                            <h4 className="text-center"><strong>Check your email</strong></h4>
                                            <p className="text-center">We've sent an email to your email address {this.state.defaultAccountSettings.email}</p>
                                            <p className="text-center"><button type="submit" className="btn btn-success" onClick={this.submitLogin} title="Go to Login"><i className="fas fa-sign-in-alt"></i> Go to Login</button></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    dataGridRender() {
        return (
            <DataGrid
                dataSource={this.state.locationList}
                id="grid-container"
                keyExpr="id"
                hoverStateEnabled={true}
                selectedRowKeys={this.state.serviceLocations}
                onSelectionChanged={this.onSelectionChanged}
                ref={ref => this.dataGrid = ref}>
                <Column dataField="name" caption="Location" />

                <Selection mode="multiple" />
                <Scrolling mode="both" />
                <Paging enabled={true} pageSize={10} />
                <FilterRow visible={true} />


            </DataGrid>
        );
    }

    dataGridAirportRender() {
        return (
            <DataGrid
                dataSource={this.state.airportList}
                id="grid-container"
                keyExpr="airportId"
                hoverStateEnabled={true}
                selectedRowKeys={this.state.serviceAirports}
                onSelectionChanged={this.onSelectionAirportChanged}
                ref={ref => this.dataGrid = ref}>
                <Column dataField="code" caption="Airport Code" />
                <Selection mode="multiple" />
                <Scrolling mode="both" />
                <Paging enabled={true} pageSize={5} />
                <FilterRow visible={true} />
            </DataGrid>
        );
    }

    dataGridVehicleTypeRender() {
        return (
            <DataGrid
                dataSource={this.state.vehicleTypeList}
                id="grid-container"
                keyExpr="id"
                hoverStateEnabled={true}
                selectedRowKeys={this.state.serviceVehicleType}
                onSelectionChanged={this.handleVehicleTypeChange}
                ref={ref => this.dataGrid = ref}>
                <Column dataField="vehicleType" caption="Vehicle Type" />
                <Selection mode="multiple" />
                <Scrolling mode="both" />
                <Paging enabled={false} pageSize={5} />
                <FilterRow visible={true} />
            </DataGrid>
        );
    }
}

export default AccountSetupComponent;