import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import RadioGroup from 'devextreme-react/radio-group';
import { SelectBox } from 'devextreme-react/select-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import NumberFormat from 'react-number-format';
import { CheckBox } from 'devextreme-react/check-box';
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'
const viewByRadio = [
    { id: 0, name: 'Check Register' },
    { id: 1, name: 'Payroll Audit Report' },
];

export class AuditReportComponent extends Component {
    constructor() {
        super()
        this.state = {
            isSubmitted: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            viewByList: viewByRadio,
            viewBy: viewByRadio[0],
            viewByType: "0",
            checkOnly: false,
            checkDueAmtGreaterZero: false,
            dropdownPayRollDate: [],
            payEndDate: '',
            radCheckType: "0",
            fromCheckNo: 0,
            toCheckNo: 0,
            singleCheckNo: "",
            radVehicle: "0",
            vehicleList: [],
            multiVehicleList: [],
            vehicleId: 0,
            fromVehicleId: 0,
            toVehicleId: 0,
            vehicleName: "",
            fromVehicleName: "",
            toVehicleName: "",
            errors: {
                payEndDate: "",
                fromCheckNo: 0,
                toCheckNo: 0,
                singleCheckNo: "",
                vehicleId: 0,
                fromVehicleId: 0,
                toVehicleId: 0,
            }
        }

        this.getVehicles = this.getVehicles.bind(this);
        this.getPayPeriod = this.getPayPeriod.bind(this);

        this.handleViewByTypeChange = this.handleViewByTypeChange.bind(this);
        this.handlePayPeriodChange = this.handlePayPeriodChange.bind(this);
        this.handleCheckTypeChange = this.handleCheckTypeChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleRadVehicleChange = this.handleRadVehicleChange.bind(this);
        this.handleVehicleChange = this.handleVehicleChange.bind(this);
        this.handleFromVehicleChange = this.handleFromVehicleChange.bind(this);
        this.handleToVehicleChange = this.handleToVehicleChange.bind(this);
        this.handleCheckOnlyValueChanged = this.handleCheckOnlyValueChanged.bind(this);
        this.handleDueAmtGreaterZeroValueChanged = this.handleDueAmtGreaterZeroValueChanged.bind(this);
        this.handleValidation = this.handleValidation.bind(this);

        this.handleAuditReportSubmit = this.handleAuditReportSubmit.bind(this);
        this.downloadAuditReportAsPdf = this.downloadAuditReportAsPdf.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
    }
    async componentDidMount() {
        this.setState({
            loadPanelVisible: true,
        });
        await this.getPayPeriod();
        await this.getVehicles();
        this.setState({
            loadPanelVisible: false,
        });
    }
    async getPayPeriod() {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('pay-period-api/0/dropdown-list', requestParams);
            const data = await response.json();
            this.setState({
                dropdownPayRollDate: data.vehiclePayPeriods,
            });

        } catch (e) {
            console.log(e);
        }
    }
    async getVehicles() {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/vehicle-api/dropdown-all', requestParams);
            const data = await response.json();
            this.setState({
                vehicleList: data.vehiclesDropdown,
                multiVehicleList: data.vehiclesDropdown,
            });
        } catch (e) {
            console.log(e);
        }
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getDriversDropdownList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleInputChange(event) {
        let cardType = this.state.cardTypeName;
        let cardObject = [];

        let errors = this.state.errors;
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: event.target.value });
    }
    handleCheckOnlyValueChanged(args) {
        this.setState({
            checkOnly: args.value
        });
    }
    handleDueAmtGreaterZeroValueChanged(args) {
        this.setState({
            checkDueAmtGreaterZero: args.value
        });
    }
    handlePayPeriodChange(selectedOption) {
        this.setState({ payEndDate: (selectedOption.selectedItem != null ? selectedOption.selectedItem.valueDate : ""), })
        this.state.errors["payEndDate"] = "";
    }
    handleCheckTypeChange(e) {
        this.setState({ radCheckType: e.target.value })
    }
    handleViewByTypeChange(e) {
        this.setState({
            viewBy: e.value,
        })
    }
    handleFromDateChanged(event) {
        let fromDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ startDate: fromDateValue })
    }
    handleToDateChanged(event) {
        let toDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ endDate: toDateValue })
    }
    handleSingleDateChanged(event) {
        let singleDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ singleDate: singleDateValue })
    }
    handleRadVehicleChange(e) {
        if (e.target.value == "0") {
            this.setState({
                radVehicle: e.target.value,
                vehicleId: 0,
                vehicleName: "",
                filterVehicleList: [],
            });
        }
        else if (e.target.value == "1") {
            this.setState({
                radVehicle: e.target.value,
                filterVehicleList: [],
            });
        }
        else {
            this.setState({
                radVehicle: e.target.value,
                vehicleId: 0,
                vehicleName: "",
            });

        }
        this.setState({ radVehicle: e.target.value });
    }
    handleVehicleChange(e) {
        if (e !== null) {
            this.setState({
                vehicleId: (e.selectedItem != null ? e.selectedItem.id : 0),
                vehicleName: (e.selectedItem != null ? e.selectedItem.name : ""),
            })
        }
        else {
            this.setState({
                vehicleId: 0,
                vehicleName: "",
            });
        }
    }
    handleFromVehicleChange(e) {
        if (e !== null) {
            this.setState({
                fromVehicleId: (e.selectedItem != null ? e.selectedItem.id : 0),
                fromVehicleName: (e.selectedItem != null ? e.selectedItem.name : ""),
            })
        }
        else {
            this.setState({
                fromVehicleId: 0,
                fromVehicleName: "",
            });
        }
    }
    handleToVehicleChange(e) {
        if (e !== null) {
            this.setState({
                toVehicleId: (e.selectedItem != null ? e.selectedItem.id : 0),
                toVehicleName: (e.selectedItem != null ? e.selectedItem.name : ""),
            })
        }
        else {
            this.setState({
                toVehicleId: 0,
                toVehicleName: "",
            });
        }
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isEmailSubmitted: false,
                isOpenModal: false,
                loadPanelVisible: false,
                viewByList: viewByRadio,
                viewBy: viewByRadio[0],
                viewByType: "0",
                checkOnly: false,
                checkDueAmtGreaterZero: false,
                payEndDate: '',
                radCheckType: "0",
                fromCheckNo: 0,
                toCheckNo: 0,
                singleCheckNo: "",
                radVehicle: "0",
                vehicleId: 0,
                fromVehicleId: 0,
                toVehicleId: 0,
                vehicleName: "",
                fromVehicleName: "",
                toVehicleName: "",
                errors: {
                    payEndDate: "",
                    fromCheckNo: 0,
                    toCheckNo: 0,
                    singleCheckNo: "",
                    vehicleId: 0,
                    fromVehicleId: 0,
                    toVehicleId: 0,
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleValidation(e) {
        let errors = this.state.errors;
        let formIsValid = true;

        errors.customerTypeId = 0;
        errors.singleCheckNo = "";
        errors.fromCheckNo = "";
        errors.toCheckNo = "";
        errors.vehicleId = "";
        errors.filterVehicleList = "";

        this.setState({ message: '' });

        if (this.state.payEndDate == "") {
            formIsValid = false;
            errors.payEndDate = "Please select payment period";
        }

        if (this.state.radCheckType == "1") {
            if (this.state.singleCheckNo == "") {
                formIsValid = false;
                errors.singleCheckNo = "Please Enter check no";
            }
        }

        if (this.state.radCheckType == "2") {
            if (this.state.fromCheckNo == "") {
                formIsValid = false;
                errors.fromCheckNo = "Please Enter from check no";
            }

            if (this.state.toCheckNo == "") {
                formIsValid = false;
                errors.toCheckNo = "Please Enter to check no";
            }
        }

        if (this.state.radVehicle == "1") {
            if (this.state.vehicleId == 0) {
                formIsValid = false;
                errors.vehicleId = "Please Select vehicle";
            }
        }

        if (this.state.radVehicle == "2") {
            if (this.state.fromVehicleId == 0) {
                formIsValid = false;
                errors.fromVehicleId = "Please select from vehicle";
            }

            if (this.state.toVehicleId == 0) {
                formIsValid = false;
                errors.toVehicleId = "Please select to vehicle";
            }
        }


        this.setState({ errors: errors });
        return formIsValid;
    }
    handleAuditReportSubmit(e) {
        e.preventDefault();
        this.downloadAuditReportAsPdf(e);
    }
    async downloadAuditReportAsPdf(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            try {
                this.setState({
                    loadPanelVisible: true,
                    isSubmited: true,
                });

                let criteria1 = "", selectionCriteria = "";
                criteria1 = "Payment Period: " + formatDate(this.state.payEndDate, "MM/dd/yyyy");
                if (this.state.radVehicle == "0") {
                    criteria1 = (criteria1 + (((criteria1 != "") ? ", " : "") + "All Vehicles"));
                }
                else if (this.state.radVehicle == "1") {
                    criteria1 = (criteria1 + (((criteria1 != "") ? ", " : "") + ("for Vehicle:" + this.state.vehicleName)));
                }
                else if (this.state.radVehicle == "2") {
                    criteria1 = (criteria1
                        + (((criteria1 != "") ? ", " : "") + ("for vehicle range: "
                            + (this.state.fromVehicleName + (" to " + this.state.toVehicleName)))));
                }

                if (this.state.radCheckType == "0") {
                    criteria1 = (criteria1 + (((criteria1 != "") ? ", " : "") + "All Checks"));
                }
                else if (this.state.radCheckType == "1") {
                    criteria1 = (criteria1 + (((criteria1 != "") ? ", " : "") + ("for check:" + this.state.singleCheckNo)));
                }
                else if (this.state.radCheckType == "2") {
                    criteria1 = (criteria1 + (((criteria1 != "") ? ", " : "") + ("for checks range: "
                        + (parseInt(this.state.fromCheckNo) + (" to " + parseInt(this.state.toCheckNo))))));
                }

                if (this.state.checkOnly) {
                    if (this.state.viewBy.id == 0) {
                        selectionCriteria = (selectionCriteria
                            + (((selectionCriteria != "") ? " And " : "") + " {Proc_Select_VehiclePayRoll_AuditReport;1.Check_No} > '0' "));
                    }
                    else {
                        selectionCriteria = (selectionCriteria
                            + (((selectionCriteria != "") ? " And " : "") + "{Proc_Select_ACCheck_Register;1.Check_No} > '0'"));
                    }
                }

                if (this.state.checkDueAmtGreaterZero) {
                    if (this.state.viewBy.id == 0) {
                        selectionCriteria = (selectionCriteria
                            + (((selectionCriteria != "") ? " And " : "") + " {Proc_Select_VehiclePayRoll_AuditReport;1.Check_Amount} > 0"));
                    }
                    else {
                        selectionCriteria = (selectionCriteria
                            + (((selectionCriteria != "") ? " And " : "") + " {Proc_Select_ACCheck_Register;1.Check_Amount} > 0"));
                    }
                }

                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.fileFormat = 'pdf';
                request.MethodName = "GetAuditReport";
                request.PayPeriodDate = formatDate(this.state.payEndDate, "MM/dd/yyyy");
                if (this.state.radVehicle == "1") {
                    request.FromVehicleNo = this.state.vehicleName;
                    request.ToVehicleNo = this.state.vehicleName;
                }
                else {
                    request.FromVehicleNo = this.state.fromVehicleName;
                    request.ToVehicleNo = this.state.toVehicleName;
                }
                if (this.state.radCheckType == "1") {
                    request.FromCheckNo = parseInt(this.state.singleCheckNo);
                    request.ToCheckNo = parseInt(this.state.singleCheckNo);
                }
                else {
                    request.FromCheckNo = parseInt(this.state.fromCheckNo);
                    request.ToCheckNo = parseInt(this.state.toCheckNo);
                }
                request.IsViewCheckRegister = this.state.viewBy.id == 0 ? true : false;
                request.SelectionCrieteria = selectionCriteria;
                request.Criteria1 = criteria1;

                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();

                this.setState({
                    loadPanelVisible: false,
                    isSubmited: false,
                });

                let a = document.createElement('a');
                a.href = dataResponse.reportBase64String;
                a.download = 'AuditReport.pdf';
                a.click();

            } catch (e) {
                //debugger;
            }
        }
    }
    handleClearSearch() {
        this.setState({
            isSubmitted: false,
            isEmailSubmitted: false,
            isOpenModal: true,
            loadPanelVisible: false,
            viewByList: viewByRadio,
            viewBy: viewByRadio[0],
            viewByType: "0",
            checkOnly: false,
            checkDueAmtGreaterZero: false,
            payEndDate: '',
            radCheckType: "0",
            fromCheckNo: 0,
            toCheckNo: 0,
            singleCheckNo: "",
            radVehicle: "0",
            vehicleId: 0,
            fromVehicleId: 0,
            toVehicleId: 0,
            vehicleName: "",
            fromVehicleName: "",
            toVehicleName: "",
            errors: {
                payEndDate: "",
                fromCheckNo: 0,
                toCheckNo: 0,
                singleCheckNo: "",
                vehicleId: 0,
                fromVehicleId: 0,
                toVehicleId: 0,
            }
        });
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isEmailSubmitted: true });
                let criteria1 = "", selectionCriteria = "";
                criteria1 = "Payment Period: " + formatDate(this.state.payEndDate, "MM/dd/yyyy");
                if (this.state.radVehicle == "0") {
                    criteria1 = (criteria1 + (((criteria1 != "") ? ", " : "") + "All Vehicles"));
                }
                else if (this.state.radVehicle == "1") {
                    criteria1 = (criteria1 + (((criteria1 != "") ? ", " : "") + ("for Vehicle:" + this.state.vehicleName)));
                }
                else if (this.state.radVehicle == "2") {
                    criteria1 = (criteria1
                        + (((criteria1 != "") ? ", " : "") + ("for vehicle range: "
                            + (this.state.fromVehicleName + (" to " + this.state.toVehicleName)))));
                }

                if (this.state.radCheckType == "0") {
                    criteria1 = (criteria1 + (((criteria1 != "") ? ", " : "") + "All Checks"));
                }
                else if (this.state.radCheckType == "1") {
                    criteria1 = (criteria1 + (((criteria1 != "") ? ", " : "") + ("for check:" + this.state.singleCheckNo)));
                }
                else if (this.state.radCheckType == "2") {
                    criteria1 = (criteria1 + (((criteria1 != "") ? ", " : "") + ("for checks range: "
                        + (parseInt(this.state.fromCheckNo) + (" to " + parseInt(this.state.toCheckNo))))));
                }

                if (this.state.checkOnly) {
                    if (this.state.viewBy.id == 0) {
                        selectionCriteria = (selectionCriteria
                            + (((selectionCriteria != "") ? " And " : "") + " {Proc_Select_VehiclePayRoll_AuditReport;1.Check_No} > '0' "));
                    }
                    else {
                        selectionCriteria = (selectionCriteria
                            + (((selectionCriteria != "") ? " And " : "") + "{Proc_Select_ACCheck_Register;1.Check_No} > '0'"));
                    }
                }

                if (this.state.checkDueAmtGreaterZero) {
                    if (this.state.viewBy.id == 0) {
                        selectionCriteria = (selectionCriteria
                            + (((selectionCriteria != "") ? " And " : "") + " {Proc_Select_VehiclePayRoll_AuditReport;1.Check_Amount} > 0"));
                    }
                    else {
                        selectionCriteria = (selectionCriteria
                            + (((selectionCriteria != "") ? " And " : "") + " {Proc_Select_ACCheck_Register;1.Check_Amount} > 0"));
                    }
                }

                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.fileFormat = 'pdf';
                request.MethodName = "GetAuditReport";
                request.PayPeriodDate = formatDate(this.state.payEndDate, "MM/dd/yyyy");
                if (this.state.radVehicle == "1") {
                    request.FromVehicleNo = this.state.vehicleName;
                    request.ToVehicleNo = this.state.vehicleName;
                }
                else {
                    request.FromVehicleNo = this.state.fromVehicleName;
                    request.ToVehicleNo = this.state.toVehicleName;
                }
                if (this.state.radCheckType == "1") {
                    request.FromCheckNo = parseInt(this.state.singleCheckNo);
                    request.ToCheckNo = parseInt(this.state.singleCheckNo);
                }
                else {
                    request.FromCheckNo = parseInt(this.state.fromCheckNo);
                    request.ToCheckNo = parseInt(this.state.toCheckNo);
                }
                request.IsViewCheckRegister = this.state.viewBy.id == 0 ? true : false;
                request.SelectionCrieteria = selectionCriteria;
                request.Criteria1 = criteria1;

                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Payout Report';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = 'AuditReport.pdf'
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '60%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleAuditReportSubmit}
                        style={(this.state.isSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Payout Report'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>View By</label>
                                        <RadioGroup id="radio-group-viewby" dataSource={this.state.viewByList} displayExpr="name" value={this.state.viewBy} onValueChanged={this.handleViewByTypeChange} layout="horizontal" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <br />
                                        <CheckBox defaultValue={false} width={100} text="Checks Only" value={this.state.checkOnly} onValueChanged={this.handleCheckOnlyValueChanged} />
                                        <CheckBox defaultValue={false} width={250} text="Due amount greater than zero value" value={this.state.checkDueAmtGreaterZero} onValueChanged={this.handleDueAmtGreaterZeroValueChanged} /></div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Payment Period</label>
                                        <SelectBox
                                            dataSource={this.state.dropdownPayRollDate.length > 0 ? this.state.dropdownPayRollDate : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select payroll date"
                                            name="payEndDate"
                                            defaultValue={[]}
                                            value={this.state.payEndDate}
                                            displayExpr='viewDate'
                                            valueExpr='valueDate'
                                            onSelectionChanged={this.handlePayPeriodChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["payEndDate"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["payEndDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radCheckType" value="0" checked={this.state.radCheckType === "0"} onChange={this.handleCheckTypeChange} />
                                            <label className="form-check-label">All Check</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radCheckType" value="1" checked={this.state.radCheckType === "1"} onChange={this.handleCheckTypeChange} />
                                            <label className="form-check-label">Single Check</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radCheckType" value="2" checked={this.state.radCheckType === "2"} onChange={this.handleCheckTypeChange} />
                                            <label className="form-check-label">Range</label>
                                        </span>
                                        <NumberFormat className="form-control form-control-sm" name="singleCheckNo"
                                            maxLength="15" value={this.state.singleCheckNo}
                                            onChange={this.handleInputChange}
                                            placeholder="Single check no."
                                            disabled={this.state.radCheckType == "1" ? false : true}
                                        />
                                        {this.state.errors["singleCheckNo"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["singleCheckNo"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>From Check No.</label>
                                        <NumberFormat className="form-control form-control-sm" name="fromCheckNo"
                                            maxLength="15" value={this.state.fromCheckNo}
                                            onChange={this.handleInputChange}
                                            placeholder="From check no."
                                            disabled={this.state.radCheckType == "2" ? false : true}
                                        />
                                        {this.state.errors["fromCheckNo"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["fromCheckNo"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>To Check No.</label>
                                        <NumberFormat className="form-control form-control-sm" name="toCheckNo"
                                            maxLength="15" value={this.state.toCheckNo}
                                            onChange={this.handleInputChange}
                                            placeholder="To check no."
                                            disabled={this.state.radCheckType == "2" ? false : true}
                                        />
                                        {this.state.errors["toCheckNo"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["toCheckNo"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radVehicle" value="0" checked={this.state.radVehicle === "0"} onChange={this.handleRadVehicleChange} />
                                            <label className="form-check-label">All Vehicle</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radVehicle" value="1" checked={this.state.radVehicle === "1"} onChange={this.handleRadVehicleChange} />
                                            <label className="form-check-label">Single Vehicle</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radVehicle" value="2" checked={this.state.radVehicle === "2"} onChange={this.handleRadVehicleChange} />
                                            <label className="form-check-label">Range</label>
                                        </span>
                                        <SelectBox
                                            dataSource={this.state.vehicleList.length > 0 ? this.state.vehicleList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select vehicle"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.vehicleId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleVehicleChange}
                                            showClearButton={true}
                                            disabled={this.state.radVehicle === "1" ? false : true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["vehicleId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["vehicleId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Multiple Vehicles</label>
                                        <SelectBox
                                            dataSource={this.state.vehicleList.length > 0 ? this.state.vehicleList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select from vehicle"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.fromVehicleId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleFromVehicleChange}
                                            showClearButton={true}
                                            disabled={this.state.radVehicle === "2" ? false : true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["fromVehicleId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["fromVehicleId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>&nbsp;</label>
                                        <SelectBox
                                            dataSource={this.state.vehicleList.length > 0 ? this.state.vehicleList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select to vehicle"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.toVehicleId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleToVehicleChange}
                                            showClearButton={true}
                                            disabled={this.state.radVehicle === "2" ? false : true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["toVehicleId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["toVehicleId"]}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="submit" className="btn btn-success btn-sm" title="Download" onClick={this.handleAuditReportSubmit} >
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                                </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}