import DataGrid, {
    Column,


    Scrolling, Selection
} from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import { LoadPanel } from 'devextreme-react/load-panel';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { getRequestParams } from '../../helpers/globalfunctions.js';

export const mailPriorityDropdownList = [{
    'id': 0,
    'name': 'Normal',
}, {
    'id': 1,
    'name': 'Low',
}, {
    'id': 2,
    'name': 'High',
}]

const customStylesEmail = {
    content: {
        width: '20%'
    }
};

export class InvoiceEmailComponent extends Component {
    constructor() {
        super()
        this.state = {
            isSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            isEmailBCc: false,
            emailTo: '',
            emailCc: '',
            emailBcc: '',
            emailSubject: '',
            emailBody: '',
            emailNotes:'',
            attachments: [],
            isSendAttachment: false,
            mailPriority: 0,
            fileNameDisplay: '',

            invoiceNo: 0,
            customerId: 0,

            isOpenEmailListModal: false,
            isEmailListSubmited: false,
            selectedRowKeys: [],
            emailList: [],

            wireInstructionList:[],

            errors: {
                emailTo: '',
                emailCc: '',
                emailBcc: '',
                emailSubject: '',
            }
        }

        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.closeEmailListModal = this.closeEmailListModal.bind(this);
        this.handleEmailListSubmit = this.handleEmailListSubmit.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.getEmailList = this.getEmailList.bind(this);
    }
    showModal = async (_invoiceNo, _customerId, emailTo, emailCc, emailSubject, emailBody, attachments) => {
        try {
            this.setState({ isOpenModal: true });
            this.setState({
                invoiceNo: _invoiceNo,
                customerId: _customerId,
                emailTo: emailTo,
                emailCc: emailCc,
                emailBody: emailBody,
                emailSubject: emailSubject,
                attachments: attachments,
                isSendAttachment: (attachments !== null && attachments.length !== 0)
            });
            let fileNameDisplay = '';
            if (attachments !== null && attachments.length !== 0) {
                attachments.forEach((file) => {
                    fileNameDisplay += (fileNameDisplay.trim().length === 0) ? file.fileName : ' , ' + file.fileName;
                });
            }
            this.setState({ fileNameDisplay: fileNameDisplay });
        } catch (ex) {
            console.log(ex);
        }
    }
     handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getWireInstructionList()
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isOpenModal: false,
                loadPanelVisible: false,
                isEmailBCc: false,
                emailTo: '',
                emailCc: '',
                emailBcc: '',
                emailSubject: '',
                emailBody: '',
                emailNotes: '',
                attachments: [],
                isSendAttachment: false,
                mailPriority: 0,
                fileNameDisplay: '',

                isOpenEmailListModal: false,
                isEmailListSubmited: false,
                selectedRowKeys: [],
                emailList: [],

                errors: {
                    emailTo: '',
                    emailCc: '',
                    emailBcc: '',
                    emailSubject: '',
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnClearSearch = async () => {
        try {
            this.setState({
                isSubmitted: false,
                loadPanelVisible: false,
                isEmailBCc: false,
                emailTo: '',
                emailCc: '',
                emailBcc: '',
                emailSubject: '',
                emailBody: '',
                emailNotes: '',
                isSendAttachment: (this.state.attachments !== null && this.state.attachments.length !== 0),
                mailPriority: 0,

                isOpenEmailListModal: false,
                isEmailListSubmited: false,
                selectedRowKeys: [],
                emailList: [],

                errors: {
                    emailTo: '',
                    emailCc: '',
                    emailBcc: '',
                    emailSubject: '',
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnInputChange = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
            let errors = this.state.errors;
            let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            switch (name) {
                case 'emailTo':
                    errors.emailTo = '';
                    if (value.length < 501) {
                        //if (value.trim() !== '') {
                        //    let email = value.split(',');
                        //    if (email.length > 0) {
                        //        for (let i = 0; i < email.length; i++) {
                        //            if ((email[i]).trim() !== "") {
                        //                if (!regEmail.test(String(email[i].trim()).toLowerCase())) {
                        //                    errors.emailTo = "Please enter valid email.";
                        //                }
                        //            }
                        //        }
                        //    }
                        //}
                        this.setState({ emailTo: value })
                    }
                    break;
                case 'emailCc':
                    errors.emailCc = '';
                    if (value.length < 501) {
                        //if (value.trim() !== '') {
                        //    let email = value.split(',');
                        //    if (email.length > 0) {
                        //        for (let i = 0; i < email.length; i++) {
                        //            if ((email[i]).trim() !== "") {
                        //                if (!regEmail.test(String(email[i].trim()).toLowerCase())) {
                        //                    errors.emailCc = "Please enter valid email.";
                        //                }
                        //            }
                        //        }
                        //    }
                        //}
                        this.setState({ emailCc: value })
                    }
                    break;
                case 'emailBcc':
                    errors.emailBcc = '';
                    if (value.length < 501) {
                        //if (value.trim() !== '') {
                        //    let email = value.split(',');
                        //    if (email.length > 0) {
                        //        for (let i = 0; i < email.length; i++) {
                        //            if ((email[i]).trim() !== "") {
                        //                if (!regEmail.test(String(email[i].trim()).toLowerCase())) {
                        //                    errors.emailBcc = "Please enter valid email.";
                        //                }
                        //            }
                        //        }
                        //    }
                        //}
                        this.setState({ emailBcc: value })
                    }
                    break;
                case 'emailSubject':
                    errors.emailSubject = '';
                    if (value.length < 151) {
                        this.setState({ emailSubject: value });
                    }
                    break; 
                case 'emailBody':
                    if (value.length < 501) {
                        this.setState({ emailBody: value });
                    }
                    break;
                case 'emailNotes':
                    if (value.length < 501) {
                        this.setState({ emailNotes: value });
                    }
                    break;
                case 'isSendAttachment':
                    this.setState({ isSendAttachment: value });
                    break;
                case 'mailPriority':
                    this.setState({ mailPriority: value });
                    break;
                default:
                    break;
            }
            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    isFormValid = async () => {
        let isValid = true;
        try {
            let errors = this.state.errors;
            errors.emailTo = '';
            errors.emailCc = '';
            errors.emailBcc = '';
            errors.emailSubject = '';
            let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (this.state.emailTo.trim() === '') {
                isValid = false;
                errors.emailTo = "Please enter email.";
            }
            if (this.state.emailTo.trim() !== '') {
                let email = this.state.emailTo.split(',');
                if (email.length > 0) {
                    for (let i = 0; i < email.length; i++) {
                        if ((email[i]).trim() !== "") {
                            if (!regEmail.test(String(email[i].trim()).toLowerCase())) {
                                isValid = false;
                                errors.emailTo = "Please enter valid email.";
                            }
                        }
                    }
                }
            }
            if (this.state.emailCc.trim() !== '') {
                let email = this.state.emailCc.split(',');
                if (email.length > 0) {
                    for (let i = 0; i < email.length; i++) {
                        if ((email[i]).trim() !== "") {
                            if (!regEmail.test(String(email[i].trim()).toLowerCase())) {
                                isValid = false;
                                errors.emailCc = "Please enter valid email.";
                            }
                        }
                    }
                }
            }
            if (this.state.emailBcc.trim() !== '') {
                let email = this.state.emailBcc.split(',');
                if (email.length > 0) {
                    for (let i = 0; i < email.length; i++) {
                        if ((email[i]).trim() !== "") {
                            if (!regEmail.test(String(email[i].trim()).toLowerCase())) {
                                isValid = false;
                                errors.emailBcc = "Please enter valid email.";
                            }
                        }
                    }
                }
            }
            if (this.state.emailSubject.trim() === '') {
                isValid = false;
                errors.emailSubject = "Please enter subject.";
            }
            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    handleOnSendEmail = async () => {
        try {
            if (await this.isFormValid()) {
                this.setState({ isSubmitted: true });
                let fileGuid = [];
                var request = new Object();
                if (this.state.isSendAttachment === true) {
                    if (this.state.attachments !== null && this.state.attachments.length !== 0) {
                        for (var i = 0; i < this.state.attachments.length; i++) {
                            request = new Object();
                            request.FileData = this.state.attachments[i].fileData;
                            request.FileName = this.state.attachments[i].fileName;
                            request.Extension = this.state.attachments[i].fileExtension;
                            let requestFileParams = getRequestParams('POST', request);
                            let responseFile = await fetch('report-api/upload-notification-attachment', requestFileParams);
                            let dataResponseFile = await responseFile.json();
                            if (dataResponseFile.isSuccess) {
                                fileGuid.push(dataResponseFile.fileGuid);
                            }
                        }
                    }
                }
                request = new Object();
                request.EmailTo = this.state.emailTo.trim();
                request.EmailCC = this.state.emailCc.trim();
                request.EmailBCC = this.state.emailBcc.trim();
                request.Subject = this.state.emailSubject.trim();
                request.Body = this.getEmailBody();
                request.MobileNo = '';
                request.Header = '';
                request.Footer = '';
                request.NotificationTypeId = 1;
                request.NotificationPriority = Number(this.state.mailPriority);
                request.FileGuid = fileGuid;
                request.InvoiceNo = Number(this.state.invoiceNo);
                request.QuoteId = Number(0);
                request.RideId = Number(0);
                let requestParams = getRequestParams('POST', request);
                let response = await fetch('report-api/send-notification', requestParams);
                if (response.ok) {
                    const data = await response.json();
                    this.setState({ isSubmitted: false });
                    await Swal.fire({
                        allowOutsideClick: false,
                        icon: data.isSuccess ? 'success' : 'error',
                        title: data.isSuccess ? 'E-mail Sent' : 'Oops...',
                        text: (data.isSuccess === false && (data.resultMessage === null || data.resultMessage === "")) ? 'Unable to process the email request.' : 'The email request has been sent and you will receive it shortly.',
                        confirmButtonText: 'Thank you!',
                    }).then(async (result) => {
                        if (data.isSuccess) {
                            if (result.isConfirmed) {

                                await this.handleOnCloseModal();
                                this.props.getApprovedInvoices();
                            }
                        }
                    })
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false });
    }

    getEmailBody() {

        var lStrMailBody = "";
        if (this.state.wireInstructionList.length <= 0) {
            return lStrMailBody;
        }

        var customerDetials = this.state.wireInstructionList[0];
       
        lStrMailBody = lStrMailBody + "<HTML>" + "\r\n";
        lStrMailBody = lStrMailBody + "<HEAD>" + "\r\n";
        lStrMailBody = lStrMailBody + "</HEAD>" + "\r\n";
        lStrMailBody = lStrMailBody + "<BODY>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TABLE width='70%' cellpadding='2' cellspacing='1'>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD colspan='2'>Dear Customer,<BR/><BR/></TD></TR>" + "\r\n";

        lStrMailBody = lStrMailBody + "</TABLE>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TABLE width='70%' cellpadding='2' cellspacing='1'>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD colspan='2'><B>All Mail Correspondence; Letters, Documents & Notices (No Checks)</B></TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD colspan='2'>Send To New York Primary Office Location Below:<BR/><BR/></TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD colspan='2'>" + customerDetials.vendorName + "</TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD colspan='2'>" + customerDetials.vendorAddress1 + "</TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD colspan='2'>" + customerDetials.vendorAddress2 + "<BR/><BR/></TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD colspan='2'><B>For Check Payments, Please Mail To:</B><BR/></TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD colspan='2'>" + sessionStorage.getItem("session_accountlegalname") + "<BR/></TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD colspan='2'>Attn: Accounts Receivable</TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD colspan='2'>" + sessionStorage.getItem("session_accountaddress") + "</TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD colspan='2' width=650><HR size='1'><BR/></TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD colspan='2'></TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD colspan='2'><B>Wiring Instructions are as follows:</B></TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD width=150>Account Name:</TD><TD>" + customerDetials.accountName + "</TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD width=150>Beneficiary:</TD><TD>" + customerDetials.beneficiary + "</TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD>Sub-Name:</TD><TD>" + customerDetials.accountSubName + "</TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD>Routing for Wire. #</TD><TD>" + customerDetials.wireRoutingNo + "</TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD>Routing for ACH Debits and Credits #</TD><TD>" + customerDetials.achRoutingNo + "</TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD>Account #</TD><TD>" + customerDetials.accountNo + "</TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD>Bank Name:</TD><TD>" + customerDetials.bankName + "</TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD>Bank Name:</TD><TD>" + customerDetials.bankAddress + "</TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD valign=TOP>Reference:</TD><TD>" + customerDetials.reference + "</TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD colspan='2' width=650><HR size='1'><BR/></TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD colspan='2'><B><U>International</U> Wiring Instructions are as follows:</B></TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD>Account Name:</TD><TD>" + customerDetials.accountNameInt + "</TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD>Beneficiary:</TD><TD>" + customerDetials.beneficiaryInt + "</TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD>Account Sub Name:</TD><TD>" + customerDetials.accountSubNameInt + "</TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD>Routing for Wire.:</TD><TD>" + customerDetials.wireRoutingNoInt + "</TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD>Routing for ACH Debits and Credits:</TD><TD>" + customerDetials.achRoutingNoInt + "</TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD>Account #:</TD><TD>" + customerDetials.accountNoInt + "</TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "</TABLE>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TABLE width='70%' cellpadding='2' cellspacing='1'>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD width=300>Swift Code for International Wires Only:</TD><TD>" + customerDetials.swiftCodeInt + "</TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD width=300>Bank Name for International Wires Only:</TD><TD>" + customerDetials.bankNameInt + "</TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD width=300>Bank Address for International Wires Only:</TD><TD>" + customerDetials.bankAddressInt + "</TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "</TABLE>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TABLE width='70%' cellpadding='2' cellspacing='1'>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD><BR/></TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD width=150 valign=TOP>Reference:</TD><TD>" + customerDetials.referenceInt + "</TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD colspan='2' width=650><HR size='1'></TD></TR>" + "\r\n";
        if (customerDetials.checkPayableName != '') {
            lStrMailBody = lStrMailBody + "<TR><TD colspan='2'><B>By Check:</B></TD></TR>" + "\r\n";
            lStrMailBody = lStrMailBody + "<TR><TD width=150>Make Check Payable to:</TD><TD>" + customerDetials.checkPayableName + "</TD></TR>" + "\r\n";
            lStrMailBody = lStrMailBody + "<TR><TD width=150>Address:</TD><TD>" + customerDetials.checkPayableAddress + "</TD></TR>" + "\r\n";
            lStrMailBody = lStrMailBody + "<TR><TD valign=TOP>Check Memo:</TD><TD>" + customerDetials.checkMemo + "</TD></TR>" + "\r\n";
            lStrMailBody = lStrMailBody + "<TR><TD colspan='2' width=650><HR size='1'><BR/></TD></TR>" + "\r\n";
        }
        lStrMailBody = lStrMailBody + "</TABLE>  " + "\r\n";
        lStrMailBody = lStrMailBody + "<TABLE width='70%' cellpadding='2' cellspacing='1'>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD>" + "\r\n";
        if (this.state.emailNotes.toString().trim().length > 0) {
            lStrMailBody = lStrMailBody + "<BR/><B>Note : </B> " + this.state.emailNotes + "<BR/>" + "\r\n";
        }
        lStrMailBody = lStrMailBody + "<BR/>This message contains the file below an attachment:" + "\r\n";
        lStrMailBody = lStrMailBody + "<BR/><I>Attachment: " + this.state.fileNameDisplay + "</I><BR/>" + "\r\n";

        //lStrMailBody = lStrMailBody + "<BR/>We are required to collect taxes on the fuel surcharge. To accomplish this, we have combined " + "\r\n";
        //lStrMailBody = lStrMailBody + "<BR/>the surcharge with the fare so that taxes will properly calculate. " + "\r\n";
        //lStrMailBody = lStrMailBody + "<BR/>" + "\r\n";

        lStrMailBody = lStrMailBody + "<TABLE width='70%' cellpadding='2' cellspacing='1'>" + "\r\n";
        lStrMailBody = lStrMailBody + "<TR><TD colspan='3'><BR/><B style='font-size:14px;'>Payment is due upon receipt of invoice.</B></TD></TR>" + "\r\n";
        //lStrMailBody = lStrMailBody + "<TR><TD colspan='3'><Span style='font-size:15px;'>For billing inquiries please call/email : </Span></TD></TR>" + "\r\n";
        //lStrMailBody = lStrMailBody + "<TR><TD><Span style='font-size:12px;font-weight: bold;'>Sandra King</Span>" + "\r\n";
        //lStrMailBody = lStrMailBody + "<BR/><Span style='font-size:12px;'>sking@gttny.com</Span>" + "\r\n";
        //lStrMailBody = lStrMailBody + "<BR/><Span style='font-size:12px;'>718-886-5204 x 7072630</Span></TD>" + "\r\n";
        //lStrMailBody = lStrMailBody + "<TD><Span style='font-size:12px;font-weight: bold;'>Yicel Pena</Span>" + "\r\n";
        //lStrMailBody = lStrMailBody + "<BR/><Span style='font-size:12px;'>ypena@gttny.com</Span>" + "\r\n";
        //lStrMailBody = lStrMailBody + "<BR/><Span style='font-size:12px;'>718-886-5204 x 7072763</Span></TD>" + "\r\n";
        //lStrMailBody = lStrMailBody + "<TD><Span style='font-size:12px;font-weight: bold;'>Stefania Quintero</Span>" + "\r\n";
        //lStrMailBody = lStrMailBody + "<BR/><Span style='font-size:12px;'>squintero@gttny.com</Span>" + "\r\n";
        //lStrMailBody = lStrMailBody + "<BR/><Span style='font-size:12px;'>718-886-5204 x 7072782</Span>" + "\r\n</TD></TR>";
        lStrMailBody = lStrMailBody + "</Table>" + "\r\n";
        lStrMailBody = lStrMailBody + "<BR/>" + "\r\n";
        lStrMailBody = lStrMailBody + "<BR/>Regards," + "\r\n";
        lStrMailBody = lStrMailBody + "<BR/>" + sessionStorage.getItem("session_accountname") + " Team," + "\r\n";
        lStrMailBody = lStrMailBody + "<BR/>" + sessionStorage.getItem("session_accountwebaddress") + "\r\n";
        lStrMailBody = lStrMailBody + "</TD></TR>" + "\r\n";
        lStrMailBody = lStrMailBody + "</TABLE>" + "\r\n";
        lStrMailBody = lStrMailBody + "</BODY>" + "\r\n";
        lStrMailBody = lStrMailBody + "</HTML>" + "\r\n";

        return lStrMailBody;
    }

    onBodyChange = (value) => {
        this.setState({ emailBody: value });

    };

    async getWireInstructionList() {
        var request = new Object();
        request.isListView = false;
        request.accountCustomerSettingWireInstructionId = 0;

        const requestParams = getRequestParams('POST', request);
        const response = await fetch('customer-api/wiringInstruction/' + this.state.customerId, requestParams);
        const data = await response.json();
        this.setState({
            wireInstructionList: data.wiringInstructions
        });
    }

    closeEmailListModal() {
        this.setState({
            isOpenEmailListModal: false,
            isEmailListSubmited: false,
            selectedRowKeys: [],
            emailList: [],
        });
    }

    async handleSearchClick(e) {
        e.preventDefault();

        try {
            this.setState({ loadPanelVisible: true });
            await this.getEmailList();

        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });

    }

    async getEmailList() {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('customer-api/' + this.state.customerId, requestParams);

            const data = await response.json();
            this.setState({
                isOpenEmailListModal: true,
                emailList: data.accountLocationEmail.length > 0 ? data.accountLocationEmail : []
            });
        } catch (e) {
            console.log(e);
        }
    }

    onSelectionChanged({ selectedRowKeys }) {
        this.setState({
            selectedRowKeys: selectedRowKeys
        });
    }

    async handleEmailListSubmit(e) {
        e.preventDefault();
        const selectedRowsData = await this.dataGrid.instance.getDataByKeys(this.state.selectedRowKeys);

        if (selectedRowsData.length <= 0) {
            const isValid = await Swal.fire({
                title: "<small>Email Notification</small>",
                text: "Please select atleast one email.",
                icon: "info",
                dangerMode: false,
                width: "25%",
                dangerMode: false,
                allowOutsideClick: false
            });
            return;
        }
        var emailStr = '';
        for (var i = 0; i < selectedRowsData.length; i++) {
            emailStr = emailStr + selectedRowsData[i].notification + ",";
        }

        emailStr = emailStr.replace(/,\s*$/, "");

        this.setState({
            isOpenEmailListModal: false,
            toEmail: emailStr
        })
    }

    render() {
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' }
            ],
            BLOCK_TYPE_DROPDOWN: [
                { label: 'Normal', style: 'unstyled' },
                { label: 'Heading Large', style: 'header-one' },
                { label: 'Heading Medium', style: 'header-two' },
                { label: 'Heading Small', style: 'header-three' }
            ],
            BLOCK_TYPE_BUTTONS: [
                { label: 'UL', style: 'unordered-list-item' },
                { label: 'OL', style: 'ordered-list-item' }
            ]
        };

        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '45%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form style={this.state.isSubmitted ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Send E-mail</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>To &nbsp;&nbsp;<i style={{ color: '#C04000' }}>( For more than one, enter a comma separated email address )</i></label>
                                        <input type="text" className="form-control form-control-sm"
                                            name="emailTo"
                                            placeholder="example@domain.com"
                                            title="To"
                                            value={this.state.emailTo}
                                            onChange={this.handleOnInputChange} />
                                        {this.state.errors["emailTo"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["emailTo"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label className="btn-block" style={{ paddingTop: 7 + 'px' }}>&nbsp;</label>
                                        <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleSearchClick}><i className="fas fa-search"></i> Search</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>CC &nbsp;&nbsp;<i style={{ color: '#C04000' }}>( For more than one, enter a comma separated email address )</i></label>
                                        <input type="text" className="form-control form-control-sm"
                                            name="emailCc"
                                            placeholder="example@domain.com"
                                            title="Cc"
                                            value={this.state.emailCc}
                                            onChange={this.handleOnInputChange} />
                                        {this.state.errors["emailCc"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["emailCc"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" style={{ display: (!this.state.isEmailBCc) || 'none' }}>
                                    <div className="form-group">
                                        <label className="btn-block" style={{ paddingTop: 7 + 'px' }}>&nbsp;</label>
                                        <button type="button" className="btn btn-custom-gray btn-sm" onClick={() => this.setState({ isEmailBCc: true })}>
                                            <i className="fas fa-plus"></i> BCC
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ display: this.state.isEmailBCc ? 'flex' : 'none' }}>
                                <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>BCC &nbsp;&nbsp;<i style={{ color: '#C04000' }}>( For more than one, enter a comma separated email address )</i></label>
                                        <input type="text" className="form-control form-control-sm"
                                            name="emailBcc"
                                            placeholder="example@domain.com"
                                            title="Bcc"
                                            value={this.state.emailBcc}
                                            onChange={this.handleOnInputChange} />
                                        {this.state.errors["emailBcc"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["emailBcc"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" style={{ display: (this.state.isEmailBCc) || 'none' }}>
                                    <div className="form-group">
                                        <label className="btn-block" style={{ paddingTop: 7 + 'px' }}>&nbsp;</label>
                                        <button type="button" className="btn btn-custom-gray btn-sm" onClick={() => this.setState({ isEmailBCc: false, emailBcc: '' })}>
                                            <i className="fas fa-minus"></i> Hide
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Subject</label>
                                        <input type="text" className="form-control form-control-sm"
                                            name="emailSubject"
                                            placeholder=""
                                            title="Subject"
                                            value={this.state.emailSubject}
                                            onChange={this.handleOnInputChange} />
                                        {this.state.errors["emailSubject"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["emailSubject"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Mail Priority</label>
                                        <select name="mailPriority" className="form-control form-control-sm"
                                            value={this.state.mailPriority} onChange={this.handleOnInputChange}>
                                            {
                                                mailPriorityDropdownList &&
                                                mailPriorityDropdownList.map((item, i) =>
                                                    (<option key={i} value={item.id}>{item.name}</option>))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4" style={{ display: (this.state.fileNameDisplay.trim().length > 0) || 'none' }}>
                                    <div className="form-group">
                                        <label></label><br />
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                name="isSendAttachment"
                                                checked={this.state.isSendAttachment}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isSendAttachment"> Send Attachment</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>
                                            <span style={{ display: (this.state.fileNameDisplay.trim().length > 0) || 'none' }} >
                                                <i className="fas fa-paperclip">&nbsp;{this.state.fileNameDisplay}</i> <br />
                                            </span> Notes (if required)
                                        </label>
                                        <textarea className="form-control form-control-sm" rows="5" name="emailNotes" placeholder="Enter invoice notes." defaultValue={this.state.emailNotes} maxLength="500" title="Email Note" onChange={this.handleOnInputChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" title="Send" onClick={this.handleOnSendEmail}>
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Send"><i className="fas fa-paper-plane"></i> Send</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleOnClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleOnCloseModal}><i className="fas fa-times"></i> Cancel</button>
                        </div>
                    </form>
                </Modal>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenEmailListModal}
                    style={customStylesEmail}
                    className={"react-modal"}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form>
                        <div className="modal-header">
                            <h6 className="modal-title">Email List</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeEmailListModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="widget-container" style={{ maxHeight: 350 + 'px', overflowY: "auto" }}>
                                <DataGrid
                                    dataSource={this.state.emailList}
                                    id="grid-container"
                                    keyExpr="rowId"
                                    showBorders={true}
                                    selectedRowKeys={this.state.selectedRowKeys}
                                    onSelectionChanged={this.onSelectionChanged}
                                    ref={ref => this.dataGrid = ref}>
                                    <Selection
                                        mode="multiple"
                                        selectAllMode={"allPages"}
                                        showCheckBoxesMode={"always"}
                                    />
                                    <Column dataField="notification" caption="Email" />
                                    <Scrolling mode="infinite" />
                                </DataGrid>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success btn-sm" onClick={this.handleEmailListSubmit}>
                                {this.state.isEmailListSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailListSubmited && <span title="Submit"><i className="fas fa-check"></i> Ok</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeEmailListModal} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        );
    }
}
