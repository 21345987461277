import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { getRequestParams, formatDate } from '../../src/helpers/globalfunctions.js';
import { SelectBox } from 'devextreme-react/select-box';
import { DateBox } from 'devextreme-react/date-box';
import { DashboardType } from '../helpers/fixcodes.js';
import { AdminDashboardComponent } from '../components/Shared/AdminDashboardComponent';
import { DispatchDashboardComponent } from '../components/Shared/DispatchDashboardComponent';

let currencyIcon = sessionStorage.getItem("session_currencyIcon") === "" ? "fas fa-dollar-sign" : sessionStorage.getItem("session_currencyIcon");

const socketUrl = "wss://172.16.0.90:12000/";

const durations = [
    //{ id: 1, name: "Bi-Monthly" },
    { id: 2, name: "Monthly" },
    { id: 3, name: "Yearly" },
    { id: 4, name: "Custom Range"},
]

let dashboardName = sessionStorage.getItem("session_dashboardName");
let timeout = 250;

export class DashboardComponent extends Component {
    static displayName = DashboardComponent.name;
    constructor(props) {
        super(props);
        dashboardName = sessionStorage.getItem("session_dashboardName");

        this.state = {
            locationId: 0,
            locationList: [],
            durationList: durations,
            durationId: 2,
            fromDate: new Date(new Date().setDate(new Date().getDate() - 30)),
            toDate: new Date(),
            searchDateRange: formatDate(new Date(new Date().setDate(new Date().getDate() - 30)), "MM/dd/yyyy") + " - " + formatDate(new Date(), "MM/dd/yyyy"),
            minZoomLevel: "year",
            maxZoomLevel: "year",
            dateDisplayFormat: "MMM '-' yyyy",
            dashboardRequest: [],
            isDisplaySearchPanel: true,            
            timeout: 250,            
        }

        this.getLocationList = this.getLocationList.bind(this);
        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.handleDurationChange = this.handleDurationChange.bind(this);
        this.handleFromDateChanged = this.handleFromDateChanged.bind(this);
        this.handleToDateChanged = this.handleToDateChanged.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
        this.calculateFromDate = this.calculateFromDate.bind(this);
        this.calculateToDate = this.calculateToDate.bind(this);
        this.customizeTooltip = this.customizeTooltip.bind(this);        
    }

    async componentDidMount() {
        await this.getLocationList();
        this.sendDashboardRequest();
        //this.connect();
    }

    //setupWebSocket() {
    //    try {
    //        var clientsocket = new W3CWebSocket(socketUrl);
    //        debugger;
    //        console.log(clientsocket.readyState);
    //        this.clientsocket = clientsocket;
    //        console.log('WEBSOCKET CLIENT CONNECTED');
    //        clientsocket.onopen = () => {
    //            debugger;
    //            console.log('WebSocket Client Connected 1');
    //            function establishSocketConnection() {
    //                console.log("WebSocket Client 1");
    //                //console.log("Map establishValidConnection");
    //                if (clientsocket.readyState === clientsocket.OPEN) {
    //                    //client.send("TEST MESSAGE");
    //                    console.log("WebSocket Client 2");
    //                } else {
    //                    console.log("WebSocket Client 3");
    //                    setTimeout(establishSocketConnection, 1000);
    //                }
    //            }
    //            establishSocketConnection();
    //        };
    //    } catch (e) {
    //        console.log("socket error: " + e);
    //    }

    //    clientsocket.onclose = (e) => {
    //        debugger;
    //        console.log("DISCONNECTED");
    //    };

    //    clientsocket.onmessage = (e) => {
    //        debugger;
    //        console.log("MESSAGE");
    //    };
    //}

    //componentWillUnmount() {
    //    // fix Warning: Can't perform a React state update on an unmounted component
    //    this.setState = (state, callback) => {
    //        return;
    //    };
    //}

    sendDashboardRequest() {
        var dashboardRequest = new Object();
        dashboardRequest.FromDate = this.state.fromDate;
        dashboardRequest.ToDate = this.state.toDate;
        dashboardRequest.LocationId = this.state.locationId;
        dashboardRequest.CustomerTypeId = 0;
        dashboardRequest.TopN = 10;
        dashboardRequest.SortDirection = false;

        switch (dashboardName) {
            case DashboardType.AdminDashboard:                
                this.refs.adminDashboard.bindAdminDashboard(dashboardRequest);
                break;
            case DashboardType.DispatchDashboard:
                this.refs.dispatchDashboard.bindDispatchDashboard(dashboardRequest);
                this.setState({ isDisplaySearchPanel: false })
                break;
            default:
                this.refs.adminDashboard.bindAdminDashboard(dashboardRequest);
                break;
        }
    }
    
    async getLocationList() {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/account-api/locations', requestParams);
            const data = await response.json();
            this.setState({
                locationList: data.locations
            });
        } catch (e) {
            console.log(e);
        }
    }
    handleLocationChange(selectedOption) {        
        this.setState({ locationId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.locationId : 0), })
    }

    handleDurationChange(selectedOption) {
        if (selectedOption.selectedItem != null) {
            switch (selectedOption.selectedItem.id) {
                case 2:
                    let fromMonthDateValue = this.calculateFromDate(selectedOption.selectedItem.id, this.state.fromDate);
                    let toMonthDateValue = this.calculateToDate(selectedOption.selectedItem.id, this.state.toDate);
                    this.setState({ maxZoomLevel: "year", minZoomLevel: "year", dateDisplayFormat: "MMM '-' yyyy", fromDate: fromMonthDateValue, toDate: toMonthDateValue });
                    break;
                case 3:
                    let fromYearDateValue = this.calculateFromDate(selectedOption.selectedItem.id, this.state.fromDate);
                    let toYearDateValue = this.calculateToDate(selectedOption.selectedItem.id, this.state.toDate);
                    this.setState({ maxZoomLevel: "decade", minZoomLevel: "decade", dateDisplayFormat: "yyyy", fromDate: fromYearDateValue, toDate: toYearDateValue });
                    break;
                default:
                    this.setState({ maxZoomLevel: "month", minZoomLevel: "month", dateDisplayFormat: "MM/dd/yyyy" });
                    break;
            }
        }

        this.setState({ durationId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
    }

    calculateFromDate(duration, searchDate) {
        let value = formatDate(searchDate, "MM/dd/yyyy");
        try {
            const month = new Date(value).getMonth();
            const year = new Date(value).getFullYear();
            if (duration == 2) {
                value = (month + 1) + "/01/" + year;
            }
            else if (duration == 3) {
                value = "01/01/" + year;
            }
        } catch (e) {

        }
        return value;
    }

    calculateToDate(duration, searchDate) {
        let value = formatDate(searchDate, "MM/dd/yyyy");
        try {
            const month = new Date(value).getMonth();
            const year = new Date(value).getFullYear();
            if (duration == 2) {
                const lastDay = this.lastDayOfMonth(year, month);
                value = (month + 1) + "/" + lastDay + "/" + year;
            }
            else if (duration == 3) {
                const lastDay = this.lastDayOfYear(year);
                value = "12/" + lastDay + "/" + year;
            }
        } catch (e) {

        }
        return value;
    }

    handleFromDateChanged(event) {
        try {
            let fromDateValue = this.calculateFromDate(this.state.durationId, event.value);
            this.setState({ fromDate: fromDateValue })
        } catch (ex) {
        }
    }

    handleToDateChanged(event) {
        try {
            let toDateValue = this.calculateToDate(this.state.durationId, event.value);
            this.setState({ toDate: toDateValue })
        } catch (ex) {

        }
    }

    lastDayOfMonth = function (y, m) {
        return new Date(y, m + 1, 0).getDate();
    }

    lastDayOfYear = function (y) {
        return new Date(y, 12, 0).getDate();
    }

    handleSearch(e) {
        e.preventDefault();
        this.setState({ searchDateRange: formatDate(this.state.fromDate, "MM/dd/yyyy") + " - " + formatDate(this.state.toDate, "MM/dd/yyyy") })
        this.sendDashboardRequest();
    }

    handleClearSearch(e) {
        e.preventDefault();
        this.setState({
            fromDate: new Date(new Date().setDate(new Date().getDate() - 30)),
            toDate: new Date(),
            searchDateRange: formatDate(new Date().setDate(new Date().getDate() - 30), "MM/dd/yyyy") + " - " + formatDate(new Date(), "MM/dd/yyyy"),
            minZoomLevel: "year",
            maxZoomLevel: "year",
            dateDisplayFormat: "MMM '-' yyyy",
            completeDashboard: [],
        });

        this.sendDashboardRequest();
    }
   
    render() {
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <h1 className="m-0 text-dark">Dashboard</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            </div>
                        </div>
                    </div>
                </div>
                <div className={this.state.isDisplaySearchPanel === false ? "rez-header displayNone" : "rez-header displayBlock"}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2 col-md-3 col-sm-6">
                                <div className="form-group">
                                    <label>Location</label>
                                    <SelectBox
                                        dataSource={this.state.locationList.length > 0 ? this.state.locationList : []}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select location"
                                        name="id"
                                        defaultValue={0}
                                        value={this.state.locationId}
                                        displayExpr='locationName'
                                        valueExpr='locationId'
                                        onSelectionChanged={this.handleLocationChange}
                                        showClearButton={true}
                                        disabled={false}
                                        searchEnabled={true}
                                    />
                                </div>

                            </div>
                            <div className="col-lg-2 col-md-3 col-sm-6">
                                <div className="form-group">
                                    <label>Duration</label>
                                    <SelectBox
                                        dataSource={this.state.durationList.length > 0 ? this.state.durationList : []}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select duration"
                                        name="id"
                                        defaultValue={0}
                                        value={this.state.durationId}
                                        displayExpr='name'
                                        valueExpr='id'
                                        onSelectionChanged={this.handleDurationChange}
                                        showClearButton={true}
                                        disabled={false}
                                        searchEnabled={true}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-3 col-sm-6">
                                <div className="form-group">
                                    <label>From Date</label>
                                    <DateBox type="date"
                                        className="form-control form-control-sm"
                                        name="fromDate"
                                        placeholder="mm/dd/yyyy"
                                        useMaskBehavior={true}
                                        displayFormat={this.state.dateDisplayFormat}
                                        defaultValue={this.state.fromDate}
                                        value={this.state.fromDate}
                                        onValueChanged={this.handleFromDateChanged}
                                        minZoomLevel={this.state.minZoomLevel}
                                        maxZoomLevel={this.state.maxZoomLevel}
                                        width="100%"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-3 col-sm-6">
                                <div className="form-group">
                                    <label>To Date</label>
                                    <DateBox type="date"
                                        className="form-control form-control-sm"
                                        name="toDate"
                                        placeholder="mm/dd/yyyy"
                                        useMaskBehavior={true}
                                        displayFormat={this.state.dateDisplayFormat}
                                        defaultValue={this.state.toDate}
                                        value={this.state.toDate}
                                        onValueChanged={this.handleToDateChanged}
                                        minZoomLevel={this.state.minZoomLevel}
                                        maxZoomLevel={this.state.maxZoomLevel}
                                        width="100%"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-12 col-sm-12 text-left">
                                <div className="form-group">
                                    <br />
                                    <button type="submit" className="btn btn-success btn-sm" onClick={this.handleSearch} title="Show Dashboard">
                                        <i className="fas fa-search" title="Search"></i> Show Dashboard
                                                </button>&nbsp; &nbsp;
                                                <button type="submit" className="btn btn-custom-gray btn-sm" onClick={this.handleClearSearch} title="Clear"><i className="fas fa-search"></i> Clear</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                {dashboardName == "AdminDashboard" ? <AdminDashboardComponent ref="adminDashboard" /> : null}
                {dashboardName == "DispatchDashboard" ? <DispatchDashboardComponent ref="dispatchDashboard" /> : null}
            </div>
        );
    }

    customizeTooltip(pointInfo) {
        return {
            html: `<div><div class="tooltip-body"><div class="series-name"><span class='top-series-name'>${pointInfo.seriesName}</span></div><div class="value-text">
                <span class='top-series-value'>${pointInfo.value}</span></div></div></div>`
        };
    }

    customizePercentageText({ valueText }) {
        return '${valueText}';
    }
}