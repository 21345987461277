import React, { Component } from 'react';
import DateBox from 'devextreme-react/date-box';
import { SelectBox } from 'devextreme-react/select-box';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions';
import { LoadPanel } from 'devextreme-react/load-panel';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'
const position = { of: '#historydiv' };

export class ARPreviewInvoiceReportComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            dropdownCustomerType: [],
            customerTypeId: 0,
            fromDropdownCustomer: [],
            toDropdownCustomer: [],
            fromCustomerCode: '',
            toCustomerCode: '',
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),
            errors: {
                customerTypeId: '',
                fromCustomerCode: '',
                toCustomerCode: '',
            }
        }
        this.getCustomerType = this.getCustomerType.bind(this);
        this.handleCustomerTypeChange = this.handleCustomerTypeChange.bind(this);
        this.handleCustomerTypeSelectionChange = this.handleCustomerTypeSelectionChange.bind(this);

        this.getCustomer = this.getCustomer.bind(this);
        this.handleFromCustomerChange = this.handleFromCustomerChange.bind(this);
        this.handleFromCustomerSelectionChange = this.handleFromCustomerSelectionChange.bind(this);

        this.handleToCustomerChange = this.handleToCustomerChange.bind(this);
        this.handleToCustomerSelectionChange = this.handleToCustomerSelectionChange.bind(this);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFromDateChanged = this.handleFromDateChanged.bind(this);
        this.handleToDateChanged = this.handleToDateChanged.bind(this);

        this.downloadAccountPaymentTypeSummaryAsPdf = this.downloadAccountPaymentTypeSummaryAsPdf.bind(this);

        this.handleSearchAccountPaySummaryReportSubmit = this.handleSearchAccountPaySummaryReportSubmit.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
    }

    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getCustomerType();
            await this.getCustomer();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {
        this.setState({
            isSubmited: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            customerTypeId: 0,
            fromCustomerCode: '',
            toCustomerCode: '',
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),
            errors: {
                customerTypeId: '',
                fromCustomerCode: '',
                toCustomerCode: '',
            }
        });
    }
    async getCustomer() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('account-api/customer-dropdown/', requestParams);
            const data = await response.json();
            this.setState({
                fromDropdownCustomer: data.customers,
                toDropdownCustomer: data.customers,
            });

        } catch (e) {
            console.log(e);
        }
    }
    handleFromCustomerChange(selectedOption) {
        this.setState({ fromCustomerCode: (selectedOption.selectedItem != null ? selectedOption.selectedItem.code : ''), })
        this.state.errors["fromCustomerCode"] = "";
    }
    handleFromCustomerSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkCustomerSelection: value, customerCode: '' });
        this.state.errors["fromCustomerCode"] = "";
    }
    handleToCustomerChange(selectedOption) {
        this.setState({ toCustomerCode: (selectedOption.selectedItem != null ? selectedOption.selectedItem.code : ''), })
        this.state.errors["toCustomerCode"] = "";
    }
    handleToCustomerSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkCustomerSelection: value, customerCode: '' });
        this.state.errors["toCustomerCode"] = "";
    }
    async getCustomerType() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('customer-type-api/', requestParams);
            const data = await response.json();
            this.setState({
                dropdownCustomerType: data.customerType,
            });

        } catch (e) {
            console.log(e);
        }
    }
    handleCustomerTypeChange(selectedOption) {
        this.setState({ customerTypeId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
        this.state.errors["customerTypeId"] = "";
    }
    handleCustomerTypeSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkCustomerTypeSelection: value, customerTypeId: '' });
        this.state.errors["customerTypeId"] = "";
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    }
    handleFromDateChanged(event) {
        let fromDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ startDate: fromDateValue })
    }
    handleToDateChanged(event) {
        let toDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ endDate: toDateValue })
    }
    handleSearchAccountPaySummaryReportSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            this.downloadAccountPaymentTypeSummaryAsPdf();
        }
    }
    handleClearSearch() {
        this.setState({
            isSubmited: false,
            isEmailSubmitted: false,
            isOpenModal: true,
            loadPanelVisible: false,
            customerTypeId: 0,
            fromCustomerCode: '',
            toCustomerCode: '',
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),
            errors: {
                customerTypeId: '',
                fromCustomerCode: '',
                toCustomerCode: '',
            }
        });
    }

    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.fromCustomerCode = "";
        errors.toCustomerCode = "";
        errors.customerTypeId = "";

        if (this.state.customerTypeId == "") {
            errors.customerTypeId = "Please select customer type"
        }
        if (this.state.fromCustomerCode = "") {
            errors.fromCustomerCode = "Please select from customer"
        }
        if (this.state.toCustomerCode = "") {
            errors.toCustomerCode = "Please select to customer"
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    async downloadAccountPaymentTypeSummaryAsPdf() {
        try {
            if (this.handleValidation()){

                this.setState({
                    isSubmited: true,
                });

                var criteria1 = "";
                var selectionCriteria = "";
                if (this.state.customerTypeId > 0) {
                    criteria1 = "For Account Type " + this.state.customerType;
                }
                if (this.state.fromCustomerCode != "" && this.state.toCustomerCode != "") {
                    criteria1 = criteria1 + " From Account " + this.state.fromCustomerCode + " To Account " + this.state.toCustomerCode;
                }
                criteria1 = selectionCriteria + " Date range between " + formatDate(this.state.fromDateTime, "MM/dd/yyyy") + " 00:00:01 AND " + formatDate(this.state.toDateTime, "MM/dd/yyyy") + " 00:00:01"

                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.StartDate = this.state.fromDateTime == null ? "" : this.state.fromDateTime.toLocaleDateString() + " 00:00:00"; //new Date(2020, 9, 1);
                request.EndDate = this.state.toDateTime == null ? "" : this.state.toDateTime.toLocaleDateString() + " 23:59:59"; //new Date(2020, 9, 31);
                request.CustomerTypeId = parseInt(this.state.customerTypeId);
                request.FromAccountCode = "";
                request.ToAccountCode = "";
                request.Criteria1 = criteria1;

                request.fileFormat = 'pdf';
                request.MethodName = "GetPreviewInvoiceReport";
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({
                    isSubmited: false,
                });

                let a = document.createElement('a');
                a.href = dataResponse.reportBase64String;
                a.download = 'CharterInvoiceReport.pdf';
                a.click();
            }
        } catch (e) {
            this.setState({
                isSubmited: false,
            });
            console.log(e)
        }
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isEmailSubmitted: true });

                var criteria1 = "";
                var selectionCriteria = "";
                if (this.state.customerTypeId > 0) {
                    criteria1 = "For Account Type " + this.state.customerType;
                }
                if (this.state.fromCustomerCode == "" && this.state.toCustomerCode == "") {
                    criteria1 = criteria1 + " From Account " + this.state.fromCustomerCode + " To Account " + this.state.toCustomerCode;
                }
                criteria1 = selectionCriteria + " Date range between " + formatDate(this.state.fromDateTime, "MM/dd/yyyy") + " 00:00:01 AND " + formatDate(this.state.toDateTime, "MM/dd/yyyy") + " 00:00:01"

                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.StartDate = this.state.fromDateTime == null ? "" : this.state.fromDateTime.toLocaleDateString() + " 00:00:00"; //new Date(2020, 9, 1);
                request.EndDate = this.state.toDateTime == null ? "" : this.state.toDateTime.toLocaleDateString() + " 23:59:59"; //new Date(2020, 9, 31);
                request.CustomerTypeId = parseInt(this.state.customerTypeId);
                request.FromAccountCode = "";
                request.ToAccountCode = "";
                request.Criteria1 = criteria1;

                request.fileFormat = 'pdf';
                request.MethodName = "GetPreviewInvoiceReport";
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();

                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Charter Invoice Report';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = 'InvoicePreview.pdf';
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '53%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleSearchAccountPaySummaryReportSubmit}
                        style={(this.state.isSubmited || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Account Receivable Invoice Report</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label htmlFor="">Invoice For</label>
                                        <SelectBox
                                            dataSource={this.state.dropdownCustomerType.length > 0 ? this.state.dropdownCustomerType : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer type"
                                            name="customerTypeId"
                                            defaultValue={0}
                                            value={this.state.customerTypeId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleCustomerTypeChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerTypeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerTypeId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label htmlFor="">From Customer</label>
                                        <SelectBox
                                            dataSource={this.state.fromDropdownCustomer.length > 0 ? this.state.fromDropdownCustomer : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="customerCode"
                                            defaultValue={0}
                                            value={this.state.fromCustomerCode}
                                            displayExpr='fullName'
                                            valueExpr='code'
                                            onSelectionChanged={this.handleFromCustomerChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["fromCustomerCode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["fromCustomerCode"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label htmlFor="">To Customer</label>
                                        <SelectBox
                                            dataSource={this.state.toDropdownCustomer.length > 0 ? this.state.toDropdownCustomer : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="customerCode"
                                            defaultValue={0}
                                            value={this.state.toCustomerCode}
                                            displayExpr='fullName'
                                            valueExpr='code'
                                            onSelectionChanged={this.handleToCustomerChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["toCustomerCode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["toCustomerCode"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label htmlFor="">From Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.startDate}
                                            value={this.state.startDate}
                                            onValueChanged={this.handleFromDateChanged}
                                            width="100%"
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>To Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.endDate}
                                            value={this.state.endDate}
                                            onValueChanged={this.handleToDateChanged}
                                            width="100%"
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="button" className="btn btn-success btn-sm" title="Download" onClick={this.handleSearchAccountPaySummaryReportSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                                </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        )
    }
}