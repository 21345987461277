import React, { Component } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
    Selection,
    Sorting,
    Scrolling,
    MasterDetail
} from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1

import { SelectBox } from 'devextreme-react/select-box';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import NumberFormat from 'react-number-format';
import NumericInput from 'react-numeric-input'; //npm install react-numeric-input --save
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { LoadPanel } from 'devextreme-react/load-panel';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Geocode from "react-geocode"; //npm install--save react - geocode
//https://www.npmjs.com/package/react-geocode
import { AddEditStopComponent } from '../Shared/AddEditStopComponent';

import {
    withGoogleMap,
    GoogleMap,
    withScriptjs,
    Marker,
    InfoWindow,
    DirectionsRenderer
} from "react-google-maps";

const MySwal = withReactContent(Swal)
const google = window.google;
const position = { of: '#bookingdiv' };

const googleMapsApiKey = "AIzaSyCAiQ31UBwphPPI36Ty_nzZ27QxNx34SXQ";
const loadingElement = <div style={{ height: `100%` }} />;
const containerElement = <div style={{ height: `600px` }} />;
const mapElement = <div style={{ height: `100%` }} />;

let selectedStops = []

export class RouteBookingComponent extends Component {
    static displayName = RouteBookingComponent.name;
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            routeId: this.props.location.state == undefined ? 0 : this.props.location.state.routeId,
            sourceUrl: this.props.location.state == undefined ? "" : this.props.location.state.sourceUrl, // Send from calling paging (Quotation list)
            sourceState: this.props.location.state == undefined ? "" : this.props.location.state.sourceState,  // Send from calling paging (Quotation list)
            selectedRowKeys: [],
            stopList: [],
            customerList: [],
            customerId: 0,
            routeName: "",
            routeGroupId: 0,
            routeDirection: 1,
            routeNumber: 0,
            totalRouteDuration: "",
            chkIsAntiBunching: false,
            chkIsActive: false,
            places: [],
            defaultLatitude: 25.798939,
            defaultLongitude: -80.291409,
            center: {
                lat: 25.798939,
                lng: -80.291409
            },
            zoom: 11,
            routeDirections: null,

            showInfoIndex: '',

            routeDetails: null,
            shuttleRouteWaypoint: [],
            errors: {
                customerId: "",
                routeName: "",
            }
        };
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.goToBack = this.goToBack.bind(this);
        this.handleRouteSubmit = this.handleRouteSubmit.bind(this);
        this.getCustomerList = this.getCustomerList.bind(this);
        this.getStopList = this.getStopList.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCustomerChange = this.handleCustomerChange.bind(this);
        this.openEditStopModel = this.openEditStopModel.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onStopAdd = this.onStopAdd.bind(this);

        this.showInfo = this.showInfo.bind(this);
        this.handleCloseCall = this.handleCloseCall.bind(this);
        this.calculateTotalDuration = this.calculateTotalDuration.bind(this);
        this.handleValidation = this.handleValidation.bind(this);

        this.getRouteList = this.getRouteList.bind(this);
    };

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'plus',
                onClick: this.onStopAdd.bind(this)
            }
        });
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true
        });
        await this.getCustomerList();
        await this.getStopList();

        if (this.state.routeId > 0) {
            await this.getRouteList();
            if (this.state.routeDetails != null) {

                

                selectedStops = [];
                let selected = [];
                let tempShuttleRouteWaypoint = [];
                if (this.state.routeDetails.shuttleRouteStops.length > 0) {
                    for (var i = 0; i < this.state.routeDetails.shuttleRouteStops.length; i++) {
                        var stop = new Object();
                        stop.Id = this.state.routeDetails.shuttleRouteStops[i].stopId;
                        stop.Name = this.state.routeDetails.shuttleRouteStops[i].actualLocationName;
                        stop.latitude = this.state.routeDetails.shuttleRouteStops[i].latitude;
                        stop.longitude = this.state.routeDetails.shuttleRouteStops[i].longitude;
                        stop.locationType = this.state.routeDetails.shuttleRouteStops[i].locationTypeId;
                        stop.distance = 0;
                        stop.time = this.state.routeDetails.shuttleRouteStops[i].scheduleTime;
                        stop.radious = this.state.routeDetails.shuttleRouteStops[i].stopRadious;
                        stop.overview_polyline = "";
                        selectedStops.push(stop);
                        selected.push(Number(this.state.routeDetails.shuttleRouteStops[i].stopId))
                    }
                    if (this.state.routeDetails.shuttleRouteWaypoint !== null && this.state.routeDetails.shuttleRouteWaypoint.length > 0) {
                        let shuttleRouteWaypoint = this.state.routeDetails.shuttleRouteWaypoint.sort((a, b) => (a.sequence > b.sequence ? 1 : -1));
                        shuttleRouteWaypoint.forEach((wp) => {
                            let chkStop = {};
                            chkStop.stopId = wp.stopId;
                            chkStop.actualLocationName = wp.actualLocationName;
                            chkStop.latitude = wp.latitude;
                            chkStop.longitude = wp.longitude;
                            chkStop.locationType = wp.locationType;
                            chkStop.stopRadious = wp.stopRadious;
                            chkStop.sequence = wp.sequence;
                            tempShuttleRouteWaypoint.push(chkStop);
                        });
                    }
                }
                this.setState({
                    customerId: this.state.routeDetails.customerId,
                    routeName: this.state.routeDetails.routeName,
                    routeGroupId: this.state.routeDetails.routeGroupId,
                    routeDirection: this.state.routeDetails.routeDirection,
                    routeNumber: this.state.routeDetails.routeNumber,
                    totalRouteDuration: this.state.routeDetails.routeDuration,
                    chkIsAntiBunching: this.state.routeDetails.isAntiBunching,
                    chkIsActive: this.state.routeDetails.isActive,
                    routeDirections: null,
                    selectedRowKeys: selected,
                    shuttleRouteWaypoint: tempShuttleRouteWaypoint
                })
            }
        }

        var defaultLocation = sessionStorage.getItem("session_locationName");
        Geocode.fromAddress(defaultLocation).then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location;
                this.setState({
                    center: {
                        lat: lat,
                        lng: lng
                    },
                    defaultLatitude: lat,
                    defaultLongitude: lng
                })
            },
            (error) => {
                this.setState({
                    center: {
                        lat: 25.798939,
                        lng: -80.291409
                    },
                })
            }
        );
        this.setState({
            loadPanelVisible: false
        });
    }

    async getRouteList() {
        var shuttleRoutesRequest = new Object();
        shuttleRoutesRequest.LocationId = Number(sessionStorage.getItem("session_locationId"));

        try {
            const requestParams = getRequestParams('POST', shuttleRoutesRequest);
            const response = await fetch('/route-api/' + this.state.routeId + '/list', requestParams);
            const data = await response.json();
            this.setState({
                routeDetails: data.shuttleRoutes[0]
            });


        } catch (e) {
            this.setState({ loadPanelVisible: false });
        }
    }

    goToBack() {
        this.props.history.push({
            pathname: this.state.sourceUrl,
            state: this.state.sourceState
        })
    }

    async getCustomerList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('account-api/customer-dropdown/', requestParams);
        const data = await response.json();
        if (data.isSuccess) {
            this.setState({
                //customerList: data.customers != null ? data.customers.filter(f => (f.customerTypeId == 5 || f.customerTypeId == 14 || f.customerTypeId == 15 || f.customerTypeId == 16)) : [],
                customerList: data.customers != null ? data.customers : [],
            });
        }
    }

    async getStopList() {
        this.setState({
            stopList: []
        });
        selectedStops = [];
        var shuttleStopsRequest = new Object();
        shuttleStopsRequest.LocationId = Number(sessionStorage.getItem("session_locationId"));

        try {
            const requestParams = getRequestParams('POST', shuttleStopsRequest);
            const response = await fetch('/stop-api/0/list', requestParams);
            const data = await response.json();
            this.setState({
                stopList: data.shuttleStops != null ? data.shuttleStops.filter(f => f.isActive == true) : []
            });

        } catch (e) {
        }
    }

    handleCustomerChange(selectedOption) {
        this.setState({ customerId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
        this.state.errors["customerId"] = "";
    }

    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.customerId = "";
        errors.routeName = "";

        if (parseInt(this.state.customerId) == 0) {
            formIsValid = false;
            errors.customerId = "Please select customer.";
        }

        if (this.state.routeName.trim() == '') {
            formIsValid = false;
            errors.routeName = "Please enter route name.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    async handleRouteSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {

            if (selectedStops.length < 2) {
                const isValid = await Swal.fire({
                    title: "<small>Invalid Route</small>",
                    text: "Route not found, Please draw route by selecting stops on the map.",
                    icon: "info",
                    dangerMode: false,
                    width: "25%",
                    allowOutsideClick: false
                });
                return;
            }

            this.setState({ isSubmited: true });
            var newObject = new Object();
            newObject.ShuttleRoutes = new Object();
            newObject.ShuttleRoutes.RouteId = parseInt(this.state.routeId);
            newObject.ShuttleRoutes.RouteName = this.state.routeName;
            newObject.ShuttleRoutes.FromStopId = parseInt(selectedStops[0].Id);
            newObject.ShuttleRoutes.ToStopId = parseInt(selectedStops[selectedStops.length - 1].Id);
            newObject.ShuttleRoutes.DispatchRouteId = 0;
            newObject.ShuttleRoutes.PickupInstructions = "";
            newObject.ShuttleRoutes.DroffInstructions = "";
            newObject.ShuttleRoutes.RouteGroupId = parseInt(this.state.routeGroupId);
            newObject.ShuttleRoutes.CustomerId = Number(this.state.customerId);
            newObject.ShuttleRoutes.IsActive = this.state.routeId > 0 ? this.state.chkIsActive : true;
            newObject.ShuttleRoutes.RouteDirection = Number(this.state.routeDirection);
            newObject.ShuttleRoutes.RouteDuration = Number(this.calculateTotalDuration());
            newObject.ShuttleRoutes.RouteNumber = Number(this.state.routeNumber);
            newObject.ShuttleRoutes.IsAntiBunching = this.state.chkIsAntiBunching
            newObject.ShuttleRouteStops = [];
            newObject.ShuttleRoutes.ShuttleRouteWaypoint = [];
            newObject.ShuttleRouteCoordinates = new Object();

            for (var i = 0; i < selectedStops.length; i++) {
                var stops = new Object();
                stops.Id = 0;
                stops.StopId = parseInt(selectedStops[i].Id);
                stops.ScheduleTime = selectedStops[i].time;
                newObject.ShuttleRouteStops.push(stops);
                var waypoints = new Object();
                waypoints.Id = 0;
                waypoints.Latitude = parseFloat(selectedStops[i].latitude);
                waypoints.Longitude = parseFloat(selectedStops[i].longitude);
                waypoints.IsStop = false;
                newObject.ShuttleRoutes.ShuttleRouteWaypoint.push(waypoints);
            }

            newObject.ShuttleRouteCoordinates.OverviewPolyline = selectedStops[0].overview_polyline;
            newObject.ShuttleRouteCoordinates.PuLocationTypeId = parseInt(selectedStops[0].locationType);
            newObject.ShuttleRouteCoordinates.PickupLocation = selectedStops[0].Name;
            newObject.ShuttleRouteCoordinates.PickupLatitude = parseFloat(selectedStops[0].latitude);
            newObject.ShuttleRouteCoordinates.PickupLongitude = parseFloat(selectedStops[0].longitude);
            newObject.ShuttleRouteCoordinates.DoLocationTypeId = parseInt(selectedStops[selectedStops.length - 1].locationType);
            newObject.ShuttleRouteCoordinates.DropoffLocation = selectedStops[selectedStops.length - 1].Name;
            newObject.ShuttleRouteCoordinates.DropoffLatitude = parseFloat(selectedStops[selectedStops.length - 1].latitude);
            newObject.ShuttleRouteCoordinates.DropoffLongitude = parseFloat(selectedStops[selectedStops.length - 1].longitude);


            try {
                const requestParams = getRequestParams('POST', newObject);
                const response = await fetch('route-api/save-update', requestParams);
                const data = await response.json();

                Swal.fire({
                    icon: data.isSuccess ? 'success' : 'error',
                    title: data.isSuccess ? 'Success' : 'Oops...',
                    text: data.resultMessage,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            this.goToBack();
                        }
                    } else {
                        this.setState({ isSubmited: false })
                    }
                })
            } catch (e) {
                this.setState({ isSubmited: false })
            }
        }

    }

    handleClearSearch(e) {
        e.preventDefault();
        this.setState({
            selectedRowKeys: [],
            customerId: 0,
            routeName: "",
            routeGroupId: 0,
            routeDirection: 1,
            routeNumber: 0,
            totalRouteDuration: "",
            chkIsAntiBunching: false,
            chkIsActive: false,
            center: {
                lat: this.state.defaultLatitude,
                lng: this.state.defaultLongitude
            },
            zoom: 11,
            routeDirections: null,

            showInfoIndex: '',

            errors: {
                customerId: "",
                routeName: "",
            }
        })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
        this.state.errors[name] = "";
    }

    async askAddModifyStopsConfirm() {
        let isResult = false;
        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: "You are going add/modify stop. The selected stops has been reset!<br> Do you want to still continue?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    async onStopAdd() {
        const isConfirm = await this.askAddModifyStopsConfirm();
        if (!isConfirm) {
            return;
        }

        this.refs.addEditStopComponent.showModal('route', 0);
    }

    async openEditStopModel(e, data) {
        e.preventDefault();
        const isConfirm = await this.askAddModifyStopsConfirm();
        if (!isConfirm) {
            return;
        }
        let stopId = data != null ? data.stopId : 0;
        this.refs.addEditStopComponent.showModal('route', stopId);
    }

    async onSelectionChanged(e) {
        this.setState({
            selectedRowKeys: e.selectedRowKeys
        });

        if (e.currentDeselectedRowKeys[0] != undefined) {
            e.component.collapseRow(e.currentDeselectedRowKeys[0]);
        }
        let checkedStop = await this.dataGrid.instance.getDataByKeys(e.selectedRowKeys);
        let maxStopSequence = 0;
        let shuttleRouteWaypoint = [];
        if (!!checkedStop && checkedStop.length > 0) {
            shuttleRouteWaypoint = this.state.shuttleRouteWaypoint;
            let newStops = [];
            if (this.state.shuttleRouteWaypoint !== null && this.state.shuttleRouteWaypoint.length > 0) {
                maxStopSequence = this.state.shuttleRouteWaypoint.reduce((prev, current) => (prev.sequence > current.sequence) ? prev : current).sequence;
                newStops = checkedStop.filter(el => {
                    return !this.state.shuttleRouteWaypoint.find(element => {
                        return element.stopId === el.stopId;
                    });
                });
                let removedStop = this.state.shuttleRouteWaypoint.filter(el => { return !checkedStop.find(element => { return element.stopId === el.stopId; }); });
                let alfterRemovedStop = this.state.shuttleRouteWaypoint.filter(el => { return !removedStop.find(element => { return element.stopId === el.stopId; }); });
                if (!!alfterRemovedStop && alfterRemovedStop.length > 0) {
                    shuttleRouteWaypoint = [];
                    alfterRemovedStop.forEach((stop) => {
                        let chkStop = {};
                        chkStop.stopId = stop.stopId;
                        chkStop.actualLocationName = stop.actualLocationName;
                        chkStop.latitude = stop.latitude;
                        chkStop.longitude = stop.longitude;
                        chkStop.locationType = stop.locationType;
                        chkStop.stopRadious = stop.stopRadious;
                        chkStop.sequence = stop.sequence;
                        shuttleRouteWaypoint.push(chkStop);
                    });
                }
                newStops.forEach((stop) => {
                    let chkStop = {};
                    chkStop.stopId = stop.stopId;
                    chkStop.actualLocationName = stop.actualLocationName;
                    chkStop.latitude = stop.latitude;
                    chkStop.longitude = stop.longitude;
                    chkStop.locationType = stop.locationType;
                    chkStop.stopRadious = stop.stopRadious;
                    chkStop.sequence = maxStopSequence + 1;
                    shuttleRouteWaypoint.push(chkStop);
                });
            }
            else {

                checkedStop.forEach((stop) => {
                    let chkStop = {};
                    chkStop.stopId = stop.stopId;
                    chkStop.actualLocationName = stop.actualLocationName;
                    chkStop.latitude = stop.latitude;
                    chkStop.longitude = stop.longitude;
                    chkStop.locationType = stop.locationType;
                    chkStop.stopRadious = stop.stopRadious;
                    chkStop.sequence = maxStopSequence + 1;
                    shuttleRouteWaypoint.push(chkStop);
                });
            }
        }
        shuttleRouteWaypoint = shuttleRouteWaypoint.sort((a, b) => (a.sequence > b.sequence ? 1 : -1));
        let tempShuttleRouteWaypoint = [];
        shuttleRouteWaypoint.forEach((stop) => {
            let chkStop = {};
            chkStop.stopId = stop.stopId;
            chkStop.actualLocationName = stop.actualLocationName;
            chkStop.latitude = stop.latitude;
            chkStop.longitude = stop.longitude;
            chkStop.locationType = stop.locationType;
            chkStop.stopRadious = stop.stopRadious;
            chkStop.sequence = tempShuttleRouteWaypoint.length + 1;
            tempShuttleRouteWaypoint.push(chkStop);
        });
        this.setState({ shuttleRouteWaypoint: tempShuttleRouteWaypoint });
        const selectedRowsData = tempShuttleRouteWaypoint;

        if (selectedRowsData.length == 0) {
            this.setState({
                places: [],
                selectedRowKeys: [],
                routeDirections: null
            })
            e.component.collapseAll(-1);
            selectedStops = [];
            this.calculateTotalDuration();
            return;
        } else if (selectedRowsData.length == 1) {

            this.setState({
                center: {
                    lat: selectedRowsData[0].latitude,
                    lng: selectedRowsData[0].longitude
                },
                zoom: 18
            });

            selectedStops = [];
            var stop = new Object();
            stop.Id = selectedRowsData[0].stopId;
            stop.Name = selectedRowsData[0].actualLocationName;
            stop.latitude = selectedRowsData[0].latitude;
            stop.longitude = selectedRowsData[0].longitude;
            stop.locationType = selectedRowsData[0].locationType;
            stop.distance = 0;
            stop.time = 0;
            stop.radious = selectedRowsData[0].stopRadious;
            stop.overview_polyline = "";
            selectedStops.push(stop);

            this.setState({
                places: selectedStops,
                routeDirections: null
            })
            this.calculateTotalDuration();
            e.component.expandRow(e.currentSelectedRowKeys[0]);
        } else {

            this.setState({
                loadPanelVisible: true
            });
            const waypoints = selectedRowsData.map(p => ({
                location: { lat: p.latitude, lng: p.longitude },
                stopover: true
            }))
            const origin = waypoints.shift().location;
            const destination = waypoints.pop().location;

            const directionsService = new google.maps.DirectionsService();
            directionsService.route(
                {
                    origin: origin,
                    destination: destination,
                    travelMode: google.maps.TravelMode.DRIVING,
                    waypoints: waypoints
                },
                (result, status) => {
                    if (status === google.maps.DirectionsStatus.OK) {
                        selectedStops = [];
                        for (var i = 0; i < selectedRowsData.length; i++) {
                            var distance = 0, time = 0;

                            if (i > 0) {
                                var _Speed = 40;
                                var distance = result.routes[0].legs[i - 1].distance.value;
                                //var time = parseInt(((parseFloat(distance) / 1000) / _Speed) * 60);
                                var time = parseInt(result.routes[0].legs[i - 1].duration.value / 60);
                            }
                            var stop = new Object();
                            stop.Id = selectedRowsData[i].stopId;
                            stop.Name = selectedRowsData[i].actualLocationName;
                            stop.latitude = selectedRowsData[i].latitude;
                            stop.longitude = selectedRowsData[i].longitude;
                            stop.locationType = selectedRowsData[0].locationType;
                            stop.distance = distance;
                            stop.time = time;
                            stop.radious = selectedRowsData[i].stopRadious;
                            stop.overview_polyline = result.routes[0].overview_polyline;
                            selectedStops.push(stop);
                        }
                        e.component.expandRow(e.currentSelectedRowKeys[0]);
                        this.setState({
                            routeDirections: result
                        });
                    } else {
                        selectedStops = [];
                        this.setState({
                            places: [],
                            selectedRowKeys: [],
                            routeDirections: null
                        })
                    }


                    this.calculateTotalDuration();
                    this.setState({
                        loadPanelVisible: false
                    });
                }

            );
        }
    }

    calculateTotalDuration() {
        var totalDuration = 0;
        if (selectedStops.length > 0) {
            for (var i = 0; i < selectedStops.length; i++) {
                totalDuration += selectedStops[i].time != "" ? parseInt(selectedStops[i].time) : 0
            }
        }
        return totalDuration;
    }

    showInfo(a) {
        this.setState({ showInfoIndex: a })
    }
    handleCloseCall() {
        this.setState({ showInfoIndex: '' })
    }

    render() {
        let pageTitle = this.state.routeId > 0 ? "Modify Route" : "New Route";
        const renderGridCell = (data) => {

            return <div>
                <a href="#" className="fas fa-pen icon text-info" title="Edit" onClick={(e) => { this.openEditStopModel(e, data.data) }}></a>&nbsp;&nbsp;
            </div>;
        }
        return (
            <div className="content-wrapper" id="bookingdiv">
                <form method="post" onSubmit={this.handleRouteSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="content-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <h1 className="m-0 text-dark">{pageTitle}</h1>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <NavItem><NavLink tag={Link} to="/stop/list" className="btn btn-block btn-outline-secondary btn-sm" title="Stops"><i className="fas fa-map-marker"></i> Stops</NavLink></NavItem>
                                        <NavItem><NavLink tag={Link} to="/route/list" className="btn btn-block btn-outline-secondary btn-sm" title="Routes"><i className="fas fa-road"></i> Routes</NavLink></NavItem>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rez-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>Customer</label>
                                        <SelectBox
                                            dataSource={this.state.customerList.length > 0 ? this.state.customerList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="customerId"
                                            defaultValue={0}
                                            value={this.state.customerId}
                                            displayExpr='fullName'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleCustomerChange}
                                            showClearButton={true}
                                            disabled={this.state.isSearched == true}
                                            searchEnabled={true}
                                        />

                                        {this.state.errors["customerId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>Route Name</label>
                                        <input type="text" className="form-control form-control-sm" name="routeName" maxLength="100" title="Route Name" value={this.state.routeName} onChange={this.handleInputChange} placeholder="Enter route name" />
                                        {this.state.errors["routeName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["routeName"]}</span>}
                                    </div>
                                </div>

                                <div className="col-lg-2 col-md-2 col-sm-6 col-xs-12" style={{ paddingTop: 6 + 'px' }}>
                                    <div className="form-group">
                                        <br />
                                        <span className="form-check">
                                            <input className="form-check-input" type="checkbox" checked={this.state.chkIsAntiBunching} onChange={this.handleInputChange} name="chkIsAntiBunching" />
                                            <label className="form-check-label" htmlFor="chkIsAntiBunching">Is Anti Bunching</label>
                                        </span>
                                        {(this.state.routeId > 0) && <span className="form-check">
                                            <input className="form-check-input" type="checkbox" checked={this.state.chkIsActive} onChange={this.handleInputChange} name="chkIsActive" />
                                            <label className="form-check-label" htmlFor="chkIsActive">Is Active</label>
                                        </span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-6 col-xs-12">

                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-6 col-xs-12 text-right">
                                    <div className="form-group">
                                        <label className="btn-block">&nbsp;</label>
                                        <button type="button" className="btn btn-success btn-sm" onClick={this.handleRouteSubmit} >
                                            {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSubmited && <span title="Save"><i className="fas fa-save"></i> Save</span>}
                                        </button>&nbsp;&nbsp;&nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleClearSearch} title="Clear"><i className="fas fa-eraser"></i> Clear</button>&nbsp;&nbsp;&nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.goToBack} title="Back"><i className="fas fa-arrow-left"></i> Back</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="card card-grid">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                            <DataGrid
                                                elementAttr={{
                                                    id: 'stopgridcontainer'
                                                }}
                                                dataSource={this.state.stopList}
                                                keyExpr="stopId"
                                                columnsAutoWidth={true}
                                                showColumnLines={false}
                                                showRowLines={true}
                                                showBorders={true}
                                                rowAlternationEnabled={true}
                                                allowColumnResizing={true}
                                                columnResizingMode='widget'
                                                onToolbarPreparing={this.onToolbarPreparing}
                                                selectedRowKeys={this.state.selectedRowKeys}
                                                onSelectionChanged={this.onSelectionChanged}
                                                ref={ref => this.dataGrid = ref}
                                            >
                                                <Selection
                                                    mode="multiple"
                                                    allowSelectAll={false}
                                                    selectAllMode={"allPages"}
                                                    showCheckBoxesMode={"always"}
                                                />
                                                <MasterDetail
                                                    enabled={true}
                                                    component={DetailTemplate}
                                                />
                                                <Column dataField="stopId" width={30} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                                <Column dataField="locationName" caption="Please select stop to create route." allowFiltering={true} allowSorting={true} />
                                                <Sorting mode="none" />
                                                <Scrolling columnRenderingMode="virtual" />
                                                <Paging enabled={false} />
                                                <LoadPanel enabled={false} />
                                                <HeaderFilter visible={false} />
                                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                            </DataGrid>
                                        </div>
                                        <div className="col-lg-9 col-md-9 col-sm-6 col-xs-12">
                                            <Map
                                                googleMapURL={window.google.toString()}
                                                markers={this.state.places}
                                                loadingElement={loadingElement}
                                                containerElement={containerElement}
                                                mapElement={mapElement}
                                                defaultCenter={this.state.center}
                                                defaultZoom={this.state.zoom}
                                                directions={this.state.routeDirections}

                                                showInfo={this.showInfo}
                                                showInfoIndex={this.state.showInfoIndex}
                                                handleCloseCall={this.handleCloseCall}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <AddEditStopComponent ref="addEditStopComponent" getStopList={this.getStopList} />

                    <LoadPanel
                        shadingColor="rgba(0,0,0,0.4)"
                        position={position}
                        visible={this.state.loadPanelVisible}
                        showIndicator={true}
                        shading={true}
                        showPane={true}
                        closeOnOutsideClick={false}
                    />
                </form>
            </div>
        );
    }
}

class DetailTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stopId: this.props.data.data.stopId,
            distance: 0,
            time: 0,
            radious: 0,
            isDisableTime: false
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    async componentDidMount() {

        if (selectedStops.length > 1) {
            var stop = selectedStops.filter(f => f.Id == this.state.stopId);
            this.setState({
                isDisableTime: false,
                distance: stop.distance,
                time: stop.time,
                radious: stop.radious,
            })

        } else {
            this.setState({
                isDisableTime: true
            })
        }
    }


    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({
                [name]: value
            });

            for (var i = 0; i < selectedStops.length; i++) {
                if (selectedStops[i].Id == this.state.stopId) {
                    selectedStops[i].time = value.trim() != "" ? parseInt(value) : 0
                }
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="master-detail-caption">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                            <div className="form-group">
                                <label>Distance (Mtr)</label>
                                <input type="text" className="form-control form-control-sm" name="distance" disabled={true} maxLength="100" title="Distance (Mtr)" value={this.state.distance} placeholder="" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                            <div className="form-group">
                                <label>Time (Min)</label>
                                <input type="text" className="form-control form-control-sm" name="time" disabled={this.state.isDisableTime} maxLength="10" title="Time (Min)" value={this.state.time} placeholder="" onChange={this.handleInputChange} />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                            <div className="form-group">
                                <label>Radious (Mtr)</label>
                                <input type="text" className="form-control form-control-sm" name="radious" disabled={true} maxLength="100" title="Radious (Mtr)" value={this.state.radious} placeholder="" />
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default DetailTemplate;

const Map = withScriptjs(

    withGoogleMap(props => (

        <GoogleMap
            center={props.defaultCenter}
            zoom={props.defaultZoom}
            defaultOptions={{
                mapTypeControl: true,
                streetViewControl: true,
                zoomControl: true,
                fullscreenControl: false,
                minZoom: 2,
                maxZoom: 18,
                gestureHandling: "greedy",
                disableDefaultUI: true
            }}
        >
            {props.markers.length == 1 && props.markers.map((marker, index) => {
                const position = { lat: marker.latitude, lng: marker.longitude };
                return <Marker key={index} position={position} onClick={() => { props.showInfo(index) }}>
                    {(props.showInfoIndex !== '') && (props.showInfoIndex == index) &&
                        <InfoWindow onCloseClick={props.handleCloseCall} options={{ width: "100%" }} >
                            <div >
                                <span>{marker.Name}</span>
                            </div>
                        </InfoWindow>}
                </Marker>;
            })}

            {props.directions && <DirectionsRenderer directions={props.directions} />}
        </GoogleMap>
    ))
);


