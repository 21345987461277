import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import { SelectBox } from 'devextreme-react/select-box';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getRequestParams } from '../../helpers/globalfunctions';
import Swal from 'sweetalert2';

let currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class RateLookupComponent extends Component {

    constructor(props) {
        super(props);

        currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            datasource: null,
            pickupZoneId: 0,
            dropoffZoneId: 0,
            apiVehicleTypeId: 0,
            vehicleTypeList: [],
            dropdownZone: [],
            pickupDropdownZone: [],
            pickupZone: '',
            dropoffDropdownZone: [],
            dropoffZone: '',
            errors: {
                pickupZoneId: '',
                dropoffZoneId: '',
            }
        }

        this.getZones = this.getZones.bind(this);
        this.getRideTypeId = this.getRideTypeId.bind(this);
        this.getVehicleTypeList = this.getVehicleTypeList.bind(this);

        this.handleRateLookupSubmit = this.handleRateLookupSubmit.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.handelToolbarPreparing = this.handelToolbarPreparing.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handlePickupZoneChange = this.handlePickupZoneChange.bind(this);
        this.handleDropoffZoneChange = this.handleDropoffZoneChange.bind(this);

        //if (this.state.transactionId !== null) {
        //    let strAlert = "";
        //    strAlert = "Your quote confirmed successfully. confirmation number is: <br><br>";
        //    strAlert += "\t" + this.state.newreservationId + "<br>";
        //    strAlert += "<br> Thank you for booking.";
        //    Swal.fire({
        //        icon: 'success',
        //        title: 'success',
        //        html: strAlert,
        //        width: "25%",
        //        allowOutsideClick: false
        //    }).then((result) => {
        //        /* Read more about isConfirmed, isDenied below */
        //        if (result.isConfirmed) {
        //            window.history.pushState({}, null, '/quotation/list/');
        //        }
        //    })
        //}
    }
    async componentDidMount() {
        this.setState({
            loadPanelVisible: true,
        });
        await this.getZones();
        await this.getVehicleTypeList();
        if (this.props.location.state !== undefined) {

            this.setState({
                apiVehicleTypeId: this.props.location.state.apiVehicleTypeId,
                pickupZoneId: this.props.location.state.pickupZoneId,
                dropoffZoneId: this.props.location.state.dropoffZoneId,
            });

            let myVar = setTimeout(() => this.getRateLookupList(), 500)
        }

        this.setState({
            loadPanelVisible: false,
        });
    }
    handelToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getRateLookupList.bind(this)
            }
        });
    }
    async getZones() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('serviceAreaZone-api/zone-dropdown/' + 0 + "/" + 0, requestParams);
            const data = await response.json();
            this.setState({
                dropdownZone: data.locationServiceAreaZoneList
            });
        } catch (e) {
            console.log(e);
        }
    }
    async getVehicleTypeList() {
        var newObject = {};
        newObject.LocationId = parseInt(sessionStorage.getItem("session_locationId"));
        const requestParams = getRequestParams('POST', newObject);
        const response = await fetch('vehicletype-api/vehicletype', requestParams);
        const data = await response.json();
        
        this.setState({
            vehicleTypeList: data.apiVehicleType
        });
    }
    handleVehicleTypeInputChange = (data) => {
        this.setState({
            apiVehicleTypeId: (data.selectedItem !== null ? data.selectedItem.rowIndex : 0),
        })
    }
    async handleRateLookupSubmit(e) {
        e.preventDefault();
        await this.getRateLookupList();
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
            this.setState({
                [name]: value
            });
    }
    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.pickupZoneId = "";
        errors.dropoffZoneId = "";

        if (this.state.pickupZoneId === 0) {
            formIsValid = false;
            errors.pickupZoneId = "Please select pick-up zone.";
        }
        if (this.state.dropoffZoneId === 0) {
            formIsValid = false;
            errors.dropoffZoneId = "Please select drop off zone.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }    
    async getRateLookupList() {
        if (this.handleValidation()) {
            let fromAirId = 0, toAirId = 0, fromZoneId = 0, toZoneId = 0, rideTypeId = 0;

            let puZone = this.state.dropdownZone.find(opt => opt.id === this.state.pickupZoneId);
            let doZone = this.state.dropdownZone.find(opt => opt.id === this.state.dropoffZoneId);

            if (puZone.zoneTypeId === 1 && doZone.zoneTypeId === 3) {
                fromAirId = puZone.airportId;
                toZoneId = doZone.id;
                rideTypeId = 1;
            } else if (puZone.zoneTypeId === 3 && doZone.zoneTypeId === 1) {
                fromZoneId = puZone.id;
                toAirId = doZone.airportId;
                rideTypeId = 2;
            } else if (puZone.zoneTypeId === 1 && doZone.zoneTypeId === 1) {
                fromAirId = puZone.airportId;
                toAirId = doZone.airportId;
                rideTypeId = 3;
            } else {
                fromZoneId = puZone.id;
                toZoneId = doZone.id;
                rideTypeId = 13;
            }

            var request = {};
            request.CustomerId = 0;
            request.RideTypeId = rideTypeId;
            request.FromAirportId = fromAirId;
            request.ToAirportId = toAirId;
            request.FromZoneId = fromZoneId;
            request.ToZoneId = toZoneId;
            request.PickUpDateTime = currentTime.toLocaleDateString() + " 00:00";
            request.ApiVehicleTypeId = this.state.apiVehicleTypeId;
            request.NoOfHours = 0;
            request.NoOfPax = 0;
            request.RateId = 0;
            request.IsMgRequired = false;

            this.setState({ isSubmited: true });
            try {
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('/customer-api/' + request.CustomerId + '/rate-lookup', requestParams);
                const data = await response.json();

                this.setState({
                    datasource: data.rateLookup,
                });

                this.setState({ isSubmited: false });

            } catch (e) {
                this.setState({ isSubmited: false });
            }
        }
    }
    handlePickupZoneChange(selectedOption) {
        this.setState({ pickupZoneId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0), })
        this.state.errors["pickupZoneId"] = "";
        (selectedOption.selectedItem !== null && this.getRideTypeId(selectedOption.selectedItem.id, this.state.dropoffZoneId));
    }
    handleDropoffZoneChange(selectedOption) {
        this.setState({ dropoffZoneId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0), })
        this.state.errors["dropoffZoneId"] = "";
        (selectedOption.selectedItem !== null && this.getRideTypeId(this.state.pickupZoneId, selectedOption.selectedItem.id));
    }
    handleClearSearch() {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            datasource: null,
            pickupZoneId: 0,
            dropoffZoneId: 0,
            apiVehicleTypeId: 0,
            pickupZone: '',
            dropoffZone: '',
            errors: {
                pickupZoneId: '',
                dropoffZoneId: '',
            }
        });
    }
    getRideTypeId(fromZId, toZId) {
        var rideTypeId = 0;
        if (fromZId > 0 && toZId > 0) {
            let puZone = this.state.dropdownZone.find(opt => opt.id == fromZId);
            let doZone = this.state.dropdownZone.find(opt => opt.id == toZId);

            if (puZone.zoneTypeId === 1 && doZone.zoneTypeId === 3) {
                this.setState({ isMeetGreet: false });
                rideTypeId = 1;
            } else if (puZone.zoneTypeId === 3 && doZone.zoneTypeId === 1) {
                this.setState({ isMeetGreet: false });
                rideTypeId = 2;
            } else if (puZone.zoneTypeId === 1 && doZone.zoneTypeId === 1) {
                this.setState({ isMeetGreet: false });
                rideTypeId = 3;
            } else {
                this.setState({ isMeetGreet: false });
                rideTypeId = 13;
            }
        }
        this.setState({ rideTypeId: rideTypeId });
    }
    createNewReservation(e, data) {
        e.preventDefault();

        var newObject = {};
        newObject.ApiVehicleTypeId = data.apiVehicleTypeId;
        newObject.CustomerId = data.customerId;
        newObject.FromZoneId = this.state.pickupZoneId;
        newObject.ToZoneId = this.state.dropoffZoneId;
        newObject.IsMgRequired = false;

        this.props.history.push({
            pathname: '/reservations',
            //state: { detail: data }
            state: { rideId: 0, rateLookup: newObject, sourceUrl: "/ratelookup/list", sourceState: this.state }
        })
    }

    createNewQuatation(e, data) {
        e.preventDefault();

        var newObject = {};
        newObject.ApiVehicleTypeId = data.apiVehicleTypeId;
        newObject.CustomerId = data.customerId;
        newObject.FromZoneId = this.state.pickupZoneId;
        newObject.ToZoneId = this.state.dropoffZoneId;
        newObject.IsMgRequired = false;
        newObject.RateId = data.rateId;

        this.props.history.push({
            pathname: '/quote',
            //state: { detail: data }
            state: { quoteId: 0, rateLookup: newObject, sourceUrl: "/ratelookup/list", sourceState: this.state }
        })
    }

    render() {
        const renderGridCurrencyCell = (data) => {
            return <div>${parseFloat(data.value).toFixed(2)}</div>;
        }
        const renderNewResQtCell = (data) => {
            return <div>&nbsp;&nbsp;
                <a href="#" className="fas fa-pen icon text-info" title="New Reservation" onClick={(e) => { this.createNewReservation(e, data.data) }} ></a>&nbsp;&nbsp;
                <a href="#" className="fas fa-quote-right icon text-info" title="New Quotation" onClick={(e) => { this.createNewQuatation(e, data.data) }} ></a>&nbsp;&nbsp;
            </div>;
        }
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-2 col-sm-12">
                                <h1 className="m-0 text-dark">Rate Lookup</h1>
                            </div>
                            <div className="col-lg-9 col-md-10 col-sm-12">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem><NavLink tag={Link} to="/quote" className="btn btn-block btn-outline-secondary btn-sm" title="New Quotation"><i className="fas fa-plus"></i> New Quotation</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/reservations" className="btn btn-block btn-outline-secondary btn-sm" title="New Reservation"><i className="fas fa-plus"></i> New Reservation</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/reservation/list" className="btn btn-block btn-outline-secondary btn-sm" title="Search Reservation"><i className="fas fa-search"></i> Search Reservation</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/uploadschedule" className="btn btn-block btn-outline-secondary btn-sm" title="Upload Schedule"><i className="fas fa-clipboard-list"></i> Upload Schedule</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <form method="post" onSubmit={this.handleRateLookupSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="rez-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Pick-up Zone <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.dropdownZone}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select pick-up zone"
                                            name="pickupZoneId"
                                            defaultValue={0}
                                            value={this.state.pickupZoneId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handlePickupZoneChange}
                                            showClearButton={true}
                                            disabled={this.state.isSearched === true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["pickupZoneId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["pickupZoneId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Drop Off Zone <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.dropdownZone}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select drop off zone"
                                            name="dropoffZoneId"
                                            defaultValue={0}
                                            value={this.state.dropoffZoneId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleDropoffZoneChange}
                                            showClearButton={true}
                                            disabled={this.state.isSearched === true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["dropoffZoneId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["dropoffZoneId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Vehicle</label>
                                        <SelectBox
                                            dataSource={this.state.vehicleTypeList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select vehicle type"
                                            name="apiVehicleTypeId"
                                            defaultValue={0}
                                            value={this.state.apiVehicleTypeId}
                                            displayExpr='apiVehicleTypeDescription'
                                            valueExpr='rowIndex'
                                            onSelectionChanged={this.handleVehicleTypeInputChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 text-right text-md-left text-sm-left text-xs-left">
                                    <div className="form-group">
                                        <label className="btn-block">&nbsp;</label>
                                        <button type="submit" className="btn btn-success btn-sm" onClick={this.handleRateLookupSubmit} >
                                            {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSubmited && <span title="Search"><i className="fas fa-search"></i> Search</span>}
                                        </button>&nbsp; &nbsp;
                                             <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleClearSearch} title="Clear"><i className="fas fa-eraser"></i> Clear</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="card card-grid">
                                <div className="card-body">
                                    <DataGrid
                                        dataSource={this.state.datasource}
                                        keyExpr="rateId"
                                        selection={{ mode: 'single' }}
                                        columnsAutoWidth={true}
                                        showColumnLines={true}
                                        showRowLines={true}
                                        showBorders={true}
                                        rowAlternationEnabled={true}
                                        showBorders={true}
                                        allowColumnResizing={true}
                                        columnResizingMode='widget'
                                        repaintChangesOnly={true}
                                        onToolbarPreparing={this.onToolbarPreparing}>
                                        <Column dataField="rateId" width={50} caption="" allowFiltering={false} visible={true} allowSorting={false} cellRender={renderNewResQtCell} />
                                        <Column dataField="vehicleTypeDescription" width={110} allowEditing={false} caption="Vehicle Type" allowFiltering={true} allowSorting={true} />
                                        <Column dataField="vehiclePaxCount" width={100} allowEditing={false} caption="Pax Capacity" allowFiltering={true} allowSorting={true} />
                                        <Column dataField="rate"  caption="Base Fare ($)" allowFiltering={true} allowSorting={true} alignment="right" format="decimal" cellRender={renderGridCurrencyCell} />
                                        <Column dataField="tollAmount" caption="Toll Amt ($)"  allowFiltering={true} allowSorting={true} alignment="right" format="decimal" cellRender={renderGridCurrencyCell} />
                                        <Column dataField="tipAmount" caption="Tip Amt ($)"  allowFiltering={true} allowSorting={true} alignment="right" format="decimal" cellRender={renderGridCurrencyCell} />
                                        <Column dataField="serviceFee" caption="Service Fee($)" allowFiltering={true} allowSorting={true} alignment="right" format="decimal" cellRender={renderGridCurrencyCell} />
                                        <Column dataField="fuelSurCharge" caption="Fuel Surcharge ($)" allowFiltering={true} allowSorting={true} alignment="right" format="decimal" cellRender={renderGridCurrencyCell} />
                                        <Column dataField="parking" caption="Parking Charge($)"  allowFiltering={true} allowSorting={true} alignment="right" format="decimal" cellRender={renderGridCurrencyCell} />
                                        <Column dataField="meetGreetCharge" caption="Meet Greet ($)"  allowFiltering={true} allowSorting={true} alignment="right" format="decimal" cellRender={renderGridCurrencyCell} />
                                        <Column dataField="addtnPaxRate" caption="Add'l Pax Amt ($)"  allowFiltering={true} allowSorting={true} alignment="right" format="decimal" cellRender={renderGridCurrencyCell} />
                                        <Column dataField="agentCommission" caption="Commission Amt ($)"  visible={true} allowFiltering={true} allowSorting={true} alignment="right" format="decimal" cellRender={renderGridCurrencyCell} />
                                        <Column dataField="portAuthority" caption="Port Authority"  visible={true} allowFiltering={true} allowSorting={true} alignment="right" format="decimal" cellRender={renderGridCurrencyCell} />
                                        <Column dataField="utilityTax" caption="Unitlity Tax"  visible={true} allowFiltering={true} allowSorting={true} alignment="right" format="decimal" cellRender={renderGridCurrencyCell} />
                                        <Column dataField="wcTax" caption="WC Tax" visible={true} allowFiltering={true} allowSorting={true} alignment="right" format="decimal" cellRender={renderGridCurrencyCell} />
                                        <Column dataField="salesTax" caption="Sales Tax"  visible={true} allowFiltering={true} allowSorting={true} alignment="right" format="decimal" cellRender={renderGridCurrencyCell} />
                                        <Column dataField="countyTax" caption="County Tax"  visible={true} allowFiltering={true} allowSorting={true} alignment="right" format="decimal" cellRender={renderGridCurrencyCell} />
                                        <Column dataField="congestionTax" caption="Congestion Tax" visible={true} allowFiltering={true} allowSorting={true} alignment="right" format="decimal" cellRender={renderGridCurrencyCell} />
                                        <Column dataField="fare" caption="Gross ($)"  allowFiltering={true} allowSorting={true} alignment="right" format="decimal" cellRender={renderGridCurrencyCell} />
                                        <Column dataField="discountCharge" caption="Discount Amt ($)"  allowFiltering={true} allowSorting={true} alignment="right" format="decimal" cellRender={renderGridCurrencyCell} />
                                        <Column dataField="totalFare" caption="Total Fare ($)" allowFiltering={true} allowSorting={true} alignment="right" format="decimal" cellRender={renderGridCurrencyCell} />

                                        <SortByGroupSummaryInfo summaryItem="count" />
                                        <GroupPanel visible={false} />
                                        <HeaderFilter visible={false} />
                                        <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                        <Grouping autoExpandAll={true} />
                                        <Paging defaultPageSize={20} />
                                        <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                        <Export enabled={true} allowExportSelectedData={false} />
                                        <ColumnChooser enabled={true} />
                                        <ColumnFixing enabled={true} />
                                    </DataGrid>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}