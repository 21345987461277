import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import { TripStatus, LogType } from '../../helpers/fixcodes';
import DatePicker from "react-datepicker"; // npm install react - datepicker --save
import { LoadPanel } from 'devextreme-react/load-panel';
const customStyles = {
    content: {
        width: '35%'
    }
};
const position = { of: '#historydiv' };
let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
let maxPickupDate = new Date(new Date(currentTime).setDate(currentTime.getDate() + 1));
let minPickupDate = new Date(new Date(currentTime).setDate(currentTime.getDate() + 0));
const session_accountId = parseInt(sessionStorage.getItem("session_accountId"));
const session_mappedAccountId1 = parseInt(sessionStorage.getItem("session_mappedAccountId1"));
const session_mappedAccountId2 = parseInt(sessionStorage.getItem("session_mappedAccountId2"));

export class ReservationDateTimeComponent extends Component {
    static displayName = ReservationDateTimeComponent.name;
    currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
    maxPickupDate = new Date(new Date(currentTime).setDate(currentTime.getDate() + 1));
    minPickupDate = new Date(new Date(currentTime).setDate(currentTime.getDate() + 0));

    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
            isSubmited: false,
            loadPanelVisible: false,
            reason: "",
            rideId: 0,
            pageName: '',
            tripDetails: null,
            chkRideTypeSelection: "1",
            puDateTime: currentTime,
            flightDateTime: currentTime,
            refreshRideSource: props.refreshDataSource,
            handleRefresh: props.handleRefresh,
            maxPickupDate: maxPickupDate,
            minPickupDate: minPickupDate,
            allowSocketConnection: sessionStorage.getItem("session_allowSocketConnection") === "true" ? true : false,
            errors: {
                puDateTime: '',
                flightDateTime: '',
            }
        }

        this.handleRideTypeSelectionChange = this.handleRideTypeSelectionChange.bind(this);
        this.closeActualTimeModal = this.closeActualTimeModal.bind(this);
        this.afterOpenActualTimeModal = this.afterOpenActualTimeModal.bind(this);
        this.handleActualTimeReservation = this.handleActualTimeReservation.bind(this);
        this.showModal = this.showModal.bind(this);

        this.handlePickupDatetimeChange = this.handlePickupDatetimeChange.bind(this);
        this.handleFlightDatetimeChange = this.handleFlightDatetimeChange.bind(this);
    }

    closeActualTimeModal() {
        this.setState({
            isOpenModal: false,
            isSubmited: false,
            reason: "",
            rideId: 0,
            pageName: '',
            tripDetails: null,

            chkRideTypeSelection: "1",
            puDateTime: currentTime,
            flightDateTime: currentTime,

            errors: {
                puDateTime: '',
                flightDateTime: '',
            }
        });

        if (!this.state.allowSocketConnection) {
            if (this.state.handleRefresh != undefined || this.state.handleRefresh != null) {
                this.state.handleRefresh(true);
            }
            if (this.state.refreshRideSource != undefined || this.state.refreshRideSource != null) {
                this.state.refreshRideSource();
            }
        }
    }

    handleRideTypeSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkRideTypeSelection: value });
    }

    async getGetTripDetails(rideId) {
        var newObject = new Object();
        newObject.tripId = parseInt(rideId);
        this.setState({
            loadPanelVisible: true,
        });
        const requestParams = getRequestParams('POST', newObject);
        const response = await fetch('reservation-api/trip-details/', requestParams);
        const data = await response.json();
        this.setState({
            tripDetails: data.tripDetails,
            loadPanelVisible: false,
        });
    }

    async showModal(pageName, rideId) {
        this.setState({
            isOpenModal: true,
            pageName: pageName,
            rideId: rideId,
            chkRideTypeSelection: "1",
            puDateTime: currentTime,
            flightDateTime: currentTime,
        });

        await this.getGetTripDetails(rideId);
        if (this.state.tripDetails != null) {
            this.setState({
                chkRideTypeSelection: (this.state.tripDetails.rideTypeId == 1 || this.state.tripDetails.rideTypeId == 3) ? "1" : "2",
                puDateTime: new Date(this.state.tripDetails.puDateTime),
                flightDateTime: (this.state.tripDetails.rideTypeId == 1 || this.state.tripDetails.rideTypeId == 3) ? new Date(this.state.tripDetails.puFlightDateTime) : new Date(this.state.tripDetails.doFlightDateTime),
                maxPickupDate: new Date(new Date(this.state.tripDetails.puDateTime).setDate(new Date(this.state.tripDetails.puDateTime).getDate() + 1)),
                minPickupDate: new Date(new Date(this.state.tripDetails.puDateTime).setDate(new Date(this.state.tripDetails.puDateTime).getDate() + 0)),
            });
        }
    }

    handlePickupDatetimeChange(date) {
        this.setState({ puDateTime: date });
        this.state.errors["puDateTime"] = "";
    }

    handleFlightDatetimeChange(date) {
        this.setState({ flightDateTime: date });
        this.state.errors["flightDateTime"] = "";
    }

    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.puDateTime = "";
        errors.flightDateTime = "";

        if (this.state.puDateTime == null) {
            formIsValid = false;
            errors.puDateTime = "Please enter pickup date time.";
        }

        if (this.state.flightDateTime == null) {
            formIsValid = false;
            errors.flightDateTime = "Please enter flight date time.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    async handleActualTimeReservation(e) {
        e.preventDefault();
        if (this.handleValidation()) {
           
            var newObject = new Object();
            newObject.RideId = parseInt(this.state.rideId);
            //newObject.PickupDateTime = this.state.puDateTime.toLocaleDateString() + " " + this.state.puDateTime.toLocaleTimeString();
            //newObject.FlightDateTime = this.state.flightDateTime.toLocaleDateString() + " " + this.state.flightDateTime.toLocaleTimeString();
            newObject.PickupDateTime = formatDate(this.state.puDateTime, "MM/dd/yyyy hh:mm:ss");
            newObject.FlightDateTime = formatDate(this.state.flightDateTime, "MM/dd/yyyy hh:mm:ss");

            this.setState({ isSubmited: true });
            const requestParams = getRequestParams('POST', newObject);
            const response = await fetch('/dispatch-api/update-ride-datetime', requestParams);
            const data = await response.json();

            Swal.fire({
                icon: data.isSuccess ? 'success' : 'error',
                title: data.isSuccess ? 'Update Ride Datetime' : 'Oops...',
                text: data.resultMessage,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (data.isSuccess) {
                    if (result.isConfirmed) {
                        this.setState({ isSubmited: false });
                        if (this.state.handleRefresh != undefined || this.state.handleRefresh != null) {
                            this.state.handleRefresh(true);
                        }
                        if (this.state.refreshRideSource != undefined || this.state.refreshRideSource != null) {
                            this.state.refreshRideSource();
                        }
                    }
                } else {
                    this.setState({ isSubmited: false });
                }
            });
        }
    }

    afterOpenActualTimeModal() {
        this.setState({
            reason: "",
        });
    }

    render() {
        const filterPassedTime = time => {
            const currentDate = currentTime;
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={customStyles}
                    className={"react-modal"}
                    onAfterOpen={this.afterOpenActualTimeModal}
                    onRequestClose={this.closeActualTimeModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleActualTimeReservation} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Update Ride Datetime - {this.state.rideId}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeActualTimeModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdArrival" value="1" disabled={true} checked={this.state.chkRideTypeSelection === "1"} onChange={this.handleRideTypeSelectionChange} />
                                            <label className="form-check-label">Arrival</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdDeparture" value="2" disabled={true} checked={this.state.chkRideTypeSelection === "2"} onChange={this.handleRideTypeSelectionChange} />
                                            <label className="form-check-label">Departure</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="form-check-label">Enter Pickup Datetime</label>
                                        <DatePicker
                                            className="form-control form-control-sm "
                                            placeholderText=""
                                            name="puDateTime"
                                            showTimeInput
                                            selected={this.state.puDateTime}
                                            dateFormat="MM/dd/yyyy HH:mm"
                                            onChange={this.handlePickupDatetimeChange}
                                            filterTime={filterPassedTime}
                                            minDate={(session_mappedAccountId1 !== session_accountId && session_mappedAccountId2 !== session_accountId) ? "" : this.state.minPickupDate}
                                            maxDate={(session_mappedAccountId1 !== session_accountId && session_mappedAccountId2 !== session_accountId) ? "" : this.state.maxPickupDate}
                                        />
                                        {this.state.errors["puDateTime"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["puDateTime"]}</span>}
                                    </div>
                                </div>
                                {(session_mappedAccountId1 !== session_accountId && session_mappedAccountId2 !== session_accountId) ?
                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="form-check-label">Enter Flight Datetime</label>
                                        <DatePicker
                                            className="form-control form-control-sm "
                                            placeholderText=""
                                            name="flightDateTime"
                                            showTimeInput
                                            selected={this.state.flightDateTime}
                                            dateFormat="MM/dd/yyyy HH:mm"
                                            onChange={this.handleFlightDatetimeChange}
                                            filterTime={filterPassedTime}
                                        />
                                        {this.state.errors["flightDateTime"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["flightDateTime"]}</span>}
                                    </div>
                                </div> : null}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success btn-sm" onClick={this.handleActualTimeReservation} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeActualTimeModal}><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}