import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

const google = window.google;

const MySwal = withReactContent(Swal)
function shouldRenderSuggestions(value, reason) {
    return value.trim().length > 2;
}

function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
function renderSuggestion(suggestion) {
    if (suggestion.isNotFound) {
        return (
            <span>
                Data not found!
            </span>
        );
    }

    return (
        <span>{suggestion.name}</span>
    );
}

export class NextStepComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            airlineCode: '',
            airportCode: '',
            customerId: 0,
            locationId: 0,
            nextButtonDisplay: false,

            Address1: "",
            City1: "",
            Location1: "",
            ZipCode1: "",
            Latitude1: 0,
            Longitude1: 0,
            State1: "",

            Address2: "",
            City2: "",
            Location2: "",
            ZipCode2: "",
            Latitude2: 0,
            Longitude2: 0,
            State2: "",

            Address3: "",
            City3: "",
            Location3: "",
            ZipCode3: "",
            Latitude3: 0,
            Longitude3: 0,
            State3: "",

            Address4: "",
            City4: "",
            Location4: "",
            ZipCode4: "",
            Latitude4: 0,
            Longitude4: 0,
            State4: "",

            Address5: "",
            City5: "",
            Location5: "",
            ZipCode5: "",
            Latitude5: 0,
            Longitude5: 0,
            State5: "",

            Address6: "",
            City6: "",
            Location6: "",
            ZipCode6: "",
            Latitude6: 0,
            Longitude6: 0,
            State6: "",

            Address7: "",
            City7: "",
            Location7: "",
            ZipCode7: "",
            Latitude7: 0,
            Longitude7: 0,
            State7: "",

            Address8: "",
            City8: "",
            Location8: "",
            ZipCode8: "",
            Latitude8: 0,
            Longitude8: 0,
            State8: "",

            documentLocation1: "",
            documentLocation2: "",
            documentLocation3: "",
            documentLocation4: "",
            documentLocation5: "",
            documentLocation6: "",
            documentLocation7: "",
            documentLocation8: "",

            documentLocation: [],
            actualLocationData: [],
            tripsList: props.location.state !== null || props.location.state.length > 0 ? props.location.state : null,
            nextStepTripList: null,
            finalTripList: null,
            errors: {
                location1: '',
                location2: '',
                location3: '',
                location4: '',
                location5: '',
                location6: '',
                location7: '',
                location8: '',
            }
        }
        this.getLocation = this.getLocation.bind(this);
        this.handlePickupPlaceSelect1 = this.handlePickupPlaceSelect1.bind(this);
        this.handlePickupPlaceSelect2 = this.handlePickupPlaceSelect2.bind(this);
        this.handlePickupPlaceSelect3 = this.handlePickupPlaceSelect3.bind(this);
        this.handlePickupPlaceSelect4 = this.handlePickupPlaceSelect4.bind(this);
        this.handlePickupPlaceSelect5 = this.handlePickupPlaceSelect5.bind(this);
        this.handlePickupPlaceSelect6 = this.handlePickupPlaceSelect6.bind(this);
        this.handlePickupPlaceSelect7 = this.handlePickupPlaceSelect7.bind(this);
        this.handlePickupPlaceSelect8 = this.handlePickupPlaceSelect8.bind(this);
        this.handlePickupToggleChange = this.handlePickupToggleChange.bind(this);
        this.handlePlaceSearch = this.handlePlaceSearch.bind(this);
        this.handleReservationDetailsFormValidation = this.handleReservationDetailsFormValidation.bind(this);
        this.handleNextDataSubmit = this.handleNextDataSubmit.bind(this);
    }
    componentDidMount = () => {
        this.getNextStepTripList();
    }
    async getNextStepTripList() {
        
        var newObject = [];
        newObject = this.state.tripsList;
        const requestParams = getRequestParams('POST', newObject);
        const response = await fetch('uploadSchedule-api/getGridNextReservationStep', requestParams);
        const data = await response.json();
        if (data !== null) {
            this.setState({
                nextButtonDisplay: true,
                nextStepTripList: data.lstTrip,
                airlineCode: data.lstTrip[0].airlineCode,
                airportCode: data.lstTrip[0].airportCode,
                customerId: data.lstTrip[0].customerId,
                locationId: data.lstTrip[0].serviceLocationId,
                documentLocation: data.finalLocation
            });
            if (data.finalLocation.length > 0) {
                this.getLocation();
            } else {
                this.props.history.push({
                    pathname: '/finallistuploadschedule',
                    state: data.lstTrip,
                })
            }
        }
    }
    SearchArray = (element, array) => {
        var len = array.length, str = element.toString().toLowerCase();
        for (var i = 0; i < len; i++) {
            if (array[i].toLowerCase() == str) { return i; }
        }
        return -1;
    }
    async getLocation() {
        
        const requestParams = getRequestParams('GET', "");
        const response = await fetch('uploadSchedule-api/getLocation/' + this.state.airlineCode + "/" + this.state.customerId + "/" + this.state.locationId, requestParams);
        const data = await response.json();
        if (data !== null) {
            var filteractualLocationData = [];
            if (data.locationData.length > 0) {
                for (let i = 0; i < data.locationData.length; i++) {
                    //var index = documentLocation.indexOf(data[i].DocumentLocationName);
                    var index = this.SearchArray(data.locationData[i].documentLocationName, this.state.documentLocation)
                    if (index > -1) {
                        filteractualLocationData.push(data.locationData[i]);
                        this.setState({
                            documentLocation: this.state.documentLocation.filter(function (item) {
                                return item.toLowerCase() !== data.locationData[i].documentLocationName.toLowerCase()
                            })
                        })
                    }
                }
                this.setState({
                    actualLocationData: filteractualLocationData,
                })
            }
            if (filteractualLocationData.length > 0) {

                for (let k = 0; k < filteractualLocationData.length; k++) {

                    var actulaLocationData = filteractualLocationData[k];

                    var request = new Object();
                    request.DocumentLocation = actulaLocationData.documentLocationName;
                    request.ActualLocation = actulaLocationData.accutalLoationName;
                    request.Address = actulaLocationData.locationAddress;
                    request.Zipcode = actulaLocationData.locationZipcode;
                    request.CityName = actulaLocationData.locationCityName;
                    request.Latitude = actulaLocationData.locationLatitude;
                    request.Longitude = actulaLocationData.locationLongitude;
                    request.StateName = actulaLocationData.locationStateName;
                    request.ScheduleTrip = this.state.finalTripList == null ? this.state.nextStepTripList : this.state.finalTripList;

                    const requestParams = getRequestParams('POST', request);
                    const response = await fetch('uploadSchedule-api/replaceLoaction', requestParams);
                    const data = await response.json();
                    if (data !== null) {
                        this.setState({ finalTripList: data })
                    }
                }

                if (this.state.documentLocation.length === 0) {
                    this.props.history.push({
                        pathname: '/finallistuploadschedule',
                        state: this.state.finalTripList == null ? this.state.nextStepTripList : this.state.finalTripList
                    })
                }

            }
            for (let i = 0; i < this.state.documentLocation.length; i++) {
                if (i === 0) {
                    this.setState({
                        documentLocation1: this.state.documentLocation[i]
                    })
                }
                else if (i === 1) {
                    this.setState({
                        documentLocation2: this.state.documentLocation[i]
                    })
                }
                else if (i === 2) {
                    this.setState({
                        documentLocation3: this.state.documentLocation[i]
                    })
                }
                else if (i === 3) {
                    this.setState({
                        documentLocation4: this.state.documentLocation[i]
                    })
                }
                else if (i === 4) {
                    this.setState({
                        documentLocation5: this.state.documentLocation[i]
                    })
                }
                else if (i === 5) {
                    this.setState({
                        documentLocation6: this.state.documentLocation[i]
                    })
                }
                else if (i === 6) {
                    this.setState({
                        documentLocation7: this.state.documentLocation[i]
                    })
                }
                else if (i === 7) {
                    this.setState({
                        documentLocation8: this.state.documentLocation[i]
                    })
                }
            }
            this.handlePickupToggleChange();
        }
    }

    handlePickupPlaceSelect1() {
        var placeObj = this.handlePlaceSearch(this.autocompletePickup1);
        if (placeObj !== null) {
            this.setState({
                Address1: placeObj.address1,
                City1: placeObj.cityName,
                Location1: placeObj.location,
                ZipCode1: placeObj.zipCode,
                Latitude1: placeObj.latitude,
                Longitude1: placeObj.longitude,
                State1: placeObj.stateName,
            })
        } else {
            this.setState({
                Address1: "",
                City1: "",
                Location1: "",
                ZipCode1: "",
                Latitude1: "",
                Longitude1: "",
                State1: "",
            });
            this.state.errors["location1"] = "";
        }
    }

    handlePickupPlaceSelect2() {
        var placeObj = this.handlePlaceSearch(this.autocompletePickup2);
        if (placeObj !== null) {
            this.setState({
                Address2: placeObj.address1,
                City2: placeObj.cityName,
                Location2: placeObj.location,
                ZipCode2: placeObj.zipCode,
                Latitude2: placeObj.latitude,
                Longitude2: placeObj.longitude,
                State2: placeObj.stateName,
            })
        } else {
            this.setState({
                Address2: "",
                City2: "",
                Location2: "",
                ZipCode2: "",
                Latitude2: "",
                Longitude2: "",
                State2: "",
            });
            this.state.errors["location2"] = "";
        }
    }

    handlePickupPlaceSelect3() {
        var placeObj = this.handlePlaceSearch(this.autocompletePickup3);
        if (placeObj !== null) {
            this.setState({
                Address3: placeObj.address1,
                City3: placeObj.cityName,
                Location3: placeObj.location,
                ZipCode3: placeObj.zipCode,
                Latitude3: placeObj.latitude,
                Longitude3: placeObj.longitude,
                State3: placeObj.stateName,
            })
        } else {
            this.setState({
                Address3: "",
                City3: "",
                Location3: "",
                ZipCode3: "",
                Latitude3: "",
                Longitude3: "",
                State3: "",
            });
            this.state.errors["location3"] = "";
        }
    }

    handlePickupPlaceSelect4() {
        var placeObj = this.handlePlaceSearch(this.autocompletePickup4);
        if (placeObj !== null) {
            this.setState({
                Address4: placeObj.address1,
                City4: placeObj.cityName,
                Location4: placeObj.location,
                ZipCode4: placeObj.zipCode,
                Latitude4: placeObj.latitude,
                Longitude4: placeObj.longitude,
                State4: placeObj.stateName,
            })
        } else {
            this.setState({
                Address4: "",
                City4: "",
                Location4: "",
                ZipCode4: "",
                Latitude4: "",
                Longitude4: "",
                State4: "",
            });
            this.state.errors["location4"] = "";
        }
    }

    handlePickupPlaceSelect5() {
        var placeObj = this.handlePlaceSearch(this.autocompletePickup5);
        if (placeObj !== null) {
            this.setState({
                Address5: placeObj.address1,
                City5: placeObj.cityName,
                Location5: placeObj.location,
                ZipCode5: placeObj.zipCode,
                Latitude5: placeObj.latitude,
                Longitude5: placeObj.longitude,
                State5: placeObj.stateName,
            })
        } else {
            this.setState({
                Address5: "",
                City5: "",
                Location5: "",
                ZipCode5: "",
                Latitude5: "",
                Longitude5: "",
                State5: "",
            });
            this.state.errors["location5"] = "";
        }
    }

    handlePickupPlaceSelect6() {
        var placeObj = this.handlePlaceSearch(this.autocompletePickup6);
        if (placeObj !== null) {
            this.setState({
                Address6: placeObj.address1,
                City6: placeObj.cityName,
                Location6: placeObj.location,
                ZipCode6: placeObj.zipCode,
                Latitude6: placeObj.latitude,
                Longitude6: placeObj.longitude,
                State6: placeObj.stateName,
            })
        } else {
            this.setState({
                Address6: "",
                City6: "",
                Location6: "",
                ZipCode6: "",
                Latitude6: "",
                Longitude6: "",
                State6: "",
            });
            this.state.errors["location6"] = "";
        }
    }

    handlePickupPlaceSelect7() {
        var placeObj = this.handlePlaceSearch(this.autocompletePickup7);
        if (placeObj !== null) {
            this.setState({
                Address7: placeObj.address1,
                City7: placeObj.cityName,
                Location7: placeObj.location,
                ZipCode7: placeObj.zipCode,
                Latitude7: placeObj.latitude,
                Longitude7: placeObj.longitude,
                State7: placeObj.stateName,
            })
        } else {
            this.setState({
                Address7: "",
                City7: "",
                Location7: "",
                ZipCode7: "",
                Latitude7: "",
                Longitude7: "",
                State7: "",
            });
            this.state.errors["location7"] = "";
        }
    }

    handlePickupPlaceSelect8() {
        var placeObj = this.handlePlaceSearch(this.autocompletePickup8);
        if (placeObj !== null) {
            this.setState({
                Address8: placeObj.address1,
                City8: placeObj.cityName,
                Location8: placeObj.location,
                ZipCode8: placeObj.zipCode,
                Latitude8: placeObj.latitude,
                Longitude8: placeObj.longitude,
                State8: placeObj.stateName,
            })
        } else {
            this.setState({
                Address8: "",
                City8: "",
                Location8: "",
                ZipCode8: "",
                Latitude8: "",
                Longitude8: "",
                State8: "",
            });
            this.state.errors["location8"] = "";
        }
    }


    handlePickupToggleChange = () => {
        var options = { types: ['establishment'] };
        if (this.state.documentLocation1 !== "") {
            this.autocompletePickup1 = new google.maps.places.Autocomplete(document.getElementById('pickupGoogle1'), options);
            this.autocompletePickup1.addListener("place_changed", this.handlePickupPlaceSelect1);
        }
        if (this.state.documentLocation2 !== "") {
            this.autocompletePickup2 = new google.maps.places.Autocomplete(document.getElementById('pickupGoogle2'), options);
            this.autocompletePickup2.addListener("place_changed", this.handlePickupPlaceSelect2);
        }
        if (this.state.documentLocation3 !== "") {
            this.autocompletePickup3 = new google.maps.places.Autocomplete(document.getElementById('pickupGoogle3'), options);
            this.autocompletePickup3.addListener("place_changed", this.handlePickupPlaceSelect3);
        }
        if (this.state.documentLocation4 !== "") {
            this.autocompletePickup4 = new google.maps.places.Autocomplete(document.getElementById('pickupGoogle4'), options);
            this.autocompletePickup4.addListener("place_changed", this.handlePickupPlaceSelect4);
        }
        if (this.state.documentLocation5 !== "") {
            this.autocompletePickup5 = new google.maps.places.Autocomplete(document.getElementById('pickupGoogle5'), options);
            this.autocompletePickup5.addListener("place_changed", this.handlePickupPlaceSelect5);
        }
        if (this.state.documentLocation6 !== "") {
            this.autocompletePickup6 = new google.maps.places.Autocomplete(document.getElementById('pickupGoogle6'), options);
            this.autocompletePickup6.addListener("place_changed", this.handlePickupPlaceSelect6);
        }
        if (this.state.documentLocation7 !== "") {
            this.autocompletePickup7 = new google.maps.places.Autocomplete(document.getElementById('pickupGoogle7'), options);
            this.autocompletePickup7.addListener("place_changed", this.handlePickupPlaceSelect7);
        }
        if (this.state.documentLocation8 !== "") {
            this.autocompletePickup8 = new google.maps.places.Autocomplete(document.getElementById('pickupGoogle8'), options);
            this.autocompletePickup8.addListener("place_changed", this.handlePickupPlaceSelect8);
        }
    }
    handlePlaceSearch(autocomplete_place) {
        let place = autocomplete_place.getPlace();

        var placeObject = new Object();

        if (place !== null && place.formatted_address !== null && place.formatted_address.trim() != "") {
            placeObject.location = place.name;
            placeObject.latitude = place.geometry.location.lat();
            placeObject.longitude = place.geometry.location.lng();

            var splitAddress = [];
            place.formatted_address = place.formatted_address.replace(/-/g, ',');
            splitAddress = place.formatted_address.split(',');
            if (splitAddress.length > 0) {
                if (splitAddress.length == 1) {
                    placeObject.address1 = splitAddress[0];
                    placeObject.cityName = "";
                    placeObject.zipCode = "";
                    placeObject.stateName = "";
                    placeObject.countryName = "";
                }
                else if (splitAddress.length == 2) {
                    placeObject.address1 = splitAddress[0];
                    placeObject.cityName = splitAddress[0];
                    placeObject.stateName = splitAddress[1].trim().search(' ') > 0 ? splitAddress[1].trim().split(' ')[0] : splitAddress[1].trim();
                    placeObject.zipCode = splitAddress[1].trim().search(' ') > 0 ? splitAddress[1].trim().split(' ')[1] : " ";
                    placeObject.countryName = "";
                }
                else if (splitAddress.length == 3) {
                    placeObject.address1 = splitAddress[0] + ' ' + splitAddress[1].trim();
                    placeObject.cityName = splitAddress[1].trim();
                    placeObject.stateName = splitAddress[2].trim().search(' ') > 0 ? splitAddress[2].trim().split(' ')[0] : splitAddress[2].trim();
                    placeObject.zipCode = splitAddress[2].trim().search(' ') > 0 ? splitAddress[2].trim().split(' ')[1] : " ";
                    placeObject.countryName = "";
                }
                else if (splitAddress.length == 4) {
                    placeObject.address1 = splitAddress[0] + ' ' + splitAddress[1].trim();
                    placeObject.cityName = splitAddress[1].trim();
                    placeObject.stateName = splitAddress[2].trim().search(' ') > 0 ? splitAddress[2].trim().split(' ')[0] : splitAddress[2].trim();
                    placeObject.zipCode = splitAddress[2].trim().search(' ') > 0 ? splitAddress[2].trim().split(' ')[1] : " ";
                    placeObject.countryName = splitAddress[3].trim();
                }
                else if (splitAddress.length == 5) {
                    placeObject.address1 = splitAddress[0] + ' ' + splitAddress[1].trim() + ' ' + splitAddress[2].trim();
                    placeObject.cityName = splitAddress[2].trim();
                    placeObject.stateName = splitAddress[3].trim().search(' ') > 0 ? splitAddress[3].trim().split(' ')[0] : splitAddress[3].trim();
                    placeObject.zipCode = splitAddress[3].trim().search(' ') > 0 ? splitAddress[3].trim().split(' ')[1] : " ";
                    placeObject.countryName = splitAddress[4].trim();
                }
                else if (splitAddress.length == 6) {
                    placeObject.address1 = splitAddress[0] + ' ' + splitAddress[1].trim() + ' ' + splitAddress[2].trim() + ' ' + splitAddress[3].trim();
                    placeObject.cityName = splitAddress[3].trim();
                    placeObject.stateName = splitAddress[4].trim().search(' ') > 0 ? splitAddress[4].trim().split(' ')[0] : splitAddress[4].trim();
                    placeObject.zipCode = splitAddress[4].trim().search(' ') > 0 ? splitAddress[4].trim().split(' ')[1] : " ";
                    placeObject.countryName = splitAddress[5].trim();
                }
                else {
                    placeObject.address1 = splitAddress[0] + ' ' + splitAddress[1].trim() + ' ' + splitAddress[2].trim() + ' ' + splitAddress[3].trim() + ' ' + splitAddress[4].trim();
                    placeObject.cityName = splitAddress[4].trim();
                    placeObject.stateName = splitAddress[5].trim().search(' ') > 0 ? splitAddress[5].trim().split(' ')[0] : splitAddress[5].trim();
                    placeObject.zipCode = splitAddress[5].trim().search(' ') > 0 ? splitAddress[5].trim().split(' ')[1] : " ";
                    placeObject.countryName = splitAddress[6].trim();
                }
            }
        }
        return placeObject;
    }
    handleReservationDetailsFormValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.location1 = "";
        errors.location2 = "";
        errors.location3 = "";
        errors.location4 = "";
        errors.location5 = "";
        errors.location6 = "";
        errors.location7 = "";
        errors.location8 = "";

        if (this.state.documentLocation1 !== "") {
            if (this.state.Location1 === "") {
                formIsValid = false;
                errors.location1 = "Please enter proper address.";
            }
        }
        if (this.state.documentLocation2 !== "") {
            if (this.state.Location2 === "") {
                formIsValid = false;
                errors.location2 = "Please enter proper address.";
            }
        }
        if (this.state.documentLocation3 !== "") {
            if (this.state.Location3 === "") {
                formIsValid = false;
                errors.location3 = "Please enter proper address.";
            }
        }
        if (this.state.documentLocation4 !== "") {
            if (this.state.Location4 === "") {
                formIsValid = false;
                errors.location4 = "Please enter proper address.";
            }
        }
        if (this.state.documentLocation5 !== "") {
            if (this.state.Location5 === "") {
                formIsValid = false;
                errors.location5 = "Please enter proper address.";
            }
        }
        if (this.state.documentLocation6 !== "") {
            if (this.state.Location6 === "") {
                formIsValid = false;
                errors.location6 = "Please enter proper address.";
            }
        }
        if (this.state.documentLocation7 !== "") {
            if (this.state.Location7 === "") {
                formIsValid = false;
                errors.location7 = "Please enter proper address.";
            }
        }
        if (this.state.documentLocation8 !== "") {
            if (this.state.Location8 === "") {
                formIsValid = false;
                errors.location8 = "Please enter proper address.";
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    async handleNextDataSubmit(e) {
        e.preventDefault();

        if (this.handleReservationDetailsFormValidation()) {
            let locations = [];
            if (this.state.documentLocation1 !== "") {
                let locationDataModel = new Object();
                locationDataModel.DocumentLocations = new Object();
                locationDataModel.DocumentLocations.AirlineCode = this.state.airlineCode;
                locationDataModel.DocumentLocations.AirportCode = this.state.airportCode;
                locationDataModel.DocumentLocations.CustomerId = parseInt(this.state.customerId);
                locationDataModel.DocumentLocations.DocumentLocationName = this.state.documentLocation1.trim();
                locationDataModel.DocumentLocations.AccutalLoationName = this.state.documentLocation1.trim().length == 3 ? this.state.documentLocation1.trim() : this.state.Location1;
                locationDataModel.DocumentLocations.LocationAddress = this.state.Address1;
                locationDataModel.DocumentLocations.LocationZipcode = this.state.ZipCode1;
                locationDataModel.DocumentLocations.LocationCityName = this.state.City1;
                locationDataModel.DocumentLocations.LocationStateName = this.state.State1;

                locationDataModel.DocumentLocations.LocationLatitude = parseFloat(this.state.Latitude1);
                locationDataModel.DocumentLocations.LocationLongitude = parseFloat(this.state.Longitude1);
                locationDataModel.DocumentLocations.AccountId = parseInt(sessionStorage.getItem('session_accountId'));
                locationDataModel.DocumentLocations.Enteredby = parseInt(sessionStorage.getItem('session_userId'));
                locationDataModel.DocumentLocations.LocationId = parseInt(this.state.locationId);
                locations.push(locationDataModel.DocumentLocations);
            }
            if (this.state.documentLocation2 !== "") {
                let locationDataModel = new Object();
                locationDataModel.DocumentLocations = new Object();
                locationDataModel.DocumentLocations.AirlineCode = this.state.airlineCode;
                locationDataModel.DocumentLocations.AirportCode = this.state.airportCode;
                locationDataModel.DocumentLocations.CustomerId = parseInt(this.state.customerId);
                locationDataModel.DocumentLocations.DocumentLocationName = this.state.documentLocation2.trim();
                locationDataModel.DocumentLocations.AccutalLoationName = this.state.documentLocation2.trim().length == 3 ? this.state.documentLocation2.trim() : this.state.Location2;
                locationDataModel.DocumentLocations.LocationAddress = this.state.Address2;
                locationDataModel.DocumentLocations.LocationZipcode = this.state.ZipCode2;
                locationDataModel.DocumentLocations.LocationCityName = this.state.City2;
                locationDataModel.DocumentLocations.LocationStateName = this.state.State2;

                locationDataModel.DocumentLocations.LocationLatitude = parseFloat(this.state.Latitude2);
                locationDataModel.DocumentLocations.LocationLongitude = parseFloat(this.state.Longitude2);
                locationDataModel.DocumentLocations.AccountId = parseInt(sessionStorage.getItem('session_accountId'));
                locationDataModel.DocumentLocations.Enteredby = parseInt(sessionStorage.getItem('session_userId'));
                locationDataModel.DocumentLocations.LocationId = parseInt(this.state.locationId);
                locations.push(locationDataModel.DocumentLocations);
            }
            if (this.state.documentLocation3 !== "") {
                let locationDataModel = new Object();
                locationDataModel.DocumentLocations = new Object();
                locationDataModel.DocumentLocations.AirlineCode = this.state.airlineCode;
                locationDataModel.DocumentLocations.AirportCode = this.state.airportCode;
                locationDataModel.DocumentLocations.CustomerId = parseInt(this.state.customerId);
                locationDataModel.DocumentLocations.DocumentLocationName = this.state.documentLocation3.trim();
                locationDataModel.DocumentLocations.AccutalLoationName = this.state.documentLocation3.trim().length == 3 ? this.state.documentLocation3.trim() : this.state.Location3;
                locationDataModel.DocumentLocations.LocationAddress = this.state.Address3;
                locationDataModel.DocumentLocations.LocationZipcode = this.state.ZipCode3;
                locationDataModel.DocumentLocations.LocationCityName = this.state.City3;
                locationDataModel.DocumentLocations.LocationStateName = this.state.State3;

                locationDataModel.DocumentLocations.LocationLatitude = parseFloat(this.state.Latitude3);
                locationDataModel.DocumentLocations.LocationLongitude = parseFloat(this.state.Longitude3);
                locationDataModel.DocumentLocations.AccountId = parseInt(sessionStorage.getItem('session_accountId'));
                locationDataModel.DocumentLocations.Enteredby = parseInt(sessionStorage.getItem('session_userId'));
                locationDataModel.DocumentLocations.LocationId = parseInt(this.state.locationId);
                locations.push(locationDataModel.DocumentLocations);
            }
            if (this.state.documentLocation4 !== "") {
                let locationDataModel = new Object();
                locationDataModel.DocumentLocations = new Object();
                locationDataModel.DocumentLocations.AirlineCode = this.state.airlineCode;
                locationDataModel.DocumentLocations.AirportCode = this.state.airportCode;
                locationDataModel.DocumentLocations.CustomerId = parseInt(this.state.customerId);
                locationDataModel.DocumentLocations.DocumentLocationName = this.state.documentLocation4.trim();
                locationDataModel.DocumentLocations.AccutalLoationName = this.state.documentLocation4.trim().length == 3 ? this.state.documentLocation4.trim() : this.state.Location4;
                locationDataModel.DocumentLocations.LocationAddress = this.state.Address4;
                locationDataModel.DocumentLocations.LocationZipcode = this.state.ZipCode4;
                locationDataModel.DocumentLocations.LocationCityName = this.state.City4;
                locationDataModel.DocumentLocations.LocationStateName = this.state.State4;

                locationDataModel.DocumentLocations.LocationLatitude = parseFloat(this.state.Latitude4);
                locationDataModel.DocumentLocations.LocationLongitude = parseFloat(this.state.Longitude4);
                locationDataModel.DocumentLocations.AccountId = parseInt(sessionStorage.getItem('session_accountId'));
                locationDataModel.DocumentLocations.Enteredby = parseInt(sessionStorage.getItem('session_userId'));
                locationDataModel.DocumentLocations.LocationId = parseInt(this.state.locationId);
                locations.push(locationDataModel.DocumentLocations);
            }
            if (this.state.documentLocation5 !== "") {
                let locationDataModel = new Object();
                locationDataModel.DocumentLocations = new Object();
                locationDataModel.DocumentLocations.AirlineCode = this.state.airlineCode;
                locationDataModel.DocumentLocations.AirportCode = this.state.airportCode;
                locationDataModel.DocumentLocations.CustomerId = parseInt(this.state.customerId);
                locationDataModel.DocumentLocations.DocumentLocationName = this.state.documentLocation5.trim();
                locationDataModel.DocumentLocations.AccutalLoationName = this.state.documentLocation5.trim().length == 3 ? this.state.documentLocation5.trim() : this.state.Location5;
                locationDataModel.DocumentLocations.LocationAddress = this.state.Address5;
                locationDataModel.DocumentLocations.LocationZipcode = this.state.ZipCode5;
                locationDataModel.DocumentLocations.LocationCityName = this.state.City5;
                locationDataModel.DocumentLocations.LocationStateName = this.state.State5;

                locationDataModel.DocumentLocations.LocationLatitude = parseFloat(this.state.Latitude5);
                locationDataModel.DocumentLocations.LocationLongitude = parseFloat(this.state.Longitude5);
                locationDataModel.DocumentLocations.AccountId = parseInt(sessionStorage.getItem('session_accountId'));
                locationDataModel.DocumentLocations.Enteredby = parseInt(sessionStorage.getItem('session_userId'));
                locationDataModel.DocumentLocations.LocationId = parseInt(this.state.locationId);
                locations.push(locationDataModel.DocumentLocations);
            }
            if (this.state.documentLocation6 !== "") {
                let locationDataModel = new Object();
                locationDataModel.DocumentLocations = new Object();
                locationDataModel.DocumentLocations.AirlineCode = this.state.airlineCode;
                locationDataModel.DocumentLocations.AirportCode = this.state.airportCode;
                locationDataModel.DocumentLocations.CustomerId = parseInt(this.state.customerId);
                locationDataModel.DocumentLocations.DocumentLocationName = this.state.documentLocation6.trim();
                locationDataModel.DocumentLocations.AccutalLoationName = this.state.documentLocation6.trim().length == 3 ? this.state.documentLocation6.trim() : this.state.Location6;
                locationDataModel.DocumentLocations.LocationAddress = this.state.Address6;
                locationDataModel.DocumentLocations.LocationZipcode = this.state.ZipCode6;
                locationDataModel.DocumentLocations.LocationCityName = this.state.City6;
                locationDataModel.DocumentLocations.LocationStateName = this.state.State6;

                locationDataModel.DocumentLocations.LocationLatitude = parseFloat(this.state.Latitude6);
                locationDataModel.DocumentLocations.LocationLongitude = parseFloat(this.state.Longitude6);
                locationDataModel.DocumentLocations.AccountId = parseInt(sessionStorage.getItem('session_accountId'));
                locationDataModel.DocumentLocations.Enteredby = parseInt(sessionStorage.getItem('session_userId'));
                locationDataModel.DocumentLocations.LocationId = parseInt(this.state.locationId);
                locations.push(locationDataModel.DocumentLocations);
            }
            if (this.state.documentLocation7 !== "") {
                let locationDataModel = new Object();
                locationDataModel.DocumentLocations = new Object();
                locationDataModel.DocumentLocations.AirlineCode = this.state.airlineCode;
                locationDataModel.DocumentLocations.AirportCode = this.state.airportCode;
                locationDataModel.DocumentLocations.CustomerId = parseInt(this.state.customerId);
                locationDataModel.DocumentLocations.DocumentLocationName = this.state.documentLocation7.trim();
                locationDataModel.DocumentLocations.AccutalLoationName = this.state.documentLocation7.trim().length == 3 ? this.state.documentLocation7.trim() : this.state.Location7;
                locationDataModel.DocumentLocations.LocationAddress = this.state.Address7;
                locationDataModel.DocumentLocations.LocationZipcode = this.state.ZipCode7;
                locationDataModel.DocumentLocations.LocationCityName = this.state.City7;
                locationDataModel.DocumentLocations.LocationStateName = this.state.State7;

                locationDataModel.DocumentLocations.LocationLatitude = parseFloat(this.state.Latitude7);
                locationDataModel.DocumentLocations.LocationLongitude = parseFloat(this.state.Longitude7);
                locationDataModel.DocumentLocations.AccountId = parseInt(sessionStorage.getItem('session_accountId'));
                locationDataModel.DocumentLocations.Enteredby = parseInt(sessionStorage.getItem('session_userId'));
                locationDataModel.DocumentLocations.LocationId = parseInt(this.state.locationId);
                locations.push(locationDataModel.DocumentLocations);
            }
            if (this.state.documentLocation8 !== "") {
                let locationDataModel = new Object();
                locationDataModel.DocumentLocations = new Object();
                locationDataModel.DocumentLocations.AirlineCode = this.state.airlineCode;
                locationDataModel.DocumentLocations.AirportCode = this.state.airportCode;
                locationDataModel.DocumentLocations.CustomerId = parseInt(this.state.customerId);
                locationDataModel.DocumentLocations.DocumentLocationName = this.state.documentLocation8.trim();
                locationDataModel.DocumentLocations.AccutalLoationName = this.state.documentLocation8.trim().length == 3 ? this.state.documentLocation8.trim() : this.state.Location8;
                locationDataModel.DocumentLocations.LocationAddress = this.state.Address8;
                locationDataModel.DocumentLocations.LocationZipcode = this.state.ZipCode8;
                locationDataModel.DocumentLocations.LocationCityName = this.state.City8;
                locationDataModel.DocumentLocations.LocationStateName = this.state.State8;

                locationDataModel.DocumentLocations.LocationLatitude = parseFloat(this.state.Latitude8);
                locationDataModel.DocumentLocations.LocationLongitude = parseFloat(this.state.Longitude8);
                locationDataModel.DocumentLocations.AccountId = parseInt(sessionStorage.getItem('session_accountId'));
                locationDataModel.DocumentLocations.Enteredby = parseInt(sessionStorage.getItem('session_userId'));
                locationDataModel.DocumentLocations.LocationId = parseInt(this.state.locationId);
                locations.push(locationDataModel.DocumentLocations);
            }
            
            if (locations.length > 0) {

                for (let k = 0; k < locations.length; k++) {

                    var actulaLocationData = locations[k];

                    var request = new Object();
                    request.DocumentLocation = actulaLocationData.DocumentLocationName;
                    request.ActualLocation = actulaLocationData.AccutalLoationName;
                    request.Address = actulaLocationData.LocationAddress;
                    request.Zipcode = actulaLocationData.LocationZipcode;
                    request.CityName = actulaLocationData.LocationCityName;
                    request.Latitude = actulaLocationData.LocationLatitude;
                    request.Longitude = actulaLocationData.LocationLongitude;
                    request.StateName = actulaLocationData.LocationStateName;
                    request.ScheduleTrip = this.state.finalTripList == null ? this.state.nextStepTripList : this.state.finalTripList;

                    const requestParams = getRequestParams('POST', request);
                    const response = await fetch('uploadSchedule-api/replaceLoaction', requestParams);
                    const data = await response.json();
                    if (data !== null) {
                        this.setState({ finalTripList: data })
                    }
                }

            }
            
            var request = new Object();
            request.DocumentLocations = new Object();
            request.DocumentLocations = locations;

            const requestParams = getRequestParams('POST', request);
            const response = await fetch('uploadSchedule-api/addEditLocationData', requestParams);
            const data = await response.json();
            if (data !== null) {

                this.props.history.push({
                    pathname: '/finallistuploadschedule',
                    state: this.state.finalTripList
                })
            }
        }
    }

    render() {

        return (<div className="content-wrapper">
            <div className="content-header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <h1 className="m-0 text-dark">Upload Schedule</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="card card-grid" >
                    <div className="card-body">
                        <form method="post" onSubmit={this.handleNextDataSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                            <div className="row">
                                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-2" style={{ display: this.state.documentLocation1 !== "" ? "block" : "none" }}>
                                    <div className="form-group">
                                        <label className="control-label">{this.state.documentLocation1}</label>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-4" style={{ display: this.state.documentLocation1 !== "" ? "block" : "none" }}>
                                    <div className="form-group">
                                        <input type="text" className="form-control form-control-sm" name="location1" id="pickupGoogle1" placeholder="'Type' slowly to search location" maxLength="150" title="enter Location" />
                                        {this.state.errors["location1"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["location1"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-2" style={{ display: this.state.documentLocation2 !== "" ? "block" : "none" }}>
                                    <div className="form-group">
                                        <label className="control-label">{this.state.documentLocation2}</label>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-4" style={{ display: this.state.documentLocation2 !== "" ? "block" : "none" }}>
                                    <div className="form-group">
                                        <input type="text" className="form-control form-control-sm" name="location2" id="pickupGoogle2" placeholder="'Type' slowly to search location" maxLength="150" title="enter Location" />
                                        {this.state.errors["location2"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["location2"]}</span>}
                                    </div>
                                </div>

                                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-2" style={{ display: this.state.documentLocation3 !== "" ? "block" : "none" }}>
                                    <div className="form-group">
                                        <label className="control-label">{this.state.documentLocation3}</label>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-4" style={{ display: this.state.documentLocation3 !== "" ? "block" : "none" }}>
                                    <div className="form-group">
                                        <input type="text" className="form-control form-control-sm" name="location3" id="pickupGoogle3" placeholder="'Type' slowly to search location" maxLength="150" title="enter Location" />
                                        {this.state.errors["location3"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["location3"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-2" style={{ display: this.state.documentLocation4 !== "" ? "block" : "none" }}>
                                    <div className="form-group">
                                        <label className="control-label">{this.state.documentLocation4}</label>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-4" style={{ display: this.state.documentLocation4 !== "" ? "block" : "none" }}>
                                    <div className="form-group">
                                        <input type="text" className="form-control form-control-sm" name="location4" id="pickupGoogle4" placeholder="'Type' slowly to search location" maxLength="150" title="enter Location" />
                                        {this.state.errors["location4"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["location4"]}</span>}
                                    </div>
                                </div>

                                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-2" style={{ display: this.state.documentLocation5 !== "" ? "block" : "none" }}>
                                    <div className="form-group">
                                        <label className="control-label">{this.state.documentLocation5}</label>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-4" style={{ display: this.state.documentLocation5 !== "" ? "block" : "none" }}>
                                    <div className="form-group">
                                        <input type="text" className="form-control form-control-sm" name="location5" id="pickupGoogle5" placeholder="'Type' slowly to search location" maxLength="150" title="enter Location" />
                                        {this.state.errors["location5"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["location5"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-2" style={{ display: this.state.documentLocation6 !== "" ? "block" : "none" }}>
                                    <div className="form-group">
                                        <label className="control-label">{this.state.documentLocation6}</label>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-4" style={{ display: this.state.documentLocation6 !== "" ? "block" : "none" }}>
                                    <div className="form-group">
                                        <input type="text" className="form-control form-control-sm" name="location6" id="pickupGoogle6" placeholder="'Type' slowly to search location" maxLength="150" title="enter Location" />
                                        {this.state.errors["location6"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["location6"]}</span>}
                                    </div>
                                </div>

                                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-2" style={{ display: this.state.documentLocation7 !== "" ? "block" : "none" }}>
                                    <div className="form-group">
                                        <label className="control-label">{this.state.documentLocation7}</label>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-4" style={{ display: this.state.documentLocation7 !== "" ? "block" : "none" }}>
                                    <div className="form-group">
                                        <input type="text" className="form-control form-control-sm" name="location7" id="pickupGoogle7" placeholder="'Type' slowly to search location" maxLength="150" title="enter Location" />
                                        {this.state.errors["location7"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["location7"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-2" style={{ display: this.state.documentLocation8 !== "" ? "block" : "none" }}>
                                    <div className="form-group">
                                        <label className="control-label">{this.state.documentLocation8}</label>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-4" style={{ display: this.state.documentLocation8 !== "" ? "block" : "none" }}>
                                    <div className="form-group">
                                        <input type="text" className="form-control form-control-sm" name="location8" id="pickupGoogle8" placeholder="'Type' slowly to search location" maxLength="150" title="enter Location" />
                                        {this.state.errors["location8"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["location8"]}</span>}
                                    </div>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12 text-right ">
                                    <div className="form-group">
                                        <button type="button" className="btn btn-success btn-sm" onClick={this.handleNextDataSubmit} style={{ display: this.state.nextButtonDisplay === true ? "inline" : "none" }} >
                                            {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSubmited && <span title="Next"><i className="fas fa-arrow-right"></i> Next</span>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="card card-grid">
                    <div className="card-body">
                        <DataGrid
                            dataSource={this.state.nextStepTripList}
                            keyExpr="reservationId"
                            selection={{ mode: 'single' }}
                            columnsAutoWidth={false}
                            showColumnLines={false}
                            showRowLines={true}
                            showBorders={true}
                            rowAlternationEnabled={true}
                            showBorders={true}>
                            <Column dataField="airlineCode" width={100} caption="Airline Code" allowFiltering={true} allowSorting={false} />
                            <Column dataField="flightNo" caption="Flight Number" width={100} allowFiltering={true} allowSorting={true} />
                            <Column dataField="flightTime" caption="Flight Time" width={100} allowFiltering={true} allowSorting={true} />
                            <Column dataField="isAirportPickup" caption="Status" width={150} allowFiltering={true} allowSorting={true} />
                            <Column dataField="pickupDate" caption="Pickup Date" width={100} allowFiltering={true} allowSorting={true} />
                            <Column dataField="pickupTime" caption="Pickup Time" width={100} allowFiltering={true} allowSorting={true} />
                            <Column dataField="pickupLocation" caption="Pickup Location" width={200} allowFiltering={true} allowSorting={true} />
                            <Column dataField="dropoffLocation" caption="Dropoff Location" width={200} allowFiltering={true} allowSorting={false} />
                            <Column dataField="noOfPax" caption="Pax" width={80} allowFiltering={true} allowSorting={false} />
                            <Column dataField="firstName" caption="First Name" width={120} allowFiltering={true} allowSorting={false} />
                            <Column dataField="lastName" caption="Last Name" width={120} allowFiltering={true} allowSorting={false} />
                            <Column dataField="flightTerminal" caption="Flight Terminal" width={150} allowFiltering={true} allowSorting={false} />

                            <SortByGroupSummaryInfo summaryItem="count" />
                            <GroupPanel visible={true} />
                            <HeaderFilter visible={true} />
                            <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                            <Grouping autoExpandAll={true} />
                            <Paging defaultPageSize={20} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                            <Export enabled={true} allowExportSelectedData={false} />
                            <ColumnChooser enabled={true} />
                            <ColumnFixing enabled={true} />

                        </DataGrid>
                    </div>
                </div>
            </div>
        </div>)
    }
}