import DateBox from 'devextreme-react/date-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import { SelectBox } from 'devextreme-react/select-box';
import React from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { formatDate, getRequestParams } from '../../helpers/globalfunctions';
import { SendEmailComponent } from '../Shared/SendEmailComponent';
const position = { of: '#historydiv' };

export class InvoiceSummaryReportPickupwiseComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
            isSubmited: false,
            isEmailSubmitted: false,
            loadPanelVisible: false,

            dropdownCustomer: [],
            customerCode: '',

            pONumber: '',
            sCNumber: '',

            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),

            errors: {
                customerCode: '',
            }
        }

        this.getCustomer = this.getCustomer.bind(this);
        this.handleCustomerChange = this.handleCustomerChange.bind(this);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFromDateChanged = this.handleFromDateChanged.bind(this);
        this.handleToDateChanged = this.handleToDateChanged.bind(this);


        this.downloadAccountPaymentTypeSummaryAsPdf = this.downloadAccountPaymentTypeSummaryAsPdf.bind(this);

        this.handleSearchAccountPaySummaryReportSubmit = this.handleSearchAccountPaySummaryReportSubmit.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
    }


    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getCustomer();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {

        this.setState({ isOpenModal: false });
        await this.handleClearSearch();
    }

    async getCustomer() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('account-api/customer-dropdown/', requestParams);
            const data = await response.json();
            this.setState({
                dropdownCustomer: data.customers,
            });

        } catch (e) {
            console.log(e);
        }
    }

    handleCustomerChange(selectedOption) {
        this.setState({ customerCode: (selectedOption.selectedItem != null ? selectedOption.selectedItem .code : ''), })
        this.state.errors["customerCode"] = "";
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleFromDateChanged(event) {
        let fromDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ startDate: fromDateValue })
    }
    handleToDateChanged(event) {
        let toDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ endDate: toDateValue })
    }


    handleSearchAccountPaySummaryReportSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            this.downloadAccountPaymentTypeSummaryAsPdf();
        }
    }
    handleClearSearch() {
        this.setState({
            isSubmited: false,
            isEmailSubmitted: false,
            loadPanelVisible: false,

            dropdownCustomer: [],
            customerCode: '',

            pONumber: '',
            sCNumber: '',

            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),

            errors: {
                customerCode: '',
            }
        });
    }

    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.customerCode = "";

        if (this.state.customerCode == "") {
            formIsValid = false;
            errors.customerCode = "Please select customer.";
        }
       
        this.setState({ errors: errors });
        return formIsValid;
    }
    async downloadAccountPaymentTypeSummaryAsPdf() {
        try {
            this.setState({
                isSubmited: true,
            });


            let customerDetails = this.state.dropdownCustomer.find(opt => opt.code == this.state.customerCode);

            var request = new Object();
            request.LocationId = Number(sessionStorage.getItem("session_locationId"));
            request.EmailSubject = "Crew transportation from  " + formatDate(this.state.startDate, "MM/dd/yyyy") + " To " + formatDate(this.state.endDate, "MM/dd/yyyy");
            request.PONumber = this.state.pONumber;
            request.SCNumber = this.state.sCNumber;
            request.EmailTo = customerDetails.email;
            request.CustomerAddress = customerDetails.customerAddress;
            request.AccountCode = this.state.customerCode;
            request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy") + " 00:00:00";
            request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy") + " 23:59:59";
            request.CustomerName = customerDetails.fullName;
            request.MethodName = "GetInvoicedRidesSummaryCount";
            request.fileFormat = 'pdf';
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            this.setState({
                isSubmited: false,
            });

            let a = document.createElement('a');
            a.href = dataResponse.reportBase64String;
            a.download = 'InvoiceSummaryReport.pdf';

            a.click();
        } catch (e) {
            this.setState({
                isSubmited: false,
            });
            console.log(e)
        }
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isEmailSubmitted: true });
                let customerDetails = this.state.dropdownCustomer.find(opt => opt.code == this.state.customerCode);

                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.EmailSubject = "Crew transportation from  " + formatDate(this.state.startDate, "MM/dd/yyyy") + " To " + formatDate(this.state.endDate, "MM/dd/yyyy");
                request.PONumber = this.state.pONumber;
                request.SCNumber = this.state.sCNumber;
                request.EmailTo = customerDetails.email;
                request.CustomerAddress = customerDetails.customerAddress;
                request.AccountCode = this.state.customerCode;
                request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy") + " 00:00:00";
                request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy") + " 23:59:59";
                request.CustomerName = customerDetails.fullName;
                request.MethodName = "GetInvoicedRidesSummaryCount";
                request.fileFormat = 'pdf';
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Invoice Summary Report Pick-up Wise';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = 'InvoiceSummaryReport.pdf'
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '50%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleSearchAccountPaySummaryReportSubmit}
                        style={(this.state.isSubmited || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Invoice Summary Report Pick-up Wise</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label className="label">Customer</label>
                                        <SelectBox
                                            dataSource={this.state.dropdownCustomer.length > 0 ? this.state.dropdownCustomer : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="customerCode"
                                            defaultValue={0}
                                            value={this.state.customerCode}
                                            displayExpr='fullName'
                                            valueExpr='code'
                                            onSelectionChanged={this.handleCustomerChange}
                                            showClearButton={true}
                                            disabled={this.state.chkCustomerSelection === "1"}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerCode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerCode"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label className="label">PO</label>
                                        <div className="form-group">
                                            <input type="text" className="form-control form-control-sm" name="pONumber" maxLength="30" title="Enter po number" value={this.state.pONumber} onChange={this.handleInputChange} placeholder="Enter po number" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label className="label">SC</label>
                                        <div className="form-group">
                                            <input type="text" className="form-control form-control-sm" name="sCNumber" maxLength="30" title="Enter sc number" value={this.state.sCNumber} onChange={this.handleInputChange} placeholder="Enter sc number" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label className="label">From Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.startDate}
                                            value={this.state.startDate}
                                            onValueChanged={this.handleFromDateChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>To Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.endDate}
                                            value={this.state.endDate}
                                            onValueChanged={this.handleToDateChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="button" className="btn btn-success btn-sm" title="Download" onClick={this.handleSearchAccountPaySummaryReportSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                                </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        )
    }
}