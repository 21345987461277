import { LoadPanel } from 'devextreme-react/load-panel';
import { SelectBox } from 'devextreme-react/select-box';
import React from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { getRequestParams } from '../../helpers/globalfunctions';
import { SendEmailComponent } from '../Shared/SendEmailComponent';
const position = { of: '#historydiv' };

export class RatesComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,

            chkCustomerSelection: "1",
            dropdownCustomer: [],
            customerCode: '',

            chkZoneSelection: "1",
            dropdownZone: [],
            fromZoneId: 0,
            toZoneId: 0,

            chkReportTypeSelection: "1",
            chkOrderBySelection: "1",

            errors: {
                customerCode: '',
                fromZoneId: '',
                toZoneId: '',
            }
        }

        this.getCustomer = this.getCustomer.bind(this);
        this.handleCustomerChange = this.handleCustomerChange.bind(this);
        this.handleCustomerSelectionChange = this.handleCustomerSelectionChange.bind(this);

        this.getZone = this.getZone.bind(this);
        this.handleFromZoneChange = this.handleFromZoneChange.bind(this);
        this.handleToZoneChange = this.handleToZoneChange.bind(this);
        this.handleZoneSelectionChange = this.handleZoneSelectionChange.bind(this);

        this.handleOrderBySelectionChange = this.handleOrderBySelectionChange.bind(this);

        this.downloadAccountPaymentTypeSummaryAsPdf = this.downloadAccountPaymentTypeSummaryAsPdf.bind(this);

        this.handleSearchAccountPaySummaryReportSubmit = this.handleSearchAccountPaySummaryReportSubmit.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
    }


    showModal = async (_reportTypeId) => {
        try {
            this.setState({ isOpenModal: true, chkReportTypeSelection: _reportTypeId });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getCustomer();
            await this.getZone();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {

        this.setState({ isOpenModal: false });
        await this.handleClearSearch();
    }

    async getCustomer() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('account-api/customer-dropdown/', requestParams);
            const data = await response.json();
            if (this.state.chkReportTypeSelection === "1") {
                this.setState({
                    dropdownCustomer: data.customers.filter(x => x.rateTypeId === 1 || x.rateTypeId === 5),
                });
            } else {
                this.setState({
                    dropdownCustomer: data.customers.filter(x => x.rateTypeId === 2),
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    handleCustomerChange(selectedOption) {
        try {
            let errors = this.state.errors;
            errors.customerCode = '';
            let customerCode = '';
            if (selectedOption !== null && selectedOption.selectedItem !== null) {
                customerCode = selectedOption.selectedItem.code;
            }
            this.setState({
                customerCode: customerCode,
                errors: errors
            })
        } catch (ex) {
            console.log(ex);
        }
    }

    handleCustomerSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ chkCustomerSelection: value, customerCode: '' });
        this.state.errors["customerCode"] = "";
    }

    async getZone() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('serviceAreaZone-api/zone-dropdown/' + 0 + "/" + 0, requestParams);
            const data = await response.json();
            this.setState({
                dropdownZone: data.locationServiceAreaZoneList
            });

        } catch (e) {
            console.log(e);
        }
    }

    handleFromZoneChange(selectedOption) {
        this.setState({ fromZoneId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0), })
        this.state.errors["fromZoneId"] = "";
    }

    handleToZoneChange(selectedOption) {
        this.setState({ toZoneId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0), })
        this.state.errors["toZoneId"] = "";
    }

    handleZoneSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkZoneSelection: value, fromZoneId: 0, toZoneId: 0 });
        this.state.errors["fromZoneId"] = "";
        this.state.errors["toZoneId"] = "";
    }

    handleOrderBySelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkOrderBySelection: value });
    }

    handleSearchAccountPaySummaryReportSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            this.downloadAccountPaymentTypeSummaryAsPdf();
        }
    }
    handleClearSearch() {
        this.setState({
            isSubmited: false,
            isEmailSubmitted: false,
            loadPanelVisible: false,

            chkCustomerSelection: "1",
            customerCode: '',

            chkZoneSelection: "1",
            fromZoneId: 0,
            toZoneId: 0,

            chkOrderBySelection: "1",

            errors: {
                customerCode: '',
                fromZoneId: '',
                toZoneId: '',
            }
        });
    }

    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.customerCode = "";
        errors.fromZoneId = "";
        errors.toZoneId = "";

        if (this.state.chkCustomerSelection === "2" && this.state.customerCode === "") {
            formIsValid = false;
            errors.customerCode = "Please select customer.";
        }

        if (this.state.chkZoneSelection === "2" && this.state.fromZoneId === 0) {
            formIsValid = false;
            errors.fromZoneId = "Please select from zone.";
        }

        if (this.state.chkZoneSelection === "2" && this.state.toZoneId === 0) {
            formIsValid = false;
            errors.toZoneId = "Please select to zone.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    async downloadAccountPaymentTypeSummaryAsPdf() {
        try {
            this.setState({
                isSubmited: true,
            });
            var RptHead = "";

            RptHead = "Order By " + " " + (this.state.chkOrderBySelection === "1" ? " Customer " : " Zone ") + " for ";

            var request = new Object();
            request.LocationId = Number(sessionStorage.getItem("session_locationId"));

            if (this.state.chkCustomerSelection == "2") {
                let puZone = this.state.dropdownCustomer.find(opt => opt.code === this.state.customerCode);
                RptHead = RptHead + ", for Customer: " + puZone.fullName;
                request.AccountCode = this.state.customerCode;
            } else {
                request.AccountCode = "";
                RptHead = RptHead + ", for all Customers";
            }

            if (this.state.chkZoneSelection == "2") {
                let puZone = this.state.dropdownZone.find(opt => opt.id === this.state.fromZoneId);
                let doZone = this.state.dropdownZone.find(opt => opt.id === this.state.toZoneId);
                RptHead = RptHead + ", From Zone: " + puZone.name + " To Zone: " + doZone.name;
                request.PickupZoneIds = this.state.fromZoneId;
                request.DropoffZoneIds = this.state.toZoneId;
            } else {
                request.PickupZoneIds = "0";
                request.DropoffZoneIds = "0";
                RptHead = RptHead + ", for all Zone";
            }

            request.ReportType = parseInt(this.state.chkReportTypeSelection);
            request.Header = RptHead;

            if (this.state.chkOrderBySelection == "1") {
                request.SortBy = "Account";
            } else if (this.state.chkOrderBySelection == "2") {
                request.SortBy = "Zone";
            }

            request.fileFormat = 'pdf';
            request.MethodName = "GetRates";
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            this.setState({
                isSubmited: false,
            });

            let a = document.createElement('a');
            a.href = dataResponse.reportBase64String;

            if (this.state.chkReportTypeSelection == "1") {
                a.download = 'ZonalReport.pdf';
            } else {
                a.download = 'ContractReport.pdf';
            }

            a.click();
        } catch (e) {
            this.setState({
                isSubmited: false,
            });
            console.log(e)
        }
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isEmailSubmitted: true });
                var RptHead = "";

                RptHead = "Order By " + " " + (this.state.chkOrderBySelection == "1" ? " Customer " : " Zone ") + " for ";

                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));

                if (this.state.chkCustomerSelection == "2") {
                    let puZone = this.state.dropdownCustomer.find(opt => opt.code == this.state.customerCode);
                    RptHead = RptHead + ", for Customer: " + puZone.fullName;
                    request.AccountCode = this.state.customerCode;
                } else {
                    request.AccountCode = "";
                    RptHead = RptHead + ", for all Customers";
                }

                if (this.state.chkZoneSelection == "2") {
                    let puZone = this.state.dropdownZone.find(opt => opt.id == this.state.fromZoneId);
                    let doZone = this.state.dropdownZone.find(opt => opt.id == this.state.toZoneId);
                    RptHead = RptHead + ", From Zone: " + puZone.name + " To Zone: " + doZone.name;
                    request.PickupZoneIds = this.state.fromZoneId;
                    request.DropoffZoneIds = this.state.toZoneId;
                } else {
                    request.PickupZoneIds = "0";
                    request.DropoffZoneIds = "0";
                    RptHead = RptHead + ", for all Zone";
                }

                request.ReportType = parseInt(this.state.chkReportTypeSelection);
                request.Header = RptHead;

                if (this.state.chkOrderBySelection == "1") {
                    request.SortBy = "Account";
                } else if (this.state.chkOrderBySelection == "2") {
                    request.SortBy = "Zone";
                }

                request.fileFormat = 'pdf';
                request.MethodName = "GetRates";
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = (Number(this.state.chkReportTypeSelection) === 1) ? 'Zonal Rate Report' : 'Contract Rate Report';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = (Number(this.state.chkReportTypeSelection) === 1) ? 'ZonalReport.pdf' : 'ContractReport.pdf';
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        var title = this.state.chkReportTypeSelection === "1" ? "Zonal Rate Report" : "Contract Rate Report";
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '50%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleSearchAccountPaySummaryReportSubmit}
                        style={(this.state.isSubmited || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{title}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdAllCustomer" value="1" checked={this.state.chkCustomerSelection === "1"} onChange={this.handleCustomerSelectionChange} />
                                            <label className="form-check-label">All Customer</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdSingleCustomer" value="2" checked={this.state.chkCustomerSelection === "2"} onChange={this.handleCustomerSelectionChange} />
                                            <label className="form-check-label">Single Customer</label>
                                        </span>
                                        <SelectBox
                                            dataSource={this.state.dropdownCustomer}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="customerCode"
                                            defaultValue={0}
                                            value={this.state.customerCode}
                                            displayExpr='fullName'
                                            valueExpr='code'
                                            onSelectionChanged={this.handleCustomerChange}
                                            showClearButton={true}
                                            disabled={this.state.chkCustomerSelection === "1"}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerCode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerCode"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdAllZone" value="1" checked={this.state.chkZoneSelection === "1"} onChange={this.handleZoneSelectionChange} />
                                            <label className="form-check-label">All Zone</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdSingleZone" value="2" checked={this.state.chkZoneSelection === "2"} onChange={this.handleZoneSelectionChange} />
                                            <label className="form-check-label">Range</label>
                                        </span>
                                        <SelectBox
                                            dataSource={this.state.dropdownZone.length > 0 ? this.state.dropdownZone : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select zone"
                                            name="fromZoneId"
                                            defaultValue={0}
                                            value={this.state.fromZoneId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleFromZoneChange}
                                            showClearButton={true}
                                            disabled={this.state.chkZoneSelection === "1"}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["fromZoneId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["fromZoneId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>&nbsp;</label>
                                        <SelectBox
                                            dataSource={this.state.dropdownZone.length > 0 ? this.state.dropdownZone : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select zone"
                                            name="toZoneId"
                                            defaultValue={0}
                                            value={this.state.toZoneId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleToZoneChange}
                                            showClearButton={true}
                                            disabled={this.state.chkZoneSelection === "1"}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["toZoneId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["toZoneId"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Order by</label><br />
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdByCustomerCode" value="1" checked={this.state.chkOrderBySelection === "1"} onChange={this.handleOrderBySelectionChange} />
                                            <label className="form-check-label">By Customer Code</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdByInvoiceNo" value="2" checked={this.state.chkOrderBySelection === "2"} onChange={this.handleOrderBySelectionChange} />
                                            <label className="form-check-label">By Zone</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="button" className="btn btn-success btn-sm" title="Download" onClick={this.handleSearchAccountPaySummaryReportSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                            </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        )
    }
}