import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import NumberFormat from 'react-number-format';

export class WireInstructionComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rowId: 0,
            isSubmited: false,
            addEditWireInstructorDataModalIsOpen: false,
            wireInstructionList: null,
            wiringName: null,
            vendorAddress1: null,
            vendorName: null,
            vendorAddress2: null,
            accountName: null,
            isDefault: false,
            beneficiary: null,
            accountSubName: null,
            routingforWire: null,
            routingforACHEFT: null,
            account: null,
            bankName: null,
            bankAddress: null,
            reference: null,
            isApplyaboveinformationforInternationalWiringInstructions: false,
            accountNameInt: null,
            beneficiaryInt: null,
            accountSubNameInt: null,
            routingforWireInt: null,
            routingforACHEFTInt: null,
            accountInt: null,
            swiftCodeInt: null,
            bankNameInt: null,
            bankAddressInt: null,
            referenceInt: null,
            checkPayableName: null,
            checkPayableAddress: null,
            checkMemo: null,
            errors: {
                wiringName: '',
                vendorAddress1: '',
                vendorName: '',
                accountName: '',
                beneficiary: '',
                routingforWire: '',
                routingforACHEFT: '',
                account: '',
                bankName: '',
                bankAddress: '',
                reference: '',
                accountNameInt: '',
                beneficiaryInt: '',
                routingforWireInt: '',
                routingforACHEFTInt: '',
                accountInt: '',
                swiftCodeInt: '',
                bankNameInt: '',
                bankAddressInt: '',
                referenceInt: '',
                checkPayableName: '',
                checkPayableAddress: '',
            }
        }
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.handleAddEditWireInstructionSubmit = this.handleAddEditWireInstructionSubmit.bind(this);
    }
    componentDidMount = () => {
        this.getWireInstructionList();
    }
    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getWireInstructionList()
            }
        });
    }
    async getWireInstructionList() {
        var request = new Object();
        request.isListView = true;
        request.accountCustomerSettingWireInstructionId = 0;

        const requestParams = getRequestParams('POST', request);
        const response = await fetch('customer-api/wiringInstruction/0', requestParams);
        const data = await response.json();
        this.setState({
            wireInstructionList: data.wiringInstructions
        });
    }
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let errors = this.state.errors;
        switch (name) {
            case 'wiringName':
                errors.wiringName = "";
                if (value.trim() === "") {
                    this.setState({ wiringName: '' });
                    errors.wiringName = "Please enter wiring name.";
                } else {
                    this.setState({ wiringName: value });
                }
                break;
            case 'vendorAddress1':
                errors.vendorAddress1 = "";
                if (value.trim() === "") {
                    this.setState({ vendorAddress1: '' });
                    errors.vendorAddress1 = "Please enter vendor address 1.";
                } else {
                    this.setState({ vendorAddress1: value });
                }
                break;
            case 'vendorName':
                errors.vendorName = "";
                if (value.trim() === "") {
                    this.setState({ vendorName: '' });
                    errors.vendorName = "Please enter vendor name.";
                } else {
                    this.setState({ vendorName: value });
                }
                break;
            case 'accountName':
                errors.accountName = "";
                if (value.trim() === "") {
                    this.setState({ accountName: '' });
                    errors.accountName = "Please enter account name.";
                } else {
                    this.setState({ accountName: value });
                }
                break;
            case 'beneficiary':
                errors.beneficiary = "";
                if (value.trim() === "") {
                    this.setState({ beneficiary: '' });
                    errors.beneficiary = "Please enter beneficiary.";
                } else {
                    this.setState({ beneficiary: value });
                }
                break;
            case 'routingforWire':
                errors.routingforWire = "";
                if (value.trim() === "") {
                    this.setState({ routingforWire: '' });
                    errors.routingforWire = "Please enter routing for wire number.";
                } else {
                    this.setState({ routingforWire: value });
                }
                break;
            case 'routingforACHEFT':
                errors.routingforACHEFT = "";
                if (value.trim() === "") {
                    this.setState({ routingforACHEFT: '' });
                    errors.routingforACHEFT = "Please enter routing for ACH/EFT.";
                } else {
                    this.setState({ routingforACHEFT: value });
                }
                break;
            case 'account':
                errors.account = "";
                if (value.trim() === "") {
                    this.setState({ account: '' });
                    errors.account = "Please enter account.";
                } else {
                    this.setState({ account: value });
                }
                break;
            case 'bankName':
                errors.bankName = "";
                if (value.trim() === "") {
                    this.setState({ bankName: '' });
                    errors.bankName = "Please enter bank name.";
                } else {
                    this.setState({ bankName: value });
                }
                break;
            case 'bankAddress':
                errors.bankAddress = "";
                if (value.trim() === "") {
                    this.setState({ bankAddress: '' });
                    errors.bankAddress = "Please enter bank address.";
                } else {
                    this.setState({ bankAddress: value });
                }
                break;
            case 'reference':
                errors.reference = "";
                if (value.trim() === "") {
                    this.setState({ reference: '' });
                    errors.reference = "Please enter reference.";
                } else {
                    this.setState({ reference: value });
                }
                break;
            case 'accountNameInt':
                errors.accountNameInt = "";
                if (value.trim() === "") {
                    this.setState({ accountNameInt: '' });
                    errors.accountNameInt = "Please enter account name.";
                } else {
                    this.setState({ accountNameInt: value });
                }
                break;
            case 'beneficiaryInt':
                errors.beneficiaryInt = "";
                if (value.trim() === "") {
                    this.setState({ beneficiaryInt: '' });
                    errors.beneficiaryInt = "Please enter beneficiary.";
                } else {
                    this.setState({ beneficiaryInt: value });
                }
                break;
            case 'routingforWireInt':
                errors.routingforWireInt = "";
                if (value.trim() === "") {
                    this.setState({ routingforWireInt: '' });
                    errors.routingforWireInt = "Please enter routing for wire number.";
                } else {
                    this.setState({ routingforWireInt: value });
                }
                break;
            case 'routingforACHEFTInt':
                errors.routingforACHEFTInt = "";
                if (value.trim() === "") {
                    this.setState({ routingforACHEFTInt: '' });
                    errors.routingforACHEFTInt = "Please enter routing for ACH/EFT.";
                } else {
                    this.setState({ routingforACHEFTInt: value });
                }
                break;
            case 'accountInt':
                errors.accountInt = "";
                if (value.trim() === "") {
                    this.setState({ accountInt: '' });
                    errors.accountInt = "Please enter account.";
                } else {
                    this.setState({ accountInt: value });
                }
                break;
            case 'swiftCodeInt':
                errors.swiftCodeInt = "";
                if (value.trim() === "") {
                    this.setState({ swiftCodeInt: '' });
                    errors.swiftCodeInt = "Please enter swift code.";
                } else {
                    this.setState({ swiftCodeInt: value });
                }
                break;
            case 'bankNameInt':
                errors.bankNameInt = "";
                if (value.trim() === "") {
                    this.setState({ bankNameInt: '' });
                    errors.bankNameInt = "Please enter bank name.";
                } else {
                    this.setState({ bankNameInt: value });
                }
                break;
            case 'bankAddressInt':
                errors.bankAddressInt = "";
                if (value.trim() === "") {
                    this.setState({ bankAddressInt: '' });
                    errors.bankAddressInt = "Please enter bank address.";
                } else {
                    this.setState({ bankAddressInt: value });
                }
                break;
            case 'referenceInt':
                errors.referenceInt = "";
                if (value.trim() === "") {
                    this.setState({ referenceInt: '' });
                    errors.referenceInt = "Please enter reference.";
                } else {
                    this.setState({ referenceInt: value });
                }
                break;
            case 'checkPayableName':
                errors.checkPayableName = "";
                if (value.trim() === "") {
                    this.setState({ checkPayableName: '' });
                    errors.checkPayableName = "Please enter check payable name.";
                } else {
                    this.setState({ checkPayableName: value });
                }
                break;
            case 'checkPayableAddress':
                errors.checkPayableAddress = "";
                if (value.trim() === "") {
                    this.setState({ checkPayableAddress: '' });
                    errors.checkPayableAddress = "Please enter check payable address.";
                } else {
                    this.setState({ checkPayableAddress: value });
                }
                break;
            case 'isApplyaboveinformationforInternationalWiringInstructions':
                if (value === true) {
                    this.setState({
                        isApplyaboveinformationforInternationalWiringInstructions: value,
                        accountNameInt: this.state.accountName,
                        beneficiaryInt: this.state.beneficiary,
                        accountSubNameInt: this.state.accountSubName,
                        routingforWireInt: this.state.routingforWire,
                        routingforACHEFTInt: this.state.routingforACHEFT,
                        accountInt: this.state.account,
                        bankNameInt: this.state.bankName,
                        bankAddressInt: this.state.bankAddress,
                        referenceInt: this.state.reference,
                    });
                } else {
                    this.setState({
                        isApplyaboveinformationforInternationalWiringInstructions: value,
                        accountNameInt: null,
                        beneficiaryInt: null,
                        accountSubNameInt: null,
                        routingforWireInt: null,
                        routingforACHEFTInt: null,
                        accountInt: null,
                        bankNameInt: null,
                        bankAddressInt: null,
                        referenceInt: null,
                    });
                }
                break;
            default:
                this.setState({
                    [name]: value
                });
                break;
        }
    }
    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.wiringName = '';
        errors.vendorAddress1 = '';
        errors.vendorName = '';
        errors.accountName = '';
        errors.beneficiary = '';
        errors.routingforWire = '';
        errors.routingforACHEFT = '';
        errors.account = '';
        errors.bankName = '';
        errors.bankAddress = '';
        errors.reference = '';
        errors.accountNameInt = '';
        errors.beneficiaryInt = '';
        errors.routingforWireInt = '';
        errors.routingforACHEFTInt = '';
        errors.accountInt = '';
        errors.swiftCodeInt = '';
        errors.bankNameInt = '';
        errors.bankAddressInt = '';
        errors.referenceInt = '';
        errors.checkPayableName = '';
        errors.checkPayableAddress = '';

        if (this.state.wiringName === null || this.state.wiringName === '') {
            formIsValid = false;
            errors.wiringName = "Please enter wiring name.";
        }
        if (this.state.vendorAddress1 === null || this.state.vendorAddress1 === '') {
            formIsValid = false;
            errors.vendorAddress1 = "Please enter vendor address 1.";
        }
        if (this.state.vendorName === null || this.state.vendorName === '') {
            formIsValid = false;
            errors.vendorName = "Please enter vendor name.";
        }
        if (this.state.accountName === null || this.state.accountName === '') {
            formIsValid = false;
            errors.accountName = "Please enter account name.";
        }
        if (this.state.beneficiary === null || this.state.beneficiary === '') {
            formIsValid = false;
            errors.beneficiary = "Please enter beneficiary.";
        }
        if (this.state.routingforWire === null || this.state.routingforWire === '') {
            formIsValid = false;
            errors.routingforWire = "Please enter routing for wire number.";
        }
        if (this.state.routingforACHEFT === null || this.state.routingforACHEFT === '') {
            formIsValid = false;
            errors.routingforACHEFT = "Please enter routing for ACH/EFT number.";
        }
        if (this.state.account === null || this.state.account === '') {
            formIsValid = false;
            errors.account = "Please enter account.";
        }
        if (this.state.bankName === null || this.state.bankName === '') {
            formIsValid = false;
            errors.bankName = "Please enter bank name.";
        }
        if (this.state.bankAddress === null || this.state.bankAddress === '') {
            formIsValid = false;
            errors.bankAddress = "Please enter bank address.";
        }
        if (this.state.reference === null || this.state.reference === '') {
            formIsValid = false;
            errors.reference = "Please enter reference.";
        }
        if (this.state.accountNameInt === null || this.state.accountNameInt === '') {
            formIsValid = false;
            errors.accountNameInt = "Please enter account name.";
        }
        if (this.state.beneficiaryInt === null || this.state.beneficiaryInt === '') {
            formIsValid = false;
            errors.beneficiaryInt = "Please enter beneficiary.";
        }
        if (this.state.routingforWireInt === null || this.state.routingforWireInt === '') {
            formIsValid = false;
            errors.routingforWireInt = "Please enter routing for wire number.";
        }
        if (this.state.routingforACHEFTInt === null || this.state.routingforACHEFTInt === '') {
            formIsValid = false;
            errors.routingforACHEFTInt = "Please enter routing for ACH/EFT number.";
        }
        if (this.state.accountInt === null || this.state.accountInt === '') {
            formIsValid = false;
            errors.accountInt = "Please enter account.";
        }
        if (this.state.swiftCodeInt === null || this.state.swiftCodeInt === '') {
            formIsValid = false;
            errors.swiftCodeInt = "Please enter swift code.";
        }
        if (this.state.bankNameInt === null || this.state.bankNameInt === '') {
            formIsValid = false;
            errors.bankNameInt = "Please enter bank name.";
        }
        if (this.state.bankAddressInt === null || this.state.bankAddressInt === '') {
            formIsValid = false;
            errors.bankAddressInt = "Please enter bank address.";
        }
        if (this.state.referenceInt === null || this.state.referenceInt === '') {
            formIsValid = false;
            errors.referenceInt = "Please enter reference.";
        }
        if (this.state.checkPayableName === null || this.state.checkPayableName === '') {
            formIsValid = false;
            errors.checkPayableName = "Please enter check payable name.";
        }
        if (this.state.checkPayableAddress === null || this.state.checkPayableAddress === '') {
            formIsValid = false;
            errors.checkPayableAddress = "Please enter check payable address.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    addEditWireInstructionControl = (e, data) => {
        if (data != null) {
            e.preventDefault();
            this.setState({
                rowId: data.wiringInstructionId,
                addEditWireInstructorDataModalIsOpen: true,
                wiringName: data.wiringName,
                vendorAddress1: data.vendorAddress1,
                vendorName: data.vendorName,
                vendorAddress2: data.vendorAddress2,
                accountName: data.serviceAccountName,
                isDefault: data.isDefault === true ? true : false,
                beneficiary: data.beneficiary,
                accountSubName: data.accountSubName,
                routingforWire: data.wireRoutingNo,
                routingforACHEFT: data.achRoutingNo,
                account: data.accountNo,
                bankName: data.bankName,
                bankAddress: data.bankAddress,
                reference: data.reference,
                isApplyaboveinformationforInternationalWiringInstructions: false,
                accountNameInt: data.accountNameInt,
                beneficiaryInt: data.beneficiaryInt,
                accountSubNameInt: data.accountSubNameInt,
                routingforWireInt: data.wireRoutingNoInt,
                routingforACHEFTInt: data.achRoutingNoInt,
                accountInt: data.accountNoInt,
                swiftCodeInt: data.swiftCodeInt,
                bankNameInt: data.bankNameInt,
                bankAddressInt: data.bankAddressInt,
                referenceInt: data.referenceInt,
                checkPayableName: data.checkPayableName,
                checkPayableAddress: data.checkPayableAddress,
                checkMemo: data.checkMemo,
            });
        }
        else {
            this.setState({
                rowId: 0,
                addEditWireInstructorDataModalIsOpen: true,
                wiringName: null,
                vendorAddress1: null,
                vendorName: null,
                vendorAddress2: null,
                accountName: null,
                isDefault: false,
                beneficiary: null,
                accountSubName: null,
                routingforWire: null,
                routingforACHEFT: null,
                account: null,
                bankName: null,
                bankAddress: null,
                reference: null,
                isApplyaboveinformationforInternationalWiringInstructions: false,
                accountNameInt: null,
                beneficiaryInt: null,
                accountSubNameInt: null,
                routingforWireInt: null,
                routingforACHEFTInt: null,
                accountInt: null,
                swiftCodeInt: null,
                bankNameInt: null,
                bankAddressInt: null,
                referenceInt: null,
                checkPayableName: null,
                checkPayableAddress: null,
                checkMemo: null,
            });
        }
    }

    openWireInstructionDataModal = () => {

    }
    async handleAddEditWireInstructionSubmit(e) {
        e.preventDefault();
        this.setState({ isSubmited: true });
        if (this.handleValidation()) {

            var addEditRequest = new Object();
            addEditRequest.RowIndex = this.state.rowId > 0 ? this.state.rowId : 0;
            addEditRequest.WiringName = this.state.wiringName;
            addEditRequest.IsDefault = this.state.isDefault;
            addEditRequest.VendorName = this.state.vendorName;
            addEditRequest.VendorAddress1 = this.state.vendorAddress1;
            addEditRequest.VendorAddress2 = this.state.vendorAddress2;
            addEditRequest.DAccountName = this.state.accountName;
            addEditRequest.DBeneficiary = this.state.beneficiary;
            addEditRequest.DAccountSubName = this.state.accountSubName;
            addEditRequest.DWireRoutingNo = this.state.routingforWire;
            addEditRequest.DAchRoutingNo = this.state.routingforACHEFT;
            addEditRequest.DAccountNo = this.state.account;
            addEditRequest.DBankName = this.state.bankName;
            addEditRequest.DBankAddress = this.state.bankAddress;
            addEditRequest.DReference = this.state.reference;
            addEditRequest.IntAccountName = this.state.accountNameInt;
            addEditRequest.IntBeneficiary = this.state.beneficiaryInt;
            addEditRequest.IntAccountSubName = this.state.accountSubNameInt;
            addEditRequest.IntWireRoutingNo = this.state.routingforWireInt;
            addEditRequest.IntAchRoutingNo = this.state.routingforACHEFTInt;
            addEditRequest.IntAccountNo = this.state.accountInt;
            addEditRequest.IntBankName = this.state.bankNameInt;
            addEditRequest.IntBankAddress = this.state.bankAddressInt;
            addEditRequest.IntSwiftCode = this.state.swiftCodeInt;
            addEditRequest.IntReference = this.state.referenceInt;
            addEditRequest.CheckpayableName = this.state.checkPayableName;
            addEditRequest.CheckPayableAddress = this.state.checkPayableAddress;
            addEditRequest.CheckMemo = this.state.checkMemo;

            const requestParams = getRequestParams('POST', addEditRequest);
            const response = await fetch('wiring-instruction-api/SaveUpdate/', requestParams);
            const data = await response.json();
            Swal.fire({
                icon: data.isSuccess ? 'success' : 'error',
                title: data.isSuccess ? 'Success' : 'Oops...',
                text: data.resultMessage,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (data.isSuccess) {
                    if (result.isConfirmed) {
                        this.closeAddEditWireInstructionModal();
                    }
                } else {
                    this.setState({ isSubmited: false })
                }
            })
        }
        else {
            this.setState({ isSubmited: false })
        }

    }
    closeAddEditWireInstructionModal = () => {
        this.setState({
            rowId: 0,
            addEditWireInstructorDataModalIsOpen: false,
            isSubmited: false,
            wiringName: null,
            vendorAddress1: null,
            vendorName: null,
            vendorAddress2: null,
            accountName: null,
            isDefault: false,
            beneficiary: null,
            accountSubName: null,
            routingforWire: null,
            routingforACHEFT: null,
            account: null,
            bankName: null,
            bankAddress: null,
            reference: null,
            isApplyaboveinformationforInternationalWiringInstructions: false,
            accountNameInt: null,
            beneficiaryInt: null,
            accountSubNameInt: null,
            routingforWireInt: null,
            routingforACHEFTInt: null,
            accountInt: null,
            swiftCodeInt: null,
            bankNameInt: null,
            bankAddressInt: null,
            referenceInt: null,
            checkPayableName: null,
            checkPayableAddress: null,
            checkMemo: null,
            errors: {
                wiringName: '',
                vendorAddress1: '',
                vendorName: '',
                accountName: '',
                beneficiary: '',
                routingforWire: '',
                routingforACHEFT: '',
                account: '',
                bankName: '',
                bankAddress: '',
                reference: '',
                accountNameInt: '',
                beneficiaryInt: '',
                routingforWireInt: '',
                routingforACHEFTInt: '',
                accountInt: '',
                swiftCodeInt: '',
                bankNameInt: '',
                bankAddressInt: '',
                referenceInt: '',
                checkPayableName: '',
                checkPayableAddress: '',
            }
        });
        this.getWireInstructionList();
    }
    render() {
        const wireInstructionModalTitle = this.state.rowId > 0 ? "Edit Wiring Instructions" : "Add Wiring Instructions"
        const renderEditGridCell = (data) => {
            return <div>&nbsp;&nbsp;
                <a href="#" className="fas fa-pen icon text-info" title="Edit" onClick={(e) => { this.addEditWireInstructionControl(e, data.data) }} ></a>&nbsp;&nbsp;
            </div>;
        }
        const renderIsDefaultCell = (data) => {
            return data.data.isDefault === true ? "Yes" : "No"
        }
        return (<div className="content-wrapper">
            <div className="content-header">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-sm-6 col-md-6 col-lg-6">
                            <h1 className="m-0 text-dark">Wire Instruction</h1>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <ol className="breadcrumb float-sm-right">
                                <NavItem>
                                    <NavLink onClick={(e) => { this.addEditWireInstructionControl(e, null) }} className="btn btn-block btn-outline-secondary btn-sm" title="Add Wire Instruction"><i className="fas fa-plus"></i> Add Wire Instruction</NavLink>
                                </NavItem>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="card card-grid">
                    <div className="card-body">
                        <DataGrid
                            dataSource={this.state.wireInstructionList}
                            keyExpr="wiringInstructionId"
                            selection={{ mode: 'single' }}
                            columnsAutoWidth={false}
                            showColumnLines={false}
                            showRowLines={true}
                            showBorders={true}
                            rowAlternationEnabled={true}
                            showBorders={true}
                            allowColumnResizing={true}
                            columnResizingMode='widget'
                            onToolbarPreparing={this.onToolbarPreparing}>
                            <Column dataField="wiringInstructionId" width={50} caption="" allowFiltering={false} allowSorting={false} cellRender={renderEditGridCell} />
                            <Column dataField="wiringName" width={200} caption="Wiring Name" allowFiltering={true} allowSorting={true} />
                            <Column dataField="isDefault" width={200} caption="Is Default?" allowFiltering={true} allowSorting={true} cellRender={renderIsDefaultCell} />
                            <Column dataField="vendorName" caption="Vendor Name" width={200} allowFiltering={true} allowSorting={true} />
                            <Column dataField="vendorAddress1" caption="Vendor Address 1" width={200} allowFiltering={true} allowSorting={true} />
                            <Column dataField="serviceAccountName" caption="Account Name" width={200} allowFiltering={true} allowSorting={true} />
                            <Column dataField="beneficiary" caption="Beneficiary" width={200} allowFiltering={true} allowSorting={true} />
                            <Column dataField="accountSubName" caption="Account Sub Name" width={200} allowFiltering={true} allowSorting={true} />
                            <Column dataField="wireRoutingNo" caption="Wire Routing #" width={200} allowFiltering={true} allowSorting={true} />
                            <Column dataField="achRoutingNo" caption="ACH Routing #" width={200} allowFiltering={true} allowSorting={true} />
                            <Column dataField="accountNo" caption="Account #" width={200} allowFiltering={true} allowSorting={true} />
                            <Column dataField="bankName" caption="Bank Name" width={200} allowFiltering={true} allowSorting={true} />
                            <Column dataField="bankAddress" caption="Bank Address" width={200} allowFiltering={true} allowSorting={true} />
                            <Column dataField="reference" caption="Reference" width={200} allowFiltering={true} allowSorting={true} />
                            <Column dataField="accountNameInt" caption="International Account Name" width={200} allowFiltering={true} allowSorting={true} />
                            <Column dataField="beneficiaryInt" caption="International Beneficiary" width={200} allowFiltering={true} allowSorting={true} />
                            <Column dataField="accountSubNameInt" caption="International Account Sub Name" width={200} allowFiltering={true} allowSorting={true} />
                            <Column dataField="wireRoutingNoInt" caption="International Routing for Wire #" width={200} allowFiltering={true} allowSorting={true} />
                            <Column dataField="achRoutingNoInt" caption="International Routing for ACH/EFT #" width={200} allowFiltering={true} allowSorting={true} />
                            <Column dataField="accountNoInt" caption="International Account #" width={200} allowFiltering={true} allowSorting={true} />
                            <Column dataField="swiftCodeInt" caption="International Swift Code" width={200} allowFiltering={true} allowSorting={true} />
                            <Column dataField="bankNameInt" caption="International Bank Name" width={200} allowFiltering={true} allowSorting={true} />
                            <Column dataField="bankAddressInt" caption="International Bank Address" width={200} allowFiltering={true} allowSorting={true} />
                            <Column dataField="referenceInt" caption="International Reference" width={200} allowFiltering={true} allowSorting={true} />
                            <Column dataField="checkPayableName" caption="Check Payable Name" width={200} allowFiltering={true} allowSorting={true} />
                            <Column dataField="checkPayableAddress" caption="Check Payable Address" width={200} allowFiltering={true} allowSorting={true} />
                            <Column dataField="checkMemo" caption="Check Memo" width={200} allowFiltering={true} allowSorting={true} />
                            <Column dataField="wefDateStr" caption="Created Date" width={200} allowFiltering={true} allowSorting={true} />
                            <SortByGroupSummaryInfo summaryItem="count" />
                            <GroupPanel visible={true} />
                            <HeaderFilter visible={true} />
                            <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                            <Grouping autoExpandAll={true} />
                            <Paging defaultPageSize={20} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                            <Export enabled={true} allowExportSelectedData={false} />
                            <ColumnChooser enabled={true} />
                            <ColumnFixing enabled={true} />

                        </DataGrid>
                    </div>
                </div>
            </div>
            <Modal ariaHideApp={false}
                isOpen={this.state.addEditWireInstructorDataModalIsOpen}
                className={"react-modal edit-wireInstructor-modal"}
                onAfterOpen={this.openWireInstructionDataModal}
                onRequestClose={this.closeAddEditWireInstructionModal}
                shouldCloseOnOverlayClick={false}
                contentLabel="Example Modal"
            >
                <form method="post" onSubmit={this.handleAddEditWireInstructionSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="modal-header">
                        <h6 className="modal-title">{wireInstructionModalTitle}</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeAddEditWireInstructionModal}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body" id="model-body-Content">
                        <div className="row">
                            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                <div className="form-group">
                                    <label>Wiring Name <span className="text-red">*</span></label>
                                    <input type="text" className="form-control form-textbox form-control-sm" name="wiringName" title="Wiring Name" placeholder="Enter wiring name" maxLength="75" value={this.state.wiringName} onChange={this.handleInputChange} />
                                    {this.state.errors["wiringName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["wiringName"]}</span>}
                                </div>
                            </div>  
                        </div>
                        <div className="row">       
                            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                <div className="form-group">
                                    <label>Vendor Name <span className="text-red">*</span></label>
                                    <input type="text" className="form-control form-textbox form-control-sm" name="vendorName" title="Vendor Name" placeholder="Enter vendor name" maxLength="75" value={this.state.vendorName} onChange={this.handleInputChange} />
                                    {this.state.errors["vendorName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["vendorName"]}</span>}
                                </div>
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label>Vendor Address 1<span className="text-red">*</span></label>
                                    <input type="text" className="form-control form-textbox form-control-sm" name="vendorAddress1" title="Vendor Address1" placeholder="Enter vendor address" maxLength="75" value={this.state.vendorAddress1} onChange={this.handleInputChange} />
                                    {this.state.errors["vendorAddress1"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["vendorAddress1"]}</span>}
                                </div>
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label>Vendor Address 2</label>
                                    <input type="text" className="form-control form-textbox form-control-sm" name="vendorAddress2" title="Vendor Address2" placeholder="Enter vendor address 2" maxLength="75" value={this.state.vendorAddress2} onChange={this.handleInputChange} />
                                </div>
                            </div>
                            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                <div className="form-group">
                                    <br/>
                                    <label htmlFor="IsDefault"><input type="checkbox" id="IsDefault" className="form-check-label text-blue" name="isDefault" value={this.state.isDefault} checked={this.state.isDefault} onChange={this.handleInputChange} />
                                     &nbsp;Is Default?</label>
                                </div>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <label className="text-info"><strong>Wiring Instructions</strong></label>
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label>Account Name <span className="text-red">*</span></label>
                                    <input type="text" className="form-control form-textbox form-control-sm" name="accountName" title="Account Name" placeholder="Enter account name" maxLength="75" value={this.state.accountName} onChange={this.handleInputChange} />
                                    {this.state.errors["accountName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["accountName"]}</span>}
                                </div>
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label>Beneficiary<span className="text-red">*</span></label>
                                    <input type="text" className="form-control form-textbox form-control-sm" name="beneficiary" title="Beneficiary" placeholder="Enter beneficiary" maxLength="75" value={this.state.beneficiary} onChange={this.handleInputChange} />
                                    {this.state.errors["beneficiary"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["beneficiary"]}</span>}
                                </div>
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label>Account Sub Name</label>
                                    <input type="text" className="form-control form-textbox form-control-sm" name="accountSubName" title="Account Sub Name" placeholder="Enter account sub name" maxLength="75" value={this.state.accountSubName} onChange={this.handleInputChange} />
                                </div>
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label>Routing for Wire Number <span className="text-red">*</span></label>
                                    <NumberFormat className="form-control form-control-sm" name="routingforWire" title="Routing for Wire #" value={this.state.routingforWire} onChange={this.handleInputChange} maxLength="20" placeholder="Enter routing for wire number" />
                                    {this.state.errors["routingforWire"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["routingforWire"]}</span>}
                                </div>
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label>Routing for ACH/EFT Number <span className="text-red">*</span></label>
                                    <NumberFormat className="form-control form-control-sm" name="routingforACHEFT" title="Routing for ACH/EFT #" placeholder="Enter routing for ACH/EFT #" maxLength="20" value={this.state.routingforACHEFT} onChange={this.handleInputChange} />
                                    {this.state.errors["routingforACHEFT"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["routingforACHEFT"]}</span>}
                                </div>
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label>Account Number <span className="text-red">*</span></label>
                                    <input type="text" className="form-control form-textbox form-control-sm" name="account" title="Account #" placeholder="Enter account number" maxLength="20" value={this.state.account} onChange={this.handleInputChange} />
                                    {this.state.errors["account"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["account"]}</span>}
                                </div>
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label>Bank Name <span className="text-red">*</span></label>
                                    <input type="text" className="form-control form-textbox form-control-sm" name="bankName" title="Bank Name" placeholder="Enter bank name" maxLength="75" value={this.state.bankName} onChange={this.handleInputChange} />
                                    {this.state.errors["bankName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["bankName"]}</span>}
                                </div>
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label>Bank Address<span className="text-red">*</span></label>
                                    <input type="text" className="form-control form-textbox form-control-sm" name="bankAddress" title="Bank Address" placeholder="Enter bank address" maxLength="100" value={this.state.bankAddress} onChange={this.handleInputChange} />
                                    {this.state.errors["bankAddress"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["bankAddress"]}</span>}
                                </div>
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label>Reference <span className="text-red">*</span></label>
                                    <textarea className="form-control form-textbox form-control-sm" style={{ height: "30px" }} name="reference" title="Reference" placeholder="Enter reference" maxLength="1000" value={this.state.reference} onChange={this.handleInputChange} />
                                    {this.state.errors["reference"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["reference"]}</span>}
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <br/>
                                    <label htmlFor="IsApplyaboveinformationforInternationalWiringInstructions"><input type="checkbox" id="IsApplyaboveinformationforInternationalWiringInstructions" name="isApplyaboveinformationforInternationalWiringInstructions" value={this.state.isApplyaboveinformationforInternationalWiringInstructions} checked={this.state.isApplyaboveinformationforInternationalWiringInstructions} onChange={this.handleInputChange} />
                                        &nbsp;Apply above information for International Wiring Instructions</label>
                                </div>
                            </div>
                                                       
                        </div>
                        
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <label className="text-info"><strong>International Wiring Instructions</strong></label>
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label>Account Name <span className="text-red">*</span></label>
                                    <input type="text" className="form-control form-textbox form-control-sm" name="accountNameInt" title="Account Name" placeholder="Enter account name" maxLength="75" value={this.state.accountNameInt} onChange={this.handleInputChange} />
                                    {this.state.errors["accountNameInt"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["accountNameInt"]}</span>}
                                </div>
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label>Beneficiary<span className="text-red">*</span></label>
                                    <input type="text" className="form-control form-textbox form-control-sm" name="beneficiaryInt" title="Beneficiary" placeholder="Add beneficiary" maxLength="75" value={this.state.beneficiaryInt} onChange={this.handleInputChange} />
                                    {this.state.errors["beneficiaryInt"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["beneficiaryInt"]}</span>}
                                </div>
                            </div> 
                            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label>Account Sub Name</label>
                                    <input type="text" className="form-control form-textbox form-control-sm" name="accountSubNameInt" title="Account Sub Name" placeholder="Enter account sub name" maxLength="75" value={this.state.accountSubNameInt} onChange={this.handleInputChange} />
                                </div>
                            </div> 
                            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label>Routing for Wire Number <span className="text-red">*</span></label>
                                    <NumberFormat className="form-control form-control-sm" name="routingforWireInt" title="Routing for Wire #" placeholder="Add routing for wire number" maxLength="20" value={this.state.routingforWireInt} onChange={this.handleInputChange} />
                                    {this.state.errors["routingforWireInt"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["routingforWireInt"]}</span>}
                                </div>
                            </div> 
                            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label>Routing for ACH/EFT Number <span className="text-red">*</span></label>
                                    <NumberFormat className="form-control form-control-sm" name="routingforACHEFTInt" title="Routing for ACH/EFT#" placeholder="Enter routing for ACH/EFT number" maxLength="20" value={this.state.routingforACHEFTInt} onChange={this.handleInputChange} />
                                    {this.state.errors["routingforACHEFTInt"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["routingforACHEFTInt"]}</span>}
                                </div>
                            </div> 
                            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label>Account Number <span className="text-red">*</span></label>
                                    <input type="text" className="form-control form-textbox form-control-sm" name="accountInt" title="Account #" placeholder="Enter account number" maxLength="20" value={this.state.accountInt} onChange={this.handleInputChange} />
                                    {this.state.errors["accountInt"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["accountInt"]}</span>}
                                </div>
                            </div>                              
                            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label>Bank Name <span className="text-red">*</span></label>
                                    <input type="text" className="form-control form-textbox form-control-sm" name="bankNameInt" title="Bank Name" placeholder="Enter bank name" maxLength="75" value={this.state.bankNameInt} onChange={this.handleInputChange} />
                                    {this.state.errors["bankNameInt"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["bankNameInt"]}</span>}
                                </div>
                            </div> 
                            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label>Bank Address<span className="text-red">*</span></label>
                                    <input type="text" className="form-control form-textbox form-control-sm" name="bankAddressInt" title="Bank Address" placeholder="Enter bank address" maxLength="100" value={this.state.bankAddressInt} onChange={this.handleInputChange} />
                                    {this.state.errors["bankAddressInt"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["bankAddressInt"]}</span>}
                                </div>
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label>Swift Code<span className="text-red">*</span></label>
                                    <input type="text" className="form-control form-textbox form-control-sm" name="swiftCodeInt" title="Swift Code" placeholder="Enter swift code" maxLength="20" value={this.state.swiftCodeInt} onChange={this.handleInputChange} />
                                    {this.state.errors["swiftCodeInt"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["swiftCodeInt"]}</span>}
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label>Reference <span className="text-red">*</span></label>
                                    <textarea className="form-control form-textbox form-control-sm" style={{ height: "30px" }} name="referenceInt" title="Reference" placeholder="Enter reference" maxLength="1000" value={this.state.referenceInt} onChange={this.handleInputChange} />
                                    {this.state.errors["referenceInt"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["referenceInt"]}</span>}
                                </div>
                            </div>                            
                        </div>
                        
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <label className="text-info"><strong>By Check</strong></label>
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label>Check Payable Name <span className="text-red">*</span></label>
                                    <input type="text" className="form-control form-textbox form-control-sm" name="checkPayableName" title="Check Payable Name" placeholder="Enter check payable name" maxLength="100" value={this.state.checkPayableName} onChange={this.handleInputChange} />
                                    {this.state.errors["checkPayableName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["checkPayableName"]}</span>}
                                </div>
                            </div> 
                            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label>Check Payable Address<span className="text-red">*</span></label>
                                    <textarea className="form-control form-textbox form-control-sm" style={{ height: "30px" }} name="checkPayableAddress" title="Check Payable Address" placeholder="Enter check payable address" maxLength="100" value={this.state.checkPayableAddress} onChange={this.handleInputChange} />
                                    {this.state.errors["checkPayableAddress"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["checkPayableAddress"]}</span>}
                                </div>
                            </div>  
                            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label>Check Memo</label>
                                    <textarea className="form-control form-textbox form-control-sm" style={{ height: "30px" }} name="checkMemo" title="Check Memo" placeholder="Enter check memo" maxLength="500" value={this.state.checkMemo} onChange={this.handleInputChange} />
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="submit" className="btn btn-success btn-sm" onClick={this.handleAddEditWireInstructionSubmit} >
                            {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                            {!this.state.isSubmited && <span title="Save"><i className="fas fa-save"></i> Save</span>}
                        </button>
                        <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeAddEditWireInstructionModal} title="Close"><i className="fas fa-times"></i> Close</button>
                    </div>
                </form>
            </Modal>
        </div >)
    }
}