import React, { Component } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
} from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { VehicleDeductionHistoryComponent } from '../Shared/VehicleDeductionHistoryComponent';
import { VehicleOwnerListComponent } from './VehicleOwnerListComponent';
import { VehicleDeductionChargeComponent } from '../Shared/VehicleDeductionChargeComponent';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { LoadPanel } from 'devextreme-react/load-panel';
export class VehicleDeductionListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isGotoVehicleOwnerComponent: false,
            isSubmited: false,
            isRefreshSubmited: false,
            loadPanelVisible: false,
            vehicleDeductionGridDataSource: null,
        };
    }
    componentDidMount() {
        this.getVehicleDeductionList();
    }
    getVehicleDeductionList = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            var request = new Object();
            request.VehicleId = Number(this.props.vehicleId);
            request.VehicleOwnerMapId = Number(this.props.vehicleOwnerMapId);
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('vehicle-deduction-api/0/list', requestParams);
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    vehicleDeductionGridDataSource: data.vehicleDeductions
                });
            }
        } catch (ex) {
            console.log(ex);
            this.onfailure('error', 'Oops...', 'Something went wrong! Unable to process the request.')
        }
        this.setState({ loadPanelVisible: false });
    };
    handleToolbarPreparing = (e) => {
        try {
            e.toolbarOptions.items.unshift({
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    onClick: this.getVehicleDeductionList.bind(this)
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnRefresh = async () => {
        try {
            this.setState({ isRefreshSubmited: true });
            this.setState({
                isGotoVehicleOwnerComponent: false,
                isSubmited: false,
                loadPanelVisible: false,
                vehicleDeductionGridDataSource: null,
            });
            await this.getVehicleDeductionList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isRefreshSubmited: false });
    }
    handleOnAddVehicleDeduction = async (e) => {
        e.preventDefault();
        try {
            await this.refs.vehicleDeductionChargeComponent.handleShowModal(
                true,
                false,
                false,
                0,
                Number(this.props.vehicleOwnerMapId),
                Number(this.props.vehicleId),
                this.props.vehicleNumber,
                Number(this.props.ownerId),
                this.props.ownerName,
                false,
                0,
                '',
                0,
                false,
                null,
            );
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnEditVehicleDeduction = async (e, data) => {
        e.preventDefault();
        try {
            if (data != null) {
                try {
                    await this.refs.vehicleDeductionChargeComponent.handleShowModal(
                        false,
                        true,
                        false,
                        (data.id === null ? 0 : Number(data.id)),
                        Number(this.props.vehicleOwnerMapId),
                        Number(this.props.vehicleId),
                        this.props.vehicleNumber,
                        Number(this.props.ownerId),
                        this.props.ownerName,
                        false,
                        0,
                        '',
                        0,
                        false,
                        null,
                    );
                } catch (ex) {
                    console.log(ex);
                }
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnViewVehicleDeduction = async (e, data) => {
        e.preventDefault();
        try {
            if (data != null) {
                try {
                    await this.refs.vehicleDeductionChargeComponent.handleShowModal(
                        false,
                        false,
                        true,
                        (data.id === null ? 0 : Number(data.id)),
                        Number(this.props.vehicleOwnerMapId),
                        Number(this.props.vehicleId),
                        this.props.vehicleNumber,
                        Number(this.props.ownerId),
                        this.props.ownerName,
                        false,
                        0,
                        '',
                        0,
                        false,
                        null,
                    );
                } catch (ex) {
                    console.log(ex);
                }
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    handleActivateDeActivateVehicleDeduction = async (e, data, isActivate) => {
        e.preventDefault();
        try {
            let msg = 'Are you sure want to ' + (isActivate ? 'activate' : 'deactivate') + ' current deduction?';
            let isConfirm = await this.isConfirm(msg, 'Yes', 'No');
            if (isConfirm === true) {
                var request = new Object();
                request.VehicleOwnerMapId = Number(this.props.vehicleOwnerMapId);
                request.VehicleId = Number(this.props.vehicleId);
                request.IsActive = isActivate;
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('vehicle-deduction-api/' + Number(data.id) + '/activate-deactivate-vehicle-deduction', requestParams);
                if (response.ok) {
                    const data = await response.json();
                    await Swal.fire({
                        icon: data.isSuccess ? 'success' : 'error',
                        title: data.isSuccess ? 'Success' : 'Oops...',
                        text: (data.isSuccess === false && (data.resultMessage === null || data.resultMessage === "")) ? 'Unable to process the request.' : data.resultMessage,
                    }).then(async (result) => {
                        if (data.isSuccess) {
                            if (result.isConfirmed) {
                                await this.handleOnRefresh();
                            }
                        }
                    })
                }
                else {
                    await this.onfailure('error', 'Something went wrong! Unable to process the request.');
                }
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    handleDeleteVehicleDeduction = async (e, data) => {
        e.preventDefault();
        try {
            let isGoToSave = true;
            let vehicleDeductionId = Number(data.id);
            let deductionId = Number(data.deductionId);
            let isAuthorized = data.isAuthorized;
            let lastAuthorizedId = Number(data.lastAuthorizedId);
            let authorizedStatusId = Number(data.authorizedStatusId);
            let authorizedRequestedById = Number(data.authorizedRequestedById);
            let longgedInUserId = parseInt(sessionStorage.getItem("session_userId"));
            if (isAuthorized === false && lastAuthorizedId === 0) {
                let msg = 'You can not delete this deduction at this time.<br/>You need to send request for delete.<br/><br/>Are you sure want to send it now?';
                let isConfirm = await this.isConfirm(msg, 'Yes', 'No');
                if (isConfirm === true) {
                    //sendDeletionRequest
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'warning',
                        title: 'Unable to send request.',
                        text: 'Thie feature is under development.',
                    });
                }
                isGoToSave = false;
            }
            if (isGoToSave === true) {
                if (isAuthorized === false && lastAuthorizedId >= 0) {
                    if (authorizedStatusId === 0) {
                        await this.onfailure('info', 'Pending approval', 'Awaiting for approval of Deletion Request.');
                        isGoToSave = false;
                    }
                    if (authorizedStatusId === 1 && isGoToSave) {
                        if (authorizedRequestedById !== Number(longgedInUserId)) {
                            await this.onfailure('info', 'Pending approval', 'You can not delete this deduction at this time. Some other user already requested');
                            isGoToSave = false;
                        }
                    }
                    if (authorizedStatusId === 2 && isGoToSave) {
                        let msg = '';
                        if (authorizedRequestedById === Number(longgedInUserId)) {
                            msg = 'Your prevoius request rejected by authorized person.<br/>You need to send new request for delete.<br/>Are you sure want to send it now?';
                        }
                        else {
                            msg = 'You can not delete this deduction at this time.<br/>You need to send request for delete.<br/>Are you sure want to send it now?';
                        }
                        let isConfirm = await this.isConfirm(msg, 'Yes', 'No');
                        if (isConfirm === true) {
                            //sendDeletionRequest
                            Swal.fire({
                                allowOutsideClick: false,
                                showCloseButton: true,
                                icon: 'warning',
                                title: 'Unable to send request.',
                                text: 'Thie feature is under development.',
                            });
                        }
                        isGoToSave = false;
                    }
                }
            }
            if (isGoToSave === true) {
                let msg = 'Are you sure want to delete current deduction?';
                let isConfirm = await this.isConfirm(msg, 'Yes', 'No');
                if (isConfirm === true) {
                    var request = new Object();
                    request.VehicleOwnerMapId = Number(this.props.vehicleOwnerMapId);
                    request.DeductionId = Number(deductionId);
                    const requestParams = getRequestParams('POST', request);
                    const response = await fetch('vehicle-deduction-api/' + Number(vehicleDeductionId) + '/delete-vehicle-deduction', requestParams);
                    if (response.ok) {
                        const data = await response.json();
                        await Swal.fire({
                            icon: data.isSuccess ? 'success' : 'error',
                            title: data.isSuccess ? 'Success' : 'Oops...',
                            text: (data.isSuccess === false && (data.resultMessage === null || data.resultMessage === "")) ? 'Unable to process the request.' : ((data.resultMessage === null || data.resultMessage === "") ? 'Current deduction deleted successfully!' : data.resultMessage),
                        }).then(async (result) => {
                            if (data.isSuccess) {
                                if (result.isConfirmed) {
                                    await this.handleOnRefresh();
                                }
                            }
                        })
                    }
                    else {
                        await this.onfailure('error', 'Something went wrong! Unable to process the request.');
                    }
                }
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    handleMasterHistoryModal = async (e, data) => {
        e.preventDefault();
        try {
            await this.refs.VehicleDeductionHistoryComponent.handleShowModal("vehicle-deduction-api", 'Deduction', data.deductionName, data.id, this.props.vehicleId, this.props.ownerId);
        } catch (ex) {
            console.log(ex);
        }
    }
    onfailure = async (icon, title, resultMessage) => {
        try {
            await Swal.fire({
                allowOutsideClick: false,
                showCloseButton: false,
                icon: (icon === null || icon.trim() === '') ? 'error' : icon,
                title: (title === null || title.trim() === '') ? 'Oops...' : title,
                html: (resultMessage === null || resultMessage.trim() === '') ? 'Something went wrong!' : resultMessage.trim(),
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    isConfirm = async (message, confirmButtonText, cancelButtonText) => {
        let isConfirm = false;
        try {
            await Swal.fire({
                icon: 'question',
                title: '<small><b>Confirmation</b></small>',
                html: message,
                //showCloseButton: true,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: "<span style='font-size:14px;'>" + ((confirmButtonText === '' || confirmButtonText === null) ? '' : confirmButtonText) + " </span>",
                cancelButtonText: ((cancelButtonText === '' || cancelButtonText === null) ? 'No' : cancelButtonText),
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                dangerMode: false,
                allowOutsideClick: false
            }).then(async (result) => {
                if (result.isConfirmed) {
                    isConfirm = true;
                }
            });
        } catch (ex) {
            console.log(ex);
        }
        return isConfirm
    }
    render() {
        if (this.state.isGotoVehicleOwnerComponent) {
            return <VehicleOwnerListComponent vehicleId={this.props.vehicleId} vehicleNumber={this.props.vehicleNumber} />
        }
        const renderGridCell = (data) => {
            let editLink = this.props.isOwnerActive ? <a href="#" title="Edit" onClick={(e) => { this.handleOnEditVehicleDeduction(e, data.data) }} >
                <i className="fas fa-pen"></i></a> : '';
            let viewLink = <a href="#" title="View" style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleOnViewVehicleDeduction(e, data.data) }} >
                <i className="fas fa-eye"></i></a>;
            let historyLink = <a href="#" title="History" style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleMasterHistoryModal(e, data.data) }}>
                <i className="fas fa-history"></i></a>
            let deleteLink = this.props.isOwnerActive ? <a href="#" title="Delete" style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleDeleteVehicleDeduction(e, data.data) }} ><i className="fas fa-trash-alt"></i></a> : '';
            let activateLink = (this.props.isOwnerActive && data.data.isActive) ? <a href="#" title={'Deactivate this deduction'}
                style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleActivateDeActivateVehicleDeduction(e, data.data, false) }} >
                <i className={"fas fa-toggle-on"}></i></a> : '';
            let deActiveLink = (this.props.isOwnerActive && !data.data.isActive) ? <a href="#" title={'Activate this deduction'}
                style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleActivateDeActivateVehicleDeduction(e, data.data, true) }} >
                <i className={"text-danger fas fa-toggle-off"}></i></a> : '';
            return (
                <div style={{ textAlign: 'left', width: 'auto' }}>
                    {editLink === '' || editLink}
                    {viewLink}
                    {historyLink}
                    {deleteLink === '' || deleteLink}
                    {activateLink === '' || activateLink}
                    {deActiveLink === '' || deActiveLink}
                </div>
            );
        }
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <h1 className="m-0 text-dark">Vehicle Deduction - Vehicle <i><strong className="text-danger">{this.props.vehicleNumber}</strong></i> Owner <i><strong className="text-danger">{this.props.ownerName}</strong></i></h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem>
                                        <NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="#" title="Refresh"
                                            onClick={this.handleOnRefresh}>
                                            {this.state.isRefreshSubmited && <span title="In Process"><i className="fas fa-spinner fa-spin"></i> In Process</span>}
                                            {!this.state.isRefreshSubmited && <span title="Refresh"><i className="fas fa-sync-alt"></i> Refresh</span>}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem style={{ display: this.props.isOwnerActive ? 'block' : 'none' }} >
                                        <NavLink tag={Link} to="#" className="btn btn-block btn-outline-secondary btn-sm"
                                            onClick={(e) => { this.handleOnAddVehicleDeduction(e) }} title="Add Deduction">
                                            <i className="fas fa-plus"></i> Add Deduction
                                            </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="#" className="btn btn-block btn-outline-secondary btn-sm"
                                            onClick={(e) => { this.setState({ isGotoVehicleOwnerComponent: true }) }} title="Back">
                                            <i className="fas fa-angle-double-left"></i> Back
                                                </NavLink>
                                    </NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="card card-grid">
                        <div className="card-body">
                            <DataGrid
                                dataSource={this.state.vehicleDeductionGridDataSource}
                                keyExpr="id"
                                selection={{ mode: 'single' }}
                                columnsAutoWidth={true}
                                showColumnLines={true}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                onToolbarPreparing={this.handleToolbarPreparing}>
                                <Column dataField="id" width={90} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                <Column dataField="deductionName" caption="Deduction Name" width={'auto'} />
                                <Column dataField="previousDeductionName" caption="Old Name" width={'auto'} />
                                <Column dataField="startDate" caption="Start Date" width={'auto'} />
                                <Column dataField="endDate" caption="End Date" width={'auto'} />
                                <Column dataField="occurrenceStatus" caption="Occurrence" />
                                <Column dataField="openingBalance" caption="Opening Balance" width={'auto'} alignment={'center'} />
                                <Column dataField="loanAmount" caption="Amount Paid" width={'auto'} alignment={'center'} />
                                <Column dataField="recurringCharge" caption="Deduction Amount" width={'auto'} alignment={'center'} />
                                <Column dataField="deductedAmount" caption="Total Collected Life" width={'auto'} alignment={'center'} />
                                <Column dataField="totalBalance" caption="Balance" width={'auto'} alignment={'center'} />
                                <Column dataField="note" caption="Note" width={'auto'} />
                                <Column dataField="activeStatus" caption="Active Status" width={'auto'} />
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                <Export enabled={true} allowExportSelectedData={false} />
                                <ColumnChooser enabled={true} />
                                <ColumnFixing enabled={true} />
                            </DataGrid>
                        </div>
                    </div>
                </div>
                <VehicleDeductionChargeComponent ref="vehicleDeductionChargeComponent" handleOnRefresh={this.handleOnRefresh} />
                <VehicleDeductionHistoryComponent ref="VehicleDeductionHistoryComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}