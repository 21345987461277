import React, { Component } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
    Selection
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { SelectBox } from 'devextreme-react/select-box';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Modal from 'react-modal'; //npm install --save react-modal
import DateBox from 'devextreme-react/date-box';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import NumberFormat from 'react-number-format';
import NumericInput from 'react-numeric-input'; //npm install react-numeric-input --save
import { getRequestParams, formatDate } from '../../helpers/globalfunctions';
import { StatusType, DateFormat } from '../../helpers/fixcodes';
import { LoadPanel } from 'devextreme-react/load-panel';
import { ReservationHistoryComponent } from '../Shared/ReservationHistoryComponent';
import { DocumentsComponent } from '../Shared/DocumentsComponent';
const position = { of: '#historydiv' };
const customStyles = {
    content: {
        width: '30%',
    }
};
const selectionData = [
    {
        "id": 1,
        "name": "Ride ID",
    },
    {
        "id": 2,
        "name": "Pax Name",
    },
    {
        "id": 3,
        "name": "Customer",
    },
    {
        "id": 4,
        "name": "Vehicle Number",
    },
]

let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
let currencyIcon = sessionStorage.getItem("session_currencyIcon") === "" ? "fas fa-dollar-sign" : sessionStorage.getItem("session_currencyIcon");

export class UnapproveRidesComponent extends React.Component {
    constructor(props) {
        super(props);
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        this.state = {
            rowId: 0,
            addEditZonalRateDataModalIsOpen: false,
            isSubmited: false,
            selectionId: 0,
            selectionName: '',
            selectionValue: '',
            fromDateTime: new Date(currentTime.toLocaleDateString() + " 00:00"),
            toDateTime: new Date(currentTime.toLocaleDateString() + " 23:59"),
            customerList: [],
            customerId: 0,
            customerTypeList: [],
            customerTypeId: 0,
            unapproveRides: [],
            selectedRowKeys: [],
            selectedRides: '',

            isOpenToolModel: false,
            toolTitle: '',
            isToolSubmited: false,
            vehicleTypeId: 0,
            vehicleTypeList: [],
            dropdownZone: [],
            pickupZoneId: 0,
            dropoffZoneId: 0,
            isPaxChange: false,
            paxFirstName: '',
            paxLastName: '',
            isPaxCountChange: false,
            noOfPax: 1,
            pilotCount: 0,
            crewCount: 0,
            flightAttandCount: 0,
            isOrderByChange: false,
            orderByName: '',
            phoneNumberOrder: '',
            rideCustomerTypeId: 0,
            isCustomerCode: false,
            customerIdBillingTool:0,

            loadPanelVisible: false,
            errors: {
                selectionValue: '',
                customerId: '',
                vehicleTypeId: '',
                pickupZoneId: '',
                dropoffZoneId: '',
                paxFirstName: '',
                paxLastName: '',
                noOfPax: '',
                pilotCount: '',
                orderByName: '',
                phoneNumberOrder: '',
                customerIdBillingTool:'',
            }
        }
        this.getcustomerList = this.getcustomerList.bind(this);
        this.getCustomerTypeList = this.getCustomerTypeList.bind(this);
        this.getVehicleType = this.getVehicleType.bind(this);
        this.getZones = this.getZones.bind(this);

        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.handleFromValueChanged = this.handleFromValueChanged.bind(this);
        this.handleToValueChanged = this.handleToValueChanged.bind(this);

        this.getUnapproveRides = this.getUnapproveRides.bind(this);

        this.handleSearchUnapproveRidesSubmit = this.handleSearchUnapproveRidesSubmit.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
        this.handleApproveRide = this.handleApproveRide.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);

        this.handleToolModel = this.handleToolModel.bind(this);
        this.closeTooleModal = this.closeTooleModal.bind(this);
        this.handleToolSubmit = this.handleToolSubmit.bind(this);
        this.handleVehicleTypeChange = this.handleVehicleTypeChange.bind(this);
        this.handlePickupZoneChange = this.handlePickupZoneChange.bind(this);
        this.handleDropoffZoneChange = this.handleDropoffZoneChange.bind(this);

        this.openEditReservationModel = this.openEditReservationModel.bind(this);
        this.openReservationHistoryModel = this.openReservationHistoryModel.bind(this);
        this.openEditFareModel = this.openEditFareModel.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.openReservationDocumentsModel = this.openReservationDocumentsModel.bind(this);
        this.clearGridFilter = this.clearGridFilter.bind(this);
        this.handlePaxCountChange = this.handlePaxCountChange.bind(this);
        this.handleModalValidation = this.handleModalValidation.bind(this);
    }
    async componentDidMount() {
        this.setState({
            loadPanelVisible: true,
        });
        await this.getcustomerList();
        await this.getCustomerTypeList();

        if (this.props.location.state != undefined) {

            this.setState({
                customerId: this.props.location.state.customerId,
                fromDateTime: this.props.location.state.fromDateTime,
                toDateTime: this.props.location.state.toDateTime,
                customerTypeId: this.props.location.state.customerTypeId,
                selectionId: this.props.location.state.selectionId,
                selectionName: this.props.location.state.selectionName,
                selectionValue: this.props.location.state.selectionValue,
            });

            let myVar = setTimeout(() => this.getUnapproveRides(), 200)
        }
        this.setState({
            loadPanelVisible: false,
        });
    }
    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'clear',
                onClick: this.clearGridFilter.bind(this)
            }
        });
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getUnapproveRides.bind(this)
            }
        });
    }
    clearGridFilter() {
        this.dataGrid.instance.clearFilter();
    }
    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.selectionValue = "";
        errors.customerId = "";
        if (this.state.selectionId > 0) {
            if (this.state.selectionId == 1) {
                if (this.state.selectionValue == 0 || this.state.selectionValue === "") {
                    formIsValid = false;
                    errors.selectionValue = "Please enter ride id.";
                }
            }
            if (this.state.selectionId == 2) {
                if (this.state.selectionValue === "") {
                    formIsValid = false;
                    errors.selectionValue = "Please enter vehicle number.";
                }
            }
            if (this.state.selectionId == 3) {
                if (parseInt(this.state.customerId) == 0) {
                    formIsValid = false;
                    errors.customerId = "Please select customer.";
                }
            }
            if (this.state.selectionId == 4) {
                if (this.state.selectionValue === "") {
                    formIsValid = false;
                    errors.selectionValue = "Please enter pax name.";
                }
            }
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    handleModalValidation() {

        let errors = this.state.errors;
        let formIsValid = true;
        errors.vehicleTypeId = "";
        errors.pickupZoneId = "";
        errors.dropoffZoneId = "";
        errors.paxFirstName = "";
        errors.paxLastName = "";
        errors.noOfPax = "";
        errors.pilotCount = "";
        errors.orderByName = "";
        errors.phoneNumberOrder = "";


        if (this.state.toolTitle === 'Change Vehicle Type') {
            if (parseInt(this.state.vehicleTypeId) == 0) {
                formIsValid = false;
                errors.vehicleTypeId = "Please select vehicle type.";
            }
        } else if (this.state.toolTitle === 'Change Zone') {
            if (parseInt(this.state.pickupZoneId) == 0) {
                formIsValid = false;
                errors.pickupZoneId = "Please select pickup zone.";
            }
            if (parseInt(this.state.dropoffZoneId) == 0) {
                formIsValid = false;
                errors.dropoffZoneId = "Please select dropoff zone.";
            }
        } else {
            if (this.state.isCustomerCode == true) {
                if (this.state.customerIdBillingTool == 0) {
                    formIsValid = false;
                    errors.customerIdBillingTool = "Please select customer.";
                }
            }

            if (this.state.isPaxChange == true) {
                if (this.state.paxFirstName.trim() == '') {
                    formIsValid = false;
                    errors.paxFirstName = "Please enter pax first name.";
                }
                if (this.state.paxLastName.trim() == '') {
                    formIsValid = false;
                    errors.paxLastName = "Please enter pax last name.";
                }
            }

            if (this.state.isPaxCountChange == true) {
                if (this.state.rideCustomerTypeId == 1 || this.state.rideCustomerTypeId == 2) {
                    if ((parseInt(this.state.pilotCount | 0) + parseInt(this.state.crewCount | 0) + parseInt(this.state.flightAttandCount | 0)) == 0) {
                        formIsValid = false;
                        errors.pilotCount = "Please enter pax count.";
                    }
                } else {
                    if (parseInt(this.state.noOfPax | 0) == 0) {
                        formIsValid = false;
                        errors.noOfPax = "Please enter pax count.";
                    }
                }
            }

            if (this.state.isOrderByChange == true) {
                if (this.state.orderByName.trim() == '') {
                    formIsValid = false;
                    errors.orderByName = "Please enter order by name.";
                }
                if (this.state.phoneNumberOrder.trim() == '') {
                    formIsValid = false;
                    errors.phoneNumberOrder = "Please enter order by phone.";
                }
            }

        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    async getUnapproveRides() {

        if (!this.handleValidation()) {
            return;
        }

        var request = new Object();

        request.CustomerTypeId = parseInt(this.state.customerTypeId);
        request.IsRideApproved = parseInt(0);
        request.PuFromDate = this.state.fromDateTime == null ? "" : this.state.fromDateTime.toLocaleDateString() + " 00:00:00"; //new Date(2020, 9, 1);
        request.PuToDate = this.state.toDateTime == null ? "" : this.state.toDateTime.toLocaleDateString() + " 23:59:59"; //new Date(2020, 9, 31);

        request.RideId = this.state.selectionId == 1 ? parseInt(this.state.selectionValue) : 0;
        request.CustomerId = this.state.selectionId == 3 ? parseInt(this.state.customerId) : 0;
        request.VehicleNo = this.state.selectionId == 2 ? this.state.selectionValue : "";
        request.PaxName = this.state.selectionId == 4 ? this.state.selectionValue : "";
        try {
            this.setState({
                isSubmited: true,
            });
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('manage-rides-api/list', requestParams);
            const data = await response.json();
            this.setState({
                unapproveRides: data.manageRides
            });
            this.setState({ isSubmited: false });

        } catch (e) {
            this.setState({ isSubmited: false });
        }

    }
    async getcustomerList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('account-api/customer-dropdown/', requestParams);
        const data = await response.json();
        this.setState({
            customerList: data.customers,
        });
    }

    async getCustomerTypeList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('customer-type-api/', requestParams);
        const data = await response.json();
        if (data !== null && data.customerType.length > 0) {
            this.setState({
                customerTypeList: data.customerType
            });
        }
    }

    async getVehicleType() {
        try {
            var newObject = new Object();
            const requestParams = getRequestParams('POST', newObject);
            const response = await fetch('vehicletype-api/vehicletype', requestParams);
            const data = await response.json();

            this.setState({
                vehicleTypeList: data.apiVehicleType
            });
        } catch (e) {
            console.log(e);
        }
    }

    async getZones() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('serviceAreaZone-api/zone-dropdown/' + 0 + "/" + 0, requestParams);
            const data = await response.json();

            this.setState({
                dropdownZone: data.locationServiceAreaZoneList
            });
        } catch (e) {
            console.log(e);
        }
    }

    handleCustomerInputChange = (data) => {
        this.setState({ customerId: (data.selectedItem != null ? data.selectedItem.id : 0), })
        this.state.errors["customerId"] = "";
    }

    handleCustomerBillingToolInputChange = (data) => {
        this.setState({ customerIdBillingTool: (data.selectedItem != null ? data.selectedItem.id : 0), })
    }

    handleFromValueChanged(date) {
        this.setState({ fromDateTime: date.value });
    }
    handleToValueChanged(date) {
        this.setState({ toDateTime: date.value });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === "selectionValue") {
            if (this.state.selectionId == 1) {
                const re = /^[0-9\b]+$/;
                if (event.target.value === '' || re.test(event.target.value)) {
                    this.setState({ [name]: event.target.value })
                }
            } else {
                this.setState({
                    [name]: value
                });
            }
        } else {
            this.setState({
                [name]: value
            });
        }

        if (name === "selectionId" && value > 0) {
            this.setState({ selectionName: selectionData.find(f => f.id == value).name })
        }
        this.state.errors["selectionValue"] = "";
        this.state.errors[name] = "";
    }

    handlePickupZoneChange(selectedOption) {
        this.setState({ pickupZoneId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
    }

    handleDropoffZoneChange(selectedOption) {
        this.setState({ dropoffZoneId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
    }

    handleVehicleTypeChange(selectedOption) {
        this.setState({ vehicleTypeId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.rowIndex : 0), })
    }

    async handleSearchUnapproveRidesSubmit(e) {
        e.preventDefault();

        await this.getUnapproveRides();
    }
    handleClearSearch() {

        this.setState({
            isSubmited: false,
            selectionId: 0,
            selectionName: '',
            selectionValue: '',
            selectedRides: '',
            fromDateTime: new Date(currentTime.toLocaleDateString() + " 00:00"),
            toDateTime: new Date(currentTime.toLocaleDateString() + " 23:59"),
            customerId: 0,
            customerTypeId: 0,
            unapproveRides: [],
            isOpenToolModel: false,
            toolTitle: '',
            isToolSubmited: false,
            vehicleTypeId: 0,
            vehicleTypeList: [],
            dropdownZone: [],
            pickupZoneId: 0,
            dropoffZoneId: 0,
            isPaxChange: false,
            paxFirstName: '',
            paxLastName: '',
            isPaxCountChange: false,
            noOfPax: 1,
            pilotCount: 0,
            crewCount: 0,
            flightAttandCount: 0,
            isOrderByChange: false,
            orderByName: '',
            phoneNumberOrder: '',
            rideCustomerTypeId: 0,
            isCustomerCode: false,
            customerIdBillingTool: 0,
            errors: {
                selectionValue: '',
                customerId: '',
                paxFirstName: '',
                paxLastName: '',
                noOfPax: '',
                pilotCount: '',
                orderByName: '',
                phoneNumberOrder: '',
                customerIdBillingTool:''
            }
        });
    }

    onSelectionChanged({ selectedRowKeys }) {
        this.setState({
            selectedRowKeys: selectedRowKeys
        });
    }

    async askApproveUnapproveConfirm() {
        let isResult = false;
        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: "You are going approve selected rides!<br> Do you want to still continue?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    async askChangeToolConfirm() {
        let isResult = false;
        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: "You are going [" + this.state.toolTitle + "] for the selected rides!<br> Do you want to still continue?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    async handleApproveRide(e) {
        e.preventDefault();
        const selectedRowsData = await this.dataGrid.instance.getDataByKeys(this.state.selectedRowKeys);

        if (selectedRowsData.length <= 0) {
            const isValid = await Swal.fire({
                title: "<small>Pending Approval</small>",
                text: "Please select atleast one ride.",
                icon: "info",
                dangerMode: false,
                width: "25%",
                dangerMode: false,
                allowOutsideClick: false
            });
            return;
        }

        const isConfirm = await this.askApproveUnapproveConfirm();
        if (!isConfirm) {
            return;
        }

        this.setState({ isSubmited: true });

        var selectedObj = "";
        for (let i = 0; i < selectedRowsData.length; i++) {
            selectedObj = selectedObj + selectedRowsData[i].rideId + ",";
        }

        this.setState({ selectedRides: selectedObj.replace(/,\s*$/, "") });
        try {
            var request = new Object();
            request.RideIdStr = selectedObj.replace(/,\s*$/, "");
            request.IsRideApproved = true;

            const requestParams = getRequestParams('POST', request);
            const response = await fetch('manage-rides-api/approve-unapprove', requestParams);
            const data = await response.json();
            Swal.fire({
                icon: data.isSuccess ? 'success' : 'error',
                title: data.isSuccess ? 'Success' : 'Oops...',
                text: data.resultMessage,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                this.setState({ isSubmited: false })
                if (result.isConfirmed) {
                    if (data.isSuccess) {
                        this.getUnapproveRides();
                    }
                }
            })

        } catch (e) {
            this.setState({ isSubmited: false });
        }
    }

    async handleToolModel(e, modelTitle) {
        e.preventDefault();
        const selectedRowsData = await this.dataGrid.instance.getDataByKeys(this.state.selectedRowKeys);

        if (selectedRowsData.length <= 0) {
            const isValid = await Swal.fire({
                title: "<small>" + modelTitle + "</small>",
                text: "Please select atleast one ride.",
                icon: "info",
                dangerMode: false,
                width: "25%",
                dangerMode: false,
                allowOutsideClick: false
            });
            return;
        }

        await this.getVehicleType();
        await this.getZones();
        var selectedObj = "";

        for (let i = 0; i < selectedRowsData.length; i++) {
            selectedObj = selectedObj + selectedRowsData[i].rideId + ",";

            this.setState({
                vehicleTypeId: selectedRowsData[i].apiVehicleTypeId,
                pickupZoneId: selectedRowsData[i].pickupZoneId,
                dropoffZoneId: selectedRowsData[i].dropoffZoneId,
                isPaxChange: false,
                paxFirstName: selectedRowsData[i].firstName,
                paxLastName: selectedRowsData[i].lastName,
                isPaxCountChange: false,
                noOfPax: selectedRowsData[i].adultNo,
                pilotCount: selectedRowsData[i].iPilot,
                crewCount: selectedRowsData[i].iCrew,
                flightAttandCount: selectedRowsData[i].iFlightAttandant,
                isOrderByChange: false,
                orderByName: selectedRowsData[i].orderBy,
                phoneNumberOrder: selectedRowsData[i].orderPhone,
                rideCustomerTypeId: selectedRowsData[i].customerTypeId,
                isCustomerCode: false,
                customerIdBillingTool: selectedRowsData[i].customerId,
            })
        }

        this.setState({ selectedRides: selectedObj.replace(/,\s*$/, "") });
        this.setState({
            toolTitle: modelTitle,
            isOpenToolModel: true,
        })
    }

    handlePaxCountChange(pType, value) {
        if (pType === "p") {
            this.setState({ pilotCount: value });

            let paxCount = (parseInt(value | 0) + parseInt(this.state.crewCount | 0) + parseInt(this.state.flightAttandCount | 0));
            this.setState({ noOfPax: paxCount });
            if (paxCount > 0) {
                this.state.errors["pilotCount"] = ""
            }
        } else if (pType === "c") {
            this.setState({ crewCount: value });

            let paxCount = (parseInt(this.state.pilotCount | 0) + parseInt(value | 0) + parseInt(this.state.flightAttandCount | 0));
            this.setState({ noOfPax: paxCount });
            if (paxCount > 0) {
                this.state.errors["pilotCount"] = ""
            }
        } else if (pType === "f") {
            this.setState({ flightAttandCount: value });

            let paxCount = (parseInt(this.state.pilotCount | 0) + parseInt(this.state.crewCount | 0) + parseInt(value | 0));
            this.setState({ noOfPax: paxCount });
            if (paxCount > 0) {
                this.state.errors["pilotCount"] = ""
            }
        } else if (pType === "a") {
            this.setState({ noOfPax: value });

            if (value > 0) {
                this.state.errors["noOfPax"] = ""
            }
        }
        this.state.errors["pilotCount"] = "";
    }

    closeTooleModal() {
        this.setState({
            isOpenToolModel: false,
            toolTitle: '',
            isToolSubmited: false,
            vehicleTypeId: 0,
            vehicleTypeList: [],
            dropdownZone: [],
            pickupZoneId: 0,
            dropoffZoneId: 0,
        })
    }

    async handleToolSubmit(e) {
        e.preventDefault();
        if (!this.handleModalValidation()) {
            return;
        }
        const isConfirm = await this.askChangeToolConfirm();
        if (!isConfirm) {
            return;
        }

        try {
            var request = new Object();
            request.RideIdStr = this.state.selectedRides;
            if (this.state.toolTitle === 'Change Vehicle Type') {
                let vehicle = this.state.vehicleTypeList.find(opt => opt.rowIndex == this.state.vehicleTypeId);
                request.ChangeToolId = 1;
                request.ApiVehicleTypeId = parseInt(this.state.vehicleTypeId);
                request.NewVehicleTypeName = vehicle.apiVehicleTypeDescription;
                request.FromZoneId = 0;
                request.ToZoneId = 0;
                request.RideTypeId = 0;
                request.NewFromZoneName = '';
                request.NewToZoneName = '';
                request.IsPaxName = false;
                request.FirstName = '';
                request.LastName = '';
                request.IsPaxNo = false;
                request.AdultNo = parseInt(0);
                request.ICrew = parseInt(0);
                request.IPilot = parseInt(0);
                request.IFlightAttandant = parseInt(0);
                request.IsOrderBy = false;
                request.OrderBy = '';
                request.OrderPhoneNo = '';


            } else if (this.state.toolTitle === 'Change Zone') {
                let puZone = this.state.dropdownZone.find(opt => opt.id == this.state.pickupZoneId);
                let doZone = this.state.dropdownZone.find(opt => opt.id == this.state.dropoffZoneId);
                var rideTypeId = 0;
                if (puZone.zoneTypeId === 1 && doZone.zoneTypeId === 3) {
                    rideTypeId = 1;
                } else if (puZone.zoneTypeId === 3 && doZone.zoneTypeId === 1) {
                    rideTypeId = 2;
                } else if (puZone.zoneTypeId === 1 && doZone.zoneTypeId === 1) {
                    rideTypeId = 3;
                } else {
                    rideTypeId = 13;
                }
                request.ChangeToolId = 2;
                request.ApiVehicleTypeId = 0;
                request.NewVehicleTypeName = '';
                request.FromZoneId = parseInt(this.state.pickupZoneId);
                request.ToZoneId = parseInt(this.state.dropoffZoneId);
                request.RideTypeId = parseInt(rideTypeId);
                request.NewFromZoneName = puZone.name;
                request.NewToZoneName = doZone.name;
                request.IsPaxName = false;
                request.FirstName = '';
                request.LastName = '';
                request.IsPaxNo = false;
                request.AdultNo = parseInt(0);
                request.ICrew = parseInt(0);
                request.IPilot = parseInt(0);
                request.IFlightAttandant = parseInt(0);
                request.IsOrderBy = false;
                request.OrderBy = '';
                request.OrderPhoneNo = '';
            } else {
                if (this.state.isCustomerCode == false && this.state.isPaxChange == false && this.state.isPaxCountChange == false && this.state.isOrderByChange == false) {
                    const isValid = await Swal.fire({
                        title: "<small>Selection Required</small>",
                        text: "Please select at least one checkbox.",
                        icon: "info",
                        dangerMode: false,
                        width: "25%",
                        dangerMode: false,
                        allowOutsideClick: false
                    });
                    return;
                }

                request.ChangeToolId = 3;
                request.ApiVehicleTypeId = parseInt(0);
                request.NewVehicleTypeName = '';
                request.FromZoneId = 0;
                request.ToZoneId = 0;
                request.RideTypeId = 0;
                request.NewFromZoneName = '';
                request.NewToZoneName = '';
                request.IsPaxName = this.state.isPaxChange;
                request.FirstName = this.state.paxFirstName;
                request.LastName = this.state.paxLastName;
                request.IsPaxNo = this.state.isPaxCountChange;
                request.AdultNo = parseInt(this.state.noOfPax);
                request.ICrew = parseInt(this.state.crewCount);
                request.IPilot = parseInt(this.state.pilotCount);
                request.IFlightAttandant = parseInt(this.state.flightAttandCount);
                request.IsOrderBy = this.state.isOrderByChange;
                request.OrderBy = this.state.orderByName;
                request.OrderPhoneNo = this.state.phoneNumberOrder;
                request.IsAccountCode = this.state.isCustomerCode;
                request.CustomerId = parseInt(this.state.customerIdBillingTool);
            }

            this.setState({ isToolSubmited: true })
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('manage-rides-api/change-billing-tool', requestParams);
            const data = await response.json();
            Swal.fire({
                icon: data.isSuccess ? 'success' : 'error',
                title: data.isSuccess ? 'Success' : 'Oops...',
                text: data.resultMessage,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                this.setState({ isToolSubmited: false })
                if (result.isConfirmed) {
                    if (data.isSuccess) {
                        this.closeTooleModal();
                        this.getUnapproveRides();
                    }
                }
            })

        } catch (e) {
            this.setState({ isToolSubmited: false });
        }

    }

    openEditReservationModel(e, data) {
        e.preventDefault();
        this.props.history.push({
            pathname: '/crudride',
            //state: { detail: data }
            state: { rideId: data.rideId, sourceUrl: "/billing/unapprove", sourceState: this.state }
        })
    }
    openReservationHistoryModel(e, data) {
        e.preventDefault();
        this.refs.reservationHistoryModal.showModal("Reservation", data.rideId);
    }
    openReservationDocumentsModel(e, data) {
        e.preventDefault();
        var rideArray = [];
        rideArray.push(data.rideId);
        this.refs.documentsComponent.showModal('Ride Id', rideArray, rideArray[0]);
    }

    openEditFareModel(e, data) {
        e.preventDefault();
        var hasAttachment = data.hasAttachment === "Yes" ? true : false;

        this.props.history.push({
            pathname: '/billing/crudfare',
            state: { rideId: data.rideId, paxName: data.fullName, customerId: data.customerId, isFranchisee: false, hasAttachment: hasAttachment, puLocationName: data.pickupLocation, doLocationName: data.dropoffLocation, isRideInvoice: false, approvalStatus: false, sourceUrl: "/billing/unapprove", sourceState: this.state }
        })
    }


    render() {

        const filterSearchFromDate = time => {
            const currentDate = currentTime;
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }

        const filterSearchToDate = time => {
            const currentDate = currentTime;
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }
        const isDisableAllButton = this.state.unapproveRides.length > 0 ? false : true;

        const renderGridCell = (data) => {
            return <div>
                <a href="#" className="fas fa-pen icon text-info" title="View" onClick={(e) => { this.openEditReservationModel(e, data.data) }}></a>&nbsp;&nbsp;
                <a href="#" className="fas fa-history icon text-info" title="History" onClick={(e) => { this.openReservationHistoryModel(e, data.data) }}></a>&nbsp;&nbsp;
                <a href="#" className="fas fa-paperclip icon text-info" title="View/Upload Documents" onClick={(e) => { this.openReservationDocumentsModel(e, data.data) }}></a>&nbsp;&nbsp;
                <a href="#" title="Edit Fare" onClick={(e) => { this.openEditFareModel(e, data.data) }}><i className={currencyIcon}></i></a>&nbsp;&nbsp;
            </div>;
        }

        const renderGridCurrencyCell = (data) => {
            return <div><i className={currencyIcon}></i>&nbsp;{parseFloat(data.value).toFixed(2)}</div>;
        }

        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-sm-6 col-md-6 col-lg-6">
                                <h1 className="m-0 text-dark">Unapprove Rides</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem>
                                        <NavLink tag={Link} to="/billing/approved" className="btn btn-block btn-outline-secondary btn-sm" title="Go to Approved Rides"><i className="fas fa-check"></i> Go to Approved Rides</NavLink>
                                    </NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <form method="post" onSubmit={this.handleSearchUnapproveRidesSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="rez-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>Selection</label>
                                        <select name="selectionId" className="form-control form-control-sm" value={this.state.selectionId} onChange={this.handleInputChange}>
                                            <option value="0">Select filter</option>
                                            {
                                                selectionData &&
                                                selectionData.map((item, i) =>
                                                    (<option key={i} value={item.id}>{item.name}</option>))
                                            }
                                        </select>
                                    </div>
                                </div>
                                {(this.state.selectionId == 3) && <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>{this.state.selectionName}</label>
                                        <SelectBox
                                            dataSource={this.state.customerList.length > 0 ? this.state.customerList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="customerId"
                                            defaultValue={0}
                                            value={this.state.customerId}
                                            displayExpr='fullName'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleCustomerInputChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerId"]}</span>}
                                    </div>
                                </div>}
                                {(this.state.selectionId == 1 || this.state.selectionId == 2 || this.state.selectionId == 4) && <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                                    <div className="form-group">
                                        <label>{this.state.selectionName}</label>
                                        <input type="text" className="form-control form-control-sm" name="selectionValue" maxLength="30" title="Enter filter value" value={this.state.selectionValue} onChange={this.handleInputChange} placeholder="" />
                                        {this.state.errors["selectionValue"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["selectionValue"]}</span>}
                                    </div>
                                </div>}
                                <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                                    <div className="form-group">
                                        <label>From Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDateTime"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.fromDateTime}
                                            value={this.state.fromDateTime}
                                            onValueChanged={this.handleFromValueChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                                    <div className="form-group">
                                        <label>To Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDateTime"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.toDateTime}
                                            value={this.state.toDateTime}
                                            onValueChanged={this.handleToValueChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>Customer Type</label>
                                        <select className="form-control form-control-sm" name="customerTypeId" placeholder="Customer Type" title="Customer Type" value={this.state.customerTypeId} onChange={this.handleInputChange}>
                                            <option value={0}>Select customer type</option>
                                            {this.state.customerTypeList && this.state.customerTypeList.map((item, i) =>
                                                <option key={i} value={item.id}>{item.name}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className={this.state.selectionId == 0 ? "col-lg-6 col-md-5 col-sm-5 col-xs-5" : "col-lg-12 col-md-5 col-sm-5 col-xs-5"} >
                                    <div className="form-group">
                                        <br />
                                        <button type="submit" className="btn btn-success btn-sm" onClick={this.handleSearchUnapproveRidesSubmit} >
                                            {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSubmited && <span title="Search"><i className="fas fa-search"></i> Search</span>}
                                        </button>&nbsp;&nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" disabled={isDisableAllButton} onClick={this.handleClearSearch} title="Clear"><i className="fas fa-eraser"></i> Clear</button>&nbsp;&nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" disabled={isDisableAllButton} onClick={this.handleApproveRide} title="Approve"><i className="fas fa-check"></i> Approve</button>&nbsp;&nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" disabled={isDisableAllButton} onClick={(e) => { this.handleToolModel(e, "Change Zone") }} title="Zone Tool"><i className="fas fa-map"></i> Zone Tool</button>&nbsp;&nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" disabled={isDisableAllButton} onClick={(e) => { this.handleToolModel(e, "Change Pax Details") }} title="Pax Details"><i className="fas fa-user"></i> Pax Details Tool</button>&nbsp;&nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" disabled={isDisableAllButton} onClick={(e) => { this.handleToolModel(e, "Change Vehicle Type") }} title="Change V Type"><i className="fas fa-car"></i> Change V Type</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="content">
                    <div className="card">
                        <div className="card-body">
                            <DataGrid
                                dataSource={this.state.unapproveRides}
                                keyExpr="rideId"
                                columnsAutoWidth={false}
                                showColumnLines={false}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                showBorders={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                width='100%'
                                onToolbarPreparing={this.onToolbarPreparing}
                                selectedRowKeys={this.state.selectedRowKeys}
                                onSelectionChanged={this.onSelectionChanged}
                                ref={ref => this.dataGrid = ref}>
                                <Selection
                                    mode="multiple"
                                    selectAllMode={"allPages"}
                                    showCheckBoxesMode={"always"}
                                />
                                <Column dataField="rideId" width={70} caption="" fixed={true} allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                <Column dataField="displayRideId" caption="Ride Id" fixed={true} width={100} allowFiltering={true} allowSorting={true} />
                                <Column dataField="fullName" caption="Pax Name" width={130} allowFiltering={true} allowSorting={true} />
                                <Column dataField="customerName" caption="Customer" width={120} allowFiltering={true} allowSorting={true} />
                                <Column dataField="pickupLocation" caption="PU Street" width={150} allowFiltering={true} allowSorting={true} />
                                <Column dataField="pickupZone" caption="PU Zone" width={100} allowFiltering={true} allowSorting={true} />
                                <Column dataField="dropoffLocation" caption="DO Street" width={150} allowFiltering={true} allowSorting={true} />
                                <Column dataField="dropoffZone" caption="DO Zone" width={100} allowFiltering={true} allowSorting={true} />
                                <Column dataField="pickupDate" caption="PU Date Time" width={130} allowFiltering={true} allowSorting={true} format={DateFormat.DateTime24Format} dataType="datetime" />
                                <Column dataField="apt" caption="Apt" width={80} allowFiltering={true} allowSorting={true} />
                                <Column dataField="adultNo" caption="Pax#" width={70} allowFiltering={true} allowSorting={true} />
                                <Column dataField="vehicleType" caption="Vehicle Type" width={120} allowFiltering={true} allowSorting={true} />
                                <Column dataField="dispatureName" caption="Dispatcher" width={100} allowFiltering={true} allowSorting={true} />
                                <Column dataField="vehicleNo" caption="Vehicle #" width={90} allowFiltering={true} allowSorting={true} />
                                <Column dataField="paymentType" caption="Payment Type" width={100} allowFiltering={true} allowSorting={true} />
                                <Column dataField="hasAttachment" caption="Has Attachment" width={80} allowFiltering={true} allowSorting={true} />

                                <Column dataField="baseFare" caption="Ride Fare" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="toComm" caption="Comms($)" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="tollAmount" caption="Toll Amt" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="tipAmount" caption="Tip Amt" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="fuelSurCharge" caption="Fuel Sur Chrg" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="stopCharge" caption="Stop" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="waitTotal" caption="Wait Chrg" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />

                                <Column dataField="portAuthority" caption="Port Auth" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="utilityTax" caption="Utility" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="wcTax" caption="WC Tax" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="salesTax" caption="Sales Tax" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="countyTax" caption="County Tax" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="congestionTax" caption="Congestion Tax" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="totalFare" caption="Net" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="dRideAmount" caption="D Ride Amnt" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="dNetAmount" caption="D Net Comms" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="tripStatus" caption="Ride Status" width={150} allowFiltering={true} allowSorting={true} />

                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                <Export enabled={true} allowExportSelectedData={false} />
                                <ColumnChooser enabled={true} />
                                <ColumnFixing enabled={true} />
                            </DataGrid>
                        </div>
                    </div>

                </div>

                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenToolModel}
                    style={customStyles}
                    className={"react-modal"}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleToolSubmit} style={this.state.isToolSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{this.state.toolTitle}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeTooleModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {(this.state.toolTitle === 'Change Vehicle Type') && <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Vehicle Type <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.vehicleTypeList.length > 0 ? this.state.vehicleTypeList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select vehicle type"
                                            name="vehicleTypeId"
                                            defaultValue={0}
                                            value={this.state.vehicleTypeId}
                                            displayExpr='apiVehicleTypeDescription'
                                            valueExpr='rowIndex'
                                            onSelectionChanged={this.handleVehicleTypeChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["vehicleTypeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["vehicleTypeId"]}</span>}
                                    </div>
                                </div>
                            </div>}
                            {(this.state.toolTitle === 'Change Zone') && <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Pick-up Zone <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.dropdownZone.length > 0 ? this.state.dropdownZone : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select pick-up zone"
                                            name="pickupZoneId"
                                            defaultValue={0}
                                            value={this.state.pickupZoneId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handlePickupZoneChange}
                                            showClearButton={false}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["pickupZoneId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["pickupZoneId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Drop Off Zone <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.dropdownZone.length > 0 ? this.state.dropdownZone : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select drop off zone"
                                            name="dropoffZoneId"
                                            defaultValue={0}
                                            value={this.state.dropoffZoneId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleDropoffZoneChange}
                                            showClearButton={false}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["dropoffZoneId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["dropoffZoneId"]}</span>}
                                    </div>
                                </div>
                            </div>}
                            {(this.state.toolTitle === 'Change Pax Details') && <div>
                                <div className="row">
                                    <div className="form-group">
                                        <label htmlFor="">&nbsp;</label>
                                        <span className="form-check">
                                            <input className="form-check-input" type="checkbox" checked={this.state.isCustomerCode} onChange={this.handleInputChange} name="isCustomerCode" />
                                            <label className="form-check-label text-blue">Customer Code</label>
                                        </span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label>Customer Code <span className="text-danger">*</span></label>
                                            <SelectBox
                                                dataSource={this.state.customerList.length > 0 ? this.state.customerList : []}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select customer"
                                                name="customerIdBillingTool"
                                                defaultValue={0}
                                                value={this.state.customerIdBillingTool}
                                                displayExpr='fullName'
                                                valueExpr='id'
                                                onSelectionChanged={this.handleCustomerBillingToolInputChange}
                                                showClearButton={false}
                                                disabled={this.state.isCustomerCode == false}
                                                searchEnabled={true}
                                            />
                                            {this.state.errors["customerIdBillingTool"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerIdBillingTool"]}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group">
                                        <label htmlFor="">&nbsp;</label>
                                        <span className="form-check">
                                            <input className="form-check-input" type="checkbox" checked={this.state.isPaxChange} onChange={this.handleInputChange} name="isPaxChange" />
                                            <label className="form-check-label text-blue">Pax Details</label>
                                        </span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <div className="form-group">
                                            <label>Passenger First Name <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control form-control-sm" maxLength={50} disabled={this.state.isPaxChange == false} value={this.state.paxFirstName} onChange={this.handleInputChange} name="paxFirstName" placeholder="Enter first name" />
                                            {this.state.errors["paxFirstName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["paxFirstName"]}</span>}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <div className="form-group">
                                            <label>Passenger Last Name <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control form-control-sm" maxLength={50} disabled={this.state.isPaxChange == false} value={this.state.paxLastName} onChange={this.handleInputChange} name="paxLastName" placeholder="Enter last name" />
                                            {this.state.errors["paxLastName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["paxLastName"]}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group">
                                        <label htmlFor="">&nbsp;</label>
                                        <span className="form-check">
                                            <input className="form-check-input" type="checkbox" checked={this.state.isPaxCountChange} onChange={this.handleInputChange} name="isPaxCountChange" />
                                            <label className="form-check-label text-blue">Pax Count</label>
                                        </span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3" style={{ display: (this.state.rideCustomerTypeId != 1 && this.state.rideCustomerTypeId != 2) ? 'block' : 'none' }}>
                                        <div className="form-group">
                                            <label>Total Pax Count <span className="text-danger">*</span></label>
                                            <NumericInput className="form-control form-control-sm" disabled={this.state.isPaxCountChange == false} name="noOfPax" title="Number of Pax" value={this.state.noOfPax} min={1} max={100} onChange={(value) => { this.handlePaxCountChange("a", value) }} />
                                            {this.state.errors["noOfPax"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["noOfPax"]}</span>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3" style={{ display: (this.state.rideCustomerTypeId == 1 || this.state.rideCustomerTypeId == 2) ? 'block' : 'none' }}>
                                        <div className="form-group">
                                            <label>Pilot <span className="text-danger">*</span></label>
                                            <NumericInput className="form-control form-control-sm" disabled={this.state.isPaxCountChange == false} name="pilotCount" title="Pilot Count" value={this.state.pilotCount} min={0} max={100} onChange={(value) => { this.handlePaxCountChange("p", value) }} />
                                            {this.state.errors["pilotCount"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["pilotCount"]}</span>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3" style={{ display: (this.state.rideCustomerTypeId == 1 || this.state.rideCustomerTypeId == 2) ? 'block' : 'none' }}>
                                        <div className="form-group">
                                            <label>Crew <span className="text-danger">*</span></label>
                                            <NumericInput className="form-control form-control-sm" disabled={this.state.isPaxCountChange == false} name="crewCount" title="Crew Count" value={this.state.crewCount} min={0} max={100} onChange={(value) => { this.handlePaxCountChange("c", value) }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3" style={{ display: (this.state.rideCustomerTypeId == 1 || this.state.rideCustomerTypeId == 2) ? 'block' : 'none' }}>
                                        <div className="form-group">
                                            <label>F/A <span className="text-danger">*</span></label>
                                            <NumericInput className="form-control form-control-sm" disabled={this.state.isPaxCountChange == false} name="flightAttandCount" title="Flight Attendant Count" value={this.state.flightAttandCount} min={0} max={100} onChange={(value) => { this.handlePaxCountChange("f", value) }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group">
                                        <label htmlFor="">&nbsp;</label>
                                        <span className="form-check">
                                            <input className="form-check-input" type="checkbox" checked={this.state.isOrderByChange} onChange={this.handleInputChange} name="isOrderByChange" />
                                            <label className="form-check-label text-blue">Order By</label>
                                        </span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <div className="form-group">
                                            <label>Order by <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control form-control-sm" maxLength={50} disabled={this.state.isOrderByChange == false} value={this.state.orderByName} onChange={this.handleInputChange} name="orderByName" placeholder="Order by" />
                                            {this.state.errors["orderByName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["orderByName"]}</span>}
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <div className="form-group">
                                            <label>Phone</label>
                                            <NumberFormat className="form-control form-control-sm" disabled={this.state.isOrderByChange == false} name="phoneNumberOrder" placeholder="(000) 000-0000" title="Phone Number" value={this.state.phoneNumberOrder} onChange={this.handleInputChange} format="###-###-####" />
                                            {this.state.errors["phoneNumberOrder"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["phoneNumberOrder"]}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success btn-sm" onClick={this.handleToolSubmit} >
                                {this.state.isToolSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isToolSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeTooleModal} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <ReservationHistoryComponent ref="reservationHistoryModal" />
                <DocumentsComponent ref="documentsComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >)
    }
}