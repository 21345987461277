import React, { Component } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1

import Modal from 'react-modal'; //npm install --save react-modal
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { LoadPanel } from 'devextreme-react/load-panel';
import { StatusType, DateFormat } from '../../helpers/fixcodes';
const customStyles = {
    content: {
        width: '70%'
    }
};
const position = { of: '#historydiv' };
let currencyIcon = sessionStorage.getItem("session_currencyIcon") === "" ? "fas fa-dollar-sign" : sessionStorage.getItem("session_currencyIcon");
const session_accountId = parseInt(sessionStorage.getItem("session_accountId"));
const session_mappedAccountId1 = parseInt(sessionStorage.getItem("session_mappedAccountId1"));
const session_mappedAccountId2 = parseInt(sessionStorage.getItem("session_mappedAccountId2"));
const session_mappedAccountId3 = parseInt(sessionStorage.getItem("session_mappedAccountId3"));

export class InvoiceRidesComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
            isSubmited: false,
            loadPanelVisible: false,
            datasource: [],
        }
        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    closeModal() {
        this.setState({
            isOpenModal: false,
            isSubmited: false,
            loadPanelVisible: false,
            datasource: [],
        });
    }

    async showModal(customerId, startDate, endDate, isApplyFromDate, invoiceNo) {
        this.setState({
            isOpenModal: true,
            loadPanelVisible: true,
            invoiceNo: invoiceNo,
        });

        await this.getInvoiceRides(customerId, startDate, endDate, isApplyFromDate, invoiceNo);
    }

    async getInvoiceRides(customerId, startDate, endDate, isApplyFromDate, invoiceNo) {
        try {
            this.setState({
                loadPanelVisible: true
            });

            var request = new Object();
            request.CustomerId = parseInt(customerId);
            request.StartDate = startDate;
            request.EndDate = endDate;
            request.InvoiceNo = parseInt(invoiceNo);
            request.IsApplyFromDate = isApplyFromDate;

            const requestParams = getRequestParams('POST', request);
            const response = await fetch('/invoice-api/rides', requestParams);
            const data = await response.json();

            this.setState({
                datasource: data.invoiceRides,
                loadPanelVisible: false
            });

        } catch (e) {
            console.log(e);
        }
    }

    render() {
        const renderGridCurrencyCell = (data) => {
            return <div><i className={currencyIcon}></i>&nbsp;{parseFloat(data.value).toFixed(2)}</div>;
        }

        const title = this.state.invoiceNo > 0 ? "Below are the invoice trip(s) of Invoice No [" + this.state.invoiceNo + "]." : "Below are the expected trip(s).";
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={customStyles}
                    className={"react-modal"}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form>
                        <div className="modal-header">
                            <h6 className="modal-title">Invoice Rides</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <small>{title}</small>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="widget-container">
                                        {
                                            (session_mappedAccountId1 !== session_accountId && session_mappedAccountId2 !== session_accountId) ?
                                                <DataGrid
                                                    dataSource={this.state.datasource}
                                                    keyExpr="invoiceNo"
                                                    columnsAutoWidth={false}
                                                    showColumnLines={false}
                                                    showRowLines={true}
                                                    showBorders={true}
                                                    rowAlternationEnabled={true}
                                                    allowColumnResizing={true}
                                                    columnResizingMode='widget'>

                                                    <Column dataField="reservationId" caption="ReservationId" width={100} allowFiltering={false} allowSorting={true} />
                                                    <Column dataField="rideId" caption="Ride Id" width={120} allowFiltering={false} allowSorting={true} />
                                                    <Column dataField="pickupDate" caption="PU Date Time" width={120} allowFiltering={false} allowSorting={true} format={DateFormat.DateTime24Format} dataType="datetime" />
                                                    <Column dataField="flightNo" caption="Flight No" width={120} allowFiltering={false} allowSorting={true} />
                                                    <Column dataField="paxName" caption="Pax Name" width={100} allowFiltering={false} allowSorting={true} />
                                                    <Column dataField="pickup" caption="Pickup" width={160} allowFiltering={true} allowSorting={true} />
                                                    <Column dataField="destination" caption="Destination" width={160} allowFiltering={true} allowSorting={true} />
                                                    <Column dataField="clubbedStatus" caption="Clubbing Status" width={160} allowFiltering={true} allowSorting={true}
                                                        visible={(session_mappedAccountId1 !== session_accountId && session_mappedAccountId2 !== session_accountId) ? false : true} />
                                                    <Column dataField="billingStatus" caption="Billing Status" width={160} allowFiltering={true} allowSorting={true}
                                                        visible={(session_mappedAccountId1 !== session_accountId && session_mappedAccountId2 !== session_accountId) ? false : true} />
                                                    <Column dataField="paymentType" caption="Payment Type" width={80} allowFiltering={false} allowSorting={true} />
                                                    <Column dataField="rideAmount" caption="Ride Amount" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                                    <Column dataField="tollAmount" caption="Toll Amount" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                                    <Column dataField="tipAmount" caption="Tip Amount" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                                    <Column dataField="stopTotal" caption="Stop Total" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                                    <Column dataField="waitTotal" caption="Wait Total" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                                    <Column dataField="ot" caption="Ot" width={100} allowFiltering={true} allowSorting={false} cellRender={renderGridCurrencyCell} />
                                                    <Column dataField="serviceFee" caption="Service Fee" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                                    <Column dataField="fuelSurcharge" caption="Fuel Surcharge" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                                    <Column dataField="parking" caption="Parking" width={100} allowFiltering={true} allowSorting={false} cellRender={renderGridCurrencyCell} />
                                                    <Column dataField="meetGreetCharge" caption="Meet Greet Charge" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                                    <Column dataField="tourGuidCharge" caption="Tour Guid Charge" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                                    <Column dataField="portAuthority" caption="Port Authority" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                                    <Column dataField="utilityTax" caption="Utility Tax" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                                    <Column dataField="wcTax" caption="Wc Tax" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                                    <Column dataField="salesTax" caption="Sales Tax" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                                    <Column dataField="countyTax" caption="County Tax" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                                    <Column dataField="congestionTax" caption="Congestion Tax" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                                    <Column dataField="extraAmount" caption="Extra Amount" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                                    <Column dataField="gross" caption="Gross Amount" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                                    <Column dataField="discount" caption="Discount" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                                    <Column dataField="totalFare" caption="Total Fare" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                                    <Column dataField="billableFare" caption="Billable Fare" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />

                                                    <SortByGroupSummaryInfo summaryItem="count" />
                                                    <GroupPanel visible={true} />
                                                    <HeaderFilter visible={true} />
                                                    <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Search..." />
                                                    <Grouping autoExpandAll={true} />
                                                    <Paging defaultPageSize={10} />
                                                    <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true} />
                                                    <Export enabled={true} allowExportSelectedData={false} />
                                                    <ColumnChooser enabled={true} />
                                                    <ColumnFixing enabled={true} />

                                                </DataGrid>
                                                :
                                                <DataGrid
                                                    dataSource={this.state.datasource}
                                                    keyExpr="invoiceNo"
                                                    columnsAutoWidth={false}
                                                    showColumnLines={false}
                                                    showRowLines={true}
                                                    showBorders={true}
                                                    rowAlternationEnabled={true}
                                                    allowColumnResizing={true}
                                                    columnResizingMode='widget'>

                                                    <Column dataField="reservationId" caption="ReservationId" width={100} allowFiltering={false} allowSorting={true} />
                                                    <Column dataField="rideId" caption="Ride Id" width={120} allowFiltering={false} allowSorting={true} />
                                                    <Column dataField="pickupDate" caption="PU Date Time" width={120} allowFiltering={false} allowSorting={true} format={DateFormat.DateTime24Format} dataType="datetime" />
                                                    <Column dataField="flightNo" caption="Flight No" width={120} allowFiltering={false} allowSorting={true} />
                                                    <Column dataField="paxName" caption="Pax Name" width={100} allowFiltering={false} allowSorting={true} />
                                                    <Column dataField="pickup" caption="Pickup" width={160} allowFiltering={true} allowSorting={true} />
                                                    <Column dataField="destination" caption="Destination" width={160} allowFiltering={true} allowSorting={true} />
                                                    <Column dataField="clubbedStatus" caption="Clubbing Status" width={160} allowFiltering={true} allowSorting={true}
                                                        visible={(session_mappedAccountId1 !== session_accountId && session_mappedAccountId2 !== session_accountId) ? false : true} />
                                                    <Column dataField="billingStatus" caption="Billing Status" width={160} allowFiltering={true} allowSorting={true}
                                                        visible={(session_mappedAccountId1 !== session_accountId && session_mappedAccountId2 !== session_accountId) ? false : true} />
                                                    <Column dataField="paymentType" caption="Payment Type" width={80} allowFiltering={false} allowSorting={true} />
                                                    <Column dataField="rideAmount" caption="Ride Amount" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                                    {/*<Column dataField="tollAmount" caption="Toll Amount" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />*/}
                                                    {/*<Column dataField="tipAmount" caption="Tip Amount" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />*/}
                                                    {/*<Column dataField="stopTotal" caption="Stop Total" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />*/}
                                                    {/*<Column dataField="waitTotal" caption="Wait Total" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />*/}
                                                    {/*<Column dataField="ot" caption="Ot" width={100} allowFiltering={true} allowSorting={false} cellRender={renderGridCurrencyCell} />*/}
                                                    {/*<Column dataField="serviceFee" caption="Service Fee" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />*/}
                                                    {/*<Column dataField="fuelSurcharge" caption="Fuel Surcharge" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />*/}
                                                    {/*<Column dataField="parking" caption="Parking" width={100} allowFiltering={true} allowSorting={false} cellRender={renderGridCurrencyCell} />*/}
                                                    {/*<Column dataField="meetGreetCharge" caption="Meet Greet Charge" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />*/}
                                                    {/*<Column dataField="tourGuidCharge" caption="Tour Guid Charge" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />*/}
                                                    {/*<Column dataField="portAuthority" caption="Port Authority" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />*/}
                                                    {/*<Column dataField="utilityTax" caption="Utility Tax" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />*/}
                                                    {/*<Column dataField="wcTax" caption="Wc Tax" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />*/}
                                                    {/*<Column dataField="salesTax" caption="Sales Tax" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />*/}
                                                    {/*<Column dataField="countyTax" caption="County Tax" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />*/}
                                                    {/*<Column dataField="congestionTax" caption="Congestion Tax" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />*/}
                                                    {/*<Column dataField="extraAmount" caption="Extra Amount" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />*/}
                                                    <Column dataField="gstTax" caption="GST Tax" width={80} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                                    <Column dataField="gross" caption="Gross Amount" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                                    {/*<Column dataField="discount" caption="Discount" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />*/}
                                                    <Column dataField="totalFare" caption="Total Fare" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                                    <Column dataField="billableFare" caption="Billable Fare" width={100} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />

                                                    <SortByGroupSummaryInfo summaryItem="count" />
                                                    <GroupPanel visible={true} />
                                                    <HeaderFilter visible={true} />
                                                    <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Search..." />
                                                    <Grouping autoExpandAll={true} />
                                                    <Paging defaultPageSize={10} />
                                                    <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true} />
                                                    <Export enabled={true} allowExportSelectedData={false} />
                                                    <ColumnChooser enabled={true} />
                                                    <ColumnFixing enabled={true} />
                                                </DataGrid>
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            {(this.state.isButtonShow == true) && <button type="button" className="btn btn-success btn-sm" visible={false} onClick={this.continueReservation}><i className="fas fa-check"></i> Continue</button>}

                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeModal}><i className="fas fa-times"></i> Cancel</button>
                        </div>
                    </form>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}