import React, { Component } from 'react';
import DataGrid, {
    Column,
    Editing,
    Selection,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1

import Modal from 'react-modal'; //npm install --save react-modal
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import DateBox from 'devextreme-react/date-box';
//import Moment from 'react-moment'; 
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import { MasterHistoryComponent } from '../Shared/MasterHistoryComponent';
import { CommissionSettingComponent } from './CommissionSettingComponent';
import { VehicleListComponent } from './VehicleListComponent';
import { VehicleDeductionListComponent } from './VehicleDeductionListComponent';
import { SetVehicleDepositComponent } from '../Shared/SetVehicleDepositComponent';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { LoadPanel } from 'devextreme-react/load-panel';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { SelectBox } from 'devextreme-react/select-box';

export const paymentModeDropdownList = [{
    'id': 0,
    'name': 'CHECK',
}, {
    'id': 1,
    'name': 'ACH',
}]
export class VehicleOwnerListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isGotoVehicleComponent: false,
            isGotoVehicleDeductionComponent: false,
            isOwnerModalOpen: false,
            isOwnerTerminateModalOpen: false,
            isCarryForwardDedModalOpen: false,
            isOwnerViewOny: false,
            isSubmited: false,
            loadPanelVisible: false,
            loadPanelMessage: 'Loading...',
            vehicleModalIsOpen: false,
            vehicleOwnerGridDataSource: null,
            vehicleOwnerData: null,
            dropdownOwner: [],
            dropdownOwnerList: [],
            dropdownPaymentMode: [],
            vehicleDeductionsCarryForwardList: null,
            vehicleDeductionsGridDataSource: null,
            vehicleOwnerMapId: 0,
            ownerId: 0,
            ownerName: '',
            email: '',
            paymentModeId: 0,
            paymentMode: '',
            wefDate: '',
            franchiseeEndDate: '',
            franchiseeEndComment: '',
            isBalanceCarryForward: false,
            isActive: false,
            showTerminatedFrachiseeDate: false,
            vehicleCurrentPayPeriod: {
                payrollType: 0,
                payEndDate: '',
                isCurrentCyleClosed: false,
                isPayPeriodExist: false,
                rdoCurrentCycle: false,
                rdoNextCycle: false,
                rdoSelectCycle: false,
                lblCurrentCycleDate: '',
                lblNextCycleDate: '',
                lblSelectedCycleDate: '',
            },
            selectCycleDate: {
                minDate: new Date(),
                value: new Date(),
            },
            errors: {
                ownerName: '',
                email: '',
                paymentMode: '',
            },
            terminateOwner: {
                terminatedDate: moment(new Date()).format("MM/DD/YYYY"),
                terminatedComment: '',
            },
            terminateOwnerErrors: {
                terminatedDate: '',
                terminatedComment: '',
            },
            dedGridFilterOpt: {
                showDedRecurringOnly: false,
                showDedActiveOnly: false
            }
        };
        this.handleToolbarPreparing = this.handleToolbarPreparing.bind(this);
        this.getVehicleOwnerList = this.getVehicleOwnerList.bind(this);
    }
    componentDidMount() {
        this.fetchApiData();
    }
    getVehicleOwnerList = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            var request = new Object();
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('vehicle-api/' + this.props.vehicleId + '/owners', requestParams);
            const data = await response.json();
            this.setState({
                vehicleOwnerGridDataSource: data.owners
            });
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    };
    getVehicleCurrentPayPeriodDetail = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('pay-period-api/' + 1 + '/dropdown-list', requestParams);
            if (response.ok) {
                const data = await response.json();
                if (data.isSuccess) {
                    let vehicleCurrentPayPeriodList = JSON.parse(JSON.stringify(data.vehiclePayPeriods));
                    if (vehicleCurrentPayPeriodList !== null) {
                        let vehicleCurrentPayPeriod = this.state.vehicleCurrentPayPeriod;
                        if (vehicleCurrentPayPeriodList.length > 0) {
                            vehicleCurrentPayPeriod.isPayPeriodExist = true;
                            vehicleCurrentPayPeriod.payrollType = vehicleCurrentPayPeriodList[0].payrollType;
                            vehicleCurrentPayPeriod.payEndDate = vehicleCurrentPayPeriodList[0].payEndDate;
                            vehicleCurrentPayPeriod.isCurrentCyleClosed = vehicleCurrentPayPeriodList[0].isClosed;
                            if (vehicleCurrentPayPeriodList[0].payEndDate === null || vehicleCurrentPayPeriodList[0].payEndDate.trim().length === 0) {
                                vehicleCurrentPayPeriod.isPayPeriodExist = false;
                            }
                        }
                        else {
                            vehicleCurrentPayPeriod.isPayPeriodExist = false;
                        }
                        this.setState({
                            vehicleCurrentPayPeriod: vehicleCurrentPayPeriod
                        });
                    }
                }
            }
            //this.setReimbursementCycleOption();
        } catch (ex) {
            console.log(ex);
        }
    }
    getOwnerDropdown = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('vehicle-owner-api/owner-dropdown-list', requestParams);
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    dropdownOwnerList: data.owners
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    getPaymentModeDropdown = async () => {
        this.setState({ dropdownPaymentMode: paymentModeDropdownList });
    }
    getVehicleDeductionsCarryForward = async () => {
        try {
            var request = new Object();
            let dedGridFilterOpt = this.state.dedGridFilterOpt;
            dedGridFilterOpt.showDedActiveOnly = false;
            dedGridFilterOpt.showDedRecurringOnly = false;
            this.setState({ dedGridFilterOpt: dedGridFilterOpt });
            request.VehicleId = Number(this.props.vehicleId);
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('vehicle-owner-api/vehicle-deduction', requestParams);
            if (response.ok) {
                const data = await response.json();
                if (data.isSuccess) {
                    this.setState({
                        vehicleDeductionsCarryForwardList: data.vehicleDeductionCarryForward,
                        vehicleDeductionsGridDataSource: data.vehicleDeductionCarryForward
                    });
                }
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    fetchApiData = async () => {
        try {
            await this.getVehicleOwnerList();
            this.setState({ loadPanelVisible: true });
            await this.getVehicleCurrentPayPeriodDetail();
            await this.getOwnerDropdown();
            await this.getPaymentModeDropdown();
            await this.getVehicleDeductionsCarryForward();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    setReimbursementCycleOption = () => {
        try {
            let vehicleCurrentPayPeriod = this.state.vehicleCurrentPayPeriod;
            let selectCycleDate = this.state.selectCycleDate;
            if (vehicleCurrentPayPeriod.isPayPeriodExist) {

                let dtCurrentCycleDate = moment(vehicleCurrentPayPeriod.payEndDate, "YYYYMMDD").format('MM/DD/YYYY');
                let dtNextCycle = null;
                if (moment(dtCurrentCycleDate, "MM/DD/YYYY").format("D") <= 15) {
                    dtNextCycle = moment(moment(dtCurrentCycleDate, "MM/DD/YYYY").format("YYYY-MM-") + moment(dtCurrentCycleDate, "MM/DD/YYYY").daysInMonth()).format("MM/DD/YYYY");
                    if (moment(dtCurrentCycleDate).format("D") < 15) {
                        dtCurrentCycleDate = moment(moment(dtCurrentCycleDate, "MM/DD/YYYY").format("YYYY-MM-") + "15").format("MM/DD/YYYY");
                    }
                }
                else {
                    let dtTempNextMonth = moment(dtCurrentCycleDate, "MM/DD/YYYY").add(1, 'months'); // Year was not changed on 31Dec of current current cycle in below commented code
                    let strDate = dtTempNextMonth.format('M') + "/" + "15" + "/" + dtTempNextMonth.format('YYYY');
                    dtNextCycle = moment(strDate, "MM/DD/YYYY");
                }
                if (dtNextCycle !== null) {
                    vehicleCurrentPayPeriod.lblCurrentCycleDate = moment(dtCurrentCycleDate).format("MMM DD,YY");
                    vehicleCurrentPayPeriod.lblNextCycleDate = moment(dtNextCycle).format("MMM DD,YY");
                }
                let dtSelectedDateMin = null;
                if (moment(dtNextCycle, "MM/DD/YYYY").format("D") <= 15) {
                    dtSelectedDateMin = moment(moment(dtNextCycle, "MM/DD/YYYY").format("YYYY-MM-") + moment(dtNextCycle, "MM/DD/YYYY").daysInMonth()).format("MM/DD/YYYY");
                }
                else {
                    let dtTempNextMonth = moment(dtNextCycle, "MM/DD/YYYY").add(1, 'months'); // Year was not changed on 31Dec of current current cycle in below commented code
                    dtSelectedDateMin = moment(moment(dtTempNextMonth, "MM/DD/YYYY").format("YYYY-MM-") + "15").format("MM/DD/YYYY");
                }
                if (dtSelectedDateMin !== null) {
                    selectCycleDate.minDate = dtSelectedDateMin;
                    selectCycleDate.value = dtSelectedDateMin;
                    vehicleCurrentPayPeriod.lblSelectedCycleDate = moment(selectCycleDate.value).format("MMM DD,YY");
                }

                vehicleCurrentPayPeriod.rdoCurrentCycle = false;
                vehicleCurrentPayPeriod.rdoNextCycle = true;
                vehicleCurrentPayPeriod.rdoSelectCycle = false;

            }
            else {
                vehicleCurrentPayPeriod.rdoCurrentCycle = false;
                vehicleCurrentPayPeriod.rdoNextCycle = false;
                vehicleCurrentPayPeriod.rdoSelectCycle = true;

                let cDate = moment(this.state.selectCycleDate.value).format("MM/DD/YYYY");
                let cDateCycle = null;
                if (moment(cDate, "MM/DD/YYYY").format("D") <= 15) {
                    cDateCycle = moment(moment(cDate, "MM/DD/YYYY").format("YYYY-MM-") + "15").format("MM/DD/YYYY");
                }
                else {
                    cDateCycle = moment(moment(cDate, "MM/DD/YYYY").format("YYYY-MM-") + moment(cDate, "MM/DD/YYYY").daysInMonth()).format("MM/DD/YYYY");
                }
                if (cDateCycle !== null) {
                    vehicleCurrentPayPeriod.lblSelectedCycleDate = moment(cDateCycle, "MM/DD/YYYY").format("MMM DD,YY");
                }
            }

            this.setState({
                vehicleCurrentPayPeriod: vehicleCurrentPayPeriod,
                selectCycleDate: selectCycleDate
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getVehicleOwnerList.bind(this)
            }
        });
    }
    handleOnCellPrepared = (e) => {
        if (e.rowType === "data" && e.column.dataField !== "id") {
            if (e.data.enable === true) {
                //e.cellElement.bgColor = "lightgreen"; //"#D79100";
                e.cellElement.style.backgroundColor = "lightgreen";
            }
            else {
                e.cellElement.style.color = "red";
            }
        }
        //if (e.rowType === "data" && e.column.dataField === "id") {
        //    e.cellElement.style.color = e.data.Amount >= 10000 ? "green" : "red";
        //    // Tracks the `Amount` data field
        //    e.watch(function () {
        //        return e.data.Amount;
        //    }, function () {
        //        e.cellElement.style.color = e.data.Amount >= 10000 ? "green" : "red";
        //    })
        //}
    }
    //handleOnRowPrepared = (e) => {
    //    if (e.rowType === "data") {
    //        if (e.columns[e.dataIndex].dataField !== "id") {
    //            if (e.data !== null && e.data.enable === true) {
    //                e.rowElement.bgColor = "green";
    //            }    
    //        }
    //    }
    //}
    handleAddVehicleOwner = (e) => {
        e.preventDefault();
        let dropdownOwner = this.state.dropdownOwnerList.filter(a => a.isActive === true);
        dropdownOwner = JSON.parse(JSON.stringify(dropdownOwner));
        this.setState({
            isOwnerModalOpen: true,
            vehicleOwnerData: null,
            dropdownOwner: dropdownOwner,
        });
        this.setReimbursementCycleOption();
    }
    handleEditViewVehicleOwner = (e, data) => {
        e.preventDefault();
        if (data !== null) {
            let dropdownOwner = this.state.dropdownOwnerList;
            dropdownOwner = JSON.parse(JSON.stringify(this.state.dropdownOwnerList));
            this.setState({
                isOwnerModalOpen: true,
                vehicleOwnerData: data,
                dropdownOwner: dropdownOwner,
            });
        }
        this.setReimbursementCycleOption();
    }
    handleTerminateFranchisee(e, data) {
        e.preventDefault();
        if (data !== null) {
            this.setState({
                isOwnerTerminateModalOpen: true,
                vehicleOwnerData: data
            });
        }
    }
    handleViewSetCommission(e, data) {
        e.preventDefault();
        this.refs.vehicleOwnerCommSettingComponent.handleShowModal("Vehicle Onwer Commission Setting", 'VOC', data.vehicleOwnerName, data.id === null ? 0 : Number(data.id), this.props.vehicleId, this.props.vehicleNumber);
    }
    handleGotoDeductionComponent(e, data) {
        e.preventDefault();
        this.setState({
            isGotoVehicleDeductionComponent: true,
            isActive: data.enable || false,
            vehicleOwnerMapId: data.id === null ? 0 : Number(data.id),
            ownerId: data.vehicleOwnerId === null ? 0 : Number(data.vehicleOwnerId),
            ownerName: data.vehicleOwnerName === null ? '' : data.vehicleOwnerName.trim()
        });
    }
    handleVehicleDeposit = async (e, data) => {
        e.preventDefault();
        try {
            await this.refs.setVehicleDepositComponent.handleShowModal(true, false, false, data.id === null ? 0 : Number(data.id), 0, this.props.vehicleId, this.props.vehicleNumber);
        } catch (ex) {
            console.log(ex);
        }
    }
    handleMasterHistoryModal(e, data) {
        e.preventDefault();
        this.refs.masterHistoryComponent.showModal('vehicle-api', "Vehicle Owner", this.props.vehicleNumber, Number(this.props.vehicleId));  //Note: Onwer history should be in vehicle reference
    }
    handleAssignDataToState = async () => {
        if (this.state.vehicleOwnerData === null) {
            this.setState({
                vehicleOwnerMapId: 0,
                ownerId: 0,
                ownerName: '',
                email: '',
                paymentModeId: 0,
                paymentMode: '',
                wefDate: '',
                franchiseeEndDate: '',
                franchiseeEndComment: '',
                isBalanceCarryForward: false,
                isActive: false,
                showTerminatedFrachiseeDate: false,
                terminateOwner: {
                    terminatedDate: moment(new Date()).format("MM/DD/YYYY"),
                    terminatedComment: '',
                },
            });
        } else {
            const owner = this.state.vehicleOwnerData;
            this.setState({
                vehicleOwnerMapId: owner.id === null ? 0 : Number(owner.id),
                ownerId: owner.vehicleOwnerId === null ? 0 : Number(owner.vehicleOwnerId),
                ownerName: owner.vehicleOwnerName === null ? '' : owner.vehicleOwnerName.trim(),
                email: owner.achEmailId === null ? '' : owner.achEmailId.trim(),
                paymentModeId: owner.vehiclePaymentModeId === null ? 0 : Number(owner.vehiclePaymentModeId),
                paymentMode: owner.vehiclePaymentModeDesc === null ? '' : owner.vehiclePaymentModeDesc.trim(),
                wefDate: owner.wefDate === null ? '' : owner.wefDate.trim(),
                franchiseeEndDate: owner.feDate === null ? '' : owner.feDate,
                franchiseeEndComment: owner.fecomment === null ? '' : owner.fecomment,
                isBalanceCarryForward: owner.isBalanceCarryForward || false,
                isActive: owner.enable || false,
                //showTerminatedFrachiseeDate: (this.state.isOwnerViewOny && owner.franchiseeEndDate !== '' && owner.franchiseeEndDate !== null)
            });
            let showTerminatedDate = false;
            if (this.state.isOwnerViewOny && owner.feDate !== '' && owner.feDate !== null) {
                showTerminatedDate = true;
            }
            this.setState({
                showTerminatedFrachiseeDate: showTerminatedDate
            });
        }
        if (this.state.isOwnerModalOpen) {
            this.submitOwnerButtonValidator.focus();
        }
    }
    handleResetStateData = async () => {
        this.setState({
            isGotoVehicleComponent: false,
            isGotoVehicleDeductionComponent: false,
            isOwnerModalOpen: false,
            isOwnerTerminateModalOpen: false,
            isOwnerViewOny: false,
            isSubmited: false,
            loadPanelVisible: false,
            loadPanelMessage: 'Loading...',
            vehicleModalIsOpen: false,
            vehicleOwnerMapId: 0,
            ownerId: 0,
            ownerName: '',
            email: '',
            paymentModeId: 0,
            paymentMode: '',
            wefDate: '',
            franchiseeEndDate: '',
            franchiseeEndComment: '',
            isBalanceCarryForward: false,
            isActive: false,
            showTerminatedFrachiseeDate: false,
            errors: {
                ownerName: '',
                email: '',
                paymentMode: '',
            },
            terminateOwner: {
                terminatedDate: moment(new Date()).format("MM/DD/YYYY"),
                terminatedComment: '',
            },
            terminateOwnerErrors: {
                terminatedDate: '',
                terminatedComment: '',
            },
            dedGridFilterOpt: {
                showDedRecurringOnly: false,
                showDedActiveOnly: false
            }
        });
    }
    handleOnValueChangedDateBox = (event) => {
        const value = moment(event.value).format("MM/DD/YYYY");
        if (this.state.isOwnerTerminateModalOpen) {
            let terminate = this.state.terminateOwner;
            terminate.terminatedDate = value;
            this.setState({
                terminateOwner: terminate
            });
        }
        else {
            let vehicleCurrentPayPeriod = this.state.vehicleCurrentPayPeriod;
            let selectCycleDate = this.state.selectCycleDate;
            let dtSelectedDateMin = null;
            if (moment(value, "MM/DD/YYYY").format("D") <= 15) {
                dtSelectedDateMin = moment(moment(value, "MM/DD/YYYY").format("YYYY-MM-") + "15").format("MM/DD/YYYY");
            }
            else {
                dtSelectedDateMin = moment(moment(value, "MM/DD/YYYY").format("YYYY-MM-") + moment(value, "MM/DD/YYYY").daysInMonth()).format("MM/DD/YYYY");
            }
            if (dtSelectedDateMin !== null) {
                vehicleCurrentPayPeriod.lblSelectedCycleDate = moment(dtSelectedDateMin, "MM/DD/YYYY").format("MMM DD,YY");
                selectCycleDate.value = value;
                this.setState({
                    vehicleCurrentPayPeriod: vehicleCurrentPayPeriod,
                    selectCycleDate: selectCycleDate
                });
            }
        }
    }
    handleInputChange = (event) => {
        let errors = this.state.errors;
        const target = event.target;
        const name = target.name;
        switch (name) {
            case 'terminatedComment':
                let terminateErrors = this.state.terminateOwnerErrors;
                terminateErrors.terminatedComment = '';
                if (target.value.trim() === '') {
                    terminateErrors.terminatedComment = 'Please enter comment.';
                }
                this.setState({ terminateOwnerErrors: terminateErrors });

                let terminate = this.state.terminateOwner;
                terminate.terminatedComment = target.value;
                this.setState({
                    terminateOwner: terminate
                });
                break;
            case 'ownerName':
                if (Number(target.value) > 0) {
                    errors.ownerName = '';
                }
                else {
                    errors.ownerName = 'Please select owner.';
                }
                this.setState({
                    ownerId: target.value,
                    ownerName: target.options[target.selectedIndex].text
                });
                break;
            case 'email':
                errors.email = '';
                if (target.value.trim() === '') {
                    errors.email = 'Please enter email.';
                }
                else {
                    const regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if (regEmail.test(String(target.value.trim()).toLowerCase()) === false) {
                        errors.email = 'Please enter valid email.';
                    }
                }
                this.setState({ email: target.value });
                break;
            case 'paymentMode':
                errors.paymentMode = '';
                if (Number(target.value) < 0) {
                    errors.paymentMode = 'Please select payment mode.';
                }
                this.setState({
                    paymentModeId: target.value,
                    paymentMode: target.options[target.selectedIndex].text
                });
                break;
            default:
                const id = target.id;
                let vehicleCurrentPayPeriod = this.state.vehicleCurrentPayPeriod;
                vehicleCurrentPayPeriod.rdoCurrentCycle = false;
                vehicleCurrentPayPeriod.rdoNextCycle = false;
                vehicleCurrentPayPeriod.rdoSelectCycle = false;
                switch (id) {
                    case "rdoCurrentCycle":
                        vehicleCurrentPayPeriod.rdoCurrentCycle = true;
                        break;
                    case "rdoNextCycle":
                        vehicleCurrentPayPeriod.rdoNextCycle = true;
                        break;
                    case "rdoSelectCycle":
                        vehicleCurrentPayPeriod.rdoSelectCycle = true;
                        break;
                    default:
                }
                this.setState({
                    vehicleCurrentPayPeriod: vehicleCurrentPayPeriod,
                });
                break;
        }
        this.setState({ errors: errors });
    }
    handleOwnerOnChange = (selectedOption) => {
        try {
            debugger;
            let errors = this.state.errors;
            errors.ownerName = '';            
            let optOwnerId = (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0);
            let optOwnerName = (selectedOption.selectedItem != null ? selectedOption.selectedItem.name : "");            
            errors.ownerName = parseInt(optOwnerId) === 0 ? 'Please select owner.' : '';
            this.setState({ errors: errors, ownerId: optOwnerId, ownerName: optOwnerName });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleVehicleOwnerSubmit = async event => {
        event.preventDefault();
        try {
            if (this.isFormVehicleOwnerAddEditValid()) {
                let vehDedInCurrentPayCycle = null;
                let loadPanelMsg = null;
                if (Number(this.state.vehicleOwnerMapId) === 0) {
                    if (this.state.vehicleDeductionsCarryForwardList !== null) {
                        if (this.state.vehicleDeductionsCarryForwardList.length > 0) {
                            let isResult = false;
                            await Swal.fire({
                                icon: 'question',
                                title: '<small><b>Confirmation</b></small>',
                                html: "<span style='font-size: 20px;'>Pending deduction is found for the previous vehicle owner.</span><br>Are you sure want to carry forward with new owner?",
                                //showCloseButton: true,
                                showCancelButton: true,
                                focusConfirm: false,
                                confirmButtonText: "<i class='fa fa-thumbs-up'></i> <span style='font-size:14px;'>Yes, Carry forward deduction to owner!</span>",
                                cancelButtonText: 'No',
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                dangerMode: false,
                                allowOutsideClick: false
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    isResult = true;
                                }
                            });
                            if (isResult) {
                                this.setState({ isCarryForwardDedModalOpen: true });
                            }
                            else {
                                /*NOTE: Current cycle pending deductions are mandatory to move with new onwer, if found.*/
                                if (this.state.vehicleCurrentPayPeriod.rdoCurrentCycle) {
                                    vehDedInCurrentPayCycle = this.state.vehicleDeductionsCarryForwardList.filter(d => d.deductionInPay === true && parseInt(d.vehiclePayDeductionId) > 0);
                                    let vdCycle = [];
                                    if (vehDedInCurrentPayCycle !== null) {
                                        if (vehDedInCurrentPayCycle.length > 0) {
                                            vehDedInCurrentPayCycle.forEach((item) => {
                                                if (item.checkRow === false) {
                                                    item.checkRow = true;
                                                }
                                                vdCycle.push(item);
                                            });
                                            loadPanelMsg = 'Carry forwarding default ded of previous owner. Please, wait...';
                                        }
                                    }
                                    vehDedInCurrentPayCycle = vdCycle;
                                }
                                await this.saveVehicleOwner(vehDedInCurrentPayCycle, loadPanelMsg);
                            }
                        }
                        else {
                            await this.saveVehicleOwner(vehDedInCurrentPayCycle, loadPanelMsg);
                        }
                    }
                    else {
                        await this.saveVehicleOwner(vehDedInCurrentPayCycle, loadPanelMsg);
                    }
                }
                else {
                    await this.saveVehicleOwner(vehDedInCurrentPayCycle, loadPanelMsg);
                }
            }
        } catch (ex) {
            console.log(ex);
            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Unable to process the request.',
            })
        }
    }
    saveVehicleOwner = async (vehDedInCurrentPayCycle, loadPanelMsg) => {
        try {
            this.setState({ isSubmited: true });

            let strWefDate = '';
            if (this.state.vehicleCurrentPayPeriod.rdoCurrentCycle) {
                strWefDate = this.state.vehicleCurrentPayPeriod.lblCurrentCycleDate;
            }
            else if (this.state.vehicleCurrentPayPeriod.rdoNextCycle) {
                strWefDate = this.state.vehicleCurrentPayPeriod.lblNextCycleDate;
            }
            else if (this.state.vehicleCurrentPayPeriod.rdoSelectCycle) {
                strWefDate = this.state.vehicleCurrentPayPeriod.lblSelectedCycleDate;
            }
            else {
                let cDAte = new Date();
                let dtNewCycleDate = null;
                if (moment(cDAte, "MM/DD/YYYY").format("D") <= 15) {
                    dtNewCycleDate = moment(moment(cDAte, "MM/DD/YYYY").format("YYYY-MM-") + "15").format("MM/DD/YYYY");
                }
                else {
                    dtNewCycleDate = moment(moment(cDAte, "MM/DD/YYYY").format("YYYY-MM-") + moment(cDAte, "MM/DD/YYYY").daysInMonth()).format("MM/DD/YYYY");
                }
                strWefDate = moment(dtNewCycleDate, "MM/DD/YYYY").format("MMM DD,YY");
            }

            var request = new Object();
            request.VehicleId = Number(this.props.vehicleId);
            request.OwnerId = Number(this.state.ownerId);
            request.Email = this.state.email.trim();
            request.PaymentModeId = Number(this.state.paymentModeId);
            request.WefDate = moment(strWefDate, "MMM DD,YY").format("MM/DD/YYYY");
            request.IsBalanceCarryForward = this.state.isBalanceCarryForward;
            request.AddEditHistory = this.setAddEditVehicleOwnerHistory();
            const requestParams = getRequestParams('POST', request);
            let apiUrl = 'vehicle-owner-api';
            if (Number(this.state.vehicleOwnerMapId) > 0) {
                apiUrl += '/' + Number(this.state.vehicleOwnerMapId);
            }
            const response = await fetch(apiUrl, requestParams);
            const data = await response.json();
            await Swal.fire({
                allowOutsideClick: false,
                icon: data.isSuccess ? 'success' : 'error',
                title: data.isSuccess ? 'Success' : 'Oops...',
                text: (data.isSuccess === false && (data.resultMessage === null || data.resultMessage === "")) ? 'Unable to process the request.' : (data.resultMessage === null || data.resultMessage === "") ? 'This vehicle owner has been ' + ((Number(this.state.vehicleOwnerMapId) > 0) ? 'updated' : 'added') + ' successfully.' : data.resultMessage,
            }).then(async (result) => {
                if (data.isSuccess) {
                    if (Number(this.state.vehicleOwnerMapId) === 0 && vehDedInCurrentPayCycle != null) {
                        if (vehDedInCurrentPayCycle.length > 0) {
                            await this.saveCarryForwarDeduction(vehDedInCurrentPayCycle, loadPanelMsg);
                        }
                    }
                    if (result.isConfirmed) {
                        await this.handleResetStateData();
                        await this.getVehicleOwnerList();
                        this.setState({ loadPanelVisible: true });
                        await this.getVehicleDeductionsCarryForward();
                        this.setState({ loadPanelVisible: false });
                    }
                } else {
                    if (this.state.vehicleDeductionsCarryForwardList !== null) {
                        if (this.state.vehicleDeductionsCarryForwardList.length > 0) {
                            let vdCycle = [];
                            this.state.vehicleDeductionsCarryForwardList.forEach((item) => {
                                if (item.deductionInPay === true && parseInt(item.vehiclePayDeductionId) > 0) {
                                    item.checkRow = true;
                                }
                                else {
                                    item.checkRow = false;
                                }
                                vdCycle.push(item);
                            });
                            this.setState({ vehicleDeductionsGridDataSource: vdCycle });
                        }
                    }
                }
            })
        } catch (ex) {
            console.log(ex);
            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Unable to process the request.',
            })
        }
        this.setState({ isSubmited: false, loadPanelMessage: 'Loading...' })
    }
    isFormVehicleOwnerAddEditValid() {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            let isFocus = false;
            errors.ownerName = '';
            errors.email = '';
            errors.paymentMode = '';
            if (Number(this.state.ownerId) === 0 || this.ownerNameSelectValidator.instance.option('selectedItem') === null) {
                formIsValid = false;
                errors.ownerName = 'Please select owner.';
                if (isFocus === false) { this.ownerNameSelectValidator.instance.focus(); isFocus = true; }
            }
            if (this.state.email.trim() === '') {
                formIsValid = false;
                errors.email = 'Please enter email.';
                if (isFocus === false) { this.emailInputValidator.focus(); isFocus = true; }
            }
            else {
                const regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (regEmail.test(String(this.state.email.trim()).toLowerCase()) === false) {
                    formIsValid = false;
                    errors.email = 'Please enter valid email.';
                    if (isFocus === false) { this.emailInputValidator.focus(); isFocus = true; }
                }
            }
            if (Number(this.state.paymentModeId) < 0 || Number(this.paymentModeSelectValidator.value) < 0) {
                formIsValid = false;
                errors.paymentMode = 'Please select payment mode.';
                if (isFocus === false) { this.paymentModeSelectValidator.focus(); isFocus = true; }
            }
            if (isFocus === false) { this.submitOwnerButtonValidator.focus(); }
            this.setState({ errors: errors });
        } catch (ex) {
            formIsValid = false;
            console.log(ex);
        }
        return formIsValid;
    }
    setAddEditVehicleOwnerHistory() {
        let history = '';
        try {
            let sep = '. ';
            let oldData = this.state.vehicleOwnerData;
            if (Number(this.state.vehicleOwnerMapId === 0)) {
                history = 'New vehicle owner [' + this.state.ownerName + '] is added' + sep;
            }
            else {
                if (Number(oldData.vehicleOwnerId) !== Number(this.state.ownerId)) {
                    if (Number(oldData.vehicleOwnerId) > 0 && Number(this.state.ownerId) > 0) {
                        history += 'Onwer is changed from ' + oldData.vehicleOwnerName + ' To ' + this.state.ownerName.trim() + sep;
                    }
                    else {
                        history += 'Onwer is updated ' + this.state.ownerName.trim() + sep;
                    }
                }
                let oldachEmailId = oldData.achEmailId === null ? '' : oldData.achEmailId.trim();
                if (oldachEmailId !== this.state.email.trim()) {
                    if (oldachEmailId.length > 0 && this.state.email.trim().length > 0) {
                        history += 'ACH email is changed from ' + oldachEmailId + ' To ' + this.state.email.trim() + sep;
                    }
                    else {
                        history += 'ACH email is updated ' + this.state.email.trim() + sep;
                    }
                }

                if (Number(oldData.vehiclePaymentModeId) !== Number(this.state.paymentModeId)) {
                    if (Number(oldData.vehiclePaymentModeId) >= 0 && Number(this.state.paymentModeId) >= 0) {
                        history += 'Payment mode is changed from ' + oldData.vehiclePaymentModeDesc + ' To ' + this.state.paymentMode.trim() + sep;
                    }
                    else {
                        history += 'Payment mode is updated ' + this.state.paymentMode.trim() + sep;
                    }
                }
                if (oldData.isBalanceCarryForward !== this.state.isBalanceCarryForward) {
                    history += 'Balance carry forward on next payout is ' + (this.state.isBalanceCarryForward ? 'activated' + sep : 'deactivated' + sep);
                }
                if (history === '') {
                    history += 'Vehicle onwer [' + this.state.ownerName + '] has been successfully updated' + sep;
                }
            }
        } catch (ex) {
            history = '';
            console.log(ex);
        }
        return history;
    }
    handleVehicleOwnerTerminateSubmit = async event => {
        event.preventDefault();
        try {
            if (this.isFormVehicleOwnerTerminateValid()) {
                this.setState({ isSubmited: true });
                var request = new Object();
                request.TerminatedDate = this.state.terminateOwner.terminatedDate;
                request.TerminatedComment = this.state.terminateOwner.terminatedComment;
                request.AddEditHistory = 'Owner [ ' + this.state.ownerName + ' ] has been terminated.'
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('vehicle-owner-api/' + Number(this.state.vehicleOwnerMapId) + '/terminate', requestParams);
                const data = await response.json();
                Swal.fire({
                    allowOutsideClick: false,
                    icon: data.isSuccess ? 'success' : 'error',
                    title: data.isSuccess ? 'Success' : 'Oops...',
                    text: (data.isSuccess === false && (data.resultMessage === null || data.resultMessage === "")) ? 'Unable to process the request.' : data.resultMessage,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            this.handleResetStateData();
                            this.getVehicleOwnerList();
                        }
                    }
                })
            }
        } catch (ex) {
            console.log(ex);
            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Unable to process the request.',
            })
        }
        this.setState({ isSubmited: false })
    }
    isFormVehicleOwnerTerminateValid() {
        let formIsValid = true;
        try {
            let errors = this.state.terminateOwnerErrors;
            let isFocus = false;
            errors.terminatedDate = '';
            errors.terminatedComment = '';
            if (moment(this.state.terminateOwner.terminatedDate, "MM/DD/YYYY").isSameOrBefore(moment(this.terminateOwnerFSDateInputValidator.value, "MM/DD/YYYY"), "day")) {
                formIsValid = false;
                errors.terminatedDate = 'Please enter valid date.';
            }
            if (this.state.terminateOwner.terminatedComment.trim() === '') {
                formIsValid = false;
                errors.terminatedComment = 'Please enter comment.';
            }
            if (isFocus === false) { this.submitOwnerTerminateButtonValidator.focus(); }
            this.setState({ terminateOwnerErrors: errors });
        } catch (ex) {
            formIsValid = false;
            console.log(ex);
        }
        return formIsValid;
    }
    handleContinueSaveVehForwardDedution = async event => {
        event.preventDefault();
        try {
            let isResult = true;
            let vehDedInCurrentPayCycle = this.state.vehicleDeductionsGridDataSource.filter(d => d.checkRow === true);
            if (this.state.vehicleDeductionsGridDataSource !== null) {
                if (this.state.vehicleDeductionsGridDataSource.length > 0) {
                    if (vehDedInCurrentPayCycle === null || vehDedInCurrentPayCycle.length === 0) {
                        await Swal.fire({
                            icon: 'question',
                            title: '<small><b>Confirmation</b></small>',
                            html: "<span style='font-size: 20px;'>You have not selected any deductions.<br>To select this option, click the first cell checkbox.</span><br><br>Are you sure to continue without deduction?",
                            showCancelButton: true,
                            focusConfirm: false,
                            confirmButtonText: "<span style='font-size:14px;'>Yes, Continue without deduction!</span>",
                            cancelButtonText: 'No',
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            dangerMode: false,
                            allowOutsideClick: false
                        }).then(async (result) => {
                            if (!result.isConfirmed) {
                                isResult = false;
                            }
                        });
                    }
                }
            }
            if (isResult) {
                this.setState({ isCarryForwardDedModalOpen: false });
                let loadPanelMsg = null;
                /*NOTE: Current cycle pending deductions are mandatory to move with new onwer, if found.*/
                if (vehDedInCurrentPayCycle === null || vehDedInCurrentPayCycle.length === 0) {
                    if (this.state.vehicleCurrentPayPeriod.rdoCurrentCycle) {
                        vehDedInCurrentPayCycle = this.state.vehicleDeductionsCarryForwardList.filter(d => d.deductionInPay === true && parseInt(d.vehiclePayDeductionId) > 0);
                        let vdCycle = [];
                        if (vehDedInCurrentPayCycle !== null) {
                            if (vehDedInCurrentPayCycle.length > 0) {
                                vehDedInCurrentPayCycle.forEach((item) => {
                                    if (item.checkRow === false) {
                                        item.checkRow = true;
                                    }
                                    vdCycle.push(item);
                                });
                                loadPanelMsg = 'Carry forwarding default ded of previous owner. Please, wait...';
                            }
                        }
                        vehDedInCurrentPayCycle = vdCycle;
                    }
                }
                await this.saveVehicleOwner(vehDedInCurrentPayCycle, loadPanelMsg);
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    handleCancelSaveVehForwardDedution = async event => {
        event.preventDefault();
        try {
            this.setState({ isCarryForwardDedModalOpen: false });
            let vehDedInCurrentPayCycle = null;
            let loadPanelMsg = null;
            /*NOTE: Current cycle pending deductions are mandatory to move with new onwer, if found.*/
            if (this.state.vehicleCurrentPayPeriod.rdoCurrentCycle) {
                vehDedInCurrentPayCycle = this.state.vehicleDeductionsCarryForwardList.filter(d => d.deductionInPay === true && parseInt(d.vehiclePayDeductionId) > 0);
                let vdCycle = [];
                if (vehDedInCurrentPayCycle !== null) {
                    if (vehDedInCurrentPayCycle.length > 0) {
                        vehDedInCurrentPayCycle.forEach((item) => {
                            if (item.checkRow === false) {
                                item.checkRow = true;
                            }
                            vdCycle.push(item);
                        });
                        loadPanelMsg = 'Carry forwarding default ded of previous owner. Please, wait...';
                    }
                }
                vehDedInCurrentPayCycle = vdCycle;
            }
            await this.saveVehicleOwner(vehDedInCurrentPayCycle, loadPanelMsg);
        } catch (ex) {
            console.log(ex);
        }
    }
    saveCarryForwarDeduction = async (vehDedInCurrentPayCycle, loadPanelMsg) => {
        try {
            if (Number(this.state.vehicleOwnerMapId) === 0 && vehDedInCurrentPayCycle != null) {
                if (vehDedInCurrentPayCycle.length > 0) {
                    this.setState({
                        loadPanelVisible: true,
                        loadPanelMessage: (loadPanelMsg === null || loadPanelMsg === '') ? 'Carry forwarding deduction to new owner. Please, wait...' : loadPanelMsg
                    });
                    await new Promise(resolve => setTimeout(resolve, 3000)); // 3 sec
                    if (vehDedInCurrentPayCycle.length > 0) {
                        let vehDedInCurrentPayCycleList = [];
                        vehDedInCurrentPayCycle.forEach((item) => {
                            if (item.checkRow) {
                                vehDedInCurrentPayCycleList.push(item);
                            }
                        });
                        if (vehDedInCurrentPayCycleList !== null) {
                            if (vehDedInCurrentPayCycleList.length > 0) {
                                var requestVD = new Object();
                                requestVD.VehicleId = Number(this.props.vehicleId);
                                requestVD.VehicleDeductionCarryForward = vehDedInCurrentPayCycleList;
                                const requestParamsVD = getRequestParams('POST', requestVD);
                                const responseVD = await fetch('vehicle-owner-api/save-deduction', requestParamsVD);
                                const dataVD = await responseVD.json();
                                if (dataVD.isSuccess) {
                                    this.setState({ loadPanelMessage: 'Deduction is carry forwarded to new owner successfully.' });
                                }
                                else {
                                    this.setState({ loadPanelMessage: 'Failed to update vehicle deuduction to recent owner.' });
                                    //Swal.fire({
                                    //    showCloseButton: true,
                                    //    icon: 'error',
                                    //    title: 'Oops...',
                                    //    text: 'Something went wrong! Unable to process vehicle deduction carray forward.',
                                    //})
                                }
                                await new Promise(resolve => setTimeout(resolve, 3000)); // 3 sec
                            }
                        }
                    }
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false, loadPanelMessage: 'Loading...' });
    }
    handleOnEditingStart = (e) => {
        if (e.column.dataField === 'checkRow') {
            if (e.data.deductionInPay === true && Number(e.data.vehiclePayDeductionId) > 0) {
                if (this.state.vehicleCurrentPayPeriod.rdoCurrentCycle === true) {
                    e.cancel = true;
                }
            }
        }
        else {
            e.cancel = true;
        }
    }
    handleOnFilterDedGrid = (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            let dedGridFilterOpt = this.state.dedGridFilterOpt;
            let filterGrid = null;
            switch (name) {
                case 'showDedActiveOnly':
                    dedGridFilterOpt.showDedActiveOnly = value;
                    this.setState({ dedGridFilterOpt: dedGridFilterOpt });
                    break;
                case 'showDedRecurringOnly':
                    dedGridFilterOpt.showDedRecurringOnly = value;
                    this.setState({ dedGridFilterOpt: dedGridFilterOpt });
                    break;
                default:
                    break;
            }
            let oneMonthLesstoDate = moment(new Date()).add(-1, 'months');

            if (dedGridFilterOpt.showDedActiveOnly === true) {
                if (dedGridFilterOpt.showDedRecurringOnly === true) {
                    filterGrid = this.state.vehicleDeductionsCarryForwardList.filter(d => d.isActive === true && parseInt(d.occurrenceNo) === 1)
                }
                else {
                    filterGrid = this.state.vehicleDeductionsCarryForwardList.filter(d => (d.isActive === true)
                        && (
                            (parseInt(d.occurrenceNo) === 2 && moment(d.startDate, "MM/DD/YYYY HH:mm").isSameOrAfter(oneMonthLesstoDate, 'day'))
                            ||
                            (parseInt(d.occurrenceNo) === 2 && (d.endDate === '' || d.isUntilDate === true || moment(d.endDate, "MM/DD/YYYY HH:mm").isSameOrAfter(moment(new Date()), "day")))
                        )
                    )
                }
            }
            if (dedGridFilterOpt.showDedRecurringOnly === true) {
                if (dedGridFilterOpt.showDedActiveOnly === true) {
                    filterGrid = this.state.vehicleDeductionsCarryForwardList.filter(d => (d.isActive === true && parseInt(d.occurrenceNo) === 1)
                        && (
                            (parseInt(d.occurrenceNo) === 2 && moment(d.startDate, "MM/DD/YYYY HH:mm").isSameOrAfter(oneMonthLesstoDate, 'day'))
                            ||
                            (parseInt(d.occurrenceNo) === 2 && (d.endDate === '' || d.isUntilDate === true || moment(d.endDate, "MM/DD/YYYY HH:mm").isSameOrAfter(moment(new Date()), "day")))
                        )
                    )
                }
                else {
                    filterGrid = this.state.vehicleDeductionsCarryForwardList.filter(d => parseInt(d.occurrenceNo) === 1)
                }
            }

            if (filterGrid !== null) {
                this.setState({ vehicleDeductionsGridDataSource: filterGrid });
            }
            else {
                this.setState({ vehicleDeductionsGridDataSource: this.state.vehicleDeductionsCarryForwardList });
            }
        } catch (ex) {
            console.log(ex);
            this.setState({ vehicleDeductionsGridDataSource: this.state.vehicleDeductionsCarryForwardList });
        }
    }
    render() {
        if (this.state.isGotoVehicleComponent) {
            return <VehicleListComponent />
        }
        if (this.state.isGotoVehicleDeductionComponent) {
            return <VehicleDeductionListComponent vehicleId={this.props.vehicleId} vehicleNumber={this.props.vehicleNumber} isOwnerActive={this.state.isActive} ownerName={this.state.ownerName} ownerId={this.state.ownerId} vehicleOwnerMapId={this.state.vehicleOwnerMapId} />
        }
        const renderGridCell = (data) => {
            let isLatestOwner = data.data.latestVehicleOwnerMapId > 0 && data.data.enable && data.data.id === data.data.latestVehicleOwnerMapId;
            let isAffiliateVehicle = data.data.vehicleOwnerTypeId === 1;
            let isTerminateFranchisee = (data.data.feDate === '' || data.data.feDate === null) ? true : false;
            if (!isAffiliateVehicle) {
                let editLink = isLatestOwner ? <a href="#" title="Edit" onClick={(e) => { this.handleEditViewVehicleOwner(e, data.data) }} ><i className="fas fa-pen text-info"></i></a> : '';
                let viewLink = <a href="#" title="View" style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleEditViewVehicleOwner(e, data.data); this.setState({ isOwnerViewOny: true }) }} ><i className="fas fa-eye text-info"></i></a>;
                let terminateFranchiseeLink = (isTerminateFranchisee && isLatestOwner) ? <a href="#" title="Terminate Franchisee" style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleTerminateFranchisee(e, data.data) }}><i className="fas fa-minus text-info"></i></a> : '';
                let commLink = isLatestOwner ? <a href="#" title="Set Commission" style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleViewSetCommission(e, data.data) }} ><i className="fas fa-dollar-sign text-info"></i></a> : '';
                let deductionLink = <a href="#" title="Set Deduction" style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleGotoDeductionComponent(e, data.data) }} ><i className="fas fa-money-check-alt text-info"></i></a>;
                let vehicleDepositLink = <a href="#" title="Set Vehicle Deposit" style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleVehicleDeposit(e, data.data) }} ><i className="fas fa-hand-holding-usd text-info"></i></a>;
                return (
                    <div style={{ textAlign: 'left', width: 'auto' }}>
                        {editLink === '' || editLink}
                        {viewLink}
                        {terminateFranchiseeLink === '' || terminateFranchiseeLink}
                        {commLink === '' || commLink}
                        {deductionLink}
                        {vehicleDepositLink}
                        <a href="#" title="History" style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleMasterHistoryModal(e, data.data) }}><i className="fas fa-history text-info"></i></a>
                    </div>
                );
            }
            else {
                return ('');
            }
        }
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <h1 className="m-0 text-dark">Vehicle Owner - Vehicle <i><strong className="text-danger">{this.props.vehicleNumber}</strong></i></h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem><NavLink tag={Link} to="#" className="btn btn-block btn-outline-secondary btn-sm" onClick={(e) => { this.handleAddVehicleOwner(e, null) }} title="Add Owner"><i className="fas fa-plus"></i> Add Owner</NavLink></NavItem>&nbsp;&nbsp;
                                    <NavItem><NavLink tag={Link} to="#" className="btn btn-block btn-outline-secondary btn-sm" onClick={(e) => { this.setState({ isGotoVehicleComponent: true }) }} title="Back"><i className="fas fa-angle-double-left"></i> Back</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="card card-grid">
                        <div className="card-body">
                            <DataGrid
                                dataSource={this.state.vehicleOwnerGridDataSource}
                                keyExpr="id"
                                selection={{ mode: 'single' }}
                                columnsAutoWidth={false}
                                showColumnLines={true}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                onToolbarPreparing={this.handleToolbarPreparing}
                                repaintChangesOnly={true}
                                onCellPrepared={this.handleOnCellPrepared}
                            //onRowPrepared={this.handleOnRowPrepared}
                            >
                                <Column dataField="id" width={130} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                <Column dataField="vehicleOwnerName" caption="Owner" />
                                <Column dataField="vehicleOwnerTypeName" caption="Franchisee Type" />
                                <Column dataField="achEmailId" caption="Ach Email" />
                                <Column dataField="vehiclePaymentModeDesc" caption="Payment Mode" width={"auto"} />
                                <Column dataField="wefDate" caption="FS Date" width={"auto"} alignment={'center'} />
                                <Column dataField="feDate" caption="FE Date" width={"auto"} alignment={'center'} />
                                <Column dataField="status" caption="Status" width={"auto"} alignment={'center'} />
                                <Column dataField="fecomment" caption="FE Comment" />
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                <Export enabled={true} allowExportSelectedData={false} />
                                <ColumnChooser enabled={true} />
                                <ColumnFixing enabled={true} />
                            </DataGrid>
                        </div>
                    </div>
                </div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOwnerModalOpen}
                    style={{ content: { width: '50%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleAssignDataToState}
                    onRequestClose={this.handleResetStateData}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleVehicleOwnerSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{this.state.isOwnerViewOny ? 'Owner (Readonly)' : Number(this.state.vehicleOwnerMapId) > 0 ? "Modify Owner" : "Add Owner"}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleResetStateData}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Owner <span className="text-danger">*</span></label>
                                        {/*<Select ref={select => this.ownerNameSelectValidator = select}*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder="---Select Owner---"*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.ownerId === 0 ? [] : [this.state.dropdownOwner.find(opt => opt.id === this.state.ownerId)]}*/}
                                        {/*    name="ownerName"*/}
                                        {/*    onChange={this.handleOwnerOnChange}*/}
                                        {/*    getOptionLabel={option => option.name}*/}
                                        {/*    getOptionValue={option => option.id}*/}
                                        {/*    options={this.state.dropdownOwner.length > 0 ? this.state.dropdownOwner : []}*/}
                                        {/*    isMulti={false}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isDisabled={(this.state.isOwnerViewOny || this.state.vehicleOwnerMapId > 0)}*/}
                                        {/*    isSearchable={true} />*/}


                                        <SelectBox
                                            dataSource={this.state.dropdownOwner}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select Owner"
                                            name="ownerName"
                                            defaultValue={0}
                                            value={this.state.ownerId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            ref={select => this.ownerNameSelectValidator = select}
                                            onSelectionChanged={this.handleOwnerOnChange}
                                            showClearButton={true}
                                            disabled={(this.state.isOwnerViewOny || this.state.vehicleOwnerMapId > 0)}
                                            searchEnabled={true}
                                        />

                                        {this.state.errors["ownerName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["ownerName"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label htmlFor="email">ACH Email<span className="text-danger">*</span></label>
                                        <input type="email" className="form-control form-control-sm" id="email" name="email" title="Email" placeholder="example@xyz.com" maxLength="50" disabled={this.state.isOwnerViewOny} ref={input => this.emailInputValidator = input} value={this.state.email} onChange={this.handleInputChange} />
                                        {this.state.errors["email"].length > 0 && <span className="error invalid-feedback">{this.state.errors["email"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Payment Mode <span className="text-danger">*</span></label>
                                        <select name="paymentMode" className="form-control form-control-sm" disabled={this.state.isOwnerViewOny} ref={select => this.paymentModeSelectValidator = select} value={this.state.paymentModeId} onChange={this.handleInputChange}>
                                            <option value="-1">---Select Payment mode---</option>
                                            {
                                                this.state.dropdownPaymentMode &&
                                                this.state.dropdownPaymentMode.map((item, i) =>
                                                    (<option key={i} value={item.id}>{item.name}</option>))
                                            }
                                        </select>
                                        {this.state.errors["paymentMode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["paymentMode"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <br />
                                        <span className="form-check">
                                            <input className="form-check-input" type="checkbox" id="isBalanceCarryForward" name="isBalanceCarryForward" disabled={this.state.isOwnerViewOny} checked={this.state.isBalanceCarryForward} onChange={(e) => { this.setState({ isBalanceCarryForward: e.target.checked }) }} />
                                            <label className="form-check-label" htmlFor="isBalanceCarryForward" style={{ fontSize: "inherit" }}> Balance carry forward on next payout</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div id="pnlReimbursementCycle" style={{ display: Number(this.state.vehicleOwnerMapId) > 0 ? 'none' : 'block' }}>
                                <div className="row">
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                        <div className="form-group">
                                            <div className="radio">
                                                <label>Current Cycle</label>&nbsp;&nbsp;
				                            <input type="radio" name="reimbursementCycle" id="rdoCurrentCycle" disabled={true} checked={this.state.vehicleCurrentPayPeriod.rdoCurrentCycle} onChange={this.handleInputChange} />
                                            </div>
                                            <span className={this.state.vehicleCurrentPayPeriod.isCurrentCyleClosed ? "text-danger" : "text-defualt"} >{this.state.vehicleCurrentPayPeriod.lblCurrentCycleDate}</span>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                        <div className="form-group">
                                            <div className="radio">
                                                <label>Next Cycle </label>&nbsp;&nbsp;
				                                    <input type="radio" name="reimbursementCycle" id="rdoNextCycle" disabled={this.state.vehicleCurrentPayPeriod.isPayPeriodExist ? false : true} checked={this.state.vehicleCurrentPayPeriod.rdoNextCycle} onChange={this.handleInputChange} />
                                            </div>
                                            <span>{this.state.vehicleCurrentPayPeriod.lblNextCycleDate}</span>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <div className="form-group">
                                            <div className="radio">
                                                <label>Select Cycle </label>&nbsp;&nbsp;
				                                    <input type="radio" name="reimbursementCycle" id="rdoSelectCycle" checked={this.state.vehicleCurrentPayPeriod.rdoSelectCycle} onChange={this.handleInputChange} />
                                                <DateBox type="date" style={{ width: "100%" }} className="form-control form-control-sm" name="selectCycleDate" placeholder="MM/dd/yyyy"
                                                    //applyValueMode="useButtons"
                                                    defaultValue={this.state.selectCycleDate.value}
                                                    min={this.state.selectCycleDate.minDate}
                                                    onValueChanged={this.handleOnValueChangedDateBox}
                                                />
                                            </div>
                                            <span>{this.state.vehicleCurrentPayPeriod.lblSelectedCycleDate}</span>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                        <div id="currentCycleCloseStatus" className="row" style={{ display: this.state.vehicleCurrentPayPeriod.isCurrentCyleClosed || 'none' }}>
                                            <div className="form-group" style={{ marginLeft: '20px' }}>
                                                <label>Cycle Status:</label>
                                                <input type="text" className="text-danger" style={{ border: 'none', width: '100px' }} value={'Closed'} readOnly={true} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="payrollStatus" className="row" style={{ display: (this.props.isPayrollActive) ? 'none' : 'block' }} >
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <label>Payroll Status: <span className="text-danger">{'Payroll is not activated for vehicle [' + this.props.vehicleNumber + '].'}</span></label>
                                </div>
                            </div>
                            <div id="pnlFSDateViewOnly" className="row" style={{ display: (this.state.showTerminatedFrachiseeDate || Number(this.state.vehicleOwnerMapId) === 0) ? 'none' : 'block' }}>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <label>Franchisee Start Date: <span className="text-info">{this.state.wefDate}</span></label>
                                </div>
                            </div>
                            <div id="pnlTerminateFrachisee" style={{ display: this.state.showTerminatedFrachiseeDate ? 'block' : 'none' }}>
                                <div className="row">
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                        <div className="form-group">
                                            <label>Franchisee Start Date:</label>
                                        </div>
                                        <span className="text-info">{this.state.wefDate}</span>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                        <div className="form-group">
                                            <label>Franchisee End Date:</label>
                                        </div>
                                        <span className="text-info">{this.state.franchiseeEndDate}</span>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <div className="form-group">
                                            <label>Comment:</label>
                                        </div>
                                        <textarea className="form-control form-textbox form-control-sm" title="FE Comment" placeholder="FE Comment..." defaultValue={this.state.franchiseeEndComment} disabled={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success btn-sm" disabled={this.state.isOwnerViewOny} ref={button => this.submitOwnerButtonValidator = button}>
                                {this.state.isSubmited && <span title="In Process"><i className="fas fa-spinner fa-spin"></i> In Process</span>}
                                {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleResetStateData}><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOwnerTerminateModalOpen}
                    style={{ content: { width: '40%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleAssignDataToState}
                    onRequestClose={this.handleResetStateData}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Terminate Franchisee'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleResetStateData}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <label>Start Date</label>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <input type="terminateOwnerFSDate" className="form-control form-control-sm" name="terminateOwnerFSDate" title="FS Date" disabled={true} ref={input => this.terminateOwnerFSDateInputValidator = input} value={moment(this.state.wefDate, "MMM DD,YY").format("MM/DD/YYYY")} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <label>Terminate Date</label>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <DateBox type="date" style={{ width: "100%" }} className="form-control form-control-sm" id="terminatedDate" name="terminatedDate" placeholder="MM/dd/yyyy"
                                            defaultValue={this.state.terminateOwner.terminatedDate}
                                            min={new Date()}
                                            onValueChanged={this.handleOnValueChangedDateBox}
                                        />
                                        {this.state.terminateOwnerErrors["terminatedDate"].length > 0 && <span className='error invalid-feedback'>{this.state.terminateOwnerErrors["terminatedDate"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <label>Comment</label>
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                    <div className="form-group">
                                        <textarea className="form-control form-textbox form-control-sm" rows="5" name="terminatedComment" placeholder="comment..." maxLength="200" title="FE Comment" onChange={this.handleInputChange} />
                                        {this.state.terminateOwnerErrors["terminatedComment"].length > 0 && <span className='error invalid-feedback'>{this.state.terminateOwnerErrors["terminatedComment"]}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" ref={button => this.submitOwnerTerminateButtonValidator = button} onClick={this.handleVehicleOwnerTerminateSubmit}>
                                {this.state.isSubmited && <span title="In Process"><i className="fas fa-spinner fa-spin"></i> In Process</span>}
                                {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleResetStateData}><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isCarryForwardDedModalOpen}
                    style={{ content: { width: '75%' } }}
                    className={"react-modal"}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Vehicle Deduction Carry Forward'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => { this.handleCancelSaveVehForwardDedution(e) }}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xs-5 col-sm-5 col-md-5 col-lg-5">
                                    <label>Owner <i><strong className="text-danger">{this.state.ownerName}</strong></i></label>
                                </div>
                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                    <label>Vehicle <i><strong className="text-danger">{this.props.vehicleNumber}</strong></i></label>
                                </div>
                                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                    <label htmlFor="showDedRecurringOnly" style={{ fontSize: "inherit" }}> Show Recurring Only &nbsp;</label>
                                    <input type="checkbox" id="showDedRecurringOnly" name="showDedRecurringOnly" style={{ cursor: 'pointer' }} checked={this.state.dedGridFilterOpt.showDedRecurringOnly} onChange={this.handleOnFilterDedGrid} />
                                </div>
                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                    <label htmlFor="showDedActiveOnly" style={{ fontSize: "inherit" }}> Show Active Only &nbsp;</label>
                                    <input type="checkbox" id="showDedActiveOnly" name="showDedActiveOnly" style={{ cursor: 'pointer' }} checked={this.state.dedGridFilterOpt.showDedActiveOnly} onChange={this.handleOnFilterDedGrid} />
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="widget-container">
                                        <DataGrid
                                            dataSource={this.state.vehicleDeductionsGridDataSource}
                                            keyExpr="vehicleDeductionId"
                                            selection={{ mode: 'single' }}
                                            columnsAutoWidth={true}
                                            showColumnLines={true}
                                            showRowLines={true}
                                            showBorders={true}
                                            rowAlternationEnabled={true}
                                            allowColumnResizing={true}
                                            columnResizingMode='widget'
                                            repaintChangesOnly={true}
                                            onEditingStart={this.handleOnEditingStart}
                                        >
                                            <Editing
                                                mode="cell"
                                                allowUpdating={true}
                                                startEditAction={'click'}
                                            />
                                            <Column dataField="checkRow" caption="" title={'Select Deduction'} width={52} alignment={'center'} allowEditing={true} allowFiltering={false} allowSorting={false} />
                                            <Column dataField="vehicleNo" caption="Vehicle" allowEditing={false} width={85} />
                                            <Column dataField="vehicleOwnerName" caption="Owner" allowEditing={false} width={140} />
                                            <Column dataField="deductionName" caption="Deduction" allowEditing={false} width={140} />
                                            <Column dataField="deductionTypeName" caption="Deduction Type" allowEditing={false} width={'auto'} />
                                            <Column dataField="startDate" caption="Start Date" allowEditing={false} width={'auto'} />
                                            <Column dataField="endDate" caption="End Date" allowEditing={false} width={'auto'} />
                                            <Column dataField="occurrenceStatus" caption="Occurrence" allowEditing={false} width={'auto'} />
                                            <Column dataField="loanAmount" caption="Amount Paid" allowEditing={false} width={'auto'} />
                                            <Column dataField="recurringCharge" caption="Deduction Amount" allowEditing={false} width={'auto'} />
                                            <Column dataField="deductedAmount" caption="Total Collected Life" allowEditing={false} width={'auto'} />
                                            <Column dataField="noOfPayouts" caption="Total Payouts" allowEditing={false} width={'auto'} />
                                            <Column dataField="pendingPayouts" caption="Pending Payouts" allowEditing={false} width={'auto'} />
                                            <Column dataField="currentDueAmount" caption="Due Amount" visible={false} allowEditing={false} width={'auto'} />
                                            <Column dataField="totalBalance" caption="Balance" allowEditing={false} width={'auto'} />
                                            <Column dataField="note" caption="Note" allowEditing={false} width={120} />
                                            <SortByGroupSummaryInfo summaryItem="count" />
                                            <GroupPanel visible={false} />
                                            <HeaderFilter visible={true} />
                                            <SearchPanel visible={false} highlightCaseSensitive={false} width={240} placeholder="Search..." />
                                            <Grouping autoExpandAll={true} />
                                            <Paging defaultPageSize={10} />
                                            <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                            <Export enabled={false} allowExportSelectedData={false} />
                                            <ColumnChooser enabled={false} />
                                            <ColumnFixing enabled={false} />
                                        </DataGrid>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={(e) => { this.handleContinueSaveVehForwardDedution(e) }}>
                                {<span><i className="fas fa-check"></i> Continue</span>}
                            </button>
                            <button type="button" className="btn btn-danger btn-sm" onClick={(e) => { this.handleCancelSaveVehForwardDedution(e) }}><i className="fas fa-times"></i> Cancel</button>
                        </div>
                    </form>
                </Modal>
                <MasterHistoryComponent ref="masterHistoryComponent" />
                <CommissionSettingComponent ref="vehicleOwnerCommSettingComponent" />
                <SetVehicleDepositComponent ref="setVehicleDepositComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    message={this.state.loadPanelMessage}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}