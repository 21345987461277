import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { SelectBox } from 'devextreme-react/select-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'

let currentYear = (new Date()).getFullYear();
let maxYear = 10;
let minYear = -3
let fromYearList = [];
let toYearList = [];
for (let i = maxYear; i >= minYear; i--) {
    let year = new Object();
    year.id = (currentYear - i);
    year.name = (currentYear - i);
    fromYearList.push(year);
}
for (let i = (maxYear + 1); i >= (minYear - 1); i--) {
    let year = new Object();
    year.id = (currentYear - i);
    year.name = (currentYear - i);
    toYearList.push(year);
}
export const monthsList = [{
    'id': 1,
    'name': 'January',
}, {
    'id': 2,
    'name': 'February',
}, {
    'id': 3,
    'name': 'March',
}, {
    'id': 4,
    'name': 'April',
}, {
    'id': 5,
    'name': 'May',
}, {
    'id': 6,
    'name': 'June',
}, {
    'id': 7,
    'name': 'July',
}, {
    'id': 8,
    'name': 'August',
}, {
    'id': 9,
    'name': 'September',
}, {
    'id': 10,
    'name': 'October',
}, {
    'id': 11,
    'name': 'November',
}, {
    'id': 12,
    'name': 'December',
}]
export class CumulativeSalesReportComponent extends Component {
    constructor() {
        super()
        this.state = {
            isSubmitted: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            isDetailsReport: true,
            isSummaryReport: false,
            fromYearDropdownList: [],
            fromYearDropdownData: null,
            fromYear: '',
            toYearDropdownList: [],
            toYearDropdownData: null,
            toYear: '',
            isAllMonth: true,
            isMonthRange: false,
            fromMonthDropdownList: [],
            fromMonthDropdownData: null,
            fromMonthId: 0,
            toMonthDropdownList: [],
            toMonthDropdownData: null,
            toMonthId: 0,
            isAllCustomerType: true,
            customerTypeDropdownList: [],
            customerTypeDropdownData: null,
            customerTypeId: 0,
            errors: {
                reportType: '',
                fromYear: '',
                toYear: '',
                fromMonth: '',
                toMonth: '',
                customerType: ''
            }
        }
    }
    getFromYearDropdownList = async () => {
        try {
            let fromCurrentYear = [];
            let fYear = '';
            if (fromYearList !== null) {
                if (fromYearList.length > 0) {
                    let fy = new Object();
                    fy.id = currentYear;
                    fy.name = currentYear.toString();
                    fromCurrentYear.push(fy);
                    fYear = currentYear.toString();
                }
            }
            this.setState({ fromYearDropdownList: fromYearList, fromYearDropdownData: fromCurrentYear, fromYear: fYear });
        } catch (ex) {
            console.log(ex);
        }
    }
    getToYearDropdownList = async () => {
        try {
            let toCurrentYear = [];
            let tYear = '';
            if (toYearList !== null) {
                if (toYearList.length > 0) {
                    let ty = new Object();
                    if (Number(this.state.fromYear) > 0) {
                        ty.id = (Number(this.state.fromYear) + 1).toString()
                        ty.name = (Number(this.state.fromYear) + 1).toString();
                        tYear = (Number(this.state.fromYear) + 1).toString()
                    }
                    else {
                        ty.id = currentYear;
                        ty.name = currentYear.toString();
                        tYear = currentYear.toString();
                    }
                    toCurrentYear.push(ty);
                }
            }
            this.setState({ toYearDropdownList: toYearList, toYearDropdownData: toCurrentYear, toYear: tYear });
        } catch (ex) {
            console.log(ex);
        }
    }
    getMonthsDropdownList = async () => {
        try {
            this.setState({ fromMonthDropdownList: monthsList, toMonthDropdownList: monthsList });
        } catch (ex) {
            console.log(ex);
        }
    }
    getCustomerTypeDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('customer-type-api/', requestParams);
            const data = await response.json();
            this.setState({
                customerTypeDropdownList: data.customerType
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getFromYearDropdownList();
            await this.getToYearDropdownList();
            await this.getMonthsDropdownList();
            await this.getCustomerTypeDropdownList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isEmailSubmitted: false,
                isOpenModal: false,
                loadPanelVisible: false,
                isDetailsReport: true,
                isSummaryReport: false,
                fromYearDropdownList: [],
                fromYearDropdownData: null,
                fromYear: '',
                toYearDropdownList: [],
                toYearDropdownData: null,
                toYear: '',
                isAllMonth: true,
                isMonthRange: false,
                fromMonthDropdownList: [],
                fromMonthDropdownData: null,
                fromMonthId: 0,
                toMonthDropdownList: [],
                toMonthDropdownData: null,
                toMonthId: 0,
                isAllCustomerType: true,
                customerTypeDropdownList: [],
                customerTypeDropdownData: null,
                customerTypeId: 0,
                errors: {
                    reportType: '',
                    fromYear: '',
                    toYear: '',
                    fromMonth: '',
                    toMonth: '',
                    customerType: ''
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnInputChange = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
            let errors = this.state.errors;
            switch (name) {
                case 'isDetailsReport':
                    errors.reportType = '';
                    errors.fromYear = '';
                    errors.toYear = '';
                    errors.fromMonth = '';
                    errors.toMonth = '';
                    errors.customerType = '';
                    this.setState({ isDetailsReport: value, isSummaryReport: false });
                    break;
                case 'isSummaryReport':
                    errors.reportType = '';
                    errors.fromYear = '';
                    errors.toYear = '';
                    errors.fromMonth = '';
                    errors.toMonth = '';
                    errors.customerType = '';
                    this.setState({ isDetailsReport: false, isSummaryReport: value });
                    break;
                case 'isAllMonth':
                    errors.fromMonth = '';
                    errors.toMonth = '';
                    this.setState({ isAllMonth: value, isMonthRange: false, fromMonthDropdownData: null, fromMonthId: 0, toMonthDropdownData: null, toMonthId: 0 });
                    break;
                case 'isMonthRange':
                    errors.fromMonth = '';
                    errors.toMonth = '';
                    this.setState({ isAllMonth: false, isMonthRange: value, fromMonthDropdownData: null, fromMonthId: 0, toMonthDropdownData: null, toMonthId: 0 });
                    break;
                case 'isAllCustomerType':
                    errors.customerType = '';
                    this.setState({ isAllCustomerType: value, customerTypeDropdownData: null, customerTypeId: 0 });
                    break;
                default:
                    break;
            }
            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnFromYearDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.fromYear = '';
            let fromYear = '';
            if (data !== null) {
                fromYear = (data.selectedItem != null ? data.selectedItem.id : 0);
            }
            else {
                //errors.fromYear = 'Please select year.';
            }
            if (this.state.toYear.trim() !== '') {
                errors.toYear = '';
            }
            this.setState({ fromYearDropdownData: data, fromYear: fromYear.toString(), errors: errors });

            let toCurrentYear = [];
            let tYear = '';
            if (toYearList !== null) {
                if (toYearList.length > 0) {
                    let ty = new Object();
                    if (Number(fromYear) > 0) {
                        ty.id = (Number(fromYear) + 1).toString()
                        ty.name = (Number(fromYear) + 1).toString();
                        tYear = (Number(fromYear) + 1).toString()
                    }
                    else {
                        ty.id = currentYear;
                        ty.name = currentYear.toString();
                        tYear = currentYear.toString();
                    }
                    toCurrentYear.push(ty);
                }
            }
            this.setState({ toYearDropdownData: toCurrentYear, toYear: tYear });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnToYearDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.toYear = '';
            let toYear = '';
            if (data !== null) {
                toYear = (data.selectedItem != null ? data.selectedItem.id : 0);
            }
            else {
                //errors.toYear = 'Please select year.';
            }
            if (this.state.fromYear.trim() !== '') {
                errors.fromYear = '';
            }
            this.setState({ toYearDropdownData: data, toYear: toYear.toString(), errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnFromMonthDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.fromMonth = '';
            let fromMonthId = 0;
            if (data !== null) {
                fromMonthId = (data.selectedItem != null ? data.selectedItem.id : 0);
            }
            else {
                //errors.fromMonth = 'Please select month.';
            }
            if (Number(this.state.toMonthId) > 0) {
                errors.toMonth = '';
            }
            this.setState({ fromMonthDropdownData: data, fromMonthId: fromMonthId, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnToMonthDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.toMonth = '';
            let toMonthId = 0;
            if (data !== null) {
                toMonthId = (data.selectedItem != null ? data.selectedItem.id : 0);
            }
            else {
                //errors.toMonth = 'Please select month.';
            }
            if (Number(this.state.fromMonthId) > 0) {
                errors.fromMonth = '';
            }
            this.setState({ toMonthDropdownData: data, toMonthId: toMonthId, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnCustomerTypeDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.customerType = '';
            let customerTypeId = 0;
            if (data !== null) {
                customerTypeId = (data.selectedItem != null ? data.selectedItem.id : 0);
            }
            else {
                //errors.customerType = 'Please select customer type.';
            }
            this.setState({ customerTypeDropdownData: data, customerTypeId: customerTypeId, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnReportSubmit = async (event) => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isSubmitted: true });
                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                if (this.state.isDetailsReport === true) {
                    request.ReportType = 1;
                }
                if (this.state.isSummaryReport === true) {
                    request.ReportType = 2;
                }
                if (this.state.fromYear.trim() !== '') {
                    request.StartDate = this.state.fromYear + '-01-01 00:00:00'
                }
                if (this.state.toYear.trim() !== '') {
                    request.EndDate = this.state.toYear + '-12-31 23:59:59'
                }
                request.Number1 = Number(this.state.fromMonthId);
                request.Number2 = Number(this.state.toMonthId);
                if (this.state.customerTypeDropdownData !== null) {
                    if (Object.keys(this.state.customerTypeDropdownData).length > 0) {
                        request.CustomerType = this.state.customerTypeDropdownData.name;
                    }
                }
                request.SelectionCrieteria = await this.setSelectionCrieteria();
                request.fileFormat = 'pdf';
                request.MethodName = "GetCumulativeSalesReport";
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                let a = document.createElement('a');
                a.href = dataResponse.reportBase64String;
                a.download = 'CumulativeSalesReport' + ((this.state.isDetailsReport === true) ? 'Details' : 'Summary') + '.pdf';
                a.click();
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false });
    }
    isFormValid = async () => {
        let isValid = true;
        try {
            let errors = this.state.errors;
            errors.reportType = '';
            errors.fromYear = '';
            errors.toYear = '';
            errors.fromMonth = '';
            errors.toMonth = '';
            errors.customerType = '';
            if (this.state.isDetailsReport === false && this.state.isSummaryReport === false) {
                errors.reportType = 'Please select report type.';
                isValid = false;
            }
            if (this.state.fromYear.trim() === '') {
                errors.fromYear = 'Please select year.';
                isValid = false;
            }
            if (this.state.toYear.trim() === '') {
                errors.toYear = 'Please select year.';
                isValid = false;
            }
            if (this.state.isAllMonth === false) {
                if (Number(this.state.fromMonthId) === 0) {
                    errors.fromMonth = 'Please select month.';
                    isValid = false;
                }
                if (Number(this.state.toMonthId) === 0) {
                    errors.toMonth = 'Please select month.';
                    isValid = false;
                }
                if (Number(this.state.fromMonthId) > Number(this.state.toMonthId)) {
                    errors.fromMonth = 'Please select valid month range.';
                    errors.toMonth = 'Please select valid month range.';
                    isValid = false;
                }
            }
            if (this.state.isAllCustomerType === false) {
                if (Number(this.state.customerTypeId) === 0) {
                    errors.customerType = 'Please select customer type.';
                    isValid = false;
                }
            }
            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    setSelectionCrieteria = async () => {
        let selectionCrieteria = 'Completed jobs for';
        try {
            if (this.state.fromYear.trim() !== '' && this.state.toYear.trim() !== '') {
                selectionCrieteria += ' year from ' + this.state.fromYear + ' to ' + this.state.toYear;
            }
            if (this.state.isAllMonth === false) {
                if (Number(this.state.fromMonthId) > 0 && Number(this.state.toMonthId) > 0) {
                    if (this.state.fromMonthDropdownData !== null && Object.keys(this.state.fromMonthDropdownData).length > 0) {
                        if (this.state.toMonthDropdownData !== null && Object.keys(this.state.toMonthDropdownData).length > 0) {
                            selectionCrieteria += ', month from  ' + this.state.fromMonthDropdownData.name + ' to ' + this.state.toMonthDropdownData.name;
                        }
                    }
                }
            }
            else {
                selectionCrieteria += ', all months';
            }
            if (Number(this.state.customerTypeId) > 0) {
                if (this.state.customerTypeDropdownData !== null && Object.keys(this.state.customerTypeDropdownData).length > 0) {
                    selectionCrieteria += ', customer type - ' + this.state.customerTypeDropdownData.name;
                }
            }
            else {
                selectionCrieteria += ', all customer type';
            }
        } catch (ex) {
            selectionCrieteria = '';
            console.log(ex);
        }
        return selectionCrieteria;
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isEmailSubmitted: true });
                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                if (this.state.isDetailsReport === true) {
                    request.ReportType = 1;
                }
                if (this.state.isSummaryReport === true) {
                    request.ReportType = 2;
                }
                if (this.state.fromYear.trim() !== '') {
                    request.StartDate = this.state.fromYear + '-01-01 00:00:00'
                }
                if (this.state.toYear.trim() !== '') {
                    request.EndDate = this.state.toYear + '-12-31 23:59:59'
                }
                request.Number1 = Number(this.state.fromMonthId);
                request.Number2 = Number(this.state.toMonthId);
                if (this.state.customerTypeDropdownData !== null) {
                    if (Object.keys(this.state.customerTypeDropdownData).length > 0) {
                        request.CustomerType = this.state.customerTypeDropdownData.name;
                    }
                }
                request.SelectionCrieteria = await this.setSelectionCrieteria();
                request.fileFormat = 'pdf';
                request.MethodName = "GetCumulativeSalesReport";
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Cumulative Sales Report';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = 'CumulativeSalesReport' + ((this.state.isDetailsReport === true) ? 'Details' : 'Summary') + '.pdf';
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div >
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '45%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleOnReportSubmit}
                        style={(this.state.isSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Cumulative Sales Report'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label htmlFor="">Report Type</label><br />
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isDetailsReport"
                                                checked={this.state.isDetailsReport}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isDetailsReport">Details</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isSummaryReport"
                                                checked={this.state.isSummaryReport}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isSummaryReport">Summary</label>
                                        </div>
                                        {this.state.errors["reportType"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["reportType"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                id="isAllMonth" name="isAllMonth"
                                                checked={this.state.isAllMonth}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isAllMonth"> All Months</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                id="isMonthRange" name="isMonthRange"
                                                checked={this.state.isMonthRange}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isMonthRange"> In Range</label>
                                        </div>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder={(this.state.isMonthRange) ? 'Select from month' : 'Select month'}*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.fromMonthDropdownData}*/}
                                        {/*    name="ddfrommonth"*/}
                                        {/*    onChange={this.handleOnFromMonthDropdownChange}*/}
                                        {/*    getOptionLabel={option => option.name}*/}
                                        {/*    getOptionValue={option => option.id}*/}
                                        {/*    options={this.state.fromMonthDropdownList}*/}
                                        {/*    isMulti={false}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*    isDisabled={this.state.isAllMonth}*/}
                                        {/*/>*/}

                                        <SelectBox
                                            dataSource={this.state.fromMonthDropdownList.length > 0 ? this.state.fromMonthDropdownList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder={(this.state.isMonthRange) ? 'Select from month' : 'Select month'}
                                            name="ddfrommonth"
                                            defaultValue={[]}
                                            value={this.state.fromMonthDropdownData}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnFromMonthDropdownChange}
                                            showClearButton={true}
                                            disabled={this.state.isAllMonth}
                                            searchEnabled={true}
                                        />

                                        {this.state.errors["fromMonth"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["fromMonth"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <label className="form-check-label" htmlFor=""></label>
                                        </div>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder={(this.state.isMonthRange) ? 'Select to month' : 'Select month'}*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.toMonthDropdownData}*/}
                                        {/*    name="ddtomonth"*/}
                                        {/*    onChange={this.handleOnToMonthDropdownChange}*/}
                                        {/*    getOptionLabel={option => option.name}*/}
                                        {/*    getOptionValue={option => option.id}*/}
                                        {/*    options={this.state.toMonthDropdownList}*/}
                                        {/*    isMulti={false}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*    isDisabled={this.state.isAllMonth}*/}
                                        {/*/>*/}

                                        <SelectBox
                                            dataSource={this.state.toMonthDropdownList.length > 0 ? this.state.toMonthDropdownList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder={(this.state.isMonthRange) ? 'Select to month' : 'Select month'}
                                            name="ddtomonth"
                                            defaultValue={[]}
                                            value={this.state.toMonthDropdownData}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnToMonthDropdownChange}
                                            showClearButton={true}
                                            disabled={this.state.isAllMonth}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["toMonth"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["toMonth"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>From Year</label>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder="Select year"*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.fromYearDropdownData}*/}
                                        {/*    name="ddfromyear"*/}
                                        {/*    onChange={this.handleOnFromYearDropdownChange}*/}
                                        {/*    getOptionLabel={option => option.name}*/}
                                        {/*    getOptionValue={option => option.id}*/}
                                        {/*    options={this.state.fromYearDropdownList}*/}
                                        {/*    isMulti={false}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*    isDisabled={false}*/}
                                        {/*/>*/}

                                        <SelectBox
                                            dataSource={this.state.fromYearDropdownList.length > 0 ? this.state.fromYearDropdownList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="select year"
                                            name="ddfromyear"
                                            defaultValue={[]}
                                            value={this.state.fromYearDropdownData}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnFromYearDropdownChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["fromYear"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["fromYear"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>To Year</label>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder="Select year"*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.toYearDropdownData}*/}
                                        {/*    name="ddtoyear"*/}
                                        {/*    onChange={this.handleOnToYearDropdownChange}*/}
                                        {/*    getOptionLabel={option => option.name}*/}
                                        {/*    getOptionValue={option => option.id}*/}
                                        {/*    options={this.state.toYearDropdownList}*/}
                                        {/*    isMulti={false}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*    isDisabled={true}*/}
                                        {/*/>*/}

                                        <SelectBox
                                            dataSource={this.state.toYearDropdownList.length > 0 ? this.state.toYearDropdownList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="select year"
                                            name="ddtoyear"
                                            defaultValue={[]}
                                            value={this.state.toYearDropdownData}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnToYearDropdownChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />

                                        {this.state.errors["toYear"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["toYear"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                id="isAllCustomerType" name="isAllCustomerType"
                                                checked={this.state.isAllCustomerType}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isAllCustomerType"> All Customer Type</label>
                                        </div>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder="Select customer type"*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.customerTypeDropdownData}*/}
                                        {/*    name="ddcustomertype"*/}
                                        {/*    onChange={this.handleOnCustomerTypeDropdownChange}*/}
                                        {/*    getOptionLabel={option => option.name}*/}
                                        {/*    getOptionValue={option => option.id}*/}
                                        {/*    options={this.state.customerTypeDropdownList}*/}
                                        {/*    isMulti={false}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*    isDisabled={this.state.isAllCustomerType}*/}
                                        {/*/>*/}

                                        <SelectBox
                                            dataSource={this.state.customerTypeDropdownList.length > 0 ? this.state.customerTypeDropdownList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="select customer type"
                                            name="ddcustomertype"
                                            defaultValue={[]}
                                            value={this.state.customerTypeDropdownData}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnCustomerTypeDropdownChange}
                                            showClearButton={true}
                                            disabled={this.state.isAllCustomerType}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerType"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["customerType"]}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="submit" className="btn btn-success btn-sm" title="Download">
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                                </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        );
    }
}