import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import RadioGroup from 'devextreme-react/radio-group';
import { SelectBox } from 'devextreme-react/select-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import { NumberBox } from 'devextreme-react/number-box';
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'

const reportTypeRadio = [
    { id: 0, name: 'Driver Wise' },
    { id: 1, name: 'Day Wise' },
];

export class DriverWorkHourHistoryReportComponent extends Component {
    constructor() {
        super()
        this.state = {
            isSubmitted: false,
            isOpenModal: false,
            isEmailSubmitted: false,
            loadPanelVisible: false,
            reportTypeList: reportTypeRadio,
            reportType: reportTypeRadio[0],
            dropdownDrivers: [],
            driverId: 0,
            driverType: "0",
            vehicleTypeList: [],
            vehicleTypeId: 0,
            vehicleType: "",
            radVehicleType: "0",
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),
            radDateType: "0",
            maxWorkingHours: 1000,
            workingHourvalue: 0,
            errors: {
                driverId: 0,
                vehicleTypeId: 0,
            }
        }

        this.getDrivers = this.getDrivers.bind(this);
        this.getVehicleTypeList = this.getVehicleTypeList.bind(this);

        this.handleReportTypeChange = this.handleReportTypeChange.bind(this);
        this.handleDriverTypeChange = this.handleDriverTypeChange.bind(this);
        this.handleDateTypeChange = this.handleDateTypeChange.bind(this);
        this.handleDriverChange = this.handleDriverChange.bind(this);
        this.handleVehicleTypeChange = this.handleVehicleTypeChange.bind(this);
        this.handleRadVehicleTypeTypeChange = this.handleRadVehicleTypeTypeChange.bind(this);
        this.handleFromDateChanged = this.handleFromDateChanged.bind(this);
        this.handleToDateChanged = this.handleToDateChanged.bind(this);
        this.handleWrokingHoursChanged = this.handleWrokingHoursChanged.bind(this);
        this.handleWorkingHoursKeyDown = this.handleWorkingHoursKeyDown.bind(this);
        this.handleDriverWorkHistoryReportSubmit = this.handleDriverWorkHistoryReportSubmit.bind(this);
        this.downloadDriverWorkHistoryAsPdf = this.downloadDriverWorkHistoryAsPdf.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
    }
    async componentDidMount() {
        this.setState({
            loadPanelVisible: true,
        });
        await this.getDrivers();
        await this.getVehicleTypeList();
        this.setState({
            loadPanelVisible: false,
        });
    }
    async getDrivers(e) {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('driver-api/dropdown/', requestParams);
            const data = await response.json();
            this.setState({
                dropdownDrivers: data.driversDropdownList
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    async getVehicleTypeList() {
        try {
            var apiVehicleTypeRequest = new Object();
            var apiVehicleTypeRequest = new Object();
            const requestParams = getRequestParams('POST', apiVehicleTypeRequest);
            const response = await fetch('/vehicletype-api/vehicletype', requestParams);
            const data = await response.json();
            if (data.isSuccess) {
                this.setState({
                    vehicleTypeList: data.apiVehicleType
                });
            }
        } catch (e) {
            console.log(e);
        }
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleReportTypeChange(e) {
        this.setState({
            reportType: e.value
        });
    }
    handleDriverTypeChange(e) {
        this.setState({ driverType: e.target.value });
    }
    handleDateTypeChange(e) {
        this.setState({ radDateType: e.target.value });
    }
    handleRadVehicleTypeTypeChange(e) {
        this.setState({ radVehicleType: e.target.value });
    }
    handleDriverChange(e) {
        this.setState({
            driverId: (e.selectedItem != null ? e.selectedItem.id : 0)
        });        
    }
    handleWrokingHoursChanged(e) {
        this.setState({
            workingHourvalue: e.value
        });
    }
    handleWorkingHoursKeyDown(e) {
        const event = e.event;
        const str = event.key || String.fromCharCode(event.which);
        if (/^[.,e]$/.test(str)) {
            event.preventDefault();
        }
    }
    handleFromDateChanged(event) {
        let fromDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ startDate: fromDateValue })
    }
    handleToDateChanged(event) {
        let toDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ endDate: toDateValue })
    }
    handleVehicleTypeChange(e) {
        if (e != null) {
            this.setState({
                vehicleTypeId: e.vehicleTypeId,
                vehicleType: e.vehicleTypeCode,
            });
        }
        else {
            this.setState({ vehicleTypeId: 0, vehicleType: "" });
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getDriversDropdownList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isOpenModal: false,
                isEmailSubmitted: false,
                loadPanelVisible: false,
                reportTypeList: reportTypeRadio,
                reportType: reportTypeRadio[0],
                driverId: 0,
                driverType: "0",
                vehicleTypeId: 0,
                vehicleType: "",
                radVehicleType: "0",
                startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
                endDate: new Date(),
                radDateType: "0",
                maxWorkingHours: 1000,
                workingHourvalue: 0,
                errors: {
                    driverId: 0,
                    vehicleTypeId: 0,
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnInputChange = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
        } catch (ex) {
            console.log(ex);
        }
    }
    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;

        errors.driverId = 0;
        errors.vehicleTypeId = 0;

        this.setState({ message: '' });

        switch (parseInt(this.state.driverType)) {
            case 1:
                if (this.state.driverId == 0) {
                    formIsValid = false;
                    errors.driverId = "Please select driver";
                }
                break;
        }

        switch (parseInt(this.state.radVehicleType)) {
            case 1:
                if (this.state.vehicleTypeId == "") {
                    formIsValid = false;
                    errors.vehicleTypeId = "Please select vehicle type";
                }
                break;
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    handleDriverWorkHistoryReportSubmit(e) {
        e.preventDefault();
        this.downloadDriverWorkHistoryAsPdf(e);
    }
    async downloadDriverWorkHistoryAsPdf(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            try {
                this.setState({
                    loadPanelVisible: true,
                    isSubmited: true,
                });
                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.fileFormat = 'pdf';
                request.ReportType = parseInt(this.state.reportType.id);
                request.MethodName = "GetDriverWorkHourHistory";
                request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy");
                request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy");
                request.VehicleTypeId = this.state.vehicleTypeId;
                request.DriverId = this.state.driverId;
                request.MinimumWorkingHours = parseInt(this.state.workingHourvalue);

                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();

                let a = document.createElement('a');
                a.href = dataResponse.reportBase64String;
                a.download = 'DriverWorkHistory.pdf';
                a.click();

                this.setState({
                    loadPanelVisible: false,
                    isSubmited: false,
                });
            } catch (e) {
                debugger;
            }
        }
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isEmailSubmitted: true });
                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.fileFormat = 'pdf';
                request.ReportType = parseInt(this.state.reportType.id);
                request.MethodName = "GetDriverWorkHourHistory";
                request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy");
                request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy");
                request.VehicleTypeId = this.state.vehicleTypeId;
                request.DriverId = this.state.driverId;
                request.MinimumWorkingHours = parseInt(this.state.workingHourvalue);

                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();

                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;

                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Driver Work Hour History';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = 'DriverWorkHistory.pdf'
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }

        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    handleClearSearch() {
        this.setState({
            isSubmitted: false,
            isOpenModal: false,
            isEmailSubmitted: false,
            loadPanelVisible: false,
            reportTypeList: reportTypeRadio,
            reportType: reportTypeRadio[0],
            driverId: 0,
            driverType: "0",
            vehicleTypeId: 0,
            vehicleType: "",
            radVehicleType: "0",
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),
            radDateType: "0",
            maxWorkingHours: 1000,
            workingHourvalue: 0,
            errors: {
                driverId: 0,
                vehicleTypeId: 0,
            }
        });
    }

    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '50%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleOnReportSubmit}
                        style={(this.state.isSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Driver Work Hours History'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Report Type</label>
                                        <RadioGroup id="radio-group-sortby" dataSource={this.state.reportTypeList} displayExpr="name" value={this.state.reportType} onValueChanged={this.handleReportTypeChange} layout="horizontal" />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radDriverType" value="0" checked={this.state.driverType === "0"} onChange={this.handleDriverTypeChange} />
                                            <label className="form-check-label">All</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radDriverType" value="1" checked={this.state.driverType === "1"} onChange={this.handleDriverTypeChange} />
                                            <label className="form-check-label">Single Driver</label>
                                        </span>
                                        <SelectBox
                                            dataSource={this.state.dropdownDrivers.length > 0 ? this.state.dropdownDrivers : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select driver"
                                            name="driverId"
                                            defaultValue={[]}
                                            value={this.state.driverId === 0 ? [] : [this.state.dropdownDrivers.find(opt => opt.id === this.state.driverId)]}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleDriverChange}
                                            showClearButton={true}
                                            disabled={this.state.driverType === "1" ? false : true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["driverId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["driverId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radVehicleType" value="0" checked={this.state.radVehicleType === "0"} onChange={this.handleRadVehicleTypeTypeChange} />
                                            <label className="form-check-label">All</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radVehiclerType" value="1" checked={this.state.radVehicleType === "1"} onChange={this.handleRadVehicleTypeTypeChange} />
                                            <label className="form-check-label">Single Vehicle Type</label>
                                        </span>
                                        <SelectBox
                                            dataSource={this.state.vehicleTypeList.length > 0 ? this.state.vehicleTypeList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select vehicle type"
                                            name="vehicleTypeId"
                                            defaultValue={[]}
                                            value={this.state.vehicleTypeId == "" ? [] : [this.state.vehicleTypeList.find(opt => opt.vehicleTypeId == this.state.vehicleTypeId)]}
                                            displayExpr='apiVehicleTypeDescription'
                                            valueExpr='vehicleTypeId'
                                            onSelectionChanged={this.handleVehicleTypeChange}
                                            showClearButton={true}
                                            disabled={this.state.radVehicleType === "1" ? false : true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["vehicleTypeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["vehicleTypeId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radDateType" value="0" checked={this.state.radDateType === "0"} onChange={this.handleDateTypeChange} />
                                            <label className="form-check-label">All</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radDateType" value="1" checked={this.state.radDateType === "1"} onChange={this.handleDateTypeChange} />
                                            <label className="form-check-label">Date Range</label>
                                        </span>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.startDate}
                                            value={this.state.startDate}
                                            onValueChanged={this.handleFromDateChanged}
                                            width="100%"
                                            disabled={this.state.radDateType === "1" ? false : true}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label><br /></label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.endDate}
                                            value={this.state.endDate}
                                            onValueChanged={this.handleToDateChanged}
                                            width="100%"
                                            disabled={this.state.radDateType === "1" ? false : true}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Working Hours</label>
                                        <NumberBox
                                            defaultValue={0}
                                            min={0}
                                            max={1000}
                                            value={this.state.workingHourvalue}
                                            showSpinButtons={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="submit" className="btn btn-success btn-sm" title="Download" onClick={this.handleDriverWorkHistoryReportSubmit} >
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                                </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}