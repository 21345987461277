import React, { Component } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
    Selection,
    Sorting,
    Scrolling,
    MasterDetail
} from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1

import { SelectBox } from 'devextreme-react/select-box';
import Swal from 'sweetalert2';
import DateBox from 'devextreme-react/date-box';
import withReactContent from 'sweetalert2-react-content';
import NumberFormat from 'react-number-format';
import NumericInput from 'react-numeric-input'; //npm install react-numeric-input --save
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { LoadPanel } from 'devextreme-react/load-panel';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

const MySwal = withReactContent(Swal)
const position = { of: '#bookingdiv' };
let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class ScheduleComponent extends Component {
    constructor(props) {
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        super(props);

        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            scheduleGroupId: this.props.location.state == undefined ? 0 : this.props.location.state.schedulesGroupId,
            sourceUrl: this.props.location.state == undefined ? "" : this.props.location.state.sourceUrl, // Send from calling paging (Quotation list)
            sourceState: this.props.location.state == undefined ? "" : this.props.location.state.sourceState,  // Send from calling paging (Quotation list)
            customerList: [],
            customerId: 0,
            routeList: [],
            routeId: 0,
            intervalTypeId: "1",
            isRecurring: false,
            isActive: true,
            startTime: new Date(currentTime.toLocaleDateString() + " 00:00"),
            endTime: new Date(currentTime.toLocaleDateString() + " 23:59"),
            interval: 1,
            randomTime: currentTime,
            isWeekSun: false,
            isWeekMon: false,
            isWeekTue: false,
            isWeekWed: false,
            isWeekThu: false,
            isWeekFri: false,
            isWeekSat: false,

            fixedTimeDataSource: [],
            ranTimeDataSource: [],

            errors: {
                customerId: "",
                routeId: "",
            }
        };
        this.getCustomerList = this.getCustomerList.bind(this);
        this.getRouteList = this.getRouteList.bind(this);
        this.handleCustomerChange = this.handleCustomerChange.bind(this);
        this.handleRouteChange = this.handleRouteChange.bind(this);
        this.goToBack = this.goToBack.bind(this);
        this.handleStartDateValueChanged = this.handleStartDateValueChanged.bind(this);
        this.handleEndDateValueChanged = this.handleEndDateValueChanged.bind(this);
        this.handleIntervalChange = this.handleIntervalChange.bind(this);
        this.handleRandomTimeValueChanged = this.handleRandomTimeValueChanged.bind(this);
        this.handleScheduleSubmit = this.handleScheduleSubmit.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleTripTypeChange = this.handleTripTypeChange.bind(this);

        this.handleFixedTime = this.handleFixedTime.bind(this);
        this.handleClearFixedTime = this.handleClearFixedTime.bind(this);

        this.handleRandomTime = this.handleRandomTime.bind(this);
        this.deleteRandomTime = this.deleteRandomTime.bind(this);
        this.handleClearRandomTime = this.handleClearRandomTime.bind(this);
        this.deleteSchedule = this.deleteSchedule.bind(this);

    };

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true
        });
        await this.getCustomerList();

        if (this.state.scheduleGroupId > 0) {

            var shuttleRouteScheduleGroupRequest = new Object();
            shuttleRouteScheduleGroupRequest.ShuttleRouteScheduleGroup = new Object();
            shuttleRouteScheduleGroupRequest.LocationId = Number(sessionStorage.getItem("session_locationId"));
            shuttleRouteScheduleGroupRequest.ShuttleRouteScheduleGroup.RouteId = 0;
            shuttleRouteScheduleGroupRequest.ShuttleRouteScheduleGroup.CustomerId = Number(0);
            shuttleRouteScheduleGroupRequest.ShuttleRouteScheduleGroup.SchedulesGroupId = Number(this.state.scheduleGroupId);
            this.setState({ loadPanelVisible: true });
            try {
                const requestParams = getRequestParams('POST', shuttleRouteScheduleGroupRequest);
                const response = await fetch('/shuttle-schedule-api/group', requestParams);
                const data = await response.json();
                if (data.shuttleRouteScheduleGroup.length > 0) {
                    var flag = false;
                    if (data.shuttleRouteScheduleGroup[0].mon == true) {
                        flag = true;
                    }
                    if (data.shuttleRouteScheduleGroup[0].tue == true) {
                        flag = true;
                    }
                    if (data.shuttleRouteScheduleGroup[0].wed == true) {
                        flag = true;
                    }
                    if (data.shuttleRouteScheduleGroup[0].thu == true) {
                        flag = true;
                    }
                    if (data.shuttleRouteScheduleGroup[0].fri == true) {
                        flag = true;
                    }
                    if (data.shuttleRouteScheduleGroup[0].sat == true) {
                        flag = true;
                    }
                    if (data.shuttleRouteScheduleGroup[0].sun == true) {
                        flag = true;
                    }

                    this.setState({
                        customerId: data.shuttleRouteScheduleGroup[0].customerId,
                        intervalTypeId: data.shuttleRouteScheduleGroup[0].scheduleTypeId.toString(),
                        isRecurring: flag,
                        isActive: data.shuttleRouteScheduleGroup[0].isActive,
                        startTime: new Date(currentTime.toLocaleDateString() + " " + data.shuttleRouteScheduleGroup[0].scheduleStartTime),
                        endTime: new Date(currentTime.toLocaleDateString() + " " + data.shuttleRouteScheduleGroup[0].scheduleEndTime),
                        interval: data.shuttleRouteScheduleGroup[0].schedueInterval,
                        randomTime: currentTime,
                        isWeekSun: data.shuttleRouteScheduleGroup[0].sun,
                        isWeekMon: data.shuttleRouteScheduleGroup[0].mon,
                        isWeekTue: data.shuttleRouteScheduleGroup[0].tue,
                        isWeekWed: data.shuttleRouteScheduleGroup[0].wed,
                        isWeekThu: data.shuttleRouteScheduleGroup[0].thu,
                        isWeekFri: data.shuttleRouteScheduleGroup[0].fri,
                        isWeekSat: data.shuttleRouteScheduleGroup[0].sat,

                    })

                    let myVar = setTimeout(() => this.getRouteList(), 200)

                    this.setState({
                        routeId: data.shuttleRouteScheduleGroup[0].routeId,
                    })

                    var request = new Object();
                    request.ShuttleRouteSchedule = new Object();
                    request.ShuttleRouteSchedule.ScheduleId = 0;
                    request.ShuttleRouteSchedule.RouteId = 0;
                    request.ShuttleRouteSchedule.ReservationId = 0;
                    request.ShuttleRouteSchedule.CustomerId = 0;
                    request.ShuttleRouteSchedule.SchedulesGroupId = data.shuttleRouteScheduleGroup[0].schedulesGroupId;
                    const requestParamsSchedule = getRequestParams('POST', request);
                    const responseSchedule = await fetch('/shuttle-schedule-api/details', requestParamsSchedule);
                    const dataSchedule = await responseSchedule.json();

                    if (data.shuttleRouteScheduleGroup[0].scheduleTypeId == 1) {
                        var fixedTimeArray = [];
                        for (var i = 0; i < dataSchedule.shuttleRouteSchedule.length; i++) {
                            var intervalObj = new Object();
                            intervalObj.ScheduleId = dataSchedule.shuttleRouteSchedule[i].scheduleId;
                            intervalObj.SrNo = i + 1;
                            var startCal = new Date(currentTime.toLocaleDateString() + " " + dataSchedule.shuttleRouteSchedule[i].scheduleTime)
                            intervalObj.StartTime = this.getHourMinutes(startCal);

                            var dt = new Date(startCal);
                            dt.setMinutes(dt.getMinutes() + parseInt(data.shuttleRouteScheduleGroup[0].schedueInterval));
                            startCal = dt;
                            intervalObj.EndTime = this.getHourMinutes(startCal);
                            intervalObj.Interval = parseInt(data.shuttleRouteScheduleGroup[0].schedueInterval);
                            fixedTimeArray.push(intervalObj);
                        }

                        this.setState({
                            fixedTimeDataSource: fixedTimeArray
                        })
                    } else {
                        var randomTimeArray = [];

                        for (var i = 0; i < dataSchedule.shuttleRouteSchedule.length; i++) {
                            var intervalObj = new Object();
                            intervalObj.ScheduleId = dataSchedule.shuttleRouteSchedule[i].scheduleId;
                            intervalObj.SrNo = i + 1;
                            var startCal = new Date(currentTime.toLocaleDateString() + " " + dataSchedule.shuttleRouteSchedule[i].scheduleTime)
                            intervalObj.StartTime = this.getHourMinutes(startCal);
                            randomTimeArray.push(intervalObj);
                        }
                        this.setState({
                            ranTimeDataSource: randomTimeArray
                        })
                    }
                }
                this.setState({ loadPanelVisible: false });
            } catch (e) {
                this.setState({ loadPanelVisible: false });
            }


        }

        this.setState({
            loadPanelVisible: false
        });
    }

    async getCustomerList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('account-api/customer-dropdown/', requestParams);
        const data = await response.json();
        if (data.isSuccess) {
            this.setState({
                //customerList: data.customers != null ? data.customers.filter(f => (f.customerTypeId == 5 || f.customerTypeId == 14 || f.customerTypeId == 15 || f.customerTypeId == 16)) : [],
                customerList: data.customers != null ? data.customers : [],
            });
        }
    }

    handleCustomerChange(selectedOption) {
        this.setState({ customerId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
        this.state.errors["customerId"] = "";
        let myVar = setTimeout(() => this.getRouteList(), 200)
    }

    async getRouteList() {

        if (this.state.customerId <= 0) {
            this.setState({
                routeList: [], routeId: 0
            });
            return;
        }

        var shuttleRoutesRequest = new Object();
        shuttleRoutesRequest.LocationId = Number(sessionStorage.getItem("session_locationId"));

        this.setState({ loadPanelVisible: true });
        try {
            const requestParams = getRequestParams('POST', shuttleRoutesRequest);
            const response = await fetch('/route-api/0/list', requestParams);
            const data = await response.json();
            if (data.shuttleRoutes != null && data.shuttleRoutes.length > 0) {
                this.setState({
                    routeList: data.shuttleRoutes.filter(f => f.customerId == this.state.customerId)
                });
            }
            this.setState({ loadPanelVisible: false });
        } catch (e) {
            this.setState({ loadPanelVisible: false });
        }
    }

    handleRouteChange(selectedOption) {
        this.setState({ routeId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.routeId : 0), })
        this.state.errors["routeId"] = "";

        if (selectedOption.selectedItem != null && this.state.intervalTypeId === "1") {
            this.setState({ interval: selectedOption.selectedItem.routeDuration })
        } else {
            this.setState({ interval: 1 })
        }
    }

    goToBack() {
        this.props.history.push({
            pathname: this.state.sourceUrl,
            state: this.state.sourceState
        })
    }

    handleStartDateValueChanged(e) {
        this.setState({ startTime: e.value });
    }
    handleEndDateValueChanged(e) {
        this.setState({ endTime: e.value });
    }

    handleIntervalChange(value) {
        this.setState({ interval: value });
    }

    handleRandomTimeValueChanged(e) {
        this.setState({ randomTime: e.value });
    }

    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.customerId = "";
        errors.routeName = "";

        if (parseInt(this.state.customerId) == 0) {
            formIsValid = false;
            errors.customerId = "Please select customer.";
        }

        if (parseInt(this.state.routeId) == 0) {
            formIsValid = false;
            errors.routeId = "Please select route.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    async askScheduleSaveConfirm() {
        let isResult = false;
        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: "You are going to save a new schedule(s).<br> Do you want to still continue?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    async askScheduleUpdateConfirm() {
        let isResult = false;
        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: "You are going to update a schedule(s).<br> Do you want to still continue?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    async handleScheduleSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {

            if (this.state.intervalTypeId === "1" && this.state.fixedTimeDataSource.length <= 0) {
                const isValid = await Swal.fire({
                    title: "<small>Schedule Not Found</small>",
                    text: "Please enter schedule.",
                    icon: "success",
                    width: '25%',
                    allowOutsideClick: false
                });
                return;
            }

            if (this.state.intervalTypeId === "2" && this.state.ranTimeDataSource.length <= 0) {
                const isValid = await Swal.fire({
                    title: "<small>Schedule Not Found</small>",
                    text: "Please enter schedule.",
                    icon: "success",
                    width: '25%',
                    allowOutsideClick: false
                });
                return;
            }

            if (Number(this.state.scheduleGroupId) <= 0) {

                const isConfirm = await this.askScheduleSaveConfirm();
                if (!isConfirm) {
                    return;
                }
                this.setState({ isSubmited: true });
                var newObject = new Object();
                newObject.ShuttleRouteScheduleGroup = new Object();
                newObject.ShuttleRouteScheduleGroup.SchedulesGroupId = Number(this.state.scheduleGroupId);
                newObject.ShuttleRouteScheduleGroup.RouteId = Number(this.state.routeId);
                newObject.ShuttleRouteScheduleGroup.CustomerId = Number(this.state.customerId);
                newObject.ShuttleRouteScheduleGroup.ScheduleTypeId = Number(this.state.intervalTypeId);
                newObject.ShuttleRouteScheduleGroup.ShuttleCurrentDate = "";
                newObject.ShuttleRouteScheduleGroup.IsActive = this.state.isActive;
                newObject.ShuttleRouteScheduleGroup.SchedueInterval = Number(this.state.interval);
                if (this.state.intervalTypeId === "1") {
                    newObject.ShuttleRouteScheduleGroup.ScheduleStartTime = currentTime.toLocaleDateString() + " " + this.getHourMinutes(this.state.startTime);
                    newObject.ShuttleRouteScheduleGroup.ScheduleEndTime = currentTime.toLocaleDateString() + " " + this.getHourMinutes(this.state.endTime);
                } else {
                    newObject.ShuttleRouteScheduleGroup.ScheduleStartTime = currentTime.toLocaleDateString() + " " + this.state.ranTimeDataSource[0].StartTime;
                    newObject.ShuttleRouteScheduleGroup.ScheduleEndTime = currentTime.toLocaleDateString() + " " + this.state.ranTimeDataSource[this.state.ranTimeDataSource.length - 1].StartTime;
                }
                newObject.ShuttleRouteScheduleGroup.Mon = this.state.isWeekMon;
                newObject.ShuttleRouteScheduleGroup.Tue = this.state.isWeekTue;
                newObject.ShuttleRouteScheduleGroup.Wed = this.state.isWeekWed;
                newObject.ShuttleRouteScheduleGroup.Thu = this.state.isWeekThu;
                newObject.ShuttleRouteScheduleGroup.Fri = this.state.isWeekFri;
                newObject.ShuttleRouteScheduleGroup.Sat = this.state.isWeekSat;
                newObject.ShuttleRouteScheduleGroup.Sun = this.state.isWeekSun;

                newObject.ShuttleRouteSchedule = new Object();
                newObject.ShuttleRouteSchedule.ScheduleId = Number(0);
                newObject.ShuttleRouteSchedule.RouteId = Number(this.state.routeId);
                newObject.ShuttleRouteSchedule.CustomerId = Number(this.state.customerId);
                newObject.ShuttleRouteSchedule.IsActive = true;
                newObject.ShuttleRouteSchedule.Mon = this.state.isWeekMon;
                newObject.ShuttleRouteSchedule.Tue = this.state.isWeekTue;
                newObject.ShuttleRouteSchedule.Wed = this.state.isWeekWed;
                newObject.ShuttleRouteSchedule.Thu = this.state.isWeekThu;
                newObject.ShuttleRouteSchedule.Fri = this.state.isWeekFri;
                newObject.ShuttleRouteSchedule.Sat = this.state.isWeekSat;
                newObject.ShuttleRouteSchedule.Sun = this.state.isWeekSun;
                newObject.ShuttleRouteSchedule.StopIdPaxNo = "";

                var scheduleTimeStr = "";
                if (this.state.intervalTypeId === "1") {
                    for (var i = 0; i < this.state.fixedTimeDataSource.length; i++) {
                        scheduleTimeStr = scheduleTimeStr + this.state.fixedTimeDataSource[i].StartTime + ",";
                    }
                } else {
                    for (var i = 0; i < this.state.ranTimeDataSource.length; i++) {
                        scheduleTimeStr = scheduleTimeStr + this.state.ranTimeDataSource[i].StartTime + ",";
                    }
                }
                newObject.ShuttleRouteSchedule.ScheduleTime = scheduleTimeStr.replace(/,\s*$/, "");

                try {
                    const requestParams = getRequestParams('POST', newObject);
                    const response = await fetch('shuttle-schedule-api/save', requestParams);
                    const data = await response.json();

                    Swal.fire({
                        icon: data.isSuccess ? 'success' : 'error',
                        title: data.isSuccess ? 'Success' : 'Oops...',
                        text: data.resultMessage,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (data.isSuccess) {
                            if (result.isConfirmed) {
                                this.goToBack();
                            }
                        } else {
                            this.setState({ isSubmited: false })
                        }
                    })
                } catch (e) {
                    this.setState({ isSubmited: false })
                }
            } else {

                const isConfirm = await this.askScheduleUpdateConfirm();
                if (!isConfirm) {
                    return;
                }
                this.setState({ isSubmited: true });
                var newObject = new Object();
                newObject.UpdateShuttleRouteSchedule = new Object();
                newObject.UpdateShuttleRouteSchedule.ScheduleGroupId = (this.state.scheduleGroupId).toString();
               
                newObject.UpdateShuttleRouteSchedule.ScheduleGroupEnable = this.state.isActive;
                newObject.UpdateShuttleRouteSchedule.Mon = this.state.isWeekMon;
                newObject.UpdateShuttleRouteSchedule.Tue = this.state.isWeekTue;
                newObject.UpdateShuttleRouteSchedule.Wed = this.state.isWeekWed;
                newObject.UpdateShuttleRouteSchedule.Thu = this.state.isWeekThu;
                newObject.UpdateShuttleRouteSchedule.Fri = this.state.isWeekFri;
                newObject.UpdateShuttleRouteSchedule.Sat = this.state.isWeekSat;
                newObject.UpdateShuttleRouteSchedule.Sun = this.state.isWeekSun;

                var scheduleTimeStr = "";
                if (this.state.intervalTypeId === "1") {
                    for (var i = 0; i < this.state.fixedTimeDataSource.length; i++) {
                        scheduleTimeStr = scheduleTimeStr + this.state.fixedTimeDataSource[i].ScheduleId + ",";
                    }
                } else {
                    for (var i = 0; i < this.state.ranTimeDataSource.length; i++) {
                        scheduleTimeStr = scheduleTimeStr + this.state.ranTimeDataSource[i].ScheduleId + ",";
                    }
                }
                newObject.UpdateShuttleRouteSchedule.ScheduleId = scheduleTimeStr.replace(/,\s*$/, "");
                debugger;
                try {
                    const requestParams = getRequestParams('POST', newObject);
                    const response = await fetch('shuttle-schedule-api/update', requestParams);
                    const data = await response.json();

                    Swal.fire({
                        icon: data.isSuccess ? 'success' : 'error',
                        title: data.isSuccess ? 'Success' : 'Oops...',
                        text: data.resultMessage,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (data.isSuccess) {
                            if (result.isConfirmed) {
                                this.goToBack();
                            }
                        } else {
                            this.setState({ isSubmited: false })
                        }
                    })
                } catch (e) {
                    this.setState({ isSubmited: false })
                }

            }
        }

    }

    handleClearSearch(e) {
        e.preventDefault();
        this.setState({
            customerId: 0,
            routeId: 0,
            intervalTypeId: "1",
            isRecurring: false,
            isActive: true,
            scheduleGroupId: 0,
            startTime: new Date(currentTime.toLocaleDateString() + " 00:00"),
            endTime: new Date(currentTime.toLocaleDateString() + " 23:59"),
            interval: 1,
            randomTime: currentTime,
            isWeekSun: false,
            isWeekMon: false,
            isWeekTue: false,
            isWeekWed: false,
            isWeekThu: false,
            isWeekFri: false,
            isWeekSat: false,

            fixedTimeDataSource: [],
            ranTimeDataSource: [],

            errors: {
                customerId: "",
                routeId: "",
            }
        })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
        this.state.errors[name] = "";

        if (name == "isRecurring") {
            this.setState({
                isWeekSun: value,
                isWeekMon: value,
                isWeekTue: value,
                isWeekWed: value,
                isWeekThu: value,
                isWeekFri: value,
                isWeekSat: value,
            })
        }
    }

    handleTripTypeChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ intervalTypeId: value, fixedTimeDataSource: [], ranTimeDataSource: [] });
    }

    async handleFixedTime(e) {
        e.preventDefault();
        if (this.state.interval == null || this.state.interval == 0) {
            const isValid = await Swal.fire({
                title: "<small>Invalid Interval</small>",
                text: "Please select time interval.",
                icon: "info",
                width: '25%',
                dangerMode: false,
                allowOutsideClick: false
            });
            return;
        }

        var fixedTimeArray = [];
        var start = new Date(this.state.startTime);
        var end = new Date(this.state.endTime);
        if (end <= start) {
            const isValid = await Swal.fire({
                title: "<small>Invalid Time Schedule</small>",
                text: "Please select end time grater than start time.",
                icon: "info",
                width: '25%',
                dangerMode: false,
                allowOutsideClick: false
            });
            return;
        }

        var diff = (end.getTime() - start.getTime()) / 1000;

        var diffInMinutes = Math.abs(Math.round(diff / 60))

        if (diffInMinutes < parseInt(this.state.interval)) {
            const isValid = await Swal.fire({
                title: "<small>Invalid Interval</small>",
                text: "Please enter valid interval time for schedule start time and schedule end time.",
                icon: "info",
                width: '25%',
                dangerMode: false,
                allowOutsideClick: false
            });
            return;
        }
        var startCal = start;
        var sr = 1;

        while (startCal < end) {
            var intervalObj = new Object();
            intervalObj.ScheduleId = 0;
            intervalObj.SrNo = sr;
            intervalObj.StartTime = this.getHourMinutes(startCal);

            var dt = new Date(startCal);
            dt.setMinutes(dt.getMinutes() + parseInt(this.state.interval));
            startCal = dt;
            intervalObj.EndTime = this.getHourMinutes(startCal);
            intervalObj.Interval = parseInt(this.state.interval);
            fixedTimeArray.push(intervalObj);
            sr = sr + 1;
        }

        this.setState({
            fixedTimeDataSource: fixedTimeArray
        })
    }

    handleClearFixedTime(e) {
        e.preventDefault();
        this.setState({
            fixedTimeDataSource: []
        })
    }

    async handleRandomTime(e) {
        e.preventDefault();
        var start = new Date(this.state.randomTime);
        var randomTimeArray = [];
        randomTimeArray = this.state.ranTimeDataSource

        var index = randomTimeArray.findIndex(f => f.StartTime == this.getHourMinutes(start));
        if (index != -1) {
            const isValid = await Swal.fire({
                title: "<small>Duplicate Time</small>",
                text: "Duplicate time found. Please add other time.",
                icon: "success",
                width: '25%',
                allowOutsideClick: false
            });
            return;
        }
        for (var i = 0; i < randomTimeArray.length; i++) {
            if (new Date(currentTime.toLocaleDateString() + " " + randomTimeArray[i].StartTime) > start) {
                const isValid = await Swal.fire({
                    title: "<small>Invalid Time</small>",
                    text: "Please enter schedule time is greater than previous entered schedule time.",
                    icon: "success",
                    width: '25%',
                    allowOutsideClick: false
                });
                return;
            }
        }

        var intervalObj = new Object();
        intervalObj.ScheduleId = 0;
        intervalObj.SrNo = randomTimeArray.length + 1;
        intervalObj.StartTime = this.getHourMinutes(start);
        randomTimeArray.push(intervalObj);

        this.setState({
            ranTimeDataSource: randomTimeArray
        })
    }

    deleteRandomTime(j) {
        const timeList = this.state.ranTimeDataSource;
        timeList.splice(j, 1);

        this.setState(state => ({
            ranTimeDataSource: timeList
        }));
    }

    handleClearRandomTime(e) {
        e.preventDefault();
        this.setState({
            ranTimeDataSource: []
        })
    }

    getHourMinutes(datetime) {
        var HH = new Date(datetime).getHours();
        var MM = new Date(datetime).getMinutes();

        if (parseInt(HH) < 10) {
            HH = "0" + HH;
        }

        if (parseInt(MM) < 10) {
            MM = "0" + MM;
        }

        return HH + ":" + MM;
    }

    async isAllowConfirm() {
        let isResult = false;
        await Swal.fire({
            icon: 'question',
            title: 'Delete',
            html: "Are you sure want to delete schedule?",
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Delete',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        });
        return isResult;
    }

    async deleteSchedule(e, rowdata) {
        e.preventDefault();
        try {
            const isConfirm = await this.isAllowConfirm();
            if (!isConfirm) {
                return;
            }
            const timeList = this.state.fixedTimeDataSource;
            var index = this.state.fixedTimeDataSource.findIndex(f => f.SrNo == rowdata.SrNo);

            timeList.splice(index, 1);

            this.setState({ fixedTimeDataSource: timeList });
            const dataSource = this.dataGrid.instance.getDataSource();
            dataSource.reload();
        } catch (e) {
        }
    }

    render() {
        let pageTitle = this.state.scheduleGroupId > 0 ? "Modify Schedule" : "New Schedule";
        let saveTitle = this.state.scheduleGroupId > 0 ? "Update" : "Save";
        const renderGridCell = (data) => {

            return <div>
                <a href="#" className="fas fa-times icon text-info" title="Delete" onClick={(e) => { this.deleteSchedule(e, data.data) }}></a>&nbsp;&nbsp;
            </div>;
        }
        return (
            <div className="content-wrapper" id="bookingdiv">
                <form method="post" onSubmit={this.handleScheduleSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="content-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <h1 className="m-0 text-dark">{pageTitle}</h1>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <NavItem><NavLink tag={Link} to="/stop/list" className="btn btn-block btn-outline-secondary btn-sm" title="Stops"><i className="fas fa-map-marker"></i> Stops</NavLink></NavItem>
                                        <NavItem><NavLink tag={Link} to="/route/list" className="btn btn-block btn-outline-secondary btn-sm" title="Routes"><i className="fas fa-road"></i> Routes</NavLink></NavItem>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rez-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>Customer</label>
                                        <SelectBox
                                            dataSource={this.state.customerList.length > 0 ? this.state.customerList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="customerId"
                                            defaultValue={0}
                                            value={this.state.customerId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleCustomerChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>Route</label>
                                        <SelectBox
                                            dataSource={this.state.routeList.length > 0 ? this.state.routeList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select route"
                                            name="routeId"
                                            defaultValue={0}
                                            value={this.state.routeId}
                                            displayExpr='routeName'
                                            valueExpr='routeId'
                                            onSelectionChanged={this.handleRouteChange}
                                            showClearButton={true}
                                            disabled={this.state.scheduleGroupId > 0}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["routeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["routeId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12" style={{ paddingTop: 24 + 'px' }}>
                                    <div className="form-group">
                                        <label htmlFor="">&nbsp;</label>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="fixInterval" value="1" disabled={this.state.scheduleGroupId > 0} checked={this.state.intervalTypeId === "1"} onChange={this.handleTripTypeChange} />
                                            <label className="form-check-label">&nbsp;Fixed Interval</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="randomInterval" value="2" disabled={this.state.scheduleGroupId > 0} checked={this.state.intervalTypeId === "2"} onChange={this.handleTripTypeChange} />
                                            <label className="form-check-label">&nbsp;Random Interval</label>
                                        </span>
                                        <span className="form-check ml-3">
                                            <input className="form-check-input" type="checkbox" checked={this.state.isRecurring} disabled={this.state.scheduleGroupId > 0} onChange={this.handleInputChange} name="isRecurring" />
                                            <label className="form-check-label">&nbsp;Recurring</label>
                                        </span>
                                        <span className="form-check ml-3" style={{ display: (this.state.scheduleGroupId <= 0) ? 'none' : 'inline-block' }}>
                                            <input className="form-check-input" type="checkbox" checked={this.state.isActive} onChange={this.handleInputChange} name="isActive" />
                                            <label className="form-check-label">&nbsp;Is Active</label>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-6 col-xs-12 text-right">
                                    <div className="form-group">
                                        <label className="btn-block">&nbsp;</label>
                                        <button type="button" className="btn btn-success btn-sm" onClick={this.handleScheduleSubmit} >
                                            {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSubmited && <span title="Save"><i className="fas fa-save"></i> {saveTitle}</span>}
                                        </button>&nbsp;&nbsp;&nbsp;
                                         <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleClearSearch} title="Clear"><i className="fas fa-eraser"></i> Clear</button>&nbsp;&nbsp;&nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.goToBack} title="Back"><i className="fas fa-arrow-left"></i> Back</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="card card-grid">
                                <div className="card-body">
                                    <div className="row">

                                        {this.state.intervalTypeId === "1" && <div className="col-lg-1 col-md-1 col-sm-6 col-xs-12">
                                            <div className="form-group">
                                                <label htmlFor="">Start Time <span className="text-danger">*</span></label>
                                                <DateBox type="time"
                                                    className="form-control form-control-sm"
                                                    name="startTime"
                                                    placeholder="HH:mm"
                                                    displayFormat="HH:mm"
                                                    showAnalogClock={true}
                                                    useMaskBehavior={true}
                                                    defaultValue={this.state.startTime}
                                                    value={this.state.startTime}
                                                    onValueChanged={this.handleStartDateValueChanged}
                                                    width="100%"
                                                    disabled={this.state.scheduleGroupId > 0}
                                                    acceptCustomValue={false}
                                                />
                                            </div>
                                        </div>}
                                        {this.state.intervalTypeId === "1" && <div className="col-lg-1 col-md-1 col-sm-6 col-xs-12">
                                            <div className="form-group">
                                                <label htmlFor="">End Time <span className="text-danger">*</span></label>
                                                <DateBox type="time"
                                                    className="form-control form-control-sm"
                                                    name="endTime"
                                                    placeholder="HH:mm"
                                                    displayFormat="HH:mm"
                                                    showAnalogClock={true}
                                                    useMaskBehavior={true}
                                                    defaultValue={this.state.endTime}
                                                    value={this.state.endTime}
                                                    onValueChanged={this.handleEndDateValueChanged}
                                                    width="100%"
                                                    disabled={this.state.scheduleGroupId > 0}
                                                    acceptCustomValue={false}
                                                    min={this.state.startTime}
                                                />
                                            </div>
                                        </div>}
                                        {this.state.intervalTypeId === "1" && <div className="col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                            <div className="form-group">
                                                <label htmlFor="">Interval <span className="text-danger">*</span></label>
                                                <NumericInput className="form-control form-control-sm" name="interval" title="Interval" disabled={this.state.scheduleGroupId > 0} value={this.state.interval} min={1} max={60} onChange={(value) => { this.handleIntervalChange(value) }} />
                                            </div>
                                        </div>}
                                        {this.state.intervalTypeId === "1" && <div className="col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                            <div className="form-group">
                                                <label className="btn-block">&nbsp;</label>
                                                <button type="button" className="btn btn-success btn-sm" disabled={this.state.scheduleGroupId > 0} onClick={this.handleFixedTime} title="Add"><i className="fas fa-plus"></i> Add</button>&nbsp;&nbsp;&nbsp;
                                                <button type="button" className="btn btn-custom-gray btn-sm" disabled={this.state.scheduleGroupId > 0} onClick={this.handleClearFixedTime} title="Clear"><i className="fas fa-eraser"></i> Remove</button>
                                            </div>
                                        </div>}
                                        {this.state.intervalTypeId === "2" && <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                            <div className="form-group">
                                                <label htmlFor="">Random Time <span className="text-danger">*</span></label>
                                                <DateBox type="time"
                                                    className="form-control form-control-sm"
                                                    name="randomTime"
                                                    placeholder="HH:mm"
                                                    displayFormat="HH:mm"
                                                    showAnalogClock={true}
                                                    useMaskBehavior={true}
                                                    defaultValue={this.state.randomTime}
                                                    value={this.state.randomTime}
                                                    onValueChanged={this.handleRandomTimeValueChanged}
                                                    width="100%"
                                                    disabled={this.state.scheduleGroupId > 0}
                                                    acceptCustomValue={false}
                                                />
                                            </div>
                                        </div>}
                                        {this.state.intervalTypeId === "2" && <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                            <div className="form-group">
                                                <label className="btn-block">&nbsp;</label>
                                                <button type="button" className="btn btn-success btn-sm" disabled={this.state.scheduleGroupId > 0} onClick={this.handleRandomTime} title="Add"><i className="fas fa-plus"></i> Add</button>&nbsp;&nbsp;&nbsp;
                                                <button type="button" className="btn btn-custom-gray btn-sm" disabled={this.state.scheduleGroupId > 0} onClick={this.handleClearRandomTime} title="Clear"><i className="fas fa-eraser"></i> Remove</button>
                                            </div>
                                        </div>}

                                        {this.state.isRecurring == true && <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12" style={{ paddingTop: 22 + 'px' }}>
                                            <div className="form-group">
                                                <span className="form-check">
                                                    <input className="form-check-input" type="checkbox" checked={this.state.isWeekSun} onChange={this.handleInputChange} name="isWeekSun" />
                                                    <label className="form-check-label">SUN</label>
                                                </span>
                                                <span className="form-check">
                                                    <input className="form-check-input" type="checkbox" checked={this.state.isWeekMon} onChange={this.handleInputChange} name="isWeekMon" />
                                                    <label className="form-check-label">MON</label>
                                                </span>
                                                <span className="form-check">
                                                    <input className="form-check-input" type="checkbox" checked={this.state.isWeekTue} onChange={this.handleInputChange} name="isWeekTue" />
                                                    <label className="form-check-label">TUE</label>
                                                </span>
                                                <span className="form-check">
                                                    <input className="form-check-input" type="checkbox" checked={this.state.isWeekWed} onChange={this.handleInputChange} name="isWeekWed" />
                                                    <label className="form-check-label">WED</label>
                                                </span>
                                                <span className="form-check">
                                                    <input className="form-check-input" type="checkbox" checked={this.state.isWeekThu} onChange={this.handleInputChange} name="isWeekThu" />
                                                    <label className="form-check-label">THU</label>
                                                </span>
                                                <span className="form-check">
                                                    <input className="form-check-input" type="checkbox" checked={this.state.isWeekFri} onChange={this.handleInputChange} name="isWeekFri" />
                                                    <label className="form-check-label">FRI</label>
                                                </span>
                                                <span className="form-check">
                                                    <input className="form-check-input" type="checkbox" checked={this.state.isWeekSat} onChange={this.handleInputChange} name="isWeekSat" />
                                                    <label className="form-check-label">SAT</label>
                                                </span>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                            <div className="card card-grid">
                                {this.state.intervalTypeId === "1" && <div className="card-body">
                                    <DataGrid
                                        dataSource={this.state.fixedTimeDataSource}
                                        keyExpr="SrNo"
                                        selection={{ mode: 'single' }}
                                        columnsAutoWidth={false}
                                        showColumnLines={false}
                                        showRowLines={true}
                                        showBorders={true}
                                        rowAlternationEnabled={true}
                                        allowColumnResizing={true}
                                        columnResizingMode='widget'
                                        ref={ref => this.dataGrid = ref}
                                    >
                                        <Column dataField="SrNo" width={80} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                        <Column dataField="SrNo" caption="Sr.No." width={100} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="StartTime" caption="Start Time" width={150} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="EndTime" caption="End Time" width={150} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="Interval" caption="Interval" width={100} allowFiltering={true} allowSorting={true} />

                                        <SortByGroupSummaryInfo summaryItem="count" />
                                        <GroupPanel visible={false} />
                                        <HeaderFilter visible={false} />
                                        <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                        <Grouping autoExpandAll={false} />
                                        <Paging defaultPageSize={20} />
                                        <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                        <Export enabled={false} allowExportSelectedData={false} />
                                        <ColumnChooser enabled={false} />
                                        <ColumnFixing enabled={false} />
                                    </DataGrid>
                                </div>}
                                {this.state.intervalTypeId === "2" && <div className="card-body">
                                    <div className="row">
                                        {this.state.ranTimeDataSource.length > 0 && this.state.ranTimeDataSource.map((item, j) =>
                                            <div className="col-lg-1" key={j} >
                                                <div className="input-group">
                                                    <input type="text" disabled={true} className="form-control" value={item.StartTime} />
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" onClick={() => { this.deleteRandomTime(j) }}><i className="fas fa-times"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>

                    <LoadPanel
                        shadingColor="rgba(0,0,0,0.4)"
                        position={position}
                        visible={this.state.loadPanelVisible}
                        showIndicator={true}
                        shading={true}
                        showPane={true}
                        closeOnOutsideClick={false}
                    />
                </form>
            </div>
        );
    }
}




