import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { CannedMessageType } from '../../helpers/fixcodes.js';
import { SelectBox } from 'devextreme-react/select-box';

const customStyles = {
    content: {
        width: '30%',
    }
};

const employeeList = [{ employeeNumber: "99999", crewName: "Jagdish Gosavi" },
{ employeeNumber: "88888", crewName: "Sharad Shitole" },
{ employeeNumber: "77777", crewName: "Adam Khan" }]

export class SendAirlineCrewMessageComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitted: false,
            isArilineCrewMessageIsOpen: false,
            singleData: null,
            employeesDropdownList: [],
            employeeNo: "",
            airlineCrewCannedMessageList: [],
            selMessageId: null,
            customMessage: '',
            chkCustomMessage: false,
            airlineCode: "",
            airlineDropdownList: [],
            errors: {
                employeeNo: "",
                errMessage: "",
                customMessage: "",
                airlineCode: "",
            }
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCustomMessage = this.handleCustomMessage.bind(this);
        this.getCannedMessage = this.getCannedMessage.bind(this);
    }

    showAirlineCrewMessageModal = (data) => {
        this.setState({ isArilineCrewMessageIsOpen: true, singleData: data, airlineCode: data?.airlineCode ?? ''});
    }

    closeSendAirlineCrewMessageModal = () => {
        this.setState({
            isArilineCrewMessageIsOpen: false,
            isSubmitted: false,
            singelData: null,
            selMessageId: null,
            customMessage: '',
            refreshRideSource: null,
            handleRefresh: null,
            employeeNo: "",
            airlineCode: "",
            employeesDropdownList: [],
            chkCustomMessage: false,
            errors: {
                employeeNo: "",
                errMessage: "",
                customMessage: "",
                airlineCode: ""
            }
        });
    }

    afterOpenAirlineCrewMessageModal = () => {
        this.getCannedMessage();
        this.getAirlineDropdown();
        if (this.state.singleData === null) {
            this.setState({
                selMessageId: null,
                customMessage: '',
                employeeNo: "",
                airlineCode: ""
            });
        }
        //else {
        //    const message = this.state.singleData;
        //    this.setstate({
        //        airlineCode: message.airlineCode
        //    });
        //}
    }

    async getAirlineDropdown() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('air-line-api/airline-dropdown', requestParams);
            const data = await response.json();
            this.setState({
                airlineDropdownList: data.airlines
            });
        } catch (e) {
            console.log(e);
        }

    }

    handleFilterAirlineOnChange = (selectedOption) => {
        let error = this.state.errors
        error.airlineCode = "";
        this.setState({
            airlineCode: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.airlineCode : ""),
            errors: error
        })
        this.getEmployeeNumberDropdown(selectedOption?.selectedItem?.airlineCode);
    }

    async getEmployeeNumberDropdown(airlineCode) {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('employee-api/' + airlineCode + '/employeeNumber-dropdown', requestParams);
            const data = await response.json();
            this.setState({
                employeesDropdownList: data.employees
            });
        } catch (e) {
            console.log(e);
        }
    }

    handleFilterEmployeeOnChange = (selectedOption) => {
        let error = this.state.errors
        error.employeeNo = "";
        this.setState({
            employeeNo: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.employeeNumber : ""),
            errors: error
        })

    }

    async getCannedMessage() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('canned-message/airlineCrew-canned-message', requestParams);
            const data = await response.json();
            if (data !== null && data.airlineCannedMessages.length > 0) {
                this.setState({
                    airlineCrewCannedMessageList: data.airlineCannedMessages
                });
            }
        } catch (e) {
            console.log(e);
        }

    }
    cannedMessageChange = (selectedOption) => {
        let error = this.state.errors
        error.errMessage = "";
        this.setState({
            selMessageId: selectedOption.selectedItem
        });

        if (selectedOption.selectedItem === null) {
            this.setState({
                customMessage: "",
                //chkCustomMessage: true,
            });
            //this.state.errors["errMessage"] = "Please select airline crew canned message.";
        }
        else {
            this.setState({
                customMessage: selectedOption.selectedItem.name,
                errors: error
                //chkCustomMessage: true,
            });
            //this.state.errors["errMessage"] = "";
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let errors = this.state.errors;
        switch (name) {
            case 'customMessage':
                if (this.state.chkCustomMessage === true) {
                    errors.customMessage = "";
                    if (event.target.value === '') {
                        this.setState({ customMessage: '' });
                        errors.customMessage = "Please enter custom message.";
                    } else {
                        this.state.errors["customMessage"] = '';
                        this.setState({ customMessage: event.target.value });
                    }
                }
                break;
            default:
                this.setState({
                    [name]: value
                });
                break;
        }
    }

    handleCustomMessage(event) {
        const target = event.target;
        let error = this.state.errors
        error.errMessage = "";
        this.setState({
            chkCustomMessage: target.checked,
        });
        let error1 = this.state.errors
        error1.customMessage = "";
        if (target.checked === true) {
            this.setState({
                customMessage: "",
                selMessageId: null,
                errors: error
            });
        } else {
            this.setState({
                errors: error1
            });
        }
    }

    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.employeeNo = "";
        errors.errMessage = "";
        errors.customMessage = "";
        errors.airlineCode = "";
        if (this.state.singleData === null) {
            if (!this.state.airlineCode) {
                formIsValid = false;
                errors.airlineCode = "Please select airline.";
            }
            if (!this.state.employeeNo) {
                formIsValid = false;
                errors.employeeNo = "Please select employee.";
            }
        }
        if (this.state.chkCustomMessage === false) {
            if (this.state.selMessageId === null) {
                formIsValid = false;
                errors.errMessage = "Please select airline crew canned message.";
            }
        }
        if (this.state.chkCustomMessage === true) {
            if (this.state.customMessage === "") {
                formIsValid = false;
                errors.customMessage = "Please enter airline crew custom message.";
            }
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    handleAirlineCrewMessageSubmit = async (e) => {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isSubmitted: true });
                var request = {};
                request.AirlineCode = this.state.airlineCode;
                request.EmployeeNumber = this.state.employeeNo;
                request.Message = this.state.customMessage;
                request.RideId = this.state.singleData?.rideId ?? 0;
                const requestparams = getRequestParams('POST', request);
                const response = await fetch('dispatch-api/send-to-airline-crew', requestparams);
                const data = await response.json();
                this.setState({ isSubmitted: false });
                Swal.fire({
                    icon: data.isSuccess === true ? 'info' : 'error',
                    title: 'Send Message',
                    text: data.resultMessage,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.closeSendAirlineCrewMessageModal();
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
        finally {
            this.setState({ isSubmitted: false })
        }
    }

    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isArilineCrewMessageIsOpen}
                    style={customStyles}
                    className={"react-modal"}
                    onAfterOpen={this.afterOpenAirlineCrewMessageModal}
                    //onRequestClose={this.closeSendAirlineCrewMessageModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form
                        style={this.state.isSubmitted ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Send Airline Crew Message</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeSendAirlineCrewMessageModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {!this.state.singleData && (
                                <>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group">
                                                <label>Select Airline <span className="text-danger">*</span></label>
                                                <SelectBox
                                                    dataSource={this.state.airlineDropdownList}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Select airliner"
                                                    name="airlineCode"
                                                    //defaultValue={0}
                                                    value={this.state.airlineCode}
                                                    displayExpr={function (items) {
                                                        return items && items?.airlineName + " " + `(${items?.airlineCode})`;
                                                    }}
                                                    valueExpr='airlineCode'
                                                    onSelectionChanged={this.handleFilterAirlineOnChange}
                                                    showClearButton={true}
                                                    disabled={false}
                                                    searchEnabled={true}
                                                />
                                                {this.state.errors["airlineCode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["airlineCode"]}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group">
                                                <label>Select Employee <span className="text-danger">*</span></label>
                                                <SelectBox
                                                    dataSource={this.state.employeesDropdownList}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Select employee number"
                                                    name="employeeNumber"
                                                    //defaultValue={0}
                                                    value={this.state.employeeNo}
                                                    displayExpr={function (items) {
                                                        return items && items?.employeeNumber + "-" + items?.crewName;
                                                    }}
                                                    valueExpr='employeeNumber'
                                                    onSelectionChanged={this.handleFilterEmployeeOnChange}
                                                    showClearButton={true}
                                                    disabled={false}
                                                    searchEnabled={true}
                                                />
                                                {this.state.errors["employeeNo"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["employeeNo"]}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group">
                                        <label>Select Message <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.airlineCrewCannedMessageList.length > 0 ? this.state.airlineCrewCannedMessageList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select message"
                                            name="selMessageId"
                                            //defaultValue={0}
                                            value={this.state.selMessageId != null ? this.state.selMessageId.id : 0}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.cannedMessageChange}
                                            showClearButton={true}
                                            disabled={this.state.chkCustomMessage}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["errMessage"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["errMessage"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <input className="form-check-input" type="checkbox" id="chkCustomMessage" name="chkCustomMessage" checked={this.state.isCustomeMessage} onChange={this.handleCustomMessage} />
                                        <label>Custom Message</label>
                                        <textarea className="form-control form-control-sm" name="customMessage" maxLength="200"
                                            placeholder="Type custom message" title="Custom Message" disabled={!this.state.chkCustomMessage} value={this.state.customMessage} onChange={this.handleInputChange} />
                                        {this.state.errors["customMessage"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customMessage"]}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-success btn-sm"
                                onClick={this.handleAirlineCrewMessageSubmit}
                            >
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Send"><i className="fas fa-share-square"></i> Send</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeSendAirlineCrewMessageModal} title="Cancel"><i className="fas fa-times"></i> Cancel</button>
                        </div>
                    </form>
                </Modal>
            </div>);
    }
}
