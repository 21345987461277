import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import RadioGroup from 'devextreme-react/radio-group';
import { SelectBox } from 'devextreme-react/select-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'
let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
const sortByRadio = [
    { id: 0, name: 'Account' },
    { id: 1, name: 'Date' },
    { id: 2, name: 'Ride' },
];

export class ClosedReservationsReportComponent extends Component {
    constructor() {
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        super()
        this.state = {
            isSubmitted: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            dropdownPaymentType: [],
            paymentTypeId: 0,
            fromPaymentTypeId: 0,
            toPaymentTypeId: 0,
            paymentTypeName: "",
            fromPaymentTypeName: "",
            toPaymentTypeName: "",
            radPaymentType: "0",
            radDateType: "0",
            singleDate: new Date(),
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),
            sortByList: sortByRadio,
            sortByType: sortByRadio[0],
            radCustomer: "0",
            dropdownCustomer: [],
            customerCode: '',
            fromCustomerCode: "",
            toCustomerCode: "",
            customerName: "",
            fromCustomerName: "",
            toCustomerName: "",
            errors: {
                paymentTypeId: 0,
                fromPaymentTypeId: 0,
                toPaymentTypeId: 0,
                singleDate: new Date(),
                startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
                endDate: new Date(),
                customerCode: '',
                fromCustomerCode: "",
                toCustomerCode: "",
            }
        }

        this.getPaymentType = this.getPaymentType.bind(this);
        this.getCustomer = this.getCustomer.bind(this);

        this.handleRadCustomerChange = this.handleRadCustomerChange.bind(this);
        this.handleCustomerChange = this.handleCustomerChange.bind(this);
        this.handleFromCustomerChange = this.handleFromCustomerChange.bind(this);
        this.handleToCustomerChange = this.handleToCustomerChange.bind(this);
        this.handleDateTypeChange = this.handleDateTypeChange.bind(this);
        this.handleSinglePaymentTypeChange = this.handleSinglePaymentTypeChange.bind(this);
        this.handleFromPaymentTypeChange = this.handleFromPaymentTypeChange.bind(this);
        this.handleToPaymentTypeChange = this.handleToPaymentTypeChange.bind(this);
        this.handlePaymentTypeChange = this.handlePaymentTypeChange.bind(this);
        this.handleRideDateChanged = this.handleRideDateChanged.bind(this);
        this.handleFromDateChanged = this.handleFromDateChanged.bind(this);
        this.handleToDateChanged = this.handleToDateChanged.bind(this);
        this.handleSortByTypeChange = this.handleSortByTypeChange.bind(this);

        this.handleClosedReservationsReportSubmit = this.handleClosedReservationsReportSubmit.bind(this);
        this.downloadClosedReservationsReportAsPdf = this.downloadClosedReservationsReportAsPdf.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
    }
    async componentDidMount() {
        this.setState({
            loadPanelVisible: true,
        });
        await this.getCustomer();
        await this.getPaymentType();
        this.setState({
            loadPanelVisible: false,
        });
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getDriversDropdownList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    async getPaymentType() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('paymenttype-api/dropdownlist', requestParams);
            const data = await response.json();

            this.setState({
                dropdownPaymentType: data.paymentType
            });
        } catch (e) {
            console.log(e);
        }
    }
    async getCustomer() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('account-api/customer-dropdown/', requestParams);
            const data = await response.json();
            this.setState({
                dropdownCustomer: data.customers,
            });

        } catch (e) {
            console.log(e);
        }
    }
    handleCustomerChange(selectedOption) {
        this.setState({
            customerCode: (selectedOption != null ? selectedOption.code : ''),
            customerName: (selectedOption != null ? selectedOption.name : ''),
        })
        this.state.errors["customerCode"] = "";
    }
    handleFromCustomerChange(selectedOption) {
        this.setState({
            fromCustomerCode: (selectedOption != null ? selectedOption.code : ''),
            fromCustomerName: (selectedOption != null ? selectedOption.name : ''),
        })
        this.state.errors["fromCustomerCode"] = "";
    }
    handleToCustomerChange(selectedOption) {
        this.setState({
            toCustomerCode: (selectedOption != null ? selectedOption.code : ''),
            toCustomerName: (selectedOption != null ? selectedOption.name : ''),
        })
        this.state.errors["toCustomerCode"] = "";
    }
    handleRadCustomerChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ radCustomer: value, customerCode: '' });
        this.state.errors["customerCode"] = "";
    }
    handleDateTypeChange(e) {
        this.setState({
            radDateType: e.target.value,
        })
    }
    handleRideDateChanged(event) {
        let rideDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ singleDate: rideDateValue })
    }
    handleFromDateChanged(event) {
        let fromDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ startDate: fromDateValue })
    }
    handleToDateChanged(event) {
        let toDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ endDate: toDateValue })
    }
    handleSinglePaymentTypeChange(selectedOption) {
        this.setState(
            {
                paymentTypeId: (selectedOption != null ? selectedOption.id : 0),
                paymentTypeName: selectedOption.name,
            })
    }
    handleFromPaymentTypeChange(selectedOption) {
        this.setState({ fromPaymentTypeId: (selectedOption != null ? selectedOption.id : 0), fromPaymentTypeName: selectedOption.name, })
    }
    handleToPaymentTypeChange(selectedOption) {
        this.setState({ toPaymentTypeId: (selectedOption != null ? selectedOption.id : 0), toPaymentTypeName: selectedOption.name, })
    }
    handlePaymentTypeChange(e) {
        this.setState({ radPaymentType: e.target.value });
    }
    handleClosedReservationsReportSubmit(e) {
        e.preventDefault();
        this.downloadClosedReservationsReportAsPdf(e);
    }
    handleSortByTypeChange(e) {
        this.setState({
            sortByType: e.value
        });
    }
    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;

        errors.customerCode = "";
        errors.fromCustomerCode = "";
        errors.toCustomerCode = "";
        errors.paymentTypeId = 0;
        errors.fromPaymentTypeId = 0;
        errors.toPaymentTypeId = 0;

        this.setState({ message: '' });

        if (this.state.radCustomer == "1") {
            if (this.state.customerCode == "") {
                formIsValid = false;
                errors.customerCode = "Please select customer";
            }
        }

        if (this.state.radCustomer == "2") {
            if (this.state.fromCustomerCode == 0) {
                formIsValid = false;
                errors.fromCustomerCode = "Please select from customer";
            }

            if (this.state.toCustomerCode == 0) {
                formIsValid = false;
                errors.toCustomerCode = "Please select to customer";
            }
        }

        if (this.state.radPaymentType == "1") {
            if (this.state.paymentTypeId == 0) {
                formIsValid = false;
                errors.paymentTypeId = "Please select payment type";
            }
        }

        if (this.state.radPaymentType == "2") {
            if (this.state.fromPaymentTypeId == 0) {
                formIsValid = false;
                errors.fromPaymentTypeId = "Please select from payment type";
            }

            if (this.state.toPaymentTypeId == 0) {
                formIsValid = false;
                errors.toPaymentTypeId = "Please select to payment type";
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    async downloadClosedReservationsReportAsPdf(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            try {
                this.setState({
                    //loadPanelVisible: true,
                    isSubmited: true,
                });

                let criteria1 = "";
                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.fileFormat = 'pdf';
                request.MethodName = "GetClosedReservationsReport";

                if (parseInt(this.state.radDateType) == 0) {
                    criteria1 = "For All Date";
                    request.StartDate = null;
                    request.EndDate = null;
                }
                else if (parseInt(this.state.radDateType) == 1) {
                    criteria1 += "For Date " + formatDate(this.state.singleDate, "MM/dd/yyyy");
                    request.StartDate = formatDate(this.state.singleDate, "MM/dd/yyyy");
                    request.EndDate = formatDate(this.state.singleDate, "MM/dd/yyyy");
                }
                else {
                    criteria1 += "From Date " + formatDate(this.state.startDate, "MM/dd/yyyy") + " To Date " + formatDate(this.state.endDate, "MM/dd/yyyy");
                    request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy");
                    request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy");
                }

                if (parseInt(this.state.radCustomer) == 0) {
                    criteria1 += " For all Account, ";
                    request.FromAccountCode = "";
                    request.ToAccountCode = "";
                }
                else if (parseInt(this.state.radCustomer) == 1) {
                    criteria1 += "  " + "For Account" + "  " + this.state.customerName + ",";
                    request.FromAccountCode = this.state.customerCode;
                    request.ToAccountCode = this.state.customerCode;
                }
                else {
                    criteria1 += "  " + "From Account " + "  " + this.state.fromCustomerName + "  " + " To Account " + "  " + this.state.toCustomerName + ",";
                    request.FromAccountCode = this.state.fromCustomerCode;
                    request.ToAccountCode = this.state.toCustomerCode;
                }

                if (parseInt(this.state.radPaymentType) == 0) {
                    criteria1 += " for All Payment Type, ";
                    request.FromPaymentTypeId = 0;
                    request.ToPaymentTypeId = 0;
                }
                else if (parseInt(this.state.radPaymentType) == 1) {
                    criteria1 += " For Payment Type " + this.state.paymentTypeName + ",";
                    request.FromPaymentTypeId = this.state.paymentTypeId;
                    request.ToPaymentTypeId = this.state.paymentTypeId;
                }
                else {
                    criteria1 += " From Payment Type " + this.state.fromPaymentTypeName + " To Payment type " + this.state.toPaymentTypeName;
                    request.FromPaymentTypeId = this.state.fromPaymentTypeId;
                    request.ToPaymentTypeId = this.state.fromPaymentTypeId;
                }

                request.OrderByType = this.state.sortByType.id;
                request.Header = criteria1;

                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({
                    //loadPanelVisible: false,
                    isSubmited: false,
                });

                let a = document.createElement('a');
                a.href = dataResponse.reportBase64String;
                a.download = 'ClosedReservations.pdf';
                a.click();

            } catch (e) {
                //debugger;
            }
        }
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isOpenModal: false,
                loadPanelVisible: false,
                paymentTypeId: 0,
                fromPaymentTypeId: 0,
                toPaymentTypeId: 0,
                paymentTypeName: "",
                fromPaymentTypeName: "",
                toPaymentTypeName: "",
                radPaymentType: "0",
                radDateType: "0",
                singleDate: new Date(),
                startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
                endDate: new Date(),
                sortByList: sortByRadio,
                sortByType: sortByRadio[0],
                radCustomer: "0",
                customerCode: '',
                fromCustomerCode: "",
                toCustomerCode: "",
                errors: {
                    paymentTypeId: 0,
                    fromPaymentTypeId: 0,
                    toPaymentTypeId: 0,
                    singleDate: new Date(),
                    startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
                    endDate: new Date(),
                    customerCode: '',
                    fromCustomerCode: "",
                    toCustomerCode: "",
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleClearSearch() {
        try {
            this.setState({
                isSubmitted: false,
                isOpenModal: true,
                loadPanelVisible: false,
                paymentTypeId: 0,
                fromPaymentTypeId: 0,
                toPaymentTypeId: 0,
                paymentTypeName: "",
                fromPaymentTypeName: "",
                toPaymentTypeName: "",
                radPaymentType: "0",
                radDateType: "0",
                singleDate: new Date(),
                startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
                endDate: new Date(),
                sortByList: sortByRadio,
                sortByType: sortByRadio[0],
                radCustomer: "0",
                customerCode: '',
                fromCustomerCode: "",
                toCustomerCode: "",
                errors: {
                    paymentTypeId: 0,
                    fromPaymentTypeId: 0,
                    toPaymentTypeId: 0,
                    singleDate: new Date(),
                    startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
                    endDate: new Date(),
                    customerCode: '',
                    fromCustomerCode: "",
                    toCustomerCode: "",
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnSendEmail = async event => {
        event.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isEmailSubmitted: true });
                let criteria1 = "";
                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.fileFormat = 'pdf';
                request.MethodName = "GetClosedReservationsReport";

                if (parseInt(this.state.radDateType) == 0) {
                    criteria1 = "For All Date";
                    request.StartDate = null;
                    request.EndDate = null;
                }
                else if (parseInt(this.state.radDateType) == 1) {
                    criteria1 += "For Date " + formatDate(this.state.singleDate, "MM/dd/yyyy");
                    request.StartDate = formatDate(this.state.singleDate, "MM/dd/yyyy");
                    request.EndDate = formatDate(this.state.singleDate, "MM/dd/yyyy");
                }
                else {
                    criteria1 += "From Date " + formatDate(this.state.startDate, "MM/dd/yyyy") + " To Date " + formatDate(this.state.endDate, "MM/dd/yyyy");
                    request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy");
                    request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy");
                }

                if (parseInt(this.state.radCustomer) == 0) {
                    criteria1 += " For all Account, ";
                    request.FromAccountCode = "";
                    request.ToAccountCode = "";
                }
                else if (parseInt(this.state.radCustomer) == 1) {
                    criteria1 += "  " + "For Account" + "  " + this.state.customerName + ",";
                    request.FromAccountCode = this.state.customerCode;
                    request.ToAccountCode = this.state.customerCode;
                }
                else {
                    criteria1 += "  " + "From Account " + "  " + this.state.fromCustomerName + "  " + " To Account " + "  " + this.state.toCustomerName + ",";
                    request.FromAccountCode = this.state.fromCustomerCode;
                    request.ToAccountCode = this.state.toCustomerCode;
                }

                if (parseInt(this.state.radPaymentType) == 0) {
                    criteria1 += " for All Payment Type, ";
                    request.FromPaymentTypeId = 0;
                    request.ToPaymentTypeId = 0;
                }
                else if (parseInt(this.state.radPaymentType) == 1) {
                    criteria1 += " For Payment Type " + this.state.paymentTypeName + ",";
                    request.FromPaymentTypeId = this.state.paymentTypeId;
                    request.ToPaymentTypeId = this.state.paymentTypeId;
                }
                else {
                    criteria1 += " From Payment Type " + this.state.fromPaymentTypeName + " To Payment type " + this.state.toPaymentTypeName;
                    request.FromPaymentTypeId = this.state.fromPaymentTypeId;
                    request.ToPaymentTypeId = this.state.fromPaymentTypeId;
                }

                request.OrderByType = this.state.sortByType.id;
                request.Header = criteria1;

                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
                    let emailSubject = 'Closed Reservation - ' + moment(currentTime).format("DD MMMM YYYY HH:mm");
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = 'ClosedReservations.pdf';
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '60%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleClosedReservationsReportSubmit} style={(this.state.isSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Closed Reservations'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radCustomer" value="1" checked={this.state.radCustomer === "0"} onChange={this.handleRadCustomerChange} />
                                            <label className="form-check-label">All Customers</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radCustomer" value="1" checked={this.state.radCustomer === "1"} onChange={this.handleRadCustomerChange} />
                                            <label className="form-check-label">Single</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radCustomer" value="2" checked={this.state.radCustomer === "2"} onChange={this.handleRadCustomerChange} />
                                            <label className="form-check-label">Range</label>
                                        </span>
                                        <SelectBox
                                            dataSource={this.state.dropdownCustomer.length > 0 ? this.state.dropdownCustomer : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="customerCode"
                                            defaultValue={[]}
                                            value={this.state.customerCode}
                                            displayExpr='fullName'
                                            valueExpr='code'
                                            onSelectionChanged={this.handleCustomerChange}
                                            showClearButton={true}
                                            disabled={this.state.radCustomer === "1" ? false : true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerCode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerCode"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>From Customer</label>
                                        <SelectBox
                                            dataSource={this.state.dropdownCustomer.length > 0 ? this.state.dropdownCustomer : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="customerCode"
                                            defaultValue={[]}
                                            value={this.state.fromCustomerCode}
                                            displayExpr='fullName'
                                            valueExpr='code'
                                            onSelectionChanged={this.handleFromCustomerChange}
                                            showClearButton={true}
                                            disabled={this.state.radCustomer === "2" ? false : true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["fromCustomerCode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["fromCustomerCode"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>To Customer</label>
                                        <SelectBox
                                            dataSource={this.state.dropdownCustomer.length > 0 ? this.state.dropdownCustomer : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="customerCode"
                                            defaultValue={[]}
                                            value={this.state.toCustomerCode}
                                            displayExpr='fullName'
                                            valueExpr='code'
                                            onSelectionChanged={this.handleToCustomerChange}
                                            showClearButton={true}
                                            disabled={this.state.radCustomer === "2" ? false : true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["toCustomerCode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["toCustomerCode"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radPaymentType" value="0" checked={this.state.radPaymentType === "0"} onChange={this.handlePaymentTypeChange} />
                                            <label className="form-check-label">All Payment Type</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radPaymentType" value="1" checked={this.state.radPaymentType === "1"} onChange={this.handlePaymentTypeChange} />
                                            <label className="form-check-label">Single</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radPaymentType" value="2" checked={this.state.radPaymentType === "2"} onChange={this.handlePaymentTypeChange} />
                                            <label className="form-check-label">Range</label>
                                        </span>
                                        <SelectBox
                                            dataSource={this.state.dropdownPaymentType.length > 0 ? this.state.dropdownPaymentType : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select payment type"
                                            name="paymentTypeId"
                                            defaultValue={[]}
                                            value={this.state.paymentTypeId}
                                            displayExpr="name"
                                            valueExpr="id"
                                            onSelectionChanged={this.handleSinglePaymentTypeChange}
                                            showClearButton={true}
                                            disabled={this.state.radPaymentType === "1" ? false : true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["paymentTypeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["paymentTypeId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>From Payment Type</label>
                                        <SelectBox
                                            dataSource={this.state.dropdownPaymentType.length > 0 ? this.state.dropdownPaymentType : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select payment type"
                                            name="paymentTypeId"
                                            defaultValue={[]}
                                            value={this.state.fromPaymentTypeId}
                                            displayExpr="name"
                                            valueExpr="id"
                                            onSelectionChanged={this.handleFromPaymentTypeChange}
                                            showClearButton={true}
                                            disabled={this.state.radPaymentType === "2" ? false : true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["fromPaymentTypeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["fromPaymentTypeId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>To Payment Type</label>
                                        <SelectBox
                                            dataSource={this.state.dropdownPaymentType.length > 0 ? this.state.dropdownPaymentType : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select payment type"
                                            name="paymentTypeId"
                                            defaultValue={[]}
                                            value={this.state.toPaymentTypeId}
                                            displayExpr="name"
                                            valueExpr="id"
                                            onSelectionChanged={this.handleToPaymentTypeChange}
                                            showClearButton={true}
                                            disabled={this.state.radPaymentType === "2" ? false : true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["toPaymentTypeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["toPaymentTypeId"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radDateType" value="0" checked={this.state.radDateType === "0"} onChange={this.handleDateTypeChange} />
                                            <label className="form-check-label">All Date</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radDateType" value="1" checked={this.state.radDateType === "1"} onChange={this.handleDateTypeChange} />
                                            <label className="form-check-label">Single</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radDateType" value="2" checked={this.state.radDateType === "2"} onChange={this.handleDateTypeChange} />
                                            <label className="form-check-label">Range</label>
                                        </span>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.singleDate}
                                            value={this.state.singleDate}
                                            onValueChanged={this.handleRideDateChanged}
                                            disabled={this.state.radDateType === "1" ? false : true}
                                            width="100%"
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["singleDate"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["singleDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>From Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.startDate}
                                            value={this.state.startDate}
                                            onValueChanged={this.handleFromDateChanged}
                                            disabled={this.state.radDateType === "2" ? false : true}
                                            width="100%"
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["startDate"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["startDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>To Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.endDate}
                                            value={this.state.endDate}
                                            onValueChanged={this.handleToDateChanged}
                                            disabled={this.state.radDateType === "2" ? false : true}
                                            width="100%"
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["endDate"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["endDate"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Sort [Aa -Zz]</label>
                                        <RadioGroup id="radio-group-sortby" dataSource={this.state.sortByList} displayExpr="name" value={this.state.sortByType} onValueChanged={this.handleSortByTypeChange} layout="horizontal" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="submit" className="btn btn-success btn-sm" title="Download" onClick={this.handleClosedReservationsReportSubmit} >
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                                </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}