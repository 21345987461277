import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
}
    from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { LoadPanel } from 'devextreme-react/load-panel';

import { SendMessageComponent } from '../Shared/SendMessageComponent';

export class LoggedDriversComponent extends Component {
    static displayName = LoggedDriversComponent.name;

    constructor(props) {
        super(props);

        this.state = {
            isSubmited: false,
            loggedDriversList: null,
            loadPanelVisible: false,
        }
        this.getLoggedDriversList = this.getLoggedDriversList.bind(this);
        this.logoutDriver = this.logoutDriver.bind(this);
        this.sendDriverMessageControl = this.sendDriverMessageControl.bind(this);
    }

    componentDidMount() {
        this.getLoggedDriversList();
    }
    async getLoggedDriversList() {
        try {
            this.setState({ loadPanelVisible: true });
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('driver-api/UtAppLoggedDrivers', requestParams);
            const data = await response.json();
            if (data !== null && data.loggedDrivers.length > 0) {
                this.setState({ loggedDriversList: data.loggedDrivers });
            }
            else {
                this.setState({ loggedDriversList: [] });
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getLoggedDriversList.bind(this)
            }
        });
    }
    handleOnCellPrepared = (e) => {
        if (e.rowType === "data" && e.column.dataField !== "id") {
            if (e.data.isLoggedIn === true) {
                e.cellElement.style.backgroundColor = "lightgreen";
                e.cellElement.style.color = "black";
            }
        }
    }
    logoutDriverStatus(data) {
        return data.isLoggedIn;
    }
    async logoutDriver(e, data) {
        let isResult = false;
        e.preventDefault();
        await Swal.fire({
            title: 'Logout Driver',
            html: 'Do you wish to log this driver out of the system?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            } else {
                this.setState({ isSubmited: false });
            }
        })

        if (isResult) {
            var newObject = {};
            newObject.DriverId = data.driverId;
            newObject.DriverName = data.driverName;
            newObject.IsDriverLoggedIn = false;
            newObject.LocationId = data.locationId;

            const requestParams = getRequestParams('POST', newObject);
            const response = await fetch('vehicle-api/UtAppLoggedVehicle/' + data.id, requestParams);
            const dataResult = await response.json();
            Swal.fire({
                icon: dataResult.isSuccess ? 'success' : 'error',
                title: dataResult.isSuccess ? 'Success' : 'Oops...',
                text: dataResult.resultMessage,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (dataResult.isSuccess) {
                    if (result.isConfirmed) {
                        this.setState({ isSubmited: false });
                        this.getLoggedDriversList();
                    }
                } else {
                    this.setState({ isSubmited: false })
                }
            })
        }
    }
    sendDriverMessageControl(e, data) {
        e.preventDefault();
        this.refs.SendMessageComponentModel.showModal(data);
    }
    render() {
        const renderGridCell = (data) => {
            return <div>{this.logoutDriverStatus(data.data) === true ? <a title="Logout" onClick={(e) => { this.logoutDriver(e, data.data) }}><i className="fas fa-sign-out-alt icon text-info"></i></a> : ""}
            </div>;
        }
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <h1 className="m-0 text-dark">Logged Drivers</h1>
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem><NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="#" onClick={this.getLoggedDriversList} title="Refresh"><i className="fas fa-sync-alt"></i> Refresh</NavLink></NavItem>
                                    <NavItem><NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="/dispatch/list" title="Dispatch"><i className="fas fa-check"></i> Dispatch</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/noshow/list" className="btn btn-block btn-outline-secondary btn-sm" title="No Show Request"><i className="fas fa-eye-slash"></i> No Show Request</NavLink></NavItem>
                                    <NavItem><NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="/drivermessage/list" title="Driver Message"><i className="fas fa-sms"></i> Driver Message</NavLink></NavItem>
                                    <NavItem><NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="/loggedvehicles/list" title="Logged Vehicles"><i className="fas fa-car"></i> Logged Vehicles</NavLink></NavItem>
                                    <NavItem><NavLink className="btn btn-block btn-outline-secondary btn-sm" to="#" tag={Link} onClick={(e) => { this.sendDriverMessageControl(e, null) }} data-toggle="modal" title="Send Message"><i className="fas fa-sms"></i> Send Message</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="card card-grid">
                        <div className="card-body">
                            <DataGrid
                                dataSource={this.state.loggedDriversList}
                                keyExpr="id"
                                selection={{ mode: 'single' }}
                                columnsAutoWidth={true}
                                showColumnLines={true}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                onToolbarPreparing={this.handleToolbarPreparing}
                                //repaintChangesOnly={true}
                                onCellPrepared={this.handleOnCellPrepared}>
                                <Column dataField="id" width={30} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                <Column dataField="driverName" caption="Driver Name" />
                                <Column dataField="userName" caption="Login Id" />
                                <Column dataField="vehicleNo" caption="Vehicle Number" />
                                <Column dataField="loginLogoutStatus" caption="Status" width="auto" />
                                <Column dataField="workHour" caption="Work Hours" alignment="center" width="auto" />
                                <Column dataField="loginLogoutDateTimeStr" caption="Last Logged-in Time / Last Logged-out Time" width="auto" />
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                <Export enabled={true} allowExportSelectedData={false} />
                                <ColumnChooser enabled={true} />
                                <ColumnFixing enabled={true} />
                            </DataGrid>
                        </div>
                    </div>
                </div>
                <SendMessageComponent ref="SendMessageComponentModel" onSendMessageClose={this.getLoggedDriversList} />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#dvLoogedDriver' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}