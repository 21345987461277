import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { SelectBox } from 'devextreme-react/select-box';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { LoadPanel } from 'devextreme-react/load-panel';
import { RatesHistoryComponent } from '../Shared/RatesHistoryComponent';

const position = { of: '#historydiv' };

export class KilometerRatesComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rowId: 0,
            addEditKilometerRateDataModalIsOpen: false,
            isSubmited: false,
            customerList: [],
            customerId: 0,
            customerName :'',
            vehicleTypeList: [],
            vehicleTypeId: 0,
            isAirportTransfer: false,
            isZonalTransfer: false,
            zonalRatesList: null,
            loadPanelVisible: false,
        }
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.handleSearchKilometerRateSubmit = this.handleSearchKilometerRateSubmit.bind(this);
        this.handleAddEditKilometerRateSubmit = this.handleAddEditKilometerRateSubmit.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
    }
    async componentDidMount() {
        this.setState({
            loadPanelVisible: true,
        });
        await this.getcustomerList();
        await this.getvehicleTypeList();
        await this.getZonalRatesList();
        if (this.props.location.state != undefined) {

            this.setState({
                customerId: this.props.location.state.customerId,
                vehicleTypeId: this.props.location.state.vehicleTypeId,
                isAirportTransfer: this.props.location.state.isAirportTransfer,
                isZonalTransfer: this.props.location.state.isZonalTransfer,
            });

            //let myVar = setTimeout(() => this.getZonalRatesList(), 200)
        }
        this.setState({
            loadPanelVisible: false,
        });
    }
    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getZonalRatesList()
            }
        });
    }
    async getZonalRatesList() {
        var request = new Object();
        request.CustomerId = this.state.customerId;
           this.setState({
            isSubmited: true,
        });
        const requestParams = getRequestParams('POST', request);
        const response = await fetch('kilometer-rate-api/list', requestParams);
        const data = await response.json();
        this.setState({
            zonalRatesList: data.kilometerRates
        });

        this.setState({
            isSubmited: false,
        });
    }
    async getcustomerList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('account-api/customer-dropdown/', requestParams);
        const data = await response.json();
        this.setState({
            customerList: data.customers,
        });

    }
    handleCustomerInputChange = (data) => {
        this.setState({ customerId: (data.selectedItem != null ? data.selectedItem.id : 0), })
        this.setState({ customerName: (data.selectedItem != null ? data.selectedItem.name : ''), })
    }

    async getvehicleTypeList() {
        var newObject = new Object();
        newObject.LocationId = parseInt(sessionStorage.getItem("session_locationId"));
        const requestParams = getRequestParams('POST', newObject);
        const response = await fetch('vehicletype-api/vehicletype', requestParams);
        const data = await response.json();

        this.setState({
            vehicleTypeList: data.apiVehicleType
        });
    }
    handleVehicleTypeInputChange = (data) => {
        this.setState({ vehicleTypeId: (data.selectedItem != null ? data.selectedItem.rowIndex : 0), })
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    async handleSearchKilometerRateSubmit(e) {
        e.preventDefault();

        await this.getZonalRatesList();
    }
    handleClearSearch() {
        this.setState({
            isSubmited: false,
            customerId: 0,
            customerName:'',
            vehicleTypeId: 0,
            isAirportTransfer: false,
            isZonalTransfer: false,
        });

        let myVar = setTimeout(() => this.getZonalRatesList(), 200)
    }

    addEditKilometerRatesControl(e, data) {
        e.preventDefault();
       if (data == null) {
            this.props.history.push({
                pathname: '/kilometer/details',
                //state: { detail: data }
                state: {
                    isAdd: true
                    , customerId: this.state.customerId
                    , rideTypeId: 0
                    , fromAirportId: 0
                    , fromZoneId: 0
                    , vehicleTypeId: this.state.vehicleTypeId
                    , isAirportTransfer: this.state.isAirportTransfer
                    , isZonalTransfer: this.state.isZonalTransfer
                    , sourceUrl: "/kilometer/list"
                    , sourceState: this.state
                    , masterRateId: 0
                    , customerName: this.state.customerName
                }
            })
        } else {
            this.props.history.push({
                pathname: '/kilometer/details',
                //state: { detail: data }
                state: {
                    isAdd: false
                    , customerId: data.customerId
                    , rideTypeId: data.rideTypeId
                    , fromAirportId: data.fromAirportId
                    , fromZoneId: data.fromZoneId
                    , vehicleTypeId: this.state.vehicleTypeId
                    , isAirportTransfer: this.state.isAirportTransfer
                    , isZonalTransfer: this.state.isZonalTransfer
                    , sourceUrl: "/kilometer/list"
                    , sourceState: this.state
                    , masterRateId: data.id
                    , customerName: data.name
                }
            })
        }

    }


    kilometerRatesHistoryControl = (e, data) => {
        e.preventDefault();

        var fromZoneIdStr = (data.rideTypeId == 1) ? data.fromAirportId : data.fromZoneId;
        var toZoneIdStr = 0;
        this.refs.ratesHistoryComponent.showModal("Kilometer Rate History", data.customerId, fromZoneIdStr, toZoneIdStr);
    }
    openZonalRateDataModal = () => { }
    async handleAddEditKilometerRateSubmit(e) {
        e.preventDefault();
    }
    closeaddEditKilometerRateModal = () => {
        this.setState({
            rowId: 0,
            isSubmited: false,
            addEditKilometerRateDataModalIsOpen: false
        });
        this.getZonalRatesList();
    }

    render() {
        const serviceAreaModalTitle = this.state.rowId > 0 ? "Edit Kilometer Rate" : "Add Kilometer Rate";
      
        const renderEditGridCell = (data) => {
            return <div>&nbsp;&nbsp;
                <a href="#" className="fas fa-pen icon text-info" title="Edit" onClick={(e) => { this.addEditKilometerRatesControl(e, data.data) }} ></a>&nbsp;&nbsp;
                <a href="#" className="fas fa-history icon text-info" title="History" onClick={(e) => { this.kilometerRatesHistoryControl(e, data.data) }} ></a>&nbsp;&nbsp;
            </div>;
        }
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-sm-6 col-md-6 col-lg-6">
                                <h1 className="m-0 text-dark">Kilometer Rates</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem>
                                        <NavLink tag={Link} to="#" onClick={(e) => { this.addEditKilometerRatesControl(e, null) }} className="btn btn-block btn-outline-secondary btn-sm" title="Add Kilometer Rate" ><i className="fas fa-plus"></i> Add Kilometer Rate</NavLink>
                                    </NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <form method="post" onSubmit={this.handleSearchKilometerRateSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="rez-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>Customer</label>
                                        <SelectBox
                                            dataSource={this.state.customerList.length > 0 ? this.state.customerList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="customerId"
                                            defaultValue={0}
                                            value={this.state.customerId}
                                            displayExpr='fullName'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleCustomerInputChange}
                                            showClearButton={true}
                                            disabled={this.state.isSearched == true}
                                            searchEnabled={true}
                                        />
                                    </div>
                                </div>
                                {/*<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">*/}
                                {/*    <div className="form-group">*/}
                                {/*        <label>Vehicle</label>*/}
                                {/*        <SelectBox*/}
                                {/*            dataSource={this.state.vehicleTypeList.length > 0 ? this.state.vehicleTypeList : []}*/}
                                {/*            className="basic-multi-select"*/}
                                {/*            classNamePrefix="select"*/}
                                {/*            placeholder="Select vehicle type"*/}
                                {/*            name="vehicleTypeId"*/}
                                {/*            defaultValue={0}*/}
                                {/*            value={this.state.vehicleTypeId}*/}
                                {/*            displayExpr='apiVehicleTypeDescription'*/}
                                {/*            valueExpr='rowIndex'*/}
                                {/*            onSelectionChanged={this.handleVehicleTypeInputChange}*/}
                                {/*            showClearButton={true}*/}
                                {/*            disabled={false}*/}
                                {/*            searchEnabled={true}*/}
                                {/*        />*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{ paddingTop: 22 + 'px' }}>*/}
                                {/*    <div className="form-group">*/}
                                {/*        <label htmlFor="">&nbsp;</label>*/}
                                {/*        <span className="form-check">*/}
                                {/*            <input className="form-check-input" type="checkbox" name="isAirportTransfer" checked={this.state.isAirportTransfer} onChange={this.handleInputChange} />*/}
                                {/*            <label className="form-check-label">Airport Transfer</label>*/}
                                {/*        </span>*/}
                                {/*        <span className="form-check ml-3">*/}
                                {/*            <input className="form-check-input" type="checkbox" name="isZonalTransfer" checked={this.state.isZonalTransfer} onChange={this.handleInputChange} />*/}
                                {/*            <label className="form-check-label">Zonal Transfer</label>*/}
                                {/*        </span>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 text-right">
                                    <div className="form-group">
                                        <label className="btn-block">&nbsp;</label>
                                        <button type="submit" className="btn btn-success btn-sm" onClick={this.handleSearchKilometerRateSubmit} >
                                            {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSubmited && <span title="Search"><i className="fas fa-search"></i> Search</span>}
                                        </button>&nbsp; &nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleClearSearch} title="Clear"><i className="fas fa-eraser"></i> Clear</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="content">
                    <div className="card">
                        <div className="card-body">
                            <DataGrid
                                dataSource={this.state.zonalRatesList}
                                keyExpr="id"
                                selection={{ mode: 'single' }}
                                columnsAutoWidth={false}
                                showColumnLines={false}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                showBorders={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                width='100%'
                                onToolbarPreparing={this.onToolbarPreparing}>
                                <Column dataField="id" width={50} caption="" allowFiltering={false} allowSorting={false} cellRender={renderEditGridCell} />
                                <Column dataField="name" caption="Customer Name" allowFiltering={true} allowSorting={true} />
                                {/*<Column dataField="rideType" caption="Ride Type" allowFiltering={true} allowSorting={true} />*/}
                                {/*<Column dataField="startZone" caption="Start Zone" />*/}
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={false} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true} />
                                <Export enabled={true} allowExportSelectedData={false} />
                                <ColumnChooser enabled={true} />
                                <ColumnFixing enabled={true} />
                            </DataGrid>
                        </div>
                    </div>

                </div>

                <Modal ariaHideApp={false}
                    isOpen={this.state.addEditKilometerRateDataModalIsOpen}
                    className={"react-modal edit-service-modal"}
                    onAfterOpen={this.openZonalRateDataModal}
                    onRequestClose={this.closeaddEditKilometerRateModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal"
                >
                    <form method="post" onSubmit={this.handleAddEditKilometerRateSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{serviceAreaModalTitle}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeaddEditKilometerRateModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group">
                                        <label>Location <span className="text-red">*</span></label>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group">
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success btn-sm" onClick={this.handleAddEditKilometerRateSubmit} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Save"><i className="fas fa-save"></i> Save</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeaddEditKilometerRateModal} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <RatesHistoryComponent ref="ratesHistoryComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >)
    }
}