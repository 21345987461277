import { post } from 'axios'; //npm install--save axios
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    Selection,
    Editing,
    SortByGroupSummaryInfo
} from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import { LoadPanel } from 'devextreme-react/load-panel';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component, Fragment } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import { Template } from 'devextreme-react/core/template';
import { Link } from 'react-router-dom';
import { SelectBox } from 'devextreme-react/select-box';
import { NavItem, NavLink } from 'reactstrap';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getRequestParams } from '../../helpers/globalfunctions.js';

const MySwal = withReactContent(Swal)
const customStyles = {
    content: {
        width: '60%',
    }
};
const position = { of: '#invoicediv' };

export const rideTypeList = [
    {
        "rideTypeId": 1,
        "rideTypeName": "BOTH"
    }, {
        "rideTypeId": 2,
        "rideTypeName": "ARRIVAL"
    }, {
        "rideTypeId": 3,
        "rideTypeName": "DEPARTURE"
    }]
    ;
export class UploadLuxaryExcelComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFirstSubmited: false,
            loadPanelVisible: false,
            isSubmited: false,
            showEditTripPopup: false,
            showMappingPopup: false,
            showLuxaryTripsMappingPopup : false,
            hideInvalidAccDiv: true,
            hideInvalidAddrDiv: true,
            hideInvalidVType: true,

            accountsList: [],
            serviceZoneList: [],
            vehicleTypesList: [],
            tripsList: [],
            invalidAccounts: [],
            invalidAddresses: [],
            invalidVehicleTypes: [],
            luxaryRezMappingList : [],
            selectedAccountRowKeys: [], selectedAddressRowKeys: [],  selectedLuxTripsRowKeys : [],

            tripData: {},
            invalidAccData : { mappingName: '', name: '' },
            invalidAddrData :  { mappingName: '', name: '' },
            invalidVTypeData : { mappingName: '', name: '' },
              
            attachment: null,
            mappingTitleName: '',

            errors: {
                attachment: '',
                accountName: '',
                vehicleType: '',
                puZone: '',
                doZone: '',
                mapping: ''
            },
        }
        this.handleReadDataSubmit = this.handleReadDataSubmit.bind(this);
        this.toolbarItemRenderForAccountGrid = this.toolbarItemRenderForAccountGrid.bind(this);
        this.toolbarItemRenderForAddressGrid = this.toolbarItemRenderForAddressGrid.bind(this);
    }
    async componentDidMount() {
        this.bindDropDowns();
    }

    handleAccountToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'before',
            template: 'toolbarTemplate'
        });
    }
    handleAddressToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'before',
            template: 'toolbarTemplate'
        });
    }

    getAccountsList = async () => {
        try {
            let accountId = sessionStorage.getItem('session_accountId');
            const requestParams = getRequestParams('GET');
            const response = await fetch('reservation-api/Customers/' + accountId, requestParams);
            const data = await response.json();
            this.setState({
                accountsList: data != null ? data : [],
            });
        } catch (ex) {
            console.log("Error in " + this.displayName + ".getAccountsList function", ex);
        }
    }

    getServiceZoneList = async () => {
        try {
            let accountId = sessionStorage.getItem('session_accountId');
            let locationId = sessionStorage.getItem('session_locationId');
            const requestParams = getRequestParams('GET');
            const response = await fetch('reservation-api/ServiceZone/' + accountId + '/' + locationId, requestParams);
            const data = await response.json();
            this.setState({
                serviceZoneList: data != null ? data : [],
            });
        } catch (ex) {
            console.log("Error in " + this.displayName + ".serviceZoneList function", ex);
        }
    }

    getVehicleTypesList = async () => {
        try {
            let accountId = sessionStorage.getItem('session_accountId');
            let locationId = sessionStorage.getItem('session_locationId');
            const requestParams = getRequestParams('GET');
            const response = await fetch('reservation-api/VehicleTypes/' + accountId + '/' + locationId, requestParams);
            const data = await response.json();
            this.setState({
                vehicleTypesList: data != null ? data : [],
            });
        } catch (ex) {
            console.log("Error in " + this.displayName + ".getVehicleTypesList function", ex);
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let errors = this.state.errors;

        switch (name) {
            case 'attachment':
                errors.attachment = "";
                if (event.target.files.length === 0) {
                    this.setState({ attachment: null });
                    errors.attachment = "Please select attachment.";
                } else {
                    this.state.errors["attachment"] = '';
                    this.setState({ attachment: event.target.files[0] });
                }
                break;
            default:
                this.setState({
                    [name]: value
                });
                break;
        }
    }

    handleFormValidation = () => {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.attachment = '';
        if (this.state.attachment === null) {
            formIsValid = false;
            errors.attachment = "Please select attachment.";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    handleReadDataSubmit = async (e) => {
        if (e !== undefined) {
            e.preventDefault();
        }
        if (this.handleFormValidation()) {
            let fileName = this.state.attachment.name;
            let _name = fileName.split(".")[0];
            let _extension = fileName.split(".")[1];

            this.setState({ isFirstSubmited: true });
            const url = 'reservation-api/uploadLuxaryExcel/' + this.state.attachment.name.split(".")[1];
            const formData = new FormData();
            formData.append('body', this.state.attachment);
            formData.append('accountId', sessionStorage.getItem('session_accountId'));
            formData.append('locationId', sessionStorage.getItem('session_locationId'));
            formData.append('userId', sessionStorage.getItem('session_userId'));
            formData.append('cityId', 0);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + sessionStorage.getItem('session_token'),
                    'CultureCode': sessionStorage.getItem('session_culturecode'),
                },
            };

            const response = await post(url, formData, config);
            const data = await response.data;
            if (data !== null) {
                this.setState({ isFirstSubmited: false });
                //console.log(data.trips);
                await Swal.fire({
                    icon: data.isSuccess ? 'success' : 'error',
                    title: data.isSuccess ? 'Success' : 'Oops...',
                    text: data.message,
                    allowOutsideClick: false,
                }).then(async (result) => {
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            this.setState({
                                tripsList: data.trips.length > 0 ? data.trips : [],
                                invalidAccounts: data.invalidAccounts.length > 0 ? data.invalidAccounts : [],
                                invalidAddresses: data.invalidAddresses.length > 0 ? data.invalidAddresses : [],
                                invalidVehicleTypes: data.invalidVehicleTypes.length > 0 ? data.invalidVehicleTypes : [],
                                isFirstSubmited: false,
                            })
                        }
                    }
                })


            } else {
                this.setState({ isFirstSubmited: false })
            }
        }
    }

    bindDropDowns = () => {
        this.getAccountsList();
        this.getServiceZoneList();
        this.getVehicleTypesList();
    }

    handleEdit = (data) => {
        this.setState({
            loadPanelVisible: false,
            showEditTripPopup: true,
            tripData: JSON.parse(JSON.stringify(data))
        })
    }

    handleResetStateData = () => {
        let errors = this.state.errors;
        errors.attachment = '';
        errors.accountName = '';
        errors.puLocation = '';
        errors.doLocation = '';
        errors.vehicleType = '';
        this.setState({
            showEditTripPopup: false,
            tripData: {
                confirmNo: '',
                passengerName: '',
                puDate: '',
                puTime: '',
                routingDetails: '',
                paxCount: '',
                vehicleType: '',
                serviceType: '',
                apiRemarks: '',
                utWizAccountId: 0,
                utWizAirlineId: 0,
                utWizRateTypeId: 0,
                utWizPuZoneId: 0,
                utWizPuZoneName: '',
                utWizPuLocationType: '',
                utWizPuLocationId: 0,
                utWizDoZoneId: 0,
                utWizDoZoneName: '',
                utWizDoLocationType: '',
                utWizDoLocationId: 0,
            },
            errors: errors
        })
    }

    handleOnAccountChange = (e) => {
        let errors = this.state.errors;
        errors.accountName = '';
        let _tripData = this.state.tripData;
        if (e.selectedItem != null) {
            _tripData.utWizAccountId = e.selectedItem.accountId;
            _tripData.utWizAccountName = e.selectedItem.accountName;
            _tripData.utWizAirlineId = e.selectedItem.airlineId;
            _tripData.utWizRateTypeId = e.selectedItem.rateTypeId;
        } else {
            _tripData.utWizAccountId = 0; _tripData.utWizAccountName = ''; _tripData.utWizAirlineId = 0; _tripData.utWizRateTypeId = 0;
        }
        this.setState({ tripData: _tripData, errors: errors });
    }

    handleOnVehicleTypeChange = (e) => {
        let errors = this.state.errors;
        errors.vehicleType = '';
        let _tripData = this.state.tripData;
        if (e.selectedItem != null) {
            _tripData.utWizVehicleTypeId = e.selectedItem.id;
            _tripData.utWizVehicleTypeName = e.selectedItem.name;
        } else {
            _tripData.utWizVehicleTypeId = 0; _tripData.utWizVehicleTypeName = "";
        }
        this.setState({ tripData: _tripData, errors: errors });
    }

    handleOnPuZoneChange = (e) => {
        let errors = this.state.errors;
        errors.puLocation = '';
        let _tripData = this.state.tripData;
        if (e.selectedItem != null) {
            _tripData.utWizPuZoneId = e.selectedItem.id;
            _tripData.utWizPuZoneName = e.selectedItem.name;
            _tripData.utWizPuLocationType = e.selectedItem.zoneTypeName;
            _tripData.utWizPuLocationId = e.selectedItem.airportId;
        } else {
            _tripData.utWizPuZoneId = 0; _tripData.utWizPuZoneName = ''; _tripData.utWizPuLocationType = ''; _tripData.utWizPuLocationId = 0
        }
        this.setState({ tripData: _tripData, errors: errors });
    }

    handleOnDoZoneChange = (e) => {
        let errors = this.state.errors;
        errors.doLocation = '';
        let _tripData = this.state.tripData;
        if (e.selectedItem != null) {
            _tripData.utWizDoZoneId = e.selectedItem.id;
            _tripData.utWizDoZoneName = e.selectedItem.name;
            _tripData.utWizDoLocationType = e.selectedItem.zoneTypeName;
            _tripData.utWizDoLocationId = e.selectedItem.airportId;
        } else {
            _tripData.utWizDoZoneId = 0; _tripData.utWizPuZoneName = ''; _tripData.utWizDoLocationType = ''; _tripData.utWizDoLocationId = 0;
        }
        this.setState({ tripData: _tripData, errors: errors });
    }

    handleSaveEditTrip = () => {
        if (this.handleSaveEditTripValidation()) {
            let tripData = this.state.tripData;
            let editTrip = this.state.tripsList.filter(c => c.confirmNo == this.state.tripData.confirmNo)[0];
            editTrip.utWizAccountId = tripData.utWizAccountId;
            editTrip.utWizAccountName = tripData.utWizAccountName;
            editTrip.utWizVehicleTypeId = tripData.utWizVehicleTypeId;
            editTrip.utWizVehicleTypeName = tripData.utWizVehicleTypeName;
            editTrip.utWizPuZoneId = tripData.utWizPuZoneId;
            editTrip.utWizPuZoneName = tripData.utWizPuZoneName;
            editTrip.utWizDoZoneId = tripData.utWizDoZoneId;
            editTrip.utWizDoZoneName = tripData.utWizDoZoneName;
            editTrip.utWizPuLocationType = tripData.utWizPuLocationType;
            editTrip.utWizPuLocationId = tripData.utWizPuLocationId;
            editTrip.utWizDoLocationType = tripData.utWizDoLocationType;
            editTrip.utWizDoLocationId = tripData.utWizDoLocationId;
            editTrip.utWizAirlineId = tripData.utWizAirlineId;
            editTrip.utWizRateTypeId = tripData.utWizRateTypeId;
            editTrip.isValid = true;
            editTrip.apiRemarks = '';
            //Swal.fire({
            //    icon: 'Success',
            //    title: 'Success',
            //    text: 'Data updated successfully.',
            //    allowOutsideClick: false,
            //});
            this.setState({ showEditTripPopup: false });
        }
    }

    handleSaveEditTripValidation = () => {
        let formIsValid = true;
        try {
            let modal = this.state.tripData;
            let errors = this.state.errors;
            errors.accountName = "";
            errors.vehicleType = "";
            errors.puZone = "";
            errors.doZone = "";

            if (modal.utWizAccountId === 0) {
                formIsValid = false;
                errors.accountName = "Please Select Account.";
            }
            if (modal.utWizVehicleTypeId === 0) {
                formIsValid = false;
                errors.vehicleType = "Please Vehicle Type.";
            }
            if (modal.utWizPuZoneId === 0) {
                formIsValid = false;
                errors.puZone = "Please Select PickUp Zone.";
            }
            if (modal.utWizDoZoneId === 0) {
                formIsValid = false;
                errors.doZone = "Please Select DropOff Zone.";
            }
            this.setState({ errors: errors });
        } catch (err) {
            console.log("Error in " + this.displayName + ".handleSaveEditTripValidation function", err);
            formIsValid = false;
        }
        return formIsValid;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        var list = this.state.tripsList.filter(f => f.utWizAccountId == 0 || f.utWizPuZoneId == 0 || f.utWizDoZoneId == 0 || f.utWizVehicleTypeId == 0);
        Swal.fire({
            html: list.length > 0 ? 'You still have (' + list.length + ') invalid trips. Do you want to generate trips?.' : 'Are you sure, Do you want to generate trips?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            allowOutsideClick: false
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.setState({
                    loadPanelVisible: true,
                    isSubmitted: true
                })
                try {
                    let accountId = parseInt(sessionStorage.getItem('session_accountId'));
                    let locationId = parseInt(sessionStorage.getItem('session_locationId'));
                    let cityId = 0;  //sessionStorage.getItem('session_cityId');
                    let userId = parseInt(sessionStorage.getItem('session_userId'));

                    var request = {};
                    request.AccountId = accountId;
                    request.UserId = userId;
                    request.LocationId = locationId;
                    request.CityId = cityId;
                    request.Trips = this.state.tripsList;

                    const requestParams = getRequestParams('POST', request);
                    fetch('reservation-api/Create', requestParams)
                        .then(response => { if (response.ok) return response.json() })
                        .then(data => {
                            this.setState({
                                loadPanelVisible: false,
                                isSubmitted: false
                            })
                            if (data != null) {
                                Swal.fire({
                                    icon: data.isSuccess ? 'success' : 'error',
                                    title: data.isSuccess ? 'Success' : 'Oops...',
                                    text: data.message,
                                    allowOutsideClick: false,
                                }).then((result) => {
                                    if (data.isSuccess) {
                                        if (result.isConfirmed) {
                                            window.location.href = window.location.href;
                                        }
                                    }
                                })
                            }
                        });


                } catch (ex) {
                    console.log("Error in " + this.displayName + ".handleSubmit function", ex);
                }
            }
        });

    }

    //invalid-trip-mapping methods.

    handleOnAccCodeChange = (e) => {
        let errors = this.state.errors;
        errors.mapping = '';
        let invalidAccData = this.state.invalidAccData;
        if (e.selectedItem != null) {
            invalidAccData.mappingName = e.selectedItem.accountCode;
        } else {
            invalidAccData.mappingName = '';
        }
        this.setState({ invalidAccData: invalidAccData, errors: errors });
    }

    handleOnZoneNameChange = (e) => {
        let errors = this.state.errors;
        errors.mapping = '';
        let invalidAddrData = this.state.invalidAddrData;
        if (e.selectedItem != null) {
            invalidAddrData.mappingName = e.selectedItem.name;
        } else {
            invalidAddrData.mappingName = '';
        }
        this.setState({ invalidAddrData: invalidAddrData, errors: errors });
    }

    handleOnVTypeChange = (e) => {
        let errors = this.state.errors;
        errors.mapping = '';
        let invalidVTypeData = this.state.invalidVTypeData;
        if (e.selectedItem != null) {
            invalidVTypeData.mappingName = e.selectedItem.name;
        } else {
            invalidVTypeData.mappingName = '';
        }
        this.setState({ invalidVTypeData: invalidVTypeData, errors: errors });
    }

    handleEditInvalidAcc = (data) => {
        let invalidAccData = this.state.invalidAccData;
        invalidAccData.mappingName = data.mappingName;
        invalidAccData.name = data.name;
        this.setState({
            invalidAccData: invalidAccData,
            mappingTitleName: "Set Account",
            hideInvalidAccDiv: false,
            hideInvalidAddrDiv: true,
            hideInvalidVType: true,
        }, () => { this.handelEditMapping() });
    }

    handleEditInvalidAddr = (data) => {
        this.setState({
            invalidAddrData: JSON.parse(JSON.stringify(data)),
            mappingTitleName: "Set Address",
            hideInvalidAccDiv: true,
            hideInvalidAddrDiv: false,
            hideInvalidVType: true,
        }, () => { this.handelEditMapping() });
    }

    handleEditInvalidVTypes = (data) => {
        this.setState({
            invalidVTypeData: JSON.parse(JSON.stringify(data)),
            mappingTitleName: "Set Vehicle Type",
            hideInvalidAccDiv: true,
            hideInvalidAddrDiv: true,
            hideInvalidVType: false,
        }, () => { this.handelEditMapping() });
    }

    handelEditMapping = () => {
        this.setState({ showMappingPopup: true });
    }

    handleSaveEditMappingValidation = () => {
        let errors = this.state.errors;
        errors.mapping = '';
        let formIsValid = true;

        if (!this.state.hideInvalidAccDiv) {
            if (this.state.invalidAccData.mappingName === null || this.state.invalidAccData.mappingName == '') {
                errors.mapping = 'Please select account.';
                formIsValid = false;
            }
        }
        if (!this.state.hideInvalidAddrDiv) {
            if (this.state.invalidAddrData.mappingName === null || this.state.invalidAddrData.mappingName == '') {
                errors.mapping = 'Please select zone.';
                formIsValid = false;
            }
        }

        if (!this.state.hideInvalidVType) {
            if (this.state.invalidVehicleTypes.mappingName === null || this.state.invalidVehicleTypes.mappingName == '') {
                errors.mapping = 'Please select vehicle type.';
                formIsValid = false;
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleResetMappingPopup = (e) => {
        try {
            let errors = this.state.errors;
            errors.mapping = '';
            let invalidAccData = this.state.invalidAccData;
            invalidAccData.mappingName = '';
            invalidAccData.name = '';
            let invalidAddrData = this.state.invalidAddrData;
            invalidAddrData.mappingName = '';
            invalidAddrData.name = '';
            this.setState({
                selectedAccountRowKeys: [],
                selectedAddressRowKeys : [],
                invalidAddrData : [],
                showMappingPopup: false,
                hideInvalidAccDiv: true,
                hideInvalidAddrDiv: true,
                hideInvalidVType: true,
                invalidAccData: invalidAccData, invalidAddrData: invalidAddrData,
                errors: errors,
                mappingName: '',
            })
        } catch (ex) {
            console.log("Error in " + this.displayName + ".handleResetMappingPopup function", ex);
        }
    }

    handleSaveEditMapping = (e) => {
        if (this.handleSaveEditMappingValidation()) {
            if (!this.state.hideInvalidAccDiv) {
                let invalidAccounts = JSON.parse(JSON.stringify(this.state.invalidAccounts));
                if (this.state.selectedAccountRowKeys.length > 0) {
                    this.state.selectedAccountRowKeys.map((item) => {
                        let invalidAcc = invalidAccounts.filter(c => c.name == item)[0];
                        invalidAcc.mappingName = this.state.invalidAccData.mappingName;
                    })
                }
                else {
                    let invalidAcc = invalidAccounts.filter(c => c.name == this.state.invalidAccData.name)[0];
                    invalidAcc.mappingName = this.state.invalidAccData.mappingName;
                }
                this.setState({  invalidAccounts: invalidAccounts });
            }
            if (!this.state.hideInvalidAddrDiv) {
                let invalidAddresses = JSON.parse(JSON.stringify(this.state.invalidAddresses));
                if (this.state.selectedAddressRowKeys.length > 0) {
                    this.state.selectedAddressRowKeys.map((item) => {
                        let invalidAddr = invalidAddresses.filter(c => c.name == item)[0];
                        invalidAddr.mappingName = this.state.invalidAddrData.mappingName;
                    })
                } else {
                    let invalidAddr = invalidAddresses.filter(c => c.name == this.state.invalidAddrData.name)[0];
                    invalidAddr.mappingName = this.state.invalidAddrData.mappingName;
                }
                this.setState({ invalidAddresses : invalidAddresses });
            }

            if (!this.state.hideInvalidVType) {
                let invalidVehicleTypes = JSON.parse(JSON.stringify(this.state.invalidVehicleTypes));
                let invalidVType = invalidVehicleTypes.filter(c => c.name == this.state.invalidVTypeData.name)[0];
                invalidVType.mappingName = this.state.invalidVTypeData.mappingName;
                this.setState({ invalidVehicleTypes: invalidVehicleTypes });
            }
            //Swal.fire({
            //    icon: 'success',
            //    title: 'Success',
            //    text: 'Data updated successfully.',
            //    allowOutsideClick: false,
            //});
            this.handleResetMappingPopup();
        }
    }

    handleSubmitMapping = async (e) => {
        e.preventDefault();
        try {
            this.setState({ loadPanelVisible: true, isSubmitted: true });
            let accountId = parseInt(sessionStorage.getItem('session_accountId'));
            var request1 = {};
            request1.AccountId = accountId;
            request1.Mappings = this.state.invalidAccounts;
            request1.ItemType = "Customer";
            const requestParams1 = getRequestParams('POST', request1);
            await fetch('reservation-api/ImportMapping', requestParams1);

            var request2 = {};
            request2.AccountId = accountId;
            request2.Mappings = this.state.invalidAddresses;
            request2.ItemType = "Zone";
            const requestParams2 = getRequestParams('POST', request2);
            await fetch('reservation-api/ImportMapping', requestParams2);

            var request3 = {};
            request3.AccountId = accountId;
            request3.Mappings = this.state.invalidVehicleTypes;
            request3.ItemType = "VehicleType";
            const requestParams3 = getRequestParams('POST', request3);
            await fetch('reservation-api/ImportMapping', requestParams3);

            this.handleReadDataSubmit();
            this.bindDropDowns();
            this.setState({ loadPanelVisible: false, isSubmitted: false });

        } catch (ex) {
            this.setState({ loadPanelVisible: false, isSubmitted: false });
            console.log("Error in " + this.displayName + ".handleSubmitMapping function", ex);
        }
    }

    handleRowPrepared(e) {
        if (e.rowType == 'data') {
            if (!e.data.isValid) {
                e.rowElement.classList.add("bgpending");
            }
        }
    }

    handleAccountsSelectionChanged = (e) => {
        this.setState({ selectedAccountRowKeys: e.selectedRowKeys });
    }

    handleSetMultipleInvalidAcc = (e) => {
        if (this.state.selectedAccountRowKeys.length == 0) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select atleast one account from list.',
                allowOutsideClick: false,
            });
            return false;
        }
        let invalidAccData = this.state.invalidAccData; invalidAccData.name = this.state.selectedAccountRowKeys[0];
        this.setState({
            mappingTitleName: "Set Account",
            hideInvalidAccDiv: false,
            hideInvalidAddrDiv: true,
            hideInvalidVType: true,
            invalidAccData: invalidAccData,
        }, () => { this.handelEditMapping(); });

    }

    handleAddressSelectionChanged = (e) => {
        this.setState({ selectedAddressRowKeys: e.selectedRowKeys });
    }

    handleSetMultipleInvalidAddr = () => {
        if (this.state.selectedAddressRowKeys.length == 0) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select atleast one zone from list.',
                allowOutsideClick: false,
            });
            return false;
        }
        let invalidAddrData = this.state.invalidAddrData; invalidAddrData.name = this.state.selectedAddressRowKeys[0];
        this.setState({
            mappingTitleName: "Set Zone",
            hideInvalidAccDiv: true,
            hideInvalidAddrDiv: false,
            hideInvalidVType: true,
            invalidAddrData: invalidAddrData,
        }, () => { this.handelEditMapping(); });
    }

    //View Trips

    handleViewTrips = async () => {
        try {
            this.setState({ loadPanelVisible: true, isSubmitted: true })
            let accountId = sessionStorage.getItem('session_accountId');
            const requestParams = getRequestParams('GET');
            const response = await fetch('reservation-api/LuxaryRezImportMapping/' + accountId, requestParams);
            const data = await response.json();
            this.setState({
                luxaryRezMappingList: data != null ? data : [], showLuxaryTripsMappingPopup: true, loadPanelVisible: false, isSubmitted: false
            });
        } catch (ex) {
            console.log("Error in " + this.displayName + ".handleViewTrips function", ex);
        }
    }

    handleLuxTripsSelectionChanged = (e) => {
        this.setState({ selectedLuxTripsRowKeys: e.selectedRowKeys });
    }

    handleResetLuxaryTripsMapping = () => {
        this.setState({ showLuxaryTripsMappingPopup: false, selectedLuxTripsRowKeys: [] });
    }

    handleDelLuxaryTripsMapping = () => {
        if (this.state.selectedLuxTripsRowKeys.length == 0) {
            Swal.fire({
                icon: 'error',
                title:'Oops...',
                text: 'Please select atleast one record.',
                allowOutsideClick: false,
            })
            return false;
        }
        Swal.fire({
            html: 'Are you sure, Do you want to delete selected trips?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            allowOutsideClick: false
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.setState({ loadPanelVisible: true,isSubmitted: true })
                try {
                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'CultureCode': sessionStorage.getItem('session_culturecode'),
                            'Authorization': 'Bearer ' + sessionStorage.getItem('session_token')
                        },
                        body: JSON.stringify(this.state.selectedLuxTripsRowKeys)
                    };
                   
                    fetch('reservation-api/DeleteLuxaryRezImportMapping', requestOptions)
                        .then(response => { if (response.ok) return response.json() })
                        .then(data => {
                            this.setState({ loadPanelVisible: false, isSubmitted: false });
                            if (data != null) {
                                Swal.fire({
                                    icon: data ? 'success' : 'error',
                                    title: data ? 'Success' : 'Oops...',
                                    text: data ? "Selected Trips deleted successfully." : "Somthing went wrong",
                                    allowOutsideClick: false,
                                }).then((result) => {
                                    if (data) {
                                        if (result.isConfirmed) {
                                            this.setState({ showLuxaryTripsMappingPopup: false, loadPanelVisible: false, isSubmitted: false, luxaryRezMappingList : [] });
                                        }
                                    }
                                })
                            }
                        });

                } catch (ex) {
                    this.setState({ loadPanelVisible: false, isSubmitted: false });
                    console.log("Error in " + this.displayName + ".handleSubmit function", ex);
                }
            }
        });
       
    }

    toolbarItemRenderForAccountGrid() {
        return (
            <Fragment>
                <div className='filter-header'>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <button type="submit" className="btn btn-success btn-sm" disabled={ this.state.invalidAccounts.length == 0} onClick={this.handleSetMultipleInvalidAcc}>
                                {this.state.isFirstSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isFirstSubmited && <span title="Set Account"><i className="fas fa-edit"></i> Set Account</span>}
                            </button>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }

    toolbarItemRenderForAddressGrid() {
        return (
            <Fragment>
                <div className='filter-header'>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <button type="submit" className="btn btn-success btn-sm" disabled={this.state.invalidAddresses.length == 0 } onClick={this.handleSetMultipleInvalidAddr}>
                                {this.state.isFirstSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isFirstSubmited && <span title="Set Zone"><i className="fas fa-edit"></i> Set Zone</span>}
                            </button>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
   
    render() {
        const renderGridEditCell = (data) => {
            if (!data.data.isValid) {
                return <div className='row' style={{ marginLeft: 0 }}>
                    <NavLink tag={Link} to="#" style={{ padding: 0 }} onClick={(e) => { this.handleEdit(data.data) }} ><i className="far fa-edit"></i></NavLink>&nbsp;
                </div>;
            }
            return '';
        }

        const renderInvalidAccGridEditCell = (data) => {
            return <div className='row' style={{ marginLeft: 0 }}>
                <NavLink tag={Link} to="#" style={{ padding: 0 }} onClick={(e) => { this.handleEditInvalidAcc(data.data) }} ><i className="far fa-edit"></i></NavLink>&nbsp;
            </div>;
        }

        const renderInvalidAddrGridEditCell = (data) => {
            return <div className='row' style={{ marginLeft: 0 }}>
                <NavLink tag={Link} to="#" style={{ padding: 0 }} onClick={(e) => { this.handleEditInvalidAddr(data.data) }} ><i className="far fa-edit"></i></NavLink>&nbsp;
            </div>;
        }

        const renderInvalidVTypesGridEditCell = (data) => {
            return <div className='row' style={{ marginLeft: 0 }}>
                <NavLink tag={Link} to="#" style={{ padding: 0 }} onClick={(e) => { this.handleEditInvalidVTypes(data.data) }} ><i className="far fa-edit"></i></NavLink>&nbsp;
            </div>;
        }

        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <h1 className="m-0 text-dark">Upload Trip</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content">
                    <div className="card card-grid">
                        <div className="card-body" >
                            <form method="post" style={this.state.isFirstSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Attachment <span className="text-danger">*</span></label>
                                            <input type="file" name="attachment" className="form-control form-control-sm" style={{ padding: 2 + 'px' }} placeholder="Upload document." onChange={this.handleInputChange} />
                                            {this.state.errors["attachment"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["attachment"]}</span>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <br />
                                            <button type="submit" className="btn btn-success btn-sm" onClick={this.handleReadDataSubmit}>
                                                {this.state.isFirstSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                                {!this.state.isFirstSubmited && <span title="Upload Trips"><i className="fas fa-upload"></i> Upload Trips</span>}
                                            </button>&nbsp;
                                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleViewTrips}>
                                                {this.state.isFirstSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                                {!this.state.isFirstSubmited && <span title="View Mapping"><i className="fas fa-eye"></i> View Mapping</span>}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="content">
                        <div className="card mt-2">
                            <div className="card-body">
                                <ul className="nav nav-tabs" id="custom-content-below-tab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="trip-list-tab" data-toggle="pill" href="#trip-list" role="tab" aria-controls="trip-list" aria-selected="true">Trips</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="mapping-list-tab" data-toggle="pill" href="#mapping-list" role="tab" aria-controls="mapping-list" aria-selected="false">Account/Address Mapping</a>
                                    </li>
                                </ul>

                                <div className="tab-content" id="custom-content-below-tabContent">
                                    <div className="tab-pane fade show active" id="trip-list" role="tabpanel" aria-labelledby="trip-list-tab">
                                        <DataGrid
                                            dataSource={this.state.tripsList}
                                            //keyExpr="tripId"
                                            selection={{ mode: 'single' }}
                                            columnsAutoWidth={false}
                                            showColumnLines={true}
                                            showRowLines={true}
                                            showBorders={true}
                                            rowAlternationEnabled={false}
                                            allowColumnResizing={true}
                                            onRowPrepared={this.handleRowPrepared}
                                            columnResizingMode='widget'
                                        >
                                            <Column dataField="tripId" width={55} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridEditCell} />
                                            <Column dataField="apiRemarks" caption="Remark" width={150} allowFiltering={true} allowSorting={true} allowSearch={true} />
                                            <Column dataField="confirmNo" width={100} caption="Confirm No" allowFiltering={true} allowSorting={true} allowSearch={true} />
                                            <Column dataField="puDate" caption="Pu Date" width={100} allowFiltering={true} allowSorting={true} allowSearch={true} />
                                            <Column dataField="puTime" caption="Pu Time" width={100} allowFiltering={true} allowSorting={true} allowSearch={true} />
                                            <Column dataField="doTime" caption="Do Time" width={100} allowFiltering={true} allowSorting={true} allowSearch={true} />
                                            <Column dataField="passengerName" caption="Passenger Name" width={150} allowFiltering={true} allowSorting={true} allowSearch={true} />
                                            <Column dataField="routingDetails" caption="Routing Details" width={150} allowFiltering={true} allowSorting={true} allowSearch={true} />
                                            <Column dataField="paxCount" caption="Pax Count" width={80} allowFiltering={true} allowSorting={true} allowSearch={true} />
                                            <Column dataField="driver" caption="Driver" width={150} allowFiltering={true} allowSorting={true} allowSearch={true} />
                                            <Column dataField="vehicleNo" caption="Vehicle#" width={150} allowFiltering={true} allowSorting={true} allowSearch={true} />
                                            <Column dataField="vehicleType" caption="Vehicle Type" width={150} allowFiltering={true} allowSorting={true} allowSearch={true} />
                                            <Column dataField="serviceType" caption="Service Type" width={150} allowFiltering={true} allowSorting={true} allowSearch={true} />
                                            <Column dataField="status" caption="Status" width={60} allowFiltering={true} allowSorting={true} allowSearch={true} />
                                            <Column dataField="tripTotal" caption="Trip Total" width={100} allowFiltering={true} allowSorting={true} allowSearch={true} />

                                            <SortByGroupSummaryInfo summaryItem="count" />
                                            <GroupPanel visible={false} />
                                            <HeaderFilter visible={true} />
                                            <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                            <Grouping autoExpandAll={true} />
                                            <Paging defaultPageSize={10} />
                                            <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true} />
                                            <Export fileName='Luxury Vans Invalid Trips' enabled={true} allowExportSelectedData={false} />
                                            <ColumnChooser enabled={true} />
                                            <ColumnFixing enabled={true} />

                                        </DataGrid>
                                        <div className='row' style={{ display: this.state.tripsList.length > 0 ? 'block' : 'none' }}>
                                            <div className="col-lg-2 col-md-2 col-sm-2">
                                                <button type="button" className="btn btn-success btn-sm" onClick={this.handleSubmit}>
                                                    {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In Process</span>}
                                                    {!this.state.isSubmitted && <span title="Generate Trips"><i className="fas fa-check"></i> Generate Trips</span>}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="mapping-list" role="tabpanel" aria-labelledby="mapping-list-tab">
                                        <div className='row'>
                                            <DataGrid
                                                dataSource={this.state.invalidAccounts}
                                                keyExpr="name"
                                                columnsAutoWidth={true}
                                                showColumnLines={true}
                                                showRowLines={true}
                                                showBorders={true}
                                                rowAlternationEnabled={false}
                                                allowColumnResizing={true}
                                                columnResizingMode='widget'
                                                showBorders={true}
                                                selectedRowKeys={this.state.selectedAccountRowKeys}
                                                onSelectionChanged={this.handleAccountsSelectionChanged}
                                                ref={ref => this.dataGrid = ref}
                                                onToolbarPreparing={this.handleAccountToolbarPreparing}
                                            >
                                                <Selection
                                                    mode="multiple"
                                                    selectAllMode={"allPages"}
                                                    showCheckBoxesMode={"always"}
                                                />
                                                <Column dataField="" width={55} caption="" allowFiltering={false} allowSorting={false} cellRender={renderInvalidAccGridEditCell} />
                                                <Column dataField="name" width={400} caption="Account Name" allowFiltering={true} allowSorting={true} allowSearch={true} />
                                                <Column dataField="mappingName" caption="Account Code" width={100} allowFiltering={true} allowSorting={true} allowSearch={true} />
                                                <ColumnChooser enabled={false} />
                                                <Grouping autoExpandAll={false} />
                                                <HeaderFilter visible={true} />
                                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                                <Export fileName='InvalidAccounts' visible={true} enabled={false} allowExportSelectedData={false} />
                                                <GroupPanel visible={false} />
                                                <Paging defaultPageSize={10} />
                                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                                <Template name="toolbarTemplate" render={this.toolbarItemRenderForAccountGrid} />
                                            </DataGrid>
                                            <DataGrid
                                                dataSource={this.state.invalidAddresses}
                                                keyExpr="name"
                                                columnsAutoWidth={true}
                                                showColumnLines={true}
                                                showRowLines={true}
                                                showBorders={true}
                                                rowAlternationEnabled={false}
                                                allowColumnResizing={true}
                                                columnResizingMode='widget'
                                                showBorders={true}
                                                selectedRowKeys={this.state.selectedAddressRowKeys}
                                                onSelectionChanged={this.handleAddressSelectionChanged}
                                                onToolbarPreparing={this.handleAddressToolbarPreparing}
                                            >
                                                <Selection
                                                    mode="multiple"
                                                    selectAllMode={"allPages"}
                                                    showCheckBoxesMode={"always"}
                                                />
                                                <Column dataField="" width={55} caption="" allowFiltering={false} allowSorting={false} cellRender={renderInvalidAddrGridEditCell} />
                                                <Column dataField="name" width={400} caption="Address" allowFiltering={true} allowSorting={true} allowSearch={true} />
                                                <Column dataField="mappingName" caption="Zone Name" width={100} allowFiltering={true} allowSorting={true} allowSearch={true} />
                                                <ColumnChooser enabled={false} />
                                                <Grouping autoExpandAll={false} />
                                                <HeaderFilter visible={true} />
                                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                                <Export fileName='InvalidAddress' visible={true} enabled={false} allowExportSelectedData={false} />
                                                <GroupPanel visible={false} />
                                                <Paging defaultPageSize={10} />
                                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                                <Template name="toolbarTemplate" render={this.toolbarItemRenderForAddressGrid} />

                                            </DataGrid>
                                            <DataGrid
                                                dataSource={this.state.invalidVehicleTypes}
                                                columnsAutoWidth={true}
                                                showColumnLines={true}
                                                showRowLines={true}
                                                showBorders={true}
                                                rowAlternationEnabled={false}
                                                allowColumnResizing={true}
                                                columnResizingMode='widget'
                                                showBorders={true}
                                            >
                                                <Column dataField="" width={55} caption="" allowFiltering={false} allowSorting={false} cellRender={renderInvalidVTypesGridEditCell} />
                                                <Column dataField="name" width={400} caption="Vehicle Type" allowFiltering={true} allowSorting={true} allowSearch={true} />
                                                <Column dataField="mappingName" caption="UTWiz Vehicle Type" width={100} allowFiltering={true} allowSorting={true} allowSearch={true} />

                                                <ColumnChooser enabled={false} />
                                                <Grouping autoExpandAll={false} />
                                                <HeaderFilter visible={true} />
                                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                                <Export fileName='InvalidVehicleTypes' visible={true} enabled={false} allowExportSelectedData={false} />
                                                <GroupPanel visible={false} />
                                                <Paging defaultPageSize={10} />
                                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />

                                            </DataGrid>
                                        </div>
                                        <div className='row' style={{ display: this.state.invalidAccounts.length || this.state.invalidAddresses.length || this.state.invalidVehicleTypes.length > 0 ? 'block' : 'none' }}>
                                            <div className="col-lg-2 col-md-2 col-sm-2">
                                                <button type="button" className="btn btn-success btn-sm" onClick={this.handleSubmitMapping}>
                                                    {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In Process</span>}
                                                    {!this.state.isSubmitted && <span title="Update Mapping & Reprocess"><i className="fas fa-check"></i> Update Mapping & Reprocess</span>}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal ariaHideApp={false}
                    isOpen={this.state.showEditTripPopup}
                    style={{ content: { width: '40%' } }}
                    className={"react-modal"}
                    onRequestClose={this.handleResetStateData}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form style={this.state.isSubmitted ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Edit Trip</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleResetStateData}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label>Passenger Name</label>
                                        <input type="text" className="form-control form-control-sm" name="confirmNo" disabled={true} value={this.state.tripData.passengerName} />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label>ConfirmNo</label>
                                        <input type="text" className="form-control form-control-sm" name="confirmNo" disabled={true} value={this.state.tripData.confirmNo} />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label>PickUp Date</label>
                                        <input type="text" className="form-control form-control-sm" name="puDate" disabled={true} value={this.state.tripData.puDate} />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label>PickUp Time</label>
                                        <input type="text" className="form-control form-control-sm" name="puDate" disabled={true} value={this.state.tripData.puTime} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group">
                                        <label>Routing Details</label>
                                        <input type="text" className="form-control form-control-sm" name="routingDetails" disabled={true} value={this.state.tripData.routingDetails} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label>Pax Count</label>
                                        <input type="text" className="form-control form-control-sm" name="paxCount" disabled={true} value={this.state.tripData.paxCount} />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label>Vehicle Type</label>
                                        <input type="text" className="form-control form-control-sm" name="vehicleType" disabled={true} value={this.state.tripData.vehicleType} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label>Service Type</label>
                                        <input type="text" className="form-control form-control-sm" name="serviceType" disabled={true} value={this.state.tripData.serviceType} />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label>Remarks</label>
                                        <input type="text" className="form-control form-control-sm" name="apiRemarks" disabled={true} value={this.state.tripData.apiRemarks} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label>Select Account</label>
                                        <SelectBox
                                            dataSource={this.state.accountsList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select Account"
                                            name="accountName"
                                            defaultValue={this.state.tripData.utWizAccountId}
                                            value={this.state.tripData.utWizAccountId}
                                            displayExpr='accountName'
                                            valueExpr='accountId'
                                            onSelectionChanged={this.handleOnAccountChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                            width="100%" />
                                        {this.state.errors["accountName"].length > 0 && <span className="error invalid-feedback">{this.state.errors["accountName"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label>Vehicle Type</label>
                                        <SelectBox
                                            dataSource={this.state.vehicleTypesList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select Vehicle Type"
                                            name="name"
                                            defaultValue={this.state.tripData.utWizVehicleTypeId}
                                            value={this.state.tripData.utWizVehicleTypeId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnVehicleTypeChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                            width="100%" />
                                        {this.state.errors["vehicleType"].length > 0 && <span className="error invalid-feedback">{this.state.errors["vehicleType"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label>PickUp Location</label>
                                        <SelectBox
                                            dataSource={this.state.serviceZoneList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select Zone"
                                            name="name"
                                            defaultValue={this.state.tripData.utWizPuZoneId}
                                            value={this.state.tripData.utWizPuZoneId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnPuZoneChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                            width="100%" />
                                        {this.state.errors["puZone"].length > 0 && <span className="error invalid-feedback">{this.state.errors["puZone"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label>DropOff Location</label>
                                        <SelectBox
                                            dataSource={this.state.serviceZoneList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select Zone"
                                            name="name"
                                            defaultValue={this.state.tripData.utWizDoZoneId}
                                            value={this.state.tripData.utWizDoZoneId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnDoZoneChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                            width="100%" />
                                        {this.state.errors["doZone"].length > 0 && <span className="error invalid-feedback">{this.state.errors["doZone"]}</span>}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleSaveEditTrip}>
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In Process</span>}
                                {!this.state.isSubmitted && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleResetStateData} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>

                <Modal ariaHideApp={false}
                    isOpen={this.state.showMappingPopup}
                    style={{ content: { width: '40%' } }}
                    className={"react-modal"}
                    onRequestClose={this.handleResetMappingPopup}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form style={this.state.isSubmitted ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{this.state.mappingTitleName}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleResetMappingPopup}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <div className="row" hidden={this.state.hideInvalidAccDiv}>
                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label>Account Name</label>
                                        <input type="text" className="form-control form-control-sm" name="accountName" disabled={true} value={this.state.invalidAccData.name} />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label>Select Account</label>
                                        <SelectBox
                                            dataSource={this.state.accountsList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select Account"
                                            name="accountCode"
                                            defaultValue={this.state.invalidAccData.mappingName}
                                            value={this.state.invalidAccData.mappingName}
                                            displayExpr='accountCode'
                                            valueExpr='accountCode'
                                            onSelectionChanged={this.handleOnAccCodeChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                            width="100%" />
                                        {this.state.errors["mapping"].length > 0 && <span className="error invalid-feedback">{this.state.errors["mapping"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row" hidden={this.state.hideInvalidAddrDiv}>
                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label>Address</label>
                                        <input type="text" className="form-control form-control-sm" name="address" disabled={true} value={this.state.invalidAddrData.name} />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label>Select Zone</label>
                                        <SelectBox
                                            dataSource={this.state.serviceZoneList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select Zone"
                                            name="name"
                                            defaultValue={this.state.invalidAddrData.mappingName}
                                            value={this.state.invalidAddrData.mappingName}
                                            displayExpr='name'
                                            valueExpr='name'
                                            onSelectionChanged={this.handleOnZoneNameChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                            width="100%" />
                                        {this.state.errors["mapping"].length > 0 && <span className="error invalid-feedback">{this.state.errors["mapping"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row" hidden={this.state.hideInvalidVType}>
                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label>Vehicle Type</label>
                                        <input type="text" className="form-control form-control-sm" name="vehicleType" disabled={true} value={this.state.invalidVTypeData.name} />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label>UTWiz Vehicle Type</label>
                                        <SelectBox
                                            dataSource={this.state.vehicleTypesList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select UTWiz Vehicle Type"
                                            name="name"
                                            defaultValue={this.state.invalidVTypeData.mappingName}
                                            value={this.state.invalidVTypeData.mappingName}
                                            displayExpr='name'
                                            valueExpr='name'
                                            onSelectionChanged={this.handleOnVTypeChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                            width="100%" />
                                        {this.state.errors["mapping"].length > 0 && <span className="error invalid-feedback">{this.state.errors["mapping"]}</span>}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleSaveEditMapping}>
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In Process</span>}
                                {!this.state.isSubmitted && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleResetMappingPopup} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>

                <Modal ariaHideApp={false}
                    isOpen={this.state.showLuxaryTripsMappingPopup}
                    style={{ content: { width: '50%' } }}
                    className={"react-modal"}
                    onRequestClose={this.handleResetLuxaryTripsMapping}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form style={this.state.isSubmitted ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Luxary Rezervation Mapping List</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleResetLuxaryTripsMapping}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <div className="row">
                                <DataGrid
                                    dataSource={this.state.luxaryRezMappingList}
                                    keyExpr="id"
                                    columnsAutoWidth={true}
                                    showColumnLines={true}
                                    showRowLines={true}
                                    showBorders={true}
                                    rowAlternationEnabled={false}
                                    allowColumnResizing={true}
                                    columnResizingMode='widget'
                                    showBorders={true}
                                    selectedRowKeys={this.state.selectedLuxTripsRowKeys}
                                    onSelectionChanged={this.handleLuxTripsSelectionChanged}
                                    ref={ref => this.dataGrid = ref}
                                >
                                    <Selection
                                        mode="multiple"
                                        selectAllMode={"allPages"}
                                        showCheckBoxesMode={"always"}
                                    />
                                    <Column dataField="itemType" width={200} caption="Item Type" allowFiltering={true} allowSorting={true} allowSearch={true} />
                                    <Column dataField="searchText" caption="Mapping Name" width={200} allowFiltering={true} allowSorting={true} allowSearch={true} />
                                    <Column dataField="value" caption="Mapping Value" width={200} allowFiltering={true} allowSorting={true} allowSearch={true} />
                                    <ColumnChooser enabled={false} />
                                    <Grouping autoExpandAll={false} />
                                    <HeaderFilter visible={true} />
                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                    <Export fileName='LuxaryRezImportMapping' visible={true} enabled={false} allowExportSelectedData={false} />
                                    <GroupPanel visible={false} />
                                    <Paging defaultPageSize={10} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                                </DataGrid>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleDelLuxaryTripsMapping}>
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In Process</span>}
                                {!this.state.isSubmitted && <span title="Delete"><i className="fas fa-trash"></i> Delete</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleResetLuxaryTripsMapping} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>

                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >);
    }
}