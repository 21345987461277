import React, { Component } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Swal from 'sweetalert2';
import { MasterHistoryComponent } from '../Shared/MasterHistoryComponent';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { LoadPanel } from 'devextreme-react/load-panel';
export class ReimbursementPayPeriodComponent extends Component {
    constructor() {
        super();
        this.state = {
            reimbursementPayPeriodlist: [],
            loadPanelVisible: false,
        }
    }

    componentDidMount() {
        this.getReimbursementPayPeriodList();
    };

    async getReimbursementPayPeriodList() {
        this.setState({
            loadPanelVisible: true
        });

        const requestParams = getRequestParams('GET', '');
        const response = await fetch('reimbursement-pay-period-api/vehiclepayperiodlist/' + 0, requestParams);
        const data = await response.json();
        if (data !== null && data.reimbursementPayPeriod.length > 0) {
            this.setState({
                reimbursementPayPeriodlist: data.reimbursementPayPeriod,
                loadPanelVisible: false
            });
        } else {
            this.setState({
                reimbursementPayPeriodlist: [],
                loadPanelVisible: false
            });
        }
    }

    handleMasterHistoryModal(e, data) {
        e.preventDefault();
        this.refs.masterHistoryComponent.showModal('reimbursement-pay-period-api', 'Vehicle Pay Period', '', data.rowIndex === null ? 0 : Number(data.rowIndex));
    };
    handleLockUnlockModal = async (e, data) => {
        let isResult = false;
        e.preventDefault();
        await Swal.fire({
            title: 'Lock/Unlock Confirmation',
            html: data.isClosed === true ? 'Do you want to Unlock the selected period?' : 'Do you want to lock the selected period?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            } else {
                this.setState({ isSubmited: false });
            }
        })

        if (isResult) {
            var request = new Object();
            request.RowIndex = data.rowIndex;
            request.IsLocked = data.isClosed == true ? false : true;

            const requestParams = getRequestParams('POST', request);
            const response = await fetch('reimbursement-pay-period-api/updateVehiclePayPeriod/', requestParams);
            const dataResult = await response.json();
            Swal.fire({
                icon: dataResult.isSuccess ? 'success' : 'error',
                title: dataResult.isSuccess ? 'Success' : 'Oops...',
                text: dataResult.resultMessage,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (dataResult.isSuccess) {
                    if (result.isConfirmed) {
                        this.setState({ isSubmited: false });
                        this.getReimbursementPayPeriodList();
                    }
                } else {
                    this.setState({ isSubmited: false })
                }
            })
        }
    }
    render() {
        const renderHistoryCell = (data) => {
            return <div><a href="#" className="fas fa-history icon text-info" title="History" onClick={(e) => { this.handleMasterHistoryModal(e, data.data) }}></a>
            </div>;
        }
        const renderLockUnlockCell = (data) => {
            return <div>{data.data.isClosed === true ? <a href="#" title="Unlock" onClick={(e) => { this.handleLockUnlockModal(e, data.data) }} title="Unlock" >Unlock</a> : <a href="#" title="Lock" onClick={(e) => { this.handleLockUnlockModal(e, data.data) }} >Lock</a>}
            </div>;
        }
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <h1 className="m-0 text-dark">Reimbursement Pay Period</h1>
                            </div>
                            <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem>
                                        <NavLink tag={Link} to="/generate/payout" className="btn btn-block btn-outline-secondary btn-sm" title="Generate Payout">
                                            <i className="fas fa-file-invoice-dollar"></i> Generate Payout</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="/post/payout" className="btn btn-block btn-outline-secondary btn-sm" title="Post Ride(s)">
                                            <i className="fas fa-check"></i> Post Ride(s)</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="/peding/postrides" className="btn btn-block btn-outline-secondary btn-sm" title="Pending Post Ride(s)">
                                            <i className="fas fa-clock"></i> Pending Post Ride(s)</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="/reimbursement/view" className="btn btn-block btn-outline-secondary btn-sm" title="Reimbursement View">
                                            <i className="fas fa-car"></i> Reimb. View</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="/posted/rides" className="btn btn-block btn-outline-secondary btn-sm" title="Posted Ride(s)">
                                            <i className="fas fa-check-double"></i> Posted Ride(s)</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="/reimbursement/history" className="btn btn-block btn-outline-secondary btn-sm" title="Reimbursement History">
                                            <i className="fas fa-history"></i> Reimb. History</NavLink>
                                    </NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="card card-grid">
                        <div className="card-body">
                            <DataGrid
                                dataSource={this.state.reimbursementPayPeriodlist}
                                keyExpr="rowIndex"
                                selection={{ mode: 'single' }}
                                columnsAutoWidth={false}
                                showColumnLines={false}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                showBorders={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                onToolbarPreparing={this.onToolbarPreparing}>
                                <Column dataField="rowIndex" width={50} caption="" allowFiltering={false} allowSorting={false} cellRender={renderHistoryCell} />
                                <Column dataField="rowIndex" width={120} caption="Locked / Unlocked" allowFiltering={false} allowSorting={false} cellRender={renderLockUnlockCell} />
                                <Column dataField="viewDate" caption="Period Date" allowFiltering={true} allowSorting={true} />
                                <Column dataField="payStartDateDisplay" caption="Start Date" allowFiltering={true} allowSorting={true} />
                                <Column dataField="payEndDateDisplay" caption="End Date" />
                                <Column dataField="lockedBy" caption="Locked / Unlocked By" allowFiltering={true} allowSorting={true} />
                                <Column dataField="lockedDate" caption="Locked / Unlocked Date" allowFiltering={true} allowSorting={true} />
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                <Export enabled={true} allowExportSelectedData={false} />
                                <ColumnChooser enabled={true} />
                                <ColumnFixing enabled={true} />

                            </DataGrid>
                        </div>
                    </div>
                </div>
                <MasterHistoryComponent ref="masterHistoryComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />

            </div>)
    }
}