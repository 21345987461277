import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
} from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import { LoadPanel } from 'devextreme-react/load-panel';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';
import { getRequestParams } from '../../helpers/globalfunctions';
const position = { of: '#historydiv' };

const customStyles = {
    content: {
        width: '40%',
    }
};
let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class RouteListComponent extends Component {

    constructor(props) {
        super(props);

        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        this.state = {
            loadPanelVisible: false,
            datasource: [],
            stopDataSource: [],
            isOpenModal: false,
            routeId: 0,
            routeName: '',
        }

        this.getRouteList = this.getRouteList.bind(this);
        this.handelToolbarPreparing = this.handelToolbarPreparing.bind(this);
        this.openEditRouteModel = this.openEditRouteModel.bind(this);
        this.deleteRoute = this.deleteRoute.bind(this);
        this.stopDetails = this.stopDetails.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    handelToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getRouteList.bind(this)
            }
        });
    }

    async componentDidMount() {
        this.setState({ loadPanelVisible: true });
        await this.getRouteList();
        this.setState({ loadPanelVisible: false });
    }

    async getRouteList() {
        var shuttleRoutesRequest = new Object();
        shuttleRoutesRequest.LocationId = Number(sessionStorage.getItem("session_locationId"));

        try {
            const requestParams = getRequestParams('POST', shuttleRoutesRequest);
            const response = await fetch('/route-api/' + this.state.routeId + '/list', requestParams);
            const data = await response.json();
            this.setState({
                datasource: data.shuttleRoutes
            });

        } catch (e) {
            this.setState({ loadPanelVisible: false });
        }
    }

    openEditRouteModel(e, data) {
        e.preventDefault();
        this.props.history.push({
            pathname: '/crudroute',
            //state: { detail: data }
            state: { routeId: (data != null? data.routeId : 0), sourceUrl: "/route/list", sourceState: this.state }
        })
    }

    async isAllowConfirm() {
        let isResult = false;
        await Swal.fire({
            icon: 'question',
            title: 'Delete',
            html: "Are you sure want to delete route?",
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Delete',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        });
        return isResult;
    }

    async deleteRoute(e, rowdata) {
        e.preventDefault();
        try {
            const isConfirm = await this.isAllowConfirm();
            if (!isConfirm) {
                return;
            }
            this.setState({ loadPanelVisible: true });
            let apiUrl = 'route-api' + '/' + rowdata.routeId + '/delete';
            const requestParams = getRequestParams('POST');
            const response = await fetch(apiUrl, requestParams);
            const data = await response.json();

            this.setState({ loadPanelVisible: false });
            Swal.fire({
                icon: data.isSuccess ? 'success' : 'error',
                title: data.isSuccess ? 'success' : 'Oops...',
                text: data.resultMessage,
            }).then((result) => {

                /* Read more about isConfirmed, isDenied below */
                if (data.isSuccess) {
                    this.getRouteList();
                }

            });

        } catch (e) {
            this.setState({ loadPanelVisible: false });
        }
    }

    async stopDetails(e, rowData) {
        e.preventDefault();
        this.setState({ isOpenModal: true, routeName: rowData.routeName, stopDataSource: rowData.shuttleRouteStops })
    }

    closeModal(e) {
        e.preventDefault();
        this.setState({ isOpenModal: false, routeName: "", stopDataSource: [] })
    }

    render() {

        const filterSearchFromDate = time => {
            const currentDate = currentTime;
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }

        const filterSearchToDate = time => {
            const currentDate = currentTime;
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }

        const renderGridCell = (data) => {

            return <div>
                <a href="#" className="fas fa-pen icon text-info" title="View" onClick={(e) => { this.openEditRouteModel(e, data.data) }}></a>&nbsp;&nbsp;
                <a href="#" className="fas fa-list icon text-info" title="Stop Details" onClick={(e) => { this.stopDetails(e, data.data) }}></a>&nbsp;&nbsp;
                <a href="#" className="fas fa-times icon text-info" title="Delete" onClick={(e) => { this.deleteRoute(e, data.data) }}></a>&nbsp;&nbsp;
            </div>;
        }

        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <h1 className="m-0 text-dark">Routes</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem><NavLink tag={Link} to="#" className="btn btn-block btn-outline-secondary btn-sm" onClick={(e) => { this.openEditRouteModel(e, null) }} title="Add Route"><i className="fas fa-plus"></i> Add Route</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/stop/list" className="btn btn-block btn-outline-secondary btn-sm" title="Stops"><i className="fas fa-map-marker"></i> Stops</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <form method="post">
                    <div className="rez-header">
                        <div className="content">
                            <div className="card card-grid">
                                <div className="card-body">
                                    <DataGrid
                                        dataSource={this.state.datasource}
                                        keyExpr="routeId"
                                        selection={{ mode: 'single' }}
                                        columnsAutoWidth={true}
                                        showColumnLines={false}
                                        showRowLines={true}
                                        showBorders={true}
                                        rowAlternationEnabled={true}
                                        allowColumnResizing={true}
                                        columnResizingMode='widget'
                                        onToolbarPreparing={this.handelToolbarPreparing}>
                                        <Column dataField="routeId" width={80} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                        <Column dataField="customerName" caption="Customer Name" allowFiltering={true} allowSorting={true} />
                                        <Column dataField="routeName" caption="Route Name" allowFiltering={true} allowSorting={true} />
                                        <Column dataField="fromStopName" caption="Start Stop" allowFiltering={true} allowSorting={true} />
                                        <Column dataField="toStopName" caption="End Stop" allowFiltering={true} allowSorting={true} />
                                        <Column dataField="routeDuration" caption="Duration" allowFiltering={true} allowSorting={true} />
                                        <Column dataField="routeNumber" caption="Route Number" allowFiltering={true} allowSorting={true} />
                                        <Column dataField="activeStatus" caption="Status" allowFiltering={true} allowSorting={true} />

                                        <SortByGroupSummaryInfo summaryItem="count" />
                                        <GroupPanel visible={true} />
                                        <HeaderFilter visible={false} />
                                        <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                        <Grouping autoExpandAll={true} />
                                        <Paging defaultPageSize={20} />
                                        <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                        <Export enabled={true} allowExportSelectedData={false} />
                                        <ColumnChooser enabled={true} />
                                        <ColumnFixing enabled={true} />
                                    </DataGrid>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={customStyles}
                    className={"react-modal"}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form>
                        <div className="modal-header">
                            <h6 className="modal-title">Stop Details</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <small><b><i>{this.state.routeName}</i></b></small>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="widget-container">

                                        <DataGrid
                                            dataSource={this.state.stopDataSource}
                                            keyExpr="id"
                                            columnsAutoWidth={false}
                                            showColumnLines={false}
                                            showRowLines={true}
                                            showBorders={true}
                                            rowAlternationEnabled={true}
                                            allowColumnResizing={true}
                                            columnResizingMode='widget'>

                                            <Column dataField="routeStopSequence" caption="Sr.No." width={80} allowFiltering={true} allowSorting={true} />
                                            <Column dataField="stopName" caption="Stop Name" width={200} allowFiltering={true} allowSorting={true} />
                                            <Column dataField="locationType" caption="Location Type" allowFiltering={true} allowSorting={true} />
                                            <Column dataField="scheduleTime" caption="Schedule Time" allowFiltering={true} allowSorting={true} />
                                            <Column dataField="stopRadious" caption="Radious" allowFiltering={true} allowSorting={true} />

                                            <SortByGroupSummaryInfo summaryItem="count" />
                                            <GroupPanel visible={false} />
                                            <HeaderFilter visible={false} />
                                            <SearchPanel visible={false} highlightCaseSensitive={true} placeholder="Search..." />
                                            <Grouping autoExpandAll={false} />
                                            <Paging defaultPageSize={10} />
                                            <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10]} showInfo={true} />
                                            <Export enabled={false} allowExportSelectedData={false} />
                                            <ColumnChooser enabled={false} />
                                            <ColumnFixing enabled={false} />

                                        </DataGrid>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeModal}><i className="fas fa-times"></i> Cancel</button>
                        </div>
                    </form>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        )

    }
}