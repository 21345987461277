import React, { Component } from 'react';
import DataGrid, {
    Column, Grouping, GroupPanel, Pager, Paging, SearchPanel, Export, SortByGroupSummaryInfo, ColumnChooser, ColumnFixing, HeaderFilter,
    Button
} from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import Modal from 'react-modal'; //npm install --save react-modal

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import { SelectBox } from 'devextreme-react/select-box';
import NumberFormat from 'react-number-format';
import { ModuleCode, CustomerType } from '../../helpers/fixcodes.js'
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import DateBox from 'devextreme-react/date-box';
import { LoadPanel } from 'devextreme-react/load-panel';

const customStyles = {
    content: {
        width: '50%'
    }
};
const position = { of: '#historydiv' };
export const rideType = [
    {
        id: 1,
        name: "Arrivals"
    },
    {
        id: 2,
        name: "Departure"
    },
    {
        id: 3,
        name: "Both Airport"
    }]
let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class CountyAndCongestionTaxComponent extends Component {
    constructor(props) {
        super(props);
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        this.state = {
            isSubmited: false,
            isCountyCongestionTaxModal: false,
            countyCongestionTaxList: null,
            locationList: [],
            locationId: null,
            taxTypeList: [],
            taxTypeId: 0,
            serviceAreaList: [],
            serviceAreaId: null,
            wefDate: currentTime,
            taxCharge: 0.000,
            loadPanelVisible: false,
            isFlatRate: true,
            isPercentage: false,
            errors: {
                locationId: '',
                taxTypeId: '',
                wefDate: '',
                taxCharge: '',
                serviceAreaId: '',

            }
        }
        this.handleWefDateChange = this.handleWefDateChange.bind(this);
        this.LocationList = this.LocationList.bind(this);
        this.getServiceAreaList = this.getServiceAreaList.bind(this);
        this.getTaxTypeList = this.getTaxTypeList.bind(this);
        this.handleCountyCongestionTaxSubmit = this.handleCountyCongestionTaxSubmit.bind(this);
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
    }
    componentDidMount = () => {
        this.getCountyCongestionTaxList();
    }
    async getCountyCongestionTaxList() {
        this.setState({
            loadPanelVisible: true
        });
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('county-congestion-tax-api/list/0/0/0', requestParams);
        const data = await response.json();
        if (data !== null && data.affCountyWiseTax.length > 0) {
            this.setState({ countyCongestionTaxList: data.affCountyWiseTax, loadPanelVisible: false });
        }
        else {
            this.setState({ countyCongestionTaxList: null, loadPanelVisible: false });
        }
    }
    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getCountyCongestionTaxList()
            }
        });
    }

    async LocationList() {

        let locationId = parseInt(sessionStorage.getItem("session_locationId"));
        let locationName = sessionStorage.getItem("session_locationName");

        const requestParams = getRequestParams('GET', '');
        const response = await fetch('account-api/locations', requestParams);
        const data = await response.json();
        if (data !== null && data.locations.length > 0) {
            this.setState({
                locationList: data.locations,
                locationId: { locationId: locationId, locationName: locationName }
            });
            this.getServiceAreaList(locationId);
        }
    }
    handleWefDateChange(event) {
        if (event.value != null) {
            let fromDateValue = formatDate(event.value, "MM/dd/yyyy");
            this.setState({ wefDate: fromDateValue })
        } else {
            this.setState({ wefDate: null });
        }
        this.state.errors["wefDate"] = '';
    }
    async getServiceAreaList(locationId) {
        if (locationId > 0) {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('serviceArea-api/0', requestParams);
            const data = await response.json();
            if (data !== null && data.locationServiceArea.length > 0) {
                this.setState({
                    serviceAreaList: data.locationServiceArea.filter(x => x.enable === true && x.locationId === locationId)
                });
            }
        }
    }
    async getTaxTypeList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('tax-api', requestParams);
        const data = await response.json();
        if (data !== null && data.taxDropdownList.length > 0) {
            this.setState({
                taxTypeList: data.taxDropdownList.filter(x => x.name !== "PORT AUTHORITY" && x.name !== "UTILITY TAX" && x.name !== "WORKERS COMPENSATION"
                    && x.name !== "CC COMMISSION" && x.name !== "SPECIAL COMMISSION" && x.name !== "SALES TAX")
            });
        }
    }

    getRideTypelist() {
        this.setState({
            rideTypeList: rideType
        });
    }

    addCountyCongestionTaxControl = (e) => {
        e.preventDefault();
        this.setState({
            isCountyCongestionTaxModal: true,
        });
    }
    afterOpenCountyCongestionTaxModal = () => {
        this.LocationList();
        this.getTaxTypeList();
        this.getRideTypelist();
    }
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let errors = this.state.errors;
        switch (name) {
            case 'taxTypeId':
                errors.taxTypeId = "";
                if (parseInt(value) === 0) {
                    this.setState({ taxTypeId: 0 });
                    errors.taxTypeId = "Please select tax type.";
                } else {
                    this.setState({ taxTypeId: parseInt(value) });
                }
                break;
            case 'taxCharge':
                errors.taxCharge = "";
                if (event.target.value !== "" && event.target.value !== null) {
                    this.setState({ taxCharge: parseFloat(event.target.value).toFixed(3) })
                } else {
                    this.setState({ taxCharge: 0.000 });
                    errors.taxCharge = "Please enter tax charge";
                }
                break;
            default:
                this.setState({
                    [name]: value
                });
                break;
        }
    }
 
    handleLocationInputChange = (data) => {
        if (data.selectedItem === null) {
            this.state.errors["locationId"] = "Please select location.";
            this.setState({
                locationId: null
            });
        }
        else {
            this.setState({
                locationId: data.selectedItem
            });
            this.state.errors["locationId"] = "";
            this.getServiceAreaList(data.selectedItem.locationId);
        }
    }
   
    handleServiceAreaInputChange = (data) => {
        this.setState({
            serviceAreaId: data.selectedItem
        });
        if (data.selectedItem === null) {
            this.state.errors["serviceAreaId"] = "Please select service area.";
        }
        else {
            this.state.errors["serviceAreaId"] = "";
        }
    }
    handleWefDateTimeChange = (date) => {
        if (date !== null) {
            this.setState({ wefDate: date });
            this.state.errors["wefDate"] = '';
        } else {
            this.setState({ wefDate: date });
            this.state.errors["wefDate"] = 'Please select wef Date.';
        }
    }
    handleRadioButtonOnChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' || target.type === 'radio' ? target.checked : target.value;
        const name = target.name;
        if (name === "isFlatRate") {
            if (event.target.checked === true) {
                this.setState({ isFlatRate: event.target.checked, isPercentage: false })
            }
        }
        else if (name === "isPercentage") {
            if (event.target.checked === true) {
                this.setState({ isPercentage: event.target.checked, isFlatRate: false })
            }
        }
        else {
            this.setState({
                [name]: value
            });
        }
    }

    handleValidation = () => {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.locationId = '';
        errors.serviceAreaId = '';
        errors.taxTypeId = '';
        errors.wefDate = '';
        errors.taxCharge = '';
        if (this.state.locationId === null || parseInt(this.state.locationId.locationId) === 0) {
            formIsValid = false;
            errors.locationId = "Please select Location.";
        }
        if (this.state.serviceAreaId === null || parseInt(this.state.serviceAreaId.id) === 0) {
            formIsValid = false;
            errors.serviceAreaId = "Please select service area.";
        }
        if (parseInt(this.state.taxTypeId) === 0) {
            formIsValid = false;
            errors.taxTypeId = "Please select Tax Type.";
        }
        if (this.state.wefDate === null) {
            formIsValid = false;
            errors.wefDate = "Please select Wef Date.";
        }
        if (parseFloat(this.state.taxCharge) === 0 || this.state.taxCharge === '') {
            formIsValid = false;
            errors.taxCharge = "Please select tax charge.";
        }
        return formIsValid;
    }

    async handleCountyCongestionTaxSubmit(e) {
        this.setState({ isSubmited: true });
        e.preventDefault();
        if (this.handleValidation()) {
            var newObject = new Object();
            newObject.Id = 0;
            newObject.LocationId = parseInt(this.state.locationId.locationId);
            newObject.ServiceAreaId = parseInt(this.state.serviceAreaId.id);
            newObject.TaxId = parseInt(this.state.taxTypeId);
            newObject.WefDate = this.state.wefDate;
            newObject.TaxCharge = parseFloat(this.state.taxCharge);
            newObject.IsFlatRate = this.state.isFlatRate;

            const requestParams = getRequestParams('POST', newObject);
            const response = await fetch('county-congestion-tax-api/saveUpdate', requestParams);
            const dataResult = await response.json();
            Swal.fire({
                icon: dataResult.isSuccess ? 'success' : 'error',
                title: dataResult.isSuccess ? 'Success' : 'Oops...',
                text: dataResult.resultMessage,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (dataResult.isSuccess) {
                    if (result.isConfirmed) {
                        this.closeCountyCongestionTaxModal();
                        this.getCountyCongestionTaxList();
                    }
                } else {
                    this.setState({ isSubmited: false })
                }
            })
        }
        else {
            this.setState({ isSubmited: false })
        }
    }

    closeCountyCongestionTaxModal = () => {
        this.setState({
            isSubmited: false,
            isCountyCongestionTaxModal: false,
            locationList: [],
            locationId: null,
            taxTypeList: [],
            taxTypeId: 0,
            wefDate: currentTime,
            taxCharge: 0.000,
            rideTypeList: [],
            rideTypeId: 0,
            serviceAreaList: [],
            serviceAreaId: null,
            isFlatRate: true,
            isPercentage: false,
            errors: {
                locationId: '',
                taxTypeId: '',
                wefDate: '',
                taxCharge: '',
                rideTypeId: '',
                serviceAreaId: ''
            }
        });
        this.getCountyCongestionTaxList();
    }
    render() {

        const renderGridCell = (data) => {
            return parseFloat(data.data.taxCharge).toFixed(3);
        }
        const filterPassedTime = time => {
            const currentDate = currentTime;
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }

        return (<div className="content-wrapper">
            <div className="content-header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <h1 className="m-0 text-dark">County and Congestion Tax</h1>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <ol className="breadcrumb float-sm-right">
                                <NavItem>
                                    <NavLink onClick={(e) => { this.addCountyCongestionTaxControl(e) }} className="btn btn-block btn-outline-secondary btn-sm"><i className="fas fa-plus"></i> Add County and Congestion Tax</NavLink>
                                </NavItem>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="card card-grid">
                    <div className="card-body">
                        <DataGrid
                            dataSource={this.state.countyCongestionTaxList}
                            keyExpr="id"
                            selection={{ mode: 'single' }}
                            columnsAutoWidth={false}
                            showColumnLines={false}
                            showRowLines={true}
                            showBorders={true}
                            rowAlternationEnabled={true}
                            showBorders={true}
                            allowColumnResizing={true}
                            columnResizingMode='widget'
                            onToolbarPreparing={this.onToolbarPreparing}>
                            <Column dataField="locationName" width={200} caption="Location Name" allowFiltering={false} allowSorting={false} />
                            <Column dataField="serviceAreaName" caption="Service Area" allowFiltering={false} allowSorting={false} />
                            <Column dataField="taxName" caption="Tax Type" allowFiltering={false} allowSorting={false} />
                            <Column dataField="wefDatestr" caption="WEF Date" allowFiltering={false} allowSorting={false} />
                            <Column dataField="taxCharge" caption="Tax Charge ($)" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                            <SortByGroupSummaryInfo summaryItem="count" />
                            <GroupPanel visible={true} />
                            <HeaderFilter visible={true} />
                            <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                            <Grouping autoExpandAll={true} />
                            <Paging defaultPageSize={20} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20, 50]} showInfo={true} />
                            <Export enabled={true} allowExportSelectedData={false} />
                            <ColumnChooser enabled={true} />
                            <ColumnFixing enabled={true} />

                        </DataGrid>

                    </div>
                </div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isCountyCongestionTaxModal}
                    style={customStyles}
                    className={"react-modal react-modal-driverlist"}
                    onAfterOpen={this.afterOpenCountyCongestionTaxModal}
                    onRequestClose={this.closeCountyCongestionTaxModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleCountyCongestionTaxSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Add County & Congestion Tax</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeCountyCongestionTaxModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">

                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Location <span className="text-red">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.locationList.length > 0 ? this.state.locationList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select location"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.locationId != null ? this.state.locationId.locationId : 0 }
                                            displayExpr='locationName'
                                            valueExpr='locationId'
                                            onSelectionChanged={this.handleLocationInputChange}
                                            showClearButton={false}
                                            disabled={this.state.rowId > 0 ? true : false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["locationId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["locationId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Service Area <span className="text-red">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.serviceAreaList.length > 0 ? this.state.serviceAreaList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select service area"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.serviceAreaId != null ? this.state.serviceAreaId.id : 0}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleServiceAreaInputChange}
                                            showClearButton={false}
                                            disabled={this.state.rowId > 0 ? true : false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["serviceAreaId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["serviceAreaId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Tax Type <span className="text-red">*</span></label>
                                        <select name="taxTypeId" className="form-control form-control-sm" value={this.state.taxTypeId} onChange={this.handleInputChange}>
                                            <option value={0}>Select tax type</option>
                                            {this.state.taxTypeList && this.state.taxTypeList.map((item, i) =>
                                                (<option key={i} value={item.id}>{item.name}</option>)
                                            )}
                                        </select>
                                        {this.state.errors["taxTypeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["taxTypeId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Tax Charge ($)<span className="text-red">*</span></label>
                                        <NumberFormat className="form-control form-control-sm" name="taxCharge" fixedDecimalScale={true} maxLength={8} decimalScale={3} title="Tax Charge" value={this.state.taxCharge} onChange={this.handleInputChange} />
                                        {this.state.errors["taxCharge"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["taxCharge"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Wef Date <span className="text-red">*</span></label>
                                       
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="wefDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.wefDate}
                                            onValueChanged={this.handleWefDateChange}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["wefDate"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["wefDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group" style={{ display: this.state.taxTypeId === 8 ? "inline" : "none" }}><br />
                                        <div className="form-check">
                                            <label className="form-check-label">
                                                <input className="form-check-input" type="radio" style={{ marginTop: "0rem" }} name="isFlatRate" checked={this.state.isFlatRate} plassceholder="" onChange={this.handleRadioButtonOnChange} />
                                                                        Flat Rate</label>
                                        </div>
                                        <div className="form-check">
                                            <label className="form-check-label">
                                                <input className="form-check-input" type="radio" style={{ marginTop: "0rem" }} name="isPercentage" checked={this.state.isPercentage} plassceholder="" onChange={this.handleRadioButtonOnChange} />
                                                                        Percentage</label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success btn-sm" onClick={this.handleCountyCongestionTaxSubmit} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Save"><i className="fas fa-save"></i> Save</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeCountyCongestionTaxModal} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
            </div>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={position}
                visible={this.state.loadPanelVisible}
                showIndicator={true}
                shading={false}
                showPane={true}
                closeOnOutsideClick={false}
            />
        </div>)
    }
}