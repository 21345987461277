import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
} from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import { LoadPanel } from 'devextreme-react/load-panel';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { getRequestParams } from '../../helpers/globalfunctions';
import { AddEditStopComponent } from '../Shared/AddEditStopComponent';
const position = { of: '#historydiv' };

const customStyles = {
    content: {
        width: '40%',
    }
};
let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class StopListComponent extends Component {

    constructor(props) {
        super(props);

        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        this.state = {
            loadPanelVisible: false,
            datasource: [],
            stopId: 0,
        }

        this.getStopList = this.getStopList.bind(this);
        this.handelToolbarPreparing = this.handelToolbarPreparing.bind(this);
        this.openEditStopModel = this.openEditStopModel.bind(this);
        this.deleteStop = this.deleteStop.bind(this);
    }

    handelToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getStopList.bind(this)
            }
        });
    }

    async componentDidMount() {
        this.setState({ loadPanelVisible: true });
        await this.getStopList();
        this.setState({ loadPanelVisible: false });
    }

    async getStopList() {
        var shuttleStopsRequest = new Object();
        shuttleStopsRequest.LocationId = Number(sessionStorage.getItem("session_locationId"));

        try {
            const requestParams = getRequestParams('POST', shuttleStopsRequest);
            const response = await fetch('/stop-api/' + this.state.stopId + '/list', requestParams);
            const data = await response.json();
            this.setState({
                datasource: data.shuttleStops
            });

        } catch (e) {
            this.setState({ loadPanelVisible: false });
        }
    }

    openEditStopModel(e, data) {
        e.preventDefault();
        let stopId = data != null ? data.stopId : 0;
        this.refs.addEditStopComponent.showModal('stop', stopId);
    }

    async isAllowConfirm() {
        let isResult = false;
        await Swal.fire({
            icon: 'question',
            title: 'Delete',
            html: "Are you sure want to delete stop?",
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Delete',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        });
        return isResult;
    }

    async deleteStop(e, rowdata) {
        e.preventDefault();
        try {
            const isConfirm = await this.isAllowConfirm();
            if (!isConfirm) {
                return;
            }
            this.setState({ loadPanelVisible: true });
            let apiUrl = 'stop-api' + '/' + rowdata.stopId + '/delete';
            const requestParams = getRequestParams('POST');
            const response = await fetch(apiUrl, requestParams);
            const data = await response.json();
            this.setState({ loadPanelVisible: false });

            Swal.fire({
                icon: data.isSuccess ? 'success' : 'error',
                title: data.isSuccess ? 'success' : 'Oops...',
                text: data.resultMessage,
            }).then((result) => {

                /* Read more about isConfirmed, isDenied below */
                if (data.isSuccess) {
                    this.getStopList();
                }

            });

        } catch (e) {
            this.setState({ loadPanelVisible: false });
        }
    }



    render() {

        const filterSearchFromDate = time => {
            const currentDate = currentTime;
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }

        const filterSearchToDate = time => {
            const currentDate = currentTime;
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }

        const renderGridCell = (data) => {

            return <div>
                <a href="#" className="fas fa-pen icon text-info" title="View" onClick={(e) => { this.openEditStopModel(e, data.data) }}></a>&nbsp;&nbsp;
                <a href="#" className="fas fa-times icon text-info" title="Delete" onClick={(e) => { this.deleteStop(e, data.data) }}></a>&nbsp;&nbsp;
            </div>;
        }

        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <h1 className="m-0 text-dark">Stops</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem><NavLink tag={Link} to="#" className="btn btn-block btn-outline-secondary btn-sm" onClick={(e) => { this.openEditStopModel(e, null) }} title="Add Stop"><i className="fas fa-plus"></i> Add Stop</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/route/list" className="btn btn-block btn-outline-secondary btn-sm" title="Routes"><i className="fas fa-road"></i> Routes</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <form method="post">
                    <div className="rez-header">
                        <div className="content">
                            <div className="card card-grid">
                                <div className="card-body">
                                    <DataGrid
                                        dataSource={this.state.datasource}
                                        keyExpr="stopId"
                                        selection={{ mode: 'single' }}
                                        columnsAutoWidth={true}
                                        showColumnLines={false}
                                        showRowLines={true}
                                        showBorders={true}
                                        rowAlternationEnabled={true}
                                        allowColumnResizing={true}
                                        columnResizingMode='widget'
                                        onToolbarPreparing={this.handelToolbarPreparing}>
                                        <Column dataField="stopId" width={50} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                        <Column dataField="locationName" caption="Location" allowFiltering={true} allowSorting={true} />
                                        <Column dataField="locationCode" caption="Location Type" allowFiltering={true} allowSorting={true} />
                                        <Column dataField="address" caption="Address" allowFiltering={true} allowSorting={true} />
                                        <Column dataField="stopDescription" caption="Stop Description" allowFiltering={true} allowSorting={true} />
                                        <Column dataField="stopRadious" caption="Stop Radious (Mtr)" allowFiltering={true} allowSorting={true} />
                                        <Column dataField="activeStatus" caption="Status" allowFiltering={true} allowSorting={true} />

                                        <SortByGroupSummaryInfo summaryItem="count" />
                                        <GroupPanel visible={true} />
                                        <HeaderFilter visible={false} />
                                        <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                        <Grouping autoExpandAll={true} />
                                        <Paging defaultPageSize={20} />
                                        <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                        <Export enabled={true} allowExportSelectedData={false} />
                                        <ColumnChooser enabled={true} />
                                        <ColumnFixing enabled={true} />
                                    </DataGrid>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <AddEditStopComponent ref="addEditStopComponent" getStopList={this.getStopList}  />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        )

    }
}