import React, { Component } from 'react';
import DataGrid, {
    Column,
    Editing,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
    RequiredRule,
    PatternRule
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1

import Modal from 'react-modal'; //npm install --save react-modal
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Swal from 'sweetalert2';
import { LoadPanel } from 'devextreme-react/load-panel';
import { getRequestParams } from '../../helpers/globalfunctions.js';
const customStyles = {
    content: {
        width: '45%'
    }
};

export const commissionSettingList = [
    {
        'customerTypeId': 1,
        'customerType': 'AIRLINE/CREW',
        'commission': 0
    },
    {
        'customerTypeId': 2,
        'customerType': 'CUSTOMER SERVICE',
        'commission': 65
    },
    {
        'customerTypeId': 3,
        'customerType': 'CHARTER',
        'commission': 60
    }, {
        'customerTypeId': 4,
        'customerType': 'CONTRACTS',
        'commission': 0
    }
    , {
        'customerTypeId': 5,
        'customerType': 'HOTEL/AGENTS',
        'commission': 65
    }
    , {
        'customerTypeId': 6,
        'customerType': 'VOUCHER/TOUR OPERATOR',
        'commission': 65
    }, {
        'customerTypeId': 7,
        'customerType': 'TRAVEL AGENTS',
        'commission': 65
    }, {
        'customerTypeId': 8,
        'customerType': 'AFFILIATES',
        'commission': 65
    }, {
        'customerTypeId': 9,
        'customerType': 'PERSONAL ACCOUNT CREDIT CARD',
        'commission': 65
    }, {
        'customerTypeId': 10,
        'customerType': 'PERSONAL ACCOUNT CHECK ONLY',
        'commission': 65
    }, {
        'customerTypeId': 11,
        'customerType': 'PERSONAL ACCOUNT CASH ONLY',
        'commission': 65
    }, {
        'customerTypeId': 12,
        'customerType': 'INDIVIDUAL TRAVELLER',
        'commission': 65
    }
];

export class CommissionSettingComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
            loadPanelVisible: false,
            isSubmited: false,
            commissionSettingGridDataSource: null,
            commissionSettingGridDataSourceClone: [],
            pageName: '',
            commissionType: '',
            vehicleOwnerMapId: 0,
            vehicleId: 0,
            vehicleName: '',
            vehicleOwner: '',
        }
        this.handleShowModal = this.handleShowModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.getCommissionSettingList = this.getCommissionSettingList.bind(this);
    }
    getCommissionSettingList = async (commissionType, vehicleId, vehhicleOwnerMapId) => {
        this.setState({ loadPanelVisible: true });
        try {
            let apiUrl = null;
            if (commissionType === 'VC') {
                apiUrl = 'vehicle-api/' + parseInt(vehicleId) + '/commission-setting';
            }
            else if (commissionType === 'VOC') {
                apiUrl = 'vehicle-owner-api/' + parseInt(vehhicleOwnerMapId) + '/commission-setting';
            }
            const requestParams = getRequestParams('GET');
            const response = await fetch(apiUrl, requestParams);
            if (response.ok) {
                const data = await response.json();
                let cloneCommission = JSON.parse(JSON.stringify(data.commissions));
                this.setState({
                    commissionSettingGridDataSource: data.commissions,
                    commissionSettingGridDataSourceClone: cloneCommission
                });
            }
            else {
                Swal.fire({
                    allowOutsideClick: false,
                    showCloseButton: true,
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Unable to process the request.',
                })
            }
        } catch (ex) {
            console.log(ex);
            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong while fetching data!',
            })
        }
        this.setState({ loadPanelVisible: false });
    };
    handleCloseModal = async () => {
        this.setState({
            isOpenModal: false,
            isSubmited: false,
            pageName: '',
            commissionType: '',
            vehicleOwnerMapId: 0,
            vehicleId: 0,
            vehicleName: '',
            vehicleOwner: '',
        });
    }
    handleShowModal = async (pageName, commissionType, vehicleOwner, vehicleOwnerMapId, vehicleId, vehicleName) => {
        try {            
            this.setState({ isOpenModal: true, pageName: pageName, commissionType: commissionType, vehicleOwner: vehicleOwner, vehicleOwnerMapId: vehicleOwnerMapId, vehicleId: vehicleId, vehicleName: vehicleName })
            await this.getCommissionSettingList(commissionType, vehicleId, vehicleOwnerMapId);
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnEditorPreparing = (e) => {
        if (e.parentType == 'dataRow' && e.dataField == 'commission') {
            e.editorOptions.maxLength = 6;
        }
    }
    handleOnSaving = (e) => {
        let value = e.changes?.[0].data?.commission;
        if (Number(value) >= 100) {
            e.cancel = true;
            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                icon: 'error',
                title: '',
                text: 'Commission percent must have valid format!',
            })
        }
    }
    handleSubmit = async event => {
        event.preventDefault();
        try {
            if (this.isFormValid()) {
                this.setState({ isSubmited: true });
                //let changedCommission = this.state.commissionSettingGridDataSource.filter(cc => !this.state.commissionSettingGridDataSourceClone.some(co => cc.customerTypeId === co.customerTypeId && Number(cc.commission) === Number(co.commission)));
                var ccList = [];
                for (var i = 0; i < this.state.commissionSettingGridDataSource.length; i++) {
                    let cc = {};
                    cc.customerTypeId = Number(this.state.commissionSettingGridDataSource[i].customerTypeId);
                    cc.customerType = this.state.commissionSettingGridDataSource[i].customerType;
                    cc.commission = parseFloat(this.state.commissionSettingGridDataSource[i].commission);
                    ccList.push(cc);
                }
                var request = new Object();
                request.Commissions = new Object();
                request.Commissions = ccList;
                request.History = await this.setCommissionHistory();
                let apiUrl = null;
                if (this.state.commissionType.trim() === 'VC') {
                    apiUrl = 'vehicle-api/' + Number(this.state.vehicleId) + '/save-commission-setting';
                } else if (this.state.commissionType.trim() === 'VOC') {
                    apiUrl = 'vehicle-owner-api/' + Number(this.state.vehicleOwnerMapId) + '/save-commission-setting';
                }
                const requestParams = getRequestParams('POST', request);
                const response = await fetch(apiUrl, requestParams);
                const data = await response.json();
                if (response.ok) {
                    await Swal.fire({
                        allowOutsideClick: false,
                        icon: data.isSuccess ? 'success' : 'error',
                        title: data.isSuccess ? 'Success' : 'Oops...',
                        text: data.isSuccess ? this.state.pageName + ' updated successfully!' : 'Error! has occurred while updating ' + this.state.pageName + '.',
                    }).then(async (result) => {
                        if (data.isSuccess) {
                            if (result.isConfirmed) {
                                await this.handleCloseModal();
                            }
                        }
                    })
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Unable to process the request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmited: false })
    }
    isFormValid() {
        return true;
    }
    setCommissionHistory = async () => {
        let history = '';
        let commissionFor = '';
        if (this.state.commissionType.trim() === 'VC') {
            commissionFor = 'Vehicle';
        }
        else if (this.state.commissionType.trim() === 'VOC') {
            commissionFor = 'Vehicle owner';
        }

        try {
            let sep = ' ';
            //const objectToList = Object.keys(this.state.commissionSettingGridDataSourceClone).map(i => this.state.commissionSettingGridDataSourceClone[i])
            //let changedCommission = this.state.commissionSettingGridDataSource.filter(cc => !this.state.commissionSettingGridDataSourceClone.some(co => cc.customerTypeId === co.customerTypeId && Number(cc.commission) === Number(co.commission)));
            for (var i = 0; i < this.state.commissionSettingGridDataSource.length; i++) {
                let oldC = Number(this.state.commissionSettingGridDataSourceClone[i].commission);
                let newC = Number(this.state.commissionSettingGridDataSource[i].commission);
                if (oldC !== newC) {
                    if (history === '') {
                        history += commissionFor+' commission changed ' + this.state.commissionSettingGridDataSourceClone[i].customerType + ' : ' + String(oldC) + ' To ' + String(newC) + sep;
                    }
                    else {
                        history += ', ' + this.state.commissionSettingGridDataSourceClone[i].customerType + ' : ' + String(oldC) + ' To ' + String(newC) + sep;
                    }
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        history = history.trim() === '' ? commissionFor+' commission updated successfully.' : history;
        return history;
    };
    render() {
        const renderGridCell = (data) => {
            let cellColor = (data.data.commission > 0) ? 'blue' : 'yellowgreen';
            return <div style={{ color: cellColor }}>{data.data.commission}</div>;
        }
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={customStyles}
                    className={"react-modal"}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{this.state.pageName}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                                    <label>Owner <i><strong className="text-danger">{this.state.vehicleOwner}</strong></i></label>
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <label>Vehicle <i><strong className="text-danger">{this.state.vehicleName}</strong></i></label>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="widget-container">
                                        <DataGrid
                                            dataSource={this.state.commissionSettingGridDataSource}
                                            keyExpr="customerTypeId"
                                            selection={{ mode: 'single' }}
                                            columnsAutoWidth={true}
                                            showColumnLines={true}
                                            showRowLines={true}
                                            showBorders={true}
                                            rowAlternationEnabled={true}
                                            allowColumnResizing={true}
                                            columnResizingMode='widget'
                                            repaintChangesOnly={true}
                                            onEditorPreparing={this.handleOnEditorPreparing}
                                            onSaving={this.handleOnSaving}
                                        >
                                            <Editing
                                                mode="row"
                                                allowUpdating={true}
                                                selectTextOnEditStart={true}
                                                startEditAction={'click'}
                                            />
                                            <Column dataField="customerType" caption="Customer Type" allowEditing={false} allowFiltering={false} allowSorting={false} />
                                            <Column dataField="commission" caption="Commission%" dataType="float" alignment="right" width={125} allowEditing={true} allowUpdating={true} allowFiltering={false} allowSorting={false} cellRender={renderGridCell}>
                                                <RequiredRule />
                                                <PatternRule
                                                    message={'Commission must have valid format!'}
                                                    pattern={'^\\d{0,2}(\\.{0,1})\\d{0,3}$'}
                                                />
                                            </Column>
                                            <SortByGroupSummaryInfo summaryItem="count" />
                                            <GroupPanel visible={false} />
                                            <HeaderFilter visible={true} />
                                            <SearchPanel visible={false} highlightCaseSensitive={false} width={240} placeholder="Search..." />
                                            <Grouping autoExpandAll={true} />
                                            <Paging defaultPageSize={10} />
                                            <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                            <Export enabled={false} allowExportSelectedData={false} />
                                            <ColumnChooser enabled={false} />
                                            <ColumnFixing enabled={false} />
                                        </DataGrid>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" ref={button => this.submitButtonValidator = button} onClick={this.handleSubmit}>
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleCloseModal}><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}