import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import { SelectBox } from 'devextreme-react/select-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'

const searchByDropdown = [
    { id: 0, name: 'All Trips' },
    { id: 1, name: 'Name' },
    { id: 2, name: 'From' },
    { id: 3, name: 'To' },
];

export class AirlineRideActivityReportComponent extends Component {
    constructor() {
        super()
        this.state = {
            isSubmitted: false,
            isOpenModal: false,
            isEmailSubmitted: false,
            loadPanelVisible: false,
            rideDate: new Date(),
            airlineList: [],
            searchByList: searchByDropdown,
            searchBy: searchByDropdown[0],
            airlineId: 0,
            airlineCode: "",
            airlineName: "",
            searchByValue: "",
        }

        this.getAirlines = this.getAirlines.bind(this);

        this.handleRideDateChanged = this.handleRideDateChanged.bind(this);
        this.handleAirlineChange = this.handleAirlineChange.bind(this);
        this.handleSearchByChange = this.handleSearchByChange.bind(this);

        this.handleAirlineRideActivityReportSubmit = this.handleAirlineRideActivityReportSubmit.bind(this);
        this.downloadAirlineRideActivityAsPdf = this.downloadAirlineRideActivityAsPdf.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
    }
    async componentDidMount() {
        await this.getAirlines();
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    async getAirlines() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('air-line-api/' + 0, requestParams);
            const data = await response.json();

            this.setState({
                airlineList: data.airLine
            });
        } catch (e) {
            console.log(e);
        }
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ [name]: event.target.value });
    }
    handleRideDateChanged(event) {
        let rideDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ rideDate: rideDateValue })
    }
    handleAirlineChange(selectedOption) {        
        this.setState({ airlineId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.airLineId : 0), })
    }
    handleSearchByChange(selectedOption) {
        this.setState({ searchBy: (selectedOption.selectedItem != null ? selectedOption.selectedItem : []), })
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getCustomerList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isOpenModal: false,
                isEmailSubmitted: false,
                loadPanelVisible: false,
                rideDate: new Date(),
                searchBy: [],
                airlineId: 0,
                airlineCode: "",
                airlineName: "",
                searchByValue: "",
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleAirlineRideActivityReportSubmit(e) {
        e.preventDefault();
        this.downloadAirlineRideActivityAsPdf(e);
    }
    async downloadAirlineRideActivityAsPdf(e) {
        e.preventDefault();

        try {
            this.setState({
                loadPanelVisible: true,
                isSubmitted: true,
            });
            let selectionCriteria = "";
            switch (this.state.searchBy.id) {
                case 1:
                    selectionCriteria = "{PROC_RPT_M_SELECT_DISPATCH_RIDE_STATUS;1.PAX_NAME} ='" + this.state.searchByValue + "'";
                    break;
                case 2:
                    selectionCriteria = "{PROC_RPT_M_SELECT_DISPATCH_RIDE_STATUS;1.PU_ADDRESS} ='" + this.state.searchByValue + "'";
                    break;
                case 3:
                    selectionCriteria = "{PROC_RPT_M_SELECT_DISPATCH_RIDE_STATUS;1.DO_ADDRESS} ='" + this.state.searchByValue + "'";
                    break;
                default:
                    selectionCriteria = "";
                    break;
            }
            var request = new Object();
            request.fileFormat = 'pdf';
            request.MethodName = "GetAirlineRideActivity";
            request.AirlineId = this.state.airlineId;
            request.RideDate = formatDate(this.state.rideDate, "MM/dd/yyyy");
            request.SelectionCrieteria = selectionCriteria;

            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            this.setState({
                loadPanelVisible: false,
                isSubmitted: false,
            });
            let a = document.createElement('a');
            a.href = dataResponse.reportBase64String;
            a.download = 'AirlineRideActivity.pdf';
            a.click();
        } catch (e) {

        }
    }
    handleClearSearch() {
        this.setState({
            isSubmitted: false,
            isOpenModal: false,
            isEmailSubmitted: false,
            loadPanelVisible: false,
            rideDate: new Date(),
            searchBy: [],
            airlineId: 0,
            airlineCode: "",
            airlineName: "",
            searchByValue: "",
        });
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            this.setState({ isEmailSubmitted: true });
            let selectionCriteria = "";
            switch (this.state.searchBy.id) {
                case 1:
                    selectionCriteria = "{PROC_RPT_M_SELECT_DISPATCH_RIDE_STATUS;1.PAX_NAME} ='" + this.state.searchByValue + "'";
                    break;
                case 2:
                    selectionCriteria = "{PROC_RPT_M_SELECT_DISPATCH_RIDE_STATUS;1.PU_ADDRESS} ='" + this.state.searchByValue + "'";
                    break;
                case 3:
                    selectionCriteria = "{PROC_RPT_M_SELECT_DISPATCH_RIDE_STATUS;1.DO_ADDRESS} ='" + this.state.searchByValue + "'";
                    break;
                default:
                    selectionCriteria = "";
                    break;
            }
            var request = new Object();
            request.fileFormat = 'pdf';
            request.MethodName = "GetAirlineRideActivity";
            request.AirlineId = this.state.airlineId;
            request.RideDate = formatDate(this.state.rideDate, "MM/dd/yyyy");
            request.SelectionCrieteria = selectionCriteria;

            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();

            this.setState({ isEmailSubmitted: false });
            let fileData = dataResponse.reportBase64String;

            if (fileData !== null && fileData !== '') {
                let emailSubject = 'Airline Ride Activity';
                let attachments = [];
                let file = new Object();
                file.fileData = fileData;
                file.fileName = 'AirlineRideActivity.pdf'
                file.fileExtension = '.pdf';
                attachments.push(file);
                this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
            }
            else {
                Swal.fire({
                    allowOutsideClick: false,
                    showCloseButton: true,
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong! Unable to process the email request.',
                })
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '40%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleOnReportSubmit}
                        style={(this.state.isSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Airline Ride Activity'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label htmlFor="">Ride Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.rideDate}
                                            value={this.state.rideDate}
                                            onValueChanged={this.handleRideDateChanged}
                                            width="100%"
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                    <div className="form-group">
                                        <label className="form-check-label">Select Airline</label>
                                        <SelectBox
                                            dataSource={this.state.airlineList.length > 0 ? this.state.airlineList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select airline"
                                            name="airlineId"
                                            defaultValue={0}
                                            value={this.state.airlineId}
                                            displayExpr='airLineName'
                                            valueExpr='airLineId'
                                            onSelectionChanged={this.handleAirlineChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label className="form-check-label">Search By</label>
                                        <SelectBox
                                            dataSource={this.state.searchByList.length > 0 ? this.state.searchByList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select airline"
                                            name="airlineId"
                                            defaultValue={0}
                                            value={this.state.searchBy.id}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleSearchByChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label className="form-check-label">Search By</label>
                                        <input type="text" className="form-control form-control-sm" name="searchByValue" maxLength="50" value={this.state.searchByValue} onChange={this.handleInputChange} placeholder="Enter search by" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="submit" className="btn btn-success btn-sm" onClick={this.handleAirlineRideActivityReportSubmit} >
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                                </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>

        );
    }
}