import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import { LoadPanel } from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import { SelectBox } from 'devextreme-react/select-box';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions';
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'
export class AccountSummaryPaymentTypeWiseComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            accountPaymentTypeSummary:[],
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),
            isDropdownCustomerTypeDisable: true,
            customerTypeList: [],
            customerTypeId: 0,
            customerType: "",
            reportType: 0,
            reportTypeAll: true,
            reportTypeCustomerType: false,
            errors: {
                customerTypeId: 0,
            }
        }

        this.getCustomerTypeList = this.getCustomerTypeList.bind(this);

        this.handleReportTypeChange = this.handleReportTypeChange.bind(this);
        this.handleCustomerTypeChange = this.handleCustomerTypeChange.bind(this);
        this.handleFromDateChanged = this.handleFromDateChanged.bind(this);
        this.handleToDateChanged = this.handleToDateChanged.bind(this);
        this.downloadAccountPaymentTypeSummaryAsPdf = this.downloadAccountPaymentTypeSummaryAsPdf.bind(this);

        this.handleSearchAccountPaySummaryReportSubmit = this.handleSearchAccountPaySummaryReportSubmit.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true,
        });
        await this.getCustomerTypeList();
        this.setState({
            loadPanelVisible: false,
        });
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    async getCustomerTypeList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('customer-type-api/', requestParams);
        const data = await response.json();
        if (data !== null && data.customerType.length > 0) {
            this.setState({
                customerTypeList: data.customerType
            });
        }
    }
    handleReportTypeChange(e) {
        let reportTypeValue = parseInt(e.target.value);
        this.setState({
            reportType: reportTypeValue,
            reportTypeAll: false,
            reportTypeCustomerType: false,
            customerTypeId: 0,
            customerType: "",
        });

        switch (reportTypeValue) {
            case 0:
                this.setState({
                    reportTypeAll: true,
                    reportTypeCustomerType: false,
                    isDropdownCustomerTypeDisable: true,
                });
                break;
            case 1:
                this.setState({
                    reportTypeAll: false,
                    reportTypeCustomerType: true,
                    isDropdownCustomerTypeDisable: false,
                });
                break;
        }
    }
    handleCustomerTypeChange(e) {
        if (e.selectedItem != null) {
            this.setState({
                customerTypeId: e.selectedItem.id,
                customerType: e.selectedItem.name,
            });
        }
        else {
            this.setState({ customerTypeId: 0, customerType: "" });
        }
    }
    handleFromDateChanged(event) {
        let fromDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ startDate: fromDateValue })
    }
    handleToDateChanged(event) {
        let toDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ endDate: toDateValue })
    }
    handleSearchAccountPaySummaryReportSubmit(e) {
        e.preventDefault();        
        if (this.handleValidation()) {
            this.downloadAccountPaymentTypeSummaryAsPdf();
        }
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmited: false,
                isEmailSubmitted: false,
                isOpenModal: false,
                loadPanelVisible: false,
                accountPaymentTypeSummary: [],
                startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
                endDate: new Date(),
                isDropdownCustomerTypeDisable: true,
                customerTypeList: [],
                customerTypeId: 0,
                customerType: "",
                reportType: 0,
                reportTypeAll: true,
                reportTypeCustomerType: false,
                errors: {
                    customerTypeId: 0,
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleClearSearch() {
        this.setState({
            isSubmited: false,
            isEmailSubmitted: false,
            isOpenModal: true,
            loadPanelVisible: false,
            accountPaymentTypeSummary: [],
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),
            isDropdownCustomerTypeDisable: true,
            customerTypeList: [],
            customerTypeId: 0,
            customerType: "",
            reportType: 0,
            reportTypeAll: true,
            reportTypeCustomerType: false,
            errors: {
                customerTypeId: 0,
            }
        });
    }
    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;

        errors.customerTypeId = 0;

        this.setState({ message: '' });

        switch (this.state.reportType) {
            case 1:
                if (this.state.customerTypeId == 0) {
                    formIsValid = false;
                    errors.customerTypeId = "Please select customer type";
                }
                break;
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    async downloadAccountPaymentTypeSummaryAsPdf() {
        try {
            this.setState({
                loadPanelVisible: true,
                isSubmited: true,
            });

            var request = new Object();
            request.LocationId = Number(sessionStorage.getItem("session_locationId"));
            request.CustomerTypeId = this.state.customerTypeId;
            request.CustomerType = this.state.customerType;
            request.StartDate = formatDate(this.state.startDate,"MM/dd/yyyy");
            request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy");
            request.ReportType = this.state.reportTypeAll ? 1 : 2;
            request.fileFormat = 'pdf';
            request.MethodName = "GetAccountPaymentSummary";
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            this.setState({
                loadPanelVisible: false,
                isSubmited: false,
            });

            let a = document.createElement('a');
            a.href = dataResponse.reportBase64String;
            a.download = 'AccountPaymentSummary.pdf';
            a.click();
        } catch (e) {
            this.setState({
                loadPanelVisible: false,
            });
            console.log(e)
        }
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isEmailSubmitted: true });
                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.CustomerTypeId = this.state.customerTypeId;
                request.CustomerType = this.state.customerType;
                request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy");
                request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy");
                request.ReportType = this.state.reportTypeAll ? 1 : 2;
                request.fileFormat = 'pdf';
                request.MethodName = "GetAccountPaymentSummary";
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Account Summary Payment Type Wise';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = 'AccountPaymentSummary.pdf'
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
            <Modal ariaHideApp={false}
                isOpen={this.state.isOpenModal}
                style={{ content: { width: '43%' } }}
                className={"react-modal"}
                onAfterOpen={this.handleOnAfterOpenModal}
                onRequestClose={this.handleOnCloseModal}
                shouldCloseOnOverlayClick={false}
                contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleOnReportSubmit}
                        style={(this.state.isSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Account Summary Payment Type Wise'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>From Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.startDate}
                                            value={this.state.startDate}
                                            onValueChanged={this.handleFromDateChanged}
                                            width="100%"
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>To Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.endDate}
                                            value={this.state.endDate}
                                            onValueChanged={this.handleToDateChanged}
                                            width="100%"
                                            acceptCustomValue={false}
                                        />                                      
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radReportType" value="0" checked={this.state.reportTypeAll} onChange={this.handleReportTypeChange} />
                                            <label className="form-check-label">All Customer Type</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radReportType" value="1" checked={this.state.reportTypeCustomerType} onChange={this.handleReportTypeChange} />
                                            <label className="form-check-label">Single Customer Type</label>
                                        </span>
                                        <SelectBox
                                            dataSource={this.state.customerTypeList.length > 0 ? this.state.customerTypeList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer type"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.customerTypeId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleCustomerTypeChange}
                                            showClearButton={true}
                                            disabled={this.state.isDropdownCustomerTypeDisable}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerTypeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerTypeId"]}</span>}
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="submit" className="btn btn-success btn-sm" onClick={this.handleSearchAccountPaySummaryReportSubmit} >
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                                </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>)
    }
}