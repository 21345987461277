import React, { Component } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { SelectBox } from 'devextreme-react/select-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import { SendEmailComponent } from '../Shared/SendEmailComponent'

let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export const graphDataList = [{
    'id': 'D',
    'name': 'Daily',
}, {
    'id': 'W',
    'name': 'Weekly',
}, {
    'id': 'M',
    'name': 'Monthly',
}]
export class DispatcherLoginWiseProductivityReportComponent extends Component {
    constructor() {
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        super()
        this.state = {
            isSubmitted: false,
            isSearchSubmitted: false,
            isEmailSubmitted: false,
            loadPanelVisible: false,
            loginwiseGridDataSource: null,
            isDetailsReport: true,
            isSummaryReport: false,
            isGraphReport: false,
            graphDropdownList: [],
            graphDropdownData: null,
            graph: '',
            isAllUser: true,
            userDropdownList: [],
            userDropdownData: null,
            userId: 0,
            isAllDate: true,
            isDateRange: false,
            fromDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
            toDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
            errors: {
                reportType: '',
                graph: '',
                user: '',
                fromDate: '',
                toDate: ''
            }
        }
    }
    async componentDidMount() {
        this.setState({ loadPanelVisible: true });
        await this.getDispatcherDropdownList();
        await this.getGraphDropdownList();
        this.setState({ loadPanelVisible: false });
    }
    getDispatcherDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('report-api/dispatcher-dropdown', requestParams);
            const data = await response.json();
            this.setState({
                userDropdownList: data.dispatcherDropdownList
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    getGraphDropdownList = async () => {
        try {
            this.setState({ graphDropdownList: graphDataList });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleToolbarPreparing = (e) => {
        try {
            e.toolbarOptions.items.unshift({
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'exportpdf',
                    onClick: this.downloadAsPdf.bind(this),
                    hint: 'Export to Pdf'
                }
            });
            //e.toolbarOptions.items.unshift({
            //    location: 'after',
            //    widget: 'dxButton',
            //    options: {
            //        icon: 'email',
            //        onClick: this.emailReport.bind(this),
            //        hint: 'Email'
            //    }
            //});
        } catch (ex) {
            console.log(ex);
        }
    }
    emailReport = async () => {
        try {
            await Swal.fire({
                title: "<small>Pending Implementation</small>",
                text: "This feature is comming soon.",
                icon: "info",
                dangerMode: false,
                width: "25%",
                dangerMode: false,
                allowOutsideClick: false
            });
            return;
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnInputChange = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
            let errors = this.state.errors;
            switch (name) {
                case 'isDetailsReport':
                    errors.reportType = '';
                    errors.graph = '';
                    this.setState({ isDetailsReport: value, isSummaryReport: false, isGraphReport: false, graphDropdownData: null, graph: '' });
                    break;
                case 'isSummaryReport':
                    errors.reportType = '';
                    errors.graph = '';
                    this.setState({ isDetailsReport: false, isSummaryReport: value, isGraphReport: false, graphDropdownData: null, graph: '' });
                    break;
                case 'isGraphReport':
                    errors.reportType = '';
                    errors.graph = '';
                    this.setState({ isDetailsReport: false, isSummaryReport: false, isGraphReport: value, graphDropdownData: null, graph: '' });
                    break;
                case 'isAllUser':
                    errors.user = '';
                    this.setState({ isAllUser: value, userDropdownData: null, userId: 0 });
                    break;
                case 'isAllDate':
                    errors.fromDate = '';
                    errors.toDate = '';
                    this.setState({ isAllDate: value, isDateRange: false });
                    break;
                case 'isDateRange':
                    errors.fromDate = '';
                    errors.toDate = '';
                    this.setState({ isAllDate: false, isDateRange: value });
                    break;
                default:
                    break;
            }
            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnGraphDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.graph = '';
            let graph = '';
            if (data !== null) {
                graph = (data.selectedItem != null ? data.selectedItem.id : 0);
            }
            else {
                //errors.graph = 'Please select graph.';
            }
            this.setState({ graphDropdownData: data, graph: graph, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnUserDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.user = '';
            let userId = 0;
            if (data !== null) {
                userId = (data.selectedItem != null ? data.selectedItem.id : 0);
            }
            else {
                //errors.user = 'Please select user.';
            }
            this.setState({ userDropdownData: data, userId: userId, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnFromDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.fromDate = '';
            let fromDate = '';
            if (event.value !== null && event.value !== '') {
                fromDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.fromDate = 'Please select date.';
            }
            if (this.state.toDate.trim() !== '') {
                errors.toDate = '';
            }
            this.setState({ fromDate: fromDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnToDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.toDate = '';
            let toDate = '';
            if (event.value !== null && event.value !== '') {
                toDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.toDate = 'Please select date.';
            }
            if (this.state.fromDate.trim() !== '') {
                errors.fromDate = '';
            }
            this.setState({ toDate: toDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnSearchSubmit = async event => {
        event.preventDefault();
        try {
            this.setState({ isSearchSubmitted: true, loadPanelVisible: true });
            let isValid = true;
            let errors = this.state.errors;
            errors.user = '';
            errors.fromDate = '';
            errors.toDate = '';
            let fromDate = '';
            let toDate = '';
            if (this.state.isAllUser === false) {
                if (Number(this.state.userId) === 0) {
                    errors.user = 'Please select user.';
                    isValid = false;
                }
            }
            if (this.state.isDateRange === true) {
                if (this.state.fromDate.trim() === '') {
                    errors.fromDate = 'Please select date.';
                    isValid = false;
                }
                else {
                    fromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00';
                }
                if (this.state.toDate.trim() === '') {
                    errors.toDate = 'Please select date.';
                    isValid = false;
                }
                else {
                    toDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59'
                }
                if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                    if (moment(this.state.fromDate, 'MM/DD/YYYY').isAfter(this.state.toDate, 'MM/DD/YYYY')) {
                        errors.fromDate = 'Please enter a valid date range.';
                        errors.toDate = 'Please enter a valid date range.';
                        isValid = false;
                    }
                }
            }
            this.setState({ errors: errors });
            if (isValid) {
                let request = new Object();
                request.DispatcherId = Number(this.state.userId);
                request.FromDate = fromDate;
                request.ToDate = toDate;
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('report-api/dispatcher-productivity', requestParams);
                const data = await response.json();
                this.setState({
                    loginwiseGridDataSource: data.dispatcherLoginWiseProductivityList
                });
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSearchSubmitted: false, loadPanelVisible: false });
    }
    handleOnClearSearch = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isSearchSubmitted: false,
                isEmailSubmitted: false,
                loadPanelVisible: false,
                loginwiseGridDataSource: null,
                isDetailsReport: true,
                isSummaryReport: false,
                isGraphReport: false,
                graphDropdownList: [],
                graphDropdownData: null,
                graph: '',
                isAllUser: true,
                userDropdownList: [],
                userDropdownData: null,
                userId: 0,
                isAllDate: true,
                isDateRange: false,
                fromDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
                toDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
                errors: {
                    reportType: '',
                    graph: '',
                    user: '',
                    fromDate: '',
                    toDate: ''
                }
            });
        } catch (ex) {
            console.log();
        }
    }
    handleOnReportSubmit = async (event) => {
        event.preventDefault();
        try {
            this.setState({ isSubmitted: true });
            await this.downloadAsPdf();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false });
    }
    downloadAsPdf = async () => {
        try {
            if (await this.isFormValid()) {
                let reportType = 0;
                let fromDate = '';
                let toDate = '';
                if (this.state.isDetailsReport === true) {
                    reportType = 1;
                }
                if (this.state.isSummaryReport === true) {
                    reportType = 2;
                }
                if (this.state.isGraphReport === true) {
                    reportType = 3;
                }
                if (this.state.isDateRange === true) {
                    if (this.state.fromDate.trim() !== '') {
                        fromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00'
                    }
                    if (this.state.toDate.trim() !== '') {
                        toDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59'
                    }
                }
                let reportHeader = await this.setSelectionCrieteria();
                await this.downloadReport(Number(this.state.userId), fromDate, toDate, Number(reportType), reportHeader, 'pdf');
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    downloadReport = async (userId, fromDate, toDate, reportType, reportHeader, fileFormat) => {
        try {
            let reportTypeFileName = '';
            var request = new Object();
            request.LocationId = Number(sessionStorage.getItem("session_locationId"));
            request.ReportType = Number(reportType);
            if (reportType === 1) {
                reportTypeFileName = 'Details';
            }
            if (reportType === 2) {
                reportTypeFileName = 'Summary';
            }
            if (reportType === 3) {
                reportTypeFileName = 'Graph';
                request.GraphType = this.state.graph.trim();
            }
            request.UserId = Number(userId);
            request.StartDate = fromDate;
            request.EndDate = toDate;
            request.SelectionCrieteria = reportHeader;
            request.fileFormat = fileFormat;
            request.MethodName = "GetDispatcherLoginWiseProductivityReport";
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            let a = document.createElement('a');
            a.href = dataResponse.reportBase64String;
            a.download = 'DispatcherProductivity' + reportTypeFileName + '.' + fileFormat;
            a.click();
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnDrownloadReportFromGrid = async (e, data, fileFormat) => {
        e.preventDefault();
        try {
            if (data !== null) {
                let userId = Number(data.id);
                let userName = data.name === null ? '' : data.name;
                let fromDate = (data.loginDateTime === null || data.loginDateTime === '') ? '' : moment(data.loginDateTime, 'MM/DD/YYYY HH:mm').format('YYYY-MM-DD HH:mm');
                let toDate = (data.logoutDateTime === null || data.logoutDateTime === '') ? '' : moment(data.logoutDateTime, 'MM/DD/YYYY HH:mm').format('YYYY-MM-DD HH:mm');
                let reportHeader = 'Dispatcher loginwise productivity for';
                if (userName !== '') {
                    reportHeader += ' user - ' + userName;
                }
                if (fromDate.trim() !== '' && toDate.trim() !== '') {
                    reportHeader += ', date from ' + fromDate + ' to ' + toDate;
                }
                else {
                    reportHeader += ', all date';
                }
                await this.downloadReport(userId, fromDate, toDate, 1, reportHeader, fileFormat);
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    isFormValid = async () => {
        let isValid = true;
        try {
            let errors = this.state.errors;
            errors.reportType = '';
            errors.graph = '';
            errors.user = '';
            errors.fromDate = '';
            errors.toDate = '';
            if (this.state.isAllUser === false) {
                if (Number(this.state.userId) === 0) {
                    errors.user = 'Please select user.';
                    isValid = false;
                }
            }
            if (this.state.isDateRange === true) {
                if (this.state.fromDate.trim() === '') {
                    errors.fromDate = 'Please select date.';
                    isValid = false;
                }
                if (this.state.toDate.trim() === '') {
                    errors.toDate = 'Please select date.';
                    isValid = false;
                }
                if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                    if (moment(this.state.fromDate, 'MM/DD/YYYY').isAfter(this.state.toDate, 'MM/DD/YYYY')) {
                        errors.fromDate = 'Please enter a valid date range.';
                        errors.toDate = 'Please enter a valid date range.';
                        isValid = false;
                    }
                }
            }
            if (this.state.isDetailsReport === false && this.state.isSummaryReport === false && this.state.isGraphReport === false) {
                errors.reportType = 'Please select report type.';
                isValid = false;
            }
            if (this.state.isGraphReport === true) {
                if (this.state.graph.trim() === '') {
                    errors.graph = 'Please select graph.';
                    isValid = false;
                }
            }
            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    setSelectionCrieteria = async () => {
        let selectionCrieteria = 'Dispatcher loginwise productivity for';
        try {
            if (Number(this.state.userId) > 0) {
                if (this.state.userDropdownData !== null && Object.keys(this.state.userDropdownData).length > 0) {
                    selectionCrieteria += ' user - ' + this.state.userDropdownData.name;
                }
            }
            else {
                selectionCrieteria += ' all users';
            }
            if (this.state.isDateRange === true) {
                if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                    selectionCrieteria += ', date from ' + this.state.fromDate + ' to ' + this.state.toDate;
                }
            }
            else {
                selectionCrieteria += ', all date';
            }
            if (this.state.isGraphReport === true) {
                if (this.state.graph.trim() !== '') {
                    selectionCrieteria += ', graph - ' + this.state.graph;
                }
            }
        } catch (ex) {
            selectionCrieteria = '';
            console.log(ex);
        }
        return selectionCrieteria;
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isEmailSubmitted: true });
                let reportType = 0;
                let fromDate = '';
                let toDate = '';
                if (this.state.isDetailsReport === true) {
                    reportType = 1;
                }
                if (this.state.isSummaryReport === true) {
                    reportType = 2;
                }
                if (this.state.isGraphReport === true) {
                    reportType = 3;
                }
                if (this.state.isDateRange === true) {
                    if (this.state.fromDate.trim() !== '') {
                        fromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00'
                    }
                    if (this.state.toDate.trim() !== '') {
                        toDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59'
                    }
                }

                let reportTypeFileName = '';
                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.ReportType = Number(reportType);
                if (reportType === 1) {
                    reportTypeFileName = 'Details';
                }
                if (reportType === 2) {
                    reportTypeFileName = 'Summary';
                }
                if (reportType === 3) {
                    reportTypeFileName = 'Graph';
                    request.GraphType = this.state.graph.trim();
                }
                request.UserId = Number(this.state.userId);
                request.StartDate = fromDate;
                request.EndDate = toDate;
                request.SelectionCrieteria = await this.setSelectionCrieteria();;
                request.fileFormat = 'pdf';
                request.MethodName = "GetDispatcherLoginWiseProductivityReport";
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Vehicle Job History';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = 'DispatcherProductivity' + reportTypeFileName + '.' + '.pdf';
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        const renderGridCell = (data) => {
            return <div>
                <a href="#" title="View in PDF" onClick={(e) => { this.handleOnDrownloadReportFromGrid(e, data.data, 'pdf') }} >
                    <i className="fas fa-file-pdf"></i>
                </a>
                <a href="#" title="View in Excel" style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleOnDrownloadReportFromGrid(e, data.data, 'xlsx') }} >
                    <i className="fas fa-file-excel"></i>
                </a>
            </div>;
        }
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-sm-6 col-md-6 col-lg-6">
                                <h1 className="m-0 text-dark">Dispatcher Loginwise Productivity</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem> <NavLink tag={Link} to="/report" className="btn btn-block btn-outline-secondary btn-sm" title="Reports" ><i className="fas fa-arrow-left"></i> Back</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <form method="post" onSubmit={this.handleOnReportSubmit}
                    style={(this.state.isSubmitted || this.state.isSearchSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="rez-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                name="isAllUser"
                                                checked={this.state.isAllUser}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isAllUser"> All User</label>
                                        </div>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder="Select User"*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.userDropdownData}*/}
                                        {/*    name="dduser"*/}
                                        {/*    onChange={this.handleOnUserDropdownChange}*/}
                                        {/*    getOptionLabel={option => option.name}*/}
                                        {/*    getOptionValue={option => option.id}*/}
                                        {/*    options={this.state.userDropdownList}*/}
                                        {/*    isMulti={false}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*    isDisabled={this.state.isAllUser}*/}
                                        {/*/>*/}

                                        <SelectBox
                                            dataSource={this.state.userDropdownList.length > 0 ? this.state.userDropdownList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select user"
                                            name="dduser"
                                            defaultValue={[]}
                                            value={this.state.userDropdownData}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnUserDropdownChange}
                                            showClearButton={true}
                                            disabled={this.state.isAllUser}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["user"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["user"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">&nbsp;</label>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isAllDate"
                                                checked={this.state.isAllDate}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isAllDate"> All Date (Login)</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isDateRange"
                                                checked={this.state.isDateRange}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isDateRange"> In Range</label>
                                        </div>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="fromDate"
                                            placeholder="MM/dd/yyyy"
                                            defaultValue={this.state.fromDate}
                                            value={this.state.fromDate}
                                            onValueChanged={this.handleOnFromDateChange}
                                            width={'100%'}
                                            disabled={this.state.isAllDate}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["fromDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["fromDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor=""><br /></label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="toDate"
                                            placeholder="MM/dd/yyyy"
                                            defaultValue={this.state.toDate}
                                            value={this.state.toDate}
                                            min={this.state.fromDate}
                                            onValueChanged={this.handleOnToDateChange}
                                            width={'100%'}
                                            disabled={this.state.isAllDate}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["toDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["toDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Report Type</label><br />
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isDetailsReport"
                                                checked={this.state.isDetailsReport}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isDetailsReport">  Details</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isSummaryReport"
                                                checked={this.state.isSummaryReport}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isSummaryReport"> Summary</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isGraphReport"
                                                checked={this.state.isGraphReport}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isGraphReport"> Graph</label>
                                        </span>
                                        {this.state.errors["reportType"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["reportType"]}</span>}
                                        {/* <div style={{ width: '62%' }}>
                                            <Select
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select graph"
                                                defaultValue={[]}
                                                value={this.state.graphDropdownData}
                                                name="ddgraph"
                                                onChange={this.handleOnGraphDropdownChange}
                                                getOptionLabel={option => option.name}
                                                getOptionValue={option => option.id}
                                                options={this.state.graphDropdownList}
                                                isMulti={false}
                                                isClearable={true}
                                                isSearchable={true}
                                                isDisabled={(!this.state.isGraphReport)}
                                            />
                                        </div>
                                        {this.state.errors["graph"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["graph"]}</span>}
                                            */}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>Select Graph</label>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder="Select graph"*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.graphDropdownData}*/}
                                        {/*    name="ddgraph"*/}
                                        {/*    onChange={this.handleOnGraphDropdownChange}*/}
                                        {/*    getOptionLabel={option => option.name}*/}
                                        {/*    getOptionValue={option => option.id}*/}
                                        {/*    options={this.state.graphDropdownList}*/}
                                        {/*    isMulti={false}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*    isDisabled={(!this.state.isGraphReport)}*/}
                                        {/*/>*/}

                                        <SelectBox
                                            dataSource={this.state.graphDropdownList.length > 0 ? this.state.graphDropdownList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select graph"
                                            name="ddgraph"
                                            defaultValue={[]}
                                            value={this.state.graphDropdownData}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnGraphDropdownChange}
                                            showClearButton={true}
                                            disabled={(!this.state.isGraphReport)}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["graph"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["graph"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12" >
                                    <div className="form-group">
                                        <label className="btn-block">&nbsp;</label>
                                        <button className="btn btn-success btn-sm" title="Search" onClick={this.handleOnSearchSubmit}>
                                            {this.state.isSearchSubmitted && <span><i className="fas fa-spinner fa-spin"></i></span>}
                                            {!this.state.isSearchSubmitted && <span><i className="fas fa-search"></i></span>}
                                        </button>&nbsp;&nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" title="Clear" onClick={this.handleOnClearSearch}>
                                            <i className="fas fa-eraser"></i>
                                        </button>&nbsp;&nbsp;
                                        <button type="submit" className="btn btn-custom-gray btn-sm">
                                            {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i></span>}
                                            {!this.state.isSubmitted && <span title="Download"><i className="fas fa-file-download"></i></span>}
                                        </button>&nbsp;&nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleOnSendEmail} >
                                            {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i></span>}
                                            {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i></span>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="content">
                    <div className="card">
                        <div className="card-body">
                            <DataGrid
                                dataSource={this.state.loginwiseGridDataSource}
                                keyExpr="id"
                                columnsAutoWidth={true}
                                showColumnLines={false}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                showBorders={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                width='100%'
                                onToolbarPreparing={this.handleToolbarPreparing}>
                                <Column dataField="id" width={40} caption="" fixed={true} allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                <Column dataField="name" caption="User" />
                                <Column dataField="loginDateTime" caption="Login Time" />
                                <Column dataField="logoutDateTime" caption="Logout Time" />
                                <Column dataField="resDispatched" caption="Dispatched Rides" alignment='center' />
                                <Column dataField="workHours" caption="Work (Hrs.)" alignment='center' />
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={false} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                <Export enabled={true} allowExportSelectedData={false} />
                                <ColumnChooser enabled={false} />
                                <ColumnFixing enabled={false} />
                            </DataGrid>
                        </div>
                    </div>
                </div>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        );
    }
}