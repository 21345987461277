import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal

const customStyles = {
    content: {
        width: '85%'
    }
};

export class ViewReservationComponent extends Component {
    static displayName = ViewReservationComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
        }

        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    closeModal() {
        this.setState({
            isOpenModal: false,
        });
    }

    showModal(data) {
        this.setState({ isOpenModal: true })
    }

    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={customStyles}
                    className={"react-modal view-rez-modal"}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Ride ID: <span>4152145236</span></h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-3">
                                    <div className="row form-group">
                                        <div className="col-5"><label>Customer Name:</label></div>
                                        <div className="col-7"><span>John D'Silva</span></div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="row form-group">
                                        <div className="col-5"><label>Customer Phone:</label></div>
                                        <div className="col-7"><span>+1 230-230-2145</span></div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="row form-group">
                                        <div className="col-6"><label>Customer Mobile:</label></div>
                                        <div className="col-6"><span>+1 230-230-2145</span></div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="row form-group">
                                        <div className="col-5"><label>Customer Email:</label></div>
                                        <div className="col-7"><span>test@test.com</span></div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="row form-group">
                                        <div className="col-5"><label>Rez Status:</label></div>
                                        <div className="col-7"><span>On Hold</span></div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="row form-group">
                                        <div className="col-5"><label>Booked On:</label></div>
                                        <div className="col-7"><span>10/21/2020 · 06:58 PM</span></div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="row form-group">
                                        <div className="col-6"><label>Payment Status:</label></div>
                                        <div className="col-6"><span>Not Paid</span></div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="row">
                                        <div className="col-5"><label>Amount:</label></div>
                                        <div className="col-7"><strong className="text-success">$1200.00</strong></div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="card card-primary card-outline card-outline-tabs">
                                <div className="card-header p-0 border-bottom-0">
                                    <ul className="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" id="custom-tabs-three-Itinerary-tab" data-toggle="pill" href="#custom-tabs-three-itinerary" role="tab" aria-controls="custom-tabs-three-itinerary" aria-selected="true">Itinerary</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="custom-tabs-three-fare-tab" data-toggle="pill" href="#custom-tabs-three-fare" role="tab" aria-controls="custom-tabs-three-fare" aria-selected="false">Fare</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="custom-tabs-three-payment-tab" data-toggle="pill" href="#custom-tabs-three-payment" role="tab" aria-controls="custom-tabs-three-payment" aria-selected="false">Payment</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="custom-tabs-three-notes-tab" data-toggle="pill" href="#custom-tabs-three-notes" role="tab" aria-controls="custom-tabs-three-notes" aria-selected="false">Notes</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="custom-tabs-three-history-tab" data-toggle="pill" href="#custom-tabs-three-history" role="tab" aria-controls="custom-tabs-three-history" aria-selected="false">History</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content" id="custom-tabs-three-tabContent">
                                        <div className="tab-pane fade active show" id="custom-tabs-three-itinerary" role="tabpanel" aria-labelledby="custom-tabs-three-Itinerary-tab">
                                            <div className="row">
                                                <div className="col-6">
                                                    <table className="table table-hover view-rez-table">
                                                        <thead>
                                                            <tr>
                                                                <th>Type</th>
                                                                <th>Value</th>
                                                                <th>Status</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Pickup Location</td>
                                                                <td><i className="fas fa-plane"></i> John F. Kennedy International Airport</td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Pickup Date & Time</td>
                                                                <td><i className="fas fa-clock"></i> 11/26/2020 03:30</td>
                                                                <td><span className="badge badge-success">Today</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Airport Code</td>
                                                                <td>JFK</td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Airline Code</td>
                                                                <td>AA</td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Flight Number</td>
                                                                <td>DL 1234</td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Terminal</td>
                                                                <td>T1</td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Flight Time</td>
                                                                <td><i className="fas fa-plane-arrival"></i> 12:15 PM</td>
                                                                <td><span className="badge badge-danger">Arrived</span></td>
                                                            </tr>

                                                            <tr>
                                                                <td>Drop Off Location</td>
                                                                <td><i className="fas fa-building"></i> 6333 N Scottsdale Rd Scottsdale, AZ 85250, USA</td>
                                                                <td></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="col-6">
                                                    <table className="table table-hover view-rez-table">
                                                        <thead>
                                                            <tr>
                                                                <th>Type</th>
                                                                <th>Value</th>
                                                                <th>Status</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            <tr>
                                                                <td>Vehicle Type</td>
                                                                <td><i className="fas fa-car"></i> Sedan (MH12345)</td>
                                                                <td><span className="badge badge-warning">EnRoute</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Total Pax</td>
                                                                <td><i className="fas fa-users"></i> 2 Adults +  <i className="fas fa-child"></i> 2 Child</td>
                                                                <td></td>

                                                            </tr>
                                                            <tr>
                                                                <td>Luggage</td>
                                                                <td><i className="fas fa-suitcase-rolling"></i> 3</td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Service(s)</td>
                                                                <td><i className="fas fa-wheelchair"></i> Accessible&nbsp;&nbsp;&nbsp;&nbsp;<i className="fas fa-star"></i> VIP Trip&nbsp;&nbsp;&nbsp;&nbsp;<i className="fas fa-check"></i> Confirmed</td>
                                                                <td><span className="badge badge-success">Yes</span></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="tab-pane fade" id="custom-tabs-three-fare" role="tabpanel" aria-labelledby="custom-tabs-three-fare-tab">
                                            <div className="row">
                                                <div className="col-3">
                                                    <table className="table table-hover view-rez-table">
                                                        <thead>
                                                            <tr>
                                                                <th>Type</th>
                                                                <th className="text-right">Amount</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Ride Amount</td>
                                                                <td className="text-right">$120.00</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Toll Amount</td>
                                                                <td className="text-right">$120.00</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Service Fee</td>
                                                                <td className="text-right">$120.00</td>
                                                            </tr>
                                                            <tr>
                                                                <td>OT Charge</td>
                                                                <td className="text-right">$120.00</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Parking</td>
                                                                <td className="text-right">$120.00</td>
                                                            </tr>

                                                            <tr>
                                                                <td>Pay to Greeter</td>
                                                                <td className="text-right">$120.00</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Waiting Time</td>
                                                                <td className="text-right">$120.00</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Waiting Charge</td>
                                                                <td className="text-right">$120.00</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Waiting Charge Total</td>
                                                                <td className="text-right">$120.00</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="col-3">
                                                    <table className="table table-hover view-rez-table">
                                                        <thead>
                                                            <tr>
                                                                <th>Type</th>
                                                                <th className="text-right">Amount</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            <tr>
                                                                <td>Extra Amount</td>
                                                                <td className="text-right">$120.00</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Extra Stop Charge</td>
                                                                <td className="text-right">$120.00</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Extra Amount For</td>
                                                                <td className="text-right">See notes</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Extra Stops</td>
                                                                <td className="text-right">$120.00</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Stop Total</td>
                                                                <td className="text-right">$120.00</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Fuel Surcharge</td>
                                                                <td className="text-right">$120.00</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Tour Guide Charge</td>
                                                                <td className="text-right">$120.00</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Pay to Tour Guide</td>
                                                                <td className="text-right">$120.00</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div className="col-3">
                                                    <table className="table table-hover view-rez-table">
                                                        <thead>
                                                            <tr>
                                                                <th>Tax Type</th>
                                                                <th className="text-right">Amount</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Port Authority</td>
                                                                <td className="text-right">$120.00</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Utility</td>
                                                                <td className="text-right">$120.00</td>
                                                            </tr>
                                                            <tr>
                                                                <td>County Tax</td>
                                                                <td className="text-right">$120.00</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Congestion Tax</td>
                                                                <td className="text-right">$120.00</td>
                                                            </tr>
                                                            <tr>
                                                                <td>WC</td>
                                                                <td className="text-right">$120.00</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Sales Tax</td>
                                                                <td className="text-right">$120.00</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="col-3">
                                                    <table className="table table-hover view-rez-table">
                                                        <thead>
                                                            <tr>
                                                                <th>Type</th>
                                                                <th className="text-right">Amount</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Grand Total</td>
                                                                <td className="text-right text-info"><strong>$120.00</strong></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Discount</td>
                                                                <td className="text-right text-danger"><strong>$120.00</strong></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Total Fare</td>
                                                                <td className="text-right text-success"><strong>$120.00</strong></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="tab-pane fade" id="custom-tabs-three-payment" role="tabpanel" aria-labelledby="custom-tabs-three-payment-tab">
                                            <div className="row">
                                                <div className="col-6">
                                                    <table className="table table-hover view-rez-table">
                                                        <thead>
                                                            <tr>
                                                                <th>Type</th>
                                                                <th>Value</th>
                                                                <th>Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Payment Method</td>
                                                                <td><i className="fas fa-credit-card"></i> Credit Card</td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Payment Status</td>
                                                                <td>Partial</td>
                                                                <td><span className="badge badge-success">Paid</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Payment Terms</td>
                                                                <td>Payment in advance</td>
                                                                <td><span className="badge badge-success">PIA</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Extra Wait Time</td>
                                                                <td><i className="fas fa-clock"></i> 10min</td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Extra Stop(s)</td>
                                                                <td><i className="fas fa-stop-circle"></i> 1</td>
                                                                <td></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="col-6">
                                                    <table className="table table-hover view-rez-table">
                                                        <thead>
                                                            <tr>
                                                                <th>Type</th>
                                                                <th>Value</th>
                                                                <th>Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>New/Existing Card</td>
                                                                <td><i className="fas fa-plus"></i> New Card</td>
                                                                <td><span className="badge badge-success">Added</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Card Number</td>
                                                                <td><i className="fab fa-cc-visa"></i> xxxx-xx12</td>
                                                                <td><span className="badge badge-success">Verified</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Expiry</td>
                                                                <td><i className="fas fa-calendar-alt"></i> 12/2020</td>
                                                                <td><span className="badge badge-danger">Expired</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Address</td>
                                                                <td><i className="fas fa-map-marker-alt"></i> Scottsdale, AZ 85250, USA</td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Name on Card</td>
                                                                <td><i className="fas fa-user"></i> JOHN D'SILVA</td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Charge To</td>
                                                                <td><i className="fas fa-user-tie"></i> Travel Agent</td>
                                                                <td><span className="badge badge-success">Today</span></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="custom-tabs-three-notes" role="tabpanel" aria-labelledby="custom-tabs-three-notes-tab">
                                            <div className="row">
                                                <div className="col-6">
                                                    <strong><i className="fas fa-suitcase-rolling"></i> Trip</strong>
                                                    <p>Duis ex nibh, dictum vel volutpat vel, tristique ut ante. Curabitur fermentum sed nisi ac congue. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tincidunt interdum sollicitudin. Ut fringilla tellus id ex congue ultrices. Quisque mauris diam, pharetra eget finibus in, vulputate vel sem. Aliquam tincidunt tortor ac mi interdum hendrerit. Donec convallis nisi sed sapien gravida, a faucibus felis pulvinar. Donec nec leo tellus. Aenean sit amet mollis nulla.</p>
                                                </div>
                                                <div className="col-6">
                                                    <strong><i className="fas fa-car-side"></i> Dispatch</strong>
                                                    <p>Duis ex nibh, dictum vel volutpat vel, tristique ut ante. Curabitur fermentum sed nisi ac congue. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tincidunt interdum sollicitudin. Ut fringilla tellus id ex congue ultrices. Quisque mauris diam, pharetra eget finibus in, vulputate vel sem. Aliquam tincidunt tortor ac mi interdum hendrerit. Donec convallis nisi sed sapien gravida, a faucibus felis pulvinar. Donec nec leo tellus. Aenean sit amet mollis nulla.</p>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="tab-pane fade" id="custom-tabs-three-history" role="tabpanel" aria-labelledby="custom-tabs-three-history-tab">
                                            <table className="table table-hover view-rez-table">
                                                <thead>
                                                    <tr>
                                                        <th>Reservation #</th>
                                                        <th>Description</th>
                                                        <th>Changes Made On</th>
                                                        <th>Changes Made By</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>44177004981</td>
                                                        <td>Reservation Made -[12/14/2020 00:19] Through UTLite.</td>
                                                        <td>12/14/2020 00:19</td>
                                                        <td>UTDemo</td>
                                                    </tr>
                                                    <tr>
                                                        <td>44177004981</td>
                                                        <td>Reservation Made -[12/14/2020 00:19] Through UTLite.</td>
                                                        <td>12/14/2020 00:19</td>
                                                        <td>UTDemo</td>
                                                    </tr>
                                                    <tr>
                                                        <td>44177004981</td>
                                                        <td>Reservation Made -[12/14/2020 00:19] Through UTLite.</td>
                                                        <td>12/14/2020 00:19</td>
                                                        <td>UTDemo</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer text-center">
                                <button type="button" className="btn btn-danger btn-sm"><i className="fas fa-copy"></i> Copy Reservation</button>
                                <button type="button" className="btn btn-danger btn-sm"><i className="fas fa-pen"></i> Edit Reservation</button>
                                <button type="button" className="btn btn-danger btn-sm"><i className="fas fa-envelope"></i> Email/Fax</button>
                                <button type="button" className="btn btn-danger btn-sm"><i className="fas fa-eraser"></i> Void</button>
                                <button type="button" className="btn btn-danger btn-sm"><i className="fas fa-times"></i> Close</button>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-danger btn-sm" >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-danger btn-sm" onClick={this.closeModal} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
            </div>
        );
    }
}