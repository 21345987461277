import React, { Component } from 'react';
import DataGrid, {
    Column,
    Editing,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
    RequiredRule,
    PatternRule,
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { SelectBox } from 'devextreme-react/select-box';
import DateBox from 'devextreme-react/date-box';
import Swal from 'sweetalert2';
import { LoadPanel } from 'devextreme-react/load-panel';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import CheckboxTree from 'react-checkbox-tree';// npm install react-checkbox-tree --save
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import 'react-tabs/style/react-tabs.css';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import Modal from 'react-modal'; //npm install --save react-modal
import { VehiclePayLogHistoryComponent } from './VehiclePayLogHistoryComponent';
import { VehicleDeductionChargeComponent } from '../Shared/VehicleDeductionChargeComponent';
export class VehiclePaymentComponent extends Component {
    constructor() {
        super()
        this.state = {
            loadPanelVisible: false,
            isRefreshSubmited: false,
            isViewCheckSubmited: false,
            isVehicleReportSubmited: false,
            isHistorySubmited: false,
            isUpdateNoteSubmited: false,
            isSumbitDeletePayAddon: false,
            isSubmitDeletePayDeduction: false,
            isSubmitDeletePayCredit: false,
            dropdownFranchiseeList: [],
            dropdownPayrollPeriodList: [],
            vehicleTreeViewList: [],
            vehicleNode: [],
            dropdownFranchiseeId: 0,
            dropdownPayPeriodDate: null,
            filterVehicleTree: '',
            selectedTreeVehicle: [],
            vehicleId: 0,
            vehicleOwnerId: 0,
            vehicleOwnerMapId: 0,
            vehicleNumber: '',
            vehicleOwnerName: '',
            vehicleOwnerType: '',
            vehicleWefDate: '',
            payId: 0,
            isPaid: false,
            regularAmount: '',
            balanceForward: '',
            note: '',
            chequeNumber: '',
            chequeDate: '',
            isViewReadOnly: true,
            vehiclePayPaymentData: [],
            smryRideAmount: '',
            smryDeductionAmount: '',
            smryAddonAmount: '',
            smryCreditAmount: '',
            smryTotalAmount: '',
            addonGridDataSource: null,
            deductionGridDataSource: null,
            creditGridDataSource: null,
            isOpenAddonModal: false,
            pendingPayAddonGridDataSource: null,
            isOpenDeductionModal: false,
            pendingPayDeductionGridDataSource: null,
            isOpenCreditModal: false,
            pendingPayCreditGridDataSource: null,
            isCreditCurrentDeductionPayroll: false,
            pcPayrollDate: null,
            pcDeductionName: '',
            pcDeductionAmount: 0,
            pcCreditAmount: '',
            pcNote: '',
            pcRecoverable: false,
            pcNonRcoverable: false,
            pcDeductionPayId: 0,
            pcDeductionId: 0,
            pcVehicleDeductionId: 0,
            pcRecoverVehicleDeductionId: 0,
            pcVehicleDeductionType: '',
            errors: {
                note: '',
                dropdownPayPeriodDate: '',
                pcPayrollDate: '',
                pcDeductionName: '',
                pcDeductionAmount: '',
                pcCreditAmount: '',
                pcRecoveryOption: '',
            },
            sortTypeValue: 0,
            filterActiveVehicle: false,
            filterInActiveVehicle: false,
            deletedPayAddonId: 0,
            deletedPayDeductionId: 0,
            deletedPayCreditId: 0,
        }
    }
    componentDidMount() {
        this.fetchApiData();
    }
    getFranchiseeDropdownList = async () => {
        try {
            //const requestParams = getRequestParams('GET');
            //const response = await fetch('vehicle-owner-api/owner-dropdown-list', requestParams);
            //if (response.ok) {
            //    const data = await response.json();
            //    this.setState({
            //        dropdownFranchiseeList: data.owners
            //    });
            //}
            var request = new Object();
            request.FranchiseeId = 0;
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('vehicle-payment-api/treeview/', requestParams);
            if (response.ok) {
                const data = await response.json();
                if (data.isSuccess) {
                    if (data.vehicleOwnerPaymentTreeView !== null) {
                        if (data.vehicleOwnerPaymentTreeView.length > 0) {
                            let franchisheeDropdownList = [];
                            data.vehicleOwnerPaymentTreeView.forEach((item) => {
                                let data = new Object();
                                data.id = item.vehicleOwnerId;
                                data.name = item.vehicleOwnerName;
                                let exist = franchisheeDropdownList.filter(f => f.id === data.id);
                                if (exist.length === 0) {
                                    franchisheeDropdownList.push(data);
                                }
                            });
                            this.setState({
                                dropdownFranchiseeList: franchisheeDropdownList
                            });
                        }
                    }
                }
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    getPayrollPeriodDropdownList = async () => {
        try {
            var request = new Object();
            request.VehicleId = 0;
            request.VehicleOwnerId = 0;
            request.PayDate = '';
            request.ChequeNo = '';
            request.IsTransactionType = true;
            request.PayrollType = 1;
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('vehicle-payment-api/payroll-pedriod-dropdown/', requestParams);
            const data = await response.json();
            this.setState({
                dropdownPayrollPeriodList: data.vehiclePayRollPeriods
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    getVehicleTreeView = async (franchiseeId) => {
        try {
            this.setState({ isViewReadOnly: true });
            var request = new Object();
            request.FranchiseeId = Number(franchiseeId);
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('vehicle-payment-api/treeview/', requestParams);
            if (response.ok) {
                const data = await response.json();
                if (data.isSuccess) {
                    this.setState({
                        vehicleTreeViewList: data.vehicleOwnerPaymentTreeView
                    });
                    await this.filterBindVehicleNodeTree('', 0, 0);
                }
            }

        } catch (ex) {
            console.log(ex);
        }
    }
    fetchApiData = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getFranchiseeDropdownList();
            await this.getPayrollPeriodDropdownList();
            await this.getVehicleTreeView(0);
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnRefresh = async () => {
        try {
            this.setState({ isRefreshSubmited: true });
            let errors = this.state.errors;
            errors.note = '';
            errors.dropdownPayPeriodDate = '';
            errors.pcPayrollDate = '';
            errors.pcDeductionName = '';
            errors.pcDeductionAmount = '';
            errors.pcCreditAmount = '';
            errors.pcRecoveryOption = '';
            this.setState({
                isViewCheckSubmited: false,
                isVehicleReportSubmited: false,
                isHistorySubmited: false,
                isUpdateNoteSubmited: false,
                dropdownFranchiseeList: [],
                dropdownPayrollPeriodList: [],
                vehicleTreeViewList: [],
                vehicleNode: [],
                dropdownFranchiseeId: 0,
                dropdownPayPeriodDate: null,
                filterVehicleTree: '',
                selectedTreeVehicle: [],
                vehicleId: 0,
                vehicleOwnerId: 0,
                vehicleOwnerMapId: 0,
                vehicleNumber: '',
                vehicleOwnerName: '',
                vehicleOwnerType: '',
                vehicleWefDate: '',
                payId: 0,
                isPaid: false,
                regularAmount: '',
                balanceForward: '',
                note: '',
                chequeNumber: '',
                chequeDate: '',
                isViewReadOnly: true,
                vehiclePayPaymentData: [],
                smryRideAmount: '',
                smryDeductionAmount: '',
                smryAddonAmount: '',
                smryCreditAmount: '',
                smryTotalAmount: '',
                addonGridDataSource: null,
                deductionGridDataSource: null,
                creditGridDataSource: null,
                isOpenAddonModal: false,
                pendingPayAddonGridDataSource: null,
                isOpenDeductionModal: false,
                pendingPayDeductionGridDataSource: null,
                isOpenCreditModal: false,
                pendingPayCreditGridDataSource: null,
                isCreditCurrentDeductionPayroll: false,
                pcPayrollDate: null,
                pcDeductionName: '',
                pcDeductionAmount: 0,
                pcCreditAmount: '',
                pcNote: '',
                pcRecoverable: false,
                pcNonRcoverable: false,
                pcDeductionPayId: 0,
                pcDeductionId: 0,
                pcVehicleDeductionId: 0,
                pcRecoverVehicleDeductionId: 0,
                pcVehicleDeductionType: '',
                errors: errors,
                sortTypeValue: 0,
                filterActiveVehicle: false,
                filterInActiveVehicle: false,
            });
            await this.fetchApiData();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isRefreshSubmited: false });
    }
    handleOwnerOnChange = async (selectedOption) => {
        try {
            this.setState({
                vehicleOwnerMapId: 0, vehicleId: 0, vehicleOwnerId: 0, vehicleNumber: '', vehicleOwnerName: '', vehicleOwnerType: '', vehicleWefDate: '',
                filterVehicleTree: '',
                sortTypeValue: 0,
                filterActiveVehicle: false,
                filterInActiveVehicle: false
            });
            await this.clearSetProps();
            let optOwnerId = 0
            if (selectedOption !== null && selectedOption.selectedItem !== null) {
                optOwnerId = (selectedOption.selectedItem.id !== null ? selectedOption.selectedItem.id : 0);
            }
            this.setState({ dropdownFranchiseeId: optOwnerId });
            this.setState({ loadPanelVisible: true });
            await this.getVehicleTreeView(optOwnerId);
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnChangeFilterNodes = async (event) => {
        event.preventDefault();
        try {
            this.setState({ filterVehicleTree: event.target.value, isViewReadOnly: true });
            if (event.target.value.trim() !== '') {
                await this.filterBindVehicleNodeTree(event.target.value.trim(), this.state.sortTypeValue, 0);
            }
            else {
                await this.filterBindVehicleNodeTree('', this.state.sortTypeValue, 0);
            }

        } catch (ex) {
            console.log(ex);
        }
    }
    filterBindVehicleNodeTree = async (filterText, sortTypeValue, activeId) => {
        try {
            let treeObject = [];
            let data = JSON.parse(JSON.stringify(this.state.vehicleTreeViewList));

            if (Number(activeId) === 1) {
                data = data.filter(f => f.isActive === true);
            }
            if (Number(activeId) === 2) {
                data = data.filter(f => f.isActive === false);
            }

            if (Number(sortTypeValue) === 0) {
                data = data.sort((a, b) => a.vehicleNumber.localeCompare(b.vehicleNumber));
            }
            if (Number(sortTypeValue) === 1) {
                data = data.sort((a, b) => a.vehicleTypeName.localeCompare(b.vehicleTypeName));
            }
            if (Number(sortTypeValue) === 2) {
                data = data.sort((a, b) => a.vehicleOwnerName.localeCompare(b.vehicleOwnerName));
            }

            if (filterText.trim().length !== 0) {
                filterText = filterText.toLowerCase();
                var reg = new RegExp(filterText.split('').join('.*'), 'i');
                data = data.filter(function (item) {
                    if (
                        item.vehicleNumber.trim().toLowerCase().match(reg)
                        || item.vehicleTypeName.trim().toLowerCase().match(reg)
                        || item.vehicleOwnerName.trim().toLowerCase().match(reg)
                    ) {
                        return item;
                    }
                });
                data = JSON.parse(JSON.stringify(data));
            }
            let key = 0;
            data.forEach((item) => {
                let mainroot = new Object();
                mainroot.key = key;
                mainroot.value = item.vehicleOwnerMapId;//item.vehicleId + '|' + item.vehicleTypeId + '|' + item.vehicleOwnerId + '|' + item.vehicleOwnerTypeId + '|' + item.vehicleOwnerMapId + '|' + item.mdtNo + '|' + item.vehicleGroupTypeId;;
                //mainroot.label = <span style={{fontSize:'10px'}}>{item.vehicleNumber + ' [' + item.vehicleOwnerName + '] [' + moment(item.wefDate, "YYYY-MM-DD HH:mm:ss").format('MMM YYYY') + ']'}</span> ;
                mainroot.label = item.vehicleNumber + ' [' + item.vehicleOwnerName + '] [' + moment(item.wefDate, "YYYY-MM-DD HH:mm:ss").format('MMM YYYY') + ']';
                mainroot.showCheckbox = false;
                mainroot.icon = null;
                mainroot.title = item.vehicleNumber + ' [' + item.vehicleOwnerName + '] [' + moment(item.wefDate, "YYYY-MM-DD HH:mm:ss").format('MMM YYYY') + ']';
                treeObject.push(mainroot);
                key = Number(key) + 1;
            });
            data = JSON.parse(JSON.stringify(treeObject));
            this.setState({ vehicleNode: data });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnVehicleTreeClicked = async (clicked) => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.clearSetProps();
            this.setState({ vehicleOwnerMapId: Number(clicked.value) });
            let displayData = this.state.vehicleTreeViewList.filter(d => d.vehicleOwnerMapId === Number(clicked.value));
            this.setState({ vehicleId: 0, vehicleOwnerId: 0, vehicleNumber: '', vehicleOwnerName: '', vehicleOwnerType: '', vehicleWefDate: '' });
            let _vehId = 0;
            let _vehOwnerId = 0;
            if (displayData.length > 0) {
                _vehId = displayData[0].vehicleId;
                _vehOwnerId = displayData[0].vehicleOwnerId;
                this.setState({
                    vehicleId: _vehId,
                    vehicleOwnerId: _vehOwnerId,
                    vehicleNumber: displayData[0].vehicleNumber,
                    vehicleOwnerName: displayData[0].vehicleOwnerName,
                    vehicleOwnerType: displayData[0].vehicleOwnerTypeName,
                    vehicleWefDate: moment(displayData[0].wefDate, "YYYY-MM-DD HH:mm:ss").format('MMM YYYY'),
                });
            }
            let dtPeriodEnd = '';
            if (this.state.dropdownPayPeriodDate !== null) {
                dtPeriodEnd = moment(this.state.dropdownPayPeriodDate.payDate, "MM/DD/YYYY").format('MM/DD/YYYY');//format('MM/DD/YYYY HH:mm:ss');
                if (Number(_vehId) > 0 && Number(_vehOwnerId) > 0 && Number(clicked.value) > 0) {
                    await this.viewPaySummary(dtPeriodEnd);
                }
            }
            //else {
            //    dtPeriodEnd = moment(new Date()).format('MM/DD/YYYY HH:mm:ss');
            //}


        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handlePayPeriodOnChange = async (selectedOption) => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.clearSetProps();
            if (selectedOption !== null && selectedOption.selectedItem !== null) {
                this.setState({ dropdownPayPeriodDate: selectedOption.selectedItem });
                let optPayDate = (selectedOption.selectedItem.payDate !== null ? selectedOption.selectedItem.payDate : '');
                //this.setState({ dropdownPayPeriodDate: optPayDate });
                let payDate = '';
                if (optPayDate.trim() !== '') {
                    payDate = moment(optPayDate, "MM/DD/YYYY").format('MM/DD/YYYY');// HH:mm:ss
                    await this.viewPaySummary(payDate);
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    clearSetProps = async () => {
        try {
            this.setState({
                isViewReadOnly: true,
                dropdownPayPeriodDate: null,
                regularAmount: '',
                balanceForward: '',
                chequeDate: '',
                chequeNumber: '',
                note: '',
                addonGridDataSource: null,
                deductionGridDataSource: null,
                creditGridDataSource: null,
                vehiclePayPaymentData: null,
                smryRideAmount: '',
                smryDeductionAmount: '',
                smryAddonAmount: '',
                smryCreditAmount: '',
                smryTotalAmount: '',
                //vehicleId: 0,
                //vehicleOwnerId: 0,
                //vehicleNumber: '',
                //vehicleOwnerName: '',
                //vehicleOwnerType: '',
                //vehicleWefDate: '',
                payId: 0,
                isPaid: false,
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    viewPaySummary = async (payDate) => {
        try {
            let _isViewReadOnly = true;
            this.setState({ isViewReadOnly: _isViewReadOnly });
            if (payDate.trim() !== '') {
                let _payDateYear = moment(payDate, "MM/DD/YYYY");
                _payDateYear = moment(_payDateYear).format('YYYY');
                if (Number(_payDateYear) < 1990) {
                    return;
                }
            }
            else {
                return;
            }
            payDate = payDate + " 23:59:59";
            let _payId = 0;
            let _isPaid = false;
            let _isExportedOracle = false;

            let _regularAmount = '';
            let _balanceForward = '';
            let _note = '';
            let _chequeNumber = '';
            let _chequeDate = '';
            if (Number(this.state.vehicleOwnerMapId) > 0) {
                var request = new Object();
                request.VehicleId = Number(this.state.vehicleId);
                request.VehicleOwnerId = Number(this.state.vehicleOwnerId);
                request.VehicleOwnerMapId = Number(this.state.vehicleOwnerMapId);
                request.PayDate = payDate;
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('vehicle-payment-api/pay-details/', requestParams);
                if (response.ok) {
                    const data = await response.json();
                    if (data.isSuccess) {
                        this.setState({ vehiclePayPaymentData: data.vehiclePayPayments });
                        if (data.vehiclePayPayments !== null) {
                            if (data.vehiclePayPayments.length > 0) {
                                _regularAmount = Number(data.vehiclePayPayments[0].rideNet) === 0 ? '' : data.vehiclePayPayments[0].rideNet;
                                _balanceForward = Number(data.vehiclePayPayments[0].balanceForward) === 0 ? '' : data.vehiclePayPayments[0].balanceForward;
                                _note = data.vehiclePayPayments[0].note;
                                _payId = data.vehiclePayPayments[0].vehiclePayId;
                                if (Number(data.vehiclePayPayments[0].ssInvoiceId) > 0) {
                                    _isExportedOracle = true;
                                }
                                if (data.vehiclePayPayments[0].isPaid) {
                                    _isPaid = true;
                                    _chequeNumber = data.vehiclePayPayments[0].checkNo;
                                    _chequeDate = data.vehiclePayPayments[0].payCheckDate;
                                    if (_chequeDate.trim() !== '') {
                                        let _year = moment(_chequeDate, " MM/DD/YYYY");
                                        _year = moment(_year).format('YYYY');
                                        if (Number(_year) < 1990) {
                                            _chequeDate = '';
                                        }
                                        else {
                                            _chequeDate = moment(_chequeDate, " MM/DD/YYYY").format('MM/DD/YY');
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            this.setState({ regularAmount: _regularAmount, balanceForward: _balanceForward, note: _note, chequeNumber: _chequeNumber, chequeDate: _chequeDate });
            await this.viewPayAddons(_payId);
            await this.viewPayDeducions(_payId);
            await this.viewPayCredits(_payId);
            await this.calculatePays(_payId);
            if (Number(_payId) > 0 && _isPaid === false && _isExportedOracle === false) {
                _isViewReadOnly = false;
            }
            this.setState({ payId: _payId, isPaid: _isPaid });
            this.setState({ isViewReadOnly: _isViewReadOnly });
        } catch (ex) {
            console.log(ex);
        }
    }
    viewPayAddons = async (payId) => {
        try {
            if (Number(payId) > 0) {
                var request = new Object();
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('vehicle-payment-api/' + Number(payId) + '/fetch-pay-addons', requestParams);
                if (response.ok) {
                    const data = await response.json();
                    this.setState({
                        addonGridDataSource: data.vehiclePayAddons
                    });
                }
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    viewPayDeducions = async (payId) => {
        try {
            if (Number(payId) > 0) {
                var request = new Object();
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('vehicle-payment-api/' + Number(payId) + '/fetch-pay-deductions', requestParams);
                if (response.ok) {
                    const data = await response.json();
                    this.setState({
                        deductionGridDataSource: data.vehiclePayDeductions
                    });
                }
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    viewPayCredits = async (payId) => {
        try {
            if (Number(payId) > 0) {
                var request = new Object();
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('vehicle-payment-api/' + Number(payId) + '/fetch-pay-credits', requestParams);
                if (response.ok) {
                    const data = await response.json();                    
                    this.setState({
                        creditGridDataSource: data.vehiclePayCredits
                    });
                }
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    calculatePays = async (payId) => {
        try {
            let _ridesFareAmt = 0, _balanceForwardAmt = 0, _deductionsAmt = 0, _addonsAmt = 0, _creditAmt = 0;
            if (this.state.vehiclePayPaymentData !== null) {
                this.state.vehiclePayPaymentData.forEach((item) => {
                    if (Number(item.vehiclePayId) === Number(payId)) {
                        let ridenet = Number(item.rideNet) === 0 ? 0 : parseFloat(item.rideNet);
                        _ridesFareAmt = parseFloat(_ridesFareAmt) + parseFloat(ridenet);
                        let balanfwdamt = Number(item.balanceForward) === 0 ? 0 : parseFloat(item.balanceForward);
                        _balanceForwardAmt = balanfwdamt;
                    }
                });
            }
            if (this.state.addonGridDataSource !== null) {
                this.state.addonGridDataSource.forEach((item) => {
                    if (Number(item.payId) === Number(payId)) {
                        let amount = Number(item.amount) === 0 ? 0 : parseFloat(item.amount);
                        _addonsAmt = parseFloat(_addonsAmt) + parseFloat(amount);
                    }
                });
            }
            if (this.state.deductionGridDataSource !== null) {
                this.state.deductionGridDataSource.forEach((item) => {
                    if (Number(item.payId) === Number(payId)) {
                        let amount = Number(item.amount) === 0 ? 0 : parseFloat(item.amount);
                        _deductionsAmt = parseFloat(_addonsAmt) + parseFloat(amount);
                    }
                });
            }

            if (this.state.creditGridDataSource !== null) {
                this.state.creditGridDataSource.forEach((item) => {
                    if (Number(item.payId) === Number(payId)) {
                        let amount = Number(item.amount) === 0 ? 0 : parseFloat(item.amount);
                        _creditAmt = parseFloat(_addonsAmt) + parseFloat(amount);
                    }
                });
            }
            this.setState({
                smryRideAmount: Number(_ridesFareAmt) === 0 ? '' : parseFloat(_ridesFareAmt).toFixed(2),
                smryDeductionAmount: Number(_deductionsAmt) === 0 ? '' : parseFloat(_deductionsAmt).toFixed(2),
                smryAddonAmount: Number(_addonsAmt) === 0 ? '' : parseFloat(_addonsAmt).toFixed(2),
                smryCreditAmount: Number(_creditAmt) === 0 ? '' : parseFloat(_creditAmt).toFixed(2),
            });
            _ridesFareAmt = Number(_ridesFareAmt) === 0 ? 0 : parseFloat(_ridesFareAmt);
            _deductionsAmt = Number(_deductionsAmt) === 0 ? 0 : parseFloat(_deductionsAmt);
            _addonsAmt = Number(_addonsAmt) === 0 ? 0 : parseFloat(_addonsAmt);
            _creditAmt = Number(_creditAmt) === 0 ? 0 : parseFloat(_creditAmt);
            _balanceForwardAmt = Number(_balanceForwardAmt) === 0 ? 0 : parseFloat(_balanceForwardAmt);

            let _smryTotalAmount = (_ridesFareAmt + _addonsAmt + _creditAmt + _balanceForwardAmt) - (_deductionsAmt);
            _smryTotalAmount = Number(_smryTotalAmount) === 0 ? 0 : parseFloat(_smryTotalAmount);

            this.setState({
                smryTotalAmount: _smryTotalAmount.toFixed(2),
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleFetchPendingPayAddon = async () => {
        try {
            this.setState({ loadPanelVisible: true, isOpenAddonModal: true });
            var request = new Object();
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('vehicle-payment-api/' + Number(this.state.payId) + '/fetch-pending-pay-addons', requestParams);
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    pendingPayAddonGridDataSource: data.vehiclePayAddons
                });
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleFetchPendingPayDeduction = async () => {
        try {
            let confirmMessage = '<h6>Do you want to add Recurring/Loan type deduction to this payroll '
                + (this.state.dropdownPayPeriodDate !== null ? '[ <b>' + this.state.dropdownPayPeriodDate.viewDate + '</b>] ' : ' ') + ' ?</h6>';
            let isResult = await this.isConfirm('Confirmation', confirmMessage);
            if (isResult) {
                let payRollDate = (this.state.dropdownPayPeriodDate !== null ? this.state.dropdownPayPeriodDate.payDate : '');
                if (payRollDate !== null && payRollDate !== '') {
                    payRollDate = moment(payRollDate, "MM/DD/YYYY").format('MM/DD/YYYY');
                    await this.refs.vehicleDeductionChargeComponentForPayroll.handleShowModal(
                        true,
                        false,
                        false,
                        0,
                        Number(this.state.vehicleOwnerMapId),
                        Number(this.state.vehicleId),
                        this.state.vehicleNumber,
                        Number(this.state.vehicleOwnerId),
                        this.state.vehicleOwnerName,
                        false,
                        0,
                        '',
                        0,
                        true,
                        payRollDate,
                    );
                }
            }
            else {
                this.setState({ loadPanelVisible: true, isOpenDeductionModal: true });
                var request = new Object();
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('vehicle-payment-api/' + Number(this.state.payId) + '/fetch-pending-pay-deductions', requestParams);
                if (response.ok) {
                    const data = await response.json();
                    this.setState({
                        pendingPayDeductionGridDataSource: data.vehiclePayDeductions
                    });
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleFetchPendingPayCredit = async () => {
        try {
            let isResult = false;
            this.setState({
                pendingPayCreditGridDataSource: null,
                pcPayrollDate: null,
                pcDeductionName: '',
                pcDeductionAmount: 0,
                pcNote: '',
                pcCreditAmount: '',
                pcRecoverable: false,
                pcNonRcoverable: false,
                pcDeductionPayId: 0,
            });
            await Swal.fire({
                title: '<small><b>Confirmation</b></small>',
                html: '<h6>Are you sure want to add credit entry in Current Reimbursment' + (this.state.dropdownPayPeriodDate !== null ? '[<b>' + this.state.dropdownPayPeriodDate.viewDate + '</b>] ' : ' ') + ' ?</h6>',
                icon: 'question',
                allowOutsideClick: false,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'No',
                confirmButtonText: 'Yes'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    isResult = true;
                    this.setState({ pcDeductionPayId: this.state.payId });
                }
            })
            this.setState({
                isOpenCreditModal: true,
                isCreditCurrentDeductionPayroll: isResult,
            });
            if (isResult === true) {
                await this.fetchPendingPayCredit(this.state.payId);
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    fetchPendingPayCredit = async (payId) => {
        try {
            this.setState({ loadPanelVisible: true });
            var request = new Object();
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('vehicle-payment-api/' + Number(payId) + '/fetch-pending-pay-credits', requestParams);
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    pendingPayCreditGridDataSource: data.vehiclePayCredits
                });
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnChangePcPayrollDate = async (selectedOption) => {
        let errors = this.state.errors;
        errors.pcPayrollDate = '';
        try {
            this.setState({
                pendingPayCreditGridDataSource: null,
                pcDeductionName: '',
                pcDeductionAmount: 0,
                pcNote: '',
                pcCreditAmount: '',
                pcRecoverable: false,
                pcNonRcoverable: false,
                pcDeductionPayId: 0,
                pcDeductionId: 0,
                pcVehicleDeductionId: 0,
                pcRecoverVehicleDeductionId: 0,
                pcVehicleDeductionType: ''
            });
            if (selectedOption === null || selectedOption.selectedItem === null) {
                errors.pcPayrollDate = 'Please select reimbursment date.';
                this.setState({
                    pcPayrollDate: null
                });
            }
            else {
                this.setState({ pcPayrollDate: selectedOption.selectedItem });
                let optPayDate = (selectedOption.selectedItem.payDate !== null ? selectedOption.selectedItem.payDate : '');
                let payDate = '';
                if (optPayDate.trim() !== '') {
                    payDate = moment(optPayDate, "MM/DD/YYYY").format('MM/DD/YYYY HH:mm:ss')
                }
                this.setState({ loadPanelVisible: true });
                var request = new Object();
                request.VehicleId = Number(this.state.vehicleId);
                request.VehicleOwnerId = Number(this.state.vehicleOwnerId);
                request.VehicleOwnerMapId = Number(this.state.vehicleOwnerMapId);
                request.PayDate = payDate;
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('vehicle-payment-api/pay-details/', requestParams);
                if (response.ok) {
                    const data = await response.json();
                    if (data.isSuccess) {
                        let vdPayId = 0;
                        data.vehiclePayPayments.forEach((item) => { //will return single row
                            vdPayId = item.vehiclePayId
                        });
                        this.setState({ pcDeductionPayId: vdPayId });
                        if (Number(vdPayId) > 0) {
                            await this.fetchPendingPayCredit(vdPayId);
                        }
                    }
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ errors: errors, loadPanelVisible: false });
    }
    handleClosePendingPayAddonModal = async () => {
        try {
            this.setState({ isOpenAddonModal: false, pendingPayAddonGridDataSource: null });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleClosePendingPayDeductionModal = async () => {
        try {
            this.setState({ isOpenDeductionModal: false, pendingPayDeductionGridDataSource: null });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleClosePendingPayCreditModal = async () => {
        try {
            let errors = this.state.errors;
            errors.pcPayrollDate = '';
            errors.pcDeductionName = '';
            errors.pcDeductionAmount = '';
            errors.pcCreditAmount = '';
            errors.pcRecoveryOption = '';
            this.setState({
                isOpenCreditModal: false,
                pcPayrollDate: null,
                pendingPayCreditGridDataSource: null,
                pcDeductionName: '',
                pcDeductionAmount: 0,
                pcCreditAmount: '',
                pcNote: '',
                pcRecoverable: false,
                pcNonRcoverable: false,
                pcDeductionPayId: 0,
                pcDeductionId: 0,
                pcVehicleDeductionId: 0,
                pcRecoverVehicleDeductionId: 0,
                pcVehicleDeductionType: '',
                errors: errors
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnPendingPayAddonEditorPreparing = (e) => {
        if (e.parentType == 'dataRow' && e.dataField == 'amount') {
            e.editorOptions.maxLength = 8;
        }
    }
    handleOnPendingPayDeductionEditorPreparing = (e) => {
        if (e.parentType == 'dataRow' && e.dataField == 'amount') {
            e.editorOptions.maxLength = 8;
        }
    }
    handleOnInputLeave = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            if (name === 'pcCreditAmount') {
                let creditAmount =
                    (Number(this.state.pcCreditAmount) === 0 ? '' :
                        parseFloat(this.state.pcCreditAmount).toFixed(2))
                this.setState({ pcCreditAmount: creditAmount });
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    handleInputChange = async (event) => {
        try {
            let errors = this.state.errors;
            const target = event.target;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
            const name = target.name;
            switch (name) {
                case 'pcCreditAmount':
                    errors.pcCreditAmount = '';
                    const regexDecimal = /^\d*(\.\d{0,2})?$/;
                    if (value.trim() === '' || regexDecimal.test(value)) {
                        if (value.trim().length < 9) {
                            if (value.trim() === '') {
                                errors.pcCreditAmount = 'Please enter credit amount.';
                            }
                            else {
                                if (Number(this.state.pcDeductionAmount) !== 0) {
                                    if (Number(value) > Number(this.state.pcDeductionAmount)) {
                                        errors.pcCreditAmount = 'Credit amount exceeding deduction amount. Please enter valid credit amount.';
                                    }
                                }
                            }
                            this.setState({ pcCreditAmount: value });
                        }
                    }
                    this.setState({ errors: errors });
                    break;
                case 'sortBy':
                    this.setState({ sortTypeValue: value });
                    let activeId = 0;
                    if (this.state.filterActiveVehicle) {
                        activeId = 1;
                    }
                    if (this.state.filterInActiveVehicle) {
                        activeId = 2;
                    }
                    await this.filterBindVehicleNodeTree(this.state.filterVehicleTree, value, activeId);
                    break;
                case 'enableFilter':
                    this.setState({ filterActiveVehicle: value, filterInActiveVehicle: false });
                    await this.filterBindVehicleNodeTree(this.state.filterVehicleTree, value, 1);
                    break;
                case 'disableFilter':
                    this.setState({ filterInActiveVehicle: value, filterActiveVehicle: false });
                    await this.filterBindVehicleNodeTree(this.state.filterVehicleTree, value, 2);
                    break;
                default:
                    break;
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnPendingPayCreditRowClick = (e) => {
        try {
            let errors = this.state.errors;
            errors.pcPayrollDate = '';
            errors.pcDeductionName = '';
            errors.pcDeductionAmount = '';
            errors.pcCreditAmount = '';
            errors.pcRecoveryOption = '';
            this.setState({ pcDeductionName: '', pcDeductionAmount: '', pcNode: '', pcDeductionId: 0, pcVehicleDeductionId: 0 });
            if (e.data !== null) {
                let pcNote = e.data.deductionName + '[ ' + (this.state.isCreditCurrentDeductionPayroll ? this.state.dropdownPayPeriodDate.payDate : this.state.pcPayrollDate.payDate) + ' ]';
                this.setState({
                    pcDeductionName: e.data.deductionName,
                    pcDeductionAmount: e.data.amount,
                    pcNote: pcNote,
                    pcDeductionId: e.data.deductionId,
                    pcVehicleDeductionId: e.data.vehicleDeductionId,
                    errors: errors
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnPendingPayAddonEditingStart = (e) => {
        try {
            if (e.column.dataField === 'amount') {
                let _amount = e.data.amount;
                const re = /^\d*(\.\d{0,2})?$/;
                if (_amount !== '' && !re.test(_amount)) {
                    e.cancel = true;
                }
            }
            else {
                e.cancel = true;
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnPendingPayAddonKeyDown = (e) => {
        try {
            let _amount = e.newData.amount;
            const re = /^\d*(\.\d{0,2})?$/;
            if (_amount !== '' && !re.test(_amount)) {
                e.cancel = true;
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    handleSubmitUpdatePayNotes = async (event) => {
        try {
            if (this.isUpdateNoteValid()) {
                let confirmMessage = '<h6>Are you sure want to update note entry in Current Reimbursment' + (this.state.dropdownPayPeriodDate !== null ? '[<b>' + this.state.dropdownPayPeriodDate.viewDate + '</b>] ' : ' ') + ' ?</h6>';
                let isResult = await this.isConfirm('Update Confirmation', confirmMessage);
                if (isResult) {
                    this.setState({ isUpdateNoteSubmited: true });
                    var request = new Object();
                    request.VehicleOwnerMapId = Number(this.state.vehicleOwnerMapId);
                    request.PayDate = moment(this.state.dropdownPayPeriodDate.payDate, "MM/DD/YYYY").format('MM/DD/YYYY HH:mm:ss')
                    request.Note = this.state.note.trim();
                    const requestParams = getRequestParams('POST', request);
                    const response = await fetch('vehicle-payment-api/' + Number(this.state.payId) + '/update-pay-note', requestParams);
                    const data = await response.json();
                    await Swal.fire({
                        allowOutsideClick: false,
                        icon: data.isSuccess ? 'success' : 'error',
                        title: data.isSuccess ? 'Success' : 'Oops...',
                        text: (data.isSuccess === false && (data.resultMessage === null || data.resultMessage === "")) ? 'Unable to process the request.' : data.resultMessage,
                    }).then(async (result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (data.isSuccess) {
                            if (result.isConfirmed) {
                                //await this.handleOnRefresh();
                            }
                        }
                    });
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isUpdateNoteSubmited: false });
    }
    isUpdateNoteValid = () => {
        let isValid = true;
        try {
            let errors = this.state.errors;
            errors.note = '';
            errors.dropdownPayPeriodDate = '';
            if (this.state.note.trim() === '') {
                isValid = false;
                errors.note = 'Please enter note.';
            }
            if (this.state.dropdownPayPeriodDate === null) {
                errors.dropdownPayPeriodDate = 'Please select payout date';
            }
            if (Number(this.state.vehicleId) === 0) {
                isValid = false;
                errors.note = 'Please select vehicle of payperiod.';
            }
        } catch (ex) {
            console.log(ex);
            isValid = false;
        }
        return isValid;
    }
    handleSubmitPayAddon = async (event) => {
        event.preventDefault();
        try {
            this.setState({ isSubmited: true });
            var request = new Object();
            let addonsList = [];
            for (var i = 0; i < this.state.pendingPayAddonGridDataSource.length; i++) {
                let data = {};
                data.AddonId = Number(this.state.pendingPayAddonGridDataSource[i].addonId);
                data.Note = '';
                data.Amount = parseFloat(this.state.pendingPayAddonGridDataSource[i].amount);
                if (parseFloat(data.Amount) > 0) {
                    addonsList.push(data);
                }
            }
            if (addonsList.length > 0) {
                request.VehiclePayAddons = addonsList;
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('vehicle-payment-api/' + Number(this.state.payId) + '/save-pay-addon', requestParams);
                const data = await response.json();
                await Swal.fire({
                    allowOutsideClick: false,
                    icon: data.isSuccess ? 'success' : 'error',
                    title: data.isSuccess ? 'Success' : 'Oops...',
                    text: (data.isSuccess === false && (data.resultMessage === null || data.resultMessage === "")) ? 'Unable to process the request.' : data.resultMessage,
                }).then(async (result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            this.setState({ isOpenAddonModal: false });
                            await this.viewPayAddons(this.state.payId);
                            await this.calculatePays(this.state.payId);
                        }
                    }
                });
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmited: false });
    }
    handleOnDeletePayAddon = async (event, data) => {
        event.preventDefault();
        try {
            if (data !== null) {
                let confirmMessage = '<h6>Are you sure want to delete addon [ <b> $' + data.data.amount + '</b> ] entry in Current Reimbursment' + (this.state.dropdownPayPeriodDate !== null ? '[<b>' + this.state.dropdownPayPeriodDate.viewDate + '</b>] ' : ' ') + ' ?</h6>';
                let isResult = await this.isConfirm('Delete Confirmation', confirmMessage);
                if (isResult) {
                    this.setState({ isSumbitDeletePayAddon: true, deletedPayAddonId: data.data.id });
                    var request = new Object();
                    request.PayId = Number(this.state.payId);
                    request.AddonId = Number(data.data.addonId);
                    const requestParams = getRequestParams('POST', request);
                    const response = await fetch('vehicle-payment-api/' + Number(data.data.id) + '/delete-pay-addon', requestParams);
                    const dataRes = await response.json();
                    await Swal.fire({
                        allowOutsideClick: false,
                        icon: dataRes.isSuccess ? 'success' : 'error',
                        title: dataRes.isSuccess ? 'Success' : 'Oops...',
                        text: (dataRes.isSuccess === false && (dataRes.resultMessage === null || dataRes.resultMessage === "")) ? 'Unable to process the request.' : dataRes.resultMessage,
                    }).then(async (result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (dataRes.isSuccess) {
                            if (result.isConfirmed) {
                                await this.viewPayAddons(this.state.payId);
                                await this.calculatePays(this.state.payId);
                            }
                        }
                    });
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSumbitDeletePayAddon: false, deletedPayAddonId: 0 });
    }
    handleSubmitPayDeduction = async (event) => {
        event.preventDefault();
        try {
            this.setState({ isSubmited: true });
            var request = new Object();
            let dedList = [];
            for (var i = 0; i < this.state.pendingPayDeductionGridDataSource.length; i++) {
                let data = {};
                data.DeductionId = Number(this.state.pendingPayDeductionGridDataSource[i].deductionId);
                data.DeductionName = this.state.pendingPayDeductionGridDataSource[i].deductionName;
                data.Amount = parseFloat(this.state.pendingPayDeductionGridDataSource[i].amount);
                if (parseFloat(data.Amount) > 0) {
                    dedList.push(data);
                }
            }
            if (dedList.length > 0) {
                request.VehicleOwnerMapId = Number(this.state.vehicleOwnerMapId);
                request.VehiclePayDeductions = dedList;
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('vehicle-payment-api/' + Number(this.state.payId) + '/save-pay-deduction', requestParams);
                const data = await response.json();
                await Swal.fire({
                    allowOutsideClick: false,
                    icon: data.isSuccess ? 'success' : 'error',
                    title: data.isSuccess ? 'Success' : 'Oops...',
                    text: (data.isSuccess === false && (data.resultMessage === null || data.resultMessage === "")) ? 'Unable to process the request.' : data.resultMessage,
                }).then(async (result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            this.setState({ isOpenDeductionModal: false });
                            await this.viewPayDeducions(this.state.payId);
                            await this.calculatePays(this.state.payId);
                        }
                    }
                });
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmited: false });
    }
    handleOnDeletePayDeduction = async (event, data) => {
        event.preventDefault();
        try {
            if (data !== null) {
                let confirmMessage = '<h6>Are you sure want to delete deduction [ <b>$' + data.data.amount + '</b> ] entry in Current Reimbursment' + (this.state.dropdownPayPeriodDate !== null ? '[<b>' + this.state.dropdownPayPeriodDate.viewDate + '</b>] ' : ' ') + ' ?</h6>';
                let isResult = await this.isConfirm('Delete Confirmation', confirmMessage);
                if (isResult) {
                    this.setState({ isSumbitDeletePayDeduction: true, deletedPayDeductionId: data.data.id });
                    var request = new Object();
                    const requestParams = getRequestParams('POST', request);
                    const response = await fetch('vehicle-payment-api/' + Number(data.data.id) + '/delete-pay-deduction', requestParams);
                    const dataRes = await response.json();
                    await Swal.fire({
                        allowOutsideClick: false,
                        icon: dataRes.isSuccess ? 'success' : 'error',
                        title: dataRes.isSuccess ? 'Success' : 'Oops...',
                        text: (dataRes.isSuccess === false && (dataRes.resultMessage === null || dataRes.resultMessage === "")) ? 'Unable to process the request.' : dataRes.resultMessage,
                    }).then(async (result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (dataRes.isSuccess) {
                            if (result.isConfirmed) {
                                await this.viewPayDeducions(this.state.payId);
                                await this.calculatePays(this.state.payId);
                            }
                        }
                    });
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSumbitDeletePayDeduction: false, deletedPayDeductionId: 0 });
    }
    handleSubmitPayCredit = async (event) => {
        event.preventDefault();
        try {
            let isValid = true;
            let errors = this.state.errors;
            errors.pcPayrollDate = '';
            errors.pcDeductionName = '';
            errors.pcDeductionAmount = '';
            errors.pcCreditAmount = '';
            errors.pcRecoveryOption = '';
            if (this.state.isCreditCurrentDeductionPayroll === false && (this.state.pcPayrollDate === null || Object.keys(this.state.pcPayrollDate).length === 0)) {
                errors.pcPayrollDate = 'Please select reimbursment date';
                isValid = false;
            }
            if (Number(this.state.pcDeductionPayId) === 0) {
                errors.pcPayrollDate = 'Invalid Deduction Reimbursment period.';
                isValid = false;
            }
            if (Number(this.state.pcVehicleDeductionId) === 0) {

                errors.pcDeductionName = 'Selected deduction is invalid.';
                isValid = false;
            }
            if (Number(this.state.pcDeductionAmount) === 0) {
                errors.pcDeductionAmount = 'Selected deduction amount is invalid.';
                isValid = false;
            }
            if (Number(this.state.pcCreditAmount) === 0) {
                errors.pcCreditAmount = 'Credit amount is Zero. Please enter valid credit amount.';
                isValid = false;
            }
            if (parseFloat(this.state.pcCreditAmount) > parseFloat(this.state.pcDeductionAmount)) {
                errors.pcCreditAmount = 'Credit amount exceeding deduction amount. Please enter valid credit amount.';
                isValid = false;
            }
            if (this.state.pcRecoverable === false && this.state.pcNonRcoverable === false) {
                errors.pcRecoveryOption = 'Please select recovery option.';
                isValid = false;
            }
            this.setState({ errors: errors });
            if (isValid) {
                this.setState({ isSubmited: true });
                var request = new Object();
                request.VehicleDeductionId = Number(this.state.vehicleOwnerMapId);
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('vehicle-payment-api/' + Number(this.state.pcDeductionPayId) + '/validate-save-pay-credit', requestParams);
                const data = await response.json();
                let vehiclePayCreditCount = 0;
                let vehicleDeductionType = '';
                if (data.isSuccess) {
                    vehiclePayCreditCount = data.vehiclePayCreditCount;
                    vehicleDeductionType = data.vehicleDeductionType;
                }
                this.setState({ pcVehicleDeductionType: vehicleDeductionType });
                this.setState({ isSubmited: false });
                if (vehiclePayCreditCount > 0) {
                    await Swal.fire({
                        allowOutsideClick: false,
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Credit duplication for this deduction. Please select another deduction.',
                    })
                    return;
                }
                let confirmMessage = '';
                if (this.state.pcRecoverable) {
                    confirmMessage = '<h6>This is recoverable credit entry. You need to fill recovery details.</br> Are you sure want to continue?';
                }
                else {
                    confirmMessage = '<h6>Are you sure you want to add this Credit Entry?';
                }
                if (await this.isConfirm('Confirmation', confirmMessage) === false) {
                    return;
                }
                this.setState({ pcRecoverVehicleDeductionId: 0 });
                if (this.state.pcNonRcoverable === false) {
                    await this.refs.vehicleDeductionChargeComponentForCredit.handleShowModal(
                        true,
                        false,
                        false,
                        0,
                        Number(this.state.vehicleOwnerMapId),
                        Number(this.state.vehicleId),
                        this.state.vehicleNumber,
                        Number(this.state.vehicleOwnerId),
                        this.state.vehicleOwnerName,
                        true,
                        Number(this.state.pcDeductionId),
                        this.state.pcDeductionName,
                        Number(this.state.pcCreditAmount),
                        false,
                        null,
                    );
                }
                else {
                    await this.savePayCredit();
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmited: false });
    }
    savePayCredit = async () => {
        try {
            if (this.state.pcRecoverable === true && Number(this.state.pcRecoverVehicleDeductionId) === 0) {
                await Swal.fire({
                    allowOutsideClick: false,
                    icon: 'warning',
                    title: 'Oops...',
                    text: 'You had selected recoverable credit but recovery details not filled. Please fill recovery details and try again.',
                })
                return;
            }
            this.setState({ isSubmited: true });
            var request = new Object();
            request.CRPayId = Number(this.state.pcDeductionPayId);
            if (this.state.isCreditCurrentDeductionPayroll) {
                request.CrPayDate = (this.state.dropdownPayPeriodDate === null ? '' : this.state.dropdownPayPeriodDate.payDate)
            }
            else {
                request.CrPayDate = (this.state.pcPayrollDate === null ? '' : this.state.pcPayrollDate.payDate)
            }
            request.VehicleDeductionId = Number(this.state.pcVehicleDeductionId);
            request.DeductionId = Number(this.state.pcDeductionId);
            request.Amount = Number(this.state.pcCreditAmount);
            request.CreditNote = this.state.pcNote.trim();
            request.IsRecoverable = this.state.pcRecoverable;
            request.RecoverVehicleDeductionId = Number(this.state.pcRecoverVehicleDeductionId);
            if (this.state.pcVehicleDeductionType.trim().toLowerCase() === "loan") {
                request.IsLoanCredit = true;
                request.LoanId = Number(this.state.pcRecoverVehicleDeductionId);
            }
            const requestParamsSave = getRequestParams('POST', request);
            const responseSave = await fetch('vehicle-payment-api/' + Number(this.state.payId) + '/save-pay-credit', requestParamsSave);
            const dataRes = await responseSave.json();
            let icon = '';
            let title = '';
            if (dataRes.isSuccess) {
                icon = 'success';
                title = 'Success';
            }
            else {
                if (dataRes.retValue === -1) {
                    icon = 'warning';
                    title = 'Unable to process';
                }
                else {
                    icon = 'error';
                    title = 'Oops...';
                }
            }
            await Swal.fire({
                allowOutsideClick: false,
                icon: icon,
                title: title,
                text: (dataRes.isSuccess === false && (dataRes.resultMessage === null || dataRes.resultMessage === "")) ? 'Unable to process the request.' : dataRes.resultMessage,
            }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (dataRes.isSuccess) {
                    if (result.isConfirmed) {
                        this.setState({ isOpenCreditModal: false });
                        await this.viewPayCredits(this.state.payId);
                        await this.calculatePays(this.state.payId);
                    }
                }
            });
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmited: false });
    }
    handleSetRecoverVehicleDeductionId = async (recoverVehicleDeductionId) => {
        try {
            this.setState({ pcRecoverVehicleDeductionId: recoverVehicleDeductionId });
            await this.savePayCredit();
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnDeletePayCredit = async (event, data) => {
        event.preventDefault();
        try {
            if (data !== null) {
                let confirmMessage = '<h6>Are you sure want to delete credit [ <b>$' + data.data.amount + '</b> ] entry in Current Reimbursment' + (this.state.dropdownPayPeriodDate !== null ? '[<b>' + this.state.dropdownPayPeriodDate.viewDate + '</b>] ' : ' ') + ' ?</h6>';
                let isResult = await this.isConfirm('Delete Confirmation', confirmMessage);
                if (isResult) {
                    this.setState({ isSubmitDeletePayCredit: true, deletedPayCreditId: data.data.id });
                    var request = new Object();
                    const requestParams = getRequestParams('POST', request);
                    const response = await fetch('vehicle-payment-api/' + Number(data.data.id) + '/delete-pay-credit', requestParams);
                    const dataRes = await response.json();
                    let icon = '';
                    let title = '';
                    if (dataRes.isSuccess) {
                        icon = 'success';
                        title = 'Success';
                    }
                    else {
                        if (dataRes.retValue === -1) {
                            icon = 'warning';
                            title = 'Unable to process';
                        }
                        else {
                            icon = 'error';
                            title = 'Oops...';
                        }
                    }
                    await Swal.fire({
                        allowOutsideClick: false,
                        icon: icon,
                        title: title,
                        text: (dataRes.isSuccess === false && (dataRes.resultMessage === null || dataRes.resultMessage === "")) ? 'Unable to process the request.' : dataRes.resultMessage,
                    }).then(async (result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (dataRes.isSuccess) {
                            if (result.isConfirmed) {
                                await this.viewPayCredits(this.state.payId);
                                await this.calculatePays(this.state.payId);
                            }
                        }
                    });
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitDeletePayCredit: false, deletedPayCreditId: 0 });
    }
    isConfirm = async (title, confirmMessage) => {
        let isResult = false;
        await Swal.fire({
            title: '<small><b> ' + title + ' </b></small>',
            html: '<small>' + confirmMessage + '</small>',
            icon: 'question',
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Yes'
        }).then(async (result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }
    handleReimbursementHistoryModal = async (e) => {
        e.preventDefault();
        try {
            this.refs.vehiclePayLogHistoryComponent.showModal('vehicle-payment-api/', 'Vehicle', this.state.vehicleNumber, Number(this.state.payId), Number(this.state.vehicleId));
        } catch (ex) {
            console.log(ex);
        }
    };
    handleOnViewCheckReport = async () => {
        try {
            this.setState({ isViewCheckSubmited: true });
            await this.showReport(1);
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isViewCheckSubmited: false });
    }
    handleOnVehicleReport = async () => {
        try {
            this.setState({ isVehicleReportSubmited: true });
            await this.showReport(0);
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isVehicleReportSubmited: false });
    }
    showReport = async (reportType) => {
        try {
            var request = new Object();
            request.LocationId = Number(sessionStorage.getItem("session_locationId"));
            request.ReportType = Number(reportType);
            request.Number1 = Number(this.state.payId);
            request.fileFormat = 'pdf';
            request.MethodName = "GetAccountPayments";
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            let a = document.createElement('a');
            a.href = dataResponse.reportBase64String;
            a.download = 'AccountPayments.pdf';
            a.click();
        } catch (ex) {
            console.log(ex);
        }
    }
    render() {
        const renderDeleteAddonGrid = (data) => {
            return <div>
                <a href="#" title="Remove" onClick={(e) => { this.handleOnDeletePayAddon(e, data) }} >
                    {this.state.isSumbitDeletePayAddon && this.state.deletedPayAddonId === data.data.id && <i className="fas fa-spinner fa-spin"></i>}
                    {this.state.deletedPayAddonId !== data.data.id && <i className="fas fa-trash-alt"></i>}
                </a>
            </div>;
        }
        const renderDeleteDeductionGrid = (data) => {
            return <div>
                <a href="#" title="Remove" onClick={(e) => { this.handleOnDeletePayDeduction(e, data) }} >
                    {this.state.isSumbitDeletePayDeduction && this.state.deletedPayDeductionId === data.data.id && <i className="fas fa-spinner fa-spin"></i>}
                    {this.state.deletedPayDeductionId !== data.data.id && <i className="fas fa-trash-alt"></i>}
                </a>
            </div>;
        }
        const renderDeleteCreditGrid = (data) => {
            return <div>
                <a href="#" title="Remove" onClick={(e) => { this.handleOnDeletePayCredit(e, data) }} >
                    {this.state.isSubmitDeletePayCredit && this.state.deletedPayCreditId === data.data.id && <i className="fas fa-spinner fa-spin"></i>}
                    {this.state.deletedPayCreditId !== data.data.id && <i className="fas fa-trash-alt"></i>}
                </a>
            </div>;
        }
        const renderPendingPayAddonGridCell = (data) => {
            let cellColor = (data.data.amount > 0) ? 'blue' : 'yellowgreen';
            return <div style={{ color: cellColor }}>{data.data.amount}</div>;
        }
        const renderPendingPayDeductionGridCell = (data) => {
            let cellColor = (data.data.amount > 0) ? 'blue' : 'yellowgreen';
            return <div style={{ color: cellColor }}>{data.data.amount}</div>;
        }
        const renderPendingPayCreditGridCell = (data) => {
            let cellColor = (data.data.amount > 0) ? 'blue' : 'yellowgreen';
            return <div style={{ color: cellColor }}>{data.data.amount}</div>;
        }
        return (
            <div>
                <form style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="content-wrapper">
                        <div className="content-header">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                        <h1 className="m-0 text-dark">Vehicle Reimbursment/Payments</h1>
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                                        <ol className="breadcrumb float-sm-right">
                                            <NavItem>
                                                <NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="#" title="Refresh"
                                                    onClick={this.handleOnRefresh}>
                                                    {this.state.isRefreshSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                                    {!this.state.isRefreshSubmited && <span title="Refresh"><i className="fas fa-sync-alt"></i> Refresh</span>}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem >
                                                <NavLink tag={Link} to="/generate/payout" className="btn btn-block btn-outline-secondary btn-sm" title="Generate Payout">
                                                    <i className="fas fa-file-invoice-dollar"></i> Generate Payout</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to="/post/payout" className="btn btn-block btn-outline-secondary btn-sm" title="Post Ride(s)">
                                                    <i className="fas fa-check"></i> Post Ride(s)</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to="/peding/postrides" className="btn btn-block btn-outline-secondary btn-sm" title="Pending Post Ride(s)">
                                                    <i className="fas fa-clock"></i> Pending Post Ride(s)</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to="/posted/rides" className="btn btn-block btn-outline-secondary btn-sm" title="Posted Rides">
                                                    <i className="fas fa-check-double"></i> Posted Rides</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to="/reimbursement/history" className="btn btn-block btn-outline-secondary btn-sm" title="Reimbursement History">
                                                    <i className="fas fa-history"></i> Reimb. History</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to="/reimbursement/payperiod" className="btn btn-block btn-outline-secondary btn-sm" title="Reimbursement Pay Period">
                                                    <i className="fas fa-calendar-alt"></i> Reimb. Pay Period</NavLink>
                                            </NavItem>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="rez-container">
                                <div className="row mt-2">
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                        <div className="card main-rez-card">
                                            <div className="card-body">
                                                <div className="card sub-rez-card">
                                                    <div className="card-header">
                                                        <h5 className="card-title m-0">Vehicle</h5>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            {/* <label htmlFor="" className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-form-label">Franchisee </label>*/}

                                                            {/*<div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                                <div className="form-group">
                                                                    <label>Franchisee </label>
                                                                </div>
                                                            </div>*/}
                                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                <div className="form-group">
                                                                    <label>Franchisee </label>
                                                                    {/*<Select
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="select"
                                                                        placeholder="All franchisee"
                                                                        defaultValue={[]}
                                                                        value={this.state.dropdownFranchiseeId === 0 ? [] :
                                                                            [this.state.dropdownFranchiseeList.find(opt => opt.id === this.state.dropdownFranchiseeId)]}
                                                                        name="owner"
                                                                        onChange={this.handleOwnerOnChange}
                                                                        getOptionLabel={option => option.name}
                                                                        getOptionValue={option => option.id}
                                                                        options={this.state.dropdownFranchiseeList !== null ? this.state.dropdownFranchiseeList : []}
                                                                        isMulti={false}
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                    />*/}
                                                                    <SelectBox
                                                                        dataSource={this.state.dropdownFranchiseeList}
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="select"
                                                                        placeholder="All franchisee"
                                                                        name="ddfranchisee"
                                                                        defaultValue={0}
                                                                        value={this.state.dropdownFranchiseeId}
                                                                        displayExpr='name'
                                                                        valueExpr='id'
                                                                        onSelectionChanged={this.handleOwnerOnChange}
                                                                        showClearButton={true}
                                                                        disabled={false}
                                                                        searchEnabled={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                <div className="form-group">
                                                                    <span className="form-check">
                                                                        <input className="form-check-input" type="radio" name="enableFilter" value="1"
                                                                            checked={this.state.filterActiveVehicle}
                                                                            onChange={(e) => { this.handleInputChange(e) }} />
                                                                        <label className="form-check-label">Enable</label>
                                                                    </span>
                                                                    <span className="form-check">
                                                                        <input className="form-check-input" type="radio" name="disableFilter" value="2"
                                                                            checked={this.state.filterInActiveVehicle}
                                                                            onChange={(e) => { this.handleInputChange(e) }} />
                                                                        <label className="form-check-label">Disable</label>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            {/*<div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                                <div className="form-group">
                                                                    <label>Sort By: </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                                <div className="form-group">
                                                                    <select name="sortBy" className="form-control form-control-sm"
                                                                        onChange={this.handleInputChange}
                                                                        value={this.state.sortTypeValue}>
                                                                        <option value="0">Vehicle No</option>
                                                                        <option value="1">Vehicle Type</option>
                                                                        <option value="2">Vehicle Owner</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            */}
                                                            {/*<label htmlFor="" className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-form-label">Filter=> </label>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                                <select name="filter" className="form-control form-control-sm" >
                                                                    <option value="0">---Filter On---</option>
                                                                    <option value="1">Vehicle No</option>
                                                                    <option value="2">Vehicle Type</option>
                                                                    <option value="3">Vehicle Owner</option>
                                                                </select>
                                                            </div>*/}
                                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                <div className="form-group">
                                                                    <label>Search </label>
                                                                    <input
                                                                        className="filter-text form-control form-textbox form-control-sm"
                                                                        placeholder="Type here..."
                                                                        type="text"
                                                                        value={this.state.filterVehicleTree}
                                                                        onChange={(e) => { this.handleOnChangeFilterNodes(e) }}
                                                                    />
                                                                    <div style={{ height: 500 + 'px', overflowY: 'auto', overflowX: 'hidden' }}>
                                                                        <CheckboxTree
                                                                            nodes={this.state.vehicleNode}
                                                                            onClick={this.handleOnVehicleTreeClicked}
                                                                            showCheckbox={false}
                                                                            showNodeIcon={false}
                                                                            className="custom-checkbox-list"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                                        <div className="row">
                                            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                <div className="form-group row">
                                                    <label className="col-lg-5 col-form-label">Reimbursment Period </label>
                                                    <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                        {/*<Select
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            placeholder="Select period"
                                                            defaultValue={[]}
                                                            value={this.state.dropdownPayPeriodDate}
                                                            name="ddpayperiod"
                                                            onChange={this.handlePayPeriodOnChange}
                                                            getOptionLabel={option => option.viewDate}
                                                            getOptionValue={option => option.payDate}
                                                            options={this.state.dropdownPayrollPeriodList}
                                                            isMulti={false}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                        />*/}
                                                        <SelectBox
                                                            dataSource={this.state.dropdownPayrollPeriodList}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            placeholder="Select period"
                                                            name="ddpayperiod"
                                                            defaultValue={''}
                                                            value={(this.state.dropdownPayPeriodDate !== null && this.state.dropdownPayPeriodDate.payDate !== null) ? this.state.dropdownPayPeriodDate.payDate : ''}
                                                            displayExpr='viewDate'
                                                            valueExpr='payDate'
                                                            onSelectionChanged={this.handlePayPeriodOnChange}
                                                            showClearButton={true}
                                                            disabled={false}
                                                            searchEnabled={true}
                                                        />
                                                        {this.state.errors["dropdownPayPeriodDate"].length > 0 && <span className='error invalid-feedback'>
                                                            {this.state.errors["dropdownPayPeriodDate"]}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7 text-right">
                                                {<button type="button" className="btn btn-custom-gray btn-sm" disabled={this.state.isViewReadOnly}
                                                    onClick={this.handleOnViewCheckReport}>
                                                    {this.state.isViewCheckSubmited && <span title="Loading">
                                                        <i className="fas fa-spinner fa-spin"></i> Loading...</span>}
                                                    {!this.state.isViewCheckSubmited && <span title="View Check">
                                                        <i className="fas fa-eye"></i> View Check</span>}
                                                </button>}&nbsp;&nbsp;
                                                {<button type="button" className="btn btn-custom-gray btn-sm" disabled={this.state.isViewReadOnly}
                                                    onClick={this.handleOnVehicleReport}>
                                                    {this.state.isVehicleReportSubmited && <span title="Loading">
                                                        <i className="fas fa-spinner fa-spin"></i> Loading...</span>}
                                                    {!this.state.isVehicleReportSubmited && <span title="Vehicle Report">
                                                        <i className="fas fa-file-alt"></i> Vehicle Report</span>}
                                                </button>}&nbsp;&nbsp;
                                                {<button type="button" className="btn btn-custom-gray btn-sm" disabled={this.state.isViewReadOnly}
                                                    onClick={this.handleReimbursementHistoryModal}>
                                                    {this.state.isHistorySubmited && <span title="In Process">
                                                        <i className="fas fa-spinner fa-spin"></i> In process</span>}
                                                    {!this.state.isHistorySubmited && <span title="History"><i className="fas fa-history"></i> History</span>}
                                                </button>}
                                            </div>
                                        </div>
                                        <div className="card main-rez-card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                        <div className="card sub-rez-card">
                                                            <div className="card-header">
                                                                <h5 className="card-title m-0">Vehicle Owner Details</h5>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                        <div className="form-group">
                                                                            <label>Vehicle Number</label>
                                                                            <input type="text" className="form-control form-control-sm"
                                                                                name="vehicleNumber" value={this.state.vehicleNumber}
                                                                                placeholder="" readOnly={true} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                        <div className="form-group">
                                                                            <label>Owner </label>
                                                                            <input type="text" className="form-control form-control-sm"
                                                                                name="vehicleOwner" value={this.state.vehicleOwnerName}
                                                                                placeholder="" readOnly={true} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                        <div className="form-group">
                                                                            <label>Owner Type </label>
                                                                            <input type="text" className="form-control form-control-sm"
                                                                                name="ownerType" value={this.state.vehicleOwnerType}
                                                                                placeholder="" readOnly={true} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                        <div className="form-group">
                                                                            <label>WEF Date</label>
                                                                            <input type="text" className="form-control form-control-sm"
                                                                                name="wefDate" value={this.state.vehicleWefDate}
                                                                                placeholder="" readOnly={true} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                        <div className="card sub-rez-card">
                                                            <div className="card-header">
                                                                <h5 className="card-title m-0">Payment Details</h5>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                        <div className="form-group">
                                                                            <label>Cheque Date</label>
                                                                            <input type="text" className="form-control form-control-sm"
                                                                                name="chequeDate" value={this.state.chequeDate} readOnly={true} placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                        <div className="form-group">
                                                                            <label>Cheque Number/Header Id </label>
                                                                            <input type="text" className="form-control form-control-sm"
                                                                                name="chequeHeaderId" value={this.state.chequeNumber} readOnly={true} placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                        <div className="form-group">
                                                                            <label>Balance Forward</label>
                                                                            <input type="text" className="form-control form-control-sm" name="balanceForward"
                                                                                value={this.state.balanceForward !== '' ? '$' + this.state.balanceForward : ''}
                                                                                placeholder="$0.00" readOnly={true} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                        <div className="form-group">
                                                                            <label>Regular </label>
                                                                            <input type="text" className="form-control form-control-sm" name="regular"
                                                                                value={this.state.regularAmount !== '' ? '$' + this.state.regularAmount : ''} placeholder="$0.00"
                                                                                readOnly={true} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                        <div className="card sub-rez-card">
                                                            <div className="card-header">
                                                                <h5 className="card-title m-0">Addons Details</h5>
                                                                <span style={{ float: 'right', cursor: (!this.state.isViewReadOnly || 'not-allowed') }}>
                                                                    <NavLink style={{ display: 'inline-block', marginRight: '10px' }}
                                                                        className="text-sm p-0" title="Refresh"
                                                                        onClick={() => { this.viewPayAddons(this.state.payId) }}
                                                                        disabled={this.state.isViewReadOnly}>
                                                                        {this.state.isViewReadOnly && <span style={{ opacity: 0.5 }}><i className="fas fa-sync-alt"></i> Refresh</span>}
                                                                        {!this.state.isViewReadOnly && <span>  <i className="fas fa-sync-alt"></i> Refresh</span>}
                                                                    </NavLink>
                                                                    <NavLink style={{ display: 'inline-block', marginRight: '10px' }}
                                                                        className="text-sm p-0" title="Add"
                                                                        disabled={this.state.isViewReadOnly}
                                                                        onClick={this.handleFetchPendingPayAddon}>
                                                                        {this.state.isViewReadOnly && <span style={{ opacity: 0.5 }}> <i className="fas fa-plus"></i> Add</span>}
                                                                        {!this.state.isViewReadOnly && <span> <i className="fas fa-plus"></i> Add</span>}
                                                                    </NavLink>
                                                                </span>
                                                            </div>
                                                            <div className="card-body mt-2">
                                                                <div>
                                                                    <DataGrid
                                                                        dataSource={this.state.addonGridDataSource}
                                                                        keyExpr="id"
                                                                        selection={{ mode: 'single' }}
                                                                        columnsAutoWidth={false}
                                                                        showColumnLines={true}
                                                                        showRowLines={true}
                                                                        showBorders={true}
                                                                        rowAlternationEnabled={true}
                                                                        allowColumnResizing={true}
                                                                        columnResizingMode='widget'
                                                                    >
                                                                        <Column dataField="id" width={30}
                                                                            caption="" alignment={'center'} allowFiltering={false} allowSorting={false}
                                                                            cellRender={renderDeleteAddonGrid}
                                                                        />
                                                                        <Column dataField="addonName" caption="Addons" />
                                                                        <Column dataField="amount" caption="Amount $" />
                                                                        <SortByGroupSummaryInfo summaryItem="count" />
                                                                        <GroupPanel visible={false} />
                                                                        <HeaderFilter visible={false} />
                                                                        <SearchPanel visible={false}
                                                                            highlightCaseSensitive={false} width={240} placeholder="Search..." />
                                                                        <Grouping autoExpandAll={false} />
                                                                        <Paging defaultPageSize={20} />
                                                                        <Pager showPageSizeSelector={false} allowedPageSizes={[5, 10, 20]} showInfo={false} />
                                                                        <Export enabled={false} allowExportSelectedData={false} />
                                                                        <ColumnChooser enabled={false} />
                                                                        <ColumnFixing enabled={false} />
                                                                    </DataGrid>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                        <div className="card sub-rez-card">
                                                            <div className="card-header">
                                                                <h5 className="card-title m-0">Credit Details</h5>
                                                                <span style={{ float: 'right', cursor: (!this.state.isViewReadOnly || 'not-allowed') }}>
                                                                    <NavLink style={{ display: 'inline-block', marginRight: '10px' }}
                                                                        className="text-sm p-0" title="Refresh"
                                                                        onClick={() => { this.viewPayCredits(this.state.payId) }}
                                                                        disabled={this.state.isViewReadOnly}>
                                                                        {this.state.isViewReadOnly && <span style={{ opacity: 0.5 }}><i className="fas fa-sync-alt"></i> Refresh</span>}
                                                                        {!this.state.isViewReadOnly && <span>  <i className="fas fa-sync-alt"></i> Refresh</span>}
                                                                    </NavLink>
                                                                    <NavLink style={{ display: 'inline-block', marginRight: '10px' }}
                                                                        className="text-sm p-0" title="Add"
                                                                        disabled={this.state.isViewReadOnly}
                                                                        onClick={this.handleFetchPendingPayCredit}>
                                                                        {this.state.isViewReadOnly && <span style={{ opacity: 0.5 }}> <i className="fas fa-plus"></i> Add</span>}
                                                                        {!this.state.isViewReadOnly && <span> <i className="fas fa-plus"></i> Add</span>}
                                                                    </NavLink>
                                                                </span>
                                                            </div>
                                                            <div className="card-body mt-2">
                                                                <div>
                                                                    <DataGrid
                                                                        dataSource={this.state.creditGridDataSource}
                                                                        keyExpr="id"
                                                                        selection={{ mode: 'single' }}
                                                                        columnsAutoWidth={false}
                                                                        showColumnLines={true}
                                                                        showRowLines={true}
                                                                        showBorders={true}
                                                                        rowAlternationEnabled={true}
                                                                        allowColumnResizing={true}
                                                                        columnResizingMode='widget'
                                                                    >
                                                                        <Column dataField="id" width={30}
                                                                            caption="" alignment={'center'} allowFiltering={false} allowSorting={false}
                                                                            cellRender={renderDeleteCreditGrid}
                                                                        />
                                                                        <Column dataField="crPayDate" caption="Deduction Date" />
                                                                        <Column dataField="deductionName" caption="Deduction" />
                                                                        <Column dataField="amount" caption="Amount $" />
                                                                        <SortByGroupSummaryInfo summaryItem="count" />
                                                                        <GroupPanel visible={false} />
                                                                        <HeaderFilter visible={false} />
                                                                        <SearchPanel visible={false}
                                                                            highlightCaseSensitive={false} width={240} placeholder="Search..." />
                                                                        <Grouping autoExpandAll={false} />
                                                                        <Paging defaultPageSize={20} />
                                                                        <Pager showPageSizeSelector={false} allowedPageSizes={[5, 10, 20]} showInfo={false} />
                                                                        <Export enabled={false} allowExportSelectedData={false} />
                                                                        <ColumnChooser enabled={false} />
                                                                        <ColumnFixing enabled={false} />
                                                                    </DataGrid>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                        <div className="card sub-rez-card">
                                                            <div className="card-header">
                                                                <h5 className="card-title m-0">Deduction Details</h5>
                                                                <span style={{ float: 'right', cursor: (!this.state.isViewReadOnly || 'not-allowed') }}>
                                                                    <NavLink style={{ display: 'inline-block', marginRight: '10px' }}
                                                                        className="text-sm p-0" title="Refresh"
                                                                        onClick={() => { this.viewPayDeducions(this.state.payId) }}
                                                                        disabled={this.state.isViewReadOnly}>
                                                                        {this.state.isViewReadOnly && <span style={{ opacity: 0.5 }}><i className="fas fa-sync-alt"></i> Refresh</span>}
                                                                        {!this.state.isViewReadOnly && <span>  <i className="fas fa-sync-alt"></i> Refresh</span>}
                                                                    </NavLink>
                                                                    <NavLink style={{ display: 'inline-block', marginRight: '10px' }}
                                                                        className="text-sm p-0" title="Add"
                                                                        disabled={this.state.isViewReadOnly}
                                                                        onClick={this.handleFetchPendingPayDeduction}>
                                                                        {this.state.isViewReadOnly && <span style={{ opacity: 0.5 }}> <i className="fas fa-plus"></i> Add</span>}
                                                                        {!this.state.isViewReadOnly && <span> <i className="fas fa-plus"></i> Add</span>}
                                                                    </NavLink>
                                                                </span>
                                                            </div>
                                                            <div className="card-body mt-2">
                                                                <div>
                                                                    <DataGrid
                                                                        dataSource={this.state.deductionGridDataSource}
                                                                        keyExpr="id"
                                                                        selection={{ mode: 'single' }}
                                                                        columnsAutoWidth={true}
                                                                        showColumnLines={true}
                                                                        showRowLines={true}
                                                                        showBorders={true}
                                                                        rowAlternationEnabled={true}
                                                                        allowColumnResizing={true}
                                                                        columnResizingMode='widget'
                                                                    >
                                                                        <Column dataField="id" width={30} caption=""
                                                                            alignment={'center'} allowFiltering={false} allowSorting={false}
                                                                            cellRender={renderDeleteDeductionGrid}
                                                                        />
                                                                        <Column dataField="deductionName" caption="Deduction" />
                                                                        <Column dataField="previousDeductionName" caption="Old Name" />
                                                                        <Column dataField="amount" caption="Amount $" />
                                                                        <SortByGroupSummaryInfo summaryItem="count" />
                                                                        <GroupPanel visible={false} />
                                                                        <HeaderFilter visible={false} />
                                                                        <SearchPanel visible={false}
                                                                            highlightCaseSensitive={false} width={240} placeholder="Search..." />
                                                                        <Grouping autoExpandAll={false} />
                                                                        <Paging defaultPageSize={20} />
                                                                        <Pager showPageSizeSelector={false} allowedPageSizes={[5, 10, 20]} showInfo={false} />
                                                                        <Export enabled={false} allowExportSelectedData={false} />
                                                                        <ColumnChooser enabled={false} />
                                                                        <ColumnFixing enabled={false} />
                                                                    </DataGrid>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                        <div className="card sub-rez-card">
                                                            <div className="card-header">
                                                                <h5 className="card-title m-0">Total / Summary </h5>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                        <div className="form-group">
                                                                            <label>Regular Charge </label>
                                                                            <input type="text" className="form-control form-control-sm" name="regularCharge"
                                                                                value={this.state.smryRideAmount !== '' ? '$' + this.state.smryRideAmount : ''}
                                                                                placeholder="$0.00" readOnly={true} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                        <div className="form-group">
                                                                            <label>Addons Charge </label>
                                                                            <input type="text" className="form-control form-control-sm" name="addonsCharge"
                                                                                value={this.state.smryAddonAmount !== '' ? '$' + this.state.smryAddonAmount : ''}
                                                                                placeholder="$0.00" readOnly={true} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                        <div className="form-group">
                                                                            <label>Credit Charge </label>
                                                                            <input type="text" className="form-control form-control-sm" name="creditCharge"
                                                                                value={this.state.smryCreditAmount !== '' ? '$' + this.state.smryCreditAmount : ''}
                                                                                placeholder="$0.00" readOnly={true} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                        <div className="form-group">
                                                                            <label>Deduction Charge </label>
                                                                            <input type="text" className="form-control form-control-sm" name="deductionCharge"
                                                                                value={this.state.smryDeductionAmount !== '' ? '$' + this.state.smryDeductionAmount : ''}
                                                                                placeholder="$0.00" readOnly={true} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                        <div className="form-group">
                                                                            <label>Net Due </label>
                                                                            <input type="text" className="form-control form-control-sm" name="netDue"
                                                                                value={this.state.smryTotalAmount !== '' ? '$' + this.state.smryTotalAmount : ''}
                                                                                placeholder="$0.00" readOnly={true} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <div className="card sub-rez-card">
                                                            <div className="card-header">
                                                                <h5 className="card-title m-0">Note</h5>
                                                            </div>
                                                            <div className="card-body mt-2">
                                                                <div className="row">
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                        <div className="form-group">
                                                                            <textarea className="form-control form-textbox form-control-sm" name="note" title="Note"
                                                                                value={this.state.note} disabled={this.state.isViewReadOnly}
                                                                                onChange={(e) => { this.setState({ note: e.target.value }) }}
                                                                                placeholder="Enter note" rows="2" maxLength="100" />
                                                                            {this.state.errors["note"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["note"]}</span>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                        <div className="form-group">
                                                                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleSubmitUpdatePayNotes}
                                                                                disabled={this.state.isViewReadOnly}>
                                                                                {this.state.isUpdateNoteSubmited && <span title="In Process">
                                                                                    <i className="fas fa-spinner fa-spin"></i> In Process</span>}
                                                                                {!this.state.isUpdateNoteSubmited && <span title="Update Note"><i className="fas fa-check"></i> Update Note</span>}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal ariaHideApp={false}
                        isOpen={this.state.isOpenAddonModal}
                        style={{ content: { width: '45%' } }}
                        className={"react-modal"}
                        shouldCloseOnOverlayClick={false}
                        contentLabel="Example Modal">
                        <form style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                            <div className="modal-header">
                                <h6 className="modal-title">{'Add New Addons'}</h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClosePendingPayAddonModal}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="widget-container">
                                            <DataGrid
                                                dataSource={this.state.pendingPayAddonGridDataSource}
                                                keyExpr="addonId"
                                                selection={{ mode: 'single' }}
                                                columnsAutoWidth={true}
                                                showColumnLines={true}
                                                showRowLines={true}
                                                showBorders={true}
                                                rowAlternationEnabled={true}
                                                allowColumnResizing={true}
                                                columnResizingMode='widget'
                                                repaintChangesOnly={true}
                                                onEditorPreparing={this.handleOnPendingPayAddonEditorPreparing}
                                            //onEditingStart={this.handleOnPendingPayAddonEditingStart}
                                            //onKeyDown={this.handleOnPendingPayAddonKeyDown}
                                            //onRowValidating={this.handleOnPendingPayAddonKeyDown}
                                            >
                                                <Editing
                                                    mode="row"
                                                    allowUpdating={true}
                                                    selectTextOnEditStart={true}
                                                    startEditAction={'click'}
                                                />
                                                <Column dataField="addonName" caption="Addon Name" allowEditing={false} allowFiltering={false} allowSorting={false} />
                                                <Column dataField="amount" caption="Amount $" dataType="float" alignment="right" width={125} allowEditing={true} allowUpdating={true} allowFiltering={false} allowSorting={false} cellRender={renderPendingPayAddonGridCell}>
                                                    <RequiredRule />
                                                    <PatternRule
                                                        message={'Amount must have valid format!'}
                                                        pattern={/^\d+(\.\d{2})?$/}
                                                    //pattern={/^\d*\.?\d*$/}
                                                    />
                                                </Column>
                                                <SortByGroupSummaryInfo summaryItem="count" />
                                                <GroupPanel visible={false} />
                                                <HeaderFilter visible={true} />
                                                <SearchPanel visible={false} highlightCaseSensitive={false} width={240} placeholder="Search..." />
                                                <Grouping autoExpandAll={true} />
                                                <Paging defaultPageSize={10} />
                                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true} />
                                                <Export enabled={false} allowExportSelectedData={false} />
                                                <ColumnChooser enabled={false} />
                                                <ColumnFixing enabled={false} />
                                            </DataGrid>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-success btn-sm" onClick={this.handleSubmitPayAddon}>
                                    {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                    {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                                </button>
                                <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleClosePendingPayAddonModal}><i className="fas fa-times"></i> Close</button>
                            </div>
                        </form>
                    </Modal>
                    <Modal ariaHideApp={false}
                        isOpen={this.state.isOpenDeductionModal}
                        style={{ content: { width: '45%' } }}
                        className={"react-modal"}
                        shouldCloseOnOverlayClick={false}
                        contentLabel="Example Modal">
                        <form style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                            <div className="modal-header">
                                <h6 className="modal-title">{'Add New Deductions'}</h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClosePendingPayDeductionModal}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="widget-container">
                                            <DataGrid
                                                dataSource={this.state.pendingPayDeductionGridDataSource}
                                                keyExpr="deductionId"
                                                selection={{ mode: 'single' }}
                                                columnsAutoWidth={true}
                                                showColumnLines={true}
                                                showRowLines={true}
                                                showBorders={true}
                                                rowAlternationEnabled={true}
                                                allowColumnResizing={true}
                                                columnResizingMode='widget'
                                                repaintChangesOnly={true}
                                                onEditorPreparing={this.handleOnPendingPayDeductionEditorPreparing}
                                            //onEditingStart={this.handleOnPendingPayAddonEditingStart}
                                            //onKeyDown={this.handleOnPendingPayAddonKeyDown}
                                            //onRowValidating={this.handleOnPendingPayAddonKeyDown}
                                            >
                                                <Editing
                                                    mode="row"
                                                    allowUpdating={true}
                                                    selectTextOnEditStart={true}
                                                    startEditAction={'click'}
                                                />
                                                <Column dataField="deductionName" caption="Deduction Name" allowEditing={false} allowFiltering={false} allowSorting={false} />
                                                <Column dataField="amount" caption="Amount $" dataType="float" alignment="right" width={125} allowEditing={true} allowUpdating={true} allowFiltering={false} allowSorting={false} cellRender={renderPendingPayDeductionGridCell}>
                                                    <RequiredRule />
                                                    <PatternRule
                                                        message={'Amount must have valid format!'}
                                                        pattern={/^\d+(\.\d{2})?$/}
                                                    //pattern={/^\d*\.?\d*$/}
                                                    />
                                                </Column>
                                                <SortByGroupSummaryInfo summaryItem="count" />
                                                <GroupPanel visible={false} />
                                                <HeaderFilter visible={true} />
                                                <SearchPanel visible={false} highlightCaseSensitive={false} width={240} placeholder="Search..." />
                                                <Grouping autoExpandAll={true} />
                                                <Paging defaultPageSize={10} />
                                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true} />
                                                <Export enabled={false} allowExportSelectedData={false} />
                                                <ColumnChooser enabled={false} />
                                                <ColumnFixing enabled={false} />
                                            </DataGrid>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-success btn-sm" onClick={this.handleSubmitPayDeduction}>
                                    {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                    {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                                </button>
                                <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleClosePendingPayDeductionModal}><i className="fas fa-times"></i> Close</button>
                            </div>
                        </form>
                    </Modal>
                    <Modal ariaHideApp={false}
                        isOpen={this.state.isOpenCreditModal}
                        style={{ content: { width: '45%' } }}
                        className={"react-modal"}
                        shouldCloseOnOverlayClick={false}
                        contentLabel="Example Modal">
                        <form style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                            <div className="modal-header">
                                <h6 className="modal-title">{'Vehicle Deduction Credit'}</h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClosePendingPayCreditModal}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <label htmlFor="" className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-form-label">Reimbursment Date : </label>
                                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8" style={{ display: this.state.isCreditCurrentDeductionPayroll || 'none' }} >
                                        <DateBox type="date" style={{ width: "100%" }} className="form-control form-control-sm" name="pcPayrollDate" placeholder="MM/dd/yyyy"
                                            disabled={true}
                                            //defaultValue={new Date()}
                                            value={(this.state.dropdownPayPeriodDate === null ? '' : this.state.dropdownPayPeriodDate.payDate)}
                                        />
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8" style={{ display: !this.state.isCreditCurrentDeductionPayroll || 'none' }}>
                                        {/*<Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="---Select Period---"
                                            defaultValue={[]}
                                            value={this.state.pcPayrollDate}
                                            name="pcPayrollDate"
                                            onChange={this.handleOnChangePcPayrollDate}
                                            getOptionLabel={option => option.viewDate}
                                            getOptionValue={option => option.payDate}
                                            options={this.state.dropdownPayrollPeriodList}
                                            isMulti={false}
                                            isClearable={true}
                                            isSearchable={true}
                                        />*/}
                                        <SelectBox
                                            dataSource={this.state.dropdownPayrollPeriodList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select period"
                                            name="pcPayrollDate"
                                            defaultValue={''}
                                            value={(this.state.pcPayrollDate !== null && this.state.pcPayrollDate.payDate !== null) ? this.state.pcPayrollDate.payDate : ''}
                                            displayExpr='viewDate'
                                            valueExpr='payDate'
                                            onSelectionChanged={this.handleOnChangePcPayrollDate}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["pcPayrollDate"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["pcPayrollDate"]}</span>}
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="widget-container">
                                            <DataGrid
                                                dataSource={this.state.pendingPayCreditGridDataSource}
                                                keyExpr="id"
                                                selection={{ mode: 'single' }}
                                                columnsAutoWidth={true}
                                                showColumnLines={true}
                                                showRowLines={true}
                                                showBorders={true}
                                                rowAlternationEnabled={true}
                                                allowColumnResizing={true}
                                                columnResizingMode='widget'
                                                repaintChangesOnly={true}
                                                onRowClick={this.handleOnPendingPayCreditRowClick}
                                            >
                                                <Column dataField="deductionName" caption="Deduction Name" allowEditing={false} allowFiltering={false} allowSorting={false} />
                                                <Column dataField="amount" caption="Amount $" dataType="float" alignment="right" width={125} allowEditing={true} allowUpdating={true} allowFiltering={false} allowSorting={false} cellRender={renderPendingPayCreditGridCell}>
                                                </Column>
                                                <SortByGroupSummaryInfo summaryItem="count" />
                                                <GroupPanel visible={false} />
                                                <HeaderFilter visible={true} />
                                                <SearchPanel visible={false} highlightCaseSensitive={false} width={240} placeholder="Search..." />
                                                <Grouping autoExpandAll={true} />
                                                <Paging defaultPageSize={10} />
                                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true} />
                                                <Export enabled={false} allowExportSelectedData={false} />
                                                <ColumnChooser enabled={false} />
                                                <ColumnFixing enabled={false} />
                                            </DataGrid>
                                        </div>
                                    </div>

                                    <label htmlFor="" className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-form-label">Deduction Name : </label>
                                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                        <input type="text" className="form-control form-textbox form-control-sm" name="pcDeductionName" title="Deduction Name" value={this.state.pcDeductionName}
                                            readOnly={true} />
                                        {this.state.errors["pcDeductionName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["pcDeductionName"]}</span>}
                                    </div>

                                    <label htmlFor="" className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-form-label">Deduction Amount : </label>
                                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                        <input type="text" className="form-control form-textbox form-control-sm" name="pcDeductionAmount" title="Deduction Amount" placeholder="$0.00"
                                            value={Number(this.state.pcDeductionAmount) === 0 ? '' : parseFloat(this.state.pcDeductionAmount).toFixed(2)} readOnly={true} />
                                        {this.state.errors["pcDeductionAmount"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["pcDeductionAmount"]}</span>}
                                    </div>

                                    <label htmlFor="" className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-form-label">Credit Amount : </label>
                                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                        <input type="text" className="form-control form-textbox form-control-sm" name="pcCreditAmount" title="Credit Amount" placeholder="$0.00"
                                            value={
                                                (Number(this.state.pcCreditAmount) === 0 ? ''
                                                    : this.state.pcCreditAmount)
                                            }
                                            onChange={this.handleInputChange}
                                            onBlur={this.handleOnInputLeave} />
                                        {this.state.errors["pcCreditAmount"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["pcCreditAmount"]}</span>}
                                    </div>

                                    <label htmlFor="" className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-form-label">Note (If Any) </label>
                                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                        <textarea className="form-control form-textbox form-control-sm" name="note" title="Note" placeholder="Note..." rows="2" maxLength="100" value={this.state.pcNote}
                                            onChange={(e) => { this.setState({ pcNote: e.target.value }) }} />
                                    </div>

                                    <label htmlFor="" className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-form-label">Recovery : </label>
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <div className="form-group">
                                            <span className="form-check">
                                                <input className="form-check-input" type="radio" name="recoverable" value="1" checked={this.state.pcRecoverable}
                                                    onChange={(e) => { this.setState({ pcRecoverable: e.target.checked, pcNonRcoverable: false }) }} />
                                                <label className="form-check-label">Recoverable</label>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <div className="form-group">
                                            <span className="form-check">
                                                <input className="form-check-input" type="radio" name="nonRecoverable" value="2" checked={this.state.pcNonRcoverable}
                                                    onChange={(e) => { this.setState({ pcNonRcoverable: e.target.checked, pcRecoverable: false }) }}
                                                />
                                                <label className="form-check-label">Non Recoverable</label>
                                            </span>
                                        </div>
                                    </div>
                                    <label htmlFor="" className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-form-label"></label>
                                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                        {this.state.errors["pcRecoveryOption"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["pcRecoveryOption"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-success btn-sm" onClick={this.handleSubmitPayCredit}>
                                    {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                    {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                                </button>
                                <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleClosePendingPayCreditModal}><i className="fas fa-times"></i> Close</button>
                            </div>
                        </form>
                    </Modal>
                    <VehiclePayLogHistoryComponent ref="vehiclePayLogHistoryComponent" />
                    <VehicleDeductionChargeComponent ref="vehicleDeductionChargeComponentForPayroll" viewPayDeducions={this.viewPayDeducions} viewPayDeducionPayId={this.state.payId} />
                    <VehicleDeductionChargeComponent ref="vehicleDeductionChargeComponentForCredit" handleSetRecoverVehicleDeductionId={this.handleSetRecoverVehicleDeductionId} />
                    <LoadPanel
                        id="LoadPanelId"
                        shadingColor="rgba(0,0,0,0.4)"
                        position={{ of: '#panelview' }}
                        visible={this.state.loadPanelVisible}
                        showIndicator={true}
                        shading={true}
                        showPane={true}
                        closeOnOutsideClick={false}
                    />
                </form>
            </div >
        );
    }
}