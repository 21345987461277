import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const notificationSettingList = [{
    'srNo': 1,
    'subject': 'New Booking',
    'displaySubject': 'New York',
    'emails': '10 Queens',
}, {
    'srNo': 2,
    'subject': 'New York',
    'displaySubject': 'New York',
    'emails': '10 Queens',
}, {
    'srNo': 3,
    'subject': 'New York',
    'displaySubject': 'New York',
    'emails': '10 Queens',
}]


export class NotificationSettingComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            customerDetails: props.customerDetails,
            rowId: 0,
            notificationSettingGridDataSource: null,
            templateTypeList: null,
            templateTypeId: 0,
            emailNotifications: "",
            isEnable: false,
            errors: {
                templateTypeId: 0,
                emailNotifications: "",
            }
        }
        this.handleNotificationSettingSubmit = this.handleNotificationSettingSubmit.bind(this);
        this.getnotificationSettingList = this.getnotificationSettingList.bind(this);
        this.getTemplateTypeList = this.getTemplateTypeList.bind(this);
    }
    componentDidMount = () => {
        this.getnotificationSettingList();
        this.getTemplateTypeList();
    }
    async getTemplateTypeList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('templateType-api/dropdownlist', requestParams);

        const data = await response.json();
        if (data !== null && data.templateType.length > 0) {
            this.setState({
                templateTypeList: data.templateType
            });
        }
    }
    handleInputChange = (event) => {        
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let errors = this.state.errors;
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        switch (name) {
            case 'templateTypeId':
                errors.templateTypeId = "";
                if (Number(event.target.value) === 0) {
                    this.setState({ templateTypeId: event.target.value })
                    errors.templateTypeId = "Please select template type.";
                }
                else {
                    this.setState({ templateTypeId: Number(event.target.value) })
                }
                break;
            case 'emailNotifications':
                errors.emailNotifications = "";
                if (event.target.value === "") {
                    this.setState({ emailNotifications: value })
                    errors.emailNotifications = "Please enter email.";
                }
                else {
                    let email = event.target.value.split(',');
                    if (email.length > 0) {
                        for (let i = 0; i < email.length; i++) {
                            if ((email[i]).trim() !== "") {
                                if (!regEmail.test((email[i]).trim())) {
                                    this.setState({ emailNotifications: event.target.value })
                                    errors.emailNotifications = "Please enter valid Email.";
                                } else {
                                    errors.emailNotifications = "";
                                    this.setState({ emailNotifications: event.target.value })
                                }
                            }
                        }
                    }
                }
                break;
            default:
                this.setState({
                    [name]: value
                });
                break;
        }
    }
    EditNotificationSettingControl = (e, data) => {
        this.setState({
            rowId: data.id,
            templateTypeId: data.templateId,
            emailNotifications: data.emails,
            isEnable: data.enable,
        })
    }
    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        errors.templateTypeId = 0;
        errors.emailNotifications = "";

        if (this.state.templateTypeId === 0) {
            formIsValid = false;
            errors.templateTypeId = "Please select template type.";
        }
        if (this.state.emailNotifications === "") {
            formIsValid = false;
            errors.emailNotifications = "Please enter email.";
        } else {
            let email = this.state.emailNotifications.split(',');
            if (email.length > 0) {
                for (let i = 0; i < email.length; i++) {
                    if ((email[i]).trim() !== "") {
                        if (!regEmail.test((email[i]).trim())) {
                            formIsValid = false;
                            errors.emailNotifications = "please enter valid Email.";
                        }
                    }
                }
            }
        }


        this.setState({ errors: errors });
        return formIsValid;
    }
    async handleNotificationSettingSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            this.setState({ isSubmited: true });

            var request = new Object();
            request.Id = this.state.rowId;
            request.TemplateId = this.state.templateTypeId;
            request.TempletSubject = "";
            request.TempletDisplaySubject = "";
            request.FreeText = "";
            request.Emails = this.state.emailNotifications;
            request.Enable = this.state.isEnable;
            request.AccountId = this.state.customerDetails.accountId;

            const requestParams = getRequestParams('POST', request);
            const response = await fetch(this.state.rowId > 0 ? 'template-notification-api/update-template-notifications' : 'template-notification-api/save-template-notifications', requestParams);
            const data = await response.json();
            Swal.fire({
                icon: data.isSuccess ? 'success' : 'error',
                title: data.isSuccess ? 'Success' : 'Oops...',
                text: data.resultMessage,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (data.isSuccess) {
                    if (result.isConfirmed) {
                        this.clearNotificationSettingModal();
                        this.getnotificationSettingList();
                    }
                } else {
                    this.setState({ isSubmited: false })
                }
            })
        }
        else {
            this.setState({ isSubmited: false })
        }
    }
    clearNotificationSettingModal = () => {
        this.setState({
            isSubmited: false,
            rowId: 0,
            templateTypeId: 0,
            emailNotifications: "",
            isEnable: false,
            errors: {
                templateTypeId: 0,
                emailNotifications: "",
            }
        });
    }
    async getnotificationSettingList() {

        const requestParams = getRequestParams('GET', '');
        const response = await fetch('template-notification-api/' + this.state.customerDetails.accountId + "/template-notifications", requestParams);
        const data = await response.json();
        this.setState({
            notificationSettingGridDataSource: data.templateNotifications,
        });
    }
    render() {
        const renderEditNotificationSettingCell = (data) => {
            return <div> <a title="Edit" className="fas fa-pen icon text-info" onClick={(e) => this.EditNotificationSettingControl(e, data.data)}></a> &nbsp;
                    </div>;
        }
        return (<div className="card card-default">
            <div className="card-header">
                <h3 className="card-title">Notification Setting</h3>
            </div>
            <div className="card-body">
                <form method="post" onSubmit={this.handleNotificationSettingSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <div className="form-group">
                                <label>Customer Name</label>
                                <input type="text" className="form-control form-control-sm" placeholder="Enter customer name" disabled="true" value={this.state.customerDetails.accountName} />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <div className="form-group">
                                <label>Location</label>
                                <input type="text" className="form-control form-control-sm" placeholder="Enter location" disabled="true" value="" />{ /*Require Login User Location*/}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div className="form-group">
                                <label>Template Type</label>
                                <select name="templateTypeId" className="form-control form-control-sm" value={this.state.templateTypeId} onChange={this.handleInputChange}>
                                    <option value={0}>Select template type</option>
                                    {
                                        this.state.templateTypeList &&
                                        this.state.templateTypeList.map((item, i) =>
                                            (<option key={i} value={item.id}>{item.name}</option>))
                                    }
                                </select>
                                {this.state.errors["templateTypeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["templateTypeId"]}</span>}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div className="form-group">
                                <br/>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="isEnable" name="isEnable" value={this.state.isEnable} checked={this.state.isEnable} onChange={this.handleInputChange} />
                                    <label className="form-check-label" htmlFor="isEnable"> Enable</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="form-group">
                                <label>Email Notifications</label>
                                <textarea className="form-control form-control-sm" rows="4" placeholder="Please enter comma seperated email." name="emailNotifications" value={this.state.emailNotifications} onChange={this.handleInputChange}></textarea>
                                {this.state.errors["emailNotifications"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["emailNotifications"]}</span>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  text-right form-group">
                            <button type="submit" className="btn btn-success btn-sm" onClick={this.handleNotificationSettingSubmit} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span><i className="fas fa-save"></i> {this.state.rowId > 0 ? "Update" : "Save"}</span>}
                            </button>&nbsp;&nbsp;
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.clearNotificationSettingModal} title="Clear"><i className="fas fa-times"></i> Clear</button>
                        </div>
                    </div>
                </form>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <DataGrid
                            dataSource={this.state.notificationSettingGridDataSource}
                            keyExpr="id"
                            selection={{ mode: 'single' }}
                            columnsAutoWidth={false}
                            showColumnLines={false}
                            showRowLines={true}
                            columnAutoWidth={true}
                            showBorders={true}
                            rowAlternationEnabled={true}
                            showBorders={true}>
                            <Column dataField="id" width={30} caption="" allowFiltering={false} allowSorting={false} cellRender={renderEditNotificationSettingCell} />
                            <Column dataField="templetSubject" caption="Subject" allowFiltering={true} allowSorting={true} />
                            <Column dataField="templetDisplaySubject" caption="Display Subject" allowFiltering={true} allowSorting={true} />
                            <Column dataField="emails" caption="Emails" allowFiltering={true} allowSorting={true} />
                            <SortByGroupSummaryInfo summaryItem="count" />
                            <GroupPanel visible={true} />
                            <HeaderFilter visible={true} />
                            <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                            <Grouping autoExpandAll={true} />
                            <Paging defaultPageSize={5} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                            <Export enabled={true} allowExportSelectedData={false} />
                            <ColumnChooser enabled={true} />
                            <ColumnFixing enabled={true} />
                        </DataGrid>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}