import React, { Component } from 'react';
import { LoadPanel } from 'devextreme-react/load-panel';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import { getRequestParams } from '../../helpers/globalfunctions.js';
import Swal from 'sweetalert2';
export class TicketCommentComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isSubmitted: false,
            isRefreshSubmitted: false,
            loadPanelVisible: false,
            requestId: (!this.props.location.state) ? '' : this.props.location.state.requestId,
            allowReply: (!this.props.location.state) ? false : this.props.location.state.allowReply,
            ticket: [],
            comments: [],
        }
    }
    async componentDidMount() {
        await this.fetchApiData();
    }
    async fetchApiData() {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getTicketComments();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    getTicketComments = async () => {
        try {
            if (this.state.requestId === null || this.state.requestId === '') {
                await this.onfailure('error', 'Invalid request', null);
            }
            else {
                const requestParams = getRequestParams('GET');
                const response = await fetch('ticketing-api/comments/' + this.state.requestId, requestParams);
                if (response.ok) {
                    const responseData = await response.json();
                    if (responseData.isSuccess) {
                        this.setState({ ticket: responseData.ticket, comments: responseData.comments });
                    }
                    else {
                        await this.onfailure('error', null, responseData.resultMessage);
                    }
                }
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnRefresh = async () => {
        try {
            this.setState({ isRefreshSubmitted: true, loadPanelVisible: true });
            this.setState({
                isSubmitted: false,
            });
            await this.fetchApiData();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isRefreshSubmitted: false, loadPanelVisible: false });
    }
    onfailure = async (icon, title, resultMessage) => {
        this.setState({ loadPanelVisible: false });
        await Swal.fire({
            allowOutsideClick: false,
            showCloseButton: false,
            icon: icon,
            title: (title === null || title.trim() === '') ? 'Oops...' : title,
            html: (resultMessage === null || resultMessage.trim() === '') ? 'Opps! something went wrong, unable to process the request.' : resultMessage.trim(),
        })
    }
    render() {
        return (
            <div>
                <form method="post" onSubmit={this.handleOnGenerateSubmit}
                    style={(this.state.isSubmitted || this.state.isRefreshSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="content-wrapper">
                        <div className="content-header">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                        <h1 className="m-0 text-dark">Ticket Comment View </h1>
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                                        <ol className="breadcrumb float-sm-right">
                                            <NavItem>
                                                <NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="#" onClick={this.handleOnRefresh}>
                                                    {this.state.isRefreshSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                                    {!this.state.isRefreshSubmitted && <span title="Refresh"><i className="fas fa-sync-alt"></i> Refresh</span>}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to="/ticketing/tickets" className="btn btn-block btn-outline-secondary btn-sm" title="Ticket">
                                                    <i className="fas fa-arrow-left"></i> Ticket</NavLink>
                                            </NavItem>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="card mt-3" style={{ width: "75%", margin: "0 auto" }}>
                                <div className="card-body">
                                    <div className="row" style={{ padding: '10px 0', backgroundColor: '#062E69', marginBottom: '15px' }}>
                                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <div className="form-group">
                                                <h4 style={{ fontWeight: 'normal', fontSize: '20px', color: '#ffffff' }}>Ticket #{this.state.ticket.ticketNo}</h4>
                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8 text-right" >
                                            <div className="form-group">
                                                <span className="badge" style={{ color: '#ffffff', fontWeight: 'normal', fontSize: '20px', marginRight: '15px' }}>Status: In Progress</span>
                                                <button type="reset" className="close" aria-label="Close"><span aria-hidden="true">×</span></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" >
                                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <label>Name: <span style={{ fontWeight: 'normal', fontSize: '20px' }}>{this.state.ticket.userFullName}</span></label>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <label>Phone: <span style={{ fontWeight: 'normal', fontSize: '20px' }}>{this.state.ticket.userPhone}</span></label>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <label>Email: <span style={{ fontWeight: 'normal', fontSize: '20px' }}>{this.state.ticket.userEmail}</span></label>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <label>Priority: <span style={{ fontWeight: 'normal', fontSize: '20px' }}>{this.state.ticket.priority}</span></label>
                                        </div>
                                        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <label>Help Topic: <span style={{ fontWeight: 'normal', fontSize: '20px' }}>{this.state.ticket.helpTopic}</span></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        )
    }
}