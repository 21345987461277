import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { LoadPanel } from 'devextreme-react/load-panel';

const position = { of: '#bookingdiv' };
export class FinalListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            loadPanelVisible:false,
            uploadedScheduleData: props.location.state !== null || props.location.state.length > 0 ? props.location.state : null,
            finalTriplist: [],
            formatlist: [],
            finalDataArray: [],

            h1: "",
            h2: "",
            h3: "",
            h4: "",
            h5: "",
            h6: "",
            h7: "",
            h8: "",
            h9: "",
            h10: "",
            h11: "",
            h12: "",
            h13: "",
            h14: "",
            h15: "",
            h16: "",
            h17: "",
            h18: "",
            h19: "",
            h20: "",
            h21: "",
            h22: "",
            h23: "",
            h24: "",
            h25: "",
            h26: "",
            h27: "",
            h28: "",
            h29: "",
            h20: "",
            h31: "",
            h32: "",
            h33: "",
            h34: "",
            h35: "",
            h36: "",
            h37: "",
            h38: "",
            h39: "",
            h40: "",
        }
        this.handleScheduleDataSubmit = this.handleScheduleDataSubmit.bind(this);
        this.getPdfAndExcelData = this.getPdfAndExcelData.bind(this);
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getPdfAndExcelData.bind(this)
            }
        });
    }

    async componentDidMount(){
        this.setState({
            loadPanelVisible: true
        });
        await this.getPdfAndExcelData();
        this.setState({
            loadPanelVisible: false
        });
    }
    async getPdfAndExcelData() {
        var newObject = [];
        newObject = this.state.uploadedScheduleData;
        const requestParams = getRequestParams('POST', newObject);
        const response = await fetch('uploadSchedule-api/getGridFinalReservationStep', requestParams);
        const data = await response.json();
        if (data !== null) {
            this.setState({
                finalTriplist: data.finalTriplist,
                formatlist: data.formatlist
            })
        
            let dynamicObject = []
           
            if (data.formatlist.length > 0) {
                for (var i = 0; i < data.formatlist.length; i++) {
                    var startDate = new Date(data.formatlist[0][0]);

                    let obj = new Object();
                    obj.startDate = data.formatlist[i][0];
                    obj.endDate = data.formatlist[i][1];
                    obj.airlineCode = data.formatlist[i][2];
                    obj.flightNumber = data.formatlist[i][3];
                    obj.flightDateTime = data.formatlist[i][4];
                    obj.pickupDateTime = data.formatlist[i][5];
                    obj.pickup = data.formatlist[i][6];
                    obj.dropoff = data.formatlist[i][7];

                    var dateLength = data.formatlist[i].length - 8;
                    if (dateLength > 0) {
                        obj.d1 = data.formatlist[i][8].length == undefined ? "" : data.formatlist[i][8];
                        dateLength = dateLength - 1;
                        this.setState({ h1: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d2 = data.formatlist[i][9].length == undefined ? "" : data.formatlist[i][9];
                        dateLength = dateLength - 1;
                        this.setState({ h2: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d3 = data.formatlist[i][10].length == undefined ? "" : data.formatlist[i][10];
                        dateLength = dateLength - 1;
                        this.setState({ h3: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d4 = data.formatlist[i][11].length == undefined ? "" : data.formatlist[i][11];
                        dateLength = dateLength - 1;
                        this.setState({ h4: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d5 = data.formatlist[i][12].length == undefined ? "" : data.formatlist[i][12];
                        dateLength = dateLength - 1;
                        this.setState({ h5: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d6 = data.formatlist[i][13].length == undefined ? "" : data.formatlist[i][13];
                        dateLength = dateLength - 1;
                        this.setState({ h6: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d7 = data.formatlist[i][14].length == undefined ? "" : data.formatlist[i][14];
                        dateLength = dateLength - 1;
                        this.setState({ h7: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d8 = data.formatlist[i][15].length == undefined ? "" : data.formatlist[i][15];
                        dateLength = dateLength - 1;
                        this.setState({ h8: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d9 = data.formatlist[i][16].length == undefined ? "" : data.formatlist[i][16];
                        dateLength = dateLength - 1;
                        this.setState({ h9: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d10 = data.formatlist[i][17].length == undefined ? "" : data.formatlist[i][17];
                        dateLength = dateLength - 1;
                        this.setState({ h10: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d11 = data.formatlist[i][18].length == undefined ? "" : data.formatlist[i][18];
                        dateLength = dateLength - 1;
                        this.setState({ h11: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d12 = data.formatlist[i][19].length == undefined ? "" : data.formatlist[i][19];
                        dateLength = dateLength - 1;
                        this.setState({ h12: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d13 = data.formatlist[i][20].length == undefined ? "" : data.formatlist[i][20];
                        dateLength = dateLength - 1;
                        this.setState({ h13: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d14 = data.formatlist[i][21].length == undefined ? "" : data.formatlist[i][21];
                        dateLength = dateLength - 1;
                        this.setState({ h14: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d15 = data.formatlist[i][22].length == undefined ? "" : data.formatlist[i][22];
                        dateLength = dateLength - 1;
                        this.setState({ h15: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d16 = data.formatlist[i][23].length == undefined ? "" : data.formatlist[i][23];
                        dateLength = dateLength - 1;
                        this.setState({ h16: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d17 = data.formatlist[i][24].length == undefined ? "" : data.formatlist[i][24];
                        dateLength = dateLength - 1;
                        this.setState({ h17: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d18 = data.formatlist[i][25].length == undefined ? "" : data.formatlist[i][25];
                        dateLength = dateLength - 1;
                        this.setState({ h18: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d19 = data.formatlist[i][26].length == undefined ? "" : data.formatlist[i][26];
                        dateLength = dateLength - 1;
                        this.setState({ h19: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d20 = data.formatlist[i][27].length == undefined ? "" : data.formatlist[i][27];
                        dateLength = dateLength - 1;
                        this.setState({ h20: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d21 = data.formatlist[i][28].length == undefined ? "" : data.formatlist[i][28];
                        dateLength = dateLength - 1;
                        this.setState({ h21: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d22 = data.formatlist[i][29].length == undefined ? "" : data.formatlist[i][29];
                        dateLength = dateLength - 1;
                        this.setState({ h22: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d23 = data.formatlist[i][30].length == undefined ? "" : data.formatlist[i][30];
                        dateLength = dateLength - 1;
                        this.setState({ h23: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d24 = data.formatlist[i][31].length == undefined ? "" : data.formatlist[i][31];
                        dateLength = dateLength - 1;
                        this.setState({ h24: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d25 = data.formatlist[i][32].length == undefined ? "" : data.formatlist[i][32];
                        dateLength = dateLength - 1;
                        this.setState({ h25: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d26 = data.formatlist[i][33].length == undefined ? "" : data.formatlist[i][33];
                        dateLength = dateLength - 1;
                        this.setState({ h26: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d27 = data.formatlist[i][34].length == undefined ? "" : data.formatlist[i][34];
                        dateLength = dateLength - 1;
                        this.setState({ h27: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d28 = data.formatlist[i][35].length == undefined ? "" : data.formatlist[i][35];
                        dateLength = dateLength - 1;
                        this.setState({ h28: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d29 = data.formatlist[i][36].length == undefined ? "" : data.formatlist[i][36];
                        dateLength = dateLength - 1;
                        this.setState({ h29: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d30 = data.formatlist[i][37].length == undefined ? "" : data.formatlist[i][37];
                        dateLength = dateLength - 1;
                        this.setState({ h30: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d31 = data.formatlist[i][38].length == undefined ? "" : data.formatlist[i][38];
                        dateLength = dateLength - 1;
                        this.setState({ h31: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d32 = data.formatlist[i][39].length == undefined ? "" : data.formatlist[i][39];
                        dateLength = dateLength - 1;
                        this.setState({ h32: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d33 = data.formatlist[i][40].length == undefined ? "" : data.formatlist[i][40];
                        dateLength = dateLength - 1;
                        this.setState({ h33: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d34 = data.formatlist[i][41].length == undefined ? "" : data.formatlist[i][41];
                        dateLength = dateLength - 1;
                        this.setState({ h34: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d35 = data.formatlist[i][42].length == undefined ? "" : data.formatlist[i][42];
                        dateLength = dateLength - 1;
                        this.setState({ h35: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d36 = data.formatlist[i][43].length == undefined ? "" : data.formatlist[i][43];
                        dateLength = dateLength - 1;
                        this.setState({ h36: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d37 = data.formatlist[i][44].length == undefined ? "" : data.formatlist[i][44];
                        dateLength = dateLength - 1;
                        this.setState({ h37: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d38 = data.formatlist[i][45].length == undefined ? "" : data.formatlist[i][45];
                        dateLength = dateLength - 1;
                        this.setState({ h38: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d39 = data.formatlist[i][46].length == undefined ? "" : data.formatlist[i][46];
                        dateLength = dateLength - 1;
                        this.setState({ h39: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    if (dateLength > 0) {
                        obj.d40 = data.formatlist[i][47].length == undefined ? "" : data.formatlist[i][47];
                        dateLength = dateLength - 1;
                        this.setState({ h40: parseInt(startDate.getDate()) < 10 ? "0" + startDate.getDate().toString() : startDate.getDate().toString() });
                        startDate = new Date(startDate.setDate(startDate.getDate() + 1));
                    }
                    //for (var j = 8; j < data.formatlist[i].length; j++) {
                    //    obj.dropoff + "_" + j.toString() = data.formatlist[i][j].length != undefined ? "" : data.formatlist[i][j];
                    //}

                    dynamicObject.push(obj);
                }
            }

            this.setState({ finalDataArray: dynamicObject });

        }
    }
    
    handleNewScheduleSubmit = () => {
        this.props.history.push({
            pathname: '/uploadschedule',
        })
    }

    async askScheduleConfirm() {
        let isResult = false;
        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: "Please let confirm your scheudle!<br> Do you want to still continue?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    async handleScheduleDataSubmit(e) {
        e.preventDefault();
        const isConfirm = await this.askScheduleConfirm();
        if (!isConfirm) {
            return;
        }
        var newObject = [];
        newObject = this.state.finalTriplist;
        const requestParams = getRequestParams('POST', newObject);
        const response = await fetch('uploadSchedule-api/upload-schedule', requestParams);
        const data = await response.json();

        Swal.fire({
            icon: data.isSuccess ? 'success' : 'error',
            title: data.isSuccess ? 'Success' : 'Oops...',
            html: data.resultMessage,
            width: "25%",
            allowOutsideClick: false
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (data.isSuccess) {
                if (result.isConfirmed) {
                    this.setState({ isSubmited: false });
                    this.props.history.push({
                        pathname: '/uploadschedule',
                    });
                }
            } else {
                this.setState({ isSubmited: false });
            }
        })
    }
    render() {
       
        return (
            <div className="content-wrapper" id="bookingdiv">
            <div className="content-header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                            <h1 className="m-0 text-dark">Upload Schedule</h1>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 text-right">
                            <div className="form-group">
                               
                                <button type="submit" className="btn btn-success btn-sm" onClick={this.handleScheduleDataSubmit} >
                                        {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                        {!this.state.isSubmited && <span title="Save"><i className="fas fa-save"></i> Save</span>}
                                </button> &nbsp; &nbsp;
                                <button type="submit" className="btn btn-custom-gray btn-sm" onClick={this.handleNewScheduleSubmit} >
                                    New Schedule
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="card card-grid">
                    <div className="card-body">

                        <DataGrid
                            dataSource={this.state.finalDataArray}
                            keyExpr="startDate"
                            selection={{ mode: 'single' }}
                            columnsAutoWidth={false}
                            showColumnLines={true}
                            showRowLines={true}
                            showBorders={true}
                            rowAlternationEnabled={true}
                            showBorders={true}
                            allowColumnResizing={true}
                            columnResizingMode='widget'
                            width="100%"
                            onToolbarPreparing={this.onToolbarPreparing}>
                            <Column dataField="startDate" caption="Start Date" width={100} allowFiltering={false} allowSorting={false} />
                            <Column dataField="endDate" caption="End Date" width={100} allowFiltering={false} allowSorting={false} />
                            <Column dataField="airlineCode" caption="Airline Code" width={80} allowFiltering={true} allowSorting={true} />
                            <Column dataField="flightNumber" caption="Flight Number" width={80} allowFiltering={true} allowSorting={true} />
                            <Column dataField="flightDateTime" caption="Flight Time" width={80} allowFiltering={true} allowSorting={true} />
                            <Column dataField="pickupDateTime" caption="Pickup Time" width={80} allowFiltering={true} allowSorting={true} />
                            <Column dataField="pickup" caption="Pickup Location" width={150} allowFiltering={true} allowSorting={false} />
                            <Column dataField="dropoff" caption="Dropoff Location" width={150} allowFiltering={true} allowSorting={false} />
                            {this.state.h1 !== "" && <Column dataField="d1" caption={this.state.h1} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h2 !== "" && <Column dataField="d2" caption={this.state.h2} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h3 !== "" && <Column dataField="d3" caption={this.state.h3} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h4 !== "" && <Column dataField="d4" caption={this.state.h4} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h5 !== "" && <Column dataField="d5" caption={this.state.h5} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h6 !== "" && <Column dataField="d6" caption={this.state.h6} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h7 !== "" && <Column dataField="d7" caption={this.state.h7} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h8 !== "" && <Column dataField="d8" caption={this.state.h8} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h9 !== "" && <Column dataField="d9" caption={this.state.h9} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h10 !== "" && <Column dataField="d10" caption={this.state.h10} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h11 !== "" && <Column dataField="d11" caption={this.state.h11} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h12 !== "" && <Column dataField="d12" caption={this.state.h12} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h13 !== "" && <Column dataField="d13" caption={this.state.h13} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h14 !== "" && <Column dataField="d14" caption={this.state.h14} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h15 !== "" && <Column dataField="d15" caption={this.state.h15} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h16 !== "" && <Column dataField="d16" caption={this.state.h16} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h17 !== "" && <Column dataField="d17" caption={this.state.h17} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h18 !== "" && <Column dataField="d18" caption={this.state.h18} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h19 !== "" && <Column dataField="d19" caption={this.state.h19} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h20 !== "" && <Column dataField="d20" caption={this.state.h20} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h21 !== "" && <Column dataField="d21" caption={this.state.h21} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h22 !== "" && <Column dataField="d22" caption={this.state.h22} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h23 !== "" && <Column dataField="d23" caption={this.state.h23} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h24 !== "" && <Column dataField="d24" caption={this.state.h24} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h25 !== "" && <Column dataField="d25" caption={this.state.h25} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h26 !== "" && <Column dataField="d26" caption={this.state.h26} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h27 !== "" && <Column dataField="d27" caption={this.state.h27} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h28 !== "" && <Column dataField="d28" caption={this.state.h28} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h29 !== "" && <Column dataField="d29" caption={this.state.h29} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h30 !== "" && <Column dataField="d30" caption={this.state.h30} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h31 !== "" && <Column dataField="d31" caption={this.state.h31} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h32 !== "" && <Column dataField="d32" caption={this.state.h32} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h33 !== "" && <Column dataField="d33" caption={this.state.h33} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h34 !== "" && <Column dataField="d34" caption={this.state.h34} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h35 !== "" && <Column dataField="d35" caption={this.state.h35} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h36 !== "" && <Column dataField="d36" caption={this.state.h36} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h37 !== "" && <Column dataField="d37" caption={this.state.h37} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h38 !== "" && <Column dataField="d38" caption={this.state.h38} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h39 !== "" && <Column dataField="d39" caption={this.state.h39} width={35} allowFiltering={false} allowSorting={false} />}
                            {this.state.h40 !== "" && <Column dataField="d40" caption={this.state.h40} width={35} allowFiltering={false} allowSorting={false} />}

                            <SortByGroupSummaryInfo summaryItem="count" />
                            <GroupPanel visible={true} />
                            <HeaderFilter visible={true} />
                            <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                            <Grouping autoExpandAll={true} />
                            <Paging defaultPageSize={50} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                            <Export enabled={true} allowExportSelectedData={false} />
                            <ColumnChooser enabled={true} />
                            <ColumnFixing enabled={true} />

                        </DataGrid>
                        
                    </div>
                </div>
                </div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
        </div >)
    }
}
