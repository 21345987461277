import DataGrid, {
    Column,
    FilterRow, Paging, Scrolling, Selection
} from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import DateBox from 'devextreme-react/date-box';
import DropDownBox from 'devextreme-react/drop-down-box';
import HtmlEditor, { Item, Toolbar } from 'devextreme-react/html-editor';
import { LoadPanel } from 'devextreme-react/load-panel';
import { SelectBox } from 'devextreme-react/select-box';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import moment from 'moment/min/moment-with-locales'; //npm install --save moment react-moment
import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { getRequestParams } from '../../helpers/globalfunctions';
import { SendEmailComponent } from '../Shared/SendEmailComponent';

const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
const fontValues = ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'];

let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class InvoiceDueSummaryReportComponent extends Component {
    constructor(props) {
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        super(props);
        this.state = {
            isSubmittedPdf: false,
            isSubmittedExcel: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            isAllCustomer: true,
            isCustomerTypeOnly: false,
            isCustomerOnly: false,
            customerTypeDropdownList: [],
            customerTypeDropdownData: null,
            customerTypeId: 0,
            customerDropdownList: [],
            customerDropdownData: [],
            fromDate: moment(currentTime).format("MM/DD/YYYY"),
            toDate: moment(currentTime).format("MM/DD/YYYY"),
            isSignature: false,
            signatureData: '',
            signature: '',
            isComments: false,
            comments: '',
            errors: {
                customerType: '',
                customer: '',
                fromDate: '',
                toDate: '',
            }
        }

        this.onSelectionCustomerChanged = this.onSelectionCustomerChanged.bind(this);
        this.syncDataGridCustomerSelection = this.syncDataGridCustomerSelection.bind(this);
        this.dataGridCustomerRender = this.dataGridCustomerRender.bind(this);
    }
    getCustomerTypeDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('customer-type-api/', requestParams);
            const data = await response.json();
            this.setState({
                customerTypeDropdownList: data.customerType
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    getCustomerDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('account-api/customer-dropdown/', requestParams);
            const data = await response.json();
            this.setState({
                customerDropdownList: data.customers,
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    getEmailSignatureData = async () => {
        try {
            let request = new Object();
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('report-api/get-user-signature/', requestParams);
            const data = await response.json();
            this.setState({
                signatureData: data.htmlAddress
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getCustomerTypeDropdownList();
            await this.getCustomerDropdownList();
            await this.getEmailSignatureData();
            if (this.state.signatureData === null || this.state.signatureData.trim() === '') {
                this.setState({ signature: ''});
            }
            else {
            }
            this.setState({ isSignature: (this.state.signatureData !== null && this.state.signatureData.trim().length !== 0) || false });
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnInputChange = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
            let errors = this.state.errors;
            errors.customerType = '';
            errors.customer = '';
            switch (name) {
                case 'isAllCustomer':
                    this.setState({
                        isAllCustomer: value,
                        isCustomerTypeOnly: false,
                        isCustomerOnly: false,
                        customerTypeDropdownData: null,
                        customerTypeId: 0,
                        customerDropdownData: [],
                        signature: '',
                        isSignature: false,
                        isComments: false,
                        comments: '',
                    });
                    break;
                case 'isCustomerTypeOnly':
                    this.setState({
                        isAllCustomer: false,
                        isCustomerTypeOnly: value,
                        isCustomerOnly: false,
                        customerTypeDropdownData: null,
                        customerTypeId: 0,
                        customerDropdownData: [],
                        signature: '',
                        isSignature: false,
                        isComments: false,
                        comments: '',
                    });
                    break;
                case 'isCustomerOnly':
                    this.setState({
                        isAllCustomer: false,
                        isCustomerTypeOnly: false,
                        isCustomerOnly: value,
                        customerTypeDropdownData: null,
                        customerTypeId: 0,
                        customerDropdownData: [],
                        signature: (this.state.signatureData !== null && this.state.signatureData.trim().length !== 0) ? this.state.signatureData : '',
                        isSignature: (this.state.signatureData !== null && this.state.signatureData.trim().length !== 0) || false,
                        isComments: false,
                        comments: '',
                    });
                    break;
                case 'isSignature':
                    if (!value) {
                        this.setState({ signature: '' });
                    }
                    else {
                        this.setState({
                            signature: (this.state.signatureData !== null && this.state.signatureData.trim().length !== 0) ? this.state.signatureData : ''
                        });
                    }
                    this.setState({ isSignature: value });
                    break;
                case 'isComments':
                    if (!value) {
                        this.setState({ comments: '' });
                    }
                    else {
                        this.commentsInputValidator.focus();
                    }
                    this.setState({ isComments: value });
                    break;
                case 'comments':
                    this.setState({ comments: value });
                    break;
                default:
                    break;
            }
            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnCustomerTypeDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.customerType = '';
            let customerTypeId = 0;
            if (data.selectedItem !== null) {
                customerTypeId = (data.selectedItem !== null ? data.selectedItem.id : 0);
            }
            else {
                //errors.customerType = 'Please select customer type.';
            }
            this.setState({ customerTypeDropdownData: data.selectedItem, customerTypeId: customerTypeId, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnCustomerDropdownChange = async (data) => {
        
    }
    handleOnFromDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.fromDate = '';
            let fromDate = '';
            if (event.value !== null && event.value !== '') {
                fromDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.fromDate = 'Please select date.';
            }
            if (this.state.toDate.trim() !== '') {
                errors.toDate = '';
            }
            this.setState({ fromDate: fromDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnToDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.toDate = '';
            let toDate = '';
            if (event.value !== null && event.value !== '') {
                toDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.toDate = 'Please select date.';
            }
            if (this.state.fromDate.trim() !== '') {
                errors.fromDate = '';
            }
            this.setState({ toDate: toDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnSignatureChange = async (e) => {
        try {
            this.setState({ signature: e.value });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmittedPdf: false,
                isSubmittedExcel: false,
                isEmailSubmitted: false,
                isOpenModal: false,
                loadPanelVisible: false,
                isAllCustomer: true,
                isCustomerTypeOnly: false,
                isCustomerOnly: false,
                customerTypeDropdownList: [],
                customerTypeDropdownData: null,
                customerTypeId: 0,
                customerDropdownList: [],
                customerDropdownData: [],
                fromDate: moment(currentTime).format("MM/DD/YYYY"),
                toDate: moment(currentTime).format("MM/DD/YYYY"),
                isSignature: false,
                signatureData: '',
                signature: '',
                isComments: false,
                comments: '',
                errors: {
                    customerType: '',
                    customer: '',
                    fromDate: '',
                    toDate: '',
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnClearSearch = async () => {
        try {
            currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
            this.setState({
                isSubmittedPdf: false,
                isSubmittedExcel: false,
                isEmailSubmitted: false,
                loadPanelVisible: false,
                isAllCustomer: true,
                isCustomerTypeOnly: false,
                isCustomerOnly: false,
                customerTypeDropdownData: null,
                customerTypeId: 0,
                customerDropdownData: [],
                fromDate: moment(currentTime).format("MM/DD/YYYY"),
                toDate: moment(currentTime).format("MM/DD/YYYY"),
                isSignature: false,
                signature: '',
                isComments: false,
                comments: '',
                errors: {
                    customerType: '',
                    customer: '',
                    fromDate: '',
                    toDate: '',
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnReportSubmit = async (event, fileFormat) => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                if (fileFormat === 'xlsx') {
                    this.setState({ isSubmittedExcel: true });
                }
                if (fileFormat === 'pdf') {
                    this.setState({ isSubmittedPdf: true });
                }
                let methodName = '';
                let customerCodes = '';
                let fromDate = '';
                let toDate = '';
                if (this.state.isAllCustomer === true || this.state.isCustomerTypeOnly === true) {
                    methodName = 'GetInvoiceDueSummary';
                }
                if (this.state.isCustomerOnly === true) {
                    methodName = 'GetInvoiceDueDetails';
                }
                if (this.state.isCustomerOnly === true) {
                    if (this.state.customerDropdownData !== null) {
                        if (Object.keys(this.state.customerDropdownData).length > 0) {
                            for (var i = 0; i < this.state.customerDropdownData.length; i++) {
                                customerCodes += customerCodes === '' ? this.state.customerDropdownData[i] : ',' + this.state.customerDropdownData[i]
                            }
                        }
                    }
                }
                if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                    fromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00';
                    toDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59';
                }
                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.CustomerTypeId = Number(this.state.customerTypeId);
                request.AccountCode = customerCodes;
                request.StartDate = fromDate;
                request.EndDate = toDate;
                if (this.state.isCustomerOnly === true && this.state.isSignature === true) {
                    request.Criteria1 = this.state.signature.toString('html');
                }
                request.Criteria2 = this.state.comments.trim();
                request.fileFormat = fileFormat;
                request.MethodName = methodName
                request.SelectionCrieteria = await this.setSelectionCrieteria();
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                let a = document.createElement('a');
                a.href = dataResponse.reportBase64String;
                a.download = ((this.state.isCustomerOnly) ? 'InvoiceDueDetails' : 'InvoiceDueSummary') + '.' + fileFormat;
                a.click();
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmittedPdf: false, isSubmittedExcel: false });
    }
    isFormValid = async () => {
        let isValid = true;
        try {
            let errors = this.state.errors;
            errors.customerType = '';
            errors.customer = '';
            if (this.state.isAllCustomer === false) {
                if (this.state.isCustomerTypeOnly === true) {
                    if (Number(this.state.customerTypeId) === 0) {
                        errors.customerType = 'Please select customer type.';
                        isValid = false;
                    }
                }
                if (this.state.isCustomerOnly === true) {
                    if (this.state.customerDropdownData === null || this.state.customerDropdownData.length === 0) {
                        errors.customer = 'Please select customer.';
                        isValid = false;
                    }
                }
            }
            if (this.state.fromDate.trim() === '') {
                errors.fromDate = 'Please select date.';
                isValid = false;
            }
            if (this.state.toDate.trim() === '') {
                errors.toDate = 'Please select date.';
                isValid = false;
            }
            if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                if (moment(this.state.fromDate, 'MM/DD/YYYY').isAfter(this.state.toDate, 'MM/DD/YYYY')) {
                    errors.fromDate = 'Please enter a valid date range.';
                    errors.toDate = 'Please enter a valid date range.';
                    isValid = false;
                }
            }
            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    setSelectionCrieteria = async () => {
        let selectionCrieteria = '';
        try {
            if (this.state.isAllCustomer === true) {
                selectionCrieteria += 'Account type wise invoiced due summary';
            }
            if (this.state.isCustomerTypeOnly === true) {
                if (Number(this.state.customerTypeId) > 0) {
                    if (this.state.customerTypeDropdownData !== null && Object.keys(this.state.customerTypeDropdownData).length > 0) {
                        selectionCrieteria += 'Account wise invoiced due summary for customer type - ' + this.state.customerTypeDropdownData.name;
                    }
                }
                else {
                    selectionCrieteria += 'Account wise invoiced due summary for all customer type';
                }
            }
            if (this.state.isCustomerOnly === true) {
                if (this.state.customerDropdownData === null || this.state.customerDropdownData.length === 0) {
                    selectionCrieteria += 'Invoice due details for all customer';
                }
                else {
                    selectionCrieteria += 'Invoice due details for customer - [ ';
                    for (var i = 0; i < this.state.customerDropdownData.length; i++) {
                        selectionCrieteria += this.state.customerDropdownData[i];
                        if (i < this.state.customerDropdownData.length - 1) {
                            selectionCrieteria += ', ';
                        }
                    }
                    selectionCrieteria += ' ]';
                }
            }
            if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                selectionCrieteria += ', period from  - ' + this.state.fromDate + ' to ' + this.state.toDate;
            }
        } catch (ex) {
            selectionCrieteria = '';
            console.log(ex);
        }
        return selectionCrieteria;
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isEmailSubmitted: true });
                let methodName = '';
                let customerCodes = '';
                let fromDate = '';
                let toDate = '';
                if (this.state.isAllCustomer === true || this.state.isCustomerTypeOnly === true) {
                    methodName = 'GetInvoiceDueSummary';
                }
                if (this.state.isCustomerOnly === true) {
                    methodName = 'GetInvoiceDueDetails';
                }
                if (this.state.isCustomerOnly === true) {
                    if (this.state.customerDropdownData !== null) {
                        if (Object.keys(this.state.customerDropdownData).length > 0) {
                            for (var i = 0; i < this.state.customerDropdownData.length; i++) {
                                customerCodes += customerCodes === '' ? this.state.customerDropdownData[i] : ',' + this.state.customerDropdownData[i]
                            }
                        }
                    }
                }
                if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                    fromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00';
                    toDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59';
                }
                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.CustomerTypeId = Number(this.state.customerTypeId);
                request.AccountCode = customerCodes;
                request.StartDate = fromDate;
                request.EndDate = toDate;
                request.Criteria1 = this.state.signature.toString('html');
                request.Criteria2 = this.state.comments.trim();
                request.fileFormat = 'pdf';
                request.MethodName = methodName
                request.SelectionCrieteria = await this.setSelectionCrieteria();
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '' && isNaN(fileData) === false) {
                    let emailSubject = (this.state.isCustomerOnly) ? 'Invoice Due Details Report' : 'Invoice Due Summary Report';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = (this.state.isCustomerOnly) ? 'InvoiceDueDetails.pdf' : 'InvoiceDueSummary.pdf';
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '55%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form
                        style={(this.state.isSubmittedExcel || this.state.isSubmittedPdf || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Invoice Due Summary Report'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isAllCustomer"
                                                checked={this.state.isAllCustomer}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isAllCustomer"> All Customer Type</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isCustomerTypeOnly"
                                                checked={this.state.isCustomerTypeOnly}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isCustomerTypeOnly"> Single Customer Type</label>
                                        </span>
                                        <SelectBox
                                            dataSource={this.state.customerTypeDropdownList.length > 0 ? this.state.customerTypeDropdownList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select Customer Type"
                                            name="ddcustomertype"
                                            defaultValue={0}
                                            value={this.state.customerTypeDropdownData != null ? this.state.customerTypeDropdownData.id : 0}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnCustomerTypeDropdownChange}
                                            showClearButton={true}
                                            disabled={(!this.state.isCustomerTypeOnly)}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerType"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["customerType"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isCustomerOnly"
                                                checked={this.state.isCustomerOnly}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isCustomerOnly"> Select Customer</label>
                                        </span>
                                        <DropDownBox
                                            value={this.state.customerDropdownData}
                                            valueExpr="code"
                                            deferRendering={false}
                                            displayExpr="fullName"
                                            placeholder="Select customer..."
                                            showClearButton={true}
                                            dataSource={this.state.customerDropdownList}
                                            onValueChanged={this.syncDataGridCustomerSelection}
                                            contentRender={this.dataGridCustomerRender}
                                            disabled={(!this.state.isCustomerOnly)}
                                        />
                                        {this.state.errors["customer"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["customer"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>From Date (Invoice)</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            defaultValue={this.state.fromDate}
                                            value={this.state.fromDate}
                                            onValueChanged={this.handleOnFromDateChange}
                                            width={'100%'}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["fromDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["fromDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>To Date (Invoice)</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            defaultValue={this.state.toDate}
                                            value={this.state.toDate}
                                            min={this.state.fromDate}
                                            onValueChanged={this.handleOnToDateChange}
                                            width={'100%'}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["toDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["toDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12" style={{ display: (this.state.isCustomerOnly) || 'none' }}>
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input"
                                                type="checkbox" name="isComments"
                                                checked={this.state.isComments}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isComments"
                                                style={{ fontSize: "inherit" }}> Add Comments &nbsp;<i>(If Yes, please enter comments)</i>
                                            </label>
                                        </div>
                                        <textarea className="form-control form-textbox form-control-sm"
                                            ref={input => this.commentsInputValidator = input}
                                            name="comments" title="Comments" placeholder=""
                                            maxLength="250"
                                            disabled={(!this.state.isComments)}
                                            value={(!this.state.isComments) ? '' : this.state.comments}
                                            onChange={this.handleOnInputChange} />
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ display: (this.state.isCustomerOnly) || 'none' }}>
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input"
                                                type="checkbox" name="isSignature"
                                                checked={this.state.isSignature}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isSignature"
                                                style={{ fontSize: "inherit" }}> Add Notes: &nbsp;
                                            </label>
                                        </div>
                                        <HtmlEditor
                                            height={200}
                                            name="issuedetailsd"
                                            value={this.state.signature}
                                            valueType={"html"}
                                            onValueChanged={this.handleOnSignatureChange}
                                            disabled={(!this.state.isSignature)}
                                        >
                                            <Toolbar>
                                                <Item name="undo" />
                                                <Item name="redo" />
                                                <Item name="separator" />
                                                <Item
                                                    name="size"
                                                    acceptedValues={sizeValues}
                                                />
                                                <Item
                                                    name="font"
                                                    acceptedValues={fontValues}
                                                />
                                                <Item name="separator" />
                                                <Item name="bold" />
                                                <Item name="italic" />
                                                <Item name="strike" />
                                                <Item name="underline" />
                                                <Item name="separator" />
                                                <Item name="alignLeft" />
                                                <Item name="alignCenter" />
                                                <Item name="alignRight" />
                                                <Item name="alignJustify" />
                                                <Item name="separator" />
                                                <Item name="color" />
                                                <Item name="background" />
                                            </Toolbar>
                                        </HtmlEditor>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="button" className="btn btn-success btn-sm" title="Download excel" onClick={(e) => this.handleOnReportSubmit(e, 'xlsx')}>
                                {this.state.isSubmittedExcel && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmittedExcel && <span title="Download Excel"><i className="fas fa-file-download"></i> Download Excel</span>}
                            </button>
                            <button type="button" className="btn btn-success btn-sm" title="Download pdf" onClick={(e) => this.handleOnReportSubmit(e, 'pdf')}>
                                {this.state.isSubmittedPdf && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmittedPdf && <span title="Download PDF"><i className="fas fa-file-download"></i> Download PDF</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleOnClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                            </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        )
    }
    async onSelectionCustomerChanged(e) {
        this.setState({
            customerDropdownData: e.selectedRowKeys.length && e.selectedRowKeys || []
        });
    }
    async syncDataGridCustomerSelection(e) {
        this.setState({
            customerDropdownData: e.value || []
        });
    }

    dataGridCustomerRender() {
        return (
            <DataGrid
                dataSource={this.state.customerDropdownList}
                id="grid-container"
                keyExpr="code"
                hoverStateEnabled={true}
                selectedRowKeys={this.state.customerDropdownData}
                onSelectionChanged={this.onSelectionCustomerChanged}
                ref={ref => this.dataGrid = ref}>
                <Column dataField="fullName" caption="Customer Name" />

                <Selection mode="multiple" />
                <Scrolling mode="virtual" />
                <Paging enabled={true} pageSize={10} />
                <FilterRow visible={true} />


            </DataGrid>
        );
    }
}