import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
    Editing
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import { DateFormat, StatusType, TripStatus } from '../../helpers/fixcodes';
import Modal from 'react-modal'; //npm install --save react-modal
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import { getRequestParams, formatFromDate, formatToDate } from '../../helpers/globalfunctions.js';
import Swal from 'sweetalert2';

let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
let startTime = new Date(new Date(currentTime).setDate(currentTime.getDate() - 45));
let endTime = new Date(new Date(currentTime).setDate(currentTime.getDate()));

const customStyles = {
    content: {
        width: '60%',
    }
};

export class CrewFeedbackComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            isFeedbackReplyModalOpen: false,
            datasource: null,
            loadPanelVisible: false,
            feedbackDetails: [],
            feedbackReplyDetails: [],
            searchFromDate: formatFromDate(startTime, "MM/dd/yyyy hh:mm"),
            searchToDate: formatToDate(endTime, "MM/dd/yyyy hh:mm"),
            replyMessage: "",
            errors: {
                replyMessage:""
            }
        }
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
    }
    componentDidMount() {
        this.getFeedbackList();
    }
    async getFeedbackList() {
        try {
            this.setState({ loadPanelVisible: true });
            var request = {};
            request.AirlineCode = "QP";
            request.TypeofFeedback = 2;
            request.FromDate = this.state.searchFromDate;
            request.ToDate = this.state.searchToDate;
            request.EmployeeNumber = "";
            request.UserMasterId = 1;
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crewfeedback/getFeedback', requestParams);
            const data = await response.json();
            
            this.setState({
                datasource: data.payload === null ? [] : data.payload,
                loadPanelVisible: false
            });
        } catch (e) {
            this.setState({ loadPanelVisible: false });
            console.log(e);
        }
    }
    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getFeedbackList.bind(this)
            }
        });
    }
    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let errors = this.state.errors;
        this.setState({
            [name]: value
        });
    }
    handleFeedbackReplySubmit = async event => {
        event.preventDefault();
        debugger;
        if (this.handleValidation()) {
            this.setState({ isSubmited: true });
            var request = new Object();
            request.FeedBackId = Number(this.state.feedbackDetails.hcFeedbackId);
            request.ReplyMsg = this.state.replyMessage;
            request.ReplyMsgHTML = "";
            request.ReplyByUserMasterId = parseInt(sessionStorage.getItem("session_userId"));
            request.IssueStatusId = 1;
            request.ImageFileName = "";
            request.ImageURL = "";
            debugger;
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('/crewfeedback/sendFeedback', requestParams);
            const data = await response.json();

            try {
                Swal.fire({
                    icon: data.isSuccess ? 'success' : 'error',
                    title: data.isSuccess ? 'Crew Feedback' : 'Oops...',
                    text: data.resultMessage,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            this.setState({ isSubmited: false, isFeedbackReplyModalOpen: false });
                            this.getFeedbackList();
                        }
                    } else {
                        this.setState({ isSubmited: false })
                    }
                });
            } catch (e) {
                debugger;
                this.setState({ isSubmited: false })
            }
        }
    }
    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.replyMessage = "";

        if (this.state.replyMessage.trim() === '') {
            formIsValid = false;
            errors.replyMessage = "Please enter reply message.";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    async handleOnFeedbackModal(e, data) {
        e.preventDefault();
        const requestParams = getRequestParams('GET');
        const response = await fetch('/crewfeedback/feedbackreplyHistory/' + data.hcFeedbackId, requestParams);
        const replyHistoryData = await response.json();
        debugger;
        this.setState({
            isFeedbackReplyModalOpen: true,
            feedbackDetails: data,
            feedbackReplyDetails: replyHistoryData,
        });
    }
    handleResetDataToState = () => {
        this.setState({
            isSubmited: false,
            isFeedbackReplyModalOpen: false,
            feedbackDetails:[],
        });
    }
    handleAssignDataToState = (data) => {
        debugger;
    }
    render() {
        const renderGridCell = (data) => {
            return <div>                
                <a href="#" className="fa fa-regular fa-eye" title="View" onClick={(e) => this.handleOnFeedbackModal(e, data.data)}></a>
            </div>;
        }

        return (
            <form>
                <div className="card card-default">
                    <div className="card-header">
                        <h3 className="card-title">Crew Feedback</h3>
                    </div>
                    <div className="content">
                        <div className="card card-grid">
                            <div className="card-body">
                                <DataGrid
                                    dataSource={this.state.datasource}
                                    keyExpr="hcFeedbackId"
                                    selection={{ mode: 'single' }}
                                    columnsAutoWidth={false}
                                    showColumnLines={false}
                                    showRowLines={true}
                                    showBorders={true}
                                    rowAlternationEnabled={true}
                                    showBorders={true}
                                    allowColumnResizing={true}
                                    columnResizingMode='widget'
                                    onToolbarPreparing={this.onToolbarPreparing}>
                                    <Column dataField="" width={30} allowSearch={true} allowFiltering={false} allowSorting={true} caption="" cellRender={renderGridCell} />
                                    <Column dataField="airlineCode" width={50} allowSearch={true} allowFiltering={false} allowSorting={true} caption="Airline" />
                                    <Column dataField="employeeNumber" width={110} allowSearch={true} allowFiltering={false} allowSorting={true} caption="Employee Number" />
                                    <Column dataField="airlineCrewType" width={100} allowSearch={true} allowFiltering={false} allowSorting={true} caption="Crew Position" />
                                    <Column dataField="recievedFrom" width={150} allowSearch={true} allowFiltering={false} allowSorting={true} caption="Employee Email" />
                                    <Column dataField="airportCode" width={90} allowSearch={true} allowFiltering={false} allowSorting={true} caption="Airport Code" />
                                    <Column dataField="transporterName" width={150} allowSearch={true} allowFiltering={false} allowSorting={true} caption="Transport Name" />
                                    <Column dataField="status" width={90} allowSearch={true} allowFiltering={false} allowSorting={true} caption="Issue Status" />
                                    <Column dataField="issueType" width={150} allowSearch={true} allowFiltering={false} allowSorting={true} caption="Issue Type" />
                                    <Column dataField="priority" width={100} allowSearch={true} allowFiltering={false} allowSorting={true} caption="Issue Priority" />
                                    <Column dataField="flightNumber" width={100} allowSearch={true} allowFiltering={false} allowSorting={true} caption="Flight Number" />
                                    <Column dataField="description" width={150} allowSearch={true} allowFiltering={false} allowSorting={true} caption="Description" />
                                    <Column dataField="transportDateTime" width={150} allowSearch={true} allowFiltering={false} allowSorting={true} caption="Transport DateTime" format={DateFormat.DateTime24Format} dataType="datetime" />
                                    <Column dataField="" width={150} allowSearch={true} allowFiltering={false} allowSorting={true} caption="Submission Datetime" format={DateFormat.DateTime24Format} dataType="datetime" />
                                    <Column dataField="resolvedBy" width={150} allowSearch={true} allowFiltering={false} allowSorting={true} caption="Resolved By" />
                                    <Column dataField="resolutionDate" width={150} allowSearch={true} allowFiltering={false} allowSorting={true} caption="Resolution Datetime" format={DateFormat.DateTime24Format} dataType="datetime" />
                                    <Column dataField="replyMsg" width={150} allowSearch={true} allowFiltering={false} allowSorting={true} caption="Reply Message" />
                                    <Column dataField="imageFileName" width={150} allowSearch={true} allowFiltering={false} allowSorting={true} caption="Image" />
                                    <SortByGroupSummaryInfo summaryItem="count" />
                                    <GroupPanel visible={true} />
                                    <HeaderFilter visible={true} />
                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                    <Grouping autoExpandAll={true} />
                                    <Paging defaultPageSize={20} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true} />
                                    <Export enabled={true} allowExportSelectedData={false} />
                                    <ColumnChooser enabled={true} />
                                    <ColumnFixing enabled={true} />
                                </DataGrid>
                            </div>
                        </div>
                    </div>
                    <Modal ariaHideApp={false}
                        isOpen={this.state.isFeedbackReplyModalOpen}
                        style={customStyles}
                        className={"react-modal"}
                        //onAfterOpen={this.handleAssignDataToState}
                        onRequestClose={this.handleResetDataToState}
                        shouldCloseOnOverlayClick={false}
                        contentLabel="Example Modal">
                        <form method="post" onSubmit={this.handleFeedbackReplySubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                            <div className="modal-header">
                                <h6 className="modal-title">{"Feedback"}  Status: {this.state.feedbackDetails.status}</h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleResetDataToState}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                        <div className="form-group form-control-sm">
                                            <label>Employee Name:</label>
                                            <label><span>{this.state.feedbackDetails.firstName}&nbsp;{this.state.feedbackDetails.lastName}</span></label>
                                        </div>
                                    </div>
                                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                        <div className="form-group form-control-sm">
                                            <label>Employee Number:</label>
                                            <label><span>&nbsp;{this.state.feedbackDetails.employeeNumber}</span></label>
                                        </div>
                                    </div>
                                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                        <div className="form-group form-control-sm">
                                            <label>Airline Code:</label>
                                            <label><span>&nbsp;{this.state.feedbackDetails.airlineCode}</span></label>
                                        </div>
                                    </div>
                                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                        <div className="form-group form-control-sm">
                                            <label>Airport Code:</label>
                                            <label><span>&nbsp;{this.state.feedbackDetails.airportCode}</span></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                        <div className="form-group form-control-sm">
                                            <label>Issue Type:</label>
                                            <label><span>&nbsp;{this.state.feedbackDetails.issueType}</span></label>
                                        </div>
                                    </div>
                                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                        <div className="form-group form-control-sm">
                                            <label>Priority:</label>
                                            <label><span>&nbsp;{this.state.feedbackDetails.priority}</span></label>
                                        </div>
                                    </div>
                                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                        <div className="form-group form-control-sm">
                                            <label>Feedback Type:</label>
                                            <label><span>&nbsp;{this.state.feedbackDetails.typeofFeedback}</span></label>
                                        </div>
                                    </div>
                                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                        <div className="form-group form-control-sm">
                                            <label>Transport Name:</label>
                                            <label><span>&nbsp;{this.state.feedbackDetails.transporterName}</span></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="post">
                                            <div className="user-block">
                                                <img className="img-circle img-bordered-sm" src="../../dist/img/user1-128x128.jpg" alt="user image" />
                                                <span className="username">
                                                    <a href="#">{this.state.feedbackDetails.firstName}&nbsp;{this.state.feedbackDetails.lastName}</a>
                                                </span>
                                                <span className="description">{this.state.feedbackDetails.dateofOccurrence}</span>
                                            </div>
                                            <p>
                                                {this.state.feedbackDetails.description}
                                            </p>
                                            {/*<p>*/}
                                            {/*    <a href="#" className="link-black text-sm"><i className="fas fa-link mr-1"></i> Demo File 1 v2</a>*/}
                                            {/*</p>*/}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="form-group">
                                            <label>Reply Message <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control form-control-sm" name="replyMessage" placeholder="Type Reply message" maxLength="100" title="Enter Reply Message" value={this.state.replyMessage} onChange={this.handleInputChange} />
                                            {this.state.errors["replyMessage"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["replyMessage"]}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-success btn-sm">
                                    {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                    {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                                </button>
                                <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleResetDataToState} title="Close"><i className="fas fa-times"></i> Close</button>
                            </div>
                        </form>
                    </Modal>
                </div>
            </form>
        )
    }
}