import React, { Component } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import DateBox from 'devextreme-react/date-box';
import { SelectBox } from 'devextreme-react/select-box';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions';
import { LoadPanel } from 'devextreme-react/load-panel';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'
const position = { of: '#historydiv' };

export class DeductionsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,

            chkVehicleSelection: "1",
            dropdownVehicle: [],
            vehicleId: 0,

            chkOrderBySelection: "1",

            errors: {
                vehicleId: '',
            }
        }

        this.getVehicle = this.getVehicle.bind(this);
        this.handleVehicleChange = this.handleVehicleChange.bind(this);
        this.handleVehicleSelectionChange = this.handleVehicleSelectionChange.bind(this);

        this.handleOrderBySelectionChange = this.handleOrderBySelectionChange.bind(this);

        this.downloadAccountPaymentTypeSummaryAsPdf = this.downloadAccountPaymentTypeSummaryAsPdf.bind(this);

        this.handleSearchAccountPaySummaryReportSubmit = this.handleSearchAccountPaySummaryReportSubmit.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
    }


    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getVehicle();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {

        this.setState({ isOpenModal: false });
        await this.handleClearSearch();
    }

    async getVehicle() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('/vehicle-api/dropdown-all', requestParams);
            const data = await response.json();
            this.setState({
                dropdownVehicle: data.vehiclesDropdown,
            });

        } catch (e) {
            console.log(e);
        }
    }

    handleVehicleChange(selectedOption) {
        this.setState({ vehicleId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
        this.state.errors["vehicleId"] = "";
    }

    handleVehicleSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkVehicleSelection: value, vehicleId: 0 });
        this.state.errors["vehicleId"] = "";
    }

    handleOrderBySelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkOrderBySelection: value });
    }

    handleSearchAccountPaySummaryReportSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            this.downloadAccountPaymentTypeSummaryAsPdf();
        }
    }
    handleClearSearch() {
        this.setState({
            isSubmited: false,
            isEmailSubmitted: false,
            loadPanelVisible: false,
            loadPanelVisible: false,

            chkVehicleSelection: "1",
            vehicleId: 0,

            chkOrderBySelection: "1",

            errors: {
                vehicleId: '',
            }
        });
    }

    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.vehicleId = "";

        if (this.state.chkVehicleSelection === "2" && this.state.vehicleId == 0) {
            formIsValid = false;
            errors.vehicleId = "Please select vehicle.";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    async downloadAccountPaymentTypeSummaryAsPdf() {
        try {
            this.setState({
                isSubmited: true,
            });
            var RptHead = "";
            var selection_Criteria = "";

            RptHead = "Order By " + " " + (this.state.chkOrderBySelection == "1" ? " Vehicle No " : (this.state.chkOrderBySelection == "2" ? " Deduction Name" : " Start date "));

            var request = new Object();
            request.LocationId = Number(sessionStorage.getItem("session_locationId"));

            if (this.state.chkVehicleSelection == "2") {
                let puZone = this.state.dropdownVehicle.find(opt => opt.id == this.state.vehicleId);

                selection_Criteria = ("{M_AFF_VEHICLES.VEHICLE_NO} = \'" + (puZone.name + "\'"));
                RptHead = RptHead + ", for Vehicle: " + puZone.name;
            } else {
                RptHead = RptHead + ", for all Vehicles";
            }

            request.OrderByType = parseInt(this.state.chkOrderBySelection);
            request.Header = RptHead;
            request.SelectionCrieteria = selection_Criteria;

            request.fileFormat = 'pdf';
            request.MethodName = "GetDeductionReport";
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            this.setState({
                isSubmited: false,
            });

            let a = document.createElement('a');
            a.href = dataResponse.reportBase64String;

            a.download = 'Deduction.pdf';

            a.click();
        } catch (e) {
            this.setState({
                isSubmited: false,
            });
            console.log(e)
        }
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isEmailSubmitted: true });
                var RptHead = "";
                var selection_Criteria = "";

                RptHead = "Order By " + " " + (this.state.chkOrderBySelection == "1" ? " Vehicle No " : (this.state.chkOrderBySelection == "2" ? " Deduction Name" : " Start date "));

                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));

                if (this.state.chkVehicleSelection == "2") {
                    let puZone = this.state.dropdownVehicle.find(opt => opt.id == this.state.vehicleId);

                    selection_Criteria = ("{M_AFF_VEHICLES.VEHICLE_NO} = \'" + (puZone.name + "\'"));
                    RptHead = RptHead + ", for Vehicle: " + puZone.name;
                } else {
                    RptHead = RptHead + ", for all Vehicles";
                }

                request.OrderByType = parseInt(this.state.chkOrderBySelection);
                request.Header = RptHead;
                request.SelectionCrieteria = selection_Criteria;
                request.fileFormat = 'pdf';
                request.MethodName = "GetDeductionReport";
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Deductions Report';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = 'Deduction.pdf'
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '30%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleSearchAccountPaySummaryReportSubmit}
                        style={(this.state.isSubmited || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Deductions Report</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdAllVehicle" value="1" checked={this.state.chkVehicleSelection === "1"} onChange={this.handleVehicleSelectionChange} />
                                            <label className="form-check-label">All Vehicle</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdSingleVehicle" value="2" checked={this.state.chkVehicleSelection === "2"} onChange={this.handleVehicleSelectionChange} />
                                            <label className="form-check-label">Single Vehicle</label>
                                        </span>
                                        <SelectBox
                                            dataSource={this.state.dropdownVehicle.length > 0 ? this.state.dropdownVehicle : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select vehicle"
                                            name="vehicleId"
                                            defaultValue={0}
                                            value={this.state.vehicleId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleVehicleChange}
                                            showClearButton={true}
                                            disabled={this.state.chkVehicleSelection === "1"}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["vehicleId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["vehicleId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Order by</label><br />
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdByVehicleNo" value="1" checked={this.state.chkOrderBySelection === "1"} onChange={this.handleOrderBySelectionChange} />
                                            <label className="form-check-label">By Vehicle No</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdByDeductionName" value="2" checked={this.state.chkOrderBySelection === "2"} onChange={this.handleOrderBySelectionChange} />
                                            <label className="form-check-label">By Deduction Name</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdByStartDate" value="3" checked={this.state.chkOrderBySelection === "3"} onChange={this.handleOrderBySelectionChange} />
                                            <label className="form-check-label">By Start Date</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="button" className="btn btn-success btn-sm" title="Download" onClick={this.handleSearchAccountPaySummaryReportSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                                </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        )
    }
}