import DataGrid, {
    Column,
    Scrolling, Selection, SearchPanel
} from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { PureComponent } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getRequestParams } from '../../helpers/globalfunctions';

const MySwal = withReactContent(Swal)

const customStyles = {
    content: {
        width: '75%'
    }
};

export class HotelMappingComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
            isSubmited: false,
            zoneDetails: null,
            selectedRowKeys: [],
            hotelList: [],
            mappedLocation: [],
            searchMode: 'contains',
            defaultLocation: 0,

        }
        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getHotelList = this.getHotelList.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
    }

    async getHotelList(zoneId) {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('serviceAreaZone-api/zone-mapped-hotel/' + zoneId, requestParams);
            const data = await response.json();
            this.setState({
                hotelList: data.zoneMappedHotel
            });
            let selected = data.zoneMappedHotel.filter(f => f.mapped === 1).map(f => f.rowIndex.toString());
            this.setState({
                selectedRowKeys: selected
            });

        } catch (e) {
            console.log(e);
        }
    }

    onSelectionChanged({ selectedRowKeys }) {
        this.setState({
            selectedRowKeys: selectedRowKeys
        });
    }

    closeModal() {
        this.setState({
            isOpenModal: false,
            isSubmited: false,
            zoneDetails: null,
        });
    }

    async showModal(data) {

        this.setState({ isOpenModal: true, zoneDetails: data });
        await this.getHotelList(data.id);
    }

    async handleSubmit(e) {
        e.preventDefault();
        const selectedRowsData = await this.dataGrid.instance.getDataByKeys(this.state.selectedRowKeys);

        if (selectedRowsData.length <= 0) {
            const isValid = await Swal.fire({
                title: "<small>Hotels</small>",
                text: "Please select atleast one hotel.",
                icon: "info",
                dangerMode: false,
                width: "25%",
                dangerMode: false,
                allowOutsideClick: false
            });
            return;
        }
        let validIndex = selectedRowsData.findIndex(f => f.latitude === 0 || f.longitude === 0);
        if (validIndex !== -1) {
            const isValid = await Swal.fire({
                title: "<small>Hotels</small>",
                text: "Please select valid hotels with geolocation.",
                icon: "info",
                dangerMode: false,
                width: "25%",
                dangerMode: false,
                allowOutsideClick: false
            });
            return;
        }

        this.setState({ isSubmited: true });
        var selectedObj = "";
        //for (const item of selectedRowsData) {
        //    selectedObj += item.rowIndex + ","
        //}
        selectedObj = selectedRowsData.map(f => f.rowIndex).join(",");

        //for replace last comma
        //selectedObj = selectedObj.replace(/,\s*$/, "");

        /*The / mark the beginning and end of the regular expression
        The, matches the comma
        The \s means whitespace characters(space, tab, etc) and the * means 0 or more
        The $ at the end signifies the end of the string
        */

        var addRequest = new Object();
        addRequest.LocationId = parseInt(this.state.zoneDetails.locationId);
        addRequest.ServiceAreaId = parseInt(this.state.zoneDetails.serviceAreaId);
        addRequest.ZoneId = parseInt(this.state.zoneDetails.id);
        addRequest.HotelId = selectedObj;

        const requestParams = getRequestParams('POST', addRequest);
        const response = await fetch('serviceAreaZone-api/map-zone-hotel', requestParams);
        const data = await response.json();
        Swal.fire({
            icon: data.isSuccess ? 'success' : 'error',
            title: data.isSuccess ? 'Success' : 'Oops...',
            text: data.resultMessage,
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (data.isSuccess) {
                this.setState({ isSubmited: true })
                if (result.isConfirmed) {
                    this.closeModal();
                }
            } else {
                this.setState({ isSubmited: false })
            }
        })

        //}
    }



    render() {
        const zoneName = this.state.zoneDetails === null ? "" : this.state.zoneDetails.name;
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={customStyles}
                    className={"react-modal"}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Set Hotels for - <strong><i>{zoneName}</i></strong></h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="widget-container" style={{ maxHeight: 350 + 'px', overflowY: "auto" }}>
                                <DataGrid
                                    dataSource={this.state.hotelList}
                                    id="grid-container"
                                    keyExpr="rowIndex"
                                    showBorders={true}
                                    selectedRowKeys={this.state.selectedRowKeys}
                                    onSelectionChanged={this.onSelectionChanged}
                                    ref={ref => this.dataGrid = ref}>
                                    <Selection
                                        mode="multiple"
                                        selectAllMode={"allPages"}
                                        showCheckBoxesMode={"always"}
                                    />
                                    <Column dataField="name" caption="Name" />
                                    <Column dataField="displayName" caption="Display Name" />
                                    <Column dataField="address" width="200" caption="Address" />
                                    <Column dataField="latitude" caption="Latitude" />
                                    <Column dataField="longitude" caption="Longitude" />
                                    <Scrolling mode="infinite" />
                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                </DataGrid>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success btn-sm" onClick={this.handleSubmit}>
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeModal} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
            </div>
        );
    }
}