import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter,
    SearchPanel,
    SortByGroupSummaryInfo,
    MasterDetail,
    Scrolling,
    FilterRow,
    Paging,
    Selection,
}
    from 'devextreme-react/data-grid';
import React, { Component } from 'react';
import { LoadPanel } from 'devextreme-react/load-panel';
import Modal from 'react-modal'; //npm install --save react-modal
import { getRequestParams } from '../../helpers/globalfunctions.js';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import { SelectBox } from 'devextreme-react/select-box';
import DropDownBox from 'devextreme-react/drop-down-box';
import DateBox from 'devextreme-react/date-box';
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'
export const vehicleOwnerDataList = [{
    'id': 1,
    'name': 'House ( GTT )',
}, {
    'id': 2,
    'name': 'Owner Operator',
}, {
    'id': 3,
    'name': 'Franchisee',
}]
let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class RideListingRevenueReportComponent extends Component {
    constructor() {
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        super()
        this.state = {
            isSubmitted: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            fromDate: moment(currentTime).format("MM/DD/YYYY"),
            toDate: moment(currentTime).format("MM/DD/YYYY"),
            isAllCustomerType: true,
            customerTypeDropdownList: [],
            customerTypeDropdownData: null,
            customerTypeId: 0,
            isAllVehicleType: true,
            vehicleTypeDropdownList: [],
            vehicleTypeDropdownData: null,
            vehicleTypeId: 0,
            isAllVehicleOwner: true,
            vehicleOwnerDropdownList: [],
            vehicleOwnerDropdownData: null,
            isAllRides: true,
            isOpenRides: false,
            isClosedRides: false,
            isCancelledRides: false,
            errors: {
                fromDate: '',
                toDate: '',
                customerType: '',
                vehicleType: '',
                vehicleOwner: ''
            }
        }

        this.syncDataGridVehicleOwnerSelection = this.syncDataGridVehicleOwnerSelection.bind(this);
        this.dataGridVehicleOwnerRender = this.dataGridVehicleOwnerRender.bind(this);
    }
    getCustomerTypeDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('customer-type-api/', requestParams);
            const data = await response.json();
            this.setState({
                customerTypeDropdownList: data.customerType
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    getVehicleTypeDropdownList = async () => {
        try {
            var apiVehicleTypeRequest = new Object();
            const requestParams = getRequestParams('POST', apiVehicleTypeRequest);
            const response = await fetch('vehicletype-api/vehicletype', requestParams);
            const data = await response.json();
            if (data.isSuccess) {
                this.setState({
                    vehicleTypeDropdownList: data.apiVehicleType
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    getVehicleOwnerDropdownList = async () => {
        try {
            this.setState({ vehicleOwnerDropdownList: vehicleOwnerDataList });
        } catch (ex) {
            console.log(ex);
        }
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getCustomerTypeDropdownList();
            await this.getVehicleTypeDropdownList();
            await this.getVehicleOwnerDropdownList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isEmailSubmitted: false,
                isOpenModal: false,
                loadPanelVisible: false,
                fromDate: moment(currentTime).format("MM/DD/YYYY"),
                toDate: moment(currentTime).format("MM/DD/YYYY"),
                isAllCustomerType: true,
                customerTypeDropdownList: [],
                customerTypeDropdownData: null,
                customerTypeId: 0,
                isAllVehicleType: true,
                vehicleTypeDropdownList: [],
                vehicleTypeDropdownData: null,
                vehicleTypeId: 0,
                isAllVehicleOwner: true,
                vehicleOwnerDropdownList: [],
                vehicleOwnerDropdownData: null,
                isAllRides: true,
                isOpenRides: false,
                isClosedRides: false,
                isCancelledRides: false,
                errors: {
                    fromDate: '',
                    toDate: '',
                    customerType: '',
                    vehicleType: '',
                    vehicleOwner: ''
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnInputChange = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
            let errors = this.state.errors;
            switch (name) {
                case 'isAllCustomerType':
                    errors.customerType = '';
                    this.setState({ isAllCustomerType: value, customerTypeDropdownData: null, customerTypeId: 0 });
                    break;
                case 'isAllVehicleType':
                    errors.vehicleType = '';
                    this.setState({ isAllVehicleType: value, vehicleTypeDropdownData: null, vehicleTypeId: 0 });
                    break;
                case 'isAllVehicleOwner':
                    errors.vehicleOwner = '';
                    this.setState({ isAllVehicleOwner: value, vehicleOwnerDropdownData: null });
                    break;
                case 'isAllRides':
                    this.setState({ isAllRides: value, isOpenRides: false, isClosedRides: false, isCancelledRides: false });
                    break;
                case 'isOpenRides':
                    this.setState({ isAllRides: false, isOpenRides: value, isClosedRides: false, isCancelledRides: false });
                    break;
                case 'isClosedRides':
                    this.setState({ isAllRides: false, isOpenRides: false, isClosedRides: value, isCancelledRides: false });
                    break;
                case 'isCancelledRides':
                    this.setState({ isAllRides: false, isOpenRides: false, isClosedRides: false, isCancelledRides: value });
                    break;
                default:
                    break;
            }
            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnFromDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.fromDate = '';
            let fromDate = '';
            if (event.value !== null && event.value !== '') {
                fromDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.fromDate = 'Please select date.';
            }
            if (this.state.toDate.trim() !== '') {
                errors.toDate = '';
            }
            this.setState({ fromDate: fromDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnToDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.toDate = '';
            let toDate = '';
            if (event.value !== null && event.value !== '') {
                toDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.toDate = 'Please select date.';
            }
            if (this.state.fromDate.trim() !== '') {
                errors.fromDate = '';
            }
            this.setState({ toDate: toDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnCustomerTypeDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.customerType = '';
            let customerTypeId = 0;
            if (data !== null) {
                customerTypeId = (data.selectedItem != null ? data.selectedItem.id : 0);
            }
            else {
                //errors.customerType = 'Please select customer type.';
            }
            this.setState({ customerTypeDropdownData: data, customerTypeId: customerTypeId, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnVehicleTypeDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.vehicleType = '';
            let vehicleTypeId = 0;
            if (data !== null) {
                vehicleTypeId = (data.selectedItem != null ? data.selectedItem.id : 0);
            }
            else {
                //errors.vehicleType = 'Please select vehicle type.';
            }
            this.setState({ vehicleTypeDropdownData: data, vehicleTypeId: vehicleTypeId, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnVehicleOwnerDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.vehicleOwner = '';
            if (data === null || data.length === 0) {
                //errors.vehicleOwner = 'Please select vehicle owner.';
            }
            this.setState({ vehicleOwnerDropdownData: data.selectedRowKeys.length && data.selectedRowKeys || [] });
        } catch (ex) {
            console.log(ex);
        }
    }
    syncDataGridVehicleOwnerSelection(e) {
        this.setState({
            vehicleOwnerDropdownData: e.value || []
        });
    }
    handleOnReportSubmit = async (event) => {
        event.preventDefault();
        try {
            this.setState({ isSubmitted: true });
            await this.downloadReport('pdf');
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false });
    }
    downloadReport = async (fileFormat) => {
        try {
            if (await this.isFormValid()) {
                let fromDate = '';
                let toDate = '';
                let vehicleOwnerTypeIds = '';
                let tripStatusType = 0;
                if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                    fromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00';
                    toDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59';
                }
                if (this.state.isOpenRides) {
                    tripStatusType = 1;
                }
                if (this.state.isClosedRides) {
                    tripStatusType = 2;
                }
                if (this.state.isCancelledRides) {
                    tripStatusType = 3;
                }
                if (this.state.isAllVehicleOwner === false) {
                    if (this.state.vehicleOwnerDropdownData !== null) {
                        if (Object.keys(this.state.vehicleOwnerDropdownData).length > 0) {
                            for (var i = 0; i < this.state.vehicleOwnerDropdownData.length; i++) {
                                vehicleOwnerTypeIds += vehicleOwnerTypeIds === '' ? this.state.vehicleOwnerDropdownData[i].id : ',' + this.state.vehicleOwnerDropdownData[i].id
                            }
                        }
                    }
                }
                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.Number1 = Number(tripStatusType);
                request.StartDate = fromDate;
                request.EndDate = toDate;
                request.CustomerTypeId = Number(this.state.customerTypeId);
                request.VehicleTypeId = Number(this.state.vehicleTypeId);
                request.FranchiseeIds = vehicleOwnerTypeIds;
                request.SelectionCrieteria = await this.setSelectionCrieteria();
                request.fileFormat = fileFormat;
                request.MethodName = "GetSpecialRevenueReport";
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                let a = document.createElement('a');
                a.href = dataResponse.reportBase64String;
                a.download = 'SpecialRevenueReport.' + fileFormat;
                a.click();
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    isFormValid = async () => {
        let isValid = true;
        try {
            let errors = this.state.errors;
            errors.fromDate = '';
            errors.toDate = '';
            errors.customerType = '';
            errors.vehicleType = '';
            errors.vehicleOwner = '';
            if (this.state.isAllCustomerType === false) {
                if (Number(this.state.customerTypeId) === 0) {
                    errors.customerType = 'Please select customer type.';
                    isValid = false;
                }
            }
            if (this.state.isAllVehicleType === false) {
                if (Number(this.state.vehicleTypeId) === 0) {
                    errors.vehicleType = 'Please select vehicle type.';
                    isValid = false;
                }
            }
            if (this.state.fromDate.trim() === '') {
                errors.fromDate = 'Please select date.';
                isValid = false;
            }
            if (this.state.toDate.trim() === '') {
                errors.toDate = 'Please select date.';
                isValid = false;
            }
            if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                if (moment(this.state.fromDate, 'MM/DD/YYYY').isAfter(this.state.toDate, 'MM/DD/YYYY')) {
                    errors.fromDate = 'Please enter a valid date range.';
                    errors.toDate = 'Please enter a valid date range.';
                    isValid = false;
                }
            }
            if (this.state.isAllVehicleOwner === false) {
                if (this.state.vehicleOwnerDropdownData === null || this.state.vehicleOwnerDropdownData.length === 0) {
                    errors.vehicleOwner = 'Please select vehicle owner.';
                    isValid = false;
                }
            }
            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    setSelectionCrieteria = async () => {
        let selectionCrieteria = 'Special revenue report for';
        try {
            if (Number(this.state.customerTypeId) > 0) {
                if (this.state.customerTypeDropdownData !== null && Object.keys(this.state.customerTypeDropdownData).length > 0) {
                    selectionCrieteria += ' customer type - ' + this.state.customerTypeDropdownData.name;
                }
            }
            else {
                selectionCrieteria += ' all customer type';
            }

            if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                selectionCrieteria += ', period from  - ' + this.state.fromDate + ' to ' + this.state.toDate;
            }

            if (Number(this.state.vehicleTypeId) > 0) {
                if (this.state.vehicleTypeDropdownData !== null && Object.keys(this.state.vehicleTypeDropdownData).length > 0) {
                    selectionCrieteria += ', vehicle type - ' + this.state.vehicleTypeDropdownData.apiVehicleTypeDescription;
                }
            }
            else {
                selectionCrieteria += ', all vehicle type';
            }
            if (this.state.vehicleOwnerDropdownData === null || this.state.vehicleOwnerDropdownData.length === 0) {
                selectionCrieteria += ', all vehicle onwer';
            }
            else {
                selectionCrieteria += ', vehicle onwer - [ ';
                for (var i = 0; i < this.state.vehicleOwnerDropdownData.length; i++) {
                    selectionCrieteria += this.state.vehicleOwnerDropdownData[i].name;
                    if (i < this.state.vehicleOwnerDropdownData.length - 1) {
                        selectionCrieteria += ', ';
                    }
                }
                selectionCrieteria += ' ]';
            }

        } catch (ex) {
            selectionCrieteria = '';
            console.log(ex);
        }
        return selectionCrieteria;
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isEmailSubmitted: true });
                let fromDate = '';
                let toDate = '';
                let vehicleOwnerTypeIds = '';
                let tripStatusType = 0;
                if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                    fromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00';
                    toDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59';
                }
                if (this.state.isOpenRides) {
                    tripStatusType = 1;
                }
                if (this.state.isClosedRides) {
                    tripStatusType = 2;
                }
                if (this.state.isCancelledRides) {
                    tripStatusType = 3;
                }
                if (this.state.isAllVehicleOwner === false) {
                    if (this.state.vehicleOwnerDropdownData !== null) {
                        if (Object.keys(this.state.vehicleOwnerDropdownData).length > 0) {
                            for (var i = 0; i < this.state.vehicleOwnerDropdownData.length; i++) {
                                vehicleOwnerTypeIds += vehicleOwnerTypeIds === '' ? this.state.vehicleOwnerDropdownData[i].id : ',' + this.state.vehicleOwnerDropdownData[i].id
                            }
                        }
                    }
                }
                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.Number1 = Number(tripStatusType);
                request.StartDate = fromDate;
                request.EndDate = toDate;
                request.CustomerTypeId = Number(this.state.customerTypeId);
                request.VehicleTypeId = Number(this.state.vehicleTypeId);
                request.FranchiseeIds = vehicleOwnerTypeIds;
                request.SelectionCrieteria = await this.setSelectionCrieteria();
                request.fileFormat = 'pdf';
                request.MethodName = "GetSpecialRevenueReport";
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Special Revenue Report';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = 'SpecialRevenueReport.pdf';
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '45%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleOnReportSubmit}
                        style={(this.state.isSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Special Revenue Report'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                name="isAllCustomerType"
                                                checked={this.state.isAllCustomerType}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isAllCustomerType"> All Customer Type</label>
                                        </div>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder="Select customer type"*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.customerTypeDropdownData}*/}
                                        {/*    name="ddcustomertype"*/}
                                        {/*    onChange={this.handleOnCustomerTypeDropdownChange}*/}
                                        {/*    getOptionLabel={option => option.name}*/}
                                        {/*    getOptionValue={option => option.id}*/}
                                        {/*    options={this.state.customerTypeDropdownList}*/}
                                        {/*    isMulti={false}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*    isDisabled={this.state.isAllCustomerType}*/}
                                        {/*/>*/}

                                        <SelectBox
                                            dataSource={this.state.customerTypeDropdownList.length > 0 ? this.state.customerTypeDropdownList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer type"
                                            name="ddcustomertype"
                                            defaultValue={[]}
                                            value={this.state.customerTypeDropdownData}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnCustomerTypeDropdownChange}
                                            showClearButton={true}
                                            disabled={this.state.isAllCustomerType}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerType"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["customerType"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                name="isAllVehicleType"
                                                checked={this.state.isAllVehicleType}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isAllVehicleType"> All Vehicle Type</label>
                                        </div>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder="Select vehicle type"*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.vehicleTypeDropdownData}*/}
                                        {/*    name="ddvehicletype"*/}
                                        {/*    onChange={this.handleOnVehicleTypeDropdownChange}*/}
                                        {/*    getOptionLabel={option => option.apiVehicleTypeDescription}*/}
                                        {/*    getOptionValue={option => option.vehicleTypeId}*/}
                                        {/*    options={this.state.vehicleTypeDropdownList}*/}
                                        {/*    isMulti={false}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*    isDisabled={this.state.isAllVehicleType}*/}
                                        {/*/>*/}
                                        <SelectBox
                                            dataSource={this.state.vehicleTypeDropdownList.length > 0 ? this.state.vehicleTypeDropdownList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select vehicle type"
                                            name="ddvehicletype"
                                            defaultValue={[]}
                                            value={this.state.vehicleTypeDropdownData}
                                            displayExpr='apiVehicleTypeDescription'
                                            valueExpr='vehicleTypeId'
                                            onSelectionChanged={this.handleOnVehicleTypeDropdownChange}
                                            showClearButton={true}
                                            disabled={this.state.isAllVehicleType}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["vehicleType"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["vehicleType"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                name="isAllVehicleOwner"
                                                checked={this.state.isAllVehicleOwner}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isAllVehicleOwner"> All Vehicle Owner</label>
                                        </div>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder="Select vehicle owner"*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.vehicleOwnerDropdownData}*/}
                                        {/*    name="ddvehicleowner"*/}
                                        {/*    onChange={this.handleOnVehicleOwnerDropdownChange}*/}
                                        {/*    getOptionLabel={option => option.name}*/}
                                        {/*    getOptionValue={option => option.id}*/}
                                        {/*    options={this.state.vehicleOwnerDropdownList}*/}
                                        {/*    isMulti={true}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*    isDisabled={this.state.isAllVehicleOwner}*/}
                                        {/*/>*/}

                                        <DropDownBox
                                            value={this.state.vehicleOwnerDropdownData}
                                            valueExpr="id"
                                            deferRendering={false}
                                            displayExpr="name"
                                            placeholder="Select vehicle owner..."
                                            showClearButton={true}
                                            dataSource={this.state.vehicleOwnerDropdownList}
                                            onValueChanged={this.syncDataGridVehicleOwnerSelection}
                                            contentRender={this.dataGridVehicleOwnerRender}
                                            disabled={this.state.isAllVehicleOwner}
                                        />
                                        {this.state.errors["vehicleOwner"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["vehicleOwner"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>From Date (Pick-up)</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            defaultValue={this.state.fromDate}
                                            value={this.state.fromDate}
                                            onValueChanged={this.handleOnFromDateChange}
                                            width={'100%'}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["fromDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["fromDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>To Date (Pick-up)</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            defaultValue={this.state.toDate}
                                            value={this.state.toDate}
                                            min={this.state.fromDate}
                                            onValueChanged={this.handleOnToDateChange}
                                            width={'100%'}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["toDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["toDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Ride Status</label><br />
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isAllRides"
                                                checked={this.state.isAllRides}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isAllRides" style={{ fontSize: "inherit" }}>All Ride(s) &nbsp;<i>- Except cancelled ride(s)</i></label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isOpenRides"
                                                checked={this.state.isOpenRides}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isOpenRides">Open Ride(s)</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isClosedRides"
                                                checked={this.state.isClosedRides}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isClosedRides">Closed Ride(s)</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isCancelledRides"
                                                checked={this.state.isCancelledRides}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isCancelledRides">Cancelled Ride(s)</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="submit" className="btn btn-success btn-sm" title="Download">
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                             </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }

    dataGridVehicleOwnerRender() {
        return (
            <DataGrid
                dataSource={this.state.vehicleOwnerDropdownList}
                id="grid-container"
                keyExpr="id"
                hoverStateEnabled={true}
                selectedRowKeys={this.state.vehicleOwnerDropdownData}
                onSelectionChanged={this.handleOnVehicleOwnerDropdownChange}
                ref={ref => this.dataGrid = ref}>
                <Column dataField="name" caption="Vehicle owner" />
                <Selection mode="multiple" />
                <Scrolling mode="virtual" />
                <Paging enabled={false} pageSize={50} />
                <FilterRow visible={true} />
            </DataGrid>
        );
    }
}
