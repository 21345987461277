import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import DateBox from 'devextreme-react/date-box';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react/load-panel';
import { getRequestParams} from '../../helpers/globalfunctions';
import { DateFormat } from '../../helpers/fixcodes';
import { ReservationHistoryComponent } from '../Shared/ReservationHistoryComponent';
import { ConfirmQuoteComponent } from '../Shared/ConfirmQuoteComponent';
import { VoucherEmailComponent } from '../Shared/VoucherEmailComponent';
import Swal from 'sweetalert2';
const position = { of: '#bookingdiv' };

let currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class QuotationListComponent extends Component {

    constructor(props) {
        super(props);
        const queryString = require('query-string');
        var parsed = queryString.parse(this.props.location.search);
        const newreservationId = parsed.reservationId;
        const transaction = parsed.transaction;

        currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        this.state = {
            newreservationId: newreservationId,
            transactionId: transaction,
            isSubmited: false,
            datasource: null,
            isNewQuote: false,
            isQuoteSent: false,
            isConfirmed: false,
            isCancelled: false,
            fromDateTime: new Date(currentTime.toLocaleDateString() + " 00:00"),
            toDateTime: new Date(currentTime.toLocaleDateString() + " 23:59"),
            quoteId: '',
            loadPanelVisible: false,
        }

        this.handleSearchQuotationSubmit = this.handleSearchQuotationSubmit.bind(this);
        this.handelToolbarPreparing = this.handelToolbarPreparing.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFromValueChanged = this.handleFromValueChanged.bind(this);
        this.handleToValueChanged = this.handleToValueChanged.bind(this);

        this.openEditQuoteModel = this.openEditQuoteModel.bind(this);
        this.openQuotationHistoryModel = this.openQuotationHistoryModel.bind(this);

        this.handleCancelSubmit = this.handleCancelSubmit.bind(this);
        this.handleEmailSubmit = this.handleEmailSubmit.bind(this);
        this.handleConfirmSubmit = this.handleConfirmSubmit.bind(this);
        this.openEmailModal = this.openEmailModal.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.goToBack = this.goToBack.bind(this);

        //if (this.state.transactionId !== null) {
        //    let strAlert = "";
        //    strAlert = "Your quote confirmed successfully. confirmation number is: <br><br>";
        //    strAlert += "\t" + this.state.newreservationId + "<br>";
        //    strAlert += "<br> Thank you for booking.";
        //    Swal.fire({
        //        icon: 'success',
        //        title: 'success',
        //        html: strAlert,
        //        width: "25%",
        //        allowOutsideClick: false
        //    }).then((result) => {
        //        /* Read more about isConfirmed, isDenied below */
        //        if (result.isConfirmed) {
        //            window.history.pushState({}, null, '/quotation/list/');
        //        }
        //    })
        //}
    }
    handelToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getQuotationList.bind(this)
            }
        });
    }

    async handleSearchQuotationSubmit(e) {
        e.preventDefault();
        await this.getQuotationList();

    }

    handleFromValueChanged(e) {
        this.setState({ fromDateTime: e.value });
    }
    handleToValueChanged(e) {
        this.setState({ toDateTime: e.value });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === "quoteId") {
            const re = /^[0-9\b]+$/;
            if (event.target.value === '' || re.test(event.target.value)) {
                this.setState({ [name]: event.target.value })
            }
        } else {
            this.setState({
                [name]: value
            });
        }
    }

    componentDidMount() {

        if (this.props.location.state != undefined) {

            this.setState({
                fromDateTime: this.props.location.state.fromDateTime,
                toDateTime: this.props.location.state.toDateTime,
                quoteId: this.props.location.state.quoteId,
                isNewQuote: this.props.location.state.isNewQuote,
                isQuoteSent: this.props.location.state.isQuoteSent,
                isConfirmed: this.props.location.state.isConfirmed,
                isCancelled: this.props.location.state.isCancelled,
            });

            let myVar = setTimeout(() => this.getQuotationList(), 500)
        }
    }

    async getQuotationList() {
        var quoteDetailsRequest = new Object();
        quoteDetailsRequest.PuDateTimeFrom = new Date(this.state.fromDateTime).toLocaleDateString() + " " + new Date(this.state.fromDateTime).toLocaleTimeString(); //new Date(2020, 9, 1);
        quoteDetailsRequest.PuDateTimeTo = new Date(this.state.toDateTime).toLocaleDateString() + " " + new Date(this.state.toDateTime).toLocaleTimeString(); //new Date(2020, 9, 31);
        quoteDetailsRequest.QuatationId = 0;

        var quoteStatus = ''
        if (this.state.isNewQuote) {
            quoteStatus += "315,"
        }
        if (this.state.isQuoteSent) {
            quoteStatus += "316,"
        }
        if (this.state.isConfirmed) {
            quoteStatus += "318,"
        }
        if (this.state.isCancelled) {
            quoteStatus += "317,"
        }
        quoteDetailsRequest.QuoteStatusId = quoteStatus;
        this.setState({ isSubmited: true });

        try {
            var quoteId = this.state.quoteId === "" ? 0 : parseInt(this.state.quoteId);
            const requestParams = getRequestParams('POST', quoteDetailsRequest);
            const response = await fetch('/quotation-api/' + quoteId + '/list', requestParams);
            const data = await response.json();
            this.setState({
                datasource: data.quoteDetails
            });

            if (data.reservations.length <= 0) {
                await Swal.fire({
                    icon: 'success',
                    title: '<small>Quotation</small>',
                    text: "Quotation not found.",
                    width: '25%',
                    allowOutsideClick: false
                });
            }
            this.setState({ isSubmited: false });

        } catch (e) {
            this.setState({ isSubmited: false });
        }
    }

    handleClearSearch() {
        this.setState({
            isSubmited: false,
            datasource: null,
            isNewQuote: false,
            isQuoteSent: false,
            isConfirmed: false,
            isCancelled: false,
            fromDateTime: new Date(currentTime.toLocaleDateString() + " 00:00"),
            toDateTime: new Date(currentTime.toLocaleDateString() + " 23:59"),
            quoteId: '',
        });
    }

    openEditQuoteModel(e, data) {
        e.preventDefault();
        this.props.history.push({
            pathname: '/quote',
            //state: { detail: data }
            state: { quoteId: data.quoteId, sourceUrl: "/quotation/list", sourceState: this.state }
        })
    }
    openQuotationHistoryModel(e, data) {
        e.preventDefault();
        this.refs.reservationHistoryModal.showModal("Quotation", data.quoteId);
    }

    async handleEmailSubmit(e, data) {
        e.preventDefault();

        //var newObject = new Object();
        //newObject.QuatationId = 0;
        //newObject.QuoteStatusId = "";

        //const requestParams = getRequestParams('POST', newObject);
        //const response = await fetch('quotation-api/' + parseInt(data.quoteId) + '/list/', requestParams);
        //const data = await response.json();
        //var tripDetails = data.quoteDetails[0];

        this.openEmailModal(data.puDateTime, data.quoteId, data.email, data.permanentRemark)
    }

    async openEmailModal(puDateTime, quoteId, emailId, permanantRemark) {

        let attachments = [];
        currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));

        var strSubject = sessionStorage.getItem("session_accountname") + " Quotation: " + new Date(puDateTime).toDateString() + " " + new Date(puDateTime).toTimeString() + " (Quote Id: " + quoteId + ") ";

        let strFileName = "Quotation" + quoteId;

        var isShowFare = false;
        const isConfirm = await this.askShowFareConfirm();
        if (!isConfirm) {
            isShowFare = false;
        } else {
            isShowFare = true;
        }

        this.setState({
            loadPanelVisible: true,
        });


        var request = new Object();
        request.QuoteId = parseInt(quoteId);
        request.IsShowRate = isShowFare;
        request.PermanantRemark = permanantRemark;
        request.fileFormat = 'pdf';
        request.MethodName = "GetQuoteReport";
        const requestParams = getRequestParams('POST', request);
        const response = await fetch('crystal-report-api', requestParams);
        const dataResponse = await response.json();
        let fileData = dataResponse.reportBase64String;
        if (fileData !== null && fileData !== '') {

            //let emailSubject = 'Data Entry Audit Report - ' + moment(currentTime).format("DD MMMM YYYY HH:mm");
            let file = new Object();
            file.fileData = fileData;
            file.fileName = strFileName + '.pdf';
            file.fileExtension = '.pdf';
            attachments.push(file);
            this.setState({
                loadPanelVisible: false,
            });
            this.refs.voucherEmailComponent.showModal(0, quoteId, emailId, '', strSubject, '', attachments);
        }
        else {
            this.setState({
                loadPanelVisible: false,
            });

            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Unable to process the email request.',
            })
        }
    }


    async askCancelQuoteConfirm() {
        let isResult = false;
        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: "You are going to cancel this Quote!<br> Do you want to still continue?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue',
            cancelButtonText: 'Close',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    async handleCancelSubmit(e, props) {
        e.preventDefault();
        const isConfirm = await this.askCancelQuoteConfirm();
        if (!isConfirm) {
            return;
        } else {
            try {
                var newObject = {};
                newObject.QuatationId = props.quotationId;
                newObject.QuoteId = props.quoteId;
                newObject.StatusId = 317;
                newObject.historyLog = "Quote cancelled.";
                this.setState({
                    loadPanelVisible: true,
                });
                const requestParams = getRequestParams('POST', newObject);
                const response = await fetch('quotation-api/update-status', requestParams);
                const data = await response.json();

                this.setState({
                    loadPanelVisible: false,
                });

                Swal.fire({
                    icon: data.isSuccess ? 'success' : 'error',
                    title: data.isSuccess ? 'Success' : 'Oops...',
                    text: data.resultMessage,
                    width: "30%",
                    allowOutsideClick: false
                }).then((result) => {
                  
                    /* Read more about isConfirmed, isDenied below */
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            this.goToBack();
                        }
                    } 
                })


            } catch (e) {
                this.setState({
                    loadPanelVisible: false,
                });
                console.log(e);
            }
        }
    }

    async askShowFareConfirm() {
        let isResult = false;
        await Swal.fire({
            title: "<small>Fare Confirmation</small>",
            html: "Do you want to print/ show fare details in quotation?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    async handleDownload(e, data) {
        e.preventDefault();
        var isShowFare = false;
        const isConfirm = await this.askShowFareConfirm();
        if (!isConfirm) {
            isShowFare = false;
        } else {
            isShowFare = true;
        }

        this.setState({
            //loadPanelVisible: true,
            loadPanelVisible: true,
        });

        var request = {};
        request.QuoteId = parseInt(data.quoteId);
        request.IsShowRate = isShowFare;
        request.PermanantRemark = data.permanentRemark;
        request.fileFormat = 'pdf';
        request.MethodName = "GetQuoteReport";
        const requestParams = getRequestParams('POST', request);
        const response = await fetch('crystal-report-api', requestParams);
        const dataResponse = await response.json();
        this.setState({
            // loadPanelVisible: false,
            loadPanelVisible: false,
        });
        let a = document.createElement('a');
        a.href = dataResponse.reportBase64String;
        a.download = "Quotation" + this.state.quoteId + ".pdf";
        a.click();
    }

    handleConfirmSubmit(e, data) {
        e.preventDefault();
        this.refs.confirmQuoteModal.showModal("Quotation", data.quoteId);
    }

    async goToBack() {
        await this.getQuotationList();
    }

    render() {

        const filterSearchFromDate = time => {
            const currentDate = currentTime;
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }

        const filterSearchToDate = time => {
            const currentDate = currentTime;
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }

        const renderGridCell = (data) => {
            let emailLink = (Number(data.data.tripStatusId) === 315) ? <a href="#" className="fas fa-envelope icon text-info" title="Email" onClick={(e) => { this.handleEmailSubmit(e, data.data) }}></a> : '';
            let confirmLink = (Number(data.data.tripStatusId) === 316) ? <a href="#" className="fas fa-check icon text-info" title="Confirm" onClick={(e) => { this.handleConfirmSubmit(e, data.data) }}></a> : '';
            let cancelLink = (Number(data.data.tripStatusId) === 315 || Number(data.data.tripStatusId) === 316) ? <a href="#" className="fas fa-times icon text-info" title="Cancel" onClick={(e) => { this.handleCancelSubmit(e, data.data) }}></a> : '';

            return <div>
                <a href="#" className="fas fa-pen icon text-info" title="Edit" onClick={(e) => { this.openEditQuoteModel(e, data.data) }}></a>&nbsp;&nbsp;
                {emailLink === '' || emailLink}&nbsp;&nbsp;
                <a href="#" className="fas fa-file-pdf icon text-info" title="Download" onClick={(e) => { this.handleDownload(e, data.data) }}></a>&nbsp;&nbsp;
                {confirmLink === '' || confirmLink}&nbsp;&nbsp;
                {cancelLink === '' || cancelLink}&nbsp;&nbsp;
                <a href="#" className="fas fa-history icon text-info" title="History" onClick={(e) => { this.openQuotationHistoryModel(e, data.data) }}></a>&nbsp;&nbsp;
            </div>;
        }


        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-2 col-sm-12">
                                <h1 className="m-0 text-dark">Quotation</h1>
                            </div>
                            <div className="col-lg-8 col-md-10 col-sm-12">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem><NavLink tag={Link} to="/quote" className="btn btn-block btn-outline-secondary btn-sm" title="New Quotation"><i className="fas fa-plus"></i> New Quotation</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/reservations" className="btn btn-block btn-outline-secondary btn-sm" title="New Reservation"><i className="fas fa-plus"></i> New Reservation</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/reservation/list" className="btn btn-block btn-outline-secondary btn-sm" title="Search Reservation"><i className="fas fa-search"></i> Search Reservation</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/uploadschedule" className="btn btn-block btn-outline-secondary btn-sm" title="Upload Schedule"><i className="fas fa-clipboard-list"></i> Upload Schedule</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <form method="post" onSubmit={this.handleSearchQuotationSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="rez-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-2 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>From Pick-up Date Time</label>
                                        <DateBox type="datetime"
                                            className="form-control form-control-sm"
                                            name="fromDateTime"
                                            placeholder="MM/dd/yyyy HH:mm"
                                            displayFormat="MM/dd/yyyy HH:mm"
                                            showAnalogClock={false}
                                            useMaskBehavior={true}
                                            defaultValue={this.state.fromDateTime}
                                            value={this.state.fromDateTime}
                                            onValueChanged={this.handleFromValueChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />

                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>To Pick-up Date Time</label>
                                        <DateBox type="datetime"
                                            className="form-control form-control-sm"
                                            name="toDateTime"
                                            placeholder="MM/dd/yyyy HH:mm"
                                            displayFormat="MM/dd/yyyy HH:mm"
                                            showAnalogClock={false}
                                            useMaskBehavior={true}
                                            defaultValue={this.state.toDateTime}
                                            value={this.state.toDateTime}
                                            onValueChanged={this.handleToValueChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Quote Id</label>
                                        <input type="text" className="form-control form-control-sm" name="quoteId" maxLength="30" title="Ride Id" value={this.state.quoteId} onChange={this.handleInputChange} placeholder="Enter quote id" />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12" style={{ paddingTop: 6 + 'px' }}>
                                    <div className="form-group">
                                        <br />
                                        <span className="form-check">
                                            <input className="form-check-input" type="checkbox" checked={this.state.isNewQuote} onChange={this.handleInputChange} name="isNewQuote" />
                                            <label className="form-check-label" htmlFor="isNewQuote">New Quote</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="checkbox" checked={this.state.isQuoteSent} onChange={this.handleInputChange} name="isQuoteSent" />
                                            <label className="form-check-label" htmlFor="isQuoteSent">Quote Sent</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="checkbox" checked={this.state.isConfirmed} onChange={this.handleInputChange} name="isConfirmed" />
                                            <label className="form-check-label" htmlFor="isConfirmed">Confirmed</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="checkbox" checked={this.state.isCancelled} onChange={this.handleInputChange} name="isCancelled" />
                                            <label className="form-check-label" htmlFor="isCancelled">Cancelled</label>
                                        </span>

                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 col-sm-12 text-right text-md-left text-sm-left text-xs-left">
                                    <div className="form-group">
                                        <label className="btn-block">&nbsp;</label>
                                        <button type="submit" className="btn btn-success btn-sm" onClick={this.handleSearchQuotationSubmit} >
                                            {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSubmited && <span title="Search"><i className="fas fa-search"></i> Search</span>}
                                        </button>&nbsp; &nbsp;
                                             <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleClearSearch} title="Clear"><i className="fas fa-eraser"></i> Clear</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="card card-grid">
                                <div className="card-body">
                                    <DataGrid
                                        dataSource={this.state.datasource}
                                        keyExpr="quoteId"
                                        selection={{ mode: 'single' }}
                                        columnsAutoWidth={false}
                                        showColumnLines={false}
                                        showRowLines={true}
                                        showBorders={true}
                                        rowAlternationEnabled={true}
                                        allowColumnResizing={true}
                                        columnResizingMode='widget'
                                        onToolbarPreparing={this.handelToolbarPreparing}>
                                        <Column dataField="quoteId" width={100} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                        <Column dataField="quoteId" caption="Quote Id" width={80} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="fullName" caption="Pax Name" width={150} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="email" caption="Email" width={150} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="puDateTime" caption="PU Date Time" width={120} allowFiltering={true} allowSorting={true} format={DateFormat.DateTime24Format} dataType="datetime" />
                                        <Column dataField="adultNo" caption="Pax #" width={70} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="vehicleType" caption="Vehicle Type" width={100} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="totalFare" caption="Amount($)" width={120} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="confirmedBy" caption="Confirmed by" width={100} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="confirmationNo" caption="Confirmation #" width={120} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="tripStatus" caption="Status" width={120} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="puLocationName" caption="Pick-up" width={250} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="doLocationName" caption="Drop Off" width={250} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="permanentRemark" caption="Remark" width={200} allowFiltering={true} allowSorting={true} />


                                        <SortByGroupSummaryInfo summaryItem="count" />
                                        <GroupPanel visible={true} />
                                        <HeaderFilter visible={false} />
                                        <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                        <Grouping autoExpandAll={true} />
                                        <Paging defaultPageSize={20} />
                                        <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true} />
                                        <Export enabled={true} allowExportSelectedData={false} />
                                        <ColumnChooser enabled={true} />
                                        <ColumnFixing enabled={true} />
                                    </DataGrid>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
                <ReservationHistoryComponent ref="reservationHistoryModal" />
                <ConfirmQuoteComponent ref="confirmQuoteModal" goToBack={this.goToBack} />
                <VoucherEmailComponent ref="voucherEmailComponent" goToBack={this.goToBack} />
            </div>
        )

    }
}