import React, { Component } from 'react';
import firebase from "./firebase-component";
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { AuthorisedLayout } from './components/AuthorisedLayout';

import { LoginComponent } from './components/LoginComponent';
import { VerificationComponent } from './components/VerificationComponent';
import { PlanComponent } from './components/Account/PlanComponent';
import { AccountRegistrationComponent } from './components/Account/AccountRegistrationComponent';
import { AccountSetupComponent } from './components/Account/AccountSetupComponent';
import { RegistrationCompleteComponent } from './components/Account/RegistrationCompleteComponent';
import { ForgotPasswordComponent } from './components/ForgotPassword/ForgotPasswordComponent';
import { ResetPasswordComponent } from './components/ForgotPassword/ResetPasswordComponent';
import { TestTemplateComponent } from './components/TestTemplateComponent';

import { DashboardComponent } from './components/DashboardComponent';
import { DispatchComponent } from './components/Dispatch/DispatchComponent';
import { DispatchCalendarComponent } from './components/Dispatch/DispatchCalendarComponent';
import { BookingComponent } from './components/Reservations/BookingComponent';
import { RouteBookingComponent } from './components/Route/RouteBookingComponent';

import { TrackingComponent } from './components/Tracking/TrackingComponent';
import { VehicleListComponent } from './components/Vehicle/VehicleListComponent';
import { VehicleFormComponent } from './components/Vehicle/VehicleFormComponent';
import { DeviceListComponent } from './components/Device/DeviceListComponent';
import { UserListComponent } from './components/User/UserListComponent';
import { CustomerListComponent } from './components/Customer/CustomerListComponent';
import { SearchReservationsComponent } from './components/Reservations/SearchReservationsComponent';
import { FranchiseeListComponent } from './components/Franchisee/FranchiseeListComponent';
import { LocationListComponent } from './components/Location/LocationListComponent';
import { DriverListComponent } from './components/Driver/DriverListComponent';
import { CustomerUserListComponent } from './components/Customer/CustomerUserListComponent';
import { ServiceAreaComponent } from './components/ServiceArea/ServiceAreaComponent'
import { ServiceAreaZoneComponent } from './components/ServiceAreaZone/ServiceAreaZoneComponent'
import { CannedMessageComponent } from './components/CannedMessage/CannedMessageComponent'
import { AccountSettingComponent } from './components/Account/AccountSettingComponent'
import { TrainingServicesComponent } from './components/TrainingServices/TrainingServicesComponent';
import { DriverMessageComponent } from './components/DriverMessage/DriverMessageComponent';
import { LoggedDriversComponent } from './components/LoggedDrivers/LoggedDriversComponent';
import { LoggedVehiclesComponent } from './components/LoggedVehicles/LoggedVehiclesComponent';
import { UserPermissionComponent } from './components/UserPermission/UserPermissionComponent';
import { UploadScheduleComponent } from './components/UploadSchedule/UploadScheduleComponent';
import { NoShowReservationsComponent } from './components/NoShow/NoShowReservationsComponent';
import { QuotationListComponent } from './components/Quotations/QuotationListComponent';
import { QuoteComponent } from './components/Quotations/QuoteComponent';
import { NextStepComponent } from './components/UploadSchedule/NextStepComponent'
import { FinalListComponent } from './components/UploadSchedule/FinalListComponent'
import { DriverLoginHistoryListComponent } from './components/Driver/DriverLoginHistoryListComponent';
import { DriverWorkHourHistoryListComponent } from './components/Driver/DriverWorkHourHistoryListComponent';
import { TaxSettingComponent } from './components/DefaultSettings/TaxSettingComponent'
import { VehicleTypeTaxComponent } from './components/DefaultSettings/VehicleTypeTaxComponent'
import { CountyAndCongestionTaxComponent } from './components/DefaultSettings/CountyAndCongestionTaxComponent'
import { AreaWiseTaxApplicableComponent } from './components/DefaultSettings/AreaWiseTaxApplicableComponent'
import { WireInstructionComponent } from './components/WireInstruction/WireInstructionComponent'
import { ZonalRatesComponent } from './components/Rates/ZonalRatesComponent'
import { AddEditZonalRatesComponent } from './components/Rates/AddEditZonalRatesComponent'
import { UnapproveRidesComponent } from './components/ManageRides/UnapproveRidesComponent'
import { ApprovedRidesComponent } from './components/ManageRides/ApprovedRidesComponent'
import { GenerateInvoiceComponent } from './components/Invoice/GenerateInvoiceComponent'
import { VehicleDepositeComponent } from './components/VehicleDeposit/VehicleDepositeComponent'
import { DeductionComponent } from './components/Deduction/DeductionComponent'
import { AddonsComponent } from './components/Addons/AddonsComponent'
import { DraftInvoiceComponent } from './components/Invoice/DraftInvoiceComponent'
import { ApprovedInvoiceComponent } from './components/Invoice/ApprovedInvoiceComponent'
import { SystemFeesComponent } from './components/Invoice/SystemFeesComponent'
import { VehiclePaymentComponent } from './components/Reimbursment/VehiclePaymentComponent'
import { PendingPostRidesComponent } from './components/Reimbursment/PendingPostRidesComponent';
import { ReimbursementHistoryComponent } from './components/Reimbursment/ReimbursementHistoryComponent';
import { ReimbursementPayPeriodComponent } from './components/Reimbursment/ReimbursementPayPeriodComponent';
import { PostedRidesComponent } from './components/Reimbursment/PostedRidesComponent';
import { CrudFareComponent } from './components/ManageRides/CrudFareComponent';
import { GenerateVehiclePayout } from './components/Reimbursment/GenerateVehiclePayout';
import { PostVehiclePayout } from './components/Reimbursment/PostVehiclePayout';
import { DriverDocumentComponent } from './components/Reimbursment/DriverDocumentComponent';
import { ContractRatesComponent } from './components/Rates/ContractRatesComponent';
import { AddEditContractRatesComponent } from './components/Rates/AddEditContractRatesComponent';
import { ReportComponent } from './components/Report/ReportComponent'
import { CharterInvoiceReportComponent } from './components/Report/CharterInvoiceReportComponent'
import { AccountSummaryPaymentTypeWiseComponent } from './components/Report/AccountSummaryPaymentTypeWiseComponent'
import { CustomerCommissionRideReportComponent } from './components/Report/CustomerCommissionRideReportComponent'
import { InvoiceDueSummaryReportComponent } from './components/Report/InvoiceDueSummaryReportComponent'
import { RideReconsilationReportComponent } from './components/Report/RideReconsilationReportComponent'
import { RidesApprovedReportComponent } from './components/Report/RidesApprovedReportComponent'
import { RidesUnApprovedReportComponent } from './components/Report/RidesUnApprovedReportComponent'
import { RidesBilledReportComponent } from './components/Report/RidesBilledReportComponent'
import { InvoiceReceiptVoucherComponent } from './components/Report/InvoiceReceiptVoucherComponent'
import { AirlineScorecardReportComponent } from './components/Report/AirlineScorecardReportComponent'
import { AirlineRideActivityReportComponent } from './components/Report/AirlineRideActivityReportComponent'
import { VehiclesProductivityReportComponent } from './components/Report/VehiclesProductivityReportComponent'
import { VehicleTripFareSummaryComponent } from './components/Report/VehicleTripFareSummaryComponent'
import { DispatcherLoginWiseProductivityReportComponent } from './components/Report/DispatcherLoginWiseProductivityReportComponent'
import { ReservationAgentProductivityReportComponent } from './components/Report/ReservationAgentProductivityReportComponent'
import { WeeklyRevenueReportComponent } from './components/Report/WeeklyRevenueReportComponent'
import { AgentCommissionReportComponent } from './components/Report/AgentCommissionReportComponent'
import { AuditReportComponent } from './components/Report/AuditReportComponent'
import { BillingReconsilationReportComponent } from './components/Report/BillingReconsilationReportComponent'
import { VehicleCommissionReportComponent } from './components/Report/VehicleCommissionReportComponent'
import { VehicleDeductionReportComponent } from './components/Report/VehicleDeductionReportComponent'
import { VehicleDepositDetailsReportComponent } from './components/Report/VehicleDepositDetailsReportComponent'
import { InvoiceSalesReportComponent } from './components/Report/InvoiceSalesReportComponent'
import { AccountPaymentsReportComponent } from './components/Report/AccountPaymentsReportComponent'
import { RevenueSummaryGraphReportComponent } from './components/Report/RevenueSummaryGraphReportComponent'
import { AccountRideDetailsReportComponent } from './components/Report/AccountRideDetailsReportComponent'
import { NoShowReservationReportComponent } from './components/Report/NoShowReservationReportComponent'
import { DriverLoginwiseRideSummaryReportComponent } from './components/Report/DriverLoginwiseRideSummaryReportComponent'
import { RideStatuswiseSummaryReportComponent } from './components/Report/RideStatuswiseSummaryReportComponent'
import { MeetAndGreetReportComponent } from './components/Report/MeetAndGreetReportComponent'
import { EstimateReimbursementReportComponent } from './components/Report/EstimateReimbursementReportComponent'
import { DriverPaidReportComponent } from './components/Report/DriverPaidReportComponent'
import { DeferredRevenueReportComponent } from './components/Report/DeferredRevenueReportComponent'
import { CreditcardHistoryReportComponent } from './components/Report/CreditcardHistoryReportComponent'
import { OpenReservationsReportComponent } from './components/Report/OpenReservationsReportComponent'
import { CancelledReservationsReportComponent } from './components/Report/CancelledReservationsReportComponent'
import { ClosedReservationsReportComponent } from './components/Report/ClosedReservationsReportComponent'
import { AccountRidePaxReportComponent } from './components/Report/AccountRidePaxReportComponent'
import { RideDetailsHourlyReportComponent } from './components/Report/RideDetailsHourlyReportComponent'
import { StopListComponent } from './components/Stop/StopListComponent'
import { RouteListComponent } from './components/Route/RouteListComponent'
import { RateLookupComponent } from './components/RateLookup/RateLookupComponent'
import { ExportARInoiceComponent } from './components/Invoice/ExportARInoiceComponent'
import { ExportARReceiptComponent } from './components/Invoice/ExportARReceiptComponent'
import { PendingDispatchComponent } from './components/ManageRides/PendingDispatchComponent'
import { RidesClosedComponent } from './components/ManageRides/RidesClosedComponent'
import { MeetGreetRidesComponent } from './components/Dispatch/MeetGreetRidesComponent'
import { ChequeViewPrintComponent } from './components/Reimbursment/ChequeViewPrintComponent'
import { ScheduleListComponent } from './components/ShuttleSchedule/ScheduleListComponent'
import { ScheduleComponent } from './components/ShuttleSchedule/ScheduleComponent'
import { ExportPayrollComponent } from './components/Reimbursment/ExportPayrollComponent'
import { ImportChequeComponent } from './components/Reimbursment/ImportChequeComponent'
import { ExportAPPayrollComponent } from './components/Reimbursment/ExportAPPayrollComponent'
import { ShuttleBookingComponent } from './components/ShuttleSchedule/ShuttleBookingComponent'
import { TicketingComponent } from './components/Ticketing/TicketingComponent'
import { TicketCommentComponent } from './components/Ticketing/TicketCommentComponent'
import { PasswordPolicyConfigComponent } from './components/PasswordPolicy/PasswordPolicyConfigComponent'
import { QuotationConfirmationComponent } from './components/External/QuotationConfirmationComponent';
import { ServiceAreaZonePlaceComponent } from './components/ServiceAreaZonePlace/ServiceAreaZonePlaceComponent';
import { CrewFeedbackComponent } from './components/CrewFeedback/CrewFeedbackComponent';
import { UploadLuxaryExcelComponent } from './components/Reservations/UploadLuxaryExcelComponent';
import { RidesToBeInvoicedComponent } from './components/ManageRides/RidesToBeInvoicedComponent';
import { VehicleDocumentComponent } from './components/Reimbursment/VehicleDocumentComponent';
import { KilometerRatesComponent } from './components/Rates/KilometerRatesComponent';
import { AddEditKilometerRatesComponent } from './components/Rates/AddEditKilometerRatesComponent';
import { TrackLogComponent } from './components/Report/TrackLogComponent';
import { GpsTrackComponent } from './components/GpsTrack/GpsTrackComponent';
import { FlightScheduleComponent } from './components/Dispatch/FlightScheduleComponent';
import { CrewManagerTrackingComponent } from './components/Tracking/CrewManagerTrackingComponent';
import { VehicleBulkComponent } from './components/Vehicle/VehicleBulkComponent';
import { CustomerBulkComponent } from './components/Customer/CustomerBulkComponent';

export default class App extends Component {
    static displayName = App.name;
    
    constructor(props) {
        super(props);
        this.state = {
            isAuthorized: false,
        };
    }
    async componentDidMount() {
        try {
            if (sessionStorage.getItem('session_token') === '' || sessionStorage.getItem('session_token') === null) {
                this.setState({ isAuthorized: false });
            } else {
                const requestParams = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem('session_token')
                    },
                }
                const response = await fetch('/authentication/isauthenticated', requestParams);
                if (response.status == 200) {
                    this.setState({ isAuthorized: true });
                } else {
                    this.setState({ isAuthorized: false });
                }
            }

        } catch (e) {
            this.setState({ isAuthorized: false });
        }
    }


    render() {
        if (this.state.isAuthorized === true) {
            document.querySelector('body').classList.remove('login-page');
        }

        return (
            <div>
                {
                    this.state.isAuthorized === true ?
                        <AuthorisedLayout>
                            <Route exact path='/' component={DashboardComponent} />
                            <Route path='/dashboard' component={DashboardComponent} />
                            <Route path='/dispatch/list' component={DispatchComponent} />
                            <Route path='/dispatch/calendar' component={DispatchCalendarComponent} />
                            <Route path='/reservations' component={BookingComponent} />
                            <Route path='/crudride' component={BookingComponent} />
                            <Route path='/crudroute' component={RouteBookingComponent} />
                            <Route path='/route/list' component={RouteListComponent} />
                            <Route path='/vehicle/list' component={VehicleListComponent} />
                            <Route path='/vehicle/form' component={VehicleFormComponent} />
                            <Route path='/device/list' component={DeviceListComponent} />
                            <Route path='/users/list' component={UserListComponent} />
                            <Route path='/customer/list' component={CustomerListComponent} />
                            <Route path='/reservation/list' component={SearchReservationsComponent} />
                            <Route path='/tracking' component={TrackingComponent} />
                            <Route path='/franchisee/list' component={FranchiseeListComponent} />
                            <Route path='/location/list' component={LocationListComponent} />
                            <Route path='/drivers/list' component={DriverListComponent} />
                            <Route path='/customer/user' component={CustomerUserListComponent} />
                            <Route path='/servicearea/list' component={ServiceAreaComponent} />
                            <Route path='/serviceareazone/list' component={ServiceAreaZoneComponent} />
                            <Route path='/cannedmessage/list' component={CannedMessageComponent} />
                            <Route path='/account/setting' component={AccountSettingComponent} />
                            <Route path='/drivermessage/list' component={DriverMessageComponent} />
                            <Route path='/loggedvehicles/list' component={LoggedVehiclesComponent} />
                            <Route path='/loggeddrivers/list' component={LoggedDriversComponent} />
                            <Route path='/gpstrack/list' component={GpsTrackComponent}/>
                            <Route path='/trainingservices' component={TrainingServicesComponent} />
                            <Route path='/user/permission' component={UserPermissionComponent} />
                            <Route path='/uploadschedule' component={UploadScheduleComponent} />
                            <Route path='/noshow/list' component={NoShowReservationsComponent} />
                            <Route path='/quotation/list' component={QuotationListComponent} />
                            <Route path='/quote' component={QuoteComponent} />

                            <Route path='/nextstepuploadschedule' component={NextStepComponent} />
                            <Route path='/finallistuploadschedule' component={FinalListComponent} />
                            <Route path='/driver/login/history' component={DriverLoginHistoryListComponent} />
                            <Route path='/driver/work/history' component={DriverWorkHourHistoryListComponent} />
                            <Route path='/taxsetting/list' component={TaxSettingComponent} />
                            <Route path='/vehicletypetax/list' component={VehicleTypeTaxComponent} />
                            <Route path='/countyandcongestiontax/list' component={CountyAndCongestionTaxComponent} />
                            <Route path='/areawisetaxapplicable/list' component={AreaWiseTaxApplicableComponent} />
                            <Route path='/wireinstruction/list' component={WireInstructionComponent} />
                            <Route path='/zonalrate/list' component={ZonalRatesComponent} />
                            <Route path='/zonalrate/details' component={AddEditZonalRatesComponent} />
                            <Route path='/franchisee/deposits' component={VehicleDepositeComponent} />
                            <Route path='/franchisee/deductions' component={DeductionComponent} />
                            <Route path='/franchisee/addons' component={AddonsComponent} />
                            <Route path='/billing/unapprove' component={UnapproveRidesComponent} />
                            <Route path='/billing/approved' component={ApprovedRidesComponent} />
                            <Route path='/billing/ridestobeinvoiced' component={RidesToBeInvoicedComponent} />
                            <Route path='/invoice/generate' component={GenerateInvoiceComponent} />
                            <Route path='/invoice/draft' component={DraftInvoiceComponent} />
                            <Route path='/invoice/approved' component={ApprovedInvoiceComponent} />
                            <Route path='/systemfees' component={SystemFeesComponent} />

                            <Route path='/peding/postrides' component={PendingPostRidesComponent} />
                            <Route path='/reimbursement/history' component={ReimbursementHistoryComponent} />
                            <Route path='/reimbursement/payperiod' component={ReimbursementPayPeriodComponent} />
                            <Route path='/reimbursement/view' component={VehiclePaymentComponent} />
                            <Route path='/billing/crudfare' component={CrudFareComponent} />
                            <Route path='/posted/rides' component={PostedRidesComponent} />

                            <Route path='/generate/payout' component={GenerateVehiclePayout} />
                            <Route path='/post/payout' component={PostVehiclePayout} />
                            <Route path='/driver/documents' component={DriverDocumentComponent} />
                            <Route path='/vehicle/documents' component={VehicleDocumentComponent}/>
                            <Route path='/contractrate/list' component={ContractRatesComponent} />
                            <Route path='/contractrate/details' component={AddEditContractRatesComponent} />
                            <Route path='/kilometer/list' component={KilometerRatesComponent} />
                            <Route path='/kilometer/details' component={AddEditKilometerRatesComponent}/>
                            <Route path='/report' component={ReportComponent} />
                            <Route path='/reports/charterinvoicereport' component={CharterInvoiceReportComponent} />
                            <Route path='/reports/AccountSummaryPaymentTypeWise' component={AccountSummaryPaymentTypeWiseComponent} />
                            <Route path='/reports/customerridecommission' component={CustomerCommissionRideReportComponent} />
                            <Route path='/reports/invoiceduesummary' component={InvoiceDueSummaryReportComponent} />
                            <Route path='/reports/ridereconsilation' component={RideReconsilationReportComponent} />
                            <Route path='/reports/ridesapproved' component={RidesApprovedReportComponent} />
                            <Route path='/reports/ridesunapproved' component={RidesUnApprovedReportComponent} />
                            <Route path='/reports/ridesbilled' component={RidesBilledReportComponent} />
                            <Route path='/reports/invoicereceiptvoucher' component={InvoiceReceiptVoucherComponent} />
                            <Route path='/reports/airlinescorecard' component={AirlineScorecardReportComponent} />
                            <Route path='/reports/airlinerideactivity' component={AirlineRideActivityReportComponent} />
                            <Route path='/reports/vehiclesproductivity' component={VehiclesProductivityReportComponent} />
                            <Route path='/reports/vehicletripfare-summary' component={VehicleTripFareSummaryComponent} />
                            <Route path='/reports/dispatcherproductivity' component={DispatcherLoginWiseProductivityReportComponent} />
                            <Route path='/reports/reservationagentproductivity' component={ReservationAgentProductivityReportComponent} />
                            <Route path='/reports/weeklyrevenue-summary' component={WeeklyRevenueReportComponent} />
                            <Route path='/reports/agentcommission' component={AgentCommissionReportComponent} />
                            <Route path='/reports/auditreport' component={AuditReportComponent} />
                            <Route path='/reports/billingreconsilation' component={BillingReconsilationReportComponent} />
                            <Route path='/reports/vehiclecommission' component={VehicleCommissionReportComponent} />
                            <Route path='/reports/vehiclededuction' component={VehicleDeductionReportComponent} />
                            <Route path='/reports/vehicledeposit' component={VehicleDepositDetailsReportComponent} />
                            <Route path='/reports/invoicesales' component={InvoiceSalesReportComponent} />
                            <Route path='/reports/account-ride-details' component={AccountRideDetailsReportComponent} />
                            <Route path='/reports/accountpayments' component={AccountPaymentsReportComponent} />
                            <Route path='/reports/revenuesummarygraph' component={RevenueSummaryGraphReportComponent} />
                            <Route path='/reports/noshowreservations' component={NoShowReservationReportComponent} />
                            <Route path='/reports/driver-loginwise-ride-summary' component={DriverLoginwiseRideSummaryReportComponent} />
                            <Route path='/reports/statuswise-ride-summary' component={RideStatuswiseSummaryReportComponent} />
                            <Route path='/reports/noshowreservations' component={NoShowReservationReportComponent} />
                            <Route path='/reports/meetandgreet' component={MeetAndGreetReportComponent} />
                            <Route path='/reports/estimatereimbursement' component={EstimateReimbursementReportComponent} />
                            <Route path='/reports/driverpaid' component={DriverPaidReportComponent} />
                            <Route path='/reports/defferedrevenue' component={DeferredRevenueReportComponent} />
                            <Route path='/reports/creditcardhistory' component={CreditcardHistoryReportComponent} />
                            <Route path='/reports/openreservations' component={OpenReservationsReportComponent} />
                            <Route path='/reports/cancelledreservations' component={CancelledReservationsReportComponent} />
                            <Route path='/reports/closedreservations' component={ClosedReservationsReportComponent} />
                            <Route path='/reports/accountridepax' component={AccountRidePaxReportComponent} />
                            <Route path='/reports/hourly-ride-report' component={RideDetailsHourlyReportComponent} />
                            <Route path='/reports/tracklog' component={TrackLogComponent}/>
                            <Route path='/stop/list' component={StopListComponent} />
                            <Route path='/ratelookup/list' component={RateLookupComponent} />
                            <Route path='/billing/export/arinvoice' component={ExportARInoiceComponent} />
                            <Route path='/billing/export/arreceipt' component={ExportARReceiptComponent} />
                            <Route path='/pendingdispatch/list' component={PendingDispatchComponent} />
                            <Route path='/ridesclosed/list' component={RidesClosedComponent} />
                            <Route path='/meetgreetrides/list' component={MeetGreetRidesComponent} />
                            <Route path='/franchisee/chequeviewprint' component={ChequeViewPrintComponent} />
                            <Route path='/shuttle/schedule' component={ScheduleListComponent} />
                            <Route path='/shuttle/crudschedule' component={ScheduleComponent} />
                            <Route path='/franchisee/export/payroll' component={() => <ExportPayrollComponent isReExport={false} />} />
                            <Route path='/franchisee/export/reexportpayroll' component={() => <ExportPayrollComponent isReExport={true} />} />
                            <Route path='/franchisee/importcheques' component={ImportChequeComponent} />
                            <Route path='/franchisee/export/appayroll' component={ExportAPPayrollComponent} />
                            <Route path='/shuttle/booking' component={ShuttleBookingComponent} />
                            <Route path='/ticketing/tickets' component={TicketingComponent} />
                            <Route path='/ticketing/ticket/comment' component={TicketCommentComponent} />
                            <Route path='/passwordpolicy/config' component={PasswordPolicyConfigComponent} />
                            <Route path='/place' component={ServiceAreaZonePlaceComponent} />
                            <Route path='/feedback' component={CrewFeedbackComponent} />
                            <Route path='/reservation/uploadLuxaryExcel' component={UploadLuxaryExcelComponent} />
                            <Route path='/dispatch/flightSchedule' component={FlightScheduleComponent} />
                            <Route path='/crewmanagertracking' component={CrewManagerTrackingComponent} />
                            <Route path='/vehicle/bulk' component={VehicleBulkComponent} />
                            <Route path='/customer/bulk' component={CustomerBulkComponent} />
                            
                        </AuthorisedLayout>
                        :
                        
                        <Layout>
                            <Route exact path='/' component={LoginComponent} />
                            <Route path='/login' component={LoginComponent} />
                            <Route path='/verification' component={VerificationComponent} />
                            <Route path='/plan' component={PlanComponent} />
                            <Route path='/registration' component={AccountRegistrationComponent} />
                            <Route path='/accountsetup' component={AccountSetupComponent} />
                            <Route path='/complete' component={RegistrationCompleteComponent} />
                            <Route path='/forgotpassword' component={ForgotPasswordComponent} />
                            <Route path='/resetpassword' component={ResetPasswordComponent} />
                            <Route path='/confirmquote' component={QuotationConfirmationComponent} />
                            <Route path='/testtemplate' component={TestTemplateComponent} />
                        </Layout>
                }
            </div>
        );
    }
}