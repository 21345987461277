import React, { Component } from 'react';
import { SelectBox } from 'devextreme-react/select-box';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions';
import { LoadPanel } from 'devextreme-react/load-panel';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'
const position = { of: '#historydiv' };

export class VehicleReimbursmentSummaryComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,

            dropdownPayRollDate: [],
            payEndDate: '',
            reportType: 0,

            errors: {
                payEndDate: '',
            }
        }

        this.getPayPeriod = this.getPayPeriod.bind(this);
        this.handlePayPeriodChange = this.handlePayPeriodChange.bind(this);

        this.downloadAccountPaymentTypeSummaryAsPdf = this.downloadAccountPaymentTypeSummaryAsPdf.bind(this);

        this.handleSearchAccountPaySummaryReportSubmit = this.handleSearchAccountPaySummaryReportSubmit.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
    }


    showModal = async (reportType) => {
        try {
            this.setState({ isOpenModal: true, reportType: reportType });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getPayPeriod();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {

        this.setState({ isOpenModal: false });
        await this.handleClearSearch();
    }

    async getPayPeriod() {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('pay-period-api/0/dropdown-list', requestParams);
            const data = await response.json();
            this.setState({
                dropdownPayRollDate: data.vehiclePayPeriods,
            });

        } catch (e) {
            console.log(e);
        }
    }

    handlePayPeriodChange =(selectedOption)=> {
        try {
            let errors = this.state.errors;
            errors.payEndDate = '';
            let payEndDate = '';
            if (selectedOption !== null && selectedOption.selectedItem !== null) {
                payEndDate = selectedOption.selectedItem.valueDate === null ? '' : selectedOption.selectedItem.valueDate;
            }
            this.setState({ payEndDate: payEndDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }

    handleSearchAccountPaySummaryReportSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            this.downloadAccountPaymentTypeSummaryAsPdf();
        }
    }
    handleClearSearch() {
        this.setState({
            isSubmited: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            payEndDate: '',

            errors: {
                payEndDate: '',
            }
        });
    }

    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.customerCode = "";
        errors.invoiceNumber = "";

        if (this.state.payEndDate == "") {
            formIsValid = false;
            errors.payEndDate = "Please select pay roll date.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    async downloadAccountPaymentTypeSummaryAsPdf() {
        try {
            this.setState({
                isSubmited: true,
            });

            var request = new Object();
            request.LocationId = Number(sessionStorage.getItem("session_locationId"));
            // request.StartDate = moment(this.state.payEndDate, 'MM/DD/YYYY').format("YYYYMMDD");
            request.StartDate = new Date(this.state.payEndDate).toLocaleDateString() + '  23:59:59';
            request.MethodName = "GetVehicleReimbursmentSummary";
            request.fileFormat = 'pdf';
            request.ReportType = parseInt(this.state.reportType);

            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            this.setState({
                isSubmited: false,
            });

            let a = document.createElement('a');
            a.href = dataResponse.reportBase64String;
            if (this.state.reportType == 1) {
                a.download = 'VehicleReimbursmentSummary.pdf';
            } else if (this.state.reportType == 2) {
                a.download = 'VehiclePayRollSummary.pdf';
            } else if (this.state.reportType == 3) {
                a.download = 'VehiclePayRollUFBalance.pdf';
            }

            a.click();
        } catch (e) {
            this.setState({
                isSubmited: false,
            });
            console.log(e)
        }
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isEmailSubmitted: true });
                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                // request.StartDate = moment(this.state.payEndDate, 'MM/DD/YYYY').format("YYYYMMDD");
                request.StartDate = new Date(this.state.payEndDate).toLocaleDateString() + '  23:59:59';
                request.MethodName = "GetVehicleReimbursmentSummary";
                request.fileFormat = 'pdf';
                request.ReportType = parseInt(this.state.reportType);
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();

                let fileName = '';
                if (this.state.reportType === 1) {
                    fileName = 'VehicleReimbursmentSummary.pdf';
                } else if (this.state.reportType == 2) {
                    fileName = 'VehiclePayRollSummary.pdf';
                } else if (this.state.reportType == 3) {
                    fileName = 'VehiclePayRollUFBalance.pdf';
                }
                let reportName = '';
                if (this.state.reportType === 0) {
                    reportName = 'Vehicle Reimbursment Summary';
                } else if (this.state.reportType === 1) {
                    reportName = 'Vehicle Reimbursment Summary';
                } else if (this.state.reportType === 2) {
                    reportName = 'Vehicle Pay Roll Summary';
                } else if (this.state.reportType === 3) {
                    reportName = 'Vehicle Pay Roll UF Balance';
                }
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = reportName;
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = fileName;
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        let reportName = '';
        if (this.state.reportType === 0) {
            reportName = 'Vehicle Reimbursment Summary';
        } else if (this.state.reportType === 1) {
            reportName = 'Vehicle Reimbursment Summary';
        } else if (this.state.reportType === 2) {
            reportName = 'Vehicle Pay Roll Summary';
        } else if (this.state.reportType === 3) {
            reportName = 'Vehicle Pay Roll UF Balance';
        }
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '40  %' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleSearchAccountPaySummaryReportSubmit}
                        style={(this.state.isSubmited || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{reportName}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Pay Roll Date</label>
                                        <SelectBox
                                            dataSource={this.state.dropdownPayRollDate}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select payroll date"
                                            name="ddpayroll"
                                            defaultValue={''}
                                            value={this.state.payEndDate}
                                            displayExpr='viewDate'
                                            valueExpr='valueDate'
                                            onSelectionChanged={this.handlePayPeriodChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["payEndDate"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["payEndDate"]}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="button" className="btn btn-success btn-sm" title="Download" onClick={this.handleSearchAccountPaySummaryReportSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Search"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                                </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        )
    }
}