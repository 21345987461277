import React, { Component } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Modal from 'react-modal'; //npm install --save react-modal
import 'react-tabs/style/react-tabs.css';
import { MasterHistoryComponent } from '../Shared/MasterHistoryComponent';
import { FeatureActionType } from '../../helpers/fixcodes.js'
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import CheckboxTree from 'react-checkbox-tree';// npm install react-checkbox-tree --save
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import Swal from 'sweetalert2';
const customStyles = {
    content: {
        width: '30%',
    }
};
export class UserPermissionComponent extends Component {
    constructor() {
        super()
        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            userModalIsOpen: false,
            singelData: null,
            isOpenSetRoleUserAccessModal: false,
            isOpenNonRoleUserModal: false,
            roleGridDataSource: null,
            roleData: null,
            roleAppliedUserGridDataSource: null,
            roleNotAppliedUserGridDataSource: null,
            featureTree: {
                isActiveFeaturePermissonTab: false,
                roleFeatureDataSource: [],
                roleFeatureList: [],
                checkedNodes: [],
                expandedNodes: [],
                clickedNodes: [],
                saveFeatureMenuIds: [],
                filterText: '',
            },
            roleId: 0,
            roleName: '',
            newRoleName:'',
            isRoleActive: false,
            errors: {
                roleName: '',
                newRoleName:'',
            }
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.editUserControl = this.editUserControl.bind(this);
        this.handleUserSubmit = this.handleUserSubmit.bind(this);
        this.closeUserModal = this.closeUserModal.bind(this);
    }
    componentDidMount() {
        this.getRolesList();
    }
    async getRolesList() {
        try {
            var request = new Object();
            request.Id = 0
            const requestParams = getRequestParams('GET');
            const response = await fetch('role-api/' + request.Id + '/search', requestParams);
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    roleGridDataSource: data.roles
                });
            }

        } catch (e) {
        }
    };
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            ["newRoleName"]: value
        });

        this.state.errors[name] = "";
    }
    editUserControl(e, data) {
        e.preventDefault();
        this.setState({
            userModalIsOpen: true,
            singelData: data,
            roleId: data === null ? 0 : data.id,
            newRoleName: data === null ? 0 : data.name
        });
    }
    closeUserModal(e) {
        e.preventDefault();
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            userModalIsOpen: false,
            singelData: null,
            roleId: 0,
            roleName: '',
            newRoleName: '',
            isRoleActive: false,
            errors: {
                roleName: '',
                newRoleName: '',
            }
        });
    }
    async getRoleUserList() {
        if (Number(this.state.roleId) > 0) {
            try {
                const requestParams = getRequestParams('GET');
                const response = await fetch('role-api/' + Number(this.state.roleId) + '/users/', requestParams);
                if (response.ok) {
                    let data = await response.json();
                    data = JSON.parse(JSON.stringify(data.userRoles));
                    const roleAppliedUserGridDataSource = data.filter(item => item.isSetAccess === true);
                    const roleNotAppliedUserGridDataSource = data.filter(item => item.isSetAccess === false);
                    this.setState({
                        roleAppliedUserGridDataSource: roleAppliedUserGridDataSource,
                        roleNotAppliedUserGridDataSource: roleNotAppliedUserGridDataSource,
                    });
                }
            } catch (e) {
            }
        }
    };
    async getRoleFeatureList() {
        try {
            if (Number(this.state.roleId) > 0) {
                const requestParams = getRequestParams('GET');
                const response = await fetch('role-api/' + Number(this.state.roleId) + '/features/' + Number(FeatureActionType.UTWiz), requestParams);
                if (response.ok) {
                    let data = await response.json();
                    data = JSON.parse(JSON.stringify(data.roleFeatures));

                    //set the original feature data
                    let featureTree = this.state.featureTree;
                    featureTree.roleFeatureList = data;
                    this.setState({ featureTree: featureTree });
                    //set the default checked
                    let checkedNode = data.filter(function (el) {
                        return el.isSetAccess === true //&& el.parentMenuId !== 0;
                    });
                    let checkedNodeIds = [];
                    checkedNode.forEach((item) => {
                        let id = item.childMenuId;
                        checkedNodeIds.push(id);
                    });
                    featureTree.checkedNodes = checkedNodeIds;
                    this.setState({ featureTree: featureTree });
                    //bind feature tree
                    this.filterBindFeatureNodeTree('');
                }
            }
        } catch (e) {
        }
    }
    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.newRoleName = "";

        if (this.state.newRoleName.trim() == '') {
            formIsValid = false;
            errors.newRoleName = "Please enter rolename.";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    async handleUserSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            this.setState({ isSubmited: true });
            
            var newObject = new Object();
            newObject.Name = this.state.newRoleName;

            try {
                //const requestParams = {};
                //if (this.state.roleId > 0) {
                //    requestParams = getRequestParams('PUT', newObject);
                //}
                //else {
                //    requestParams = getRequestParams('POST', newObject);
                //}

                const requestParams = this.state.roleId > 0 ?
                        getRequestParams('PUT', newObject) : getRequestParams('POST', newObject);
                let path = this.state.roleId > 0 ? 'role-api/' + this.state.roleId + '/editrole' : 'role-api/addrole';
                const response = await fetch(path, requestParams);
                const data = await response.json();

                Swal.fire({
                    icon: data.isSuccess ? 'success' : 'error',
                    title: data.isSuccess ? 'Success' : 'Oops...',
                    text: data.resultMessage,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            this.closeUserModal(e);
                            //window.location.href = "/users/list";
                            this.getRolesList();
                        }
                    } else {
                        this.setState({ isSubmited: false })
                    }
                })
            } catch (e) {
                this.setState({ isSubmited: false })
            }
        }
    }
    filterBindFeatureNodeTree(filterText) {
        let treeObject = [];
        let isFiltered = false;
        let data = JSON.parse(JSON.stringify(this.state.featureTree.roleFeatureList));
        data = data.filter(item => item.childMenuName !== null && item.childMenuName !== '');
        if (filterText.trim().length !== 0) {
            filterText = filterText.toLowerCase();
            var reg = new RegExp(filterText.split('').join('.*'), 'i');
            data = data.filter(function (item) {
                if (item.childMenuName.trim().toLowerCase().match(reg)) {
                    return item;
                }
            });
            data = JSON.parse(JSON.stringify(data));
            isFiltered = true;
        }
        data.forEach((item) => {
            if (isFiltered == true) {
                let mainroot = new Object();
                mainroot.value = item.childMenuId;
                mainroot.label = item.childMenuName;
                let chkSubNode = data.filter(el => el.parentMenuId === item.childMenuId);
                if (chkSubNode.length > 0) {
                    mainroot.children = [];
                    this.bindChildFeatureNodeTree(data, mainroot, item.childMenuId);
                }
                treeObject.push(mainroot);
            }
            else {
                if (item.parentMenuId === 0) {
                    let mainroot = new Object();
                    mainroot.value = item.childMenuId;
                    mainroot.label = item.childMenuName;
                    let chkSubNode = data.filter(el => el.parentMenuId === item.childMenuId);
                    if (chkSubNode.length > 0) {
                        mainroot.children = [];
                        this.bindChildFeatureNodeTree(data, mainroot, item.childMenuId);
                    }
                    treeObject.push(mainroot);
                }
            }
        });
        //set data source
        let featureTree = this.state.featureTree;
        data = JSON.parse(JSON.stringify(treeObject));
        featureTree.roleFeatureDataSource = data;
        this.setState({ featureTree: featureTree });
    }
    bindChildFeatureNodeTree(data, mainroot, parentMenuId) {
        let subNode = data.filter(function (el) {
            return el.parentMenuId === parentMenuId;
        });
        subNode.forEach((item) => {
            let subroot = new Object();
            subroot.value = item.childMenuId;
            subroot.label = item.childMenuName;
            let chkSubNode = data.filter(el => el.parentMenuId === item.childMenuId);
            if (chkSubNode.length > 0) {
                subroot.children = [];
            }
            mainroot.children.push(subroot);
            this.bindChildFeatureNodeTree(data, subroot, item.childMenuId);

        });
    }
    handleOnCheckedNodes = (checked) => {
        let featureTree = this.state.featureTree;
        featureTree.checkedNodes = checked;
        this.setState({ featureTree: featureTree });
    }
    handleOnClickedNodes = (clicked) => {
        let featureTree = this.state.featureTree;
        featureTree.clickedNodes = clicked;
        this.setState({ featureTree: featureTree });
    }
    handleOnExpandedNodes = (expanded) => {
        let featureTree = this.state.featureTree;
        featureTree.expandedNodes = expanded;
        this.setState({ featureTree: featureTree });
    }
    handleOnChangeFilterNodes = (event) => {
        event.preventDefault();
        if (event.target.value.trim() !== '') {
            this.filterBindFeatureNodeTree(event.target.value.trim());
        }
        else {
            this.filterBindFeatureNodeTree('');
        }
        let featureTree = this.state.featureTree;
        featureTree.filterText = event.target.value.trim();
        this.setState({ featureTree: featureTree });
    }
    handleToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getRolesList.bind(this)
            }
        });
    }
    handleToolbarPreparingAppliedUserRole = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getRoleUserList.bind(this)
            }
        });
    }
    handleToolbarPreparingNotAppliedUserRole = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getRoleUserList.bind(this)
            }
        });
    }
    handleMasterHistoryModal(e, data) {
        e.preventDefault();
        this.refs.masterHistoryComponent.showModal('role-api', 'User Permission', data.name, data.id === null ? 0 : Number(data.id));
    }
    async handleSetRoleUserAccess(e, data) {
        e.preventDefault();
        this.setState({
            isOpenSetRoleUserAccessModal: true,
            roleData: data
        });
        //await this.getRoleUserList(Number(data.id));
        //await this.getRoleFeatureList(Number(data.id));
    }
    handleAssignDataToState = async () => {
        if (this.state.roleData === null) {
            this.setState({
                roleId: 0,
                roleName: "",
                isRoleActive: false
            });
        } else {
            const role = this.state.roleData;
            this.setState({
                roleId: role.id === null ? 0 : Number(role.id),
                roleName: role.name === null ? '' : role.name.trim(),
                isRoleActive: role.enable || false,
            });
            await new Promise(resolve => setTimeout(resolve, 1000)); // 3 sec
            await this.getRoleUserList();
            await this.getRoleFeatureList();
        }
    }
    handleResetStateData = () => {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            userModalIsOpen: false,
            singelData: null,
            isOpenSetRoleUserAccessModal: false,
            isOpenNonRoleUserModal: false,
            roleData: null,
            roleAppliedUserGridDataSource: null,
            roleNotAppliedUserGridDataSource: null,
            featureTree: {
                isActiveFeaturePermissonTab: false,
                roleFeatureDataSource: [],
                roleFeatureList: [],
                checkedNodes: [],
                expandedNodes: [],
                clickedNodes: [],
                saveFeatureMenuIds: [],
                filterText: '',
            },
            roleId: 0,
            roleName: '',
            newRoleName: '',
            isRoleActive: false,
            errors: {
                roleName: '',
                newRoleName: '',
            }
        });
    }
    handleSaveRoleUser = async (e, data, isActive) => {
        e.preventDefault();
        if (!isActive) {
            const isConfirm = await this.isSaveConfirm(false, isActive);
            if (!isConfirm) {
                return;
            }
        }
        this.setState({ isSubmited: true });
        try {
            var request = new Object();
            request.UserId = data.userId
            request.Enable = isActive
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('role-api/' + this.state.roleId + '/user', requestParams);
            if (response.ok) {
                const responseData = await response.json();
                if (responseData.isSuccess) {
                    await this.getRoleUserList();
                }
            }
        } catch (e) {
        }
        this.setState({ isSubmited: false })
    }
    async isSaveConfirm(isForFeature, isActive) {
        let isResult = false;
        let comfirmationMessage = (isForFeature ? 'Are you sure want to save?' : 'Are you sure want to ' + (isActive ? 'Add' : 'Remove') + '?');
        let confirmButtonText = (isForFeature ? 'Yes Save it!' : 'Yes, ' + (isActive ? 'Add' : 'Remove') + ' it!');
        await Swal.fire({
            title: '<small><b>Confirmation</b></small>',
            html: comfirmationMessage,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: confirmButtonText,
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }
    handleSaveRoleFeature = async (e) => {
        e.preventDefault();
        try {
            const isConfirm = await this.isSaveConfirm(true, false);
            if (!isConfirm) {
                return;
            }
            this.setState({ isSubmited: true });            
            let featureTree = this.state.featureTree;
            featureTree.saveFeatureMenuIds = [];
            this.setState({ featureTree: featureTree });
            let checkedNodes = this.state.featureTree.checkedNodes;
            checkedNodes.forEach((childMenuId) => {
                this.state.featureTree.saveFeatureMenuIds.push(Number(childMenuId));
                this.getParentMenuIdToSave(Number(childMenuId));
            });
            var meneIds = [];
            this.state.featureTree.saveFeatureMenuIds.forEach((menuId) => {
                var checkedManueId = new Object();
                checkedManueId.ChildMenuId = Number(menuId);
                checkedManueId.IsSetAccess = true;
                meneIds.push(checkedManueId);
            });
            this.state.featureTree.roleFeatureList.forEach((menu) => {
                let menuExists = meneIds.filter(function (el) {
                    return el.ChildMenuId === menu.childMenuId;
                });
                if (menuExists.length === 0) {
                    var checkedManueId = new Object();
                    checkedManueId.ChildMenuId = Number(menu.childMenuId);
                    checkedManueId.IsSetAccess = false;
                    meneIds.push(checkedManueId);
                }
            });
            var request = new Object();
            request.RoleFeatures = new Object();
            request.RoleFeatures = meneIds
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('role-api/' + this.state.roleId + '/feature', requestParams);
            if (response.ok) {
                const responseData = await response.json();
                Swal.fire({
                    icon: responseData.isSuccess ? 'success' : 'error',
                    title: responseData.isSuccess ? 'Success' : 'Oops...',
                    text: (responseData.isSuccess === false && (responseData.resultMessage === null || responseData.resultMessage === "")) ? 'Failed! to process the request.' : (responseData.resultMessage === null || responseData.resultMessage === "") ? 'Role feature(s) has been successfully updated.' : responseData.resultMessage,
                }).then((result) => {
                    if (responseData.isSuccess) {
                        if (result.isConfirmed) {
                            this.setState({ isSubmited: false })
                            let featureTree = this.state.featureTree;
                            featureTree.filterText = '';
                            this.setState({ featureTree: featureTree });
                        }
                    } else {
                        this.setState({ isSubmited: false })
                    }
                })
                await this.getRoleFeatureList();
            }
        } catch (e) {
            this.setState({ isSubmited: false })
        }
        this.setState({ isSubmited: false })
    }
    getParentMenuIdToSave(childMenuId) {
        let parentMenuId = this.state.featureTree.roleFeatureList.filter(el => el.childMenuId === Number(childMenuId));
        parentMenuId.forEach((parentMenu) => {
            if (parentMenu.parentMenuId > 0) {
                let menuExists = this.state.featureTree.saveFeatureMenuIds.filter(function (menuId) {
                    return menuId === parentMenu.parentMenuId;
                });
                if (menuExists.length === 0) {
                    this.state.featureTree.saveFeatureMenuIds.push(Number(parentMenu.parentMenuId));
                    this.getParentMenuIdToSave(parentMenu.parentMenuId);
                }
            }
        });
    }
    handleCloseNonRoleUserModal = (e) => {
        e.preventDefault();
        this.setState({
            isSubmited: false,
            isOpenNonRoleUserModal: false
        });
    }
    render() {
        const renderRoleGridCell = (data) => {
            return <div>
                <a href="#" className="fas fa-pen icon text-info" title="Edit" onClick={(e) => { this.editUserControl(e, data.data) }} ></a>&nbsp;&nbsp;
                <a href="#" title="Set Role Access" onClick={(e) => { this.handleSetRoleUserAccess(e, data.data) }} ><i className="fas fa-user"></i></a>&nbsp;&nbsp;
                {/*<a href="#" title="History" onClick={(e) => { this.handleMasterHistoryModal(e, data.data) }}><i className="fas fa-history"></i></a>&nbsp;&nbsp;*/}
            </div>;
        }
        const renderRoleUserRemoveGridCell = (data) => {
            return <div>
                <a title="Remove" onClick={(e) => { this.handleSaveRoleUser(e, data.data, false) }}>
                    {this.state.isSubmited && <i className="fas fa-spinner fa-spin"></i>}
                    {!this.state.isSubmited && <i className="fas fa-times"></i>}
                </a>
            </div>;
        }
        const renderRoleUserAddGridCell = (data) => {
            return <div>
                <a title="Add" onClick={(e) => { this.handleSaveRoleUser(e, data.data, true) }}>
                    {this.state.isSubmited && <i className="fas fa-spinner fa-spin"></i>}
                    {!this.state.isSubmited && <i className="fas fa-plus"></i>}
                </a>
            </div>;
        }
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <h1 className="m-0 text-dark">User Permission</h1>
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8 text-right">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem><NavLink tag={Link} to="#" onClick={(e) => { this.editUserControl(e, null) }} className="btn btn-block btn-outline-secondary btn-sm" title="Add Role"><i className="fas fa-plus"></i> Add Role</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/drivermessage/list" className="btn btn-block btn-outline-secondary btn-sm" title="Ground User"><i className="fas fa-user-alt"></i> Ground User</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/loggeddrivers/list" className="btn btn-block btn-outline-secondary btn-sm" title="Customer User"><i className="fas fa-user"></i> Customer User</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/loggedvehicles/list" className="btn btn-block btn-outline-secondary btn-sm" title="Drivers"><i className="fas fa-car"></i> Drivers</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="card card-grid">
                        <div className="card-body">
                            <DataGrid
                                dataSource={this.state.roleGridDataSource}
                                keyExpr="id"
                                selection={{ mode: 'single' }}
                                columnsAutoWidth={false}
                                showColumnLines={true}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                onToolbarPreparing={this.handleToolbarPreparing}
                            >
                                <Column dataField="id" width={40} caption="" allowFiltering={false} allowSorting={false} cellRender={renderRoleGridCell} />
                                <Column dataField="name" caption="Role Name" />
                                <Column dataField="status" caption="Status" width={80} />
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                <Export enabled={true} allowExportSelectedData={false} />
                                <ColumnChooser enabled={true} />
                                <ColumnFixing enabled={true} />
                            </DataGrid>
                        </div>
                    </div>
                </div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.userModalIsOpen}
                    style={customStyles}
                    className={"react-modal"}
                    onAfterOpen={this.afterOpenUserModal}
                    onRequestClose={this.closeUserModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleUserSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{this.state.userId > 0 ? "Modify Role" : "Add Role"}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeUserModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group">
                                        <label>Role Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="newRoleName" placeholder="Enter Role Name" maxLength="25" title="Role Name" value={this.state.newRoleName} onChange={this.handleInputChange} />
                                        {this.state.errors["newRoleName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["newRoleName"]}</span>}
                                    </div>
                                </div>
                            </div>                            
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success btn-sm" onClick={this.handleUserSubmit} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeUserModal} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenSetRoleUserAccessModal}
                    //style={this.state.featureTree.isActiveFeaturePermissonTab ? { content: { width: '40%' } } : {}}
                    style={{ content: { width: '50%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleAssignDataToState}
                    onRequestClose={this.handleResetStateData}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Role Permission - <strong>{this.state.roleName}</strong></h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleResetStateData}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="content">
                                <ul className="nav nav-tabs" id="custom-content-below-tab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="user-permission-tab" data-toggle="pill" href="#user-permission" role="tab" aria-controls="user-permission" aria-selected="true" onClick={() => { let ft = this.state.featureTree; ft.isActiveFeaturePermissonTab = false; this.setState({ featureTree: ft }) }}>User Permission</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="Feature-permission-tab" data-toggle="pill" href="#feature-Permission" role="tab" aria-controls="feature-Permission" aria-selected="false" onClick={() => { let ft = this.state.featureTree; ft.isActiveFeaturePermissonTab = true; this.setState({ featureTree: ft }) }}>Feature Permission</a>
                                    </li>
                                    <NavLink className="btn btn-outline-secondary btn-sm" style={{ display: this.state.featureTree.isActiveFeaturePermissonTab ? 'none' : 'inline-block', cursor: 'pointer', position: 'absolute', right: '15px', paddingTop: 3 + 'px', paddingLeft: 5 + 'px', paddingBottom: 3 + 'px', paddingRight: 5 + 'px' }} title="Add User" onClick={() => { this.setState({ isOpenNonRoleUserModal: true }) }} ><i className="fas fa-plus"></i> Add</NavLink>
                                </ul>
                                <div className="tab-content" id="custom-content-below-tabContent">
                                    <div className="tab-pane fade show active" id="user-permission" role="tabpanel" aria-labelledby="user-permission-tab">
                                        <div style={{ height: 285 + 'px', overflowY: 'auto', overflowX:'hidden' }}>
                                            <DataGrid
                                                dataSource={this.state.roleAppliedUserGridDataSource}
                                                keyExpr="id"
                                                selection={{ mode: 'single' }}
                                                columnsAutoWidth={false}
                                                showColumnLines={true}
                                                showRowLines={true}
                                                showBorders={true}
                                                rowAlternationEnabled={true}
                                                allowColumnResizing={true}
                                                columnResizingMode='widget'
                                                onToolbarPreparing={this.handleToolbarPreparingAppliedUserRole}
                                            >
                                                <Column dataField="id" width={30} caption="" alignment={"center"} allowFiltering={false} allowSorting={false} cellRender={renderRoleUserRemoveGridCell} />
                                                <Column dataField="fullName" caption="Full Name" allowFiltering={true} allowSorting={true} />
                                                <Column dataField="userName" caption="User Name" allowFiltering={true} allowSorting={true} />
                                                <Column dataField="userType" caption="User Type" allowFiltering={true} allowSorting={true} />
                                                <Column dataField="email" caption="Email" allowSorting={false} />
                                                <SortByGroupSummaryInfo summaryItem="count" />
                                                <GroupPanel visible={false} />
                                                <HeaderFilter visible={false} />
                                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                                <Grouping autoExpandAll={false} />
                                                <Paging defaultPageSize={5} />
                                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                                <Export enabled={false} allowExportSelectedData={false} />
                                                <ColumnChooser enabled={false} />
                                                <ColumnFixing enabled={false} />
                                            </DataGrid>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="feature-Permission" role="tabpanel" aria-labelledby="feature-Permission-tab">
                                        <div style={{ height: 250 + 'px', overflowY: 'auto', overflowX: 'hidden' }}>
                                            <div className="filter-container">
                                                <div className="form-group">
                                                    <input
                                                        className="filter-text form-control form-textbox form-control-sm"
                                                        placeholder="Search..."
                                                        type="text"
                                                        value={this.state.featureTree.filterText}
                                                        onChange={(e) => { this.handleOnChangeFilterNodes(e) }}
                                                    />
                                                </div>
                                                <CheckboxTree
                                                    iconsClass="fa5"
                                                    showExpandAll={true}
                                                    nodes={this.state.featureTree.roleFeatureDataSource}
                                                    checked={this.state.featureTree.checkedNodes}
                                                    expanded={this.state.featureTree.expandedNodes}
                                                    onCheck={this.handleOnCheckedNodes}
                                                    onExpand={this.handleOnExpandedNodes}
                                                    onClick={this.handleOnClickedNodes}
                                                    expandOnClick={true}
                                                    icons={{
                                                        check: <span className="rct-icon rct-icon-check" />,
                                                        uncheck: <span className="rct-icon rct-icon-uncheck" />,
                                                        halfCheck: <span className="rct-icon rct-icon-half-check" />,
                                                        expandClose: <span className="rct-icon rct-icon-expand-close" />,
                                                        expandOpen: <span className="rct-icon rct-icon-expand-open" />,
                                                        expandAll: <span className="rct-icon rct-icon-expand-all" />,
                                                        collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
                                                        parentClose: <span className="rct-icon rct-icon-parent-close" />,
                                                        parentOpen: <span className="rct-icon rct-icon-parent-open" />,
                                                        leaf: <span className="rct-icon rct-icon-leaf" />,
                                                    }}
                                                    nativeCheckboxes={true}
                                                //noCascade={true}  //Uncomment this code to get and update status of parent menu id 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" style={{ display: this.state.featureTree.isActiveFeaturePermissonTab ? 'block' : 'none' }} onClick={(e) => { this.handleSaveRoleFeature(e) }}>
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleResetStateData}><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal >
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenNonRoleUserModal}
                    style={{ content: { width: '50%' } }}
                    className={"react-modal"}
                    onRequestClose={this.handleCloseNonRoleUserModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Users who have not applied the role <strong>{this.state.roleName}</strong></h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCloseNonRoleUserModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="content">
                                <div style={{ height: 285 + 'px', overflowY: 'auto', overflowX: 'hidden' }}>
                                    <DataGrid
                                        dataSource={this.state.roleNotAppliedUserGridDataSource}
                                        keyExpr="id"
                                        selection={{ mode: 'single' }}
                                        columnsAutoWidth={false}
                                        showColumnLines={true}
                                        showRowLines={true}
                                        showBorders={true}
                                        rowAlternationEnabled={true}
                                        allowColumnResizing={true}
                                        columnResizingMode='widget'
                                        onToolbarPreparing={this.handleToolbarPreparingNotAppliedUserRole}
                                    >
                                        <Column dataField="id" width={30} caption="" alignment={"center"} allowFiltering={false} allowSorting={false} cellRender={renderRoleUserAddGridCell} />
                                        <Column dataField="fullName" caption="Full Name" allowFiltering={true} allowSorting={true} />
                                        <Column dataField="userName" caption="User Name" allowFiltering={true} allowSorting={true} />
                                        <Column dataField="userType" caption="User Type" allowFiltering={true} allowSorting={true} />
                                        <Column dataField="email" caption="Email" allowFiltering={true} allowSorting={false} />
                                        <SortByGroupSummaryInfo summaryItem="count" />
                                        <GroupPanel visible={false} />
                                        <HeaderFilter visible={false} />
                                        <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                        <Grouping autoExpandAll={false} />
                                        <Paging defaultPageSize={5} />
                                        <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                        <Export enabled={false} allowExportSelectedData={false} />
                                        <ColumnChooser enabled={false} />
                                        <ColumnFixing enabled={false} />
                                    </DataGrid>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleCloseNonRoleUserModal}><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <MasterHistoryComponent ref="masterHistoryComponent" />
            </div >
        );
    }
}
