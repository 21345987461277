import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    Selection,
    Scrolling,
    SortByGroupSummaryInfo,
    FilterRow
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import DropDownBox from 'devextreme-react/drop-down-box';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions';
import DateBox from 'devextreme-react/date-box';
import { DateFormat } from '../../helpers/fixcodes';
import { ReservationHistoryComponent } from '../Shared/ReservationHistoryComponent';
import { NoShowReservationComponent } from '../Shared/NoShowReservationComponent';

let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class RidesClosedComponent extends Component {

    constructor(props) {
        super(props);

        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            datasource: null,
            startDate: new Date(new Date().setDate(new Date().getDate() - 15)),
            endDate: new Date(),
            vehicleGroupTypesList: [],
            filterVehicleGroupTypeList: [],
            refreshDataSource: null,
        }

        this.getVehicleGroupTypeList = this.getVehicleGroupTypeList.bind(this);

        this.handleFromDateChanged = this.handleFromDateChanged.bind(this);
        this.handleToDateChanged = this.handleToDateChanged.bind(this);
        this.openEditReservationModel = this.openEditReservationModel.bind(this);
        this.openReservationHistoryModel = this.openReservationHistoryModel.bind(this);
        
        this.handleRideClosedSubmit = this.handleRideClosedSubmit.bind(this);
        this.handelToolbarPreparing = this.handelToolbarPreparing.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

        this.onSelectionVehicleTypeChanged = this.onSelectionVehicleTypeChanged.bind(this);
        this.syncDataGridVehicleTypeSelection = this.syncDataGridVehicleTypeSelection.bind(this);
        this.dataGridVehicleTypeRender = this.dataGridVehicleTypeRender.bind(this);
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true,
        });
        await this.getVehicleGroupTypeList();
        this.setState({
            loadPanelVisible: false,
        });
    }
    async getVehicleGroupTypeList() {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/vehicle-group-type-api/vehicle-group-type', requestParams);
            const data = await response.json();
            if (data.isSuccess) {
                this.setState({
                    vehicleGroupTypesList: data.vehicleGroupTypes
                });
            }
        } catch (e) {
            console.log(e);
        }
    }
    handleFromDateChanged(event) {
        let fromDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ startDate: fromDateValue })
    }
    handleToDateChanged(event) {
        let toDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ endDate: toDateValue })
    }
    handelToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getClosedRidesList.bind(this)
            }
        });
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }
    openEditReservationModel(e, data) {
        e.preventDefault();
        this.props.history.push({
            pathname: '/crudride',
            //state: { detail: data }
            state: { rideId: data.rideId, sourceUrl: "/reservation/list", sourceState: this.state }
        })
    }
    openReservationHistoryModel(e, data) {
        e.preventDefault();
        this.refs.reservationHistoryModal.showModal("Reservation", data.rideId);
    }
    openNoShowReservationModal = (e, data) => {
        e.preventDefault();
        this.refs.noShowReservationModal.showModal("No-Show Reservation", data.rideId, data.reservationId, data.vehicleNo, data.driverId);
    }
    async handleRideClosedSubmit(e) {
        await this.getClosedRidesList();
    }
    async getClosedRidesList() {
        let tripVehicleGroupType = "";

        for (let vt = 0; vt < this.state.filterVehicleGroupTypeList.length; vt++) {
            tripVehicleGroupType += this.state.filterVehicleGroupTypeList[vt] + ",";
            tripVehicleGroupType.substring(0, tripVehicleGroupType.length - 1);
        }

        var request = new Object();
        request.VehicleTypeId = "";
        request.VehicleGroupTypeId = tripVehicleGroupType;
        request.FromDate = formatDate(this.state.startDate, "MM/dd/yyyy hh:mm");;
        request.ToDate = formatDate(this.state.endDate, "MM/dd/yyyy hh:mm");

        this.setState({ isSubmited: true });
        try {
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('/manage-rides-api/closed-rides', requestParams);
            const data = await response.json();

            this.setState({
                datasource: data.ridesClosed,
            });

            this.setState({ isSubmited: false });

        } catch (e) {
            this.setState({ isSubmited: false });
        }

    }
    handleClearSearch() {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            datasource: null,
            startDate: new Date(new Date().setDate(new Date().getDate() - 15)),
            endDate: new Date(),
            filterVehicleGroupTypeList: [],
        });
    }
    render() {
        const renderNewResQtCell = (data) => {
            return <div>&nbsp;&nbsp;
                <a href="#" className="fas fa-pen icon text-info" title="View" onClick={(e) => { this.openEditReservationModel(e, data.data) }}></a>&nbsp;&nbsp;
                <a href="#" className="fas fa-history icon text-info" title="History" onClick={(e) => { this.openReservationHistoryModel(e, data.data) }}></a>&nbsp;&nbsp;
                <a href="#" className="fas fa-eye-slash" title="No-Show Reservation" onClick={(e) => { this.openNoShowReservationModal(e, data.data) }}></a>&nbsp;&nbsp;
            </div>;
        }
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <h1 className="m-0 text-dark">Rides Closed</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem><NavLink tag={Link} to="/quote" className="btn btn-block btn-outline-secondary btn-sm" title="New Quotation"><i className="fas fa-plus"></i> New Quotation</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/reservations" className="btn btn-block btn-outline-secondary btn-sm" title="New Reservation"><i className="fas fa-plus"></i> New Reservation</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/reservation/list" className="btn btn-block btn-outline-secondary btn-sm" title="Search Reservation"><i className="fas fa-search"></i> Search Reservation</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/uploadschedule" className="btn btn-block btn-outline-secondary btn-sm" title="Upload Schedule"><i className="fas fa-clipboard-list"></i> Upload Schedule</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rez-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                <div className="form-group">
                                    <label>From Date</label>
                                    <DateBox type="date"
                                        className="form-control form-control-sm"
                                        name="fromDate"
                                        placeholder="mm/dd/yyyy"
                                        useMaskBehavior={true}
                                        defaultValue={this.state.startDate}
                                        value={this.state.startDate}
                                        onValueChanged={this.handleFromDateChanged}
                                        width="100%"
                                        acceptCustomValue={false}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                <div className="form-group">
                                    <label>To Date</label>
                                    <DateBox type="date"
                                        className="form-control form-control-sm"
                                        name="toDate"
                                        placeholder="mm/dd/yyyy"
                                        useMaskBehavior={true}
                                        defaultValue={this.state.endDate}
                                        value={this.state.endDate}
                                        onValueChanged={this.handleToDateChanged}
                                        width="100%"
                                        acceptCustomValue={false}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <div className="form-group">
                                    <label>Vehicle Group</label>
                                    <DropDownBox
                                        value={this.state.filterVehicleGroupTypeList}
                                        valueExpr="id"
                                        deferRendering={false}
                                        displayExpr="name"
                                        placeholder="Select vehicle group..."
                                        showClearButton={true}
                                        dataSource={this.state.vehicleGroupTypesList}
                                        onValueChanged={this.syncDataGridVehicleTypeSelection}
                                        contentRender={this.dataGridVehicleTypeRender}
                                        disabled={false}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 text-right">
                                <div className="form-group">
                                    <label className="btn-block">&nbsp;</label>
                                    <button type="submit" className="btn btn-success btn-sm" onClick={this.handleRideClosedSubmit} >
                                        {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                        {!this.state.isSubmited && <span title="Search"><i className="fas fa-search"></i> Search</span>}
                                    </button>&nbsp; &nbsp;
                                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleClearSearch} title="Clear"><i className="fas fa-eraser"></i> Clear</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="card card-grid">
                            <div className="card-body">
                                <DataGrid
                                    dataSource={this.state.datasource}
                                    keyExpr="rideId"
                                    selection={{ mode: 'single' }}
                                    columnsAutoWidth={false}
                                    showColumnLines={true}
                                    showRowLines={true}
                                    showBorders={true}
                                    rowAlternationEnabled={true}
                                    showBorders={true}
                                    allowColumnResizing={true}
                                    columnResizingMode='widget'
                                    onToolbarPreparing={this.onToolbarPreparing}>
                                    <Column dataField="rateId" width={70} caption="" allowFiltering={false} visible={true} allowSorting={false} cellRender={renderNewResQtCell} />
                                    <Column dataField="rideId" width={100} allowEditing={false} caption="Ride Id" allowFiltering={true} allowSorting={true} />
                                    <Column dataField="vehicleTypeName" width={100} caption="V Type" allowFiltering={true} allowSorting={true} />
                                    <Column dataField="pickupDateTime" caption="PU Time" width={120} allowFiltering={true} allowSorting={true} format={DateFormat.DateTime24Format} dataType="datetime" />
                                    <Column dataField="flightInfo" caption="Flight#" width={100} allowFiltering={true} allowSorting={true} />
                                    <Column dataField="flghtTime" caption="Time" width={100} allowFiltering={true} allowSorting={true} />
                                    <Column dataField="paxName" caption="Pax Name" width={100} allowFiltering={true} allowSorting={true} />
                                    <Column dataField="puStreet" caption="Pickup" width={200} allowFiltering={true} allowSorting={true} />
                                    <Column dataField="doStreet" caption="Dropoff" width={200} allowFiltering={true} allowSorting={true} />
                                    <Column dataField="driverName" caption="Driver" width={100} allowFiltering={true} allowSorting={true} />
                                    <Column dataField="vehicleNo" caption="Vehicle" width={120} visible={true} allowFiltering={true} allowSorting={true} />
                                    <Column dataField="noofAdults" caption="Pax" width={100} visible={true} allowFiltering={true} allowSorting={true} />
                                    <Column dataField="wait" caption="Wait" width={100} allowFiltering={true} allowSorting={true} />
                                    <Column dataField="lead" caption="Lead" width={100} allowFiltering={true} allowSorting={true} />
                                    <Column dataField="bdo" caption="BDO" width={100} allowFiltering={true} allowSorting={true} />
                                    <Column dataField="vIPStatus" caption="VIP" width={100} allowFiltering={true} allowSorting={true} />
                                    <Column dataField="statusDesc" caption="Trip Status" width={100} allowFiltering={true} allowSorting={true} />
                                    <Column dataField="discountCharge" caption="Trip Remark" width={100} allowFiltering={true} allowSorting={true} />

                                    <SortByGroupSummaryInfo summaryItem="count" />
                                    <GroupPanel visible={true} />
                                    <HeaderFilter visible={true} />
                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                    <Grouping autoExpandAll={true} />
                                    <Paging defaultPageSize={20} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                    <Export enabled={true} allowExportSelectedData={false} />
                                    <ColumnChooser enabled={true} />
                                    <ColumnFixing enabled={true} />

                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </div>
                <ReservationHistoryComponent ref="reservationHistoryModal" />
                <NoShowReservationComponent ref="noShowReservationModal" refreshDataSource={this.state.handleRideClosedSubmit} />
            </div>
        )
    }

    onSelectionVehicleTypeChanged(e) {
        this.setState({
            filterVehicleGroupTypeList: e.selectedRowKeys.length && e.selectedRowKeys || []
        });
    }
    syncDataGridVehicleTypeSelection(e) {
        this.setState({
            filterVehicleGroupTypeList: e.value || []
        });
    }

    dataGridVehicleTypeRender() {
        return (
            <DataGrid
                dataSource={this.state.vehicleGroupTypesList}
                id="grid-container"
                keyExpr="id"
                hoverStateEnabled={true}
                selectedRowKeys={this.state.filterVehicleGroupTypeList}
                onSelectionChanged={this.onSelectionVehicleTypeChanged}
                ref={ref => this.dataGrid = ref}>
                <Column dataField="name" caption="Vehicle Type" />

                <Selection mode="multiple" />
                <Scrolling mode="virtual" />
                <Paging enabled={true} pageSize={10} />
                <FilterRow visible={true} />


            </DataGrid>
        );
    }
}