import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
} from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import { LoadPanel } from 'devextreme-react/load-panel';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import { SelectBox } from 'devextreme-react/select-box';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';
import { getRequestParams } from '../../helpers/globalfunctions';
const position = { of: '#historydiv' };

const customStyles = {
    content: {
        width: '30%',
    }
};
const customStopStyles = {
    content: {
        width: '20%',
    }
};
let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class ScheduleListComponent extends Component {

    constructor(props) {
        super(props);

        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        this.state = {
            loadPanelVisible: false,
            datasource: [],
            customerId: 0,
            customerList: [],
            scheduleDataSource: [],
            isOpenModal: false,
            isStopOpenModal: false,
            scheduleStopDataSource: [],
            routeId: 0,
            routeName: '',
        }

        this.getScheduleList = this.getScheduleList.bind(this);
        this.getCustomerList = this.getCustomerList.bind(this);
        this.handleCustomerChange = this.handleCustomerChange.bind(this);
        this.handelToolbarPreparing = this.handelToolbarPreparing.bind(this);
        this.openEditScheduleModel = this.openEditScheduleModel.bind(this);
        this.deleteSchedule = this.deleteSchedule.bind(this);
        this.scheduleDetails = this.scheduleDetails.bind(this); 
        this.scheduleStopDetails = this.scheduleStopDetails.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.renderGridImageCellMon = this.renderGridImageCellMon.bind(this);
        this.renderGridImageCellTue = this.renderGridImageCellTue.bind(this);
        this.renderGridImageCellWed = this.renderGridImageCellWed.bind(this);
        this.renderGridImageCellThu = this.renderGridImageCellThu.bind(this);
        this.renderGridImageCellFri = this.renderGridImageCellFri.bind(this);
        this.renderGridImageCellSat = this.renderGridImageCellSat.bind(this);
        this.renderGridImageCellSun = this.renderGridImageCellSun.bind(this);

        this.closeStopModal = this.closeStopModal.bind(this);
       
    }

    handelToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getScheduleList.bind(this)
            }
        });
    }

    async componentDidMount() {
        this.setState({ loadPanelVisible: true });
        await this.getCustomerList();
        await this.getScheduleList();
        this.setState({ loadPanelVisible: false });
    }

    async getScheduleList() {
        var shuttleRouteScheduleGroupRequest = new Object();
        shuttleRouteScheduleGroupRequest.ShuttleRouteScheduleGroup = new Object();
        shuttleRouteScheduleGroupRequest.LocationId = Number(sessionStorage.getItem("session_locationId"));
        shuttleRouteScheduleGroupRequest.ShuttleRouteScheduleGroup.RouteId = 0;
        shuttleRouteScheduleGroupRequest.ShuttleRouteScheduleGroup.CustomerId = Number(this.state.customerId);
        shuttleRouteScheduleGroupRequest.ShuttleRouteScheduleGroup.SchedulesGroupId = 0;
        this.setState({ loadPanelVisible: true });
        try {
            const requestParams = getRequestParams('POST', shuttleRouteScheduleGroupRequest);
            const response = await fetch('/shuttle-schedule-api/group', requestParams);
            const data = await response.json();
            this.setState({
                datasource: data.shuttleRouteScheduleGroup
            });
            this.setState({ loadPanelVisible: false });
        } catch (e) {
            this.setState({ loadPanelVisible: false });
        }
    }

    async getCustomerList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('account-api/customer-dropdown/', requestParams);
        const data = await response.json();
        if (data.isSuccess) {
            this.setState({
                //customerList: data.customers != null ? data.customers.filter(f => (f.customerTypeId == 5 || f.customerTypeId == 14 || f.customerTypeId == 15 || f.customerTypeId == 16)) : [],
                customerList: data.customers != null ? data.customers : [],
            });
        }
    }

   handleCustomerChange(selectedOption) {
       this.setState({ customerId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
       let myVar = setTimeout(() => this.getScheduleList(), 200)
    }

    openEditScheduleModel(e, data) {
        e.preventDefault();
        this.props.history.push({
            pathname: '/shuttle/crudschedule',
            //state: { detail: data }
            state: { schedulesGroupId: (data != null ? data.schedulesGroupId : 0), sourceUrl: "/shuttle/schedule", sourceState: this.state }
        })
    }

    renderGridImageCellMon(cellData) {
        if (cellData.value === "True") {
            return (<div class="text-success"><i class="fas fa-check"></i></div>);
        }
        else {
            return (<div class="text-danger"><i class="fas fa-times"></i></div>);
        }
    }

    renderGridImageCellTue(cellData) {
        if (cellData.value === "True") {
            return (<div class="text-success"><i class="fas fa-check"></i></div>);
        }
        else {
            return (<div class="text-danger"><i class="fas fa-times"></i></div>);
        }
    }

    renderGridImageCellWed(cellData) {
        if (cellData.value === "True") {
            return (<div class="text-success"><i class="fas fa-check"></i></div>);
        }
        else {
            return (<div class="text-danger"><i class="fas fa-times"></i></div>);
        }
    }

    renderGridImageCellThu(cellData) {
        if (cellData.value === "True") {
            return (<div class="text-success"><i class="fas fa-check"></i></div>);
        }
        else {
            return (<div class="text-danger"><i class="fas fa-times"></i></div>);
        }
    }

    renderGridImageCellFri(cellData) {
        if (cellData.value === "True") {
            return (<div class="text-success"><i class="fas fa-check"></i></div>);
        }
        else {
            return (<div class="text-danger"><i class="fas fa-times"></i></div>);
        }
    }

    renderGridImageCellSat(cellData) {
        if (cellData.value === "True") {
            return (<div class="text-success"><i class="fas fa-check"></i></div>);
        }
        else {
            return (<div class="text-danger"><i class="fas fa-times"></i></div>);
        }
    }

    renderGridImageCellSun(cellData) {
        if (cellData.value === "True") {
            return (<div class="text-success"><i class="fas fa-check"></i></div>);
        }
        else {
            return (<div class="text-danger"><i class="fas fa-times"></i></div>);
        }
    }

    async isAllowConfirm() {
        let isResult = false;
        await Swal.fire({
            icon: 'question',
            title: 'Delete',
            html: "Are you sure want to delete schedule?",
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Delete',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        });
        return isResult;
    }

    async deleteSchedule(e, rowdata) {
        e.preventDefault();
        try {
            const isConfirm = await this.isAllowConfirm();
            if (!isConfirm) {
                return;
            }
            this.setState({ loadPanelVisible: true });
            let apiUrl = 'shuttle-schedule-api' + '/' + rowdata.schedulesGroupId + '/delete';
            const requestParams = getRequestParams('POST');
            const response = await fetch(apiUrl, requestParams);
            const data = await response.json();

            this.setState({ loadPanelVisible: false });
            Swal.fire({
                icon: data.isSuccess ? 'success' : 'error',
                title: data.isSuccess ? 'success' : 'Oops...',
                text: data.resultMessage,
            }).then((result) => {

                /* Read more about isConfirmed, isDenied below */
                if (data.isSuccess) {
                    this.getScheduleList();
                }

            });

        } catch (e) {
            this.setState({ loadPanelVisible: false });
        }
    }

    async scheduleDetails(e, rowData) {
        e.preventDefault();
        var request = new Object();
        request.ShuttleRouteSchedule = new Object();
        request.ShuttleRouteSchedule.ScheduleId = 0;
        request.ShuttleRouteSchedule.RouteId = 0;
        request.ShuttleRouteSchedule.ReservationId = 0;
        request.ShuttleRouteSchedule.CustomerId = 0;
        request.ShuttleRouteSchedule.SchedulesGroupId = rowData.schedulesGroupId;
        this.setState({ loadPanelVisible: true });
        try {
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('/shuttle-schedule-api/details', requestParams);
            const data = await response.json();
            this.setState({
                isOpenModal: true, routeName: rowData.routeName, scheduleDataSource: data.shuttleRouteSchedule
            });
            this.setState({ loadPanelVisible: false });
        } catch (e) {
            this.setState({ loadPanelVisible: false });
        }
    }

    closeModal(e) {
        e.preventDefault();
        this.setState({ isOpenModal: false, routeName: "", scheduleDataSource: [] })
    }

    async scheduleStopDetails(e, rowData) {
        e.preventDefault();
        var request = new Object();
        request.ShuttleRouteStopSchedules = new Object();
        request.ShuttleRouteStopSchedules.RouteStopScheduleId = 0;
        request.ShuttleRouteStopSchedules.RouteScheduleId = rowData.scheduleId;
        request.ShuttleRouteStopSchedules.RouteStopId = 0;
        this.setState({ loadPanelVisible: true });
        try {
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('/shuttle-schedule-api/stopschedule', requestParams);
            const data = await response.json();
            this.setState({
                isStopOpenModal: true, scheduleStopDataSource: data.shuttleRouteStopSchedules
            });
            this.setState({ loadPanelVisible: false });
        } catch (e) {
            this.setState({ loadPanelVisible: false });
        }
    }

    closeStopModal(e) {
        e.preventDefault();
        this.setState({ isStopOpenModal: false, scheduleStopDataSource: [] })
    }

    async isAllowConfirm() {
        let isResult = false;
        await Swal.fire({
            icon: 'question',
            title: 'Delete',
            html: "Are you sure want to delete schedule?",
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Delete',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        });
        return isResult;
    }

    async deleteSchedule(e, rowdata) {
        e.preventDefault();
        try {
            const isConfirm = await this.isAllowConfirm();
            if (!isConfirm) {
                return;
            }
            this.setState({ loadPanelVisible: true });
            let apiUrl = 'shuttle-schedule-api' + '/' + rowdata.schedulesGroupId + '/delete';
            const requestParams = getRequestParams('POST');
            const response = await fetch(apiUrl, requestParams);
            const data = await response.json();

            this.setState({ loadPanelVisible: false });
            Swal.fire({
                icon: data.isSuccess ? 'success' : 'error',
                title: data.isSuccess ? 'success' : 'Oops...',
                text: data.resultMessage,
            }).then((result) => {

                /* Read more about isConfirmed, isDenied below */
                if (data.isSuccess) {
                    this.getScheduleList();
                }

            });

        } catch (e) {
            this.setState({ loadPanelVisible: false });
        }
    }
    
    render() {

        const filterSearchFromDate = time => {
            const currentDate = currentTime;
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }

        const filterSearchToDate = time => {
            const currentDate = currentTime;
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }

        const renderGridCell = (data) => {

            return <div>
                <a href="#" className="fas fa-pen icon text-info" title="View" onClick={(e) => { this.openEditScheduleModel(e, data.data) }}></a>&nbsp;&nbsp;
                <a href="#" className="fas fa-list icon text-info" title="Schedule Details" onClick={(e) => { this.scheduleDetails(e, data.data) }}></a>&nbsp;&nbsp;
                <a href="#" className="fas fa-times icon text-info" title="Delete" onClick={(e) => { this.deleteSchedule(e, data.data) }}></a>&nbsp;&nbsp;
            </div>;
        }

        const renderScheduleGridCell = (data) => {

            return <div>
                <a href="#" className="fas fa-list icon text-info" title="Stop Details" onClick={(e) => { this.scheduleStopDetails(e, data.data) }}></a>&nbsp;&nbsp;
            </div>;
        }

        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <h1 className="m-0 text-dark">Schedules</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem><NavLink tag={Link} to="#" className="btn btn-block btn-outline-secondary btn-sm" onClick={(e) => { this.openEditScheduleModel(e, null) }} title="Add Schedule"><i className="fas fa-plus"></i> Add Schedule</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/route/list" className="btn btn-block btn-outline-secondary btn-sm" title="Routes"><i className="fas fa-road"></i> Routes</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/stop/list" className="btn btn-block btn-outline-secondary btn-sm" title="Stops"><i className="fas fa-map-marker"></i> Stops</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rez-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                <div className="form-group">
                                    <label>Customer</label>
                                    <SelectBox
                                        dataSource={this.state.customerList.length > 0 ? this.state.customerList : []}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select customer"
                                        name="customerId"
                                        defaultValue={0}
                                        value={this.state.customerId}
                                        displayExpr='name'
                                        valueExpr='id'
                                        onSelectionChanged={this.handleCustomerChange}
                                        showClearButton={true}
                                        disabled={false}
                                        searchEnabled={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <form method="post">
                    <div className="rez-header">
                        <div className="content">
                            <div className="card card-grid">
                                <div className="card-body">
                                    <DataGrid
                                        dataSource={this.state.datasource}
                                        keyExpr="schedulesGroupId"
                                        selection={{ mode: 'single' }}
                                        columnsAutoWidth={true}
                                        showColumnLines={false}
                                        showRowLines={true}
                                        showBorders={true}
                                        rowAlternationEnabled={true}
                                        allowColumnResizing={true}
                                        columnResizingMode='widget'
                                        onToolbarPreparing={this.handelToolbarPreparing}>
                                        <Column dataField="schedulesGroupId" width={80} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                        <Column dataField="customerName" caption="Customer Name" allowFiltering={true} allowSorting={true} />
                                        <Column dataField="routeName" caption="Route Name" allowFiltering={true} allowSorting={true} />
                                        <Column dataField="scheduleType" caption="Schedule Type" allowFiltering={true} allowSorting={true} />
                                        <Column dataField="scheduleStartDate" caption="Start Date" allowFiltering={true} allowSorting={true} />
                                        <Column dataField="scheduleEndDate" caption="End Date" allowFiltering={true} allowSorting={true} />
                                        <Column dataField="scheduleStartTime" caption="Start Time" allowFiltering={true} allowSorting={true} />
                                        <Column dataField="scheduleEndTime" caption="End Time" allowFiltering={true} allowSorting={true} />
                                        <Column dataField="schedueInterval" caption="Schedue Interval" allowFiltering={true} allowSorting={true} />
                                        {/*<Column dataField="monStatus" caption="Mon" allowFiltering={false} allowSorting={false} />*/}
                                        <Column dataField="monStatus" caption="Mon" allowFiltering={false} allowSorting={false} cellRender={this.renderGridImageCellMon} />
                                        <Column dataField="tueStatus" caption="Tue" allowFiltering={false} allowSorting={false} cellRender={this.renderGridImageCellTue} />
                                        <Column dataField="wedStatus" caption="Wed" allowFiltering={false} allowSorting={false} cellRender={this.renderGridImageCellWed} />
                                        <Column dataField="thuStatus" caption="Thu" allowFiltering={false} allowSorting={false} cellRender={this.renderGridImageCellThu} />
                                        <Column dataField="friStatus" caption="Fri" allowFiltering={false} allowSorting={false} cellRender={this.renderGridImageCellFri} />
                                        <Column dataField="satStatus" caption="Sat" allowFiltering={false} allowSorting={false} cellRender={this.renderGridImageCellSat} />
                                        <Column dataField="sunStatus" caption="Sun" allowFiltering={false} allowSorting={false} cellRender={this.renderGridImageCellSun} />

                                        <SortByGroupSummaryInfo summaryItem="count" />
                                        <GroupPanel visible={true} />
                                        <HeaderFilter visible={false} />
                                        <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                        <Grouping autoExpandAll={true} />
                                        <Paging defaultPageSize={20} />
                                        <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                        <Export enabled={true} allowExportSelectedData={false} />
                                        <ColumnChooser enabled={true} />
                                        <ColumnFixing enabled={true} />
                                    </DataGrid>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={customStyles}
                    className={"react-modal"}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form>
                        <div className="modal-header">
                            <h6 className="modal-title">Schedule Time Details</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <small><b><i>{this.state.routeName}</i></b></small>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="widget-container">
                                       
                                        <DataGrid
                                            dataSource={this.state.scheduleDataSource}
                                            keyExpr="scheduleId"
                                            columnsAutoWidth={false}
                                            showColumnLines={false}
                                            showRowLines={true}
                                            showBorders={true}
                                            rowAlternationEnabled={true}
                                            allowColumnResizing={true}
                                            columnResizingMode='widget'>

                                            <Column dataField="scheduleId" width={80} caption="" allowFiltering={false} allowSorting={false} cellRender={renderScheduleGridCell} />
                                            <Column dataField="scheduleTime" caption="Schedule Time"  allowFiltering={true} allowSorting={true} />
                                            <Column dataField="reservationId" caption="ReservationId"  allowFiltering={true} allowSorting={true} />

                                            <SortByGroupSummaryInfo summaryItem="count" />
                                            <GroupPanel visible={false} />
                                            <HeaderFilter visible={false} />
                                            <SearchPanel visible={false} highlightCaseSensitive={true} placeholder="Search..." />
                                            <Grouping autoExpandAll={false} />
                                            <Paging defaultPageSize={10} />
                                            <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10]} showInfo={true} />
                                            <Export enabled={false} allowExportSelectedData={false} />
                                            <ColumnChooser enabled={false} />
                                            <ColumnFixing enabled={false} />

                                        </DataGrid>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeModal}><i className="fas fa-times"></i> Cancel</button>
                        </div>
                    </form>
                </Modal>

                <Modal ariaHideApp={false}
                    isOpen={this.state.isStopOpenModal}
                    style={customStopStyles}
                    className={"react-modal"}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form>
                        <div className="modal-header">
                            <h6 className="modal-title">Schedule Stop Details</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeStopModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="widget-container">

                                        <DataGrid
                                            dataSource={this.state.scheduleStopDataSource}
                                            keyExpr="routeStopScheduleId"
                                            columnsAutoWidth={false}
                                            showColumnLines={false}
                                            showRowLines={true}
                                            showBorders={true}
                                            rowAlternationEnabled={true}
                                            allowColumnResizing={true}
                                            columnResizingMode='widget'>
                                            <Column dataField="stopName" caption="Stop Name" allowFiltering={true} allowSorting={true} />
                                            <Column dataField="scheduleTime" caption="Schedule Time" allowFiltering={true} allowSorting={true} />
                                            <Column dataField="scheduleTimeInMinutes" caption="Duration (Min)"  allowFiltering={true} allowSorting={true} />
                                            <SortByGroupSummaryInfo summaryItem="count" />
                                            <GroupPanel visible={false} />
                                            <HeaderFilter visible={false} />
                                            <SearchPanel visible={false} highlightCaseSensitive={true} placeholder="Search..." />
                                            <Grouping autoExpandAll={false} />
                                            <Paging defaultPageSize={10} />
                                            <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10]} showInfo={true} />
                                            <Export enabled={false} allowExportSelectedData={false} />
                                            <ColumnChooser enabled={false} />
                                            <ColumnFixing enabled={false} />

                                        </DataGrid>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeStopModal}><i className="fas fa-times"></i> Cancel</button>
                        </div>
                    </form>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        )

    }
}