import React, { Component } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import jsSHA from "jssha"; // npm install jssha
import { getRequestParams, formatDate } from '../helpers/globalfunctions.js';
import { UserType, StatusType, TripStatus } from '../helpers/fixcodes.js';
import { w3cwebsocket as W3CWebSocket } from "websocket"; //npm i websocket

const $ = window.$;

export class LoginComponent extends Component {
    static displayName = LoginComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            userName: '', password: '', isSubmited: false, message: '', isPasswordUpdated: false,
            errors: {
                userName: '',
                password: ''
            }
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        //this.handlePasswordUpdate = this.handlePasswordUpdate.bind(this);
        this.clearControl = this.clearControl.bind(this);
        this.calcHash = this.calcHash.bind(this);

        this.getCustomerList = this.getCustomerList.bind(this);
        this.getLocationList = this.getLocationList.bind(this);
        this.getDriversList = this.getDriversList.bind(this);
        this.getVehiclesList = this.getVehiclesList.bind(this);
        this.getStatusList = this.getStatusList.bind(this);
        this.getVehicleTypeList = this.getVehicleTypeList.bind(this);
        this.getVehicleGroupTypeList = this.getVehicleGroupTypeList.bind(this);
        this.getZones = this.getZones.bind(this);
        this.getAirlines = this.getAirlines.bind(this);
    }

    clearControl() {
        this.setState({
            userName: '', password: '', isSubmited: false, message: '', isPasswordUpdated: false,
            errors: {
                userName: '',
                password: ''
            }
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.userName = "";
        errors.password = "";
        this.setState({ message: '' });

        if (this.state.userName == '') {
            formIsValid = false;
            errors.userName = "Please enter username.";
        }

        if (this.state.password == '') {
            formIsValid = false;
            errors.password = "Please enter password.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    calcHash(value, securityKey) {
        let shaObj = new jsSHA("SHA-256", "TEXT", { encoding: "UTF8" });
        shaObj.update(value);
        let saltText = shaObj.getHash("HEX");
        let shaObj2 = new jsSHA("SHA-256", "TEXT", { encoding: "UTF8" });
        shaObj2.update(saltText + securityKey);
        //sha512(key | txnid | amount | productinfo | firstname | email | udf1 | udf2 | udf3 | udf4 | udf5 |||||| SALT)
        return shaObj2.getHash("HEX");
    }

    //async handlePasswordUpdate(e) {
    //    e.preventDefault();
    //    try {
    //        this.setState({ isPasswordUpdated: true });
    //        const pwdRequestParams = {
    //            method: 'POST',
    //            headers: { 'Content-Type': 'application/json' },
    //            //body: JSON.stringify({ UserName: this.state.userName })
    //        }

    //        const pwdResponse = await fetch('authentication/updatepasswordusers', pwdRequestParams);
    //        const tokenData = await pwdResponse.json();
    //        this.setState({ isPasswordUpdated: false });
    //    } catch (e) {
    //        debugger;
    //    }
    //}

    async handleSubmit(e) {        
        e.preventDefault();
        if (this.handleValidation()) {
            this.setState({ isSubmited: true });
            let passwordHash = "";

            const tokenRequestParams = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ UserName: this.state.userName })
            }

            const tokenResponse = await fetch('authentication/password-token-request', tokenRequestParams);
            const tokenData = await tokenResponse.json();


            if (tokenData.isSuccess) {
                passwordHash = this.calcHash(this.state.password, tokenData.token);
            } else {
                this.setState({ isSubmited: false, message: tokenData.resultMessage });
                return;
            }

            const requestParams = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ UserName: this.state.userName, Password: passwordHash })
            }
            const response = await fetch('authentication', requestParams);
            const data = await response.json();

            if (data.isSuccess) {
                if (data.userContext.isPaymentDone == false) {
                    var CreateTransactionRequest = new Object();
                    CreateTransactionRequest.TransactionSource = "UtWiz Account Registration";
                    CreateTransactionRequest.AccountId = parseInt(data.userContext.accountId);
                    CreateTransactionRequest.LocationId = 0;
                    CreateTransactionRequest.RideId = 0;
                    CreateTransactionRequest.ReservationId = 0;
                    CreateTransactionRequest.FirstName = data.userContext.firstName;
                    CreateTransactionRequest.LastName = data.userContext.lastName;
                    CreateTransactionRequest.EmailId = data.userContext.email;
                    CreateTransactionRequest.PhoneNo = data.userContext.mobileNo;
                    CreateTransactionRequest.Amount = parseFloat(parseFloat(data.userContext.planCostPerMonth).toFixed(2));
                    CreateTransactionRequest.OrderDetails = "";
                    CreateTransactionRequest.PlanId = parseInt(data.userContext.planId);
                    const transactionRequestParams = getRequestParams('POST', CreateTransactionRequest);
                    const transactionResponse = await fetch('payment-gateway-api/' + data.userContext.paymentGatewayId + '/transaction', transactionRequestParams);
                    const transactionData = await transactionResponse.json();
                    let paymentUrl = "", paymentKey = "", paymentSalt = "", txnId = "";
                    if (transactionData.transactionId != null && transactionData.settings.length >= 3) {
                        txnId = transactionData.transactionId;
                        paymentUrl = transactionData.settings[0].settingValue;
                        paymentKey = transactionData.settings[1].settingValue;
                        paymentSalt = transactionData.settings[2].settingValue;

                        
                        let dataStr = paymentKey + "|" + txnId + "|" + CreateTransactionRequest.Amount + "|" +
                            "UTWiz RIDE" + "|" + this.state.firstName + "|" +
                            this.state.emailAddress + "|" + CreateTransactionRequest.TransactionSource + "||||||||||" + paymentSalt;

                        let hashObj = new jsSHA("SHA-512", "TEXT", { numRounds: parseInt(1, 10) });
                        hashObj.update(dataStr);
                        let hashValue = hashObj.getHash("HEX");
                        let paymentAppUrl = data.paymentAppUrl;
                        let postForm = '<form action="' + paymentUrl + '" method="post" id="frmPay">';
                        postForm += '<input type="hidden" id="key" name="key" value="' + paymentKey + '" />';
                        postForm += '<input type="hidden" name="salt" value="' + paymentSalt + '" />';
                        postForm += '<input type="hidden" id="firstname" name="firstname" value="' + this.state.firstName + '" />';
                        postForm += '<input type="hidden" id="lastname" name="lastname" value="' + this.state.lastName + '" />';
                        postForm += '<input type="hidden" id="surl" name="surl" value="' + paymentAppUrl + 'PayUMoney/TrnxSuccess" />';
                        postForm += '<input type="hidden" id="phone" name="phone" value="' + this.state.mobileNumber + '" />';
                        postForm += '<input type="hidden" id="hash" name="hash" value="' + hashValue + '" />';
                        postForm += '<input type="hidden" id="curl" name="curl" value="' + paymentAppUrl + 'PayUMoney/TrnxCancelledByUser" />';
                        postForm += '<input type="hidden" id="furl" name="furl" value="' + paymentAppUrl + 'PayUMoney/TrnxFailed" />';
                        postForm += '<input type="hidden" id="txnid" name="txnid" value="' + txnId + '" />';
                        postForm += '<input type="hidden" id="productinfo" name="productinfo" value="UTWiz RIDE" />';
                        postForm += '<input type="hidden" id="amount" name="amount" value="' + CreateTransactionRequest.Amount + '" />';
                        postForm += '<input type="hidden" id="udf1" name="udf1" value="' + CreateTransactionRequest.TransactionSource + '" />';
                        postForm += '<input type="hidden" id="email" name="email" value="' + this.state.emailAddress + '" />';
                        postForm += '<input type="submit" value="submit">';
                        postForm += '</form>';
                        $(postForm).appendTo('body').submit();
                    }
                    else {
                        this.setState({ isSubmited: false });
                    }
                }
                else if (!data.userContext.isCompletedSetup) {
                    window.location.href = './accountsetup?ngid=' + parseInt(data.userContext.accountId);
                }
                else {
                    const activePlanRequestParams = getRequestParams('GET', '');
                    const accountActivePlanResponse = await fetch('account-api/' + data.userContext.accountId+'/active-plans', activePlanRequestParams);
                    const accountActivePlanData = await accountActivePlanResponse.json();
                    
                    if (accountActivePlanData.isSuccess) {
                        sessionStorage.setItem("session_token", data.token);
                        sessionStorage.setItem("session_userId", data.userContext.userId);
                        sessionStorage.setItem("session_userName", data.userContext.userName);
                        sessionStorage.setItem("session_name", data.userContext.fullName);
                        sessionStorage.setItem("session_email", data.userContext.email);
                        sessionStorage.setItem("session_accountId", data.userContext.accountId);
                        sessionStorage.setItem("session_accountname", data.userContext.accountName);
                        sessionStorage.setItem("session_accountcode", data.userContext.accountCode);
                        sessionStorage.setItem("session_locationId", data.userDefaultLocations[0].locationId);
                        sessionStorage.setItem("session_locationName", data.userDefaultLocations[0].locationName);
                        sessionStorage.setItem("session_timezoneName", data.userDefaultLocations[0].timezoneName);
                        sessionStorage.setItem("session_timezoneCode", data.userDefaultLocations[0].timezoneCode);
                        sessionStorage.setItem("session_uTCOffset", data.userDefaultLocations[0].uTCOffset);
                        sessionStorage.setItem("session_isDayLightApply", data.userDefaultLocations[0].isDayLightApply);
                        sessionStorage.setItem("session_dlStartTime", data.userDefaultLocations[0].dlStartTime);
                        sessionStorage.setItem("session_dlEndTime", data.userDefaultLocations[0].dlEndTime);
                        sessionStorage.setItem("session_dlTimeDifference", data.userDefaultLocations[0].dlTimeDifference);
                        
                        sessionStorage.setItem("session_minutesDifference", data.userDefaultLocations[0].minutesDifference);
                        sessionStorage.setItem("session_userTypeId", data.userContext.userTypeId);
                        sessionStorage.setItem("session_userTypeName", data.userContext.userTypeName);
                        sessionStorage.setItem("session_moduleId", data.userContext.moduleId);
                        sessionStorage.setItem("session_fileGuid", data.userContext.fileGuid);
                        sessionStorage.setItem("session_culturecode", "en-US");
                        sessionStorage.setItem("session_accountlegalname", data.userContext.accountLegalName);
                        sessionStorage.setItem("session_accountaddress", data.userContext.accountAddress);
                        sessionStorage.setItem("session_accountwebaddress", data.userContext.accountWebAddress);

                        sessionStorage.setItem("session_accountEmail", data.userContext.accountEmail);
                        sessionStorage.setItem("session_accountCountryCode", data.userContext.accountCountryCode);
                        sessionStorage.setItem("session_accountPhone", data.userContext.accountPhone);
                        sessionStorage.setItem("session_accountMobile", data.userContext.accountMobile);
                        sessionStorage.setItem("session_accountState", data.userContext.accountState);
                        sessionStorage.setItem("session_accountCity", data.userContext.accountCity);
                        sessionStorage.setItem("session_accountCountry", data.userContext.accountCountry);
                        sessionStorage.setItem("session_skinId", data.userContext.skinId);
                        sessionStorage.setItem("session_skinName", data.userContext.skinName);
                        sessionStorage.setItem("session_cssSkinName", data.userContext.cssSkinName);
                        sessionStorage.setItem("session_accountLogo", data.userContext.logo);
                        sessionStorage.setItem("session_accountLogoSize", data.userContext.logoSize);                        
                        sessionStorage.setItem("session_dashboardName", data.userDefaultDashboard[0].dashboardName);
                        sessionStorage.setItem("session_landginView", data.userDefaultLandingView[0].landingView);
                        sessionStorage.setItem("session_locationTime", this.getLocationTime());
                        sessionStorage.setItem("session_dispatchDisplayDuration", data.userDefaultLocations[0].dispatchDisplayDuration);
                        sessionStorage.setItem("session_dispatchDisplayDurationMin", data.userDefaultLocations[0].dispatchDisplayDurationMin);
                        sessionStorage.setItem("session_dispatchDisplayDurationMax", data.userDefaultLocations[0].dispatchDisplayDurationMax);
                        sessionStorage.setItem("session_allowToAddDriverWorkingHours", data.userDefaultLocations[0].allowToAddDriverWorkingHours);
                        sessionStorage.setItem("session_dispatchRefreshInterval", data.userDefaultLocations[0].dispatchRefreshInterval);
                        sessionStorage.setItem("session_mappedAccountId1", data.mappedAccountId1);
                        sessionStorage.setItem("session_mappedAccountId2", data.mappedAccountId2);
                        sessionStorage.setItem("session_mappedAccountId4", data.mappedAccountId4);
                        sessionStorage.setItem("session_displayCompletedReservations", data.userDefaultLocations[0].displayCompletedReservations);
                        sessionStorage.setItem("session_displayCancelledReservations", data.userDefaultLocations[0].displayCancelledReservations);
                        sessionStorage.setItem("session_doNotDeleteVehicle", data.userDefaultLocations[0].doNotDeleteVehicle);
                        sessionStorage.setItem("session_doNotDeleteDriver", data.userDefaultLocations[0].doNotDeleteDriver);
                        sessionStorage.setItem("session_mappedAccountId3", data.mappedAccountId3);
                        sessionStorage.setItem("session_userDefaultLatitude", data.userDefaultLocations[0].userDefaultLatitude);
                        sessionStorage.setItem("session_userDefaultLongitude", data.userDefaultLocations[0].userDefaultLongitude);
                        sessionStorage.setItem("session_dispatchDisplayDurationMinSec", data.userDefaultLocations[0].dispatchDisplayDurationMinSec);
                        sessionStorage.setItem("session_dispatchDisplayDurationMaxSec", data.userDefaultLocations[0].dispatchDisplayDurationMaxSec);
                        sessionStorage.setItem("session_socketApiUrl", data.userDefaultLocations[0].socketApiUrl);
                        sessionStorage.setItem("session_allowSocketConnection", data.userDefaultLocations[0].allowSocketConnection);
                        sessionStorage.setItem("session_allowDriverDocumentVerification", data.userContext.allowDriverDocumentVerification);                        
                        sessionStorage.setItem("session_allowVehicleDocumentVerification", data.userContext.allowVehicleDocumentVerification);
                        sessionStorage.setItem("session_currencyCode", data.userContext.currencyCode);
                        sessionStorage.setItem("session_currencyId", data.userContext.currencyId);
                        sessionStorage.setItem("session_currencyIcon", data.userContext.currencyIcon);
                        await this.getLocationList();
                        await this.getDriversList();
                        await this.getVehiclesList();
                        await this.getStatusList();
                        await this.getVehicleTypeList();
                        await this.getVehicleGroupTypeList();
                        //await this.getZones();
                        await this.getAirlines();
                        //await this.getCustomerList();

                        window.location.href = data.userDefaultLandingView[0].landingView;
                        
                        //switch (parseInt(data.userContext.userTypeId)) {
                        //    case (parseInt(UserType.Administrator)):
                        //    case (parseInt(UserType.Account)):
                        //        window.location.href = "/dashboard";
                        //        break;
                        //    case (parseInt(UserType.Dispatcher)):
                        //        window.location.href = "/dispatch/list";
                        //        break;
                        //    default:
                        //        window.location.href = "/dashboard";
                        //        break;
                        //}
                    }
                    else {
                        this.setState({ isSubmited: false, message: "Subscription not available." })
                    }
                }
            }
            else {
                this.setState({ isSubmited: false, message: data.resultMessage })
            }
        }
    }

    async getCustomerList() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('account-api/customer-dropdown/', requestParams);
            const data = await response.json();
            sessionStorage.setItem("session_customers", data != null ? JSON.stringify(data) : []);
        } catch (e) {
            console.log(e);
        }
    }

    async getLocationList() {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/account-api/locations', requestParams);
            const data = await response.json();
            sessionStorage.setItem("session_locations", data != null ? JSON.stringify(data) : []);
        } catch (e) {
            console.log(e);
        }
    }

    async getDriversList() {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/driver-api/dropdown/', requestParams);
            const data = await response.json();
            sessionStorage.setItem("session_drivers", data != null ? JSON.stringify(data) : []);
        } catch (e) {
            console.log(e);
        }
    }

    async getVehiclesList() {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/vehicle-api/dropdown/0/0', requestParams);
            const data = await response.json();
            sessionStorage.setItem("session_vehicles", data != null ? JSON.stringify(data) : []);
        } catch (e) {
            console.log(e);
        }
    }

    async getStatusList() {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/status-api/' + StatusType.Booking, requestParams);
            const data = await response.json();
            sessionStorage.setItem("session_tripstatus", data != null ? JSON.stringify(data) : []);
        } catch (e) {
            console.log(e);
        }
    }

    async getVehicleTypeList() {
        try {
            var apiVehicleTypeRequest = new Object();
            var apiVehicleTypeRequest = new Object();
            const requestParams = getRequestParams('POST', apiVehicleTypeRequest);
            const response = await fetch('/vehicletype-api/vehicletype', requestParams);
            const data = await response.json();
            sessionStorage.setItem("session_vehicletype", data != null ? JSON.stringify(data) : []);         
        } catch (e) {
            console.log(e);
        }
    }

    async getVehicleGroupTypeList() {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/vehicle-group-type-api/vehicle-group-type', requestParams);
            const data = await response.json();
            sessionStorage.setItem("session_vehiclegrouptype", data != null ? JSON.stringify(data) : []);            
        } catch (e) {
            console.log(e);
        }
    }

    async getZones() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('serviceAreaZone-api/zone-dropdown/' + 0 + "/" + 0, requestParams);
            const data = await response.json();
            sessionStorage.setItem("session_zones", data != null ? JSON.stringify(data) : []);
        } catch (e) {
            console.log(e);
        }
    }

    async getAirlines() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('air-line-api/' + 0, requestParams);
            const data = await response.json();
            sessionStorage.setItem("session_airlines", data != null ? JSON.stringify(data) : []);
        } catch (e) {
            console.log(e);
        }
    }

    getLocationTime() {        
        let locationdate = new Date();
        try {
            let offset = parseInt(sessionStorage.getItem("session_minutesDifference"));
            let isDayLightApply = sessionStorage.getItem("session_isDayLightApply");
            let dLStartDateTime = formatDate(sessionStorage.getItem("session_dlStartTime"), "MM/dd/yyyy hh:mm:ss");
            let dLEndDateTime = formatDate(sessionStorage.getItem("session_dlEndTime"), "MM/dd/yyyy hh:mm:ss");
            let dLTimeDifference = parseInt(sessionStorage.getItem("session_dlTimeDifference"));

            let currentDate = new Date();
            var istDateTime = new Date(currentDate.getTime() + (60000 * (currentDate.getTimezoneOffset() + offset)));
            
            if (isDayLightApply === "true") {
                if (formatDate(currentDate, "MM/dd/yyyy hh:mm:ss") >= dLStartDateTime && formatDate(currentDate, "MM/dd/yyyy hh:mm:ss") <= dLEndDateTime) {
                    istDateTime = new Date(istDateTime.getTime() + (60000 * dLTimeDifference));
                }
            }
            locationdate = formatDate(istDateTime, "MM/dd/yyyy hh:mm:ss");
            
        } catch (e) {
        }
        return locationdate;
    }

    render() {
        return (
            <div className="login-page">
                <div className="login-box">
                    <div className="card card-login">
                        <div className="card-body">
                            <div className="login-logo mb-0"><img src="../../dist/img/logo_utwiz.png" alt="UTWiz" /></div>
                            <p className="login-box-msg">Sign in to your account!</p>
                            <form method="post" onSubmit={this.handleSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                <div className="form-group mb-3">
                                    <input type="text" className="form-control" name="userName" placeholder="Username" value={this.state.userName} onChange={this.handleInputChange} />
                                    
                                    {this.state.errors["userName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["userName"]}</span>}
                                </div>
                                <div className="form-group mb-3">
                                    <input type="password" className="form-control" name="password" placeholder="Password" value={this.state.password} onChange={this.handleInputChange} />
                                    {this.state.errors["password"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["password"]}</span>}
                                    {this.state.message.length > 0 && <span className='error invalid-feedback'>{this.state.message}</span>}
                                </div>
                                <div className="row form-group">
                                    <div className="col-5">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="remember" />
                                            <label className="form-check-label" htmlFor="remember">
                                                Remember Me
                                            </label>
                                        </div>
                                    </div>                                    
                                    <div className="col-7 text-right">
                                        <button type="submit" className="btn btn-primary btn-sm" onClick={this.handleSubmit} title="Login" >
                                            {this.state.isSubmited && <span title="Login"><i className="fas fa-spinner fa-spin"></i> Login</span>}
                                            {!this.state.isSubmited && <span title="Login"><i className="fas fa-sign-in-alt"></i> Login</span>}
                                        </button>&nbsp;
                                        <button type="button" className="btn btn-danger btn-sm" onClick={this.clearControl} title="Clear"><i className="fa fa-eraser"></i> Clear</button>
                                    </div>
                                </div>
                                {/*<div className="row form-group">*/}
                                {/*    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">*/}
                                {/*        <button type="submit" className="btn btn-danger btn-sm" onClick={this.handlePasswordUpdate} title="Login" >*/}
                                {/*            {this.state.isPasswordUpdated && <span title="Update Password"><i className="fas fa-spinner fa-spin"></i> Update Password</span>}*/}
                                {/*            {!this.state.isPasswordUpdated && <span title="Update Password"><i className="fas fa-sign-in-alt"></i> Update Password</span>}*/}
                                {/*        </button>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div><NavLink tag={Link} to="/forgotpassword" title="I forgot my password" className="text-sm p-0 mb-1">I forgot my password</NavLink></div>
                                        <div><NavLink tag={Link} to="/plan" title="Register a new membership" className="text-sm p-0 mb-0">Register a new membership</NavLink></div>
                                    </div>                                   
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}