import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import RadioGroup from 'devextreme-react/radio-group';
import { SelectBox } from 'devextreme-react/select-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'
const paymentTypeRadio = [
    { id: 0, name: 'Cash Only' },
    { id: 1, name: 'CC Only' },
    { id: 2, name: 'Both (Cash & CC)' },
];

const rideStatusRadio = [
    { id: 0, name: 'Closed' },
    { id: 1, name: 'All' },
];

export class RideCommissionCashCCReportComponent extends Component {
    constructor() {
        super()
        this.state = {
            isSubmitted: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            paymentTypeList: paymentTypeRadio,
            paymentType: paymentTypeRadio[0],
            paymentTypeId: "0",
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),
            radCustomerType: "0",
            dropdownCustomerType: [],
            customerTypeId: 0,
            customerType: "",
            fromCustomerTypeId: 0,
            fromCustomerType: "",
            toCustomerTypeId: 0,
            toCustomerType: "",
            rideStatusList: rideStatusRadio,
            rideStatus: rideStatusRadio[0],
            rideStatusId: "0",
            errors: {
                customerTypeId: 0,
                fromCustomerTypeId: 0,
                toCustomerTypeId: 0,
            }
        }

        this.getCustomerType = this.getCustomerType.bind(this);

        this.handlePaymentTypeChange = this.handlePaymentTypeChange.bind(this);
        this.handleRideStatusChange = this.handleRideStatusChange.bind(this);
        this.handleFromDateChanged = this.handleFromDateChanged.bind(this);
        this.handleToDateChanged = this.handleToDateChanged.bind(this);
        this.handleRadCustomerTypeChange = this.handleRadCustomerTypeChange.bind(this);
        this.handleCustomerTypeChange = this.handleCustomerTypeChange.bind(this);
        this.handleFromCustomerTypeChange = this.handleFromCustomerTypeChange.bind(this);
        this.handleToCustomerTypeChange = this.handleToCustomerTypeChange.bind(this);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleValidation = this.handleValidation.bind(this);

        this.handleRideCommissionCashCCReportSubmit = this.handleRideCommissionCashCCReportSubmit.bind(this);
        this.downloadRideCommissionCashCCReportAsPdf = this.downloadRideCommissionCashCCReportAsPdf.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
    }
    async componentDidMount() {
        this.setState({
            loadPanelVisible: true,
        });
        await this.getCustomerType();
        this.setState({
            loadPanelVisible: false,
        });
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getDriversDropdownList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    async getCustomerType() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('customer-type-api/', requestParams);
            const data = await response.json();
            this.setState({
                dropdownCustomerType: data.customerType,
            });
        } catch (e) {
            console.log(e);
        }
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ [name]: value });
    }
    handleRadCustomerTypeChange(e) {
        if (e.target.value === "0") {
            this.setState({
                radCustomerType: e.target.value,
                customerTypeId: 0,
                customerType: "",
                fromCustomerTypeId: 0,
                toCustomerTypeId: 0,
                fromCustomerType: "",
                toCustomerType: ""
            });
        }
        else if (e.target.value === "1") {
            this.setState({
                radCustomerType: e.target.value,
                fromCustomerTypeId: 0,
                toCustomerTypeId: 0,
                fromCustomerType: "",
                toCustomerType: ""
            });
        }
        else {
            this.setState({
                radCustomerType: e.target.value,
                customerTypeId: 0,
                customerType: ""
            });

        }
        this.setState({ radCustomerType: e.target.value });
    }
    handleFromDateChanged(event) {
        let fromDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ startDate: fromDateValue })
    }
    handleToDateChanged(event) {
        let toDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ endDate: toDateValue })
    }
    handleCustomerTypeChange(selectedOption) {
        if (selectedOption != null) {
            //this.setState({ customerTypeId: selectedOption.id, customerType: selectedOption.name })
            this.setState({
                customerTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
                customerType: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.name : ""),
            })
        }
        else {
            this.setState({ customerTypeId: 0, customerType: "" })
        }
    }
    handleFromCustomerTypeChange(selectedOption) {
        if (selectedOption != null) {
            this.setState({
                fromCustomerTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
                fromCustomerType: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.name : ""),
            })
        }
        else {
            this.setState({ fromCustomerTypeId: 0, fromCustomerType: "" })
        }
    }
    handleToCustomerTypeChange(selectedOption) {
        if (selectedOption != null) {
            this.setState({
                toCustomerTypeId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0),
                toCustomerType: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.name : ""),
            })
        }
        else {
            this.setState({ toCustomerTypeId: 0, toCustomerType: "" })
        }
    }
    handlePaymentTypeChange(e) {
        this.setState({
            paymentType: e.value,
        })
    }
    handleRideStatusChange(e) {
        this.setState({
            rideStatus: e.value,
        })
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isEmailSubmitted: false,
                isOpenModal: false,
                loadPanelVisible: false,
                paymentTypeList: paymentTypeRadio,
                paymentType: paymentTypeRadio[0],
                paymentTypeId: "0",
                startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
                endDate: new Date(),
                radCustomerType: "0",
                customerTypeId: 0,
                customerType: "",
                fromCustomerTypeId: 0,
                fromCustomerType: "",
                toCustomerTypeId: 0,
                toCustomerType: "",
                rideStatusList: rideStatusRadio,
                rideStatus: rideStatusRadio[0],
                rideStatusId: "0",
                errors: {
                    customerTypeId: 0,
                    fromCustomerTypeId: 0,
                    toCustomerTypeId: 0,
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleValidation(e) {
        let errors = this.state.errors;
        let formIsValid = true;

        errors.customerTypeId = 0;
        errors.fromCustomerTypeId = 0;
        errors.toCustomerTypeId = 0;

        this.setState({ message: '' });

        if (this.state.radCustomerType === "1") {
            if (this.state.customerTypeId === 0) {
                formIsValid = false;
                errors.customerTypeId = "Please select customer type";
            }
        }

        if (this.state.radCustomerType === "2") {
            if (this.state.fromCustomerTypeId === 0) {
                formIsValid = false;
                errors.fromCustomerTypeId = "Please select from customer type";
            }

            if (this.state.toCustomerTypeId === 0) {
                formIsValid = false;
                errors.toCustomerTypeId = "Please select to customer type";
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    handleRideCommissionCashCCReportSubmit(e) {
        e.preventDefault();
        this.downloadRideCommissionCashCCReportAsPdf(e);
    }
    async downloadRideCommissionCashCCReportAsPdf(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            try {
                this.setState({
                    loadPanelVisible: true,
                    isSubmited: true
                });
                let selectionCriteria = "";
                if (this.state.radCustomerType === "1") {
                    selectionCriteria = selectionCriteria + (selectionCriteria !== "" ? " and " : "") + " {Proc_Select_CashCCRides;1.Account_Type} = '" + this.state.fromCustomerType + "' ";
                }
                else if (this.state.radCustomerType === "2") {
                    selectionCriteria = selectionCriteria + (selectionCriteria !== "" ? " and " : "") + " {Proc_Select_CashCCRides;1.Account_Type} >= '" + this.state.fromCustomerType + "' and {Proc_Select_CashCCRides;1.Account_Type} <= '" + this.state.toCustomerType + "' ";
                }

                if (this.state.paymentType.id === 0) {
                    selectionCriteria = selectionCriteria + (selectionCriteria !== "" ? " and " : "") + " {Proc_Select_CashCCRides;1.PAYMENT_TYPE} = 'CS' ";
                }
                else if (this.state.paymentType.id === 1) {
                    selectionCriteria = selectionCriteria + (selectionCriteria !== "" ? " and " : "") + " {Proc_Select_CashCCRides;1.PAYMENT_TYPE} = 'CC' ";
                }

                if (this.state.rideStatus.id === 0) {
                    selectionCriteria = selectionCriteria + (selectionCriteria !== "" ? " and " : "") + " {Proc_Select_CashCCRides;1.STATUS_ID} = 116 ";
                }

                var request = {};
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.fileFormat = 'pdf';
                request.MethodName = "GetRideCommissionCashCC";
                request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy");
                request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy");
                request.SelectionCrieteria = selectionCriteria;

                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();

                this.setState({
                    loadPanelVisible: false,
                    isSubmited: false,
                });

                let a = document.createElement('a');
                a.href = dataResponse.reportBase64String;
                a.download = 'RideCommissionCashCC.pdf';
                a.click();

            } catch (e) {
                //debugger;
            }
        }
    }
    handleClearSearch() {
        this.setState({
            isSubmitted: false,
            isEmailSubmitted: false,
            isOpenModal: true,
            loadPanelVisible: false,
            paymentTypeList: paymentTypeRadio,
            paymentType: paymentTypeRadio[0],
            paymentTypeId: "0",
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),
            radCustomerType: "0",
            customerTypeId: 0,
            customerType: "",
            fromCustomerTypeId: 0,
            fromCustomerType: "",
            toCustomerTypeId: 0,
            toCustomerType: "",
            rideStatusList: rideStatusRadio,
            rideStatus: rideStatusRadio[0],
            rideStatusId: "0",
            errors: {
                customerTypeId: 0,
                fromCustomerTypeId: 0,
                toCustomerTypeId: 0,
            }
        });
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isEmailSubmitted: true });
                let selectionCriteria = "";

                if (this.state.radCustomerType === "1") {
                    selectionCriteria = selectionCriteria + (selectionCriteria !== "" ? " and " : "") + " {Proc_Select_CashCCRides;1.Account_Type} = '" + this.state.fromCustomerType + "' ";
                }
                else if (this.state.radCustomerType === "2") {
                    selectionCriteria = selectionCriteria + (selectionCriteria !== "" ? " and " : "") + " {Proc_Select_CashCCRides;1.Account_Type} >= '" + this.state.fromCustomerType + "' and {Proc_Select_CashCCRides;1.Account_Type} <= '" + this.state.toCustomerType + "' ";
                }

                if (this.state.paymentType.id === 0) {
                    selectionCriteria = selectionCriteria + (selectionCriteria !== "" ? " and " : "") + " {Proc_Select_CashCCRides;1.PAYMENT_TYPE} = 'CS' ";
                }
                else if (this.state.paymentType.id === 1) {
                    selectionCriteria = selectionCriteria + (selectionCriteria !== "" ? " and " : "") + " {Proc_Select_CashCCRides;1.PAYMENT_TYPE} = 'CC' ";
                }

                if (this.state.rideStatus.id === 0) {
                    selectionCriteria = selectionCriteria + (selectionCriteria !== "" ? " and " : "") + " {Proc_Select_CashCCRides;1.STATUS_ID} = 116 ";
                }

                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.fileFormat = 'pdf';
                request.MethodName = "GetRideCommissionCashCC";
                request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy");
                request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy");
                request.SelectionCrieteria = selectionCriteria;

                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Ride Commission Cash & CC';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = 'RideCommissionCashCC.pdf'
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '60%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleRideCommissionCashCCReportSubmit}
                        style={(this.state.isSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Ride Commission Cash & CC'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Payment Type</label>
                                        <RadioGroup id="radio-group-viewby" dataSource={this.state.paymentTypeList} displayExpr="name" value={this.state.paymentType} onValueChanged={this.handlePaymentTypeChange} layout="horizontal" />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label htmlFor="">From Ride Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.startDate}
                                            value={this.state.startDate}
                                            onValueChanged={this.handleFromDateChanged}
                                            width="100%"
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>To Ride Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.endDate}
                                            value={this.state.endDate}
                                            onValueChanged={this.handleToDateChanged}
                                            width="100%"
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radCustomerType" value="0" checked={this.state.radCustomerType === "0"} onChange={this.handleRadCustomerTypeChange} />
                                            <label className="form-check-label">All</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radCustomerType" value="1" checked={this.state.radCustomerType === "1"} onChange={this.handleRadCustomerTypeChange} />
                                            <label className="form-check-label">Single</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radCustomerType" value="2" checked={this.state.radCustomerType === "2"} onChange={this.handleRadCustomerTypeChange} />
                                            <label className="form-check-label">Range</label>
                                        </span>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder="Select customer type"*/}
                                        {/*    name="customerTypeId"*/}
                                        {/*    options={this.state.dropdownCustomerType.length > 0 ? this.state.dropdownCustomerType : []}*/}
                                        {/*    getOptionLabel={option => option.name}*/}
                                        {/*    getOptionValue={option => option.id}*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.customerTypeId === '' ? [] : [this.state.dropdownCustomerType.find(opt => opt.id == this.state.customerTypeId)]}*/}
                                        {/*    onChange={this.handleCustomerTypeChange}*/}
                                        {/*    isMulti={false}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isDisabled={false}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*    isClearable={false}*/}
                                        {/*    isDisabled={this.state.radCustomerType == "1" ? false : true}*/}
                                        {/*/>*/}

                                        <SelectBox
                                            dataSource={this.state.dropdownCustomerType}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer type"
                                            name="customerTypeId"
                                            defaultValue={0}
                                            value={this.state.customerTypeId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleCustomerTypeChange}
                                            showClearButton={true}
                                            disabled={this.state.radCustomerType === "1" ? false : true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerTypeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerTypeId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>Select Customer Type Range</label>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder="Select customer type"*/}
                                        {/*    name="fromCustomerTypeId"*/}
                                        {/*    options={this.state.dropdownCustomerType.length > 0 ? this.state.dropdownCustomerType : []}*/}
                                        {/*    getOptionLabel={option => option.name}*/}
                                        {/*    getOptionValue={option => option.id}*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.fromCustomerTypeId === '' ? [] : [this.state.dropdownCustomerType.find(opt => opt.id == this.state.fromCustomerTypeId)]}*/}
                                        {/*    onChange={this.handleFromCustomerTypeChange}*/}
                                        {/*    isMulti={false}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isDisabled={false}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*    isClearable={false}*/}
                                        {/*    isDisabled={this.state.radCustomerType === "2" ? false : true}*/}
                                        {/*/>*/}

                                        <SelectBox
                                            dataSource={this.state.dropdownCustomerType}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer type"
                                            name="fromCustomerTypeId"
                                            defaultValue={0}
                                            value={this.state.fromCustomerTypeId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleFromCustomerTypeChange}
                                            showClearButton={true}
                                            disabled={this.state.radCustomerType === "2" ? false : true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["fromCustomerTypeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["fromCustomerTypeId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>&nbsp;</label>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder="Select customer type"*/}
                                        {/*    name="toCustomerTypeId"*/}
                                        {/*    options={this.state.dropdownCustomerType.length > 0 ? this.state.dropdownCustomerType : []}*/}
                                        {/*    getOptionLabel={option => option.name}*/}
                                        {/*    getOptionValue={option => option.id}*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.toCustomerTypeId === '' ? [] : [this.state.dropdownCustomerType.find(opt => opt.id == this.state.toCustomerTypeId)]}*/}
                                        {/*    onChange={this.handleToCustomerTypeChange}*/}
                                        {/*    isMulti={false}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isDisabled={false}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*    isClearable={false}*/}
                                        {/*    isDisabled={this.state.radCustomerType == "2" ? false : true}*/}
                                        {/*/>*/}

                                        <SelectBox
                                            dataSource={this.state.dropdownCustomerType}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer type"
                                            name="toCustomerTypeId"
                                            defaultValue={0}
                                            value={this.state.toCustomerTypeId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleToCustomerTypeChange}
                                            showClearButton={true}
                                            disabled={this.state.radCustomerType === "2" ? false : true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["toCustomerTypeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["toCustomerTypeId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>Payment Type by Rides</label>
                                        <RadioGroup id="radio-group-ridestatus" dataSource={this.state.rideStatusList} displayExpr="name" value={this.state.rideStatus} onValueChanged={this.handleRideStatusChange} layout="horizontal" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="submit" className="btn btn-success btn-sm" title="Download" onClick={this.handleRideCommissionCashCCReportSubmit} >
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                                </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}