import React, { Component } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter,
    SearchPanel,
    SortByGroupSummaryInfo,
    MasterDetail,
    Scrolling,
}
    from 'devextreme-react/data-grid';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions';
import { StatusType, DateFormat, TripStatus } from '../../helpers/fixcodes';
import DateBox from 'devextreme-react/date-box';
import DropDownBox from 'devextreme-react/drop-down-box';
import { SelectBox } from 'devextreme-react/select-box';
import { AcceptDeclineNoShowComponent } from '../Shared/AcceptDeclineNoShowComponent';
import { SendMessageComponent } from '../Shared/SendMessageComponent';

let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class NoShowReservationsComponent extends Component {
    static displayName = NoShowReservationsComponent.name;

    constructor(props) {
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        super(props);
        this.state = {
            isSubmited: false,
            noShowReservationGridDataSource: [],
            fromDate: new Date(new Date(currentTime).setDate(currentTime.getDate() - 1)),
            toDate: new Date(new Date(currentTime).setDate(currentTime.getDate() + 1)),
            locationList: [],
            locationId: parseInt(sessionStorage.getItem('session_locationId')),
            isDisplayHeader:true,
        };

        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.handleFromDateChange = this.handleFromDateChange.bind(this);
        this.handleToDateChange = this.handleToDateChange.bind(this);
        this.callNoshowReservation = this.callNoshowReservation.bind(this);
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);

        this.getLocationList = this.getLocationList.bind(this);
        this.getNoShowReservations = this.getNoShowReservations.bind(this);
        this.acceptNoShowModel = this.acceptNoShowModel.bind(this);
        this.declinetNoShowModel = this.declinetNoShowModel.bind(this);  
        this.sendDriverMessageControl = this.sendDriverMessageControl.bind(this);
        this.handleSearchNoShowReservation = this.handleSearchNoShowReservation.bind(this);
    }

    async componentDidMount() {
        this.setState({ isDisplayHeader: true });
        await this.getLocationList();
        await this.getNoShowReservations();
    }
    
    async getLocationList() {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/account-api/locations', requestParams);
            const data = await response.json();
            this.setState({
                locationList: data.locations
            });
        } catch (e) {
            console.log(e);
        }
    }

    async getNoShowReservations() {        
        try {
            var noShowReservationRequest = new Object();
            noShowReservationRequest.SearchOptionNo = parseInt(1);
            noShowReservationRequest.FromDate = formatDate(this.state.fromDate, "MM/dd/yyyy");
            noShowReservationRequest.ToDate = formatDate(this.state.toDate, "MM/dd/yyyy");
            noShowReservationRequest.RequestId = parseInt(0);
            noShowReservationRequest.AccountId = 0;
            noShowReservationRequest.LocationId = this.state.locationId;
            noShowReservationRequest.LoginUserId = parseInt(sessionStorage.getItem("session_userId"));
            const requestParams = getRequestParams('POST', noShowReservationRequest);
            const response = await fetch('/noshow-api/noshow-ride-list', requestParams);
            const data = await response.json();
            
            this.setState({
                noShowReservationGridDataSource: data.noShowReservations,
            });
        } catch (e) {
            //debugger;
        }
    }

    async callNoshowReservation() {        
        try {
            var noShowReservationRequest = new Object();
            noShowReservationRequest.SearchOptionNo = parseInt(1);
            noShowReservationRequest.FromDate = formatDate(this.state.fromDate, "MM/dd/yyyy");
            noShowReservationRequest.ToDate = formatDate(this.state.toDate, "MM/dd/yyyy");
            noShowReservationRequest.RequestId = parseInt(0);
            noShowReservationRequest.AccountId = 0;
            noShowReservationRequest.LocationId = this.state.locationId;
            noShowReservationRequest.LoginUserId = parseInt(sessionStorage.getItem("session_userId"));
            const requestParams = getRequestParams('POST', noShowReservationRequest);
            const response = await fetch('/noshow-api/noshow-ride-list', requestParams);
            const data = await response.json();

            this.setState({
                noShowReservationGridDataSource: data.noShowReservations,
                isDisplayHeader: false,
            });
        } catch (e) {
            //debugger;
        }
    }

    //setDisplayHeaderValue() {
    //    debugger;
    //    this.setState({ isDisplayHeader: false });
    //}

    handleSearchNoShowReservation(e) {        
        this.setState({ isSubmited: true });
        this.getNoShowReservations();
        this.setState({ isSubmited: false });
    }

    handleLocationChange(selectedOption) {        
        this.setState({ locationId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.locationId : 0), })
    }
    handleFromDateChange(date) {
        let fromDateValue = formatDate(date.value, "MM/dd/yyyy");
        this.setState({ fromDate: fromDateValue })
    }
    handleToDateChange(date) {
        //var dt = formatDate(date, "MM/dd/yyyy");
        //this.setState({ searchToDate: date });
        let toDateValue = formatDate(date.value, "MM/dd/yyyy");
        this.setState({ ToDate: toDateValue })
    }

    acceptNoShowModel(e, data) {
        e.preventDefault();
        Swal.fire({
            icon: 'info',
            title: 'NoShow Request',
            //text: "Are you sure, you want to approve this No SHOW request?",
            width: '650px',
            html: "Are you sure, you want to approve this No SHOW request?",
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                this.setState({ isStatusSubmited: false });
                this.refs.acceptDeclineNoShowRequestModal.showModal("NoShow Request", data, 1);
            }
        });
    }

    declinetNoShowModel(e, data) {
        e.preventDefault();
        Swal.fire({
            icon: 'info',
            title: 'NoShow Request',
            //text: "Are you sure, you want to approve this No SHOW request?",
            width: '650px',
            html: "Are you sure, you want to decline this No SHOW request?",
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                this.setState({ isStatusSubmited: false });
                this.refs.acceptDeclineNoShowRequestModal.showModal("NoShow Request", data, 0);
            }
        });
    }

    sendDriverMessageControl(e, data) {
        e.preventDefault();
        this.refs.SendMessageComponentModel.showModal(data);
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getNoShowReservations.bind(this)
            }
        });
    }

    render() {
        const renderGridCell = (data) => {            
            if (data.data.noShowStatusId === 1) {
                return;
            }
            return <div>
                <a href="#" className="fas fa-thumbs-up icon text-info" title="Accept" onClick={(e) => { this.acceptNoShowModel(e, data.data) }}></a>&nbsp;&nbsp;
                <a href="#" className="fas fa-thumbs-down icon text-info" title="Decline" onClick={(e) => { this.declinetNoShowModel(e, data.data) }}></a>&nbsp;&nbsp;                 
            </div>;
        }

        return (
            <div className="content-wrapper">
                <div className={this.state.isDisplayHeader === false ? "content-header displayNone" : "content-header displayBlock"}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <h1 className="m-0 text-dark">No Show Requests</h1>
                            </div>
                            <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                                <ol className="breadcrumb float-sm-right">
                                    {/*<NavItem><NavLink tag={Link} to="/noshow/list" className="btn btn-block btn-outline-secondary btn-sm" title="Refresh" onClick={(e) => { this.handleSearchNoShowReservation(e) }}><i className="fas fa-sync-alt"></i> Refresh</NavLink></NavItem>*/}
                                    <NavItem><NavLink id="routeDispatchRefresh" tag={Link} to="#" className="btn btn-block btn-outline-secondary btn-sm" title="Refresh" onClick={(e) => { this.handleSearchNoShowReservation(e) }}><i className="fas fa-sync-alt"></i> Refresh</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/dispatch/list" className="btn btn-block btn-outline-secondary btn-sm" title="Dispatch"><i className="fas fa-check"></i> Dispatch</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/drivermessage/list" className="btn btn-block btn-outline-secondary btn-sm" title="Driver Message"><i className="fas fa-sms"></i> Driver Message</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/loggeddrivers/list" className="btn btn-block btn-outline-secondary btn-sm" title="Logged Drivers"><i className="fas fa-user"></i> Logged Drivers</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/loggedvehicles/list" className="btn btn-block btn-outline-secondary btn-sm" title="Logged Vehicles"><i className="fas fa-car"></i> Logged Vehicles</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="#" className="btn btn-block btn-outline-secondary btn-sm" onClick={(e) => { this.sendDriverMessageControl(e, null) }} data-toggle="modal" title="Send Message"><i className="fas fa-sms"></i> Send Message</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={this.state.isDisplayHeader === false ? "rez-header displayNone" : "rez-header displayBlock"}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                <div className="form-group">
                                    <label>From Date</label>
                                    <DateBox type="date"
                                        className="form-control form-control-sm"
                                        name="fromDate"
                                        placeholder="mm/dd/yyyy"
                                        useMaskBehavior={true}
                                        defaultValue={this.state.fromDate}
                                        value={this.state.fromDate}
                                        onValueChanged={this.handleFromDateChange}
                                        acceptCustomValue={false}
                                        width="100%"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                <div className="form-group">
                                    <label>To Date</label>
                                    <DateBox type="date"
                                        className="form-control form-control-sm"
                                        name="toDate"
                                        placeholder="mm/dd/yyyy"
                                        useMaskBehavior={true}
                                        defaultValue={this.state.toDate}
                                        value={this.state.toDate}
                                        onValueChanged={this.handleToDateChange}
                                        acceptCustomValue={false}
                                        width="100%"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                <div className="form-group">
                                    <label>Location</label>
                                    <SelectBox
                                        dataSource={this.state.locationList.length > 0 ? this.state.locationList : []}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select location"
                                        name="locationId"
                                        defaultValue={0}
                                        value={this.state.locationId}
                                        displayExpr='locationName'
                                        valueExpr='locationId'
                                        onSelectionChanged={this.handleLocationChange}
                                        showClearButton={true}
                                        disabled={false}
                                        searchEnabled={true}
                                        width="100%"
                                    />
                                </div>
                            </div>
                            {/*<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{ paddingTop: 22 + 'px' }}>
                                <div className="form-group">
                                    <label htmlFor="">&nbsp;</label>
                                    <span className="form-check">
                                        <input className="form-check-input" type="checkbox" name="isArrival" checked={this.state.isArrival} onChange={this.handleInputChange} />
                                        <label className="form-check-label">Arrival</label>
                                    </span>
                                    <span className="form-check ml-3">
                                        <input className="form-check-input" type="checkbox" name="isDeparture" checked={this.state.isDeparture} onChange={this.handleInputChange} />
                                        <label className="form-check-label">Departure</label>
                                    </span>
                                    <span className="form-check ml-3">
                                        <input className="form-check-input" type="checkbox" name="isAirporttoAirport" checked={this.state.isAirporttoAirport} onChange={this.handleInputChange} />
                                        <label className="form-check-label">Airport to Airport</label>
                                    </span>
                                    <span className="form-check ml-3">
                                        <input className="form-check-input" type="checkbox" name="isLocationToLocation" checked={this.state.isLocationToLocation} onChange={this.handleInputChange} />
                                        <label className="form-check-label">Location to Location</label>
                                    </span>
                                    <span className="form-check ml-3">
                                        <input className="form-check-input" type="checkbox" name="isReturnLocationToLocation" checked={this.state.isReturnLocationToLocation} onChange={this.handleInputChange} />
                                        <label className="form-check-label">Return - Location to Location</label>
                                    </span>

                                </div>
                            </div>*/}
                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 text-right">
                                <div className="form-group">
                                    <label className="btn-block">&nbsp;</label>
                                    <button type="submit" className="btn btn-success btn-sm" onClick={this.handleSearchNoShowReservation} >
                                        {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                        {!this.state.isSubmited && <span title="Search"><i className="fas fa-search"></i> Search</span>}
                                    </button>&nbsp; &nbsp;
                                             <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleClearSearch} title="Clear"><i className="fas fa-eraser"></i> Clear</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="card card-grid">
                        <div className="card-body">
                            <DataGrid
                                id="gridNoShowReservationContainer"
                                name="gridNoShowReservationContainer"
                                dataSource={this.state.noShowReservationGridDataSource}
                                className="dx-datagrid-height"
                                keyExpr="rideId"
                                columnsAutoWidth={true}
                                showColumnLines={true}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={false}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                onSelectionChanged={this.rowChanged}
                                onToolbarPreparing={this.onToolbarPreparing}
                                onRowPrepared={this.handleRowPrepared}>
                                <ColumnChooser enabled={true} />
                                <Grouping autoExpandAll={false} />
                                <HeaderFilter visible={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Export enabled={true} allowExportSelectedData={false} />
                                <GroupPanel visible={true} />
                                <Scrolling mode="virtual" />
                                <Column dataField="rideId" width={70} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                <Column dataField="vehicleTypeName" width={120} caption="Vehicle Type" width={80} allowFiltering={false} allowSorting={true} />
                                <Column dataField="rideId" width={110} caption="Ride Id" width={90} allowFiltering={false} allowSorting={true} />
                                <Column dataField="paxName" width={100} caption="Pax Name" width={150} allowFiltering={false} allowSorting={true} />
                                <Column dataField="noShowReason" width={150} caption="Noshow Reason" width={150} allowFiltering={false} allowSorting={true} />
                                <Column dataField="driverName" width={100} caption="Requested By" width={150} allowFiltering={false} allowSorting={true} />
                                <Column dataField="deviceName" width={100} caption="Device" width={150} allowFiltering={false} allowSorting={true} />                                
                                <Column dataField="vehicleNo" width={100} caption="Vehicle" width={150} allowFiltering={false} allowSorting={true} />
                                <Column dataField="requestDateTime" width={100} caption="Requested Time" width={150} allowFiltering={false} allowSorting={true} format={DateFormat.DateTime24Format} dataType="datetime"  />
                                <Column dataField="noShowStatusDescription" width={100} caption="Requested Status" width={150} allowFiltering={false} allowSorting={true} />
                                <Column dataField="processedDateTime" width={100} caption="Processed Time" width={150} allowFiltering={false} allowSorting={true} format={DateFormat.DateTime24Format} dataType="datetime"  />
                                <Column dataField="dispatcherComments" width={200} caption="Comments" width={150} allowFiltering={false} allowSorting={true} />
                            </DataGrid>
                        </div>
                    </div>
                </div>
                <AcceptDeclineNoShowComponent ref="acceptDeclineNoShowRequestModal" />
                <SendMessageComponent ref="SendMessageComponentModel" onSendMessageClose={this.getNoShowReservations} />
            </div>
        );
    }
}