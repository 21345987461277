import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
    Scrolling,
    Editing,
    Selection
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { SelectBox } from 'devextreme-react/select-box';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { LoadPanel } from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import { RatesHistoryComponent } from '../Shared/RatesHistoryComponent';

const position = { of: '#rateDiv' };
let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));

var historyRawData = [];
export class AddEditContractRatesComponent extends React.Component {
    constructor(props) {
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        super(props);
        this.state = {
            isSubmited: false,
            isUpdateSubmited: false,
            isHistorySubmited: false,
            isMultipleZoneOpen: false,
            isSearched: false,
            wholeRawData: [],
            dataSource: [],

            isAdd: this.props.location.state == undefined ? true : this.props.location.state.isAdd,
            customerId: this.props.location.state == undefined ? 0 : this.props.location.state.customerId,
            rideTypeId: this.props.location.state == undefined ? 0 : this.props.location.state.rideTypeId,
            fromAirportId: this.props.location.state == undefined ? 0 : this.props.location.state.fromAirportId,
            fromZoneId: this.props.location.state == undefined ? 0 : this.props.location.state.fromZoneId,

            vehicleTypeId: this.props.location.state == undefined ? 0 : this.props.location.state.vehicleTypeId,
            isAirportTransfer: this.props.location.state == undefined ? 0 : this.props.location.state.isAirportTransfer,
            isZonalTransfer: this.props.location.state == undefined ? 0 : this.props.location.state.isZonalTransfer,

            wefDate: currentTime,
            wefDateStr: '',

            pickupZoneId: 0,

            dropdownZone: [],
            customerList: [],
            isReturnTrip: false,
            loadPanelVisible: false,

            sourceUrl: this.props.location.state == undefined ? "" : this.props.location.state.sourceUrl, // Send from calling paging (Search Reservation)
            sourceState: this.props.location.state == undefined ? "" : this.props.location.state.sourceState,  // Send from calling paging 

            dropdownWefDate: [],

            pickupDropdownZone: [],
            pickupZone: '',
            selectedPuRowKeys: [],
            errors: {
                customerId: '',
                pickupZoneId: '',
                wefDate: '',
                wefDateStr: '',
            }
        }

        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.handleCustomerInputChange = this.handleCustomerInputChange.bind(this);
        this.handlePickupZoneChange = this.handlePickupZoneChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleWefDateChange = this.handleWefDateChange.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.handleUpdateValidation = this.handleUpdateValidation.bind(this);

        this.getcustomerList = this.getcustomerList.bind(this);
        this.getZones = this.getZones.bind(this);
        this.getRideTypeId = this.getRideTypeId.bind(this);
        this.getZonalRatesDetails = this.getZonalRatesDetails.bind(this);

        this.handleSearchZonalRateSubmit = this.handleSearchZonalRateSubmit.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
        this.handleUpdateZonalRateSubmit = this.handleUpdateZonalRateSubmit.bind(this);
        this.updateZonalRates = this.updateZonalRates.bind(this);
        this.handleConfirmEvents = this.handleConfirmEvents.bind(this);
        this.goToBackPage = this.goToBackPage.bind(this);
        this.handleWEFDateSelectChange = this.handleWEFDateSelectChange.bind(this);
        this.getHistoryDescription = this.getHistoryDescription.bind(this);
        this.handleHistoryRateSubmit = this.handleHistoryRateSubmit.bind(this);

        this.handleSelectMultipleZoneSubmit = this.handleSelectMultipleZoneSubmit.bind(this);
        this.closeMultipleZoneModal = this.closeMultipleZoneModal.bind(this);
        this.onSelectionPuGridChanged = this.onSelectionPuGridChanged.bind(this);

    }
    async componentDidMount() {
        this.setState({
            loadPanelVisible: true,
        });
        this.setState({ wefDate: currentTime });
        await this.getcustomerList();
        await this.getZones();
        
        if (this.state.isAdd == false) {
            if (this.state.rideTypeId == 1 && this.state.fromAirportId > 0) {
                let puZone = this.state.dropdownZone.find(opt => opt.airportId == this.state.fromAirportId);
                if (puZone != undefined) {
                    this.setState({ pickupZoneId: puZone.id });
                }
            } else if (this.state.rideTypeId == 13 && this.state.fromZoneId > 0) {
                let puZone = this.state.dropdownZone.find(opt => opt.id == this.state.fromZoneId);
                if (puZone != undefined) {
                    this.setState({ pickupZoneId: puZone.id });
                }
                
            }
            await this.getZonalRatesDetails();
        }


        this.setState({
            loadPanelVisible: false,
        });
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getZonalRatesDetails.bind(this)
            }
        });
    }
    async getZones() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('serviceAreaZone-api/zone-dropdown/' + 0 + "/" + 0, requestParams);
            const data = await response.json();
            this.setState({
                dropdownZone: data.locationServiceAreaZoneList
            });
        } catch (e) {
            console.log(e);
        }
    }

    async getcustomerList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('account-api/customer-dropdown/', requestParams);
        const data = await response.json();
        this.setState({
            customerList: data.customers.filter(x => x.rateTypeId === 2),
        });

    }

    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.customerId = "";
        errors.pickupZoneId = "";
        errors.dropoffZoneId = "";
        errors.wefDate = "";

        if (this.state.customerId == 0) {
            formIsValid = false;
            errors.customerId = "Please select customer.";
        }
        if (this.state.pickupZoneId == 0) {
            formIsValid = false;
            errors.pickupZoneId = "Please select pick-up zone.";
        }
        //if (this.state.dropoffZoneId == 0) {
        //    formIsValid = false;
        //    errors.dropoffZoneId = "Please select drop off zone.";
        //}

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleUpdateValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.customerId = "";
        errors.pickupZoneId = "";
        errors.dropoffZoneId = "";
        errors.wefDate = "";
        errors.wefDateStr = "";

        if (this.state.customerId == 0) {
            formIsValid = false;
            errors.customerId = "Please select customer.";
        }
        if (this.state.pickupZoneId == 0) {
            formIsValid = false;
            errors.pickupZoneId = "Please select pick-up zone.";
        }
        //if (this.state.dropoffZoneId == 0) {
        //    formIsValid = false;
        //    errors.dropoffZoneId = "Please select drop off zone.";
        //}

        if (this.state.isAdd == true) {
            if (this.state.wefDate == null) {
                formIsValid = false;
                errors.wefDate = "Please select wef date.";
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    async getZonalRatesDetails() {

        if (this.handleValidation()) {
            let fromAirId = 0, fromZoneId = 0;

            let puZone = this.state.dropdownZone.find(opt => opt.id == this.state.pickupZoneId);

            if (puZone.zoneTypeId === 1) {
                fromAirId = puZone.airportId;
            } else if (puZone.zoneTypeId === 3) {
                fromZoneId = puZone.id;
            }

            var request = new Object();
            request.RideTypeId = this.state.rideTypeId;
            request.CustomerId = this.state.customerId;
            request.FromZoneId = fromZoneId;
            request.FromAirportId = fromAirId;
            request.WefDate = "";

            try {
                this.setState({
                    isSubmited: true,
                    isSearched: true,
                });
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('contract-rate-api/details', requestParams);
                const data = await response.json();
                this.setState({
                    wholeRawData: data.contractRateDetail,
                });


                var wefDateArray = [];
                if (data.contractRateDetail.length > 0) {
                    if (data.contractRateDetail[0].rowId > 0) {
                        for (var i = 0; i < data.contractRateDetail.length; i++) {

                            if (wefDateArray.length > 0) {

                                var findex = wefDateArray.findIndex(f => f.id === data.contractRateDetail[i].wefDateStr);
                                if (findex == -1) {
                                    var dateObject = new Object();
                                    dateObject.id = data.contractRateDetail[i].wefDateStr
                                    dateObject.name = data.contractRateDetail[i].wefDateStr
                                    wefDateArray.push(dateObject);
                                }

                            } else {
                                var dateObject = new Object();
                                dateObject.id = data.contractRateDetail[i].wefDateStr
                                dateObject.name = data.contractRateDetail[i].wefDateStr
                                wefDateArray.push(dateObject);
                            }

                        }
                    }
                }

                if (wefDateArray.length > 0) {
                    var defaultSelectedWefDate = wefDateArray[0].name;

                    var filterDataSource = data.contractRateDetail.filter(f => f.wefDateStr === defaultSelectedWefDate);
                    this.setState({
                        dataSource: filterDataSource,
                        wefDateStr: defaultSelectedWefDate,
                        dropdownWefDate: wefDateArray,
                        isAdd: false
                    })

                } else {
                    this.setState({
                        dataSource: data.contractRateDetail,
                        isAdd: true
                    })
                }

                this.setState({
                    isSubmited: false,

                });
            } catch (e) {
                this.setState({ isSubmited: false })
            }
        }
    }

    handleCustomerInputChange = (data) => {
        this.setState({ customerId: (data.selectedItem != null ? data.selectedItem.id : 0), })
    }

    handlePickupZoneChange(selectedOption) {
        this.setState({ pickupZoneId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
        this.state.errors["pickupZoneId"] = "";
        (selectedOption.selectedItem != null && this.getRideTypeId(selectedOption.selectedItem.id, this.state.dropoffZoneId));
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleWEFDateSelectChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;


        var defaultSelectedWefDate = value;

        var filterDataSource = this.state.wholeRawData.filter(f => f.wefDateStr === defaultSelectedWefDate);
        this.setState({
            dataSource: filterDataSource,
            wefDateStr: defaultSelectedWefDate,
        })

    }

    handleWefDateChange(date) {
        this.setState({ wefDate: date.value });
    }

    //getRideTypeId(fromZId, toZId) {

    //    var rideTypeId = 0;
    //    if (fromZId > 0 && toZId > 0) {
    //        let puZone = this.state.dropdownZone.find(opt => opt.id == fromZId);
    //        let doZone = this.state.dropdownZone.find(opt => opt.id == toZId);

    //        if (puZone.zoneTypeId === 1 && doZone.zoneTypeId === 3) {
    //            this.setState({ isMeetGreet: false });
    //            rideTypeId = 1;
    //        } else if (puZone.zoneTypeId === 3 && doZone.zoneTypeId === 1) {
    //            this.setState({ isMeetGreet: false });
    //            rideTypeId = 2;
    //        } else if (puZone.zoneTypeId === 1 && doZone.zoneTypeId === 1) {
    //            this.setState({ isMeetGreet: false });
    //            rideTypeId = 3;
    //        } else {
    //            this.setState({ isMeetGreet: false });
    //            rideTypeId = 13;
    //        }
    //    }

    //    this.setState({ rideTypeId: rideTypeId });

    //}

    getRideTypeId(fromZId) {

        var rideTypeId = 0;
        if (fromZId > 0) {
            let puZone = this.state.dropdownZone.find(opt => opt.id == fromZId);

            if (puZone.zoneTypeId === 1) {
                rideTypeId = 1;
            }  else {
                rideTypeId = 13;
            }
        }

        this.setState({ rideTypeId: rideTypeId });

    }

    async handleSearchZonalRateSubmit(e) {
        e.preventDefault();

        await this.getZonalRatesDetails();
    }
    handleClearSearch() {
        this.setState({
            isSubmited: false,
            isUpdateSubmited: false,
            isSearched: false,
            isMultipleZoneOpen: false,
            wholeRawData: [],
            dataSource: [],

            isAdd: this.props.location.state == undefined ? true : this.props.location.state.isAdd,
            customerId: this.props.location.state == undefined ? 0 : this.props.location.state.customerId,
            rideTypeId: this.props.location.state == undefined ? 0 : this.props.location.state.rideTypeId,
            fromAirportId: this.props.location.state == undefined ? 0 : this.props.location.state.fromAirportId,
            fromZoneId: this.props.location.state == undefined ? 0 : this.props.location.state.fromZoneId,

            vehicleTypeId: this.props.location.state == undefined ? 0 : this.props.location.state.vehicleTypeId,
            isAirportTransfer: this.props.location.state == undefined ? 0 : this.props.location.state.isAirportTransfer,
            isZonalTransfer: this.props.location.state == undefined ? 0 : this.props.location.state.isZonalTransfer,

            wefDate: currentTime,
            wefDateStr: '',

            pickupZoneId: 0,
            dropoffZoneId: 0,

            isReturnTrip: false,
            loadPanelVisible: false,

            sourceUrl: this.props.location.state == undefined ? "" : this.props.location.state.sourceUrl, // Send from calling paging (Search Reservation)
            sourceState: this.props.location.state == undefined ? "" : this.props.location.state.sourceState,  // Send from calling paging 

            dropdownWefDate: [],
            pickupDropdownZone: [],
            pickupZone: '',
            selectedPuRowKeys: [],
            errors: {
                customerId: '',
                pickupZoneId: '',
                wefDate: '',
                wefDateStr: '',
            }
        });
    }



    handleUpdateZonalRateSubmit(e) {
        e.preventDefault();

        this.refs.dataGridRef.instance.saveEditData();
        //this.refs.dataGridRef.instance.refresh();

        let myVar = setTimeout(() => this.handleConfirmEvents(), 200)

    }

    goToBackPage() {
        this.props.history.push({
            pathname: this.state.sourceUrl,
            state: this.state.sourceState
        })
    }



    async askReturnTripConfirm(fromZone) {
        let isResult = false;
        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: "You are going enter rate of [" + fromZone + "] for return trip!<br> Do you want to still continue?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    async askSaveRateConfirm(fromZone) {
        let isResult = false;
        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: "You are going enter rate of [" + fromZone + "] !<br> Do you want to still continue?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    async askSaveMultipleRateConfirm() {
        let isResult = false;
        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: "You are going enter rate of [multiple zone] !<br> Do you want to still continue?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    async askNewRateConfirm() {
        let isResult = false;
        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: "You have successfully updated rates!<br> Do you want add other rates?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    async askMultipleZoneConfirm() {
        let isResult = "";
        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: "Do you want to apply rates for multiple zone?",
            icon: 'question',
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = "yes";
            } else if (result.isDenied) {
                isResult = "no";
            }
        })
        return isResult;
    }

    async askUpdateFutureRateConfirm() {
        let isResult = false;
        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: "You want to update future rides?<br> Press Yes to apply?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    async askWefLessThanCurrentConfirm() {
        let isResult = false;
        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: "Given WEF date is less than current date!<br> Do you want to still continue?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    async askApdateUnapprovedRideConfirm() {
        let isResult = "";

        var msg = "You are entering rate for previous date.<br>";
        msg += "The rides which are Uninvoiced and Approved from given WEF date will Unapproved.<br>";
        msg += "You have to approve these rides again to reflect the new rates.<br><br><br>";
        msg += "If You click on 'Yes' - Rates wil be saved and rides will be unapproved <br><br>";
        msg += "If you click on 'No' - Rates will be saved and rides will not be unapproved <br>";
        msg += "You still want to continue???";

        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: msg,
            icon: 'question',
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            denyButtonText: 'No',

            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = "yes";
            } else if (result.isDenied) {
                isResult = "no";
            }
        })
        return isResult;
    }


    async handleConfirmEvents() {
        if (this.handleUpdateValidation()) {
            if (this.state.isAdd == true && this.state.isReturnTrip == false) {
                const isConfirm = await this.askMultipleZoneConfirm();
                if (isConfirm === "yes") {
                    this.openMultipleZone();
                } else if (isConfirm === "no") {
                    await this.updateZonalRates([], []);
                } else {
                    return;
                }

            } else {
                await this.updateZonalRates([], []);
            }
        }
    }

    openMultipleZone() {
        let puZone = this.state.dropdownZone.find(opt => opt.id == this.state.pickupZoneId);
        var firstPuRowKeys = [];

        firstPuRowKeys.push(this.state.pickupZoneId.toString())

        this.setState({
            isMultipleZoneOpen: true,
            pickupZone: puZone.name,
            pickupDropdownZone: this.state.dropdownZone.filter(opt => opt.zoneTypeId == puZone.zoneTypeId),
            selectedPuRowKeys: firstPuRowKeys,
        });
    }

    onSelectionPuGridChanged({ selectedRowKeys }) {
        this.setState({
            selectedPuRowKeys: selectedRowKeys
        });
    }

    async handleSelectMultipleZoneSubmit(e) {
        e.preventDefault();
        const selectedPuRowsData = await this.dataPuGrid.instance.getDataByKeys(this.state.selectedPuRowKeys);

        if (selectedPuRowsData.length <= 0) {
            const isValid = await Swal.fire({
                title: "<small>Pickup Zone</small>",
                text: "Please select atleast one pickup zone.",
                icon: "info",
                dangerMode: false,
                width: "25%",
                dangerMode: false,
                allowOutsideClick: false
            });
            return;
        }

        this.closeMultipleZoneModal();

        await this.updateZonalRates(selectedPuRowsData);

    }
    closeMultipleZoneModal() {
        this.setState({
            isMultipleZoneOpen: false,
            pickupDropdownZone: [],
            pickupZone: '',
            selectedPuRowKeys: [],
        })
    }

    async updateZonalRates(selectedPuRowsData) {
        const dataSource = this.refs.dataGridRef.instance.getDataSource();
        var dataArray = dataSource._items;

        if (dataArray.length <= 0) {
            const isValid = await Swal.fire({
                title: "<small>Zone Rate</small>",
                text: "Zonal rates not found.",
                icon: "info",
                dangerMode: false,
                width: "25%",
                dangerMode: false,
                allowOutsideClick: false
            });
            return;
        }

        var isUpdateApprovedRides = false;
        var isUpdateFutureRides = false;
        if (this.state.isAdd == true) {
            if (new Date(this.state.wefDate) < new Date(currentTime)) {
                const isConfirm = await this.askWefLessThanCurrentConfirm();
                if (!isConfirm) {
                    return;
                } else {
                    const isConfirmUnapprove = await this.askApdateUnapprovedRideConfirm();
                    if (isConfirmUnapprove === "yes") {
                        isUpdateApprovedRides = true;
                    } else if (isConfirmUnapprove === "no") {
                        isUpdateApprovedRides = false;
                    } else {
                        return;
                    }
                }
            }
        }

        const isConfirmFutur = await this.askUpdateFutureRateConfirm();
        if (isConfirmFutur) {
            isUpdateFutureRides = true;
        }
        if (selectedPuRowsData.length > 0) {

            const isConfirm = await this.askSaveMultipleRateConfirm();
            if (!isConfirm) {
                return;
            }
            var request = new Object();
            request.CustomerId = parseInt(this.state.customerId);
            request.IsReturnTrip = this.state.isAdd == true ? this.state.isReturnTrip : false;
            request.WefDate = this.state.isAdd == true ? this.state.wefDate.toLocaleDateString() : this.state.wefDateStr;
            request.ContractGroup = [];

            for (var pu = 0; pu < selectedPuRowsData.length; pu++) {

                let fromAirId = 0, fromZoneId = 0;
                let puZone = this.state.dropdownZone.find(opt => opt.id == selectedPuRowsData[pu].id);

                if (puZone.zoneTypeId === 1) {
                    fromAirId = puZone.airportId;
                } else if (puZone.zoneTypeId === 3) {
                    fromZoneId = puZone.id;
                } 

                var zonalGroup = new Object();
                zonalGroup.IsUpdateApprovedRides = isUpdateApprovedRides;
                zonalGroup.IsUpdateFutureRides = isUpdateFutureRides;
                zonalGroup.RideTypeId = parseInt(this.state.rideTypeId);
                zonalGroup.FromAirportId = parseInt(fromAirId);
                zonalGroup.FromZoneId = parseInt(fromZoneId);
                zonalGroup.HistoryType = "System";
                zonalGroup.Description = this.state.isAdd == true ? "New Rate Added for WEF date [" + this.state.wefDate.toDateString() + "]" : await this.getHistoryDescription(dataArray);
                zonalGroup.ContractZonalGroup = [];

                for (var i = 0; i < dataArray.length; i++) {
                    var rateZonalGroup = new Object();

                    rateZonalGroup.RateId = this.state.isAdd == true ? 0 : parseInt(dataArray[i].rowId);
                    rateZonalGroup.CurrencyId = parseInt(150);
                    rateZonalGroup.VehicleTypeId = parseInt(dataArray[i].vehicleTypeId);
                    rateZonalGroup.VehiclePaxSizeId = parseInt(dataArray[i].vehiclePaxSizeId);
                    rateZonalGroup.HourlyRate = parseFloat(dataArray[i].hourlyRate == null ? 0 : dataArray[i].hourlyRate);
                    rateZonalGroup.IsFlatRate = dataArray[i].isFlatRate == null ? false : dataArray[i].isFlatRate;
                    rateZonalGroup.IsFuelFlatRate = dataArray[i].isFuelFlatRate == null ? false : dataArray[i].isFuelFlatRate;
                    rateZonalGroup.Rate = parseFloat(dataArray[i].hourlyRate == null ? 0 : dataArray[i].hourlyRate);
                    rateZonalGroup.IsHourlyRate = true;
                    rateZonalGroup.AddtnPaxRate = parseFloat(dataArray[i].addTnPaxRate == null ? 0 : dataArray[i].addTnPaxRate);
                    rateZonalGroup.IncludeToll = parseFloat(dataArray[i].tollAmount == null ? 0 : dataArray[i].tollAmount) != 0 ? true : false;
                    rateZonalGroup.TollAmount = parseFloat(dataArray[i].tollAmount == null ? 0 : dataArray[i].tollAmount);
                    rateZonalGroup.IncludeTip = parseFloat(dataArray[i].tipAmount == null ? 0 : dataArray[i].tipAmount) != 0 ? true : false;
                    rateZonalGroup.TipAmount = parseFloat(dataArray[i].tipAmount == null ? 0 : dataArray[i].tipAmount);
                    rateZonalGroup.Stop = parseFloat(dataArray[i].stop == null ? 0 : dataArray[i].stop);
                    rateZonalGroup.Wt = parseFloat(dataArray[i].wt == null ? 0 : dataArray[i].wt);
                    rateZonalGroup.WaitTime = parseFloat(dataArray[i].waitTime == null ? 0 : dataArray[i].waitTime);
                    rateZonalGroup.ServiceFee = parseFloat(dataArray[i].serviceFee == null ? 0 : dataArray[i].serviceFee);
                    rateZonalGroup.FuelSurCharge = parseFloat(dataArray[i].fuelSurCharge == null ? 0 : dataArray[i].fuelSurCharge);
                    rateZonalGroup.MeetGreetCharge = parseFloat(dataArray[i].meetGreetCharge == null ? 0 : dataArray[i].meetGreetCharge);
                    rateZonalGroup.Parking = parseFloat(dataArray[i].parking == null ? 0 : dataArray[i].parking);
                    rateZonalGroup.PortAuthority = parseFloat(dataArray[i].portAuthority == null ? 0 : dataArray[i].portAuthority);
                    rateZonalGroup.UtilityTax = parseFloat(dataArray[i].utilityTax == null ? 0 : dataArray[i].utilityTax);
                    rateZonalGroup.WcTax = parseFloat(dataArray[i].wcTax == null ? 0 : dataArray[i].wcTax);
                    rateZonalGroup.Discount = parseFloat(dataArray[i].discount == null ? 0 : dataArray[i].discount);
                    rateZonalGroup.Commission = parseFloat(dataArray[i].commission == null ? 0 : dataArray[i].commission);
                    rateZonalGroup.CommissionPercentage = parseFloat(dataArray[i].commissionPercentage == null ? 0 : dataArray[i].commissionPercentage);
                    rateZonalGroup.Enable = true
                    zonalGroup.ContractZonalGroup.push(rateZonalGroup);
                }
                request.ContractGroup.push(zonalGroup);
            }

        } else {


            let fromAirId = 0, fromZoneId = 0;
            let puZone = this.state.dropdownZone.find(opt => opt.id == this.state.pickupZoneId);

            if (puZone.zoneTypeId === 1 ) {
                fromAirId = puZone.airportId;
            } else if (puZone.zoneTypeId === 3) {
                fromZoneId = puZone.id;
            }

            if (this.state.isReturnTrip == true) {
                const isConfirm = await this.askReturnTripConfirm(puZone.name);
                if (!isConfirm) {
                    return;
                }
            } else {
                const isConfirm = await this.askSaveRateConfirm(puZone.name);
                if (!isConfirm) {
                    return;
                }
            }

            var request = new Object();
            request.CustomerId = parseInt(this.state.customerId);
            request.IsReturnTrip = this.state.isAdd == true ? this.state.isReturnTrip : false;
            request.WefDate = this.state.isAdd == true ? this.state.wefDate.toLocaleDateString() : this.state.wefDateStr;

            request.ContractGroup = [];

            var zonalGroup = new Object();
            zonalGroup.IsUpdateApprovedRides = isUpdateApprovedRides;
            zonalGroup.IsUpdateFutureRides = isUpdateFutureRides;
            zonalGroup.RideTypeId = parseInt(this.state.rideTypeId);
            zonalGroup.FromAirportId = parseInt(fromAirId);
            zonalGroup.FromZoneId = parseInt(fromZoneId);
            zonalGroup.HistoryType = "System";
            zonalGroup.Description = this.state.isAdd == true ? "New Rate Added for WEF date [" + this.state.wefDate.toDateString() + "]" : await this.getHistoryDescription(dataArray);
            zonalGroup.ContractZonalGroup = [];

            for (var i = 0; i < dataArray.length; i++) {
                var rateZonalGroup = new Object();

                rateZonalGroup.RateId = this.state.isAdd == true ? 0 : parseInt(dataArray[i].rowId);
                rateZonalGroup.CurrencyId = parseInt(150);
                rateZonalGroup.VehicleTypeId = parseInt(dataArray[i].vehicleTypeId);
                rateZonalGroup.VehiclePaxSizeId = parseInt(dataArray[i].vehiclePaxSizeId);
                rateZonalGroup.HourlyRate = parseFloat(dataArray[i].hourlyRate == null ? 0 : dataArray[i].hourlyRate);
                rateZonalGroup.IsFlatRate = dataArray[i].isFlatRate == null ? false : dataArray[i].isFlatRate;
                rateZonalGroup.IsFuelFlatRate = dataArray[i].isFuelFlatRate == null ? false : dataArray[i].isFuelFlatRate;
                rateZonalGroup.Rate = parseFloat(dataArray[i].hourlyRate == null ? 0 : dataArray[i].hourlyRate);
                rateZonalGroup.IsHourlyRate = true;
                rateZonalGroup.AddtnPaxRate = parseFloat(dataArray[i].addTnPaxRate == null ? 0 : dataArray[i].addTnPaxRate);
                rateZonalGroup.IncludeToll = parseFloat(dataArray[i].tollAmount == null ? 0 : dataArray[i].tollAmount) != 0 ? true : false;
                rateZonalGroup.TollAmount = parseFloat(dataArray[i].tollAmount == null ? 0 : dataArray[i].tollAmount);
                rateZonalGroup.IncludeTip = parseFloat(dataArray[i].tipAmount == null ? 0 : dataArray[i].tipAmount) != 0 ? true : false;
                rateZonalGroup.TipAmount = parseFloat(dataArray[i].tipAmount == null ? 0 : dataArray[i].tipAmount);
                rateZonalGroup.Stop = parseFloat(dataArray[i].stop == null ? 0 : dataArray[i].stop);
                rateZonalGroup.Wt = parseFloat(dataArray[i].wt == null ? 0 : dataArray[i].wt);
                rateZonalGroup.WaitTime = parseFloat(dataArray[i].waitTime == null ? 0 : dataArray[i].waitTime);
                rateZonalGroup.ServiceFee = parseFloat(dataArray[i].serviceFee == null ? 0 : dataArray[i].serviceFee);
                rateZonalGroup.FuelSurCharge = parseFloat(dataArray[i].fuelSurCharge == null ? 0 : dataArray[i].fuelSurCharge);
                rateZonalGroup.MeetGreetCharge = parseFloat(dataArray[i].meetGreetCharge == null ? 0 : dataArray[i].meetGreetCharge);
                rateZonalGroup.Parking = parseFloat(dataArray[i].parking == null ? 0 : dataArray[i].parking);
                rateZonalGroup.PortAuthority = parseFloat(dataArray[i].portAuthority == null ? 0 : dataArray[i].portAuthority);
                rateZonalGroup.UtilityTax = parseFloat(dataArray[i].utilityTax == null ? 0 : dataArray[i].utilityTax);
                rateZonalGroup.WcTax = parseFloat(dataArray[i].wcTax == null ? 0 : dataArray[i].wcTax);
                rateZonalGroup.Discount = parseFloat(dataArray[i].discount == null ? 0 : dataArray[i].discount);
                rateZonalGroup.Commission = parseFloat(dataArray[i].commission == null ? 0 : dataArray[i].commission);
                rateZonalGroup.CommissionPercentage = parseFloat(dataArray[i].commissionPercentage == null ? 0 : dataArray[i].commissionPercentage);
                rateZonalGroup.Enable = true

                zonalGroup.ContractZonalGroup.push(rateZonalGroup);
            }
            request.ContractGroup.push(zonalGroup);
        }

        try {
            this.setState({
                isUpdateSubmited: true,
            });
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('contract-rate-api/save', requestParams);
            const data = await response.json();

            Swal.fire({
                icon: data.isSuccess ? 'success' : 'error',
                title: data.isSuccess ? 'Success' : 'Oops...',
                text: data.resultMessage,
                confirmButtonText: 'Thank you!',
                allowOutsideClick: false
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (data.isSuccess) {
                    if (result.isConfirmed) {
                        this.setState({
                            isUpdateSubmited: false,
                        });
                        if (this.props.location.state == undefined) {
                            const isConfirm = this.askNewRateConfirm();
                            if (!isConfirm) {
                                this.goToBackPage();
                            } else {
                                this.handleClearSearch();
                            }

                        } else {
                            this.goToBackPage(); // method defined in BookingComponent
                        }
                    }
                } else {
                    this.setState({
                        isUpdateSubmited: false,
                    });
                }
            })


        } catch (e) {
            this.setState({ isUpdateSubmited: false })
        }

    }


    async getHistoryDescription(dataArray) {
        var dis2 = "";
        let fromAirId = 0, fromZoneId = 0;

        let puZone = this.state.dropdownZone.find(opt => opt.id == this.state.pickupZoneId);

        if (puZone.zoneTypeId === 1) {
            fromAirId = puZone.airportId;
        } else if (puZone.zoneTypeId === 3) {
            fromZoneId = puZone.id;
        } 

        var request = new Object();
        request.RideTypeId = this.state.rideTypeId;
        request.CustomerId = this.state.customerId;
        request.FromZoneId = fromZoneId;
        request.FromAirportId = fromAirId;
        request.WefDate = "";
        const requestParams = getRequestParams('POST', request);
        const response = await fetch('contract-rate-api/details', requestParams);
        const data = await response.json();


        var filterDataSource = data.contractRateDetail.filter(f => f.wefDateStr === this.state.wefDateStr);


        for (var j = 0; j < filterDataSource.length; j++) {
            for (var i = 0; i < dataArray.length; i++) {
                var vehicletypeId = dataArray[i].vehicleTypeId;

                if (filterDataSource[j].vehicleTypeId == vehicletypeId) {
                    var hDescription = "(";

                    var isChanges = false;
                    if (filterDataSource[j].isFlatRate != dataArray[i].isFlatRate) {
                        isChanges = true;
                        if (dataArray[i].isFlatRate == true) {
                            hDescription = hDescription + "Base flat rate applied , ";
                        } else {
                            hDescription = hDescription + "Base flat rate not applied , ";
                        }
                    }
                    if (parseFloat(filterDataSource[j].hourlyRate) != parseFloat(dataArray[i].hourlyRate == null ? 0 : dataArray[i].hourlyRate)) {
                        isChanges = true;
                        hDescription = hDescription + "Hourly rate from " + filterDataSource[j].hourlyRate + " to " + (dataArray[i].hourlyRate == null ? 0 : dataArray[i].hourlyRate) + " , ";
                    }
                    if (parseFloat(filterDataSource[j].commission) != parseFloat(dataArray[i].commission == null ? 0 : dataArray[i].commission)) {
                        isChanges = true;
                        hDescription = hDescription + "Commission rate from " + filterDataSource[j].commission + " to " + (dataArray[i].commission == null ? 0 : dataArray[i].commission) + " , ";
                    }
                    if (parseFloat(filterDataSource[j].commissionPercentage) != parseFloat(dataArray[i].commissionPercentage == null ? 0 : dataArray[i].commissionPercentage)) {
                        isChanges = true;
                        hDescription = hDescription + "Commission % from " + filterDataSource[j].commissionPercentage + " to " + (dataArray[i].commissionPercentage == null ? 0 : dataArray[i].commissionPercentage) + " , ";
                    }
                    if (parseFloat(filterDataSource[j].meetGreetCharge) != parseFloat(dataArray[i].meetGreetCharge == null ? 0 : dataArray[i].meetGreetCharge)) {
                        isChanges = true;
                        hDescription = hDescription + "Meet Greet Charge from " + filterDataSource[j].meetGreetCharge + " to " + (dataArray[i].meetGreetCharge == null ? 0 : dataArray[i].meetGreetCharge) + " , ";
                    }
                    if (parseFloat(filterDataSource[j].addTnPaxRate) != parseFloat(dataArray[i].addTnPaxRate == null ? 0 : dataArray[i].addTnPaxRate)) {
                        isChanges = true;
                        hDescription = hDescription + "AddtionalPaxRate from " + filterDataSource[j].addTnPaxRate + " to " + (dataArray[i].addTnPaxRate == null ? 0 : dataArray[i].addTnPaxRate) + " , ";
                    }
                    if (parseFloat(filterDataSource[j].tollAmount) != parseFloat(dataArray[i].tollAmount == null ? 0 : dataArray[i].tollAmount)) {
                        isChanges = true;
                        hDescription = hDescription + "TollAmount from " + filterDataSource[j].tollAmount + " to " + (dataArray[i].tollAmount == null ? 0 : dataArray[i].tollAmount) + " , ";
                    }
                    if (parseFloat(filterDataSource[j].tipAmount) != parseFloat(dataArray[i].tipAmount == null ? 0 : dataArray[i].tipAmount)) {
                        isChanges = true;
                        hDescription = hDescription + "TipAmount from " + filterDataSource[j].tipAmount + " to " + (dataArray[i].tipAmount == null ? 0 : dataArray[i].tipAmount) + " , ";
                    }
                    if (parseFloat(filterDataSource[j].stop) != parseFloat(dataArray[i].stop == null ? 0 : dataArray[i].stop)) {
                        isChanges = true;
                        hDescription = hDescription + "Stop from " + filterDataSource[j].stop + " to " + (dataArray[i].stop == null ? 0 : dataArray[i].stop) + " , ";
                    }
                    if (parseFloat(filterDataSource[j].wt) != parseFloat(dataArray[i].wt == null ? 0 : dataArray[i].wt)) {
                        isChanges = true;
                        hDescription = hDescription + "Wt from " + filterDataSource[j].wt + " to " + (dataArray[i].wt == null ? 0 : dataArray[i].wt) + " , ";
                    }
                    if (parseFloat(filterDataSource[j].serviceFee) != parseFloat(dataArray[i].serviceFee == null ? 0 : dataArray[i].serviceFee)) {
                        isChanges = true;
                        hDescription = hDescription + "ServiceFee from " + filterDataSource[j].serviceFee + " to " + (dataArray[i].serviceFee == null ? 0 : dataArray[i].serviceFee) + " , ";
                    }
                    if (filterDataSource[j].isFuelFlatRate != dataArray[i].isFuelFlatRate) {
                        isChanges = true;
                        if (dataArray[i].isFuelFlatRate == true) {
                            hDescription = hDescription + "Fuel flat rate applied , ";
                        } else {
                            hDescription = hDescription + "Fuel flat rate not applied , ";
                        }
                    }
                    if (parseFloat(filterDataSource[j].fuelSurCharge) != parseFloat(dataArray[i].fuelSurCharge == null ? 0 : dataArray[i].fuelSurCharge)) {
                        isChanges = true;
                        hDescription = hDescription + "FuelSurCharge from " + filterDataSource[j].fuelSurCharge + " to " + (dataArray[i].fuelSurCharge == null ? 0 : dataArray[i].fuelSurCharge) + " , ";
                    }
                    if (parseFloat(filterDataSource[j].parking) != parseFloat(dataArray[i].parking == null ? 0 : dataArray[i].parking)) {
                        isChanges = true;
                        hDescription = hDescription + "Parking from " + filterDataSource[j].parking + " to " + (dataArray[i].parking == null ? 0 : dataArray[i].parking) + " , ";
                    }

                    if (isChanges == true) {
                        dis2 = dis2 + hDescription + " of " + dataArray[i].vehicleType + "), ";
                    }

                    break;
                }
            }
        }
        dis2 = "Changes made [" + dis2 + "] for wef date " + this.state.wefDateStr + ".";
        return dis2;
    }

    handleHistoryRateSubmit(e) {
        e.preventDefault();
        let fromAirId = 0, fromZoneId = 0;

        let puZone = this.state.dropdownZone.find(opt => opt.id == this.state.pickupZoneId);

        if (puZone.zoneTypeId === 1) {
            fromAirId = puZone.airportId;
        } else if (puZone.zoneTypeId === 3) {
            fromZoneId = puZone.id;
        } 

        var fromZoneIdStr = (this.state.rideTypeId == 1 || this.state.rideTypeId == 3) ? fromAirId : fromZoneId;
        this.setState({ isHistorySubmited: true });
        this.refs.ratesHistoryComponent.showModal("Contract Rate History", this.state.customerId, fromZoneIdStr, 0);
        this.setState({ isHistorySubmited: false });
    }

    render() {
        const rateTitle = this.state.isAdd == false ? "Edit Contract Rate" : "Add Contract Rate"
        const renderGridCurrencyCell = (data) => {
            return <div>${parseFloat(data.value).toFixed(2)}</div>;
        }

        return (
            <div className="content-wrapper" id="rateDiv">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-sm-6 col-md-6 col-lg-6">
                                <h1 className="m-0 text-dark">{rateTitle}</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem>
                                        <NavLink tag={Link} to="#" className="btn btn-block btn-outline-secondary btn-sm" onClick={(e) => { this.goToBackPage() }} title="Back" ><i className="fas fa-arrow-left"></i> Back</NavLink>
                                    </NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <form method="post" onSubmit={this.handleSearchZonalRateSubmit} style={(this.state.isSubmited || this.state.isUpdateSubmited) ? { pointerEvents: "none", opacity: "0.4" } : {}}>

                    <div className="rez-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>Customer <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.customerList.length > 0 ? this.state.customerList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="customerId"
                                            defaultValue={0}
                                            value={this.state.customerId}
                                            displayExpr='fullName'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleCustomerInputChange}
                                            showClearButton={true}
                                            disabled={this.state.isSearched == true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>Pickup/Dropoff Zone <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.dropdownZone.length > 0 ? this.state.dropdownZone : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select pick-up zone"
                                            name="pickupZoneId"
                                            defaultValue={0}
                                            value={this.state.pickupZoneId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handlePickupZoneChange}
                                            showClearButton={true}
                                            disabled={this.state.isSearched == true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["pickupZoneId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["pickupZoneId"]}</span>}

                                    </div>
                                </div>
                              

                                {(this.state.dataSource.length > 0) && <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2" style={{ display: this.state.isAdd == true ? 'block' : 'none' }}>
                                    <div className="form-group" >
                                        <label>WEF Date <span className="text-danger">*</span></label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="wefDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.wefDate}
                                            value={this.state.wefDate}
                                            onValueChanged={this.handleWefDateChange}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["wefDate"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["wefDate"]}</span>}
                                    </div>
                                </div>}
                                {(this.state.dataSource.length > 0) && <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2" style={{ display: this.state.isAdd == false ? 'block' : 'none' }}>
                                    <div className="form-group">
                                        <label>WEF Date <span className="text-danger">*</span></label>
                                        <select name="wefDateStr" className="form-control form-control-sm" value={this.state.wefDateStr} onChange={this.handleWEFDateSelectChange}>
                                            {
                                                this.state.dropdownWefDate &&
                                                this.state.dropdownWefDate.map((item, i) =>
                                                    (<option key={i} value={item.id}>{item.name}</option>))
                                            }
                                        </select>
                                        {this.state.errors["wefDateStr"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["wefDate"]}</span>}
                                    </div>
                                </div>}

                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <br />
                                        <span className="form-check mr-2" style={{ display: this.state.dropdownWefDate.length > 0 ? 'inline-block' : 'none' }} >
                                            <input className="form-check-input" type="checkbox" name="isAdd" checked={this.state.isAdd} onChange={this.handleInputChange} />
                                            <label className="form-check-label">Add New Rate</label>
                                        </span>
                                       
                                    </div>

                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-right">
                                    <div className="form-group">
                                        <label className="btn-block">&nbsp;</label>
                                        <button type="submit" className="btn btn-success btn-sm" style={{ display: this.state.dataSource.length > 0 ? 'none' : 'inline-block' }} onClick={this.handleSearchZonalRateSubmit} >
                                            {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSubmited && <span title="Search"><i className="fas fa-search"></i> Search</span>}
                                        </button>&nbsp; &nbsp;
                                        <button type="submit" className="btn btn-success btn-sm" style={{ display: this.state.dataSource.length == 0 ? 'none' : 'inline-block' }} onClick={this.handleUpdateZonalRateSubmit} >
                                            {this.state.isUpdateSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isUpdateSubmited && <span title="Update"><i className="fas fa-check"></i> Update</span>}
                                        </button>&nbsp; &nbsp;
                                        <button type="submit" className="btn btn-success btn-sm" style={{ display: this.state.dataSource.length == 0 ? 'none' : 'inline-block' }} onClick={this.handleHistoryRateSubmit} >
                                            {this.state.isHistorySubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isHistorySubmited && <span title="History"><i className="fas fa-history"></i> History</span>}
                                        </button>&nbsp; &nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" style={{ display: this.state.dataSource.length == 0 ? 'none' : 'inline-block' }} onClick={this.handleClearSearch} title="Clear"><i className="fas fa-eraser"></i> Clear</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content">
                        <div className="card card-grid">
                            <div className="card-body">
                                <DataGrid
                                    ref="dataGridRef"
                                    dataSource={this.state.dataSource}
                                    keyExpr="vehicleTypeId"
                                    selection={{ mode: 'single' }}
                                    columnsAutoWidth={false}
                                    showColumnLines={true}
                                    showRowLines={true}
                                    showBorders={true}
                                    rowAlternationEnabled={true}
                                    showBorders={true}
                                    allowColumnResizing={true}
                                    columnResizingMode='widget'

                                    repaintChangesOnly={true}
                                    onToolbarPreparing={this.onToolbarPreparing}>
                                    <Editing
                                        mode="cell"
                                        allowAdding={false}
                                        allowUpdating={true}
                                        allowDeleting={false}>
                                    </Editing>
                                    <Column dataField="vehicleType" width={300} allowEditing={false} caption="Customer Name" allowFiltering={true} allowSorting={true} />
                                    <Column dataField="isFlatRate" width={100} caption="Is Flat Rate" allowFiltering={true} allowSorting={true} />
                                    <Column dataField="hourlyRate" width={100} caption="Hourly Fare ($)" allowFiltering={true} allowSorting={true} alignment="right" format="decimal" cellRender={renderGridCurrencyCell} />
                                    <Column dataField="tollAmount" caption="Toll Amt ($)" width={100} allowFiltering={true} allowSorting={true} alignment="right" format="decimal" cellRender={renderGridCurrencyCell} />
                                    <Column dataField="tipAmount" caption="Tip Amt ($)" width={100} allowFiltering={true} allowSorting={true} alignment="right" format="decimal" cellRender={renderGridCurrencyCell} />
                                    <Column dataField="serviceFee" caption="Service Fee($)" width={100} allowFiltering={true} allowSorting={true} alignment="right" format="decimal" cellRender={renderGridCurrencyCell} />
                                    <Column dataField="isFuelFlatRate" width={100} caption="Is Fuel Flat Rate" allowFiltering={true} allowSorting={true} />
                                    <Column dataField="fuelSurCharge" caption="Fuel Surcharge ($)" width={100} allowFiltering={true} allowSorting={true} alignment="right" format="decimal" cellRender={renderGridCurrencyCell} />
                                    <Column dataField="parking" caption="Parking Charge($)" width={100} allowFiltering={true} allowSorting={true} alignment="right" format="decimal" cellRender={renderGridCurrencyCell} />
                                    <Column dataField="meetGreetCharge" caption="Meet Greet ($)" width={100} allowFiltering={true} allowSorting={true} alignment="right" format="decimal" cellRender={renderGridCurrencyCell} />
                                    <Column dataField="addTnPaxRate" caption="Add'l Pax Amt ($)" width={100} allowFiltering={true} allowSorting={true} alignment="right" format="decimal" cellRender={renderGridCurrencyCell} />
                                    <Column dataField="commission" caption="Conmmission Amt ($)" width={120} allowFiltering={true} allowSorting={true} alignment="right" format="decimal" cellRender={renderGridCurrencyCell} />
                                    <Column dataField="commissionPercentage" caption="Conmmission (%)" width={100} allowFiltering={true} allowSorting={true} alignment="right" format="decimal" cellRender={renderGridCurrencyCell} />
                                    <Column dataField="stop" caption="Stop Amt ($)" width={100} allowFiltering={true} allowSorting={true} alignment="right" format="decimal" cellRender={renderGridCurrencyCell} />
                                    <Column dataField="stop" caption="Stop Amt ($)" width={100} allowFiltering={true} allowSorting={true} alignment="right" format="decimal" cellRender={renderGridCurrencyCell} />
                                    <Column dataField="wt" caption="Wait Amt ($)" width={100} allowFiltering={true} allowSorting={true} alignment="right" format="decimal" cellRender={renderGridCurrencyCell} />
                                    <Column dataField="discount" caption="Discount Amt ($)" width={100} allowFiltering={true} allowSorting={true} alignment="right" format="decimal" cellRender={renderGridCurrencyCell} />

                                    <SortByGroupSummaryInfo summaryItem="count" />
                                    <GroupPanel visible={false} />
                                    <HeaderFilter visible={false} />
                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                    <Grouping autoExpandAll={true} />

                                    <Export enabled={true} allowExportSelectedData={false} />
                                    <ColumnChooser enabled={true} />
                                    <ColumnFixing enabled={true} />
                                    <Scrolling mode="infinite" />
                                </DataGrid>
                            </div>
                        </div>

                    </div>

                </form>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isMultipleZoneOpen}
                    className={"react-modal edit-service-modal"}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal"
                >
                    <form method="post" onSubmit={this.handleSelectMultipleZoneSubmit} >
                        <div className="modal-header">
                            <h6 className="modal-title">Multiple Zone</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeMultipleZoneModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <small>Pickup/Dropoff zone is <i><strong className="text-danger">{this.state.pickupZone}</strong></i>.</small>
                                </div>
                            </div>
                            <div className="widget-container" style={{ maxHeight: 350 + 'px' }}>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div>
                                            <small>Apply same rates for other pickup/dropoff zone:</small>
                                        </div>
                                        <DataGrid
                                            dataSource={this.state.pickupDropdownZone}
                                            id="grid-container"
                                            keyExpr="id"
                                            showBorders={true}
                                            selectedRowKeys={this.state.selectedPuRowKeys}
                                            onSelectionChanged={this.onSelectionPuGridChanged}
                                            ref={ref => this.dataPuGrid = ref}>
                                            <Selection
                                                mode="multiple"
                                                selectAllMode={"allPages"}
                                                showCheckBoxesMode={"always"}
                                            />
                                            <Column dataField="name" caption="Zone Name" />
                                            <Scrolling mode="infinite" />

                                        </DataGrid>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success btn-sm" onClick={this.handleSelectMultipleZoneSubmit} ><span><i className="fas fa-save"></i> Continue</span> </button>

                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeMultipleZoneModal}><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <RatesHistoryComponent ref="ratesHistoryComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >)
    }
}