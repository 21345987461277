import React, { Component } from 'react';
import IdleTimer from 'react-idle-timer' // npm i react-idle-timer
import Swal from 'sweetalert2';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Modal from 'react-modal'; //npm install --save react-modal
import { SelectBox } from 'devextreme-react/select-box';
import './NavMenu.css';
import { ClockComponent } from '../components/Shared/ClockComponent';
import { ChangePasswordComponent } from '../components/Shared/ChangePasswordComponent';
import { formatDate, getRequestParams } from '../helpers/globalfunctions.js';
import { UserType, FeatureActionType, StatusType } from '../helpers/fixcodes.js'
import { w3cwebsocket as W3CWebSocket } from "websocket"; //npm i --save websocket
import { LoadPanel } from 'devextreme-react/load-panel';
import $ from 'jquery';

var today = new Date();
var currentDate = null;

const lanOptions = [
    { "cultureCode": "en-US", "languageName": "English" },
    { "cultureCode": "en-FR", "languageName": "French" },
    { "cultureCode": "es-ES", "languageName": "Spanish" },
];

const customStyles = {
    content: {
        width: '40%',
    }
};

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);
        currentDate = (today.getMonth() + 1) + '-' + today.getDate() + '-' + today.getFullYear();
        this.idleTimer = null;
        this.timeout = 3600000;
        //this.timeout = 36000;
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.state = {
            loadPanelVisible: false,
            collapsed: true,
            isOpenAutoLogoutModal: false,
            cultureCode: "",
            locationId: parseInt(sessionStorage.getItem('session_locationId')),
            timezoneCode: sessionStorage.getItem("session_timezoneCode"),
            minutesDifference: sessionStorage.getItem("session_minutesDifference"),
            languages: [],
            servicelocations: [],
            currentDateTime: currentDate,
            date: new Date(),
            driverMessageModalIsOpen: false,
            menuHtml: null,
            fileGuid: '00000000-0000-0000-0000-000000000000',
            userProfileImage: '../../dist/img/user1-128x128.jpg',
            remaining: this.timeout,
            isIdle: false,
            lastActive: new Date(),
            elapsed: 0,
            lastEvent: 'Events Emitted on Leader',
            leader: false,
            userTypeId: sessionStorage.getItem('session_userTypeId'),
            fileGuid: sessionStorage.getItem("session_fileGuid"),
            skinId: sessionStorage.getItem("session_skinId"),
            skinName: sessionStorage.getItem("session_skinName"),
            cssSkinName: sessionStorage.getItem("session_cssSkinName"),
            accountLogo: sessionStorage.getItem("session_accountLogo"),
            accountLogoSize: sessionStorage.getItem("session_accountLogoSize"),
            cssName: '/dist/css/skins/skin-' + sessionStorage.getItem("session_cssSkinName") + '.css',
            wsurl: sessionStorage.getItem("session_socketApiUrl"),
            notificationModalIsOpen: false,
            notificationMessage: [],
            allowDispatchNotification: false,
            allowSocketConnection: sessionStorage.getItem("session_allowSocketConnection") === "true" ? true : false,
            //allowSocketConnection: false,
        };

        this.webSocketInterval = null;
        this.getLocations = this.getLocations.bind(this);
        this.getLanguages = this.getLanguages.bind(this);
        this.getUserLocations = this.getUserLocations.bind(this);

        this.updateDate = this.updateDate.bind(this);
        this.bindProfileImage = this.bindProfileImage.bind(this);
        this.openPasswordModal = this.openPasswordModal.bind(this);

        this.handleCultureChange = this.handleCultureChange.bind(this);
        this.handleDefaultLocationChange = this.handleDefaultLocationChange.bind(this);

        //this.closeCannedMessageModal = this.closeCannedMessageModal.bind(this);
        //this.afterOpenCannedMessageModal = this.afterOpenCannedMessageModal.bind(this);
        //this.editCannedMessageControl = this.editCannedMessageControl.bind(this);

        this.handleOnActive = this.handleOnActive.bind(this);
        this.handleOnIdle = this.handleOnIdle.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.handlePause = this.handlePause.bind(this);
        this.handleResume = this.handleResume.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        //this.selectCSS = this.selectCSS.bind(this);

        this.getLocationList = this.getLocationList.bind(this);
        this.getDriversList = this.getDriversList.bind(this);
        this.getVehiclesList = this.getVehiclesList.bind(this);
        this.getStatusList = this.getStatusList.bind(this);
        this.getVehicleTypeList = this.getVehicleTypeList.bind(this);
        this.getVehicleGroupTypeList = this.getVehicleGroupTypeList.bind(this);
        this.getZones = this.getZones.bind(this);
        this.setupWebSocket = this.setupWebSocket.bind(this);
        this.closeNotificationModal = this.closeNotificationModal.bind(this);
    }

    //selectCSS() {        
    //    //if (this.state.cssSkinName == "") {
    //    //    require('../../ClientApp/public/skins/skin-blue.css');
    //    //} else {
    //    //    require('../../ClientApp/public/skins/skin-' + this.state.cssSkinName + '.css');
    //    //}

    //    //if (this.state.cssSkinName == "") {
    //    //    require('ClientApp/public/dist/css/skins/skin-blue.css');
    //    //} else {
    //    //    require('ClientApp/public/dist/css/skins/skin-' + this.state.cssSkinName + '.css');
    //    //}
    //}

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }
    async componentDidMount() {
        //this.selectCSS();
        //this.interval = setInterval(this.updateDate, 1000); 
        try {
            if (this.state.allowSocketConnection) {
                this.setupWebSocket();
            }

            this.setState({
                remaining: this.idleTimer.getRemainingTime(),
                lastActive: this.idleTimer.getLastActiveTime(),
                elapsed: this.idleTimer.getElapsedTime(),
                leader: this.idleTimer.isLeader(),
                isIdle: this.idleTimer.isIdle(),

                skinId: parseInt(sessionStorage.getItem("session_skinId")),
                skinName: sessionStorage.getItem("session_skinName"),
                cssSkinName: sessionStorage.getItem("session_cssSkinName"),
                accountLogo: sessionStorage.getItem("session_accountLogo"),
                accountLogoSize: parseInt(sessionStorage.getItem("session_accountLogoSize")),
            });

            setInterval(() => {
                this.setState({
                    remaining: this.idleTimer.getRemainingTime(),
                    lastActive: this.idleTimer.getLastActiveTime(),
                    elapsed: this.idleTimer.getElapsedTime(),
                    leader: this.idleTimer.isLeader(),
                    isIdle: this.idleTimer.isIdle()
                })

                if ((this.idleTimer.getRemainingTime() / 1000) < 20 && (this.idleTimer.getRemainingTime() / 1000) > 19) {
                    this.showModal();
                }
            }, 1000)
        } catch (e) {

        }

        await this.getLanguages();
        this.getLocations();
        await this.getUserRoleFeatureList();
        await this.bindProfileImage();
        document.addEventListener("mousemove", this.handleMouseMove, false);
        document.addEventListener("keypress", this.handleKeyPress, false);
    }

    handleMouseMove = event => {
        if (this.state.isOpenAutoLogoutModal === true) {
            this.setState({ isOpenAutoLogoutModal: false })
            this.handleReset();
        }
    };

    handleKeyPress = event => {
        if (this.state.isOpenAutoLogoutModal === true) {
            this.setState({ isOpenAutoLogoutModal: false })
            this.handleReset();
        }
    }

    handleOnActive() {
        this.setState({ lastEvent: 'active' })
    }

    handleOnIdle(e) {
        this.setState({ lastEvent: 'idle' })
        sessionStorage.clear();
        window.location.href = "/";
    }

    handleReset() {
        this.idleTimer.reset()
    }

    handlePause() {
        this.idleTimer.pause()
    }

    handleResume() {
        this.idleTimer.resume()
    }

    showModal = async () => {
        try {
            this.setState({ isOpenAutoLogoutModal: true });
        } catch (ex) {
            console.log(ex);
        }
        return;
    }
    handleOnAfterOpenModal = async () => {
        try {

        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isOpenAutoLogoutModal: false,
            });
        } catch (ex) {
            console.log(ex);
        }
    }

    async bindProfileImage() {
        try {
            var fileGuid = sessionStorage.getItem('session_fileGuid');
            if (fileGuid != '00000000-0000-0000-0000-000000000000') {
                const requestParams = getRequestParams('GET', '');
                const response = await fetch('file-api/download/' + fileGuid, requestParams);
                const data = await response.json();
                this.setState({ userProfileImage: 'data:' + data.mimeType + ';base64,' + data.fileContent });
            }
        } catch (e) {
        }
    }
    updateDate() {
        this.setState({
            date: new Date(),
            currentDateTime: currentDate,
        });
    }
    getLanguages() {
        this.setState({ languages: lanOptions });
    }
    async getLocations() {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/user-location-api/user/' + parseInt(sessionStorage.getItem('session_userId')), requestParams);
            const data = await response.json();
            //let defaultLocationId = data.userLocations.find(opt => opt.isDefault == true).locationId;

            this.setState({
                servicelocations: data.userLocations,
                //locationId: parseInt(sessionStorage.getItem('session_locationId')),
            });
        } catch (e) {
            console.log(e);
        }
    }
    async getUserRoleFeatureList() {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('user-api/' + parseInt(sessionStorage.getItem('session_userId')) + '/role-feature/' + Number(FeatureActionType.UTWiz), requestParams);
            if (response.ok) {
                let data = await response.json();
                data = JSON.parse(JSON.stringify(data.userRoleFeature));
                data = data.filter(el => el.isActive === true);
                let menuHtmlItem = this.getMenuHtml(data);
                this.setState({ menuHtml: menuHtmlItem });
            }
        } catch (e) {
        }
    }
    getMenuHtml(menuFeature) {
        let menuHtmlItem = [];
        menuFeature = JSON.parse(JSON.stringify(menuFeature));
        menuFeature.forEach((menu) => {
            if (menu.parentMenuId === 0) {
                let childMenu = menuFeature.filter(el => el.parentMenuId === menu.childMenuId);
                if (childMenu.length > 0) {
                    menuHtmlItem.push(
                        <li key={menu.childMenuId} className="nav-item dropdown">
                            <a id={"dropdown" + menu.menuName} href={'/' + menu.componentPath} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle">{menu.menuName}</a>
                            {
                                <ul key={menu.childMenuId} aria-labelledby={"dropdown" + menu.menuName} className="dropdown-menu border-0 shadow menu-Sub-Child-BackGround-Color">
                                    {
                                        this.subMenuHtml(menuFeature, menu)
                                    }
                                </ul>
                            }
                        </li >
                    );
                }
                else {
                    menuHtmlItem.push(<NavItem key={menu.childMenuId} > <NavLink tag={Link} to={'/' + menu.componentPath} >{menu.menuName}</NavLink></NavItem>)
                }
            }
        });
        return menuHtmlItem;
    }

    handleCultureChange(selectedOption) {
        var selCultureCode = selectedOption.selectedItem != null ? selectedOption.selectedItem.cultureCode : "en-US";
        sessionStorage.setItem("session_culturecode", selCultureCode);
        this.setState({ cultureCode: selCultureCode });
    }
    async handleDefaultLocationChange(event) {
        if (event.preventDefault) {
            this.setState({ loadPanelVisible:true})
            try {
                const target = event.target;
                const value = target.type === 'checkbox' ? target.checked : target.value;
                const name = target.name;
                let sellocationName = event.target.options[event.target.selectedIndex].text;
                let sellocationId = event.target.options[event.target.selectedIndex].value;
                await this.setLocation(sellocationId);
            //try {
            //    let alertmesage = "Changing location to [" + sellocationName + " ] will be set as default location. Do you want to proceed?";
            //    Swal.fire({
            //        title: "<small>Change Default Location</small>",
            //        html: alertmesage,
            //        icon: 'warning',
            //        showCancelButton: true,
            //        confirmButtonColor: '#3085d6',
            //        cancelButtonColor: '#d33',
            //        confirmButtonText: 'Yes',
            //        allowOutsideClick: false
            //    }).then((result) => {
            //        if (result.isConfirmed) {
            //            this.setLocation(sellocationId);
            //        }
            //    });
            //} catch (e) {
            //    debugger;
            //    console.log(e);
            //}
            } catch (e) {
            }
            finally {
                this.setState({ loadPanelVisible: false })
            }
            
        }
    }

    async getLocationList() {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/account-api/locations', requestParams);
            const data = await response.json();
            sessionStorage.setItem("session_locations", data != null ? JSON.stringify(data) : []);
        } catch (e) {
            console.log(e);
        }
    }

    async getDriversList() {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/driver-api/dropdown/', requestParams);
            const data = await response.json();
            sessionStorage.setItem("session_drivers", data != null ? JSON.stringify(data) : []);
        } catch (e) {
            console.log(e);
        }
    }

    async getVehiclesList() {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/vehicle-api/dropdown/0/0', requestParams);
            const data = await response.json();
            sessionStorage.setItem("session_vehicles", data != null ? JSON.stringify(data) : []);
        } catch (e) {
            console.log(e);
        }
    }

    async getStatusList() {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/status-api/' + StatusType.Booking, requestParams);
            const data = await response.json();
            sessionStorage.setItem("session_tripstatus", data != null ? JSON.stringify(data) : []);
        } catch (e) {
            console.log(e);
        }
    }

    async getVehicleTypeList() {
        try {
            var apiVehicleTypeRequest = new Object();
            var apiVehicleTypeRequest = new Object();
            const requestParams = getRequestParams('POST', apiVehicleTypeRequest);
            const response = await fetch('/vehicletype-api/vehicletype', requestParams);
            const data = await response.json();
            sessionStorage.setItem("session_vehicletype", data != null ? JSON.stringify(data) : []);
        } catch (e) {
            console.log(e);
        }
    }

    async getVehicleGroupTypeList() {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/vehicle-group-type-api/vehicle-group-type', requestParams);
            const data = await response.json();
            sessionStorage.setItem("session_vehiclegrouptype", data != null ? JSON.stringify(data) : []);
        } catch (e) {
            console.log(e);
        }
    }

    async getZones() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('serviceAreaZone-api/zone-dropdown/' + 0 + "/" + 0, requestParams);
            const data = await response.json();
            sessionStorage.setItem("session_zones", data != null ? JSON.stringify(data) : []);
        } catch (e) {
            console.log(e);
        }
    }

    subMenuHtml = (menuFeature, menuList) =>
        menuFeature.filter(el => el.parentMenuId === menuList.childMenuId)
            .map(subchild =>
                (menuFeature.filter(el => el.parentMenuId === subchild.childMenuId).length === 0) ? <NavItem key={subchild.childMenuId} > <NavLink className="dropdown-item" key={subchild.childMenuId} tag={Link} to={'/' + subchild.componentPath} >{subchild.menuName}</NavLink></NavItem>
                    :
                    <li key={subchild.childMenuId} className="dropdown-submenu dropdown-hover">
                        <a id={"dropdown" + subchild.menuName} href={'/' + subchild.componentPath} role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle dropdown-item">{subchild.menuName}</a>
                        {
                            <ul key={subchild.childMenuId} aria-labelledby={"dropdown" + subchild.menuName} className="dropdown-menu border-0 shadow menu-Sub-Child-BackGround-Color">
                                {
                                    this.subMenuHtml(menuFeature, subchild)
                                }
                            </ul>
                        }
                    </li>
            )



    async setLocation(locationId) {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/location-api/' + parseInt(locationId) + '/set-default', requestParams);
            const locationdata = await response.json();
            if (locationdata.isSuccess) {
                this.setState({ locationId: Number(locationId) })
                await this.getUserLocations();
            }
        } catch (e) {

        }
    }

    async getUserLocations() {
        const requestLocationParams = getRequestParams('GET', {});
        const response = await fetch('/authentication/set-user-locations', requestLocationParams);
        const data = await response.json();
        if (data.isSuccess) {
            sessionStorage.setItem("session_token", data.token);
            sessionStorage.setItem("session_locationId", data.userDefaultLocations[0].locationId);
            sessionStorage.setItem("session_locationName", data.userDefaultLocations[0].locationName);
            sessionStorage.setItem("session_timezoneName", data.userDefaultLocations[0].timeZoneName);
            sessionStorage.setItem("session_timezoneCode", data.userDefaultLocations[0].timeZoneCode);
            sessionStorage.setItem("session_minutesDifference", data.userDefaultLocations[0].minutesDifference);
            sessionStorage.setItem("session_dispatchDisplayDuration", data.userDefaultLocations[0].dispatchDisplayDuration);
            sessionStorage.setItem("session_dispatchDisplayDurationMin", data.userDefaultLocations[0].dispatchDisplayDurationMin);
            sessionStorage.setItem("session_dispatchDisplayDurationMax", data.userDefaultLocations[0].dispatchDisplayDurationMax);
            sessionStorage.setItem("session_allowToAddDriverWorkingHours", data.userDefaultLocations[0].allowToAddDriverWorkingHours);
            sessionStorage.setItem("session_dispatchRefreshInterval", data.userDefaultLocations[0].dispatchRefreshInterval);
            sessionStorage.setItem("session_displayCompletedReservations", data.userDefaultLocations[0].displayCompletedReservations);
            sessionStorage.setItem("session_displayCancelledReservations", data.userDefaultLocations[0].displayCancelledReservations);
            sessionStorage.setItem("session_doNotDeleteVehicle", data.userDefaultLocations[0].doNotDeleteVehicle);
            sessionStorage.setItem("session_doNotDeleteDriver", data.userDefaultLocations[0].doNotDeleteDriver);
            sessionStorage.setItem("session_userDefaultLatitude", data.userDefaultLocations[0].userDefaultLatitude);
            sessionStorage.setItem("session_userDefaultLongitude", data.userDefaultLocations[0].userDefaultLongitude);
            sessionStorage.setItem("session_dispatchDisplayDurationMinSec", data.userDefaultLocations[0].dispatchDisplayDurationMinSec);
            sessionStorage.setItem("session_dispatchDisplayDurationMaxSec", data.userDefaultLocations[0].dispatchDisplayDurationMaxSec);
            sessionStorage.setItem("session_socketApiUrl", data.userDefaultLocations[0].socketApiUrl);
            sessionStorage.setItem("session_allowSocketConnection", data.userDefaultLocations[0].allowSocketConnection);

            await this.getLocationList();
            await this.getDriversList();
            await this.getVehiclesList();
            await this.getStatusList();
            await this.getVehicleTypeList();
            await this.getVehicleGroupTypeList();
            await this.getZones();

            window.location.href = window.location.href;
        }
    }

    openPasswordModal(e) {
        this.refs.changePasswordModal.showModal();
    }

    async handleLogout(e) {
        e.preventDefault();
        try {
            var userId = sessionStorage.getItem('session_userId');
            var request = new Object();
            request.UserId = parseInt(userId);
            request.Token = "";

            $.ajax({
                "type": "POST",
                "contentType": 'application/json; charset=UTF-8',
                "url": "/authentication/set-firebasetoken/",
                "data": JSON.stringify(request),
            }).done(
                function (result) {
                    //do what you need
                });
        } catch (e) {
        }
        sessionStorage.clear();
        window.location.href = "/";
    }

    setupWebSocket() {
        try {
            if (this.state.wsurl != "") {
                var socket = new W3CWebSocket(this.state.wsurl + "Connect");
                socket.onopen = (event) => {
                    //console.log("Websocket client connected");

                    //let sessRequest = JSON.stringify({
                    //    operation: 1,
                    //    user: {
                    //        username: sessionStorage.getItem("session_userName"),
                    //        password: sessionStorage.getItem("session_name"),
                    //        userId: sessionStorage.getItem("session_userId")
                    //    }
                    //});
                    //console.log("WebSocket Client " + sessRequest);
                    //socket.send(sessRequest);

                    function establishValidConnection() {
                        console.log("Websocket client connected");
                        if (socket.readyState === socket.OPEN) {
                            let sessRequest = JSON.stringify({
                                user:
                                {
                                    UserName: sessionStorage.getItem("session_userName"),
                                    AccountId: parseInt(sessionStorage.getItem("session_accountId")),
                                    LocationId: parseInt(sessionStorage.getItem("session_locationId")),
                                }
                                //operation: 1,
                                //user: {
                                //    UserName: sessionStorage.getItem("session_userName"),
                                //    //password: sessionStorage.getItem("session_name"),
                                //    //userId: sessionStorage.getItem("session_userId"),
                                //    //token: sessionStorage.getItem("session_token"),
                                //}
                            });
                            console.log("WebSocket Client " + sessRequest);
                            socket.send(sessRequest);
                        } else {
                            setTimeout(establishValidConnection, 500);
                        }
                    }
                    establishValidConnection();
                };

                socket.onerror = () => {
                    console.log('Websocket Connection Error');
                };

                socket.onclose = (event) => {
                    console.log("Websocket client disconnected");
                    this.webSocketInterval = setTimeout(() => { this.setupWebSocket(); }, 5000);
                };

                socket.onmessage = (message) => {
                    console.log("Websocket client message received");
                    //console.log(message.data); 
                    if (message.data != null) {
                        const notification = JSON.parse(message.data);
                        var allnotifications = [];
                        allnotifications = this.state.notificationMessage;
                        //if (notification.UserName === sessionStorage.getItem("session_userName") || notification.AccountId === sessionStorage.getItem("session_accountId")) {
                        if (!notification.DisableDisplayNotification) {
                            if (notification.AccountId === parseInt(sessionStorage.getItem("session_accountId"))) {
                                if (notification.Message !== "") {
                                    allnotifications.push(notification.Message);
                                    this.setState({
                                        notificationModalIsOpen: true,
                                        notificationMessage: allnotifications,
                                    });
                                }
                            }
                        }
                    }
                };
            }
        } catch (err) {
            console.log("Error in " + this.displayName + " setupWebSocket function", err);
        }
    }

    closeNotificationModal() {
        this.setState({
            notificationModalIsOpen: false,
            notificationMessage: [],
        });
    }

    render() {

        const cultureCode = this.state.languages != null ? this.state.languages.cultureCode : "en-US";
        //const locationId = this.state.servicelocations != null ? this.state.locationId : 0;

        //const CustomCss = () => {
        //    let customCss = this.state.cssSkinName;
        //    if (!customCss) {
        //        customCss = '/dist/css/skins/skin-blue.css';
        //    }
        //    else {
        //        customCss = '/dist/css/skins/skin-' + this.state.cssSkinName + '.css';
        //    }
        //    return (
        //        <link rel="stylesheet" type="text/css" href={customCss} />
        //    );
        //};

        return (
            <>
                <header>
                    {/*<CustomCss />*/}
                    <link rel="stylesheet" type="text/css" href={this.state.cssName} />
                    <IdleTimer
                        ref={ref => { this.idleTimer = ref }}
                        onActive={this.handleOnActive}
                        onIdle={this.handleOnIdle}
                        timeout={this.timeout}
                        crossTab={{
                            emitOnAllTabs: true
                        }}
                    />
                    {/*<div>*/}
                    {/*    <h4>Timeout: {this.timeout}ms</h4>*/}
                    {/*    <h4>Time Remaining: {this.state.remaining}</h4>*/}
                    {/*    <h4>Time Elapsed: {this.state.elapsed}</h4>*/}
                    {/*    <h4>Last Active: {formatDate(this.state.lastActive, 'MM-dd-yyyy hh:mm')}</h4>*/}
                    {/*    <h4>Last Event: {this.state.lastEvent}</h4>*/}
                    {/*    <h4>Is Leader: {this.state.leader.toString()}</h4>*/}
                    {/*    <h4>Idle: {this.state.isIdle.toString()}</h4>*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*    <button onClick={this.handleReset}>RESET</button>*/}
                    {/*    <button onClick={this.handlePause}>PAUSE</button>*/}
                    {/*    <button onClick={this.handleResume}>RESUME</button>*/}
                    {/*</div>*/}
                    <nav className="main-header navbar navbar-expand-md navbar-light navbar-white">
                        <div className="container">
                            {this.state.accountLogoSize <= 0 && <a href="/dashboard" className="navbar-brand">
                                <img src="../../dist/img/logo_white_utwiz.png" style={{ height: 24 + 'px' }} alt="UTPro" />
                            </a>}
                            {this.state.accountLogoSize > 0 && <a href="/dashboard" className="navbar-brand">
                                <img src={this.state.accountLogo} alt="UTWiz" style={{ height: 24 + 'px' }} />
                            </a>}
                            <button className="navbar-toggler order-1" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse order-3" id="navbarCollapse">
                                <ul className="navbar-nav">
                                    {this.state.menuHtml}
                                    {/*
                                <NavItem>
                                    <NavLink tag={Link} to="/dashboard">Dashboard</NavLink>
                                </NavItem>
                                <li className="nav-item dropdown">
                                    <a id="dropdownSubMenu1" href="/reservations" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle">Reservation</a>
                                    <ul aria-labelledby="dropdownSubMenu1" className="dropdown-menu border-0 shadow menu-Sub-Child-BackGround-Color">
                                        <NavItem>
                                            <NavLink tag={Link} className="dropdown-item" to="/reservations">New Reservation</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className="dropdown-item" tag={Link} to="/reservation/list">Search Reservation</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className="dropdown-item" tag={Link} to="/uploadschedule">Upload Schedule</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className="dropdown-item" tag={Link} to="/quotation/list">Quotation List</NavLink>
                                        </NavItem>
                                    </ul>
                                </li>
                                <NavItem>
                                    <NavLink tag={Link} to="/dispatch/list">Dispatch</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} to="/tracking">Tracking</NavLink>
                                </NavItem>

                                <li className="nav-item dropdown">
                                    <a id="dropdownMaster" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle">Master</a>
                                    <ul aria-labelledby="dropdownMaster" className="dropdown-menu border-0 shadow menu-Sub-Child-BackGround-Color">
                                        <li className="dropdown-submenu dropdown-hover">
                                            <a id="childMasterCustomer" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="dropdown-item dropdown-toggle">Customers</a>
                                            <ul aria-labelledby="childMasterCustomer" className="dropdown-menu border-0 shadow menu-Sub-Child-BackGround-Color">
                                                <NavItem>
                                                    <NavLink tag={Link} className="dropdown-item" to="/customer/list">Customer</NavLink>
                                                </NavItem>
                                            </ul>
                                        </li>
                                        <li className="dropdown-submenu dropdown-hover">
                                            <a id="childMasterUser" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="dropdown-item dropdown-toggle">Users</a>
                                            <ul aria-labelledby="childMasterUser" className="dropdown-menu border-0 shadow menu-Sub-Child-BackGround-Color">
                                                <NavItem>
                                                    <NavLink tag={Link} className="dropdown-item" to="/users/list">Ground User</NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink tag={Link} className="dropdown-item" to="/customer/user">Customer User</NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink tag={Link} className="dropdown-item" to="/drivers/list">Drivers</NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink tag={Link} className="dropdown-item" to="/user/permission">User Permission</NavLink>
                                                </NavItem>
                                            </ul>
                                        </li>
                                        <li className="dropdown-divider"></li>
                                        <NavItem>
                                            <NavLink tag={Link} className="dropdown-item" to="/franchisee/list">Franchisee</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} className="dropdown-item" to="/vehicle/list">Vehicle</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} className="dropdown-item" to="/device/list">Device</NavLink>
                                        </NavItem>
                                        <li className="dropdown-divider"></li>
                                        <li className="dropdown-submenu dropdown-hover">
                                            <a id="childMasterLocation" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="dropdown-item dropdown-toggle">Location</a>
                                            <ul aria-labelledby="childMasterLocation" className="dropdown-menu border-0 shadow menu-Sub-Child-BackGround-Color">
                                                <NavItem>
                                                    <NavLink tag={Link} className="dropdown-item" to="/location/list">Location</NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink tag={Link} className="dropdown-item" to="/servicearea/list">Service Area</NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink tag={Link} className="dropdown-item" to="/serviceareazone/list">Service Area Zone</NavLink>
                                                </NavItem>
                                            </ul>
                                        </li>
                                        <NavItem>
                                            <NavLink tag={Link} className="dropdown-item" to="/cannedmessage/list">Canned Message</NavLink>
                                        </NavItem>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle">Driver</a>
                                    <ul aria-labelledby="dropdownSubMenu1" className="dropdown-menu border-0 shadow menu-Sub-Child-BackGround-Color">
                                        <NavItem>
                                            <NavLink tag={Link} className="dropdown-item" to="/drivermessage/list">Driver Message</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} className="dropdown-item" to="/loggeddrivers/list">Logged Drivers</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} className="dropdown-item" to="/loggedvehicles/list">Logged Vehicles</NavLink>
                                        </NavItem>
                                    </ul>
                                </li>
                                */}
                                </ul>
                                <ul className="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
                                    <li className="nav-item">
                                        <div className="displayNone">
                                            <SelectBox
                                                dataSource={this.state.languages.length > 0 ? this.state.languages : []}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select Language"
                                                name="cultureCode"
                                                defaultValue={[]}
                                                alue={this.state.cultureCode}
                                                displayExpr='languageName'
                                                valueExpr='cultureCode'
                                                onSelectionChanged={this.handleCultureChange}
                                                showClearButton={true}
                                                disabled={false}
                                                searchEnabled={true}
                                            />
                                        </div>
                                    </li>
                                    <li className="nav-item">
                                        <div className="">
                                            <select name="locationId" className="form-control form-control-sm" value={this.state.locationId} onChange={this.handleDefaultLocationChange}>
                                                {
                                                    this.state.servicelocations &&
                                                    this.state.servicelocations.map((item, i) =>
                                                        (<option key={i} value={item.locationId}>{item.locationName}</option>))
                                                }
                                            </select>
                                        </div>
                                    </li>
                                    <li className="nav-item">
                                        {/*<a className="nav-link" href="#"><ClockComponent
                                        name={this.state.timezoneCode}
                                        date={this.state.date}
                                        currentDateTime={this.state.currentDateTime}
                                        country={"en-US"}
                                        timeZone={"America/New_York"}
                                        utcoffset={this.state.minutesDifference}
                                    /></a>*/}
                                        <span className="clockstyle"><ClockComponent /></span>
                                    </li>
                                    {/*<li className="nav-item">
                                    <a className="nav-link" href="#"></a>
                                </li>*/}
                                    <li className="nav-item dropdown">
                                        <a className="nav-link" data-toggle="dropdown" href="#">
                                            <i className="fas fa-user-circle"></i>&nbsp;&nbsp;{sessionStorage.getItem('session_accountname').substring(0, 7)}&nbsp;&nbsp;<i className="fas fa-arrow-down"></i>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right user-menu">
                                            <a href="#" className="dropdown-item"></a>
                                            <div className="media">
                                                <img alt="User Profile" src={this.state.userProfileImage} className="img-size-50 mr-3 img-circle" />
                                                <div className="media-body">
                                                    <h3 className="dropdown-item-title text-info">
                                                        <b>{sessionStorage.getItem('session_name')}</b>
                                                    </h3>
                                                    <p className="text-sm">{sessionStorage.getItem('session_email')}</p>
                                                    {/*<p className="text-sm"><a href="#" className="text-info">Profile & Prefrences</a></p>*/}
                                                </div>
                                            </div>
                                            <div className="dropdown-divider"></div>
                                            <span className="dropdown-item">
                                                <div className="form-group">
                                                    <label><b>{sessionStorage.getItem('session_accountname')}</b></label>
                                                </div>
                                                <div className="form-group">
                                                    <label>{sessionStorage.getItem('session_accountcode')}</label>
                                                </div>
                                            </span>
                                            {/*<div className="dropdown-divider"></div>
                                        <span className="dropdown-item">
                                            <div className="form-group">
                                                <label>Setup your UTPro account</label>
                                                <div className="progress">
                                                    <div className="progress-bar bg-success progress-bar-striped" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{ width: 40 + '%' }} >
                                                        <span className="sr-only">40% Complete (success)</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </span>*/}
                                            <div className="dropdown-divider"></div>
                                            <NavLink tag={Link} className="dropdown-item" to="/account/setting/">Account Setup</NavLink>
                                            <a className="dropdown-item" onClick={(e) => { this.openPasswordModal(this) }}>Change Password</a>
                                            {/*<a href="#" className="dropdown-item">Feedback</a>*/}
                                            <a href="http://support.utwiz.com/" target="new" className="dropdown-item">Help Desk</a>
                                            <NavLink tag={Link} className="dropdown-item" to="/trainingservices/">Training & Services</NavLink>
                                            <NavLink tag={Link} className="dropdown-item" to="/ticketing/tickets">Ticket & Feedback</NavLink>
                                            {
                                                this.state.userTypeId == parseInt(UserType.Administrator) ?
                                                    <div>
                                                        <div className="dropdown-divider"></div>
                                                        <span className="dropdown-item" value={this.state.isPasswordPolicyCoonfigurationVisible}><NavLink tag={Link} className="dropdown-item" to="/passwordpolicy/config/">Password Policy Configuration</NavLink></span>
                                                    </div>
                                                    : null
                                            }
                                            <div className="dropdown-divider"></div>

                                            <span className="dropdown-item"><a href="#" className="text-info" onClick={this.handleLogout}>Sign out</a> <a target="new" href="https://www.utwiz.com/privacy/" className="float-right text-sm text-info">Privacy Policy</a></span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <ChangePasswordComponent ref="changePasswordModal" />

                            <Modal ariaHideApp={false}
                                isOpen={this.state.notificationModalIsOpen}
                                style={customStyles}
                                className={"react-modal react-modal-notification"}
                                onRequestClose={this.closeNotificationModal}
                                shouldCloseOnOverlayClick={false}>
                                <div className="modal-header">
                                    <h6 className="modal-title">New Notification(s)</h6>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeNotificationModal}>
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="modal-overflow">
                                        <ul className="list-group border-0">
                                            {this.state.notificationMessage.map((data, i) => (
                                                <li key={i} className="list-group-item"><i className="far fa-bell mr-2"></i>{data}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                {/*<div className="alert alert-success mb-0" role="alert">*/}
                                {/*    <h4 className="alert-heading">New Notification Arrived!*/}
                                {/*        <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.closeNotificationModal}>*/}
                                {/*            <span aria-hidden="true" className="text-dark">&times;</span>*/}
                                {/*        </button>*/}
                                {/*    </h4><hr />*/}
                                {/*    {this.state.notificationMessage.map((data,i) => (*/}
                                {/*        <div key={i} className="alert" role="alert">{data}</div>*/}
                                {/*    ))}*/}
                                {/*</div>*/}
                            </Modal>
                        </div>
                    </nav>
                    <Modal ariaHideApp={false}
                        isOpen={this.state.isOpenAutoLogoutModal}
                        style={{ content: { width: '35%' } }}
                        className={"react-modal"}
                        onAfterOpen={this.handleOnAfterOpenModal}
                        onRequestClose={this.handleOnCloseModal}
                        shouldCloseOnOverlayClick={false}
                        contentLabel="Example Modal">
                        <form method="post">
                            <div className="modal-header">
                                <h6 className="modal-title">{'Session Timeout'}</h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                    <span aria-hidden="true">x</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <center><span className="text-dark">You will be auto-logged out from system in </span><span className="m-0 text-danger font-weight-bold">{parseInt(this.state.remaining / 1000)}</span> <span className="text-dark"> seconds.</span></center>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                    onClick={this.handleOnCloseModal}>
                                    <i className="fas fa-times"></i> Close
                                </button>
                            </div>
                        </form>
                    </Modal>
                </header>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#divLoader' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </>
        );
    }
}