import React, { Component } from 'react';

export class RegistrationCompleteComponent extends Component {
    static displayName = RegistrationCompleteComponent.name;

    render() {
        return (
            <div>
                <h1>Registration Succesfull</h1>
            </div>
        );
    }
}