import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import { LoadPanel } from 'devextreme-react/load-panel';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import DateBox from 'devextreme-react/date-box';
import { SelectBox } from 'devextreme-react/select-box';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions';
import RadioGroup from 'devextreme-react/radio-group';
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'
const reportRadio = [
    { id: 0, name: 'Show Paid' },
    { id: 1, name: 'Show UnPaid' },
    { id: 2, name: 'Show' },
];

export class DriverPaidReportComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            singleDate: new Date(),
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),           
            radDateType: "0",
            reportList: reportRadio,
            reportType: reportRadio[0],
            radVehicle: "0",
            vehicleList: [],
            multiVehicleList: [],
            vehicleId: 0,
            fromVehicleId: 0,
            toVehicleId: 0,
            vehicleName: "",
            fromVehicleName: "",
            toVehicleName: "",
            radCustomer: "0",
            dropdownCustomer: [],
            customerCode: '',
            fromCustomerCode: "",
            toCustomerCode: "",
            customerName: "",
            fromCustomerName: "",
            toCustomerName: "",
            errors: {
                singleDate: new Date(),
                startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
                endDate: new Date(),
                vehicleId: 0,
                fromVehicleId: 0,
                toVehicleId: 0,
                customerCode: '',
                fromCustomerCode: "",
                toCustomerCode: "",
            }
        }

        this.getVehicles = this.getVehicles.bind(this);
        this.getCustomer = this.getCustomer.bind(this);

        this.handleRideDateChanged = this.handleRideDateChanged.bind(this);
        this.handleFromDateChanged = this.handleFromDateChanged.bind(this);
        this.handleToDateChanged = this.handleToDateChanged.bind(this);
        this.handleDateTypeChange = this.handleDateTypeChange.bind(this);
        this.handleReportTypeChange = this.handleReportTypeChange.bind(this);
        this.handleRadVehicleChange = this.handleRadVehicleChange.bind(this);
        this.handleVehicleChange = this.handleVehicleChange.bind(this);
        this.handleFromVehicleChange = this.handleFromVehicleChange.bind(this);
        this.handleToVehicleChange = this.handleToVehicleChange.bind(this);
        this.handleRadCustomerChange = this.handleRadCustomerChange.bind(this);
        this.handleCustomerChange = this.handleCustomerChange.bind(this);
        this.handleFromCustomerChange = this.handleFromCustomerChange.bind(this);
        this.handleToCustomerChange = this.handleToCustomerChange.bind(this);

        this.downloadDriverPaidAsPdf = this.downloadDriverPaidAsPdf.bind(this);
        this.handleDriverPaidReportSubmit = this.handleDriverPaidReportSubmit.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);        
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true,
        });
        await this.getCustomer();
        await this.getVehicles();
        this.setState({
            loadPanelVisible: false,
        });
    }
    async getVehicles() {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/vehicle-api/dropdown-all', requestParams);
            const data = await response.json();
            this.setState({
                vehicleList: data.vehiclesDropdown,
                multiVehicleList: data.vehiclesDropdown,
            });
        } catch (e) {
            console.log(e);
        }
    }
    async getCustomer() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('account-api/customer-dropdown/', requestParams);
            const data = await response.json();
            this.setState({
                dropdownCustomer: data.customers,
            });

        } catch (e) {
            console.log(e);
        }
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });

        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleDateTypeChange(e) {
        this.setState({
            radDateType: e.target.value,
        })

        if (e.target.value == "0") {
            this.setState({
                radDateType: e.target.value,
                singleDate: new Date(),
                startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
                endDate: new Date(),
            });
        }
        else if (e.target.value == "1") {
            this.setState({
                radDateType: e.target.value,
                startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
                endDate: new Date(),
            });
        }
        else if (e.target.value == "2") {
            this.setState({
                radDateType: e.target.value,
                singleDate: new Date(),
            });
        }
    }
    handleRideDateChanged(event) {
        let rideDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ singleDate: rideDateValue })
    }
    handleFromDateChanged(event) {
        let fromDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ startDate: fromDateValue })
    }
    handleToDateChanged(event) {
        let toDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ endDate: toDateValue })
    }
    handleReportTypeChange(e) {
        this.setState({
            reportType: e.value
        });
    }
    handleRadVehicleChange(e) {
        if (e.target.value == "0") {
            this.setState({
                radVehicle: e.target.value,
                vehicleId: 0,
                vehicleName: "",
                filterVehicleList: [],
            });
        }
        else if (e.target.value == "1") {
            this.setState({
                radVehicle: e.target.value,
                filterVehicleList: [],
                fromVehicleId: 0,
                fromVehicleName: "",
                toVehicleId: 0,
                toVehicleName:"",
            });
        }
        else {
            this.setState({
                radVehicle: e.target.value,
                vehicleId: 0,
                vehicleName: "",
            });
        }
        //this.setState({ radVehicle: e.target.value });
    }
    handleVehicleChange(e) {
        if (e.selectedItem !== null) {
            this.setState({
                vehicleId: e.selectedItem.id,
                vehicleName: e.selectedItem.name,
            });
        }
        else {
            this.setState({
                vehicleId: 0,
                vehicleName: "",
            });
        }
    }
    handleFromVehicleChange(e) {
        if (e !== null) {
            this.setState({
                fromVehicleId: e.id,
                fromVehicleName: e.name,
            });
        }
        else {
            this.setState({
                fromVehicleId: 0,
                fromVehicleName: "",
            });
        }
    }
    handleToVehicleChange(e) {
        if (e !== null) {
            this.setState({
                toVehicleId: e.id,
                toVehicleName: e.name,
            });
        }
        else {
            this.setState({
                toVehicleId: 0,
                toVehicleName: "",
            });
        }
    }
    handleRadCustomerChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        
        if (value === "0") {
            this.setState({ radCustomer: value, customerCode: "", customerName: "", fromCustomerCode: "", fromCustomerName: "", toCustomerCode: "", toCustomerName: "", })
        }
        else if (value === "1") {
            this.setState({ radCustomer: value,fromCustomerCode: "", fromCustomerName: "", toCustomerCode: "", toCustomerName: "", })
        }
        else if (value === "2") {
            this.setState({ radCustomer: value, customerCode: "", customerName: "", })
        }

        this.state.errors["customerCode"] = "";

    }
    handleCustomerChange(selectedOption) {
        this.setState({
            customerCode: (selectedOption.selectedItem != null ? selectedOption.selectedItem.code : ''),
            customerName: (selectedOption.selectedItem != null ? selectedOption.selectedItem.name : ''),
        })
        
        this.state.errors["customerCode"] = "";
    }
    handleFromCustomerChange(selectedOption) {
        this.setState({
            fromCustomerCode: (selectedOption != null ? selectedOption.code : ''),
            fromCustomerName: (selectedOption != null ? selectedOption.name : ''),
        })
        this.state.errors["fromCustomerCode"] = "";
    }
    handleToCustomerChange(selectedOption) {
        this.setState({
            toCustomerCode: (selectedOption != null ? selectedOption.code : ''),
            toCustomerName: (selectedOption != null ? selectedOption.name : ''),
        })
        this.state.errors["toCustomerCode"] = "";
    }
    handleDriverPaidReportSubmit(e) {
        e.preventDefault();
        this.downloadDriverPaidAsPdf(e);
    }
    handleClearSearch() {
        this.setState({
            isSubmited: false,
            isEmailSubmitted: false,
            isOpenModal: true,
            loadPanelVisible: false,
            singleDate: new Date(),
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),
            radDateType: "0",
            reportList: reportRadio,
            reportType: reportRadio[0],
            radVehicle: "0",
            vehicleId: 0,
            fromVehicleId: 0,
            toVehicleId: 0,
            vehicleName: "",
            fromVehicleName: "",
            toVehicleName: "",
            radCustomer: "0",
            customerCode: '',
            fromCustomerCode: "",
            toCustomerCode: "",
            customerName: "",
            fromCustomerName: "",
            toCustomerName: "",
            errors: {
                singleDate: new Date(),
                startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
                endDate: new Date(),
                vehicleId: 0,
                fromVehicleId: 0,
                toVehicleId: 0,
                customerCode: '',
                fromCustomerCode: "",
                toCustomerCode: "",
            }
        });
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmited: false,
                isEmailSubmitted: false,
                isOpenModal: false,
                loadPanelVisible: false,
                singleDate: new Date(),
                startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
                endDate: new Date(),
                radDateType: "0",
                reportList: reportRadio,
                reportType: reportRadio[0],
                radVehicle: "0",
                vehicleId: 0,
                fromVehicleId: 0,
                toVehicleId: 0,
                vehicleName: "",
                fromVehicleName: "",
                toVehicleName: "",
                radCustomer: "0",
                customerCode: '',
                fromCustomerCode: "",
                toCustomerCode: "",
                customerName: "",
                fromCustomerName: "",
                toCustomerName: "",
                errors: {
                    singleDate: new Date(),
                    startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
                    endDate: new Date(),
                    vehicleId: 0,
                    fromVehicleId: 0,
                    toVehicleId: 0,
                    customerCode: '',
                    fromCustomerCode: "",
                    toCustomerCode: "",
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }   
    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;

        errors.customerCode = "";
        errors.fromCustomerCode = "";
        errors.toCustomerCode = "";
        errors.vehicleId = 0;
        errors.fromVehicleId = 0;
        errors.toVehicleId = 0;

        this.setState({ message: '' });

        if (this.state.radCustomer == "1") {
            if (this.state.customerCode == "") {
                formIsValid = false;
                errors.customerCode = "Please select customer";
            }
        }

        if (this.state.radCustomer == "2") {
            if (this.state.fromCustomerCode == 0) {
                formIsValid = false;
                errors.fromCustomerCode = "Please select from customer";
            }

            if (this.state.toCustomerCode == 0) {
                formIsValid = false;
                errors.toCustomerCode = "Please select to customer";
            }
        }

        if (this.state.radVehicle == "1") {
            if (this.state.vehicleId == 0) {
                formIsValid = false;
                errors.vehicleId = "Please Select vehicle";
            }
        }

        if (this.state.radVehicle == "2") {
            if (this.state.fromVehicleId == 0) {
                formIsValid = false;
                errors.fromVehicleId = "Please select from vehicle";
            }

            if (this.state.toVehicleId == 0) {
                formIsValid = false;
                errors.toVehicleId = "Please select to vehicle";
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    async downloadDriverPaidAsPdf(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            try {
                this.setState({
                  //  loadPanelVisible: true,
                    isSubmited: true,
                });

                let reportHeader= "";
                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.fileFormat = 'pdf';
                request.MethodName = "GetDriverPaidReport";

                if (parseInt(this.state.radCustomer) == 0) {
                    reportHeader = " For all Account, ";
                    request.FromAccountCode = "";
                }
                else if (parseInt(this.state.radCustomer) == 1) {
                    reportHeader += "  " + " For Account" + "  " + this.state.customerName + ",";
                    request.FromAccountCode = this.state.customerCode;
                }

                if (this.state.radVehicle == "0") {
                    reportHeader += " For All Vehicle Number, ";
                    request.FromVehicleNo = "";
                }
                else if (this.state.radVehicle == "1") {
                    reportHeader += " For Vehicle No " + this.state.vehicleName + ",";
                    request.FromVehicleNo = this.state.vehicleName;
                }

                if (parseInt(this.state.radDateType) == 0) {
                    reportHeader += " For All Date";
                    request.StartDate = null;
                    request.EndDate = null;
                }
                else if (parseInt(this.state.radDateType) == 1) {
                    reportHeader += "For Date " + formatDate(this.state.singleDate, "MM/dd/yyyy");
                    request.StartDate = formatDate(this.state.singleDate, "MM/dd/yyyy");
                    request.EndDate = formatDate(this.state.singleDate, "MM/dd/yyyy");
                }
                else {
                    reportHeader += "From Date " + formatDate(this.state.startDate, "MM/dd/yyyy") + " To Date " + formatDate(this.state.endDate, "MM/dd/yyyy");
                    request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy");
                    request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy");
                }

                request.ReportType = this.state.reportType.id;
                request.Header = reportHeader;

                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({
                    loadPanelVisible: false,
                    isSubmited: false,
                });

                let a = document.createElement('a');
                a.href = dataResponse.reportBase64String;
                a.download = 'DriverPaid.pdf';
                a.click();


                this.setState({
                   // loadPanelVisible: false,
                    isSubmited: false,
                });

            } catch (e) {              
                console.log(e)
            }
        }
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isEmailSubmitted: true });
                let reportHeader = "";
                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.fileFormat = 'pdf';
                request.MethodName = "GetDriverPaidReport";

                if (parseInt(this.state.radCustomer) == 0) {
                    reportHeader = " For all Account, ";
                    request.FromAccountCode = "";
                }
                else if (parseInt(this.state.radCustomer) == 1) {
                    reportHeader += "  " + " For Account" + "  " + this.state.customerName + ",";
                    request.FromAccountCode = this.state.customerCode;
                }

                if (this.state.radVehicle == "0") {
                    reportHeader += " For All Vehicle Number, ";
                    request.FromVehicleNo = "";
                }
                else if (this.state.radVehicle == "1") {
                    reportHeader += " For Vehicle No " + this.state.vehicleName + ",";
                    request.FromVehicleNo = this.state.vehicleName;
                }

                if (parseInt(this.state.radDateType) == 0) {
                    reportHeader += " For All Date";
                    request.StartDate = null;
                    request.EndDate = null;
                }
                else if (parseInt(this.state.radDateType) == 1) {
                    reportHeader += "For Date " + formatDate(this.state.singleDate, "MM/dd/yyyy");
                    request.StartDate = formatDate(this.state.singleDate, "MM/dd/yyyy");
                    request.EndDate = formatDate(this.state.singleDate, "MM/dd/yyyy");
                }
                else {
                    reportHeader += "From Date " + formatDate(this.state.startDate, "MM/dd/yyyy") + " To Date " + formatDate(this.state.endDate, "MM/dd/yyyy");
                    request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy");
                    request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy");
                }

                request.ReportType = this.state.reportType.id;
                request.Header = reportHeader;

                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Driver Paid';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = 'DriverPaid.pdf'
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '45%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleDriverPaidReportSubmit}
                        style={(this.state.isSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Driver Paid'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radCustomer" value="0" checked={this.state.radCustomer === "0"} onChange={this.handleRadCustomerChange} />
                                            <label className="form-check-label">All Customer</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radCustomer" value="1" checked={this.state.radCustomer === "1"} onChange={this.handleRadCustomerChange} />
                                            <label className="form-check-label">Single</label>
                                        </span>
                                        <SelectBox
                                            dataSource={this.state.dropdownCustomer.length > 0 ? this.state.dropdownCustomer : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="customerCode"
                                            defaultValue={0}
                                            value={this.state.customerCode}
                                            displayExpr='fullName'
                                            valueExpr='code'
                                            onSelectionChanged={this.handleCustomerChange}
                                            showClearButton={true}
                                            disabled={this.state.radCustomer === "1" ? false : true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerCode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerCode"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radVehicle" value="0" checked={this.state.radVehicle === "0"} onChange={this.handleRadVehicleChange} />
                                            <label className="form-check-label">All Vehicle</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radVehicle" value="1" checked={this.state.radVehicle === "1"} onChange={this.handleRadVehicleChange} />
                                            <label className="form-check-label">Single</label>
                                        </span>
                                        <SelectBox
                                            dataSource={this.state.vehicleList.length > 0 ? this.state.vehicleList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select vehicle"
                                            name="vehicle"
                                            defaultValue={0}
                                            value={this.state.vehicleId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleVehicleChange}
                                            showClearButton={true}
                                            disabled={this.state.radVehicle === "1" ? false : true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["vehicleId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["vehicleId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radDateType" value="0" checked={this.state.radDateType === "0"} onChange={this.handleDateTypeChange} />
                                            <label className="form-check-label">All Date</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radDateType" value="1" checked={this.state.radDateType === "1"} onChange={this.handleDateTypeChange} />
                                            <label className="form-check-label">Single</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radDateType" value="2" checked={this.state.radDateType === "2"} onChange={this.handleDateTypeChange} />
                                            <label className="form-check-label">Range</label>
                                        </span>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.singleDate}
                                            value={this.state.singleDate}
                                            onValueChanged={this.handleRideDateChanged}
                                            disabled={this.state.radDateType === "1" ? false : true}
                                            width="100%"
                                        />
                                        {this.state.errors["singleDate"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["singleDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label className="form-check-label">From Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.startDate}
                                            value={this.state.startDate}
                                            onValueChanged={this.handleFromDateChanged}
                                            disabled={this.state.radDateType === "2" ? false : true}
                                            width="100%"
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["startDate"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["startDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label className="form-check-label">To Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.endDate}
                                            value={this.state.endDate}
                                            onValueChanged={this.handleToDateChanged}
                                            disabled={this.state.radDateType === "2" ? false : true}
                                            width="100%"
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["endDate"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["endDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label className="form-check-label">Report Type</label>
                                        <RadioGroup id="radio-group-sortby" dataSource={this.state.reportList} displayExpr="name" value={this.state.reportType} onValueChanged={this.handleReportTypeChange} layout="horizontal" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="submit" className="btn btn-success btn-sm" title="Download" onClick={this.handleDriverPaidReportSubmit} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                                </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        )
    }
}