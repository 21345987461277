import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import { SelectBox } from 'devextreme-react/select-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import RadioGroup from 'devextreme-react/radio-group';
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'
const occurenceRadio = [
    { id: 0, name: 'All' },
    { id: 1, name: 'Once' },
    { id: 2, name: 'Recurring' },
];

const recurringTypeRadio = [
    { id: 0, name: 'Loan' },
    { id: 1, name: 'Register' },
];

export class VehicleDeductionReportComponent extends Component {
    constructor() {
        super()
        this.state = {
            isSubmitted: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            radVehicle: "0",
            vehicleList: [],
            vehicleId: 0,
            vehicleName: "",
            occurenceList: occurenceRadio,
            occurenceType: occurenceRadio[0],
            recurringTypeList: recurringTypeRadio,
            recurringType: recurringTypeRadio[0],
            errors: {
                vehicleId: 0,
            }
        }

        this.getVehicles = this.getVehicles.bind(this);

        this.handleRadVehicleChange = this.handleRadVehicleChange.bind(this);
        this.handleVehicleChange = this.handleVehicleChange.bind(this);
        this.handleOccurenceChange = this.handleOccurenceChange.bind(this);
        this.handleRecurringTypeChange = this.handleRecurringTypeChange.bind(this);

        this.handleVehicleDeductionReportSubmit = this.handleVehicleDeductionReportSubmit.bind(this);
        this.downloadVehicleDeductionAsPdf = this.downloadVehicleDeductionAsPdf.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
    }
    async componentDidMount() {
        await this.getVehicles();
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    async getVehicles() {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/vehicle-api/dropdown-all', requestParams);
            const data = await response.json();
            this.setState({
                vehicleList: data.vehiclesDropdown,
                multiVehicleList: data.vehiclesDropdown,
            });
        } catch (e) {
            console.log(e);
        }
    }
    handleOccurenceChange(e) {
        if (e != null) {
            this.setState({
                occurenceType: e.value,
            })
        }
        else {
            this.setState({
                occurenceType: occurenceRadio[0],
            })
        }
    }
    handleRecurringTypeChange(e) {
        if (e != null) {
            this.setState({
                recurringType: e.value,
            })
        }
        else {
            this.setState({
                recurringType: occurenceRadio[0],
            })
        }
    }
    handleRadVehicleChange(e) {
        if (e.target.value == "0") {
            this.setState({
                radVehicle: e.target.value,
                vehicleId: 0,
                vehicleName: "",
            });
        }
        else if (e.target.value == "1") {
            this.setState({
                radVehicle: e.target.value,
            });
        }
        this.setState({ radVehicle: e.target.value });
    }
    handleVehicleChange(e) {
        if (e !== null) {
            this.setState({
                vehicleId: (e.selectedItem != null ? e.selectedItem.id : 0),
                vehicleName: (e.selectedItem != null ? e.selectedItem.name : 0),
            })
        }
        else {
            this.setState({
                vehicleId: 0,
                vehicleName: "",
            });
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isEmailSubmitted: false,
                isOpenModal: false,
                loadPanelVisible: false,
                radVehicle: "0",
                vehicleId: 0,
                vehicleName: "",
                occurenceList: occurenceRadio,
                occurenceType: occurenceRadio[0],
                recurringTypeList: recurringTypeRadio,
                recurringType: recurringTypeRadio[0],
                errors: {
                    vehicleId: 0,
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;

        errors.vehicleId = 0;

        if (this.state.radVehicle == "1") {
            if (this.state.vehicleId == 0) {
                formIsValid = false;
                errors.vehicleId = "Please select vehicle";
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    handleVehicleDeductionReportSubmit(e) {
        e.preventDefault();
        this.downloadVehicleDeductionAsPdf(e);
    }
    async downloadVehicleDeductionAsPdf(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            try {
                this.setState({
                    //  loadPanelVisible: true,
                    isSubmitted: true,
                });

                let ocType, rcType;
                if (this.state.radVehicle == "0") {
                    if (this.state.occurenceType.id == 0) {
                        ocType = -1;
                        rcType = -1;
                    }
                    else if (this.state.occurenceType.id == 1) {
                        ocType = 0;
                        rcType = -1;
                    }
                    else if (this.state.occurenceType.id == 2) {
                        ocType = 1;
                        if (this.state.recurringType.id == 0) {
                            rcType = 1;
                        }
                        else if (this.state.recurringType.id == 1) {
                            rcType = 0;
                        }
                    }
                }
                else if (this.state.radVehicle == "1") {
                    if (this.state.occurenceType.id == 0) {
                        ocType = -1;
                        rcType = -1;
                    }
                    else if (this.state.occurenceType.id == 1) {
                        ocType = 0;
                        rcType = -1;
                    }
                    else if (this.state.occurenceType.id == 2) {
                        ocType = 1;
                        if (this.state.recurringType.id == 0) {
                            rcType = 1;
                        }
                        else if (this.state.recurringType.id == 2) {
                            rcType = 0;
                        }
                    }
                }

                var request = new Object();
                request.fileFormat = 'pdf';
                request.MethodName = "GetVehicleDeduction";
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.VehicleId = this.state.vehicleId;
                request.VehicleNo = this.state.vehicleId == 0 ? "All" : this.state.vehicleName;
                request.OccurenceType = ocType;
                request.RecurringType = rcType;

                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();

                this.setState({
                    //loadPanelVisible: false,
                    isSubmitted: false,
                });

                let a = document.createElement('a');
                a.href = dataResponse.reportBase64String;
                a.download = 'VehicleCommission.pdf';
                a.click();

            } catch (e) {

            }
        }
    }
    handleClearSearch() {
        this.setState({
            isSubmitted: false,
            isEmailSubmitted: false,
            isOpenModal: true,
            loadPanelVisible: false,
            radVehicle: "0",
            vehicleId: 0,
            vehicleName: "",
            occurenceList: occurenceRadio,
            occurenceType: occurenceRadio[0],
            recurringTypeList: recurringTypeRadio,
            recurringType: recurringTypeRadio[0],
            errors: {
                vehicleId: 0,
            }
        });
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isEmailSubmitted: true });
                let ocType, rcType;
                if (this.state.radVehicle == "0") {
                    if (this.state.occurenceType.id == 0) {
                        ocType = -1;
                        rcType = -1;
                    }
                    else if (this.state.occurenceType.id == 1) {
                        ocType = 0;
                        rcType = -1;
                    }
                    else if (this.state.occurenceType.id == 2) {
                        ocType = 1;
                        if (this.state.recurringType.id == 0) {
                            rcType = 1;
                        }
                        else if (this.state.recurringType.id == 1) {
                            rcType = 0;
                        }
                    }
                }
                else if (this.state.radVehicle == "1") {
                    if (this.state.occurenceType.id == 0) {
                        ocType = -1;
                        rcType = -1;
                    }
                    else if (this.state.occurenceType.id == 1) {
                        ocType = 0;
                        rcType = -1;
                    }
                    else if (this.state.occurenceType.id == 2) {
                        ocType = 1;
                        if (this.state.recurringType.id == 0) {
                            rcType = 1;
                        }
                        else if (this.state.recurringType.id == 2) {
                            rcType = 0;
                        }
                    }
                }

                var request = new Object();
                request.fileFormat = 'pdf';
                request.MethodName = "GetVehicleDeduction";
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.VehicleId = this.state.vehicleId;
                request.VehicleNo = this.state.vehicleId == 0 ? "All" : this.state.vehicleName;
                request.OccurenceType = ocType;
                request.RecurringType = rcType;
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Vehicle Deduction Report';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = 'VehicleCommission.pdf'
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '50%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleVehicleDeductionReportSubmit}
                        style={(this.state.isSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Vehicle Deduction Report'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radVehicle" value="0" checked={this.state.radVehicle === "0"} onChange={this.handleRadVehicleChange} />
                                            <label className="form-check-label">All Vehicle</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radVehicle" value="1" checked={this.state.radVehicle === "1"} onChange={this.handleRadVehicleChange} />
                                            <label className="form-check-label">Single Vehicle</label>
                                        </span>
                                        <SelectBox
                                            dataSource={this.state.vehicleList.length > 0 ? this.state.vehicleList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select vehicle"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.vehicleId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleVehicleChange}
                                            showClearButton={true}
                                            disabled={this.state.radVehicle === "1" ? false : true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["vehicleId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["vehicleId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label className="form-check-label">Occurence</label>
                                        <RadioGroup id="radio-group-viewby" dataSource={this.state.occurenceList} displayExpr="name" value={this.state.occurenceType} onValueChanged={this.handleOccurenceChange} layout="horizontal" />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label className="form-check-label">Recurring Type</label>
                                        <RadioGroup id="radio-group-viewby" dataSource={this.state.recurringTypeList} displayExpr="name" value={this.state.recurringType} onValueChanged={this.handleRecurringTypeChange} layout="horizontal" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="submit" className="btn btn-success btn-sm" title="Download" onClick={this.handleVehicleDeductionReportSubmit} >
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                                </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}