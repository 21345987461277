import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { CannedMessageType } from '../../helpers/fixcodes.js';
import { SelectBox } from 'devextreme-react/select-box';

const customStyles = {
    content: {
        width: '30%',
    }
};

export class SendMessageComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            driverMessageModalIsOpen: false,
            singleData: null,
            isDisabled: false,
            vehiclesList: [],
            selVehicleId: null,
            cannedMessageList: [],
            selMessageId: null,
            customMessage: '',
            chkCustomMessage: false,
            driversDropdownList: [],
            filterDriverId: 0,
            refreshRideSource: props.refreshDataSource,
            handleRefresh: props.handleRefresh,
            errors: {
                errVehicle: '',
                errMessage: '',
                customMessage: '',
                errDriver: '',
            }
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCustomMessage = this.handleCustomMessage.bind(this);
        this.getVehicles = this.getVehicles.bind(this);
        this.getCannedMessage = this.getCannedMessage.bind(this);
        this.handleDriverMessageSubmit = this.handleDriverMessageSubmit.bind(this);
    }
    async getVehicles() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('vehicle-api/dropdown/0/0', requestParams);
            const data = await response.json();
            if (data !== null && data.vehiclesDropdown.length > 0) {
                this.setState({
                    vehiclesList: data.vehiclesDropdown
                });
            }
        } catch (e) {
            console.log(e);
        }
    }
    vehicleChange = (selectedOption) => {
        this.setState({
            selVehicleId: selectedOption.selectedItem
        });
        if (selectedOption.selectedItem === null) {
            this.state.errors["errVehicle"] = "Please select vehicle.";
        }
        else {
            this.state.errors["errVehicle"] = "";
        }

    }

    async getCannedMessage() {
        try {
            const requestParams = getRequestParams('GET', '');
            //const response = await fetch('canned-message/0/' + CannedMessageType.DRIVER, requestParams);
            const response = await fetch('canned-message/dispatcher-canned-message-web', requestParams);
            const data = await response.json();            
            if (data !== null && data.driverCannedMessage.length > 0) {
                this.setState({
                    cannedMessageList: data.driverCannedMessage
                });
            }
        } catch (e) {
            console.log(e);
        }

    }
    cannedMessageChange = (selectedOption) => {
        this.setState({
            selMessageId: selectedOption.selectedItem
        });

        if (selectedOption.selectedItem === null) {
            this.setState({
                customMessage: "",
                //chkCustomMessage: true,
            });
            this.state.errors["errMessage"] = "Please select vehicle.";
        }
        else {
            this.setState({
                customMessage: selectedOption.selectedItem.name,
                //chkCustomMessage: true,
            });
            this.state.errors["errMessage"] = "";
        }
    }

    showModal = (data) => {
        this.setState({ driverMessageModalIsOpen: true, singleData: data });
    }

    afterOpenDriverMessageModal = () => {
        //this.getVehicles();          
        this.getCannedMessage();
        this.getDriversDropdownList();
        if (this.state.singleData === null) {
            this.setState({
                selVehicleId: null,
                selMessageId: null,
                customMessage: '',
                filterDriverId: 0
            });
        } else {
            const message = this.state.singleData;
            this.setState({                
                filterDriverId: message.driverId
            });
            //this.setState({
            //    selVehicleId: { id: message.vehicleId, name: message.vehicleNo },
            //    filterDriverId : message.driverId,
            //});
            //let myVar = setTimeout(() => this.getDriversDropdownList(), 500);
        }        
    }
    getDriversDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/driver-api/dropdown-all/', requestParams);
            const data = await response.json();
            this.setState({
                driversDropdownList: data.driversDropdownList
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let errors = this.state.errors;
        switch (name) {
            case 'customMessage':
                if (this.state.chkCustomMessage === true) {
                    errors.customMessage = "";
                    if (event.target.value === '') {
                        this.setState({ customMessage: '' });
                        errors.customMessage = "Please enter custom message.";
                    } else {
                        this.state.errors["customMessage"] = '';
                        this.setState({ customMessage: event.target.value });
                    }
                }
                break;
            default:
                this.setState({
                    [name]: value
                });
                break;
        }
    }
    handleCustomMessage(event) {
        const target = event.target;
        this.setState({
            chkCustomMessage: target.checked,
        });

        if (target.checked === true) {
            this.setState({
                customMessage: "",
                selMessageId: null,
            });
        }
    }
    handleFilterDriverOnChange = (selectedOption) => {
        this.setState({ filterDriverId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0) })
    }
    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.errVehicle = "";
        errors.errDriver = "";
        errors.errMessage = "";
        errors.customMessage = "";

        if (this.state.filterDriverId === 0) {
            formIsValid = false;
            errors.errDriver = "Please select driver.";
        }
        if (this.state.chkCustomMessage === false) {
            if (this.state.selMessageId === null) {
                formIsValid = false;
                errors.errMessage = "Please select canned message.";
            }
        }
        if (this.state.chkCustomMessage === true) {
            if (this.state.customMessage === "") {
                formIsValid = false;
                errors.customMessage = "Please enter custom message.";
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    async handleDriverMessageSubmit(e) {
        let isSendMessageToDriver = true;
        e.preventDefault();
        this.setState({ isSubmited: true });
        if (this.handleValidation()) {
            try {
                var newObject = new Object();
                const requestParams = getRequestParams('POST', newObject);
                const response = await fetch('vehicle-api/status', requestParams);

                const data = await response.json();
                console.log("isSendMessageToDriver: " + isSendMessageToDriver);
                console.log("isSendMessageToDriver IN: " + isSendMessageToDriver);

                var request = new Object();
                request.DriverId = this.state.filterDriverId;
                request.Message = this.state.customMessage;

                const requestMessageParams = getRequestParams('POST', request);
                const responseMessage = await fetch('dispatch-api/send-to-driver', requestMessageParams);
                const dataMessage = await responseMessage.json();
                
                Swal.fire({
                    icon: dataMessage.isSuccess === true ? 'info' : 'error',
                    title: 'Send Message',
                    text: dataMessage.resultMessage,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.closeSendMessageModal();
                    }
                });
            } catch (e) {
                this.setState({ isSubmited: false })
            }
        } else {
            this.setState({ isSubmited: false })
        }
    }

    closeSendMessageModal = () => {
        this.setState({
            driverMessageModalIsOpen: false,
            isSubmited: false,
            singelData: null,
            vehicleId: '',
            messageId: '',
            selVehicleId: null,
            selMessageId: null,
            customMessage: '',
            refreshRideSource: null,
            handleRefresh: null,
            filterDriverId: 0,
            errors: {
                errVehicle: '',
                errDriver: '',
                errMessage: '',
                customMessage: '',
            }
        });
        if (this.state.handleRefresh != undefined) {
            this.state.handleRefresh(true);
        }
        if (this.state.refreshRideSource != undefined) {
            this.state.refreshRideSource();
        }
        this.props.onSendMessageClose();
    }

    render() {
        return (<div>
            <Modal ariaHideApp={false}
                isOpen={this.state.driverMessageModalIsOpen}
                style={customStyles}
                className={"react-modal"}
                onAfterOpen={this.afterOpenDriverMessageModal}
                onRequestClose={this.closeSendMessageModal}
                shouldCloseOnOverlayClick={false}
                contentLabel="Example Modal">
                <form method="post" onSubmit={this.handleDriverMessageSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="modal-header">
                        <h6 className="modal-title">Send Message</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeSendMessageModal}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="form-group">
                                    <label>Select Driver <span className="text-danger">*</span></label>
                                    {/*<SelectBox*/}
                                    {/*    dataSource={this.state.vehiclesList.length > 0 ? this.state.vehiclesList : []}*/}
                                    {/*    className="basic-multi-select"*/}
                                    {/*    classNamePrefix="select"*/}
                                    {/*    placeholder="Select vehicle"*/}
                                    {/*    name="selVehicleId"*/}
                                    {/*    defaultValue={0}*/}
                                    {/*    value={this.state.selVehicleId != null ? this.state.selVehicleId.id : 0}*/}
                                    {/*    displayExpr='name'*/}
                                    {/*    valueExpr='id'*/}
                                    {/*    onSelectionChanged={this.vehicleChange}*/}
                                    {/*    showClearButton={true}*/}
                                    {/*    disabled={false}*/}
                                    {/*    searchEnabled={true}*/}
                                    {/*/>*/}
                                    <SelectBox
                                        dataSource={this.state.driversDropdownList}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select driver"
                                        name="id"
                                        defaultValue={0}
                                        value={this.state.filterDriverId}
                                        displayExpr='name'
                                        valueExpr='id'
                                        onSelectionChanged={this.handleFilterDriverOnChange}
                                        showClearButton={true}
                                        disabled={false}
                                        searchEnabled={true}
                                    />
                                    {this.state.errors["errDriver"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["errDriver"]}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="form-group">
                                    <label>Select Message <span className="text-danger">*</span></label>
                                    <SelectBox
                                        dataSource={this.state.cannedMessageList.length > 0 ? this.state.cannedMessageList : []}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select message"
                                        name="selMessageId"
                                        defaultValue={0}
                                        value={this.state.selMessageId != null ? this.state.selMessageId.id : 0}
                                        displayExpr='name'
                                        valueExpr='id'
                                        onSelectionChanged={this.cannedMessageChange}
                                        showClearButton={true}
                                        disabled={this.state.chkCustomMessage}
                                        searchEnabled={true}
                                    />
                                    {this.state.errors["errMessage"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["errMessage"]}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="form-group">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <input className="form-check-input" type="checkbox" id="chkCustomMessage" name="chkCustomMessage" checked={this.state.isCustomeMessage} onChange={this.handleCustomMessage} />
                                    <label>Custom Message</label>
                                    <textarea className="form-control form-control-sm" row name="customMessage" maxLength="200"
                                        placeholder="Type custom message" title="Custom Message" disabled={!this.state.chkCustomMessage} value={this.state.customMessage} onChange={this.handleInputChange} />
                                    {this.state.errors["customMessage"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customMessage"]}</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="submit" className="btn btn-success btn-sm" onClick={this.handleDriverMessageSubmit}>
                            {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                            {!this.state.isSubmited && <span title="Send"><i className="fas fa-share-square"></i> Send</span>}
                        </button>
                        <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeSendMessageModal} title="Cancel"><i className="fas fa-times"></i> Cancel</button>
                    </div>
                </form>
            </Modal>
        </div>)
    }
}