import DataGrid, {
    Column,
    Scrolling, Selection
} from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { PureComponent } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getRequestParams } from '../../helpers/globalfunctions';

const MySwal = withReactContent(Swal)



const customStyles = {
    content: {
        width: '50%'
    }
};

export class SetUserLocationComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
            isSubmited: false,
            userDetails: null,
            selectedRowKeys: [],
            locationList: [],
            mappedLocation: [],
            searchMode: 'contains',
            defaultLocation: 0,
            sourceurl: props.sourceurl,

        }
        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getLocationList = this.getLocationList.bind(this);
        this.getMappedLocationList = this.getMappedLocationList.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
    }

    async getLocationList() {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/account-api/locations', requestParams);
            const data = await response.json();
            this.setState({
                locationList: data.locations
            });
        } catch (e) {
            console.log(e);
        }
    }

    async getMappedLocationList() {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/user-location-api/user/' + this.state.userDetails.id, requestParams);
            const data = await response.json();
            let selected = [];
            this.setState({ mappedLocation: data.userLocations });
            if (data.userLocations.length > 0) {

                data.userLocations.map((item, i) =>
                    selected.push(item.locationId.toString())
                )
                let defaultLocationId = data.userLocations.find(opt => opt.isDefault == true).locationId;
                this.setState({
                    defaultLocation: defaultLocationId
                });

                this.setState({
                    selectedRowKeys: selected
                });
            }


        } catch (e) {
            console.log(e);
        }
    }

    onSelectionChanged({ selectedRowKeys }) {
        this.setState({
            selectedRowKeys: selectedRowKeys
        });

        let newMappedLocation = [];
        for (let i = 0; i < selectedRowKeys.length; i++) {
            let chklocation = this.state.locationList.find(opt => opt.locationId == selectedRowKeys[i]);
            newMappedLocation.push(chklocation);
        }

        this.setState({
            mappedLocation: newMappedLocation
        });
    }

    closeModal() {
        this.setState({
            isOpenModal: false,
            isSubmited: false,
            userDetails: null,
        });
    }

    async showModal(data) {

        this.setState({ isOpenModal: true, userDetails: data });
        await this.getLocationList();
        await this.getMappedLocationList();
        //this.setState({
        //    selectedRowKeys: ["13","53","2"]
        //});
        //let myVar = setTimeout(() => this.setState({
        //    selectedRowKeys: [{ id: 5, text: 'New Brochures', number: "64654654654654654" },
        //        { id: 6, text: '2019 Brochure Designs', number: "64654654654654654" }]
        //}), 1000)

    }

    async handleSubmit(e) {
        e.preventDefault();
        const selectedRowsData = await this.dataGrid.instance.getDataByKeys(this.state.selectedRowKeys);

        if (selectedRowsData.length <= 0) {
            const isValid = await Swal.fire({
                title: "<small>User Location</small>",
                text: "Please select atleast one location.",
                icon: "info",
                dangerMode: false,
                width: "25%",
                dangerMode: false,
                allowOutsideClick: false
            });
            return;
        }

       
        this.setState({ isSubmited: true });

        var selectedObj = [];
        
        for (let i = 0; i < selectedRowsData.length; i++) {
            var obj = new Object();
            obj.LocationId = selectedRowsData[i].locationId;
            selectedObj.push(obj);
        }

        var addRequest = new Object();
        addRequest.LocationId = selectedObj.length == 1 ? parseInt(selectedObj[0].LocationId) : parseInt(this.state.defaultLocation);
        addRequest.LoginUserId = parseInt(this.state.userDetails.id);
        addRequest.UserLocation = selectedObj;

        const requestParams = getRequestParams('POST', addRequest);
        const response = await fetch('user-location-api/save', requestParams);
        const data = await response.json();
        Swal.fire({
            icon: data.isSuccess ? 'success' : 'error',
            title: data.isSuccess ? 'Success' : 'Oops...',
            text: data.resultMessage,
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (data.isSuccess) {
                this.setState({ isSubmited: true })
                if (result.isConfirmed) {
                    this.closeModal();                    
                    if (this.state.sourceurl === "groundUsers") {
                        window.location.href = "/users/list";
                    }
                    else if (this.state.sourceurl === "driverUsers") {
                        window.location.href = "/drivers/list";
                    }
                    else {
                        window.location.href = "/users/list";
                    }
                }
            } else {
                this.setState({ isSubmited: false })
            }
        })
        //}
    }

    render() {
        const userName = this.state.userDetails === null ? "" : this.state.userDetails.fullName;
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={customStyles}
                    className={"react-modal"}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Set Location for - <strong><i>{userName}</i></strong></h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <label htmlFor="defaultLocation" className="col-sm-3 col-xs-3 col-md-3 col-lg-3 col-form-label">Set Default Location</label>
                                <div className="col-sm-4 col-xs-4 col-md-4 col-lg-4">
                                    <select name="defaultLocation" className="form-control form-control-sm" value={this.state.defaultLocation} onChange={(e) => { this.setState({ defaultLocation: e.target.value }) }} >
                                        {
                                            this.state.mappedLocation &&
                                            this.state.mappedLocation.map((item, i) =>
                                                (<option key={i} value={item.locationId}>{item.locationName}</option>))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="widget-container" style={{ maxHeight: 350 + 'px', overflowY: "auto" }}>
                                <DataGrid
                                    dataSource={this.state.locationList}
                                    id="grid-container"
                                    keyExpr="locationId"
                                    showBorders={true}
                                    selectedRowKeys={this.state.selectedRowKeys}
                                    onSelectionChanged={this.onSelectionChanged}
                                    ref={ref => this.dataGrid = ref}>
                                    <Selection
                                        mode="multiple"
                                        selectAllMode={"allPages"}
                                        showCheckBoxesMode={"always"}
                                    />
                                    <Column dataField="locationName" caption="Location" />
                                    <Column dataField="accountNumber" caption="Account Number" />
                                    <Scrolling mode="infinite" />

                                </DataGrid>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success btn-sm" onClick={this.handleSubmit}>
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeModal} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
            </div>
        );
    }
}