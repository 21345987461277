import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import Swal from 'sweetalert2';

export class ForgotPasswordComponent extends Component {
    static displayName = ForgotPasswordComponent.name;

    constructor(props) {
        super(props);

        this.state = {
            emailAddress: '', message: '',
            errors: {
                emailAddress: '',
            }
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.emailAddress = "";
        this.setState({ message: '' });

        if (this.state.emailAddress == '') {
            formIsValid = false;
            errors.emailAddress = "Please enter username/email.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    async handleSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            var resetPasswordRequest = new Object();
            resetPasswordRequest.UserName = this.state.emailAddress;
            const requestParams = getRequestParams('POST', resetPasswordRequest);

            try {
                const response = await fetch('/authentication/reset-password-request', requestParams);
                const data = await response.json();
                Swal.fire({
                    icon: data.isSuccess ? 'Error' : 'error',
                    title: data.isSuccess ? 'Success' : 'Oops...',
                    text: data.resultMessage,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            this.setState({ emailAddress: "" });
                            window.location.href = "/";
                        }
                        else {
                            this.setState({ isSubmited: false });
                        }
                    } else {
                        this.setState({ isSubmited: false });
                    }
                })
            } catch (e) {
                this.setState({ isSubmited: false });
            }
        }
    }

    render() {
        return (
            <div className="forgot-password-page">
                <div className="forgot-password-box">
                   
                    <div className="card card-forgotpassword">
                        <div className="card-body">
                            <div className="forgot-password-logo">
                                <a href="/"><img src="../../dist/img/logo_utwiz.png" alt="UTWiz" /></a>
                            </div>
                            {/*<h4 className="text-center"><strong>Reset your password</strong></h4>*/}
                            <p className="login-box-msg">We are here to help you. Enter the email/username and we'll send you link.</p>
                            <form method="post" onSubmit={this.handleSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                <div className="form-group mb-3">
                                    <input type="text" className="form-control" name="emailAddress" placeholder="Username / Email" value={this.state.emailAddress} onChange={this.handleInputChange} />
                                    {this.state.errors["emailAddress"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["emailAddress"]}</span>}
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
                                        <button type="submit" className="btn btn-danger btn-block" title="Reset your password"><i className="fas fa-key"></i> Reset your password</button>
                                    </div>
                                </div>
                                <NavLink className="text-center" tag={Link} to="/" title="Go back to login"><i className="mb-0 text-center"></i>Go back to login</NavLink>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        );
    }

}