import React, { Component } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
    Scrolling,
    Editing,
    Selection
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { SelectBox } from 'devextreme-react/select-box';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import NumberFormat from 'react-number-format'; //npm install react-number-format --save
import NumericInput from 'react-numeric-input'; //npm install react-numeric-input --save
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { StatusType, DateFormat } from '../../helpers/fixcodes';
import { LoadPanel } from 'devextreme-react/load-panel';
import Modal from 'react-modal'; //npm install --save react-modal
import { DocumentsComponent } from '../Shared/DocumentsComponent';
const position = { of: '#bookingdiv' };
const customStyles = {
    content: {
        width: '80%',
    }
};

let isApprove = false;
export class CrudFareComponent extends Component {
    static displayName = CrudFareComponent.name;

    constructor(props) {
        super(props);

        this.state = {
            isSubmited: false,
            isDraftSubmited: false,
            isMultipleRideOpen: false,
            loadPanelVisible: false,
            sourceUrl: this.props.location.state == undefined ? "" : this.props.location.state.sourceUrl, // Send from calling paging (Search Reservation)
            sourceState: this.props.location.state == undefined ? "" : this.props.location.state.sourceState,  // Send from calling paging (Search Reservation)
            approvalStatus: this.props.location.state == undefined ? 0 : this.props.location.state.approvalStatus,
            rideId: this.props.location.state == undefined ? 0 : this.props.location.state.rideId,
            paxName: this.props.location.state == undefined ? 0 : this.props.location.state.paxName,
            customerId: this.props.location.state == undefined ? 0 : this.props.location.state.customerId,
            isFranchisee: this.props.location.state == undefined ? 0 : this.props.location.state.isFranchisee,
            hasAttachment: this.props.location.state == undefined ? 0 : this.props.location.state.hasAttachment,
            puLocationName: this.props.location.state == undefined ? 0 : this.props.location.state.puLocationName,
            doLocationName: this.props.location.state == undefined ? 0 : this.props.location.state.doLocationName,
            isRideInvoice: this.props.location.state == undefined ? 0 : this.props.location.state.isRideInvoice,

            customerName: '',
            customerType: '',
            pickupDateTime: '',
            contactNumber: '',
            vehicleType: '',
            vehicleNumber: '',
            paymentType: '',
            vehicleId: 0,

            unapproveRides: [],
            selectedRowKeys: [],

            fareResponse: null,
            dropdownAgentCommission: [],
            agentCommission: 0.000,
            agentCommissionId: 0,
            agentName: '',
            rideAmount: 0.000,
            tollAmount: 0.000,
            tipAmount: 0.000,
            otAmount: 0.000,
            serviceFeeAmount: 0.000,
            fuelSurcharge: 0.000,
            parkingFee: 0.000,
            tourGuideCharge: 0.000,
            extraAmount: 0.000,
            extraAmountFor: "",
            waitingCharge: 0.000,
            waitingTime: 0,
            waitingChargeTotal: 0.000,
            extraStopCharge: 0.000,
            dropdownTourGuide: [],
            payToTourGuid: 0.000,
            payToTourGuidId: 0,
            noOfStops: 0,
            stopTotal: 0.000,
            meetGreet: 0.000,
            mgToPay: "2",
            dropdownGreeter: [],

            payMeetGreetId: 0,
            payMeetGreetAmount: 0.000,
            mgDriverComms: "1",
            discount: 0.000,

            portAuthority: 0.000,
            utitity: 0.000,
            countyTax: 0.000,
            congestionTax: 0.000,
            workCompantation: 0.000,
            salesTax: 0.000,
            grossAmount: 0.000,
            totalFare: 0.000,
            paidAmount: 0.000,
            portAuthorityPercent: 0.00,
            utilityPercent: 0.00,
            countyPercent: 0.00,
            congestionPercent: 0.00,
            wcPercent: 0.00,
            salesPercent: 0.00,

            taxOptionId: "3",
            isAppliedPortAuthority: false,
            isAppliedSalesTax: false,
            isAppliedCountyTax: false,
            isAppliedCongestionTax: false,

            isAppliedWorkComp: false,
            isAppliedUtility: false,
            isCongestionTaxFlatRate: false,

            franchiseeComm: [],
            driverPayment: null,
            vVehicleNo: '',
            vDriverName: '',
            vComm: 0.000,
            vCCComm: 0.000,
            isCCManual: false,
            vSpecialComm: 0.000,
            isSpecialManual: false,
            ccCommPercent: 0.00,
            specialCommPercent: 0.00,
            vBaseComms: 0.000,
            vTollAmount: 0.000,
            vTipAmount: 0.000,
            vMeetGreet: 0.000,
            vFuelSurcharge: 0.000,
            vParkingFee: 0.000,
            vOtAmount: 0.000,
            vStopCharge: 0.000,
            vNoOfStops: 0,
            vWaitingCharge: 0.000,
            vWaitingTime: 0,
            vExtraAmount: 0.000,
            vDiscount: 0.000,
            driverNet: 0.000,
            vTollAmountPercent: 0.00,
            vTipAmountPercent: 0.00,
            vMeetGreetPercent: 0.00,
            vFuelSurchargePercent: 0.00,
            vParkingFeePercent: 0.00,
            vOtAmountPercent: 0.00,
            vExtraAmountPercent: 0.00,
            vStopChargePercent: 0.00,
            vWaitingChargePercent: 0.00,

            errors: {
                payMeetGreetId: '',
                payMeetGreetAmount: '',
                payToTourGuidId: '',
                payToTourGuid: ''
            }
        }

        this.getGreeter = this.getGreeter.bind(this);
        this.getTourGuide = this.getTourGuide.bind(this);
        this.getVehicleRideFareDetails = this.getVehicleRideFareDetails.bind(this);
        this.getVehiclePayment = this.getVehiclePayment.bind(this);
        this.fnGetFranchiseeCommission = this.fnGetFranchiseeCommission.bind(this);
        this.setVehicleCommission = this.setVehicleCommission.bind(this);
        this.showCommissionVehicle = this.showCommissionVehicle.bind(this);
        this.fnFranchiseeComCalculation = this.fnFranchiseeComCalculation.bind(this);
        this.setPaymentsofMovedRides = this.setPaymentsofMovedRides.bind(this);
        this.getAgentCommission = this.getAgentCommission.bind(this);

        this.handleAgentCommissionChange = this.handleAgentCommissionChange.bind(this);
        this.handleUpdateSubmit = this.handleUpdateSubmit.bind(this);
        this.goToBackPage = this.goToBackPage.bind(this);
        this.handleRateChange = this.handleRateChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleMgToPay = this.handleMgToPay.bind(this);
        this.handleDriverCommissionPay = this.handleDriverCommissionPay.bind(this);
        this.handleGreeterChange = this.handleGreeterChange.bind(this);
        this.handleTaxOptionChange = this.handleTaxOptionChange.bind(this);
        this.handleTourGuideChange = this.handleTourGuideChange.bind(this);
        this.handleWithoutRateChange = this.handleWithoutRateChange.bind(this);
        this.handleVehicleCommChange = this.handleVehicleCommChange.bind(this);
        this.handleFranchiseeRateChange = this.handleFranchiseeRateChange.bind(this);
        this.handleCCManualChange = this.handleCCManualChange.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.handleViewAttachment = this.handleViewAttachment.bind(this);
        this.updateSingleMultipleFare = this.updateSingleMultipleFare.bind(this);

        this.handleSelectMultipleRideSubmit = this.handleSelectMultipleRideSubmit.bind(this);
        this.closeMultipleRideModal = this.closeMultipleRideModal.bind(this);
        this.onSelectionGridChanged = this.onSelectionGridChanged.bind(this);
    }

    goToBackPage() {
        this.props.history.push({
            pathname: this.state.sourceUrl,
            state: this.state.sourceState
        })
    }


    async componentDidMount() {
        this.setState({
            loadPanelVisible: true
        });
        await this.getGreeter();
        await this.getTourGuide();
        await this.fnGetFranchiseeCommission();
        await this.getVehicleRideFareDetails();
        await this.getAgentCommission();

        this.setState({
            loadPanelVisible: false
        });
    }

    async getAgentCommission() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('commission-agent-api/commission-agent-dropdown/', requestParams);
            const data = await response.json();

            this.setState({
                dropdownAgentCommission: data.commissionAgentDropdownList
            });
        } catch (e) {
            console.log(e);
        }
    }


    async getGreeter() {

        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('account-api/greeter-dropdown/', requestParams);
            const data = await response.json();

            this.setState({
                dropdownGreeter: data.users
            });
        } catch (e) {
            console.log(e);
        }
    }

    async getTourGuide() {

        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('account-api/tourguide-dropdown/', requestParams);
            const data = await response.json();

            this.setState({
                dropdownTourGuide: data.users
            });
        } catch (e) {
            console.log(e);
        }
    }

    async fnGetFranchiseeCommission() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('manage-rides-api/franchisee-commission-setting/' + this.state.customerId, requestParams);
            const data = await response.json();

            this.setState({
                franchiseeComm: data.franchiseeCommissionSetting
            });
        } catch (e) {
            console.log(e);
        }
    }

    async getVehicleRideFareDetails() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('manage-rides-api/ride-fare-edit/' + this.state.rideId, requestParams);
            const data = await response.json();
            var fareResponseData = data.rideFareEdit.length > 0 ? data.rideFareEdit[0] : null;
            this.setState({
                fareResponse: fareResponseData
            });
            if (fareResponseData == null) {
                const isValid = await Swal.fire({
                    title: "<small>Driver/Ride Fare</small>",
                    text: "No record found.",
                    icon: "info",
                    width: '25%',
                    dangerMode: false,
                    allowOutsideClick: false
                });
                return;
            }

            this.setState({
                customerName: fareResponseData.customerName,
                customerType: fareResponseData.customerType,
                pickupDateTime: fareResponseData.puDateTimeStr,
                contactNumber: fareResponseData.mobileNo,
                vehicleType: fareResponseData.vehicleType,
                vehicleNumber: fareResponseData.vehicleNo,
                paymentType: fareResponseData.paymentType,
                vehicleId: fareResponseData.vehicleId,
                agentCommission: fareResponseData.agentCommission,
                agentName: fareResponseData.agentName,
                agentCommissionId: fareResponseData.agentId,
                rideAmount: fareResponseData.rideAmount,
                tollAmount: fareResponseData.tollAmount,
                tipAmount: fareResponseData.tipAmount,
                otAmount: fareResponseData.ot,
                serviceFeeAmount: fareResponseData.serviceFee,
                fuelSurcharge: fareResponseData.fuelSurCharge,
                parkingFee: fareResponseData.parking,
                tourGuideCharge: fareResponseData.tourGuideCharge,
                extraAmount: fareResponseData.extraAmount,
                extraAmountFor: fareResponseData.extraAmountFor,
                waitingCharge: fareResponseData.waitCharge,
                waitingTime: fareResponseData.waitTime,
                waitingChargeTotal: fareResponseData.waitTotal,
                extraStopCharge: fareResponseData.stopCharge,
                payToTourGuid: fareResponseData.payToTourGuide,
                payToTourGuidId: 0,
                noOfStops: fareResponseData.noOfStop,
                stopTotal: (fareResponseData.stopCharge * fareResponseData.noOfStop),
                meetGreet: fareResponseData.meetGreetCharge,
                mgToPay: fareResponseData.mgPayTo.toString(),
                payMeetGreetId: fareResponseData.meetGreetId,
                payMeetGreetAmount: fareResponseData.meetGreetAmount,
                mgDriverComms: fareResponseData.driverCommType == false ? "1" : "2",
                discount: fareResponseData.discount,

                portAuthority: fareResponseData.portAuthority,
                utitity: fareResponseData.utilityTax,
                countyTax: fareResponseData.countyTax,
                congestionTax: fareResponseData.congestionTax,
                workCompantation: fareResponseData.wcTax,
                salesTax: fareResponseData.salesTax,
                grossAmount: fareResponseData.grossAmount,
                totalFare: fareResponseData.totalFare,
                paidAmount: fareResponseData.totalPaid,
                portAuthorityPercent: fareResponseData.portAuthorityChargePercent,
                utilityPercent: fareResponseData.utilityTaxChargePercent,
                countyPercent: fareResponseData.countyTaxChargePercent,
                congestionPercent: fareResponseData.congestionTaxChargePercent,
                wcPercent: fareResponseData.wcTaxChargePercent,
                salesPercent: fareResponseData.salesTaxChargePercent,

                taxOptionId: fareResponseData.taxInclude.toString(),
                isAppliedPortAuthority: fareResponseData.portAuthorityApplicable,
                isAppliedSalesTax: fareResponseData.salesTaxApplicable,
                isAppliedCountyTax: fareResponseData.countyTaxApplicable,
                isAppliedCongestionTax: fareResponseData.congestionTaxApplicable,

                isAppliedWorkComp: fareResponseData.wcTaxApplicable,
                isAppliedUtility: fareResponseData.utilityTaxApplicable,
                isCongestionTaxFlatRate: fareResponseData.isCongestionTaxFlatRate,

            })

            let myVar = setTimeout(() => this.calculateFare("", 0), 500)

            this.setState({ vVehicleNo: fareResponseData.vehicleNo, vDriverName: fareResponseData.driverName })
            await this.getVehiclePayment();
            await this.setVehicleCommission();
            this.showCommissionVehicle();
            this.fnFranchiseeComCalculation();
            if (fareResponseData.rideMoved > 0) {
                this.setPaymentsofMovedRides();
            }

        } catch (e) {

        }
    }

    async getVehiclePayment() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('manage-rides-api/driver-payment/' + this.state.rideId, requestParams);
            const data = await response.json();
            var driverPaymentData = data.driverPayment.length > 0 ? data.driverPayment[0] : null;
            this.setState({
                driverPayment: driverPaymentData
            });

            if (data.driverPayment.length > 0) {
                this.setState({
                    vBaseComms: driverPaymentData.rideAmount,
                    vTollAmount: driverPaymentData.tollAmount,
                    vTipAmount: driverPaymentData.tipAmount,
                    vMeetGreet: driverPaymentData.meetGreetCharge,
                    vFuelSurcharge: driverPaymentData.fuelSurCharge,
                    vParkingFee: driverPaymentData.parking,
                    vOtAmount: driverPaymentData.ot,
                    vStopCharge: driverPaymentData.stopCharge,
                    vNoOfStops: driverPaymentData.noOfStop,
                    vWaitingCharge: driverPaymentData.waitCharge,
                    vWaitingTime: driverPaymentData.waitTime,
                    vExtraAmount: driverPaymentData.extraAmount,
                    vDiscount: driverPaymentData.discount,
                    vCCComm: driverPaymentData.ccCommission,
                    vSpecialComm: driverPaymentData.spCommission,
                    driverNet: driverPaymentData.totalFare,
                    vComm: driverPaymentData.driverComm,
                    isCCManual: driverPaymentData.isManualCcCommission,
                    isSpecialManual: driverPaymentData.isManualSpCommission,

                });
            }


        } catch (e) {

        }
    }

    async setVehicleCommission() {
        try {
            var newObject = new Object();
            newObject.CustomerId = parseInt(this.state.customerId);
            newObject.VehicleId = parseInt(this.state.vehicleId);
            newObject.PickupDateTime = this.state.pickupDateTime;

            const requestParams = getRequestParams('POST', newObject);
            const response = await fetch('manage-rides-api/vehicle-commission', requestParams);
            const data = await response.json();

            this.setState({
                vComm: data.vehicleCommission[0].commission
            });
        } catch (e) {
            console.log(e);
        }

    }

    fnFranchiseeComCalculation() {
        var _vBaseComms = parseFloat(parseFloat(parseFloat(parseFloat(this.state.rideAmount) - this.state.vDiscount - this.state.vCCComm - this.state.vSpecialComm - parseFloat(this.state.agentCommission)) * parseFloat(parseFloat(this.state.vComm) / 100)).toFixed(2));
        var dcTotal = parseFloat(parseFloat(parseFloat(_vBaseComms) + parseFloat(this.state.vTollAmount) + parseFloat(this.state.vTipAmount) + parseFloat(this.state.vFuelSurcharge) + parseFloat(this.state.vParkingFee) + parseFloat(this.state.vOtAmount) + parseFloat(this.state.vWaitingCharge) + parseFloat(this.state.vStopCharge) + parseFloat(this.state.vExtraAmount) + parseFloat(this.state.vMeetGreet)).toFixed(2));
        this.setState({
            driverNet: dcTotal
        })
    }

    setPaymentsofMovedRides() {
        this.setState({
            vCCComm: 0.00,
            vSpecialComm: 0.00,
            vDiscount: 0.00,
            vBaseComms: 0.00,
            vTollAmount: 0.00,
            vTipAmount: 0.00,
            vFuelSurcharge: 0.00,
            vParkingFee: 0.00,
            vMeetGreet: 0.00,
            vOtAmount: 0.00,
            vExtraAmount: 0.00,
            vNoOfStops: 0.00,
            vStopCharge: 0.00,
            vWaitingTime: 0.00,
            vWaitingCharge: 0.00,
            driverNet: 0.00
        })
    }

    showCommissionVehicle() {
        try {
            var _vCCComm = 0, _ccCommPercent = 0, _vSpecialComm = 0, _specialCommPercent = 0, _vDiscount = 0;
            var _vBaseComms = 0.00, _vTollAmount = 0.00, _vTollAmountPercent = 0.00, _vTipAmount = 0.00, _vTipAmountPercent = 0.00;
            var _vFuelSurcharge = 0.00, _vFuelSurchargePercent = 0.00, _vParkingFee = 0.00, _vParkingFeePercent = 0.00;
            var _vMeetGreet = 0.00, _vMeetGreetPercent = 0.00, _vOtAmount = 0.00, _vOtAmountPercent = 0.00, _vExtraAmount = 0.00, _vExtraAmountPercent = 0.00;
            var _vNoOfStops = 0, _vStopCharge = 0.00, _vStopChargePercent = 0.00;
            var _vWaitingTime = 0, _vWaitingCharge = 0.00, _vWaitingChargePercent = 0.00;
            //cc commission
            if (this.state.isCCManual == true || this.state.fareResponse.isCcCommissionApplicable == true) {
                if (parseInt(this.state.fareResponse.paymentTypeId) == 2) { // credit card
                    _vCCComm = parseFloat(parseFloat(this.state.rideAmount) * parseFloat(this.state.fareResponse.ccTaxCharge / 100)).toFixed(2);
                    _ccCommPercent = this.state.fareResponse.ccTaxCharge;
                }
                else {
                    _vCCComm = 0.00;
                    _ccCommPercent = 0.00;
                }
            }
            else {
                _vCCComm = 0.00;
            }

            //special commission
            if (this.state.isSpecialManual == true || this.state.fareResponse.isSpecialCommissionApplicable == true) {
                _vSpecialComm = parseFloat(parseFloat(this.state.rideAmount) * parseFloat(this.state.fareResponse.specialTaxCharge / 100)).toFixed(2);
                _specialCommPercent = this.state.fareResponse.specialTaxCharge;
            }
            else {
                _vSpecialComm = 0.00;
            }
            _vDiscount = this.state.discount;

            //Base Fare
            var miBase_FareComms = this.state.franchiseeComm.find(f => f.fieldId.toUpperCase() === 'RIDE_AMNT')
            if (miBase_FareComms.isCompany == true) {
                _vBaseComms = 0.00;
            }
            else if (miBase_FareComms.isFranchisee == true) {
                _vBaseComms = parseFloat(parseFloat(parseFloat(this.state.rideAmount) - _vDiscount - _vCCComm - _vSpecialComm - parseFloat(this.state.agentCommission)).toFixed(2));
            }
            else if (miBase_FareComms.isSplit == true) {
                _vBaseComms = parseFloat(parseFloat(parseFloat(parseFloat(this.state.rideAmount) - _vDiscount - _vCCComm - _vSpecialComm - parseFloat(this.state.agentCommission)) * parseFloat(parseFloat(this.state.vComm) / 100)).toFixed(2));
            }
            else {
                _vBaseComms = parseFloat(parseFloat(parseFloat(parseFloat(this.state.rideAmount) - _vDiscount - _vCCComm - _vSpecialComm - parseFloat(this.state.agentCommission)) * parseFloat(parseFloat(this.state.vComm) / 100)).toFixed(2));
            }

            //Toll Fare
            var miToll_Comms = this.state.franchiseeComm.find(f => f.fieldId.toUpperCase() === 'TOLL_AMNT')
            if (miToll_Comms.isCompany == true) {
                _vTollAmount = 0.00;
                _vTollAmountPercent = 0.00;
            }
            else if (miToll_Comms.isFranchisee == true) {
                _vTollAmount = this.state.tollAmount;
                _vTollAmountPercent = 100;
            }
            else if (miToll_Comms.isSplit == true) {
                _vTollAmount = parseFloat(parseFloat(parseFloat(this.state.tollAmount) * (parseFloat(this.state.vComm) / 100)).toFixed(2));
                _vTollAmountPercent = this.state.vComm;
            }

            //Tip Fare
            var miTip_Comms = this.state.franchiseeComm.find(f => f.fieldId.toUpperCase() === 'TIP_AMNT')
            if (miTip_Comms.isCompany == true) {
                _vTipAmount = 0.00;
                _vTipAmountPercent = 0.00;
            }
            else if (miTip_Comms.isFranchisee == true) {
                _vTipAmount = this.state.tipAmount;
                _vTipAmountPercent = 100;
            }
            else if (miTip_Comms.isSplit == true) {
                _vTipAmount = parseFloat(parseFloat(parseFloat(this.state.tipAmount) * (parseFloat(this.state.vComm) / 100)).toFixed(2));
                _vTipAmountPercent = this.state.vComm;
            }

            //Fuel Charge Fare
            var miFuelCharge_Comms = this.state.franchiseeComm.find(f => f.fieldId.toUpperCase() === 'FUEL_SUR_CHARGE')
            if (miFuelCharge_Comms.isCompany == true) {
                _vFuelSurcharge = 0.00;
                _vFuelSurchargePercent = 0.00;
            }
            else if (miFuelCharge_Comms.isFranchisee == true) {
                _vFuelSurcharge = this.state.fuelSurcharge;
                _vFuelSurchargePercent = 100;
            }
            else if (miFuelCharge_Comms.isSplit == true) {
                _vFuelSurcharge = parseFloat(parseFloat(parseFloat(this.state.fuelSurcharge) * (parseFloat(this.state.vComm) / 100)).toFixed(2));
                _vFuelSurchargePercent = this.state.vComm;
            }

            //Parking
            var miParking_Comms = this.state.franchiseeComm.find(f => f.fieldId.toUpperCase() === 'PARKING')
            if (miParking_Comms.isCompany == true) {
                _vParkingFee = 0.00;
                _vParkingFeePercent = 0.00;
            }
            else if (miParking_Comms.isFranchisee == true) {
                _vParkingFee = this.state.parkingFee;
                _vParkingFeePercent = 100;
            }
            else if (miParking_Comms.isSplit == true) {
                _vParkingFee = parseFloat(parseFloat(parseFloat(this.state.parkingFee) * (parseFloat(this.state.vComm) / 100)).toFixed(2));
                _vParkingFeePercent = this.state.vComm;
            }

            //MeetGreet
            var miMeet_Greet_Comms = this.state.franchiseeComm.find(f => f.fieldId.toUpperCase() === 'MEET_GREET')
            if (miMeet_Greet_Comms.isCompany == true || miMeet_Greet_Comms.isFranchisee == true || miMeet_Greet_Comms.isSplit == true) {
                if (this.state.mgToPay === "3") {
                    if (this.state.mgDriverComms === "1") {
                        _vMeetGreet = this.state.meetGreet;
                        _vMeetGreetPercent = 100;
                    } else {
                        _vMeetGreet = parseFloat(parseFloat(parseFloat(this.state.meetGreet) * (parseFloat(this.state.vComm) / 100)).toFixed(2));
                        _vMeetGreetPercent = this.state.vComm;
                    }
                } else {
                    _vMeetGreet = 0.00;
                    _vMeetGreetPercent = 0.00;
                }
            }

            //OT
            var miOT_Comms = this.state.franchiseeComm.find(f => f.fieldId.toUpperCase() === 'OT')
            if (miOT_Comms.isCompany == true) {
                _vOtAmount = 0.00;
                _vOtAmountPercent = 0.00;
            }
            else if (miOT_Comms.isFranchisee == true) {
                _vOtAmount = this.state.otAmount;
                _vOtAmountPercent = 100;
            }
            else if (miOT_Comms.isSplit == true) {
                _vOtAmount = parseFloat(parseFloat(parseFloat(this.state.otAmount) * (parseFloat(this.state.vComm) / 100)).toFixed(2));
                _vOtAmountPercent = this.state.vComm;
            }

            //Extra Amount
            var miExtra_Comms = this.state.franchiseeComm.find(f => f.fieldId.toUpperCase() === 'EXTRA_AMNT')
            if (miExtra_Comms.isCompany == true) {
                _vExtraAmount = 0.00;
                _vExtraAmountPercent = 0.00;
            }
            else if (miExtra_Comms.isFranchisee == true) {
                _vExtraAmount = this.state.extraAmount;
                _vExtraAmountPercent = 100;
            }
            else if (miExtra_Comms.isSplit == true) {
                _vExtraAmount = parseFloat(parseFloat(parseFloat(this.state.extraAmount) * (parseFloat(this.state.vComm) / 100)).toFixed(2));
                _vExtraAmountPercent = this.state.vComm;
            }

            //No of Stop
            _vNoOfStops = this.state.noOfStops;

            //Stop Charge
            var miStop_Comms = this.state.franchiseeComm.find(f => f.fieldId.toUpperCase() === 'STOP')
            if (miStop_Comms.isCompany == true) {
                _vStopCharge = 0.00;
                _vStopChargePercent = 0.00;
            }
            else if (miStop_Comms.isFranchisee == true) {
                if (_vNoOfStops <= 0) {
                    _vStopCharge = 0.00;
                } else {
                    _vStopCharge = this.state.stopTotal;
                }

                _vStopChargePercent = 100;
            }
            else if (miStop_Comms.isSplit == true) {
                if (_vNoOfStops <= 0) {
                    _vStopCharge = 0.00;
                } else {
                    _vStopCharge = parseFloat(parseFloat(parseFloat(this.state.stopTotal) * (parseFloat(this.state.vComm) / 100)).toFixed(2));
                }
                _vStopChargePercent = this.state.vComm;
            }

            //Wait Time
            _vWaitingTime = this.state.waitingTime;

            //Wait Charge
            var miWait_Comms = this.state.franchiseeComm.find(f => f.fieldId.toUpperCase() === 'WT')
            if (miWait_Comms.isCompany == true) {
                _vWaitingCharge = 0.00;
                _vWaitingChargePercent = 0.00;
            }
            else if (miWait_Comms.isFranchisee == true) {
                if (_vWaitingTime <= 0) {
                    _vWaitingCharge = 0.00;
                } else {
                    _vWaitingCharge = this.state.waitingChargeTotal;
                }

                _vWaitingChargePercent = 100;
            }
            else if (miWait_Comms.isSplit == true) {
                if (_vWaitingTime <= 0) {
                    _vWaitingCharge = 0.00;
                } else {
                    _vWaitingCharge = parseFloat(parseFloat(parseFloat(this.state.waitingChargeTotal) * (parseFloat(this.state.vComm) / 100)).toFixed(2));
                }
                _vWaitingChargePercent = this.state.vComm;
            }

            //Total Commission
            var dcTotal = parseFloat(parseFloat(parseFloat(_vBaseComms) + parseFloat(_vTollAmount) + parseFloat(_vTipAmount) + parseFloat(_vFuelSurcharge) + parseFloat(_vParkingFee) + parseFloat(_vOtAmount) + parseFloat(_vWaitingCharge) + parseFloat(_vStopCharge) + parseFloat(_vExtraAmount) + parseFloat(_vMeetGreet)).toFixed(2));

            this.setState({
                vCCComm: _vCCComm,
                ccCommPercent: _ccCommPercent,
                vSpecialComm: _vSpecialComm,
                specialCommPercent: _specialCommPercent,
                vDiscount: _vDiscount,
                vBaseComms: _vBaseComms,
                vTollAmount: _vTollAmount,
                vTollAmountPercent: _vTollAmountPercent,
                vTipAmount: _vTipAmount,
                vTipAmountPercent: _vTipAmountPercent,
                vFuelSurcharge: _vFuelSurcharge,
                vFuelSurchargePercent: _vFuelSurchargePercent,
                vParkingFee: _vParkingFee,
                vParkingFeePercent: _vParkingFeePercent,
                vMeetGreet: _vMeetGreet,
                vMeetGreetPercent: _vMeetGreetPercent,
                vOtAmount: _vOtAmount,
                vOtAmountPercent: _vOtAmountPercent,
                vExtraAmount: _vExtraAmount,
                vExtraAmountPercent: _vExtraAmountPercent,
                vNoOfStops: _vNoOfStops,
                vStopCharge: _vStopCharge,
                vStopChargePercent: _vStopChargePercent,
                vWaitingTime: _vWaitingTime,
                vWaitingCharge: _vWaitingCharge,
                vWaitingChargePercent: _vWaitingChargePercent,
                driverNet: dcTotal
            })

        } catch (e) {
            this.setState({
                vCCComm: 0.00,
                ccCommPercent: 0.00,
                vSpecialComm: 0.00,
                specialCommPercent: 0.00,
                vDiscount: 0.00,
                vBaseComms: 0.00,
                vTollAmount: 0.00,
                vTollAmountPercent: 0.00,
                vTipAmount: 0.00,
                vTipAmountPercent: 0.00,
                vFuelSurcharge: 0.00,
                vFuelSurchargePercent: 0.00,
                vParkingFee: 0.00,
                vParkingFeePercent: 0.00,
                vMeetGreet: 0.00,
                vMeetGreetPercent: 0.00,
                vOtAmount: 0.00,
                vOtAmountPercent: 0.00,
                vExtraAmount: 0.00,
                vExtraAmountPercent: 0.00,
                vNoOfStops: 0.00,
                vStopCharge: 0.00,
                vStopChargePercent: 0.00,
                vWaitingTime: 0.00,
                vWaitingCharge: 0.00,
                vWaitingChargePercent: 0.00,
                driverNet: 0.00
            })
        }
    }

    async calculateFare(ctrl, value) {

        try {

            var newObject = new Object();
            newObject.TripAmt = (ctrl === "rideAmount") ? parseFloat(value) : parseFloat(this.state.rideAmount);
            newObject.TollAmt = (ctrl === "tollAmount") ? parseFloat(value) : parseFloat(this.state.tollAmount);
            newObject.TipAmt = (ctrl === "tipAmount") ? parseFloat(value) : parseFloat(this.state.tipAmount);
            newObject.ExtraAmt = (ctrl === "extraAmount") ? parseFloat(value) : parseFloat(this.state.extraAmount);
            newObject.Misc = (ctrl === "parkingFee") ? parseFloat(value) : parseFloat(this.state.parkingFee);
            newObject.FuelSurCharge = (ctrl === "fuelSurcharge") ? parseFloat(value) : parseFloat(this.state.fuelSurcharge);
            newObject.TourGuideAmt = (ctrl === "tourGuideCharge") ? parseFloat(value) : parseFloat(this.state.tourGuideCharge);
            newObject.MeetGreet = (ctrl === "meetGreet") ? parseFloat(value) : parseFloat(this.state.meetGreet);
            newObject.OT = (ctrl === "otAmount") ? parseFloat(value) : parseFloat(this.state.otAmount);
            newObject.SvcFee = (ctrl === "serviceFeeAmount") ? parseFloat(value) : parseFloat(this.state.serviceFeeAmount);
            newObject.WaitCharge = (ctrl === "waitingCharge") ? parseFloat(value) : parseFloat(this.state.waitingCharge);
            newObject.WaitTime = (ctrl === "waitingTime") ? parseFloat(value) : parseInt(this.state.waitingTime);
            newObject.StopCharge = (ctrl === "extraStopCharge") ? parseFloat(value) : parseFloat(this.state.extraStopCharge);
            newObject.NoOfStop = (ctrl === "noOfStops") ? parseFloat(value) : parseInt(this.state.noOfStops);

            newObject.AgentCommissionPercentage = parseFloat(this.state.fareResponse.agentCommissionPercentage == null ? 0 : this.state.fareResponse.agentCommissionPercentage);

            newObject.IsWcTax = this.state.fareResponse.wcTaxApplicable;
            newObject.IsPATax = this.state.fareResponse.portAuthorityApplicable;
            newObject.IsUtilitTax = this.state.fareResponse.utilityTaxApplicable;
            newObject.IsSalesTax = this.state.fareResponse.salesTaxApplicable;
            newObject.IsCountyTax = this.state.fareResponse.countyTaxApplicable;
            newObject.IsCongestionTax = this.state.fareResponse.congestionTaxApplicable;
            newObject.TaxInclude = parseInt(this.state.fareResponse.taxInclude);

            newObject.WcCharge = parseFloat(this.state.fareResponse.wcTaxChargePercent);
            newObject.PaCharge = parseFloat(this.state.fareResponse.portAuthorityChargePercent);
            newObject.UtilityCharge = parseFloat(this.state.fareResponse.utilityTaxChargePercent);
            newObject.SalesCharge = parseFloat(this.state.fareResponse.salesTaxChargePercent);
            newObject.CountyCharge = parseFloat(this.state.fareResponse.countyTaxChargePercent);
            newObject.CongestionCharge = parseFloat(this.state.fareResponse.congestionTaxChargePercent);
            newObject.IsCongestionTaxFlatRate = this.state.fareResponse.isCongestionTaxFlatRate;
            newObject.Discount = (ctrl === "discount") ? parseFloat(value) : parseFloat(this.state.discount);
            newObject.NoOfPax = parseInt(this.state.fareResponse.noOfPax);
            newObject.IsSharedRateApplicable = this.state.fareResponse.isSharedRateApplicable;

            const requestParams = getRequestParams('POST', newObject);
            const response = await fetch('reservation-api/rate-calculation', requestParams);
            const data = await response.json();

            this.setState({
                waitingChargeTotal: parseFloat(data.waitTotal),
                stopTotal: parseFloat(data.stopTotal),

                portAuthority: parseFloat(data.paTax),
                salesTax: parseFloat(data.salesTax),
                utitity: parseFloat(data.utilityTax),
                workCompantation: parseFloat(data.wcTax),
                countyTax: parseFloat(data.countyTax),
                congestionTax: parseFloat(data.congestionTax),

                grossAmount: parseFloat(data.grossFare),
                totalFare: parseFloat(data.totalFare),
            });

            if (parseFloat(data.agentCommissionAmount) > 0) {
                this.setState({
                    agentCommissionAmount: parseFloat(data.agentCommissionAmount)
                });
            }
            let myVar = setTimeout(() => this.showCommissionVehicle(), 200)
        } catch (e) {
            console.log(e);
        }
    }

    handleAgentCommissionChange(selectedOption) {
        this.setState({ agentCommissionId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
    }

    async handleVehicleCommChange(ctrl, value) {
        if (value == undefined) { value = 0 }

        this.setState({
            [ctrl]: value
        });

        let myVar = setTimeout(() => this.showCommissionVehicle(), 200)
    }
    async handleRateChange(ctrl, value) {
        if (value == undefined) { value = 0 }
        if (ctrl === "tourGuideCharge") {
            if (parseFloat(value) < parseFloat(this.state.payToTourGuid)) {
                const isValid = await Swal.fire({
                    title: "<small>Invalid Amount</small>",
                    text: "Amount to pay Tour Guide should be less than Tour Guide charge.",
                    icon: "info",
                    width: '25%',
                    dangerMode: false,
                    allowOutsideClick: false
                });
                this.setState({
                    [ctrl]: 0.00
                });
                return;
            }

        } else if (ctrl === "meetGreet") {
            if (parseFloat(value) < parseFloat(this.state.payMeetGreetAmount)) {
                const isValid = await Swal.fire({
                    title: "<small>Invalid Amount</small>",
                    text: "Amount to pay greeter should be less than Meet & Greet charge.",
                    icon: "info",
                    width: '25%',
                    dangerMode: false,
                    allowOutsideClick: false
                });
                this.setState({
                    [ctrl]: 0.00
                });
                return;
            }
        }
        this.setState({
            [ctrl]: value
        });

        await this.calculateFare(ctrl, value);
    }

    async handleFranchiseeRateChange(ctrl, value) {
        if (value == undefined) { value = 0 }

        this.setState({
            [ctrl]: value
        });

        let myVar = setTimeout(() => this.fnFranchiseeComCalculation(), 200);
    }


    async handleWithoutRateChange(ctrl, value) {

        if (ctrl === "payToTourGuid") {
            if (parseFloat(value) > parseFloat(this.state.tourGuideCharge)) {
                const isValid = await Swal.fire({
                    title: "<small>Invalid Amount</small>",
                    text: "Amount to pay Tour Guide should be less than Tour Guide charge.",
                    icon: "info",
                    width: '25%',
                    dangerMode: false,
                    allowOutsideClick: false
                });
                this.setState({
                    [ctrl]: 0.00
                });
                return;
            }
        } else if (ctrl === "payMeetGreetAmount") {
            if (parseFloat(value) > parseFloat(this.state.meetGreet)) {
                const isValid = await Swal.fire({
                    title: "<small>Invalid Amount</small>",
                    text: "Amount to pay greeter should be less than Meet & Greet charge.",
                    icon: "info",
                    width: '25%',
                    dangerMode: false,
                    allowOutsideClick: false
                });
                this.setState({
                    [ctrl]: 0.00
                });
                return;
            }
        }

        this.setState({
            [ctrl]: value
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
        this.state.errors[name] = "";
    }

    handleCCManualChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        let myVar = setTimeout(() => this.showCommissionVehicle(), 200)
    }

    handleMgToPay(e) {
        this.setState({
            mgToPay: e.target.value
        });
        this.setState({ payMeetGreetId: 0, payMeetGreetAmount: 0.000 })
        let myVar = setTimeout(() => this.calculateFare("", 0), 200)
    }

    handleDriverCommissionPay(e) {
        this.setState({
            mgDriverComms: e.target.value
        });
        let myVar = setTimeout(() => this.showCommissionVehicle(), 200)
    }

    handleGreeterChange(selectedOption) {
        this.setState({ payMeetGreetId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
    }

    handleTourGuideChange(selectedOption) {
        this.setState({ payToTourGuidId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
    }

    handleTaxOptionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === "exempted" || name === "addToFare" || name === "includedInFare") {
            this.setState({
                taxOptionId: value,
            });
            if (name === "exempted") {
                this.setState({
                    isAppliedPortAuthority: false,
                });
            }

        } else {
            this.setState({
                [name]: value,
            });
        }
    }

    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.payMeetGreetId = "";
        errors.payMeetGreetAmount = "";
        errors.payToTourGuidId = "";
        errors.payToTourGuid = "";

        if (parseInt(this.state.mgToPay) === 1) {
            if (this.state.payMeetGreetId === 0) {
                formIsValid = false;
                errors.payMeetGreetId = "Please select greeter.";
            }

            if (parseFloat(this.state.payMeetGreetAmount) === 0) {
                formIsValid = false;
                errors.payMeetGreetAmount = "Please enter greeter amt.";
            }
        }

        if (parseInt(this.state.payToTourGuidId) > 0) {
            if (parseFloat(this.state.payToTourGuid) == 0) {
                formIsValid = false;
                errors.payToTourGuid = "Please enter tourguide amt.";
            }
        }

        if (parseFloat(this.state.payToTourGuid) > 0) {
            if (parseInt(this.state.payToTourGuidId) == 0) {
                formIsValid = false;
                errors.payToTourGuidId = "Please select tourguide.";
            }
        }

        return formIsValid
    }

    async askUpdateRideFareConfirm() {
        let isResult = false;
        var str = "";
        if (isApprove) {
            str = "You are going update and approve fare details of ride!<br> Do you want to still continue?"
        } else {
            str = "You are going update fare details of ride!<br> Do you want to still continue?"
        }
        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: str,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    async askMultipleRideUpdateConfirm() {
        let isResult = false;

        await Swal.fire({
            title: "<small>Update Multiple Rides</small>",
            html: "Multiple rides found for same trip!<br> Do you want to update multiple rides with same fare?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    async handleUpdateSubmit(e, _isApprove) {
        e.preventDefault();
        isApprove = _isApprove;
        if (!this.handleValidation()) {
            return;
        }

        var day = new Date(this.state.fareResponse.puDateTime).getDate();
        var startDate, endDate;
        if (day <= 15) {
            startDate = (new Date(this.state.fareResponse.puDateTime).getMonth() + 1).toString() + "/01/" + (new Date(this.state.fareResponse.puDateTime).getFullYear()).toString() + " 00:00:00";
            endDate = (new Date(this.state.fareResponse.puDateTime).getMonth() + 1).toString() + "/15/" + (new Date(this.state.fareResponse.puDateTime).getFullYear()).toString() + " 23:59:59";
        } else {
            startDate = (new Date(this.state.fareResponse.puDateTime).getMonth() + 1).toString() + "/16/" + (new Date(this.state.fareResponse.puDateTime).getFullYear()).toString() + " 00:00:00";
            endDate = new Date(new Date(this.state.fareResponse.puDateTime).getFullYear(), new Date(this.state.fareResponse.puDateTime).getMonth() + 1, 0).toLocaleDateString() + " 23:59:59";
        }

        var request = new Object();

        request.CustomerTypeId = parseInt(this.state.fareResponse.customerTypeId);
        request.IsRideApproved = parseInt(0);
        request.PuFromDate = startDate;
        request.PuToDate = endDate;

        request.RideId = 0;
        request.CustomerId = parseInt(this.state.fareResponse.customerId);
        request.VehicleNo = this.state.fareResponse.vehicleNo;
        request.PaxName = "";
        try {
            if (isApprove) {
                this.setState({ isSubmited: true });
            } else {
                this.setState({ isDraftSubmited: true });
            }
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('manage-rides-api/list', requestParams);
            const data = await response.json();
            var manageRids = data.manageRides.filter(f => f.vehicleTypeId == this.state.fareResponse.vehicleTypeId)
                .filter(f => f.rideTypeId == this.state.fareResponse.rideTypeId)
                .filter(f => f.puAreaId == this.state.fareResponse.puAreaId)
                .filter(f => f.doAreaId == this.state.fareResponse.doAreaId)
                .filter(f => f.rideId != this.state.fareResponse.rideId)

            if (isApprove) {
                this.setState({ unapproveRides: manageRids,isSubmited: false });
            } else {
                this.setState({ unapproveRides: manageRids,isDraftSubmited: false });
            }

        } catch (e) {
            if (isApprove) {
                this.setState({ isSubmited: false });
            } else {
                this.setState({ isDraftSubmited: false });
            }
        }

        if (manageRids.length > 0) {
            const isConfirm = await this.askMultipleRideUpdateConfirm();
            if (!isConfirm) {
                this.setState({ unapproveRides: [] });
                var rideList = [];
                rideList.push(parseInt(this.state.rideId));
                await this.updateSingleMultipleFare(rideList);
            } else {
                this.setState({ unapproveRides: manageRids, isMultipleRideOpen: true });
            }
        } else {
            this.setState({ unapproveRides: [] });
            var rideList = [];
            rideList.push(parseInt(this.state.rideId));
            await this.updateSingleMultipleFare(rideList);
        }
    }

    async updateSingleMultipleFare(rideList) {
        const isConfirm = await this.askUpdateRideFareConfirm();
        if (!isConfirm) {
            return;
        }

        try {
            if (isApprove) {
                this.setState({ isSubmited: true });
            } else {
                this.setState({ isDraftSubmited: true });
            }

            var request = new Object();
            request.IsUpdateApprove = isApprove;
            request.TripStatusId = parseInt(this.state.fareResponse.tripStatusId);
            request.RideFare = new Object();
            request.RideFare.RideAmount = parseFloat(this.state.rideAmount);
            request.RideFare.TollAmount = parseFloat(this.state.tollAmount);
            request.RideFare.TipAmount = parseFloat(this.state.tipAmount);
            request.RideFare.PayToTourGuide = parseFloat(this.state.payToTourGuid);
            request.RideFare.TourGuideName = this.state.payToTourGuidId == 0 ? "" : this.state.dropdownTourGuide.find(opt => opt.id == this.state.payToTourGuidId).name;
            request.RideFare.MgPayTo = parseInt(this.state.mgToPay);
            request.RideFare.MeetGreetCharge = parseFloat(this.state.meetGreet);
            request.RideFare.MeetGreetAmount = parseFloat(this.state.payMeetGreetAmount);
            request.RideFare.MgDriverComms = this.state.mgDriverComms === "1" ? false : true;
            request.RideFare.StopCharge = parseFloat(this.state.extraStopCharge);
            request.RideFare.NoOfStop = parseFloat(this.state.noOfStops);
            request.RideFare.WaitTotal = parseFloat(this.state.waitingChargeTotal);
            request.RideFare.WaitTime = parseFloat(this.state.waitingTime);
            request.RideFare.Ot = parseFloat(this.state.otAmount);
            request.RideFare.ServiceFee = parseFloat(this.state.serviceFeeAmount);
            request.RideFare.FuelSurCharge = parseFloat(this.state.fuelSurcharge);
            request.RideFare.Parking = parseFloat(this.state.parking);
            request.RideFare.PortAuthority = parseFloat(this.state.portAuthority);
            request.RideFare.UtilityTax = parseFloat(this.state.utitity);
            request.RideFare.WcTax = parseFloat(this.state.workCompantation);
            request.RideFare.SalesTax = parseFloat(this.state.salesTax);
            request.RideFare.CountyTax = parseFloat(this.state.countyTax);
            request.RideFare.CongestionTax = parseFloat(this.state.congestionTax);
            request.RideFare.ExtraAmount = parseFloat(this.state.extraAmount);
            request.RideFare.ExtraAmountFor = this.state.extraAmountFor;
            request.RideFare.GrossAmount = parseFloat(this.state.grossAmount);
            request.RideFare.Discount = parseFloat(this.state.discount);
            request.RideFare.TotalFare = parseFloat(this.state.totalFare);
            request.RideFare.AgentName = this.state.agentName;
            request.RideFare.AgentId = parseInt(this.state.agentCommissionId);
            request.RideFare.AgentCommission = parseFloat(this.state.agentCommission);
            request.RideFare.MeetGreetName = this.state.payMeetGreetId == 0 ? "" : this.state.dropdownGreeter.find(opt => opt.id == this.state.payMeetGreetId).name;
            request.RideFare.MeetGreetId = parseInt(this.state.payMeetGreetId);

            request.DriverFare = new Object();
            request.DriverFare.RideAmount = parseFloat(this.state.vBaseComms);
            request.DriverFare.TollAmount = parseFloat(this.state.vTollAmount);
            request.DriverFare.TipAmount = parseFloat(this.state.vTipAmount);
            request.DriverFare.MeetGreetCharge = parseFloat(this.state.vMeetGreet);
            request.DriverFare.StopCharge = parseFloat(this.state.vStopCharge);
            request.DriverFare.NoOfStop = parseInt(this.state.vNoOfStops);
            request.DriverFare.WaitTime = parseFloat(this.state.vWaitingTime);
            request.DriverFare.WaitCharge = parseFloat(this.state.vWaitingCharge);
            request.DriverFare.Ot = parseFloat(this.state.vOtAmount);
            request.DriverFare.ServiceFee = parseFloat(this.state.serviceFeeAmount);
            request.DriverFare.FuelSurCharge = parseFloat(this.state.vFuelSurcharge);
            request.DriverFare.Parking = parseFloat(this.state.vParkingFee);
            request.DriverFare.PortAuthority = parseFloat(this.state.portAuthority);
            request.DriverFare.UtilityTax = parseFloat(this.state.utitity);
            request.DriverFare.WcTax = parseFloat(this.state.workCompantation);
            request.DriverFare.ExtraAmount = parseFloat(this.state.vExtraAmount);
            request.DriverFare.ExtraAmountFor = this.state.extraAmountFor;
            request.DriverFare.CcCommission = parseFloat(this.state.vCCComm);
            request.DriverFare.SpCommission = parseFloat(this.state.vSpecialComm);
            request.DriverFare.GrossAmount = parseFloat(this.state.driverNet);
            request.DriverFare.Discount = parseFloat(this.state.vDiscount);
            request.DriverFare.TotalFare = parseFloat(this.state.driverNet);
            request.DriverFare.DriverCommission = parseFloat(this.state.vComm);
            request.DriverFare.IsManualCcCommission = this.state.isCCManual;
            request.DriverFare.IsManualSpCommission = this.state.isSpecialManual;

            request.RideList = rideList;

            const requestParams = getRequestParams('POST', request);
            const response = await fetch('manage-rides-api/update-ride-fare', requestParams);
            const data = await response.json();
            Swal.fire({
                icon: data.isSuccess ? 'success' : 'error',
                title: data.isSuccess ? 'Success' : 'Oops...',
                text: data.resultMessage,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (isApprove) {
                    this.setState({ isSubmited: false });
                } else {
                    this.setState({ isDraftSubmited: false });
                }
                if (result.isConfirmed) {
                    if (data.isSuccess) {
                        this.goToBackPage();
                    }
                }
            })

        } catch (e) {
            if (isApprove) {
                this.setState({ isSubmited: false });
            } else {
                this.setState({ isDraftSubmited: false });
            }
        }
    }

    async handleViewAttachment(e) {
        e.preventDefault();
        var rideArray = [];
        rideArray.push(this.state.rideId);
        this.refs.documentsComponent.showModal('Ride Id', rideArray, rideArray[0]);
    }

    onSelectionGridChanged({ selectedRowKeys }) {
        this.setState({
            selectedRowKeys: selectedRowKeys
        });
    }

    closeMultipleRideModal() {
        this.setState({
            isMultipleRideOpen: false,
            selectedRowKeys: [],
            unapproveRides: [],
        })
    }

    async handleSelectMultipleRideSubmit(e) {
        e.preventDefault();
        const selectedRowsData = await this.dataGrid.instance.getDataByKeys(this.state.selectedRowKeys);

        if (selectedRowsData.length <= 0) {
            const isValid = await Swal.fire({
                title: "<small>Unapproved Rides</small>",
                text: "Please select atleast one ride.",
                icon: "info",
                dangerMode: false,
                width: "25%",
                dangerMode: false,
                allowOutsideClick: false
            });
            return;
        }
        var rideList = [];
        rideList.push(parseInt(this.state.rideId));
        for (var i = 0; i < selectedRowsData.length; i++) {
            rideList.push(parseInt(selectedRowsData[i].rideId));
        }

        this.closeMultipleRideModal();

        await this.updateSingleMultipleFare(rideList);
    }

    render() {
        const isDisableAllRate = (this.state.isFranchisee == true || this.state.isRideInvoice == true || this.state.approvalStatus == true) ? true : false;
        const isFranchiseeRideMoved = this.state.fareResponse == null ? false : (this.state.fareResponse.rideMoved > 0 ? true : false)
        const renderGridCurrencyCell = (data) => {
            return <div>${parseFloat(data.value).toFixed(2)}</div>;
        }
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <h1 className="m-0 text-dark">Update Ride Fare/Driver Commission - {this.state.rideId} </h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={(this.state.isSubmited || this.state.isDraftSubmited) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                <ol className="breadcrumb float-sm-right">
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-success btn-sm" disabled={isDisableAllRate} onClick={(e) => { this.handleUpdateSubmit(e, true) }} >
                                            {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSubmited && <span title="Update & Approve"><i className="fas fa-check"></i> Update & Approve</span>}
                                        </button>&nbsp; &nbsp;
                                         <button type="submit" className="btn btn-success btn-sm" disabled={isDisableAllRate} onClick={(e) => { this.handleUpdateSubmit(e, false) }} >
                                            {this.state.isDraftSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isDraftSubmited && <span title="Update"><i className="fas fa-save"></i> Update</span>}
                                        </button>&nbsp; &nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" disabled={isDisableAllRate} title="View Attachment" onClick={this.handleViewAttachment} ><i className="fas fa-upload"></i> View Attachment</button>&nbsp; &nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" title="Back" onClick={this.goToBackPage}><i className="fas fa-backward"></i> Back</button>
                                    </div>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="rez-header">
                    <div className="container">
                        <div className="row">

                        </div>
                    </div>
                </div>

                <div className="content" style={(this.state.isSubmited || this.state.isDraftSubmited) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>Pax Name</label>
                                        <div><strong>{this.state.paxName}</strong></div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>Customer Name</label>
                                        <div><strong>{this.state.customerName}</strong></div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>Customer Type</label>
                                        <div><strong>{this.state.customerType}</strong></div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>Pickup DateTime</label>
                                        <div><strong>{this.state.pickupDateTime}</strong></div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>Contact Number</label>
                                        <div><strong>{this.state.contactNumber}</strong></div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>Picku Location</label>
                                        <div><strong>{this.state.puLocationName}</strong></div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>Dropoff Location</label>
                                        <div><strong>{this.state.doLocationName}</strong></div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>Vehicle Type</label>
                                        <div><strong>{this.state.vehicleType}</strong></div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>Vehicle Number</label>
                                        <div><strong>{this.state.vehicleNumber}</strong></div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>Payment Type</label>
                                        <div><strong>{this.state.paymentType}</strong></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <ul className="nav nav-tabs" id="custom-content-below-tab" style={{ marginTop: 0 + 'px' }} role="tablist">
                                <li className="nav-item" style={{ display: this.state.isFranchisee == true ? 'none' : 'block' }}>
                                    <a className="nav-link active" id="ride-fare-tab" data-toggle="pill" href="#ride-fare" role="tab" aria-controls="ride-fare" aria-selected="true">Ride Fare Details</a>
                                </li>
                                <li className="nav-item" style={{ display: this.state.isRideInvoice == true ? 'none' : 'block' }}>
                                    <a className="nav-link" id="franchisee-commission-tab" data-toggle="pill" href="#franchisee-commission" role="tab" aria-controls="franchisee-commission" aria-selected="false">Franchisee Commission Details</a>
                                </li>
                            </ul>
                            <div className="tab-content" id="custom-content-below-tabContent">
                                <div className="tab-pane fade show active" id="ride-fare" role="tabpanel" aria-labelledby="ride-fare-tab">
                                    <div className="card main-rez-card row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ display: this.state.isFranchisee == true ? 'none' : 'block' }}>
                                            <div className="card sub-rez-card">
                                                <div className="card-header">
                                                    <h5 className="card-title m-0">Commission Agent</h5>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <div className="form-group">
                                                                <label>Agent Commission</label>
                                                                <SelectBox
                                                                    dataSource={this.state.dropdownAgentCommission.length > 0 ? this.state.dropdownAgentCommission : []}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    placeholder="Select agent comm."
                                                                    name="agentCommissionId"
                                                                    defaultValue={0}
                                                                    value={this.state.agentCommissionId}
                                                                    displayExpr='name'
                                                                    valueExpr='id'
                                                                    onSelectionChanged={this.handleAgentCommissionChange}
                                                                    showClearButton={true}
                                                                    disabled={isDisableAllRate}
                                                                    searchEnabled={true}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                                            <div className="form-group">
                                                                <label>Commission Amount</label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" name="agentCommission" disabled={isDisableAllRate} value={this.state.agentCommission} fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Commission Amount" onValueChange={(values) => { this.setState({ agentCommission: values.floatValue }) }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-header">
                                                    <h5 className="card-title m-0">Fare Details</h5>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Ride Amount</label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" disabled={isDisableAllRate} name="rideAmount" fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Ride Amount" value={this.state.rideAmount} onValueChange={(values) => { this.handleRateChange("rideAmount", values.floatValue) }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Toll Amount</label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" disabled={isDisableAllRate} name="tollAmount" fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Toll Amount" value={this.state.tollAmount} onValueChange={(values) => { this.handleRateChange("tollAmount", values.floatValue) }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Tip Amount</label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" disabled={isDisableAllRate} name="tipAmount" fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Tip Amount" value={this.state.tipAmount} onValueChange={(values) => { this.handleRateChange("tipAmount", values.floatValue) }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Overtime Charge</label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" disabled={isDisableAllRate} name="otAmount" fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Overtime Amount" value={this.state.otAmount} onValueChange={(values) => { this.handleRateChange("otAmount", values.floatValue) }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Service Fee <span className="text-primary" style={{ fontSize: 9 + 'px' }}>[100% To Co.]</span></label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" disabled={isDisableAllRate} name="serviceFeeAmount" fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Service Fee Amount" value={this.state.serviceFeeAmount} onValueChange={(values) => { this.handleRateChange("serviceFeeAmount", values.floatValue) }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Fuel Surcharge</label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" disabled={isDisableAllRate} name="fuelSurcharge" fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Fuel Surcharge" value={this.state.fuelSurcharge} onValueChange={(values) => { this.handleRateChange("fuelSurcharge", values.floatValue) }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Parking Fee</label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" disabled={isDisableAllRate} name="parkingFee" fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Parking Fee" value={this.state.parkingFee} onValueChange={(values) => { this.handleRateChange("parkingFee", values.floatValue) }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Tour Guide</label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" disabled={isDisableAllRate} name="tourGuideCharge" fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Tour Guide Charge" value={this.state.tourGuideCharge} onValueChange={(values) => { this.handleRateChange("tourGuideCharge", values.floatValue) }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Meet & Greet</label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" disabled={isDisableAllRate} name="meetGreet" fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Meet & Greet" value={this.state.meetGreet} onValueChange={(values) => { this.handleRateChange("meetGreet", values.floatValue) }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Extra Amount</label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" disabled={isDisableAllRate} name="extraAmount" fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Extra Amount" value={this.state.extraAmount} onValueChange={(values) => { this.handleRateChange("extraAmount", values.floatValue) }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                                            <div className="form-group">
                                                                <label>Extra Amount For</label>
                                                                <input type="text" className="form-control form-control-sm" disabled={isDisableAllRate} maxLength={50} value={this.state.extraAmountFor} onChange={this.handleInputChange} name="extraAmountFor" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                                            <div className="form-group">
                                                                <label>Pay to Tour Guid.</label>
                                                                <SelectBox
                                                                    dataSource={this.state.dropdownTourGuide.length > 0 ? this.state.dropdownTourGuide : []}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    placeholder="Select Tour Guide"
                                                                    name="payToTourGuidId"
                                                                    defaultValue={0}
                                                                    value={this.state.payToTourGuidId}
                                                                    displayExpr='name'
                                                                    valueExpr='id'
                                                                    onSelectionChanged={this.handleTourGuideChange}
                                                                    showClearButton={true}
                                                                    disabled={isDisableAllRate || (parseFloat(this.state.tourGuideCharge) == 0 ? true : false)}
                                                                    searchEnabled={true}
                                                                />
                                                                {this.state.errors["payToTourGuidId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["payToTourGuidId"]}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Amount</label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" disabled={isDisableAllRate || (parseFloat(this.state.tourGuideCharge) == 0 ? true : false)} name="payToTourGuid" fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Pay To Tour Guid Amount" value={this.state.payToTourGuid} onValueChange={(values) => { this.handleWithoutRateChange("payToTourGuid", values.floatValue) }} />
                                                                {this.state.errors["payToTourGuid"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["payToTourGuid"]}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Wait Chg. <span className="text-primary" style={{ fontSize: 9 + 'px' }}>[Charge per 60 min.]</span></label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" name="waitingCharge" fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Waiting Charge" value={this.state.waitingCharge} onValueChange={(values) => { this.handleRateChange("waitingCharge", values.floatValue) }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Wait Time (Min.)</label>
                                                                <NumericInput className="form-control form-control-sm" disabled={isDisableAllRate} name="waitingTime" title="Waiting Time" value={this.state.waitingTime} min={0} max={1000} onChange={(value) => { this.handleRateChange("waitingTime", value) }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Wait Total <span className="text-primary" style={{ fontSize: 9 + 'px' }}>[time(15=1)*charge]</span></label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" readOnly={true} name="waitingChargeTotal" fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Waiting Charge Total" value={this.state.waitingChargeTotal} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Stop Charge</label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" disabled={isDisableAllRate} name="extraStopCharge" fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Extra Stop Charge" value={this.state.extraStopCharge} onValueChange={(values) => { this.handleRateChange("extraStopCharge", values.floatValue) }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>No Of Stops</label>
                                                                <NumericInput className="form-control form-control-sm" disabled={isDisableAllRate} name="noOfStops" title="No Of Stops" value={this.state.noOfStops} min={0} max={100} onChange={(value) => { this.handleRateChange("noOfStops", value) }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Stop Total <span className="text-primary" style={{ fontSize: 9 + 'px' }}>[stop chg*stop#]</span></label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" readOnly={true} name="stopTotal" fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Stop Total" value={this.state.stopTotal} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label className="btn-block" style={{ marginBottom: 6 + 'px' }}>&nbsp;</label>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" disabled={isDisableAllRate} type="radio" name="rdPayToGreeter" value="1" checked={this.state.mgToPay === "1"} onChange={this.handleMgToPay} />
                                                                    <label className="form-check-label">Pay to Greeter</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label className="btn-block" style={{ marginBottom: 6 + 'px' }}>&nbsp;</label>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" disabled={isDisableAllRate} type="radio" name="rdPayToCompany" value="2" checked={this.state.mgToPay === "2"} onChange={this.handleMgToPay} />
                                                                    <label className="form-check-label">100% Comp.</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label className="btn-block" style={{ marginBottom: 6 + 'px' }}>&nbsp;</label>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" disabled={isDisableAllRate} type="radio" name="rdPayToDriver" value="3" checked={this.state.mgToPay === "3"} onChange={this.handleMgToPay} />
                                                                    <label className="form-check-label">Pay to Driver</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                                            <div className="form-group">
                                                                <label>Greeter Name</label>
                                                                <SelectBox
                                                                    dataSource={this.state.dropdownGreeter.length > 0 ? this.state.dropdownGreeter : []}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    placeholder="Select Greeter"
                                                                    name="payMeetGreetId"
                                                                    defaultValue={0}
                                                                    value={this.state.payMeetGreetId}
                                                                    displayExpr='name'
                                                                    valueExpr='id'
                                                                    onSelectionChanged={this.handleGreeterChange}
                                                                    showClearButton={true}
                                                                    disabled={this.state.mgToPay === "2" || this.state.mgToPay === "3"}
                                                                    searchEnabled={true}
                                                                />
                                                                {this.state.errors["payMeetGreetId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["payMeetGreetId"]}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Amount</label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" disabled={this.state.mgToPay === "2" || this.state.mgToPay === "3" || isDisableAllRate} name="payMeetGreetAmount" fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Greeter Amount" value={this.state.payMeetGreetAmount} onValueChange={(values) => { this.handleWithoutRateChange("payMeetGreetAmount", values.floatValue) }} />
                                                                {this.state.errors["payMeetGreetAmount"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["payMeetGreetAmount"]}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label className="btn-block" style={{ marginBottom: 6 + 'px' }}>&nbsp;</label>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="radio" disabled={this.state.mgToPay === "1" || this.state.mgToPay === "2" || isDisableAllRate} name="rdDriver100" value="1" checked={this.state.mgDriverComms === "1"} onChange={this.handleDriverCommissionPay} />
                                                                    <label className="form-check-label">100%</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label className="btn-block" style={{ marginBottom: 6 + 'px' }}>&nbsp;</label>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="radio" disabled={this.state.mgToPay === "1" || this.state.mgToPay === "2" || isDisableAllRate} name="rdDriverSplit" value="2" checked={this.state.mgDriverComms === "2"} onChange={this.handleDriverCommissionPay} />
                                                                    <label className="form-check-label">Split</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Port Authority <span className="text-primary" name="portAuthorityPercent" style={{ fontSize: 9 + 'px' }}>[{parseFloat(this.state.portAuthorityPercent).toFixed(2)}%]</span></label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" name="portAuthority" readOnly={true} fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Port Authority" value={this.state.portAuthority} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Utility <span className="text-primary" name="utilityPercent" style={{ fontSize: 9 + 'px' }}>[{parseFloat(this.state.utilityPercent).toFixed(2)}%]</span></label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" name="utitity" readOnly={true} fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Utitity" value={this.state.utitity} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>County Tax <span className="text-primary" name="countyPercent" style={{ fontSize: 9 + 'px' }}>[{parseFloat(this.state.countyPercent).toFixed(2)}%]</span></label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" name="countyTax" readOnly={true} fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="County Tax" value={this.state.countyTax} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Congestion Tax <span className="text-primary" name="congestionPercent" style={{ fontSize: 9 + 'px' }}>[{parseFloat(this.state.congestionPercent).toFixed(2)}{this.state.isCongestionTaxFlatRate == true ? '' : '%'}]</span></label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" name="congestionTax" readOnly={true} fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Congestion Tax" value={this.state.congestionTax} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>WC <span className="text-primary" name="wcPercent" style={{ fontSize: 9 + 'px' }}>[{parseFloat(this.state.wcPercent).toFixed(2)}%]</span></label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" name="workCompantation" readOnly={true} fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Work Compantation" value={this.state.workCompantation} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Sales Tax <span className="text-primary" name="salesPercent" style={{ fontSize: 9 + 'px' }}>[{parseFloat(this.state.salesPercent).toFixed(2)}%]</span></label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" name="salesTax" readOnly={true} fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Sales Tax" value={this.state.salesTax} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Gross Amount</label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" name="grossAmount" readOnly={true} fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Gross Amount" value={this.state.grossAmount} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Discount</label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" disabled={isDisableAllRate} name="discount" fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Discount Amount" value={this.state.discount} onValueChange={(values) => { this.handleRateChange("discount", values.floatValue) }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>&nbsp;</label>
                                                                <h5>Total: ${parseFloat(this.state.totalFare).toFixed(2)}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-header">
                                                    <h5 className="card-title m-0">Tax Option</h5>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <div className="form-group">
                                                                <span className="form-check">
                                                                    <input className="form-check-input" disabled={true} type="radio" name="includedInFare" value="2" checked={this.state.taxOptionId === "2"} onChange={this.handleTaxOptionChange} />
                                                                    <label className="form-check-label">Included In Fare</label>
                                                                </span>
                                                                <span className="form-check">
                                                                    <input className="form-check-input" disabled={true} type="radio" name="addToFare" value="3" checked={this.state.taxOptionId === "3"} onChange={this.handleTaxOptionChange} />
                                                                    <label className="form-check-label">Add To Fare</label>
                                                                </span>
                                                                <span className="form-check">
                                                                    <input className="form-check-input" disabled={true} type="radio" name="exempted" value="1" checked={this.state.taxOptionId === "1"} onChange={this.handleTaxOptionChange} />
                                                                    <label className="form-check-label">Exempted</label>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <div className="form-group">
                                                                <span className="form-check" style={{ display: this.state.taxOptionId !== "1" ? 'inline-block' : 'none' }}>
                                                                    <input className="form-check-input" disabled={true} type="checkbox" checked={this.state.isAppliedPortAuthority} onChange={this.handleTaxOptionChange} name="isAppliedPortAuthority" />
                                                                    <label className="form-check-label">Port Authority</label>
                                                                </span>
                                                                <span className="form-check" >
                                                                    <input className="form-check-input" disabled={true} type="checkbox" checked={this.state.isAppliedSalesTax} onChange={this.handleTaxOptionChange} name="isAppliedSalesTax" />
                                                                    <label className="form-check-label">Sales Tax</label>
                                                                </span>
                                                                <span className="form-check">
                                                                    <input className="form-check-input" disabled={true} type="checkbox" id="isAccessible" checked={this.state.isAppliedCountyTax} onChange={this.handleTaxOptionChange} name="isAppliedCountyTax" />
                                                                    <label className="form-check-label">County Tax</label>
                                                                </span>
                                                                <span className="form-check">
                                                                    <input className="form-check-input" disabled={true} type="checkbox" id="isPriorityTrip" checked={this.state.isAppliedCongestionTax} onChange={this.handleTaxOptionChange} name="isAppliedCongestionTax" />
                                                                    <label className="form-check-label">Congestion Tax</label>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="franchisee-commission" role="tabpanel" aria-labelledby="franchisee-commission-tab">
                                    <div className="card main-rez-card row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ display: this.state.isRideInvoice == true ? 'none' : 'block' }}>
                                            <div className="card sub-rez-card">
                                                <div className="card-header">
                                                    <h5 className="card-title m-0">Franchisee Details</h5>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-lg-1 col-md-1 col-sm-6 col-xs-12">
                                                            <div className="form-group">
                                                                <label>Vehicle No</label>
                                                                <div><span>{this.state.vVehicleNo}</span></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                                            <div className="form-group">
                                                                <label>Driver Name</label>
                                                                <div><span>{this.state.vDriverName}</span></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-1 col-sm-6 col-xs-12">
                                                            <div className="form-group">
                                                                <label>Commission %</label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" readOnly={true} name="vComm" fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Vehicle Commission" value={this.state.vComm} onValueChange={(values) => { this.handleVehicleCommChange("vComm", values.floatValue) }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-1 col-sm-6 col-xs-12">
                                                            <div className="form-group">
                                                                <label>Discount</label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" disabled={isDisableAllRate} name="vDiscount" fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Vehicle Discount" value={this.state.vDiscount} onValueChange={(values) => { this.handleRateChange("vDiscount", values.floatValue) }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-1 col-sm-6 col-xs-12">
                                                            <div className="form-group">
                                                                <label>CC Comms <span className="text-primary" name="ccCommPercent" style={{ fontSize: 9 + 'px' }}>[{parseFloat(this.state.ccCommPercent).toFixed(2)}%]</span></label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" disabled={isDisableAllRate} name="vCCComm" fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Vehicle CC Commission" value={this.state.vCCComm} onValueChange={(values) => { this.handleRateChange("vCCComm", values.floatValue) }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-1 col-sm-6 col-xs-12">
                                                            <div className="form-group" style={{ paddingTop: 26 + 'px' }}>
                                                                <span className="form-check">
                                                                    <input className="form-check-input" disabled={isDisableAllRate || (parseFloat(this.state.vCCComm) > 0 && this.state.isCCManual == false) || (parseFloat(this.state.vBaseComms) != 0 ? true : false)} type="checkbox" checked={this.state.isCCManual} onChange={this.handleCCManualChange} name="isCCManual" />
                                                                    <label className="form-check-label">Manual</label>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-1 col-sm-6 col-xs-12">
                                                            <div className="form-group">
                                                                <label>Special Comms <span className="text-primary" name="specialCommPercent" style={{ fontSize: 9 + 'px' }}>[{parseFloat(this.state.specialCommPercent).toFixed(2)}%]</span></label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" disabled={isDisableAllRate} name="vSpecialComm" fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Vehicle Special Commission" value={this.state.vSpecialComm} onValueChange={(values) => { this.handleRateChange("vSpecialComm", values.floatValue) }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-1 col-sm-6 col-xs-12">
                                                            <div className="form-group" style={{ paddingTop: 26 + 'px' }}>
                                                                <span className="form-check">
                                                                    <input className="form-check-input" disabled={isDisableAllRate || (parseFloat(this.state.vSpecialComm) > 0 && this.state.isSpecialManual == false) || (parseFloat(this.state.vBaseComms) != 0 ? true : false)} type="checkbox" checked={this.state.isSpecialManual} onChange={this.handleCCManualChange} name="isSpecialManual" />
                                                                    <label className="form-check-label">Manual</label>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-header">
                                                    <h5 className="card-title m-0">Franchisee Commission -  <span className="text-primary" name="ccCommPercent" style={{ fontSize: 9 + 'px' }}>[ (Ride Amnt-Discount-Comms Amnt-CC Comms-Special Comms)*Commission% ]</span> </h5>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Base Comms</label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" readOnly={isFranchiseeRideMoved} name="vBaseComms" fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Base Comms" value={this.state.vBaseComms} onValueChange={(values) => { this.handleFranchiseeRateChange("vBaseComms", values.floatValue) }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Toll Amount <span className="text-primary" name="vTollAmountPercent" style={{ fontSize: 9 + 'px' }}>[{parseFloat(this.state.vTollAmountPercent).toFixed(2)}%]</span></label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" readOnly={isFranchiseeRideMoved} disabled={isDisableAllRate} name="vTollAmount" fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Toll Amount" value={this.state.vTollAmount} onValueChange={(values) => { this.handleFranchiseeRateChange("vTollAmount", values.floatValue) }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Tip Amount <span className="text-primary" name="vTipAmountPercent" style={{ fontSize: 9 + 'px' }}>[{parseFloat(this.state.vTipAmountPercent).toFixed(2)}%]</span></label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" readOnly={isFranchiseeRideMoved} disabled={isDisableAllRate} name="vTipAmount" fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Tip Amount" value={this.state.vTipAmount} onValueChange={(values) => { this.handleFranchiseeRateChange("vTipAmount", values.floatValue) }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Meet & Greet <span className="text-primary" name="vMeetGreetPercent" style={{ fontSize: 9 + 'px' }}>[{parseFloat(this.state.vMeetGreetPercent).toFixed(2)}%]</span></label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" readOnly={isFranchiseeRideMoved} disabled={isDisableAllRate} name="vMeetGreet" fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Meet Greet" value={this.state.vMeetGreet} onValueChange={(values) => { this.handleFranchiseeRateChange("vMeetGreet", values.floatValue) }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Fuel Surcharge <span className="text-primary" name="vFuelSurchargePercent" style={{ fontSize: 9 + 'px' }}>[{parseFloat(this.state.vFuelSurchargePercent).toFixed(2)}%]</span></label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" readOnly={isFranchiseeRideMoved} disabled={isDisableAllRate} name="vFuelSurcharge" fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Fuel Surcharge" value={this.state.vFuelSurcharge} onValueChange={(values) => { this.handleFranchiseeRateChange("vFuelSurcharge", values.floatValue) }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Parking Fee <span className="text-primary" name="vParkingFeePercent" style={{ fontSize: 9 + 'px' }}>[{parseFloat(this.state.vParkingFeePercent).toFixed(2)}%]</span></label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" readOnly={isFranchiseeRideMoved} disabled={isDisableAllRate} name="vParkingFee" fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Parking Fee" value={this.state.vParkingFee} onValueChange={(values) => { this.handleFranchiseeRateChange("vParkingFee", values.floatValue) }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Overtime Charge <span className="text-primary" name="vOtAmountPercent" style={{ fontSize: 9 + 'px' }}>[{parseFloat(this.state.vOtAmountPercent).toFixed(2)}%]</span></label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" readOnly={isFranchiseeRideMoved} disabled={isDisableAllRate} name="vOtAmount" fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Overtime Amount" value={this.state.vOtAmount} onValueChange={(values) => { this.handleFranchiseeRateChange("vOtAmount", values.floatValue) }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Stop Charge <span className="text-primary" name="vStopTotalPercent" style={{ fontSize: 9 + 'px' }}>[{parseFloat(this.state.vStopChargePercent).toFixed(2)}%]</span></label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" readOnly={isFranchiseeRideMoved} disabled={isDisableAllRate} name="vStopCharge" fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Extra Stop Charge" value={this.state.vStopCharge} onValueChange={(values) => { this.handleFranchiseeRateChange("vStopCharge", values.floatValue) }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>No Of Stops</label>
                                                                <NumericInput className="form-control form-control-sm" readOnly={isFranchiseeRideMoved} disabled={isDisableAllRate} name="vNoOfStops" title="No Of Stops" value={this.state.vNoOfStops} min={0} max={100} onChange={(value) => { this.handleFranchiseeRateChange("vNoOfStops", value) }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Wait Chg. <span className="text-primary" name="vWaitingChargePercent" style={{ fontSize: 9 + 'px' }}>[{parseFloat(this.state.vWaitingChargePercent).toFixed(2)}%]</span></label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" readOnly={isFranchiseeRideMoved} name="vWaitingCharge" fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Waiting Charge" value={this.state.vWaitingCharge} onValueChange={(values) => { this.handleFranchiseeRateChange("vWaitingCharge", values.floatValue) }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Wait Time (Min.)</label>
                                                                <NumericInput className="form-control form-control-sm" readOnly={isFranchiseeRideMoved} disabled={isDisableAllRate} name="vWaitingTime" title="Waiting Time" value={this.state.vWaitingTime} min={0} max={1000} onChange={(value) => { this.handleFranchiseeRateChange("vWaitingTime", value) }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>Extra Amount <span className="text-primary" name="vExtraAmountPercent" style={{ fontSize: 9 + 'px' }}>[{parseFloat(this.state.vExtraAmountPercent).toFixed(2)}%]</span></label>
                                                                <NumberFormat allowNegative={false} className="form-control form-control-sm" readOnly={isFranchiseeRideMoved} disabled={isDisableAllRate} name="vExtraAmount" fixedDecimalScale={true} maxLength={10} prefix={'$'} decimalScale={3} title="Extra Amount" value={this.state.vExtraAmount} onValueChange={(values) => { this.handleFranchiseeRateChange("vExtraAmount", values.floatValue) }} />
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-2 col-md-2 col-sm-3 col-xs-6">
                                                            <div className="form-group">
                                                                <label>&nbsp;</label>
                                                                <h5>Driver Net: ${parseFloat(this.state.driverNet).toFixed(2)}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isMultipleRideOpen}
                    style={customStyles}
                    className={"react-modal"}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal"
                >
                    <form method="post" onSubmit={this.handleSelectMultipleRideSubmit} >
                        <div className="modal-header">
                            <h6 className="modal-title">Multiple Zone</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeMultipleRideModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <div className="widget-container" style={{ maxHeight: 350 + 'px' }}>
                                <div className="row">
                                    <DataGrid
                                        dataSource={this.state.unapproveRides}
                                        id="grid-container"
                                        keyExpr="rideId"
                                        showBorders={true}
                                        columnsAutoWidth={false}
                                        showColumnLines={false}
                                        showRowLines={true}
                                        rowAlternationEnabled={true}
                                        allowColumnResizing={true}
                                        columnResizingMode='widget'
                                        width='100%'
                                        selectedRowKeys={this.state.selectedRowKeys}
                                        onSelectionChanged={this.onSelectionGridChanged}
                                        ref={ref => this.dataGrid = ref}>
                                        <Selection
                                            mode="multiple"
                                            selectAllMode={"allPages"}
                                            showCheckBoxesMode={"always"}
                                        />
                                        <Column dataField="rideId" caption="Ride Id" fixed={true} width={100} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="pickupDate" caption="PU Date Time" width={130} allowFiltering={true} allowSorting={true} format={DateFormat.DateTime24Format} dataType="datetime" />
                                        <Column dataField="adultNo" caption="Pax#" width={70} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="paymentType" caption="Payment Type" width={100} allowFiltering={true} allowSorting={true} />

                                        <Column dataField="baseFare" caption="Ride Fare" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="toComm" caption="Comms($)" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="tollAmount" caption="Toll Amt" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="tipAmount" caption="Tip Amt" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="fuelSurCharge" caption="Fuel Sur Chrg" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="stopCharge" caption="Stop" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="waitTotal" caption="Wait Chrg" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />

                                        <Column dataField="portAuthority" caption="Port Auth" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="utilityTax" caption="Utility" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="wcTax" caption="WC Tax" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="salesTax" caption="Sales Tax" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="countyTax" caption="County Tax" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="congestionTax" caption="Congestion Tax" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="totalFare" caption="Net" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="dRideAmount" caption="D Ride Amnt" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="dNetAmount" caption="D Net Comms" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="tripStatus" caption="Ride Status" width={150} allowFiltering={true} allowSorting={true} />
                                        
                                        <SortByGroupSummaryInfo summaryItem="count" />
                                        <GroupPanel visible={false} />
                                        <HeaderFilter visible={false} />
                                        <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                        <Grouping autoExpandAll={true} />
                                        <Paging defaultPageSize={20} />
                                        <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                        <Export enabled={true} allowExportSelectedData={false} />
                                        <ColumnChooser enabled={true} />
                                        <ColumnFixing enabled={true} />
                                    </DataGrid>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success btn-sm" onClick={this.handleSelectMultipleRideSubmit} ><span><i className="fas fa-save"></i> Continue</span> </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeMultipleRideModal}><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <DocumentsComponent ref="documentsComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}