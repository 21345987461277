import DataGrid, {
    Column,
    FilterRow,
    Paging, Scrolling,
    Selection
} from 'devextreme-react/data-grid';
import DateBox from 'devextreme-react/date-box';
import DropDownBox from 'devextreme-react/drop-down-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import RadioGroup from 'devextreme-react/radio-group';
import { SelectBox } from 'devextreme-react/select-box';
import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { formatDate, getRequestParams } from '../../helpers/globalfunctions.js';
import { SendEmailComponent } from '../Shared/SendEmailComponent';
const reportTypeRadio = [
    { id: 0, name: 'By Month' },
    { id: 1, name: 'By Vehicle' },
];

export class VehiclesProductivityReportComponent extends Component {
    constructor() {
        super()
        this.state = {
            isSubmitted: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            reportTypeList: reportTypeRadio,
            reportType: reportTypeRadio[0],
            radVehicle: "0",
            vehicleList: [],
            multiVehicleList: [],
            vehicleId: 0,
            vehicleName: "",
            filterVehicleList: [],
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),
            radDateType: "0",
            airlineList: [],
            errors: {
                vehicleId: 0,
            }
        }

        this.getVehicles = this.getVehicles.bind(this);

        this.handleRadVehicleChange = this.handleRadVehicleChange.bind(this);
        this.handleVehicleChange = this.handleVehicleChange.bind(this);
        this.handleMultiVehicleChange = this.handleMultiVehicleChange.bind(this);
        this.handleDateTypeChange = this.handleDateTypeChange.bind(this);
        this.handleFromDateChanged = this.handleFromDateChanged.bind(this);
        this.handleToDateChanged = this.handleToDateChanged.bind(this);
        this.handleReportTypeChange = this.handleReportTypeChange.bind(this);
        this.handleVehicleProductivityReportSubmit = this.handleVehicleProductivityReportSubmit.bind(this);
        this.downloadVehicleProductivityAsPdf = this.downloadVehicleProductivityAsPdf.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
        this.syncDataGridVehicleSelection = this.syncDataGridVehicleSelection.bind(this);
        this.dataGridVehicleRender = this.dataGridVehicleRender.bind(this);
    }
    async componentDidMount() {
        await this.getVehicles();
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    async getVehicles() {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/vehicle-api/dropdown-all', requestParams);
            const data = await response.json();
            this.setState({
                vehicleList: data.vehiclesDropdown,
                multiVehicleList: data.vehiclesDropdown,
            });
        } catch (e) {
            console.log(e);
        }
    }
    syncTreeViewSelection(e) {
        let treeView = (e.component.selectItem && e.component) || (this.treeView && this.treeView.instance);

        if (treeView) {
            if (e.value === null) {
                treeView.unselectAll();
            } else {
                let values = e.value || this.state.treeBoxValue;
                values && values.forEach(function (value) {
                    treeView.selectItem(value);
                });
            }
        }

        if (e.value !== undefined) {
            this.setState({
                treeBoxValue: e.value
            });
        }
    }
    treeView_itemSelectionChanged(e) {
        this.setState({
            treeBoxValue: e.component.getSelectedNodeKeys()
        });
    }
    handleRadVehicleChange(e) {
        if (e.target.value === "0") {
            this.setState({
                radVehicle: e.target.value,
                vehicleId: 0,
                vehicleName: "",
                filterVehicleList: [],
            });
        }
        else if (e.target.value === "1") {
            this.setState({
                radVehicle: e.target.value,
                filterVehicleList: [],
            });
        }
        else {
            this.setState({
                radVehicle: e.target.value,
                vehicleId: 0,
                vehicleName: "",
            });

        }
        this.setState({ radVehicle: e.target.value });
    }
    handleDateTypeChange(e) {
        this.setState({
            radDateType: e.target.value,
        });
    }
    handleReportTypeChange(e) {
        this.setState({
            reportType: e.value
        });
    }
    handleVehicleChange(e) {
        if (e !== null) {
            this.setState({
                vehicleId: (e.selectedItem != null ? e.selectedItem.id : 0),
                vehicleName: (e.selectedItem != null ? e.selectedItem.name : "")
            });
        }
        else {
            this.setState({
                vehicleId: 0,
                vehicleName: "",
            });
        }
    }
    handleMultiVehicleChange(selectedOption) {
        //this.setState({ filterVehicleList: [] })
        if (selectedOption != null) {
            //this.setState({ filterVehicleList: selectedOption })
            this.setState({ filterVehicleList: selectedOption.selectedRowKeys.length && selectedOption.selectedRowKeys || [] });
        }
    }
    syncDataGridVehicleSelection(e) {
        this.setState({
            filterVehicleList: e.value || []
        });
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ [name]: event.target.value });
    }
    handleFromDateChanged(event) {
        let fromDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ startDate: fromDateValue })
    }
    handleToDateChanged(event) {
        let toDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ endDate: toDateValue })
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getCustomerList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isEmailSubmitted: false,
                isOpenModal: false,
                loadPanelVisible: false,
                reportTypeList: reportTypeRadio,
                reportType: reportTypeRadio[0],
                radVehicle: "0",
                vehicleId: 0,
                vehicleName: "",
                filterVehicleList: [],
                startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
                endDate: new Date(),
                radDateType: "0",
                errors: {
                    vehicleId: 0,
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleVehicleProductivityReportSubmit(e) {
        e.preventDefault();
        this.downloadVehicleProductivityAsPdf(e);
    }
    async downloadVehicleProductivityAsPdf(e) {
        e.preventDefault();

        try {
            this.setState({
                loadPanelVisible: true,
                isSubmitted: true,
            });
            let reportHeader = "", multipleVehicleId = "";
            if (this.state.radVehicle === 0) {
                reportHeader = "For All Vehicles";
                multipleVehicleId = "";
            }
            else if (this.state.radVehicle === 1) {
                reportHeader = reportHeader + " For Vehicles " + this.state.vehicleName + ",";
                multipleVehicleId = this.state.vehicleId.toString();
            }
            else if (this.state.radVehicle === 2) {
                let selVeicleId = "";
                for (let vt = 0; vt < this.state.filterVehicleList.length; vt++) {
                    selVeicleId += this.state.filterVehicleList[vt].id + ",";
                }
                multipleVehicleId = selVeicleId.substring(0, selVeicleId.length - 1);
            }

            if (this.state.radDateType === 0) {
                reportHeader = reportHeader + " For All Date ";
            }
            else {
                reportHeader = reportHeader + " From Date " + formatDate(this.state.startDate, "MM/dd/yyyy") + " To " + formatDate(this.state.endDate, "MM/dd/yyyy");
            }

            var request = new Object();
            request.LocationId = Number(sessionStorage.getItem("session_locationId"));
            request.fileFormat = 'pdf';
            request.MethodName = "GetVehiclesProductivity";
            request.MultipleVehicleId = multipleVehicleId;
            request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy");
            request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy");
            request.Header = reportHeader;

            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            this.setState({
                loadPanelVisible: false,
                isSubmitted: false,
            });

            let a = document.createElement('a');
            a.href = dataResponse.reportBase64String;
            a.download = 'VehiclesProductivity.pdf';
            a.click();
        } catch (e) {

        }
    }
    handleClearSearch() {
        this.setState({
            isSubmitted: false,
            isEmailSubmitted: false,
            isOpenModal: true,
            loadPanelVisible: false,
            reportTypeList: reportTypeRadio,
            reportType: reportTypeRadio[0],
            radVehicle: "0",
            vehicleId: 0,
            vehicleName: "",
            filterVehicleList: [],
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),
            radDateType: "0",
            errors: {
                vehicleId: 0,
            }
        });
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            this.setState({ isEmailSubmitted: true });
            let reportHeader = "", multipleVehicleId = "";
            if (this.state.radVehicle === 0) {
                reportHeader = "For All Vehicles";
                multipleVehicleId = "";
            }
            else if (this.state.radVehicle === 1) {
                reportHeader = reportHeader + " For Vehicles " + this.state.vehicleName + ",";
                multipleVehicleId = this.state.vehicleId.toString();
            }
            else if (this.state.radVehicle === 2) {
                let selVeicleId = "";
                for (let vt = 0; vt < this.state.filterVehicleList.length; vt++) {
                    selVeicleId += this.state.filterVehicleList[vt].id + ",";
                }
                multipleVehicleId = selVeicleId.substring(0, selVeicleId.length - 1);
            }

            if (this.state.radDateType === 0) {
                reportHeader = reportHeader + " For All Date ";
            }
            else {
                reportHeader = reportHeader + " From Date " + formatDate(this.state.startDate, "MM/dd/yyyy") + " To " + formatDate(this.state.endDate, "MM/dd/yyyy");
            }

            var request = new Object();
            request.LocationId = Number(sessionStorage.getItem("session_locationId"));
            request.fileFormat = 'pdf';
            request.MethodName = "GetVehiclesProductivity";
            request.MultipleVehicleId = multipleVehicleId;
            request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy");
            request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy");
            request.Header = reportHeader;

            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            this.setState({ isEmailSubmitted: false });
            let fileData = dataResponse.reportBase64String;
            if (fileData !== null && fileData !== '') {
                let emailSubject = 'Vehicle Productivity Report';
                let attachments = [];
                let file = new Object();
                file.fileData = fileData;
                file.fileName = 'VehiclesProductivity.pdf'
                file.fileExtension = '.pdf';
                attachments.push(file);
                this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
            }
            else {
                Swal.fire({
                    allowOutsideClick: false,
                    showCloseButton: true,
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong! Unable to process the email request.',
                })
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '60%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleOnReportSubmit}
                        style={(this.state.isSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Vehicle Productivity Report'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radVehicle" value="0" checked={this.state.radVehicle === "0"} onChange={this.handleRadVehicleChange} />
                                            <label className="form-check-label">All Vehicle</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radVehicle" value="1" checked={this.state.radVehicle === "1"} onChange={this.handleRadVehicleChange} />
                                            <label className="form-check-label">Single Vehicle</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radVehicle" value="2" checked={this.state.radVehicle === "2"} onChange={this.handleRadVehicleChange} />
                                            <label className="form-check-label">Vehicle Range</label>
                                        </span>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder="Select vehicle"*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.vehicleId === 0 ? [] : [this.state.vehicleList.find(opt => opt.id === this.state.vehicleId)]}*/}
                                        {/*    name="id"*/}
                                        {/*    onChange={this.handleVehicleChange}*/}
                                        {/*    getOptionLabel={option => option.name}*/}
                                        {/*    getOptionValue={option => option.id}*/}
                                        {/*    options={this.state.vehicleList.length > 0 ? this.state.vehicleList : []}*/}
                                        {/*    isMulti={false}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*    isDisabled={this.state.radVehicle === "1" ? false : true}*/}
                                        {/*/>*/}

                                        <SelectBox
                                            dataSource={this.state.vehicleList.length > 0 ? this.state.vehicleList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select vehicle"
                                            name="id"
                                            defaultValue={[]}
                                            value={this.state.vehicleId === 0 ? [] : [this.state.vehicleList.find(opt => opt.id === this.state.vehicleId)]}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleVehicleChange}
                                            showClearButton={true}
                                            disabled={this.state.radVehicle === "1" ? false : true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["vehicleId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["vehicleId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>&nbsp;</label>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder="Select vehicle"*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.filterVehicleList.length > 0 ? this.state.filterVehicleList : []}*/}
                                        {/*    name="id"*/}
                                        {/*    onChange={this.handleMultiVehicleChange}*/}
                                        {/*    getOptionLabel={option => option.name}*/}
                                        {/*    getOptionValue={option => option.id}*/}
                                        {/*    options={this.state.multiVehicleList.length > 0 ? this.state.multiVehicleList : []}*/}
                                        {/*    isMulti={true}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isDisabled={false}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*    isDisabled={this.state.radVehicle === "2" ? false : true} />*/}

                                        <DropDownBox
                                            value={this.state.filterVehicleList.length > 0 ? this.state.filterVehicleList : []}
                                            valueExpr="id"
                                            deferRendering={false}
                                            displayExpr="name"
                                            placeholder="Select vehicle"
                                            showClearButton={true}
                                            dataSource={this.state.multiVehicleList.length > 0 ? this.state.multiVehicleList : []}
                                            onValueChanged={this.syncDataGridVehicleSelection}
                                            contentRender={this.dataGridVehicleRender}
                                            disabled={this.state.radVehicle === "2" ? false : true}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>Report Type</label>
                                        <RadioGroup id="radio-group-sortby" dataSource={this.state.reportTypeList} displayExpr="name" value={this.state.reportType} onValueChanged={this.handleReportTypeChange} layout="horizontal" />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radDateType" value="0" checked={this.state.radDateType === "0"} onChange={this.handleDateTypeChange} />
                                            <label className="form-check-label">All Date</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radDateType" value="1" checked={this.state.radDateType === "1"} onChange={this.handleDateTypeChange} />
                                            <label className="form-check-label">Date Range</label>
                                        </span>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.startDate}
                                            value={this.state.startDate}
                                            onValueChanged={this.handleFromDateChanged}
                                            width="100%"
                                            disabled={this.state.radDateType === "1" ? false : true}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>&nbsp;</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.endDate}
                                            value={this.state.endDate}
                                            onValueChanged={this.handleToDateChanged}
                                            width="100%"
                                            disabled={this.state.radDateType === "1" ? false : true}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="submit" className="btn btn-success btn-sm" title="Download" onClick={this.handleVehicleProductivityReportSubmit} >
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                                </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>

        );
    }

    dataGridVehicleRender() {
        return (
            <DataGrid
                dataSource={this.state.multiVehicleList}
                id="grid-container"
                keyExpr="id"
                hoverStateEnabled={true}
                selectedRowKeys={this.state.filterVehicleList}
                onSelectionChanged={this.handleMultiVehicleChange}
                ref={ref => this.dataGrid = ref}>
                <Column dataField="name" caption="Vehicle" />
                <Selection mode="multiple" />
                <Scrolling mode="virtual" />
                <Paging enabled={false} pageSize={50} />
                <FilterRow visible={true} />
            </DataGrid>
        );
    }
}