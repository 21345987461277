import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter,
    SearchPanel,
    SortByGroupSummaryInfo,
    MasterDetail,
    Scrolling,
    FilterRow,
    Paging,
    Selection,
}
    from 'devextreme-react/data-grid';
import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import { SelectBox } from 'devextreme-react/select-box';
import DropDownBox from 'devextreme-react/drop-down-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import RadioGroup from 'devextreme-react/radio-group';
import { CheckBox } from 'devextreme-react/check-box';
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'
const graphTypeRadio = [
    { id: 0, name: 'Pie' },
    { id: 1, name: 'Bar' },
];

export class RevenueSummaryGraphReportComponent extends Component {
    constructor() {
        super()
        this.state = {
            isSubmitted: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),
            radCustomerType: "0",
            dropdownCustomerType: [],
            customerTypeId: 0,
            customerType: "",
            multipleCustomerTypeList: [],
            filterCustomerTypeList: [],
            graphTypeList: graphTypeRadio,
            graphType: graphTypeRadio[0],
            partialPay: false,
            errors: {
                customerTypeId: 0,
                multipleCustomerTypeId: 0,
            }
        }

        this.getCustomerType = this.getCustomerType.bind(this);

        this.handleRadCustomerTypeChange = this.handleRadCustomerTypeChange.bind(this);
        this.handleCustomerTypeChange = this.handleCustomerTypeChange.bind(this);
        this.handleMultipleCustomerTypeChange = this.handleMultipleCustomerTypeChange.bind(this);
        this.handleFromDateChanged = this.handleFromDateChanged.bind(this);
        this.handleToDateChanged = this.handleToDateChanged.bind(this);
        this.handleCustomerChange = this.handleCustomerChange.bind(this);
        this.handlePartialPayValueChanged = this.handlePartialPayValueChanged.bind(this);
        this.handleGraphTypeChange = this.handleGraphTypeChange.bind(this);

        this.syncDataGridCustomerTypeSelection = this.syncDataGridCustomerTypeSelection.bind(this);
        this.dataGridCustomerTypeRender = this.dataGridCustomerTypeRender.bind(this);

        this.handleRevenueSummaryGraphReportSubmit = this.handleRevenueSummaryGraphReportSubmit.bind(this);
        this.downloadRevenueSummaryGraphAsPdf = this.downloadRevenueSummaryGraphAsPdf.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
    }
    async componentDidMount() {
        await this.getCustomerType();
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    async getCustomerType() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('customer-type-api/', requestParams);
            const data = await response.json();
            this.setState({
                dropdownCustomerType: data.customerType,
                multipleCustomerTypeList: data.customerType,
            });

        } catch (e) {
            console.log(e);
        }
    }
    handleGraphTypeChange(e) {
        this.setState({
            graphType: e.value
        });
    }
    handleCustomerTypeChange(selectedOption) {
        if (selectedOption != null) {            
            this.setState({
                customerTypeId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0),
                customerType: (selectedOption.selectedItem != null ? selectedOption.selectedItem.name : ""),
            })
        }
        else {
            this.setState({ customerTypeId: 0, customerType: "" })
        }
    }
    handleMultipleCustomerTypeChange(selectedOption) {
        try {
            this.setState({ filterCustomerTypeList: [] })
            this.setState({
                filterCustomerTypeList: selectedOption.selectedRowKeys.length && selectedOption.selectedRowKeys || []
            });
        } catch (e) {

        }
    }
    handlePartialPayValueChanged(e) {
        this.setState({ partialPay: e.value })
    }
    handleRadCustomerTypeChange(e) {
        if (e.target.value == "0") {
            this.setState({
                radCustomerType: e.target.value,
                vehicleId: 0,
                vehicleName: "",
                filterVehicleList: [],
            });
        }
        else if (e.target.value == "1") {
            this.setState({
                radCustomerType: e.target.value,
                filterVehicleList: [],
            });
        }
        else {
            this.setState({
                radCustomerType: e.target.value,
                vehicleId: 0,
                vehicleName: "",
            });

        }
        this.setState({ radCustomerType: e.target.value });
    }
    handleFromDateChanged(event) {
        let fromDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ startDate: fromDateValue })
    }
    handleToDateChanged(event) {
        let toDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ endDate: toDateValue })
    }
    handleCustomerChange(e) {
        if (e != null) {
            this.setState({
                customerId: e.id,
                customerName: e.name,
                customerCode: e.code,
            });
        }
        else {
            this.setState({ customerId: 0, customerName: "", customerCode: "" });
        }
    }
    syncDataGridCustomerTypeSelection(e) {
        this.setState({
            filterCustomerTypeList: e.value || []
        });
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getCustomerList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isEmailSubmitted: false,
                isOpenModal: false,
                loadPanelVisible: false,
                startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
                endDate: new Date(),
                radCustomerType: "0",
                customerTypeId: 0,
                filterCustomerTypeList: [],
                graphTypeList: graphTypeRadio,
                graphType: graphTypeRadio[0],
                partialPay: false,
                errors: {
                    customerTypeId: 0,
                    multipleCustomerTypeId: 0,
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;

        errors.customerTypeId = 0;
        errors.multipleCustomerTypeId = 0;

        if (this.state.radCustomerType == "1") {
            if (this.state.customerTypeId == "") {
                formIsValid = false;
                errors.customerTypeId = "Please select customer";
            }
        }

        if (this.state.radCustomerType == "2") {
            if (this.state.filterCustomerTypeList.length == 0) {
                formIsValid = false;
                errors.multipleCustomerTypeId = "Please select customer";
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    handleRevenueSummaryGraphReportSubmit(e) {
        e.preventDefault();
        this.downloadRevenueSummaryGraphAsPdf(e);
    }
    async downloadRevenueSummaryGraphAsPdf(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            try {
                let criteria1 = "", accountType = "", selectionCriteria = "", partialPayValue = 0;

                this.setState({
                    //loadPanelVisible: true,
                    isSubmitted: true,
                });

                if (this.state.partialPay) {
                    partialPayValue = 1;
                }

                if (this.state.radCustomerType == "0") {
                    criteria1 = " For all Account Types";
                    accountType = "";
                    selectionCriteria = "";
                }
                else if (this.state.radCustomerType == "1") {
                    criteria1 = " For all Account Types";
                    accountType = this.state.customerType;
                    selectionCriteria = "For Account Type - " + accountType;
                    if (partialPayValue == 0) {
                        selectionCriteria = " {Proc_Revenue_Details;1.ACCT_TYPE} = '" + accountType + "'";
                    }
                    else {
                        selectionCriteria = " {Proc_Revenue_Details_Partial_Pay;1.ACCT_DESC} = '" + accountType + "'";
                    }
                }
                else if (this.state.radCustomerType == "2") {
                    let mulCustomerType = "";
                    for (let vt = 0; vt < this.state.filterCustomerTypeList.length; vt++) {
                        mulCustomerType += this.state.filterCustomerTypeList[vt].name + ",";
                    }
                    selectionCriteria = "For Account Type's -  " + mulCustomerType.substring(0, mulCustomerType.length - 1);
                }

                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.fileFormat = 'pdf';
                request.MethodName = "GetRevenueSummaryGraph";
                request.IsPartialPay = this.state.partialPay;
                request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy");
                request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy");
                request.GraphType = this.state.graphType.id.toString();
                request.Criteria1 = criteria1;
                request.SelectionCrieteria = selectionCriteria;

                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({
                    //loadPanelVisible: false,
                    isSubmitted: false,
                });
                let a = document.createElement('a');
                a.href = dataResponse.reportBase64String;
                a.download = 'RevenueSummaryGraph.pdf';
                a.click();
            } catch (e) {

            }
        }
    }
    handleClearSearch() {
        this.setState({
            isSubmitted: false,
            isEmailSubmitted: false,
            isOpenModal: true,
            loadPanelVisible: false,
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),
            radCustomerType: "0",
            customerTypeId: 0,
            filterCustomerTypeList: [],
            graphTypeList: graphTypeRadio,
            graphType: graphTypeRadio[0],
            partialPay: false,
            errors: {
                customerTypeId: 0,
                multipleCustomerTypeId: 0,
            }
        });
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isEmailSubmitted: true });
                let criteria1 = "", accountType = "", selectionCriteria = "", partialPayValue = 0;
                if (this.state.partialPay) {
                    partialPayValue = 1;
                }

                if (this.state.radCustomerType == "0") {
                    criteria1 = " For all Account Types";
                    accountType = "";
                    selectionCriteria = "";
                }
                else if (this.state.radCustomerType == "1") {
                    criteria1 = " For all Account Types";
                    accountType = this.state.customerType;
                    selectionCriteria = "For Account Type - " + accountType;
                    if (partialPayValue == 0) {
                        selectionCriteria = " {Proc_Revenue_Details;1.ACCT_TYPE} = '" + accountType + "'";
                    }
                    else {
                        selectionCriteria = " {Proc_Revenue_Details_Partial_Pay;1.ACCT_DESC} = '" + accountType + "'";
                    }
                }
                else if (this.state.radCustomerType == "2") {
                    let mulCustomerType = "";
                    for (let vt = 0; vt < this.state.filterCustomerTypeList.length; vt++) {
                        mulCustomerType += this.state.filterCustomerTypeList[vt].name + ",";
                    }
                    selectionCriteria = "For Account Type's -  " + mulCustomerType.substring(0, mulCustomerType.length - 1);
                }

                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.fileFormat = 'pdf';
                request.MethodName = "GetRevenueSummaryGraph";
                request.IsPartialPay = this.state.partialPay;
                request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy");
                request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy");
                request.GraphType = this.state.graphType.id.toString();
                request.Criteria1 = criteria1;
                request.SelectionCrieteria = selectionCriteria;
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Revenue Summary Graph';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = 'RevenueSummaryGraph.pdf'
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '45%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleOnReportSubmit}
                        style={(this.state.isSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Revenue Summary Graph'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label htmlFor="">From Ride Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.startDate}
                                            value={this.state.startDate}
                                            onValueChanged={this.handleFromDateChanged}
                                            width="100%"
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>To Ride Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.endDate}
                                            value={this.state.endDate}
                                            onValueChanged={this.handleToDateChanged}
                                            width="100%"
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radCustomerType" value="0" checked={this.state.radCustomerType === "0"} onChange={this.handleRadCustomerTypeChange} />
                                            <label className="form-check-label">All</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radCustomerType" value="1" checked={this.state.radCustomerType === "1"} onChange={this.handleRadCustomerTypeChange} />
                                            <label className="form-check-label">Single</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radCustomerType" value="2" checked={this.state.radCustomerType === "2"} onChange={this.handleRadCustomerTypeChange} />
                                            <label className="form-check-label">Range</label>
                                        </span>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder="Select customer type"*/}
                                        {/*    name="customerTypeId"*/}
                                        {/*    options={this.state.dropdownCustomerType.length > 0 ? this.state.dropdownCustomerType : []}*/}
                                        {/*    getOptionLabel={option => option.name}*/}
                                        {/*    getOptionValue={option => option.id}*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.customerTypeId === '' ? [] : [this.state.dropdownCustomerType.find(opt => opt.id == this.state.customerTypeId)]}*/}
                                        {/*    onChange={this.handleCustomerTypeChange}*/}
                                        {/*    isMulti={false}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isDisabled={false}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*    isClearable={false}*/}
                                        {/*    isDisabled={this.state.radCustomerType == "1" ? false : true}*/}
                                        {/*/>*/}

                                        <SelectBox
                                            dataSource={this.state.dropdownCustomerType.length > 0 ? this.state.dropdownCustomerType : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer type"
                                            name="customerTypeId"
                                            defaultValue={[]}
                                            value={this.state.customerTypeId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleCustomerTypeChange}
                                            showClearButton={true}
                                            disabled={this.state.radCustomerType == "1" ? false : true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerTypeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerTypeId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Select Multiple Customer Type</label>
                                        <DropDownBox
                                            value={this.state.filterCustomerTypeList}
                                            valueExpr="id"
                                            deferRendering={false}
                                            displayExpr="name"
                                            placeholder="Select customer type..."
                                            showClearButton={true}
                                            dataSource={this.state.multipleCustomerTypeList.length > 0 ? this.state.multipleCustomerTypeList : []}
                                            onValueChanged={this.syncDataGridCustomerTypeSelection}
                                            contentRender={this.dataGridCustomerTypeRender}
                                        />

                                        {this.state.errors["multipleCustomerTypeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["multipleCustomerTypeId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>Graph Type</label>
                                        <RadioGroup id="radio-group-graphtype" dataSource={this.state.graphTypeList} displayExpr="name" value={this.state.graphType} onValueChanged={this.handleGraphTypeChange} layout="horizontal" />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label><br /></label>
                                        <CheckBox defaultValue={false} width={100} text="Partial Pay" value={this.state.partialPay} onValueChanged={this.handlePartialPayValueChanged} />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="submit" className="btn btn-success btn-sm" title="Download" onClick={this.handleRevenueSummaryGraphReportSubmit} >
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                                </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }

    dataGridCustomerTypeRender() {
        return (
            <DataGrid
                dataSource={this.state.multipleCustomerTypeList}
                id="grid-container"
                keyExpr="id"
                hoverStateEnabled={true}
                selectedRowKeys={this.state.filterCustomerTypeList}
                onSelectionChanged={this.handleMultipleCustomerTypeChange}
                ref={ref => this.dataGrid = ref}>
                <Column dataField="name" caption="Location" />
                <Selection mode="multiple" />
                <Scrolling mode="virtual" />
                <Paging enabled={false} pageSize={50} />
                <FilterRow visible={true} />
            </DataGrid>
        );
    }
}