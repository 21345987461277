import React, { Component } from 'react';
import DataGrid, {
    Column, Grouping, GroupPanel, Pager, Paging, SearchPanel, Export, SortByGroupSummaryInfo, ColumnChooser, ColumnFixing, HeaderFilter,
    Button
} from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import Modal from 'react-modal'; //npm install --save react-modal

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ReactTooltip from "react-tooltip"; //npm install react - tooltip
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { CustomerSettingComponent } from './CustomerSettingComponent';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { SelectBox } from 'devextreme-react/select-box';
import NumberFormat from 'react-number-format';
import { ModuleCode, CustomerType } from '../../helpers/fixcodes.js'
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react/load-panel';
import { ApplicationType } from '../../helpers/fixcodes.js'
import { CustomerVehicleTypeCommissionSettingComponent } from './CustomerVehicleTypeCommissionSettingComponent';
const customStyles = {
    content: {
        width: '90%'
    }
};

const customHeight = {
    content: {
        height: '90%'
    }
};

const position = { of: '#historydiv' };

export const rating = [
    {
        id: "0", name: "Select rating"
    },
    {
        id: "1", name: "*"
    },
    {
        id: "2", name: "**"
    },
    {
        id: "3", name: "***"
    },
    {
        id: "4", name: "****"
    },
    {
        id: "5", name: "*****"
    }]

export class CustomerListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            customerList: null,
            customerSettingModalIsOpen: false,
            customerData: [],
            tabcontent: null,
            customerTypeList: null,
            customerBillToList: [],
            rowId: 0,
            customerType: 0,
            accountCode: "",
            dropdownConsumer: [],
            consumerId: 0,
            contactPerson: "",
            legalName: "",
            name: "",
            webAddress: "",
            userName: "",
            password: '',
            firstName: "",
            lastName: "",
            address: "",
            rateTypeList: null,
            rateTypeId: 0,
            billTo: 0,
            phone: "",
            ratingList: null,
            ratingId: "0",
            priority: false,
            fax: "",
            loadPanelVisible: false,
            isActive: true,
            appTypeId: parseInt(ApplicationType.UTPro),
            vIsPasswordComplexity: false,
            vMinLength: 0,
            vMinUpperCaseLetter: 0,
            vMinLowerCaseLetter: 0,
            vMinNumber: 0,
            vMinSpecialCharacter: 0,
            isConsumerEnabled: 1,
            contactPerson1: "",
            contactPerson2: "",
            contactPerson3: "",
            contactPerson4: "",
            contactPerson5: "",
            designation1: "",
            designation2: "",
            designation3: "",
            designation4: "",
            designation5: "",
            mobile1: "",
            mobile2: "",
            mobile3: "",
            mobile4: "",
            mobile5: "",
            email1: "",
            email2: "",
            email3: "",
            email4: "",
            email5: "",
            contactPersonCount: 1,
            isRemoveButton: true,
            isAddButton: false,
            errors: {
                customerType: "",
                accountCode:"",
                legalName: "",
                consumerId: "",
                name: "",
                userName: "",
                password: "",
                firstName: "",
                rateTypeId: "",
                billTo: "",
                phone: "",
                fax: "",
                webAddress: "",
                contactPerson1: "",
                contactPerson2: "",
                contactPerson3: "",
                contactPerson4: "",
                contactPerson5: "",
                designation1: "",
                designation2: "",
                designation3: "",
                designation4: "",
                designation5: "",
                mobile1: "",
                mobile2: "",
                mobile3: "",
                mobile4: "",
                mobile5: "",
                email1: "",
                email2: "",
                email3: "",
                email4: "",
                email5: "",
            }
        }

        this.getCustomerList = this.getCustomerList.bind(this);
        this.AddEditCustomerControl = this.AddEditCustomerControl.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCustomerTypeChange = this.handleCustomerTypeChange.bind(this);
        this.handleAddEditCustomerSubmit = this.handleAddEditCustomerSubmit.bind(this);
        this.OpenAddEditCutomerDataModal = this.OpenAddEditCutomerDataModal.bind(this);
        this.closeAddEditCustomerModal = this.closeAddEditCustomerModal.bind(this);
        this.handleConsumerChange = this.handleConsumerChange.bind(this);
        this.getConsumer = this.getConsumer.bind(this);
        this.getValidationRules = this.getValidationRules.bind(this);
        this.addContactPerson = this.addContactPerson.bind(this);
        this.removeContactPerson = this.removeContactPerson.bind(this);
    }
    componentDidMount() {
        this.getCustomerList();
        this.getValidationRules();
    }
    async getCustomerList() {
        this.setState({
            loadPanelVisible: true
        });
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('customer-api/list/' + 0, requestParams);
        const data = await response.json();
        this.setState({
            customerList: data.customer,
            loadPanelVisible: false
        });
    }

    async getValidationRules() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('password-policy-api/validation-rule/' + sessionStorage.getItem('session_accountId') + '/' + parseInt(this.state.appTypeId), requestParams);
            const data = await response.json();
            if (data !== null && data.passwordValidationRule != null) {
                this.setState({
                    vIsPasswordComplexity: data.passwordValidationRule.isPasswordComplexity,
                    vMinLength: data.passwordValidationRule.minLength,
                    vMinUpperCaseLetter: data.passwordValidationRule.minUpperCaseLetter,
                    vMinLowerCaseLetter: data.passwordValidationRule.minLowerCaseLetter,
                    vMinNumber: data.passwordValidationRule.minNumber,
                    vMinSpecialCharacter: data.passwordValidationRule.minSpecialCharacter
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    async getCustomerTypeList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('customer-type-api/', requestParams);

        const data = await response.json();
        if (data !== null && data.customerType.length > 0) {
            this.setState({
                customerTypeList: data.customerType
            });
        }
    }

    async getRateTypeList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('ratetype-api/', requestParams);

        const data = await response.json();
        if (data !== null && data.rateType.length > 0) {
            this.setState({
                rateTypeList: data.rateType
            });
        }
    }

    async getCustomerUserList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('account-api/customerUsers-list', requestParams);

        const data = await response.json();
        if (data !== null && data.customers.length > 0) {
            this.setState({
                customerBillToList: data.customers
            });
        }
    }
    handleBillToInputChange = (data) => {        
        this.setState({ billTo: data.selectedItem.id });
        this.state.errors["billTo"] = '';
    }

    AddEditCustomerControl(e, data) {
        e.preventDefault();
        if (data != null) {
            this.setState({
                customerSettingModalIsOpen: true,
                customerData: data
            });
        }
        else {
            this.setState({
                customerSettingModalIsOpen: true,
                customerData: data
            });
        }

    }
    async OpenAddEditCutomerDataModal() {
        let notificationLength = [];        
        this.setState({
            loadPanelVisible: true
        });
        this.getCustomerTypeList();
        this.getRateTypeList();
        this.getCustomerUserList();

        this.setState({ ratingList: rating })
        if (this.state.customerData !== null) {
            const requestParams = getRequestParams('GET', '');

            const response = await fetch('customer-api/list/' + this.state.customerData.accountId, requestParams);
            const data = await response.json();
            this.setState({ customerData: data.customer[0] });

            const response1 = await fetch('customer-api/' + this.state.customerData.accountId, requestParams);
            const data1 = await response1.json();
            let customerNotification = data1.accountLocationEmail;

            const customer = data.customer[0];
            
            this.setState({
                rowId: customer.accountId,
                customerType: customer.customerTypeId > 0 ? customer.customerTypeId : 0,
                contactPerson: customer.contactPerson !== "" || customer.contactPerson !== null ? customer.contactPerson : "",
                legalName: (customer.legalName !== "" && customer.legalName !== null) ? customer.legalName : customer.accountName,
                accountCode: customer.actualAccountCode !== "" || customer.actualAccountCode !== null ? customer.actualAccountCode : "",
                name: customer.accountName !== "" || customer.accountName !== null ? customer.accountName : "",
                webAddress: (customer.webAddress !== "" && customer.webAddress !== null) ? customer.webAddress : "",
                userName: customer.userName !== "" || customer.userName !== null ? customer.userName : "",
                firstName: customer.firstName !== "" || customer.firstName !== null ? customer.firstName : "",
                lastName: customer.lastName !== "" || customer.lastName !== null ? customer.lastName : "",
                address: customer.address1 !== "" || customer.address1 !== null ? customer.address1 : "",
                rateTypeId: customer.rateTypeId > 0 ? customer.rateTypeId : 0,
                billTo: customer.billToCustomerId <= 0 ? { id: -1, accountName: "Self" } : { id: -1, accountName: "Self" },
                phone: (customer.phone !== "" && customer.phone !== null) ? customer.phone : "",
                ratingId: customer.rating === null || customer.rating === '' ? "0" : customer.rating.trim(),
                fax: (customer.faxNo !== "" && customer.faxNo !== null) ? customer.faxNo : "",
                isActive: customer.enable,
                priority: customer.isPriority,
                contactPerson1: customer.vipP1,
                contactPerson2: customer.vipP2,
                contactPerson3: customer.vipP3,
                contactPerson4: customer.vipP4,
                contactPerson5: customer.vipP5,
                designation1: customer.vipT1,
                designation2: customer.vipT2,
                designation3: customer.vipT3,
                designation4: customer.vipT4,
                designation5: customer.vipT5,
            });
            
            const notificationGroups = [];

            if (customerNotification != null) {
                notificationGroups = customerNotification.reduce((singlenotification, { notificationTypeId, notification }) => {
                    (singlenotification[notificationTypeId] = singlenotification[notificationTypeId] || []).push(notification);
                    return singlenotification;
                }, {});

                for (const notificationType in notificationGroups) {
                    notificationLength.push(notificationGroups[notificationType].length);
                }
            }

            let result = notificationLength.map((y) => y);
            let maxNotificationLength = Math.max.apply(null, result);

            for (let i = 1; i <= maxNotificationLength; i++) {
                if (i > 1) {
                    var element = document.getElementById("row_" + parseInt(i));
                    if (element != null) {
                        element.classList.remove("displayNone");
                        element.classList.add("displayBlock");
                    }
                }
            }

            for (const notificationTypeIndex in notificationGroups) {                
                let notficationTypeObject = notificationGroups[notificationTypeIndex];
                if (notificationTypeIndex == "1") {
                    for (const notificationIndex in notficationTypeObject) {
                        let notificationEmail = notficationTypeObject[notificationIndex];                        
                        
                        switch (parseInt(notificationIndex) + 1) {
                            case 1:
                                this.setState({
                                    email1: notificationEmail
                                });
                                break;
                            case 2:
                                this.setState({
                                    email2: notificationEmail
                                });
                                break;
                            case 3:
                                this.setState({
                                    email3: notificationEmail
                                });
                                break;
                            case 4:
                                this.setState({
                                    email4: notificationEmail
                                });
                                break;
                            case 5:
                                this.setState({
                                    email5: notificationEmail
                                });
                                break;
                            default:
                                break;
                        }
                    }
                }
                else if (notificationTypeIndex == "6") {
                    for (const notificationIndex in notficationTypeObject) {
                        let notificationMobile = notficationTypeObject[notificationIndex];
                        switch (parseInt(notificationIndex) + 1) {
                            case 1:
                                this.setState({
                                    mobile1: notificationMobile
                                });
                                break;
                            case 2:
                                this.setState({
                                    mobile2: notificationMobile
                                });
                                break;
                            case 3:
                                this.setState({
                                    mobile3: notificationMobile
                                });
                                break;
                            case 4:
                                this.setState({
                                    mobile4: notificationMobile
                                });
                                break;
                            case 5:
                                this.setState({
                                    mobile5: notificationMobile
                                });
                                break;
                            default:
                                break;
                        }
                    }
                }
            }

            if (Number(customer.customerTypeId) === 1) {
                await this.getConsumer(Number(customer.customerTypeId));
            }
            else if (Number(customer.customerTypeId) === 5) {
                await this.getConsumer(Number(customer.customerTypeId));
            }
            else if (Number(customer.customerTypeId) === 14) {
                await this.getConsumer(Number(customer.customerTypeId));
            }
            else if (Number(customer.customerTypeId) === 15) {
                await this.getConsumer(Number(customer.customerTypeId));
            }
            else if (Number(customer.customerTypeId) === 16) {
                await this.getConsumer(Number(customer.customerTypeId));
            }
            else {
                this.setState({
                    dropdownConsumer: []
                });
            }
            this.setState({
                consumerId: customer.consumerId
            });
        } else {
            this.setState({
                rowId: 0,
                customerType: 0,
                contactPerson: "",
                legalName: "",
                name: "",
                webAddress: "",
                userName: "",
                password: "",
                firstName: "",
                lastName: "",
                address: "",
                rateTypeId: 0,
                billTo: 0,
                phone: "",
                ratingId: "0",
                priority: false,
                fax: "",
                contactPerson1: "",
                contactPerson2: "",
                contactPerson3: "",
                contactPerson4: "",
                contactPerson5: "",
                designation1: "",
                designation2: "",
                designation3: "",
                designation4: "",
                designation5: "",
                mobile1: "",
                mobile2: "",
                mobile3: "",
                mobile4: "",
                mobile5: "",
                email1: "",
                email2: "",
                email3: "",
                email4: "",
                email5: "",
            });
        }
        this.setState({
            loadPanelVisible: false
        });
    }
    closeAddEditCustomerModal() {
        this.setState({
            isSubmited: false,
            customerSettingModalIsOpen: false,
            tabcontent: null,
            //customerTypeList: null,
            //customerBillToList: [],
            rowId: 0,
            customerType: 0,
            accountCode:"",
            dropdownConsumer: [],
            consumerId: 0,
            contactPerson: "",
            legalName: "",
            name: "",
            webAddress: "",
            userName: "",
            password: "",
            firstName: "",
            lastName: "",
            address: "",
            rateTypeList: null,
            rateTypeId: 0,
            billTo: 0,
            phone: "",
            ratingList: null,
            ratingId: "0",
            priority: false,
            fax: "",
            loadPanelVisible: false,
            isActive: true,
            isConsumerEnabled: 1,
            contactPerson1: "",
            contactPerson2: "",
            contactPerson3: "",
            contactPerson4: "",
            contactPerson5: "",
            designation1: "",
            designation2: "",
            designation3: "",
            designation4: "",
            designation5: "",
            mobile1: "",
            mobile2: "",
            mobile3: "",
            mobile4: "",
            mobile5: "",
            email1: "",
            email2: "",
            email3: "",
            email4: "",
            email5: "",
            contactPersonCount: 1,
            isRemoveButton: true,
            isAddButton: false,
            errors: {
                customerType: "",
                legalName: "",
                consumerId: "",
                accountCode: "",
                name: "",
                password: "",
                userName: "",
                firstName: "",
                rateTypeId: "",
                billTo: "",
                phone: "",
                fax: "",
                webAddress: "",
                contactPerson1: "",
                contactPerson2: "",
                contactPerson3: "",
                contactPerson4: "",
                contactPerson5: "",
                designation1: "",
                designation2: "",
                designation3: "",
                designation4: "",
                designation5: "",
                mobile1: "",
                mobile2: "",
                mobile3: "",
                mobile4: "",
                mobile5: "",
                email1: "",
                email2: "",
                email3: "",
                email4: "",
                email5: "",
            }
        });
    }

    async getConsumer(customerTypeId) {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('consumer-api/' + customerTypeId, requestParams);

        const data = await response.json();
        if (data !== null && data.consumer.length > 0) {
            this.setState({
                dropdownConsumer: data.consumer
            });
        }
    }

    handleConsumerChange(selectedOption) {
        //this.setState({ consumerId: (selectedOption != null ? selectedOption.id : 0), })
        //this.state.errors["consumerId"] = "";

        //if (selectedOption != null) {
        //    this.setState({
        //        legalName: selectedOption.name, name: selectedOption.name
        //    })
        //}
        
        this.setState({ consumerId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
        if (selectedOption != null && selectedOption.selectedItem != null) {
            this.setState({ legalName: selectedOption.selectedItem.name, name: selectedOption.selectedItem.name })
        }
    }

    async handleCustomerTypeChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ customerType: Number(event.target.value), isConsumerEnabled: 1 });

        this.state.errors["customerType"] = '';
        this.state.errors["consumerId"] = '';

        if (Number(event.target.value) > 0) {
            if (Number(event.target.value) === 1) {
                await this.getConsumer(Number(event.target.value));
            }
            else if (Number(event.target.value) === 5) {
                await this.getConsumer(Number(event.target.value));
            }
            else if (Number(event.target.value) === 14) {
                await this.getConsumer(Number(event.target.value));
            }
            else if (Number(event.target.value) === 15) {
                await this.getConsumer(Number(event.target.value));
            }
            else if (Number(event.target.value) === 16) {
                await this.getConsumer(Number(event.target.value));
            }
            else {
                this.setState({
                    dropdownConsumer: [], isConsumerEnabled: 0
                });
            }
        } else {
            this.setState({
                dropdownConsumer: [], isConsumerEnabled: 0
            });
        }
    }
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        
        if (name == "accountCode") {
            const re = /^[0-9a-zA-Z]+$/;
            if (event.target.value === '' || re.test(event.target.value)) {
                this.setState({ accountCode: event.target.value })
                this.state.errors[name] = "";
            }
            else {
                this.state.errors[name] = "Please enter valid account code";
            }
        }
        else if (name == "mobile1") {
            if (event.target.value.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length > 0) {
                if (event.target.value.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length >= 10) {
                    this.setState({ mobile1: event.target.value })
                    this.state.errors[name] = "";
                }
                else {
                    this.state.errors[name] = "Please enter valid mobile no.";
                }
            }
            else {
                this.setState({ mobile1: event.target.value })
                this.state.errors[name] = "";
            }
        }
        else if (name == "mobile2") {
            if (event.target.value.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length > 0) {
                if (event.target.value.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length >= 10) {
                    this.setState({ mobile2: event.target.value })
                    this.state.errors[name] = "";
                }
                else {
                    this.state.errors[name] = "Please enter valid mobile no.";
                }
            }
            else {
                this.setState({ mobile2: event.target.value })
                this.state.errors[name] = "";
            }
        }
        else if (name == "mobile3") {
            if (event.target.value.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length > 0) {
                if (event.target.value.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length >= 10) {
                    this.setState({ mobile3: event.target.value })
                    this.state.errors[name] = "";
                }
                else {
                    this.state.errors[name] = "Please enter valid mobile no.";
                }
            }
            else {
                this.setState({ mobile3: event.target.value })
                this.state.errors[name] = "";
            }
        }
        else if (name == "mobile4") {
            if (event.target.value.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length > 0) {
                if (event.target.value.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length >= 10) {
                    this.setState({ mobile4: event.target.value })
                    this.state.errors[name] = "";
                }
                else {
                    this.state.errors[name] = "Please enter valid mobile no.";
                }
            }
            else {
                this.setState({ mobile4: event.target.value })
                this.state.errors[name] = "";
            }
        }
        else if (name == "mobile5") {
            if (event.target.value.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length > 0) {
                if (event.target.value.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length >= 10) {
                    this.setState({ mobile5: event.target.value })
                    this.state.errors[name] = "";
                }
                else {
                    this.state.errors[name] = "Please enter valid mobile no.";
                }
            }
            else {
                this.setState({ mobile5: event.target.value })
                this.state.errors[name] = "";
            }
        }
        else {
            this.setState({
                [name]: value
            });
        }
    }
    handleFormValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.customerType = "";
        errors.accountCode = "";
        errors.consumerId = "";
        errors.legalName = "";
        errors.name = "";
        errors.userName = "";
        errors.password = "";
        errors.firstName = "";
        errors.rateTypeId = "";
        errors.billTo = "";
        errors.email1 = "";
        errors.email2 = "";
        errors.email3 = "";
        errors.phone = "";
        errors.fax = "";
        errors.webAddress = "";

        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let regNumber = /^[0-9\b]+$/;
        let regWebAddress = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

        if (this.state.customerType === 0) {
            formIsValid = false;
            errors.customerType = "Please select customer type.";
        }
        if (this.state.customerType === 1) {
            if (this.state.consumerId === 0) {
                formIsValid = false;
                errors.consumerId = "Please select airline.";
            }
        }
        if (this.state.customerType === 5) {
            if (this.state.consumerId === 0) {
                formIsValid = false;
                errors.consumerId = "Please select hotel.";
            }
        }
        if (this.state.customerType === 14) {
            if (this.state.consumerId === 0) {
                formIsValid = false;
                errors.consumerId = "Please select school.";
            }
        }
        if (this.state.customerType === 15) {
            if (this.state.consumerId === 0) {
                formIsValid = false;
                errors.consumerId = "Please select hospital.";
            }
        }
        if (this.state.customerType === 16) {
            if (this.state.consumerId === 0) {
                formIsValid = false;
                errors.consumerId = "Please select church.";
            }
        }
        if (this.state.legalName === "") {
            formIsValid = false;
            errors.legalName = "please enter account legal name.";
        }
        if (this.state.accountCode === "") {
            formIsValid = false;
            errors.accountCode = "Please enter account code.";
        }
        if (this.state.name === "") {
            formIsValid = false;
            errors.name = "Please enter user person.";
        }
        if (this.state.userName === "") {
            formIsValid = false;
            errors.userName = "Please enter username.";
        }
        if (this.state.rowId == 0 && this.state.password.trim() != '' && this.state.vIsPasswordComplexity == true) {
            const renumbersymbolexp = /[^0-9]/g;
            const lowercaseexp = /[^a-z]/g;
            const uppercaseexp = /[^A-Z]/g;
            const specialcharacters = /[^!@#$%^&*]/g;

            var IsPwdnumbersymbol = this.state.password.replace(renumbersymbolexp, "").length;
            var IsLowercase = this.state.password.replace(lowercaseexp, "").length;
            var IsUppercase = this.state.password.replace(uppercaseexp, "").length;
            var IsSpecialCharacters = this.state.password.replace(specialcharacters, "").length;

            if (this.state.password.length < this.state.vMinLength) {
                formIsValid = false;
                errors.password = "Password must be " + this.state.vMinLength + " characters long";
            }
            else if (IsPwdnumbersymbol < this.state.vMinNumber) {
                formIsValid = false;
                errors.password = "Password must contains atleast " + this.state.vMinNumber + " number";
            }
            else if (IsLowercase < this.state.vMinLowerCaseLetter) {
                formIsValid = false;
                errors.password = "Password must contains atleast " + this.state.vMinLowerCaseLetter + " lowercase alphabet";
            }
            else if (IsUppercase < this.state.vMinUpperCaseLetter) {
                formIsValid = false;
                errors.password = "Password must contains atleast " + this.state.vMinUpperCaseLetter + " uppercase alphabet";
            }
            else if (IsSpecialCharacters < this.state.vMinSpecialCharacter) {
                formIsValid = false;
                errors.password = "Password must contains atleast " + this.state.vMinSpecialCharacter + " special character";
            }
        }
        if (this.state.firstName === "") {
            formIsValid = false;
            errors.firstName = "Please enter first name.";
        }
        if (this.state.rateTypeId === 0) {
            formIsValid = false;
            errors.rateTypeId = "Please select rate type.";
        }
        if (this.state.billTo === 0) {
            formIsValid = false;
            errors.billTo = "Please select bill to user.";
        }        
        if (this.state.mobile2.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length > 0) {
            if (this.state.mobile1.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length != 10) {
                formIsValid = false;
                errors.mobile1 = "Invalid  mobile number";
            }
        }
        if (this.state.mobile2.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length > 0) {
            if (this.state.mobile2.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length != 10) {
                formIsValid = false;
                errors.mobile2 = "Invalid  mobile number";
            }
        }
        if (this.state.mobile3.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length > 0) {
            if (this.state.mobile3.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length != 10) {
                formIsValid = false;
                errors.mobile3 = "Invalid  mobile number";
            }
        }
        if (this.state.mobile4.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length > 0) {
            if (this.state.mobile4.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length != 10) {
                formIsValid = false;
                errors.mobile4 = "Invalid  mobile number";
            }
        }
        if (this.state.mobile5.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length > 0) {
            if (this.state.mobile5.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length != 10) {
                formIsValid = false;
                errors.mobile5 = "Invalid  mobile number";
            }
        }
        //if (this.state.email1 === "") {
        //    formIsValid = false;
        //    errors.email1 = "Please enter email 1.";
        //}
        //if (this.state.email1 !== "") {
        //    if (!regEmail.test(this.state.email1)) {
        //        formIsValid = false;
        //        errors.email1 = "Please enter valid email 1.";
        //    }
        //}
        //if (this.state.email2 !== "") {
        //    if (!regEmail.test(this.state.email2)) {
        //        formIsValid = false;
        //        errors.email2 = "Please enter valid email 2.";
        //    }
        //}
        //if (this.state.email3 !== "") {
        //    if (!regEmail.test(this.state.email3)) {
        //        formIsValid = false;
        //        errors.email3 = "Please enter valid email 3.";
        //    }
        //}

        if (this.state.phone === "") {
            formIsValid = false;
            errors.phone = "Please enter phone number.";
        }
        else if (this.state.phone.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length != 10) {
            formIsValid = false;
            errors.phone = "Invalid phone number";
        }

        if (this.state.fax !== "") {
            //if (regNumber.test(this.state.fax)) {
            //    formIsValid = false;
            //    errors.fax = "Please enter valid fax number.";
            //}
            //else
            if (this.state.fax.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length != 10) {
                formIsValid = false;
                errors.fax = "Invalid fax number.";
            }
        }

        if (this.state.webAddress !== "") {
            if (!regWebAddress.test(this.state.webAddress)) {
                formIsValid = false;
                errors.webAddress = "Please enter valid web address.";
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    async handleAddEditCustomerSubmit(e) {
        e.preventDefault();
        if (this.handleFormValidation()) {
            var addEditRequest = new Object();
            addEditRequest.Id = this.state.rowId > 0 ? parseInt(this.state.rowId) : 0;
            addEditRequest.ModuleId = parseInt(this.state.customerType) === parseInt(CustomerType.AirlineCrew) ? parseInt(ModuleCode.CREW) : parseInt(ModuleCode.CUSTOMER);
            addEditRequest.AccountCode = this.state.accountCode;
            addEditRequest.AccountName = this.state.name;
            addEditRequest.ContactPerson = this.state.contactPerson;
            addEditRequest.WebAddress = this.state.webAddress;
            addEditRequest.CityId = 0;
            addEditRequest.CityName = "";
            addEditRequest.StateName = "";
            addEditRequest.CountryName = "";
            addEditRequest.Mobile = "";
            addEditRequest.PhoneNo = this.state.phone;
            addEditRequest.UserName = this.state.userName;
            addEditRequest.Password = this.state.password;
            addEditRequest.FirstName = this.state.firstName;
            addEditRequest.LastName = this.state.lastName;
            addEditRequest.Email = "";
            addEditRequest.LegalName = this.state.legalName;
            addEditRequest.SiteName = "";
            addEditRequest.SiteUrl = "";
            addEditRequest.SiteCategoryType = 0;
            addEditRequest.AirlineId = parseInt(this.state.customerType) === parseInt(CustomerType.AirlineCrew) ? parseInt(this.state.consumerId) : 0;
            addEditRequest.ConsumerId = parseInt(this.state.consumerId);
            addEditRequest.Address1 = this.state.address;
            addEditRequest.Address2 = "";
            addEditRequest.ZipCode = "";
            addEditRequest.PinNo = "";
            addEditRequest.TimeZone = 0;
            addEditRequest.CurrencyId = 0;
            addEditRequest.NoOfYrInOperation = 0;
            addEditRequest.PassLastYear = 0;
            addEditRequest.NoOfPassengerToUsa = 0;
            addEditRequest.NoOfPassengerToRow = 0;
            addEditRequest.NoOfTerminal = 0;
            addEditRequest.PaymentMode = 1;
            addEditRequest.ConstitutionTypeId = 0;
            addEditRequest.Enable = this.state.isActive;
            addEditRequest.IsServiceApiProvided = false;
            addEditRequest.TemplateTypeName = "ACCOUNT REGISTRATION";
            addEditRequest.NotificationSubject = "Customer Account Registered";
            addEditRequest.CustomerTypeId = parseInt(this.state.customerType);
            addEditRequest.RateType = parseInt(this.state.rateTypeId);
            addEditRequest.BillToCustomerId = parseInt(this.state.billTo) > 0 ? parseInt(this.state.billTo) : -1;
            addEditRequest.ParentCustomerId = -1;
            addEditRequest.FaxNo = this.state.fax;
            addEditRequest.CountryCode = "";
            addEditRequest.AreaCode = "";
            addEditRequest.SkinId = 0;
            addEditRequest.LogoSize = 0;
            addEditRequest.Logo = "";
            addEditRequest.LogoName = "";
            addEditRequest.LogoExt = "";
            addEditRequest.ReportDisplayName = "";
            addEditRequest.ReportHeader = "";
            addEditRequest.ReportFooter = "";
            addEditRequest.Notes = "";
            addEditRequest.SpecialInstruction = "";
            addEditRequest.ReportImage = null;
            addEditRequest.IsPriority = this.state.priority;
            addEditRequest.Rating = this.state.ratingId != "" ? (this.state.ratingId).toString() : "0";
            addEditRequest.VipP1 = this.state.contactPerson1;
            addEditRequest.VipP2 = this.state.contactPerson2;
            addEditRequest.VipP3 = this.state.contactPerson3;
            addEditRequest.VipP4 = this.state.contactPerson4;
            addEditRequest.VipP5 = this.state.contactPerson5;
            addEditRequest.VipP6 = "";
            addEditRequest.VipT1 = this.state.designation1;
            addEditRequest.VipT2 = this.state.designation2;
            addEditRequest.VipT3 = this.state.designation3;
            addEditRequest.VipT4 = this.state.designation4;
            addEditRequest.VipT5 = this.state.designation5;
            addEditRequest.VipT6 = "";
            addEditRequest.PlanId = 0;
            addEditRequest.IsAllowMessage = false;
            addEditRequest.IsValidateGeoFence = false;
            addEditRequest.LocationEmail = [];

            if (this.state.email1.trim() != "") {
                let email1 = {
                    DepartmentTypeId: 22,
                    NotificationTypeId: 1,
                    Notification: decodeURIComponent(this.state.email1),
                };
                addEditRequest.LocationEmail.push(email1);
            }
            if (this.state.email2.trim() != "") {
                let email2 = {
                    DepartmentTypeId: 22,
                    NotificationTypeId: 1,
                    Notification: decodeURIComponent(this.state.email2),
                };
                addEditRequest.LocationEmail.push(email2);
            }
            if (this.state.email3.trim() != "") {
                let email3 = {
                    DepartmentTypeId: 22,
                    NotificationTypeId: 1,
                    Notification: decodeURIComponent(this.state.email3),
                };
                addEditRequest.LocationEmail.push(email3);
            }
            if (this.state.email4.trim() != "") {
                let email4 = {
                    DepartmentTypeId: 22,
                    NotificationTypeId: 1,
                    Notification: decodeURIComponent(this.state.email4),
                };
                addEditRequest.LocationEmail.push(email4);
            }
            if (this.state.email5.trim() != "") {
                let email5 = {
                    DepartmentTypeId: 22,
                    NotificationTypeId: 1,
                    Notification: decodeURIComponent(this.state.email5),
                };
                addEditRequest.LocationEmail.push(email5);
            }

            if (this.state.mobile1.trim() != "") {
                let mobile1 = {
                    DepartmentTypeId: 22,
                    NotificationTypeId: 6,
                    Notification: decodeURIComponent(this.state.mobile1),
                };
                addEditRequest.LocationEmail.push(mobile1);
            }
            if (this.state.mobile2.trim() != "") {
                let mobile2 = {
                    DepartmentTypeId: 22,
                    NotificationTypeId: 6,
                    Notification: decodeURIComponent(this.state.mobile2),
                };
                addEditRequest.LocationEmail.push(mobile2);
            }
            if (this.state.mobile3.trim() != "") {
                let mobile3 = {
                    DepartmentTypeId: 22,
                    NotificationTypeId: 6,
                    Notification: decodeURIComponent(this.state.mobile3),
                };
                addEditRequest.LocationEmail.push(mobile3);
            }
            if (this.state.mobile4.trim() != "") {
                let mobile4 = {
                    DepartmentTypeId: 22,
                    NotificationTypeId: 6,
                    Notification: decodeURIComponent(this.state.mobile4),
                };
                addEditRequest.LocationEmail.push(mobile4);
            }
            if (this.state.mobile5.trim() != "") {
                let mobile5 = {
                    DepartmentTypeId: 22,
                    NotificationTypeId: 6,
                    Notification: decodeURIComponent(this.state.mobile5),
                };
                addEditRequest.LocationEmail.push(mobile5);
            }

            this.setState({ isSubmited: true })
            const requestParams = getRequestParams('POST', addEditRequest);
            const response = await fetch('customer-api/add-update', requestParams);
            const data = await response.json();
            Swal.fire({
                icon: data.isSuccess ? 'success' : 'error',
                title: data.isSuccess ? 'Success' : 'Oops...',
                text: data.resultMessage,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (data.isSuccess) {
                    if (result.isConfirmed) {
                        this.closeAddEditCustomerModal();
                        this.getCustomerList();
                    }
                } else {
                    this.setState({ isSubmited: false })
                }
            })
        }
        else {
            this.setState({ isSubmited: false })
        }
    }

    /**
     * Customer Setting Changes
     * @param {any} e
     * @param {any} data
     */
    openCustomerSettingModal(e, data) {
        e.preventDefault();
        this.refs.customerSettingModal.showModal(data);
    }
    addContactPerson(e) {
        e.preventDefault();
        let rowCount = this.state.contactPersonCount;
        rowCount = rowCount + 1;
        var element = document.getElementById("row_" + rowCount);
        if (element != null) {
            this.setState({ contactPersonCount: rowCount });
            if (rowCount > 1) {
                this.setState({ isRemoveButton: false });
            }
            if (rowCount >= 5) {
                this.setState({ isAddButton: true });
            }
            element.classList.remove("displayNone");
            element.classList.add("displayBlock");
        }
    }

    removeContactPerson(e) {
        e.preventDefault();
        let rowCount = this.state.contactPersonCount;
        var element = document.getElementById("row_" + rowCount);
        if (element != null || rowCount > 1) {
            rowCount = rowCount - 1;
            if (rowCount == 1) {
                this.setState({ contactPersonCount: rowCount, isRemoveButton: true });
            }
            else {
                this.setState({ contactPersonCount: rowCount, isRemoveButton: false});
            }

            if (rowCount < 5) {
                this.setState({ isAddButton: false });
            }
            else {
                this.setState({ isAddButton: true });
            }

            element.classList.remove("displayBlock");
            element.classList.add("displayNone");
        }
    }
    handleViewSetCommission(e, data) {
        e.preventDefault();
        this.refs.customerVehicleTypeCommissionSettingComponent.handleShowModal(Number(data.accountId), data.accountName);
    }
    render() {
        const modelHeader = {}
        const customerModalTitle = this.state.rowId > 0 ? "Modify Customer" : "Add Customer";
        const renderCustomerEditCell = (data) => {
            return (
                <div style={{ textAlign: 'left', width: 'auto' }}>
                    <a title="Edit" onClick={(e) => this.AddEditCustomerControl(e, data.data)}><i className="fas fa-pen icon text-info"></i></a>
                    <a title="Customer Setting" style={{ paddingLeft: '6px' }} onClick={(e) => { this.openCustomerSettingModal(e, data.data) }} ><i className="fas fa-user-cog text-info"></i></a>
                    <a title="Set Commission" style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleViewSetCommission(e, data.data) }} ><i className="fas fa-dollar-sign text-info"></i></a>
                </div>
            );
        }
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-sm-6 col-md-6 col-lg-6">
                                <h1 className="m-0 text-dark">Customer</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <ol className="breadcrumb float-sm-right">
                                        <NavItem><NavLink tag={Link} to="/customer/bulk" className="btn btn-block btn-outline-secondary btn-sm" title="Import Customer"><i className="fas fa-upload"></i> Import Customers</NavLink></NavItem>
                                    </ol>
                                    <ol className="breadcrumb float-sm-right">
                                        <NavItem><NavLink tag={Link} to="#" onClick={(e) => { this.AddEditCustomerControl(e, null) }} className="btn btn-block btn-outline-secondary btn-sm" title="Add Customer"><i className="fas fa-plus"></i> Add Customer</NavLink></NavItem>
                                    </ol>                                    
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="card card-grid">
                        <div className="card-body">
                            <DataGrid
                                dataSource={this.state.customerList}
                                keyExpr="userId"
                                selection={{ mode: 'single' }}
                                columnsAutoWidth={false}
                                showColumnLines={false}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                showBorders={true}>
                                <Column dataField="userId" width={50} caption="" allowFiltering={false} allowSorting={false} cellRender={renderCustomerEditCell} />
                                <Column dataField="accountCode" width={200} caption="Account Code" allowFiltering={true} allowSorting={true} />
                                <Column dataField="accountName" caption="Account Name" width={200} allowFiltering={true} allowSorting={true} />
                                <Column dataField="customerTypeName" caption="Customer Type Name" width={200} allowFiltering={true} allowSorting={true} />
                                <Column dataField="cityName" caption="City" width={200} allowFiltering={true} allowSorting={true} />
                                <Column dataField="stateName" caption="State" allowFiltering={true} allowSorting={true} width={200} />
                                <Column dataField="zipCode" caption="Zip Code" allowFiltering={true} allowSorting={true} width={200} />
                                <Column dataField="createdByName" caption="Created By" width={200} allowFiltering={true} allowSorting={true} />
                                <Column dataField="createdDateStr" caption="Created Date" width={200} allowFiltering={true} allowSorting={true} />
                                <Column dataField="allowMessage" caption="Message Applicable" width={200} visible={false} />
                                <Column dataField="validateGeofence" caption="Validate Geofence" width={200} visible={false} />

                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={false} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                <Export enabled={true} allowExportSelectedData={false} />
                                <ColumnChooser enabled={true} />
                                <ColumnFixing enabled={true} />

                            </DataGrid>
                        </div>
                    </div>
                    <Modal ariaHideApp={false}
                        isOpen={this.state.customerSettingModalIsOpen}
                        className={"react-modal add-customer-modal"}
                        onAfterOpen={this.OpenAddEditCutomerDataModal}
                        onRequestClose={this.closeAddEditCustomerModal}
                        shouldCloseOnOverlayClick={false}
                        contentLabel="Example Modal"
                    >
                        <form method="post" onSubmit={this.handleAddEditCustomerSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                            <div className="modal-header">
                                <h6 className="modal-title">{customerModalTitle}</h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeAddEditCustomerModal}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body modal-overflow">
                                <div className="row">
                                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <label>Type <span className="text-red">*</span></label>
                                            <select className="form-control form-control-sm" name="customerType" placeholder="Customer Type" title="Customer Type" value={this.state.customerType} onChange={this.handleCustomerTypeChange}>
                                                <option value={0}>Select customer type</option>
                                                {this.state.customerTypeList && this.state.customerTypeList.map((item, i) =>
                                                    <option key={i} value={item.id}>{item.name}</option>)
                                                }
                                            </select>
                                            {this.state.errors["customerType"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerType"]}</span>}
                                        </div>
                                    </div>
                                    {
                                        this.state.isConsumerEnabled == 1 ?
                                            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                                <div className="form-group" >
                                                    <label>Consumer</label>
                                                    <SelectBox
                                                        dataSource={this.state.dropdownConsumer.length > 0 ? this.state.dropdownConsumer : []}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        placeholder="Select consumer"
                                                        name="consumerId"
                                                        defaultValue={0}
                                                        value={this.state.consumerId}
                                                        displayExpr='displayName'
                                                        valueExpr='id'
                                                        onSelectionChanged={this.handleConsumerChange}
                                                        showClearButton={true}
                                                        disabled={false}
                                                        searchEnabled={true}
                                                    />
                                                    {this.state.errors["consumerId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["consumerId"]}</span>}
                                                </div>
                                            </div> : null
                                    }
                                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <label>Legal Name <span className="text-red">*</span></label>
                                            <input type="text" className="form-control form-control-sm" name="legalName" placeholder="Enter Legal Name" maxLength="50" title="Legal Name" value={this.state.legalName} onChange={this.handleInputChange} disabled={this.state.rowId > 0 ? true : false} />
                                            {this.state.errors["legalName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["legalName"]}</span>}
                                        </div>
                                    </div>
                                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <label>Account Name <span className="text-red">*</span></label>
                                            <input type="text" className="form-control form-control-sm" name="name" placeholder="Enter name" maxLength="50" title="Name" value={this.state.name} onChange={this.handleInputChange} />
                                            {this.state.errors["name"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["name"]}</span>}
                                        </div>
                                    </div>
                                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <label>Account Code <span className="text-red">*</span></label>
                                            <input type="text" className="form-control form-control-sm" name="accountCode" placeholder="Enter account code" maxLength="50" title="Account code" value={this.state.accountCode} onChange={this.handleInputChange} />
                                            {this.state.errors["accountCode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["accountCode"]}</span>}
                                        </div>
                                    </div>
                                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <label>Username <span className="text-red">*</span></label>
                                            <input type="text" className="form-control form-control-sm" name="userName" placeholder="Enter userame" maxLength="50" title="User Name" value={this.state.userName} onChange={this.handleInputChange} disabled={this.state.rowId > 0 ? true : false} />
                                            {this.state.errors["userName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["userName"]}</span>}
                                        </div>
                                    </div>
                                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                        <div className="form-group" style={{ display: this.state.rowId > 0 ? 'none' : 'block' }}>
                                            <label htmlFor="">Password  <span className="text-danger">*</span><i className="fas fa-info-circle text-info" data-tip data-for="passwordTooltip"></i></label>
                                            <input type="password" className="form-control form-control-sm" name="password" autoComplete="new-password" disabled={this.state.rowId > 0} placeholder="Enter password" maxLength="25" title="Password" value={this.state.password} onChange={this.handleInputChange} />
                                            {this.state.errors["password"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["password"]}</span>}
                                            <ReactTooltip id="passwordTooltip" place="top" effect="solid">
                                                <div className="form-group">
                                                    <ul>
                                                        <li>At least {this.state.vMinLength} characters long</li>
                                                        <li>At least {this.state.vMinLowerCaseLetter} lowercase character</li>
                                                        <li>At least {this.state.vMinUpperCaseLetter} uppercase character</li>
                                                        <li>At least {this.state.vMinNumber} number, symbol, or whitespace character</li>
                                                        <li>At least {this.state.vMinSpecialCharacter} special character</li>
                                                    </ul>
                                                </div>
                                            </ReactTooltip>
                                        </div>
                                    </div>
                                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <label>First Name <span className="text-red">*</span></label>
                                            <input type="text" className="form-control form-control-sm" name="firstName" placeholder="Enter first name" maxLength="50" title="First Name" value={this.state.firstName} onChange={this.handleInputChange} disabled={this.state.rowId > 0 ? true : false} />
                                            {this.state.errors["firstName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["firstName"]}</span>}
                                        </div>

                                    </div>
                                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <label>Last Name</label>
                                            <input type="text" className="form-control form-control-sm" name="lastName" placeholder="Enter last name" maxLength="50" title="Last Name" value={this.state.lastName} onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <label>Contact Person</label>
                                            <input type="text" className="form-control form-control-sm" name="contactPerson" placeholder="Enter contact person name" maxLength="250" title="Contact Person" value={this.state.contactPerson} onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <label>Address</label>
                                            <textarea className="form-control form-control-sm" name="address" placeholder="Enter address" maxLength="250" title="Address" value={this.state.address} onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label>Rate Type <span className="text-red">*</span></label>
                                            <select className="form-control form-control-sm" name="rateTypeId" placeholder="Select rate type" title="Rate Type" value={this.state.rateTypeId} onChange={this.handleInputChange}>
                                                <option value={0}>Select rate type</option>
                                                {
                                                    this.state.rateTypeList && this.state.rateTypeList.map((item, i) =>
                                                        <option key={i} value={item.id}>{item.name}</option>)
                                                }
                                            </select>
                                            {this.state.errors["rateTypeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["rateTypeId"]}</span>}
                                        </div>
                                    </div>
                                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label>Bill To <span className="text-red">*</span></label>
                                            <SelectBox
                                                dataSource={this.state.customerBillToList.length > 0 ? this.state.customerBillToList : []}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select bill to"
                                                name="billTo"
                                                defaultValue={0}
                                                value={this.state.billTo}
                                                displayExpr='accountName'
                                                valueExpr='id'
                                                onSelectionChanged={this.handleBillToInputChange}
                                                showClearButton={true}
                                                disabled={false}
                                                searchEnabled={true}
                                            />
                                            {this.state.errors["billTo"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["billTo"]}</span>}
                                        </div>
                                    </div>
                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <label>Rating </label>
                                            <select className="form-control form-control-sm" name="ratingId" placeholder="Enter rating" title="Rating" value={this.state.ratingId} onChange={this.handleInputChange}>
                                                {
                                                    this.state.ratingList && this.state.ratingList.map((item, i) =>
                                                        <option key={i} value={item.id}>{item.name}</option>)
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <label>Phone <span className="text-red">*</span></label>
                                            <NumberFormat className="form-control form-control-sm" name="phone" title="Phone" placeholder="(000) 000-0000" format="(###) ###-####" value={this.state.phone} onChange={this.handleInputChange} />
                                            {this.state.errors["phone"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["phone"]}</span>}
                                        </div>
                                    </div>
                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <label>Fax </label>
                                            <NumberFormat className="form-control form-control-sm" name="fax" placeholder="(000) 000-0000" format="(###) ###-####" title="Fax" value={this.state.fax} onChange={this.handleInputChange} />
                                            {this.state.errors["fax"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["fax"]}</span>}
                                        </div>
                                    </div>
                                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <label>Web Address</label>
                                            <input type="text" className="form-control form-control-sm" name="webAddress" placeholder="Enter web address" maxLength="250" title="Web Address" value={this.state.webAddress} onChange={this.handleInputChange} />
                                            {this.state.errors["webAddress"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["webAddress"]}</span>}
                                        </div>
                                    </div>
                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <br />
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="Priority" name="priority" checked={this.state.priority} onChange={this.handleInputChange} />
                                                <label className="form-check-label" htmlFor="Priority"> Priority </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <br />
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="IsActive" name="isActive" checked={this.state.isActive} onChange={this.handleInputChange} />
                                                <label className="form-check-label" htmlFor="IsActive"> Is Active </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                        <button type="submit" className="btn btn-custom-gray btn-sm" onClick={this.addContactPerson} disabled={this.state.isAddButton}><i className="fas fa-plus" aria-hidden="true" title="Add Contact Person"></i></button>&nbsp;&nbsp;
                                        <button type="submit" className="btn btn-custom-gray btn-sm" onClick={this.removeContactPerson} disabled={this.state.isRemoveButton}><i className="fas fa-minus" aria-hidden="true" title="Remove Contact Person"></i></button>
                                    </div>
                                </div>
                                <div className="row" id="row_1">
                                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <label>1. Contact Person</label>
                                            <input type="text" className="form-control form-control-sm" name="contactPerson1" placeholder="Enter Contact Person Name" maxLength="50" title="Contact Person 1" value={this.state.contactPerson1} onChange={this.handleInputChange} />
                                            {this.state.errors["contactPerson1"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["contactPerson1"]}</span>}
                                        </div>
                                    </div>
                                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label>Designation</label>
                                            <input type="text" className="form-control form-control-sm" name="designation1" placeholder="Enter designation" maxLength="50" title="Designation 1" value={this.state.designation1} onChange={this.handleInputChange} />
                                            {this.state.errors["designation1"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["designation1"]}</span>}
                                        </div>
                                    </div>
                                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input type="text" className="form-control form-control-sm" name="email1" placeholder="Enter email" maxLength="50" title="Email 1" value={this.state.email1} onChange={this.handleInputChange} />
                                            {this.state.errors["email1"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["email1"]}</span>}
                                        </div>
                                    </div>
                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <label>Mobile</label>
                                            {/*<input type="text" className="form-control form-control-sm" name="mobile1" placeholder="Enter mobile" maxLength="50" title="Mobile 1" value={this.state.mobile1} onChange={this.handleInputChange} />*/}
                                            <NumberFormat className="form-control form-control-sm" id="mobile1" name="mobile1" title="Mobile 1" value={this.state.mobile1} onChange={this.handleInputChange} placeholder="(000) 000-0000" format="(###) ###-####" />
                                            {this.state.errors["mobile1"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["mobile1"]}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="displayNone" id="row_2">
                                    <div className="row">
                                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                            <div className="form-group">
                                                <label>2. Contact Person</label>
                                                <input type="text" className="form-control form-control-sm" name="contactPerson2" placeholder="Enter Contact Person Name" maxLength="50" title="Contact Person 2" value={this.state.contactPerson2} onChange={this.handleInputChange} />
                                                {this.state.errors["contactPerson2"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["contactPerson2"]}</span>}
                                            </div>
                                        </div>
                                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                            <div className="form-group">
                                                <label>Designation</label>
                                                <input type="text" className="form-control form-control-sm" name="designation2" placeholder="Enter designation" maxLength="50" title="Designation 2" value={this.state.designation2} onChange={this.handleInputChange} />
                                                {this.state.errors["designation2"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["designation2"]}</span>}
                                            </div>
                                        </div>
                                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input type="text" className="form-control form-control-sm" name="email2" placeholder="Enter email" maxLength="50" title="Email 2" value={this.state.email2} onChange={this.handleInputChange} />
                                                {this.state.errors["email2"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["email2"]}</span>}
                                            </div>
                                        </div>
                                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                            <div className="form-group">
                                                <label>Mobile</label>
                                                {/*<input type="text" className="form-control form-control-sm" name="mobile2" placeholder="Enter mobile" maxLength="50" title="Mobile 2" value={this.state.mobile2} onChange={this.handleInputChange} />*/}
                                                <NumberFormat className="form-control form-control-sm" id="mobile2" name="mobile2" title="Mobile 2" value={this.state.mobile2} onChange={this.handleInputChange} placeholder="(000) 000-0000" format="(###) ###-####" />
                                                {this.state.errors["mobile2"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["mobile2"]}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="displayNone" id="row_3">
                                    <div className="row">
                                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                            <div className="form-group">
                                                <label>3. Contact Person</label>
                                                <input type="text" className="form-control form-control-sm" name="contactPerson3" placeholder="Enter Contact Person Name" maxLength="50" title="Contact Person 3" value={this.state.contactPerson3} onChange={this.handleInputChange} />
                                                {this.state.errors["contactPerson3"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["contactPerson3"]}</span>}
                                            </div>
                                        </div>
                                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                            <div className="form-group">
                                                <label>Designation</label>
                                                <input type="text" className="form-control form-control-sm" name="designation3" placeholder="Enter designation" maxLength="50" title="Designation 3" value={this.state.designation3} onChange={this.handleInputChange} />
                                                {this.state.errors["designation3"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["designation3"]}</span>}
                                            </div>
                                        </div>
                                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input type="text" className="form-control form-control-sm" name="email3" placeholder="Enter email" maxLength="50" title="Email 3" value={this.state.email3} onChange={this.handleInputChange} />
                                                {this.state.errors["email3"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["email3"]}</span>}
                                            </div>
                                        </div>
                                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                            <div className="form-group">
                                                <label>Mobile</label>
                                                {/*<input type="text" className="form-control form-control-sm" name="mobile3" placeholder="Enter mobile" maxLength="50" title="Mobile 3" value={this.state.mobile3} onChange={this.handleInputChange} />*/}
                                                <NumberFormat className="form-control form-control-sm" id="mobile3" name="mobile3" title="Mobile 3" value={this.state.mobile3} onChange={this.handleInputChange} placeholder="(000) 000-0000" format="(###) ###-####" />
                                                {this.state.errors["mobile3"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["mobile3"]}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="displayNone" id="row_4">
                                    <div className="row">
                                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                            <div className="form-group">
                                                <label>4. Contact Person</label>
                                                <input type="text" className="form-control form-control-sm" name="contactPerson4" placeholder="Enter Contact Person Name" maxLength="50" title="Contact Person 4" value={this.state.contactPerson4} onChange={this.handleInputChange} />
                                                {this.state.errors["contactPerson4"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["contactPerson4"]}</span>}
                                            </div>
                                        </div>
                                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                            <div className="form-group">
                                                <label>Designation</label>
                                                <input type="text" className="form-control form-control-sm" name="designation4" placeholder="Enter designation" maxLength="50" title="Designation 4" value={this.state.designation4} onChange={this.handleInputChange} />
                                                {this.state.errors["designation4"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["designation4"]}</span>}
                                            </div>
                                        </div>
                                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input type="text" className="form-control form-control-sm" name="email4" placeholder="Enter email" maxLength="50" title="Email 4" value={this.state.email4} onChange={this.handleInputChange} />
                                                {this.state.errors["email4"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["email4"]}</span>}
                                            </div>
                                        </div>
                                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                            <div className="form-group">
                                                <label>Mobile</label>
                                                {/*<input type="text" className="form-control form-control-sm" name="mobile4" placeholder="Enter mobile" maxLength="50" title="Mobile 4" value={this.state.mobile4} onChange={this.handleInputChange} />*/}
                                                <NumberFormat className="form-control form-control-sm" id="mobile4" name="mobile4" title="Mobile 4" value={this.state.mobile4} onChange={this.handleInputChange} placeholder="(000) 000-0000" format="(###) ###-####" />
                                                {this.state.errors["mobile4"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["mobile4"]}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="displayNone" id="row_5">
                                    <div className="row">
                                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                            <div className="form-group">
                                                <label>5. Contact Person</label>
                                                <input type="text" className="form-control form-control-sm" name="contactPerson5" placeholder="Enter Contact Person Name" maxLength="50" title="Contact Person 5" value={this.state.contactPerson5} onChange={this.handleInputChange} />
                                                {this.state.errors["contactPerson5"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["contactPerson5"]}</span>}
                                            </div>
                                        </div>
                                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                            <div className="form-group">
                                                <label>Designation</label>
                                                <input type="text" className="form-control form-control-sm" name="designation5" placeholder="Enter designation" maxLength="50" title="Designation" value={this.state.designation5} onChange={this.handleInputChange} />
                                                {this.state.errors["designation5"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["designation5"]}</span>}
                                            </div>
                                        </div>
                                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input type="text" className="form-control form-control-sm" name="email5" placeholder="Enter email" maxLength="50" title="Email 5" value={this.state.email5} onChange={this.handleInputChange} />
                                                {this.state.errors["email5"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["email5"]}</span>}
                                            </div>
                                        </div>
                                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                            <div className="form-group">
                                                <label>Mobile</label>
                                                {/*<input type="text" className="form-control form-control-sm" name="mobile5" placeholder="Enter mobile" maxLength="50" title="Mobile 5" value={this.state.mobile5} onChange={this.handleInputChange} />*/}
                                                <NumberFormat className="form-control form-control-sm" id="mobile5" name="mobile5" title="Mobile 5" value={this.state.mobile5} onChange={this.handleInputChange} placeholder="(000) 000-0000" format="(###) ###-####" />
                                                {this.state.errors["mobile5"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["mobile5"]}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-success btn-sm" onClick={this.handleAddEditCustomerSubmit} >
                                    {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                    {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                                </button>
                                <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeAddEditCustomerModal} title="Close"><i className="fas fa-times"></i> Close</button>
                            </div>
                        </form>
                    </Modal>
                    <CustomerSettingComponent ref="customerSettingModal" onCustomerSettingClose={this.getCustomerList} />
                    <CustomerVehicleTypeCommissionSettingComponent ref="customerVehicleTypeCommissionSettingComponent" />
                </div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        );
    }
}
