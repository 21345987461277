import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
} from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import DateBox from 'devextreme-react/date-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React from 'react';
import { Link } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';
import Swal from 'sweetalert2';
import { DateFormat } from '../../helpers/fixcodes';
import { getRequestParams } from '../../helpers/globalfunctions';
import { SendEmailComponent } from '../Shared/SendEmailComponent';
const position = { of: '#historydiv' };


let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));

export class CharterInvoiceReportComponent extends React.Component {
    constructor(props) {
        super(props);
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        this.state = {
            isSubmited: false,
            isEmailSubmitted: false,
            fromDateTime: new Date(currentTime.toLocaleDateString() + " 00:00"),
            toDateTime: new Date(currentTime.toLocaleDateString() + " 23:59"),
            customerTypeList: [],
            customerTypeId: 0,
            charterInvoice: [],
            isDisplayTableFormat: false,
            loadPanelVisible: false,
        }
        this.getCustomerTypeList = this.getCustomerTypeList.bind(this);

        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.handleFromValueChanged = this.handleFromValueChanged.bind(this);
        this.handleToValueChanged = this.handleToValueChanged.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.getCharterInvoiceReport = this.getCharterInvoiceReport.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.handleSearchCharterInvoiceReportSubmit = this.handleSearchCharterInvoiceReportSubmit.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
        this.downloadAsPdf = this.downloadAsPdf.bind(this);
        this.emailReports = this.emailReports.bind(this);

    }
    async componentDidMount() {
        this.setState({
            loadPanelVisible: true,
        });
        await this.getCustomerTypeList();

        this.setState({
            loadPanelVisible: false,
        });
    }
    onToolbarPreparing(e) {


        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'exportpdf',
                onClick: this.downloadAsPdf.bind(this),
                hint: 'Export to Pdf'
            }
        });

        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'email',
                onClick: this.emailReports.bind(this),
                hint: 'Email'
            }
        });

        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getCharterInvoiceReport.bind(this),
                hint: 'Refresh'
            }
        });


    }

    async downloadAsPdf() {

        try {
            this.setState({
                isSubmited: true,
            });

            var request = new Object();
            request.LocationId = Number(sessionStorage.getItem("session_locationId"));
            request.CustomerTypeId = this.state.customerTypeId > 0 ? parseInt(this.state.customerTypeId) : 0;
            request.CustomerType = this.state.customerTypeId > 0 ? this.state.customerTypeList.find(opt => opt.id == this.state.customerTypeId).name : "";
            request.StartDate = this.state.fromDateTime == null ? "" : this.state.fromDateTime.toLocaleDateString() + " 00:00:00"; //new Date(2020, 9, 1);
            request.EndDate = this.state.toDateTime == null ? "" : this.state.toDateTime.toLocaleDateString() + " 23:59:59"; //new Date(2020, 9, 31);
            request.fileFormat = 'pdf';
            request.MethodName = "GetCharterInvoiceReportBaseFare";
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            this.setState({
                isSubmited: false,
            });

            let a = document.createElement('a');
            a.href = dataResponse.reportBase64String;
            a.download = 'CharterInvoiceBaseFare.pdf';
            a.click();
        } catch (e) {
            this.setState({
                isSubmited: false,
            });
            console.log(e)
        }
    }

    async emailReports() {
        const isValid = await Swal.fire({
            title: "<small>Pending Implementation</small>",
            text: "This feature is comming soon.",
            icon: "info",
            dangerMode: false,
            width: "25%",
            dangerMode: false,
            allowOutsideClick: false
        });
        return;
    }

    async getCharterInvoiceReport() {

        var request = new Object();
        request.LocationId = Number(sessionStorage.getItem("session_locationId"));
        request.CustomerTypeId = parseInt(this.state.customerTypeId);
        request.StartDate = this.state.fromDateTime == null ? "" : this.state.fromDateTime.toLocaleDateString() + " 00:00:00"; //new Date(2020, 9, 1);
        request.EndDate = this.state.toDateTime == null ? "" : this.state.toDateTime.toLocaleDateString() + " 23:59:59"; //new Date(2020, 9, 31);

        try {
            this.setState({
                isSubmited: true,
            });
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('report-api/charter-invoice-report', requestParams);
            const data = await response.json();
            this.setState({
                charterInvoice: data.charterInvoiceReport
            });
            this.setState({ isSubmited: false });

        } catch (e) {
            this.setState({ isSubmited: false });
        }

    }

    async getCustomerTypeList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('customer-type-api/', requestParams);
        const data = await response.json();
        if (data !== null && data.customerType.length > 0) {
            this.setState({
                customerTypeList: data.customerType
            });
        }
    }

    handleFromValueChanged(date) {
        this.setState({ fromDateTime: date.value });
    }
    handleToValueChanged(date) {
        this.setState({ toDateTime: date.value });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }
    handleChange(e) {
        this.setState({ isDisplayTableFormat: e.target.checked });
    }
    async handleSearchCharterInvoiceReportSubmit(e) {
        e.preventDefault();
        if (this.state.isDisplayTableFormat) {
            await this.getCharterInvoiceReport();
        }
        else {
            await this.downloadAsPdf();
        }
    }
    handleClearSearch() {
        this.setState({
            isSubmited: false,
            isEmailSubmitted: false,
            fromDateTime: new Date(currentTime.toLocaleDateString() + " 00:00"),
            toDateTime: new Date(currentTime.toLocaleDateString() + " 23:59"),
            customerTypeId: 0,
            charterInvoice: [],
        });
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            this.setState({ isEmailSubmitted: true });
            var request = new Object();
            request.LocationId = Number(sessionStorage.getItem("session_locationId"));
            request.CustomerTypeId = this.state.customerTypeId > 0 ? parseInt(this.state.customerTypeId) : 0;
            request.CustomerType = this.state.customerTypeId > 0 ? this.state.customerTypeList.find(opt => opt.id == this.state.customerTypeId).name : "";
            request.StartDate = this.state.fromDateTime == null ? "" : this.state.fromDateTime.toLocaleDateString() + " 00:00:00"; //new Date(2020, 9, 1);
            request.EndDate = this.state.toDateTime == null ? "" : this.state.toDateTime.toLocaleDateString() + " 23:59:59"; //new Date(2020, 9, 31);
            request.fileFormat = 'pdf';
            request.MethodName = "GetCharterInvoiceReportBaseFare";
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            this.setState({ isEmailSubmitted: false });
            let fileData = dataResponse.reportBase64String;
            if (fileData !== null && fileData !== '') {
                let emailSubject = 'Charter Invoice Report';
                let attachments = [];
                let file = new Object();
                file.fileData = fileData;
                file.fileName = 'CharterInvoiceBaseFare.pdf'
                file.fileExtension = '.pdf';
                attachments.push(file);
                this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
            }
            else {
                Swal.fire({
                    allowOutsideClick: false,
                    showCloseButton: true,
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong! Unable to process the email request.',
                })
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {

        const filterSearchFromDate = time => {
            const currentDate = currentTime;
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }

        const filterSearchToDate = time => {
            const currentDate = currentTime;
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }
        const isDisableAllButton = this.state.charterInvoice.length > 0 ? false : true;

        const renderGridCurrencyCell = (data) => {
            return <div>${parseFloat(data.value).toFixed(2)}</div>;
        }
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-sm-6 col-md-6 col-lg-6">
                                <h1 className="m-0 text-dark">Charter Invoice Report</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem> <NavLink tag={Link} to="/report" className="btn btn-block btn-outline-secondary btn-sm" title="Reports" ><i className="fas fa-arrow-left"></i> Back</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <form method="post" onSubmit={this.handleSearchCharterInvoiceReportSubmit}
                    style={(this.state.isSubmited || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="rez-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>Customer Type</label>
                                        <select className="form-control form-control-sm" name="customerTypeId" placeholder="Select customer type" title="Customer Type" value={this.state.customerTypeId} onChange={this.handleInputChange}>
                                            <option value={0}>Select customer type</option>
                                            {this.state.customerTypeList && this.state.customerTypeList.map((item, i) =>
                                                <option key={i} value={item.id}>{item.name}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>Billing Start Date</label>

                                        <DateBox type="datetime"
                                            className="form-control form-control-sm"
                                            name="fromDateTime"
                                            placeholder="MM/dd/yyyy HH:mm"
                                            displayFormat="MM/dd/yyyy HH:mm"
                                            showAnalogClock={false}
                                            useMaskBehavior={true}
                                            defaultValue={this.state.fromDateTime}
                                            value={this.state.fromDateTime}
                                            onValueChanged={this.handleFromValueChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>Billing End Date</label>
                                        <DateBox type="datetime"
                                            className="form-control form-control-sm"
                                            name="toDateTime"
                                            placeholder="MM/dd/yyyy HH:mm"
                                            displayFormat="MM/dd/yyyy HH:mm"
                                            showAnalogClock={false}
                                            useMaskBehavior={true}
                                            defaultValue={this.state.toDateTime}
                                            value={this.state.toDateTime}
                                            onValueChanged={this.handleToValueChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label className="btn-block">&nbsp;</label>
                                        <button type="submit" className="btn btn-success btn-sm" onClick={this.handleSearchCharterInvoiceReportSubmit} >
                                            {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSubmited && <span title="Search"><i className="fas fa-search"></i> Search</span>}
                                        </button>&nbsp; &nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" disabled={isDisableAllButton} onClick={this.handleClearSearch} title="Clear"><i className="fas fa-eraser"></i> Clear</button>&nbsp; &nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleOnSendEmail} >
                                            {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                                        </button>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group custom-control custom-switch swicth-group-checkbox">
                                        <label className="btn-block"><br /></label>
                                        <input type="checkbox" className="custom-control-input" onChange={this.handleChange} id="reportSwitch" />
                                        <label className="custom-control-label" style={{ paddingTop: 5 + "px" }} htmlFor="reportSwitch">Display Data</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="content">
                    <div className="card">
                        <div className="card-body">
                            <DataGrid
                                dataSource={this.state.charterInvoice}
                                keyExpr="invoicE_NO"
                                columnsAutoWidth={true}
                                showColumnLines={false}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                showBorders={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                width='100%'
                                onToolbarPreparing={this.onToolbarPreparing}>

                                <Column dataField="accounT_TYPE" caption="Customer Type" allowFiltering={true} allowSorting={true} />
                                <Column dataField="accounT_CODE" caption="Customer Code" allowFiltering={true} allowSorting={true} />
                                <Column dataField="accT_NAME" caption="Customer Name" allowFiltering={true} allowSorting={true} />
                                <Column dataField="invoicE_NO" caption="Invoice Number" allowFiltering={true} allowSorting={true} />
                                <Column dataField="invoicE_DATE" caption="Invoice Date" allowFiltering={true} allowSorting={true} format={DateFormat.DefaultDateTimeFormat} dataType="datetime" />
                                <Column dataField="noofrides" caption="No. of Rides" allowFiltering={true} allowSorting={true} />
                                <Column dataField="ridE_AMNT" caption="Base Fare" allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="totaL_FARE" caption="Total Amount" allowSorting={true} cellRender={renderGridCurrencyCell} />

                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={false} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                <Export enabled={true} allowExportSelectedData={false} />
                                <ColumnChooser enabled={false} />
                                <ColumnFixing enabled={false} />
                            </DataGrid>
                        </div>
                    </div>

                </div>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >)
    }
}