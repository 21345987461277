import React, { Component } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Modal from 'react-modal'; //npm install --save react-modal
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import DateBox from 'devextreme-react/date-box';
import Swal from 'sweetalert2';
import { MasterHistoryComponent } from '../Shared/MasterHistoryComponent';
import { SetVehicleDepositComponent } from '../Shared/SetVehicleDepositComponent';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react/load-panel';
export const paymentModeDropdownList = [{
    'id': 0,
    'name': 'CHECK',
}, {
    'id': 1,
    'name': 'ACH',
}]
export class VehicleDepositeComponent extends Component {
    constructor() {
        super()
        this.state = {
            isSubmitted: false,
            isRefreshSubmited: false,
            loadPanelVisible: false,
            vehicleDepositGridDataSource: null,
            depositData: null,
            depositRefundModalIsOpen: false,
            depositRefundViewOnly: false,
            dropdownPaymentMode: [],
            vehicleDepositPendingAdjustmentsList: [],
            id: 0,
            vehicleOwnerMapId: 0,
            vehicleId: 0,
            vehicleNumber: '',
            depositedRefundId: 0,
            depositedAmount: 0,
            depositedDate: moment(new Date()).format("MM/DD/YYYY"),
            isRefunded: false,
            refundedStatus: '',
            chequeDate: moment(new Date()).format("MM/DD/YYYY"),
            refundDate: moment(new Date()).format("MM/DD/YYYY"),
            interestApplicable: false,
            interestPercent: '',
            interestAmount: '',
            paymentModeId: -1,
            paymentMode: '',
            chequeBank: '',
            chequeNumber: '',
            note: '',
            refundedBy: '',
            isAdjestment: false,
            adjustmentAmount: 0,
            refundAmount: 0,
            errors: {
                refundDate: '',
                depositedDate: '',
                depositedAmount: '',
                interestPercent: '',
                interestAmount: '',
                paymentMode: '',
                chequeBank: '',
                chequeNumber: '',
                chequeDate: '',
                refundedBy: '',

            }
        }
    }
    componentDidMount() {
        this.getVehicleDepositList();
        this.getPaymentModeDropdown();
    }
    getVehicleDepositList = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            var request = new Object();
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('vehicle-deposit-api/0/list', requestParams);
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    vehicleDepositGridDataSource: data.vehicleDeposits
                });
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    };
    getPaymentModeDropdown = async () => {
        this.setState({ dropdownPaymentMode: paymentModeDropdownList });
    }
    handleOnRefresh = async () => {
        try {
            this.setState({ isRefreshSubmited: true });
            await this.getVehicleDepositList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isRefreshSubmited: false });
    }
    getPendingAdjustments = async (vehicleId) => {
        try {
            this.setState({ loadPanelVisible: true });
            var request = new Object();
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('vehicle-deposit-api/' + parseInt(vehicleId) + '/vehicle-deposit-pending-adjustments', requestParams);
            if (response.ok) {
                const data = await response.json();
                if (data.isSuccess) {
                    if (data.vehicleDepositPendingAdjustments !== null) {
                        let pendingAdjustmentsList = [];
                        let refundAmount = this.state.refundAmount;
                        let dueBalance = 0;
                        data.vehicleDepositPendingAdjustments.forEach((item) => {
                            let adjustedAmount = 0;
                            dueBalance = item.dueBalance;
                            if (refundAmount > dueBalance) {
                                adjustedAmount = dueBalance;
                                refundAmount = refundAmount - dueBalance;
                            }
                            else {
                                adjustedAmount = refundAmount;
                                refundAmount = 0;
                            }
                            let data = new Object();
                            data.refundId = 0;
                            data.vehicleDeductionId = item.vehicleDeductionId;
                            data.deductionId = item.deductionId;
                            data.deductionName = item.deductionName;
                            data.loanAmount = item.loanAmount;
                            data.dueBalance = item.dueBalance;
                            data.adjustedAmount = adjustedAmount;
                            pendingAdjustmentsList.push(data);
                        });
                        this.setState({
                            vehicleDepositPendingAdjustmentsList: pendingAdjustmentsList
                        });
                    }
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    };
    calculateRefund = async (interestApplicable, interestPercent, interestAmount, isAdjestment, adjustmentAmount) => {
        try {
            if (interestApplicable === false) {
                interestPercent = 0;
                interestAmount = 0;
            }
            if (isAdjestment === false) {
                adjustmentAmount = 0;
            }
            else {
                if (this.state.vehicleDepositPendingAdjustmentsList !== null) {
                    if (this.state.vehicleDepositPendingAdjustmentsList.length > 0) {
                        let adjustedAmount = 0;
                        this.state.vehicleDepositPendingAdjustmentsList.forEach((item) => {
                            if (item.adjustedAmount !== '') {
                                adjustedAmount = parseFloat(adjustedAmount) + parseFloat(item.adjustedAmount);
                            }
                        });
                        adjustmentAmount = parseFloat(adjustedAmount).toFixed(2);
                    }
                    //let pendingAdjustmentsList = [];
                    //if (this.state.vehicleDepositPendingAdjustmentsList.length > 0) {
                    //    this.state.vehicleDepositPendingAdjustmentsList.forEach((item) => {
                    //        let data = new Object();
                    //        data.refundId = item.refundId;
                    //        data.vehicleDeductionId = item.vehicleDeductionId;
                    //        data.deductionId = item.deductionId;
                    //        data.deductionName = item.deductionName;
                    //        data.loanAmount = item.loanAmount;
                    //        data.dueBalance = item.dueBalance;
                    //        data.adjustedAmount = adjustedAmount;
                    //        pendingAdjustmentsList.push(data);
                    //    });
                    //}
                    //this.setState({
                    //    vehicleDepositPendingAdjustmentsList: pendingAdjustmentsList
                    //});                   
                }
            }
            let tempInterestAmount = interestAmount.toString().trim() === '' ? 0 : interestAmount;
            let tempadjustmentAmount = adjustmentAmount.toString().trim() === '' ? 0 : adjustmentAmount;
            let tempDepositedAmount = this.state.depositedAmount.toString().trim() === '' ? 0 : this.state.depositedAmount;

            let _refundAmount = (parseFloat(tempDepositedAmount) + parseFloat(tempInterestAmount)) - parseFloat(tempadjustmentAmount)
            _refundAmount = _refundAmount.toFixed(2);
            this.setState({
                refundAmount: _refundAmount,
                interestPercent: interestPercent,
                interestAmount: interestAmount,
                adjustmentAmount: adjustmentAmount
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    getDepositRefund = async (depositRefundId) => {
        try {
            this.setState({ loadPanelVisible: true });
            var request = new Object();
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('vehicle-deposit-api/' + parseInt(depositRefundId) + '/vehicle-deposit-refund', requestParams);
            if (response.ok) {
                const dataResponse = await response.json();
                let data = new Object();
                if (dataResponse.isSuccess) {
                    if (dataResponse.vehicleDepositRefund !== null) {
                        dataResponse.vehicleDepositRefund.forEach((item) => {
                            data.refundId = item.id;
                            if (item.refundDate !== null && item.refundDate !== '') {
                                data.refundDate = item.refundDate;
                            }
                            data.refundAmount = item.refundAmount;
                            data.note = item.note;
                            data.refundedBy = item.refundedByName;
                            data.interestApplicable = item.isInterestApplicable;
                            data.interestPercent = item.interestPercent;
                            data.interestAmount = item.interestAccurredAmount;
                            data.isAdjestment = item.isAdjusted;
                            data.adjustmentAmount = item.adjustedAmount;
                            data.paymentModeId = item.paymentModeId;
                            data.chequeNumber = item.chequeNumber;
                            data.chequeBank = item.chequeBank;
                            if (item.chequeDate !== null && item.chequeDate !== '') {
                                data.chequeDate = item.chequeDate;
                            }
                            this.setState({
                                refundId: data.refundId,
                                refundDate: data.refundDate,
                                refundAmount: data.refundAmount,
                                note: data.note,
                                refundedBy: data.refundedBy,
                                interestApplicable: data.interestApplicable,
                                interestPercent: data.interestPercent,
                                interestAmount: data.interestAmount,
                                isAdjestment: data.isAdjestment,
                                adjustmentAmount: data.adjustmentAmount,
                                paymentModeId: data.paymentModeId,
                                chequeNumber: data.chequeNumber,
                                chequeBank: data.chequeBank,
                                chequeDate: data.chequeDate,
                            });
                        });
                    }
                    await this.GetDepositRefundAdjustments(data.refundId);
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    };
    GetDepositRefundAdjustments = async (depositRefundId) => {
        try {
            this.setState({ loadPanelVisible: true });
            var request = new Object();
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('vehicle-deposit-api/' + parseInt(depositRefundId) + '/vehicle-deposit-refund-adjustments', requestParams);
            if (response.ok) {
                const data = await response.json();
                if (data.isSuccess) {
                    if (data.vehicleDepositRefundAdjustments !== null) {
                        let pendingAdjustmentsList = [];
                        data.vehicleDepositRefundAdjustments.forEach((item) => {
                            let data = new Object();
                            data.refundId = item.refundId;
                            data.vehicleDeductionId = item.vehicleDeductionId;
                            data.deductionId = item.deductionId;
                            data.deductionName = item.deductionName;
                            data.loanAmount = item.loanAmount;
                            data.dueBalance = item.dueBalance;
                            data.adjustedAmount = item.adjustedAmount;
                            pendingAdjustmentsList.push(data);
                        });
                        this.setState({
                            vehicleDepositPendingAdjustmentsList: pendingAdjustmentsList
                        });
                    }
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleInputChange = async (event) => {
        let errors = this.state.errors;
        try {
            const target = event.target;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
            const name = target.name;
            let isFocus = false;
            switch (name) {
                case 'interestApplicable':
                    this.setState({ interestApplicable: value });
                    await this.calculateRefund(value, this.state.interestPercent, this.state.interestAmount, this.state.isAdjestment, this.state.adjustmentAmount);
                    break;
                case 'interestPercent':
                    let regexPercent = /((^100(\.\0{1,2})?)$|(^\d{0,2}(\.\d{0,2})?))$/;
                    if (value.trim() === '' || regexPercent.test(value)) {
                        await this.calculateRefund(this.state.interestApplicable, value, this.state.interestAmount, this.state.isAdjestment, this.state.adjustmentAmount);
                    }
                    break;
                case 'interestAmount':
                    const re = /(^\d{0,5}(\.\d{0,2})?)$/;  ///^\d*(\.\d{0,2})?$/;
                    if (value.trim() === '' || re.test(value)) {
                        await this.calculateRefund(this.state.interestApplicable, this.state.interestPercent, value, this.state.isAdjestment, this.state.adjustmentAmount);
                    }
                    break;
                case 'paymentMode':
                    errors.paymentMode = '';
                    errors.chequeBank = '';
                    errors.chequeNumber = '';
                    errors.chequeDate = '';
                    if (Number(value) < 0) {
                        errors.paymentMode = 'Please select payment mode.';
                    }
                    else {
                        if (Number(value) === 0) {
                            if (this.state.chequeBank.trim() === '') {
                                errors.chequeBank = 'Please enter cheque bank name.';
                                if (isFocus === false) { this.chequeBankInputValidator.focus(); isFocus = true; }
                            } if (this.state.chequeNumber.trim() === '') {
                                errors.chequeNumber = 'Please enter cheque bank Number.';
                                if (isFocus === false) { this.chequeNumberInputValidator.focus(); isFocus = true; }
                            }
                            if (this.state.chequeDate === null || moment(new Date(this.state.chequeDate)).isValid() === false) {
                                errors.chequeDate = 'Please enter cheque date.';
                                if (isFocus === false) { this.chequeDateSelectValidator.instance.focus(); isFocus = true; }
                            }
                        }
                    }
                    if (Number(value) !== 0) {
                        this.setState({
                            chequeBank: '',
                            chequeNumber: '',
                            chequeDate: moment(new Date()).format("MM/DD/YYYY")
                        });
                    }
                    this.setState({
                        paymentModeId: value,
                        paymentMode: target.options[target.selectedIndex].text
                    });
                    break;
                case 'chequeBank':
                    errors.chequeBank = '';
                    if (value.trim() === '') {
                        errors.chequeBank = 'Please enter cheque bank.';
                        if (isFocus === false) { this.chequeBankInputValidator.focus(); isFocus = true; }
                    }
                    if (value.trim().length < 76) {
                        this.setState({
                            chequeBank: value
                        });
                    }
                    break;
                case 'chequeNumber':
                    errors.chequeNumber = '';
                    if (value.trim() === '') {
                        errors.chequeNumber = 'Please enter cheque number.';
                        if (isFocus === false) { this.chequeBankInputValidator.focus(); isFocus = true; }
                    }
                    if (value.trim().length < 21) {
                        this.setState({
                            chequeNumber: value
                        });
                    }
                    break;
                case 'note':
                    if (value.trim().length < 101) {
                        this.setState({
                            note: value
                        });
                    }
                    break;
                case 'refundedBy':
                    errors.refundedBy = '';
                    if (value.trim() === '') {
                        errors.refundedBy = 'Please enter refundedBy By.';
                        if (isFocus === false) { this.refundedByInputValidator.focus(); isFocus = true; }
                    }
                    if (value.trim().length < 51) {
                        this.setState({
                            refundedBy: value
                        });
                    }
                    break;
                case 'isAdjestment':
                    this.setState({ isAdjestment: value });
                    await this.calculateRefund(this.state.interestApplicable, this.state.interestPercent, this.state.interestAmount, value, this.state.adjustmentAmount);
                //case 'adjustmentAmount':
                //    debugger;
                //    await this.calculateRefund(this.state.interestApplicable, this.state.interestPercent, this.state.interestAmount, this.state.isAdjestment, value);
                //    break;
                default:
                    if (isFocus === false) { this.submitVehicleDepositRefundButtonValidator.focus(); }
                    break;
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ errors: errors });
    }
    handleOnValueChangedRefundDateBox = (event) => {
        let errors = this.state.errors;
        try {
            errors.refundDate = '';
            if (event.value === '' || event.value === null) {
                errors.refundDate = 'Please select refund date.';
            }
            else {
                if (moment(new Date(event.value)).isValid() === false) {
                    errors.refundDate = 'Please select valid date.';
                }
            }
            this.setState({
                refundDate: event.value
            });
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ errors: errors });
    }
    handleOnValueChangedDepositDateBox = (event) => {
        let errors = this.state.errors;
        try {
            errors.depositedDate = '';
            if (event.value === '' || event.value === null) {
                errors.depositedDate = 'Please select deposit date.';
            }
            else {
                if (moment(new Date(event.value)).isValid() === false) {
                    errors.depositedDate = 'Please select valid date.';
                }
            }
            this.setState({
                depositedDate: event.value
            });
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ errors: errors });
    }
    handleOnValueChangedChequeDateBox = (event) => {
        let errors = this.state.errors;
        try {
            errors.chequeDate = '';
            if (event.value === '' || event.value === null) {
                errors.chequeDate = 'Please select cheque date.';
            }
            else {
                if (moment(new Date(event.value)).isValid() === false) {
                    errors.chequeDate = 'Please select valid date.';
                }
            }
            this.setState({
                chequeDate: event.value
            });
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ errors: errors });
    }
    handleToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getVehicleDepositList.bind(this)
            }
        });
    }
    handleAddDeposit = async (e) => {
        e.preventDefault();
        try {
            await this.refs.setVehicleDepositComponent.handleShowModal(true, false, false, 0, 0, 0, 0);
        } catch (ex) {
            console.log(ex);
        }
    }
    handleEditDeposit = async (e, data) => {
        e.preventDefault();
        if (data != null) {
            try {
                await this.refs.setVehicleDepositComponent.handleShowModal(false, true, false, 0, data.id === null ? 0 : Number(data.id), data.vehicleId, data.vehicleNumber);
            } catch (ex) {
                console.log(ex);
            }
        }
    }
    handleViewDeposit = async (e, data) => {
        e.preventDefault();
        if (data != null) {
            try {
                await this.refs.setVehicleDepositComponent.handleShowModal(false, false, true, 0, data.id === null ? 0 : Number(data.id), data.vehicleId, data.vehicleNumber);
            } catch (ex) {
                console.log(ex);
            }
        }
    }
    handleDepositRefund = async (e, data, isViewOnly) => {
        e.preventDefault();
        if (data != null) {
            try {
                this.setState({
                    depositRefundModalIsOpen: true,
                    depositData: data,
                    depositRefundViewOnly: isViewOnly,
                    vehicleId: data.vehicleId === null ? 0 : Number(data.vehicleId),
                    vehicleNumber: data.vehicleNumber === null ? '' : data.vehicleNumber,
                    id: data.id === null ? 0 : Number(data.id),
                    vehicleOwnerMapId: data.vehicleOwnerMapId === null ? 0 : Number(data.vehicleOwnerMapId),
                    depositedDate: data.depositedDate || null,
                    depositedAmount: data.depositedAmount || null,
                    depositedRefundId: data.depositedRefundId === null ? 0 : Number(data.depositedRefundId),
                    refundAmount: data.depositedAmount || null,
                    isRefunded: data.isRefunded || false,
                    refundedStatus: data.refundedStatus || '',
                });
            } catch (ex) {
                console.log(ex);
            }
        }
    }
    handleViewDepositReceipt = async (e, data) => {
        e.preventDefault();
        try {
            if (data != null) {
                this.setState({ loadPanelVisible: true });
                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.Number1 = Number(data.id);
                request.fileFormat = 'pdf';
                request.MethodName = "GetVehicleDepositReceiptReport";
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                let a = document.createElement('a');
                a.href = dataResponse.reportBase64String;
                a.download = 'FranchiseeDepositReceipt.pdf';
                a.click();
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleMasterHistoryModal = (e, data) => {
        e.preventDefault();
        try {
            this.refs.masterHistoryComponent.showModal('vehicle-api', 'Vehicle', data.vehicleNumber === null ? '' : data.vehicleNumber.trim(), data.vehicleId === null ? 0 : Number(data.vehicleId));
        } catch (ex) {
            console.log(ex);
        }
    }
    handleAssignDataToState = async () => {
        try {
            if (this.state.depositedRefundId === 0) {
                this.setState({
                    refundAmount: this.state.depositedAmount,
                });
                await this.getPendingAdjustments(this.state.vehicleId);
                await this.calculateRefund(this.state.interestApplicable, this.state.interestPercent, this.state.interestAmount, this.state.isAdjestment, this.state.adjustmentAmount);
            }
            else {
                await this.getDepositRefund(this.state.depositedRefundId);
            }
        } catch (ex) {
            console.log(ex);
            await this.onfailure(null, 'Something went wrong while assigning data!');
        }
    }
    handleResetStateData = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isRefreshSubmited: false,
                loadPanelVisible: false,
                depositData: null,
                depositRefundModalIsOpen: false,
                depositRefundViewOnly: false,
                vehicleDepositPendingAdjustmentsList: [],
                id: 0,
                vehicleOwnerMapId: 0,
                vehicleId: 0,
                vehicleNumber: '',
                depositedRefundId: 0,
                depositedAmount: 0,
                depositedDate: moment(new Date()).format("MM/DD/YYYY"),
                isRefunded: false,
                refundedStatus: '',
                chequeDate: moment(new Date()).format("MM/DD/YYYY"),
                refundDate: moment(new Date()).format("MM/DD/YYYY"),
                interestApplicable: false,
                interestPercent: '',
                interestAmount: '',
                paymentModeId: -1,
                paymentMode: '',
                chequeBank: '',
                chequeNumber: '',
                note: '',
                refundedBy: '',
                isAdjestment: false,
                adjustmentAmount: 0,
                refundAmount: 0,
                errors: {
                    refundDate: '',
                    depositedDate: '',
                    depositedAmount: '',
                    interestPercent: '',
                    interestAmount: '',
                    paymentMode: '',
                    chequeBank: '',
                    chequeNumber: '',
                    chequeDate: '',
                    refundedBy: '',

                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleVehicleDepositRefundSubmit = async event => {
        event.preventDefault();
        try {
            if (await this.iSFormVehicleDepositRefundValid()) {
                const isConfirm = await this.isConfirm();
                if (!isConfirm) {
                    return;
                }
                this.setState({ isSubmitted: true });
                var request = new Object();
                request.VehicleOwnerMapId = Number(this.state.vehicleOwnerMapId);
                request.RefundDate = moment(new Date(this.state.refundDate), 'MM/DD/YYYY').format('MM/DD/YYYY HH:mm:ss');
                request.RefundAmount = parseFloat(this.state.refundAmount);
                request.Note = this.state.note;
                request.RefundedByName = this.state.refundedBy;
                request.VehicleId = Number(this.state.vehicleId);
                request.IsInterestApplicable = this.state.interestApplicable;
                request.InterestPercent = parseFloat(this.state.interestPercent);
                request.InterestAccurredAmount = parseFloat(this.state.interestAmount);
                request.IsAdjustment = this.state.isAdjestment;
                request.AdjustmentAmount = parseFloat(this.state.adjustmentAmount);
                request.PaymentModeId = Number(this.state.paymentModeId);
                request.ChqNo = this.state.chequeNumber.trim();
                request.ChqDate = moment(new Date(this.state.chequeDate), 'MM/DD/YYYY').format('MM/DD/YYYY HH:mm:ss');;
                request.ChqBank = this.state.chequeBank.trim();
                request.VehicleDepositRefundAdjustmentList = await this.vehicleDepositRefundAdjustmentList(Number(this.state.id));
                try {
                    const requestParams = getRequestParams('PUT', request);
                    const response = await fetch('vehicle-deposit-api/' + Number(this.state.id) + '/save-vehicle-deposit-refund', requestParams);
                    const data = await response.json();
                    let icon = '';
                    let title = '';
                    if (data.isSuccess) {
                        icon = 'success';
                        title = 'Success';
                    }
                    else {
                        if (data.retValue === -1) {
                            icon = 'warning';
                            title = 'Unable to process';
                        }
                        else {
                            icon = 'error';
                            title = 'Oops...';
                        }
                    }
                    await Swal.fire({
                        allowOutsideClick: false,
                        icon: icon,
                        title: title,
                        text: data.resultMessage,
                    }).then(async (result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (data.isSuccess) {
                            if (result.isConfirmed) {
                                await this.handleResetStateData();
                                await this.getVehicleDepositList();
                            }

                        }
                    })
                } catch (ex) {
                    console.log(ex);
                    await this.onfailure(null, null);
                }
            }
        } catch (ex) {
            console.log(ex);
            await this.onfailure(null, null);
        }
        this.setState({ isSubmitted: false })
    }
    vehicleDepositRefundAdjustmentList = async (refundId) => {
        let SaveVehicleDepositRefundAdjustmentList = [];
        try {
            if (Number(refundId) > 0) {
                if (this.state.vehicleDepositPendingAdjustmentsList !== null) {
                    this.state.vehicleDepositPendingAdjustmentsList.forEach((item) => {
                        var request = new Object();
                        request.VehicleOwnerMapId = Number(this.state.vehicleOwnerMapId);
                        request.VehicleDeductionId = item.vehicleDeductionId;
                        request.AdjustmentAmount = item.adjustedAmount;
                        SaveVehicleDepositRefundAdjustmentList.push(request);
                    });
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        return SaveVehicleDepositRefundAdjustmentList;
    }
    iSFormVehicleDepositRefundValid = async () => {
        let formIsValid = true;
        let errors = this.state.errors;
        try {
            let isFocus = false;
            errors.refundDate = '';
            errors.paymentMode = '';
            errors.chequeBank = '';
            errors.chequeNumber = '';
            errors.chequeDate = '';
            errors.refundedBy = '';
            if (this.state.refundDate === null || this.state.refundDate === '') {
                formIsValid = false;
                errors.refundDate = 'Please enter refund date.';
                if (isFocus === false) { this.refundDateSelectValidator.instance.focus(); isFocus = true; }
            }
            else {
                if (moment(new Date(this.state.refundDate)).isValid() === false) {
                    formIsValid = false;
                    errors.refundDate = 'Please enter refund date.';
                    if (isFocus === false) { this.refundDateSelectValidator.instance.focus(); isFocus = true; }
                }
            }
            if (Number(this.state.paymentModeId) < 0 || Number(this.paymentModeSelectValidator.value) < 0) {
                formIsValid = false;
                errors.paymentMode = 'Please select payment mode.';
                if (isFocus === false) { this.paymentModeSelectValidator.focus(); isFocus = true; }
            }            
            if (Number(this.state.paymentModeId) === 0) {
                if (this.state.chequeBank.trim() === '') {
                    formIsValid = false;
                    errors.chequeBank = 'Please enter cheque bank name.';
                    if (isFocus === false) { this.chequeBankInputValidator.focus(); isFocus = true; }
                } if (this.state.chequeNumber.trim() === '') {
                    formIsValid = false;
                    errors.chequeNumber = 'Please enter cheque bank Number.';
                    if (isFocus === false) { this.chequeNumberInputValidator.focus(); isFocus = true; }
                }
                if (this.state.chequeDate === null || moment(new Date(this.state.chequeDate)).isValid() === false) {
                    formIsValid = false;
                    errors.chequeDate = 'Please enter cheque date.';
                    if (isFocus === false) { this.chequeDateSelectValidator.instance.focus(); isFocus = true; }
                }
            }
            if (this.state.refundedBy.trim() === '') {
                formIsValid = false;
                errors.refundedBy = 'Please enter refunded by user name.';
                if (isFocus === false) { this.refundedByInputValidator.focus(); isFocus = true; }
            }
            if (isFocus === false) { this.submitVehicleDepositRefundButtonValidator.focus(); }
        } catch (ex) {
            console.log(ex);
            formIsValid = false;
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    isConfirm = async () => {
        let isResult = false;
        let htmlConfirmation = '<small>Are you sure want to refund deposit of vehicle [<b>' + this.state.vehicleNumber + '</b>] ? <br> Deposit Amount: <b>' + this.state.depositedAmount + '</b><br> Refund Amount: <b>' + this.state.refundAmount + '</b></small>';
        await Swal.fire({
            title: '<small><b>Confirmation</b></small>',
            html: htmlConfirmation,
            icon: 'question',
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Yes'
        }).then(async (result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }
    onfailure = async (title, resultMessage) => {
        await Swal.fire({
            allowOutsideClick: false,
            showCloseButton: true,
            icon: 'error',
            title: (title === null || title.trim() === '') ? 'Oops...' : title,
            html: (resultMessage === null || resultMessage.trim() === '') ? 'Something went wrong!' : resultMessage.trim(),
        })
    }
    
    render() {
        const renderGridCell = (data) => {
            let isRefunded = data.data.isRefunded;
            let editLink = <a href="#" title="Edit" onClick={(e) => { this.handleEditDeposit(e, data.data) }} ><i className="fas fa-pen"></i></a>
            let viewLink = <a href="#" title="View" style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleViewDeposit(e, data.data) }} ><i className="fas fa-eye"></i></a>
            let depositRefund = !isRefunded ? <a href="#" title="Deposit Refund" style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleDepositRefund(e, data.data, false) }}><i className="fas fa-file-invoice-dollar"></i></a> : ''
            let depositRefundView = isRefunded ? <a href="#" title="View Deposit Refund" style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleDepositRefund(e, data.data, true) }}><i className="text-danger fas fa-file-invoice-dollar"></i></a> : ''
            let depositReceiptView = <a href="#" title="View Deposit Receipt" style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleViewDepositReceipt(e, data.data) }}><i className="fas fa-file-alt"></i></a>
            return (
                <div style={{ textAlign: 'left', width: 'auto' }}>
                    {editLink}
                    {viewLink}
                    {depositRefund === '' || depositRefund}
                    {depositRefundView === '' || depositRefundView}
                    {depositReceiptView}
                    <a href="#" title="History" style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleMasterHistoryModal(e, data.data) }}><i className="fas fa-history"></i></a>
                </div>
            );
        }

        const renderGridCurrencyCell = (data) => {
            return <div>${parseFloat(data.value).toFixed(2)}</div>;
        }
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <h1 className="m-0 text-dark">Deposits</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem>
                                        <NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="#" title="Refresh"
                                            onClick={this.handleOnRefresh}>
                                            {this.state.isRefreshSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isRefreshSubmited && <span title="Refresh"><i className="fas fa-sync-alt"></i> Refresh</span>}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem><NavLink tag={Link} to="#" className="btn btn-block btn-outline-secondary btn-sm" onClick={this.handleAddDeposit} title="Add Deposit"><i className="fas fa-plus"></i> Add Deposit</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/franchisee/deductions" className="btn btn-block btn-outline-secondary btn-sm" title="Deduction"><i className="fas fa-minus-circle"></i> Deduction</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/franchisee/addons" className="btn btn-block btn-outline-secondary btn-sm" title="Addons"><i className="fas fa-puzzle-piece"></i> Addons</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/driver/documents" className="btn btn-block btn-outline-secondary btn-sm" title="Driver Documents"><i className="fas fa-file-alt"></i> Driver Documents</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="card card-grid">
                        <div className="card-body">
                            <DataGrid
                                dataSource={this.state.vehicleDepositGridDataSource}
                                keyExpr="id"
                                selection={{ mode: 'single' }}
                                columnsAutoWidth={false}
                                showColumnLines={true}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                onToolbarPreparing={this.handleToolbarPreparing}
                            >
                                <Column dataField="id" width={95} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                <Column dataField="vehicleNumber" caption="Vehicle" />
                                <Column dataField="vehicleType" caption="Vehicle Type" />
                                <Column dataField="vehicleOwnerName" caption="Owner" />
                                <Column dataField="depositedDate" caption="Deposit Date" width={"auto"} />
                                <Column dataField="depositedAmount" caption="Deposit Amount" cellRender={renderGridCurrencyCell} />
                                <Column dataField="depositedBy" caption="Deposited By" />
                                <Column dataField="refundedStatus" caption="Status" />
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                <Export enabled={true} allowExportSelectedData={false} />
                                <ColumnChooser enabled={true} />
                                <ColumnFixing enabled={true} />
                            </DataGrid>
                        </div>
                    </div>
                </div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.depositRefundModalIsOpen}
                    style={{ content: { width: '55%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleAssignDataToState}
                    onRequestClose={this.handleResetStateData}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" style={this.state.isSubmitted ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            {/*<h6 className="modal-title">Deposit Refund {'Modify' + (this.state.depositRefundViewOnly ? '(ReadOnly)' : '')}</h6>*/}
                            <h6 className="modal-title">Deposit Refund {(this.state.depositRefundViewOnly ? 'View' : 'Modify')}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleResetStateData}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body" style={{ height: 350 + 'px', overflowY: 'auto', overflowX: 'hidden' }}>
                            <div className="row">
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <label>Refund Date</label>
                                        <DateBox type="date" style={{ width: "100%" }} className="form-control form-control-sm" name="refundDate" placeholder="MM/dd/yyyy"
                                            disabled={this.state.depositRefundViewOnly}
                                            defaultValue={new Date()}
                                            value={this.state.refundDate}
                                            ref={DateBox => this.refundDateSelectValidator = DateBox}
                                            onValueChanged={this.handleOnValueChangedRefundDateBox}
                                        />
                                        {this.state.errors["refundDate"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["refundDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <label>Deposit Date</label>
                                        <DateBox type="date" style={{ width: "100%" }} className="form-control form-control-sm" name="depositedDate" placeholder="MM/dd/yyyy"
                                            disabled={this.state.depositRefundViewOnly}
                                            defaultValue={new Date()}
                                            value={this.state.depositedDate}
                                            onValueChanged={this.handleOnValueChangedDepositDateBox}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <label>Deposit Amount</label>
                                        <input type="text" className="form-control form-textbox form-control-sm" name="depositedAmount" title="Deposit Amount" placeholder="00.00" disabled={this.state.depositRefundViewOnly} readOnly={true} value={this.state.depositedAmount} onChange={this.handleInputChange} />
                                    </div>
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <div className="icheck-primary">
                                        <input type="checkbox" id="interestApplicable" name="interestApplicable" disabled={this.state.depositRefundViewOnly} checked={this.state.interestApplicable} onChange={this.handleInputChange} />
                                        <label htmlFor="interestApplicable" style={{ fontSize: "inherit" }}> Interest Applicable</label>
                                    </div>
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <label>Interest %</label>
                                        <input type="text" className="form-control form-textbox form-control-sm" name="interestPercent" title="Interest Percent" placeholder="00.00" disabled={this.state.depositRefundViewOnly} value={this.state.interestPercent} readOnly={!this.state.interestApplicable} onChange={this.handleInputChange} />
                                    </div>
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <label>Interest Amount</label>
                                        <input type="text" className="form-control form-textbox form-control-sm" name="interestAmount" title="Interest Amount" placeholder="00.00" disabled={this.state.depositRefundViewOnly} value={this.state.interestAmount} readOnly={!this.state.interestApplicable} onChange={this.handleInputChange} />
                                    </div>
                                </div>
                                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                    <div className="form-group">
                                        <label>Payment Mode <span className="text-danger">*</span></label>
                                        <select name="paymentMode" className="form-control form-control-sm" disabled={this.state.depositRefundViewOnly} ref={select => this.paymentModeSelectValidator = select} value={this.state.paymentModeId} onChange={this.handleInputChange}>
                                            <option value="-1">---Select Payment mode---</option>
                                            {
                                                this.state.dropdownPaymentMode &&
                                                this.state.dropdownPaymentMode.map((item, i) =>
                                                    (<option key={i} value={item.id}>{item.name}</option>))
                                            }
                                        </select>
                                        {this.state.errors["paymentMode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["paymentMode"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>Cheque Bank</label>
                                            <input type="text" className="form-control form-textbox form-control-sm" name="chequeBank" title="Cheque Bank" disabled={this.state.depositRefundViewOnly} ref={input => this.chequeBankInputValidator = input} readOnly={Number(this.state.paymentModeId) === 0 ? false : true} value={this.state.chequeBank} onChange={this.handleInputChange} />
                                            {this.state.errors["chequeBank"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["chequeBank"]}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                    <div className="form-group">
                                        <label>Cheque Number</label>
                                        <input type="text" className="form-control form-textbox form-control-sm" name="chequeNumber" title="Cheque Number" disabled={this.state.depositRefundViewOnly} ref={input => this.chequeNumberInputValidator = input} readOnly={Number(this.state.paymentModeId) === 0 ? false : true} value={this.state.chequeNumber} onChange={this.handleInputChange} />
                                        {this.state.errors["chequeNumber"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["chequeNumber"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                    <div className="form-group">
                                        <label>Cheque Date</label>
                                        <DateBox type="date" style={{ width: "100%" }} className="form-control form-control-sm" name="chequeDate" placeholder="MM/dd/yyyy"
                                            disabled={this.state.depositRefundViewOnly}
                                            defaultValue={new Date()}
                                            value={this.state.chequeDate}
                                            ref={select => this.chequeDateSelectValidator = select}
                                            onValueChanged={this.handleOnValueChangedChequeDateBox}
                                            readOnly={Number(this.state.paymentModeId) === 0 ? false : true}
                                        />
                                        {this.state.errors["chequeDate"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["chequeDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Note (If Any) </label>
                                        <textarea className="form-control form-textbox form-control-sm" name="note" title="Note" placeholder="Note..." rows="2" maxLength="100" disabled={this.state.depositRefundViewOnly} value={this.state.note} onChange={this.handleInputChange} />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>Refunded By <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-textbox form-control-sm" name="refundedBy" title="Refunded By User" placeholder="Refunded by user" disabled={this.state.depositRefundViewOnly} ref={input => this.refundedByInputValidator = input} value={this.state.refundedBy} onChange={this.handleInputChange} />
                                        {this.state.errors["refundedBy"].length > 0 && <span className="error invalid-feedback">{this.state.errors["refundedBy"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="icheck-primary">
                                            <input type="checkbox" id="isAdjestment" name="isAdjestment" disabled={this.state.depositRefundViewOnly} checked={this.state.isAdjestment} onChange={this.handleInputChange} />
                                            <label htmlFor="isAdjestment" style={{ fontSize: "inherit" }}> Adjestment(Due Loans)</label>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="card card-grid">
                                            <div className="card-body">
                                                <DataGrid
                                                    dataSource={this.state.vehicleDepositPendingAdjustmentsList}
                                                    keyExpr="vehicleDeductionId"
                                                    selection={{ mode: 'single' }}
                                                    columnsAutoWidth={false}
                                                    showColumnLines={true}
                                                    showRowLines={true}
                                                    showBorders={true}
                                                    rowAlternationEnabled={true}
                                                    allowColumnResizing={true}
                                                    columnResizingMode='widget'
                                                >
                                                    <Column dataField="deductionName" caption="Deduction" />
                                                    <Column dataField="loanAmount" caption="Loan$" />
                                                    <Column dataField="dueBalance" caption="Due$" />
                                                    <Column dataField="adjestedAmount" caption="Adjustment$" />
                                                    <SortByGroupSummaryInfo summaryItem="count" />
                                                    <GroupPanel visible={false} />
                                                    <HeaderFilter visible={false} />
                                                    <SearchPanel visible={false} highlightCaseSensitive={false} width={240} placeholder="Search..." />
                                                    <Grouping autoExpandAll={false} />
                                                    <Paging defaultPageSize={20} />
                                                    <Pager showPageSizeSelector={false} allowedPageSizes={[5, 10, 20]} showInfo={false} />
                                                    <Export enabled={false} allowExportSelectedData={false} />
                                                    <ColumnChooser enabled={false} />
                                                    <ColumnFixing enabled={false} />
                                                </DataGrid>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <label>Adjustment Amount</label>
                                        <input type="text" className="form-control form-textbox form-control-sm" name="adjustmentAmount" title="Adjustment" placeholder="00.00" readOnly={true} disabled={this.state.depositRefundViewOnly} value={this.state.adjustmentAmount} onChange={this.handleInputChange} />
                                    </div>
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <label>Refund Amount</label>
                                        <input type="text" className="form-control form-textbox form-control-sm" name="refundAmount" title="Refund Amount" placeholder="00.00" readOnly={true} readOnly={true} disabled={this.state.depositRefundViewOnly} value={this.state.refundAmount} />
                                    </div>
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" disabled={this.state.depositRefundViewOnly} ref={button => this.submitVehicleDepositRefundButtonValidator = button} onClick={this.handleVehicleDepositRefundSubmit}>
                                {this.state.isSubmitted && <span title="In Process"><i className="fas fa-spinner fa-spin"></i> In Process</span>}
                                {!this.state.isSubmitted && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleResetStateData} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <MasterHistoryComponent ref="masterHistoryComponent" />
                <SetVehicleDepositComponent ref="setVehicleDepositComponent" isHandleFromDepositMasterList={true} handleOnRefresh={this.handleOnRefresh} />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}
