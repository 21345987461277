import React, { Component } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import DateBox from 'devextreme-react/date-box';
import { SelectBox } from 'devextreme-react/select-box';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions';
import { LoadPanel } from 'devextreme-react/load-panel';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'
const position = { of: '#historydiv' };

export class CongestionReportComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            chkPaymentPeriodSelection: "1",
            chkLocationSelection: "1",
            chkReportTypeSelection: "1",
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),

            reimbursementPeriodlist: [],
            reimbursementPeriodId: '',
            locationList: [],
            locationId: 0,

            errors: {
                locationId: 0,
                reimbursementPeriodId: 0,
            }
        }

        this.getReimbursementPeriodList = this.getReimbursementPeriodList.bind(this);
        this.getLocation = this.getLocation.bind(this);
        this.handlePayPeriodChange = this.handlePayPeriodChange.bind(this);
        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.handlePayPeriodSelectionChange = this.handlePayPeriodSelectionChange.bind(this);
        this.handleLocationSelectionChange = this.handleLocationSelectionChange.bind(this);
        this.handleReportTypeSelectionChange = this.handleReportTypeSelectionChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFromDateChanged = this.handleFromDateChanged.bind(this);
        this.handleToDateChanged = this.handleToDateChanged.bind(this);
        this.downloadAccountPaymentTypeSummaryAsPdf = this.downloadAccountPaymentTypeSummaryAsPdf.bind(this);

        this.handleSearchAccountPaySummaryReportSubmit = this.handleSearchAccountPaySummaryReportSubmit.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
    }


    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getLocation();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {

        this.setState({ isOpenModal: false });
        await this.handleClearSearch();
    }

    async getReimbursementPeriodList() {
        try {
            var request = new Object();
            request.locationId = 0;
            request.VehicleOwnerId = 0;
            request.PayDate = '';
            request.ChequeNo = '';
            request.IsTransactionType = true;
            request.PayrollType = 0;
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('vehicle-payment-api/payroll-pedriod-dropdown/', requestParams);
            const data = await response.json();
            if (data !== null && data.vehiclePayRollPeriods.length > 0) {
                this.setState({
                    reimbursementPeriodlist: data.vehiclePayRollPeriods
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    }

    async getLocation() {
        try {
            let locationId = parseInt(sessionStorage.getItem("session_locationId"));
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('account-api/locations', requestParams);
            const data = await response.json();
            if (data !== null && data.locations.length > 0) {
                this.setState({
                    locationList: data.locations
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    handlePayPeriodChange(selectedOption) {
        this.setState({ reimbursementPeriodId: (selectedOption != null ? selectedOption.payDate : ''), })
        this.state.errors["reimbursementPeriodId"] = "";
    }

    handleLocationChange(selectedOption) {
        this.setState({ locationId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.locationId : 0), })
        this.state.errors["locationId"] = "";
    }

    handlePayPeriodSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkPaymentPeriodSelection: value, reimbursementPeriodId: '' });
        this.state.errors["reimbursementPeriodId"] = "";
    }

    handleLocationSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkLocationSelection: value, locationId: 0 });
        this.state.errors["locationId"] = "";
    }

    handleReportTypeSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkReportTypeSelection: value });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value });
    }

    handleFromDateChanged(event) {
        let fromDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ startDate: fromDateValue })
    }
    handleToDateChanged(event) {
        let toDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ endDate: toDateValue })
    }
    handleSearchAccountPaySummaryReportSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            this.downloadAccountPaymentTypeSummaryAsPdf();
        }
    }
    handleClearSearch() {
        this.setState({
            isSubmited: false,
            isEmailSubmitted: false,
            loadPanelVisible: false,
            chkPaymentPeriodSelection: "1",
            chkLocationSelection: "1",
            chkReportTypeSelection: "1",
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),

            reimbursementPeriodId: '',
            locationId: 0,

            errors: {
                locationId: 0,
                reimbursementPeriodId: 0,
            }
        });
    }

    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.locationId = "";

        if (this.state.chkLocationSelection === "1" && this.state.locationId == 0) {
            formIsValid = false;
            errors.locationId = "Please select location.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    async downloadAccountPaymentTypeSummaryAsPdf() {
        try {
            this.setState({
                isSubmited: true,
            });
            var RptHead = "";
            var request = new Object();

            request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy") + " 00:00:00";
            request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy") + " 23:59:59";
            RptHead = RptHead + "For date range " + formatDate(this.state.startDate, "MM/dd/yyyy") + " to " + formatDate(this.state.endDate, "MM/dd/yyyy");


            if (this.state.chkLocationSelection == "1") {
                request.LocationId = parseInt(this.state.locationId);
                let puZone = this.state.locationList.find(opt => opt.locationId == this.state.locationId);
                RptHead = RptHead + ", for Location " + puZone.locationName;
            } else {
                request.LocationId = parseInt(0);
                RptHead = RptHead + ", for all Location";
            }

            request.Criteria1 = RptHead;

            request.fileFormat = 'pdf';
            if (this.state.chkReportTypeSelection == "1") {
                request.MethodName = "GetTaxOnInvoicesRidesSummaryCongestion";
            } else if (this.state.chkReportTypeSelection == "2") {
                request.MethodName = "GetTaxOnInvoicesRidesCongestion";
            } else {
                request.MethodName = "GetInvoicesCongestionTax";
            }

            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            this.setState({
                isSubmited: false,
            });

            let a = document.createElement('a');
            a.href = dataResponse.reportBase64String;
            if (this.state.chkReportTypeSelection == "1") {
                a.download = 'TaxOnInvoicesRidesSummaryCongestion.pdf';
            } else if (this.state.chkReportTypeSelection == "2") {
                a.download = 'TaxOnInvoicesRidesCongestion.pdf';
            } else {
                a.download = 'InvoicesCongestionTax.pdf';
            }

            a.click();
        } catch (e) {
            this.setState({
                isSubmited: false,
            });
            console.log(e)
        }
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isEmailSubmitted: true });
                var RptHead = "";
                var request = new Object();

                request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy") + " 00:00:00";
                request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy") + " 23:59:59";
                RptHead = RptHead + "For date range " + formatDate(this.state.startDate, "MM/dd/yyyy") + " to " + formatDate(this.state.endDate, "MM/dd/yyyy");


                if (this.state.chkLocationSelection == "1") {
                    request.LocationId = parseInt(this.state.locationId);
                    let puZone = this.state.locationList.find(opt => opt.locationId == this.state.locationId);
                    RptHead = RptHead + ", for Location " + puZone.locationName;
                } else {
                    request.LocationId = parseInt(0);
                    RptHead = RptHead + ", for all Location";
                }

                request.Criteria1 = RptHead;

                request.fileFormat = 'pdf';
                if (this.state.chkReportTypeSelection == "1") {
                    request.MethodName = "GetTaxOnInvoicesRidesSummaryCongestion";
                } else if (this.state.chkReportTypeSelection == "2") {
                    request.MethodName = "GetTaxOnInvoicesRidesCongestion";
                } else {
                    request.MethodName = "GetInvoicesCongestionTax";
                }

                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                let fileName = '';
                if (this.state.chkReportTypeSelection == "1") {
                    fileName= 'TaxOnInvoicesRidesSummaryCongestion.pdf';
                } else if (this.state.chkReportTypeSelection == "2") {
                    fileName = 'TaxOnInvoicesRidesCongestion.pdf';
                } else {
                    fileName = 'InvoicesCongestionTax.pdf';
                }
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Congestion Report';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = fileName
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '45%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleSearchAccountPaySummaryReportSubmit}
                        style={(this.state.isSubmited || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Congestion Report</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">From Ride Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.startDate}
                                            value={this.state.startDate}
                                            onValueChanged={this.handleFromDateChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>To Ride Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.endDate}
                                            value={this.state.endDate}
                                            onValueChanged={this.handleToDateChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="singleLocation" value="1" checked={this.state.chkLocationSelection === "1"} onChange={this.handleLocationSelectionChange} />
                                            <label className="form-check-label">Single Location</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="allLocation" value="2" checked={this.state.chkLocationSelection === "2"} onChange={this.handleLocationSelectionChange} />
                                            <label className="form-check-label">All Location</label>
                                        </span>
                                        <SelectBox
                                            dataSource={this.state.locationList.length > 0 ? this.state.locationList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select location"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.locationId}
                                            displayExpr='locationName'
                                            valueExpr='locationId'
                                            onSelectionChanged={this.handleLocationChange}
                                            showClearButton={true}
                                            disabled={this.state.chkLocationSelection === "2"}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["locationId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["locationId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Report Type</label><br />
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdSummary" value="1" checked={this.state.chkReportTypeSelection === "1"} onChange={this.handleReportTypeSelectionChange} />
                                            <label className="form-check-label">Invoice Ride Summary</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdDetails" value="2" checked={this.state.chkReportTypeSelection === "2"} onChange={this.handleReportTypeSelectionChange} />
                                            <label className="form-check-label">Invoice Ride Details</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdCongestionTax" value="3" checked={this.state.chkReportTypeSelection === "3"} onChange={this.handleReportTypeSelectionChange} />
                                            <label className="form-check-label">Congestion Tax</label>
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="button" className="btn btn-success btn-sm" title="Download" onClick={this.handleSearchAccountPaySummaryReportSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Search"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                                </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        )
    }
}