import React, { Component, useDebugValue } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { MasterHistoryComponent } from '../Shared/MasterHistoryComponent';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import NumberFormat from 'react-number-format';
import { LoadPanel } from 'devextreme-react/load-panel';
import { SelectBox } from 'devextreme-react/select-box';
const customStyles = {
    content: {
        width: '50%',
    }
};
export class FranchiseeListComponent extends Component {
    constructor() {
        super();
        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            franchiseeModalIsOpen: false,
            franchiseeGridDataSource: null,
            franchiseeData: null,
            dropdownOwnerType: [],
            dropdownCity: [],
            id: 0,
            name: '',
            ownerTypeId: 0,
            ownerType: '',
            cityId: 0,
            cityName: '',
            zipCode: '',
            address1: '',
            address2: '',
            mobileNumber: '',
            email: '',
            contactPersonFirstName: '',
            contactPersonLastName: '',
            contactPersonDesignation: '',
            isActive: false,
            errors: {
                name: '',
                ownerType: '',
                cityName: '',
                zipCode: '',
                address1: '',
                address2: '',
                mobileNumber: '',
                email: '',
                contactPersonFirstName: '',
                contactPersonLastName: '',
                contactPersonDesignation: '',
            }
        };
        this.handleEditFranchisee = this.handleEditFranchisee.bind(this);
    };
    componentDidMount() {
        this.fetchApiData();
    };
    getFranchiseeGridList = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('franchisee-api/' + 0 + '/' + 0, requestParams);
            if (response.ok) {
                const data = await response.json();
                this.setState({ franchiseeGridDataSource: data.franchisees });
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    };
    getOwnerTypeDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('owner-type-api/dropdown-list', requestParams);
            if (response.ok) {
                const data = await response.json();
                if (data !== null) {
                    if (data.ownerType !== null) {
                        this.setState({ dropdownOwnerType: data.ownerType });
                    }
                }
            }
        } catch (ex) {
            console.log(ex);
        }
    };
    getCityDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('city-api/dropdown-list/' + 0 + "/" + 0 + "/" + 0, requestParams);
            if (response.ok) {
                const data = await response.json();
                if (data !== null) {
                    if (data.cities !== null) {
                        this.setState({ dropdownCity: data.cities });
                    }
                }
            }
        } catch (ex) {
            console.log(ex);
        }
    };
    fetchApiData = async () => {
        try {
            await this.getFranchiseeGridList();
            this.setState({ loadPanelVisible: true });
            await this.getOwnerTypeDropdownList();
            await this.getCityDropdownList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getFranchiseeGridList.bind(this)
            }
        });
    }
    handleAddFranchisee = (e) => {
        e.preventDefault();
        this.setState({
            franchiseeModalIsOpen: true,
            franchiseeData: null,
            id: 0,
        });
    };
    handleEditFranchisee = async (e, data) => {
        e.preventDefault();
        try {
            if (data != null) {
                this.setState({
                    franchiseeModalIsOpen: true,
                    franchiseeData: data,
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    };
    handleMasterHistoryModal(e, data) {
        e.preventDefault();
        this.refs.masterHistoryComponent.showModal('franchisee-api', 'Franchisee', data.name === null ? '' : data.name.trim(), data.id === null ? 0 : Number(data.id));
    };
    handleInputChange = (event) => {
        try {
            const target = event.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;
            let errors = this.state.errors;

            let regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            const regPhone = /^[0-9-\b]+$/;
            const regfax = /^[0-9\b]+$/;
            const regWebAddress = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

            switch (name) {
                case 'name':
                    errors.name = '';
                    if (event.target.value === "") {
                        this.setState({ name: "" });
                        errors.name = "Please enter franchisee name.";
                    } else {
                        this.state.errors["name"] = '';
                        this.setState({ name: event.target.value });
                    }
                    break;
                case 'ownerType':
                    errors.ownerType = "";
                    if (Number(event.target.value) === 0) {
                        this.setState({ ownerTypeId: 0 });
                        errors.ownerType = "Please select owner type.";
                    } else {
                        this.state.errors["ownerType"] = '';
                        this.setState({ ownerTypeId: Number(event.target.value), ownerType: event.target.options[event.target.selectedIndex].text });
                    }
                    break;
                case 'contactPersonFirstName':
                    errors.contactPersonFirstName = '';
                    if (event.target.value === '') {
                        this.setState({ contactPersonFirstName: '' });
                        errors.contactPersonFirstName = "Please enter first name.";
                    } else {
                        this.state.errors["contactPersonFirstName"] = '';
                        this.setState({ contactPersonFirstName: event.target.value });
                    }
                    break;
                case 'contactPersonLastName':
                    errors.contactPersonLastName = '';
                    if (event.target.value === '') {
                        this.setState({ contactPersonLastName: '' });
                        errors.contactPersonLastName = "Please enter last name.";
                    } else {
                        this.state.errors["contactPersonLastName"] = '';
                        this.setState({ contactPersonLastName: event.target.value });
                    }
                    break;
                case 'contactPersonDesignation':
                    errors.contactPersonDesignation = '';
                    if (event.target.value === '') {
                        this.setState({ contactPersonDesignation: '' });
                        errors.contactPersonDesignation = "Please enter designation.";
                    } else {
                        this.state.errors["contactPersonDesignation"] = '';
                        this.setState({ contactPersonDesignation: event.target.value });
                    }
                    break;
                case 'email':
                    errors.email = '';
                    if (event.target.value === '') {
                        this.setState({ email: event.target.value });
                        errors.email = "Please enter email.";
                    } else if (event.target.value !== '' && regEmail.test(event.target.value) !== true) {
                        this.setState({ email: event.target.value });
                        errors.email = "Please enter valid email.";
                    }
                    else {
                        this.setState({ email: event.target.value });
                    }
                    break;
                case 'mobileNumber':
                    errors.mobileNumber = '';
                    if (event.target.value === '') {
                        this.setState({ mobileNumber: '' });
                        errors.mobileNumber = "Please enter mobile number.";
                    } else {
                        this.state.errors["mobileNumber"] = '';
                        this.setState({ mobileNumber: event.target.value });
                    }
                    break;
                case 'cityName':
                    errors.cityName = '';
                    if (Number(event.target.value) === 0) {
                        this.setState({ cityId: 0 });
                        errors.cityName = "Please select city.";
                    } else {
                        this.state.errors["cityName"] = '';
                        this.setState({ cityId: Number(event.target.value), cityName: event.target.options[event.target.selectedIndex].text });
                    }
                    break;
                case 'zipCode':
                    errors.zipCode = '';
                    if (event.target.value === '') {
                        this.setState({ zipCode: '' });
                        errors.zipCode = "Please enter zipCode.";
                    } else {
                        this.state.errors["zipCode"] = '';
                        this.setState({ zipCode: event.target.value });
                    }
                    break;
                case 'address1':
                    errors.address1 = '';
                    if (event.target.value === '') {
                        this.setState({ address1: '' });
                        errors.address1 = "Please enter address1.";
                    } else {
                        this.state.errors["address1"] = '';
                        this.setState({ address1: event.target.value });
                    }
                    break;
                default:
                    this.setState({
                        [name]: value
                    });
                    break;
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    handleCitryOnChange = (selectedOption) => {
        try {
            let errors = this.state.errors;
            errors.cityName = '';
            let optCityId = (selectedOption !== null && selectedOption.selectedItem !== null) ? selectedOption.selectedItem.id : 0;
            let optCityName = (selectedOption !== null && selectedOption.selectedItem !== null) ? selectedOption.selectedItem.name : '';
            errors.cityName = Number(optCityId) === 0 ? 'Please select city.' : '';
            this.setState({ errors: errors, cityId: optCityId, cityName: optCityName });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOpenFranchiseeModal = async () => {
        try {
            if (this.state.franchiseeData === null) {
                this.setState({
                    id: 0,
                    name: '',
                    ownerTypeId: 0,
                    ownerType: '',
                    cityId: 0,
                    cityName: '',
                    zipCode: '',
                    address1: '',
                    address2: '',
                    mobileNumber: '',
                    email: '',
                    contactPersonFirstName: '',
                    contactPersonLastName: '',
                    contactPersonDesignation: '',
                    isActive: true,
                });
            } else {
                let franchisee = this.state.franchiseeData;
                this.setState({
                    id: franchisee.id === null ? 0 : Number(franchisee.id),
                    name: franchisee.name === null ? '' : franchisee.name.trim(),
                    ownerTypeId: franchisee.ownerTypeId === null ? 0 : Number(franchisee.ownerTypeId),
                    ownerType: franchisee.ownerType === null ? '' : franchisee.ownerType.trim(),
                    cityId: franchisee.cityId === null ? 0 : Number(franchisee.cityId),
                    cityName: franchisee.locationName === null ? '' : franchisee.locationName.trim(),
                    zipCode: franchisee.zipCode === null ? '' : franchisee.zipCode.trim(),
                    address1: franchisee.address1 === null ? '' : franchisee.address1.trim(),
                    address2: franchisee.address2 === null ? '' : franchisee.address2.trim(),
                    mobileNumber: franchisee.mobileNo === null ? '' : franchisee.mobileNo.trim(),
                    email: franchisee.emailId === null ? '' : franchisee.emailId.trim(),
                    contactPersonFirstName: franchisee.contactPersonFirstName === null ? '' : franchisee.contactPersonFirstName.trim(),
                    contactPersonLastName: franchisee.contactPersonLastName === null ? '' : franchisee.contactPersonLastName.trim(),
                    contactPersonDesignation: franchisee.contactPersonDesignation === null ? '' : franchisee.contactPersonDesignation.trim(),
                    isActive: franchisee.enable || false,
                });
            }
            this.submitValidator.focus();
        } catch (ex) {
            console.log(ex);
        }
    }
    handleCloseFranchiseeModal = async () => {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            franchiseeModalIsOpen: false,
            franchiseeData: null,
            id: 0,
            name: '',
            ownerTypeId: 0,
            ownerType: '',
            cityId: 0,
            cityName: '',
            zipCode: '',
            address1: '',
            address2: '',
            mobileNumber: '',
            email: '',
            contactPersonFirstName: '',
            contactPersonLastName: '',
            contactPersonDesignation: '',
            isActive: false,
            errors: {
                name: '',
                ownerType: '',
                cityName: '',
                zipCode: '',
                address1: '',
                address2: '',
                mobileNumber: '',
                email: '',
                contactPersonFirstName: '',
                contactPersonLastName: '',
                contactPersonDesignation: '',
            }
        });
    }
    handleFranchiseeSubmit = async event => {
        event.preventDefault();
        try {
            if (this.isFormValid()) {
                this.setState({ isSubmited: true });
                var request = new Object();
                request.Id = Number(this.state.id);
                request.Name = this.state.name.trim();
                request.FranchiseTypeId = Number(this.state.ownerTypeId);
                request.CityId = Number(this.state.cityId);
                request.ZipCode = this.state.zipCode;
                request.Address1 = this.state.address1.trim();
                request.Address2 = this.state.address2.trim();
                request.MobileNo = Number(this.state.mobileNumber.replace("(", "").replace(")", "").replace(" ", "").replace("-", ""));
                request.EmailId = this.state.email.trim();
                request.ContactPersonFirstName = this.state.contactPersonFirstName.trim();
                request.ContactPersonLastName = this.state.contactPersonLastName.trim();
                request.ContactPersonDesignation = this.state.contactPersonDesignation.trim();
                request.Enable = this.state.isActive;
                request.addEditHistory = this.setAddEditHistory();
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('franchisee-api', requestParams);
                if (response.ok) {
                    const data = await response.json();
                    await Swal.fire({
                        allowOutsideClick: false,
                        icon: data.isSuccess ? 'success' : 'error',
                        title: data.isSuccess ? 'Success' : 'Oops...',
                        text: (data.isSuccess === false && (data.resultMessage === null || data.resultMessage === "")) ? 'Unable to process the request.' : data.resultMessage,
                    }).then(async (result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (data.isSuccess) {
                            if (result.isConfirmed) {
                                await this.handleCloseFranchiseeModal();
                                await this.getFranchiseeGridList();
                            }
                        }
                    })
                } else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Unable to process the request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Unable to process the request.',
            })
        }
        this.setState({ isSubmited: false })
    }
    isFormValid() {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            let isFocus = false;
            errors.name = "";
            errors.ownerType = '';
            errors.cityName = "";
            errors.zipCode = "";
            errors.address1 = "";
            errors.address2 = '';
            errors.mobileNumber = "";
            errors.email = "";
            errors.contactPersonFirstName = "";
            errors.contactPersonLastName = "";
            errors.contactPersonDesignation = '';
            if (this.state.name.trim() === '') {
                formIsValid = false;
                errors.name = "Please enter franchisee name.";
                if (isFocus === false) { this.franchiseeNameInputValidator.focus(); isFocus = true; }
            }
            else {
                if (this.state.name.length > 35) {
                    formIsValid = false;
                    errors.name = "Please enter franchisee name maximum length is 35 characters.";
                    if (isFocus === false) { this.franchiseeNameInputValidator.focus(); isFocus = true; }
                }
            }
            if (Number(this.state.ownerTypeId) === 0 || Number(this.ownerTypeSelectValueValidator.value) === 0) {
                formIsValid = false;
                errors.ownerType = "Please select owner type.";
                if (isFocus === false) { this.ownerTypeSelectValueValidator.focus(); isFocus = true; }
            }
            if (this.state.contactPersonFirstName.trim() === '') {
                formIsValid = false;
                errors.contactPersonFirstName = "Please enter first name.";
                if (isFocus === false) { this.contactPersonFirstNameInputValidator.focus(); isFocus = true; }
            }
            else {
                if (this.state.contactPersonFirstName.length > 25) {
                    formIsValid = false;
                    errors.contactPersonFirstName = "Please enter first name maximum length is 25 characters.";
                    if (isFocus === false) { this.contactPersonFirstNameInputValidator.focus(); isFocus = true; }
                }
            }
            if (this.state.contactPersonLastName.trim() === '') {
                formIsValid = false;
                errors.contactPersonLastName = "Please enter last name.";
                if (isFocus === false) { this.contactPersonLastNameInputValidator.focus(); isFocus = true; }
            }
            else {
                if (this.state.contactPersonLastName.length > 25) {
                    formIsValid = false;
                    errors.contactPersonLastName = "Please enter last name maximum length is 25 characters.";
                    if (isFocus === false) { this.contactPersonLastNameInputValidator.focus(); isFocus = true; }
                }
            }
            if (this.state.contactPersonDesignation.trim() === '') {
                formIsValid = false;
                errors.contactPersonDesignation = "Please enter designation.";
                if (isFocus === false) { this.contactPersonDesignationInputValidator.focus(); isFocus = true; }
            }
            else {
                if (this.state.contactPersonDesignation.length > 30) {
                    formIsValid = false;
                    errors.contactPersonDesignation = "Please enter designation maximum length is 30 characters.";
                    if (isFocus === false) { this.contactPersonDesignationInputValidator.focus(); isFocus = true; }
                }
            }
            if (this.state.email.trim() === '') {
                formIsValid = false;
                errors.email = "Please enter email.";
                if (isFocus === false) { this.emailInputValidator.focus(); isFocus = true; }
            }
            else {
                const regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (regEmail.test(String(this.state.email.trim()).toLowerCase()) === false) {
                    formIsValid = false;
                    errors.email = "Please enter valid email.";
                    if (isFocus === false) { this.emailInputValidator.focus(); isFocus = true; }
                }
            }
            if (this.state.mobileNumber === '') {
                formIsValid = false;
                errors.mobileNumber = "Please enter mobile number.";
                if (isFocus === false) { this.mobileNumberInputValidator.focus(); isFocus = true; }
            } else if (this.state.mobileNumber.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length != 10) {
                formIsValid = false;
                errors.mobileNumber = "Please enter valid mobile number.";
                if (isFocus === false) { this.mobileNumberInputValidator.focus(); isFocus = true; }            
            }
            if (this.cityNameSelectValueValidator.instance.option('selectedItem') === null || Number(this.state.cityId) === 0) {
                formIsValid = false;
                errors.cityName = "Please select city.";
                if (isFocus === false) { this.cityNameSelectValueValidator.instance.focus(); isFocus = true; }
            }
            if (this.state.zipCode === '') {
                formIsValid = false;
                errors.zipCode = "Please enter zip code.";
                if (isFocus === false) { this.zipCodeInputValidator.focus(); isFocus = true; }
            }
            else {
                if (this.state.zipCode.length > 10) {
                    formIsValid = false;
                    errors.zipCode = "Please enter zip code maximum length is 10 characters.";
                    if (isFocus === false) { this.zipCodeInputValidator.focus(); isFocus = true; }
                }
            }
            if (this.state.address1.trim() === '') {
                formIsValid = false;
                errors.address1 = "Please enter address1.";
                if (isFocus === false) { this.address1InputValidator.focus(); isFocus = true; }
            }
            else {
                if (this.state.address1.length > 100) {
                    formIsValid = false;
                    errors.address1 = "Please enter address1 maximum length is 100 characters.";
                    if (isFocus === false) { this.address1InputValidator.focus(); isFocus = true; }
                }
            }
            if (this.state.address2.length > 100) {
                formIsValid = false;
                errors.address2 = "Please enter address2 maximum length is 100 characters.";
                if (isFocus === false) { this.address2InputValidator.focus(); isFocus = true; }
            }
            if (isFocus === false) { this.submitValidator.focus(); }

            this.setState({ errors: errors });
        } catch (ex) {
            formIsValid = false;
            console.log(ex);
        }
        return formIsValid;
    }
    setAddEditHistory() {
        let history = '';
        try {
            let sep = '. ';
            let oldData = this.state.franchiseeData;
            if (Number(this.state.id) === 0) {
                history = 'New franchisee is added' + sep;
            }
            else {
                let oldname = oldData.name === null ? '' : oldData.name.trim();
                if (oldname !== this.state.name.trim()) {
                    if (oldname.length > 0 && this.state.name.trim().length > 0) {
                        history += 'Franchisee Name is changed from ' + oldname + ' To ' + this.state.name.trim() + sep;
                    }
                    else {
                        history += 'Franchisee Name is updated ' + this.state.name.trim() + sep;
                    }
                }
                if (Number(oldData.ownerTypeId) !== Number(this.state.ownerTypeId)) {
                    if (Number(oldData.ownerTypeId) > 0 && Number(this.state.ownerTypeId) > 0) {
                        history += 'Owner type is changed from ' + oldData.ownerType + ' To ' + this.state.ownerType + sep;
                    }
                    else {
                        history += 'Owner type is updated ' + this.state.ownerType.trim() + sep;
                    }
                }
                let oldcontactPersonFirstName = oldData.contactPersonFirstName === null ? '' : oldData.contactPersonFirstName.trim();
                if (oldcontactPersonFirstName !== this.state.contactPersonFirstName.trim()) {
                    if (oldcontactPersonFirstName.length > 0 && this.state.contactPersonFirstName.trim().length > 0) {
                        history += 'First name is changed from ' + oldcontactPersonFirstName + ' To ' + this.state.contactPersonFirstName.trim() + sep;
                    }
                    else {
                        history += 'First Name is updated ' + this.state.contactPersonFirstName.trim() + sep;
                    }

                }
                let oldcontactPersonLastName = oldData.contactPersonLastName === null ? '' : oldData.contactPersonLastName.trim();
                if (oldcontactPersonLastName !== this.state.contactPersonLastName.trim()) {
                    if (oldcontactPersonLastName.length > 0 && this.state.contactPersonLastName.trim().length > 0) {
                        history += 'Last name is changed from ' + oldcontactPersonLastName + ' To ' + this.state.contactPersonLastName.trim() + sep;
                    }
                    else {
                        history += 'Last Name is updated ' + this.state.contactPersonLastName.trim() + sep;
                    }
                }
                let oldcontactPersonDesignation = oldData.contactPersonDesignation === null ? '' : oldData.contactPersonDesignation.trim();
                if (oldcontactPersonDesignation !== this.state.contactPersonDesignation.trim()) {
                    if (oldcontactPersonDesignation.length > 0 && this.state.contactPersonDesignation.trim().length > 0) {
                        history += 'Designation is changed from ' + oldcontactPersonDesignation + ' To ' + this.state.contactPersonDesignation.trim() + sep;
                    }
                    else {
                        history += 'Designation is updated ' + this.state.contactPersonDesignation.trim() + sep;
                    }
                }
                let oldemail = oldData.emailId === null ? '' : oldData.emailId.trim();
                if (oldemail !== this.state.email.trim()) {
                    if (oldemail.length > 0 && this.state.email.trim().length > 0) {
                        history += 'Email is changed from ' + oldemail + ' To ' + this.state.email.trim() + sep;
                    }
                    else {
                        history += 'Email is updated ' + this.state.email.trim() + sep;
                    }
                }
                let oldmobileNumber = oldData.mobileNo === null ? '' : oldData.mobileNo.trim();
                if (oldmobileNumber !== this.state.mobileNumber.trim()) {
                    if (oldmobileNumber.length > 0 && this.state.mobileNumber.trim().length > 0) {
                        history += 'Mobile number is changed from ' + oldmobileNumber + ' To ' + this.state.mobileNumber + sep;
                    }
                    else {
                        history += 'Mobile is updated ' + this.state.mobileNumber.trim() + sep;
                    }
                }
                if (Number(oldData.cityId) !== Number(this.state.cityId)) {
                    if (Number(oldData.cityId) > 0 && Number(this.state.cityId) > 0) {
                        history += 'City is changed from ' + oldData.locationName + ' To ' + this.state.cityName.trim() + sep;
                    }
                    else {
                        history += 'City is updated ' + this.state.cityName.trim() + sep;
                    }

                }
                let oldzipCode = oldData.zipCode === null ? '' : oldData.zipCode.trim();
                if (oldzipCode !== this.state.zipCode.trim()) {
                    if (oldzipCode.length > 0 && this.state.zipCode.trim().length > 0) {
                        history += 'Zip code is changed from ' + oldzipCode + ' To ' + this.state.zipCode.trim() + sep;
                    }
                    else {
                        history += 'Zip code is updated ' + this.state.zipCode.trim() + sep;
                    }
                }
                let oldaddress1 = oldData.address1 === null ? '' : oldData.address1.trim();
                if (oldaddress1 !== this.state.address1.trim()) {
                    if (oldaddress1.length > 0 && this.state.zipCode.trim().length > 0) {
                        history += 'Adddress1 is changed from ' + oldaddress1 + ' To ' + this.state.address1.trim() + sep;
                    }
                    else {
                        history += 'Adddress1 is updated ' + this.state.address1.trim() + sep;
                    }
                }
                let oldaddress2 = oldData.address2 === null ? '' : oldData.address2.trim();
                if (oldaddress2 !== this.state.address2.trim()) {
                    if (oldaddress2.length > 0 && this.state.address2.trim().length > 0) {
                        history += 'Adddress2 is changed from ' + oldaddress2 + ' To ' + this.state.address2.trim() + sep;
                    }
                    else {
                        history += 'Adddress2 is updated ' + this.state.address2.trim() + sep;
                    }
                }
                if (oldData.enable !== this.state.isActive) {
                    history += 'Franchisee is ' + (this.state.isActive === true ? 'Activated' : 'Inactivated') + sep;
                }
                if (history === '') {
                    history += 'Franchisee has been successfully updated' + sep;
                }
            }
        } catch (ex) {
            if (history === '') {
                history += 'Franchisee has been successfully updated.';
            }
            console.log(ex);
        }
        return history;
    };
    render() {
        const renderGridCell = (data) => {
            return <div style={{ textAlign: 'left' }}>
                <a href="#" className="fas fa-pen icon text-info" title="Edit" onClick={(e) => { this.handleEditFranchisee(e, data.data) }} ></a>&nbsp;&nbsp;
                <a href="#" className="fas fa-history icon text-info" title="History" onClick={(e) => { this.handleMasterHistoryModal(e, data.data) }}></a>
            </div>;
        }
        const contactPersonName = (data) => {
            return <div>
                {data.data.contactPersonFirstName + ' ' + data.data.contactPersonLastName}
            </div>
        }
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <h1 className="m-0 text-dark">Franchisee</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem><NavLink tag={Link} to="#" className="btn btn-block btn-outline-secondary btn-sm" onClick={(e) => { this.handleAddFranchisee(e) }} title="Add Franchisee"><i className="fas fa-plus"></i> Add Franchisee</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="card card-grid">
                        <div className="card-body">
                            <DataGrid
                                dataSource={this.state.franchiseeGridDataSource}
                                keyExpr="id"
                                selection={{ mode: 'single' }}
                                columnsAutoWidth={true}
                                showColumnLines={false}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                onToolbarPreparing={this.handleToolbarPreparing}>
                                <Column dataField="id" width={40} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                <Column dataField="ownerType" caption="Franchisee Type" />
                                <Column dataField="name" caption="Franchisee" />
                                <Column dataField="locationName" caption="Location" />
                                <Column dataField="address1" caption="Address" />
                                <Column dataField="mobileNo" caption="Mobile Number" />
                                <Column dataField="emailId" caption="Email" />
                                <Column dataField="contactPersonFirstName" caption="Contact Preson" />
                                <Column dataField="status" caption="Status" />
                                {/*
                                //<Column dataField="contactPersonFirstName" caption="Contact Person" cellRender={contactPersonName} />
                                */}
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true} />
                                <Export enabled={true} allowExportSelectedData={false} />
                                <ColumnChooser enabled={true} />
                                <ColumnFixing enabled={true} />
                            </DataGrid>
                        </div>
                    </div>
                </div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.franchiseeModalIsOpen}
                    style={customStyles}
                    className={"react-modal"}
                    onAfterOpen={this.handleOpenFranchiseeModal}
                    onRequestClose={this.handleCloseFranchiseeModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleFranchiseeSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{Number(this.state.id) > 0 ? "Modify Franchisee" : "Add Franchisee"}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCloseFranchiseeModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-8 col-xs-8">
                                    <div className="form-group">
                                        <label>Franchisee Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-textbox form-control-sm" name="name" title="Franchisee Name" placeholder="Enter franchisee name" maxLength="35" ref={input => this.franchiseeNameInputValidator = input} value={this.state.name} onChange={this.handleInputChange} />
                                        {this.state.errors["name"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["name"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-8 col-xs-8">
                                    <div className="form-group">
                                        <label>Owner Type <span className="text-danger">*</span></label>
                                        <select name="ownerType" className="form-control form-control-sm" title="Owner Type" placeholder="Select owner type" disabled={Number(this.state.id) > 0 ? true : false} ref={select => this.ownerTypeSelectValueValidator = select} value={this.state.ownerTypeId} onChange={this.handleInputChange}>
                                            <option value={0}>Select owner type</option>
                                            {
                                                this.state.dropdownOwnerType &&
                                                this.state.dropdownOwnerType.map((item, i) =>
                                                    (<option key={i} value={item.id}>{item.name}</option>))
                                            }
                                        </select>
                                        {this.state.errors["ownerType"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["ownerType"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-8 col-xs-8">
                                    <div className="form-group">
                                        <label>First Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-textbox form-control-sm" name="contactPersonFirstName" title="First Name" placeholder="Enter first name" maxLength="25" ref={input => this.contactPersonFirstNameInputValidator = input} value={this.state.contactPersonFirstName} onChange={this.handleInputChange} />
                                        {this.state.errors["contactPersonFirstName"].length > 0 && <span className="error invalid-feedback">{this.state.errors["contactPersonFirstName"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-8 col-xs-8">
                                    <div className="form-group">
                                        <label>Last Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-textbox form-control-sm" name="contactPersonLastName" title="Last Name" placeholder="Enter last name" maxLength="25" ref={input => this.contactPersonLastNameInputValidator = input} value={this.state.contactPersonLastName} onChange={this.handleInputChange} />
                                        {this.state.errors["contactPersonLastName"].length > 0 && <span className="error invalid-feedback">{this.state.errors["contactPersonLastName"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-8 col-xs-8">
                                    <div className="form-group">
                                        <label>Designation <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-textbox form-control-sm" name="contactPersonDesignation" title="Designation" placeholder="Enter designation" maxLength="30" ref={input => this.contactPersonDesignationInputValidator = input} value={this.state.contactPersonDesignation} onChange={this.handleInputChange} />
                                        {this.state.errors["contactPersonDesignation"].length > 0 && <span className="error invalid-feedback">{this.state.errors["contactPersonDesignation"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>Email <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-textbox form-control-sm" name="email" title="Email" placeholder="Enter email address" maxLength="50" ref={input => this.emailInputValidator = input} value={this.state.email} onChange={this.handleInputChange} />
                                        {this.state.errors["email"].length > 0 && <span className="error invalid-feedback">{this.state.errors["email"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>Mobile <span className="text-danger">*</span></label>
                                        <NumberFormat className="form-control form-textbox form-control-sm" name="mobileNumber" title="Mobile Number" placeholder="(000) 000-0000" format="(###) ###-####" value={this.state.mobileNumber} onChange={this.handleInputChange} getInputRef={(el) => this.mobileNumberInputValidator = el}/>
                                        {this.state.errors["mobileNumber"].length > 0 && <span className="error invalid-feedback">{this.state.errors["mobileNumber"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>City <span className="text-danger">*</span></label>
                                        {/* <select name="cityName" className="form-control form-control-sm" title="City" placeholder="City" ref={select => this.cityNameSelectValueValidator = select} value={this.state.cityId} onChange={this.handleInputChange}>
                                                <option value={0}>---Select City---</option>
                                                {
                                                    this.state.dropdownCity &&
                                                    this.state.dropdownCity.map((item, i) =>
                                                        (<option key={i} value={item.id}>{item.name}</option>))
                                                }
                                            </select>*/}
                                        {/*<Select ref={select => this.cityNameSelectValueValidator = select}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select city"
                                            defaultValue={[]}
                                            value={this.state.dropdownCity.id === 0 ? [] : [this.state.dropdownCity.find(opt => opt.id === this.state.cityId)]}
                                            name="cityName"
                                            onChange={this.handleCitryOnChange}
                                            getOptionLabel={option => option.name}
                                            getOptionValue={option => option.id}
                                            options={this.state.dropdownCity.length > 0 ? this.state.dropdownCity : []}
                                            isMulti={false}
                                            isClearable={true}
                                            isDisabled={false}
                                            isSearchable={true} />*/}
                                        <SelectBox
                                            ref={select => this.cityNameSelectValueValidator = select}
                                            dataSource={this.state.dropdownCity}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select city"
                                            name="ddcity"
                                            defaultValue={0}
                                            value={this.state.cityId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleCitryOnChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["cityName"].length > 0 && <span className="error invalid-feedback">{this.state.errors["cityName"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>Zip Code <span className="text-danger">*</span></label>
                                        <NumberFormat className="form-control form-textbox form-control-sm" name="zipCode" title="Zip Code" placeholder="Enter zip code" maxLength="10" getInputRef={(el) => this.zipCodeInputValidator = el} value={this.state.zipCode} onChange={this.handleInputChange} />
                                        {this.state.errors["zipCode"].length > 0 && <span className="error invalid-feedback">{this.state.errors["zipCode"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Address 1 <span className="text-danger">*</span></label>
                                        <textarea className="form-control form-textbox form-control-sm" name="address1" title="Address1" placeholder="Enter address 1" maxLength="100" ref={input => this.address1InputValidator = input} value={this.state.address1} onChange={this.handleInputChange} />
                                        {this.state.errors["address1"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["address1"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Address 2</label>
                                        <textarea className="form-control form-textbox form-control-sm" name="address2" title="Address2" placeholder="Enter address 2" maxLength="100" ref={input => this.address2InputValidator = input} value={this.state.address2} onChange={(e) => { this.setState({ address2: e.target.value }) }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ display: this.state.id > 0 ? 'block' : 'none' }}>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="isActive" name="isActive" checked={this.state.isActive} onChange={(e) => { this.setState({ isActive: e.target.checked }) }} />
                                            <label className="form-check-label" htmlFor="isActive"> Is Active</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success btn-sm" ref={button => this.submitValidator = button}>
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleCloseFranchiseeModal} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <MasterHistoryComponent ref="masterHistoryComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>

        )
    };
};