import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
    Scrolling,
    Selection, FilterRow,
} from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import { LoadPanel } from 'devextreme-react/load-panel';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import { Link } from 'react-router-dom';
import DropDownBox from 'devextreme-react/drop-down-box';
import { SelectBox } from 'devextreme-react/select-box';
import { NavItem, NavLink } from 'reactstrap';
import Swal from 'sweetalert2';
import { VehicleStatus } from '../../helpers/fixcodes.js';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { SendMessageComponent } from '../Shared/SendMessageComponent';
export class LoggedVehiclesComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitted: false,
            isLogoutAllVehicleSubmitted: false,
            isLogoutVehicleSubmitted: false,
            isOpenDriverLoginModal: false,
            vehicleGroupTypesList: [],
            vehicleGroupTypesDropdownData: null,
            loggedVehicleList: null,
            loggedVehicleGriDatasource: null,
            vehicleId: 0,
            vehicleNumber: '',
            driverId: 0,
            loggedDriverList: [],
            loggedDriverId: [],
            loadPanelVisible: false,
            errors: {
                driver: ''
            }
        }
    }
    async componentDidMount() {
        await this.getVehicleGroupTypeList();
        await this.getLoggedVehicleList();
    }
    getVehicleGroupTypeList = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            const requestParams = getRequestParams('GET');
            const response = await fetch('vehicle-group-type-api/vehicle-group-type', requestParams);
            const data = await response.json();
            if (data !== null && data.vehicleGroupTypes.length > 0) {
                this.setState({
                    vehicleGroupTypesList: data.vehicleGroupTypes,
                });
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    getLoggedVehicleList = async () => {
        this.setState({ loadPanelVisible: true });
        try {
            let vehicleGroupIds = '';
            //if (this.state.vehicleGroupTypesDropdownData !== null) {
            //    if (Object.keys(this.state.vehicleGroupTypesDropdownData).length > 0) {
            //        vehicleGroupIds = this.state.vehicleGroupTypesDropdownData.join(',');
            //    }
            //}
            var newObject = {};
            newObject.VehicleNo = "";
            newObject.VehicleTypeId = vehicleGroupIds;
            newObject.IsDriverLoggedIn = false;
            const requestParams = getRequestParams('POST', newObject);
            const response = await fetch('vehicle-api/logged-list/', requestParams);
            const data = await response.json();
            this.setState({ loggedVehicleGriDatasource: data.loggedVehicles, loggedVehicleList: data.loggedVehicles });
            await this.filterGridOnVehicleGroup();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }

    handleOnToolbarPreparing = (e) => {
        try {
            e.toolbarOptions.items.unshift({
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    onClick: this.getLoggedVehicleList.bind(this)
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnCellPrepared = (e) => {
        try {
            if (e.rowType === "data" && e.column.dataField !== "id") {
                if (e.data.isDriverLoggedIn === true) {
                    e.cellElement.style.backgroundColor = "lightgreen";
                    e.cellElement.style.color = "black";
                }
                if (e.data.isDriverLoggedIn === true && (e.data.jobId > 0)) {
                    e.cellElement.style.backgroundColor = "greenyellow";
                    e.cellElement.style.color = "black";
                }
                else {
                    if (e.data.isMdtLoggedIn === true && parseInt(e.data.workHour) > 12) {
                        e.cellElement.style.backgroundColor = "red";
                        e.cellElement.style.color = "black";
                    }
                }
            }
        } catch (ex) {
            console.log(ex);
        }
    }

    handleOnVehicleGroupDropdownChange = async (e) => {
        try {
            this.setState({
                vehicleGroupTypesDropdownData: e.value || []
            });
            await new Promise(resolve => setTimeout(resolve, 500)); // 1 sec
            await this.filterGridOnVehicleGroup();
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnVehicleGroupSelectionChange = async (data) => {
        try {
            this.setState({
                vehicleGroupTypesDropdownData: data.selectedRowKeys.length && data.selectedRowKeys || []
            });
            await new Promise(resolve => setTimeout(resolve, 500)); // 1 sec
            await this.filterGridOnVehicleGroup();
        } catch (ex) {
            console.log(ex);
        }
    }
    filterGridOnVehicleGroup = async () => {
        try {
            if (this.state.vehicleGroupTypesDropdownData !== null && this.state.vehicleGroupTypesDropdownData.length > 0) {
                let data = this.state.loggedVehicleList.filter(item => this.state.vehicleGroupTypesDropdownData.includes(item.vehicleTypeGroupId));
                data = JSON.parse(JSON.stringify(data));
                this.setState({ loggedVehicleGriDatasource: data });
            }
            else {
                this.setState({ loggedVehicleGriDatasource: this.state.loggedVehicleList });
            }
        } catch (ex) {
            console.log(ex);
        }
    }

    handleOnLogoutAllDriverSubmit = async (e) => {
        e.preventDefault();
        try {
            let isResult = false;
            let isSuccess = false;
            if (this.state.loggedVehicleGriDatasource !== null && this.state.loggedVehicleGriDatasource.length > 0) {
                await Swal.fire({
                    title: "Confirmation",
                    html: "Are you sure to logout all driver(s)?",
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.isConfirmed) {
                        isResult = true;
                    }
                })
                if (isResult) {
                    this.setState({ isLogoutAllVehicleSubmitted: true });
                    let data = this.state.loggedVehicleGriDatasource;
                    for (let i = 0; i < data.length; i++) {
                        let lngVehicleId = data[i].vehicleId;
                        let strVehicleNo = data[i].vehicleNo;
                        let lngDriverId = data[i].driverId;
                        let strDriverName = data[i].driverName;
                        if (data[i].isDriverLoggedIn === true && data[i].statusId === VehicleStatus.AVAILABLE) {

                            var requestLogoutDriver = {};
                            requestLogoutDriver.DriverName = strDriverName;
                            requestLogoutDriver.VehicleId = lngVehicleId;
                            requestLogoutDriver.IsDriverLoggedIn = false;
                            const requestParams = getRequestParams('POST', requestLogoutDriver);
                            const response = await fetch('driver-api/' + lngDriverId + "/login-logout", requestParams);
                            const responseData = await response.json();
                            if (responseData.isSuccess) {
                                isSuccess = true;
                                if (responseData.RetValue > 0) {
                                    var requestLoginDriverHistory = {};
                                    requestLoginDriverHistory.VehicleId = lngVehicleId;
                                    requestLoginDriverHistory.LoginTypeId = 2;
                                    requestLoginDriverHistory.LoggedInByUserId = 0;
                                    requestLoginDriverHistory.IsLogin = false;
                                    const requestParams = getRequestParams('POST', requestLoginDriverHistory);
                                    const response = await fetch('driver-api/' + lngDriverId + "/login-history", requestParams);
                                    const resultData = await response.json();
                                }
                            }
                        }
                    }
                    if (isSuccess) {
                        await this.showMessage('success', 'Success', 'All driver logout successfully.');
                        await this.getLoggedVehicleList();
                    }
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isLogoutAllVehicleSubmitted: false });
    }
    handleOnShowDriverMessageModal = (e, data) => {
        e.preventDefault();
        try {
            this.refs.SendMessageComponentModel.showModal(data);
        } catch (ex) {
            console.log(ex);
        }
    }

    handleOnUpdateMdtStatus = async (e, vehicleId, isMdtOffline) => {
        e.preventDefault();
        try {
            await Swal.fire({
                title: "Confirmation",
                html: "Are you sure to take the Mdt " + (isMdtOffline ? "offline" : "online"),
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const requestParams = getRequestParams('PUT', {});
                    const response = await fetch('vehicle-api/' + vehicleId + "/mdt-status/" + isMdtOffline, requestParams);
                    const responseData = await response.json();
                    await Swal.fire({
                        icon: responseData.isSuccess ? 'success' : 'error',
                        title: responseData.isSuccess ? 'Success' : 'Oops...',
                        text: responseData.resultMessage,
                    }).then(async (result) => {
                        if (responseData.isSuccess) {
                            if (result.isConfirmed) {
                                await this.getLoggedVehicleList();
                            }
                        }
                    });
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }

    handleOnLoginDriverModel = (e, data) => {
        e.preventDefault();
        try {
            this.setState({ isOpenDriverLoginModal: true, vehicleId: data.vehicleId, vehicleNumber: data.vehicleNo });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnLogoutDriverEvent = async (e, data) => {
        e.preventDefault();
        try {
            let isResult = false;
            let lngVehicleId = data.vehicleId;
            let strVehicleNo = data.vehicleNo;
            let lngDriverId = data.driverId;
            let strDriverName = data.driverName;

            await Swal.fire({
                title: "Confirmation",
                html: "Are you sure to logout the driver?",
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }).then((result) => {
                if (result.isConfirmed) {
                    isResult = true;
                }
            })
            if (isResult) {
                if (data.isDriverLoggedIn === false) {
                    await this.showMessage('info', 'Logout Driver vehicle', strVehicleNo + 'Vehicle is not logged in!');
                }
                else {
                    //if (data.statusId !== VehicleStatus.AVAILABLE) {
                    //    Swal.fire({
                    //        icon: 'info',
                    //        title: 'Logout Driver vehicle',
                    //        text: strVehicleNo + ' Vehicle is not free!',
                    //    }).then((result) => {
                    //        return
                    //    })
                    //}
                    this.setState({ isLogoutVehicleSubmitted: true, vehicleId: lngVehicleId });
                    var requestLogoutDriver = {};
                    requestLogoutDriver.DriverName = strDriverName;
                    requestLogoutDriver.VehicleId = lngVehicleId;
                    requestLogoutDriver.IsDriverLoggedIn = false;
                    const requestParams = getRequestParams('POST', requestLogoutDriver);
                    const response = await fetch('driver-api/' + lngDriverId + "/login-logout", requestParams);
                    const responseData = await response.json();
                    await Swal.fire({
                        icon: responseData.isSuccess ? 'success' : 'error',
                        title: responseData.isSuccess ? 'Success' : 'Oops...',
                        text: responseData.resultMessage,
                    }).then(async (result) => {
                        if (responseData.isSuccess) {
                            if (responseData.RetValue > 0) {
                                var requestLoginDriverHistory = {};
                                requestLoginDriverHistory.VehicleId = lngVehicleId;
                                requestLoginDriverHistory.LoginTypeId = 2;
                                requestLoginDriverHistory.LoggedInByUserId = 0;
                                requestLoginDriverHistory.IsLogin = false;
                                const requestParams = getRequestParams('POST', requestLoginDriverHistory);
                                const response = await fetch('driver-api/' + lngDriverId + "/login-history", requestParams);
                                const data = await response.json();
                            }
                            if (result.isConfirmed) {
                                await this.getLoggedVehicleList();
                            }
                        }
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isLogoutVehicleSubmitted: false, vehicleId: 0 });
    }

    handleOnAfterOpenDriverLoginModal = async () => {
        try {
            await this.getLoggedDriverList();
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnCloseDriverLoginModal = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isOpenDriverLoginModal: false,
                vehicleId: 0,
                vehicleNumber: '',
                driverId: 0,
                loggedDriverList: [],
                loggedDriverId: [],
                loadPanelVisible: false,
                errors: {
                    driver: ''
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }

    getLoggedDriverList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('driver-api/dropdown', requestParams);
            const data = await response.json();
            if (data !== null && data.driversDropdownList.length > 0) {
                this.setState({
                    loggedDriverList: data.driversDropdownList,
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnInputChange = (event) => {
        let errors = this.state.errors;
        try {
            const target = event.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;
            switch (name) {
                case 'driverId':
                    const re = /^[0-9\b]+$/;
                    errors.driver = '';
                    if (value.trim() === '') {
                        this.setState({ driverId: 0 });
                        errors.driver = "Please select driver.";
                    } else {
                        if (value.trim().length <= 10 && re.test(value)) {
                            let driverId = Number(value);
                            this.setState({ driverId: driverId, loggedDriverId: [] });
                            let driverData = [... new Set(this.state.loggedDriverList.filter(x => x.id === Number(value)))];
                            if (driverData !== null && driverData.length > 0) {
                                this.setState({ loggedDriverId: { id: driverId, name: driverData[0].name } });
                            }
                        }
                    }
                    break;
                default:
                    this.setState({
                        [name]: value
                    });
                    break;
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ errors: errors });
    }
    handleOnloggedDriverChange = (selectedOption) => {
        let errors = this.state.errors;
        try {
            errors.driver = '';
            let driverId = 0;
            let selectedItem = [];
            if (selectedOption !== null && selectedOption.selectedItem !== null) {
                driverId = selectedOption.selectedItem.id;
                selectedItem = selectedOption.selectedItem;
            }
            else {
                errors.driver = 'Please select driver.';
            }
            this.setState({ loggedDriverId: selectedItem, driverId: driverId });
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ errors: errors });
    }
    handleOnDriverLoginSubmit = async (e) => {
        e.preventDefault();
        try {
            if (await this.isLogingFormValid() === true) {
                this.setState({ isSubmitted: true })
                var requestLogoutDriver = {};
                requestLogoutDriver.DriverName = this.state.loggedDriverId.name;
                requestLogoutDriver.VehicleId = Number(this.state.vehicleId);
                requestLogoutDriver.IsDriverLoggedIn = true;
                const requestParams = getRequestParams('POST', requestLogoutDriver);
                const response = await fetch('driver-api/' + this.state.loggedDriverId.id + "/login-logout", requestParams);
                const responseData = await response.json();
                await Swal.fire({
                    icon: responseData.isSuccess ? 'success' : 'error',
                    title: responseData.isSuccess ? 'Success' : 'Oops...',
                    text: responseData.resultMessage,
                }).then(async (result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (responseData.isSuccess) {
                        if (responseData.RetValue > 0) {
                            var requestLoginDriverHistory = {};
                            requestLoginDriverHistory.VehicleId = Number(this.state.vehicleId);
                            requestLoginDriverHistory.LoginTypeId = 2;
                            requestLoginDriverHistory.LoggedInByUserId = 0;
                            requestLoginDriverHistory.IsLogin = true;
                            const requestParams = getRequestParams('POST', requestLoginDriverHistory);
                            const response = await fetch('driver-api/' + this.state.loggedDriverId.id + "/login-history", requestParams);
                            const data = await response.json();
                        }
                        if (result.isConfirmed) {
                            this.setState({ isOpenDriverLoginModal: false })
                            await this.handleOnCloseDriverLoginModal();
                            await this.getLoggedVehicleList();
                        }
                    }
                })
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false })
    }
    isLogingFormValid = async () => {
        let formIsValid = true;
        let errors = this.state.errors;
        errors.driver = '';
        try {
            let driverData = [... new Set(this.state.loggedDriverList.filter(x => x.id === Number(this.state.driverId)))];
            if (Number(this.state.driverId) === 0 || driverData.length === 0) {
                formIsValid = false;
                errors.driver = 'Please select driver.';
            }
            if (Number(this.state.vehicleId) === 0) {
                await this.showMessage('info', 'Driver Login', 'Vehicle is not available');
                formIsValid = false;
            }
        } catch (ex) {
            formIsValid = false;
            console.log(ex);
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    showMessage = async (icon, title, resultMessage) => {
        this.setState({ loadPanelVisible: false });
        await Swal.fire({
            allowOutsideClick: false,
            showCloseButton: false,
            icon: icon,
            title: (title === null || title.trim() === '') ? 'Oops...' : title,
            html: (resultMessage === null || resultMessage.trim() === '') ? 'Opps! something went wrong, unable to process the request.' : resultMessage.trim(),
        })
    }
    isVehicleLoggedIn = (data) => {
        return data.isDriverLoggedIn;
    }
    dataGridVehicleGroupRender = () => {
        return (
            <DataGrid
                dataSource={this.state.vehicleGroupTypesList}
                id="grid-container"
                keyExpr="id"
                hoverStateEnabled={true}
                selectedRowKeys={this.state.vehicleGroupTypesDropdownData}
                onSelectionChanged={this.handleOnVehicleGroupSelectionChange}
                ref={ref => this.dataGrid = ref}>
                <Column dataField="name" caption="Vehcile Group" />
                <Selection mode="multiple" />
                <Scrolling mode="virtual" />
                <Paging enabled={true} pageSize={10} />
                <FilterRow visible={true} />
            </DataGrid>
        );
    }
    render() {
        const renderLoggedVehiclesCell = (data) => {
            return <div>
                {this.isVehicleLoggedIn(data.data) === true ?
                    <a title="Logout" onClick={(e) => { this.handleOnLogoutDriverEvent(e, data.data) }} >
                        {this.state.isLogoutVehicleSubmitted === true && this.state.vehicleId === data.data.vehicleId && <span title="In-process"><i className="fas fa-spinner fa-spin"></i></span>}
                        {this.state.vehicleId !== data.data.vehicleId && <span title="Logout"><i className="fas fa-sign-out-alt icon text-danger"></i></span>}
                    </a>
                    : <a title="Login" onClick={(e) => { this.handleOnLoginDriverModel(e, data.data) }} ><i className="fas fa-sign-in-alt icon text-success"></i></a>
                }
                {data.data.isMdtOffline === true ?
                    <a title="Take Mdt Online" onClick={(e) => { this.handleOnUpdateMdtStatus(e, data.data.vehicleId, false) }} style={{ paddingLeft: '8px' }} ><i className="fas fa-car text-danger"></i></a>
                    : <a title="Take Mdt Offline" onClick={(e) => { this.handleOnUpdateMdtStatus(e, data.data.vehicleId, true) }} style={{ paddingLeft: '8px' }} ><i className="fas fa-car-alt icon text-info"></i></a>
                }
            </div>;
        }
        return (
            <div className="content-wrapper" >
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <h1 className="m-0 text-dark">Logged Vehicles</h1>
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem><NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="#" onClick={this.getLoggedVehicleList} title="Refresh"><i className="fas fa-sync-alt"></i> Refresh</NavLink></NavItem>
                                    <NavItem><NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="/dispatch/list" title="Dispatch"><i className="fas fa-check"></i> Dispatch</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/noshow/list" className="btn btn-block btn-outline-secondary btn-sm" title="No Show Request"><i className="fas fa-eye-slash"></i> No Show Request</NavLink></NavItem>
                                    <NavItem><NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="/drivermessage/list" title="Driver Message"><i className="fas fa-sms"></i> Driver Message</NavLink></NavItem>
                                    <NavItem><NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="/loggeddrivers/list" title="Logged Drivers"><i className="fas fa-car"></i> Logged Drivers</NavLink></NavItem>
                                    <NavItem><NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="#" onClick={(e) => { this.handleOnShowDriverMessageModal(e, null) }} data-toggle="modal" title="Send Message" title="Send Message"><i className="fas fa-sms"></i> Send Message</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rez-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                {/* <Select
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select vehicle"
                                        name="loggedDriverId"
                                        options={this.state.vehicleGroupTypesList !== null && this.state.vehicleGroupTypesList.length > 0 ? this.state.vehicleGroupTypesList : []}
                                        getOptionLabel={option => option.name}
                                        getOptionValue={option => option.id}
                                        value={this.state.vehicleGroupTypesDropdownData}
                                        defaultValue={[]}
                                        onChange={this.handleOnVehicleGroupDropdownChange}
                                        isMulti={true}
                                        isClearable={true}
                                        isDisabled={false}
                                        isSearchable={true}
                                    />*/}
                                <DropDownBox
                                    value={this.state.vehicleGroupTypesDropdownData}
                                    valueExpr="id"
                                    deferRendering={false}
                                    displayExpr="name"
                                    placeholder="Select vehicle group"
                                    showClearButton={true}
                                    dataSource={this.state.vehicleGroupTypesList}
                                    onValueChanged={this.handleOnVehicleGroupDropdownChange}
                                    contentRender={this.dataGridVehicleGroupRender}
                                />
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <button type="button" className="btn btn-danger btn-sm" disabled={(this.state.vehicleGroupTypesDropdownData === null || this.state.vehicleGroupTypesDropdownData.length === 0) ? true : false}
                                    onClick={(e) => { this.handleOnLogoutAllDriverSubmit(e) }} >
                                    {this.state.isLogoutAllVehicleSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                    {!this.state.isLogoutAllVehicleSubmitted && <span title="Log out All"><i className="fas fa-sign-out-alt"></i> Log out All</span>}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="card card-grid">
                        <div className="card-body">
                            <DataGrid
                                dataSource={this.state.loggedVehicleGriDatasource}
                                keyExpr="id"
                                selection={{ mode: 'single' }}
                                columnsAutoWidth={true}
                                showColumnLines={true}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                onToolbarPreparing={this.handleOnToolbarPreparing}
                                //repaintChangesOnly={true}
                                onCellPrepared={this.handleOnCellPrepared}>
                                <Column dataField="id" width={45} caption="" alignment="center" allowFiltering={false} allowSorting={false} cellRender={renderLoggedVehiclesCell} />
                                <Column dataField="vehicleTypeDescription" caption="Vehicle Type" />
                                <Column dataField="vehicleNo" caption="Vehicle" />
                                <Column dataField="isDriverLoggedInStatus" caption="Login" alignment="center" width="auto" />
                                <Column dataField="statusDescription" caption="Status" width="auto" />
                                <Column dataField="driverName" caption="Driver" />
                                <Column dataField="vehicleInZone" caption="Zone" />
                                <Column dataField="loginDatetimeStr" caption="Last login" width="auto" />
                                <Column dataField="logoutDatetimeStr" caption="Last logout" width="auto" />
                                <Column dataField="workHour" caption="W/H" alignment="center" width="auto" />
                                <Column dataField="isMdtLoggedInStatus" caption="MDT" alignment="center" width="auto" />
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true} />
                                <Export enabled={true} allowExportSelectedData={false} />
                                <ColumnChooser enabled={false} />
                                <ColumnFixing enabled={true} />
                            </DataGrid>
                        </div>
                    </div>
                </div >
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenDriverLoginModal}
                    //style={{ content: { width: '30%' } }}
                    className={"react-modal react-modal-driverlist"}
                    onAfterOpen={this.handleOnAfterOpenDriverLoginModal}
                    onRequestClose={this.handleOnCloseDriverLoginModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form style={this.state.isSubmitted ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Driver Login</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseDriverLoginModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Vehicle </label>
                                        <input type="text" className="form-control form-control-sm" name="vehicle" placeholder="Enter Vehicle" title="Vehicle" value={this.state.vehicleNumber} disabled />
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 d-none">
                                    <div className="form-group">
                                        <label>Driver Id <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm"
                                            name="driverId"
                                            placeholder="Enter driver id"
                                            maxLength="25"
                                            title="Driver Id"
                                            value={(Number(this.state.driverId) !== 0) ? this.state.driverId : ''} onChange={this.handleOnInputChange} />
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Driver <span className="text-danger">*</span></label>
                                        {/*<Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select driver"
                                            name="loggedDriverId"
                                            options={this.state.loggedDriverList !== null && this.state.loggedDriverList.length > 0 ? this.state.loggedDriverList : []}
                                            getOptionLabel={option => option.name}
                                            getOptionValue={option => option.id}
                                            value={this.state.loggedDriverId}
                                            defaultValue={[]}
                                            onChange={this.handleOnloggedDriverChange}
                                            isMulti={false}
                                            isClearable={true}
                                            isDisabled={false}
                                            isSearchable={true}
                                        />*/}
                                        <SelectBox
                                            //dataSource={this.state.loggedDriverList.length > 0 ? this.state.loggedDriverList : []}
                                            dataSource={this.state.loggedDriverList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select driver"
                                            name="dddriver"
                                            //defaultValue={[]}
                                            //value={this.state.loggedDriverId}
                                            defaultValue={0}
                                            value={this.state.driverId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnloggedDriverChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["driver"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["driver"]}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnDriverLoginSubmit}>
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Login"><i className="fas fa-sign-in-alt"></i> Login</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleOnCloseDriverLoginModal} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
                <SendMessageComponent ref="SendMessageComponentModel" onSendMessageClose={this.getLoggedVehicleList} />
            </div>
        );
    }
}