import React, { Component } from 'react';
import { LoadPanel } from 'devextreme-react/load-panel';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import DateBox from 'devextreme-react/date-box';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { SelectBox } from 'devextreme-react/select-box';
import Swal from 'sweetalert2';
import ProgressBar from "./ProgressBarComponent.js";
import Modal from 'react-modal'; //npm install --save react-modal
export class PostVehiclePayout extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isSubmitted: false,
            isRefreshSubmitted: false,
            loadPanelVisible: false,
            isProcessModalOpen: false,
            vehiclesDropdownList: [],
            vehiclePayPeriodDropdownList: [],
            dropdownPayPeriodDate: null,
            isVehicleAllChecked: true,
            dropdownVehicle: null,
            fromDate: moment(new Date()).format("MM/DD/YYYY"),
            toDate: moment(new Date()).format("MM/DD/YYYY"),
            processStatus: 'Click on process to start...!',
            progressBarValue: 0,
            errors: {
                dropdownPayPeriodDate: '',
                fromDate: '',
                toDate: '',
                dropdownVehicle: '',
            }
        }
    }
    componentDidMount() {
        this.fetchApiData();
    }
    getPayPeriodDropdownList = async () => {
        try {
            const requestParams = getRequestParams('POST', {});
            const response = await fetch('post-payout-api/0/dropdown-payperiod', requestParams);
            if (response.ok) {
                const data = await response.json();
                if (data.isSuccess) {
                    this.setState({ vehiclePayPeriodDropdownList: data.vehiclePayPeriods });
                }
            }
            else {
                await this.onfailure('error', null, null,)
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    getVehiclesDropdownList = async () => {
        try {
            var request = new Object();
            request.IsActiveOnly = false;
            request.IsPayrollActivatedOnly = true;
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('post-payout-api/dropdown-vehicle', requestParams);
            if (response.ok) {
                const data = await response.json();
                if (data.isSuccess) {
                    this.setState({
                        vehiclesDropdownList: data.vehicleDropdownList
                    });
                }
            }
            else {
                await this.onfailure('error', null, null,)
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    async fetchApiData() {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getPayPeriodDropdownList();
            await this.getVehiclesDropdownList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnRefresh = async () => {
        try {
            this.setState({ isRefreshSubmitted: true, loadPanelVisible: true });
            await new Promise(resolve => setTimeout(resolve, 2000)); // 3 sec
            let errors = this.state.errors;
            errors.dropdownPayPeriodDate = '';
            errors.fromDate = '';
            errors.toDate = '';
            errors.dropdownVehicle = '';
            this.setState({
                isSubmitted: false,
                isProcessModalOpen: false,
                vehiclesDropdownList: [],
                vehiclePayPeriodDropdownList: [],
                dropdownPayPeriodDate: null,
                isVehicleAllChecked: true,
                dropdownVehicle: null,
                fromDate: moment(new Date()).format("MM/DD/YYYY"),
                toDate: moment(new Date()).format("MM/DD/YYYY"),
                processStatus: 'Click on process to start...!',
                progressBarValue: 0,
                errors: errors
            });
            await this.fetchApiData();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isRefreshSubmitted: false, loadPanelVisible: false });
    }
    handlePayPeriodOnChange = async (selectedOption) => {
        try {
            let errors = this.state.errors;
            errors.dropdownPayPeriodDate = '';
            errors.fromDate = '';
            errors.toDate = '';
            let fromDate = '';
            let toDate = '';
            let selectedPayPeriod = [];
            if (selectedOption !== null && selectedOption.selectedItem !== null) {
                fromDate = moment(selectedOption.selectedItem.payStartDate, 'YYYYMMDD').format('MM/DD/YYYY');
                toDate = moment(selectedOption.selectedItem.payEndDate, 'YYYYMMDD').format('MM/DD/YYYY');
                selectedPayPeriod = selectedOption.selectedItem;
            }
            else {
                errors.dropdownPayPeriodDate = 'Please select reimbursment period.';
            }
            this.setState({ dropdownPayPeriodDate: selectedPayPeriod, fromDate: fromDate, toDate: toDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnValueChangedFromDateBox = async (event) => {
        try {
            let errors = this.state.errors;
            errors.fromDate = '';
            let value = '';
            if (event.value === '' || event.value === null) {
                errors.fromDate = 'Please select from date.';
            }
            else {
                if (moment(new Date(event.value)).isValid() === false) {
                    errors.fromDate = 'Please select valid date.';
                }
                else {
                    value = moment(event.value).format("MM/DD/YYYY");
                }
            }
            if (this.state.toDate.trim() !== '') {
                errors.toDate = '';
            }
            this.setState({ fromDate: value, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnValueChangedToDateBox = async (event) => {
        try {
            let errors = this.state.errors;
            errors.toDate = '';
            let value = '';
            if (event.value === '' || event.value === null) {
                errors.toDate = 'Please select to date.';
            }
            else {
                if (moment(new Date(event.value)).isValid() === false) {
                    errors.toDate = 'Please select valid date.';
                }
                else {
                    value = moment(event.value).format("MM/DD/YYYY");
                }
            }
            if (this.state.fromDate.trim() !== '') {
                errors.fromDate = '';
            }
            this.setState({ toDate: value, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleVehicleOnChange = async (selectedOption) => {
        try {
            this.setState({ loadPanelVisible: true });
            let errors = this.state.errors;
            errors.dropdownVehicle = '';
            let selectVehicleItem = [];
            if (selectedOption === null || selectedOption.selectedItem === null) {
                if (!this.state.isVehicleAllChecked) {
                    errors.dropdownVehicle = 'Please select vehicle.';
                }
            }
            else {
                selectVehicleItem = selectedOption.selectedItem;
            }
            this.setState({ dropdownVehicle: selectVehicleItem, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnPostRidesSubmit = async event => {
        event.preventDefault();
        try {
            this.setState({ isSubmitted: true, progressBarValue: 0, processStatus: '' });
            let isValid = await this.isFormValid();
            if (isValid) {
                await Swal.fire({
                    title: '<small><b>Confirmation</b></small>',
                    html: "<span style='font- size: 20px;'>Continue posting vehicle/driver ride(s) for reimbursement period <b>" + this.state.dropdownPayPeriodDate.viewDate
                        + "</b>  starting from <b><br/>" + this.state.fromDate + "</b> to <b>" + this.state.toDate + "</b></span>?",
                    icon: 'question',
                    allowOutsideClick: false,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'No',
                    confirmButtonText: 'Yes'
                }).then(async (result) => {
                    if (!result.isConfirmed) {
                        this.setState({ isSubmitted: false, processStatus: 'Click on process to start...!' });
                    } else {
                        this.setState({ isProcessModalOpen: true });
                        let totalVehicleCount = 0;
                        let postVehiclePayoutCount = 0;
                        let isSuccess = false;
                        let successCount = 0;
                        let failedCount = 0;
                        let status = '';
                        if (this.state.isVehicleAllChecked) {
                            if (this.state.vehiclesDropdownList !== null) {
                                totalVehicleCount = this.state.vehiclesDropdownList.length;
                            }
                            for (let i = 0; i < this.state.vehiclesDropdownList.length; i++) {
                                //postVehiclePayoutCount = Number(postVehiclePayoutCount) + 1;
                                this.setState({ processStatus: 'Posting vehicle: ' + this.state.vehiclesDropdownList[i].vehicleNumber + ' .. completed ' + postVehiclePayoutCount + ' / ' + totalVehicleCount });
                                isSuccess = await this.postVehicleRides(Number(this.state.vehiclesDropdownList[i].vehicleId));
                                postVehiclePayoutCount = Number(postVehiclePayoutCount) + 1;
                                status = '';
                                if (isSuccess) {
                                    successCount = Number(successCount) + 1;
                                    status = 'Vehicle: ' + this.state.vehiclesDropdownList[i].vehicleNumber + ' .. posted ' + postVehiclePayoutCount + ' / ' + totalVehicleCount;
                                }
                                else {
                                    failedCount = Number(failedCount) + 1;
                                    status = 'Faild! Vehicle: ' + this.state.vehiclesDropdownList[i].vehicleNumber + ' ..' + postVehiclePayoutCount + ' / ' + totalVehicleCount;
                                }
                                let progressBarValue = (Number(postVehiclePayoutCount) / Number(totalVehicleCount)) * 100;
                                this.setState({ processStatus: status, progressBarValue: parseInt(progressBarValue) });
                            };
                        }
                        else {
                            totalVehicleCount = 1;
                            postVehiclePayoutCount = 1;
                            isSuccess = await this.postVehicleRides(Number(this.state.dropdownVehicle.vehicleId));
                            if (isSuccess) {
                                successCount = 1;
                                status = 'Vehicle: ' + this.state.dropdownVehicle.vehicleNumber + ' .. completed ' + 1 + ' / ' + totalVehicleCount;
                            }
                            else {
                                failedCount = 1;
                                status = 'Faild! Vehicle: ' + this.state.dropdownVehicle.vehicleNumber + ' ..' + 1 + ' / ' + totalVehicleCount;
                            }
                            this.setState({ processStatus: status, progressBarValue: parseInt(100) });
                        }
                        await new Promise(resolve => setTimeout(resolve, 1000)); // 1 sec
                        this.setState({ isProcessModalOpen: false });
                        await Swal.fire({
                            allowOutsideClick: false,
                            icon: Number(postVehiclePayoutCount) > 0 ? 'success' : 'error',
                            title: Number(postVehiclePayoutCount) > 0 ? 'Success' : 'Oops...',
                            html: Number(postVehiclePayoutCount) === 0 ?
                                'Vehicle/driver ride(s) not posted for reimbursement period <b>' + this.state.dropdownPayPeriodDate.viewDate + '</b> starting from <b> ' + this.state.fromDate + ' </b> to <b> ' + this.state.toDate + ' </b>.'
                                : 'Successfully posted ride(s) for reimbursement period <b>' + this.state.dropdownPayPeriodDate.viewDate + '</b> starting from <b> ' + this.state.fromDate + ' </b> to <b> ' + this.state.toDate + ' </b>.'
                                + '<br/>Success vehicles/drivers count is [<b>' + successCount + '</b>] out of [<b>' + totalVehicleCount + '</b>].'
                                + (Number(failedCount) === 0 ? '' : '<br/>Failed vehicles/drivers count is [<b>' + failedCount + '</b>] out of [<b>' + totalVehicleCount + '</b>]'),
                        }).then(async (result) => {
                            if (result.isConfirmed) {
                                //await this.onfailure('success', 'Success', 'This process was a test and did not affect database.');
                                await this.fetchApiData();
                                this.setState({ isSubmitted: false });
                            }
                        });
                    }
                });
            }
            else {
                this.setState({ processStatus: 'Click on process to start...!' });
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false, loadPanelVisible: false });
    }
    postVehicleRides = async (vehicleId) => {
        let isSuccess = false;
        try {
            var request = new Object();
            request.StatementDate = moment(this.state.dropdownPayPeriodDate.payEndDate, 'YYYYMMDD').format('YYYY-MM-DD') + ' 23:59:59';
            request.StartDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00';
            request.EndDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59';
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('post-payout-api/' + Number(vehicleId) + '/vehicle-driver-rides', requestParams)
            const data = await response.json();
            if (data.isSuccess) {
                isSuccess = true;
            }
        } catch (ex) {
            console.log(ex);
        }
        return isSuccess;
    }
    isFormValid = async () => {
        let isValid = true;
        try {
            let errors = this.state.errors;
            errors.dropdownPayPeriodDate = '';
            errors.fromDate = '';
            errors.toDate = '';
            errors.dropdownVehicle = '';
            if (this.state.dropdownPayPeriodDate === null) {
                isValid = false;
                errors.dropdownPayPeriodDate = 'Please select reimbursment period.';
            }
            if (this.state.fromDate === '' || this.state.fromDate === null) {
                isValid = false;
                errors.fromDate = 'From date is not valid.';
            }
            else {
                if (moment(new Date(this.state.fromDate)).isValid() === false) {
                    isValid = false;
                    errors.fromDate = 'From date is not valid.';
                }
            }
            if (this.state.toDate === '' || this.state.toDate === null) {
                isValid = false;
                errors.toDate = 'To date is not valid.';
            }
            else {
                if (moment(new Date(this.state.toDate)).isValid() === false) {
                    isValid = false;
                    errors.toDate = 'From date is not valid.';
                }
                else {
                    if (this.state.dropdownPayPeriodDate !== null) {
                        let payDate = moment(this.state.dropdownPayPeriodDate.payEndDate, 'YYYYMMDD').format('MM/DD/YYYY');
                        let payDaysDiff = moment(payDate).diff(this.state.toDate, 'days');
                        if (payDaysDiff < 0) {
                            isValid = false;
                            errors.toDate = 'To date must be less than or equal to pay date.';
                        }
                    }
                }
            }
            if (this.state.dropdownVehicle === null) {
                if (!this.state.isVehicleAllChecked) {
                    isValid = false;
                    errors.dropdownVehicle = 'Please select vehicle.';
                }
            }
            //let daysDiff = moment(this.state.toDate).diff(this.state.fromDate, 'days');
            //if (Number(daysDiff) < 0) {
            //    isValid = false;
            //    errors.fromDate = 'Please enter valid ride date range.';
            //    errors.toDate = 'Please enter valid ride date range.';
            //}
            if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                if (moment(this.state.fromDate, 'MM/DD/YYYY').isAfter(this.state.toDate, 'MM/DD/YYYY')) {
                    errors.fromDate = 'Please enter valid ride date range.';
                    errors.toDate = 'Please enter valid ride date range.';
                    isValid = false;
                }
            }
            if (this.state.vehiclesDropdownList === null || this.state.vehiclesDropdownList.length === 0) {
                isValid = false;
                errors.dropdownVehicle = 'Unable to generate payout, vehicle details not found .';
            }
            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    onfailure = async (icon, title, resultMessage) => {
        this.setState({ loadPanelVisible: false });
        await Swal.fire({
            allowOutsideClick: false,
            showCloseButton: false,
            icon: icon,
            title: (title === null || title.trim() === '') ? 'Oops...' : title,
            text: (resultMessage === null || resultMessage.trim() === '') ? 'Opps! something went wrong, unable to process the request.' : resultMessage.trim(),
        })
    }
    render() {
        return (
            <div>
                <form method="post" onSubmit={this.handleOnPostRidesSubmit}
                    style={(this.state.isSubmitted || this.state.isRefreshSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="content-wrapper">
                        <div className="content-header">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                        <h1 className="m-0 text-dark">Post Vehicle Ride(s)</h1>
                                    </div>
                                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                                        <ol className="breadcrumb float-sm-right">
                                            <NavItem>
                                                <NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="#" title="Refresh"
                                                    onClick={this.handleOnRefresh}>
                                                    {this.state.isRefreshSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                                    {!this.state.isRefreshSubmitted && <span title="Refresh"><i className="fas fa-sync-alt"></i> Refresh</span>}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to="/generate/payout" className="btn btn-block btn-outline-secondary btn-sm" title="Generate Payout">
                                                    <i className="fas fa-file-invoice-dollar"></i> Generate Payout</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to="/reimbursement/view" className="btn btn-block btn-outline-secondary btn-sm" title="Reimb. View">
                                                    <i className="fas fa-car"></i> Reimb. View</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to="/peding/postrides" className="btn btn-block btn-outline-secondary btn-sm" title="Pending Post Ride(s)">
                                                    <i className="fas fa-clock"></i> Pending Post Ride(s)</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to="/posted/rides" className="btn btn-block btn-outline-secondary btn-sm" title="Posted Ride(s)">
                                                    <i className="fas fa-check-double"></i> Posted Ride(s)</NavLink>
                                            </NavItem>
                                            <NavItem >
                                                <NavLink tag={Link} to="/reimbursement/history" className="btn btn-block btn-outline-secondary btn-sm" title="Reimbursement History">
                                                    <i className="fas fa-history"></i> Reimb. History</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to="/reimbursement/payperiod" className="btn btn-block btn-outline-secondary btn-sm" title="Reimbursement Pay Period">
                                                    <i className="fas fa-calendar-alt"></i> Reimb. Pay Period</NavLink>
                                            </NavItem>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="content">
                            <div className="card mt-3" style={{ width: "30%", margin: "0 auto" }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label>Reimbursment Period</label>
                                                {/*<Select
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Select period"
                                                    defaultValue={[]}
                                                    value={this.state.dropdownPayPeriodDate}
                                                    name="ddpayperiod"
                                                    onChange={this.handlePayPeriodOnChange}
                                                    getOptionLabel={option => option.viewDate}
                                                    getOptionValue={option => option.payEndDate}
                                                    options={this.state.vehiclePayPeriodDropdownList}
                                                    isMulti={false}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                />*/}
                                                <SelectBox
                                                    dataSource={this.state.vehiclePayPeriodDropdownList}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Select period"
                                                    name="ddpayperiod"
                                                    defaultValue={''}
                                                    value={(this.state.dropdownPayPeriodDate !== null && this.state.dropdownPayPeriodDate.payEndDate !== null) ? this.state.dropdownPayPeriodDate.payEndDate : ''}
                                                    displayExpr='viewDate'
                                                    valueExpr='payEndDate'
                                                    onSelectionChanged={this.handlePayPeriodOnChange}
                                                    showClearButton={true}
                                                    disabled={false}
                                                    searchEnabled={true}
                                                />
                                                {this.state.errors["dropdownPayPeriodDate"].length > 0 && <span className='error invalid-feedback'>
                                                    {this.state.errors["dropdownPayPeriodDate"]}</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label>From Date </label>
                                                <DateBox type="date"
                                                    className="form-control form-control-sm" name="fromDate"
                                                    placeholder="mm/dd/yyyy"
                                                    defaultValue={new Date()}
                                                    value={this.state.fromDate}
                                                    onValueChanged={this.handleOnValueChangedFromDateBox}
                                                    width="100%"
                                                    acceptCustomValue={false}
                                                //min={this.state.fromDate}
                                                //disabled={true}
                                                />
                                                {this.state.errors["fromDate"].length > 0 &&
                                                    <span className="error invalid-feedback">{this.state.errors["fromDate"]}</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label>To Date </label>
                                                <DateBox type="date"
                                                    className="form-control form-control-sm" name="toDate"
                                                    placeholder="mm/dd/yyyy"
                                                    defaultValue={new Date()}
                                                    value={this.state.toDate}
                                                    min={this.state.fromDate}
                                                    onValueChanged={this.handleOnValueChangedToDateBox}
                                                    width="100%"
                                                    acceptCustomValue={false}
                                                //disabled={true}
                                                />
                                                {this.state.errors["toDate"].length > 0 &&
                                                    <span className="error invalid-feedback">{this.state.errors["toDate"]}</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
                                            <div className="form-group">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox"
                                                        id="isVehicleAllChecked" name="isVehicleAllChecked"
                                                        checked={this.state.isVehicleAllChecked}
                                                        onChange={(e) => {
                                                            let errors = this.state.errors;
                                                            errors.dropdownVehicle = '';
                                                            this.setState({
                                                                isVehicleAllChecked: e.target.checked,
                                                                dropdownVehicle: null,
                                                                errors: errors
                                                            })
                                                        }}
                                                    />
                                                    <label className="form-check-label" htmlFor="isVehicleAllChecked"> All Vehicle</label>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
                                            <div className="form-group">
                                                {/*<Select
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Select vehicle"
                                                    value={this.state.dropdownVehicle}
                                                    name="ddvehicle"
                                                    onChange={this.handleVehicleOnChange}
                                                    getOptionLabel={option => option.vehicleNumber}
                                                    getOptionValue={option => option.vehicleId}
                                                    options={this.state.vehiclesDropdownList}
                                                    isMulti={false}
                                                    isClearable={true}
                                                    isDisabled={this.state.isVehicleAllChecked}
                                                    isSearchable={true}
                                                    width="100%"
                                                />*/}
                                                <SelectBox
                                                    dataSource={this.state.vehiclesDropdownList}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Select vehicle"
                                                    name="ddvehicle"
                                                    defaultValue={0}
                                                    value={(this.state.dropdownVehicle !== null && this.state.dropdownVehicle.vehicleId !== null) ? this.state.dropdownVehicle.vehicleId : 0}
                                                    displayExpr='vehicleNumber'
                                                    valueExpr='vehicleId'
                                                    onSelectionChanged={this.handleVehicleOnChange}
                                                    showClearButton={true}
                                                    disabled={this.state.isVehicleAllChecked}
                                                    searchEnabled={true}
                                                />
                                                {this.state.errors["dropdownVehicle"].length > 0 && <span className='error invalid-feedback'>
                                                    {this.state.errors["dropdownVehicle"]}</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
                                            <div className="form-group">
                                                <button type="submit" className="btn btn-success btn-sm btn-block">
                                                    {this.state.isSubmitted && <span title="In Process">
                                                        <i className="fas fa-spinner fa-spin"></i> In Process</span>}
                                                    {!this.state.isSubmitted && <span title="Update Note">
                                                        <i className="fas fa-check"></i> Process</span>}
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="row">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ top: '40px' }}>
                                                        <div className="card sub-rez-card">
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                                        <div className="form-group">
                                                                            <span style={{ color: 'darkblue' }}>{this.state.processStatus}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ display: Number(this.state.progressBarValue) > 0 || 'none' }}
                                                                        className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                        <div className="form-group">
                                                                            <ProgressBar key={this.state.progressBarValue}
                                                                                bgcolor={'#28a745'} completed={this.state.progressBarValue} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isProcessModalOpen}
                    style={{ content: { width: '35%' } }}
                    className={"react-modal"}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <div className="modal-body">
                        <div className="row mt-2">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                                <h5><strong>{'Processing...'}</strong></h5>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                                <div className="form-group">
                                    <span className="text-success">{this.state.processStatus}</span>
                                </div>
                            </div>
                            <div style={{ display: Number(this.state.progressBarValue) > 0 || 'none' }} className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="form-group">
                                    <ProgressBar key={this.state.progressBarValue}
                                        bgcolor={'#28a745'} completed={this.state.progressBarValue} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        )
    }
}