import React, { Component } from 'react';
import { post, axios } from 'axios'; //npm install--save axios

export class TrainingServicesComponent extends Component {
    static displayName = TrainingServicesComponent.name;
    constructor(props) {
        super(props);
        this.state = {
            isFirstSubmited: false,
        }
        this.handleDownloadSubmit = this.handleDownloadSubmit.bind(this);
    };

    async handleDownloadSubmit(e) {
        e.preventDefault();
        this.setState({
            isFirstSubmited: true
        });
        const url = 'training-services-api/downloadfile';
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': 'Bearer ' + sessionStorage.getItem('session_token'),
                'CultureCode': sessionStorage.getItem('session_culturecode'),
                'Accept': 'application/octet-stream',
            },
            responseType: 'blob', // important
        };

        const response = await post(url, null, config);
        const data = await response.data;
        if (data !== null) {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'UTLite.pdf');
            document.body.appendChild(link);
            link.click();
            this.setState({
                isFirstSubmited: false
            })
        } else {
            this.setState({
                isFirstSubmited: false
            })
        }
    }
    render() {
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <h1 className="m-0 text-dark">Training and Services</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                &nbsp;
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">

                    <div className="row mt-2">
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <div className="card">
                                <div className="card-body">
                                    <form method="post" onSubmit={this.handleDownloadSubmit} style={this.state.isFirstSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                        <div><strong style={{ fontSize: 1.5 + 'rem' }}>UTLite</strong> <sup className="badge badge-danger text-small">PDF</sup></div>
                                        <div className="text-muted mb-2">Sed vitae luctus diam, in dapibus dolor. Nunc malesuada tempor malesuada</div>
                                        <div className="text-right"><button className="btn btn-outline-danger btn-sm" onClick={this.state.handleDownloadSubmit}><i className="fas fa-arrow-circle-down" ></i> Download</button></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <div className="card">
                                <div className="card-body">
                                    <form method="post" onSubmit={this.handleDownloadSubmit} style={this.state.isFirstSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                        <div><strong style={{ fontSize: 1.5 + 'rem' }}>UTRover</strong> <sup className="badge badge-info text-small">VIDEO</sup> <sup className="badge badge-danger text-small">PDF</sup> </div>
                                        <div className="text-muted mb-2">Drivers use the UTRover app to accept and complete crew jobs, and to update ride details.</div>
                                        <div className="text-right"><button className="btn btn-outline-info btn-sm" data-toggle="modal" data-target="#modal-view"><i className="fas fa-eye"></i> View</button> <button className="btn btn-outline-danger btn-sm" onClick={this.state.handleDownloadSubmit}><i className="fas fa-arrow-circle-down"></i> Download</button></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 displayNone">
                            <div className="card">
                                <div className="card-body">
                                    <div><strong style={{ fontSize: 1.5 + 'rem' }}>UTCrew</strong> <sup className="badge badge-danger text-small">PDF</sup> </div>
                                    <div className="text-muted mb-2">With the UTCrew transport app for airline crew members, your pick-up is ready when you are.</div>
                                    <div className="text-right"><button className="btn btn-outline-info btn-sm" data-toggle="modal" data-target="#modal-view"><i className="fas fa-eye"></i> View</button> <button className="btn btn-outline-danger btn-sm"><i className="fas fa-arrow-circle-down"></i> Download</button></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <div className="card">
                                <div className="card-body">
                                    <form method="post" onSubmit={this.handleDownloadSubmit} style={this.state.isFirstSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                        <div><strong style={{ fontSize: 1.5 + 'rem' }}>UTShuttle</strong> <sup className="badge badge-danger text-small">PDF</sup> </div>
                                        <div className="text-muted mb-2">App to select route, select stops, board and un-board passengers, maintain job records.</div>
                                        <div className="text-right"><button className="btn btn-outline-info btn-sm" data-toggle="modal" data-target="#utShuttle-model"><i className="fas fa-eye"></i> View</button> <button className="btn btn-outline-danger btn-sm" onClick={this.state.handleDownloadSubmit}><i className="fas fa-arrow-circle-down"></i> Download</button></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 displayNone">
                            <div className="card">
                                <div className="card-body">
                                    <div><strong style={{ fontSize: 1.5 + 'rem' }}>UTGo</strong> <sup className="badge badge-danger text-small">PDF</sup> </div>
                                    <div className="text-muted mb-2">With the UTGo shuttle bus app, commuters are able to request a ride and track shuttles to get an ETA.</div>
                                    <div className="text-right"><button className="btn btn-outline-info btn-sm" data-toggle="modal" data-target="#modal-view"><i className="fas fa-eye"></i> View</button> <button className="btn btn-outline-danger btn-sm"><i className="fas fa-arrow-circle-down"></i> Download</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="modal-view">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">UTRover</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <iframe width="100%" height="415" src="https://www.youtube.com/watch?v=JOK9opsneaw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                            </div>
                            <div className="modal-footer text-right">
                                <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="utShuttle-model">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">UTShuttle</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <iframe width="100%" height="415" src="https://www.youtube.com/watch?v=JOK9opsneaw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                            </div>
                            <div className="modal-footer text-right">
                                <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}