import DataGrid, {
    Column,
    ColumnChooser,
    Export, Grouping,
    GroupPanel,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
    ColumnFixing
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import { DateFormat } from '../../helpers/fixcodes';
import { getRequestParams } from '../../helpers/globalfunctions';

let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));

export class NotificationComponent extends Component {
    static displayName = NotificationComponent.name;

    constructor(props) {
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        super(props);
        this.state = {
            isSubmited: false,
            notificationGridDataSource: [],
        };

        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.getNotifications = this.getNotifications.bind(this);
    }

    async componentDidMount() {
        await this.getNotifications();
    }

    async getNotifications() {
        try {
            var notificationRequest = new Object();
            const requestParams = getRequestParams('GET', notificationRequest);
            const response = await fetch('/notification-api/list', requestParams);
            const data = await response.json();
            this.setState({
                notificationGridDataSource: data.notifications,
            });
        } catch (e) {
            //debugger;
        }
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getNotifications.bind(this)
            }
        });
    }

    render() {

        return (
            <div className="content-wrapper">
                <div className="content">
                    <div className="card card-grid">
                        <div className="card-body">

                            <DataGrid
                                dataSource={this.state.notificationGridDataSource}
                                keyExpr="notificationId"
                                selection={{ mode: 'single' }}
                                columnsAutoWidth={false}
                                showColumnLines={true}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                showBorders={true}
                                onToolbarPreparing={this.onToolbarPreparing}>

                                <Column dataField="messageText" width={120} caption="Notification Message" width={500} allowFiltering={false} allowSorting={true} />
                                <Column dataField="rideId" caption="Ride Id" width={150} allowFiltering={false} allowSorting={true} />
                                <Column dataField="flightNo" caption="Flight No" width={150} allowFiltering={false} allowSorting={true} />
                                <Column dataField="processedDate" caption="Processed Date" width={150} allowFiltering={false} allowSorting={true} format={DateFormat.DateTime24Format} dataType="datetime" />
                                {/*<Column dataField="resultMessage" width={100} caption="ResultMessage" width={150} allowFiltering={false} allowSorting={true} />*/}

                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true} />
                                <Export enabled={false} allowExportSelectedData={false} fileName={'Location'} />
                                <ColumnChooser enabled={false} />
                                <ColumnFixing enabled={false} />
                            </DataGrid>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}