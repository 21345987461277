import Modal from 'react-modal'; //npm install --save react-modal
import { LoadPanel } from 'devextreme-react/load-panel';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import Swal from 'sweetalert2';
import { SelectBox } from 'devextreme-react/select-box';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions';
import DateBox from 'devextreme-react/date-box';
import { SendEmailComponent } from '../Shared/SendEmailComponent'
let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));

export class CustomerCommissionRideReportComponent extends React.Component {
    constructor(props) {
        super(props);
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        this.state = {
            isSubmited: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            fromDateTime: formatDate(new Date(currentTime), "MM/dd/yyyy"),
            toDateTime: formatDate(new Date(currentTime), "MM/dd/yyyy"),
            customer: [],
            agent: [],
            customerCode: "",
            agentCode: "",
            customerRideCommission: [],
            isDisplayTableFormat: false,
            loadPanelVisible: false,
            errors: {
                customerCode: "",
                agentCode: "",
            }
        }

        this.getCustomerList = this.getCustomerList.bind(this);
        this.getAgentCommissionList = this.getAgentCommissionList.bind(this);

        this.handleCustomerChange = this.handleCustomerChange.bind(this);
        this.handleAgentChange = this.handleAgentChange.bind(this);
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.handleFromValueChanged = this.handleFromValueChanged.bind(this);
        this.handleToValueChanged = this.handleToValueChanged.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.getCustomerRideCommissionReport = this.getCustomerRideCommissionReport.bind(this);
        this.handleSearchCharterInvoiceReportSubmit = this.handleSearchCharterInvoiceReportSubmit.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
        this.downloadAsPdf = this.downloadAsPdf.bind(this);
        this.emailReports = this.emailReports.bind(this);

    }
    async componentDidMount() {
        this.setState({
            loadPanelVisible: true,
        });
        await this.getCustomerList();
        await this.getAgentCommissionList();

        this.setState({
            loadPanelVisible: false,
        });
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });

        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'exportpdf',
                onClick: this.downloadAsPdf.bind(this),
                hint: 'Export to Pdf'
            }
        });

        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'email',
                onClick: this.emailReports.bind(this),
                hint: 'Email'
            }
        });

        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getCustomerRideCommissionReport.bind(this),
                hint: 'Refresh'
            }
        });
    }
    async getCustomerList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('account-api/customer-dropdown/', requestParams);
        const data = await response.json();
        this.setState({
            customer: data.customers,
        });
    }

    async getAgentCommissionList() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('commission-agent-api/commission-agent-dropdown/', requestParams);
            const data = await response.json();

            this.setState({
                agent: data.commissionAgentDropdownList
            });
        } catch (e) {
            console.log(e);
        }
    }
    async getCustomerRideCommissionReport() {
        var request = new Object();
        request.AccountCode = this.state.customerCode == null ? "" : this.state.customerCode;
        request.AgentCode = this.state.agentCode == null ? "" : this.state.agentCode;
        request.StartDate = this.state.fromDateTime == null ? "" : this.state.fromDateTime.toLocaleDateString() + " 00:00:00"; //new Date(2020, 9, 1);
        request.EndDate = this.state.toDateTime == null ? "" : this.state.toDateTime.toLocaleDateString() + " 23:59:59"; //new Date(2020, 9, 31);

        try {
            this.setState({
                isSubmited: true,
            });
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('report-api/customer-ride-commission-report', requestParams);
            const data = await response.json();

            this.setState({
                customerRideCommission: data.customerRideCommissionReport
            });
            this.setState({ isSubmited: false });

        } catch (e) {
            this.setState({ isSubmited: false });
        }
    }
    async downloadAsPdf() {
        try {
            this.setState({
                //loadPanelVisible: true,
                isSubmited: true,
            });

            var request = new Object();
            request.AccountCode = this.state.customerCode == null ? "" : this.state.customerCode;
            request.AgentCode = this.state.agentCode == null ? "" : this.state.agentCode;
            request.StartDate = this.state.fromDateTime + " 00:00";
            request.EndDate = this.state.toDateTime + " 23:59";
            request.fileFormat = 'pdf';
            request.MethodName = "GetCustomerRideCommission";
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            this.setState({
                loadPanelVisible: false,
            });

            this.setState({
                //loadPanelVisible: true,
                isSubmited: false,
            });

            let a = document.createElement('a');
            a.href = dataResponse.reportBase64String;
            a.download = 'CustomerRideCommission.pdf';
            a.click();
        } catch (e) {
            debugger;
        }
    }
    async emailReports() {
        const isValid = await Swal.fire({
            title: "<small>Pending Implementation</small>",
            text: "This feature is comming soon.",
            icon: "info",
            dangerMode: false,
            width: "25%",
            dangerMode: false,
            allowOutsideClick: false
        });
        return;
    }
    handleCustomerChange(e) {
        if (e.selectedItem != null) {
            this.setState({
                customerCode: e.selectedItem.code,
            });
        }
        else {
            this.setState({ customerCode: "" });
        }
    }
    handleAgentChange(e) {
        if (e.selectedItem != null) {
            this.setState({
                agentCode: e.selectedItem.agentCode,
            });
        }
        else {
            this.setState({ agentCode: "" });
        }
    }
    handleFromValueChanged(date) {
        let fromDateValue = formatDate(date.value, "MM/dd/yyyy");
        this.setState({ fromDateTime: fromDateValue });
    }
    handleToValueChanged(date) {
        let toDateValue = formatDate(date.value, "MM/dd/yyyy");
        this.setState({ toDateTime: toDateValue });
    }
    handleChange(e) {
        this.setState({ isDisplayTableFormat: e.target.checked });
    }
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }
    async handleSearchCharterInvoiceReportSubmit(e) {
        e.preventDefault();
        if (this.state.isDisplayTableFormat) {
            await this.getCustomerRideCommissionReport();
        }
        else {
            this.downloadAsPdf();
        }
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmited: false,
                isEmailSubmitted: false,
                isOpenModal: false,
                fromDateTime: formatDate(new Date(currentTime), "MM/dd/yyyy"),
                toDateTime: formatDate(new Date(currentTime), "MM/dd/yyyy"),
                customerCode: "",
                agentCode: "",
                customerRideCommission: [],
                isDisplayTableFormat: false,
                loadPanelVisible: false,
                errors: {
                    customerCode: "",
                    agentCode: "",
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleClearSearch() {
        this.setState({
            isSubmited: false,
            isEmailSubmitted: false,
            isOpenModal: true,
            fromDateTime: formatDate(new Date(currentTime), "MM/dd/yyyy"),
            toDateTime: formatDate(new Date(currentTime), "MM/dd/yyyy"),
            customerCode: "",
            agentCode: "",
            customerRideCommission: [],
            isDisplayTableFormat: false,
            loadPanelVisible: false,
            errors: {
                customerCode: "",
                agentCode: "",
            }
        });
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            this.setState({ isEmailSubmitted: true });
            var request = new Object();
            request.AccountCode = this.state.customerCode == null ? "" : this.state.customerCode;
            request.AgentCode = this.state.agentCode == null ? "" : this.state.agentCode;
            request.StartDate = this.state.fromDateTime + " 00:00";
            request.EndDate = this.state.toDateTime + " 23:59";
            request.fileFormat = 'pdf';
            request.MethodName = "GetCustomerRideCommission";
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            this.setState({ isEmailSubmitted: false });
            let fileData = dataResponse.reportBase64String;
            if (fileData !== null && fileData !== '') {
                let emailSubject = 'Customer Commission Report';
                let attachments = [];
                let file = new Object();
                file.fileData = fileData;
                file.fileName = 'CustomerRideCommission.pdf'
                file.fileExtension = '.pdf';
                attachments.push(file);
                this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
            }
            else {
                Swal.fire({
                    allowOutsideClick: false,
                    showCloseButton: true,
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong! Unable to process the email request.',
                })
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {

        const filterSearchFromDate = time => {
            const currentDate = currentTime;
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }

        const filterSearchToDate = time => {
            const currentDate = currentTime;
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }
        const isDisableAllButton = this.state.customerRideCommission.length > 0 ? false : true;

        const renderGridCurrencyCell = (data) => {
            return <div>${parseFloat(data.value).toFixed(2)}</div>;
        }
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '40%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleSearchCharterInvoiceReportSubmit}
                        style={(this.state.isSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Customer Commission Report'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label className="form-check-label">Customer</label>
                                        <SelectBox
                                            dataSource={this.state.customer.length > 0 ? this.state.customer : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.customerCode}
                                            displayExpr='name'
                                            valueExpr='code'
                                            onSelectionChanged={this.handleCustomerChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerCode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerCode"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label className="form-check-label">Agent</label>
                                        <SelectBox
                                            dataSource={this.state.agent.length > 0 ? this.state.agent : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select agent"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.agentCode}
                                            displayExpr='name'
                                            valueExpr='code'
                                            onSelectionChanged={this.handleAgentChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerCode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerCode"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label className="form-check-label">From Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDateTime"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.fromDateTime}
                                            value={this.state.fromDateTime}
                                            onValueChanged={this.handleFromValueChanged}
                                            width="100%"
                                            acceptCustomValue={false} />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label className="form-check-label">To Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.toDateTime}
                                            value={this.state.toDateTime}
                                            onValueChanged={this.handleToValueChanged}
                                            width="100%"
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="submit" className="btn btn-success btn-sm" title="Download" onClick={this.handleSearchCharterInvoiceReportSubmit} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                                </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        )
    }
}