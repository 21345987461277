import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
} from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import DateBox from 'devextreme-react/date-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React from 'react';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';
import { SelectBox } from 'devextreme-react/select-box';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import { getRequestParams, formatDate } from '../../helpers/globalfunctions';
import { DateFormat } from '../../helpers/fixcodes';
import { SendEmailComponent } from '../Shared/SendEmailComponent';
const position = { of: '#historydiv' };
let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
let startTime = new Date(new Date(currentTime).setDate(currentTime.getDate() - 30));
let endTime = new Date(new Date(currentTime).setDate(currentTime.getDate()));

export class TrackLogComponent extends React.Component {
    constructor(props) {
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        startTime = new Date(new Date(currentTime).setDate(currentTime.getDate() - 30));
        endTime = new Date(new Date(currentTime).setDate(currentTime.getDate()));

        super(props);
        this.state = {
            loadPanelVisible: false,
            loadPanelMessage: 'Loading...',
            isSubmited: false,
            vehicleDropdownList: [],
            vehicleDeviceList: [],
            trackLoglist:[],
            fromDateTime: new Date(moment(startTime).format("MM/DD/YYYY")),
            toDateTime: new Date(moment(endTime).format("MM/DD/YYYY")),
            vehicleId: 0,
            deviceId: 0,
            errors: {
                dateRange:'',
            }
        }
    }

    async componentDidMount() {
        this.setState({ loadPanelVisible: true });
        await this.getVehicleDropdownList();
        this.setState({ loadPanelVisible: false });
    }
    getVehicleDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('vehicle-api/dropdown/0/0', requestParams);
            const data = await response.json();
            this.setState({
                vehicleDropdownList: data.vehiclesDropdown
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    getDeviceIdByVehicle = async (vehicleId) => {
        try {
             const requestParams = getRequestParams('GET', {});
            const response = await fetch('vehicle-api/GetDeviceByVehicleId/' + vehicleId, requestParams);
            const data = await response.json();
            this.setState({
                vehicleDeviceList: data.vehicles[0],
                deviceId:data.vehicles[0].deviceId
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleVehicleChange = (selectedOption) => {
        this.setState({ vehicleId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
        this.getDeviceIdByVehicle(this.state.vehicleId)
    }
    handleFromValueChanged = (date) => {
        this.setState({ fromDateTime: date.value});
    }
    handleToValueChanged = (date) => {
        this.setState({ toDateTime: date.value });
    }
    getTrackList = async () => {        
        const startDate = moment(this.state.fromDateTime);
        const endDate = moment(this.state.toDateTime);
        var diffDays = moment(moment(this.state.toDateTime)).diff(moment(this.state.fromDateTime), 'day');
        let formIsValid = true;
        let errors = this.state.errors;
        errors.dateRange = '';
        if (diffDays > 30) {
            formIsValid = false;
            errors.dateRange = "Please select date range within 30 days.";
            this.setState({ errors: errors });
            return formIsValid;
        }
        this.setState({ loadPanelVisible: true });
        var request = new Object();
        request.FromDate = formatDate(this.state.fromDateTime, 'MM/dd/yyyy')  + " 00:00:00";
        request.ToDate = formatDate(this.state.toDateTime, 'MM/dd/yyyy') + " 23:59:59";;
        request.DeviceId = this.state.deviceId;
         try {
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('report-api/tracklog-report', requestParams);
             const data = await response.json();
             if (data.isSuccess) {
                 this.setState({
                     trackLoglist: data.trackLogs,
                     loadPanelVisible: false
                 });
             }
             else {
                 this.setState({ trackLoglist :[],deviceId : 0 ,loadPanelVisible: false });
                 Swal.fire({
                     allowOutsideClick: false,
                     showCloseButton: true,
                     icon: 'error',
                     title: 'Oops...',
                     text: data.resultMessage,
                 })
             }
           

        } catch (e) {
            console.log(e);
        }
    }
    handleClearSearch = ()=> {
        this.setState({
            isSubmited: false,
            trackLoglist: [],
            fromDateTime: new Date(moment(startTime).format("MM/DD/YYYY")),
            toDateTime: new Date(moment(endTime).format("MM/DD/YYYY")),
            vehicleId: 0,
            deviceId: 0,
        });
    }
    render() {
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-sm-6 col-md-6 col-lg-6">
                                <h1 className="m-0 text-dark">Track log</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem> <NavLink tag={Link} to="/report" className="btn btn-block btn-outline-secondary btn-sm" title="Reports" ><i className="fas fa-arrow-left"></i> Back</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            
                    <div className="rez-header">
                        <div className="container">
                            <div className="row">
                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                <div className="form-group">
                                    <label> Vehicle</label>
                                    <SelectBox
                                        dataSource={this.state.vehicleDropdownList}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select Vehicle"
                                        name="vehicleNo"
                                        defaultValue={0}
                                        value={this.state.vehicleId}
                                        displayExpr='name'
                                        valueExpr='id'
                                        onSelectionChanged={this.handleVehicleChange}
                                        showClearButton={true}
                                        searchEnabled={true}
                                    />
                                </div>
                            </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>Start Date</label>
                                         <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDateTime"
                                            placeholder="MM/dd/yyyy"
                                            displayFormat="MM/dd/yyyy"
                                            showAnalogClock={false}
                                            useMaskBehavior={true}
                                            defaultValue={this.state.fromDateTime}
                                            value={this.state.fromDateTime}
                                            onValueChanged={this.handleFromValueChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                    />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label> End Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDateTime"
                                            placeholder="MM/dd/yyyy"
                                            displayFormat="MM/dd/yyyy"
                                            showAnalogClock={false}
                                            useMaskBehavior={true}
                                            defaultValue={this.state.toDateTime}
                                            value={this.state.toDateTime}
                                            min={this.state.fromDateTime}
                                          // max={this.state.toDateTime}
                                            onValueChanged={this.handleToValueChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                    />
                                    {this.state.errors["dateRange"].length > 0 && <span className='error invalid-feedback'>
                                        {this.state.errors["dateRange"]}</span>}
                                    </div>
                            </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                    <label className="btn-block">&nbsp;</label>
                                    <button type="submit" className="btn btn-success btn-sm" onClick={this.getTrackList} >
                                            {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSubmited && <span title="Search"><i className="fas fa-search"></i> Search</span>}
                                        </button>&nbsp; &nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleClearSearch} title="Clear"><i className="fas fa-eraser"></i> Clear</button>&nbsp; &nbsp;
                                       
                                    </div>
                                </div>
                               </div>
                        </div>
                    </div>
                
                <div className="content">
                    <div className="card">
                        <div className="card-body">
                            <DataGrid
                                dataSource={this.state.trackLoglist}
                                keyExpr="rowIndex"
                                columnsAutoWidth={true}
                                showColumnLines={false}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                showBorders={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                width='100%'
                                onToolbarPreparing={this.onToolbarPreparing}>

                                {/*<Column dataField="vehicleNo" caption="Vehicle No" allowFiltering={true} allowSorting={true} />*/}
                                <Column dataField="deviceTime" caption="Device Time" allowFiltering={true} allowSorting={true} format={DateFormat.DateTimeFullFormat} dataType="datetime" />
                                <Column dataField="trackingTime" caption="Tracking Time" allowFiltering={true} allowSorting={true} format={DateFormat.DateTimeFullFormat} dataType="datetime" />
                                <Column dataField="latitude" caption="Latitude" allowFiltering={true} allowSorting={true} />
                                <Column dataField="longitude" caption="Longitude" allowFiltering={true} allowSorting={true} />
                                <Column dataField="speed" caption="Speed" allowFiltering={true} allowSorting={true} format={"#0.## kmph"} />
                                <Column dataField="altitude" caption="Altitude" allowFiltering={true} allowSorting={true} />
                                <Column dataField="bearing" caption="Bearing" allowFiltering={true} allowSorting={true} />
                                {/*<Column dataField="jobId" caption="Job Id" allowFiltering={true} allowSorting={true} />*/}
                                <Column dataField="tripId" caption="Trip Id" allowFiltering={true} allowSorting={true} />
                                <Column dataField="tripStatusName" caption="Trip StatusName" allowFiltering={true} allowSorting={true} />
                                {/*<Column dataField="trackingSourceId" caption="Tracking SourceId" allowFiltering={true} allowSorting={true} />*/}
                                
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={false} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true} />
                                <Export enabled={true} allowExportSelectedData={false} />
                                <ColumnChooser enabled={false} />
                                <ColumnFixing enabled={false} />
                            </DataGrid>
                        </div>
                    </div>

                </div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
            )
    }
}