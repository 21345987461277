import React, { Component } from 'react';

export class VerificationComponent extends Component {
    static displayName = VerificationComponent.name;

    render() {
        return (
            <div className="email-verification-page">
                <div className="email-verification-box">
                    <div className="email-verification-logo">
                        <a href="#"><img src="../../dist/img/logo_utwiz.png" alt="UTWiz" /></a>
                    </div>
                    <div className="card">
                        <div className="card-body login-card-body">
                            <p className="text-center"><i class="far fa-envelope fa-5x text-m"></i></p>
                            <h4 className="text-center"><strong>Email Verification</strong></h4>
                            <p className="text-center">Click the button below to confirm your email verification and activate your account.</p>
                            <p className="text-center"><button type="button" className="btn btn-danger"><i className="fab fa-google"></i> Email Verification</button></p>                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}