import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import { TripStatus } from '../../helpers/fixcodes';
const customStyles = {
    content: {
        width: '35%'
    }
};


export class AcceptDeclineNoShowComponent extends Component {
    static displayName = AcceptDeclineNoShowComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
            isSubmited: false,
            noShowRideId: 0,            
            isNoShowAccept: true,
            noShowRideDetails: [],
            dispatcherComments: "",
            pageName: '',
            errors: {
                dispatcherComments:"",
            }
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.closeAcceptDeclineNoShowModal = this.closeAcceptDeclineNoShowModal.bind(this);
        this.afterOpenAcceptDeclineNoShowModal = this.afterOpenAcceptDeclineNoShowModal.bind(this);
        this.handleAcceptNoShow = this.handleAcceptNoShow.bind(this);
        this.handleAcceptNoShowNonBillable = this.handleAcceptNoShowNonBillable.bind(this);
        this.handleDeclineReservation = this.handleDeclineReservation.bind(this);        
    }

    closeAcceptDeclineNoShowModal() {
        this.setState({
            isOpenModal: false,
            isSubmited: false,
            pageName: '',
            noShowRideId: 0,
            noShowRideDetails: [],
            isNoShowAccept: true,
            dispatcherComments: "",
        });
    }

    showModal(pageName, data, noshowStatus) {        
        this.setState({
            isOpenModal: true,
            pageName: pageName,
            noShowRideDetails: data,
            noShowRideId: data.rideId,
            isNoShowAccept: noshowStatus,
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        this.state.errors[name] = "";
    }

    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.dispatcherComments = "";

        if (this.state.dispatcherComments === "") {
            formIsValid = false;
            errors.dispatcherComments = "Please enter Dispatcher comments.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    async handleAcceptNoShow(e) {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isSubmited: true });                
                const ridedata = await this.ValidRide();
                
                if (ridedata.isSuccess) {
                    var acceptNoShowRequest = new Object();
                    acceptNoShowRequest.RideId = this.state.noShowRideId;
                    acceptNoShowRequest.TripStatusId = parseInt(TripStatus.NOSHOW);
                    acceptNoShowRequest.DispatcherComment = this.state.dispatcherComments;
                    acceptNoShowRequest.MachineIp = "";

                    const requestParams = getRequestParams('POST', acceptNoShowRequest);
                    const response = await fetch('/noshow-api/' + this.state.noShowRideDetails.requestId + '/accept-no-show-request', requestParams);
                    const data = await response.json();
                    
                    Swal.fire({
                        icon: data.isSuccess ? 'success' : 'error',
                        title: data.isSuccess ? 'No-Show Request' : 'Oops...',
                        text: data.resultMessage,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (data.isSuccess) {
                            if (result.isConfirmed) {
                                this.setState({ isSubmited: false });
                            }
                        } else {
                            this.setState({ isSubmited: false });
                        }
                    });
                }
            }
        } catch (ex) {            
            throw (ex);
        }
    }

    async handleAcceptNoShowNonBillable(e) {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isSubmited: true });
                const ridedata = await this.ValidRide();

                if (ridedata.isSuccess) {
                    var acceptNoShowRequest = new Object();
                    acceptNoShowRequest.RideId = this.state.noShowRideId;
                    acceptNoShowRequest.TripStatusId = parseInt(TripStatus.NOSHOWNONBILLABLE);
                    acceptNoShowRequest.DispatcherComment = this.state.dispatcherComments;
                    acceptNoShowRequest.MachineIp = "";

                    const requestParams = getRequestParams('POST', acceptNoShowRequest);
                    const response = await fetch('/noshow-api/' + this.state.noShowRideDetails.requestId + '/accept-no-show-request', requestParams);
                    const data = await response.json();

                    Swal.fire({
                        icon: data.isSuccess ? 'success' : 'error',
                        title: data.isSuccess ? 'No-Show Request' : 'Oops...',
                        text: data.resultMessage,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (data.isSuccess) {
                            if (result.isConfirmed) {
                                this.setState({ isSubmited: false });
                            }
                        } else {
                            this.setState({ isSubmited: false });
                        }
                    });
                }
            }
        } catch (ex) {
            throw (ex);
        }
    }

    async handleDeclineReservation(e) {
        e.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isSubmited: true });
                //TO CHECK RIDE IS VALID OR NOT 
                const ridedata = await this.ValidRide();
                if (ridedata.isSuccess) {
                    var declineNoShowRequest = new Object();
                    declineNoShowRequest.RideId = this.state.noShowRideId;
                    declineNoShowRequest.TripStatusId = parseInt(TripStatus.NOSHOWNONBILLABLE);
                    declineNoShowRequest.DispatcherComment = this.state.dispatcherComments;
                    declineNoShowRequest.MachineIp = "";

                    const requestParams = getRequestParams('POST', declineNoShowRequest);
                    const response = await fetch('/noshow-api/' + this.state.noShowRideDetails.requestId + '/decline-no-show-request', requestParams);
                    const data = await response.json();

                    Swal.fire({
                        icon: data.isSuccess ? 'success' : 'error',
                        title: data.isSuccess ? 'No-Show Request' : 'Oops...',
                        text: data.resultMessage,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (data.isSuccess) {
                            if (result.isConfirmed) {
                                this.setState({ isSubmited: false });
                            }
                        } else {
                            this.setState({ isSubmited: false });
                        }
                    });
                }
            }
        } catch (e) {
            //debugger;
        }
    }

    afterOpenAcceptDeclineNoShowModal() {
        this.setState({

        });
    }

    async ValidRide() {
        var validateRideRequest = new Object();
        validateRideRequest.RideId = this.state.noShowRideId;
        validateRideRequest.VehicleId = this.state.noShowRideDetails.vehicleId;
        const requestParams = getRequestParams('POST', validateRideRequest);
        const response = await fetch("noshow-api/valid-ride", requestParams);
        const data = await response.json();
        return data;
    }

    async NoShowBillableRide() {
        var completeJobRequest = new Object();
        completeJobRequest.DeviceId = "0";
        completeJobRequest.CompletedTime = new Date();
        completeJobRequest.Stops = parseInt(0);
        completeJobRequest.WaitTime = parseInt(0);
        completeJobRequest.Latitude = parseFloat(0.0);
        completeJobRequest.Longitude = parseFloat(0.0);
        completeJobRequest.DriverComments = "";
        completeJobRequest.NoShowReason = this.state.dispatcherComments;
        completeJobRequest.CompletedBy = false;
        const requestParams = getRequestParams('POST', completeJobRequest);
        const response = await fetch("dispatch-api/" + this.state.noShowRideId + '/no-show', requestParams);
        const data = await response.json();
        return data;
    }

    async NoShowNonBilllableRide() {
        var completeJobRequest = new Object();
        completeJobRequest.DeviceId = "0";
        completeJobRequest.CompletedTime = new Date();
        completeJobRequest.Stops = parseInt(0);
        completeJobRequest.WaitTime = parseInt(0);
        completeJobRequest.Latitude = parseFloat(0.0);
        completeJobRequest.Longitude = parseFloat(0.0);
        completeJobRequest.DriverComments = "";
        completeJobRequest.NoShowReason = this.state.dispatcherComments;
        completeJobRequest.CompletedBy = false;
        const requestParams = getRequestParams('POST', completeJobRequest);
        const response = await fetch("dispatch-api/" + this.state.noShowRideId + '/no-response', requestParams);
        const data = await response.json();
        return data;
    }

    async AcceptNoShowRide(statusId) {
        var acceptDeclineNoShowRequest = new Object();
        acceptDeclineNoShowRequest.RideId = this.state.noShowRideId;
        acceptDeclineNoShowRequest.NoShowReason = "";
        acceptDeclineNoShowRequest.StatusId = (parseInt(statusId));
        acceptDeclineNoShowRequest.Latitude = parseFloat(0);
        acceptDeclineNoShowRequest.Longitude = parseFloat(0);
        acceptDeclineNoShowRequest.DispatcherId = 0
        acceptDeclineNoShowRequest.DispatcherComments = this.state.dispatcherComments;
        acceptDeclineNoShowRequest.IsRequest = false;
        acceptDeclineNoShowRequest.MachineIp = "";
        acceptDeclineNoShowRequest.ShowResult = true;

        const requestParams = getRequestParams('POST', acceptDeclineNoShowRequest);
        const response = await fetch("noshow-api/accept-decline", requestParams);
        const data = await response.json();

        Swal.fire({
            icon: data.isSuccess ? 'success' : 'error',
            title: data.isSuccess ? 'Noshow Request' : 'Oops...',
            text: data.resultMessage,
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (data.isSuccess) {
                if (result.isConfirmed) {
                    this.setState({ isSubmited: false });
                    this.closeAcceptDeclineNoShowModal();
                }
            } else {
                this.setState({ isSubmited: false });
            }
        });
        return data;
    }

    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={customStyles}
                    className={"react-modal"}
                    onAfterOpen={this.afterOpenAcceptDeclineNoShowModal}
                    onRequestClose={this.closeAcceptDeclineNoShowModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleAcceptNoShow} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{this.state.pageName}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeAcceptDeclineNoShowModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label>Ride Id: <span><b>{this.state.noShowRideId}</b></span></label>
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label>Vehicle Number: <span>{this.state.noShowRideDetails.vehicleNo}</span></label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group">
                                        <label>Driver Comments: <span>{this.state.noShowRideDetails.noShowReason}</span></label>                                       
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group">
                                        <label>Dispatcher Comments </label>
                                        <input type="text" className="form-control form-control-sm" name="dispatcherComments" placeholder="Dispatcher Comments" maxLength="250" title="Dispatcher Comments" value={this.state.dispatcherComments} onChange={this.handleInputChange} />
                                        {this.state.errors["dispatcherComments"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["dispatcherComments"]}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            {
                                    this.state.isNoShowAccept ?
                                <button type="submit" className="btn btn-success btn-sm" onClick={this.handleAcceptNoShow} >
                                        {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                        {!this.state.isSubmited && <span title="No-Show Billable"><i className="fas fa-check"></i> No Show Billable</span>}
                                </button>
                                : null
                            }
                            {
                                    this.state.isNoShowAccept ?
                                <button type="submit" className="btn btn-success btn-sm" onClick={this.handleAcceptNoShowNonBillable} >
                                        {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                        {!this.state.isSubmited && <span title="No-Show Non Billable"><i className="fas fa-times"></i> No Show Non Billable</span>}
                                </button>
                                : null
                            }
                            {
                                !this.state.isNoShowAccept ?
                                    <button type="submit" className="btn btn-success btn-sm" onClick={this.handleDeclineReservation} >
                                        {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                        {!this.state.isSubmited && <span tirle="Decline"><i className="fas fa-times"></i> Decline</span>}
                                    </button>
                                    : null
                            }
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeAcceptDeclineNoShowModal} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
            </div>
        );
    }
}