import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import { TripStatus } from '../../helpers/fixcodes';
import NumericInput from 'react-numeric-input'; //npm install react-numeric-input --save
const customStyles = {
    content: {
        width: '35%'
    }
};

export class NoShowReservationComponent extends Component {
    static displayName = NoShowReservationComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
            isSubmited: false,
            isNoShowBillable: true,
            noShowRideId: 0,
            reservationId: 0,
            driverId: 0,
            vehicleNumber: '',
            extraStops: 0,
            waitingTime: 0,
            noShowReason: '',
            refreshRideSource: props.refreshDataSource,
            singleRideSource: props.singleRideSource,
            isStopAutoRefresh: props.isStopAutoRefresh,
            handleRefresh: props.handleRefresh,
            allowSocketConnection: sessionStorage.getItem("session_allowSocketConnection") === "true" ? true : false,
            pageName: '',
            errors: {
                extraStops: "",
                waitingTime: "",
                noShowReason: "",
            }
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.closeNoShowReservationModal = this.closeNoShowReservationModal.bind(this);
        this.afterOpenNoShowReservationModal = this.afterOpenNoShowReservationModal.bind(this);
        this.handleNoShowReservation = this.handleNoShowReservation.bind(this);
        this.handlExtraStopChange = this.handlExtraStopChange.bind(this);
        this.handleWaitingTimeChange = this.handleWaitingTimeChange.bind(this);
    }

    closeNoShowReservationModal() {
        this.setState({
            isOpenModal: false,
            isSubmited: false,
            pageName: '',
            noShowRideId: 0,
            reservationId: 0,
            driverId: 0,
            vehicleNumber: '',
        });

        if (!this.state.allowSocketConnection) {
            if (this.state.handleRefresh != undefined || this.state.handleRefresh != null) {
                this.state.handleRefresh(true);
            }
            if (this.state.refreshRideSource != undefined || this.state.refreshRideSource != null) {
                this.state.refreshRideSource();
            }
        }
    }

    showModal(pageName, rideId, reservationId, vehicleNumber, driverId) {
        this.setState({
            isOpenModal: true,
            pageName: pageName,
            noShowRideId: rideId,
            vehicleNumber: vehicleNumber,
            driverId: driverId,
            reservationId: reservationId,
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        this.state.errors[name] = "";
    }

    handlExtraStopChange(value) {
        if (value === null) {
            value = 0;
        }
        this.setState({ extraStops: value });
    }

    handleWaitingTimeChange(value) {
        if (value === null) {
            value = 0;
        }
        this.setState({ waitingTime: value });
    }

    handleValidation = () => {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.extraStops = "";
        errors.waitingTime = "";
        errors.noShowReason = "";

        if (this.state.extraStops < 0) {
            formIsValid = false;
            errors.extraStops = "Please enter extra stops.";
        }

        if (this.state.waitingTime < 0) {
            formIsValid = false;
            errors.waitingTime = "Please enter waiting time.";
        }

        if (this.state.noShowReason == "") {
            formIsValid = false;
            errors.noShowReason = "Please enter no show reason.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleNoShowReservation = async (e) => {
        try {
            let methodName = "";

            e.preventDefault();
            if (this.handleValidation()) {
                this.setState({ isSubmited: true });

                var completeJobWebRequest = new Object();
                completeJobWebRequest.Stops = parseInt(this.state.extraStops);
                completeJobWebRequest.WaitTime = parseInt(this.state.waitingTime);
                completeJobWebRequest.NoShowReason = this.state.noShowReason;
                completeJobWebRequest.MachineIP = "";

                if (this.state.isNoShowBillable === true) {
                    methodName = "no-show";
                }
                else {
                    methodName = "no-response";
                }
                const requestParams = getRequestParams('POST', completeJobWebRequest);
                const response = await fetch("dispatch-api/" + parseInt(this.state.noShowRideId) + "/" + methodName, requestParams);
                const data = await response.json();
                
                await Swal.fire({
                    icon: data.isSuccess ? 'success' : 'error',
                    title: data.isSuccess ? 'No show completed' : 'Oops...',
                    text: data.resultMessage,
                }).then(async (result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            if (this.state.handleRefresh != undefined || this.state.handleRefresh != null) {
                                this.state.handleRefresh(true);
                            }
                            if (this.state.refreshRideSource != undefined || this.state.refreshRideSource != null) {
                                this.state.refreshRideSource();
                            }
                        }
                    }
                });
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmited: false });
    }

    afterOpenNoShowReservationModal() {
        this.setState({

        });
    }

    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={customStyles}
                    className={"react-modal"}
                    onAfterOpen={this.afterOpenNoShowReservationModal}
                    onRequestClose={this.closeNoShowReservationModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleNoShowReservation} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{this.state.pageName}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeNoShowReservationModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group">
                                        <label>Ride Id <span className="text-danger">{this.state.noShowRideId}</span></label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group form-control-sm">
                                        <label>&nbsp;</label>
                                        <input type="radio" name="noShowBillableType" checked={this.state.isNoShowBillable === true} onChange={(e) => { this.setState({ isNoShowBillable: true }) }} />&nbsp;No-show Billable
                                        &nbsp;&nbsp;<input type="radio" name="noShowBillableType" checked={this.state.isNoShowBillable === false} onChange={(e) => { this.setState({ isNoShowBillable: false }) }} />&nbsp;No-show Non Billable
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label>Enter no of extra Stops# <span className="text-danger">*</span></label>
                                        <NumericInput className="form-control form-control-sm" name="extraStops" placeholder="Extra Stops" title="Extra Stops" min={0} max={50} value={this.state.extraStops} onChange={(value) => { this.handlExtraStopChange(value) }} />
                                        {this.state.errors["extraStops"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["extraStops"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label>Enter waiting time Min(s) <span className="text-danger">*</span></label>
                                        <NumericInput className="form-control form-control-sm" name="waitingTime" placeholder="Waiting Time" title="Waiting Time" min={0} max={300} value={this.state.waitingTime} onChange={(value) => { this.handleWaitingTimeChange(value) }} />
                                        {this.state.errors["waitingTime"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["waitingTime"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group">
                                        <label>Noshow Reason <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="noShowReason" placeholder="Noshow Reason" maxLength="250" title="Noshow Reason" value={this.state.noShowReason} onChange={this.handleInputChange} />
                                        {this.state.errors["noShowReason"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["noShowReason"]}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success btn-sm" onClick={this.handleNoShowReservation} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeNoShowReservationModal} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
            </div>
        );
    }
}