import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import NumericInput from 'react-numeric-input'; //npm install react-numeric-input --save
import Swal from 'sweetalert2';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import { TripStatus, LogType } from '../../helpers/fixcodes';
const customStyles = {
    content: {
        width: '35%'
    }
};

export class CompleteReservationComponent extends Component {
    static displayName = CompleteReservationComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
            isSubmited: false,
            reason: "",
            rideId: 0,
            driverId: 0,
            vehicleNo: "",
            waitingTime: 0,
            noOfStops: 0,
            refreshRideSource: props.refreshDataSource,
            handleRefresh: props.handleRefresh,
            pageName: '',
            pageSource: '',
            errors: {
                reason: '',
            }
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.closeCompleteReservationModal = this.closeCompleteReservationModal.bind(this);
        this.afterOpenCompleteReservationModal = this.afterOpenCompleteReservationModal.bind(this);
        this.handleCompleteReservation = this.handleCompleteReservation.bind(this);
    }

    closeCompleteReservationModal() {
        this.setState({
            isOpenModal: false,
            isSubmited: false,
            reason: "",
            rideId: 0,
            driverId: 0,
            vehicleNo: "",
            waitingTime: 0,
            noOfStops: 0,
            pageName: '',
            pageSource: '',
            airlineCode:"",
            errors: {
                reason: '',
            }
        });

        if (this.state.handleRefresh != undefined || this.state.handleRefresh != null) {
            this.state.handleRefresh(true);
        }
        if (this.state.refreshRideSource != undefined || this.state.refreshRideSource != null) {
            this.state.refreshRideSource();
        }
    }

    showModal(pageName, rideId, driverId, vehicleNo, pageSource, airlineCode) {
        this.setState({
            isOpenModal: true,
            pageName: pageName,
            rideId: rideId,
            driverId: driverId,
            vehicleNo: vehicleNo,
            pageSource: pageSource,
            airlineCode: airlineCode,
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        this.state.errors[name] = "";
    }

    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.reason = "";

        if (this.state.reason.trim() == '') {
            formIsValid = false;
            errors.reason = "Please enter reason.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    async handleCompleteReservation(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            this.setState({ isSubmited: true });

            var completeRideRequest = new Object();
            completeRideRequest.ReservationId = 0;
            completeRideRequest.StatusId = parseInt(TripStatus.COMPLETED);
            completeRideRequest.DriverId = this.state.driverId;
            completeRideRequest.VehicleNo = this.state.vehicleNo;
            completeRideRequest.NoOfStop = this.state.noOfStops;
            completeRideRequest.StopWaitTime = this.state.waitingTime;
            completeRideRequest.Reason = this.state.reason;

            const requestParams = getRequestParams('PUT', completeRideRequest);
            const response = await fetch('/ride-api/' + this.state.rideId + '/complete', requestParams);
            const data = await response.json();

            if (data.isSuccess) {
                if (this.state.airlineCode === "QP") {
                    const requestRateParams = getRequestParams('GET');
                    const rateResponse = await fetch('/manage-rides-api/rate-calculate/' + this.state.rideId, requestRateParams);
                    const dataRate = await rateResponse.json();
                }
            }

            Swal.fire({
                icon: data.isSuccess ? 'success' : 'error',
                title: data.isSuccess ? 'Complete Reservation' : 'Oops...',
                text: data.resultMessage,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (data.isSuccess) {
                    if (result.isConfirmed) {
                        this.setState({ isSubmited: false });
                        if (this.state.handleRefresh != undefined || this.state.handleRefresh != null) {
                            this.state.handleRefresh(true);
                        }
                        if (this.state.refreshRideSource != undefined || this.state.refreshRideSource != null) {
                            this.state.refreshRideSource();
                        }
                    }
                } else {
                    this.setState({ isSubmited: false });
                }
            });
        }
    }

    afterOpenCompleteReservationModal() {
        this.setState({
            reason: "",
            waitingTime: 0,
            noOfStops: 0,
        });
    }

    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={customStyles}
                    className={"react-modal"}
                    onAfterOpen={this.afterOpenCompleteReservationModal}
                    onRequestClose={this.closeCompleteReservationModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleCompleteReservation} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{this.state.pageName}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeCompleteReservationModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label>Waiting Time (Min.)</label>
                                        <NumericInput className="form-control form-control-sm" name="waitingTime" title="Waiting Time" value={this.state.waitingTime} min={0} max={1000} onChange={(value) => { this.setState({ waitingTime: value }) }} />
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label>No. of Stops</label>
                                        <NumericInput className="form-control form-control-sm" name="noOfStops" title="No Of Stops" value={this.state.noOfStops} min={0} max={100} onChange={(value) => { this.setState({ noOfStops: value }) }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group">
                                        <label>Reason <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="reason" placeholder="Reason" maxLength="250" title="Reason" value={this.state.reason} onChange={this.handleInputChange} />
                                        {this.state.errors["reason"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["reason"]}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success btn-sm" onClick={this.handleCompleteReservation} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeCompleteReservationModal} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
            </div>
        );
    }
}