import React, { Component } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Modal from 'react-modal'; //npm install --save react-modal
import 'react-tabs/style/react-tabs.css';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import { LoadPanel } from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import Swal from 'sweetalert2';
import { getRequestParams } from '../../helpers/globalfunctions.js';
export class VehicleHistoryComponent extends Component {
    static displayName = VehicleHistoryComponent.name;
    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
            isSubmited: false,
            isFilterSubmitted: false,
            loadPanelVisible: false,
            refreshRideSource: props.refreshDataSource,
            handleRefresh: props.handleRefresh,
            pageName: '',
            vehicleNumber: '',
            filterFromDatetime: moment(new Date()).format("MM/DD/YYYY"),
            filterToDatetime: moment(new Date()).format("MM/DD/YYYY"),
            vehicleRideHistoryGridDataSource: null,
            vehicleWorkHourCurrentDriverGridDataSource: null,
            vehicleWorkHourLastDriverGridDataSource: null,
            allowSocketConnection: sessionStorage.getItem("session_allowSocketConnection") === "true" ? true : false,
            vehicleHistoryInfo: {
                vehicleNo: '',
                vehicleTypeDescription: '',                
                vehicleOwnerName: '',
                vehicleSeat: 0,
                vehicleYear: '',
                vehicleModel: '',
                vehicleOwnerTypeName: '',
                vehicleLicenceNo: '',
                vehicleStatusDescription: '',
                permitNo: '',
                currentLoginDriverName: '',
                currentLoginDriverEmail: '',
                currentLoginDriverMobileNo: '',
                currentLoginDriverAddress: '',
                previousLoginDriverName: '',
                previousLoginDriverEmail: '',
                previousLoginDriverMobileNo: '',
                previousLoginDriverAddress: '',
                previousLoginDriverLogoutTime:'',
            },
        }
        this.getVehicleHistoryInfo = this.getVehicleHistoryInfo.bind(this);
        this.getVehicleRideHistory = this.getVehicleRideHistory.bind(this);
        this.getVehicleCurrentDriverWorkHours = this.getVehicleCurrentDriverWorkHours.bind(this);
        this.getVehicleLastDriverWorkHours = this.getVehicleLastDriverWorkHours.bind(this);
        this.getVehicleWorkHours = this.getVehicleWorkHours.bind(this);
    }
    async getVehicleHistoryInfo(vehicleNumber) {
        try {
            var request = new Object();
            request.VehicleNo = vehicleNumber;
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('vehicle-history-api/info', requestParams);
            if (response.ok) {
                const data = await response.json();
                if (data.isSuccess) {
                    const vehicle = data.vehicleHistoryInfo;
                    let vehicleHistoryInfo = this.state.vehicleHistoryInfo;
                    vehicleHistoryInfo.vehicleNo = vehicle.vehicleNo === null ? '' : vehicle.vehicleNo.trim();
                    vehicleHistoryInfo.vehicleTypeDescription = vehicle.vehicleTypeDescription === null ? '' : vehicle.vehicleTypeDescription.trim();
                    vehicleHistoryInfo.vehicleOwnerName = vehicle.vehicleOwnerName === null ? '' : vehicle.vehicleOwnerName.trim();
                    vehicleHistoryInfo.vehicleSeat = vehicle.vehicleSeat === null ? 0 : vehicle.vehicleSeat;
                    vehicleHistoryInfo.vehicleYear = vehicle.vehicleYear === null ? '' : vehicle.vehicleYear.trim();
                    vehicleHistoryInfo.vehicleModel = vehicle.vehicleModel === null ? '' : vehicle.vehicleModel.trim();
                    vehicleHistoryInfo.vehicleOwnerTypeName = vehicle.vehicleOwnerTypeName === null ? '' : vehicle.vehicleOwnerTypeName.trim();
                    vehicleHistoryInfo.vehicleLicenceNo = vehicle.vehicleLicenceNo === null ? '' : vehicle.vehicleLicenceNo.trim();
                    vehicleHistoryInfo.vehicleStatusDescription = vehicle.vehicleStatusDescription === null ? '' : vehicle.vehicleStatusDescription.trim();
                    vehicleHistoryInfo.permitNo = vehicle.permitNo === null ? '' : vehicle.permitNo.trim();
                    vehicleHistoryInfo.currentLoginDriverName = vehicle.driverName === null ? '' : vehicle.driverName.trim();
                    vehicleHistoryInfo.currentLoginDriverEmail = vehicle.driverEmailId === null ? '' : vehicle.driverEmailId.trim();
                    vehicleHistoryInfo.currentLoginDriverMobileNo = vehicle.driverMobileNo === null ? '' : vehicle.driverMobileNo.trim();
                    vehicleHistoryInfo.currentLoginDriverAddress = vehicle.driverAddress === null ? '' : vehicle.driverAddress.trim();
                    vehicleHistoryInfo.previousLoginDriverName = vehicle.lastLoginDriverName === null ? '' : vehicle.lastLoginDriverName.trim();
                    vehicleHistoryInfo.previousLoginDriverEmail = vehicle.lastLoginDriverEmailId === null ? '' : vehicle.lastLoginDriverEmailId.trim();
                    vehicleHistoryInfo.previousLoginDriverMobileNo = vehicle.lastLoginDriverMobileNo === null ? '' : vehicle.lastLoginDriverMobileNo.trim();
                    vehicleHistoryInfo.previousLoginDriverAddress = vehicle.lastLoginDriverAddress === null ? '' : vehicle.lastLoginDriverAddress.trim();
                    vehicleHistoryInfo.previousLoginDriverLogoutTime = vehicle.lastLogoutDriverTime === null ? '' : vehicle.lastLogoutDriverTime.trim();
                    this.setState({
                        vehicleHistoryInfo: vehicleHistoryInfo
                    });
                }
            }
            else {
                Swal.fire({
                    allowOutsideClick: false,
                    showCloseButton: true,
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Unable to process the request.',
                })
            }
        } catch (ex) {
            console.log(ex);
            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Unable to process the request.',
            })
        }
    };
    async getVehicleRideHistory(vehicleNumber) {
        try {
            var request = new Object();
            request.VehicleNo = vehicleNumber;
            if (this.state.filterFromDatetime.trim() !== '') {
                request.FromDate = moment(this.state.filterFromDatetime.trim(), "MM/DD/YYYY").format("YYYY-MM-DD") + " 00:00:00";
            }
            if (this.state.filterToDatetime.trim() !== '') {
                request.ToDate = moment(this.state.filterToDatetime.trim(), "MM/DD/YYYY").format("YYYY-MM-DD") + " 23:59:59";
            }
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('vehicle-history-api/ride-details', requestParams);
            if (response.ok) {
                const data = await response.json();
                if (data.isSuccess) {
                    this.setState({
                        vehicleRideHistoryGridDataSource: data.vehicleRideHistory
                    });
                }
            }
            else {
                Swal.fire({
                    allowOutsideClick: false,
                    showCloseButton: true,
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Unable to process the request.',
                })
            }
        } catch (ex) {
            console.log(ex);
            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Unable to process the request.',
            })
        }
    };
    async getVehicleCurrentDriverWorkHours() {
        try {
            var request = new Object();
            request.VehicleNo = this.state.vehicleNumber;
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('vehicle-history-api/work-hours', requestParams);
            if (response.ok) {
                let data = await response.json();
                if (data.isSuccess) {
                    data = JSON.parse(JSON.stringify(data.workingHours));
                    const vehicleWorkHourCurrentDriverGridDataSource = data.filter(item => item.driverViewNo === 1);
                    this.setState({
                        vehicleWorkHourCurrentDriverGridDataSource: vehicleWorkHourCurrentDriverGridDataSource,
                    });
                }
            }
            else {
                Swal.fire({
                    allowOutsideClick: false,
                    showCloseButton: true,
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Unable to process the request.',
                })
            }
        } catch (ex) {
            console.log(ex);
            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Unable to process the request.',
            })
        }
    };
    async getVehicleLastDriverWorkHours() {
        try {
            var request = new Object();
            request.VehicleNo = this.state.vehicleNumber;
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('vehicle-history-api/work-hours', requestParams);
            if (response.ok) {
                let data = await response.json();
                if (data.isSuccess) {
                    data = JSON.parse(JSON.stringify(data.workingHours));
                    const vehicleWorkHourLastDriverGridDataSource = data.filter(item => item.driverViewNo === 2);
                    this.setState({
                        vehicleWorkHourLastDriverGridDataSource: vehicleWorkHourLastDriverGridDataSource
                    });
                }
            }
            else {
                Swal.fire({
                    allowOutsideClick: false,
                    showCloseButton: true,
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Unable to process the request.',
                })
            }
        } catch (e) {
            console.log(e);
            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Unable to process the request.',
            })
        }
    };
    async getVehicleWorkHours() {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getVehicleCurrentDriverWorkHours();
            await this.getVehicleLastDriverWorkHours();

        } catch (ex) {
            console.log(ex);
            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Unable to process the request.',
            })
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCurrentDriverWorkHourToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getVehicleCurrentDriverWorkHours.bind(this)
            }
        });
    }
    handleOnLastDriverWorkHourToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getVehicleLastDriverWorkHours.bind(this)
            }
        });
    }
    showModal = async (vehicleNumber) => {
        this.setState({
            isOpenModal: true,
            vehicleNumber: vehicleNumber,
        });
        this.setState({ loadPanelVisible: true });
        await this.getVehicleHistoryInfo(vehicleNumber);
        await this.getVehicleRideHistory(vehicleNumber);
        this.setState({ loadPanelVisible: false });
    }
    handleCloseModal = () => {
        this.setState({
            isOpenModal: false,
            isSubmited: false,
            isFilterSubmitted: false,
            loadPanelVisible: false,
            vehicleNumber: '',
            filterFromDatetime: moment(new Date()).format("MM/DD/YYYY"),
            filterToDatetime: moment(new Date()).format("MM/DD/YYYY"),
            vehicleRideHistoryGridDataSource: null,
            vehicleWorkHourCurrentDriverGridDataSource: null,
            vehicleWorkHourLastDriverGridDataSource: null,
            vehicleHistoryInfo: {
                vehicleNo: '',
                vehicleTypeDescription: '',
                vehicleOwnerName: '',
                vehicleSeat: 0,
                vehicleYear: '',
                vehicleModel: '',
                vehicleOwnerTypeName: '',
                vehicleLicenceNo: '',
                vehicleStatusDescription: '',
                permitNo: '',
                currentLoginDriverName: '',
                currentLoginDriverEmail: '',
                currentLoginDriverMobileNo: '',
                currentLoginDriverAddress: '',
                previousLoginDriverName: '',
                previousLoginDriverEmail: '',
                previousLoginDriverMobileNo: '',
                previousLoginDriverAddress: '',
                previousLoginDriverLogoutTime: '',
            },
        });
        if (!this.state.allowSocketConnection) {
            this.state.handleRefresh(true);
            this.state.refreshRideSource();
        }
    }
    handleSearch = async (event) => {
        event.preventDefault();
        this.setState({ isFilterSubmitted: true, loadPanelVisible: true });
        await this.getVehicleRideHistory(this.state.vehicleNumber);
        this.setState({ isFilterSubmitted: false, loadPanelVisible: false });
    }
    handleFilterOnValueChangedFromDate = (event) => {
        const value = moment(event.value).format("MM/DD/YYYY");
        this.setState({ filterFromDatetime: value });
    }
    handleFilterOnValueChangedToDate = (event) => {
        const value = moment(event.value).format("MM/DD/YYYY");
        this.setState({ filterToDatetime: value });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '75%' } }}
                    className={"react-modal"}
                    onRequestClose={this.handleCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div id="historydiv">
                            <div className="modal-header">
                                <h6 className="modal-title">Vehicle Work History - <i><strong>{this.state.vehicleNumber}</strong></i></h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCloseModal}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="content">
                                    <ul className="nav nav-tabs" id="custom-content-below-tab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" id="vehilce-history-tab" data-toggle="pill" href="#vehicle-history" role="tab" aria-controls="vehicle-history" aria-selected="true" >Vehicle History</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="vehilce-info-tab" data-toggle="pill" href="#vehicle-info" role="tab" aria-controls="vehicle-info" aria-selected="false" >Vehicle Information</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="driver-hours-tab" data-toggle="pill" href="#driver-hours" role="tab" aria-controls="driver-hours" aria-selected="false" onClick={this.getVehicleWorkHours}>Driver's Hours</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="custom-content-below-tabContent">
                                        <div className="tab-pane fade show active" id="vehicle-history" role="tabpanel" aria-labelledby="vehicle-history-tab">
                                            <div style={{ height: 300 + 'px', overflowY: 'auto', overflowX: 'hidden' }}>
                                                <div className="row">
                                                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3" style={{ marginLeft: '10px' }}>
                                                        <div className="form-group">
                                                            <DateBox type="date" className="form-control form-control-sm input-date" id="fromDate" name="fromDate" placeholder="MM/dd/yyyy"
                                                                defaultValue={this.state.filterFromDatetime}
                                                                onValueChanged={this.handleFilterOnValueChangedFromDate}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                                        <div className="form-group">
                                                            <DateBox type="date" className="form-control form-control-sm input-date" id="toDate" name="toDate" placeholder="MM/dd/yyyy"
                                                                defaultValue={this.state.filterToDatetime}
                                                                min={this.state.filterFromDatetime}
                                                                onValueChanged={this.handleFilterOnValueChangedToDate}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                                        <div className="form-group">
                                                            <button type="button" className="btn btn-success btn-sm" onClick={(e) => { this.handleSearch(e) }}>
                                                                {this.state.isFilterSubmitted && <span><i className="fas fa-spinner fa-spin"></i> In Process</span>}
                                                                {!this.state.isFilterSubmitted && <span><i className="fas fa-search"></i> Search</span>}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <DataGrid
                                                    dataSource={this.state.vehicleRideHistoryGridDataSource}
                                                    keyExpr="rideId"
                                                    selection={{ mode: 'single' }}
                                                    columnsAutoWidth={false}
                                                    showColumnLines={true}
                                                    showRowLines={true}
                                                    showBorders={true}
                                                    rowAlternationEnabled={true}
                                                    allowColumnResizing={true}
                                                    columnResizingMode='widget'
                                                >
                                                    <Column dataField="rideId" caption="Ride Id" alignment="left" width="auto" />
                                                    <Column dataField="pickupDatetime" caption="Pick-up Time" width="auto" />
                                                    <Column dataField="paxName" caption="Pax Name" />
                                                    <Column dataField="pax" caption="Pax" alignment="center" width="auto" />
                                                    <Column dataField="puStreet" caption="Pick-up" />
                                                    <Column dataField="doStreet" caption="Drop Off" />
                                                    <Column dataField="tripStatusDescription" caption="Trip Status" />
                                                    <Column dataField="permanentRemard" caption="Permanent Remark" />
                                                    <Column dataField="tripRemark" caption="Trip Remark" />
                                                    <SortByGroupSummaryInfo summaryItem="count" />
                                                    <GroupPanel visible={false} />
                                                    <HeaderFilter visible={false} />
                                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                                    <Grouping autoExpandAll={false} />
                                                    <Paging defaultPageSize={5} />
                                                    <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                                    <Export enabled={false} allowExportSelectedData={false} />
                                                    <ColumnChooser enabled={false} />
                                                    <ColumnFixing enabled={false} />
                                                </DataGrid>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="vehicle-info" role="tabpanel" aria-labelledby="vehicle-info-tab">
                                            <div style={{ height: 300 + 'px', overflowY: 'auto', overflowX: 'hidden' }}>
                                                <div className="content">
                                                    <div className="card main-rez-card">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                    <div className="card sub-rez-card">
                                                                        <div className="card-header">
                                                                            <h6 className="card-title m-0">Vehicle Details</h6>
                                                                        </div>
                                                                        <div className="card card-grid">
                                                                            <div className="card-body">
                                                                                <div className="row">
                                                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                                                                        <img src="#" className="img-size-50 mr-3 img-circle" />
                                                                                    </div>
                                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                                        <div className="form-group">
                                                                                            <label>Vehicle Number</label>
                                                                                            <input type="text" className="form-control form-control-sm" name="vehicleNo" title="Vehicle Number" readOnly={true} value={this.state.vehicleHistoryInfo.vehicleNo} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                                        <div className="form-group">
                                                                                            <label>Fleet</label>
                                                                                            <input type="text" className="form-control form-control-sm" name="vehicleType" title="Vehicle Type" readOnly={true} value={this.state.vehicleHistoryInfo.vehicleTypeDescription} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                                        <div className="form-group">
                                                                                            <label>Owner</label>
                                                                                            <input type="text" className="form-control form-control-sm" name="ownerName" title="Owner" readOnly={true} value={this.state.vehicleHistoryInfo.vehicleOwnerName} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                                        <div className="form-group">
                                                                                            <label>Seat </label>
                                                                                            <input type="text" className="form-control form-control-sm" name="seat" title="Seat" readOnly={true} value={this.state.vehicleHistoryInfo.vehicleSeat} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                                        <div className="form-group">
                                                                                            <label>Year</label>
                                                                                            <input type="text" className="form-control form-control-sm" name="year" title="Year" readOnly={true} value={this.state.vehicleHistoryInfo.vehicleYear} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                                        <div className="form-group">
                                                                                            <label>Model</label>
                                                                                            <input type="text" className="form-control form-control-sm" name="model" title="Model" readOnly={true} value={this.state.vehicleHistoryInfo.vehicleModel} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                                        <div className="form-group">
                                                                                            <label>Owner Type</label>

                                                                                            <input type="text" className="form-control form-control-sm" name="ownerType" title="Owner Type" readOnly={true} value={this.state.vehicleHistoryInfo.vehicleOwnerTypeName} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                                        <div className="form-group">
                                                                                            <label>License Number</label>
                                                                                            <input type="text" className="form-control form-control-sm" name="licenseNo" title="License Number" readOnly={true} value={this.state.vehicleHistoryInfo.vehicleLicenceNo} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                                        <div className="form-group">
                                                                                            <label>Status</label>
                                                                                            <input type="text" className="form-control form-control-sm" name="status" title="Status" readOnly={true} value={this.state.vehicleHistoryInfo.vehicleStatusDescription} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                                        <div className="form-group">
                                                                                            <label>Permit Number</label>
                                                                                            <input type="text" className="form-control form-control-sm" name="permitNo" title="Permit Number" readOnly={true} value={this.state.vehicleHistoryInfo.permitNo} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                    <div className="card sub-rez-card">
                                                                        <div className="card-header">
                                                                            <h5 className="card-title m-0">Current Driver Login Details</h5>
                                                                        </div>
                                                                        <div className="card card-grid">
                                                                            <div className="card-body">
                                                                                <div className="row">
                                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                                        <div className="form-group">
                                                                                            <label>Driver Name</label>
                                                                                            <input type="text" className="form-control form-textbox form-control-sm" name="driverName" title="Driver Name" readOnly={true} value={this.state.vehicleHistoryInfo.currentLoginDriverName} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                                        <div className="form-group">
                                                                                            <label>Email </label>
                                                                                            <input type="text" className="form-control form-textbox form-control-sm" name="driveremail" title="Email" readOnly={true} value={this.state.vehicleHistoryInfo.currentLoginDriverEmail} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                                        <div className="form-group">
                                                                                            <label>Mobile</label>
                                                                                            <input type="text" className="form-control form-textbox form-control-sm" name="driverMobile" title="Mobile" readOnly={true} value={this.state.vehicleHistoryInfo.currentLoginDriverMobileNo} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                                         <div className="form-group">
                                                                                            <label>Address</label>                                                                                        
                                                                                            <textarea className="form-control form-textbox form-control-sm" name="driverAddress" rows="2" readOnly={true} disabled={this.state.isView} value={this.state.vehicleHistoryInfo.currentLoginDriverAddress} />
                                                                                        </div>
                                                                                    </div>                                                                                   
                                                                                </div>                                                                               
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card sub-rez-card">
                                                                        <div className="card-header">
                                                                            <h6 className="card-title m-0">Previous Driver Login Details</h6>
                                                                        </div>
                                                                        <div className="card card-grid">
                                                                            <div className="card-body">
                                                                                <div className="row">
                                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                                        <div className="form-group">
                                                                                            <label>Driver Name</label>
                                                                                            <input type="text" className="form-control form-textbox form-control-sm" name="lastDriverName" title="Driver Name" readOnly={true} value={this.state.vehicleHistoryInfo.previousLoginDriverName} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                                        <div className="form-group">
                                                                                            <label>Email </label>
                                                                                            <input type="text" className="form-control form-textbox form-control-sm" name="lastDriveremail" title="Email" readOnly={true} value={this.state.vehicleHistoryInfo.previousLoginDriverEmail} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                                        <div className="form-group">
                                                                                            <label>Mobile</label>
                                                                                            <input type="text" className="form-control form-textbox form-control-sm" name="lastDriverMobile" title="Mobile" readOnly={true} value={this.state.vehicleHistoryInfo.previousLoginDriverMobileNo} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                                        <div className="form-group">
                                                                                            <label>Logout Time</label>
                                                                                            <input type="text" className="form-control form-textbox form-control-sm" name="lastDriverLogoutime" title="Logout Time" readOnly={true} value={this.state.vehicleHistoryInfo.previousLoginDriverLogoutTime} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                                        <div className="form-group">
                                                                                            <label>Address</label>
                                                                                            <textarea className="form-control form-textbox form-control-sm" name="lastDriverAddress" rows="2" readOnly={true} disabled={this.state.isView} value={this.state.vehicleHistoryInfo.previousLoginDriverAddress} />
                                                                                        </div>
                                                                                    </div>                                                                                    
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="driver-hours" role="tabpanel" aria-labelledby="driver-hours-tab">
                                            <div style={{ height: 300 + 'px', overflowY: 'auto', overflowX: 'hidden' }}>
                                                <div className="content">
                                                    <div className="card main-rez-card">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                    <div className="card sub-rez-card">
                                                                        <div className="card-header">
                                                                            <h6 className="card-title m-0">Previous Driver Work History</h6>
                                                                        </div>
                                                                        <div className="card card-grid">
                                                                            <div className="card-body">
                                                                                <DataGrid
                                                                                    dataSource={this.state.vehicleWorkHourLastDriverGridDataSource}
                                                                                    keyExpr="id"
                                                                                    selection={{ mode: 'single' }}
                                                                                    columnsAutoWidth={false}
                                                                                    showColumnLines={true}
                                                                                    showRowLines={true}
                                                                                    showBorders={true}
                                                                                    rowAlternationEnabled={true}
                                                                                    allowColumnResizing={true}
                                                                                    columnResizingMode='widget'
                                                                                    onToolbarPreparing={this.handleOnLastDriverWorkHourToolbarPreparing}
                                                                                >
                                                                                    <Column dataField="workDateDisplay" caption="Work Day" allowSorting={false} width="45%" />
                                                                                    <Column dataField="dailyWorking" caption="Daily Work" allowSorting={false} />
                                                                                    <Column dataField="weeklyWorking" caption="Weekly Work" allowSorting={false} />
                                                                                    <SortByGroupSummaryInfo summaryItem="count" />
                                                                                    <GroupPanel visible={false} />
                                                                                    <HeaderFilter visible={false} />
                                                                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                                                                    <Grouping autoExpandAll={false} />
                                                                                    <Paging defaultPageSize={5} />
                                                                                    <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                                                                    <Export enabled={false} allowExportSelectedData={false} />
                                                                                    <ColumnChooser enabled={false} />
                                                                                    <ColumnFixing enabled={false} />
                                                                                </DataGrid>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                    <div className="card sub-rez-card">
                                                                        <div className="card-header">
                                                                            <h6 className="card-title m-0">Current Driver Work History</h6>
                                                                        </div>
                                                                        <div className="card card-grid">
                                                                            <div className="card-body">
                                                                                <DataGrid
                                                                                    dataSource={this.state.vehicleWorkHourCurrentDriverGridDataSource}
                                                                                    keyExpr="id"
                                                                                    selection={{ mode: 'single' }}
                                                                                    columnsAutoWidth={false}
                                                                                    showColumnLines={true}
                                                                                    showRowLines={true}
                                                                                    showBorders={true}
                                                                                    rowAlternationEnabled={true}
                                                                                    allowColumnResizing={true}
                                                                                    columnResizingMode='widget'
                                                                                    onToolbarPreparing={this.handleOnCurrentDriverWorkHourToolbarPreparing}
                                                                                >
                                                                                    <Column dataField="workDateDisplay" caption="Work Day" allowSorting={false} width="45%" />
                                                                                    <Column dataField="dailyWorking" caption="Daily Work" allowSorting={false} />
                                                                                    <Column dataField="weeklyWorking" caption="Weekly Work" allowSorting={false} />
                                                                                    <SortByGroupSummaryInfo summaryItem="count" />
                                                                                    <GroupPanel visible={false} />
                                                                                    <HeaderFilter visible={false} />
                                                                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                                                                    <Grouping autoExpandAll={false} />
                                                                                    <Paging defaultPageSize={5} />
                                                                                    <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                                                                    <Export enabled={false} allowExportSelectedData={false} />
                                                                                    <ColumnChooser enabled={false} />
                                                                                    <ColumnFixing enabled={false} />
                                                                                </DataGrid>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleCloseModal}><i className="fas fa-times"></i> Close</button>
                            </div>
                        </div>
                        <LoadPanel
                            shadingColor="rgba(0,0,0,0.4)"
                            position={{ of: '#historydiv' }}
                            visible={this.state.loadPanelVisible}
                            showIndicator={true}
                            shading={true}
                            showPane={true}
                            closeOnOutsideClick={false}
                        />
                    </form>
                </Modal>
            </div>
        );
    }
}