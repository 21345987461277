import React, { Component } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    Selection,
    Scrolling,
    SortByGroupSummaryInfo,
    FilterRow
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { SelectBox } from 'devextreme-react/select-box';
import DropDownBox from 'devextreme-react/drop-down-box';
import DateBox from 'devextreme-react/date-box';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import { LoadPanel } from 'devextreme-react/load-panel';
import DatePicker from "react-datepicker"; // npm install react - datepicker --save
import { DateFormat } from '../../helpers/fixcodes';
export class DriverLoginHistoryListComponent extends Component {
    constructor() {
        super()
        this.state = {
            isSubmitted: false,
            isFilterSubmitted: false,
            loadPanelVisible: false,
            isOpenAddEditModal: false,
            driverLoinHistoryGridDataSource: null,
            driverLoginHistoryData: null,
            driversDropdownList: [],
            vehiclesDropdownList: [],
            vehicleGroupTypeDropdownList: [],
            filterDriverId: 0,
            filterVehicleId: 0,
            filterFromDatetime: moment(new Date()).format("MM/DD/YYYY"),
            filterToDatetime: moment(new Date()).format("MM/DD/YYYY"),
            filterWorkedInHours: 0,
            filterVehicleGroupTypeIds: [],
            isDriverAllChecked: true,
            isVehicleAllChecked: true,
            isVehicleGroupAllChecked: true,
            isDateAllChecked: false,
            addEditDeleteParam: {
                id: 0,
                driverId: 0,
                vehicleId: 0,
                loginDatetime: new Date(),
                logoutDatetime: new Date(),
                loginByUserUserName: '',
                logoutByUserId: 0,
                logoutByUserDropdownList: [],
                timeDiffStrDisplay: '',
            },
            errors: {
                driverName: '',
                vehicleName: '',
                logoutDatetime: '',
                logoutByUserName: '',
            }
        }

        this.onSelectionFleetChanged = this.onSelectionFleetChanged.bind(this);
        this.syncDataGridFleetSelection = this.syncDataGridFleetSelection.bind(this);
        this.dataGridFleetRender = this.dataGridFleetRender.bind(this);
    }
    componentDidMount() {
        this.fetchApiData();
    }
    getDriverLoginHistoryList = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            var request = new Object();
            request.DriverId = Number(this.state.filterDriverId);
            request.VehicleId = Number(this.state.filterVehicleId);
            if (this.state.isDateAllChecked === false) {
                if (this.state.filterFromDatetime.trim() !== '') {
                    request.StartDate = moment(this.state.filterFromDatetime.trim(), "MM/DD/YYYY").format("YYYY-MM-DD") + " 00:00:00";
                }
                if (this.state.filterToDatetime.trim() !== '') {
                    request.EndDate = moment(this.state.filterToDatetime.trim(), "MM/DD/YYYY").format("YYYY-MM-DD") + " 23:59:59";
                }
            }
            else {
                request.StartDate = '';
                request.EndDate = '';
            }
            request.WorkedInHours = Number(this.state.filterWorkedInHours);
            let vgTypeIds = '';
            if (this.state.filterVehicleGroupTypeIds.length > 0) {
                for (var i = 0; i < this.state.filterVehicleGroupTypeIds.length; i++) {
                    if (vgTypeIds.length > 0) {

                        vgTypeIds = vgTypeIds + "," + this.state.filterVehicleGroupTypeIds[i].toString();
                    }
                    else {
                        vgTypeIds = vgTypeIds + this.state.filterVehicleGroupTypeIds[i].toString();
                    }
                }
            }
            request.VehicleGroupTypeId = vgTypeIds;
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('driver-login-history-api/0/list', requestParams);
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    driverLoinHistoryGridDataSource: data.driverLoginHistory
                });
            }
            else {
                Swal.fire({
                    allowOutsideClick: false,
                    showCloseButton: true,
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Unable to process the request.',
                })
            }
        } catch (ex) {
            console.log(ex);
            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Unable to process the request.',
            })
        }
        this.setState({ loadPanelVisible: false });
    }
    getDriversDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/driver-api/dropdown-all/', requestParams);
            const data = await response.json();
            this.setState({
                driversDropdownList: data.driversDropdownList
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    getVehiclesDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/vehicle-api/dropdown-all', requestParams);
            const data = await response.json();
            this.setState({
                vehiclesDropdownList: data.vehiclesDropdown
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    getVehicleGroupTypeDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/vehicle-group-type-api/vehicle-group-type', requestParams);
            const data = await response.json();
            if (data.isSuccess) {
                this.setState({
                    vehicleGroupTypeDropdownList: data.vehicleGroupTypes
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    async fetchApiData() {
        try {
            await this.getDriverLoginHistoryList();
            this.setState({ loadPanelVisible: true });
            await this.getDriversDropdownList();
            await this.getVehiclesDropdownList();
            await this.getVehicleGroupTypeDropdownList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getDriverLoginHistoryList.bind(this)
            }
        });
    }
    handleSearch = async () => {
        try {
            this.setState({ isFilterSubmitted: true });
            await this.getDriverLoginHistoryList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isFilterSubmitted: false });
    }
    handleFilterClearSearch = (event) => {
        event.preventDefault();
        this.setState({
            isSubmitted: false,
            isFilterSubmitted: false,
            loadPanelVisible: false,
            filterDriverId: 0,
            filterVehicleId: 0,
            filterWorkedInHours: 0,
            filterVehicleGroupTypeIds: [],
            isDriverAllChecked: true,
            isVehicleAllChecked: true,
            isVehicleGroupAllChecked: true,
            isDateAllChecked: false,
        });
    }
    handleFilterDriverOnChange = (selectedOption) => {
        this.setState({ filterDriverId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0) })
    }
    handleFilterVehicleOnChange = (selectedOption) => {
        this.setState({ filterVehicleId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0) })
    }
    handleFilterVehicleGroupTypeOnChange = (selectedOption) => {
       
    }
    handleFilterOnInputChange = (event) => {
        const regExrOnPaxInput = /^\d*(\d)?$/;
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        switch (name) {
            case 'isDriverAllChecked':
                this.setState({ isDriverAllChecked: value, filterDriverId: 0 });
                break;
            case 'isVehicleAllChecked':
                this.setState({ isVehicleAllChecked: value, filterVehicleId: 0 });
                break;
            case 'isVehicleGroupAllChecked':
                this.setState({ isVehicleGroupAllChecked: value, filterVehicleGroupTypeIds: [] });
                break;
            case 'isDateAllChecked':
                this.setState({ isDateAllChecked: value });
                break;
            case 'filterWorkedInHours':
                if (value.trim() === '' || regExrOnPaxInput.test(value)) {
                    if (value.trim().length < 4) {
                        this.setState({ filterWorkedInHours: value });
                    }
                }
                break;
            default:
                this.setState({
                    [name]: value
                });
        }
    }
    handleFilterOnValueChangedFromDate = (event) => {
        const value = moment(event.value).format("MM/DD/YYYY");
        this.setState({ filterFromDatetime: value });
    }
    handleFilterOnValueChangedToDate = (event) => {
        const value = moment(event.value).format("MM/DD/YYYY");
        this.setState({ filterToDatetime: value });
    }
    handleAddLoginHistory = (e) => {
        e.preventDefault();
        this.setState({
            isOpenAddEditModal: true,
            driverLoginHistoryData: null
        });
    }
    handleEditLoginHistory(e, data) {
        e.preventDefault();
        if (data != null) {
            this.setState({
                isOpenAddEditModal: true,
                driverLoginHistoryData: data
            });
        }
    }
    viewLoginLogoutTimeDiff(loginDatetime, logoutDatetime) {
        let addEditDeleteParam = this.state.addEditDeleteParam;
        addEditDeleteParam.timeDiffStrDisplay = '';
        try {
            if (loginDatetime !== null && logoutDatetime !== null) {
                let diffMs = (new Date(Date.parse(logoutDatetime)) - new Date(Date.parse(loginDatetime))); // milliseconds between
                let isValid = Math.sign(parseInt(diffMs));
                if (isValid === 1) {
                    var ms = moment(logoutDatetime, "MM/DD/YYYY HH:mm:ss").diff(moment(loginDatetime, "MM/DD/YYYY HH:mm:ss"));
                    var d = moment.duration(ms);
                    if (d.days() === 0) {
                        if (d.hours() > 0 || d.minutes() > 0) {
                            addEditDeleteParam.timeDiffStrDisplay = d.hours() + ' hours ' + d.minutes() + ' minutes ';
                        }
                    }
                    //let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
                    //let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
                    //if (diffHrs > 0 || diffMins > 0) {
                    //    addEditDeleteParam.timeDiffStrDisplay = diffHrs + ' hours ' + diffMins + ' minutes ';
                    //}
                }
            }
            this.setState({ addEditDeleteParam: addEditDeleteParam });
        } catch (e) {
        }
    }
    handleAssignDataToState = async () => {
        if (this.state.driverLoginHistoryData === null) {
            this.setState({
                addEditDeleteParam: {
                    id: 0,
                    driverId: 0,
                    vehicleId: 0,
                    loginDatetime: new Date(),
                    logoutDatetime: new Date(),
                    loginByUserUserName: '',
                    logoutByUserId: 0,
                    logoutByUserDropdownList: [],
                    timeDiffStrDisplay: '',
                },
            });

        } else {
            const data = this.state.driverLoginHistoryData;
            let addEditDeleteParam = this.state.addEditDeleteParam;
            addEditDeleteParam.id = data.id === null ? 0 : Number(data.id);
            addEditDeleteParam.driverId = data.driverId === null ? 0 : Number(data.driverId);
            addEditDeleteParam.vehicleId = data.vehicleId === null ? 0 : Number(data.vehicleId);
            if (data.loginDateTime !== null) {
                if (data.loginDateTime.trim() !== '') {
                    addEditDeleteParam.loginDatetime = data.loginDateTime === null ? '' : new Date(Date.parse(data.loginDateTime));
                }
            }
            if (data.logoutDateTime !== null) {
                if (data.logoutDateTime.trim() !== '') {
                    addEditDeleteParam.logoutDatetime = data.logoutDateTime === null ? '' : new Date(Date.parse(data.logoutDateTime));
                }
            }
            addEditDeleteParam.loginByUserUserName = data.driverName === null ? '' : data.driverName.trim();
            addEditDeleteParam.logoutByUserId = data.loggedOutByUserId === null ? 0 : Number(data.loggedOutByUserId);
            let logoutByUserList = [];
            let logoutUser = new Object();
            if (parseInt(addEditDeleteParam.driverId) > 0) {
                logoutUser.id = parseInt(addEditDeleteParam.driverId);
                logoutUser.name = addEditDeleteParam.loginByUserUserName;
                logoutByUserList.push(logoutUser);
            }
            logoutUser = new Object();
            logoutUser.id = 0;
            logoutUser.name = 'System';
            logoutByUserList.push(logoutUser);
            addEditDeleteParam.logoutByUserDropdownList = logoutByUserList;
            this.setState({
                addEditDeleteParam: addEditDeleteParam
            });
            if (data.loginDateTime !== null && data.logoutDateTime !== null) {
                if (data.loginDateTime.trim() !== '' && data.logoutDateTime.trim() !== '') {
                    this.viewLoginLogoutTimeDiff(data.loginDateTime, data.logoutDateTime);
                }
            }
        }
    }
    handleResetStateData = async () => {
        this.setState({
            isSubmitted: false,
            isOpenAddEditModal: false,
            driverLoginHistoryData: null,
            addEditDeleteParam: {
                id: 0,
                driverId: 0,
                vehicleId: 0,
                loginDatetime: new Date(),
                logoutDatetime: new Date(),
                loginByUserUserName: '',
                logoutByUserId: 0,
                logoutByUserDropdownList: [],
                timeDiffStrDisplay: '',
            },
            errors: {
                driverName: '',
                vehicleName: '',
                logoutDatetime: '',
                logoutByUserName: '',
            }
        });
    }
    handleDriverOnChange = (selectedOption) => {
        try {
            let errors = this.state.errors;
            errors.driverName = '';
            let addEditDeleteParam = this.state.addEditDeleteParam;
            addEditDeleteParam.driverId = (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0);
            addEditDeleteParam.loginByUserUserName = (selectedOption.selectedItem !== null ? selectedOption.selectedItem.name : '');
            errors.driverName = parseInt(addEditDeleteParam.driverId) === 0 ? 'Please select driver.' : '';
            let logoutByUserList = [];
            let logoutUser = new Object();
            if (parseInt(addEditDeleteParam.driverId) > 0) {
                logoutUser.id = parseInt(addEditDeleteParam.driverId);
                logoutUser.name = addEditDeleteParam.loginByUserUserName;
                logoutByUserList.push(logoutUser);
            }
            logoutUser = new Object();
            logoutUser.id = 0;
            logoutUser.name = 'System';
            logoutByUserList.push(logoutUser);
            addEditDeleteParam.logoutByUserDropdownList = logoutByUserList;
            this.setState({ errors: errors, addEditDeleteParam: addEditDeleteParam })
        } catch (ex) {
            console.log(ex);
        }        
    }
    handleVehicleOnChange = (selectedOption) => {
        try {
            let errors = this.state.errors;
            errors.vehicleName = '';
            let addEditDeleteParam = this.state.addEditDeleteParam;
            addEditDeleteParam.vehicleId = (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0);
            errors.vehicleName = parseInt(addEditDeleteParam.vehicleId) === 0 ? 'Please select vehicle.' : '';
            this.setState({ errors: errors, addEditDeleteParam: addEditDeleteParam })
        } catch (ex) {
            console.log(ex);
        }
    }
    handleLogoutByUserOnChange = (selectedOption) => {
        let addEditDeleteParam = this.state.addEditDeleteParam;
        addEditDeleteParam.logoutByUserId = (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0);
        this.setState({ addEditDeleteParam: addEditDeleteParam })
    }
    handleLoggedInDatetime = (date) => {
        let addEditDeleteParam = this.state.addEditDeleteParam;
        let errors = this.state.errors;
        errors.logoutDatetime = '';
        addEditDeleteParam.loginDatetime = date;
        try {
            this.viewLoginLogoutTimeDiff(date, this.state.addEditDeleteParam.logoutDatetime);
            let diffMs = (new Date(Date.parse(this.state.addEditDeleteParam.logoutDatetime)) - new Date(Date.parse(date))); // milliseconds between
            let isValid = Math.sign(parseInt(diffMs));
            if (isValid === -1) {
                errors.logoutDatetime = 'Logged out time must be greater than logged in time.';
            }
        } catch (e) {
        }
        this.setState({ errors: errors, addEditDeleteParam: addEditDeleteParam });
    }
    handleLoggedOutDatetime = (date) => {
        let addEditDeleteParam = this.state.addEditDeleteParam;
        let errors = this.state.errors;
        errors.logoutDatetime = '';
        addEditDeleteParam.logoutDatetime = date;
        try {
            this.viewLoginLogoutTimeDiff(this.state.addEditDeleteParam.loginDatetime, date);
            let diffMs = (new Date(Date.parse(date)) - new Date(Date.parse(this.state.addEditDeleteParam.loginDatetime))); // milliseconds between
            let isValid = Math.sign(parseInt(diffMs));
            if (isValid === -1) {
                errors.logoutDatetime = 'Logged out time must be greater than logged in time.';
            }
        } catch (e) {
        }
        this.setState({ errors: errors, addEditDeleteParam: addEditDeleteParam });
    }
    handleAddEditLoginHistorySubmit = async event => {
        event.preventDefault();
        try {
            if (this.isFormValid()) {
                this.setState({ isSubmitted: true });
                var request = new Object();
                request.DriverId = Number(this.state.addEditDeleteParam.driverId);
                request.VehicleId = Number(this.state.addEditDeleteParam.vehicleId);
                request.LoginDateTime = formatDate(this.state.addEditDeleteParam.loginDatetime, 'MM/dd/yyyy hh:mm');
                request.LogoutDateTime = formatDate(this.state.addEditDeleteParam.logoutDatetime, 'MM/dd/yyyy hh:mm');
                request.LoggedInByUserId = Number(this.state.addEditDeleteParam.driverId);
                request.LoggedOutByUserId = Number(this.state.addEditDeleteParam.logoutByUserId);
                let requestParams = getRequestParams('POST', request);
                let apiUrl = 'driver-login-history-api';
                if (parseInt(this.state.addEditDeleteParam.id) > 0) {
                    apiUrl = 'driver-login-history-api/' + this.state.addEditDeleteParam.id;
                }
                const response = await fetch(apiUrl, requestParams);
                const data = await response.json();
                await Swal.fire({
                    allowOutsideClick: false,
                    icon: data.isSuccess ? 'success' : 'error',
                    title: data.isSuccess ? 'Success' : 'Oops...',
                    text: (data.isSuccess === false && (data.resultMessage === null || data.resultMessage === "")) ? 'Unable to process the request.' : data.resultMessage,
                }).then(async (result) => {
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            await this.handleResetStateData();
                            await this.getDriverLoginHistoryList();
                        }
                    }
                })
            }
        } catch (ex) {
            console.log(ex);
            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong.',
            })
        }
        this.setState({ isSubmitted: false })
    }
    isFormValid() {
        let formIsValid = true;
        try {
            let isFocus = false;
            let errors = this.state.errors;
            errors.driverName = '';
            errors.vehicleName = '';
            errors.logoutDatetime = '';
            errors.logoutByUserName = '';
            if (this.driverSelectValidator.instance.option('selectedItem') === null || Number(this.state.addEditDeleteParam.driverId) === 0) {
                formIsValid = false;
                errors.driverName = "Please select driver.";
                if (isFocus === false) {
                    this.driverSelectValidator.instance.focus(); isFocus = true;
                }
            }
            if (this.vehicleSelectValidator.instance.option('selectedItem') === null && Number(this.state.addEditDeleteParam.vehicleId) === 0) {
                formIsValid = false;
                errors.vehicleName = "Please select vehicle.";
                if (isFocus === false) { this.vehicleSelectValidator.instance.focus(); isFocus = true; }
            }
            try {
                let diffMs = (new Date(Date.parse(this.state.addEditDeleteParam.logoutDatetime)) - new Date(Date.parse(this.state.addEditDeleteParam.loginDatetime))); // milliseconds between
                let isValid = Math.sign(parseInt(diffMs));
                if (isValid === -1) {
                    formIsValid = false;
                    errors.logoutDatetime = 'Logged out time must be greater than logged in time.';
                }
            } catch (e) {
            }
            if (isFocus === false) { this.submitLoginHistoryButtonValidator.focus(); }
            this.setState({ errors: errors });
        } catch (ex) {
            formIsValid = false;
            console.log(ex);
        }
        return formIsValid;
    }
    handleDeleteLoginHistory = async (e, data) => {
        e.preventDefault();
        try {
            const isConfirm = await this.isConfirm();
            if (!isConfirm) {
                return;
            }
            let id = data.id === null ? 0 : Number(data.id);
            if (id > 0) {
                this.setState({ loadPanelVisible: true });
                let apiUrl = 'driver-login-history-api' + '/' + id;
                const requestParams = getRequestParams('DELETE');
                const response = await fetch(apiUrl, requestParams);
                const responseData = await response.json();
                this.setState({ loadPanelVisible: false });
                let message = responseData.isSuccess ? (responseData.resultMessage === '' || responseData.resultMessage === null ? 'History deleted successfully...! ' : responseData.resultMessage) : (responseData.resultMessage === '' || responseData.resultMessage === null ? 'Unable to delete history.' : responseData.resultMessage);
                await Swal.fire({
                    allowOutsideClick: false,
                    icon: responseData.isSuccess ? 'success' : 'error',
                    title: responseData.isSuccess ? 'Success' : 'Oops...',
                    text: message,
                }).then(async (result) => {
                    if (responseData.isSuccess) {
                        if (result.isConfirmed) {
                            await this.getDriverLoginHistoryList();
                        }
                    }
                })
            }
            else {
                Swal.fire({
                    allowOutsideClick: false,
                    showCloseButton: true,
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Any data that has a problem is invalid!',
                })
            }
        } catch (ex) {
            console.log(ex);
            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            })
        }
    }
    isConfirm = async () => {
        let isResult = false;
        await Swal.fire({
            title: '<small><b>Confirmation</b></small>',
            html: '<small>Are you sure want to delete the history?</small>',
            icon: 'question',
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }
    render() {
        const renderGridCell = (data) => {
            return <div>
                <a href="#" title="Edit History" onClick={(e) => { this.handleEditLoginHistory(e, data.data) }} ><i className="fas fa-pen"></i></a>&nbsp;&nbsp;
                <a href="#" title="Delete History" onClick={(e) => { this.handleDeleteLoginHistory(e, data.data) }}><i className="fas fa-times"></i></a>&nbsp;&nbsp;
            </div>;
        }
        const loggedInDatetime = time => {
            const currentDate = new Date();
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }
        const loggedOutDatetime = time => {
            const currentDate = new Date();
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs4">
                                <h1 className="m-0 text-dark">Driver Login History</h1>
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem><NavLink tag={Link} to="#" className="btn btn-block btn-outline-secondary btn-sm" onClick={this.handleAddLoginHistory} title="Add Driver Login History"><i className="fas fa-plus"></i> Add Driver Login History</NavLink></NavItem>
                                    <NavItem><NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="#" onClick={this.getDriverLoginHistoryList} title="Refresh"><i className="fas fa-sync-alt"></i> Refresh</NavLink></NavItem>
                                    <NavItem><NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="/loggeddrivers/list" title="Dispatch"><i className="fas fa-check"></i> Logged Drivers</NavLink></NavItem>
                                    <NavItem><NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="/driver/work/history" title="Dispatch"><i className="fas fa-check"></i> Driver's Hours</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rez-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                <div className="form-group">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="isDriverAllChecked" name="isDriverAllChecked" checked={this.state.isDriverAllChecked} onChange={this.handleFilterOnInputChange} />
                                        <label className="form-check-label" htmlFor="isDriverAllChecked"> All Driver</label>
                                    </div>
                                    <SelectBox
                                        dataSource={this.state.driversDropdownList.length > 0 ? this.state.driversDropdownList : []}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select driver"
                                        name="id"
                                        defaultValue={0}
                                        value={this.state.filterDriverId}
                                        displayExpr='name'
                                        valueExpr='id'
                                        onSelectionChanged={this.handleFilterDriverOnChange}
                                        showClearButton={true}
                                        disabled={this.state.isDriverAllChecked}
                                        searchEnabled={true}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                <div className="form-group">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="isVehicleAllChecked" name="isVehicleAllChecked" checked={this.state.isVehicleAllChecked} onChange={this.handleFilterOnInputChange} />
                                        <label className="form-check-label" htmlFor="isVehicleAllChecked"> All Vehicle</label>
                                    </div>
                                    <SelectBox
                                        dataSource={this.state.vehiclesDropdownList.length > 0 ? this.state.vehiclesDropdownList : []}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select vehicle"
                                        name="id"
                                        defaultValue={0}
                                        value={this.state.filterVehicleId}
                                        displayExpr='name'
                                        valueExpr='id'
                                        onSelectionChanged={this.handleFilterVehicleOnChange}
                                        showClearButton={true}
                                        disabled={this.state.isVehicleAllChecked}
                                        searchEnabled={true}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                <div className="form-group">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="isVehicleGroupAllChecked" name="isVehicleGroupAllChecked" checked={this.state.isVehicleGroupAllChecked} onChange={this.handleFilterOnInputChange} />
                                        <label className="form-check-label" htmlFor="isVehicleGroupAllChecked"> All Fleet</label>
                                    </div>
                                    <DropDownBox
                                        value={this.state.filterVehicleGroupTypeIds}
                                        valueExpr="id"
                                        deferRendering={false}
                                        displayExpr="name"
                                        placeholder="Select fleet..."
                                        showClearButton={true}
                                        dataSource={this.state.vehicleGroupTypeDropdownList}
                                        onValueChanged={this.syncDataGridFleetSelection}
                                        contentRender={this.dataGridFleetRender}
                                        disabled={this.state.isVehicleGroupAllChecked}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <div className="form-group">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="isDateAllChecked" name="isDateAllChecked" checked={this.state.isDateAllChecked} onChange={this.handleFilterOnInputChange} />
                                        <label className="form-check-label" htmlFor="isDateAllChecked"> All Date <i className="txt-xs">(If not select FROM and TO date)</i></label>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <DateBox type="date" className="form-control form-control-sm input-date" id="fromDate" name="fromDate" placeholder="MM/dd/yyyy"
                                                    defaultValue={this.state.filterFromDatetime}
                                                    onValueChanged={this.handleFilterOnValueChangedFromDate}
                                                    disabled={this.state.isDateAllChecked}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <DateBox type="date" className="form-control form-control-sm input-date" id="toDate" name="toDate" placeholder="MM/dd/yyyy"
                                                    defaultValue={this.state.filterToDatetime}
                                                    min={this.state.filterFromDatetime}
                                                    onValueChanged={this.handleFilterOnValueChangedToDate}
                                                    disabled={this.state.isDateAllChecked}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                <div className="form-group">
                                    <label>Worked more than hours</label>
                                    <input type="text" name="filterWorkedInHours" className="form-control form-control-sm" value={this.state.filterWorkedInHours} onChange={this.handleFilterOnInputChange} />
                                </div>
                            </div>
                            <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                                <div className="form-group">
                                    <label className="btn-block">&nbsp;</label>
                                    <button className="btn btn-success btn-sm" onClick={this.handleSearch}>
                                        {this.state.isFilterSubmitted && <span><i className="fas fa-spinner fa-spin"></i></span>}
                                        {!this.state.isFilterSubmitted && <span><i className="fas fa-search"></i></span>}
                                    </button>&nbsp;&nbsp;
                                <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleFilterClearSearch}><i className="fas fa-times"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                <div className="content">
                    <div className="card card-grid">
                        <div className="card-body">
                            <DataGrid
                                dataSource={this.state.driverLoinHistoryGridDataSource}
                                keyExpr="id"
                                selection={{ mode: 'single' }}
                                columnsAutoWidth={false}
                                showColumnLines={true}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                onToolbarPreparing={this.handleToolbarPreparing}
                            >
                                <Column dataField="id" width={45} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                <Column dataField="driverName" caption="Driver Name" />
                                <Column dataField="driverUserName" caption="Login Id" />
                                <Column dataField="loginDateTime" caption="Login Datetime" />
                                <Column dataField="logoutDateTime" caption="Logout Datetime" />
                                <Column dataField="vehicleNo" caption="Vehicle" />
                                <Column dataField="loggedInByUserName" caption="Logged In By" />
                                <Column dataField="loggedOutByUserName" caption="Logged Out By" />
                                <Column dataField="workHours" caption="Work Hours" />
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                <Export enabled={true} allowExportSelectedData={false} />
                                <ColumnChooser enabled={true} />
                                <ColumnFixing enabled={true} />
                            </DataGrid>
                        </div>
                    </div>
                </div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenAddEditModal}
                    style={{ content: { width: '45%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleAssignDataToState}
                    onRequestClose={this.handleResetStateData}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleAddEditLoginHistorySubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{this.state.id > 0 ? "Modify Login History" : "Add Login History"}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleResetStateData}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Driver <span className="text-danger">*</span></label>
                                        <SelectBox
                                            ref={select => this.driverSelectValidator = select}
                                            dataSource={this.state.driversDropdownList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select driver"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.addEditDeleteParam.driverId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleDriverOnChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["driverName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["driverName"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Vehicle <span className="text-danger">*</span></label>
                                        <SelectBox
                                            ref={select => this.vehicleSelectValidator = select}
                                            dataSource={this.state.vehiclesDropdownList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select vehicle"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.addEditDeleteParam.vehicleId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleVehicleOnChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["vehicleName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["vehicleName"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Logged In Date Time <span className="text-danger">*</span></label>
                                        <DatePicker
                                            className="form-control form-control-sm "
                                            placeholderText="Select logged-in time"
                                            name="loginDatetime"
                                            showTimeInput
                                            selected={this.state.addEditDeleteParam.loginDatetime}
                                            dateFormat={DateFormat.DateTime24Format}
                                            onChange={this.handleLoggedInDatetime}
                                            filterTime={loggedInDatetime}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Logged Out Date Time <span className="text-danger">*</span></label>
                                        <DatePicker
                                            className="form-control form-control-sm "
                                            placeholderText="Select logged-out time"
                                            name="logoutDatetime"
                                            showTimeInput
                                            selected={this.state.addEditDeleteParam.logoutDatetime}
                                            dateFormat={DateFormat.DateTime24Format}
                                            onChange={this.handleLoggedOutDatetime}
                                            filterTime={loggedOutDatetime}
                                            startDate={this.state.addEditDeleteParam.loginDatetime}
                                            minDate={this.state.addEditDeleteParam.loginDatetime}

                                        />
                                        {this.state.errors["logoutDatetime"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["logoutDatetime"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Logged In By </label>
                                        <input type="text" className="form-control form-textbox form-control-sm" readOnly={true} value={this.state.addEditDeleteParam.loginByUserUserName} />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Logged Out By <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.addEditDeleteParam.logoutByUserDropdownList.length > 0 ? this.state.addEditDeleteParam.logoutByUserDropdownList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select System"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.addEditDeleteParam.logoutByUserDropdownList.length > 0 ? this.state.addEditDeleteParam.logoutByUserDropdownList[0].id : []}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleLogoutByUserOnChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["logoutByUserName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["logoutByUserName"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ display: this.state.addEditDeleteParam.timeDiffStrDisplay.length > 0 || 'none' }}>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Work Hours <b><span className="text-danger" style={{ fontSize: '15px' }}>&nbsp;&nbsp;{this.state.addEditDeleteParam.timeDiffStrDisplay}</span></b></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success btn-sm" ref={button => this.submitLoginHistoryButtonValidator = button}>
                                {this.state.isSubmitted && <span><i className="fas fa-spinner fa-spin"></i> In Process</span>}
                                {!this.state.isSubmitted && <span><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleResetStateData}><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >

        );
    }

    onSelectionFleetChanged(e) {
        this.setState({
            filterVehicleGroupTypeIds: e.selectedRowKeys.length && e.selectedRowKeys || []
        });
    }
    syncDataGridFleetSelection(e) {
        this.setState({
            filterVehicleGroupTypeIds: e.value || []
        });
    }

    dataGridFleetRender() {
        return (
            <DataGrid
                dataSource={this.state.vehicleGroupTypeDropdownList}
                id="grid-container"
                keyExpr="id"
                hoverStateEnabled={true}
                selectedRowKeys={this.state.filterVehicleGroupTypeIds}
                onSelectionChanged={this.onSelectionFleetChanged}
                ref={ref => this.dataGrid = ref}>
                <Column dataField="name" caption="Fleet Name" />

                <Selection mode="multiple" />
                <Scrolling mode="virtual" />
                <Paging enabled={true} pageSize={10} />
                <FilterRow visible={true} />


            </DataGrid>
        );
    }
}