import React, { Component } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
}
    from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';//npm install --save react-tabs
import 'react-tabs/style/react-tabs.css';
import Modal from 'react-modal'; //npm install --save react-modal
import { getRequestParams, formatDate } from '../../helpers/globalfunctions';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
const customStyles = {
    content: {
        width: '30%',
    }
};

let feedbackstatusobject = [
    { id: 2, name: "In Progress" },
    { id: 3, name: "Hotel Updated" },
    { id: 4, name: "Resolved" },
    { id: 5, name: "Canceled" },
]

export class CannedMessageComponent extends Component {
    static displayName = CannedMessageComponent.name;

    constructor(props) {
        super(props);

        this.state = {
            isSubmited: false,
            isCannedMessageModalOpen: false,
            cannedMessageData: null,
            allCannedMessageGridDataSource: null,
            dispatcherCannedMessageGridDataSource: null,
            driverCannedMessageGridDataSource: null,
            cannedMessageId: 0,
            cannedMessageTypeId: 0,
            cannedMessage: '',
            isActive: false,
            errors: {
                cannedMessage: '',
            }
        }

        this.getCannedMessageList = this.getCannedMessageList.bind(this);
    }
    componentDidMount() {
        this.getCannedMessageList();
    }
    async getCannedMessageList() {
        const requestParams = getRequestParams('GET', "{}");
        const response = await fetch('/canned-message/0/0/', requestParams);
        const data = await response.json();
        this.setState({
            allCannedMessageGridDataSource: data.cannedMessages,
            driverCannedMessageGridDataSource: data.cannedMessages.filter(item => item.sourceMessageId === 1),
            dispatcherCannedMessageGridDataSource: data.cannedMessages.filter(item => item.sourceMessageId === 2),
        });
    }
    handleOnToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getCannedMessageList.bind(this)
            }
        });
    }
    handleOnAddNewCannedMessage(e) {
        e.preventDefault();
        this.setState({
            isCannedMessageModalOpen: true,
            cannedMessageTypeId: 2,
            cannedMessageData: null
        });
    }
    handleOnEditCannedMessage(e, data) {
        e.preventDefault();
        this.setState({
            isCannedMessageModalOpen: true,
            cannedMessageData: data
        });
    }
    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let errors = this.state.errors;
        switch (name) {
            case 'cannedMessage':
                errors.cannedMessage = "";
                if (this.cannedMessageInputValidator.value.trim().length === 0) {
                    errors.cannedMessage = 'Please enter canned message.';
                }
                this.setState({ cannedMessage: value });
                break;
            default:
                this.setState({
                    [name]: value
                });
        }
        this.setState({ errors: errors });
    }
    handleAssignDataToState = () => {
        if (this.state.cannedMessageData === null) {
            this.setState({
                cannedMessageId: 0,                
                cannedMessage: '',
                isActive: false,
            });
        } else {
            const message = this.state.cannedMessageData;
            this.setState({
                cannedMessageId: message.id === null ? 0 : Number(message.id),
                cannedMessageTypeId: message.sourceMessageId === null ? 0 : Number(message.sourceMessageId),
                cannedMessage: message.message === null ? '' : message.message.trim(),
                isActive: message.isActive || false,
            });
        }
    }
    handleResetDataToState = () => {
        this.setState({
            isSubmited: false,
            isCannedMessageModalOpen: false,
            cannedMessageData: null,
            cannedMessageId: 0,
            cannedMessageTypeId: 0,
            cannedMessage: '',
            isActive: false,
            errors: {
                cannedMessage: '',
            }
        });
    }
    handleCannedMessageSubmit = async event => {
        event.preventDefault();
        if (this.handleValidation()) {
            this.setState({ isSubmited: true });
            var addEditCannedMessageRequest = new Object();
            addEditCannedMessageRequest.Id = Number(this.state.cannedMessageId);
            addEditCannedMessageRequest.SourceMessageId = Number(this.state.cannedMessageTypeId);
            addEditCannedMessageRequest.CannedMessage = this.state.cannedMessage.trim();
            addEditCannedMessageRequest.Enable = this.state.cannedMessageId > 0 ? this.state.isActive : true;
            const requestParams = getRequestParams('POST', addEditCannedMessageRequest);
            const response = await fetch('/canned-message/', requestParams);
            const data = await response.json();

            try {
                Swal.fire({
                    icon: data.isSuccess ? 'success' : 'error',
                    title: data.isSuccess ? 'Canned Message' : 'Oops...',
                    text: data.resultMessage,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            this.setState({ isSubmited: false, isCannedMessageModalOpen: false });
                            this.getCannedMessageList();
                        }
                    } else {
                        this.setState({ isSubmited: false })
                    }
                });
            } catch (e) {
                this.setState({ isSubmited: false })
            }
        }
    }
    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        let isFocus = false;
        errors.cannedMessage = "";

        if (this.state.cannedMessage.trim() === '') {
            formIsValid = false;
            errors.cannedMessage = "Please enter canned message.";
            if (isFocus === false) { this.cannedMessageInputValidator.focus(); isFocus = true; }
        }
        if (isFocus === false) { this.submitCannedMessageValidator.focus(); }
        this.setState({ errors: errors });
        return formIsValid;
    }
    render() {
        const renderGridCell = (data) => {
            return <div>
                <a href="#" className="fas fa-pen icon text-info" title="Edit" onClick={(e) => this.handleOnEditCannedMessage(e, data.data)}></a>
            </div>;
        }
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <h1 className="m-0 text-dark">Canned Message</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem><NavLink tag={Link} to="#" className="btn btn-block btn-outline-secondary btn-sm" onClick={(e) => this.handleOnAddNewCannedMessage(e, null)} title="Add Canned Message"><i className="fas fa-plus"></i> Add Canned Message</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="card mt-2">
                        <div className="card-body">
                            <ul className="nav nav-tabs" id="custom-content-below-tab" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="all-message-tab" data-toggle="pill" href="#all-message" role="tab" aria-controls="all-message" aria-selected="true">All Message</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="dispatcher-message-tab" data-toggle="pill" href="#dispatcher-message" role="tab" aria-controls="dispatcher-message" aria-selected="false">Dispatcher Message</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="driver-message-tab" data-toggle="pill" href="#driver-message" role="tab" aria-controls="driver-message" aria-selected="false">Driver Message</a>
                                </li>
                            </ul>
                            <div className="tab-content" id="custom-content-below-tabContent">
                                <div className="tab-pane fade show active" id="all-message" role="tabpanel" aria-labelledby="all-message-tab">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <DataGrid
                                                dataSource={this.state.allCannedMessageGridDataSource}
                                                keyExpr="id"
                                                selection={{ mode: 'single' }}
                                                columnsAutoWidth={false}
                                                showColumnLines={false}
                                                showRowLines={true}
                                                showBorders={true}
                                                rowAlternationEnabled={true}
                                                showBorders={true}
                                                allowColumnResizing={true}
                                                columnResizingMode='widget'
                                                onToolbarPreparing={this.handleOnToolbarPreparing}>
                                                <Column dataField="message" caption="Canned Message" allowFiltering={false} allowSorting={false} />
                                                <Column dataField="sourceMessage" caption="Message Type" allowFiltering={false} allowSorting={false} />
                                                <Column dataField="activeStatus" caption="Status"  allowFiltering={false} allowSorting={false} />
                                                <SortByGroupSummaryInfo summaryItem="count" />
                                                <GroupPanel visible={true} />
                                                <HeaderFilter visible={true} />
                                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                                <Grouping autoExpandAll={true} />
                                                <Paging defaultPageSize={20} />
                                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                                <Export enabled={true} allowExportSelectedData={false} />
                                                <ColumnChooser enabled={true} />
                                                <ColumnFixing enabled={true} />
                                            </DataGrid>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="dispatcher-message" role="tabpanel" aria-labelledby="dispatcher-message-tab">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <DataGrid
                                                dataSource={this.state.dispatcherCannedMessageGridDataSource}
                                                keyExpr="id"
                                                selection={{ mode: 'single' }}
                                                columnsAutoWidth={true}
                                                showColumnLines={false}
                                                showRowLines={true}
                                                showBorders={true}
                                                rowAlternationEnabled={true}
                                                showBorders={true}
                                                allowColumnResizing={true}
                                                columnResizingMode='widget'
                                                onToolbarPreparing={this.handleOnToolbarPreparing}>
                                                <Column dataField="id" caption="" allowFiltering={false} width={30} allowSorting={false} cellRender={renderGridCell} />
                                                <Column dataField="message" caption="Canned Message" allowFiltering={false} allowSorting={false} />
                                                <Column dataField="activeStatus" caption="Status" allowFiltering={false} allowSorting={false} />
                                                <SortByGroupSummaryInfo summaryItem="count" />
                                                <GroupPanel visible={true} />
                                                <HeaderFilter visible={true} />
                                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                                <Grouping autoExpandAll={true} />
                                                <Paging defaultPageSize={20} />
                                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                                <Export enabled={true} allowExportSelectedData={false} />
                                                <ColumnChooser enabled={true} />
                                                <ColumnFixing enabled={true} />

                                            </DataGrid>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="driver-message" role="tabpanel" aria-labelledby="driver-message-tab">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <DataGrid
                                                dataSource={this.state.driverCannedMessageGridDataSource}
                                                keyExpr="id"
                                                selection={{ mode: 'single' }}
                                                columnsAutoWidth={true}
                                                showColumnLines={false}
                                                showRowLines={true}
                                                showBorders={true}
                                                rowAlternationEnabled={true}
                                                showBorders={true}
                                                allowColumnResizing={true}
                                                columnResizingMode='widget'
                                                onToolbarPreparing={this.handleOnToolbarPreparing}>
                                                <Column dataField="id" caption="" allowFiltering={false} width={30} allowSorting={false} cellRender={renderGridCell} />
                                                <Column dataField="message" caption="Canned Message" allowFiltering={false} allowSorting={false} />
                                                <Column dataField="activeStatus" caption="Status" allowFiltering={false} allowSorting={false} />
                                                <SortByGroupSummaryInfo summaryItem="count" />
                                                <GroupPanel visible={true} />
                                                <HeaderFilter visible={true} />
                                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                                <Grouping autoExpandAll={true} />
                                                <Paging defaultPageSize={20} />
                                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                                <Export enabled={true} allowExportSelectedData={false} />
                                                <ColumnChooser enabled={true} />
                                                <ColumnFixing enabled={true} />
                                            </DataGrid>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isCannedMessageModalOpen}
                    style={customStyles}
                    className={"react-modal"}
                    onAfterOpen={this.handleAssignDataToState}
                    onRequestClose={this.handleResetDataToState}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleCannedMessageSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{Number(this.state.cannedMessageId) > 0 ? "Modify Canned Message" : "Add Canned Message"}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleResetDataToState}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group form-control-sm">
                                        <input type="radio" name="cannedMessageType"checked={this.state.cannedMessageTypeId === 2} onChange={(e) => { this.setState({ cannedMessageTypeId: 2 }) }} />&nbsp;Dispatcher
                                        &nbsp;&nbsp;<input type="radio" name="cannedMessageType" checked={this.state.cannedMessageTypeId === 1} onChange={(e) => { this.setState({ cannedMessageTypeId: 1 }) }} />&nbsp;Driver
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group">
                                        <label>Canned Message <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="cannedMessage" placeholder="Type canned message" maxLength="100" title="Enter Canned Message" ref={input => this.cannedMessageInputValidator = input} value={this.state.cannedMessage} onChange={this.handleInputChange} />
                                        {this.state.errors["cannedMessage"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["cannedMessage"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{ display: this.state.cannedMessageId > 0 ? 'block' : 'none' }}>
                                    <div className="form-group">
                                        <label><span className="text-danger">&nbsp;</span></label>
                                        <input type="checkbox" id="isActive" name="isActive" checked={this.state.isActive} onChange={this.handleInputChange} />                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success btn-sm" ref={button => this.submitCannedMessageValidator = button}>
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleResetDataToState} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
            </div>
        );
    }
}