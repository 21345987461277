import React, { Component } from 'react';
import { SelectBox } from 'devextreme-react/select-box';
import DayPicker, { DateUtils } from 'react-day-picker'; //npm install react-day-picker --save
import DatePicker from "react-datepicker"; // npm install react - datepicker --save
import DateBox from 'devextreme-react/date-box';
import Autosuggest from 'react-autosuggest'; //npm install react-autosuggest --save

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import NumberFormat from 'react-number-format';
import NumericInput from 'react-numeric-input'; //npm install react-numeric-input --save
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { DuplicateReservationComponent } from '../Shared/DuplicateReservationComponent';
import ReactTooltip from "react-tooltip"; //npm install react - tooltip
import { LoadPanel } from 'devextreme-react/load-panel';
import { VehicleDriverComponent } from './VehicleDriverComponent';
import "react-datepicker/dist/react-datepicker.css";
import 'react-day-picker/lib/style.css';
import { HoursData } from '../../datastore/hours.json';
import { MinutesData } from '../../datastore/minutes.json';

const MySwal = withReactContent(Swal)
const google = window.google;
const position = { of: '#bookingdiv' };

const terminalTypeData = [
    {
        "id": 1,
        "name": "Domestic",
    },
    {
        "id": 2,
        "name": "International",
    },
    {
        "id": 3,
        "name": "Hangar",
    },
    {
        "id": 4,
        "name": "Military",
    },
    {
        "id": 5,
        "name": "Regional",
    },
    {
        "id": 6,
        "name": "Unknown",
    },
]

function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
function renderSuggestion(suggestion) {
    if (suggestion.isNotFound) {
        return (
            <span>
                Data not found!
            </span>
        );
    }
    return (
        <span>{suggestion.name}</span>
    );
}
function shouldRenderSuggestions(value, reason) {
    return value.trim().length > 2;
}
let rideTypeId = 0;
let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
let currentHour = 0;
let currentMinute = 0;

export class ContractTransferComponent extends Component {
    static displayName = ContractTransferComponent.name;

    constructor(props) {
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        currentHour = new Date(currentTime).getHours();
        currentMinute = new Date(currentTime).getMinutes();

        super(props);
        this.state = {
            rideId: this.props.tripDetails == null ? 0 : this.props.tripDetails.rideId,
            customer: props.customer,
            paxFirstName: '',
            paxLastName: '',
            countryCodePhone: '1',
            phoneNumber: '',
            countryCode: '1',
            mobileNumber: '',
            email: '',
            isSendEmail: false,
            poNumber: '',
            bookingAddress: [],

            dropdownZone: [],
            isPickupGoogleBooking: false,
            pickupSuggestions: [],
            pickupLocation: "",
            pickupZoneId: 0,
            pickupAddress: "",
            pickupAddressType: "",
            pickupCity: "",
            pickupState: "",
            pickupZipCode: "",
            pickupLatitude: 0,
            pickupLongitude: 0,
            apt: '',
            isDropoffGoogleBooking: false,
            dropoffSuggestions: [],
            dropoffLocation: "",
            dropoffZoneId: 0,
            dropoffAddress: "",
            dropoffAddressType: "",
            dropoffCity: "",
            dropoffState: "",
            dropoffZipCode: "",
            dropoffLatitude: 0,
            dropoffLongitude: 0,
            serviceSourceId: 1,
            dropdownAffiliates: [],
            otherAffiliateId: 0,
            dropdownHours: HoursData,
            dropdownMinutes: MinutesData,

            dropdownAirline: [],
            airlineId: this.props.customer.airlineId,
            flightNumber: '',
            terminalType: 1,
            terminal: '',

            flightTime: new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate(), currentHour, currentMinute),
            flightHour: currentHour,
            flightMinute: currentMinute,

            airlineIdReturn: this.props.customer.airlineId,
            flightNumberReturn: '',
            terminalTypeReturn: 0,
            terminalReturn: '',
            flightTimeReturn: new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate(), currentHour, currentMinute),
            flightHourReturn: currentHour,
            flightMinuteReturn: currentMinute,

            orderByName: '',
            countryCodeOrderPhone: '',
            phoneNumberOrder: '',
            emailOrder: '',
            dropdownReservationMode: [],
            reservationModeId: 0,
            puDateTime: currentTime,
            puDateTimeReturn: currentTime,

            doDateTime: currentTime,
            tripDuration: 0,
            dropdownVehicleType: [],
            vehicleTypeId: 0,
            noOfPax: 1,
            noOfPaxReturn: 1,
            luggage: 0,
            noOfTrip: 1,
            isAccessible: false,
            isVipTrip: false,
            isPriorityTrip: false,
            isMeetGreet: false,
            tripTypeId: "1",
            recurringTypeId: 2,
            recurringEndDate: "",
            selectedDays: [],
            isWeekSun: false,
            isWeekMon: false,
            isWeekTue: false,
            isWeekWed: false,
            isWeekThu: false,
            isWeekFri: false,
            isWeekSat: false,
            isMonthSun: false,
            isMonthMon: false,
            isMonthTue: false,
            isMonthWed: false,
            isMonthThu: false,
            isMonthFri: false,
            isMonthSat: false,
            specialInstruction: "",
            permanentRemark: "",
            pickupNotes: "",
            billToNotes: "",
            customerRateTax: null,
            loadPanelVisible: false,
            isCopy: false,
            isAttachment: false,

            errors: {
                paxFirstName: "",
                paxLastName: "",
                countryCode: "",
                mobileNumber: "",
                email: "",
                pickupLocation: "",
                pickupZoneId: "",
                dropoffLocation: "",
                dropoffZoneId: "",
                otherAffiliateId: "",
                puDateTime: "",
                puDateTimeReturn: "",
                doDateTime: "",
                flightTime: "",
                flightNumber: "",
                terminalType: "",
                flightNumberReturn: "",
                terminalTypeReturn: "",
                flightTimeReturn: "",
                orderByName: "",
                reservationModeId: "",
                vehicleTypeId: "",
                noOfTrip: "",
                noOfPax: "",
                noOfPaxReturn: "",
                recurringEndDate: "",
                recurring: "",
            }
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handlePickupZoneChange = this.handlePickupZoneChange.bind(this);
        this.handleDropoffZoneChange = this.handleDropoffZoneChange.bind(this);
        this.handleAirlineChange = this.handleAirlineChange.bind(this);
        this.handleAirlineReturnChange = this.handleAirlineReturnChange.bind(this);
        this.handleVehicleTypeChange = this.handleVehicleTypeChange.bind(this);
        this.handleAffiliateChange = this.handleAffiliateChange.bind(this);
        this.handleReservationModeChange = this.handleReservationModeChange.bind(this);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.handlePaxCountChange = this.handlePaxCountChange.bind(this);
        this.handleTripCountChange = this.handleTripCountChange.bind(this);
        this.handlePaxCountReturnChange = this.handlePaxCountReturnChange.bind(this);
        this.handleTripTypeChange = this.handleTripTypeChange.bind(this);

        this.loadPage = this.loadPage.bind(this);
        this.getAirlines = this.getAirlines.bind(this);
        this.getVehicleType = this.getVehicleType.bind(this);
        this.getAffiliates = this.getAffiliates.bind(this);
        this.getReservationModes = this.getReservationModes.bind(this);
        this.getBookingSuggestions = this.getBookingSuggestions.bind(this);
        this.getZones = this.getZones.bind(this);
        this.getPickupSuggestionValue = this.getPickupSuggestionValue.bind(this);
        this.onChangePickupLocation = this.onChangePickupLocation.bind(this);
        this.onBlurPickupLocation = this.onBlurPickupLocation.bind(this);
        this.getDropoffSuggestionValue = this.getDropoffSuggestionValue.bind(this);
        this.getRideTypeId = this.getRideTypeId.bind(this);

        this.handleValidation = this.handleValidation.bind(this);
        this.contractTransferSubmit = this.contractTransferSubmit.bind(this);

        this.handleRecurringEndDateChange = this.handleRecurringEndDateChange.bind(this);
        this.handleFlightDateTimeChange = this.handleFlightDateTimeChange.bind(this);
        this.handleFlightHourChange = this.handleFlightHourChange.bind(this);
        this.handleFlightMinuteChange = this.handleFlightMinuteChange.bind(this);
        this.handleFlightHourReturnChange = this.handleFlightHourReturnChange.bind(this);
        this.handleFlightMinuteReturnChange = this.handleFlightMinuteReturnChange.bind(this);
        this.handleFlightDateTimeReturnChange = this.handleFlightDateTimeReturnChange.bind(this);
        this.handlePickupDateTimeChange = this.handlePickupDateTimeChange.bind(this);
        this.handlePickupDateTimeReturnChange = this.handlePickupDateTimeReturnChange.bind(this);
        this.handleMeetGreetChange = this.handleMeetGreetChange.bind(this);

        this.handlePlaceSearch = this.handlePlaceSearch.bind(this);
        this.handlePickupPlaceSelect = this.handlePickupPlaceSelect.bind(this);
        this.handlePickupToggleChange = this.handlePickupToggleChange.bind(this);

        this.handleDropoffPlaceSelect = this.handleDropoffPlaceSelect.bind(this);
        this.handleDropoffToggleChange = this.handleDropoffToggleChange.bind(this);

        this.getCustomerRateTaxSetting = this.getCustomerRateTaxSetting.bind(this);
        this.getDaysOfMonthlyRec = this.getDaysOfMonthlyRec.bind(this);
        this.getDaysOfWeeklyRec = this.getDaysOfWeeklyRec.bind(this);
        this.getDaysOfDailyRec = this.getDaysOfDailyRec.bind(this);
        this.getMonthlyRecurringDays = this.getMonthlyRecurringDays.bind(this);
        this.getWeeklyRecurringDays = this.getWeeklyRecurringDays.bind(this);
        this.getDailyRecurringDays = this.getDailyRecurringDays.bind(this);
        this.diffDays = this.diffDays.bind(this);
        this.getRecurringPuDateArrayList = this.getRecurringPuDateArrayList.bind(this);

        this.checkDuplicateReservation = this.checkDuplicateReservation.bind(this);
        this.bookReservation = this.bookReservation.bind(this);
        this.clearAllControls = this.clearAllControls.bind(this);

        this.getTripChangesDetails = this.getTripChangesDetails.bind(this);
        this.copyReservation = this.copyReservation.bind(this);
    };

    async getBookingSuggestions(value) {
        const escapedValue = escapeRegexCharacters(value.trim());

        if (escapedValue === '') {
            return [];
        }

        const requestParams = getRequestParams('GET', '');
        const response = await fetch('address-search-api/' + this.state.customer.id + "/" + this.state.customer.customerTypeId + "/" + value, requestParams);
        const data = await response.json();

        this.setState({ bookingAddress: data === false ? [] : data.addressSearchList })

        const regex = new RegExp('^' + escapedValue, 'i');

        //const suggestions = (this.state.bookingAddress.length > 0) ? this.state.bookingAddress.filter(bookingAddress => regex.test(bookingAddress.name)) : [];
        const suggestions = (data === false) ? [] : data.addressSearchList;
        if (suggestions.length === 0) {
            return [
                { isNotFound: true }
            ];
        }

        return suggestions;
    }

    onChangePickupLocation = (event, { newValue }) => {
        this.setState({
            pickupLocation: newValue
        });
        this.state.errors["pickupLocation"] = "";
    };

    onBlurPickupLocation = (event, { value }) => {
        const index2 = (this.state.bookingAddress.length > 0) ? this.state.bookingAddress.map(item => item.name).indexOf(this.state.pickupLocation) : -1;
        if (index2 === -1) {
            this.setState({
                pickupLocation: "",
                pickupAddress: "",
                pickupAddressType: "",
                pickupCity: "",
                pickupState: "",
                pickupZipCode: "",
                pickupZoneId: 0,
                pickupLatitude: 0,
                pickupLongitude: 0
            });
            this.getRideTypeId(0, this.state.dropoffZoneId);
        }
        this.state.errors["pickupLocation"] = "";
    };

    getPickupSuggestionValue(suggestion) {

        if (suggestion.isNotFound) {
            return "";
        }

        this.setState({
            pickupLocation: suggestion.name,
            pickupAddress: suggestion.address,
            pickupAddressType: suggestion.addressType,
            pickupCity: suggestion.city,
            pickupState: suggestion.state,
            pickupZipCode: suggestion.zipCode,
            pickupZoneId: suggestion.zoneId,
            pickupLatitude: suggestion.latitude,
            pickupLongitude: suggestion.longitude
        });
        if (this.state.dropdownZone.findIndex(opt => opt.id == suggestion.zoneId) < 0) {
            this.setState({
                pickupZoneId: 0,
            });
        } else {
            this.getRideTypeId(suggestion.zoneId, this.state.dropoffZoneId);
            this.getCustomerRateTaxSetting(suggestion.id, this.state.dropoffZoneId, this.state.puDateTime, this.state.vehicleTypeId, parseInt(this.state.noOfPax | 0), 0, this.state.isMeetGreet);
            this.state.errors["pickupZoneId"] = "";
        }

        this.state.errors["pickupLocation"] = "";
        return suggestion.name;
    }

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onPickupSuggestionsFetchRequested = ({ value }) => {
        this.getBookingSuggestions(value).then(data => this.setState({
            pickupSuggestions: data
        }));
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onPickupSuggestionsClearRequested = () => {
        this.setState({
            pickupSuggestions: []
        });
        this.state.errors["pickupLocation"] = "";
    };

    onChangeDropoffLocation = (event, { newValue }) => {
        this.setState({
            dropoffLocation: newValue
        });
        this.state.errors["dropoffLocation"] = "";
    };

    onBlurDropoffLocation = (event, { value }) => {
        const index2 = (this.state.bookingAddress.length > 0) ? this.state.bookingAddress.map(item => item.name).indexOf(this.state.dropoffLocation) : -1;
        if (index2 === -1) {
            this.setState({
                dropoffLocation: "",
                dropoffAddress: "",
                dropoffAddressType: "",
                dropoffCity: "",
                dropoffState: "",
                dropoffZipCode: "",
                dropoffZoneId: 0,
                dropoffLatitude: 0,
                dropoffLongitude: 0
            });
            this.getRideTypeId(this.state.pickupZoneId, 0);
        }
        this.state.errors["dropoffLocation"] = "";
    };

    getDropoffSuggestionValue(suggestion) {
        if (suggestion.isNotFound) {
            return "";
        }

        this.setState({
            dropoffLocation: suggestion.name,
            dropoffAddress: suggestion.address,
            dropoffAddressType: suggestion.addressType,
            dropoffCity: suggestion.city,
            dropoffState: suggestion.state,
            dropoffZipCode: suggestion.zipCode,
            dropoffZoneId: suggestion.zoneId,
            dropoffLatitude: suggestion.latitude,
            dropoffLongitude: suggestion.longitude
        });

        if (this.state.dropdownZone.findIndex(opt => opt.id == suggestion.zoneId) < 0) {
            this.setState({
                dropoffZoneId: 0,
            });
        } else {
            this.getRideTypeId(this.state.pickupZoneId, suggestion.zoneId);
            this.getCustomerRateTaxSetting(this.state.pickupZoneId, suggestion.zoneId, this.state.puDateTime, this.state.vehicleTypeId, parseInt(this.state.noOfPax | 0), 0, this.state.isMeetGreet);
            this.state.errors["dropoffZoneId"] = "";
        }

        this.state.errors["dropoffLocation"] = "";
        return suggestion.name;
    }

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onDropoffSuggestionsFetchRequested = ({ value }) => {
        this.getBookingSuggestions(value).then(data => this.setState({
            dropoffSuggestions: data
        }));
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onDropoffSuggestionsClearRequested = () => {
        this.setState({
            dropoffSuggestions: []
        });
        this.state.errors["dropoffLocation"] = "";
    };

    handleDayClick(day, { selected }) {
        const { selectedDays } = this.state;
        if (selected) {
            const selectedIndex = selectedDays.findIndex(selectedDay =>
                DateUtils.isSameDay(selectedDay, day)
            );
            selectedDays.splice(selectedIndex, 1);
        } else {
            selectedDays.push(day);
        }
        this.setState({ selectedDays });
        this.state.errors["recurring"] = "";
    }

    async getAirlines() {
        try {
            const data = JSON.parse(sessionStorage.getItem("session_airlines"));
            if (data === null) {
                const requestParams = getRequestParams('GET', '');
                const response = await fetch('air-line-api/' + 0, requestParams);
                const data = await response.json();

                this.setState({
                    dropdownAirline: data.airLine
                });
            }
            else {
                this.setState({
                    dropdownAirline: data.airLine
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    async getVehicleType() {
        try {
            const data = JSON.parse(sessionStorage.getItem("session_vehicletype"));
            if (data === null) {
                var newObject = new Object();
                const requestParams = getRequestParams('POST', newObject);
                const response = await fetch('vehicletype-api/vehicletype', requestParams);
                const data = await response.json();

                this.setState({
                    dropdownVehicleType: data.apiVehicleType
                });
            }
            else {
                this.setState({
                    dropdownVehicleType: data.apiVehicleType
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    async getAffiliates() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('account-api/affliate-dropdown', requestParams);
            const data = await response.json();

            this.setState({
                dropdownAffiliates: data.affiliatesDropdownList
            });
        } catch (e) {
            console.log(e);
        }
    }

    async getReservationModes() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('reservation-mode-api', requestParams);
            const data = await response.json();

            this.setState({
                dropdownReservationMode: data.reservationMode
            });
        } catch (e) {
            console.log(e);
        }
    }
    async getZones() {
        try {
            const data = JSON.parse(sessionStorage.getItem("session_zones"));
            if (data === null) {
                const requestParams = getRequestParams('GET', '');
                const response = await fetch('serviceAreaZone-api/zone-dropdown/' + 0 + "/" + 0, requestParams);
                const data = await response.json();

                this.setState({
                    dropdownZone: data.locationServiceAreaZoneList
                });
            }
            else {
                this.setState({
                    dropdownZone: data.locationServiceAreaZoneList
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true
        });
        if (this.props.tripDetails != null) {
            await this.loadPage(this.state.customer);
        }
        this.setState({
            loadPanelVisible: false
        });
    }

    async copyReservation() {
        if (this.props.tripDetails != null) {
            this.setState({
                loadPanelVisible: true
            });

            rideTypeId = parseInt(this.props.tripDetails.rideTypeId);
            let puZone = null, doZone = null;
            if (rideTypeId == 1) {
                puZone = this.state.dropdownZone.find(opt => opt.airportId == this.props.tripDetails.puAreaId);
                doZone = this.state.dropdownZone.find(opt => opt.id == this.props.tripDetails.doAreaId);
            } else if (rideTypeId == 2) {
                puZone = this.state.dropdownZone.find(opt => opt.id == this.props.tripDetails.puAreaId);
                doZone = this.state.dropdownZone.find(opt => opt.airportId == this.props.tripDetails.doAreaId);
            } else if (rideTypeId == 3) {
                puZone = this.state.dropdownZone.find(opt => opt.airportId == this.props.tripDetails.puAreaId);
                doZone = this.state.dropdownZone.find(opt => opt.airportId == this.props.tripDetails.doAreaId);
            } else {
                puZone = this.state.dropdownZone.find(opt => opt.id == this.props.tripDetails.puAreaId);
                doZone = this.state.dropdownZone.find(opt => opt.id == this.props.tripDetails.doAreaId);
            }

            this.setState({
                isCopy: true,
                rideId: 0,
                paxFirstName: this.props.tripDetails.firstName,
                paxLastName: this.props.tripDetails.lastName,
                countryCodePhone: this.props.tripDetails.countryCode,
                phoneNumber: this.props.tripDetails.phoneNo,
                countryCode: this.props.tripDetails.cellCountryCode,
                mobileNumber: this.props.tripDetails.mobileNo,
                email: this.props.tripDetails.emailId,

                isPickupGoogleBooking: false,
                pickupLocation: this.props.tripDetails.puLocationName,
                pickupZoneId: puZone != null ? puZone.id : 0,
                pickupAddress: this.props.tripDetails.puAddress,
                pickupAddressType: "",
                pickupCity: this.props.tripDetails.puCity,
                pickupState: this.props.tripDetails.puStateName,
                pickupZipCode: this.props.tripDetails.puZipCode,
                pickupLatitude: this.props.tripDetails.puLatitude,
                pickupLongitude: this.props.tripDetails.puLongitude,
                isDropoffGoogleBooking: false,
                apt: this.props.tripDetails.apt,
                dropoffLocation: this.props.tripDetails.doLocationName,
                dropoffZoneId: doZone != null ? doZone.id : 0,
                dropoffAddress: this.props.tripDetails.doAddress,
                dropoffAddressType: "",
                dropoffCity: this.props.tripDetails.doCity,
                dropoffState: this.props.tripDetails.doStateName,
                dropoffZipCode: this.props.tripDetails.doZipCode,
                dropoffLatitude: this.props.tripDetails.doLatitude,
                dropoffLongitude: this.props.tripDetails.doLongitude,
                serviceSourceId: this.props.tripDetails.serviceSourceId,
                otherAffiliateId: this.props.tripDetails.serviceSourceAffiliateId,

                airlineId: (rideTypeId == 1 || rideTypeId == 3) ? this.props.tripDetails.puAirlineId : this.props.tripDetails.doAirlineId,
                flightNumber: (rideTypeId == 1 || rideTypeId == 3) ? this.props.tripDetails.puAirlineNo : this.props.tripDetails.doAirlineNo,
                terminalType: (rideTypeId == 1 || rideTypeId == 3) ? this.props.tripDetails.puTerminalTypeId : this.props.tripDetails.doTerminalTypeId,
                terminal: (rideTypeId == 1 || rideTypeId == 3) ? this.props.tripDetails.puFlightTerminal : this.props.tripDetails.doFlightTerminal,
                flightTime: currentTime,
                flightHour: currentHour,
                flightMinute: currentMinute,

                airlineIdReturn: (rideTypeId == 1 || rideTypeId == 3) ? this.props.tripDetails.puAirlineId : this.props.tripDetails.DoAirlineId,
                flightNumberReturn: '',
                terminalTypeReturn: 0,
                terminalReturn: '',
                flightTimeReturn: currentTime,
                flightHourReturn: currentHour,
                flightMinuteReturn: currentMinute,

                orderByName: this.props.tripDetails.orderBy,
                countryCodeOrderPhone: this.props.tripDetails.orderByCountryCode,
                phoneNumberOrder: this.props.tripDetails.orderPhone,
                emailOrder: this.props.tripDetails.orderByEmail,
                reservationModeId: this.props.tripDetails.reservationModeId,
                puDateTime: currentTime,
                puDateTimeReturn: currentTime,

                doDateTime: currentTime,
                tripDuration: this.props.tripDetails.approxTripDurationMin,
                vehicleTypeId: this.props.tripDetails.apiVehicleTypeId,
                noOfPax: this.props.tripDetails.adultNo,
                noOfPaxReturn: 1,
                luggage: this.props.tripDetails.baggageCount,
                noOfTrip: 1,
                isAccessible: this.props.tripDetails.isAccessible,
                isVipTrip: this.props.tripDetails.vip === 'Y' ? true : false,
                isPriorityTrip: this.props.tripDetails.priority === 'Y' ? true : false,
                isMeetGreet: this.props.tripDetails.isMgRequired,

                poNumber: this.props.tripDetails.crewPo,
                noOfTrip: 1,
                tripTypeId: "1",

                recurringTypeId: 2,
                recurringEndDate: "",
                selectedDays: [],
                isWeekSun: false,
                isWeekMon: false,
                isWeekTue: false,
                isWeekWed: false,
                isWeekThu: false,
                isWeekFri: false,
                isWeekSat: false,
                isMonthSun: false,
                isMonthMon: false,
                isMonthTue: false,
                isMonthWed: false,
                isMonthThu: false,
                isMonthFri: false,
                isMonthSat: false,
                specialInstruction: this.props.tripDetails.specialInstruction,
                permanentRemark: this.props.tripDetails.permanentRemark,
                pickupNotes: this.props.tripDetails.pickUpNotes,
                billToNotes: this.props.tripDetails.billToNotes,

                customerRateTax: null,
            });

            //this.refs.vehicleDriverComponent.state.filterVehicleId = this.props.tripDetails.vehicleId;
            //this.refs.vehicleDriverComponent.state.filterDriverId = this.props.tripDetails.driverId;
            this.refs.vehicleDriverComponent.state.filterVehicleId = 0;
            this.refs.vehicleDriverComponent.state.filterDriverId = 0;

            let paxCount = (parseInt(this.state.noOfPax | 0));

            await this.getCustomerRateTaxSetting((puZone != null ? puZone.id : 0), (doZone != null ? doZone.id : 0), currentTime, this.props.tripDetails.apiVehicleTypeId, paxCount, 0, this.props.tripDetails.isMgRequired)

            this.setState({
                loadPanelVisible: false
            });

            const isValid = await Swal.fire({
                title: "<small>Copy Reservation</small>",
                text: "Reservation copied successfully.",
                icon: "success",
                width: '25%',
                dangerMode: false,
                allowOutsideClick: false
            });
        }
    }

    async loadPage(data) {

        this.setState({ customer: data, airlineId: data.airlineId })
        await this.getAirlines();
        await this.getVehicleType();
        await this.getAffiliates();
        await this.getReservationModes();
        await this.getZones();
        var options = { types: ['establishment'] };
        this.autocompletePickup = new google.maps.places.Autocomplete(document.getElementById('pickupGoogle'), options);
        this.autocompletePickup.addListener("place_changed", this.handlePickupPlaceSelect);

        var options = { types: ['establishment'] };
        this.autocompleteDropoff = new google.maps.places.Autocomplete(document.getElementById('dropoffGoogle'), options);
        this.autocompleteDropoff.addListener("place_changed", this.handleDropoffPlaceSelect);
        if (this.props.tripDetails != null) {
            rideTypeId = parseInt(this.props.tripDetails.rideTypeId);
            let puZone = null, doZone = null;
            if (rideTypeId == 1) {
                puZone = this.state.dropdownZone.find(opt => opt.airportId == this.props.tripDetails.puAreaId);
                doZone = this.state.dropdownZone.find(opt => opt.id == this.props.tripDetails.doAreaId);
            } else if (rideTypeId == 2) {
                puZone = this.state.dropdownZone.find(opt => opt.id == this.props.tripDetails.puAreaId);
                doZone = this.state.dropdownZone.find(opt => opt.airportId == this.props.tripDetails.doAreaId);
            } else if (rideTypeId == 3) {
                puZone = this.state.dropdownZone.find(opt => opt.airportId == this.props.tripDetails.puAreaId);
                doZone = this.state.dropdownZone.find(opt => opt.airportId == this.props.tripDetails.doAreaId);
            } else {
                puZone = this.state.dropdownZone.find(opt => opt.id == this.props.tripDetails.puAreaId);
                doZone = this.state.dropdownZone.find(opt => opt.id == this.props.tripDetails.doAreaId);
            }

            this.setState({
                paxFirstName: this.props.tripDetails.firstName,
                paxLastName: this.props.tripDetails.lastName,
                countryCodePhone: this.props.tripDetails.countryCode,
                phoneNumber: this.props.tripDetails.phoneNo,
                countryCode: this.props.tripDetails.cellCountryCode,
                mobileNumber: this.props.tripDetails.mobileNo,
                email: this.props.tripDetails.emailId,

                isPickupGoogleBooking: false,
                pickupLocation: this.props.tripDetails.puLocationName,
                pickupZoneId: puZone != null ? puZone.id : 0,
                pickupAddress: this.props.tripDetails.puAddress,
                pickupAddressType: "",
                pickupCity: this.props.tripDetails.puCity,
                pickupState: this.props.tripDetails.puStateName,
                pickupZipCode: this.props.tripDetails.puZipCode,
                pickupLatitude: this.props.tripDetails.puLatitude,
                pickupLongitude: this.props.tripDetails.puLongitude,
                isDropoffGoogleBooking: false,
                apt: this.props.tripDetails.apt,
                dropoffLocation: this.props.tripDetails.doLocationName,
                dropoffZoneId: doZone != null ? doZone.id : 0,
                dropoffAddress: this.props.tripDetails.doAddress,
                dropoffAddressType: "",
                dropoffCity: this.props.tripDetails.doCity,
                dropoffState: this.props.tripDetails.doStateName,
                dropoffZipCode: this.props.tripDetails.doZipCode,
                dropoffLatitude: this.props.tripDetails.doLatitude,
                dropoffLongitude: this.props.tripDetails.doLongitude,
                serviceSourceId: this.props.tripDetails.serviceSourceId,
                otherAffiliateId: this.props.tripDetails.serviceSourceAffiliateId,

                airlineId: (rideTypeId == 1 || rideTypeId == 3) ? this.props.tripDetails.puAirlineId : this.props.tripDetails.doAirlineId,
                flightNumber: (rideTypeId == 1 || rideTypeId == 3) ? this.props.tripDetails.puAirlineNo : this.props.tripDetails.doAirlineNo,
                terminalType: (rideTypeId == 1 || rideTypeId == 3) ? this.props.tripDetails.puTerminalTypeId : this.props.tripDetails.doTerminalTypeId,
                terminal: (rideTypeId == 1 || rideTypeId == 3) ? this.props.tripDetails.puFlightTerminal : this.props.tripDetails.doFlightTerminal,
                flightTime: (rideTypeId == 1 || rideTypeId == 3) ? new Date(this.props.tripDetails.puFlightDateTime) : new Date(this.props.tripDetails.doFlightDateTime),
                flightHour: (rideTypeId == 1 || rideTypeId == 3) ? new Date(this.props.tripDetails.puFlightDateTime).getHours() : new Date(this.props.tripDetails.doFlightDateTime).getHours(),
                flightMinute: (rideTypeId == 1 || rideTypeId == 3) ? new Date(this.props.tripDetails.puFlightDateTime).getMinutes() : new Date(this.props.tripDetails.doFlightDateTime).getMinutes(),

                airlineIdReturn: (rideTypeId == 1 || rideTypeId == 3) ? this.props.tripDetails.puAirlineId : this.props.tripDetails.DoAirlineId,
                flightNumberReturn: '',
                terminalTypeReturn: 0,
                terminalReturn: '',
                flightTimeReturn: currentTime,
                flightHourReturn: new Date(currentTime).getHours(),
                flightMinuteReturn: new Date(currentTime).getMinutes(),

                orderByName: this.props.tripDetails.orderBy,
                countryCodeOrderPhone: this.props.tripDetails.orderByCountryCode,
                phoneNumberOrder: this.props.tripDetails.orderPhone,
                emailOrder: this.props.tripDetails.orderByEmail,
                reservationModeId: this.props.tripDetails.reservationModeId,
                puDateTime: new Date(this.props.tripDetails.puDateTime),
                puDateTimeReturn: currentTime,

                doDateTime: this.props.tripDetails.approxDropoffTime == null ? new Date() : new Date(this.props.tripDetails.approxDropoffTime),
                tripDuration: this.props.tripDetails.approxTripDurationMin,
                vehicleTypeId: this.props.tripDetails.apiVehicleTypeId,
                noOfPax: this.props.tripDetails.adultNo,
                noOfPaxReturn: 1,
                luggage: this.props.tripDetails.baggageCount,
                noOfTrip: 1,
                isAccessible: this.props.tripDetails.isAccessible,
                isVipTrip: this.props.tripDetails.vip === 'Y' ? true : false,
                isPriorityTrip: this.props.tripDetails.priority === 'Y' ? true : false,
                isMeetGreet: this.props.tripDetails.isMgRequired,

                poNumber: this.props.tripDetails.crewPo,
                noOfTrip: 1,
                tripTypeId: "1",

                recurringTypeId: 2,
                recurringEndDate: "",
                selectedDays: [],
                isWeekSun: false,
                isWeekMon: false,
                isWeekTue: false,
                isWeekWed: false,
                isWeekThu: false,
                isWeekFri: false,
                isWeekSat: false,
                isMonthSun: false,
                isMonthMon: false,
                isMonthTue: false,
                isMonthWed: false,
                isMonthThu: false,
                isMonthFri: false,
                isMonthSat: false,
                specialInstruction: this.props.tripDetails.specialInstruction,
                permanentRemark: this.props.tripDetails.permanentRemark,
                pickupNotes: this.props.tripDetails.pickUpNotes,
                billToNotes: this.props.tripDetails.billToNotes,

                customerRateTax: null,
            });

            this.refs.vehicleDriverComponent.state.filterVehicleId = this.props.tripDetails.vehicleId;
            this.refs.vehicleDriverComponent.state.filterDriverId = this.props.tripDetails.driverId;

            let paxCount = (parseInt(this.state.noOfPax | 0));

            await this.getCustomerRateTaxSetting((puZone != null ? puZone.id : 0), (doZone != null ? doZone.id : 0), new Date(this.props.tripDetails.puDateTime), this.props.tripDetails.apiVehicleTypeId, paxCount, 0, this.props.tripDetails.isMgRequired)
        }
    }

    handlePickupPlaceSelect() {
        var placeObj = this.handlePlaceSearch(this.autocompletePickup);
        this.state.errors["pickupLocation"] = "";
        if (placeObj !== null) {
            this.setState({
                pickupLocation: placeObj.location,
                pickupAddress: placeObj.address1,
                pickupAddressType: placeObj.addressType,
                pickupCity: placeObj.cityName,
                pickupState: placeObj.stateName,
                pickupZipCode: placeObj.zipCode,
                pickupLatitude: placeObj.latitude,
                pickupLongitude: placeObj.longitude,
            })
        } else {
            this.setState({
                pickupLocation: "",
                pickupAddress: "",
                pickupAddressType: "",
                pickupCity: "",
                pickupState: "",
                pickupZipCode: "",
                pickupLatitude: 0,
                pickupLongitude: 0
            });

        }

    }

    handleDropoffPlaceSelect() {
        var placeObj = this.handlePlaceSearch(this.autocompleteDropoff);
        this.state.errors["dropoffLocation"] = "";
        if (placeObj !== null) {
            this.setState({
                dropoffLocation: placeObj.location,
                dropoffAddress: placeObj.address1,
                dropoffAddressType: placeObj.addressType,
                dropoffCity: placeObj.cityName,
                dropoffState: placeObj.stateName,
                dropoffZipCode: placeObj.zipCode,
                dropoffLatitude: placeObj.latitude,
                dropoffLongitude: placeObj.longitude,
            })
        } else {
            this.setState({
                dropoffLocation: "",
                dropoffAddress: "",
                dropoffAddressType: "",
                dropoffCity: "",
                dropoffState: "",
                dropoffZipCode: "",
                dropoffLatitude: 0,
                dropoffLongitude: 0
            });

        }

    }

    handlePlaceSearch(autocomplete_place) {
        let place = autocomplete_place.getPlace();

        var placeObject = new Object();

        if (place !== null && place.formatted_address !== null && place.formatted_address.trim() != "") {
            placeObject.location = place.name;
            placeObject.latitude = place.geometry.location.lat();
            placeObject.longitude = place.geometry.location.lng();

            var splitAddress = [];
            place.formatted_address = place.formatted_address.replace(/-/g, ',');
            splitAddress = place.formatted_address.split(',');
            if (splitAddress.length > 0) {
                if (splitAddress.length == 1) {
                    placeObject.address1 = splitAddress[0];
                    placeObject.cityName = "";
                    placeObject.zipCode = "";
                    placeObject.stateName = "";
                    placeObject.countryName = "";
                }
                else if (splitAddress.length == 2) {
                    placeObject.address1 = splitAddress[0];
                    placeObject.cityName = splitAddress[0];
                    placeObject.stateName = splitAddress[1].trim().search(' ') > 0 ? splitAddress[1].trim().split(' ')[0] : splitAddress[1].trim();
                    placeObject.zipCode = splitAddress[1].trim().search(' ') > 0 ? splitAddress[1].trim().split(' ')[1] : " ";
                    placeObject.countryName = "";
                }
                else if (splitAddress.length == 3) {
                    placeObject.address1 = splitAddress[0] + ' ' + splitAddress[1].trim();
                    placeObject.cityName = splitAddress[1].trim();
                    placeObject.stateName = splitAddress[2].trim().search(' ') > 0 ? splitAddress[2].trim().split(' ')[0] : splitAddress[2].trim();
                    placeObject.zipCode = splitAddress[2].trim().search(' ') > 0 ? splitAddress[2].trim().split(' ')[1] : " ";
                    placeObject.countryName = "";
                }
                else if (splitAddress.length == 4) {
                    placeObject.address1 = splitAddress[0] + ' ' + splitAddress[1].trim();
                    placeObject.cityName = splitAddress[1].trim();
                    placeObject.stateName = splitAddress[2].trim().search(' ') > 0 ? splitAddress[2].trim().split(' ')[0] : splitAddress[2].trim();
                    placeObject.zipCode = splitAddress[2].trim().search(' ') > 0 ? splitAddress[2].trim().split(' ')[1] : " ";
                    placeObject.countryName = splitAddress[3].trim();
                }
                else if (splitAddress.length == 5) {
                    placeObject.address1 = splitAddress[0] + ' ' + splitAddress[1].trim() + ' ' + splitAddress[2].trim();
                    placeObject.cityName = splitAddress[2].trim();
                    placeObject.stateName = splitAddress[3].trim().search(' ') > 0 ? splitAddress[3].trim().split(' ')[0] : splitAddress[3].trim();
                    placeObject.zipCode = splitAddress[3].trim().search(' ') > 0 ? splitAddress[3].trim().split(' ')[1] : " ";
                    placeObject.countryName = splitAddress[4].trim();
                }
                else if (splitAddress.length == 6) {
                    placeObject.address1 = splitAddress[0] + ' ' + splitAddress[1].trim() + ' ' + splitAddress[2].trim() + ' ' + splitAddress[3].trim();
                    placeObject.cityName = splitAddress[3].trim();
                    placeObject.stateName = splitAddress[4].trim().search(' ') > 0 ? splitAddress[4].trim().split(' ')[0] : splitAddress[4].trim();
                    placeObject.zipCode = splitAddress[4].trim().search(' ') > 0 ? splitAddress[4].trim().split(' ')[1] : " ";
                    placeObject.countryName = splitAddress[5].trim();
                }
                else {
                    placeObject.address1 = splitAddress[0] + ' ' + splitAddress[1].trim() + ' ' + splitAddress[2].trim() + ' ' + splitAddress[3].trim() + ' ' + splitAddress[4].trim();
                    placeObject.cityName = splitAddress[4].trim();
                    placeObject.stateName = splitAddress[5].trim().search(' ') > 0 ? splitAddress[5].trim().split(' ')[0] : splitAddress[5].trim();
                    placeObject.zipCode = splitAddress[5].trim().search(' ') > 0 ? splitAddress[5].trim().split(' ')[1] : " ";
                    placeObject.countryName = splitAddress[6].trim();
                }
            }
        }
        return placeObject;
    }

    handlePickupToggleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            pickupLocation: "",
            pickupAddress: "",
            pickupAddressType: "",
            pickupCity: "",
            pickupState: "",
            pickupZipCode: "",
            pickupLatitude: 0,
            pickupLongitude: 0
        });
        this.state.errors["pickupLocation"] = "";
        document.getElementById('pickupGoogle').value = "";


        this.setState({ isPickupGoogleBooking: value })
    }

    handleDropoffToggleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            dropoffLocation: "",
            dropoffAddress: "",
            dropoffAddressType: "",
            dropoffCity: "",
            dropoffState: "",
            dropoffZipCode: "",
            dropoffLatitude: 0,
            dropoffLongitude: 0
        });
        this.state.errors["dropoffLocation"] = "";
        document.getElementById('dropoffGoogle').value = "";

        this.setState({ isDropoffGoogleBooking: value })
    }


    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === "recurringTypeId") {
            this.setState({
                selectedDays: [],
                isWeekSun: false,
                isWeekMon: false,
                isWeekTue: false,
                isWeekWed: false,
                isWeekThu: false,
                isWeekFri: false,
                isWeekSat: false,
                isMonthSun: false,
                isMonthMon: false,
                isMonthTue: false,
                isMonthWed: false,
                isMonthThu: false,
                isMonthFri: false,
                isMonthSat: false,
            });
            this.setState({
                [name]: value
            });
            this.state.errors["recurring"] = "";
        } else if (name === "serviceSourceId") {
            if (value === "1") {
                this.setState({
                    otherAffiliateId: 0,
                });
            }
            this.setState({
                [name]: value
            });
            this.state.errors[name] = "";
        } else if (name === "apt") {
            this.setState({
                [name]: value
            });
            this.state.errors[name] = "";
            let myVar = setTimeout(() => this.getCustomerRateTaxSetting(this.state.pickupZoneId, this.state.dropoffZoneId, this.state.puDateTime, this.state.vehicleTypeId, parseInt(this.state.noOfPax | 0), 0, this.state.isMeetGreet), 100)
        } else {
            this.setState({
                [name]: value
            });
            this.state.errors[name] = "";
        }


    }

    handlePickupZoneChange(selectedOption) {

        this.setState({ pickupZoneId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
        this.state.errors["pickupZoneId"] = "";
        (selectedOption.selectedItem != null && this.getRideTypeId(selectedOption.selectedItem.id, this.state.dropoffZoneId));

        (selectedOption.selectedItem != null && this.getCustomerRateTaxSetting(selectedOption.selectedItem.id, this.state.dropoffZoneId, this.state.puDateTime, this.state.vehicleTypeId, parseInt(this.state.noOfPax | 0), 0, this.state.isMeetGreet));
    }
    handleDropoffZoneChange(selectedOption) {
        this.setState({ dropoffZoneId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
        this.state.errors["dropoffZoneId"] = "";
        (selectedOption.selectedItem != null && this.getRideTypeId(this.state.pickupZoneId, selectedOption.selectedItem.id));

        (selectedOption.selectedItem != null && this.getCustomerRateTaxSetting(this.state.pickupZoneId, selectedOption.selectedItem.id, this.state.puDateTime, this.state.vehicleTypeId, parseInt(this.state.noOfPax | 0), 0, this.state.isMeetGreet));
    }
    handleAirlineChange(selectedOption) {
        this.setState({ airlineId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.airLineId : 0), })
    }
    handleAirlineReturnChange(selectedOption) {
        this.setState({ airlineIdReturn: (selectedOption.selectedItem != null ? selectedOption.selectedItem.airLineId : 0), })
        this.state.errors["airlineIdReturn"] = "";
    }
    handleVehicleTypeChange(selectedOption) {
        this.setState({ vehicleTypeId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.rowIndex : 0), })
        this.state.errors["vehicleTypeId"] = "";

        (selectedOption != null && this.getCustomerRateTaxSetting(this.state.pickupZoneId, this.state.dropoffZoneId, this.state.puDateTime, selectedOption.selectedItem.rowIndex, parseInt(this.state.noOfPax | 0), 0, this.state.isMeetGreet));
    }

    handleAffiliateChange(selectedOption) {
        this.setState({ otherAffiliateId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
        this.state.errors["otherAffiliateId"] = "";
    }

    handleReservationModeChange(selectedOption) {
        this.setState({ reservationModeId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
        this.state.errors["reservationModeId"] = "";
    }

    handleRecurringEndDateChange(date) {
        this.setState({ recurringEndDate: date.value });
        this.state.errors["recurringEndDate"] = "";
    }

    handleFlightDateTimeChange(date) {
        this.setState({ flightTime: date });
        this.state.errors["flightTime"] = "";
    }

    handleFlightHourChange(e) {
        if (e.selectedItem != null) {
            this.setState({ flightHour: e.selectedItem.id });
        }
    }

    handleFlightHourReturnChange(e) {
        if (e.selectedItem != null) {
            this.setState({ flightHourReturn: e.selectedItem.id });
        }
    }

    handleFlightMinuteChange(e) {
        if (e.selectedItem != null) {
            this.setState({ flightMinute: e.selectedItem.id });
        }
    }

    handleFlightMinuteReturnChange(e) {
        if (e.selectedItem != null) {
            this.setState({ flightMinuteReturn: e.selectedItem.id });
        }
    }

    handleFlightDateTimeReturnChange(date) {
        this.setState({ flightTimeReturn: date });
        this.state.errors["flightTimeReturn"] = "";
    }

    handlePickupDateTimeChange(date) {
        this.setState({ puDateTime: date.value });
        this.state.errors["puDateTime"] = "";

        (date != null && this.getCustomerRateTaxSetting(this.state.pickupZoneId, this.state.dropoffZoneId, date, this.state.vehicleTypeId, parseInt(this.state.noOfPax | 0), 0, this.state.isMeetGreet));
    }

    handlePickupDateTimeReturnChange(date) {
        this.setState({ puDateTimeReturn: date.value });
        this.state.errors["puDateTimeReturn"] = "";
    }

    handlePaxCountChange(value) {
        this.setState({ noOfPax: value });
        this.getCustomerRateTaxSetting(this.state.pickupZoneId, this.state.dropoffZoneId, this.state.puDateTime, this.state.vehicleTypeId, value, 0, this.state.isMeetGreet);

        if (value > 0) {
            this.state.errors["noOfPax"] = ""
        }
    }

    handleTripCountChange(value) {
        this.setState({ noOfTrip: value });
        this.state.errors["noOfTrip"] = ""
    }

    handlePaxCountReturnChange(value) {
        this.setState({ noOfPaxReturn: value });
    }

    handleTripTypeChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ tripTypeId: value });

        this.setState({
            recurringTypeId: 2,
            selectedDays: [],
            recurringEndDate: "",
            isWeekSun: false,
            isWeekMon: false,
            isWeekTue: false,
            isWeekWed: false,
            isWeekThu: false,
            isWeekFri: false,
            isWeekSat: false,
            isMonthSun: false,
            isMonthMon: false,
            isMonthTue: false,
            isMonthWed: false,
            isMonthThu: false,
            isMonthFri: false,
            isMonthSat: false,
        });
        this.state.errors["recurring"] = "";

        this.setState({
            flightNumberReturn: "",
            terminalTypeReturn: 0,
            terminalReturn: "",
            noOfPaxReturn: 1,
            noOfTrip: 1,
            flightTimeReturn: currentTime,
            flightHourReturn: currentHour,
            flightMinuteReturn: currentMinute,
        });

        this.state.errors["airlineIdReturn"] = "";
        this.state.errors["flightNumberReturn"] = "";
        this.state.errors["noOfPaxReturn"] = "";
        this.state.errors["flightTimeReturn"] = "";
        this.state.errors["puDateTimeReturn"] = "";
    }

    handleMeetGreetChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ isMeetGreet: value });
        let paxCount = (parseInt(this.state.noOfPax | 0));
        this.getCustomerRateTaxSetting(this.state.pickupZoneId, this.state.dropoffZoneId, this.state.puDateTime, this.state.vehicleTypeId, paxCount, 0, value);
    }

    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.paxFirstName = "";
        errors.paxLastName = "";
        errors.countryCode = "";
        errors.mobileNumber = "";
        errors.email = "";
        errors.pickupLocation = "";
        errors.pickupZoneId = "";
        errors.dropoffLocation = "";
        errors.dropoffZoneId = "";
        errors.otherAffiliateId = "";
        errors.puDateTime = "";
        errors.puDateTimeReturn = "";
        errors.doDateTime = "";
        errors.flightTime = "";
        errors.flightNumber = "";
        errors.terminalType = "";
        errors.flightTimeReturn = "";
        errors.flightNumberReturn = "";
        errors.terminalTypeReturn = "";
        errors.noOfPaxReturn = "";
        errors.orderByName = "";
        errors.reservationModeId = "";
        errors.vehicleTypeId = "";
        errors.noOfTrip = "";
        errors.noOfPax = "";
        errors.recurringEndDate = "";
        errors.recurring = "";

        if (this.state.paxFirstName.trim() === '') {
            formIsValid = false;
            errors.paxFirstName = "Please enter pax first name.";
        }
        if (this.state.paxLastName.trim() === '') {
            formIsValid = false;
            errors.paxLastName = "Please enter pax last name.";
        }
        if (this.state.mobileNumber.trim() === "") {
            formIsValid = false;
            errors.mobileNumber = "Please enter mobile.";
        } else if (this.state.mobileNumber.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length != 10) {
            formIsValid = false;
            errors.mobileNumber = "Invalid mobile number.";
        }
        if (this.state.countryCode.trim() == '') {
            formIsValid = false;
            errors.countryCode = "Required.";
        }

        if (this.state.pickupLocation.trim() === '') {
            formIsValid = false;
            errors.pickupLocation = "Please enter pickup location.";
        }
        if (this.state.pickupZoneId == 0) {
            formIsValid = false;
            errors.pickupZoneId = "Please select pickup zone.";
        }
        if (this.state.dropoffLocation.trim() === '') {
            formIsValid = false;
            errors.dropoffLocation = "Please enter dropoff location.";
        }
        if (this.state.dropoffZoneId == 0) {
            formIsValid = false;
            errors.dropoffZoneId = "Please select dropoff zone.";
        }
        if (this.state.puDateTime == null) {
            formIsValid = false;
            errors.puDateTime = "Please enter pickup date time.";
        }
        if (this.state.doDateTime == '') {
            formIsValid = false;
            errors.puDateTime = "Please enter dropoff date time.";
        }
        if (this.state.airlineId > 0) {
            if (this.state.flightNumber.trim() === '') {
                formIsValid = false;
                errors.flightNumber = "Please enter flight number.";
            }
            if (this.state.flightTime == null) {
                formIsValid = false;
                errors.flightTime = "Please enter flight time.";
            }
            if (parseInt(this.state.terminalType) == 0) {
                formIsValid = false;
                errors.terminalType = "Please select terminal type.";
            }
        }

        if (this.state.tripTypeId === "2") {
            if (this.state.airlineIdReturn > 0) {
                if (this.state.flightNumberReturn.trim() === '') {
                    formIsValid = false;
                    errors.flightNumberReturn = "Please enter flight number.";
                }
                if (this.state.flightTimeReturn == null) {
                    formIsValid = false;
                    errors.flightTimeReturn = "Please enter flight time.";
                }
                if (parseInt(this.state.terminalTypeReturn) == 0) {
                    formIsValid = false;
                    errors.terminalTypeReturn = "Please select terminal type.";
                }
            }

            if (this.state.puDateTimeReturn == null) {
                formIsValid = false;
                errors.puDateTimeReturn = "Please enter pickup date time.";
            }

            if (parseInt(this.state.noOfPaxReturn | 0) == 0) {
                formIsValid = false;
                errors.noOfPaxReturn = "Please enter no of pax.";
            }
        }

        if (this.state.orderByName.trim() === '') {
            formIsValid = false;
            errors.orderByName = "Please enter order by.";
        }
        if (this.state.reservationModeId == 0) {
            formIsValid = false;
            errors.reservationModeId = "Please select rez mode.";
        }

        if (this.state.isSendEmail == true) {
            if (this.state.email.trim() === '') {
                formIsValid = false;
                errors.email = "Please enter email.";
            }
        }

        if (parseInt(this.state.reservationModeId) == 3) {
            if (this.state.email.trim() === '') {
                formIsValid = false;
                errors.email = "Please enter email.";
            }
        }

        if (this.state.email != '') {
            let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!regEmail.test(this.state.email)) {
                formIsValid = false;
                errors.email = "Please enter valid email.";
            }
        }

        if (this.state.vehicleTypeId == 0) {
            formIsValid = false;
            errors.vehicleTypeId = "Please select vehicle type.";
        }
        if (parseInt(this.state.noOfTrip | 0) == 0) {
            formIsValid = false;
            errors.noOfTrip = "Please enter no of trip.";
        }
        if (parseInt(this.state.noOfPax | 0) == 0) {
            formIsValid = false;
            errors.noOfPax = "Please enter no of trip.";
        }
        if (this.state.serviceSourceId == 2 || this.state.serviceSourceId == 3) {
            if (this.state.otherAffiliateId == 0) {
                formIsValid = false;
                errors.otherAffiliateId = "Please select affiliate.";
            }
        }

        if (this.state.tripTypeId === "3") {

            if (this.state.recurringEndDate === "" || this.state.recurringEndDate === null) {
                formIsValid = false;
                errors.recurringEndDate = "Please enter recurring end date.";
            }

            if (this.state.recurringTypeId == 1) {
                if (this.state.selectedDays.length === 0) {
                    formIsValid = false;
                    errors.recurring = "Please select recurring days.";
                }
            }

            if (this.state.recurringTypeId == 2) {
                if (this.state.isWeekSun === false &&
                    this.state.isWeekMon === false &&
                    this.state.isWeekTue === false &&
                    this.state.isWeekWed === false &&
                    this.state.isWeekThu === false &&
                    this.state.isWeekFri === false &&
                    this.state.isWeekSat === false) {
                    formIsValid = false;
                    errors.recurring = "Please select recurring days.";
                }
            }

            if (this.state.recurringTypeId == 3) {
                if (this.state.isMonthSun === false &&
                    this.state.isMonthMon === false &&
                    this.state.isMonthTue === false &&
                    this.state.isMonthWed === false &&
                    this.state.isMonthThu === false &&
                    this.state.isMonthFri === false &&
                    this.state.isMonthSat === false) {
                    formIsValid = false;
                    errors.recurring = "Please select recurring days.";
                }
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    async checkDuplicateReservation(isButtonShow) {
        let isResult = false;
        var stpuDate = new Date(new Date(this.state.puDateTime).toLocaleDateString());
        var pickupdate = ((stpuDate.getMonth() + 1) < 10 ? "0" + (stpuDate.getMonth() + 1) : (stpuDate.getMonth() + 1)) + '/' + (stpuDate.getDate() < 10 ? "0" + stpuDate.getDate() : stpuDate.getDate()) + '/' + stpuDate.getFullYear();

        if (this.state.paxFirstName.trim() == "") {
            this.state.errors["paxFirstName"] = "Please enter first name."
            return;
        }

        if (this.state.paxLastName.trim() == "") {
            this.state.errors["paxLastName"] = "Please enter last name."
            return;
        }

        var newObject = new Object();
        newObject.CustomerId = parseInt(this.state.customer.id);
        newObject.FirstName = this.state.paxFirstName;
        newObject.LastName = this.state.paxLastName;
        newObject.FromDate = pickupdate + " 00:00";
        newObject.ToDate = pickupdate + " 23:59";

        try {
            if (isButtonShow == false) {
                this.props.duplicateFunction(true);
            }
            const requestParams = getRequestParams('POST', newObject);
            const response = await fetch('reservation-api/duplicate-list', requestParams);
            const data = await response.json();

            if (data.duplicateTrips.length > 0) {
                this.props.duplicateFunction(false);

                const isValid = await Swal.fire({
                    title: "<small>Duplicate trips</small>",
                    text: "Duplicate trip(s) found!",
                    icon: "success",
                    width: '25%',
                    dangerMode: false,
                    allowOutsideClick: false
                });

                this.refs.duplicationReservationModal.showModal(data.duplicateTrips, isButtonShow);
                isResult = true;
            } else {

                if (isButtonShow == false) {

                    this.props.duplicateFunction(false);
                    const isValid = await Swal.fire({
                        title: "<small>Duplicate trips</small>",
                        text: "Duplicate trip(s) not found!",
                        icon: "success",
                        width: '25%',
                        dangerMode: false,
                        allowOutsideClick: false
                    });
                }

            }
            return isResult;
        } catch (e) {
            console.log(e);
        }
    }

    async askDuplicateConfirm() {
        let isResult = false;
        await Swal.fire({
            title: "<small>Duplicate trips</small>",
            html: "Duplicate trip(s) found.<br> Do you want to continue ..... ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    async askScheduleTimeConfirm() {
        let isResult = false;
        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: "Pickup time should be minimum 10 minutes less than schedule time!<br> Do you want to still continue?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    async askReturnTripConfirm() {
        let isResult = false;
        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: "You are going book a return trip!<br> Do you want to still continue?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    async askBookingConfirm(paxCount) {
        let isResult = false;
        let strAlert = "";
        strAlert = (strAlert + ("Please Let me confirm your reservation." + ("<br>" + ("<br>" + ("You have a ["
            + (this.state.puDateTime.toLocaleTimeString() + ("] pickup on ["
                + (this.state.puDateTime.toLocaleDateString() + ("]" + ("<br>" + ('\t' + ("From the ["
                    + ((((this.state.pickupLocation.Length) <= 0) ? "" : this.state.pickupLocation) + ("] " + ("<br>" + ("for a total of ["
                        + (paxCount + ("] passanger(s) " + ("<br>" + ('\t' + ("going to ["
                            + (this.state.dropoffLocation + ("]" + ("<br>" + ("\r\n" + ("SPECIAL INSTRUCTION: "
                                + (this.state.specialInstruction + ("<br>" + "Is this Correct?"))))))))))))))))))))))))))));

        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: strAlert,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }


    async contractTransferSubmit(isAttachment, isSendEmail) {
        let myVar = setTimeout(() => this.setState({ isAttachment: isAttachment, isSendEmail: isSendEmail }), 100)
        if (!this.handleValidation()) {
            return;
        }

        if (this.state.rideId > 0) {
            if (parseInt(this.props.tripDetails.tripStatusId) == 116) {

                if (parseInt(this.props.tripDetails.invoiceNo) > 0) {
                    const isValid = await Swal.fire({
                        title: "<small>Unable to modify</small>",
                        text: "Reservation is completed & invoiced, you cant modify/update Reservation.",
                        icon: "info",
                        dangerMode: false,
                        width: "25%",
                        dangerMode: false,
                        allowOutsideClick: false
                    });
                    return;
                } else {
                    if (parseInt(window.sessionStorage.getItem("session_userTypeId")) == 33
                        || parseInt(window.sessionStorage.getItem("session_userTypeId")) == 6
                        || parseInt(window.sessionStorage.getItem("session_userTypeId")) == 2) {

                    } else {
                        const isValid = await Swal.fire({
                            title: "<small>Unable to modify</small>",
                            text: "Reservation is completed, you cant modify/update Reservation.",
                            icon: "info",
                            dangerMode: false,
                            width: "25%",
                            dangerMode: false,
                            allowOutsideClick: false
                        });
                        return;
                    }
                }
            }

            if (parseInt(this.props.tripDetails.tripStatusId) == 111 || parseInt(this.props.tripDetails.tripStatusId) == 118) {
                const isValid = await Swal.fire({
                    title: "<small>Unable to modify</small>",
                    text: "Reservation is void/cancelled, you cant modify/update Reservation.",
                    icon: "info",
                    dangerMode: false,
                    width: "25%",
                    dangerMode: false,
                    allowOutsideClick: false
                });
                return;
            }
        }

        if (parseInt(this.state.customer.accountStatusId) == 2) {
            const isValid = await Swal.fire({
                title: "<small>Account on hold</small>",
                text: "This account is on hold. You are not allow to book any reservation. <br>Please contact to accounting department.",
                icon: "info",
                dangerMode: false,
                width: "25%",
                dangerMode: false,
                allowOutsideClick: false
            });
            return;
        }// On Hold

        if (parseInt(this.state.customer.accountStatusId) == 3) {
            const isValid = await Swal.fire({
                title: "<small>Account on close</small>",
                text: "This account is on close. You are not allow to book any reservation. <br>Please contact to accounting department.",
                icon: "info",
                dangerMode: false,
                width: "25%",
                dangerMode: false,
                allowOutsideClick: false
            });
            return;
        }// On Hold

        //Schedule Validation
        if (this.state.rideId > 0) {
            if (parseInt(this.props.tripDetails.tripStatusId) == 101
                || parseInt(this.props.tripDetails.tripStatusId) == 103
                || parseInt(this.props.tripDetails.tripStatusId) == 105) {

                if (rideTypeId == 2 && this.state.airlineId > 0) {
                    let diffInMs = Math.abs(this.state.puDateTime - new Date(this.state.puDateTime.toLocaleDateString() + " " + this.state.flightTime.toLocaleTimeString()));
                    let minuteDiff = diffInMs / (1000 * 60);

                    if (minuteDiff <= 0 || minuteDiff < 10) {
                        const isConfirm = await this.askScheduleTimeConfirm();
                        if (!isConfirm) {
                            return;
                        }
                    }
                }
            }
        } else {
            if (rideTypeId == 2 && this.state.airlineId > 0) {
                let diffInMs = Math.abs(this.state.puDateTime - new Date(this.state.puDateTime.toLocaleDateString() + " " + this.state.flightTime.toLocaleTimeString()));
                let minuteDiff = diffInMs / (1000 * 60);

                if (minuteDiff <= 0 || minuteDiff < 10) {
                    const isConfirm = await this.askScheduleTimeConfirm();
                    if (!isConfirm) {
                        return;
                    }
                }
            }
        }

        let paxCount = (parseInt(this.state.noOfPax | 0));
        //Pax count validation.
        let vehicleDetails = this.state.dropdownVehicleType.find(opt => opt.rowIndex == this.state.vehicleTypeId);
        if (paxCount > vehicleDetails.apiPaxNumber) {
            const isValid = await Swal.fire({
                title: "<small>Invalid Pax Count</small>",
                text: "Please enter pax count less than or equal to " + vehicleDetails.apiPaxNumber + " for " + vehicleDetails.apiVehicleTypeDescription + "!",
                icon: "info",
                dangerMode: false,
                width: "25%",
                allowOutsideClick: false
            });
            return;
        }
        //Customer Setting Validation.
        if (this.state.customerRateTax == null) {
            await this.getCustomerRateTaxSetting(this.state.pickupZoneId, this.state.dropoffZoneId, this.state.puDateTime, this.state.vehicleTypeId, paxCount, 0, this.state.isMeetGreet)
        }

        if (this.state.customerRateTax == null) {
            const isValid = await Swal.fire({
                title: "<small>Oops..</small>",
                text: "Customer setting not found!",
                icon: "warning",
                dangerMode: false,
                allowOutsideClick: false
            });
            return;
        }

        if (this.state.tripTypeId == "2") {
            const isConfirm = await this.askReturnTripConfirm();
            if (!isConfirm) {
                return;
            }
        }

        const isBookingConfirm = await this.askBookingConfirm(paxCount);
        if (!isBookingConfirm) {
            return;
        }

        if (this.state.rideId > 0) {
            this.props.submitFunction(true);
            await this.bookReservation();
        } else {
            this.props.submitFunction(true);
            //Duplicate reservation check.
            const isDuplicateFound = await this.checkDuplicateReservation(true);
            if (isDuplicateFound) {
                this.props.submitFunction(false);
            } else {
                await this.bookReservation();
            }
        }
    }

    async bookReservation() {
        let paxCount = (parseInt(this.state.noOfPax | 0));

        var pickupDate = [];
        if (this.state.tripTypeId === "3") {
            pickupDate = this.getRecurringPuDateArrayList();

        } else if (this.state.tripTypeId === "2") {
            var stpuDate = new Date(new Date(this.state.puDateTime).toLocaleDateString());
            pickupDate.push(((stpuDate.getMonth() + 1) < 10 ? "0" + (stpuDate.getMonth() + 1) : (stpuDate.getMonth() + 1)) + '/' + (stpuDate.getDate() < 10 ? "0" + stpuDate.getDate() : stpuDate.getDate()) + '/' + stpuDate.getFullYear());
        } else {
            for (var tripNo = 0; tripNo < parseInt(this.state.noOfTrip); tripNo++) {
                var stpuDate = new Date(new Date(this.state.puDateTime).toLocaleDateString());
                pickupDate.push(((stpuDate.getMonth() + 1) < 10 ? "0" + (stpuDate.getMonth() + 1) : (stpuDate.getMonth() + 1)) + '/' + (stpuDate.getDate() < 10 ? "0" + stpuDate.getDate() : stpuDate.getDate()) + '/' + stpuDate.getFullYear());
            }
        }

        if (pickupDate.length <= 0) {
            const isValid = await Swal.fire({
                title: "<small>Date Range</small>",
                text: "Selected days are not between pickup date and end date!",
                icon: "info",
                width: "25%",
                dangerMode: false,
                allowOutsideClick: false
            });
            this.props.submitFunction(false);
            return;
        }

        //Get Ride Type Zone Ids.
        let fromAreaId = 0, toAreaId = 0, pickupLocationType = "", dropoffLocationType = "";

        let puZone = this.state.dropdownZone.find(opt => opt.id == this.state.pickupZoneId);
        let doZone = this.state.dropdownZone.find(opt => opt.id == this.state.dropoffZoneId);

        if (puZone.zoneTypeId === 1 && doZone.zoneTypeId === 3) {
            fromAreaId = puZone.airportId;
            toAreaId = doZone.id;
            pickupLocationType = "AIRPORT";
            dropoffLocationType = "HOTEL";

        } else if (puZone.zoneTypeId === 3 && doZone.zoneTypeId === 1) {
            fromAreaId = puZone.id;
            toAreaId = doZone.airportId;
            pickupLocationType = "HOTEL";
            dropoffLocationType = "AIRPORT";
        } else if (puZone.zoneTypeId === 1 && doZone.zoneTypeId === 1) {
            fromAreaId = puZone.airportId;
            toAreaId = doZone.airportId;
            pickupLocationType = "AIRPORT";
            dropoffLocationType = "AIRPORT";
        } else {
            fromAreaId = puZone.id;
            toAreaId = doZone.id;
            pickupLocationType = "HOTEL";
            dropoffLocationType = "HOTEL";
        }

        let airlines = this.state.dropdownAirline.find(opt => opt.airLineId == this.state.airlineId);

        var newObject = new Object();
        newObject.reservationId = this.state.rideId <= 0 ? 0 : this.props.tripDetails.reservationId;
        newObject.rideId = this.state.rideId;

        newObject.rideTypeId = rideTypeId;
        newObject.fromAreaId = fromAreaId;
        newObject.toAreaId = toAreaId;
        newObject.paxCount = paxCount;
        newObject.paxCountReturn = 0;
        newObject.baggage = this.state.luggage;

        newObject.firstName = this.state.paxFirstName;
        newObject.lastName = this.state.paxLastName;
        newObject.firstNameReturn = "";
        newObject.lastNameReturn = "";

        if (parseInt(this.state.tripTypeId) == 2) {
            let paxCountReturn = (parseInt(this.state.noOfPaxReturn | 0));

            newObject.firstNameReturn = this.state.paxLastName;
            newObject.lastNameReturn = this.state.paxLastName;
            newObject.paxCountReturn = paxCountReturn;
        }
        newObject.countryCode = this.state.countryCodePhone;
        newObject.phoneNo = this.state.phoneNumber;
        newObject.cellCountryCode = this.state.countryCode;
        newObject.mobileNo = this.state.mobileNumber;

        newObject.customerId = parseInt(this.state.customer.id);
        newObject.airlineId = this.state.airlineId != null ? parseInt(this.state.airlineId) : 0;
        newObject.flightNumber = this.state.flightNumber;
        //newObject.flightTime = this.state.flightTime.toLocaleTimeString();
        newObject.flightTime = new Date(this.state.flightTime.getFullYear(), this.state.flightTime.getMonth(), this.state.flightTime.getDate(), this.state.flightHour, this.state.flightMinute).toLocaleTimeString();
        newObject.terminalType = this.state.terminalType != "" ? parseInt(this.state.terminalType) : 0;
        newObject.terminal = this.state.terminal;
        newObject.airlineIdReturn = this.state.airlineIdReturn != null ? parseInt(this.state.airlineIdReturn) : 0;
        newObject.flightNumberReturn = this.state.flightNumberReturn;
        //newObject.flightTimeReturn = this.state.flightTimeReturn.toLocaleTimeString();
        newObject.flightTimeReturn = new Date(this.state.flightTimeReturn.getFullYear(), this.state.flightTimeReturn.getMonth(), this.state.flightTimeReturn.getDate(), this.state.flightHourReturn, this.state.flightMinuteReturn).toLocaleTimeString();
        newObject.terminalTypeReturn = this.state.terminalTypeReturn != "" ? parseInt(this.state.terminalTypeReturn) : 0;
        newObject.terminalReturn = this.state.terminalReturn;
        newObject.email = this.state.email;
        newObject.poNumber = this.state.poNumber;
        newObject.isPickupGoogleBooking = this.state.isPickupGoogleBooking;
        newObject.pickupLocation = this.state.pickupLocation;
        newObject.pickupLocationType = pickupLocationType;
        newObject.pickupAddress = this.state.pickupAddress;
        newObject.pickupCity = this.state.pickupCity;
        newObject.pickupState = this.state.pickupState;
        newObject.pickupZipCode = this.state.pickupZipCode;
        newObject.pickupLatitude = this.state.pickupLatitude;
        newObject.pickupLongitude = this.state.pickupLongitude;
        newObject.pickupGeoZoneId = puZone != null ? puZone.id : 0;
        newObject.pickupIsHotel = puZone != null ? (puZone.zoneTypeId === 3 ? true : false) : false;

        newObject.isDropoffGoogleBooking = this.state.isDropoffGoogleBooking;
        newObject.dropoffLocation = this.state.dropoffLocation;
        newObject.dropoffLocationType = dropoffLocationType;
        newObject.dropoffAddress = this.state.dropoffAddress;
        newObject.dropoffCity = this.state.dropoffCity;
        newObject.dropoffState = this.state.dropoffState;
        newObject.dropoffZipCode = this.state.dropoffZipCode;
        newObject.dropoffLatitude = this.state.dropoffLatitude;
        newObject.dropoffLongitude = this.state.dropoffLongitude;
        newObject.dropoffGeoZoneId = doZone != null ? doZone.id : 0;
        newObject.dropoffIsHotel = doZone != null ? (doZone.zoneTypeId === 3 ? true : false) : false;

        newObject.apt = this.state.apt;

        newObject.serviceSourceId = parseInt(this.state.serviceSourceId);
        newObject.serviceSourceName = (parseInt(this.state.serviceSourceId) == 1) ? "In House" : ((parseInt(this.state.serviceSourceId) == 2) ? "Farm In" : "Farm Out");
        newObject.otherAffiliateId = parseInt(this.state.otherAffiliateId);
        newObject.isVipTrip = this.state.isVipTrip;
        newObject.isMeetGreet = this.state.isMeetGreet;
        newObject.isAccessible = this.state.isAccessible;
        newObject.isPriority = this.state.isPriorityTrip;
        newObject.pickupDateArray = pickupDate;
        newObject.pickupTime = this.state.puDateTime.toLocaleTimeString();
        newObject.pickupTimeReturn = this.state.puDateTimeReturn.toLocaleTimeString();
        newObject.pickupDateReturn = this.state.puDateTimeReturn.toLocaleDateString();
        newObject.apiVehicleTypeId = parseInt(this.state.vehicleTypeId);
        newObject.tripTypeId = parseInt(this.state.tripTypeId);
        newObject.specialInstruction = this.state.specialInstruction;
        newObject.permanentRemark = this.state.permanentRemark;
        newObject.pickupNotes = this.state.pickupNotes;
        newObject.billToNotes = this.state.billToNotes;

        newObject.orderByName = this.state.orderByName;
        newObject.orderByCountryCode = this.state.countryCodeOrderPhone;
        newObject.orderByPhone = this.state.phoneNumberOrder;
        newObject.orderByEmail = this.state.emailOrder;
        newObject.approxDropoffTime = this.state.doDateTime;
        newObject.approxTripDurationMin = parseInt(this.state.tripDuration);
        newObject.reservationModeId = parseInt(this.state.reservationModeId);
        newObject.vehicleId = parseInt(this.refs.vehicleDriverComponent.state.filterVehicleId);
        newObject.driverId = parseInt(this.refs.vehicleDriverComponent.state.filterDriverId);

        newObject.pilotCount = 0;
        newObject.crewCount = 0;
        newObject.flightAttandCount = 0;
        newObject.pilotCountReturn = 0;
        newObject.crewCountReturn = 0;
        newObject.flightAttandCountReturn = 0;

        newObject.rateId = this.state.customerRateTax.rateId;
        newObject.paymentTypeId = 1;
        newObject.voucherNo = "";
        newObject.voucherCompany = 0;
        newObject.chqBankName = "";
        newObject.chqNumber = "";
        newObject.chqDate = "";

        newObject.creditCardNumber = "";
        newObject.creditCardExpiry = "";
        newObject.cardType = "";

        newObject.transferTypeId = this.state.customerRateTax.transferTypeId;
        newObject.serviceTypeId = this.state.customerRateTax.serviceTypeId;

        newObject.rideAmount = parseFloat(this.state.customerRateTax.rate);
        newObject.tollAmnt = parseFloat(this.state.customerRateTax.tollAmount);
        newObject.tipAmnt = parseFloat(this.state.customerRateTax.tipAmount);
        newObject.meetGreetCharge = parseFloat(this.state.customerRateTax.meetGreetCharge);
        newObject.payToTourGuide = 0.00;
        newObject.tourGuideName = "";
        newObject.tourGuideCharge = 0.00;
        newObject.mgPayTo = 2 // 1 => Pay To Greeter, 2 => 100% to Company, 3 => Pay to Driver;
        newObject.meetGreetId = 0;
        newObject.meetGreetName = "";
        newObject.meetGreetAmount = 0.00;
        newObject.mgDriverComms = false;
        newObject.gratuity = "";
        newObject.stop = parseFloat(this.state.customerRateTax.stop);
        newObject.noOfStop = 0;
        newObject.wtTotal = 0.00;
        newObject.wtTime = 0;
        newObject.wt = parseFloat(this.state.customerRateTax.waitCharge);
        newObject.ot = 0.00;
        newObject.serviceFee = parseFloat(this.state.customerRateTax.serviceFee);
        newObject.fuelSurCharge = parseFloat(this.state.customerRateTax.fuelSurCharge);
        newObject.parking = parseFloat(this.state.customerRateTax.parking);
        newObject.portAuthority = parseFloat(this.state.customerRateTax.portAuthority);
        newObject.utilityTax = parseFloat(this.state.customerRateTax.utilityTax);
        newObject.wcTax = parseFloat(this.state.customerRateTax.wcTax);
        newObject.salesTax = parseFloat(this.state.customerRateTax.salesTax);
        newObject.countyTax = parseFloat(this.state.customerRateTax.countyTax);
        newObject.congestionTax = parseFloat(this.state.customerRateTax.congestionTax);
        newObject.extraAmnt = 0.00;
        newObject.extraAmntFor = "";
        newObject.gross = parseFloat(this.state.customerRateTax.fare);
        newObject.discount = parseFloat(this.state.customerRateTax.discountCharge);
        newObject.totalFare = parseFloat(this.state.customerRateTax.totalFare);
        newObject.billableFare = parseFloat(this.state.customerRateTax.totalFare);
        newObject.totalPaid = 0.00;
        newObject.chargeTo = "";
        newObject.agentName = this.state.customerRateTax.agentName;
        newObject.agentId = this.state.customerRateTax.agentId;
        newObject.agentCommission = parseFloat(this.state.customerRateTax.agentCommission);

        newObject.partialPay = false;
        newObject.rateLocked = false;
        newObject.currencyId = 0;
        newObject.exchangeRate = 0;
        newObject.totalTax = parseFloat(this.state.customerRateTax.portAuthority) + parseFloat(this.state.customerRateTax.utilityTax) + parseFloat(this.state.customerRateTax.wcTax) +
            parseFloat(this.state.customerRateTax.salesTax) + parseFloat(this.state.customerRateTax.countyTax) + parseFloat(this.state.customerRateTax.congestionTax);
        newObject.GratuityAmount = 0;

        newObject.tripChangesLogs = this.state.rideId <= 0 ? "" : this.getTripChangesDetails();

        try {
            this.props.submitFunction(true);

            const requestParams = getRequestParams('POST', newObject);
            const response = await fetch('reservation-api/book', requestParams);
            const data = await response.json();

            let strAlert = "";

            if (!data.isSuccess) {
                if (data.retValue == -2000) {
                    strAlert = data.resultMessage;
                } else {
                    strAlert = "Reservation failed due to server error.";
                }
            } else {
                if (this.state.rideId <= 0) {
                    strAlert = "Your confirmation number is: <br><br>";
                } else {
                    strAlert = "Your booking has been modify and updated successfully.<br><br>";
                }
                strAlert += "\t" + data.reservationId + "<br>";
                strAlert += "<br> Thank you for booking.";

                //if (data.tripResult.length > 1) {
                //    if (this.state.tripTypeId == 3) {
                //        strAlert = "Your confirmation number is: <br><br>";
                //        strAlert += "\t" + data.reservationId + "<br>";
                //    }
                //    else {
                //        strAlert = "Your confirmation number is: " + data.reservationId + ".<br><br>";
                //        strAlert += "below are the trip(s). <br><br>";
                //        for (var i = 0; i < data.tripResult.length; i++) {
                //            strAlert += "\t" + data.tripResult[i].tripId + "<br>";
                //        }
                //    }
                //    strAlert += "<br> Thank you for booking.";

                //} else {
                //    strAlert = "Your confirmation number is: <br><br>";

                //    strAlert += "\t" + data.reservationId + "<br>";

                //    strAlert += "<br> Thank you for booking.";
                //}
            }

            Swal.fire({
                icon: data.isSuccess ? 'success' : 'error',
                title: data.isSuccess ? 'Success' : 'Oops...',
                html: strAlert,
                width: '25%',
                allowOutsideClick: false
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (data.isSuccess) {
                    if (result.isConfirmed) {
                        this.props.submitFunction(false);
                        if (this.state.rideId <= 0) {
                            if (this.state.isCopy == true) {
                                if (this.state.isAttachment == true) {
                                    var rideArray = [];
                                    for (var i = 0; i < data.tripResult.length; i++) {
                                        rideArray.push(data.tripResult[i].tripId);
                                    }
                                    this.props.attachmentUpload(rideArray);

                                } else if (this.state.isSendEmail == true) {

                                    this.props.openEmailModal(this.state.puDateTime, data.tripResult[0].tripId, this.state.email)
                                } else {
                                    this.props.goToBackPage(); // method defined in BookingComponent
                                }
                            } else {
                                if (this.state.isAttachment == true) {
                                    var rideArray = [];
                                    for (var i = 0; i < data.tripResult.length; i++) {
                                        rideArray.push(data.tripResult[i].tripId);
                                    }
                                    this.props.attachmentUpload(rideArray);
                                } else if (this.state.isSendEmail == true) {

                                    this.props.openEmailModal(this.state.puDateTime, data.tripResult[0].tripId, this.state.email)
                                }
                                this.clearAllControls();
                            }
                        } else {
                            if (this.state.isAttachment == true) {
                                var rideArray = [];
                                rideArray.push(this.state.rideId);
                                this.props.attachmentUpload(rideArray);

                            } else {
                                this.props.goToBackPage(); // method defined in BookingComponent
                            }
                        }
                    }
                } else {
                    this.props.submitFunction(false);
                }
            })

        } catch (e) {
            this.props.submitFunction(false);
        }
    }

    getTripChangesDetails() {
        let changes = ""
        let rideTypeIOldd = parseInt(this.props.tripDetails.rideTypeId);

        let puZone = null, doZone = null;
        if (rideTypeIOldd == 1) {
            puZone = this.state.dropdownZone.find(opt => opt.airportId == this.props.tripDetails.puAreaId);
            doZone = this.state.dropdownZone.find(opt => opt.id == this.props.tripDetails.doAreaId);
        } else if (rideTypeIOldd == 2) {
            puZone = this.state.dropdownZone.find(opt => opt.id == this.props.tripDetails.puAreaId);
            doZone = this.state.dropdownZone.find(opt => opt.airportId == this.props.tripDetails.doAreaId);
        } else if (rideTypeIOldd == 3) {
            puZone = this.state.dropdownZone.find(opt => opt.airportId == this.props.tripDetails.puAreaId);
            doZone = this.state.dropdownZone.find(opt => opt.airportId == this.props.tripDetails.doAreaId);
        } else {
            puZone = this.state.dropdownZone.find(opt => opt.id == this.props.tripDetails.puAreaId);
            doZone = this.state.dropdownZone.find(opt => opt.id == this.props.tripDetails.doAreaId);
        }

        if (this.state.paxFirstName != this.props.tripDetails.firstName) {
            changes += "Pax first name changed from " + this.props.tripDetails.firstName + " to " + this.state.paxFirstName + ". "
        }
        if (this.state.paxLastName != this.props.tripDetails.lastName) {
            changes += "Pax last name changed from " + this.props.tripDetails.lastName + " to " + this.state.paxLastName + ". "
        }
        if (this.state.countryCodePhone != this.props.tripDetails.countryCode) {
            changes += "Country code changed from " + this.props.tripDetails.countryCode + " to " + this.state.countryCodePhone + ". "
        }
        if (this.state.phoneNumber != this.props.tripDetails.phoneNo) {
            changes += "Phone no changed from " + this.props.tripDetails.phoneNo + " to " + this.state.phoneNumber + ". "
        }
        if (this.state.countryCode != this.props.tripDetails.cellCountryCode) {
            changes += "Cell country code changed from " + this.props.tripDetails.cellCountryCode + " to " + this.state.countryCode + ". "
        }
        if (this.state.mobileNumber != this.props.tripDetails.mobileNo) {
            changes += "Mobile number changed from " + this.props.tripDetails.mobileNo + " to " + this.state.mobileNumber + ". "
        }
        if (this.state.email != this.props.tripDetails.emailId) {
            changes += "Email changed from " + this.props.tripDetails.emailId + " to " + this.state.email + ". "
        }
        if (this.state.poNumber != this.props.tripDetails.crewPo) {
            changes += "PO changed from " + this.props.tripDetails.crewPo + " to " + this.state.poNumber + ". "
        }

        if (this.state.pickupLocation != this.props.tripDetails.puLocationName) {
            changes += "Pikcup location changed from " + this.props.tripDetails.puLocationName + " to " + this.state.pickupLocation + ". "
        }
        if (parseInt(this.state.pickupZoneId) != parseInt(puZone != null ? puZone.id : 0)) {
            let puZoneNew = this.state.dropdownZone.find(opt => opt.id == parseInt(this.state.pickupZoneId));
            changes += "Pikcup zone changed from " + (puZone != null ? puZone.name : "") + " to " + puZoneNew.name + ". "
        }
        if (this.state.pickupAddress != this.props.tripDetails.puAddress) {
            changes += "Pikcup address changed from " + this.props.tripDetails.puAddress + " to " + this.state.pickupAddress + ". "
        }

        if (this.state.dropoffLocation != this.props.tripDetails.doLocationName) {
            changes += "Dropoff location changed from " + this.props.tripDetails.doLocationName + " to " + this.state.dropoffLocation + ". "
        }
        if (parseInt(this.state.dropoffZoneId) != parseInt(doZone != null ? doZone.id : 0)) {
            let doZoneNew = this.state.dropdownZone.find(opt => opt.id == parseInt(this.state.dropoffZoneId));
            changes += "Dropoff zone changed from " + (doZone != null ? doZone.name : "") + " to " + doZoneNew.name + ". "
        }
        if (this.state.dropoffAddress != this.props.tripDetails.doAddress) {
            changes += "Pikcup address changed from " + this.props.tripDetails.doAddress + " to " + this.state.dropoffAddress + ". "
        }
        if (parseInt(this.state.serviceSourceId) != parseInt(this.props.tripDetails.serviceSourceId)) {
            let newSource = parseInt(this.state.serviceSourceId) == 1 ? "In House" : (parseInt(this.state.serviceSourceId) == 2) ? "Farm In" : "Farm Out";
            let oldSource = parseInt(this.props.tripDetails.serviceSourceId) == 1 ? "In House" : (parseInt(this.props.tripDetails.serviceSourceId) == 2) ? "Farm In" : "Farm Out";

            changes += "Service source changed from " + oldSource + " to " + newSource + ". "
        }

        if (parseInt(this.state.otherAffiliateId) != parseInt(this.props.tripDetails.serviceSourceAffiliateId)) {
            if (parseInt(this.state.otherAffiliateId) > 0 && parseInt(this.props.tripDetails.serviceSourceAffiliateId) == 0) {
                let newSource = this.state.dropdownAffiliates.find(opt => opt.id == parseInt(this.state.otherAffiliateId));
                changes += "Source affiliate added " + newSource.name + ". "
            } else if (parseInt(this.state.otherAffiliateId) == 0 && parseInt(this.props.tripDetails.serviceSourceAffiliateId) > 0) {
                let oldSource = this.state.dropdownAffiliates.find(opt => opt.id == parseInt(this.props.tripDetails.serviceSourceAffiliateId));
                changes += "Source affiliate removed " + oldSource.name + ". "
            } else {
                let newSource = this.state.dropdownAffiliates.find(opt => opt.id == parseInt(this.state.otherAffiliateId));
                let oldSource = this.state.dropdownAffiliates.find(opt => opt.id == parseInt(this.props.tripDetails.serviceSourceAffiliateId));
                changes += "Source affiliate changed from " + oldSource.name + " to " + newSource.name + ". "
            }
        }

        if (this.state.apt != this.props.tripDetails.apt) {
            changes += "APT changed from " + this.props.tripDetails.apt + " to " + this.state.apt + ". "
        }

        let airlineId = parseInt((rideTypeId == 1 || rideTypeId == 3) ? this.props.tripDetails.puAirlineId : this.props.tripDetails.doAirlineId);
        if (parseInt(this.state.airlineId) != airlineId) {
            if (parseInt(this.state.airlineId) > 0 && airlineId == 0) {
                let newAirline = this.state.dropdownAirline.find(opt => opt.airLineId == parseInt(this.state.airlineId));
                changes += "Airline added " + newAirline.airLineName + ". "
            } else if (parseInt(this.state.airlineId) == 0 && airlineId > 0) {
                let oldAirline = this.state.dropdownAirline.find(opt => opt.airLineId == airlineId);
                changes += "Airline removed " + oldAirline.airLineName + ". "
            } else {
                let newAirline = this.state.dropdownAirline.find(opt => opt.airLineId == parseInt(this.state.airlineId));
                let oldAirline = this.state.dropdownAirline.find(opt => opt.airLineId == airlineId);
                changes += "Airline changed from " + oldAirline.airLineName + " to " + newAirline.airLineName + ". "
            }
        }

        let flightNo = (rideTypeIOldd == 1 || rideTypeIOldd == 3) ? this.props.tripDetails.puAirlineNo : this.props.tripDetails.doAirlineNo;
        if (this.state.flightNumber != flightNo) {
            changes += "Flight number changed from " + flightNo + " to " + this.state.flightNumber + ". "
        }

        let terminal = (rideTypeIOldd == 1 || rideTypeIOldd == 3) ? this.props.tripDetails.puFlightTerminal : this.props.tripDetails.doFlightTerminal;
        if (this.state.terminal != terminal) {
            changes += "Terminal changed from " + terminal + " to " + this.state.terminal + ". "
        }

        let terminalType = parseInt((rideTypeIOldd == 1 || rideTypeIOldd == 3) ? this.props.tripDetails.puTerminalTypeId : this.props.tripDetails.doTerminalTypeId);
        if (parseInt(this.state.terminalType) != terminalType) {
            if (parseInt(this.state.terminalType) > 0 && terminalType == 0) {
                let newTerminal = terminalTypeData.find(opt => opt.id == parseInt(this.state.terminalType));
                changes += "Terminal type added " + newTerminal.name + ". "
            } else if (parseInt(this.state.terminalType) == 0 && terminalType > 0) {
                let oldTerminal = terminalTypeData.find(opt => opt.id == terminalType);
                changes += "Terminal type removed " + oldTerminal.name + ". "
            } else {
                let newTerminal = terminalTypeData.find(opt => opt.id == parseInt(this.state.terminalType));
                let oldTerminal = terminalTypeData.find(opt => opt.id == terminalType);
                changes += "Terminal type changed from " + oldTerminal.name + " to " + newTerminal.name + ". "
            }
        }
        let flightDate = (rideTypeIOldd == 1 || rideTypeIOldd == 3) ? new Date(this.props.tripDetails.puFlightDateTime) : new Date(this.props.tripDetails.doFlightDateTime)
        if ((this.state.puDateTime.toLocaleDateString() + " " + this.state.flightTime.toLocaleTimeString()) != (flightDate.toLocaleDateString() + " " + flightDate.toLocaleTimeString())) {
            changes += "Flight date changed from " + flightDate.toLocaleDateString() + " " + flightDate.toLocaleTimeString() + " to " + this.state.puDateTime.toLocaleDateString() + " " + this.state.flightTime.toLocaleTimeString() + ". "
        }

        if (this.state.orderByName != this.props.tripDetails.orderBy) {
            changes += "Order by changed from " + this.props.tripDetails.orderBy + " to " + this.state.orderByName + ". "
        }
        if (this.state.countryCodeOrderPhone != this.props.tripDetails.orderByCountryCode) {
            changes += "Order by country code changed from " + this.props.tripDetails.orderByCountryCode + " to " + this.state.countryCodeOrderPhone + ". "
        }
        if (this.state.phoneNumberOrder != this.props.tripDetails.orderPhone) {
            changes += "Order by phone no changed from " + this.props.tripDetails.orderPhone + " to " + this.state.phoneNumberOrder + ". "
        }
        if (this.state.emailOrder != this.props.tripDetails.orderByEmail) {
            changes += "Order by email changed from " + this.props.tripDetails.orderByEmail + " to " + this.state.emailOrder + ". "
        }

        if (parseInt(this.state.reservationModeId) != parseInt(this.props.tripDetails.reservationModeId)) {
            let newMode = this.state.dropdownReservationMode.find(opt => opt.id == parseInt(this.state.reservationModeId));
            let oldMode = this.state.dropdownReservationMode.find(opt => opt.id == parseInt(this.props.tripDetails.reservationModeId));
            changes += "Source affiliate changed from " + oldMode.name + " to " + newMode.name + ". "
        }

        if ((this.state.puDateTime.toLocaleDateString() + " " + this.state.puDateTime.toLocaleTimeString()) != (new Date(this.props.tripDetails.puDateTime).toLocaleDateString() + " " + new Date(this.props.tripDetails.puDateTime).toLocaleTimeString())) {
            changes += "Pickup date changed from " + new Date(this.props.tripDetails.puDateTime).toLocaleDateString() + " " + new Date(this.props.tripDetails.puDateTime).toLocaleTimeString() + " to " + this.state.puDateTime.toLocaleDateString() + " " + this.state.puDateTime.toLocaleTimeString() + ". "
        }
        if (parseInt(this.state.vehicleTypeId) != parseInt(this.props.tripDetails.apiVehicleTypeId)) {
            let newVehicleType = this.state.dropdownVehicleType.find(opt => opt.rowIndex == parseInt(this.state.vehicleTypeId));
            let oldVehicleType = this.state.dropdownVehicleType.find(opt => opt.rowIndex == parseInt(this.props.tripDetails.apiVehicleTypeId));
            changes += "Vehicle type changed from " + oldVehicleType.apiVehicleTypeDescription + " to " + newVehicleType.apiVehicleTypeDescription + ". "
        }
        if (parseInt(this.state.noOfPax) != parseInt(this.props.tripDetails.adultNo)) {
            changes += "Pax count changed from " + this.props.tripDetails.adultNo + " to " + this.state.noOfPax + ". "
        }
        if (parseInt(this.state.luggage) != parseInt(this.props.tripDetails.baggageCount)) {
            changes += "Luggage count changed from " + this.props.tripDetails.baggageCount + " to " + this.state.luggage + ". "
        }

        if (this.state.isAccessible != this.props.tripDetails.isAccessible) {
            if (this.state.isAccessible == true) {
                changes += "Accessible changed from NO to YES. ";
            } else {
                changes += "Accessible changed from YES to NO. ";
            }
        }
        if (this.state.isVipTrip != (this.props.tripDetails.vip === 'Y' ? true : false)) {
            if (this.state.isVipTrip == true) {
                changes += "VIP changed from NO to YES. ";
            } else {
                changes += "VIP changed from YES to NO. ";
            }
        }
        if (this.state.isPriorityTrip != (this.props.tripDetails.priority === 'Y' ? true : false)) {
            if (this.state.isPriorityTrip == true) {
                changes += "Priority changed from NO to YES. ";
            } else {
                changes += "Priority changed from YES to NO. ";
            }
        }
        if (this.state.isMeetGreet != this.props.tripDetails.isMgRequired) {
            if (this.state.isMeetGreet == true) {
                changes += "MeetGreet changed from NO to YES. ";
            } else {
                changes += "MeetGreet changed from YES to NO. ";
            }
        }
        if (this.state.specialInstruction != this.props.tripDetails.specialInstruction) {
            changes += "Spcl Inst. changed from " + this.props.tripDetails.specialInstruction + " to " + this.state.specialInstruction + ". "
        }
        if (this.state.permanentRemark != this.props.tripDetails.permanentRemark) {
            changes += "Permanent remark. changed from " + this.props.tripDetails.permanentRemark + " to " + this.state.permanentRemark + ". "
        }
        if (this.state.pickupNotes != this.props.tripDetails.pickUpNotes) {
            changes += "Pickup notes changed from " + this.props.tripDetails.pickUpNotes + " to " + this.state.pickupNotes + ". "
        }
        if (this.state.billToNotes != this.props.tripDetails.billToNotes) {
            changes += "Bill notes. changed from " + this.props.tripDetails.billToNotes + " to " + this.state.billToNotes + ". "
        }

        if (changes === "") {
            changes = "Ride updated without any changes."
        }

        return changes;
    }

    clearAllControls() {
        rideTypeId = 0;
        this.setState({
            rideId: 0,
            paxFirstName: '',
            paxLastName: '',
            countryCodePhone: '1',
            phoneNumber: '',
            countryCode: '1',
            mobileNumber: '',
            email: '',
            isSendEmail: false,
            isPickupGoogleBooking: false,
            pickupLocation: "",
            pickupZoneId: 0,
            pickupAddress: "",
            pickupAddressType: "",
            pickupCity: "",
            pickupState: "",
            pickupZipCode: "",
            pickupLatitude: 0,
            pickupLongitude: 0,
            apt: '',
            isDropoffGoogleBooking: false,
            dropoffLocation: "",
            dropoffZoneId: 0,
            dropoffAddress: "",
            dropoffAddressType: "",
            dropoffCity: "",
            dropoffState: "",
            dropoffZipCode: "",
            dropoffLatitude: 0,
            dropoffLongitude: 0,
            serviceSourceId: 1,
            otherAffiliateId: 0,
            airlineId: this.props.customer.airlineId,
            flightNumber: '',
            terminalType: 1,
            terminal: '',
            flightTime: currentTime,
            flightHour: currentHour,
            flightMinute: currentMinute,
            flightHourReturn: currentHour,
            flightMinuteReturn: currentMinute,

            airlineIdReturn: this.props.customer.airlineId,
            flightNumberReturn: '',
            terminalTypeReturn: 0,
            terminalReturn: '',
            flightTimeReturn: currentTime,

            orderByName: '',
            countryCodeOrderPhone: '',
            phoneNumberOrder: '',
            emailOrder: '',
            reservationModeId: 0,
            puDateTime: currentTime,
            puDateTimeReturn: currentTime,

            doDateTime: currentTime,
            tripDuration: 0,
            vehicleTypeId: 0,
            noOfPax: 1,
            noOfPaxReturn: 1,
            luggage: 0,
            noOfTrip: 1,
            isAccessible: false,
            isVipTrip: false,
            isPriorityTrip: false,
            isMeetGreet: false,
            tripTypeId: "1",
            recurringTypeId: 2,
            recurringEndDate: "",
            selectedDays: [],
            isWeekSun: false,
            isWeekMon: false,
            isWeekTue: false,
            isWeekWed: false,
            isWeekThu: false,
            isWeekFri: false,
            isWeekSat: false,
            isMonthSun: false,
            isMonthMon: false,
            isMonthTue: false,
            isMonthWed: false,
            isMonthThu: false,
            isMonthFri: false,
            isMonthSat: false,
            specialInstruction: "",
            permanentRemark: "",
            pickupNotes: "",
            billToNotes: "",
            customerRateTax: null,
            loadPanelVisible: false,
            isCopy: false,
            isAttachment: false,
            errors: {
                paxFirstName: "",
                paxLastName: "",
                countryCode: "",
                mobileNumber: "",
                email: "",
                pickupLocation: "",
                pickupZoneId: "",
                dropoffLocation: "",
                dropoffZoneId: "",
                otherAffiliateId: "",
                puDateTime: "",
                puDateTimeReturn: "",
                doDateTime: "",
                flightTime: "",
                flightNumber: "",
                terminalType: "",
                flightNumberReturn: "",
                terminalTypeReturn: "",
                flightTimeReturn: "",
                orderByName: "",
                reservationModeId: "",
                vehicleTypeId: "",
                noOfTrip: "",
                noOfPax: "",
                noOfPaxReturn: "",
                recurringEndDate: "",
                recurring: "",
            }
        });

        this.refs.vehicleDriverComponent.state.filterDriverId = 0;
        this.refs.vehicleDriverComponent.state.filterVehicleId = 0;
    }

    getRideTypeId(fromZId, toZId) {
        rideTypeId = 0;
        this.setState({ isMeetGreet: false });

        if (fromZId > 0 && toZId > 0) {
            let puZone = this.state.dropdownZone.find(opt => opt.id == fromZId);
            let doZone = this.state.dropdownZone.find(opt => opt.id == toZId);

            if (puZone.zoneTypeId === 1 && doZone.zoneTypeId === 3) {
                this.setState({ isMeetGreet: false });
                rideTypeId = 1;
            } else if (puZone.zoneTypeId === 3 && doZone.zoneTypeId === 1) {
                this.setState({ isMeetGreet: false });
                rideTypeId = 2;
            } else if (puZone.zoneTypeId === 1 && doZone.zoneTypeId === 1) {
                this.setState({ isMeetGreet: false });
                rideTypeId = 3;
            } else {
                this.setState({ isMeetGreet: false });
                rideTypeId = 13;
            }
        }

    }

    async getCustomerRateTaxSetting(fromZId, toZId, puDateTime, vTypeId, pCount, hNumber, bMeetGreet) {
        try {

            if (fromZId > 0 && toZId > 0 && puDateTime != '' && vTypeId > 0 && pCount > 0) {

                let fromAirId = 0, toAirId = 0, fromZoneId = 0, toZoneId = 0;

                let puZone = this.state.dropdownZone.find(opt => opt.id == fromZId);
                let doZone = this.state.dropdownZone.find(opt => opt.id == toZId);

                if (puZone.zoneTypeId === 1 && doZone.zoneTypeId === 3) {
                    fromAirId = puZone.airportId;
                    toZoneId = doZone.id;
                } else if (puZone.zoneTypeId === 3 && doZone.zoneTypeId === 1) {
                    fromZoneId = puZone.id;
                    toAirId = doZone.airportId;
                } else if (puZone.zoneTypeId === 1 && doZone.zoneTypeId === 1) {
                    fromAirId = puZone.airportId;
                    toAirId = doZone.airportId;
                } else {
                    fromZoneId = puZone.id;
                    toZoneId = doZone.id;
                }

                var newObject = new Object();
                newObject.RideTypeId = rideTypeId;
                newObject.FromAirportId = fromAirId;
                newObject.ToAirportId = toAirId;
                newObject.FromZoneId = fromZoneId;
                newObject.ToZoneId = toZoneId;
                newObject.PickUpDateTime = puDateTime.toLocaleDateString() + " " + puDateTime.toLocaleTimeString();
                newObject.ApiVehicleTypeId = vTypeId;
                newObject.NoOfPax = pCount;
                newObject.RateId = 0;
                newObject.IsMgRequired = bMeetGreet;
                let szAPT = "0";
                let dcHours = 0;
                if (this.state.apt.length > 0) {
                    szAPT = this.state.apt.trim();
                    if (szAPT.startsWith("AD")) {
                        let szSplit = [];
                        szSplit = szAPT.split(" ");
                        if (szSplit.length > 0) {
                            if (szSplit.length < 1) {

                            }
                            else if (szSplit[1] == null) {

                            }
                            else {
                                dcHours = parseInt(szSplit[1]);
                            }
                        }
                    }
                }

                newObject.NoOfHours = dcHours;

                const requestParams = getRequestParams('POST', newObject);
                const response = await fetch('customer-api/' + this.state.customer.id + '/rate-tax-setting', requestParams);
                const data = await response.json();

                this.setState({
                    customerRateTax: data
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    getRecurringPuDateArrayList() {
        var puDateArrayList = [];

        if (this.state.recurringTypeId == 1) {
            puDateArrayList = this.getDailyRecurringDays();
        } else if (this.state.recurringTypeId == 2) {
            puDateArrayList = this.getWeeklyRecurringDays();
        } else if (this.state.recurringTypeId == 3) {
            puDateArrayList = this.getMonthlyRecurringDays();
        }

        return puDateArrayList;
    }

    diffDays(d1, d2) {
        var ndays;
        var tv1 = d1.valueOf();  // msec since 1970
        var tv2 = d2.valueOf();

        ndays = (tv2 - tv1) / 1000 / 86400;
        ndays = Math.round(ndays - 0.5);
        return ndays;
    }

    getDailyRecurringDays() {
        var finalArrayList = [];

        var stpuDate = new Date(new Date(this.state.puDateTime).toLocaleDateString());
        var enDate = new Date(new Date(this.state.recurringEndDate).toLocaleDateString());
        var dayscount = this.diffDays(stpuDate, enDate) + 1;

        var chkPuDay = [];
        chkPuDay = this.getDaysOfDailyRec();
        for (var day = 1; day <= dayscount; day++) {
            for (var scount = 0; scount < chkPuDay.length; scount++) {
                if ((stpuDate.toLocaleDateString()) === chkPuDay[scount]) {
                    finalArrayList.push(((stpuDate.getMonth() + 1) < 10 ? "0" + (stpuDate.getMonth() + 1) : (stpuDate.getMonth() + 1)) + '/' + (stpuDate.getDate() < 10 ? "0" + stpuDate.getDate() : stpuDate.getDate()) + '/' + stpuDate.getFullYear());
                }
            }
            stpuDate.setDate(stpuDate.getDate() + 1);
        }

        return finalArrayList;
    }

    getWeeklyRecurringDays() {
        var finalArrayList = [];

        var stpuDate = new Date(new Date(this.state.puDateTime).toLocaleDateString());
        var enDate = new Date(new Date(this.state.recurringEndDate).toLocaleDateString());

        var dayscount = this.diffDays(stpuDate, enDate) + 1;

        var chkPuDay = [];
        chkPuDay = this.getDaysOfWeeklyRec();
        for (var day = 1; day <= dayscount; day++) {
            for (var scount = 0; scount < chkPuDay.length; scount++) {
                if (stpuDate.getDay() == chkPuDay[scount].toString()) {
                    finalArrayList.push(((stpuDate.getMonth() + 1) < 10 ? "0" + (stpuDate.getMonth() + 1) : (stpuDate.getMonth() + 1)) + '/' + (stpuDate.getDate() < 10 ? "0" + stpuDate.getDate() : stpuDate.getDate()) + '/' + stpuDate.getFullYear());
                }
            }
            stpuDate.setDate(stpuDate.getDate() + 1);
        }
        return finalArrayList;
    }

    getMonthlyRecurringDays() {
        var finalArrayList = [];

        var stpuDate = new Date(new Date(this.state.puDateTime).toLocaleDateString());
        var enDate = new Date(new Date(this.state.recurringEndDate).toLocaleDateString());

        var dayscount = this.diffDays(stpuDate, enDate) + 1;
        var chkPuDay = [];
        chkPuDay = this.getDaysOfMonthlyRec();
        for (var day = 1; day <= dayscount; day++) {
            for (var scount = 0; scount < chkPuDay.length; scount++) {
                if (stpuDate.getDay() == chkPuDay[scount].toString()) {
                    finalArrayList.push(((stpuDate.getMonth() + 1) < 10 ? "0" + (stpuDate.getMonth() + 1) : (stpuDate.getMonth() + 1)) + '/' + (stpuDate.getDate() < 10 ? "0" + stpuDate.getDate() : stpuDate.getDate()) + '/' + stpuDate.getFullYear());
                }
            }
            stpuDate.setDate(stpuDate.getDate() + 1);
        }
        return finalArrayList;
    }

    getDaysOfDailyRec() {
        var chkPuDate = [];

        var allDays = [];
        allDays = this.state.selectedDays;
        for (var i = 0; i < allDays.length; i++) {
            chkPuDate.push(allDays[i].toLocaleDateString());
        }

        return chkPuDate;
    }

    getDaysOfWeeklyRec() {
        var chkPuDays = [];
        if (this.state.isWeekMon === true) {
            chkPuDays.push(1);
        }
        if (this.state.isWeekTue === true) {
            chkPuDays.push(2);
        }
        if (this.state.isWeekWed === true) {
            chkPuDays.push(3);
        }
        if (this.state.isWeekThu === true) {
            chkPuDays.push(4);
        }
        if (this.state.isWeekFri === true) {
            chkPuDays.push(5);
        }
        if (this.state.isWeekSat === true) {
            chkPuDays.push(6);
        }
        if (this.state.isWeekSun === true) {
            chkPuDays.push(0);
        }

        return chkPuDays;
    }

    getDaysOfMonthlyRec() {
        var chkPuMonths = [];
        if (this.state.isMonthMon === true) {
            chkPuMonths.push(1);
        }
        if (this.state.isMonthTue === true) {
            chkPuMonths.push(2);
        }
        if (this.state.isMonthWed === true) {
            chkPuMonths.push(3);
        }
        if (this.state.isMonthThu === true) {
            chkPuMonths.push(4);
        }
        if (this.state.isMonthFri === true) {
            chkPuMonths.push(5);
        }
        if (this.state.isMonthSat === true) {
            chkPuMonths.push(6);
        }
        if (this.state.isMonthSun === true) {
            chkPuMonths.push(0);
        }

        return chkPuMonths;
    }

    render() {
        const customerType = this.props.customer != null ? this.props.customer.customerType : "";
        const filterPassedTime = time => {
            const currentDate = currentTime;
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }
        const inputPropsPickup = {
            placeholder: 'Enter pickup location.',
            value: this.state.pickupLocation,
            onChange: this.onChangePickupLocation,
            onBlur: this.onBlurPickupLocation,
        };
        const inputPropsDropoff = {
            placeholder: 'Enter dropoff location.',
            value: this.state.dropoffLocation,
            onChange: this.onChangeDropoffLocation,
            onBlur: this.onBlurDropoffLocation,
        };

        console.log("Contract render called");
        return (
            <div className="content" >
                <div className="rez-container">
                    <div className="card main-rez-card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-left">
                                    <div className="card sub-rez-card">
                                        <div className="card-header">
                                            <h5 className="card-title m-0">Customer Details -  <i>{customerType}</i></h5>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                    <div className="form-group">
                                                        <label>Passenger First Name <span className="text-danger">*</span></label>
                                                        <input type="text" className="form-control form-control-sm" maxLength={50} value={this.state.paxFirstName} onChange={this.handleInputChange} name="paxFirstName" />
                                                        {this.state.errors["paxFirstName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["paxFirstName"]}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                    <div className="form-group">
                                                        <label>Passenger Last Name <span className="text-danger">*</span></label>
                                                        <input type="text" className="form-control form-control-sm" maxLength={50} value={this.state.paxLastName} onChange={this.handleInputChange} name="paxLastName" />
                                                        {this.state.errors["paxLastName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["paxLastName"]}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>Code</label>
                                                                <input type="text" className="form-control form-control-sm" name="countryCodePhone" placeholder="+1" maxLength="2" title="Country Code" value={this.state.countryCodePhone} onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                                                            <div className="form-group">
                                                                <label>Phone</label>
                                                                <NumberFormat className="form-control form-control-sm" name="phoneNumber" placeholder="000-000-0000" title="Phone Number" value={this.state.phoneNumber} onChange={this.handleInputChange} format="###-###-####" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>Code <span className="text-red">*</span></label>
                                                                <input type="text" className="form-control form-control-sm" name="countryCode" placeholder="+1" maxLength="2" title="Country Code" value={this.state.countryCode} onChange={this.handleInputChange} />
                                                                {this.state.errors["countryCode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["countryCode"]}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                                                            <div className="form-group">
                                                                <label>Mobile Number <span className="text-danger">*</span></label>
                                                                <NumberFormat className="form-control form-control-sm" name="mobileNumber" placeholder="(000) 000-0000" title="Mobile Number" value={this.state.mobileNumber} onChange={this.handleInputChange} format="(###) ###-####" />
                                                                {this.state.errors["mobileNumber"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["mobileNumber"]}</span>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                    <div className="form-group">
                                                        <label>Email</label>
                                                        <input type="text" className="form-control form-control-sm" name="email" placeholder="test@domain.com" maxLength="100" title="Email" value={this.state.email} onChange={this.handleInputChange} />
                                                        {this.state.errors["email"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["email"]}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                    <div className="form-group">
                                                        <label>PO / Client Ref #</label>
                                                        <input type="text" className="form-control form-control-sm" placeholder="" name="poNumber" maxLength="100" title="PO / Client Ref #" value={this.state.poNumber} onChange={this.handleInputChange} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                                                    <div className="form-group">
                                                        <label className="btn-block">&nbsp;</label>
                                                        <button type="button" className="btn btn-default btn-sm mt-1"><img src="../../dist/img/scanner.png" /></button>
                                                    </div>
                                                </div>

                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                    <div className="form-group">
                                                        <label>Pax# <span className="text-danger">*</span></label>
                                                        <NumericInput className="form-control form-control-sm" name="noOfPax" title="Number of Pax" value={this.state.noOfPax} min={1} max={100} onChange={(value) => { this.handlePaxCountChange(value) }} />
                                                        {this.state.errors["noOfPax"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["noOfPax"]}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-1 col-md-1 col-sm-2 col-xs-2">
                                                    <div className="form-group">
                                                        <label>Bag#</label>
                                                        <NumericInput className="form-control form-control-sm" name="luggage" title="Number of luggage" value={this.state.luggage} min={0} max={20} onChange={(value) => { this.setState({ luggage: value }) }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="card sub-rez-card">
                                        <div className="card-header">
                                            <h5 className="card-title m-0">Pick-up and Drop Off Details</h5>
                                        </div>
                                        <div className="card-body">
                                            <div className="form-group custom-control custom-switch swicth-group-checkbox">
                                                <input type="checkbox" className="custom-control-input" onChange={this.handlePickupToggleChange} id="customSwitchPickup" />
                                                <label className="custom-control-label" style={{ paddingTop: 5 + "px" }} htmlFor="customSwitchPickup">Add Google Address</label>
                                            </div>
                                            <div className="row" style={{ display: this.state.isPickupGoogleBooking ? 'none' : 'flex' }}>
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                    <div className="form-group">
                                                        <label>Pick-up Location <span className="text-danger">*</span></label>
                                                        <Autosuggest
                                                            name="pickupLocation"
                                                            focusInputOnSuggestionClick={true}
                                                            suggestions={this.state.pickupSuggestions}
                                                            onSuggestionsFetchRequested={this.onPickupSuggestionsFetchRequested}
                                                            onSuggestionsClearRequested={this.onPickupSuggestionsClearRequested}
                                                            getSuggestionValue={this.getPickupSuggestionValue}
                                                            renderSuggestion={renderSuggestion}
                                                            shouldRenderSuggestions={shouldRenderSuggestions}
                                                            inputProps={inputPropsPickup}
                                                            highlightFirstSuggestion={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                    <div className="form-group">
                                                        <label>Pick-up Address</label>
                                                        <input type="text" className="form-control form-control-sm" placeholder="" name="pickupAddress" maxLength="150" title="Pickup Address" value={this.state.pickupAddress} onChange={this.handleInputChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ display: this.state.isPickupGoogleBooking ? 'flex' : 'none' }}>
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <div className="form-group">
                                                        <label>Pick-up Location & Address <span className="text-danger">*</span></label>
                                                        <input type="text" className="form-control form-control-sm" id="pickupGoogle" placeholder="'Type' slowly to search location" maxLength="150" title="Pickup Location & Address" />
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.errors["pickupLocation"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["pickupLocation"]}</span>}

                                            <div className="row">
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                    <div className="form-group">
                                                        <label>APT &nbsp;<i className="fas fa-info-circle text-info" data-tip data-for="aptTooltip"></i></label>
                                                        <input type="text" className="form-control form-control-sm" placeholder="" name="apt" maxLength="6" title="APT" value={this.state.apt} onChange={this.handleInputChange} />
                                                        <ReactTooltip id="aptTooltip" place="top" effect="solid"> <ul>
                                                            <li>T - One way Transfer</li>
                                                            <li>OT - Other State Transfer</li>
                                                            <li>AD - As Directive ( Hourly Basis) e.g. AD 15, AD 5</li>
                                                            <li>MUL - Multiday or Many day Tour Transfer e.g. MUL 15 day Tour</li>
                                                        </ul></ReactTooltip>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                    <div className="form-group">
                                                        <label>Pick-up Zone <span className="text-danger">*</span></label>
                                                        <SelectBox
                                                            dataSource={this.state.dropdownZone.length > 0 ? this.state.dropdownZone : []}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            placeholder="Select pick-up zone"
                                                            name="pickupZoneId"
                                                            defaultValue={0}
                                                            value={this.state.pickupZoneId}
                                                            displayExpr='name'
                                                            valueExpr='id'
                                                            onSelectionChanged={this.handlePickupZoneChange}
                                                            showClearButton={true}
                                                            disabled={false}
                                                            searchEnabled={true}
                                                        />
                                                        {this.state.errors["pickupZoneId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["pickupZoneId"]}</span>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group custom-control custom-switch swicth-group-checkbox">
                                                <input type="checkbox" className="custom-control-input" onChange={this.handleDropoffToggleChange} id="customSwitchDropoff" />
                                                <label className="custom-control-label" style={{ paddingTop: 5 + "px" }} htmlFor="customSwitchDropoff">Add Google Address</label>
                                            </div>
                                            <div className="row" style={{ display: this.state.isDropoffGoogleBooking ? 'none' : 'flex' }}>
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                    <div className="form-group">
                                                        <label>Drop Off Location <span className="text-danger">*</span></label>
                                                        <Autosuggest
                                                            name="dropoffLocation"
                                                            focusInputOnSuggestionClick={true}
                                                            suggestions={this.state.dropoffSuggestions}
                                                            onSuggestionsFetchRequested={this.onDropoffSuggestionsFetchRequested}
                                                            onSuggestionsClearRequested={this.onDropoffSuggestionsClearRequested}
                                                            getSuggestionValue={this.getDropoffSuggestionValue}
                                                            renderSuggestion={renderSuggestion}
                                                            shouldRenderSuggestions={shouldRenderSuggestions}
                                                            inputProps={inputPropsDropoff}
                                                            highlightFirstSuggestion={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                    <div className="form-group">
                                                        <label>Drop Off Address</label>
                                                        <input type="text" className="form-control form-control-sm" placeholder="" name="dropoffAddress" maxLength="150" title="Dropoff Address" value={this.state.dropoffAddress} onChange={this.handleInputChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ display: this.state.isDropoffGoogleBooking ? 'flex' : 'none' }}>
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <div className="form-group">
                                                        <label>Drop-off Location & Address <span className="text-danger">*</span></label>
                                                        <input type="text" className="form-control form-control-sm" id="dropoffGoogle" placeholder="'Type' slowly to search location" maxLength="150" title="Dropoff Location & Address" />
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.errors["dropoffLocation"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["dropoffLocation"]}</span>}

                                            <div className="row">
                                                <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                    <div className="form-group">
                                                        <label>Drop Off Zone <span className="text-danger">*</span></label>
                                                        <SelectBox
                                                            dataSource={this.state.dropdownZone.length > 0 ? this.state.dropdownZone : []}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            placeholder="Select drop off zone"
                                                            name="dropoffZoneId"
                                                            defaultValue={0}
                                                            value={this.state.dropoffZoneId}
                                                            displayExpr='name'
                                                            valueExpr='id'
                                                            onSelectionChanged={this.handleDropoffZoneChange}
                                                            showClearButton={true}
                                                            disabled={false}
                                                            searchEnabled={true}
                                                        />
                                                        {this.state.errors["dropoffZoneId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["dropoffZoneId"]}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card sub-rez-card">
                                        <div className="card-header">
                                            <h5 className="card-title m-0">Flight Details</h5>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                    <div className="form-group">
                                                        <label>Airline Name</label>
                                                        <SelectBox
                                                            dataSource={this.state.dropdownAirline.length > 0 ? this.state.dropdownAirline : []}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            placeholder="Select airline"
                                                            name="airlineId"
                                                            defaultValue={0}
                                                            value={this.state.airlineId}
                                                            displayExpr='airLineName'
                                                            valueExpr='airLineId'
                                                            onSelectionChanged={this.handleAirlineChange}
                                                            showClearButton={true}
                                                            disabled={false}
                                                            searchEnabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                    <div className="form-group">
                                                        <label>Flight Number</label>
                                                        <input type="text" className="form-control form-control-sm" maxLength={25} value={this.state.flightNumber} onChange={this.handleInputChange} name="flightNumber" />
                                                        {this.state.errors["flightNumber"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["flightNumber"]}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                    <div className="form-group">
                                                        <label>Terminal Type</label>
                                                        <select className="form-control form-control-sm" name="terminalType" value={this.state.terminalType} onChange={this.handleInputChange} >
                                                            <option value="0">Select Terminal Type</option>
                                                            {
                                                                terminalTypeData &&
                                                                terminalTypeData.map((item, i) =>
                                                                    (<option key={i} value={item.id}>{item.name}</option>))
                                                            }
                                                        </select>
                                                        {this.state.errors["terminalType"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["terminalType"]}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                    <div className="form-group">
                                                        <label>Terminal</label>
                                                        <input type="text" className="form-control form-control-sm" maxLength={25} value={this.state.terminal} onChange={this.handleInputChange} name="terminal" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card sub-rez-card">
                                        <div className="card-header">
                                            <h5 className="card-title m-0">Other Details</h5>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                    <div className="form-group">
                                                        <label>Order By <span className="text-danger">*</span></label>
                                                        <input type="text" className="form-control form-control-sm" maxLength={50} value={this.state.orderByName} onChange={this.handleInputChange} name="orderByName" />
                                                        {this.state.errors["orderByName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["orderByName"]}</span>}
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>Code</label>
                                                                <input type="text" className="form-control form-control-sm" name="countryCodeOrderPhone" placeholder="+1" maxLength="2" title="Country Code" value={this.state.countryCodeOrderPhone} onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                                                            <div className="form-group">
                                                                <label>Phone</label>
                                                                <NumberFormat className="form-control form-control-sm" name="phoneNumberOrder" placeholder="000-000-0000" title="Phone Number" value={this.state.phoneNumberOrder} onChange={this.handleInputChange} format="###-###-####" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                    <div className="form-group">
                                                        <label>Order by Email</label>
                                                        <input type="text" className="form-control form-control-sm" name="emailOrder" placeholder="test@domain.com" maxLength="100" title="Email" value={this.state.emailOrder} onChange={this.handleInputChange} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                    <div className="form-group">
                                                        <label htmlFor="">Reservation Mode <span className="text-danger">*</span></label>
                                                        <SelectBox
                                                            dataSource={this.state.dropdownReservationMode.length > 0 ? this.state.dropdownReservationMode : []}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            placeholder="Select rez mode"
                                                            name="reservationModeId"
                                                            defaultValue={0}
                                                            value={this.state.reservationModeId}
                                                            displayExpr='name'
                                                            valueExpr='id'
                                                            onSelectionChanged={this.handleReservationModeChange}
                                                            showClearButton={true}
                                                            disabled={false}
                                                            searchEnabled={true}
                                                        />
                                                        {this.state.errors["reservationModeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["reservationModeId"]}</span>}

                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                    <div className="form-group">
                                                        <label htmlFor="">Service Source</label>
                                                        <select className="form-control form-control-sm" name="serviceSourceId" value={this.state.serviceSourceId} onChange={this.handleInputChange}>
                                                            <option value="1">In House</option>
                                                            <option value="2">Farm In</option>
                                                            <option value="3">Farm Out</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                {(this.state.serviceSourceId != 1) && <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                    <div className="form-group">
                                                        <label htmlFor="">Affiliate <span className="text-danger">*</span></label>
                                                        <SelectBox
                                                            dataSource={this.state.dropdownAffiliates.length > 0 ? this.state.dropdownAffiliates : []}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            placeholder="Select Affliates"
                                                            name="otherAffiliateId"
                                                            defaultValue={0}
                                                            value={this.state.otherAffiliateId}
                                                            displayExpr='name'
                                                            valueExpr='id'
                                                            onSelectionChanged={this.handleAffiliateChange}
                                                            showClearButton={true}
                                                            disabled={false}
                                                            searchEnabled={true}
                                                        />
                                                        {this.state.errors["otherAffiliateId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["otherAffiliateId"]}</span>}
                                                    </div>
                                                </div>}
                                            </div>
                                            <div className="row">
                                                <VehicleDriverComponent ref="vehicleDriverComponent" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-right">
                                    <div className="card sub-rez-card">
                                        <div className="card-header">
                                            <h5 className="card-title m-0">Reservation Date Time Details</h5>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                    <div className="form-group">
                                                        <label htmlFor="">Flight Time [ hh ]</label>
                                                        {/*<DatePicker*/}
                                                        {/*    className="form-control form-control-sm"*/}
                                                        {/*    name="flightTime"*/}
                                                        {/*    selected={this.state.flightTime}*/}
                                                        {/*    onChange={this.handleFlightDateTimeChange}*/}
                                                        {/*    showTimeSelect*/}
                                                        {/*    showTimeSelectOnly*/}
                                                        {/*    timeIntervals={15}*/}
                                                        {/*    timeCaption="Time"*/}
                                                        {/*    dateFormat="HH:mm"*/}
                                                        {/*    timeFormat="HH:mm"*/}
                                                        {/*    filterTime={filterPassedTime}*/}
                                                        {/*/>*/}
                                                        <SelectBox
                                                            dataSource={this.state.dropdownHours.length > 0 ? this.state.dropdownHours : []}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            placeholder="Select Hour"
                                                            name="id"
                                                            defaultValue={0}
                                                            value={this.state.flightHour}
                                                            displayExpr='name'
                                                            valueExpr='id'
                                                            onSelectionChanged={this.handleFlightHourChange}
                                                            showClearButton={true}
                                                            disabled={false}
                                                            searchEnabled={true}
                                                        />
                                                        {this.state.errors["flightTime"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["flightTime"]}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                    <div className="form-group">
                                                        <label htmlFor="">[ min ]</label>
                                                        <SelectBox
                                                            dataSource={this.state.dropdownMinutes.length > 0 ? this.state.dropdownMinutes : []}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            placeholder="Select Minute"
                                                            name="id"
                                                            defaultValue={0}
                                                            value={this.state.flightMinute}
                                                            displayExpr='name'
                                                            valueExpr='id'
                                                            onSelectionChanged={this.handleFlightMinuteChange}
                                                            showClearButton={true}
                                                            disabled={false}
                                                            searchEnabled={true}
                                                        />
                                                        {this.state.errors["flightTime"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["flightTime"]}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                    <div className="form-group">
                                                        <label>PU Date and Time  <span className="text-danger">*</span></label>
                                                        <DateBox type="datetime"
                                                            className="form-control form-control-sm"
                                                            name="puDateTime"
                                                            placeholder="MM/dd/yyyy HH:mm"
                                                            displayFormat="MM/dd/yyyy HH:mm"
                                                            showAnalogClock={true}
                                                            useMaskBehavior={true}
                                                            defaultValue={this.state.puDateTime}
                                                            value={this.state.puDateTime}
                                                            onValueChanged={this.handlePickupDateTimeChange}
                                                            width="100%"
                                                            disabled={false}
                                                            acceptCustomValue={false}
                                                        />
                                                        {this.state.errors["puDateTime"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["puDateTime"]}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                    <div className="form-group">
                                                        <label>Approx. Drop Off Time</label>
                                                        <DatePicker
                                                            className="form-control form-control-sm "
                                                            placeholderText="Click to select a pickup date"
                                                            name="doDateTime"
                                                            showTimeInput
                                                            selected={this.state.doDateTime}
                                                            dateFormat="MM/dd/yyyy HH:mm"
                                                            onChange={date => this.setState({ doDateTime: date })}
                                                            filterTime={filterPassedTime}
                                                        />
                                                        {this.state.errors["doDateTime"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["doDateTime"]}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                    <div className="form-group">
                                                        <label>Trip Duration (Min)</label>
                                                        <NumericInput className="form-control form-control-sm" name="tripDuration" title="Trip Duration" value={this.state.tripDuration} min={0} max={5000} onChange={(value) => { this.setState({ tripDuration: value }) }} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                    <div className="form-group">
                                                        <label>Vehicle Type <span className="text-danger">*</span></label>
                                                        <SelectBox
                                                            dataSource={this.state.dropdownVehicleType.length > 0 ? this.state.dropdownVehicleType : []}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            placeholder="Select vehicle type"
                                                            name="vehicleTypeId"
                                                            defaultValue={0}
                                                            value={this.state.vehicleTypeId}
                                                            displayExpr='apiVehicleTypeDescription'
                                                            valueExpr='rowIndex'
                                                            onSelectionChanged={this.handleVehicleTypeChange}
                                                            showClearButton={true}
                                                            disabled={false}
                                                            searchEnabled={true}
                                                        />
                                                        {this.state.errors["vehicleTypeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["vehicleTypeId"]}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-1 col-md-1 col-sm-3 col-xs-3">
                                                    <div className="form-group">
                                                        <label>Trip#</label>
                                                        <NumericInput className="form-control form-control-sm" name="noOfTrip" title="Number of Trips" disabled={this.state.tripTypeId === "2" || this.state.tripTypeId === "3" || this.state.rideId > 0} value={this.state.noOfTrip} min={1} max={20} onChange={(value) => { this.handleTripCountChange(value) }} />
                                                        {this.state.errors["noOfTrip"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["noOfTrip"]}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9" style={{ paddingTop: 27 + "px" }}>
                                                    <div className="form-group custom-form-group">
                                                        <span className="form-check">
                                                            <input className="form-check-input" type="radio" name="oneWay" value="1" checked={this.state.tripTypeId === "1"} disabled={this.state.rideId > 0} onChange={this.handleTripTypeChange} />
                                                            <label className="form-check-label">Oneway</label>
                                                        </span>
                                                        <span className="form-check">
                                                            <input className="form-check-input" type="radio" name="isReturnTrip" value="2" checked={this.state.tripTypeId === "2"} disabled={this.state.rideId > 0} onChange={this.handleTripTypeChange} />
                                                            <label className="form-check-label">Round Trip</label>
                                                        </span>
                                                        <span className="form-check">
                                                            <input className="form-check-input" type="radio" name="isRecurring" value="3" checked={this.state.tripTypeId === "3"} disabled={this.state.rideId > 0} onChange={this.handleTripTypeChange} />
                                                            <label className="form-check-label">Recurring</label>
                                                        </span>
                                                        <span className="form-check ml-3">
                                                            <input className="form-check-input" type="checkbox" checked={this.state.isVipTrip} onChange={this.handleInputChange} name="isVipTrip" />
                                                            <label className="form-check-label text-blue">VIP</label>
                                                        </span>
                                                        <span className="form-check" style={{ display: (rideTypeId == 1 || rideTypeId == 3) ? 'inline' : 'none' }}>
                                                            <input className="form-check-input" type="checkbox" checked={this.state.isMeetGreet} onChange={this.handleMeetGreetChange} name="isMeetGreet" />
                                                            <label className="form-check-label text-blue">Meet & Greet</label>
                                                        </span>
                                                        <span className="form-check">
                                                            <input className="form-check-input" type="checkbox" id="isAccessible" checked={this.state.isAccessible} onChange={this.handleInputChange} name="isAccessible" />
                                                            <label className="form-check-label text-blue">Accessible</label>
                                                        </span>
                                                        <span className="form-check">
                                                            <input className="form-check-input" type="checkbox" id="isPriorityTrip" checked={this.state.isPriorityTrip} onChange={this.handleInputChange} name="isPriorityTrip" />
                                                            <label className="form-check-label text-blue">Priority Trip</label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            {(this.state.tripTypeId === "2") && <div className="card sub-rez-card">
                                                <div className="card-header">
                                                    <h5 className="card-title m-0">Return Details</h5>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                            <div className="form-group">
                                                                <label>Airline</label>
                                                                <SelectBox
                                                                    dataSource={this.state.dropdownAirline.length > 0 ? this.state.dropdownAirline : []}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    placeholder="Select airline"
                                                                    name="airlineIdReturn"
                                                                    defaultValue={0}
                                                                    value={this.state.airlineIdReturn}
                                                                    displayExpr='airLineName'
                                                                    valueExpr='airLineId'
                                                                    onSelectionChanged={this.handleAirlineReturnChange}
                                                                    showClearButton={true}
                                                                    disabled={false}
                                                                    searchEnabled={true}
                                                                />
                                                                {this.state.errors["airlineIdReturn"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["airlineIdReturn"]}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-2 col-xs-2">
                                                            <div className="form-group">
                                                                <label>Flight #</label>
                                                                <input type="text" className="form-control form-control-sm" maxLength={25} value={this.state.flightNumberReturn} onChange={this.handleInputChange} name="flightNumberReturn" />
                                                                {this.state.errors["flightNumberReturn"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["flightNumberReturn"]}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>Terminal Type</label>
                                                                <select className="form-control form-control-sm" name="terminalTypeReturn" value={this.state.terminalTypeReturn} onChange={this.handleInputChange} >
                                                                    <option value="0">Select Terminal Type</option>
                                                                    {
                                                                        terminalTypeData &&
                                                                        terminalTypeData.map((item, i) =>
                                                                            (<option key={i} value={item.id}>{item.name}</option>))
                                                                    }
                                                                </select>
                                                                {this.state.errors["terminalTypeReturn"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["terminalTypeReturn"]}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                            <div className="form-group">
                                                                <label>Terminal</label>
                                                                <input type="text" className="form-control form-control-sm" maxLength={25} value={this.state.terminalReturn} onChange={this.handleInputChange} name="terminalReturn" />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">

                                                            <div className="form-group">
                                                                <label>Pax#</label>
                                                                <NumericInput className="form-control form-control-sm" name="noOfPaxReturn" title="Number of Pax" value={this.state.noOfPaxReturn} min={1} max={100} onChange={(value) => { this.handlePaxCountReturnChange(value) }} />
                                                                {this.state.errors["noOfPaxReturn"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["noOfPaxReturn"]}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-2 col-xs-2">
                                                            <div className="form-group">
                                                                <label htmlFor="">Pickup Date & Time <span className="text-danger">*</span></label>
                                                                <DateBox type="datetime"
                                                                    className="form-control form-control-sm"
                                                                    name="puDateTimeReturn"
                                                                    placeholder="MM/dd/yyyy HH:mm"
                                                                    displayFormat="MM/dd/yyyy HH:mm"
                                                                    showAnalogClock={true}
                                                                    useMaskBehavior={true}
                                                                    defaultValue={this.state.puDateTimeReturn}
                                                                    value={this.state.puDateTimeReturn}
                                                                    onValueChanged={this.handlePickupDateTimeReturnChange}
                                                                    width="100%"
                                                                    disabled={false}
                                                                    acceptCustomValue={false}
                                                                    min={this.state.puDateTime}
                                                                />
                                                                {this.state.errors["puDateTimeReturn"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["puDateTimeReturn"]}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                            <div className="form-group">
                                                                <label htmlFor="">Flight Time [ hh ]</label>
                                                                {/*<DatePicker*/}
                                                                {/*    className="form-control form-control-sm"*/}
                                                                {/*    name="flightTimeReturn"*/}
                                                                {/*    selected={this.state.flightTimeReturn}*/}
                                                                {/*    onChange={this.handleFlightDateTimeReturnChange}*/}
                                                                {/*    showTimeSelect*/}
                                                                {/*    showTimeSelectOnly*/}
                                                                {/*    timeIntervals={15}*/}
                                                                {/*    timeCaption="Time"*/}
                                                                {/*    dateFormat="HH:mm"*/}
                                                                {/*    timeFormat="HH:mm"*/}
                                                                {/*    filterTime={filterPassedTime}*/}
                                                                {/*/>*/}
                                                                <SelectBox
                                                                    dataSource={this.state.dropdownHours.length > 0 ? this.state.dropdownHours : []}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    placeholder="Select Hour"
                                                                    name="id"
                                                                    defaultValue={0}
                                                                    value={this.state.flightHourReturn}
                                                                    displayExpr='name'
                                                                    valueExpr='id'
                                                                    onSelectionChanged={this.handleFlightHourReturnChange}
                                                                    showClearButton={true}
                                                                    disabled={false}
                                                                    searchEnabled={true}
                                                                />
                                                                {this.state.errors["flightTimeReturn"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["flightTimeReturn"]}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                            <div className="form-group">
                                                                <label htmlFor="">[ min ]</label>
                                                                <SelectBox
                                                                    dataSource={this.state.dropdownMinutes.length > 0 ? this.state.dropdownMinutes : []}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    placeholder="Select Hour"
                                                                    name="id"
                                                                    defaultValue={0}
                                                                    value={this.state.flightMinuteReturn}
                                                                    displayExpr='name'
                                                                    valueExpr='id'
                                                                    onSelectionChanged={this.handleFlightMinuteReturnChange}
                                                                    showClearButton={true}
                                                                    disabled={false}
                                                                    searchEnabled={true}
                                                                />
                                                                {this.state.errors["flightTimeReturn"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["flightTimeReturn"]}</span>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}

                                            {(this.state.tripTypeId === "3") && <div className="card sub-rez-card">
                                                <div className="card-header">
                                                    <h5 className="card-title m-0">Recurring Details</h5>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>Repeat Type <span className="text-danger">*</span></label>
                                                                <select className="form-control form-control-sm" name="recurringTypeId" value={this.state.recurringTypeId} onChange={this.handleInputChange}>
                                                                    <option value="1">Daily</option>
                                                                    <option value="2">Weekly</option>
                                                                    <option value="3">Monthly</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>End Date <span className="text-danger">*</span></label>

                                                                <DateBox type="date"
                                                                    className="form-control form-control-sm"
                                                                    name="recurringEndDate"
                                                                    placeholder="mm/dd/yyyy"
                                                                    useMaskBehavior={true}
                                                                    defaultValue={this.state.recurringEndDate}
                                                                    value={this.state.recurringEndDate}
                                                                    onValueChanged={this.handleRecurringEndDateChange}
                                                                    width="100%"
                                                                    disabled={false}
                                                                    acceptCustomValue={false}
                                                                    min={this.state.puDateTime}
                                                                />
                                                                {this.state.errors["recurringEndDate"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["recurringEndDate"]}</span>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}

                                            {(this.state.recurringTypeId == 1 && this.state.tripTypeId === "3") && <div id="recurringDaily" className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                    <div className="row">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <div className="form-group">
                                                                <div id="divrecurringDaily">
                                                                    <DayPicker
                                                                        selectedDays={this.state.selectedDays}
                                                                        onDayClick={this.handleDayClick}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                            {(this.state.recurringTypeId == 2 && this.state.tripTypeId === "3") && <div id="recurringWeekly" className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <div className="form-group">
                                                        <span className="form-check">
                                                            <input className="form-check-input" type="checkbox" checked={this.state.isWeekSun} onChange={this.handleInputChange} name="isWeekSun" />
                                                            <label className="form-check-label">SUN</label>
                                                        </span>
                                                        <span className="form-check">
                                                            <input className="form-check-input" type="checkbox" checked={this.state.isWeekMon} onChange={this.handleInputChange} name="isWeekMon" />
                                                            <label className="form-check-label">MON</label>
                                                        </span>
                                                        <span className="form-check">
                                                            <input className="form-check-input" type="checkbox" checked={this.state.isWeekTue} onChange={this.handleInputChange} name="isWeekTue" />
                                                            <label className="form-check-label">TUE</label>
                                                        </span>
                                                        <span className="form-check">
                                                            <input className="form-check-input" type="checkbox" checked={this.state.isWeekWed} onChange={this.handleInputChange} name="isWeekWed" />
                                                            <label className="form-check-label">WED</label>
                                                        </span>
                                                        <span className="form-check">
                                                            <input className="form-check-input" type="checkbox" checked={this.state.isWeekThu} onChange={this.handleInputChange} name="isWeekThu" />
                                                            <label className="form-check-label">THU</label>
                                                        </span>
                                                        <span className="form-check">
                                                            <input className="form-check-input" type="checkbox" checked={this.state.isWeekFri} onChange={this.handleInputChange} name="isWeekFri" />
                                                            <label className="form-check-label">FRI</label>
                                                        </span>
                                                        <span className="form-check">
                                                            <input className="form-check-input" type="checkbox" checked={this.state.isWeekSat} onChange={this.handleInputChange} name="isWeekSat" />
                                                            <label className="form-check-label">SAT</label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>}
                                            {(this.state.recurringTypeId == 3 && this.state.tripTypeId === "3") && <div id="recurringMonthly" className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <div className="form-group">
                                                        <span className="form-check">
                                                            <input className="form-check-input" type="checkbox" checked={this.state.isMonthSun} onChange={this.handleInputChange} name="isMonthSun" />
                                                            <label className="form-check-label">SUN</label>
                                                        </span>
                                                        <span className="form-check">
                                                            <input className="form-check-input" type="checkbox" checked={this.state.isMonthMon} onChange={this.handleInputChange} name="isMonthMon" />
                                                            <label className="form-check-label">MON</label>
                                                        </span>
                                                        <span className="form-check">
                                                            <input className="form-check-input" type="checkbox" checked={this.state.isMonthTue} onChange={this.handleInputChange} name="isMonthTue" />
                                                            <label className="form-check-label">TUE</label>
                                                        </span>
                                                        <span className="form-check">
                                                            <input className="form-check-input" type="checkbox" checked={this.state.isMonthWed} onChange={this.handleInputChange} name="isMonthWed" />
                                                            <label className="form-check-label">WED</label>
                                                        </span>
                                                        <span className="form-check">
                                                            <input className="form-check-input" type="checkbox" checked={this.state.isMonthThu} onChange={this.handleInputChange} name="isMonthThu" />
                                                            <label className="form-check-label">THU</label>
                                                        </span>
                                                        <span className="form-check">
                                                            <input className="form-check-input" type="checkbox" checked={this.state.isMonthFri} onChange={this.handleInputChange} name="isMonthFri" />
                                                            <label className="form-check-label">FRI</label>
                                                        </span>
                                                        <span className="form-check">
                                                            <input className="form-check-input" type="checkbox" checked={this.state.isMonthSat} onChange={this.handleInputChange} name="isMonthSat" />
                                                            <label className="form-check-label">SAT</label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>}
                                            {this.state.errors["recurring"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["recurring"]}</span>}
                                        </div>
                                    </div>
                                    <div className="card sub-rez-card">
                                        <div className="card-header">
                                            <h5 className="card-title m-0">Notes</h5>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <ul className="nav nav-tabs" id="custom-content-below-tab" role="tablist">
                                                        <li className="nav-item">
                                                            <a className="nav-link active" id="trip-notes-tab" data-toggle="pill" href="#trip-notes" role="tab" aria-controls="trip-notes" aria-selected="true">Trip Notes</a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" id="bill-to-notes-tab" data-toggle="pill" href="#bill-to-notes" role="tab" aria-controls="bill-to-notes" aria-selected="false">Bill To / Pax Notes</a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" id="pickup-to-notes-tab" data-toggle="pill" href="#pickup-to-notes" role="tab" aria-controls="pickup-to-notes" aria-selected="false">Pickup Notes</a>
                                                        </li>
                                                    </ul>
                                                    <div className="tab-content" id="custom-content-below-tabContent">
                                                        <div className="tab-pane fade show active" id="trip-notes" role="tabpanel" aria-labelledby="trip-notes-tab">
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                    <div className="form-group">
                                                                        <textarea className="form-control form-control-sm" rows="3" name="specialInstruction" placeholder="Please enter special instruction." maxLength="500" title="Special Instruction" value={this.state.specialInstruction} onChange={this.handleInputChange} />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <textarea className="form-control form-control-sm" rows="3" name="permanentRemark" placeholder="Please enter permanent remark." maxLength="500" title="Permanent Remark" value={this.state.permanentRemark} onChange={this.handleInputChange} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="bill-to-notes" role="tabpanel" aria-labelledby="bill-to-notes-tab">
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                    <div className="form-group">
                                                                        <textarea className="form-control form-control-sm" rows="6" name="billToNotes" placeholder="Please enter bill to notes." maxLength="1000" title="Bill To Notes" value={this.state.billToNotes} onChange={this.handleInputChange} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="pickup-to-notes" role="tabpanel" aria-labelledby="pickup-to-notes-tab">
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                    <div className="form-group">
                                                                        <textarea className="form-control form-control-sm" rows="6" name="pickupNotes" placeholder="Please enter pickup notes." maxLength="1000" title="Pickup Notes" value={this.state.pickupNotes} onChange={this.handleInputChange} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DuplicateReservationComponent ref="duplicationReservationModal" bookReservation={this.bookReservation} />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}