import React from 'react';
import DateBox from 'devextreme-react/date-box';
import { SelectBox } from 'devextreme-react/select-box';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions';
import { LoadPanel } from 'devextreme-react/load-panel';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'
const position = { of: '#historydiv' };
let currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class TaxComponent extends React.Component {
    constructor(props) {
        currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        super(props);
        this.state = {
            isSubmitted: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            chkCustomerSelection: "1",
            dropdownCustomer: [],
            customerCode: '',
            startDate: new Date(new Date(currentTime).setDate(new Date(currentTime).getDate() - 1)),
            endDate: new Date(currentTime),
            chkReportTypeSelection: "1",
            errors: {
                customerCode:'',
                startDate: '',
                endDate: '',
            }
        }

        this.getCustomer = this.getCustomer.bind(this);
        this.handleCustomerChange = this.handleCustomerChange.bind(this);
        this.handleCustomerSelectionChange = this.handleCustomerSelectionChange.bind(this);
        this.handleFromDateChanged = this.handleFromDateChanged.bind(this);
        this.handleToDateChanged = this.handleToDateChanged.bind(this);
        this.handleReportTypeSelectionChange = this.handleReportTypeSelectionChange.bind(this);
        this.downloadAccountPaymentTypeSummaryAsPdf = this.downloadAccountPaymentTypeSummaryAsPdf.bind(this);
        this.handleSearchAccountPaySummaryReportSubmit = this.handleSearchAccountPaySummaryReportSubmit.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
    }


    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getCustomer();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({ isOpenModal: false });
            await this.handleClearSearch();
        } catch (ex) {
            console.log(ex);
        }
    }

    async getCustomer() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('account-api/customer-dropdown/', requestParams);
            const data = await response.json();
            this.setState({
                dropdownCustomer: data.customers,
            });

        } catch (e) {
            console.log(e);
        }
    }

    handleCustomerChange(selectedOption) {
        try {
            let errors = this.state.errors;
            errors.customerCode = '';
            let customerCode = '';
            if (selectedOption !== null && selectedOption.selectedItem !== null) {
                customerCode = selectedOption.selectedItem.code === null ? '' : selectedOption.selectedItem.code;
            }
            this.setState({ customerCode: customerCode, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }

    handleCustomerSelectionChange(event) {
        try {
            let errors = this.state.errors;
            errors.customerCode = '';
            const target = event.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            this.setState({
                chkInvoiceSelection: value,
                customerCode:'',
                errors: errors
            });

        } catch (ex) {
            console.log(ex);
        }
    }

    handleInvoiceSelectionChange(event) {
        try {
            let errors = this.state.errors;
            errors.invoiceNumber = '';
            const target = event.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            this.setState({
                chkInvoiceSelection: value,
                invoiceNumber: '',
                errors: errors
            });
        } catch (ex) {
            console.log(ex);
        }
    }


    handleReportTypeSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ chkReportTypeSelection: value });
    }

    handleFromDateChanged(event) {
        let fromDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ startDate: fromDateValue })
    }
    handleToDateChanged(event) {
        let toDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ endDate: toDateValue })
    }


    handleSearchAccountPaySummaryReportSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            this.downloadAccountPaymentTypeSummaryAsPdf();
        }
    }
    handleClearSearch() {
        this.setState({
            isSubmitted: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            chkCustomerSelection: "1",
            customerCode: '',
            startDate: new Date(new Date(currentTime).setDate(new Date(currentTime).getDate() - 1)),
            endDate: new Date(currentTime),
            chkReportTypeSelection: "1",
            errors: {
                customerCode:'',
                startDate: '',
                endDate: '',
            }
        });
    }
    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.customerCode = "";
        errors.startDate = "";
        errors.endDate = "";

        if (this.state.chkCustomerSelection === "2" && this.state.customerCode === "") {
            formIsValid = false;
            errors.customerCode = "Please select customer.";
        }

        if (this.state.startDate === "") {
            formIsValid = false;
            errors.startDate = "Please select start date.";
        }

        if (this.state.endDate === "") {
            formIsValid = false;
            errors.endDate = "Please select end date.";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    async downloadAccountPaymentTypeSummaryAsPdf() {
        try {
            this.setState({
                isSubmitted: true,
            });
            let RptHead = "";
            let selection_Criteria = "";

            RptHead = ("Date Range " + (formatDate(this.state.startDate, "MM/dd/yyyy") + (" to " + formatDate(this.state.endDate, "MM/dd/yyyy"))));
            let request = {};
            request.LocationId = Number(sessionStorage.getItem("session_locationId"));
            request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy") + " 00:00:00";
            request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy") + " 23:59:59";
            request.AccountCode = this.state.customerCode;

            if (this.state.chkReportTypeSelection === "1") {
                if (this.state.chkCustomerSelection === "2") {
                    selection_Criteria = (selection_Criteria + ("{PROC_RPT_TAX_ON_INVOICE_RIDES;1.CUSTOMER_CODE}= \'" + (this.state.customerCode + "\'")));
                    RptHead = (RptHead + (", for A/c: " + this.state.customerCode));
                } else {
                    selection_Criteria = "";
                    RptHead = RptHead + ", for all Customers";
                }
                if (selection_Criteria === "") {
                    selection_Criteria = selection_Criteria + ("{PROC_RPT_TAX_ON_INVOICE_RIDES;1.INVOICE_DATE} In CDateTime(\'"
                        + (formatDate(this.state.startDate, 'MM/dd/yyyy') + " 00:00:00" + ("\') to  CDateTime(\'"
                        + (formatDate(this.state.endDate, 'MM/dd/yyyy') + " 23:59:59" + "\') "))));
                }
                else {
                    selection_Criteria = selection_Criteria + "AND" + ("{PROC_RPT_TAX_ON_INVOICE_RIDES;1.INVOICE_DATE} In CDateTime(\'"
                        + (formatDate(this.state.startDate, "MM/dd/yyyy") + " 00:00:00" + ("\') to  CDateTime (\'"
                        + (formatDate(this.state.endDate, "MM/dd/yyyy") + " 23:59:59" + "\') "))));
                }
            }

            if (this.state.chkReportTypeSelection === "2" || this.state.chkReportTypeSelection === "3") {
                if (this.state.chkCustomerSelection === "2") {
                    selection_Criteria = (selection_Criteria + ("{Rpt_Airport_Tax;1.Account_Code}= \'" + (this.state.customerCode + "\'")));
                    RptHead = (RptHead + (", for A/c: " + this.state.customerCode));
                } else {
                    selection_Criteria = "";
                    RptHead = RptHead + ", for all Customers";
                }
            }
           
            request.ReportType = parseInt(this.state.chkReportTypeSelection);
            request.MethodName = "GetTaxReports";
            request.Header = RptHead;
            request.SelectionCrieteria = selection_Criteria;

            request.fileFormat = 'pdf';
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            this.setState({
                isSubmitted: false,
            });

            let a = document.createElement('a');
            a.href = dataResponse.reportBase64String;

            if (this.state.chkReportTypeSelection === "1") {
                a.download = 'ItemisedTaxReports.pdf';
            } else if (this.state.chkReportTypeSelection === "2") {
                a.download = 'TaxSummary.pdf';
            } else if (this.state.chkReportTypeSelection === "3") {
                a.download = 'TollSummary.pdf';
            } else if (this.state.chkReportTypeSelection === "4") {
                a.download = 'ItemisedSalesTaxReports.pdf';
            } else if (this.state.chkReportTypeSelection === "5") {
                a.download = 'ItemisedSalesTaxReportsSeparate.pdf';
            } else if (this.state.chkReportTypeSelection === "6") {
                a.download = 'SalesTaxSummary.pdf';
            } 

            a.click();
        } catch (e) {
            this.setState({
                isSubmitted: false,
            });
            console.log(e)
        }
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isEmailSubmitted: true });
                var RptHead = "";
                var selection_Criteria = "";

                RptHead = ("Date Range " + (formatDate(this.state.startDate, "MM/dd/yyyy") + (" to " + formatDate(this.state.endDate, "MM/dd/yyyy"))));
                var request = {};
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy") + " 00:00:00";
                request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy") + " 23:59:59";
                request.AccountCode = this.state.customerCode;

                if (this.state.chkReportTypeSelection === "1") {
                    if (this.state.chkCustomerSelection === "2") {
                        selection_Criteria = (selection_Criteria + ("{PROC_RPT_TAX_ON_INVOICE_RIDES;1.CUSTOMER_CODE}= \'" + (this.state.customerCode + "\'")));
                        RptHead = (RptHead + (", for A/c: " + this.state.customerCode));
                    } else {
                        selection_Criteria = "";
                        RptHead = RptHead + ", for all Customers";
                    }
                    if (selection_Criteria === "") {
                        selection_Criteria = selection_Criteria + ("{PROC_RPT_TAX_ON_INVOICE_RIDES;1.INVOICE_DATE} In CDateTime (\'"
                            + (formatDate(this.state.startDate, 'MM/dd/yyyy') + " 00:00:00" + ("\') to  CDateTime (\'"
                                + (formatDate(this.state.endDate, 'MM/dd/yyyy') + " 23:59:59" + "\') "))));
                    }
                    else {
                        selection_Criteria = selection_Criteria + "AND" + ("{PROC_RPT_TAX_ON_INVOICE_RIDES;1.INVOICE_DATE} In CDateTime (\'"
                            + (formatDate(this.state.startDate, "MM/dd/yyyy") + " 00:00:00" + ("\') to  CDateTime (\'"
                                + (formatDate(this.state.endDate, "MM/dd/yyyy") + " 23:59:59" + "\') "))));
                    }
                }

                if (this.state.chkReportTypeSelection === "2" || this.state.chkReportTypeSelection === "3") {
                    if (this.state.chkCustomerSelection === "2") {
                        selection_Criteria = (selection_Criteria + ("{Rpt_Airport_Tax;1.Account_Code}= \'" + (this.state.customerCode + "\'")));
                        RptHead = (RptHead + (", for A/c: " + this.state.customerCode));
                    } else {
                        selection_Criteria = "";
                        RptHead = RptHead + ", for all Customers";
                    }
                }

                request.ReportType = parseInt(this.state.chkReportTypeSelection);
                request.MethodName = "GetTaxReports";
                request.Header = RptHead;
                request.SelectionCrieteria = selection_Criteria;

                request.fileFormat = 'pdf';
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                let reportName = '';
                if (this.state.chkReportTypeSelection === "1") {
                    reportName = 'ItemisedTaxReports.pdf';
                } else if (this.state.chkReportTypeSelection === "2") {
                    reportName = 'TaxSummary.pdf';
                } else if (this.state.chkReportTypeSelection === "3") {
                    reportName = 'TollSummary.pdf';
                } else if (this.state.chkReportTypeSelection === "4") {
                    reportName = 'ItemisedSalesTaxReports.pdf';
                } else if (this.state.chkReportTypeSelection === "5") {
                    reportName = 'ItemisedSalesTaxReportsSeparate.pdf';
                } else if (this.state.chkReportTypeSelection === "6") {
                    reportName = 'SalesTaxSummary.pdf';
                } 
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Tax Report';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = reportName
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '50%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleSearchAccountPaySummaryReportSubmit}
                        style={(this.state.isSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Tax Report</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdAllCustomer" value="1" checked={this.state.chkCustomerSelection === "1"} onChange={this.handleCustomerSelectionChange} />
                                            <label className="form-check-label">All Customer</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdSingleCustomer" value="2" checked={this.state.chkCustomerSelection === "2"} onChange={this.handleCustomerSelectionChange} />
                                            <label className="form-check-label">Single</label>
                                        </span>
                                        <SelectBox
                                            dataSource={this.state.dropdownCustomer}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select Customer"
                                            name="ddcustomer"
                                            defaultValue={''}
                                            value={this.state.customerCode}
                                            displayExpr='fullName'
                                            valueExpr='code'
                                            onSelectionChanged={this.handleCustomerChange}
                                            showClearButton={true}
                                            disabled={(this.state.chkCustomerSelection === '1')}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerCode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerCode"]}</span>}
                                    </div>
                                </div>
                               
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>From Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.startDate}
                                            value={this.state.startDate}
                                            onValueChanged={this.handleFromDateChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>To Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.endDate}
                                            value={this.state.endDate}
                                            onValueChanged={this.handleToDateChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Report Type</label><br />
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdItemisedTaxReports" value="1" checked={this.state.chkReportTypeSelection === "1"} onChange={this.handleReportTypeSelectionChange} />
                                            <label className="form-check-label">Itemised Tax Reports</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdTaxSummary" value="2" checked={this.state.chkReportTypeSelection === "2"} onChange={this.handleReportTypeSelectionChange} />
                                            <label className="form-check-label">Tax Summary</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdTollSummary" value="3" checked={this.state.chkReportTypeSelection === "3"} onChange={this.handleReportTypeSelectionChange} />
                                            <label className="form-check-label">Toll Summary</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdItemisedSalesTaxReports" value="4" checked={this.state.chkReportTypeSelection === "4"} onChange={this.handleReportTypeSelectionChange} />
                                            <label className="form-check-label">Itemised Sales Tax Reports</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdItemisedSalesTaxReportsSeparate" value="5" checked={this.state.chkReportTypeSelection === "5"} onChange={this.handleReportTypeSelectionChange} />
                                            <label className="form-check-label">Itemised Sales Tax Reports Separate</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdSalesTaxSummary" value="6" checked={this.state.chkReportTypeSelection === "6"} onChange={this.handleReportTypeSelectionChange} />
                                            <label className="form-check-label">Sales Tax Summary</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="button" className="btn btn-success btn-sm" title="Download" onClick={this.handleSearchAccountPaySummaryReportSubmit} style={this.state.isSubmitted ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Search"><i className="fas fa-file-pdf"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                                </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        )
    }
}