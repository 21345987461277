import React, { PureComponent } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1

import Modal from 'react-modal'; //npm install --save react-modal
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

const customStyles = {
    content: {
        width: '70%'
    }
};

export class DuplicateReservationComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
            isSubmited: false,
            isButtonShow: false,
            tripData: null,
            //pageName: '',
            //name: '',
            //id: 0,
        }
        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.continueReservation = this.continueReservation.bind(this);
    }

    closeModal() {
        this.setState({
            isOpenModal: false,
            isSubmited: false,
            //pageName: '',
            //params: null,
        });
    }

    continueReservation() {
        this.setState({
            isOpenModal: false,
            isSubmited: false,
        });
        this.props.bookReservation();

    }

    showModal(data, isButtonShow) {
        this.setState({ isOpenModal: true, tripData: data, isButtonShow: isButtonShow })
    }

    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={customStyles}
                    className={"react-modal"}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form>
                        <div className="modal-header">
                            <h6 className="modal-title">Duplicate Reservation</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <small>Below are the duplicate trip(s) found.</small>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="widget-container">
                                        <DataGrid
                                            dataSource={this.state.tripData}
                                            columnsAutoWidth={true}
                                            showColumnLines={true}
                                            showRowLines={true}
                                            showBorders={true}
                                            rowAlternationEnabled={true}
                                            allowColumnResizing={true}
                                            columnResizingMode='widget'
                                            showBorders={true}>

                                            <Column dataField="vehicleType" caption="Vehicle Type" width={90} allowFiltering={true} allowSorting={true} />
                                            <Column dataField="vehicleNo" caption="Vehicle No" width={80} allowFiltering={true} allowSorting={true} />
                                            <Column dataField="accessible" caption="Accessible" width={80} allowFiltering={true} allowSorting={true} />
                                            <Column dataField="puDateTimeStr" caption="PU Date Time" width={120} allowFiltering={true} allowSorting={true} />
                                            <Column dataField="rideId" caption="RideId" width={80} allowFiltering={true} allowSorting={true} />
                                            <Column dataField="rideType" caption="Ride Type" width={160} allowFiltering={true} allowSorting={true} />
                                            <Column dataField="fullName" caption="Name" width={150} allowFiltering={true} allowSorting={true} />
                                            <Column dataField="mobileNo" caption="Contact No" width={80} allowFiltering={true} allowSorting={true} />
                                            <Column dataField="puLocationAddress" caption="PU Street" width={250} allowFiltering={true} allowSorting={true} />
                                            <Column dataField="doLocationAddress" caption="DO Street" width={250} allowFiltering={true} allowSorting={true} />
                                            <Column dataField="priority" caption="Priority" width={50} allowFiltering={true} allowSorting={true} />
                                            <Column dataField="accountCode" caption="Account Code" width={100} allowFiltering={true} allowSorting={true} />
                                            <Column dataField="puAirlineCode" caption="Airline" width={100} allowFiltering={true} allowSorting={true} />
                                            <Column dataField="flightInfo" caption="Flight No" width={200} allowFiltering={true} allowSorting={true} />
                                            <Column dataField="adultNo" caption="Pax#" width={50} allowFiltering={true} allowSorting={true} />
                                            <Column dataField="hasAttachment" caption="Has Attachment" width={100} allowFiltering={true} allowSorting={true} />

                                            <SortByGroupSummaryInfo summaryItem="count" />
                                            <GroupPanel visible={false} />
                                            <HeaderFilter visible={false} />
                                            <SearchPanel visible={false} highlightCaseSensitive={false} width={600} placeholder="Search..." />
                                            <Grouping autoExpandAll={false} />
                                            <Paging defaultPageSize={10} />
                                            <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                            <Export enabled={false} allowExportSelectedData={false} />
                                            <ColumnChooser enabled={false} />
                                            <ColumnFixing enabled={true} />

                                        </DataGrid>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            {(this.state.isButtonShow == true) && <button type="button" className="btn btn-success btn-sm" visible={false} onClick={this.continueReservation}><i className="fas fa-check"></i> Continue</button>}
                            
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeModal}><i className="fas fa-times"></i> Cancel</button>
                        </div>
                    </form>
                </Modal>
            </div>
        );
    }
}