import React, { Component } from 'react';
import DataGrid, {
    Column, Grouping, GroupPanel, Pager, Paging, SearchPanel, Export, SortByGroupSummaryInfo, ColumnChooser, ColumnFixing, HeaderFilter,
    Button, Editing
} from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import Modal from 'react-modal'; //npm install --save react-modal
import { SelectBox } from 'devextreme-react/select-box';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import NumberFormat from 'react-number-format';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { Item } from 'devextreme-react/select-box';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';

export class VehicleTypeTaxComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            locationList: [],
            locationId: null,
            vehicleTypeTaxList: [],
            checkBoxesMode: 'onClick',
            errors: {
                locationId: ''
            }
        }
        this.handleVehicleTypeTaxSettingSubmit = this.handleVehicleTypeTaxSettingSubmit.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
    }
    componentDidMount = () => {
        this.getLocationList();
        this.getVehicleTypeTaxList();
    }
    async getVehicleTypeTaxList() {

        const requestParams = getRequestParams('GET', '');
        const response = await fetch('vehicle-type-tax-setting-api/list/0', requestParams);
        const data = await response.json();
        if (data !== null && data.vehicleTypeTaxSetting.length > 0) {            
            this.setState({
                vehicleTypeTaxList: data.vehicleTypeTaxSetting
            });
        }
    }
    async getLocationList() {

        let locationId = parseInt(sessionStorage.getItem("session_locationId"));
        let locationName = sessionStorage.getItem("session_locationName");

        const requestParams = getRequestParams('GET', '');
        const response = await fetch('account-api/locations', requestParams);
        const data = await response.json();
        if (data !== null && data.locations.length > 0) {
            this.setState({
                locationList: data.locations,
                locationId: { locationId: locationId, locationName: locationName }
            });
        }
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getVehicleTypeTaxList()
            }
        });
    }
    handleLocationInputChange = (data) => {
        this.setState({
            locationId: data.selectedItem
        });
        if (data.selectedItem === null) {
            this.state.errors["locationId"] = "Please select location.";
        }
        else {
            this.state.errors["locationId"] = "";
        }
    }
    handleValidation = () => {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.locationId = '';
        if (this.state.locationId === null || parseInt(this.state.locationId.locationId) === 0) {
            formIsValid = false;
            errors.locationId = "Please select Location.";
        }

        return formIsValid;
    }

    async handleVehicleTypeTaxSettingSubmit(e) {
        this.setState({ isSubmited: true });
        e.preventDefault();
        if (this.handleValidation()) {
            var request = new Object();
            request.VehicleTypeTaxSetting = this.state.vehicleTypeTaxList;
            request.LocationId = this.state.locationId.locationId;

            const requestParams = getRequestParams('POST', request);
            const response = await fetch('vehicle-type-tax-setting-api/saveUpdate', requestParams);
            const dataResult = await response.json();
            Swal.fire({
                icon: dataResult.isSuccess ? 'success' : 'error',
                title: dataResult.isSuccess ? 'Success' : 'Oops...',
                text: dataResult.resultMessage,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (dataResult.isSuccess) {
                    if (result.isConfirmed) {
                        this.getVehicleTypeTaxList();
                    }
                } else {
                    this.setState({ isSubmited: false })
                }
            })
            this.setState({ isSubmited: false })
        }
        else {
            this.setState({ isSubmited: false })
        }
    }
    render() {
        const checkBoxesMode = this.state.checkBoxesMode;
        return (<div className="content-wrapper">
            <div className="content-header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <h1 className="m-0 text-dark">Vehicle Type Tax</h1>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <ol className="breadcrumb float-sm-right">
                                &nbsp;
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rez-header">
                <div className="container">
                    <form method="post" onSubmit={this.handleVehicleTypeTaxSettingSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <div className="form-group">
                                    <label>Location</label>
                                    <SelectBox
                                        dataSource={this.state.locationList.length > 0 ? this.state.locationList : []}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select location"
                                        name="locationId"
                                        defaultValue={0}
                                        value={this.state.locationId != null ? this.state.locationId.locationId : 0}
                                        displayExpr='locationName'
                                        valueExpr='locationId'
                                        onSelectionChanged={this.handleLocationInputChange}
                                        showClearButton={false}
                                        disabled={false}
                                        searchEnabled={true}
                                    />
                                    {this.state.errors["locationId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["locationId"]}</span>}
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6  text-left"><br />
                                <button type="submit" className="btn btn-success btn-sm" onClick={this.handleVehicleTypeTaxSettingSubmit} >
                                    {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                    {!this.state.isSubmited && <span title="Save"><i className="fas fa-save"></i> Save</span>}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="content">
                <div className="card card-default">
                    <div className="card-body">
                        <DataGrid
                            dataSource={this.state.vehicleTypeTaxList}
                            keyExpr="vehicleTypeId"
                            columnsAutoWidth={false}
                            showColumnLines={false}
                            showRowLines={true}
                            showBorders={true}
                            rowAlternationEnabled={true}
                            showBorders={true}
                            allowColumnResizing={true}
                            columnResizingMode='widget'
                            onToolbarPreparing={this.onToolbarPreparing}
                        >
                            <Editing
                                mode="cell"
                                allowUpdating={true} />
                            <Column dataField="vehicleType" caption="Vehicle Type" width={150} allowFiltering={true} allowSorting={true} allowEditing={false} />
                            <Column dataField="paTax" caption="PA" width={150} allowFiltering={false} allowSorting={false} Selection={checkBoxesMode} mode="multiple" alignment="center" allowEditing={true} />
                            <Column dataField="wcTax" caption="WC" width={150} allowFiltering={false} allowSorting={false} Selection={checkBoxesMode} mode="multiple" alignment="center" allowEditing={true} />
                            <Column dataField="utilityTax" caption="Utility" width={150} allowFiltering={false} allowSorting={false} Selection={checkBoxesMode} mode="multiple" alignment="center" allowEditing={true} />
                            <Column dataField="nysalesTax" caption="NY Sales" width={150} allowFiltering={false} allowSorting={false} Selection={checkBoxesMode} mode="multiple" alignment="center" allowEditing={true} />
                            <Column dataField="countyTax" caption="County" width={150} allowFiltering={false} allowSorting={false} Selection={checkBoxesMode} mode="multiple" alignment="center" allowEditing={true} />
                            <Column dataField="congestionTax" caption="Congestion" width={150} allowFiltering={false} allowSorting={false} Selection={checkBoxesMode} mode="multiple" alignment="center" allowEditing={true} />
                            <Column dataField="gstTax" caption="Gst" width={150} allowFiltering={false} allowSorting={false} Selection={checkBoxesMode} mode="multiple" alignment="center" allowEditing={true} />

                            <SortByGroupSummaryInfo summaryItem="count" />
                            <GroupPanel visible={true} />
                            <HeaderFilter visible={true} />
                            <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                            <Grouping autoExpandAll={true} />
                            <Paging defaultPageSize={20} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                            <Export enabled={true} allowExportSelectedData={false} />
                            <ColumnChooser enabled={true} />
                            <ColumnFixing enabled={true} />

                        </DataGrid>
                    </div>
                </div>
            </div>
        </div>)
    }
}