import React, { Component } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { LoadPanel } from 'devextreme-react/load-panel';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { VehicleOwnerType } from '../../helpers/fixcodes.js';
import { SelectBox } from 'devextreme-react/select-box';
import Swal from 'sweetalert2';
import ProgressBar from "./ProgressBarComponent.js";
import Modal from 'react-modal'; //npm install --save react-modal
import exportFromJSON from 'export-from-json'; //npm i --save export-from-json
import { SendEmailComponent } from '../Shared/SendEmailComponent';
export class ImportChequeComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isSubmitted: false,
            isRefreshSubmitted: false,
            loadPanelVisible: false,
            isProcessModalOpen: false,
            isImportCheque: false,
            isExportToXML: false,
            isExportToPdf: false,
            isEmailSubmitted: false,
            vehiclePayPeriodDropdownList: [],
            payPeriodDropdownDate: null,
            payPeriodDate: '',
            isAllVehicle: true,
            vehiclesDropdownList: [],
            vehicleDropdownData: null,
            vehicleId: 0,
            payoutDetailsGridDataSource: null,
            isPayrollDataFound: false,
            processStatus: 'Click on process to start...!',
            progressBarValue: 0,
            errors: {
                payPeriodDate: '',
                vehicle: '',
            }
        }
    }
    componentDidMount() {
        this.fetchApiData();
    }
    getPayPeriodDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('pay-period-api/0/dropdown-list', requestParams);
            if (response.ok) {
                const data = await response.json();
                if (data.isSuccess) {
                    let peyPeriodList = [];
                    if (data.vehiclePayPeriods !== null) {
                        data.vehiclePayPeriods.forEach((item) => {
                            if (item.isClosed === true && item.isChequeGenerated === false && item.ssSource !== null) {
                                if (item.ssSource.trim().length > 0) {
                                    let periodData = new Object();
                                    periodData.viewDate = item.viewDate;
                                    periodData.payEndDate = item.payEndDate;
                                    periodData.ssSource = item.ssSource;
                                    peyPeriodList.push(periodData);
                                }
                            }
                        });
                    }
                    this.setState({ vehiclePayPeriodDropdownList: peyPeriodList });
                }
            }
            //else {
            //    await this.onfailure('error', null, null,)
            //}
        } catch (ex) {
            console.log(ex);
        }
    }
    getVehiclesDropdownList = async () => {
        try {
            var request = new Object();
            request.IsActiveOnly = true;
            request.VehicleOwnerTypeIds = VehicleOwnerType.Franchisee.toString();
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('vehicle-api/dropdown', requestParams);
            if (response.ok) {
                const data = await response.json();
                if (data.isSuccess) {
                    this.setState({
                        vehiclesDropdownList: data.vehiclesDropdown
                    });
                }
            }
            //else {
            //    await this.onfailure('error', null, null,)
            //}
        } catch (ex) {
            console.log(ex);
        }
    }
    async fetchApiData() {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getPayPeriodDropdownList();
            await this.getVehiclesDropdownList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnToolbarPreparing = async (e) => {
        try {
            let isButtonDisable = !this.state.isPayrollDataFound;
            e.toolbarOptions.items.unshift({
                location: 'after',
                widget: 'dxButton',
                showText: 'always',
                options: {
                    disabled: isButtonDisable,
                    text: 'Import Cheques',
                    icon: 'fas fa-file-import',
                    onClick: this.handleOnImportCheque.bind(this),
                    hint: 'Import Cheques'
                }
            }, {
                location: 'after',
                widget: 'dxButton',
                showText: 'always',
                options: {
                    disabled: isButtonDisable,
                    text: 'Export to Excel',
                    icon: 'export-excel-button',//'export',
                    elementAttr: {
                        "class": "dx-datagrid-export-button"
                    },
                    onClick: function () {
                        e.component.option("export.fileName", "VehiclePayInvoiceCheque");
                        e.component.exportToExcel(false);
                    },
                    hint: 'Export to Excel'
                }
            }, {
                location: 'after',
                widget: 'dxButton',
                options: {
                    disabled: isButtonDisable,
                    text: 'Export to Xml',
                    icon: 'export',
                    onClick: this.handleOnExportToXML.bind(this),
                    hint: 'Export to Xml'
                }
            },
            //    {
            //    location: 'after',
            //    widget: 'dxButton',
            //    options: {
            //        disabled: isButtonDisable,
            //        text: 'Export to Pdf',
            //        icon: 'exportpdf',
            //        onClick: this.handleOnExportToPdf.bind(this),
            //        hint: 'Export to Pdf'
            //    }
            //}, {
            //    location: 'after',
            //    widget: 'dxButton',
            //    options: {
            //        disabled: isButtonDisable,
            //        icon: 'email',
            //        onClick: this.handleOnSendEmail.bind(this),
            //        hint: 'Email'
            //    }
            //},
                {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    onClick: this.handleOnRefreshGrid.bind(this),
                    hint: 'Refresh'
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnRefresh = async () => {
        try {
            this.setState({ isRefreshSubmitted: true, loadPanelVisible: true });
            await new Promise(resolve => setTimeout(resolve, 2000)); // 3 sec
            this.setState({
                isSubmitted: false,
                isRefreshSubmitted: false,
                loadPanelVisible: false,
                isProcessModalOpen: false,
                isImportCheque: false,
                isExportToXML: false,
                isExportToPdf: false,
                isEmailSubmitted: false,
                vehiclePayPeriodDropdownList: [],
                payPeriodDropdownDate: null,
                payPeriodDate: '',
                isAllVehicle: true,
                vehiclesDropdownList: [],
                vehicleDropdownData: null,
                vehicleId: 0,
                payoutDetailsGridDataSource: null,
                isPayrollDataFound: false,
                processStatus: 'Click on process to start...!',
                progressBarValue: 0,
                errors: {
                    payPeriodDate: '',
                    vehicle: '',
                }
            });
            await this.fetchApiData();
            this.dataGrid.instance.repaint();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isRefreshSubmitted: false, loadPanelVisible: false });
    }
    handleOnClearSearch = async (event) => {
        event.preventDefault();
        try {
            this.setState({
                isSubmitted: false,
                isRefreshSubmitted: false,
                loadPanelVisible: false,
                isProcessModalOpen: false,
                isImportCheque: false,
                isExportToXML: false,
                isExportToPdf: false,
                isEmailSubmitted: false,
                payPeriodDropdownDate: null,
                payPeriodDate: '',
                isAllVehicle: true,
                vehicleDropdownData: null,
                vehicleId: 0,
                payoutDetailsGridDataSource: null,
                isPayrollDataFound: false,
                processStatus: 'Click on process to start...!',
                progressBarValue: 0,
                errors: {
                    payPeriodDate: '',
                    vehicle: '',
                }
            });
            await new Promise(resolve => setTimeout(resolve, 1000)); // 1 sec
            this.dataGrid.instance.repaint();
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnPayPeriodDropdownChange = async (selectedOption) => {
        try {
            let errors = this.state.errors;
            errors.payPeriodDate = '';
            let payPeriodDate = '';
            let selectedPayPeriod = [];
            if (selectedOption !== null && selectedOption.selectedItem !== null) {
                payPeriodDate = moment(selectedOption.selectedItem.payEndDate, 'YYYYMMDD').format('MM/DD/YYYY');
                selectedPayPeriod = selectedOption.selectedItem;
            }
            else {
                errors.payPeriodDate = 'Please select payment period.';
            }
            this.setState({ payPeriodDropdownDate: selectedPayPeriod, payPeriodDate: payPeriodDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnVehicleDropdownChange = async (selectedOption) => {
        try {
            let errors = this.state.errors;
            errors.vehicle = '';
            let vehicleId = 0;
            let selectVehicleItem = [];
            if (selectedOption !== null && selectedOption.selectedItem !== null) {
                vehicleId = (selectedOption.selectedItem.id === null ? 0 : selectedOption.selectedItem.id);
                selectVehicleItem = selectedOption.selectedItem;
            }
            else {
                if (!this.state.isVehicleAllChecked) {
                    errors.vehicle = 'Please select vehicle.';
                }
            }
            this.setState({ vehicleDropdownData: selectVehicleItem, vehicleId: vehicleId, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnSearchSubmit = async (event) => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isSearchSubmitted: true, loadPanelVisible: true });
                let request = new Object();
                request.PayDate = moment(this.state.payPeriodDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59';
                request.VehicleId = Number(this.state.vehicleId);
                request.IsReExport = false;
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('vehicle-pay-pending-cheque-api/list', requestParams);
                const data = await response.json();
                this.setState({
                    payoutDetailsGridDataSource: data.vehiclePayrollInvoices
                });
                this.setState({
                    isPayrollDataFound: (data.vehiclePayrollInvoices !== null && data.vehiclePayrollInvoices.length > 0) || false
                });
                this.dataGrid.instance.repaint();
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSearchSubmitted: false, loadPanelVisible: false });
    }
    handleOnRefreshGrid = async () => {
        try {
            this.dataGrid.instance.refresh();
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnImportCheque = async (e) => {
        try {
            this.setState({ isImportCheque: true });
            e.component.option('icon', 'fas fa-spinner fa-spin');
            e.component.option('text', 'In-process');
            e.component.option('hint', 'In-process');
            await this.onfailure('warning', 'Unable to process', 'Thie feature is under development.');
        } catch (ex) {
            console.log(ex);
        }
        e.component.option('icon', 'fas fa-file-import');
        e.component.option('text', 'Import Cheques');
        e.component.option('hint', 'Import Cheques');
        this.setState({ isImportCheque: false });
    }
    handleOnExportToXML = async (e) => {
        try {
            this.setState({ isExportToXML: true });
            e.component.option('icon', 'fas fa-spinner fa-spin');
            e.component.option('text', 'In-process');
            e.component.option('hint', 'In-process');
            let fileName = 'VehiclePayInvoiceCheque';
            let exportType = 'xml';
            let data = this.state.payoutDetailsGridDataSource;
            exportFromJSON({ data, fileName, exportType });
        } catch (ex) {
            console.log(ex);
        }
        e.component.option('icon', 'export');
        e.component.option('text', 'Export to Xml');
        e.component.option('hint', 'Export to Xml');
        this.setState({ isExportToXML: false });
    }
    handleOnExportToPdf = async (e) => {
        try {
            this.setState({ isExportToPdf: true });
            e.component.option('icon', 'fas fa-spinner fa-spin');
            e.component.option('text', 'In-process');
            e.component.option('hint', 'In-process');
            await this.onfailure('warning', 'Unable to process', 'Thie feature is under development.');
        } catch (ex) {
            console.log(ex);
        }
        e.component.option('icon', 'exportpdf');
        e.component.option('text', 'Export to Pdf');
        e.component.option('hint', 'Export to Pdf');
        this.setState({ isExportToPdf: false, loadPanelVisible: false });
    }
    handleOnSendEmail = async (e) => {
        try {
            this.setState({ isEmailSubmitted: true });
            e.component.option('icon', 'fas fa-spinner fa-spin');
            e.component.option('hint', 'In-process');
            await this.onfailure('warning', 'Unable to process', 'Thie feature is under development.');
        } catch (ex) {
            console.log(ex);
        }
        e.component.option('icon', 'email');
        e.component.option('hint', 'Email');
        this.setState({ isEmailSubmitted: false });
    }
    onfailure = async (icon, title, resultMessage) => {
        this.setState({ loadPanelVisible: false });
        await Swal.fire({
            allowOutsideClick: false,
            showCloseButton: false,
            icon: icon,
            title: (title === null || title.trim() === '') ? 'Oops...' : title,
            text: (resultMessage === null || resultMessage.trim() === '') ? 'Opps! something went wrong, unable to process the request.' : resultMessage.trim(),
        })
    }
    isFormValid = async () => {
        let isValid = true;
        let errors = this.state.errors;
        errors.payPeriodDate = '';
        errors.vehicle = '';
        try {
            if (this.state.payPeriodDate.trim() === '') {
                errors.payPeriodDate = 'Please select payment period.';
                isValid = false;
            }
            if (this.state.isAllVehicle === false) {
                if (Number(this.state.vehicleId) === 0) {
                    errors.vehicle = 'Please select vehicle.';
                    isValid = false;
                }
            }
            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    render() {
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <h1 className="m-0 text-dark">Import Vehicle Pay Cheques</h1>
                            </div>
                            <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem>
                                        <NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="#" title="Refresh"
                                            onClick={this.handleOnRefresh}>
                                            {this.state.isRefreshSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isRefreshSubmitted && <span title="Refresh"><i className="fas fa-sync-alt"></i> Refresh</span>}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to={{
                                            pathname: "/franchisee/export/payroll",
                                            isReExport: false
                                        }}
                                            className="btn btn-block btn-outline-secondary btn-sm" title="Export Payroll">
                                            <i className="fas fa-file-export"></i> Export Payroll</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="/franchisee/export/appayroll" className="btn btn-block btn-outline-secondary btn-sm" title="Export AP Payroll">
                                            <i className="fas fa-file-export"></i> Export AP Payroll</NavLink>
                                    </NavItem>
                                    <NavItem >
                                        <NavLink tag={Link} to="/franchisee/chequeviewprint" className="btn btn-block btn-outline-secondary btn-sm" title="Cheque - View / Print">
                                            <i className="fas fa-file-pdf"></i> Cheque - View / Print</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="/reimbursement/view" className="btn btn-block btn-outline-secondary btn-sm" title="Reimbursement View">
                                            <i className="fas fa-car"></i> Reimb. View</NavLink>
                                    </NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <form
                    style={(
                        this.state.isSubmitted || this.state.isRefreshSubmitted || this.state.isImportCheque || this.state.isExportToXML
                        || this.state.isExportToPdf || this.state.isEmailSubmitted
                    ) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="rez-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>Payment Period</label>
                                        {/*<Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select period"
                                            defaultValue={[]}
                                            value={this.state.payPeriodDropdownDate}
                                            name="ddpayperiod"
                                            onChange={this.handleOnPayPeriodDropdownChange}
                                            getOptionLabel={option => option.viewDate}
                                            getOptionValue={option => option.payEndDate}
                                            options={this.state.vehiclePayPeriodDropdownList}
                                            isMulti={false}
                                            isClearable={true}
                                            isSearchable={true}
                                        />*/}
                                        <SelectBox
                                            dataSource={this.state.vehiclePayPeriodDropdownList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select period"
                                            name="ddpayperiod"
                                            defaultValue={''}
                                            value={(this.state.payPeriodDropdownDate !== null && this.state.payPeriodDropdownDate.payEndDate !== null) ? this.state.payPeriodDropdownDate.payEndDate : ''}
                                            displayExpr='viewDate'
                                            valueExpr='payEndDate'
                                            onSelectionChanged={this.handleOnPayPeriodDropdownChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["payPeriodDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["payPeriodDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12" >
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                id="isAllVehicle" name="isAllVehicle"
                                                checked={this.state.isAllVehicle}
                                                onChange={(e) => {
                                                    let errors = this.state.errors;
                                                    errors.vehicle = '';
                                                    this.setState({
                                                        isAllVehicle: e.target.checked,
                                                        vehicleDropdownData: null,
                                                        vehicleId: 0,
                                                        errors: errors
                                                    })
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor="isAllVehicle"> All Vehicle</label>
                                        </div>
                                        {/*<Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select vehicle"
                                            value={this.state.vehicleDropdownData}
                                            name="ddvehicle"
                                            onChange={this.handleOnVehicleDropdownChange}
                                            getOptionLabel={option => option.name}
                                            getOptionValue={option => option.id}
                                            options={this.state.vehiclesDropdownList}
                                            isMulti={false}
                                            isClearable={true}
                                            isDisabled={this.state.isAllVehicle}
                                            isSearchable={true}
                                            width="100%"
                                        />*/}
                                        <SelectBox
                                            dataSource={this.state.vehiclesDropdownList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select vehicle"
                                            name="ddvehicle"
                                            defaultValue={0}
                                            value={this.state.vehicleId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnVehicleDropdownChange}
                                            showClearButton={true}
                                            disabled={this.state.isAllVehicle}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["vehicle"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["vehicle"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label className="btn-block">&nbsp;</label>
                                        <button type="button" className="btn btn-success btn-sm" title="Search" onClick={this.handleOnSearchSubmit} >
                                            {this.state.isSearchSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSearchSubmitted && <span title="Search"><i className="fas fa-search"></i> Search</span>}
                                        </button>&nbsp;&nbsp;
                                                 <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                            onClick={this.handleOnClearSearch}>
                                            <i className="fas fa-eraser"></i> Clear
                                                  </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="content">
                    <div className="card">
                        <div className="card-body">
                            <DataGrid
                                ref={(ref) => this.dataGrid = ref}
                                dataSource={this.state.payoutDetailsGridDataSource}
                                keyExpr="payId"
                                selection={{ mode: 'single' }}
                                columnsAutoWidth={true}
                                showColumnLines={false}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                showBorders={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                width='100%'
                                onToolbarPreparing={this.handleOnToolbarPreparing}
                            >
                                <Column dataField="payId" caption="Pay No." alignment="center" width={'auto'} />
                                <Column dataField="vehicleNo" caption="Vehicle" />
                                <Column dataField="vehicleTypeName" caption="Charter" />
                                <Column dataField="franchiseeName" caption="Franchisee" />
                                <Column dataField="ssInvoiceId" caption="Ora. Invoice No" alignment="center" />
                                <Column dataField="chequeNo" caption="Cheque No." alignment="center" />
                                <Column dataField="chequeAmount" caption="Inv. Amount" alignment="center" width={'auto'} />
                                <Column dataField="vendorId" caption="Vendor Id" alignment="center" width={'auto'}/>
                                <Column dataField="vendorNo" caption="Vendor No." />
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={false} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                <Export enabled={false} allowExportSelectedData={false} />
                                <ColumnChooser enabled={false} />
                                <ColumnFixing enabled={false} />
                            </DataGrid>
                        </div>
                    </div>
                </div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isProcessModalOpen}
                    style={{ content: { width: '35%' } }}
                    className={"react-modal"}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <div className="modal-body">
                        <div className="row mt-2">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                                <h5><strong>{'Processing...'}</strong></h5>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                                <div className="form-group">
                                    <span className="text-success">{this.state.processStatus}</span>
                                </div>
                            </div>
                            <div style={{ display: Number(this.state.progressBarValue) > 0 || 'none' }} className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="form-group">
                                    <ProgressBar key={this.state.progressBarValue}
                                        bgcolor={'#28a745'} completed={this.state.progressBarValue} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        )
    }
}