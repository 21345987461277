import React, { Component, Fragment } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { MasterHistoryComponent } from '../Shared/MasterHistoryComponent';
import { DocumentsComponent } from '../Shared/DocumentsComponent';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import NumberFormat from 'react-number-format';
import { LoadPanel } from 'devextreme-react/load-panel';
import { SelectBox } from 'devextreme-react/select-box';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import DateBox from 'devextreme-react/date-box';
export class DriverDocumentComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            loadPanelMessage: 'Loading...',
            loadPanelWidth: '15%',
            isRefreshSubmited: false,
            isForDefaultUserEmailSubmited: false,
            isOpenDocumentModal: false,
            isViewOnly: false,
            driverDocumentGridDataSource: null,
            driverDocumentData: null,
            driversDropdownList: [],
            driversDropdownData: null,
            documentTypeDropdownList: [],
            documentTypeDropdownData: null,
            id: 0,
            driverId: 0,
            documentTypeId: 0,
            documentName: '',
            refNo: '',
            validDateFrom: moment(new Date()).format("MM/DD/YYYY"),
            expiryDate: new Date(moment(new Date()).add(15, 'days').format("MM/DD/YYYY")),
            isRemind: false,
            daysRemider: 0,
            description: '',
            isActive: false,
            emailDetailsFrom: '',
            emailDetailsTo: '',
            emailDetailsCC: '',
            emailDetailsSubject: 'Driver Documents ' + moment(new Date()).format("MM/DD/YYYY HH:mm:ss"),
            isDisplayDriverList: this.props.isDisplayDriverList === "false" ? false : true,
            errors: {
                driver: '',
                documentType: '',
                documentName: '',
                refNo: '',
                validDateFrom: '',
                daysRemider: '',
                emailDetailsFrom: '',
                emailDetailsTo: '',
                emailDetailsCC: '',
            }
        }
    }

    async componentDidMount() {
        this.fetchApiData();
    };
    getDriverDocuments = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            let requestDriverId = 0;
            var request = new Object();
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('driver-document-api/' + Number(requestDriverId) + '/list', requestParams);
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    driverDocumentGridDataSource: data.driverProfileDocuments
                });
            }
        } catch (ex) {
            console.log(ex);
            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Unable to process the request.',
            })
        }
        this.setState({ loadPanelVisible: false });
    }
    getDriversDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('driver-api/dropdown-all/', requestParams);
            const data = await response.json();
            this.setState({
                driversDropdownList: data.driversDropdownList
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    getDocumentTypeDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('driver-document-api/documenttype', requestParams);
            const data = await response.json();
            this.setState({
                documentTypeDropdownList: data.profileDocumentsTypes
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    fetchApiData = async () => {
        try {
            await this.getDriverDocuments();
            this.setState({ loadPanelVisible: true });
            await this.getDriversDropdownList();
            await this.getDocumentTypeDropdownList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getDriverDocuments.bind(this)
            }
        });
    }
    handleOnRefresh = async () => {
        try {
            this.setState({ isRefreshSubmited: true });
            let errors = this.state.errors;
            errors.driver = '';
            errors.documentType = '';
            errors.documentName = '';
            errors.refNo = '';
            errors.validDateFrom = '';
            errors.daysRemider = '';
            errors.emailDetailsFrom = '';
            errors.emailDetailsTo = '';
            errors.emailDetailsCC = '';
            this.setState({
                isSubmited: false,
                isForDefaultUserEmailSubmited: false,
                isOpenDocumentModal: false,
                loadPanelMessage: 'Loading...',
                loadPanelWidth: '15%',
                isViewOnly: false,
                driverDocumentGridDataSource: null,
                driverDocumentData: null,
                driversDropdownList: [],
                driversDropdownData: null,
                documentTypeDropdownList: [],
                documentTypeDropdownData: null,
                id: 0,
                driverId: 0,
                documentTypeId: 0,
                documentName: '',
                refNo: '',
                validDateFrom: moment(new Date()).format("MM/DD/YYYY"),
                expiryDate: new Date(moment(new Date()).add(15, 'days').format("MM/DD/YYYY")),
                isRemind: false,
                daysRemider: 0,
                description: '',
                isActive: false,
                emailDetailsFrom: '',
                emailDetailsTo: '',
                emailDetailsCC: '',
                emailDetailsSubject: 'Driver Documents ' + moment(new Date()).format("MM/DD/YYYY HH:mm:ss"),
                errors: errors
            });
            await this.fetchApiData();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isRefreshSubmited: false });
    }
    showModal = (data) => {
        // e.preventDefault();
        this.setState({ isOpenDocumentModal: true, driverId: data.id });
    }
    handleOnAddDriverDocument = (e) => {
        e.preventDefault();
        try {
            this.setState({
                isOpenDocumentModal: true,
                driverDocumentData: null
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnEditDriverDocument = (e, data) => {
        e.preventDefault();
        try {
            if (data !== null) {
                this.setState({
                    isOpenDocumentModal: true,
                    driverDocumentData: data
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnViewDriverDocument = async (e, data) => {
        e.preventDefault();
        try {
            if (data !== null) {
                this.setState({
                    isOpenDocumentModal: true,
                    driverDocumentData: data,
                    isViewOnly: true,
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnGoToUserEmailView = async (e, data) => {
        e.preventDefault();
        try {
            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                icon: 'warning',
                title: 'Unable to redirect page.',
                text: 'User email view is under development.',
            })
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnViewUploadDocument = async (e, data) => {
        e.preventDefault();
        try {
            let documentDisplayName = data.documentName === null ? '' : data.documentName.trim();
            var documentArray = []
            let row = new Object();
            row.id = data.id === null ? 0 : Number(data.id);
            row.driverName = data.driverName === null ? '' : data.driverName.trim();
            row.documentTypeName = data.documentTypeName === null ? '' : data.documentTypeName.trim();
            row.documentName = data.documentName === null ? '' : data.documentName.trim();
            row.documentRefNo = data.documentRefNo === null ? '' : data.documentRefNo.trim();
            documentArray.push(row);
            //this.refs.documentsComponent.showModal('Driver Document', documentArray, documentDisplayName);
            this.refs.documentsComponent.showModal('Driver Document', documentArray, '');
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnLoadDefaultUserEmail = async (e) => {
        e.preventDefault();
        try {
            this.setState({ isForDefaultUserEmailSubmited: true });
            await this.getUserEmailData(true, 0);
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isForDefaultUserEmailSubmited: false });
    }
    handleOnGoToMasterHistoryModal = async (e, data) => {
        e.preventDefault();
        try {
            this.refs.masterHistoryComponent.showModal('driver-document-api', 'Driver', data.driverName, data.driverId === null ? 0 : Number(data.driverId));
        } catch (ex) {
            console.log(ex);
        }
    }
    getUserEmailData = async (isLoadDefault, documentDetailId) => {
        try {
            this.setState({ loadPanelVisible: true, loadPanelMessage: 'Loading user email. Please, wait...', loadPanelWidth: '20%' });
            let emailDetailsFrom = '';
            let emailDetailsTo = '';
            let emailDetailsCC = '';
            let emailDetailsSubject = 'Driver Documents ' + moment(new Date()).format("MM/DD/YYYY HH:mm:ss");
            const requestParams = getRequestParams('GET', '');
            if (isLoadDefault === true) {
                const responseDefaultEmail = await fetch('driver-document-api/fetch-default-users-email-list', requestParams);
                const dataDefaultEmail = await responseDefaultEmail.json();
                if (responseDefaultEmail.ok) {
                    if (dataDefaultEmail.isSuccess && dataDefaultEmail.groupUsersEmailLogs !== null) {
                        if (dataDefaultEmail.groupUsersEmailLogs.length > 0) {
                            emailDetailsFrom = dataDefaultEmail.groupUsersEmailLogs[0].fromEmailId;
                            emailDetailsTo = dataDefaultEmail.groupUsersEmailLogs[0].toEmailId;
                        }
                    }
                }
                this.setState({
                    emailDetailsFrom: emailDetailsFrom,
                    emailDetailsTo: emailDetailsTo,
                    emailDetailsSubject: emailDetailsSubject
                });
            }
            else {
                const responseEmailLog = await fetch('driver-document-api/' + Number(documentDetailId) + '/fetch-driver-document-email-log', requestParams);
                const dataEmailLog = await responseEmailLog.json();
                if (responseEmailLog.ok) {
                    if (dataEmailLog.isSuccess && dataEmailLog.groupUsersEmailLogs !== null) {
                        if (dataEmailLog.groupUsersEmailLogs.length > 0) {
                            emailDetailsFrom = dataEmailLog.groupUsersEmailLogs[0].fromEmailId;
                            emailDetailsTo = dataEmailLog.groupUsersEmailLogs[0].toEmailId;
                            emailDetailsCC = dataEmailLog.groupUsersEmailLogs[0].ccEmailId;
                            emailDetailsSubject = dataEmailLog.groupUsersEmailLogs[0].emailSubject;
                        }
                    }
                }
                this.setState({
                    emailDetailsFrom: emailDetailsFrom,
                    emailDetailsTo: emailDetailsTo,
                    emailDetailsCC: emailDetailsCC,
                    emailDetailsSubject: emailDetailsSubject
                });
            }
        } catch (ex) {
            console.log(ex);
        }
        await this.defualtLoader();
    }
    handleAssignDataToState = async () => {
        try {
            if (this.state.driverDocumentData !== null) {
                const data = this.state.driverDocumentData;
                let assigneddriver = [];
                let driver = new Object();
                if (Number(data.driverId) > 0) {
                    driver.id = Number(data.driverId);
                    driver.name = data.driverName === null ? '' : data.driverName.trim();
                    assigneddriver.push(driver);
                }
                let assignedDocumentType = [];
                let dt = new Object();
                if (Number(data.documentTypeId) > 0) {
                    dt.id = Number(data.documentTypeId);
                    dt.name = data.documentTypeName === null ? '' : data.documentTypeName.trim();
                    assignedDocumentType.push(dt);
                }
                let validDateFrom = (data.validFrom === null || data.validFrom.trim() === '') ? '' : moment(new Date(data.validFrom)).format("MM/DD/YYYY");
                let expiryDate = (data.validUpTo === null || data.validUpTo.trim() === '') ? '' : moment(new Date(data.validUpTo)).format("MM/DD/YYYY");
                this.setState({
                    id: data.id === null ? 0 : Number(data.id),
                    driversDropdownData: assigneddriver,
                    documentTypeDropdownData: assignedDocumentType,
                    driverId: data.driverId === null ? 0 : Number(data.driverId),
                    documentTypeId: data.documentTypeId === null ? 0 : Number(data.documentTypeId),
                    documentName: data.documentName === null ? '' : data.documentName.trim(),
                    refNo: data.documentRefNo === null ? '' : data.documentRefNo.trim(),
                    validDateFrom: validDateFrom,
                    expiryDate: expiryDate,
                    isRemind: data.isReminder || false,
                    daysRemider: data.daysReminder === null ? 0 : Number(data.daysReminder),
                    description: data.description === null ? '' : data.description.trim(),
                    isActive: data.isActive || false,
                });
                await this.getUserEmailData(false, Number(data.id));
            }
            else {
                await this.getUserEmailData(true, 0);
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    handleResetStateData = async () => {
        try {
            await this.resetStateData();
        } catch (ex) {
            console.log(ex);
        }
    }
    resetStateData = async () => {
        try {
            this.setState({
                isSubmited: false,
                loadPanelVisible: false,
                loadPanelMessage: 'Loading...',
                loadPanelWidth: '15%',
                isRefreshSubmited: false,
                isForDefaultUserEmailSubmited: false,
                isOpenDocumentModal: false,
                isViewOnly: false,
                driverDocumentData: null,
                driversDropdownData: null,
                documentTypeDropdownData: null,
                id: 0,
                driverId: 0,
                documentTypeId: 0,
                documentName: '',
                refNo: '',
                validDateFrom: moment(new Date()).format("MM/DD/YYYY"),
                expiryDate: new Date(moment(new Date()).add(15, 'days').format("MM/DD/YYYY")),
                isRemind: false,
                daysRemider: 0,
                description: '',
                isActive: false,
                emailDetailsFrom: '',
                emailDetailsTo: '',
                emailDetailsCC: '',
                emailDetailsSubject: 'Driver Documents ' + moment(new Date()).format("MM/DD/YYYY HH:mm:ss"),
                errors: {
                    driver: '',
                    documentType: '',
                    documentName: '',
                    refNo: '',
                    validDateFrom: '',
                    daysRemider: '',
                    emailDetailsFrom: '',
                    emailDetailsTo: '',
                    emailDetailsCC: '',
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnDriverChange = async (selectedOption) => {
        try {
            let errors = this.state.errors;
            errors.driver = '';
            let driverId = 0;
            let driverSelectedItem = [];
            if (selectedOption === null || selectedOption.selectedItem === null) {
                errors.driver = 'Please select driver.';
            }
            else {
                driverId = (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0);
                driverSelectedItem = selectedOption.selectedItem;
            }
            this.setState({ driversDropdownData: driverSelectedItem, errors: errors, driverId: driverId });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnDocumentTypeChange = async (selectedOption) => {
        try {
            let errors = this.state.errors;
            errors.documentType = '';
            let documentTypeId = 0;
            let documentTypeSelectedItem = [];
            if (selectedOption === null || selectedOption.selectedItem === null) {
                errors.documentType = 'Please select document type.';
            }
            else {
                documentTypeId = (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0);
                documentTypeSelectedItem = selectedOption.selectedItem;
            }
            this.setState({ documentTypeDropdownData: documentTypeSelectedItem, errors: errors, documentTypeId: documentTypeId });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnValidFromDateChange = (date) => {
        try {
            let errors = this.state.errors;
            errors.validDateFrom = '';
            let dateExp = '';
            let validDateFrom = '';
            if (date.value !== null && date.value !== '') {
                validDateFrom = moment(date.value).format("MM/DD/YYYY");
            }
            else {
                errors.validDateFrom = 'Please select date.';
            }
            this.setState({ validDateFrom: validDateFrom, expiryDate: dateExp, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnExpiryDateChange = (date) => {
        try {
            let errors = this.state.errors;
            let expiryDate = '';
            if (date.value !== null && date.value !== '') {
                expiryDate = moment(date.value).format("MM/DD/YYYY");
            }
            this.setState({ expiryDate: expiryDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleInputChange = (event) => {
        try {
            const target = event.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;
            let errors = this.state.errors;
            let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            switch (name) {
                case 'documentName':
                    errors.documentName = '';
                    if (value.trim() === '') {
                        errors.documentName = "Please enter document name.";
                    }
                    if (value.length < 76) {
                        this.setState({ documentName: value });
                    }
                    break;
                case 'refNo':
                    errors.refNo = '';
                    if (value.trim() === '') {
                        errors.refNo = "Please enter document reference number.";
                    }
                    if (value.length < 76) {
                        this.setState({ refNo: value });
                    }
                    break;
                case 'isRemind':
                    errors.daysRemider = '';
                    if (value === false) {
                        this.setState({ daysRemider: 0 });
                    }
                    else {
                        if (Number(this.state.daysRemider) < 1) {
                            errors.daysRemider = 'Please enter reminder days.';
                        }
                    }
                    this.setState({ isRemind: value });
                    break;
                case 'daysRemider':
                    errors.daysRemider = '';
                    if (this.state.isRemind === true) {
                        if (value.trim() === '') {
                            errors.daysRemider = "Please enter reminder days.";
                        }
                    }
                    if (value.toString().length < 3) {
                        this.setState({ daysRemider: Number(value) });
                    }
                    break;
                case 'description':
                    if (value.length < 151) {
                        this.setState({ description: value });
                    }
                    break;
                case 'emailDetailsFrom':
                    errors.emailDetailsFrom = "";
                    if (value.trim() !== '') {
                        let email = value.split(',');
                        if (email.length > 1) {
                            errors.emailDetailsFrom = 'Please enter valid email.';
                        }
                        else {
                            if (regEmail.test(String(value.trim()).toLowerCase()) === false) {
                                errors.emailDetailsFrom = 'Please enter valid email.';
                            }
                        }
                    }
                    this.setState({ emailDetailsFrom: value })
                    break;
                case 'emailDetailsTo':
                    errors.emailDetailsTo = "";
                    if (value.trim() !== '') {
                        let email = value.split(',');
                        if (email.length > 0) {
                            for (let i = 0; i < email.length; i++) {
                                if ((email[i]).trim() !== "") {
                                    if (!regEmail.test(String(email[i].trim()).toLowerCase())) {
                                        errors.emailDetailsTo = "Please enter valid email.";
                                    }
                                }
                            }
                        }
                    }
                    this.setState({ emailDetailsTo: value })
                    break;
                case 'emailDetailsCC':
                    errors.emailDetailsCC = "";
                    if (value.trim() !== '') {
                        let email = value.split(',');
                        if (email.length > 0) {
                            for (let i = 0; i < email.length; i++) {
                                if ((email[i]).trim() !== "") {
                                    if (!regEmail.test(String(email[i].trim()).toLowerCase())) {
                                        errors.emailDetailsCC = "Please enter valid email.";
                                    }
                                }
                            }
                        }
                    }
                    this.setState({ emailDetailsCC: value })
                    break;
                case 'emailDetailsSubject':
                    if (value.length < 151) {
                        this.setState({ emailDetailsSubject: value });
                    }
                    break;
                default:
                    this.setState({
                        [name]: value
                    });
                    break;
            }
            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnDriverDocumentSubmit = async (e) => {
        
        e.preventDefault();
        try {
            
            if (await this.isFormValid()) {
                let isConfirm = false;
                await Swal.fire({
                    icon: 'question',
                    title: '<small><b>Confirmation</b></small>',
                    html: "<span style='font-size: 20px;'>" + (Number(this.state.id) > 0 ? 'Modify document - ' + this.state.documentName : ' New driver document')
                        + ".</span><br>Are you sure want to " + (Number(this.state.id) > 0 ? ' update' : 'add') + " this document?",
                    //showCloseButton: true,
                    showCancelButton: true,
                    focusConfirm: false,
                    confirmButtonText: "<i className='fa fa-check'></i> <span style='font-size:14px;'>Yes, " + (Number(this.state.id) > 0 ? ' Update' : 'Save') + " </span>",
                    cancelButtonText: 'No',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    dangerMode: false,
                    allowOutsideClick: false
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        isConfirm = true;
                    }
                });
                if (isConfirm) {
                    this.setState({ isSubmited: true })
                    this.setState({
                        loadPanelVisible: true,
                        loadPanelMessage: 'Processing document. Please, wait...',
                        loadPanelWidth: '20%'
                    });
                    
                    var request = new Object();
                    request.DocumentTypeId = Number(this.state.documentTypeId);
                    request.DriverId = Number(this.state.driverId);
                    request.DocumentName = this.state.documentName.trim();
                    request.DocumentRefNo = this.state.refNo.trim();
                    request.ValidFrom = this.state.validDateFrom;
                    if (this.state.expiryDate !== null && this.state.expiryDate !== '') {
                        request.ValidUpTo = formatDate(this.state.expiryDate, "MM/dd/yyyy"); //this.state.expiryDate;
                    }
                    request.IsReminder = this.state.isRemind;
                    request.Description = this.state.description.trim();
                    request.DaysRemider = Number(this.state.daysRemider);
                    request.IsActive = this.state.id > 0 ? this.state.isActive : true;
                    const requestParams = getRequestParams('POST', request);
                    const response = await fetch('driver-document-api/' + Number(this.state.id) + '/save-document', requestParams);
                    if (response.ok) {
                        const data = await response.json();
                        if (data.isSuccess) {
                            await this.insertEmailLog(Number(data.retValue));
                        }
                        else {
                            await this.defualtLoader();
                        }
                        await Swal.fire({
                            icon: data.isSuccess ? 'success' : 'error',
                            title: data.isSuccess ? 'Success' : 'Oops...',
                            text: (data.isSuccess === false && (data.resultMessage === null || data.resultMessage === "")) ? 'Unable to process the request.' : data.resultMessage,
                        }).then(async (result) => {
                            if (data.isSuccess) {
                                if (result.isConfirmed) {
                                    await this.handleResetStateData();
                                    await this.getDriverDocuments();
                                }
                            }
                        })
                    }
                    else {
                        this.onfailure('error', 'Something went wrong! Unable to process the request.');
                    }
                }
            }
        } catch (ex) {
            console.log(ex);
            this.onfailure('error', 'Something went wrong! Unable to process the request.');
        }
        this.setState({ isSubmited: false })
    }
    isFormValid = async () => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.driver = '';
            errors.documentType = '';
            errors.documentName = '';
            errors.refNo = '';
            errors.validDateFrom = '';
            errors.daysRemider = '';
            errors.emailDetailsFrom = '';
            errors.emailDetailsTo = '';
            errors.emailDetailsCC = '';
            let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (this.state.driversDropdownData === null || Number(this.state.driverId) === 0) {
                formIsValid = false;
                errors.driver = "Please select driver.";
            }
            if (this.state.documentTypeDropdownData === null || Number(this.state.documentTypeId) === 0) {
                formIsValid = false;
                errors.documentType = "Please select document type.";
            }
            if (this.state.documentName.trim() === '') {
                formIsValid = false;
                errors.documentName = "Please select document name.";
            }
            if (this.state.refNo.trim() === '') {
                formIsValid = false;
                errors.refNo = "Please enter document reference number.";
            }
            if (this.state.validDateFrom === null || this.state.validDateFrom.trim() === '') {
                formIsValid = false;
                errors.validDateFrom = "Please select date.";
            }
            if (this.state.isRemind === true) {
                if (Number(this.state.daysRemider) < 1) {
                    formIsValid = false;
                    errors.daysRemider = 'Please enter reminder days.';
                }
            }
            if (this.state.emailDetailsFrom.trim() !== '') {
                let email = this.state.emailDetailsFrom.split(',');
                if (email.length > 1) {
                    formIsValid = false;
                    errors.emailDetailsFrom = 'Please enter valid email.';
                }
                else {
                    if (regEmail.test(String(this.state.emailDetailsFrom.trim()).toLowerCase()) === false) {
                        formIsValid = false;
                        errors.emailDetailsFrom = 'Please enter valid email.';
                    }
                }
            }
            if (this.state.emailDetailsTo.trim() !== '') {
                let email = this.state.emailDetailsTo.split(',');
                if (email.length > 0) {
                    for (let i = 0; i < email.length; i++) {
                        if ((email[i]).trim() !== "") {
                            if (!regEmail.test(String(email[i].trim()).toLowerCase())) {
                                formIsValid = false;
                                errors.emailDetailsTo = "Please enter valid email.";
                            }
                        }
                    }
                }
            }
            if (this.state.emailDetailsCC.trim() !== '') {
                let email = this.state.emailDetailsCC.split(',');
                if (email.length > 0) {
                    for (let i = 0; i < email.length; i++) {
                        if ((email[i]).trim() !== "") {
                            if (!regEmail.test(String(email[i].trim()).toLowerCase())) {
                                formIsValid = false;
                                errors.emailDetailsCC = "Please enter valid email.";
                            }
                        }
                    }
                }
            }
            this.setState({ errors: errors });
        } catch (ex) {
            formIsValid = false;
            console.log(ex);
        }
        return formIsValid;
    }
    insertEmailLog = async (documentDetailId) => {
        try {
            if (this.state.emailDetailsFrom.trim() !== '' && this.state.emailDetailsTo.trim() !== '') {

                this.setState({
                    loadPanelVisible: true,
                    loadPanelMessage: 'Cleaning up previous email notification. Please, wait...',
                    loadPanelWidth: '20%'
                });
                //await new Promise(resolve => setTimeout(resolve, 2000)); // 2 sec
                const requestParamsDelete = getRequestParams('DELETE');
                const responseDelete = await fetch('driver-document-api/' + Number(documentDetailId) + '/delete-driver-document-email-log', requestParamsDelete);
                const dataDelete = await responseDelete.json();
                this.setState({
                    loadPanelVisible: true,
                    loadPanelMessage: 'Processing email notification. Please, wait...',
                    loadPanelWidth: '15%'
                });
                //await new Promise(resolve => setTimeout(resolve, 2000)); // 2 sec
                var request = new Object();
                request.FromEmailId = this.state.emailDetailsFrom.trim();
                request.ToEmailId = this.state.emailDetailsTo.trim();
                request.CCEmailId = this.state.emailDetailsCC.trim();
                request.EmailSubject = this.state.emailDetailsSubject.trim();
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('driver-document-api/' + Number(documentDetailId) + '/' + Number(this.state.driverId) + '/save-driver-document-email-log', requestParams);
                const data = await response.json();
                let isSuccessSave = false;
                if (response.ok) {
                    const data = await response.json();
                    isSuccessSave = data.isSuccess;
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        await this.defualtLoader();
    }
    onfailure = (title, resultMessage) => {
        try {
            this.defualtLoader();
            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                icon: 'error',
                title: (title === null || title.trim() === '') ? 'Oops...' : title,
                text: (resultMessage === null || resultMessage.trim() === '') ? 'Something went wrong!' : resultMessage.trim(),
            })
        } catch (ex) {
            console.log(ex);
        }
    }
    defualtLoader = async () => {
        try {
            this.setState({
                loadPanelVisible: false,
                loadPanelMessage: 'Loading...',
                loadPanelWidth: '15%'
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    render() {
        const renderGridCell = (data) => {
            let uploadDocumentLinkIcon = 'fas fa-upload'
            if (data.data.isFoundDocumentUploaded === false) {
                uploadDocumentLinkIcon = 'text-danger ' + uploadDocumentLinkIcon
            }
            let editLink = <a href="#" title="Edit" onClick={(e) => { this.handleOnEditDriverDocument(e, data.data) }} ><i className="fas fa-pen"></i></a>
            let viewLink = <a href="#" title="View" style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleOnViewDriverDocument(e, data.data) }} >
                <i className="fas fa-eye"></i></a>
            let userEmailLink = <a href="#" title="User Email" style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleOnGoToUserEmailView(e, data.data) }} >
                <i className="fas fa-envelope"></i></a>
            let documentLink = <a href="#" title="Upload/View Document" style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleOnViewUploadDocument(e, data.data) }} >
                <i className={uploadDocumentLinkIcon}></i></a>
            let historyLink = <a href="#" title="History" style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleOnGoToMasterHistoryModal(e, data.data) }}>
                <i className="fas fa-history"></i></a>
            return (
                <div style={{ textAlign: 'left', width: 'auto' }}>
                    {editLink}
                    {viewLink}
                    {userEmailLink}
                    {documentLink}
                    {historyLink}
                </div>
            );
        }
        return (
            <Fragment>
                {this.state.isDisplayDriverList === true ?
                    <div className="content-wrapper">
                        <div className="content-header">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                        <h1 className="m-0 text-dark">Driver Documents</h1>
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                                        <ol className="breadcrumb float-sm-right">
                                            <NavItem>
                                                <NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="#" title="Refresh"
                                                    onClick={this.handleOnRefresh}>
                                                    {this.state.isRefreshSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                                    {!this.state.isRefreshSubmited && <span title="Refresh"><i className="fas fa-sync-alt"></i> Refresh</span>}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem><NavLink className="btn btn-block btn-outline-secondary btn-sm" onClick={this.handleOnAddDriverDocument}
                                                title="Add Document"><i className="fas fa-plus"></i> Add Document</NavLink></NavItem>
                                            <NavItem><NavLink tag={Link} to="/franchisee/addons" className="btn btn-block btn-outline-secondary btn-sm" title="Addons"><i className="fas fa-puzzle-piece"></i> Addons</NavLink></NavItem>
                                            <NavItem><NavLink tag={Link} to="/franchisee/deductions" className="btn btn-block btn-outline-secondary btn-sm" title="Deduction"><i className="fas fa-minus-circle"></i> Deduction</NavLink></NavItem>
                                            <NavItem><NavLink tag={Link} to="/franchisee/deposits" className="btn btn-block btn-outline-secondary btn-sm" title="Deposites"><i className="fas fa-hand-holding-usd"></i> Deposites</NavLink></NavItem>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="card card-grid">
                                <div className="card-body">
                                    <DataGrid
                                        dataSource={this.state.driverDocumentGridDataSource}
                                        keyExpr="id"
                                        selection={{ mode: 'single' }}
                                        columnsAutoWidth={false}
                                        showColumnLines={true}
                                        showRowLines={true}
                                        showBorders={true}
                                        rowAlternationEnabled={true}
                                        allowColumnResizing={true}
                                        columnResizingMode='widget'
                                        onToolbarPreparing={this.handleToolbarPreparing}>
                                        <Column dataField="id" width={95} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                        <Column dataField="documentTypeName" caption="Document Type" />
                                        <Column dataField="driverName" caption="Driver" />
                                        <Column dataField="documentName" caption="Document" />
                                        <Column dataField="documentRefNo" caption="Document Ref. No." />
                                        <Column dataField="validFrom" caption="Valid From" width={"auto"} />
                                        <Column dataField="validUpTo" caption="Valid Upto" width={"auto"} />
                                        <Column dataField="description" caption="Description" />
                                        <Column dataField="reminderStatus" caption="Reminder" width={"auto"} alignment={'center'} />
                                        <Column dataField="daysReminder" caption="Reminder Days" width={"auto"} alignment={'center'} />
                                        <Column dataField="activeStatus" caption="Status" width={"auto"} alignment={'center'} />
                                        <SortByGroupSummaryInfo summaryItem="count" />
                                        <GroupPanel visible={true} />
                                        <HeaderFilter visible={false} />
                                        <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                        <Grouping autoExpandAll={true} />
                                        <Paging defaultPageSize={20} />
                                        <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true} />
                                        <Export enabled={true} allowExportSelectedData={false} />
                                        <ColumnChooser enabled={true} />
                                        <ColumnFixing enabled={true} />

                                    </DataGrid>
                                </div>
                            </div>
                        </div>
                        <DocumentsComponent ref="documentsComponent" />
                        <MasterHistoryComponent ref="masterHistoryComponent" />
                        <LoadPanel
                            shadingColor="rgba(0,0,0,0.4)"
                            position={{ of: '#panelgrid' }}
                            visible={this.state.loadPanelVisible}
                            showIndicator={true}
                            shading={true}
                            showPane={true}
                            message={this.state.loadPanelMessage}
                            closeOnOutsideClick={false}
                            width={this.state.loadPanelWidth}
                        />
                    </div> : null
                }
                <div>
            
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenDocumentModal}
                    style={{ content: { width: '70%' } }}
                    className={"react-modal edit-driver-document-modal"}
                    onAfterOpen={this.handleAssignDataToState}
                    onRequestClose={this.handleResetStateData}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal"
                >
                    <form method="post" onSubmit={this.handleOnDriverDocumentSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{(this.state.id > 0 ? 'Modify' + (this.state.isViewOnly ? ' (Read only)' : '') : 'Add') + ' driver document'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleResetStateData}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                    <div className="form-group">
                                        <label>Driver <span className="text-red">*</span></label>
                                        {/*<Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="---Select Driver---"
                                            defaultValue={[]}
                                            value={this.state.driversDropdownData}
                                            name="dddriver"
                                            onChange={this.handleOnDriverChange}
                                            getOptionLabel={option => option.name}
                                            getOptionValue={option => option.id}
                                            options={this.state.driversDropdownList}
                                            isMulti={false}
                                            isClearable={true}
                                            isDisabled={(this.state.isViewOnly || Number(this.state.id) > 0)}
                                            isSearchable={true}
                                        />*/}
                                        <SelectBox
                                            dataSource={this.state.driversDropdownList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select Driver"
                                            name="dddriver"
                                            defaultValue={0}
                                            value={this.state.driverId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnDriverChange}
                                            showClearButton={true}
                                            disabled={(this.state.isViewOnly || Number(this.state.id) > 0 || Number(this.state.driverId) > 0)}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["driver"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["driver"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                    <div className="form-group">
                                        <label>Document Type<span className="text-danger">*</span></label>
                                        {/*<Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="---Select Document Type---"
                                            defaultValue={[]}
                                            value={this.state.documentTypeDropdownData}
                                            name="dddriverdocumenttype"
                                            onChange={this.handleOnDocumentTypeChange}
                                            getOptionLabel={option => option.name}
                                            getOptionValue={option => option.id}
                                            options={this.state.documentTypeDropdownList}
                                            isMulti={false}
                                            isClearable={true}
                                            isDisabled={(this.state.isViewOnly || Number(this.state.id) > 0)}
                                            isSearchable={true}
                                        />*/}
                                        <SelectBox
                                            dataSource={this.state.documentTypeDropdownList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select Document Type"
                                            name="dddriverdocumenttype"
                                            defaultValue={0}
                                            value={this.state.documentTypeId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnDocumentTypeChange}
                                            showClearButton={true}
                                            disabled={(this.state.isViewOnly || Number(this.state.id) > 0)}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["documentType"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["documentType"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                    <div className="form-group">
                                        <label>Document Name<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-textbox form-control-sm" name="documentName"
                                            title="Document Name" placeholder="Enter document name"
                                            value={this.state.documentName} onChange={this.handleInputChange}
                                            disabled={this.state.isViewOnly} />
                                        {this.state.errors["documentName"].length > 0 && <span className="error invalid-feedback">{this.state.errors["documentName"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                    <div className="form-group">
                                        <label>Ref No<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-textbox form-control-sm" name="refNo" title="Ref No"
                                            placeholder="Enter Ref No" value={this.state.refNo} onChange={this.handleInputChange}
                                            disabled={this.state.isViewOnly} />
                                        {this.state.errors["refNo"].length > 0 && <span className="error invalid-feedback">
                                            {this.state.errors["refNo"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>Valid From<span className="text-danger">*</span></label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="validDateFrom"
                                            placeholder=""
                                            defaultValue={this.state.validDateFrom}
                                            value={this.state.validDateFrom}
                                            onValueChanged={this.handleOnValidFromDateChange}
                                            width={'100%'}
                                            disabled={this.state.isViewOnly}
                                        />
                                        {this.state.errors["validDateFrom"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["validDateFrom"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                    <div className="form-group">
                                        <label>Expiry Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="expiryDate"
                                            placeholder=""
                                            showClearButton={true}
                                            displayFormat="shortdate"
                                            //useMaskBehavior={true}
                                            defaultValue={this.state.expiryDate}
                                            value={this.state.expiryDate}
                                            min={this.state.validDateFrom}
                                            onValueChanged={this.handleOnExpiryDateChange}
                                            disabled={this.state.isViewOnly}
                                            width={'100%'}
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                    <div className="form-group">
                                        <label>Days Before Remind</label><br />
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="isRemind" name="isRemind"
                                                checked={this.state.isRemind} onChange={this.handleInputChange}
                                                disabled={this.state.isViewOnly} />
                                            <label className="form-check-label" htmlFor="isRemind"> Remind me before</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                    <div className="form-group">
                                        <label>Days of expiry date</label>
                                        <NumberFormat className="form-control form-control-sm" style={{ width: '70%' }} name="daysRemider"
                                            title="Days Of Expiry Date" placeholder="Days Of Expiry Date"
                                            value={this.state.daysRemider} onChange={this.handleInputChange}
                                            disabled={(this.state.isViewOnly || this.state.isRemind === false)} />
                                    </div>
                                    {this.state.errors["daysRemider"].length > 0 && <span className="error invalid-feedback">
                                        {this.state.errors["daysRemider"]}</span>}
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <label>Description</label>
                                        <textarea className="form-control form-control-sm" rows="2" name="description" value={this.state.description}
                                            onChange={this.handleInputChange} disabled={this.state.isViewOnly}>
                                        </textarea>
                                    </div>
                                </div>
                                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3" style={{ display: Number(this.state.id) > 0 || 'none' }}>
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="isActive" name="isActive" disabled={this.state.isViewOnly}
                                                checked={this.state.isActive} onChange={(e) => { this.setState({ isActive: e.target.checked }) }} />
                                            <label className="form-check-label" htmlFor="isActive"> Is Active</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="card main-rez-card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                    <div className="card sub-rez-card">
                                                        <div className="card-header">
                                                            <h6 className="card-title m-0">E-mail Details</h6>
                                                        </div>
                                                        <div className="card card-grid">
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="emailDetailsFrom">From</label>
                                                                            <input type="email" className="form-control form-control-sm"
                                                                                id="emailDetailsFrom" name="emailDetailsFrom"
                                                                                title="From" placeholder="example@xyz.com"
                                                                                maxLength="50" value={this.state.emailDetailsFrom}
                                                                                onChange={this.handleInputChange}
                                                                                disabled={this.state.isViewOnly} />
                                                                            {this.state.errors["emailDetailsFrom"].length > 0 && <span className='error invalid-feedback'>
                                                                                {this.state.errors["emailDetailsFrom"]}</span>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="emailDetailsTo"
                                                                                style={{ fontSize: "inherit" }}> To &nbsp;
                                                                                <span style={{ display: !this.state.isViewOnly || 'none' }}>
                                                                                    <i style={{ color: '#C04000' }}>( Please enter comma separated email address )</i>&nbsp;&nbsp;
                                                                                    <a title="Load default user email"
                                                                                        onClick={this.handleOnLoadDefaultUserEmail}>
                                                                                        {this.state.isForDefaultUserEmailSubmited && <span title="In Process">
                                                                                            <i className="fas fa-spinner fa-spin"></i> Loading...</span>}
                                                                                        {!this.state.isForDefaultUserEmailSubmited && <span title="Click to load email address">
                                                                                            <i className="fas fa-sync-alt"></i> Load Default E-mail Address</span>}
                                                                                    </a>
                                                                                </span>
                                                                            </label>
                                                                            <textarea className="form-control form-control-sm" rows="2" maxLength={500}
                                                                                placeholder="example@xyz.com" name="emailDetailsTo"
                                                                                value={this.state.emailDetailsTo} onChange={this.handleInputChange}
                                                                                disabled={this.state.isViewOnly}
                                                                            />
                                                                            {this.state.errors["emailDetailsTo"].length > 0 && <span className='error invalid-feedback'>
                                                                                {this.state.errors["emailDetailsTo"]}</span>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="emailDetailsCC">CC</label>
                                                                            <input type="email" className="form-control form-control-sm"
                                                                                name="emailDetailsCC" title="CC"
                                                                                placeholder="example@xyz.com" maxLength="50"
                                                                                value={this.state.emailDetailsCC}
                                                                                onChange={this.handleInputChange}
                                                                                disabled={this.state.isViewOnly} />
                                                                            {this.state.errors["emailDetailsCC"].length > 0 && <span className='error invalid-feedback'>
                                                                                {this.state.errors["emailDetailsCC"]}</span>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                                        <div className="form-group">
                                                                            <label>Subject</label>
                                                                            <input type="text" className="form-control form-textbox form-control-sm"
                                                                                name="emailDetailsSubject" title="Subject"
                                                                                placeholder="Enter subject"
                                                                                value={this.state.emailDetailsSubject}
                                                                                onChange={this.handleInputChange}
                                                                                disabled={this.state.isViewOnly} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success btn-sm" disabled={this.state.isViewOnly} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In process</span>}
                                {!this.state.isSubmited && <span title="Save"><i className="fas fa-save"></i> Save</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleResetStateData} title="Close">
                                <i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
              
            </div>
            </Fragment>
        )
    }
}