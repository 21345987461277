import React, { PureComponent } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Modal from 'react-modal'; //npm install --save react-modal
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { DateFormat } from '../../helpers/fixcodes';
import { LoadPanel } from 'devextreme-react/load-panel';
const customStyles = {
    content: {
        width: '70%'
    }
};
export class MasterHistoryComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
            loadPanelVisible: false,
            isAddViewModalOpen: false,
            isSubmited: false,
            isAddHistory: false,
            historyGridDataSource: null,
            historyControllerName: '',
            pageName: '',
            name: '',
            id: 0,
            description: '',
            errors: {
                description: ''
            }
        }
    }
    getMasterHistoryList = async (ControllerName, id) => {
     this.setState({ loadPanelVisible: true });
        let apiUrl = ControllerName + '/' + id + '/history';
        if (apiUrl !== '') {
            try {
                const requestParams = getRequestParams('GET');
                const response = await fetch(apiUrl, requestParams);
                if (response.ok) {
                    const data = await response.json();
                    this.setState({
                        historyGridDataSource: data.masterHistoryList
                    });
                }

            } catch (e) {
                Swal.fire({
                    allowOutsideClick: false,
                    showCloseButton: true,
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong while fetching data!',
                })
            }
        }
        this.setState({ loadPanelVisible: false });
    };
    showModal = async (historyControllerName, pageName, name, id) => {
       this.setState({
            isOpenModal: true,
            historyControllerName: historyControllerName,
            pageName: pageName,
            name: name,
            id: id
        })
        await this.getMasterHistoryList(historyControllerName, id);
    }
    handleCloseModal = () => {
        this.setState({
            isOpenModal: false,
            loadPanelVisible: false,
            isAddViewModalOpen: false,
            isSubmited: false,
            isAddHistory: false,
            historyGridDataSource: null,
            historyControllerName: '',
            pageName: '',
            name: '',
            id: 0,
            description: '',
            errors: {
                description: ''
            }
        });
    }
    handleAddViewHistory(e, data) {
        e.preventDefault();
        this.setState({
            isAddViewModalOpen: true,
            description: data === null ? '' : (data.description === null ? '' : data.description.trim()),
            isAddHistory: data === null ? true : false
        });
    }
    handleResetStateData = async () => {
        this.setState({
            isAddViewModalOpen: false,
            description: '',
            errors: {
                description: ''
            }
        });
    }
    handleOnChangeInputActivatePayroll = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        let errors = this.state.errors;
        errors.description = '';
        if (this.descriptionInputValidator.value.trim() === '') {
            errors.description = "Please enter description.";
        }
        this.setState({
            [name]: value,
            errors: errors
        });
    }
    handleHistorySubmit = async event => {
        event.preventDefault();
        if (this.isFormValid()) {
            try {
                if (Number(this.state.id) === 0) {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                    })
                }
                else {
                    let apiUrl = this.state.historyControllerName + '/' + Number(this.state.id) + '/history';
                    if (apiUrl !== '') {
                        var request = new Object();
                        request.Description = this.state.description.trim();
                        const requestParams = getRequestParams('POST', request);
                        const response = await fetch(apiUrl, requestParams);
                        if (response.ok) {
                            const data = await response.json();
                            await Swal.fire({
                                allowOutsideClick: false,
                                icon: data.isSuccess ? 'success' : 'error',
                                title: data.isSuccess ? 'Success' : 'Oops...',
                                text: data.isSuccess ? this.state.pageName + ' history added successfully!' : 'Error! has occurred while adding ' + this.state.pageName + ' history.',
                            }).then(async (result) => {
                                /* Read more about isConfirmed, isDenied below */
                                if (data.isSuccess) {
                                    if (result.isConfirmed) {
                                        await this.handleResetStateData();
                                        await this.getMasterHistoryList(this.state.historyControllerName, this.state.id);
                                    }
                                }
                            })
                        }
                        else {
                            Swal.fire({
                                allowOutsideClick: false,
                                showCloseButton: true,
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Unable to process the request.',
                            })
                        }
                    }
                    else {
                        Swal.fire({
                            allowOutsideClick: false,
                            showCloseButton: true,
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something went wrong!',
                            //footer: '<a href>Why do I have this issue?</a>'
                        })
                    }
                }
            } catch (e) {
                Swal.fire({
                    allowOutsideClick: false,
                    showCloseButton: true,
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                })
            }
        }
        this.setState({ isSubmited: false })
    }
    isFormValid() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.description = '';
        if (this.state.description === '') {
            formIsValid = false;
            errors.description = 'Please enter description.';
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    render() {
        //console.log("Master History Render")
        const renderGridCell = (data) => {
            return <div>
                <a href="#" title="View" onClick={(e) => { this.handleAddViewHistory(e, data.data) }} ><i className="fas fa-eye"></i></a>
            </div>;
        }
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={customStyles}
                    className={"react-modal"}
                    onRequestClose={this.handleCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form>
                        <div className="modal-header">
                            <h6 className="modal-title">Master History</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <small>{this.state.pageName === 'Vehicle Owner' ? this.state.pageName + ' - Vehicle ' : this.state.pageName} <i><strong className="text-danger">{this.state.name}</strong></i></small>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-right">
                                    <NavLink tag={Link} to="#" className="btn btn-outline-secondary btn-sm mb-2" style={{ display: 'inline-block', cursor: 'pointer' }} onClick={(e) => { this.handleAddViewHistory(e, null) }} title="Add History"><i className="fas fa-plus"></i> Add History</NavLink>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="widget-container">
                                        <DataGrid
                                            dataSource={this.state.historyGridDataSource}
                                            selection={{ mode: 'single' }}
                                            columnsAutoWidth={true}
                                            showColumnLines={true}
                                            showRowLines={true}
                                            showBorders={true}
                                            rowAlternationEnabled={true}
                                            allowColumnResizing={true}
                                            columnResizingMode='widget'
                                        >
                                            <Column dataField="referenceId" width={25} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                            <Column dataField="description" caption="Description" width={400} allowFiltering={true} allowSorting={false} />
                                            <Column dataField="logType" caption="Log Type" allowFiltering={true} allowSorting={false} />
                                            <Column dataField="logDate" caption="Log Date" width={'auto'} allowFiltering={true} allowSorting={false} format={DateFormat.DateTime24Format} dataType="datetime" />
                                            <Column dataField="logByUser" caption="User" allowFiltering={true} allowSorting={false} />
                                            <Column dataField="machineIp" caption="Machine IP" allowFiltering={true} allowSorting={false} />
                                            <SortByGroupSummaryInfo summaryItem="count" />
                                            <GroupPanel visible={false} />
                                            <HeaderFilter visible={true} />
                                            <SearchPanel visible={false} highlightCaseSensitive={false} width={600} placeholder="Search..." />
                                            <Grouping autoExpandAll={false} />
                                            <Paging defaultPageSize={10} />
                                            <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                            <Export enabled={false} allowExportSelectedData={false} />
                                            <ColumnChooser enabled={false} />
                                            <ColumnFixing enabled={true} />

                                        </DataGrid>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleCloseModal} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isAddViewModalOpen}
                    style={{ content: { width: '30%' } }}
                    className={"react-modal"}
                    onRequestClose={this.handleResetStateData}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleHistorySubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{this.state.isAddHistory ? "Add History" : "View History"}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleResetStateData}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Description <span className="text-danger" style={{ display: this.state.isAddHistory || 'none' }}>*</span></label>
                                        <textarea className="form-control form-textbox form-control-sm" rows="5" name="description" placeholder="Description..." maxLength="1000" title="Description" ref={input => this.descriptionInputValidator = input} value={this.state.description} onChange={this.handleOnChangeInputActivatePayroll}
                                            disabled={this.state.isAddHistory ? false : true} />
                                        {this.state.errors["description"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["description"]}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleHistorySubmit} style={{ display: this.state.isAddHistory ? 'block' : 'none' }}>
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleResetStateData} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}