import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import { getRequestParams } from '../../helpers/globalfunctions';
import { TrackingSource } from '../../helpers/fixcodes';
const customStyles = {
    content: {
        width: '85%',
        //height: '80%',
    }
};

export class RideTrackingReplay extends Component {
    static displayName = RideTrackingReplay.name;
    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
            rideId: 0,
            vehicleId: 0,
            locationdate: '',
            completeDate: '',
            trackingSourceId:0,
            mapUrl: ''
        }
    }
    showModal = async (rideId, vehicleId, startTime, endTime, trackingSourceId) => {
         this.setState({
            isOpenModal: true,
             rideId: rideId,
             vehicleId: vehicleId,
             locationdate: startTime,
             completeDate: endTime,
             trackingSourceId: trackingSourceId
        });
        const requestParams = getRequestParams('GET');
        const response = await fetch('helper/map-url', requestParams);
        const data = await response.json();
        this.setState({
            mapUrl: data.resultMessage,
        });
      }
    handleCloseModal = () => {
        this.setState({
            isOpenModal: false,
            rideId: 0,
            mapUrl: ''
        })
    }
    render() {
        let url;
        if (this.state.trackingSourceId !== TrackingSource.SSTRACKING) {
             url = this.state.mapUrl + "ride-replay?rideId=" + this.state.rideId;
        }
        else {
            url = this.state.mapUrl + "replay?vehicleId=" + this.state.vehicleId + "&fromDatetime=" + this.state.locationdate + "&toDatetime=" + this.state.completeDate + "&rideId=" + this.state.rideId;
        }
        
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={customStyles}
                    className={"react-modal"}
                    onRequestClose={this.handleCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form>
                        <div id="locateVehiclediv">
                            <div className="modal-header">
                                <h6 className="modal-title">Ride Replay</h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCloseModal}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body" >
                                <div className="row">
                                    {/*<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">*/}
                                    {/*    <small>Ride Id: <i><strong className="text-danger"> {this.state.rideId} </strong></i></small>&nbsp;*/}
                                    {/*    <small>On Location Time: <i><strong className="text-danger"> {this.state.locationdate} </strong></i></small>&nbsp;*/}
                                    {/*    <small>Drop off Time: <i><strong className="text-danger"> {this.state.completeDate} </strong></i></small>*/}
                                    {/*    <small>Total Distance: 0Km</small>*/}
                                    {/*</div>*/}
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="widget-map-container">
                                            <iframe width="100%" height="550" frameBorder="0" scrolling="no" allowtransparency="true" src={url}></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal>
            </div >
        );
    }
}