import React, { Component } from 'react';
import DataGrid, {
    Column, Grouping, GroupPanel, Pager, Paging, SearchPanel, Export, SortByGroupSummaryInfo, ColumnChooser, ColumnFixing, HeaderFilter,
    Button
} from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import Modal from 'react-modal'; //npm install --save react-modal
import DateBox from 'devextreme-react/date-box';
import { SelectBox } from 'devextreme-react/select-box';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import NumberFormat from 'react-number-format';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { Item } from 'devextreme-react/select-box';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';


export const rideType = [
    {
        id: 1,
        name: "Arrivals"
    },
    {
        id: 2,
        name: "Departure"
    },
    {
        id: 3,
        name: "Both Airport"
    }]
let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class TaxSettingComponent extends React.Component {
    constructor(props) {
        super(props);
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        this.state = {
            isSubmited: false,
            taxSettingList: null,
            locationList: [],
            rideTypeList: null,
            taxTypeList: null,
            customerDetails: props.customerDetails,
            rowId: 0,
            locationId: 0,
            taxType: 0,
            taxCharge: 0,
            wefDate: currentTime,
            rideType: 0,
            errors: {
                location: '',
                taxType: '',
                taxCharge: '',
                wefDate: '',
                rideType: '',
            }
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleWefDateChange = this.handleWefDateChange.bind(this);
        this.getTaxSettingList = this.getTaxSettingList.bind(this);
        this.handleTaxSettingSubmit = this.handleTaxSettingSubmit.bind(this);
        this.EditTaxSettingControl = this.EditTaxSettingControl.bind(this);
    }
    componentDidMount() {
        this.getTaxSettingList();
        this.getLocationList();
        this.getRideTypelist();
        this.getTaxTypeList();
    }

    handleWefDateChange(event) {
        let fromDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ wefDate: fromDateValue })
    }

    async getTaxSettingList() {

        const requestParams = getRequestParams('GET', '');
        const response = await fetch('customer-api/' + this.state.customerDetails.accountId + "/0/aff-tax-settings", requestParams);
        const data = await response.json();
        if (data !== null && data.affTaxSettings.length > 0) {
            this.setState({
                taxSettingList: data.affTaxSettings
            });
        }
    }
    async getLocationList() {
        let locationId = parseInt(sessionStorage.getItem("session_locationId"));
        let locationName = sessionStorage.getItem("session_locationName");

        const requestParams = getRequestParams('GET', '');
        const response = await fetch('account-api/locations', requestParams);
        const data = await response.json();
        if (data !== null && data.locations.length > 0) {
            this.setState({
                locationList: data.locations,
                locationId: locationId
            });
        }
    }
    async getTaxTypeList() {

        const requestParams = getRequestParams('GET', '');
        const response = await fetch('tax-api', requestParams);
        const data = await response.json();
        if (data !== null && data.taxDropdownList.length > 0) {
            this.setState({
                taxTypeList: data.taxDropdownList
            });
        }
    }

    getRideTypelist() {
        this.setState({
            rideTypeList: rideType
        });
    }
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === "taxCharge") {
            const re = /^[0-9\b]+$/;
            if (event.target.value === '' || re.test(event.target.value)) {
                this.setState({ taxCharge: event.target.value });
            }
        } else if (name === "taxType") {
            this.setState({
                [name]: Number(value)
            });
        }
        else if (name === "rideType") {
            this.setState({
                [name]: Number(value)
            });
        }
        else {
            this.setState({
                [name]: value
            });
        }
    }
    handleLocationInputChange = (selectedOption) => {
        this.setState({ locationId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.locationId : 0) })
    }
    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.location = 0;
        errors.taxType = 0;
        errors.taxCharge = 0;
        errors.wefDate = "";
        errors.rideType = 0;

        if (Number(this.state.locationId) === 0) {
            formIsValid = false;
            errors.location = "Please select Location.";
        }
        if (Number(this.state.taxType) === 0) {
            formIsValid = false;
            errors.taxType = "Please enter Tax type.";
        }
        if (Number(this.state.taxType) === 1) {
            if (Number(this.state.rideType) === 0) {
                formIsValid = false;
                errors.rideType = "Please select ride type.";
            }
        }
        if (parseFloat(this.state.taxCharge) === 0) {
            formIsValid = false;
            errors.taxCharge = "Please enter tax charges.";
        }
        if (this.state.wefDate === "" || this.state.wefDate === null) {
            formIsValid = false;
            errors.wefDate = "Please select wef Date.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    async handleTaxSettingSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            this.setState({ isSubmited: true });
            var request = new Object();
            request.TaxSettingRowId = this.state.rowId;
            request.TaxId = this.state.taxType;
            request.WefDate = this.state.wefDate;
            request.TaxCharge = parseFloat(this.state.taxCharge);
            request.RideTypeId = this.state.rideType;
            request.LocationId = Number(this.state.locationId);
            request.CustomerId = this.state.customerDetails.accountId;

            const requestParams = getRequestParams('POST', request);
            const response = await fetch(this.state.rowId > 0 ? 'customer-api/' + this.state.customerDetails.accountId + '/update-aff-tax-setting' : 'customer-api/' + this.state.customerDetails.accountId + '/save-aff-tax-setting', requestParams);
            const data = await response.json();
            Swal.fire({
                icon: data.isSuccess ? 'success' : 'error',
                title: data.isSuccess ? 'Success' : 'Oops...',
                text: data.resultMessage,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (data.isSuccess) {
                    if (result.isConfirmed) {
                        this.clearTaxSettingModal();
                        this.getTaxSettingList();
                    }
                } else {
                    this.setState({ isSubmited: false })
                }
            })
        }
        else {
            this.setState({ isSubmited: false })
        }
    }
    async EditTaxSettingControl(e, value) {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('customer-api/' + this.state.customerDetails.accountId + "/" + value.id + "/aff-tax-settings", requestParams);
        const data = await response.json();
        if (data !== null && data.affTaxSettings.length > 0) {
            const getActualData = data.affTaxSettings[0];
            if (getActualData !== null || getActualData !== "")
                this.setState({
                    rowId: getActualData.id,
                    locationId: getActualData.locationId,
                    taxType: getActualData.taxId,
                    taxCharge: getActualData.taxCharge,
                    wefDate: getActualData.wefDate != null ? formatDate(new Date(getActualData.wefDate), "MM/dd/yyyy") : formatDate(currentTime, "MM/dd/yyyy"),
                    rideType: getActualData.taxId === 1 ? getActualData.rideTypeId : 0,
                })
        }
    }
    clearTaxSettingModal = () => {
        this.setState({
            isSubmited: false,
            rowId: 0,
            locationId: 0,
            taxType: 0,
            taxCharge: 0,
            rideType: 0,
            wefDate: currentTime,
            errors: {
                location: '',
                taxType: '',
                taxCharge: '',
                wefDate: '',
                rideType: '',
            }
        });
    }
    render() {
        const renderGridCell = (data) => {
            return parseFloat(data.data.taxCharge).toFixed(3);
        }
        const renderEditTaxSettingCell = (data) => {
            return <div> <a title="Edit" className="fas fa-pen icon text-info" onClick={(e) => this.EditTaxSettingControl(e, data.data)}></a> &nbsp;
                    </div>;
        }
        return (<div className="card card-default">
            <div className="card-header">
                <h3 className="card-title">Tax Setting</h3>
            </div>

            <div className="card-body">
                <form method="post" onSubmit={this.handleTaxSettingSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div className="form-group">
                                <label>Location</label>
                                <SelectBox
                                    dataSource={this.state.locationList.length > 0 ? this.state.locationList : []}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Select location"
                                    name="locationId"
                                    defaultValue={0}
                                    value={this.state.locationId}
                                    displayExpr='locationName'
                                    valueExpr='locationId'
                                    onSelectionChanged={this.handleLocationInputChange}
                                    showClearButton={true}
                                    disabled={false}
                                    searchEnabled={true}
                                />
                                {this.state.errors["location"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["location"]}</span>}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div className="form-group">
                                <label>Tax Type</label>
                                <select name="taxType" className="form-control form-control-sm" value={this.state.taxType} onChange={this.handleInputChange}>
                                    <option value={0}>Select tax type</option>
                                    {this.state.taxTypeList && this.state.taxTypeList.map((item, i) =>
                                        (<option key={i} value={item.id}>{item.name}</option>)
                                    )}
                                </select>
                                {this.state.errors["taxType"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["taxType"]}</span>}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div className="form-group">
                                <label>Tax Charge (%)</label>
                                <NumberFormat className="form-control form-control-sm" name="taxCharge" fixedDecimalScale={true} maxLength={10} decimalScale={3} title="Tax Charge" value={this.state.taxCharge} onValueChange={(values) => { this.setState({ taxCharge: parseFloat(values.floatValue).toFixed(3) }) }} placeholder="Enter tax charge (%)" />
                                {this.state.errors["taxCharge"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["taxCharge"]}</span>}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div className="form-group">
                                <label>WEF Date</label>
                                <DateBox type="date"
                                    className="form-control form-control-sm"
                                    name="wefDate"
                                    placeholder="mm/dd/yyyy"
                                    useMaskBehavior={true}
                                    defaultValue={this.state.wefDate}
                                    onValueChanged={this.handleWefDateChange}
                                    width="100%"
                                    disabled={false}
                                />
                                {this.state.errors["wefDate"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["wefDate"]}</span>}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div className="form-group" style={{ display: this.state.taxType === 1 ? "inline" : "none" }}>
                                <label>Ride Type</label><br />
                                <select name="rideType" className="form-control form-control-sm" value={this.state.rideType} onChange={this.handleInputChange}>
                                    <option value={0}>Select ride type</option>
                                    {
                                        this.state.rideTypeList && this.state.rideTypeList.map((item, i) =>
                                            (<option key={i} value={item.id}>{item.name}</option>)
                                        )
                                    }
                                </select>
                                {this.state.errors["rideType"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["rideType"]}</span>}

                            </div>
                        </div>
                    </div >
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group text-right">
                            <button type="submit" className="btn btn-success btn-sm" onClick={this.handleTaxSettingSubmit} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span><i className="fas fa-save"></i> {this.state.rowId > 0 ? "Update" : "Save"}</span>}
                            </button>&nbsp;&nbsp;
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.clearTaxSettingModal} title="Clear"><i className="fas fa-times"></i> Clear</button>
                        </div>
                    </div>
                </form >
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <DataGrid
                            dataSource={this.state.taxSettingList}
                            keyExpr="id"
                            selection={{ mode: 'single' }}
                            columnsAutoWidth={false}
                            showColumnLines={false}
                            showRowLines={true}
                            showBorders={true}
                            rowAlternationEnabled={true}
                            showBorders={true}>
                            <Column dataField="id" caption="" width={50} cellRender={renderEditTaxSettingCell} />
                            <Column dataField="accountId" caption="Account Id" visible={false} />
                            <Column dataField="accountName" caption="Account Name" visible={false} />
                            <Column dataField="locationId" caption="Location Id" visible={false} />
                            <Column dataField="locationName" caption="Location Name" allowFiltering={true} allowSorting={true} width={180} />
                            <Column dataField="taxId" caption="Tax Id" visible={false} />
                            <Column dataField="taxName" caption="Tax Type" allowFiltering={true} allowSorting={true} />
                            <Column dataField="RideTypeId" caption="Ride WEF Date" visible={false} width={180} />
                            <Column dataField="wefDateStr" caption="WEF Date" allowFiltering={true} allowSorting={true} width={180} />
                            <Column dataField="taxCharge" caption="Tax Charge" allowFiltering={true} allowSorting={true} width={180} cellRender={renderGridCell} />

                            <SortByGroupSummaryInfo summaryItem="count" />
                            <GroupPanel visible={true} />
                            <HeaderFilter visible={true} />
                            <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                            <Grouping autoExpandAll={true} />
                            <Paging defaultPageSize={5} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20, 50]} showInfo={true} />
                            <Export enabled={true} allowExportSelectedData={false} />
                            <ColumnChooser enabled={true} />
                            <ColumnFixing enabled={true} />

                        </DataGrid>
                    </div>
                </div>
            </div >

        </div >)
    }
}