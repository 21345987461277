import React, { Component } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping, Editing,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
} from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { LoadPanel } from 'devextreme-react/load-panel';
//import CheckboxTree from 'react-checkbox-tree';// npm install react-checkbox-tree --save
import { getRequestParams } from '../../helpers/globalfunctions.js';
export class LocationListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitted: false,
            isRefreshSubmitted: false,
            loadPanelVisible: false,
            isLocationMappingModalOpen: false,
            isEditLocationModalOpen: false,
            isAddLocationModalOpen: false,
            id: 0,
            locationId: 0,
            locationName: '',
            isActive: false,
            isDisable: false,
            locationGridDataSource: null,
            locationDropdownList: [],
            getMapAllLocationList: [],
            selectLocationList: "",
            selectMapLocationList: "",
            mappingLocation: {
                treeviewDataSource: [],
                checkedNodes: [],
                expandedNodes: [],
                clickedNodes: [],
                filterText: '',
                saveMappingIds: [],
                transferTypeMappings: [],
                serviceTypeMappings: [],
                vehicleTypeMappings: [],
            },
            errors: {
                location: ''
            }
        }
    }
    async componentDidMount() {
        await this.getlocationList();
    }
    getlocationList = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('account-api/locations', requestParams);
            const data = await response.json();
            this.setState({
                locationGridDataSource: data.locations
            });
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnToolbarPreparing = async (e) => {
        try {
            e.toolbarOptions.items.unshift({
                location: 'after',
                widget: 'dxButton',
                showText: 'always',
                options: {
                    disabled: false,
                    //text: 'Export to Excel',
                    icon: 'export-excel-button',//'export',
                    elementAttr: {
                        "class": "dx-datagrid-export-button"
                    },
                    onClick: function () {
                        e.component.option("export.fileName", "Location");
                        e.component.exportToExcel(false);
                    },
                    hint: 'Export to Excel'
                }
            }, {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    onClick: this.getlocationList.bind(this),
                    hint: 'Refresh'
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnRefresh = async () => {
        try {
            this.setState({ isRefreshSubmitted: true, loadPanelVisible: true });
            await new Promise(resolve => setTimeout(resolve, 1000)); // 1 sec
            this.setState({
                isSubmitted: false,
                isLocationMappingModalOpen: false,
                isEditLocationModalOpen: false,
                isAddLocationModalOpen: false,
                id: 0,
                locationId: 0,
                locationName: '',
                isActive: false,
                isDisable: false,
                locationGridDataSource: null,
                locationDropdownList: [],
                getMapAllLocationList: [],
                selectLocationList: "",
                selectMapLocationList: "",
                mappingLocation: {
                    treeviewDataSource: [],
                    checkedNodes: [],
                    expandedNodes: [],
                    clickedNodes: [],
                    saveMappingIds: [],
                    filterText: '',
                    transferTypeMappings: [],
                    serviceTypeMappings: [],
                    vehicleTypeMappings: [],
                },
                errors: {
                    location: ''
                }
            });
            await this.getlocationList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isRefreshSubmitted: false, loadPanelVisible: false });
    }
    handleResetStateData = async () => {
        this.setState({
            isSubmitted: false,
            isRefreshSubmitted: false,
            loadPanelVisible: false,
            isLocationMappingModalOpen: false,
            isEditLocationModalOpen: false,
            isAddLocationModalOpen: false,
            id: 0,
            locationId: 0,
            locationName: '',
            isActive: false,
            isDisable: false,
            locationDropdownList: [],
            getMapAllLocationList: [],
            selectLocationList: "",
            selectMapLocationList: "",
            mappingLocation: {
                treeviewDataSource: [],
                checkedNodes: [],
                expandedNodes: [],
                clickedNodes: [],
                saveMappingIds: [],
                filterText: '',
                transferTypeMappings: [],
                serviceTypeMappings: [],
                vehicleTypeMappings: [],
            },
            errors: {
                location: ''
            }
        });
    }

    /*-----Location Add Start------*/
    getLocationDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('location-api/locations-dropdownlist/0', requestParams);
            const data = await response.json();
            if (data !== null && data.locations.length > 0) {
                var unmappedData = data.locations;
                if (this.state.locationGridDataSource.length > 0) {
                    for (let i = 0; i < this.state.locationGridDataSource.length; i++) {
                        unmappedData = unmappedData.filter(x => x.id !== this.state.locationGridDataSource[i].locationId);
                    }
                }
                this.setState({
                    locationDropdownList: unmappedData
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenAddLocationModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getLocationDropdownList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnLocationSelectionChange = (event) => {
        try {
            const target = event.target;
            const value = Array.from(event.target.selectedOptions, option => option.value);
            const name = target.name;
            this.setState({
                [name]: value
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAddNewLocation = async () => {
        try {
            var mappedLocationId = this.state.selectLocationList;
            const finallocationmap = this.state.getMapAllLocationList.slice();
            if (mappedLocationId.length > 0) {
                for (var i = 0; i < mappedLocationId.length; i++) {
                    const addfilteredItems = this.state.locationDropdownList.splice(this.state.locationDropdownList.findIndex(x => x.id === Number(mappedLocationId[i] !== null ? mappedLocationId[i] : "0")), 1)
                    finallocationmap.push(addfilteredItems[0]);
                }
                this.setState({ getMapAllLocationList: finallocationmap })
                for (var j = 0; j < mappedLocationId.length; j++) {
                    const removeData = this.state.locationDropdownList.filter(item => item.id !== Number(mappedLocationId[j] !== null ? mappedLocationId[j] : "0"))
                    this.setState({ locationDropdownList: removeData })
                }
                this.setState({ selectLocationList: "" });
            }
            else {
                await this.onfailure('warning', 'Location', 'Please select location');
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnRemoveNewLocation = async () => {
        try {
            var removeMappedLocationId = this.state.selectMapLocationList;
            const finalUnMapLocationmap = this.state.locationDropdownList.slice();
            if (removeMappedLocationId.length > 0) {
                for (var i = 0; i < removeMappedLocationId.length; i++) {
                    const addfilteredItems = this.state.getMapAllLocationList.splice(this.state.getMapAllLocationList.findIndex(x => x.id === Number(removeMappedLocationId[i] !== null ? removeMappedLocationId[i] : "0")), 1)
                    finalUnMapLocationmap.push(addfilteredItems[0]);
                }
                this.setState({ locationDropdownList: finalUnMapLocationmap })
                for (var j = 0; j < removeMappedLocationId.length; j++) {
                    const removeData = this.state.getMapAllLocationList.filter(item => item.id !== Number(removeMappedLocationId[j] !== null ? removeMappedLocationId[j] : "0"))
                    this.setState({ getMapAllLocationList: removeData })
                }
                this.setState({ selectMapLocationList: "" });
            }
            else {
                await this.onfailure('warning', 'Location', 'Please select location');
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnLocationAddSubmit = async (event) => {
        event.preventDefault();
        try {
            let isFormValid = true;
            let errors = this.state.errors;
            errors.location = '';
            if (this.state.getMapAllLocationList.length === 0) {
                isFormValid = false;
                errors.location = "Please select Location.";
            }
            if (isFormValid === true) {
                this.setState({ isSubmitted: true });
                var request = {};
                request.saveServiceLocations = this.state.getMapAllLocationList;
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('location-api/saveLocation', requestParams);
                const data = await response.json();
                await Swal.fire({
                    icon: data.isSuccess ? 'success' : 'error',
                    title: data.isSuccess ? 'Success' : 'Oops...',
                    text: data.resultMessage,
                    allowOutsideClick: false,
                }).then(async (result) => {
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            await this.handleResetStateData();
                            await this.getlocationList();
                        }
                    }
                })
            }
            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false });
    }
    /*-----Location Add End------*/

    /*-----Location Edit Start------*/
    handleOnShowEditLocationModal = async (e, data) => {
        e.preventDefault();
        try {
            this.setState({
                id: data.id,
                isEditLocationModalOpen: true,
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenEditLocationModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('account-api/locations', requestParams);
            const selectedData = await response.json();
            if (selectedData !== null && selectedData.locations.length > 0) {
                this.setState({
                    isEditLocationModalOpen: true,
                    id: selectedData.locations[0] !== null ? selectedData.locations[0].id : 0,
                    locationId: selectedData.locations[0] !== null ? selectedData.locations[0].locationId : 0,
                    locationName: selectedData.locations[0] !== null ? selectedData.locations[0].locationName : '',
                    isActive: selectedData.locations[0] !== null && selectedData.locations[0].enable || false,
                });
            }
            const serviceAreaResponse = await fetch('serviceArea-api/' + 0 + "/" + selectedData.locations[0].locationId, requestParams);
            const serviceAreaData = await serviceAreaResponse.json();
            if (serviceAreaData !== null && serviceAreaData.locationServiceArea.length > 0) {
                if (selectedData.locations[0].locationId === serviceAreaData.locationServiceArea[0].locationId) {
                    this.setState({ isDisable: true });
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnLocationUpdateSubmit = async (event) => {
        event.preventDefault();
        try {
            this.setState({ isSubmitted: true });
            var request = {};
            request.saveServiceLocations = [
                { Id: this.state.id, LocationId: this.state.locationId, Enable: this.state.isActive }
            ];
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('location-api/updateLocation', requestParams);
            const data = await response.json();
            await Swal.fire({
                icon: data.isSuccess ? 'success' : 'error',
                title: data.isSuccess ? 'Success' : 'Oops...',
                text: data.resultMessage,
                allowOutsideClick: false,
            }).then(async (result) => {
                if (data.isSuccess) {
                    if (result.isConfirmed) {
                        await this.handleResetStateData();
                        await this.getlocationList();
                    }
                }
            })
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false })
    }
    /*-----Location End Start------*/

    /*-----Location Transfer Mapping Start------*/
    handleOnShowLocationMappingModal = async (e, data) => {
        e.preventDefault();
        try {
            this.setState({
                locationId: data.locationId,
                locationName: data.locationName,
                isLocationMappingModalOpen: true,
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenLocationMappingModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getTranferServiceVehicleTypeMapping();
            //await this.bindTranferServiceVehicleTypeMappingTree('');

        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    getTranferServiceVehicleTypeMapping = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('location-api/' + Number(this.state.locationId) + '/transfer-service-vehicletype-mapping', requestParams);
            if (response.ok) {
                const responseData = await response.json();
                let transferTypeMappings = responseData.transferTypeMappings;
                let serviceTypeMappings = responseData.serviceTypeMappings;
                let vehicleTypeMappings = responseData.vehicleTypeMappings;
                let tsvmapping = this.state.mappingLocation;
                tsvmapping.transferTypeMappings = transferTypeMappings;
                tsvmapping.serviceTypeMappings = serviceTypeMappings;
                tsvmapping.vehicleTypeMappings = vehicleTypeMappings;
                this.setState({
                    mappingLocation: tsvmapping
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    bindTranferServiceVehicleTypeMappingTree = async (filterText) => {
        try {
            let vehicleTypeMappings = JSON.parse(JSON.stringify(this.state.mappingLocation.vehicleTypeMappings));
            if (filterText.trim().length !== 0) {
                filterText = filterText.toLowerCase();
                var reg = new RegExp(filterText.split('').join('.*'), 'i');
                vehicleTypeMappings = vehicleTypeMappings.filter(function (item) {
                    if (item.vehicleTypeDescription.trim().toLowerCase().match(reg)) {
                        return item;
                    }
                });
                vehicleTypeMappings = JSON.parse(JSON.stringify(vehicleTypeMappings));
            }
            let treeObject = [];
            let checkedNodeIds = [];
            let isVehicleTypeFound = false;
            let transferDataList = this.state.mappingLocation.transferTypeMappings;
            let mainroot = {};
            mainroot.value = '0';
            mainroot.label = 'Location Transfer Setting';
            mainroot.children = [];
            for (var t = 0; t < transferDataList.length; t++) {
                isVehicleTypeFound = false;
                let tt = {};
                tt.value = transferDataList[t].transferTypeId.toString();
                tt.label = transferDataList[t].transferTypeName;
                tt.children = [];
                let serviceDataList = this.state.mappingLocation.serviceTypeMappings.filter(item => item.transferTypeId === transferDataList[t].transferTypeId);
                for (var s = 0; s < serviceDataList.length; s++) {
                    let st = {};
                    st.value = serviceDataList[s].transferTypeId.toString() + '_' + serviceDataList[s].serviceTypeId.toString();
                    st.label = serviceDataList[s].serviceTypeName;
                    st.children = [];
                    tt.children.push(st);
                    let vehicleTypeDataList = vehicleTypeMappings.filter(item => item.transferTypeId === serviceDataList[s].transferTypeId && item.serviceTypeId === serviceDataList[s].serviceTypeId);
                    for (var v = 0; v < vehicleTypeDataList.length; v++) {
                        let vt = new Object();
                        vt.value = vehicleTypeDataList[v].tsvRowId;
                        vt.label = vehicleTypeDataList[v].vehicleTypeDescription;
                        st.children.push(vt);
                        isVehicleTypeFound = true;
                        if (vehicleTypeDataList[v].isMapped === true) {
                            checkedNodeIds.push(vehicleTypeDataList[v].tsvRowId);
                        }
                    }
                }
                if (isVehicleTypeFound === true) {
                    mainroot.children.push(tt);
                }
            }
            treeObject.push(mainroot);
            let data = JSON.parse(JSON.stringify(treeObject));
            let tsvmapping = this.state.mappingLocation;
            tsvmapping.treeviewDataSource = data;
            tsvmapping.checkedNodes = checkedNodeIds;
            this.setState({ mappingLocation: tsvmapping });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnChangeFilterNodes = (event) => {
        event.preventDefault();
        try {
            if (event.target.value.trim() !== '') {
                this.bindTranferServiceVehicleTypeMappingTree(event.target.value.trim());
            }
            else {
                this.bindTranferServiceVehicleTypeMappingTree('');
            }
            let tsvmapping = this.state.mappingLocation;
            tsvmapping.filterText = event.target.value;
            this.setState({ mappingLocation: tsvmapping });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnCheckedNodes = (checked) => {
        let mappingLocation = this.state.mappingLocation;
        mappingLocation.checkedNodes = checked;
        this.setState({ mappingLocation: mappingLocation });
    }
    handleOnClickedNodes = (clicked) => {
        let mappingLocation = this.state.mappingLocation;
        mappingLocation.clickedNodes = clicked;
        this.setState({ mappingLocation: mappingLocation });
    }
    handleOnExpandedNodes = (expanded) => {
        let mappingLocation = this.state.mappingLocation;
        mappingLocation.expandedNodes = expanded;
        this.setState({ mappingLocation: mappingLocation });
    }
    handleOnLocationTransferMappingSubmit = async event => {
        event.preventDefault();
        try {
            let isResult = true;
            let tsvMappingData = this.state.mappingLocation.vehicleTypeMappings.filter(d => d.isMapped === true);
            if (this.state.mappingLocation.vehicleTypeMappings !== null) {
                if (this.state.mappingLocation.vehicleTypeMappings.length > 0) {
                    if (tsvMappingData === null || tsvMappingData.length === 0) {
                        await Swal.fire({
                            icon: 'question',
                            title: '<small><b>Confirmation</b></small>',
                            html: "<span style='font-size: 20px;'>You have not selected any vehicle type.<br>To select this option, click the cell checkbox.</span><br><br>Are you sure want to continue without selection?",
                            showCancelButton: true,
                            focusConfirm: false,
                            confirmButtonText: "<span style='font-size:14px;'>Yes</span>",
                            cancelButtonText: 'No',
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            dangerMode: false,
                            allowOutsideClick: false
                        }).then(async (result) => {
                            if (!result.isConfirmed) {
                                isResult = false;
                            }
                        });
                    }
                }
            }
            if (isResult) {
                this.setState({ isSubmitted: true });
                var request = new Object();
                let LocationTransferVehicleType = [];
                tsvMappingData.forEach((item) => {
                    let mapping = new Object();
                    mapping.TransferTypeId = Number(item.transferTypeId);
                    mapping.ServiceTypeId = Number(item.serviceTypeId);
                    mapping.VehicleTypeId = Number(item.vehicleTypeId);
                    LocationTransferVehicleType.push(mapping);
                });
                request.LocationTransferVehicleType = LocationTransferVehicleType;
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('location-api/' + Number(this.state.locationId) + '/save-transfer-service-vehicletype-mapping', requestParams);
                if (response.ok) {
                    const data = await response.json();
                    await Swal.fire({
                        allowOutsideClick: false,
                        icon: data.isSuccess ? 'success' : 'error',
                        title: data.isSuccess ? 'Success' : 'Oops...',
                        text: (data.isSuccess === false && (data.resultMessage === null || data.resultMessage === "")) ? 'Unable to process the request.' : data.resultMessage,
                    }).then(async (result) => {
                        if (data.isSuccess) {
                            if (result.isConfirmed) {
                                await this.handleResetStateData();
                            }
                        }
                    })
                }
                else {
                    await this.onfailure('error', null, null);
                }
            }
        } catch (ex) {
            console.log(ex);
            await this.onfailure('error', null, null);
        }
        this.setState({ isSubmitted: false });
    }
    handleOnEditingStart = async (e) => {
        if (e.column.dataField !== 'isMapped') {
            e.cancel = true;
        }
    }
    onfailure = async (icon, title, resultMessage) => {
        this.setState({ loadPanelVisible: false });
        await Swal.fire({
            allowOutsideClick: false,
            showCloseButton: false,
            icon: icon,
            title: (title === null || title.trim() === '') ? 'Oops...' : title,
            html: (resultMessage === null || resultMessage.trim() === '') ? 'Opps! something went wrong, unable to process the request.' : resultMessage.trim(),
        })
    }
    /*-----Location Transfer Mapping End------*/

    render() {
        const renderEditGridCell = (data) => {
            return <div>
                <a href="#" className="fas fa-tasks icon text-info" title="Location Setting" onClick={(e) => { this.handleOnShowLocationMappingModal(e, data.data) }} ></a>
            </div>;
        }
        return (<div className="content-wrapper">
            <div className="content-header">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-sm-6 col-md-6 col-lg-6">
                            <h1 className="m-0 text-dark">Location</h1>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <ol className="breadcrumb float-sm-right">
                                <NavItem>
                                    <NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="#" title="Refresh"
                                        onClick={this.handleOnRefresh}>
                                        {this.state.isRefreshSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                        {!this.state.isRefreshSubmitted && <span title="Refresh"><i className="fas fa-sync-alt"></i> Refresh</span>}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={(e) => { this.setState({ isAddLocationModalOpen: true }) }}
                                        className="btn btn-block btn-outline-secondary btn-sm" title="Add Location"><i className="fas fa-plus"></i> Add Location</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} to="/servicearea/list" className="btn btn-block btn-outline-secondary btn-sm" title="Service Area"><i className="fas fa-map-marker-alt"></i> Service Area</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} to="/serviceareazone/list" className="btn btn-block btn-outline-secondary btn-sm" title="Service Area Zone"><i className="fas fa-map-pin"></i> Service Area Zone</NavLink>
                                </NavItem>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="card card-grid">
                    <div className="card-body">
                        <DataGrid
                            dataSource={this.state.locationGridDataSource}
                            keyExpr="locationId"
                            selection={{ mode: 'single' }}
                            columnsAutoWidth={false}
                            showColumnLines={true}
                            showRowLines={true}
                            showBorders={true}
                            rowAlternationEnabled={true}
                            showBorders={true}
                            onToolbarPreparing={this.handleOnToolbarPreparing}>
                            <Column dataField="locationId" width={45} caption="" allowFiltering={false} allowSorting={false} cellRender={renderEditGridCell} />
                            <Column dataField="locationName" caption="Location" />
                            <Column dataField="status" caption="Status" width={90} />
                            <SortByGroupSummaryInfo summaryItem="count" />
                            <GroupPanel visible={true} />
                            <HeaderFilter visible={true} />
                            <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                            <Grouping autoExpandAll={true} />
                            <Paging defaultPageSize={20} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                            <Export enabled={false} allowExportSelectedData={false} fileName={'Location'} />
                            <ColumnChooser enabled={false} />
                            <ColumnFixing enabled={false} />
                        </DataGrid>
                    </div>
                </div>
            </div>
            <Modal ariaHideApp={false}
                isOpen={this.state.isAddLocationModalOpen}
                style={{ content: { width: '60% !important' } }}
                className={"react-modal add-customer-modal"}
                onAfterOpen={this.handleOnAfterOpenAddLocationModal}
                onRequestClose={this.handleResetStateData}
                shouldCloseOnOverlayClick={false}
                contentLabel="Example Modal"
            >
                <form method="post" onSubmit={this.handleOnLocationAddSubmit} style={this.state.isSubmitted ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="modal-header">
                        <h6 className="modal-title">Add Location</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleResetStateData}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-xs-5 col-sm-5 col-md-5 col-lg-5">
                                <div className="form-group">
                                    <select name="selectLocationList"
                                        className="form-control form-control-sm" multiple="true" style={{ width: "100%", height: "200px", margin: "0px 2px 0px 3px", }}
                                        value={this.state.selectLocationList} onChange={this.handleOnLocationSelectionChange}>
                                        {
                                            this.state.locationDropdownList &&
                                            this.state.locationDropdownList.map((item, i) =>
                                                (<option key={i} value={item.id}>{item.name}</option>))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 text-center">
                                <a onClick={() => this.handleOnAddNewLocation()} id="addPop" className="btn btn-custom-gray" ><i className="fas fa-chevron-right" /></a><br /><br />
                                <a onClick={() => this.handleOnRemoveNewLocation()} id="removePop" className="btn btn-custom-gray"><i className="fas fa-chevron-left" /></a>
                            </div>
                            <div className="col-xs-5 col-sm-5 col-md-5 col-lg-5">
                                <div className="form-group">
                                    <select name="selectMapLocationList" multiple="true" style={{ width: "100%", height: "200px", margin: "0px 2px 0px 3px" }} value={this.state.selectMapLocationList} onChange={this.handleOnLocationSelectionChange}>
                                        {
                                            this.state.getMapAllLocationList &&
                                            this.state.getMapAllLocationList.map((item, i) =>
                                                (<option key={i} value={item.id}>{item.name}</option>))
                                        }
                                    </select>
                                    <span className="text-red">*
                                    {this.state.errors["location"].length > 0 && <span className='error' style={{ display: "contents" }}>
                                            {this.state.errors["location"]}</span>}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="submit" className="btn btn-success btn-sm">
                            {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                            {!this.state.isSubmitted && <span title="Save"><i className="fas fa-save"></i> Save</span>}
                        </button>
                        <button type="button" className="btn btn-custom-gray btn-sm"
                            onClick={this.handleResetStateData} title="Close"><i className="fas fa-times"></i> Close
                        </button>
                    </div>
                </form>
            </Modal>
            <Modal ariaHideApp={false}
                isOpen={this.state.isEditLocationModalOpen}
                className={"react-modal edit-location-modal"}
                onAfterOpen={this.handleOnAfterOpenEditLocationModal}
                onRequestClose={this.handleResetStateData}
                shouldCloseOnOverlayClick={false}
                contentLabel="Example Modal"
            >
                <form method="post" onSubmit={this.handleOnLocationUpdateSubmit} style={this.state.isSubmitted ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="modal-header">
                        <h6 className="modal-title">Edit Location</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleResetStateData}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="form-group">
                                    <label>Location Name <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control form-control-sm"
                                        name="locationName" placeholder="Location Name" title="Location Name"
                                        disabled value={this.state.locationName} />
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="form-group">
                                    <div className="form-check">
                                        <input className="form-check-input"
                                            type="checkbox" name="isActive" checked={this.state.isActive}
                                            value={this.state.isActive} disabled={this.state.isDisable} onChange={(e) => { this.setState({ isActive: e.target.checked }) }} />
                                        <label className="form-check-label" htmlFor="isActive"> Is Active<i></i></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="submit" className="btn btn-success btn-sm">
                            {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                            {!this.state.isSubmitted && <span title="Save"><i className="fas fa-save"></i> Save</span>}
                        </button>
                        <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                            onClick={this.handleResetStateData}>
                            <i className="fas fa-times"></i> Close
                        </button>
                    </div>
                </form>
            </Modal>
            <Modal ariaHideApp={false}
                isOpen={this.state.isLocationMappingModalOpen}
                style={{ content: { width: '50%' } }}
                className={"react-modal"}
                onAfterOpen={this.handleOnAfterOpenLocationMappingModal}
                onRequestClose={this.handleResetStateData}
                shouldCloseOnOverlayClick={false}
                contentLabel="Example Modal"
            >
                <form method="post" onSubmit={this.handleOnLocationTransferMappingSubmit} style={this.state.isSubmitted ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="modal-header">
                        <h6 className="modal-title">Location transfer wise vehicle type mapping</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleResetStateData}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="form-group">
                                    <label>Location: <span style={{ fontWeight: 'bold', fontSize: '12px' }}>{this.state.locationName}</span></label>
                                </div>
                            </div>
                            {/*<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div style={{ height: 250 + 'px', overflowY: 'auto', overflowX: 'hidden' }}>
                                    <div className="filter-container">
                                        <div className="form-group">
                                            <input
                                                className="filter-text form-control form-textbox form-control-sm"
                                                placeholder="Search vehicle type..."
                                                type="text"
                                                value={this.state.mappingLocation.filterText}
                                                onChange={(e) => { this.handleOnChangeFilterNodes(e) }}
                                            />
                                        </div>
                                        <CheckboxTree
                                            iconsClass="fa5"
                                            showExpandAll={true}
                                            nodes={this.state.mappingLocation.treeviewDataSource}
                                            checked={this.state.mappingLocation.checkedNodes}
                                            expanded={this.state.mappingLocation.expandedNodes}
                                            onCheck={this.handleOnCheckedNodes}
                                            onExpand={this.handleOnExpandedNodes}
                                            onClick={this.handleOnClickedNodes}
                                            expandOnClick={true}
                                            onlyLeafCheckboxes={true}
                                            showCheckbox={false}
                                            showNodeIcon={false}
                                            icons={{
                                                check: <span className="rct-icon rct-icon-check" />,
                                                uncheck: <span className="rct-icon rct-icon-uncheck" />,
                                                halfCheck: <span className="rct-icon rct-icon-half-check" />,
                                                expandClose: <span className="rct-icon rct-icon-expand-close" />,
                                                expandOpen: <span className="rct-icon rct-icon-expand-open" />,
                                                expandAll: <span className="rct-icon rct-icon-expand-all" />,
                                                collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
                                                parentClose: <span className="rct-icon rct-icon-parent-close" />,
                                                parentOpen: <span className="rct-icon rct-icon-parent-open" />,
                                                leaf: <span className="rct-icon rct-icon-leaf" />,
                                            }}
                                            nativeCheckboxes={false}
                                        //noCascade={true}  //Uncomment this code to get and update status of parent menu id 
                                        />
                                    </div>
                                </div>
                            </div>
                            */}
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="card card-grid">
                                    <div className="card-body">
                                        <DataGrid
                                            dataSource={this.state.mappingLocation.vehicleTypeMappings}
                                            keyExpr="tsvRowId"
                                            allowColumnReordering={true}
                                            selection={{ mode: 'single' }}
                                            columnsAutoWidth={false}
                                            showColumnLines={true}
                                            showRowLines={true}
                                            showBorders={true}
                                            rowAlternationEnabled={false}
                                            showBorders={true}
                                            onEditingStart={this.handleOnEditingStart}>
                                            <Editing
                                                mode="cell"
                                                allowUpdating={true}
                                                startEditAction={'click'}
                                            />
                                            <Column dataField="vehicleTypeDescription" caption="Vehicle Type" dataType="string" allowSorting={false} />
                                            <Column dataField="vehicleCategory" caption="Category" dataType="string" allowSorting={false} />
                                            <Column dataField="paxCount" caption="Pax Capacity" dataType="number" alignment={'center'} allowSorting={false} />
                                            <Column dataField="isMapped" width={50} caption="Mapped" title={'Select V. Type'} width={75} alignment={'center'} allowEditing={true} allowFiltering={false} allowSorting={false} />
                                            <Column dataField="transferTypeName" caption="Transfer Type" groupIndex={0} dataType="string" />
                                            <Column dataField="serviceTypeName" caption="Service Type" groupIndex={1} dataType="string" />
                                            <SortByGroupSummaryInfo summaryItem="count" />
                                            <GroupPanel visible={true} />
                                            {/*<Summary>
                                                <GroupItem
                                                    column="tsvRowId"
                                                    summaryType="count" />
                                            </Summary>*/}
                                            <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                            <Grouping autoExpandAll={false} />
                                            <Paging defaultPageSize={10} />
                                            <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 15]} showInfo={true} />
                                            <Export enabled={true} allowExportSelectedData={false} fileName={'LocationTranserMapping'} />
                                            <ColumnChooser enabled={false} />
                                            <ColumnFixing enabled={false} />
                                        </DataGrid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="submit" className="btn btn-success btn-sm">
                            {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                            {!this.state.isSubmitted && <span title="Save"><i className="fas fa-save"></i> Save</span>}
                        </button>
                        <button type="button" className="btn btn-custom-gray btn-sm"
                            onClick={this.handleResetStateData} title="Close">
                            <i className="fas fa-times"></i> Close
                        </button>
                    </div>
                </form>
            </Modal>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={{ of: '#panelgrid' }}
                visible={this.state.loadPanelVisible}
                showIndicator={true}
                shading={true}
                showPane={true}
                closeOnOutsideClick={false}
            />
        </div >
        )
    }
}