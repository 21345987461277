import DateBox from 'devextreme-react/date-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import { SelectBox } from 'devextreme-react/select-box';
import React from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { formatDate, getRequestParams } from '../../helpers/globalfunctions';
import { SendEmailComponent } from '../Shared/SendEmailComponent';
const position = { of: '#historydiv' };

export class RevenueYearlyComparisonComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
            isEmailSubmitted: false,
            isSubmited: false,
            loadPanelVisible: false,
            chkLocationSelection: "1",
            dropdownLocation: [],
            locationId: 0,
            chkCustomerSelection: "1",
            dropdownCustomer: [],
            customerCode: '',
            chkPeriodSelection: "1",
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),
            chkReportTypeSelection: "1",
            chkSelectTypeSelection: "1",
            chkReportViewSelection: "1",
            errors: {
                customerCode: '',
                locationId: '',
            }
        }
        this.getLocation = this.getLocation.bind(this);
        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.handleLocationSelectionChange = this.handleLocationSelectionChange.bind(this);

        this.getCustomer = this.getCustomer.bind(this);
        this.handleCustomerChange = this.handleCustomerChange.bind(this);
        this.handleCustomerSelectionChange = this.handleCustomerSelectionChange.bind(this);

        this.handlePeriodSelectionChange = this.handlePeriodSelectionChange.bind(this);
        this.handleFromDateChanged = this.handleFromDateChanged.bind(this);
        this.handleToDateChanged = this.handleToDateChanged.bind(this);

        this.handleReportTypeSelectionChange = this.handleReportTypeSelectionChange.bind(this);
        this.handleSelectTypeSelectionChange = this.handleSelectTypeSelectionChange.bind(this);
        this.handleReportViewSelectionChange = this.handleReportViewSelectionChange.bind(this);

        this.downloadAccountPaymentTypeSummaryAsPdf = this.downloadAccountPaymentTypeSummaryAsPdf.bind(this);

        this.handleSearchAccountPaySummaryReportSubmit = this.handleSearchAccountPaySummaryReportSubmit.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
    }


    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getLocation();
            await this.getCustomer();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {

        this.setState({ isOpenModal: false });
        await this.handleClearSearch();
    }

    async getCustomer() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('account-api/customer-dropdown/', requestParams);
            const data = await response.json();
            this.setState({
                dropdownCustomer: data.customers,
            });

        } catch (e) {
            console.log(e);
        }
    }

    handleCustomerChange(selectedOption) {
        this.setState({ customerCode: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.code : '')})
        this.state.errors["customerCode"] = "";
    }

    handleCustomerSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ chkCustomerSelection: value, customerCode: '' });
        this.state.errors["customerCode"] = "";
    }

    async getLocation() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('account-api/locations', requestParams);
            const data = await response.json();
            this.setState({
                dropdownLocation: data.locations,
            });

        } catch (e) {
            console.log(e);
        }
    }

    handleLocationChange(selectedOption) {
        this.setState({ locationId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.locationId : 0), })
        this.state.errors["locationId"] = "";
    }

    handleLocationSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ chkLocationSelection: value, locationId: 0 });
        this.state.errors["locationId"] = "";
    }

    handlePeriodSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ chkPeriodSelection: value });
    }

    handleFromDateChanged(event) {
        let fromDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ startDate: fromDateValue })
    }
    handleToDateChanged(event) {
        let toDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ endDate: toDateValue })
    }

    handleReportTypeSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ chkReportTypeSelection: value });
        if (value == 2) {
            this.setState({ chkReportViewSelection: "1" });
        }
    }

    handleSelectTypeSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ chkSelectTypeSelection: value });
    }

    handleReportViewSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ chkReportViewSelection: value });
    }

    handleSearchAccountPaySummaryReportSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            this.downloadAccountPaymentTypeSummaryAsPdf();
        }
    }
    handleClearSearch() {
        this.setState({
            isSubmited: false,
            isEmailSubmitted: false,
            loadPanelVisible: false,

            chkLocationSelection: "1",
            locationId: 0,

            chkCustomerSelection: "1",
            customerCode: '',

            chkPeriodSelection: "1",
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),

            chkReportTypeSelection: "1",
            chkSelectTypeSelection: "1",
            chkReportViewSelection: "1",

            errors: {
                customerCode: '',
                locationId: '',
            }
        });
    }

    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.customerCode = "";
        errors.locationId = "";

        if (this.state.chkLocationSelection === "2" && this.state.locationId == 0) {
            formIsValid = false;
            errors.locationId = "Please select location.";
        }

        if (this.state.chkCustomerSelection === "2" && this.state.customerCode == "") {
            formIsValid = false;
            errors.customerCode = "Please select customer.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    async downloadAccountPaymentTypeSummaryAsPdf() {
        try {
            this.setState({
                isSubmited: true,
            });
            var RptHead = "";
            var sLocation = "";
            var sCustomer = "";
            var sDate = "";
            var fileName = "";

            var request = {};
            if (this.state.chkLocationSelection === "1") {
                sLocation = " For all Location, ";
                request.LocationId = 0;
            } else {
                let customer = this.state.dropdownLocation.find(opt => opt.locationId === this.state.locationId);
                request.LocationId = parseInt(this.state.locationId);
                sLocation = "  " + "For Location " + "  " + customer.locationName + ",";
            }

            if (this.state.chkCustomerSelection === "1") {
                sCustomer = " For all Customer, ";
                request.CustomerId = 0;
            } else {
                let customer = this.state.dropdownCustomer.find(opt => opt.code === this.state.customerCode);
                request.CustomerId = parseInt(customer.id);
                sCustomer = "  " + "For Customer " + "  " + customer.fullName + ",";
            }


            var sd = new Date(this.state.startDate);
            var ed = new Date(this.state.endDate);

            request.StartDate = sd.getFullYear().toString();
            request.EndDate = ed.getFullYear().toString();
            sDate = " From Year " + "  " + sd.getFullYear().toString() + "  " + "  To Year " + ed.getFullYear().toString();

            if (this.state.chkReportViewSelection === "1") {
                request.MethodName = "GetRevenueComparisonListView";
                fileName = "RevenueComparisonListView";

            } else {
                request.MethodName = "GetRevenueComparisonGraphView";
                fileName = "RevenueComparisonGraphView";
            }

            request.ReportType = parseInt(this.state.chkReportTypeSelection);
            request.OrderByType = parseInt(this.state.chkSelectTypeSelection);

            RptHead = RptHead + sCustomer;
            RptHead = RptHead + sLocation;
            RptHead = RptHead + sDate;

            request.Header = RptHead;

            request.fileFormat = 'pdf';
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            this.setState({
                isSubmited: false,
            });

            let a = document.createElement('a');
            a.href = dataResponse.reportBase64String;
            a.download = fileName + '.pdf';

            a.click();
        } catch (e) {
            this.setState({
                isSubmited: false,
            });
            console.log(e)
        }
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isEmailSubmitted: true });
                var RptHead = "";
                var sLocation = "";
                var sCustomer = "";
                var sDate = "";
                var fileName = "";

                var request = {};
                if (this.state.chkLocationSelection === "1") {
                    sLocation = " For all Location, ";
                    request.LocationId = 0;
                } else {
                    let customer = this.state.dropdownLocation.find(opt => opt.locationId === this.state.locationId);
                    request.LocationId = parseInt(this.state.locationId);
                    sLocation = "  " + "For Location " + "  " + customer.locationName + ",";
                }

                if (this.state.chkCustomerSelection === "1") {
                    sCustomer = " For all Customer, ";
                    request.CustomerId = 0;
                } else {
                    let customer = this.state.dropdownCustomer.find(opt => opt.code === this.state.customerCode);
                    request.CustomerId = parseInt(customer.id);
                    sCustomer = "  " + "For Customer " + "  " + customer.fullName + ",";
                }


                var sd = new Date(this.state.startDate);
                var ed = new Date(this.state.endDate);

                request.StartDate = sd.getFullYear().toString();
                request.EndDate = ed.getFullYear().toString();
                sDate = " From Year " + "  " + sd.getFullYear().toString() + "  " + "  To Year " + ed.getFullYear().toString();

                if (this.state.chkReportViewSelection === "1") {
                    request.MethodName = "GetRevenueComparisonListView";
                    fileName = "RevenueComparisonListView";

                } else {
                    request.MethodName = "GetRevenueComparisonGraphView";
                    fileName = "RevenueComparisonGraphView";
                }

                request.ReportType = parseInt(this.state.chkReportTypeSelection);
                request.OrderByType = parseInt(this.state.chkSelectTypeSelection);

                RptHead = RptHead + sCustomer;
                RptHead = RptHead + sLocation;
                RptHead = RptHead + sDate;

                request.Header = RptHead;

                request.fileFormat = 'pdf';
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Revenue Yearly Comparison';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = fileName + '.pdf';
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '46%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleSearchAccountPaySummaryReportSubmit}
                        style={(this.state.isSubmited || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Revenue Yearly Comparison</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">

                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="allLocation" value="1" checked={this.state.chkLocationSelection === "1"} onChange={this.handleLocationSelectionChange} />
                                            <label className="form-check-label">All Location</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="singleLocation" value="2" checked={this.state.chkLocationSelection === "2"} onChange={this.handleLocationSelectionChange} />
                                            <label className="form-check-label">Single Location</label>
                                        </span>
                                        <SelectBox
                                            dataSource={this.state.dropdownLocation.length > 0 ? this.state.dropdownLocation : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select location"
                                            name="locationId"
                                            defaultValue={0}
                                            value={this.state.locationId}
                                            displayExpr='locationName'
                                            valueExpr='locationId'
                                            onSelectionChanged={this.handleLocationChange}
                                            showClearButton={true}
                                            disabled={this.state.chkLocationSelection === "1"}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["locationId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["locationId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdAllCustomer" value="1" checked={this.state.chkCustomerSelection === "1"} onChange={this.handleCustomerSelectionChange} />
                                            <label className="form-check-label">All Customer</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdSingleCustomer" value="2" checked={this.state.chkCustomerSelection === "2"} onChange={this.handleCustomerSelectionChange} />
                                            <label className="form-check-label">Single</label>
                                        </span>
                                        <SelectBox
                                            dataSource={this.state.dropdownCustomer}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="customerCode"
                                            defaultValue={0}
                                            value={this.state.customerCode}
                                            displayExpr='fullName'
                                            valueExpr='code'
                                            onSelectionChanged={this.handleCustomerChange}
                                            showClearButton={true}
                                            disabled={this.state.chkCustomerSelection === "1"}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerCode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerCode"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>From Year</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            displayFormat={"'Year': yyyy"}
                                            useMaskBehavior={true}
                                            defaultValue={this.state.startDate}
                                            value={this.state.startDate}
                                            onValueChanged={this.handleFromDateChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>To Year</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            displayFormat={"'Year': yyyy"}
                                            defaultValue={this.state.endDate}
                                            value={this.state.endDate}
                                            onValueChanged={this.handleToDateChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Report Type</label><br />
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdSummary" value="1" checked={this.state.chkReportTypeSelection === "1"} onChange={this.handleReportTypeSelectionChange} />
                                            <label className="form-check-label">Summary</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdDetails" value="2" checked={this.state.chkReportTypeSelection === "2"} onChange={this.handleReportTypeSelectionChange} />
                                            <label className="form-check-label">Details</label>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Select Select Type</label><br />
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdMonthly" value="1" checked={this.state.chkSelectTypeSelection === "1"} onChange={this.handleSelectTypeSelectionChange} />
                                            <label className="form-check-label">Monthly</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdYearly" value="2" checked={this.state.chkSelectTypeSelection === "2"} onChange={this.handleSelectTypeSelectionChange} />
                                            <label className="form-check-label">Yearly</label>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Report View</label><br />
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdListView" value="1" checked={this.state.chkReportViewSelection === "1"} onChange={this.handleReportViewSelectionChange} />
                                            <label className="form-check-label">List View</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdGraphView" value="2" disabled={this.state.chkReportTypeSelection === "2"} checked={this.state.chkReportViewSelection === "2"} onChange={this.handleReportViewSelectionChange} />
                                            <label className="form-check-label">Graph View</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="button" className="btn btn-success btn-sm" title="Download" onClick={this.handleSearchAccountPaySummaryReportSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                                </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        )
    }
}