import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
    Editing
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
/* referance for multipal check box https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/MultipleRecordSelectionModes/React/Light/         
 * https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CellEditingAndEditingAPI/React/Light/  */

export class HotelMappingComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            customerDetails: props.customerDetails,
            rowId: 0,
            hotelMappingGridDataSource: null,
            checkBoxesMode: 'onClick',
        }
        this.handleMapingHotelSubmit = this.handleMapingHotelSubmit.bind(this);
    }
    componentDidMount = () => {
        this.getHotelMappingList();
    }
    async getHotelMappingList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('customer-api/hotelsMapping/' + this.state.customerDetails.accountId, requestParams);
        const data = await response.json();
        this.setState({
            hotelMappingGridDataSource: data.hotelsMapping,
        });
    }
    async handleMapingHotelSubmit(e) {
        e.preventDefault();
        this.setState({ isSubmited: true });
        var mappedData = [];
        mappedData = this.state.hotelMappingGridDataSource.filter(item => item.isMapped === true);

        const requestParams = getRequestParams('POST', mappedData);
        const response = await fetch('customer-api/saveHotelsMapping/' + this.state.customerDetails.accountId, requestParams);
        const data = await response.json();
        Swal.fire({
            icon: data.isSuccess ? 'success' : 'error',
            title: data.isSuccess ? 'Success' : 'Oops...',
            text: data.resultMessage,
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (data.isSuccess) {
                if (result.isConfirmed) {
                    this.getHotelMappingList();
                }
            } else {
                this.setState({ isSubmited: false })
            }
        })

    }
    handleMapingHotelClear = () => {
        this.getHotelMappingList();
    }
    render() {
        const checkBoxesMode = this.state.checkBoxesMode;
        return (
            <div className="card card-default">
                <div className="card-header">
                    <h3 className="card-title">Hotel Mapping</h3>
                </div>
                <div className="card-grid">
                    <div className="card-body">
                        <DataGrid
                            id="hotelMapping"
                            dataSource={this.state.hotelMappingGridDataSource}
                            keyExpr="hotelId"
                            columnsAutoWidth={false}
                            showColumnLines={false}
                            showRowLines={true}
                            showBorders={true}
                            rowAlternationEnabled={true}
                            showBorders={true}
                        >
                            <Editing
                                mode="cell"
                                allowUpdating={true} />
                            <Column dataField="isMapped" width={80} caption="Is Mapped" allowFiltering={false} allowSorting={false} Selection={checkBoxesMode} mode="multiple" alignment="center" allowEditing={true} />
                            <Column dataField="hotelName" caption="Name" allowEditing={false} />
                            <Column dataField="address" caption="Address" allowEditing={false} />
                            <Column dataField="cityName" caption="City" allowEditing={false} />
                            <Column dataField="stateName" caption="State" allowEditing={false} />
                            <Column dataField="zipCode" caption="Zip Code" allowEditing={false} />
                            <SortByGroupSummaryInfo summaryItem="count" />
                            <GroupPanel visible={true} />
                            <HeaderFilter visible={true} />
                            <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                            <Grouping autoExpandAll={true} />
                            <Paging defaultPageSize={5} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                            <Export enabled={true} allowExportSelectedData={false} />
                            <ColumnChooser enabled={true} />
                            <ColumnFixing enabled={true} />
                        </DataGrid>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  text-right">                                
                                <button type="button" className="btn btn-success btn-sm" onClick={this.handleMapingHotelSubmit} title="Save"><i className="fas fa-save"></i> Save</button>&nbsp;&nbsp;
                                <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleMapingHotelClear} title="Clear"><i className="fas fa-eraser"></i> Clear</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}