import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { SelectBox } from 'devextreme-react/select-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'

let currentYear = (new Date()).getFullYear();
let maxYear = 10;
let minYear = -3
let budgetYearList = [];
for (let i = maxYear; i >= minYear; i--) {
    let year = new Object();
    year.id = (currentYear - i);
    year.name = (currentYear - i);
    budgetYearList.push(year);
}
export class MonthlyBudgetComparisonReportComponent extends Component {
    constructor() {
        super()
        this.state = {
            isSubmitted: false,
            isOpenModal: false,
            isEmailSubmitted: false,
            loadPanelVisible: false,
            customerGroupDropdownList: [],
            customerGroupDropdownData: null,
            customerGroupId: 0,
            budgetYearDropdownList: [],
            budgetYearDropdownData: null,
            budgetYear: '',
            errors: {
                customerGroup: '',
                budgetYear: ''
            }
        }
    }
    getCustomerGroupDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('report-api/customer-group-dropdown', requestParams);
            const data = await response.json();
            this.setState({
                customerGroupDropdownList: data.customerGroups
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    getBudgetYearDropdownList = async () => {
        try {
            let budgetCurrentYear = [];
            let bgYear = '';
            if (budgetYearList !== null) {
                if (budgetYearList.length > 0) {
                    let bgy = new Object();
                    bgy.id = currentYear;
                    bgy.name = currentYear.toString();
                    budgetCurrentYear.push(bgy);
                    bgYear = currentYear.toString();
                }
            }
            this.setState({ budgetYearDropdownList: budgetYearList, budgetYearDropdownData: budgetCurrentYear, budgetYear: bgYear });
        } catch (ex) {
            console.log(ex);
        }
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getCustomerGroupDropdownList();
            await this.getBudgetYearDropdownList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnClearSearch = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isEmailSubmitted: false,
                loadPanelVisible: false,
                customerGroupDropdownData: null,
                customerGroupId: 0,
                budgetYearDropdownData: null,
                budgetYear: '',
                errors: {
                    customerGroup: '',
                    budgetYear: ''
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isOpenModal: false,
                isEmailSubmitted: false,
                loadPanelVisible: false,
                customerGroupDropdownList: [],
                customerGroupDropdownData: null,
                customerGroupId: 0,
                budgetYearDropdownList: [],
                budgetYearDropdownData: null,
                budgetYear: '',
                errors: {
                    customerGroup: '',
                    budgetYear: ''
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnCustomerGroupDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.customerGroup = '';
            let customerGroupId = 0;
            if (data !== null) {
                customerGroupId = (data.selectedItem != null ? data.selectedItem.id : 0);
            }
            else {
                //errors.customerGroup = 'Please select customer group.';
            }
            this.setState({ customerGroupDropdownData: data, customerGroupId: customerGroupId, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnBudgetYearDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.budgetYear = '';
            let budgetYear = '';
            if (data !== null) {
                budgetYear = (data.selectedItem != null ? data.selectedItem.id : 0);
            }
            else {
                //errors.budgetYear = 'Please select budget year.';
            }
            this.setState({ budgetYearDropdownData: data, budgetYear: budgetYear.toString(), errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnReportSubmit = async (event) => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isSubmitted: true });
                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.Number1 = Number(this.state.customerGroupId);
                request.Number2 = Number(this.state.budgetYear);
                request.SelectionCrieteria = await this.setSelectionCrieteria();
                request.fileFormat = 'pdf';
                request.MethodName = "GetMonthlyBudgetComparisonReport";
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                let a = document.createElement('a');
                a.href = dataResponse.reportBase64String;
                a.download = 'MonthlyBudgetComparison.pdf';
                a.click();
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false });
    }
    isFormValid = async () => {
        let isValid = true;
        let errors = this.state.errors;
        errors.customerGroup = '';
        errors.budgetYear = '';
        try {
            if (Number(this.state.customerGroupId) === 0 || Object.keys(this.state.customerGroupDropdownData).length === 0) {
                errors.customerGroup = 'Please select customer group.';
                isValid = false;
            }
            if (this.state.budgetYear.trim() === '') {
                errors.budgetYear = 'Please select budget year.';
                isValid = false;
            }
            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    setSelectionCrieteria = async () => {
        let selectionCrieteria = 'Yearly budget for';
        try {
            if (Number(this.state.customerGroupId) > 0) {
                if (this.state.customerGroupDropdownData !== null && Object.keys(this.state.customerGroupDropdownData).length > 0) {
                    selectionCrieteria += ', customer group - ' + this.state.customerGroupDropdownData.name;
                }
            }
            if (this.state.budgetYear.trim() !== '') {
                selectionCrieteria += ', year - ' + this.state.budgetYear;
            }
        } catch (ex) {
            selectionCrieteria = '';
            console.log(ex);
        }
        return selectionCrieteria;
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isEmailSubmitted: true });
                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.Number1 = Number(this.state.customerGroupId);
                request.Number2 = Number(this.state.budgetYear);
                request.SelectionCrieteria = await this.setSelectionCrieteria();
                request.fileFormat = 'pdf';
                request.MethodName = "GetMonthlyBudgetComparisonReport";
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Monthly Budget Comparison';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = 'MonthlyBudgetComparison.pdf'
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }

        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '35%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleOnReportSubmit}
                        style={(this.state.isSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Monthly Budget Comparison'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Customer Group</label>
                                        <SelectBox
                                            dataSource={this.state.customerGroupDropdownList.length > 0 ? this.state.customerGroupDropdownList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer group"
                                            name="ddcustomergroup"
                                            defaultValue={[]}
                                            value={this.state.customerGroupDropdownData}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnCustomerGroupDropdownChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerGroup"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["customerGroup"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Budget Year</label>
                                        <SelectBox
                                            dataSource={this.state.budgetYearDropdownList.length > 0 ? this.state.budgetYearDropdownList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select budget year"
                                            name="ddbudgetyear"
                                            defaultValue={[]}
                                            value={this.state.budgetYearDropdownData}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnBudgetYearDropdownChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["budgetYear"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["budgetYear"]}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="submit" className="btn btn-success btn-sm" title="Download">
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleOnClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                                </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >

        );
    }
}