import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import { SelectBox } from 'devextreme-react/select-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'
export class AccountRidePaxReportComponent extends Component {
    constructor() {
        super()
        this.state = {
            isSubmitted: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            radDateType: "0",
            singleDate: new Date(),
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),
            radCustomer: "0",
            dropdownCustomer: [],
            customerCode: '',
            fromCustomerCode: "",
            toCustomerCode: "",
            customerName: "",
            fromCustomerName: "",
            toCustomerName: "",
            radCustomerType: "0",
            dropdownCustomerType: [],
            customerTypeId: 0,
            customerType: "",
            multipleCustomerTypeList: [],
            filterCustomerTypeList: [],
            errors: {
                singleDate: new Date(),
                startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
                endDate: new Date(),
                customerCode: '',
                fromCustomerCode: "",
                toCustomerCode: "",
                customerTypeId: 0,
                multipleCustomerTypeId: 0,
            }
        }

        this.getCustomer = this.getCustomer.bind(this);
        this.getCustomerType = this.getCustomerType.bind(this);

        this.handleRadCustomerTypeChange = this.handleRadCustomerTypeChange.bind(this);
        this.handleCustomerTypeChange = this.handleCustomerTypeChange.bind(this);
        this.handleMultipleCustomerTypeChange = this.handleMultipleCustomerTypeChange.bind(this);
        this.handleRadCustomerChange = this.handleRadCustomerChange.bind(this);
        this.handleCustomerChange = this.handleCustomerChange.bind(this);
        this.handleFromCustomerChange = this.handleFromCustomerChange.bind(this);
        this.handleToCustomerChange = this.handleToCustomerChange.bind(this);
        this.handleDateTypeChange = this.handleDateTypeChange.bind(this);
        this.handleRideDateChanged = this.handleRideDateChanged.bind(this);
        this.handleFromDateChanged = this.handleFromDateChanged.bind(this);
        this.handleToDateChanged = this.handleToDateChanged.bind(this);

        this.handleClosedReservationsReportSubmit = this.handleClosedReservationsReportSubmit.bind(this);
        this.downloadClosedReservationsReportAsPdf = this.downloadClosedReservationsReportAsPdf.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
    }
    async componentDidMount() {
        this.setState({
            loadPanelVisible: true,
        });
        await this.getCustomer();
        await this.getCustomerType();
        this.setState({
            loadPanelVisible: false,
        });
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });

        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    async getCustomer() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('account-api/customer-dropdown/', requestParams);
            const data = await response.json();
            this.setState({
                dropdownCustomer: data.customers,
            });

        } catch (e) {
            console.log(e);
        }
    }
    async getCustomerType() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('customer-type-api/', requestParams);
            const data = await response.json();
            this.setState({
                dropdownCustomerType: data.customerType,
                multipleCustomerTypeList: data.customerType,
            });

        } catch (e) {
            console.log(e);
        }
    }
    handleRadCustomerTypeChange(e) {
        if (e.target.value == "0") {
            this.setState({
                radCustomerType: e.target.value,
                vehicleId: 0,
                vehicleName: "",
                filterVehicleList: [],
            });
        }
        else if (e.target.value == "1") {
            this.setState({
                radCustomerType: e.target.value,
                filterVehicleList: [],
            });
        }
        else {
            this.setState({
                radCustomerType: e.target.value,
                vehicleId: 0,
                vehicleName: "",
            });

        }
        this.setState({ radCustomerType: e.target.value });
    }
    handleCustomerTypeChange(selectedOption) {
        if (selectedOption != null) {
            //this.setState({ customerTypeId: selectedOption.id, customerType: selectedOption.name })
            this.setState({
                customerTypeId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0),
                customerType: (selectedOption.selectedItem != null ? selectedOption.selectedItem.name : ""),
            })
        }
        else {
            this.setState({ customerTypeId: 0, customerType: "" })
        }
    }
    handleMultipleCustomerTypeChange(selectedOption) {
        try {
            this.setState({ filterCustomerTypeList: [] })
            if (selectedOption != null) {
                //this.setState({ filterCustomerTypeList: selectedOption })
                this.setState({ filterCustomerTypeList: (selectedOption.selectedItem != null ? selectedOption.selectedItem : []), })
            }
        } catch (e) {

        }
    }
    handleCustomerChange(selectedOption) {
        this.setState({
            customerCode: (selectedOption.selectedItem != null ? selectedOption.selectedItem.code : ""),
            customerName: (selectedOption.selectedItem != null ? selectedOption.selectedItem.name : ""),
        })
        this.state.errors["customerCode"] = "";
    }
    handleFromCustomerChange(selectedOption) {
        this.setState({
            fromCustomerCode: (selectedOption != null ? selectedOption.code : ''),
            fromCustomerName: (selectedOption != null ? selectedOption.name : ''),
        })
        this.state.errors["fromCustomerCode"] = "";
    }
    handleToCustomerChange(selectedOption) {
        this.setState({
            toCustomerCode: (selectedOption != null ? selectedOption.code : ''),
            toCustomerName: (selectedOption != null ? selectedOption.name : ''),
        })
        this.state.errors["toCustomerCode"] = "";
    }
    handleRadCustomerChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ radCustomer: value, customerCode: '' });
        this.state.errors["customerCode"] = "";
    }
    handleDateTypeChange(e) {
        this.setState({
            radDateType: e.target.value,
        })
    }
    handleRideDateChanged(event) {
        let rideDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ singleDate: rideDateValue })
    }
    handleFromDateChanged(event) {
        let fromDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ startDate: fromDateValue })
    }
    handleToDateChanged(event) {
        let toDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ endDate: toDateValue })
    }
    handleClosedReservationsReportSubmit(e) {
        e.preventDefault();
        this.downloadClosedReservationsReportAsPdf(e);
    }
    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;

        errors.customerCode = "";
        errors.fromCustomerCode = "";
        errors.toCustomerCode = "";
        errors.customerTypeId = 0;
        errors.multipleCustomerTypeId = 0;

        this.setState({ message: '' });

        if (this.state.radCustomerType == "1") {
            if (this.state.customerTypeId == "") {
                formIsValid = false;
                errors.customerTypeId = "Please select customer type";
            }
        }

        if (this.state.radCustomerType == "2") {
            if (this.state.filterCustomerTypeList.length == 0) {
                formIsValid = false;
                errors.multipleCustomerTypeId = "Please select customer type";
            }
        }

        if (this.state.radCustomer == "1") {
            if (this.state.customerCode == "") {
                formIsValid = false;
                errors.customerCode = "Please select customer";
            }
        }

        if (this.state.radCustomer == "2") {
            if (this.state.fromCustomerCode == 0) {
                formIsValid = false;
                errors.fromCustomerCode = "Please select from customer";
            }

            if (this.state.toCustomerCode == 0) {
                formIsValid = false;
                errors.toCustomerCode = "Please select to customer";
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    async downloadClosedReservationsReportAsPdf(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            try {
                this.setState({
                    //loadPanelVisible: true,
                    isSubmited: true,
                });

                let selectionCriteria = "", reportHeader = "", accountType = "";
                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.fileFormat = 'pdf';
                request.MethodName = "GetAccountRidePaxReport";

                if (this.state.radCustomerType == "0") {
                    reportHeader = "For all Customer Types";
                    accountType = "";
                    selectionCriteria = "";
                }
                else if (this.state.radCustomerType == "1") {
                    accountType = this.state.customerType;
                    reportHeader = "For Customer Type -" + accountType;
                    selectionCriteria += " {Rpt_Accounts;1.Account_Type} = '" + accountType + "'";
                }
                else if (this.state.radCustomerType == "2") {
                    let mulCustomerType = "";
                    for (let vt = 0; vt < this.state.filterCustomerTypeList.length; vt++) {
                        mulCustomerType += this.state.filterCustomerTypeList[vt].name + ",";
                    }
                    reportHeader = "For Account Type's -  " + mulCustomerType.substring(0, mulCustomerType.length - 1);
                    selectionCriteria += " " + (selectionCriteria != "" ? " Or " : "") + " ({Rpt_Accounts;1.ACCOUNT_TYPE} = '" + mulCustomerType.substring(0, mulCustomerType.length - 1) + "') ";
                }

                if (parseInt(this.state.radCustomer) == 0) {
                    reportHeader += " For all Account, ";
                    request.FromAccountCode = "";
                    request.ToAccountCode = "";
                }
                else if (parseInt(this.state.radCustomer) == 1) {
                    reportHeader += "  " + " For Account" + "  " + this.state.customerName + ",";
                    request.FromAccountCode = this.state.customerCode;
                    request.ToAccountCode = this.state.customerCode;
                }
                else {
                    reportHeader += "  " + " From Account " + "  " + this.state.fromCustomerName + "  " + " To Account " + "  " + this.state.toCustomerName + ",";
                    request.FromAccountCode = this.state.fromCustomerCode;
                    request.ToAccountCode = this.state.toCustomerCode;
                }

                if (parseInt(this.state.radDateType) == 0) {
                    reportHeader += "For All Date";
                    request.StartDate = null;
                    request.EndDate = null;
                }
                else if (parseInt(this.state.radDateType) == 1) {
                    reportHeader += "For Date " + formatDate(this.state.singleDate, "MM/dd/yyyy");
                    request.StartDate = formatDate(this.state.singleDate, "MM/dd/yyyy");
                    request.EndDate = formatDate(this.state.singleDate, "MM/dd/yyyy");
                }
                else {
                    reportHeader += "From Date " + formatDate(this.state.startDate, "MM/dd/yyyy") + " To Date " + formatDate(this.state.endDate, "MM/dd/yyyy");
                    request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy");
                    request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy");
                }

                request.Header = reportHeader;
                request.SelectionCrieteria = selectionCriteria;

                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({
                    //loadPanelVisible: false,
                    isSubmited: false,
                });

                let a = document.createElement('a');
                a.href = dataResponse.reportBase64String;
                a.download = 'AccountsRidePax.pdf';
                a.click();

            } catch (e) {
                //debugger;
            }
        }
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isEmailSubmitted: false,
                isOpenModal: false,
                loadPanelVisible: false,
                radDateType: "0",
                singleDate: new Date(),
                startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
                endDate: new Date(),
                radCustomer: "0",
                customerCode: '',
                fromCustomerCode: "",
                toCustomerCode: "",
                customerName: "",
                fromCustomerName: "",
                toCustomerName: "",
                radCustomerType: "0",
                customerTypeId: 0,
                customerType: "",
                filterCustomerTypeList: [],
                errors: {
                    singleDate: new Date(),
                    startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
                    endDate: new Date(),
                    customerCode: '',
                    fromCustomerCode: "",
                    toCustomerCode: "",
                    customerTypeId: 0,
                    multipleCustomerTypeId: 0,
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleClearSearch() {
        try {
            this.setState({
                isSubmitted: false,
                isEmailSubmitted: false,
                isOpenModal: true,
                loadPanelVisible: false,
                radDateType: "0",
                singleDate: new Date(),
                startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
                endDate: new Date(),
                radCustomer: "0",
                customerCode: '',
                fromCustomerCode: "",
                toCustomerCode: "",
                customerName: "",
                fromCustomerName: "",
                toCustomerName: "",
                radCustomerType: "0",
                customerTypeId: 0,
                customerType: "",
                filterCustomerTypeList: [],
                errors: {
                    singleDate: new Date(),
                    startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
                    endDate: new Date(),
                    customerCode: '',
                    fromCustomerCode: "",
                    toCustomerCode: "",
                    customerTypeId: 0,
                    multipleCustomerTypeId: 0,
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isEmailSubmitted: true });
                let selectionCriteria = "", reportHeader = "", accountType = "";
                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.fileFormat = 'pdf';
                request.MethodName = "GetAccountRidePaxReport";

                if (this.state.radCustomerType == "0") {
                    reportHeader = "For all Customer Types";
                    accountType = "";
                    selectionCriteria = "";
                }
                else if (this.state.radCustomerType == "1") {
                    accountType = this.state.customerType;
                    reportHeader = "For Customer Type -" + accountType;
                    selectionCriteria += " {Rpt_Accounts;1.Account_Type} = '" + accountType + "'";
                }
                else if (this.state.radCustomerType == "2") {
                    let mulCustomerType = "";
                    for (let vt = 0; vt < this.state.filterCustomerTypeList.length; vt++) {
                        mulCustomerType += this.state.filterCustomerTypeList[vt].name + ",";
                    }
                    reportHeader = "For Account Type's -  " + mulCustomerType.substring(0, mulCustomerType.length - 1);
                    selectionCriteria += " " + (selectionCriteria != "" ? " Or " : "") + " ({Rpt_Accounts;1.ACCOUNT_TYPE} = '" + mulCustomerType.substring(0, mulCustomerType.length - 1) + "') ";
                }

                if (parseInt(this.state.radCustomer) == 0) {
                    reportHeader += " For all Account, ";
                    request.FromAccountCode = "";
                    request.ToAccountCode = "";
                }
                else if (parseInt(this.state.radCustomer) == 1) {
                    reportHeader += "  " + " For Account" + "  " + this.state.customerName + ",";
                    request.FromAccountCode = this.state.customerCode;
                    request.ToAccountCode = this.state.customerCode;
                }
                else {
                    reportHeader += "  " + " From Account " + "  " + this.state.fromCustomerName + "  " + " To Account " + "  " + this.state.toCustomerName + ",";
                    request.FromAccountCode = this.state.fromCustomerCode;
                    request.ToAccountCode = this.state.toCustomerCode;
                }

                if (parseInt(this.state.radDateType) == 0) {
                    reportHeader += "For All Date";
                    request.StartDate = null;
                    request.EndDate = null;
                }
                else if (parseInt(this.state.radDateType) == 1) {
                    reportHeader += "For Date " + formatDate(this.state.singleDate, "MM/dd/yyyy");
                    request.StartDate = formatDate(this.state.singleDate, "MM/dd/yyyy");
                    request.EndDate = formatDate(this.state.singleDate, "MM/dd/yyyy");
                }
                else {
                    reportHeader += "From Date " + formatDate(this.state.startDate, "MM/dd/yyyy") + " To Date " + formatDate(this.state.endDate, "MM/dd/yyyy");
                    request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy");
                    request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy");
                }

                request.Header = reportHeader;
                request.SelectionCrieteria = selectionCriteria;

                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Accounts Ride wise pax report';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = 'AccountsRidePax.pdf'
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '60%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleClosedReservationsReportSubmit}
                        style={(this.state.isSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Accounts Ride wise pax report'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radCustomer" value="1" checked={this.state.radCustomer === "0"} onChange={this.handleRadCustomerChange} />
                                            <label className="form-check-label">All Customers</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radCustomer" value="1" checked={this.state.radCustomer === "1"} onChange={this.handleRadCustomerChange} />
                                            <label className="form-check-label">Single</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radCustomer" value="2" checked={this.state.radCustomer === "2"} onChange={this.handleRadCustomerChange} />
                                            <label className="form-check-label">Range</label>
                                        </span>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder="Select customer"*/}
                                        {/*    name="customerCode"*/}
                                        {/*    options={this.state.dropdownCustomer.length > 0 ? this.state.dropdownCustomer : []}*/}
                                        {/*    getOptionLabel={option => option.fullName}*/}
                                        {/*    getOptionValue={option => option.code}*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.customerCode === '' ? [] : [this.state.dropdownCustomer.find(opt => opt.code == this.state.customerCode)]}*/}
                                        {/*    onChange={this.handleCustomerChange}*/}
                                        {/*    isMulti={false}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isDisabled={this.state.radCustomer === "1" ? false : true}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*    isClearable={false}*/}
                                        {/*/>*/}
                                        <SelectBox
                                            dataSource={this.state.dropdownCustomer.length > 0 ? this.state.dropdownCustomer : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="customerCode"
                                            defaultValue={0}
                                            value={this.state.customerCode}
                                            displayExpr='fullName'
                                            valueExpr='code'
                                            onSelectionChanged={this.handleCustomerChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerCode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerCode"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>From Customer</label>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder="Select customer"*/}
                                        {/*    name="customerCode"*/}
                                        {/*    options={this.state.dropdownCustomer.length > 0 ? this.state.dropdownCustomer : []}*/}
                                        {/*    getOptionLabel={option => option.fullName}*/}
                                        {/*    getOptionValue={option => option.code}*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.fromCustomerCode === '' ? [] : [this.state.dropdownCustomer.find(opt => opt.code == this.state.fromCustomerCode)]}*/}
                                        {/*    onChange={this.handleFromCustomerChange}*/}
                                        {/*    isMulti={false}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isDisabled={this.state.radCustomer === "2" ? false : true}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*    isClearable={false}*/}
                                        {/*/>*/}
                                        <SelectBox
                                            dataSource={this.state.dropdownCustomer.length > 0 ? this.state.dropdownCustomer : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="customerCode"
                                            defaultValue={0}
                                            value={this.state.customerCode}
                                            displayExpr='fullName'
                                            valueExpr='code'
                                            onSelectionChanged={this.handleFromCustomerChange}
                                            showClearButton={true}
                                            disabled={this.state.radCustomer === "2" ? false : true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["fromCustomerCode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["fromCustomerCode"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>To Customer</label>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder="Select customer"*/}
                                        {/*    name="customerCode"*/}
                                        {/*    options={this.state.dropdownCustomer.length > 0 ? this.state.dropdownCustomer : []}*/}
                                        {/*    getOptionLabel={option => option.fullName}*/}
                                        {/*    getOptionValue={option => option.code}*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.toCustomerCode === '' ? [] : [this.state.dropdownCustomer.find(opt => opt.code == this.state.toCustomerCode)]}*/}
                                        {/*    onChange={this.handleToCustomerChange}*/}
                                        {/*    isMulti={false}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isDisabled={this.state.radCustomer === "2" ? false : true}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*    isClearable={false}*/}
                                        {/*/>*/}
                                        <SelectBox
                                            dataSource={this.state.dropdownCustomer.length > 0 ? this.state.dropdownCustomer : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="customerCode"
                                            defaultValue={0}
                                            value={this.state.customerCode}
                                            displayExpr='fullName'
                                            valueExpr='code'
                                            onSelectionChanged={this.handleToCustomerChange}
                                            showClearButton={true}
                                            disabled={this.state.radCustomer === "2" ? false : true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["toCustomerCode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["toCustomerCode"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radCustomerType" value="0" checked={this.state.radCustomerType === "0"} onChange={this.handleRadCustomerTypeChange} />
                                            <label className="form-check-label">All</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radCustomerType" value="1" checked={this.state.radCustomerType === "1"} onChange={this.handleRadCustomerTypeChange} />
                                            <label className="form-check-label">Single</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radCustomerType" value="2" checked={this.state.radCustomerType === "2"} onChange={this.handleRadCustomerTypeChange} />
                                            <label className="form-check-label">Range</label>
                                        </span>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder="Select customer type"*/}
                                        {/*    name="customerTypeId"*/}
                                        {/*    options={this.state.dropdownCustomerType.length > 0 ? this.state.dropdownCustomerType : []}*/}
                                        {/*    getOptionLabel={option => option.name}*/}
                                        {/*    getOptionValue={option => option.id}*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.customerTypeId === '' ? [] : [this.state.dropdownCustomerType.find(opt => opt.id == this.state.customerTypeId)]}*/}
                                        {/*    onChange={this.handleCustomerTypeChange}*/}
                                        {/*    isMulti={false}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isDisabled={false}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*    isClearable={false}*/}
                                        {/*    isDisabled={this.state.radCustomerType == "1" ? false : true}*/}
                                        {/*/>*/}
                                        <SelectBox
                                            dataSource={this.state.dropdownCustomerType.length > 0 ? this.state.dropdownCustomerType : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer type"
                                            name="customerTypeId"
                                            defaultValue={0}
                                            value={this.state.customerTypeId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleCustomerTypeChange}
                                            showClearButton={true}
                                            disabled={this.state.radCustomerType == "1" ? false : true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerTypeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerTypeId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Select Multiple Customer Type</label>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder="Select customer type"*/}
                                        {/*    name="multipleCustomerTypeId"*/}
                                        {/*    options={this.state.multipleCustomerTypeList.length > 0 ? this.state.multipleCustomerTypeList : []}*/}
                                        {/*    getOptionLabel={option => option.name}*/}
                                        {/*    getOptionValue={option => option.id}*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.filterCustomerTypeList.length > 0 ? this.state.filterCustomerTypeList : []}*/}
                                        {/*    onChange={this.handleMultipleCustomerTypeChange}*/}
                                        {/*    isMulti={true}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isDisabled={false}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*    isClearable={false}*/}
                                        {/*    isDisabled={this.state.radCustomerType == "2" ? false : true}*/}
                                        {/*/>*/}
                                        <SelectBox
                                            dataSource={this.state.multipleCustomerTypeList.length > 0 ? this.state.multipleCustomerTypeList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer type"
                                            name="multipleCustomerTypeId"
                                            defaultValue={0}
                                            value={this.state.filterCustomerTypeList}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleMultipleCustomerTypeChange}
                                            showClearButton={true}
                                            disabled={this.state.radCustomerType == "2" ? false : true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["multipleCustomerTypeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["multipleCustomerTypeId"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radDateType" value="0" checked={this.state.radDateType === "0"} onChange={this.handleDateTypeChange} />
                                            <label className="form-check-label">All Date</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radDateType" value="1" checked={this.state.radDateType === "1"} onChange={this.handleDateTypeChange} />
                                            <label className="form-check-label">Single</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radDateType" value="2" checked={this.state.radDateType === "2"} onChange={this.handleDateTypeChange} />
                                            <label className="form-check-label">Range</label>
                                        </span>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.singleDate}
                                            value={this.state.singleDate}
                                            onValueChanged={this.handleRideDateChanged}
                                            disabled={this.state.radDateType === "1" ? false : true}
                                            width="100%"
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["singleDate"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["singleDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>From Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.startDate}
                                            value={this.state.startDate}
                                            onValueChanged={this.handleFromDateChanged}
                                            disabled={this.state.radDateType === "2" ? false : true}
                                            width="100%"
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["startDate"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["startDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>To Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.endDate}
                                            value={this.state.endDate}
                                            onValueChanged={this.handleToDateChanged}
                                            disabled={this.state.radDateType === "2" ? false : true}
                                            width="100%"
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["endDate"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["endDate"]}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="submit" className="btn btn-success btn-sm" title="Download" onClick={this.handleClosedReservationsReportSubmit} >
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                                </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}