import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import DateBox from 'devextreme-react/date-box';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions';
import { LoadPanel } from 'devextreme-react/load-panel';
import RadioGroup from 'devextreme-react/radio-group';

const position = { of: '#historydiv' };

const invoiceTypeRadio = [
    { id: 0, name: 'Invoice wise' },
    { id: 1, name: 'Projected wise' },
];

export class InvoiceSalesReportComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            isDownloadSubmited: false,
            loadPanelVisible: false,
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),
            invoiceTypeList: invoiceTypeRadio,
            invoiceType: invoiceTypeRadio[0],
            invoiceSalesGridDataSource: [],
            errors: {
                userId: 0,
            }
        }

        this.handleInvoiceTypeChange = this.handleInvoiceTypeChange.bind(this);
        this.handleFromDateChanged = this.handleFromDateChanged.bind(this);
        this.handleToDateChanged = this.handleToDateChanged.bind(this);

        this.handleInvoiceSalesSubmit = this.handleInvoiceSalesSubmit.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true,
        });

        this.setState({
            loadPanelVisible: false,
        });
    }

    handleInvoiceTypeChange(e) {
        if (e != null) {
            this.setState({
                invoiceType: e.value,
            })
        }
        else {
            this.setState({
                invoiceType: invoiceTypeRadio[0],
            })
        }
    }
    async handleInvoiceSalesSubmit(e) {
        e.preventDefault();
        try {
            this.setState({
                //loadPanelVisible: true,
                isSubmited: true,
            });

            var invoiceSalesRequest = new Object();
            invoiceSalesRequest.FromDate = formatDate(this.state.startDate, "MM/dd/yyyy") + " 00:00:00";
            invoiceSalesRequest.ToDate = formatDate(this.state.endDate, "MM/dd/yyyy") + " 23:59:59";
            invoiceSalesRequest.AccountType = "";
            invoiceSalesRequest.AccountCode = "";
            invoiceSalesRequest.InvoiceNo = parseInt(0);
            invoiceSalesRequest.Flag = this.state.invoiceType.id == 0 ? "MI_ACTTYPEGRP" : "RES_ACTTYPEGRP";

            const requestParams = getRequestParams('POST', invoiceSalesRequest);
            const response = await fetch('/report-api/invoice-sales', requestParams);
            const data = await response.json();

            if (data.isSuccess) {
                this.setState({
                    invoiceSalesGridDataSource: data.invoiceSales
                });
            }

            this.setState({
                //loadPanelVisible: false,
                isSubmited: false,
            });
        } catch (e) {
            debugger;
        }
    }
    handleFromDateChanged(event) {
        let fromDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ startDate: fromDateValue })
    }
    handleToDateChanged(event) {
        let toDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ endDate: toDateValue })
    }

    handleClearSearch(e) {
        this.setState({
            isSubmited: false,
            isDownloadSubmited: false,
            loadPanelVisible: false,
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),
            invoiceTypeList: invoiceTypeRadio,
            invoiceType: invoiceTypeRadio[0],
            invoiceSalesGridDataSource: [],
            errors: {
                userId: 0,
            }
        });
    }

    render() {

        const renderGridCurrencyCell = (data) => {
            return <div>${parseFloat(data.value).toFixed(2)}</div>;
        }

        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-sm-6 col-md-6 col-lg-6">
                                <h1 className="m-0 text-dark">Invoice Sales</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem> <NavLink tag={Link} to="/report" className="btn btn-block btn-outline-secondary btn-sm" title="Reports" ><i className="fas fa-arrow-left"></i> Back</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <form method="post" onSubmit={this.handleInvoiceSalesSubmit} style={(this.state.isSubmited) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="rez-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">From Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.startDate}
                                            value={this.state.startDate}
                                            onValueChanged={this.handleFromDateChanged}
                                            width="100%"
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>To Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.endDate}
                                            value={this.state.endDate}
                                            onValueChanged={this.handleToDateChanged}
                                            width="100%"
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label className="form-check-label">&nbsp;</label>
                                        <RadioGroup id="radio-group-viewby" dataSource={this.state.invoiceTypeList} displayExpr="name" value={this.state.invoiceType} onValueChanged={this.handleInvoiceTypeChange} layout="horizontal" />
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label className="btn-block">&nbsp;</label>
                                        <button type="submit" className="btn btn-success btn-sm" onClick={this.handleInvoiceSalesSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                            {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSubmited && <span title="Search"><i className="fas fa-search"></i> Search</span>}
                                        </button>&nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleClearSearch} title="Clear"><i className="fas fa-eraser"></i> Clear</button>                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="content">
                    <div className="card">
                        <div className="card-body">
                            <DataGrid
                                dataSource={this.state.invoiceSalesGridDataSource}
                                keyExpr="invoiceNo"
                                columnsAutoWidth={true}
                                showColumnLines={false}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                showBorders={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                width='100%'
                            //onToolbarPreparing={this.onToolbarPreparing}
                            >
                                <Column dataField="accountDesc" caption="Account Description" allowFiltering={true} allowSorting={true} />
                                <Column dataField="rideNetAmount" caption="Total Ride Amount" allowFiltering={true} allowSorting={true} />
                                <Column dataField="creditAmount" caption="Total Credit Amount" allowFiltering={true} allowSorting={true} />
                                <Column dataField="netAmount" caption="Total Billable Amount" allowFiltering={true} allowSorting={true} />
                                <Column dataField="totalPaid" caption="Total Paid" allowFiltering={true} allowSorting={true} />
                                <Column dataField="dueAmount" caption="Due Amount" allowFiltering={true} allowSorting={true} />
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={false} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                <Export enabled={true} allowExportSelectedData={false} />
                                <ColumnChooser enabled={false} />
                                <ColumnFixing enabled={false} />
                            </DataGrid>
                        </div>
                    </div>

                </div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        )
    }
}