import { LoadPanel } from 'devextreme-react/load-panel';
import { SelectBox } from 'devextreme-react/select-box';
import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { SendEmailComponent } from '../Shared/SendEmailComponent';
const vehicleStatusDropdown = [
    { id: 0, name: 'Enabled vehicles only' },
    { id: 1, name: 'Disabled vehicles only' },
    { id: 2, name: 'All vehicles (both)' },
];

export class VehicleCommissionReportComponent extends Component {
    constructor() {
        super()
        this.state = {
            isSubmitted: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            radVehicle: "0",
            vehicleList: [],
            fromVehicleId: 0,
            fromVehicleName: "",
            toVehicleId: 0,
            toVehicleName: "",
            vehicleStatusList: vehicleStatusDropdown,
            vehicleStatus: vehicleStatusDropdown[2],
            errors: {
                fromVehicleId: 0,
                toVehicleId: 0
            }
        }

        this.getVehicles = this.getVehicles.bind(this);

        this.handleRadVehicleChange = this.handleRadVehicleChange.bind(this);
        this.handleFromVehicleChange = this.handleFromVehicleChange.bind(this);
        this.handleToVehicleChange = this.handleToVehicleChange.bind(this);
        this.handleVehicleStatusChange = this.handleVehicleStatusChange.bind(this);

        this.handleVehicleCommissionReportSubmit = this.handleVehicleCommissionReportSubmit.bind(this);
        this.downloadVehicleCommissionAsPdf = this.downloadVehicleCommissionAsPdf.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
    }
    async componentDidMount() {
        await this.getVehicles();
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    async getVehicles() {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/vehicle-api/dropdown-all', requestParams);
            const data = await response.json();
            this.setState({
                vehicleList: data.vehiclesDropdown,
                multiVehicleList: data.vehiclesDropdown,
            });
        } catch (e) {
            console.log(e);
        }
    }
    handleVehicleStatusChange(selectedOption) {
        if (selectedOption != null) {
            this.setState({ vehicleStatus: (selectedOption.selectedItem != null ? selectedOption.selectedItem : []), })
        }
        else {
            this.setState({ vehicleStatus: vehicleStatusDropdown[2] })
        }
    }
    handleRadVehicleChange(e) {
        if (e.target.value == "0") {
            this.setState({
                radVehicle: e.target.value,
                vehicleId: 0,
                vehicleName: "",
            });
        }
        else if (e.target.value == "1") {
            this.setState({
                radVehicle: e.target.value,
            });
        }
        this.setState({ radVehicle: e.target.value });
    }
    handleFromVehicleChange(e) {
        if (e !== null) {
            this.setState({
                fromVehicleId: (e.selectedItem != null ? e.selectedItem.id : 0),
                fromVehicleName: (e.selectedItem != null ? e.selectedItem.name : ""),
            })
        }
        else {
            this.setState({
                fromVehicleId: 0,
                fromVehicleName: "",
            });
        }
    }
    handleToVehicleChange(e) {
        if (e !== null) {
            this.setState({
                toVehicleId: (e.selectedItem != null ? e.selectedItem.id : 0),
                toVehicleName: (e.selectedItem != null ? e.selectedItem.name : ""),
            });
        }
        else {
            this.setState({
                toVehicleId: 0,
                toVehicleName: "",
            });
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getCustomerList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isEmailSubmitted: false,
                isOpenModal: false,
                loadPanelVisible: false,
                radVehicle: "0",
                fromVehicleId: 0,
                fromVehicleName: "",
                toVehicleId: 0,
                toVehicleName: "",
                vehicleStatusList: vehicleStatusDropdown,
                vehicleStatus: vehicleStatusDropdown[2],
                errors: {
                    fromVehicleId: 0,
                    toVehicleId: 0
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;

        errors.fromVehicleId = 0;
        errors.toVehicleId = 0;

        if (this.state.radVehicle == "1") {
            if (this.state.fromVehicleId == 0) {
                formIsValid = false;
                errors.fromVehicleId = "Please select from vehicle";
            }

            if (this.state.toVehicleId == 0) {
                formIsValid = false;
                errors.toVehicleId = "Please select to vehicle";
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    handleVehicleCommissionReportSubmit(e) {
        e.preventDefault();
        this.downloadVehicleCommissionAsPdf(e);
    }
    async downloadVehicleCommissionAsPdf(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            try {
                this.setState({
                    //  loadPanelVisible: true,
                    isSubmitted: true,
                });

                let reportHeader = "", vehicleRange = "", selectionCriteria = "", vehicleStatus = "";
                if (this.state.radVehicle == "1") {
                    vehicleRange = " From Vehicle " + this.state.fromVehicleName + " To " + this.state.toVehicleName;
                    selectionCriteria = "{PROC_SELECT_VHCommission_REPORT;1.VehicleNo} >= '" + this.state.fromVehicleName + "' and {PROC_SELECT_VHCommission_REPORT;1.VehicleNo} <= '" + this.state.toVehicleName + "'";
                }
                else {
                    vehicleRange = " For All Vehicles";
                }

                if (this.state.vehicleStatus.id == 0) {
                    selectionCriteria += (selectionCriteria != "" ? " AND " : "") + "{PROC_SELECT_VHCommission_REPORT;1.VH_ENABLE_YN} = " + "true";
                    vehicleStatus = ", For " + this.state.vehicleStatus.name;
                }
                else if (this.state.vehicleStatus.id == 1) {
                    selectionCriteria += (selectionCriteria != "" ? " AND " : "") + "{PROC_SELECT_VHCommission_REPORT;1.VH_ENABLE_YN} = " + "false";
                    vehicleStatus = ", For " + this.state.vehicleStatus.name;
                }

                if (vehicleRange == "") {
                    reportHeader = vehicleStatus;
                }
                else {
                    reportHeader = vehicleRange + vehicleStatus;
                }

                var request = new Object();
                request.fileFormat = 'pdf';
                request.MethodName = "GetVehicleCommission";
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.Header = reportHeader;
                request.SelectionCrieteria = selectionCriteria;

                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();

                this.setState({
                    //loadPanelVisible: false,
                    isSubmitted: false,
                });

                let a = document.createElement('a');
                a.href = dataResponse.reportBase64String;
                a.download = 'VehicleCommission.pdf';
                a.click();

            } catch (e) {

            }
        }
    }
    handleClearSearch() {
        this.setState({
            isSubmitted: false,
            isEmailSubmitted: false,
            isOpenModal: true,
            loadPanelVisible: false,
            radVehicle: "0",
            fromVehicleId: 0,
            fromVehicleName: "",
            toVehicleId: 0,
            toVehicleName: "",
            vehicleStatusList: vehicleStatusDropdown,
            vehicleStatus: vehicleStatusDropdown[2],
            errors: {
                fromVehicleId: 0,
                toVehicleId: 0
            }
        });
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isEmailSubmitted: true });
                let reportHeader = "", vehicleRange = "", selectionCriteria = "", vehicleStatus = "";
                if (this.state.radVehicle == "1") {
                    vehicleRange = " From Vehicle " + this.state.fromVehicleName + " To " + this.state.toVehicleName;
                    selectionCriteria = "{PROC_SELECT_VHCommission_REPORT;1.VehicleNo} >= '" + this.state.fromVehicleName + "' and {PROC_SELECT_VHCommission_REPORT;1.VehicleNo} <= '" + this.state.toVehicleName + "'";
                }
                else {
                    vehicleRange = " For All Vehicles";
                }

                if (this.state.vehicleStatus.id == 0) {
                    selectionCriteria += (selectionCriteria != "" ? " AND " : "") + "{PROC_SELECT_VHCommission_REPORT;1.VH_ENABLE_YN} = " + "true";
                    vehicleStatus = ", For " + this.state.vehicleStatus.name;
                }
                else if (this.state.vehicleStatus.id == 1) {
                    selectionCriteria += (selectionCriteria != "" ? " AND " : "") + "{PROC_SELECT_VHCommission_REPORT;1.VH_ENABLE_YN} = " + "false";
                    vehicleStatus = ", For " + this.state.vehicleStatus.name;
                }

                if (vehicleRange == "") {
                    reportHeader = vehicleStatus;
                }
                else {
                    reportHeader = vehicleRange + vehicleStatus;
                }

                var request = new Object();
                request.fileFormat = 'pdf';
                request.MethodName = "GetVehicleCommission";
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.Header = reportHeader;
                request.SelectionCrieteria = selectionCriteria;
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Vehicle Commission Report';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = 'VehicleCommission.pdf'
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '50%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleVehicleCommissionReportSubmit}
                        style={(this.state.isSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Vehicle Commission Report'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radVehicle" value="0" checked={this.state.radVehicle === "0"} onChange={this.handleRadVehicleChange} />
                                            <label className="form-check-label">All</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radVehicle" value="1" checked={this.state.radVehicle === "1"} onChange={this.handleRadVehicleChange} />
                                            <label className="form-check-label">Multiple Vehicle</label>
                                        </span>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder="Select vehicle"*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.fromVehicleId === 0 ? [] : [this.state.vehicleList.find(opt => opt.id === this.state.fromVehicleId)]}*/}
                                        {/*    name="id"*/}
                                        {/*    onChange={this.handleFromVehicleChange}*/}
                                        {/*    getOptionLabel={option => option.name}*/}
                                        {/*    getOptionValue={option => option.id}*/}
                                        {/*    options={this.state.vehicleList.length > 0 ? this.state.vehicleList : []}*/}
                                        {/*    isMulti={false}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*    isDisabled={this.state.radVehicle === "1" ? false : true}*/}
                                        {/*/>*/}

                                        <SelectBox
                                            dataSource={this.state.vehicleList.length > 0 ? this.state.vehicleList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select vehicle"
                                            name="id"
                                            defaultValue={[]}
                                            value={this.state.fromVehicleId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleFromVehicleChange}
                                            showClearButton={true}
                                            disabled={this.state.radVehicle === "1" ? false : true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["fromVehicleId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["fromVehicleId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label className="form-check-label">To Vehicle</label>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder="Select vehicle"*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.toVehicleId === 0 ? [] : [this.state.vehicleList.find(opt => opt.id === this.state.toVehicleId)]}*/}
                                        {/*    name="id"*/}
                                        {/*    onChange={this.handleToVehicleChange}*/}
                                        {/*    getOptionLabel={option => option.name}*/}
                                        {/*    getOptionValue={option => option.id}*/}
                                        {/*    options={this.state.vehicleList.length > 0 ? this.state.vehicleList : []}*/}
                                        {/*    isMulti={false}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*    isDisabled={this.state.radVehicle === "1" ? false : true}*/}
                                        {/*/>*/}

                                        <SelectBox
                                            dataSource={this.state.vehicleList.length > 0 ? this.state.vehicleList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select vehicle"
                                            name="id"
                                            defaultValue={[]}
                                            value={this.state.toVehicleId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleToVehicleChange}
                                            showClearButton={true}
                                            disabled={this.state.radVehicle === "1" ? false : true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["toVehicleId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["toVehicleId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label className="form-check-label">Vehicle Status</label>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder="Select vehicle status"*/}
                                        {/*    name="id"*/}
                                        {/*    options={this.state.vehicleStatusList.length > 0 ? this.state.vehicleStatusList : []}*/}
                                        {/*    getOptionLabel={option => option.name}*/}
                                        {/*    getOptionValue={option => option.id}*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.vehicleStatus.id == 0 ? [] : [this.state.vehicleStatusList.find(opt => opt.id == this.state.vehicleStatus.id)]}*/}
                                        {/*    onChange={this.handleVehicleStatusChange}*/}
                                        {/*    isMulti={false}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isDisabled={false}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*/>*/}

                                        <SelectBox
                                            dataSource={this.state.vehicleStatusList.length > 0 ? this.state.vehicleStatusList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select vehicle status"
                                            name="id"
                                            defaultValue={[]}
                                            value={this.state.vehicleStatus.id}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleVehicleStatusChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="submit" className="btn btn-success btn-sm" title="Download" onClick={this.handleVehicleCommissionReportSubmit} >
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                                </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}