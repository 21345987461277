import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
} from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import DateBox from 'devextreme-react/date-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import { SelectBox } from 'devextreme-react/select-box';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React from 'react';
import { Link } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';
import Swal from 'sweetalert2';
import { DateFormat } from '../../helpers/fixcodes';
import { formatDate, getRequestParams } from '../../helpers/globalfunctions';
import { SendEmailComponent } from '../Shared/SendEmailComponent';
const position = { of: '#historydiv' };

const graphDropdown = [
    { id: 0, name: 'Daily' },
    { id: 1, name: 'Weekly' },
    { id: 2, name: 'Monthly' },
];

export class ReservationAgentProductivityReportComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            isDownloadSubmited: false,
            isEmailSubmitted: false,
            loadPanelVisible: false,
            chkPeriodSelection: "1",
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),
            isAllUser: true,
            userDropdownList: [],
            userData: null,
            userId: 0,
            graphList: graphDropdown,
            graphType: graphDropdown[0],
            reportType: "0",
            reservationAgentProductivityGridDataSource: [],
            errors: {
                userId: 0,
            }
        }

        this.getReservationAgentList = this.getReservationAgentList.bind(this);
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.handlePeriodSelectionChange = this.handlePeriodSelectionChange.bind(this);
        this.handleReportTypeChange = this.handleReportTypeChange.bind(this);
        this.handleFromDateChanged = this.handleFromDateChanged.bind(this);
        this.handleToDateChanged = this.handleToDateChanged.bind(this);
        this.handleGraphChange = this.handleGraphChange.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);

        this.downloadAsPdf = this.downloadAsPdf.bind(this);
        this.emailReports = this.emailReports.bind(this);
        this.handleReservationAgentProductivitySubmit = this.handleReservationAgentProductivitySubmit.bind(this);
        this.handleReservationAgentProductivityReportSubmit = this.handleReservationAgentProductivityReportSubmit.bind(this);
        this.getReservationAgentProductivityReport = this.getReservationAgentProductivityReport.bind(this);
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true,
        });
        await this.getReservationAgentList();
        this.setState({
            loadPanelVisible: false,
        });
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'exportpdf',
                onClick: this.downloadAsPdf.bind(this),
                hint: 'Export to Pdf'
            }
        });

        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'email',
                onClick: this.emailReports.bind(this),
                hint: 'Email'
            }
        });

        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getReservationAgentProductivityReport.bind(this),
                hint: 'Refresh'
            }
        });
    }

    async getReservationAgentList(e) {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('account-api/reservationagent-dropdown', requestParams);
            const data = await response.json();
            this.setState({
                userDropdownList: data.users
            });
        } catch (ex) {
            console.log(ex);
        }
    }

    async downloadAsPdf(e) {
        this.getReservationAgentProductivityReport(e);
    }

    async emailReports() {
        const isValid = await Swal.fire({
            title: "<small>Pending Implementation</small>",
            text: "This feature is comming soon.",
            icon: "info",
            dangerMode: false,
            width: "25%",
            dangerMode: false,
            allowOutsideClick: false
        });
        return;
    }

    async handleReservationAgentProductivityReportSubmit(e) {
        await this.getReservationAgentProductivityReport();
    }

    async getReservationAgentProductivityReport(e) {
        try {
            this.setState({
                isSubmited: false,
                isDownloadSubmited: true,
            });
            let reportHeader = "", selectedGraphType = "";

            if (parseInt(this.state.reportType) == 2) {
                switch (this.state.graphType.id) {
                    case 0:
                        selectedGraphType = "D";
                        break;
                    case 1:
                        selectedGraphType = "W";
                        break;
                    case 2:
                        selectedGraphType = "M";
                        break;
                    default:
                        selectedGraphType = "";
                        break;
                }
                reportHeader = "Reservation Agent Loginwise Productivity " + this.state.graphType.name + " Graph ";
            }

            if (this.state.userId == 0) {
                reportHeader = reportHeader + "For All Users ";
            }
            else {
                reportHeader = reportHeader + "For User : " + this.state.userData.name;
            }

            if (parseInt(this.state.chkPeriodSelection) == 1) {
                reportHeader = reportHeader + " For All Period ";
            }
            else {
                reportHeader = reportHeader + " From Date : " + formatDate(this.state.startDate, "MM/dd/yyyy") + " To : " + formatDate(this.state.endDate, "MM/dd/yyyy");
            }

            var request = new Object();
            request.fileFormat = 'pdf';
            request.MethodName = "GetReservationAgentProductivity";
            request.ReportType = parseInt(this.state.reportType);
            request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy");
            request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy");
            request.UserId = this.state.userId;
            request.Header = reportHeader;
            request.Grouping = selectedGraphType;

            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();

            this.setState({
                isSubmited: false,
                isDownloadSubmited: false,
            });
            let a = document.createElement('a');
            a.href = dataResponse.reportBase64String;
            a.download = 'ReservationAgentProductivity.pdf';
            a.click();
        } catch (e) {

        }
    }

    handleReportTypeChange(e) {
        this.setState({ reportType: e.target.value });
    }
    handleGraphChange(selectedOption) {
        this.setState({ graphType: selectedOption.selectedItem })
    }
    handleUserChange(e) {
        if (e.selectedItem !== null) {
            this.setState({ userData: e.selectedItem, userId: e.selectedItem.id });
        }
        else {
            this.setState({ userData: null, userId: 0 });
        }
    }
    handlePeriodSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkPeriodSelection: value });
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }
    handleFromDateChanged(event) {
        let fromDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ startDate: fromDateValue })
    }
    handleToDateChanged(event) {
        let toDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ endDate: toDateValue })
    }

    async handleReservationAgentProductivitySubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {

            this.setState({
                //loadPanelVisible: true,
                isSubmited: true,
            });

            var reservationAgentProductivtyReportRequest = new Object();
            reservationAgentProductivtyReportRequest.fromDate = formatDate(this.state.startDate, "MM/dd/yyyy");
            reservationAgentProductivtyReportRequest.toDate = formatDate(this.state.endDate, "MM/dd/yyyy");
            reservationAgentProductivtyReportRequest.userId = parseInt(this.state.userId);
            const requestParams = getRequestParams('POST', reservationAgentProductivtyReportRequest);

            const response = await fetch('/report-api/reservation-productivity', requestParams);
            const data = await response.json();

            if (data.isSuccess) {
                this.setState({
                    reservationAgentProductivityGridDataSource: data.reservationAgentProductivity
                });
            }

            this.setState({
                //loadPanelVisible: false,
                isSubmited: false,
            });
        }
    }

    handleClearSearch(e) {
        this.setState({
            isSubmited: false,
            isDownloadSubmited: false,
            isEmailSubmitted: false,
            loadPanelVisible: false,
            chkPeriodSelection: "1",
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),
            isAllUser: true,
            userData: null,
            userId: 0,
            graphList: graphDropdown,
            graphType: graphDropdown[0],
            reportType: "0",
            reservationAgentProductivityGridDataSource: [],
            errors: {
                userId: 0,
            }
        });
    }

    handleValidation() {
        return true;
    }

    async viewReservationAgentProductivityReport(e, data, fileFormat) {
        this.getReservationAgentProductivityReport(e);
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isEmailSubmitted: true });
                let reportHeader = "", selectedGraphType = "";
                if (parseInt(this.state.reportType) == 2) {
                    switch (this.state.graphType.id) {
                        case 0:
                            selectedGraphType = "D";
                            break;
                        case 1:
                            selectedGraphType = "W";
                            break;
                        case 2:
                            selectedGraphType = "M";
                            break;
                        default:
                            selectedGraphType = "";
                            break;
                    }
                    reportHeader = "Reservation Agent Loginwise Productivity " + this.state.graphType.name + " Graph ";
                }
                if (this.state.userId == 0) {
                    reportHeader = reportHeader + "For All Users ";
                }
                else {
                    reportHeader = reportHeader + "For User : " + this.state.userData.name;
                }
                if (parseInt(this.state.chkPeriodSelection) == 1) {
                    reportHeader = reportHeader + " For All Period ";
                }
                else {
                    reportHeader = reportHeader + " From Date : " + formatDate(this.state.startDate, "MM/dd/yyyy") + " To : " + formatDate(this.state.endDate, "MM/dd/yyyy");
                }

                var request = new Object();
                request.fileFormat = 'pdf';
                request.MethodName = "GetReservationAgentProductivity";
                request.ReportType = parseInt(this.state.reportType);
                request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy");
                request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy");
                request.UserId = this.state.userId;
                request.Header = reportHeader;
                request.Grouping = selectedGraphType;

                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Reservation Agent Loginwise Productivity';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = 'ReservationAgentProductivity.pdf'
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {

        const renderGridCurrencyCell = (data) => {
            return <div>${parseFloat(data.value).toFixed(2)}</div>;
        }

        const renderGridCell = (data) => {
            return <div>
                <a href="#" className="fas fa-file-pdf icon text-info" title="View in PDF" onClick={(e) => { this.viewReservationAgentProductivityReport(e, data.data, "pdf") }}></a>&nbsp;&nbsp;
                <a href="#" className="fas fa-file-excel icon text-info" title="View in Excel" onClick={(e) => { this.viewReservationAgentProductivityReport(e, data.data, "xlsx") }}></a>&nbsp;&nbsp;
            </div>;
        }

        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-sm-6 col-md-6 col-lg-6">
                                <h1 className="m-0 text-dark">Reservation Agent Loginwise Productivity</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem> <NavLink tag={Link} to="/report" className="btn btn-block btn-outline-secondary btn-sm" title="Reports" ><i className="fas fa-arrow-left"></i> Back</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <form method="post" onSubmit={this.handleReservationAgentProductivitySubmit}
                    style={(this.state.isSubmited || this.state.isDownloadSubmited || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="rez-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                name="isAllUser"
                                                checked={this.state.isAllUser}
                                                onChange={this.handleInputChange} />
                                            <label className="form-check-label" htmlFor="isAllUser"> All User</label>
                                        </div>
                                        <SelectBox
                                            dataSource={this.state.userDropdownList.length > 0 ? this.state.userDropdownList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select user"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.userData != null ? this.state.userData.id : 0}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleUserChange}
                                            showClearButton={true}
                                            disabled={this.state.isAllUser}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["userId"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["userId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label htmlFor="">&nbsp;</label>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdAllPeriod" value="1" checked={this.state.chkPeriodSelection === "1"} onChange={this.handlePeriodSelectionChange} />
                                            <label className="form-check-label">All Period</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdInRangePeriod" value="2" checked={this.state.chkPeriodSelection === "2"} onChange={this.handlePeriodSelectionChange} />
                                            <label className="form-check-label">In Range</label>
                                        </span>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.startDate}
                                            value={this.state.startDate}
                                            onValueChanged={this.handleFromDateChanged}
                                            width="100%"
                                            disabled={this.state.chkPeriodSelection === "1"}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>To Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.endDate}
                                            value={this.state.endDate}
                                            onValueChanged={this.handleToDateChanged}
                                            width="100%"
                                            disabled={this.state.chkPeriodSelection === "1"}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radReportType" value="0" checked={this.state.reportType === "0"} onChange={this.handleReportTypeChange} />
                                            <label className="form-check-label">Summary</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radReportType" value="1" checked={this.state.reportType === "1"} onChange={this.handleReportTypeChange} />
                                            <label className="form-check-label">Details</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radReportType" value="2" checked={this.state.reportType === "2"} onChange={this.handleReportTypeChange} />
                                            <label className="form-check-label">Graph</label>
                                        </span>
                                        <SelectBox
                                            dataSource={this.state.graphList.length > 0 ? this.state.graphList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select airline"
                                            name="airlineId"
                                            defaultValue={0}
                                            value={this.state.graphType != null ? this.state.graphType.id : 0}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleGraphChange}
                                            showClearButton={true}
                                            disabled={this.state.reportType === "2" ? false : true}
                                            searchEnabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 text-right">
                                    <div className="form-group">
                                        <label className="btn-block"><br /></label>
                                        <button type="submit" className="btn btn-success btn-sm" onClick={this.handleReservationAgentProductivitySubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                            {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i></span>}
                                            {!this.state.isSubmited && <span title="Search"><i className="fas fa-search"></i></span>}
                                        </button>&nbsp;&nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleClearSearch}
                                            title="Clear"><i className="fas fa-eraser"></i></button>&nbsp;&nbsp;
                                        <button type="submit" className="btn btn-custom-gray btn-sm" onClick={this.handleReservationAgentProductivityReportSubmit} style={this.state.isDownloadSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                            {this.state.isDownloadSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i></span>}
                                            {!this.state.isDownloadSubmited && <span title="Download"><i className="fas fa-file-download"></i></span>}
                                        </button>&nbsp;&nbsp;
                                        <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                            {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i></span>}
                                            {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i></span>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="content">
                    <div className="card">
                        <div className="card-body">
                            <DataGrid
                                dataSource={this.state.reservationAgentProductivityGridDataSource}
                                keyExpr="userId"
                                columnsAutoWidth={true}
                                showColumnLines={false}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                showBorders={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                width='100%'
                                onToolbarPreparing={this.onToolbarPreparing}>
                                <Column dataField="userId" width={40} caption="" fixed={true} allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                <Column dataField="userName" caption="User" allowFiltering={true} allowSorting={true} />
                                <Column dataField="loginDateTime" caption="Login DateTime" allowFiltering={true} allowSorting={true} format={DateFormat.DefaultDateTimeFormat} dataType="datetime" />
                                <Column dataField="logoutDateTime" caption="Logout DateTime" allowFiltering={true} allowSorting={true} format={DateFormat.DefaultDateTimeFormat} dataType="datetime" />
                                <Column dataField="reservationCount" caption="Booked Reservations#" allowFiltering={true} allowSorting={true} />
                                <Column dataField="reservationChanged" caption="Changed Reservations#" allowFiltering={true} allowSorting={true} />
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={false} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                <Export enabled={true} allowExportSelectedData={false} />
                                <ColumnChooser enabled={false} />
                                <ColumnFixing enabled={false} />
                            </DataGrid>
                        </div>
                    </div>

                </div>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        )
    }
}