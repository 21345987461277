import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import { LoadPanel } from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import RadioGroup from 'devextreme-react/radio-group';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions';
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'
const invoicedRadio = [
    { id: 0, name: 'All' },
    { id: 1, name: 'Invoiced' },
    { id: 2, name: 'Non Invoiced' },
];
const driverPaidRadio = [
    { id: 0, name: 'All' },
    { id: 1, name: 'Driver Paid' },
    { id: 2, name: 'Driver Not Paid' },
];
const approvedRadio = [
    { id: 0, name: 'All' },
    { id: 1, name: 'Approved' },
    { id: 2, name: 'Non Approved' },
];
const orderByRadio = [
    { id: 0, name: 'By Customer Code' },
    { id: 1, name: 'By Ride date' },
];

export class RideReconsilationReportComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            rideReconsilation: [],
            singleDate: new Date(),
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),
            invoicedList: invoicedRadio,
            driverPaidList: driverPaidRadio,
            approvedList: approvedRadio,
            orderByList: orderByRadio,
            invoicedType: invoicedRadio[0],
            driverPaidType: driverPaidRadio[0],
            approvedType: approvedRadio[0],
            orderByType: orderByRadio[0],
            ridePeriod: "0",
            isRideDateRangeDisable: true,
        }
        this.handleFromDateChanged = this.handleFromDateChanged.bind(this);
        this.handleToDateChanged = this.handleToDateChanged.bind(this);
        this.downloadRideReconsilationAsPdf = this.downloadRideReconsilationAsPdf.bind(this);
        this.handleRideReconsilationReportSubmit = this.handleRideReconsilationReportSubmit.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
        this.handleInvoicedTypeChange = this.handleInvoicedTypeChange.bind(this);
        this.handleDriverPaidTypeChange = this.handleDriverPaidTypeChange.bind(this);
        this.handleApprovedTypeChange = this.handleApprovedTypeChange.bind(this);
        this.handleOrderByTypeChange = this.handleOrderByTypeChange.bind(this);
        this.handleRidePeriodChange = this.handleRidePeriodChange.bind(this);
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true,
        });

        this.setState({
            loadPanelVisible: false,
        });
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });

        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleInvoicedTypeChange(e) {
        this.setState({
            invoicedType: e.value
        });
    }
    handleDriverPaidTypeChange(e) {
        this.setState({
            driverPaidType: e.value
        });
    }
    handleApprovedTypeChange(e) {
        this.setState({
            approvedType: e.value
        });
    }
    handleOrderByTypeChange(e) {
        this.setState({
            orderByType: e.value
        });
    }
    handleRidePeriodChange(e) {
        let isDisable = false;
        if (parseInt(e.target.value) == 0) {
            isDisable = true;
        }
        else {
            isDisable = false;
        }

        this.setState({
            ridePeriod: e.target.value,
            isRideDateRangeDisable: isDisable,
        });
    }
    handleRideDateChanged(event) {
        let rideDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ rideDate: rideDateValue })
    }
    handleFromDateChanged(event) {
        let fromDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ startDate: fromDateValue })
    }
    handleToDateChanged(event) {
        let toDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ endDate: toDateValue })
    }
    handleRideReconsilationReportSubmit(e) {
        e.preventDefault();
        this.downloadRideReconsilationAsPdf();
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmited: false,
                isEmailSubmitted: false,
                isOpenModal: false,
                loadPanelVisible: false,
                rideReconsilation: [],
                singleDate: new Date(),
                startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
                endDate: new Date(),
                invoicedList: invoicedRadio,
                driverPaidList: driverPaidRadio,
                approvedList: approvedRadio,
                orderByList: orderByRadio,
                invoicedType: invoicedRadio[0],
                driverPaidType: driverPaidRadio[0],
                approvedType: approvedRadio[0],
                orderByType: orderByRadio[0],
                ridePeriod: "0",
                isRideDateRangeDisable: true,
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleClearSearch() {
        this.setState({
            isSubmited: false,
            isEmailSubmitted: false,
            isOpenModal: true,
            loadPanelVisible: false,
            rideReconsilation: [],
            singleDate: new Date(),
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),
            invoicedList: invoicedRadio,
            driverPaidList: driverPaidRadio,
            approvedList: approvedRadio,
            orderByList: orderByRadio,
            invoicedType: invoicedRadio[0],
            driverPaidType: driverPaidRadio[0],
            approvedType: approvedRadio[0],
            orderByType: orderByRadio[0],
            ridePeriod: "0",
            isRideDateRangeDisable: true,
        });
    }
    async downloadRideReconsilationAsPdf() {
        try {
            this.setState({
                loadPanelVisible: true,
                isSubmited: true,
            });

            let criteria1 = "", criteria2 = "", selectionCreteria = "";
            if (this.state.approvedType.id == 0) {
                criteria2 = criteria2 + " Approved-Yes, Not Approved-Yes,";
            }
            else if (this.state.approvedType.id == 1) {
                if (selectionCreteria != "") {
                    selectionCreteria += " And ";
                }
                else {
                    selectionCreteria += "";
                }
                selectionCreteria += "({Proc_Select_Ride_Reconsilation;1.ride_approved} = True) ";

                //selectionCreteria = selectionCreteria + (selectionCreteria != "" ? " And " : "").ToString() + "({Proc_Select_Ride_Reconsilation;1.ride_approved} = True) ";
                criteria2 = criteria2 + " Approved-Yes, Not Approved-No,";
            }
            else if (this.state.approvedType.id == 2) {
                if (selectionCreteria != "") {
                    selectionCreteria += " And ";
                }
                else {
                    selectionCreteria += "";
                }
                selectionCreteria += "({Proc_Select_Ride_Reconsilation;1.ride_approved} = False) ";

                //selectionCreteria = selectionCreteria + (selectionCreteria != "" ? " And " : "").ToString() + "({Proc_Select_Ride_Reconsilation;1.ride_approved} = False) ";
                criteria2 = criteria2 + " Approved-No, Not Approved-Yes,";
            }

            if (this.state.invoicedType.id == 0) {
                criteria2 = criteria2 + " Invoiced-Yes, Not Invoiced-Yes,";
            }
            else if (this.state.invoicedType.id == 1) {
                if (selectionCreteria != "") {
                    selectionCreteria += " And ";
                }
                else {
                    selectionCreteria += "";
                }
                selectionCreteria += "({Proc_Select_Ride_Reconsilation;1.invoice_no} > 0) ";

                //selectionCreteria = selectionCreteria + (selectionCreteria != "" ? " And " : "").ToString() + "({Proc_Select_Ride_Reconsilation;1.invoice_no} > 0) ";
                criteria2 = criteria2 + " Invoiced-Yes, Not Invoiced-No,";
            }
            else if (this.state.invoicedType.id == 2) {
                if (selectionCreteria != "") {
                    selectionCreteria += " And ";
                }
                else {
                    selectionCreteria += "";
                }
                selectionCreteria += "({Proc_Select_Ride_Reconsilation;1.invoice_no} <= 0) ";

                //selectionCreteria = selectionCreteria + (selectionCreteria != "" > 0 ? " And " : "").ToString() + "({Proc_Select_Ride_Reconsilation;1.invoice_no} <= 0) ";
                criteria2 = criteria2 + " Invoiced-No, Not Invoiced-Yes,";
            }

            if (this.state.driverPaidType.id == 0) {
                criteria2 = criteria2 + " Driver Paid-Yes, Driver Not Paid-Yes";
            }
            else if (this.state.driverPaidType.id == 1) {
                if (selectionCreteria != "") {
                    selectionCreteria += " And ";
                }
                else {
                    selectionCreteria += "";
                }
                selectionCreteria += "({Proc_Select_Ride_Reconsilation;1.ispaid}= True) ";

                //selectionCreteria = selectionCreteria + (selectionCreteria != "" ? " And " : "").ToString() + "({Proc_Select_Ride_Reconsilation;1.ispaid}= True) ";
                criteria2 = criteria2 + " Driver Paid-Yes, Driver Not Paid-No";
            }
            else if (this.state.driverPaidType.id == 2) {
                if (selectionCreteria != "") {
                    selectionCreteria += " And ";
                }
                else {
                    selectionCreteria += "";
                }
                selectionCreteria += "({Proc_Select_Ride_Reconsilation;1.ispaid}= False) ";

                //selectionCreteria = selectionCreteria + (selectionCreteria != "" ? " And " : "").ToString() + "({Proc_Select_Ride_Reconsilation;1.ispaid} =False) ";
                criteria2 = criteria2 + " Driver Paid-No, Driver Not Paid-Yes";
            }

            if (parseInt(this.state.ridePeriod) == 0) {
                criteria1 = criteria1 + "Ride Date: " + formatDate(this.state.singleDate, "MM/dd/yyyy");
            }
            else if (parseInt(this.state.ridePeriod) == 1) {
                criteria1 = criteria1 + "Ride Date Range: " + formatDate(this.state.startDate, "MM/dd/yyyy") + " To " + formatDate(this.state.endDate, "MM/dd/yyyy");
            }

            var request = new Object();
            request.fileFormat = 'pdf';
            request.MethodName = "GetRideReconsilationReport";
            if (parseInt(this.state.ridePeriod) == 0) {
                request.StartDate = formatDate(this.state.singleDate, "MM/dd/yyyy");
                request.EndDate = formatDate(this.state.singleDate, "MM/dd/yyyy");
            }
            else {
                request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy");
                request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy");
            }

            request.SelectionCrieteria = selectionCreteria;
            request.Criteria1 = criteria1;
            request.Criteria2 = criteria2;
            request.OrderByType = this.state.orderByType.id;

            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            this.setState({
                loadPanelVisible: false,
                isSubmited: false,
            });

            let a = document.createElement('a');
            a.href = dataResponse.reportBase64String;
            a.download = 'RideReconsilation.pdf';
            a.click();
        } catch (e) {
            this.setState({
                loadPanelVisible: false,
            });
            console.log(e)
        }
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            this.setState({ isEmailSubmitted: true });
            let criteria1 = "", criteria2 = "", selectionCreteria = "";
            if (this.state.approvedType.id == 0) {
                criteria2 = criteria2 + " Approved-Yes, Not Approved-Yes,";
            }
            else if (this.state.approvedType.id == 1) {
                if (selectionCreteria != "") {
                    selectionCreteria += " And ";
                }
                else {
                    selectionCreteria += "";
                }
                selectionCreteria += "({Proc_Select_Ride_Reconsilation;1.ride_approved} = True) ";

                //selectionCreteria = selectionCreteria + (selectionCreteria != "" ? " And " : "").ToString() + "({Proc_Select_Ride_Reconsilation;1.ride_approved} = True) ";
                criteria2 = criteria2 + " Approved-Yes, Not Approved-No,";
            }
            else if (this.state.approvedType.id == 2) {
                if (selectionCreteria != "") {
                    selectionCreteria += " And ";
                }
                else {
                    selectionCreteria += "";
                }
                selectionCreteria += "({Proc_Select_Ride_Reconsilation;1.ride_approved} = False) ";

                //selectionCreteria = selectionCreteria + (selectionCreteria != "" ? " And " : "").ToString() + "({Proc_Select_Ride_Reconsilation;1.ride_approved} = False) ";
                criteria2 = criteria2 + " Approved-No, Not Approved-Yes,";
            }

            if (this.state.invoicedType.id == 0) {
                criteria2 = criteria2 + " Invoiced-Yes, Not Invoiced-Yes,";
            }
            else if (this.state.invoicedType.id == 1) {
                if (selectionCreteria != "") {
                    selectionCreteria += " And ";
                }
                else {
                    selectionCreteria += "";
                }
                selectionCreteria += "({Proc_Select_Ride_Reconsilation;1.invoice_no} > 0) ";

                //selectionCreteria = selectionCreteria + (selectionCreteria != "" ? " And " : "").ToString() + "({Proc_Select_Ride_Reconsilation;1.invoice_no} > 0) ";
                criteria2 = criteria2 + " Invoiced-Yes, Not Invoiced-No,";
            }
            else if (this.state.invoicedType.id == 2) {
                if (selectionCreteria != "") {
                    selectionCreteria += " And ";
                }
                else {
                    selectionCreteria += "";
                }
                selectionCreteria += "({Proc_Select_Ride_Reconsilation;1.invoice_no} <= 0) ";

                //selectionCreteria = selectionCreteria + (selectionCreteria != "" > 0 ? " And " : "").ToString() + "({Proc_Select_Ride_Reconsilation;1.invoice_no} <= 0) ";
                criteria2 = criteria2 + " Invoiced-No, Not Invoiced-Yes,";
            }

            if (this.state.driverPaidType.id == 0) {
                criteria2 = criteria2 + " Driver Paid-Yes, Driver Not Paid-Yes";
            }
            else if (this.state.driverPaidType.id == 1) {
                if (selectionCreteria != "") {
                    selectionCreteria += " And ";
                }
                else {
                    selectionCreteria += "";
                }
                selectionCreteria += "({Proc_Select_Ride_Reconsilation;1.ispaid}= True) ";

                //selectionCreteria = selectionCreteria + (selectionCreteria != "" ? " And " : "").ToString() + "({Proc_Select_Ride_Reconsilation;1.ispaid}= True) ";
                criteria2 = criteria2 + " Driver Paid-Yes, Driver Not Paid-No";
            }
            else if (this.state.driverPaidType.id == 2) {
                if (selectionCreteria != "") {
                    selectionCreteria += " And ";
                }
                else {
                    selectionCreteria += "";
                }
                selectionCreteria += "({Proc_Select_Ride_Reconsilation;1.ispaid}= False) ";

                //selectionCreteria = selectionCreteria + (selectionCreteria != "" ? " And " : "").ToString() + "({Proc_Select_Ride_Reconsilation;1.ispaid} =False) ";
                criteria2 = criteria2 + " Driver Paid-No, Driver Not Paid-Yes";
            }

            if (parseInt(this.state.ridePeriod) == 0) {
                criteria1 = criteria1 + "Ride Date: " + formatDate(this.state.singleDate, "MM/dd/yyyy");
            }
            else if (parseInt(this.state.ridePeriod) == 1) {
                criteria1 = criteria1 + "Ride Date Range: " + formatDate(this.state.startDate, "MM/dd/yyyy") + " To " + formatDate(this.state.endDate, "MM/dd/yyyy");
            }

            var request = new Object();
            request.fileFormat = 'pdf';
            request.MethodName = "GetRideReconsilationReport";
            if (parseInt(this.state.ridePeriod) == 0) {
                request.StartDate = formatDate(this.state.singleDate, "MM/dd/yyyy");
                request.EndDate = formatDate(this.state.singleDate, "MM/dd/yyyy");
            }
            else {
                request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy");
                request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy");
            }

            request.SelectionCrieteria = selectionCreteria;
            request.Criteria1 = criteria1;
            request.Criteria2 = criteria2;
            request.OrderByType = this.state.orderByType.id;

            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            this.setState({ isEmailSubmitted: false });
            let fileData = dataResponse.reportBase64String;
            if (fileData !== null && fileData !== '') {
                let emailSubject = 'Ride Reconciliation Report';
                let attachments = [];
                let file = new Object();
                file.fileData = fileData;
                file.fileName = 'RideReconsilation.pdf'
                file.fileExtension = '.pdf';
                attachments.push(file);
                this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
            }
            else {
                Swal.fire({
                    allowOutsideClick: false,
                    showCloseButton: true,
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong! Unable to process the email request.',
                })
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (

            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '60%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleRideReconsilationReportSubmit}
                        style={(this.state.isSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Ride Reconciliation Report'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radReportType" value="0" checked={this.state.ridePeriod == "0"} onChange={this.handleRidePeriodChange} />
                                            <label className="form-check-label">Single Date</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radReportType" value="1" checked={this.state.ridePeriod == "1"} onChange={this.handleRidePeriodChange} />
                                            <label className="form-check-label">Date Range</label>
                                        </span>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.singleDate}
                                            value={this.state.singleDate}
                                            onValueChanged={this.handleRideDateChanged}
                                            disabled={!this.state.isRideDateRangeDisable}
                                            width="100%" />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label className="form-check-label">From Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.startDate}
                                            value={this.state.startDate}
                                            onValueChanged={this.handleFromDateChanged}
                                            disabled={this.state.isRideDateRangeDisable}
                                            acceptCustomValue={false}
                                            width="100%" />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label className="form-check-label">To Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.endDate}
                                            value={this.state.endDate}
                                            onValueChanged={this.handleToDateChanged}
                                            disabled={this.state.isRideDateRangeDisable}
                                            acceptCustomValue={false}
                                            width="100%"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label className="form-check-label">Invoiced / Non Invoiced</label>
                                        <RadioGroup id="radio-group-invoiced" dataSource={this.state.invoicedList} displayExpr="name" value={this.state.invoicedType} onValueChanged={this.handleInvoicedTypeChange} layout="horizontal" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label className="form-check-label">Driver Paid / Driver Not Paid</label>
                                        <RadioGroup id="radio-group-driverpaid" dataSource={this.state.driverPaidList} displayExpr="name" value={this.state.driverPaidType} onValueChanged={this.handleDriverPaidTypeChange} layout="horizontal" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label className="form-check-label">Approved / Non Approved</label>
                                        <RadioGroup id="radio-group-approved" dataSource={this.state.approvedList} displayExpr="name" value={this.state.approvedType} onValueChanged={this.handleApprovedTypeChange} layout="horizontal" />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label className="form-check-label">Order By</label>
                                        <RadioGroup id="radio-group-orderby" dataSource={this.state.orderByList} displayExpr="name" value={this.state.orderByType} onValueChanged={this.handleOrderByTypeChange} layout="horizontal" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="submit" className="btn btn-success btn-sm" title="Download" onClick={this.handleRideReconsilationReportSubmit} >
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                                </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        )
    }
}