import React, { Component } from 'react';
import { LoadPanel } from 'devextreme-react/load-panel';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import DateBox from 'devextreme-react/date-box';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import { getRequestParams } from '../../helpers/globalfunctions.js';
import Swal from 'sweetalert2';
import ProgressBar from "./ProgressBarComponent.js";
import Modal from 'react-modal'; //npm install --save react-modal
export class GenerateVehiclePayout extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isSubmitted: false,
            isRefreshSubmitted: false,
            loadPanelVisible: false,
            isProcessModalOpen: false,
            vehiclesDropdownList: [],
            statementDate: moment(new Date()).format("MM/DD/YYYY"),
            fromDate: '',
            toDate: '',
            weekDayName: '',
            processStatus: 'Click on generate to start...!',
            progressBarValue: 0,
            errors: {
                statementDate: '',
                fromDate: '',
                toDate: '',
            }
        }
    }
    componentDidMount() {
        this.fetchApiData();
    }
    getVehiclesDropdownList = async () => {
        try {
            var request = new Object();
            request.IsActiveOnly = false;
            request.IsPayrollActivatedOnly = false;
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('pre-payout-api/dropdown-vehicle', requestParams);
            if (response.ok) {
                const data = await response.json();
                if (data.isSuccess) {
                    this.setState({
                        vehiclesDropdownList: data.vehicleDropdownList
                    });
                }
            }
            else {
                await this.onfailure('error', null, null,)
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    async fetchApiData() {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getVehiclesDropdownList();
            const value = moment(new Date()).format("MM/DD/YYYY");
            await this.setDate(value);
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnRefresh = async () => {
        try {
            this.setState({ isRefreshSubmitted: true, loadPanelVisible: true });
            await new Promise(resolve => setTimeout(resolve, 3000)); // 3 sec
            let errors = this.state.errors;
            errors.statementDate = '';
            errors.fromDate = '';
            errors.toDate = '';
            this.setState({
                isSubmitted: false,
                statementDate: moment(new Date()).format("MM/DD/YYYY"),
                fromDate: '',
                toDate: '',
                weekDayName: '',
                processStatus: 'Click on generate to start...!',
                progressBarValue: 0,
                errors: errors
            });
            await this.fetchApiData();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isRefreshSubmitted: false, loadPanelVisible: false });
    }
    handleOnValueChangedDateBox = async (event) => {
        try {
            let errors = this.state.errors;
            errors.statementDate = '';
            errors.fromDate = '';
            errors.toDate = '';
            let value = '';
            if (event.value === '' || event.value === null) {
                errors.statementDate = 'Please select statement date.';
            }
            else {
                if (moment(new Date(event.value)).isValid() === false) {
                    errors.statementDate = 'Please select valid statement date.';
                }
                else {
                    value = moment(event.value).format("MM/DD/YYYY");
                }
            }
            await this.setDate(value);
            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    setDate = async (value) => {
        try {
            let fromDate = null;
            let toDate = null;
            let weekDayName = moment(value, "MM/DD/YYYY").format("dddd");
            if (moment(value, "MM/DD/YYYY").format("D") > 15) {
                fromDate = moment(moment(value, "MM/DD/YYYY").format("YYYY-MM-") + "16").format("MM/DD/YYYY");
                toDate = moment(moment(value, "MM/DD/YYYY").format("YYYY-MM-") + moment(value, "MM/DD/YYYY").daysInMonth()).format("MM/DD/YYYY");
            }
            if (moment(value, "MM/DD/YYYY").format("D") <= 15) {
                fromDate = moment(moment(value, "MM/DD/YYYY").format("YYYY-MM-") + "01").format("MM/DD/YYYY");
                toDate = moment(moment(value, "MM/DD/YYYY").format("YYYY-MM-") + "15").format("MM/DD/YYYY");
            }
            this.setState({
                statementDate: value,
                fromDate: fromDate,
                toDate: toDate,
                weekDayName: weekDayName
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnGenerateSubmit = async event => {
        event.preventDefault();
        try {
            this.setState({ isSubmitted: true, progressBarValue: 0, processStatus: '' });
            let isValid = await this.isFormValid();
            if (isValid) {
                await Swal.fire({
                    title: '<small><b>Confirmation</b></small>',
                    html: "<span style='font- size: 20px;'>Continue generating payments for period starting from <b>" + this.state.fromDate + "</b> to <b>" + this.state.toDate + "</b></span>?",
                    icon: 'question',
                    allowOutsideClick: false,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'No',
                    confirmButtonText: 'Yes'
                }).then(async (result) => {
                    if (!result.isConfirmed) {
                        this.setState({ isSubmitted: false, processStatus: 'Click on generate to start...!' });
                    }
                    else {
                        this.setState({ isProcessModalOpen: true });
                        let totalVehicleCount = 0;
                        let payoutGeneratedVehicleCount = 0;
                        var request = new Object();
                        request.StatementDate = moment(this.state.statementDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00';
                        request.StartDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00';
                        request.EndDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59';
                        const requestParams = getRequestParams('POST', request);
                        if (this.state.vehiclesDropdownList !== null) {
                            totalVehicleCount = this.state.vehiclesDropdownList.length;
                        }
                        let isSuccess = false;
                        let successCount = 0;
                        let failedCount = 0;
                        let status = '';
                        for (let i = 0; i < this.state.vehiclesDropdownList.length; i++) {
                            //payoutGeneratedVehicleCount = Number(payoutGeneratedVehicleCount) + 1;

                            this.setState({
                                processStatus: 'Generating vehicle: ' + this.state.vehiclesDropdownList[i].vehicleNumber + ' .. completed ' + payoutGeneratedVehicleCount + ' / ' + totalVehicleCount
                            });

                            let vehicleId = Number(this.state.vehiclesDropdownList[i].vehicleId);
                            const response = await fetch('pre-payout-api/' + Number(vehicleId) + '/generate', requestParams);
                            if (response.ok) {
                                const data = await response.json();
                                isSuccess = data.isSuccess;
                            }
                            payoutGeneratedVehicleCount = Number(payoutGeneratedVehicleCount) + 1;

                            if (isSuccess) {
                                successCount = Number(successCount) + 1;
                                status = 'Vehicle: ' + this.state.vehiclesDropdownList[i].vehicleNumber + ' .. generated ' + payoutGeneratedVehicleCount + ' / ' + totalVehicleCount;
                            }
                            else {
                                failedCount = Number(failedCount) + 1;
                                status = 'Faild! Vehicle: ' + this.state.vehiclesDropdownList[i].vehicleNumber + ' ..' + payoutGeneratedVehicleCount + ' / ' + totalVehicleCount;
                            }
                            let progressBarValue = (Number(payoutGeneratedVehicleCount) / Number(totalVehicleCount)) * 100;
                            this.setState({ processStatus: status, progressBarValue: parseInt(progressBarValue) });
                        };
                        await new Promise(resolve => setTimeout(resolve, 1000)); // 1 sec
                        this.setState({ isProcessModalOpen: false });
                        await Swal.fire({
                            allowOutsideClick: false,
                            icon: Number(payoutGeneratedVehicleCount) > 0 ? 'success' : 'error',
                            title: Number(payoutGeneratedVehicleCount) > 0 ? 'Success' : 'Oops...',
                            html: Number(payoutGeneratedVehicleCount) === 0 ?
                                'Vehicle payout not generated for period from [ <b>' + this.state.fromDate + '</b> ] to [<b> ' + this.state.toDate + '</b> ] '
                                : 'Successfully generated payout for vehicles/drivers starting period from <b> ' + this.state.fromDate + ' </b> to <b> ' + this.state.toDate + ' </b>.'
                                + '<br/>Success vehicles/drivers count is [<b>' + successCount + '</b>] out of [<b>' + totalVehicleCount + '</b>].'
                                + (Number(failedCount) === 0 ? '' : '<br/>Failed vehicles/drivers count is [<b>' + failedCount + '</b>] out of [<b>' + totalVehicleCount + '</b>]'),
                        }).then(async (result) => {
                            if (result.isConfirmed) {
                                //await this.onfailure('success', 'Success', 'This process was a test and did not affect database.');
                                //await this.fetchApiData();
                                this.setState({ isSubmitted: false });
                            }
                        });
                    }
                })
            }
            else {
                this.setState({ processStatus: 'Click on generate to start...!' });
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false });
    }
    isFormValid = async () => {
        let isValid = true;
        try {
            let errors = this.state.errors;
            errors.statementDate = '';
            errors.fromDate = '';
            errors.toDate = '';
            if (this.state.statementDate === '' || this.state.statementDate === null) {
                isValid = false;
                errors.statementDate = 'Please select statement date.';
            }
            else {
                if (moment(new Date(this.state.statementDate)).isValid() === false) {
                    isValid = false;
                    errors.statementDate = 'Please select statement date.';
                }
            }
            if (moment(new Date(this.state.fromDate)).isValid() === false) {
                isValid = false;
                errors.fromDate = 'Date is not valid.';
            }
            if (moment(new Date(this.state.toDate)).isValid() === false) {
                isValid = false;
                errors.toDate = 'Date is not valid.';
            }
            let daysDiff = moment(this.state.toDate).diff(this.state.fromDate, 'days');
            if (Number(daysDiff) <= 0) {
                isValid = false;
                errors.statementDate = 'Please enter valid ride date range.';
            }
            if (this.state.vehiclesDropdownList === null || this.state.vehiclesDropdownList.length === 0) {
                isValid = false;
                errors.statementDate = 'Unable to generate payout, Vehicle details not found .';
            }
            if (isValid === true) {
                var request = new Object();
                request.FromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00';
                request.ToDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59';
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('pre-payout-api/validate-pre-generate', requestParams);
                if (response.ok) {
                    const data = await response.json();
                    let icon = '';
                    let title = '';
                    if (!data.isSuccess) {
                        if (data.retValue === -1) {
                            isValid = false;
                            icon = 'warning';
                            title = 'Unable to process';
                            await this.onfailure(icon, title, 'Previous payout is not closed, Please close it and then try to generate.');
                        }
                        else if (data.retValue === -2) {
                            isValid = false;
                            await Swal.fire({
                                title: '<small><b>Confirmation</b></small>',
                                html: "<span style='font-size: 18px;color: green;'>Payout is <b>already</b> generated for this period ending <b>[ " + this.state.toDate + " ].</b></span><br>Are you sure still want to generate payout again?",
                                icon: 'question',
                                allowOutsideClick: false,
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                cancelButtonText: 'No',
                                confirmButtonText: 'Yes'
                            }).then(async (result) => {
                                if (result.isConfirmed) {
                                    isValid = true;
                                }
                            })
                        }
                        else if (data.retValue === -3) {
                            isValid = false;
                            icon = 'warning';
                            title = 'Unable to process';
                            await this.onfailure(icon, title, 'Payout is closed for this period ending <b>[ ' + this.state.toDate + ' ]</b>, Once closed not allowed again to generate payout..');
                        }
                        else {
                            isValid = false;
                            icon = 'error';
                            title = 'Oops...';
                            await this.onfailure(icon, title, data.resultMessage);
                        }
                    }
                }
                else {
                    isValid = false;
                }
            }
            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    onfailure = async (icon, title, resultMessage) => {
        this.setState({ loadPanelVisible: false });
        await Swal.fire({
            allowOutsideClick: false,
            showCloseButton: false,
            icon: icon,
            title: (title === null || title.trim() === '') ? 'Oops...' : title,
            html: (resultMessage === null || resultMessage.trim() === '') ? 'Opps! something went wrong, unable to process the request.' : resultMessage.trim(),
        })
    }
    render() {
        return (
            <div>
                <form method="post" onSubmit={this.handleOnGenerateSubmit}
                    style={(this.state.isSubmitted || this.state.isRefreshSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="content-wrapper">
                        <div className="content-header">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                        <h1 className="m-0 text-dark">Generate Vehicle Payout</h1>
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                                        <ol className="breadcrumb float-sm-right">
                                            <NavItem>
                                                <NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="#" onClick={this.handleOnRefresh}>
                                                    {this.state.isRefreshSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                                    {!this.state.isRefreshSubmitted && <span title="Refresh"><i className="fas fa-sync-alt"></i> Refresh</span>}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to="/post/payout" className="btn btn-block btn-outline-secondary btn-sm" title="Post Ride(s)">
                                                    <i className="fas fa-check"></i> Post Ride(s)</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to="/reimbursement/view" className="btn btn-block btn-outline-secondary btn-sm" title="Reimbursement View">
                                                    <i className="fas fa-car"></i> Reimb. View</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to="/peding/postrides" className="btn btn-block btn-outline-secondary btn-sm" title="Pending Post Ride(s)">
                                                    <i className="fas fa-clock"></i> Pending Post Ride(s)</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to="/posted/rides" className="btn btn-block btn-outline-secondary btn-sm" title="Posted Ride(s)">
                                                    <i className="fas fa-check-double"></i> Posted Ride(s)</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to="/reimbursement/history" className="btn btn-block btn-outline-secondary btn-sm" title="Reimbursement History">
                                                    <i className="fas fa-history"></i> Reimb. History</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to="/reimbursement/payperiod" className="btn btn-block btn-outline-secondary btn-sm" title="Reimbursement Pay Period">
                                                    <i className="fas fa-calendar-alt"></i> Reimb. Pay Period</NavLink>
                                            </NavItem>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="content">
                            <div className="card mt-3" style={{ width: "30%", margin: "0 auto" }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label>Statement Date</label>
                                                <DateBox type="date"
                                                    className="form-control form-control-sm" name="statementDate"
                                                    placeholder="mm/dd/yyyy"
                                                    defaultValue={this.state.statementDate}
                                                    value={this.state.statementDate}
                                                    onValueChanged={this.handleOnValueChangedDateBox}
                                                    width="100%"
                                                    acceptCustomValue={false}
                                                />
                                                {this.state.errors["statementDate"].length > 0 &&
                                                    <span className="error invalid-feedback">
                                                        {this.state.errors["statementDate"]}</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label>From Date </label>
                                                <DateBox type="date"
                                                    className="form-control form-control-sm" name="fromDate"
                                                    placeholder="mm/dd/yyyy"
                                                    defaultValue={new Date()}
                                                    value={this.state.fromDate}
                                                    min={this.state.fromDate}
                                                    disabled={true}
                                                    width="100%"
                                                    acceptCustomValue={false}
                                                //readOnly={true}
                                                />
                                                {this.state.errors["fromDate"].length > 0 &&
                                                    <span className="error invalid-feedback">{this.state.errors["fromDate"]}</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label>To Date </label>
                                                <DateBox type="date"
                                                    className="form-control form-control-sm" name="toDate"
                                                    placeholder="mm/dd/yyyy"
                                                    defaultValue={new Date()}
                                                    value={this.state.toDate}
                                                    min={this.state.toDate}
                                                    disabled={true}
                                                    width="100%"
                                                    acceptCustomValue={false}
                                                //readOnly={true}
                                                />
                                                {this.state.errors["toDate"].length > 0 &&
                                                    <span className="error invalid-feedback">{this.state.errors["toDate"]}</span>}
                                            </div>
                                        </div>
                                        {/*<div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                                        <div className="form-group">
                                                                            <span style={{ position: 'absolute', top: '12px', color: 'green' }}>{this.state.weekDayName} </span>
                                                                        </div>
                                                                    </div>
                                                                    */}
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
                                            <div className="form-group">
                                                <button type="submit" className="btn btn-success btn-sm btn-block">
                                                    {this.state.isSubmitted && <span title="In Process">
                                                        <i className="fas fa-spinner fa-spin"></i> In Process</span>}
                                                    {!this.state.isSubmitted && <span title="Generate">
                                                        <i className="fas fa-check"></i> Generate</span>}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ top: '25px' }}>
                                                        <div className="card sub-rez-card">
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                                        <div className="form-group">
                                                                            <span style={{ color: 'darkblue' }}>{this.state.processStatus}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ display: Number(this.state.progressBarValue) > 0 || 'none' }}
                                                                        className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                        <div className="form-group">
                                                                            <ProgressBar key={this.state.progressBarValue}
                                                                                bgcolor={'#28a745'} completed={this.state.progressBarValue} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isProcessModalOpen}
                    style={{ content: { width: '35%' } }}
                    className={"react-modal"}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ top: '25px', textAlign: 'center' }}>
                                <span style={{ fontWeight: 'bold', fontSize: '20px', fontFamily: 'unset' }}>{'Processing...'}</span>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ top: '25px' }}>
                                <div className="card sub-rez-card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                <div className="form-group">
                                                    <span style={{ color: 'darkblue' }}>{this.state.processStatus}</span>
                                                </div>
                                            </div>
                                            <div style={{ display: Number(this.state.progressBarValue) > 0 || 'none' }}
                                                className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <ProgressBar key={this.state.progressBarValue}
                                                        bgcolor={'#28a745'} completed={this.state.progressBarValue} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        )
    }
}