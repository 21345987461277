import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import { TripStatus, LogType } from '../../helpers/fixcodes';
import ImgsViewer from 'react-images-viewer' //npm install react-images-viewer --save
import { LoadPanel } from 'devextreme-react/load-panel';
import { post, axios } from 'axios'; //npm install--save axios
const customStyles = {
    content: {
        width: '50%'
    }
};
const position = { of: '#historydiv' };

export class DocumentsComponent extends Component {
    static displayName = DocumentsComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
            isSubmited: false,
            uniqueId: [],
            documentType: 0,
            displayNumber: '',
            pageName: '',
            isOpen: false,
            currImg: 0,
            documentSelectedFile: [],
            fileGuid: '00000000-0000-0000-0000-000000000000',
            imageList: [],
            pdfList: [],
            excelList: [],
            otherList: [],
            browseFileTitle: '',
            refreshRideSource: props.refreshDataSource,
            handleRefresh: props.handleRefresh,
            closeInvoicePayment: props.closeInvoicePayment,
            allowSocketConnection: sessionStorage.getItem("session_allowSocketConnection") === "true" ? true : false,
            errors: {
                reason: '',
            },
            driverDocumentDtails: {
                driverName: '',
                documentTypeName: '',
                documentName: '',
                documentRefNo: ''
            },
            vehicleDocumentDetails: {
                vehicleNo: '',
                documentTypeName: '',
                documentName: '',
                documentRefNo: ''
            }
        }

        this.closeDocumentModal = this.closeDocumentModal.bind(this);
        this.afterOpenDocumentModal = this.afterOpenDocumentModal.bind(this);
        this.gotoNext = this.gotoNext.bind(this)
        this.gotoPrev = this.gotoPrev.bind(this)
        this.closeImgsViewer = this.closeImgsViewer.bind(this)
        this.openImgsViewer = this.openImgsViewer.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this);
        this.documentUpload = this.documentUpload.bind(this);
        this.getDocuments = this.getDocuments.bind(this);
    }

    async handleInputChange(event) {
         if (this.state.documentType == 1) {
            if (event.target.files[0].type != 'image/jpeg' && event.target.files[0].type != 'image/png') {
                const isValid = await Swal.fire({
                    title: "<small>Invalid file!</small>",
                    text: "Only .jpeg, .jpg, .png files are allowed to upload.",
                    icon: "success",
                    width: '25%',
                    dangerMode: false,
                    allowOutsideClick: false
                });
                return;
            }
        }

        this.setState({
            loadPanelVisible: true
        });
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (event.target.files.length > 0) {
            this.setState({ documentSelectedFile: event.target.files[0] })
            let myVar = setTimeout(() => this.documentUpload(), 200)
        }
        this.setState({
            loadPanelVisible: false
        });
    }

    async documentUpload() {
        if (this.state.documentSelectedFile.size != undefined) {
            if (this.state.documentSelectedFile.size > 0) {
                const url = 'file-api/file-upload/';
                const formData = new FormData();
                formData.append('body', this.state.documentSelectedFile);

                const config = {
                    headers: {
                        'content-type': 'multipart/form-data',
                        'UserId': sessionStorage.getItem('session_userId'),
                        'Authorization': 'Bearer ' + sessionStorage.getItem('session_token'),
                        'CultureCode': sessionStorage.getItem('session_culturecode'),
                    },
                };
                this.setState({
                    loadPanelVisible: true
                });
                const response = await post(url, formData, config);
                const data = await response.data;
                if (data !== null) {
                    this.setState({
                        fileGuid: data.fileGuid
                    });

                    try {
                        var isSuccess = false;
                        var resultMessage = '';
                        for (var i = 0; i < this.state.uniqueId.length; i++) {
                            var request = new Object();
                            request.FileGuid = data.fileGuid;
                            const requestVehicleParams = getRequestParams('POST', request);
                            const responseVehicle = await fetch('file-api/' + this.state.documentType + '/' + this.state.uniqueId[i] + '/save-documents', requestVehicleParams);
                            const dataVehicle = await responseVehicle.json();
                            isSuccess = dataVehicle.isSuccess;
                            resultMessage = dataVehicle.resultMessage;
                        }
                        this.setState({
                            loadPanelVisible: false
                        });
                        if (isSuccess) {
                            Swal.fire({
                                icon: isSuccess ? 'success' : 'error',
                                title: isSuccess ? 'success' : 'Oops...',
                                text: resultMessage,
                            }).then((result) => {
                                /* Read more about isConfirmed, isDenied below */
                                if (isSuccess) {
                                    this.getDocuments();
                                }
                            });
                        } else {
                            this.onfailure('Failed to upload image.', resultMessage);
                        }
                    } catch (e) {
                        //
                    }

                }

                this.setState({
                    loadPanelVisible: false
                });
            }
        }
    }

    closeDocumentModal() {
        this.setState({
            isOpenModal: false,
            isSubmited: false,
            uniqueId: [],
            documentType: 0,
            displayNumber: '',
            pageName: '',
            isOpen: false,
            currImg: 0,
            documentSelectedFile: [],
            fileGuid: '00000000-0000-0000-0000-000000000000',
            imageList: [],
            pdfList: [],
            excelList: [],
            otherList: [],
            browseFileTitle: '',
            errors: {
                reason: '',
            },
            driverDocumentDtails: {
                driverName: '',
                documentTypeName: '',
                documentName: '',
                documentRefNo: ''
            },
              vehicleDocumentDetails: {
                vehicleNo: '',
                documentTypeName: '',
                documentName: '',
                documentRefNo: ''
            }
        });
        if (!this.state.allowSocketConnection) {
            if (this.state.refreshRideSource != undefined) {
                this.state.handleRefresh(true);
                this.state.refreshRideSource();
            }
        }

        if (this.state.closeInvoicePayment != undefined) {
            this.state.closeInvoicePayment();
        }
    }

    showModal(pageName, uniqueId, displayNumber) {
        try {
            if (pageName === "Vehicle") {
                this.setState({
                    documentType: 1,
                    browseFileTitle: 'Add Vehicle Image'
                })
            } else if (pageName === "Ride Id") {
                this.setState({
                    documentType: 2,
                    browseFileTitle: 'Add Ride Document'
                })
            } else if (pageName === "Invoice No") {
                this.setState({
                    documentType: 3,
                    browseFileTitle: 'Add Invoice Document'
                })
            }
            else if (pageName === "Driver Document") {
                 let driverDocumentDtails = this.state.driverDocumentDtails;
                driverDocumentDtails.driverName = uniqueId[0].driverName;
                driverDocumentDtails.documentTypeName = uniqueId[0].documentTypeName;
                driverDocumentDtails.documentName = uniqueId[0].documentName;
                driverDocumentDtails.documentRefNo = uniqueId[0].documentRefNo;
                let id = Number(uniqueId[0].id);
                uniqueId = [];
                uniqueId.push(id);
                this.setState({
                    documentType: 4,
                    browseFileTitle: 'Add Driver Document',
                    driverDocumentDtails: driverDocumentDtails
                })
            } else if (pageName === "Invoice Receipt") {
                this.setState({
                    documentType: 5,
                    browseFileTitle: 'Add Invoice Receipt Document'
                })
            }
            else if (pageName === "Vehicle Document") {
                 let vehicleDocumentDetails = this.state.vehicleDocumentDetails;
                vehicleDocumentDetails.vehicleNo = uniqueId[0].vehicleNo;
                vehicleDocumentDetails.documentTypeName = uniqueId[0].documentTypeName;
                vehicleDocumentDetails.documentName = uniqueId[0].documentName;
                vehicleDocumentDetails.documentRefNo = uniqueId[0].documentRefNo;
                let id = Number(uniqueId[0].id);
                uniqueId = [];
                uniqueId.push(id);
                this.setState({
                    documentType: 6,
                    browseFileTitle: 'Add Vehicle Document',
                    vehicleDocumentDetails: vehicleDocumentDetails
                })
            }
            this.setState({
                pageName: pageName,
                uniqueId: uniqueId,
                displayNumber: displayNumber,
                isOpenModal: true,
            });
            let myVar = setTimeout(() => this.getDocuments(), 200)
        } catch (ex) {
            
            console.log(ex);
        }
    }
    async afterOpenDocumentModal() {

    }
    async getDocuments() {
        try {
            this.setState({
                loadPanelVisible: true
            });
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/file-api/' + this.state.documentType + '/' + this.state.uniqueId[0] + '/documents', requestParams);
            const data = await response.json();
            var imageListObj = [];
            var pdfListObj = [];
            var excelListObj = [];
            var otherListObj = [];
            if (data.documents != null && data.documents.length > 0) {
                for (var i = 0; i < data.documents.length; i++) {
                    if (data.documents[i].fileGuid != '00000000-0000-0000-0000-000000000000') {
                        const requestParamsImage = getRequestParams('GET', '');
                        const responseImage = await fetch('file-api/download/' + data.documents[i].fileGuid, requestParamsImage);
                        const dataImage = await responseImage.json();

                        if (dataImage.fileExtension.toUpperCase() == '.PDF') {
                            var img = new Object();
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            img.fileName = dataImage.fileName;
                            pdfListObj.push(img);
                        } else if (dataImage.fileExtension.toUpperCase() == '.XLXS' || dataImage.fileExtension.toUpperCase() == '.CSV' || dataImage.fileExtension.toUpperCase() == '.XLS') {
                            var img = new Object();
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            img.fileName = dataImage.fileName;
                            excelListObj.push(img);
                        } else if (dataImage.fileExtension.toUpperCase() == '.JPG' || dataImage.fileExtension.toUpperCase() == '.PNG' || dataImage.fileExtension.toUpperCase() == '.JPEG') {
                            var img = new Object();
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            imageListObj.push(img);
                        } else {
                            var img = new Object();
                            img.src = 'data:' + dataImage.mimeType + ';base64,' + dataImage.fileContent;
                            img.fileGuid = data.documents[i].fileGuid;
                            img.fileName = dataImage.fileName;
                            otherListObj.push(img);
                        }

                    }
                }
            }

            this.setState({
                loadPanelVisible: false,
                imageList: imageListObj,
                pdfList: pdfListObj,
                excelList: excelListObj,
                otherList: otherListObj
            });

        } catch (e) {
            console.log(e);
        }
    }
    openImgsViewer(event, index) {
        event.preventDefault()
        this.setState({
            currImg: index,
            isOpen: true,
        })
    }
    closeImgsViewer() {
        this.setState({
            currImg: 0,
            isOpen: false,
        })
    }
    gotoPrev() {
        this.setState({
            currImg: this.state.currImg - 1
        })
    }
    gotoNext() {
        this.setState({
            currImg: this.state.currImg + 1
        })
    }

    render() {

        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={customStyles}
                    className={"react-modal"}
                    onAfterOpen={this.afterOpenDocumentModal}
                    onRequestClose={this.closeDocumentModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            {/*<h6 className="modal-title">{this.state.pageName} - {this.state.displayNumber}</h6>*/}
                            <h6 className="modal-title">{this.state.pageName
                                + ((this.state.displayNumber === null || this.state.displayNumber === '') ? '' : ' - ' + this.state.displayNumber)}
                            </h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeDocumentModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row" style={{ display: Number(this.state.documentType) === 4 || 'none' }}>
                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                    <div className="form-group">
                                        <label>Driver </label>                                        
                                    </div>
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <span style={{ fontWeight: 'bold', fontSize: '13px', fontFamily: 'unset' }}>{this.state.driverDocumentDtails.driverName}</span>
                                    </div>
                                </div>
                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                    <div className="form-group">
                                        <label>Document Type </label>
                                    </div>
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <span style={{ fontWeight: 'bold', fontSize:'13px',fontFamily: 'unset'}}>{this.state.driverDocumentDtails.documentTypeName}</span>
                                    </div>
                                </div>
                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                    <div className="form-group">
                                        <label>Document Name </label>
                                    </div>
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <span style={{ fontWeight: 'bold', fontSize: '13px', fontFamily: 'unset' }}>{this.state.driverDocumentDtails.documentName}</span>
                                    </div>
                                </div>
                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                    <div className="form-group">
                                        <label>Document Ref No </label>
                                    </div>
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <span style={{ fontWeight: 'bold', fontSize: '13px', fontFamily: 'unset' }}>{this.state.driverDocumentDtails.documentRefNo}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ display: Number(this.state.documentType) === 6 || 'none' }}>
                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                    <div className="form-group">
                                        <label>Vehicle </label>
                                    </div>
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <span style={{ fontWeight: 'bold', fontSize: '13px', fontFamily: 'unset' }}>{this.state.vehicleDocumentDetails.vehicleNo}</span>
                                    </div>
                                </div>
                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                    <div className="form-group">
                                        <label>Document Type </label>
                                    </div>
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <span style={{ fontWeight: 'bold', fontSize: '13px', fontFamily: 'unset' }}>{this.state.vehicleDocumentDetails.documentTypeName}</span>
                                    </div>
                                </div>
                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                    <div className="form-group">
                                        <label>Document Name </label>
                                    </div>
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <span style={{ fontWeight: 'bold', fontSize: '13px', fontFamily: 'unset' }}>{this.state.vehicleDocumentDetails.documentName}</span>
                                    </div>
                                </div>
                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                    <div className="form-group">
                                        <label>Document Ref No </label>
                                    </div>
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <span style={{ fontWeight: 'bold', fontSize: '13px', fontFamily: 'unset' }}>{this.state.vehicleDocumentDetails.documentRefNo}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group">
                                        <label>{this.state.browseFileTitle}</label>
                                        <div className="input-group">
                                            <div className="custom-file">
                                                <input type="file" name="driverFile" className="custom-file-input" onChange={this.handleInputChange} />
                                                <label className="custom-file-label" >Choose file</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="box-body no-padding" style={{ maxHeight: 400 + 'px', overflowY: "scroll" }}>
                                        <div>
                                            <ul className="users-list clearfix">
                                                {this.state.imageList.length > 0 && this.state.imageList.map((item, j) =>
                                                    <li style={{ position: "relative" }} key={j}>
                                                        <a href="#" onClick={(e) => { this.openImgsViewer(e, j) }}><div className="framehover"><img src={item.src} width="600" alt="Blank Image" /></div></a>
                                                    </li>
                                                )}
                                                {this.state.pdfList.length > 0 && this.state.pdfList.map((item, j) =>
                                                    <li style={{ position: "relative" }} key={j}>
                                                        <a href={item.src} download={item.fileName}><div className="framehover"><img src="../../dist/img/pdf_icon.png" width="600" alt="Blank Image" /></div></a>
                                                    </li>
                                                )}
                                                {this.state.excelList.length > 0 && this.state.excelList.map((item, j) =>
                                                    <li style={{ position: "relative" }} key={j}>
                                                        <a href={item.src} download={item.fileName}><div className="framehover"><img src="../../dist/img/excel_icon.png" width="600" alt="Blank Image" /></div></a>
                                                    </li>
                                                )}
                                                {this.state.otherList.length > 0 && this.state.otherList.map((item, j) =>
                                                    <li style={{ position: "relative" }} key={j}>
                                                        <a href={item.src} download={item.fileName}><div className="framehover"><img src="../../dist/img/other_icon.png" width="600" alt="Blank Image" /></div></a>
                                                    </li>
                                                )}

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeDocumentModal} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                        <ImgsViewer
                            imgs={this.state.imageList}
                            currImg={this.state.currImg}
                            onClickPrev={this.gotoPrev}
                            onClickNext={this.gotoNext}
                            onClose={this.closeViewer}
                            isOpen={this.state.isOpen}
                            onClose={this.closeImgsViewer}
                        />
                    </form>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}