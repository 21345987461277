import { post } from 'axios'; //npm install--save axios
import DataGrid, {
    Column,
    FilterRow, Paging, Scrolling, Selection
} from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import DropDownBox from 'devextreme-react/drop-down-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import { SelectBox } from 'devextreme-react/select-box';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import ReactTooltip from "react-tooltip"; //npm install react - tooltip
import Swal from 'sweetalert2';
import { City } from '../../datastore/city.json';
import { Country } from '../../datastore/country.json';
import { StateData } from '../../datastore/state.json';
import { CustomerType, ModuleCode, ParentAccount } from '../../helpers/fixcodes.js';
import { formatDate, getRequestParams } from '../../helpers/globalfunctions.js';
const position = { of: '#historydiv' };

const google = window.google;

export class AccountSettingComponent extends Component {
    static displayName = AccountSettingComponent.name;

    constructor(props) {
        super(props);
        this.autocomplete = null;

        this.state = {
            transporterName: '',
            legalName: '',
            siteName: '',
            siteURL: '',
            siteCategoryType: '',
            airlineId: 0,
            firstName: '',
            lastName: '',
            mobileNumber: '',
            phoneNumber: '',
            emailAddress: '',
            EINNumber: '',
            streetAddress: '',
            city: '',
            stateName: '',
            country: '',
            zipcode: '',
            faxNumber: '',
            websiteURL: '',
            userPhone: '',
            userName: '',
            userEmailAddress: '',
            noofYearsInOperation: 0,
            passLastYear: 0,
            noOfPassengerToUsa: 0,
            noOfPassengerToRow: 0,
            noOfTerminal: 0,
            paymentMode: 0,
            constitutionTypeId: 0,
            isServiceApiProvided: false,
            customerTypeId: 0,
            countryCode: '',
            areaCode: '',
            skinId: 0,
            logoSize: 0,
            logo: "",
            logoName: "",
            logoExt: "",
            reportDisplayName: "",
            reportHeader: "",
            reportFooter: "",
            notes: "",
            specialInstruction: "",
            reportImage: "",
            isPriority: false,
            rating: 'N',
            planId: 0,
            accountId: 0,
            skinList: [],
            accountDetails: [],
            locationList: [],
            airportList: [],
            distanceUnitList: [],
            vehicleTypeList: [],
            timezoneList: [],
            timezoneId: 0,
            timezone: '',
            currencyList: [],
            currency: '',
            currencyId: 0,
            countryList: Country,
            stateList: StateData,
            cityList: City,
            stateId: 0,
            serviceLocations: [],
            serviceAirports: [],
            serviceVehicleType: [],
            doTripDuration: '',
            distanceUnit: '',
            nonAirportTransfer: '',
            airportTransferDomestic: '',
            airportTransferInternational: '',
            isProfileSubmited: false,
            isProfilePhotoSubmited: false,
            isGeneralInfoSubmit: false,
            isServiceInfoSubmit: false,
            accountProfileImage: "../../dist/img/avatar5.png",
            accountSelectedProfileImage: [],
            fileGuid: '00000000-0000-0000-0000-000000000000',
            loadPanelVisible: false,
            errors: {
                timezone: '',
                timezoneId: 0,
                currencyId: 0,
                currency: '',
                transporterName: '',
                firstName: '',
                lastName: '',
                mobileNumber: '',
                phoneNumber: '',
                emailAddress: '',
                EINNumber: '',
                streetAddress: '',
                city: '',
                stateName: '',
                country: '',
                zipcode: '',
                faxNumber: '',
                websiteURL: '',
                userPhone: '',
                userName: '',
                userEmailAddress: '',
                doTripDuration: '',
                distanceUnit: '',
                nonAirportTransfer: '',
                airportTransferDomestic: '',
                airportTransferInternational: '',
                serviceLocations: '',
                serviceAirports: '',
                serviceVehicleType: '',
            }
        }

        this.getAccountDetails = this.getAccountDetails.bind(this);
        this.getTimezone = this.getTimezone.bind(this);
        this.getCurrency = this.getCurrency.bind(this);
        this.getDistanceUnit = this.getDistanceUnit.bind(this);
        this.getAirports = this.getAirports.bind(this);
        this.getLocations = this.getLocations.bind(this);
        this.getVehicleType = this.getVehicleType.bind(this);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputChangeStep1 = this.handleInputChangeStep1.bind(this);
        this.handleTimezoneChange = this.handleTimezoneChange.bind(this);
        this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);
        this.handleDistanceunitChange = this.handleDistanceunitChange.bind(this);
        this.handleProfileSubmit = this.handleProfileSubmit.bind(this);
        this.handleGeneralInfoSubmit = this.handleGeneralInfoSubmit.bind(this);
        this.handleServiceInfoSubmit = this.handleServiceInfoSubmit.bind(this);
        this.handleProfileImageSubmit = this.handleProfileImageSubmit.bind(this);
        this.removeImageHandle = this.removeImageHandle.bind(this);
        this.handleSkinChange = this.handleSkinChange.bind(this);
        this.removeCompanyLogoHandle = this.removeCompanyLogoHandle.bind(this);

        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.syncDataGridSelection = this.syncDataGridSelection.bind(this);
        this.dataGridRender = this.dataGridRender.bind(this);

        this.onSelectionAirportChanged = this.onSelectionAirportChanged.bind(this);
        this.syncDataGridAirportSelection = this.syncDataGridAirportSelection.bind(this);
        this.dataGridAirportRender = this.dataGridAirportRender.bind(this);

        this.onSelectionVehicleTypeChanged = this.onSelectionVehicleTypeChanged.bind(this);
        this.syncDataGridVehicleTypeSelection = this.syncDataGridVehicleTypeSelection.bind(this);
        this.dataGridVehicleTypeRender = this.dataGridVehicleTypeRender.bind(this);
    }

   

    componentDidMount() {
        this.getAccountDetails();
    }

    async getAccountDetails() {
        try {
            this.setState({ loadPanelVisible: true })
            const requestParams = getRequestParams('GET', "{}");
            const response = await fetch('/account-api/' + parseInt(sessionStorage.getItem('session_accountId')) + '/list/0/0/0/0/', requestParams);
            const data = await response.json();

            if (data.isSuccess) {
                //debugger;
                //locIdList.push(data.accounts[0].locationId);
                debugger;
                sessionStorage.setItem("session_currencyCode", data.accounts[0].currencyCode);
                sessionStorage.setItem("session_currencyId", data.accounts[0].currencyId);
                sessionStorage.setItem("session_currencyIcon", data.accounts[0].currencyIcon);

                await this.getTimezone();
                await this.getDistanceUnit();
                await this.getCurrency();
                await this.getLocations();
                await this.getVehicleType();
                await this.getSkin();

                this.state.errors["country"] = "";
                if (data.accounts[0].countryId == 1) {
                    var cityoptions = { types: ['establishment'] };
                    this.autocomplete = new google.maps.places.Autocomplete(document.getElementById('streetAddress'), cityoptions)
                    this.autocomplete.addListener("place_changed", this.handlePlaceSelect)
                }
                else {
                    this.autocomplete = null;
                }

                this.setState({
                    accountDetails: data.accounts[0]
                    , accountId: data.accounts[0].accountId
                    , transporterName: data.accounts[0].accountName
                    , legalName: data.accounts[0].accountName
                    , siteName: data.accounts[0].siteName
                    , siteURL: data.accounts[0].siteURL
                    , siteCategoryType: data.accounts[0].siteCategoryType
                    , airlineId: data.accounts[0].airlineId
                    , firstName: data.accounts[0].firstName
                    , lastName: data.accounts[0].lastName
                    , mobileNumber: data.accounts[0].mobile
                    , phoneNumber: data.accounts[0].phone
                    , emailAddress: data.accounts[0].email
                    , EINNumber: data.accounts[0].registrationNumber != null ? data.accounts[0].registrationNumber : ""
                    , streetAddress: data.accounts[0].address1
                    , city: data.accounts[0].cityName
                    , stateName: data.accounts[0].stateName
                    , country: data.accounts[0].countryName
                    , zipcode: data.accounts[0].zipCode
                    , faxNumber: data.accounts[0].faxNo != null ? data.accounts[0].faxNo : ""
                    , websiteURL: data.accounts[0].webAddress
                    , userPhone: data.accounts[0].phone
                    , userName: data.accounts[0].userName
                    , userEmailAddress: data.accounts[0].email
                    , countryId: data.accounts[0].countryId
                    , stateId: data.accounts[0].stateId
                    , cityId: data.accounts[0].cityId
                    , timezoneId: data.accounts[0].timezoneId
                    , currencyId: data.accounts[0].currencyId
                    , currencyName: data.accounts[0].currencyName
                    , currencyCode: data.accounts[0].currencyCode
                    , currencyIcon: data.accounts[0].currencyIcon
                    , planId: data.accounts[0].planId
                    , locationId: data.accounts[0].locationId
                    , doTripDuration: data.accounts[0].locationSetting == null ? 0 : data.accounts[0].locationSetting.doTripDuration
                    , distanceUnit: data.accounts[0].locationSetting == null ? 1 : data.accounts[0].locationSetting.distanceUnitType
                    , nonAirportTransfer: data.accounts[0].locationSetting == null ? "" : data.accounts[0].locationSetting.nonAirportTransfer
                    , airportTransferDomestic: data.accounts[0].locationSetting == null ? "" : data.accounts[0].locationSetting.domesticAirportTransfer
                    , airportTransferInternational: data.accounts[0].locationSetting == null ? "" : data.accounts[0].locationSetting.internationalAirportTransfer
                    , noofYearsInOperation: data.accounts[0].noofYearsInOperation
                    , passLastYear: data.accounts[0].passLastYear
                    , noOfPassengerToUsa: data.accounts[0].noOfPassengerToUsa
                    , noOfPassengerToRow: data.accounts[0].noOfPassengerToRow
                    , noOfTerminal: data.accounts[0].noOfTerminal
                    , paymentMode: data.accounts[0].paymentMode
                    , constitutionTypeId: data.accounts[0].constitutionTypeId
                    , isServiceApiProvided: data.accounts[0].isServiceApiProvided
                    , customerTypeId: data.accounts[0].customerTypeId
                    , countryCode: data.accounts[0].countryCode
                    , areaCode: data.accounts[0].areaCode
                    , skinId: data.accounts[0].skinId
                    , logoSize: data.accounts[0].logoSize
                    , logo: data.accounts[0].logo
                    , logoName: data.accounts[0].logoName
                    , logoExt: data.accounts[0].logoExt
                    , reportDisplayName: data.accounts[0].reportDisplayName
                    , reportHeader: data.accounts[0].reportHeader
                    , reportFooter: data.accounts[0].reportFooter
                    , notes: data.accounts[0].notes
                    , specialInstruction: data.accounts[0].specialInstruction
                    , reportImage: data.accounts[0].reportImage
                    , isPriority: data.accounts[0].isPriority
                    , rating: data.accounts[0].rating                    
                   // , serviceLocations: data.accounts[0].locations
                    //, airportList: data.accounts[0].airports
                    //, vehicleTypeList: data.accounts[0].vehicleTypeIdList
                    //, serviceAirports: data.accounts[0].airports
                    //, airports: data.accounts[0].airports
                    //, serviceVehicleType: data.accounts[0].vehicleTypeIdList
                    , fileGuid: data.accounts[0].fileGuid
                });

                if (data.accounts[0].locations.length > 0) {
                    var sLocation = [];
                    for (var location of data.accounts[0].locations) {
                        sLocation.push(location.id);
                    }
                    this.setState({ serviceLocations: sLocation})
                }

                if (data.accounts[0].airports.length > 0) {
                    var sLocation = [];
                    for (var location of data.accounts[0].airports) {
                        sLocation.push(location.airportId);
                    }
                    this.setState({ serviceAirports: sLocation })
                }

                if (data.accounts[0].vehicleTypeIdList.length > 0) {
                    var sLocation = [];
                    for (var location of data.accounts[0].vehicleTypeIdList) {
                        sLocation.push(location.id);
                    }
                    this.setState({ serviceVehicleType: sLocation })
                }
                
                await this.getAirports();

                try {
                    if (data.accounts[0].fileGuid != '00000000-0000-0000-0000-000000000000') {
                        const requestParams = getRequestParams('GET', '');
                        const response = await fetch('file-api/download/' + data.accounts[0].fileGuid, requestParams);
                        const profiledata = await response.json();
                        this.setState({ accountProfileImage: 'data:' + profiledata.mimeType + ';base64,' + profiledata.fileContent });
                    }
                } catch (e) {
                    //debugger;
                }
            }
            this.setState({ loadPanelVisible: false })
        } catch (e) {
            this.setState({ loadPanelVisible: false })
        }
    }

    removeImageHandle() {
        this.setState({
            fileGuid: '00000000-0000-0000-0000-000000000000',
            accountProfileImage: "../../dist/img/avatar5.png",
            accountSelectedProfileImage: []
        });
    }

    async getTimezone() {
        debugger;
        const requestParams = getRequestParams('GET', "{}");
        const response = await fetch('/timezone-api/time-zones/0', requestParams);
        const data = await response.json();
        if (data.isSuccess) {
            this.setState({
                timezoneList: data.timeZones,
            });
        }
    }

    getDistanceUnit() {
        const response = [
            { name: 'Miles', id: 1 },
            { name: 'Kilometers', id: 2 }
        ];

        this.setState({
            distanceUnitList: response
        });
    }

    async getSkin() {
        const requestParams = getRequestParams('GET', "{}");
        const response = await fetch('/skintype-api', requestParams);
        const data = await response.json();
        if (data.isSuccess) {
            this.setState({
                skinList: data.skinType,
            });
        }
    }

    async getCurrency() {
        let currencyId = 0;
        const requestParams = getRequestParams('GET', "{}");
        const response = await fetch('/currency-api/list/0/0', requestParams);
        const data = await response.json();
        if (data.isSuccess) {
            this.setState({
                currencyList: data.currencyList,
            });
        }
    }

    async getLocations() {
        try {
            const requestParams = getRequestParams('GET', "{}");
            const response = await fetch('/location-api/locations-dropdownlist/0', requestParams);
            const data = await response.json();
            if (data.isSuccess) {
                //locations.push(data.locationsDropdownList);
                this.setState({
                    locationList: data.locations
                });
            }
        } catch (e) {
            //debugger;
        }
    }

    async getAirports() {
        try {
            debugger;
            var airportsDropdownListRequest = new Object();
            airportsDropdownListRequest.LocationIdList = this.state.serviceLocations;
            const requestParams = getRequestParams('POST', airportsDropdownListRequest);
            const response = await fetch('/airports-api/airports-dropdown-list/', requestParams);
            const data = await response.json();
            if (data.isSuccess) {
                this.setState({
                    airportList: data.airportsDropdownList,
                });
            }
            else {
                this.setState({ airportList: [] });
            }
        } catch (e) {
            //debugger;
        }
    }

    async getVehicleType() {
        try {
            const response = await fetch('/vehicletype-api/dropdown/');
            const data = await response.json();
            if (data.isSuccess) {
                this.setState({
                    vehicleTypeList: data.vehicleTypeDropdownList
                });
            }
        } catch (e) {
            //debugger;
        }
    }

    handleProfileValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        //return true;
        errors.transporterName = "";
        errors.firstName = "";
        errors.lastName = "";
        errors.phoneNumber = "";
        errors.mobileNumber = "";
        errors.emailAddress = "";
        errors.EINNumber = "";
        errors.streetAddress = "";
        errors.city = "";
        errors.stateName = "";
        errors.country = "";
        errors.zipcode = "";
        errors.faxNumber = "";
        errors.websiteURL = "";
        errors.userPhone = "";
        errors.userName = "";
        errors.userEmailAddress = "";
        errors.userPassword = "";
        errors.confirmPassword = "";
        errors.creditcardUserName = "";
        errors.creditcardNumber = "";
        errors.creditcardCVV = "";
        errors.cardExpiry = "";

        this.setState({ message: '' });

        if (this.state.transporterName.trim() == '') {
            formIsValid = false;
            errors.transporterName = "Please enter transporter name";
        }

        if (this.state.EINNumber.trim() == '') {
            formIsValid = false;
            errors.EINNumber = "Please enter EIN/registration number";
        }

        if (this.state.streetAddress.trim() == '') {
            formIsValid = false;
            errors.streetAddress = "Please enter street address";
        }

        if (this.state.city.trim() == '') {
            formIsValid = false;
            errors.city = "Please enter city";
        }

        if (this.state.stateName.trim() == '') {
            formIsValid = false;
            errors.stateName = "Please enter state";
        }

        if (this.state.country.trim() == '') {
            formIsValid = false;
            errors.country = "Please enter country";
        }

        if (this.state.zipcode.trim() == '') {
            formIsValid = false;
            errors.zipcode = "Please enter zip code";
        }

        if (this.state.firstName.trim() == '') {
            formIsValid = false;
            errors.firstName = "Please enter first name";
        }

        if (this.state.lastName.trim() == '') {
            formIsValid = false;
            errors.lastName = "Please enter last name";
        }

        if (this.state.phoneNumber.trim() == '') {
            formIsValid = false;
            errors.phoneNumber = "Please enter phone number";
        } else if (this.state.phoneNumber.trim().replace("(", "").replace(")", "").replace("-", "").replace("-", "").replace(" ", "").length != 10) {
            formIsValid = false;
            errors.phoneNumber = "Invalid phone number";
        }

        if (this.state.mobileNumber.trim() == '') {
            formIsValid = false;
            errors.mobileNumber = "Please enter mobile number";
        } else if (this.state.mobileNumber.trim().replace("(", "").replace(")", "").replace("-", "").replace("-", "").replace(" ", "").length != 10) {
            formIsValid = false;
            errors.mobileNumber = "Invalid mobile number";
        }

        if (this.state.emailAddress.trim() == '') {
            formIsValid = false;
            errors.emailAddress = "Please enter email address";
        }

        if (this.state.emailAddress.trim() != '') {
            let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!regEmail.test(this.state.emailAddress)) {
                formIsValid = false;
                errors.emailAddress = "Please enter valid email";
            }
        }

        if (this.state.userPhone.trim() == '') {
            formIsValid = false;
            errors.userPhone = "Please enter phone number";
        } else if (this.state.userPhone.trim().replace("(", "").replace(")", "").replace("-", "").replace("-", "").replace(" ", "").length != 10) {
            formIsValid = false;
            errors.userPhone = "Invalid user phone number";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    handleGeneralInfoValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        //return true;
        errors.doTripDuration = "";
        errors.timezone = "";
        errors.currency = "";
        errors.distanceUnit = "";
        errors.nonAirportTransfer = "";
        errors.airportTransferDomestic = "";
        errors.airportTransferInternational = "";

        this.setState({ message: '' });

        if (this.state.doTripDuration == '') {
            formIsValid = false;
            errors.doTripDuration = "Please enter DO trip duration";
        }
        if (parseInt(this.state.doTripDuration) < 10 || parseInt(this.state.doTripDuration) > 90) {
            formIsValid = false;
            errors.doTripDuration = "DO trip duration should be between 10 to 90";
        }

        if (this.state.timezoneId == '') {
            formIsValid = false;
            errors.timezone = "Please select time zone";
        }

        if (this.state.currencyId == '') {
            formIsValid = false;
            errors.currency = "Please select currency";
        }

        if (this.state.distanceUnit == '') {
            formIsValid = false;
            errors.distanceUnit = "Please select distance unit";
        }

        if (this.state.nonAirportTransfer == '') {
            formIsValid = false;
            errors.nonAirportTransfer = "Please enter non airport transfer minutes";
        }
        if (parseInt(this.state.nonAirportTransfer) < 0 || parseInt(this.state.nonAirportTransfer) > 120) {
            formIsValid = false;
            errors.nonAirportTransfer = "Non airport transfer should be between 0 to 120";
        }

        if (this.state.airportTransferDomestic == '') {
            formIsValid = false;
            errors.airportTransferDomestic = "Please enter airport transfer domestic minutes";
        }
        if (parseInt(this.state.airportTransferDomestic) < 0 || parseInt(this.state.airportTransferDomestic) > 60) {
            formIsValid = false;
            errors.airportTransferDomestic = "Non airport transfer domestic should be between 0 to 60";
        }

        if (this.state.airportTransferInternational == '') {
            formIsValid = false;
            errors.airportTransferInternational = "Please enter airport transfer international minutes";
        }
        if (parseInt(this.state.airportTransferInternational) < 0 || parseInt(this.state.airportTransferInternational) > 120) {
            formIsValid = false;
            errors.airportTransferInternational = "Non airport transfer international should be between 0 to 120";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    handleServiceInfoValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        //return true;
        errors.serviceLocations = "";
        errors.serviceAirports = "";
        errors.serviceVehicleType = "";

        this.setState({ message: '' });

        if (this.state.serviceLocations.length == 0) {
            formIsValid = false;
            errors.serviceLocations = "Please select service locations";
        }

        if (this.state.serviceAirports.length == 0) {
            formIsValid = false;
            errors.serviceAirports = "Please select service airport";
        }

        if (this.state.serviceVehicleType.length == 0) {
            formIsValid = false;
            errors.serviceVehicleType = "Please select vehicle type";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    handleTimezoneChange(selectedValue) {
        this.setState({ timezoneId: (selectedValue.selectedItem != null ? selectedValue.selectedItem.id : 0), })
        this.state.errors["timezone"] = "";
    }
    handleCurrencyChange(selectedValue) {
        this.setState({ currencyId: (selectedValue.selectedItem != null ? selectedValue.selectedItem.currencyId : 0), })
        this.state.errors["currency"] = "";
    }
    handleCountryChange(selectedValue) {
        try {
            this.setState({ country: '', streetAddress: '', zipcode: '' });
            if (selectedValue.selectedItem != null) {

                let stetelet = [];
                this.setState({ country: (selectedValue.selectedItem != null ? selectedValue.selectedItem.countryName : ''), });
                for (let a = 0; a < StateData.length; a++) {
                    if (StateData[a].countryId == selectedValue.selectedItem.countryId) {
                        stetelet.push(StateData[a]);
                    }
                }

                this.setState({ stateList: stetelet, stateName: '', countryId: selectedValue.selectedItem.countryId, stateId: 0, city: '', cityId: 0 });
                this.state.errors["country"] = "";
                if (selectedValue.selectedItem.countryId == 1) {
                    var cityoptions = { types: ['establishment'] };
                    this.autocomplete = new google.maps.places.Autocomplete(document.getElementById('streetAddress'), cityoptions)
                    this.autocomplete.addListener("place_changed", this.handlePlaceSelect)
                }
                else {
                    this.autocomplete = null;
                }
            }
            else {
                this.setState({ stateList: [], stateName: '', countryId: 0, stateId: 0, cityList: [], city: '', cityId: 0 });
            }
        } catch (e) {
            debugger;
        }
    }
  
    handlePlaceSelect() {
        if (this.autocomplete != null) {
            let place = this.autocomplete.getPlace();
            let address = place.address_components;
            let placedetails = place.formatted_address.trim();

            if (place.formatted_address.trim() != "") {
                var splitAddress = [];
                splitAddress = place.formatted_address.split(',');
                if (splitAddress.length > 0) {
                    if (splitAddress.length == 1) {
                        this.setState({
                            streetAddress: place.name,
                            //city: "",                            
                            //state: "",
                            //country: "",
                            zipcode: "",
                        });
                    }
                    else if (splitAddress.length == 2) {
                        this.setState({
                            streetAddress: place.name,
                            //city: splitAddress[0],
                            //state: splitAddress[1].trim().search(' ') > 0 ? splitAddress[1].trim().split(' ')[0] : "0",                            
                            //country: splitAddress[3].trim(),
                            zipcode: splitAddress[1].trim().search(' ') > 0 ? splitAddress[1].trim().split(' ')[1] : "0",
                        });
                    }
                    else if (splitAddress.length == 3) {
                        this.setState({
                            streetAddress: place.name,
                            //city: splitAddress[0].trim(),
                            //state: splitAddress[1].trim().search(' ') > 0 ? splitAddress[1].trim().split(' ')[0] : "0",                            
                            //country: splitAddress[2].trim(),
                            zipcode: splitAddress[1].trim().search(' ') > 0 ? splitAddress[1].trim().split(' ')[1] : "0",
                        });
                    }
                    else if (splitAddress.length == 4) {
                        this.setState({
                            streetAddress: place.name,
                            //city: splitAddress[1].trim(),
                            //state: splitAddress[2].trim().search(' ') > 0 ? splitAddress[2].trim().split(' ')[0] : "0",                            
                            //country: splitAddress[3].trim(),
                            zipcode: splitAddress[2].trim().search(' ') > 0 ? splitAddress[2].trim().split(' ')[1] : "0",
                        });
                    }
                    else if (splitAddress.length == 5) {
                        this.setState({
                            streetAddress: place.name,
                            //city: splitAddress[2].trim(),
                            //state: splitAddress[3].trim().search(' ') > 0 ? splitAddress[3].trim().split(' ')[0] : "0",                            
                            //country: splitAddress[3].trim(),
                            zipcode: splitAddress[3].trim().search(' ') > 0 ? splitAddress[3].trim().split(' ')[1] : "0",
                        });
                    }
                    else if (splitAddress.length == 6) {
                        this.setState({
                            streetAddress: place.name,
                            //city: splitAddress[3].trim(),
                            //state: splitAddress[4].trim().search(' ') > 0 ? splitAddress[4].trim().split(' ')[0] : "0",                            
                            //country: splitAddress[3].trim(),
                            zipcode: splitAddress[4].trim().search(' ') > 0 ? splitAddress[4].trim().split(' ')[1] : "0",
                        });
                    }
                    else {
                        this.setState({
                            streetAddress: place.name,
                            //city: splitAddress[4].trim(),
                            //state: splitAddress[5].trim().search(' ') > 0 ? splitAddress[5].trim().split(' ')[0] : "0",                            
                            //country: splitAddress[3].trim(),
                            zipcode: splitAddress[5].trim().search(' ') > 0 ? splitAddress[5].trim().split(' ')[1] : "0",
                        });

                    }
                }
                else {
                    this.setState({
                        streetAddress: "",
                        //city: "",
                        //state: "",                        
                        //country: "",
                        zipcode: "",
                    });
                }

                if (this.state.zipcode != "") {
                    this.state.errors["zipcode"] = "";
                }
                window.servicelocation = this.state.city;
            }
        }
    }
    handleStateChange(selectedValue) {
        this.setState({ stateName: (selectedValue.selectedItem != null ? selectedValue.selectedItem.stateName : ''), stateId: (selectedValue.selectedItem != null ? selectedValue.selectedItem.stateId : 0) });
        if (selectedValue.selectedItem != null) {
            let citylet = [];
            for (let a = 0; a < City.length; a++) {
                if (City[a].stateId == selectedValue.selectedItem.stateId) {
                    citylet.push(City[a]);
                }
            }
            this.setState({ cityList: citylet, stateId: selectedValue.selectedItem.stateId });
            this.state.errors["stateName"] = "";
        }
        else {
            this.setState({ stateName: '', stateId: 0, cityList: [], city: '', cityId: 0 });
        }
    }
    handleCityChange(selectedValue) {
        this.setState({ city: (selectedValue.selectedItem != null ? selectedValue.selectedItem.cityName : ''), cityId: (selectedValue.selectedItem != null ? selectedValue.selectedItem.cityId : 0) });
        this.state.errors["city"] = "";
    }

    handleSkinChange(selectedValue) {
        this.setState({ skinId: (selectedValue.selectedItem != null ? selectedValue.selectedItem.id : 0) });
    }
   
    handleDistanceunitChange(selectedValue) {
        this.setState({ distanceUnit: (selectedValue.selectedItem != null ? selectedValue.selectedItem.id : 0), })
        this.state.errors["distanceUnit"] = "";
    }
    handleInputChange(event) {
        let errors = this.state.errors;
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name == "websiteURL") {
            this.setState({ websiteURL: event.target.value });
            const re = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
            if (event.target.value == "") {
                this.setState({ [name]: event.target.value });
                this.state.errors[name] = "";
            }
            else if (!re.test(event.target.value)) {
                errors.websiteURL = "Please enter valid Website URL";
            }
            else {
                this.setState({ [name]: event.target.value });
                this.state.errors[name] = "";
            }
        }
        //else if (name == "faxNumber") {
        //    const re = /^[0-9\b]+$/;
        //    if (event.target.value === '' || re.test(event.target.value)) {
        //        debugger;
        //        this.setState({ faxNumber: event.target.value })
        //    }
        //}
        else if (name == "zipcode") {
            const re = /^[0-9a-zA-Z]+$/;
            //const re = /^\d{5}$|^\d{5}-\d{4}$/;
            if (event.target.value === '' || re.test(event.target.value)) {
                this.setState({ zipcode: event.target.value })
            }
            else {
                this.setState({ [name]: event.target.value });
                this.state.errors[name] = "";
            }
        } else if (name == "accountProfileImage") {
            if (event.target.files.length > 0) {
                this.setState({ accountProfileImage: URL.createObjectURL(event.target.files[0]) })
                this.setState({ accountSelectedProfileImage: event.target.files[0] })
            }
        } else if (name == "accountLogoImage") {

            if (event.target.files.length > 0) {
                this.setState({
                    logoSize: event.target.files[0].size,
                    logo: "",
                    logoName: event.target.files[0].name,
                    logoExt: event.target.files[0].type,
                })

                this.getBase64(event.target.files[0])
                    .then(result => {
                        this.setState({
                            logo: result,
                        });
                    })
                    .catch(err => {
                        console.log(err);
                    });
            } else {
                this.setState({
                    logoSize: 0,
                    logo: "",
                    logoName: "",
                    logoExt: "",
                })
            }
        } else {
            if (/\S/.test(event.target.value)) {
                this.setState({ [name]: event.target.value });
                this.state.errors[name] = "";
            }
            else {
                if (event.target.value.length == 0) {
                    this.setState({ [name]: event.target.value });
                    this.state.errors[name] = "";
                }
            }
        }
    }

    getBase64 = file => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                console.log("Called", reader);
                baseURL = reader.result;
                console.log(baseURL);
                resolve(baseURL);
            };
            console.log(fileInfo);
        });
    };

    async askRemoveLogoConfirm() {
        let isResult = false;
        await Swal.fire({
            title: "<small>Remove Logo</small>",
            html: "Do you want to remove logo? Please confirm.",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    async removeCompanyLogoHandle(e) {
        e.preventDefault();

        const isConfirm = await this.askRemoveLogoConfirm();
        if (!isConfirm) {
            return;
        }
        this.setState({
            logoSize: 0,
            logo: "",
            logoName: "",
            logoExt: "",
        })
    }

    handleInputChangeStep1(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name == "doTripDuration") {
            const re = /^[0-9-\b]+$/;
            if (event.target.value === '' || re.test(event.target.value)) {
                this.setState({ doTripDuration: event.target.value })
                if (event.target.value == "") {
                    this.state.errors[name] = "Please enter DO trip duration";
                }
                else if (event.target.value < 10 || event.target.value > 90) {
                    this.state.errors[name] = "DO trip duration should be between 10 to 90";
                }
                else {
                    this.state.errors[name] = "";
                }
            }
            else {
                this.setState({ doTripDuration: "" });
                this.state.errors[name] = "Please enter DO trip duration";
            }
        }
        else if (name == "nonAirportTransfer") {
            const re = /^[0-9-\b]+$/;
            if (event.target.value === '' || re.test(event.target.value)) {
                this.setState({ nonAirportTransfer: event.target.value })
                if (event.target.value == "") {
                    this.state.errors[name] = "Please enter non airport transfer minutes";
                }
                else if (event.target.value < 0 || event.target.value > 120) {
                    this.state.errors[name] = "DO trip duration should be between 0 to 120";
                }
                else {
                    this.state.errors[name] = "";
                }
            }
            else {
                this.setState({ nonAirportTransfer: "" });
                this.state.errors[name] = "Please enter non airport transfer minutes";
            }
        }
        else if (name == "airportTransferDomestic") {
            const re = /^[0-9-\b]+$/;
            if (event.target.value === '' || re.test(event.target.value)) {
                this.setState({ airportTransferDomestic: event.target.value })
                if (event.target.value == "") {
                    this.state.errors[name] = "Please enter non airport domestic transfer minutes";
                }
                else if (event.target.value < 0 || event.target.value > 60) {
                    this.state.errors[name] = "Non airport transfer domestic should be between 0 to 60";
                }
                else {
                    this.state.errors[name] = "";
                }
            }
            else {
                this.setState({ airportTransferDomestic: "" });
                this.state.errors[name] = "Please enter non airport domestic transfer minutes";
            }
        }
        else if (name == "airportTransferInternational") {
            const re = /^[0-9-\b]+$/;
            if (event.target.value === '' || re.test(event.target.value)) {
                this.setState({ airportTransferInternational: event.target.value })
                if (event.target.value == "") {
                    this.state.errors[name] = "Please enter non airport international transfer minutes";
                }
                else if (event.target.value < 0 || event.target.value > 120) {
                    this.state.errors[name] = "Non airport transfer international should be between 0 to 120";
                }
                else {
                    this.state.errors[name] = "";
                }
            }
            else {
                this.setState({ airportTransferInternational: "" });
                this.state.errors[name] = "Please enter non airport international transfer minutes";
            }
        }
        else {
            this.setState({
                [name]: value
            });
            this.state.errors[name] = "";
        }
    }
    async handleProfileImageSubmit(e) {
        e.preventDefault();

        if (this.state.accountSelectedProfileImage.size != undefined) {
            this.setState({ isProfilePhotoSubmited: true });
            if (this.state.accountSelectedProfileImage.size > 0) {
                const url = 'file-api/file-upload/';
                const formData = new FormData();
                formData.append('body', this.state.accountSelectedProfileImage);

                const config = {
                    headers: {
                        'content-type': 'multipart/form-data',
                        'UserId': sessionStorage.getItem('session_userId'),
                        'Authorization': 'Bearer ' + sessionStorage.getItem('session_token'),
                        'CultureCode': sessionStorage.getItem('session_culturecode'),
                    },
                };

                const response = await post(url, formData, config);
                const data = await response.data;
                if (data !== null) {
                    this.setState({
                        fileGuid: data.fileGuid
                    });
                }
            }
        }

        const requestParams = getRequestParams('GET', "");
        const fileresponse = await fetch('/account-api/' + this.state.accountId + "/" + this.state.fileGuid + "/update-account-profile-photo", requestParams);
        const filedata = await fileresponse.json();
        Swal.fire({
            icon: filedata.isSuccess ? 'Error' : 'error',
            title: filedata.isSuccess ? 'Update Profile Photo' : 'Oops...',
            width: '850px',
            html: filedata.resultMessage,
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (filedata.isSuccess) {
                if (result.isConfirmed) {
                    this.setState({ isProfilePhotoSubmited: false });
                    window.location.href = "/account/setting/";
                }
                else {
                    this.setState({ isProfilePhotoSubmited: false });
                }
            } else {
                this.setState({ isProfilePhotoSubmited: false });
            }
        });
    }
    async handleProfileSubmit(e) {
        e.preventDefault();
        if (this.handleProfileValidation()) {
            //Update

            var RegisterRequest = new Object();
            RegisterRequest.Account = new Object();
            RegisterRequest.Account.ParentAccountId = ParentAccount.UTWIZ;
            RegisterRequest.Account.ModuleId = ModuleCode.AFFILIATE;
            RegisterRequest.Account.AccountCode = "";
            RegisterRequest.Account.AccountName = this.state.transporterName;
            RegisterRequest.Account.ContactPerson = this.state.firstName + " " + this.state.lastName;
            RegisterRequest.Account.WebAddress = this.state.websiteURL;
            RegisterRequest.Account.CityId = this.state.cityId;
            RegisterRequest.Account.CityName = this.state.city;
            RegisterRequest.Account.StateName = this.state.stateName;
            RegisterRequest.Account.CountryName = this.state.country;
            RegisterRequest.Account.Mobile = this.state.mobileNumber;
            RegisterRequest.Account.PhoneNo = this.state.phoneNumber;
            RegisterRequest.Account.UserName = this.state.userName;
            RegisterRequest.Account.Password = this.state.userPassword;
            RegisterRequest.Account.FirstName = this.state.firstName;
            RegisterRequest.Account.LastName = this.state.lastName;
            RegisterRequest.Account.Email = this.state.emailAddress;
            RegisterRequest.Account.LegalName = this.state.transporterName;
            RegisterRequest.Account.SiteName = "";
            RegisterRequest.Account.SiteUrl = "";
            RegisterRequest.Account.SiteCategoryType = 0;
            RegisterRequest.Account.AirlineId = 0;
            RegisterRequest.Account.Address1 = this.state.streetAddress;
            RegisterRequest.Account.Address2 = "";
            RegisterRequest.Account.ZipCode = this.state.zipcode;
            RegisterRequest.Account.PinNo = "";
            RegisterRequest.Account.Timezone = this.state.timezoneId;
            RegisterRequest.Account.CurrencyId = this.state.currencyId;
            RegisterRequest.Account.NoOfYearInOperation = 0;
            RegisterRequest.Account.PassLastYear = 0;
            RegisterRequest.Account.NoOfPassengerToUsa = 0;
            RegisterRequest.Account.NoOfPassengerToRow = 0;
            RegisterRequest.Account.NoOfTerminal = 0;
            RegisterRequest.Account.PaymentMode = 0;
            RegisterRequest.Account.ConstitutionTypeId = 0;
            RegisterRequest.Account.IsActive = true;
            RegisterRequest.Account.IsServiceApiProvided = false;
            RegisterRequest.Account.TemplateTypeName = "ACCOUNT REGISTRATION";
            RegisterRequest.Account.NotificationSubject = "Affiliate Account Registered";
            RegisterRequest.Account.CustomerTypeId = CustomerType.Affiliates;
            RegisterRequest.Account.RateType = 0;
            RegisterRequest.Account.BillToCustomerId = 0;
            RegisterRequest.Account.ParentCustomerId = 0;
            RegisterRequest.Account.FaxNo = this.state.faxNumber;
            RegisterRequest.Account.CountryCode = "";
            RegisterRequest.Account.AreaCode = "";
            RegisterRequest.Account.SkinId = Number(this.state.skinId);
            RegisterRequest.Account.LogoSize = Number(this.state.logoSize);
            RegisterRequest.Account.Logo = this.state.logo;
            RegisterRequest.Account.LogoName = this.state.logoName;
            RegisterRequest.Account.LogoExt = this.state.logoExt;
            RegisterRequest.Account.ReportDisplayName = "";
            RegisterRequest.Account.ReportHeader = "";
            RegisterRequest.Account.ReportFooter = "";
            RegisterRequest.Account.Notes = "";
            RegisterRequest.Account.SpecialInstruction = "";
            RegisterRequest.Account.ReportImage = "";
            RegisterRequest.Account.IsPriority = false;
            RegisterRequest.Account.Rating = 'N';
            RegisterRequest.Account.PlanId = parseInt(this.state.planId);
            RegisterRequest.Account.DefaultUserLocationId = 0;
            RegisterRequest.Account.RegistrationNumber = this.state.EINNumber;
            //Billing Setting
            RegisterRequest.BillingSetting = new Object();
            RegisterRequest.BillingSetting.AccountId = parseInt(this.state.accountId);
            RegisterRequest.BillingSetting.IsCreditLimit = false;
            RegisterRequest.BillingSetting.CreditLimitAmount = 1000;
            RegisterRequest.BillingSetting.CreditLimitUsedAmount = 0;
            RegisterRequest.BillingSetting.BillingTypeId = 0;
            RegisterRequest.BillingSetting.BillingCycleId = 0;
            RegisterRequest.BillingSetting.IsDiscountApplicable = false;
            RegisterRequest.BillingSetting.IsDiscountPercentage = false;
            RegisterRequest.BillingSetting.DiscountCharge = 0;
            RegisterRequest.BillingSetting.IsCommissionApplicable = false;
            RegisterRequest.BillingSetting.IsCommissionPercentage = false;
            RegisterRequest.BillingSetting.CommissionCharge = 0;
            RegisterRequest.BillingSetting.IsMarkupApplicable = false;
            RegisterRequest.BillingSetting.IsMarkupPercentage = false;
            RegisterRequest.BillingSetting.MarkupCharge = 0;
            RegisterRequest.BillingSetting.WefDate = new Date();
            //location
            RegisterRequest.Location = new Object();
            RegisterRequest.Location.AccountId = 0;
            RegisterRequest.Location.LocationId = 0;
            RegisterRequest.Location.IsActive = true;

            const locationEmails = [];
            //Location Emails
            RegisterRequest.LocationEmails = new Object();
            RegisterRequest.LocationEmails = locationEmails;
            const requestParams = getRequestParams('PUT', RegisterRequest);
   
            try {
                this.setState({ isProfileSubmited: true });
                const response = await fetch('/account-api/' + this.state.accountId + "/register", requestParams);
                const data = await response.json();

                Swal.fire({
                    icon: data.isSuccess ? 'Error' : 'error',
                    title: data.isSuccess ? 'Update Profile' : 'Oops...',
                    width: '850px',
                    html: data.resultMessage,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            this.setState({ isProfileSubmited: false });
                            
                            if (this.state.skinId > 0) {
                                
                                var skinObj = this.state.skinList.find(f => f.id == this.state.skinId);
                                sessionStorage.setItem("session_skinId", this.state.skinId);
                                sessionStorage.setItem("session_skinName", skinObj.skinName);
                                sessionStorage.setItem("session_cssSkinName", skinObj.cssSkinName);
                            } else {
                                sessionStorage.setItem("session_skinId", 0);
                                sessionStorage.setItem("session_skinName", "");
                                sessionStorage.setItem("session_cssSkinName", "");
                            }
                            
                            sessionStorage.setItem("session_accountLogo", this.state.logo);
                            sessionStorage.setItem("session_accountLogoSize", this.state.logoSize);
                            window.location.href = "/account/setting/";
                        }
                        else {
                            this.setState({ isProfileSubmited: false });
                        }
                    } else {
                        this.setState({ isProfileSubmited: false });
                    }
                });
            } catch (e) {
                this.setState({ isProfileSubmited: false });
            }
        }
    }
    async handleGeneralInfoSubmit(e) {
        e.preventDefault();
        //let locationIdList = [];
        //let vehicleTypeIdList = [];
        try {
            if (this.handleGeneralInfoValidation()) {
                var accountSetupRequest = new Object();
                accountSetupRequest.MachineIp = "Web";
                accountSetupRequest.Enable = true;
                accountSetupRequest.TransferTypeId = 1;
                accountSetupRequest.ServiceTypeId = 2;
                accountSetupRequest.LocationId = parseInt(sessionStorage.getItem('session_locationId'));
                accountSetupRequest.FileGuid = this.state.fileGuid;

                accountSetupRequest.AirfieldSettings = new Object();
                accountSetupRequest.AirfieldSettings.AirfieldMaxPaxPerBus = 0;
                accountSetupRequest.AirfieldSettings.AirfieldMaxAdaPerEmptyBus = 0;
                accountSetupRequest.AirfieldSettings.AirfieldDepCrewMinAvdMinutes = 0;
                accountSetupRequest.AirfieldSettings.AirfieldDepPaxMinAvdMinutes = 0;
                accountSetupRequest.AirfieldSettings.AirfieldArrCrewMinAvdMinutes = 0;
                accountSetupRequest.AirfieldSettings.AirfieldArrPaxMinAvdMinutes = 0;

                accountSetupRequest.SaveGeneralInformation = new Object();
                accountSetupRequest.SaveGeneralInformation.TimeZoneId = parseInt(this.state.timezoneId);
                accountSetupRequest.SaveGeneralInformation.CurrencyId = parseInt(this.state.currencyId);
                accountSetupRequest.SaveGeneralInformation.DoTripDuration = parseInt(this.state.doTripDuration);
                accountSetupRequest.SaveGeneralInformation.DistanceUnitType = parseInt(this.state.distanceUnit);
                accountSetupRequest.SaveGeneralInformation.NonAirportTransfer = this.state.nonAirportTransfer;
                accountSetupRequest.SaveGeneralInformation.DomesticAirportTransfer = this.state.airportTransferDomestic;
                accountSetupRequest.SaveGeneralInformation.InternationalAirportTransfer = this.state.airportTransferInternational;
                this.setState({ isGeneralInfoSubmit: true });
                const requestParams = getRequestParams('POST', accountSetupRequest);
                const response = await fetch('/account-api/' + this.state.accountId + '/update-setting-general', requestParams);
                const data = await response.json();
                //if (data.isSuccess)
                {
                    Swal.fire({
                        icon: data.isSuccess ? 'General Information' : 'error',
                        title: data.isSuccess ? 'General Information' : 'Oops...',
                        text: data.resultMessage,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (data.isSuccess) {
                            if (result.isConfirmed) {
                                this.setState({ isGeneralInfoSubmit: false });
                                window.location.href = "/account/setting/";
                            }
                        } else {
                            this.setState({ isGeneralInfoSubmit: false });
                        }
                    });
                }
            }
        } catch (e) {
            this.setState({ isGeneralInfoSubmit: false });
        }
    }
    async handleServiceInfoSubmit(e) {
        e.preventDefault();
        try {
            //debugger;
            if (this.handleServiceInfoValidation()) {
                var locationIdList = [];
                var vehicleTypeIdList = [];
                

                var accountSetupRequest = new Object();
                accountSetupRequest.MachineIp = "Web";
                accountSetupRequest.Enable = true;
                accountSetupRequest.TransferTypeId = 1;
                accountSetupRequest.ServiceTypeId = 2;
                accountSetupRequest.LocationIdList = new Object();
                for (let j = 0; j < this.state.serviceLocations.length; j++) {
                    locationIdList.push(this.state.serviceLocations[j]);
                }
                accountSetupRequest.LocationIdList = locationIdList;
                //debugger;

                accountSetupRequest.VehicleTypeIdList = new Object();
                for (let j = 0; j < this.state.serviceVehicleType.length; j++) {
                    vehicleTypeIdList.push(parseInt(this.state.serviceVehicleType[j]));
                }
                accountSetupRequest.VehicleTypeIdList = vehicleTypeIdList;
  
                var serviceAirports = [];
                accountSetupRequest.Airports = new Object();
                for (let j = 0; j < this.state.serviceAirports.length; j++) {
                    var row = new Object();
                    const airportLocation = this.state.airportList.find(f => f.airportId === this.state.serviceAirports[j]);
                    row.LocationId = parseInt(airportLocation.locationId);
                    row.AirportId = parseInt(airportLocation.airportId);
                    serviceAirports.push(row);
                }
                accountSetupRequest.Airports = serviceAirports;
                this.setState({ isServiceInfoSubmit: true });
                const requestParams = getRequestParams('POST', accountSetupRequest);
                const response = await fetch('/account-api/' + this.state.accountId + '/update-setting-service', requestParams);
                const data = await response.json();

                //debugger;
                if (data.isSuccess) {
                    Swal.fire({
                        icon: data.isSuccess ? 'Service Information' : 'error',
                        title: data.isSuccess ? 'Service Information' : 'Oops...',
                        text: data.resultMessage,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (data.isSuccess) {
                            if (result.isConfirmed) {
                                this.setState({ isServiceInfoSubmit: false });
                                window.location.href = "/account/setting/";
                            }
                        } else {
                            this.setState({ isServiceInfoSubmit: false });
                        }
                    });
                }
            }
        }
        catch (e) {
            this.setState({ isServiceInfoSubmit: false });
        }
    }

    async onSelectionChanged(e) {
        this.setState({
            serviceLocations: e.selectedRowKeys.length && e.selectedRowKeys || []
        });
        await this.getAirports();
    }
   async syncDataGridSelection(e) {
        this.setState({
            serviceLocations: e.value || []
        });
        await this.getAirports();
    }

    onSelectionAirportChanged(e) {
        this.setState({
            serviceAirports: e.selectedRowKeys.length && e.selectedRowKeys || []
        });
    }
    syncDataGridAirportSelection(e) {
        this.setState({
            serviceAirports: e.value || []
        });
    }

    onSelectionVehicleTypeChanged(e) {
        this.setState({
            serviceVehicleType: e.selectedRowKeys.length && e.selectedRowKeys || []
        });
    }
    syncDataGridVehicleTypeSelection(e) {
        this.setState({
            serviceVehicleType: e.value || []
        });
    }

    render() {
        return (
            <div className="wrapper">
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <h1 className="m-0 text-dark">Account Setup</h1>
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content">
                        <div className="row" style={{ marginRight: -7.5 + 'px', marginLeft: -7.5 + 'px' }}>
                            <div className="col-lg-12">
                                <div className="card card-primary card-outline card-outline-tabs account-settings mt-2">
                                    <div className="card-header p-0 border-bottom-0">
                                        <ul className="nav" id="custom-tabs-tab" role="tablist">
                                            <li className="nav-item">
                                                    <a className="nav-link active" id="custom-tabs-home-tab" data-toggle="pill" href="#custom-tabs-home" role="tab" aria-controls="custom-tabs-home" aria-selected="true">Account Profile</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="custom-tabs-profile-tab" data-toggle="pill" href="#custom-tabs-profile" role="tab" aria-controls="custom-tabs-profile" aria-selected="false">General Information</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="custom-tabs-messages-tab" data-toggle="pill" href="#custom-tabs-messages" role="tab" aria-controls="custom-tabs-messages" aria-selected="false">Service Information</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="card-body">
                                        <div className="tab-content" id="custom-tabs-tabContent">
                                            <div className="tab-pane fade active show" id="custom-tabs-home" role="tabpanel" aria-labelledby="custom-tabs-home-tab">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                        <div className="card-body box-profile" style={{ paddingTop: 0 + 'px' }}>
                                                            <div className="text-center" style={{ marginBottom: 10 + 'px' }}>
                                                                <img className="img-circle" style={{ height: 200 + "px", width: 200 + "px" }} src={this.state.accountProfileImage} alt="User profile picture" />
                                                            </div>
                                                            <div className="row"></div>
                                                            <div className="input-group">
                                                                <div className="col-lg-8 custom-file">
                                                                    <input type="file" name="accountProfileImage" className="custom-file-input" onChange={this.handleInputChange} />
                                                                    <label className="custom-file-label" >Choose file</label>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <button type="submit" className="btn btn-custom-gray btn-sm" onClick={this.removeImageHandle} title="Remove Photo"><i className="fas fa-image"></i> Remove </button>
                                                                </div>
                                                            </div>
                                                            <h3 className="profile-username text-center">{this.state.accountName}</h3>
                                                            <ul className="list-group list-group-unbordered mb-3">
                                                                <li className="list-group-item">
                                                                    <i className="far fa-star"></i> <strong>Status</strong> <span className="float-right"><span className="badge badge-success">{this.state.accountDetails.accountStatusType}</span></span>
                                                                </li>
                                                                <li className="list-group-item">
                                                                    <i className="far fa-check-circle"></i> <strong>Current Plan</strong> <span className="float-right">{this.state.accountDetails.planName}</span>
                                                                </li>
                                                                <li className="list-group-item">
                                                                    <i className="far fa-money-bill-alt"></i> <strong>Plan Fees</strong> <span className="float-right">${parseFloat(this.state.accountDetails.planCostPerMonth).toFixed(2)} /mo</span>
                                                                </li>
                                                                <li className="list-group-item">
                                                                    <i className="far fa-money-bill-alt"></i> <strong>Account Setup Fees</strong> <span className="float-right">${parseFloat(this.state.accountDetails.accountsetupFees).toFixed(2)} </span>
                                                                </li>
                                                                <li className="list-group-item">
                                                                    <i className="far fa-calendar-alt"></i> <strong>Member Since</strong> <span className="float-right">{formatDate(this.state.accountDetails.registrationDate, "MM/dd/yyyy")}</span>
                                                                </li>
                                                                <li className="list-group-item">
                                                                    <i className="far fa-clock"></i> <strong>Time Zone</strong> <span className="float-right">{this.state.accountDetails.timezoneShortName}</span>
                                                                </li>
                                                            </ul>
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-right">
                                                                    <button type="button" className="btn btn-success btn-sm" onClick={this.handleProfileImageSubmit} >
                                                                        {this.state.isProfilePhotoSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                                                        {!this.state.isProfilePhotoSubmited && <span title="Update Picture"><i className="fas fa-image"></i> Update Picture</span>}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8 account-setting">
                                                        <form method="post" onSubmit={this.handleProfileSubmit} style={this.state.isProfileSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                            <h6 className="text-info">Company Information</h6>
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                                    <label>Transporter Name <span className="text-red">*</span></label>
                                                                    <div className="input-group mb-3 form-group">
                                                                        <input type="text" className="form-control form-control-sm" name="transporterName" maxLength="50" value={this.state.transporterName} onChange={this.handleInputChange} placeholder="Enter transpoter name" />
                                                                        <div className="input-group-append">
                                                                            <div className="input-group-text">
                                                                                <span className="fas fa-car"></span>
                                                                            </div>
                                                                        </div>
                                                                        {this.state.errors["transporterName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["transporterName"]}</span>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                                    <label>EIN/Registration Number <span className="text-red">*</span></label>
                                                                    <div className="input-group mb-3">
                                                                        <input type="text" className="form-control form-control-sm" name="EINNumber" maxLength="20" value={this.state.EINNumber} onChange={this.handleInputChange} placeholder="Enter ein/registration number" />
                                                                        <div className="input-group-append">
                                                                            <div className="input-group-text">
                                                                                <span className="fas fa-user-tie"></span>
                                                                            </div>
                                                                        </div>
                                                                        {this.state.errors["EINNumber"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["EINNumber"]}</span>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                                    <label>Website URL&nbsp;<i className="fas fa-info-circle text-info" data-tip data-for="websiteURLTooltip"></i></label>
                                                                    <div className="input-group mb-3">
                                                                        <input type="text" className="form-control form-control-sm" name="websiteURL" maxLength="100" value={this.state.websiteURL} onChange={this.handleInputChange} placeholder="Enter website url" />
                                                                        <div className="input-group-append">
                                                                            <div className="input-group-text">
                                                                                <span className="fas fa-fax"></span>
                                                                            </div>
                                                                        </div>
                                                                        {this.state.errors["websiteURL"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["websiteURL"]}</span>}
                                                                        <ReactTooltip id="websiteURLTooltip" place="top" effect="solid"> <ul>
                                                                            <li>http://www.example.com</li>
                                                                            <li>https://www.example.com</li>
                                                                            <li>http://example.com</li>
                                                                            <li>www.example.com</li>
                                                                        </ul></ReactTooltip>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                                    <label>Country <span className="text-red">*</span></label>
                                                                    <div className="form-group">
                                                                        <SelectBox
                                                                            dataSource={this.state.countryList.length > 0 ? this.state.countryList : []}
                                                                            className="basic-multi-select"
                                                                            classNamePrefix="select"
                                                                            placeholder="Select country"
                                                                            name="countryId"
                                                                            defaultValue={0}
                                                                            value={this.state.countryId}
                                                                            displayExpr='countryName'
                                                                            valueExpr='countryId'
                                                                            onSelectionChanged={this.handleCountryChange}
                                                                            showClearButton={true}
                                                                            disabled={false}
                                                                            searchEnabled={true}
                                                                        />
                                                                        {this.state.errors["country"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["country"]}</span>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                                    <label>State <span className="text-red">*</span></label>
                                                                    <div className="form-group">
                                                                        <SelectBox
                                                                            dataSource={this.state.stateList.length > 0 ? this.state.stateList : []}
                                                                            className="basic-multi-select"
                                                                            classNamePrefix="select"
                                                                            placeholder="Select state"
                                                                            name="stateId"
                                                                            defaultValue={0}
                                                                            value={this.state.stateId}
                                                                            displayExpr='stateName'
                                                                            valueExpr='stateId'
                                                                            onSelectionChanged={this.handleStateChange}
                                                                            showClearButton={true}
                                                                            disabled={false}
                                                                            searchEnabled={true}
                                                                        />
                                                                        {this.state.errors["stateName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["stateName"]}</span>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                                    <label>City <span className="text-red">*</span></label>
                                                                    <div className="form-group">
                                                                        <SelectBox
                                                                            dataSource={this.state.cityList.length > 0 ? this.state.cityList : []}
                                                                            className="basic-multi-select"
                                                                            classNamePrefix="select"
                                                                            placeholder="Select city"
                                                                            name="cityId"
                                                                            defaultValue={0}
                                                                            value={this.state.cityId}
                                                                            displayExpr='cityName'
                                                                            valueExpr='cityId'
                                                                            onSelectionChanged={this.handleCityChange}
                                                                            showClearButton={true}
                                                                            disabled={false}
                                                                            searchEnabled={true}
                                                                        />
                                                                        {this.state.errors["city"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["city"]}</span>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                                    <label>Street Address <span className="text-red">*</span></label>
                                                                    <div className="input-group mb-3">
                                                                        <input type="text" className="form-control form-control-sm" maxLength="100" id="streetAddress" name="streetAddress" value={this.state.streetAddress} onChange={this.handleInputChange} placeholder="Enter street address" />
                                                                        <div className="input-group-append">
                                                                            <div className="input-group-text">
                                                                                <span className="fas fa-road"></span>
                                                                            </div>
                                                                        </div>
                                                                        {this.state.errors["streetAddress"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["streetAddress"]}</span>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                                    <label>Zip Code <span className="text-red">*</span></label>
                                                                    <div className="input-group mb-3">
                                                                        <input type="text" className="form-control form-control-sm" name="zipcode" maxLength="6" value={this.state.zipcode} onChange={this.handleInputChange} placeholder="Enter zip code" />
                                                                        <div className="input-group-append">
                                                                            <div className="input-group-text">
                                                                                <span className="fas fa-globe"></span>
                                                                            </div>
                                                                        </div>
                                                                        {this.state.errors["zipcode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["zipcode"]}</span>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                                    <label>Phone Number <span className="text-red">*</span></label>
                                                                    <div className="input-group mb-3">
                                                                        <NumberFormat className="form-control form-control-sm" name="phoneNumber" title="Phone Number" value={this.state.phoneNumber} onChange={this.handleInputChange} format="(###) ###-####" placeholder="(000) 000-0000" />
                                                                        <div className="input-group-append">
                                                                            <div className="input-group-text">
                                                                                <span className="fas fa-phone"></span>
                                                                            </div>
                                                                        </div>
                                                                        {this.state.errors["phoneNumber"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["phoneNumber"]}</span>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                                    <label>Mobile Number <span className="text-red">*</span></label>
                                                                    <div className="input-group mb-3">
                                                                        <NumberFormat className="form-control form-control-sm" name="mobileNumber" title="Mobile Number" value={this.state.mobileNumber} onChange={this.handleInputChange} format="(###) ###-####" placeholder="(000) 000-0000" />
                                                                        <div className="input-group-append">
                                                                            <div className="input-group-text">
                                                                                <span className="fas fa-mobile"></span>
                                                                            </div>
                                                                        </div>
                                                                        {this.state.errors["mobileNumber"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["mobileNumber"]}</span>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                                    <label>Fax Number</label>
                                                                    <div className="input-group mb-3">
                                                                        <NumberFormat className="form-control form-control-sm" name="faxNumber" title="Fax Number" value={this.state.faxNumber} onChange={this.handleInputChange} format="(###) ###-####" placeholder="(000) 000-0000" />
                                                                        <div className="input-group-append">
                                                                            <div className="input-group-text">
                                                                                <span className="fas fa-fax"></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                                    <label>Company Logo </label>
                                                                    <div className="input-group">
                                                                        <input type="file" name="accountLogoImage" className="custom-file-input" onChange={this.handleInputChange} />
                                                                        <label className="custom-file-label" >Choose file</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                                    <label>Logo Preview</label>
                                                                    {this.state.logoSize > 0 && <div>
                                                                        <img className="" style={{ height: 38 + "px" }} src={this.state.logo} alt="Company Logo" />
                                                                        <a href="#" className="text-dager" onClick={this.removeCompanyLogoHandle} title="Remove Photo">&nbsp;<i className="fas fa-times-circle text-danger"></i>&nbsp;</a>
                                                                    </div>}
                                                                    {this.state.logoSize <= 0 && <div>
                                                                        <img style={{ height: 38 + "px"}} src="../../dist/img/logo_utwiz.png" alt="UTWiz" />
                                                                    </div>}
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                                    <label>Web Theme</label>
                                                                    <div className="form-group">
                                                                        <SelectBox
                                                                            dataSource={this.state.skinList.length > 0 ? this.state.skinList : []}
                                                                            className="basic-multi-select"
                                                                            classNamePrefix="select"
                                                                            placeholder="Select skin"
                                                                            name="id"
                                                                            defaultValue={0}
                                                                            value={this.state.skinId}
                                                                            displayExpr='name'
                                                                            valueExpr='id'
                                                                            onSelectionChanged={this.handleSkinChange}
                                                                            showClearButton={true}
                                                                            disabled={false}
                                                                            searchEnabled={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <h6 className="text-info">Primary Contact</h6>
                                                            <div className="row">
                                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                    <label>First Name <span className="text-red">*</span></label>
                                                                    <div className="input-group mb-3">
                                                                        <input type="text" className="form-control form-control-sm" name="firstName" maxLength="50" value={this.state.firstName} onChange={this.handleInputChange} placeholder="Enter first name" />
                                                                        <div className="input-group-append">
                                                                            <div className="input-group-text">
                                                                                <span className="fas fa-user"></span>
                                                                            </div>
                                                                        </div>
                                                                        {this.state.errors["firstName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["firstName"]}</span>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                    <label>Last Name <span className="text-red">*</span></label>
                                                                    <div className="input-group mb-3">
                                                                        <input type="text" className="form-control form-control-sm" name="lastName" maxLength="50" value={this.state.lastName} onChange={this.handleInputChange} placeholder="Enter last name" />
                                                                        <div className="input-group-append">
                                                                            <div className="input-group-text">
                                                                                <span className="fas fa-user"></span>
                                                                            </div>
                                                                        </div>
                                                                        {this.state.errors["lastName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["lastName"]}</span>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                    <label>Email Address <span className="text-red">*</span></label>
                                                                    <div className="input-group mb-3">
                                                                        <input type="text" className="form-control form-control-sm" name="emailAddress" maxLength="50" value={this.state.emailAddress} onChange={this.handleInputChange} placeholder="example@domain.com" />
                                                                        <div className="input-group-append">
                                                                            <div className="input-group-text">
                                                                                <span className="fas fa-envelope"></span>
                                                                            </div>
                                                                        </div>
                                                                        {this.state.errors["emailAddress"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["emailAddress"]}</span>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                    <label>Phone Number <span className="text-red">*</span></label>
                                                                    <div className="input-group mb-3">
                                                                        <NumberFormat className="form-control form-control-sm" name="userPhone" title="Phone Number" value={this.state.userPhone} onChange={this.handleInputChange} format="(###) ###-####" placeholder="(000) 000-0000" />
                                                                        <div className="input-group-append">
                                                                            <div className="input-group-text">
                                                                                <span className="fas fa-phone"></span>
                                                                            </div>
                                                                        </div>
                                                                        {this.state.errors["userPhone"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["userPhone"]}</span>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 displayNone">
                                                                    <label>Username <span className="text-red">*</span></label>
                                                                    <div className="input-group mb-3">
                                                                        <input type="text" className="form-control form-control-sm" name="userName" maxLength="25" value={this.state.userName} onChange={this.handleInputChange} placeholder="Enter username" />
                                                                        <div className="input-group-append">
                                                                            <div className="input-group-text">
                                                                                <span className="fas fa-city"></span>
                                                                            </div>
                                                                        </div>
                                                                        {this.state.errors["userName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["userName"]}</span>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-right">
                                                                    <button type="button" className="btn btn-success btn-sm" onClick={this.handleProfileSubmit} >
                                                                        {this.state.isProfileSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                                                        {!this.state.isProfileSubmited && <span title="Update Profile"><i className="fas fa-save"></i> Update Profile</span>}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="custom-tabs-profile" role="tabpanel" aria-labelledby="custom-tabs-profile-tab">
                                                <form method="post" onSubmit={this.handleGeneralInfoSubmit} style={this.state.isGeneralInfoSubmit ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                    <h6 className="text-info">Time Zone and Currency</h6>
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>Time Zone</label>
                                                                <SelectBox
                                                                    dataSource={this.state.timezoneList.length > 0 ? this.state.timezoneList : []}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    placeholder="Select time zone"
                                                                    name="id"
                                                                    defaultValue={0}
                                                                    value={this.state.timezoneId}
                                                                    displayExpr='shortName'
                                                                    valueExpr='id'
                                                                    onSelectionChanged={this.handleTimezoneChange}
                                                                    showClearButton={true}
                                                                    disabled={false}
                                                                    searchEnabled={true}
                                                                />
                                                                {this.state.errors["timezone"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["timezone"]}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>Default Currency</label>
                                                                <SelectBox
                                                                    dataSource={this.state.currencyList.length > 0 ? this.state.currencyList : []}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    placeholder="Select currency"
                                                                    name="id"
                                                                    defaultValue={0}
                                                                    value={this.state.currencyId}
                                                                    displayExpr='currencyName'
                                                                    valueExpr='currencyId'
                                                                    onSelectionChanged={this.handleCurrencyChange}
                                                                    showClearButton={true}
                                                                    disabled={false}
                                                                    searchEnabled={true}
                                                                />
                                                                {this.state.errors["currency"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["currency"]}</span>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h6 className="text-info">Reservation</h6>
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>DO Trip Duration (in mins)</label>
                                                                <input className="form-control form-control-sm" type="text" name="doTripDuration" maxLength="3" value={this.state.doTripDuration} onChange={this.handleInputChangeStep1} placeholder="Enter drop off trip duration" />
                                                            </div>
                                                            {this.state.errors["doTripDuration"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["doTripDuration"]}</span>}
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>Miles or Kilometers</label>
                                                                <SelectBox
                                                                    dataSource={this.state.distanceUnitList.length > 0 ? this.state.distanceUnitList : []}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    placeholder="Select distance unit"
                                                                    name="id"
                                                                    defaultValue={0}
                                                                    value={this.state.distanceUnit}
                                                                    displayExpr='name'
                                                                    valueExpr='id'
                                                                    onSelectionChanged={this.handleDistanceunitChange}
                                                                    showClearButton={true}
                                                                    disabled={false}
                                                                    searchEnabled={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h6 className="text-info">Default Pick-up Arrivals (In mins)</h6>
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>Non Airport Transfer</label>
                                                                <input className="form-control form-control-sm" type="text" name="nonAirportTransfer" maxLength="3" value={this.state.nonAirportTransfer} onChange={this.handleInputChangeStep1} placeholder="Enter non airport transfer in minutes" />
                                                            </div>
                                                            {this.state.errors["nonAirportTransfer"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["nonAirportTransfer"]}</span>}
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>Airport Transfer - Domestic</label>
                                                                <input className="form-control form-control-sm" type="text" name="airportTransferDomestic" maxLength="3" value={this.state.airportTransferDomestic} onChange={this.handleInputChangeStep1} placeholder="Enter airport transfer domestic in minutes" />
                                                            </div>
                                                            {this.state.errors["airportTransferDomestic"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["airportTransferDomestic"]}</span>}
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                            <div className="form-group">
                                                                <label>Airport Transfer - International</label>
                                                                <input className="form-control form-control-sm" type="text" name="airportTransferInternational" maxLength="3" value={this.state.airportTransferInternational} onChange={this.handleInputChangeStep1} placeholder="Enter airport transfer international in minutes" />
                                                            </div>
                                                            {this.state.errors["airportTransferInternational"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["airportTransferInternational"]}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-right">
                                                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleGeneralInfoSubmit} >
                                                                {this.state.isGeneralInfoSubmit && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                                                {!this.state.isGeneralInfoSubmit && <span title="Update General Information"><i className="fas fa-save"></i> Update General Information</span>}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="tab-pane fade" id="custom-tabs-messages" role="tabpanel" aria-labelledby="custom-tabs-messages-tab">
                                                <form method="post" onSubmit={this.handleServiceInfoSubmit} style={this.state.isServiceInfoSubmit ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                                    <div className="row">
                                                        <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                            <div className="form-group">
                                                                <label>Service location(s)</label>
                                                                <DropDownBox
                                                                    value={this.state.serviceLocations}
                                                                    valueExpr="id"
                                                                    deferRendering={false}
                                                                    displayExpr="name"
                                                                    placeholder="Select locations..."
                                                                    showClearButton={true}
                                                                    dataSource={this.state.locationList}
                                                                    onValueChanged={this.syncDataGridSelection}
                                                                    contentRender={this.dataGridRender}
                                                                />
                                                            </div>
                                                            {this.state.errors["serviceLocations"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["serviceLocations"]}</span>}
                                                        </div>
                                                        <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                            <div className="form-group">
                                                                <label>Service Airport(s)</label>
                                                                <DropDownBox
                                                                    value={this.state.serviceAirports}
                                                                    valueExpr="airportId"
                                                                    deferRendering={false}
                                                                    displayExpr="code"
                                                                    placeholder="Select airports..."
                                                                    showClearButton={true}
                                                                    dataSource={this.state.airportList}
                                                                    onValueChanged={this.syncDataGridAirportSelection}
                                                                    contentRender={this.dataGridAirportRender}
                                                                />
                                                            </div>
                                                            {this.state.errors["serviceAirports"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["serviceAirports"]}</span>}
                                                        </div>
                                                        <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                            <div className="form-group">
                                                                <label>Available vehicle type</label>
                                                                <DropDownBox
                                                                    value={this.state.serviceVehicleType}
                                                                    valueExpr="id"
                                                                    deferRendering={false}
                                                                    displayExpr="vehicleType"
                                                                    placeholder="Select vehicle type..."
                                                                    showClearButton={true}
                                                                    dataSource={this.state.vehicleTypeList}
                                                                    onValueChanged={this.syncDataGridVehicleTypeSelection}
                                                                    contentRender={this.dataGridVehicleTypeRender}
                                                                />
                                                            </div>
                                                            {this.state.errors["serviceVehicleType"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["serviceVehicleType"]}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-right">
                                                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleServiceInfoSubmit} >
                                                                {this.state.isServiceInfoSubmit && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                                                {!this.state.isServiceInfoSubmit && <span title="Update Service Information"><i className="fas fa-save"></i> Update Service Information</span>}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }

    dataGridRender() {
        return (
            <DataGrid
                dataSource={this.state.locationList}
                id="grid-container"
                keyExpr="id"
                hoverStateEnabled={true}
                selectedRowKeys={this.state.serviceLocations}
                onSelectionChanged={this.onSelectionChanged}
                ref={ref => this.dataGrid = ref}>
                <Column dataField="name" caption="Location" />

                <Selection mode="multiple" />
                <Scrolling mode="both" />
                <Paging enabled={true} pageSize={10} />
                <FilterRow visible={true} />


            </DataGrid>
        );
    }

    dataGridAirportRender() {
        return (
            <DataGrid
                dataSource={this.state.airportList}
                id="grid-container"
                keyExpr="airportId"
                hoverStateEnabled={true}
                selectedRowKeys={this.state.serviceAirports}
                onSelectionChanged={this.onSelectionAirportChanged}
                ref={ref => this.dataGrid = ref}>
                <Column dataField="code" caption="Airport Code" />

                <Selection mode="multiple" />
                <Scrolling mode="both" />
                <Paging enabled={true} pageSize={5} />
                <FilterRow visible={true} />


            </DataGrid>
        );
    }

    dataGridVehicleTypeRender() {
        return (
            <DataGrid
                dataSource={this.state.vehicleTypeList}
                id="grid-container"
                keyExpr="id"
                hoverStateEnabled={true}
                selectedRowKeys={this.state.serviceVehicleType}
                onSelectionChanged={this.onSelectionVehicleTypeChanged}
                ref={ref => this.dataGrid = ref}>
                <Column dataField="vehicleType" caption="Vehicle Type" />

                <Selection mode="multiple" />
                <Scrolling mode="both" />
                <Paging enabled={true} pageSize={5} />
                <FilterRow visible={true} />


            </DataGrid>
        );
    }

    
}

