import React, { Component } from 'react';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions';
import { LoadPanel } from 'devextreme-react/load-panel';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { VehiclePayReportPayrollPeriodwise } from '../Report/VehiclePayReportPayrollPeriodwise'
import { IATAInvoiceReportComponent } from '../Report/IATAInvoiceReportComponent'
import { WorkCompensationReportComponent } from '../Report/WorkCompensationReportComponent'
import { RideCommissionDetailsReportComponent } from '../Report/RideCommissionDetailsReportComponent'
import { DriverLoginHistoryReportComponent } from '../Report/DriverLoginHistoryReportComponent'
import { MonthlyBudgetComparisonReportComponent } from '../Report/MonthlyBudgetComparisonReportComponent'
import { CongestionReportComponent } from '../Report/CongestionReportComponent'
import { SalesActivityComponent } from '../Report/SalesActivityComponent'
import { VehiclePayReportSummaryDetailsComponent } from '../Report/VehiclePayReportSummaryDetailsComponent'
import { InvoiceReportComponent } from '../Report/InvoiceReportComponent'
import { InvoiceSummaryReportPickupwiseComponent } from '../Report/InvoiceSummaryReportPickupwiseComponent'
import { InvoiceSummaryGraphComponent } from '../Report/InvoiceSummaryGraphComponent'
import { DriverWorkHourHistoryReportComponent } from '../Report/DriverWorkHourHistoryReportComponent'
import { CustomerRideActivityReportComponent } from '../Report/CustomerRideActivityReportComponent';
import { RevenueProfitLossComponent } from '../Report/RevenueProfitLossComponent';
import { RevenueYearlyComparisonComponent } from '../Report/RevenueYearlyComparisonComponent';
import { GTOnTimePerformanceComponent } from '../Report/GTOnTimePerformanceComponent';
import { AirlineScorecardReportComponent } from '../Report/AirlineScorecardReportComponent'
import { AirlineRideActivityReportComponent } from './AirlineRideActivityReportComponent';
import { VehiclesProductivityReportComponent } from './VehiclesProductivityReportComponent';
import { CompletedJobsComparisonReportComponent } from '../Report/CompletedJobsComparisonReportComponent'
import { CumulativeSalesReportComponent } from '../Report/CumulativeSalesReportComponent'
import { ReservationAgentProductivityReportComponent } from '../Report/ReservationAgentProductivityReportComponent'
import { VehicleReimbursmentSummaryComponent } from '../Report/VehicleReimbursmentSummaryComponent'
import { VehicleLoanAccountLedgerComponent } from '../Report/VehicleLoanAccountLedgerComponent'
import { VehicleLoanReportComponent } from '../Report/VehicleLoanReportComponent'
import { AgentCommissionReportComponent } from '../Report/AgentCommissionReportComponent'
import { AuditReportComponent } from '../Report/AuditReportComponent'
import { BillingReconsilationReportComponent } from '../Report/BillingReconsilationReportComponent'
import { VehicleCommissionReportComponent } from '../Report/VehicleCommissionReportComponent'
import { VehicleDeductionReportComponent } from '../Report/VehicleDeductionReportComponent'
import { VehicleDepositDetailsReportComponent } from '../Report/VehicleDepositDetailsReportComponent'
import { VehicleReimbursmentAddOnsReportComponent } from '../Report/VehicleReimbursmentAddOnsReportComponent'
import { VehicleReimbDeductionHistoryReportComponent } from '../Report/VehicleReimbDeductionHistoryReportComponent'
import { VehicleReimbReceivableReportComponent } from '../Report/VehicleReimbReceivableReportComponent'
import { VehicleReimbRevenueReportComponent } from '../Report/VehicleReimbRevenueReportComponent'
import { FranchiseeDescripancyReportComponent } from '../Report/FranchiseeDescripancyReportComponent'
import { CustomerInvoiceSummaryReportComponent } from '../Report/CustomerInvoiceSummaryReportComponent'
import { AccountPaymentsReportComponent } from '../Report/AccountPaymentsReportComponent'
import { RevenueSummaryGraphReportComponent } from '../Report/RevenueSummaryGraphReportComponent'
import { RideCommissionCashCCReportComponent } from '../Report/RideCommissionCashCCReportComponent'
import { DataEntryAuditReportComponent } from '../Report/DataEntryAuditReportComponent'
import { NoShowReservationReportComponent } from '../Report/NoShowReservationReportComponent'
import { VehiclePayrollExceptionsComponent } from '../Report/VehiclePayrollExceptionsComponent'
import { TaxComponent } from '../Report/TaxComponent'
import { AccountRideDetailsReportOnlyComponent } from '../Report/AccountRideDetailsReportOnlyComponent'
import { RideListingRevenueReportComponent } from '../Report/RideListingRevenueReportComponent'
import { MeetAndGreetReportComponent } from '../Report/MeetAndGreetReportComponent'
import { EstimateReimbursementReportComponent } from '../Report/EstimateReimbursementReportComponent'
import { AccountSummaryPaymentTypeWiseComponent } from '../Report/AccountSummaryPaymentTypeWiseComponent'
import { RideReconsilationReportComponent } from '../Report/RideReconsilationReportComponent'
import { InvoiceDueSummaryReportComponent } from '../Report/InvoiceDueSummaryReportComponent'
import { RidesApprovedReportComponent } from '../Report/RidesApprovedReportComponent'
import { RidesBilledReportComponent } from '../Report/RidesBilledReportComponent'
import { RidesUnApprovedReportComponent } from '../Report/RidesUnApprovedReportComponent'
import { CustomerCommissionRideReportComponent } from '../Report/CustomerCommissionRideReportComponent'
import { RideListingCashCCReportComponent } from '../Report/RideListingCashCCReportComponent'
import { DispatchComponent } from '../Report/DispatchComponent'
import { RatesComponent } from '../Report/RatesComponent'
import { DriverCommissionComponent } from '../Report/DriverCommissionComponent'
import { DriverPaidReportComponent } from '../Report/DriverPaidReportComponent'
import { DeferredRevenueReportComponent } from '../Report/DeferredRevenueReportComponent'
import { CreditcardHistoryReportComponent } from '../Report/CreditcardHistoryReportComponent'
import { OpenReservationsReportComponent } from '../Report/OpenReservationsReportComponent'
import { CancelledReservationsReportComponent } from '../Report/CancelledReservationsReportComponent'
import { ClosedReservationsReportComponent } from '../Report/ClosedReservationsReportComponent'
import { DeductionsComponent } from '../Report/DeductionsComponent'
import { VehicleJobHistoryReportComponent } from '../Report/VehicleJobHistoryReportComponent'
import { AccountRidePaxReportComponent } from '../Report/AccountRidePaxReportComponent'
import { DailyJobCountComponent } from '../Report/DailyJobCountComponent'
import { RidesCloseoutReportComponent } from '../Report/RidesCloseoutReportComponent'
import { ARPreviewInvoiceReportComponent } from '../Report/ARPreviewInvoiceReportComponent'
import { BillingCharterInvoiceReportComponent } from '../Report/BillingCharterInvoiceReportComponent'
import { InvoicePaidReportCompnent } from '../Report/InvoicePaidReportCompnent'
import { DriverDutyReportCompnent } from '../Report/DriverDutyReportCompnent'

const $ = window.$;

const position = { of: '#historydiv' };
export class ReportComponent extends Component {
    static displayName = ReportComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            loadPanelVisible: false,
            totalReports: [],
            searchValue: '',
            filteredReport: [],
            isReportDisplayList: true,
            isReportHeadingDisplay: true,
        }

        this.getReportList = this.getReportList.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.filterlist = this.filterlist.bind(this);
        this.handleReportEvent = this.handleReportEvent.bind(this);
        this.handleReportDisplay = this.handleReportDisplay.bind(this);
        this.handleFilterReportType = this.handleFilterReportType.bind(this);
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true
        })
        await this.getReportList();
        this.setState({
            loadPanelVisible: false
        })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
        if (value.trim() != "") {
            var textFilterArray = this.state.totalReports.filter(f => f.reportName.toUpperCase().includes(value.trim().toUpperCase()));
            this.setState({
                filteredReport: this.filterlist(textFilterArray)
            });

        } else {
            this.setState({
                filteredReport: this.filterlist(this.state.totalReports)
            });
        }
    }

    handleReportDisplay(e) {
        if (this.state.isReportDisplayList) {
            this.setState({ isReportDisplayList: false });
        }
        else {
            this.setState({ isReportDisplayList: true });
        }
    }

    handleFilterReportType(e) {
        e.preventDefault();
        var value = e.currentTarget.innerText;
        if (value == "All") {
            $('.filter').show('1000');
            this.setState({ isReportHeadingDisplay: true });
        }
        else {
            this.setState({ isReportHeadingDisplay: false });
            $(".filter").not('.' + value).hide('3000');
            $('.filter').filter('.' + value).show('3000');
        }

        if ($(".filter-button").removeClass("active")) {
            $(this).removeClass("active");
        }

        $(this).addClass("active");
    }

    clearSearch(e) {
        e.preventDefault();
        this.setState({
            searchValue: '',
            filteredReport: this.filterlist(this.state.totalReports)
        });
    }

    groupArrayOfObjects(list, key) {
        return list.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    async getReportList() {
        var request = new Object();
        request.ActionTypeId = 1;
        try {
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('report-api/list', requestParams);
            const data = await response.json();
            this.setState({
                totalReports: data.reportList,
                filteredReport: this.filterlist(data.reportList)
            });

        } catch (e) {
            console.log(e);
        }
    }

    filterlist(datalist) {
        var filterArray = [];
        if (datalist.length == 0) {
            return filterArray;
        }
        var groupReport = this.groupArrayOfObjects(datalist, "reportType")

        if (groupReport.BILLING != undefined) {
            if (groupReport.BILLING.length > 0) {
                var rpTypeObj = new Object();
                rpTypeObj.reportTypeId = groupReport.BILLING[0].reportTypeId;
                rpTypeObj.reportType = groupReport.BILLING[0].reportType;
                rpTypeObj.reportList = groupReport.BILLING;
                filterArray.push(rpTypeObj);
            }
        }

        if (groupReport.RESERVATION != undefined) {
            if (groupReport.RESERVATION.length > 0) {
                var rpTypeObj = new Object();
                rpTypeObj.reportTypeId = groupReport.RESERVATION[0].reportTypeId;
                rpTypeObj.reportType = groupReport.RESERVATION[0].reportType;
                rpTypeObj.reportList = groupReport.RESERVATION;
                filterArray.push(rpTypeObj);
            }
        }

        if (groupReport.DISPATCHING != undefined) {
            if (groupReport.DISPATCHING.length > 0) {
                var rpTypeObj = new Object();
                rpTypeObj.reportTypeId = groupReport.DISPATCHING[0].reportTypeId;
                rpTypeObj.reportType = groupReport.DISPATCHING[0].reportType;
                rpTypeObj.reportList = groupReport.DISPATCHING;
                filterArray.push(rpTypeObj);
            }
        }

        if (groupReport.FRANCHISEE != undefined) {
            if (groupReport.FRANCHISEE.length > 0) {
                var rpTypeObj = new Object();
                rpTypeObj.reportTypeId = groupReport.FRANCHISEE[0].reportTypeId;
                rpTypeObj.reportType = groupReport.FRANCHISEE[0].reportType;
                rpTypeObj.reportList = groupReport.FRANCHISEE;
                filterArray.push(rpTypeObj);
            }
        }

        if (groupReport.ACCOUNTS != undefined) {
            if (groupReport.ACCOUNTS.length > 0) {
                var rpTypeObj = new Object();
                rpTypeObj.reportTypeId = groupReport.ACCOUNTS[0].reportTypeId;
                rpTypeObj.reportType = groupReport.ACCOUNTS[0].reportType;
                rpTypeObj.reportList = groupReport.ACCOUNTS;
                filterArray.push(rpTypeObj);
            }
        }

        if (groupReport.ADMINISTRATION != undefined) {
            if (groupReport.ADMINISTRATION.length > 0) {
                var rpTypeObj = new Object();
                rpTypeObj.reportTypeId = groupReport.ADMINISTRATION[0].reportTypeId;
                rpTypeObj.reportType = groupReport.ADMINISTRATION[0].reportType;
                rpTypeObj.reportList = groupReport.ADMINISTRATION;
                filterArray.push(rpTypeObj);
            }
        }

        if (groupReport.MANAGEMENT != undefined) {
            if (groupReport.MANAGEMENT.length > 0) {
                var rpTypeObj = new Object();
                rpTypeObj.reportTypeId = groupReport.MANAGEMENT[0].reportTypeId;
                rpTypeObj.reportType = groupReport.MANAGEMENT[0].reportType;
                rpTypeObj.reportList = groupReport.MANAGEMENT;
                filterArray.push(rpTypeObj);
            }
        }
        return filterArray;
    }

    handleReportEvent(e, _reportName) {
        e.preventDefault();
        try {
            let compReportName = _reportName.replace(/ +/g, "").replace(":", "").replace(/-+/g, "").replace("(", "").replace(")", "").replace("/", "").replace("[", "").replace("]", "").replace("&", "").toUpperCase();
            switch (compReportName) {
                case "CHARTERINVOICEREPORTBASEFARE":
                    this.props.history.push({
                        pathname: '/reports/charterinvoicereport',
                        state: { sourceUrl: "/report", sourceState: this.state }
                    })
                    break;
                case "ACCOUNTSUMMARYPAYMENTTYPEWISE":
                    //this.props.history.push({
                    //    pathname: '/reports/AccountSummaryPaymentTypeWise',
                    //    state: { sourceUrl: "/report", sourceState: this.state }
                    //})
                    this.refs.accountSummaryPaymentTypeWiseComponent.showModal();
                    break;
                case "CUSTOMERCOMMISSION":
                    //this.props.history.push({
                    //    pathname: '/reports/customerridecommission',
                    //    state: { sourceUrl: "/report", sourceState: this.state }
                    //})
                    this.refs.customerCommissionRideReportComponent.showModal();
                    break;
                case "INVOICEDUESUMMARY306090DAYS":
                    //this.props.history.push({
                    //    pathname: '/reports/invoiceduesummary',
                    //    state: { sourceUrl: "/report", sourceState: this.state }
                    //})
                    this.refs.invoiceDueSummaryReportComponent.showModal();
                    break;
                case "VEHICLEPAYREPORTPAYROLLPERIODWISE":
                    this.refs.vehiclePayReportPayrollPeriodwise.showModal();
                    break;
                case "VEHICLEPAYREPORTSUMMARYDETAILS":
                    this.refs.vehiclePayReportSummaryDetailsComponent.showModal();
                    break;
                case "RIDERECONSILATIONSTATEMENT":
                    //this.props.history.push({
                    //    pathname: '/reports/ridereconsilation',
                    //    state: { sourceUrl: "/report", sourceState: this.state }
                    //})
                    this.refs.rideReconsilationReportComponent.showModal();
                    break;
                case "RIDESAPPROVED":
                    //this.props.history.push({
                    //    pathname: '/reports/ridesapproved',
                    //    state: { sourceUrl: "/report", sourceState: this.state }
                    //})
                    this.refs.ridesApprovedReportComponent.showModal();
                    break;
                case "SALESACTIVITY":
                    if (this.refs.salesActivityComponent != undefined) {
                        this.refs.salesActivityComponent.showModal();
                    }
                    break;
                case "INVOICERECEIPTVOUCHER":
                    this.props.history.push({
                        pathname: '/reports/invoicereceiptvoucher',
                        state: { sourceUrl: "/report", sourceState: this.state }
                    })
                    break;
                case "INVOICEREPORT":
                    this.refs.invoiceReportComponent.showModal();
                    break;
                case "IATAREPORT":
                    this.refs.iataInvoiceReportComponent.showModal();
                    break;
                case "WORKCOMPENSATIONREPORT":
                    this.refs.workCompensationReportComponent.showModal();
                    break;
                case "RIDECOMMISSIONDETAILS":
                    this.refs.rideCommissionDetailsReportComponent.showModal();
                    break;
                case "RIDESUNAPPROVED":
                    //this.props.history.push({
                    //    pathname: '/reports/ridesunapproved',
                    //    state: { sourceUrl: "/report", sourceState: this.state }
                    //})
                    this.refs.ridesUnApprovedReportComponent.showModal();
                    break;
                case "RIDESBILLED":
                    //this.props.history.push({
                    //    pathname: '/reports/ridesbilled',
                    //    state: { sourceUrl: "/report", sourceState: this.state }
                    //})
                    this.refs.ridesBilledReportComponent.showModal();
                    break;
                case "CONGESTIONREPORT":
                    this.refs.congestionReportComponent.showModal();
                    break;
                case "DRIVERLOGINHISTORY":
                    this.refs.driverLoginHistoryReportComponent.showModal();
                    break;
                case "INVOICESUMMARYREPORTPICKUPWISE":
                    this.refs.invoiceSummaryReportPickupwiseComponent.showModal();
                    break;
                case "INVOICESUMMARYGRAPH":
                    this.refs.invoiceSummaryGraphComponent.showModal();
                    break;
                case "MONTHLYBUDGETCOMPARISON":
                    this.refs.monthlyBudgetComparisonReportComponent.showModal();
                    break;
                case "DRIVERWORKHOURSHISTORY":
                    this.refs.driverWorkHourHistoryReportComponent.showModal();
                    break;
                case "CUSTOMERRIDEACTIVITYLOG":
                    this.refs.customerRideActivityReportComponent.showModal();
                    break;
                case "REVENUEPROFITLOSS":
                    this.refs.revenueProfitLossComponent.showModal();
                    break;
                case "REVENUEYEARLYCOMPARISON":
                    this.refs.revenueYearlyComparisonComponent.showModal();
                    break;
                case "GTONTIMEPERFORMANCE":
                    this.refs.gTOnTimePerformanceComponent.showModal();
                    break;
                case "COMPLETEDJOBSCOMPARISON":
                    this.refs.completedJobsComparisonReportComponent.showModal();
                    break;
                case "CUMULATIVESALESREPORT":
                    this.refs.cumulativeSalesReportComponent.showModal();
                    break;
                case "DISPATCHERLOGINWISEPRODUCTIVITY":
                    this.props.history.push({
                        pathname: '/reports/dispatcherproductivity',
                        state: { sourceUrl: "/report", sourceState: this.state }
                    })
                    break;
                case "AIRLINESCORECARD":
                    this.refs.airlineScorecardReportComponent.showModal();
                    break;
                case "AIRLINERIDEACTIVITY":
                    this.refs.airlineRideActivityReportComponent.showModal();
                    break;
                case "VEHICLEPRODUCTIVITY":
                    this.refs.vehiclesProductivityReportComponent.showModal();
                    break;
                case "VEHICLEREIMBURSEMENTSUMMARY":
                    this.refs.vehicleReimbursmentSummaryComponent.showModal(1);
                    break;
                case "VEHICLETRIPFARESUMMARY":
                    this.props.history.push({
                        pathname: '/reports/vehicletripfare-summary',
                        state: { sourceUrl: "/report", sourceState: this.state }
                    })
                    break;
                case "RESERVATIONAGENTLOGINWISEPRODUCTIVITY":
                    this.props.history.push({
                        pathname: '/reports/reservationagentproductivity',
                        state: { sourceUrl: "/report", sourceState: this.state }
                    })
                    break;
                case "AGENTCOMMISSION":
                    this.refs.agentCommissionReportComponent.showModal();
                    break;
                case "AUDITREPORT":
                    this.refs.auditReportComponent.showModal();
                    break;
                case "BILLINGRECONSILATIONREPORT":
                    this.refs.billingReconsilationReportComponent.showModal();
                    break;
                case "VEHICLECOMMISSION":
                    this.refs.vehicleCommissionReportComponent.showModal();
                    break;
                case "VEHICLEDEDUCTIONREPORT":
                    this.refs.vehicleDeductionReportComponent.showModal();
                    break;
                case "VEHICLEDEPOSITDETAILS":
                    this.refs.vehicleDepositDetailsReportComponent.showModal();
                    break;
                case "INVOICESALES":
                    this.props.history.push({
                        pathname: '/reports/invoicesales',
                        state: { sourceUrl: "/report", sourceState: this.state }
                    })
                    break;
                case "VEHICLEREIMBURSEMENTSUMMARYREPORT":
                    this.refs.vehicleReimbursmentSummaryComponent.showModal(2);
                    break;
                case "VEHICLEREIMBURSEMENTUFBALANCEREPORT":
                    this.refs.vehicleReimbursmentSummaryComponent.showModal(3);
                    break;
                case "WEEKLYREVENUEREPORT":
                    this.props.history.push({
                        pathname: '/reports/weeklyrevenue-summary',
                        state: { sourceUrl: "/report", sourceState: this.state }
                    })
                    break;
                case "VEHICLELOANACLEDGER":
                    this.refs.vehicleLoanAccountLedgerComponent.showModal();
                    break;
                case "VEHICLELOANREPORT":
                    this.refs.vehicleLoanReportComponent.showModal();
                    break;
                case "VEHICLEREIMBURSEMENTADDONS":
                    this.refs.vehicleReimbursmentAddOnsReportComponent.showModal();
                    break;
                case "VEHICLEREIMBURSEMENTDEDUCTIONHISTORY":
                    this.refs.vehicleReimbDeductionHistoryReportComponent.showModal();
                    break;
                case "VEHICLEREIMBURSEMENTRECEIVABLE":
                    this.refs.vehicleReimbReceivableReportComponent.showModal();
                    break;
                case "VEHICLEREIMBURSEMENTREVENUE":
                    this.refs.vehicleReimbRevenueReportComponent.showModal();
                    break;
                case "FRDESCRIPANCYREPORT":
                    this.refs.franchiseeDescripancyReportComponent.showModal();
                    break;
                case "CUSTOMERINVOICESUMMARY":
                    this.refs.customerInvoiceSummaryReportComponent.showModal();
                    break;
                case "PAYMENTS":
                    this.refs.accountPaymentsReportComponent.showModal();
                    break;
                case "REVENUESUMMARYGRAPH":
                    this.refs.revenueSummaryGraphReportComponent.showModal();
                    break;
                case "DATAENTRYAUDITREPORT":
                    this.refs.dataEntryAuditReportComponent.showModal();
                    break;
                case "ACCOUNTRIDEDETAILS":
                    this.props.history.push({
                        pathname: '/reports/account-ride-details',
                        state: { sourceUrl: "/report", sourceState: this.state }
                    })
                    break;
                case "ACCOUNTRIDEDETAILSREPORTONLY":
                    this.refs.accountRideDetailsReportOnlyComponent.showModal();
                    break;
                case "DRIVELOGINWISERIDESUMMARY":
                    this.props.history.push({
                        pathname: '/reports/driver-loginwise-ride-summary',
                        state: { sourceUrl: "/report", sourceState: this.state }
                    })
                    break;
                case "RIDELISTINGREVENUE":
                    this.refs.rideListingRevenueReportComponent.showModal();
                    break;
                case "VEHICLEPAYREGISTER":
                    this.refs.auditReportComponent.showModal();
                    break;
                case "VEHICLEPAYROLLEXCEPTIONS":
                    this.refs.vehiclePayrollExceptionsComponent.showModal();
                    break;
                case "TAX":
                    this.refs.taxComponent.showModal();
                    break;
                case "RIDECOMMISSIONCASHCC":
                    this.refs.rideCommissionCashCCReportComponent.showModal();
                    break;
                case "NOSHOWRESERVATION":
                    this.refs.noShowReservationReportComponent.showModal();
                    break;
                case "RIDELISTINGCASHCC":
                    this.refs.rideListingCashCCReportComponent.showModal();
                    break;
                case "RIDESTATUSWISESUMMARY":
                    this.props.history.push({
                        pathname: '/reports/statuswise-ride-summary',
                        state: { sourceUrl: "/report", sourceState: this.state }
                    })
                    break;
                case "RIDESTOBEDISPATCHED":
                    this.refs.dispatchComponent.showModal("1");
                    break;
                case "RIDESDISPATCHED":
                    this.refs.dispatchComponent.showModal("2");
                    break;
                case "SCHEDULEDRIDESNEXT8HOURS":
                    this.refs.dispatchComponent.showModal("3");
                    break;
                case "SCHEDULEDRIDESBEYOND8HOURS":
                    this.refs.dispatchComponent.showModal("4");
                    break;
                case "TRACKLOG":
                    this.props.history.push({
                        pathname: '/reports/tracklog',
                        state: { sourceUrl: "/report", sourceState: this.state }
                    })
                    break;
                case "MEETANDGREETRIDES":
                    this.refs.meetAndGreetReportComponent.showModal();
                    break;
                case "ESTIMATEREIMBURSEMENT":
                    this.refs.estimateReimbursementReportComponent.showModal();
                    break;
                case "DRIVERLOGINWISERIDESUMMARY":
                    //this.refs.driverLoginwiseRideSummaryReportComponent.showModal();
                    this.props.history.push({
                        pathname: '/reports/driver-loginwise-ride-summary',
                        state: { sourceUrl: "/report", sourceState: this.state }
                    })
                    break;
                case "DRIVERPAID":
                    this.refs.driverPaidReportComponent.showModal();
                    break;
                case "ZONALRATES":
                    this.refs.ratesComponent.showModal("1");
                    break;
                case "HOURSRATES":
                    this.refs.ratesComponent.showModal("2");
                    break;
                case "DRIVERCOMMISSION":
                    this.refs.driverCommissionComponent.showModal();
                    break;
                case "DEFFEREDREVENUEREPORT":
                    this.refs.deferredRevenueReportComponent.showModal();
                    break;
                case "CREDITCARD":
                    this.refs.creditcardHistoryReportComponent.showModal();
                    break;
                case "OPENRESERVATION":
                    this.refs.openReservationsReportComponent.showModal();
                    break;
                case "CANCELLEDRESERVATION":
                    this.refs.cancelledReservationReportComponent.showModal();
                    break;
                case "CLOSEDRESERVATION":
                    this.refs.closedReservationsReportComponent.showModal();
                    break;
                case "DEDUCTIONS":
                    this.refs.deductionsComponent.showModal();
                    break;
                case "VEHICLEJOBHISTORY":
                    this.refs.vehicleJobHistoryReportComponent.showModal();
                    break;
                case "ACCOUNTS":
                    this.refs.accountRidePaxReportComponent.showModal();
                    break;
                case "DAILYJOBCOUNT":
                    this.refs.dailyJobCountComponent.showModal();
                    break;
                case "RIDEDETAILSHOURLY":
                    this.props.history.push({
                        pathname: '/reports/hourly-ride-report',
                        state: { sourceUrl: "/report", sourceState: this.state }
                    })
                    break;
                case "RIDECLOSEOUTREPORT":
                    this.refs.ridesCloseoutReportComponent.showModal();
                    break;
                case "PREVIEWINVOICE":
                    this.refs.aRPreviewInvoiceReportComponent.showModal();
                    break;
                case "CHARTERINVOICE":
                    this.refs.billingCharterInvoiceReportComponent.showModal();
                    break;
                case "INVOICEPAID":
                    this.refs.invoicePaidReportCompnent.showModal();
                    break;
                case "DRIVERDUTYREPORT":
                    this.refs.driverDutyReportCompnent.showModal();
                    break;
                default:
                    break;
            }
        } catch (e) {

        }
    }
    render() {
        return (
            <div className="content-wrapper" style={{ backgroundColor: '#ffffff' }}>
                <div className="content-header mb-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                <h1 className="text-dark">Report</h1>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-right">
                                <div className="row">
                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                        <div className="btn-group">
                                            <button type="button" className="btn btn-default btn-sm" title={this.state.isReportDisplayList == true ? "Click to switch Grid View" : "Click to switch List View"} onClick={this.handleReportDisplay}>{this.state.isReportDisplayList == true ? <i className="fas fa-list"></i> : <i className="fas fa-th"></i>}</button>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                        <input type="text" className="form-control form-control-sm" name="searchValue" placeholder="Search report" maxLength="50" title="Search" value={this.state.searchValue} onChange={this.handleInputChange} />
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                        <ol className="breadcrumb float-sm-right" style={{ width: 100 + '%' }}>
                                            <NavItem style={{ width: 100 + '%' }}><NavLink tag={Link} to="#" onClick={(e) => { this.clearSearch(e) }} className="btn btn-block btn-outline-secondary btn-sm" title="Add User"><i className="fas fa-eraser"></i> Clear</NavLink></NavItem>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content reports">
                    {this.state.isReportDisplayList == false ?
                        <div className="content">
                            <div className="card card-primary card-outline card-outline-tabs account-settings mt-2">
                                <div className="card-header p-0 border-bottom-0">
                                    <ul className="nav" id="custom-tabs-tab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" id="custom-tabs-all-tab" data-toggle="pill" href="#custom-tabs-all" role="tab" aria-controls="custom-tabs-all" aria-selected="true">All</a>
                                        </li>
                                        {
                                            (this.state.filteredReport.length > 0 &&
                                                this.state.filteredReport.map((item, j) =>
                                                    <li className="nav-item">
                                                        <a className="nav-link" id={"custom-tabs-" + item.reportType + "-tab"} data-toggle="pill" href={"#custom-tabs-" + item.reportType + ""} role="tab" aria-controls={"custom-tabs-" + item.reportType + ""} aria-selected="false">{item.reportType}</a>
                                                    </li>
                                                )
                                            )}
                                    </ul>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content" id="custom-tabs-tabContent">
                                        <div className="report-card-view text-center tab-pane fade active show" id="custom-tabs-all" role="tabpanel" aria-labelledby="custom-tabs-all-tab">
                                            {
                                                (this.state.totalReports.length > 0 &&
                                                    this.state.totalReports.map((item, j) =>
                                                        <div className="card text-left">
                                                            <div className="card-body">
                                                                {/*{this.state.isReportHeadingDisplay == true ? <span className="text-xs">{item.reportType}</span> : ""}*/}
                                                                <span className="text-xs">{item.reportType}</span>
                                                                <h6><strong>{item.reportName}</strong></h6>
                                                                <p className="text-sm">&nbsp;</p>
                                                            </div>
                                                            <div className="btnReportLink"><a className="btn-view" href="#" onClick={(e) => { this.handleReportEvent(e, item.reportName) }} ><i className="fas fa-eye"></i></a></div>
                                                            <div className="circleNormal"></div>
                                                            <div className="circleHover"></div>
                                                        </div>
                                                    )
                                                )}
                                        </div>
                                        {
                                            (this.state.filteredReport.length > 0 &&
                                                this.state.filteredReport.map((item, j) => <div className="report-card-view text-center tab-pane fade" id={"custom-tabs-" + item.reportType} role="tabpanel" aria-labelledby={"custom-tabs-" + item.reportType + "-tab"}> {
                                                    item.reportList.length > 0 && item.reportList.map((tag, k) =>
                                                        <div className="card text-left">
                                                            <div className="card-body">
                                                                {/*{this.state.isReportHeadingDisplay == true ? <span className="text-xs">{item.reportType}</span> : ""}*/}
                                                                <span className="text-xs">{item.reportType}</span>
                                                                <h6><strong>{tag.reportName}</strong></h6>
                                                                <p className="text-sm">&nbsp;</p>
                                                            </div>
                                                            <div className="btnReportLink"><a className="btn-view" href="#" onClick={(e) => { this.handleReportEvent(e, tag.reportName) }} ><i className="fas fa-eye"></i></a></div>
                                                            <div className="circleNormal"></div>
                                                            <div className="circleHover"></div>
                                                        </div>
                                                    )}
                                                </div>
                                                )
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div> : null
                    }

                    {/*{this.state.isReportDisplayList == false ?*/}
                    {/*    <div className="row displayNone">*/}
                    {/*        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">*/}
                    {/*            <div align="center">*/}
                    {/*                <button className="btn btn-outline-secondary filter-button" data-filter="ALL" id="reportTypeButton" name="ALL" onClick={this.handleFilterReportType}> All</button>*/}
                    {/*                {*/}
                    {/*                    (this.state.filteredReport.length > 0 &&*/}
                    {/*                        this.state.filteredReport.map((item, j) =>*/}
                    {/*                            <button className="btn btn-outline-secondary filter-button" data-filter={item.reportType} id="reportTypeButton" name={item.reportType} onClick={this.handleFilterReportType}> {item.reportType}</button>*/}
                    {/*                        )*/}
                    {/*                    )}*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 displayNone">*/}
                    {/*            {*/}
                    {/*                (this.state.filteredReport.length > 0 &&*/}
                    {/*                    this.state.filteredReport.map((item, j) => <div className={"report-card-view text-center filter " + item.reportType}> {*/}
                    {/*                        item.reportList.length > 0 && item.reportList.map((tag, k) =>*/}
                    {/*                            <div className="card text-left" key={k}>*/}
                    {/*                                <div className="card-body">*/}
                    {/*                                    */}{/*{this.state.isReportHeadingDisplay == true ? <span className="text-xs">{item.reportType}</span> : ""}*/}
                    {/*                                    <span className="text-xs">{item.reportType}</span>*/}
                    {/*                                    <h6><strong>{tag.reportName}</strong></h6>*/}
                    {/*                                    <p className="text-sm">&nbsp;</p>*/}
                    {/*                                </div>*/}
                    {/*                                <div className="btnReportLink"><a className="btn-view" href="#" onClick={(e) => { this.handleReportEvent(e, tag.reportName) }} ><i className="fas fa-eye"></i></a></div>*/}
                    {/*                                <div className="circleNormal"></div>                                                    */}
                    {/*                                <div className="circleHover"></div>*/}
                    {/*                            </div>*/}
                    {/*                        )}*/}
                    {/*                    </div>*/}
                    {/*                    )*/}
                    {/*                )}*/}
                    {/*        </div>*/}
                    {/*    </div> : null*/}
                    {/*}*/}
                    {this.state.isReportDisplayList == true ?
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                {(this.state.filteredReport.length == 0) && <div className="card mt-3">
                                    <div className="card-body">
                                        <div className="row">
                                            <span className="text-primary">&nbsp;&nbsp;&nbsp;{this.state.loadPanelVisible == true ? "Loading Reports.." : "   Reports not found! "} </span>
                                        </div>
                                    </div>
                                </div>}
                                {(this.state.filteredReport.length > 0) && <div>
                                    {this.state.filteredReport.map((item, j) =>
                                        <div className="card report-list-view" key={j}>
                                            <div className="card-body">
                                                <h6 className="m-0"><strong>{item.reportType}</strong></h6>
                                                <hr />
                                                <div className="report-item-container">
                                                    {item.reportList.length > 0 && item.reportList.map((tag, k) =>
                                                        <span className="report-item" key={k}>
                                                            <a href="#" onClick={(e) => { this.handleReportEvent(e, tag.reportName) }} ><i className="fas fa-file-pdf"></i> {tag.reportName}</a>
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>}
                            </div>
                        </div> : null
                    }
                </div>



                <VehiclePayReportPayrollPeriodwise ref="vehiclePayReportPayrollPeriodwise" />
                <IATAInvoiceReportComponent ref="iataInvoiceReportComponent" />
                <WorkCompensationReportComponent ref="workCompensationReportComponent" />
                <RideCommissionDetailsReportComponent ref="rideCommissionDetailsReportComponent" />
                <DriverLoginHistoryReportComponent ref="driverLoginHistoryReportComponent" />
                <CongestionReportComponent ref="congestionReportComponent" />
                <SalesActivityComponent ref="salesActivityComponent" />
                <VehiclePayReportSummaryDetailsComponent ref="vehiclePayReportSummaryDetailsComponent" />
                <InvoiceReportComponent ref="invoiceReportComponent" />
                <InvoiceSummaryReportPickupwiseComponent ref="invoiceSummaryReportPickupwiseComponent" />
                <InvoiceSummaryGraphComponent ref="invoiceSummaryGraphComponent" />
                <MonthlyBudgetComparisonReportComponent ref="monthlyBudgetComparisonReportComponent" />
                <DriverWorkHourHistoryReportComponent ref="driverWorkHourHistoryReportComponent" />
                <CustomerRideActivityReportComponent ref="customerRideActivityReportComponent" />
                <RevenueProfitLossComponent ref="revenueProfitLossComponent" />
                <RevenueYearlyComparisonComponent ref="revenueYearlyComparisonComponent" />
                <GTOnTimePerformanceComponent ref="gTOnTimePerformanceComponent" />
                <CompletedJobsComparisonReportComponent ref="completedJobsComparisonReportComponent" />
                <CumulativeSalesReportComponent ref="cumulativeSalesReportComponent" />
                <AirlineScorecardReportComponent ref="airlineScorecardReportComponent" />
                <AirlineRideActivityReportComponent ref="airlineRideActivityReportComponent" />
                <VehiclesProductivityReportComponent ref="vehiclesProductivityReportComponent" />
                <VehicleReimbursmentSummaryComponent ref="vehicleReimbursmentSummaryComponent" />
                <AgentCommissionReportComponent ref="agentCommissionReportComponent" />
                <AuditReportComponent ref="auditReportComponent" />
                <BillingReconsilationReportComponent ref="billingReconsilationReportComponent" />
                <VehicleCommissionReportComponent ref="vehicleCommissionReportComponent" />
                <VehicleDeductionReportComponent ref="vehicleDeductionReportComponent" />
                <VehicleDepositDetailsReportComponent ref="vehicleDepositDetailsReportComponent" />
                <VehicleLoanAccountLedgerComponent ref="vehicleLoanAccountLedgerComponent" />
                <VehicleLoanReportComponent ref="vehicleLoanReportComponent" />
                <VehicleReimbursmentAddOnsReportComponent ref="vehicleReimbursmentAddOnsReportComponent" />
                <VehicleReimbDeductionHistoryReportComponent ref="vehicleReimbDeductionHistoryReportComponent" />
                <VehicleReimbReceivableReportComponent ref="vehicleReimbReceivableReportComponent" />
                <VehicleReimbRevenueReportComponent ref="vehicleReimbRevenueReportComponent" />
                <FranchiseeDescripancyReportComponent ref="franchiseeDescripancyReportComponent" />
                <CustomerInvoiceSummaryReportComponent ref="customerInvoiceSummaryReportComponent" />
                <DataEntryAuditReportComponent ref="dataEntryAuditReportComponent" />
                <AccountPaymentsReportComponent ref="accountPaymentsReportComponent" />
                <RevenueSummaryGraphReportComponent ref="revenueSummaryGraphReportComponent" />
                <RideCommissionCashCCReportComponent ref="rideCommissionCashCCReportComponent" />
                <NoShowReservationReportComponent ref="noShowReservationReportComponent" />
                <AccountRideDetailsReportOnlyComponent ref="accountRideDetailsReportOnlyComponent" />
                <RideListingRevenueReportComponent ref="rideListingRevenueReportComponent" />
                <VehiclePayrollExceptionsComponent ref="vehiclePayrollExceptionsComponent" />
                <TaxComponent ref="taxComponent" />
                <DispatchComponent ref="dispatchComponent" />
                <MeetAndGreetReportComponent ref="meetAndGreetReportComponent" />
                <EstimateReimbursementReportComponent ref="estimateReimbursementReportComponent" />
                <AccountSummaryPaymentTypeWiseComponent ref="accountSummaryPaymentTypeWiseComponent" />
                <RideReconsilationReportComponent ref="rideReconsilationReportComponent" />
                <InvoiceDueSummaryReportComponent ref="invoiceDueSummaryReportComponent" />
                <RidesApprovedReportComponent ref="ridesApprovedReportComponent" />
                <RidesBilledReportComponent ref="ridesBilledReportComponent" />
                <RidesUnApprovedReportComponent ref="ridesUnApprovedReportComponent" />
                <CustomerCommissionRideReportComponent ref="customerCommissionRideReportComponent" />
                <RideListingCashCCReportComponent ref="rideListingCashCCReportComponent" />
                <RatesComponent ref="ratesComponent" />
                <DriverCommissionComponent ref="driverCommissionComponent" />
                <DriverPaidReportComponent ref="driverPaidReportComponent" />
                <DeductionsComponent ref="deductionsComponent" />
                <VehicleJobHistoryReportComponent ref="vehicleJobHistoryReportComponent" />
                <DeferredRevenueReportComponent ref="deferredRevenueReportComponent" />
                <CreditcardHistoryReportComponent ref="creditcardHistoryReportComponent" />
                <OpenReservationsReportComponent ref="openReservationsReportComponent" />
                <CancelledReservationsReportComponent ref="cancelledReservationReportComponent" />
                <ClosedReservationsReportComponent ref="closedReservationsReportComponent" />
                <AccountRidePaxReportComponent ref="accountRidePaxReportComponent" />
                <DailyJobCountComponent ref="dailyJobCountComponent" />
                <RidesCloseoutReportComponent ref="ridesCloseoutReportComponent" />
                <ARPreviewInvoiceReportComponent ref="aRPreviewInvoiceReportComponent" />
                <BillingCharterInvoiceReportComponent ref="billingCharterInvoiceReportComponent" />
                <InvoicePaidReportCompnent ref="invoicePaidReportCompnent" />
                <DriverDutyReportCompnent ref="driverDutyReportCompnent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}