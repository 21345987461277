import React, { Component } from 'react';

export class TestTemplateComponent extends Component {
    static displayName = TestTemplateComponent.name;

    render() {
        return (
            <div className="content-wrapper">

                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <h1 className="m-0 text-dark">Device</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <a to="#" className="btn btn-block btn-outline-secondary btn-sm" title="Add Device"><i className="fas fa-plus"></i> Add Device</a>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div class="row mb-5">
                        <div className="col">1</div>
                        <div className="col">2</div>
                        <div className="col">3</div>
                        <div className="col">4</div>
                    </div>
                </div>
            </div>
        );
    }
}