import React, { Component, createRef } from 'react';
import { AirportTransferComponent } from './AirportTransferComponent';
import { ContractTransferComponent } from './ContractTransferComponent';
import { CharterTransferComponent } from './CharterTransferComponent';
import { CustomerServiceTransferComponent } from './CustomerServiceTransferComponent';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { ReservationHistoryComponent } from '../Shared/ReservationHistoryComponent';
import { LocateVehicleComponent } from '../Shared/LocateVehicleComponent';
import { VoidReservationComponent } from '../Shared/VoidReservationComponent';
import { TripExceptionComponent } from '../Shared/TripExceptionComponent';
import { ReservationActualTimeComponent } from '../Shared/ReservationActualTimeComponent';
import { DocumentsComponent } from '../Shared/DocumentsComponent';
import { VoucherEmailComponent } from '../Shared/VoucherEmailComponent';
import Swal from 'sweetalert2';
import { SelectBox } from 'devextreme-react/select-box';
import { LoadPanel } from 'devextreme-react/load-panel';
const position = { of: '#bookingdiv' };
let editCustomerId = 0;
export class BookingComponent extends Component {
    static displayName = BookingComponent.name;

    constructor(props) {
        super(props);        
        const queryString = require('query-string');
        var parsed = queryString.parse(this.props.location.search);
        const newreservationId = parsed.reservationId;
        const newcustomerId = parsed.customerId;
        const transaction = parsed.transaction;

        this.state = {
            newreservationId: newreservationId,
            transactionId: transaction,
            newcustomerId: newcustomerId,
            isSubmited: false,
            isDuplicateSubmited: false,
            isSaveSubmited: false,
            customer: null,
            rideId: this.props.location.state == undefined ? 0 : this.props.location.state.rideId,

            dropdownCustomer: [],
            tripDetails: null,
            loadPanelVisible: false,

            sourceUrl: this.props.location.state == undefined ? "" : this.props.location.state.sourceUrl, // Send from calling paging (Search Reservation)
            sourceState: this.props.location.state == undefined ? "" : this.props.location.state.sourceState,  // Send from calling paging (Search Reservation)
            rateLookup: this.props.location.state == undefined ? null : (this.props.location.state.rateLookup == undefined ? null : this.props.location.state.rateLookup),
        };

        this.handleCustomerChange = this.handleCustomerChange.bind(this);
        this.handleRezSubmit = this.handleRezSubmit.bind(this);
        this.handleRezDuplicate = this.handleRezDuplicate.bind(this);
        this.handleClearControls = this.handleClearControls.bind(this);
        this.handleAttachments = this.handleAttachments.bind(this);
        this.getCustomerList = this.getCustomerList.bind(this);
        this.submitFunction = this.submitFunction.bind(this);
        this.duplicateFunction = this.duplicateFunction.bind(this);
        this.getTripDetails = this.getTripDetails.bind(this);

        this.goToBackPage = this.goToBackPage.bind(this);
        this.viewHistroy = this.viewHistroy.bind(this);
        this.locateVehicle = this.locateVehicle.bind(this);
        this.voidRecallTrip = this.voidRecallTrip.bind(this);
        this.copyReservation = this.copyReservation.bind(this);
        this.exceptionReservation = this.exceptionReservation.bind(this);
        this.actualTimeTrip = this.actualTimeTrip.bind(this);
        this.attachmentUpload = this.attachmentUpload.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.openEmailModal = this.openEmailModal.bind(this);
        this.handleDownload = this.handleDownload.bind(this);

        if (this.state.transactionId != null) {
            let strAlert = "";
            strAlert = "Your confirmation number is: <br><br>";
            strAlert += "\t" + this.state.newreservationId + "<br>";
            strAlert += "<br> Thank you for booking.";
            Swal.fire({
                icon: 'success',
                title: 'success',
                html: strAlert,
                width: "25%",
                allowOutsideClick: false
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    this.submitFunction(false);
                    window.history.pushState({}, null, '/reservations/');
                    if (this.state.sourceUrl != "") {
                        this.goToBackPage(); // method defined in BookingComponent
                    }
                }
            })
        }
    };

    goToBackPage() {
        this.props.history.push({
            pathname: this.state.sourceUrl,
            state: this.state.sourceState
        })
    }

    viewHistroy(e) {
        e.preventDefault();
        this.refs.reservationHistoryModal.showModal("Reservation", this.state.rideId);
    }

    exceptionReservation(e) {
        e.preventDefault();
        this.refs.tripExceptionModal.showModal(this.state.tripDetails.vehicleNo, this.state.rideId);
    }

    locateVehicle(e) {
        e.preventDefault();
        this.refs.locateVehicleModal.showModal(this.state.tripDetails.vehicleNo, this.state.rideId);
    }

    voidRecallTrip(e, isVoid) {
        e.preventDefault();
        if (isVoid == true) {
            this.refs.voidReservationModal.showModal("Void Reservation", this.state.rideId, "New Reservation");
        } else {
            this.refs.voidReservationModal.showModal("Recall Reservation", this.state.rideId, "New Reservation");
        }

    }

    actualTimeTrip(e) {
        e.preventDefault();
        this.refs.actualTimeModal.showModal("Reservation", this.state.rideId);
    }

    handleAttachments(e) {
        e.preventDefault();
        var rideArray = [];
        rideArray.push(this.state.rideId);
        this.refs.documentsComponent.showModal('Ride Id', rideArray, rideArray[0]);

    }

    attachmentUpload(rideArray) {
        this.refs.documentsComponent.showModal('Ride Id', rideArray, rideArray[0]);
    }

    async askShowFareConfirm() {
        let isResult = false;
        await Swal.fire({
            title: "<small>Fare Confirmation</small>",
            html: "Do you want to print/ show fare details in voucher?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    async handleDownload(e) {
        e.preventDefault();
        var isShowFare = false;
        const isConfirm = await this.askShowFareConfirm();
        if (!isConfirm) {
            isShowFare = false;
        } else {
            isShowFare = true;
        }

        this.setState({
            //loadPanelVisible: true,
            loadPanelVisible: true,
        });


        var request = new Object();
        request.RideId = parseInt(this.state.rideId);
        request.IsShowRate = isShowFare;
        request.fileFormat = 'pdf';
        request.MethodName = "GetVoucherReport";
        const requestParams = getRequestParams('POST', request);
        const response = await fetch('crystal-report-api', requestParams);
        const dataResponse = await response.json();
        this.setState({
            // loadPanelVisible: false,
            loadPanelVisible: false,
        });
        let a = document.createElement('a');
        a.href = dataResponse.reportBase64String;
        a.download = "Voucher" + this.state.rideId + ".pdf";
        a.click();
    }

    async handleEmail(e) {
        e.preventDefault();
        this.openEmailModal(this.state.tripDetails.puDateTime, this.state.rideId, this.state.tripDetails.emailId)
    }

    async openEmailModal(puDateTime, rideId, emailId) {
        let attachments = [];
        //currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));

        var strSubject = sessionStorage.getItem("session_accountname") + " Voucher: " + new Date(puDateTime).toLocaleDateString() + " " + new Date(puDateTime).toLocaleTimeString() + " (Ride Id: " + rideId + ") ";

        let strFileName = "Voucher" + rideId;

        var isShowFare = false;
        const isConfirm = await this.askShowFareConfirm();
        if (!isConfirm) {
            isShowFare = false;
        } else {
            isShowFare = true;
        }

        this.setState({
            loadPanelVisible: true,
        });

        var request = new Object();
        request.RideId = parseInt(rideId);
        request.IsShowRate = isShowFare;
        request.fileFormat = 'pdf';
        request.MethodName = "GetVoucherReport";
        const requestParams = getRequestParams('POST', request);
        const response = await fetch('crystal-report-api', requestParams);
        const dataResponse = await response.json();
        let fileData = dataResponse.reportBase64String;
        if (fileData !== null && fileData !== '') {

            //let emailSubject = 'Data Entry Audit Report - ' + moment(currentTime).format("DD MMMM YYYY HH:mm");
            let file = new Object();
            file.fileData = fileData;
            file.fileName = strFileName + '.pdf';
            file.fileExtension = '.pdf';
            attachments.push(file);
            this.setState({
                loadPanelVisible: false,
            });

            this.refs.voucherEmailComponent.showModal(rideId, 0, emailId, '', strSubject, '', attachments);
        }
        else {
            this.setState({
                loadPanelVisible: false,
            });

            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Unable to process the email request.',
            })
        }
    }

    async copyReservation(e) {
        e.preventDefault();

        if (this.state.customer.customerTypeId === 8) {
            return;
        } else if (this.state.customer.customerTypeId === 1) {
            if (this.refs.airportTransferRef) {
                this.setState({ rideId: 0 });
                this.refs.airportTransferRef.copyReservation();
            }
        } else if (this.state.customer.customerTypeId === 2) {
            if (this.refs.customerServiceTransferRef) {
                this.setState({ rideId: 0 });
                this.refs.customerServiceTransferRef.copyReservation();
            }
        } else if (this.state.customer.customerTypeId === 3) {
            if (this.refs.charterTransferRef) {
                this.setState({ rideId: 0 });
                this.refs.charterTransferRef.copyReservation();
            }
        } else if (this.state.customer.customerTypeId === 4) {
            if (this.refs.contractTransferRef) {
                this.setState({ rideId: 0 });
                this.refs.contractTransferRef.copyReservation();
            }
        }
    }

    submitFunction(value) {
        this.setState({ isSaveSubmited: value, isSubmited: value });
    }

    duplicateFunction(value) {
        this.setState({ isDuplicateSubmited: value, isSubmited: value });
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true
        });        
        if (parseInt(this.state.rideId) > 0) {
            await this.getTripDetails();
            await this.getCustomerList();
        } else {
            await this.getCustomerList();
        }
      
        this.setState({
            loadPanelVisible: false
        });
    }

    async getCustomerList() {
        let customerList = [];
        const data = JSON.parse(sessionStorage.getItem("session_customers"));
        if (data === null) {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('account-api/customer-dropdown/', requestParams);
            const data = await response.json();
            customerList = data.customers;
            this.setState({
                dropdownCustomer: data.customers,
            });
        }
        else {
            customerList = data.customers;
            this.setState({
                dropdownCustomer: data.customers
            });
        }

        if (parseInt(this.state.rideId) > 0) {
            this.setState({
                //customer: data.customers[0] // this.props.location.state == undefined ? data.customers[0] : data.customers.find(f => f.id == this.state.tripDetails.customerId)
                //customer: this.props.location.state == undefined ? data.customers[0] : data.customers.find(f => f.id == this.state.tripDetails.customerId)
                customer: this.props.location.state == undefined ? customerList[0] : customerList.find(f => f.id == this.state.tripDetails.customerId)
            });
        } else {
            if (this.state.newcustomerId != undefined) {
                this.setState({
                    //customer: data.customers.find(f => f.id == parseInt(this.state.newcustomerId))
                    customer: customerList.find(f => f.id == parseInt(this.state.newcustomerId))
                });
            } else if (this.state.rateLookup != null) {
                this.setState({
                    //customer: data.customers.find(f => f.id == parseInt(this.state.rateLookup.CustomerId))
                    customer: customerList.find(f => f.id == parseInt(this.state.rateLookup.CustomerId))
                });
            } else {
                this.setState({
                    //customer: data.customers[0]
                    customer: null
                });
            }
        }
    }

    async getTripDetails() {
        var newObject = new Object();
        newObject.tripId = parseInt(this.state.rideId);

        const requestParams = getRequestParams('POST', newObject);
        const response = await fetch('reservation-api/trip-details/', requestParams);        
        const data = await response.json();
        this.setState({
            tripDetails: data.tripDetails,
        });
        editCustomerId = data.tripDetails.customerId;
    }

    handleCustomerChange(data) {
        if (data.selectedItem === null) {
            this.setState({
                customer: null,
                rideId: 0
            });
            return;
        }

        this.setState({
            customer: data.selectedItem
        });

        if (data.selectedItem.customerTypeId === 8) {
            window.location.href = "/route/booking/?id=" + data.selectedItem.id;
            return;
        } else if (data.selectedItem.customerTypeId === 1) {
            if (this.refs.airportTransferRef) {
                this.refs.airportTransferRef.loadPage(data.selectedItem);

            }
        } else if (data.selectedItem.customerTypeId === 2) {
            if (this.refs.customerServiceTransferRef) {
                this.refs.customerServiceTransferRef.loadPage(data.selectedItem);
            }
        } else if (data.selectedItem.customerTypeId === 3) {
            if (this.refs.charterTransferRef) {
                this.refs.charterTransferRef.loadPage(data.selectedItem);
            }
        } else if (data.selectedItem.customerTypeId === 4) {
            if (this.refs.contractTransferRef) {
                this.refs.contractTransferRef.loadPage(data.selectedItem);
            }
        }
    }

    async handleRezSubmit(e, isAttachment, isSentEmail) {
        e.preventDefault();
        debugger;
        if (this.state.customer.customerTypeId === 8) {
            window.location.href = "/route/booking/?id=" + this.customer.id;
            return;
        } else if (this.state.customer.customerTypeId === 1) {
            if (this.refs.airportTransferRef) {
                this.refs.airportTransferRef.airportTransferSubmit(isAttachment, isSentEmail);
            }
        } else if (this.state.customer.customerTypeId === 2) {
            if (this.refs.customerServiceTransferRef) {
                this.refs.customerServiceTransferRef.customerServiceTransferSubmit(isAttachment, isSentEmail);
            }
        } else if (this.state.customer.customerTypeId === 3) {
            if (this.refs.charterTransferRef) {
                this.refs.charterTransferRef.charterTransferSubmit(isAttachment, isSentEmail);
            }
        } else if (this.state.customer.customerTypeId === 4) {
            if (this.refs.contractTransferRef) {
                this.refs.contractTransferRef.contractTransferSubmit(isAttachment, isSentEmail);
            }
        }
    }


    async handleRezDuplicate(e) {
        e.preventDefault();

        if (this.state.customer.customerTypeId === 8) {
            return;
        } else if (this.state.customer.customerTypeId === 1) {
            if (this.refs.airportTransferRef) {
                this.refs.airportTransferRef.checkDuplicateReservation(false);
            }
        } else if (this.state.customer.customerTypeId === 2) {
            if (this.refs.customerServiceTransferRef) {
                this.refs.customerServiceTransferRef.checkDuplicateReservation(false);
            }
        } else if (this.state.customer.customerTypeId === 3) {
            if (this.refs.charterTransferRef) {
                this.refs.charterTransferRef.checkDuplicateReservation(false);
            }
        } else if (this.state.customer.customerTypeId === 4) {
            if (this.refs.contractTransferRef) {
                this.refs.contractTransferRef.checkDuplicateReservation(false);
            }
        }
    }

    async handleClearControls(e) {
        e.preventDefault();

        if (this.state.customer.customerTypeId === 8) {
            return;
        } else if (this.state.customer.customerTypeId === 1) {
            if (this.refs.airportTransferRef) {
                this.refs.airportTransferRef.clearAllControls();
            }
        } else if (this.state.customer.customerTypeId === 2) {
            if (this.refs.customerServiceTransferRef) {
                this.refs.customerServiceTransferRef.clearAllControls();
            }
        } else if (this.state.customer.customerTypeId === 3) {
            if (this.refs.charterTransferRef) {
                this.refs.charterTransferRef.clearAllControls();
            }
        } else if (this.state.customer.customerTypeId === 4) {
            if (this.refs.contractTransferRef) {
                this.refs.contractTransferRef.clearAllControls();
            }
        }
    }
    //<Link to={{pathname: '/template', search: '?query=abc', state: { detail: response.data }}}> My Link </Link>
    render() {
        const isDisableAllButton = this.state.customer != null ? false : true;
        const customerTypeId = this.state.customer != null ? this.state.customer.customerTypeId : 0;
        const customerId = this.state.customer != null ? this.state.customer.id : 0;
        const createdBy = this.state.tripDetails == null ? "" : this.state.tripDetails.createdByName;
        const createDate = this.state.tripDetails == null ? "" : this.state.tripDetails.createdDateStr;
        const rideStatus = this.state.tripDetails == null ? "PENDING" : this.state.tripDetails.tripStatus;
        const rideStatusId = this.state.tripDetails == null ? 101 : this.state.tripDetails.tripStatusId;
        const rezTitle = this.state.rideId > 0 ? "Edit Reservation" : "New Reservation";

        //console.log("Booking render called");
        return (
            <div>
                <form method="post" onSubmit={this.handleRezSubmit} style={(this.state.isSubmited || this.state.isDuplicateSubmited) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="content-wrapper" id="bookingdiv">
                        <div className="content-header">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 col-md-4 col-sm-12">
                                        <h1 className="m-0 text-dark">{rezTitle}</h1>
                                    </div>
                                    <div className="col-lg-6 col-md-8 col-sm-12">
                                        <ol className="breadcrumb float-sm-right">
                                            <NavItem><NavLink tag={Link} to="/reservation/list" className="btn btn-block btn-outline-secondary btn-sm" title="Search Reservation"><i className="fas fa-search"></i> Search Reservation</NavLink></NavItem>
                                            <NavItem><NavLink tag={Link} to="/quotation/list" className="btn btn-block btn-outline-secondary btn-sm" title="Quotation"><i className="fas fa-pen"></i> Quotation</NavLink></NavItem>
                                            <NavItem><NavLink tag={Link} to="/uploadschedule" className="btn btn-block btn-outline-secondary btn-sm" title="Upload Schedule"><i className="fas fa-clipboard-list"></i> Upload Schedule</NavLink></NavItem>

                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(this.state.rideId > 0) && <div className="rez-header">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        Rez# <span className="text-info">{this.state.rideId == 0 ? "0000000000" : this.state.rideId}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Status <span className="text-info">{rideStatus}</span>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-right">
                                        Rez Created By <span className="text-info">{createdBy}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Rez Date Time <span className="text-info">{createDate}</span>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        <div className="buttons-header">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-3 col-md-12 col-sm-12">
                                        <div className="form-group row">
                                            <label htmlFor="" className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-form-label">Account</label>
                                            <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                                                <SelectBox
                                                    dataSource={this.state.dropdownCustomer.length > 0 ? this.state.dropdownCustomer : []}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Select customer"
                                                    name="customerId"
                                                    defaultValue={0}
                                                    value={customerId}
                                                    displayExpr='fullName'
                                                    valueExpr='id'
                                                    onSelectionChanged={this.handleCustomerChange}
                                                    showClearButton={true}
                                                    disabled={this.state.rideId <= 0 ? false : true}
                                                    searchEnabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-9 col-md-12 col-sm-12 text-right text-md-left text-sm-left text-xs-left">
                                        <button type="button" className="btn btn-success btn-sm" disabled={isDisableAllButton} onClick={(e) => { this.handleRezSubmit(e, false, false) }} >
                                            {this.state.isSaveSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSaveSubmited && <span title="Save"><i className="fas fa-save"></i> Save</span>}
                                        </button>
                                        {(this.state.rideId == 0) && <button type="button" className="btn btn-custom-gray btn-sm" disabled={isDisableAllButton} onClick={this.handleRezDuplicate} >
                                            {this.state.isDuplicateSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isDuplicateSubmited && <span title="Check Duplicate"><i className="fas fa-check-double"></i> Check Duplicate</span>}
                                        </button>}
                                        {(this.state.rideId == 0) && <button type="button" className="btn btn-custom-gray btn-sm" disabled={isDisableAllButton} onClick={(e) => { this.handleRezSubmit(e, true, false) }} >
                                            {this.state.isSaveSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSaveSubmited && <span title="Save & Attach"><i className="fas fa-paperclip"></i> Save & Attach</span>}
                                        </button>}
                                        {(this.state.rideId == 0) && <button type="button" className="btn btn-custom-gray btn-sm" disabled={isDisableAllButton} onClick={(e) => { this.handleRezSubmit(e, false, true) }} >
                                            {this.state.isSaveSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSaveSubmited && <span title="Save & Attach"><i className="fas fa-envelope"></i> Save & Email</span>}
                                        </button>}
                                        {(this.state.rideId > 0) && <button type="button" className="btn btn-custom-gray btn-sm" disabled={isDisableAllButton} onClick={this.handleAttachments} title="Upload/View Documents"><i className="fas fa-paperclip"></i> Attachments</button>}
                                        {(this.state.rideId > 0) && <button type="button" className="btn btn-custom-gray btn-sm" disabled={isDisableAllButton} onClick={this.handleDownload} title="Download Voucher"><i className="fas fa-download"></i> Download</button>}
                                        {(this.state.rideId > 0) && <button type="button" className="btn btn-custom-gray btn-sm" disabled={isDisableAllButton} onClick={this.handleEmail} title="Email/Fax"><i className="fas fa-envelope"></i> Email/Fax</button>}
                                        {(this.state.rideId > 0 && (rideStatusId != 111)) && <button type="button" className="btn btn-custom-gray btn-sm" disabled={isDisableAllButton} onClick={(e) => { this.actualTimeTrip(e) }} title="Actual Time"><i className="fas fa-clock"></i> Actual Time</button>}
                                        {(this.state.rideId > 0 && rideStatusId != 116) && <button type="button" className="btn btn-custom-gray btn-sm" disabled={isDisableAllButton} onClick={this.exceptionReservation} title="Exceptions"><i className="fas fa-exclamation-triangle"></i> Exceptions</button>}
                                        {(this.state.rideId > 0) && <button type="button" className="btn btn-custom-gray btn-sm" disabled={isDisableAllButton} onClick={this.copyReservation}><i className="fas fa-copy" title="Copy"></i> Copy</button>}
                                        {(this.state.rideId > 0 && (rideStatusId == 101 || rideStatusId == 102 || rideStatusId == 103 || rideStatusId == 105)) && <button type="button" className="btn btn-custom-gray btn-sm" disabled={isDisableAllButton} onClick={(e) => { this.voidRecallTrip(e, true) }} title="Cancel"><i className="fas fa-times"></i> Cancel</button>}
                                        {(this.state.rideId > 0 && rideStatusId == 111) && <button type="button" className="btn btn-custom-gray btn-sm" disabled={isDisableAllButton} onClick={(e) => { this.voidRecallTrip(e, false) }} title="Recall"><i className="fas fa-redo"></i> Recall</button>}
                                        {(this.state.rideId > 0) && <button type="button" className="btn btn-custom-gray btn-sm" disabled={isDisableAllButton} onClick={this.viewHistroy} title="History"><i className="fas fa-history"></i> History</button>}
                                        {(this.state.rideId > 0 && (rideStatusId == 104 || rideStatusId == 106 || rideStatusId == 107 || rideStatusId == 108 || rideStatusId == 114)) && <button type="button" className="btn btn-custom-gray btn-sm" disabled={isDisableAllButton} onClick={this.locateVehicle} title="Locate"><i className="fas fa-map-marker"></i> Locate</button>}
                                        {(this.state.rideId == 0) && <button type="button" className="btn btn-custom-gray btn-sm" disabled={isDisableAllButton} onClick={this.handleClearControls} title="Clear"><i className="fas fa-eraser"></i> Clear</button>}
                                        {(this.state.rideId > 0 || this.state.rateLookup != null ) && <button type="button" className="btn btn-custom-gray btn-sm" disabled={isDisableAllButton} onClick={this.goToBackPage} title="Back"><i className="fas fa-backward"></i> Back</button>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {(customerTypeId == 1) && <AirportTransferComponent ref="airportTransferRef" attachmentUpload={this.attachmentUpload} openEmailModal={this.openEmailModal} submitFunction={this.submitFunction} duplicateFunction={this.duplicateFunction} customer={this.state.customer} tripDetails={this.state.tripDetails} goToBackPage={this.goToBackPage} />}
                        {(customerTypeId == 2) && <CustomerServiceTransferComponent ref="customerServiceTransferRef" attachmentUpload={this.attachmentUpload} openEmailModal={this.openEmailModal} submitFunction={this.submitFunction} duplicateFunction={this.duplicateFunction} customer={this.state.customer} tripDetails={this.state.tripDetails} goToBackPage={this.goToBackPage} />}
                        {(customerTypeId == 3) && <CharterTransferComponent ref="charterTransferRef" attachmentUpload={this.attachmentUpload} openEmailModal={this.openEmailModal} submitFunction={this.submitFunction} duplicateFunction={this.duplicateFunction} customer={this.state.customer} tripDetails={this.state.tripDetails} goToBackPage={this.goToBackPage} rateLookup={this.state.rateLookup} />}
                        {(customerTypeId == 4) && <ContractTransferComponent ref="contractTransferRef" attachmentUpload={this.attachmentUpload} openEmailModal={this.openEmailModal} submitFunction={this.submitFunction} duplicateFunction={this.duplicateFunction} customer={this.state.customer} tripDetails={this.state.tripDetails} goToBackPage={this.goToBackPage} />}
                    </div>

                    <ReservationHistoryComponent ref="reservationHistoryModal" />
                    <LocateVehicleComponent ref="locateVehicleModal" />
                    <VoidReservationComponent ref="voidReservationModal" refreshDataSource={this.state.refreshDataSource} goToBackPage={this.goToBackPage} />
                    <TripExceptionComponent ref="tripExceptionModal" />
                    <ReservationActualTimeComponent ref="actualTimeModal" />
                    <DocumentsComponent ref="documentsComponent" />
                    <VoucherEmailComponent ref="voucherEmailComponent" />

                    <LoadPanel
                        shadingColor="rgba(0,0,0,0.4)"
                        position={position}
                        visible={this.state.loadPanelVisible}
                        showIndicator={true}
                        shading={true}
                        showPane={true}
                        closeOnOutsideClick={false}
                    />
                </form>
            </div>
        );
    }
}