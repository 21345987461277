import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { SelectBox } from 'devextreme-react/select-box';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { ZoneType } from '../../helpers/fixcodes.js'
import { HotelMappingComponent } from '../Shared/HotelMappingComponent';
const google = window.google;
const $ = window.$;
const customStyles = {
    content: {
        width: '70%',
    }
}

let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));

export class ServiceAreaZonePlaceComponent extends React.Component {
    constructor(props) {
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        super(props);
        this.state = {
            rowId: 0,
            isSubmited: false,
            placeGridDataSource: null,
            addEditPlaceDataModalIsOpen: false,
            locationList: [],
            locationId: 0,
            serviceAreaList: [],
            serviceAreaZoneId: 0,
            zoneTypeList: [],
            zoneType: 0,
            name: '',
            bookingZone: false,
            isActive: false,
            isDisable: false,
            airportList: [],
            airport: 0,
            address: "",
            city: "",
            state: "",
            country: "",
            zipcode: "",
            latitude: 0,
            longitude: 0,
            placeName:"",
            errors: {
                locationId: '',
                serviceAreaZoneId: '',
                zoneType: '',
                name: '',
                airport: '',
                placeName: "",
                city: "",
                state: "",
                zipcode: "",
                country:"",
                latitude: 0,
                longitude: 0,
                placeName: "",
                placeAddress:"",
            }
        }

        this.getServiceAreaList = this.getServiceAreaList.bind(this);
        this.handleAddEditServiceAreaZoneSubmit = this.handleAddEditServiceAreaZoneSubmit.bind(this);
        this.openHotelMapping = this.openHotelMapping.bind(this);
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    }
    componentDidMount = () => {
        this.getserviceAreaZoneList();        
    }    
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }
    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getserviceAreaZoneList()
            }
        });
    }
    async getserviceAreaZoneList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('serviceAreaZone-api/service-area-zone-place/0', requestParams);
        const data = await response.json();
        if (data !== null && data.serviceAreaZonePlace.length > 0) {
            this.setState({
                placeGridDataSource: data.serviceAreaZonePlace
            });
        }
    }
    async LocationList() {
        let locationId = parseInt(sessionStorage.getItem("session_locationId"));
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('account-api/locations', requestParams);
        const data = await response.json();
        if (data !== null && data.locations.length > 0) {
            this.setState({
                locationList: data.locations
            });
        }
    }
    async getServiceAreaList(locationId) {
        if (locationId > 0) {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('serviceAreaZone-api/zone-dropdown/0/' + locationId, requestParams);
            const data = await response.json();            
            if (data !== null && data.locationServiceAreaZoneList.length > 0) {
                this.setState({
                    serviceAreaList: data.locationServiceAreaZoneList,
                });
            }
        }
    }
    async getServiceAreaPlaceDistanceList(placeId) {
        if (placeId > 0) {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('serviceAreaZone-api/service-area-zone-place-distance/' + placeId, requestParams);
            const data = await response.json();
            if (data !== null && data.placesDistance.length > 0) {
                this.setState({
                    airport: data.placesDistance[0].airportId,
                });

                for (let i = 1; i <= data.placesDistance.length; i++) {
                    var distance = "0";
                    var duration = "0";
                    var rowIndex = i;
                    var placeDistance = data.placesDistance[i - 1];
                    distance = placeDistance.distanceKM;
                    duration = placeDistance.durationMIN;
                    $("#airportRoute").removeClass("displayNone").addClass("displayBlock");
                    $("#airport_details_" + rowIndex).removeClass("displayNone").addClass("displayBlock");
                    var airport_element = document.getElementById("airport_" + rowIndex);
                    var distance_element = document.getElementById("totalDistance_" + rowIndex);
                    var duration_element = document.getElementById("totalDuration_" + rowIndex);
                    var airport_hidden_element = document.getElementById("airport_hid_" + rowIndex);

                    airport_element.innerText = placeDistance.airportCode;
                    airport_hidden_element.value = placeDistance.airportId;
                    distance_element.value = distance + " KM";
                    duration_element.value = duration + " Min";
                }
            }
        }
    }
    async ZoneTypeList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('zoneType-api/zoneType-dropdown-list', requestParams);
        const data = await response.json();
        if (data !== null && data.zoneType.length > 0) {
            this.setState({
                zoneTypeList: data.zoneType
            });
        }
    }
    async AirportList(locationId) {
        const reqlocation = [];
        reqlocation.push(locationId);
        //var airportsDropdownListRequest = new Object();
        //airportsDropdownListRequest.LocationIdList = reqlocation;
        const requestParams = getRequestParams('GET', "");
        const response = await fetch('/airports-api/serviceAirportByLocation/' + locationId, requestParams);
        const data = await response.json();
        if (data !== null && data.servicedAirports.length > 0) {
            this.setState({
                airportList: data.servicedAirports
            });
        } else {
            this.setState({
                airportList: [], airport: 0
            });
        }
    }

    openServiceAreaZoneDataModal = () => {
        this.LocationList();
        this.ZoneTypeList();
        var options = { types: ['establishment'] };
        this.autocompletePlace = new google.maps.places.Autocomplete(document.getElementById('placeAddress'), options);
        this.autocompletePlace.addListener("place_changed", this.handlePlaceSelect);
        this.distanceMatrixService = new google.maps.DistanceMatrixService();   
    }
    addEditServiceAreaZoneControl = (e, data) => {        
        if (data != null) {
            e.preventDefault();
            this.setState({
                rowId: data.id,
                locationId: data.locationId,
                serviceAreaZoneId: data.serviceAreaZoneId,
                placeAddress: data.actualName,
                placeName: data.displayName,
                city: data.city,
                state: data.state,
                country: data.country,
                zipcode: data.zipCode,
                latitude: data.latitude,
                longitude: data.longitude,
                isActive: data.isActive,
                airport: data.airportId,
                addEditPlaceDataModalIsOpen: true,
            });
            this.getServiceAreaList(data.locationId);
            this.AirportList(data.locationId);
            this.getServiceAreaPlaceDistanceList(data.id);
        }
        else {
            this.setState({
                rowId: 0,
                addEditPlaceDataModalIsOpen: true,
            });
        }
    }
    handlePlaceSelect() {
        var placeObj = this.handlePlaceSearch(this.autocompletePlace);
        if (placeObj !== null) {
            //this.state.errors["placeAddress"] = "";
            this.setState({
                placeAddress: placeObj.address1,
                placeName: placeObj.location,
                address: placeObj.address1,
                //pickupAddressType: placeObj.addressType,
                city: placeObj.cityName,
                state: placeObj.stateName,
                country: placeObj.countryName,
                zipcode: placeObj.zipCode,
                latitude: placeObj.latitude,
                longitude: placeObj.longitude,
            })
        } else {
            this.setState({
                placeAddress: "",
                placeName: "",
                address: "",
                //pickupAddressType: "",
                city: "",
                state: "",
                country:"",
                zipcode: "",
                latitude: 0,
                longitude: 0
            });
            this.state.errors["pickupLocation"] = "";
        }
    }
    handlePlaceSearch(autocomplete_place) {
        let place = autocomplete_place.getPlace();

        var placeObject = new Object();
        if (place !== null && place.formatted_address !== null && place.formatted_address.trim() != "") {
            placeObject.location = place.name;
            placeObject.latitude = place.geometry.location.lat();
            placeObject.longitude = place.geometry.location.lng();

            var splitAddress = [];
            place.formatted_address = place.formatted_address.replace(/-/g, ',');
            splitAddress = place.formatted_address.split(',');
            if (splitAddress.length > 0) {
                if (splitAddress.length == 1) {
                    placeObject.address1 = splitAddress[0];
                    placeObject.cityName = "";
                    placeObject.zipCode = "";
                    placeObject.stateName = "";
                    placeObject.countryName = "";
                }
                else if (splitAddress.length == 2) {
                    placeObject.address1 = splitAddress[0];
                    placeObject.cityName = splitAddress[0];
                    placeObject.stateName = splitAddress[1].trim().search(' ') > 0 ? splitAddress[1].trim().split(' ')[0] : splitAddress[1].trim();
                    placeObject.zipCode = splitAddress[1].trim().search(' ') > 0 ? splitAddress[1].trim().split(' ')[1] : " ";
                    placeObject.countryName = "";
                }
                else if (splitAddress.length == 3) {
                    placeObject.address1 = splitAddress[0] + ' ' + splitAddress[1].trim();
                    placeObject.cityName = splitAddress[1].trim();
                    placeObject.stateName = splitAddress[2].trim().search(' ') > 0 ? splitAddress[2].trim().split(' ')[0] : splitAddress[2].trim();
                    placeObject.zipCode = splitAddress[2].trim().search(' ') > 0 ? splitAddress[2].trim().split(' ')[1] : " ";
                    placeObject.countryName = "";
                }
                else if (splitAddress.length == 4) {
                    placeObject.address1 = splitAddress[0] + ' ' + splitAddress[1].trim();
                    placeObject.cityName = splitAddress[1].trim();
                    placeObject.stateName = splitAddress[2].trim().search(' ') > 0 ? splitAddress[2].trim().split(' ')[0] : splitAddress[2].trim();
                    placeObject.zipCode = splitAddress[2].trim().search(' ') > 0 ? splitAddress[2].trim().split(' ')[1] : " ";
                    placeObject.countryName = splitAddress[3].trim();
                }
                else if (splitAddress.length == 5) {
                    placeObject.address1 = splitAddress[0] + ' ' + splitAddress[1].trim() + ' ' + splitAddress[2].trim();
                    placeObject.cityName = splitAddress[2].trim();
                    placeObject.stateName = splitAddress[3].trim().search(' ') > 0 ? splitAddress[3].trim().split(' ')[0] : splitAddress[3].trim();
                    placeObject.zipCode = splitAddress[3].trim().search(' ') > 0 ? splitAddress[3].trim().split(' ')[1] : " ";
                    placeObject.countryName = splitAddress[4].trim();
                }
                else if (splitAddress.length == 6) {
                    placeObject.address1 = splitAddress[0] + ' ' + splitAddress[1].trim() + ' ' + splitAddress[2].trim() + ' ' + splitAddress[3].trim();
                    placeObject.cityName = splitAddress[3].trim();
                    placeObject.stateName = splitAddress[4].trim().search(' ') > 0 ? splitAddress[4].trim().split(' ')[0] : splitAddress[4].trim();
                    placeObject.zipCode = splitAddress[4].trim().search(' ') > 0 ? splitAddress[4].trim().split(' ')[1] : " ";
                    placeObject.countryName = splitAddress[5].trim();
                }
                else {
                    placeObject.address1 = splitAddress[0] + ' ' + splitAddress[1].trim() + ' ' + splitAddress[2].trim() + ' ' + splitAddress[3].trim() + ' ' + splitAddress[4].trim();
                    placeObject.cityName = splitAddress[4].trim();
                    placeObject.stateName = splitAddress[5].trim().search(' ') > 0 ? splitAddress[5].trim().split(' ')[0] : splitAddress[5].trim();
                    placeObject.zipCode = splitAddress[5].trim().search(' ') > 0 ? splitAddress[5].trim().split(' ')[1] : " ";
                    placeObject.countryName = splitAddress[6].trim();
                }
            }
        }
        return placeObject;
    }
    handleAirportChange = (event) => {
        var distance = "0";
        var duration = "0";
        var rowIndex = event.target.selectedIndex;
        $("#airportRoute").removeClass("displayNone").addClass("displayBlock");
        $("#airport_details_" + rowIndex).removeClass("displayNone").addClass("displayBlock");
        var airport_element = document.getElementById("airport_" + rowIndex);
        var distance_element = document.getElementById("totalDistance_" + rowIndex);
        var duration_element = document.getElementById("totalDuration_" + rowIndex);
        var airport_hidden_element = document.getElementById("airport_hid_" + rowIndex);
        
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        var selectAirport = this.state.airportList.filter(e => e.airportId === parseInt(value));
        var airport = selectAirport[0];
        airport_element.innerText = airport.code;
        airport_hidden_element.value = airport.airportId;
        //var distanceduartion = this.getRoute(airport.latitude, airport.longitude);
        const name = target.name;
        let errors = this.state.errors;
        errors.airport = "";
        if (value === null || Number(value) === 0) {
            this.setState({ airport: 0 });
            errors.airport = "Please select airport.";
        } else {
            this.setState({ airport: Number(value) });
            var source = new google.maps.LatLng(this.state.latitude, this.state.longitude);
            var destination = new google.maps.LatLng(airport.latitude, airport.longitude);
            this.distanceMatrixService.getDistanceMatrix(
                {
                    origins: [source],
                    destinations: [destination],
                    travelMode: 'DRIVING',
                    //transitOptions: google.maps.TravelMode.DRIVING,
                    //drivingOptions: google.maps.UnitSystem.METRIC,
                    //unitSystem: UnitSystem,
                    durationInTraffic: true,
                    avoidHighways: false,
                    avoidTolls: false
                }, function (response, status) {
                    if (status == google.maps.DistanceMatrixStatus.OK && response.rows[0].elements[0].status != "ZERO_RESULTS") {
                        distance = response.rows[0].elements[0].distance.text;
                        duration = response.rows[0].elements[0].duration.text;
                        distance_element.value = distance;
                        duration_element.value = duration;
                    } else {
                        distance_element.value = distance;
                        duration_element.value = duration;
                        console.log("Unable to find the Distance and Duration.");
                    }
                });
        }
        this.setState({ errors: errors });
    }
    getRoute = (airportLatitude, airportLongitude) => {
        //var origin1 = new google.maps.LatLng(airportLatitude, airportLongitude);
        //var origin2 = 'Greenwich, England';
        //var destinationA = 'Stockholm, Sweden';
        //var destinationB = new google.maps.LatLng(50.087692, 14.421150);

        //var source = this.state.latitude + "," + this.state.longitude;
        //var destination = airportLatitude + "," + airportLongitude;

        var source = new google.maps.LatLng(this.state.latitude, this.state.longitude);
        var destination = new google.maps.LatLng(airportLatitude, airportLongitude);
        var distanceduartion = "";
        this.distanceMatrixService.getDistanceMatrix(
            {
                origins: [source],
                destinations: [destination],
                travelMode: 'DRIVING',
                //transitOptions: google.maps.TravelMode.DRIVING,
                //drivingOptions: google.maps.UnitSystem.METRIC,
                //unitSystem: UnitSystem,
                //avoidHighways: Boolean,
                //avoidTolls: Boolean,
                durationInTraffic: true,
                avoidHighways: false,
                avoidTolls: false
            }, function (response, status) {                
                if (status == google.maps.DistanceMatrixStatus.OK && response.rows[0].elements[0].status != "ZERO_RESULTS") {
                    var distance = response.rows[0].elements[0].distance.text;
                    var duration = response.rows[0].elements[0].duration.text;
                    distanceduartion = response.rows[0].elements[0];
                    console.log(distance);
                    console.log(duration);
                    //removeAndAdd(distance, duration);
                } else {
                    console.log("Unable to find the Distance and Duration.");
                    //$.alert("Unable to find the Distance and Duration.", "Invalid");
                    //removeAndAdd("0 km", "00 hours 00 mins");
                }
        });

        return distanceduartion;
    }
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let errors = this.state.errors;
        switch (name) {
            case 'serviceAreaZoneId':
                errors.serviceAreaZoneId = "";
                if (value === null || Number(value) === 0) {
                    this.setState({ serviceAreaZoneId: 0 });
                    errors.serviceAreaZoneId = "Please select service area zone.";
                } else {
                    this.setState({ serviceAreaZoneId: Number(value) });
                }
                break;
            case 'placeAddress':
                errors.placeAddress = "";
                if (value === null || Number(value) === 0) {
                    this.setState({ placeAddress: "" });
                    errors.serviceAreaZoneId = "Please select place address.";
                } else {
                    this.setState({ placeAddress: value });
                }
                break;
            case 'zoneType':
                errors.zoneType = "";
                if (value === null || Number(value) === 0) {
                    this.setState({ zoneType: 0 });
                    errors.zoneType = "Please select zone Type.";
                } else {
                    this.setState({ zoneType: Number(value) });
                    if (Number(value) === ZoneType.Airport) {
                        this.AirportList(this.state.locationId);
                    }
                }
                break;
            case 'name':
                errors.name = "";
                if (value.trim() === "") {
                    this.setState({ name: '' });
                    errors.name = "Please enter service area zone name.";
                } else {
                    this.setState({ name: value });
                }
                break;
            case 'airport':
                errors.airport = "";
                if (value === null || Number(value) === 0) {
                    this.setState({ airport: 0 });
                    errors.airport = "Please select airport.";
                } else {
                    this.setState({ airport: Number(value) });
                }
                break;
            default:
                this.setState({
                    [name]: value
                });
                break;
        }
        if (name === "isActive") {
            this.setState({
                isDisable: value
            });
        }
        this.setState({ errors: errors });
    }
    handleLocationInputChange = (data) => {
        if (data.selectedItem !== null) {
            this.setState({
                locationId: data.selectedItem.locationId
            });
            this.getServiceAreaList(data.selectedItem.locationId);
            this.AirportList(data.selectedItem.locationId);
        } else {
            this.setState({
                locationId: 0
            });
        }
    }
    handleServiceAreaInputChange = (data) => {
        this.setState({ serviceAreaZoneId: (data.selectedItem != null ? data.selectedItem.id : 0) })
    }
    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.locationId = 0;
        errors.serviceAreaZoneId = 0;
        errors.placeName = "";
        errors.placeAddress = "";
        errors.city = "";
        errors.state = "";
        errors.country = "";
        errors.zipcode = "";
        errors.latitude = 0;
        errors.longitude = 0;
        errors.airport = 0;

        if (this.state.locationId === 0) {
            formIsValid = false;
            errors.locationId = "Please select location";
        }
        if (this.state.serviceAreaZoneId === 0) {
            formIsValid = false;
            errors.serviceAreaZoneId = "Please select service area zone";
        }
        if (this.state.placeName === "") {
            formIsValid = false;
            errors.placeName = "Please enter place name";
        }
        if (this.state.placeAddress === "") {
            formIsValid = false;
            errors.placeAddress = "Please enter address";
        }
        if (this.state.city === "") {
            formIsValid = false;
            errors.city = "Please enter city";
        }
        if (this.state.state === "") {
            formIsValid = false;
            errors.state = "Please enter state";
        }
        if (this.state.country === "") {
            formIsValid = false;
            errors.country = "Please enter country";
        }
        if (this.state.zipcode === "") {
            formIsValid = false;
            errors.zipcode = "Please enter zipcode";
        }
        if (this.state.latitude === 0) {
            formIsValid = false;
            errors.latitude = "Please enter latitude";
        }
        if (this.state.longitude === 0) {
            formIsValid = false;
            errors.longitude = "Please enter longitude";
        }
        if (this.state.airport === 0) {
            formIsValid = false;
            errors.airport = "Please select airport";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    async handleAddEditServiceAreaZoneSubmit(e) {
        e.preventDefault();
        this.setState({ isSubmited: true });

        if (this.handleValidation())
        {
            var myxml = "<?xml version=\"1.0\" ?>";
            myxml += "<DetailData>";
            for (var i = 1; i <= 5; i++) {
                var airport_hidden_element = document.getElementById("airport_hid_" + i);
                if (airport_hidden_element.value != "") {
                    var airport_element = document.getElementById("airport_" + i);
                    var distance_element = document.getElementById("totalDistance_" + i);
                    var duration_element = document.getElementById("totalDuration_" + i);
                    var distanceValue = distance_element.value.split(" ")[0];
                    var durationValue = duration_element.value.split(" ")[0];                    
                    if (distanceValue != "0" && durationValue != "0") {
                        myxml += "<AirportDistDurArrry>";
                        myxml += "<AirportId>" + parseInt(airport_hidden_element.value) + "</AirportId>";
                        myxml += "<Distance>" + distanceValue + "</Distance>";
                        myxml += "<Duration>" + durationValue + "</Duration>";
                        myxml += "</AirportDistDurArrry>";
                    }
                }
            }
            myxml += "</DetailData>";

            debugger;

            var addEditRequest = new Object();
            addEditRequest.RowIndex = this.state.rowId;
            addEditRequest.LocationId = this.state.locationId;
            addEditRequest.ServiceAreaId = 0;
            addEditRequest.serviceAreaZoneId = this.state.serviceAreaZoneId;
            addEditRequest.ActualLocation = this.state.placeAddress;
            addEditRequest.DisplayName = this.state.placeName;            
            addEditRequest.City = this.state.city;
            addEditRequest.State = this.state.state;
            addEditRequest.Country = this.state.country;
            addEditRequest.Zipcode = this.state.zipcode;
            addEditRequest.Latitude = this.state.latitude;
            addEditRequest.Longitude = this.state.longitude;
            addEditRequest.Enable = this.state.rowId > 0 ? this.state.isActive === true ? true : false : true;
            addEditRequest.AccountPlaceDistance = myxml;
            addEditRequest.CreatedDate = currentTime;

            const requestParams = getRequestParams('POST', addEditRequest);
            const response = await fetch('serviceAreaZone-api/map-service-area-zone-place', requestParams);
            const data = await response.json();
            
            Swal.fire({
                icon: data.isSuccess ? 'success' : 'error',
                title: data.isSuccess ? 'Success' : 'Oops...',
                text: data.resultMessage,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (data.isSuccess) {
                    if (result.isConfirmed) {
                        this.setState({ isSubmited: false, addEditPlaceDataModalIsOpen: false });
                        this.getserviceAreaZoneList();
                        this.closeaddEditServiceAreaZoneModal();
                    }
                } else {
                    this.setState({ isSubmited: false })
                }
            })
        }
        else {
            this.setState({ isSubmited: false })
        }
    }
    closeaddEditServiceAreaZoneModal = () => {
        this.setState({
            rowId: 0,
            isSubmited: false,
            addEditPlaceDataModalIsOpen: false,
            locationId: 0,
            serviceAreaZoneId: 0,
            zoneType: 0,
            name: '',
            bookingZone: false,
            isActive: false,
            isDisable: false,
            airport: 0,
            address: "",
            city: "",
            state: "",
            country: "",
            zipcode: "",
            latitude: 0,
            longitude: 0,
            placeName: "",
            errors: {
                locationId: '',
                serviceAreaZoneId: '',
                zoneType: '',
                name: '',
                airport: '',
                placeName: "",
                city: "",
                state: "",
                zipcode: "",
                country: "",
                latitude: 0,
                longitude: 0,
                placeName: "",
                placeAddress: "",
            }
        });
    }
    openHotelMapping(e, data) {
        e.preventDefault();
        this.refs.hotelMappingComponent.showModal(data);
    }
    closeAirportRoute = (e, rowIndex) => {
        e.preventDefault();
        var airport_element = document.getElementById("airport_" + rowIndex);
        var distance_element = document.getElementById("totalDistance_" + rowIndex);
        var duration_element = document.getElementById("totalDuration_" + rowIndex);
        airport_element.innerText = "";
        distance_element.value = "0";
        duration_element.value = "0";
        $("#airport_details_" + rowIndex).removeClass("displayBlock").addClass("displayNone");                
    }

    render() {

        const serviceAreaZoneModalTitle = this.state.rowId == 0 ? "Add Place" : "Edit Place"
        const renderEditGridCell = (data) => {
            return <div>&nbsp;&nbsp;
                <a href="#" className="fas fa-pen icon text-info" title="Edit" onClick={(e) => { this.addEditServiceAreaZoneControl(e, data.data) }} ></a>&nbsp;&nbsp;
                <a href="#" className="fas fa-hotel text-info" title="Set Hotel" onClick={(e) => { this.openHotelMapping(e, data.data) }}></a>&nbsp;&nbsp;
            </div>;
        }

        return (<div className="content-wrapper">
            <div className="content-header">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-sm-6 col-md-6 col-lg-6">
                            <h1 className="m-0 text-dark">Place</h1>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <ol className="breadcrumb float-sm-right">
                                <NavItem>
                                    <NavLink tag={Link} to="/location/list" className="btn btn-block btn-outline-secondary btn-sm" title="Location"><i className="fas fa-map-marker-alt"></i> Location</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} to="/servicearea/list" className="btn btn-block btn-outline-secondary btn-sm" title="Service Area"><i className="fas fa-map-marker-alt"></i> Service Area</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={(e) => { this.addEditServiceAreaZoneControl(e, null) }} className="btn btn-block btn-outline-secondary btn-sm" title="Add Service Area Zone"><i className="fas fa-plus"></i> Add Place</NavLink>
                                </NavItem>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="card card-grid">
                    <div className="card-body">
                        <DataGrid
                            dataSource={this.state.placeGridDataSource}
                            keyExpr="id"
                            selection={{ mode: 'single' }}
                            columnsAutoWidth={false}
                            showColumnLines={false}
                            showRowLines={true}
                            showBorders={true}
                            rowAlternationEnabled={true}
                            showBorders={true}
                            allowColumnResizing={true}
                            columnResizingMode='widget'
                            onToolbarPreparing={this.onToolbarPreparing}>
                            <Column dataField="id" width={50} caption="" allowFiltering={false} allowSorting={false} cellRender={renderEditGridCell} />
                            <Column dataField="locationName" width={150} caption="Location" allowFiltering={true} allowSorting={true} />
                            <Column dataField="serviceAreaName" width={200} caption="Service Area" allowFiltering={true} allowSorting={true} />
                            <Column dataField="actualName" width={550} caption="Place" allowFiltering={true} allowSorting={true} />
                            <Column dataField="displayName" width={350} caption="Place Name" allowFiltering={true} allowSorting={true} />
                            <Column dataField="status" width={200} caption="Status" allowFiltering={true} allowSorting={true} />

                            <SortByGroupSummaryInfo summaryItem="count" />
                            <GroupPanel visible={true} />
                            <HeaderFilter visible={true} />
                            <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                            <Grouping autoExpandAll={true} />
                            <Paging defaultPageSize={20} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true} />
                            <Export enabled={true} allowExportSelectedData={false} />
                            <ColumnChooser enabled={true} />
                            <ColumnFixing enabled={true} />

                        </DataGrid>
                    </div>
                </div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.addEditPlaceDataModalIsOpen}
                    className={"react-modal edit-service-place-modal"}
                    onAfterOpen={this.openServiceAreaZoneDataModal}
                    onRequestClose={this.closeaddEditServiceAreaZoneModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal"
                >
                    <form method="post" onSubmit={this.handleAddEditServiceAreaZoneSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}} encType="multipart/form-data">
                        <div className="modal-header">
                            <h6 className="modal-title">{serviceAreaZoneModalTitle}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeaddEditServiceAreaZoneModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <label>Location <span className="text-red">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.locationList.length > 0 ? this.state.locationList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select location"
                                            name="locationId"
                                            defaultValue={0}
                                            value={this.state.locationId}
                                            displayExpr='locationName'
                                            valueExpr='locationId'
                                            onSelectionChanged={this.handleLocationInputChange}
                                            showClearButton={true}
                                            disabled={this.state.rowId > 0 ? true : false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["locationId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["locationId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <label>Service Area <span className="text-red">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.serviceAreaList.length > 0 ? this.state.serviceAreaList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select service area"
                                            name="serviceAreaZoneId"
                                            defaultValue={0}
                                            value={this.state.serviceAreaZoneId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleServiceAreaInputChange}
                                            showClearButton={true}
                                            disabled={this.state.rowId > 0 ? true : false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["serviceAreaZoneId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["serviceAreaZoneId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <div className="form-group">
                                        
                                    </div>
                                </div>
                                <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                                    <div className="form-group">
                                        <label>Address<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" id="placeAddress" placeholder="'Type' to search place" maxLength="150" title="Pick-up Location & Address" value={this.state.placeAddress}
                                        disabled={this.state.rowId > 0 ? true : false} />
                                        {this.state.errors["placeAddress"].length > 0 && <span className="error invalid-feedback">{this.state.errors["placeAddress"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <label>Place Name<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-textbox form-control-sm" name="placeName" title="Place Name" placeholder="Enter Place Name" maxLength="50" value={this.state.placeName} onChange={this.handleInputChange}
                                        disabled={this.state.rowId > 0 ? true : false} />
                                        {this.state.errors["placeName"].length > 0 && <span className="error invalid-feedback">{this.state.errors["placeName"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                    <div className="form-group">
                                        <label>City<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-textbox form-control-sm" name="city" title="City" placeholder="Enter City" maxLength="50" value={this.state.city} onChange={this.handleInputChange} />
                                        {this.state.errors["city"].length > 0 && <span className="error invalid-feedback">{this.state.errors["city"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                    <div className="form-group">
                                        <label>State<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-textbox form-control-sm" name="state" title="State" placeholder="Enter State" maxLength="50" value={this.state.state} onChange={this.handleInputChange} />
                                        {this.state.errors["state"].length > 0 && <span className="error invalid-feedback">{this.state.errors["state"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                    <div className="form-group">
                                        <label>Country<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-textbox form-control-sm" name="country" title="Country" placeholder="Enter Country" maxLength="50" value={this.state.country} onChange={this.handleInputChange} />
                                        {this.state.errors["country"].length > 0 && <span className="error invalid-feedback">{this.state.errors["country"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                    <div className="form-group">
                                        <label>Zipcode<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-textbox form-control-sm" name="zipcode" title="Zipcode" placeholder="Enter Zipcode" maxLength="50" value={this.state.zipcode} onChange={this.handleInputChange} />
                                        {this.state.errors["zipcode"].length > 0 && <span className="error invalid-feedback">{this.state.errors["zipcode"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                    <div className="form-group">
                                        <label>Latitude<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-textbox form-control-sm" name="latitude" title="Latitude" placeholder="Enter Latitude" maxLength="50" value={this.state.latitude} onChange={this.handleInputChange} />
                                        {this.state.errors["latitude"].length > 0 && <span className="error invalid-feedback">{this.state.errors["latitude"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                    <div className="form-group">
                                        <label>Longitude<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-textbox form-control-sm" name="longitude" title="Longitude" placeholder="Enter Longitude" maxLength="50" value={this.state.longitude} onChange={this.handleInputChange} />
                                        {this.state.errors["longitude"].length > 0 && <span className="error invalid-feedback">{this.state.errors["longitude"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <div className="form-check" style={{ display: this.state.rowId > 0 ? 'inline-block' : 'none' }}>
                                            <input className="form-check-input" type="checkbox" id="isActive" name="isActive" checked={this.state.isActive} onChange={this.handleInputChange} />
                                            <label className="form-check-label" htmlFor="isActive"> Is Active</label>
                                            { /*<label htmlFor="isActive" style={{ fontSize: "inherit" }}> Is Active &nbsp;<i>(If Inactive, please enter reason)</i></label>*/}
                                        </div>
                                        { /* <textarea className="form-control form-control-sm" name="inactiveReason" disabled={this.state.isDisable === true ? false : true} placeholder="Inactive Reason" maxLength="100" title="Inactive Reason" value={this.state.inactiveReason} onChange={this.handleInputChange} />
                                    {this.state.errors["inactiveReason"].length > 0 && <span className="error invalid-feedback">{this.state.errors["inactiveReason"]}</span>}*/}
                                    </div>
                                </div>                                
                            </div>
                            <div className="row">
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <label>Airport </label>
                                        <select className="form-control form-control-sm" name="airport" placeholder="Airport" title="Airport" value={this.state.airport} onChange={this.handleAirportChange}>
                                            <option value={0}>Select Airport</option>
                                            {this.state.airportList && this.state.airportList.map((item, i) => (
                                                <option key={i} value={item.airportId}>{item.code} - {item.airportName}</option>
                                            ))
                                            }
                                        </select>
                                        {this.state.errors["airport"].length > 0 && <span className="error invalid-feedback">{this.state.errors["airport"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                                    <div className="form-group">
                                    </div>
                                </div>
                                <div className="col-xs-7 col-sm-7 col-md-7 col-lg-7">
                                    <div className="form-group">
                                        <div id="airportRoute" className="displayNone">
                                            <div className="row">
                                                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3"><label><b>Airport</b></label></div>
                                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4"><label><b>Distance Km</b></label></div>
                                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4"><label><b>Duration</b></label></div>
                                                <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1">&nbsp;</div>
                                            </div>
                                            <div className="row">
                                                <div id="airport_details_1" className="displayNone">
                                                    <div className="row">
                                                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3"><label id="airport_1">Airport</label><input type="hidden" id="airport_hid_1" /></div>
                                                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4"><input type="text" className="form-control form-textbox form-control-sm" id="totalDistance_1" name="totalDistance_1" maxLength="50" /></div>
                                                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4"><input type="text" className="form-control form-textbox form-control-sm" id="totalDuration_1" name="totalDuration_1" maxLength="50" /></div>
                                                        <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1"><a class="btn-remove" title="Click here to Remove Airport."
                                                            onClick={(e) => { this.closeAirportRoute(e, 1) }}><i class="fa fa-times"></i></a></div>
                                                    </div>
                                                </div>
                                                <div id="airport_details_2" className="displayNone">
                                                    <div className="row">
                                                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3"><label id="airport_2">Airport</label><input type="hidden" id="airport_hid_2" /></div>
                                                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4"><input type="text" className="form-control form-textbox form-control-sm" id="totalDistance_2" name="totalDistance_2" maxLength="50" /></div>
                                                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4"><input type="text" className="form-control form-textbox form-control-sm" id="totalDuration_2" name="totalDuration_2" maxLength="50" /></div>
                                                        <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1"><a class="btn-remove" title="Click here to Remove Airport."
                                                            onClick={(e) => { this.closeAirportRoute(e, 2) }}><i class="fa fa-times"></i></a></div>
                                                    </div>
                                                </div>
                                                <div id="airport_details_3" className="displayNone">
                                                    <div className="row">
                                                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3"><label id="airport_3">Airport</label><input type="hidden" id="airport_hid_3" /></div>
                                                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4"><input type="text" className="form-control form-textbox form-control-sm" id="totalDistance_3" name="totalDistance_3" maxLength="50" /></div>
                                                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4"><input type="text" className="form-control form-textbox form-control-sm" id="totalDuration_3" name="totalDuration_3" maxLength="50" /></div>
                                                        <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1"><a class="btn-remove" title="Click here to Remove Airport."
                                                            onClick={(e) => { this.closeAirportRoute(e, 3) }}><i class="fa fa-times"></i></a></div>
                                                    </div>
                                                </div>
                                                <div id="airport_details_4" className="displayNone">
                                                    <div className="row">
                                                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3"><label id="airport_4">Airport</label><input type="hidden" id="airport_hid_4" /></div>
                                                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4"><input type="text" className="form-control form-textbox form-control-sm" id="totalDistance_4" name="totalDistance_4" maxLength="50" /></div>
                                                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4"><input type="text" className="form-control form-textbox form-control-sm" id="totalDuration_4" name="totalDuration_4" maxLength="50" /></div>
                                                        <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1"><a class="btn-remove" title="Click here to Remove Airport."
                                                            onClick={(e) => { this.closeAirportRoute(e, 4) }}><i class="fa fa-times"></i></a></div>
                                                    </div>
                                                </div>
                                                <div id="airport_details_5" className="displayNone">
                                                    <div className="row">
                                                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3"><label id="airport_5">Airport</label><input type="hidden" id="airport_hid_5" /></div>
                                                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4"><input type="text" className="form-control form-textbox form-control-sm" name="totalDistance_5" name="totalDistance_5" maxLength="50" /></div>
                                                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4"><input type="text" className="form-control form-textbox form-control-sm" name="totalDuration_5" name="totalDuration_5" maxLength="50" /></div>
                                                        <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1"><a class="btn-remove" title="Click here to Remove Airport."
                                                            onClick={(e) => { this.closeAirportRoute(e, 5) }}><i class="fa fa-times"></i></a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success btn-sm" onClick={this.handleAddEditServiceAreaZoneSubmit} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Save"><i className="fas fa-save"></i> Save</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeaddEditServiceAreaZoneModal} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <HotelMappingComponent ref="hotelMappingComponent" />
            </div>

        </div >)
    }
}

