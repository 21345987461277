import React, { Component } from 'react';
import DataGrid, {
    Column,
    Editing,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
    RequiredRule,
    PatternRule
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1

import Modal from 'react-modal'; //npm install --save react-modal
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Swal from 'sweetalert2';
import { LoadPanel } from 'devextreme-react/load-panel';
import { getRequestParams } from '../../helpers/globalfunctions.js';
export class CustomerVehicleTypeCommissionSettingComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
            loadPanelVisible: false,
            isSubmitted: false,
            commissionSettingGridDataSource: null,
            commissionSettingGridDataSourceClone: [],
            customerId: 0,
            customerName: ''
        }
    }
    getCommissionSettingList = async (customerId) => {
        this.setState({ loadPanelVisible: true });
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch(`customer-api/${customerId}/commission-setting`, requestParams);
            if (response.ok) {
                const data = await response.json();
                if (data.isSuccess) {
                    let cloneCommission = JSON.parse(JSON.stringify(data.commissions));
                    this.setState({
                        commissionSettingGridDataSource: data.commissions,
                        commissionSettingGridDataSourceClone: cloneCommission
                    });
                }
            }
            else {
                Swal.fire({
                    allowOutsideClick: false,
                    showCloseButton: true,
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Unable to process the request.',
                })
            }
        } catch (ex) {
            console.log(ex);
            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong while fetching data!',
            })
        }
        this.setState({ loadPanelVisible: false });
    };
    handleCloseModal = async () => {
        this.setState({   
            isOpenModal: false,
            loadPanelVisible: false,
            isSubmitted: false,
            commissionSettingGridDataSource: null,
            commissionSettingGridDataSourceClone: [],
            customerId: 0,
            customerName: ''
        });
    }
    handleShowModal = async (customerId,customerName) => {
        try {
            this.setState({ isOpenModal: true, customerId: customerId, customerName: customerName})
            await this.getCommissionSettingList(customerId);
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnEditorPreparing = (e) => {
        if (e.parentType === 'dataRow' && e.dataField === 'commission') {
            e.editorOptions.maxLength = 6;
            //e.editorOptions.onValueChanged = function (e) {
            //    if (Number(e.value.split('.')[0]) > 99) {                    
            //    }
            //};
        }
    }
    handleOnSaving = (e) => {
        let value = e.changes?.[0].data?.commission;
        if (Number(value) >= 100) {
            e.cancel = true;
            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                icon: 'error',
                title: '',
                text: 'Commission percent must have valid format!',
            })
        }
    }    
    handleSubmit = async event => {
        event.preventDefault();
        try {
            if (this.isFormValid()) {
                this.setState({ isSubmitted: true });
                //let changedCommission = this.state.commissionSettingGridDataSource.filter(cc => !this.state.commissionSettingGridDataSourceClone.some(co => cc.apiVehicleTypeId === co.apiVehicleTypeId && Number(cc.commission) === Number(co.commission)));
                var ccList = [];
                for (var i = 0; i < this.state.commissionSettingGridDataSource.length; i++) {
                    let cc = {};
                    cc.apiVehicleTypeId = Number(this.state.commissionSettingGridDataSource[i].apiVehicleTypeId);
                    cc.commission = Number(this.state.commissionSettingGridDataSource[i].commission);
                    ccList.push(cc);
                }
                var request = {};
                request.Commissions = [];
                request.Commissions = ccList;                
                request.History = await this.setCommissionHistory();
                const requestParams = getRequestParams('POST', request);
                const response = await fetch(`customer-api/${Number(this.state.customerId)}/save-commission-setting`, requestParams);
                const data = await response.json();
                if (response.ok) {
                    await Swal.fire({
                        allowOutsideClick: false,
                        icon: data.isSuccess ? 'success' : 'error',
                        title: data.isSuccess ? 'Success' : 'Oops...',
                        text: data.isSuccess ? `Commission applied for customer: [ ${this.state.customerName} ] successfully!` : `Error! has occurred while aplying commission for [ ${this.state.customerName} ]`,
                    }).then(async (result) => {
                        if (data.isSuccess) {
                            if (result.isConfirmed) {
                                await this.handleCloseModal();
                            }
                        }
                    })
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Unable to process the request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false })
    }
    isFormValid() {
        return true;
    }
    setCommissionHistory = async () => {
        let history = '';
        try {
            //const objectToList = Object.keys(this.state.commissionSettingGridDataSourceClone).map(i => this.state.commissionSettingGridDataSourceClone[i])
            //let changedCommission = this.state.commissionSettingGridDataSource.filter(cc => !this.state.commissionSettingGridDataSourceClone.some(co => cc.apiVehicleTypeId === co.apiVehicleTypeId && Number(cc.commission) === Number(co.commission)));
            for (var i = 0; i < this.state.commissionSettingGridDataSource.length; i++) {
                let oldC = Number(this.state.commissionSettingGridDataSourceClone[i].commission);
                let newC = Number(this.state.commissionSettingGridDataSource[i].commission);
                if (oldC !== newC) {
                    if (history === '') {
                        history =`commission changed [ ${this.state.commissionSettingGridDataSourceClone[i].vehicleType} : ${String(oldC)} To ${String(newC)} ] `;
                    }
                    else {
                        history += `, [ ${this.state.commissionSettingGridDataSourceClone[i].vehicleType} : ${String(oldC)} To ${String(newC)} ]`;
                    }
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        return 'Customer vehicle type ' + (history.trim() === '' ? 'commission update successfully' : ` ${history}`);
    };
    render() {
        const renderGridCell = (data) => {
            let cellColor = (data.data.commission > 0) ? 'blue' : 'yellowgreen';
            return <div style={{ color: cellColor }}>{data.data.commission}</div>;
        }
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{content: {width: '45%'}}}
                    className={"react-modal"}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form style={this.state.isSubmitted ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Customer Vehicle Type Commission</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <label>Customer <i><strong className="text-danger">{this.state.customerName}</strong></i></label>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="widget-container">
                                        <DataGrid
                                            dataSource={this.state.commissionSettingGridDataSource}
                                            keyExpr="apiVehicleTypeId"
                                            selection={{ mode: 'single' }}
                                            columnsAutoWidth={true}
                                            showColumnLines={true}
                                            showRowLines={true}
                                            showBorders={true}
                                            rowAlternationEnabled={true}
                                            allowColumnResizing={true}
                                            columnResizingMode='widget'
                                            repaintChangesOnly={true}
                                            onEditorPreparing={this.handleOnEditorPreparing}
                                            onSaving={this.handleOnSaving}
                                        >
                                            <Editing
                                                mode="row"
                                                allowUpdating={true}
                                                selectTextOnEditStart={true}
                                                startEditAction={'click'}
                                            />
                                            <Column dataField="vehicleType" caption="Vehicle Type" allowEditing={false} allowFiltering={false} allowSorting={false} />
                                            <Column dataField="commission" caption="Commission%" dataType="float" alignment="right" width={125}
                                                allowEditing={true} allowUpdating={true} allowFiltering={false} allowSorting={false} cellRender={renderGridCell}>
                                                <RequiredRule />
                                                <PatternRule
                                                    message={'Commission must have valid format!'}
                                                    pattern={'^\\d{0,2}(\\.{0,1})\\d{0,3}$'}
                                                />
                                                
                                            </Column>
                                            <SortByGroupSummaryInfo summaryItem="count" />
                                            <GroupPanel visible={false} />
                                            <HeaderFilter visible={true} />
                                            <SearchPanel visible={false} highlightCaseSensitive={false} width={240} placeholder="Search..." />
                                            <Grouping autoExpandAll={true} />
                                            <Paging defaultPageSize={10} />
                                            <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true} />
                                            <Export enabled={false} allowExportSelectedData={false} />
                                            <ColumnChooser enabled={false} />
                                            <ColumnFixing enabled={false} />
                                        </DataGrid>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" ref={button => this.submitButtonValidator = button} onClick={this.handleSubmit}>
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleCloseModal}><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}