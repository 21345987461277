import React, { Component } from 'react';
import { LoadPanel } from 'devextreme-react/load-panel';
import Modal from 'react-modal'; //npm install --save react-modal
import { getRequestParams } from '../../helpers/globalfunctions.js';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import { SelectBox } from 'devextreme-react/select-box';
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'
export class VehicleReimbDeductionHistoryReportComponent extends Component {
    constructor() {
        super()
        this.state = {
            isSubmitted: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            payrollPeriodDropdownList: [],
            payrollPeriodDropdownData: null,
            payDate: '',
            isDetailsReport: true,
            isSummaryReport: false,
            errors: {
                payDate: '',
                reportType: ''
            }
        }
    }
    getPayrollPeriodDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('vehicle-payroll-period-api/dropdown-list/', requestParams);
            const data = await response.json();
            this.setState({
                payrollPeriodDropdownList: data.vehiclePayRollPeriods
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getPayrollPeriodDropdownList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isEmailSubmitted: false,
                isOpenModal: false,
                loadPanelVisible: false,
                payrollPeriodDropdownList: [],
                payrollPeriodDropdownData: null,
                payDate: '',
                isDetailsReport: true,
                isSummaryReport: false,
                errors: {
                    payDate: '',
                    reportType: ''
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handlePayrollPeriodOnChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.payDate = '';
            let payDate = '';
            if (data !== null) {
                payDate = (data.selectedItem != null ? data.selectedItem.payDate : "");
            }
            else {
                //errors.payDate = 'Please select payroll period.';
            }
            this.setState({ payrollPeriodDropdownData: data, payDate: payDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnInputChange = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
            let errors = this.state.errors;
            switch (name) {
                case 'isDetailsReport':
                    errors.reportType = '';
                    this.setState({ isDetailsReport: value, isSummaryReport: false });
                    break;
                case 'isSummaryReport':
                    errors.reportType = '';
                    this.setState({ isDetailsReport: false, isSummaryReport: value });
                    break;
            }
            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnReportSubmit = async (event) => {
        event.preventDefault();
        try {
            this.setState({ isSubmitted: true });
            await this.downloadAsPdf();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false });
    }
    downloadAsPdf = async () => {
        try {
            if (await this.isFormValid()) {
                let payDate = '';
                let reportType = 0;
                if (this.state.isDetailsReport === true) {
                    reportType = 1;
                }
                else {
                    reportType = 2;
                }
                if (this.state.payDate.trim() !== '') {
                    payDate = moment(this.state.payDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00'
                }
                let reportHeader = await this.setSelectionCrieteria();
                await this.downloadReport(reportType, payDate, reportHeader, 'pdf');
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    downloadReport = async (reportType, payDate, reportHeader, fileFormat) => {
        try {
            var request = new Object();
            request.LocationId = Number(sessionStorage.getItem("session_locationId"));
            request.CurrentDate = payDate;
            request.ReportType = Number(reportType);
            request.SelectionCrieteria = reportHeader;
            request.fileFormat = fileFormat;
            request.MethodName = "GetVehicleReimbDedHistoryReport";
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            let a = document.createElement('a');
            a.href = dataResponse.reportBase64String;
            a.download = 'VehicleReimbursmentDeductionHistory.' + fileFormat;
            a.click();
        } catch (ex) {
            console.log(ex);
        }
    }
    isFormValid = async () => {
        let isValid = true;
        try {
            let errors = this.state.errors;
            errors.payDate = '';
            errors.reportType = '';
            if (this.state.payDate.trim() === '') {
                errors.payDate = 'Please select payroll period.';
                isValid = false;
            }
            if (this.state.isDetailsReport === false && this.state.isSummaryReport === false) {
                errors.reportType = 'Please select report type.';
                isValid = false;
            }
            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    setSelectionCrieteria = async () => {
        let selectionCrieteria = 'Vehicle reimbursment deduction history for';
        try {
            if (this.state.payDate.trim() !== '' && Object.keys(this.state.payrollPeriodDropdownData).length > 0) {
                selectionCrieteria += ' payroll period - ' + this.state.payrollPeriodDropdownData.viewDate;
            }
            else {
                selectionCrieteria = '';
            }
        } catch (ex) {
            selectionCrieteria = '';
            console.log(ex);
        }
        return selectionCrieteria;
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isEmailSubmitted: true });
                let payDate = '';
                let reportType = 0;
                if (this.state.isDetailsReport === true) {
                    reportType = 1;
                }
                else {
                    reportType = 2;
                }
                if (this.state.payDate.trim() !== '') {
                    payDate = moment(this.state.payDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00'
                }

                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.CurrentDate = payDate;
                request.ReportType = Number(reportType);
                request.SelectionCrieteria = await this.setSelectionCrieteria();;
                request.fileFormat = 'pdf';
                request.MethodName = "GetVehicleReimbDedHistoryReport";
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Vehicle Reimbursement Deduction History';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = 'VehicleReimbursmentDeductionHistory.pdf'
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '30%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleOnReportSubmit}
                        style={(this.state.isSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Vehicle Reimbursement Deduction History'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Reimbursment Period</label>
                                        <SelectBox
                                            dataSource={this.state.payrollPeriodDropdownList.length > 0 ? this.state.payrollPeriodDropdownList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select reimbursment period"
                                            name="ddpayrollperiod"
                                            defaultValue={[]}
                                            value={this.state.payrollPeriodDropdownData}
                                            displayExpr='viewDate'
                                            valueExpr='payDate'
                                            onSelectionChanged={this.handlePayrollPeriodOnChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["payDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["payDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Report Type</label><br />
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isDetailsReport"
                                                checked={this.state.isDetailsReport}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isDetailsReport">  Details</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isSummaryReport"
                                                checked={this.state.isSummaryReport}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isSummaryReport"> Summary</label>
                                        </div>
                                        {this.state.errors["reportType"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["reportType"]}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="submit" className="btn btn-success btn-sm" title="Download">
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                             </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}