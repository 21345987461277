import React, { Component } from 'react';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions';

export class ClockComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentlocationdate: formatDate(new Date(), "MM/dd/yyyy hh:mm:ss"),
        }

        this.getLocationTime = this.getLocationTime.bind(this);
        this.getEstOffset = this.getEstOffset.bind(this);
        this.getEstOffset1 = this.getEstOffset1.bind(this);
    }

    componentDidMount() {
        this.interval = setInterval(this.getLocationTime, 1000);
        //this.getLocationTime();
    }

    getLocationTime() {
        try {
            let currentDate = new Date();

            let offset = parseInt(sessionStorage.getItem("session_minutesDifference"));
            let isDayLightApply = sessionStorage.getItem("session_isDayLightApply");
            
            let tmpdLStartDateTime = new Date(sessionStorage.getItem("session_dlStartTime"));
            let tmpdLEndDateTime = new Date(sessionStorage.getItem("session_dlEndTime"));

            let dLStartDateTime = formatDate(new Date(currentDate.getFullYear(), tmpdLStartDateTime.getMonth(), tmpdLStartDateTime.getDate()
                , tmpdLStartDateTime.getHours(), tmpdLStartDateTime.getMinutes(), tmpdLStartDateTime.getSeconds()), "MM/dd/yyyy hh:mm:ss");

            let dLEndDateTime = formatDate(new Date(currentDate.getFullYear(), tmpdLEndDateTime.getMonth(), tmpdLEndDateTime.getDate()
                , tmpdLEndDateTime.getHours(), tmpdLEndDateTime.getMinutes(), tmpdLEndDateTime.getSeconds()), "MM/dd/yyyy hh:mm:ss");
            
            //let dLStartDateTime = formatDate(sessionStorage.getItem("session_dlStartTime"), "MM/dd/yyyy hh:mm:ss");
            //let dLEndDateTime = formatDate(sessionStorage.getItem("session_dlEndTime"), "MM/dd/yyyy hh:mm:ss");
            let dLTimeDifference = parseInt(sessionStorage.getItem("session_dlTimeDifference"));            
            
            var istDateTime = new Date(currentDate.getTime() + (60000 * (currentDate.getTimezoneOffset() + offset)));            
            //const estOffset = this.getEstOffset(istDateTime);

            if (isDayLightApply === "true") {
                if (formatDate(currentDate, "MM/dd/yyyy hh:mm:ss") >= dLStartDateTime && formatDate(currentDate, "MM/dd/yyyy hh:mm:ss") <= dLEndDateTime) {                    
                    istDateTime = new Date(istDateTime.getTime() + (60000 * dLTimeDifference));
                }
            }

            let locationdate = formatDate(istDateTime, "MM/dd/yyyy hh:mm:ss");
            sessionStorage.setItem('session_locationTime', locationdate);
            this.setState({ currentlocationdate: locationdate });

            //const d = new Date();
            //const localTime = d.getTime();
            ////const localOffset = d.getTimezoneOffset() * 60 * 1000;
            //const localOffset = offset * 60 * 1000;
            //const utcTime = localTime + localOffset;
            //const estOffset1 = this.getEstOffset1();
            //const usa = utcTime + (60 * 60 * 1000 * estOffset1);

            //// convert msec value to date string
            //const nd = new Date(usa)
            //let locationdate1 = formatDate(nd, "MM/dd/yyyy hh:mm:ss");
            //sessionStorage.setItem('session_locationTime', locationdate1);
            //this.setState({ currentlocationdate: locationdate1 });            

        } catch (e) {
        }
    }

    getEstOffset1() {
        const stdTimezoneOffset = () => {
            var jan = new Date(0, 1)
            var jul = new Date(6, 1)
            return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset())
        }

        var today = new Date()

        const isDstObserved = (today) => {
            return today.getTimezoneOffset() < stdTimezoneOffset()
        }

        if (isDstObserved(today)) {
            return -4
        } else {
            return -5
        }
    }

    getEstOffset(locDateTime) {
        const stdTimezoneOffset = () => {
            var jan = new Date(0, 1)
            var jul = new Date(6, 1)
            return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset())
        }

        var today = new Date(locDateTime);
        
        const isDstObserved = (today) => {
            return today.getTimezoneOffset() < stdTimezoneOffset()
        }
        
        if (isDstObserved(today)) {
            return -4
        } else {
            return -5
        }
    }

    render() {
        return (
            <div className="clock">
                <div>
                    {this.state.currentlocationdate} 
                    {/*{this.props.date.toLocaleTimeString(this.props.country, { hour12: false, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', seconds: '2-digit', timeZone: this.props.timeZone })}*/}
                </div>
            </div>
        );
    }
}