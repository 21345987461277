import React, { Component } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import { DateFormat } from '../../helpers/fixcodes';
import { SelectBox } from 'devextreme-react/select-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import DatePicker from "react-datepicker"; // npm install react - datepicker --save
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
export class VehicleDeductionChargeComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            isLoanPayScheduleSubmit: false,
            isOpenModal: false,
            isOpenLoanPayScheduleModal: false,
            isOpenCreditReimbursmentModal: false,
            loadPanelVisible: false,
            loadPanelMessage: 'Loading...',
            loadPanelWidth: '15%',
            isAdd: false,
            isEdit: false,
            isViewOnly: false,
            vehicleDeductionId: 0,
            vehicleOwnerMapId: 0,
            vehicleId: 0,
            vehicleNumber: '',
            vehicleOwnerId: 0,
            vehicleOwnerName: '',
            deductionId: 0,
            isCredit: false,
            creditLoanDeductionAmount: 0,
            pcRecoverVehicleDeductionId: 0,
            isAddToPayroll: false,
            payRollDate: null,
            deductionDropdownList: [],
            deductionDropdownData: null,
            vehicleDeductionDetail: null,
            payingVehicleDropdownList: [],
            payingVehicleDropdownData: null,
            vehicleDeductionParam: {
                reference: '',
                openingBalance: 0,
                startDate: new Date(),
                isUntilDate: false,
                endDate: null,
                isActive: false,
                isOneTime: false,
                isRegular: false,
                isLoan: false,
                recurringCharge: 0,
                note: '',
                firstDay: 0,
                secondDay: 0,
                startingAmount: 0,
                downPayment: 0,
                loanAmount: 0,
                noOfPayouts: 0,
                perTermPrinciple: 0,
                interestPercent: 0,
                perTermInterest: 0,
                salesTaxPercent: 0,
                salesTaxAmount: 0,
                totalDueAmount: 0,
                isSplitDeduction: false,
                prevIsSplitDeduction: false,
                isUsedVehicleDeduction: false,
                loanPayScheduleList: [],
                vehiclePayCreditId: 0,
            },
            creditReimbursment: {
                isCurrentPayroll: false,
                reimbursmentDate: null,
                deductionName: '',
                previousCreditLoanAmount: 0,
                vehiclePayPeriodDropdownList: [],
            },
            controllDisability: {
                isOneTimeDisabled: false,
                isRegularDisabled: false,
                isLoanDisabled: false,
            },
            errors: {
                deduction: '',
                startDate: '',
                endDate: '',
                payingVehicle: '',
                reimbursmentDate: '',
                recurringCharge: '',
                startingAmount: '',
                noOfPayouts: ''
            }
        }
    }
    getDeductionDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('vehicle-deduction-api/dropdown-deduction/', requestParams);
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    deductionDropdownList: data.deductions
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    getOwnerVehiclesList = async (vehicleOwnerId, vehicleId) => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('vehicle-deduction-api/' + Number(vehicleOwnerId) + '/dropdown-owner-vehicle', requestParams);
            if (response.ok) {
                const data = await response.json();
                if (data.onwerVehicles !== null) {
                    let dataList = data.onwerVehicles.filter(f => Number(f.latestVehicleOwnerMapId) > 0 && Number(f.vehicleId) !== Number(vehicleId));
                    this.setState({
                        payingVehicleDropdownList: dataList
                    });
                }
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    getVehicleDeductionDetail = async (vehicleDeductionId, vehicleId, vehicleOwnerMapId) => {
        try {
            var request = new Object();
            request.VehicleId = Number(vehicleId);
            request.VehicleOwnerMapId = Number(vehicleOwnerMapId);
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('vehicle-deduction-api/' + Number(vehicleDeductionId) + '/list', requestParams);
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    vehicleDeductionDetail: data.vehicleDeductions
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    getVehicleDeductionSplittedVehicles = async (vehicleDeductionId, isSplitDeduction) => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('vehicle-deduction-api/' + Number(vehicleDeductionId) + '/splitted-vehicles', requestParams);
            if (response.ok) {
                const data = await response.json();
                let payingVehicleDropdownData = [];
                let setIsSplitDeduction = false;
                if (isSplitDeduction === true) {
                    if (data.vehicleDeductionSplittedVehicles !== null) {
                        if (data.vehicleDeductionSplittedVehicles.length > 0) {
                            setIsSplitDeduction = true;
                            data.vehicleDeductionSplittedVehicles.forEach((item) => {
                                let splittedVehicle = new Object();
                                splittedVehicle.vehicleId = Number(item.vehicleId);
                                splittedVehicle.vehicleNumber = item.vehicleNumber;
                                for (var i = 0; i < this.state.payingVehicleDropdownList.length; i++) {
                                    if (Number(this.state.payingVehicleDropdownList[i].vehicleId) === Number(item.vehicleId)) {
                                        var existVehicle = payingVehicleDropdownData.filter(v => Number(v.vehicleId) === Number(item.vehicleId))
                                        if (existVehicle.length === 0) {
                                            payingVehicleDropdownData.push(splittedVehicle);
                                        }
                                    }
                                }
                            });
                        }
                    }
                }
                let vehicleDeductionParam = this.state.vehicleDeductionParam;
                vehicleDeductionParam.isSplitDeduction = setIsSplitDeduction;
                vehicleDeductionParam.prevIsSplitDeduction = setIsSplitDeduction;
                this.setState({
                    payingVehicleDropdownData: payingVehicleDropdownData,
                    vehicleDeductionParam: vehicleDeductionParam
                });
            }
        } catch (ex) {
            debugger;
            console.log(ex);
        }
    }
    getValidateRecurringChargeDetails = async (vehicleDeductionId, deductionId, vehicleId, vehicleOwnerMapId, payDate) => {
        let chargeDetails = null;
        try {
            var request = new Object();
            request.DeductionId = Number(deductionId);
            request.VehicleId = Number(vehicleId);
            request.VehicleOwnerMapId = Number(vehicleOwnerMapId);
            request.PayDate = payDate;
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('vehicle-deduction-api/' + Number(vehicleDeductionId) + '/validate-recurring-charge', requestParams);
            if (response.ok) {
                const data = await response.json();
                if (data.validateRecurringChargeDetails !== null) {
                    chargeDetails = data.validateRecurringChargeDetails;
                }
            }
        } catch (ex) {
            debugger;
            console.log(ex);
        }
        return chargeDetails;
    }
    getPayPeriodDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('vehicle-deduction-api/0/dropdown-payperiod', requestParams);
            if (response.ok) {
                let creditReimbursment = this.state.creditReimbursment;
                const data = await response.json();
                if (data.isSuccess) {
                    creditReimbursment.vehiclePayPeriodDropdownList = data.vehiclePayPeriods;
                    this.setState({ creditReimbursment: creditReimbursment });
                }
            }
            else {
                await this.onfailure('error', null, null,)
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    handleShowModal = async (isAdd, isEdit, isView, vehicleDeductionId, vehicleOwnerMapId, vehicleId, vehicleNumber, vehicleOwnerId, vehicleOwnerName, isCredit, creditLoandeductionId, creditLoanDeductionName, creditLoanDeductionAmount, isAddToPayroll, payRollDate) => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getDeductionDropdownList();
            await this.getOwnerVehiclesList(vehicleOwnerId, vehicleId);
            if (isAdd === false && Number(vehicleDeductionId) > 0) {
                await this.getVehicleDeductionDetail(vehicleDeductionId, vehicleId, vehicleOwnerMapId);
            }

            let deductionDropdownData = null;
            if (isAdd === true && isCredit === true) {
                deductionDropdownData = { id: creditLoandeductionId === null ? 0 : Number(creditLoandeductionId), name: creditLoanDeductionName === null ? '' : creditLoanDeductionName.trim() };
            }

            this.setState({
                isOpenModal: true,
                isAdd: isAdd,
                isEdit: isEdit,
                isViewOnly: isView,
                vehicleDeductionId: vehicleDeductionId,
                vehicleOwnerMapId: vehicleOwnerMapId,
                vehicleId: vehicleId,
                vehicleNumber: vehicleNumber,
                vehicleOwnerId: vehicleOwnerId,
                vehicleOwnerName: vehicleOwnerName,
                isCredit: isCredit,
                deductionDropdownData: deductionDropdownData,
                deductionId: isCredit ? Number(creditLoandeductionId) : 0,
                creditLoanDeductionAmount: isCredit ? Number(creditLoanDeductionAmount) : 0,
                isAddToPayroll: isAddToPayroll,
                payRollDate: isAddToPayroll ? ((payRollDate === null || payRollDate === '') ? new Date() : new Date(payRollDate)) : null
            });
        } catch (ex) {
            debugger;
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleAssignDataToState = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            let vehicleDeductionParam = this.state.vehicleDeductionParam;
            let deductionId = this.state.deductionId;
            if (this.state.isAdd) {
                if (this.state.isAddToPayroll === true) {
                    vehicleDeductionParam.startDate = this.state.payRollDate;
                }
                else {
                    vehicleDeductionParam.startDate = new Date();
                }
                vehicleDeductionParam.endDate = null;
                vehicleDeductionParam.isUntilDate = true;
                vehicleDeductionParam.isActive = true;
                vehicleDeductionParam.firstDay = 15;
                vehicleDeductionParam.secondDay = 31;
                this.setState({
                    vehicleDeductionParam: vehicleDeductionParam
                });
            }
            else {
                if (this.state.vehicleDeductionDetail !== null) {
                    const data = this.state.vehicleDeductionDetail[0];
                    if (Number(data.occurrenceNo) === 1 && Number(data.recurringType) === 1) {
                        let chargeDetails = await this.getValidateRecurringChargeDetails(this.state.vehicleDeductionId, data.deductionId, this.state.vehicleId, 0, null);
                        if (chargeDetails !== null) {
                            if (Number(chargeDetails.usedVehicleDeduction) > 0) {
                                vehicleDeductionParam.isUsedVehicleDeduction = true;
                            }
                        }
                        await this.getVehicleDeductionSplittedVehicles(this.state.vehicleDeductionId, data.isSplitDeduction);
                    }
                    let startDate = (data.startDate === null || data.startDate === '') ? null : new Date(data.startDate);
                    let endDate = (data.isUntilDate === null || data.isUntilDate === false) ? ((data.endDate === null || data.endDate === '') ? null : new Date(data.endDate)) : null;
                    vehicleDeductionParam.reference = data.referenceDetails === null ? '' : data.referenceDetails.trim();
                    vehicleDeductionParam.openingBalance = (data.openingBalance === null || data.openingBalance === '') ? 0 : parseFloat(data.openingBalance).toFixed(2);
                    vehicleDeductionParam.startDate = startDate;
                    vehicleDeductionParam.isUntilDate = data.isUntilDate || false;
                    vehicleDeductionParam.endDate = endDate;
                    vehicleDeductionParam.isActive = data.isActive || false;
                    vehicleDeductionParam.firstDay = (data.dayOne === null || data.dayOne === 0) ? 15 : Number(data.dayOne);
                    vehicleDeductionParam.secondDay = (data.dayTwo === null || data.dayTwo === 0) ? 31 : Number(data.dayTwo);
                    vehicleDeductionParam.isOneTime = (Number(data.occurrenceNo) === 0 && Number(data.recurringType) === 0) ? true : false;
                    vehicleDeductionParam.isRegular = (Number(data.occurrenceNo) === 1 && Number(data.recurringType) === 0) ? true : false;
                    vehicleDeductionParam.isLoan = (Number(data.occurrenceNo) === 1 && Number(data.recurringType) === 1) ? true : false;
                    vehicleDeductionParam.startingAmount = (data.startingPaidAmount === null || data.startingPaidAmount === '') ? 0 : parseFloat(data.startingPaidAmount).toFixed(2);
                    vehicleDeductionParam.downPayment = Number(data.downPaymentAmount).toFixed(2);
                    vehicleDeductionParam.noOfPayouts = Number(data.noOfPayouts);
                    vehicleDeductionParam.interestPercent = Number(data.paInterestPercent).toFixed(2);
                    vehicleDeductionParam.salesTaxPercent = Number(data.salesTaxPercent).toFixed(2);
                    vehicleDeductionParam.salesTaxAmount = Number(data.perTermSalesTaxAmount).toFixed(2);
                    vehicleDeductionParam.loanAmount = Number(data.currentDueAmount).toFixed(2);
                    vehicleDeductionParam.perTermPrinciple = Number(data.perTermPrincipleAmount).toFixed(2);
                    vehicleDeductionParam.perTermInterest = Number(data.perTermInterestAmount).toFixed(2);
                    vehicleDeductionParam.totalDueAmount = Number(data.loanAmount).toFixed(2);
                    vehicleDeductionParam.recurringCharge = Number(data.recurringCharge).toFixed(2);
                    vehicleDeductionParam.note = data.note;
                    vehicleDeductionParam.vehiclePayCreditId = Number(data.vehiclePayCreditId);
                    deductionId = data.deductionId === null ? 0 : Number(data.deductionId);
                    let deductionDropdownData = { id: data.deductionId === null ? 0 : Number(data.deductionId), name: data.deductionName === null ? '' : data.deductionName.trim() };
                    this.setState({
                        deductionId: data.deductionId === null ? 0 : Number(data.deductionId),
                        deductionDropdownData: deductionDropdownData,
                        vehicleDeductionParam: vehicleDeductionParam
                    });
                }
            }
            await this.setOccurrence(deductionId);
        } catch (ex) {
            debugger;
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleResetStateData = async () => {
        try {
            this.setState({
                isSubmited: false,
                isLoanPayScheduleSubmit: false,
                isOpenModal: false,
                isOpenLoanPayScheduleModal: false,
                isOpenCreditReimbursmentModal: false,
                loadPanelVisible: false,
                loadPanelMessage: 'Loading...',
                loadPanelWidth: '15%',
                isAdd: false,
                isEdit: false,
                isViewOnly: false,
                vehicleDeductionId: 0,
                vehicleOwnerMapId: 0,
                vehicleId: 0,
                vehicleNumber: '',
                vehicleOwnerId: 0,
                vehicleOwnerName: '',
                deductionId: 0,
                //isCredit: false,
                creditLoanDeductionAmount: 0,
                //isAddToPayroll: false,
                payRollDate: null,
                deductionDropdownList: [],
                deductionDropdownData: null,
                vehicleDeductionDetail: null,
                payingVehicleDropdownList: [],
                payingVehicleDropdownData: null,
                vehicleDeductionParam: {
                    reference: '',
                    openingBalance: 0,
                    startDate: new Date(),
                    isUntilDate: false,
                    endDate: null,
                    isActive: false,
                    isOneTime: false,
                    isRegular: false,
                    isLoan: false,
                    recurringCharge: 0,
                    note: '',
                    firstDay: 0,
                    secondDay: 0,
                    startingAmount: 0,
                    downPayment: 0,
                    loanAmount: 0,
                    noOfPayouts: 0,
                    perTermPrinciple: 0,
                    interestPercent: 0,
                    perTermInterest: 0,
                    salesTaxPercent: 0,
                    salesTaxAmount: 0,
                    totalDueAmount: 0,
                    isSplitDeduction: false,
                    prevIsSplitDeduction: false,
                    isUsedVehicleDeduction: false,
                    loanPayScheduleList: [],
                    vehiclePayCreditId: 0,
                },
                creditReimbursment: {
                    isCurrentPayroll: false,
                    reimbursmentDate: null,
                    deductionName: '',
                    previousCreditLoanAmount: 0,
                    vehiclePayPeriodDropdownList: [],
                },
                controllDisability: {
                    isOneTimeDisabled: false,
                    isRegularDisabled: false,
                    isLoanDisabled: false,
                },
                errors: {
                    deduction: '',
                    startDate: '',
                    endDate: '',
                    payingVehicle: '',
                    reimbursmentDate: '',
                    recurringCharge: '',
                    startingAmount: '',
                    noOfPayouts: ''
                }
            });
            if (this.state.isCredit === false && this.state.isAddToPayroll === false) {
                await this.props.handleOnRefresh();
            }
            if (this.state.isAddToPayroll === true) {
                await this.props.viewPayDeducions(this.props.viewPayDeducionPayId);
            }
            if (this.state.isCredit === true) {
                await this.props.handleSetRecoverVehicleDeductionId(Number(this.state.pcRecoverVehicleDeductionId));
            }
            this.setState({ isCredit: false, isAddToPayroll: false, pcRecoverVehicleDeductionId: 0, });
        } catch (ex) {
            debugger;
            console.log(ex);
        }
    }
    handleOnDeductionDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.deduction = '';
            let deductionId = 0;
            if (data.selectedItem === null) {
                errors.deduction = 'Please select charge type.';
            }
            else {
                deductionId = (data.selectedItem !== null ? data.selectedItem.id : 0);
            }
            this.setState({ deductionDropdownData: data.selectedItem, errors: errors, deductionId: deductionId });
            await this.setOccurrence(deductionId);
            await this.clearChargeDetails();
        } catch (ex) {
            debugger;
            console.log(ex);
        }
    }
    setOccurrence = async (deductionId) => {
        try {
            let vehicleDeductionParam = this.state.vehicleDeductionParam;
            let controllDisability = this.state.controllDisability;
            if (Number(deductionId) === 0) {
                vehicleDeductionParam.isOneTime = false;
                vehicleDeductionParam.isRegular = false;
                vehicleDeductionParam.isLoan = false;
                controllDisability.isOneTimeDisabled = true;
                controllDisability.isRegularDisabled = true;
                controllDisability.isLoanDisabled = true;
            }
            else {
                if (this.state.deductionDropdownList !== null) {
                    if (this.state.deductionDropdownList.length > 0) {
                        this.state.deductionDropdownList.forEach((item) => {
                            if (Number(item.id) === Number(deductionId)) {
                                if (Number(item.deductionTypeId) === 0) {
                                    vehicleDeductionParam.isOneTime = true;
                                    vehicleDeductionParam.isRegular = false;
                                    vehicleDeductionParam.isLoan = false;
                                    controllDisability.isOneTimeDisabled = false;
                                    controllDisability.isRegularDisabled = false;
                                    controllDisability.isLoanDisabled = true;
                                }
                                if (Number(item.deductionTypeId) === 1 && item.isAdvance === false) {
                                    vehicleDeductionParam.isOneTime = false;
                                    vehicleDeductionParam.isRegular = true;
                                    vehicleDeductionParam.isLoan = false;
                                    controllDisability.isOneTimeDisabled = false;
                                    controllDisability.isRegularDisabled = false;
                                    controllDisability.isLoanDisabled = true;
                                }
                                if (Number(item.deductionTypeId) === 1 && item.isAdvance === true) {
                                    vehicleDeductionParam.isOneTime = false;
                                    vehicleDeductionParam.isRegular = false;
                                    vehicleDeductionParam.isLoan = true;
                                    controllDisability.isOneTimeDisabled = true;
                                    controllDisability.isRegularDisabled = true;
                                    controllDisability.isLoanDisabled = false;
                                }
                            }
                        });
                    }
                }

                if (this.state.isViewOnly === true || this.state.isEdit === true) {
                    controllDisability.isOneTimeDisabled = true;
                    controllDisability.isRegularDisabled = true;
                    controllDisability.isLoanDisabled = true;
                }

                if (this.state.isAdd === true && this.state.isCredit === true) {
                    controllDisability.isRegularDisabled = true;
                }

                if (this.state.isAdd === true && this.state.isAddToPayroll === true) {
                    vehicleDeductionParam.isOneTime = false;
                    vehicleDeductionParam.isRegular = true;
                    vehicleDeductionParam.isLoan = false;
                }
            }

            this.setState({ vehicleDeductionParam: vehicleDeductionParam, controllDisability: controllDisability });
        } catch (ex) {
            debugger;
            console.log(ex);
        }
    }
    clearChargeDetails = async () => {
        try {
            let vehicleDeductionParam = this.state.vehicleDeductionParam;
            vehicleDeductionParam.startingAmount = 0;
            vehicleDeductionParam.downPayment = 0;
            vehicleDeductionParam.loanAmount = 0;
            vehicleDeductionParam.noOfPayouts = 0;
            vehicleDeductionParam.perTermPrinciple = 0;
            vehicleDeductionParam.interestPercent = 0;
            vehicleDeductionParam.perTermInterest = 0;
            vehicleDeductionParam.salesTaxPercent = 0;
            vehicleDeductionParam.salesTaxAmount = 0;
            vehicleDeductionParam.totalDueAmount = 0;
            vehicleDeductionParam.recurringCharge = 0;
            vehicleDeductionParam.isSplitDeduction = false;
            this.setState({
                vehicleDeductionParam: vehicleDeductionParam
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnPayingVehicleDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.payingVehicle = '';
            if (data.selectedItem === null || data.selectedItem.length === 0) {
                if (this.state.vehicleDeductionParam.isSplitDeduction === true) {
                    errors.payingVehicle = 'Please select the vehicle to split.';
                }
            }
            this.setState({ payingVehicleDropdownData: data.selectedItem, errors: errors });
        } catch (ex) {
            debugger;
            console.log(ex);
        }
    }
    handleOnStartDateChange = async (date) => {
        try {
            let errors = this.state.errors;
            errors.startDate = '';
            let dateExp = null;
            let vehicleDeductionParam = this.state.vehicleDeductionParam;
            if (date !== null) {
                //dateExp = new Date(moment(date).add(15, 'days').format("MM/DD/YYYY"));
                let prevDate = formatDate(vehicleDeductionParam.startDate, DateFormat.DefaultDateTimeFormat);
                let newDate = formatDate(date, DateFormat.DefaultDateTimeFormat);
                if (moment(prevDate, "MM/DD/YYYY").isSame(newDate, "MM/DD/YYYY") === false) {
                    await this.calculateLoan(this.state.vehicleDeductionParam.startingAmount, this.state.vehicleDeductionParam.downPayment, this.state.vehicleDeductionParam.noOfPayouts, this.state.vehicleDeductionParam.interestPercent, this.state.vehicleDeductionParam.salesTaxPercent);
                }
            }
            else {
                errors.startDate = 'Please enter start date.';
            }
            vehicleDeductionParam.startDate = date;
            this.setState({ vehicleDeductionParam: vehicleDeductionParam, endDate: dateExp, errors: errors });
        } catch (ex) {
            debugger;
            console.log(ex);
        }
    }
    handleOnEndDateChange = async (date) => {
        try {
            let errors = this.state.errors;
            errors.endDate = '';
            let vehicleDeductionParam = this.state.vehicleDeductionParam;
            vehicleDeductionParam.endDate = date;
            if (date === null) {
                if (this.state.vehicleDeductionParam.isUntilDate === false) {
                    errors.endDate = 'Please enter end date.';
                }
            }
            this.setState({ vehicleDeductionParam: vehicleDeductionParam, errors: errors });
        } catch (ex) {
            debugger;
            console.log(ex);
        }
    }
    handleOnInputChange = async (event) => {
        try {
            const target = event.target;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
            const name = target.name;
            const regexDecimal = /^\d*(\.\d{0,10})?$/;
            //const regexDecimal = /^\d{0,9}(\.{0,1})\d{0,10}$/;
            const regexNumberOnly = /^\d+$/;
            let errors = this.state.errors;
            let vehicleDeductionParam = this.state.vehicleDeductionParam;
            switch (name) {
                case 'reference':
                    if (value.trim().length < 76) {
                        vehicleDeductionParam.reference = value;
                        this.setState({ vehicleDeductionParam: vehicleDeductionParam });
                    }
                    break;
                case 'openingBalance':
                    if (value.trim() === '' || regexDecimal.test(value)) {
                        if (value.trim().length < 10) {
                            vehicleDeductionParam.openingBalance = value;
                        }
                    }
                    this.setState({ vehicleDeductionParam: vehicleDeductionParam });
                    break;
                case 'isUntilDate':
                    errors.endDate = '';
                    vehicleDeductionParam.isUntilDate = value;
                    if (value === false) {
                        if (vehicleDeductionParam.endDate === null) {
                            errors.endDate = 'Please enter end date.';
                        }
                    }
                    else {
                        if (this.state.isAdd) {
                            vehicleDeductionParam.endDate = null;
                        }
                    }
                    this.setState({ vehicleDeductionParam: vehicleDeductionParam });
                    break;
                case 'isOneTime':
                    vehicleDeductionParam.isRegular = false;
                    vehicleDeductionParam.isLoan = false;
                    vehicleDeductionParam.isOneTime = value;
                    this.setState({ vehicleDeductionParam: vehicleDeductionParam });
                    break;
                case 'isRegular':
                    vehicleDeductionParam.isOneTime = false;
                    vehicleDeductionParam.isLoan = false;
                    vehicleDeductionParam.isRegular = value;
                    this.setState({ vehicleDeductionParam: vehicleDeductionParam });
                    break;
                case 'isLoan':
                    vehicleDeductionParam.isOneTime = false;
                    vehicleDeductionParam.isRegular = false;
                    vehicleDeductionParam.isLoan = value;
                    if (value === true) {
                        if (this.state.isCredit === true) {
                            vehicleDeductionParam.startingAmount = this.state.creditLoanDeductionAmount;
                        }
                    }
                    this.setState({ vehicleDeductionParam: vehicleDeductionParam });
                    break;
                case 'recurringCharge':
                    errors.recurringCharge = '';
                    if (value.trim() === '' || regexDecimal.test(value)) {
                        if (value.trim().length < 10) {
                            vehicleDeductionParam.recurringCharge = value;
                        }
                    }
                    this.setState({ vehicleDeductionParam: vehicleDeductionParam });
                    break;
                case 'note':
                    vehicleDeductionParam.note = value;
                    this.setState({ vehicleDeductionParam: vehicleDeductionParam });
                    break;
                case 'firstDay':
                    vehicleDeductionParam.firstDay = value;
                    this.setState({ vehicleDeductionParam: vehicleDeductionParam });
                    break;
                case 'secondDay':
                    vehicleDeductionParam.secondDay = value;
                    this.setState({ vehicleDeductionParam: vehicleDeductionParam });
                    break;
                case 'startingAmount':
                    errors.startingAmount = '';
                    if (value.trim() === '' || regexDecimal.test(value)) {
                        if (value.trim().length < 12) {
                            await this.calculateLoan(value, this.state.vehicleDeductionParam.downPayment, this.state.vehicleDeductionParam.noOfPayouts, this.state.vehicleDeductionParam.interestPercent, this.state.vehicleDeductionParam.salesTaxPercent);
                        }
                    }
                    break;
                case 'downPayment':
                    if (value.trim() === '' || regexDecimal.test(value)) {
                        if (value.trim().length < 12) {
                            await this.calculateLoan(this.state.vehicleDeductionParam.startingAmount, value, this.state.vehicleDeductionParam.noOfPayouts, this.state.vehicleDeductionParam.interestPercent, this.state.vehicleDeductionParam.salesTaxPercent);
                        }
                    }
                    break;
                case 'noOfPayouts':
                    errors.noOfPayouts = '';
                    if (value.trim() === '' || regexNumberOnly.test(value)) {
                        if (value.trim().length < 4) {
                            await this.calculateLoan(this.state.vehicleDeductionParam.startingAmount, this.state.vehicleDeductionParam.downPayment, value, this.state.vehicleDeductionParam.interestPercent, this.state.vehicleDeductionParam.salesTaxPercent);
                        }
                    }
                    break;
                case 'interestPercent':
                    if (value.trim() === '' || regexDecimal.test(value)) {
                        if (value.trim().length < 6) {
                            let val = value;
                            if (val.trim() === '100.') {
                                val = '100';
                            }
                            if (isNaN(parseFloat(val)) || parseFloat(val) <= 100) {
                                await this.calculateLoan(this.state.vehicleDeductionParam.startingAmount, this.state.vehicleDeductionParam.downPayment, this.state.vehicleDeductionParam.noOfPayouts, val, this.state.vehicleDeductionParam.salesTaxPercent);
                            }
                        }
                    }
                    break;
                case 'salesTaxPercent':
                    if (value.trim() === '' || regexDecimal.test(value)) {
                        if (value.trim().length < 6) {
                            let val = value;
                            if (val.trim() === '100.') {
                                val = '100';
                            }
                            if (isNaN(parseFloat(val)) || parseFloat(val) <= 100) {
                                await this.calculateLoan(this.state.vehicleDeductionParam.startingAmount, this.state.vehicleDeductionParam.downPayment, this.state.vehicleDeductionParam.noOfPayouts, this.state.vehicleDeductionParam.interestPercent, val);
                            }
                        }
                    }
                    break;
                case 'isSplitDeduction':
                    errors.payingVehicle = '';
                    vehicleDeductionParam.isSplitDeduction = value;
                    if (value === false) {
                        this.setState({ payingVehicleDropdownData: null });
                    }
                    else {
                        if (this.state.payingVehicleDropdownData === null) {
                            errors.payingVehicle = 'Please select the vehicle to split.';
                        }
                    }
                    this.setState({ vehicleDeductionParam: vehicleDeductionParam });
                    break;
                default:
                    break;
            }
            this.setState({ errors: errors });
        } catch (ex) {
            debugger;
            console.log(ex);
        }
    }
    handleOnInputLeave = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            let vehicleDeductionParam = this.state.vehicleDeductionParam;
            switch (name) {
                case 'openingBalance':
                    vehicleDeductionParam.openingBalance =
                        (Number(this.state.vehicleDeductionParam.openingBalance) === 0 ? '' :
                            parseFloat(this.state.vehicleDeductionParam.openingBalance).toFixed(2))
                    break;
                case 'startingAmount':
                    vehicleDeductionParam.startingAmount =
                        (Number(this.state.vehicleDeductionParam.startingAmount) === 0 ? '' :
                            parseFloat(this.state.vehicleDeductionParam.startingAmount).toFixed(2))
                    break;
                case 'downPayment':
                    vehicleDeductionParam.downPayment =
                        (Number(this.state.vehicleDeductionParam.downPayment) === 0 ? '' :
                            parseFloat(this.state.vehicleDeductionParam.downPayment).toFixed(2))
                    break;
                case 'interestPercent':
                    vehicleDeductionParam.interestPercent =
                        (Number(this.state.vehicleDeductionParam.interestPercent) === 0 ? '' :
                            parseFloat(this.state.vehicleDeductionParam.interestPercent).toFixed(2))
                    break;
                case 'salesTaxPercent':
                    vehicleDeductionParam.salesTaxPercent =
                        (Number(this.state.vehicleDeductionParam.salesTaxPercent) === 0 ? '' :
                            parseFloat(this.state.vehicleDeductionParam.salesTaxPercent).toFixed(2))
                case 'recurringCharge':
                    vehicleDeductionParam.recurringCharge =
                        (Number(this.state.vehicleDeductionParam.recurringCharge) === 0 ? '' :
                            parseFloat(this.state.vehicleDeductionParam.recurringCharge).toFixed(2))
                    break;
                default:
                    break;
            }
            this.setState({ vehicleDeductionParam: vehicleDeductionParam });
        } catch (ex) {
            debugger;
            console.log(ex);
        }
    }
    calculateLoan = async (startingAmount, downPayment, noOfPayouts, interestPercent, salesTaxPercent) => {
        try {
            let vehicleDeductionParam = this.state.vehicleDeductionParam;
            let _salesTaxAmount = Number(vehicleDeductionParam.salesTaxAmount); //_sales_tax_amount
            let _loanAmount = Number(vehicleDeductionParam.loanAmount); //_current_due_amount
            let _perTermPrinciple = Number(vehicleDeductionParam.perTermPrinciple); //_per_term_principle_amount
            let _perTermInterest = Number(vehicleDeductionParam.perTermInterest); //_per_term_interest_amount
            let _totalDueAmount = Number(vehicleDeductionParam.totalDueAmount); //_loan_amount
            let _recurringCharge = Number(vehicleDeductionParam.recurringCharge); //_recurring_charge

            if (Number(startingAmount) > 0) {
                _recurringCharge = 0
                _loanAmount = Number(startingAmount) - Number(downPayment);

                noOfPayouts = Number(noOfPayouts) === 0 ? 1 : Number(noOfPayouts);
                if (Number(interestPercent) > 0) {
                    _perTermPrinciple = 0;
                    let ratePerPediod = parseFloat((Number(interestPercent) / 100) / 24);
                    let noOfPayments = noOfPayouts;
                    let persentValue = -(parseFloat(_loanAmount) === 0 ? 0 : parseFloat(_loanAmount));
                    let pmt = await this.fnPMT(Number(ratePerPediod), Number(noOfPayments), Number(persentValue), 0, 0);
                    _recurringCharge = parseFloat(pmt);
                    _perTermInterest = 0;
                    _totalDueAmount = _recurringCharge * noOfPayouts;
                }
                else {
                    _perTermPrinciple = _loanAmount / noOfPayouts;
                    _recurringCharge = _perTermPrinciple;
                    _perTermInterest = 0;
                    _totalDueAmount = _loanAmount;
                }
                if (Number(_recurringCharge) > 0) {
                    _salesTaxAmount = 0;
                    if (Number(salesTaxPercent) > 0) {
                        _salesTaxAmount = (_recurringCharge * Number(salesTaxPercent)) / 100;
                    }
                }
                _recurringCharge = _recurringCharge + _salesTaxAmount;
            }
            else {
                salesTaxPercent = 0;
                _salesTaxAmount = 0;
                _loanAmount = 0;
                _perTermPrinciple = 0;
                _perTermInterest = 0;
                _totalDueAmount = 0;
                _recurringCharge = 0;
            }
            vehicleDeductionParam.startingAmount = startingAmount;
            vehicleDeductionParam.downPayment = downPayment;
            vehicleDeductionParam.noOfPayouts = noOfPayouts;
            vehicleDeductionParam.interestPercent = interestPercent;
            vehicleDeductionParam.salesTaxPercent = salesTaxPercent;
            vehicleDeductionParam.salesTaxAmount = Number(_salesTaxAmount).toFixed(2);
            vehicleDeductionParam.loanAmount = Number(_loanAmount).toFixed(2);
            vehicleDeductionParam.perTermPrinciple = Number(_perTermPrinciple).toFixed(2);
            vehicleDeductionParam.perTermInterest = Number(_perTermInterest).toFixed(2);
            vehicleDeductionParam.totalDueAmount = Number(_totalDueAmount).toFixed(2);
            vehicleDeductionParam.recurringCharge = Number(_recurringCharge).toFixed(2);
            this.setState({ vehicleDeductionParam: vehicleDeductionParam });
        } catch (ex) {
            debugger;
            console.log(ex);
        }
    }
    calculateLoanEMI = async (noOfPayouts, interestPercent, loanAmount, salesTaxAmount) => {
        try {
            let splittedVehicles = [];
            if (this.state.payingVehicleDropdownData !== null) {
                if (this.state.payingVehicleDropdownData.length > 0) {
                    splittedVehicles = this.state.payingVehicleDropdownData.slice();
                }
            }

            let perTermPrinciple = 0;
            let perTermInterest = 0;
            let dtDueDate = null;
            let vehicleDeductionParam = this.state.vehicleDeductionParam;
            vehicleDeductionParam.loanPayScheduleList = [];
            let vehicle = new Object();
            if (this.state.vehicleNumber === null || this.state.vehicleNumber.trim() === '') {
                return;
            }
            let startDate = formatDate(vehicleDeductionParam.startDate, DateFormat.DefaultDateTimeFormat);
            let _year = moment(startDate, " MM/DD/YYYY");
            _year = moment(_year).format('YYYY');
            if (Number(_year) <= 1990) {
                return;
            }
            if (vehicleDeductionParam.isSplitDeduction === true) {
                if (splittedVehicles === null || splittedVehicles.length === 0) {
                    return;
                }
                else {
                    vehicle = new Object();
                    vehicle.vehicleId = this.state.vehicleId;
                    vehicle.vehicleNumber = this.state.vehicleNumber;
                    splittedVehicles.push(vehicle);
                }
            }
            if (vehicleDeductionParam.isSplitDeduction === false) {
                splittedVehicles = null;
                vehicle = new Object();
                vehicle.vehicleId = this.state.vehicleId;
                vehicle.vehicleNumber = this.state.vehicleNumber;
                let vehicles = [];
                vehicles.push(vehicle);
                splittedVehicles = vehicles;
            }

            let prentVehicleId = this.state.vehicleId;
            let parentVehicleNumber = this.state.vehicleNumber;

            let countVehicle = splittedVehicles.length;
            for (var i = 0; i < countVehicle; i++) {
                vehicle = new Object();
                vehicle = splittedVehicles[i];
                let vehicleId = vehicle.vehicleId;
                let vehicleNumber = vehicle.vehicleNumber;
                if (Number(vehicleId) === Number(this.state.vehicleId)) {
                    prentVehicleId = vehicleId;
                    parentVehicleNumber = vehicleNumber
                }
                if (Number(vehicleId) === 0) {
                    return;
                }
                dtDueDate = vehicleDeductionParam.startDate;
                noOfPayouts = Number(noOfPayouts) === 0 ? 1 : noOfPayouts;
                for (var nopCount = 1; nopCount <= noOfPayouts; nopCount++) {
                    dtDueDate = formatDate(dtDueDate, DateFormat.DefaultDateTimeFormat)
                    let dueDay = Number(moment(dtDueDate, "MM/DD/YYYY").format("D"));
                    let dueMonth = Number(moment(dtDueDate, "MM/DD/YYYY").format("MM"));
                    let DueYear = Number(moment(dtDueDate, "MM/DD/YYYY").format("YYYY"));
                    if (Number(dueDay) <= 15) {
                        dtDueDate = moment(dtDueDate, "MM/DD/YYYY").add((15 - dueDay), 'days');
                    }
                    else {
                        let daysInMonth = Number(moment(dtDueDate, "MM/DD/YYYY").daysInMonth());
                        dtDueDate = moment(dtDueDate, "MM/DD/YYYY").add((daysInMonth - dueDay), 'days');
                    }
                    let ratePerPediod = parseFloat((interestPercent / 100) / 24);
                    let persentValue = -(parseFloat(loanAmount) === 0 ? 0 : parseFloat(loanAmount));
                    perTermInterest = await this.fnIPMT(Number(ratePerPediod), Number(nopCount), Number(noOfPayouts), Number(persentValue), 0, 0);
                    perTermPrinciple = await this.fnPPMT(Number(ratePerPediod), Number(nopCount), Number(noOfPayouts), Number(persentValue), 0, 0);
                    let rowData = new Object();
                    rowData.isParentVehicle = (Number(vehicleId) === Number(prentVehicleId)) ? 1 : 0;
                    rowData.id = Number(nopCount);
                    rowData.vehicleId = Number(vehicleId);
                    rowData.vehicleNumber = vehicleNumber;
                    rowData.prentVehicleId = Number(prentVehicleId);
                    rowData.parentVehicleNumber = parentVehicleNumber;
                    rowData.dueDate = moment(dtDueDate).format("MM/DD/YYYY");
                    rowData.principleAmount = (Number(perTermPrinciple) / countVehicle).toFixed(2);
                    rowData.interestAmount = (Number(perTermInterest) / countVehicle).toFixed(2);
                    rowData.salesTaxAmount = (Number(salesTaxAmount) / countVehicle).toFixed(2);
                    rowData.totalDueAmount = Number((Number(perTermPrinciple) + Number(perTermInterest)) / countVehicle).toFixed(2);
                    let description = '';
                    if (Number(vehicleId) === Number(this.state.vehicleId)) {
                        description = 'Vehicle loan successfully created for vehicle[ ' + parentVehicleNumber + ' ]';
                    }
                    else {
                        description = 'Splitted loan from vehicle[ ' + parentVehicleNumber + ' ]. Vehicle loan successfully created for vehicle[ ' + vehicleNumber + ' ]';
                    }
                    rowData.description = description;
                    vehicleDeductionParam.loanPayScheduleList.push(rowData);
                    dtDueDate = moment(dtDueDate, "MM/DD/YYYY").add((1), 'days');
                }
            }
        } catch (ex) {
            debugger;
            console.log(ex);
        }
    }
    fnPMT = async (r, nper, pv, fv, type) => {
        let returnValue = 0
        try {
            if (!fv) fv = 0;
            if (!type) type = 0;
            if (r === 0) {
                returnValue = -(pv + fv) / nper;
            }
            else {
                var pvif = Math.pow(1 + r, nper);
                returnValue = r / (pvif - 1) * -(pv * pvif + fv);
            }
            if (type === 1) {
                returnValue /= Number(1 + r);
            }
        } catch (ex) {
            debugger;
            console.log(ex);
        }
        return returnValue;
    }
    fnFV = async (r, nper, c, pv, type) => {
        let returnValue = 0
        try {
            if (!type) type = 0;
            var pow = Math.pow(1 + r, nper);
            if (r) {
                returnValue = (c * (1 + r * type) * (1 - pow) / r) - pv * pow;
            } else {
                returnValue = -1 * (pv + c * nper);
            }
        } catch (ex) {
            debugger;
            console.log(ex);
        }
        return returnValue;
    }
    fnIPMT = async (r, per, nper, pv, fv, type) => {
        let returnValue = 0
        try {
            let pmt = await this.fnPMT(r, nper, pv, fv, type);
            returnValue = await this.fnFV(r, (per - 1), pmt, pv, type) * r;
            if (type === 1) {
                returnValue /= (1 + r);
            }
        } catch (ex) {
            debugger;
            console.log(ex);
        }
        return returnValue;
    }
    fnPPMT = async (r, per, nper, pv, fv, type) => {
        let returnValue = 0
        try {
            returnValue = await this.fnPMT(r, nper, pv, fv, type) - await this.fnIPMT(r, per, nper, pv, fv, type);
        } catch (ex) {
            debugger;
            console.log(ex);
        }
        return returnValue;
    }
    handleOnCreditReimbDateChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.reimbursmentDate = '';
            if (data.selectedItem === null) {
                //fromDate = moment(data.payStartDate, 'YYYYMMDD').format('MM/DD/YYYY');
                errors.reimbursmentDate = 'Please select reimbursment period.';
            }
            let creditReimbursment = this.state.creditReimbursment;
            creditReimbursment.reimbursmentDate = data.selectedItem;
            this.setState({ creditReimbursment: creditReimbursment, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnLoanPayScheduleSubmit = async (event) => {
        event.preventDefault();
        try {
            this.setState({ isLoanPayScheduleSubmit: true });
            await new Promise(resolve => setTimeout(resolve, 500)); // 2 sec
            await this.calculateLoanEMI(this.state.vehicleDeductionParam.noOfPayouts, this.state.vehicleDeductionParam.interestPercent, this.state.vehicleDeductionParam.loanAmount, this.state.vehicleDeductionParam.salesTaxAmount)
            this.setState({ isOpenLoanPayScheduleModal: true });
        } catch (ex) {
            debugger;
            console.log(ex);
        }
        this.setState({ isLoanPayScheduleSubmit: false });
    }
    handleOnVehicleDeductionSubmit = async (event) => {
        event.preventDefault();
        try {
            this.setState({ isSubmited: true })
            if (await this.isFormValid()) {
                let confirmMsg = 'Are you sure want to <b>' + (this.state.isAdd ? 'add' : 'update') + '</b> deduction <b>' + this.state.deductionDropdownData.name + '</b> for vehicle <b>'
                    + this.state.vehicleNumber + '</b>?';
                let isConfirm = await this.isConfirm(confirmMsg, 'Yes', 'No');
                if (isConfirm) {
                    let chargeDetails = await this.getValidateRecurringChargeDetails(this.state.vehicleDeductionId, this.state.deductionId, this.state.vehicleId, 0, null);
                    let payEndDate = null;
                    let chequeNo = '';
                    let previousCreditLoanAmount = 0;
                    if (chargeDetails !== null) {
                        chequeNo = chargeDetails.chequeNo;
                        previousCreditLoanAmount = Number(chargeDetails.amount);
                        //payEndDate = (chargeDetails.payEndDate === null || chargeDetails.payEndDate === '') ? null : new Date(chargeDetails.payEndDate);
                        if (moment(new Date(chargeDetails.payEndDate)).isValid() === true) {
                            payEndDate = chargeDetails.payEndDate;
                        }
                    }
                    if (this.state.isAdd === true && this.state.isCredit === false && this.state.vehicleDeductionParam.isLoan === true) {
                        if (payEndDate === null) {
                            await this.onfailure('info', 'Reimbursment not available', 'There is no active reimbursment available. You can not add this loan at this time.');
                        }
                        else {
                            let creditReimbursment = this.state.creditReimbursment;
                            creditReimbursment.previousCreditLoanAmount = previousCreditLoanAmount;
                            creditReimbursment.deductionName = this.state.deductionDropdownData.name;
                            creditReimbursment.reimbursmentDate = null;
                            let reimbursmentViewDate = null;
                            let reimbursmentPayEndDate = null;
                            let confirmMsg = 'Are you sure want to to credit this loan for Reimbursment <b>' + payEndDate + '</b> for vehicle <b>' + this.state.vehicleNumber + '</b>?';
                            let isConfirm = await this.isConfirm(confirmMsg, 'Yes', 'No');
                            if (isConfirm) {
                                reimbursmentViewDate = moment(payEndDate, 'MM/DD/YYYY').format("MMM DD,YY");
                                reimbursmentPayEndDate = moment(payEndDate, 'MM/DD/YYYY').format("YYYYMMDD");
                                creditReimbursment.reimbursmentDate = { viewDate: reimbursmentViewDate, payEndDate: reimbursmentPayEndDate };
                                creditReimbursment.isCurrentPayroll = true;
                            }
                            else {
                                reimbursmentViewDate = moment(formatDate(new Date(), DateFormat.DefaultDateTimeFormat), 'MM/DD/YYYY').format("MMM DD,YY");
                                reimbursmentPayEndDate = moment(formatDate(new Date(), DateFormat.DefaultDateTimeFormat), 'MM/DD/YYYY').format("YYYYMMDD");
                                creditReimbursment.isCurrentPayroll = false;
                                await this.getPayPeriodDropdownList();
                                if (this.state.creditReimbursment.vehiclePayPeriodDropdownList !== null) {
                                    let periodExists = this.state.creditReimbursment.vehiclePayPeriodDropdownList.filter(p => p.payEndDate === reimbursmentPayEndDate);
                                    if (periodExists.length > 0) {
                                        creditReimbursment.reimbursmentDate = { viewDate: reimbursmentViewDate, payEndDate: reimbursmentPayEndDate };
                                    }
                                }
                            }
                            this.setState({ isOpenCreditReimbursmentModal: true, creditReimbursment: creditReimbursment });
                        }
                    }
                    else {
                        let isCreditLoan = false;
                        let goToSave = true;
                        //need ot discuss and remove on onthime with loan in below condition
                        if (this.state.isAdd === false && (this.state.vehicleDeductionParam.isLoan === true || this.state.vehicleDeductionParam.isOneTime === true)) {
                            if (chequeNo === null || chequeNo.trim() === '' || chequeNo.trim() === '0') {
                                isCreditLoan = true;
                            }
                            else {
                                await this.onfailure('info', 'The loan already exists', 'This loan is already credited and paid by check. You can not update this loan.');
                                goToSave = false;
                            }
                        }
                        if (goToSave === true) {
                            let creditLoanPayId = 0;
                            let creditLoanPayDate = formatDate(new Date(), DateFormat.DefaultDateTimeFormat) + ' 23:59:59';
                            await this.insertUpdateDeduction(isCreditLoan, creditLoanPayId, creditLoanPayDate, previousCreditLoanAmount);
                        }
                    }
                }
            }
        } catch (ex) {
            console.log(ex);
            await this.onfailure(null, null, null);
        }
        this.setState({ isSubmited: false })
    }
    handleOnCreditReimbursmentSubmit = async (event) => {
        event.preventDefault();
        try {
            let payId = 0;
            let payDate = null;
            let goToSave = true;
            let errors = this.state.errors;
            errors.reimbursmentDate = '';
            if (this.state.creditReimbursment.reimbursmentDate === null || Object.keys(this.state.creditReimbursment.reimbursmentDate).length === 0) {
                if (this.state.creditReimbursment.isCurrentPayroll === false) {
                    errors.reimbursmentDate = 'Please select reimbursment period.';
                }
                else {
                    errors.reimbursmentDate = 'Invalid reimbursment period.';
                }
                this.setState({ errors: errors });
                goToSave = false;
            }
            else {
                payDate = moment(this.state.creditReimbursment.reimbursmentDate.payEndDate, 'YYYYMMMDD').format('YYYY-MM-DD') + ' 23:59:59';
            }

            if (goToSave === true) {
                let payStartDate = null;
                let chargeDetails = await this.getValidateRecurringChargeDetails(0, 0, this.state.vehicleId, 0, this.state.creditReimbursment.reimbursmentDate.payEndDate);
                if (chargeDetails !== null) {
                    //if (moment(new Date(chargeDetails.payStartDate)).isValid() === true) {
                    if (moment(chargeDetails.payStartDate, 'MM/DD/YYYY').isValid() === true) {
                        payStartDate = chargeDetails.payStartDate;
                        payId = chargeDetails.payId;
                    }
                }
                if (payStartDate === null) {
                    await this.onfailure('info', 'Invalid reimbursment period [' + this.state.creditReimbursment.reimbursmentDate.viewDate + '].', 'You can not add this loan at this time.');
                    goToSave = false;
                }
                else {
                    let cStartDate = formatDate(this.state.vehicleDeductionParam.startDate, DateFormat.DefaultDateTimeFormat);
                    if (moment(payStartDate, 'MM/DD/YYYY').isAfter(cStartDate, 'MM/DD/YYYY')) {
                        let msg = 'Loan deduction start date [ ' + cStartDate + ' ] should be greater or equals to reimbursment <br>start date [ ' + payStartDate + ' ].<br>Please verify start date and then try again.';
                        await this.onfailure('info', 'Invalid reimbursment date', msg);
                        goToSave = false;
                    }
                }
            }
            if (goToSave === true) {
                if (Number(payId) === 0) {
                    let msg = 'Reimbursment not found for vehicle [ ' + this.state.vehicleNumber + ' ] on reimbursment period [ ' + this.state.creditReimbursment.reimbursmentDate.viewDate + ' ]';
                    await this.onfailure('info', 'Invalid reimbursment', msg);
                    goToSave = false
                }
            }

            if (goToSave === true) {
                this.setState({ isOpenCreditReimbursmentModal: false });
                let isCreditLoan = true;
                let creditLoanPayId = payId;
                let creditLoanPayDate = payDate;
                let previousCreditLoanAmount = this.state.creditReimbursment.previousCreditLoanAmount;
                await this.insertUpdateDeduction(isCreditLoan, creditLoanPayId, creditLoanPayDate, previousCreditLoanAmount)
            }
        } catch (ex) {
            debugger;
            console.log(ex);
        }
    }
    insertUpdateDeduction = async (isCreditLoan, creditLoanPayId, creditLoanPayDate, previousCreditLoanAmount) => {
        try {
            this.setState({
                loadPanelVisible: true,
                loadPanelMessage: 'Processing deduction. Please, wait...',
                loadPanelWidth: '20%'
            });
            await new Promise(resolve => setTimeout(resolve, 1000)); // 1 sec
            let request = new Object();
            request.VehicleId = Number(this.state.vehicleId);
            request.VehicleOwnerId = Number(this.state.vehicleOwnerId);
            request.VehicleOwnerMapId = Number(this.state.vehicleOwnerMapId);
            request.DeductionId = Number(this.state.deductionId);
            request.ReferenceDetails = this.state.vehicleDeductionParam.reference.trim();
            request.StartDate = moment(formatDate(this.state.vehicleDeductionParam.startDate, DateFormat.DefaultDateTimeFormat), 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00';
            request.IsUntilDate = this.state.vehicleDeductionParam.isUntilDate;
            if (this.state.vehicleDeductionParam.isUntilDate === false) {
                request.EndDate = moment(formatDate(this.state.vehicleDeductionParam.endDate, DateFormat.DefaultDateTimeFormat), 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00';
            }
            request.OpeningBalance = Number(this.state.vehicleDeductionParam.openingBalance);
            if (this.state.vehicleDeductionParam.isOneTime === true) {
                request.OccurrenceNo = 0;
                request.RecurringType = 0;
            }
            if (this.state.vehicleDeductionParam.isRegular === true) {
                request.OccurrenceNo = 1;
                request.RecurringType = 0;
                request.RecurringCharge = Number(this.state.vehicleDeductionParam.recurringCharge);
                request.Note = this.state.vehicleDeductionParam.note.trim();
            }
            if (this.state.vehicleDeductionParam.isLoan === true) {
                request.OccurrenceNo = 1;
                request.RecurringType = 1;
                if (this.state.vehicleDeductionParam.isSplitDeduction === true) {
                    request.IsSplitDeduction = this.state.vehicleDeductionParam.isSplitDeduction;
                    await this.calculateLoanEMI(this.state.vehicleDeductionParam.noOfPayouts, this.state.vehicleDeductionParam.interestPercent, this.state.vehicleDeductionParam.loanAmount, this.state.vehicleDeductionParam.salesTaxAmount)
                }
                request.DayOne = Number(this.state.vehicleDeductionParam.firstDay);
                request.DayTwo = Number(this.state.vehicleDeductionParam.secondDay);
                request.StartingPaidAmount = Number(this.state.vehicleDeductionParam.startingAmount);
                request.DownPaymentAmount = Number(this.state.vehicleDeductionParam.downPayment);
                request.LoanAmount = Number(this.state.vehicleDeductionParam.totalDueAmount);
                request.NoOfPayouts = Number(this.state.vehicleDeductionParam.noOfPayouts);
                request.PerTermPrincipleAmount = Number(this.state.vehicleDeductionParam.perTermPrinciple);
                request.PAInterestPercent = Number(this.state.vehicleDeductionParam.interestPercent);
                request.PerTermInterestAmount = Number(this.state.vehicleDeductionParam.perTermInterest);
                request.SalesTaxPercent = Number(this.state.vehicleDeductionParam.salesTaxPercent);
                request.PerTermSalesTaxAmount = Number(this.state.vehicleDeductionParam.salesTaxAmount);
                request.CurrentDueAmount = Number(this.state.vehicleDeductionParam.loanAmount);
                request.RecurringCharge = Number(this.state.vehicleDeductionParam.recurringCharge);
                request.Note = this.state.vehicleDeductionParam.note.trim();
            }
            let apiUrl = '';
            if (this.state.isAdd === true) {
                request.IsAddToPayroll = this.state.isAddToPayroll;
                if (this.state.isAddToPayroll) {
                    request.PayDate = formatDate(this.state.payRollDate, DateFormat.DefaultDateTimeFormat) + ' 23:59:59';
                }
                request.IsActive = true;
                apiUrl = 'vehicle-deduction-api/0/save-vehicle-deduction';
            }
            else {
                request.IsActive = false;
                apiUrl = 'vehicle-deduction-api/' + Number(this.state.vehicleDeductionId) + '/save-vehicle-deduction';
            }
            const requestParams = getRequestParams('POST', request);
            const response = await fetch(apiUrl, requestParams);
            if (response.ok) {
                const data = await response.json();
                if (data.isSuccess) {
                    this.setState({ pcRecoverVehicleDeductionId: data.retValue });
                    if (this.state.vehicleDeductionParam.isLoan === true) {
                        if (this.state.isEdit === true) {
                            let splittedVehicleHistory = await this.setLoanUpdatedHistory();
                            await this.saveVehicleDeductionLog(this.state.vehicleDeductionId, splittedVehicleHistory);
                            await this.deleteVehiclePaySchedule(this.state.vehicleDeductionId);
                        }
                        if (this.state.vehicleDeductionParam.isSplitDeduction === true) {
                            await this.saveVehiclePaySchedule(data.retValue);
                        }
                    }
                    if (isCreditLoan === true) {
                        await this.saveVehiclePayCreditLoan(data.retValue, isCreditLoan, creditLoanPayId, creditLoanPayDate, previousCreditLoanAmount);
                    }
                }
                await this.defualtLoader();
                let icon = '';
                let title = '';
                if (data.isSuccess === false) {
                    data.resultMessage = (data.resultMessage === null || data.resultMessage === "") ? 'Unable to process the request.' : data.resultMessage;
                }
                else {
                    data.resultMessage = (data.resultMessage === null || data.resultMessage === "") ? 'Vehicle deduction processed successfully.' : data.resultMessage;
                }
                if (data.isSuccess) {
                    icon = 'success';
                    title = 'Success';
                }
                else {
                    if (data.retValue === -1) {
                        icon = 'warning';
                        title = 'Unable to process';
                    }
                    else {
                        icon = 'error';
                        title = 'Oops...';
                    }
                }
                await Swal.fire({
                    allowOutsideClick: false,
                    icon: icon,
                    title: title,
                    text: data.resultMessage,
                }).then(async (result) => {
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            await this.handleResetStateData();
                        }
                    }
                });
            }
            else {
                await this.onfailure('error', null, 'Something went wrong! Unable to process the request.');
            }

        } catch (ex) {
            debugger;
            console.log(ex);
            this.onfailure('error', null, 'Something went wrong! Unable to process the request.');
        }
    }
    isFormValid = async () => {
        let formIsValid = true;
        try {
            let isFocus = false;
            let errors = this.state.errors;
            errors.deduction = '';
            errors.startDate = '';
            errors.endDate = '';
            errors.payingVehicle = '';            
            if (this.chargeTypeSelectValidator.instance.option('selectedItem') === null || Number(this.state.deductionId) === 0) {
                formIsValid = false;
                errors.deduction = 'Please select charge type.';
                if (isFocus === false) { this.chargeTypeSelectValidator.instance.focus(); isFocus = true; }
            }
            if (this.state.vehicleDeductionParam.startDate === null) {
                formIsValid = false;
                errors.startDate = 'Please enter start date.';
                if (isFocus === false) { this.startDateInputValidator.input.focus(); isFocus = true; }
            }
            if (this.state.vehicleDeductionParam.isUntilDate === false) {
                if (this.state.vehicleDeductionParam.endDate === null) {
                    formIsValid = false;
                    errors.endDate = 'Please enter end date.';
                    if (isFocus === false) { this.endDateInputValidator.input.focus(); isFocus = true; }
                }
            }
            if (this.state.vehicleDeductionParam.isLoan === true && this.state.vehicleDeductionParam.isSplitDeduction === true) {
                if (this.state.payingVehicleDropdownData === null || this.state.payingVehicleDropdownData.length === 0 || this.payingVehicleSelectValidator.instance.option('selectedItem') === null) {
                    formIsValid = false;
                    errors.payingVehicle = 'Please select the vehicle to split.';
                    if (isFocus === false) { this.payingVehicleSelectValidator.instance.focus(); isFocus = true; }
                }
            }
            this.setState({ errors: errors });
            if (formIsValid === true) {
                formIsValid = await this.isRecurringValid();
            }

        } catch (ex) {
            debugger;
            formIsValid = false;
            console.log(ex);
        }
        await this.defualtLoader();
        return formIsValid;
    }
    isRecurringValid = async () => {
        let isValid = false;
        let errors = this.state.errors;
        errors.recurringCharge = '';
        errors.startingAmount = '';
        errors.noOfPayouts = '';
        try {
            this.setState({
                loadPanelVisible: true,
                loadPanelMessage: 'Validating recurring charge. Please, wait...',
                loadPanelWidth: '20%'
            });
            await new Promise(resolve => setTimeout(resolve, 500)); // 1/2 sec

            if (this.state.isAdd === true) {

                let chargeDetails = await this.getValidateRecurringChargeDetails(this.state.vehicleDeductionId, this.state.deductionId, this.state.vehicleId, 0, null);
                let vehicleDeductionType = '';
                if (chargeDetails !== null) {
                    vehicleDeductionType = chargeDetails.vehicleDeductionType === null ? '' : chargeDetails.vehicleDeductionType.trim().toLowerCase();
                }
                if ((this.state.vehicleDeductionParam.isOneTime === true && vehicleDeductionType !== 'regular')
                    || (this.state.vehicleDeductionParam.isRegular === true && vehicleDeductionType !== 'recurring')) {
                    await this.defualtLoader();
                    let msg = "<span style='font-size: 20px;'>Invalid deduction type. You have selected <b>" + vehicleDeductionType + "</b> deduction."
                        + ".</span><br>Are you sure want to continue?";
                    let isConfirmed = await this.isConfirm(msg, 'Continue', 'No');
                    if (isConfirmed === false) {
                        this.chargeTypeSelectValidator._instance.focus();
                        return isValid;
                    }
                    this.setState({
                        loadPanelVisible: true,
                        loadPanelMessage: 'Validating recurring charge. Please, wait...',
                        loadPanelWidth: '20%'
                    });
                    await new Promise(resolve => setTimeout(resolve, 500)); // 1/2 sec
                }

                if (this.state.vehicleDeductionParam.isLoan === true && vehicleDeductionType !== 'loan') {
                    await this.onfailure('info', 'Invalid deduction type', 'Please select loan deduction type to add new loan');
                    this.chargeTypeSelectValidator._instance.focus();
                    return isValid;
                }
            }

            if (this.state.vehicleDeductionParam.isOneTime === false) {

                if (this.state.vehicleDeductionParam.isRegular === true) {
                    if (Number(this.state.vehicleDeductionParam.recurringCharge) <= 0) {
                        await this.onfailure('info', 'Invalid deduction amount', 'Please enter recurring amount.');
                        errors.recurringCharge = 'Please enter recurring charge.';
                        this.recurringChargeInputValidator.focus();
                        return isValid;
                    }
                    isValid = true;
                    return isValid;
                }
                if (this.state.isCredit === true && this.state.vehicleDeductionParam.isLoan === true
                    && Number(this.state.vehicleDeductionParam.loanAmount) !== Number(this.state.creditLoanDeductionAmount)) {
                    let msg = 'Loan amount [ <b>' + Number(this.state.vehicleDeductionParam.loanAmount) + '</b> ] not matched to credit amount '
                        + '[<b> ' + Number(this.state.creditLoanDeductionAmount) + ' </b>].';
                    await this.onfailure('info', 'Invalid loan amount', msg);
                    this.startingAmountInputValidator.focus();
                    return isValid;
                }
                if (Number(this.state.vehicleDeductionParam.firstDay) === 0) {
                    await this.onfailure('info', 'Invalid deduction day', 'Missing 1st deduction day of a month.<br>Please try again.');
                    return isValid;
                }
                if (Number(this.state.vehicleDeductionParam.secondDay) === 0) {
                    await this.onfailure('info', 'Invalid deduction day', 'Missing 2nd deduction day of a month.<br>Please try again.');
                    return isValid;
                }
                if (Number(this.state.vehicleDeductionParam.startingAmount) <= 0) {
                    await this.onfailure('info', 'Invalid starting amount', 'Please enter starting amount.');
                    errors.startingAmount = 'Please enter starting amount.';
                    this.startingAmountInputValidator.focus();
                    return isValid;
                }
                if (Number(this.state.vehicleDeductionParam.totalDueAmount) <= 0) {
                    await this.onfailure('info', 'Invalid loan amount', 'Missing Loan amount.<br>Please try again.');
                    return isValid;
                }
                if (Number(this.state.vehicleDeductionParam.noOfPayouts) <= 0) {
                    await this.onfailure('info', 'Invalid payouts', 'Please enter payouts.');
                    errors.noOfPayouts = 'Please enter payouts.';
                    this.noOfPayoutsInputValidator.focus();
                    return isValid;
                }
                if (Number(this.state.vehicleDeductionParam.recurringCharge) <= 0) {
                    await this.onfailure('info', 'Invalid recurring charge', 'Please enter recurring amount to be deducted from every payment');
                    errors.recurringCharge = 'Please enter recurring charge.';
                    this.recurringChargeInputValidator.focus();
                    return isValid;
                }
                if (this.state.vehicleDeductionParam.isLoan === true && this.state.vehicleDeductionParam.isSplitDeduction === true) {
                    if (this.state.payingVehicleDropdownData !== null) {
                        if (Object.keys(this.state.payingVehicleDropdownData).length > 0) {
                            for (var i = 0; i < this.state.payingVehicleDropdownData.length; i++) {
                                let vehicleId = this.state.payingVehicleDropdownData[i].vehicleId;
                                let vehicleNumber = this.state.payingVehicleDropdownData[i].vehicleNumber;

                                if (Number(vehicleId) !== Number(this.state.vehicleId)) {
                                    let chargeDetails = await this.getValidateRecurringChargeDetails(0, this.state.deductionId, Number(vehicleId), this.state.vehicleOwnerMapId, null);
                                    if (chargeDetails !== null) {
                                        if (chargeDetails.isVehicleDeductionExist) {
                                            let deducionName = ''
                                            try {
                                                deducionName = this.state.deductionDropdownData.name;
                                            } catch (ex) {
                                                console.log(ex);
                                            }
                                            let msg = 'Deduction <b>' + (deducionName === '' ? '' : deducionName) + '</b> is already exists for vehicle <b>' + vehicleNumber + '</b>.';
                                            await this.onfailure('info', 'Duplicate deduction', msg);
                                            this.chargeTypeSelectValidator._instance.focus();
                                            return isValid;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            isValid = true;
        } catch (ex) {
            debugger;
            isValid = false;
            console.log(ex);
        }
        this.setState({ errors: errors });
        await this.defualtLoader();
        return isValid;
    }
    defualtLoader = async () => {
        try {
            this.setState({
                loadPanelVisible: false,
                loadPanelMessage: 'Loading...',
                loadPanelWidth: '15%'
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    onfailure = async (icon, title, resultMessage) => {
        try {
            await this.defualtLoader();
            await Swal.fire({
                allowOutsideClick: false,
                showCloseButton: false,
                icon: (icon === null || icon.trim() === '') ? 'error' : icon,
                title: (title === null || title.trim() === '') ? 'Oops...' : title,
                html: (resultMessage === null || resultMessage.trim() === '') ? 'Something went wrong!' : resultMessage.trim(),
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    isConfirm = async (message, confirmButtonText, cancelButtonText) => {
        let isConfirm = false;
        try {
            await Swal.fire({
                icon: 'question',
                title: '<small><b>Confirmation</b></small>',
                html: message,
                //showCloseButton: true,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: "<span style='font-size:14px;'>" + ((confirmButtonText === '' || confirmButtonText === null) ? '' : confirmButtonText) + " </span>",
                cancelButtonText: ((cancelButtonText === '' || cancelButtonText === null) ? 'No' : cancelButtonText),
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                dangerMode: false,
                allowOutsideClick: false
            }).then(async (result) => {
                if (result.isConfirmed) {
                    isConfirm = true;
                }
            });
        } catch (ex) {
            console.log(ex);
        }
        return isConfirm
    }
    saveVehicleDeductionLog = async (vehicleDeductionId, logDescription) => {
        let returnValue = false;
        try {
            if (logDescription !== null && logDescription !== '') {
                var request = new Object();
                request.VehicleId = Number(this.state.vehicleId);
                request.VehicleOwnerId = Number(this.state.vehicleOwnerId);
                request.Description = logDescription.trim();
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('vehicle-deduction-api/' + Number(vehicleDeductionId) + '/save-vehicle-deduction-log', requestParams);
                if (response.ok) {
                    const data = await response.json();
                    returnValue = data.isSuccess;
                }
            }
        } catch (ex) {
            debugger;
            console.log(ex);
        }
        return returnValue;
    }
    setLoanUpdatedHistory = async () => {
        let history = '';
        try {
            let prevSplittedVehicles = [];
            let removedSplittedVehicles = '';
            const requestParams = getRequestParams('GET');
            const response = await fetch('vehicle-deduction-api/' + Number(this.state.vehicleDeductionId) + '/splitted-vehicles', requestParams);
            if (response.ok) {
                const data = await response.json();
                if (data.vehicleDeductionSplittedVehicles !== null) {
                    if (data.vehicleDeductionSplittedVehicles.length > 0) {
                        data.vehicleDeductionSplittedVehicles.forEach((item) => {
                            let splittedVehicle = new Object();
                            splittedVehicle.vehicleId = Number(item.vehicleId);
                            splittedVehicle.vehicleNumber = item.vehicleNumber;
                            var existVehicle = prevSplittedVehicles.filter(v => Number(v.vehicleId) === Number(item.vehicleId))
                            if (existVehicle.length === 0) {
                                prevSplittedVehicles.push(splittedVehicle);
                            }
                        });
                    }
                }
            }

            prevSplittedVehicles.forEach((item) => {
                if (removedSplittedVehicles.length === 0) {
                    removedSplittedVehicles = item.vehicleNumber;
                }
                else {
                    removedSplittedVehicles += ', ' + item.vehicleNumber;
                }
            });

            if (this.state.vehicleDeductionParam.prevIsSplitDeduction !== this.state.vehicleDeductionParam.isSplitDeduction) {
                if (this.state.vehicleDeductionParam.isSplitDeduction === true) {
                    history = 'Deduction splitted.';
                }
                if (removedSplittedVehicles.length > 0) {
                    history = 'Deduction splitted [ ' + removedSplittedVehicles + ' ] removed.';
                }
            }
            if (this.state.vehicleDeductionParam.loanPayScheduleList !== null) {
                let currentSplittedVehicles = [];
                this.state.vehicleDeductionParam.loanPayScheduleList.forEach((item) => {
                    let splittedVehicle = new Object();
                    splittedVehicle.vehicleId = Number(item.vehicleId);
                    splittedVehicle.vehicleNumber = item.vehicleNumber;
                    var existVehicle = currentSplittedVehicles.filter(v => Number(v.vehicleId) === Number(item.vehicleId))
                    if (existVehicle.length === 0) {
                        currentSplittedVehicles.push(splittedVehicle);
                    }
                });
                if (prevSplittedVehicles.length !== currentSplittedVehicles.length) {
                    history += 'Split vehicles changed from ' + prevSplittedVehicles.length + ' to ' + currentSplittedVehicles.length;
                }
            }

        } catch (ex) {
            debugger;
            console.log(ex);
        }
        return history;
    }
    deleteVehiclePaySchedule = async (vehicleDeductionId) => {
        let returnValue = false;
        try {
            var request = new Object();
            request.VehicleOwnerMapId = Number(this.state.vehicleOwnerMapId);
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('vehicle-deduction-api/' + Number(vehicleDeductionId) + '/delete-vehicle-pay-schedule', requestParams);
            if (response.ok) {
                const data = await response.json();
                returnValue = data.isSuccess;
            }
        } catch (ex) {
            debugger;
            console.log(ex);
        }
        return returnValue;
    }
    saveVehiclePaySchedule = async (vehicleDeductionId) => {
        let returnValue = false;
        try {
            let splittedVehicles = [];
            if (this.state.vehicleDeductionParam.loanPayScheduleList !== null) {
                if (this.state.vehicleDeductionParam.loanPayScheduleList.length > 0) {
                    this.state.vehicleDeductionParam.loanPayScheduleList.forEach((item) => {
                        let sv = new Object();
                        sv.VehicleId = Number(item.vehicleId);
                        sv.ParentVehicleDeductionId = Number(vehicleDeductionId);
                        sv.SrNo = Number(item.id);
                        sv.DueDate = moment(item.dueDate, 'MM/DD/YYYY').format("MM/DD/YYYY") + ' 23:59:59';
                        sv.PrincipleAmount = Number(item.principleAmount);
                        sv.InterestAmount = Number(item.interestAmount);
                        sv.SalesTaxAmount = Number(item.salesTaxAmount);
                        sv.TotalDueAmount = Number(item.totalDueAmount);
                        sv.Description = item.description;
                        splittedVehicles.push(sv);
                    });
                }
            }
            if (splittedVehicles.length > 0) {
                var request = new Object();
                request.VehicleDeductionPaySchedules = splittedVehicles;
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('vehicle-deduction-api/' + Number(vehicleDeductionId) + '/save-vehicle-pay-schedule', requestParams);
                if (response.ok) {
                    const data = await response.json();
                    returnValue = data.isSuccess;
                }
            }
        } catch (ex) {
            debugger;
            console.log(ex);
        }
        return returnValue;
    }
    saveVehiclePayCreditLoan = async (vehicleDeductionId, isCreditLoan, creditLoanPayId, creditLoanPayDate, previousCreditLoanAmount) => {
        let returnValue = false;
        try {
            if (isCreditLoan === true) {
                var request = new Object();
                request.PayId = Number(creditLoanPayId);
                request.CRPayId = 0;
                request.CrPayDate = creditLoanPayDate;
                request.VehicleDeductionId = Number(vehicleDeductionId);
                request.DeductionId = Number(this.state.deductionId);
                request.Amount = Number(this.state.vehicleDeductionParam.loanAmount);
                request.CreditNote = 'Loan credited.';
                request.IsRecoverable = true;
                request.RecoverVehicleDeductionId = Number(vehicleDeductionId);
                request.IsLoanCredit = true;
                request.LoanId = Number(vehicleDeductionId);
                let apiUrl = '';
                if (this.state.isAdd === true) {
                    apiUrl = 'vehicle-deduction-api/insert-pay-credit-loan';
                    const requestParams = getRequestParams('POST', request);
                    const response = await fetch(apiUrl, requestParams);
                    if (response.ok) {
                        const data = await response.json();
                        returnValue = data.isSuccess;
                    }
                }
                else {
                    if (Number(previousCreditLoanAmount) !== Number(this.state.vehicleDeductionParam.loanAmount)) {
                        apiUrl = 'vehicle-deduction-api/' + Number(this.state.vehicleDeductionParam.vehiclePayCreditId) + '/update-pay-credit-loan';
                        const requestParams = getRequestParams('POST', request);
                        const response = await fetch(apiUrl, requestParams);
                        if (response.ok) {
                            const data = await response.json();
                            returnValue = data.isSuccess;
                        }
                    }
                }
            }
        } catch (ex) {
            debugger;
            console.log(ex);
        }
        return returnValue;
    }
    render() {
        const validStartDateTime = time => {
            const currentDate = new Date();
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }
        const validEndDateTime = time => {
            const currentDate = new Date();
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }
        const renderLoanPayScheduleGridCell = (data, filedName) => {
            let amount = 0;
            switch (data.column.dataField) {
                case 'principleAmount':
                    amount = data.data.principleAmount;
                    break;
                case 'interestAmount':
                    amount = data.data.interestAmount;
                    break;
                case 'salesTaxAmount':
                    amount = data.data.salesTaxAmount;
                    break;
                case 'totalDueAmount':
                    amount = data.data.totalDueAmount;
                    break;
                default:
                    break;
            }
            let cellColor = (Number(amount) > 0) ? '#008000' : '#999900';
            return <div style={{ color: cellColor }}>${amount}</div>;
        }
        const renderLoanPaySchDescription = (data) => {
            return <span title={data.data.description}>
                <a title={data.data.description} onClick={() => {
                    Swal.fire(data.data.description)
                }} ><span>{data.data.description}</span></a>
            </span>;
        }
        return (
            <div className="content-wrapper">
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '55%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleAssignDataToState}
                    onRequestClose={this.handleResetStateData}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">
                                {
                                    (
                                        (this.state.isAdd && !this.state.isViewOnly) ? 'Add Vehicle Deduction' :
                                            (this.state.isEdit && !this.state.isViewOnly) ? 'Modify Vehicle Deduction' :
                                                'View Vehicle Deduction'
                                    )
                                }
                            </h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleResetStateData}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label>Vehicle - <strong className="text-danger">{this.state.vehicleNumber}</strong></label>
                                    </div>
                                </div>
                               
                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 text-right">
                                    <div className="form-group">
                                        <label>Owner - <strong className="text-danger">{this.state.vehicleOwnerName}</strong></label>
                                    </div>
                                </div>
                                
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <hr style={{ marginTop: '0.3rem', marginBottom: '0.3rem', width: '100%' }} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <label>Charge Type <span className="text-red">*</span></label>
                                        <SelectBox
                                            ref={select => this.chargeTypeSelectValidator = select}
                                            dataSource={this.state.deductionDropdownList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select charge type"
                                            name="dddeduction"
                                            defaultValue={0}
                                            value={this.state.deductionDropdownData !== null ? this.state.deductionDropdownData.id : 0}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnDeductionDropdownChange}
                                            showClearButton={true}
                                            disabled={(this.state.isViewOnly || this.state.isEdit)}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["deduction"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["deduction"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <label>Reference </label>
                                        <input type="text" className="form-control form-textbox form-control-sm" name="reference"
                                            title="Reference" placeholder="Enter reference"
                                            value={this.state.vehicleDeductionParam.reference} onChange={this.handleOnInputChange}
                                            disabled={this.state.isViewOnly} />
                                    </div>
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <label>Opening Balance </label>
                                        <input type="text" className="form-control form-textbox form-control-sm" name="openingBalance"
                                            title="Opening Balance" placeholder="$0.00"
                                            value={
                                                (Number(this.state.vehicleDeductionParam.openingBalance) === 0 ? ''
                                                    : this.state.vehicleDeductionParam.openingBalance)
                                            }
                                            onChange={this.handleOnInputChange}
                                            onBlur={this.handleOnInputLeave}
                                            disabled={this.state.isViewOnly} />
                                    </div>
                                </div>
                                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                    <div className="form-group">
                                        <label>State Date <span className="text-danger">*</span></label>
                                        <DatePicker
                                            className="form-control form-control-sm "
                                            placeholderText="Click to select date"
                                            name="startDate"
                                            selected={this.state.vehicleDeductionParam.startDate}
                                            dateFormat={DateFormat.DefaultDateTimeFormat}
                                            onChange={this.handleOnStartDateChange}
                                            filterTime={validStartDateTime}
                                            disabled={(this.state.isViewOnly || this.state.isEdit)}
                                            showTimeInput={false}
                                            ref={(datepicker) => { this.startDateInputValidator = datepicker }}
                                        />
                                        {this.state.errors["startDate"].length > 0 && <span className="error invalid-feedback">
                                            {this.state.errors["startDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                    <div className="form-group">
                                        <div className="form-check" style={{ top: '28px', position: 'absolute' }}>
                                            <input className="form-check-input" type="checkbox" id="isUntilDate" name="isUntilDate"
                                                checked={this.state.vehicleDeductionParam.isUntilDate} onChange={this.handleOnInputChange}
                                                disabled={this.state.isViewOnly} />
                                            <label className="form-check-label"
                                                htmlFor="isUntilDate">Until Date</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                    <div className="form-group">
                                        <label>End Date <span style={{ display: (!this.state.vehicleDeductionParam.isUntilDate) || 'none' }} className="text-danger">*</span></label>
                                        <DatePicker
                                            className="form-control form-control-sm "
                                            placeholderText=""
                                            name="endDate"
                                            selected={this.state.vehicleDeductionParam.endDate}
                                            dateFormat={DateFormat.DefaultDateTimeFormat}
                                            onChange={this.handleOnEndDateChange}
                                            filterTime={validEndDateTime}
                                            startDate={this.state.vehicleDeductionParam.startDate}
                                            minDate={this.state.vehicleDeductionParam.startDate}
                                            disabled={(this.state.isViewOnly || (this.state.vehicleDeductionParam.isUntilDate === true))}
                                            showTimeInput={false}
                                            title={'Click to select date'}
                                            ref={(datepicker) => { this.endDateInputValidator = datepicker }}
                                        />
                                        {this.state.errors["endDate"].length > 0 && <span className="error invalid-feedback">
                                            {this.state.errors["endDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                                    style={{ display: (!this.state.isAdd) || 'none' }}>
                                    <div className="form-group">
                                        <div className="form-check" style={{ position: 'absolute', top: '28px' }}>
                                            <input className="form-check-input" type="checkbox"
                                                id="isActive" name="isActive" disabled={this.state.isViewOnly}
                                                checked={this.state.vehicleDeductionParam.isActive} onChange={(e) => {
                                                    let vehicleDeductionParam = this.state.vehicleDeductionParam;
                                                    vehicleDeductionParam.isActive = e.target.checked;
                                                    this.setState({ vehicleDeductionParam: vehicleDeductionParam })
                                                }} />
                                            <label className="form-check-label" htmlFor="isActive"> Is Active</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <hr style={{ marginTop: '0.3rem', marginBottom: '0.3rem', width: '100%' }} />
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                   <div className="form-group">
                                            <div className="form-check">
                                                <label><strong>{'Occurrence'}</strong></label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" id="isOneTime" name="isOneTime"
                                                    disabled={this.state.controllDisability.isOneTimeDisabled}
                                                    checked={this.state.vehicleDeductionParam.isOneTime} onChange={(e) => { this.handleOnInputChange(e) }} />
                                                <label className="form-check-label" htmlFor="isOneTime"> Once</label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" id="isRegular" name="isRegular"
                                                    disabled={this.state.controllDisability.isRegularDisabled}
                                                    checked={this.state.vehicleDeductionParam.isRegular} onChange={(e) => { this.handleOnInputChange(e) }} />
                                                <label className="form-check-label" htmlFor="isRegular"> Regular</label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" id="isLoan" name="isLoan"
                                                    disabled={this.state.controllDisability.isLoanDisabled}
                                                    checked={this.state.vehicleDeductionParam.isLoan} onChange={(e) => { this.handleOnInputChange(e) }} />
                                                <label className="form-check-label" htmlFor="isLoan"> Loan</label>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className="row" style={{ height: '200px', display: (this.state.vehicleDeductionParam.isOneTime === false) || 'none' }}>
                                
                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <label>1st Day </label>
                                            <select name="firstDay" className="form-control form-control-sm"
                                                disabled={
                                                    (this.state.isViewOnly || (!this.state.vehicleDeductionParam.isLoan))
                                                }
                                                value={this.state.vehicleDeductionParam.firstDay} onChange={this.handleOnInputChange}>
                                                <option value="1">1</option>
                                                <option value="15">15</option>
                                                <option value="31">31</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <label>2nd Day</label>
                                            <select name="secondDay" className="form-control form-control-sm"
                                                disabled={
                                                    (this.state.isViewOnly || (!this.state.vehicleDeductionParam.isLoan))
                                                }
                                                value={this.state.vehicleDeductionParam.secondDay}
                                                onChange={this.handleOnInputChange}>
                                                <option value="1">1</option>
                                                <option value="15">15</option>
                                                <option value="31">31</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <label>Starting Amt. Owed</label>
                                            <input type="text"
                                                className="form-control form-textbox form-control-sm" name="startingAmount"
                                                title="Starting Amount" placeholder="$0.00"
                                                ref={input => this.startingAmountInputValidator = input}
                                                value={
                                                    (Number(this.state.vehicleDeductionParam.startingAmount) === 0 ? ''
                                                        : this.state.vehicleDeductionParam.startingAmount)
                                                }
                                                onChange={this.handleOnInputChange}
                                                onBlur={this.handleOnInputLeave}
                                                disabled={
                                                    (
                                                        this.state.isViewOnly
                                                        || this.state.vehicleDeductionParam.isUsedVehicleDeduction
                                                        || (!this.state.vehicleDeductionParam.isLoan)
                                                    )
                                                }
                                            />
                                            {this.state.errors["startingAmount"].length > 0 && <span className="error invalid-feedback">
                                                {this.state.errors["startingAmount"]}</span>}
                                        </div>
                                    </div>
                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <label>Down Payment</label>
                                            <input type="text"
                                                className="form-control form-textbox form-control-sm" name="downPayment"
                                                title="Down Payment" placeholder="$0.00"
                                                value={
                                                    (Number(this.state.vehicleDeductionParam.downPayment) === 0 ? ''
                                                        : this.state.vehicleDeductionParam.downPayment)
                                                }
                                                onChange={this.handleOnInputChange}
                                                onBlur={this.handleOnInputLeave}
                                                disabled={
                                                    (
                                                        this.state.isViewOnly
                                                        || this.state.vehicleDeductionParam.isUsedVehicleDeduction
                                                        || (!this.state.vehicleDeductionParam.isLoan)
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <label>Number of Payouts</label>
                                            <input type="text"
                                                className="form-control form-textbox form-control-sm" name="noOfPayouts"
                                                title="NO Of Payouts" placeholder=""
                                                ref={input => this.noOfPayoutsInputValidator = input}
                                                value={
                                                    (Number(this.state.vehicleDeductionParam.noOfPayouts) === 0 ? ''
                                                        : this.state.vehicleDeductionParam.noOfPayouts)
                                                }
                                                onChange={this.handleOnInputChange}
                                                disabled={
                                                    (
                                                        this.state.isViewOnly
                                                        || this.state.vehicleDeductionParam.isUsedVehicleDeduction
                                                        || (!this.state.vehicleDeductionParam.isLoan)
                                                    )
                                                }
                                            />
                                            {this.state.errors["noOfPayouts"].length > 0 && <span className="error invalid-feedback">
                                                {this.state.errors["noOfPayouts"]}</span>}
                                        </div>
                                    </div>
                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <label>Interest %</label>
                                            <input type="text" className="form-control form-textbox form-control-sm"
                                                name="interestPercent"
                                                title="Interest%" placeholder="$0.00"
                                                value={
                                                    (Number(this.state.vehicleDeductionParam.interestPercent) === 0 ? ''
                                                        : this.state.vehicleDeductionParam.interestPercent)
                                                }
                                                onChange={this.handleOnInputChange}
                                                onBlur={this.handleOnInputLeave}
                                                disabled={
                                                    (
                                                        this.state.isViewOnly
                                                        || this.state.vehicleDeductionParam.isUsedVehicleDeduction
                                                        || (!this.state.vehicleDeductionParam.isLoan)
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <label>Sales Tax %</label>
                                            <input type="text" className="form-control form-textbox form-control-sm"
                                                name="salesTaxPercent"
                                                title="Sales Tax%" placeholder="$0.00"
                                                value={
                                                    (Number(this.state.vehicleDeductionParam.salesTaxPercent) === 0 ? ''
                                                        : this.state.vehicleDeductionParam.salesTaxPercent)
                                                }
                                                onChange={this.handleOnInputChange}
                                                onBlur={this.handleOnInputLeave}
                                                disabled={
                                                    (
                                                        this.state.isViewOnly
                                                        || this.state.vehicleDeductionParam.isUsedVehicleDeduction
                                                        || (!this.state.vehicleDeductionParam.isLoan)
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <label>Per Term Sales Tax</label>
                                            <input type="text"
                                                className="form-control form-textbox form-control-sm" name="salesTaxAmount"
                                                title="Per Term Sales Tax" placeholder="$0.00"
                                                value={
                                                    (Number(this.state.vehicleDeductionParam.salesTaxAmount) === 0 ? ''
                                                        : this.state.vehicleDeductionParam.salesTaxAmount)
                                                }
                                                disabled={this.state.isViewOnly} readOnly={true} />
                                        </div>
                                    </div>
                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <label>Loan Amount</label>
                                            <input type="text"
                                                className="form-control form-textbox form-control-sm"
                                                name="loanAmount"
                                                title="Loan Amount" placeholder="$0.00"
                                                value={
                                                    (Number(this.state.vehicleDeductionParam.loanAmount) === 0 ? ''
                                                        : this.state.vehicleDeductionParam.loanAmount)
                                                }
                                                disabled={this.state.isViewOnly} readOnly={true} />
                                        </div>
                                    </div>
                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <label>Per Term Principle</label>
                                            <input type="text"
                                                className="form-control form-textbox form-control-sm"
                                                name="perTermPrinciple"
                                                title="Per Term Principle" placeholder="$0.00"
                                                value={
                                                    (Number(this.state.vehicleDeductionParam.perTermPrinciple) === 0 ? ''
                                                        : this.state.vehicleDeductionParam.perTermPrinciple)
                                                }
                                                disabled={this.state.isViewOnly} readOnly={true} />
                                        </div>
                                    </div>
                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <label>Per Term Interest</label>
                                            <input type="text"
                                                className="form-control form-textbox form-control-sm"
                                                name="perTermInterest"
                                                title="Per Term Interest" placeholder="$0.00"
                                                value={
                                                    (Number(this.state.vehicleDeductionParam.perTermInterest) === 0 ? ''
                                                        : this.state.vehicleDeductionParam.perTermInterest)
                                                }
                                                disabled={this.state.isViewOnly} readOnly={true} />
                                        </div>
                                    </div>
                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <label>Total Due Amount</label>
                                            <input type="text"
                                                className="form-control form-textbox form-control-sm"
                                                name="totalDueAmount"
                                                title="Total Due Amount" placeholder="$0.00"
                                                value={
                                                    (Number(this.state.vehicleDeductionParam.totalDueAmount) === 0 ? ''
                                                        : this.state.vehicleDeductionParam.totalDueAmount)
                                                }
                                                disabled={this.state.isViewOnly} readOnly={true} />
                                        </div>
                                    </div>
                                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <div className="form-check">
                                                <input className="form-check-input"
                                                    type="checkbox" id="isSplitDeduction" name="isSplitDeduction"
                                                    disabled={
                                                        (
                                                            this.state.isViewOnly
                                                            || this.state.vehicleDeductionParam.isUsedVehicleDeduction
                                                            || (!this.state.vehicleDeductionParam.isLoan)
                                                        )
                                                    }
                                                    checked={this.state.vehicleDeductionParam.isSplitDeduction}
                                                    onChange={this.handleOnInputChange} />
                                                <label className="form-check-label"
                                                    htmlFor="isSplitDeduction">Split Deduction</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                                        <div className="form-group">
                                        <SelectBox
                                            ref={select => this.payingVehicleSelectValidator = select}
                                            dataSource={this.state.payingVehicleDropdownList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select "
                                            name="ddvehicle"
                                            defaultValue={0}
                                            value={this.state.payingVehicleDropdownData !== null ? this.state.payingVehicleDropdownData.vehicleId : 0}
                                            displayExpr='vehicleNumber'
                                            valueExpr='vehicleId'
                                            onSelectionChanged={this.handleOnPayingVehicleDropdownChange}
                                            showClearButton={true}
                                            disabled={(
                                                this.state.isViewOnly
                                                || (this.state.vehicleDeductionParam.isSplitDeduction === false)
                                                || this.state.vehicleDeductionParam.isUsedVehicleDeduction
                                                || (this.state.vehicleDeductionParam.isLoan === false)
                                            )}
                                            searchEnabled={true}
                                        />
                                            {this.state.errors["payingVehicle"].length > 0 && <span className='error invalid-feedback'>
                                                {this.state.errors["payingVehicle"]}</span>}
                                        </div>
                                    </div>
                                
                            </div>
                            <div className="row" style={{ display: (this.state.vehicleDeductionParam.isOneTime === false) || 'none' }}>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <hr style={{ marginTop: '0.3rem', marginBottom: '0.3rem', width: '100%' }} />
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <label>Recurring Charge</label>
                                        <input type="text" className="form-control form-textbox form-control-sm" name="recurringCharge"
                                            title="Recurring Charge" placeholder="$0.00"
                                            ref={input => this.recurringChargeInputValidator = input}
                                            value={
                                                (Number(this.state.vehicleDeductionParam.recurringCharge) === 0 ? ''
                                                    : this.state.vehicleDeductionParam.recurringCharge)
                                            }
                                            onChange={this.handleOnInputChange}
                                            onBlur={this.handleOnInputLeave}
                                            disabled={(this.state.isViewOnly)}
                                            readOnly={this.state.vehicleDeductionParam.isLoan} />
                                        {this.state.errors["recurringCharge"].length > 0 && <span className="error invalid-feedback">
                                            {this.state.errors["recurringCharge"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                                    <div className="form-group">
                                        <label>Note </label>
                                        <textarea className="form-control form-control-sm" rows="1" name="note" value={this.state.vehicleDeductionParam.note}
                                            onChange={this.handleOnInputChange} disabled={this.state.isViewOnly}>
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary btn-sm"
                                style={{ display: this.state.vehicleDeductionParam.isLoan || 'none' }}
                                onClick={this.handleOnLoanPayScheduleSubmit}>
                                {this.state.isLoanPayScheduleSubmit && <span title="In-pocess"><i className="fas fa-spinner fa-spin"></i> Loading...</span>}
                                {!this.state.isLoanPayScheduleSubmit && <span title="Loan Pay Schedule">
                                    <i className="fas fa-grip-horizontal"></i> Loan Pay Schedule</span>}
                            </button>
                            <button type="button" className="btn btn-success btn-sm" disabled={this.state.isViewOnly} ref={button => this.submitVehicleDeductionButtonValidator = button} onClick={this.handleOnVehicleDeductionSubmit}>
                                {this.state.isSubmited && <span title="In-pocess"><i className="fas fa-spinner fa-spin"></i> In Pocess</span>}
                                {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close" onClick={this.handleResetStateData}>
                                <i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenLoanPayScheduleModal}
                    style={{ content: { width: '65%' } }}
                    className={"react-modal"}
                    onRequestClose={() => { this.setState({ isOpenLoanPayScheduleModal: false }) }}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form>
                        <div className="modal-header">
                            <h6 className="modal-title">Vehicle Loan Pay Schedule</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { this.setState({ isOpenLoanPayScheduleModal: false }) }}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="widget-container">
                                        <DataGrid
                                            dataSource={this.state.vehicleDeductionParam.loanPayScheduleList.sort((a, b) => b.isParentVehicle - a.isParentVehicle)}
                                            //keyExpr="id"
                                            selection={{ mode: 'single' }}
                                            columnsAutoWidth={true}
                                            showColumnLines={true}
                                            showRowLines={true}
                                            showBorders={true}
                                            rowAlternationEnabled={true}
                                            allowColumnResizing={true}
                                            columnResizingMode='widget'
                                        >
                                            <Column dataField="vehicleNumber" caption="Vehicle" />
                                            <Column dataField="parentVehicleNumber" caption="P.Vehicle" />
                                            <Column dataField="id" caption="Sr.No." alignment={'center'} />
                                            <Column dataField="dueDate" caption="Due Date" />
                                            <Column dataField="principleAmount" caption="Principle" alignment={'center'} cellRender={renderLoanPayScheduleGridCell} />
                                            <Column dataField="interestAmount" caption="Interest" alignment={'center'} cellRender={renderLoanPayScheduleGridCell} />
                                            <Column dataField="salesTaxAmount" caption="Sales Tax" alignment={'center'} cellRender={renderLoanPayScheduleGridCell} />
                                            <Column dataField="totalDueAmount" caption="Total Due" alignment={'center'} cellRender={renderLoanPayScheduleGridCell} />
                                            <Column dataField="description" caption="Discription" cellRender={renderLoanPaySchDescription} />
                                            <SortByGroupSummaryInfo summaryItem="count" />
                                            <GroupPanel visible={false} />
                                            <HeaderFilter visible={true} />
                                            <SearchPanel visible={false} highlightCaseSensitive={false} width={600} placeholder="Search..." />
                                            <Grouping autoExpandAll={false} />
                                            <Paging defaultPageSize={10} />
                                            <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                            <Export enabled={false} allowExportSelectedData={false} />
                                            <ColumnChooser enabled={false} />
                                            <ColumnFixing enabled={true} />
                                        </DataGrid>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-custom-gray btn-sm"
                                onClick={() => { this.setState({ isOpenLoanPayScheduleModal: false }) }} title="Close">
                                <i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenCreditReimbursmentModal}
                    style={{ content: { width: '35%' } }}
                    className={"react-modal"}
                    onRequestClose={() => { this.setState({ isOpenCreditReimbursmentModal: false }) }}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form>
                        <div className="modal-header">
                            <h6 className="modal-title">Vehicle Deduction Credit Reimbursment</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={() => { this.setState({ isOpenCreditReimbursmentModal: false }) }}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="widget-container">
                                <div className="row">
                                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <label>Reimbursment Date : <span style={{ display: (!this.state.creditReimbursment.isCurrentPayroll) || 'none' }} className="text-red">*</span></label>
                                        </div>
                                    </div>
                                    <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8" style={{ display: (!this.state.creditReimbursment.isCurrentPayroll) || 'none' }} >
                                        <div className="form-group">
                                            <SelectBox
                                                ref={select => this.reimbursmentDateSelectValidator = select}
                                                dataSource={this.state.creditReimbursment.vehiclePayPeriodDropdownList}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select "
                                                name="ddreimbursmentDate"
                                                defaultValue={0}
                                                value={this.state.creditReimbursment.reimbursmentDate !== null ? this.state.creditReimbursment.reimbursmentDate.payEndDate : ''}
                                                displayExpr='viewDate'
                                                valueExpr='payEndDate'
                                                onSelectionChanged={this.handleOnCreditReimbDateChange}
                                                showClearButton={true}
                                                disabled={false}
                                                searchEnabled={true}
                                            />
                                            {this.state.errors["reimbursmentDate"].length > 0 && <span className='error invalid-feedback'>
                                                {this.state.errors["reimbursmentDate"]}</span>}
                                        </div>
                                    </div>
                                    <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8" style={{ display: (this.state.creditReimbursment.isCurrentPayroll) || 'none' }} >
                                        <div className="form-group">
                                            <input type="text" className="form-control form-textbox form-control-sm" name="reimbursmentDate"
                                                value={
                                                    (this.state.creditReimbursment.reimbursmentDate === null || Object.keys(this.state.creditReimbursment.reimbursmentDate).length === 0) ? ''
                                                        : this.state.creditReimbursment.reimbursmentDate.viewDate
                                                }
                                                readOnly={true} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <label>Deduction Name : </label>
                                        </div>
                                    </div>
                                    <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                                        <div className="form-group">
                                            <input type="text" className="form-control form-textbox form-control-sm" name="creditLoanDeductionName"
                                                value={this.state.creditReimbursment.deductionName}
                                                readOnly={true} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <label>Credit Amount : </label>
                                        </div>
                                    </div>
                                    <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                                        <div className="form-group">
                                            <input type="text" className="form-control form-textbox form-control-sm" name="creditLoanDeductionAmount"
                                                value={this.state.vehicleDeductionParam.loanAmount}
                                                readOnly={true} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" title="OK"
                                onClick={this.handleOnCreditReimbursmentSubmit}>
                                {<span title="Submit"><i className="fas fa-check"></i> OK</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm"
                                onClick={() => { this.setState({ isOpenCreditReimbursmentModal: false }) }} title="Cancel">
                                <i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    message={this.state.loadPanelMessage}
                    closeOnOutsideClick={false}
                    width={this.state.loadPanelWidth}
                />
            </div>
        )
    }
}

