import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ReactTooltip from "react-tooltip"; //npm install react - tooltip
import { ChangePasswordComponent } from '../Shared/ChangePasswordComponent';
import { SetUserLocationComponent } from '../Shared/SetUserLocationComponent';
import { MasterHistoryComponent } from '../Shared/MasterHistoryComponent';
import NumberFormat from 'react-number-format';
import { SelectBox } from 'devextreme-react/select-box';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { NavItem, NavLink } from 'reactstrap';
import { LoadPanel } from 'devextreme-react/load-panel';
import { Link } from 'react-router-dom';
import List from 'devextreme-react/list';
import { ApplicationType, CustomerType } from '../../helpers/fixcodes.js';

const MySwal = withReactContent(Swal)

const customStyles = {
    content: {
        width: '60%',
    }
};

const airlineCustomStyles = {
    content: {
        width: '40%',
    }
};
const position = { of: '#historydiv' };

const mappedUnmappedCustomer = [{ id: 5, text: 'New Brochures' },
{ id: 6, text: '2019 Brochure Designs' },
{ id: 7, text: 'Brochure Design Review' },
{ id: 8, text: 'Website Re-Design Plan' },
{ id: 9, text: 'Rollout of New Website and Marketing Brochures' },
{ id: 10, text: 'Create 2018 Sales Report' },
{ id: 11, text: 'Direct vs Online Sales Comparison Report' },
{ id: 12, text: 'Review 2018 Sales Report and Approve 2019 Plans' },
{ id: 13, text: 'Submit Signed NDA' },
{ id: 14, text: 'Update Revenue Projections' },
{ id: 15, text: 'Review Revenue Projections' },
{ id: 16, text: 'Comment on Revenue Projections' },
{ id: 17, text: 'Scan Health Insurance Forms' },
{ id: 18, text: 'Sign Health Insurance Forms' },
{ id: 19, text: 'Follow up with West Coast Stores' },
{ id: 20, text: 'Follow up with East Coast Stores' },
{ id: 21, text: 'Submit Refund Report for 2019 Recall' },
{ id: 22, text: 'Give Final Approval for Refunds' },
{ id: 23, text: 'Prepare Product Recall Report' },
{ id: 24, text: 'Review Product Recall Report by Engineering Team' },
{ id: 25, text: 'Review Training Course for any Ommissions' },
{ id: 26, text: 'Review Overtime Report' },
{ id: 27, text: 'Submit Overtime Request Forms' },
{ id: 28, text: 'Overtime Approval Guidelines' },
{ id: 29, text: 'Create Report on Customer Feedback' },
{ id: 30, text: 'Review Customer Feedback Report' }];

export class CustomerUserListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            datasource: null,
            userModalIsOpen: false,
            singelData: null,
            loadPanelVisible: false,
            dropdownUserType: [],
            dropdownCustomer: [],
            userId: 0,
            customerId: 0,
            userName: '',
            password: '',
            userType: 0,
            firstName: '',
            lastName: '',
            mobileNumber: '',
            countryCode: '',
            email: '',
            address: '',
            isActive: true,
            isLocked: false,
            inactiveReason: '',
            lockedReason: '',
            userAirlineModalIsOpen: false,
            selectedItemKeys: [],
            airlineId: 0,
            appTypeId: parseInt(ApplicationType.UTPro),
            vIsPasswordComplexity: false,
            vMinLength: 0,
            vMinUpperCaseLetter: 0,
            vMinLowerCaseLetter: 0,
            vMinNumber: 0,
            vMinSpecialCharacter: 0,

            errors: {
                userName: '',
                password: '',
                customerId: '',
                userType: '',
                firstName: '',
                lastName: '',
                mobileNumber: '',
                countryCode: '',
                email: ''
            }

        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCustomerChange = this.handleCustomerChange.bind(this);

        this.getUserList = this.getUserList.bind(this);
        this.getCustomer = this.getCustomer.bind(this);
        this.getUserType = this.getUserType.bind(this);

        this.editUserControl = this.editUserControl.bind(this);
        this.deleteCustomerUser = this.deleteCustomerUser.bind(this);
        this.closeUserModal = this.closeUserModal.bind(this);
        this.afterOpenUserModal = this.afterOpenUserModal.bind(this);
        this.handleUserSubmit = this.handleUserSubmit.bind(this);
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.openPasswordModal = this.openPasswordModal.bind(this);
        this.openUserAirlineModal = this.openUserAirlineModal.bind(this);

        this.onSelectedItemKeysChange = this.onSelectedItemKeysChange.bind(this);
        this.closeAirlineModal = this.closeAirlineModal.bind(this);
        this.handleAirlineSubmit = this.handleAirlineSubmit.bind(this);

        this.getValidationRules = this.getValidationRules.bind(this);
    }



    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getUserList.bind(this)
            }
        });
    }


    async componentDidMount() {
        await this.getUserList();
        await this.getValidationRules();
    }

    async getUserList() {
        try {
            this.setState({ loadPanelVisible: true });
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('customer-api/user-list', requestParams);
            const data = await response.json();

            this.setState({
                datasource: data.userDetails
            });
            this.setState({ loadPanelVisible: false });
        } catch (e) {
            console.log(e);
            this.setState({ loadPanelVisible: false });
        }
    }

    async getValidationRules() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('password-policy-api/validation-rule/' + sessionStorage.getItem('session_accountId') + '/' + parseInt(this.state.appTypeId), requestParams);
            const data = await response.json();
            if (data !== null && data.passwordValidationRule != null) {
                this.setState({
                    vIsPasswordComplexity: data.passwordValidationRule.isPasswordComplexity,
                    vMinLength: data.passwordValidationRule.minLength,
                    vMinUpperCaseLetter: data.passwordValidationRule.minUpperCaseLetter,
                    vMinLowerCaseLetter: data.passwordValidationRule.minLowerCaseLetter,
                    vMinNumber: data.passwordValidationRule.minNumber,
                    vMinSpecialCharacter: data.passwordValidationRule.minSpecialCharacter
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    async getCustomer() {

        try {

            const requestParams = getRequestParams('GET', '');
            const response = await fetch('account-api/customer-dropdown/', requestParams);
            const data = await response.json();
            this.setState({
                dropdownCustomer: data.customers,
            });

        } catch (e) {
            console.log(e);
        }
    }

    async getUserType() {
        try {

            const requestParams = getRequestParams('GET', "");
            const response = await fetch('user-api/usertype/' + 0, requestParams);
            const data = await response.json();

            this.setState({
                dropdownUserType: data.userType
            });
        } catch (e) {
            console.log(e);
        }
    }

    async handleCustomerChange(selectedOption) {
        this.setState({ customerId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
        this.state.errors["customerId"] = "";
        if (selectedOption.selectedItem !== null && selectedOption.selectedItem.customerTypeId === CustomerType.AirlineCrew) {
            this.setState({ airlineId: selectedOption.selectedItem.airlineId })
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name == "countryCode") {
            const re = /^[0-9\b]+$/;
            if (event.target.value === '' || re.test(event.target.value)) {
                this.setState({ countryCode: event.target.value })
            }
        } else {
            this.setState({
                [name]: value
            });
        }
        this.state.errors[name] = "";
    }

    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.userName = "";
        errors.password = "";
        errors.userType = "";
        errors.customerId = "";
        errors.firstName = "";
        errors.lastName = "";
        errors.mobileNumber = "";
        errors.countryCode = "";
        errors.email = "";

        if (this.state.userName == null || this.state.userName.trim() == '') {
            formIsValid = false;
            errors.userName = "Please enter username.";
        }
        if (this.state.userId == 0 && this.state.password.trim() != '' && this.state.vIsPasswordComplexity == true) {
            const renumbersymbolexp = /[^0-9]/g;
            const lowercaseexp = /[^a-z]/g;
            const uppercaseexp = /[^A-Z]/g;
            const specialcharacters = /[^!@#$%^&*]/g;

            var IsPwdnumbersymbol = this.state.password.replace(renumbersymbolexp, "").length;
            var IsLowercase = this.state.password.replace(lowercaseexp, "").length;
            var IsUppercase = this.state.password.replace(uppercaseexp, "").length;
            var IsSpecialCharacters = this.state.password.replace(specialcharacters, "").length;

            if (this.state.password.length < this.state.vMinLength) {
                formIsValid = false;
                errors.password = "Password must be " + this.state.vMinLength + " characters long";
            }
            else if (IsPwdnumbersymbol < this.state.vMinNumber) {
                formIsValid = false;
                errors.password = "Password must contains atleast " + this.state.vMinNumber + " number";
            }
            else if (IsLowercase < this.state.vMinLowerCaseLetter) {
                formIsValid = false;
                errors.password = "Password must contains atleast " + this.state.vMinLowerCaseLetter + " lowercase alphabet";
            }
            else if (IsUppercase < this.state.vMinUpperCaseLetter) {
                formIsValid = false;
                errors.password = "Password must contains atleast " + this.state.vMinUpperCaseLetter + " uppercase alphabet";
            }
            else if (IsSpecialCharacters < this.state.vMinSpecialCharacter) {
                formIsValid = false;
                errors.password = "Password must contains atleast " + this.state.vMinSpecialCharacter + " special character";
            }
        }
        if (parseInt(this.state.userType) == 0) {
            formIsValid = false;
            errors.userType = "Please select user type.";
        }
        if (parseInt(this.state.customerId) == 0) {
            formIsValid = false;
            errors.customerId = "Please select customer.";
        }
        if (this.state.firstName == null || this.state.firstName.trim() == '') {
            formIsValid = false;
            errors.firstName = "Please enter first name.";
        }
        if (this.state.lastName == null || this.state.lastName.trim() == '') {
            formIsValid = false;
            errors.lastName = "Please enter last name.";
        }
        if (this.state.mobileNumber == null || this.state.mobileNumber.trim() === "") {
            formIsValid = false;
            errors.mobileNumber = "Please enter mobile.";
        } else if (this.state.mobileNumber.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length != 10) {
            formIsValid = false;
            errors.mobileNumber = "Invalid mobile number.";
        }
        if (this.state.countryCode == null || this.state.countryCode.trim() == '') {
            formIsValid = false;
            errors.countryCode = "Required.";
        }
        if (this.state.email == null || this.state.email.trim() == '') {
            formIsValid = false;
            errors.email = "Please enter email.";
        }

        if (this.state.email != '') {
            let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!regEmail.test(this.state.email)) {
                formIsValid = false;
                errors.email = "Please enter valid email.";
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    editUserControl(e, data) {
        e.preventDefault();
        this.setState({
            userModalIsOpen: true,
            singelData: data,
            userId: data === null ? 0 : data.id
        });
    }

    openPasswordModal(e, data) {
        e.preventDefault();
        data.accountId = sessionStorage.getItem('session_accountId');
        this.refs.changePasswordModal.showModal(data);
    }

    openUserAirlineModal(e, data) {
        e.preventDefault();

        this.setState({
            userAirlineModalIsOpen: true,
            singelData: data,
            userId: data === null ? 0 : data.id
        });
    }

    openMasterHistoryModal(e, data) {
        e.preventDefault();
        let userName = data.fullName !== null ? data.fullName : '';
        if (userName.trim() === '') {
            userName = data.userName !== null ? data.userName : '';
        }
        this.refs.masterHistoryComponent.showModal('customer-api', 'Customer User', userName, data.id === null ? 0 : Number(data.id));
    }

    afterOpenUserModal() {
        this.getUserType();
        this.getCustomer();
        if (this.state.singelData === null) {
            this.setState({
                userName: "",
                password: "",
                customerId: 0,
                userType: 0,
                firstName: "",
                lastName: "",
                mobileNumber: "",
                countryCode: "",
                email: "",
                address: "",
                isActive: true,
                isLocked: false,
                inactiveReason: '',
                lockedReason: '',
            });

        } else {
            const user = this.state.singelData;
            this.setState({
                userName: user.userName,
                password: user.password,
                customerId: user.accountId,
                userType: user.userTypeId,
                firstName: user.firstName,
                lastName: user.lastName,
                mobileNumber: user.mobile,
                countryCode: user.countryCode,
                email: user.email,
                address: user.address,
                isActive: user.enable,
                isLocked: user.isLockedOut,
                inactiveReason: user.inactiveReason,
                lockedReason: user.lockedOutReason,
            });

        }
    }

    async handleUserSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            this.setState({ isSubmited: true });

            var newObject = new Object();
            newObject.Id = this.state.userId;
            newObject.AccountId = parseInt(this.state.customerId);
            newObject.UserTypeId = parseInt(this.state.userType);
            newObject.UserName = this.state.userName.trim();
            newObject.Password = this.state.password.trim();
            newObject.FirstName = this.state.firstName.trim();
            newObject.LastName = this.state.lastName.trim();
            newObject.Email = this.state.email.trim();
            newObject.Address = this.state.address;
            newObject.Mobile = this.state.mobileNumber.trim();
            newObject.ActionTypeId = 4;
            newObject.CountryCode = this.state.countryCode.trim();
            newObject.Enable = this.state.isActive;
            newObject.IsLockedOut = this.state.isLocked;
            newObject.InactiveReason = this.state.inactiveReason;
            newObject.LockedReason = this.state.lockedReason;
            newObject.AirlineId = this.state.userId > 0 ? 0 : this.state.airlineId;
            try {
                const requestParams = getRequestParams('POST', newObject);
                let path = this.state.userId > 0 ? 'user-api/update-user-details' : 'user-api/save-user-details';
                const response = await fetch(path, requestParams);
                const data = await response.json();

                Swal.fire({
                    icon: data.isSuccess ? 'success' : 'error',
                    title: data.isSuccess ? 'Success' : 'Oops...',
                    text: data.resultMessage,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            this.closeUserModal();
                            this.getUserList();
                        }
                    } else {
                        this.setState({ isSubmited: false })
                    }
                })
            } catch (e) {
                this.setState({ isSubmited: false })
            }
        }
    }

    async isAllowConfirm() {
        let isResult = false;
        await Swal.fire({
            icon: 'success',
            title: 'Delete Customer User',
            html: "Are you sure want to delete customer user?",
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Delete',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        });
        return isResult;
    }
    onfailure(title, resultMessage) {
        Swal.fire({
            showCloseButton: true,
            icon: 'error',
            title: (title === null || title.trim() === '') ? 'Oops...' : title,
            text: (resultMessage === null || resultMessage.trim() === '') ? 'Something went wrong!' : resultMessage.trim(),
        })
    }
    async deleteCustomerUser(e, dataresult) {
        e.preventDefault();
        try {
            const isConfirm = await this.isAllowConfirm();
            if (!isConfirm) {
                return;
            }
            var deleteCustomerUserRequest = new Object();
            deleteCustomerUserRequest.UserId = parseInt(dataresult.id);
            const requestParams = getRequestParams('POST', deleteCustomerUserRequest);
            const response = await fetch("user-api/delete", requestParams);
            const data = await response.json();
            if (data.isSuccess) {
                Swal.fire({
                    icon: data.isSuccess ? 'success' : 'error',
                    title: data.isSuccess ? 'success' : 'Oops...',
                    text: data.resultMessage,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (data.isSuccess) {
                        this.getUserList();
                    }
                });
            } else {
                this.onfailure('Failed to Delete Customer User.', data.resultMessage);
            }
        } catch (e) {
            //debugger;
        }
    }

    closeUserModal() {
        this.setState({
            userModalIsOpen: false,
            isSubmited: false,
            singelData: null,
            userId: 0,
            userName: '',
            password: '',
            customerId: '',
            userType: 0,
            firstName: '',
            lastName: '',
            mobileNumber: '',
            countryCode: '',
            email: '',
            address: '',
            isActive: true,
            isLocked: false,
            inactiveReason: '',
            lockedReason: '',
            errors: {
                userName: '',
                password: '',
                customerId: '',
                userType: '',
                firstName: '',
                lastName: '',
                mobileNumber: '',
                countryCode: '',
                email: '',
            }
        });
    }

    onSelectedItemKeysChange(args) {
        if (args.name === 'selectedItemKeys') {
            this.setState({
                selectedItemKeys: args.value
            });
        }
    }

    closeAirlineModal() {
        this.setState({
            userAirlineModalIsOpen: false,
            isSubmited: false,
            singelData: null,
            userId: 0,
        });
    }

    async handleAirlineSubmit(e) {
        e.preventDefault();
        this.setState({ isSubmited: true });
    }

    render() {
        const renderGridCell = (data) => {

            return <div>
                <a href="#" className="fas fa-pen icon text-info" title="Edit" onClick={(e) => { this.editUserControl(e, data.data) }} ></a>&nbsp;&nbsp;
                <a href="#" className="fas fa-key icon text-info" title="Change Password" onClick={(e) => { this.openPasswordModal(e, data.data) }}></a>&nbsp;&nbsp;
                {/*<a href="#" className="fas fa-plane icon text-info" title="Customer Mapping" onClick={(e) => { this.openUserAirlineModal(e, data.data) }}></a>&nbsp;&nbsp;*/}
                <a href="#" className="fas fa-history icon text-info" title="History" onClick={(e) => { this.openMasterHistoryModal(e, data.data) }}></a>&nbsp;&nbsp;
                <a href="#" className="fas fa-trash-alt icon text-info" title="Delete Customer User" onClick={(e) => { this.deleteCustomerUser(e, data.data) }}></a>&nbsp;&nbsp;
            </div>;
        }

        const userName = this.state.singelData === null ? "" : this.state.singelData.fullName;

        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <h1 className="m-0 text-dark">Customer Users</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem><NavLink tag={Link} to="#" onClick={(e) => { this.editUserControl(e, null) }} className="btn btn-block btn-outline-secondary btn-sm" title="Add User"><i className="fas fa-plus"></i> Add User</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="card card-grid">
                        <div className="card-body">
                            <DataGrid
                                dataSource={this.state.datasource}
                                keyExpr="id"
                                selection={{ mode: 'single' }}
                                columnsAutoWidth={false}
                                showColumnLines={false}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                showBorders={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                onToolbarPreparing={this.onToolbarPreparing}>
                                <Column dataField="id" width={75} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                <Column dataField="accountName" caption="Customer Name" width={180} />
                                <Column dataField="userType" caption="User Type" width={180} />
                                <Column dataField="userName" caption="User Name" width={150} />
                                <Column dataField="fullName" caption="Full Name" />
                                <Column dataField="email" caption="Email" allowFiltering={false} allowSorting={false} />
                                <Column dataField="mobile" caption="Mobile Number" allowFiltering={false} allowSorting={false} />
                                <Column dataField="userStatus" caption="User Status" width={150} />

                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                <Export enabled={true} allowExportSelectedData={false} />
                                <ColumnChooser enabled={true} />
                                <ColumnFixing enabled={true} />

                            </DataGrid>
                        </div>
                    </div>
                </div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.userModalIsOpen}
                    style={customStyles}
                    className={"react-modal"}
                    onAfterOpen={this.afterOpenUserModal}
                    onRequestClose={this.closeUserModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleUserSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{this.state.userId > 0 ? "Modify User" : "Add User"}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeUserModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label>Customer <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.dropdownCustomer.length > 0 ? this.state.dropdownCustomer : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="customerId"
                                            defaultValue={0}
                                            value={this.state.customerId}
                                            displayExpr='fullName'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleCustomerChange}
                                            showClearButton={true}
                                            disabled={this.state.userId > 0}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerId"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                                    <div className="form-group">
                                        <label>Username <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="userName" disabled={this.state.userId > 0} placeholder="Enter username" maxLength="25" title="User Name" value={this.state.userName} onChange={this.handleInputChange} />
                                        {this.state.errors["userName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["userName"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3" style={{ display: this.state.userId > 0 ? 'none' : 'block' }}>
                                    <div className="form-group">
                                        <label for="">Password  <span className="text-danger">*</span><i className="fas fa-info-circle text-info" data-tip data-for="passwordTooltip"></i></label>
                                        <input type="password" className="form-control form-control-sm" name="password" autoComplete="new-password" disabled={this.state.userId > 0} placeholder="Enter password" maxLength="25" title="Password" value={this.state.password} onChange={this.handleInputChange} />
                                        {this.state.errors["password"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["password"]}</span>}
                                        <ReactTooltip id="passwordTooltip" place="top" effect="solid">
                                            <div className="form-group">
                                                <ul>
                                                    <li>At least {this.state.vMinLength} characters long</li>
                                                    <li>At least {this.state.vMinLowerCaseLetter} lowercase character</li>
                                                    <li>At least {this.state.vMinUpperCaseLetter} uppercase character</li>
                                                    <li>At least {this.state.vMinNumber} number, symbol, or whitespace character</li>
                                                    <li>At least {this.state.vMinSpecialCharacter} special character</li>
                                                </ul>
                                            </div>
                                        </ReactTooltip>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label>User Type <span className="text-danger">*</span></label>
                                        <select name="userType" className="form-control form-control-sm" value={this.state.userType} onChange={this.handleInputChange}>
                                            <option value="0">Select user type</option>
                                            {
                                                this.state.dropdownUserType &&
                                                this.state.dropdownUserType.map((item, i) =>
                                                    (<option key={i} value={item.id}>{item.name}</option>))
                                            }
                                        </select>
                                        {this.state.errors["userType"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["userType"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">

                                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                    <div className="form-group">
                                        <label>First Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="firstName" placeholder="Enter first name" maxLength="50" title="First Name" value={this.state.firstName} onChange={this.handleInputChange} />
                                        {this.state.errors["firstName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["firstName"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                    <div className="form-group">
                                        <label>Last Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="lastName" placeholder="Enter last name" maxLength="50" title="Last Name" value={this.state.lastName} onChange={this.handleInputChange} />
                                        {this.state.errors["lastName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["lastName"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                                    <div className="form-group">
                                        <label>Code <span className="text-red">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="countryCode" placeholder="+1" maxLength="2" title="Country Code" value={this.state.countryCode} onChange={this.handleInputChange} />
                                        {this.state.errors["countryCode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["countryCode"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                    <div className="form-group">
                                        <label>Mobile Number <span className="text-danger">*</span></label>
                                        <NumberFormat className="form-control form-control-sm" name="mobileNumber" placeholder="(000) 000-0000" title="Mobile Number" value={this.state.mobileNumber} onChange={this.handleInputChange} format="(###) ###-####" />
                                        {this.state.errors["mobileNumber"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["mobileNumber"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                    <div className="form-group">
                                        <label>Email <span className="text-red">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="email" placeholder="test@domain.com" maxLength="100" title="Email" value={this.state.email} onChange={this.handleInputChange} />
                                        {this.state.errors["email"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["email"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6" style={{ display: this.state.userId <= 0 ? 'none' : 'block' }}>
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="isActive" name="isActive" checked={this.state.isActive} onChange={this.handleInputChange} />
                                            <label className="form-check-label" htmlFor="isActive"> Is Active &nbsp;<i>(If Inactive, please enter reason)</i></label>
                                        </div>
                                        <textarea className="form-control form-control-sm" name="inactiveReason" placeholder="Type inactive reason" maxLength="100" title="Inactive Reason" value={this.state.inactiveReason} onChange={this.handleInputChange} />
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6" style={{ display: this.state.userId <= 0 ? 'none' : 'block' }}>
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="isLocked" name="isLocked" checked={this.state.isLocked} onChange={this.handleInputChange} />
                                            <label className="form-check-label" htmlFor="isLocked"> Is Locked-out &nbsp;&nbsp;<i>(If Locked out, please enter reason)</i></label>
                                        </div>
                                        <textarea className="form-control form-control-sm" name="lockedReason" placeholder="Type locked-out reason" maxLength="100" title="Locked-out Reason" value={this.state.lockedReason} onChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success btn-sm" onClick={this.handleUserSubmit} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeUserModal} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <Modal ariaHideApp={false}
                    isOpen={this.state.userAirlineModalIsOpen}
                    style={airlineCustomStyles}
                    className={"react-modal"}
                    //onAfterOpen={this.afterOpenUserModal}
                    //onRequestClose={this.closeUserModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleAirlineSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Map Airlines(s)</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeAirlineModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <small>Please map the airline(s) for the user <i><strong className="text-danger">{userName}</strong></i>.</small>
                                </div>
                            </div>
                            <div className="widget-container">
                                <List
                                    dataSource={mappedUnmappedCustomer}
                                    height={300}
                                    showSelectionControls={true}
                                    selectionMode="all"
                                    selectAllMode='page'
                                    selectedItemKeys={this.state.selectedItemKeys}
                                    onOptionChanged={this.onSelectedItemKeysChange}>
                                </List>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success btn-sm" >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeAirlineModal} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <ChangePasswordComponent ref="changePasswordModal" />
                <MasterHistoryComponent ref="masterHistoryComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}