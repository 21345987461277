import React, { Component } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel, FilterRow,
    HeaderFilter, Pager,
    Paging, Scrolling,
    SearchPanel,
    Selection,
    SortByGroupSummaryInfo,
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { SelectBox } from 'devextreme-react/select-box';
import DropDownBox from 'devextreme-react/drop-down-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import { SendEmailComponent } from '../Shared/SendEmailComponent'
let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export const periodTypeDataList = [{
    'id': 'D',
    'name': 'Daily',
}, {
    'id': 'W',
    'name': 'Weekly',
}, {
    'id': 'M',
    'name': 'Monthly',
}]
export class RideStatuswiseSummaryReportComponent extends Component {
    constructor() {
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        super()
        this.state = {
            isSubmitted: false,
            isEmailSubmitted: false,
            isSearchSubmitted: false,
            loadPanelVisible: false,
            exportPDF: false,
            rideSummaryWithInvoiceCountGridDataSource: null,
            periodTypeDropdownList: [],
            periodTypeDropdownData: null,
            periodType: '',
            isAllCustomerType: true,
            customerTypeDropdownList: [],
            customerTypeDropdownData: null,
            isAllPickupZone: true,
            pickupZoneDropdownList: [],
            pickupZoneDropdownData: null,
            isAllDropoffZone: true,
            dropoffZoneDropdownList: [],
            dropoffZoneDropdownData: null,
            isAllVehicleGroupType: true,
            vehicleGroupTypeDropdownList: [],
            vehicleGroupTypeDropdownData: null,
            isAllCustomer: true,
            customerDropdownList: [],
            customerDropdownData: null,
            customerId: 0,
            fromDate: moment(currentTime).format("MM/DD/YYYY"),
            toDate: moment(currentTime).format("MM/DD/YYYY"),
            errors: {
                customerType: '',
                pickupZone: '',
                dropoffZone: '',
                vehicleGroupType: '',
                customer: '',
                periodType: '',
                fromDate: '',
                toDate: ''
            }
        }

        this.syncDataGridCustomerTypeSelection = this.syncDataGridCustomerTypeSelection.bind(this);
        this.dataGridCustomerTypeRender = this.dataGridCustomerTypeRender.bind(this);
        this.syncDataGridPickupzoneSelection = this.syncDataGridPickupzoneSelection.bind(this);
        this.dataGridPickupzoneRender = this.dataGridPickupzoneRender.bind(this);
        this.syncDataGridDropoffzoneSelection = this.syncDataGridDropoffzoneSelection.bind(this);
        this.dataGridDropoffzoneRender = this.dataGridDropoffzoneRender.bind(this);
        this.syncDataGridVehicleGroupTypeSelection = this.syncDataGridVehicleGroupTypeSelection.bind(this);
        this.dataGridVehicleGroupTypeRender = this.dataGridVehicleGroupTypeRender.bind(this);
    }
    async componentDidMount() {
        this.setState({ loadPanelVisible: true });
        await this.getCustomerTypeDropdownList();
        await this.getZonesDropdownList();
        await this.getVehicleGroupTypeDropdownList();
        await this.getCustomerDropdownList();
        await this.getPeriodTypeDropdownList();
        this.setState({ loadPanelVisible: false });
    }
    getCustomerTypeDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('customer-type-api/', requestParams);
            const data = await response.json();
            this.setState({
                customerTypeDropdownList: data.customerType
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    getZonesDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('serviceAreaZone-api/zone-dropdown/' + 0 + "/" + 0, requestParams);
            const data = await response.json();
            this.setState({
                pickupZoneDropdownList: data.locationServiceAreaZoneList,
                dropoffZoneDropdownList: data.locationServiceAreaZoneList
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    getVehicleGroupTypeDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('vehicle-group-type-api/vehicle-group-type/', requestParams);
            const data = await response.json();
            this.setState({
                vehicleGroupTypeDropdownList: data.vehicleGroupTypes
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    getCustomerDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('account-api/customer-dropdown/', requestParams);
            const data = await response.json();
            this.setState({
                customerDropdownList: data.customers
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    getPeriodTypeDropdownList = async () => {
        try {
            this.setState({ periodTypeDropdownList: periodTypeDataList });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleToolbarPreparing = (e) => {
        try {
            e.toolbarOptions.items.unshift({
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: (this.state.exportPDF) ? 'fas fa-spinner fa-spin' : 'exportpdf',
                    onClick: this.downloadAsPdf.bind(this),
                    hint: 'Export to Pdf'
                }
            });
            //e.toolbarOptions.items.unshift({
            //    location: 'after',
            //    widget: 'dxButton',
            //    options: {
            //        icon: 'email',
            //        onClick: this.emailReport.bind(this),
            //        hint: 'Email'
            //    }
            //});
        } catch (ex) {
            console.log(ex);
        }
    }
    emailReport = async () => {
        try {
            await Swal.fire({
                title: "<small>Pending Implementation</small>",
                text: "This feature is comming soon.",
                icon: "info",
                dangerMode: false,
                width: "25%",
                dangerMode: false,
                allowOutsideClick: false
            });
            return;
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnInputChange = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
            let errors = this.state.errors;
            switch (name) {
                case 'isAllCustomerType':
                    errors.customerType = '';
                    this.setState({ isAllCustomerType: value, customerTypeDropdownData: null });
                    break;
                case 'isAllPickupZone':
                    errors.pickupZone = '';
                    this.setState({ isAllPickupZone: value, pickupZoneDropdownData: null });
                    break;
                case 'isAllDropoffZone':
                    errors.dropoffZone = '';
                    this.setState({ isAllDropoffZone: value, dropoffZoneDropdownData: null });
                    break;
                case 'isAllVehicleGroupType':
                    errors.vehicleGroupType = '';
                    this.setState({ isAllVehicleGroupType: value, vehicleGroupTypeDropdownData: null });
                    break;
                case 'isAllCustomer':
                    errors.customer = '';
                    this.setState({ isAllCustomer: value, customerDropdownData: null, customerId: 0 });
                    break;
                case 'isAllDate':
                    errors.fromDate = '';
                    errors.toDate = '';
                    this.setState({ isAllDate: value, isDateRange: false });
                    break;
                case 'isDateRange':
                    errors.fromDate = '';
                    errors.toDate = '';
                    this.setState({ isAllDate: false, isDateRange: value });
                    break;
                default:
                    break;
            }
            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnPeriodTypeDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.periodType = '';
            let periodType = '';
            if (data !== null) {
                periodType = (data.selectedItem != null ? data.selectedItem.id : 0);
            }
            else {
                //errors.periodType = 'Please select summary type.';
            }
            this.setState({ periodTypeDropdownData: data, periodType: periodType, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnCustomerTypeDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.customerType = '';
            if (data === null || data.length === 0) {
                //errors.customerType = 'Please select customer type.';
            }
            this.setState({ customerTypeDropdownData: data.selectedRowKeys.length && data.selectedRowKeys || [], errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    syncDataGridCustomerTypeSelection(e) {
        this.setState({
            customerTypeDropdownData: e.value || []
        });
    }
    handleOnPickupZoneDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.pickupZone = '';
            if (data === null || data.length === 0) {
                //errors.pickupZone = 'Please select zone.';
            }
            this.setState({ pickupZoneDropdownData: data.selectedRowKeys.length && data.selectedRowKeys || [], errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    syncDataGridPickupzoneSelection(e) {
        this.setState({
            pickupZoneDropdownData: e.value || []
        });
    }
    handleOnDropoffZoneDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.dropoffZone = '';
            if (data === null || data.length === 0) {
                //errors.dropoffZone = 'Please select zone.';
            }
            this.setState({ dropoffZoneDropdownData: data.selectedRowKeys.length && data.selectedRowKeys || [], errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    syncDataGridDropoffzoneSelection(e) {
        this.setState({
            dropoffZoneDropdownData: e.value || []
        });
    }
    handleOnVehicleGroupTypeDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.vehicleGroupType = '';
            if (data === null || data.length === 0) {
                //errors.vehicleGroupType = 'Please select vehicle type.';
            }            
            this.setState({ vehicleGroupTypeDropdownData: data.selectedRowKeys.length && data.selectedRowKeys || [], errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    syncDataGridVehicleGroupTypeSelection(e) {
        this.setState({
            vehicleGroupTypeDropdownData: e.value || []
        });
    }
    handleOnCustomerDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.customer = '';
            let customerId = 0;
            if (data !== null) {
                customerId = (data.selectedItem != null ? data.selectedItem.id : 0);
            }
            else {
                //errors.customer = 'Please select customer.';
            }
            this.setState({ customerDropdownData: data, customerId: customerId, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnFromDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.fromDate = '';
            let fromDate = '';
            if (event.value !== null && event.value !== '') {
                fromDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.fromDate = 'Please select date.';
            }
            if (this.state.toDate.trim() !== '') {
                errors.toDate = '';
            }
            this.setState({ fromDate: fromDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnToDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.toDate = '';
            let toDate = '';
            if (event.value !== null && event.value !== '') {
                toDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.toDate = 'Please select date.';
            }
            if (this.state.fromDate.trim() !== '') {
                errors.fromDate = '';
            }
            this.setState({ toDate: toDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnSearchSubmit = async event => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isSearchSubmitted: true, loadPanelVisible: true });
                let fromDate = '';
                let toDate = '';
                if (this.state.fromDate.trim() !== '') {
                    fromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00'
                }
                if (this.state.toDate.trim() !== '') {
                    toDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59'
                }
                let request = new Object();
                request.CustomerId = Number(this.state.customerId);
                request.FromDate = fromDate;
                request.ToDate = toDate;
                request.CustomerTypeIds = await this.getIds(this.state.customerTypeDropdownData);
                request.PickupZoneIds = await this.getIds(this.state.pickupZoneDropdownData);
                request.DropoffZoneIds = await this.getIds(this.state.dropoffZoneDropdownData);
                request.VehicleGroupTypeIds = await this.getIds(this.state.vehicleGroupTypeDropdownData);
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('report-api/statuswise-ride-summary', requestParams);
                const data = await response.json();
                this.setState({
                    rideSummaryWithInvoiceCountGridDataSource: data.rideSummaryWithInvoiceCount
                });
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSearchSubmitted: false, loadPanelVisible: false });
    }
    handleOnClearSearch = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isEmailSubmitted: false,
                isSearchSubmitted: false,
                loadPanelVisible: false,
                exportPDF: false,
                rideSummaryWithInvoiceCountGridDataSource: null,
                periodTypeDropdownData: null,
                periodType: '',
                isAllCustomerType: true,
                customerTypeDropdownData: null,
                isAllPickupZone: true,
                pickupZoneDropdownData: null,
                isAllDropoffZone: true,
                dropoffZoneDropdownData: null,
                isAllVehicleGroupType: true,
                vehicleGroupTypeDropdownData: null,
                isAllCustomer: true,
                customerDropdownData: null,
                customerId: 0,
                fromDate: moment(currentTime).format("MM/DD/YYYY"),
                toDate: moment(currentTime).format("MM/DD/YYYY"),
                errors: {
                    customerType: '',
                    pickupZone: '',
                    dropoffZone: '',
                    vehicleGroupType: '',
                    customer: '',
                    periodType: '',
                    fromDate: '',
                    toDate: ''
                }
            });
        } catch (ex) {
            console.log();
        }
    }
    handleOnReportSubmit = async (event) => {
        event.preventDefault();
        try {
            this.setState({ isSubmitted: true });
            await this.downloadReport('pdf');
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false });
    }
    downloadAsPdf = async () => {
        try {
            this.setState({ exportPDF: true });
            await this.downloadReport('pdf');
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ exportPDF: false });
    }
    downloadReport = async (fileFormat) => {
        try {
            let isValid = true;
            let errors = this.state.errors;
            errors.periodType = '';
            isValid = await this.isFormValid();
            if (this.state.periodType.trim() === '') {
                errors.periodType = 'Please select summary type.';
                isValid = false;
            }
            this.setState({ errors: errors });
            if (isValid === true) {
                let fromDate = '';
                let toDate = '';
                let periodType = 'D';
                let reportName = '';
                if (this.state.fromDate.trim() !== '') {
                    fromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00'
                }
                if (this.state.toDate.trim() !== '') {
                    toDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59'
                }
                if (this.state.periodType.trim() !== '') {
                    periodType = this.state.periodType.trim();
                }
                if (this.state.periodType.trim() !== '') {
                    if (this.state.periodTypeDropdownData !== null && Object.keys(this.state.periodTypeDropdownData).length > 0) {
                        reportName = this.state.periodTypeDropdownData.name;
                    }
                }
                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.CustomerId = Number(this.state.customerId);
                request.StartDate = fromDate;
                request.EndDate = toDate;
                request.CustomerTypeIds = await this.getIds(this.state.customerTypeDropdownData);
                request.PickupZoneIds = await this.getIds(this.state.pickupZoneDropdownData);
                request.DropoffZoneIds = await this.getIds(this.state.dropoffZoneDropdownData);
                request.VehicleGroupTypeIds = await this.getIds(this.state.vehicleGroupTypeDropdownData);
                request.Grouping = periodType;
                request.SelectionCrieteria = await this.setSelectionCrieteria();
                request.fileFormat = fileFormat;
                request.MethodName = "GetRideSummaryWithInvoiceCountReport";
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                let a = document.createElement('a');
                a.href = dataResponse.reportBase64String;
                a.download = 'RideSummaryWithInvoiceCount' + reportName + '.' + fileFormat;
                a.click();
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    isFormValid = async () => {
        let isValid = true;
        try {
            let errors = this.state.errors;
            errors.customerType = '';
            errors.pickupZone = '';
            errors.dropoffZone = '';
            errors.vehicleGroupType = '';
            errors.customer = '';
            errors.periodType = '';
            errors.fromDate = '';
            errors.toDate = '';
            if (this.state.isAllCustomerType === false) {
                if (this.state.customerTypeDropdownData === null || this.state.customerTypeDropdownData.length === 0) {
                    errors.customerType = 'Please select customer type.';
                    isValid = false;
                }
            }
            if (this.state.isAllPickupZone === false) {
                if (this.state.pickupZoneDropdownData === null || this.state.pickupZoneDropdownData.length === 0) {
                    errors.pickupZone = 'Please select zone.';
                    isValid = false;
                }
            }
            if (this.state.isAllDropoffZone === false) {
                if (this.state.dropoffZoneDropdownData === null || this.state.dropoffZoneDropdownData.length === 0) {
                    errors.dropoffZone = 'Please select zone.';
                    isValid = false;
                }
            }
            if (this.state.isAllVehicleGroupType === false) {
                if (this.state.vehicleGroupTypeDropdownData === null || this.state.vehicleGroupTypeDropdownData.length === 0) {
                    errors.vehicleGroupType = 'Please select vehicle type.';
                    isValid = false;
                }
            }
            if (this.state.isAllCustomer === false) {
                if (Number(this.state.customerId) === 0) {
                    errors.customer = 'Please select customer.';
                    isValid = false;
                }
            }
            if (this.state.fromDate.trim() === '') {
                errors.fromDate = 'Please select date.';
                isValid = false;
            }
            if (this.state.toDate.trim() === '') {
                errors.toDate = 'Please select date.';
                isValid = false;
            }
            if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                if (moment(this.state.fromDate, 'MM/DD/YYYY').isAfter(this.state.toDate, 'MM/DD/YYYY')) {
                    errors.fromDate = 'Please enter a valid date range.';
                    errors.toDate = 'Please enter a valid date range.';
                    isValid = false;
                }
            }
            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    setSelectionCrieteria = async () => {
        let selectionCrieteria = 'Statuswise ride summary for';
        try {
            if (this.state.customerTypeDropdownData === null || this.state.customerTypeDropdownData.length === 0) {
                selectionCrieteria += ' all customer type';
            }
            else {
                selectionCrieteria += ' customer type - [ ';
                for (var i = 0; i < this.state.customerTypeDropdownData.length; i++) {
                    selectionCrieteria += this.state.customerTypeDropdownData[i].name;
                    if (i < this.state.customerTypeDropdownData.length - 1) {
                        selectionCrieteria += ', ';
                    }
                }
                selectionCrieteria += ' ]';
            }

            if (this.state.pickupZoneDropdownData === null || this.state.pickupZoneDropdownData.length === 0) {
                selectionCrieteria += ', all pickup zone';
            }
            else {
                selectionCrieteria += ', pickup zone - [ ';
                for (var i = 0; i < this.state.pickupZoneDropdownData.length; i++) {
                    selectionCrieteria += this.state.pickupZoneDropdownData[i].name;
                    if (i < this.state.pickupZoneDropdownData.length - 1) {
                        selectionCrieteria += ', ';
                    }
                }
                selectionCrieteria += ' ]';
            }

            if (this.state.dropoffZoneDropdownData === null || this.state.dropoffZoneDropdownData.length === 0) {
                selectionCrieteria += ', all dropoff zone';
            }
            else {
                selectionCrieteria += ', dropoff zone - [ ';
                for (var i = 0; i < this.state.dropoffZoneDropdownData.length; i++) {
                    selectionCrieteria += this.state.dropoffZoneDropdownData[i].name;
                    if (i < this.state.dropoffZoneDropdownData.length - 1) {
                        selectionCrieteria += ', ';
                    }
                }
                selectionCrieteria += ' ]';
            }

            if (this.state.vehicleGroupTypeDropdownData === null || this.state.vehicleGroupTypeDropdownData.length === 0) {
                selectionCrieteria += ', all vehicle type';
            }
            else {
                selectionCrieteria += ', vehicle type - [ ';
                for (var i = 0; i < this.state.vehicleGroupTypeDropdownData.length; i++) {
                    selectionCrieteria += this.state.vehicleGroupTypeDropdownData[i].name;
                    if (i < this.state.vehicleGroupTypeDropdownData.length - 1) {
                        selectionCrieteria += ', ';
                    }
                }
                selectionCrieteria += ' ]';
            }

            if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                selectionCrieteria += ', date from ' + this.state.fromDate + ' to ' + this.state.toDate;
            }
            if (Number(this.state.customerId) > 0) {
                if (this.state.customerDropdownData !== null && Object.keys(this.state.customerDropdownData).length > 0) {
                    selectionCrieteria += ', customer - ' + this.state.customerDropdownData.name;
                }
            }
            else {
                selectionCrieteria += ', all customer';
            }
            if (this.state.periodType.trim() !== '') {
                if (this.state.periodTypeDropdownData !== null && Object.keys(this.state.periodTypeDropdownData).length > 0) {
                    selectionCrieteria += ', report summary type - ' + this.state.periodTypeDropdownData.name;
                }
            }
        } catch (ex) {
            selectionCrieteria = '';
            console.log(ex);
        }
        return selectionCrieteria;
    }
    getIds = async (data) => {
        let ids = '';
        try {
            if (data !== null) {
                if (Object.keys(data).length > 0) {
                    for (var i = 0; i < data.length; i++) {
                        ids += (ids === '') ? data[i].id : ',' + data[i].id
                    }
                }
            }
        } catch (ex) {
            ids = '';
            console.log(ex);
        }
        return ids;
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            let isValid = true;
            let errors = this.state.errors;
            errors.periodType = '';
            isValid = await this.isFormValid();
            if (this.state.periodType.trim() === '') {
                errors.periodType = 'Please select summary type.';
                isValid = false;
            }
            this.setState({ errors: errors });
            if (isValid === true) {
                this.setState({ isEmailSubmitted: true });
                let fromDate = '';
                let toDate = '';
                let periodType = 'D';
                let reportName = '';
                if (this.state.fromDate.trim() !== '') {
                    fromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00'
                }
                if (this.state.toDate.trim() !== '') {
                    toDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59'
                }
                if (this.state.periodType.trim() !== '') {
                    periodType = this.state.periodType.trim();
                }
                if (this.state.periodType.trim() !== '') {
                    if (this.state.periodTypeDropdownData !== null && Object.keys(this.state.periodTypeDropdownData).length > 0) {
                        reportName = this.state.periodTypeDropdownData.name;
                    }
                }
                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.CustomerId = Number(this.state.customerId);
                request.StartDate = fromDate;
                request.EndDate = toDate;
                request.CustomerTypeIds = await this.getIds(this.state.customerTypeDropdownData);
                request.PickupZoneIds = await this.getIds(this.state.pickupZoneDropdownData);
                request.DropoffZoneIds = await this.getIds(this.state.dropoffZoneDropdownData);
                request.VehicleGroupTypeIds = await this.getIds(this.state.vehicleGroupTypeDropdownData);
                request.Grouping = periodType;
                request.SelectionCrieteria = await this.setSelectionCrieteria();
                request.fileFormat = 'pdf';
                request.MethodName = "GetRideSummaryWithInvoiceCountReport";
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Statuswise Ride Summary';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = 'RideSummaryWithInvoiceCount' + reportName + '.pdf'
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-sm-6 col-md-6 col-lg-6">
                                <h1 className="m-0 text-dark">Statuswise Ride Summary</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem> <NavLink tag={Link} to="/report" className="btn btn-block btn-outline-secondary btn-sm" title="Reports" ><i className="fas fa-backward"></i> Back</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <form method="post" onSubmit={this.handleOnReportSubmit}
                    style={(this.state.isSubmitted || this.state.isSearchSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="rez-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                id="isAllCustomerType" name="isAllCustomerType"
                                                checked={this.state.isAllCustomerType}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isAllCustomerType"> All Customer Type</label>
                                        </div>
                                        <DropDownBox
                                            value={this.state.customerTypeDropdownData}
                                            valueExpr="id"
                                            deferRendering={false}
                                            displayExpr="name"
                                            placeholder="Select customer type ..."
                                            showClearButton={true}
                                            dataSource={this.state.customerTypeDropdownList}
                                            onValueChanged={this.syncDataGridCustomerTypeSelection}
                                            contentRender={this.dataGridCustomerTypeRender}
                                            disabled={this.state.isAllCustomerType}
                                        />
                                        {this.state.errors["customerType"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["customerType"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                id="isAllPickupZone" name="isAllPickupZone"
                                                checked={this.state.isAllPickupZone}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isAllPickupZone"> All Pick-up Zone</label>
                                        </div>
                                        <DropDownBox
                                            value={this.state.pickupZoneDropdownData}
                                            valueExpr="id"
                                            deferRendering={false}
                                            displayExpr="name"
                                            placeholder="Select pickup zone ..."
                                            showClearButton={true}
                                            dataSource={this.state.pickupZoneDropdownList}
                                            onValueChanged={this.syncDataGridPickupzoneSelection}
                                            contentRender={this.dataGridPickupzoneRender}
                                            disabled={this.state.isAllPickupZone}
                                        />
                                        {this.state.errors["pickupZone"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["pickupZone"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                id="isAllDropoffZone" name="isAllDropoffZone"
                                                checked={this.state.isAllDropoffZone}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isAllDropoffZone"> All Drop Off Zone</label>
                                        </div>
                                        <DropDownBox
                                            value={this.state.dropoffZoneDropdownData}
                                            valueExpr="id"
                                            deferRendering={false}
                                            displayExpr="name"
                                            placeholder="Select dropoff zone ..."
                                            showClearButton={true}
                                            dataSource={this.state.dropoffZoneDropdownList}
                                            onValueChanged={this.syncDataGridDropoffzoneSelection}
                                            contentRender={this.dataGridDropoffzoneRender}
                                            disabled={this.state.isAllDropoffZone}
                                        />
                                        {this.state.errors["dropoffZone"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["dropoffZone"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                name="isAllVehicleGroupType"
                                                checked={this.state.isAllVehicleGroupType}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isAllVehicleGroupType"> All Vehicle Group Type</label>
                                        </div>
                                        <DropDownBox
                                            value={this.state.vehicleGroupTypeDropdownData}
                                            valueExpr="id"
                                            deferRendering={false}
                                            displayExpr="name"
                                            placeholder="Select vehicle group type ..."
                                            showClearButton={true}
                                            dataSource={this.state.vehicleGroupTypeDropdownList}
                                            onValueChanged={this.syncDataGridVehicleGroupTypeSelection}
                                            contentRender={this.dataGridVehicleGroupTypeRender}
                                            disabled={this.state.isAllVehicleGroupType}
                                        />
                                        {this.state.errors["vehicleGroupType"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["vehicleGroupType"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>From Date (Pickup)</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="fromDate"
                                            placeholder="MM/dd/yyyy"
                                            defaultValue={this.state.fromDate}
                                            value={this.state.fromDate}
                                            onValueChanged={this.handleOnFromDateChange}
                                            width={'100%'}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["fromDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["fromDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>To Date (Pickup)</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="toDate"
                                            placeholder="MM/dd/yyyy"
                                            defaultValue={this.state.toDate}
                                            value={this.state.toDate}
                                            min={this.state.fromDate}
                                            onValueChanged={this.handleOnToDateChange}
                                            width={'100%'}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["toDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["toDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                id="isAllCustomer" name="isAllCustomer"
                                                checked={this.state.isAllCustomer}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isAllCustomer"> All Customer</label>
                                        </div>
                                        <SelectBox
                                            dataSource={this.state.customerDropdownList.length > 0 ? this.state.customerDropdownList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="ddcustomer"
                                            defaultValue={[]}
                                            value={this.state.customerDropdownData}
                                            displayExpr='fullName'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnCustomerDropdownChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customer"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["customer"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>Report Summary</label>
                                        <SelectBox
                                            dataSource={this.state.customerDropdownList.length > 0 ? this.state.customerDropdownList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select summary type"
                                            name="ddperiodtype"
                                            defaultValue={[]}
                                            value={this.state.periodTypeDropdownData}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnPeriodTypeDropdownChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["periodType"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["periodType"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12" >
                                    <div className="form-group">
                                        <label className="btn-block">&nbsp;</label>
                                        <button className="btn btn-success btn-sm" title="Search" onClick={this.handleOnSearchSubmit}>
                                            {this.state.isSearchSubmitted && <span><i className="fas fa-spinner fa-spin"></i></span>}
                                            {!this.state.isSearchSubmitted && <span><i className="fas fa-search"></i></span>}
                                        </button>&nbsp;&nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" title="Clear" onClick={this.handleOnClearSearch}>
                                            <i className="fas fa-eraser"></i>
                                        </button>&nbsp;&nbsp;
                                        <button type="submit" className="btn btn-custom-gray btn-sm" title="Download">
                                            {this.state.isSubmitted && <span><i className="fas fa-spinner fa-spin"></i> Loading...</span>}
                                            {!this.state.isSubmitted && <span><i className="fas fa-file-pdf"></i> Download</span>}
                                        </button>&nbsp;&nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleOnSendEmail} >
                                            {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="content">
                    <div className="card">
                        <div className="card-body">
                            <DataGrid
                                dataSource={this.state.rideSummaryWithInvoiceCountGridDataSource}
                                columnsAutoWidth={true}
                                showColumnLines={false}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                showBorders={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                width='100%'
                                onToolbarPreparing={this.handleToolbarPreparing}>
                                <Column dataField="pickupDate" caption="Pickup Date" />
                                <Column dataField="totalRidesBooked" caption="Ride(s) Booked" alignment='center' />
                                <Column dataField="totalPaxBooked" caption="Pax(s) Booked" alignment='center' />
                                <Column dataField="totalPendingRides" caption="Ride(s) Pending" alignment='center' />
                                <Column dataField="totalCancelledRids" caption="Ride(s) Cancelled" alignment='center' />
                                <Column dataField="totalRidesCompleted" caption="Ride(s) Completed" alignment='center' />
                                <Column dataField="totalPickupPax" caption="Pax(s) Picked-up" alignment='center' />
                                <Column dataField="totalInvoicedRides" caption="Ride(s) Invoiced" alignment='center' />
                                <Column dataField="totalInvoicedAmount" caption="Invoiced Amount" alignment='center' />
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={false} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                <Export enabled={true} allowExportSelectedData={false} />
                                <ColumnChooser enabled={false} />
                                <ColumnFixing enabled={false} />
                            </DataGrid>
                        </div>
                    </div>
                </div>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        );
    }

    dataGridCustomerTypeRender() {
        return (
            <DataGrid
                dataSource={this.state.customerTypeDropdownList}
                id="grid-container"
                keyExpr="id"
                hoverStateEnabled={true}
                selectedRowKeys={this.state.customerTypeDropdownData}
                onSelectionChanged={this.handleOnCustomerTypeDropdownChange}
                ref={ref => this.dataGrid = ref}>
                <Column dataField="name" caption="Customer Type" />
                <Selection mode="multiple" />
                <Scrolling mode="virtual" />
                <Paging enabled={false} pageSize={50} />
                <FilterRow visible={true} />
            </DataGrid>
        );
    }

    dataGridPickupzoneRender() {
        return (
            <DataGrid
                dataSource={this.state.pickupZoneDropdownList}
                id="grid-container"
                keyExpr="id"
                hoverStateEnabled={true}
                selectedRowKeys={this.state.pickupZoneDropdownData}
                onSelectionChanged={this.handleOnPickupZoneDropdownChange}
                ref={ref => this.dataGrid = ref}>
                <Column dataField="name" caption="Pickup zone" />
                <Selection mode="multiple" />
                <Scrolling mode="virtual" />
                <Paging enabled={false} pageSize={50} />
                <FilterRow visible={true} />
            </DataGrid>
        );
    }

    dataGridDropoffzoneRender() {
        return (
            <DataGrid
                dataSource={this.state.dropoffZoneDropdownList}
                id="grid-container"
                keyExpr="id"
                hoverStateEnabled={true}
                selectedRowKeys={this.state.dropoffZoneDropdownData}
                onSelectionChanged={this.handleOnDropoffZoneDropdownChange}
                ref={ref => this.dataGrid = ref}>
                <Column dataField="name" caption="Dropoff zone" />
                <Selection mode="multiple" />
                <Scrolling mode="virtual" />
                <Paging enabled={false} pageSize={50} />
                <FilterRow visible={true} />
            </DataGrid>
        );
    }

    dataGridVehicleGroupTypeRender() {
        return (
            <DataGrid
                dataSource={this.state.vehicleGroupTypeDropdownList}
                id="grid-container"
                keyExpr="id"
                hoverStateEnabled={true}
                selectedRowKeys={this.state.vehicleGroupTypeDropdownData}
                onSelectionChanged={this.handleOnVehicleGroupTypeDropdownChange}
                ref={ref => this.dataGrid = ref}>
                <Column dataField="description" caption="Vehicle group type" />
                <Selection mode="multiple" />
                <Scrolling mode="virtual" />
                <Paging enabled={false} pageSize={50} />
                <FilterRow visible={true} />
            </DataGrid>
        );
    }    
}