import React, { Component } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import DateBox from 'devextreme-react/date-box';
import { SelectBox } from 'devextreme-react/select-box';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions';
import { LoadPanel } from 'devextreme-react/load-panel';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'
const position = { of: '#historydiv' };

export class SalesActivityComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,

            chkCustomerSelection: "1",
            dropdownCustomer: [],
            customerCode: '',

            chkInvoiceSelection: "1",
            invoiceNumber: '',

            chkPeriodSelection: "1",
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),

            chkReportTypeSelection: "1",
            chkSelectTypeSelection: "1",
            chkOrderBySelection: "1",

            errors: {
                customerCode: '',
                invoiceNumber: '',
            }
        }

        this.getCustomer = this.getCustomer.bind(this);
        this.handleCustomerChange = this.handleCustomerChange.bind(this);
        this.handleCustomerSelectionChange = this.handleCustomerSelectionChange.bind(this);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInvoiceSelectionChange = this.handleInvoiceSelectionChange.bind(this);

        this.handlePeriodSelectionChange = this.handlePeriodSelectionChange.bind(this);
        this.handleFromDateChanged = this.handleFromDateChanged.bind(this);
        this.handleToDateChanged = this.handleToDateChanged.bind(this);

        this.handleReportTypeSelectionChange = this.handleReportTypeSelectionChange.bind(this);
        this.handleSelectTypeSelectionChange = this.handleSelectTypeSelectionChange.bind(this);
        this.handleOrderBySelectionChange = this.handleOrderBySelectionChange.bind(this);

        this.downloadAccountPaymentTypeSummaryAsPdf = this.downloadAccountPaymentTypeSummaryAsPdf.bind(this);

        this.handleSearchAccountPaySummaryReportSubmit = this.handleSearchAccountPaySummaryReportSubmit.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
    }


    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getCustomer();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {

        this.setState({ isOpenModal: false });
        await this.handleClearSearch();
    }

    async getCustomer() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('account-api/customer-dropdown/', requestParams);
            const data = await response.json();
            this.setState({
                dropdownCustomer: data.customers,
            });

        } catch (e) {
            console.log(e);
        }
    }

    handleCustomerChange = (selectedOption) => {
        try {
            let errors = this.state.errors;
            errors.customerCode = '';
            let customerCode = '';
            if (selectedOption !== null && selectedOption.selectedItem !== null) {
                customerCode = selectedOption.selectedItem.code === null ? '' : selectedOption.selectedItem.code;
            }
            this.setState({ customerCode: customerCode, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }

    handleCustomerSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkCustomerSelection: value, customerCode: '' });
        this.state.errors["customerCode"] = "";
    }

    handleInvoiceSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkInvoiceSelection: value, invoiceNumber: '' });
        this.state.errors["invoiceNumber"] = "";
    }


    handlePeriodSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkPeriodSelection: value });
    }

    handleReportTypeSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkReportTypeSelection: value });
    }

    handleSelectTypeSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkSelectTypeSelection: value });
    }

    handleOrderBySelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkOrderBySelection: value });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === "invoiceNumber") {
            const re = /^[0-9\b]+$/;
            if (event.target.value === '' || re.test(event.target.value)) {
                this.setState({ [name]: event.target.value })
            }
        } else {
            this.setState({
                [name]: value
            });
        }
    }

    handleFromDateChanged(event) {
        let fromDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ startDate: fromDateValue })
    }
    handleToDateChanged(event) {
        let toDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ endDate: toDateValue })
    }


    handleSearchAccountPaySummaryReportSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            this.downloadAccountPaymentTypeSummaryAsPdf();
        }
    }
    handleClearSearch() {
        this.setState({
            isSubmited: false,
            isEmailSubmitted: false,
            loadPanelVisible: false,

            chkCustomerSelection: "1",
            dropdownCustomer: [],
            customerCode: '',

            chkInvoiceSelection: "1",
            invoiceNumber: '',

            chkPeriodSelection: "1",
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),

            chkReportTypeSelection: "1",
            chkSelectTypeSelection: "1",
            chkOrderBySelection: "1",

            errors: {
                customerCode: '',
                invoiceNumber: '',
            }
        });
    }

    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.customerCode = "";
        errors.invoiceNumber = "";

        if (this.state.chkCustomerSelection === "2" && this.state.customerCode == "") {
            formIsValid = false;
            errors.customerCode = "Please select customer.";
        }

        if (this.state.chkInvoiceSelection === "2" && this.state.invoiceNumber == "") {
            formIsValid = false;
            errors.invoiceNumber = "Please enter invoice number.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    async downloadAccountPaymentTypeSummaryAsPdf() {
        try {
            this.setState({
                isSubmited: true,
            });
            var RptHead = "";
            var selection_Criteria = "";

            RptHead = "Order By " + " " + (this.state.chkOrderBySelection == "1" ? " Customer " : (this.state.chkOrderBySelection == "2" ? " Invoice No " : " Invoice date ")) + " for ";

            var request = new Object();
            request.LocationId = Number(sessionStorage.getItem("session_locationId"));
            if (this.state.chkCustomerSelection == "2") {
                selection_Criteria = "{Proc_Select_Customer_Invoice;1.CUSTOMER_CODE} ='" + this.state.customerCode + "'";
                let puZone = this.state.dropdownCustomer.find(opt => opt.code == this.state.customerCode);
                RptHead = RptHead + ", for Customer: " + puZone.fullName;
            } else {
                RptHead = RptHead + ", for all Customers";
            }

            if (this.state.chkInvoiceSelection == "2") {
                selection_Criteria = selection_Criteria + (selection_Criteria.Length > 0 ? " And " : "") + "{Proc_Select_Customer_Invoice;1.Invoice_No} = " + this.state.invoiceNumber.toString();
                RptHead = RptHead + ", for Invoice No: " + this.state.invoiceNumber.toString();
            } else {
                RptHead = RptHead + ", for all Invoices";
            }

            if (this.state.chkPeriodSelection == "2") {
                selection_Criteria = selection_Criteria + (selection_Criteria.Length > 0 ? " And " : "") + "({Proc_Select_Customer_Invoice;1.Invoice_Date} >=  CDateTime('" + formatDate(this.state.startDate, "MM/dd/yyyy") + " 00:00:00'" + ") and  {Proc_Select_Customer_Invoice;1.Invoice_Date} <=  CDateTime('" + formatDate(this.state.endDate, "MM/dd/yyyy") + " 23:59:59'" + "))";
                RptHead = RptHead + "For invoice date range " + formatDate(this.state.startDate, "MM/dd/yyyy") + " to " + formatDate(this.state.endDate, "MM/dd/yyyy");
            } else {
                RptHead = RptHead + ", for all Invoice Date";
            }


            request.Criteria1 = RptHead;
            request.SelectionCrieteria = selection_Criteria;

            request.fileFormat = 'pdf';
            if (this.state.chkReportTypeSelection == "1") {
                if (this.state.chkSelectTypeSelection == "1") {
                    request.MethodName = "GetSalesActivitySummaryByAccountCode";
                } else {
                    request.MethodName = "GetSalesActivitySummaryByAccountType";
                }
            } else {

                if (this.state.chkOrderBySelection == "1") {
                    request.SortBy = "CustomerCode";
                } else if (this.state.chkOrderBySelection == "2") {
                    request.SortBy = "InvoiceNo";
                } else if (this.state.chkOrderBySelection == "3") {
                    request.SortBy = "InvoiceDate";
                }

                request.MethodName = "GetSalesActivityDetails";
            }

            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            this.setState({
                isSubmited: false,
            });

            let a = document.createElement('a');
            a.href = dataResponse.reportBase64String;

            if (this.state.chkReportTypeSelection == "1") {
                if (this.state.chkSelectTypeSelection == "1") {
                    a.download = 'SalesActivitySummaryByAccountCode.pdf';
                } else {
                    a.download = 'SalesActivitySummaryByAccountType.pdf';
                }
            } else {
                a.download = 'SalesActivityDetails.pdf';
            }

            a.click();
        } catch (e) {
            this.setState({
                isSubmited: false,
            });
            console.log(e)
        }
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isEmailSubmitted: true });
                var RptHead = "";
                var selection_Criteria = "";
                RptHead = "Order By " + " " + (this.state.chkOrderBySelection == "1" ? " Customer " : (this.state.chkOrderBySelection == "2" ? " Invoice No " : " Invoice date ")) + " for ";

                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                if (this.state.chkCustomerSelection == "2") {
                    selection_Criteria = "{Proc_Select_Customer_Invoice;1.CUSTOMER_CODE} ='" + this.state.customerCode + "'";
                    let puZone = this.state.dropdownCustomer.find(opt => opt.code == this.state.customerCode);
                    RptHead = RptHead + ", for Customer: " + puZone.fullName;
                } else {
                    RptHead = RptHead + ", for all Customers";
                }

                if (this.state.chkInvoiceSelection == "2") {
                    selection_Criteria = selection_Criteria + (selection_Criteria.Length > 0 ? " And " : "") + "{Proc_Select_Customer_Invoice;1.Invoice_No} = " + this.state.invoiceNumber.toString();
                    RptHead = RptHead + ", for Invoice No: " + this.state.invoiceNumber.toString();
                } else {
                    RptHead = RptHead + ", for all Invoices";
                }

                if (this.state.chkPeriodSelection == "2") {
                    selection_Criteria = selection_Criteria + (selection_Criteria.Length > 0 ? " And " : "") + "({Proc_Select_Customer_Invoice;1.Invoice_Date} >=  CDateTime('" + formatDate(this.state.startDate, "MM/dd/yyyy") + " 00:00:00'" + ") and  {Proc_Select_Customer_Invoice;1.Invoice_Date} <=  CDateTime('" + formatDate(this.state.endDate, "MM/dd/yyyy") + " 23:59:59'" + "))";
                    RptHead = RptHead + "For invoice date range " + formatDate(this.state.startDate, "MM/dd/yyyy") + " to " + formatDate(this.state.endDate, "MM/dd/yyyy");
                } else {
                    RptHead = RptHead + ", for all Invoice Date";
                }


                request.Criteria1 = RptHead;
                request.SelectionCrieteria = selection_Criteria;

                request.fileFormat = 'pdf';
                if (this.state.chkReportTypeSelection == "1") {
                    if (this.state.chkSelectTypeSelection == "1") {
                        request.MethodName = "GetSalesActivitySummaryByAccountCode";
                    } else {
                        request.MethodName = "GetSalesActivitySummaryByAccountType";
                    }
                } else {

                    if (this.state.chkOrderBySelection == "1") {
                        request.SortBy = "CustomerCode";
                    } else if (this.state.chkOrderBySelection == "2") {
                        request.SortBy = "InvoiceNo";
                    } else if (this.state.chkOrderBySelection == "3") {
                        request.SortBy = "InvoiceDate";
                    }

                    request.MethodName = "GetSalesActivityDetails";
                }

                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                let fileName = '';
                if (this.state.chkReportTypeSelection == "1") {
                    if (this.state.chkSelectTypeSelection == "1") {
                        fileName = 'SalesActivitySummaryByAccountCode.pdf';
                    } else {
                        fileName = 'SalesActivitySummaryByAccountType.pdf';
                    }
                } else {
                    fileName = 'SalesActivityDetails.pdf';
                }
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Sales Activity Report';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = fileName;
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '50%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleSearchAccountPaySummaryReportSubmit}
                        style={(this.state.isSubmited || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Sales Activity Report</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdAllCustomer" value="1" checked={this.state.chkCustomerSelection === "1"} onChange={this.handleCustomerSelectionChange} />
                                            <label className="form-check-label">All Customer</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdSingleCustomer" value="2" checked={this.state.chkCustomerSelection === "2"} onChange={this.handleCustomerSelectionChange} />
                                            <label className="form-check-label">Single</label>
                                        </span>
                                        <SelectBox
                                            dataSource={this.state.dropdownCustomer}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select Customer"
                                            name="ddcustomer"
                                            defaultValue={''}
                                            value={this.state.customerCode}
                                            displayExpr='fullName'
                                            valueExpr='code'
                                            onSelectionChanged={this.handleCustomerChange}
                                            showClearButton={true}
                                            disabled={(this.state.chkCustomerSelection === '1')}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerCode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerCode"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdAllInvoice" value="1" checked={this.state.chkInvoiceSelection === "1"} onChange={this.handleInvoiceSelectionChange} />
                                            <label className="form-check-label">All Invoice</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdSingleInvoice" value="2" checked={this.state.chkInvoiceSelection === "2"} onChange={this.handleInvoiceSelectionChange} />
                                            <label className="form-check-label">Single</label>
                                        </span>
                                        <div className="form-group">
                                            <input type="text" className="form-control form-control-sm" name="invoiceNumber" disabled={this.state.chkInvoiceSelection === "1"} maxLength="30" title="Enter invoice number" value={this.state.invoiceNumber} onChange={this.handleInputChange} placeholder="Enter invoice #" />
                                        </div>
                                        {this.state.errors["invoiceNumber"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["invoiceNumber"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label htmlFor="">&nbsp;</label>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdAllPeriod" value="1" checked={this.state.chkPeriodSelection === "1"} onChange={this.handlePeriodSelectionChange} />
                                            <label className="form-check-label">All Period</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdInRangePeriod" value="2" checked={this.state.chkPeriodSelection === "2"} onChange={this.handlePeriodSelectionChange} />
                                            <label className="form-check-label">In Range</label>
                                        </span>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.startDate}
                                            value={this.state.startDate}
                                            onValueChanged={this.handleFromDateChanged}
                                            width="100%"
                                            disabled={this.state.chkPeriodSelection === "1"}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label htmlFor="">&nbsp;</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.endDate}
                                            value={this.state.endDate}
                                            onValueChanged={this.handleToDateChanged}
                                            width="100%"
                                            disabled={this.state.chkPeriodSelection === "1"}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label htmlFor="">Report Type</label><br />
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdSummary" value="1" checked={this.state.chkReportTypeSelection === "1"} onChange={this.handleReportTypeSelectionChange} />
                                            <label className="form-check-label">Summary</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdDetails" value="2" checked={this.state.chkReportTypeSelection === "2"} onChange={this.handleReportTypeSelectionChange} />
                                            <label className="form-check-label">Details</label>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label htmlFor="">Type</label><br />
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdAccountCode" value="1" checked={this.state.chkSelectTypeSelection === "1"} onChange={this.handleSelectTypeSelectionChange} />
                                            <label className="form-check-label">Account Code</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdAccountType" value="2" checked={this.state.chkSelectTypeSelection === "2"} onChange={this.handleSelectTypeSelectionChange} />
                                            <label className="form-check-label">Account Type</label>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                    <div className="form-group">
                                        <label htmlFor="">Order by</label><br />
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdByCustomerCode" value="1" checked={this.state.chkOrderBySelection === "1"} onChange={this.handleOrderBySelectionChange} />
                                            <label className="form-check-label">By Customer Code</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdByInvoiceNo" value="2" checked={this.state.chkOrderBySelection === "2"} onChange={this.handleOrderBySelectionChange} />
                                            <label className="form-check-label">By Invoice No</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdByInvoiceDate" value="3" checked={this.state.chkOrderBySelection === "3"} onChange={this.handleOrderBySelectionChange} />
                                            <label className="form-check-label">By Invoice Date</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="button" className="btn btn-success btn-sm" title="Download" onClick={this.handleSearchAccountPaySummaryReportSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                                </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        )
    }
}