import React, { Component } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import DateBox from 'devextreme-react/date-box';
import { SelectBox } from 'devextreme-react/select-box';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions';
import { LoadPanel } from 'devextreme-react/load-panel';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'
const position = { of: '#historydiv' };

export class GTOnTimePerformanceComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
            isEmailSubmitted: false,
            isSubmited: false,
            loadPanelVisible: false,

            chkLocationSelection: "1",
            dropdownLocation: [],
            locationId: 0,

            dropdownCustomerType: [],
            customerTypeId: 0,

            chkCustomerSelection: "1",
            dropdownCustomer: [],
            customerCode: '',

            chkPeriodSelection: "1",
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),

            airportDelay: 0,
            hotelDelay: 0,
            airportInternational: 0,
            airportDomestic: 0,
            hotelInternational: 0,
            hotelDomestic: 0,

            chkDelayTypeSelection: "1",
            chkReportTypeSelection: "1",
            chkReportViewSelection: "1",

            errors: {
                locationId: '',
                customerCode: '',
                customerTypeId: '',
            }
        }

        this.getLocation = this.getLocation.bind(this);
        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.handleLocationSelectionChange = this.handleLocationSelectionChange.bind(this);

        this.getCustomer = this.getCustomer.bind(this);
        this.handleCustomerChange = this.handleCustomerChange.bind(this);
        this.handleCustomerSelectionChange = this.handleCustomerSelectionChange.bind(this);

        this.getCustomerType = this.getCustomerType.bind(this);
        this.handleCustomerTypeChange = this.handleCustomerTypeChange.bind(this);

        this.handleInputChange = this.handleInputChange.bind(this);

        this.handlePeriodSelectionChange = this.handlePeriodSelectionChange.bind(this);
        this.handleFromDateChanged = this.handleFromDateChanged.bind(this);
        this.handleToDateChanged = this.handleToDateChanged.bind(this);

        this.handleReportTypeSelectionChange = this.handleReportTypeSelectionChange.bind(this);
        this.handleDelayTypeSelectionChange = this.handleDelayTypeSelectionChange.bind(this);
        this.handleReportViewSelectionChange = this.handleReportViewSelectionChange.bind(this);

        this.downloadAccountPaymentTypeSummaryAsPdf = this.downloadAccountPaymentTypeSummaryAsPdf.bind(this);

        this.handleSearchAccountPaySummaryReportSubmit = this.handleSearchAccountPaySummaryReportSubmit.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
    }

    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getLocation();
            await this.getCustomerType();
            await this.getCustomer(0);
            await this.getAirline();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {

        this.setState({ isOpenModal: false });
        await this.handleClearSearch();
    }

    async getLocation() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('account-api/locations', requestParams);
            const data = await response.json();
            this.setState({
                dropdownLocation: data.locations,
            });

        } catch (e) {
            console.log(e);
        }
    }

    handleLocationChange(selectedOption) {
        this.setState({ locationId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.locationId : 0), })
        this.state.errors["locationId"] = "";
    }

    handleLocationSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkLocationSelection: value, locationId: 0 });
        this.state.errors["locationId"] = "";
    }

    async getCustomer(customerTypeId) {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('account-api/customer-dropdown/', requestParams);
            const data = await response.json();

            this.setState({
                dropdownCustomer: customerTypeId == 0 ? data.customers : data.customers.filter(f => f.customerTypeId == customerTypeId),
            });

        } catch (e) {
            console.log(e);
        }
    }

    handleCustomerChange(selectedOption) {
        this.setState({ customerCode: (selectedOption.selectedItem != null ? selectedOption.selectedItem.code : ''), })
        this.state.errors["customerCode"] = "";
    }

    handleCustomerSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkCustomerSelection: value, customerCode: '' });
        this.state.errors["customerCode"] = "";
    }

    async getCustomerType() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('customer-type-api/', requestParams);
            const data = await response.json();
            this.setState({
                dropdownCustomerType: data.customerType,
            });

        } catch (e) {
            console.log(e);
        }
    }

    handleCustomerTypeChange(selectedOption) {
        this.setState({ customerTypeId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
        this.state.errors["customerTypeId"] = "";
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({ [name]: event.target.value })
        }
    }

    handleInvoiceSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkInvoiceSelection: value, invoiceNumber: '' });
        this.state.errors["invoiceNumber"] = "";
    }

    handlePeriodSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkPeriodSelection: value });
    }

    handleReportTypeSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkReportTypeSelection: value });
    }

    handleDelayTypeSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkDelayTypeSelection: value });
    }

    handleReportViewSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkReportViewSelection: value });
    }

    handleFromDateChanged(event) {
        let fromDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ startDate: fromDateValue })
    }
    handleToDateChanged(event) {
        let toDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ endDate: toDateValue })
    }


    handleSearchAccountPaySummaryReportSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            this.downloadAccountPaymentTypeSummaryAsPdf();
        }
    }
    handleClearSearch() {
        this.setState({
            isSubmited: false,
            isEmailSubmitted: false,
            loadPanelVisible: false,

            chkLocationSelection: "1",
            dropdownLocation: [],
            locationId: 0,

            dropdownCustomerType: [],
            customerTypeId: 0,

            chkCustomerSelection: "1",
            dropdownCustomer: [],
            customerCode: '',

            chkPeriodSelection: "1",
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),

            airportDelay: 0,
            hotelDelay: 0,
            airportInternational: 0,
            airportDomestic: 0,
            hotelInternational: 0,
            hotelDomestic: 0,

            chkDelayTypeSelection: "1",
            chkReportTypeSelection: "1",
            chkReportViewSelection: "1",

            errors: {
                locationId: '',
                customerCode: '',
                customerTypeId: '',
            }
        });
    }

    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.customerTypeId = "";

        if (this.state.customerTypeId == 0) {
            formIsValid = false;
            errors.customerTypeId = "Please select customer type.";
        }

        if (this.state.chkCustomerSelection === "2" && this.state.customerCode == "") {
            formIsValid = false;
            errors.customerCode = "Please select customer.";
        }

        if (this.state.chkLocationSelection === "2" && this.state.locationId == 0) {
            formIsValid = false;
            errors.customerTypeId = "Please select location.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    async downloadAccountPaymentTypeSummaryAsPdf() {
        try {
            this.setState({
                isSubmited: true,
            });
            var RptHead = "";
            var sCustomer = "";
            var sLocation = "";
            var sDate = "";
            var sAirport = "";
            var sHotel = "";
            var sAirportInt = "";
            var sAirportDom = "";
            var sHotelInt = "";
            var sHotelDom = "";
            var fileName = "";

            var request = new Object();
            if (this.state.chkLocationSelection === "1") {
                sLocation = " For all Location, ";
                request.LocationId = 0;
            } else {
                let customerType = this.state.dropdownLocation.find(opt => opt.locationId == this.state.locationId);
                request.LocationId = parseInt(this.state.locationId);
                sLocation = "  " + "For Location" + "  " + customerType.locationName + ",";
            }
            request.CustomerTypeId = parseInt(this.state.customerTypeId);
            if (this.state.chkCustomerSelection === "1") {
                sCustomer = " For all Customer, ";
                request.CustomerId = 0;
            } else {
                let customer = this.state.dropdownCustomer.find(opt => opt.code == this.state.customerCode);
                request.CustomerId = parseInt(customer.id);
                sCustomer = "  " + "For Customer " + "  " + customer.fullName + ",";
            }

            if (this.state.chkPeriodSelection == "2") {
                request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy") + " 00:00:01";
                request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy") + " 23:59:59";
                sDate = " From Date " + "  " + formatDate(this.state.startDate, "MM/dd/yyyy") + "  " + "  To Date " + formatDate(this.state.endDate, "MM/dd/yyyy");
            } else {
                request.StartDate = null;
                request.EndDate = null;
                sDate = "For All Date";
            }

            if (parseInt(this.state.airportDelay) > 0) {
                request.AirportDelay = parseFloat(this.state.airportDelay);
                sAirport = "  " + "Airport Delay" + "  " + (this.state.airportDelay).toString() + ",";
            }

            if (parseInt(this.state.hotelDelay) > 0) {
                request.HotelDelay = parseFloat(this.state.hotelDelay);
                sHotel = "  " + "Hotel Delay" + "  " + (this.state.hotelDelay).toString() + ",";
            }

            if (parseInt(this.state.airportInternational) > 0) {
                request.AirportInternationDelay = parseFloat(this.state.airportInternational);
                sAirportInt = "  " + "Airport Int. Delay" + "  " + (this.state.airportInternational).toString() + ",";
            }

            if (parseInt(this.state.airportDomestic) > 0) {
                request.AirportDomesticDelay = parseFloat(this.state.airportDomestic);
                sAirportDom = "  " + "Airport Dom. Delay" + "  " + (this.state.airportDomestic).toString() + ",";
            }

            if (parseInt(this.state.hotelInternational) > 0) {
                request.HotelInternationDelay = parseFloat(this.state.hotelInternational);
                sHotelInt = "  " + "Hotel Int. Delay" + "  " + (this.state.hotelInternational).toString() + ",";
            }

            if (parseInt(this.state.hotelDomestic) > 0) {
                request.HotelDomesticDelay = parseFloat(this.state.hotelDomestic);
                sHotelDom = "  " + "Hotel Dom. Delay" + "  " + (this.state.hotelDomestic).toString() + ",";
            }
            request.OrderByType = parseInt(this.state.chkDelayTypeSelection);
            request.ViewType = parseInt(this.state.chkReportViewSelection);

            if (this.state.chkReportTypeSelection === "1") {
                if (this.state.chkReportViewSelection === "1") {
                    fileName = "GTPerformanceSummaryLocationWise";
                }
                else if (this.state.chkReportViewSelection === "2") {
                    fileName = "GTPerformanceSummaryCustomerWise";
                }
                else if (this.state.chkReportViewSelection === "3") {
                    fileName = "GTPerformanceSummaryMonthWise";
                }
                else {
                    fileName = "GTPerformanceSummaryAccountMonthWise";
                }
            } else {
                fileName = "GTPerformance";
            }

            RptHead = RptHead + sCustomer;
            RptHead = RptHead + sLocation;
            RptHead = RptHead + sDate;
            RptHead = RptHead + sAirport;
            RptHead = RptHead + sHotel;
            RptHead = RptHead + sAirportInt;
            RptHead = RptHead + sAirportDom;
            RptHead = RptHead + sHotelInt;
            RptHead = RptHead + sHotelDom;

            request.Header = RptHead;
            request.MethodName = "GetGTOnTimePerformance";

            request.ReportType = parseInt(this.state.chkReportTypeSelection);


            request.fileFormat = 'pdf';
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            this.setState({
                isSubmited: false,
            });

            let a = document.createElement('a');
            a.href = dataResponse.reportBase64String;
            a.download = fileName + '.pdf';

            a.click();
        } catch (e) {
            this.setState({
                isSubmited: false,
            });
            console.log(e)
        }
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isEmailSubmitted: true });
                var RptHead = "";
                var sCustomer = "";
                var sLocation = "";
                var sDate = "";
                var sAirport = "";
                var sHotel = "";
                var sAirportInt = "";
                var sAirportDom = "";
                var sHotelInt = "";
                var sHotelDom = "";
                var fileName = "";

                var request = new Object();
                if (this.state.chkLocationSelection === "1") {
                    sLocation = " For all Location, ";
                    request.LocationId = 0;
                } else {
                    let customerType = this.state.dropdownLocation.find(opt => opt.locationId == this.state.locationId);
                    request.LocationId = parseInt(this.state.locationId);
                    sLocation = "  " + "For Location" + "  " + customerType.locationName + ",";
                }
                request.CustomerTypeId = parseInt(this.state.customerTypeId);
                if (this.state.chkCustomerSelection === "1") {
                    sCustomer = " For all Customer, ";
                    request.CustomerId = 0;
                } else {
                    let customer = this.state.dropdownCustomer.find(opt => opt.code == this.state.customerCode);
                    request.CustomerId = parseInt(customer.id);
                    sCustomer = "  " + "For Customer " + "  " + customer.fullName + ",";
                }

                if (this.state.chkPeriodSelection == "2") {
                    request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy") + " 00:00:01";
                    request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy") + " 23:59:59";
                    sDate = " From Date " + "  " + formatDate(this.state.startDate, "MM/dd/yyyy") + "  " + "  To Date " + formatDate(this.state.endDate, "MM/dd/yyyy");
                } else {
                    request.StartDate = null;
                    request.EndDate = null;
                    sDate = "For All Date";
                }

                if (parseInt(this.state.airportDelay) > 0) {
                    request.AirportDelay = parseFloat(this.state.airportDelay);
                    sAirport = "  " + "Airport Delay" + "  " + (this.state.airportDelay).toString() + ",";
                }

                if (parseInt(this.state.hotelDelay) > 0) {
                    request.HotelDelay = parseFloat(this.state.hotelDelay);
                    sHotel = "  " + "Hotel Delay" + "  " + (this.state.hotelDelay).toString() + ",";
                }

                if (parseInt(this.state.airportInternational) > 0) {
                    request.AirportInternationDelay = parseFloat(this.state.airportInternational);
                    sAirportInt = "  " + "Airport Int. Delay" + "  " + (this.state.airportInternational).toString() + ",";
                }

                if (parseInt(this.state.airportDomestic) > 0) {
                    request.AirportDomesticDelay = parseFloat(this.state.airportDomestic);
                    sAirportDom = "  " + "Airport Dom. Delay" + "  " + (this.state.airportDomestic).toString() + ",";
                }

                if (parseInt(this.state.hotelInternational) > 0) {
                    request.HotelInternationDelay = parseFloat(this.state.hotelInternational);
                    sHotelInt = "  " + "Hotel Int. Delay" + "  " + (this.state.hotelInternational).toString() + ",";
                }

                if (parseInt(this.state.hotelDomestic) > 0) {
                    request.HotelDomesticDelay = parseFloat(this.state.hotelDomestic);
                    sHotelDom = "  " + "Hotel Dom. Delay" + "  " + (this.state.hotelDomestic).toString() + ",";
                }
                request.OrderByType = parseInt(this.state.chkDelayTypeSelection);
                request.ViewType = parseInt(this.state.chkReportViewSelection);

                if (this.state.chkReportTypeSelection === "1") {
                    if (this.state.chkReportViewSelection === "1") {
                        fileName = "GTPerformanceSummaryLocationWise";
                    }
                    else if (this.state.chkReportViewSelection === "2") {
                        fileName = "GTPerformanceSummaryCustomerWise";
                    }
                    else if (this.state.chkReportViewSelection === "3") {
                        fileName = "GTPerformanceSummaryMonthWise";
                    }
                    else {
                        fileName = "GTPerformanceSummaryAccountMonthWise";
                    }
                } else {
                    fileName = "GTPerformance";
                }

                RptHead = RptHead + sCustomer;
                RptHead = RptHead + sLocation;
                RptHead = RptHead + sDate;
                RptHead = RptHead + sAirport;
                RptHead = RptHead + sHotel;
                RptHead = RptHead + sAirportInt;
                RptHead = RptHead + sAirportDom;
                RptHead = RptHead + sHotelInt;
                RptHead = RptHead + sHotelDom;

                request.Header = RptHead;
                request.MethodName = "GetGTOnTimePerformance";

                request.ReportType = parseInt(this.state.chkReportTypeSelection);


                request.fileFormat = 'pdf';
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'GT On Time Performance';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = fileName + '.pdf';
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '70%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleSearchAccountPaySummaryReportSubmit}
                        style={(this.state.isSubmited || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">GT On Time Performance</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">

                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="allLocation" value="1" checked={this.state.chkLocationSelection === "1"} onChange={this.handleLocationSelectionChange} />
                                            <label className="form-check-label">All Location</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="singleLocation" value="2" checked={this.state.chkLocationSelection === "2"} onChange={this.handleLocationSelectionChange} />
                                            <label className="form-check-label">Single Location</label>
                                        </span>
                                        <SelectBox
                                            dataSource={this.state.dropdownLocation.length > 0 ? this.state.dropdownLocation : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select location"
                                            name="locationId"
                                            defaultValue={0}
                                            value={this.state.locationId}
                                            displayExpr='locationName'
                                            valueExpr='locationId'
                                            onSelectionChanged={this.handleLocationChange}
                                            showClearButton={true}
                                            disabled={this.state.chkLocationSelection === "1"}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["locationId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["locationId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>Customer Type</label>
                                        <SelectBox
                                            dataSource={this.state.dropdownCustomerType.length > 0 ? this.state.dropdownCustomerType : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer type"
                                            name="customerTypeId"
                                            defaultValue={0}
                                            value={this.state.customerTypeId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleCustomerTypeChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerTypeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerTypeId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdAllCustomer" value="1" checked={this.state.chkCustomerSelection === "1"} onChange={this.handleCustomerSelectionChange} />
                                            <label className="form-check-label">All Customer</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdSingleCustomer" value="2" checked={this.state.chkCustomerSelection === "2"} onChange={this.handleCustomerSelectionChange} />
                                            <label className="form-check-label">Single Customer</label>
                                        </span>
                                        <SelectBox
                                            dataSource={this.state.dropdownCustomer.length > 0 ? this.state.dropdownCustomer : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="customerCode"
                                            defaultValue={0}
                                            value={this.state.customerCode}
                                            displayExpr='fullName'
                                            valueExpr='code'
                                            onSelectionChanged={this.handleCustomerChange}
                                            showClearButton={true}
                                            disabled={this.state.chkCustomerSelection === "1"}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerCode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerCode"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label htmlFor="">Report Type</label><br />
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdSummary" value="1" checked={this.state.chkReportTypeSelection === "1"} onChange={this.handleReportTypeSelectionChange} />
                                            <label className="form-check-label">Summary</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdDetails" value="2" checked={this.state.chkReportTypeSelection === "2"} onChange={this.handleReportTypeSelectionChange} />
                                            <label className="form-check-label">Details</label>
                                        </span>
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label htmlFor="">&nbsp;</label>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdAllPeriod" value="1" checked={this.state.chkPeriodSelection === "1"} onChange={this.handlePeriodSelectionChange} />
                                            <label className="form-check-label">All Period</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdInRangePeriod" value="2" checked={this.state.chkPeriodSelection === "2"} onChange={this.handlePeriodSelectionChange} />
                                            <label className="form-check-label">In Range</label>
                                        </span>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.startDate}
                                            value={this.state.startDate}
                                            onValueChanged={this.handleFromDateChanged}
                                            width="100%"
                                            disabled={this.state.chkPeriodSelection === "1"}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label><br /></label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.endDate}
                                            value={this.state.endDate}
                                            onValueChanged={this.handleToDateChanged}
                                            width="100%"
                                            disabled={this.state.chkPeriodSelection === "1"}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{ display: (this.state.chkReportTypeSelection === "2") ? 'none' : 'flex' }}>
                                    <div className="form-group">
                                        <label htmlFor="">Report View</label><br />
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdLocationWise" value="1" checked={this.state.chkReportViewSelection === "1"} onChange={this.handleReportViewSelectionChange} />
                                            <label className="form-check-label">Location wise</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdMonthWise" value="2" checked={this.state.chkReportViewSelection === "2"} onChange={this.handleReportViewSelectionChange} />
                                            <label className="form-check-label">Month wise</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdCustomerWise" value="3" checked={this.state.chkReportViewSelection === "3"} onChange={this.handleReportViewSelectionChange} />
                                            <label className="form-check-label">Customer wise</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdCustomerMonthWise" value="4" checked={this.state.chkReportViewSelection === "4"} onChange={this.handleReportViewSelectionChange} />
                                            <label className="form-check-label">Customer Month wise</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>Airport Delay</label>
                                        <div className="form-group">
                                            <input type="text" className="form-control form-control-sm" name="airportDelay" maxLength="30" title="Enter airport delay" value={this.state.airportDelay} onChange={this.handleInputChange} placeholder="Enter Airport Delay" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>Hotel Delay</label>
                                        <div className="form-group">
                                            <input type="text" className="form-control form-control-sm" name="hotelDelay" maxLength="30" title="Enter hotel delay" value={this.state.hotelDelay} onChange={this.handleInputChange} placeholder="Enter Hotel Delay" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label htmlFor="">Delay Type</label><br />
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdBasedOnLocationTime" value="1" checked={this.state.chkDelayTypeSelection === "1"} onChange={this.handleDelayTypeSelectionChange} />
                                            <label className="form-check-label">Based on Location Time</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdBasedOnLoadTime" value="2" checked={this.state.chkDelayTypeSelection === "2"} onChange={this.handleDelayTypeSelectionChange} />
                                            <label className="form-check-label">Based on Load Time</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ display: (this.state.chkReportTypeSelection === "2") ? 'none' : 'flex' }}>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>Airport International</label>
                                        <div className="form-group">
                                            <input type="text" className="form-control form-control-sm" name="airportInternational" maxLength="30" title="Enter Airport International" value={this.state.airportInternational} onChange={this.handleInputChange} placeholder="Enter Airport International" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>Airport Domestic</label>
                                        <div className="form-group">
                                            <input type="text" className="form-control form-control-sm" name="airportDomestic" maxLength="30" title="Enter Airport Domestic" value={this.state.airportDomestic} onChange={this.handleInputChange} placeholder="Enter Airport Domestic" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>Hotel International</label>
                                        <div className="form-group">
                                            <input type="text" className="form-control form-control-sm" name="hotelInternational" maxLength="30" title="Enter Hotel International" value={this.state.hotelInternational} onChange={this.handleInputChange} placeholder="Enter Hotel International" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>Hotel Domestic</label>
                                        <div className="form-group">
                                            <input type="text" className="form-control form-control-sm" name="hotelDomestic" maxLength="30" title="Enter Hotel Domestic" value={this.state.hotelDomestic} onChange={this.handleInputChange} placeholder="Enter Hotel Domestic" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="button" className="btn btn-success btn-sm" title="Download" onClick={this.handleSearchAccountPaySummaryReportSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Search"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                                </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        )
    }
}