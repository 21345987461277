import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
    Selection
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Modal from 'react-modal'; //npm install --save react-modal
import DateBox from 'devextreme-react/date-box';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { SelectBox } from 'devextreme-react/select-box';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions';
import { StatusType, DateFormat } from '../../helpers/fixcodes';
import { LoadPanel } from 'devextreme-react/load-panel';
import { ReservationHistoryComponent } from '../Shared/ReservationHistoryComponent';
import { DocumentsComponent } from '../Shared/DocumentsComponent';
const position = { of: '#historydiv' };
const selectionData = [
    {
        "id": 1,
        "name": "Ride ID",
    },
    {
        "id": 2,
        "name": "Pax Name",
    },
    {
        "id": 3,
        "name": "Customer",
    },
    {
        "id": 4,
        "name": "Vehicle Number",
    },
]

let currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
let currencyIcon = sessionStorage.getItem("session_currencyIcon") === "" ? "fas fa-dollar-sign" : sessionStorage.getItem("session_currencyIcon");
const session_accountId = parseInt(sessionStorage.getItem("session_accountId"));
const session_mappedAccountId1 = parseInt(sessionStorage.getItem("session_mappedAccountId1"));
const session_mappedAccountId2 = parseInt(sessionStorage.getItem("session_mappedAccountId2"));

export class RidesToBeInvoicedComponent extends React.Component {
    constructor(props) {
        super(props);
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        this.state = {
            rowId: 0,
            addEditZonalRateDataModalIsOpen: false,
            isSubmited: false,
            selectionId: 0,
            selectionName: '',
            selectionValue: '',
            fromDateTime: new Date(currentTime.toLocaleDateString() + " 00:00"),
            toDateTime: new Date(currentTime.toLocaleDateString() + " 23:59"),
            customerList: [],
            customerId: 0,
            customerTypeList: [],
            customerTypeId: 0,
            unapproveRides: [],
            selectedRowKeys: [],

            loadPanelVisible: false,
            errors: {
                selectionValue: '',
                customerId: '',
            }
        }
        this.getcustomerList = this.getcustomerList.bind(this);
        this.getCustomerTypeList = this.getCustomerTypeList.bind(this);

        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.handleFromValueChanged = this.handleFromValueChanged.bind(this);
        this.handleToValueChanged = this.handleToValueChanged.bind(this);

        this.getUnapproveRides = this.getUnapproveRides.bind(this);

        this.handleSearchUnapproveRidesSubmit = this.handleSearchUnapproveRidesSubmit.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
        this.handleUnApproveRide = this.handleUnApproveRide.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);

        this.openEditReservationModel = this.openEditReservationModel.bind(this);
        this.openReservationHistoryModel = this.openReservationHistoryModel.bind(this);
        this.openEditFareModel = this.openEditFareModel.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.openReservationDocumentsModel = this.openReservationDocumentsModel.bind(this);
    }
    async componentDidMount() {
        this.setState({
            loadPanelVisible: true,
        });
        await this.getcustomerList();
        await this.getCustomerTypeList();

        if (this.props.location.state != undefined) {
            this.setState({
                customerId: this.props.location.state.customerId,
                fromDateTime: this.props.location.state.fromDateTime,
                toDateTime: this.props.location.state.toDateTime,
                customerTypeId: this.props.location.state.customerTypeId,
                selectionId: this.props.location.state.selectionId,
                selectionName: this.props.location.state.selectionName,
                selectionValue: this.props.location.state.selectionValue,

            });

            let myVar = setTimeout(() => this.getUnapproveRides(), 200)
        }
        this.setState({
            loadPanelVisible: false,
        });
    }
    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getUnapproveRides.bind(this)
            }
        });
    }
    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.selectionValue = "";
        errors.customerId = "";
        if (this.state.selectionId > 0) {
            if (this.state.selectionId == 1) {
                if (this.state.selectionValue == 0 || this.state.selectionValue === "") {
                    formIsValid = false;
                    errors.selectionValue = "Please enter ride id.";
                }
            }
            if (this.state.selectionId == 2) {
                if (this.state.selectionValue === "") {
                    formIsValid = false;
                    errors.selectionValue = "Please enter vehicle number.";
                }
            }
            if (this.state.selectionId == 3) {
                if (parseInt(this.state.customerId) == 0) {
                    formIsValid = false;
                    errors.customerId = "Please select customer.";
                }
            }
            if (this.state.selectionId == 4) {
                if (this.state.selectionValue === "") {
                    formIsValid = false;
                    errors.selectionValue = "Please enter pax name.";
                }
            }
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    async getUnapproveRides() {
        if (!this.handleValidation()) {
            return;
        }

        var request = new Object();

        request.CustomerTypeId = parseInt(this.state.customerTypeId);
        request.IsRideApproved = parseInt(1);
        request.PuFromDate = this.state.fromDateTime == null ? "" : this.state.fromDateTime.toLocaleDateString() + " 00:00:00"; //new Date(2020, 9, 1);
        request.PuToDate = this.state.toDateTime == null ? "" : this.state.toDateTime.toLocaleDateString() + " 23:59:59"; //new Date(2020, 9, 31);

        request.RideId = this.state.selectionId == 1 ? parseInt(this.state.selectionValue) : 0;
        request.CustomerId = this.state.selectionId == 3 ? parseInt(this.state.customerId) : 0;
        request.VehicleNo = this.state.selectionId == 2 ? this.state.selectionValue : "";
        request.PaxName = this.state.selectionId == 4 ? this.state.selectionValue : "";

        try {
            this.setState({
                isSubmited: true,
            });
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('manage-rides-api/list', requestParams);
            const data = await response.json();
            this.setState({
                unapproveRides: data.manageRides
            });
            this.setState({ isSubmited: false });

        } catch (e) {
            this.setState({ isSubmited: false });
        }

    }
    async getcustomerList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('account-api/customer-dropdown/', requestParams);
        const data = await response.json();
        this.setState({
            customerList: data.customers,
        });
    }

    async getCustomerTypeList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('customer-type-api/', requestParams);
        const data = await response.json();
        if (data !== null && data.customerType.length > 0) {
            this.setState({
                customerTypeList: data.customerType
            });
        }
    }

    handleCustomerInputChange = (data) => {
        this.setState({ customerId: (data.selectedItem != null ? data.selectedItem.id : 0), })
        this.state.errors["customerId"] = "";
    }

    handleFromValueChanged(date) {
        this.setState({ fromDateTime: date.value });
    }
    handleToValueChanged(date) {
        this.setState({ toDateTime: date.value });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === "selectionValue") {
            if (this.state.selectionId == 1) {
                const re = /^[0-9\b]+$/;
                if (event.target.value === '' || re.test(event.target.value)) {
                    this.setState({ [name]: event.target.value })
                }
            } else {
                this.setState({
                    [name]: value
                });
            }
        } else {
            this.setState({
                [name]: value
            });
        }

        if (name === "selectionId" && value > 0) {
            this.setState({ selectionName: selectionData.find(f => f.id == value).name })
        }
        this.state.errors["selectionValue"] = "";
    }

    async handleSearchUnapproveRidesSubmit(e) {
        e.preventDefault();

        await this.getUnapproveRides();
    }
    handleClearSearch() {
        this.setState({
            isSubmited: false,
            selectionId: 0,
            selectionName: '',
            selectionValue: '',
            fromDateTime: new Date(currentTime.toLocaleDateString() + " 00:00"),
            toDateTime: new Date(currentTime.toLocaleDateString() + " 23:59"),
            customerId: 0,
            customerTypeId: 0,
            unapproveRides: [],
            errors: {
                selectionValue: '',
                customerId: '',
            }
        });
    }

    onSelectionChanged({ selectedRowKeys }) {
        this.setState({
            selectedRowKeys: selectedRowKeys
        });
    }

    async askApproveUnapproveConfirm() {
        let isResult = false;
        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: "You are going unapprove selected rides!<br> Do you want to still continue?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    async handleUnApproveRide(e) {
        e.preventDefault();
        const selectedRowsData = await this.dataGrid.instance.getDataByKeys(this.state.selectedRowKeys);

        if (selectedRowsData.length <= 0) {
            const isValid = await Swal.fire({
                title: "<small>Pending Unapproval</small>",
                text: "Please select atleast one ride.",
                icon: "info",
                dangerMode: false,
                width: "25%",
                dangerMode: false,
                allowOutsideClick: false
            });
            return;
        }

        const isConfirm = await this.askApproveUnapproveConfirm();
        if (!isConfirm) {
            return;
        }

        this.setState({ isSubmited: true });

        var selectedObj = "";
        for (let i = 0; i < selectedRowsData.length; i++) {
            selectedObj = selectedObj + selectedRowsData[i].rideId + ",";
        }

        try {
            var request = new Object();
            request.RideIdStr = selectedObj.replace(/,\s*$/, "");
            request.IsRideApproved = false;

            const requestParams = getRequestParams('POST', request);
            const response = await fetch('manage-rides-api/approve-unapprove', requestParams);
            const data = await response.json();
            Swal.fire({
                icon: data.isSuccess ? 'success' : 'error',
                title: data.isSuccess ? 'Success' : 'Oops...',
                text: data.resultMessage,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                this.setState({ isSubmited: false })
                if (result.isConfirmed) {
                    if (data.isSuccess) {
                        this.getUnapproveRides();
                    }
                }
            })

        } catch (e) {
            this.setState({ isSubmited: false });
        }
    }

    openEditReservationModel(e, data) {
        e.preventDefault();
        this.props.history.push({
            pathname: '/crudride',
            //state: { detail: data }
            state: { rideId: data.rideId, sourceUrl: "/billing/approved", sourceState: this.state }
        })
    }
    openReservationHistoryModel(e, data) {
        e.preventDefault();
        this.refs.reservationHistoryModal.showModal("Reservation", data.rideId);
    }
    openReservationDocumentsModel(e, data) {
        e.preventDefault();
        var rideArray = [];
        rideArray.push(data.rideId);
        this.refs.documentsComponent.showModal('Ride Id', rideArray, rideArray[0]);
    }
    openEditFareModel(e, data) {
        e.preventDefault();
        var hasAttachment = data.hasAttachment === "Yes" ? true : false;
        this.props.history.push({
            pathname: '/billing/crudfare',
            state: { rideId: data.rideId, paxName: data.fullName, customerId: data.customerId, isFranchisee: false, hasAttachment: hasAttachment, puLocationName: data.pickupLocation, doLocationName: data.dropoffLocation, isRideInvoice: false, approvalStatus: true, sourceUrl: "/billing/approved", sourceState: this.state }
        })
    }

    render() {

        const filterSearchFromDate = time => {
            const currentDate = currentTime;
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }

        const filterSearchToDate = time => {
            const currentDate = currentTime;
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }

        const isDisableAllButton = this.state.unapproveRides.length > 0 ? false : true;
        const renderGridCell = (data) => {

            return <div>
                <a href="#" className="fas fa-pen icon text-info" title="View" onClick={(e) => { this.openEditReservationModel(e, data.data) }}></a>&nbsp;&nbsp;
                <a href="#" className="fas fa-history icon text-info" title="History" onClick={(e) => { this.openReservationHistoryModel(e, data.data) }}></a>&nbsp;&nbsp;
                <a href="#" className="fas fa-paperclip icon text-info" title="View/Upload Documents" onClick={(e) => { this.openReservationDocumentsModel(e, data.data) }}></a>&nbsp;&nbsp;
                <a href="#" className={currencyIcon} title="Edit Fare" onClick={(e) => { this.openEditFareModel(e, data.data) }}></a>&nbsp;&nbsp;
            </div>;
        }

        const renderGridCurrencyCell = (data) => {
            return <div><i className={currencyIcon}></i>&nbsp;{parseFloat(data.value != undefined ? data.value : 0.00).toFixed(2)}</div>;
        }
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-sm-6 col-md-6 col-lg-6">
                                <h1 className="m-0 text-dark">Approved Rides</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                {/*<ol className="breadcrumb float-sm-right">*/}
                                {/*    <NavItem>*/}
                                {/*        <NavLink tag={Link} to="/billing/unapprove" className="btn btn-block btn-outline-secondary btn-sm" title="Go to Unapprove Rides" ><i className="fas fa-times"></i> Go to Unapprove Rides</NavLink>*/}
                                {/*    </NavItem>*/}
                                {/*</ol>*/}
                            </div>
                        </div>
                    </div>
                </div>
                <form method="post" onSubmit={this.handleSearchUnapproveRidesSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="rez-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>Selection</label>
                                        <select name="selectionId" className="form-control form-control-sm" value={this.state.selectionId} onChange={this.handleInputChange}>
                                            <option value="0">Select filter</option>
                                            {
                                                selectionData &&
                                                selectionData.map((item, i) =>
                                                    (<option key={i} value={item.id}>{item.name}</option>))
                                            }
                                        </select>
                                    </div>
                                </div>
                                {(this.state.selectionId == 3) && <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>Select {this.state.selectionName}</label>
                                        <SelectBox
                                            dataSource={this.state.customerList.length > 0 ? this.state.customerList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="customerId"
                                            defaultValue={0}
                                            value={this.state.customerId}
                                            displayExpr='fullName'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleCustomerInputChange}
                                            showClearButton={false}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerId"]}</span>}
                                    </div>
                                </div>}
                                {(this.state.selectionId == 1 || this.state.selectionId == 2 || this.state.selectionId == 4) && <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                                    <div className="form-group">
                                        <label>{this.state.selectionName}</label>
                                        <input type="text" className="form-control form-control-sm" name="selectionValue" maxLength="30" title="Enter filter value" value={this.state.selectionValue} onChange={this.handleInputChange} placeholder="" />
                                        {this.state.errors["selectionValue"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["selectionValue"]}</span>}
                                    </div>
                                </div>}
                                <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                                    <div className="form-group">
                                        <label>From Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDateTime"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.fromDateTime}
                                            value={this.state.fromDateTime}
                                            onValueChanged={this.handleFromValueChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                                    <div className="form-group">
                                        <label>To Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDateTime"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.toDateTime}
                                            value={this.state.toDateTime}
                                            onValueChanged={this.handleToValueChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />

                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>Customer Type</label>
                                        <select className="form-control form-control-sm" name="customerTypeId" placeholder="Customer Type" title="Customer Type" value={this.state.customerTypeId} onChange={this.handleInputChange}>
                                            <option value={0}>Select customer type</option>
                                            {this.state.customerTypeList && this.state.customerTypeList.map((item, i) =>
                                                <option key={i} value={item.id}>{item.name}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className={this.state.selectionId == 0 ? "col-lg-5 col-md-5 col-sm-5 col-xs-5" : "col-lg-5 col-md-5 col-sm-5 col-xs-5"} >
                                    <div className="form-group">
                                        <br />
                                        <button type="submit" className="btn btn-success btn-sm" onClick={this.handleSearchUnapproveRidesSubmit} >
                                            {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSubmited && <span title="Search"><i className="fas fa-search"></i> Search</span>}
                                        </button>&nbsp;&nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" disabled={isDisableAllButton} onClick={this.handleClearSearch} title="Clear"><i className="fas fa-eraser"></i> Clear</button>&nbsp;&nbsp;
                                        {/*<button type="button" className="btn btn-custom-gray btn-sm" disabled={isDisableAllButton} onClick={this.handleUnApproveRide} title="Unapprove"><i className="fas fa-times"></i> Unapprove</button>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="content">
                    <div className="card">
                        <div className="card-body">
                            {
                                (session_mappedAccountId1 !== session_accountId && session_mappedAccountId2 !== session_accountId) ?
                                    <DataGrid
                                        dataSource={this.state.unapproveRides}
                                        keyExpr="rideId"

                                        columnsAutoWidth={false}
                                        showColumnLines={false}
                                        showRowLines={true}
                                        showBorders={true}
                                        rowAlternationEnabled={true}
                                        showBorders={true}
                                        allowColumnResizing={true}
                                        columnResizingMode='widget'
                                        width='100%'
                                        onToolbarPreparing={this.onToolbarPreparing}
                                        selectedRowKeys={this.state.selectedRowKeys}
                                        onSelectionChanged={this.onSelectionChanged}
                                        ref={ref => this.dataGrid = ref}>
                                        <Selection
                                            mode="multiple"
                                            selectAllMode={"allPages"}
                                            showCheckBoxesMode={"always"}
                                        />
                                        <Column dataField="rideId" width={70} caption="" fixed={true} allowFiltering={true} allowSorting={false} cellRender={renderGridCell} />
                                        <Column dataField="displayRideId" caption="Ride Id" fixed={true} width={100} allowFiltering={false} allowSorting={true} />
                                        <Column dataField="fullName" caption="Pax Name" width={130} allowFiltering={false} allowSorting={true} />
                                        <Column dataField="customerName" caption="Customer" width={120} allowFiltering={false} allowSorting={true} />
                                        <Column dataField="pickupLocation" caption="PU Street" width={150} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="pickupZone" caption="PU Zone" width={100} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="dropoffLocation" caption="DO Street" width={150} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="dropoffZone" caption="DO Zone" width={100} allowFiltering={false} allowSorting={true} />
                                        <Column dataField="pickupDate" caption="PU Date Time" width={130} allowFiltering={false} allowSorting={true} format={DateFormat.DateTime24Format} dataType="datetime" />
                                        <Column dataField="apt" caption="Apt" width={80} allowFiltering={false} allowSorting={true} />
                                        <Column dataField="adultNo" caption="Pax#" width={70} allowFiltering={false} allowSorting={true} />
                                        <Column dataField="vehicleType" caption="Vehicle Type" width={120} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="dispatureName" caption="Dispatcher" width={100} allowFiltering={false} allowSorting={true} />
                                        <Column dataField="vehicleNo" caption="Vehicle Number" width={90} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="totalKilometers" caption="Total Kilometers" width={100} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="clubbedStatus" caption="Clubbing Status" width={150} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="billingStatus" caption="Billing Status" width={150} allowFiltering={true} allowSorting={true} allowEditing={false} />

                                        <Column dataField="paymentType" caption="Payment Type" width={100} allowFiltering={false} allowSorting={true} />
                                        <Column dataField="hasAttachment" caption="Has Attachment" width={80} allowFiltering={false} allowSorting={true} />

                                        <Column dataField="baseFare" caption="Ride Fare" width={80} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="toComm" caption="Comms($)" width={80} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="tollAmount" caption="Toll Amt" width={80} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="tipAmount" caption="Tip Amt" width={80} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="fuelSurCharge" caption="Fuel Sur Chrg" width={80} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="stopCharge" caption="Stop" width={80} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="waitTotal" caption="Wait Chrg" width={80} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />

                                        <Column dataField="portAuthority" caption="Port Auth" width={80} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="utilityTax" caption="Utility" width={80} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="wcTax" caption="WC Tax" width={80} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="salesTax" caption="Sales Tax" width={80} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="countyTax" caption="County Tax" width={80} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="congestionTax" caption="Congestion Tax" width={80} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="totalFare" caption="Net" width={80} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="dRideAmount" caption="D Ride Amnt" width={80} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="dNetAmount" caption="D Net Comms" width={80} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="tripStatus" caption="Ride Status" width={150} allowFiltering={false} allowSorting={true} />

                                        <SortByGroupSummaryInfo summaryItem="count" />
                                        <GroupPanel visible={true} />
                                        <HeaderFilter visible={true} />
                                        <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                        <Grouping autoExpandAll={true} />
                                        <Paging defaultPageSize={20} />
                                        <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true} />
                                        <Export enabled={true} allowExportSelectedData={false} />
                                        <ColumnChooser enabled={true} />
                                        <ColumnFixing enabled={true} />
                                    </DataGrid>
                                    :
                                    <DataGrid
                                        dataSource={this.state.unapproveRides}
                                        keyExpr="rideId"

                                        columnsAutoWidth={false}
                                        showColumnLines={false}
                                        showRowLines={true}
                                        showBorders={true}
                                        rowAlternationEnabled={true}
                                        showBorders={true}
                                        allowColumnResizing={true}
                                        columnResizingMode='widget'
                                        width='100%'
                                        onToolbarPreparing={this.onToolbarPreparing}
                                        selectedRowKeys={this.state.selectedRowKeys}
                                        onSelectionChanged={this.onSelectionChanged}
                                        ref={ref => this.dataGrid = ref}>
                                        <Selection
                                            mode="multiple"
                                            selectAllMode={"allPages"}
                                            showCheckBoxesMode={"always"}
                                        />
                                        <Column dataField="rideId" width={70} caption="" fixed={true} allowFiltering={true} allowSorting={false} cellRender={renderGridCell} />
                                        <Column dataField="displayRideId" caption="Ride Id" fixed={true} width={100} allowFiltering={false} allowSorting={true} />
                                        <Column dataField="fullName" caption="Pax Name" width={130} allowFiltering={false} allowSorting={true} />
                                        <Column dataField="customerName" caption="Customer" width={120} allowFiltering={false} allowSorting={true} />
                                        <Column dataField="pickupLocation" caption="PU Street" width={150} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="pickupZone" caption="PU Zone" width={100} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="dropoffLocation" caption="DO Street" width={150} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="dropoffZone" caption="DO Zone" width={100} allowFiltering={false} allowSorting={true} />
                                        <Column dataField="pickupDate" caption="PU Date Time" width={130} allowFiltering={false} allowSorting={true} format={DateFormat.DateTime24Format} dataType="datetime" />
                                        {/*<Column dataField="apt" caption="Apt" width={80} allowFiltering={false} allowSorting={true} />*/}
                                        <Column dataField="adultNo" caption="Pax#" width={70} allowFiltering={false} allowSorting={true} />
                                        <Column dataField="vehicleType" caption="Vehicle Type" width={120} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="dispatureName" caption="Dispatcher" width={100} allowFiltering={false} allowSorting={true} />
                                        <Column dataField="vehicleNo" caption="Vehicle Number" width={90} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="totalKilometers" caption="Total Kilometers" width={100} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="clubbedStatus" caption="Clubbing Status" width={150} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="billingStatus" caption="Billing Status" width={150} allowFiltering={true} allowSorting={true} allowEditing={false} />

                                        <Column dataField="paymentType" caption="Payment Type" width={100} allowFiltering={false} allowSorting={true} />
                                        {/*<Column dataField="hasAttachment" caption="Has Attachment" width={80} allowFiltering={false} allowSorting={true} />*/}

                                        <Column dataField="baseFare" caption="Ride Fare" width={80} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        {/*<Column dataField="toComm" caption="Comms($)" width={80} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />*/}
                                        {/*<Column dataField="tollAmount" caption="Toll Amt" width={80} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />*/}
                                        {/*<Column dataField="tipAmount" caption="Tip Amt" width={80} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />*/}
                                        {/*<Column dataField="fuelSurCharge" caption="Fuel Sur Chrg" width={80} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />*/}
                                        {/*<Column dataField="stopCharge" caption="Stop" width={80} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />*/}
                                        {/*<Column dataField="waitTotal" caption="Wait Chrg" width={80} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />*/}

                                        {/*<Column dataField="portAuthority" caption="Port Auth" width={80} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />*/}
                                        {/*<Column dataField="utilityTax" caption="Utility" width={80} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />*/}
                                        {/*<Column dataField="wcTax" caption="WC Tax" width={80} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />*/}
                                        {/*<Column dataField="salesTax" caption="Sales Tax" width={80} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />*/}
                                        {/*<Column dataField="countyTax" caption="County Tax" width={80} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />*/}
                                        {/*<Column dataField="congestionTax" caption="Congestion Tax" width={80} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />*/}
                                        <Column dataField="gstTax" caption="GST Tax" width={80} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="totalFare" caption="Net" width={80} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        {/*<Column dataField="dRideAmount" caption="D Ride Amnt" width={80} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />*/}
                                        {/*<Column dataField="dNetAmount" caption="D Net Comms" width={80} allowFiltering={false} allowSorting={true} cellRender={renderGridCurrencyCell} />*/}
                                        <Column dataField="tripStatus" caption="Ride Status" width={150} allowFiltering={false} allowSorting={true} />

                                        <SortByGroupSummaryInfo summaryItem="count" />
                                        <GroupPanel visible={true} />
                                        <HeaderFilter visible={true} />
                                        <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                        <Grouping autoExpandAll={true} />
                                        <Paging defaultPageSize={20} />
                                        <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true} />
                                        <Export enabled={true} allowExportSelectedData={false} />
                                        <ColumnChooser enabled={true} />
                                        <ColumnFixing enabled={true} />
                                    </DataGrid>
                            }
                        </div>
                    </div>

                </div>

                <Modal ariaHideApp={false}
                    isOpen={this.state.addEditZonalRateDataModalIsOpen}
                    className={"react-modal edit-service-modal"}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal"
                >
                </Modal>
                <ReservationHistoryComponent ref="reservationHistoryModal" />
                <DocumentsComponent ref="documentsComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >)
    }
}