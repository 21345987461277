import React, { Component } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1

import Modal from 'react-modal'; //npm install --save react-modal
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { StatusType, DateFormat } from '../../helpers/fixcodes';
import { LoadPanel } from 'devextreme-react/load-panel';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions';
import { DocumentsComponent } from '../Shared/DocumentsComponent';
const position = { of: '#historydiv' };
const customStyles = {
    content: {
        width: '50%'
    }
};

export class PreviousPaymentComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
            isSubmited: false,
            isButtonShow: false,
            previousPayment: [],
            loadPanelVisible: false,
            isSystemFees: false,
            //pageName: '',
            //name: '',
            //id: 0,
        }
        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.viewInvoiceReport = this.viewInvoiceReport.bind(this);
        this.openInvoiceDocumentsModel = this.openInvoiceDocumentsModel.bind(this);
    }

    closeModal() {
        this.setState({
            isOpenModal: false,
            isSubmited: false,
            //pageName: '',
            //params: null,
        });
    }

    showModal(data, _isSystemFees) {
        this.setState({ isOpenModal: true, previousPayment: data, isSystemFees: _isSystemFees})
    }

    async viewInvoiceReport(e, data, fileFormat) {
        e.preventDefault();
        this.setState({
            loadPanelVisible: true,
        });
        var request = new Object();
        request.ReceiptNo = parseInt(data.receiptNo);
        request.fileFormat = fileFormat;
        if (this.state.isSystemFees == true) {
            request.MethodName = "GetSystemFeesReceiptReport";
        } else {
            request.MethodName = "GetInvoiceReceiptReport";
        }
        
        const requestParams = getRequestParams('POST', request);
        const response = await fetch('crystal-report-api', requestParams);
        const dataResponse = await response.json();
        this.setState({
            loadPanelVisible: false,
        });

        let a = document.createElement('a');
        a.href = dataResponse.reportBase64String;
        if (fileFormat == "pdf") {
            a.download = 'InvoiceReceipt_' + data.receiptNo + '.pdf';
        } else {
            a.download = 'InvoiceReceipt_' + data.receiptNo + '.xlsx';
        }
        a.click();

    }

    openInvoiceDocumentsModel(e, data) {
        e.preventDefault();
        var rideArray = [];
        rideArray.push(data.receiptNo);
        this.refs.documentsComponent.showModal('Invoice Receipt', rideArray, rideArray[0]);
    }

    render() {
        const renderGridCurrencyCell = (data) => {
            return <div>${parseFloat(data.value).toFixed(2)}</div>;
        }
        const renderGridCell = (data) => {

            return <div>
                <a href="#" className="fas fa-file-pdf icon text-info" title="View in PDF" onClick={(e) => { this.viewInvoiceReport(e, data.data, "pdf") }}></a>&nbsp;&nbsp;
                <a href="#" className="fas fa-paperclip icon text-info" title="View/Upload Documents" onClick={(e) => { this.openInvoiceDocumentsModel(e, data.data) }}></a>&nbsp;&nbsp;
            </div>;
        }
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={customStyles}
                    className={"react-modal"}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form>
                        <div className="modal-header">
                            <h6 className="modal-title">Previous Invoice Payments</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <small>Below are the previous payment details.</small>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="widget-container">
                                        <DataGrid
                                            dataSource={this.state.previousPayment}
                                            keyExpr="receiptNo"
                                            columnsAutoWidth={true}
                                            showColumnLines={true}
                                            showRowLines={true}
                                            showBorders={true}
                                            rowAlternationEnabled={true}
                                            allowColumnResizing={true}
                                            columnResizingMode='widget'
                                            showBorders={true}>
                                            <Column dataField="receiptNo" width={50} caption="" fixed={true} allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                            <Column dataField="receiptNo" caption="Deposite No" width={100} allowFiltering={true} allowSorting={true} />
                                            <Column dataField="customerName" caption="Customer Name" width={120} allowFiltering={true} allowSorting={true} />
                                            <Column dataField="paymentTypeName" caption="Payment Type" width={100} allowFiltering={true} allowSorting={true} />
                                            <Column dataField="dueAmount" caption="Due Amount" width={100} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                            <Column dataField="paidAmount" caption="Paid Amount" width={100} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                            <Column dataField="paidDate" caption="Paid Date" width={100} allowFiltering={true} allowSorting={true} format={DateFormat.DefaultDateTimeFormat} dataType="datetime" />
                                            <Column dataField="discount" caption="Discount" width={100} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                            <Column dataField="writeOffAmount" caption="Write-Off" width={100} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                            <Column dataField="ccType" caption="CC Type" width={80} allowFiltering={true} allowSorting={true} />
                                            <Column dataField="ccNumber" caption="CC Number" width={90} allowFiltering={true} allowSorting={true} />
                                            <Column dataField="ccExp" caption="CC Exp" width={80} allowFiltering={true} allowSorting={true} />
                                            <Column dataField="chqBankName" caption="Check Bank" width={120} allowFiltering={true} allowSorting={true} />
                                            <Column dataField="chqNumber" caption="Check Number" width={100} allowFiltering={true} allowSorting={true} />
                                            <Column dataField="chqDate" caption="Check Date" width={100} allowFiltering={true} allowSorting={true} format={DateFormat.DefaultDateTimeFormat} dataType="datetime" />
                                            <Column dataField="depositedBy" caption="Deposited By" width={150} allowFiltering={true} allowSorting={true} />
                                            <Column dataField="note" caption="Note" width={200} allowFiltering={true} allowSorting={true} />

                                            <SortByGroupSummaryInfo summaryItem="count" />
                                            <GroupPanel visible={false} />
                                            <HeaderFilter visible={false} />
                                            <SearchPanel visible={false} highlightCaseSensitive={false} width={600} placeholder="Search..." />
                                            <Grouping autoExpandAll={false} />
                                            <Paging defaultPageSize={10} />
                                            <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                            <Export enabled={false} allowExportSelectedData={false} />
                                            <ColumnChooser enabled={false} />
                                            <ColumnFixing enabled={true} />

                                        </DataGrid>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeModal}><i className="fas fa-times"></i> Cancel</button>
                        </div>
                    </form>
                </Modal>
                <DocumentsComponent ref="documentsComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}