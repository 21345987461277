import React, { Component } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { LoadPanel } from 'devextreme-react/load-panel';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { VehicleOwnerType } from '../../helpers/fixcodes.js';
import { SelectBox } from 'devextreme-react/select-box';
import Swal from 'sweetalert2';
import ProgressBar from "./ProgressBarComponent.js";
import Modal from 'react-modal'; //npm install --save react-modal
import exportFromJSON from 'export-from-json'; //npm i --save export-from-json
import { SendEmailComponent } from '../Shared/SendEmailComponent';
let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class ExportPayrollComponent extends Component {
    constructor(props) {
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        super(props)
        this.state = {
            isSubmitted: false,
            isRefreshSubmitted: false,
            loadPanelVisible: false,
            isProcessModalOpen: false,
            isExportToOracle: false,
            isExportToXML: false,
            isExportToPdf: false,
            isEmailSubmitted: false,
            vehiclePayPeriodDropdownList: [],
            payPeriodDropdownDate: null,
            payPeriodDate: '',
            isAllVehicle: true,
            vehiclesDropdownList: [],
            vehicleDropdownData: null,
            vehicleId: 0,
            payoutDetailsGridDataSource: null,
            isPayrollDataFound: false,
            processStatus: 'Click on process to start...!',
            progressBarValue: 0,
            errors: {
                payPeriodDate: '',
                vehicle: '',
            }
        }
    }
    componentDidMount() {
        this.fetchApiData();
    }
    getPayPeriodDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('pay-period-api/0/dropdown-list', requestParams);
            if (response.ok) {
                const data = await response.json();
                if (data.isSuccess) {
                    if (this.props.isReExport === true) {
                        let peyPeriodList = [];
                        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
                        let oneYearBefor = moment(new Date(currentTime)).add(-365, 'day').format('MM/DD/YYYY');

                        if (data.vehiclePayPeriods !== null) {
                            data.vehiclePayPeriods.forEach((item) => {
                                if (item.isClosed === true && item.isChequeGenerated === false) {
                                    let payDate = moment(item.payEndDate, 'YYYYMMDD').format('MM/DD/YYYY');
                                    if (moment(payDate, 'MM/DD/YYYY').isAfter(oneYearBefor, 'MM/DD/YYYY')) {
                                        let periodData = new Object();
                                        periodData.viewDate = item.viewDate;
                                        periodData.payEndDate = item.payEndDate;
                                        peyPeriodList.push(periodData);
                                    }
                                }
                            });
                        }
                    }
                    else {
                        this.setState({ vehiclePayPeriodDropdownList: data.vehiclePayPeriods });
                    }
                }
            }
            //else {
            //    await this.onfailure('error', null, null,)
            //}
        } catch (ex) {
            console.log(ex);
        }
    }
    getVehiclesDropdownList = async () => {
        try {
            var request = new Object();
            request.IsActiveOnly = true;
            request.VehicleOwnerTypeIds = VehicleOwnerType.Franchisee.toString();
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('vehicle-api/dropdown', requestParams);
            if (response.ok) {
                const data = await response.json();
                if (data.isSuccess) {
                    this.setState({
                        vehiclesDropdownList: data.vehiclesDropdown
                    });
                }
            }
            //else {
            //    await this.onfailure('error', null, null,)
            //}
        } catch (ex) {
            console.log(ex);
        }
    }
    async fetchApiData() {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getPayPeriodDropdownList();
            await this.getVehiclesDropdownList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnToolbarPreparing = async (e) => {
        try {
            let isButtonDisable = !this.state.isPayrollDataFound;
            e.toolbarOptions.items.unshift({
                location: 'after',
                widget: 'dxButton',
                showText: 'always',
                options: {
                    disabled: isButtonDisable,
                    text: 'Export to Oracle',
                    icon: 'export',
                    onClick: this.handleOnExportToOracle.bind(this),
                    hint: 'Export to Oracle'
                }
            }, {
                location: 'after',
                widget: 'dxButton',
                showText: 'always',
                options: {
                    disabled: isButtonDisable,
                    text: 'Export to Excel',
                    icon: 'export-excel-button',//'export',
                    elementAttr: {
                        "class": "dx-datagrid-export-button"
                    },
                    onClick: function () {
                        e.component.option("export.fileName", "VehiclePayInvoice");
                        e.component.exportToExcel(false);
                    },
                    hint: 'Export to Excel'
                }
            }, {
                location: 'after',
                widget: 'dxButton',
                options: {
                    disabled: isButtonDisable,
                    text: 'Export to Xml',
                    icon: 'export',
                    onClick: this.handleOnExportToXML.bind(this),
                    hint: 'Export to Xml'
                }
            },
                //    {
                //    location: 'after',
                //    widget: 'dxButton',
                //    options: {
                //        disabled: isButtonDisable,
                //        text: 'Export to Pdf',
                //        icon: 'exportpdf',
                //        onClick: this.handleOnExportToPdf.bind(this),
                //        hint: 'Export to Pdf'
                //    }
                //}, {
                //    location: 'after',
                //    widget: 'dxButton',
                //    options: {
                //        disabled: isButtonDisable,
                //        icon: 'email',
                //        onClick: this.handleOnSendEmail.bind(this),
                //        hint: 'Email'
                //    }
                //},
                {
                    location: 'after',
                    widget: 'dxButton',
                    options: {
                        icon: 'refresh',
                        onClick: this.handleOnRefreshGrid.bind(this),
                        hint: 'Refresh'
                    }
                });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnPayPeriodDropdownChange = async (selectedOption) => {
        try {
            let errors = this.state.errors;
            errors.payPeriodDate = '';
            let payPeriodDate = '';
            let selectedPayPeriod = [];
            if (selectedOption !== null && selectedOption.selectedItem !== null) {
                payPeriodDate = moment(selectedOption.selectedItem.payEndDate, 'YYYYMMDD').format('MM/DD/YYYY');
                selectedPayPeriod = selectedOption.selectedItem;
            }
            else {
                errors.payPeriodDate = 'Please select payment period.';
            }
            this.setState({ payPeriodDropdownDate: selectedPayPeriod, payPeriodDate: payPeriodDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnVehicleDropdownChange = async (selectedOption) => {
        try {
            let errors = this.state.errors;
            errors.vehicle = '';
            let vehicleId = 0;
            let selectVehicleItem = [];
            if (selectedOption !== null && selectedOption.selectedItem !== null) {
                vehicleId = (selectedOption.selectedItem.id === null ? 0 : selectedOption.selectedItem.id);
                selectVehicleItem = selectedOption.selectedItem;
            }
            else {
                if (!this.state.isVehicleAllChecked) {
                    errors.vehicle = 'Please select vehicle.';
                }
            }
            this.setState({ vehicleDropdownData: selectVehicleItem, vehicleId: vehicleId, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnSearchSubmit = async (event) => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isSearchSubmitted: true, loadPanelVisible: true });
                let request = new Object();
                request.PayDate = moment(this.state.payPeriodDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59';
                request.VehicleId = Number(this.state.vehicleId);
                request.IsReExport = this.props.isReExport;
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('vehicle-payroll-invoice-api/list', requestParams);
                const data = await response.json();
                this.setState({
                    payoutDetailsGridDataSource: data.vehiclePayrollInvoices
                });
                this.setState({
                    isPayrollDataFound: (data.vehiclePayrollInvoices !== null && data.vehiclePayrollInvoices.length > 0) || false
                });
                this.dataGrid.instance.repaint();
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSearchSubmitted: false, loadPanelVisible: false });
    }
    handleOnRefresh = async () => {
        try {
            this.setState({ isRefreshSubmitted: true, loadPanelVisible: true });
            await new Promise(resolve => setTimeout(resolve, 1000)); // 1 sec
            this.setState({
                isSubmitted: false,
                isRefreshSubmitted: false,
                loadPanelVisible: false,
                isProcessModalOpen: false,
                isExportToOracle: false,
                isExportToXML: false,
                isExportToPdf: false,
                isEmailSubmitted: false,
                vehiclePayPeriodDropdownList: [],
                payPeriodDropdownDate: null,
                payPeriodDate: '',
                isAllVehicle: true,
                vehiclesDropdownList: [],
                vehicleDropdownData: null,
                vehicleId: 0,
                payoutDetailsGridDataSource: null,
                isPayrollDataFound: false,
                processStatus: 'Click on process to start...!',
                progressBarValue: 0,
                errors: {
                    payPeriodDate: '',
                    vehicle: '',
                }
            });
            await this.fetchApiData();
            this.dataGrid.instance.repaint();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isRefreshSubmitted: false, loadPanelVisible: false });
    }
    handleOnClearSearch = async (event) => {
        event.preventDefault();
        try {
            this.setState({
                isSubmitted: false,
                isRefreshSubmitted: false,
                loadPanelVisible: false,
                isProcessModalOpen: false,
                isExportToOracle: false,
                isExportToXML: false,
                isExportToPdf: false,
                isEmailSubmitted: false,
                payPeriodDropdownDate: null,
                payPeriodDate: '',
                isAllVehicle: true,
                vehicleDropdownData: null,
                vehicleId: 0,
                payoutDetailsGridDataSource: null,
                isPayrollDataFound: false,
                processStatus: 'Click on process to start...!',
                progressBarValue: 0,
                errors: {
                    payPeriodDate: '',
                    vehicle: '',
                }
            });
            await new Promise(resolve => setTimeout(resolve, 1000)); // 1/2 sec
            this.dataGrid.instance.repaint();
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnRefreshGrid = async () => {
        try {
            this.dataGrid.instance.refresh();
        } catch (ex) {
            console.log(ex);
        }
    }
    exportPyaoutToOracle = async (vendorId, amount, payIds) => {
        let isSuccess = true;
        try {
            //var request = new Object();
            //request.vendorId = vendorId;
            //request.amount = Number(amount);
            //request.payIds = payIds;
            //const requestParams = getRequestParams('POST', request);
            //const response = await fetch('export-payroll-api/oracle', requestParams);
            //if (response.ok) {
            //    const data = await response.json();
            //    if (data.isSuccess) {
            //        isSuccess = true;
            //    }
            //}
        } catch (ex) {
            isSuccess = false;
            console.log(ex);
        }
        return isSuccess;
    }
    handleOnExportToOracle = async (e) => {
        try {
            this.setState({ isExportToOracle: true });
            e.component.option('icon', 'fas fa-spinner fa-spin');
            e.component.option('text', 'In-process');
            e.component.option('hint', 'In-process');

            if (this.state.payoutDetailsGridDataSource === null && this.state.payoutDetailsGridDataSource.length === 0) {
                await this.onfailure('warning', 'Unable to ' + (this.props.isReExport ? 'Re-Export' : 'Export'), 'Vehicle payroll data not found.');
            }
            else {
                let paydoutDataList = JSON.parse(JSON.stringify(this.state.payoutDetailsGridDataSource));
                let chkEmptyVendorId = paydoutDataList.filter(el => Number(el.vendorId) === 0);
                if (chkEmptyVendorId !== null && chkEmptyVendorId.length > 0) {
                    await this.onfailure('warning', 'Unable to ' + (this.props.isReExport ? 'Re-Export' : 'Export'), "These Vehicles do not have a super shuttle Vendor ID.<br/>Please update the Vendor ID first and try again.");
                }
                else {
                    let distinctVendorList = [...new Set(paydoutDataList.map(item => item.vendorId))];
                    let confirmMsg = this.props.isReExport ? 'You are Re-Exporting this payroll.' : 'Once you Export Payroll to Oracle Finance, your Payroll will be closed.';
                    await Swal.fire({
                        title: '<small><b>Confirmation</b></small>',
                        html: "<span style='font- size: 20px;'>" + confirmMsg + "</span> <br/>Are you sure want to continue?",
                        icon: 'question',
                        allowOutsideClick: false,
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: 'No',
                        confirmButtonText: 'Yes'
                    }).then(async (result) => {
                        if (!result.isConfirmed) {
                            this.setState({ isExportToOracle: false, processStatus: 'Click on process to start...!' });
                        } else {
                            this.setState({ isProcessModalOpen: true });
                            let totalExportCount = distinctVendorList.length;
                            let exportedCount = 0;
                            let isSuccess = false;
                            let successCount = 0;
                            let failedCount = 0;
                            let status = '';
                            for (var i = 0; i < distinctVendorList.length; i++) {
                                let vendorId = Number(distinctVendorList[i]);
                                let vendorDataList = [...new Set(paydoutDataList.filter(item => Number(item.vendorId) === vendorId))];
                                let chequeAmount = vendorDataList.reduce((total, currentValue) => total = total + currentValue.chequeAmount, 0);
                                let payIds = vendorDataList.map(item => { return item.payId }).join(",");

                                this.setState({ processStatus: 'Exporting vendor: ' + vendorId + ' .. completed ' + exportedCount + ' / ' + totalExportCount });

                                isSuccess = await this.exportPyaoutToOracle(vendorId, chequeAmount, payIds);
                                exportedCount = Number(exportedCount) + 1;
                                status = '';
                                if (isSuccess) {
                                    successCount = Number(successCount) + 1;
                                    status = 'Vendor: ' + vendorId + ' .. exported ' + exportedCount + ' / ' + totalExportCount;
                                }
                                else {
                                    failedCount = Number(failedCount) + 1;
                                    status = 'Faild! Vendor: ' + vendorId + ' ..' + exportedCount + ' / ' + totalExportCount;
                                }
                                let progressBarValue = (Number(exportedCount) / Number(totalExportCount)) * 100;
                                this.setState({ processStatus: status, progressBarValue: parseInt(progressBarValue) });
                            };
                            await new Promise(resolve => setTimeout(resolve, 1000)); // 1 sec
                            this.setState({ isProcessModalOpen: false });
                            await this.onfailure('warning', 'Unable to ' + (this.props.isReExport ? 'Re-Export' : 'Export'), 'Thie feature is under development.');
                            //await Swal.fire({
                            //    allowOutsideClick: false,
                            //    icon: Number(exportedCount) > 0 ? 'success' : 'error',
                            //    title: Number(exportedCount) > 0 ? 'Success' : 'Oops...',
                            //    html: Number(exportedCount) === 0 ?
                            //        'Vehicle payroll not exported to oracle.'
                            //        : 'Successfully exported to oracle.'
                            //        + '<br/>Success exported count is [<b>' + successCount + '</b>] out of [<b>' + totalExportCount + '</b>].'
                            //        + (Number(failedCount) === 0 ? '' : '<br/>Failed exprted count is [<b>' + failedCount + '</b>] out of [<b>' + totalExportCount + '</b>]'),
                            //}).then(async (result) => {
                            //    if (result.isConfirmed) {
                            //        await this.fetchApiData();
                            //        this.setState({ isExportToOracle: false });
                            //    }
                            //});
                        }
                    });
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        e.component.option('icon', 'export');
        e.component.option('text', 'Export to Oracle');
        e.component.option('hint', 'Export to Oracle');
        this.setState({ isExportToOracle: false });
    }
    handleOnExportToXML = async (e) => {
        try {
            this.setState({ isExportToXML: true });
            e.component.option('icon', 'fas fa-spinner fa-spin');
            e.component.option('text', 'In-process');
            e.component.option('hint', 'In-process');
            let fileName = 'VehiclePayInvoice';
            let exportType = 'xml';
            let data = this.state.payoutDetailsGridDataSource;
            exportFromJSON({ data, fileName, exportType });
        } catch (ex) {
            console.log(ex);
        }
        e.component.option('icon', 'export');
        e.component.option('text', 'Export to Xml');
        e.component.option('hint', 'Export to Xml');
        this.setState({ isExportToXML: false });
    }
    handleOnExportToPdf = async (e) => {
        try {
            this.setState({ isExportToPdf: true });
            e.component.option('icon', 'fas fa-spinner fa-spin');
            e.component.option('text', 'In-process');
            e.component.option('hint', 'In-process');
            await this.onfailure('warning', 'Unable to process', 'Thie feature is under development.');
        } catch (ex) {
            console.log(ex);
        }
        e.component.option('icon', 'exportpdf');
        e.component.option('text', 'Export to Pdf');
        e.component.option('hint', 'Export to Pdf');
        this.setState({ isExportToPdf: false, loadPanelVisible: false });
    }
    handleOnSendEmail = async (e) => {
        try {
            this.setState({ isEmailSubmitted: true });
            e.component.option('icon', 'fas fa-spinner fa-spin');
            e.component.option('hint', 'In-process');
            await this.onfailure('warning', 'Unable to process', 'Thie feature is under development.');
        } catch (ex) {
            console.log(ex);
        }
        e.component.option('icon', 'email');
        e.component.option('hint', 'Email');
        this.setState({ isEmailSubmitted: false });
    }
    onfailure = async (icon, title, resultMessage) => {
        this.setState({ loadPanelVisible: false });
        await Swal.fire({
            allowOutsideClick: false,
            showCloseButton: false,
            icon: icon,
            title: (title === null || title.trim() === '') ? 'Oops...' : title,
            html: (resultMessage === null || resultMessage.trim() === '') ? 'Opps! something went wrong, unable to process the request.' : resultMessage.trim(),
        })
    }
    isFormValid = async () => {
        let isValid = true;
        let errors = this.state.errors;
        errors.payPeriodDate = '';
        errors.vehicle = '';
        try {
            if (this.state.payPeriodDate.trim() === '') {
                errors.payPeriodDate = 'Please select payment period.';
                isValid = false;
            }
            if (this.state.isAllVehicle === false) {
                if (Number(this.state.vehicleId) === 0) {
                    errors.vehicle = 'Please select vehicle.';
                    isValid = false;
                }
            }
            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    render() {
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                <h1 className="m-0 text-dark">{(this.props.isReExport) ? 'Re-Export' : 'Export'} Vehicle Payroll</h1>
                            </div>
                            <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem>
                                        <NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="#" title="Refresh"
                                            onClick={this.handleOnRefresh}>
                                            {this.state.isRefreshSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isRefreshSubmitted && <span title="Refresh"><i className="fas fa-sync-alt"></i> Refresh</span>}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to={{
                                            pathname: (this.props.isReExport ? "/franchisee/export/payroll" : '/franchisee/export/reexportpayroll'),
                                            isReExport: !this.props.isReExport
                                        }}
                                            className="btn btn-block btn-outline-secondary btn-sm" title={(this.props.isReExport) ? 'Export Payroll' : 'Re-Export Payroll'}>
                                            <i className="fas fa-file-export"></i> {(this.props.isReExport) ? ' Export Payroll' : ' Re-Export Payroll'}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="/franchisee/importcheques" className="btn btn-block btn-outline-secondary btn-sm" title="Import Cheque">
                                            <i className="fas fa-file-import"></i> Import Cheque</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="/franchisee/export/appayroll" className="btn btn-block btn-outline-secondary btn-sm" title="Export AP Payroll">
                                            <i className="fas fa-file-export"></i> Export AP Payroll</NavLink>
                                    </NavItem>
                                    <NavItem >
                                        <NavLink tag={Link} to="/franchisee/chequeviewprint" className="btn btn-block btn-outline-secondary btn-sm" title="Cheque - View / Print">
                                            <i className="fas fa-file-pdf"></i> Cheque - View / Print</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="/reimbursement/view" className="btn btn-block btn-outline-secondary btn-sm" title="Reimbursement View">
                                            <i className="fas fa-car"></i> Reimb. View</NavLink>
                                    </NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <form
                    style={(
                        this.state.isSubmitted || this.state.isRefreshSubmitted || this.state.isExportToOracle || this.state.isExportToXML
                        || this.state.isExportToPdf || this.state.isEmailSubmitted
                    ) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="rez-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>Payment Period</label>
                                        {/*<Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select period"
                                            defaultValue={[]}
                                            value={this.state.payPeriodDropdownDate}
                                            name="ddpayperiod"
                                            onChange={this.handleOnPayPeriodDropdownChange}
                                            getOptionLabel={option => option.viewDate}
                                            getOptionValue={option => option.payEndDate}
                                            options={this.state.vehiclePayPeriodDropdownList}
                                            isMulti={false}
                                            isClearable={true}
                                            isSearchable={true}
                                        />*/}
                                        <SelectBox
                                            dataSource={this.state.vehiclePayPeriodDropdownList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select period"
                                            name="ddpayperiod"
                                            defaultValue={''}
                                            value={(this.state.payPeriodDropdownDate !== null && this.state.payPeriodDropdownDate.payEndDate !== null) ? this.state.payPeriodDropdownDate.payEndDate : ''}
                                            displayExpr='viewDate'
                                            valueExpr='payEndDate'
                                            onSelectionChanged={this.handleOnPayPeriodDropdownChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["payPeriodDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["payPeriodDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12" >
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                id="isAllVehicle" name="isAllVehicle"
                                                checked={this.state.isAllVehicle}
                                                onChange={(e) => {
                                                    let errors = this.state.errors;
                                                    errors.vehicle = '';
                                                    this.setState({
                                                        isAllVehicle: e.target.checked,
                                                        vehicleDropdownData: null,
                                                        vehicleId: 0,
                                                        errors: errors
                                                    })
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor="isAllVehicle"> All Vehicle</label>
                                        </div>
                                        {/*<Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select vehicle"
                                            value={this.state.vehicleDropdownData}
                                            name="ddvehicle"
                                            onChange={this.handleOnVehicleDropdownChange}
                                            getOptionLabel={option => option.name}
                                            getOptionValue={option => option.id}
                                            options={this.state.vehiclesDropdownList}
                                            isMulti={false}
                                            isClearable={true}
                                            isDisabled={this.state.isAllVehicle}
                                            isSearchable={true}
                                            width="100%"
                                        />*/}
                                        <SelectBox
                                            dataSource={this.state.vehiclesDropdownList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select vehicle"
                                            name="ddvehicle"
                                            defaultValue={0}
                                            value={this.state.vehicleId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnVehicleDropdownChange}
                                            showClearButton={true}
                                            disabled={this.state.isAllVehicle}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["vehicle"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["vehicle"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label className="btn-block">&nbsp;</label>
                                        <button type="button" className="btn btn-success btn-sm" title="Search" onClick={this.handleOnSearchSubmit} >
                                            {this.state.isSearchSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSearchSubmitted && <span title="Search"><i className="fas fa-search"></i> Search</span>}
                                        </button>&nbsp;&nbsp;
                                                 <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                            onClick={this.handleOnClearSearch}>
                                            <i className="fas fa-eraser"></i> Clear
                                                  </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="content">
                    <div className="card">
                        <div className="card-body">
                            <DataGrid
                                ref={(ref) => this.dataGrid = ref}
                                dataSource={this.state.payoutDetailsGridDataSource}
                                keyExpr="payId"
                                selection={{ mode: 'single' }}
                                columnsAutoWidth={true}
                                showColumnLines={false}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                showBorders={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                width='100%'
                                onToolbarPreparing={this.handleOnToolbarPreparing}
                            >
                                <Column dataField="payId" caption="Pay No." alignment="center" width={'auto'} />
                                <Column dataField="vehicleNo" caption="Vehicle" />
                                <Column dataField="vehicleTypeName" caption="Charter" />
                                <Column dataField="franchiseeName" caption="Franchisee" />
                                <Column dataField="chequeAmount" caption="Inv. Amount" alignment="center" width={'auto'} dataType="decimal" />
                                <Column dataField="vendorId" caption="Vendor Id" alignment="center" width={'auto'} />
                                <Column dataField="vendorNo" caption="Vendor No." />
                                <Column dataField="vendorName" caption="Vendor Name" />
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={false} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                <Export enabled={false} allowExportSelectedData={false} />
                                <ColumnChooser enabled={false} />
                                <ColumnFixing enabled={false} />
                            </DataGrid>
                        </div>
                    </div>
                </div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isProcessModalOpen}
                    style={{ content: { width: '35%' } }}
                    className={"react-modal"}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <div className="modal-body">
                        <div className="row mt-2">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                                <h5><strong>{'Processing...'}</strong></h5>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                                <div className="form-group">
                                    <span className="text-success">{this.state.processStatus}</span>
                                </div>
                            </div>
                            <div style={{ display: Number(this.state.progressBarValue) > 0 || 'none' }} className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="form-group">
                                    <ProgressBar key={this.state.progressBarValue}
                                        bgcolor={'#28a745'} completed={this.state.progressBarValue} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        )
    }
}