import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import RadioGroup from 'devextreme-react/radio-group';
import { SelectBox } from 'devextreme-react/select-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import { CheckBox } from 'devextreme-react/check-box';
import Swal from 'sweetalert2';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import { SendEmailComponent } from '../Shared/SendEmailComponent'
const reportTypeRadio = [
    { id: 0, name: 'View Vehicle Report' },
    { id: 1, name: 'View Checks Only' },
];

export class AccountPaymentsReportComponent extends Component {
    constructor() {
        super()
        this.state = {
            isSubmitted: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            reportTypeList: reportTypeRadio,
            reportType: reportTypeRadio[0],
            radVehicle: "0",
            vehicleList: [],
            vehicleId: 0,
            vehicleName: "",
            checkOnly: false,
            checkDueAmtGreaterZero: false,
            dropdownPayRollDate: [],
            payEndDate: '',
            errors: {
                vehicleId: 0,
                payEndDate: "",
            }
        }

        this.getVehicles = this.getVehicles.bind(this);
        this.getPayPeriod = this.getPayPeriod.bind(this);

        this.handleReportTypeChange = this.handleReportTypeChange.bind(this);
        this.handleRadVehicleChange = this.handleRadVehicleChange.bind(this);
        this.handleVehicleChange = this.handleVehicleChange.bind(this);
        this.handleCheckOnlyValueChanged = this.handleCheckOnlyValueChanged.bind(this);
        this.handleDueAmtGreaterZeroValueChanged = this.handleDueAmtGreaterZeroValueChanged.bind(this);
        this.handlePayPeriodChange = this.handlePayPeriodChange.bind(this);

        this.handleAccountPaymentReportSubmit = this.handleAccountPaymentReportSubmit.bind(this);
        this.downloadAccountPaymentAsPdf = this.downloadAccountPaymentAsPdf.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
    }
    async componentDidMount() {
        this.setState({
            loadPanelVisible: true,
        });
        await this.getVehicles();
        await this.getPayPeriod()
        this.setState({
            loadPanelVisible: false,
        });
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    async getVehicles() {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/vehicle-api/dropdown-all', requestParams);
            const data = await response.json();
            this.setState({
                vehicleList: data.vehiclesDropdown,
            });
        } catch (e) {
            console.log(e);
        }
    }
    async getPayPeriod() {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('pay-period-api/0/dropdown-list', requestParams);
            const data = await response.json();
            this.setState({
                dropdownPayRollDate: data.vehiclePayPeriods,
            });

        } catch (e) {
            console.log(e);
        }
    }
    handlePayPeriodChange(selectedOption) {
        this.setState({ payEndDate: (selectedOption.selectedItem != null ? selectedOption.selectedItem.valueDate : ""), })
        this.state.errors["payEndDate"] = "";
    }
    handleCheckOnlyValueChanged(args) {
        this.setState({
            checkOnly: args.value
        });
    }
    handleDueAmtGreaterZeroValueChanged(args) {
        this.setState({
            checkDueAmtGreaterZero: args.value
        });
    }
    handleReportTypeChange(e) {
        this.setState({
            reportType: e.value
        });
    }
    handleRadVehicleChange(e) {
        if (e.target.value == "0") {
            this.setState({
                radVehicle: e.target.value,
                vehicleId: 0,
                vehicleName: "",
            });
        }
        else if (e.target.value == "1") {
            this.setState({
                radVehicle: e.target.value,
            });
        }
        this.setState({ radVehicle: e.target.value });
    }
    handleVehicleChange(selectedOption) {
        //if (e !== null) {
        //    this.setState({
        //        vehicleId: e.id,
        //        vehicleName: e.name,
        //    });
        //}
        //else {
        //    this.setState({
        //        vehicleId: 0,
        //        vehicleName: "",
        //    });
        //}
        this.setState({
            vehicleId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0),
            vehicleName: (selectedOption.selectedItem != null ? selectedOption.selectedItem.name : ""),
        });
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getDriversDropdownList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isEmailSubmitted: false,
                isOpenModal: false,
                loadPanelVisible: false,
                reportTypeList: reportTypeRadio,
                reportType: reportTypeRadio[0],
                radVehicle: "0",
                vehicleId: 0,
                vehicleName: "",
                checkOnly: false,
                checkDueAmtGreaterZero: false,
                payEndDate: '',
                errors: {
                    vehicleId: 0,
                    payEndDate: "",
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnInputChange = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
        } catch (ex) {
            console.log(ex);
        }
    }
    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.payEndDate = "";

        this.setState({ message: '' });

        if (this.state.payEndDate == "") {
            formIsValid = false;
            errors.payEndDate = "Please select payment period";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    handleAccountPaymentReportSubmit(e) {
        e.preventDefault();
        this.downloadAccountPaymentAsPdf(e);
    }
    async downloadAccountPaymentAsPdf(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            try {
                this.setState({ isSubmitted: true });
                var request = new Object();
                let selectionCriteria = "";
                
                if (this.state.reportType.id == 0) {
                    selectionCriteria = "{PROC_RPT_ACVehicleCheque_Details;1.PAY_DATE} = CDateTime('" + moment(this.state.payEndDate, 'YYYY-MM-DD').format("YYYY-MM-DD") + " 23:59:59')";

                    if (this.state.radVehicle == 1 && this.state.vehicleId > 0) {
                        selectionCriteria = selectionCriteria + (selectionCriteria != "" ? " And " : "") + " {PROC_RPT_ACVehicleCheque_Details;1.VEHICLE_NO} = '" + this.state.vehicleName + "'";
                    }

                    if (this.state.checkOnly) {
                        selectionCriteria = selectionCriteria + (selectionCriteria != "" ? " And " : "") + "{PROC_RPT_ACVehicleCheque_Details;1.CHECK_NO} > '0' ";
                    }
                    if (this.state.checkDueAmtGreaterZero) {
                        selectionCriteria = selectionCriteria + (selectionCriteria != "" ? " And " : "") + " {PROC_RPT_ACVehicleCheque_Details;1.CHECK_AMOUNT} > 0 ";
                    }
                }
                else {
                    if (this.state.checkOnly) {
                        selectionCriteria = "{Proc_Select_VehicleCheque_Summary;1.Check_No} > '0' ";
                    }

                    if (this.state.checkDueAmtGreaterZero) {
                        selectionCriteria = selectionCriteria + (selectionCriteria != "" ? " And " : "") + "{Proc_Select_VehicleCheque_Summary;1.Total_Paid} > 0 ";
                    }
                    request.VehicleId = this.state.vehicleId;
                    request.PayPeriodDate = formatDate(this.state.payEndDate, "MM/dd/yyyy");
                }


                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.fileFormat = 'pdf';
                request.ReportType = parseInt(this.state.reportType.id);
                request.MethodName = "GetAccountPayments";
                request.SelectionCrieteria = selectionCriteria;

                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();

                let a = document.createElement('a');
                a.href = dataResponse.reportBase64String;
                a.download = 'AccountPayments.pdf';
                a.click();
            } catch (e) {
            }
            this.setState({ isSubmitted: false });
        }
    }
    handleClearSearch() {
        this.setState({
            isSubmitted: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            reportTypeList: reportTypeRadio,
            reportType: reportTypeRadio[0],
            radVehicle: "0",
            vehicleId: 0,
            vehicleName: "",
            checkOnly: false,
            checkDueAmtGreaterZero: false,
            payEndDate: '',
            errors: {
                vehicleId: 0,
                payEndDate: "",
            }
        });
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isEmailSubmitted: true });
                var request = new Object();
                let selectionCriteria = "";
                if (this.state.reportType.id == 0) {
                    selectionCriteria = "{PROC_RPT_ACVehicleCheque_Details;1.PAY_DATE} = CDateTime('" + moment(this.state.payEndDate, 'YYYY-MM-DD').format("YYYY-MM-DD") + " 23:59:59')";

                    if (this.state.radVehicle == 1 && this.state.vehicleId > 0) {
                        selectionCriteria = selectionCriteria + (selectionCriteria != "" ? " And " : "") + " {PROC_RPT_ACVehicleCheque_Details;1.VEHICLE_NO} = '" + this.state.vehicleName + "'";
                    }

                    if (this.state.checkOnly) {
                        selectionCriteria = selectionCriteria + (selectionCriteria != "" ? " And " : "") + "{PROC_RPT_ACVehicleCheque_Details;1.CHECK_NO} > '0' ";
                    }
                    if (this.state.checkDueAmtGreaterZero) {
                        selectionCriteria = selectionCriteria + (selectionCriteria != "" ? " And " : "") + " {PROC_RPT_ACVehicleCheque_Details;1.CHECK_AMOUNT} > 0 ";
                    }
                }
                else {
                    if (this.state.checkOnly) {
                        selectionCriteria = "{Proc_Select_VehicleCheque_Summary;1.Check_No} > '0' ";
                    }

                    if (this.state.checkDueAmtGreaterZero) {
                        selectionCriteria = selectionCriteria + (selectionCriteria != "" ? " And " : "") + "{Proc_Select_VehicleCheque_Summary;1.Total_Paid} > 0 ";
                    }
                    request.VehicleId = this.state.vehicleId;
                    request.PayPeriodDate = formatDate(this.state.payEndDate, "MM/dd/yyyy");
                }
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.fileFormat = 'pdf';
                request.ReportType = parseInt(this.state.reportType.id);
                request.MethodName = "GetAccountPayments";
                request.SelectionCrieteria = selectionCriteria;
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Payments';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = 'AccountPayments.pdf'
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '45%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleOnReportSubmit}
                        style={(this.state.isSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Payments'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Report Type</label>
                                        <RadioGroup id="radio-group-viewby" dataSource={this.state.reportTypeList} displayExpr="name" value={this.state.reportType} onValueChanged={this.handleReportTypeChange} layout="horizontal" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label></label>
                                        <CheckBox defaultValue={false} width={100} text="Checks Only" value={this.state.checkOnly} onValueChanged={this.handleCheckOnlyValueChanged} />
                                        <CheckBox defaultValue={false} width={250} text="Due amount greater than zero value" value={this.state.checkDueAmtGreaterZero} onValueChanged={this.handleDueAmtGreaterZeroValueChanged} />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Select Payment Period</label>
                                        <SelectBox
                                            dataSource={this.state.dropdownPayRollDate.length > 0 ? this.state.dropdownPayRollDate : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select payroll date"
                                            name="payEndDate"
                                            defaultValue={0}
                                            value={this.state.payEndDate}
                                            displayExpr='viewDate'
                                            valueExpr='valueDate'
                                            onSelectionChanged={this.handlePayPeriodChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["payEndDate"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["payEndDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radVehicle" value="0" checked={this.state.radVehicle === "0"} onChange={this.handleRadVehicleChange} />
                                            <label className="form-check-label">All Vehicle</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radVehicle" value="1" checked={this.state.radVehicle === "1"} onChange={this.handleRadVehicleChange} />
                                            <label className="form-check-label">Single Vehicle</label>
                                        </span>
                                        <SelectBox
                                            dataSource={this.state.vehicleList.length > 0 ? this.state.vehicleList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select payEndDate"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.vehicleId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleVehicleChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["vehicleId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["vehicleId"]}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="submit" className="btn btn-success btn-sm" title="Download" onClick={this.handleAccountPaymentReportSubmit} >
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                                </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}