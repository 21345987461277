import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { SelectBox } from 'devextreme-react/select-box';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import { LoadPanel } from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'
let currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class WorkCompensationReportComponent extends Component {
    constructor() {
        currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        super()
        this.state = {
            isSubmittedPdf: false,
            isSubmittedExcel: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            isInvoiceRideDetails: true,
            isInvoiceRideSummary: false,
            isWCTax: false,
            isAllLocation: true,
            locationDropdownList: [],
            locationDropdownData: null,
            locationId: 0,
            fromDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
            toDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
            errors: {
                location: '',
                fromDate: '',
                toDate: '',
            }
        }
    }
    getLocationDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('account-api/locations', requestParams);
            const data = await response.json();
            this.setState({
                locationDropdownList: data.locations
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getLocationDropdownList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmittedPdf: false,
                isSubmittedExcel: false,
                isEmailSubmitted: false,
                isOpenModal: false,
                loadPanelVisible: false,
                isInvoiceRideDetails: true,
                isInvoiceRideSummary: false,
                isWCTax: false,
                isAllLocation: true,
                locationDropdownList: [],
                locationDropdownData: null,
                locationId: 0,
                fromDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
                toDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
                errors: {
                    location: '',
                    fromDate: '',
                    toDate: '',
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnClearSearch = async () => {
        try {
            currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
            this.setState({
                isSubmittedPdf: false,
                isSubmittedExcel: false,
                isEmailSubmitted: false,
                loadPanelVisible: false,
                isInvoiceRideDetails: true,
                isInvoiceRideSummary: false,
                isWCTax: false,
                isAllLocation: true,
                locationDropdownData: null,
                locationId: 0,
                fromDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
                toDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
                errors: {
                    location: '',
                    fromDate: '',
                    toDate: '',
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnInputChange = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
            let errors = this.state.errors;
            switch (name) {
                case 'isInvoiceRideDetails':
                    errors.location = '';
                    errors.fromDate = '';
                    errors.toDate = '';
                    this.setState({ isInvoiceRideDetails: value, isInvoiceRideSummary: false, isWCTax: false });
                    break;
                case 'isInvoiceRideSummary':
                    errors.location = '';
                    errors.fromDate = '';
                    errors.toDate = '';
                    this.setState({ isInvoiceRideDetails: false, isInvoiceRideSummary: value, isWCTax: false });
                    break;
                case 'isWCTax':
                    errors.location = '';
                    errors.fromDate = '';
                    errors.toDate = '';
                    this.setState({ isInvoiceRideDetails: false, isInvoiceRideSummary: false, isWCTax: value });
                    break;
                case 'isAllLocation':
                    errors.location = '';
                    this.setState({ isAllLocation: value, locationDropdownData: null, locationId: 0 });
                    break;
                default:
                    break;
            }
            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnLocationDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.location = '';
            let locationId = 0;
            let locationDropdownData = null;
            if (data !== null && data.selectedItem !== null) {
                locationId = (data.selectedItem !== null ? data.selectedItem.locationId : 0);
                locationDropdownData = data.selectedItem;
            }
            else {
                //errors.location = 'Please select location.';
            }
            this.setState({ locationDropdownData: locationDropdownData, locationId: locationId, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnFromDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.fromDate = '';
            let fromDate = '';
            if (event.value !== null && event.value !== '') {
                fromDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.fromDate = 'Please select date.';
            }
            if (this.state.toDate.trim() !== '') {
                errors.toDate = '';
            }
            this.setState({ fromDate: fromDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnToDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.toDate = '';
            let toDate = '';
            if (event.value !== null && event.value !== '') {
                toDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.toDate = 'Please select date.';
            }
            if (this.state.fromDate.trim() !== '') {
                errors.fromDate = '';
            }
            this.setState({ toDate: toDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnReportSubmit = async (event, fileFormat) => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                if (fileFormat === 'xlsx') {
                    this.setState({ isSubmittedExcel: true });
                }
                if (fileFormat === 'pdf') {
                    this.setState({ isSubmittedPdf: true });
                }

                var request = {};
                if (this.state.isInvoiceRideDetails === true) {
                    request.ReportType = 1;
                }
                if (this.state.isInvoiceRideSummary === true) {
                    request.ReportType = 2;
                }
                if (this.state.isWCTax === true) {
                    request.ReportType = 3;
                }
                request.LocationId = Number(this.state.locationId);
                request.StartDate = this.state.fromDate;
                request.EndDate = this.state.toDate;
                request.SelectionCrieteria = await this.setSelectionCrieteria();
                request.fileFormat = fileFormat;
                request.MethodName = "GetWorkCompensationReport";
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                let a = document.createElement('a');
                a.href = dataResponse.reportBase64String;
                a.download = 'WorkCompensationTax.' + fileFormat;
                a.click();
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmittedPdf: false, isSubmittedExcel: false });
    }
    isFormValid = async () => {
        let isValid = true;
        let errors = this.state.errors;
        errors.location = '';
        errors.fromDate = '';
        errors.toDate = '';
        try {
            if (this.state.isAllLocation === false) {
                if (Number(this.state.locationId) === 0 || Object.keys(this.state.locationDropdownData).length === 0) {
                    errors.location = 'Please select location.';
                    isValid = false;
                }
            }
            if (this.state.fromDate.trim() === '') {
                errors.fromDate = 'Please select date.';
                isValid = false;
            }
            if (this.state.toDate.trim() === '') {
                errors.toDate = 'Please select date.';
                isValid = false;
            }
            if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                if (moment(this.state.fromDate, 'MM/DD/YYYY').isAfter(this.state.toDate, 'MM/DD/YYYY')) {
                    errors.fromDate = 'Please select a valid date range.';
                    errors.toDate = 'Please select a valid date range.';
                    isValid = false;
                }
            }
            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    setSelectionCrieteria = async () => {
        let selectionCrieteria = 'Work Compensation for';
        try {
            if (this.state.isAllLocation === true) {
                selectionCrieteria += ' all location';
            }
            else {
                if (this.state.locationDropdownData !== null && Object.keys(this.state.locationDropdownData).length > 0) {
                    selectionCrieteria += ' location - ' + this.state.locationDropdownData.locationName;
                }
            }
            if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                selectionCrieteria += ', date from ' + this.state.fromDate + ' to ' + this.state.toDate;
            }
            else {
                selectionCrieteria += ', all date';
            }
            //if (this.state.isInvoiceRideDetails === true) {
            //    selectionCrieteria += ', report - invoice ride details';
            //}
            //if (this.state.isInvoiceRideSummary === true) {
            //    selectionCrieteria += ', report - invoice ride summary';
            //}
            //if (this.state.isWCTax === true) {
            //    selectionCrieteria += ', report - WC tax';
            //}
        } catch (ex) {
            selectionCrieteria = '';
            console.log(ex);
        }
        return selectionCrieteria;
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isEmailSubmitted: true });
                var request = {};
                if (this.state.isInvoiceRideDetails === true) {
                    request.ReportType = 1;
                }
                if (this.state.isInvoiceRideSummary === true) {
                    request.ReportType = 2;
                }
                if (this.state.isWCTax === true) {
                    request.ReportType = 3;
                }
                request.LocationId = Number(this.state.locationId);
                request.StartDate = this.state.fromDate;
                request.EndDate = this.state.toDate;
                request.SelectionCrieteria = await this.setSelectionCrieteria();
                request.fileFormat = 'pdf';
                request.MethodName = "GetWorkCompensationReport";
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Work Compensation Report';
                    let attachments = [];
                    let file = {};
                    file.fileData = fileData;
                    file.fileName = 'WorkCompensationTax.pdf'
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '45%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form style={(this.state.isSubmittedPdf || this.state.isSubmittedExcel || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Work Compensation Report'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                name="isAllLocation"
                                                checked={this.state.isAllLocation}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isAllLocation"> All Location</label>
                                        </div>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder="Select location"*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.locationDropdownData}*/}
                                        {/*    name="ddlocation"*/}
                                        {/*    onChange={this.handleOnLocationDropdownChange}*/}
                                        {/*    getOptionLabel={option => option.locationName}*/}
                                        {/*    getOptionValue={option => option.locationId}*/}
                                        {/*    options={this.state.locationDropdownList}*/}
                                        {/*    isMulti={false}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*    isDisabled={this.state.isAllLocation}*/}
                                        {/*/>*/}
                                        <SelectBox
                                            dataSource={this.state.locationDropdownList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select location"
                                            name="ddlocation"
                                            defaultValue={0}
                                            value={this.state.locationId}
                                            displayExpr='locationName'
                                            valueExpr='locationId'
                                            onSelectionChanged={this.handleOnLocationDropdownChange}
                                            showClearButton={true}
                                            disabled={this.state.isAllLocation}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["location"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["location"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>From Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="fromDate"
                                            placeholder="MM/dd/yyyy"
                                            defaultValue={this.state.fromDate}
                                            value={this.state.fromDate}
                                            onValueChanged={this.handleOnFromDateChange}
                                            width={'100%'}
                                            disabled={this.state.isAllDate}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["fromDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["fromDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>To Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="toDate"
                                            placeholder="MM/dd/yyyy"
                                            defaultValue={this.state.toDate}
                                            value={this.state.toDate}
                                            min={this.state.fromDate}
                                            onValueChanged={this.handleOnToDateChange}
                                            width={'100%'}
                                            disabled={this.state.isAllDate}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["toDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["toDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Report Type</label><br />
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isInvoiceRideDetails"
                                                checked={this.state.isInvoiceRideDetails}
                                                onChange={this.handleOnInputChange}
                                                disabled={false} />
                                            <label className="form-check-label" htmlFor="isInvoiceRideDetails"> Invoice Ride Details</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isInvoiceRideSummary"
                                                checked={this.state.isInvoiceRideSummary}
                                                onChange={this.handleOnInputChange}
                                                disabled={false}
                                            />
                                            <label className="form-check-label" htmlFor="isInvoiceRideSummary"> Invoice Ride Summary</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isWCTax"
                                                checked={this.state.isWCTax}
                                                onChange={this.handleOnInputChange}
                                                disabled={false}
                                            />
                                            <label className="form-check-label" htmlFor="isWCTax"> WC Tax</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="button" className="btn btn-success btn-sm" title="Download excel" onClick={(e) => this.handleOnReportSubmit(e, 'xlsx')}>
                                {this.state.isSubmittedExcel && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmittedExcel && <span title="Download Excel"><i className="fas fa-file-download"></i> Download Excel</span>}
                            </button>
                            <button type="button" className="btn btn-success btn-sm" title="Download pdf" onClick={(e) => this.handleOnReportSubmit(e, 'pdf')}>
                                {this.state.isSubmittedPdf && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmittedPdf && <span title="Download PDF"><i className="fas fa-file-download"></i> Download PDF</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleOnClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                            </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        );
    }
}