import React, { Component } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import { LoadPanel } from 'devextreme-react/load-panel';
import Modal from 'react-modal'; //npm install --save react-modal
import { StatusType, DateFormat, TripStatus } from '../../helpers/fixcodes';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import DateBox from 'devextreme-react/date-box';
import { SelectBox } from 'devextreme-react/select-box';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import { Fragment } from 'react';
import Swal from 'sweetalert2';

const customStyles = {
    content: {
        width: '70%'
    }
};

const position = { of: '#historydiv' };
let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class FlightScheduleComponent extends Component {
    currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
            isSyncData: false,
            isSubmited: false,
            datasource: null,
            loadPanelVisible: false,
            flightFromDate: new Date(currentTime.toLocaleDateString() + " 00:00"),
            flightToDate: new Date(currentTime.toLocaleDateString() + " 23:59"),
            employeeNo: '',
            employeeList: [],
            airlineCode: '',
            IsCurrentDayRecentRequestLog: false,
          currentDate: true,
        }

      //  this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.getFlightScheduleList = this.getFlightScheduleList.bind(this);
        this.getCurrentFlightScheduleList = this.getCurrentFlightScheduleList.bind(this);
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true,
        });
        await this.getEmployeeList();
       await this.getCurrentFlightScheduleList();

        this.setState({
            loadPanelVisible: false,
        });
    }
    //onToolbarPreparing(e) {
    //    e.toolbarOptions.items.unshift({
    //        location: 'after',
    //        widget: 'dxButton',
    //        options: {
    //            icon: 'refresh',
    //            onClick: this.getFlightScheduleList()
    //        }
    //    });
    //}
    closeModal() {
        this.setState({
            flightFromDate: new Date(currentTime.toLocaleDateString() + " 00:00"),
            flightToDate: new Date(currentTime.toLocaleDateString() + " 23:59"),
            airlineCode: '',
            IsCurrentDayRecentRequestLog: true,
            employeeNo: '',
            datasource: null,
        });
    }
    async getEmployeeList() {
        try {
            this.setState({
                loadPanelVisible: true
            });
            var airlineCode = 'QP';
            const requestParams = getRequestParams('GET');
            const response = await fetch('/dispatch-api/employee/' + airlineCode, requestParams);
            const data = await response.json();
             this.setState({
                  employeeList: data.employees,
                    loadPanelVisible: false
                });
             } catch (e) {
            console.log(e);
        }
    }


    async getFlightScheduleList() {
        try {
            this.setState({
                loadPanelVisible: true
            });
            var request = new Object();
            request.airlineCode = 'QP';
            request.EmployeeNo = this.state.employeeNo; //this.state.EmployeeNumber;
            request.FromFlightTime = formatDate(this.state.flightFromDate, 'MM/dd/yyyy hh:mm:ss');
            request.ToFlightTime = formatDate(this.state.flightToDate, 'MM/dd/yyyy hh:mm:ss');
            request.IsCurrentDayRecentRequestLog = false //data == 1 ? this.state.IsCurrentDayRecentRequestLog : true;
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('/dispatch-api/flight-schedule', requestParams);
            const data = await response.json();
            if (data.isSuccess) {
                this.setState({
                    datasource: data.flightSchedules,
                    loadPanelVisible: false
                });
            }
            else {
                this.setState({ datasource: [], loadPanelVisible: false });
                Swal.fire({
                    allowOutsideClick: false,
                    showCloseButton: true,
                    icon: 'error',
                    title: 'Oops...',
                    text: data.resultMessage,
                })
            }
            

        } catch (e) {
            console.log(e);
        }
    }
    async getCurrentFlightScheduleList() {
        try {
            this.setState({
                loadPanelVisible: true
            });
            var request = new Object();
            request.airlineCode = 'QP';
            //request.EmployeeNo = this.state.employeeNo; //this.state.EmployeeNumber;
            request.FromFlightTime = formatDate(this.state.flightFromDate, 'MM/dd/yyyy');
            //   request.ToFlightTime = formatDate(this.state.flightToDate, 'MM/dd/yyyy hh:mm:ss');
            request.IsCurrentDayRecentRequestLog = true; //data == 1 ? this.state.IsCurrentDayRecentRequestLog : true;
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('/dispatch-api/flight-schedule', requestParams);
            const data = await response.json();
            if (data.isSuccess) {
                this.setState({
                    datasource: data.flightSchedules,
                    loadPanelVisible: false
                });
            }
            else {
                this.setState({ datasource: [], loadPanelVisible: false });
                Swal.fire({
                    allowOutsideClick: false,
                    showCloseButton: true,
                    icon: 'success',
                    //title: 'Oops...',
                    text: data.resultMessage,
                })
            }


        } catch (e) {
            console.log(e);
        }
    }

    handleFromValueChanged = (date) => {
        this.setState({ flightFromDate: date.value });
    }
    handleToValueChanged = (date) => {
        this.setState({ flightToDate: date.value });
    }

    handleEmployeeInputChange = (data) => {

        this.setState({ employeeNo: (data.selectedItem != null ? data.selectedItem.userName : ''), })
        this.setState({ airlineCode: (data.selectedItem != null ? data.selectedItem.airlineCode : ''), })
    }

    render() {

        return (
            <Fragment>
                <div className="rez-header">
                 <div className="row">
                            {/*<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">*/}
                            {/*<div className="form-group">*/}
                            {/*    <label> Employee Number </label>*/}
                            {/*    <SelectBox*/}
                            {/*        dataSource={this.state.employeeList}*/}
                            {/*        className="basic-multi-select"*/}
                            {/*        classNamePrefix="select"*/}
                            {/*        placeholder="Select Employee"*/}
                            {/*        name="userName"*/}
                            {/*        defaultValue={0}*/}
                            {/*        value={this.state.employeeNo}*/}
                            {/*        displayExpr='userName'*/}
                            {/*        valueExpr='userName'*/}
                            {/*        onSelectionChanged={this.handleEmployeeInputChange}*/}
                            {/*        showClearButton={true}*/}
                            {/*        searchEnabled={true}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            {/*</div>*/}
                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                            <div className="form-group">
                                <label>Start Date</label>
                                <DateBox type="date"
                                    className="form-control form-control-sm"
                                    name="flightFromDate"
                                    placeholder="MM/dd/yyyy"
                                    displayFormat="MM/dd/yyyy"
                                    showAnalogClock={false}
                                    useMaskBehavior={true}
                                    defaultValue={this.state.flightFromDate}
                                    value={this.state.flightFromDate}
                                    onValueChanged={this.handleFromValueChanged}
                                    width="100%"
                                    disabled={false}
                                    acceptCustomValue={false}
                                />
                            </div>
                        </div>
                        {/*<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">*/}
                        {/*    <div className="form-group">*/}
                        {/*        <label>End Date</label>*/}
                        {/*        <DateBox type="datetime"*/}
                        {/*            className="form-control form-control-sm"*/}
                        {/*            name="flightToDate"*/}
                        {/*            placeholder="MM/dd/yyyy HH:mm"*/}
                        {/*            displayFormat="MM/dd/yyyy HH:mm"*/}
                        {/*            showAnalogClock={false}*/}
                        {/*            useMaskBehavior={true}*/}
                        {/*            defaultValue={this.state.flightToDate}*/}
                        {/*            value={this.state.flightToDate}*/}
                        {/*            onValueChanged={this.handleToValueChanged}*/}
                        {/*            width="100%"*/}
                        {/*            disabled={false}*/}
                        {/*            acceptCustomValue={false}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div className="form-group">
                                <label className="btn-block">&nbsp;</label>
                                {/*<button type="submit" className="btn btn-success btn-sm" onClick={this.getFlightScheduleList} >*/}
                                {/*    {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}*/}
                                {/*    {!this.state.isSubmited && <span title="Search"><i className="fas fa-search"></i> Search</span>}*/}
                                {/*</button>&nbsp; &nbsp;*/}
                                <button type="submit" className="btn btn-success btn-sm" onClick={this.getCurrentFlightScheduleList} >
                                    {this.state.isSyncData && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                    {!this.state.isSyncData && <span title="Search"><i className="fas fa-search"></i> Sync Latest Schedule</span>}
                                </button>&nbsp; &nbsp;
                                <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeModal} title="Clear"><i className="fas fa-eraser"></i> Clear</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="card">
                        <div className="card-body">
                                <DataGrid
                                    dataSource={this.state.datasource}
                                    keyExpr="rowIndex"
                                    selection={{ mode: 'single' }}
                                    columnsAutoWidth={true}
                                    showColumnLines={true}
                                    showRowLines={true}
                                    showBorders={true}
                                    rowAlternationEnabled={true}
                                    allowColumnResizing={true}
                                    columnResizingMode='widget'
                                    showBorders={true}
                                       >

                                    <Column dataField="airportPickUp" caption="AirportPickUp"  allowFiltering={false} allowSorting={false} />
                                    <Column dataField="flightTime" caption="FlightTime" allowFiltering={true} allowSorting={true} format={DateFormat.DateTime24Format} dataType="datetime" />
                                <Column dataField="airlineCode" caption="AirlineCode" allowFiltering={true} allowSorting={false} />
                                <Column dataField="airportCode" caption="AirportCode" allowFiltering={true} allowSorting={true} format={DateFormat.DateTime24Format} dataType="datetime" />
                                <Column dataField="flightNumber" caption="FlightNumber" allowFiltering={true} allowSorting={false} />
                                <Column dataField="deBriefingTime" caption="DeBriefingTime" allowFiltering={true} allowSorting={false} />
                                <Column dataField="dutyReportingTime" caption="DutyReportingTime" allowFiltering={true} allowSorting={false} />
                                <Column dataField="layoverTime" caption="LayoverTime" allowFiltering={true} allowSorting={false} />
                                <Column dataField="employeeNo" caption="EmployeeNo" allowFiltering={true} allowSorting={false} />
                                <Column dataField="firstName" caption="FirstName" allowFiltering={true} allowSorting={false} />
                                <Column dataField="lastName" caption="LastName" allowFiltering={true} allowSorting={false} />
                                <Column dataField="crewGender" caption="CrewGender" allowFiltering={true} allowSorting={false} />
                                <Column dataField="airlineCrewType" caption="AirlineCrewType" allowFiltering={true} allowSorting={false} />
                                <Column dataField="baseStation" caption="BaseStation" allowFiltering={true} allowSorting={false} />

                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={false} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true} />
                                <Export enabled={true} allowExportSelectedData={false} />
                                <ColumnChooser enabled={true} />
                                <ColumnFixing enabled={true} />

                                </DataGrid>
                        </div>
                    </div>

                </div>
                        <LoadPanel
                            shadingColor="rgba(0,0,0,0.4)"
                            position={position}
                            visible={this.state.loadPanelVisible}
                            showIndicator={true}
                            shading={true}
                            showPane={true}
                            closeOnOutsideClick={false}
                        />
                   

            </Fragment>
        );
    }
}