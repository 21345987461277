import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions';
import DateBox from 'devextreme-react/date-box';
import { ReservationHistoryComponent } from '../Shared/ReservationHistoryComponent';
import { MeetAndGreeterComponent } from '../Shared/MeetAndGreeterComponent';
import Swal from 'sweetalert2';

let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class MeetGreetRidesComponent extends Component {

    constructor(props) {
        super(props);

        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            datasource: null,
            startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
            endDate: new Date(),
            refreshDataSource: null,
        }

        this.handleFromDateChanged = this.handleFromDateChanged.bind(this);
        this.handleToDateChanged = this.handleToDateChanged.bind(this);
        this.openEditReservationModel = this.openEditReservationModel.bind(this);
        this.openReservationHistoryModel = this.openReservationHistoryModel.bind(this);
        this.handleResetMeetAndGreeterNameModal = this.handleResetMeetAndGreeterNameModal.bind(this);

        this.handleVoucherReportDownload = this.handleVoucherReportDownload.bind(this);
        this.handlePrintPax = this.handlePrintPax.bind(this);        
        this.handleMeetGreetRidesSubmit = this.handleMeetGreetRidesSubmit.bind(this);
        this.handelToolbarPreparing = this.handelToolbarPreparing.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true,
        });
        
        this.setState({
            loadPanelVisible: false,
        });
    }
    handleFromDateChanged(event) {
        let fromDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ startDate: fromDateValue })
    }
    handleToDateChanged(event) {
        let toDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ endDate: toDateValue })
    }
    handelToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getClosedRidesList.bind(this)
            }
        });
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }
    openEditReservationModel(e, data) {
        e.preventDefault();
        this.props.history.push({
            pathname: '/crudride',
            //state: { detail: data }
            state: { rideId: data.rideId, sourceUrl: "/reservation/list", sourceState: this.state }
        })
    }
    openReservationHistoryModel(e, data) {
        e.preventDefault();
        this.refs.reservationHistoryModal.showModal("Reservation", data.rideId);
    }
    openMeetAndGreeterNameModal = (e, data) => {
        e.preventDefault();
        clearInterval(this.state.stopAutoRefresh);
        clearInterval(this.state.stopAutoRefreshHandle);
        this.refs.meetAndGreeterDataModel.showModal("Set Meet & Greeter Name", data.rideId);
    }
    async handleMeetGreetRidesSubmit(e) {
        await this.getClosedRidesList();
    }
    async askShowFareConfirm() {
        let isResult = false;
        await Swal.fire({
            title: "<small>Fare Confirmation</small>",
            html: "Do you want to print/ show fare details in voucher?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }
    handleResetMeetAndGreeterNameModal = async (e, data) => {
        e.preventDefault();
        let isResult = false;

        await Swal.fire({
            title: 'Confirmation...!',
            html: 'Are you sure to reset the greeter name?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed) {
                isResult = true;
            } else {
                this.handleMeetGreetRidesSubmit();
                this.setState({ isSubmited: false });
            }
        });

        if (isResult) {
            var newObject = new Object();
            newObject.RideId = data.rideId;
            newObject.MGPayTo = 0;
            newObject.MeetGreetId = 0;
            newObject.MeetGreetName = '';
            newObject.MeetGreetAmount = 0;
            newObject.IsSetMeetGreet = false;
            newObject.DispatcherId = parseInt(sessionStorage.getItem("session_userId"));

            const requestParams = getRequestParams('POST', newObject);
            const response = await fetch('meet-greeter-api/SaveUpdate', requestParams);
            const dataResult = await response.json();
            
            Swal.fire({
                icon: dataResult.isSuccess ? 'success' : 'error',
                title: dataResult.isSuccess ? 'Success' : 'Oops...',
                text: dataResult.resultMessage,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (dataResult.isSuccess) {
                    if (result.isConfirmed) {
                        this.handleMeetGreetRidesSubmit();
                        this.setState({ isSubmited: false });
                    }
                } else {
                    this.setState({ isSubmited: false })
                }
            })
        }
    }
    async handleVoucherReportDownload(e, data) {
        e.preventDefault();
        var isShowFare = false;
        const isConfirm = await this.askShowFareConfirm();
        if (!isConfirm) {
            isShowFare = false;
        } else {
            isShowFare = true;
        }

        this.setState({
            //loadPanelVisible: true,
            loadPanelVisible: true,
        });

        var request = new Object();
        request.LocationId = Number(sessionStorage.getItem("session_locationId"));
        request.RideId = parseInt(data.rideId);
        request.IsShowRate = isShowFare;
        request.fileFormat = 'pdf';
        request.MethodName = "GetVoucherReport";
        const requestParams = getRequestParams('POST', request);
        const response = await fetch('crystal-report-api', requestParams);
        const dataResponse = await response.json();
        this.setState({
            // loadPanelVisible: false,
            loadPanelVisible: false,
        });
        let a = document.createElement('a');
        a.href = dataResponse.reportBase64String;
        a.download = "Voucher" + data.rideId + ".pdf";
        a.click();
    }
    async handlePrintPax(e, data) {
        e.preventDefault();

        this.setState({
            //loadPanelVisible: true,
            loadPanelVisible: true,
        });

        var request = new Object();
        request.LocationId = Number(sessionStorage.getItem("session_locationId"));
        request.RideId = parseInt(data.rideId);
        request.fileFormat = 'pdf';
        request.MethodName = "GetPrintPax";
        const requestParams = getRequestParams('POST', request);
        const response = await fetch('crystal-report-api', requestParams);
        const dataResponse = await response.json();
        this.setState({
            // loadPanelVisible: false,
            loadPanelVisible: false,
        });
        let a = document.createElement('a');
        a.href = dataResponse.reportBase64String;
        a.download = "PaxReport.pdf";
        a.click();
    }
    async getClosedRidesList() {
        var request = new Object();
        request.FromDate = formatDate(this.state.startDate, "MM/dd/yyyy hh:mm");;
        request.ToDate = formatDate(this.state.endDate, "MM/dd/yyyy hh:mm");

        this.setState({ isSubmited: true });
        try {
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('/dispatch-api/meetgreet-rides', requestParams);
            const data = await response.json();
            
            this.setState({
                datasource: data.meetGreetRides,
            });

            this.setState({ isSubmited: false });

        } catch (e) {
            this.setState({ isSubmited: false });
        }

    }
    handleClearSearch() {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            datasource: null,
            startDate: new Date(new Date().setDate(new Date().getDate() - 15)),
            endDate: new Date(),
        });
    }
    render() {
        const renderNewResQtCell = (data) => {
            return <div>&nbsp;&nbsp;
                <a href="#" className="fas fa-pen icon text-info" title="View" onClick={(e) => { this.openEditReservationModel(e, data.data) }}></a>&nbsp;&nbsp;
                <a href="#" className="fas fa-history icon text-info" title="History" onClick={(e) => { this.openReservationHistoryModel(e, data.data) }}></a>&nbsp;&nbsp;
                <a href="#" className="fas fa-user-cog" title="Set Meet & Greet" onClick={(e) => { this.openMeetAndGreeterNameModal(e, data.data) }}></a>&nbsp;&nbsp;
                <a href="#" className="fas fa-user-cog" title="Reset Meet & Greet" onClick={(e) => { this.handleResetMeetAndGreeterNameModal(e, data.data) }}></a>&nbsp;&nbsp;
                <a href="#" className="fas fa-print" title="Voucher Report" onClick={(e) => { this.handleVoucherReportDownload(e, data.data) }}></a>&nbsp;&nbsp;
                <a href="#" className="fas fa-print" title="Print Pax Report" onClick={(e) => { this.handlePrintPax(e, data.data) }}></a>&nbsp;&nbsp;
            </div>;
        }
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <h1 className="m-0 text-dark">Meet & Greet Rides</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem><NavLink tag={Link} to="/quote" className="btn btn-block btn-outline-secondary btn-sm" title="New Quotation"><i className="fas fa-plus"></i> New Quotation</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/reservations" className="btn btn-block btn-outline-secondary btn-sm" title="New Reservation"><i className="fas fa-plus"></i> New Reservation</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/reservation/list" className="btn btn-block btn-outline-secondary btn-sm" title="Search Reservation"><i className="fas fa-search"></i> Search Reservation</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/uploadschedule" className="btn btn-block btn-outline-secondary btn-sm" title="Upload Schedule"><i className="fas fa-clipboard-list"></i> Upload Schedule</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rez-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                <div className="form-group">
                                    <label>From Date</label>
                                    <DateBox type="date"
                                        className="form-control form-control-sm"
                                        name="fromDate"
                                        placeholder="mm/dd/yyyy"
                                        useMaskBehavior={true}
                                        defaultValue={this.state.startDate}
                                        value={this.state.startDate}
                                        onValueChanged={this.handleFromDateChanged}
                                        width="100%"
                                        acceptCustomValue={false}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                <div className="form-group">
                                    <label>To Date</label>
                                    <DateBox type="date"
                                        className="form-control form-control-sm"
                                        name="toDate"
                                        placeholder="mm/dd/yyyy"
                                        useMaskBehavior={true}
                                        defaultValue={this.state.endDate}
                                        value={this.state.endDate}
                                        onValueChanged={this.handleToDateChanged}
                                        width="100%"
                                        acceptCustomValue={false}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <div className="form-group">
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 text-right">
                                <div className="form-group">
                                    <label className="btn-block">&nbsp;</label>
                                    <button type="submit" className="btn btn-success btn-sm" onClick={this.handleMeetGreetRidesSubmit} >
                                        {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                        {!this.state.isSubmited && <span title="Search"><i className="fas fa-search"></i> Search</span>}
                                    </button>&nbsp; &nbsp;
                                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleClearSearch} title="Clear"><i className="fas fa-eraser"></i> Clear</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="card card-grid">
                            <div className="card-body">
                                <DataGrid
                                    dataSource={this.state.datasource}
                                    keyExpr="rideId"
                                    selection={{ mode: 'single' }}
                                    columnsAutoWidth={false}
                                    showColumnLines={true}
                                    showRowLines={true}
                                    showBorders={true}
                                    rowAlternationEnabled={true}
                                    showBorders={true}
                                    allowColumnResizing={true}
                                    columnResizingMode='widget'
                                    onToolbarPreparing={this.onToolbarPreparing}>
                                    <Column dataField="rateId" width={130} caption="" allowFiltering={false} visible={true} allowSorting={false} cellRender={renderNewResQtCell} />
                                    <Column dataField="rideId" width={100} allowEditing={false} caption="Ride Id" allowFiltering={true} allowSorting={true} />
                                    <Column dataField="paxName" caption="Pax Name" width={100} allowFiltering={true} allowSorting={true} />
                                    <Column dataField="noofAdults" caption="Pax" width={100} visible={true} allowFiltering={true} allowSorting={true} />
                                    <Column dataField="voucherCompany" width={100} caption="Voucher No" allowFiltering={true} allowSorting={true} />
                                    <Column dataField="airportCode" caption="Airport" width={50} allowFiltering={true} allowSorting={true} />
                                    <Column dataField="charterType" caption="V Type" width={100} allowFiltering={true} allowSorting={true} />
                                    <Column dataField="pickupDateTime" caption="PU Time" width={150} allowFiltering={true} allowSorting={true} />                                    
                                    <Column dataField="flightAirlines" caption="Airline" width={50} allowFiltering={true} allowSorting={true} />
                                    <Column dataField="flightNo" caption="Flight#" width={50} allowFiltering={true} allowSorting={true} />
                                    <Column dataField="puStreet" caption="Pickup" width={200} allowFiltering={true} allowSorting={true} />
                                    <Column dataField="doStreet" caption="Dropoff" width={200} visible={true} allowFiltering={true} allowSorting={true} />
                                    <Column dataField="flightDateTime" caption="F.Time" width={150} allowFiltering={true} allowSorting={true} />
                                    <Column dataField="accountName" caption="A/C Name" width={100} allowFiltering={true} allowSorting={true} />
                                    <Column dataField="meetGreetName" caption="Greeter Name" width={100} allowFiltering={true} allowSorting={true} />
                                    <Column dataField="meetGreetCharge" caption="Charge" width={100} allowFiltering={true} allowSorting={true} />
                                    <Column dataField="meetGreetAmount" caption="Amount" width={100} allowFiltering={true} allowSorting={true} />
                                    <Column dataField="tripStatus" caption="Trip Status" width={100} allowFiltering={true} allowSorting={true} />

                                    <SortByGroupSummaryInfo summaryItem="count" />
                                    <GroupPanel visible={true} />
                                    <HeaderFilter visible={true} />
                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                    <Grouping autoExpandAll={true} />
                                    <Paging defaultPageSize={20} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                    <Export enabled={true} allowExportSelectedData={false} />
                                    <ColumnChooser enabled={false} />
                                    <ColumnFixing enabled={true} />

                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </div>
                <ReservationHistoryComponent ref="reservationHistoryModal" />
                <MeetAndGreeterComponent ref="meetAndGreeterDataModel" refreshDataSource={this.handleMeetGreetRidesSubmit} />
            </div>
        )
    }
}