import React, { Component } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Modal from 'react-modal'; //npm install --save react-modal
import NumberFormat from 'react-number-format';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { MasterHistoryComponent } from '../Shared/MasterHistoryComponent';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react/load-panel';
const MySwal = withReactContent(Swal);
export class DeviceListComponent extends Component {
    constructor() {
        super()
        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            deviceGridDataSource: null,
            deviceData: null,
            dropdownDeviceType: [],
            deviceModalIsOpen: false,
            id: 0,
            countryCode: '',
            mobileNumber: '',
            isActive: true,
            isMappedVehicle: false,
            errors: {
                countryCode: '',
                mobileNumber: ''
            }
        }
    }
    componentDidMount() {
        this.getDeviceList();
    }
    getDeviceList = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            var request = new Object();
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('device-api/search', requestParams);
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    deviceGridDataSource: data.devices
                });
            }

        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    };
    handleToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getDeviceList.bind(this)
            }
        });
    }
    handleOnCellPrepared = (e) => {
        if (e.rowType === "data" && e.column.dataField !== "id") {
            if (e.data.deviceTypeId === null || Number(e.data.deviceTypeId) <= 0) {
                e.cellElement.style.backgroundColor = "#ffcccb";
                //e.cellElement.bgColor = "#FFFFE0";
                //e.cellElement.style.color = "red";
            }
        }
    }
    handleAddDevice = (e) => {
        e.preventDefault();
        this.setState({
            deviceModalIsOpen: true,
            deviceData: null
        });
    }
    handleEditDevice(e, data) {
        e.preventDefault();
        if (data !== null) {
            this.setState({
                deviceModalIsOpen: true,
                deviceData: data
            });
        }
    }
    handleMasterHistoryModal(e, data) {
        e.preventDefault();
        let mobileNumberFormated = '';
        mobileNumberFormated = this.getMobileNumberFormated(data.countryCode, data.mobileNo);
        if (mobileNumberFormated === '') {
            mobileNumberFormated = data.uniqueId === null ? '' : data.uniqueId.trim();
        }
        this.refs.masterHistoryComponent.showModal('device-api', 'Device', mobileNumberFormated, data.id === null ? 0 : Number(data.id));
    }
    handleSendRegistrationCode = async (e, data) => {
        e.preventDefault();
        try {
            let uniqueId = data.uniqueId === null ? '' : data.uniqueId.trim();
            let countryCode = data.countryCode === null ? '' : data.countryCode.replace(/\D/g, '').trim();
            let mobileNumber = data.mobileNo === null ? '' : data.mobileNo.replace(/\D/g, '').trim().substring(0, 10);
            if (uniqueId === '') {
                Swal.fire({
                    allowOutsideClick: false,
                    showCloseButton: true,
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Registation code is missing!',
                })
            }
            else if (mobileNumber === '') {
                Swal.fire({
                    allowOutsideClick: false,
                    showCloseButton: true,
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Mobile number is missing!',
                })
            }
            else {
                let mobileNumberFormated = this.getMobileNumberFormated(countryCode, mobileNumber);
                const isConfirm = await this.isConfirm('send', mobileNumberFormated, uniqueId);
                if (!isConfirm) {
                    return;
                }
                let apiUrl = 'device-api' + '/' + uniqueId + '/send-registration-code/' + countryCode + '/' + mobileNumber;
                const requestParams = getRequestParams('POST');
                const response = await fetch(apiUrl, requestParams);
                const responseData = await response.json();
                Swal.fire({
                    allowOutsideClick: false,
                    showCloseButton: true,
                    icon: responseData.isSuccess ? 'success' : 'error',
                    title: responseData.isSuccess ? 'Success' : 'Oops...',
                    text: (responseData.isSuccess ? 'Registraction code sent to ' : 'Error! has occurred while sending registraction code to ') + mobileNumberFormated,
                })
            }
        } catch (ex) {
            console.log(ex);
            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Unable to process the request.',
            })
        }
    }
    handleDeleteDevice = async (e, data) => {
        e.preventDefault();
        try {
            let countryCode = data.countryCode === null ? '' : data.countryCode.replace(/\D/g, '').trim();
            let mobileNumber = data.mobileNo === null ? '' : data.mobileNo.replace(/\D/g, '').trim().substring(0, 10);
            let mobileNumberFormated = this.getMobileNumberFormated(countryCode, mobileNumber);
            const isConfirm = await this.isConfirm('delete', mobileNumberFormated, '');
            if (!isConfirm) {
                return;
            }
            let id = data.id === null ? 0 : Number(data.id);
            let apiUrl = 'device-api' + '/' + id + '/delete';
            const requestParams = getRequestParams('POST');
            const response = await fetch(apiUrl, requestParams);
            const responseData = await response.json();
            let message = responseData.isSuccess ? (responseData.resultMessage === '' || responseData.resultMessage === null ? 'Device ' + mobileNumberFormated + ' deleted successfully...! ' : responseData.resultMessage) : (responseData.resultMessage === '' || responseData.resultMessage === null ? 'Unable to delete Device ' + mobileNumberFormated : responseData.resultMessage);
            await Swal.fire({
                allowOutsideClick: false,
                icon: responseData.isSuccess ? 'success' : 'error',
                title: responseData.isSuccess ? 'Success' : 'Oops...',
                text: message,
            }).then(async (result) => {
                if (responseData.isSuccess) {
                    if (result.isConfirmed) {
                        await this.getDeviceList();
                    }
                }
            })
        } catch (ex) {
            console.log(ex);
            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            })
        }
    }
    handleAssignDataToState = async () => {
        if (this.state.deviceData === null) {
            this.setState({
                id: 0,
                countryCode: "",
                mobileNumber: "",
                isActive: true,
                isMappedVehicle: false
            });

        } else {
            const device = this.state.deviceData;
            this.setState({
                id: device.id === null ? 0 : Number(device.id),
                countryCode: device.countryCode === null ? '' : device.countryCode.trim(),
                mobileNumber: device.mobileNo === null ? '' : device.mobileNo.trim(),
                isMappedVehicle: Number(device.vehicleId) > 0 ? true : false,
                isActive: device.enable || false,
            });
        }
    }
    handleResetStateData = async () => {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            deviceData: null,
            deviceModalIsOpen: false,
            id: 0,
            countryCode: '',
            mobileNumber: '',
            isActive: true,
            isMappedVehicle: false,
            errors: {
                countryCode: '',
                mobileNumber: ''
            }
        });
    }
    handleDeviceSubmit = async event => {
        event.preventDefault();
        try {
            if (this.isFormValid()) {
                this.setState({ isSubmited: true });

                var request = new Object();
                request.Id = Number(this.state.id);
                request.CountryCode = this.state.countryCode.trim();
                request.MobileNo = this.state.mobileNumber.trim();
                request.Enable = this.state.isActive;
                request.Description = this.setAddEditDeviceHistory();
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('device-api', requestParams);
                const data = await response.json();
                await Swal.fire({
                    allowOutsideClick: false,
                    icon: data.isSuccess ? 'success' : 'error',
                    title: data.isSuccess ? 'Success' : 'Oops...',
                    text: (data.isSuccess === false && (data.resultMessage === null || data.resultMessage === "")) ? 'Unable to process the request.' : data.resultMessage,
                }).then(async (result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            await this.handleResetStateData();
                            await this.getDeviceList();
                        }
                    }
                })
            }
        } catch (ex) {
            console.log(ex);
            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            })
            this.setState({ isSubmited: false })
        }
        this.setState({ isSubmited: false })
    }
    isFormValid() {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.countryCode = '';
            errors.mobileNumber = '';
            if (this.state.countryCode === '') {
                formIsValid = false;
                errors.countryCode = 'Please enter country code.';
            }
            if (this.state.mobileNumber === '') {
                formIsValid = false;
                errors.mobileNumber = 'Please enter mobile no.';
            }
            if (this.state.mobileNumber.trim() !== '' && this.state.mobileNumber.trim().length < 10) {
                formIsValid = false;
                errors.mobileNumber = 'Please enter valid mobile no.';
            }
            this.setState({ errors: errors });
        } catch (ex) {
            formIsValid = false;
            console.log(ex);
        }
        return formIsValid;
    }
    setAddEditDeviceHistory() {
        let history = '';
        try {
            let sep = '. ';
            let oldData = this.state.deviceData;
            if (Number(this.state.id) === 0) {
                let mobileNumberFormated = '';
                mobileNumberFormated = this.getMobileNumberFormated(this.state.countryCode, this.state.mobileNumber);
                history = 'New device [' + mobileNumberFormated + '] is added' + sep;
            }
            else {
                let oldcountryCode = oldData.countryCode === null ? '' : oldData.countryCode.trim();
                if (oldcountryCode !== this.state.countryCode.trim()) {
                    if (oldcountryCode.length > 0 && this.state.countryCode.trim().length > 0) {
                        history += 'Country code changed from ' + oldcountryCode + ' To ' + this.state.countryCode.trim() + sep;
                    }
                    else {
                        history += 'Country code is updated ' + this.state.countryCode.trim() + sep;
                    }
                }
                let oldmobileNo = oldData.mobileNo === null ? '' : oldData.mobileNo.trim();
                if (oldmobileNo !== this.state.mobileNumber.trim()) {
                    if (oldmobileNo.length > 0 && this.state.mobileNumber.trim().length > 0) {
                        history += 'Mobile number changed from ' + oldmobileNo + ' To ' + this.state.mobileNumber.trim() + sep;
                    }
                    else {
                        history += 'Mobile number is updated ' + this.state.mobileNumber.trim() + sep;
                    }
                }
                if (history === '') {
                    let mobileNumberFormated = '';
                    mobileNumberFormated = this.getMobileNumberFormated(this.state.countryCode, this.state.mobileNumber);
                    history += 'Device [' + mobileNumberFormated + '] has been successfully updated' + sep;
                }
            }
        } catch (ex) {
            if (history === '') {
                let mobileNumberFormated = '';
                mobileNumberFormated = this.getMobileNumberFormated(this.state.countryCode, this.state.mobileNumber);
                history += 'Device [' + mobileNumberFormated + '] has been successfully updated';
            }
            console.log(ex);
        }
        return history;
    }
    isConfirm = async (type, mobileNumber, registrationCode) => {
        let isResult = false;
        let confirmMessage = '';
        if (type === 'delete') {
            if (mobileNumber !== '') {
                confirmMessage = 'Are you sure want to delete the device cell number <b>' + mobileNumber + '</b>?';
            }
            else {
                confirmMessage = 'Are you sure want to delete the device?';
            }
        }
        else {
            confirmMessage = 'The registration code <b>' + registrationCode + '</b> will be sent to the mobile number <b>' + mobileNumber + '</b><br>Are you sure want to send?';
        }
        await Swal.fire({
            title: (type === 'delete' ? 'Confirmation' : '<small><b>Sending Message Confirmation</b></small>'),
            html: '<small>' + confirmMessage + '</small>',
            icon: 'question',
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Yes, ' + (type === 'delete' ? 'Delete' : 'Send') + ' it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }
    getMobileNumberFormated = (countryCode, mobileNo) => {
        let mobileNumberFormated = '';
        let countryCodeMobile = '';
        let mobileNumberText = '';
        try {
            if (mobileNo !== null) {
                mobileNumberText = mobileNo.replace(/[ ]/g, '');
                mobileNumberText = mobileNumberText.replace(/\D/g, '').substring(0, 10);
                if (mobileNumberText.length > 6) {
                    mobileNumberFormated = '(' + mobileNumberText.substring(0, 3) + ') ' + mobileNumberText.substring(3, 6) + '-' + mobileNumberText.substring(6, 10);
                }
                else {
                    if (mobileNumberText.length <= 6 && mobileNumberText.length > 3) {
                        mobileNumberFormated = '(' + mobileNumberText.substring(0, 3) + ') ' + mobileNumberText.substring(3, 6) + ' -';
                    }
                    else {
                        if (mobileNumberText.length == 3) {
                            mobileNumberFormated = '(' + mobileNumberText.substring(0, 3) + ') '
                        }
                        else {
                            mobileNumberFormated = mobileNumberText
                        }
                    }
                }
            }
            if (countryCode !== null) {
                countryCodeMobile = countryCode.replace(/\D/g, '');//countryCode.replace(/[+]/g, '');
            }
            if (countryCodeMobile !== null && countryCodeMobile.trim() !== '') {
                mobileNumberFormated = ['+' + countryCodeMobile, mobileNumberFormated].join(' ');
            }
        } catch (e) {
            mobileNumberFormated = [countryCodeMobile, mobileNumberFormated].join(' ');
        }
        return mobileNumberFormated;
    }
    render() {
        const renderGridCell = (data) => {
            return <div>
                <a href="#" title="Edit" onClick={(e) => { this.handleEditDevice(e, data.data) }} ><i className="fas fa-pen"></i></a>&nbsp;&nbsp;
                <a href="#" title="Send Registration Code" onClick={(e) => { this.handleSendRegistrationCode(e, data.data) }}><i className="fas fa-share-square"></i></a>&nbsp;&nbsp;
                <a href="#" title="Delete this device" onClick={(e) => { this.handleDeleteDevice(e, data.data) }}><i className="fas fa-trash-alt"></i></a>&nbsp;&nbsp;
                <a href="#" title="History" onClick={(e) => { this.handleMasterHistoryModal(e, data.data) }}><i className="fas fa-history"></i></a>&nbsp;&nbsp;
            </div>;
        }
        const renderMobileGridCell = (data) => {
            let mobileNumberFormated = '';
            mobileNumberFormated = this.getMobileNumberFormated(data.data.countryCode, data.data.mobileNo);
            let cellColor = (data.data.vehicleId > 0) ? '#008000' : '#999900';
            return <div style={{ color: cellColor }}>{mobileNumberFormated}</div>;
        }
        const renderDeviceImeiGridCell = (data) => {
            let deviceImei = '';
            if (data.data.deviceTypeId !== null && data.data.deviceTypeId > 0) {
                deviceImei = data.data.deviceImei;
            }
            return <div>{deviceImei}</div>;
        }
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <h1 className="m-0 text-dark">Device</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem><NavLink tag={Link} to="#" className="btn btn-block btn-outline-secondary btn-sm" onClick={this.handleAddDevice} title="Add Device"><i className="fas fa-plus"></i> Add Device</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="card card-grid">
                        <div className="card-body">
                            <DataGrid
                                dataSource={this.state.deviceGridDataSource}
                                keyExpr="id"
                                selection={{ mode: 'single' }}
                                columnsAutoWidth={false}
                                showColumnLines={true}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                onToolbarPreparing={this.handleToolbarPreparing}
                                //repaintChangesOnly={true}
                                onCellPrepared={this.handleOnCellPrepared}>
                                <Column dataField="id" width={80} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                <Column dataField="mobileNo" caption="Cell Phone Number" cellRender={renderMobileGridCell} />
                                <Column dataField="uniqueId" caption="Registration Code" />
                                <Column dataField="deviceImei" caption="IMEI / Vendor Id" width={"auto"} cellRender={renderDeviceImeiGridCell} />
                                <Column dataField="vehicleNo" caption="Vehicle Number" />
                                <Column dataField="deviceType" caption="Device Type" />
                                <Column dataField="status" caption="Status" />
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                <Export enabled={true} allowExportSelectedData={false} />
                                <ColumnChooser enabled={true} />
                                <ColumnFixing enabled={true} />
                            </DataGrid>
                        </div>
                    </div>
                </div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.deviceModalIsOpen}
                    style={{ content: { width: '25%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleAssignDataToState}
                    onRequestClose={this.handleResetStateData}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleDeviceSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{this.state.id > 0 ? "Modify Device" : "Add Device"}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleResetStateData}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Country Code <span className="text-danger">*</span></label>
                                        <NumberFormat className="form-control form-control-sm" name="countryCode" title="Country Code" format="+##" value={this.state.countryCode} onValueChange={(e) => { this.setState({ countryCode: e.value }) }} placeholder="Enter code" />
                                        {this.state.errors["countryCode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["countryCode"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                    <div className="form-group">
                                        <label>Mobile Number <span className="text-danger">*</span></label>
                                        <NumberFormat className="form-control form-control-sm" name="mobileNumber" title="Mobile Number" placeholder="(000) 000-0000" format="(###) ###-####" value={this.state.mobileNumber} onValueChange={(e) => { this.setState({ mobileNumber: e.value }) }} />
                                        {this.state.errors["mobileNumber"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["mobileNumber"]}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success btn-sm">
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleResetStateData} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <MasterHistoryComponent ref="masterHistoryComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>

        );
    }
}