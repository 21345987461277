import React, { Component } from 'react';
import { SelectBox } from 'devextreme-react/select-box';
import { getRequestParams } from '../../helpers/globalfunctions';


export class VehicleDriverComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            driversDropdownList: [],
            vehiclesDropdownList: [],
            filterDriverId: 0,
            filterVehicleId: 0,
        }
    }

    async componentDidMount() {
        await this.getDriversDropdownList();
        await this.getVehiclesDropdownList();
    }

    getDriversDropdownList = async () => {
        try {
            const data = JSON.parse(sessionStorage.getItem("session_drivers"));
            if (data === null) {
                const requestParams = getRequestParams('GET', {});
                const response = await fetch('/driver-api/dropdown-all/', requestParams);
                const data = await response.json();
                this.setState({
                    driversDropdownList: data.driversDropdownList
                });
            }
            else {
                this.setState({
                    driversDropdownList: data.driversDropdownList,
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    getVehiclesDropdownList = async () => {
        try {            
            const data = JSON.parse(sessionStorage.getItem("session_vehicles"));
            if (data === null) {
                const requestParams = getRequestParams('GET', {});
                const response = await fetch('/vehicle-api/dropdown-all', requestParams);
                const data = await response.json();
                this.setState({
                    vehiclesDropdownList: data.vehiclesDropdown
                });
            }
            else {
                this.setState({
                    vehiclesDropdownList: data.vehiclesDropdown,
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    }

    handleFilterDriverOnChange = (selectedOption) => {
        this.setState({ filterDriverId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0) })
    }
    handleFilterVehicleOnChange = (selectedOption) => {
        this.setState({ filterVehicleId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0) })
    }

    render() {
        return (
            <React.Fragment>
                <div className="col-lg-6">
                    <div className="form-group">
                        <label htmlFor="">Driver</label>
                        <SelectBox
                            dataSource={this.state.driversDropdownList}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Select driver"
                            name="id"
                            defaultValue={0}
                            value={this.state.filterDriverId}
                            displayExpr='name'
                            valueExpr='id'
                            onSelectionChanged={this.handleFilterDriverOnChange}
                            showClearButton={true}
                            disabled={false}
                            searchEnabled={true}
                        />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <label htmlFor="">Vehicle</label>
                        <SelectBox
                            dataSource={this.state.vehiclesDropdownList}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Select vehicle"
                            name="id"
                            defaultValue={0}
                            value={this.state.filterVehicleId}
                            displayExpr='name'
                            valueExpr='id'
                            onSelectionChanged={this.handleFilterVehicleOnChange}
                            showClearButton={true}
                            disabled={false}
                            searchEnabled={true}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}