import React, { Component } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Scrolling,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import DateBox from 'devextreme-react/date-box';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { SelectBox } from 'devextreme-react/select-box';
import Modal from 'react-modal'; //npm install --save react-modal
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { LoadPanel } from 'devextreme-react/load-panel';
import { StatusType, DateFormat } from '../../helpers/fixcodes';
const customStyles = {
    content: {
        width: '70%'
    }
};
const position = { of: '#historydiv' };
const MySwal = withReactContent(Swal)

let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));

export class VehicleDeviceMappingComponent extends Component {
    constructor(props) {
        super(props);
        let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        this.state = {
            isOpenModal: false,
            isSubmited: false,
            loadPanelVisible: false,
            dataSource: [],
            vehicleId: 0,
            vehicleNo: '',
            accountId: '',
            locationId: '',
            deviceId: 0,
            fromDate: new Date(currentTime.toLocaleDateString()),
            deviceDropdownList: [],
            errors: {
                deviceId: ''
            }
        }
        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleFromValueChanged = this.handleFromValueChanged.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFilterdeviceOnChange = this.handleFilterdeviceOnChange.bind(this);
        this.getVehicleDetails = this.getVehicleDetails.bind(this);
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
    }
    async componentDidMount() {
        await this.getDeviceDropdownList();
    }

    getDeviceDropdownList = async () => {
        try {
          var request = new Object();
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('device-api/search', requestParams);
            const data = await response.json();
            this.setState({
                deviceDropdownList: data.devices
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getVehicleDetails.bind(this)
            }
        });
    }
    closeModal() {
        this.setState({
            isOpenModal: false,
            isSubmited: false,
            loadPanelVisible: false,
            dataSource: [],
            deviceId: 0,
            fromDate: new Date(currentTime.toLocaleDateString()),
            errors: {
                deviceId: ''
            }
        });
    }

    async showModal(_vehicleId, _vehicleNo) {
        this.setState({
            isOpenModal: true,
            loadPanelVisible: false,
            vehicleId: _vehicleId,
            vehicleNo: _vehicleNo,
        });
        await this.getVehicleDetails(_vehicleId, _vehicleNo);

    }
    handleValidation() {
        let formIsValid = true;
        let errors = this.state.errors;
        let isFocus = false;
        errors.deviceId = "";
       
        if (Number(this.state.deviceId) == 0 || this.vehicleTypeSelectValidator.instance.option('selectedItem') === null) {
            formIsValid = false;
            errors.deviceId = "Please select device Id.";
            if (isFocus === false) { this.vehicleTypeSelectValidator.instance.focus(); isFocus = true; }
        }
        this.setState({ errors: errors });
        return formIsValid;
    }


    handleFromValueChanged(date) {
        this.setState({ fromDate: date.value });
    };

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    async getVehicleDetails(_vehicleId, _vehicleNo) {
        try {
            this.setState({ loadPanelVisible: true });
            const requestParams = getRequestParams('GET', "");
            const response = await fetch('vehicleDeviceMapping-api/vehicleid/' + _vehicleId, requestParams);
            const data = await response.json();
            this.setState({
                dataSource: data.list
            });
            this.setState({ loadPanelVisible: false });
        } catch (e) {
            this.setState({ loadPanelVisible: false });
            console.log(e);
        }
    }
    handleFilterdeviceOnChange = (selectedOption) => {
        this.setState({ deviceId: (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0) })
    }
    async handleSubmit(e) {
       e.preventDefault();
        let isSuccess = this.handleValidation();
        if (isSuccess) {
         this.setState({ isSubmited: true });
            var request = new Object();
            request.AccountId = Number(sessionStorage.getItem("session_accountId"));
            request.LocationId = Number(sessionStorage.getItem("session_locationId"));
            request.VehicleId = parseInt(this.state.vehicleId);
            request.VehicleNo = this.state.vehicleNo;
            request.DeviceId = this.state.deviceId;
            request.FromDate = this.state.fromDate;
            try {
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('vehicleDeviceMapping-api', requestParams);
                const data = await response.json();
                Swal.fire({
                    icon: data.isSuccess ? 'success' : 'error',
                    title: data.isSuccess ? 'Success' : 'Oops...',
                    text: data.resultMessage,
                }).then((result) => {
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            this.getVehicleDetails(this.state.vehicleId, this.state.vehicleNo);
                            this.setState({
                                deviceId: 0,
                                fromDate: new Date(),
                            });
                        }
                        this.setState({ isSubmited: false })
                    }
                    else {
                        this.setState({ isSubmited: false })
                    }
                })
            }
         catch (e) {
                this.setState({ isSubmited: false })
            }
        }
    }

    render() {
        const filterSearchFromDate = time => {
            const currentDate = currentTime;
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={customStyles}
                    className={"react-modal"}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                   
                        <div className="modal-header">
                            <h6 className="modal-title">Vehicle-Device Mapping - {this.state.vehicleNo}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <form method="post" onSubmit={this.handleSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                            <div className="rez-header">
                                <div className="container">
                                    <div className="content-header">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                    <div className="form-group">
                                                        <label>Device Id</label>
                                                        <SelectBox
                                                            dataSource={this.state.deviceDropdownList}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            placeholder="Select Device"
                                                            name="id"
                                                            defaultValue={0}
                                                            value={this.state.deviceId}
                                                            displayExpr='deviceImei'
                                                            valueExpr='id'
                                                            onSelectionChanged={this.handleFilterdeviceOnChange}
                                                            showClearButton={true}
                                                            disabled={false}
                                                            searchEnabled={true}
                                                            ref={select => this.vehicleTypeSelectValidator = select }
                                                        />
                                                    {this.state.errors["deviceId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["deviceId"]}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                    <div className="form-group">
                                                        <label> With Effective From</label>
                                                        <DateBox type="datetime"
                                                            className="form-control form-control-sm"
                                                            name="fromDate"
                                                            placeholder="MM/dd/yyyy HH:mm"
                                                            displayFormat="MM/dd/yyyy HH:mm"
                                                            showAnalogClock={false}
                                                            useMaskBehavior={true}
                                                            defaultValue={this.state.fromDate}
                                                            value={this.state.fromDate}
                                                            onValueChanged={this.handleFromValueChanged}
                                                            width="100%"
                                                            disabled={false}
                                                            acceptCustomValue={false}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                    <div className="form-group"><br />
                                                        <button type="submit" className="btn btn-success btn-sm" onClick={this.handleSubmit} >
                                                            {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                                            {!this.state.isSubmited && <span title="Submit"> Map Device</span>}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </div>
                    </form>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="widget-container">
                                        <DataGrid
                                            dataSource={this.state.dataSource}
                                            keyExpr="id"
                                            selection={{ mode: 'single' }}
                                            columnsAutoWidth={false}
                                            showColumnLines={false}
                                            showRowLines={true}
                                            showBorders={true}
                                            rowAlternationEnabled={true}
                                            allowColumnResizing={true}
                                            columnResizingMode='widget'
                                            height={240}>

                                            <Column dataField="vehicleNo" caption="VehicleNo" width={100} allowFiltering={true} allowSorting={true} />
                                            <Column dataField="deviceName" caption="DeviceName" width={160} allowFiltering={true} allowSorting={true} />
                                            <Column dataField="deviceImei" caption="DeviceImei" width={160} allowFiltering={true} allowSorting={true} />
                                            <Column dataField="fromDate" caption="FromDate" width={160} allowFiltering={true} allowSorting={true} format={DateFormat.DateTime24Format} dataType="datetime" />
                                            <Column dataField="toDate" caption="ToDate" width={160} allowFiltering={true} allowSorting={true} format={DateFormat.DateTime24Format} dataType="datetime" />
                                            <Column dataField="createdDate" caption="CreatedDate" width={160} allowFiltering={true} allowSorting={true} format={DateFormat.DateTime24Format} dataType="datetime" />
                                            <Column dataField="updatedDate" caption="UpdatedDate" width={160} allowFiltering={true} allowSorting={true} format={DateFormat.DateTime24Format} dataType="datetime" />
                                            <SortByGroupSummaryInfo summaryItem="count" />
                                            <GroupPanel visible={true} />
                                            <HeaderFilter visible={false} />
                                            <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                            <Grouping autoExpandAll={true} />
                                            <Paging defaultPageSize={10} />
                                            <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20, 50, 100, 500]} showInfo={true} />
                                            <Export enabled={true} allowExportSelectedData={false} />
                                            <ColumnChooser enabled={true} />
                                            <ColumnFixing enabled={true} />
                                            <Scrolling mode="virtual" />
                                            <Paging enabled={false} pageSize={10} />
                                        </DataGrid>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeModal}><i className="fas fa-times"></i> Cancel</button>
                        </div>
                    
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />

            </div>
        )
    }
}


