import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
//https://tomchentw.github.io/react-google-maps/
import Geocode from "react-geocode"; //npm install--save react - geocode
import { GoogleMap, InfoWindow, Marker, withGoogleMap, withScriptjs } from "react-google-maps"; //npm install --save react-google-maps
import Modal from 'react-modal'; //npm install --save react-modal
import NumericInput from 'react-numeric-input'; //npm install react-numeric-input --save
import Swal from 'sweetalert2';
import { getRequestParams } from '../../helpers/globalfunctions.js';


const customStyles = {
    content: {
        width: '70%'
    }
};


const locationTypeData = [
    {
        "id": 1,
        "name": "Hotel",
    },
    {
        "id": 2,
        "name": "Home/Resident",
    },
    {
        "id": 3,
        "name": "Airport",
    },
]

const googleMapURL = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCAiQ31UBwphPPI36Ty_nzZ27QxNx34SXQ&v=3.exp&libraries=geometry,drawing,places";
const loadingElement = <div style={{ height: `100%` }} />;
const containerElement = <div style={{ height: `500px` }} />;
const mapElement = <div style={{ height: `100%` }} />;
const google = window.google;

Geocode.setApiKey("AIzaSyCAiQ31UBwphPPI36Ty_nzZ27QxNx34SXQ");

export class AddEditStopComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpenModal: false,
            isSubmited: false,
            stopId: 0,
            pageName: '',
            stopName: "",
            stopAddress: "",
            stopCode: "",
            stopLatitude: 0,
            stopLongitude: 0,
            stopCity: "",
            stopState: "",
            stopZipCode: "",
            stopActualLocationName: "",
            stopDescription: "",
            stopRadios: 0,
            locationType: 1,
            chkAntiBunching: false,
            chkIsActive: true,

            showAddessWindow: true,
            showAllStop: false,
            allStops: [],

            showInfoIndex: '',
            defaultLatitude: 25.798939,
            defaultLongitude: -80.291409,
            center: {
                lat: 25.798939,
                lng: -80.291409
            },
            zoom: 11,
            errors: {
                stopAddress: "",
                stopName: "",
                locationType: "",
                stopDescription: "",
                stopRadios: "",
            }
        }
        this.showModal = this.showModal.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleNumericInputChange = this.handleNumericInputChange.bind(this);
        this.handleGoogleAddressSearch = this.handleGoogleAddressSearch.bind(this);
        this.handlePlaceSearch = this.handlePlaceSearch.bind(this);
        this.handleStopSubmit = this.handleStopSubmit.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.getStopDetails = this.getStopDetails.bind(this);
        this.extractGoogleAddress = this.extractGoogleAddress.bind(this);

        
        this.onMapDoubleClick = this.onMapDoubleClick.bind(this);
        this.showInfo = this.showInfo.bind(this);
        this.handleCloseCall = this.handleCloseCall.bind(this);
        this.showAddressInfo = this.showAddressInfo.bind(this);
        this.onMarkerDragEnd = this.onMarkerDragEnd.bind(this);
        this.handleCloseAddress = this.handleCloseAddress.bind(this);
    }

    showModal = async (_pageName, _stopId) => {
        try {
            this.setState({ isOpenModal: true, stopId: _stopId, pageName: _pageName });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            var defaultLocation = sessionStorage.getItem("session_locationName");
            Geocode.fromAddress(defaultLocation).then(
                (response) => {
                    const { lat, lng } = response.results[0].geometry.location;
                    this.setState({
                        center: {
                            lat: lat,
                            lng: lng
                        },
                        defaultLatitude: lat,
                        defaultLongitude: lng
                    })
                },
                (error) => {
                    this.setState({
                        center: {
                            lat: 25.798939,
                            lng: -80.291409
                        },
                    })
                }
            );

            this.autocompletePickup = new google.maps.places.Autocomplete(document.getElementById('googleSearch'), { types: ['geocode', 'establishment'] });
            this.autocompletePickup.setFields(
                ['formatted_address', 'geometry', 'name']);
            this.autocompletePickup.addListener("place_changed", this.handleGoogleAddressSearch);

            if (this.state.stopId > 0) {
                await this.getStopDetails();
            }

            

        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {

        this.setState({ isOpenModal: false });
        await this.handleClearSearch();
    }

    handleClearSearch() {
        this.setState({
            isSubmited: false,
            stopId: 0,
            pageName: '',
            stopName: "",
            stopAddress: "",
            stopCode: "",
            stopLatitude: 0,
            stopLongitude: 0,
            stopCity: "",
            stopState: "",
            stopZipCode: "",
            stopActualLocationName: "",
            stopDescription: "",
            stopRadios: 0,
            locationType: 1,
            chkAntiBunching: false,
            chkIsActive: true,

            showAllStop: false,
            allStops: [],

            showInfoIndex: '',
            center: {
                lat: this.state.defaultLatitude,
                lng: this.state.defaultLongitude
            },

            zoom: 11,
            errors: {
                stopAddress: "",
                stopName: "",
                locationType: "",
                stopDescription: "",
                stopRadios: "",
            }
        });
        if (document.getElementById('googleSearch') != null) {
            document.getElementById('googleSearch').value = "";
        }

    }

    async getStopDetails() {
        var shuttleStopsRequest = new Object();
        shuttleStopsRequest.LocationId = Number(sessionStorage.getItem("session_locationId"));

        try {
            const requestParams = getRequestParams('POST', shuttleStopsRequest);
            const response = await fetch('/stop-api/' + this.state.stopId + '/list', requestParams);
            const data = await response.json();
            var stop = data.shuttleStops[0];
            this.setState({
                stopName: stop.locationName,
                stopAddress: stop.address,
                stopCode: stop.locationCode,
                stopLatitude: stop.latitude,
                stopLongitude: stop.longitude,
                stopCity: stop.city,
                stopState: stop.state,
                stopZipCode: stop.zip,
                stopActualLocationName: stop.actualLocationName,
                stopDescription: stop.stopDescription,
                stopRadios: stop.stopRadious,
                locationType: stop.locationType,
                chkAntiBunching: stop.isAntiBunching,
                chkIsActive: stop.isActive,
                center: {
                    lat: stop.latitude,
                    lng: stop.longitude
                },
                zoom:18
            });

        } catch (e) {
            this.setState({ loadPanelVisible: false });
        }
    }
    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.stopName = "";
        errors.stopAddress = "";
        errors.locationType = "";
        errors.stopDescription = "";
        errors.stopRadios = "";

        if (this.state.stopName.trim() == '') {
            formIsValid = false;
            errors.stopName = "Please enter stop name.";
        }
        if (this.state.stopAddress.trim() == '') {
            formIsValid = false;
            errors.stopAddress = "Please enter stop address.";
        }
        if (parseInt(this.state.locationType) == 0) {
            formIsValid = false;
            errors.userType = "Please select location type.";
        }
        if (this.state.stopDescription.trim() == '') {
            formIsValid = false;
            errors.stopDescription = "Please enter stop description.";
        }
        if (parseInt(this.state.stopRadios) == 0) {
            formIsValid = false;
            errors.stopRadios = "Please select stop radios.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    async handleStopSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {

            if (Number(this.state.stopLatitude) == 0 && Number(this.state.stopLongitude) == 0) {
                const isValid = await Swal.fire({
                    title: "<small>Invalid Geolocation</small>",
                    text: "Geoloaction not found. Please add stop on the map!",
                    icon: "info",
                    dangerMode: false,
                    width: "25%",
                    dangerMode: false,
                    allowOutsideClick: false
                });
                return;
            }
            this.setState({ isSubmited: true });

            var newObject = new Object();
            newObject.ShuttleStops = new Object();
            newObject.ShuttleStops.StopId = this.state.stopId;
            newObject.ShuttleStops.LocationName = this.state.stopName;
            newObject.ShuttleStops.LocationCode = this.state.stopCode;
            newObject.ShuttleStops.IsService = true;
            newObject.ShuttleStops.CityCode = "";
            newObject.ShuttleStops.Latitude = Number(this.state.stopLatitude);
            newObject.ShuttleStops.Longitude = Number(this.state.stopLongitude);
            newObject.ShuttleStops.LocationType = Number(this.state.locationType);
            newObject.ShuttleStops.IsTerminal = false;
            newObject.ShuttleStops.IsActive = this.state.stopId > 0 ? this.state.chkIsActive : true;
            newObject.ShuttleStops.Sequence = 0;
            newObject.ShuttleStops.Address = this.state.stopAddress;
            newObject.ShuttleStops.City = this.state.stopCity;
            newObject.ShuttleStops.State = this.state.stopState;
            newObject.ShuttleStops.Zip = this.state.stopZipCode;
            newObject.ShuttleStops.FavouriteName = this.state.stopName;
            newObject.ShuttleStops.StopDescription = this.state.stopDescription;
            newObject.ShuttleStops.StopRadious = Number(this.state.stopRadios);
            newObject.ShuttleStops.ActualLocationName = this.state.stopActualLocationName;
            newObject.ShuttleStops.IsAntiBunching = this.state.chkAntiBunching;


            try {
                const requestParams = getRequestParams('POST', newObject);
                const response = await fetch('stop-api/save-update', requestParams);
                const data = await response.json();

                Swal.fire({
                    icon: data.isSuccess ? 'success' : 'error',
                    title: data.isSuccess ? 'Success' : 'Oops...',
                    text: data.resultMessage,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            this.props.getStopList();
                            this.handleOnCloseModal();
                        }
                    } else {
                        this.setState({ isSubmited: false })
                    }
                })
            } catch (e) {
                this.setState({ isSubmited: false })
            }
        }
    }

    handleGoogleAddressSearch() {
        let place = this.autocompletePickup.getPlace();
        var placeObj = new Object();
        if (place !== null && place.formatted_address !== null && place.formatted_address !== "") {
            placeObj = new Object();
            placeObj = this.extractGoogleAddress(place.geometry.location.lat(), place.geometry.location.lng(), place.name, place.formatted_address)
        }

        if (placeObj !== null) {
            this.setState({
                stopName: placeObj.location,
                stopAddress: placeObj.address1,
                stopLatitude: placeObj.latitude,
                stopLongitude: placeObj.longitude,
                stopCity: placeObj.cityName,
                stopState: placeObj.stateName,
                stopZipCode: placeObj.zipCode,
                stopActualLocationName: placeObj.fullAddress,
                center: {
                    lat: placeObj.latitude,
                    lng: placeObj.longitude
                },
                zoom: 18,
            });

        } else {
            this.setState({
                stopName: "",
                stopAddress: "",
                stopLatitude: 0,
                stopLongitude: 0,
                stopCity: "",
                stopState: "",
                stopZipCode: "",
                stopActualLocationName: "",
                center: {
                    lat: this.state.defaultLatitude,
                    lng: this.state.defaultLongitude
                },
                zoom: 11,
            });
        }

        this.state.errors["stopAddress"] = "";
        this.state.errors["stopName"] = "";
        this.state.errors["locationType"] = "";
        this.state.errors["stopDescription"] = "";
        this.state.errors["stopRadios"] = "";
    }

    handlePlaceSearch(lat, lng, placeName, address) {
        var placeObj = new Object();
        if (address !== null && address !== "") {
            placeObj = new Object();
            placeObj = this.extractGoogleAddress(lat, lng, placeName, address)
        }
        if (placeObj !== null) {
            this.setState({
                stopName: placeObj.location,
                stopAddress: placeObj.address1,
                stopLatitude: placeObj.latitude,
                stopLongitude: placeObj.longitude,
                stopCity: placeObj.cityName,
                stopState: placeObj.stateName,
                stopZipCode: placeObj.zipCode,
                stopActualLocationName: placeObj.fullAddress,
                center: {
                    lat: placeObj.latitude,
                    lng: placeObj.longitude
                },
                zoom: 18,
            });

        } else {
            this.setState({
                stopName: "",
                stopAddress: "",
                stopLatitude: 0,
                stopLongitude: 0,
                stopCity: "",
                stopState: "",
                stopZipCode: "",
                stopActualLocationName: "",
                center: {
                    lat: this.state.defaultLatitude,
                    lng: this.state.defaultLongitude
                },
                zoom: 11,
            });
        }

        this.state.errors["stopAddress"] = "";
        this.state.errors["stopName"] = "";
        this.state.errors["locationType"] = "";
        this.state.errors["stopDescription"] = "";
        this.state.errors["stopRadios"] = "";
    }

    extractGoogleAddress(lat, lng, placeName, address) {
        var placeObject = new Object();
        if (address !== null && address !== null && address !== "") {
            placeObject.location = placeName;
            placeObject.latitude = lat;
            placeObject.longitude = lng;

            placeObject.fullAddress = address;
            var splitAddress = [];

            splitAddress = address.replace(/-/g, ',').split(',');
            if (splitAddress.length > 0) {
                if (splitAddress.length == 1) {
                    placeObject.address1 = splitAddress[0];
                    placeObject.cityName = "";
                    placeObject.zipCode = "";
                    placeObject.stateName = "";
                    placeObject.countryName = "";
                }
                else if (splitAddress.length == 2) {
                    placeObject.address1 = splitAddress[0];
                    placeObject.cityName = splitAddress[0];
                    placeObject.stateName = splitAddress[1].trim().search(' ') > 0 ? splitAddress[1].trim().split(' ')[0] : splitAddress[1].trim();
                    placeObject.zipCode = splitAddress[1].trim().search(' ') > 0 ? splitAddress[1].trim().split(' ')[1] : " ";
                    placeObject.countryName = "";
                }
                else if (splitAddress.length == 3) {
                    placeObject.address1 = splitAddress[0] + ' ' + splitAddress[1].trim();
                    placeObject.cityName = splitAddress[1].trim();
                    placeObject.stateName = splitAddress[2].trim().search(' ') > 0 ? splitAddress[2].trim().split(' ')[0] : splitAddress[2].trim();
                    placeObject.zipCode = splitAddress[2].trim().search(' ') > 0 ? splitAddress[2].trim().split(' ')[1] : " ";
                    placeObject.countryName = "";
                }
                else if (splitAddress.length == 4) {
                    placeObject.address1 = splitAddress[0] + ' ' + splitAddress[1].trim();
                    placeObject.cityName = splitAddress[1].trim();
                    placeObject.stateName = splitAddress[2].trim().search(' ') > 0 ? splitAddress[2].trim().split(' ')[0] : splitAddress[2].trim();
                    placeObject.zipCode = splitAddress[2].trim().search(' ') > 0 ? splitAddress[2].trim().split(' ')[1] : " ";
                    placeObject.countryName = splitAddress[3].trim();
                }
                else if (splitAddress.length == 5) {
                    placeObject.address1 = splitAddress[0] + ' ' + splitAddress[1].trim() + ' ' + splitAddress[2].trim();
                    placeObject.cityName = splitAddress[2].trim();
                    placeObject.stateName = splitAddress[3].trim().search(' ') > 0 ? splitAddress[3].trim().split(' ')[0] : splitAddress[3].trim();
                    placeObject.zipCode = splitAddress[3].trim().search(' ') > 0 ? splitAddress[3].trim().split(' ')[1] : " ";
                    placeObject.countryName = splitAddress[4].trim();
                }
                else if (splitAddress.length == 6) {
                    placeObject.address1 = splitAddress[0] + ' ' + splitAddress[1].trim() + ' ' + splitAddress[2].trim() + ' ' + splitAddress[3].trim();
                    placeObject.cityName = splitAddress[3].trim();
                    placeObject.stateName = splitAddress[4].trim().search(' ') > 0 ? splitAddress[4].trim().split(' ')[0] : splitAddress[4].trim();
                    placeObject.zipCode = splitAddress[4].trim().search(' ') > 0 ? splitAddress[4].trim().split(' ')[1] : " ";
                    placeObject.countryName = splitAddress[5].trim();
                }
                else {
                    placeObject.address1 = splitAddress[0] + ' ' + splitAddress[1].trim() + ' ' + splitAddress[2].trim() + ' ' + splitAddress[3].trim() + ' ' + splitAddress[4].trim();
                    placeObject.cityName = splitAddress[4].trim();
                    placeObject.stateName = splitAddress[5].trim().search(' ') > 0 ? splitAddress[5].trim().split(' ')[0] : splitAddress[5].trim();
                    placeObject.zipCode = splitAddress[5].trim().search(' ') > 0 ? splitAddress[5].trim().split(' ')[1] : " ";
                    placeObject.countryName = splitAddress[6].trim();
                }
            }
        }
        return placeObject;
    }

    async handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
        this.state.errors[name] = "";

        if (name == "showAllStop" && value == true) {
            this.setState({ loadPanelVisible: true });
            var shuttleStopsRequest = new Object();
            shuttleStopsRequest.LocationId = Number(sessionStorage.getItem("session_locationId"));

            try {
                const requestParams = getRequestParams('POST', shuttleStopsRequest);
                const response = await fetch('/stop-api/0/list', requestParams);
                const data = await response.json();
                this.setState({
                    allStops: data.shuttleStops != null ? data.shuttleStops.filter(f => f.isActive == true) : []
                });

            } catch (e) {
                this.setState({ loadPanelVisible: false });
            }
        }
    }

    handleNumericInputChange(value) {
        this.setState({
            stopRadios: value
        });
        this.state.errors["stopRadios"] = ""

    }



    showInfo(a) {
        this.setState({ showInfoIndex: a })
    }
    handleCloseCall() {
        this.setState({ showInfoIndex: '' })
    }

    showAddressInfo() {
        this.setState({ showAddessWindow: true })
    }

    onMarkerDragEnd(event) {
        let newLat = event.latLng.lat(),
            newLng = event.latLng.lng();
        this.setState({
            stopLatitude: newLat,
            stopLongitude: newLng,
        });

        Geocode.fromLatLng(newLat, newLng).then(
            response => {
                const address = response.results[0].formatted_address;
                this.handlePlaceSearch(newLat, newLng, "", address);
            },
            error => {

            }
        );
    }

    handleCloseAddress() {
        this.setState({ showAddessWindow: false })
    }

    onMapDoubleClick(event) {
        let newLat = event.latLng.lat(),
            newLng = event.latLng.lng();
        this.setState({
            stopLatitude: newLat,
            stopLongitude: newLng,
        });

        Geocode.fromLatLng(newLat, newLng).then(
            response => {
                const address = response.results[0].formatted_address;
                this.handlePlaceSearch(newLat, newLng, "", address);
            },
            error => {

            }
        );
    }
    

    render() {
        const modalTitle = this.state.stopId > 0 ? "Modify Stop" : "New Stop";
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={customStyles}
                    className={"react-modal"}
                    shouldCloseOnOverlayClick={false}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleStopSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{modalTitle}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-10">
                                    Note: <span style={{ color: "darkblue" }}><i> Double click on map or enter address in text field (Google Search) to add new stop. Use drag feature to adjust actual stop address on the map.</i></span>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-2">
                                    <span className="form-check ml-3">
                                        <input className="form-check-input" type="checkbox" checked={this.state.showAllStop} onChange={this.handleInputChange} name="showAllStop" />
                                        <label className="form-check-label">Show All Stop</label>
                                    </span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                    <div className="col-xs-12 form-group" >
                                        <label>Google Search<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" id="googleSearch" name="googleSearch" placeholder="'Type' slowly to search stop" maxLength="150" title="Google Search" />
                                    </div>
                                    <div className="col-xs-12 form-group">
                                        <label>Terminal Type</label>
                                        <select className="form-control form-control-sm" name="locationType" value={this.state.locationType} onChange={this.handleInputChange} >
                                            <option value="0">Select Location Type</option>
                                            {
                                                locationTypeData &&
                                                locationTypeData.map((item, i) =>
                                                    (<option key={i} value={item.id}>{item.name}</option>))
                                            }
                                        </select>
                                        {this.state.errors["locationType"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["locationType"]}</span>}
                                    </div>
                                    <div className="col-xs-12 form-group" >
                                        <label>Stop Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="stopName" placeholder="Stop Name" value={this.state.stopName} maxLength="100" title="Stop Name" onChange={this.handleInputChange} />
                                        {this.state.errors["stopName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["stopName"]}</span>}
                                    </div>
                                    <div className="col-xs-12 form-group" >
                                        <label>Stop Address <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="stopAddress" placeholder="Stop Address" value={this.state.stopAddress} maxLength="200" title="Stop Address" onChange={this.handleInputChange} />
                                        {(this.state.stopLatitude != 0 && this.state.stopLongitude != 0) && <span> {this.state.stopLatitude + ", " + this.state.stopLongitude}</span>}
                                        {this.state.errors["stopAddress"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["stopAddress"]}</span>}
                                    </div>
                                    <div className="col-xs-12 form-group" >
                                        <label>Stop Description <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="stopDescription" value={this.state.stopDescription} placeholder="Stop Description" maxLength="250" title="Stop Description" onChange={this.handleInputChange} />
                                        {this.state.errors["stopDescription"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["stopDescription"]}</span>}
                                    </div>
                                    <div className="col-xs-12 form-group" >
                                        <div className="form-group">
                                            <label>Stop Radios <span className="text-danger">*</span></label>
                                            <NumericInput className="form-control form-control-sm" name="stopRadios" title="Stop Radios" value={this.state.stopRadios} min={0} max={500} onChange={(value) => { this.handleNumericInputChange(value) }} />
                                            {this.state.errors["stopRadios"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["stopRadios"]}</span>}
                                        </div>
                                    </div>
                                    <div className="col-xs-6 form-group" >
                                        <div className="form-group">
                                            <span className="form-check">
                                                <input className="form-check-input" type="checkbox" checked={this.state.chkAntiBunching} onChange={this.handleInputChange} name="chkAntiBunching" />
                                                <label className="form-check-label">Anti Bunching</label>
                                            </span>
                                        </div>
                                    </div>
                                    {(this.state.stopId > 0) && <div className="col-xs-6 form-group" >
                                        <div className="form-group">
                                            <span className="form-check">
                                                <input className="form-check-input" type="checkbox" checked={this.state.chkIsActive} onChange={this.handleInputChange} name="chkIsActive" />
                                                <label className="form-check-label">Is Active</label>
                                            </span>
                                        </div>
                                    </div>}
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-9">
                                    <div className="map-container">
                                        <Map
                                            googleMapURL={window.google.toString()}
                                            loadingElement={loadingElement}
                                            containerElement={containerElement}
                                            mapElement={mapElement}
                                            onMapDoubleClick={this.onMapDoubleClick}

                                            latitude={this.state.stopLatitude}
                                            longitude={this.state.stopLongitude}
                                            stopActualLocationName={this.state.stopActualLocationName}

                                            defaultCenter={this.state.center}
                                            defaultZoom={this.state.zoom}

                                            showAddessWindow={this.state.showAddessWindow}
                                            showAddressInfo={this.showAddressInfo}
                                            onMarkerDragEnd={this.onMarkerDragEnd}
                                            handleCloseAddress={this.handleCloseAddress}

                                            showAllStop={this.state.showAllStop}
                                            allStops={this.state.allStops}
                                            showInfo={this.showInfo}
                                            showInfoIndex={this.state.showInfoIndex}
                                            handleCloseCall={this.handleCloseCall}


                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleStopSubmit} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleOnCloseModal}><i className="fas fa-times"></i> Cancel</button>
                        </div>
                    </form>
                </Modal>
            </div>
        );
    }
}

const Map = withScriptjs(

    withGoogleMap(props => (

        <GoogleMap
            center={props.defaultCenter}
            zoom={props.defaultZoom}
            onDblClick={props.onMapDoubleClick}
            defaultOptions={{
                mapTypeControl: true,
                streetViewControl: true,
                zoomControl: true,
                fullscreenControl: false,
                minZoom: 2,
                maxZoom: 18,
                gestureHandling: "greedy",
                disableDefaultUI: true
            }}
        >
            {(props.latitude != 0 && props.longitude != 0) && <Marker
                position={{ lat: parseFloat(props.latitude), lng: parseFloat(props.longitude) }}
                onClick={() => { props.showAddressInfo() }}
                draggable={true}
                onDragEnd={props.onMarkerDragEnd}
            >
                {(props.showAddessWindow) &&
                    <InfoWindow onCloseClick={props.handleCloseAddress} >
                        <div>
                            <span>{props.stopActualLocationName}</span>
                        </div>
                    </InfoWindow>}
            </Marker>}

            {(props.showAllStop == true && props.allStops.length > 0) && props.allStops.map((marker, index) =>
                <Marker
                    key={index}
                    position={{ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) }}
                    onClick={() => { props.showInfo(index) }}
                >

                    {(props.showInfoIndex !== '') && (props.showInfoIndex == index) &&
                        <InfoWindow onCloseClick={props.handleCloseCall} options={{ maxWidth: 100 }} >
                            <div>
                                <span>{marker.actualLocationName}</span>
                            </div>
                        </InfoWindow>}
                </Marker>
            )}


        </GoogleMap>
    ))
);
