import React, { Component } from 'react';
import DataGrid, {
    Column,
    FilterRow, Paging, Scrolling, Selection
} from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import DropDownBox from 'devextreme-react/drop-down-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import Modal from 'react-modal'; //npm install --save react-modal
import { getRequestParams } from '../../helpers/globalfunctions.js';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import { SelectBox } from 'devextreme-react/select-box';
import DateBox from 'devextreme-react/date-box';
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'
const tripStatusLst = [{
    id: 1,
    name: 'Pending'
}, {
    id: 2,
    name: 'On Job'
}, {
    id: 3,
    name: 'Completed'
}];
let currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class DriverDutyReportCompnent extends Component {
    constructor() {
        currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        super()
        this.state = {
            isSubmitted: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            driversDropdownList: [],
            driverDropdownData: [],
            driverId: 0,
            isAllDriver: true,
            isSingleDriver: false,
            tripStatusDropdownList: [],
            tripStatusDropdownData: null,
            isAllTripStatus: true,
            isSingleTripStatus: false,
            fromPickupDate: moment(currentTime).format("MM/DD/YYYY"),
            toPickupDate: moment(currentTime).format("MM/DD/YYYY"),
            errors: {
                driver: '',
                tripStatus: '',
                fromPickupDate: '',
                toPickupDate: '',
            }
        }
    }
    getDriverDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('driver-api/dropdown-all/', requestParams);
            const data = await response.json();
            this.setState({
                driversDropdownList: data.driversDropdownList
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    getRecordTypeDropdownList = async () => {
        try {
            this.setState({
                tripStatusDropdownList: tripStatusLst
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getDriverDropdownList();
            await this.getRecordTypeDropdownList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isEmailSubmitted: false,
                isOpenModal: false,
                loadPanelVisible: false,
                driversDropdownList: [],
                driverDropdownData: null,
                driverId: 0,
                isAllDriver: true,
                isSingleDriver: false,
                tripStatusDropdownList: [],
                tripStatusDropdownData: null,
                isAllTripStatus: true,
                isSingleTripStatus: false,
                fromPickupDate: moment(currentTime).format("MM/DD/YYYY"),
                toPickupDate: moment(currentTime).format("MM/DD/YYYY"),
                errors: {
                    driver: '',
                    tripStatus: '',
                    fromPickupDate: '',
                    toPickupDate: '',
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnClearSearch = async () => {
        try {
            currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
            this.setState({
                isSubmitted: false,
                isEmailSubmitted: false,
                loadPanelVisible: false,
                driverDropdownData: null,
                driverId: 0,
                isAllDriver: true,
                isSingleDriver: false,
                tripStatusDropdownData: null,
                isAllTripStatus: true,
                isSingleTripStatus: false,
                fromPickupDate: moment(currentTime).format("MM/DD/YYYY"),
                toPickupDate: moment(currentTime).format("MM/DD/YYYY"),
                errors: {
                    driver: '',
                    tripStatus: '',
                    fromPickupDate: '',
                    toPickupDate: '',
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnInputChange = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
            let errors = this.state.errors;
            switch (name) {
                case 'isAllDriver':
                    errors.driver = '';
                    this.setState({ isAllDriver: value, isSingleDriver: false, driverId: 0, driverDropdownData: null });
                    break;
                case 'isSingleDriver':
                    errors.driver = '';
                    this.setState({ isAllDriver: false, isSingleDriver: true, driverId: 0, driverDropdownData: null });
                    break;
                case 'isAllTripStatus':
                    errors.tripStatus = '';
                    this.setState({ isAllTripStatus: value, isSingleTripStatus: false, tripStatusDropdownData: null });
                    break;
                case 'isSingleTripStatus':
                    errors.tripStatus = '';
                    this.setState({ isAllTripStatus: false, isSingleTripStatus: value, tripStatusDropdownData: null });
                    break;
                default:
                    break;
            }
            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnFromPickupDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.fromPickupDate = '';
            let fromPickupDate = '';
            if (event.value !== null && event.value !== '') {
                fromPickupDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.fromDate = 'Please select date.';
            }
            if (this.state.toPickupDate.trim() !== '') {
                errors.toPickupDate = '';
            }
            this.setState({ fromPickupDate: fromPickupDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnToPickupDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.toPickupDate = '';
            let toPickupDate = '';
            if (event.value !== null && event.value !== '') {
                toPickupDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.toDate = 'Please select date.';
            }
            if (this.state.fromPickupDate.trim() !== '') {
                errors.fromPickupDate = '';
            }
            this.setState({ toPickupDate: toPickupDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnDriverDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.driver = '';
            let driverId = 0;
            let driverDropdownData = null;
            if (data !== null && data.selectedItem !== null) {
                driverId = (data.selectedItem !== null ? data.selectedItem.id : 0)
                driverDropdownData = data.selectedItem;
            }
            else {
                //errors.driver = 'Please select driver.';
            }
            this.setState({ driverDropdownData: driverDropdownData, driverId: driverId, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnReportSubmit = async (event) => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isSubmitted: true });
                let driverId = 0;
                let fromPickupDate = '';
                let toPickupDate = '';
                let tripStatus = '';
                if (this.state.isSingleDriver === true) {
                    driverId = Number(this.state.driverId);
                }
                if (this.state.isSingleTripStatus === true) {
                    if (this.state.tripStatusDropdownData !== null) {
                        if (Object.keys(this.state.tripStatusDropdownData).length > 0) {
                            for (var i = 0; i < this.state.tripStatusDropdownData.length; i++) {
                                tripStatus += tripStatus === '' ? this.state.tripStatusDropdownData[i] : ',' + this.state.tripStatusDropdownData[i]
                            }
                        }
                    }
                }
                if (this.state.fromPickupDate.trim() !== '' && this.state.toPickupDate.trim() !== '') {
                    fromPickupDate = moment(this.state.fromPickupDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00';
                    toPickupDate = moment(this.state.toPickupDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59';
                }
                var request = {};
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.DriverId = driverId;
                request.Criteria1 = tripStatus;
                request.StartDate = fromPickupDate;
                request.EndDate = toPickupDate;
                request.fileFormat = 'pdf';
                request.MethodName = "GetDriverDutyReport";
                request.SelectionCrieteria = await this.setSelectionCrieteria();
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                let a = document.createElement('a');
                a.href = dataResponse.reportBase64String;
                a.download = 'DriverDutySummary.pdf';
                a.click();
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false });
    }
    isFormValid = async () => {
        let isValid = true;
        try {
            let errors = this.state.errors;
            errors.driver = '';
            errors.tripStatus = '';
            errors.fromPickupDate = '';
            errors.toPickupDate = '';
            if (this.state.isSingleDriver === true) {
                if (Number(this.state.driverId) === 0 || isNaN(this.state.driverId)) {
                    errors.driver = 'Please select driver.';
                    isValid = false;
                }
            }
            if (this.state.isSingleTripStatus === true) {
                if (this.state.tripStatusDropdownData === null || this.state.tripStatusDropdownData.length === 0) {
                    errors.tripStatus = 'Please select trip status.';
                    isValid = false;
                }
            }
            if (this.state.fromPickupDate.trim() === '') {
                errors.fromPickupDate = 'Please select date.';
                isValid = false;
            }
            if (this.state.toPickupDate.trim() === '') {
                errors.toPickupDate = 'Please select date.';
                isValid = false;
            }
            if (this.state.fromPickupDate.trim() !== '' && this.state.toPickupDate.trim() !== '') {
                if (moment(this.state.fromPickupDate, 'MM/DD/YYYY').isAfter(this.state.toPickupDate, 'MM/DD/YYYY')) {
                    errors.fromPickupDate = 'Please enter a valid date range.';
                    errors.toPickupDate = 'Please enter a valid date range.';
                    isValid = false;
                }
            }
            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    setSelectionCrieteria = async () => {
        let selectionCrieteria = 'Driver duty report for';
        try {
            if (this.state.isSingleDriver === true) {
                if (this.state.driverDropdownData !== null && Object.keys(this.state.driverDropdownData).length > 0) {
                    selectionCrieteria += ' driver - [ ' + this.state.driverDropdownData.name + ' ]';
                }
                else {
                    selectionCrieteria += ' all drivers';
                }
            }
            else {
                selectionCrieteria += ' all drivers';
            }

            if (this.state.isSingleTripStatus === true) {
                if (this.state.tripStatusDropdownData === null || this.state.tripStatusDropdownData.length === 0) {
                    selectionCrieteria += ', all trips';
                }
                else {
                    selectionCrieteria += ' , for trip status - [ ';
                    for (var i = 0; i < this.state.tripStatusDropdownData.length; i++) {
                        let tripStatusId = this.state.tripStatusDropdownData[i];
                        let tripStatus = this.state.tripStatusDropdownList.filter(f => f.id === tripStatusId)[0].name;
                        selectionCrieteria += tripStatus;
                        if (i < this.state.tripStatusDropdownData.length - 1) {
                            selectionCrieteria += ', ';
                        }
                    }
                    selectionCrieteria += ' ]';
                }
            }
            else {
                selectionCrieteria += ', all trips';
            }
            if (this.state.fromPickupDate.trim() !== '' && this.state.toPickupDate.trim() !== '') {
                selectionCrieteria += ', pick-up period from  - ' + this.state.fromPickupDate + ' to ' + this.state.toPickupDate;
            }
            else {
                selectionCrieteria += ', all pick-up periods';
            }
        } catch (ex) {
            selectionCrieteria = '';
            console.log(ex);
        }
        return selectionCrieteria;
    }
    handleOnSendEmail = async event => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isEmailSubmitted: true });
                let driverId = 0;
                let fromPickupDate = '';
                let toPickupDate = '';
                let tripStatus = '';
                if (this.state.isSingleDriver === true) {
                    driverId = Number(this.state.driverId);
                }
                if (this.state.isSingleTripStatus === true) {
                    if (this.state.tripStatusDropdownData !== null) {
                        if (Object.keys(this.state.tripStatusDropdownData).length > 0) {
                            for (var i = 0; i < this.state.tripStatusDropdownData.length; i++) {
                                tripStatus += tripStatus === '' ? this.state.tripStatusDropdownData[i] : ',' + this.state.tripStatusDropdownData[i]
                            }
                        }
                    }
                }
                if (this.state.fromPickupDate.trim() !== '' && this.state.toPickupDate.trim() !== '') {
                    fromPickupDate = moment(this.state.fromPickupDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00';
                    toPickupDate = moment(this.state.toPickupDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59';
                }
                var request = {};
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.DriverId = driverId;
                request.Criteria1 = tripStatus;
                request.StartDate = fromPickupDate;
                request.EndDate = toPickupDate;
                request.fileFormat = 'pdf';
                request.MethodName = "GetDriverDutyReport";
                request.SelectionCrieteria = await this.setSelectionCrieteria();
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
                    let emailSubject = 'Driver duty report Summary - ' + moment(currentTime).format("DD MMMM YYYY HH:mm");
                    let attachments = [];
                    let file = {};
                    file.fileData = fileData;
                    file.fileName = 'DriverDutySummary.pdf';
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    handleOnSelectionTripStatusChanged = async (e) => {
        let errors = this.state.errors;
        errors.tripStatus = '';
        try {
            this.setState({
                tripStatusDropdownData: e.selectedRowKeys.length && e.selectedRowKeys || [],
                errors: errors
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnSyncDataGridTripStatusSelection = async (e) => {
        try {
            this.setState({
                tripStatusDropdownData: e.value || []
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    dataGridTripStatusRender = () => {
        return (
            <DataGrid
                dataSource={this.state.tripStatusDropdownList}
                id="grid-container"
                keyExpr="id"
                hoverStateEnabled={true}
                selectedRowKeys={this.state.tripStatusDropdownData}
                onSelectionChanged={this.handleOnSelectionTripStatusChanged}
                ref={ref => this.dataGrid = ref}>
                <Column dataField="name" caption="Trip Status" />
                <Selection mode="multiple" />
                <Scrolling mode="virtual" />
                <Paging enabled={true} pageSize={10} />
                <FilterRow visible={true} />
            </DataGrid>
        );
    }
    render() {
        return (
            <>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '35%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleOnReportSubmit}
                        style={(this.state.isSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Driver Duty Report'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">�</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                id="isAllDriver" name="isAllDriver"
                                                checked={this.state.isAllDriver}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isAllDriver"> All Drivers</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                id="isSingleDriver" name="isSingleDriver"
                                                checked={this.state.isSingleDriver}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isSingleDriver"> Select Driver</label>
                                        </div>
                                        <SelectBox
                                            dataSource={this.state.driversDropdownList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select Driver"
                                            name="dddriver"
                                            defaultValue={0}
                                            value={this.state.driverId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnDriverDropdownChange}
                                            showClearButton={true}
                                            searchEnabled={true}
                                            disabled={this.state.isAllDriver}
                                        />
                                        {this.state.errors["driver"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["driver"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                id="isAllTripStatus" name="isAllTripStatus"
                                                checked={this.state.isAllTripStatus}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isAllTripStatus"> All Status</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                id="isSingleTripStatus" name="isSingleTripStatus"
                                                checked={this.state.isSingleTripStatus}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isSingleTripStatus"> Multiple Status</label>
                                        </div>
                                        <DropDownBox
                                            value={this.state.tripStatusDropdownData}
                                            valueExpr="id"
                                            deferRendering={false}
                                            displayExpr="name"
                                            placeholder="Select Trip Status..."
                                            showClearButton={true}
                                            dataSource={this.state.tripStatusDropdownList}
                                            onValueChanged={this.handleOnSyncDataGridTripStatusSelection}
                                            contentRender={this.dataGridTripStatusRender}
                                            disabled={this.state.isAllTripStatus}
                                        />
                                        {this.state.errors["tripStatus"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["tripStatus"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>From Date (Pick-up)</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="fromPickupDate"
                                            placeholder="MM/dd/yyyy"
                                            defaultValue={this.state.fromPickupDate}
                                            value={this.state.fromPickupDate}
                                            onValueChanged={this.handleOnFromPickupDateChange}
                                            width={'100%'}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["fromPickupDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["fromPickupDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>To Date (Pick-up)</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="toPickupDate"
                                            placeholder="MM/dd/yyyy"
                                            defaultValue={this.state.toPickupDate}
                                            value={this.state.toPickupDate}
                                            min={this.state.fromPickupDate}
                                            onValueChanged={this.handleOnToPickupDateChange}
                                            width={'100%'}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["toPickupDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["toPickupDate"]}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="submit" className="btn btn-success btn-sm" title="Download">
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleOnClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                            </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </>
        );
    }
}