import React, { Component } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { DateFormat } from '../../helpers/fixcodes';
import { SelectBox } from 'devextreme-react/select-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'
let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class AccountRideDetailsReportComponent extends Component {
    constructor() {
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        super()
        this.state = {
            isSubmitted: false,
            isSearchSubmitted: false,
            isEmailSubmitted: false,
            loadPanelVisible: false,
            exportPDF: false,
            rideDetailsGridDataSource: null,
            fromDate: moment(currentTime).format("MM/DD/YYYY"),
            toDate: moment(currentTime).format("MM/DD/YYYY"),
            isAllCustomer: true,
            customerDropdownList: [],
            customerDropdownData: null,
            customerId: 0,
            errors: {
                fromDate: '',
                toDate: '',
                customer: ''
            }
        }
    }
    async componentDidMount() {
        this.setState({ loadPanelVisible: true });
        await this.getCustomerDropdownList();
        this.setState({ loadPanelVisible: false });
    }
    getCustomerDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('account-api/customer-dropdown/', requestParams);
            const data = await response.json();            
            this.setState({
                customerDropdownList: data.customers
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleToolbarPreparing = (e) => {
        try {
            e.toolbarOptions.items.unshift({
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: (this.state.exportPDF) ? 'fas fa-spinner fa-spin' : 'exportpdf',
                    onClick: this.downloadAsPdf.bind(this),
                    hint: 'Export to Pdf'
                }
            });
            //e.toolbarOptions.items.unshift({
            //    location: 'after',
            //    widget: 'dxButton',
            //    options: {
            //        icon: 'email',
            //        onClick: this.emailReport.bind(this),
            //        hint: 'Email'
            //    }
            //});
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnSearchSubmit = async event => {
        event.preventDefault();
        try {
            this.setState({ isSearchSubmitted: true, loadPanelVisible: true });
            let isValid = true;
            let errors = this.state.errors;
            errors.customer = '';
            errors.fromDate = '';
            errors.toDate = '';
            let fromDate = '';
            let toDate = '';

            if (this.state.isAllCustomer === false) {
                if (Number(this.state.customerId) === 0) {
                    errors.customer = 'Please select customer.';
                    isValid = false;
                }
            }
            if (this.state.fromDate.trim() === '') {
                errors.fromDate = 'Please select date.';
                isValid = false;
            }
            else {
                fromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00';
            }
            if (this.state.toDate.trim() === '') {
                errors.toDate = 'Please select date.';
                isValid = false;
            }
            else {
                toDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59'
            }
            if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                if (moment(this.state.fromDate, 'MM/DD/YYYY').isAfter(this.state.toDate, 'MM/DD/YYYY')) {
                    errors.fromDate = 'Please enter a valid date range.';
                    errors.toDate = 'Please enter a valid date range.';
                    isValid = false;
                }
            }
            this.setState({ errors: errors });
            if (isValid) {
                
                let request = new Object();
                let customerCode = '';
                if (this.state.customerDropdownData !== null) {
                    if (Object.keys(this.state.customerDropdownData).length > 0) {
                        customerCode = this.state.customerDropdownData.code;
                    }
                }
                request.AccountCode = customerCode;
                request.FromDate = fromDate;
                request.ToDate = toDate;
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('report-api/account-ride-details', requestParams);
                const data = await response.json();
                this.setState({
                    rideDetailsGridDataSource: data.accountRideDetails
                });
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSearchSubmitted: false, loadPanelVisible: false });
    }
    handleOnClearSearch = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isSearchSubmitted: false,
                isEmailSubmitted: false,
                loadPanelVisible: false,
                exportPDF: false,
                rideDetailsGridDataSource: null,
                fromDate: moment(currentTime).format("MM/DD/YYYY"),
                toDate: moment(currentTime).format("MM/DD/YYYY"),
                isAllCustomer: true,
                customerDropdownData: null,
                customerId: 0,
                errors: {
                    fromDate: '',
                    toDate: '',
                    customer: ''
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnInputChange = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
            let errors = this.state.errors;
            switch (name) {
                case 'isAllCustomer':
                    errors.customer = '';
                    this.setState({ isAllCustomer: value, customerDropdownData: null, customerId: 0 });
                    break;
                default:
                    break;
            }
            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnFromDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.fromDate = '';
            let fromDate = '';
            if (event.value !== null && event.value !== '') {
                fromDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.fromDate = 'Please select date.';
            }
            if (this.state.toDate.trim() !== '') {
                errors.toDate = '';
            }
            this.setState({ fromDate: fromDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnToDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.toDate = '';
            let toDate = '';
            if (event.value !== null && event.value !== '') {
                toDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.toDate = 'Please select date.';
            }
            if (this.state.fromDate.trim() !== '') {
                errors.fromDate = '';
            }
            this.setState({ toDate: toDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnCustomerDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.customer = '';
            let customerId = 0;
            if (data !== null) {
                customerId = (data.selectedItem != null ? data.selectedItem.id : 0);
            }
            else {
                //errors.customer = 'Please select customer.';
            }
            this.setState({ customerDropdownData: data, customerId: customerId, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnReportSubmit = async (event) => {
        event.preventDefault();
        try {
            this.setState({ isSubmitted: true });
            await this.downloadReport('pdf');
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false });
    }
    downloadAsPdf = async () => {
        try {
            this.setState({ exportPDF: true });
            await this.downloadReport('pdf');
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ exportPDF: false });
    }
    downloadReport = async (fileFormat) => {
        try {
            if (await this.isFormValid()) {
                let fromDate = '';
                let toDate = '';
                let customerCode = '';
                if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                    fromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00';
                    toDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59';
                }
                if (this.state.customerDropdownData !== null) {
                    if (Object.keys(this.state.customerDropdownData).length > 0) {
                        customerCode = this.state.customerDropdownData.code;
                    }
                }
                
                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.StartDate = fromDate;
                request.EndDate = toDate;
                request.AccountCode = customerCode;
                request.Number1 = 0;
                request.Number2 = 0;
                request.fileFormat = fileFormat;
                request.MethodName = "GetAccountRideDetailsReport";
                request.SelectionCrieteria = await this.setSelectionCrieteria();
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                let a = document.createElement('a');
                a.href = dataResponse.reportBase64String;
                a.download = 'AccountRideDetails.' + fileFormat;
                a.click();
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    isFormValid = async () => {
        let isValid = true;
        try {
            let errors = this.state.errors;
            errors.fromDate = '';
            errors.toDate = '';
            errors.customer = '';
            if (this.state.isAllCustomer === false) {
                if (Number(this.state.customerId) === 0) {
                    errors.customer = 'Please select customer.';
                    isValid = false;
                }
            }
            if (this.state.fromDate.trim() === '') {
                errors.fromDate = 'Please select date.';
                isValid = false;
            }
            if (this.state.toDate.trim() === '') {
                errors.toDate = 'Please select date.';
                isValid = false;
            }
            if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                if (moment(this.state.fromDate, 'MM/DD/YYYY').isAfter(this.state.toDate, 'MM/DD/YYYY')) {
                    errors.fromDate = 'Please enter a valid date range.';
                    errors.toDate = 'Please enter a valid date range.';
                    isValid = false;
                }
            }
            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    setSelectionCrieteria = async () => {
        let selectionCrieteria = 'Account ride details for';
        try {
            if (Number(this.state.customerId) > 0) {
                if (this.state.customerDropdownData !== null && Object.keys(this.state.customerDropdownData).length > 0) {
                    selectionCrieteria += ' customer - ' + this.state.customerDropdownData.name;
                }
            }
            else {
                selectionCrieteria += ' all customer';
            }
            if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                selectionCrieteria += ', period from  - ' + this.state.fromDate + ' to ' + this.state.toDate;
            }

        } catch (ex) {
            selectionCrieteria = '';
            console.log(ex);
        }
        return selectionCrieteria;
    }
    handleOnViewRideDetails = async (e, data) => {
        e.preventDefault();
        try {
            this.props.history.push({
                pathname: '/crudride',
                state: { rideId: data.rideId, sourceUrl: "/reports/account-ride-details", sourceState: this.state }
            })
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnSendEmail = async event => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isEmailSubmitted: true });
                let fromDate = '';
                let toDate = '';
                let customerCode = '';
                if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                    fromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00';
                    toDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59';
                }
                if (this.state.customerDropdownData !== null) {
                    if (Object.keys(this.state.customerDropdownData).length > 0) {
                        customerCode = this.state.customerDropdownData.code;
                    }
                }
                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.StartDate = fromDate;
                request.EndDate = toDate;
                request.AccountCode = customerCode;
                request.Number1 = 0;
                request.Number2 = 0;
                request.fileFormat = 'pdf';
                request.MethodName = "GetAccountRideDetailsReport";
                request.SelectionCrieteria = await this.setSelectionCrieteria();
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
                    let emailSubject = 'Account Ride Details - ' + moment(currentTime).format("DD MMMM YYYY HH:mm");
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = 'AccountRideDetails.pdf';
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        const renderGridCell = (data) => {
            return <div style={{ textAlign: 'center' }}>
                <a href="#" title="View Details" onClick={(e) => { this.handleOnViewRideDetails(e, data.data) }} >
                    <i className="fas fa-eye"></i>
                </a>
            </div>;
        }
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-sm-6 col-md-6 col-lg-6">
                                <h1 className="m-0 text-dark">Account Ride Details</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem> <NavLink tag={Link} to="/report" className="btn btn-block btn-outline-secondary btn-sm" title="Reports" ><i className="fas fa-arrow-left"></i> Back</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <form style={(this.state.isSubmitted || this.state.isSearchSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="rez-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                id="isAllCustomer" name="isAllCustomer"
                                                checked={this.state.isAllCustomer}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isAllCustomer"> All Customer</label>
                                        </div>
                                        <SelectBox
                                            dataSource={this.state.customerDropdownList.length > 0 ? this.state.customerDropdownList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="ddcustomer"
                                            defaultValue={0}
                                            value={this.state.customerDropdownData}
                                            displayExpr='fullName'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnCustomerDropdownChange}
                                            showClearButton={true}
                                            disabled={this.state.isAllCustomer}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customer"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["customer"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>From Date (Pick-up)</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            defaultValue={this.state.fromDate}
                                            value={this.state.fromDate}
                                            onValueChanged={this.handleOnFromDateChange}
                                            width={'100%'}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["fromDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["fromDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>To Date (Pick-up)</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            defaultValue={this.state.toDate}
                                            value={this.state.toDate}
                                            min={this.state.fromDate}
                                            onValueChanged={this.handleOnToDateChange}
                                            width={'100%'}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["toDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["toDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5" >
                                    <div className="form-group">
                                        <label className="btn-block">&nbsp;</label>
                                        <button type="button" className="btn btn-success btn-sm" title="Search" onClick={this.handleOnSearchSubmit} >
                                            {this.state.isSearchSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSearchSubmitted && <span title="Search"><i className="fas fa-search"></i> Search</span>}
                                        </button>&nbsp;&nbsp;
                                           <button type="button" className="btn btn-custom-gray btn-sm" title="Clear" onClick={this.handleOnClearSearch}
                                            onClick={this.handleOnClearSearch}>
                                            <i className="fas fa-eraser"></i> Clear
                                        </button>&nbsp;&nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" title="Download" onClick={this.handleOnReportSubmit}>
                                            {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSubmitted && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                                        </button>&nbsp;&nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleOnSendEmail} >
                                            {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="content">
                    <div className="card">
                        <div className="card-body">
                            <DataGrid
                                dataSource={this.state.rideDetailsGridDataSource}
                                keyExpr="rideId"
                                columnsAutoWidth={true}
                                showColumnLines={false}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                showBorders={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                width='100%'
                                onToolbarPreparing={this.handleToolbarPreparing}
                            >
                                <Column dataField="rideId" width={35} caption="" fixed={true} allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                <Column dataField="rideId" caption="Ride#" alignment='left' width={'auto'} />
                                <Column dataField="paxName" caption="Pax Name" />
                                <Column dataField="pickupDatetime" caption="Sched. Time" alignment='center' format={DateFormat.DefaultDateTimeFormat} dataType="datetime" />
                                <Column dataField="locationTime" caption="On Location" alignment='center' format={DateFormat.DefaultDateTimeFormat} dataType="datetime" />
                                <Column dataField="loadTime" caption="Actual Load Time" alignment='center' format={DateFormat.DefaultDateTimeFormat} dataType="datetime" />
                                <Column dataField="completedTime" caption="Completed Time" alignment='center' format={DateFormat.DefaultDateTimeFormat} dataType="datetime" />
                                <Column dataField="pickupStreet" caption="PU Street" />
                                <Column dataField="dropoffStreet" caption="DO Street" />
                                <Column dataField="noOfPax" caption="Pax #" alignment='center' />
                                <Column dataField="charterType" caption="V. Type" />
                                <Column dataField="vehicleNo" caption="Vehicle #" />
                                <Column dataField="flightAirlines" caption="Fl. Airlines" alignment='center' />
                                <Column dataField="flightDatetime" caption="Fl. Time" alignment='center' format={DateFormat.DefaultDateTimeFormat} dataType="datetime" />
                                <Column dataField="flightTerminal" caption="Fl. Terminal" alignment='center' />
                                <Column dataField="flightNo" caption="Fl.No." alignment='center' />
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={false} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                <Export enabled={true} allowExportSelectedData={false} />
                                <ColumnChooser enabled={false} />
                                <ColumnFixing enabled={false} />
                            </DataGrid>
                        </div>
                    </div>
                </div>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}