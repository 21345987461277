import React, { Component } from 'react';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import { SelectBox } from 'devextreme-react/select-box';
import DateBox from 'devextreme-react/date-box';
import { StatusType, TripStatus, LogType } from '../../helpers/fixcodes';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from 'react-number-format';
import { ViewReservationComponent } from '../Shared/ViewReservationComponent';
import { ReservationHistoryComponent } from '../Shared/ReservationHistoryComponent';
import { VoidReservationComponent } from '../Shared/VoidReservationComponent';
import { CompleteReservationComponent } from '../Shared/CompleteReservationComponent';
import { NoShowReservationComponent } from '../Shared/NoShowReservationComponent';
import { SplitReservationComponent } from '../Shared/SplitReservationComponent';
import { VehicleHistoryComponent } from '../Shared/VehicleHistoryComponent';
import { LocateVehicleComponent } from '../Shared/LocateVehicleComponent';
import { DocumentsComponent } from '../Shared/DocumentsComponent';
import { ReservationActualTimeComponent } from '../Shared/ReservationActualTimeComponent';
import { MeetAndGreeterComponent } from '../Shared/MeetAndGreeterComponent';
import { RideTrackingReplay } from '../Shared/RideTrackingReplay';
import { ReservationDateTimeComponent } from '../Shared/ReservationDateTimeComponent';
import { ViewUpdateFlightStatusComponent } from '../Shared/ViewUpdateFlightStatusComponent'
import { SendAirlineCrewMessageComponent } from '../Shared/SendAirlineCrewMessageComponent'

const allowToAddDriverWorkingHours = sessionStorage.getItem("session_allowToAddDriverWorkingHours");
const session_accountId = parseInt(sessionStorage.getItem("session_accountId"));
const session_mappedAccountId1 = parseInt(sessionStorage.getItem("session_mappedAccountId1"));
const session_mappedAccountId2 = parseInt(sessionStorage.getItem("session_mappedAccountId2"));
let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));

let driverWorkingHoursobject = [
    { id: 1, name: "1" },
    { id: 2, name: "2" },
    { id: 3, name: "3" },
    { id: 4, name: "4" },
    { id: 5, name: "5" },
    { id: 6, name: "6" },
    { id: 7, name: "7" },
    { id: 8, name: "8" },
    { id: 9, name: "9" },
    { id: 10, name: "10" },
    { id: 11, name: "11" },
    { id: 12, name: "12" },
]

export class DispatchDetailComponent extends Component {
    static displayName = DispatchDetailComponent.name;
    currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));

    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            isCloseDispatchDisplay: false,
            isManualDispatchDisplay: false,
            isTripStatusDisplay: false,
            isNoshowDisplay: false,
            iMeetGreetDisplay: 0,
            isSaveCompleteDisplay: false,
            isRideCompleteDisplay: false,
            isSaveDisabled: true,
            isSaveAssignDisabled: true,
            isSaveUnAssignDisabled: true,
            isVehicleUnAssignDisabled: true,
            isDriverUnAssignDisabled: true,
            isDocumentsAttachment: true,
            isDispatchRowIndex: props.isDispatchRowIndex,
            isVoidShow: false,
            isRecallShow: false,
            isUnAssigned: false,
            isPreUnAssigned: false,
            dispatchRideId: props.dispatchRideId,
            singleRideSource: props.singleRideSource,
            refreshDataSource: props.refreshDataSource,
            stopAutoRefresh: props.stopAutoRefresh,
            stopAutoRefreshHandle: props.stopAutoRefreshHandle,
            handleRefresh: props.handleRefresh,
            // rowChanged: props.rowChanged,
            historydatasource: [],
            serviceVehicles: [],
            serviceStatusList: [],
            serviceDrivers: [],
            dispatchVehicleId: 0,
            dispatchVehicleNo: "",
            dispatchDriverId: 0,
            dispatchStatusId: 0,
            dispatchSearchDate: formatDate(currentTime, "MM/dd/yyyy hh:mm"),
            isHistorySubmited: false,
            isStatusSubmited: false,
            isVehicleAssignedSubmited: false,
            isVehicleUnAssignedSubmited: false,
            isSaveCompleteSubmited: false,
            isRecallSubmited: false,
            isVoidStopParentRefresh: false,
            isNoShowStopParentRefresh: false,
            isSplitRideDisplay: false,
            isActualTime: false,
            isUndoSplitRideDisplay: false,
            isShowVehicleHistory: false,
            isShowFlightStatus: false,
            driverWorkingHours: driverWorkingHoursobject,
            driverWorkingHoursId: 0,
            driverWorkingHoursValue: "0",
            driverMobileNumber: '',
            allowEdit: (session_mappedAccountId1 !== session_accountId && session_mappedAccountId2 !== session_accountId) ? true : false,
            errors: {
                dispatchVehicleId: 0,
                dispatchVehicleNo: "",
                dispatchDriverId: 0,
                dispatchVehicleIdTypeId: 0,
                driverMobileNumber: ""
            }
        };

        this.getServiceVehicles = this.getServiceVehicles.bind(this);
        this.getDrivers = this.getDrivers.bind(this);
        this.getStatusList = this.getStatusList.bind(this);
        this.getSingleRideDetails = this.getSingleRideDetails.bind(this);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDispatchVehicleChange = this.handleDispatchVehicleChange.bind(this);
        this.handleDispatchDriverChange = this.handleDispatchDriverChange.bind(this);
        this.handleDispatchStatusChange = this.handleDispatchStatusChange.bind(this);
        this.handleDispatchSearchDateChange = this.handleDispatchSearchDateChange.bind(this);

        this.handleStatusSubmit = this.handleStatusSubmit.bind(this);
        this.handleJobAssign = this.handleJobAssign.bind(this);
        this.handleJobUnAssign = this.handleJobUnAssign.bind(this);
        this.handleJobSaveComplete = this.handleJobSaveComplete.bind(this);
        this.handleRecallReservation = this.handleRecallReservation.bind(this);

        this.handleViewReservationModal = this.handleViewReservationModal.bind(this);
        this.handleReservationHistoryModal = this.handleReservationHistoryModal.bind(this);
        this.handleRideDocumentsModal = this.handleRideDocumentsModal.bind(this);
        this.handleMeetAndGreeterNameModal = this.handleMeetAndGreeterNameModal.bind(this);
        this.handleResetMeetAndGreeterNameModal = this.handleResetMeetAndGreeterNameModal.bind(this);
        this.handleVoidReservationModal = this.handleVoidReservationModal.bind(this);
        this.handleNoShowReservationModal = this.handleNoShowReservationModal.bind(this);
        this.handleDetailsRowClose = this.handleDetailsRowClose.bind(this);
        this.handleEditReservationModel = this.handleEditReservationModel.bind(this);
        this.locateVehicle = this.locateVehicle.bind(this);
        this.handleShowRideTrackingReplayModal = this.handleShowRideTrackingReplayModal.bind(this);
    }

    componentDidMount() {
        this.getStatusList();
        this.getServiceVehicles();
        this.getDrivers();
        this.getSingleRideDetails();
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    async getSingleRideDetails() {
        let rideId = this.state.dispatchRideId;
        const data = this.state.singleRideSource;
        if (data != null) {
            this.setState({
                dispatchSearchDate: formatDate(new Date(data.pickupDateTime), "MM/dd/yyyy hh:mm"),
                dispatchStatusId: data.tripStatusId,
                dispatchDriverId: data.driverId,
                dispatchVehicleId: data.vehicleId,
                dispatchVehicleNo: data.vehicleNo,
                driverMobileNumber: data.driverMobileNo
            });
            this.setState({ isShowVehicleHistory: Number(data.vehicleId) > 0 ? true : false })

            switch (data.tripStatusId) {
                case (parseInt(TripStatus.PENDING)):
                case (parseInt(TripStatus.VEHICLEUNASSIGNED)):
                case (parseInt(TripStatus.DRIVERREJECTED)):
                    this.setState({
                        isSaveDisabled: false,
                        isSaveAssignDisabled: false,
                        isVoidShow: true,
                        isVehicleUnAssignDisabled: false,
                        isDriverUnAssignDisabled: false,
                        isShowFlightStatus: true
                    });
                    if (parseInt(data.parentRideId) === 0 && parseInt(data.noOfAdults) > 1) {
                        this.setState({ isSplitRideDisplay: true });
                    }
                    if (parseInt(data.parentRideId) > 0 && parseInt(data.parentRideId) === parseInt(data.rideId)) {
                        this.setState({ isUndoSplitRideDisplay: true });
                    }
                    break;
                case (parseInt(TripStatus.VEHICLEASSIGNED)):
                case (parseInt(TripStatus.DRIVERACCEPTED)):
                    this.setState({
                        isSaveDisabled: true,
                        isSaveAssignDisabled: true,
                        isVehicleUnAssignDisabled: true,
                        isDriverUnAssignDisabled: true,
                    });
                    break;
                case (parseInt(TripStatus.ONLOCATION)):
                case (parseInt(TripStatus.PAXLOAD)):
                case (parseInt(TripStatus.PAXUNLOAD)):
                    this.setState({
                        isSaveDisabled: true,
                        isSaveAssignDisabled: true,
                        isVehicleUnAssignDisabled: true,
                        isDriverUnAssignDisabled: true,
                    });
                    break;
                case (parseInt(TripStatus.NOSHOW)):
                case (parseInt(TripStatus.ONHIRE)):
                case (parseInt(TripStatus.AUTOCANCELLED)):
                    this.setState({ isSaveDisabled: true });
                    this.setState({ isSaveAssignDisabled: true });
                    this.setState({ isVehicleUnAssignDisabled: true });
                    this.setState({ isDriverUnAssignDisabled: true });
                    break;
                case (parseInt(TripStatus.COMPLETED)):
                case (parseInt(TripStatus.CANCELLED)):
                case (parseInt(TripStatus.AUTOCANCELLED)):
                case (parseInt(TripStatus.NOSHOWNONBILLABLE)):
                    this.setState({
                        isSaveDisabled: true,
                        isSaveAssignDisabled: true,
                        isVehicleUnAssignDisabled: true,
                        isDriverUnAssignDisabled: true
                    });
                    break;
                default:
                    this.setState({
                        isSaveDisabled: true,
                        isSaveAssignDisabled: true,
                        isVehicleUnAssignDisabled: true,
                        isDriverUnAssignDisabled: true
                    });
                    break;
            }

            switch (data.tripStatusId) {
                case (parseInt(TripStatus.PENDING)):
                case (parseInt(TripStatus.VEHICLEUNASSIGNED)):
                case (parseInt(TripStatus.DRIVERREJECTED)):
                    if (data.isPreAssignedRide) {
                        if (data.vehicleId > 0 || data.driverId > 0) {
                            this.setState({ isUnAssigned: true });
                        }
                        else {
                            this.setState({ isUnAssigned: false });
                        }
                        this.setState({ isPreUnAssigned: true, isVoidShow: true });
                    }
                    else {
                        this.setState({ isPreUnAssigned: false, isVoidShow: true });
                    }

                    if (data.meetGreetName == "") {
                        this.setState({ iMeetGreetDisplay: 1 });
                    }
                    else if (data.meetGreetName != "") {
                        this.setState({ iMeetGreetDisplay: 2 });
                    }
                    this.setState({ isSaveCompleteDisplay: true });
                    break;
                case (parseInt(TripStatus.VEHICLEASSIGNED)):
                case (parseInt(TripStatus.DRIVERACCEPTED)):
                case (parseInt(TripStatus.ONLOCATION)):
                    this.setState({ isUnAssigned: true, isPreUnAssigned: false });
                    if (data.meetGreetName == "") {
                        this.setState({ iMeetGreetDisplay: 1 });
                    }
                    else if (data.meetGreetName != "") {
                        this.setState({ iMeetGreetDisplay: 2 });
                    }
                    break;
                case (parseInt(TripStatus.COMPLETED)):
                case (parseInt(TripStatus.NOSHOW)):
                    this.setState({ isRecallShow: false, iMeetGreetDisplay: 0, isActualTime: true });
                    break;
                case (parseInt(TripStatus.CANCELLED)):
                case (parseInt(TripStatus.AUTOCANCELLED)):
                    this.setState({ isRecallShow: true, iMeetGreetDisplay: 0, isDocumentsAttachment: false });
                    break;
                default:
                    this.setState({ isUnAssigned: false, isPreUnAssigned: false, iMeetGreetDisplay: 0, isDocumentsAttachment: false });
                    this.setState({ isVoidShow: true });
                    this.setState({ isRecallShow: false });
                    break;
            }

            switch (data.tripStatusId) {
                case (parseInt(TripStatus.DRIVERACCEPTED)):
                case (parseInt(TripStatus.VEHICLEASSIGNED)):
                case (parseInt(TripStatus.ONLOCATION)):
                case (parseInt(TripStatus.PAXLOAD)):
                case (parseInt(TripStatus.PAXUNLOAD)):
                    this.setState({ isTripStatusDisplay: true });
                    this.setState({ isRideCompleteDisplay: true });
                    break;
                default:
                    this.setState({ isTripStatusDisplay: false });
            }

            switch (data.tripStatusId) {
                case (parseInt(TripStatus.CANCELLED)):
                case (parseInt(TripStatus.AUTOCANCELLED)):
                case (parseInt(TripStatus.COMPLETED)):
                case (parseInt(TripStatus.NOSHOW)):
                case (parseInt(TripStatus.NOSHOWNONBILLABLE)):
                    this.setState({ isNoshowDisplay: false });
                    break;
                default:
                    this.setState({ isNoshowDisplay: true });
            }
        }
    }

    handleJobAssignValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.dispatchVehicleId = "";
        errors.dispatchDriverId = "";
        if (this.state.dispatchVehicleId == 0 && this.state.dispatchDriverId == 0) {
            formIsValid = false;
            errors.dispatchVehicleId = "Please select vehicle or driver.";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    handleVehicleAssignValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.dispatchVehicleId = "";
        if (this.state.dispatchVehicleId == 0) {
            formIsValid = false;
            errors.dispatchVehicleId = "Please select vehicle.";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    async getStatusList() {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/status-api/' + StatusType.Booking, requestParams);
            const data = await response.json();
            this.setState({
                serviceStatusList: data.statusType
            });
        } catch (e) {
            console.log(e);
        }
    }

    async getServiceVehicles() {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/vehicle-api/dropdown/0/0', requestParams);
            const data = await response.json();
            this.setState({
                serviceVehicles: data.vehiclesDropdown
            });
        } catch (e) {
            console.log(e);
        }
    }

    async getDrivers() {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/driver-api/dropdown/', requestParams);
            const data = await response.json();
            this.setState({
                serviceDrivers: data.driversDropdownList
            });
        } catch (e) {
            console.log(e);
        }
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleDispatchVehicleChange(selectedOption) {
        let vehicleId = (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0);
        let vehicleNo = (selectedOption.selectedItem != null ? selectedOption.selectedItem.name : "");
        this.setState({
            dispatchVehicleId: vehicleId,
            dispatchVehicleNo: vehicleNo,
        });
    }

    handleDispatchDriverChange = (selectedOption) => {
        this.setState({ dispatchDriverId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
    }

    handleDispatchStatusChange = (selectedOption) => {
        this.setState({ dispatchStatusId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
    }

    handleDispatchSearchDateChange = (date) => {
        var dt = formatDate(date, "MM/dd/yyyy hh:mm");
        this.setState({ dispatchSearchDate: date });
    }

    handleViewReservationModal = (e, data) => {
        e.preventDefault();
        this.refs.viewReservationModal.showModal("View Reservation", data);
    }

    handleReservationHistoryModal = (e, data) => {
        e.preventDefault();
        clearInterval(this.state.stopAutoRefresh);
        clearInterval(this.state.stopAutoRefreshHandle);
        //this.getReservationHistory(data.rideId);
        this.refs.reservationHistoryModal.showModal("Reservation History", data.rideId);
    }

    handleMeetAndGreeterNameModal = (e, data) => {
        e.preventDefault();
        clearInterval(this.state.stopAutoRefresh);
        clearInterval(this.state.stopAutoRefreshHandle);
        this.refs.meetAndGreeterDataModel.showModal("Set Meet & Greeter Name", data.rideId);
    }

    handleDriverWorkingHourChange = (selectedOption) => {
        this.setState({
            driverWorkingHoursId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0),
            driverWorkingHoursValue: (selectedOption.selectedItem != null ? selectedOption.selectedItem.name : "0"),
        })
    }

    handleRideDocumentsModal = (e, data) => {
        e.preventDefault();
        clearInterval(this.state.stopAutoRefresh);
        clearInterval(this.state.stopAutoRefreshHandle);
        var rideArray = [];
        rideArray.push(data.rideId);
        this.refs.reservationDocumentsModal.showModal('Ride Id', rideArray, rideArray[0]);
    }

    handleResetMeetAndGreeterNameModal = async (e, data) => {
        e.preventDefault();
        let isResult = false;

        await Swal.fire({
            title: 'Confirmation...!',
            html: 'Are you sure to reset the greeter name?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed) {
                isResult = true;
            } else {
                this.state.refreshDataSource();
                this.setState({ isSubmited: false });
            }
        })
        if (isResult) {
            var newObject = new Object();
            newObject.RideId = this.state.dispatchRideId;
            newObject.MGPayTo = 0;
            newObject.MeetGreetId = 0;
            newObject.MeetGreetName = '';
            newObject.MeetGreetAmount = 0;
            newObject.IsSetMeetGreet = false;
            newObject.DispatcherId = parseInt(sessionStorage.getItem("session_userId"));

            const requestParams = getRequestParams('POST', newObject);
            const response = await fetch('meet-greeter-api/SaveUpdate', requestParams);
            const dataResult = await response.json();
            Swal.fire({
                icon: dataResult.isSuccess ? 'success' : 'error',
                title: dataResult.isSuccess ? 'Success' : 'Oops...',
                text: dataResult.resultMessage,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (dataResult.isSuccess) {
                    if (result.isConfirmed) {
                        this.state.refreshDataSource();
                        this.setState({ isSubmited: false });
                    }
                } else {
                    this.setState({ isSubmited: false })
                }
            })
        }
    }
    handleShowRideTrackingReplayModal = async (e, data) => {
        e.preventDefault();
        clearInterval(this.state.stopAutoRefresh);
        clearInterval(this.state.stopAutoRefreshHandle);
        this.setState({ isVoidStopParentRefresh: true });
        this.refs.rideTrackingReplay.showModal(data.rideId, data.fmVehicleId, data.startTime, data.endTime, data.trackingSourceId);
    }

    handleVoidReservationModal = (e, data) => {
        e.preventDefault();
        this.setState({ isVoidStopParentRefresh: true });
        this.refs.voidReservationModal.showModal("Void Reservation", data.rideId, "Dispatch");
        //this.state.refreshDataSource();
    }

    handleNoShowReservationModal = (e, data) => {
        e.preventDefault();
        clearInterval(this.state.stopAutoRefresh);
        clearInterval(this.state.stopAutoRefreshHandle);
        this.setState({ isNoShowStopParentRefresh: true });
        this.refs.noShowReservationModal.showModal("No-Show Reservation", data.rideId, data.reservationId, data.vehicleNo, data.driverId);
        //this.state.refreshDataSource();
    }

    handleSplitReservationModal = (e, data) => {
        e.preventDefault();
        clearInterval(this.state.stopAutoRefresh);
        clearInterval(this.state.stopAutoRefreshHandle);
        this.refs.splitReservationModal.showModal("Split Reservation", data.rideId, data.reservationId, data.noOfAdults, data.vehicleTypeDescription, data.tempPickupDateTime, data.puStreet, data.noOfCharter, true);
    }

    handleActualTimeModal = (e, data) => {
        e.preventDefault();
        clearInterval(this.state.stopAutoRefresh);
        clearInterval(this.state.stopAutoRefreshHandle);
        this.refs.actualTimeModal.showModal("Dispatch", data.rideId, data.airlineCode);
    }

    handleChangeRideDateTime = (e, data) => {
        e.preventDefault();
        clearInterval(this.state.stopAutoRefresh);
        clearInterval(this.state.stopAutoRefreshHandle);
        this.setState({ isNoShowStopParentRefresh: true });
        this.refs.changeDateTimeReservationModal.showModal("Dispatch", data.rideId);
    }

    handleUndoSplitReservationModal = async (e, data) => {
        e.preventDefault();
        try {
            const isConfirm = await this.isConfirm('Confirmation', '<small>Are you sure want to undo split?</small>', 'Yes, Undo Split!');
            if (!isConfirm) {
                return;
            }
            clearInterval(this.state.stopAutoRefresh);
            clearInterval(this.state.stopAutoRefreshHandle);
            this.setState({ isSubmited: true });
            var request = new Object();
            request.RideId = Number(data.rideId);
            request.ReservationId = Number(data.reservationId);
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('ride-api/undo-split-ride/', requestParams);
            if (response.ok) {
                const data = await response.json();
                if (data.isSuccess) {
                    await Swal.fire({
                        allowOutsideClick: false,
                        icon: 'success',
                        title: 'success',
                        text: (data.resultMessage === null || data.resultMessage.trim() === '') ? 'Ride# [' + data.rideId + '] splitted is cancelled ride(s) successfully!' : data.resultMessage.trim(),
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            this.state.refreshDataSource();
                        }
                    });
                }
                else {
                    this.onfailure('Failed to split ride.', data.resultMessage);
                }
            }
            else {
                this.onfailure();
            }
        } catch (e) {
            this.onfailure();
        }
        this.setState({ isSubmited: false });
    }

    handleVehicleHistoryModal = async (e, data) => {
        e.preventDefault();
        clearInterval(this.state.stopAutoRefresh);
        clearInterval(this.state.stopAutoRefreshHandle);
        if (data.vehicleId > 0) {
            this.refs.vehicleHistoryModal.showModal(data.vehicleNo);
        }
        else {
            await Swal.fire({
                showCloseButton: true,
                title: 'Vehicle not assigned',
                html: 'Vehicle not assigned to ride ' + data.rideId,
                icon: 'question',
                confirmButtonText: 'OK'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.state.handleRefresh(true);
                    this.state.refreshDataSource();
                }
            })
        }
    }
    isConfirm = async (titile, confirmationMessage, confirmButtonText) => {
        let isResult = false;
        await Swal.fire({
            title: '<small><b>' + titile + '</b></small>',
            html: confirmationMessage,
            icon: 'question',
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: confirmButtonText
        }).then(async (result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }
    onfailure = (title, resultMessage) => {
        Swal.fire({
            allowOutsideClick: false,
            showCloseButton: true,
            icon: 'error',
            title: (title === null || title.trim() === '') ? 'Oops...' : title,
            text: (resultMessage === null || resultMessage.trim() === '') ? 'Something went wrong!' : resultMessage.trim(),
        })
    }
    locateVehicle = (e, data) => {
        e.preventDefault();
        clearInterval(this.state.stopAutoRefresh);
        clearInterval(this.state.stopAutoRefreshHandle);
        this.refs.locateVehicleModal.showModal(data.vehicleNo, data.rideId);
    }
    handleStatusSubmit = async (e) => {
        e.preventDefault();
        if (this.handleJobAssignValidation()) {
            this.setState({ isStatusSubmited: true });
            var rideStatusRequest = new Object();
            rideStatusRequest.TripStatusId = parseInt(this.state.dispatchStatusId);
            rideStatusRequest.CancelReason = this.state.reason;
            rideStatusRequest.LogTypeId = parseInt(LogType.Reservation);

            const requestParams = getRequestParams('PUT', rideStatusRequest);
            const response = await fetch('/ride-api/' + this.state.dispatchRideId + '/status', requestParams);
            const data = await response.json();

            Swal.fire({
                icon: data.isSuccess ? 'success' : 'error',
                title: data.isSuccess ? 'Job Assigned' : 'Oops...',
                text: data.resultMessage,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (data.isSuccess) {
                    if (result.isConfirmed) {
                        this.setState({ isStatusSubmited: false });
                        this.state.refreshDataSource();
                    }
                } else {
                    this.setState({ isStatusSubmited: false });
                }
            });
        }
    }

    handleJobAssign = async (e) => {
        e.preventDefault();
        let jobrides = [];
        if (this.handleJobAssignValidation()) {
            this.setState({ isVehicleAssignedSubmited: true });
            jobrides.push(this.state.dispatchRideId);
            debugger;
            if (this.state.dispatchVehicleId > 0 || this.state.dispatchDriverId > 0) {
                var assignJobRequest = new Object();
                assignJobRequest.Rides = jobrides;
                assignJobRequest.VehicleId = this.state.dispatchVehicleId;
                assignJobRequest.DriverId = this.state.dispatchDriverId;
                assignJobRequest.IsAssignRide = true;
                assignJobRequest.DriverWorkingHours = parseInt(this.state.driverWorkingHoursValue);

                const requestParams = getRequestParams('POST', assignJobRequest);
                //const response = await fetch('/dispatch-api/pre-assign-unassign-ride', requestParams);
                const response = await fetch('/dispatch-api/assign-job', requestParams);
                const data = await response.json();

                Swal.fire({
                    icon: data.isSuccess ? 'success' : 'error',
                    title: data.isSuccess ? 'Job Assigned' : 'Oops...',
                    text: data.resultMessage,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            this.setState({
                                isVehicleAssignedSubmited: false
                            });
                            this.state.refreshDataSource();
                        }
                    } else {
                        this.setState({ isVehicleAssignedSubmited: false })
                    }
                });
            }
            else {
                var preAssignUnAssignRideRequest = new Object();
                preAssignUnAssignRideRequest.Rides = jobrides;
                preAssignUnAssignRideRequest.VehicleId = this.state.dispatchVehicleId;
                preAssignUnAssignRideRequest.DriverId = this.state.dispatchDriverId;
                preAssignUnAssignRideRequest.IsAssignRide = true;
                preAssignUnAssignRideRequest.DriverWorkingHours = parseInt(this.state.driverWorkingHoursValue);

                const requestParams = getRequestParams('POST', preAssignUnAssignRideRequest);
                const response = await fetch('/dispatch-api/pre-assign-unassign-ride', requestParams);
                const data = await response.json();

                Swal.fire({
                    icon: data.isSuccess ? 'success' : 'error',
                    title: data.isSuccess ? 'Job Assigned' : 'Oops...',
                    text: data.resultMessage,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            this.setState({ isVehicleAssignedSubmited: false });
                            this.state.refreshDataSource();
                        }
                    } else {
                        this.setState({ isVehicleAssignedSubmited: false })
                    }
                });
            }
        }
    }

    handleJobUnAssign = async (e) => {
        e.preventDefault();
        //let jobrides = [];
        let jobRides = [];
        let preUnAssignRides = [];
        //let onJobRide = false;
        this.setState({ isVehicleUnAssignedSubmited: true });
        try {
            switch (parseInt(this.state.singleRideSource.tripStatusId)) {
                case parseInt(TripStatus.PENDING):
                case parseInt(TripStatus.DRIVERREJECTED):
                case parseInt(TripStatus.VEHICLEUNASSIGNED):
                    preUnAssignRides.push(this.state.dispatchRideId);
                    break;
                case parseInt(TripStatus.ONLOCATION):
                case parseInt(TripStatus.VEHICLEASSIGNED):
                case parseInt(TripStatus.DRIVERACCEPTED):
                    jobRides.push(this.state.dispatchRideId);
                    break;
            }

            let jobId = this.state.singleRideSource.jobId;
            let rideId = this.state.singleRideSource.rideId;
            //let jobrides = [];
            //jobrides.push(rideId);
            var unassignJobRequest = new Object();
            unassignJobRequest.DispatcherId = 0;
            unassignJobRequest.JobRides = jobRides;
            unassignJobRequest.PreUnAssignRides = preUnAssignRides;
            const requestParams = getRequestParams('POST', unassignJobRequest);
            //const response = await fetch('/dispatch-api/' + jobId + '/unassign-job/', requestParams);
            const response = await fetch('/dispatch-api/unassign-jobtrip/', requestParams);
            const data = await response.json();

            Swal.fire({
                icon: data.isSuccess ? 'success' : 'error',
                title: data.isSuccess ? 'Job Unassigned' : 'Oops...',
                text: data.isSuccess ? "Job unassigned successful" : data.resultMessage,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (data.isSuccess) {
                    if (result.isConfirmed) {
                        this.setState({ isVehicleUnAssignedSubmited: false });
                        this.state.refreshDataSource();
                    }
                } else {
                    this.setState({ isVehicleUnAssignedSubmited: false })
                }
            });

            //if (onJobRide === false) {
            //    jobrides.push(this.state.dispatchRideId);
            //    var preAssignUnAssignRideRequest = new Object();
            //    preAssignUnAssignRideRequest.Rides = jobrides;
            //    preAssignUnAssignRideRequest.VehicleId = this.state.dispatchVehicleId;
            //    preAssignUnAssignRideRequest.DriverId = this.state.dispatchDriverId;
            //    preAssignUnAssignRideRequest.IsAssignRide = false;

            //    const requestParams = getRequestParams('POST', preAssignUnAssignRideRequest);
            //    const response = await fetch('/dispatch-api/pre-assign-unassign-ride', requestParams);
            //    const data = await response.json();

            //    Swal.fire({
            //        icon: data.isSuccess ? 'success' : 'error',
            //        title: data.isSuccess ? 'Job Unassigned' : 'Oops...',
            //        text: data.resultMessage,
            //    }).then((result) => {
            //        /* Read more about isConfirmed, isDenied below */
            //        if (data.isSuccess) {
            //            if (result.isConfirmed) {
            //                this.setState({ isVehicleAssignedSubmited: false });
            //                this.state.refreshDataSource();
            //            }
            //        } else {
            //            this.setState({ isVehicleAssignedSubmited: false })
            //        }
            //    });
            //}
            //else {
            //    let jobId = this.state.singleRideSource.jobId;
            //    let rideId = this.state.singleRideSource.rideId;
            //    let jobrides = [];
            //    jobrides.push(rideId);
            //    var unassignJobRequest = new Object();
            //    unassignJobRequest.DispatcherId = 0;
            //    unassignJobRequest.Rides = jobrides;
            //    const requestParams = getRequestParams('POST', unassignJobRequest);
            //    //const response = await fetch('/dispatch-api/' + jobId + '/unassign-job/', requestParams);
            //    const response = await fetch('/dispatch-api/unassign-jobtrip/', requestParams);
            //    const data = await response.json();

            //    Swal.fire({
            //        icon: data.isSuccess ? 'success' : 'error',
            //        title: data.isSuccess ? 'Job Unassigned' : 'Oops...',
            //        text: data.resultMessage,
            //    }).then((result) => {
            //        /* Read more about isConfirmed, isDenied below */
            //        if (data.isSuccess) {
            //            if (result.isConfirmed) {
            //                this.setState({ isVehicleUnAssignedSubmited: false });
            //                this.state.refreshDataSource();
            //            }
            //        } else {
            //            this.setState({ isVehicleUnAssignedSubmited: false })
            //        }
            //    });
            //}
        } catch (e) {
        }
    }

    handleJobSaveComplete = async (e) => {
        e.preventDefault();
        let isResult = false;
        debugger;
        await Swal.fire({
            title: 'Confirmation...!',
            html: 'Are you sure you want to complete the ride?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed) {
                isResult = true;
            } else {
                this.setState({ isSubmited: false });
            }
        })

        let rideDetails = this.state.singleRideSource;
        if (isResult) {
            try {
                if (rideDetails.tripStatusId === (parseInt(TripStatus.PENDING)) ||
                    rideDetails.tripStatusId === (parseInt(TripStatus.VEHICLEUNASSIGNED)) ||
                    rideDetails.tripStatusId === (parseInt(TripStatus.DRIVERREJECTED))) {
                    //if (this.handleVehicleAssignValidation()) {

                    var completeJobWebRequest = new Object();
                    completeJobWebRequest.Stops = 0;
                    completeJobWebRequest.WaitTime = 0;
                    completeJobWebRequest.NoShowReason = "";
                    completeJobWebRequest.MachineIP = "";
                    completeJobWebRequest.VehicleId = this.state.dispatchVehicleId;
                    completeJobWebRequest.DriverId = this.state.dispatchDriverId;

                    const requestParams = getRequestParams('POST', completeJobWebRequest);
                    const response = await fetch('/dispatch-api/' + parseInt(rideDetails.rideId) + '/complete-job', requestParams);
                    const data = await response.json();

                    if (data.isSuccess) {
                        if (rideDetails.airlineCode === "QP") {
                            const requestRateParams = getRequestParams('GET');
                            const rateResponse = await fetch('/manage-rides-api/rate-calculate/' + rideDetails.rideId, requestRateParams);
                            const dataRate = await rateResponse.json();
                        }
                    }

                    Swal.fire({
                        icon: data.isSuccess ? 'success' : 'error',
                        title: data.isSuccess ? 'Complete Reservation' : 'Oops...',
                        text: data.resultMessage,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (data.isSuccess) {
                            if (result.isConfirmed) {
                                this.setState({ isSaveCompleteSubmited: false });
                                this.state.refreshDataSource();
                            }
                        } else {
                            this.setState({ isSaveCompleteSubmited: false });
                        }
                    });
                    //}
                }
                else {
                    e.preventDefault();
                    clearInterval(this.state.stopAutoRefresh);
                    clearInterval(this.state.stopAutoRefreshHandle);
                    this.setState({ isNoShowStopParentRefresh: true });
                    this.refs.completeReservationModal.showModal("Complete Reservation", rideDetails.rideId, rideDetails.driverId, rideDetails.vehicleNo, "Dispatch", rideDetails.airlineCode);
                }
            } catch (ex) {
                throw ex;
            }
        }
    }

    handleRecallReservation = async (e) => {
        e.preventDefault();
        this.setState({ isRecallSubmited: true });
        var rideStatusRequest = new Object();
        rideStatusRequest.TripStatusId = parseInt(TripStatus.PENDING);
        rideStatusRequest.CancelReason = this.state.reason;
        rideStatusRequest.LogTypeId = parseInt(LogType.Reservation);

        const requestParams = getRequestParams('PUT', rideStatusRequest);
        const response = await fetch('/ride-api/' + this.state.dispatchRideId + '/status', requestParams);
        const data = await response.json();

        Swal.fire({
            icon: data.isSuccess ? 'success' : 'error',
            title: data.isSuccess ? 'Recall Reservation' : 'Oops...',
            text: data.resultMessage,
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (data.isSuccess) {
                if (result.isConfirmed) {
                    this.setState({ isRecallSubmited: false });
                    this.state.refreshDataSource();
                }
            } else {
                this.setState({ isRecallSubmited: false });
            }
        });
    }

    UpdateDriverMobileNo = async (e) => {
        e.preventDefault();
        let errors = this.state.errors;
        let formIsValid = true;
        errors.driverMobileNumber = "";
        errors.dispatchDriverId = "";
        if (this.state.driverMobileNumber == null || this.state.driverMobileNumber.trim() === "") {
            formIsValid = false;
            errors.driverMobileNumber = "Please enter mobile.";
            this.setState({ errors: errors });
            return formIsValid;
        } else if (this.state.driverMobileNumber.trim().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length != 10) {
            formIsValid = false;
            errors.driverMobileNumber = "Invalid mobile number.";
            this.setState({ errors: errors });
            return formIsValid;
        }

        if (this.state.dispatchDriverId == 0) {
            formIsValid = false;
            errors.driverMobileNumber = "Please select driver.";
            this.setState({ errors: errors });
            return formIsValid;
        }

        const requestParams = getRequestParams('GET');
        const response = await fetch('/dispatch-api/' + this.state.dispatchDriverId + '/' + this.state.driverMobileNumber, requestParams);
        const data = await response.json();
        Swal.fire({
            icon: data.isSuccess ? 'success' : 'error',
            title: data.isSuccess ? 'success' : 'Oops...',
            text: data.resultMessage,
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (data.isSuccess) {
                if (result.isConfirmed) {
                    this.state.refreshDataSource();
                }
            } else {
                this.setState({ isRecallSubmited: false });
            }
        });
    }
    handleDetailsRowClose = (e) => {
        //this.state.rowChanged(e);
        //debugger;
        //e.component.collapseAll(-1);
        //e.component.expandRow(e.currentSelectedRowKeys[0]);
    }

    handleEditReservationModel = (e, data) => {
        e.preventDefault();
        this.props.editViewRide(data);
    }

    handleShowFlightStatusModal = async (e, data) => {
        try {
            e.preventDefault();
            clearInterval(this.state.stopAutoRefresh);
            clearInterval(this.state.stopAutoRefreshHandle);
            this.refs.viewUpdateFlightStatusComponent.showModal(data.rideId, data.tempPickupDateTime);
        } catch (ex) {
            console.log(ex);
        }
    }
    sendCrewAirlineMessageControl = (e, data) => {
        try {
            e.preventDefault();
            clearInterval(this.state.stopAutoRefresh);
            clearInterval(this.state.stopAutoRefreshHandle);
            this.refs.SendAirlineCrewMessageComponentModel.showAirlineCrewMessageModal(data);
        } catch (ex) {
            console.log(ex);
        }
    }
    render() {
        const filterDispatchSearchDate = time => {
            const currentDate = new Date();
            const selectedDate = new Date(time);
            return currentDate.getTime() < selectedDate.getTime();
        }

        return (
            <form method="post">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="row form-horizontal form-group">
                            {/*<div className="col-lg-3 col-md-3 col-sm-3 col-xs-3  displayNone">*/}
                            {/*    <div className="row">*/}
                            {/*        <label htmlFor="" className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-form-label">Pick-up Date</label>*/}
                            {/*        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">*/}
                            {/*            <DateBox type="datetime"*/}
                            {/*                className="form-control form-control-sm"*/}
                            {/*                name="dispatchSearchDate"*/}
                            {/*                placeholder="mm/dd/yyyy"*/}
                            {/*                showAnalogClock={false}*/}
                            {/*                useMaskBehavior={true}*/}
                            {/*                //defaultValue={this.state.dispatchSearchDate}*/}
                            {/*                //value={this.state.dispatchSearchDate}*/}
                            {/*                onValueChanged={this.handleDispatchSearchDateChange}*/}
                            {/*                width="100%"*/}
                            {/*                disabled={false}*/}
                            {/*                acceptCustomValue={false}*/}
                            {/*            />*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 displayNone">
                                <div className="row">
                                    <label htmlFor="" className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-form-label">Status</label>
                                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                        <SelectBox
                                            dataSource={this.state.serviceStatusList.length > 0 ? this.state.serviceStatusList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select status"
                                            name="dispatchStatusId"
                                            defaultValue={0}
                                            value={this.state.dispatchStatusId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleDispatchStatusChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                <div className="row">
                                    <label htmlFor="" className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-form-label">Vehicles</label>
                                    <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                        <SelectBox
                                            dataSource={this.state.serviceVehicles.length > 0 ? this.state.serviceVehicles : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select vehicle"
                                            name="dispatchVehicleId"
                                            defaultValue={0}
                                            value={this.state.dispatchVehicleId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleDispatchVehicleChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />

                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 displayNone">
                                        <button type="button" className="btn btn-info btn-sm" disabled={this.state.isVehicleUnAssignDisabled}><i className="far fa-times-circle"></i></button>
                                    </div>
                                    {this.state.errors["dispatchVehicleId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["dispatchVehicleId"]}</span>}
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                <div className="row">
                                    <label htmlFor="" className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-form-label">Drivers</label>
                                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                        <SelectBox
                                            dataSource={this.state.serviceDrivers.length > 0 ? this.state.serviceDrivers : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select driver"
                                            name="dispatchDriverId"
                                            defaultValue={0}
                                            value={this.state.dispatchDriverId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleDispatchDriverChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 displayNone">
                                        <button type="button" className="btn btn-info btn-sm" disabled={this.state.isDriverUnAssignDisabled}><i className="far fa-times-circle"></i></button>
                                    </div>
                                    {this.state.errors["dispatchDriverId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["dispatchDriverId"]}</span>}
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2" style={{ display: this.state.singleRideSource.driverId === 0 ? "none" : "block" }}>
                                <div className="row">
                                    <label htmlFor="" className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-form-label">Mobile</label>
                                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                        <NumberFormat className="form-control form-control-sm" name="driverMobileNumber" placeholder="(000) 000-0000" title="Mobile Number" value={this.state.driverMobileNumber} onChange={this.handleInputChange} format="(###) ###-####" />
                                        {this.state.errors["driverMobileNumber"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["driverMobileNumber"]}</span>}
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                        <button type="button" className="btn btn-success btn-sm" onClick={this.UpdateDriverMobileNo}>Update</button>
                                    </div>
                                </div>
                            </div>
                            {/*<div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">*/}
                            {/*    <div className="form-group">*/}
                            {/*        <label>Mobile Number <span className="text-danger">*</span></label>*/}
                            {/*        <NumberFormat className="form-control form-control-sm" name="driverMobileNumber" placeholder="(000) 000-0000" title="Mobile Number" value={this.state.driverMobileNumber} onChange={this.handleInputChange} format="(###) ###-####" />*/}
                            {/*       {this.state.errors["driverMobileNumber"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["driverMobileNumber"]}</span>}*/}
                            {/*    </div>*/}

                            {/*      </div>*/}
                            <div className={allowToAddDriverWorkingHours === "true" ? "col-lg-2 col-md-2 col-sm-2 col-xs-2 displayBlock" : "col-lg-2 col-md-2 col-sm-2 col-xs-2 displayNone"}>
                                <div className="row">
                                    <label htmlFor="" className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-form-label">Working Hours</label>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <SelectBox
                                            dataSource={this.state.driverWorkingHours.length > 0 ? this.state.driverWorkingHours : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select working hour"
                                            name="driverWorkingHoursId"
                                            defaultValue={0}
                                            value={this.state.driverWorkingHoursId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleDriverWorkingHourChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                            width="100%"
                                            visible={allowToAddDriverWorkingHours === "true" ? true : false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row form-horizontal form-group">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <label>&nbsp;</label>
                        {
                            this.state.isManualDispatchDisplay ?
                                <button type="button" id="btnSave" className="btn btn-success btn-sm" onClick={this.handleStatusSubmit} disabled={this.state.isSaveDisabled}>
                                    {this.state.isStatusSubmited && <span><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                    {!this.state.isStatusSubmited && <span><i className="fas fa-save"></i> Manual Dispatch</span>}
                                </button> : null
                        }&nbsp;
                        {
                            this.state.isUnAssigned ?
                                <button type="button" id="btnSaveUnAssign" className="btn btn-success btn-sm" onClick={this.handleJobUnAssign}>
                                    {this.state.isVehicleUnAssignedSubmited && <span><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                    {!this.state.isVehicleUnAssignedSubmited && <span><i className="fas fa-history"></i> Unassign</span>}
                                </button> : null
                        }&nbsp;
                        {
                            !this.state.isUnAssigned ?
                                <button type="button" id="btnSaveAssign" className="btn btn-success btn-sm" onClick={this.handleJobAssign} disabled={this.state.isSaveAssignDisabled}>
                                    {this.state.isVehicleAssignedSubmited && <span><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                    {!this.state.isVehicleAssignedSubmited && <span><i className="fas fa-history"></i> Save & Assign</span>}
                                </button> : null
                        }&nbsp;
                        {
                            (this.state.isSaveCompleteDisplay == true) ?
                                <button type="button" id="btnSaveComplete" className="btn btn-success btn-sm" onClick={this.handleJobSaveComplete} disabled={false}>
                                    {this.state.isSaveCompleteSubmited && <span><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                    {!this.state.isSaveCompleteSubmited && <span><i className="fas fa-double-check"></i> Save & Complete</span>}
                                </button> : null
                        }&nbsp;
                        {
                            (this.state.isRideCompleteDisplay == true) ?
                                <button type="button" id="btnRideComplete" className="btn btn-success btn-sm" onClick={this.handleJobSaveComplete} disabled={false}>
                                    {this.state.isSaveCompleteSubmited && <span><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                    {!this.state.isSaveCompleteSubmited && <span><i className="fas fa-double-check"></i> Ride Complete</span>}
                                </button> : null
                        }&nbsp;
                        {
                            this.state.isSaveCompleteDisplay ? <button type="button" className="btn btn-custom-gray btn-sm" onClick={(e) => { this.handleChangeRideDateTime(e, this.state.singleRideSource) }}><i className="fas fa-clock"></i> Change Ride Datetime</button> : null
                        }&nbsp;
                        {
                            this.state.isCloseDispatchDisplay ? <button type="button" className="btn btn-custom-gray btn-sm" onClick={(e) => { this.handleDetailsRowClose(e, this.state.dispatchRideId) }}><i className="fas fa-times"></i> Close</button> : null
                        }&nbsp;
                        {
                            this.state.allowEdit ? <button type="button" className="btn btn-custom-gray btn-sm" tag={Link} onClick={(e) => { this.handleEditReservationModel(e, this.state.singleRideSource) }}><i className="fas fa-eye"></i> Edit/View</button> : null
                        }&nbsp;
                        {
                            this.state.isVoidShow ? <button type="button" className="btn btn-custom-gray btn-sm" onClick={(e) => { this.handleVoidReservationModal(e, this.state.singleRideSource) }}><i className="fas fa-times"></i> Void</button> : null
                        }&nbsp;
                        {
                            this.state.isRecallShow ?
                                <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleRecallReservation}>
                                    {this.state.isRecallSubmited && <span><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                    {!this.state.isRecallSubmited && <span><i className="fas fa-times"></i> Recall</span>}
                                </button> : null
                        }&nbsp;
                        <button type="button" className="btn btn-custom-gray btn-sm" onClick={(e) => { this.handleReservationHistoryModal(e, this.state.singleRideSource) }}>
                            {this.state.isHistorySubmited && <span><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                            {!this.state.isHistorySubmited && <span><i className="fas fa-history"></i> Ride History</span>}
                        </button>&nbsp;
                        {
                            this.state.isTripStatusDisplay ?
                                <button type="button" id="btnLocate" className="btn btn-custom-gray btn-sm" onClick={(e) => { this.locateVehicle(e, this.state.singleRideSource) }} title="Locate"><i className="fas fa-map-marker"></i> Locate</button> : null
                        }&nbsp;
                        {
                            this.state.isShowVehicleHistory ?
                                <button type="button" id="btnVehicleHistory" className="btn btn-custom-gray btn-sm" onClick={(e) => { this.handleVehicleHistoryModal(e, this.state.singleRideSource) }} title="Vehicle History"><i className="fas fa-history"></i> Vehicle History</button>
                                : null
                        }&nbsp;
                        {
                            this.state.isDocumentsAttachment == true ?
                                <button type="button" id="btnRideDocuments" className="btn btn-custom-gray btn-sm" onClick={(e) => { this.handleRideDocumentsModal(e, this.state.singleRideSource) }} title="Upload/View Attachment"><i className="fas fa-upload"></i> Attachments</button> : null
                        } &nbsp;
                        {
                            this.state.isSplitRideDisplay ?
                                <button type="button" id="btnSplitRide" className="btn btn-custom-gray btn-sm" onClick={(e) => { this.handleSplitReservationModal(e, this.state.singleRideSource) }} title="Split Ride"><i className="fas fa-unlink"></i> Split Ride</button> : null
                        }&nbsp;
                        {
                            this.state.isActualTime ?
                                <button type="button" id="btnActualRide" className="btn btn-custom-gray btn-sm" onClick={(e) => { this.handleActualTimeModal(e, this.state.singleRideSource) }} title="Actual Time"><i className="fas fa-clock"></i> Actual Time</button> : null
                        }&nbsp;
                        {
                            this.state.isUndoSplitRideDisplay ?
                                <button type="button" id="btnUndoSplit" className="btn btn-custom-gray btn-sm" onClick={(e) => { this.handleUndoSplitReservationModal(e, this.state.singleRideSource) }}>
                                    {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                    {!this.state.isSubmited && <span title="Undo Split"><i className="fas fa-undo"></i> Undo Split</span>}
                                </button> : null
                        }&nbsp;
                        {
                            this.state.isNoshowDisplay ?
                                <button type="button" id="btnNoShow" className="btn btn-custom-gray btn-sm" onClick={(e) => { this.handleNoShowReservationModal(e, this.state.singleRideSource) }} title="No Show"><i className="fas fa-eye-slash"></i> No Show</button> : null
                        }&nbsp;
                        {
                            parseInt(this.state.iMeetGreetDisplay) == parseInt(1) ?
                                <button type="button" id="btnSetMeetAndGreeterName" className="btn btn-custom-gray btn-sm" onClick={(e) => { this.handleMeetAndGreeterNameModal(e, this.state.singleRideSource) }} title="Set Meet and Greeter Name"><i className="fas fa-user-cog"></i> Set Meet and Greeter Name</button> : null
                        }&nbsp;
                        {
                            parseInt(this.state.iMeetGreetDisplay) == parseInt(2) ?
                                <button type="button" id="btnSetMeetAndGreeterName" className="btn btn-custom-gray btn-sm" onClick={(e) => { this.handleResetMeetAndGreeterNameModal(e, this.state.singleRideSource) }} title="Reset Meet and Greeter Name"><i className="fas fa-user-cog"></i> Reset Meet and Greeter Name</button> : null
                        }&nbsp;
                        {
                            this.state.dispatchStatusId === TripStatus.COMPLETED ?
                                <button type="button" id="btnRideTrackingReplay" className="btn btn-custom-gray btn-sm" onClick={(e) => { this.handleShowRideTrackingReplayModal(e, this.state.singleRideSource) }} title="Replay"><i className="fas fa-user-cog"></i> Replay</button> : null
                        }&nbsp;
                        {
                            this.state.isShowFlightStatus ?
                                <button type="button" id="btnShowFlightStatus" className="btn btn-custom-gray btn-sm" onClick={(e) => { this.handleShowFlightStatusModal(e, this.state.singleRideSource) }} title="Click on flight status and get current flight status">
                                    <i className="fas fa-plane"></i> Flight Status</button> : null
                        }
                        &nbsp;
                        {
                            (this.state.dispatchStatusId !== TripStatus.COMPLETED || this.state.dispatchStatusId !== TripStatus.AUTOCANCELLED || this.state.dispatchStatusId !== TripStatus.CANCELLED) &&
                            <button type="button" id="routeCrewAirlineMessage" className="btn btn-custom-gray btn-sm" onClick={(e) => { this.sendCrewAirlineMessageControl(e, this.state.singleRideSource) }}
                                title="Send airline crew message">
                                <i className="fas fa-sms"></i> Send Airline Crew Message
                            </button>
                        }
                        {/*<button type="button" id="btnResetMeetAndGreeterName" className="btn btn-custom-gray btn-sm"><i className="fas fa-user-cog"></i> Reset Meet and Greeter Name</button>&nbsp;*/}
                    </div>
                </div>
                <ViewReservationComponent ref="viewReservationModal" />
                <ReservationHistoryComponent ref="reservationHistoryModal" refreshDataSource={this.state.refreshDataSource} handleRefresh={this.state.handleRefresh} />
                <VoidReservationComponent ref="voidReservationModal" refreshDataSource={this.state.refreshDataSource} />
                <NoShowReservationComponent ref="noShowReservationModal" refreshDataSource={this.state.refreshDataSource} singleRideSource={this.state.singleRideSource} handleRefresh={this.state.handleRefresh} />
                <SplitReservationComponent ref="splitReservationModal" refreshDataSource={this.state.refreshDataSource} handleRefresh={this.state.handleRefresh} />
                <VehicleHistoryComponent ref="vehicleHistoryModal" refreshDataSource={this.state.refreshDataSource} handleRefresh={this.state.handleRefresh} />
                <MeetAndGreeterComponent ref="meetAndGreeterDataModel" refreshDataSource={this.state.refreshDataSource} handleRefresh={this.state.handleRefresh} />
                <LocateVehicleComponent ref="locateVehicleModal" refreshDataSource={this.state.refreshDataSource} handleRefresh={this.state.handleRefresh} />
                <DocumentsComponent ref="reservationDocumentsModal" refreshDataSource={this.state.refreshDataSource} handleRefresh={this.state.handleRefresh} />
                <ReservationActualTimeComponent ref="actualTimeModal" />
                <RideTrackingReplay ref="rideTrackingReplay" />
                <CompleteReservationComponent ref="completeReservationModal" refreshDataSource={this.state.refreshDataSource} handleRefresh={this.state.handleRefresh} />
                <ReservationDateTimeComponent ref="changeDateTimeReservationModal" refreshDataSource={this.state.refreshDataSource} handleRefresh={this.state.handleRefresh} />
                <ViewUpdateFlightStatusComponent ref="viewUpdateFlightStatusComponent" refreshDataSource={this.state.refreshDataSource} handleRefresh={this.state.handleRefresh} />
                <SendAirlineCrewMessageComponent ref="SendAirlineCrewMessageComponentModel"/>
            </form>
        );
    }
}
