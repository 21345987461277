import React, { Component } from 'react';
import DateBox from 'devextreme-react/date-box';
import { SelectBox } from 'devextreme-react/select-box';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions';
import { LoadPanel } from 'devextreme-react/load-panel';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'

const position = { of: '#historydiv' };

export class DispatchComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            isOpenModal: false,
            isEmailSubmitted: false,
            loadPanelVisible: false,

            chkCustomerSelection: "1",
            dropdownCustomer: [],
           
            customerCode: '',
            chkPaymentTypeSelection: "1",
            dropdownPaymentType: [],
            paymentTypeId: 0,

            chkPeriodSelection: "1",
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),

            chkReportTypeSelection: "1",
            chkSortBySelection: "1",

            errors: {
                customerCode: '',
                paymentTypeId: '',
                startDate: '',
                endDate: '',
            }
        }
        this.getCustomer = this.getCustomer.bind(this);
        this.handleCustomerChange = this.handleCustomerChange.bind(this);
        this.handleCustomerSelectionChange = this.handleCustomerSelectionChange.bind(this);

        this.getPaymentType = this.getPaymentType.bind(this);
        this.handlePaymentTypeChange = this.handlePaymentTypeChange.bind(this);
        this.handlePaymentTypeSelectionChange = this.handlePaymentTypeSelectionChange.bind(this);

        this.handlePeriodSelectionChange = this.handlePeriodSelectionChange.bind(this);
        this.handleFromDateChanged = this.handleFromDateChanged.bind(this);
        this.handleToDateChanged = this.handleToDateChanged.bind(this);

        this.handleReportTypeSelectionChange = this.handleReportTypeSelectionChange.bind(this);
        this.handleSortBySelectionChange = this.handleSortBySelectionChange.bind(this);
        this.downloadAccountPaymentTypeSummaryAsPdf = this.downloadAccountPaymentTypeSummaryAsPdf.bind(this);

        this.handleSearchAccountPaySummaryReportSubmit = this.handleSearchAccountPaySummaryReportSubmit.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
    }


    showModal = async (reportType) => {
        try {
            this.setState({ isOpenModal: true, chkReportTypeSelection: reportType });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getCustomer();
            await this.getPaymentType();
         
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {
        this.setState({
            isSubmited: false,
            isOpenModal: false,
            loadPanelVisible: false,
            isEmailSubmitted: false,
            chkCustomerSelection: "1",
            customerCode: '',
           

            chkPaymentTypeSelection: "1",
            paymentTypeId: 0,

            chkPeriodSelection: "1",
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),

            chkSortBySelection: "1",

            errors: {
                customerCode: '',
                paymentTypeId: '',
                startDate: '',
                endDate: '',
            }
        });
        await this.handleClearSearch();
    }

    async getCustomer() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('account-api/customer-dropdown/', requestParams);
            const data = await response.json();
            this.setState({
                dropdownCustomer: data.customers,
            });

        } catch (e) {
            console.log(e);
        }
    }
 
    handleCustomerChange(selectedOption) {
        //this.setState({ customerCode: (selectedOption != null ? selectedOption.code : ''), })
        this.setState({ customerCode: (selectedOption.selectedItem != null ? selectedOption.selectedItem.customerCode : ""), })
        this.state.errors["customerCode"] = "";
    }

    handleCustomerSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkCustomerSelection: value, customerCode: '' });
        this.state.errors["customerCode"] = "";
    }


    async getPaymentType() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('paymenttype-api/dropdownlist', requestParams);
            const data = await response.json();
            this.setState({
                dropdownPaymentType: data.paymentType,
            });

        } catch (e) {
            console.log(e);
        }
    }

    handlePaymentTypeChange(selectedOption) {
        //this.setState({ paymentTypeId: (selectedOption != null ? selectedOption.id : 0), })
        this.setState({ paymentTypeId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
        this.state.errors["paymentTypeId"] = "";
    }

    handlePaymentTypeSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkPaymentTypeSelection: value, paymentTypeId: 0 });
        this.state.errors["paymentTypeId"] = "";
    }

    handleReportTypeSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkReportTypeSelection: value });
    }

    handleSortBySelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkSortBySelection: value });
    }

    handlePeriodSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkPeriodSelection: value });
    }

    handleFromDateChanged(event) {
        let fromDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ startDate: fromDateValue })
    }
    handleToDateChanged(event) {
        let toDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ endDate: toDateValue })
    }


    handleSearchAccountPaySummaryReportSubmit(e) {
        e.preventDefault();
       
        if (this.handleValidation()) {
            this.downloadAccountPaymentTypeSummaryAsPdf();
        }
    }
    handleClearSearch() {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,
            isEmailSubmitted: false,
            chkCustomerSelection: "1",
            customerCode: '',

            chkPaymentTypeSelection: "1",
            paymentTypeId: 0,

            chkPeriodSelection: "1",
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),

            chkSortBySelection: "1",

            errors: {
                customerCode: '',
                paymentTypeId: '',
                startDate: '',
                endDate: '',
            }
        });
    }

    handleValidation() {
      
        let errors = this.state.errors;
        let formIsValid = true;
        errors.customerCode = "";
        errors.paymentTypeId = "";
        errors.startDate = "";
        errors.endDate = "";

        if (this.state.chkReportTypeSelection === "1" || this.state.chkReportTypeSelection === "2") {
            if (this.state.chkCustomerSelection === "2" && this.state.customerCode === "") {
                formIsValid = false;
                errors.customerCode = "Please select customer.";
            }
        }

        if (this.state.chkReportTypeSelection === "3" || this.state.chkReportTypeSelection === "4") {

            if (this.state.chkPaymentTypeSelection === "2" && this.state.paymentTypeId == 0) {
                formIsValid = false;
                errors.paymentTypeId = "Please select payment type.";
            }
        }
       
        if (this.state.chkPeriodSelection === "2" && this.state.startDate === "") {
            formIsValid = false;
            errors.startDate = "Please select start date.";
        }

        if (this.state.chkPeriodSelection === "2" && this.state.endDate === "") {
            formIsValid = false;
            errors.endDate = "Please select end date.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    async downloadAccountPaymentTypeSummaryAsPdf() {
        try {
           
            this.setState({
                isSubmited: true,
            });
            var RptHead = "";
            var sDate = "";
            var sAccountType = "";
            var sPayentType = "";

            var request = new Object();
            request.LocationId = Number(sessionStorage.getItem("session_locationId"));

            request.StartDate = this.state.chkPeriodSelection === "1" ? null : formatDate(this.state.startDate, "MM/dd/yyyy") + " 00:00:00";
            request.EndDate = this.state.chkPeriodSelection === "1" ? null : formatDate(this.state.endDate, "MM/dd/yyyy") + " 23:59:59";
            if (this.state.chkPeriodSelection === "1") {
                sDate = " For All Date";
            } else {
                sDate = "From Date " + formatDate(this.state.startDate, "MM/dd/yyyy") + "  To Date " + formatDate(this.state.endDate, "MM/dd/yyyy");
            }

            request.AccountCode = "";
            if (this.state.chkReportTypeSelection === "1" || this.state.chkReportTypeSelection === "2") {
                if (this.state.chkCustomerSelection === "1") {
                    sAccountType = " For All Customer";
                } else {
                    let newMode = this.state.dropdownCustomer.find(opt => opt.code == this.state.customerCode);
                    sAccountType = "For Customer " + newMode.fullName;
                    request.AccountCode = this.state.customerCode;
                }
            }

            request.PaymentType = "";
            if (this.state.chkReportTypeSelection === "3" || this.state.chkReportTypeSelection === "4") {
                if (this.state.chkPaymentTypeSelection === "1") {
                    sPayentType = " For All Payment Type";
                } else {
                    let newMode = this.state.dropdownPaymentType.find(opt => opt.id == this.state.paymentTypeId);
                    sPayentType = "For Payment Type " + newMode.name;
                    request.PaymentType = newMode.name;
                }
            }

            request.ReportType = parseInt(this.state.chkReportTypeSelection);
            if (this.state.chkSortBySelection === "1") {
                request.SortBy = "Account";
            } else if (this.state.chkSortBySelection === "1") {
                request.SortBy = "Date";
            } else {
                request.SortBy = "RideId";
            }

            RptHead = RptHead + " " + sAccountType;
            RptHead = RptHead + " " + sPayentType;
            RptHead = RptHead + " " + sDate;

            request.MethodName = "GetDispatchedReports";
            request.Header = RptHead;

            request.fileFormat = 'pdf';
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            this.setState({
                isSubmited: false,
            });

            let a = document.createElement('a');
            a.href = dataResponse.reportBase64String;

            if (this.state.chkReportTypeSelection == "1") {
                a.download = 'RidesTobeDispatched.pdf';
            } else if (this.state.chkReportTypeSelection == "2") {
                a.download = 'RidesDispatched.pdf';
            } else if (this.state.chkReportTypeSelection == "3") {
                a.download = 'ScheduledRidesNext8Hours.pdf';
            } else if (this.state.chkReportTypeSelection == "4") {
                a.download = 'ScheduledRidesBeyond8Hours.pdf';
            } 

            a.click();
        } catch (e) {
            this.setState({
                isSubmited: false,
            });
            console.log(e)
        }
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            this.setState({ isEmailSubmitted: true });
            var RptHead = "";
            var sDate = "";
            var sAccountType = "";
            var sPayentType = "";

            var request = new Object();
            request.LocationId = Number(sessionStorage.getItem("session_locationId"));

            request.StartDate = this.state.chkPeriodSelection === "1" ? null : formatDate(this.state.startDate, "MM/dd/yyyy") + " 00:00:00";
            request.EndDate = this.state.chkPeriodSelection === "1" ? null : formatDate(this.state.endDate, "MM/dd/yyyy") + " 23:59:59";
            if (this.state.chkPeriodSelection === "1") {
                sDate = " For All Date";
            } else {
                sDate = "From Date " + formatDate(this.state.startDate, "MM/dd/yyyy") + "  To Date " + formatDate(this.state.endDate, "MM/dd/yyyy");
            }

            request.AccountCode = "";
            if (this.state.chkReportTypeSelection === "1" || this.state.chkReportTypeSelection === "2") {
                if (this.state.chkCustomerSelection === "1") {
                    sAccountType = " For All Customer";
                } else {
                    let newMode = this.state.dropdownCustomer.find(opt => opt.code == this.state.customerCode);
                    sAccountType = "For Customer " + newMode.fullName;
                    request.AccountCode = this.state.customerCode;
                }
            }

            request.PaymentType = "";
            if (this.state.chkReportTypeSelection === "3" || this.state.chkReportTypeSelection === "4") {
                if (this.state.chkPaymentTypeSelection === "1") {
                    sPayentType = " For All Payment Type";
                } else {
                    let newMode = this.state.dropdownPaymentType.find(opt => opt.id == this.state.paymentTypeId);
                    sPayentType = "For Payment Type " + newMode.name;
                    request.PaymentType = newMode.name;
                }
            }

            request.ReportType = parseInt(this.state.chkReportTypeSelection);
            if (this.state.chkSortBySelection === "1") {
                request.SortBy = "Account";
            } else if (this.state.chkSortBySelection === "1") {
                request.SortBy = "Date";
            } else {
                request.SortBy = "RideId";
            }

            RptHead = RptHead + " " + sAccountType;
            RptHead = RptHead + " " + sPayentType;
            RptHead = RptHead + " " + sDate;

            request.MethodName = "GetDispatchedReports";
            request.Header = RptHead;

            request.fileFormat = 'pdf';
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();

            this.setState({ isEmailSubmitted: false });
            let fileData = dataResponse.reportBase64String;

            let downloadFileName = "", subject = "";
            if (this.state.chkReportTypeSelection == "1") {
                downloadFileName = 'RidesTobeDispatched.pdf';
                subject = "Rides To Be Dispatched";
            } else if (this.state.chkReportTypeSelection == "2") {
                downloadFileName = 'RidesDispatched.pdf';
                subject = "Rides Dispatched";
            } else if (this.state.chkReportTypeSelection == "3") {
                downloadFileName = 'ScheduledRidesNext8Hours.pdf';
                subject = "Scheduled Rides Next 8 Hours";
            } else if (this.state.chkReportTypeSelection == "4") {
                downloadFileName = 'ScheduledRidesBeyond8Hours.pdf';
                subject = "Scheduled Rides Beyond 8 Hours";
            }
           


            if (fileData !== null && fileData !== '') {
                let emailSubject = subject;
                let attachments = [];
                let file = new Object();
                file.fileData = fileData;
                file.fileName = downloadFileName
                file.fileExtension = '.pdf';
                attachments.push(file);
                this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
            }
            else {
                Swal.fire({
                    allowOutsideClick: false,
                    showCloseButton: true,
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong! Unable to process the email request.',
                })
            }

        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        var titleName = "";
        if (this.state.chkReportTypeSelection === "1") {
            titleName = "Rides To be Dispatched";
        } else if (this.state.chkReportTypeSelection === "2") {
            titleName = "Rides Dispatched";
        } else if (this.state.chkReportTypeSelection === "3") {
            titleName = "Scheduled Rides [ Next 8 Hours]";
        } else if (this.state.chkReportTypeSelection === "4") {
            titleName = "Scheduled Rides [Beyond  8  Hours]";
        } 

        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '50%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleSearchAccountPaySummaryReportSubmit}
                        style={(this.state.isSubmited || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{titleName}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{ display: (this.state.chkReportTypeSelection == "1" || this.state.chkReportTypeSelection == "2") ? 'block' : 'none' }}>
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdAllCustomer" value="1" checked={this.state.chkCustomerSelection === "1"} onChange={this.handleCustomerSelectionChange} />
                                            <label className="form-check-label">All Customer</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdSingleCustomer" value="2" checked={this.state.chkCustomerSelection === "2"} onChange={this.handleCustomerSelectionChange} />
                                            <label className="form-check-label">Single</label>
                                        </span>
                                        <SelectBox
                                            dataSource={this.state.dropdownCustomer.length > 0 ? this.state.dropdownCustomer : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="consumerId"
                                            defaultValue={0}
                                            value={this.state.customerCode}
                                            displayExpr='fullName'
                                            valueExpr='code'
                                            onSelectionChanged={this.handleCustomerChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />                                        
                                        {this.state.errors["customerCode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerCode"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12" style={{ display: (this.state.chkReportTypeSelection == "3" || this.state.chkReportTypeSelection == "4") ? 'block' : 'none' }}>
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdAllPaymentType" value="1" checked={this.state.chkPaymentTypeSelection === "1"} onChange={this.handlePaymentTypeSelectionChange} />
                                            <label className="form-check-label">All Payment Type</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdSinglePaymentType" value="2" checked={this.state.chkPaymentTypeSelection === "2"} onChange={this.handlePaymentTypeSelectionChange} />
                                            <label className="form-check-label">Single</label>
                                        </span>
                                        <SelectBox
                                            dataSource={this.state.dropdownPaymentType.length > 0 ? this.state.dropdownPaymentType : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select Payment Type"
                                            name="consumerId"
                                            defaultValue={0}
                                            value={this.state.paymentTypeId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handlePaymentTypeChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["paymentTypeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["paymentTypeId"]}</span>}
                                    </div>
                                </div>
                          
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdAllDate" value="1" checked={this.state.chkPeriodSelection === "1"} onChange={this.handlePeriodSelectionChange} />
                                            <label className="form-check-label">All Date</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdDateRange" value="2" checked={this.state.chkPeriodSelection === "2"} onChange={this.handlePeriodSelectionChange} />
                                            <label className="form-check-label">Date Range</label>
                                        </span>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.startDate}
                                            value={this.state.startDate}
                                            onValueChanged={this.handleFromDateChanged}
                                            width="100%"
                                            disabled={this.state.chkPeriodSelection === "1"}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["startDate"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["startDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>To Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.endDate}
                                            value={this.state.endDate}
                                            onValueChanged={this.handleToDateChanged}
                                            width="100%"
                                            disabled={this.state.chkPeriodSelection === "1"}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["endDate"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["endDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label htmlFor="">Sort By</label><br />
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdAccount" value="1" checked={this.state.chkSortBySelection === "1"} onChange={this.handleSortBySelectionChange} />
                                            <label className="form-check-label">Account</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdDate" value="2" checked={this.state.chkSortBySelection === "2"} onChange={this.handleSortBySelectionChange} />
                                            <label className="form-check-label">Date</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdRideId" value="3" checked={this.state.chkSortBySelection === "3"} onChange={this.handleSortBySelectionChange} />
                                            <label className="form-check-label">Ride Id</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail}>
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="button" className="btn btn-success btn-sm" title="Download" onClick={this.handleSearchAccountPaySummaryReportSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Downloadd"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                                </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        )
    }
}