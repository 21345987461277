import React, { Component } from 'react';
import { SelectBox } from 'devextreme-react/select-box';
import Swal from 'sweetalert2';
import DateBox from 'devextreme-react/date-box';
import withReactContent from 'sweetalert2-react-content';
import NumberFormat from 'react-number-format';
import NumericInput from 'react-numeric-input'; //npm install react-numeric-input --save
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { LoadPanel } from 'devextreme-react/load-panel';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

const MySwal = withReactContent(Swal)
const position = { of: '#bookingdiv' };
let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class ShuttleBookingComponent extends Component {
    constructor(props) {
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        super(props);

        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            customerList: [],
            customerId: 0,
            routeList: [],
            routeId: 0,
            startDate: new Date(currentTime),
            startTime: new Date(currentTime),
            routeStopDetails: [],
            errors: {
                customerId: "",
                routeId: "",
            }
        };
        this.getCustomerList = this.getCustomerList.bind(this);
        this.getRouteList = this.getRouteList.bind(this);
        this.handleCustomerChange = this.handleCustomerChange.bind(this);
        this.handleRouteChange = this.handleRouteChange.bind(this);
        this.handleStartDateValueChanged = this.handleStartDateValueChanged.bind(this);
        this.handleStartTimeValueChanged = this.handleStartTimeValueChanged.bind(this);

        this.handleScheduleSubmit = this.handleScheduleSubmit.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

        this.getRouteStopsList = this.getRouteStopsList.bind(this);
        this.plusPaxCount = this.plusPaxCount.bind(this);
        this.minusPaxCount = this.minusPaxCount.bind(this);
    };

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true
        });
        await this.getCustomerList();

        this.setState({
            loadPanelVisible: false
        });
    }

    async getCustomerList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('account-api/customer-dropdown/', requestParams);
        const data = await response.json();
        if (data.isSuccess) {
            this.setState({
                //customerList: data.customers != null ? data.customers.filter(f => (f.customerTypeId == 5 || f.customerTypeId == 14 || f.customerTypeId == 15 || f.customerTypeId == 16)) : [],
                customerList: data.customers != null ? data.customers : [],
            });
        }
    }

    handleCustomerChange(selectedOption) {
        this.setState({ customerId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
        this.state.errors["customerId"] = "";
        let myVar = setTimeout(() => this.getRouteList(), 200)
    }

    async getRouteList() {

        if (this.state.customerId <= 0) {
            this.setState({
                routeList: [], routeId: 0
            });
            return;
        }

        var shuttleRoutesRequest = new Object();
        shuttleRoutesRequest.LocationId = Number(sessionStorage.getItem("session_locationId"));

        this.setState({ loadPanelVisible: true });
        try {
            const requestParams = getRequestParams('POST', shuttleRoutesRequest);
            const response = await fetch('/route-api/0/list', requestParams);
            const data = await response.json();
            if (data.shuttleRoutes != null && data.shuttleRoutes.length > 0) {
                this.setState({
                    routeList: data.shuttleRoutes.filter(f => f.customerId == this.state.customerId)
                });
            }
            this.setState({ loadPanelVisible: false });
        } catch (e) {
            this.setState({ loadPanelVisible: false });
        }
    }

    handleRouteChange(selectedOption) {
        this.setState({ routeId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.routeId : 0), })
        this.state.errors["routeId"] = "";

        let myVar = setTimeout(() => this.getRouteStopsList(), 200)
    }

    async getRouteStopsList() {
        var shuttleRoutesRequest = new Object();
        shuttleRoutesRequest.LocationId = Number(sessionStorage.getItem("session_locationId"));
        try {
            const requestParams = getRequestParams('POST', shuttleRoutesRequest);
            const response = await fetch('/route-api/' + this.state.routeId + '/list', requestParams);
            const data = await response.json();

            var stopList = [];
            for (var i = 0; i < data.shuttleRoutes[0].shuttleRouteStops.length; i++) {
                var stop = new Object();
                stop.stopId = data.shuttleRoutes[0].shuttleRouteStops[i].stopId;
                stop.stopName = data.shuttleRoutes[0].shuttleRouteStops[i].stopName;
                stop.noOfPax = 0;
                stopList.push(stop);
            }

            this.setState({
                routeStopDetails: stopList
            });


        } catch (e) {
            this.setState({ loadPanelVisible: false });
        }
    }

    handleStartDateValueChanged(e) {
        this.setState({ startDate: e.value });
    }

    handleStartTimeValueChanged(e) {
        this.setState({ startTime: e.value });
    }

    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.customerId = "";
        errors.routeName = "";

        if (parseInt(this.state.customerId) == 0) {
            formIsValid = false;
            errors.customerId = "Please select customer.";
        }

        if (parseInt(this.state.routeId) == 0) {
            formIsValid = false;
            errors.routeId = "Please select route.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    async askScheduleSaveConfirm() {
        let isResult = false;
        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: "You are going to save a new schedule(s).<br> Do you want to still continue?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    async handleScheduleSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {

            if (this.state.intervalTypeId === "1" && this.state.fixedTimeDataSource.length <= 0) {
                const isValid = await Swal.fire({
                    title: "<small>Schedule Not Found</small>",
                    text: "Please enter schedule.",
                    icon: "success",
                    width: '25%',
                    allowOutsideClick: false
                });
                return;
            }

            if (this.state.intervalTypeId === "2" && this.state.ranTimeDataSource.length <= 0) {
                const isValid = await Swal.fire({
                    title: "<small>Schedule Not Found</small>",
                    text: "Please enter schedule.",
                    icon: "success",
                    width: '25%',
                    allowOutsideClick: false
                });
                return;
            }

            const isConfirm = await this.askScheduleSaveConfirm();
            if (!isConfirm) {
                return;
            }
            this.setState({ isSubmited: true });
            var newObject = new Object();
            newObject.ShuttleRouteScheduleGroup = new Object();
            newObject.ShuttleRouteScheduleGroup.SchedulesGroupId = Number(0);
            newObject.ShuttleRouteScheduleGroup.RouteId = Number(this.state.routeId);
            newObject.ShuttleRouteScheduleGroup.CustomerId = Number(this.state.customerId);
            newObject.ShuttleRouteScheduleGroup.ScheduleTypeId = Number(1);
            newObject.ShuttleRouteScheduleGroup.ShuttleCurrentDate = this.state.startDate.toLocaleDateString();
            newObject.ShuttleRouteScheduleGroup.IsActive = true;
            newObject.ShuttleRouteScheduleGroup.SchedueInterval = Number(0);
            newObject.ShuttleRouteScheduleGroup.ScheduleStartTime = this.state.startDate.toLocaleDateString() + " " + this.getHourMinutes(this.state.startTime);
            newObject.ShuttleRouteScheduleGroup.ScheduleEndTime = this.state.startDate.toLocaleDateString() + " " + this.getHourMinutes(this.state.startTime);
         
            newObject.ShuttleRouteScheduleGroup.Mon = false;
            newObject.ShuttleRouteScheduleGroup.Tue = false;
            newObject.ShuttleRouteScheduleGroup.Wed = false;
            newObject.ShuttleRouteScheduleGroup.Thu = false;
            newObject.ShuttleRouteScheduleGroup.Fri = false;
            newObject.ShuttleRouteScheduleGroup.Sat = false;
            newObject.ShuttleRouteScheduleGroup.Sun = false;

            newObject.ShuttleRouteSchedule = new Object();
            newObject.ShuttleRouteSchedule.ScheduleId = Number(0);
            newObject.ShuttleRouteSchedule.RouteId = Number(this.state.routeId);
            newObject.ShuttleRouteSchedule.CustomerId = Number(this.state.customerId);
            newObject.ShuttleRouteSchedule.IsActive = true;
            newObject.ShuttleRouteSchedule.Mon = false;
            newObject.ShuttleRouteSchedule.Tue = false;
            newObject.ShuttleRouteSchedule.Wed = false;
            newObject.ShuttleRouteSchedule.Thu = false;
            newObject.ShuttleRouteSchedule.Fri = false;
            newObject.ShuttleRouteSchedule.Sat = false;
            newObject.ShuttleRouteSchedule.Sun = false;
            newObject.ShuttleRouteSchedule.ScheduleTime =  this.getHourMinutes(this.state.startTime)

            var _StopIdPaxNo = "";

            for (var i = 0; i < this.state.routeStopDetails.length - 1; i++) {
                if (_StopIdPaxNo == "") {
                    _StopIdPaxNo = this.state.routeStopDetails[i].stopId.toString() + "-" + this.state.routeStopDetails[i].noOfPax.toString();
                } else {
                    _StopIdPaxNo += "," + this.state.routeStopDetails[i].stopId.toString() + "-" + this.state.routeStopDetails[i].noOfPax.toString();
                }
            }

            newObject.ShuttleRouteSchedule.StopIdPaxNo = _StopIdPaxNo;

            try {
                const requestParams = getRequestParams('POST', newObject);
                const response = await fetch('shuttle-schedule-api/save', requestParams);
                const data = await response.json();

                let strAlert = "";

                if (!data.isSuccess) {
                    strAlert = "Schedule booking failed due to server error.";
                } else {
                    strAlert = "Your schedule booking has been successfully created. The reservation number is: <br><br>";
                    strAlert += "\t" + data.resultMessage + "<br>";
                    strAlert += "<br> Thank you.";
                }

                Swal.fire({
                    icon: data.isSuccess ? 'success' : 'error',
                    title: data.isSuccess ? 'Success' : 'Oops...',
                    html: strAlert,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            this.handleClearSearch();
                        }
                    } else {
                        this.setState({ isSubmited: false })
                    }
                })
            } catch (e) {
                this.setState({ isSubmited: false })
            }
        }

    }

    handleClearSearch() {
        this.setState({
            customerId: 0,
            routeId: 0,
            startDate: new Date(currentTime.toLocaleDateString() + " 00:00"),
            startTime: new Date(currentTime.toLocaleDateString() + " 00:00"),
            routeStopDetails: [],
            errors: {
                customerId: "",
                routeId: "",
            }
        })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
        this.state.errors[name] = "";
    }

    plusPaxCount(e, id) {
        e.preventDefault();
        var stopList = [];
        for (var i = 0; i < this.state.routeStopDetails.length; i++) {
            var stop = new Object();
            if (this.state.routeStopDetails[i].stopId == id) {
                stop.stopId = this.state.routeStopDetails[i].stopId;
                stop.stopName = this.state.routeStopDetails[i].stopName;
                stop.noOfPax = this.state.routeStopDetails[i].noOfPax + 1;
                stopList.push(stop);
            } else {
                stop.stopId = this.state.routeStopDetails[i].stopId;
                stop.stopName = this.state.routeStopDetails[i].stopName;
                stop.noOfPax = this.state.routeStopDetails[i].noOfPax;
                stopList.push(stop);
            }
        }

        var totalPax = 0;
        for (var i = 0; i < stopList.length - 1; i++) {
            totalPax = totalPax + stopList[i].noOfPax;
        }
        stopList[stopList.length - 1].noOfPax = totalPax;

        this.setState({
            routeStopDetails: stopList
        })
    }

    minusPaxCount(e, id) {
        e.preventDefault();
        var stopList = [];
        for (var i = 0; i < this.state.routeStopDetails.length; i++) {
            var stop = new Object();
            if (this.state.routeStopDetails[i].stopId == id) {
                stop.stopId = this.state.routeStopDetails[i].stopId;
                stop.stopName = this.state.routeStopDetails[i].stopName;
                stop.noOfPax = this.state.routeStopDetails[i].noOfPax > 0 ? this.state.routeStopDetails[i].noOfPax - 1 : 0;
                stopList.push(stop);
            } else {
                stop.stopId = this.state.routeStopDetails[i].stopId;
                stop.stopName = this.state.routeStopDetails[i].stopName;
                stop.noOfPax = this.state.routeStopDetails[i].noOfPax;
                stopList.push(stop);
            }
        }

        var totalPax = 0;
        for (var i = 0; i < stopList.length - 1; i++) {
            totalPax = totalPax + stopList[i].noOfPax;
        }
        stopList[stopList.length - 1].noOfPax = totalPax;

        this.setState({
            routeStopDetails: stopList
        })
    }

    getHourMinutes(datetime) {
        var HH = new Date(datetime).getHours();
        var MM = new Date(datetime).getMinutes();

        if (parseInt(HH) < 10) {
            HH = "0" + HH;
        }

        if (parseInt(MM) < 10) {
            MM = "0" + MM;
        }

        return HH + ":" + MM;
    }


    render() {

        return (
            <div className="content-wrapper" id="bookingdiv">
                <form method="post" onSubmit={this.handleScheduleSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="content-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <h1 className="m-0 text-dark">New Booking</h1>
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-12">
                                    <ol className="breadcrumb float-sm-right">
                                        <NavItem><NavLink tag={Link} to="/stop/list" className="btn btn-block btn-outline-secondary btn-sm" title="Stops"><i className="fas fa-map-marker"></i> Stops</NavLink></NavItem>
                                        <NavItem><NavLink tag={Link} to="/route/list" className="btn btn-block btn-outline-secondary btn-sm" title="Routes"><i className="fas fa-road"></i> Routes</NavLink></NavItem>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rez-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-2 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Customer</label>
                                        <SelectBox
                                            dataSource={this.state.customerList.length > 0 ? this.state.customerList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="customerId"
                                            defaultValue={0}
                                            value={this.state.customerId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleCustomerChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Route</label>
                                        <SelectBox
                                            dataSource={this.state.routeList.length > 0 ? this.state.routeList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select route"
                                            name="routeId"
                                            defaultValue={0}
                                            value={this.state.routeId}
                                            displayExpr='routeName'
                                            valueExpr='routeId'
                                            onSelectionChanged={this.handleRouteChange}
                                            showClearButton={true}
                                            disabled={this.state.scheduleGroupId > 0}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["routeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["routeId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="">Start Date <span className="text-danger">*</span></label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="startDate"
                                            placeholder="MM/dd/yyyy"
                                            displayFormat="MM/dd/yyyy"
                                            showAnalogClock={true}
                                            useMaskBehavior={true}
                                            defaultValue={this.state.startDate}
                                            value={this.state.startDate}
                                            onValueChanged={this.handleStartDateValueChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="">Start Time <span className="text-danger">*</span></label>
                                        <DateBox type="time"
                                            className="form-control form-control-sm"
                                            name="startTime"
                                            placeholder="HH:mm"
                                            displayFormat="HH:mm"
                                            showAnalogClock={true}
                                            useMaskBehavior={true}
                                            defaultValue={this.state.startTime}
                                            value={this.state.startTime}
                                            onValueChanged={this.handleStartTimeValueChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 text-right">
                                    <div className="form-group">
                                        <label className="btn-block">&nbsp;</label>
                                        <button type="button" className="btn btn-success btn-sm" disabled={this.state.routeStopDetails.length == 0} onClick={this.handleScheduleSubmit} >
                                            {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSubmited && <span title="Save"><i className="fas fa-save"></i> Save</span>}
                                        </button>&nbsp;&nbsp;&nbsp;
                                         <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleClearSearch} title="Clear"><i className="fas fa-eraser"></i> Clear</button>&nbsp;&nbsp;&nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.goToBack} title="Back"><i className="fas fa-arrow-left"></i> Back</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {this.state.routeStopDetails.length > 0 && <div className="content">
                            <div className="card card-grid">
                                <div className="card-body">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div className="row" style={{ borderBottom: 2 + 'px solid #e6e6e6', lineHeight: 40 + 'px' }}>
                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8 stopname">Stops</div>
                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-left">Add Passenger</div>
                                        </div>
                                        <div className="row" style={{ borderBottom: 1 + 'px solid #e6e6e6', lineHeight: 40 + 'px' }}>
                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8 stopname">{this.state.routeStopDetails[0].stopName}<span className="text-green small"> (Start Point)</span></div>
                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-left counter">
                                                <a href="#" id="aplus_0" className="aplus" name="0" onClick={(e) => { this.plusPaxCount(e, this.state.routeStopDetails[0].stopId) }}><i className="fa fa-plus-circle text-green"></i></a>
                                                <span className="pax" id="spnpax_0">{this.state.routeStopDetails[0].noOfPax}</span>
                                                <a href="#" id="aminus_0" className="aminus" name="0" onClick={(e) => { this.minusPaxCount(e, this.state.routeStopDetails[0].stopId) }}><i className="fa fa-minus-circle text-green"></i></a>
                                            </div>
                                        </div>
                                        {this.state.routeStopDetails.length > 0 && this.state.routeStopDetails.map((item, j) =>
                                            (j > 0 && j < this.state.routeStopDetails.length - 1) &&
                                            <div className="row" style={{ borderBottom: 1 + 'px solid #e6e6e6', lineHeight: 40 + 'px' }}>
                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8 stopname">{item.stopName}</div>
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-left counter">
                                                    <a href="#" id="aplus_1" className="aplus" name="1" onClick={(e) => { this.plusPaxCount(e, item.stopId) }}><i className="fa fa-plus-circle text-green"></i></a>
                                                    <span className="pax" id="spnpax_1">{item.noOfPax}</span>
                                                    <a href="#" id="aminus_1" className="aminus" name="1" onClick={(e) => { this.minusPaxCount(e, item.stopId) }}><i className="fa fa-minus-circle text-green"></i></a>
                                                </div>
                                            </div>
                                        )}

                                        <div className="row" style={{ borderBottom: 1 + 'px solid #e6e6e6', lineHeight: 40 + 'px' }}>
                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8 stopname">{this.state.routeStopDetails[this.state.routeStopDetails.length - 1].stopName}<span className="text-red small"> (End Point)</span></div>
                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-left counter">Drop off: <strong><span id="spntotalpaxcount" className="pax" style={{ color: 'blue' }}>{this.state.routeStopDetails[this.state.routeStopDetails.length - 1].noOfPax}</span></strong></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>

                    <LoadPanel
                        shadingColor="rgba(0,0,0,0.4)"
                        position={position}
                        visible={this.state.loadPanelVisible}
                        showIndicator={true}
                        shading={true}
                        showPane={true}
                        closeOnOutsideClick={false}
                    />
                </form>
            </div>
        );
    }
}




