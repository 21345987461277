import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { SelectBox } from 'devextreme-react/select-box';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import { LoadPanel } from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'
let currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class IATAInvoiceReportComponent extends Component {
    constructor() {
        currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        super()
        this.state = {
            isSubmitted: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            customerDropdownList: [],
            customerDropdownData: null,
            customerCode: '',
            isAllDate: true,
            isDateRange: false,
            fromDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
            toDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
            isAllInvoice: true,
            isSingleInvoice: false,
            isRangeInvoice: false,
            fromInvoiceNumber: '',
            toInvoiceNumber: '',
            errors: {
                customer: '',
                fromDate: '',
                toDate: '',
                fromInvoiceNumber: '',
                toInvoiceNumber: ''
            }
        }
    }
    getCustomerDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('account-api/customer-dropdown/', requestParams);
            const data = await response.json();
            this.setState({
                customerDropdownList: data.customers
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getCustomerDropdownList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isEmailSubmitted: false,
                isOpenModal: false,
                loadPanelVisible: false,
                customerDropdownList: [],
                customerDropdownData: null,
                customerCode: '',
                isAllDate: true,
                isDateRange: false,
                fromDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
                toDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
                isAllInvoice: true,
                isSingleInvoice: false,
                isRangeInvoice: false,
                fromInvoiceNumber: '',
                toInvoiceNumber: '',
                errors: {
                    customer: '',
                    fromDate: '',
                    toDate: '',
                    fromInvoiceNumber: '',
                    toInvoiceNumber: ''
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnClearSearch = async () => {
        try {
            currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
            this.setState({
                isSubmitted: false,
                isEmailSubmitted: false,
                loadPanelVisible: false,
                customerDropdownData: null,
                customerCode: '',
                isAllDate: true,
                isDateRange: false,
                fromDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
                toDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
                isAllInvoice: true,
                isSingleInvoice: false,
                isRangeInvoice: false,
                fromInvoiceNumber: '',
                toInvoiceNumber: '',
                errors: {
                    customer: '',
                    fromDate: '',
                    toDate: '',
                    fromInvoiceNumber: '',
                    toInvoiceNumber: ''
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnInputChange = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
            let errors = this.state.errors;
            const regexNumberOnly = /^\d+$/;
            switch (name) {
                case 'isAllDate':
                    errors.fromDate = '';
                    errors.toDate = '';
                    this.setState({ isAllDate: value, isDateRange: false });
                    break;
                case 'isDateRange':
                    errors.fromDate = '';
                    errors.toDate = '';
                    this.setState({ isAllDate: false, isDateRange: value });
                    break;
                case 'isAllInvoice':
                    errors.fromInvoiceNumber = '';
                    errors.toInvoiceNumber = '';
                    this.setState({
                        isAllInvoice: value,
                        isSingleInvoice: false,
                        isRangeInvoice: false,
                        fromInvoiceNumber: '',
                        toInvoiceNumber: '',
                    });
                    break;
                case 'isSingleInvoice':
                    errors.fromInvoiceNumber = '';
                    errors.toInvoiceNumber = '';
                    this.setState({
                        isAllInvoice: false,
                        isSingleInvoice: value,
                        isRangeInvoice: false,
                        fromInvoiceNumber: '',
                        toInvoiceNumber: '',
                    });
                    break;
                case 'isRangeInvoice':
                    errors.fromInvoiceNumber = '';
                    errors.toInvoiceNumber = '';
                    this.setState({
                        isAllInvoice: false,
                        isSingleInvoice: false,
                        isRangeInvoice: value,
                        fromInvoiceNumber: '',
                        toInvoiceNumber: '',
                    });
                    break;
                case 'fromInvoiceNumber':
                    errors.fromInvoiceNumber = '';
                    if (value.trim() === '' || regexNumberOnly.test(value)) {
                        if (value.trim().length < 10) {
                            this.setState({ fromInvoiceNumber: value });
                        }
                        if (value.trim() === '') {
                            //errors.fromInvoiceNumber = 'Please enter invoice number.';
                        }
                        if (Number(this.state.toInvoiceNumber) > 0) {
                            errors.toInvoiceNumber = '';
                        }
                    }
                    break;
                case 'toInvoiceNumber':
                    errors.toInvoiceNumber = '';
                    if (value.trim() === '' || regexNumberOnly.test(value)) {
                        if (value.trim().length < 10) {
                            this.setState({ toInvoiceNumber: value });
                        }
                        if (value.trim() === '') {
                            //errors.toInvoiceNumber = 'Please enter invoice number.';
                        }
                        if (Number(this.state.fromInvoiceNumber) > 0) {
                            errors.fromInvoiceNumber = '';
                        }
                    }
                    break;
                default:
                    break;
            }
            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnCustomerDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.customer = '';
            let customerCode = '';
            let customerDropdownData = null;
            if (data !== null && data.selectedItem !== null) {
                customerCode = (data.selectedItem !== null ? data.selectedItem.code : "");
                customerDropdownData = data.selectedItem;
            }
            else {
                //errors.customer = 'Please select customer.';
            }
            this.setState({ customerDropdownData: customerDropdownData, customerCode: customerCode, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnFromDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.fromDate = '';
            let fromDate = '';
            if (event.value !== null && event.value !== '') {
                fromDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.fromDate = 'Please select date.';
            }
            if (this.state.toDate.trim() !== '') {
                errors.toDate = '';
            }
            this.setState({ fromDate: fromDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnToDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.toDate = '';
            let toDate = '';
            if (event.value !== null && event.value !== '') {
                toDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.toDate = 'Please select date.';
            }
            if (this.state.fromDate.trim() !== '') {
                errors.fromDate = '';
            }
            this.setState({ toDate: toDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnReportSubmit = async (event) => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isSubmitted: true });
                var request = {};
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.AccountCode = this.state.customerCode;
                if (this.state.isDateRange === true) {
                    request.StartDate = this.state.fromDate;
                    request.EndDate = this.state.toDate;
                }
                request.InvoiceNo = Number(this.state.fromInvoiceNumber);
                request.Number1 = Number(this.state.toInvoiceNumber);
                request.SelectionCrieteria = await this.setSelectionCrieteria();
                request.fileFormat = 'pdf';
                request.MethodName = "GetIATAInvoiceReport";
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                let a = document.createElement('a');
                a.href = dataResponse.reportBase64String;
                a.download = 'IATAInvoice.pdf';
                a.click();
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false });
    }
    isFormValid = async () => {
        let isValid = true;
        let errors = this.state.errors;
        errors.customer = '';
        errors.fromDate = '';
        errors.toDate = '';
        errors.fromInvoiceNumber = '';
        errors.toInvoiceNumber = '';
        try {
            if (isNaN(this.state.customerCode) || this.state.customerCode.trim() === '' || Object.keys(this.state.customerDropdownData).length === 0) {
                errors.customer = 'Please select customer.';
                isValid = false;
            }
            if (this.state.isAllDate === false) {
                if (this.state.isDateRange === true) {
                    if (this.state.fromDate.trim() === '') {
                        errors.fromDate = 'Please select date.';
                        isValid = false;
                    }
                    if (this.state.toDate.trim() === '') {
                        errors.toDate = 'Please select date.';
                        isValid = false;
                    }
                    if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                        if (moment(this.state.fromDate, 'MM/DD/YYYY').isAfter(this.state.toDate, 'MM/DD/YYYY')) {
                            errors.fromDate = 'Please select a valid date range.';
                            errors.toDate = 'Please select a valid date range.';
                            isValid = false;
                        }
                    }
                }
            }
            if (this.state.isAllInvoice === false) {
                if (this.state.isSingleInvoice === true) {
                    if (Number(this.state.fromInvoiceNumber) === 0) {
                        errors.fromInvoiceNumber = 'Please enter invoice number.';
                        isValid = false;
                    }
                }
                if (this.state.isRangeInvoice === true) {
                    if (Number(this.state.fromInvoiceNumber) === 0) {
                        errors.fromInvoiceNumber = 'Please enter invoice number.';
                        isValid = false;
                    }
                    if (Number(this.state.toInvoiceNumber) === 0) {
                        errors.toInvoiceNumber = 'Please enter invoice number.';
                        isValid = false;
                    }
                    if (Number(this.state.fromInvoiceNumber) > 0 && Number(this.state.toInvoiceNumber) > 0) {
                        if (Number(this.state.fromInvoiceNumber) > Number(this.state.toInvoiceNumber)) {
                            errors.fromInvoiceNumber = 'Please enter a valid invoice number range.';
                            errors.toInvoiceNumber = 'Please enter a valid invoice number range.';
                            isValid = false;
                        }
                    }
                }
                if (this.state.isSingleInvoice === false && this.state.isRangeInvoice === false) {
                    errors.fromInvoiceNumber = 'Please select invoice option.';
                    errors.toInvoiceNumber = 'Please select invoice option.';
                    isValid = false;
                }
            }
            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    setSelectionCrieteria = async () => {
        let selectionCrieteria = 'Invoice details for';
        try {
            if (this.state.customerDropdownData !== null && Object.keys(this.state.customerDropdownData).length > 0) {
                selectionCrieteria += ' customer - ' + this.state.customerDropdownData.fullName;
            }
            if (this.state.isDateRange === true) {
                if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                    selectionCrieteria += ', date from ' + this.state.fromDate + ' to ' + this.state.toDate;
                }
            }
            else {
                selectionCrieteria += ', all date';
            }
            if (Number(this.state.fromInvoiceNumber) === 0 && Number(this.state.toInvoiceNumber) === 0) {
                selectionCrieteria += ', all invoices';
            }
            else {
                if (Number(this.state.fromInvoiceNumber) !== 0 && Number(this.state.toInvoiceNumber) === 0) {
                    selectionCrieteria += ', invoice - ' + this.state.fromInvoiceNumber;
                }
                if (Number(this.state.fromInvoiceNumber) !== 0 && Number(this.state.toInvoiceNumber) !== 0) {
                    selectionCrieteria += ', invoice from ' + this.state.fromInvoiceNumber + ' to ' + this.state.toInvoiceNumber;
                }
            }

        } catch (ex) {
            selectionCrieteria = '';
            console.log(ex);
        }
        return selectionCrieteria;
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isEmailSubmitted: true });
                var request = {};
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.AccountCode = this.state.customerCode;
                if (this.state.isDateRange === true) {
                    request.StartDate = this.state.fromDate;
                    request.EndDate = this.state.toDate;
                }
                request.InvoiceNo = Number(this.state.fromInvoiceNumber);
                request.Number1 = Number(this.state.toInvoiceNumber);
                request.SelectionCrieteria = await this.setSelectionCrieteria();
                request.fileFormat = 'pdf';
                request.MethodName = "GetIATAInvoiceReport";
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'IATA Invoice';
                    let attachments = [];
                    let file = {};
                    file.fileData = fileData;
                    file.fileName = 'IATAInvoice.pdf'
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '35%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleOnReportSubmit}
                        style={(this.state.isSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'IATA Invoice'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Customer</label>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder="Select customer"*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.customerDropdownData}*/}
                                        {/*    name="ddcustomer"*/}
                                        {/*    onChange={this.handleOnCustomerDropdownChange}*/}
                                        {/*    getOptionLabel={option => option.fullName}*/}
                                        {/*    getOptionValue={option => option.code}*/}
                                        {/*    options={this.state.customerDropdownList}*/}
                                        {/*    isMulti={false}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*    isDisabled={false}*/}
                                        {/*/>*/}

                                        <SelectBox
                                            dataSource={this.state.customerDropdownList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="ddcustomer"
                                            defaultValue={''}
                                            value={this.state.customerCode}
                                            displayExpr='fullName'
                                            valueExpr='code'
                                            onSelectionChanged={this.handleOnCustomerDropdownChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customer"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["customer"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isAllDate"
                                                checked={this.state.isAllDate}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isAllDate"> All Date (Invoice)</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isDateRange"
                                                checked={this.state.isDateRange}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isDateRange"> In Range</label>
                                        </div>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="fromDate"
                                            placeholder="MM/dd/yyyy"
                                            defaultValue={this.state.fromDate}
                                            value={this.state.fromDate}
                                            onValueChanged={this.handleOnFromDateChange}
                                            width={'100%'}
                                            disabled={this.state.isAllDate}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["fromDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["fromDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label htmlFor=""><br /></label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="toDate"
                                            placeholder="MM/dd/yyyy"
                                            defaultValue={this.state.toDate}
                                            value={this.state.toDate}
                                            min={this.state.fromDate}
                                            onValueChanged={this.handleOnToDateChange}
                                            width={'100%'}
                                            disabled={this.state.isAllDate}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["toDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["toDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isAllInvoice"
                                                checked={this.state.isAllInvoice}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isAllInvoice"> All Invoice</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isSingleInvoice"
                                                checked={this.state.isSingleInvoice}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isSingleInvoice"> Single</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isRangeInvoice"
                                                checked={this.state.isRangeInvoice}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isRangeInvoice"> In Range</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <input type="text"
                                            className="form-control form-textbox form-control-sm" name="fromInvoiceNumber"
                                            title="Invoice number" placeholder={(this.state.isRangeInvoice) ? 'Enter from invoice' : 'Enter invoice number'}
                                            value={this.state.fromInvoiceNumber}
                                            onChange={this.handleOnInputChange}
                                            disabled={this.state.isAllInvoice}
                                        />
                                        {this.state.errors["fromInvoiceNumber"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["fromInvoiceNumber"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <input type="text"
                                            className="form-control form-textbox form-control-sm" name="toInvoiceNumber"
                                            title="Invoice number" placeholder={(this.state.isRangeInvoice) ? 'Enter to invoice' : 'Enter invoice number'}
                                            value={this.state.toInvoiceNumber}
                                            onChange={this.handleOnInputChange}
                                            disabled={(!this.state.isRangeInvoice)}
                                        />
                                        {this.state.errors["toInvoiceNumber"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["toInvoiceNumber"]}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="submit" className="btn btn-success btn-sm" title="Download">
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleOnClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                            </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        );
    }
}

