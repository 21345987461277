import React from "react";

const ProgressBar = (props) => {
    const { bgcolor, completed } = props;
    const containerStyles = {
        height: 17,
        width: '100%',
        backgroundColor: '#e0e0de',
        borderRadius: 50,
        margin: 0,
        fontSize: '13px',
    }

    const fillerStyles = {
        height: '100%',
        width: `${(completed < 8 ? 8 : completed)}%`,
        backgroundColor: bgcolor,
        borderRadius: 'inherit',
        textAlign: 'right',
    }

    const labelStyles = {
        padding: 5,
        color: 'yellow',
        fontWeight: 'bold'
    }
    return (
        <div style={containerStyles}>
            <div style={fillerStyles}>
                <span style={labelStyles}>{`${completed}%`}</span>
            </div>
        </div>
    );
};

export default ProgressBar;