import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import DatePicker from "react-datepicker"; // npm install react - datepicker --save
import { LoadPanel } from 'devextreme-react/load-panel';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { DateFormat } from '../../helpers/fixcodes';

let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class ViewUpdateFlightStatusComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpenModal: false,
            isSubmitted: false,
            loadPanelVisible: false,
            rideId: 0,
            pickupDatetime: null,
            airportCode: '',
            flightDirection: false, //flightDirection = false[arr] , true[dep]
            flightDirectionText: '',
            flightCode: '',
            flightNumber: '',
            flightStatus: '',
            flightScheduleTime: '',
            flightEstimatedTime: '',
            flightActualTime: '',
            airportTerminal: '',
            airportGate: '',
            isUpdatePickupDatetime: false,
            refreshRideSource: props.refreshDataSource,
            handleRefresh: props.handleRefresh,
            isFlightDataFound: false,
            isPickupAirport: false,
            allowSocketConnection: sessionStorage.getItem("session_allowSocketConnection") === "true" ? true : false,
            flightStatusDisplay: {
                value: '',
                cssStatus: ''
            },
            flightStatusSummary: {
                flightCalEstimatedStatus: '', // 'Expected to depart in',
                cssEstStatus: '',
                flightCalEstimatedTime: '', // '1 hours 30 minutes before',
                cssEstTime: '',
                flightCalDelay: '', // '(27 minutes late)',
                cssDelay: ''
            },
            errors: {
                pickupDatetime: ''
            }
        }
    }
    showModal = (rideId, pickupDatetime) => {
        try {
            this.setState({
                isOpenModal: true,
                rideId: rideId,
                pickupDatetime: new Date(pickupDatetime),
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            await this.getFlightTrackRide();
            await this.setPickupDateTime();
            await this.setFlightStatus();
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isOpenModal: false,
                isSubmitted: false,
                loadPanelVisible: false,
                rideId: 0,
                pickupDatetime: null,
                airportCode: '',
                flightDirection: false, //flightDirection = false[arr] , true[dep]
                flightDirectionText: '',
                flightCode: '',
                flightNumber: '',
                flightStatus: '',
                flightScheduleTime: '',
                flightEstimatedTime: '',
                flightActualTime: '',
                airportTerminal: '',
                airportGate: '',
                isUpdatePickupDatetime: false,
                isFlightDataFound: false,
                isPickupAirport: false,
                flightStatusDisplay: {
                    value: '',
                    cssStatus: ''
                },
                flightStatusSummary: {
                    flightCalEstimatedStatus: '', // 'Expected to depart in',
                    cssEstStatus: '',
                    flightCalEstimatedTime: '', // '1 hours 30 minutes before',
                    cssEstTime: '',
                    flightCalDelay: '', // '(27 minutes late)',
                    cssDelay: ''
                },
                errors: {
                    pickupDatetime: ''
                }
            });
            if (!this.state.allowSocketConnection) {
                this.state.handleRefresh(true);
                this.state.refreshRideSource();
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    getFlightTrackRide = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            const requestParams = getRequestParams('GET');
            const response = await fetch('flight-track-api/' + Number(this.state.rideId) + '/get-flight-track-ride', requestParams);
            const data = await response.json();
            if (data !== null && data.isSuccess === true) {
                let flightRide = data.flightRide;
                let airportCode = flightRide.airportCode ?? '';
                let flightDirection = flightRide.flightDirection;
                let flightDirectionText = flightRide.flightDirection === false ? 'Arrival' : 'Departure';
                let flightCode = flightRide.airlineCode ?? '';
                let flightNumber = flightRide.airlineNo ?? '';
                let flightStatus = flightRide.flightStatus ?? '';
                let flightScheduleTime = flightRide.flightSTA ?? '';
                let flightEstimatedTime = flightRide.flightETA ?? '';
                let flightActualTime = flightRide.flightATA ?? '';
                let airportTerminal = flightRide.flightTerminal ?? '';
                let airportGate = flightRide.flightGate ?? '';
                let isPickupAirport = (flightDirection === false) ? true : false; //if flightDirection = false[arr] pickup airpot ,  else true[dep] dropoff airport
                flightCode = (flightCode.trim() === '0') ? '' : flightCode.trim();
                flightNumber = (flightNumber.trim() === '0') ? '' : flightNumber.trim();
                flightStatus = flightStatus.trim();

                airportTerminal = (airportTerminal.trim() === '') ? '' : airportTerminal;
                airportGate = (airportGate.trim() === '') ? '' : airportGate;
                flightScheduleTime = (flightScheduleTime.trim() === '') ? '' : moment(flightScheduleTime, 'MM/DD/YYYY HH:mm').format('MM/DD/YYYY HH:mm');
                flightEstimatedTime = (flightEstimatedTime.trim() === '') ? '' : moment(flightEstimatedTime, 'MM/DD/YYYY HH:mm').format('MM/DD/YYYY HH:mm');
                flightActualTime = (flightActualTime.trim() === '') ? '' : moment(flightActualTime, 'MM/DD/YYYY HH:mm').format('MM/DD/YYYY HH:mm');
                this.setState({
                    airportCode: airportCode,
                    flightDirection: flightDirection,
                    flightDirectionText: flightDirectionText,
                    flightCode: flightCode,
                    flightNumber: flightNumber,
                    isPickupAirport: isPickupAirport,
                    flightStatus: flightStatus,
                    flightScheduleTime: flightScheduleTime,
                    flightEstimatedTime: flightEstimatedTime,
                    flightActualTime: flightActualTime,
                    airportTerminal: airportTerminal,
                    airportGate: airportGate
                });

                if (flightStatus !== 'L') {
                    if (flightCode.trim() === '' || flightNumber.trim() === '') {
                        await this.showMessage('info', '', 'Invalid flight details.')
                        await new Promise(resolve => setTimeout(resolve, 500)); // 1 sec
                        this.handleOnCloseModal();
                    }
                    else {
                        //todo: fetch live flight details                        
                        const requestFlightStatsKeysParams = getRequestParams('GET');
                        const responseFlightStatsKeys = await fetch('flight-track-api/get-flight-stats-keys', requestFlightStatsKeysParams);
                        const FlightStatsKeysData = await responseFlightStatsKeys.json();
                        if (FlightStatsKeysData !== null && FlightStatsKeysData.isSuccess === true) {
                            let requestFlightTrack = {};
                            requestFlightTrack.FSAPPID = FlightStatsKeysData.fsAppId;
                            requestFlightTrack.FSAPPKEY = FlightStatsKeysData.fsAppKey;
                            requestFlightTrack.FlightCode = this.state.flightCode;
                            requestFlightTrack.FlightNumber = this.state.flightNumber;
                            requestFlightTrack.AirportCode = this.state.airportCode;
                            requestFlightTrack.FlightDirection = this.state.flightDirection;
                            requestFlightTrack.Year = Number(moment(this.state.pickupDatetime, 'MM/DD/YYYY HH:mm').format('YYYY'));
                            requestFlightTrack.Month = Number(moment(this.state.pickupDatetime, 'MM/DD/YYYY HH:mm').format('MM'));
                            requestFlightTrack.Day = Number(moment(this.state.pickupDatetime, 'MM/DD/YYYY HH:mm').format('DD'));
                            const requestFlightTrackParams = getRequestParams('POST', requestFlightTrack);
                            const responseFlightTrack = await fetch('flight-track-api/get-flight-track-status', requestFlightTrackParams);
                            const flightTrackResponse = await responseFlightTrack.json();

                            await this.insertFlightTrackApiLog(flightCode, flightNumber, airportCode, flightTrackResponse.isSuccess, flightTrackResponse.resultMessage)

                            if (flightTrackResponse !== null && flightTrackResponse.isSuccess === true) {
                                let flightTrack = flightTrackResponse.flightStatus
                                flightStatus = flightTrack.flightStatus ?? '';
                                flightStatus = flightStatus.trim();
                                if (flightRide.flightDirection === false) {
                                    flightScheduleTime = flightTrack.scheduledGateArrivalDateLocal ?? '';
                                    flightEstimatedTime = flightTrack.estimatedGateArrivalDateLocal ?? '';
                                    flightActualTime = flightTrack.actualGateArrivalDateLocal ?? '';
                                    airportTerminal = flightTrack.airportResourcesArrivalTerminal ?? '';
                                    airportGate = flightTrack.airportResourcesArrivalGate ?? '';
                                }
                                else {
                                    flightScheduleTime = flightTrack.scheduledGateDepartureDateLocal ?? '';
                                    flightEstimatedTime = flightTrack.estimatedGateDepartureDateLocal ?? '';
                                    flightActualTime = flightTrack.actualGateDepartureDateLocal ?? '';
                                    airportTerminal = flightTrack.airportResourcesDepartureTerminal ?? '';
                                    airportGate = flightTrack.airportResourcesDepartureGate ?? '';
                                }

                                airportTerminal = (airportTerminal.trim() === '') ? '' : airportTerminal;
                                airportGate = (airportGate.trim() === '') ? '' : airportGate;
                                flightScheduleTime = (flightScheduleTime.trim() === '') ? '' : moment(flightScheduleTime, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY HH:mm');
                                flightEstimatedTime = (flightEstimatedTime.trim() === '') ? '' : moment(flightEstimatedTime, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY HH:mm');
                                flightActualTime = (flightActualTime.trim() === '') ? '' : moment(flightActualTime, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY HH:mm');

                                this.setState({
                                    flightStatus: flightStatus,
                                    flightScheduleTime: flightScheduleTime,
                                    flightEstimatedTime: flightEstimatedTime,
                                    flightActualTime: flightActualTime,
                                    airportTerminal: airportTerminal,
                                    airportGate: airportGate,
                                    isFlightDataFound: true,
                                    //isUpdatePickupDatetime: true
                                });
                            }
                        }
                    }
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    insertFlightTrackApiLog = async (flightCode, flightNumber, airportCode, isFlightLiveStatusUpdate, flightTrackStatusMsg) => {
        try {
            let request = {};
            request.RideId = Number(this.state.rideId);
            request.AirlineCode = flightCode;
            request.AirlineNo = flightNumber;
            request.AirportCode = airportCode;
            request.IsFlightLiveStatusUpdate = isFlightLiveStatusUpdate;
            request.FlightStatusMessage = flightTrackStatusMsg;
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('flight-track-api/insert-flight-track-log', requestParams);
            const data = await response.json();
            if (data !== null && data.isSuccess === true) {
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    setPickupDateTime = async () => {
        try {
            //flightDirection = false[arr] pickup airport , true[dep] dropoff airport
            if (this.state.isPickupAirport === true) {
                let pickupDatetime = this.state.pickupDatetime;
                if (this.state.flightActualTime.trim() !== '') {
                    pickupDatetime = this.state.flightActualTime;
                }
                else if (this.state.flightEstimatedTime.trim() !== '') {
                    pickupDatetime = this.state.flightEstimatedTime;
                }
                pickupDatetime = new Date(pickupDatetime);
                this.setState({ pickupDatetime: pickupDatetime });
            }
            await this.calculateFlightTiming();
        } catch (ex) {
            console.log(ex);
        }
    }
    setFlightStatus = async () => {
        let flightStatus = 'Scheduled';
        let flightStatusCss = 'form-control form-control-sm ';
        let flightStatusDisplay = this.state.flightStatusDisplay;
        try {
            switch (this.state.flightStatus) {
                case 'A':
                    flightStatus = 'Active';
                    flightStatusCss += 'text-blue';
                    break;
                case 'C':
                    flightStatus = 'Cancelled';
                    flightStatusCss += 'text-red';
                    break;
                case 'D':
                    flightStatus = 'Diverted';
                    flightStatusCss += 'text-red';
                    break;
                case 'DN':
                    flightStatus = 'Data source needed';
                    flightStatusCss += 'text-red';
                    break;
                case 'L':
                    flightStatus = 'Landed';
                    flightStatusCss += 'text-green';
                    break;
                case 'NO':
                    flightStatus = 'Not Operational';
                    flightStatusCss += 'text-red';
                    break;
                case 'R':
                    flightStatus = 'Redirected';
                    flightStatusCss += 'text-red';
                    break;
                case 'S':
                    flightStatus = 'Scheduled';
                    flightStatusCss += 'text-blue';
                    break;
                default:
                    flightStatus = 'Scheduled';
                    flightStatusCss += 'text-blue';
                    break;
            }
        } catch (ex) {
            console.log(ex);
        }
        flightStatusDisplay.value = flightStatus;
        flightStatusDisplay.cssStatus = flightStatusCss;
        this.setState({ flightStatusDisplay: flightStatusDisplay });
    }
    calculateFlightTiming = async () => {
        try {
            let now = null;
            let flArrtime = null;
            let flDepTime = null;
            let duration = null;
            let days = 0;
            let hours = 0;
            let minutes = 0;
            let msgStatus = '';
            let flightStatusSummary = this.state.flightStatusSummary;
            flightStatusSummary.flightCalEstimatedStatus = '';
            flightStatusSummary.cssEstStatus = '';
            flightStatusSummary.flightCalEstimatedTime = '';
            flightStatusSummary.cssEstTime = '';
            flightStatusSummary.flightCalDelay = '';
            flightStatusSummary.cssDelay = '';
            if (this.state.isPickupAirport === true) {
                if (this.state.flightStatus === 'L') {
                    if (this.state.flightActualTime !== '') {
                        flArrtime = moment(this.state.flightActualTime, 'MM/DD/YYYY HH:mm');
                        now = moment(new Date());
                        duration = moment.duration(now.diff(flArrtime));
                        days = duration.days();
                        hours = duration.hours();
                        minutes = duration.minutes();
                        if (Number(days) > 0) {
                            msgStatus = days + ' days ';
                        }
                        if (Number(hours) > 0) {
                            msgStatus += hours + ' hours ';
                        }
                        if (Number(minutes) > 0) {
                            msgStatus += minutes + ' minutes ';
                        }
                        if (msgStatus.trim() !== '') {
                            msgStatus += ' ago';

                            flightStatusSummary.flightCalEstimatedStatus = 'Arrived';
                            flightStatusSummary.cssEstStatus = 'text-danger';
                            flightStatusSummary.cssEstTime = 'text-danger';
                            flightStatusSummary.flightCalEstimatedTime = msgStatus;
                            this.setState({ flightStatusSummary: flightStatusSummary });
                        }
                    }
                }
                else {
                    let estimetedTime = '';
                    if (this.state.flightActualTime !== '') {
                        estimetedTime = this.state.flightActualTime;
                    }
                    else if (this.state.flightEstimatedTime !== '') {
                        estimetedTime = this.state.flightEstimatedTime;
                    }
                    else {
                        if (this.state.flightScheduleTime !== '') {
                            estimetedTime = this.state.flightScheduleTime;
                        }
                    }
                    if (estimetedTime !== '') {
                        flArrtime = moment(estimetedTime, 'MM/DD/YYYY HH:mm');
                        now = moment(new Date());
                        duration = moment.duration(flArrtime.diff(now));
                        days = duration.days();
                        hours = duration.hours();
                        minutes = duration.minutes();

                        if (Number(days) > 0) {
                            msgStatus = days + ' days ';
                        }
                        if (Number(hours) > 0) {
                            msgStatus += hours + ' hours '
                        }
                        if (Number(minutes) > 0) {
                            msgStatus += minutes + ' minutes '
                        }
                        if (msgStatus.trim() !== '') {

                            flightStatusSummary.flightCalEstimatedStatus = 'Arriving in';
                            flightStatusSummary.cssEstStatus = 'text-info';
                            flightStatusSummary.cssEstTime = 'text-info';
                            flightStatusSummary.flightCalEstimatedTime = msgStatus;
                            this.setState({ flightStatusSummary: flightStatusSummary });
                        }
                    }
                }
            }
            else {
                if (this.state.flightActualTime !== '') {
                    flDepTime = moment(this.state.flightActualTime, 'MM/DD/YYYY HH:mm');
                    now = moment(new Date());
                    duration = moment.duration(now.diff(flDepTime));
                    days = duration.days();
                    hours = duration.hours();
                    minutes = duration.minutes();

                    if (duration.asMinutes() > 0) {
                        if (Number(days) > 0) {
                            msgStatus = days + ' days ';
                        }
                        if (Number(hours) > 0) {
                            msgStatus += hours + ' hours '
                        }
                        if (Number(minutes) > 0) {
                            msgStatus += minutes + ' minutes '
                        }
                        if (msgStatus.trim() !== '') {
                            msgStatus += ' before'

                            flightStatusSummary.flightCalEstimatedStatus = 'Departed';
                            flightStatusSummary.cssEstStatus = 'text-danger';
                            flightStatusSummary.cssEstTime = 'text-danger';
                            flightStatusSummary.flightCalEstimatedTime = msgStatus;
                            this.setState({ flightStatusSummary: flightStatusSummary });
                        }
                    }
                }
                else {
                    let depTime = '';
                    if (this.state.flightEstimatedTime !== '') {
                        depTime = this.state.flightEstimatedTime;
                    }
                    else {
                        if (this.state.flightScheduleTime !== '') {
                            depTime = this.state.flightScheduleTime;
                        }
                    }
                    if (depTime !== '') {
                        flDepTime = moment(depTime, 'MM/DD/YYYY HH:mm');
                        now = moment(new Date());
                        duration = moment.duration(flDepTime.diff(now));
                        days = duration.days();
                        hours = duration.hours();
                        minutes = duration.minutes();

                        if (Number(days) > 0) {
                            msgStatus = days + ' days ';
                        }
                        if (Number(hours) > 0) {
                            msgStatus += hours + ' hours '
                        }
                        if (Number(minutes) > 0) {
                            msgStatus += minutes + ' minutes '
                        }
                        if (msgStatus.trim() !== '') {

                            flightStatusSummary.flightCalEstimatedStatus = 'Expected to depart in';
                            flightStatusSummary.cssEstStatus = 'text-info';
                            flightStatusSummary.cssEstTime = 'text-info';
                            flightStatusSummary.flightCalEstimatedTime = msgStatus;
                            this.setState({ flightStatusSummary: flightStatusSummary });
                        }
                    }
                }
            }

            let delayTime = '';
            if (this.state.flightActualTime !== '') {
                delayTime = this.state.flightActualTime;
            }
            else {
                if (this.state.flightEstimatedTime !== '') {
                    delayTime = this.state.flightEstimatedTime;
                }
            }
            if (delayTime !== '' && this.state.flightScheduleTime !== '') {
                let flTime = moment(delayTime, 'MM/DD/YYYY HH:mm');
                let schTime = moment(this.state.flightScheduleTime, 'MM/DD/YYYY HH:mm');

                duration = moment.duration(flTime.diff(schTime));

                if (duration.asMinutes() <= 0) {

                    flightStatusSummary.flightCalDelay = '(On time)';
                    flightStatusSummary.cssDelay = 'text-info';
                    this.setState({ flightStatusSummary: flightStatusSummary });
                }
                else {
                    days = duration.days();
                    hours = duration.hours();
                    minutes = duration.minutes();

                    if (Number(days) > 0) {
                        msgStatus = days + ' days ';
                    }
                    if (Number(hours) > 0) {
                        msgStatus += hours + ' hours '
                    }
                    if (Number(minutes) > 0) {
                        msgStatus += minutes + ' minutes '
                    }
                    if (msgStatus.trim() !== '') {
                        flightStatusSummary.flightCalDelay = '( ' + msgStatus + ' late )';
                        flightStatusSummary.cssDelay = 'text-danger';
                        this.setState({ flightStatusSummary: flightStatusSummary });
                    }
                }
            }

        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnInputChange = async (event) => {
        const target = event.target;
        const name = target.name;
        const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
        let errors = this.state.errors;
        switch (name) {
            case 'isUpdatePickupDatetime':
                errors.pickupDatetime = '';
                this.setState({ isUpdatePickupDatetime: value });
                break;
            default:
                break;
        }
        this.setState({ errors: errors });
    }
    handleOnPickupDatetimeChange = (date) => {
        try {
            let errors = this.state.errors;
            errors.pickupDatetime = "";
            if (date === null && date === '') {
                errors.pickupDatetime = 'Please select date.';
            }
            this.setState({ pickupDatetime: date, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnUpdateFlightStatus = async (event) => {
        event.preventDefault();
        try {
            if (this.state.isFlightDataFound === true) {
                let confirmMsg = 'Update flight details only';
                if (this.state.isUpdatePickupDatetime === true) {
                    confirmMsg = 'Update flight details with pickup date time';
                }
                await Swal.fire({
                    icon: 'question',
                    title: '<small><b>Confirmation</b></small>',
                    html: "<span style='font-size: 20px;'>" + confirmMsg + ".</span><br>Are you sure you want to update?",
                    //showCloseButton: true,
                    showCancelButton: true,
                    focusConfirm: false,
                    confirmButtonText: "<i class='fa fa-thumbs-up'></i> <span style='font-size:14px;'>Yes, Update!</span>",
                    cancelButtonText: 'No',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    dangerMode: false,
                    allowOutsideClick: false
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.setState({ loadPanelVisible: true });
                        let request = {};
                        request.RideId = Number(this.state.rideId);
                        request.FlightScheduleTime = this.state.flightScheduleTime.trim() !== '' ? moment(this.state.flightScheduleTime, 'MM/DD/YYYY HH:mm').format('YYYY-MM-DD HH:mm') : '';
                        request.FlightETA = this.state.flightEstimatedTime.trim() !== '' ? moment(this.state.flightEstimatedTime, 'MM/DD/YYYY HH:mm').format('YYYY-MM-DD HH:mm') : '';
                        request.FlightATA = this.state.flightActualTime.trim() !== '' ? moment(this.state.flightActualTime, 'MM/DD/YYYY HH:mm').format('YYYY-MM-DD HH:mm') : '';
                        request.FlightStatus = this.state.flightStatus;
                        request.IsFlightLanded = this.state.flightStatus === 'L' ? true : false;
                        request.FlightTerminal = this.state.airportTerminal;
                        request.FlightGate = this.state.airportGate;
                        request.FlightCommentStatus = '';
                        request.PickupDateTime = this.state.isUpdatePickupDatetime === true ? moment(this.state.pickupDatetime, 'MM/DD/YYYY HH:mm').format('YYYY-MM-DD HH:mm') : '';
                        const requestParams = getRequestParams('POST', request);
                        const response = await fetch('flight-track-api/update-flight-status', requestParams);
                        const data = await response.json();
                        this.setState({ loadPanelVisible: false, isFlightDataFound: false });
                        await Swal.fire({
                            allowOutsideClick: false,
                            icon: data.isSuccess ? 'success' : 'error',
                            title: data.isSuccess ? 'Success' : 'Oops...',
                            text: (data.isSuccess === false && (data.resultMessage === null || data.resultMessage === "")) ? 'Unable to process the request.'
                                : (data.resultMessage === null || data.resultMessage === "") ? 'Flight status updated successfully.' : data.resultMessage,
                        }).then(async (result) => {
                            if (result.isConfirmed) {
                                await this.handleOnCloseModal();
                            }
                        });
                    }
                });
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    showMessage = async (icon, title, resultMessage) => {
        this.setState({ loadPanelVisible: false });
        await Swal.fire({
            allowOutsideClick: false,
            showCloseButton: false,
            icon: (icon === null || icon.trim() === '') ? 'error' : icon,
            title: (title === null || title.trim() === '') ? 'Oops...' : title,
            html: (resultMessage === null || resultMessage.trim() === '') ? 'Opps! something went wrong, unable to process the request.' : resultMessage.trim(),
        })
    }

    render() {
        const filterPickuptime = time => {
            const currentDate = currentTime;
            const selectedDate = new Date(time);
            return currentDate.getTime() < selectedDate.getTime();
        }
        return (
            <React.Fragment>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ ontent: { width: '35%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form style={this.state.isSubmitted ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Flight Status</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                                    <span className="badge badge-pill alert-warning" style={{ width: 'inherit' }}>
                                        <span className="text-info" style={{ fontSize: '20px' }}>{this.state.flightDirectionText}</span>
                                        {
                                            (this.state.isFlightDataFound === false && this.state.flightStatus !== 'L') ? <span className="text-danger" style={{ paddingLeft: '6px' }}>{'Flight live status not found'}</span> : ''
                                        }
                                        {
                                            (this.state.isFlightDataFound === true && this.state.flightStatusSummary.flightCalEstimatedStatus.trim().length > 0)
                                                ? <span className={this.state.flightStatusSummary.cssEstStatus} style={{ paddingLeft: '6px' }}>{this.state.flightStatusSummary.flightCalEstimatedStatus}</span> : ''
                                        }
                                        {
                                            (this.state.isFlightDataFound === true && this.state.flightStatusSummary.flightCalEstimatedTime.trim().length > 0)
                                                ? <span className={this.state.flightStatusSummary.cssEstTime} style={{ paddingLeft: '6px' }}>{this.state.flightStatusSummary.flightCalEstimatedTime}</span> : ''
                                        }
                                        {
                                            (this.state.isFlightDataFound === true && this.state.flightStatusSummary.flightCalDelay.trim().length > 0)
                                                ? <span className={this.state.flightStatusSummary.cssDelay} style={{ paddingLeft: '6px' }}>{this.state.flightStatusSummary.flightCalDelay}</span> : ''
                                        }

                                    </span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Ride Id</label>
                                        <input type="text" className="form-control form-control-sm"
                                            name="rideId"
                                            value={this.state.rideId}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Flight Info</label>
                                        <input type="text" className="form-control form-control-sm"
                                            name="flightInfo"
                                            value={this.state.flightCode + ' ' + this.state.flightNumber}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Flight Status</label>
                                        <input type="text" className={this.state.flightStatusDisplay.cssStatus}
                                            name="flightStatus"
                                            value={this.state.flightStatusDisplay.value}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Flight Schedule Time</label>
                                        <input type="text" className="form-control form-control-sm"
                                            name="flightScheduleTime"
                                            value={(this.state.flightScheduleTime.trim() === '' ? 'N/A' : this.state.flightScheduleTime)}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Flight Estimated Time</label>
                                        <input type="text" className="form-control form-control-sm"
                                            name="flightEstimatedTime"
                                            value={(this.state.flightEstimatedTime.trim() === '' ? 'N/A' : this.state.flightEstimatedTime)}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Flight Actual Time</label>
                                        <input type="text" className="form-control form-control-sm"
                                            name="flightActualTime"
                                            value={(this.state.flightActualTime.trim() === '' ? 'N/A' : this.state.flightActualTime)}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Airport Terminal</label>
                                        <input type="text" className="form-control form-control-sm"
                                            name="airportTerminal"
                                            value={(this.state.airportTerminal.trim() === '' ? 'N/A' : this.state.airportTerminal)}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Gate</label>
                                        <input type="text" className="form-control form-control-sm"
                                            name="airportGate"
                                            value={(this.state.airportGate.trim() === '' ? 'N/A' : this.state.airportGate)}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label style={{ display: (this.state.isFlightDataFound === false) || 'none' }}>Pick-up Time</label>
                                        <div className="form-check" style={{ display: (this.state.isFlightDataFound === true) || 'none' }}>
                                            <input className="form-check-input" type="checkbox"
                                                name="isUpdatePickupDatetime"
                                                checked={this.state.isUpdatePickupDatetime}
                                                onChange={this.handleOnInputChange}
                                                disabled={!this.state.isFlightDataFound} />
                                            <label className="form-check-label" htmlFor="isUpdatePickupDatetime"> Pick-up Time <i style={{ color: '#C04000' }}>( Check for updated pick-up time )</i></label>
                                        </div>
                                        <DatePicker
                                            className="form-control form-control-sm "
                                            placeholderText=""
                                            name="pickupDatetime"
                                            showTimeInput
                                            selected={this.state.pickupDatetime}
                                            dateFormat={DateFormat.DateTime24Format}
                                            onChange={this.handleOnPickupDatetimeChange}
                                            disabled={!this.state.isUpdatePickupDatetime}
                                            filterTime={filterPickuptime}
                                        />
                                        {this.state.errors["pickupDatetime"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["pickupDatetime"]}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" title="Save" onClick={this.handleOnUpdateFlightStatus} disabled={!this.state.isFlightDataFound}>
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Save"><i className="fas fa-save"></i> Save</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleOnCloseModal}><i className="fas fa-times"></i> Cancel</button>
                        </div>
                    </form>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </React.Fragment>
        );
    }
}
