import React, { Component } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Scheduler, { Resource } from 'devextreme-react/scheduler';
import Swal from 'sweetalert2';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions';
import { SendMessageComponent } from '../Shared/SendMessageComponent';

let currentDispatchDate = new Date();
let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));

const views = ['day', 'week', 'workWeek', 'month'];
const scrolling = { mode: 'virtual' };

export class DispatchCalendarComponent extends Component {
    static displayName = DispatchCalendarComponent.name;

    constructor(props) {
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        let d = new Date(currentTime);
        currentDispatchDate = new Date(d.getFullYear(), d.getMonth(), d.getDate());
        let endTime = new Date(new Date(currentDispatchDate).setDate(currentDispatchDate.getDate() + 365))
        super(props);        

        this.state = {
            isSubmited: false,
            reservationCalendarSource: null,
            searchFromDate: currentTime,
            searchToDate: endTime,
            vehicleType: [],
            calRideList: [],
            calVehicleList: [],
            calServiceVehicles: [],
            calAvailableVehicles:[],
        }

        this.getPreApprovedRideList = this.getPreApprovedRideList.bind(this);
        this.getServiceVehicles = this.getServiceVehicles.bind(this);
        this.refreshDispatch = this.refreshDispatch.bind(this);
        this.sortByKeyAsc = this.sortByKeyAsc.bind(this);
    }

    componentDidMount() {
        this.getServiceVehicles();
        this.getPreApprovedRideList();        
    }

    sendDriverMessageControl(e, data) {
        e.preventDefault();
        this.refs.SendMessageComponentModel.showModal(data);
    }

    getPreApprovedRideList() {
        try {
            var dispatchReservationRequest = new Object();
            dispatchReservationRequest.fromDate = formatDate(this.state.searchFromDate, "MM/dd/yyyy");
            dispatchReservationRequest.toDate = formatDate(this.state.searchToDate, "MM/dd/yyyy");
            dispatchReservationRequest.vehicleTypeGroupId = "";
            const requestParams = getRequestParams('POST', dispatchReservationRequest);
            this.bindGrid(requestParams);
        } catch (e) {
            console.log(e);
        }
    }

    async getServiceVehicles() {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/vehicle-api/dropdown/0/0', requestParams);
            const data = await response.json();
            this.setState({
                calServiceVehicles: data.vehiclesDropdown
            });

        } catch (e) {
            console.log(e);
        }
    }

    refreshDispatch(e, data) {
        e.preventDefault();
        this.getPreApprovedRideList();
    }

    onDragStart(e) {
        e.itemData = this.state[e.fromData][e.fromIndex];
    }

    handleDragStart = (e, name) => {
        e.dataTransfer.setData("id", name);
    }

    handleDragOver = (e) => {
        e.preventDefault();
    }

    handleOnDrop = (e, item) => {
        let id = e.dataTransfer.getData("id")
        let assigntask = new Object();
        assigntask.rideDetails = new Object();
        let list = this.state.calRideList.filter((task) => {
            if (task.rideId == id) {                
                assigntask = task;
                task.status = item.status;
                task.vehicleId = item.vehicleId;
                //task.rankId = item.rankId;
                task.displayName = item.displayName;
                task.vehicleNo = item.status == "pending" ? "" : item.status;
                task.jobId = item.status == "pending" ? 0 : item.jobId;                
            }
            return task;
        });
        this.handleJobAssignUnAssign(assigntask);
    }

    async handleJobAssignUnAssign(assigntask) {
        try {
            let jobrides = [];
            if (assigntask.vehicleNo != "") {
                jobrides.push(assigntask.rideId);
                var assignJobRequest = new Object();
                assignJobRequest.Rides = jobrides;
                assignJobRequest.VehicleNo = assigntask.vehicleNo;
                assignJobRequest.DriverId = 0;
                assignJobRequest.AllowManualDispatch = false;

                const requestParams = getRequestParams('POST', assignJobRequest);
                const response = await fetch('/dispatch-api/assign-job', requestParams);
                const data = await response.json();
                
                Swal.fire({
                    icon: data.isSuccess ? 'success' : 'error',
                    title: data.isSuccess ? 'Job Assigned' : 'Oops...',
                    text: data.resultMessage,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            this.getServiceVehicles();
                            this.getPreApprovedRideList();
                        }
                    } else {
                        //this.setState({ isVehicleAssignedSubmited: false })
                    }
                });
            }
            else {                                
                if (assigntask.rideDetails.isPreAssignedRide == true) {
                    jobrides.push(assigntask.rideDetails.rideId);
                    var preAssignUnAssignRideRequest = new Object();
                    preAssignUnAssignRideRequest.Rides = jobrides;
                    preAssignUnAssignRideRequest.VehicleId = assigntask.rideDetails.vehicleId;
                    preAssignUnAssignRideRequest.DriverId = assigntask.rideDetails.driverId;
                    preAssignUnAssignRideRequest.IsAssignRide = false;

                    const requestParams = getRequestParams('POST', preAssignUnAssignRideRequest);
                    const response = await fetch('/dispatch-api/pre-assign-unassign-ride', requestParams);
                    const data = await response.json();

                    Swal.fire({
                        icon: data.isSuccess ? 'Job Unassigned' : 'error',
                        title: data.isSuccess ? 'Job Unassigned' : 'Oops...',
                        text: data.resultMessage,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (data.isSuccess) {
                            if (result.isConfirmed) {
                                this.getServiceVehicles();
                                this.getPreApprovedRideList();
                            }
                        } else {
                            
                        }
                    });
                }
                else {
                    let jobId = assigntask.jobId;
                    var unassignJobRequest = new Object();
                    unassignJobRequest.DispatcherId = 0;
                    const requestParams = getRequestParams('POST', unassignJobRequest);
                    const response = await fetch('/dispatch-api/' + jobId + '/unassign-job/', requestParams);
                    const data = await response.json();

                    Swal.fire({
                        icon: data.isSuccess ? 'Job Unassigned' : 'error',
                        title: data.isSuccess ? 'Job Unassigned' : 'Oops...',
                        text: data.resultMessage,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (data.isSuccess) {
                            if (result.isConfirmed) {
                                this.getServiceVehicles();
                                this.getPreApprovedRideList();
                            }
                        } else {

                        }
                    });
                }
            }
        } catch (e) {

        }
    }

    sortByKeyAsc(array, key) {
        return array.sort(function (a, b) {
            var x = a[key]; var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }

    async bindGrid(requestParams) {
        try {
            const response = await fetch('/dispatch-api/pre-approved-rides', requestParams);
            const data = await response.json();
            if (data.isSuccess) {
                let reservationCalendar = [];
                let rideVehicles = [];

                for (let i = 0; i < data.dispatchReservations.length; i++) {
                    var calObject = new Object();
                    var pickupDateTime = new Date(data.dispatchReservations[i].pickupDateTime);
                    var pickupEndDateTime = new Date(new Date(pickupDateTime).setMinutes(pickupDateTime.getMinutes() + 30));
                    calObject.text = data.dispatchReservations[i].paxName + " " + data.dispatchReservations[i].vehicleTypeName;
                    calObject.startDate = pickupDateTime;
                    calObject.endDate = pickupEndDateTime;
                    reservationCalendar.push(calObject);
                }

                for (let k = 0; k < data.dispatchReservations.length; k++) {   
                    var rideObject = new Object();
                    rideObject.rideDetails = new Object();
                    
                    if (data.dispatchReservations[k].vehicleId === 0) {
                        //rideObject.rankId = 0;
                        rideObject.text = data.dispatchReservations[k].paxName;// + " " + data.dispatchReservations[k].vehicleTypeName;
                        rideObject.rideId = data.dispatchReservations[k].rideId;
                        rideObject.status = "pending";
                        rideObject.vehicleId = 0;
                        rideObject.vehicleNo = "";
                        rideObject.jobId = 0;
                        rideObject.pickupDateTime = new Date(data.dispatchReservations[k].pickupDateTime);
                        rideObject.displayName = "Pending";
                        rideObject.rideDetails = data.dispatchReservations[k];
                    }
                    else {
                        rideObject.rideId = data.dispatchReservations[k].rideId;
                        rideObject.text = data.dispatchReservations[k].paxName; // + " " + data.dispatchReservations[k].vehicleTypeName;
                        rideObject.status = data.dispatchReservations[k].vehicleNo;
                        rideObject.displayName = data.dispatchReservations[k].vehicleNo;
                        rideObject.vehicleId = data.dispatchReservations[k].vehicleId;
                        rideObject.vehicleNo = data.dispatchReservations[k].vehicleNo;
                        rideObject.jobId = data.dispatchReservations[k].jobId;
                        //var rideFilter = rideVehicles.filter(function (result) { return data.dispatchReservations[k].vehicleNo === result.status; });
                        //if (rideFilter.length === 0) {
                        //    rideObject.rankId = rideVehicles.length + 1;
                        //}
                        //else {
                        //    rideObject.rankId = rideFilter[0].rankId;
                        //}
                        rideObject.rideDetails = data.dispatchReservations[k];
                    }
                    rideVehicles.push(rideObject);
                }

                var vehicleFilter = rideVehicles.filter(function (result) { return result.vehicleId == 0; });
                if (vehicleFilter.length == 0) {
                    var rideObject = new Object();
                    //rideObject.rankId = 0;
                    rideObject.text = "";
                    rideObject.rideId = "";
                    rideObject.status = "pending";
                    rideObject.vehicleId = 0;
                    rideObject.vehicleNo = "";
                    rideObject.jobId = 0;
                    rideObject.pickupDateTime = null;
                    rideObject.displayName = "Pending";
                    rideObject.rideDetails = new Object();
                    rideVehicles.push(rideObject);
                }

                //let maxId = rideVehicles[0].rankId;
                //for (let i = 0; i < rideVehicles.length; i++) {
                //    if (rideVehicles[i].rankId > maxId) {
                //        maxId = rideVehicles[i].rankId;                        
                //    }
                //}
                //maxId++;

                for (let v = 0; v < this.state.calServiceVehicles.length; v++) {
                    var vehicle = this.state.calServiceVehicles[v];
                    var vehicleFilter = rideVehicles.filter(function (result) { return vehicle.id === result.vehicleId; });
                    
                    if (vehicleFilter.length == 0) {
                        var vehicleObject = new Object();
                        //vehicleObject.rankId = v + maxId;
                        vehicleObject.rideId = "";
                        vehicleObject.text = "";
                        vehicleObject.vehicleNo = vehicle.name;
                        vehicleObject.vehicleId = vehicle.id;
                        vehicleObject.jobId = 0;
                        vehicleObject.status = vehicle.name;
                        vehicleObject.displayName = vehicle.name;
                        vehicleObject.rideDetails = new Object();
                        rideVehicles.push(vehicleObject);
                    }
                }

                this.setState({
                    reservationCalendarSource: reservationCalendar,
                    calRideList: rideVehicles,
                    calVehicleList: this.sortByKeyAsc(rideVehicles, "vehicleId"),
                });
                let allVehicleType = [];
                let vehicleTypeList = [];

                this.state.calVehicleList.forEach(task => {
                    allVehicleType.push(task);
                });

                const map = new Map();
                for (const item of allVehicleType) {
                    if (!map.has(item.vehicleId)) {
                        map.set(item.vehicleId, true);
                        vehicleTypeList.push({
                            //rankId: item.rankId,
                            status: item.status,
                            displayName: item.displayName,
                        });
                    }
                }
                this.setState({ calAvailableVehicles: vehicleTypeList })
            }
            this.setState({ isSubmited: false });
        } catch (e) {
            //debugger;
        }
    }

    render() {
        return (
            <div>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <h1 className="m-0 text-dark">Dispatch Calendar</h1>
                                </div>
                                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                                    <ol className="breadcrumb float-sm-right">
                                        <NavItem><NavLink tag={Link} to="#" className="btn btn-block btn-outline-secondary btn-sm" title="Refresh" onClick={(e) => { this.refreshDispatch(e, null) }}><i className="fas fa-sync-alt"></i> Refresh</NavLink></NavItem>
                                        <NavItem><NavLink tag={Link} to="/dispatch/list" className="btn btn-block btn-outline-secondary btn-sm" title="Dispatch Calendar"><i className="fas fa-calendar-alt"></i> Dispatch</NavLink></NavItem>
                                        <NavItem><NavLink tag={Link} to="/noshow/list" className="btn btn-block btn-outline-secondary btn-sm" title="No Show Request"><i className="fas fa-eye-slash"></i> No Show Request</NavLink></NavItem>
                                        <NavItem><NavLink tag={Link} to="/drivermessage/list" className="btn btn-block btn-outline-secondary btn-sm" title="Driver Message"><i className="fas fa-sms"></i> Driver Message</NavLink></NavItem>
                                        <NavItem><NavLink tag={Link} to="/loggeddrivers/list" className="btn btn-block btn-outline-secondary btn-sm" title="Logged Drivers"><i className="fas fa-user"></i> Logged Drivers</NavLink></NavItem>
                                        <NavItem><NavLink tag={Link} to="/loggedvehicles/list" className="btn btn-block btn-outline-secondary btn-sm" title="Logged Vehicles"><i className="fas fa-car"></i> Logged Vehicles</NavLink></NavItem>
                                        <NavItem><NavLink tag={Link} to="#" className="btn btn-block btn-outline-secondary btn-sm" onClick={(e) => { this.sendDriverMessageControl(e, null) }} data-toggle="modal" title="Send Message"><i className="fas fa-sms"></i> Send Message</NavLink></NavItem>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="card card-grid">
                            <div className="card-body">
                                {/*<div className="container">
                                    <div>
                                        {
                                            this.state.calAvailableVehicles.map((vitem, i) => (
                                                <div
                                                    onDragOver={(e) => { this.handleDragOver(e) }}
                                                    onDrop={(e) => this.handleOnDrop(e, vitem)}
                                                    className="pending-container">
                                                    &nbsp;<b><u>{vitem.displayName}</u></b>
                                                    {
                                                        this.state.calRideList && this.state.calRideList.filter(function (entry) { return entry.status === vitem.status && entry.rideId != "" }).map((ritem, i) => (
                                                            <div>
                                                                <div
                                                                    onDragStart={(e) => { this.handleDragStart(e, ritem.rideId) }}
                                                                    key={ritem.rideId}
                                                                    draggable
                                                                    className="draggable ride-container">{ritem.text}
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>*/}

                                <Scheduler
                                    //timeZone="america/new_york"
                                    dataSource={this.state.reservationCalendarSource}
                                    views={views}
                                    defaultCurrentView="day"
                                    defaultCurrentDate={currentDispatchDate}
                                    height={600}
                                    startDayHour={0}
                                //scrolling={scrolling}
                                />
                            </div>
                        </div>
                    </div>
                    <SendMessageComponent ref="SendMessageComponentModel" onSendMessageClose={this.getPreApprovedRideList} />
                </div>
            </div>
        );
    }
}