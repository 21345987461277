import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import { LoadPanel } from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import { SelectBox } from 'devextreme-react/select-box';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions';
import { SendEmailComponent } from '../Shared/SendEmailComponent'
import Swal from 'sweetalert2';

let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class BillingCharterInvoiceReportComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            isOpenModal: false,
            isEmailSubmitted: false,
            fromDateTime: new Date(currentTime.toLocaleDateString() + " 00:00"),
            toDateTime: new Date(currentTime.toLocaleDateString() + " 23:59"),
            chkCustomerTypeSelection: "1",
            dropdownCustomerType: [],
            customerTypeId: 0,
            loadPanelVisible: false,
            errors: {
                customerTypeId: 0,
            }
        }

        this.getCustomerType = this.getCustomerType.bind(this);
        this.handleCustomerTypeChange = this.handleCustomerTypeChange.bind(this);
        this.handleCustomerTypeSelectionChange = this.handleCustomerTypeSelectionChange.bind(this);

        this.handleFromValueChanged = this.handleFromValueChanged.bind(this);
        this.handleToValueChanged = this.handleToValueChanged.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.handleSearchCharterInvoiceReportSubmit = this.handleSearchCharterInvoiceReportSubmit.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
        this.downloadAsPdf = this.downloadAsPdf.bind(this);
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true,
        });
        await this.getCustomerType();
        this.setState({
            loadPanelVisible: false,
        });
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });

        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    async getCustomerType() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('customer-type-api/', requestParams);
            const data = await response.json();
            this.setState({
                dropdownCustomerType: data.customerType,
            });

        } catch (e) {
            console.log(e);
        }
    }
    handleCustomerTypeChange(selectedOption) {
        this.setState({ customerTypeId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
        this.state.errors["customerTypeId"] = "";
    }
    handleCustomerTypeSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkCustomerTypeSelection: value, customerTypeId: '' });
        this.state.errors["customerTypeId"] = "";
    }
    handleFromValueChanged(date) {
        this.setState({ fromDateTime: date.value });
    }
    handleToValueChanged(date) {
        this.setState({ toDateTime: date.value });
    }
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }
    handleChange(e) {
        this.setState({ isDisplayTableFormat: e.target.checked });
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmited: false,
                isOpenModal: false,
                isEmailSubmitted: false,
                fromDateTime: new Date(currentTime.toLocaleDateString() + " 00:00"),
                toDateTime: new Date(currentTime.toLocaleDateString() + " 23:59"),
                chkCustomerTypeSelection: "1",
                customerTypeId: 0,
                loadPanelVisible: false,
                errors: {
                    customerTypeId: '',
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleClearSearch() {
        this.setState({
            isSubmited: false,
            isOpenModal: true,
            isEmailSubmitted: false,
            fromDateTime: new Date(currentTime.toLocaleDateString() + " 00:00"),
            toDateTime: new Date(currentTime.toLocaleDateString() + " 23:59"),
            chkCustomerTypeSelection: "1",
            customerTypeId: 0,
            loadPanelVisible: false,
            errors: {
                customerTypeId: 0,
            }
        });
    }
    async getCustomerList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('account-api/customer-dropdown/', requestParams);
        const data = await response.json();
        this.setState({
            customer: data.customers,
        });
    }
    async handleSearchCharterInvoiceReportSubmit(e) {
        e.preventDefault();
        await this.downloadAsPdf();
    }
    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.customerTypeId = "";

        if (this.state.chkCustomerTypeSelection === "2" && this.state.customerTypeId == "") {
            formIsValid = false;
            errors.customerTypeId = "Please select customer type.";

            this.setState({ errors: errors });
            return formIsValid;
        }
    }
    async downloadAsPdf() {
        try {
            if (this.handleValidation()) {
                this.setState({
                    isSubmited: true,
                });

                var selectionCriteria = "";

                if (this.state.chkCustomerTypeSelection == "1") {
                    selectionCriteria = "For All Account Type's";
                }
                if (this.state.chkCustomerTypeSelection == "2") {
                    selectionCriteria = selectionCriteria + "For Account Type " + this.state.customerType;
                }
                selectionCriteria = selectionCriteria + " Date range between " + formatDate(this.state.fromDateTime, "MM/dd/yyyy") + " 00:00:01 AND " + formatDate(this.state.toDateTime, "MM/dd/yyyy") + " 00:00:01"

                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.StartDate = this.state.fromDateTime == null ? "" : this.state.fromDateTime.toLocaleDateString() + " 00:00:00"; //new Date(2020, 9, 1);
                request.EndDate = this.state.toDateTime == null ? "" : this.state.toDateTime.toLocaleDateString() + " 23:59:59"; //new Date(2020, 9, 31);
                request.CustomerTypeId = parseInt(this.state.customerTypeId);
                request.SelectionCrieteria = selectionCriteria;

                request.fileFormat = 'pdf';
                request.MethodName = "GetInvoiceRateSummary";
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({
                    isSubmited: false,
                });

                let a = document.createElement('a');
                a.href = dataResponse.reportBase64String;
                a.download = 'CharterInvoiceReport.pdf';
                a.click();
            }
        } catch (e) {
            this.setState({
                isSubmited: false,
            });
            console.log(e)
        }
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isEmailSubmitted: true });
                var selectionCriteria = "";

                if (this.state.chkCustomerTypeSelection == "1") {
                    selectionCriteria = "For All Account Type's";
                }
                if (this.state.chkCustomerTypeSelection == "2") {
                    selectionCriteria = selectionCriteria + "For Account Type " + this.state.customerType;
                }
                selectionCriteria = selectionCriteria + " Date range between " + formatDate(this.state.fromDateTime, "MM/dd/yyyy") + " 00:00:01 AND " + formatDate(this.state.toDateTime, "MM/dd/yyyy") + " 00:00:01"

                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.StartDate = this.state.fromDateTime == null ? "" : this.state.fromDateTime.toLocaleDateString() + " 00:00:00"; //new Date(2020, 9, 1);
                request.EndDate = this.state.toDateTime == null ? "" : this.state.toDateTime.toLocaleDateString() + " 23:59:59"; //new Date(2020, 9, 31);
                request.CustomerTypeId = parseInt(this.state.customerTypeId);
                request.SelectionCrieteria = selectionCriteria;

                request.fileFormat = 'pdf';
                request.MethodName = "GetInvoiceRateSummary";
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();

                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Charter Invoice Report';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = 'CharterInvoice.pdf';
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '60%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleRidesApprovedReportSubmit}
                        style={(this.state.isSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Charter Invoice'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdAllCustomerType" value="1" checked={this.state.chkCustomerTypeSelection === "1"} onChange={this.handleCustomerTypeSelectionChange} />
                                            <label className="form-check-label">All Customer Type</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdSingleCustomerType" value="2" checked={this.state.chkCustomerTypeSelection === "2"} onChange={this.handleCustomerTypeSelectionChange} />
                                            <label className="form-check-label">Single Customer Type</label>
                                        </span>
                                        <SelectBox
                                            dataSource={this.state.dropdownCustomerType.length > 0 ? this.state.dropdownCustomerType : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer type"
                                            name="customerTypeId"
                                            defaultValue={0}
                                            value={this.state.customerTypeId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleCustomerTypeChange}
                                            showClearButton={true}
                                            disabled={this.state.chkCustomerTypeSelection === "1"}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerTypeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerTypeId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label className="form-check-label">From Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.fromDateTime}
                                            value={this.state.fromDateTime}
                                            onValueChanged={this.handleFromValueChanged}
                                            width="100%"
                                            acceptCustomValue={false} />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label className="form-check-label">To Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.toDateTime}
                                            value={this.state.toDateTime}
                                            onValueChanged={this.handleToValueChanged}
                                            width="100%"
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="submit" className="btn btn-success btn-sm" title="Download" onClick={this.handleRidesApprovedReportSubmit} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                                </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        )
    }
}