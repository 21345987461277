import React, { Component } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { SelectBox } from 'devextreme-react/select-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'
let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class RideDetailsHourlyReportComponent extends Component {
    constructor() {
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        super()
        this.state = {
            isSubmitted: false,
            isEmailSubmitted: false,
            isSearchSubmitted: false,
            loadPanelVisible: false,
            exportPDF: false,
            isDetailsReport: true,
            isSummaryReport: false,
            isGraphReport: false,
            hourlyRideSummaryGridDataSource: null,
            fromDate: moment(currentTime).format("MM/DD/YYYY"),
            toDate: moment(currentTime).format("MM/DD/YYYY"),
            isAllVehicleType: true,
            vehicleTypeDropdownList: [],
            vehicleTypeDropdownData: null,
            vehicleTypeId: 0,
            errors: {
                vehicleType: '',
                fromDate: '',
                toDate: '',
            }
        }
    }
    async componentDidMount() {
        this.setState({ loadPanelVisible: true });
        await this.getVehicleTypeDropdownList();
        this.setState({ loadPanelVisible: false });
    }
    getVehicleTypeDropdownList = async () => {
        try {
            var request = new Object();
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('vehicletype-api/vehicletype', requestParams);
            const data = await response.json();
            this.setState({
                vehicleTypeDropdownList: data.apiVehicleType
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleToolbarPreparing = (e) => {
        try {
            e.toolbarOptions.items.unshift({
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: (this.state.exportPDF) ? 'fas fa-spinner fa-spin' : 'exportpdf',
                    onClick: this.downloadAsPdf.bind(this),
                    hint: 'Export to Pdf'
                }
            });
            //e.toolbarOptions.items.unshift({
            //    location: 'after',
            //    widget: 'dxButton',
            //    options: {
            //        icon: 'email',
            //        onClick: this.emailReport.bind(this),
            //        hint: 'Email'
            //    }
            //});
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnSearchSubmit = async event => {
        event.preventDefault();
        try {
            this.setState({ isSearchSubmitted: true, loadPanelVisible: true });
            let isValid = true;
            let errors = this.state.errors;
            errors.vehicleType = '';
            errors.fromDate = '';
            errors.toDate = '';
            let fromDate = '';
            let toDate = '';

            if (this.state.isAllVehicleType === false) {
                if (Number(this.state.vehicleTypeId) === 0) {
                    errors.vehicleType = 'Please select vehicle type.';
                    isValid = false;
                }
            }
            if (this.state.fromDate.trim() === '') {
                errors.fromDate = 'Please select date.';
                isValid = false;
            }
            else {
                fromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00';
            }
            if (this.state.toDate.trim() === '') {
                errors.toDate = 'Please select date.';
                isValid = false;
            }
            else {
                toDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59'
            }
            if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                if (moment(this.state.fromDate, 'MM/DD/YYYY').isAfter(this.state.toDate, 'MM/DD/YYYY')) {
                    errors.fromDate = 'Please enter a valid date range.';
                    errors.toDate = 'Please enter a valid date range.';
                    isValid = false;
                }
            }
            this.setState({ errors: errors });
            if (isValid) {
                let request = new Object();
                request.VehicleTypeId = Number(this.state.vehicleTypeId);
                request.FromDate = fromDate;
                request.ToDate = toDate;
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('report-api/hourly-ride-summary', requestParams);
                const data = await response.json();
                this.setState({
                    hourlyRideSummaryGridDataSource: data.rideSummary
                });
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSearchSubmitted: false, loadPanelVisible: false });
    }
    handleOnClearSearch = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isEmailSubmitted: false,
                isSearchSubmitted: false,
                loadPanelVisible: false,
                exportPDF: false,
                isDetailsReport: true,
                isSummaryReport: false,
                isGraphReport: false,
                hourlyRideSummaryGridDataSource: null,
                fromDate: moment(currentTime).format("MM/DD/YYYY"),
                toDate: moment(currentTime).format("MM/DD/YYYY"),
                isAllVehicleType: true,
                vehicleTypeDropdownList: [],
                vehicleTypeDropdownData: null,
                vehicleTypeId: 0,
                errors: {
                    vehicleType: '',
                    fromDate: '',
                    toDate: '',
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnInputChange = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
            let errors = this.state.errors;
            switch (name) {
                case 'isAllVehicleType':
                    errors.vehicleType = '';
                    this.setState({ isAllVehicleType: value, vehicleTypeDropdownData: null, vehicleTypeId: 0 });
                    break;
                case 'isDetailsReport':
                    this.setState({ isDetailsReport: value, isSummaryReport: false, isGraphReport: false });
                    break;
                case 'isSummaryReport':
                    this.setState({ isDetailsReport: false, isSummaryReport: value, isGraphReport: false });
                    break;
                case 'isGraphReport':
                    this.setState({ isDetailsReport: false, isSummaryReport: false, isGraphReport: value });
                    break;
                default:
                    break;
            }
            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnFromDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.fromDate = '';
            let fromDate = '';
            if (event.value !== null && event.value !== '') {
                fromDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.fromDate = 'Please select date.';
            }
            if (this.state.toDate.trim() !== '') {
                errors.toDate = '';
            }
            this.setState({ fromDate: fromDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnToDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.toDate = '';
            let toDate = '';
            if (event.value !== null && event.value !== '') {
                toDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.toDate = 'Please select date.';
            }
            if (this.state.fromDate.trim() !== '') {
                errors.fromDate = '';
            }
            this.setState({ toDate: toDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnVehicleTypeDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.vehicleType = '';
            let vehicleTypeId = 0;
            if (data !== null) {
                vehicleTypeId = (data.selectedItem != null ? data.selectedItem.vehicleTypeId : 0);
            }
            else {
                //errors.vehicleType = 'Please select vehicle type.';
            }
            this.setState({ vehicleTypeDropdownData: data, vehicleTypeId: vehicleTypeId, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnReportSubmit = async (event) => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isSubmitted: true });
                let fromDate = '';
                let toDate = '';
                let reportType = 0;

                if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                    fromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00';
                    toDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59';
                }

                if (this.state.isDetailsReport === true) {
                    reportType = 1;
                }
                if (this.state.isSummaryReport === true) {
                    reportType = 2;
                }
                if (this.state.isGraphReport === true) {
                    reportType = 3;
                }
                let reportHeader = await this.setSelectionCrieteria();
                await this.downloadReport(Number(this.state.vehicleTypeId), fromDate, toDate, reportType, reportHeader, 'pdf');
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false });
    }
    downloadAsPdf = async () => {
        try {
            if (await this.isFormValid()) {
                this.setState({ exportPDF: true });
                let fromDate = '';
                let toDate = '';
                let reportType = 0;

                if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                    fromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00';
                    toDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59';
                }

                if (this.state.isDetailsReport === true) {
                    reportType = 1;
                }
                if (this.state.isSummaryReport === true) {
                    reportType = 2;
                }
                if (this.state.isGraphReport === true) {
                    reportType = 3;
                }
                let reportHeader = await this.setSelectionCrieteria();
                await this.downloadReport(Number(this.state.vehicleTypeId), fromDate, toDate, reportType, reportHeader, 'pdf');
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ exportPDF: false });
    }
    downloadReport = async (vehicleTypeId, fromDate, toDate, reportType, reportHeader, fileFormat) => {
        try {
            let reportTypeFileName = '';
            if (reportType === 1) {
                reportTypeFileName = 'Details';
            }
            if (reportType === 2) {
                reportTypeFileName = 'Summary';
            }
            if (reportType === 3) {
                reportTypeFileName = 'Graph';
            }
            var request = new Object();
            request.LocationId = Number(sessionStorage.getItem("session_locationId"));
            request.StartDate = fromDate;
            request.EndDate = toDate;
            request.VehicleTypeId = Number(vehicleTypeId);
            request.ReportType = Number(reportType);
            request.fileFormat = fileFormat;
            request.MethodName = "GetHourlyRideReport";
            request.SelectionCrieteria = reportHeader
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            let a = document.createElement('a');
            a.href = dataResponse.reportBase64String;
            a.download = 'HourlyRide' + reportTypeFileName + 'Report.' + fileFormat;
            a.click();
        } catch (ex) {
            console.log(ex);
        }
    }
    isFormValid = async () => {
        let isValid = true;
        try {
            let errors = this.state.errors;
            errors.vehicleType = '';
            errors.fromDate = '';
            errors.toDate = '';
            if (this.state.isAllVehicleType === false) {
                if (Number(this.state.vehicleTypeId) === 0) {
                    errors.vehicleType = 'Please select vehicle type.';
                    isValid = false;
                }
            }
            if (this.state.fromDate.trim() === '') {
                errors.fromDate = 'Please select date.';
                isValid = false;
            }
            if (this.state.toDate.trim() === '') {
                errors.toDate = 'Please select date.';
                isValid = false;
            }
            if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                if (moment(this.state.fromDate, 'MM/DD/YYYY').isAfter(this.state.toDate, 'MM/DD/YYYY')) {
                    errors.fromDate = 'Please enter a valid date range.';
                    errors.toDate = 'Please enter a valid date range.';
                    isValid = false;
                }
            }
            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    setSelectionCrieteria = async () => {
        let selectionCrieteria = 'Hourly ride';
        try {
            if (this.state.isDetailsReport === true) {
                selectionCrieteria += ' details for';
            }
            if (this.state.isSummaryReport === true) {
                selectionCrieteria += ' summary for';
            }
            if (this.state.isGraphReport === true) {
                selectionCrieteria += ' graph for';
            }
            if (Number(this.state.vehicleTypeId) > 0) {
                if (this.state.vehicleTypeDropdownData !== null && Object.keys(this.state.vehicleTypeDropdownData).length > 0) {
                    selectionCrieteria += ' vehicle type - ' + this.state.vehicleTypeDropdownData.name;
                }
            }
            else {
                selectionCrieteria += ' all vehicle type';
            }
            if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                selectionCrieteria += ', period from  - ' + this.state.fromDate + ' to ' + this.state.toDate;
            }

        } catch (ex) {
            selectionCrieteria = '';
            console.log(ex);
        }
        return selectionCrieteria;
    }
    handleOnDrownloadReportFromGrid = async (e, data, fileFormat) => {
        e.preventDefault();
        try {
            if (data !== null) {
                let vehicleTypeId = Number(data.vehicleTypeId);
                let vehicleType = data.vehicleType === null ? '' : data.vehicleType;
                let fromDate = (data.pickupDatetime === null || data.pickupDatetime === '') ? '' : moment(data.pickupDatetime, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00';
                let toDate = (data.pickupDatetime === null || data.pickupDatetime === '') ? '' : moment(data.pickupDatetime, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59';
                let reportHeader = 'Hourly ride details for';
                if (vehicleType !== '') {
                    reportHeader += ' vehicle type - ' + vehicleType;
                }
                if (fromDate.trim() !== '' && toDate.trim() !== '') {
                    reportHeader += ', date from ' + moment(fromDate, 'YYYY-MM-DD').format('MM/DD/YYYY') + ' to ' + moment(toDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
                }
                else {
                    reportHeader += ', all date';
                }
                await this.downloadReport(vehicleTypeId, fromDate, toDate, 1, reportHeader, fileFormat);
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isEmailSubmitted: true });
                let fromDate = '';
                let toDate = '';
                let reportType = 0;

                if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                    fromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00';
                    toDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59';
                }

                if (this.state.isDetailsReport === true) {
                    reportType = 1;
                }
                if (this.state.isSummaryReport === true) {
                    reportType = 2;
                }
                if (this.state.isGraphReport === true) {
                    reportType = 3;
                }

                let reportTypeFileName = '';
                if (reportType === 1) {
                    reportTypeFileName = 'Details';
                }
                if (reportType === 2) {
                    reportTypeFileName = 'Summary';
                }
                if (reportType === 3) {
                    reportTypeFileName = 'Graph';
                }
                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.StartDate = fromDate;
                request.EndDate = toDate;
                request.VehicleTypeId = Number(this.state.vehicleTypeId);
                request.ReportType = Number(reportType);
                request.fileFormat = 'pdf';
                request.MethodName = "GetHourlyRideReport";
                request.SelectionCrieteria = await this.setSelectionCrieteria();
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Hourly Ride Listing Report';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = 'HourlyRide' + reportTypeFileName + 'Report.pdf';
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        const renderGridCell = (data) => {
            return <div style={{ textAlign: 'center' }}>
                <a href="#" title="View in PDF" onClick={(e) => { this.handleOnDrownloadReportFromGrid(e, data.data, 'pdf') }} >
                    <i className="fas fa-file-pdf"></i>
                </a>
                <a href="#" title="View in Excel" style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleOnDrownloadReportFromGrid(e, data.data, 'xlsx') }} >
                    <i className="fas fa-file-excel"></i>
                </a>
            </div>;
        }
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-sm-6 col-md-6 col-lg-6">
                                <h1 className="m-0 text-dark">Hourly Ride Listing Report</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem> <NavLink tag={Link} to="/report" className="btn btn-block btn-outline-secondary btn-sm" title="Reports" ><i className="fas fa-arrow-left"></i> Back</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <form style={(this.state.isSubmitted || this.state.isSearchSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="rez-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                name="isAllVehicleType"
                                                checked={this.state.isAllVehicleType}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isAllVehicleType"> All Vehicle Type</label>
                                        </span>
                                        <SelectBox
                                            dataSource={this.state.vehicleTypeDropdownList.length > 0 ? this.state.vehicleTypeDropdownList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select vehicle type"
                                            name="ddvehicletype"
                                            defaultValue={[]}
                                            value={this.state.vehicleTypeDropdownData}
                                            displayExpr='apiVehicleTypeDescription'
                                            valueExpr='vehicleTypeId'
                                            onSelectionChanged={this.handleOnVehicleTypeDropdownChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["vehicleType"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["vehicleType"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>From Date (Pick-up)</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            defaultValue={this.state.fromDate}
                                            value={this.state.fromDate}
                                            onValueChanged={this.handleOnFromDateChange}
                                            width={'100%'}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["fromDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["fromDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>To Date (Pick-up)</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            defaultValue={this.state.toDate}
                                            value={this.state.toDate}
                                            min={this.state.fromDate}
                                            onValueChanged={this.handleOnToDateChange}
                                            width={'100%'}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["toDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["toDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label htmlFor="">Report Type</label><br />
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isDetailsReport"
                                                checked={this.state.isDetailsReport}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isDetailsReport">  Details</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isSummaryReport"
                                                checked={this.state.isSummaryReport}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isSummaryReport"> Summary</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isGraphReport"
                                                checked={this.state.isGraphReport}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isGraphReport"> Graph</label>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3" >
                                    <div className="form-group">
                                        <label className="btn-block">&nbsp;</label>
                                        <button type="button" className="btn btn-success btn-sm" title="Search" onClick={this.handleOnSearchSubmit} >
                                            {this.state.isSearchSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i></span>}
                                            {!this.state.isSearchSubmitted && <span title="Search"><i className="fas fa-search"></i></span>}
                                        </button>&nbsp;&nbsp;
                                           <button type="button" className="btn btn-custom-gray btn-sm" title="Clear" onClick={this.handleOnClearSearch}
                                            onClick={this.handleOnClearSearch}><i className="fas fa-eraser"></i>
                                        </button>&nbsp;&nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" title="Download" onClick={this.handleOnReportSubmit}>
                                            {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSubmitted && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                                        </button>&nbsp;&nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleOnSendEmail} >
                                            {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="content">
                    <div className="card">
                        <div className="card-body">
                            <DataGrid
                                dataSource={this.state.hourlyRideSummaryGridDataSource}
                                columnsAutoWidth={true}
                                showColumnLines={false}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                showBorders={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                width='100%'
                                onToolbarPreparing={this.handleToolbarPreparing}>
                                <Column dataField="pickupDatetime" width={40} caption="" fixed={true} allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                <Column dataField="pickupDatetime" caption="Pick-up Date" />
                                <Column dataField="vehicleType" caption="Vehicle Type" alignment='center' />
                                <Column dataField="noOfRides" caption="Ride(s) Booked" alignment='center' />
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={false} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                <Export enabled={true} allowExportSelectedData={false} />
                                <ColumnChooser enabled={false} />
                                <ColumnFixing enabled={false} />
                            </DataGrid>
                        </div>
                    </div>
                </div>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}