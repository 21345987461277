import React, { Component } from 'react';
import DataGrid, {
    Column, Grouping, GroupPanel, Pager, Paging, SearchPanel, Export, SortByGroupSummaryInfo, ColumnChooser, ColumnFixing, HeaderFilter,
    Button
} from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import Modal from 'react-modal'; //npm install --save react-modal

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import { SelectBox } from 'devextreme-react/select-box';
import NumberFormat from 'react-number-format';
import { ModuleCode, CustomerType } from '../../helpers/fixcodes.js'
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import DateBox from 'devextreme-react/date-box';
import { LoadPanel } from 'devextreme-react/load-panel';

const customStyles = {
    content: {
        width: '50%'
    }
};
const position = { of: '#historydiv' };
export const rideType = [
    {
        id: 1,
        name: "Arrivals"
    },
    {
        id: 2,
        name: "Departure"
    },
    {
        id: 3,
        name: "Both Airport"
    }]
let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class TaxSettingComponent extends Component {
    constructor(props) {
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        super(props);
        this.state = {
            isSubmited: false,
            isOpenTaxSettingModal: false,
            taxSettingList: null,
            locationList: [],
            locationId: null,
            taxTypeList: [],
            taxTypeId: 0,
            rideTypeList: [],
            rideTypeId: 0,
            wefDate: currentTime,
            taxCharge: 0.000,
            loadPanelVisible: false,
            errors: {
                locationId: '',
                taxTypeId: '',
                wefDate: '',
                taxCharge: '',
                rideTypeId: '',

            }
        }
        this.LocationList = this.LocationList.bind(this);
        this.getTaxTypeList = this.getTaxTypeList.bind(this);
        this.handleTaxSettingSubmit = this.handleTaxSettingSubmit.bind(this);

    }
    componentDidMount = () => {
        this.gettaxSettingList();
    }
    async gettaxSettingList() {
        this.setState({
            loadPanelVisible: true
        });
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('aff-text-setting-api/list/0/0', requestParams);
        const data = await response.json();
        if (data !== null && data.affTaxSettings.length > 0) {
            this.setState({ taxSettingList: data.affTaxSettings, loadPanelVisible: false });
        }
        else {
            this.setState({ taxSettingList: null, loadPanelVisible: false });
        }
    }
    async LocationList() {

        let locationId = parseInt(sessionStorage.getItem("session_locationId"));
        let locationName = sessionStorage.getItem("session_locationName");

        const requestParams = getRequestParams('GET', '');
        const response = await fetch('account-api/locations', requestParams);
        const data = await response.json();
        if (data !== null && data.locations.length > 0) {
            this.setState({
                locationList: data.locations,
                locationId: { locationId: locationId, locationName: locationName }
            });
        }
    }
    async getTaxTypeList() {

        const requestParams = getRequestParams('GET', '');
        const response = await fetch('tax-api', requestParams);
        const data = await response.json();
        if (data !== null && data.taxDropdownList.length > 0) {
            this.setState({
                taxTypeList: data.taxDropdownList.filter(x => x.name !== "COUNTY TAX" && x.name !== "CONGESTION TAX")
            });
        }
    }
    getRideTypelist() {
        this.setState({
            rideTypeList: rideType
        });
    }
    addTaxSettingControl = (e) => {
        e.preventDefault();
        this.setState({
            isOpenTaxSettingModal: true,
        });
    }
    afterOpenTaxSettingModal = () => {
        this.LocationList();
        this.getTaxTypeList();
        this.getRideTypelist();
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let errors = this.state.errors;
        switch (name) {
            case 'taxTypeId':
                errors.taxTypeId = "";
                if (parseInt(value) === 0) {
                    this.setState({ taxTypeId: 0 });
                    errors.taxTypeId = "Please select tax type.";
                } else {
                    this.setState({ taxTypeId: parseInt(value) });
                }
                break;
            case 'taxCharge':
                errors.taxCharge = "";
                if (event.target.value !== "" && event.target.value !== null) {
                    this.setState({ taxCharge: parseFloat(event.target.value).toFixed(3) })
                } else {
                    this.setState({ taxCharge: 0.000 });
                    errors.taxCharge = "Please enter tax charge";
                }
                break;
            case 'rideTypeId':
                errors.rideTypeId = "";
                if (this.state.taxTypeId === 1 && parseInt(event.target.value) !== 0) {
                    this.setState({ rideTypeId: parseInt(event.target.value) })
                } else {
                    this.setState({ rideTypeId: 0 });
                    errors.rideTypeId = "Please enter ride type.";
                }
                break;
            default:
                this.setState({
                    [name]: value
                });
                break;
        }
    }
    handleLocationInputChange = (data) => {
        this.setState({
            locationId: data.selectedItem
        });
        if (data.selectedItem === null) {
            this.state.errors["locationId"] = "Please select location.";
        }
        else {
            this.state.errors["locationId"] = "";
        }
    }
    handleWefDateTimeChange = (date) => {
        if (date !== null) {
            let fromDateValue = formatDate(date.value, "MM/dd/yyyy");
            this.setState({ wefDate: fromDateValue });
            this.state.errors["wefDate"] = '';
        } else {
            this.setState({ wefDate: null });
            this.state.errors["wefDate"] = 'Please select wef Date.';
        }
    }

    handleValidation = () => {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.locationId = '';
        errors.taxTypeId = '';
        errors.wefDate = '';
        errors.taxCharge = '';

        if (this.state.locationId === null) {
            formIsValid = false;
            errors.locationId = "Please select location.";
        }
        if (parseInt(this.state.taxTypeId) === 0) {
            formIsValid = false;
            errors.taxTypeId = "Please select tax type.";
        }
        if (this.state.wefDate === null) {
            formIsValid = false;
            errors.wefDate = "Please select wef date.";
        }
        if (parseFloat(this.state.taxCharge) === 0 || this.state.taxCharge === '') {
            formIsValid = false;
            errors.taxCharge = "Please select tax charge.";
        }
        return formIsValid;
    }

    async handleTaxSettingSubmit(e) {
        this.setState({ isSubmited: true });
        e.preventDefault();
        if (this.handleValidation()) {
            var newObject = new Object();
            newObject.TaxSettingRowId = 0;
            newObject.LocationId = parseInt(this.state.locationId.locationId);
            newObject.TaxId = parseInt(this.state.taxTypeId);
            newObject.WefDate = this.state.wefDate;
            newObject.TaxCharge = parseFloat(this.state.taxCharge);
            newObject.Enable = true;
            newObject.RideTypeId = parseInt(this.state.rideTypeId);

            const requestParams = getRequestParams('POST', newObject);
            const response = await fetch('aff-text-setting-api/save', requestParams);
            const dataResult = await response.json();
            Swal.fire({
                icon: dataResult.isSuccess ? 'success' : 'error',
                title: dataResult.isSuccess ? 'Success' : 'Oops...',
                text: dataResult.resultMessage,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (dataResult.isSuccess) {
                    if (result.isConfirmed) {
                        this.closeTaxSettingModal();
                        this.gettaxSettingList();
                    }
                } else {
                    this.setState({ isSubmited: false })
                }
            })
        }
        else {
            this.setState({ isSubmited: false })
        }
    }
    closeTaxSettingModal = () => {
        this.setState({
            isSubmited: false,
            isOpenTaxSettingModal: false,
            locationList: [],
            locationId: null,
            taxTypeList: [],
            taxTypeId: 0,
            wefDate: currentTime,
            taxCharge: 0,
            rideTypeList: [],
            rideTypeId: 0,
            errors: {
                locationId: '',
                taxTypeId: '',
                wefDate: '',
                taxCharge: '',
                rideTypeId: '',
            }
        });
        this.gettaxSettingList();
    }
    render() {
        const renderGridCell = (data) => {
            return parseFloat(data.data.taxCharge).toFixed(3);
        }
        const filterWefDateTime = time => {
            const currentDate = currentTime;
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }

        return (<div className="content-wrapper">
            <div className="content-header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <h1 className="m-0 text-dark">Tax Setting</h1>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <ol className="breadcrumb float-sm-right">
                                <NavItem>
                                    <NavLink onClick={(e) => { this.addTaxSettingControl(e) }} className="btn btn-block btn-outline-secondary btn-sm" title="Add Tax Charge"><i className="fas fa-plus"></i> Add Tax Charge</NavLink>
                                </NavItem>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="card card-grid">
                    <div className="card-body">
                        <DataGrid
                            dataSource={this.state.taxSettingList}
                            keyExpr="id"
                            selection={{ mode: 'single' }}
                            columnsAutoWidth={false}
                            showColumnLines={false}
                            showRowLines={true}
                            showBorders={true}
                            rowAlternationEnabled={true}
                            showBorders={true}
                            allowColumnResizing={true}
                            columnResizingMode='widget'
                            onToolbarPreparing={this.onToolbarPreparing}>
                            <Column dataField="locationName" width={200} caption="Location Name" allowFiltering={false} allowSorting={false} />
                            <Column dataField="taxName" caption="Tax Type" allowFiltering={false} allowSorting={false} />
                            <Column dataField="wefDateStr" caption="WEF Date" allowFiltering={false} allowSorting={false} />
                            <Column dataField="taxCharge" caption="Tax Charge ($)" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                            <SortByGroupSummaryInfo summaryItem="count" />
                            <GroupPanel visible={true} />
                            <HeaderFilter visible={true} />
                            <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                            <Grouping autoExpandAll={true} />
                            <Paging defaultPageSize={20} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20, 50]} showInfo={true} />
                            <Export enabled={true} allowExportSelectedData={false} />
                            <ColumnChooser enabled={true} />
                            <ColumnFixing enabled={true} />

                        </DataGrid>

                    </div>
                </div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenTaxSettingModal}
                    style={customStyles}
                    className={"react-modal react-modal-driverlist"}
                    onAfterOpen={this.afterOpenTaxSettingModal}
                    onRequestClose={this.closeTaxSettingModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleTaxSettingSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Add Tax Setting</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeTaxSettingModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">

                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Location <span className="text-red">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.locationList.length > 0 ? this.state.locationList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select location"
                                            name="locationId"
                                            defaultValue={0}
                                            value={this.state.locationId != null ? this.state.locationId.locationId : 0}
                                            displayExpr='locationName'
                                            valueExpr='locationId'
                                            onSelectionChanged={this.handleLocationInputChange}
                                            showClearButton={true}
                                            disabled={this.state.rowId > 0 ? true : false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["locationId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["locationId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Tax Type <span className="text-red">*</span></label>
                                        <select name="taxTypeId" className="form-control form-control-sm" value={this.state.taxTypeId} onChange={this.handleInputChange}>
                                            <option value={0}>Select tax type</option>
                                            {this.state.taxTypeList && this.state.taxTypeList.map((item, i) =>
                                                (<option key={i} value={item.id}>{item.name}</option>)
                                            )}
                                        </select>
                                        {this.state.errors["taxTypeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["taxTypeId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>WEF Date <span className="text-red">*</span></label>
                                
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="wefDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.wefDate}
                                            onValueChanged={this.handleWefDateTimeChange}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["wefDate"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["wefDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Tax Charge ($) <span className="text-red">*</span></label>
                                        <NumberFormat className="form-control form-control-sm" name="taxCharge" fixedDecimalScale={true} maxLength={8} decimalScale={3} title="Tax Charge($)" value={this.state.taxCharge} onChange={this.handleInputChange} placeholder="Enter tax charge in $" />
                                        {this.state.errors["taxCharge"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["taxCharge"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group" style={{ display: this.state.taxTypeId === 1 ? "inline" : "none" }}>
                                        <label>Ride Type</label><br />
                                        <select name="rideTypeId" className="form-control form-control-sm" value={this.state.rideTypeId} onChange={this.handleInputChange}>
                                            <option value={0}>Select ride type</option>
                                            {
                                                this.state.rideTypeList && this.state.rideTypeList.map((item, i) =>
                                                    (<option key={i} value={item.id}>{item.name}</option>)
                                                )
                                            }
                                        </select>
                                        {this.state.errors["rideTypeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["rideTypeId"]}</span>}

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success btn-sm" onClick={this.handleTaxSettingSubmit} >
                                {this.state.isSubmited && <span title="In-process" ><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Save"><i className="fas fa-save"></i> Save</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeTaxSettingModal} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
            </div>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={position}
                visible={this.state.loadPanelVisible}
                showIndicator={true}
                shading={false}
                showPane={true}
                closeOnOutsideClick={false}
            />
        </div>)
    }
}