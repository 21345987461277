import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
} from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import DateBox from 'devextreme-react/date-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import { SelectBox } from 'devextreme-react/select-box';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React from 'react';
import { Link } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';
import Swal from 'sweetalert2';
import { DateFormat } from '../../helpers/fixcodes';
import { formatDate, getRequestParams } from '../../helpers/globalfunctions';
const position = { of: '#historydiv' };

export class InvoiceReceiptVoucherComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            loadPanelVisible: false,

            chkCustomerSelection: "1",
            dropdownCustomer: [],
            customerCode: '',

            chkInvoiceSelection: "1",
            invoiceNumber: '',
            chkReceiptSelection: "1",
            receiptNumber: '',

            chkPeriodSelection: "1",
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),
            invoiceReceiptVoucher: [],
            errors: {
                customerCode: '',
                invoiceNumber: '',
                receiptNumber: '',
            }
        }
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.getCustomer = this.getCustomer.bind(this);
        this.handleCustomerChange = this.handleCustomerChange.bind(this);
        this.handleCustomerSelectionChange = this.handleCustomerSelectionChange.bind(this);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInvoiceSelectionChange = this.handleInvoiceSelectionChange.bind(this);
        this.handleReceiptSelectionChange = this.handleReceiptSelectionChange.bind(this);

        this.handlePeriodSelectionChange = this.handlePeriodSelectionChange.bind(this);
        this.handleFromDateChanged = this.handleFromDateChanged.bind(this);
        this.handleToDateChanged = this.handleToDateChanged.bind(this);


        this.handleSearchAccountPaySummaryReportSubmit = this.handleSearchAccountPaySummaryReportSubmit.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);

        this.downloadAsPdf = this.downloadAsPdf.bind(this);
        this.emailReports = this.emailReports.bind(this);
        this.getInvoiceReceiptVoucherReport = this.getInvoiceReceiptVoucherReport.bind(this);
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true,
        });
        await this.getCustomer();
        this.setState({
            loadPanelVisible: false,
        });
    }

    onToolbarPreparing(e) {


        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'exportpdf',
                onClick: this.downloadAsPdf.bind(this),
                hint: 'Export to Pdf'
            }
        });

        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'email',
                onClick: this.emailReports.bind(this),
                hint: 'Email'
            }
        });

        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getInvoiceReceiptVoucherReport.bind(this),
                hint: 'Refresh'
            }
        });
    }

    async downloadAsPdf() {
        if (this.handleValidation()) {
            try {
                this.setState({
                    isSubmited: true,
                });
                var RptHead = "";
                if (this.state.chkCustomerSelection == "2") {
                    let puZone = this.state.dropdownCustomer.find(opt => opt.code == this.state.customerCode);
                    RptHead = RptHead + ", for Customer: " + puZone.fullName;
                } else {
                    RptHead = RptHead + ", for all Customers";
                }

                if (this.state.chkInvoiceSelection == "2") {
                    RptHead = RptHead + ", for Invoice No: " + this.state.invoiceNumber.toString();
                } else {
                    RptHead = RptHead + ", for all Invoices";
                }

                if (this.state.chkReceiptSelection == "2") {
                    RptHead = RptHead + ", for Receipt No: " + this.state.receiptNumber.toString();
                } else {
                    RptHead = RptHead + ", for all Receipt";
                }

                if (this.state.chkPeriodSelection == "2") {
                    RptHead = RptHead + "For paid date range " + formatDate(this.state.startDate, "MM/dd/yyyy") + " to " + formatDate(this.state.endDate, "MM/dd/yyyy");
                } else {
                    RptHead = RptHead + ", for all paid Date";
                }

                var request = new Object();
                request.AccountCode = this.state.chkCustomerSelection == "1" ? "" : this.state.customerCode;
                request.InvoiceNo = this.state.chkInvoiceSelection == "1" ? 0 : parseInt(this.state.invoiceNumber);
                request.ReceiptNo = this.state.chkReceiptSelection == "1" ? 0 : parseInt(this.state.receiptNumber);
                request.StartDate = this.state.chkPeriodSelection == "1" ? null : formatDate(this.state.startDate, "MM/dd/yyyy") + " 00:00:00"; //new Date(2020, 9, 1);
                request.EndDate = this.state.chkPeriodSelection == "1" ? null : formatDate(this.state.endDate, "MM/dd/yyyy") + " 23:59:59"; //new Date(2020, 9, 31);
                request.Criteria1 = RptHead;
                request.fileFormat = 'pdf';
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.MethodName = "GetMultipleInvoiceReceiptVoucher";
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({
                    isSubmited: false,
                });

                let a = document.createElement('a');
                a.href = dataResponse.reportBase64String;
                a.download = 'MultipleReceiptVoucher.pdf';
                a.click();
            } catch (e) {
                this.setState({
                    isSubmited: false,
                });
                console.log(e)
            }
        }
    }

    async emailReports() {
        const isValid = await Swal.fire({
            title: "<small>Pending Implementation</small>",
            text: "This feature is comming soon.",
            icon: "info",
            dangerMode: false,
            width: "25%",
            dangerMode: false,
            allowOutsideClick: false
        });
        return;
    }

    async getInvoiceReceiptVoucherReport() {

        var request = new Object();
        request.LocationId = Number(sessionStorage.getItem("session_locationId"));
        request.CustomerCode = this.state.chkCustomerSelection == "1" ? "" : this.state.customerCode;
        request.InvoiceNo = this.state.chkInvoiceSelection == "1" ? 0 : parseInt(this.state.invoiceNumber);
        request.ReceiptNo = this.state.chkReceiptSelection == "1" ? 0 : parseInt(this.state.receiptNumber);
        request.StartDate = this.state.chkPeriodSelection == "1" ? null : formatDate(this.state.startDate, "MM/dd/yyyy") + " 00:00:00"; //new Date(2020, 9, 1);
        request.EndDate = this.state.chkPeriodSelection == "1" ? null : formatDate(this.state.endDate, "MM/dd/yyyy") + " 23:59:59"; //new Date(2020, 9, 31);

        try {
            this.setState({
                isSubmited: true,
            });
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('report-api/multiple-receipt-voucher', requestParams);
            const data = await response.json();
            this.setState({
                invoiceReceiptVoucher: data.multipleReceiptVoucher
            });
            this.setState({ isSubmited: false });

        } catch (e) {
            this.setState({ isSubmited: false });
        }

    }


    async getCustomer() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('account-api/customer-dropdown/', requestParams);
            const data = await response.json();
            this.setState({
                dropdownCustomer: data.customers,
            });

        } catch (e) {
            console.log(e);
        }
    }

    handleCustomerChange(selectedOption) {
        this.setState({ customerCode: (selectedOption.selectedItem != null ? selectedOption.selectedItem.code : ''), })
        this.state.errors["customerCode"] = "";
    }

    handleCustomerSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkCustomerSelection: value, customerCode: '' });
        this.state.errors["customerCode"] = "";
    }

    handleInvoiceSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkInvoiceSelection: value, invoiceNumber: '' });
        this.state.errors["invoiceNumber"] = "";
    }

    handleReceiptSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkReceiptSelection: value, receiptNumber: '' });
        this.state.errors["receiptNumber"] = "";
    }


    handlePeriodSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkPeriodSelection: value });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === "invoiceNumber") {
            const re = /^[0-9\b]+$/;
            if (event.target.value === '' || re.test(event.target.value)) {
                this.setState({ [name]: event.target.value })
            }
        } else if (name === "receiptNumber") {
            const re = /^[0-9\b]+$/;
            if (event.target.value === '' || re.test(event.target.value)) {
                this.setState({ [name]: event.target.value })
            }
        } else {
            this.setState({
                [name]: value
            });
        }
    }

    handleFromDateChanged(event) {
        let fromDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ startDate: fromDateValue })
    }
    handleToDateChanged(event) {
        let toDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ endDate: toDateValue })
    }


   async handleSearchAccountPaySummaryReportSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            await this.getInvoiceReceiptVoucherReport();
            //this.downloadAccountPaymentTypeSummaryAsPdf();
        }
    }
    handleClearSearch() {
        this.setState({
            isSubmited: false,
            loadPanelVisible: false,

            chkCustomerSelection: "1",
            dropdownCustomer: [],
            customerCode: '',

            chkInvoiceSelection: "1",
            invoiceNumber: '',
            chkReceiptSelection: "1",
            receiptNumber: '',

            chkPeriodSelection: "1",
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),

            errors: {
                customerCode: '',
                invoiceNumber: '',
                receiptNumber: '',
            }
        });
    }

    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.vehicleId = "";
        errors.reimbursementPeriodId = "";

        if (this.state.chkCustomerSelection === "2" && this.state.customerCode == "") {
            formIsValid = false;
            errors.customerCode = "Please select customer.";
        }

        if (this.state.chkInvoiceSelection === "2" && this.state.invoiceNumber == "") {
            formIsValid = false;
            errors.invoiceNumber = "Please enter invoice number.";
        }

        if (this.state.chkReceiptSelection === "2" && this.state.receiptNumber == "") {
            formIsValid = false;
            errors.invoiceNumber = "Please enter receipt number.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }


    async downloadAccountPaymentTypeSummaryAsPdf() {
        try {
            this.setState({
                isSubmited: true,
            });
            var RptHead = "";
            var selection_Criteria = "";

            RptHead = "Order By " + " " + (this.state.chkOrderBySelection == "1" ? " Customer " : (this.state.chkOrderBySelection == "2" ? " Invoice No " : " Invoice date ")) + " for ";

            var request = new Object();

            if (this.state.chkCustomerSelection == "2") {
                selection_Criteria = "{Proc_Select_Customer_Invoice;1.CUSTOMER_CODE} ='" + this.state.customerCode + "'";
                let puZone = this.state.dropdownCustomer.find(opt => opt.code == this.state.customerCode);
                RptHead = RptHead + ", for Customer: " + puZone.fullName;
            } else {
                request.VehicleId = parseInt(0);
                RptHead = RptHead + ", for all Customers";
            }

            if (this.state.chkInvoiceSelection == "2") {
                selection_Criteria = selection_Criteria + (selection_Criteria.Length > 0 ? " And " : "") + "{Proc_Select_Customer_Invoice;1.Invoice_No} = " + this.state.invoiceNumber.toString();
                RptHead = RptHead + ", for Invoice No: " + this.state.invoiceNumber.toString();
            } else {
                RptHead = RptHead + ", for all Invoices";
            }

            if (this.state.chkPeriodSelection == "2") {
                selection_Criteria = selection_Criteria + (selection_Criteria.Length > 0 ? " And " : "") + "({Proc_Select_Customer_Invoice;1.Invoice_Date} >=  CDateTime (" + formatDate(this.state.startDate, "MM/dd/yyyy") + " 00:00:01" + ") and  {Proc_Select_Customer_Invoice;1.Invoice_Date} <=  CDateTime (" + formatDate(this.state.endDate, "MM/dd/yyyy") + " 23:59:59" + "))";
                RptHead = RptHead + "For invoice date range " + formatDate(this.state.startDate, "MM/dd/yyyy") + " to " + formatDate(this.state.endDate, "MM/dd/yyyy");
            } else {
                RptHead = RptHead + ", for all Invoice Date";
            }


            request.Criteria1 = RptHead;
            request.SelectionCrieteria = selection_Criteria;
            request.LocationId = Number(sessionStorage.getItem("session_locationId"));
            request.fileFormat = 'pdf';
            if (this.state.chkReportTypeSelection == "1") {
                if (this.state.chkSelectTypeSelection == "1") {
                    request.MethodName = "GetSalesActivitySummaryByAccountCode";
                } else {
                    request.MethodName = "GetSalesActivitySummaryByAccountType";
                }
            } else {

                if (this.state.chkOrderBySelection == "1") {
                    request.SortBy = "CustomerCode";
                } else if (this.state.chkOrderBySelection == "2") {
                    request.SortBy = "InvoiceNo";
                } else if (this.state.chkOrderBySelection == "3") {
                    request.SortBy = "InvoiceDate";
                }

                request.MethodName = "GetSalesActivityDetails";
            }

            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            this.setState({
                isSubmited: false,
            });

            let a = document.createElement('a');
            a.href = dataResponse.reportBase64String;

            if (this.state.chkReportTypeSelection == "1") {
                if (this.state.chkSelectTypeSelection == "1") {
                    a.download = 'SalesActivitySummaryByAccountCode.pdf';
                } else {
                    a.download = 'SalesActivitySummaryByAccountType.pdf';
                }
            } else {
                a.download = 'SalesActivityDetails.pdf';
            }

            a.click();
        } catch (e) {
            this.setState({
                isSubmited: false,
            });
            console.log(e)
        }
    }

    async viewInvoiceReport(e, data, fileFormat) {
        e.preventDefault();
        this.setState({
            loadPanelVisible: true,
        });
        var request = new Object();
        request.ReceiptNo = parseInt(data.receiptNo);
        request.fileFormat = fileFormat;
        request.MethodName = "GetInvoiceReceiptReport";

        const requestParams = getRequestParams('POST', request);
        const response = await fetch('crystal-report-api', requestParams);
        const dataResponse = await response.json();
        this.setState({
            loadPanelVisible: false,
        });

        let a = document.createElement('a');
        a.href = dataResponse.reportBase64String;
        if (fileFormat == "pdf") {
            a.download = 'InvoiceReceipt_' + data.receiptNo + '.pdf';
        } else {
            a.download = 'InvoiceReceipt_' + data.receiptNo + '.xlsx';
        }
        a.click();
    }

    render() {
       
        const renderGridCurrencyCell = (data) => {
            return <div>${parseFloat(data.value).toFixed(2)}</div>;
        }

        const renderGridCell = (data) => {
            return <div>
                <a href="#" className="fas fa-file-pdf icon text-info" title="View in PDF" onClick={(e) => { this.viewInvoiceReport(e, data.data, "pdf") }}></a>&nbsp;&nbsp;
                <a href="#" className="fas fa-file-excel icon text-info" title="View in Excel" onClick={(e) => { this.viewInvoiceReport(e, data.data, "xlsx") }}></a>&nbsp;&nbsp;
            </div>;
        }

        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-sm-6 col-md-6 col-lg-6">
                                <h1 className="m-0 text-dark">Invoice Receipt Voucher</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem> <NavLink tag={Link} to="/report" className="btn btn-block btn-outline-secondary btn-sm" title="Reports" ><i className="fas fa-backward"></i> Back</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <form method="post" onSubmit={this.handleSearchAccountPaySummaryReportSubmit} style={(this.state.isSubmited) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="rez-header">
                        <div className="container">
                            <div className="row">

                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdAllCustomer" value="1" checked={this.state.chkCustomerSelection === "1"} onChange={this.handleCustomerSelectionChange} />
                                            <label className="form-check-label">All Customer</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdSingleCustomer" value="2" checked={this.state.chkCustomerSelection === "2"} onChange={this.handleCustomerSelectionChange} />
                                            <label className="form-check-label">Single</label>
                                        </span>
                                        <SelectBox
                                            dataSource={this.state.dropdownCustomer.length > 0 ? this.state.dropdownCustomer : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select Customer"
                                            name="customerCode"
                                            defaultValue={0}
                                            value={this.state.customerCode}
                                            displayExpr='fullName'
                                            valueExpr='code'
                                            onSelectionChanged={this.handleCustomerChange}
                                            showClearButton={true}
                                            disabled={this.state.chkCustomerSelection === "1"}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerCode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerCode"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdAllInvoice" value="1" checked={this.state.chkInvoiceSelection === "1"} onChange={this.handleInvoiceSelectionChange} />
                                            <label className="form-check-label">All Invoice</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdSingleInvoice" value="2" checked={this.state.chkInvoiceSelection === "2"} onChange={this.handleInvoiceSelectionChange} />
                                            <label className="form-check-label">Single</label>
                                        </span>
                                        <div className="form-group">
                                            <input type="text" className="form-control form-control-sm" name="invoiceNumber" disabled={this.state.chkInvoiceSelection === "1"} maxLength="30" title="Enter invoice number" value={this.state.invoiceNumber} onChange={this.handleInputChange} placeholder="Enter invoice #" />
                                        </div>
                                        {this.state.errors["invoiceNumber"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["invoiceNumber"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdAllReceipt" value="1" checked={this.state.chkReceiptSelection === "1"} onChange={this.handleReceiptSelectionChange} />
                                            <label className="form-check-label">All Receipt</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdSingleReceipt" value="2" checked={this.state.chkReceiptSelection === "2"} onChange={this.handleReceiptSelectionChange} />
                                            <label className="form-check-label">Single</label>
                                        </span>
                                        <div className="form-group">
                                            <input type="text" className="form-control form-control-sm" name="receiptNumber" disabled={this.state.chkReceiptSelection === "1"} maxLength="30" title="Enter receipt number" value={this.state.receiptNumber} onChange={this.handleInputChange} placeholder="Enter receipt #" />
                                        </div>
                                        {this.state.errors["receiptNumber"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["receiptNumber"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">&nbsp;</label>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdAllPeriod" value="1" checked={this.state.chkPeriodSelection === "1"} onChange={this.handlePeriodSelectionChange} />
                                            <label className="form-check-label">All Period</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdInRangePeriod" value="2" checked={this.state.chkPeriodSelection === "2"} onChange={this.handlePeriodSelectionChange} />
                                            <label className="form-check-label">In Range</label>
                                        </span>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.startDate}
                                            value={this.state.startDate}
                                            onValueChanged={this.handleFromDateChanged}
                                            width="100%"
                                            disabled={this.state.chkPeriodSelection === "1"}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>To Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.endDate}
                                            value={this.state.endDate}
                                            onValueChanged={this.handleToDateChanged}
                                            width="100%"
                                            disabled={this.state.chkPeriodSelection === "1"}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12 text-right">
                                    <div className="form-group">
                                        <label className="btn-block">&nbsp;</label>
                                        <button type="submit" className="btn btn-success btn-sm" onClick={this.handleSearchAccountPaySummaryReportSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                            {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSubmited && <span title="Search"><i className="fas fa-search"></i> Search</span>}
                                        </button>&nbsp; &nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleClearSearch} title="Clear"><i className="fas fa-eraser"></i> Clear</button>&nbsp; &nbsp;
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </form>
                <div className="content">
                    <div className="card">
                        <div className="card-body">
                            <DataGrid
                                dataSource={this.state.invoiceReceiptVoucher}
                                keyExpr="receiptNo"
                                columnsAutoWidth={true}
                                showColumnLines={false}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                showBorders={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                width='100%'
                                onToolbarPreparing={this.onToolbarPreparing}>
                                <Column dataField="receiptNo" width={40} caption="" fixed={true} allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                <Column dataField="receiptNo" caption="Receipt Number" allowFiltering={true} allowSorting={true} />
                                <Column dataField="customerType" caption="Customer Type" allowFiltering={true} allowSorting={true} />
                                <Column dataField="customerCode" caption="Customer Code" allowFiltering={true} allowSorting={true} />
                                <Column dataField="customerName" caption="Customer Name" allowFiltering={true} allowSorting={true} />
                                <Column dataField="invoiceNo" caption="Invoice Number" allowFiltering={true} allowSorting={true} />
                                <Column dataField="invoiceDate" caption="Invoice Date" allowFiltering={true} allowSorting={true} format={DateFormat.DefaultDateTimeFormat} dataType="datetime" />
                                <Column dataField="paymentType" caption="Payment Type" allowFiltering={true} allowSorting={true} />
                                <Column dataField="netAmount" caption="Billed Amount" allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="dedAmount" caption="Discount Amount" allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="writeOffAmount" caption="Write-off Amount" allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="totalPaid" caption="Paid Amount" allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="paidDate" caption="Paid Date" allowFiltering={true} allowSorting={true} format={DateFormat.DefaultDateTimeFormat} dataType="datetime" />
                                <Column dataField="depositedBy" caption="Paid By" allowFiltering={true} allowSorting={true} />

                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={false} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                <Export enabled={true} allowExportSelectedData={false} />
                                <ColumnChooser enabled={false} />
                                <ColumnFixing enabled={false} />
                            </DataGrid>
                        </div>
                    </div>

                </div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        )
    }
}