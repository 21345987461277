import React, { Component } from 'react';
import { LoadPanel } from 'devextreme-react/load-panel';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { SelectBox } from 'devextreme-react/select-box';
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'
export class ChequeViewPrintComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isSubmitted: false,
            isRefreshSubmitted: false,
            isEmailSubmitted: false,
            loadPanelVisible: false,
            isViewChequeReport: true,
            isViewVehicleReport: false,
            isViewChequeOnly: false,
            isCheckDueAmtGreaterZero: false,
            isAllVehicle: true,
            vehiclesDropdownList: [],
            vehicleDropdownData: null,
            vehicleId: 0,
            vehicleNo: '',
            vehiclePayPeriodDropdownList: [],
            payPeriodDropdownDate: null,
            payPeriodDate: '',
            errors: {
                payPeriodDate: '',
                vehicle: '',
            }
        }
    }
    componentDidMount() {
        this.fetchApiData();
    }
    getPayPeriodDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('pay-period-api/0/dropdown-list', requestParams);
            if (response.ok) {
                const data = await response.json();
                if (data.isSuccess) {
                    this.setState({ vehiclePayPeriodDropdownList: data.vehiclePayPeriods });
                }
            }
            else {
                //await this.onfailure('error', null, null,)
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    getVehiclesDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('vehicle-api/dropdown-all', requestParams);
            if (response.ok) {
                const data = await response.json();
                if (data.isSuccess) {
                    this.setState({
                        vehiclesDropdownList: data.vehiclesDropdown
                    });
                }
            }
            else {
                //await this.onfailure('error', null, null,)
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    async fetchApiData() {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getPayPeriodDropdownList();
            await this.getVehiclesDropdownList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnRefresh = async () => {
        try {
            this.setState({ isRefreshSubmitted: true, loadPanelVisible: true });
            await new Promise(resolve => setTimeout(resolve, 1000)); // 1 sec
            this.setState({
                isSubmitted: false,
                isRefreshSubmitted: false,
                isEmailSubmitted: false,
                loadPanelVisible: false,
                isViewChequeReport: true,
                isViewVehicleReport: false,
                isViewChequeOnly: false,
                isCheckDueAmtGreaterZero: false,
                isAllVehicle: true,
                vehiclesDropdownList: [],
                vehicleDropdownData: null,
                vehicleId: 0,
                vehicleNo: '',
                vehiclePayPeriodDropdownList: [],
                payPeriodDropdownDate: null,
                payPeriodDate: '',
                errors: {
                    payPeriodDate: '',
                    vehicle: '',
                }
            });
            await this.fetchApiData();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isRefreshSubmitted: false, loadPanelVisible: false });
    }
    handleOnClearSearch = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isRefreshSubmitted: false,
                isEmailSubmitted: false,
                loadPanelVisible: false,
                isViewChequeReport: true,
                isViewVehicleReport: false,
                isViewChequeOnly: false,
                isCheckDueAmtGreaterZero: false,
                isAllVehicle: true,
                vehicleDropdownData: null,
                vehicleId: 0,
                vehicleNo: '',
                payPeriodDropdownDate: null,
                payPeriodDate: '',
                errors: {
                    payPeriodDate: '',
                    vehicle: '',
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnInputChange = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
            let errors = this.state.errors;
            switch (name) {
                case 'isViewChequeReport':
                    this.setState({ isViewChequeReport: value, isViewVehicleReport: false });
                    break;
                case 'isViewVehicleReport':
                    this.setState({ isViewChequeReport: false, isViewVehicleReport: value });
                    break;
                case 'isViewChequeOnly':
                    this.setState({ isViewChequeOnly: value });
                    break;
                case 'isCheckDueAmtGreaterZero':
                    this.setState({ isCheckDueAmtGreaterZero: value });
                    break;
                case 'isAllVehicle':
                    errors.vehicle = '';
                    this.setState({ isAllVehicle: value, vehicleDropdownData: null, vehicleId: 0, vehicleNo: '' });
                    break;
                default:
                    break;
            }
            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnPayPeriodDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.payPeriodDate = '';
            let payPeriodDate = '';
            let selectedPayPeriod = [];
            if (data !== null && data.selectedItem !== null) {
                payPeriodDate = moment(data.selectedItem.payEndDate, 'YYYYMMDD').format('MM/DD/YYYY');
                selectedPayPeriod = data.selectedItem;
            }
            else {
                errors.payPeriodDate = 'Please select reimbursment period.';
            }
            this.setState({ payPeriodDropdownDate: selectedPayPeriod, payPeriodDate: payPeriodDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnVehicleDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.vehicle = '';
            let vehicleId = 0;
            let vehicleNo = '';
            let selectedVehicle = [];
            if (data !== null && data.selectedItem !== null) {
                vehicleId = data.selectedItem.id;
                vehicleNo = data.selectedItem.name;
                selectedVehicle = data.selectedItem;
            }
            else {
                errors.vehicle = 'Please select vehicle.';
            }
            this.setState({ vehicleDropdownData: selectedVehicle, vehicleId: vehicleId, vehicleNo: vehicleNo, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnReportSubmit = async (event) => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isSubmitted: true });
                var request = new Object();
                let selectionCriteria = "";
                if (this.state.isViewVehicleReport === true) {
                    selectionCriteria = "{PROC_RPT_ACVehicleCheque_Details;1.PAY_DATE} = CDateTime('" + moment(this.state.payPeriodDate, 'MM/DD/YYYY').format("YYYY-MM-DD") + " 23:59:59')";

                    if (Number(this.state.vehicleId) > 0) {
                        selectionCriteria = selectionCriteria + (selectionCriteria != "" ? " And " : "") + " {PROC_RPT_ACVehicleCheque_Details;1.VEHICLE_NO} = '" + this.state.vehicleNo + "'";
                    }
                    if (this.state.isViewChequeOnly) {
                        selectionCriteria = selectionCriteria + (selectionCriteria != "" ? " And " : "") + "{PROC_RPT_ACVehicleCheque_Details;1.CHECK_NO} > '0' ";
                    }
                    if (this.state.isCheckDueAmtGreaterZero) {
                        selectionCriteria = selectionCriteria + (selectionCriteria != "" ? " And " : "") + " {PROC_RPT_ACVehicleCheque_Details;1.CHECK_AMOUNT} > 0 ";
                    }
                }
                else {
                    if (this.state.isViewChequeOnly) {
                        selectionCriteria = "{Proc_Select_VehicleCheque_Summary;1.Check_No} > '0' ";
                    }
                    if (this.state.isCheckDueAmtGreaterZero) {
                        selectionCriteria = selectionCriteria + (selectionCriteria != "" ? " And " : "") + "{Proc_Select_VehicleCheque_Summary;1.Total_Paid} > 0 ";
                    }
                    request.VehicleId = Number(this.state.vehicleId);
                    //request.PayPeriodDate = moment(this.state.payPeriodDate, 'MM/DD/YYYY').format("MM/DD/YYYY");
                    request.PayPeriodDate = moment(this.state.payPeriodDate, 'MM/DD/YYYY').format("YYYY-MM-DD") + ' 23:59:59';
                }
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.fileFormat = 'pdf';
                request.ReportType = (this.state.isViewChequeReport === true) ? 1 : 0;
                request.MethodName = "GetAccountPayments";
                request.SelectionCrieteria = selectionCriteria;
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                let a = document.createElement('a');
                a.href = dataResponse.reportBase64String;
                a.download = 'AccountPayments.pdf';
                a.click();
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false });
    }
    isFormValid = async () => {
        let isValid = true;
        try {
            let errors = this.state.errors;
            errors.payPeriodDate = '';
            errors.vehicle = '';
            if (this.state.payPeriodDate.trim() === '') {
                errors.payPeriodDate = 'Please select reimbursment period.';
                isValid = false;
            }
            if (this.state.isAllVehicle === false) {
                if (Number(this.state.vehicleId) === 0) {
                    errors.vehicle = 'Please select vehicle.';
                    isValid = false;
                }
            }
            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    onfailure = async (icon, title, resultMessage) => {
        this.setState({ loadPanelVisible: false });
        await Swal.fire({
            allowOutsideClick: false,
            showCloseButton: true,
            icon: icon,
            title: (title === null || title.trim() === '') ? 'Oops...' : title,
            text: (resultMessage === null || resultMessage.trim() === '') ? 'Opps! something went wrong, unable to process the request.' : resultMessage.trim(),
        })
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isEmailSubmitted: true });
                var request = new Object();
                let selectionCriteria = "";
                if (this.state.isViewVehicleReport === true) {
                    selectionCriteria = "{PROC_RPT_ACVehicleCheque_Details;1.PAY_DATE} = CDateTime('" + moment(this.state.payPeriodDate, 'MM/DD/YYYY').format("YYYY-MM-DD") + " 23:59:59')";

                    if (Number(this.state.vehicleId) > 0) {
                        selectionCriteria = selectionCriteria + (selectionCriteria != "" ? " And " : "") + " {PROC_RPT_ACVehicleCheque_Details;1.VEHICLE_NO} = '" + this.state.vehicleNo + "'";
                    }
                    if (this.state.isViewChequeOnly) {
                        selectionCriteria = selectionCriteria + (selectionCriteria != "" ? " And " : "") + "{PROC_RPT_ACVehicleCheque_Details;1.CHECK_NO} > '0' ";
                    }
                    if (this.state.isCheckDueAmtGreaterZero) {
                        selectionCriteria = selectionCriteria + (selectionCriteria != "" ? " And " : "") + " {PROC_RPT_ACVehicleCheque_Details;1.CHECK_AMOUNT} > 0 ";
                    }
                }
                else {
                    if (this.state.isViewChequeOnly) {
                        selectionCriteria = "{Proc_Select_VehicleCheque_Summary;1.Check_No} > '0' ";
                    }
                    if (this.state.isCheckDueAmtGreaterZero) {
                        selectionCriteria = selectionCriteria + (selectionCriteria != "" ? " And " : "") + "{Proc_Select_VehicleCheque_Summary;1.Total_Paid} > 0 ";
                    }
                    request.VehicleId = Number(this.state.vehicleId);
                    request.PayPeriodDate = moment(this.state.payPeriodDate, 'MM/DD/YYYY').format("YYYY-MM-DD") + ' 23:59:59';
                }
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.fileFormat = 'pdf';
                request.ReportType = (this.state.isViewChequeReport === true) ? 1 : 0;
                request.MethodName = "GetAccountPayments";
                request.SelectionCrieteria = selectionCriteria;
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Payments';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = 'AccountPayments.pdf'
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <form method="post" onSubmit={this.handleOnReportSubmit}
                    style={(this.state.isSubmitted || this.state.isEmailSubmitted || this.state.isRefreshSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="content-wrapper">
                        <div className="content-header">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                        <h1 className="m-0 text-dark">Cheque - View / Print</h1>
                                    </div>
                                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                                        <ol className="breadcrumb float-sm-right">
                                            <NavItem>
                                                <NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="#" title="Refresh"
                                                    onClick={this.handleOnRefresh}>
                                                    {this.state.isRefreshSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                                    {!this.state.isRefreshSubmitted && <span title="Refresh"><i className="fas fa-sync-alt"></i> Refresh</span>}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to={{
                                                    pathname: "/franchisee/export/payroll",
                                                    isReExport: false
                                                }}
                                                    className="btn btn-block btn-outline-secondary btn-sm" title="Export Payroll">
                                                    <i className="fas fa-file-export"></i> Export Payroll</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to="/franchisee/importcheques" className="btn btn-block btn-outline-secondary btn-sm" title="Import Cheque">
                                                    <i className="fas fa-file-import"></i> Import Cheque</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to="/franchisee/export/appayroll" className="btn btn-block btn-outline-secondary btn-sm" title="Export AP Payroll">
                                                    <i className="fas fa-file-export"></i> Export AP Payroll</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to="/reimbursement/view" className="btn btn-block btn-outline-secondary btn-sm" title="Reimbursement View">
                                                    <i className="fas fa-car"></i> Reimb. View</NavLink>
                                            </NavItem>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="card mt-3" style={{ width: "30%", margin: "0 auto" }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label>Reimbursment Period</label>
                                                {/*<Select
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Select pay period"
                                                    defaultValue={[]}
                                                    value={this.state.payPeriodDropdownDate}
                                                    name="ddpayperiod"
                                                    onChange={this.handleOnPayPeriodDropdownChange}
                                                    getOptionLabel={option => option.viewDate}
                                                    getOptionValue={option => option.payEndDate}
                                                    options={this.state.vehiclePayPeriodDropdownList}
                                                    isMulti={false}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    isDisabled={false}
                                                />*/}
                                                <SelectBox
                                                    dataSource={this.state.vehiclePayPeriodDropdownList}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Select pay period"
                                                    name="ddpayperiod"
                                                    defaultValue={''}
                                                    value={(this.state.payPeriodDropdownDate !== null && this.state.payPeriodDropdownDate.payEndDate !== null) ? this.state.payPeriodDropdownDate.payEndDate : ''}
                                                    displayExpr='viewDate'
                                                    valueExpr='payEndDate'
                                                    onSelectionChanged={this.handleOnPayPeriodDropdownChange}
                                                    showClearButton={true}
                                                    disabled={false}
                                                    searchEnabled={true}
                                                />
                                                {this.state.errors["payPeriodDate"].length > 0 && <span className='error invalid-feedback'>
                                                    {this.state.errors["payPeriodDate"]}</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox"
                                                        name="isAllVehicle"
                                                        checked={this.state.isAllVehicle}
                                                        onChange={this.handleOnInputChange} />
                                                    <label className="form-check-label" htmlFor="isAllVehicle"> All Vehicle</label>
                                                </div>
                                                {/*<Select
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Select vehicle"
                                                    defaultValue={[]}
                                                    value={this.state.vehicleDropdownData}
                                                    name="ddvehicles"
                                                    onChange={this.handleOnVehicleDropdownChange}
                                                    getOptionLabel={option => option.name}
                                                    getOptionValue={option => option.id}
                                                    options={this.state.vehiclesDropdownList}
                                                    isMulti={false}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    isDisabled={this.state.isAllVehicle}
                                                />*/}
                                                <SelectBox
                                                    dataSource={this.state.vehiclesDropdownList}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Select vehicle"
                                                    name="ddvehicles"
                                                    defaultValue={0}
                                                    value={this.state.vehicleId}
                                                    displayExpr='name'
                                                    valueExpr='id'
                                                    onSelectionChanged={this.handleOnVehicleDropdownChange}
                                                    showClearButton={true}
                                                    disabled={this.state.isAllVehicle}
                                                    searchEnabled={true}
                                                />
                                                {this.state.errors["vehicle"].length > 0 && <span className='error invalid-feedback'>
                                                    {this.state.errors["vehicle"]}</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label htmlFor="">Report Type</label><br />
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio"
                                                        name="isViewChequeReport"
                                                        checked={this.state.isViewChequeReport}
                                                        onChange={this.handleOnInputChange} />
                                                    <label className="form-check-label" htmlFor="isViewChequeReport"> View Cheque</label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio"
                                                        name="isViewVehicleReport"
                                                        checked={this.state.isViewVehicleReport}
                                                        onChange={this.handleOnInputChange} />
                                                    <label className="form-check-label" htmlFor="isViewVehicleReport"> View Vehicle</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label htmlFor="">Filter Report</label><br />
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox"
                                                        name="isViewChequeOnly"
                                                        checked={this.state.isViewChequeOnly}
                                                        onChange={this.handleOnInputChange} />
                                                    <label className="form-check-label" htmlFor="isViewChequeOnly"> View Cheque Only</label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox"
                                                        name="isCheckDueAmtGreaterZero"
                                                        checked={this.state.isCheckDueAmtGreaterZero}
                                                        onChange={this.handleOnInputChange} />
                                                    <label className="form-check-label" htmlFor="isCheckDueAmtGreaterZero"> View due amount greter than zero only</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
                                            <div className="form-group">
                                                <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                                    {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                                    {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                                                </button>&nbsp;&nbsp;
                                                <button type="submit" className="btn btn-success btn-sm" title="Download">
                                                    {this.state.isSubmitted && <span title="In-process">
                                                        <i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                                    {!this.state.isSubmitted && <span title="Download">
                                                        <i className="fas fa-file-download"></i> Download</span>}
                                                </button>&nbsp;&nbsp;
                                                <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                                    onClick={this.handleOnClearSearch}>
                                                    <i className="fas fa-eraser"></i> Clear
                                                 </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        )
    }
}