import { post } from 'axios'; //npm install--save axios
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
} from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import { LoadPanel } from 'devextreme-react/load-panel';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { SelectBox } from 'devextreme-react/select-box';
import { NavItem, NavLink } from 'reactstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getRequestParams } from '../../helpers/globalfunctions.js';

const MySwal = withReactContent(Swal)
const customStyles = {
    content: {
        width: '60%',
    }
};
const position = { of: '#invoicediv' };

export const rideTypeList = [
    {
        "rideTypeId": 1,
        "rideTypeName": "BOTH"
    }, {
        "rideTypeId": 2,
        "rideTypeName": "ARRIVAL"
    }, {
        "rideTypeId": 3,
        "rideTypeName": "DEPARTURE"
    }]
    ;
export class UploadScheduleComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFirstSubmited: false,
            isSubmited: false,
            chkDocType: 'IB',
            customerList: [],
            customerId: 0,
            locationList: [],
            locationListId: parseInt(sessionStorage.getItem("session_locationId")),
            vehicleTypeList: [],
            vehicleTypeId: 0,
            rideTypeId: 0,
            airlineCodeList: [],
            airlineCodeId: 0,
            attachment: null,
            loadPanelVisible: false,
            //read file response:
            isFileUploaded: false,
            scheduleFileReservation: null,
            tripsList: [],
            airportList: [],
            airportNameId: 0,
            flightTerminal: '',
            arrivalInterval: 0,
            departureInterval: 0,
            chkIncludePastDates: false,
            errors: {
                customerId: '',
                locationListId: '',
                vehicleTypeId: '',
                rideTypeId: '',
                airlineCodeId: '',
                attachment: ''
            },
            reservationDetailsErrors: {
                airportNameId: '',
                arrivalInterval: '',
                departureInterval: '',
            }
        }
        this.handleReadDataSubmit = this.handleReadDataSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleReservationDetailsDataSubmit = this.handleReservationDetailsDataSubmit.bind(this);
        this.getAirportList = this.getAirportList.bind(this);
        this.handleBackToUploadfile = this.handleBackToUploadfile.bind(this);

        this.handleCustomerInputChange = this.handleCustomerInputChange.bind(this);
        this.handleLocationInputChange = this.handleLocationInputChange.bind(this);
        this.handleLocationInputChange = this.handleLocationInputChange.bind(this);
        this.handleVehicleTypeInputChange = this.handleVehicleTypeInputChange.bind(this);
        this.handleRideTypeInputChange = this.handleRideTypeInputChange.bind(this);
        this.handleAirlineCodeInputChange = this.handleAirlineCodeInputChange.bind(this);
        this.handleAirportListInputChange = this.handleAirportListInputChange.bind(this);
    }
    async componentDidMount() {

        this.setState({
            loadPanelVisible: true,
        });
        await this.getairlineCodeList();
        await this.getcustomerList();
        await this.getlocationList();
        this.setState({
            loadPanelVisible: false,
        });
    }
    async getcustomerList() {
        try {
            const data = JSON.parse(sessionStorage.getItem("session_customers"));
            if (data === null) {
                const requestParams = getRequestParams('GET', '');
                const response = await fetch('account-api/customer-dropdown/', requestParams);
                const data = await response.json();
                this.setState({
                    customerList: data.customers,
                });
            }
            else {
                this.setState({
                    customerList: data.customers
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    async getlocationList() {
        const requestParams = getRequestParams('GET', "");
        const response = await fetch('user-location-api/user/' + parseInt(sessionStorage.getItem("session_userId")), requestParams);
        const data = await response.json();
        if (data !== null && data.userLocations.length > 0) {
            this.setState({
                locationList: data.userLocations,
            });
        }
        await this.getvehicleTypeList(parseInt(sessionStorage.getItem("session_locationId")));
    }

    async getvehicleTypeList(locationId) {
        try {
            var newObject = new Object();
            newObject.LocationId = locationId;
            const requestParams = getRequestParams('POST', newObject);
            const response = await fetch('vehicletype-api/vehicletype', requestParams);
            const data = await response.json();

            if (data !== null && data.apiVehicleType.length > 0) {
                this.setState({
                    vehicleTypeList: data.apiVehicleType
                });
            } else {
                this.setState({
                    vehicleTypeList: []
                });
            }

        } catch (e) {
            console.log(e);
        }
    }
    async getairlineCodeList() {
        try {
            const data = JSON.parse(sessionStorage.getItem("session_airlines"));
            if (data === null) {
                const requestParams = getRequestParams('GET', '');
                const response = await fetch('air-line-api/' + 0, requestParams);
                const data = await response.json();

                this.setState({
                    airlineCodeList: data.airLine
                });
            }
            else {
                this.setState({
                    airlineCodeList: data.airLine
                });
            }
        } catch (e) {
            console.log(e);
        }
    }
    handleCheckBoxRadioOnChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' || target.type === 'radio' ? target.checked : target.value;
        const name = target.name;
        if (name === "pdf") {
            if (event.target.checked === true) {
                this.setState({ chkDocType: target.value, })
            }
        } else if (name === "excel") {
            if (event.target.checked === true) {
                this.setState({ chkDocType: target.value, })
            }
        } else if (name === "standardformat") {
            if (event.target.checked === true) {
                this.setState({ chkDocType: target.value, })
            }
        }
        else if (name === "adjusted") {
            debugger;
            if (event.target.checked === true) {
                this.setState({ chkDocType: target.value, })
            }
        } else {
            this.setState({
                [name]: value
            });
        }
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let errors = this.state.errors;

        switch (name) {
            case 'attachment':
                errors.attachment = "";
                if (event.target.files.length === 0) {
                    this.setState({ attachment: null });
                    errors.attachment = "Please select attachment.";
                } else {
                    this.state.errors["attachment"] = '';
                    this.setState({ attachment: event.target.files[0] });
                }
                break;
            default:
                this.setState({
                    [name]: value
                });
                break;
        }
    }
    handleCustomerInputChange = (data) => {
        this.setState({ customerId: (data.selectedItem != null ? data.selectedItem.id : 0), })

        if (parseInt(data.selectedItem.airlineId) > 0) {
            let filterData = this.state.airlineCodeList.find(x => x.airLineId === data.selectedItem.airlineId);
            this.setState({
                airlineCodeId: filterData.airLineId
            });
        } else {
            this.setState({
                airlineCodeId: 0
            });
        }

        this.state.errors["airlineCodeId"] = '';
        this.state.errors["customerId"] = '';
    }

    handleLocationInputChange(data) {
        this.setState({ locationListId: (data.selectedItem != null ? data.selectedItem.locationId : 0), })

        this.getvehicleTypeList(parseInt(data.selectedItem.locationId));
        this.state.errors["locationListId"] = '';
    }
    handleVehicleTypeInputChange = (data) => {
        this.setState({ vehicleTypeId: (data.selectedItem != null ? data.selectedItem.rowIndex : 0), })
        this.state.errors["vehicleTypeId"] = '';
    }
    handleRideTypeInputChange = (data) => {
        this.setState({ rideTypeId: (data.selectedItem != null ? data.selectedItem.rideTypeId : 0), })
        this.state.errors["rideTypeId"] = '';
    }
    handleAirlineCodeInputChange = (data) => {

        this.setState({ airlineCodeId: (data.selectedItem != null ? data.selectedItem.airLineId : 0), })
        this.state.errors["airlineCodeId"] = '';
    }
    handleFormValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.customerId = '';
        errors.locationListId = '';
        errors.vehicleTypeId = '';
        errors.rideTypeId = '';
        errors.airlineCodeId = '';
        errors.attachment = '';
        if (this.state.customerId === 0) {
            formIsValid = false;
            errors.customerId = "Please select customer.";
        }
        if (this.state.locationListId === 0) {
            formIsValid = false;
            errors.locationListId = "Please select location.";
        }
        if (this.state.vehicleTypeId === 0) {
            formIsValid = false;
            errors.vehicleTypeId = "Please select vehicle type.";
        }
        if (this.state.chkDocType !== "IB" && (this.state.rideTypeId === 0)) {
            formIsValid = false;
            errors.rideTypeId = "Please select ride type.";
        }
        if (this.state.airlineCodeId === 0) {
            formIsValid = false;
            errors.airlineCodeId = "Please select airline code.";
        }
        if (this.state.attachment === null) {
            formIsValid = false;
            errors.attachment = "Please select attachment.";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    async handleReadDataSubmit(e) {
        e.preventDefault();
        if (this.handleFormValidation()) {
            let fileName = this.state.attachment.name;
            let _name = fileName.split(".")[0];
            let _extension = fileName.split(".")[1];

            if (this.state.chkDocType === "IB") {
                if (_extension.toUpperCase() !== "XLSX") {
                    const isValid = await Swal.fire({
                        title: "<small>Upload Schedule</small>",
                        text: "Invalid schedule file. Only 'xlsx' file can upload!",
                        icon: "info",
                        width: '25%',
                        dangerMode: false,
                        allowOutsideClick: false
                    });
                    return;
                }
            } else {
                let newFileName = "";

                let airlineCode = this.state.airlineCodeList.find(opt => opt.airLineId == this.state.airlineCodeId).code;
                let rideType = rideTypeList.find(opt => opt.rideTypeId == this.state.rideTypeId).rideTypeName;

                if (rideType.toUpperCase() == "BOTH") {
                    newFileName = airlineCode + "." + _extension;
                }
                else {
                    newFileName = airlineCode + "_" + rideType + "." + _extension;
                }

                if (newFileName.toUpperCase() != fileName.toUpperCase()) {
                    const isValid = await Swal.fire({
                        title: "<small>Upload Schedule</small>",
                        text: "Invalid schedule file. File Name should be [" + newFileName + "] as per selection!",
                        icon: "info",
                        width: '25%',
                        dangerMode: false,
                        allowOutsideClick: false
                    });
                    return;
                }
            }

            this.setState({
                isFirstSubmited: true
            });

            //Get rideType based on doc type
            let rideType = this.state.chkDocType === "IB" ? "Select Ride Type" : rideTypeList.find(opt => opt.rideTypeId == this.state.rideTypeId).rideTypeName;
            const url = 'uploadSchedule-api/getGridPdfAndExcelDetails/' + this.state.airlineCodeList.find(opt => opt.airLineId == this.state.airlineCodeId).code + '/' + this.state.attachment.name.split(".")[1] + '/' + rideType + '/' + this.state.chkDocType + '/' + this.state.chkIncludePastDates;
            const formData = new FormData();
            formData.append('body', this.state.attachment);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + sessionStorage.getItem('session_token'),
                    'CultureCode': sessionStorage.getItem('session_culturecode'),
                },
            };

            const response = await post(url, formData, config);
            const data = await response.data;
            debugger;
            if (data !== null) {
                if (data.isSuccss) {
                    this.setState({
                        scheduleFileReservation: data,
                        tripsList: data.trips.length > 0 ? data.trips : [],
                        isFileUploaded: true,
                        isFirstSubmited: false,
                        flightTerminal: data.flightTrminal
                    })
                    await this.getAirportList(data);
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        html: data.message,
                        width: "25%",
                        allowOutsideClick: false
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (data.isSuccess) {
                            if (result.isConfirmed) {
                                this.setState({
                                    isFirstSubmited: false
                                })
                            }
                        } else {
                            this.setState({ isFirstSubmited: false });
                        }
                    });
                }
            } else {
                this.setState({
                    isFirstSubmited: false
                })
            }
        }
    }

    handleReservationDetailsInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let errors = this.state.errors;

        this.setState({
            [name]: value
        });

    }
    handleAirportListInputChange = (data) => {
        this.setState({ airportNameId: (data.selectedItem != null ? data.selectedItem.id : 0), })

        this.state.reservationDetailsErrors["airportNameId"] = "";
    }
    async getAirportList(tripdata) {
        let airportId = 0;
        const requestParams = getRequestParams('GET', "");
        const response = await fetch('airports-api/serviceAirportByLocation/' + this.state.locationListId, requestParams);
        const data = await response.json();
        if (data !== null && data.servicedAirports.length > 0) {
            debugger;
            if (tripdata.airport != "") {
                airportId = data.servicedAirports.filter(x => x.code === tripdata.airport)[0].id;
                if (data.servicedAirports.length > 1) {
                    if (tripdata !== null && tripdata.trips[0].isAirportPickup === "Departure") {
                        airportId = data.servicedAirports.filter(x => x.code === tripdata.trips[0].dropoffLocation)[0].id;
                    } else {
                        airportId = data.servicedAirports.filter(x => x.code === tripdata.trips[0].pickupLocation)[0].id;
                    }
                }
            }

            this.setState({
                airportList: data.servicedAirports,
                airportNameId: airportId
            });

        }
    }
    handleReservationDetailsFormValidation() {
        let errors = this.state.reservationDetailsErrors;
        let formIsValid = true;
        errors.airportNameId = '';

        if (parseInt(this.state.airportNameId) <= 0) {
            formIsValid = false;
            errors.airportNameId = "Please select airport.";
        }
        this.setState({ reservationDetailsErrors: errors });
        return formIsValid;
    }
    async handleReservationDetailsDataSubmit(e) {
        e.preventDefault();
        if (this.handleReservationDetailsFormValidation()) {
            var newObject = new Object();
            newObject.Airport = this.state.airportList.filter(x => x.id === this.state.airportNameId)[0].code;
            newObject.DateRange = this.state.scheduleFileReservation.dateRange;
            newObject.FlightTerminal = this.state.flightTerminal;
            newObject.FromAirpotInterval = this.state.arrivalInterval;
            newObject.ToAirportInterval = this.state.departureInterval;
            newObject.AirlineCode = this.state.airlineCodeList.find(opt => opt.airLineId == this.state.airlineCodeId).code;
            newObject.CustomerId = this.state.customerId;
            newObject.VehicleTypeId = this.state.vehicleTypeId;
            newObject.CityName = "";
            newObject.Trips = this.state.tripsList;

            const requestParams = getRequestParams('POST', newObject);
            const response = await fetch('uploadSchedule-api/bindDocumentSchedule/' + this.state.locationListId + "/" + this.state.chkDocType, requestParams);
            const data = await response.json();
            if (data !== null && data.length > 0) {
                this.props.history.push({
                    pathname: '/nextstepuploadschedule',
                    state: data
                })
            }

        }
    }
    async handleBackToUploadfile(e) {
        e.preventDefault();
        this.setState({
            isFirstSubmited: false,
            isSubmited: false,
            chkDocType: 'IB',
            customerId: 0,
            locationListId: 0,
            vehicleTypeId: 0,
            rideTypeId: 0,
            airlineCodeId: 0,
            attachment: null,
            loadPanelVisible: false,
            //read file response:
            isFileUploaded: false,
            scheduleFileReservation: null,
            tripsList: [],
            airportList: [],
            airportNameId: 0,
            flightTerminal: '',
            arrivalInterval: 0,
            departureInterval: 0,
            chkIncludePastDates: false,
            errors: {
                customerId: '',
                locationListId: '',
                vehicleTypeId: '',
                rideTypeId: '',
                airlineCodeId: '',
                attachment: ''
            },
            reservationDetailsErrors: {
                airportNameId: '',
                arrivalInterval: '',
                departureInterval: '',
            }
        })
    }
    render() {
        return (<div className="content-wrapper">
            <div className="content-header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <h1 className="m-0 text-dark">Upload Schedule</h1>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12">
                            <ol className="breadcrumb float-sm-right">
                                <NavItem><NavLink tag={Link} to="/reservations" className="btn btn-block btn-outline-secondary btn-sm" title="New Reservation"><i className="fas fa-plus"></i> New Reservation</NavLink></NavItem>
                                <NavItem><NavLink tag={Link} to="/reservation/list" className="btn btn-block btn-outline-secondary btn-sm" title="Search Reservation"><i className="fas fa-search"></i> Search Reservation</NavLink></NavItem>
                                <NavItem><NavLink tag={Link} to="/quotation/list" className="btn btn-block btn-outline-secondary btn-sm" title="Quotation"><i className="fas fa-pen"></i> Quotation</NavLink></NavItem>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="card card-grid">
                    <div className="card-body" id="dvuploadfile" style={{ display: this.state.isFileUploaded === false ? "inline" : "none" }}>
                        <form method="post" onSubmit={this.handleReadDataSubmit} style={this.state.isFirstSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group">
                                        Document &nbsp; &nbsp; &nbsp;
                                        <div className="form-check">
                                            <label className="form-check-label">
                                                <input className="form-check-input" type="radio" style={{ marginTop: "0rem" }} name="pdf" checked={this.state.chkDocType === "Pdf" ? true : false} plassceholder="" value="Pdf" onChange={this.handleCheckBoxRadioOnChange} />
                                                PDF</label>
                                        </div>
                                        <div className="form-check">
                                            <label className="form-check-label">
                                                <input className="form-check-input" type="radio" style={{ marginTop: "0rem" }} name="excel" checked={this.state.chkDocType === "xlsx" ? true : false} plassceholder="" value="xlsx" onChange={this.handleCheckBoxRadioOnChange} />
                                                EXCEL</label>
                                        </div>
                                        <div className="form-check">
                                            <label className="form-check-label">
                                                <input className="form-check-input" type="radio" style={{ marginTop: "0rem" }} name="standardformat" checked={this.state.chkDocType === "CM" ? true : false} value="CM" plassceholder="" onChange={this.handleCheckBoxRadioOnChange} />
                                                Standard Format</label>
                                        </div>
                                        <div className="form-check">
                                            <label className="form-check-label">
                                                <input className="form-check-input" type="radio" style={{ marginTop: "0rem" }} name="adjusted" checked={this.state.chkDocType === "IB" ? true : false} plassceholder="" value="IB" onChange={this.handleCheckBoxRadioOnChange} />
                                                Adjusted</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Select Customer <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.customerList.length > 0 ? this.state.customerList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="customerId"
                                            defaultValue={0}
                                            value={this.state.customerId}
                                            displayExpr='fullName'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleCustomerInputChange}
                                            showClearButton={false}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Select Location <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.locationList.length > 0 ? this.state.locationList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select location"
                                            name="locationListId"
                                            defaultValue={0}
                                            value={this.state.locationListId}
                                            displayExpr='locationName'
                                            valueExpr='locationId'
                                            onSelectionChanged={this.handleLocationInputChange}
                                            showClearButton={false}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["locationListId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["locationListId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Select Vehicle Type <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.vehicleTypeList.length > 0 ? this.state.vehicleTypeList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select vehicle type"
                                            name="vehicleTypeId"
                                            defaultValue={0}
                                            value={this.state.vehicleTypeId}
                                            displayExpr='apiVehicleTypeDescription'
                                            valueExpr='rowIndex'
                                            onSelectionChanged={this.handleVehicleTypeInputChange}
                                            showClearButton={false}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["vehicleTypeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["vehicleTypeId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="form-group" style={{ marginTop: 26 + 'px' }}>
                                        <span className="form-check">
                                            <input className="form-check-input" type="checkbox" checked={this.state.chkIncludePastDates} onChange={this.handleInputChange} name="chkIncludePastDates" />
                                            <label className="form-check-label">Include past dates</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-12" style={{ display: this.state.chkDocType === "IB" ? "none" : "inline" }}>
                                    <div className="form-group">
                                        <label>Ride Type <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={rideTypeList.length > 0 ? rideTypeList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select location"
                                            name="rideTypeId"
                                            defaultValue={0}
                                            value={this.state.rideTypeId}
                                            displayExpr='rideTypeName'
                                            valueExpr='rideTypeId'
                                            onSelectionChanged={this.handleRideTypeInputChange}
                                            showClearButton={false}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["rideTypeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["rideTypeId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Airline Name <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.airlineCodeList.length > 0 ? this.state.airlineCodeList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select airline"
                                            name="airlineCodeId"
                                            defaultValue={0}
                                            value={this.state.airlineCodeId}
                                            displayExpr='code'
                                            valueExpr='airLineId'
                                            onSelectionChanged={this.handleAirlineCodeInputChange}
                                            showClearButton={false}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["airlineCodeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["airlineCodeId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Attachment <span className="text-danger">*</span></label>
                                        <input type="file" name="attachment" className="form-control form-control-sm" style={{ padding: 2 + 'px' }} placeholder="Upload document." onChange={this.handleInputChange} />
                                        {this.state.errors["attachment"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["attachment"]}</span>}
                                    </div>

                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <br />
                                        <button type="submit" className="btn btn-success btn-sm" onClick={this.handleReadDataSubmit} >
                                            {this.state.isFirstSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isFirstSubmited && <span title="Read"><i className="fas fa-eye"></i> Read</span>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="card-body" id="ReservationDetails" style={{ display: this.state.isFileUploaded === true ? "inline" : "none" }}>
                        <form method="post" onSubmit={this.handleReservationDetailsDataSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                            <div className="row">
                                <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>Airport</label>
                                        <SelectBox
                                            dataSource={this.state.airportList.length > 0 ? this.state.airportList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select airport"
                                            name="airportNameId"
                                            defaultValue={0}
                                            value={this.state.airportNameId}
                                            displayExpr='code'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleAirportListInputChange}
                                            showClearButton={false}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.reservationDetailsErrors["airportNameId"].length > 0 && <span className='error invalid-feedback'>{this.state.reservationDetailsErrors["airportNameId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>Flight Terminal</label>
                                        <input type="text" className="form-control form-control-sm" name="flightTerminal" value={this.state.flightTerminal} onChange={this.handleReservationDetailsInputChange} />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>Arrival Interval</label>
                                        <NumberFormat className="form-control form-control-sm" name="arrivalInterval" placeholder="ArrivalInterval" title="ArrivalInterval" value={this.state.arrivalInterval} onChange={this.handleReservationDetailsInputChange} />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>Departure Interval</label>
                                        <NumberFormat className="form-control form-control-sm" name="departureInterval" placeholder="DepartureInterval" title="DepartureInterval" value={this.state.departureInterval} onChange={this.handleReservationDetailsInputChange} />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                                    <div className="form-group"><br />
                                        <button type="submit" className="btn btn-success btn-sm" onClick={this.handleReservationDetailsDataSubmit} >
                                            {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSubmited && <span title="Next"><i className="fas fa-arrow-right"></i> Next</span>}
                                        </button> &nbsp;&nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleBackToUploadfile} ><i className="fas fa-arrow-left"></i> Back</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="card card-grid">
                    <div className="card-body">
                        <DataGrid
                            dataSource={this.state.tripsList}
                            keyExpr="reservationIndex"
                            selection={{ mode: 'single' }}
                            columnsAutoWidth={false}
                            showColumnLines={false}
                            showRowLines={true}
                            showBorders={true}
                            rowAlternationEnabled={true}
                            showBorders={true}>
                            <Column dataField="airlineCode" width={100} caption="Airline Code" allowFiltering={true} allowSorting={false} />
                            <Column dataField="flightNo" caption="Flight #" width={100} allowFiltering={true} allowSorting={true} />
                            <Column dataField="flightTime" caption="Flight Time" width={100} allowFiltering={true} allowSorting={true} />
                            <Column dataField="isAirportPickup" caption="Status" width={150} allowFiltering={true} allowSorting={true} />
                            <Column dataField="pickupDate" caption="Pick-up Date" width={100} allowFiltering={true} allowSorting={true} />
                            <Column dataField="pickupTime" caption="Pick-up Time" width={100} allowFiltering={true} allowSorting={true} />
                            <Column dataField="pickupLocation" caption="Pick-up Location" width={200} allowFiltering={true} allowSorting={true} />
                            <Column dataField="dropoffLocation" caption="Drop Off Location" width={200} allowFiltering={true} allowSorting={false} />
                            <Column dataField="noOfPax" caption="Pax" width={80} allowFiltering={true} allowSorting={false} />
                            <Column dataField="firstName" caption="First Name" width={120} allowFiltering={true} allowSorting={false} />
                            <Column dataField="lastName" caption="Last Name" width={120} allowFiltering={true} allowSorting={false} />
                            <Column dataField="flightTerminal" caption="Flight Terminal" width={150} allowFiltering={true} allowSorting={false} />

                            <SortByGroupSummaryInfo summaryItem="count" />
                            <GroupPanel visible={true} />
                            <HeaderFilter visible={false} />
                            <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                            <Grouping autoExpandAll={true} />
                            <Paging defaultPageSize={20} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true} />
                            <Export enabled={true} allowExportSelectedData={false} />
                            <ColumnChooser enabled={true} />
                            <ColumnFixing enabled={true} />

                        </DataGrid>
                    </div>
                </div>
            </div>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={position}
                visible={this.state.loadPanelVisible}
                showIndicator={true}
                shading={true}
                showPane={true}
                closeOnOutsideClick={false}
            />
        </div >);
    }
}