import { LoadPanel } from 'devextreme-react/load-panel';
import React from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { getRequestParams } from '../../helpers/globalfunctions';
import { SendEmailComponent } from '../Shared/SendEmailComponent';
const position = { of: '#historydiv' };

export class DriverCommissionComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,

            chkInvoiceSelection: "1",
            invoiceNumber: '',
            fromInvoiceNumber: '',
            toInvoiceNumber: '',

            errors: {
                invoiceNumber: '',
                fromInvoiceNumber: '',
                toInvoiceNumber: '',
            }
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInvoiceSelectionChange = this.handleInvoiceSelectionChange.bind(this);

        this.downloadAccountPaymentTypeSummaryAsPdf = this.downloadAccountPaymentTypeSummaryAsPdf.bind(this);

        this.handleSearchAccountPaySummaryReportSubmit = this.handleSearchAccountPaySummaryReportSubmit.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
    }


    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {

        this.setState({ isOpenModal: false });
        await this.handleClearSearch();
    }

    handleInvoiceSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkInvoiceSelection: value, invoiceNumber: '', fromInvoiceNumber: '', toInvoiceNumber: '' });
        this.state.errors["invoiceNumber"] = "";
        this.state.errors["fromInvoiceNumber"] = "";
        this.state.errors["toInvoiceNumber"] = "";
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({ [name]: event.target.value })
        }
    }

    handleSearchAccountPaySummaryReportSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            this.downloadAccountPaymentTypeSummaryAsPdf();
        }
    }
    handleClearSearch() {
        this.setState({
            isSubmited: false,
            isEmailSubmitted: false,
            loadPanelVisible: false,

            chkInvoiceSelection: "1",
            invoiceNumber: '',
            fromInvoiceNumber: '',
            toInvoiceNumber: '',

            errors: {
                invoiceNumber: '',
                fromInvoiceNumber: '',
                toInvoiceNumber: '',
            }
        });
    }

    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.fromInvoiceNumber = "";
        errors.invoiceNumber = "";
        errors.toInvoiceNumber = "";

        if (this.state.chkCustomerSelection === "2" && this.state.invoiceNumber == "") {
            formIsValid = false;
            errors.invoiceNumber = "Please enter invoice number.";
        }

        if (this.state.chkCustomerSelection === "3" && this.state.fromInvoiceNumber == "") {
            formIsValid = false;
            errors.fromInvoiceNumber = "Please enter from invoice number.";
        }

        if (this.state.chkInvoiceSelection === "3" && this.state.toInvoiceNumber == "") {
            formIsValid = false;
            errors.toInvoiceNumber = "Please enter to invoice number.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    async downloadAccountPaymentTypeSummaryAsPdf() {
        try {
            this.setState({
                isSubmited: true,
            });
            var RptHead = "";

            var request = new Object();
            request.LocationId = Number(sessionStorage.getItem("session_locationId"));
            request.FromCheckNo = 0;
            request.ToCheckNo = 0;
            if (this.state.chkInvoiceSelection == "2") {
                request.FromCheckNo = parseInt(this.state.invoiceNumber);
                request.ToCheckNo = parseInt(this.state.invoiceNumber);
                RptHead = RptHead + ", for Invoice No: " + this.state.invoiceNumber.toString();
            } else if (this.state.chkInvoiceSelection == "3") {
                request.FromCheckNo = parseInt(this.state.fromInvoiceNumber);
                request.ToCheckNo = parseInt(this.state.toInvoiceNumber);
                RptHead = RptHead + ", from Invoice No: " + this.state.fromInvoiceNumber.toString() + " To Invoice No: " + this.state.toInvoiceNumber.toString();
            } else {
                RptHead = RptHead + ", for all Invoices";
            }

            request.Header = RptHead;
            request.fileFormat = 'pdf';
            request.MethodName = "GetDriverCommissionReport";

            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            this.setState({
                isSubmited: false,
            });

            let a = document.createElement('a');
            a.href = dataResponse.reportBase64String;

            a.download = 'DriverCommission.pdf';

            a.click();
        } catch (e) {
            this.setState({
                isSubmited: false,
            });
            console.log(e)
        }
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isEmailSubmitted: true });
                var RptHead = "";

                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.FromCheckNo = 0;
                request.ToCheckNo = 0;
                if (this.state.chkInvoiceSelection == "2") {
                    request.FromCheckNo = parseInt(this.state.invoiceNumber);
                    request.ToCheckNo = parseInt(this.state.invoiceNumber);
                    RptHead = RptHead + ", for Invoice No: " + this.state.invoiceNumber.toString();
                } else if (this.state.chkInvoiceSelection == "3") {
                    request.FromCheckNo = parseInt(this.state.fromInvoiceNumber);
                    request.ToCheckNo = parseInt(this.state.toInvoiceNumber);
                    RptHead = RptHead + ", from Invoice No: " + this.state.fromInvoiceNumber.toString() + " To Invoice No: " + this.state.toInvoiceNumber.toString();
                } else {
                    RptHead = RptHead + ", for all Invoices";
                }

                request.Header = RptHead;
                request.fileFormat = 'pdf';
                request.MethodName = "GetDriverCommissionReport";

                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Driver Commission';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = 'DriverCommission.pdf'
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '50%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleSearchAccountPaySummaryReportSubmit}
                        style={(this.state.isSubmited || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Driver Commission</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdAllInvoice" value="1" checked={this.state.chkInvoiceSelection === "1"} onChange={this.handleInvoiceSelectionChange} />
                                            <label className="form-check-label">All Invoice</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdSingleInvoice" value="2" checked={this.state.chkInvoiceSelection === "2"} onChange={this.handleInvoiceSelectionChange} />
                                            <label className="form-check-label">Single Invoice</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdRangeInvoice" value="3" checked={this.state.chkInvoiceSelection === "3"} onChange={this.handleInvoiceSelectionChange} />
                                            <label className="form-check-label">Range Invoice</label>
                                        </span>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label className="form-check-label">Invoice #</label>
                                            <input type="text" className="form-control form-control-sm" name="invoiceNumber" disabled={this.state.chkInvoiceSelection !== "2"} maxLength="30" title="Enter invoice number" value={this.state.invoiceNumber} onChange={this.handleInputChange} placeholder="Enter invoice #" />
                                        </div>
                                        {this.state.errors["invoiceNumber"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["invoiceNumber"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label className="form-check-label">From Invoice</label>
                                        <input type="text" className="form-control form-control-sm" name="fromInvoiceNumber" disabled={this.state.chkInvoiceSelection !== "3"} maxLength="30" title="Enter from invoice number" value={this.state.fromInvoiceNumber} onChange={this.handleInputChange} placeholder="Enter invoice #" />
                                    </div>
                                    {this.state.errors["fromInvoiceNumber"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["fromInvoiceNumber"]}</span>}
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label className="form-check-label">To Invoice</label>
                                        <div className="form-group">
                                            <input type="text" className="form-control form-control-sm" name="toInvoiceNumber" disabled={this.state.chkInvoiceSelection !== "3"} maxLength="30" title="Enter to invoice number" value={this.state.toInvoiceNumber} onChange={this.handleInputChange} placeholder="Enter invoice #" />
                                        </div>
                                        {this.state.errors["toInvoiceNumber"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["toInvoiceNumber"]}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="button" className="btn btn-success btn-sm" title="Download" onClick={this.handleSearchAccountPaySummaryReportSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                                </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        )
    }
}