import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
    Editing,
    Selection,
    Scrolling,
    FilterRow
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import { SelectBox } from 'devextreme-react/select-box';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import DropDownBox from 'devextreme-react/drop-down-box';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions';
import { DateFormat, StatusType } from '../../helpers/fixcodes';
import { ReservationHistoryComponent } from '../Shared/ReservationHistoryComponent';
import { DocumentsComponent } from '../Shared/DocumentsComponent';
import { ReservationActualTimeComponent } from '../Shared/ReservationActualTimeComponent';
import { RideTrackingReplay } from '../Shared/RideTrackingReplay';
import Swal from 'sweetalert2';
import { TripStatus } from '../../helpers/fixcodes';

const position = { of: '#historydiv' };
const customStyles = {
    content: {
        width: '40%',
    }
};

const allowToAddDriverWorkingHours = sessionStorage.getItem("session_allowToAddDriverWorkingHours");
const session_accountId = parseInt(sessionStorage.getItem("session_accountId"));
const session_mappedAccountId1 = parseInt(sessionStorage.getItem("session_mappedAccountId1"));
const session_mappedAccountId2 = parseInt(sessionStorage.getItem("session_mappedAccountId2"));
const session_mappedAccountId3 = parseInt(sessionStorage.getItem("session_mappedAccountId3"));

let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
let currencyIcon = sessionStorage.getItem("session_currencyIcon") === "" ? "fas fa-dollar-sign" : sessionStorage.getItem("session_currencyIcon");

export class SearchReservationsComponent extends Component {

    constructor(props) {
        super(props);
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        this.state = {
            isDateDisabled: false,
            isSubmited: false,
            loadPanelVisible: false,
            userDetails: null,
            locationList: [],
            customerList: [],
            dropdownAirline: [],
            vehicleTypeList: [],
            filterVehicleTypeList: [],
            searchReservationGridDataSource: null,
            selectedCustomer: "",
            searchReservationModalIsOpen: false,
            locationId: 0,
            customerId: 0,
            airlineId: 0,
            customerId: 0,
            vehicleTypeId: 0,
            vehicleNumber: '',
            firstName: '',
            lastName: '',
            //fromDateTime: new Date(currentTime.toLocaleDateString() + " 00:00"),
            //toDateTime: new Date(currentTime.toLocaleDateString() + " 23:59"),
            fromDateTime: formatDate(new Date(currentTime), "MM/dd/yyyy") + " 00:00",
            toDateTime: formatDate(new Date(currentTime), "MM/dd/yyyy") + " 23:59",
            reservationNumber: '',
            rideId: '',
            flightNumber: '',
            pickupLocation: '',
            dropoffLocation: '',
            externalReservationNumber: '',
            driverComm: 0,
            filterStatusList: [],
            statusList: [],
        }

        this.now = currentTime;
        this.getLocationList = this.getLocationList.bind(this);
        this.getCustomerList = this.getCustomerList.bind(this);
        this.getAirlines = this.getAirlines.bind(this);
        this.getReservationList = this.getReservationList.bind(this);
        this.getVehicleTypeList = this.getVehicleTypeList.bind(this);

        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.handleCustomerChange = this.handleCustomerChange.bind(this);
        this.handleAirlineChange = this.handleAirlineChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFromValueChanged = this.handleFromValueChanged.bind(this);
        this.handleToValueChanged = this.handleToValueChanged.bind(this);
        this.handleVehicleTypeChange = this.handleVehicleTypeChange.bind(this);
        this.handleSearchReservationSubmit = this.handleSearchReservationSubmit.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);

        this.openEditReservationModel = this.openEditReservationModel.bind(this);
        this.openReservationHistoryModel = this.openReservationHistoryModel.bind(this);
        this.actualTimeTrip = this.actualTimeTrip.bind(this);
        this.dataGridStatusRender = this.dataGridStatusRender.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.syncDataGridStatusSelection = this.syncDataGridStatusSelection.bind(this);
        this.handleShowRideTrackingReplayModal = this.handleShowRideTrackingReplayModal.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
    }

    async componentDidMount() {
        this.getLocationList();
        this.getCustomerList();
        this.getAirlines();
        this.getVehicleTypeList();
        this.getStatusList();
        if (this.props.location.state != undefined) {
            this.setState({
                locationId: this.props.location.state.locationId,
                customerId: this.props.location.state.customerId,
                airlineId: this.props.location.state.airlineId,
                vehicleTypeId: this.props.location.state.vehicleTypeId,
                vehicleNumber: this.props.location.state.vehicleNumber,
                firstName: this.props.location.state.firstName,
                lastName: this.props.location.state.lastName,
                fromDateTime: this.props.location.state.fromDateTime,
                toDateTime: this.props.location.state.toDateTime,
                reservationNumber: this.props.location.state.reservationNumber,
                rideId: this.props.location.state.rideId,
                flightNumber: this.props.location.state.flightNumber,
                pickupLocation: this.props.location.state.pickupLocation,
                dropoffLocation: this.props.location.state.dropoffLocation,
                externalReservationNumber: this.props.location.state.externalReservationNumber,
            });

            let myVar = setTimeout(() => this.getReservationList(), 500)
        }
    }

    async handleRefresh() {
        let myVar = setTimeout(() => this.getReservationList(), 500)
    }

    async getLocationList() {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/account-api/locations', requestParams);
            const data = await response.json();
            this.setState({
                locationList: data.locations != null ? data.locations : []
            });
        } catch (e) {
            console.log(e);
        }
    }

    async getCustomerList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('account-api/customer-dropdown/', requestParams);
        const data = await response.json();
        if (data.isSuccess) {
            this.setState({
                customerList: data.customers,
                customer: data.customers[0]
            });
        }
    }

    async getAirlines() {
        try {
            const data = JSON.parse(sessionStorage.getItem("session_airlines"));
            if (data === null) {
                const requestParams = getRequestParams('GET', '');
                const response = await fetch('air-line-api/' + 0, requestParams);
                const data = await response.json();

                this.setState({
                    dropdownAirline: data.airLine
                });
            }
            else {
                this.setState({
                    dropdownAirline: data.airLine
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    async getVehicleTypeList() {
        try {
            var apiVehicleTypeRequest = new Object();
            const requestParams = getRequestParams('POST', apiVehicleTypeRequest);
            const response = await fetch('/vehicletype-api/vehicletype', requestParams);
            const data = await response.json();
            if (data.isSuccess) {
                this.setState({
                    vehicleTypeList: data.apiVehicleType
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    async getStatusList() {
        try {
            const data = JSON.parse(sessionStorage.getItem("session_tripstatus"));
            if (data === null) {
                const requestParams = getRequestParams('GET', {});
                const response = await fetch('/status-api/' + StatusType.Booking, requestParams);
                const data = await response.json();
                sessionStorage.setItem("session_tripstatus", data != null ? JSON.stringify(data) : []);
                this.setState({
                    statusList: data.statusType
                });
            }
            else {
                this.setState({
                    statusList: data.statusType
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    searchToggle = () => {
        var x = document.getElementById("advanceSearch");
        if (x.style.display === "none") {
            x.style.display = "block";
            this.setState({ isDateDisabled: true });
        } else {
            this.setState({ isDateDisabled: false });
            x.style.display = "none";
        }
    }
    handleFromValueChanged(e) {
        //this.setState({ fromDateTime: e.value });
        this.setState({ fromDateTime: formatDate(e.value, "MM/dd/yyyy hh:mm:ss") });
    }
    handleToValueChanged(e) {
        //this.setState({ toDateTime: e.value });
        this.setState({ toDateTime: formatDate(e.value, "MM/dd/yyyy hh:mm:ss") });
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name == "rideId" || name == "reservationNumber") {
            const re = /^[0-9\b]+$/;
            if (event.target.value === '' || re.test(event.target.value)) {
                this.setState({ [name]: event.target.value })
            }
        } else {
            this.setState({
                [name]: value
            });
        }
    }
    handleLocationChange(selectedOption) {
        this.setState({ locationId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
    }
    handleCustomerChange(selectedOption) {
        this.setState({ customerId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
    }
    handleAirlineChange(selectedOption) {
        this.setState({ airlineId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.airLineId : 0), })
    }
    handleVehicleTypeChange(selectedOption) {
        this.setState({ vehicleTypeId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.rowIndex : 0), })
    }
    handelToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getReservationList.bind(this),
                hint: 'Refresh'
            }
        });
    }

    handleStatusChange(e) {
        this.setState({
            filterStatusList: e.selectedRowKeys.length && e.selectedRowKeys || []
        });
    }
    handleShowRideTrackingReplayModal = async (e, data) => {
        e.preventDefault();        
        this.refs.rideTrackingReplay.showModal(data.rideId, data.fmVehicleId, data.startTime, data.endTime, data.trackingSourceId);
    }
    syncDataGridStatusSelection(e) {
        this.setState({
            filterStatusList: e.value || []
        });
    }
    async getReservationList() {
        this.setState({
            isSubmited: true
            , loadPanelVisible: true
        });

        let tripStatusId = "";
        for (let vt = 0; vt < this.state.filterStatusList.length; vt++) {
            tripStatusId += this.state.filterStatusList[vt] + ",";
            tripStatusId.substring(0, tripStatusId.length - 1);
        }

        var searchReservationRequest = new Object();
        //searchReservationRequest.AccountId
        searchReservationRequest.LocationId = Number(sessionStorage.getItem("session_locationId"));
        searchReservationRequest.ReservationId = this.state.reservationNumber == '' ? 0 : parseInt(this.state.reservationNumber);
        searchReservationRequest.TripId = this.state.rideId == '' ? 0 : parseInt(this.state.rideId);
        searchReservationRequest.CustomerId = parseInt(this.state.customerId);
        searchReservationRequest.VehicleTypeId = parseInt(this.state.vehicleTypeId);
        //searchReservationRequest.PuDateTimeFrom = new Date(this.state.fromDateTime).toLocaleDateString() + " " + new Date(this.state.fromDateTime).toLocaleTimeString(); //new Date(2020, 9, 1);
        //searchReservationRequest.PuDateTimeTo = new Date(this.state.toDateTime).toLocaleDateString() + " " + new Date(this.state.toDateTime).toLocaleTimeString(); //new Date(2020, 9, 31);
        searchReservationRequest.PuDateTimeFrom = formatDate(this.state.fromDateTime, "MM/dd/yyyy hh:mm:ss"); //new Date(2020, 9, 1);
        searchReservationRequest.PuDateTimeTo = formatDate(this.state.toDateTime, "MM/dd/yyyy hh:mm:ss"); //new Date(2020, 9, 31);
        searchReservationRequest.FirstName = this.state.firstName;
        searchReservationRequest.LastName = this.state.lastName;
        searchReservationRequest.VehicleNo = this.state.vehicleNumber;
        searchReservationRequest.PuLocationName = this.state.pickupLocation;
        searchReservationRequest.DoLocationName = this.state.dropoffLocation;
        searchReservationRequest.GttRefrenceNo = this.state.externalReservationNumber;
        searchReservationRequest.FlightNo = this.state.flightNumber;
        searchReservationRequest.AirlineCode = "";
        searchReservationRequest.TripStatusId = tripStatusId;

        if (this.state.airlineId > 0) {
            var airline = this.state.dropdownAirline.find(opt => opt.airLineId == this.state.airlineId);
            searchReservationRequest.AirlineCode = airline.code;
        }

        try {
            const requestParams = getRequestParams('POST', searchReservationRequest);
            const response = await fetch('/reservation-api/search', requestParams);
            const data = await response.json();
            this.setState({
                searchReservationGridDataSource: data.reservations
            });

            this.setState({
                isSubmited: false
                , loadPanelVisible: false
            });

            if (data.reservations.length <= 0) {
                await Swal.fire({
                    icon: 'success',
                    title: '<small>Search Reservation</small>',
                    text: "Reservation not found.",
                    width: '25%',
                    //allowOutsideClick: false
                });
            }            
        } catch (e) {
            this.setState({
                isSubmited: false
                , loadPanelVisible: false
            });
        }
    }

    async handleSearchReservationSubmit(e) {
        e.preventDefault();
        await this.getReservationList();
    }

    handleClearSearch() {
        this.setState({
            isDateDisabled: false,
            isSubmited: false,
            loadPanelVisible: false,
            selectedCustomer: "",
            //searchReservationModalIsOpen: false,
            locationId: 0,
            customerId: 0,
            airlineId: 0,
            customerId: 0,
            vehicleTypeId: 0,
            vehicleNumber: '',
            firstName: '',
            lastName: '',
            fromDateTime: formatDate(new Date(currentTime), "MM/dd/yyyy") + " 00:00",
            toDateTime: formatDate(new Date(currentTime), "MM/dd/yyyy") + " 23:59",
            reservationNumber: '',
            rideId: '',
            flightNumber: '',
            pickupLocation: '',
            dropoffLocation: '',
            externalReservationNumber: '',
            driverComm: 0,
            filterStatusList: [],
        });
    }
    async getUpdatedReservationList() {
        var searchReservationRequest = new Object();
        // searchReservationRequest

        if (this.state.airlineId > 0) {
            var airline = this.state.dropdownAirline.find(opt => opt.airLineId == this.state.airlineId);
            searchReservationRequest.AirlineCode = airline.code;
        }

        this.setState({
            isSubmited: true
            , loadPanelVisible: true
        });

        try {
            const requestParams = getRequestParams('POST', searchReservationRequest);
            const response = await fetch('/reservation-api/search', requestParams);
            const data = await response.json();
            this.setState({
                searchReservationGridDataSource: data.reservations
            });

            if (data.reservations.length <= 0) {
                await Swal.fire({
                    icon: 'success',
                    title: '<small>Search Reservation</small>',
                    text: "Reservation not found.",
                    width: '25%',
                    allowOutsideClick: false
                });
            }

            this.setState({
                isSubmited: false
                , loadPanelVisible: false
            });
        } catch (e) {
            this.setState({
                isSubmited: false
                , loadPanelVisible: false
            });
        }
    }

    openEditReservationModel(e, data) {
        e.preventDefault();
        this.props.history.push({
            pathname: '/crudride',
            //state: { detail: data }
            state: { rideId: data.rideId, sourceUrl: "/reservation/list", sourceState: this.state }
        })
    }

    openReservationHistoryModel(e, data) {
        e.preventDefault();
        this.refs.reservationHistoryModal.showModal("Reservation", data.rideId);
    }

    openAttchmentModel(e, data) {
        e.preventDefault();
        var rideArray = [];
        rideArray.push(data.rideId);
        this.refs.documentsComponent.showModal('Ride Id', rideArray, rideArray[0]);
    }

    actualTimeTrip(e, data) {        
        e.preventDefault();
        this.refs.actualTimeModal.showModal("Reservation", data.rideId, data.airlineCode, this.handleRefresh());
    }

    async onRowUpdated(e) {
        try {
            if (e.data.tripStatusId == TripStatus.CANCELLED) {
                await Swal.fire({
                    allowOutsideClick: false,
                    showCloseButton: true,
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You cannot modify cancelled ride',
                });

            }
            else {
                var newObject = new Object();
                newObject.DriverFees = e.data.driverFees;
                newObject.TotalFare = e.data.totalFare;
                newObject.RideAmount = e.data.rideAmnt;
                newObject.CompanyFees = e.data.compComm;
                newObject.TipAmount = e.data.tipAmnt;
                const requestParams = getRequestParams('POST', newObject);
                const response = await fetch('/reservation-api/ModifyFare/' + e.data.rideId + "/" + e.data.reservationId, requestParams);
                const data = await response.json();
                if (data.isSuccess) {
                    this.getReservationList.bind(this);
                }
                else {
                    await Swal.fire({
                        title: "<small>Modify Fare</small>",
                        text: "Something went wrong.",
                        icon: "success",
                        width: '30%',
                        dangerMode: false,
                        allowOutsideClick: false
                    });
                }
            }
        }
        catch (e) {

        }

    }
    //async handleRowPrepared(e) {
    //    try {
    //        if (e.rowType == 'data') {

    //            e.data.tripStatusId !== 111 ? e.isEditing = true : e.isEditing = false;
    //                //e.rowElement.classList.add("bgurgent");
    //            }

    //    }
    //    catch (e) {

    //    }

    //}
    async handleCellPrepared(e) {
        try {
            if (e.rowType === 'data') {
                if (e.column.dataField === 'driverFees') {
                    e.column.allowEditing = true;
                    if (e.data.tripStatusId === TripStatus.CANCELLED) {
                        e.column.allowEditing = false;
                    }
                }
            }
        } catch (e) {

        }
    }
    async handleRowPrepared(e) {
        try {
            if (e.rowType === 'data') {
                if (e.columns[12].dataField === 'driverFees') //e.column.dataField == 'driverFees'
                {
                    e.column.enabled = true;
                    if (e.data.tripStatusId === TripStatus.CANCELLED) {
                        e.column.enabled = false;
                    }
                }
            }
        } catch (e) {

        }
    }
    async showingEditor(e) {
        try {
            e.cancel = true;
        } catch (e) {

        }
    }
    render() {
        const filterSearchFromDate = time => {
            const currentDate = currentTime;
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }

        const filterSearchToDate = time => {
            const currentDate = currentTime;
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }

        const renderGridCell = (data) => {
            let actualLink = (Number(data.data.tripStatusId) == 116) ? <a href="#" className="fas fa-clock icon text-info" title="Update Actual Time" onClick={(e) => { this.actualTimeTrip(e, data.data) }}></a> : '';
            let replayLink = (Number(data.data.tripStatusId) == 116) ? <a href="#" className="fas fa-route icon text-info" title="Replay" onClick={(e) => { this.handleShowRideTrackingReplayModal(e, data.data) }}></a> : '';

            return <div>
                <a href="#" className="fas fa-pen icon text-info" title="Edit" onClick={(e) => { this.openEditReservationModel(e, data.data) }}></a>&nbsp;&nbsp;
                <a href="#" className="fas fa-history icon text-info" title="History" onClick={(e) => { this.openReservationHistoryModel(e, data.data) }}></a>&nbsp;&nbsp;
                <a href="#" className="fas fa-paperclip icon text-info" title="Upload/View Attachments" onClick={(e) => { this.openAttchmentModel(e, data.data) }}></a>&nbsp;&nbsp;
                {actualLink === '' || actualLink}&nbsp;&nbsp;
                {replayLink === '' || replayLink}&nbsp;&nbsp;
            </div>;
        }
        const renderEditGridCell = (data) => {
            //if (data.data.tripStatusId !== TripStatus.CANCELLED) {
            //    //debugger;
            //    return <div style={{ display : data.column.allowEditing = true}}>{data.data.driverFees}</div>
            //}
            //else {
            //    //debugger;
            //    return <div style={{ display: data.column.allowEditing = false }}>{data.data.driverFees}</div>
            //}

            data.column.allowEditing = true;
            if (data.data.tripStatusId === TripStatus.CANCELLED) {
                //debugger;
                data.column.allowEditing = false;
            }
            return <div>{data.data.driverFees}</div>;
        }

        const renderGridCurrencyCell = (data) => {
            return <div><i className={currencyIcon}></i>&nbsp;{parseFloat(data.value != undefined ? data.value : 0.00).toFixed(2)}</div>;
        }

        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-4 col-sm-12">
                                <h1 className="m-0 text-dark">Search Reservation</h1>
                            </div>
                            <div className="col-lg-6 col-md-8 col-sm-12">
                                <ol className="breadcrumb float-sm-right">
                                    <li>
                                        <a className="nav-link btn btn-outline-secondary btn-sm" data-widget="control-sidebar" data-slide="true" href="#" role="button" title="Advanced Search"><i className="fas fa-th-large"></i></a>
                                    </li>
                                    <NavItem><NavLink tag={Link} to="/reservations" className="btn btn-block btn-outline-secondary btn-sm" title="New Reservation"><i className="fas fa-plus"></i> New Reservation</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/quotation/list" className="btn btn-block btn-outline-secondary btn-sm" title="Quotation"><i className="fas fa-pen"></i> Quotation</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/uploadschedule" className="btn btn-block btn-outline-secondary btn-sm" title="Upload Schedule"><i className="fas fa-clipboard-list"></i> Upload Schedule</NavLink></NavItem>
                                </ol>
                            </div>

                        </div>
                    </div>
                </div>
                <form method="post" onSubmit={this.handleSearchReservationSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="content">
                        <div className="card card-grid">
                            <div className="card-body">
                                <DataGrid
                                    dataSource={this.state.searchReservationGridDataSource}
                                    keyExpr="rideId"
                                    selection={{ mode: 'single' }}
                                    className="dx-datagrid-height"
                                    columnsAutoWidth={false}
                                    showColumnLines={false}
                                    showRowLines={true}
                                    showBorders={true}
                                    rowAlternationEnabled={true}
                                    allowColumnResizing={true}
                                    columnResizingMode='widget'
                                    onRowUpdated={this.onRowUpdated}
                                    //onRowPrepared={this.handleRowPrepared}
                                    //onCellPrepared={this.handleCellPrepared}
                                    //onEditingStart={this.onEditingStart}
                                    //onEditCanceling={this.showingEditor}
                                    onToolbarPreparing={this.handelToolbarPreparing}>
                                    <Editing
                                        mode="cell"
                                        allowUpdating={true}

                                    />
                                    <Column dataField="rideId" width={90} caption="" allowExporting={false} allowFiltering={false} allowSorting={false} allowEditing={false} cellRender={renderGridCell} />
                                    <Column dataField="accountCode" caption="Customer" width={90} allowFiltering={false} allowSorting={true} allowEditing={false} />
                                    <Column dataField="rideId" caption="Ride Id" width={150} allowFiltering={true} allowSorting={true} allowEditing={false} />
                                    <Column dataField="fullName" caption="Pax Name" width={150} allowFiltering={true} allowSorting={true} allowEditing={false} />
                                    <Column dataField="flightInfo" caption="Flight Number" width={200} allowFiltering={true} allowSorting={true} allowEditing={false} />
                                    <Column dataField="vehicleType" caption="Vehicle Type" width={150} allowFiltering={true} allowSorting={true} allowEditing={false} />
                                    <Column dataField="driverName" caption="Driver Name" width={200} allowFiltering={true} allowSorting={true} allowEditing={false} />
                                    <Column dataField="driverWorkingHours" width={100} caption="Driver working Hrs" width={120} allowFiltering={false} allowSorting={false} allowEditing={true} visible={allowToAddDriverWorkingHours === "true" ? true : false} />
                                    <Column dataField="puDateTime" caption="PU Date Time" width={200} allowFiltering={true} allowSorting={true} allowEditing={false} format={DateFormat.DateTime24Format} dataType="datetime" />
                                    <Column dataField="assignedTime" caption="Assigned Time" width={100} allowFiltering={true} allowSorting={true} allowEditing={false} />
                                    <Column dataField="acceptedTime" caption="Accepted Time" width={100} allowFiltering={true} allowSorting={true} allowEditing={false} visible={false} />
                                    <Column dataField="onLocationTime" caption="On Location" width={80} allowFiltering={true} allowSorting={true} allowEditing={false} />
                                    <Column dataField="onLoadTime" caption="Boarded" width={80} allowFiltering={true} allowSorting={true} allowEditing={false} />
                                    <Column dataField="onUnloadTime" caption="Unload Time" width={100} allowFiltering={true} allowSorting={true} allowEditing={false} visible={false} />
                                    <Column dataField="onCompletedTime" caption="Drop" width={125} allowFiltering={true} allowSorting={true} allowEditing={false} />
                                    <Column dataField="puLocationAddress" caption="Pick-up" width={300} allowFiltering={true} allowSorting={true} allowEditing={false} />
                                    <Column dataField="doLocationAddress" caption="Drop Off" width={300} allowFiltering={true} allowSorting={true} allowEditing={false} />
                                    <Column dataField="affBookingNo" caption="External Rez#" width={80} allowFiltering={false} allowSorting={true} allowEditing={false}
                                        visible={session_accountId == session_mappedAccountId3 ? true : false} />
                                    <Column dataField="adultNo" caption="Pax" width={70} allowFiltering={true} allowSorting={false} allowEditing={false} />
                                    <Column dataField="vehicleNo" caption="Vehicle Number" width={200} allowFiltering={true} allowSorting={true} allowEditing={false} />
                                    <Column dataField="clubbedStatus" caption="Clubbing Status" width={150} allowFiltering={true} allowSorting={true}
                                        visible={(session_mappedAccountId1 !== session_accountId && session_mappedAccountId2 !== session_accountId) ? false : true} />
                                    <Column dataField="driverFees" caption="Driver Fee" width={100} allowFiltering={false} allowSorting={true}
                                        visible={session_accountId == session_mappedAccountId3 ? true : false} cellRender={renderGridCurrencyCell} />
                                    <Column dataField="totalKmsTravelled" caption="Total Kms Travelled" width={150} allowFiltering={true} allowSorting={true} allowEditing={false}
                                        visible={(session_mappedAccountId1 !== session_accountId && session_mappedAccountId2 !== session_accountId) ? false : true} />
                                    <Column dataField="totalFare" caption="Total Fare" width={100} allowFiltering={true} allowSorting={true}
                                        visible={
                                            ((session_accountId !== session_mappedAccountId3) ||
                                             (session_mappedAccountId1 !== session_accountId && session_mappedAccountId2 !== session_accountId)) ? true : false}
                                        cellRender={renderGridCurrencyCell} />
                                    <Column dataField="billingStatus" caption="Billing Status" width={150} allowFiltering={true} allowSorting={true} allowEditing={false}
                                        visible={(session_mappedAccountId1 !== session_accountId && session_mappedAccountId2 !== session_accountId) ? false : true} />
                                    <Column dataField="tripStatus" caption="Status" width={150} allowFiltering={true} allowSorting={true} allowEditing={false} />
                                    <SortByGroupSummaryInfo summaryItem="count" />
                                    <GroupPanel visible={true} />
                                    <HeaderFilter visible={true} />
                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                    <Grouping autoExpandAll={true} />
                                    <Paging defaultPageSize={20} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true} />
                                    <Export enabled={true} allowExportSelectedData={false} />
                                    <ColumnChooser enabled={true} />
                                    <ColumnFixing enabled={true} />
                                </DataGrid>
                            </div>
                        </div>
                    </div>

                    <LoadPanel
                        shadingColor="rgba(0,0,0,0.4)"
                        position={position}
                        visible={this.state.loadPanelVisible}
                        showIndicator={true}
                        shading={false}
                        showPane={true}
                        closeOnOutsideClick={false}
                    />
                </form>
                <aside className="control-sidebar control-sidebar-light p-3">
                    <h5>Search Reservations</h5>
                    <div className="row">
                        <div className="rez-header">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <div className="form-group">
                                            <label>From Pick-up Date</label>
                                            <DateBox type="datetime"
                                                className="form-control form-control-sm"
                                                name="fromDateTime"
                                                placeholder="MM/dd/yyyy HH:mm"
                                                displayFormat="MM/dd/yyyy HH:mm"
                                                showAnalogClock={false}
                                                useMaskBehavior={true}
                                                defaultValue={this.state.fromDateTime}
                                                value={this.state.fromDateTime}
                                                onValueChanged={this.handleFromValueChanged}
                                                width="100%"
                                                disabled={false}
                                                acceptCustomValue={false}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <div className="form-group">
                                            <label>To Pick-up Date</label>
                                            <DateBox type="datetime"
                                                className="form-control form-control-sm"
                                                name="toDateTime"
                                                placeholder="MM/dd/yyyy HH:mm"
                                                displayFormat="MM/dd/yyyy HH:mm"
                                                showAnalogClock={false}
                                                useMaskBehavior={true}
                                                defaultValue={this.state.toDateTime}
                                                value={this.state.toDateTime}
                                                onValueChanged={this.handleToValueChanged}
                                                width="100%"
                                                disabled={false}
                                                acceptCustomValue={false}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label>Customer</label>
                                            <SelectBox
                                                dataSource={this.state.customerList.length > 0 ? this.state.customerList : []}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select customer"
                                                name="customerId"
                                                defaultValue={0}
                                                value={this.state.customerId}
                                                displayExpr='fullName'
                                                valueExpr='id'
                                                onSelectionChanged={this.handleCustomerChange}
                                                showClearButton={true}
                                                disabled={false}
                                                searchEnabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <div className="form-group">
                                            <label>Vehicle Type</label>
                                            <SelectBox
                                                dataSource={this.state.vehicleTypeList.length > 0 ? this.state.vehicleTypeList : []}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select vehicle Type"
                                                name="vehicleTypeId"
                                                defaultValue={0}
                                                value={this.state.vehicleTypeId}
                                                displayExpr='apiVehicleTypeDescription'
                                                valueExpr='rowIndex'
                                                onSelectionChanged={this.handleVehicleTypeChange}
                                                showClearButton={true}
                                                disabled={false}
                                                searchEnabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <div className="form-group">
                                            <label>Vehicle Number</label>
                                            <input type="text" className="form-control form-control-sm" name="vehicleNumber" maxLength="50" title="Vehicle Number" value={this.state.vehicleNumber} onChange={this.handleInputChange} placeholder="Enter vehicle number" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <div className="form-group">
                                            <label>Reservation Number</label>
                                            <input type="text" className="form-control form-control-sm" name="reservationNumber" maxLength="15" title="Reservation Number" value={this.state.reservationNumber} onChange={this.handleInputChange} placeholder="Enter reservation number" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <div className="form-group">
                                            <label>Ride Id</label>
                                            <input type="text" className="form-control form-control-sm" name="rideId" maxLength="30" title="Ride Id" value={this.state.rideId} onChange={this.handleInputChange} placeholder="Enter ride id" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <div className="form-group">
                                            <label>First Name</label>
                                            <input type="text" className="form-control form-control-sm" name="firstName" maxLength="50" title="First Name" value={this.state.firstName} onChange={this.handleInputChange} placeholder="Enter first name" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <div className="form-group">
                                            <label>Last Name</label>
                                            <input type="text" className="form-control form-control-sm" name="lastName" maxLength="50" title="Last Name" value={this.state.lastName} onChange={this.handleInputChange} placeholder="Enter last name" />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label>Status</label>
                                            <DropDownBox
                                                value={this.state.filterStatusList}
                                                valueExpr="id"
                                                deferRendering={false}
                                                displayExpr="name"
                                                placeholder="Select status..."
                                                showClearButton={true}
                                                dataSource={this.state.statusList}
                                                onValueChanged={this.syncDataGridStatusSelection}
                                                contentRender={this.dataGridStatusRender}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label>Airline</label>
                                            <SelectBox
                                                dataSource={this.state.dropdownAirline.length > 0 ? this.state.dropdownAirline : []}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select airline"
                                                name="airlineId"
                                                defaultValue={0}
                                                value={this.state.airlineId}
                                                displayExpr='displayName'
                                                valueExpr='airLineId'
                                                onSelectionChanged={this.handleAirlineChange}
                                                showClearButton={true}
                                                disabled={false}
                                                searchEnabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label>Flight Number</label>
                                            <input type="text" className="form-control form-control-sm" name="flightNumber" maxLength="50" title="Flight Number" value={this.state.flightNumber} onChange={this.handleInputChange} placeholder="Enter flight number" />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label>Pick-up Location</label>
                                            <input type="text" className="form-control form-control-sm" name="pickupLocation" maxLength="50" title="Pickup Location" value={this.state.pickupLocation} onChange={this.handleInputChange} placeholder="Enter pick-up location" />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label>Drop Off Location</label>
                                            <input type="text" className="form-control form-control-sm" name="dropoffLocation" maxLength="50" title="Dropoff Location" value={this.state.dropoffLocation} onChange={this.handleInputChange} placeholder="Enter drop off location" />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label>External Rez Number</label>
                                            <input type="text" className="form-control form-control-sm" name="externalReservationNumber" maxLength="50" title="External Rez Number" value={this.state.externalReservationNumber} onChange={this.handleInputChange} placeholder="Enter external rez number" />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-right">
                                        <div className="form-group">
                                            <label className="btn-block">&nbsp;</label>
                                            <button type="submit" className="btn btn-success btn-sm" onClick={this.handleSearchReservationSubmit} data-widget="control-sidebar" data-slide="true">
                                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                                {!this.state.isSubmited && <span title="Search"><i className="fas fa-search"></i> Search</span>}
                                            </button>&nbsp; &nbsp;
                                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleClearSearch} data-widget="control-sidebar" data-slide="true" title="Clear"><i className="fas fa-eraser"></i> Clear</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </aside>
                <ReservationHistoryComponent ref="reservationHistoryModal" />
                <DocumentsComponent ref="documentsComponent" />
                <ReservationActualTimeComponent ref="actualTimeModal" handleRefresh={this.handleRefresh} />
                <RideTrackingReplay ref="rideTrackingReplay" />
            </div>
        );
    }

     dataGridStatusRender() {
        return (
            <DataGrid
                dataSource={this.state.statusList}
                id="grid-container"
                keyExpr="id"
                height={345}
                hoverStateEnabled={true}
                selectedRowKeys={this.state.filterStatusList}
                onSelectionChanged={this.handleStatusChange}
                ref={ref => this.dataGrid = ref}>
                <Column dataField="name" caption="Location" />
                <Selection mode="multiple" />
                <Scrolling mode="virtual" />
                {/*<Paging enabled={true} pageSize={5} />*/}
                <FilterRow visible={true} />
            </DataGrid>
        );
    }
}