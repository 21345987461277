import DateBox from 'devextreme-react/date-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import RadioGroup from 'devextreme-react/radio-group';
import { SelectBox } from 'devextreme-react/select-box';
import React from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { formatDate, getRequestParams } from '../../helpers/globalfunctions';
import { SendEmailComponent } from '../Shared/SendEmailComponent';
const rideTypeRadio = [
    { id: 0, name: 'Charter Only' },
    { id: 1, name: 'All' },
];
const reportTypeRadio = [
    { id: 0, name: 'Summary' },
    { id: 1, name: 'Details' },
];
const approvedTypeRadio = [
    { id: 0, name: 'Rides closed but not approved' },
    { id: 1, name: 'Rides approved but not billed' },
];

export class RidesUnApprovedReportComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            rideReconsilation: [],
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),
            rideTypeList: rideTypeRadio,
            reportTypeList: reportTypeRadio,
            approvedTypeList: approvedTypeRadio,
            rideType: rideTypeRadio[0],
            reportType: reportTypeRadio[0],
            approvedType: approvedTypeRadio[0],
            customer: [],
            customerCode: "",
            customerName: "",
            vehicleTypeList: [],
            vehicleTypeId: 0,
            vehicleType: "",
            isCharterOnly: true,
            isRideApproved: true,
            errors: {
                customerCode: "",
                vehicleTypeId: 0,
            }
        }

        this.getCustomerList = this.getCustomerList.bind(this);
        this.getVehicleTypeList = this.getVehicleTypeList.bind(this);

        this.handleFromDateChanged = this.handleFromDateChanged.bind(this);
        this.handleToDateChanged = this.handleToDateChanged.bind(this);
        this.handleCustomerChange = this.handleCustomerChange.bind(this);
        this.handleVehicleTypeChange = this.handleVehicleTypeChange.bind(this);
        this.downloadRidesApprovedAsPdf = this.downloadRidesApprovedAsPdf.bind(this);
        this.handleRidesApprovedReportSubmit = this.handleRidesApprovedReportSubmit.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);

        this.handleRideTypeChange = this.handleRideTypeChange.bind(this);
        this.handleReportTypeChange = this.handleReportTypeChange.bind(this);
        this.handleApprovedTypeChange = this.handleApprovedTypeChange.bind(this);
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true,
        });
        await this.getCustomerList();
        await this.getVehicleTypeList();
        this.setState({
            loadPanelVisible: false,
        });
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });

        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleRideTypeChange(e) {
        this.setState({
            rideType: e.value,
            isCharterOnly: parseInt(e.value) == 0 ? true : false,
        });
    }
    handleReportTypeChange(e) {
        this.setState({
            reportType: e.value
        });
    }
    handleApprovedTypeChange(e) {
        this.setState({
            approvedType: e.value,
            isRideApproved: parseInt(e.value) == 0 ? true : false,
        });
    }
    handleOrderByTypeChange(e) {
        this.setState({
            orderByType: e.value
        });
    }
    handleCustomerChange(e) {
        if (e.selectedItem != null) {
            this.setState({
                customerName: e.selectedItem.name,
                customerCode: e.selectedItem.code,
            });
        }
        else {
            this.setState({ customerName: "", customerCode: "" });
        }
    }
    handleVehicleTypeChange(e) {
        if (e != null) {
            this.setState({
                vehicleTypeId: e.selectedItem.vehicleTypeId,
                vehicleType: e.selectedItem.vehicleTypeCode,
            });
        }
        else {
            this.setState({ vehicleTypeId: 0, vehicleType: "" });
        }
    }
    handleRideDateChanged(event) {
        let rideDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ rideDate: rideDateValue })
    }
    handleFromDateChanged(event) {
        let fromDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ startDate: fromDateValue })
    }
    handleToDateChanged(event) {
        let toDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ endDate: toDateValue })
    }
    handleRidesApprovedReportSubmit(e) {
        e.preventDefault();
        this.downloadRidesApprovedAsPdf();
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmited: false,
                isEmailSubmitted: false,
                isOpenModal: false,
                loadPanelVisible: false,
                startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
                endDate: new Date(),
                rideTypeList: rideTypeRadio,
                reportTypeList: reportTypeRadio,
                approvedTypeList: approvedTypeRadio,
                rideType: rideTypeRadio[0],
                reportType: reportTypeRadio[0],
                approvedType: approvedTypeRadio[0],
                customerCode: "",
                customerName: "",
                vehicleTypeId: 0,
                vehicleType: "",
                isCharterOnly: true,
                isRideApproved: true,
                errors: {
                    customerCode: "",
                    vehicleTypeId: 0,
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleClearSearch() {
        this.setState({
            isSubmited: false,
            isEmailSubmitted: false,
            isOpenModal: true,
            loadPanelVisible: false,
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),
            rideTypeList: rideTypeRadio,
            reportTypeList: reportTypeRadio,
            approvedTypeList: approvedTypeRadio,
            rideType: rideTypeRadio[0],
            reportType: reportTypeRadio[0],
            approvedType: approvedTypeRadio[0],
            customerCode: "",
            customerName: "",
            vehicleTypeId: 0,
            vehicleType: "",
            isCharterOnly: true,
            isRideApproved: true,
            errors: {
                customerCode: "",
                vehicleTypeId: 0,
            }
        });
    }
    async getCustomerList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('account-api/customer-dropdown/', requestParams);
        const data = await response.json();
        this.setState({
            customer: data.customers,
        });
    }
    async getVehicleTypeList() {
        try {
            var apiVehicleTypeRequest = new Object();
            var apiVehicleTypeRequest = new Object();
            const requestParams = getRequestParams('POST', apiVehicleTypeRequest);
            const response = await fetch('/vehicletype-api/vehicletype', requestParams);
            const data = await response.json();
            if (data.isSuccess) {
                this.setState({
                    vehicleTypeList: data.apiVehicleType
                });
            }
        } catch (e) {
            console.log(e);
        }
    }
    async downloadRidesApprovedAsPdf() {
        try {
            this.setState({
                loadPanelVisible: true,
                isSubmited: true,
            });

            let criteria1 = "", header = "", selectionCreteria = "";

            if (selectionCreteria != "") {
                selectionCreteria += " And ";
            }
            else {
                selectionCreteria += "";
            }
            selectionCreteria += "{PROC_RPT_APPROVED_RIDES;1.IS_RIDE_APPROVED} =" + this.state.isRideApproved;
            if (selectionCreteria != "") {
                selectionCreteria += " And ";
            }
            else {
                selectionCreteria += "";
            }
            selectionCreteria += "{@InvoiceNo} <= 0 ";

            if (this.state.customerCode != "") {
                if (selectionCreteria != "") {
                    selectionCreteria += " And ";
                }
                else {
                    selectionCreteria += "";
                }
                selectionCreteria += "{PROC_RPT_APPROVED_RIDES;1.ACCOUNT_CODE} ='" + this.state.customerCode + "'";
            }

            if (this.state.vehicleType != "") {
                if (selectionCreteria != "") {
                    selectionCreteria += " And ";
                }
                else {
                    selectionCreteria += "";
                }
                selectionCreteria += " {PROC_RPT_APPROVED_RIDES;1.VEHICLE_TYPE} ='" + this.state.vehicleType + "'";
            }

            let customerCodeSelection = "", vehicleTypeSelection = "";
            if (this.state.customerCode != "") {
                customerCodeSelection = " " + this.state.customerCode;
            }
            if (this.state.vehicleType != "") {
                vehicleTypeSelection = " " + this.state.vehicleType;
            }

            criteria1 = "( For Trip Period " + formatDate(this.state.startDate, "MM/dd/yyyy") + " to " + formatDate(this.state.endDate, "MM/dd/yyyy") + customerCodeSelection + vehicleTypeSelection + " )";
            header = this.state.isRideApproved ? "Ride Closeout-Open Reservations" : "Ride Closeout-Close Reservations";

            var request = new Object();
            request.LocationId = Number(sessionStorage.getItem("session_locationId"));
            request.fileFormat = 'pdf';
            request.MethodName = this.state.reportType == 0 ? "GetRidesApprovedSummary" : "GetRidesApprovedDetails";
            request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy");
            request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy");
            request.IsCharterOnly = this.state.isCharterOnly;
            request.Criteria1 = criteria1;
            request.Header = header;
            request.SelectionCrieteria = selectionCreteria;

            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            this.setState({
                loadPanelVisible: false,
                isSubmited: false,
            });

            let a = document.createElement('a');
            a.href = dataResponse.reportBase64String;
            a.download = 'RidesUnApproved.pdf';
            a.click();

            this.setState({
                loadPanelVisible: false,
                isSubmited: false,
            });
        } catch (e) {
            this.setState({
                loadPanelVisible: false,
            });
            console.log(e)
        }
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            this.setState({ isEmailSubmitted: true });
            let criteria1 = "", header = "", selectionCreteria = "";

            if (selectionCreteria != "") {
                selectionCreteria += " And ";
            }
            else {
                selectionCreteria += "";
            }
            selectionCreteria += "{PROC_RPT_APPROVED_RIDES;1.IS_RIDE_APPROVED} =" + this.state.isRideApproved;
            if (selectionCreteria != "") {
                selectionCreteria += " And ";
            }
            else {
                selectionCreteria += "";
            }
            selectionCreteria += "{@InvoiceNo} <= 0 ";

            if (this.state.customerCode != "") {
                if (selectionCreteria != "") {
                    selectionCreteria += " And ";
                }
                else {
                    selectionCreteria += "";
                }
                selectionCreteria += "{PROC_RPT_APPROVED_RIDES;1.ACCOUNT_CODE} ='" + this.state.customerCode + "'";
            }

            if (this.state.vehicleType != "") {
                if (selectionCreteria != "") {
                    selectionCreteria += " And ";
                }
                else {
                    selectionCreteria += "";
                }
                selectionCreteria += " {PROC_RPT_APPROVED_RIDES;1.VEHICLE_TYPE} ='" + this.state.vehicleType + "'";
            }

            let customerCodeSelection = "", vehicleTypeSelection = "";
            if (this.state.customerCode != "") {
                customerCodeSelection = " " + this.state.customerCode;
            }
            if (this.state.vehicleType != "") {
                vehicleTypeSelection = " " + this.state.vehicleType;
            }

            criteria1 = "( For Trip Period " + formatDate(this.state.startDate, "MM/dd/yyyy") + " to " + formatDate(this.state.endDate, "MM/dd/yyyy") + customerCodeSelection + vehicleTypeSelection + " )";
            header = this.state.isRideApproved ? "Ride Closeout-Open Reservations" : "Ride Closeout-Close Reservations";

            var request = new Object();
            request.LocationId = Number(sessionStorage.getItem("session_locationId"));
            request.fileFormat = 'pdf';
            request.MethodName = this.state.reportType == 0 ? "GetRidesApprovedSummary" : "GetRidesApprovedDetails";
            request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy");
            request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy");
            request.IsCharterOnly = this.state.isCharterOnly;
            request.Criteria1 = criteria1;
            request.Header = header;
            request.SelectionCrieteria = selectionCreteria;

            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            this.setState({ isEmailSubmitted: false });
            let fileData = dataResponse.reportBase64String;
            if (fileData !== null && fileData !== '') {
                let emailSubject = 'Rides Unapproved';
                let attachments = [];
                let file = new Object();
                file.fileData = fileData;
                file.fileName = 'RidesUnApproved.pdf'
                file.fileExtension = '.pdf';
                attachments.push(file);
                this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
            }
            else {
                Swal.fire({
                    allowOutsideClick: false,
                    showCloseButton: true,
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong! Unable to process the email request.',
                })
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '60%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleRidesApprovedReportSubmit}
                        style={(this.state.isSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Rides Unapproved'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label className="form-check-label">Ride Type</label>
                                        <RadioGroup id="radio-group-rideType" dataSource={this.state.rideTypeList}
                                            displayExpr="name" value={this.state.rideType}
                                            onValueChanged={this.handleRideTypeChange} layout="horizontal" />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label className="form-check-label">Report Type</label>
                                        <RadioGroup id="radio-group-reportType" dataSource={this.state.reportTypeList} displayExpr="name" value={this.state.reportType} onValueChanged={this.handleReportTypeChange} layout="horizontal" />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label className="form-check-label">Rides Closed/Approved</label>
                                        <RadioGroup id="radio-group-approvedCloseout" dataSource={this.state.approvedTypeList} displayExpr="name" value={this.state.approvedType} onValueChanged={this.handleApprovedTypeChange} layout="horizontal" />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label className="form-check-label">Customer</label>
                                        <SelectBox
                                            dataSource={this.state.customer.length > 0 ? this.state.customer : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.customerCode}
                                            displayExpr='name'
                                            valueExpr='code'
                                            onSelectionChanged={this.handleCustomerChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerCode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerCode"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label className="form-check-label">Vehicle Type</label>
                                        <SelectBox
                                            dataSource={this.state.vehicleTypeList.length > 0 ? this.state.vehicleTypeList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select vehicle type"
                                            name="vehicleTypeId"
                                            defaultValue={0}
                                            value={this.state.vehicleTypeId}
                                            displayExpr='apiVehicleTypeDescription'
                                            valueExpr='vehicleTypeId'
                                            onSelectionChanged={this.handleVehicleTypeChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["vehicleTypeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["vehicleTypeId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label className="form-check-label">From Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.startDate}
                                            value={this.state.startDate}
                                            onValueChanged={this.handleFromDateChanged}
                                            disabled={this.state.isRideDateRangeDisable}
                                            width="100%"
                                            acceptCustomValue={false} />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label className="form-check-label">To Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.endDate}
                                            value={this.state.endDate}
                                            onValueChanged={this.handleToDateChanged}
                                            disabled={this.state.isRideDateRangeDisable}
                                            width="100%"
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="submit" className="btn btn-success btn-sm" title="Download" onClick={this.handleRidesApprovedReportSubmit} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                                </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        )
    }
}