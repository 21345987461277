import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import { LoadPanel } from 'devextreme-react/load-panel';
import {
    Chart,
    Series,
    ArgumentAxis,
    ValueAxis,
    CommonSeriesSettings,
    Export,
    Legend,
    Margin,
    Tooltip,
    Grid,
} from 'devextreme-react/chart';

const GTPerformanceSources = [
    { value: 'gtPerformance', name: 'dayName' },
];
const UpcommingRidesSources = [
    { value: 'hourRides', name: 'hourName' },
];

const durations = [
    //{ id: 1, name: "Bi-Monthly" },
    { id: 2, name: "Monthly" },
    { id: 3, name: "Yearly" },
    { id: 4, name: "Custom Range" },
]

let currencyIcon = sessionStorage.getItem("session_currencyIcon") === "" ? "fas fa-dollar-sign" : sessionStorage.getItem("session_currencyIcon");

export class DispatchDashboardComponent extends Component {
    static displayName = DispatchDashboardComponent.name;
    constructor(props) {
        super(props);
        this.state = {
            tripsData: null,
            upcomingRidesData: [],
            lastSevenDayTripData: [],
            scheduleVsActualData: [],
            hourlyRidesData: [],
            type: 'line',
            loadPanelVisible: false,
        }
    }

    async bindDispatchDashboard(dashboardRequest) {
        await this.getDashboardData(dashboardRequest);        
    }
    
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    customizeTooltip1(arg) {
        return {
            text: `${arg.seriesName}: ${arg.valueText}`,
        };
    }

    customizeTooltip2(arg) {
        return {
            text: `${arg.valueText} Ride(s)` ,
        };
    }
    
    async getDashboardData(dashboardRequest) {
        try {
            this.setState({ loadPanelVisible: true });
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('dashboard-api/' + parseInt(sessionStorage.getItem("session_moduleId")), requestParams);
            const data = await response.json();

            if (data !== null) {
                this.setState({
                    tripsData: data.dbTripsModel,
                    upcomingRidesData: data.dbUpcomingRides,
                    lastSevenDayTripData: data.dBLastSevenDayTrip
                });
            }

            const requestGraphParams = getRequestParams('GET', '');
            const responseGraph = await fetch('dashboard-api/dashboardGraph/10', requestGraphParams);
            const dataGraph = await responseGraph.json();
            
            if (dataGraph !== null) {
                this.setState({
                    scheduleVsActualData: dataGraph.rptScheduleVsActual,
                    hourlyRidesData: dataGraph.rptHourlyRides,
                });
            }
            this.setState({ loadPanelVisible: false });
        } catch (e) {
            debugger;
        }
    }

    render() {
        return (
            <div className="content-wrapper">
                <div className="content dashboard">
                    <div className="row mt-2">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="small-box bg-info">
                                        <div className="inner">
                                            <p><i className="fas fa-calendar-alt"></i> Total Rides Booked</p>
                                            <h3>{this.state.tripsData !== null ? this.state.tripsData.totalRides : 0}</h3>
                                        </div>
                                        <div className="icon">
                                            <i className="fas fa-calendar-alt"></i>
                                        </div>
                                        <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="small-box bg-success">
                                        <div className="inner">
                                            <p><i className="fas fa-hourglass-start"></i> Pending Rides</p>
                                            <h3>{this.state.tripsData != null ? this.state.tripsData.totalPendingRides : 0}</h3>
                                        </div>
                                        <div className="icon">
                                            <i className="fas fa-hourglass-start"></i>
                                        </div>
                                        <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="small-box bg-warning">
                                        <div className="inner">
                                            <p><i className="fas fa-check-circle"></i> Completed Rides</p>
                                            <h3>{this.state.tripsData !== null ? this.state.tripsData.totalCompletedRides : 0}</h3>
                                        </div>
                                        <div className="icon">
                                            <i className="fas fa-check-circle"></i>
                                        </div>
                                        <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="small-box bg-danger">
                                        <div className="inner">
                                            <p><i className="fas fa-car"></i> Total Upcoming Rides</p>
                                            <h3>{this.state.tripsData !== null ? this.state.tripsData.totalUpcommingRides : 0}</h3>
                                        </div>
                                        <div className="icon">
                                            <i className="fas fa-car"></i>
                                        </div>
                                        <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Scheduled Vs Actual Pick-up Time</h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        <Chart
                                            id="scheduleVsActual"
                                            title=""
                                            dataSource={this.state.scheduleVsActualData}
                                            style={{ height: "200px", width: "690px" }}>
                                            <CommonSeriesSettings argumentField="dayName" type="stackedBar" />
                                            <Series
                                                valueField="dayTotalRides"
                                                name="Total Rides"
                                                stack="dayTotalRides"
                                                color="#ffc107"
                                            />
                                            <Series
                                                valueField="totalCompletedRides"
                                                name="Completed Rides"
                                                stack="totalCompletedRides"
                                                color="#28a745"
                                            />
                                            <ValueAxis
                                                title="Rides"
                                            />
                                            <ArgumentAxis
                                                title="Day Name"
                                            />
                                            <Legend
                                                verticalAlignment="top"
                                                horizontalAlignment="center"
                                            />
                                            <Export enabled={true} />
                                            <Tooltip
                                                enabled={true}
                                                location="edge"
                                                customizeTooltip={this.customizeTooltip1}
                                            />
                                        </Chart>
                                        {/*<img src="https://docs.moodle.org/dev/images_dev/3/3d/line_chart.png">*/}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">On Time Performance</h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        <Chart
                                            palette=""
                                            dataSource={this.state.scheduleVsActualData}
                                            id="GTPerformance"
                                        >
                                            <CommonSeriesSettings
                                                argumentField="dayName"
                                                valueField="gtPerformance"
                                                type={this.state.type}
                                                color="#fd7e14"
                                            />
                                            {
                                                GTPerformanceSources.map(function (item) {
                                                    return <Series key={item.value} valueField={item.value} name={item.name} />;
                                                })
                                            }
                                            <Margin bottom={20} />
                                            <ValueAxis
                                                title="GT Performance (%)"
                                            />
                                            <ArgumentAxis
                                                title="Day Name">
                                                <Grid visible={true} />
                                            </ArgumentAxis>
                                            <Legend
                                                verticalAlignment="bottom"
                                                horizontalAlignment="center"
                                                itemTextPosition="bottom"
                                                visible={false}
                                            />
                                            <Export enabled={true} />
                                            <Tooltip enabled={true} />
                                        </Chart>
                                        {/* <img src="https://docs.moodle.org/dev/images_dev/9/97/horizontal_bar_chart.png" />*/}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Next 24 Hours Upcoming Rides</h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        <Chart
                                            palette=""
                                            dataSource={this.state.hourlyRidesData}
                                            id="UpcomingRides"
                                        >
                                            <CommonSeriesSettings
                                                argumentField="hourName"
                                                valueField="hourRides"
                                                type={this.state.type}
                                                color="#dc3545"
                                            />
                                            {
                                                UpcommingRidesSources.map(function (item) {
                                                    return <Series key={item.value} valueField={item.value} name={item.name} />;
                                                })
                                            }
                                            <Margin bottom={20} />
                                            <ValueAxis
                                                title="Upcoming Rides"
                                            />
                                            <ArgumentAxis
                                                title="Hour"
                                            />
                                            <Legend
                                                verticalAlignment="bottom"
                                                horizontalAlignment="center"
                                                itemTextPosition="bottom"
                                                visible={false}
                                            />
                                            <Export enabled={true} />
                                            <Tooltip
                                                enabled={true}
                                                customizeTooltip={this.customizeTooltip2}
                                            />
                                        </Chart>
                                        {/*<img src="https://docs.moodle.org/dev/images_dev/c/c5/bar_chart.png" />*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}