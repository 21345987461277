import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { SelectBox } from 'devextreme-react/select-box';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { ZoneType } from '../../helpers/fixcodes.js'
import {HotelMappingComponent} from '../Shared/HotelMappingComponent';

export class ServiceAreaZoneComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rowId: 0,
            isSubmited: false,
            serviceAreaZoneGridDataSource: null,
            addEditServiceAreaZoneDataModalIsOpen: false,
            locationList: [],
            locationId: 0,
            serviceAreaList: [],
            serviceAreaId: 0,
            zoneTypeList: [],
            zoneType: 0,
            name: '',
            bookingZone: false,
            isActive: false,
            isDisable: false,
            airportList: [],
            airport: 0,
            errors: {
                locationId: '',
                serviceAreaId: '',
                zoneType: '',
                name: '',
                airport:'',
            }
        }

        this.getServiceAreaList = this.getServiceAreaList.bind(this);
        this.handleaddEditServiceAreaZoneSubmit = this.handleaddEditServiceAreaZoneSubmit.bind(this);
        this.openHotelMapping = this.openHotelMapping.bind(this);
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);

    }
    componentDidMount = () => {
        this.getserviceAreaZoneList();
    }
    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getserviceAreaZoneList()
            }
        });
    }
    async getserviceAreaZoneList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('account-api/location-servicearea-zone', requestParams);
        const data = await response.json();
        if (data !== null && data.locationServiceAreaZone.length > 0) {
            this.setState({
                serviceAreaZoneGridDataSource: data.locationServiceAreaZone
            });
        }
    }
    async LocationList() {
        let locationId = parseInt(sessionStorage.getItem("session_locationId"));
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('account-api/locations', requestParams);
        const data = await response.json();
        if (data !== null && data.locations.length > 0) {
            this.setState({
                locationList: data.locations
            });
        }
    }
    async getServiceAreaList(locationId) {
        if (locationId > 0) {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('serviceArea-api/0', requestParams);
            const data = await response.json();
            if (data !== null && data.locationServiceArea.length > 0) {
                this.setState({
                    serviceAreaList: data.locationServiceArea.filter(x => x.enable === true && x.locationId === locationId)
                });
            }
        }
    }
    async ZoneTypeList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('zoneType-api/zoneType-dropdown-list', requestParams);
        const data = await response.json();
        if (data !== null && data.zoneType.length > 0) {
            this.setState({
                zoneTypeList: data.zoneType
            });
        }
    }
    async AirportList(locationId) {
        const reqlocation = [];
        reqlocation.push(locationId);
        var airportsDropdownListRequest = new Object();
        airportsDropdownListRequest.LocationIdList = reqlocation;
        const requestParams = getRequestParams('POST', airportsDropdownListRequest);
        const response = await fetch('/airports-api/airports-dropdown-list/', requestParams);
        const data = await response.json();
        if (data !== null && data.airportsDropdownList.length > 0) {
            this.setState({
                airportList: data.airportsDropdownList
            });
        } else {
            this.setState({
                airportList: [], airport: 0
            });
        }
    }

    openServiceAreaZoneDataModal = () => {
        this.LocationList();
        this.ZoneTypeList();
    }
    addEditServiceAreaZoneControl = (e, data) => {
        if (data != null) {
            e.preventDefault();
            this.setState({
                rowId: data.id,
                locationId: data.locationId,
                serviceAreaId: data.serviceAreaId,
                zoneType: data.zoneTypeId,
                name: data.name,
                bookingZone: data.isBookingZone,
                isActive: data.enable,
                airport: data.airportId,
                addEditServiceAreaZoneDataModalIsOpen: true,
            });
            this.getServiceAreaList(data.locationId);
            this.AirportList(data.locationId);
        }
        else {
            this.setState({
                addEditServiceAreaZoneDataModalIsOpen: true,
            });
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let errors = this.state.errors;
        switch (name) {
            case 'serviceAreaId':
                errors.serviceAreaId = "";
                if (value === null || Number(value) === 0) {
                    this.setState({ serviceAreaId: 0 });
                    errors.serviceAreaId = "Please select service Area.";
                } else {
                    this.setState({ serviceAreaId: Number(value) });
                }
                break;
            case 'zoneType':
                errors.zoneType = "";
                if (value === null || Number(value) === 0) {
                    this.setState({ zoneType: 0 });
                    errors.zoneType = "Please select zone Type.";
                } else {
                    this.setState({ zoneType: Number(value) });
                    if (Number(value) === ZoneType.Airport) {
                        this.AirportList(this.state.locationId);
                    }
                }
                break;
            case 'name':
                errors.name = "";
                if (value.trim() === "") {
                    this.setState({ name: '' });
                    errors.name = "Please enter service area zone name.";
                } else {
                    this.setState({ name: value });
                }
                break;
            case 'airport':
                errors.airport = "";
                if (value === null || Number(value) === 0) {
                    this.setState({ airport: 0 });
                    errors.airport = "Please select airport.";
                } else {
                    this.setState({ airport: Number(value) });
                }
                break;
            default:
                this.setState({
                    [name]: value
                });
                break;
        }
        if (name === "isActive") {
            this.setState({
                isDisable: value
            });
        }
        this.setState({ errors: errors });
    }
    handleLocationInputChange = (data) => {
       
        if (data.selectedItem !== null) {
            this.setState({
                locationId: data.selectedItem.locationId
            });
            this.getServiceAreaList(data.selectedItem.locationId);
            this.AirportList(data.selectedItem.locationId);
        } else {
            this.setState({
                locationId: 0
            });
        }
    }
    handleServiceAreaInputChange = (data) => {
        this.setState({ serviceAreaId: (data.selectedItem != null ? data.selectedItem.id : 0) })
    }

    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.locationId = 0;
        errors.serviceAreaId = '';
        errors.zoneType = 0;
        errors.name = "";
        errors.airport = "";

        if (this.state.locationId === 0) {
            formIsValid = false;
            errors.locationId = "Please select Location.";
        }
        if (this.state.serviceAreaId === 0) {
            formIsValid = false;
            errors.serviceAreaId = "Please select service Area.";
        }
        if (this.state.zoneType === null || this.state.zoneType === 0) {
            formIsValid = false;
            errors.zoneType = "Please select zone Type.";
        }
        if (this.state.name.trim() === "") {
            formIsValid = false;
            errors.name = "Please enter service area zone name.";
        }
        if (this.state.zoneType === 1) {
            if (this.state.airport === 0) {
                formIsValid = false;
                errors.airport = "Please select airport.";
            }
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    async handleaddEditServiceAreaZoneSubmit(e) {
        e.preventDefault();
        this.setState({ isSubmited: true });

        if (this.handleValidation()) {

            var addEditRequest = new Object();
            addEditRequest.ServiceAreaId = this.state.serviceAreaId;
            addEditRequest.Name = this.state.name;
            addEditRequest.LocationId = this.state.locationId;
            addEditRequest.ZoneTypeId = this.state.zoneType;
            addEditRequest.AirportId = this.state.airport;
            addEditRequest.IsBookingZone = this.state.bookingZone;
            addEditRequest.Enable = this.state.rowId > 0 ? this.state.isActive === true ? true : false : true;

            const requestParams = getRequestParams('POST', addEditRequest);
            const response = await fetch(this.state.rowId > 0 ? 'serviceAreaZone-api/update/' + this.state.rowId : 'serviceAreaZone-api/save', requestParams);
            const data = await response.json();
            Swal.fire({
                icon: data.isSuccess ? 'success' : 'error',
                title: data.isSuccess ? 'Success' : 'Oops...',
                text: data.resultMessage,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (data.isSuccess) {
                    if (result.isConfirmed) {
                        this.setState({ isSubmited: false, addEditServiceAreaZoneDataModalIsOpen: false });
                        this.getserviceAreaZoneList();
                        this.closeaddEditServiceAreaZoneModal();
                    }
                } else {
                    this.setState({ isSubmited: false })
                }
            })
        }
        else {
            this.setState({ isSubmited: false })
        }

    }
    closeaddEditServiceAreaZoneModal = () => {
        this.setState({
            rowId: 0,
            isSubmited: false,
            addEditServiceAreaZoneDataModalIsOpen: false,
            locationList: [],
            locationId: 0,
            serviceAreaList: [],
            serviceAreaId: 0,
            zoneTypeList: [],
            zoneType: 0,
            name: '',
            bookingZone: false,
            isActive: false,
            isDisable: false,
            airportList: [],
            airport: 0,
            errors: {
                locationId: '',
                serviceAreaId: '',
                zoneType: '',
                name: '',
                airport:''
            }
        });
    }

    openHotelMapping(e, data) {
        e.preventDefault();
        this.refs.hotelMappingComponent.showModal(data);
    }

    render() {
        const serviceAreaZoneModalTitle = this.state.rowId > 0 ? "Edit Service Area Zone" : "Add Service Area Zone"
        const renderEditGridCell = (data) => {
            return <div>&nbsp;&nbsp;
                <a href="#" className="fas fa-pen icon text-info" title="Edit" onClick={(e) => { this.addEditServiceAreaZoneControl(e, data.data) }} ></a>&nbsp;&nbsp;
                <a href="#" className="fas fa-hotel text-info" title="Set Hotel" onClick={(e) => { this.openHotelMapping(e, data.data) }}></a>&nbsp;&nbsp;
            </div>;
        }
        

        return (<div className="content-wrapper">
            <div className="content-header">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-sm-6 col-md-6 col-lg-6">
                            <h1 className="m-0 text-dark">Service Area Zone</h1>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <ol className="breadcrumb float-sm-right">
                                <NavItem>
                                    <NavLink tag={Link} to="/location/list" className="btn btn-block btn-outline-secondary btn-sm" title="Location"><i className="fas fa-map-marker-alt"></i> Location</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} to="/servicearea/list" className="btn btn-block btn-outline-secondary btn-sm" title="Service Area"><i className="fas fa-map-marker-alt"></i> Service Area</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={(e) => { this.addEditServiceAreaZoneControl(e, null) }} className="btn btn-block btn-outline-secondary btn-sm" title="Add Service Area Zone"><i className="fas fa-plus"></i> Add Service Area Zone</NavLink>
                                </NavItem>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="card card-grid">
                    <div className="card-body">
                        <DataGrid
                            dataSource={this.state.serviceAreaZoneGridDataSource}
                            keyExpr="id"
                            selection={{ mode: 'single' }}
                            columnsAutoWidth={false}
                            showColumnLines={false}
                            showRowLines={true}
                            showBorders={true}
                            rowAlternationEnabled={true}
                            showBorders={true}
                            allowColumnResizing={true}
                            columnResizingMode='widget'
                            onToolbarPreparing={this.onToolbarPreparing}>
                            <Column dataField="id" width={50} caption="" allowFiltering={false} allowSorting={false} cellRender={renderEditGridCell} />
                            <Column dataField="locationName" caption="Location" allowFiltering={true} allowSorting={true} />
                            <Column dataField="serviceAreaName" caption="Service Area" allowFiltering={true} allowSorting={true} />
                            <Column dataField="name" caption="Zone Name" allowFiltering={true} allowSorting={true} />
                            <Column dataField="zoneType" caption="Zone Type" allowFiltering={true} allowSorting={true} />
                            <Column dataField="airportName" caption="Airport" allowFiltering={true} allowSorting={true} />
                            <Column dataField="status" caption="Status" allowFiltering={true} allowSorting={true} />

                            <SortByGroupSummaryInfo summaryItem="count" />
                            <GroupPanel visible={true} />
                            <HeaderFilter visible={true} />
                            <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                            <Grouping autoExpandAll={true} />
                            <Paging defaultPageSize={20} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                            <Export enabled={true} allowExportSelectedData={false} />
                            <ColumnChooser enabled={true} />
                            <ColumnFixing enabled={true} />

                        </DataGrid>
                    </div>
                </div>

                <Modal ariaHideApp={false}
                    isOpen={this.state.addEditServiceAreaZoneDataModalIsOpen}
                    className={"react-modal edit-service-modal"}
                    onAfterOpen={this.openServiceAreaZoneDataModal}
                    onRequestClose={this.closeaddEditServiceAreaZoneModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal"
                >
                    <form method="post" onSubmit={this.handleaddEditServiceAreaZoneSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}} enctype="multipart/form-data">
                        <div className="modal-header">
                            <h6 className="modal-title">{serviceAreaZoneModalTitle}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeaddEditServiceAreaZoneModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group">
                                        <label>Location <span className="text-red">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.locationList.length > 0 ? this.state.locationList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select location"
                                            name="locationId"
                                            defaultValue={0}
                                            value={this.state.locationId}
                                            displayExpr='locationName'
                                            valueExpr='locationId'
                                            onSelectionChanged={this.handleLocationInputChange}
                                            showClearButton={true}
                                            disabled={this.state.rowId > 0 ? true : false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["locationId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["locationId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group">
                                        <label>Service Area <span className="text-red">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.serviceAreaList.length > 0 ? this.state.serviceAreaList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select service area"
                                            name="serviceAreaId"
                                            defaultValue={0}
                                            value={this.state.serviceAreaId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleServiceAreaInputChange}
                                            showClearButton={true}
                                            disabled={this.state.rowId > 0 ? true : false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["serviceAreaId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["serviceAreaId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group">
                                        <label>Zone Type <span className="text-red">*</span></label>
                                        <select className="form-control form-control-sm" name="zoneType" placeholder="Zone Type" title="Zone Type" value={this.state.zoneType} onChange={this.handleInputChange}>
                                            <option value={0}>Select zone type</option>
                                            {this.state.zoneTypeList && this.state.zoneTypeList.map((item, i) => (
                                                <option key={i} value={item.id}>{item.name}</option>
                                            ))
                                            }
                                        </select>
                                        {this.state.errors["zoneType"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["zoneType"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group">
                                        <label>Name<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-textbox form-control-sm" name="name" title="Name" placeholder="Enter name" maxLength="50" value={this.state.name} onChange={this.handleInputChange} />
                                        {this.state.errors["name"].length > 0 && <span className="error invalid-feedback">{this.state.errors["name"]}</span>}
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="bookingZone" name="bookingZone" checked={this.state.bookingZone} onChange={this.handleInputChange} />
                                            <label className="form-check-label" htmlFor="bookingZone"> Booking Zone</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <div className="form-check" style={{ display: this.state.rowId > 0 ? 'inline-block' : 'none' }}>
                                            <input className="form-check-input" type="checkbox" id="isActive" name="isActive" checked={this.state.isActive} onChange={this.handleInputChange} />
                                            <label className="form-check-label" htmlFor="isActive"> Is Active</label>
                                            { /*<label htmlFor="isActive" style={{ fontSize: "inherit" }}> Is Active &nbsp;<i>(If Inactive, please enter reason)</i></label>*/}
                                        </div>
                                        { /* <textarea className="form-control form-control-sm" name="inactiveReason" disabled={this.state.isDisable === true ? false : true} placeholder="Inactive Reason" maxLength="100" title="Inactive Reason" value={this.state.inactiveReason} onChange={this.handleInputChange} />
                                    {this.state.errors["inactiveReason"].length > 0 && <span className="error invalid-feedback">{this.state.errors["inactiveReason"]}</span>}*/}
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group" style={{ display: this.state.zoneType === ZoneType.Airport ? "inline" : "none" }}>
                                        <label>Airport </label>
                                        <select className="form-control form-control-sm" name="airport" placeholder="Airport" title="Airport" value={this.state.airport} onChange={this.handleInputChange}>
                                            <option value={0}></option>
                                            {this.state.airportList && this.state.airportList.map((item, i) => (
                                                <option key={i} value={item.airportId}>{item.airportName}</option>
                                            ))
                                            }
                                        </select>
                                        {this.state.errors["airport"].length > 0 && <span className="error invalid-feedback">{this.state.errors["airport"]}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success btn-sm" onClick={this.handleaddEditServiceAreaZoneSubmit} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Save"><i className="fas fa-save"></i> Save</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeaddEditServiceAreaZoneModal} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <HotelMappingComponent ref="hotelMappingComponent" />
            </div>
            
        </div >)
    }
}

