import React, { Component } from 'react';
import { setPlan, GlobalState } from '../../helpers/globalfunctions.js';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

export class PlanComponent extends Component {
    static displayName = PlanComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            plans: [],
            planFeatures: [],
            nplanId: 0,
            //selectPlan: [],
            //isSelectPlan: false,
        };
        this.getPlanList = this.getPlanList.bind(this);
    };

    componentDidMount() {
        this.getPlanList();
    };

    async getPlanList() {
        var request = new Object();
        request.PlanId = 0;
        request.PlanFeatureId = 0;

        const requestParams = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('session_token')
            },
            body: JSON.stringify({ request })
        }

        const response = await fetch('/plan-api/plan', requestParams);
        const data = await response.json();
        this.setState({
            plans: data.plans,
            planFeatures: data.planFeatures,
        });
    }

    displayForm(splan) {
        window.location.href = "/registration?plan=" + splan.planId;
    }

    render() {
        return (
            <div className="plan-page">
                <div className="container">
                    <div className="register-logo">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 text-left">
                                <a href="/"><img src="../../dist/img/logo_white_utwiz.png" alt="UTWiz" /></a>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-center">
                                <span className="title">Get started now, pick a plan!</span>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 text-right">
                                <NavLink className="btn btn-outline-secondary btn-sm" tag={Link} to="/" title="Go back to home">Go back to home</NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="card-deck text-center">
                        {this.state.plans && this.state.plans.map((pitem, i) => (
                            <div className="card">
                                <div className="ribbon-wrapper ribbon-lg">
                                    <div className="ribbon bg-danger">
                                        Popular
                                    </div>
                                </div>
                                <div className="card-header">
                                    <div className="plan-type">{pitem.planName}</div>
                                    <small className="plan-recom"></small>
                                </div>
                                <div className="card-body">
                                    <div class="form-group plan-features">
                                        {this.state.planFeatures && this.state.planFeatures.filter(function (fplan) { return fplan.planId == pitem.planId }).map((fitem, i) => (
                                            <p>{fitem.featureName}</p>
                                        ))}
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="plan-price"><sup>$</sup>{pitem.planCostPerMonth}<small> / month</small></div>
                                    <button type="button" value={pitem} className="btn btn-info" onClick={() => this.displayForm(pitem)} title="Sign up">Sign up</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}