import React, { Component } from 'react';
import { LoadPanel } from 'devextreme-react/load-panel';
import Modal from 'react-modal'; //npm install --save react-modal
import { getRequestParams, formatDate } from '../../helpers/globalfunctions';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

const customStyles = {
    content: {
        width: '85%',
        height: '80%',
    }
};

const position = { of: '#locateVehiclediv' };

export class LocateVehicleComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
            isSubmited: false,
            rideId: 0,
            vehicleNumber: '',
            loadPanelVisible: false,
            mapUrl: "",
            refreshRideSource: props.refreshDataSource,
            handleRefresh: props.handleRefresh,
            userId: parseInt(sessionStorage.getItem("session_userId")),
            accountId: parseInt(sessionStorage.getItem("session_accountId")),
        }

        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    closeModal() {
        this.setState({
            isOpenModal: false,
            isSubmited: false,
            vehicleNumber: '',
            rideId: 0,
            mapUrl: "",
            userId: 0,
            accountId: 0,
        });
        this.state.handleRefresh(true);
        this.state.refreshRideSource();
    }

    async showModal(vehicleNumber, rideId) {
        this.setState({
            isOpenModal: true,
            vehicleNumber: vehicleNumber,
            rideId: rideId,
            userId: parseInt(sessionStorage.getItem("session_userId")),
            accountId: parseInt(sessionStorage.getItem("session_accountId")),
        });
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('helper/map-url', requestParams);
        const data = await response.json();

        this.setState({
            mapUrl: data.resultMessage,
        });
    }

    render() {
        const url = this.state.mapUrl + "?accountId=" + this.state.accountId + "&locationId=0&userId=" + this.state.userId + "&vgTypeId=0&rideId=" + this.state.rideId;

        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={customStyles}
                    className={"react-modal"}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal"
                >
                    <form>
                        <div id="locateVehiclediv">
                            <div className="modal-header">
                                <h6 className="modal-title">Locate Vehicle</h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body" >
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <small>Below is the current location of the vehicle number <i><strong className="text-danger"> {this.state.vehicleNumber} </strong></i>.</small>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="widget-map-container">
                                            <iframe width="100%" height="800" frameBorder="0" scrolling="no" allowtransparency="true" src={url}></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        </div>
                        <LoadPanel
                            shadingColor="rgba(0,0,0,0.4)"
                            position={position}
                            visible={this.state.loadPanelVisible}
                            showIndicator={true}
                            shading={true}
                            showPane={true}
                            closeOnOutsideClick={false}
                        />
                    </form>
                </Modal>

            </div>
        );
    }
}