import React, { Component } from 'react';
import DateBox from 'devextreme-react/date-box';
import Modal from 'react-modal'; //npm install --save react-modal
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import Swal from 'sweetalert2';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { SelectBox } from 'devextreme-react/select-box';
import { LoadPanel } from 'devextreme-react/load-panel';

export class SetVehicleDepositComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            isVehiclDepositModalOpen: false,
            vehicleDepositData: [],
            vehiclesDropdownList: [],
            isAdd: false,
            isEdit: false,
            isView: false,
            id: 0,
            vehicleOwnerMapId: 0,
            vehicleId: 0,
            vehicleNumber: '',
            depositedDate: moment(new Date()).format("MM/DD/YYYY"),
            depositedAmount: 0,
            depositedBy: '',
            note: '',
            isRefunded: false,
            refundedStatus: '',
            errors: {
                vehicleNumber: '',
                depositedDate: '',
                depositedAmount: '',
                depositedBy: '',
            }
        }
    }
    getVehicleDepositDetail = async (depositId) => {
        try {
            var request = new Object();
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('vehicle-deposit-api/' + depositId + '/list', requestParams);
            if (response.ok) {
                const data = await response.json();
                if (data.isSuccess) {
                    this.setState({
                        vehicleDepositData: data.vehicleDeposits
                    });
                }
                else {
                    await this.onfailure(null, 'Unable to process the request.');
                }
            }
            else {
                await this.onfailure(null, 'Unable to process the request.');
            }
        } catch (ex) {
            console.log(ex);
            await this.onfailure(null, 'Something went wrong while fetching data!');
        }
    };
    getVehiclesDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('vehicle-api/dropdown-all', requestParams);
            const data = await response.json();
            this.setState({
                vehiclesDropdownList: data.vehiclesDropdown
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleShowModal = async (isAdd, isEdit, isView, vehicleOwnerMapId, depositId, vehicleId, vehicleNumber) => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getVehiclesDropdownList();
            if (Number(depositId) > 0) {
                await this.getVehicleDepositDetail(depositId);
            }
            this.setState({
                isVehiclDepositModalOpen: true,
                isAdd: isAdd,
                isEdit: isEdit,
                isView: isView,
                vehicleOwnerMapId: vehicleOwnerMapId,
                id: depositId,
                vehicleId: vehicleId,
                vehicleNumber: vehicleNumber,
            });
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnValueChangedDateBox = (event) => {
        try {
            let errors = this.state.errors;
            errors.depositedDate = '';
            if (event.value === '' || event.value === null) {
                errors.depositedDate = 'Please select deposited date.';
            }
            else {
                if (moment(new Date(event.value)).isValid() === false) {
                    errors.depositedDate = 'Please select valid date.';
                }
            }
            this.setState({
                depositedDate: event.value
            });
            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleInputChange = (event) => {
        let errors = this.state.errors;
        const target = event.target;
        const name = target.name;
        switch (name) {
            case 'vehicleNumber':
                if (Number(target.value) > 0) {
                    errors.vehicleNumber = '';
                }
                else {
                    errors.vehicleNumber = 'Please select vehicle.';
                }
                this.setState({
                    vehicleId: target.value,
                    vehicleNumber: target.options[target.selectedIndex].text
                });
                break;
            case 'depositedBy':
                errors.depositedBy = '';
                if (target.value.trim() === '') {
                    errors.depositedBy = 'Please enter deposited by.';
                }
                this.setState({
                    depositedBy: target.value
                });
                break;
            case 'depositedAmount':
                errors.depositedAmount = '';
                const re = /^\d*(\.\d{0,2})?$/;
                if (target.value.trim() === '' || re.test(target.value)) {
                    this.setState({
                        depositedAmount: target.value
                    });
                    if (target.value.trim() === '' || parseFloat(target.value) === 0) {
                        errors.depositedAmount = 'Please enter deposited amount.';
                    }
                }
                break;
            default:
                break;
        }
        this.setState({ errors: errors });
    }
    handleVehicleOnChange = (selectedOption) => {
        let errors = this.state.errors;
        errors.vehicleNumber = '';
        const vhId = (selectedOption.selectedItem !== null ? Number(selectedOption.selectedItem.id) : 0);
        const vhNumber = (selectedOption.selectedItem !== null ? selectedOption.selectedItem.name : '');
        if (vhId === 0) {
            errors.vehicleNumber = 'Please select vehicle.';
        }
        this.setState({
            errors: errors,
            vehicleId: vhId,
            vehicleNumber: vhNumber
        });
    }
    handleAssignDataToState = async () => {
        try {
            if (this.state.vehicleDepositData === null || this.state.vehicleDepositData.length === 0) {
                this.setState({
                    id: 0,
                    depositedDate: moment(new Date()).format("MM/DD/YYYY"),
                    depositedAmount: 0,
                    depositedBy: '',
                    note: '',
                    isRefunded: false,
                    refundedStatus: '',
                    errors: {
                        vehicleNumber: '',
                        depositedDate: '',
                        depositedAmount: '',
                        depositedBy: '',
                    }
                });
            } else {
                if (this.state.isAdd === false) {
                    let deposit = this.state.vehicleDepositData;
                    if (deposit.length > 0) {
                        this.setState({
                            id: deposit[0].id === null ? 0 : Number(deposit[0].id),
                            vehicleOwnerMapId: deposit[0].vehicleOwnerMapId === null ? 0 : Number(deposit[0].vehicleOwnerMapId),
                            vehicleId: deposit[0].vehicleId === null ? 0 : Number(deposit[0].vehicleId),
                            vehicleNumber: deposit[0].vehicleNumber === null ? '' : deposit[0].vehicleNumber.trim(),
                            depositedDate: deposit[0].depositedDate || null,
                            depositedAmount: deposit[0].depositedAmount || null,
                            depositedBy: deposit[0].depositedBy === null ? '' : deposit[0].depositedBy.trim(),
                            note: deposit[0].note === null ? '' : deposit[0].note.trim(),
                            isRefunded: deposit[0].isRefunded || false,
                            refundedStatus: deposit[0].refundedStatus || '',
                        });
                    }
                }
                else {
                    this.setState({
                        depositedDate: moment(new Date()).format("MM/DD/YYYY"),
                    });
                }
            }
            if (this.state.isVehiclDepositModalOpen) {
                this.submitVehicleDepositButtonValidator.focus();
            }
        } catch (ex) {
            console.log(ex);
            await this.onfailure(null, 'Something went wrong while assigning data!');
        }
    }
    handleResetDataToState = async () => {
        try {
            this.setState({
                isSubmited: false,
                isVehiclDepositModalOpen: false,
                isAdd: false,
                isEdit: false,
                isView: false,
                id: 0,
                vehicleOwnerMapId: 0,
                vehicleId: 0,
                vehicleNumber: '',
                depositedDate: '',
                depositedAmount: 0,
                depositedBy: '',
                note: '',
                isRefunded: false,
                refundedStatus: '',
                errors: {
                    vehicleNumber: '',
                    depositedDate: '',
                    depositedAmount: '',
                    depositedBy: '',
                }
            });
            if (this.props.isHandleFromDepositMasterList) {
                await this.props.handleOnRefresh();
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    handleVehicleDepositSubmit = async event => {
        event.preventDefault();
        try {
            if (this.iSFormVehicleDepositValid()) {
                const isConfirm = await this.isConfirm();
                if (!isConfirm) {
                    return;
                }
                this.setState({ isSubmited: true });
                var request = new Object();
                request.VehicleOwnerMapId = Number(this.state.vehicleOwnerMapId);
                request.VehicleId = Number(this.state.vehicleId);
                request.DepositedDate = moment(new Date(this.state.depositedDate), 'MM/DD/YYYY').format('MM/DD/YYYY HH:mm:ss');
                request.DepositedAmount = parseFloat(this.state.depositedAmount);
                request.DepositedBy = this.state.depositedBy;
                request.Note = this.state.note;
                request.History = this.setAddEditVehicleDepositHistory();
                try {
                    const requestParams = getRequestParams('POST', request);
                    const response = await fetch('vehicle-deposit-api/' + Number(this.state.id) + '/save', requestParams);
                    const data = await response.json();
                    let icon = '';
                    let title = '';
                    if (data.isSuccess) {
                        icon = 'success';
                        title = 'Success';
                    }
                    else {
                        if (data.retValue === -1) {
                            icon = 'warning';
                            title = 'Unable to process';
                        }
                        else {
                            icon = 'error';
                            title = 'Oops...';
                        }
                    }
                    await Swal.fire({
                        allowOutsideClick: false,
                        icon: icon,
                        title: title,
                        text: data.resultMessage,
                    }).then(async (result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (data.isSuccess) {
                            if (result.isConfirmed) {
                                await this.handleResetDataToState();
                            }
                        }
                    })
                } catch (ex) {
                    console.log(ex);
                    await this.onfailure(null, null);
                }
            }
        } catch (ex) {
            console.log(ex);
            await this.onfailure(null, null);
        }
        this.setState({ isSubmited: false })
    }
    iSFormVehicleDepositValid = () => {
        let formIsValid = true;
        let errors = this.state.errors;
        try {
            let isFocus = false;
            errors.vehicleNumber = '';
            errors.depositedDate = '';
            errors.depositedBy = '';
            errors.depositedAmount = '';
            if (Number(this.state.vehicleId) === 0 || this.vehicleNumberSelectValidator.props.value === undefined) {
                formIsValid = false;
                errors.vehicleNumber = 'Please select vehicle.';
                if (this.vehicleNumberSelectValidator.props.isDisabled) {
                    errors.vehicleNumber = 'Please select a vehicle. It may be disabled.';
                    if (this.state.vehicleNumber !== null) {
                        if (this.state.vehicleNumber.trim() !== '') {
                            errors.vehicleNumber = 'Please select a vehicle. Vehicle [' + this.state.vehicleNumber + '] may be disabled.';
                        }
                    }
                }

                if (isFocus === false) { this.vehicleNumberSelectValidator._instance.focus(); isFocus = true; }
            }            
            if (this.state.depositedDate == "" || this.state.depositedDate == null) {
                formIsValid = false;
                errors.depositedDate = 'Please select deposited date.';
                if (isFocus === false) { this.depositedDateSelectValidator.instance.focus(); isFocus = true; }
            }
            else {
                if (this.state.depositedDate === null || moment(new Date(this.state.depositedDate)).isValid() === false) {
                    formIsValid = false;
                    errors.depositedDate = 'Please select valid date.';
                    if (isFocus === false) { this.depositedDateSelectValidator.instance.focus(); isFocus = true; }
                }
            }
            if (this.state.depositedBy.trim() === '') {
                formIsValid = false;
                errors.depositedBy = 'Please enter deposited by.';
                if (isFocus === false) { this.depositedByInputValidator.focus(); isFocus = true; }
            }
            if (this.state.depositedAmount === null || this.state.depositedAmount.toString().trim() === '' || parseFloat(this.state.depositedAmount) === 0) {
                formIsValid = false;
                errors.depositedAmount = 'Please enter deposited amount.';
                if (isFocus === false) { this.depositedAmountSelectValidator.focus(); isFocus = true; }
            }
            if (isFocus === false) { this.submitVehicleDepositButtonValidator.focus(); }
        } catch (ex) {
            console.log(ex);
            formIsValid = false;
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    isConfirm = async () => {
        let isResult = false;
        let addUpdateComfirmation = '<small>Deposit details for Vehicle [<b>' + this.state.vehicleNumber + '</b>] <br> Deposit Amount: <b>' + this.state.depositedAmount + '</b></small>';
        addUpdateComfirmation = addUpdateComfirmation + (Number(this.state.vehicleOwnerMapId) === 0 ? "<span style='color:green'> <br>[<b>" + this.state.vehicleNumber + "</b>] is deposited with recent vehilce owner</span>" : "");
        addUpdateComfirmation = addUpdateComfirmation + '<br>Are you sure want to ' + (this.state.isAdd ? 'Add' : 'Update') + '?';
        await Swal.fire({
            title: '<small><b>Confirmation</b></small>',
            html: addUpdateComfirmation,
            icon: 'question',
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Yes, ' + (this.state.isAdd ? 'Add' : 'Update') + ' it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }
    setAddEditVehicleDepositHistory = () => {
        let history = '';
        try {
            let sep = '. ';
            let oldData = this.state.vehicleDepositData;
            if (this.state.isAdd) {
                history = 'New vehicle deposit is added' + sep;
            }
            else {
                history = 'Vehicle deposit has been successfully updated' + sep;
                if (oldData.length > 0) {
                    if (Number(oldData[0].vehicleId) !== Number(this.state.vehicleId)) {
                        if (Number(oldData[0].vehicleId) > 0 && Number(this.state.vehicleId) > 0) {
                            history += 'Vehicle is changed from ' + oldData[0].vehicleNumber + ' To ' + this.state.vehicleNumber.trim() + sep;
                        }
                        else {
                            history += 'Vehicle is updated ' + this.state.vehicleNumber.trim() + sep;
                        }
                    }
                    let olddepositedDate = oldData[0].depositedDate === null ? '' : oldData[0].depositedDate.trim();
                    if (olddepositedDate !== '') {
                        if (moment(olddepositedDate, "MM/DD/YYYY").isSame(moment(this.state.depositedDate, "MM/DD/YYYY")) === false) {
                            history += 'Deposited date is changed from ' + moment(olddepositedDate).format("MM/DD/YYYY") + ' To ' + moment(this.state.depositedDate).format("MM/DD/YYYY") + sep;
                        }
                    }
                    else {
                        history += 'Deposited date is updated ' + moment(this.state.depositedDate).format("MM/DD/YYYY") + sep;
                    }

                    let olddepositedBy = oldData[0].depositedBy === null ? '' : oldData[0].depositedBy.trim();
                    if (olddepositedBy !== this.state.depositedBy.trim()) {
                        if (olddepositedBy.length > 0 && this.state.depositedBy.trim().length > 0) {
                            history += 'Deposited by is changed from ' + olddepositedBy + ' To ' + this.state.depositedBy.trim() + sep;
                        }
                        else {
                            history += 'Deposited by is updated ' + this.state.depositedBy.trim() + sep;
                        }
                    }
                    let olddepositedAmount = oldData[0].depositedAmount === null ? 0 : oldData[0].depositedAmount;
                    if (parseFloat(olddepositedAmount) !== parseFloat(this.state.depositedAmount)) {
                        history += 'Deposited amount is changed from ' + parseFloat(olddepositedAmount) + ' To ' + parseFloat(this.state.depositedAmount) + sep;
                    }
                    let oldnote = oldData[0].note === null ? '' : oldData[0].note.trim();
                    if (oldnote !== this.state.note.trim()) {
                        if (oldnote.length > 0 && this.state.note.trim().length > 0) {
                            history += 'Note is changed from ' + oldnote + ' To ' + this.state.note.trim() + sep;
                        }
                        else {
                            history += 'Not is updated ' + this.state.note.trim() + sep;
                        }
                    }
                }
                if (history.trim() === '') {
                    history += 'Vehicle deposit has been successfully updated' + sep;
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        return history;
    }
    onfailure = async (title, resultMessage) => {
        await Swal.fire({
            allowOutsideClick: false,
            showCloseButton: true,
            icon: 'error',
            title: (title === null || title.trim() === '') ? 'Oops...' : title,
            text: (resultMessage === null || resultMessage.trim() === '') ? 'Something went wrong!' : resultMessage.trim(),
        })
    }
    render() {
        return (
            <div className="content-wrapper">
                <Modal ariaHideApp={false}
                    isOpen={this.state.isVehiclDepositModalOpen}
                    style={{ content: { width: '35%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleAssignDataToState}
                    onRequestClose={this.handleResetDataToState}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            {/*<h6 className="modal-title">{"Vehicle Franchisee Deposit" + (this.state.isView ? ' (Readonly)' : '')}</h6>*/}
                            <h6 className="modal-title">{(this.state.isView ? 'View Vehicle Franchisee Deposit' : 'Modify Vehicle Franchisee Deposit')}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleResetDataToState}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                    <div className="form-group">
                                        <label>Vehicle <span className="text-danger">*</span></label>
                                        <SelectBox ref={(input) => this.vehicleNumberSelectValidator = input}
                                            dataSource={this.state.vehiclesDropdownList.length > 0 ? this.state.vehiclesDropdownList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select vehicle"
                                            name="vehicleNumber"
                                            defaultValue={0}
                                            value={this.state.vehicleId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleVehicleOnChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["vehicleNumber"].length > 0 && <span className="error invalid-feedback">{this.state.errors["vehicleNumber"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                    <div className="form-group">
                                        <label>Deposit Date <span className="text-danger">*</span></label>
                                        <DateBox type="date" style={{ width: "100%" }} className="form-control form-control-sm" name="depositedDate" placeholder="mm/dd/yyyy"
                                            disabled={this.state.isView}
                                            defaultValue={new Date()}
                                            value={this.state.depositedDate}
                                            ref={select => this.depositedDateSelectValidator = select}
                                            onValueChanged={this.handleOnValueChangedDateBox}
                                        />
                                        {this.state.errors["depositedDate"].length > 0 && <span className="error invalid-feedback">{this.state.errors["depositedDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                    <div className="form-group">
                                        <label>Deposited By <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-textbox form-control-sm" name="depositedBy" title="Deposited By User" placeholder="Enter deposited by user" maxLength="30" disabled={this.state.isView} ref={input => this.depositedByInputValidator = input} disabled={this.state.isView} value={this.state.depositedBy} onChange={this.handleInputChange} />
                                        {this.state.errors["depositedBy"].length > 0 && <span className="error invalid-feedback">{this.state.errors["depositedBy"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                    <div className="form-group">
                                        <label>Deposit Amount <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-textbox form-control-sm" name="depositedAmount" title="Deposit Amount" placeholder="00.00" maxLength="15" disabled={this.state.isView} ref={select => this.depositedAmountSelectValidator = select} value={this.state.depositedAmount} onChange={this.handleInputChange} />
                                        {this.state.errors["depositedAmount"].length > 0 && <span className="error invalid-feedback">{this.state.errors["depositedAmount"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Note (If any) </label>
                                        <textarea className="form-control form-textbox form-control-sm" name="note" title="Note" placeholder="Enter note (if any)" rows="3" maxLength="100" disabled={this.state.isView} value={this.state.note} onChange={(e) => { this.setState({ note: e.target.value }) }} />
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ display: this.state.isView ? 'block' : 'none' }} >
                                    <label>Status: <span className="text-danger"> {this.state.isRefunded === true ? ' Refunded' : ' Deposited'}</span></label>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" disabled={this.state.isView} ref={button => this.submitVehicleDepositButtonValidator = button} onClick={this.handleVehicleDepositSubmit}>
                                {this.state.isSubmited && <span title="In-pocess"><i className="fas fa-spinner fa-spin"></i> In-pocess</span>}
                                {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleResetDataToState} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        );
    }
}