import React, { Component } from 'react';
import { LoadPanel } from 'devextreme-react/load-panel';
import Modal from 'react-modal'; //npm install --save react-modal
import { getRequestParams } from '../../helpers/globalfunctions.js';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import { SelectBox } from 'devextreme-react/select-box';
import DateBox from 'devextreme-react/date-box';
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'
let currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class AccountRideDetailsReportOnlyComponent extends Component {
    constructor() {
        currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        super()
        this.state = {
            isSubmitted: false,
            isEmailSubmitted:false,
            isOpenModal: false,
            loadPanelVisible: false,
            fromDate: moment(currentTime).format("MM/DD/YYYY"),
            toDate: moment(currentTime).format("MM/DD/YYYY"),
            isAllCustomer: true,
            customerDropdownList: [],
            customerDropdownData: null,
            customerId: 0,
            isAllPickupZone: true,
            pickupZoneDropdownList: [],
            pickupZoneDropdownData: null,
            pickupZoneId: 0,
            isAllDropoffZone: true,
            dropoffZoneDropdownList: [],
            dropoffZoneDropdownData: null,
            dropoffZoneId: 0,
            errors: {
                fromDate: '',
                toDate: '',
                customer: '',
                pickupZone: '',
                dropoffZone: ''
            }
        }
    }
    getCustomerDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('account-api/customer-dropdown/', requestParams);
            const data = await response.json();
            this.setState({
                customerDropdownList: data.customers
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    getZonesDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('serviceAreaZone-api/zone-dropdown/' + 0 + "/" + 0, requestParams);
            const data = await response.json();
            this.setState({
                pickupZoneDropdownList: data.locationServiceAreaZoneList,
                dropoffZoneDropdownList: data.locationServiceAreaZoneList
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getCustomerDropdownList();
            await this.getZonesDropdownList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isEmailSubmitted: false,
                isOpenModal: false,
                loadPanelVisible: false,
                fromDate: moment(currentTime).format("MM/DD/YYYY"),
                toDate: moment(currentTime).format("MM/DD/YYYY"),
                isAllCustomer: true,
                customerDropdownList: [],
                customerDropdownData: null,
                customerId: 0,
                isAllPickupZone: true,
                pickupZoneDropdownList: [],
                pickupZoneDropdownData: null,
                pickupZoneId: 0,
                isAllDropoffZone: true,
                dropoffZoneDropdownList: [],
                dropoffZoneDropdownData: null,
                dropoffZoneId: 0,
                errors: {
                    fromDate: '',
                    toDate: '',
                    customer: '',
                    pickupZone: '',
                    dropoffZone: ''
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnInputChange = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
            let errors = this.state.errors;
            switch (name) {
                case 'isAllCustomer':
                    errors.customer = '';
                    this.setState({ isAllCustomer: value, customerDropdownData: null, customerId: 0 });
                    break;
                case 'isAllPickupZone':
                    errors.pickupZone = '';
                    this.setState({ isAllPickupZone: value, pickupZoneDropdownData: null, pickupZoneId: 0 });
                    break;
                case 'isAllDropoffZone':
                    errors.dropoffZone = '';
                    this.setState({ isAllDropoffZone: value, dropoffZoneDropdownData: null, dropoffZoneId: 0 });
                    break;
                default:
                    break;
            }
            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnFromDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.fromDate = '';
            let fromDate = '';
            if (event.value !== null && event.value !== '') {
                fromDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.fromDate = 'Please select date.';
            }
            if (this.state.toDate.trim() !== '') {
                errors.toDate = '';
            }
            this.setState({ fromDate: fromDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnToDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.toDate = '';
            let toDate = '';
            if (event.value !== null && event.value !== '') {
                toDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.toDate = 'Please select date.';
            }
            if (this.state.fromDate.trim() !== '') {
                errors.fromDate = '';
            }
            this.setState({ toDate: toDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnCustomerDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.customer = '';
            let customerId = 0;
            if (data !== null) {
                customerId = (data !== null ? data.id : 0);
            }
            else {
                //errors.customer = 'Please select customer.';
            }
            this.setState({ customerDropdownData: data, customerId: customerId, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnPickupZoneDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.pickupZone = '';
            let pickupZoneId = 0;
            if (data !== null) {                
                pickupZoneId = (data.selectedItem != null ? data.selectedItem.id : 0)
            }
            else {
                //errors.pickupZone = 'Please select zone.';
            }
            this.setState({ pickupZoneDropdownData: data, pickupZoneId: pickupZoneId, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnDropoffZoneDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.dropoffZone = '';
            let dropoffZoneId = 0;
            if (data !== null) {                
                dropoffZoneId = (data.selectedItem != null ? data.selectedItem.id : 0)
            }
            else {
                //errors.dropoffZone = 'Please select zone.';
            }
            this.setState({ dropoffZoneDropdownData: data, dropoffZoneId: dropoffZoneId, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnReportSubmit = async (event) => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isSubmitted: true });
                let fromDate = '';
                let toDate = '';
                let customerCode = '';
                if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                    fromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00';
                    toDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59';
                }
                if (this.state.customerDropdownData !== null) {
                    if (Object.keys(this.state.customerDropdownData).length > 0) {
                        customerCode = this.state.customerDropdownData.code;
                    }
                }
                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.StartDate = fromDate;
                request.EndDate = toDate;
                request.AccountCode = customerCode;
                request.Number1 = Number(this.state.pickupZoneId);
                request.Number2 = Number(this.state.dropoffZoneId);
                request.fileFormat = 'pdf';
                request.MethodName = "GetAccountRideDetailsReport";
                request.SelectionCrieteria = await this.setSelectionCrieteria();
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                let a = document.createElement('a');
                a.href = dataResponse.reportBase64String;
                a.download = 'AccountRideDetails.pdf';
                a.click();
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false });
    }
    isFormValid = async () => {
        let isValid = true;
        try {
            let errors = this.state.errors;
            errors.fromDate = '';
            errors.toDate = '';
            errors.customer = '';
            errors.pickupZone = '';
            errors.dropoffZone = '';
            if (this.state.isAllCustomer === false) {
                if (Number(this.state.customerId) === 0) {
                    errors.customer = 'Please select customer.';
                    isValid = false;
                }
            }
            if (this.state.fromDate.trim() === '') {
                errors.fromDate = 'Please select date.';
                isValid = false;
            }
            if (this.state.toDate.trim() === '') {
                errors.toDate = 'Please select date.';
                isValid = false;
            }
            if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                if (moment(this.state.fromDate, 'MM/DD/YYYY').isAfter(this.state.toDate, 'MM/DD/YYYY')) {
                    errors.fromDate = 'Please enter a valid date range.';
                    errors.toDate = 'Please enter a valid date range.';
                    isValid = false;
                }
            }
            if (this.state.isAllPickupZone === false) {
                if (Number(this.state.pickupZoneId) === 0) {
                    errors.pickupZone = 'Please select zone.';
                    isValid = false;
                }
            }
            if (this.state.isAllDropoffZone === false) {
                if (Number(this.state.dropoffZoneId) === 0) {
                    errors.dropoffZone = 'Please select zone.';
                    isValid = false;
                }
            }
            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    setSelectionCrieteria = async () => {
        let selectionCrieteria = 'Data entry audit for';
        try {
            if (Number(this.state.customerId) > 0) {
                if (this.state.customerDropdownData !== null && Object.keys(this.state.customerDropdownData).length > 0) {
                    selectionCrieteria += ' customer - ' + this.state.customerDropdownData.name;
                }
            }
            else {
                selectionCrieteria += ' all customer';
            }
            if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                selectionCrieteria += ', period from  - ' + this.state.fromDate + ' to ' + this.state.toDate;
            }
            if (Number(this.state.pickupZoneId) > 0) {
                if (this.state.pickupZoneDropdownData !== null && Object.keys(this.state.pickupZoneDropdownData).length > 0) {
                    selectionCrieteria += ', pickup zone - ' + this.state.pickupZoneDropdownData.name;
                }
            }
            else {
                selectionCrieteria += ', all pickup zone';
            }
            if (Number(this.state.dropoffZoneId) > 0) {
                if (this.state.dropoffZoneDropdownData !== null && Object.keys(this.state.dropoffZoneDropdownData).length > 0) {
                    selectionCrieteria += ', dropoff zone - ' + this.state.dropoffZoneDropdownData.name;
                }
            }
            else {
                selectionCrieteria += ', all dropoff zone';
            }
        } catch (ex) {
            selectionCrieteria = '';
            console.log(ex);
        }
        return selectionCrieteria;
    }
    handleOnSendEmail = async event => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isEmailSubmitted: true });
                let fromDate = '';
                let toDate = '';
                let customerCode = '';
                if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                    fromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00';
                    toDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59';
                }
                if (this.state.customerDropdownData !== null) {
                    if (Object.keys(this.state.customerDropdownData).length > 0) {
                        customerCode = this.state.customerDropdownData.code;
                    }
                }
                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.StartDate = fromDate;
                request.EndDate = toDate;
                request.AccountCode = customerCode;
                request.Number1 = Number(this.state.pickupZoneId);
                request.Number2 = Number(this.state.dropoffZoneId);
                request.fileFormat = 'pdf';
                request.MethodName = "GetAccountRideDetailsReport";
                request.SelectionCrieteria = await this.setSelectionCrieteria();
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
                    let emailSubject = 'Account Ride Details - ' + moment(currentTime).format("DD MMMM YYYY HH:mm");
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = 'AccountRideDetails.pdf';
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '45%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleOnReportSubmit}
                        style={(this.state.isSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Account Ride Details'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                id="isAllCustomer" name="isAllCustomer"
                                                checked={this.state.isAllCustomer}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isAllCustomer"> All Customer</label>
                                        </div>
                                        <SelectBox
                                            dataSource={this.state.customerDropdownList.length > 0 ? this.state.customerDropdownList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="ddcustomer"
                                            defaultValue={0}
                                            value={this.state.customerDropdownData}
                                            displayExpr='fullName'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnCustomerDropdownChange}
                                            showClearButton={true}
                                            disabled={this.state.isAllCustomer}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customer"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["customer"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>From Date (Pick-up)</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="fromDate"
                                            placeholder="MM/dd/yyyy"
                                            defaultValue={this.state.fromDate}
                                            value={this.state.fromDate}
                                            onValueChanged={this.handleOnFromDateChange}
                                            width={'100%'}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["fromDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["fromDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>To Date (Pick-up)</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="toDate"
                                            placeholder="MM/dd/yyyy"
                                            defaultValue={this.state.toDate}
                                            value={this.state.toDate}
                                            min={this.state.fromDate}
                                            onValueChanged={this.handleOnToDateChange}
                                            width={'100%'}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["toDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["toDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                id="isAllPickupZone" name="isAllPickupZone"
                                                checked={this.state.isAllPickupZone}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isAllPickupZone"> All Pick-up Zone</label>
                                        </div>
                                        <SelectBox
                                            dataSource={this.state.pickupZoneDropdownList.length > 0 ? this.state.pickupZoneDropdownList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select pick-up zone"
                                            name="ddpickupzone"
                                            defaultValue={0}
                                            value={this.state.pickupZoneDropdownData}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnPickupZoneDropdownChange}
                                            showClearButton={true}
                                            disabled={this.state.isAllPickupZone}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["pickupZone"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["pickupZone"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                id="isAllDropoffZone" name="isAllDropoffZone"
                                                checked={this.state.isAllDropoffZone}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isAllDropoffZone"> All Drop Off Zone</label>
                                        </div>
                                        <SelectBox
                                            dataSource={this.state.dropoffZoneDropdownList.length > 0 ? this.state.dropoffZoneDropdownList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select drop off zone"
                                            name="dddropoffzone"
                                            defaultValue={0}
                                            value={this.state.dropoffZoneDropdownData}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnDropoffZoneDropdownChange}
                                            showClearButton={true}
                                            disabled={this.state.isAllDropoffZone}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["dropoffZone"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["dropoffZone"]}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="submit" className="btn btn-success btn-sm" title="Download">
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                             </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}