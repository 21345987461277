import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import { SelectBox } from 'devextreme-react/select-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import RadioGroup from 'devextreme-react/radio-group';
import { CheckBox } from 'devextreme-react/check-box';
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'
const sortByRadio = [
    { id: 0, name: 'Vehicle Id' },
    { id: 1, name: 'Invoice Number' },
    { id: 2, name: 'Pick-up Date' },
    { id: 3, name: 'Account' },
];

export class BillingReconsilationReportComponent extends Component {
    constructor() {
        super()
        this.state = {
            isSubmitted: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),
            radVehicle: "0",
            vehicleList: [],
            vehicleId: 0,
            vehicleName: "",
            sortByList: sortByRadio,
            sortByType: sortByRadio[0],
            checkBilled: false,
            checkPayoutGenerated: false,
            checksPaid: false,
            errors: {
                vehicleId: 0,
            }
        }

        this.getVehicles = this.getVehicles.bind(this);

        this.handleRadVehicleChange = this.handleRadVehicleChange.bind(this);
        this.handleFromDateChanged = this.handleFromDateChanged.bind(this);
        this.handleToDateChanged = this.handleToDateChanged.bind(this);
        this.handleVehicleChange = this.handleVehicleChange.bind(this);
        this.handleSortByTypeChange = this.handleSortByTypeChange.bind(this);
        this.handleCheckBilledValueChanged = this.handleCheckBilledValueChanged.bind(this);
        this.handleCheckPayoutGeneratedValueChanged = this.handleCheckPayoutGeneratedValueChanged.bind(this);
        this.handleCheckPaidValueChanged = this.handleCheckPaidValueChanged.bind(this);

        this.handleBillingReconsilationReportSubmit = this.handleBillingReconsilationReportSubmit.bind(this);
        this.downloadBillingReconsilationAsPdf = this.downloadBillingReconsilationAsPdf.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
    }
    async componentDidMount() {
        await this.getVehicles();
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    async getVehicles() {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/vehicle-api/dropdown-all', requestParams);
            const data = await response.json();
            this.setState({
                vehicleList: data.vehiclesDropdown,
                multiVehicleList: data.vehiclesDropdown,
            });
        } catch (e) {
            console.log(e);
        }
    }
    handleFromDateChanged(event) {
        let fromDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ startDate: fromDateValue })
    }
    handleToDateChanged(event) {
        let toDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ endDate: toDateValue })
    }
    handleSortByTypeChange(e) {
        this.setState({
            sortByType: e.value
        });
    }
    handleRadVehicleChange(e) {
        if (e.target.value == "0") {
            this.setState({
                radVehicle: e.target.value,
                vehicleId: 0,
                vehicleName: "",
            });
        }
        else if (e.target.value == "1") {
            this.setState({
                radVehicle: e.target.value,
            });
        }
        this.setState({ radVehicle: e.target.value });
    }
    handleVehicleChange(e) {
        if (e !== null) {
            this.setState({
                vehicleId: (e.selectedItem != null ? e.selectedItem.id : 0),
                vehicleName: (e.selectedItem != null ? e.selectedItem.name : ""),
            })
        }
        else {
            this.setState({
                vehicleId: 0,
                vehicleName: "",
            });
        }
    }
    handleCheckBilledValueChanged(e) {
        this.setState({ checkBilled: e.value })
    }
    handleCheckPayoutGeneratedValueChanged(e) {
        this.setState({ checkPayoutGenerated: e.value })
    }
    handleCheckPaidValueChanged(e) {
        this.setState({ checksPaid: e.value })
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getCustomerList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isEmailSubmitted: false,
                isOpenModal: false,
                loadPanelVisible: false,
                startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
                endDate: new Date(),
                radVehicle: "0",
                vehicleId: 0,
                vehicleName: "",
                sortByList: sortByRadio,
                sortByType: sortByRadio[0],
                checkBilled: false,
                checkPayoutGenerated: false,
                checksPaid: false,
                errors: {
                    vehicleId: "",
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;

        errors.vehicleId = 0;

        if (this.state.radVehicle == "1") {
            if (this.state.vehicleId == 0) {
                formIsValid = false;
                errors.vehicleId = "Please select vehicle";
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    handleBillingReconsilationReportSubmit(e) {
        e.preventDefault();
        this.downloadBillingReconsilationAsPdf(e);
    }
    async downloadBillingReconsilationAsPdf(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            try {
                this.setState({
                    //  loadPanelVisible: true,
                    isSubmitted: true,
                });

                let reportHeader = "", showRides = "", selectionCriteria = "";
                if (this.state.radVehicle == "0") {
                    reportHeader = (reportHeader + (" For Date Range: "
                        + (formatDate(this.state.startDate, "MM/dd/yyyy") + (" - "
                            + (formatDate(this.state.endDate, "MM/dd/yyyy") + ", For All Vehicles")))));
                }
                else {
                    reportHeader = (reportHeader + (" For Date Range: "
                        + (formatDate(this.state.startDate, "MM/dd/yyyy") + (" - "
                            + ((formatDate(this.state.endDate, "MM/dd/yyyy") + (" , For Vehicle: " + this.state.vehicleName)))))));
                }

                if (this.state.checkBilled) {
                    showRides = (showRides + (((showRides != "") ? "" : ",") + "Billed"));
                }

                if (this.state.checkPayoutGenerated) {
                    showRides = (showRides + (((showRides != "") ? "" : ",") + "Payout Generated"));
                }

                if (this.state.checksPaid) {
                    showRides = (showRides + (((showRides != "") ? "" : ",") + "Check\'s Paid"));
                }

                if ((!this.state.checkBilled) && (!this.state.checkBilled) && (!this.state.checkBilled)) {
                    showRides = (showRides + (((showRides != "") ? "" : ",") + "All"));
                }
                reportHeader = (reportHeader + (((reportHeader != "") ? "" : ",") + (" Show " + (showRides + " Rides"))));

                if (this.state.checkBilled) {
                    selectionCriteria = (selectionCriteria + (((selectionCriteria != "") ? " and " : "") + " {proc_VehiclePaySummaryReport;1.Invoice_No} > 0 "));
                }

                if (this.state.checkPayoutGenerated) {
                    selectionCriteria = (selectionCriteria + (((selectionCriteria != "") ? " and " : "") + " {proc_VehiclePaySummaryReport;1.PayoutNo} > 0 "));
                }

                if (this.state.checksPaid) {
                    selectionCriteria = (selectionCriteria + (((selectionCriteria != "") ? " and " : "") + " {proc_VehiclePaySummaryReport;1.Check_no} > '0' "));
                }

                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.fileFormat = 'pdf';
                request.MethodName = "GetBillingReconsilation";
                request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy");
                request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy");
                request.VehicleNo = this.state.vehicleName;
                request.SortBy = this.state.sortByType.id.toString();
                request.Header = reportHeader;
                request.SelectionCrieteria = selectionCriteria;

                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({
                    // loadPanelVisible: false,
                    isSubmitted: false,
                });
                let a = document.createElement('a');
                a.href = dataResponse.reportBase64String;
                a.download = 'BillingReconsilation.pdf';
                a.click();

            } catch (e) {

            }
        }
    }
    handleClearSearch() {
        this.setState({
            isSubmitted: false,
            isEmailSubmitted: false,
            isOpenModal: true,
            loadPanelVisible: false,
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),
            radVehicle: "0",
            vehicleId: 0,
            vehicleName: "",
            sortByList: sortByRadio,
            sortByType: sortByRadio[0],
            checkBilled: false,
            checkPayoutGenerated: false,
            checksPaid: false,
            errors: {
                vehicleId: "",
            }
        });
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isEmailSubmitted: true });
                let reportHeader = "", showRides = "", selectionCriteria = "";
                if (this.state.radVehicle == "0") {
                    reportHeader = (reportHeader + (" For Date Range: "
                        + (formatDate(this.state.startDate, "MM/dd/yyyy") + (" - "
                            + (formatDate(this.state.endDate, "MM/dd/yyyy") + ", For All Vehicles")))));
                }
                else {
                    reportHeader = (reportHeader + (" For Date Range: "
                        + (formatDate(this.state.startDate, "MM/dd/yyyy") + (" - "
                            + ((formatDate(this.state.endDate, "MM/dd/yyyy") + (" , For Vehicle: " + this.state.vehicleName)))))));
                }

                if (this.state.checkBilled) {
                    showRides = (showRides + (((showRides != "") ? "" : ",") + "Billed"));
                }

                if (this.state.checkPayoutGenerated) {
                    showRides = (showRides + (((showRides != "") ? "" : ",") + "Payout Generated"));
                }

                if (this.state.checksPaid) {
                    showRides = (showRides + (((showRides != "") ? "" : ",") + "Check\'s Paid"));
                }

                if ((!this.state.checkBilled) && (!this.state.checkBilled) && (!this.state.checkBilled)) {
                    showRides = (showRides + (((showRides != "") ? "" : ",") + "All"));
                }
                reportHeader = (reportHeader + (((reportHeader != "") ? "" : ",") + (" Show " + (showRides + " Rides"))));

                if (this.state.checkBilled) {
                    selectionCriteria = (selectionCriteria + (((selectionCriteria != "") ? " and " : "") + " {proc_VehiclePaySummaryReport;1.Invoice_No} > 0 "));
                }

                if (this.state.checkPayoutGenerated) {
                    selectionCriteria = (selectionCriteria + (((selectionCriteria != "") ? " and " : "") + " {proc_VehiclePaySummaryReport;1.PayoutNo} > 0 "));
                }

                if (this.state.checksPaid) {
                    selectionCriteria = (selectionCriteria + (((selectionCriteria != "") ? " and " : "") + " {proc_VehiclePaySummaryReport;1.Check_no} > '0' "));
                }

                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.fileFormat = 'pdf';
                request.MethodName = "GetBillingReconsilation";
                request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy");
                request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy");
                request.VehicleNo = this.state.vehicleName;
                request.SortBy = this.state.sortByType.id.toString();
                request.Header = reportHeader;
                request.SelectionCrieteria = selectionCriteria;

                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Billing & Reconsilation Report';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = 'BillingReconsilation.pdf'
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '50%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleBillingReconsilationReportSubmit}
                        style={(this.state.isSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Billing & Reconsilation Report'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">From Ride Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.startDate}
                                            value={this.state.startDate}
                                            onValueChanged={this.handleFromDateChanged}
                                            width="100%"
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>To Ride Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.endDate}
                                            value={this.state.endDate}
                                            onValueChanged={this.handleToDateChanged}
                                            width="100%"
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radVehicle" value="0" checked={this.state.radVehicle === "0"} onChange={this.handleRadVehicleChange} />
                                            <label className="form-check-label">All Vehicle</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radVehicle" value="1" checked={this.state.radVehicle === "1"} onChange={this.handleRadVehicleChange} />
                                            <label className="form-check-label">Single Vehicle</label>
                                        </span>
                                        <SelectBox
                                            dataSource={this.state.vehicleList.length > 0 ? this.state.vehicleList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select vehicle"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.vehicleId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleVehicleChange}
                                            showClearButton={true}
                                            disabled={this.state.radVehicle === "1" ? false : true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["vehicleId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["vehicleId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Sort By</label>
                                        <RadioGroup id="radio-group-sortby" dataSource={this.state.sortByList} displayExpr="name" value={this.state.sortByType} onValueChanged={this.handleSortByTypeChange} layout="horizontal" />
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <CheckBox defaultValue={false} width={100} text="Billed" value={this.state.checkBilled} onValueChanged={this.handleCheckBilledValueChanged} />
                                        <CheckBox defaultValue={false} width={150} text="Payout Generated" value={this.state.checkPayoutGenerated} onValueChanged={this.handleCheckPayoutGeneratedValueChanged} />
                                        <CheckBox defaultValue={false} width={100} text="Checks Paid" value={this.state.checksPaid} onValueChanged={this.handleCheckPaidValueChanged} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="submit" className="btn btn-success btn-sm" title="Download" onClick={this.handleBillingReconsilationReportSubmit} >
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                                </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}