import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import { SelectBox } from 'devextreme-react/select-box';
import DatePicker from "react-datepicker"; // npm install react - datepicker --save
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions';
import { DateFormat } from '../../helpers/fixcodes';
import { ReservationHistoryComponent } from '../Shared/ReservationHistoryComponent';
import { InvoiceRidesComponent } from '../Shared/InvoiceRidesComponent';
import Swal from 'sweetalert2';
import { LoadPanel } from 'devextreme-react/load-panel';

const position = { of: '#invoicediv' };
const customStyles = {
    content: {
        width: '40%',
    }
};

let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
let currencyIcon = sessionStorage.getItem("session_currencyIcon") === "" ? "fas fa-dollar-sign" : sessionStorage.getItem("session_currencyIcon");

export class GenerateInvoiceComponent extends Component {

    constructor(props) {
        super(props);
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        this.state = {
            isSubmited: false,
            isDraftSubmited: false,
            isApprovedSubmited: false,
            datasource: [],
            fromDateTime: new Date((currentTime.getMonth() + 1) + '/01/' + currentTime.getFullYear()),
            toDateTime: null,
            billingCycleId: '2',
            billingCycleList: [],
            customerTypeId: 0,
            customerSelection: '2',
            customerList: [],
            customerId: 0,
            isFromDate: true,
            toDateArray: [],
            totalFare: 0.00,
            creditAmount: 0.00,
            totalBillable: 0.00

        }

        this.getcustomerList = this.getcustomerList.bind(this);
        this.getCustomerTypeList = this.getCustomerTypeList.bind(this);
        this.getBillingCycleList = this.getBillingCycleList.bind(this);
        this.getToBeGenerateInvoiceList = this.getToBeGenerateInvoiceList.bind(this);

        this.handleSearchInvoiceSubmit = this.handleSearchInvoiceSubmit.bind(this);
        this.handleSaveInvoice = this.handleSaveInvoice.bind(this);
        this.handelToolbarPreparing = this.handelToolbarPreparing.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFromValueChanged = this.handleFromValueChanged.bind(this);
        this.handleToValueChanged = this.handleToValueChanged.bind(this);

        this.openRideDetailsModel = this.openRideDetailsModel.bind(this);

        this.handleCustomerSelectionChange = this.handleCustomerSelectionChange.bind(this);
        this.handleBillingCycleChange = this.handleBillingCycleChange.bind(this);
        this.handleCustomerTypeChange = this.handleCustomerTypeChange.bind(this);
    }
    handelToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getToBeGenerateInvoiceList.bind(this)
            }
        });
    }

    async handleSearchInvoiceSubmit(e) {
        e.preventDefault();
        await this.getToBeGenerateInvoiceList();

    }

    handleFromValueChanged(date) {
        var dt = formatDate(date, "MM/dd/yyyy HH:mm");
        this.setState({ fromDateTime: date });
        this.getToDateArray(date);
    }
    handleToValueChanged(date) {
        var dt = formatDate(date, "MM/dd/yyyy HH:mm");
        this.setState({ toDateTime: date });
    }

    handleCustomerSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ customerSelection: value });
        if (value === "2") {
            this.setState({ customerId: 0 });
        } else {
            this.setState({ customerId: this.state.customerList.length > 0 ? this.state.customerList[0].id : [] });
        }

    }

    async handleBillingCycleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
        await this.getcustomerList(this.state.customerTypeId, parseInt(value));
    }

    async handleCustomerTypeChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
        await this.getcustomerList(parseInt(value), this.state.billingCycleId);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name == "quoteId") {
            const re = /^[0-9\b]+$/;
            if (event.target.value === '' || re.test(event.target.value)) {
                this.setState({ [name]: event.target.value })
            }
        } else {
            this.setState({
                [name]: value
            });
        }
    }

    async getcustomerList(customerTypeId, billingCycleId) {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('account-api/customer-dropdown/', requestParams);
        const data = await response.json();

        if (data.customers != null && data.customers.length > 0) {

            if (customerTypeId > 0 && billingCycleId == 0) {
                var customerArray = data.customers.filter(f => f.customerTypeId == customerTypeId);
                this.setState({
                    customerList: customerArray,
                    customerId: customerArray.length > 0 ? customerArray[0].id : 0
                });
            } else if (customerTypeId == 0 && billingCycleId > 0) {
                var customerArray = data.customers.filter(f => f.billingCycleId == billingCycleId);
                this.setState({
                    customerList: customerArray,
                    customerId: customerArray.length > 0 ? customerArray[0].id : 0
                });
            } else if (customerTypeId > 0 && billingCycleId > 0) {
                var customerArray = data.customers.filter(f => f.customerTypeId == customerTypeId).filter(f => f.billingCycleId == billingCycleId);
                this.setState({
                    customerList: customerArray,
                    customerId: customerArray.length > 0 ? customerArray[0].id : 0
                });
            } else {
                this.setState({
                    customerList: data.customers,
                    customerId: data.customers.length > 0 ? data.customers[0].id : 0
                });
            }
        }

        if (this.state.customerSelection === "2") {
            this.setState({
                customerId: 0
            });
        }
    }

    async getBillingCycleList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('billingCycle-api/', requestParams);

        const data = await response.json();
        if (data !== null && data.billingCycle.length > 0) {
            this.setState({
                billingCycleList: data.billingCycle
            });
        }
    }

    async getCustomerTypeList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('customer-type-api/', requestParams);
        const data = await response.json();
        if (data !== null && data.customerType.length > 0) {
            this.setState({
                customerTypeList: data.customerType
            });
        }
    }

    handleCustomerInputChange = (data) => {
        this.setState({ customerId: (data.selectedItem != null ? data.selectedItem.id : 0), })

    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true,
        });
        await this.getBillingCycleList();
        await this.getcustomerList(this.state.customerTypeId, this.state.billingCycleId);
        await this.getCustomerTypeList();
        this.getToDateArray(this.state.fromDateTime);
        if (this.props.location.state != undefined) {

            this.setState({
                fromDateTime: this.props.location.state.fromDateTime,
                toDateTime: this.props.location.state.toDateTime,
                billingCycleId: this.props.location.state.billingCycleId,
                customerTypeId: this.props.location.state.customerTypeId,
                customerSelection: this.props.location.state.customerSelection,
                customerId: this.props.location.state.customerId,
                isFromDate: this.props.location.state.isFromDate,
            });

            let myVar = setTimeout(() => this.getToBeGenerateInvoiceList(), 500)
        }
        this.setState({
            loadPanelVisible: false,
        });
    }

    diffDays(d1, d2) {
        var ndays;
        var tv1 = d1.valueOf();  // msec since 1970
        var tv2 = d2.valueOf();

        ndays = (tv2 - tv1) / 1000 / 86400;
        ndays = Math.round(ndays - 0.5);
        return ndays;
    }

    getToDateArray(fromDate) {
        var stpuDate = new Date(new Date(fromDate).toLocaleDateString());
        var enDate = new Date(new Date(currentTime).toLocaleDateString());
        var dayscount = this.diffDays(stpuDate, enDate) + 1;
        var dates = [];
        for (var day = 1; day <= dayscount; day++) {

            if (stpuDate.getDate() == 15) {
                dates.push(new Date(stpuDate));
            }
            if (stpuDate.getDate() == 28 || stpuDate.getDate() == 29 || stpuDate.getDate() == 30 || stpuDate.getDate() == 31) {
                stpuDate = new Date(stpuDate.getFullYear(), stpuDate.getMonth() + 1, 0)
                if (stpuDate < new Date(new Date(currentTime).toLocaleDateString())) {
                    dates.push(new Date(stpuDate));
                }
            }
            stpuDate.setDate(stpuDate.getDate() + 1);
        }

        this.setState({ toDateArray: dates });
    }

    async getToBeGenerateInvoiceList() {

        if (this.state.customerList.length <= 0) {
            await Swal.fire({
                icon: 'success',
                title: '<small>Invoice Generation</small>',
                text: "Customer not found or selected to generate invoice.",
                width: '25%',
                allowOutsideClick: false
            });
            return;
        }

        if ((this.state.fromDateTime == null) || this.state.toDateTime == null) {
            await Swal.fire({
                icon: 'success',
                title: '<small>Invoice Generation</small>',
                text: "Please select date range to generate invoice.",
                width: '25%',
                allowOutsideClick: false
            });
            return;
        }

        var selectedObj = "";
        if (this.state.customerSelection === "1") {
            selectedObj = this.state.customerId.toString();
        } else {
            for (let i = 0; i < this.state.customerList.length; i++) {
                selectedObj = selectedObj + this.state.customerList[i].id + ",";
            }
            selectedObj = selectedObj.replace(/,\s*$/, "");
        }
      
        var request = new Object();
        request.CustomerIdStr = selectedObj;
        request.StartDate = this.state.fromDateTime.toLocaleDateString(); //new Date(2020, 9, 1);
        request.EndDate = this.state.toDateTime.toLocaleDateString(); //new Date(2020, 9, 31);
        request.InvoiceDate = this.state.toDateTime.toLocaleDateString();
        request.IsApplyFromDate = this.state.isFromDate;

        this.setState({ isSubmited: true });

        try {
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('/generate-invoice-api/to-be-generate/list', requestParams);
            const data = await response.json();
            this.setState({
                datasource: data.toBeGenerateInvoice == null ? [] : data.toBeGenerateInvoice
            });

            if (data.toBeGenerateInvoice != null && data.toBeGenerateInvoice.length <= 0) {
                await Swal.fire({
                    icon: 'success',
                    title: '<small>Invoice Generation</small>',
                    text: "Rides not found to generate invoice.",
                    width: '25%',
                    allowOutsideClick: false
                });
                this.setState({ isSubmited: false });
            } else {
                var totalFare = 0.00, creditAmount = 0.00, totalBillable = 0.00;
                for (var i = 0; i < data.toBeGenerateInvoice.length; i++) {
                    totalFare = totalFare + data.toBeGenerateInvoice[i].rideFare;
                    creditAmount = creditAmount + data.toBeGenerateInvoice[i].creditAmount;
                    totalBillable = totalBillable + data.toBeGenerateInvoice[i].billableFare;
                }
                this.setState({ isSubmited: false });
            }
            this.setState({ totalFare: totalFare, creditAmount: creditAmount, totalBillable: totalBillable });

        } catch (e) {
            this.setState({ isSubmited: false });
        }
    }

    handleClearSearch() {
        this.setState({
            isSubmited: false,
            isDraftSubmited: false,
            isApprovedSubmited: false,
            datasource: [],
            fromDateTime: new Date((currentTime.getMonth() + 1) + '/01/' + currentTime.getFullYear()),
            toDateTime: null,
            billingCycleId: '2',
            customerTypeId: 0,
            customerSelection: '2',
            customerId: 0,
            isFromDate: true,
            totalFare: 0.00,
            creditAmount: 0.00,
            totalBillable: 0.00
        });
    }

    async askGenerateInvoiceConfirm(isApproved) {
        let isResult = false;
        var strMsg = "";
        if (isApproved) {
            if (this.state.customerSelection === "1") {
                strMsg = "You are going to generate invoice as approved for single customer.<br> Do you to want to generate invoice then press 'Yes'?";
            } else {
                strMsg = "You are going to generate invoice as approved for all customer.<br> Do you to want to generate invoice then press 'Yes'?";
            }
        } else {
            if (this.state.customerSelection === "1") {
                strMsg = "You are going to generate invoice as draft for single customer.<br> Do you to want to generate invoice then press 'Yes'?";
            } else {
                strMsg = "You are going to generate invoice as draft for all customer.<br> Do you to want to generate invoice then press 'Yes'?";
            }
        }
        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: strMsg,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    async handleSaveInvoice(e, isApproved) {
        e.preventDefault();
        if (this.state.customerList.length <= 0) {
            await Swal.fire({
                icon: 'success',
                title: '<small>Invoice Generation</small>',
                text: "Customer not found or selected to generate invoice.",
                width: '25%',
                allowOutsideClick: false
            });
            return;
        }

        if ((this.state.fromDateTime == null) || this.state.toDateTime == null) {
            await Swal.fire({
                icon: 'success',
                title: '<small>Invoice Generation</small>',
                text: "Please select date range to generate invoice.",
                width: '25%',
                allowOutsideClick: false
            });
            return;
        }


        const isConfirm = await this.askGenerateInvoiceConfirm();
        if (!isConfirm) {
            return;
        }

        if (isApproved == true) {
            this.setState({ isApprovedSubmited: true });
        } else {
            this.setState({ isDraftSubmited: true });
        }

        var totalSucccess = 0;
        var totalFailed = 0;
        var customerCount = 0;

        if (this.state.customerSelection === "1") {
            try {
                customerCount = customerCount + 1;
                var request = new Object();
                request.CustomerId = parseInt(this.state.customerId);
                request.StartDate = this.state.fromDateTime.toLocaleDateString(); //new Date(2020, 9, 1);
                request.EndDate = this.state.toDateTime.toLocaleDateString(); //new Date(2020, 9, 31);
                request.InvoiceDate = this.state.toDateTime.toLocaleDateString();
                request.IsApplyFromDate = this.state.isFromDate;
                request.IsApprovedInvoice = isApproved;

                const requestParams = getRequestParams('POST', request);
                const response = await fetch('/generate-invoice-api', requestParams);
                const data = await response.json();
                if (data.retValue > 0) {
                    totalSucccess = totalSucccess + 1;
                } else if (data.retValue < 0) {
                    totalFailed = totalFailed + 1;
                }

            } catch (e) {
                totalFailed = totalFailed + 1;
            }

        } else {

            for (let i = 0; i < this.state.customerList.length; i++) {

                try {
                    customerCount = customerCount + 1;
                    var request = new Object();
                    request.CustomerId = parseInt(this.state.customerList[i].id);
                    request.StartDate = this.state.fromDateTime.toLocaleDateString(); //new Date(2020, 9, 1);
                    request.EndDate = this.state.toDateTime.toLocaleDateString(); //new Date(2020, 9, 31);
                    request.InvoiceDate = this.state.toDateTime.toLocaleDateString();
                    request.IsApplyFromDate = this.state.isFromDate;
                    request.IsApprovedInvoice = isApproved;

                    const requestParams = getRequestParams('POST', request);
                    const response = await fetch('/generate-invoice-api', requestParams);
                    const data = await response.json();
                    if (data.retValue > 0) {
                        totalSucccess = totalSucccess + 1;
                    } else if (data.retValue < 0) {
                        totalFailed = totalFailed + 1;
                    }

                } catch (e) {
                    totalFailed = totalFailed + 1;
                }
            }
        }
       

        var totalInvoice = totalSucccess + totalFailed;
        var resultMsg = "Total number of selected customer(s) [" + customerCount + "].<br> Total number of invoice(s) tried to generate [" + totalInvoice + "].<br> Success invoice count [" + totalSucccess + "].<br> Failed invoice count [" + totalFailed + "].";

        Swal.fire({
            icon: totalFailed == 0 ? 'success' : 'error',
            title: totalFailed == 0 ? 'Success' : 'Oops...',
            html: resultMsg,
            confirmButtonText: 'Thank you!',
            allowOutsideClick: false
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (totalFailed == 0) {
                if (result.isConfirmed) {
                    if (isApproved == true) {
                        this.setState({ isApprovedSubmited: false });
                    } else {
                        this.setState({ isDraftSubmited: false });
                    }
                    this.handleClearSearch();
                }
            } else {
                if (isApproved == true) {
                    this.setState({ isApprovedSubmited: false });
                } else {
                    this.setState({ isDraftSubmited: false });
                }
            }
        })
    }

    openRideDetailsModel(e, data) {
        e.preventDefault();
        this.refs.invoiceRidesModal.showModal(data.customerId, this.state.fromDateTime.toLocaleDateString(), this.state.toDateTime.toLocaleDateString(), this.state.isFromDate, 0);
    }


    render() {

        const filterSearchFromDate = time => {
            const currentDate = currentTime;
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }

        const filterSearchToDate = time => {
            const currentDate = currentTime;
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }

        const renderGridCell = (data) => {
            return <div>
                <a href="#" className="fas fa-list icon text-info" title="Ride Details" onClick={(e) => { this.openRideDetailsModel(e, data.data) }}></a>&nbsp;&nbsp;
            </div>;
        }
        const renderGridCurrencyCell = (data) => {
            return <div><i className={currencyIcon}></i>&nbsp;{parseFloat(data.value).toFixed(2)}</div>;
        }

        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <h1 className="m-0 text-dark">Generate Invoice</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem><NavLink tag={Link} to="/invoice/draft" className="btn btn-block btn-outline-secondary btn-sm" title="Go to Draft Invoice"><i className="fas fa-tasks"></i> Go to Draft Invoice</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/invoice/approved" className="btn btn-block btn-outline-secondary btn-sm" title="Go to Approved Invoice"><i className="fas fa-check"></i> Go to Approved Invoice</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <form method="post" onSubmit={this.handleSearchInvoiceSubmit} style={(this.state.isSubmited || this.state.isDraftSubmited || this.state.isApprovedSubmited) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="rez-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>Billing Cycle</label>
                                        <select name="billingCycleId" className="form-control form-control-sm" value={this.state.billingCycleId} onChange={this.handleBillingCycleChange}>
                                            {
                                                this.state.billingCycleList &&
                                                this.state.billingCycleList.map((item, i) =>
                                                    (<option key={i} value={item.id}>{item.name}</option>))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="checkbox" name="isFromDate" checked={this.state.isFromDate} onChange={this.handleInputChange} />
                                            <label className="form-check-label">Billing Start Date</label>
                                        </span>
                                        <DatePicker
                                            className="form-control form-control-sm "
                                            placeholderText="Select date & time"
                                            name="fromDateTime"
                                            selected={this.state.fromDateTime}
                                            dateFormat="MM/dd/yyyy"
                                            onChange={this.handleFromValueChanged}
                                            filterTime={filterSearchFromDate}
                                            maxDate={currentTime}
                                            disabled={this.state.isFromDate ? false : true}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>Billing End Date</label>
                                        <DatePicker
                                            className="form-control form-control-sm "
                                            placeholderText="Select date & time"
                                            name="toDateTime"
                                            selected={this.state.toDateTime}
                                            dateFormat="MM/dd/yyyy"
                                            onChange={this.handleToValueChanged}
                                            filterTime={filterSearchToDate}
                                            //minDate={this.state.isFromDate == true ? this.state.fromDateTime : []}
                                            //maxDate={this.state.fromDateTime}
                                            includeDates={this.state.toDateArray}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>Customer Type</label>
                                        <select className="form-control form-control-sm" name="customerTypeId" placeholder="Customer Type" title="Customer Type" value={this.state.customerTypeId} onChange={this.handleCustomerTypeChange}>
                                            <option value={0}>Select customer type</option>
                                            {this.state.customerTypeList && this.state.customerTypeList.map((item, i) =>
                                                <option key={i} value={item.id}>{item.name}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="singleCustomer" value="1" checked={this.state.customerSelection === "1"} onChange={this.handleCustomerSelectionChange} />
                                            <label className="form-check-label">Single Customer</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="allCustomer" value="2" checked={this.state.customerSelection === "2"} onChange={this.handleCustomerSelectionChange} />
                                            <label className="form-check-label">All Customer</label>
                                        </span>
                                        <SelectBox
                                            dataSource={this.state.customerList.length > 0 ? this.state.customerList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="customerId"
                                            defaultValue={0}
                                            value={this.state.customerId}
                                            displayExpr='fullName'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleCustomerInputChange}
                                            showClearButton={this.state.customerSelection === "1" ? false : true}
                                            disabled={this.state.customerSelection === "1" ? false : true}
                                            searchEnabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-right">
                                    <div className="form-group">
                                        <label className="btn-block">&nbsp;</label>
                                        <button type="submit" className="btn btn-success btn-sm" onClick={this.handleSearchInvoiceSubmit} >
                                            {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSubmited && <span title="Search"><i className="fas fa-search"></i></span>}
                                        </button>&nbsp; &nbsp;
                                         <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleClearSearch} title="Clear"><i className="fas fa-eraser"></i></button>&nbsp; &nbsp;

                                         <button type="submit" className="btn btn-custom-gray btn-sm" onClick={(e) => { this.handleSaveInvoice(e, false) }} disabled={this.state.datasource.length > 0 ? false : true} title="Save as Draft">
                                            {this.state.isDraftSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isDraftSubmited && <span title=" Save as Draft"><i className="fas fa-save"></i> Save as Draft</span>}
                                        </button>&nbsp; &nbsp;
                                         <button type="submit" className="btn btn-custom-gray btn-sm" onClick={(e) => { this.handleSaveInvoice(e, true) }} disabled={this.state.datasource.length > 0 ? false : true} title="Save as Approved">
                                            {this.state.isApprovedSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isApprovedSubmited && <span title="Save as Approved"><i className="fas fa-check"></i> Save as Approved</span>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="card card-grid">
                                <div className="card-body">
                                    <DataGrid
                                        dataSource={this.state.datasource}
                                        keyExpr="rowId"
                                        selection={{ mode: 'single' }}
                                        columnsAutoWidth={false}
                                        showColumnLines={false}
                                        showRowLines={true}
                                        showBorders={true}
                                        rowAlternationEnabled={true}
                                        allowColumnResizing={true}
                                        columnResizingMode='widget'
                                        width='100%'
                                        onToolbarPreparing={this.handelToolbarPreparing}>
                                        <Column dataField="customerId" width={50} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                        <Column dataField="invoiceDate" caption="Invoice Date" width={150} allowFiltering={true} allowSorting={true} format={DateFormat.DefaultDateTimeFormat} dataType="datetime" />
                                        <Column dataField="customerName" caption="Customer" width={400} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="customerType" caption="Customer Type" width={150} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="locationName" caption="Customer Type" width={150} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="paxTotal" caption="Passenger Count" width={120} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="rideCount" caption="Ride Count" width={120} allowFiltering={true} allowSorting={true} />
                                        <Column dataField="baseFare" caption="Base Fare" width={120} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="grossFare" caption="Gross Fare" width={120} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="taxAmount" caption="Tax Amount" width={120} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="rideFare" caption="Ride Fare" width={120} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="creditAmount" caption="Credit Amount" width={120} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                        <Column dataField="billableFare" caption="Billable Fare" width={120} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />


                                        <SortByGroupSummaryInfo summaryItem="count" />
                                        <GroupPanel visible={true} />
                                        <HeaderFilter visible={true} />
                                        <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                        <Grouping autoExpandAll={true} />
                                        <Paging defaultPageSize={20} />
                                        <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                        <Export enabled={true} allowExportSelectedData={false} />
                                        <ColumnChooser enabled={true} />
                                        <ColumnFixing enabled={true} />
                                    </DataGrid>
                                </div>
                                {(this.state.datasource.length > 0) && <div className="row" style={{ marginLeft: 0 + 'px', marginRight: 0 + 'px' }} >
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div className="form-group">
                                            <label>Invoice Note</label>
                                            <textarea className="form-control" id="txtArea" rows="5" placeholder="Enter invoice note here.."></textarea>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div>
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ textAlign: 'right', width: '80%'  }}><strong>Total Ride Fare</strong></td>
                                                            <td style={{ textAlign: 'right', width: '100%'   }}><strong className="text-success"><i className={currencyIcon}></i>&nbsp;{this.state.totalFare}</strong></td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'right', width: '80%' }}><strong>Total Credit Amount</strong></td>
                                                            <td style={{ textAlign: 'right', width: '100%' }}><strong className="text-success"><i className={currencyIcon}></i>&nbsp;{this.state.creditAmount}</strong></td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'right', width: '80%' }}><strong>Total Billable Fare</strong></td>
                                                            <td style={{ textAlign: 'right', width: '100%' }}><strong className="text-success"><i className={currencyIcon}></i>&nbsp;{this.state.totalBillable}</strong></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </form>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
                <ReservationHistoryComponent ref="reservationHistoryModal" />
                <InvoiceRidesComponent ref="invoiceRidesModal" />
            </div>
        )

    }
}