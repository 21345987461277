import React, { Component } from 'react';
import { LoadPanel } from 'devextreme-react/load-panel';
import Modal from 'react-modal'; //npm install --save react-modal
import { getRequestParams } from '../../helpers/globalfunctions.js';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import { SelectBox } from 'devextreme-react/select-box';
import DateBox from 'devextreme-react/date-box';
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'
let currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class InvoicePaidReportCompnent extends Component {
    constructor() {
        currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        super()
        this.state = {
            isSubmitted: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            fromDate: moment(currentTime).format("MM/DD/YYYY"),
            toDate: moment(currentTime).format("MM/DD/YYYY"),
            isAllCustomerType: true,
            isSingleCustomerType: false,
            customerTypeDropdownList: [],
            customerTypeDropdownData: null,
            customerTypeId: 0,
            isAllCustomer: true,
            isSingleCustomer: false,
            customerDropdownList: [],
            customerDropdownData: null,
            customerId: 0,
            isSummaryReport: true,
            isDetailsReport: false,
            errors: {
                fromDate: '',
                toDate: '',
                customerType: '',
                customer: '',
                reportType: '',
            }
        }
    }
    getCustomerDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('account-api/customer-dropdown/', requestParams);
            const data = await response.json();
            this.setState({
                customerDropdownList: data.customers
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    getCustomerTypeDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('customer-type-api/', requestParams);
            const data = await response.json();
            this.setState({
                customerTypeDropdownList: data.customerType
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getCustomerTypeDropdownList();
            await this.getCustomerDropdownList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isEmailSubmitted: false,
                isOpenModal: false,
                loadPanelVisible: false,
                fromDate: moment(currentTime).format("MM/DD/YYYY"),
                toDate: moment(currentTime).format("MM/DD/YYYY"),
                isAllCustomerType: true,
                isSingleCustomerType: false,
                customerTypeDropdownList: [],
                customerTypeDropdownData: null,
                customerTypeId: 0,
                isAllCustomer: true,
                isSingleCustomer: false,
                customerDropdownList: [],
                customerDropdownData: null,
                customerId: 0,
                isSummaryReport: true,
                isDetailsReport: false,
                errors: {
                    fromDate: '',
                    toDate: '',
                    customerType: '',
                    customer: '',
                    reportType: '',
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnClearSearch = async () => {
        try {
            currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
            this.setState({
                isSubmitted: false,
                isEmailSubmitted: false,
                loadPanelVisible: false,
                fromDate: moment(currentTime).format("MM/DD/YYYY"),
                toDate: moment(currentTime).format("MM/DD/YYYY"),
                isAllCustomerType: true,
                isSingleCustomerType: false,
                customerTypeDropdownData: null,
                customerTypeId: 0,
                isAllCustomer: true,
                isSingleCustomer: false,
                customerDropdownData: null,
                customerId: 0,
                isSummaryReport: true,
                isDetailsReport: false,
                errors: {
                    fromDate: '',
                    toDate: '',
                    customerType: '',
                    customer: '',
                    reportType: '',
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnInputChange = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
            let errors = this.state.errors;
            switch (name) {
                case 'isAllCustomerType':
                    errors.customerType = '';
                    this.setState({ isAllCustomerType: value, isSingleCustomerType: false, customerTypeDropdownData: null, customerTypeId: 0 });
                    break;
                case 'isSingleCustomerType':
                    errors.customerType = '';
                    this.setState({ isAllCustomerType: false, isSingleCustomerType: value, customerTypeDropdownData: null, customerTypeId: 0 });
                    break;
                case 'isAllCustomer':
                    errors.customer = '';
                    this.setState({ isAllCustomer: value, isSingleCustomer: false, customerDropdownData: null, customerId: 0 });
                    break;
                case 'isSingleCustomer':
                    errors.customer = '';
                    this.setState({ isAllCustomer: false, isSingleCustomer: value, customerDropdownData: null, customerId: 0 });
                    break;
                case 'isSummaryReport':
                    errors.reportType = '';
                    errors.fromDate = '';
                    errors.toDate = '';
                    errors.customerType = '';
                    errors.customer = '';
                    this.setState({ isDetailsReport: false, isSummaryReport: value });
                    break;
                case 'isDetailsReport':
                    errors.reportType = '';
                    errors.fromDate = '';
                    errors.toDate = '';
                    errors.customerType = '';
                    errors.customer = '';
                    this.setState({ isDetailsReport: value, isSummaryReport: false });
                    break;
                default:
                    break;
            }
            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnFromDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.fromDate = '';
            let fromDate = '';
            if (event.value !== null && event.value !== '') {
                fromDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.fromDate = 'Please select date.';
            }
            if (this.state.toDate.trim() !== '') {
                errors.toDate = '';
            }
            this.setState({ fromDate: fromDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnToDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.toDate = '';
            let toDate = '';
            if (event.value !== null && event.value !== '') {
                toDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.toDate = 'Please select date.';
            }
            if (this.state.fromDate.trim() !== '') {
                errors.fromDate = '';
            }
            this.setState({ toDate: toDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnCustomerDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.customer = '';
            let customerId = 0;
            let customerDropdownData = null;
            if (data !== null && data.selectedItem !== null) {
                customerId = (data.selectedItem !== null ? data.selectedItem.id : 0);
                customerDropdownData = data.selectedItem;
            }
            else {
                //errors.customer = 'Please select customer.';
            }
            this.setState({ customerDropdownData: customerDropdownData, customerId: customerId, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnCustomerTypeDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.customerType = '';
            let customerTypeId = 0;
            let customerTypeDropdownData = null;
            if (data !== null && data.selectedItem !== null) {
                customerTypeId = (data.selectedItem !== null ? data.selectedItem.id : 0)
                customerTypeDropdownData = data.selectedItem;
            }
            else {
                //errors.pickupZone = 'Please select customer type.';
            }
            this.setState({ customerTypeDropdownData: customerTypeDropdownData, customerTypeId: customerTypeId, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnReportSubmit = async (event) => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isSubmitted: true });
                let fromDate = '';
                let toDate = '';
                let reportType = 0;
                if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                    fromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00';
                    toDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59';
                }
                if (this.state.isDetailsReport === true) {
                    reportType = 1;
                }
                if (this.state.isSummaryReport === true) {
                    reportType = 2;
                }
                var request = {};
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.StartDate = fromDate;
                request.EndDate = toDate;
                request.CustomerTypeId = 0;
                request.CustomerId = 0;
                if (this.state.isSingleCustomerType === true) {
                    request.CustomerTypeId = Number(this.state.customerTypeId);
                }
                if (this.state.isSingleCustomer === true) {
                    request.CustomerId = Number(this.state.customerId);
                }
                request.ReportType = Number(reportType);
                request.fileFormat = 'pdf';
                request.MethodName = "GetInvoicePaidReport";
                request.SelectionCrieteria = await this.setSelectionCrieteria();
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                let a = document.createElement('a');
                a.href = dataResponse.reportBase64String;
                a.download = (reportType === 1) ? 'InvoicePaidDetails.pdf' : 'InvoicePaidSummary.pdf';
                a.click();
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false });
    }
    isFormValid = async () => {
        let isValid = true;
        try {
            let errors = this.state.errors;
            errors.fromDate = '';
            errors.toDate = '';
            errors.customerType = '';
            errors.customer = '';
            errors.reportType = '';
            if (this.state.fromDate.trim() === '') {
                errors.fromDate = 'Please select date.';
                isValid = false;
            }
            if (this.state.toDate.trim() === '') {
                errors.toDate = 'Please select date.';
                isValid = false;
            }
            if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                if (moment(this.state.fromDate, 'MM/DD/YYYY').isAfter(this.state.toDate, 'MM/DD/YYYY')) {
                    errors.fromDate = 'Please enter a valid date range.';
                    errors.toDate = 'Please enter a valid date range.';
                    isValid = false;
                }
            }
            if (this.state.isSingleCustomerType === true) {
                if (Number(this.state.customerTypeId) === 0 || isNaN(this.state.customerTypeId)) {
                    errors.customerType = 'Please select customer type.';
                    isValid = false;
                }
            }
            if (this.state.isSingleCustomer === true) {
                if (Number(this.state.customerId) === 0 || isNaN(this.state.customerId)) {
                    errors.customer = 'Please select customer.';
                    isValid = false;
                }
            }
            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    setSelectionCrieteria = async () => {
        let selectionCrieteria = 'Invoice paid for';
        try {
            if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                selectionCrieteria += ' period from  - ' + this.state.fromDate + ' to ' + this.state.toDate;
            }
            else {
                selectionCrieteria += ' all periods';
            }
            if (this.state.isSingleCustomerType === true && Number(this.state.customerTypeId) > 0) {
                if (this.state.customerTypeDropdownData !== null && Object.keys(this.state.customerTypeDropdownData).length > 0) {
                    selectionCrieteria += ', customer type - [ ' + this.state.customerTypeDropdownData.name + ' ]';
                }
            }
            else {
                selectionCrieteria += ', all customer type';
            }
            if (this.state.isSingleCustomer === true && Number(this.state.customerId) > 0) {
                if (this.state.customerDropdownData !== null && Object.keys(this.state.customerDropdownData).length > 0) {
                    selectionCrieteria += ', customer - [ ' + this.state.customerDropdownData.fullName + ' ]';
                }
            }
            else {
                selectionCrieteria += ', all customer';
            }
        } catch (ex) {
            selectionCrieteria = '';
            console.log(ex);
        }
        return selectionCrieteria;
    }
    handleOnSendEmail = async event => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isEmailSubmitted: true });
                let fromDate = '';
                let toDate = '';
                let reportType = 0;
                if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                    fromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00';
                    toDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59';
                }
                if (this.state.isDetailsReport === true) {
                    reportType = 1;
                }
                if (this.state.isSummaryReport === true) {
                    reportType = 2;
                }
                var request = {};
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.StartDate = fromDate;
                request.EndDate = toDate;
                request.CustomerTypeId = 0;
                request.CustomerId = 0;
                if (this.state.isSingleCustomerType === true) {
                    request.CustomerTypeId = Number(this.state.customerTypeId);
                }
                if (this.state.isSingleCustomer === true) {
                    request.CustomerId = Number(this.state.customerId);
                }
                request.ReportType = Number(reportType);
                request.fileFormat = 'pdf';
                request.MethodName = "GetInvoicePaidReport";
                request.SelectionCrieteria = await this.setSelectionCrieteria();
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;

                if (fileData !== null && fileData !== '' && isNaN(fileData) === false) {
                    currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
                    let emailSubject = (reportType === 1) ? 'Invoice Paid - ' : 'Invoice Summary - ' + moment(currentTime).format("DD MMMM YYYY HH:mm");
                    let attachments = [];
                    let file = {};
                    file.fileData = fileData;
                    file.fileName = (reportType === 1) ? 'InvoicePaidDetails.pdf' : 'InvoicePaidSummary.pdf';
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '45%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleOnReportSubmit}
                        style={(this.state.isSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Invoice Paid'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-10">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                id="isAllCustomerType" name="isAllCustomerType"
                                                checked={this.state.isAllCustomerType}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isAllCustomerType"> All Customer Type</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                id="isSingleCustomerType" name="isSingleCustomerType"
                                                checked={this.state.isSingleCustomerType}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isSingleCustomerType"> Single Customer Type</label>
                                        </div>
                                        <SelectBox
                                            dataSource={this.state.customerTypeDropdownList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer type"
                                            name="ddcustomertype"
                                            defaultValue={0}
                                            value={this.state.customerTypeId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnCustomerTypeDropdownChange}
                                            showClearButton={true}
                                            disabled={this.state.isAllCustomerType}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerType"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["customerType"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                id="isAllCustomer" name="isAllCustomer"
                                                checked={this.state.isAllCustomer}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isAllCustomer"> All Customer</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                id="isSingleCustomer" name="isSingleCustomer"
                                                checked={this.state.isSingleCustomer}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isSingleCustomer"> Single Customer</label>
                                        </div>
                                        <SelectBox
                                            dataSource={this.state.customerDropdownList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="ddcustomer"
                                            defaultValue={0}
                                            value={this.state.customerId}
                                            displayExpr='fullName'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnCustomerDropdownChange}
                                            showClearButton={true}
                                            disabled={this.state.isAllCustomer}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customer"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["customer"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>From Date (Invoice)</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="fromDate"
                                            placeholder="MM/dd/yyyy"
                                            defaultValue={this.state.fromDate}
                                            value={this.state.fromDate}
                                            onValueChanged={this.handleOnFromDateChange}
                                            width={'100%'}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["fromDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["fromDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>To Date (Invoice)</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="toDate"
                                            placeholder="MM/dd/yyyy"
                                            defaultValue={this.state.toDate}
                                            value={this.state.toDate}
                                            min={this.state.fromDate}
                                            onValueChanged={this.handleOnToDateChange}
                                            width={'100%'}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["toDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["toDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Report Type</label><br />
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isSummaryReport"
                                                checked={this.state.isSummaryReport}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isSummaryReport">Summary</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isDetailsReport"
                                                checked={this.state.isDetailsReport}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isDetailsReport">Details</label>
                                        </div>
                                        {this.state.errors["reportType"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["reportType"]}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="submit" className="btn btn-success btn-sm" title="Download">
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleOnClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                             </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}