import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import Swal from 'sweetalert2';

export class ResetPasswordComponent extends Component {
    static displayName = ResetPasswordComponent.name;

    constructor(props) {
        super(props);
        const queryString = require('query-string');
        var parsed = queryString.parse(this.props.location.search);
        this.state = {
            isDisabled: true,
            requestId: parsed.requestId,
            type: 'password',
            retype: 'password',
            newPassword: '',
            reTypePassword: '',
            userId: 0,
            errors: {
                errNewPassword: '',
                errReTypePassword: '',
            }
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.showHide = this.showHide.bind(this);
        this.pwdShowHide = this.pwdShowHide.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;

        errors.errOldPassword = "";
        errors.errNewPassword = "";
        errors.errReTypePassword = "";

        if (this.state.oldPassword == '') {
            formIsValid = false;
            errors.errOldPassword = "Please enter old password.";
        }

        if (this.state.newPassword == '') {
            formIsValid = false;
            errors.errNewPassword = "Please enter new password.";
        }

        if (this.state.reTypePassword == '') {
            formIsValid = false;
            errors.errReTypePassword = "Please enter re-Type password.";
        }

        if (this.state.newPassword != this.state.reTypePassword) {
            formIsValid = false;
            errors.errReTypePassword = "Password does not match.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    showHide(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            type: this.state.type === 'input' ? 'password' : 'input',
            errNewPassword: '',
            // errReTypePassword: '',
        })
    }

    pwdShowHide(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({            
            retype: this.state.retype === 'input' ? 'password' : 'input',
            errReTypePassword: '',
        })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name == "newPassword" || name == "reTypePassword") {
            var elementpwdlength = document.getElementById("pwdlength");
            var elementpwdlowercase = document.getElementById("pwdlowercase");
            var elementpwduppercase = document.getElementById("pwduppercase");
            var elementpwdnumbersymbol = document.getElementById("pwdnumbersymbol");
            var elementspecialcharacters = document.getElementById("specialcharacters");

            const renumbersymbolexp = /[0-9]/;
            const lowercaseexp = /[a-z]/;
            const uppercaseexp = /[A-Z]/;
            const specialcharacters = /[!@#$%^&*]/;

            var IsPwdnumbersymbol = renumbersymbolexp.test(value);
            var IsLowercase = lowercaseexp.test(value);
            var IsUppercase = uppercaseexp.test(value);
            var IsSpecialCharacters = specialcharacters.test(value);

            if (value.length > 8) {
                elementpwdlength.classList.remove("text-muted");
                elementpwdlength.classList.add("text-success");
            }
            else {
                elementpwdlength.classList.remove("text-success");
                elementpwdlength.classList.add("text-muted");
            }

            if (IsPwdnumbersymbol) {
                elementpwdnumbersymbol.classList.remove("text-muted");
                elementpwdnumbersymbol.classList.add("text-success");
            }
            else {
                elementpwdnumbersymbol.classList.remove("text-success");
                elementpwdnumbersymbol.classList.add("text-muted");
            }

            if (IsLowercase) {
                elementpwdlowercase.classList.remove("text-muted");
                elementpwdlowercase.classList.add("text-success");
            }
            else {
                elementpwdlowercase.classList.remove("text-success");
                elementpwdlowercase.classList.add("text-muted");
            }

            if (IsUppercase) {
                elementpwduppercase.classList.remove("text-muted");
                elementpwduppercase.classList.add("text-success");
            }
            else {
                elementpwduppercase.classList.remove("text-success");
                elementpwduppercase.classList.add("text-muted");
            }

            if (IsSpecialCharacters) {
                elementspecialcharacters.classList.remove("text-muted");
                elementspecialcharacters.classList.add("text-success");
            }
            else {
                elementspecialcharacters.classList.remove("text-success");
                elementspecialcharacters.classList.add("text-muted");
            }

            if (value == "") {
                elementpwdlength.classList.remove("text-success");
                elementpwdlength.classList.add("text-muted");
                elementpwdnumbersymbol.classList.remove("text-success");
                elementpwdnumbersymbol.classList.add("text-muted");
                elementpwdlowercase.classList.remove("text-success");
                elementpwdlowercase.classList.add("text-muted");
                elementpwduppercase.classList.remove("text-success");
                elementpwduppercase.classList.add("text-muted");
                elementspecialcharacters.classList.remove("text-success");
                elementspecialcharacters.classList.add("text-muted");
            }

            if (value.length > 8 && IsPwdnumbersymbol == true && IsLowercase == true && IsUppercase == true && IsPwdnumbersymbol == true && IsSpecialCharacters == true) {
                this.setState({ isDisabled: false });
            } else {
                this.setState({
                    isDisabled: true,
                    errOldPassword: '',
                    errNewPassword: '',
                    errReTypePassword: '',
                });
            }
        }

        this.setState({
            [name]: value
        });
    }

    async handleSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            this.setState({ isSubmited: true });
            var resetPasswordRequest = new Object();
            resetPasswordRequest.RequestId = this.state.requestId;
            resetPasswordRequest.NewPassword = this.state.newPassword.trim();            
            const requestParams = getRequestParams('POST', resetPasswordRequest);

            try {
                const response = await fetch('authentication/reset-password', requestParams);
                const data = await response.json();

                Swal.fire({
                    icon: data.isSuccess ? 'success' : 'error',
                    title: data.isSuccess ? 'Success' : 'Oops...',
                    text: data.resultMessage,
                    confirmButtonText: data.isSuccess ? 'Done' : 'Cancel',
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            e.preventDefault();
                            window.location.href = "/";
                        }
                    } else {
                        this.setState({ isSubmited: false })
                    }
                })
            } catch (e) {
                this.setState({ isSubmited: false })
            }
        }
    }

    render() {
        return (
            <div className="forgot-password-page">
                <div className="forgot-password-box">
                    <div className="forgot-password-logo">
                        <a href="#"><img src="../../dist/img/logo_utwiz.png" alt="UTWiz" /></a>
                    </div>
                    <div className="card">
                        <div className="card-body login-card-body">
                            <h4 className="text-center"><strong>Reset your password</strong></h4>                            
                            <form method="post" onSubmit={this.handleSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label>New Password <span className="text-red">*</span></label><label className="mb-1 float-right"><a href="#" onClick={this.showHide}>{this.state.type === 'input' ? 'Hide Password' : 'Show Password'}</a></label>
                                            <input type={this.state.type} className="form-control form-control-sm" id="newPassword" name="newPassword" title="New Password" placeholder="New Password" value={this.state.newPassword} onChange={this.handleInputChange} />
                                            {this.state.errors["errNewPassword"].length > 0 && <span className="error invalid-feedback">{this.state.errors["errNewPassword"]}</span>}
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label>Re-type New Password <span className="text-red">*</span></label><label className="mb-1 float-right"><a href="#" onClick={this.pwdShowHide}>{this.state.retype === 'input' ? 'Hide Password' : 'Show Password'}</a></label>
                                            <input type={this.state.retype} className="form-control form-control-sm" id="reTypePassword" name="reTypePassword" title="Re-type New Password" placeholder="Re-type New Password" value={this.state.reTypePassword} onChange={this.handleInputChange} />
                                            {this.state.errors["errReTypePassword"].length > 0 && <span className="error invalid-feedback">{this.state.errors["errReTypePassword"]}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <p className="mt-3 mb-1"><small id="pwdlength" className="text-muted"><i className="fas fa-check-circle"></i> At least 8 characters long</small></p>
                                            <p className="mb-1"><small id="pwdlowercase" className="text-muted"><i className="fas fa-check-circle"></i> One lowercase character</small></p>
                                            <p className="mb-1"><small id="pwduppercase" className="text-muted"><i className="fas fa-check-circle"></i> One uppercase character</small></p>
                                            <p className="mb-1"><small id="pwdnumbersymbol" className="text-muted"><i className="fas fa-check-circle"></i> One number, symbol, or whitespace character</small></p>
                                            <p className="mb-1"><small id="specialcharacters" className="text-muted"><i className="fas fa-check-circle"></i> One special character</small></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
                                        <button type="submit" className="btn btn-danger btn-block" disabled={this.state.isDisabled}><i className="fas fa-key"></i> Reset your password</button>
                                    </div>
                                </div>
                                <NavLink className="text-center" tag={Link} to="/"><i className="mb-0 text-center"></i>Go back to UTWiz login</NavLink>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}