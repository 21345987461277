import DataGrid, {
    Column,
    FilterRow,
    Paging, Scrolling,
    Selection
} from 'devextreme-react/data-grid';
import DateBox from 'devextreme-react/date-box';
import DropDownBox from 'devextreme-react/drop-down-box';
import { SelectBox } from 'devextreme-react/select-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import moment from 'moment/min/moment-with-locales'; //npm install --save moment react-moment
import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { SendEmailComponent } from '../Shared/SendEmailComponent';
let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class VehicleReimbReceivableReportComponent extends Component {
    constructor() {
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        super()
        this.state = {
            isSubmitted: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            isSinglePayrollPeriod: true,
            isRangePayrollPeriod: false,
            payrollPeriodDropdownList: [],
            payrollPeriodDropdownData: null,
            payDate: '',
            fromDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
            toDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
            isAllDeduction: true,
            deductionDropdownList: [],
            deductionDropdownData: null,
            isAllFranchisee: true,
            franchiseeDropdownList: [],
            franchiseeDropdownData: null,
            isAllVehicle: true,
            vehicleDropdownList: [],
            vehicleDropdownData: null,
            errors: {
                payDate: '',
                fromDate: '',
                toDate: '',
                deduction: '',
                franchisee: '',
                vehicle: ''
            }
        }

        this.syncDataGridDeductionSelection = this.syncDataGridDeductionSelection.bind(this);
        this.dataGridDeductionRender = this.dataGridDeductionRender.bind(this);
        this.syncDataGridFranchiseeSelection = this.syncDataGridFranchiseeSelection.bind(this);
        this.dataGridFranchiseeRender = this.dataGridFranchiseeRender.bind(this);
        this.syncDataGridVehicleSelection = this.syncDataGridVehicleSelection.bind(this);
        this.dataGridVehicleRender = this.dataGridVehicleRender.bind(this);
    }
    getPayrollPeriodDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('vehicle-payroll-period-api/dropdown-list/', requestParams);
            const data = await response.json();
            this.setState({
                payrollPeriodDropdownList: data.vehiclePayRollPeriods
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    getDeductionDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('vehicle-deduction-api/dropdown-deduction/', requestParams);
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    deductionDropdownList: data.deductions
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    getFranchiseeDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('vehicle-owner-api/owner-dropdown-list', requestParams);
            const data = await response.json();
            this.setState({
                franchiseeDropdownList: data.owners
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    getVehicleDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('vehicle-api/dropdown-all', requestParams);
            const data = await response.json();
            this.setState({
                vehicleDropdownList: data.vehiclesDropdown
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getPayrollPeriodDropdownList();
            await this.getDeductionDropdownList();
            await this.getFranchiseeDropdownList();
            await this.getVehicleDropdownList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isEmailSubmitted: false,
                isOpenModal: false,
                loadPanelVisible: false,
                isSinglePayrollPeriod: true,
                isRangePayrollPeriod: false,
                payrollPeriodDropdownList: [],
                payrollPeriodDropdownData: null,
                payDate: '',
                fromDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
                toDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
                isAllDeduction: true,
                deductionDropdownList: [],
                deductionDropdownData: null,
                isAllFranchisee: true,
                franchiseeDropdownList: [],
                franchiseeDropdownData: null,
                isAllVehicle: true,
                vehicleDropdownList: [],
                vehicleDropdownData: null,
                errors: {
                    payDate: '',
                    fromDate: '',
                    toDate: '',
                    deduction: '',
                    franchisee: '',
                    vehicle: ''
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnDeductionDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.deduction = '';
            if (data === null || data.length === 0) {
                errors.deduction = 'Please select receivable.';
            }
            this.setState({
                deductionDropdownData: data.selectedRowKeys.length && data.selectedRowKeys || []
                , errors: errors
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    syncDataGridDeductionSelection(e) {
        this.setState({
            deductionDropdownData: e.value || []
        });
    }
    handleOnFranchiseeDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.franchisee = '';
            if (data === null || data.length === 0) {
                errors.franchisee = 'Please select franchisee.';
            }
            this.setState({
                franchiseeDropdownData: data.selectedRowKeys.length && data.selectedRowKeys || []
                , errors: errors
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    syncDataGridFranchiseeSelection(e) {
        this.setState({
            franchiseeDropdownData: e.value || []
        });
    }
    handleOnVehicleDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.vehicle = '';
            if (data === null || data.length === 0) {
                errors.vehicle = 'Please select vehicle.';
            }
            this.setState({
                vehicleDropdownData: data.selectedRowKeys.length && data.selectedRowKeys || []
                , errors: errors
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    syncDataGridVehicleSelection(e) {
        this.setState({
            vehicleDropdownData: e.value || []
        });
    }
    handlePayrollPeriodOnChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.payDate = '';
            let payDate = '';
            if (data !== null) {
                payDate = (data.selectedItem != null ? data.selectedItem.payDate : "");
            }
            else {
                //errors.payDate = 'Please select period.';
            }
            this.setState({ payrollPeriodDropdownData: data, payDate: payDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnFromDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.fromDate = '';
            let fromDate = '';
            if (event.value !== null && event.value !== '') {
                fromDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.fromDate = 'Please select date.';
            }
            if (this.state.toDate.trim() !== '') {
                errors.toDate = '';
            }
            this.setState({ fromDate: fromDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnToDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.toDate = '';
            let toDate = '';
            if (event.value !== null && event.value !== '') {
                toDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.toDate = 'Please select date.';
            }
            if (this.state.fromDate.trim() !== '') {
                errors.fromDate = '';
            }
            this.setState({ toDate: toDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnInputChange = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
            let errors = this.state.errors;
            switch (name) {
                case 'isSinglePayrollPeriod':
                    errors.payDate = '';
                    errors.fromDate = '';
                    errors.toDate = '';
                    this.setState({ isSinglePayrollPeriod: value, isRangePayrollPeriod: false, payrollPeriodDropdownData: null, payDate: '' });
                    break;
                case 'isRangePayrollPeriod':
                    errors.payDate = '';
                    errors.fromDate = '';
                    errors.toDate = '';
                    this.setState({ isSinglePayrollPeriod: false, isRangePayrollPeriod: value, payrollPeriodDropdownData: null, payDate: '' });
                    break;
                case 'isAllDeduction':
                    errors.deduction = '';
                    this.setState({ isAllDeduction: value, deductionDropdownData: null });
                    break;
                case 'isAllFranchisee':
                    errors.franchisee = '';
                    this.setState({ isAllFranchisee: value, franchiseeDropdownData: null });
                    break;
                case 'isAllVehicle':
                    errors.vehicle = '';
                    this.setState({ isAllVehicle: value, vehicleDropdownData: null });
                    break;
                default:
                    break;

            }
            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnReportSubmit = async (event) => {
        event.preventDefault();
        try {
            this.setState({ isSubmitted: true });
            await this.downloadAsPdf();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false });
    }
    downloadAsPdf = async () => {
        try {
            if (await this.isFormValid()) {
                let fromDate = '';
                let toDate = '';
                let deductionIds = '';
                let franchiseeIds = '';
                let vehicleIds = '';
                if (this.state.isSinglePayrollPeriod === true) {
                    debugger;
                    if (this.state.payDate.trim() !== '') {
                        if (moment(this.state.payDate, "MM/DD/YYYY").format("D") > 15) {
                            fromDate = moment(moment(this.state.payDate, "MM/DD/YYYY").format("YYYY-MM-") + "16").format("YYYY-MM-DD") + ' 00:00:00';
                            toDate = moment(moment(this.state.payDate, "MM/DD/YYYY").format("YYYY-MM-") + moment(this.state.payDate, "MM/DD/YYYY").daysInMonth()).format("YYYY-MM-DD") + ' 23:59:59';
                        }
                        if (moment(this.state.payDate, "MM/DD/YYYY").format("D") <= 15) {
                            fromDate = moment(moment(this.state.payDate, "MM/DD/YYYY").format("YYYY-MM-") + "01").format("YYYY-MM-DD") + ' 00:00:00';
                            toDate = moment(moment(this.state.payDate, "MM/DD/YYYY").format("YYYY-MM-") + "15").format("YYYY-MM-DD") + ' 23:59:59';
                        }
                    }
                }
                if (this.state.isRangePayrollPeriod === true) {
                    if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                        fromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00'
                        toDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59'
                    }
                }
                if (this.state.isAllDeduction === false) {
                    if (this.state.deductionDropdownData !== null) {
                        if (Object.keys(this.state.deductionDropdownData).length > 0) {
                            for (var i = 0; i < this.state.deductionDropdownData.length; i++) {
                                deductionIds += deductionIds === '' ? this.state.deductionDropdownData[i].id : ',' + this.state.deductionDropdownData[i].id
                            }
                        }
                    }
                }
                if (this.state.isAllFranchisee === false) {
                    if (this.state.franchiseeDropdownData !== null) {
                        if (Object.keys(this.state.franchiseeDropdownData).length > 0) {
                            for (var i = 0; i < this.state.franchiseeDropdownData.length; i++) {
                                franchiseeIds += franchiseeIds === '' ? this.state.franchiseeDropdownData[i].id : ',' + this.state.franchiseeDropdownData[i].id
                            }
                        }
                    }
                }
                if (this.state.isAllVehicle === false) {
                    if (this.state.vehicleDropdownData !== null) {
                        if (Object.keys(this.state.vehicleDropdownData).length > 0) {
                            for (var i = 0; i < this.state.vehicleDropdownData.length; i++) {
                                vehicleIds += vehicleIds === '' ? this.state.vehicleDropdownData[i].id : ',' + this.state.vehicleDropdownData[i].id
                            }
                        }
                    }
                }
                let reportHeader = await this.setSelectionCrieteria();
                await this.downloadReport(fromDate, toDate, deductionIds, franchiseeIds, vehicleIds, reportHeader, 'pdf');
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    downloadReport = async (fromDate, toDate, deductionIds, franchiseeIds, vehicleIds, reportHeader, fileFormat) => {
        try {
            var request = new Object();
            request.LocationId = Number(sessionStorage.getItem("session_locationId"));
            request.StartDate = fromDate;
            request.EndDate = toDate;
            request.DeductionIds = deductionIds;
            request.FranchiseeIds = franchiseeIds;
            request.VehicleIds = vehicleIds;
            request.SelectionCrieteria = reportHeader;
            request.fileFormat = fileFormat;
            request.MethodName = "GetVehicleReimbReceivableReport";
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            let a = document.createElement('a');
            a.href = dataResponse.reportBase64String;
            a.download = 'VehicleReimbursmentReceivable.' + fileFormat;
            a.click();
        } catch (ex) {
            console.log(ex);
        }
    }
    isFormValid = async () => {
        let isValid = true;
        try {
            let errors = this.state.errors;
            errors.payDate = '';
            errors.fromDate = '';
            errors.toDate = '';
            errors.deduction = '';
            errors.franchisee = '';
            errors.vehicle = '';
            if (this.state.isSinglePayrollPeriod === true) {
                if (this.state.payDate.trim() === '') {
                    errors.payDate = 'Please select period.';
                    isValid = false;
                }
            }
            if (this.state.isRangePayrollPeriod === true) {
                if (this.state.fromDate.trim() === '') {
                    errors.fromDate = 'Please select date.';
                    isValid = false;
                }
                if (this.state.toDate.trim() === '') {
                    errors.toDate = 'Please select date.';
                    isValid = false;
                }
                if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                    if (moment(this.state.fromDate, 'MM/DD/YYYY').isAfter(this.state.toDate, 'MM/DD/YYYY')) {
                        errors.fromDate = 'Please enter a valid date range.';
                        errors.toDate = 'Please enter a valid date range.';
                        isValid = false;
                    }
                }
            }
            if (this.state.isAllDeduction === false) {
                if (this.state.deductionDropdownData === null || this.state.deductionDropdownData.length === 0) {
                    errors.deduction = 'Please select receivable.';
                    isValid = false;
                }
            }
            if (this.state.isAllFranchisee === false) {
                if (this.state.franchiseeDropdownData === null || this.state.franchiseeDropdownData.length === 0) {
                    errors.franchisee = 'Please select franchisee.';
                    isValid = false;
                }
            }
            if (this.state.isAllVehicle === false) {
                if (this.state.vehicleDropdownData === null || this.state.vehicleDropdownData.length === 0) {
                    errors.vehicle = 'Please select vehicle.';
                    isValid = false;
                }
            }
            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    setSelectionCrieteria = async () => {
        let selectionCrieteria = 'Vehicle reimbursment receivable for';
        try {
            if (this.state.deductionDropdownData === null || this.state.deductionDropdownData.length === 0) {
                selectionCrieteria += ', all receivable';
            }
            if (this.state.franchiseeDropdownData === null || this.state.franchiseeDropdownData.length === 0) {
                selectionCrieteria = ', all franchisee';
            }
            if (this.state.vehicleDropdownData === null || this.state.vehicleDropdownData.length === 0) {
                selectionCrieteria = ', all vehicles';
            }
            if (this.state.isSinglePayrollPeriod === true) {
                if (this.state.payDate.trim() !== '' && Object.keys(this.state.payrollPeriodDropdownData).length > 0) {
                    selectionCrieteria += ', period - ' + this.state.payrollPeriodDropdownData.viewDate;
                }
            }
            if (this.state.isRangePayrollPeriod === true) {
                if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                    selectionCrieteria += ', period from - ' + this.state.fromDate + ' to ' + this.state.toDate;
                }
            }

        } catch (ex) {
            selectionCrieteria = '';
            console.log(ex);
        }
        return selectionCrieteria;
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isEmailSubmitted: true });
                let fromDate = '';
                let toDate = '';
                let deductionIds = '';
                let franchiseeIds = '';
                let vehicleIds = '';
                if (this.state.isSinglePayrollPeriod === true) {
                    if (this.state.payDate.trim() !== '') {
                        if (moment(this.state.payDate, "MM/DD/YYYY").format("D") > 15) {
                            fromDate = moment(moment(this.state.payDate, "MM/DD/YYYY").format("YYYY-MM-") + "16").format("YYYY-MM-DD") + ' 00:00:00';
                            toDate = moment(moment(this.state.payDate, "MM/DD/YYYY").format("YYYY-MM-") + moment(this.state.payDate, "MM/DD/YYYY").daysInMonth()).format("YYYY-MM-DD") + ' 23:59:59';
                        }
                        if (moment(this.state.payDate, "MM/DD/YYYY").format("D") <= 15) {
                            fromDate = moment(moment(this.state.payDate, "MM/DD/YYYY").format("YYYY-MM-") + "01").format("YYYY-MM-DD") + ' 00:00:00';
                            toDate = moment(moment(this.state.payDate, "MM/DD/YYYY").format("YYYY-MM-") + "15").format("YYYY-MM-DD") + ' 23:59:59';
                        }
                    }
                }
                if (this.state.isRangePayrollPeriod === true) {
                    if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                        fromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00'
                        toDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59'
                    }
                }
                if (this.state.isAllDeduction === false) {
                    if (this.state.deductionDropdownData !== null) {
                        if (Object.keys(this.state.deductionDropdownData).length > 0) {
                            for (var i = 0; i < this.state.deductionDropdownData.length; i++) {
                                deductionIds += deductionIds === '' ? this.state.deductionDropdownData[i].id : ',' + this.state.deductionDropdownData[i].id
                            }
                        }
                    }
                }
                if (this.state.isAllFranchisee === false) {
                    if (this.state.franchiseeDropdownData !== null) {
                        if (Object.keys(this.state.franchiseeDropdownData).length > 0) {
                            for (var i = 0; i < this.state.franchiseeDropdownData.length; i++) {
                                franchiseeIds += franchiseeIds === '' ? this.state.franchiseeDropdownData[i].id : ',' + this.state.franchiseeDropdownData[i].id
                            }
                        }
                    }
                }
                if (this.state.isAllVehicle === false) {
                    if (this.state.vehicleDropdownData !== null) {
                        if (Object.keys(this.state.vehicleDropdownData).length > 0) {
                            for (var i = 0; i < this.state.vehicleDropdownData.length; i++) {
                                vehicleIds += vehicleIds === '' ? this.state.vehicleDropdownData[i].id : ',' + this.state.vehicleDropdownData[i].id
                            }
                        }
                    }
                }

                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.StartDate = fromDate;
                request.EndDate = toDate;
                request.DeductionIds = deductionIds;
                request.FranchiseeIds = franchiseeIds;
                request.VehicleIds = vehicleIds;
                request.SelectionCrieteria = await this.setSelectionCrieteria();;
                request.fileFormat = 'pdf';
                request.MethodName = "GetVehicleReimbReceivableReport";
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Vehicle Reimbursement Receivable';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = 'VehicleReimbursmentReceivable.pdf'
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '45%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleOnReportSubmit}
                        style={(this.state.isSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Vehicle Reimbursement Receivable'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="checkbox" name="isAllDeduction" checked={this.state.isAllDeduction}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isAllDeduction"> All Receivable</label>
                                        </span>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder="Select receivable"*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.deductionDropdownData}*/}
                                        {/*    name="dddeduction"*/}
                                        {/*    onChange={this.handleOnDeductionDropdownChange}*/}
                                        {/*    getOptionLabel={option => option.name}*/}
                                        {/*    getOptionValue={option => option.id}*/}
                                        {/*    options={this.state.deductionDropdownList}*/}
                                        {/*    isMulti={true}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*    isDisabled={this.state.isAllDeduction}*/}
                                        {/*/>*/}
                                        <DropDownBox
                                            value={this.state.deductionDropdownData}
                                            valueExpr="id"
                                            deferRendering={false}
                                            displayExpr="name"
                                            placeholder="Select receivable"
                                            showClearButton={true}
                                            dataSource={this.state.deductionDropdownList}
                                            onValueChanged={this.syncDataGridDeductionSelection}
                                            contentRender={this.dataGridDeductionRender}
                                            disabled={this.state.isAllDeduction}
                                        />
                                        {this.state.errors["deduction"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["deduction"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="checkbox" name="isAllFranchisee" checked={this.state.isAllFranchisee}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isAllFranchisee"> All Franchisee</label>
                                        </span>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder="Select franchisee"*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.franchiseeDropdownData}*/}
                                        {/*    name="ddfranchisee"*/}
                                        {/*    onChange={this.handleOnFranchiseeDropdownChange}*/}
                                        {/*    getOptionLabel={option => option.name}*/}
                                        {/*    getOptionValue={option => option.id}*/}
                                        {/*    options={this.state.franchiseeDropdownList}*/}
                                        {/*    isMulti={true}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*    isDisabled={this.state.isAllFranchisee}*/}
                                        {/*/>*/}
                                        <DropDownBox
                                            value={this.state.franchiseeDropdownData}
                                            valueExpr="id"
                                            deferRendering={false}
                                            displayExpr="name"
                                            placeholder="Select franchisee"
                                            showClearButton={true}
                                            dataSource={this.state.franchiseeDropdownList}
                                            onValueChanged={this.syncDataGridFranchiseeSelection}
                                            contentRender={this.dataGridFranchiseeRender}
                                            disabled={this.state.isAllFranchisee}
                                        />
                                        {this.state.errors["franchisee"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["franchisee"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="checkbox" name="isAllVehicle" checked={this.state.isAllVehicle}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isAllVehicle"> All Vehicle</label>
                                        </span>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder="Select vehicle"*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.vehicleDropdownData}*/}
                                        {/*    name="ddvehicle"*/}
                                        {/*    onChange={this.handleOnVehicleDropdownChange}*/}
                                        {/*    getOptionLabel={option => option.name}*/}
                                        {/*    getOptionValue={option => option.id}*/}
                                        {/*    options={this.state.vehicleDropdownList}*/}
                                        {/*    isMulti={true}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*    isDisabled={this.state.isAllVehicle}*/}
                                        {/*/>*/}

                                        <DropDownBox
                                            value={this.state.vehicleDropdownData}
                                            valueExpr="id"
                                            deferRendering={false}
                                            displayExpr="name"
                                            placeholder="Select vehicle"
                                            showClearButton={true}
                                            dataSource={this.state.vehicleDropdownList}
                                            onValueChanged={this.syncDataGridVehicleSelection}
                                            contentRender={this.dataGridVehicleRender}
                                            disabled={this.state.isAllVehicle}
                                        />
                                        {this.state.errors["vehicle"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["vehicle"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="isSinglePayrollPeriod" checked={this.state.isSinglePayrollPeriod}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isSinglePayrollPeriod"> Single Period</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="isRangePayrollPeriod" checked={this.state.isRangePayrollPeriod}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isRangePayrollPeriod"> In Range</label>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <div style={{ display: (this.state.isSinglePayrollPeriod) || 'none' }}>
                                            {/*<Select*/}
                                            {/*    className="basic-multi-select"*/}
                                            {/*    classNamePrefix="select"*/}
                                            {/*    placeholder="Select period"*/}
                                            {/*    defaultValue={[]}*/}
                                            {/*    value={this.state.payrollPeriodDropdownData}*/}
                                            {/*    name="ddpayrollperiod"*/}
                                            {/*    onChange={this.handlePayrollPeriodOnChange}*/}
                                            {/*    getOptionLabel={option => option.viewDate}*/}
                                            {/*    getOptionValue={option => option.payDate}*/}
                                            {/*    options={this.state.payrollPeriodDropdownList}*/}
                                            {/*    isMulti={false}*/}
                                            {/*    isClearable={true}*/}
                                            {/*    isSearchable={true}*/}
                                            {/*/>*/}

                                            <SelectBox
                                                dataSource={this.state.payrollPeriodDropdownList.length > 0 ? this.state.payrollPeriodDropdownList : []}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select period"
                                                name="consumerId"
                                                defaultValue={0}
                                                value={this.state.payrollPeriodDropdownData}
                                                displayExpr='viewDate'
                                                valueExpr='payDate'
                                                onSelectionChanged={this.handlePayrollPeriodOnChange}
                                                showClearButton={true}
                                                disabled={false}
                                                searchEnabled={true}
                                            />
                                        </div>
                                        <div style={{ display: (this.state.isRangePayrollPeriod) || 'none' }}>
                                            <DateBox type="date"
                                                className="form-control form-control-sm input-date"
                                                name="fromDate"
                                                placeholder="mm/dd/yyyy"
                                                defaultValue={this.state.fromDate}
                                                value={this.state.fromDate}
                                                onValueChanged={this.handleOnFromDateChange}
                                                width={'100%'}
                                                disabled={this.state.isSinglePayrollPeriod}
                                                acceptCustomValue={false}
                                            />
                                            {this.state.errors["fromDate"].length > 0 && <span className='error invalid-feedback'>
                                                {this.state.errors["fromDate"]}</span>}
                                        </div>
                                        {this.state.errors["payDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["payDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        {/*<label>To Date <span style={{ display: (this.state.isRangePayrollPeriod) || 'none' }} className="text-danger">*</span></label>*/}
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            defaultValue={this.state.toDate}
                                            value={this.state.toDate}
                                            min={this.state.fromDate}
                                            onValueChanged={this.handleOnToDateChange}
                                            width={'100%'}
                                            disabled={this.state.isSinglePayrollPeriod}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["toDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["toDate"]}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="submit" className="btn btn-success btn-sm" title="Download">
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                             </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }

    dataGridDeductionRender() {
        return (
            <DataGrid
                dataSource={this.state.deductionDropdownList}
                id="grid-container"
                keyExpr="id"
                hoverStateEnabled={true}
                selectedRowKeys={this.state.deductionDropdownData}
                onSelectionChanged={this.handleOnDeductionDropdownChange}
                ref={ref => this.dataGrid = ref}>
                <Column dataField="name" caption="Deduction" />
                <Selection mode="multiple" />
                <Scrolling mode="virtual" />
                <Paging enabled={false} pageSize={50} />
                <FilterRow visible={true} />
            </DataGrid>
        );
    }

    dataGridFranchiseeRender() {
        return (
            <DataGrid
                dataSource={this.state.franchiseeDropdownList}
                id="grid-container"
                keyExpr="id"
                hoverStateEnabled={true}
                selectedRowKeys={this.state.franchiseeDropdownData}
                onSelectionChanged={this.handleOnFranchiseeDropdownChange}
                ref={ref => this.dataGrid = ref}>
                <Column dataField="name" caption="Franchisee" />
                <Selection mode="multiple" />
                <Scrolling mode="virtual" />
                <Paging enabled={false} pageSize={50} />
                <FilterRow visible={true} />
            </DataGrid>
        );
    }

    dataGridVehicleRender() {
        return (
            <DataGrid
                dataSource={this.state.vehicleDropdownList}
                id="grid-container"
                keyExpr="id"
                hoverStateEnabled={true}
                selectedRowKeys={this.state.vehicleDropdownData}
                onSelectionChanged={this.handleOnVehicleDropdownChange}
                ref={ref => this.dataGrid = ref}>
                <Column dataField="name" caption="Vehicle" />
                <Selection mode="multiple" />
                <Scrolling mode="virtual" />
                <Paging enabled={false} pageSize={50} />
                <FilterRow visible={true} />
            </DataGrid>
        );
    }
}