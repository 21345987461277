

import React, { Component } from 'react';
import { SelectBox } from 'devextreme-react/select-box';
import DayPicker, { DateUtils } from 'react-day-picker'; //npm install react-day-picker --save
import DatePicker from "react-datepicker"; // npm install react - datepicker --save
import Autosuggest from 'react-autosuggest'; //npm install react-autosuggest --save
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import NumberFormat from 'react-number-format';
import NumericInput from 'react-numeric-input'; //npm install react-numeric-input --save
import { getRequestParams } from '../../helpers/globalfunctions.js';

import { LoadPanel } from 'devextreme-react/load-panel';

import "react-datepicker/dist/react-datepicker.css";
import 'react-day-picker/lib/style.css';

const MySwal = withReactContent(Swal)
const google = window.google;

const customStyles = {
    content: {
        width: '50%'
    }
};
const position = { of: '#confirmdiv' };

function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
function renderSuggestion(suggestion) {
    if (suggestion.isNotFound) {
        return (
            <span>
                Data not found!
            </span>
        );
    }
    return (
        <span>{suggestion.name}</span>
    );
}
function shouldRenderSuggestions(value, reason) {
    return value.trim().length > 2;
}

let rideTypeId = 0;
let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));

export class TripExceptionComponent extends Component {
    static displayName = TripExceptionComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
            isSubmited: false,
            rideId: 0,
            loadPanelVisible: false,
            reservationId: 0,
            pickupDateTimeStr: "",
            effectiveDate: currentTime,
            untilDate: currentTime,
            exceptionTypeId: "2",
            tripDetails: null,

            dropdownCustomer: [],
            pageName: '',
            paxFirstName: '',
            paxLastName: '',
            customerId: 0,
            customerTypeId: 0,
            bookingAddress: [],
            dropdownZone: [],
            isPickupGoogleBooking: false,
            pickupSuggestions: [],
            pickupLocation: "",
            pickupZoneId: 0,
            pickupAddress: "",
            pickupAddressType: "",
            pickupCity: "",
            pickupState: "",
            pickupZipCode: "",
            pickupLatitude: 0,
            pickupLongitude: 0,
            isDropoffGoogleBooking: false,
            dropoffSuggestions: [],
            dropoffLocation: "",
            dropoffZoneId: 0,
            dropoffAddress: "",
            dropoffAddressType: "",
            dropoffCity: "",
            dropoffState: "",
            dropoffZipCode: "",
            dropoffLatitude: 0,
            dropoffLongitude: 0,

            flightNumber: '',
            cancelReason: "",
            puDateTime: currentTime,

            dropdownVehicleType: [],
            vehicleTypeId: 0,
            noOfPax: 1,
            pilotCount: 0,
            crewCount: 0,
            flightAttandCount: 0,
            vehicleNumber: "",
            recurringTypeId: 2,
            selectedDays: [],
            isWeekSun: false,
            isWeekMon: false,
            isWeekTue: false,
            isWeekWed: false,
            isWeekThu: false,
            isWeekFri: false,
            isWeekSat: false,
            isMonthSun: false,
            isMonthMon: false,
            isMonthTue: false,
            isMonthWed: false,
            isMonthThu: false,
            isMonthFri: false,
            isMonthSat: false,
            specialInstruction: "",


            loadPanelVisible: false,
            isCopy: false,

            errors: {
                effectiveDate: "",
                untilDate: "",

                paxFirstName: "",
                paxLastName: "",
                customerId: "",
                pickupLocation: "",
                pickupZoneId: "",
                dropoffLocation: "",
                dropoffZoneId: "",
                puDateTime: "",
                flightNumber: "",
                vehicleTypeId: "",
                noOfPax: "",
                pilotCount: "",
                recurring: "",
                vehicleNumber: "",
                cancelReason: "",
                specialInstruction: "",
            }
        }

        this.closeModal = this.closeModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.afterOpenTripExceptionModal = this.afterOpenTripExceptionModal.bind(this);
        this.getGetTripDetails = this.getGetTripDetails.bind(this);
        this.handleSubmitException = this.handleSubmitException.bind(this);
        this.getCustomerList = this.getCustomerList.bind(this);
        this.handleExceptionOptionChange = this.handleExceptionOptionChange.bind(this);
        this.handleExtendTripOptionChange = this.handleExtendTripOptionChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handlePickupZoneChange = this.handlePickupZoneChange.bind(this);
        this.handleDropoffZoneChange = this.handleDropoffZoneChange.bind(this);
        this.handleCustomerChange = this.handleCustomerChange.bind(this);
        this.handleVehicleTypeChange = this.handleVehicleTypeChange.bind(this);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.handlePaxCountChange = this.handlePaxCountChange.bind(this);

        this.getVehicleType = this.getVehicleType.bind(this);
        this.getBookingSuggestions = this.getBookingSuggestions.bind(this);
        this.getZones = this.getZones.bind(this);
        this.getPickupSuggestionValue = this.getPickupSuggestionValue.bind(this);
        this.onChangePickupLocation = this.onChangePickupLocation.bind(this);
        this.onBlurPickupLocation = this.onBlurPickupLocation.bind(this);
        this.getDropoffSuggestionValue = this.getDropoffSuggestionValue.bind(this);
        this.getRideTypeId = this.getRideTypeId.bind(this);

        this.handleValidation = this.handleValidation.bind(this);

        this.handlePickupDateTimeChange = this.handlePickupDateTimeChange.bind(this);

        this.handlePlaceSearch = this.handlePlaceSearch.bind(this);
        this.handlePickupPlaceSelect = this.handlePickupPlaceSelect.bind(this);
        this.handlePickupToggleChange = this.handlePickupToggleChange.bind(this);

        this.handleDropoffPlaceSelect = this.handleDropoffPlaceSelect.bind(this);
        this.handleDropoffToggleChange = this.handleDropoffToggleChange.bind(this);

        this.getDaysOfMonthlyRec = this.getDaysOfMonthlyRec.bind(this);
        this.getDaysOfWeeklyRec = this.getDaysOfWeeklyRec.bind(this);
        this.getDaysOfDailyRec = this.getDaysOfDailyRec.bind(this);
        this.getMonthlyRecurringDays = this.getMonthlyRecurringDays.bind(this);
        this.getWeeklyRecurringDays = this.getWeeklyRecurringDays.bind(this);
        this.getDailyRecurringDays = this.getDailyRecurringDays.bind(this);
        this.diffDays = this.diffDays.bind(this);
        this.getRecurringPuDateArrayList = this.getRecurringPuDateArrayList.bind(this);
        this.getRecurringPickupDates = this.getRecurringPickupDates.bind(this);
    }

    async getGetTripDetails() {
        var newObject = new Object();
        newObject.tripId = parseInt(this.state.rideId);

        const requestParams = getRequestParams('POST', newObject);
        const response = await fetch('reservation-api/trip-details/', requestParams);
        const data = await response.json();
        this.setState({
            tripDetails: data.tripDetails,
        });
    }

    closeModal() {
        this.setState({
            isOpenModal: false,
            isSubmited: false,
            rideId: 0,
            loadPanelVisible: false,
            reservationId: 0,
            pickupDateTimeStr: "",
            effectiveDate: currentTime,
            untilDate: currentTime,
            exceptionTypeId: "2",
            tripDetails: null,

            dropdownCustomer: [],
            pageName: '',
            paxFirstName: '',
            paxLastName: '',
            customerId: 0,
            bookingAddress: [],
            dropdownZone: [],
            isPickupGoogleBooking: false,
            pickupSuggestions: [],
            pickupLocation: "",
            pickupZoneId: 0,
            pickupAddress: "",
            pickupAddressType: "",
            pickupCity: "",
            pickupState: "",
            pickupZipCode: "",
            pickupLatitude: 0,
            pickupLongitude: 0,
            isDropoffGoogleBooking: false,
            dropoffSuggestions: [],
            dropoffLocation: "",
            dropoffZoneId: 0,
            dropoffAddress: "",
            dropoffAddressType: "",
            dropoffCity: "",
            dropoffState: "",
            dropoffZipCode: "",
            dropoffLatitude: 0,
            dropoffLongitude: 0,

            flightNumber: '',
            cancelReason: "",

            puDateTime: currentTime,

            dropdownVehicleType: [],
            vehicleTypeId: 0,
            noOfPax: 1,
            pilotCount: 0,
            crewCount: 0,
            flightAttandCount: 0,
            vehicleNumber: "",
            recurringTypeId: 2,
            selectedDays: [],
            isWeekSun: false,
            isWeekMon: false,
            isWeekTue: false,
            isWeekWed: false,
            isWeekThu: false,
            isWeekFri: false,
            isWeekSat: false,
            isMonthSun: false,
            isMonthMon: false,
            isMonthTue: false,
            isMonthWed: false,
            isMonthThu: false,
            isMonthFri: false,
            isMonthSat: false,
            specialInstruction: "",


            loadPanelVisible: false,


            errors: {
                effectiveDate: "",
                untilDate: "",

                paxFirstName: "",
                paxLastName: "",
                customerId: "",
                pickupLocation: "",
                pickupZoneId: "",
                dropoffLocation: "",
                dropoffZoneId: "",
                puDateTime: "",
                flightNumber: "",
                vehicleTypeId: "",
                noOfPax: "",
                pilotCount: "",
                recurring: "",
                vehicleNumber: "",
                cancelReason: "",
                specialInstruction: "",
            }
        });
    }

    async showModal(pageName, rideId) {

        this.setState({
            isOpenModal: true,
            pageName: pageName,
            rideId: rideId,
            loadPanelVisible: true,
        });
        await this.getCustomerList();
        await this.getVehicleType();
        await this.getZones();
        await this.getGetTripDetails();
        if (this.state.tripDetails != null) {

            rideTypeId = parseInt(this.state.tripDetails.rideTypeId);
            let puZone = null, doZone = null;
            if (rideTypeId == 1) {
                puZone = this.state.dropdownZone.find(opt => opt.airportId == this.state.tripDetails.puAreaId);
                doZone = this.state.dropdownZone.find(opt => opt.id == this.state.tripDetails.doAreaId);
            } else if (rideTypeId == 2) {
                puZone = this.state.dropdownZone.find(opt => opt.id == this.state.tripDetails.puAreaId);
                doZone = this.state.dropdownZone.find(opt => opt.airportId == this.state.tripDetails.doAreaId);
            } else if (rideTypeId == 3) {
                puZone = this.state.dropdownZone.find(opt => opt.airportId == this.state.tripDetails.puAreaId);
                doZone = this.state.dropdownZone.find(opt => opt.airportId == this.state.tripDetails.doAreaId);
            } else {
                puZone = this.state.dropdownZone.find(opt => opt.id == this.state.tripDetails.puAreaId);
                doZone = this.state.dropdownZone.find(opt => opt.id == this.state.tripDetails.doAreaId);
            }



            this.setState({
                pickupDateTimeStr: this.state.tripDetails.puDateTimeStr,
                reservationId: this.state.tripDetails.reservationId,
                paxFirstName: this.state.tripDetails.firstName,
                paxLastName: this.state.tripDetails.lastName,
                customerId: this.state.tripDetails.customerId,
                customerTypeId: this.state.dropdownCustomer.find(opt => opt.id == this.state.tripDetails.customerId).customerTypeId,
                isPickupGoogleBooking: false,
                pickupLocation: this.state.tripDetails.puLocationName,
                pickupZoneId: puZone.id,
                pickupAddress: this.state.tripDetails.puAddress,
                pickupAddressType: "",
                pickupCity: this.state.tripDetails.puCity,
                pickupState: this.state.tripDetails.puStateName,
                pickupZipCode: this.state.tripDetails.puZipCode,
                pickupLatitude: this.state.tripDetails.puLatitude,
                pickupLongitude: this.state.tripDetails.puLongitude,
                isDropoffGoogleBooking: false,
                dropoffLocation: this.state.tripDetails.doLocationName,
                dropoffZoneId: doZone.id,
                dropoffAddress: this.state.tripDetails.doAddress,
                dropoffAddressType: "",
                dropoffCity: this.state.tripDetails.doCity,
                dropoffState: this.state.tripDetails.doStateName,
                dropoffZipCode: this.state.tripDetails.doZipCode,
                dropoffLatitude: this.state.tripDetails.doLatitude,
                dropoffLongitude: this.state.tripDetails.doLongitude,


                flightNumber: (rideTypeId == 1 || rideTypeId == 3) ? this.state.tripDetails.puAirlineNo : this.state.tripDetails.doAirlineNo,

                puDateTime: new Date(this.state.tripDetails.puDateTime),

                vehicleTypeId: this.state.tripDetails.apiVehicleTypeId,
                noOfPax: this.state.tripDetails.adultNo,
                pilotCount: this.state.tripDetails.iPilot,
                crewCount: this.state.tripDetails.iFlightAttendent,
                flightAttandCount: this.state.tripDetails.iCrew,
                recurringTypeId: 2,
                selectedDays: [],
                isWeekSun: false,
                isWeekMon: false,
                isWeekTue: false,
                isWeekWed: false,
                isWeekThu: false,
                isWeekFri: false,
                isWeekSat: false,
                isMonthSun: false,
                isMonthMon: false,
                isMonthTue: false,
                isMonthWed: false,
                isMonthThu: false,
                isMonthFri: false,
                isMonthSat: false,
                specialInstruction: this.state.tripDetails.specialInstruction,


            });
        }

        this.setState({

            loadPanelVisible: false,
        });
    }



    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.effectiveDate = "";
        errors.untilDate = "";
        errors.paxFirstName = "";
        errors.paxLastName = "";
        errors.customerId = "";
        errors.pickupLocation = "";
        errors.pickupZoneId = "";
        errors.dropoffLocation = "";
        errors.dropoffZoneId = "";
        errors.puDateTime = "";
        errors.flightNumber = "";
        errors.vehicleTypeId = "";
        errors.noOfPax = "";
        errors.pilotCount = "";
        errors.recurring = "";
        errors.vehicleNumber = "";
        errors.cancelReason = "";
        errors.specialInstruction = "";

        //NewPickupTime = 1,
        //    CancelRide = 2,
        //    AddNote = 3,
        //    Account = 4,
        //    PaxName = 5,
        //    PickupStreet = 6,
        //    DropoffStreet = 7,
        //    NoofPax = 8,
        //    VehicleNumber = 9,
        //    ExtendSchedule = 10,
        //    FlightNumber = 11,

        if (this.state.exceptionTypeId === "1") {
            if (this.state.puDateTime == null) {
                formIsValid = false;
                errors.puDateTime = "Please enter pickup date time.";
            }
        } else if (this.state.exceptionTypeId === "2") {
            if (this.state.cancelReason.trim() === '') {
                formIsValid = false;
                errors.cancelReason = "Please enter cancel reason.";
            }
        } else if (this.state.exceptionTypeId === "3") {
            if (this.state.specialInstruction.trim() === '') {
                formIsValid = false;
                errors.specialInstruction = "Please enter special instruction.";
            }
        } else if (this.state.exceptionTypeId === "4") {
            if (this.state.customerId == 0) {
                formIsValid = false;
                errors.customerId = "Please select customer.";
            }
        } else if (this.state.exceptionTypeId === "5") {
            if (this.state.paxFirstName.trim() === '') {
                formIsValid = false;
                errors.paxFirstName = "Please enter pax first name.";
            }
            if (this.state.paxLastName.trim() === '') {
                formIsValid = false;
                errors.paxLastName = "Please enter pax last name.";
            }
        } else if (this.state.exceptionTypeId === "6") {
            if (this.state.pickupLocation.trim() === '') {
                formIsValid = false;
                errors.pickupLocation = "Please enter pickup location.";
            }
            if (this.state.pickupZoneId == 0) {
                formIsValid = false;
                errors.pickupZoneId = "Please select pickup zone.";
            }
        } else if (this.state.exceptionTypeId === "7") {
            if (this.state.dropoffLocation.trim() === '') {
                formIsValid = false;
                errors.dropoffLocation = "Please enter dropoff location.";
            }
            if (this.state.dropoffZoneId == 0) {
                formIsValid = false;
                errors.dropoffZoneId = "Please select dropoff zone.";
            }
        } else if (this.state.exceptionTypeId === "8") {
            if (this.state.vehicleTypeId == 0) {
                formIsValid = false;
                errors.vehicleTypeId = "Please select vehicle type.";
            }
            if (this.state.customerTypeId == 1 || this.state.customerTypeId == 2) {
                if ((parseInt(this.state.pilotCount | 0) + parseInt(this.state.crewCount | 0) + parseInt(this.state.flightAttandCount | 0)) == 0) {
                    formIsValid = false;
                    errors.pilotCount = "Please enter at least any pax count.";
                }
            } else {
                if (parseInt(this.state.noOfPax | 0) == 0) {
                    formIsValid = false;
                    errors.noOfPax = "Please enter no of trip.";
                }
            }
        } else if (this.state.exceptionTypeId === "9") {
            if (this.state.vehicleNumber.trim() === '') {
                formIsValid = false;
                errors.vehicleNumber = "Please enter vehicle number.";
            }
        } else if (this.state.exceptionTypeId === "11") {
            if (this.state.flightNumber.trim() === '') {
                formIsValid = false;
                errors.flightNumber = "Please enter flight number.";
            }
        } else if (this.state.exceptionTypeId === "10") {
            if (this.state.untilDate === "" || this.state.untilDate === null) {
                formIsValid = false;
                errors.untilDate = "Please enter until date.";
            }

            if (this.state.recurringTypeId == 1) {
                if (this.state.selectedDays.length === 0) {
                    formIsValid = false;
                    errors.recurring = "Please select recurring days.";
                }
            }

            if (this.state.recurringTypeId == 2) {
                if (this.state.isWeekSun === false &&
                    this.state.isWeekMon === false &&
                    this.state.isWeekTue === false &&
                    this.state.isWeekWed === false &&
                    this.state.isWeekThu === false &&
                    this.state.isWeekFri === false &&
                    this.state.isWeekSat === false) {
                    formIsValid = false;
                    errors.recurring = "Please select recurring days.";
                }
            }

            if (this.state.recurringTypeId == 3) {
                if (this.state.isMonthSun === false &&
                    this.state.isMonthMon === false &&
                    this.state.isMonthTue === false &&
                    this.state.isMonthWed === false &&
                    this.state.isMonthThu === false &&
                    this.state.isMonthFri === false &&
                    this.state.isMonthSat === false) {
                    formIsValid = false;
                    errors.recurring = "Please select recurring days.";
                }
            }
        }

        if (this.state.cancelReason.trim() === '') {
            formIsValid = false;
            errors.cancelReason = "Please enter exception reason.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    async askExceptionTripConfirm() {
        let isResult = false;

        var exceptionTripMessage = "";
        var strMessage = "";

        if (this.state.exceptionTypeId === "1") {
            exceptionTripMessage = "Pickup time: " + this.state.puDateTime.toLocaleTimeString() + "<br> Reason: " + this.state.cancelReason + "."
        } else if (this.state.exceptionTypeId === "2") {
            exceptionTripMessage = "Reason: " + this.state.cancelReason + "."
        } else if (this.state.exceptionTypeId === "3") {
            exceptionTripMessage = "Special Ins: " + this.state.specialInstruction + "<br> Reason: " + this.state.cancelReason + "."
        } else if (this.state.exceptionTypeId === "4") {
            exceptionTripMessage = "Customer: " + this.state.dropdownCustomer.find(opt => opt.id == this.state.customerId).fullName + "<br> Reason: " + this.state.cancelReason + "."
        } else if (this.state.exceptionTypeId === "5") {
            exceptionTripMessage = "Pax Name: " + this.state.paxFirstName.trim() + " " + this.state.paxLastName.trim() + "<br> Reason: " + this.state.cancelReason + "."
        } else if (this.state.exceptionTypeId === "6") {
            exceptionTripMessage = "PU Location: " + this.state.pickupLocation.trim() + " Address: " + this.state.pickupAddress.trim() + " Zone: " + this.state.dropdownZone.find(opt => opt.id == this.state.pickupZoneId).name + "<br> Reason: " + this.state.cancelReason + "."
        } else if (this.state.exceptionTypeId === "7") {
            exceptionTripMessage = "DO Location: " + this.state.dropoffLocation.trim() + " Address: " + this.state.dropoffAddress.trim() + " Zone: " + this.state.dropdownZone.find(opt => opt.id == this.state.dropoffZoneId).name + "<br> Reason: " + this.state.cancelReason + "."
        } else if (this.state.exceptionTypeId === "8") {
            exceptionTripMessage = "Vehicle Type: " + this.state.dropdownVehicleType.find(opt => opt.rowIndex == this.state.vehicleTypeId).apiVehicleTypeDescription + " No Of Pax: " + this.state.noOfPax + "<br> Reason: " + this.state.cancelReason + "."
        } else if (this.state.exceptionTypeId === "9") {
            exceptionTripMessage = "Vehicle No: " + this.state.vehicleNumber.trim() + "<br> Reason: " + this.state.cancelReason + "."
        } else if (this.state.exceptionTypeId === "11") {
            exceptionTripMessage = "Flight No: " + this.state.flightNumber.trim() + "<br> Reason: " + this.state.cancelReason + "."
        } else if (this.state.exceptionTypeId === "10") {

            strMessage = "You are trying to extends the schedules <br> related to Ride# " + this.state.rideId + "<br>"
                + "for Pickup Date range from " + this.state.effectiveDate.toLocaleDateString() + " to " + this.state.untilDate.toLocaleDateString() + "<br>"
                + "Would you like to proceed ?.";
        }

        if (strMessage === "") {
            strMessage = "You are trying to update the records <br> related to Reservation# " + this.state.reservationId + "<br>"
                + "for Pickup Date range from " + this.state.effectiveDate.toLocaleDateString() + " to " + this.state.untilDate.toLocaleDateString() + "<br>"
                + "Details are as below <br>"
                + exceptionTripMessage + "<br><br>"
                + "Would you like to proceed ?.";
        }

        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: strMessage,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue',
            cancelButtonText: 'Close',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    async handleSubmitException(e) {
        e.preventDefault();

        if (this.handleValidation()) {
            var stpuDate = new Date(new Date(this.state.effectiveDate).toLocaleDateString());
            var enDate = new Date(new Date(this.state.untilDate).toLocaleDateString());
            var dayscount = this.diffDays(stpuDate, enDate) + 1;
            if (dayscount <= 0) {
                const isValid = await Swal.fire({
                    title: "<small>Date Range</small>",
                    text: "Please select proper date range!",
                    icon: "info",
                    width: "25%",
                    dangerMode: false,
                    allowOutsideClick: false
                });
                return;
            }

            const isConfirm = await this.askExceptionTripConfirm();
            if (!isConfirm) {
                return;
            } else {
                try {
                    var pickupDate = new Date(new Date(this.state.effectiveDate).toLocaleDateString());
                    var endingDate = new Date(new Date(this.state.untilDate).toLocaleDateString());
                    
                    var newObject = new Object();
                    newObject.ReservationId = parseInt(this.state.reservationId);
                    newObject.RideId = parseInt(this.state.rideId);
                    newObject.PickupDate = ((pickupDate.getMonth() + 1) < 10 ? "0" + (pickupDate.getMonth() + 1) : (pickupDate.getMonth() + 1)) + '/' + (pickupDate.getDate() < 10 ? "0" + pickupDate.getDate() : pickupDate.getDate()) + '/' + pickupDate.getFullYear();
                    newObject.EndingDate = ((endingDate.getMonth() + 1) < 10 ? "0" + (endingDate.getMonth() + 1) : (endingDate.getMonth() + 1)) + '/' + (endingDate.getDate() < 10 ? "0" + endingDate.getDate() : endingDate.getDate()) + '/' + endingDate.getFullYear();
                    newObject.PickupTime = this.state.puDateTime.toLocaleTimeString()
                    newObject.ResonForException = this.state.cancelReason;
                    newObject.AdditionNote = this.state.specialInstruction;
                    newObject.CustomerId = parseInt(this.state.customerId);
                    newObject.CustomerName = this.state.dropdownCustomer.find(opt => opt.id == this.state.customerId).fullName;
                    newObject.PaxName = this.state.paxFirstName.trim() + " " + this.state.paxLastName.trim();
                    newObject.PaxFirstName = this.state.paxFirstName.trim();
                    newObject.PaxLastName = this.state.paxLastName.trim();
                    newObject.PickupHotelId = 0;
                    newObject.PickupStreet = this.state.pickupLocation.trim();
                    newObject.PuZoneId = parseInt(this.state.pickupZoneId);
                    newObject.PuZone = this.state.dropdownZone.find(opt => opt.id == this.state.pickupZoneId).name;
                    newObject.PuLandmark = this.state.pickupAddress.trim();
                    newObject.PuCity = this.state.pickupCity;
                    newObject.PuState = this.state.pickupState;
                    newObject.PuZipCode = this.state.pickupZipCode;
                    newObject.PuLatitude = parseFloat(this.state.pickupLatitude);
                    newObject.PuLongitude = parseFloat(this.state.pickupLongitude);
                    newObject.DropOffHotelId = 0;
                    newObject.DropOffStreet = this.state.dropoffLocation.trim();
                    newObject.DoZoneId = parseInt(this.state.dropoffZoneId);
                    newObject.DoZone = this.state.dropdownZone.find(opt => opt.id == this.state.dropoffZoneId).name;
                    newObject.DoLandmark = this.state.dropoffAddress.trim();
                    newObject.DoCity = this.state.dropoffCity;
                    newObject.DoState = this.state.dropoffState;
                    newObject.DoZipCode = this.state.dropoffZipCode;
                    newObject.DoLatitude = parseFloat(this.state.dropoffLatitude);
                    newObject.DoLongitude = parseFloat(this.state.dropoffLongitude);
                    newObject.NoOfPax = parseInt(this.state.noOfPax);
                    newObject.VehicleTypeId = parseInt(this.state.vehicleTypeId);
                    newObject.Pilot = parseInt(this.state.pilotCount);
                    newObject.Crew = parseInt(this.state.crewCount);
                    newObject.FlightAttendent = parseInt(this.state.flightAttandCount);
                    newObject.VehicleNumber = this.state.vehicleNumber;
                    newObject.RecurringDate = "";
                    newObject.FlightNumber = this.state.flightNumber;
                    newObject.ExceptionTypeId = parseInt(this.state.exceptionTypeId);

                    if (parseInt(this.state.exceptionTypeId) == 10) {
                        newObject.RecurringDate = this.getRecurringPickupDates();
                    }
                    this.setState({ isSubmited: true });
                    const requestParams = getRequestParams('POST', newObject);
                    const response = await fetch('reservation-api/exception-trips', requestParams);
                    const data = await response.json();

                    Swal.fire({
                        icon: data.isSuccess ? 'success' : 'error',
                        title: data.isSuccess ? 'Success' : 'Oops...',
                        text: data.resultMessage,
                        width: "30%",
                        allowOutsideClick: false
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (data.isSuccess) {
                            if (result.isConfirmed) {
                                this.setState({ isSubmited: false });
                                this.closeModal();
                            }
                        } else {
                            this.setState({ isSubmited: false });
                        }
                    })
                } catch (e) {
                    this.setState({ isSubmited: false });
                    console.log(e);
                }
            }
        }
    }

    afterOpenTripExceptionModal() {

    }

    getRecurringPickupDates() {
        var pickupDate = [];
        var recurringDate = "";
        pickupDate = this.getRecurringPuDateArrayList();
        if (pickupDate.length > 0) {
            for (var i = 0; i < pickupDate.length; i++)
            {
                if (recurringDate == "") {
                    recurringDate = pickupDate[i].toString();
                }
                else {
                    recurringDate = recurringDate + "," + pickupDate[i];
                }
            }
        }
        return recurringDate;
    }

    handlePickupDateTimeChange(date) {
        this.setState({ puDateTime: date });
        this.state.errors["puDateTime"] = "";

    }


    handleCustomerChange(selectedOption) {
        this.setState({ customerId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
        this.state.errors["customerId"] = "";
    }


    getRideTypeId(fromZId, toZId) {
        rideTypeId = 0;
        this.setState({ isMeetGreet: false });

        if (fromZId > 0 && toZId > 0) {
            let puZone = this.state.dropdownZone.find(opt => opt.id == fromZId);
            let doZone = this.state.dropdownZone.find(opt => opt.id == toZId);

            if (puZone.zoneTypeId === 1 && doZone.zoneTypeId === 3) {
                this.setState({ isMeetGreet: false });
                rideTypeId = 1;
            } else if (puZone.zoneTypeId === 3 && doZone.zoneTypeId === 1) {
                this.setState({ isMeetGreet: false });
                rideTypeId = 2;
            } else if (puZone.zoneTypeId === 1 && doZone.zoneTypeId === 1) {
                this.setState({ isMeetGreet: false });
                rideTypeId = 3;
            } else {
                this.setState({ isMeetGreet: false });
                rideTypeId = 13;
            }
        }
    }

    async getCustomerList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('account-api/customer-dropdown/', requestParams);
        const data = await response.json();
        this.setState({
            dropdownCustomer: data.customers,
        });
    }

    async getZones() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('serviceAreaZone-api/zone-dropdown/' + 0 + "/" + 0, requestParams);
            const data = await response.json();

            this.setState({
                dropdownZone: data.locationServiceAreaZoneList
            });
        } catch (e) {
            console.log(e);
        }
    }

    async getBookingSuggestions(value) {
        const escapedValue = escapeRegexCharacters(value.trim());

        if (escapedValue === '') {
            return [];
        }

        const requestParams = getRequestParams('GET', '');
        const response = await fetch('address-search-api/0/0/' + value, requestParams);
        const data = await response.json();

        this.setState({ bookingAddress: data === false ? [] : data.addressSearchList })

        const regex = new RegExp('^' + escapedValue, 'i');

        const suggestions = (this.state.bookingAddress.length > 0) ? this.state.bookingAddress.filter(bookingAddress => regex.test(bookingAddress.name)) : [];

        if (suggestions.length === 0) {
            return [
                { isNotFound: true }
            ];
        }

        return suggestions;
    }

    onChangePickupLocation = (event, { newValue }) => {
        this.setState({
            pickupLocation: newValue
        });
        this.state.errors["pickupLocation"] = "";
    };

    onBlurPickupLocation = (event, { value }) => {
        const index2 = (this.state.bookingAddress.length > 0) ? this.state.bookingAddress.map(item => item.name).indexOf(this.state.pickupLocation) : -1;
        if (index2 === -1) {
            this.setState({
                pickupLocation: "",
                pickupAddress: "",
                pickupAddressType: "",
                pickupCity: "",
                pickupState: "",
                pickupZipCode: "",
                pickupZoneId: 0,
                pickupLatitude: 0,
                pickupLongitude: 0
            });
            this.getRideTypeId(0, this.state.dropoffZoneId);
        }
        this.state.errors["pickupLocation"] = "";
    };

    getPickupSuggestionValue(suggestion) {
        if (suggestion.isNotFound) {
            return "";
        }
        this.setState({
            pickupLocation: suggestion.name,
            pickupAddress: suggestion.address,
            pickupAddressType: suggestion.addressType,
            pickupCity: suggestion.city,
            pickupState: suggestion.state,
            pickupZipCode: suggestion.zipCode,
            pickupZoneId: suggestion.zoneId,
            pickupLatitude: suggestion.latitude,
            pickupLongitude: suggestion.longitude
        });
        if (this.state.dropdownZone.findIndex(opt => opt.id == suggestion.zoneId) < 0) {
            this.setState({
                pickupZoneId: 0,
            });
        } else {
            this.getRideTypeId(suggestion.zoneId, this.state.dropoffZoneId);
            this.state.errors["pickupZoneId"] = "";
        }

        this.state.errors["pickupLocation"] = "";
        return suggestion.name;
    }

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onPickupSuggestionsFetchRequested = ({ value }) => {
        this.getBookingSuggestions(value).then(data => this.setState({
            pickupSuggestions: data
        }));
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onPickupSuggestionsClearRequested = () => {
        this.setState({
            pickupSuggestions: []
        });
        this.state.errors["pickupLocation"] = "";
    };

    onChangeDropoffLocation = (event, { newValue }) => {
        this.setState({
            dropoffLocation: newValue
        });

        this.state.errors["dropoffLocation"] = "";
    };


    onBlurDropoffLocation = (event, { value }) => {
        const index2 = (this.state.bookingAddress.length > 0) ? this.state.bookingAddress.map(item => item.name).indexOf(this.state.dropoffLocation) : -1;
        if (index2 === -1) {
            this.setState({
                dropoffLocation: "",
                dropoffAddress: "",
                dropoffAddressType: "",
                dropoffCity: "",
                dropoffState: "",
                dropoffZipCode: "",
                dropoffZoneId: 0,
                dropoffLatitude: 0,
                dropoffLongitude: 0
            });
            this.getRideTypeId(this.state.pickupZoneId, 0);
        }
        this.state.errors["dropoffLocation"] = "";
    };

    getDropoffSuggestionValue(suggestion) {
        if (suggestion.isNotFound) {
            return "";
        }
        this.setState({
            dropoffLocation: suggestion.name,
            dropoffAddress: suggestion.address,
            dropoffAddressType: suggestion.addressType,
            dropoffCity: suggestion.city,
            dropoffState: suggestion.state,
            dropoffZipCode: suggestion.zipCode,
            dropoffZoneId: suggestion.zoneId,
            dropoffLatitude: suggestion.latitude,
            dropoffLongitude: suggestion.longitude
        });

        if (this.state.dropdownZone.findIndex(opt => opt.id == suggestion.zoneId) < 0) {
            this.setState({
                dropoffZoneId: 0,
            });
        } else {
            this.getRideTypeId(this.state.pickupZoneId, suggestion.zoneId);
            this.state.errors["dropoffZoneId"] = "";
        }

        this.state.errors["dropoffLocation"] = "";

        return suggestion.name;
    }

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onDropoffSuggestionsFetchRequested = ({ value }) => {
        this.getBookingSuggestions(value).then(data => this.setState({
            dropoffSuggestions: data
        }));
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onDropoffSuggestionsClearRequested = () => {
        this.setState({
            dropoffSuggestions: []
        });
        this.state.errors["dropoffLocation"] = "";
    };

    handleDayClick(day, { selected }) {
        const { selectedDays } = this.state;
        if (selected) {
            const selectedIndex = selectedDays.findIndex(selectedDay =>
                DateUtils.isSameDay(selectedDay, day)
            );
            selectedDays.splice(selectedIndex, 1);
        } else {
            selectedDays.push(day);
        }
        this.setState({ selectedDays });
        this.state.errors["recurring"] = "";
    }

    handleVehicleTypeChange(selectedOption) {
        this.setState({ vehicleTypeId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.rowIndex : 0), })
        this.state.errors["vehicleTypeId"] = "";
    }

    handlePickupZoneChange(selectedOption) {

        this.setState({ pickupZoneId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
        this.state.errors["pickupZoneId"] = "";
        (selectedOption.selectedItem != null && this.getRideTypeId(selectedOption.selectedItem.id, this.state.dropoffZoneId));

    }
    handleDropoffZoneChange(selectedOption) {
        this.setState({ dropoffZoneId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
        this.state.errors["dropoffZoneId"] = "";
        (selectedOption.selectedItem != null && this.getRideTypeId(this.state.pickupZoneId, selectedOption.selectedItem.id));
    }




    handleExceptionOptionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ exceptionTypeId: value });

        this.setState({
            recurringTypeId: 2,
            selectedDays: [],
            isWeekSun: false,
            isWeekMon: false,
            isWeekTue: false,
            isWeekWed: false,
            isWeekThu: false,
            isWeekFri: false,
            isWeekSat: false,
            isMonthSun: false,
            isMonthMon: false,
            isMonthTue: false,
            isMonthWed: false,
            isMonthThu: false,
            isMonthFri: false,
            isMonthSat: false,
        });
        this.state.errors["recurring"] = "";

    }

    async getRecurringLastPickup() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('reservation-api/' + this.state.reservationId + '/recurring-last-pickup/', requestParams);
        const data = await response.json();
        return data;
    }

    async askExtendScheduleConfirm(result) {
        let isResult = false;
        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: "New schedule start from " + new Date(result.lastPickupDate).toLocaleDateString() + "!<br> Do you want to still continue?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    async handleExtendTripOptionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var result = await this.getRecurringLastPickup();
        if (result.isSuccess == false) {
            const isValid = await Swal.fire({
                title: "<small>Extend Schedule</small>",
                text: result.resultMessage,
                icon: "success",
                width: '25%',
                dangerMode: false,
                allowOutsideClick: false
            });
            this.setState({ exceptionTypeId: "2" });
        } else {
            const isConfirm = await this.askExtendScheduleConfirm(result);
            if (!isConfirm) {
                this.setState({ exceptionTypeId: "2" });
                return;
            }

            this.setState({ exceptionTypeId: value, effectiveDate: new Date(result.lastPickupDate), untilDate: new Date(new Date(result.lastPickupDate).setMonth(new Date(result.lastPickupDate).getMonth() + 1)) });
            this.setState({
                recurringTypeId: 2,
                selectedDays: [],
                isWeekSun: false,
                isWeekMon: false,
                isWeekTue: false,
                isWeekWed: false,
                isWeekThu: false,
                isWeekFri: false,
                isWeekSat: false,
                isMonthSun: false,
                isMonthMon: false,
                isMonthTue: false,
                isMonthWed: false,
                isMonthThu: false,
                isMonthFri: false,
                isMonthSat: false,
            });
            this.state.errors["recurring"] = "";
        }
    }


    handlePickupPlaceSelect() {
        var placeObj = this.handlePlaceSearch(this.autocompletePickup);
        this.state.errors["pickupLocation"] = "";
        if (placeObj !== null) {
            this.setState({
                pickupLocation: placeObj.location,
                pickupAddress: placeObj.address1,
                pickupAddressType: placeObj.addressType,
                pickupCity: placeObj.cityName,
                pickupState: placeObj.stateName,
                pickupZipCode: placeObj.zipCode,
                pickupLatitude: placeObj.latitude,
                pickupLongitude: placeObj.longitude,
            })
        } else {
            this.setState({
                pickupLocation: "",
                pickupAddress: "",
                pickupAddressType: "",
                pickupCity: "",
                pickupState: "",
                pickupZipCode: "",
                pickupLatitude: 0,
                pickupLongitude: 0
            });

        }

    }

    handleDropoffPlaceSelect() {
        var placeObj = this.handlePlaceSearch(this.autocompletePickup);
        this.state.errors["dropoffLocation"] = "";
        if (placeObj !== null) {
            this.setState({
                dropoffLocation: placeObj.location,
                dropoffAddress: placeObj.address1,
                dropoffAddressType: placeObj.addressType,
                dropoffCity: placeObj.cityName,
                dropoffState: placeObj.stateName,
                dropoffZipCode: placeObj.zipCode,
                dropoffLatitude: placeObj.latitude,
                dropoffLongitude: placeObj.longitude,
            })
        } else {
            this.setState({
                dropoffLocation: "",
                dropoffAddress: "",
                dropoffAddressType: "",
                dropoffCity: "",
                dropoffState: "",
                dropoffZipCode: "",
                dropoffLatitude: 0,
                dropoffLongitude: 0
            });

        }

    }

    handlePlaceSearch(autocomplete_place) {
        let place = autocomplete_place.getPlace();

        var placeObject = new Object();

        if (place !== null && place.formatted_address !== null && place.formatted_address.trim() != "") {
            placeObject.location = place.name;
            placeObject.latitude = place.geometry.location.lat();
            placeObject.longitude = place.geometry.location.lng();

            var splitAddress = [];
            place.formatted_address = place.formatted_address.replace(/-/g, ',');
            splitAddress = place.formatted_address.split(',');
            if (splitAddress.length > 0) {
                if (splitAddress.length == 1) {
                    placeObject.address1 = splitAddress[0];
                    placeObject.cityName = "";
                    placeObject.zipCode = "";
                    placeObject.stateName = "";
                    placeObject.countryName = "";
                }
                else if (splitAddress.length == 2) {
                    placeObject.address1 = splitAddress[0];
                    placeObject.cityName = splitAddress[0];
                    placeObject.stateName = splitAddress[1].trim().search(' ') > 0 ? splitAddress[1].trim().split(' ')[0] : splitAddress[1].trim();
                    placeObject.zipCode = splitAddress[1].trim().search(' ') > 0 ? splitAddress[1].trim().split(' ')[1] : " ";
                    placeObject.countryName = "";
                }
                else if (splitAddress.length == 3) {
                    placeObject.address1 = splitAddress[0] + ' ' + splitAddress[1].trim();
                    placeObject.cityName = splitAddress[1].trim();
                    placeObject.stateName = splitAddress[2].trim().search(' ') > 0 ? splitAddress[2].trim().split(' ')[0] : splitAddress[2].trim();
                    placeObject.zipCode = splitAddress[2].trim().search(' ') > 0 ? splitAddress[2].trim().split(' ')[1] : " ";
                    placeObject.countryName = "";
                }
                else if (splitAddress.length == 4) {
                    placeObject.address1 = splitAddress[0] + ' ' + splitAddress[1].trim();
                    placeObject.cityName = splitAddress[1].trim();
                    placeObject.stateName = splitAddress[2].trim().search(' ') > 0 ? splitAddress[2].trim().split(' ')[0] : splitAddress[2].trim();
                    placeObject.zipCode = splitAddress[2].trim().search(' ') > 0 ? splitAddress[2].trim().split(' ')[1] : " ";
                    placeObject.countryName = splitAddress[3].trim();
                }
                else if (splitAddress.length == 5) {
                    placeObject.address1 = splitAddress[0] + ' ' + splitAddress[1].trim() + ' ' + splitAddress[2].trim();
                    placeObject.cityName = splitAddress[2].trim();
                    placeObject.stateName = splitAddress[3].trim().search(' ') > 0 ? splitAddress[3].trim().split(' ')[0] : splitAddress[3].trim();
                    placeObject.zipCode = splitAddress[3].trim().search(' ') > 0 ? splitAddress[3].trim().split(' ')[1] : " ";
                    placeObject.countryName = splitAddress[4].trim();
                }
                else if (splitAddress.length == 6) {
                    placeObject.address1 = splitAddress[0] + ' ' + splitAddress[1].trim() + ' ' + splitAddress[2].trim() + ' ' + splitAddress[3].trim();
                    placeObject.cityName = splitAddress[3].trim();
                    placeObject.stateName = splitAddress[4].trim().search(' ') > 0 ? splitAddress[4].trim().split(' ')[0] : splitAddress[4].trim();
                    placeObject.zipCode = splitAddress[4].trim().search(' ') > 0 ? splitAddress[4].trim().split(' ')[1] : " ";
                    placeObject.countryName = splitAddress[5].trim();
                }
                else {
                    placeObject.address1 = splitAddress[0] + ' ' + splitAddress[1].trim() + ' ' + splitAddress[2].trim() + ' ' + splitAddress[3].trim() + ' ' + splitAddress[4].trim();
                    placeObject.cityName = splitAddress[4].trim();
                    placeObject.stateName = splitAddress[5].trim().search(' ') > 0 ? splitAddress[5].trim().split(' ')[0] : splitAddress[5].trim();
                    placeObject.zipCode = splitAddress[5].trim().search(' ') > 0 ? splitAddress[5].trim().split(' ')[1] : " ";
                    placeObject.countryName = splitAddress[6].trim();
                }
            }
        }
        return placeObject;
    }

    handlePickupToggleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            pickupLocation: "",
            pickupAddress: "",
            pickupAddressType: "",
            pickupCity: "",
            pickupState: "",
            pickupZipCode: "",
            pickupLatitude: 0,
            pickupLongitude: 0
        });
        this.state.errors["pickupLocation"] = "";
        if (value === true) {
            var options = { types: ['establishment'] };
            this.autocompletePickup = new google.maps.places.Autocomplete(document.getElementById('pickupGoogleException'), options);
            this.autocompletePickup.addListener("place_changed", this.handlePickupPlaceSelect);
        }

        this.setState({ isPickupGoogleBooking: value })
    }

    handleDropoffToggleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            dropoffLocation: "",
            dropoffAddress: "",
            dropoffAddressType: "",
            dropoffCity: "",
            dropoffState: "",
            dropoffZipCode: "",
            dropoffLatitude: 0,
            dropoffLongitude: 0
        });
        this.state.errors["dropoffLocation"] = "";

        if (value === true) {
            var options = { types: ['establishment'] };
            this.autocompletePickup = new google.maps.places.Autocomplete(document.getElementById('dropoffGoogleException'), options);
            this.autocompletePickup.addListener("place_changed", this.handleDropoffPlaceSelect);
        }

        this.setState({ isDropoffGoogleBooking: value })
    }

    handlePaxCountChange(pType, value) {
        if (pType === "p") {
            this.setState({ pilotCount: value });

            let paxCount = (parseInt(value | 0) + parseInt(this.state.crewCount | 0) + parseInt(this.state.flightAttandCount | 0));
            this.setState({ noOfPax: paxCount });
            if (paxCount > 0) {
                this.state.errors["pilotCount"] = ""
                this.state.errors["noOfPax"] = ""
            }
        } else if (pType === "c") {
            this.setState({ crewCount: value });

            let paxCount = (parseInt(this.state.pilotCount | 0) + parseInt(value | 0) + parseInt(this.state.flightAttandCount | 0));
            this.setState({ noOfPax: paxCount });
            if (paxCount > 0) {
                this.state.errors["pilotCount"] = ""
                this.state.errors["noOfPax"] = ""
            }
        } else if (pType === "f") {
            this.setState({ flightAttandCount: value });
            let paxCount = (parseInt(this.state.pilotCount | 0) + parseInt(this.state.crewCount | 0) + parseInt(value | 0));
            this.setState({ noOfPax: paxCount });
            if (paxCount > 0) {
                this.state.errors["pilotCount"] = ""
                this.state.errors["noOfPax"] = ""
            }

        } else if (pType === "total") {
            this.setState({ noOfPax: value });

            let paxCount = (parseInt(value));
            if (paxCount > 0) {
                this.state.errors["noOfPax"] = ""
            }
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === "recurringTypeId") {
            this.setState({
                selectedDays: [],
                isWeekSun: false,
                isWeekMon: false,
                isWeekTue: false,
                isWeekWed: false,
                isWeekThu: false,
                isWeekFri: false,
                isWeekSat: false,
                isMonthSun: false,
                isMonthMon: false,
                isMonthTue: false,
                isMonthWed: false,
                isMonthThu: false,
                isMonthFri: false,
                isMonthSat: false,
            });
            this.setState({
                [name]: value
            });
            this.state.errors["recurring"] = "";
        } else {
            this.setState({
                [name]: value
            });
            this.state.errors[name] = "";
        }
    }

    async getVehicleType() {
        try {
            var newObject = new Object();
            const requestParams = getRequestParams('POST', newObject);
            const response = await fetch('vehicletype-api/vehicletype', requestParams);
            const data = await response.json();

            this.setState({
                dropdownVehicleType: data.apiVehicleType
            });
        } catch (e) {
            console.log(e);
        }
    }

    getRecurringPuDateArrayList() {
        var puDateArrayList = [];

        if (this.state.recurringTypeId == 1) {
            puDateArrayList = this.getDailyRecurringDays();
        } else if (this.state.recurringTypeId == 2) {
            puDateArrayList = this.getWeeklyRecurringDays();
        } else if (this.state.recurringTypeId == 3) {
            puDateArrayList = this.getMonthlyRecurringDays();
        }

        return puDateArrayList;
    }

    diffDays(d1, d2) {
        var ndays;
        var tv1 = d1.valueOf();  // msec since 1970
        var tv2 = d2.valueOf();

        ndays = (tv2 - tv1) / 1000 / 86400;
        ndays = Math.round(ndays - 0.5);
        return ndays;
    }

    getDailyRecurringDays() {
        var finalArrayList = [];

        var stpuDate = new Date(new Date(this.state.puDateTime).toLocaleDateString());
        var enDate = new Date(new Date(this.state.untilDate).toLocaleDateString());
        var dayscount = this.diffDays(stpuDate, enDate) + 1;

        var chkPuDay = [];
        chkPuDay = this.getDaysOfDailyRec();
        for (var day = 1; day <= dayscount; day++) {
            for (var scount = 0; scount < chkPuDay.length; scount++) {
                if ((stpuDate.toLocaleDateString()) === chkPuDay[scount]) {
                    finalArrayList.push(((stpuDate.getMonth() + 1) < 10 ? "0" + (stpuDate.getMonth() + 1) : (stpuDate.getMonth() + 1)) + '/' + (stpuDate.getDate() < 10 ? "0" + stpuDate.getDate() : stpuDate.getDate()) + '/' + stpuDate.getFullYear());
                }
            }
            stpuDate.setDate(stpuDate.getDate() + 1);
        }

        return finalArrayList;
    }

    getWeeklyRecurringDays() {
        var finalArrayList = [];

        var stpuDate = new Date(new Date(this.state.puDateTime).toLocaleDateString());
        var enDate = new Date(new Date(this.state.untilDate).toLocaleDateString());

        var dayscount = this.diffDays(stpuDate, enDate) + 1;

        var chkPuDay = [];
        chkPuDay = this.getDaysOfWeeklyRec();
        for (var day = 1; day <= dayscount; day++) {
            for (var scount = 0; scount < chkPuDay.length; scount++) {
                if (stpuDate.getDay() == chkPuDay[scount].toString()) {
                    finalArrayList.push(((stpuDate.getMonth() + 1) < 10 ? "0" + (stpuDate.getMonth() + 1) : (stpuDate.getMonth() + 1)) + '/' + (stpuDate.getDate() < 10 ? "0" + stpuDate.getDate() : stpuDate.getDate()) + '/' + stpuDate.getFullYear());
                }
            }
            stpuDate.setDate(stpuDate.getDate() + 1);
        }
        return finalArrayList;
    }

    getMonthlyRecurringDays() {
        var finalArrayList = [];

        var stpuDate = new Date(new Date(this.state.puDateTime).toLocaleDateString());
        var enDate = new Date(new Date(this.state.untilDate).toLocaleDateString());

        var dayscount = this.diffDays(stpuDate, enDate) + 1;
        var chkPuDay = [];
        chkPuDay = this.getDaysOfMonthlyRec();
        for (var day = 1; day <= dayscount; day++) {
            for (var scount = 0; scount < chkPuDay.length; scount++) {
                if (stpuDate.getDay() == chkPuDay[scount].toString()) {
                    finalArrayList.push(((stpuDate.getMonth() + 1) < 10 ? "0" + (stpuDate.getMonth() + 1) : (stpuDate.getMonth() + 1)) + '/' + (stpuDate.getDate() < 10 ? "0" + stpuDate.getDate() : stpuDate.getDate()) + '/' + stpuDate.getFullYear());
                }
            }
            stpuDate.setDate(stpuDate.getDate() + 1);
        }
        return finalArrayList;
    }

    getDaysOfDailyRec() {
        var chkPuDate = [];

        var allDays = [];
        allDays = this.state.selectedDays;
        for (var i = 0; i < allDays.length; i++) {
            chkPuDate.push(allDays[i].toLocaleDateString());
        }

        return chkPuDate;
    }

    getDaysOfWeeklyRec() {
        var chkPuDays = [];
        if (this.state.isWeekMon === true) {
            chkPuDays.push(1);
        }
        if (this.state.isWeekTue === true) {
            chkPuDays.push(2);
        }
        if (this.state.isWeekWed === true) {
            chkPuDays.push(3);
        }
        if (this.state.isWeekThu === true) {
            chkPuDays.push(4);
        }
        if (this.state.isWeekFri === true) {
            chkPuDays.push(5);
        }
        if (this.state.isWeekSat === true) {
            chkPuDays.push(6);
        }
        if (this.state.isWeekSun === true) {
            chkPuDays.push(0);
        }

        return chkPuDays;
    }

    getDaysOfMonthlyRec() {
        var chkPuMonths = [];
        if (this.state.isMonthMon === true) {
            chkPuMonths.push(1);
        }
        if (this.state.isMonthTue === true) {
            chkPuMonths.push(2);
        }
        if (this.state.isMonthWed === true) {
            chkPuMonths.push(3);
        }
        if (this.state.isMonthThu === true) {
            chkPuMonths.push(4);
        }
        if (this.state.isMonthFri === true) {
            chkPuMonths.push(5);
        }
        if (this.state.isMonthSat === true) {
            chkPuMonths.push(6);
        }
        if (this.state.isMonthSun === true) {
            chkPuMonths.push(0);
        }

        return chkPuMonths;
    }

    render() {
        const filterPassedTime = time => {
            const currentDate = currentTime;
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }
        const inputPropsPickup = {
            placeholder: 'Enter pickup location.',
            value: this.state.pickupLocation,
            onChange: this.onChangePickupLocation,
            onBlur: this.onBlurPickupLocation,
        };
        const inputPropsDropoff = {
            placeholder: 'Enter dropoff location.',
            value: this.state.dropoffLocation,
            onChange: this.onChangeDropoffLocation,
            onBlur: this.onBlurDropoffLocation,
        };
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={customStyles}
                    className={"react-modal"}
                    onAfterOpen={this.afterOpenTripExceptionModal}
                    onRequestClose={this.closeModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleSubmitException} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Add Exception - {this.state.rideId}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body" id="confirmdiv">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <div className="col-12"><label>Reservation #</label></div>
                                        <div><label><b>{this.state.reservationId}</b></label></div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <div className="col-12"><label>Pickup Date Time</label></div>
                                        <div><label>{this.state.pickupDateTimeStr}</label></div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>Effective Date</label>
                                        <DatePicker
                                            className="form-control form-control-sm "
                                            placeholderText="Select check date"
                                            name="effectiveDate"
                                            selected={this.state.effectiveDate}
                                            dateFormat="MM/dd/yyyy"
                                            onChange={date => this.setState({ effectiveDate: date })}
                                            disabled={false}
                                        />
                                        {this.state.errors["effectiveDate"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["effectiveDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>Until Date</label>
                                        <DatePicker
                                            className="form-control form-control-sm "
                                            placeholderText="Select check date"
                                            name="untilDate"
                                            selected={this.state.untilDate}
                                            dateFormat="MM/dd/yyyy"
                                            onChange={date => this.setState({ untilDate: date })}
                                            disabled={false}
                                            minDate={this.state.effectiveDate}
                                        />
                                        {this.state.errors["untilDate"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["untilDate"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="newPickupTime" value="1" checked={this.state.exceptionTypeId === "1"} onChange={this.handleExceptionOptionChange} />
                                            <label className="form-check-label">Pickup Time</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="cancelRide" value="2" checked={this.state.exceptionTypeId === "2"} onChange={this.handleExceptionOptionChange} />
                                            <label className="form-check-label">Cancel</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="addNote" value="3" checked={this.state.exceptionTypeId === "3"} onChange={this.handleExceptionOptionChange} />
                                            <label className="form-check-label">Add Note</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="account" value="4" checked={this.state.exceptionTypeId === "4"} onChange={this.handleExceptionOptionChange} />
                                            <label className="form-check-label">Account</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="paxName" value="5" checked={this.state.exceptionTypeId === "5"} onChange={this.handleExceptionOptionChange} />
                                            <label className="form-check-label">Pax Name</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="pickupStreet" value="6" checked={this.state.exceptionTypeId === "6"} onChange={this.handleExceptionOptionChange} />
                                            <label className="form-check-label">PU Street</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="dropoffStreet" value="7" checked={this.state.exceptionTypeId === "7"} onChange={this.handleExceptionOptionChange} />
                                            <label className="form-check-label">DO Street</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="noOfPax" value="8" checked={this.state.exceptionTypeId === "8"} onChange={this.handleExceptionOptionChange} />
                                            <label className="form-check-label">No Of Pax</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="vehicleNo" value="9" checked={this.state.exceptionTypeId === "9"} onChange={this.handleExceptionOptionChange} />
                                            <label className="form-check-label">Vehicle No</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="flightNumber" value="11" checked={this.state.exceptionTypeId === "11"} onChange={this.handleExceptionOptionChange} />
                                            <label className="form-check-label">Flight Number</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="extendSchedule" value="10" checked={this.state.exceptionTypeId === "10"} onChange={this.handleExtendTripOptionChange} />
                                            <label className="form-check-label">Extend Schedule</label>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <hr />
                            <div className="row">
                                {(this.state.exceptionTypeId === "4") && <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Customer <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.dropdownCustomer.length > 0 ? this.state.dropdownCustomer : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="customerId"
                                            defaultValue={0}
                                            value={this.state.customerId}
                                            displayExpr='fullName'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleCustomerChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerId"]}</span>}
                                    </div>
                                </div>}
                                {(this.state.exceptionTypeId === "5") && <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Passenger First Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" maxLength={50} value={this.state.paxFirstName} onChange={this.handleInputChange} name="paxFirstName" />
                                        {this.state.errors["paxFirstName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["paxFirstName"]}</span>}
                                    </div>
                                </div>}
                                {(this.state.exceptionTypeId === "5") && <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Passenger Last Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" maxLength={50} value={this.state.paxLastName} onChange={this.handleInputChange} name="paxLastName" />
                                        {this.state.errors["paxLastName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["paxLastName"]}</span>}
                                    </div>
                                </div>}
                                {(this.state.exceptionTypeId === "6") && <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group custom-control custom-switch swicth-group-checkbox">
                                        <input type="checkbox" className="custom-control-input" onChange={this.handlePickupToggleChange} id="customSwitchPickupPopup" />
                                        <label className="custom-control-label" style={{ paddingTop: 5 + "px" }} htmlFor="customSwitchPickupPopup">Add Google Address</label>
                                    </div>

                                    <div className="row" style={{ display: this.state.isPickupGoogleBooking ? 'none' : 'flex', marginLeft: 0 + 'px', marginRight: 0 + 'px' }}>
                                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <div className="form-group">
                                                <label>Pick-up Location <span className="text-danger">*</span></label>
                                                <Autosuggest
                                                    name="pickupLocation"
                                                    focusInputOnSuggestionClick={true}
                                                    suggestions={this.state.pickupSuggestions}
                                                    onSuggestionsFetchRequested={this.onPickupSuggestionsFetchRequested}
                                                    onSuggestionsClearRequested={this.onPickupSuggestionsClearRequested}
                                                    getSuggestionValue={this.getPickupSuggestionValue}
                                                    renderSuggestion={renderSuggestion}
                                                    shouldRenderSuggestions={shouldRenderSuggestions}
                                                    inputProps={inputPropsPickup}
                                                    highlightFirstSuggestion={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <div className="form-group">
                                                <label>Pick-up Address</label>
                                                <input type="text" className="form-control form-control-sm" placeholder="" name="pickupAddress" maxLength="150" title="Pickup Address" value={this.state.pickupAddress} onChange={this.handleInputChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{ display: this.state.isPickupGoogleBooking ? 'flex' : 'none', marginLeft: 0 + 'px', marginRight: 0 + 'px' }}>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label>Pick-up Location & Address <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control form-control-sm" id="pickupGoogleException" placeholder="'Type' slowly to search location" maxLength="150" title="Pickup Location & Address" />
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.errors["pickupLocation"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["pickupLocation"]}</span>}
                                    <div className="row" style={{ marginLeft: 0 + 'px', marginRight: 0 + 'px' }}>

                                        <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                            <div className="form-group">
                                                <label>Pick-up Zone <span className="text-danger">*</span></label>
                                                <SelectBox
                                                    dataSource={this.state.dropdownZone.length > 0 ? this.state.dropdownZone : []}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Select pick-up zone"
                                                    name="pickupZoneId"
                                                    defaultValue={0}
                                                    value={this.state.pickupZoneId}
                                                    displayExpr='name'
                                                    valueExpr='id'
                                                    onSelectionChanged={this.handlePickupZoneChange}
                                                    showClearButton={true}
                                                    disabled={false}
                                                    searchEnabled={true}
                                                />
                                                {this.state.errors["pickupZoneId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["pickupZoneId"]}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {(this.state.exceptionTypeId === "7") && <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group custom-control custom-switch swicth-group-checkbox">
                                        <input type="checkbox" className="custom-control-input" onChange={this.handleDropoffToggleChange} id="customSwitchDropoffPopup" />
                                        <label className="custom-control-label" style={{ paddingTop: 5 + "px" }} htmlFor="customSwitchDropoffPopup">Add Google Address</label>
                                    </div>
                                    <div className="row" style={{ display: this.state.isDropoffGoogleBooking ? 'none' : 'flex', marginLeft: 0 + 'px', marginRight: 0 + 'px' }}>
                                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <div className="form-group">
                                                <label>Drop Off Location <span className="text-danger">*</span></label>
                                                <Autosuggest
                                                    name="dropoffLocation"
                                                    focusInputOnSuggestionClick={true}
                                                    suggestions={this.state.dropoffSuggestions}
                                                    onSuggestionsFetchRequested={this.onDropoffSuggestionsFetchRequested}
                                                    onSuggestionsClearRequested={this.onDropoffSuggestionsClearRequested}
                                                    getSuggestionValue={this.getDropoffSuggestionValue}
                                                    renderSuggestion={renderSuggestion}
                                                    shouldRenderSuggestions={shouldRenderSuggestions}
                                                    inputProps={inputPropsDropoff}
                                                    highlightFirstSuggestion={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <div className="form-group">
                                                <label>Drop Off Address</label>
                                                <input type="text" className="form-control form-control-sm" placeholder="" name="dropoffAddress" maxLength="150" title="Dropoff Address" value={this.state.dropoffAddress} onChange={this.handleInputChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{ display: this.state.isDropoffGoogleBooking ? 'flex' : 'none', marginLeft: 0 + 'px', marginRight: 0 + 'px' }}>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label>Drop-off Location & Address <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control form-control-sm" id="dropoffGoogleException" placeholder="'Type' slowly to search location" maxLength="150" title="Dropoff Location & Address" />
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.errors["dropoffLocation"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["dropoffLocation"]}</span>}
                                    <div className="row" style={{ marginLeft: 0 + 'px', marginRight: 0 + 'px' }}>
                                        <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                            <div className="form-group">
                                                <label>Drop Off Zone <span className="text-danger">*</span></label>
                                                <SelectBox
                                                    dataSource={this.state.dropdownZone.length > 0 ? this.state.dropdownZone : []}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Select drop off zone"
                                                    name="dropoffZoneId"
                                                    defaultValue={0}
                                                    value={this.state.dropoffZoneId}
                                                    displayExpr='name'
                                                    valueExpr='id'
                                                    onSelectionChanged={this.handleDropoffZoneChange}
                                                    showClearButton={true}
                                                    disabled={false}
                                                    searchEnabled={true}
                                                />
                                                {this.state.errors["dropoffZoneId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["dropoffZoneId"]}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {(this.state.exceptionTypeId === "1") && <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>Pickup Time <span className="text-danger">*</span></label>
                                        <DatePicker
                                            className="form-control form-control-sm"
                                            name="puDateTime"
                                            selected={this.state.puDateTime}
                                            onChange={this.handlePickupDateTimeChange}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm"
                                            timeFormat="HH:mm"
                                            filterTime={filterPassedTime}
                                        />
                                        {this.state.errors["puDateTime"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["puDateTime"]}</span>}
                                    </div>
                                </div>}
                                {(this.state.exceptionTypeId === "8") && <div className="col-lg-5 col-md-5 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Vehicle Type <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.dropdownVehicleType.length > 0 ? this.state.dropdownVehicleType : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select vehicle type"
                                            name="vehicleTypeId"
                                            defaultValue={0}
                                            value={this.state.vehicleTypeId}
                                            displayExpr='apiVehicleTypeDescription'
                                            valueExpr='rowIndex'
                                            onSelectionChanged={this.handleVehicleTypeChange}
                                            showClearButton={false}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["vehicleTypeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["vehicleTypeId"]}</span>}
                                    </div>
                                </div>}
                                {(this.state.exceptionTypeId === "8") && <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>Pax# <span className="text-danger">*</span></label>
                                        <NumericInput className="form-control form-control-sm" name="noOfPax" title="Number of Pax" disabled={this.state.customerTypeId == 1 || this.state.customerTypeId == 2} value={this.state.noOfPax} min={1} max={100} onChange={(value) => { this.handlePaxCountChange("total", value) }} />
                                        {this.state.errors["noOfPax"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["noOfPax"]}</span>}
                                    </div>
                                </div>}
                                {(this.state.exceptionTypeId === "8" && (this.state.customerTypeId == 1 || this.state.customerTypeId == 2)) && <div className="col-lg-1 col-md-1 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>Pilot <span className="text-danger">*</span></label>
                                        <NumericInput className="form-control form-control-sm" name="pilotCount" title="Pilot Count" value={this.state.pilotCount} min={0} max={100} onChange={(value) => { this.handlePaxCountChange("p", value) }} />
                                        {this.state.errors["pilotCount"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["pilotCount"]}</span>}
                                    </div>
                                </div>}
                                {(this.state.exceptionTypeId === "8" && (this.state.customerTypeId == 1 || this.state.customerTypeId == 2)) && <div className="col-lg-1 col-md-1 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>Crew <span className="text-danger">*</span></label>
                                        <NumericInput className="form-control form-control-sm" name="crewCount" title="Crew Count" value={this.state.crewCount} min={0} max={100} onChange={(value) => { this.handlePaxCountChange("c", value) }} />
                                    </div>
                                </div>}
                                {(this.state.exceptionTypeId === "8" && (this.state.customerTypeId == 1 || this.state.customerTypeId == 2)) && <div className="col-lg-1 col-md-1 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>F/A <span className="text-danger">*</span></label>
                                        <NumericInput className="form-control form-control-sm" name="flightAttandCount" title="Flight Attendant Count" value={this.state.flightAttandCount} min={0} max={100} onChange={(value) => { this.handlePaxCountChange("f", value) }} />
                                    </div>
                                </div>}
                                {(this.state.exceptionTypeId === "3") && <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Special Instruction <span className="text-danger">*</span></label>
                                        <textarea className="form-control form-control-sm" rows="3" name="specialInstruction" placeholder="Please enter special instruction." maxLength="500" title="Special Instruction" value={this.state.specialInstruction} onChange={this.handleInputChange} />
                                        {this.state.errors["specialInstruction"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["specialInstruction"]}</span>}
                                    </div>
                                </div>}
                                {(this.state.exceptionTypeId === "9") && <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Vehicle Number <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" maxLength={50} value={this.state.vehicleNumber} onChange={this.handleInputChange} name="vehicleNumber" />
                                        {this.state.errors["vehicleNumber"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["vehicleNumber"]}</span>}
                                    </div>
                                </div>}

                                {(this.state.exceptionTypeId === "11") && <div className="col-lg-3 col-md-3 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>Flight # <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" maxLength={25} value={this.state.flightNumber} onChange={this.handleInputChange} name="flightNumber" />
                                        {this.state.errors["flightNumber"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["flightNumber"]}</span>}
                                    </div>
                                </div>}
                                {(this.state.exceptionTypeId === "10") && <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Repeat Type <span className="text-danger">*</span></label>
                                        <select className="form-control form-control-sm col-lg-3" name="recurringTypeId" value={this.state.recurringTypeId} onChange={this.handleInputChange}>
                                            <option value="1">Daily</option>
                                            <option value="2">Weekly</option>
                                            <option value="3">Monthly</option>
                                        </select>
                                    </div>
                                </div>}
                                {(this.state.recurringTypeId == 1 && this.state.exceptionTypeId === "10") && <div id="recurringDaily">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <div id="divrecurringDaily">
                                                    <DayPicker
                                                        selectedDays={this.state.selectedDays}
                                                        onDayClick={this.handleDayClick}
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {(this.state.recurringTypeId == 2 && this.state.exceptionTypeId === "10") && <div id="recurringWeekly">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <span className="form-check">
                                                <input className="form-check-input" type="checkbox" checked={this.state.isWeekSun} onChange={this.handleInputChange} name="isWeekSun" />
                                                <label className="form-check-label">SUN</label>
                                            </span>
                                            <span className="form-check">
                                                <input className="form-check-input" type="checkbox" checked={this.state.isWeekMon} onChange={this.handleInputChange} name="isWeekMon" />
                                                <label className="form-check-label">MON</label>
                                            </span>
                                            <span className="form-check">
                                                <input className="form-check-input" type="checkbox" checked={this.state.isWeekTue} onChange={this.handleInputChange} name="isWeekTue" />
                                                <label className="form-check-label">TUE</label>
                                            </span>
                                            <span className="form-check">
                                                <input className="form-check-input" type="checkbox" checked={this.state.isWeekWed} onChange={this.handleInputChange} name="isWeekWed" />
                                                <label className="form-check-label">WED</label>
                                            </span>
                                            <span className="form-check">
                                                <input className="form-check-input" type="checkbox" checked={this.state.isWeekThu} onChange={this.handleInputChange} name="isWeekThu" />
                                                <label className="form-check-label">THU</label>
                                            </span>
                                            <span className="form-check">
                                                <input className="form-check-input" type="checkbox" checked={this.state.isWeekFri} onChange={this.handleInputChange} name="isWeekFri" />
                                                <label className="form-check-label">FRI</label>
                                            </span>
                                            <span className="form-check">
                                                <input className="form-check-input" type="checkbox" checked={this.state.isWeekSat} onChange={this.handleInputChange} name="isWeekSat" />
                                                <label className="form-check-label">SAT</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>}
                                {(this.state.recurringTypeId == 3 && this.state.exceptionTypeId === "10") && <div id="recurringMonthly">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <span className="form-check">
                                                <input className="form-check-input" type="checkbox" checked={this.state.isMonthSun} onChange={this.handleInputChange} name="isMonthSun" />
                                                <label className="form-check-label">SUN</label>
                                            </span>
                                            <span className="form-check">
                                                <input className="form-check-input" type="checkbox" checked={this.state.isMonthMon} onChange={this.handleInputChange} name="isMonthMon" />
                                                <label className="form-check-label">MON</label>
                                            </span>
                                            <span className="form-check">
                                                <input className="form-check-input" type="checkbox" checked={this.state.isMonthTue} onChange={this.handleInputChange} name="isMonthTue" />
                                                <label className="form-check-label">TUE</label>
                                            </span>
                                            <span className="form-check">
                                                <input className="form-check-input" type="checkbox" checked={this.state.isMonthWed} onChange={this.handleInputChange} name="isMonthWed" />
                                                <label className="form-check-label">WED</label>
                                            </span>
                                            <span className="form-check">
                                                <input className="form-check-input" type="checkbox" checked={this.state.isMonthThu} onChange={this.handleInputChange} name="isMonthThu" />
                                                <label className="form-check-label">THU</label>
                                            </span>
                                            <span className="form-check">
                                                <input className="form-check-input" type="checkbox" checked={this.state.isMonthFri} onChange={this.handleInputChange} name="isMonthFri" />
                                                <label className="form-check-label">FRI</label>
                                            </span>
                                            <span className="form-check">
                                                <input className="form-check-input" type="checkbox" checked={this.state.isMonthSat} onChange={this.handleInputChange} name="isMonthSat" />
                                                <label className="form-check-label">SAT</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>}
                                {this.state.errors["recurring"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["recurring"]}</span>}
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Exception Reason <span className="text-danger">*</span></label>
                                        <textarea className="form-control form-control-sm" rows="3" name="cancelReason" placeholder="Please enter exception reason." maxLength="500" title="Cancel Reason" value={this.state.cancelReason} onChange={this.handleInputChange} />
                                        {this.state.errors["cancelReason"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["cancelReason"]}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success btn-sm" onClick={this.handleSubmitException} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Confirm"><i className="fas fa-check"></i> Confirm</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeModal}><i className="fas fa-times"></i> Close</button>
                        </div>
                        <LoadPanel
                            shadingColor="rgba(0,0,0,0.4)"
                            position={position}
                            visible={this.state.loadPanelVisible}
                            showIndicator={true}
                            shading={true}
                            showPane={true}
                            closeOnOutsideClick={false}
                        />
                    </form>
                </Modal>
            </div>
        );
    }
}