import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import DateBox from 'devextreme-react/date-box';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions';
import { LoadPanel } from 'devextreme-react/load-panel';
const position = { of: '#historydiv' };

export class WeeklyRevenueReportComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            isOpenModal: false,
            loadPanelVisible: false,

            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),
            invoiceReceiptVoucher: [],
            errors: {
                startDate: '',
                endDate: '',
            }
        }


        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.handleFromDateChanged = this.handleFromDateChanged.bind(this);
        this.handleToDateChanged = this.handleToDateChanged.bind(this);

        this.handleSearchAccountPaySummaryReportSubmit = this.handleSearchAccountPaySummaryReportSubmit.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);

        this.emailReports = this.emailReports.bind(this);
        this.getInvoiceReceiptVoucherReport = this.getInvoiceReceiptVoucherReport.bind(this);
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true,
        });
        this.setState({
            loadPanelVisible: false,
        });
    }


    onToolbarPreparing(e) {

        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'email',
                onClick: this.emailReports.bind(this),
                hint: 'Email'
            }
        });

        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getInvoiceReceiptVoucherReport.bind(this),
                hint: 'Refresh'
            }
        });
    }

    handleFromDateChanged(event) {
        let fromDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ startDate: fromDateValue })
    }
    handleToDateChanged(event) {
        let toDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ endDate: toDateValue })
    }

    async emailReports() {
        const isValid = await Swal.fire({
            title: "<small>Pending Implementation</small>",
            text: "This feature is comming soon.",
            icon: "info",
            dangerMode: false,
            width: "25%",
            dangerMode: false,
            allowOutsideClick: false
        });
        return;
    }

    async getInvoiceReceiptVoucherReport() {

        var request = new Object();
        request.LocationId = Number(sessionStorage.getItem("session_locationId"));
        request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy") + " 00:00:01";
        request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy") + " 23:59:59";


        try {
            this.setState({
                isSubmited: true,
            });
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('report-api/weekly-revenue-summary', requestParams);
            const data = await response.json();
            this.setState({
                invoiceReceiptVoucher: data.rptWeeklyRevenueSummary
            });
            this.setState({ isSubmited: false });

        } catch (e) {
            this.setState({ isSubmited: false });
        }

    }

    handlePayPeriodChange(selectedOption) {
        this.setState({ payEndDate: (selectedOption != null ? selectedOption.payDate : ''), })
        this.state.errors["payEndDate"] = "";
    }

    async handleSearchAccountPaySummaryReportSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            await this.getInvoiceReceiptVoucherReport();
            //this.downloadAccountPaymentTypeSummaryAsPdf();
        }
    }
    handleClearSearch() {
        this.setState({
            isSubmited: false,
            isOpenModal: false,
            loadPanelVisible: false,

            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),
            errors: {
                startDate: '',
                endDate: '',
            }
        });
    }

    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.startDate = "";
        errors.endDate = "";

        if (this.state.startDate == "") {
            formIsValid = false;
            errors.startDate = "Please select start date.";
        }

        if (this.state.endDate == "") {
            formIsValid = false;
            errors.endDate = "Please select end date.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    render() {
        const renderGridCurrencyCell = (data) => {
            return <div>${parseFloat(data.value).toFixed(2)}</div>;
        }

        return (

            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-sm-6 col-md-6 col-lg-6">
                                <h1 className="m-0 text-dark">Weekly Revenue Report</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem> <NavLink tag={Link} to="/report" className="btn btn-block btn-outline-secondary btn-sm" title="Reports" ><i className="fas fa-arrow-left"></i> Back</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <form method="post" onSubmit={this.handleSearchAccountPaySummaryReportSubmit} style={(this.state.isSubmited) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="rez-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>Start Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.startDate}
                                            value={this.state.startDate}
                                            onValueChanged={this.handleFromDateChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["startDate"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["startDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>To Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.endDate}
                                            value={this.state.endDate}
                                            onValueChanged={this.handleToDateChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["endDate"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["endDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label className="btn-block">&nbsp;</label>
                                        <button type="submit" className="btn btn-success btn-sm" onClick={this.handleSearchAccountPaySummaryReportSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                            {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSubmited && <span title="Search"><i className="fas fa-search"></i> Search</span>}
                                        </button>&nbsp; &nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleClearSearch} title="Clear"><i className="fas fa-eraser"></i> Clear</button>&nbsp; &nbsp;
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </form>
                <div className="content">
                    <div className="card">
                        <div className="card-body">
                            <DataGrid
                                dataSource={this.state.invoiceReceiptVoucher}
                                keyExpr="weekDate"
                                columnsAutoWidth={true}
                                showColumnLines={false}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                showBorders={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                onToolbarPreparing={this.onToolbarPreparing}>
                                <Column dataField="weekDate" caption="Week Date" allowFiltering={true} allowSorting={true} />
                                <Column dataField="customerTypeName" caption="Customer Type" allowFiltering={true} allowSorting={true} />
                                <Column dataField="totalRides" caption="Total Rides" allowFiltering={true} allowSorting={true} />
                                <Column dataField="revenue" caption="Revenue" allowSorting={true} cellRender={renderGridCurrencyCell} />

                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={false} />
                                <HeaderFilter visible={false} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                <Export enabled={true} allowExportSelectedData={false} />
                                <ColumnChooser enabled={false} />
                                <ColumnFixing enabled={false} />
                            </DataGrid>
                        </div>
                    </div>

                </div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        )
    }
}