import React, { Component } from 'react';
import DateBox from 'devextreme-react/date-box';
import { CheckBox } from 'devextreme-react/check-box';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import Swal from 'sweetalert2';
import { LoadPanel } from 'devextreme-react/load-panel';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter,
    SearchPanel,
    SortByGroupSummaryInfo,
    Paging,
    Pager,
    Scrolling,
}
    from 'devextreme-react/data-grid';
import { ApplicationType, DateFormat, ProductType } from '../../helpers/fixcodes.js'

let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));

export class PasswordPolicyConfigComponent extends Component {
    static displayName = PasswordPolicyConfigComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            loadPanelVisible: false,
            expiryDays: 0,
            minPasswordLength: 0,
            isNewUserPasswordReset: false,
            isNoRepeat: false,
            noRepeatTimes: 0,
            passwordResetEmailNotifications: 0,
            noOfDaysDefore: 0,
            isPasswordAuditPolicy: false,
            isPasswordComplexity: false,
            minUppercaseLetter: 0,
            minLowercaseLetter: 0,
            minNumber: 0,
            minSpecialCharacter: 0,
            isContainUserId: false,
            fromDate: new Date(),
            toDate: new Date(),
            wefDate: formatDate(currentTime, "MM/dd/yyyy hh:mm"),
            appTypeId: parseInt(ApplicationType.UTPro),
            productTypeId: parseInt(ProductType.UTWiz),
            passwordPolicy: [],
            systemPasswordPolicy: [],
            errors: {
                expiryDays: 0,
                minPasswordLength: 0,
                minUppercaseLetter:0,
                minLowercaseLetter: 0,
                minNumber: 0,
                minSpecialCharacter: 0,
                noRepeatTimes: 0,
            }
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.getSystemPasswordPolicy = this.getSystemPasswordPolicy.bind(this);
        this.getPasswordPolicy = this.getPasswordPolicy.bind(this);
        this.handleWefDateChanged = this.handleWefDateChanged.bind(this);
        this.handlePasswordPolicySubmit = this.handlePasswordPolicySubmit.bind(this);
    }

    async componentDidMount() {
        this.setState({
            loadPanelVisible: true,
        });
        await this.getSystemPasswordPolicy();
        await this.getPasswordPolicy();
        this.setState({
            loadPanelVisible: false,
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name
        
        if (name == "expiryDays") {
            const re = /^[0-9-\b]+$/;
            
            this.setState({ expiryDays: event.target.value });
            if (event.target.value === '' || re.test(event.target.value)) {
                if (event.target.value == "") {
                    this.state.errors[name] = "Please enter expiry days";
                }
                else if (parseInt(event.target.value) < this.state.systemPasswordPolicy.expiryDays || parseInt(event.target.value) > this.state.systemPasswordPolicy.maxExpiryDays) {
                    this.state.errors[name] = "Expiry days should be between " + this.state.systemPasswordPolicy.expiryDays + " to " + this.state.systemPasswordPolicy.maxExpiryDays;
                }
                else {
                    this.state.errors[name] = "";
                }
            }
            else {
                this.setState({ expiryDays: "" });
                this.state.errors[name] = "Please enter expiry days";
            }
        }
        else if (name == "minPasswordLength") {
            const re = /^[0-9-\b]+$/;
            
            this.setState({ minPasswordLength: event.target.value });
            if (event.target.value === '' || re.test(event.target.value)) {
                if (event.target.value == "") {
                    this.state.errors[name] = "Please enter password length";
                }
                else if (parseInt(event.target.value) < this.state.systemPasswordPolicy.minLength || parseInt(event.target.value) > this.state.systemPasswordPolicy.maxLength) {
                    this.state.errors[name] = "Password length should be between " + this.state.systemPasswordPolicy.minLength + " to " + this.state.systemPasswordPolicy.maxLength;
                }
                else {
                    this.state.errors[name] = "";
                }
            }
            else {
                this.setState({ minPasswordLength: "" });
                this.state.errors[name] = "Please enter password length";
            }
        }
        else if (name == "minUppercaseLetter") {
            const re = /^[0-9-\b]+$/;
            
            this.setState({ minUppercaseLetter: event.target.value });
            if (event.target.value === '' || re.test(event.target.value)) {
                if (event.target.value == "") {
                    this.state.errors[name] = "Please enter uppercase letter length";
                }
                else if (parseInt(event.target.value) < this.state.systemPasswordPolicy.minUppercaseLetter || parseInt(event.target.value) > this.state.systemPasswordPolicy.maxUppercaseLetters) {
                    this.state.errors[name] = "Uppercase length should be between " + this.state.systemPasswordPolicy.minUppercaseLetter + " to " + this.state.systemPasswordPolicy.maxUppercaseLetters;
                }
                else {
                    this.state.errors[name] = "";
                }
            }
            else {
                this.setState({ minUppercaseLetter: "" });
                this.state.errors[name] = "Please enter uppercase letter length";
            }
        }
        else if (name == "minLowercaseLetter") {
            const re = /^[0-9-\b]+$/;

            this.setState({ minLowercaseLetter: event.target.value });
            if (event.target.value === '' || re.test(event.target.value)) {
                if (event.target.value == "") {
                    this.state.errors[name] = "Please enter lowercase letter length";
                }
                else if (parseInt(event.target.value) < this.state.systemPasswordPolicy.minLowercaseLetter || parseInt(event.target.value) > this.state.systemPasswordPolicy.maxLowercaseLetters) {
                    this.state.errors[name] = "Lowercase length should be between " + this.state.systemPasswordPolicy.minLowercaseLetter + " to " + this.state.systemPasswordPolicy.maxLowercaseLetters;
                }
                else {
                    this.state.errors[name] = "";
                }
            }
            else {
                this.setState({ minLowercaseLetter: "" });
                this.state.errors[name] = "Please enter lowercase letter length";
            }
        }
        else if (name == "minNumber") {
            const re = /^[0-9-\b]+$/;

            this.setState({ minNumber: event.target.value });
            if (event.target.value === '' || re.test(event.target.value)) {
                if (event.target.value == "") {
                    this.state.errors[name] = "Please enter number length";
                }
                else if (parseInt(event.target.value) < this.state.systemPasswordPolicy.minNumber || parseInt(event.target.value) > this.state.systemPasswordPolicy.maxNumber) {
                    this.state.errors[name] = "Number length should be between " + this.state.systemPasswordPolicy.minNumber + " to " + this.state.systemPasswordPolicy.maxNumber;
                }
                else {
                    this.state.errors[name] = "";
                }
            }
            else {
                this.setState({ minNumber: "" });
                this.state.errors[name] = "Please enter number length";
            }
        }
        else if (name == "minSpecialCharacter") {
            const re = /^[0-9-\b]+$/;

            this.setState({ minSpecialCharacter: event.target.value });
            if (event.target.value === '' || re.test(event.target.value)) {
                if (event.target.value == "") {
                    this.state.errors[name] = "Please enter special character length";
                }
                else if (parseInt(event.target.value) < this.state.systemPasswordPolicy.minSpecialCharacter || parseInt(event.target.value) > this.state.systemPasswordPolicy.maxSpecialCharacter) {
                    this.state.errors[name] = "Special character length should be between " + this.state.systemPasswordPolicy.minSpecialCharacter + " to " + this.state.systemPasswordPolicy.maxSpecialCharacter;
                }
                else {
                    this.state.errors[name] = "";
                }
            }
            else {
                this.setState({ minSpecialCharacter: "" });
                this.state.errors[name] = "Please enter special character length";
            }
        }
        else if (name == "noRepeatTimes") {
            const re = /^[0-9-\b]+$/;

            this.setState({ noRepeatTimes: event.target.value });
            if (event.target.value === '' || re.test(event.target.value)) {
                if (event.target.value == "") {
                    this.state.errors[name] = "Please enter last repat password";
                }
                else if (parseInt(event.target.value) < this.state.systemPasswordPolicy.noRepeatTime || parseInt(event.target.value) > this.state.systemPasswordPolicy.maxNoRepeatTimes) {
                    this.state.errors[name] = "Last repat password length should be between " + this.state.systemPasswordPolicy.noRepeatTime + " to " + this.state.systemPasswordPolicy.maxNoRepeatTimes;
                }
                else {
                    this.state.errors[name] = "";
                }
            }
            else {
                this.setState({ noRepeatTimes: "" });
                this.state.errors[name] = "Please enter last repat password";
            }
        }        
        else
        {
            this.setState({
                [name]: value
            });
            this.state.errors[name] = "";
        }
    }

    handleWefDateChanged(event) {
        let wefDateValue = formatDate(event.value, "MM/dd/yyyy hh:mm");
        this.setState({ wefDate: wefDateValue })
    }

    async getSystemPasswordPolicy() {
        try {
            const requestParams = getRequestParams('POST', {});
            let path = 'password-policy-api/system-password-policy-list';
            const response = await fetch(path, requestParams);
            const data = await response.json();

            if (data.isSuccess) {
                let systemPwdPolicy = data.systemPasswordPolicy[0];
               
                this.setState({
                    systemPasswordPolicy: systemPwdPolicy,
                    expiryDays: systemPwdPolicy.expiryDays,
                    maxExpiryDays: systemPwdPolicy.maxExpiryDays,
                    minPasswordLength: systemPwdPolicy.minLength,
                    isNewUserPasswordReset: systemPwdPolicy.newUserReset,
                    isNoRepeat: systemPwdPolicy.isNoRepeat,
                    noRepeatTimes: systemPwdPolicy.noRepeatTime,
                    minUppercaseLetter: systemPwdPolicy.minUppercaseLetter,
                    minLowercaseLetter: systemPwdPolicy.minLowercaseLetter,
                    minNumber: systemPwdPolicy.minNumber,
                    minSpecialCharacter: systemPwdPolicy.minSpecialCharacter,
                    isContainUserId: systemPwdPolicy.isContainUserId,
                    maxUpperCaseLetter: systemPwdPolicy.maxUppercaseLetters,
                    maxLowerCaseLetter: systemPwdPolicy.maxLowercaseLetters,
                    maxNumber: systemPwdPolicy.maxNumber,
                    maxSpecialCharacter: systemPwdPolicy.maxSpecialCharacter,
                    maxPasswordLength: systemPwdPolicy.maxLength,
                });
            }
        } catch (e) {
        }
    }

    async getPasswordPolicy() {
        try {
            var newObject = new Object();
            newObject.AppTypeId = parseInt(this.state.appTypeId);
            newObject.IsAllRecords = true;

            const requestParams = getRequestParams('POST', newObject);
            let path = 'password-policy-api/list';
            const response = await fetch(path, requestParams);
            const data = await response.json();

            if (data.isSuccess) {
                this.setState({
                    passwordPolicy: data.passwordPolicyConfig
                });
            }            
        } catch (e) {

        }
    }

    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;

        errors.expiryDays = "";

        this.setState({ message: '' });

        if (this.state.expiryDays == 0) {
            formIsValid = false;
            errors.expiryDays = "Please enter expiry days";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    async handlePasswordPolicySubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            this.setState({ isSubmited: true });
            
            var newObject = new Object();
            newObject.RowIndex = 0;
            newObject.Id = this.state.userId;
            newObject.AppTypeId = parseInt(this.state.appTypeId);
            newObject.ProductId = parseInt(this.state.productTypeId);
            newObject.ExpiryDays = parseInt(this.state.expiryDays);
            newObject.IsPasswordComplexity = this.state.isPasswordComplexity;
            newObject.IsNewUserReset = this.state.isNewUserPasswordReset;
            newObject.MinPasswordLength = parseInt(this.state.minPasswordLength);
            newObject.minUppercaseLetter = parseInt(this.state.minUppercaseLetter);
            newObject.minLowercaseLetter = parseInt(this.state.minLowercaseLetter);
            newObject.MinNumber = parseInt(this.state.minNumber);
            newObject.MinSpecialCharacter = parseInt(this.state.minSpecialCharacter);
            newObject.IsContainUserId = this.state.isContainUserId;
            newObject.IsNoRepeat = this.state.isNoRepeat;
            newObject.NoRepeatTimes = parseInt(this.state.noRepeatTimes);
            newObject.ResetPasswordEmailNotification = this.state.passwordResetEmailNotifications;
            newObject.IsPasswordAuditPolicy = this.state.isPasswordAuditPolicy;
            newObject.Enable = true;
            newObject.CreatedDate = currentTime;
            newObject.WefDate = this.state.wefDate;

            try {
                const requestParams = getRequestParams('POST', newObject);
                let path = 'password-policy-api/save-password-policy';
                const response = await fetch(path, requestParams);
                const data = await response.json();
                
                Swal.fire({
                    icon: data.isSuccess ? 'success' : 'error',
                    title: data.isSuccess ? 'Success' : 'Oops...',
                    text: data.resultMessage,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            this.getPasswordPolicy();
                            this.setState({ isSubmited: false, wefDate: currentTime });
                        }
                    } else {
                        this.setState({ isSubmited: false })
                    }
                })
            } catch (e) {
                this.setState({ isSubmited: false })
            }
        }
    }

    render() {
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <h1 className="m-0 text-dark">Password Policy Configuration</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label className="form-check-label">Expiry Days</label>
                                        {/*<input className="form-control form-control-sm" type="text" name="doTripDuration" maxLength="3" value={this.state.doTripDuration} onChange={this.handleInputChangeStep1} placeholder="Enter DO trip duration" />*/}
                                        <input className="form-control form-control-sm" type="text" name="expiryDays" maxLength="3" value={this.state.expiryDays} onChange={this.handleInputChange} />
                                        {this.state.errors["expiryDays"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["expiryDays"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label className="form-check-label">Min. Password Length</label>
                                        <input className="form-control form-control-sm" type="text" name="minPasswordLength" maxLength="3" value={this.state.minPasswordLength} onChange={this.handleInputChange} />
                                        {this.state.errors["minPasswordLength"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["minPasswordLength"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label className="form-check-label">No. Repeat</label><br />
                                        <CheckBox defaultValue={false} width={100} value={this.state.noRepeat} />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label className="form-check-label">No. Repeat Times</label>
                                        <input className="form-control form-control-sm" type="text" name="noRepeatTimes" maxLength="3" value={this.state.noRepeatTimes} onChange={this.handleInputChange} />
                                        {this.state.errors["noRepeatTimes"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["noRepeatTimes"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label className="form-check-label">New User Password Reset</label><br />
                                        {/*<CheckBox defaultValue={false} width={100} text="Checks Only" value={this.state.checkOnly} onValueChanged={this.handleCheckOnlyValueChanged} />*/}
                                        <CheckBox defaultValue={false} width={100} value={this.state.newUserPasswordReset} />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label className="form-check-label">Password Reset Notifications</label>
                                        <input className="form-control form-control-sm" type="text" name="passwordResetEmailNotifications" maxLength="3" value={this.state.passwordResetEmailNotifications} placeholder="Password Reset Email Notifications" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label className="form-check-label">Password Complexity</label><br />
                                        <CheckBox defaultValue={false} width={100} value={this.state.passwordComplexity} />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label className="form-check-label">Contains Username</label><br />
                                        <CheckBox defaultValue={false} width={100} value={this.state.conatinsUserId} />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label className="form-check-label">Min. Uppercase Letter</label>
                                        <input className="form-control form-control-sm" type="text" name="minUppercaseLetter" maxLength="3" value={this.state.minUppercaseLetter} onChange={this.handleInputChange} />
                                        {this.state.errors["minUppercaseLetter"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["minUppercaseLetter"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label className="form-check-label">Min. Lowercase Letter</label>
                                        <input className="form-control form-control-sm" type="text" name="minLowercaseLetter" maxLength="3" value={this.state.minLowercaseLetter} onChange={this.handleInputChange} />
                                        {this.state.errors["minLowercaseLetter"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["minLowercaseLetter"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label className="form-check-label">Min. Number</label>
                                        <input className="form-control form-control-sm" type="text" name="minNumber" maxLength="3" value={this.state.minNumber} onChange={this.handleInputChange} />
                                        {this.state.errors["minNumber"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["minNumber"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label className="form-check-label">Min. Special Character</label>
                                        <input className="form-control form-control-sm" type="text" name="minSpecialCharacter" maxLength="3" value={this.state.minSpecialCharacter} onChange={this.handleInputChange} />
                                        {this.state.errors["minSpecialCharacter"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["minSpecialCharacter"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label className="form-check-label">Wef Date</label>
                                            <DateBox type="date"
                                                className="form-control form-control-sm"
                                                name="toDate"
                                                placeholder="mm/dd/yyyy"
                                                useMaskBehavior={true}
                                                defaultValue={this.state.wefDate}
                                                value={this.state.wefDate}
                                                onValueChanged={this.handleWefDateChanged}
                                                width="100%"
                                                acceptCustomValue={false}
                                            />
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label className="form-check-label">&nbsp;</label>
                                        <button type="button" className="btn btn-success btn-sm" onClick={this.handlePasswordPolicySubmit}>
                                            {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSubmited && <span title="Send Email"><i className="fas fa-envelope"></i> Save Configuration</span>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                            <div className="content">
                                <div className="card card-grid">
                                    <div className="card-body">
                                        <DataGrid
                                            dataSource={this.state.passwordPolicy}
                                            selection={{ mode: 'single' }}
                                            columnsAutoWidth={false}
                                            showColumnLines={false}
                                            showRowLines={true}
                                            showBorders={true}
                                            rowAlternationEnabled={true}
                                            showBorders={true}
                                            allowColumnResizing={true}
                                            columnResizingMode='widget'
                                            onToolbarPreparing={this.onToolbarPreparing}>
                                            <Column dataField="expiryDays" caption="Expiry Days" allowFiltering={false} allowSorting={false} />
                                            <Column dataField="isPasswordComplexity" caption="Password Complexity" allowFiltering={false} allowSorting={false} visible={false} />
                                            <Column dataField="minPasswordLength" caption="Min. Password Length" allowFiltering={false} allowSorting={false} />
                                            <Column dataField="minUppercaseLetter" caption="Min. Uppercase Letter" allowFiltering={false} allowSorting={false} />
                                            <Column dataField="minLowercaseLetter" caption="Min. Lowercase Letter" allowFiltering={false} allowSorting={false} />
                                            <Column dataField="minNumber" caption="Min. Number" allowFiltering={false} allowSorting={false} />
                                            <Column dataField="minSpecialCharacter" caption="Min. Special Character" allowFiltering={false} allowSorting={false} />
                                            <Column dataField="newUserReset" caption="New User Reset" allowFiltering={false} allowSorting={false} />
                                            <Column dataField="noRepeatTimes" caption="Repeat Times" allowFiltering={false} allowSorting={false} />
                                            <Column dataField="containUserId" caption="Contains UserName" allowFiltering={false} allowSorting={false} />
                                            <Column dataField="noRepeat" caption="No Repeat" allowFiltering={false} allowSorting={false} />
                                            <Column dataField="newUserReset" caption="New User Reset" allowFiltering={false} allowSorting={false} />
                                            <Column dataField="createdByUser" caption="Created By" allowFiltering={false} allowSorting={false} />
                                            <Column dataField="wefDate" caption="Wef Date" allowFiltering={true} allowSorting={true} format={DateFormat.DefaultDateTimeFormat} dataType="date" />
                                            <SortByGroupSummaryInfo summaryItem="count" />
                                            <GroupPanel visible={true} />
                                            <HeaderFilter visible={true} />
                                            <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                            <Grouping autoExpandAll={true} />
                                            <Paging defaultPageSize={10} />
                                            <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                            <Export enabled={true} allowExportSelectedData={false} />
                                            <ColumnChooser enabled={true} />
                                            <ColumnFixing enabled={true} />
                                        </DataGrid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}