import React, { Component } from 'react';
import { LoadPanel } from 'devextreme-react/load-panel';
import Modal from 'react-modal'; //npm install --save react-modal
import { getRequestParams } from '../../helpers/globalfunctions.js';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import { SelectBox } from 'devextreme-react/select-box';
import DateBox from 'devextreme-react/date-box';
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'
let currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class VehicleReimbRevenueReportComponent extends Component {
    constructor() {
        currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        super()
        this.state = {
            isSubmitted: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            isSinglePayrollPeriod: true,
            isRangePayrollPeriod: false,
            payrollPeriodDropdownList: [],
            payrollPeriodDropdownData: null,
            payDate: '',
            fromDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
            toDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
            isDetailsReport: true,
            isSummaryReport: false,
            errors: {
                payDate: '',
                fromDate: '',
                toDate: '',
                reportType: ''
            }
        }
    }
    getPayrollPeriodDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('vehicle-payroll-period-api/dropdown-list/', requestParams);
            const data = await response.json();
            this.setState({
                payrollPeriodDropdownList: data.vehiclePayRollPeriods
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getPayrollPeriodDropdownList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isEmailSubmitted: false,
                isOpenModal: false,
                loadPanelVisible: false,
                isSinglePayrollPeriod: true,
                isRangePayrollPeriod: false,
                payrollPeriodDropdownList: [],
                payrollPeriodDropdownData: null,
                payDate: '',
                fromDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
                toDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
                isDetailsReport: true,
                isSummaryReport: false,
                errors: {
                    payDate: '',
                    fromDate: '',
                    toDate: '',
                    reportType: ''
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handlePayrollPeriodOnChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.payDate = '';
            let payDate = '';
            if (data !== null) {
                payDate = (data.selectedItem != null ? data.selectedItem.payDate : "");
            }
            else {
                //errors.payDate = 'Please select period.';
            }
            this.setState({ payrollPeriodDropdownData: data, payDate: payDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnFromDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.fromDate = '';
            let fromDate = '';
            if (event.value !== null && event.value !== '') {
                fromDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.fromDate = 'Please select date.';
            }
            if (this.state.toDate.trim() !== '') {
                errors.toDate = '';
            }
            this.setState({ fromDate: fromDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnToDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.toDate = '';
            let toDate = '';
            if (event.value !== null && event.value !== '') {
                toDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.toDate = 'Please select date.';
            }
            if (this.state.fromDate.trim() !== '') {
                errors.fromDate = '';
            }
            this.setState({ toDate: toDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnInputChange = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
            let errors = this.state.errors;
            switch (name) {
                case 'isSinglePayrollPeriod':
                    errors.payDate = '';
                    errors.fromDate = '';
                    errors.toDate = '';
                    this.setState({ isSinglePayrollPeriod: value, isRangePayrollPeriod: false, payrollPeriodDropdownData: null, payDate: '' });
                    break;
                case 'isRangePayrollPeriod':
                    errors.payDate = '';
                    errors.fromDate = '';
                    errors.toDate = '';
                    this.setState({ isSinglePayrollPeriod: false, isRangePayrollPeriod: value, payrollPeriodDropdownData: null, payDate: '' });
                    break;
                case 'isDetailsReport':
                    errors.reportType = '';
                    this.setState({ isDetailsReport: value, isSummaryReport: false });
                    break;
                case 'isSummaryReport':
                    errors.reportType = '';
                    this.setState({ isDetailsReport: false, isSummaryReport: value });
                    break;
                default:
                    break;

            }
            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnReportSubmit = async (event) => {
        event.preventDefault();
        try {
            this.setState({ isSubmitted: true });
            await this.downloadAsPdf();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false });
    }
    downloadAsPdf = async () => {
        try {
            if (await this.isFormValid()) {
                let fromDate = '';
                let toDate = '';
                let reportType = 0;
                if (this.state.isDetailsReport === true) {
                    reportType = 1;
                }
                else {
                    reportType = 2;
                }
                if (this.state.isSinglePayrollPeriod === true) {
                    if (this.state.payDate.trim() !== '') {
                        if (moment(this.state.payDate, "MM/DD/YYYY").format("D") > 15) {
                            fromDate = moment(moment(this.state.payDate, "MM/DD/YYYY").format("YYYY-MM-") + "16").format("YYYY-MM-DD") + ' 00:00:00';
                            toDate = moment(moment(this.state.payDate, "MM/DD/YYYY").format("YYYY-MM-") + moment(this.state.payDate, "MM/DD/YYYY").daysInMonth()).format("YYYY-MM-DD") + ' 23:59:59';
                        }
                        if (moment(this.state.payDate, "MM/DD/YYYY").format("D") <= 15) {
                            fromDate = moment(moment(this.state.payDate, "MM/DD/YYYY").format("YYYY-MM-") + "01").format("YYYY-MM-DD") + ' 00:00:00';
                            toDate = moment(moment(this.state.payDate, "MM/DD/YYYY").format("YYYY-MM-") + "15").format("YYYY-MM-DD") + ' 23:59:59';
                        }
                    }
                }
                if (this.state.isRangePayrollPeriod === true) {
                    if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                        fromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00'
                        toDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59'
                    }
                }
                let reportHeader = await this.setSelectionCrieteria();
                await this.downloadReport(reportType, fromDate, toDate, reportHeader, 'pdf');
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    downloadReport = async (reportType, fromDate, toDate, reportHeader, fileFormat) => {
        try {
            var request = new Object();
            request.LocationId = Number(sessionStorage.getItem("session_locationId"));
            request.StartDate = fromDate;
            request.EndDate = toDate;
            request.ReportType = Number(reportType);
            request.SelectionCrieteria = reportHeader;
            request.fileFormat = fileFormat;
            request.MethodName = "GetVehicleReimbRevenueReport";
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            let a = document.createElement('a');
            a.href = dataResponse.reportBase64String;
            a.download = 'VehicleReimbursmentRevenue.' + fileFormat;
            a.click();
        } catch (ex) {
            console.log(ex);
        }
    }
    isFormValid = async () => {
        let isValid = true;
        try {
            let errors = this.state.errors;
            errors.payDate = '';
            errors.fromDate = '';
            errors.toDate = '';
            errors.reportType = '';
            if (this.state.isSinglePayrollPeriod === true) {
                if (this.state.payDate.trim() === '') {
                    errors.payDate = 'Please select period.';
                    isValid = false;
                }
            }
            if (this.state.isRangePayrollPeriod === true) {
                if (this.state.fromDate.trim() === '') {
                    errors.fromDate = 'Please select date.';
                    isValid = false;
                }
                if (this.state.toDate.trim() === '') {
                    errors.toDate = 'Please select date.';
                    isValid = false;
                }
                if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                    if (moment(this.state.fromDate, 'MM/DD/YYYY').isAfter(this.state.toDate, 'MM/DD/YYYY')) {
                        errors.fromDate = 'Please enter a valid date range.';
                        errors.toDate = 'Please enter a valid date range.';
                        isValid = false;
                    }
                }
            }
            if (this.state.isDetailsReport === false && this.state.isSummaryReport === false) {
                errors.reportType = 'Please select report type.';
                isValid = false;
            }
            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    setSelectionCrieteria = async () => {
        let selectionCrieteria = 'Vehicle reimbursment revenue for';
        try {
            if (this.state.isSinglePayrollPeriod === true) {
                if (this.state.payDate.trim() !== '' && Object.keys(this.state.payrollPeriodDropdownData).length > 0) {
                    selectionCrieteria += ' period - ' + this.state.payrollPeriodDropdownData.viewDate;
                }
            }
            if (this.state.isRangePayrollPeriod === true) {
                if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                    selectionCrieteria += ', period from - ' + this.state.fromDate + ' to ' + this.state.toDate;
                }
            }

        } catch (ex) {
            selectionCrieteria = '';
            console.log(ex);
        }
        return selectionCrieteria;
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isEmailSubmitted: true });
                let fromDate = '';
                let toDate = '';
                let reportType = 0;
                if (this.state.isDetailsReport === true) {
                    reportType = 1;
                }
                else {
                    reportType = 2;
                }
                if (this.state.isSinglePayrollPeriod === true) {
                    if (this.state.payDate.trim() !== '') {
                        if (moment(this.state.payDate, "MM/DD/YYYY").format("D") > 15) {
                            fromDate = moment(moment(this.state.payDate, "MM/DD/YYYY").format("YYYY-MM-") + "16").format("YYYY-MM-DD") + ' 00:00:00';
                            toDate = moment(moment(this.state.payDate, "MM/DD/YYYY").format("YYYY-MM-") + moment(this.state.payDate, "MM/DD/YYYY").daysInMonth()).format("YYYY-MM-DD") + ' 23:59:59';
                        }
                        if (moment(this.state.payDate, "MM/DD/YYYY").format("D") <= 15) {
                            fromDate = moment(moment(this.state.payDate, "MM/DD/YYYY").format("YYYY-MM-") + "01").format("YYYY-MM-DD") + ' 00:00:00';
                            toDate = moment(moment(this.state.payDate, "MM/DD/YYYY").format("YYYY-MM-") + "15").format("YYYY-MM-DD") + ' 23:59:59';
                        }
                    }
                }
                if (this.state.isRangePayrollPeriod === true) {
                    if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                        fromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00'
                        toDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59'
                    }
                }

                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.StartDate = fromDate;
                request.EndDate = toDate;
                request.ReportType = Number(reportType);
                request.SelectionCrieteria = await this.setSelectionCrieteria();;
                request.fileFormat = 'pdf';
                request.MethodName = "GetVehicleReimbRevenueReport";
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Vehicle Reimbursement Revenue';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = 'VehicleReimbursmentRevenue.pdf'
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '30%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleOnReportSubmit}
                        style={(this.state.isSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Vehicle Reimbursement Revenue'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="isSinglePayrollPeriod" checked={this.state.isSinglePayrollPeriod}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isSinglePayrollPeriod"> Single Period</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="isRangePayrollPeriod" checked={this.state.isRangePayrollPeriod}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isRangePayrollPeriod"> In Range</label>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <div style={{ display: (this.state.isSinglePayrollPeriod) || 'none' }}>
                                            {/*<Select*/}
                                            {/*    className="basic-multi-select"*/}
                                            {/*    classNamePrefix="select"*/}
                                            {/*    placeholder="Select period"*/}
                                            {/*    defaultValue={[]}*/}
                                            {/*    value={this.state.payrollPeriodDropdownData}*/}
                                            {/*    name="ddpayrollperiod"*/}
                                            {/*    onChange={this.handlePayrollPeriodOnChange}*/}
                                            {/*    getOptionLabel={option => option.viewDate}*/}
                                            {/*    getOptionValue={option => option.payDate}*/}
                                            {/*    options={this.state.payrollPeriodDropdownList}*/}
                                            {/*    isMulti={false}*/}
                                            {/*    isClearable={true}*/}
                                            {/*    isSearchable={true}*/}
                                            {/*/>*/}

                                            <SelectBox
                                                dataSource={this.state.payrollPeriodDropdownList.length > 0 ? this.state.payrollPeriodDropdownList : []}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select period"
                                                name="ddpayrollperiod"
                                                defaultValue={[]}
                                                value={this.state.payrollPeriodDropdownData}
                                                displayExpr='viewDate'
                                                valueExpr='payDate'
                                                onSelectionChanged={this.handlePayrollPeriodOnChange}
                                                showClearButton={true}
                                                disabled={false}
                                                searchEnabled={true}
                                            />
                                        </div>
                                        <div style={{ display: (this.state.isRangePayrollPeriod) || 'none' }}>
                                            <DateBox type="date"
                                                className="form-control form-control-sm input-date"
                                                name="fromDate"
                                                placeholder="mm/dd/yyyy"
                                                defaultValue={this.state.fromDate}
                                                value={this.state.fromDate}
                                                onValueChanged={this.handleOnFromDateChange}
                                                width={'100%'}
                                                disabled={this.state.isSinglePayrollPeriod}
                                                acceptCustomValue={false}
                                            />
                                            {this.state.errors["fromDate"].length > 0 && <span className='error invalid-feedback'>
                                                {this.state.errors["fromDate"]}</span>}
                                        </div>
                                        {this.state.errors["payDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["payDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        {/*<label>To Date <span style={{ display: (this.state.isRangePayrollPeriod) || 'none' }} className="text-danger">*</span></label>*/}
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            defaultValue={this.state.toDate}
                                            value={this.state.toDate}
                                            min={this.state.fromDate}
                                            onValueChanged={this.handleOnToDateChange}
                                            width={'100%'}
                                            disabled={this.state.isSinglePayrollPeriod}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["toDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["toDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Report Type</label><br />
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isDetailsReport"
                                                checked={this.state.isDetailsReport}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isDetailsReport">  Details</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isSummaryReport"
                                                checked={this.state.isSummaryReport}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isSummaryReport"> Summary</label>
                                        </div>
                                        {this.state.errors["reportType"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["reportType"]}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="submit" className="btn btn-success btn-sm" title="Download">
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                             </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}