import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { SelectBox } from 'devextreme-react/select-box';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import { LoadPanel } from 'devextreme-react/load-panel';
import DatePicker from "react-datepicker"; // npm install react - datepicker --save
import DateBox from 'devextreme-react/date-box';
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'
let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class DriverLoginHistoryReportComponent extends Component {
    constructor() {
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        super()
        this.state = {
            isSubmitted: false,
            isOpenModal: false,
            isEmailSubmitted: false,
            loadPanelVisible: false,
            driversDropdownList: [],
            driverDropdownData: null,
            driverId: 0,
            isAllDate: true,
            fromDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
            toDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
            isAllTime: false,
            fromTime: '',
            fromTimeData: null,
            toTime: '',
            toTimeData: null,
            controllDisability: {
                isAllTimeDisabled: true,
                isFromTimeInputDisabled: true,
                isToTimeInputDisabled: true,
            },
            errors: {
                driver: '',
                fromDate: '',
                toDate: '',
                fromTime: '',
                toTime: ''
            }

        }
    }
    getDriversDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('driver-api/dropdown-all/', requestParams);
            const data = await response.json();
            this.setState({
                driversDropdownList: data.driversDropdownList
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getDriversDropdownList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnClearSearch = async () => {
        try {
            currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
            this.setState({
                isSubmitted: false,
                isEmailSubmitted: false,
                loadPanelVisible: false,
                driverDropdownData: null,
                driverId: 0,
                isAllDate: true,
                fromDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
                toDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
                isAllTime: false,
                fromTime: '',
                fromTimeData: null,
                toTime: '',
                toTimeData: null,
                controllDisability: {
                    isAllTimeDisabled: true,
                    isFromTimeInputDisabled: true,
                    isToTimeInputDisabled: true,
                },
                errors: {
                    driver: '',
                    fromDate: '',
                    toDate: '',
                    fromTime: '',
                    toTime: ''
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isOpenModal: false,
                isEmailSubmitted: false,
                loadPanelVisible: false,
                driversDropdownList: [],
                driverDropdownData: null,
                driverId: 0,
                isAllDate: true,
                fromDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
                toDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
                isAllTime: false,
                fromTime: '',
                fromTimeData: null,
                toTime: '',
                toTimeData: null,
                controllDisability: {
                    isAllTimeDisabled: true,
                    isFromTimeInputDisabled: true,
                    isToTimeInputDisabled: true,
                },
                errors: {
                    driver: '',
                    fromDate: '',
                    toDate: '',
                    fromTime: '',
                    toTime: ''
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnInputChange = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
            let controllDisability = this.state.controllDisability;
            let errors = this.state.errors;
            switch (name) {
                case 'isAllDate':
                    errors.fromDate = '';
                    errors.toDate = '';
                    errors.fromTime = '';
                    errors.toTime = '';
                    controllDisability.isAllTimeDisabled = value;
                    controllDisability.isFromTimeInputDisabled = true;
                    controllDisability.isToTimeInputDisabled = true;
                    this.setState({
                        isAllDate: value,
                        isAllTime: !value,
                        fromTimeData: null,
                        fromTime: '',
                        toTimeData: null,
                        toTime: '',
                        controllDisability: controllDisability
                    });
                    break;
                case 'isAllTime':
                    errors.fromTime = '';
                    errors.toTime = '';
                    controllDisability.isFromTimeInputDisabled = value;
                    controllDisability.isToTimeInputDisabled = value;
                    this.setState({
                        isAllTime: value,
                        fromTimeData: null,
                        fromTime: '',
                        toTimeData: null,
                        toTime: '',
                        controllDisability: controllDisability
                    });
                    break;
                default:
                    break;
            }
            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnDriverDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.driver = '';
            let driverId = 0;
            if (data !== null) {
                driverId = (data.selectedItem != null ? data.selectedItem.id : 0);
            }
            else {
                //errors.driver = 'Please select driver.';
            }
            this.setState({ driverDropdownData: data, driverId: driverId, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnFromDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.fromDate = '';
            let fromDate = '';
            if (event.value !== null && event.value !== '') {
                fromDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.fromDate = 'Please select date.';
            }
            if (this.state.toDate.trim() !== '') {
                errors.toDate = '';
            }
            this.setState({ fromDate: fromDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnToDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.toDate = '';
            let toDate = '';
            if (event.value !== null && event.value !== '') {
                toDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.toDate = 'Please select date.';
            }
            if (this.state.fromDate.trim() !== '') {
                errors.fromDate = '';
            }
            this.setState({ toDate: toDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnFromTimeChange = async (time) => {
        try {
            let errors = this.state.errors;
            errors.fromTime = '';
            let fromTime = '';
            if (time !== null) {
                fromTime = time.toLocaleTimeString('it-IT');
            }
            else {
                if (this.state.isAllTime === false) {
                    //errors.fromTime = 'Please enter time.';
                }
            }
            if (this.state.toTime.trim() !== '') {
                errors.toTime = '';
            }
            this.setState({ fromTimeData: time, fromTime: fromTime, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnToTimeChange = async (time) => {
        try {
            let errors = this.state.errors;
            errors.toTime = '';
            let toTime = '';
            if (time !== null) {
                toTime = time.toLocaleTimeString('it-IT');
            }
            else {
                if (this.state.isAllTime === false) {
                    //errors.toTime = 'Please enter time.';
                }
            }
            if (this.state.fromTime.trim() !== '') {
                errors.fromTime = '';
            }
            this.setState({ toTimeData: time, toTime: toTime, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnReportSubmit = async (event) => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isSubmitted: true });
                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.DriverId = Number(this.state.driverId);
                if (this.state.isAllDate === false) {
                    if (this.state.fromDate.trim() !== '') {
                        request.StartDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
                        if (this.state.fromTime.trim() !== '') {
                            request.StartDate = request.StartDate + ' ' + this.state.fromTime;
                        }
                        else {
                            request.StartDate = request.StartDate + ' 00:00:00';
                        }
                    }
                    if (this.state.toDate.trim() !== '') {
                        request.EndDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
                        if (this.state.toTime.trim() !== '') {
                            request.EndDate = request.EndDate + ' ' + this.state.toTime;
                        }
                        else {
                            request.EndDate = request.EndDate + ' 23:59:59';
                        }
                    }
                }
                request.SelectionCrieteria = await this.setSelectionCrieteria();
                request.fileFormat = 'pdf';
                request.MethodName = "GetDriverLoginHistoryReport";
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                let a = document.createElement('a');
                a.href = dataResponse.reportBase64String;
                a.download = 'DriverLoginHistory.pdf';
                a.click();
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false });
    }
    isFormValid = async () => {
        let isValid = true;
        let errors = this.state.errors;
        errors.driver = '';
        errors.fromDate = '';
        errors.toDate = '';
        errors.fromTime = '';
        errors.toTime = '';
        try {
            if (Number(this.state.driverId) === 0 || Object.keys(this.state.driverDropdownData).length === 0) {
                errors.driver = 'Please select driver.';
                isValid = false;
            }
            if (this.state.isAllDate === false) {
                if (this.state.fromDate.trim() === '') {
                    errors.fromDate = 'Please select date.';
                    isValid = false;
                }
                if (this.state.toDate.trim() === '') {
                    errors.toDate = 'Please select date.';
                    isValid = false;
                }
                if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                    if (moment(this.state.fromDate, 'MM/DD/YYYY').isAfter(this.state.toDate, 'MM/DD/YYYY')) {
                        errors.fromDate = 'Please enter a valid date range.';
                        errors.toDate = 'Please enter a valid date range.';
                        isValid = false;
                    }
                }
                if (this.state.isAllTime === false) {
                    if (this.state.fromTime.trim() === '') {
                        errors.fromTime = 'Please enter time.';
                        isValid = false;
                    }
                    if (this.state.toTime.trim() === '') {
                        errors.toTime = 'Please enter time.';
                        isValid = false;
                    }
                }
            }
            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    setSelectionCrieteria = async () => {
        let selectionCrieteria = 'Driver login history for';
        try {
            if (Number(this.state.driverId) > 0) {
                if (this.state.driverDropdownData !== null && Object.keys(this.state.driverDropdownData).length > 0) {
                    selectionCrieteria += ' driver - ' + this.state.driverDropdownData.name;
                }
            }
            if (this.state.isAllDate === false) {
                if (this.state.fromDate.trim() === '' && this.state.toDate.trim() === '') {
                    selectionCrieteria += ', all date';
                }
                else {
                    if (this.state.fromTime.trim() !== '' && this.state.toTime.trim() !== '') {
                        selectionCrieteria += ', date from ' + this.state.fromDate + ' ' + this.state.fromTime + ' to ' + this.state.toDate + ' ' + this.state.toTime;
                    }
                    else {
                        selectionCrieteria += ', date from ' + this.state.fromDate + ' to ' + this.state.toDate;
                    }
                }
            }
        } catch (ex) {
            selectionCrieteria = '';
            console.log(ex);
        }
        return selectionCrieteria;
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isEmailSubmitted: true });
                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.DriverId = Number(this.state.driverId);
                if (this.state.isAllDate === false) {
                    if (this.state.fromDate.trim() !== '') {
                        request.StartDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
                        if (this.state.fromTime.trim() !== '') {
                            request.StartDate = request.StartDate + ' ' + this.state.fromTime;
                        }
                        else {
                            request.StartDate = request.StartDate + ' 00:00:00';
                        }
                    }
                    if (this.state.toDate.trim() !== '') {
                        request.EndDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
                        if (this.state.toTime.trim() !== '') {
                            request.EndDate = request.EndDate + ' ' + this.state.toTime;
                        }
                        else {
                            request.EndDate = request.EndDate + ' 23:59:59';
                        }
                    }
                }
                request.SelectionCrieteria = await this.setSelectionCrieteria();
                request.fileFormat = 'pdf';
                request.MethodName = "GetDriverLoginHistoryReport";
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Driver Login History';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = 'DriverLoginHistory.pdf'
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }

        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        const validStartDateTime = time => {
            const currentDate = new Date();
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }
        const validEndDateTime = time => {
            const currentDate = new Date();
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '30%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleOnReportSubmit}
                        style={(this.state.isSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Driver Login History'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Driver</label>
                                        <SelectBox
                                            dataSource={this.state.driversDropdownList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select driver"
                                            name="dddriver"
                                            defaultValue={[]}
                                            value={this.state.driverDropdownData}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnDriverDropdownChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["driver"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["driver"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                name="isAllDate"
                                                checked={this.state.isAllDate}
                                                onChange={this.handleOnInputChange}
                                                disabled={false} />
                                            <label className="form-check-label" htmlFor="isAllDate"> All Date</label>
                                        </div>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            defaultValue={this.state.fromDate}
                                            value={this.state.fromDate}
                                            onValueChanged={this.handleOnFromDateChange}
                                            width={'100%'}
                                            disabled={this.state.isAllDate}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["fromDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["fromDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>To Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            defaultValue={this.state.toDate}
                                            value={this.state.toDate}
                                            min={this.state.fromDate}
                                            onValueChanged={this.handleOnToDateChange}
                                            width={'100%'}
                                            disabled={this.state.isAllDate}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["toDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["toDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                name="isAllTime"
                                                checked={this.state.isAllTime}
                                                onChange={this.handleOnInputChange}
                                                disabled={this.state.controllDisability.isAllTimeDisabled}
                                            />
                                            <label className="form-check-label" htmlFor="isAllTime"> All Time</label>
                                        </div>
                                        <DatePicker
                                            className="form-control form-control-sm "
                                            placeholderText="hh:mm"
                                            title="From Time"
                                            name="fromTime"
                                            selected={this.state.fromTimeData}
                                            onChange={this.handleOnFromTimeChange}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm"
                                            timeFormat="HH:mm"
                                            filterTime={validStartDateTime}
                                            disabled={this.state.controllDisability.isFromTimeInputDisabled}
                                        />
                                        {this.state.errors["fromTime"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["fromTime"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <label className="form-check-label" htmlFor=""></label>
                                        </div>
                                        <DatePicker
                                            className="form-control form-control-sm "
                                            placeholderText="hh:mm"
                                            title="To Time"
                                            name="toTime"
                                            selected={this.state.toTimeData}
                                            onChange={this.handleOnToTimeChange}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm"
                                            timeFormat="HH:mm"
                                            filterTime={validEndDateTime}
                                            disabled={this.state.controllDisability.isToTimeInputDisabled}
                                            showTimeInput={false}
                                        />
                                        {this.state.errors["toTime"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["toTime"]}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="submit" className="btn btn-success btn-sm" title="Download">
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleOnClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                                </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        );
    }
}