import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import NumberFormat from 'react-number-format';
import { SelectBox } from 'devextreme-react/select-box';
import { LoadPanel } from 'devextreme-react/load-panel';
const customStyles = {
    content: {
        width: '55%'
    }
};

export class SplitReservationComponent extends Component {
    static displayName = SplitReservationComponent.name;
    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
            isSubmited: false,
            isSplitted: false,
            loadPanelVisible: false,
            refreshRideSource: props.refreshDataSource,
            handleRefresh: props.handleRefresh,
            pageName: '',
            splitCount: 2, //default 2
            serviceVehicles: [],
            pRideId: 0,
            pReservationId: 0,
            pPax: 0,
            pVehicleType: '',
            pPickUpDateTime: null,
            pPuStreet: '',
            pNoOfCharter: 0,
            allowToAssign: false,
            splittedVehicle: {
                charterIdSplit1: 0,
                charterNumberSplit1: '',
                charterTypeSplit1: '',
                noofPaxSplit1: 0,
                charterIdSplit2: 0,
                charterNumberSplit2: '',
                charterTypeSplit2: '',
                noofPaxSplit2: 0,
                rideIdSplit2: 0,
                charterIdSplit3: 0,
                charterNumberSplit3: '',
                charterTypeSplit3: '',
                noofPaxSplit3: 0,
                rideIdSplit3: 0,
                charterIdSplit4: 0,
                charterNumberSplit4: '',
                charterTypeSplit4: '',
                noofPaxSplit4: 0,
                rideIdSplit4: 0,
                charterIdSplit5: 0,
                charterNumberSplit5: '',
                charterTypeSplit5: '',
                noofPaxSplit5: 0,
                rideIdSplit5: 0,
                isSubmitedVehicleSplit1: false,
                isSubmitedVehicleSplit2: false,
                isSubmitedVehicleSplit3: false,
                isSubmitedVehicleSplit4: false,
                isSubmitedVehicleSplit5: false,
                isVehicleAssignSplit1: false,
                isVehicleAssignSplit2: false,
                isVehicleAssignSplit3: false,
                isVehicleAssignSplit4: false,
                isVehicleAssignSplit5: false,
            },
            errors: {
                splitCountErr: '',
                charterNumberSplit1: '',
                charterNumberSplit2: '',
                charterNumberSplit3: '',
                charterNumberSplit4: '',
                charterNumberSplit5: '',
                noofPaxSplit1: '',
                noofPaxSplit2: '',
                noofPaxSplit3: '',
                noofPaxSplit4: '',
                noofPaxSplit5: '',
            }
        }
    }
    async getLoggedVehicleList(OnlyLoggedInDriver) {
        try {
            this.setState({ loadPanelVisible: true });
            var request = new Object();
            request.VehicleNo = "";
            request.VehicleTypeId = "";
            request.IsDriverLoggedIn = false; //OnlyLoggedInDriver;

            const requestParams = getRequestParams('POST', request);
            const response = await fetch('vehicle-api/logged-list/', requestParams);
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    serviceVehicles: data.loggedVehicles
                });
            }
        } catch (e) {
        }
        this.setState({ loadPanelVisible: false });
    };
    showModal = (pageName, rideId, reservationId, pax, vehicleType, pickUpDateTime, puStreet, noOfCharter, allowToAssign) => {
        this.setState({
            isOpenModal: true,
            pageName: pageName,
            pRideId: rideId,
            pReservationId: reservationId,
            pPax: pax,
            pVehicleType: vehicleType,
            pPickUpDateTime: pickUpDateTime,
            pPuStreet: puStreet,
            pNoOfCharter: noOfCharter,
            allowToAssign: allowToAssign
        });
        this.getLoggedVehicleList(allowToAssign);
        this.setSplitedVehicleOnSplitCount(0);
    }
    handleCloseModal = () => {
        this.setState({
            isOpenModal: false,
            isSubmited: false,
            isSplitted: false,
            pageName: '',
            splitCount: 2, //default 2
            serviceVehicles: [],
            pRideId: 0,
            pReservationId: 0,
            pPax: 0,
            pVehicleType: '',
            pPickUpDateTime: null,
            pPuStreet: '',
            pNoOfCharter: 0,
            allowToAssign: false,
            splittedVehicle: {
                charterIdSplit1: 0,
                charterNumberSplit1: '',
                charterTypeSplit1: '',
                noofPaxSplit1: 0,
                charterIdSplit2: 0,
                charterNumberSplit2: '',
                charterTypeSplit2: '',
                noofPaxSplit2: 0,
                rideIdSplit2: 0,
                charterIdSplit3: 0,
                charterNumberSplit3: '',
                charterTypeSplit3: '',
                noofPaxSplit3: 0,
                rideIdSplit3: 0,
                charterIdSplit4: 0,
                charterNumberSplit4: '',
                charterTypeSplit4: '',
                noofPaxSplit4: 0,
                rideIdSplit4: 0,
                charterIdSplit5: 0,
                charterNumberSplit5: '',
                charterTypeSplit5: '',
                noofPaxSplit5: 0,
                rideIdSplit5: 0,
                isSubmitedVehicleSplit1: false,
                isSubmitedVehicleSplit2: false,
                isSubmitedVehicleSplit3: false,
                isSubmitedVehicleSplit4: false,
                isSubmitedVehicleSplit5: false,
                isVehicleAssignSplit1: false,
                isVehicleAssignSplit2: false,
                isVehicleAssignSplit3: false,
                isVehicleAssignSplit4: false,
                isVehicleAssignSplit5: false,
            },
            errors: {
                splitCountErr: '',
                charterNumberSplit1: '',
                charterNumberSplit2: '',
                charterNumberSplit3: '',
                charterNumberSplit4: '',
                charterNumberSplit5: '',
                noofPaxSplit1: '',
                noofPaxSplit2: '',
                noofPaxSplit3: '',
                noofPaxSplit4: '',
                noofPaxSplit5: '',
            }
        });
        this.state.handleRefresh(true);
        this.state.refreshRideSource();
    }
    setSplitedVehicleOnSplitCount(splitNo) {
        let errors = this.state.errors;
        errors.charterNumberSplit1 = '';
        errors.charterNumberSplit2 = '';
        errors.charterNumberSplit3 = '';
        errors.charterNumberSplit4 = '';
        errors.charterNumberSplit5 = '';
        errors.noofPaxSplit1 = '';
        errors.noofPaxSplit2 = '';
        errors.noofPaxSplit3 = '';
        errors.noofPaxSplit4 = '';
        errors.noofPaxSplit5 = '';
        let splittedVehicle = this.state.splittedVehicle;
        splittedVehicle.charterIdSplit1 = 0;
        splittedVehicle.charterNumberSplit1 = '';
        splittedVehicle.charterTypeSplit1 = '';
        splittedVehicle.noofPaxSplit1 = 0;
        splittedVehicle.charterIdSplit2 = 0;
        splittedVehicle.charterNumberSplit2 = '';
        splittedVehicle.charterTypeSplit2 = '';
        splittedVehicle.noofPaxSplit2 = 0;
        splittedVehicle.rideIdSplit2 = 0;
        splittedVehicle.charterIdSplit3 = 0;
        splittedVehicle.charterNumberSplit3 = '';
        splittedVehicle.charterTypeSplit3 = '';
        splittedVehicle.noofPaxSplit3 = 0;
        splittedVehicle.rideIdSplit3 = 0;
        splittedVehicle.charterIdSplit4 = 0;
        splittedVehicle.charterNumberSplit4 = '';
        splittedVehicle.charterTypeSplit4 = '';
        splittedVehicle.noofPaxSplit4 = 0;
        splittedVehicle.rideIdSplit4 = 0;
        splittedVehicle.charterIdSplit5 = 0;
        splittedVehicle.charterNumberSplit5 = '';
        splittedVehicle.charterTypeSplit5 = '';
        splittedVehicle.noofPaxSplit5 = 0;
        splittedVehicle.rideIdSplit5 = 0;
        splittedVehicle.isSubmitedVehicleSplit1 = false;
        splittedVehicle.isSubmitedVehicleSplit2 = false;
        splittedVehicle.isSubmitedVehicleSplit3 = false;
        splittedVehicle.isSubmitedVehicleSplit4 = false;
        splittedVehicle.isSubmitedVehicleSplit5 = false;
        splittedVehicle.isVehicleAssignSplit1 = false;
        splittedVehicle.isVehicleAssignSplit2 = false;
        splittedVehicle.isVehicleAssignSplit3 = false;
        splittedVehicle.isVehicleAssignSplit4 = false;
        splittedVehicle.isVehicleAssignSplit5 = false;
        this.setState({ errors: errors, splittedVehicle: splittedVehicle });
    }
    handleOnSplitCountChange = (e) => {
        let errors = this.state.errors;
        errors.splitCountErr = '';
        let splitCount = Number(e.value);
        if (splitCount > 5 || splitCount < 2) {
            errors.splitCountErr = 'Please enter number of ride(s) between 2 to 5.';
        }
        this.setState({
            splitCount: splitCount,
            errors: errors
        });
        this.setSplitedVehicleOnSplitCount(e.value);
    }
    handleOnInputChange = (e) => {
        const regExrOnPaxInput = /^\d*(\d)?$/;
        let errors = this.state.errors;
        let splittedVehicle = this.state.splittedVehicle;
        const target = e.target;
        const name = target.name;
        switch (name) {
            case 'noofPaxSplit1':
                errors.noofPaxSplit1 = '';
                if (target.value.trim() === '' || regExrOnPaxInput.test(target.value)) {
                    if (target.value.trim().length < 4) {
                        splittedVehicle.noofPaxSplit1 = Number(target.value);
                        if (target.value.trim() === '' || parseInt(target.value) === 0) {
                            errors.noofPaxSplit1 = 'Please enter pax.';
                        }
                        else {
                            if (parseInt(target.value) > parseInt(this.state.pPax)) {
                                errors.noofPaxSplit1 = 'Please enter no more than ' + this.state.pPax + ' pax';
                            }
                        }
                    }
                }
                break;
            case 'noofPaxSplit2':
                errors.noofPaxSplit2 = '';
                if (target.value.trim() === '' || regExrOnPaxInput.test(target.value)) {
                    if (target.value.trim().length < 4) {
                        splittedVehicle.noofPaxSplit2 = Number(target.value);
                        if (target.value.trim() === '' || parseInt(target.value) === 0) {
                            errors.noofPaxSplit2 = 'Please enter pax.';
                        }
                        else {
                            if (parseInt(target.value) > parseInt(this.state.pPax)) {
                                errors.noofPaxSplit2 = 'Please enter no more than ' + this.state.pPax + ' pax';
                            }
                        }
                    }
                }
                break;
            case 'noofPaxSplit3':
                errors.noofPaxSplit3 = '';
                if (target.value.trim() === '' || regExrOnPaxInput.test(target.value)) {
                    if (target.value.trim().length < 4) {
                        splittedVehicle.noofPaxSplit3 = Number(target.value);
                        if (target.value.trim() === '' || parseInt(target.value) === 0) {
                            errors.noofPaxSplit3 = 'Please enter pax.';
                        }
                        else {
                            if (parseInt(target.value) > parseInt(this.state.pPax)) {
                                errors.noofPaxSplit3 = 'Please enter no more than ' + this.state.pPax + ' pax';
                            }
                        }
                    }
                }
                break;
            case 'noofPaxSplit4':
                errors.noofPaxSplit4 = '';
                if (target.value.trim() === '' || regExrOnPaxInput.test(target.value)) {
                    if (target.value.trim().length < 4) {
                        splittedVehicle.noofPaxSplit4 = Number(target.value);
                        if (target.value.trim() === '' || parseInt(target.value) === 0) {
                            errors.noofPaxSplit4 = 'Please enter pax.';
                        }
                        else {
                            if (parseInt(target.value) > parseInt(this.state.pPax)) {
                                errors.noofPaxSplit4 = 'Please enter no more than ' + this.state.pPax + ' pax';
                            }
                        }
                    }
                }
                break;
            case 'noofPaxSplit5':
                errors.noofPaxSplit5 = '';
                if (target.value.trim() === '' || regExrOnPaxInput.test(target.value)) {
                    if (target.value.trim().length < 4) {
                        splittedVehicle.noofPaxSplit5 = Number(target.value);
                        if (target.value.trim() === '' || parseInt(target.value) === 0) {
                            errors.noofPaxSplit5 = 'Please enter pax.';
                        }
                        else {
                            if (parseInt(target.value) > parseInt(this.state.pPax)) {
                                errors.noofPaxSplit5 = 'Please enter no more than ' + this.state.pPax + ' pax';
                            }
                        }
                    }
                }
                break;
            default:
                break;
        }
        this.setState({ errors: errors, splittedVehicle: splittedVehicle });
    }
    handlecharterNoSplit1OnChange = (selectedOption) => {
        let errors = this.state.errors;
        let splittedVehicle = this.state.splittedVehicle;
        errors.charterNumberSplit1 = '';
        errors.noofPaxSplit1 = '';
        splittedVehicle.charterTypeSplit1 = '';
        splittedVehicle.noofPaxSplit1 = 0;

        const vhId = (selectedOption.selectedItem !== null ? Number(selectedOption.selectedItem.vehicleId) : 0);
        const vhNumber = (selectedOption.selectedItem !== null ? selectedOption.selectedItem.vehicleNo : '');

        if (Number(vhId) > 0) {
            if (vhId === Number(splittedVehicle.charterIdSplit2) || vhId === Number(splittedVehicle.charterIdSplit3) || vhId === Number(splittedVehicle.charterIdSplit4) || vhId === Number(splittedVehicle.charterIdSplit5)) {
                errors.charterNumberSplit1 = 'Duplicate charter not allowed.';
            }
            let vehicleData = this.state.serviceVehicles.filter(item => item.vehicleId === Number(vhId));
            if (vehicleData !== null && vehicleData.length > 0) {
                splittedVehicle.charterTypeSplit1 = vehicleData[0].vehicleTypeDescription;
                splittedVehicle.noofPaxSplit1 = vehicleData[0].vehicleSeats;
            }
        }
        else {
            errors.charterNumberSplit1 = 'Please select vehicle.';
        }
        splittedVehicle.charterIdSplit1 = vhId;
        splittedVehicle.charterNumberSplit1 = vhNumber;
        this.setState({ errors: errors, splittedVehicle: splittedVehicle });
    }
    handlecharterNoSplit2OnChange = (selectedOption) => {
        let errors = this.state.errors;
        let splittedVehicle = this.state.splittedVehicle;
        errors.charterNumberSplit2 = '';
        errors.noofPaxSplit2 = '';
        splittedVehicle.charterTypeSplit2 = '';;
        splittedVehicle.noofPaxSplit2 = 0;

        const vhId = (selectedOption.selectedItem !== null ? Number(selectedOption.selectedItem.vehicleId) : 0);
        const vhNumber = (selectedOption.selectedItem !== null ? selectedOption.selectedItem.vehicleNo : '');

        if (Number(vhId) > 0) {
            if (vhId === Number(splittedVehicle.charterIdSplit1) || vhId === Number(splittedVehicle.charterIdSplit3) || vhId === Number(splittedVehicle.charterIdSplit4) || vhId === Number(splittedVehicle.charterIdSplit5)) {
                errors.charterNumberSplit2 = 'Duplicate charter not allowed.';
            }
            let vehicleData = this.state.serviceVehicles.filter(item => item.vehicleId === Number(vhId));
            if (vehicleData !== null && vehicleData.length > 0) {
                splittedVehicle.charterTypeSplit2 = vehicleData[0].vehicleTypeDescription;
                splittedVehicle.noofPaxSplit2 = vehicleData[0].vehicleSeats;
            }
        }
        else {
            errors.charterNumberSplit2 = 'Please select vehicle.';
        }
        splittedVehicle.charterIdSplit2 = vhId;
        splittedVehicle.charterNumberSplit2 = vhNumber;
        this.setState({ errors: errors, splittedVehicle: splittedVehicle });
    }
    handlecharterNoSplit3OnChange = (selectedOption) => {
        let errors = this.state.errors;
        let splittedVehicle = this.state.splittedVehicle;
        errors.charterNumberSplit3 = '';
        errors.noofPaxSplit3 = '';
        splittedVehicle.charterTypeSplit3 = '';
        splittedVehicle.noofPaxSplit3 = 0;

        const vhId = (selectedOption.selectedItem !== null ? Number(selectedOption.selectedItem.vehicleId) : 0);
        const vhNumber = (selectedOption.selectedItem !== null ? selectedOption.selectedItem.vehicleNo : '');

        if (Number(vhId) > 0) {
            if (vhId === Number(splittedVehicle.charterIdSplit1) || vhId === Number(splittedVehicle.charterIdSplit2) || vhId === Number(splittedVehicle.charterIdSplit4) || vhId === Number(splittedVehicle.charterIdSplit5)) {
                errors.charterNumberSplit3 = 'Duplicate charter not allowed.';
            }
            let vehicleData = this.state.serviceVehicles.filter(item => item.vehicleId === Number(vhId));
            if (vehicleData !== null && vehicleData.length > 0) {
                splittedVehicle.charterTypeSplit3 = vehicleData[0].vehicleTypeDescription;
                splittedVehicle.noofPaxSplit3 = vehicleData[0].vehicleSeats;
            }
        }
        else {
            errors.charterNumberSplit3 = 'Please select vehicle.';
        }
        splittedVehicle.charterIdSplit3 = vhId;
        splittedVehicle.charterNumberSplit3 = vhNumber;
        this.setState({ errors: errors, splittedVehicle: splittedVehicle });
    }
    handlecharterNoSplit4OnChange = (selectedOption) => {
        let errors = this.state.errors;
        let splittedVehicle = this.state.splittedVehicle;
        errors.charterNumberSplit4 = '';
        errors.noofPaxSplit4 = '';
        splittedVehicle.charterTypeSplit4 = '';
        splittedVehicle.noofPaxSplit4 = 0;

        const vhId = (selectedOption.selectedItem !== null ? Number(selectedOption.selectedItem.vehicleId) : 0);
        const vhNumber = (selectedOption.selectedItem !== null ? selectedOption.selectedItem.vehicleNo : '');

        if (Number(vhId) > 0) {
            if (vhId === Number(splittedVehicle.charterIdSplit1) || vhId === Number(splittedVehicle.charterIdSplit2) || vhId === Number(splittedVehicle.charterIdSplit3) || vhId === Number(splittedVehicle.charterIdSplit5)) {
                errors.charterNumberSplit4 = 'Duplicate charter not allowed.';
            }
            let vehicleData = this.state.serviceVehicles.filter(item => item.vehicleId === Number(vhId));
            if (vehicleData !== null && vehicleData.length > 0) {
                splittedVehicle.charterTypeSplit4 = vehicleData[0].vehicleTypeDescription;
                splittedVehicle.noofPaxSplit4 = vehicleData[0].vehicleSeats;
            }
        }
        else {
            errors.charterNumberSplit4 = 'Please select vehicle.';
        }
        splittedVehicle.charterIdSplit4 = vhId;
        splittedVehicle.charterNumberSplit4 = vhNumber;
        this.setState({ errors: errors, splittedVehicle: splittedVehicle });
    }
    handlecharterNoSplit5OnChange = (selectedOption) => {
        let errors = this.state.errors;
        let splittedVehicle = this.state.splittedVehicle;
        errors.charterNumberSplit5 = '';
        errors.noofPaxSplit5 = '';
        splittedVehicle.charterTypeSplit5 = '';
        splittedVehicle.noofPaxSplit5 = 0;

        const vhId = (selectedOption.selectedItem !== null ? Number(selectedOption.selectedItem.vehicleId) : 0);
        const vhNumber = (selectedOption.selectedItem !== null ? selectedOption.selectedItem.vehicleNo : '');

        if (Number(vhId) > 0) {
            if (vhId === Number(splittedVehicle.charterIdSplit1) || vhId === Number(splittedVehicle.charterIdSplit2) || vhId === Number(splittedVehicle.charterIdSplit3) || vhId === Number(splittedVehicle.charterIdSplit4)) {
                errors.charterNumberSplit5 = 'Duplicate charter not allowed.';
            }
            let vehicleData = this.state.serviceVehicles.filter(item => item.vehicleId === Number(vhId));
            if (vehicleData !== null && vehicleData.length > 0) {
                splittedVehicle.charterTypeSplit5 = vehicleData[0].vehicleTypeDescription;
                splittedVehicle.noofPaxSplit5 = vehicleData[0].vehicleSeats;
            }
        }
        else {
            errors.charterNumberSplit5 = 'Please select vehicle.';
        }
        splittedVehicle.charterIdSplit5 = vhId;
        splittedVehicle.charterNumberSplit5 = vhNumber;
        this.setState({ errors: errors, splittedVehicle: splittedVehicle });
    }
    handleSplitSubmit = async event => {
        event.preventDefault();
        if (this.isFormValid()) {
            const isConfirm = await this.isConfirm();
            if (!isConfirm) {
                return;
            }
            this.setState({ isSubmited: true });
            let splittedVehicle = this.state.splittedVehicle;
            try {
                if (parseInt(this.state.splittedVehicle.charterIdSplit2) > 0) {
                    splittedVehicle.isSubmitedVehicleSplit2 = true;
                    this.setState({ splittedVehicle: splittedVehicle });
                    //await new Promise(resolve => setTimeout(resolve, 5000)); // 3 sec
                    try {
                        var request = new Object();
                        request.RideId = Number(this.state.pRideId);
                        request.ReservationId = Number(this.state.pReservationId);
                        request.VehicleNumber = this.state.splittedVehicle.charterNumberSplit2.trim();
                        request.Pax = Number(this.state.splittedVehicle.noofPaxSplit2);
                        request.SplitCount = Number(this.state.splitCount);
                        request.SplitNumber = 2;
                        const requestParams = getRequestParams('POST', request);
                        const response = await fetch('ride-api/split-ride/', requestParams);
                        if (response.ok) {
                            const data = await response.json();
                            if (data.isSuccess) {
                                splittedVehicle.rideIdSplit2 = data.retValue;
                                if (this.state.allowToAssign) {
                                    //Assing vehicle to trip vehicleID:charterIdSplit2,VehicleNo:charterNumberSplit2,new rideID:data.retValue,ReservationID:this.state.pReservationId                                    
                                    if (await this.isJobAssigned(parseInt(data.retValue), parseInt(this.state.splittedVehicle.charterIdSplit2), this.state.splittedVehicle.charterNumberSplit2.trim())) {
                                        splittedVehicle.isVehicleAssignSplit2 = true;
                                    }
                                }
                            }
                        }
                        else {
                        }
                    } catch (e) {
                    }
                    splittedVehicle.isSubmitedVehicleSplit2 = false;
                    this.setState({ splittedVehicle: splittedVehicle });
                }
                if (parseInt(this.state.splittedVehicle.charterIdSplit3) > 0) {
                    splittedVehicle.isSubmitedVehicleSplit3 = true;
                    this.setState({ splittedVehicle: splittedVehicle });
                    //await new Promise(resolve => setTimeout(resolve, 5000)); // 3 sec
                    try {
                        var request = new Object();
                        request.RideId = Number(this.state.pRideId);
                        request.ReservationId = Number(this.state.pReservationId);
                        request.VehicleNumber = this.state.splittedVehicle.charterNumberSplit3.trim();
                        request.Pax = Number(this.state.splittedVehicle.noofPaxSplit3);
                        request.SplitCount = Number(this.state.splitCount);
                        request.SplitNumber = 3;
                        const requestParams = getRequestParams('POST', request);
                        const response = await fetch('ride-api/split-ride/', requestParams);
                        if (response.ok) {
                            const data = await response.json();
                            if (data.isSuccess) {
                                splittedVehicle.rideIdSplit3 = data.retValue;
                                if (this.state.allowToAssign) {
                                    //Assing vehicle to trip vehicleID:charterIdSplit3,VehicleNo:charterNumberSplit3,new rideID:data.retValue,ReservationID:this.state.pReservationId
                                    if (await this.isJobAssigned(parseInt(data.retValue), parseInt(this.state.splittedVehicle.charterIdSplit3), this.state.splittedVehicle.charterNumberSplit3.trim())) {
                                        splittedVehicle.isVehicleAssignSplit3 = true;
                                    }
                                }
                            }
                        }
                        else {
                        }
                    } catch (e) {
                    }
                    splittedVehicle.isSubmitedVehicleSplit3 = false;
                    this.setState({ splittedVehicle: splittedVehicle });
                }
                if (parseInt(this.state.splittedVehicle.charterIdSplit4) > 0) {
                    splittedVehicle.isSubmitedVehicleSplit4 = true;
                    this.setState({ splittedVehicle: splittedVehicle });
                    //await new Promise(resolve => setTimeout(resolve, 5000)); // 3 sec
                    try {
                        var request = new Object();
                        request.RideId = Number(this.state.pRideId);
                        request.ReservationId = Number(this.state.pReservationId);
                        request.VehicleNumber = this.state.splittedVehicle.charterNumberSplit4.trim();
                        request.Pax = Number(this.state.splittedVehicle.noofPaxSplit4);
                        request.SplitCount = Number(this.state.splitCount);
                        request.SplitNumber = 4;
                        const requestParams = getRequestParams('POST', request);
                        const response = await fetch('ride-api/split-ride/', requestParams);
                        if (response.ok) {
                            const data = await response.json();
                            if (data.isSuccess) {
                                splittedVehicle.rideIdSplit4 = data.retValue;
                                if (this.state.allowToAssign) {
                                    //Assing vehicle to trip vehicleID:charterIdSplit4,VehicleNo:charterNumberSplit4,new rideID:data.retValue,ReservationID:this.state.pReservationId
                                    if (await this.isJobAssigned(parseInt(data.retValue), parseInt(this.state.splittedVehicle.charterIdSplit4), this.state.splittedVehicle.charterNumberSplit4.trim())) {
                                        splittedVehicle.isVehicleAssignSplit4 = true;
                                    }
                                }
                            }
                        }
                        else {
                        }
                    } catch (e) {
                    }
                    splittedVehicle.isSubmitedVehicleSplit4 = false;
                    this.setState({ splittedVehicle: splittedVehicle });
                }
                if (parseInt(this.state.splittedVehicle.charterIdSplit5) > 0) {
                    splittedVehicle.isSubmitedVehicleSplit5 = true;
                    this.setState({ splittedVehicle: splittedVehicle });
                    //await new Promise(resolve => setTimeout(resolve, 5000)); // 3 sec
                    try {
                        var request = new Object();
                        request.RideId = Number(this.state.pRideId);
                        request.ReservationId = Number(this.state.pReservationId);
                        request.VehicleNumber = this.state.splittedVehicle.charterNumberSplit5.trim();
                        request.Pax = Number(this.state.splittedVehicle.noofPaxSplit5);
                        request.SplitCount = Number(this.state.splitCount);
                        request.SplitNumber = 5;
                        const requestParams = getRequestParams('POST', request);
                        const response = await fetch('ride-api/split-ride/', requestParams);
                        if (response.ok) {
                            const data = await response.json();
                            if (data.isSuccess) {
                                splittedVehicle.rideIdSplit5 = data.retValue;
                                if (this.state.allowToAssign) {
                                    //Assing vehicle to trip vehicleID:charterIdSplit5,VehicleNo:charterNumberSplit5,new rideID:data.retValue,ReservationID:this.state.pReservationId
                                    if (await this.isJobAssigned(parseInt(data.retValue), parseInt(this.state.splittedVehicle.charterIdSplit5), this.state.splittedVehicle.charterNumberSplit5.trim())) {
                                        splittedVehicle.isVehicleAssignSplit5 = true;
                                    }
                                }
                            }
                        }
                        else {
                        }
                    } catch (e) {
                    }
                    splittedVehicle.isSubmitedVehicleSplit5 = false;
                    this.setState({ splittedVehicle: splittedVehicle });
                }
                if (parseInt(this.state.splittedVehicle.charterIdSplit1) > 0) {
                    splittedVehicle.isSubmitedVehicleSplit1 = true;
                    this.setState({ splittedVehicle: splittedVehicle });
                    //await new Promise(resolve => setTimeout(resolve, 5000)); // 3 sec
                    try {
                        var request = new Object();
                        request.RideId = Number(this.state.pRideId);
                        request.ReservationId = Number(this.state.pReservationId);
                        request.VehicleNumber = this.state.splittedVehicle.charterNumberSplit1.trim();
                        request.Pax = Number(this.state.splittedVehicle.noofPaxSplit1);
                        request.SplitCount = Number(this.state.splitCount);
                        request.SplitNumber = 1;
                        const requestParams = getRequestParams('POST', request);
                        const response = await fetch('ride-api/split-ride/', requestParams);
                        if (response.ok) {
                            const data = await response.json();
                            if (data.isSuccess) {
                                this.setState({ pRideId: data.retValue });
                                if (this.state.allowToAssign) {
                                    //Assing vehicle to trip vehicleID:charterIdSplit1,VehicleNo:charterNumberSplit1,new rideID:data.retValue,ReservationID:this.state.pReservationId
                                    if (await this.isJobAssigned(parseInt(data.retValue), parseInt(this.state.splittedVehicle.charterIdSplit1), this.state.splittedVehicle.charterNumberSplit1.trim())) {
                                        splittedVehicle.isVehicleAssignSplit1 = true;
                                    }
                                }
                                await Swal.fire({
                                    allowOutsideClick: false,
                                    icon: 'success',
                                    title: 'success',
                                    text: (data.resultMessage === null || data.resultMessage.trim() === '') ? 'Ride# [' + this.state.pRideId + '] splitted into [' + this.state.splitCount + '] ride(s) successfully!' : data.resultMessage.trim(),
                                }).then(async (result) => {
                                    if (result.isConfirmed) {
                                        //this.props.refreshDataSource();
                                    }
                                });
                            }
                            else {
                                this.onfailure('Failed to split ride.', data.resultMessage);
                            }
                        }
                        else {
                            this.onfailure();
                        }
                    } catch (e) {
                        this.onfailure();
                    }
                    splittedVehicle.isSubmitedVehicleSplit1 = false;
                    this.setState({ splittedVehicle: splittedVehicle });
                }
            } catch (e) {
                this.setState({ isSubmited: false })
            }
            this.setState({ isSubmited: false, isSplitted: true });
        }
    }
    async isConfirm() {
        let isResult = false;
        let addUpdateComfirmation = '<small>Reservation [<b>' + this.state.pRideId + '</b>] will be splitted into [<b>' + this.state.splitCount + '</b>] ride(s).</small>';
        addUpdateComfirmation = addUpdateComfirmation + '<br>Are you sure want to split?';
        await Swal.fire({
            title: '<small><b>Confirmation</b></small>',
            html: addUpdateComfirmation,
            icon: 'question',
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Split it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }
    isFormValid() {
        let formIsValid = true;
        let errors = this.state.errors;
        errors.charterNumberSplit1 = '';
        errors.charterNumberSplit2 = '';
        errors.charterNumberSplit3 = '';
        errors.charterNumberSplit4 = '';
        errors.charterNumberSplit5 = '';
        errors.noofPaxSplit1 = '';
        errors.noofPaxSplit2 = '';
        errors.noofPaxSplit3 = '';
        errors.noofPaxSplit4 = '';
        errors.noofPaxSplit5 = '';
        if (parseInt(this.state.splitCount) < 2 || parseInt(this.state.splitCount) > 5) {
            this.onfailure('', 'Please enter number of ride(s) between 2 to 5.');
            formIsValid = false;
        }
        if (formIsValid) {
            const totalPax = parseInt(this.state.splittedVehicle.noofPaxSplit1) + parseInt(this.state.splittedVehicle.noofPaxSplit2) + parseInt(this.state.splittedVehicle.noofPaxSplit3) +
                parseInt(this.state.splittedVehicle.noofPaxSplit4) + parseInt(this.state.splittedVehicle.noofPaxSplit5)
            if (parseInt(this.state.pPax) !== parseInt(totalPax)) {
                this.onfailure('Invalid Pax number', 'The total pax should match the sum of the pax numbers entered!');
                formIsValid = false;
            }
        }
        switch (parseInt(this.state.splitCount)) {
            case 1:
                if (!parseInt(this.state.splittedVehicle.charterIdSplit1) > 0) {
                    errors.charterNumberSplit1 = 'Please select vehicle.';
                    formIsValid = false;
                }
                else {
                    if (!this.checkDuplicateVehicle(1, this.state.splittedVehicle.charterIdSplit1)) {
                        formIsValid = false;
                    }
                }
                if (!parseInt(this.state.splittedVehicle.noofPaxSplit1) > 0) {
                    errors.noofPaxSplit1 = 'Please enter pax.';
                    formIsValid = false;
                }
                else {
                    if (parseInt(this.state.splittedVehicle.noofPaxSplit1) > parseInt(this.state.pPax)) {
                        errors.noofPaxSplit1 = 'Please enter no more than ' + this.state.pPax + ' pax';
                        formIsValid = false;
                    }
                }
                break;
            case 2:
                if (!parseInt(this.state.splittedVehicle.charterIdSplit1) > 0) {
                    errors.charterNumberSplit1 = 'Please select vehicle.';
                    formIsValid = false;
                }
                else {
                    if (!this.checkDuplicateVehicle(1, this.state.splittedVehicle.charterIdSplit1)) {
                        formIsValid = false;
                    }
                }
                if (!parseInt(this.state.splittedVehicle.noofPaxSplit1) > 0) {
                    errors.noofPaxSplit1 = 'Please enter pax.';
                    formIsValid = false;
                }
                else {
                    if (parseInt(this.state.splittedVehicle.noofPaxSplit1) > parseInt(this.state.pPax)) {
                        errors.noofPaxSplit1 = 'Please enter no more than ' + this.state.pPax + ' pax';
                        formIsValid = false;
                    }
                }
                if (!parseInt(this.state.splittedVehicle.charterIdSplit2) > 0) {
                    errors.charterNumberSplit2 = 'Please select vehicle.';
                    formIsValid = false;
                }
                else {
                    if (!this.checkDuplicateVehicle(2, this.state.splittedVehicle.charterIdSplit2)) {
                        formIsValid = false;
                    }
                }
                if (!parseInt(this.state.splittedVehicle.noofPaxSplit2) > 0) {
                    errors.noofPaxSplit2 = 'Please enter pax.';
                    formIsValid = false;
                }
                else {
                    if (parseInt(this.state.splittedVehicle.noofPaxSplit2) > parseInt(this.state.pPax)) {
                        errors.noofPaxSplit2 = 'Please enter no more than ' + this.state.pPax + ' pax';
                        formIsValid = false;
                    }
                }
                break;
            case 3:
                if (!parseInt(this.state.splittedVehicle.charterIdSplit1) > 0) {
                    errors.charterNumberSplit1 = 'Please select vehicle.';
                    formIsValid = false;
                }
                else {
                    if (!this.checkDuplicateVehicle(1, this.state.splittedVehicle.charterIdSplit1)) {
                        formIsValid = false
                    }
                }
                if (!parseInt(this.state.splittedVehicle.noofPaxSplit1) > 0) {
                    errors.noofPaxSplit1 = 'Please enter pax.';
                    formIsValid = false;
                }
                else {
                    if (parseInt(this.state.splittedVehicle.noofPaxSplit1) > parseInt(this.state.pPax)) {
                        errors.noofPaxSplit1 = 'Please enter no more than ' + this.state.pPax + ' pax';
                        formIsValid = false;
                    }
                }
                if (!parseInt(this.state.splittedVehicle.charterIdSplit2) > 0) {
                    errors.charterNumberSplit2 = 'Please select vehicle.';
                    formIsValid = false;
                }
                else {
                    if (!this.checkDuplicateVehicle(2, this.state.splittedVehicle.charterIdSplit2)) {
                        formIsValid = false;
                    }
                }
                if (!parseInt(this.state.splittedVehicle.noofPaxSplit2) > 0) {
                    errors.noofPaxSplit2 = 'Please enter pax.';
                    formIsValid = false;
                }
                else {
                    if (parseInt(this.state.splittedVehicle.noofPaxSplit2) > parseInt(this.state.pPax)) {
                        errors.noofPaxSplit2 = 'Please enter no more than ' + this.state.pPax + ' pax';
                        formIsValid = false;
                    }
                }
                if (!parseInt(this.state.splittedVehicle.charterIdSplit3) > 0) {
                    errors.charterNumberSplit3 = 'Please select vehicle.';
                    formIsValid = false;
                }
                else {
                    if (!this.checkDuplicateVehicle(3, this.state.splittedVehicle.charterIdSplit3)) {
                        formIsValid = false;
                    }
                }
                if (!parseInt(this.state.splittedVehicle.noofPaxSplit3) > 0) {
                    errors.noofPaxSplit3 = 'Please enter pax.';
                    formIsValid = false;
                }
                else {
                    if (parseInt(this.state.splittedVehicle.noofPaxSplit3) > parseInt(this.state.pPax)) {
                        errors.noofPaxSplit3 = 'Please enter no more than ' + this.state.pPax + ' pax';
                        formIsValid = false;
                    }
                }
                break;
            case 4:
                if (!parseInt(this.state.splittedVehicle.charterIdSplit1) > 0) {
                    errors.charterNumberSplit1 = 'Please select vehicle.';
                    formIsValid = false;
                }
                else {
                    if (!this.checkDuplicateVehicle(1, this.state.splittedVehicle.charterIdSplit1)) {
                        formIsValid = false;
                    }
                }
                if (!parseInt(this.state.splittedVehicle.noofPaxSplit1) > 0) {
                    errors.noofPaxSplit1 = 'Please enter pax.';
                    formIsValid = false;
                }
                else {
                    if (parseInt(this.state.splittedVehicle.noofPaxSplit1) > parseInt(this.state.pPax)) {
                        errors.noofPaxSplit1 = 'Please enter no more than ' + this.state.pPax + ' pax';
                        formIsValid = false;
                    }
                }
                if (!parseInt(this.state.splittedVehicle.charterIdSplit2) > 0) {
                    errors.charterNumberSplit2 = 'Please select vehicle.';
                    formIsValid = false;
                }
                else {
                    if (!this.checkDuplicateVehicle(2, this.state.splittedVehicle.charterIdSplit2)) {
                        formIsValid = false;
                    }
                }
                if (!parseInt(this.state.splittedVehicle.noofPaxSplit2) > 0) {
                    errors.noofPaxSplit2 = 'Please enter pax.';
                    formIsValid = false;
                }
                else {
                    if (parseInt(this.state.splittedVehicle.noofPaxSplit2) > parseInt(this.state.pPax)) {
                        errors.noofPaxSplit2 = 'Please enter no more than ' + this.state.pPax + ' pax';
                        formIsValid = false;
                    }
                }
                if (!parseInt(this.state.splittedVehicle.charterIdSplit3) > 0) {
                    errors.charterNumberSplit3 = 'Please select vehicle.';
                    formIsValid = false;
                }
                else {
                    if (!this.checkDuplicateVehicle(3, this.state.splittedVehicle.charterIdSplit3)) {
                        formIsValid = false;
                    }
                }
                if (!parseInt(this.state.splittedVehicle.noofPaxSplit3) > 0) {
                    errors.noofPaxSplit3 = 'Please enter pax.';
                    formIsValid = false;
                }
                else {
                    if (parseInt(this.state.splittedVehicle.noofPaxSplit3) > parseInt(this.state.pPax)) {
                        errors.noofPaxSplit3 = 'Please enter no more than ' + this.state.pPax + ' pax';
                        formIsValid = false;
                    }
                }
                if (!parseInt(this.state.splittedVehicle.charterIdSplit4) > 0) {
                    errors.charterNumberSplit4 = 'Please select vehicle.';
                    formIsValid = false;
                }
                else {
                    if (!this.checkDuplicateVehicle(4, this.state.splittedVehicle.charterIdSplit4)) {
                        formIsValid = false;
                    }
                }
                if (!parseInt(this.state.splittedVehicle.noofPaxSplit4) > 0) {
                    errors.noofPaxSplit4 = 'Please enter pax.';
                    formIsValid = false;
                }
                else {
                    if (parseInt(this.state.splittedVehicle.noofPaxSplit4) > parseInt(this.state.pPax)) {
                        errors.noofPaxSplit4 = 'Please enter no more than ' + this.state.pPax + ' pax';
                        formIsValid = false;
                    }
                }
                break;
            case 5:
                if (!parseInt(this.state.splittedVehicle.charterIdSplit1) > 0) {
                    errors.charterNumberSplit1 = 'Please select vehicle.';
                    formIsValid = false;
                }
                else {
                    if (!this.checkDuplicateVehicle(1, this.state.splittedVehicle.charterIdSplit1)) {
                        formIsValid = false;
                    }
                }
                if (!parseInt(this.state.splittedVehicle.noofPaxSplit1) > 0) {
                    errors.noofPaxSplit1 = 'Please enter pax.';
                    formIsValid = false;
                }
                else {
                    if (parseInt(this.state.splittedVehicle.noofPaxSplit1) > parseInt(this.state.pPax)) {
                        errors.noofPaxSplit1 = 'Please enter no more than ' + this.state.pPax + ' pax';
                        formIsValid = false;
                    }
                }
                if (!parseInt(this.state.splittedVehicle.charterIdSplit2) > 0) {
                    errors.charterNumberSplit2 = 'Please select vehicle.';
                    formIsValid = false;
                }
                else {
                    if (!this.checkDuplicateVehicle(2, this.state.splittedVehicle.charterIdSplit2)) {
                        formIsValid = false;
                    }
                }
                if (!parseInt(this.state.splittedVehicle.noofPaxSplit2) > 0) {
                    errors.noofPaxSplit2 = 'Please enter pax.';
                    formIsValid = false;
                }
                else {
                    if (parseInt(this.state.splittedVehicle.noofPaxSplit2) > parseInt(this.state.pPax)) {
                        errors.noofPaxSplit2 = 'Please enter no more than ' + this.state.pPax + ' pax';
                        formIsValid = false;
                    }
                }
                if (!parseInt(this.state.splittedVehicle.charterIdSplit3) > 0) {
                    errors.charterNumberSplit3 = 'Please select vehicle.';
                    formIsValid = false;
                }
                else {
                    if (!this.checkDuplicateVehicle(3, this.state.splittedVehicle.charterIdSplit3)) {
                        formIsValid = false;
                    }
                }
                if (!parseInt(this.state.splittedVehicle.noofPaxSplit3) > 0) {
                    errors.noofPaxSplit3 = 'Please enter pax.';
                    formIsValid = false;
                }
                else {
                    if (parseInt(this.state.splittedVehicle.noofPaxSplit3) > parseInt(this.state.pPax)) {
                        errors.noofPaxSplit3 = 'Please enter no more than ' + this.state.pPax + ' pax';
                        formIsValid = false;
                    }
                }
                if (!parseInt(this.state.splittedVehicle.charterIdSplit4) > 0) {
                    errors.charterNumberSplit4 = 'Please select vehicle.';
                    formIsValid = false;
                }
                else {
                    if (!this.checkDuplicateVehicle(4, this.state.splittedVehicle.charterIdSplit4)) {
                        formIsValid = false;
                    }
                }
                if (!parseInt(this.state.splittedVehicle.noofPaxSplit4) > 0) {
                    errors.noofPaxSplit4 = 'Please enter pax.';
                    formIsValid = false;
                }
                else {
                    if (parseInt(this.state.splittedVehicle.noofPaxSplit4) > parseInt(this.state.pPax)) {
                        errors.noofPaxSplit4 = 'Please enter no more than ' + this.state.pPax + ' pax';
                        formIsValid = false;
                    }
                }
                if (!parseInt(this.state.splittedVehicle.charterIdSplit5) > 0) {
                    errors.charterNumberSplit5 = 'Please select vehicle.';
                    formIsValid = false;
                }
                else {
                    if (!this.checkDuplicateVehicle(5, this.state.splittedVehicle.charterIdSplit5)) {
                        formIsValid = false;
                    }
                }
                if (!parseInt(this.state.splittedVehicle.noofPaxSplit5) > 0) {
                    errors.noofPaxSplit5 = 'Please enter pax.';
                    formIsValid = false;
                }
                else {
                    if (parseInt(this.state.splittedVehicle.noofPaxSplit5) > parseInt(this.state.pPax)) {
                        errors.noofPaxSplit5 = 'Please enter no more than ' + this.state.pPax + ' pax';
                        formIsValid = false;
                    }
                }
                break;
            default:
                break;
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    checkDuplicateVehicle(splitNumber, vehicleId) {
        let isValid = true;
        let errors = this.state.errors;
        vehicleId = parseInt(vehicleId);
        switch (splitNumber) {
            case 1:
                errors.charterNumberSplit1 = '';
                if (vehicleId === parseInt(this.state.splittedVehicle.charterIdSplit2)
                    || vehicleId === parseInt(this.state.splittedVehicle.charterIdSplit3)
                    || vehicleId === parseInt(this.state.splittedVehicle.charterIdSplit4)
                    || vehicleId === parseInt(this.state.splittedVehicle.charterIdSplit5)) {
                    errors.charterNumberSplit1 = 'Duplicate charter not allowed.';
                    isValid = false;
                }
                break;
            case 2:
                errors.charterNumberSplit2 = '';
                if (vehicleId === parseInt(this.state.splittedVehicle.charterIdSplit1)
                    || vehicleId === parseInt(this.state.splittedVehicle.charterIdSplit3)
                    || vehicleId === parseInt(this.state.splittedVehicle.charterIdSplit4)
                    || vehicleId === parseInt(this.state.splittedVehicle.charterIdSplit5)) {
                    errors.charterNumberSplit2 = 'Duplicate charter not allowed.';
                    isValid = false;
                }
                break;
            case 3:
                errors.charterNumberSplit3 = '';
                if (vehicleId === parseInt(this.state.splittedVehicle.charterIdSplit1)
                    || vehicleId === parseInt(this.state.splittedVehicle.charterIdSplit2)
                    || vehicleId === parseInt(this.state.splittedVehicle.charterIdSplit4)
                    || vehicleId === parseInt(this.state.splittedVehicle.charterIdSplit5)) {
                    errors.charterNumberSplit3 = 'Duplicate charter not allowed.';
                    isValid = false;
                }
                break;
            case 4:
                errors.charterNumberSplit4 = '';
                if (vehicleId === parseInt(this.state.splittedVehicle.charterIdSplit1)
                    || vehicleId === parseInt(this.state.splittedVehicle.charterIdSplit2)
                    || vehicleId === parseInt(this.state.splittedVehicle.charterIdSplit3)
                    || vehicleId === parseInt(this.state.splittedVehicle.charterIdSplit5)) {
                    errors.charterNumberSplit4 = 'Duplicate charter not allowed.';
                    isValid = false;
                }
                break;
            case 5:
                errors.charterNumberSplit5 = '';
                if (vehicleId === parseInt(this.state.splittedVehicle.charterIdSplit1)
                    || vehicleId === parseInt(this.state.splittedVehicle.charterIdSplit2)
                    || vehicleId === parseInt(this.state.splittedVehicle.charterIdSplit3)
                    || vehicleId === parseInt(this.state.splittedVehicle.charterIdSplit4)) {
                    errors.charterNumberSplit5 = 'Duplicate charter not allowed.';
                    isValid = false;
                }
                break;
            default:
                break;
        }
        this.setState({ errors: errors });
        return isValid;
    }
    onfailure(title, resultMessage) {
        Swal.fire({
            allowOutsideClick: false,
            showCloseButton: true,
            icon: 'error',
            title: (title === null || title.trim() === '') ? 'Oops...' : title,
            text: (resultMessage === null || resultMessage.trim() === '') ? 'Something went wrong!' : resultMessage.trim(),
        })
    }
    async isJobAssigned(rideId, vehicleId, vehicleNumber,) {
        let isSuccess = false;
        let rides = [];
        rides.push(Number(rideId));
        var assignJobRequest = new Object();
        let vehicleData = this.state.serviceVehicles.filter(item => item.vehicleId === parseInt(vehicleId));
        if (vehicleData !== null && vehicleData.length > 0) {
            assignJobRequest.DriverId = vehicleData[0].driverId;
        }
        assignJobRequest.Rides = rides;
        assignJobRequest.VehicleNo = vehicleNumber.trim();
        const requestJobParams = getRequestParams('POST', assignJobRequest);
        const responseJob = await fetch('dispatch-api/assign-job', requestJobParams);
        const dataJob = await responseJob.json();
        if (dataJob.isSuccess) {
            isSuccess = true;
        }
        return isSuccess;
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={customStyles}
                    className={"react-modal"}
                    onRequestClose={this.handleCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div id="dvPanelLoad">
                            <div className="modal-header">
                                <h6 className="modal-title">{this.state.pageName}</h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCloseModal}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <label>Number of Ride(s) <span className="text-danger">*</span></label>
                                            <NumberFormat className="form-control form-control-sm" name="splitCount" title="Split Number" placeholder="0" format="#" style={{ float: 'right', width: '100px', marginRight: '0px' }} value={this.state.splitCount} onValueChange={(e) => { this.handleOnSplitCountChange(e) /*this.setState({ splitCount: e.value })*/ }} />
                                        </div>
                                        {this.state.errors["splitCountErr"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["splitCountErr"]}</span>}
                                    </div>
                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <label>Total Pax <span style={{ fontSize: '14PX' }}>&nbsp;&nbsp;<b>{this.state.pPax}</b></span></label>
                                        </div>
                                    </div>
                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label>Booking Charter Type <span style={{ fontSize: '14PX' }}>&nbsp;&nbsp;<b>{this.state.pVehicleType}</b></span></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <hr style={{ marginTop: '15px', marginBottom: '5px', width: '100%', backgroundColor: 'red' }} />
                                </div>
                                <div id="dvSplit1" className="row" style={{ display: Number(this.state.splitCount) > 0 || 'none' }}>
                                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <label>Charter Number <span className="text-danger">*</span></label>
                                            <SelectBox
                                                dataSource={this.state.serviceVehicles.length > 0 ? this.state.serviceVehicles : []}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select Charter"
                                                name="charterNoSplit1"
                                                defaultValue={0}
                                                value={this.state.splittedVehicle.charterIdSplit1}
                                                displayExpr='vehicleNo'
                                                valueExpr='vehicleId'
                                                onSelectionChanged={this.handlecharterNoSplit1OnChange}
                                                showClearButton={true}
                                                disabled={false}
                                                searchEnabled={true}
                                            />
                                            {this.state.errors["charterNumberSplit1"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["charterNumberSplit1"]}</span>}
                                        </div>
                                    </div>
                                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label>Charter Type </label>
                                            <input type="text" name="charterTypeSplit1" className="form-control form-control-sm" readOnly={true} value={this.state.splittedVehicle.charterTypeSplit1} />
                                        </div>
                                    </div>
                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <label>Number of Pax <span className="text-danger">*</span></label>
                                            <input type="text" name="noofPaxSplit1" className="form-control form-control-sm" value={this.state.splittedVehicle.noofPaxSplit1} onChange={this.handleOnInputChange} />
                                            {this.state.errors["noofPaxSplit1"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["noofPaxSplit1"]}</span>}
                                        </div>
                                    </div>
                                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label>Ride Id </label>
                                            <input type="text" name="rideIdSplit1" className="form-control form-control-sm" readOnly={true} value={this.state.pRideId} />
                                            {this.state.isSubmited && this.state.splittedVehicle.isSubmitedVehicleSplit1
                                                && <span style={{ position: 'absolute', top: '28px', right: '15px' }}>
                                                    <i className="fas fa-spinner fa-spin"></i> </span>}
                                            {this.state.splittedVehicle.isVehicleAssignSplit1
                                                && <span style={{ position: 'absolute', top: '28px', right: '15px' }}>
                                                    <i className="fas fa-check"></i> </span>}
                                        </div>
                                    </div>
                                </div>
                                <div id="dvSplit2" className="row" style={{ display: Number(this.state.splitCount) > 1 || 'none' }}>
                                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <label>Charter Number <span className="text-danger">*</span></label>
                                            <SelectBox
                                                dataSource={this.state.serviceVehicles.length > 0 ? this.state.serviceVehicles : []}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select Charter"
                                                name="charterNoSplit2"
                                                defaultValue={0}
                                                value={this.state.splittedVehicle.charterIdSplit2}
                                                displayExpr='vehicleNo'
                                                valueExpr='vehicleId'
                                                onSelectionChanged={this.handlecharterNoSplit2OnChange}
                                                showClearButton={true}
                                                disabled={false}
                                                searchEnabled={true}
                                            />
                                            {this.state.errors["charterNumberSplit2"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["charterNumberSplit2"]}</span>}
                                        </div>
                                    </div>
                                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label>Charter Type </label>
                                            <input type="text" name="charterTypeSplit2" className="form-control form-control-sm" readOnly={true} value={this.state.splittedVehicle.charterTypeSplit2} />
                                        </div>
                                    </div>
                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <label>Number of Pax <span className="text-danger">*</span></label>
                                            <input type="text" name="noofPaxSplit2" className="form-control form-control-sm" value={this.state.splittedVehicle.noofPaxSplit2} onChange={this.handleOnInputChange} />
                                            {this.state.errors["noofPaxSplit2"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["noofPaxSplit2"]}</span>}
                                        </div>
                                    </div>
                                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label>Ride Id </label>
                                            <input type="text" name="rideIdSplit2" className="form-control form-control-sm" readOnly={true} value={this.state.splittedVehicle.rideIdSplit2} />
                                            {this.state.isSubmited && this.state.splittedVehicle.isSubmitedVehicleSplit2
                                                && <span style={{ position: 'absolute', top: '28px', right: '15px' }}>
                                                    <i className="fas fa-spinner fa-spin"></i> </span>}
                                            {this.state.splittedVehicle.isVehicleAssignSplit2
                                                && <span style={{ position: 'absolute', top: '28px', right: '15px' }}>
                                                    <i className="fas fa-check"></i> </span>}
                                        </div>
                                    </div>
                                </div>
                                <div id="dvSplit3" className="row" style={{ display: Number(this.state.splitCount) > 2 || 'none' }}>
                                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <label>Charter Number <span className="text-danger">*</span></label>
                                            <SelectBox
                                                dataSource={this.state.serviceVehicles.length > 0 ? this.state.serviceVehicles : []}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select Charter"
                                                name="charterNoSplit3"
                                                defaultValue={0}
                                                value={this.state.splittedVehicle.charterIdSplit3}
                                                displayExpr='vehicleNo'
                                                valueExpr='vehicleId'
                                                onSelectionChanged={this.handlecharterNoSplit3OnChange}
                                                showClearButton={true}
                                                disabled={false}
                                                searchEnabled={true}
                                            />
                                            {this.state.errors["charterNumberSplit3"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["charterNumberSplit3"]}</span>}
                                        </div>
                                    </div>
                                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label>Charter Type </label>
                                            <input type="text" name="charterTypeSplit3" className="form-control form-control-sm" readOnly={true} value={this.state.splittedVehicle.charterTypeSplit3} />
                                        </div>
                                    </div>
                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <label>Number of Pax <span className="text-danger">*</span></label>
                                            <input type="text" name="noofPaxSplit3" className="form-control form-control-sm" value={this.state.splittedVehicle.noofPaxSplit3} onChange={this.handleOnInputChange} />
                                            {this.state.errors["noofPaxSplit3"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["noofPaxSplit3"]}</span>}
                                        </div>
                                    </div>
                                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label>Ride Id </label>
                                            <input type="text" name="rideIdSplit3" className="form-control form-control-sm" readOnly={true} value={this.state.splittedVehicle.rideIdSplit3} />
                                            {this.state.isSubmited && this.state.splittedVehicle.isSubmitedVehicleSplit3
                                                && <span style={{ position: 'absolute', top: '28px', right: '15px' }}>
                                                    <i className="fas fa-spinner fa-spin"></i> </span>}
                                            {this.state.splittedVehicle.isVehicleAssignSplit3
                                                && <span style={{ position: 'absolute', top: '28px', right: '15px' }}>
                                                    <i className="fas fa-check"></i> </span>}
                                        </div>
                                    </div>
                                </div>
                                <div id="dvSplit4" className="row" style={{ display: Number(this.state.splitCount) > 3 || 'none' }}>
                                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <label>Charter Number <span className="text-danger">*</span></label>
                                            <SelectBox
                                                dataSource={this.state.serviceVehicles.length > 0 ? this.state.serviceVehicles : []}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select Charter"
                                                name="charterNoSplit4"
                                                defaultValue={0}
                                                value={this.state.splittedVehicle.charterIdSplit4}
                                                displayExpr='vehicleNo'
                                                valueExpr='vehicleId'
                                                onSelectionChanged={this.handlecharterNoSplit4OnChange}
                                                showClearButton={true}
                                                disabled={false}
                                                searchEnabled={true}
                                            />
                                            {this.state.errors["charterNumberSplit4"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["charterNumberSplit4"]}</span>}
                                        </div>
                                    </div>
                                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label>Charter Type </label>
                                            <input type="text" name="charterTypeSplit4" className="form-control form-control-sm" readOnly={true} value={this.state.splittedVehicle.charterTypeSplit4} />
                                        </div>
                                    </div>
                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <label>Number of Pax <span className="text-danger">*</span></label>
                                            <input type="text" name="noofPaxSplit4" className="form-control form-control-sm" value={this.state.splittedVehicle.noofPaxSplit4} onChange={this.handleOnInputChange} />
                                            {this.state.errors["noofPaxSplit4"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["noofPaxSplit4"]}</span>}
                                        </div>
                                    </div>
                                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label>Ride Id </label>
                                            <input type="text" name="rideIdSplit4" className="form-control form-control-sm" readOnly={true} value={this.state.splittedVehicle.rideIdSplit4} />
                                            {this.state.isSubmited && this.state.splittedVehicle.isSubmitedVehicleSplit4
                                                && <span style={{ position: 'absolute', top: '28px', right: '15px' }}>
                                                    <i className="fas fa-spinner fa-spin"></i> </span>}
                                            {this.state.splittedVehicle.isVehicleAssignSplit4
                                                && <span style={{ position: 'absolute', top: '28px', right: '15px' }}>
                                                    <i className="fas fa-check"></i> </span>}
                                        </div>
                                    </div>
                                </div>
                                <div id="dvSplit5" className="row" style={{ display: Number(this.state.splitCount) > 4 || 'none' }}>
                                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <label>Charter Number <span className="text-danger">*</span></label>
                                            <SelectBox
                                                dataSource={this.state.serviceVehicles.length > 0 ? this.state.serviceVehicles : []}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select Charter"
                                                name="charterNoSplit5"
                                                defaultValue={0}
                                                value={this.state.splittedVehicle.charterIdSplit5}
                                                displayExpr='vehicleNo'
                                                valueExpr='vehicleId'
                                                onSelectionChanged={this.handlecharterNoSplit5OnChange}
                                                showClearButton={true}
                                                disabled={false}
                                                searchEnabled={true}
                                            />
                                            {this.state.errors["charterNumberSplit5"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["charterNumberSplit5"]}</span>}
                                        </div>
                                    </div>
                                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label>Charter Type </label>
                                            <input type="text" name="charterTypeSplit5" className="form-control form-control-sm" readOnly={true} value={this.state.splittedVehicle.charterTypeSplit5} />
                                        </div>
                                    </div>
                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <label>Number of Pax <span className="text-danger">*</span></label>
                                            <input type="text" name="noofPaxSplit5" className="form-control form-control-sm" value={this.state.splittedVehicle.noofPaxSplit5} onChange={this.handleOnInputChange} />
                                            {this.state.errors["noofPaxSplit5"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["noofPaxSplit5"]}</span>}
                                        </div>
                                    </div>
                                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                        <div className="form-group">
                                            <label>Ride Id </label>
                                            <input type="text" name="rideIdSplit5" className="form-control form-control-sm" readOnly={true} value={this.state.splittedVehicle.rideIdSplit5} />
                                            {this.state.isSubmited && this.state.splittedVehicle.isSubmitedVehicleSplit5
                                                && <span style={{ position: 'absolute', top: '28px', right: '15px' }}>
                                                    <i className="fas fa-spinner fa-spin"></i> </span>}
                                            {this.state.splittedVehicle.isVehicleAssignSplit5
                                                && <span style={{ position: 'absolute', top: '28px', right: '15px' }}>
                                                    <i className="fas fa-check"></i> </span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-success btn-sm" onClick={this.handleSplitSubmit} disabled={this.state.isSplitted}>
                                    {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                    {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                                </button>
                                <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleCloseModal} title="Close"><i className="fas fa-times"></i> Close</button>
                            </div>
                        </div>
                        <LoadPanel
                            shadingColor="rgba(0,0,0,0.4)"
                            position={{ of: '#dvPanelLoad' }}
                            visible={this.state.loadPanelVisible}
                            showIndicator={true}
                            shading={true}
                            showPane={true}
                            closeOnOutsideClick={false}
                        />
                    </form>
                </Modal>
            </div>
        );
    }
}