import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import DateBox from 'devextreme-react/date-box';
//import withReactContent from 'sweetalert2-react-content';
import { getRequestParams } from '../../helpers/globalfunctions';
import {  DateFormat } from '../../helpers/fixcodes';
import { LoadPanel } from 'devextreme-react/load-panel';
import { ReservationHistoryComponent } from '../Shared/ReservationHistoryComponent';
import { InvoiceRidesComponent } from '../Shared/InvoiceRidesComponent';
import { InvoicePaymentComponent } from '../Shared/InvoicePaymentComponent';
const position = { of: '#historydiv' };

let currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
let currencyIcon = sessionStorage.getItem("session_currencyIcon") === "" ? "fas fa-dollar-sign" : sessionStorage.getItem("session_currencyIcon");

export class SystemFeesComponent extends Component {
    constructor(props) {
        super(props);
        currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        this.state = {
            isSubmited: false,
            invoiceNumber: 0,
            fromDateTime: new Date(currentTime.toLocaleDateString() + " 00:00"),
            toDateTime: new Date(currentTime.toLocaleDateString() + " 23:59"),
            draftInvoices: [],

            loadPanelVisible: false,
        }

        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.handleFromValueChanged = this.handleFromValueChanged.bind(this);
        this.handleToValueChanged = this.handleToValueChanged.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.getApprovedInvoices = this.getApprovedInvoices.bind(this);

        this.handleSearchUnapproveRidesSubmit = this.handleSearchUnapproveRidesSubmit.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
        this.openInvoiceHistoryModel = this.openInvoiceHistoryModel.bind(this);
        this.openInvoiceTripModel = this.openInvoiceTripModel.bind(this);
        this.openPaymentModel = this.openPaymentModel.bind(this);
        this.viewInvoiceReport = this.viewInvoiceReport.bind(this);
    }
    async componentDidMount() {
        this.setState({
            loadPanelVisible: true,
        });

        if (this.props.location.state != undefined) {

            this.setState({
                fromDateTime: this.props.location.state.fromDateTime,
                toDateTime: this.props.location.state.toDateTime,
            });

            setTimeout(() => this.getApprovedInvoices(), 200)
        }
        this.setState({
            loadPanelVisible: false,
        });
    }
    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getApprovedInvoices.bind(this)
            }
        });
    }
    async getApprovedInvoices() {

        var request = new Object();

        request.InvoiceFromDate = this.state.fromDateTime == null ? "" : this.state.fromDateTime.toLocaleDateString() + " 00:00:00"; //new Date(2020, 9, 1);
        request.InvoiceToDate = this.state.toDateTime == null ? "" : this.state.toDateTime.toLocaleDateString() + " 23:59:59"; //new Date(2020, 9, 31);
        request.InvoiceNo = this.state.invoiceNumber !== "" ? parseInt(this.state.invoiceNumber) : 0;

        try {
            this.setState({
                isSubmited: true,
            });
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('invoice-api/system-fees', requestParams);
            const data = await response.json();
            this.setState({
                draftInvoices: data.invoiceList
            });
            this.setState({ isSubmited: false });

        } catch (e) {
            this.setState({ isSubmited: false });
        }

    }
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === "invoiceNumber") {
            const re = /^[0-9\b]+$/;
            if (event.target.value === '' || re.test(event.target.value)) {
                this.setState({ [name]: event.target.value })
            }
        } else {
            this.setState({
                [name]: value
            });
        }

    }
    handleFromValueChanged(date) {
        this.setState({ fromDateTime: date.value });
    }
    handleToValueChanged(date) {
        this.setState({ toDateTime: date.value });
    }

    async handleSearchUnapproveRidesSubmit(e) {
        e.preventDefault();

        await this.getApprovedInvoices();
    }
    handleClearSearch() {
        this.setState({
            isSubmited: false,
            invoiceNumber: 0,
            fromDateTime: new Date(currentTime.toLocaleDateString() + " 00:00"),
            toDateTime: new Date(currentTime.toLocaleDateString() + " 23:59"),
            draftInvoices: [],
        });
    }


    openInvoiceHistoryModel(e, data) {
        e.preventDefault();
        this.refs.reservationHistoryModal.showModal("Billing", data.invoiceNo);
    }


    openInvoiceTripModel(e, data) {
        e.preventDefault();
        this.refs.invoiceRidesModal.showModal(0, "", "", false, data.invoiceNo);
    }


    async viewInvoiceReport(e, data, fileFormat) {
        e.preventDefault();
        this.setState({
            loadPanelVisible: true,
        });
        var request = new Object();
        request.InvoiceNo = parseInt(data.invoiceNo);
        request.fileFormat = fileFormat;
        request.MethodName = "GetSystemFeesReport";
        const requestParams = getRequestParams('POST', request);
        const response = await fetch('crystal-report-api', requestParams);
        const dataResponse = await response.json();
        this.setState({
            loadPanelVisible: false,
        });

        let a = document.createElement('a');
        a.href = dataResponse.reportBase64String;
        if (fileFormat == "pdf") {
            a.download = 'SystemFeesInvoice_' + data.invoiceNo + '.pdf';
        } else {
            a.download = 'SystemFeesInvoice_' + data.invoiceNo + '.xlsx';
        }
        a.click();

    }

    openPaymentModel(e, data) {
        e.preventDefault();
        this.refs.invoicePaymentComponent.showModal("SystemFees", data.invoiceNo,data.customerId,true);
    }

    // <Column dataField="totalCreditCc" caption="Credit Cc" width={80} allowFiltering={true} allowSorting={true} format="currency" />
    //<Column dataField="totalCreditCash" caption="Credit Cash" width={80} allowFiltering={true} allowSorting={true} format="currency" />
    //<Column dataField="totalCreditCheck" caption="Credit Check" width={80} allowFiltering={true} allowSorting={true} format="currency" />
    //<Column dataField="totalCreditOtherAmount" caption="Credit OtherAmount" width={100} allowFiltering={true} allowSorting={true} format="currency" />

    render() {

        const filterSearchFromDate = time => {
            const currentDate = currentTime;
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }

        const filterSearchToDate = time => {
            const currentDate = currentTime;
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }
        const isDisableAllButton = this.state.draftInvoices.length > 0 ? false : true;

        const renderGridCell = (data) => {

            return <div>
                <a href="#" className="fas fa-history icon text-info" title="History" onClick={(e) => { this.openInvoiceHistoryModel(e, data.data) }}></a>&nbsp;&nbsp;
                <a href="#" className="fas fa-list icon text-info" title="Ride Details" onClick={(e) => { this.openInvoiceTripModel(e, data.data) }}></a>&nbsp;&nbsp;
                <a href="#" className="fas fa-money-bill icon text-info" title="Payment" onClick={(e) => { this.openPaymentModel(e, data.data) }}></a>&nbsp;&nbsp;
                <a href="#" className="fas fa-file-pdf icon text-info" title="View in PDF" onClick={(e) => { this.viewInvoiceReport(e, data.data, "pdf") }}></a>&nbsp;&nbsp;
                <a href="#" className="fas fa-file-excel icon text-info" title="View in Excel" onClick={(e) => { this.viewInvoiceReport(e, data.data, "xlsx") }}></a>&nbsp;&nbsp;
            </div>;
        }

        const renderGridCurrencyCell = (data) => {
            return <div><i className={currencyIcon}></i>&nbsp;{parseFloat(data.value).toFixed(2)}</div>;
        }

        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-sm-6 col-md-6 col-lg-6">
                                <h1 className="m-0 text-dark">System Fees</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem><NavLink tag={Link} to="/invoice/generate" className="btn btn-block btn-outline-secondary btn-sm" title="Generate Invoice" ><i className="fas fa-file-invoice"></i> Generate Invoice</NavLink></NavItem>
                                    <NavItem> <NavLink tag={Link} to="/invoice/draft" className="btn btn-block btn-outline-secondary btn-sm" title="Draft Invoice" ><i className="fas fa-tasks"></i> Draft Invoice</NavLink></NavItem>
                                    <NavItem> <NavLink tag={Link} to="/invoice/approved" className="btn btn-block btn-outline-secondary btn-sm" title="Approved Invoice" ><i className="fas fa-check"></i> Approved Invoice</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <form method="post" onSubmit={this.handleSearchUnapproveRidesSubmit} style={(this.state.isSubmited) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="rez-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>Invoice #</label>
                                        <input type="text" className="form-control form-control-sm" name="invoiceNumber" maxLength="30" title="Enter invoice number" value={this.state.invoiceNumber} onChange={this.handleInputChange} placeholder="Enter invoice #" />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>Billing Start Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDateTime"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.fromDateTime}
                                            value={this.state.fromDateTime}
                                            onValueChanged={this.handleFromValueChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>Billing End Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDateTime"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.toDateTime}
                                            value={this.state.toDateTime}
                                            onValueChanged={this.handleToValueChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-right">
                                    <div className="form-group">
                                        <label className="btn-block">&nbsp;</label>
                                        <button type="submit" className="btn btn-success btn-sm" onClick={this.handleSearchUnapproveRidesSubmit} >
                                            {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSubmited && <span title="Search"><i className="fas fa-search"></i> Search</span>}
                                        </button>&nbsp; &nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" disabled={isDisableAllButton} onClick={this.handleClearSearch} title="Clear"><i className="fas fa-eraser"></i> Clear</button>&nbsp; &nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="content">
                    <div className="card">
                        <div className="card-body">
                            <DataGrid
                                dataSource={this.state.draftInvoices}
                                keyExpr="invoiceNo"

                                columnsAutoWidth={false}
                                showColumnLines={false}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                showBorders={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                width='100%'
                                onToolbarPreparing={this.onToolbarPreparing}
                                ref={ref => this.dataGrid = ref}>
                             
                                <Column dataField="invoiceNo" width={120} caption="" fixed={true}  allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                <Column dataField="displayInvoiceNo" caption="Invoice Number" fixed={true} width={100} allowFiltering={true} allowSorting={true} />
                                <Column dataField="invoiceDate" caption="Invoice Date" width={100} allowFiltering={true} allowSorting={true} format={DateFormat.DefaultDateTimeFormat} dataType="datetime" />
                                <Column dataField="invoiceStartDate" caption="Invoice Start Date" width={120} allowFiltering={true} allowSorting={true} format={DateFormat.DefaultDateTimeFormat} dataType="datetime" />
                                <Column dataField="invoiceEndDate" caption="Invoice End Date" width={120} allowFiltering={true} allowSorting={true} format={DateFormat.DefaultDateTimeFormat} dataType="datetime" />

                                <Column dataField="netAmount" caption="Net Amount" allowEditing={false} width={100} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="taxAmount" caption="Tax Amount" width={100} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="ccChargeAmount" caption="CC Charge" width={100} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="billAmount" caption="Bill Amount" width={100} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="writeOffAmount" caption="Write-Off" allowEditing={false} width={100} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="discountAmount" caption="Discount Amount" width={100} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="dueAmount" caption="Due Amount" width={100} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="paidAmount" caption="Paid Amount" width={100} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />

                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={false} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                <Export enabled={true} allowExportSelectedData={false} />
                                <ColumnChooser enabled={true} />
                                <ColumnFixing enabled={true} />
                            </DataGrid>
                        </div>
                    </div>

                </div>

                <ReservationHistoryComponent ref="reservationHistoryModal" />
                <InvoiceRidesComponent ref="invoiceRidesModal" />
                <InvoicePaymentComponent getApprovedInvoices={this.getApprovedInvoices} ref="invoicePaymentComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >)
    }
}