import React, { Component } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { MasterHistoryComponent } from '../Shared/MasterHistoryComponent';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react/load-panel';
export class AddonsComponent extends Component {
    constructor() {
        super()
        this.state = {
            isSubmitted: false,
            isRefreshSubmited: false,
            loadPanelVisible: false,
            addonsGridDataSource: null,
            addonData: null,
            addonModalIsOpen: false,
            isViewOnly: false,
            id: 0,
            name: "",
            isDefault: false,
            isActive: false,
            errors: {
                name: '',
            }
        }
    }
    componentDidMount() {
        this.getAddonsList();
    }
    getAddonsList = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            var request = new Object();
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('addons-api/0/list', requestParams);
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    addonsGridDataSource: data.addons
                });
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    };
    handleToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getAddonsList.bind(this)
            }
        });
    }
    handleOnRefresh = async () => {
        try {
            this.setState({ isRefreshSubmited: true });
            await this.getAddonsList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isRefreshSubmited: false });
    }
    handleAddAddons = async (e) => {
        e.preventDefault();
        try {
            this.setState({
                addonModalIsOpen: true,
                addonData: null
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleEditAddons = async (e, data) => {
        e.preventDefault();
        try {
            if (data !== null) {
                this.setState({
                    addonModalIsOpen: true,
                    addonData: data
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    handleViewAddons = async (e, data) => {
        e.preventDefault();
        try {
            if (data !== null) {
                this.setState({
                    addonModalIsOpen: true,
                    addonData: data,
                    isViewOnly: true,
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    handleHistoryModal = (e, data) => {
        e.preventDefault();
        this.refs.masterHistoryComponent.showModal('addons-api', 'Addon', data.name === null ? '' : data.name.trim(), data.id === null ? 0 : Number(data.id));
    }
    handleAssignDataToState = async () => {
        if (this.state.addonData === null) {
            this.setState({
                id: 0,
                name: "",
                isDefault: false,
                isActive: false,
            });

        } else {
            const addon = this.state.addonData;
            this.setState({
                id: addon.id === null ? 0 : Number(addon.id),
                name: addon.name === null ? '' : addon.name.trim(),
                isDefault: addon.isDefault || false,
                isActive: addon.isActive || false,
            });
        }
    }
    handleResetStateData = async () => {
        this.setState({
            isSubmitted: false,
            isRefreshSubmited: false,
            loadPanelVisible: false,
            addonData: null,
            addonModalIsOpen: false,
            isViewOnly: false,
            id: 0,
            name: "",
            isDefault: false,
            isActive: false,
            errors: {
                name: '',
            }
        });
    }
    handleInputChange = (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
            let errors = this.state.errors;
            switch (name) {
                case 'addonName':
                    errors.name = "";
                    if (this.addonNameInputValidator.value.trim().length === 0) {
                        errors.name = "Please enter addon name.";
                    }
                    else {
                        if (this.addonNameInputValidator.value.length > 50) {
                            errors.name = "Please enter addon name less than or equal to 50 characters.";
                        }
                    }
                    this.setState({ name: value });
                    break;
                default:
                    this.setState({
                        [name]: value
                    });
            }
            this.setState({ errors: errors });
        } catch (ex) {
            debugger;
            console.log(ex);
        }
    }
    handleAddonSubmit = async event => {
        event.preventDefault();
        try {
            if (this.isFormValid()) {
                let isConfirm = await this.isConfirm((this.state.id === 0 ? 'add' : 'update'), this.state.name.trim(), '<small><b>Save Confirmation</b></small>')
                if (isConfirm) {
                    this.setState({ isSubmitted: true });
                    var request = new Object();
                    request.AddonName = this.state.name.trim();
                    request.IsDefault = this.state.isDefault;
                    const requestParams = getRequestParams('PUT', request);
                    const response = await fetch('addons-api/' + parseInt(this.state.id) + '/save', requestParams);
                    const data = await response.json();
                    let icon = '';
                    let title = '';
                    if (data.isSuccess) {
                        icon = 'success';
                        title = 'Success';
                    }
                    else {
                        if (data.retValue === -1) {
                            icon = 'warning';
                            title = 'Unable to process';
                        }
                        else {
                            icon = 'error';
                            title = 'Oops...';
                        }
                    }
                    await Swal.fire({
                        allowOutsideClick: false,
                        icon: icon,
                        title: title,
                        text: (data.isSuccess === false && (data.resultMessage === null || data.resultMessage === "")) ? 'Unable to process the request.' : data.resultMessage,
                    }).then(async (result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (data.isSuccess) {
                            if (result.isConfirmed) {
                                await this.handleResetStateData();
                                await this.getAddonsList();
                            }
                        }
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            })
        }
        this.setState({ isSubmitted: false })
    }
    isFormValid = () => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.name = '';
            if (this.state.name.trim() === '' || this.addonNameInputValidator.value.trim().length === 0) {
                formIsValid = false;
                errors.name = "Please enter addon name.";
            }
            else {
                if (this.state.name.length > 50 || this.addonNameInputValidator.value.length > 50) {
                    formIsValid = false;
                    errors.name = "Please enter addon name less than or equal to 50 characters.";
                }
            }
            this.setState({ errors: errors });
        } catch (ex) {
            debugger;
            formIsValid = false;
            console.log(ex);
        }
        return formIsValid;
    }
    handleDeleteAddon = async (event, data) => {
        event.preventDefault();
        try {
            let addonId = data.id;
            let isConfirm = await this.isConfirm('delete', data.name.trim(), 'Delete')
            if (isConfirm) {
                const requestParams = getRequestParams('DELETE');
                let apiUrl = 'addons-api/' + parseInt(addonId) + '/delete';
                const response = await fetch(apiUrl, requestParams);
                const data = await response.json();
                let icon = '';
                let title = '';
                if (data.isSuccess) {
                    icon = 'success';
                    title = 'Success';
                }
                else {
                    if (data.retValue === -1) {
                        icon = 'warning';
                        title = 'Unable to process';
                    }
                    else {
                        icon = 'error';
                        title = 'Oops...';
                    }
                }
                await Swal.fire({
                    allowOutsideClick: false,
                    icon: icon,
                    title: title,
                    text: (data.isSuccess === false && (data.resultMessage === null || data.resultMessage === "")) ? 'Unable to process the request.' : data.resultMessage,
                }).then(async (result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            await this.handleResetStateData();
                            await this.getAddonsList();
                        }
                    }
                })
            }
        } catch (ex) {
            console.log(ex);
            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            })
        }
    }
    handleActivateDeactivateAddon = async (e, data, isActive) => {
        e.preventDefault();
        try {
            let addonId = data.id;
            let msg = 'Are you sure want to ' + (isActive ? 'Activate' : 'Deactivate') + ' this addon [ ' + data.name + ' ]'
            let title = (isActive ? 'Activate' : 'Deactivate');
            let isConfirm = await this.isConfirm('status', msg, title);
            if (isConfirm) {
                var request = new Object();
                const requestParams = getRequestParams('PUT', request);
                const response = await fetch('addons-api/' + parseInt(addonId) + '/' + isActive + '/update-status', requestParams);
                const data = await response.json();
                let icon = '';
                let title = '';
                if (data.isSuccess) {
                    icon = 'success';
                    title = 'Success';
                }
                else {
                    if (data.retValue === -1) {
                        icon = 'warning';
                        title = 'Unable to process';
                    }
                    else {
                        icon = 'error';
                        title = 'Oops...';
                    }
                }
                await Swal.fire({
                    allowOutsideClick: false,
                    icon: icon,
                    title: title,
                    text: (data.isSuccess === false && (data.resultMessage === null || data.resultMessage === "")) ? 'Unable to process the request.' : data.resultMessage,
                }).then(async (result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            await this.handleResetStateData();
                            await this.getAddonsList();
                        }
                    }
                })
            }
        } catch (ex) {
            console.log(ex);
            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            })
        }
    }
    isConfirm = async (type, msg, title) => {
        let isResult = false;
        let confirmMessage = '';
        if (type === 'delete') {
            if (msg !== '') {
                confirmMessage = 'Are you sure want to delete this addon <b>' + msg + '</b>?';
            }
            else {
                confirmMessage = 'Are you sure want to delete this addon?';
            }
        }
        else if (type === 'status') {
            confirmMessage = msg;
        }
        else {
            confirmMessage = 'Are you sure want to ' + (type === 'add' ? 'Add' : 'Update') + ' this addon?';
        }
        await Swal.fire({
            title: title,
            html: '<small>' + confirmMessage + '</small>',
            icon: 'question',
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Yes, ' + (type === 'delete' ? 'Delete' : (type === 'add' ? 'Add' : 'Update')) + ' it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }
    render() {
        const renderGridCell = (data) => {
            let editLink = <a href="#" title="Edit" onClick={(e) => { this.handleEditAddons(e, data.data) }} ><i className="fas fa-pen"></i></a>
            let viewLink = <a href="#" title="View" style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleViewAddons(e, data.data) }} ><i className="fas fa-eye"></i></a>
            let inactiveLink = data.data.isActive ? <a href="#" title="Deactivate" style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleActivateDeactivateAddon(e, data.data, false) }} ><i className="fas fa-toggle-on"></i></a> : ''
            let activeLink = !data.data.isActive ? <a href="#" title="Activate" style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleActivateDeactivateAddon(e, data.data, true) }} ><i className="text-danger fas fa-toggle-off"></i></a> : ''
            return (
                <div style={{ textAlign: 'left', width: 'auto' }}>
                    {editLink}
                    {viewLink}
                    {inactiveLink === '' || inactiveLink}
                    {activeLink === '' || activeLink}
                    <a href="#" title="Delete" style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleDeleteAddon(e, data.data) }}><i className="fas fa-trash-alt"></i></a>
                    <a href="#" title="History" style={{ paddingLeft: '6px' }} onClick={(e) => { this.handleHistoryModal(e, data.data) }}><i className="fas fa-history"></i></a>
                </div>
            );
        }
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <h1 className="m-0 text-dark">Addons</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem>
                                        <NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="#" title="Refresh"
                                            onClick={this.handleOnRefresh}>
                                            {this.state.isRefreshSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isRefreshSubmited && <span title="Refresh"><i className="fas fa-sync-alt"></i> Refresh</span>}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem><NavLink tag={Link} to="#" className="btn btn-block btn-outline-secondary btn-sm" onClick={this.handleAddAddons} title="Add Addons"><i className="fas fa-plus"></i> Add Addons</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/franchisee/deductions" className="btn btn-block btn-outline-secondary btn-sm" title="Deduction"><i className="fas fa-minus-circle"></i> Deduction</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/franchisee/deposits" className="btn btn-block btn-outline-secondary btn-sm" title="Deposites"><i className="fas fa-hand-holding-usd"></i> Deposites</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/driver/documents" className="btn btn-block btn-outline-secondary btn-sm" title="Driver Documents"><i className="fas fa-file-alt"></i> Driver Documents</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="card card-grid">
                        <div className="card-body">
                            <DataGrid
                                dataSource={this.state.addonsGridDataSource}
                                keyExpr="id"
                                selection={{ mode: 'single' }}
                                columnsAutoWidth={false}
                                showColumnLines={true}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                onToolbarPreparing={this.handleToolbarPreparing}
                            >
                                <Column dataField="id" width={95} caption="" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                <Column dataField="name" caption="Addons" />
                                <Column dataField="defaultStatus" caption="Default" />
                                <Column dataField="activeStatus" caption="Status" />
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                <Export enabled={true} allowExportSelectedData={false} />
                                <ColumnChooser enabled={true} />
                                <ColumnFixing enabled={true} />
                            </DataGrid>
                        </div>
                    </div>
                </div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.addonModalIsOpen}
                    style={{ content: { width: '30%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleAssignDataToState}
                    onRequestClose={this.handleResetStateData}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleAddonSubmit} style={this.state.isSubmitted ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            {/*<h6 className="modal-title">{this.state.id > 0 ? 'Modify' + (this.state.isViewOnly ? '(ReadOnly)' : '') + ' Addon' : 'Add Addon'}</h6>*/}
                            <h6 className="modal-title">{this.state.id > 0 ? (this.state.isViewOnly ? 'View' : 'Modify') + ' Addon' : 'Add Addon'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleResetStateData}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group">
                                        <label>Addon Name <span class="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="addonName" title="Addon Name" ref={input => this.addonNameInputValidator = input} disabled={this.state.isViewOnly} value={this.state.name} onChange={(e) => { this.handleInputChange(e) }} placeholder="Enter addon name" />
                                        {this.state.errors["name"].length > 0 && <span className="error invalid-feedback">{this.state.errors["name"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="isDefault" name="isDefault" disabled={this.state.isViewOnly} checked={this.state.isDefault} onChange={(e) => { this.setState({ isDefault: e.target.checked }) }} />
                                            <label className="form-check-label" htmlFor="isDefault" style={{ fontSize: "inherit" }}> Default</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success btn-sm" disabled={this.state.isViewOnly}>
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In Process</span>}
                                {!this.state.isSubmitted && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleResetStateData} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <MasterHistoryComponent ref="masterHistoryComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        );
    }
}