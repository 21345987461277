import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
    Scrolling,
    RequiredRule,
    PatternRule,
    Editing,
   }
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component, Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Template } from 'devextreme-react/core/template';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { SelectBox } from 'devextreme-react/select-box';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import { LoadPanel } from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import { TextBox } from 'devextreme-react/text-box';
import { RatesHistoryComponent } from '../Shared/RatesHistoryComponent';

const position = { of: '#rateDiv' };
//const SerialEditorOptions = { maxLength: 000.00 };
let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));

var historyRawData = [];
export class AddEditKilometerRatesComponent extends React.Component {
    constructor(props) {
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        super(props);
      this.state = {
            isSubmited: false,
            isUpdateSubmited: false,
            isHistorySubmited: false,
            isMultipleZoneOpen: false,
            isSearched: false,
            wholeRawData: [],
            dataSource: [],
            isAdd: this.props.location.state == undefined ? true : this.props.location.state.isAdd,
            customerId: this.props.location.state == undefined ? 0 : this.props.location.state.customerId,
            customerName: this.props.location.state == undefined ? '' : this.props.location.state.customerName,
            masterRateId: this.props.location.state == undefined ? 0 : this.props.location.state.masterRateId,
            wefDate: currentTime,
            wefDateStr: '',
            dropdownZone: [],
            customerList: [],
            isReturnTrip: false,
            loadPanelVisible: false,
            sourceUrl: this.props.location.state == undefined ? "" : this.props.location.state.sourceUrl, // Send from calling paging (Search Reservation)
            sourceState: this.props.location.state == undefined ? "" : this.props.location.state.sourceState,  // Send from calling paging 
            dropdownWefDate: [],
            pickupDropdownZone: [],
            pickupZone: '',
            selectedPuRowKeys: [],
            selectedItemKeys:[],
            errors: {
                customerId: '',
                pickupZoneId: '',
                wefDate: '',
                wefDateStr: '',
            }
        }

        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.handleCustomerInputChange = this.handleCustomerInputChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleWefDateChange = this.handleWefDateChange.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.handleUpdateValidation = this.handleUpdateValidation.bind(this);

        this.getcustomerList = this.getcustomerList.bind(this);
        this.getZones = this.getZones.bind(this);
        this.getRideTypeId = this.getRideTypeId.bind(this);
        this.getZonalRatesDetails = this.getZonalRatesDetails.bind(this);

        this.handleSearchZonalRateSubmit = this.handleSearchZonalRateSubmit.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
        this.handleUpdateZonalRateSubmit = this.handleUpdateZonalRateSubmit.bind(this);
        this.updateZonalRates = this.updateZonalRates.bind(this);
        this.handleConfirmEvents = this.handleConfirmEvents.bind(this);
        this.goToBackPage = this.goToBackPage.bind(this);
        this.handleWEFDateSelectChange = this.handleWEFDateSelectChange.bind(this);
        this.getHistoryDescription = this.getHistoryDescription.bind(this);
        this.handleHistoryRateSubmit = this.handleHistoryRateSubmit.bind(this);

        this.handleSelectMultipleZoneSubmit = this.handleSelectMultipleZoneSubmit.bind(this);
        this.closeMultipleZoneModal = this.closeMultipleZoneModal.bind(this);
        this.onSelectionPuGridChanged = this.onSelectionPuGridChanged.bind(this);

    }
    async componentDidMount() {
        this.setState({
            loadPanelVisible: true,
        });
        this.setState({ wefDate: currentTime });
        await this.getcustomerList();
        await this.getZones();

        if (this.state.isAdd == false) {
         await this.getZonalRatesDetails();
        }


        this.setState({
            loadPanelVisible: false,
        });
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getZonalRatesDetails.bind(this)
            }
        });
        e.toolbarOptions.items.unshift({
            location: 'before',
            template: 'toolbarTemplate'
        },
        )
    }
    async getZones() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('serviceAreaZone-api/zone-dropdown/' + 0 + "/" + 0, requestParams);
            const data = await response.json();
            this.setState({
                dropdownZone: data.locationServiceAreaZoneList
            });
        } catch (e) {
            console.log(e);
        }
    }

    async getcustomerList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('account-api/customer-dropdown/', requestParams);
        const data = await response.json();
        this.setState({
            customerList: data.customers,
        });

    }

    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.customerId = "";
        errors.pickupZoneId = "";
        errors.dropoffZoneId = "";
        errors.wefDate = "";

        if (this.state.customerId == 0) {
            formIsValid = false;
            errors.customerId = "Please select customer.";
        }
       this.setState({ errors: errors });
        return formIsValid;
    }

    handleUpdateValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.customerId = "";
        errors.pickupZoneId = "";
        errors.dropoffZoneId = "";
        errors.wefDate = "";
        errors.wefDateStr = "";

        if (this.state.customerId == 0) {
            formIsValid = false;
            errors.customerId = "Please select customer.";
        }
    
        if (this.state.isAdd == true) {
            if (this.state.wefDate == null) {
                formIsValid = false;
                errors.wefDate = "Please select wef date.";
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    async getZonalRatesDetails() {
        if (this.handleValidation())
        {
            var request = new Object();
            request.CustomerId = this.state.customerId;
            request.MasterRateId = this.state.masterRateId;
            request.WefDate = "";
           try {
                this.setState({
                    isSubmited: true,
                    isSearched: true,
                });
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('kilometer-rate-api/details', requestParams);
                const data = await response.json();
               this.setState({
                    wholeRawData: data.kilometerRateDetails,
               });
                var wefDateArray = [];
                if (data.kilometerRateDetails.length > 0) {
                    if (data.kilometerRateDetails[0].rowIndex > 0) {
                        for (var i = 0; i < data.kilometerRateDetails.length; i++) {

                            if (wefDateArray.length > 0) {

                                var findex = wefDateArray.findIndex(f => f.id === data.kilometerRateDetails[i].wefDateStr);
                                if (findex == -1) {
                                    var dateObject = new Object();
                                    dateObject.id = data.kilometerRateDetails[i].wefDateStr
                                    dateObject.name = data.kilometerRateDetails[i].wefDateStr
                                    wefDateArray.push(dateObject);
                                }

                            } else {
                                var dateObject = new Object();
                                dateObject.id = data.kilometerRateDetails[i].wefDateStr
                                dateObject.name = data.kilometerRateDetails[i].wefDateStr
                                wefDateArray.push(dateObject);
                            }

                        }
                    }
                }
                if (wefDateArray.length > 0) {
                    var defaultSelectedWefDate = wefDateArray[0].name;

                    var filterDataSource = data.kilometerRateDetails.filter(f => f.wefDateStr === defaultSelectedWefDate);
                    this.setState({
                        dataSource: filterDataSource,
                        wefDateStr: defaultSelectedWefDate,
                        dropdownWefDate: wefDateArray,
                        isAdd: false
                    })

                } else {
                    this.setState({
                        dataSource: data.kilometerRateDetails,
                        isAdd: true
                    })
                }

                this.setState({
                    isSubmited: false,

                });
            } catch (e) {
                this.setState({ isSubmited: false })
            }
        }
    }

    handleCustomerInputChange = (data) => {
        this.setState({ customerId: (data.selectedItem != null ? data.selectedItem.id : 0), });
        this.setState({ customerName: (data.selectedItem != null ? data.selectedItem.name : '') })
    }

   
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleWEFDateSelectChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var defaultSelectedWefDate = value;
        var filterDataSource = this.state.wholeRawData.filter(f => f.wefDateStr === defaultSelectedWefDate);
        this.setState({
            dataSource: filterDataSource,
            wefDateStr: defaultSelectedWefDate,
        })

    }

    handleWefDateChange(date) {
        this.setState({ wefDate: date.value });
    }

    //getRideTypeId(fromZId, toZId) {

    //    var rideTypeId = 0;
    //    if (fromZId > 0 && toZId > 0) {
    //        let puZone = this.state.dropdownZone.find(opt => opt.id == fromZId);
    //        let doZone = this.state.dropdownZone.find(opt => opt.id == toZId);

    //        if (puZone.zoneTypeId === 1 && doZone.zoneTypeId === 3) {
    //            this.setState({ isMeetGreet: false });
    //            rideTypeId = 1;
    //        } else if (puZone.zoneTypeId === 3 && doZone.zoneTypeId === 1) {
    //            this.setState({ isMeetGreet: false });
    //            rideTypeId = 2;
    //        } else if (puZone.zoneTypeId === 1 && doZone.zoneTypeId === 1) {
    //            this.setState({ isMeetGreet: false });
    //            rideTypeId = 3;
    //        } else {
    //            this.setState({ isMeetGreet: false });
    //            rideTypeId = 13;
    //        }
    //    }

    //    this.setState({ rideTypeId: rideTypeId });

    //}

    getRideTypeId(fromZId) {
       var rideTypeId = 0;
        if (fromZId > 0) {
            let puZone = this.state.dropdownZone.find(opt => opt.id == fromZId);
           if (puZone.zoneTypeId === 1) {
                rideTypeId = 1;
            } else {
                rideTypeId = 13;
            }
        }

        this.setState({ rideTypeId: rideTypeId });

    }

    async handleSearchZonalRateSubmit(e) {
        e.preventDefault();

        await this.getZonalRatesDetails();
    }
    handleClearSearch() {
        this.setState({
            isSubmited: false,
            isUpdateSubmited: false,
            isSearched: false,
            isMultipleZoneOpen: false,
            wholeRawData: [],
            dataSource: [],
           
            isAdd: this.props.location.state == undefined ? true : this.props.location.state.isAdd,
            customerId: this.props.location.state == undefined ? 0 : this.props.location.state.customerId,
            rideTypeId: this.props.location.state == undefined ? 0 : this.props.location.state.rideTypeId,
            fromAirportId: this.props.location.state == undefined ? 0 : this.props.location.state.fromAirportId,
            fromZoneId: this.props.location.state == undefined ? 0 : this.props.location.state.fromZoneId,

            vehicleTypeId: this.props.location.state == undefined ? 0 : this.props.location.state.vehicleTypeId,
            isAirportTransfer: this.props.location.state == undefined ? 0 : this.props.location.state.isAirportTransfer,
            isZonalTransfer: this.props.location.state == undefined ? 0 : this.props.location.state.isZonalTransfer,

            wefDate: currentTime,
            wefDateStr: '',

            pickupZoneId: 0,
            dropoffZoneId: 0,

            isReturnTrip: false,
            loadPanelVisible: false,

            sourceUrl: this.props.location.state == undefined ? "" : this.props.location.state.sourceUrl, // Send from calling paging (Search Reservation)
            sourceState: this.props.location.state == undefined ? "" : this.props.location.state.sourceState,  // Send from calling paging 

            dropdownWefDate: [],
            pickupDropdownZone: [],
            pickupZone: '',
            selectedPuRowKeys: [],
            errors: {
                customerId: '',
                pickupZoneId: '',
                wefDate: '',
                wefDateStr: '',
            }
        });
    }



    handleUpdateZonalRateSubmit(e) {
       e.preventDefault();
      //   this.refs.dataGridRef.instance.saveEditData();
        //this.refs.dataGridRef.instance.refresh();
         this.updateZonalRates([], []);

      //  let myVar = setTimeout(() => this.handleConfirmEvents(), 200)

    }

    goToBackPage() {
        this.props.history.push({
            pathname: this.state.sourceUrl,
            state: this.state.sourceState
        })
    }



    async askReturnTripConfirm(fromZone) {
        let isResult = false;
        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: "You are going enter rate of [" + fromZone + "] for return trip!<br> Do you want to still continue?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    async askSaveRateConfirm(fromZone) {
        let isResult = false;
        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: "You are going enter rate of [" + fromZone + "] !<br> Do you want to still continue?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    async askSaveMultipleRateConfirm() {
        let isResult = false;
        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: "You are going enter rate of [multiple zone] !<br> Do you want to still continue?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    async askNewRateConfirm() {
        let isResult = false;
        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: "You have successfully updated rates!<br> Do you want add other rates?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    async askMultipleZoneConfirm() {
        let isResult = "";
        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: "Do you want to apply rates for multiple zone?",
            icon: 'question',
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = "yes";
            } else if (result.isDenied) {
                isResult = "no";
            }
        })
        return isResult;
    }

    async askUpdateFutureRateConfirm() {
        let isResult = false;
        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: "You want to update future rides?<br> Press Yes to apply?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    async askWefLessThanCurrentConfirm() {
        
        let isResult = false;
        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: "Given WEF date is less than current date!<br> Do you want to still continue?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue',
            allowOutsideClick: false
        }).then((result) => {
            
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    async askApdateUnapprovedRideConfirm() {
        let isResult = "";
      
        var msg = "You are entering rate for previous date.<br>";
        
        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: msg,
            icon: 'question',
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            denyButtonText: 'No',

            allowOutsideClick: false
        }).then((result) => {
    
            if (result.isConfirmed) {
                isResult = "yes";
            } else if (result.isDenied) {
                isResult = "no";
            }
        })
        return isResult;
    }


    async handleConfirmEvents() {
        if (this.handleUpdateValidation()) {
            if (this.state.isAdd == true && this.state.isReturnTrip == false) {
                //const isConfirm = await this.askMultipleZoneConfirm();

                await this.updateZonalRates([], []);
                //if (isConfirm === "yes") {
                //    await this.updateZonalRates([], []);
                //   // this.openMultipleZone();
                //} else if (isConfirm === "no") {
                   
                //} else {
                //    return;
                //}

            } else {
                await this.updateZonalRates([], []);
            }
        }
    }
    onSelectionPuGridChanged({ selectedRowKeys }) {
        this.setState({
            selectedPuRowKeys: selectedRowKeys
        });
    }

    async handleSelectMultipleZoneSubmit(e) {
        e.preventDefault();
        const selectedPuRowsData = await this.dataPuGrid.instance.getDataByKeys(this.state.selectedPuRowKeys);

        if (selectedPuRowsData.length <= 0) {
            const isValid = await Swal.fire({
                title: "<small>Pickup Zone</small>",
                text: "Please select atleast one pickup zone.",
                icon: "info",
                dangerMode: false,
                width: "25%",
                dangerMode: false,
                allowOutsideClick: false
            });
            return;
        }

        this.closeMultipleZoneModal();

        await this.updateZonalRates(selectedPuRowsData);

    }
    closeMultipleZoneModal() {
        this.setState({
            isMultipleZoneOpen: false,
            pickupDropdownZone: [],
            pickupZone: '',
            selectedPuRowKeys: [],
        })
    }

    isGuid = (stringToTest) => {
     if (stringToTest[0] === "{") {
            stringToTest = stringToTest.substring(1, stringToTest.length - 1);
        }
        var regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
        return regexGuid.test(stringToTest);
    }

    async updateZonalRates(selectedPuRowsData) {
       // debugger;
        const dataSource = this.refs.dataGridRef.instance.getDataSource();
        var dataArray = dataSource._items;
        if (dataArray.length <= 0) {
            const isValid = await Swal.fire({
                title: "<small>Kilometer Rate</small>",
                text: "Kilometer rates not found.",
                icon: "info",
                dangerMode: false,
                width: "25%",
                dangerMode: false,
                allowOutsideClick: false
            });
            return;
        }
        var isUpdateApprovedRides = false;
        var isUpdateFutureRides = false;
        //let wef = formatDate(this.state.wefDate, "MM/dd/yyyy");
        //let current = formatDate(currentTime, "MM/dd/yyyy");
        
        if (this.state.isAdd == true) {
            if (new Date(this.state.wefDate) < new Date(currentTime) ) { //
                const isConfirm = await this.askWefLessThanCurrentConfirm();
                if (!isConfirm) {
                    return;
                } else {
                    const isConfirmUnapprove = await this.askApdateUnapprovedRideConfirm();
                    if (isConfirmUnapprove === "yes") {
                        isUpdateApprovedRides = true;
                    } else if (isConfirmUnapprove === "no") {
                        return;
                    } else {
                        return;
                    }
                }
            }
        }

         if (selectedPuRowsData.length > 0) {
          var request = new Object();
            request.CustomerId = parseInt(this.state.customerId);
            request.MasterRateId = this.state.masterRateId;
            request.IsReturnTrip = this.state.isAdd == true ? this.state.isReturnTrip : false;
            request.WefDate = this.state.isAdd == true ? this.state.wefDate.toLocaleDateString() : this.state.wefDateStr;
            request.Description = this.state.isAdd == true ? "New Rate Added for WEF date [" + this.state.wefDate.toDateString() + "]" : await this.getHistoryDescription(dataArray);
            request.kilometerRateGroups = [];
           for (var i = 0; i < dataArray.length; i++) {
                    var rateZonalGroup = new Object();
                   rateZonalGroup.RowIndex = this.isGuid(dataArray[i].rowIndex) === true ? 0 : dataArray[i].rowIndex;
                    rateZonalGroup.FromKm = parseInt(dataArray[i].fromKm);
                    rateZonalGroup.ToKm = parseInt(dataArray[i].toKm);
                    rateZonalGroup.Rate = parseInt(dataArray[i].rate);
                    rateZonalGroup.Enable = true;
                  
                    request.kilometerRateGroups.push(rateZonalGroup);
               }
         } else {
             debugger;
             var editRateHistory = await this.getHistoryDescription(dataArray);
             var startwefDate = this.state.isAdd == true ? this.state.wefDate.toLocaleDateString() : this.state.wefDateStr;
             var foDate = formatDate(startwefDate, "MM/dd/yyyy") + " " + "00:00:00";
             var toDate = formatDate(startwefDate, "MM/dd/yyyy") + " " + "23:59:59"
             var request = new Object();
             request.CustomerId = parseInt(this.state.customerId);
             request.WefDate = foDate;
             request.WefDateTime = toDate;
             request.kilometerRateGroups = [];
             var newRateHistory = "";
             for (var i = 0; i < dataArray.length; i++) {
                 var rateZonalGroup = new Object();
                 rateZonalGroup.RowIndex = this.isGuid(dataArray[i].rowIndex) === true ? 0 : dataArray[i].rowIndex;
                 rateZonalGroup.FromKm = parseInt(dataArray[i].fromKm);
                 rateZonalGroup.ToKm = parseInt(dataArray[i].toKm);
                 rateZonalGroup.Rate = parseInt(dataArray[i].rate);
                 rateZonalGroup.Enable = true;
                 request.kilometerRateGroups.push(rateZonalGroup);
                 newRateHistory += rateZonalGroup.RowIndex === 0 ? " New rate [" + parseInt(dataArray[i].rate) + "] added for [ " + parseInt(dataArray[i].fromKm) + " km(s) - " + parseInt(dataArray[i].toKm) + " km(s)]" : "";
             }
             request.Description = newRateHistory + " " + editRateHistory;
        }
            try {
                this.setState({
                    isUpdateSubmited: true,
                });
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('kilometer-rate-api/save', requestParams);
                const data = await response.json();

                Swal.fire({
                    icon: data.isSuccess ? 'success' : 'error',
                    title: data.isSuccess ? 'Success' : 'Oops...',
                    text: data.resultMessage,
                    confirmButtonText: 'Thank you!',
                    allowOutsideClick: false
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            this.setState({
                                isUpdateSubmited: false,
                            });
                            if (this.props.location.state == undefined) {
                                const isConfirm = this.askNewRateConfirm();
                                if (!isConfirm) {
                                    this.goToBackPage();
                                } else {
                                    this.handleClearSearch();
                                }

                            } else {
                                this.goToBackPage(); // method defined in BookingComponent
                            }
                        }
                    } else {
                        this.setState({
                            isUpdateSubmited: false,
                        });
                    }
                })

            }
         catch (e) {
            this.setState({ isUpdateSubmited: false })
        }
    }

    async getHistoryDescription(dataArray) {
        var dis2 = "";
        var request = new Object();
        request.MasterRateId = this.state.masterRateId;
        request.CustomerId = this.state.customerId;
        request.WefDate = "";
        const requestParams = getRequestParams('POST', request);
        const response = await fetch('kilometer-rate-api/details', requestParams);
        const data = await response.json();
        var filterDataSource = data.kilometerRateDetails.filter(f => f.wefDateStr === this.state.wefDateStr);
        let sum = 0;
        for (var j = 0; j < filterDataSource.length; j++) {
            for (var i = 0; i < dataArray.length; i++) {
                var _rowIndex = dataArray[i].rowIndex;

                if (filterDataSource[j].rowIndex == _rowIndex) {
                    var hDescription = "(";

                    var isChanges = false;

                    if (parseFloat(filterDataSource[j].fromKm) != parseFloat(dataArray[i].fromKm)) {
                        isChanges = true;
                        hDescription = hDescription + "From kms changed from " + filterDataSource[j].fromKm + " to " + (dataArray[i].fromKm) + " , ";
                    }
                    if (parseFloat(filterDataSource[j].toKm) != parseFloat(dataArray[i].toKm)) {
                        isChanges = true;
                        hDescription = hDescription + "To kms changed from " + filterDataSource[j].toKm + " to " + (dataArray[i].toKm) + " , ";
                    }
                    if (parseFloat(filterDataSource[j].rate) != parseFloat(dataArray[i].rate)) {
                        isChanges = true;
                        hDescription = hDescription + "Rate changed from " + filterDataSource[j].rate + " to " + (dataArray[i].rate) + " for [" + filterDataSource[j].fromKm + " - " + filterDataSource[j].toKm + "] , ";
                    }
                    if (isChanges == true) {
                        dis2 = dis2 + hDescription + " of " + dataArray[i].accountName + "), ";
                    }
                }
            }
        }
        return dis2;
    }

    handleHistoryRateSubmit(e) {
        e.preventDefault();
        this.setState({ isHistorySubmited: true });
        this.refs.ratesHistoryComponent.showModal("Kilometer Rate History", this.state.customerId, 0, 0);
        this.setState({ isHistorySubmited: false });
    }
    toolbarItemRender = ()=> {
        const modalTitle = this.state.dataSource.length > 0 ? "Update" : "Add"
        return (
            <Fragment>
               <b> Customer Name</b>:  <label style={{ color: 'red' }}>{this.state.customerName}</label>&nbsp;&nbsp;
                <button type="submit" className="btn btn-success btn-sm" onClick={this.handleUpdateZonalRateSubmit} >
                    {this.state.isUpdateSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                    {!this.state.isUpdateSubmited && <span title="Update"><i className="fas fa-check"></i> {modalTitle}</span>}
                </button>
            </Fragment>
        );
    }

    onRowRemoved = async (e) => {
        var deletedDescription = "Deleted rate  From  [ " + e.data.fromKm + " km(s) - " + " to" + e.data.toKm + " km(s)] ";
        var request = new Object();
        request.Description = deletedDescription;
        request.CustomerId = e.data.customerId;
        const requestParams = getRequestParams('POST', request);
        const response = await fetch('kilometer-rate-api/delete/' + e.data.rowIndex, requestParams);
        const data = await response.json();
        Swal.fire({
            icon: data.isSuccess ? 'success' : 'error',
            title: data.isSuccess ? 'Success' : 'Oops...',
            text: data.resultMessage,
            confirmButtonText: 'Thank you!',
            allowOutsideClick: false
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (data.isSuccess) {
                if (result.isConfirmed) {
                    this.getZonalRatesDetails();

                }
            }
            });
    }

    onRowInserting = async (e) => {
      //  debugger;
        this.setState({ isAdd: true });
    }
    render() {
        const modalTitle = this.state.dataSource.length > 0? "Update" : "Add"
        const rateTitle = this.state.isAdd == false ? "Edit Kilometer Rate" : "Add Kilometer Rate"
        const renderGridCurrencyCell = (data) => {
            if (data.value !== undefined) {
                return <div>{parseFloat(data.value).toFixed(2)}</div>;
            }
            else {
                return <div></div>
            }
        }
        

        return (
            <div className="content-wrapper" id="rateDiv">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-sm-6 col-md-6 col-lg-6">
                                <h1 className="m-0 text-dark">{rateTitle}</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem>
                                        <NavLink tag={Link} to="#" className="btn btn-block btn-outline-secondary btn-sm" onClick={(e) => { this.goToBackPage() }} title="Back" ><i className="fas fa-arrow-left"></i> Back</NavLink>
                                    </NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <form method="post" onSubmit={this.handleSearchZonalRateSubmit} style={(this.state.isSubmited || this.state.isUpdateSubmited) ? { pointerEvents: "none", opacity: "0.4" } : {}}>

                    <div className="rez-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>Customer <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.customerList.length > 0 ? this.state.customerList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="customerId"
                                            defaultValue={0}
                                            value={this.state.customerId}
                                            displayExpr='fullName'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleCustomerInputChange}
                                            showClearButton={true}
                                            disabled={this.state.isSearched == true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["customerId"]}</span>}
                                    </div>
                                </div>
                            
                                {<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2" style={{ display: this.state.isAdd == true ? 'block' : 'none' }}>
                                    <div className="form-group" >
                                        <label>WEF Date <span className="text-danger">*</span></label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="wefDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.wefDate}
                                            value={this.state.wefDate}
                                            onValueChanged={this.handleWefDateChange}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["wefDate"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["wefDate"]}</span>}
                                    </div>
                                </div>}
                                {(this.state.dataSource.length > 0) && <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2" style={{ display: this.state.isAdd == false ? 'block' : 'none' }}>
                                    <div className="form-group">
                                        <label>WEF Date <span className="text-danger">*</span></label>
                                        <select name="wefDateStr" className="form-control form-control-sm" value={this.state.wefDateStr} onChange={this.handleWEFDateSelectChange}>
                                            {
                                                this.state.dropdownWefDate &&
                                                this.state.dropdownWefDate.map((item, i) =>
                                                    (<option key={i} value={item.id}>{item.name}</option>))
                                            }
                                        </select>
                                        {this.state.errors["wefDateStr"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["wefDate"]}</span>}
                                    </div>
                                </div>}

                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <br />
                                        <span className="form-check mr-2" style={{ display: this.state.dropdownWefDate.length > 0 ? 'inline-block' : 'none' }} >
                                            <input className="form-check-input" type="checkbox" name="isAdd" checked={this.state.isAdd} onChange={this.handleInputChange} />
                                            <label className="form-check-label">Add New Rate</label>
                                        </span>

                                    </div>

                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-right">
                                    <div className="form-group">
                                        <label className="btn-block">&nbsp;</label>
                                        <button type="submit" className="btn btn-success btn-sm" style={{ display: this.state.dataSource.length > 0 ? 'none' : 'inline-block' }} onClick={this.handleSearchZonalRateSubmit} >
                                            {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSubmited && <span title="Search"><i className="fas fa-search"></i> Search</span>}
                                        </button>&nbsp; &nbsp;
                                        {/*<button type="submit" className="btn btn-success btn-sm"  onClick={this.handleUpdateZonalRateSubmit} >*/}
                                        {/*    {this.state.isUpdateSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}*/}
                                        {/*    {!this.state.isUpdateSubmited && <span title="Update"><i className="fas fa-check"></i> {modalTitle}</span>}*/}
                                        {/*</button>&nbsp; &nbsp;*/}
                                        <button type="submit" className="btn btn-success btn-sm" style={{ display: this.state.dataSource.length == 0 ? 'none' : 'inline-block' }} onClick={this.handleHistoryRateSubmit} >
                                            {this.state.isHistorySubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isHistorySubmited && <span title="History"><i className="fas fa-history"></i> History</span>}
                                        </button>&nbsp; &nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleClearSearch} title="Clear"><i className="fas fa-eraser"></i> Clear</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content">
                        <div className="card card-grid">
                            <div className="card-body">
                               <DataGrid
                                    ref="dataGridRef"
                                    dataSource={this.state.dataSource}
                                    keyExpr="rowIndex"
                                    selection={{ mode: 'single' }}
                                    columnsAutoWidth={false}
                                    showColumnLines={true}
                                    showRowLines={true}
                                    showBorders={true}
                                    rowAlternationEnabled={true}
                                    showBorders={true}
                                    allowColumnResizing={true}
                                    columnResizingMode='widget'
                                    onRowInserting={this.onRowInserting}
                                    repaintChangesOnly={true}
                                    onRowRemoving={this.onRowRemoved}
                                    onToolbarPreparing={this.onToolbarPreparing}>
                                    <Editing
                                        mode="cell"
                                        allowAdding={true}
                                        allowUpdating={true}
                                        allowDeleting={true}
                                        editorOptions={true} >
                                    </Editing>
                                    <Column dataField="fromKm" width={100} caption="From Km" width={500} allowFiltering={true} allowSorting={true} sortOrder='asc' />
                                    <Column dataField="toKm" width={100} caption="To Km" width={550} allowFiltering={true} allowSorting={true} />
                                    <Column dataField="rate" width={100} caption="Rate($)" width={550} allowFiltering={true} allowSorting={true} format="decimal" cellRender={renderGridCurrencyCell}> <RequiredRule />  <PatternRule
                                        message={'From Km Should Be 00.00'}
                                        pattern={/^[0-9]{1,4}(?:\.[0-9]{1,3})?$/}
                                    /> </Column>
                                    <SortByGroupSummaryInfo summaryItem="count" />
                                    <GroupPanel visible={false} />
                                    <HeaderFilter visible={false} />
                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                    <Grouping autoExpandAll={true} />
                                    <Template name="toolbarTemplate" render={this.toolbarItemRender} />
                                    <Export enabled={true} allowExportSelectedData={false} />
                                     <ColumnFixing enabled={true} />
                                    <Scrolling mode="infinite" />
                                </DataGrid>
                            </div>
                        </div>

                    </div>

                </form>
                {/*<Modal ariaHideApp={false}*/}
                {/*    isOpen={this.state.isMultipleZoneOpen}*/}
                {/*    className={"react-modal edit-service-modal"}*/}
                {/*    shouldCloseOnOverlayClick={false}*/}
                {/*    contentLabel="Example Modal"*/}
                {/*>*/}
                {/*    <form method="post" onSubmit={this.handleSelectMultipleZoneSubmit} >*/}
                {/*        <div className="modal-header">*/}
                {/*            <h6 className="modal-title">Multiple Zone</h6>*/}
                {/*            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeMultipleZoneModal}>*/}
                {/*                <span aria-hidden="true">×</span>*/}
                {/*            </button>*/}
                {/*        </div>*/}

                {/*        <div className="modal-body">*/}
                {/*            <div className="row">*/}
                {/*                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">*/}
                {/*                    <small>Pickup/Dropoff zone is <i><strong className="text-danger">{this.state.pickupZone}</strong></i>.</small>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className="widget-container" style={{ maxHeight: 350 + 'px' }}>*/}
                {/*                <div className="row">*/}
                {/*                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">*/}
                {/*                        <div>*/}
                {/*                            <small>Apply same rates for other pickup/dropoff zone:</small>*/}
                {/*                        </div>*/}
                {/*                        <DataGrid*/}
                {/*                            dataSource={this.state.pickupDropdownZone}*/}
                {/*                            id="grid-container"*/}
                {/*                            keyExpr="id"*/}
                {/*                            showBorders={true}*/}
                {/*                            selectedRowKeys={this.state.selectedPuRowKeys}*/}
                {/*                            onSelectionChanged={this.onSelectionPuGridChanged}*/}
                {/*                            ref={ref => this.dataPuGrid = ref}>*/}
                {/*                            <Selection*/}
                {/*                                mode="multiple"*/}
                {/*                                selectAllMode={"allPages"}*/}
                {/*                                showCheckBoxesMode={"always"}*/}
                {/*                            />*/}
                {/*                            <Column dataField="name" caption="Zone Name" />*/}
                {/*                            <Scrolling mode="infinite" />*/}

                {/*                        </DataGrid>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="modal-footer">*/}
                {/*            <button type="submit" className="btn btn-success btn-sm" onClick={this.handleSelectMultipleZoneSubmit} ><span><i className="fas fa-save"></i> Continue</span> </button>*/}

                {/*            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeMultipleZoneModal}><i className="fas fa-times"></i> Close</button>*/}
                {/*        </div>*/}
                {/*    </form>*/}
                {/*</Modal>*/}
                <RatesHistoryComponent ref="ratesHistoryComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >)
    }
}