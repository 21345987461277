import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
    Selection
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Modal from 'react-modal'; //npm install --save react-modal
import DateBox from 'devextreme-react/date-box';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { SelectBox } from 'devextreme-react/select-box';  //npm install --save react-select
import { getRequestParams, formatDate } from '../../helpers/globalfunctions';
import { StatusType, DateFormat } from '../../helpers/fixcodes';
import { LoadPanel } from 'devextreme-react/load-panel';
import { ReservationHistoryComponent } from '../Shared/ReservationHistoryComponent';
import { InvoiceRidesComponent } from '../Shared/InvoiceRidesComponent';
import { DocumentsComponent } from '../Shared/DocumentsComponent';
import { InvoicePaymentComponent } from '../Shared/InvoicePaymentComponent';
import { InvoiceEmailComponent } from '../Shared/InvoiceEmailComponent';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment

let currencyIcon = sessionStorage.getItem("session_currencyIcon") === "" ? "fas fa-dollar-sign" : sessionStorage.getItem("session_currencyIcon");

const position = { of: '#historydiv' };
const customStyles = {
    content: {
        width: '30%',
    }
};
const invoiceStatus = [
    {
        "id": 0,
        "name": "All",
    },
    {
        "id": 1,
        "name": "Closed",
    },
    {
        "id": 2,
        "name": "Opened",
    },

]

let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
const session_accountId = parseInt(sessionStorage.getItem("session_accountId"));
const session_mappedAccountId1 = parseInt(sessionStorage.getItem("session_mappedAccountId1"));
const session_mappedAccountId2 = parseInt(sessionStorage.getItem("session_mappedAccountId2"));

export class ApprovedInvoiceComponent extends React.Component {
    constructor(props) {
        super(props);
        const queryString = require('query-string');
        var parsed = queryString.parse(this.props.location.search);
        const newreceiptNo = parsed.receiptNo;
        const transaction = parsed.transaction;

        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        this.state = {
            newreceiptNo: newreceiptNo,
            transactionId: transaction,
            isSubmited: false,
            isDraftSubmited: false,
            invoiceNumber: 0,
            fromDateTime: new Date(currentTime.toLocaleDateString() + " 00:00"),
            toDateTime: new Date(currentTime.toLocaleDateString() + " 23:59"),
            customerList: [],
            customerId: 0,
            customerTypeList: [],
            customerTypeId: 0,
            draftInvoices: [],
            selectedRowKeys: [],
            invoiceStatusId: 0,
            loadPanelVisible: false,
            akasaInvoice: (session_mappedAccountId1 !== session_accountId && session_mappedAccountId2 !== session_accountId) ? false : true,
        }
        this.getcustomerList = this.getcustomerList.bind(this);
        this.getCustomerTypeList = this.getCustomerTypeList.bind(this);

        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.handleFromValueChanged = this.handleFromValueChanged.bind(this);
        this.handleToValueChanged = this.handleToValueChanged.bind(this);

        this.getApprovedInvoices = this.getApprovedInvoices.bind(this);

        this.handleSearchUnapproveRidesSubmit = this.handleSearchUnapproveRidesSubmit.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
        this.handleApproveInvoice = this.handleApproveInvoice.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.openInvoiceHistoryModel = this.openInvoiceHistoryModel.bind(this);
        this.openInvoiceTripModel = this.openInvoiceTripModel.bind(this);
        this.openInvoiceEmailModel = this.openInvoiceEmailModel.bind(this);
        this.openInvoiceDocumentsModel = this.openInvoiceDocumentsModel.bind(this);
        this.openPaymentModel = this.openPaymentModel.bind(this);
        this.viewInvoiceReport = this.viewInvoiceReport.bind(this);
        this.ungenerateInvoice = this.ungenerateInvoice.bind(this);

        if (this.state.transactionId != null) {
            let strAlert = "";
            strAlert = "Your invoice payment has been done. Receipt Number is " + this.state.newreceiptNo + ".<br><br>";
            strAlert += "<br> Thank you for payment.";

            Swal.fire({
                icon: 'success',
                title: 'success',
                html: strAlert,
                width: "25%",
                allowOutsideClick: false
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                var isAttach = sessionStorage.getItem("session_invoice_receipt_attach")
                if (isAttach !== 'false') {
                    var rideArray = [];
                    rideArray.push(parseInt(this.state.newreceiptNo));
                    this.refs.documentsComponent.showModal('Invoice Receipt', rideArray, rideArray[0]);
                } else {

                    if (result.isConfirmed) {
                        window.history.pushState({}, null, '/billing/approved');
                    }
                }
            })
        }
    }
    async componentDidMount() {
        this.setState({
            loadPanelVisible: true,
        });
        await this.getcustomerList();
        await this.getCustomerTypeList();

        if (this.props.location.state != undefined) {

            this.setState({
                customerId: this.props.location.state.customerId,
                fromDateTime: this.props.location.state.fromDateTime,
                toDateTime: this.props.location.state.toDateTime,
                customerTypeId: this.props.location.state.customerTypeId,
                invoiceNumber: this.props.location.state.invoiceNumber,
            });

            let myVar = setTimeout(() => this.getApprovedInvoices(), 200)
        }
        this.setState({
            loadPanelVisible: false,
        });
    }
    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getApprovedInvoices.bind(this)
            }
        });
    }
    async getApprovedInvoices() {

        var request = new Object();

        request.CustomerTypeId = parseInt(this.state.customerTypeId);
        request.IsInvoieApproved = true;
        request.InvoiceFromDate = this.state.fromDateTime == null ? "" : this.state.fromDateTime.toLocaleDateString() + " 00:00:00"; //new Date(2020, 9, 1);
        request.InvoiceToDate = this.state.toDateTime == null ? "" : this.state.toDateTime.toLocaleDateString() + " 23:59:59"; //new Date(2020, 9, 31);
        request.InvoiceNo = this.state.invoiceNumber !== "" ? parseInt(this.state.invoiceNumber) : 0;
        request.CustomerId = this.state.selectionId == 3 ? parseInt(this.state.customerId) : 0;
        request.InvoiceStatus = parseInt(this.state.invoiceStatusId);

        try {
            this.setState({
                isSubmited: true,
            });
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('invoice-api/list', requestParams);
            const data = await response.json();
            this.setState({
                draftInvoices: data.invoiceList
            });
            this.setState({ isSubmited: false });

        } catch (e) {
            this.setState({ isSubmited: false });
        }

    }
    async getcustomerList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('account-api/customer-dropdown/', requestParams);
        const data = await response.json();
        this.setState({
            customerList: data.customers,
        });
    }

    async getCustomerTypeList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('customer-type-api/', requestParams);
        const data = await response.json();
        if (data !== null && data.customerType.length > 0) {
            this.setState({
                customerTypeList: data.customerType
            });
        }
    }

    handleCustomerInputChange = (data) => {
        this.setState({ customerId: (data.selectedItem != null ? data.selectedItem.id : 0), })
    }

    handleFromValueChanged(date) {
        this.setState({ fromDateTime: date.value });
    }
    handleToValueChanged(date) {
        this.setState({ toDateTime: date.value });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === "invoiceNumber") {
            const re = /^[0-9\b]+$/;
            if (event.target.value === '' || re.test(event.target.value)) {
                this.setState({ [name]: event.target.value })
            }
        } else {
            this.setState({
                [name]: value
            });
        }

    }


    async handleSearchUnapproveRidesSubmit(e) {
        e.preventDefault();

        await this.getApprovedInvoices();
    }
    handleClearSearch() {
        this.setState({
            isSubmited: false,
            isDraftSubmited: false,
            invoiceNumber: 0,
            fromDateTime: new Date(currentTime.toLocaleDateString() + " 00:00"),
            toDateTime: new Date(currentTime.toLocaleDateString() + " 23:59"),
            customerId: 0,
            customerTypeId: 0,
            draftInvoices: [],
            invoiceStatusId: 0,
        });
    }

    onSelectionChanged({ selectedRowKeys }) {
        this.setState({
            selectedRowKeys: selectedRowKeys
        });
    }

    async askApproveUnapproveConfirm() {
        let isResult = false;
        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: "You are going approve selected rides!<br> Do you want to still continue?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }



    async handleApproveInvoice(e) {
        e.preventDefault();
        const selectedRowsData = await this.dataGrid.instance.getDataByKeys(this.state.selectedRowKeys);

        if (selectedRowsData.length <= 0) {
            const isValid = await Swal.fire({
                title: "<small>Pending Approval</small>",
                text: "Please select atleast one ride.",
                icon: "info",
                dangerMode: false,
                width: "25%",
                dangerMode: false,
                allowOutsideClick: false
            });
            return;
        }

        const isConfirm = await this.askApproveUnapproveConfirm();
        if (!isConfirm) {
            return;
        }


        var selectedObj = "";
        for (let i = 0; i < selectedRowsData.length; i++) {
            selectedObj = selectedObj + selectedRowsData[i].invoiceNo + ",";
        }

        try {
            this.setState({ isDraftSubmited: true });
            var request = new Object();
            request.InvoiceNoStr = selectedObj.replace(/,\s*$/, "");
            request.IsInvoieApproved = false;

            const requestParams = getRequestParams('POST', request);
            const response = await fetch('invoice-api/approve-unapprove', requestParams);
            const data = await response.json();
            Swal.fire({
                icon: data.isSuccess ? 'success' : 'error',
                title: data.isSuccess ? 'Success' : 'Oops...',
                text: data.resultMessage,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                this.setState({ isDraftSubmited: false })
                if (result.isConfirmed) {
                    if (data.isSuccess) {
                        this.getApprovedInvoices();
                    }
                }
            })

        } catch (e) {
            this.setState({ isDraftSubmited: false });
        }
    }

    async askUngenerateInvoiceConfirm() {
        let isResult = false;
        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: "You are going ungenerate invoice!<br> Do you want to still continue?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    async ungenerateInvoice(e, dataValue) {

        e.preventDefault();
        const isConfirm = await this.askUngenerateInvoiceConfirm();
        if (!isConfirm) {
            return;
        }

        try {
            this.setState({ loadPanelVisible: true });
            var request = new Object();
            request.InvoiceNoStr = dataValue.invoiceNo.toString();

            const requestParams = getRequestParams('POST', request);
            const response = await fetch('generate-invoice-api/remove', requestParams);
            const data = await response.json();
            this.setState({ loadPanelVisible: false })
            Swal.fire({
                icon: data.isSuccess ? 'success' : 'error',
                title: data.isSuccess ? 'Success' : 'Oops...',
                text: data.resultMessage,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    if (data.isSuccess) {
                        this.getApprovedInvoices();
                    }
                }
            })

        } catch (e) {
            this.setState({ loadPanelVisible: false });
        }
    }

    openInvoiceHistoryModel(e, data) {
        e.preventDefault();
        this.refs.reservationHistoryModal.showModal("Billing", data.invoiceNo);
    }
    openInvoiceDocumentsModel(e, data) {
        e.preventDefault();
        var rideArray = [];
        rideArray.push(data.invoiceNo);
        this.refs.documentsComponent.showModal('Invoice No', rideArray, rideArray[0]);
    }

    openInvoiceTripModel(e, data) {
        e.preventDefault();
        this.refs.invoiceRidesModal.showModal(0, "", "", false, data.invoiceNo);
    }
    async openInvoiceEmailModel(e, data) {
        e.preventDefault();
        try {
            this.setState({ loadPanelVisible: true });
            var invoiceNos = [];            
            invoiceNos.push(parseInt(data.invoiceNo))
            var reportName = this.state.akasaInvoice ? "GetInvoiceAkasaReport" : "GetGtCustomerSingleInvoice";
            var request = {};
            request.InvoiceNo = Number(data.invoiceNo);
            request.InvoiceNos = invoiceNos;
            request.fileFormat = 'pdf';
            request.MethodName = (data.viewInvocieOdometerReport ? "GetInvoiceOdometerDetails" : reportName);;
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            let fileData = dataResponse.reportBase64String;
            if (fileData !== null && fileData !== '') {
                //currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
                //let emailSubject = 'Data Entry Audit Report - ' + moment(currentTime).format("DD MMMM YYYY HH:mm");
                let emailSubject = `INV ${data.invoiceNo} ${sessionStorage.getItem("session_accountname")}`;
                let attachments = [];
                let file = {};
                file.fileData = fileData;
                file.fileName = `INV${data.invoiceNo}${sessionStorage.getItem("session_accountname")}.pdf`;
                file.fileExtension = '.pdf';
                attachments.push(file);
                this.refs.invoiceEmailComponent.showModal(data.invoiceNo, data.customerId, '', '', emailSubject, '', attachments);
            }
            else {
                Swal.fire({
                    allowOutsideClick: false,
                    showCloseButton: true,
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong! Unable to process the email request.',
                })
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({loadPanelVisible: false});
    }

    async viewInvoiceReport(e, data, fileFormat) {        
        e.preventDefault();
        try {            
            this.setState({ loadPanelVisible: true });
            var invoiceNos = [];            
            invoiceNos.push(parseInt(data.invoiceNo))
            var reportName = this.state.akasaInvoice ? "GetInvoiceAkasaReport" : "GetGtCustomerSingleInvoice";
            var request = new Object();
            request.InvoiceNo = parseInt(data.invoiceNo);
            request.InvoiceNos = invoiceNos;
            request.fileFormat = fileFormat;
            request.MethodName = (data.viewInvocieOdometerReport ? "GetInvoiceOdometerDetails" : reportName);
            request.ViewInvocieOdometerReport = data.ViewInvocieOdometerReport;
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            let fileData = dataResponse.reportBase64String;            
            if (fileData !== null && fileData !== '') {
                let a = document.createElement('a');
                a.href = fileData;
                if (fileFormat == "pdf") {
                    a.download =`InvoiceVoucher_${data.invoiceNo}.pdf`;
                } else {
                    a.download = `InvoiceVoucher_${data.invoiceNo}.xlsx`;
                }
                a.click();
            }
            else {
                Swal.fire({
                    allowOutsideClick: false,
                    showCloseButton: true,
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong! Unable to view report.',
                })
            }
            
        } catch (ex) {            
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }

    openPaymentModel(e, data) {
        e.preventDefault();
        this.refs.invoicePaymentComponent.showModal("Billing", data.invoiceNo, data.customerId, false);
    }

    // <Column dataField="totalCreditCc" caption="Credit Cc" width={80} allowFiltering={true} allowSorting={true} format="currency" />
    //<Column dataField="totalCreditCash" caption="Credit Cash" width={80} allowFiltering={true} allowSorting={true} format="currency" />
    //<Column dataField="totalCreditCheck" caption="Credit Check" width={80} allowFiltering={true} allowSorting={true} format="currency" />
    //<Column dataField="totalCreditOtherAmount" caption="Credit OtherAmount" width={100} allowFiltering={true} allowSorting={true} format="currency" />

    render() {

        const filterSearchFromDate = time => {
            const currentDate = currentTime;
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }

        const filterSearchToDate = time => {
            const currentDate = currentTime;
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }
        const isDisableAllButton = this.state.draftInvoices.length > 0 ? false : true;

        const renderGridCell = (data) => {
            return <div style={{ textAlign: 'left' }}>
                <a href="#" title="History" onClick={(e) => { this.openInvoiceHistoryModel(e, data.data) }}><i className="fas fa-history icon text-info"></i></a>
                <a href="#" title="View/Upload Documents" style={{ paddingLeft: '6px' }} onClick={(e) => { this.openInvoiceDocumentsModel(e, data.data) }}><i className="fas fa-paperclip icon text-info"></i></a>
                <a href="#" title="Ride Details" style={{ paddingLeft: '6px' }} onClick={(e) => { this.openInvoiceTripModel(e, data.data) }}><i className="fas fa-list icon text-info"></i></a>
                <a href="#" title="Email" style={{ paddingLeft: '6px' }} onClick={(e) => { this.openInvoiceEmailModel(e, data.data) }}><i className="fas fa-envelope icon text-info"></i></a>
                <a href="#" title="Payment" style={{ paddingLeft: '6px' }} onClick={(e) => { this.openPaymentModel(e, data.data) }}><i className="fas fa-money-bill icon text-info"></i></a>
                <a href="#" title="View in PDF" style={{ paddingLeft: '6px' }} onClick={(e) => { this.viewInvoiceReport(e, data.data, "pdf") }}><i className="fas fa-file-pdf icon text-info"></i></a>
                <a href="#" title="View in Excel" style={{ paddingLeft: '6px' }} onClick={(e) => { this.viewInvoiceReport(e, data.data, "xlsx") }}><i className="fas fa-file-excel icon text-info"></i></a>
                <a href="#" title="Ungenerate Invoice" style={{ paddingLeft: '6px' }} onClick={(e) => { this.ungenerateInvoice(e, data.data) }}><i className="fas fa-window-close icon text-info"></i></a>
            </div>;
        }

        const renderGridCurrencyCell = (data) => {
            return <div><i className={currencyIcon}></i>&nbsp;{parseFloat(data.value).toFixed(2)}</div>;
        }

        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-sm-6 col-md-6 col-lg-6">
                                <h1 className="m-0 text-dark">Approved Invoice</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem><NavLink tag={Link} to="/invoice/generate" className="btn btn-block btn-outline-secondary btn-sm" title="Go to Generate Invoice" ><i className="fas fa-file-invoice"></i> Go to Generate Invoice</NavLink></NavItem>
                                    <NavItem> <NavLink tag={Link} to="/invoice/draft" className="btn btn-block btn-outline-secondary btn-sm" title="Go to Draft Invoice" ><i className="fas fa-tasks"></i> Go to Draft Invoice</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <form method="post" onSubmit={this.handleSearchUnapproveRidesSubmit} style={(this.state.isSubmited || this.state.isDraftSubmited) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="rez-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                                    <div className="form-group">
                                        <label>Invoice #</label>
                                        <input type="text" className="form-control form-control-sm" name="invoiceNumber" maxLength="30" title="Enter invoice number" value={this.state.invoiceNumber} onChange={this.handleInputChange} placeholder="Enter invoice #" />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>Customer Type</label>
                                        <select className="form-control form-control-sm" name="customerTypeId" placeholder="Select customer type" title="Customer Type" value={this.state.customerTypeId} onChange={this.handleInputChange}>
                                            <option value={0}>Select customer type</option>
                                            {this.state.customerTypeList && this.state.customerTypeList.map((item, i) =>
                                                <option key={i} value={item.id}>{item.name}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>Select Customer</label>
                                        <SelectBox
                                            dataSource={this.state.customerList.length > 0 ? this.state.customerList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select customer"
                                            name="customerId"
                                            defaultValue={0}
                                            value={this.state.customerId}
                                            displayExpr='fullName'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleCustomerInputChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>Billing Start Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDateTime"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.fromDateTime}
                                            value={this.state.fromDateTime}
                                            onValueChanged={this.handleFromValueChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>Billing End Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDateTime"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.toDateTime}
                                            value={this.state.toDateTime}
                                            onValueChanged={this.handleToValueChanged}
                                            width="100%"
                                            disabled={false}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                                    <div className="form-group">
                                        <label>Invoice Status</label>
                                        <select name="invoiceStatusId" className="form-control form-control-sm" value={this.state.invoiceStatusId} onChange={this.handleInputChange}>
                                            {
                                                invoiceStatus &&
                                                invoiceStatus.map((item, i) =>
                                                    (<option key={i} value={item.id}>{item.name}</option>))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-3 col-xs-3 text-right">
                                    <div className="form-group">
                                        <label className="btn-block">&nbsp;</label>
                                        <button type="submit" className="btn btn-success btn-sm" onClick={this.handleSearchUnapproveRidesSubmit} >
                                            {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSubmited && <span title="Search"><i className="fas fa-search"></i> Search</span>}
                                        </button>&nbsp; &nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" disabled={isDisableAllButton} onClick={this.handleClearSearch} title="Clear"><i className="fas fa-eraser"></i> Clear</button>&nbsp; &nbsp;
                                        <button type="submit" className="btn btn-custom-gray btn-sm" disabled={isDisableAllButton} onClick={this.handleApproveInvoice} >
                                            {this.state.isDraftSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isDraftSubmited && <span title="Move to Draft"><i className="fas fa-check"></i> Move to Draft</span>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="content">
                    <div className="card">
                        <div className="card-body">
                            <DataGrid
                                dataSource={this.state.draftInvoices}
                                keyExpr="invoiceNo"

                                columnsAutoWidth={false}
                                showColumnLines={false}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                showBorders={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                width='100%'
                                onToolbarPreparing={this.onToolbarPreparing}
                                selectedRowKeys={this.state.selectedRowKeys}
                                onSelectionChanged={this.onSelectionChanged}
                                ref={ref => this.dataGrid = ref}>
                                <Selection
                                    mode="multiple"
                                    selectAllMode={"allPages"}
                                    showCheckBoxesMode={"always"}
                                />
                                <Column dataField="invoiceNo" width={"auto"} caption="" fixed={true} allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                <Column dataField="displayInvoiceNo" caption="Invoice Number" fixed={true} width={100} allowFiltering={true} allowSorting={true} />
                                <Column dataField="invoiceDate" caption="Invoice Date" width={100} allowFiltering={true} allowSorting={true} format={DateFormat.DefaultDateTimeFormat} dataType="datetime" />
                                <Column dataField="invoiceDueDate" caption="Invoice Due Date" width={100} allowFiltering={true} allowSorting={true} format={DateFormat.DefaultDateTimeFormat} dataType="datetime" />
                                <Column dataField="customerCode" caption="Customer Code" width={80} allowFiltering={true} allowSorting={true} />
                                <Column dataField="customerName" caption="Customer Name" width={130} allowFiltering={true} allowSorting={true} />
                                <Column dataField="customerTypeName" caption="Customer Type" width={120} allowFiltering={true} allowSorting={true} />
                                <Column dataField="poNo" caption="Po No" width={100} allowFiltering={true} allowSorting={true} />
                                <Column dataField="erpRefrenceNo" caption="ERP Refrence#" width={110} allowFiltering={true} allowSorting={true} />
                                <Column dataField="baseAmount" caption="Base Fare" allowEditing={false} width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="totalRideFare" caption="Ride Fare" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="creditAmount" caption="Credit Amount" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="billAmount" caption="Bill Amount" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="writeOffAmount" caption="Write-Off" allowEditing={false} width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="discountAmount" caption="Discount Amount" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="dueAmount" caption="Due Amount" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="paidAmount" caption="Paid Amount" width={80} allowFiltering={true} allowSorting={true} cellRender={renderGridCurrencyCell} />
                                <Column dataField="lastEmailBy" caption="Last Email By" width={120} allowFiltering={true} allowSorting={true} />
                                <Column dataField="lastEmailDate" caption="Last Email Date " width={120} allowFiltering={true} allowSorting={true} format={DateFormat.DefaultDateTimeFormat} dataType="datetime" />
                                <Column dataField="invoiceApprovedBy" caption="Invoice ApprovedBy" width={120} allowFiltering={true} allowSorting={true} />
                                <Column dataField="invoiceApprovedDate" caption="Invoice ApprovedDate " width={120} allowFiltering={true} allowSorting={true} format={DateFormat.DefaultDateTimeFormat} dataType="datetime" />
                                <Column dataField="generatedBy" caption="Created By" width={120} allowFiltering={true} allowSorting={true} />
                                <Column dataField="createdDate" caption="Created Date" width={100} allowFiltering={true} allowSorting={true} format={DateFormat.DateTime24Format} dataType="datetime" />

                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={false} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true} />
                                <Export enabled={true} allowExportSelectedData={false} />
                                <ColumnChooser enabled={true} />
                                <ColumnFixing enabled={true} />
                            </DataGrid>
                        </div>
                    </div>

                </div>

                <ReservationHistoryComponent ref="reservationHistoryModal" />
                <InvoiceRidesComponent ref="invoiceRidesModal" />
                <DocumentsComponent ref="documentsComponent" />
                <InvoicePaymentComponent getApprovedInvoices={this.getApprovedInvoices} ref="invoicePaymentComponent" />
                <InvoiceEmailComponent getApprovedInvoices={this.getApprovedInvoices} ref="invoiceEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >)
    }
}