import React, { Component } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { SelectBox } from 'devextreme-react/select-box';
import Swal from 'sweetalert2';
import { LoadPanel } from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import NumberFormat from 'react-number-format';
import Modal from 'react-modal'; //npm install --save react-modal
import { post } from 'axios'; //npm install--save axios
import ReactHtmlParser from 'html-react-parser' //npm i html-react-parser;

import HtmlEditor, { Toolbar, Item } from 'devextreme-react/html-editor';
const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
const fontValues = ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'];

export const priorityDropdownLis = [{
    'id': 1,
    'name': 'Normal',
}, {
    'id': 2,
    'name': 'Moderate',
}, {
    'id': 3,
    'name': 'High',
}]
let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class TicketingComponent extends Component {
    constructor(props) {
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        super(props)
        this.state = {
            isSubmitted: false,
            isRefreshSubmitted: false,
            loadPanelVisible: false,
            isShowTicketClosedOnly: false,
            ticketDataList: [],
            ticketGridDataSource: null,
            fromDate: moment(currentTime).add(-7, "days").format("MM/DD/YYYY"),
            toDate: moment(currentTime).format("MM/DD/YYYY"),
            isAllLocation: true,
            locationDropdownList: [],
            locationDropdownData: null,
            locationId: 0,
            ticketNo: '',
            isValidTicketNo: false,
            isOpenAddNewTicketModal: false,
            isAddNewTicketSubmitted: false,
            helpTopicDropdownList: [],
            helpTopicDropdownData: [],
            helpTopicId: 0,
            userFirstName: '',
            userLastName: '',
            userEmail: '',
            userMobile: '',
            countryCode: '',
            priorityId: 1,
            issueSummary: '',
            issueDetails: '',
            attachments: [],
            isViewTicketCommentModal: false,
            isCommentSubmitted: false,
            viewTicketComments: {
                ticketNo: '',
                userFullName: '',
                userPhone: '',
                userEmail: '',
                helpTopic: '',
                priority: '',
                issueStatusId: 1,
                status: '',
                issueBy: '',
                dateofOccurrence: '',
                locationName: '',
                resolvedBy: '',
                resolvedDate: '',
                allowReply: false,
                isReplyClosed: false,
                issueSummary: '',
                issueDetails: '',
                attachments: [],
                comments: [],
            },
            ticketId: 0,
            issueStatusDropdownList: [],
            issueStatusId: 1,
            comment: '',
            errors: {
                fromDate: '',
                toDate: '',
                location: '',
                ticketNo: '',
                helpTopic: '',
                userFirstName: '',
                userEmail: '',
                userMobile: '',
                countryCode: '',
                issueSummary: '',
                issueDetails: '',
                issuestatus: '',
                comment: ''
            }
        }
    }
    async componentDidMount() {
        await this.getLocationDropdownList();
        await this.getTicketList();
    }
    getLocationDropdownList = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            const requestParams = getRequestParams('GET');
            const response = await fetch('account-api/locations', requestParams);
            const data = await response.json();
            this.setState({
                locationDropdownList: data.locations
            });
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnToolbarPreparing = (e) => {
        try {
            e.toolbarOptions.items.unshift({
                location: 'after',
                widget: 'dxButton',
                showText: 'always',
                options: {
                    disabled: false,
                    //text: 'Export to Excel',
                    icon: 'export-excel-button',//'export',
                    elementAttr: {
                        "class": "dx-datagrid-export-button"
                    },
                    onClick: function () {
                        e.component.option("export.fileName", "Tickets");
                        e.component.exportToExcel(false);
                    },
                    hint: 'Export to Excel'
                }
            }, {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    onClick: function () {
                        e.component.refresh();
                    },
                    hint: 'Refresh'
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnCellPrepared = (e) => {
        if (e.rowType === "data" && e.column.dataField !== "id") {
            if (e.data.isReplyClosed === true) {
                if (e.data.issueStatusId === 2) {
                    e.cellElement.style.backgroundColor = "#90EE90";
                }
                else {
                    e.cellElement.style.backgroundColor = "#FF7F7F";
                }
            }
            if (e.data.allowReply === false && e.data.isReplyClosed === false) {
                e.cellElement.style.color = "red";
            }
        }
    }
    handleOnShowClosedFeedbck = (e) => {
        try {
            this.setState({ isShowTicketClosedOnly: e.target.checked });
            let ticketGridDataList = [...new Set(this.state.ticketDataList)];
            if (e.target.checked) {
                ticketGridDataList = [...new Set(this.state.ticketDataList.filter(item => item.isReplyClosed === true))];
            }
            this.setState({ ticketGridDataSource: ticketGridDataList });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnRefresh = async () => {
        try {
            this.setState({ isRefreshSubmitted: true, loadPanelVisible: true });
            await new Promise(resolve => setTimeout(resolve, 1000)); // 1 sec
            currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
            this.setState({
                isSubmitted: false,
                loadPanelVisible: false,
                isShowTicketClosedOnly: false,
                ticketDataList: [],
                ticketGridDataSource: null,
                fromDate: moment(currentTime).add(-7, "days").format("MM/DD/YYYY"),
                toDate: moment(currentTime).format("MM/DD/YYYY"),
                isAllLocation: true,
                locationDropdownList: [],
                locationDropdownData: null,
                locationId: 0,
                ticketNo: '',
                isValidTicketNo: false,
                isOpenAddNewTicketModal: false,
                isAddNewTicketSubmitted: false,
                helpTopicDropdownList: [],
                helpTopicDropdownData: null,
                helpTopicId: 0,
                userFirstName: '',
                userLastName: '',
                userEmail: '',
                userMobile: '',
                countryCode: '',
                priorityId: 1,
                issueSummary: '',
                issueDetails: '',
                attachments: [],
                isViewTicketCommentModal: false,
                isCommentSubmitted: false,
                viewTicketComments: {
                    ticketNo: '',
                    userFullName: '',
                    userPhone: '',
                    userEmail: '',
                    helpTopic: '',
                    priority: '',
                    issueStatusId: 1,
                    status: '',
                    issueBy: '',
                    dateofOccurrence: '',
                    locationName: '',
                    resolvedBy: '',
                    resolvedDate: '',
                    allowReply: false,
                    isReplyClosed: false,
                    issueSummary: '',
                    issueDetails: '',
                    attachments: [],
                    comments: [],
                },
                ticketId: 0,
                issueStatusDropdownList: [],
                issueStatusId: 1,
                comment: '',
                errors: {
                    fromDate: '',
                    toDate: '',
                    location: '',
                    ticketNo: '',
                    helpTopic: '',
                    userFirstName: '',
                    userEmail: '',
                    userMobile: '',
                    countryCode: '',
                    issueSummary: '',
                    issueDetails: '',
                    issuestatus: '',
                    comment: ''
                }
            });
            await this.getLocationDropdownList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isRefreshSubmitted: false, loadPanelVisible: false });
    }
    handleOnSearchSubmit = async event => {
        event.preventDefault();
        try {
            let isValid = true;
            let errors = this.state.errors;
            errors.fromDate = '';
            errors.toDate = '';
            errors.location = '';
            errors.ticketNo = '';
            if (this.state.isAllLocation === false) {
                if (Number(this.state.locationId) === 0) {
                    errors.location = 'Please select location.';
                    isValid = false;
                }
            }
            if (this.state.fromDate.trim() === '') {
                errors.fromDate = 'Please select date.';
                isValid = false;
            }
            if (this.state.toDate.trim() === '') {
                errors.toDate = 'Please select date.';
                isValid = false;
            }
            if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                if (moment(this.state.fromDate, 'MM/DD/YYYY').isAfter(this.state.toDate, 'MM/DD/YYYY')) {
                    errors.fromDate = 'Please enter a valid date range.';
                    errors.toDate = 'Please enter a valid date range.';
                    isValid = false;
                }
            }
            if (this.state.ticketNo.trim() !== '' && this.state.ticketNo.trim().length !== 8) {
                errors.ticketNo = 'Please enter 8 characters for each ticket.';
                isValid = false;
            }
            this.setState({ errors: errors });
            if (isValid === true) {
                this.setState({ isSubmitted: true, loadPanelVisible: true });
                await this.getTicketList();
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false, loadPanelVisible: false });
    }
    getTicketList = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            let fromDate = '';
            let toDate = '';
            if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                fromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00';
                toDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59'
            }
            let request = {};
            request.LocationId = Number(this.state.locationId);
            request.FromDate = fromDate;
            request.ToDate = toDate;
            request.TicketNo = (this.state.ticketNo.trim() !== '' ? 'TK' + this.state.ticketNo.trim() : '');
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('ticketing-api/list', requestParams);
            const data = await response.json();
            this.setState({
                ticketGridDataSource: data.tickets,
                ticketDataList: data.tickets
            });
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnClearSearch = async () => {
        try {
            currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
            this.setState({
                isSubmitted: false,
                isRefreshSubmitted: false,
                loadPanelVisible: false,
                isShowTicketClosedOnly: false,
                ticketDataList: [],
                ticketGridDataSource: null,
                fromDate: moment(currentTime).add(-7, "days").format("MM/DD/YYYY"),
                toDate: moment(currentTime).format("MM/DD/YYYY"),
                isAllLocation: true,
                locationDropdownData: null,
                locationId: 0,
                ticketNo: '',
                isValidTicketNo: false,
                isOpenAddNewTicketModal: false,
                isAddNewTicketSubmitted: false,
                helpTopicDropdownList: [],
                helpTopicDropdownData: null,
                helpTopicId: 0,
                userFirstName: '',
                userLastName: '',
                userEmail: '',
                userMobile: '',
                countryCode: '',
                priorityId: 1,
                issueSummary: '',
                issueDetails: '',
                attachments: [],
                isViewTicketCommentModal: false,
                isCommentSubmitted: false,
                viewTicketComments: {
                    ticketNo: '',
                    userFullName: '',
                    userPhone: '',
                    userEmail: '',
                    helpTopic: '',
                    priority: '',
                    issueStatusId: 1,
                    status: '',
                    issueBy: '',
                    dateofOccurrence: '',
                    locationName: '',
                    resolvedBy: '',
                    resolvedDate: '',
                    allowReply: false,
                    isReplyClosed: false,
                    issueSummary: '',
                    issueDetails: '',
                    attachments: [],
                    comments: [],
                },
                ticketId: 0,
                issueStatusDropdownList: [],
                issueStatusId: 1,
                comment: '',
                errors: {
                    fromDate: '',
                    toDate: '',
                    location: '',
                    ticketNo: '',
                    helpTopic: '',
                    userFirstName: '',
                    userEmail: '',
                    userMobile: '',
                    countryCode: '',
                    issueSummary: '',
                    issueDetails: '',
                    issuestatus: '',
                    comment: ''
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnInputChange = (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
            let errors = this.state.errors;
            switch (name) {
                case 'isAllLocation':
                    errors.location = '';
                    this.setState({ isAllLocation: value, locationDropdownData: null, locationId: 0 });
                    break;
                case 'ticketNo':
                    errors.ticketNo = '';
                    if (value.trim().length < 9) {
                        this.setState({ ticketNo: value.toUpperCase(), isValidTicketNo: false });
                        if (value.trim().length === 8) {
                            this.setState({ isValidTicketNo: true });
                        }
                    }
                    break;
                case 'userFirstName':
                    errors.userFirstName = '';
                    if (value.length <= 50) {
                        this.setState({ userFirstName: value });
                    }
                    break;
                case 'userLastName':
                    errors.userLastName = '';
                    if (value.length <= 50) {
                        this.setState({ userLastName: value });
                    }
                    break;
                case 'userEmail':
                    errors.userEmail = '';
                    if (value.length <= 50) {
                        this.setState({ userEmail: value });
                    }
                    break;
                case 'issueSummary':
                    errors.issueSummary = '';
                    if (value.length < 76) {
                        this.setState({ issueSummary: value });
                    }
                    break;
                default:
                    break;
            }
            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnInputLeave = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            if (name === 'ticketNo') {
                let ticketNo = this.state.ticketNo.trim();
                if (ticketNo.length === 8) {
                    this.setState({ isValidTicketNo: true });
                }
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnPaste = async (event) => {
        try {
            let ticketNo = event.clipboardData.getData('Text').trim();
            if (ticketNo.length === 10) {
                ticketNo = ticketNo.substring(2, ticketNo.length);
                this.setState({ ticketNo: ticketNo, isValidTicketNo: true });
            }
            if (ticketNo.length === 8) {
                this.setState({ ticketNo: ticketNo, isValidTicketNo: true });
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnFromDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.fromDate = '';
            let fromDate = '';
            if (event.value !== null && event.value !== '') {
                fromDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.fromDate = 'Please select date.';
            }
            if (this.state.toDate.trim() !== '') {
                errors.toDate = '';
            }
            this.setState({ fromDate: fromDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnToDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.toDate = '';
            let toDate = '';
            if (event.value !== null && event.value !== '') {
                toDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.toDate = 'Please select date.';
            }
            if (this.state.fromDate.trim() !== '') {
                errors.fromDate = '';
            }
            this.setState({ toDate: toDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnLocationDropdownChange = (data) => {
        try {
            let errors = this.state.errors;
            errors.location = '';
            let locationId = 0;
            if (data !== null) {
                locationId = (data.selectedItem != null ? data.selectedItem.id : 0);
            }
            else {
                if (this.state.isAllLocation === false) {
                    errors.location = 'Please select location.';
                }
            }
            this.setState({ locationDropdownData: data, locationId: locationId, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }

    // Start add new ticket
    getHelptopicDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('ticketing-api/helptopic-dropdown', requestParams);
            const data = await response.json();
            if (data.isSuccess) {
                this.setState({
                    helpTopicDropdownList: data.helpTopics
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnFileSelect = async (event, isComment) => {
        try {
            this.setState({ loadPanelVisible: true });
            let attachments = this.state.attachments;
            //let fileCount = attachments.length + event.target.files.length;
            //if (fileCount > 5) {
            //    await this.onfailure('error', 'Max file limit', 'Please select no more than 5 files.');
            //}
            //else
            {
                if (event.target.files.length > 0) {
                    let isFilesInvalid = false;
                    let documentSelectedFile = event.target.files;
                    const formData = new FormData();
                    for (var i = 0; i < documentSelectedFile.length; i++) {
                        let file = documentSelectedFile[i];
                        if (file.size > 0) {
                            formData.append('File', file);
                        }
                        else {
                            isFilesInvalid = true;
                            await this.onfailure('error', 'Invalid file', 'The file ' + file.name + ' found invalid.');
                            break;
                        }
                    }
                    if (isFilesInvalid === false) {
                        const config = {
                            headers: {
                                'content-type': 'multipart/form-data',
                                'UserId': sessionStorage.getItem('session_userId'),
                                'Authorization': 'Bearer ' + sessionStorage.getItem('session_token'),
                                'CultureCode': sessionStorage.getItem('session_culturecode'),
                            },
                        };
                        const response = await post('ticketing-api/add-attachment', formData, config);
                        if (response.status === 200) {
                            const data = await response.data.attachments;
                            if (data !== null) {
                                let maxFileId = 0;
                                if (attachments !== null && attachments.length > 0) {
                                    maxFileId = Math.max.apply(Math, attachments.map(function (val) { return val.id; }))
                                }
                                for (var a = 0; a < data.length; a++) {
                                    maxFileId = Number(maxFileId) + 1;
                                    let attachment = {};
                                    attachment.id = maxFileId;
                                    attachment.fileName = data[a].fileName;
                                    attachment.fileData = data[a].fileData;
                                    attachment.fileSize = data[a].fileSize;
                                    attachment.fileExtension = data[a].fileExtension;
                                    attachments.push(attachment);
                                    this.setState({ attachments: attachments });
                                }
                            }
                        }
                    }
                }
                else {
                    await this.onfailure('error', 'Invalid file', 'Please select file again.');
                }
            }
            if (isComment === false) {
                this.fileInput.value = "";
            }
            else {
                this.fileComment.value = "";
            }
        } catch (ex) {
            await this.onfailure('error', null, null);
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    removeAttachmante = async (e, id) => {
        e.preventDefault();
        try {
            let attachments = this.state.attachments;
            attachments = attachments.filter(r => r.id !== id);
            this.setState({ attachments: attachments });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOpenNewTicketModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getHelptopicDropdownList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleCloseNewTicketModal = async () => {
        try {
            let errors = this.state.errors;
            errors.helpTopic = '';
            errors.userFirstName = '';
            errors.userEmail = '';
            errors.userMobile = '';
            errors.countryCode = '';
            errors.issueSummary = '';
            errors.issueDetails = '';
            this.setState({
                isOpenAddNewTicketModal: false,
                isAddNewTicketSubmitted: false,
                helpTopicDropdownList: [],
                helpTopicDropdownData: null,
                helpTopicId: 0,
                userFirstName: '',
                userLastName: '',
                userEmail: '',
                userMobile: '',
                countryCode: '',
                priorityId: 1,
                issueSummary: '',
                issueDetails: '',
                attachments: [],
                errors: errors
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnSaveNewTicketSubmitted = async event => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isAddNewTicketSubmitted: true });
                let request = {};
                request.HelpTopicId = Number(this.state.helpTopicId);
                request.UserFirstName = this.state.userFirstName.trim();
                request.UserLastName = this.state.userLastName.trim();
                request.UserEmail = this.state.userEmail.trim();
                request.UserMobile = this.state.userMobile.trim();
                request.CountryCode = this.state.countryCode.trim();
                request.IssuePriorityId = Number(this.state.priorityId);
                request.IssueSummary = this.state.issueSummary.trim();
                request.IssueDetails = this.state.issueDetails.trim();
                request.Attachments = this.state.attachments;
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('ticketing-api', requestParams);
                if (response.ok) {
                    const data = await response.json();
                    await Swal.fire({
                        allowOutsideClick: false,
                        icon: data.isSuccess ? 'success' : 'error',
                        title: data.isSuccess ? 'Success' : 'Oops...',
                        html: (data.isSuccess === false && (data.resultMessage === null || data.resultMessage === "")) ? 'Unable to process the request.' : data.resultMessage,
                    }).then(async (result) => {
                        if (data.isSuccess) {
                            if (result.isConfirmed) {
                                await this.handleCloseNewTicketModal();
                                await this.getTicketList();
                            }
                        }
                    })
                }
                else {
                    await this.onfailure('error', 'Failed!', null);
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isAddNewTicketSubmitted: false });
    }
    handleOnClearNewTicket = async (event) => {
        event.preventDefault();
        try {
            let errors = this.state.errors;
            errors.helpTopic = '';
            errors.userFirstName = '';
            errors.userEmail = '';
            errors.userMobile = '';
            errors.countryCode = '';
            errors.issueSummary = '';
            errors.issueDetails = '';
            this.setState({
                isAddNewTicketSubmitted: false,
                helpTopicDropdownData: null,
                helpTopicId: 0,
                userFirstName: '',
                userLastName: '',
                userEmail: '',
                userMobile: '',
                countryCode: '',
                priorityId: 1,
                issueSummary: '',
                issueDetails: '',
                attachments: [],
                errors: errors
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnHelpTopicDropdownChange = (data) => {
        try {
            let errors = this.state.errors;
            errors.helpTopic = '';
            let helpTopicId = 0;
            if (data !== null) {
                helpTopicId = (data.selectedItem != null ? data.selectedItem.id : 0);
            }
            else {
                //errors.helpTopic = 'Please select help topic.';
            }
            this.setState({ helpTopicDropdownData: data, helpTopicId: helpTopicId, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnIssueBodyChange = (e) => {
       
        try {
            let errors = this.state.errors;
            errors.issueDetails = '';
            this.setState({ issueDetails: e.value, errors: errors })
        } catch (ex) {            
            console.log(ex);
        }
    }
    isFormValid = async () => {
        let isValid = true;
        try {
            let errors = this.state.errors;
            errors.helpTopic = '';
            errors.userFirstName = '';
            errors.userEmail = '';
            errors.userMobile = '';
            errors.countryCode = '';
            errors.issueSummary = ''; 
            errors.issueDetails = '';
            let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (Number(this.state.helpTopicId) === 0) {
                errors.helpTopic = 'Please select help topic.';
                isValid = false;
            }
            if (this.state.userFirstName.trim() === '') {
                errors.userFirstName = 'Please enter first name.';
                isValid = false;
            }
            if (this.state.userEmail.trim() === '') {
                isValid = false;
                errors.userEmail = "Please enter email.";
            }
            if (this.state.userEmail.trim() !== '') {
                if (!regEmail.test(String(this.state.userEmail.trim()).toLowerCase())) {
                    isValid = false;
                    errors.userEmail = "Please enter valid email.";
                }
            }
            if (this.state.countryCode.trim() !== '') {
                if (this.state.userMobile.trim() === '') {
                    isValid = false;
                    errors.userMobile = 'Please enter mobile no.';
                }
            }
            if (this.state.userMobile.trim() !== '') {
                if (this.state.countryCode.trim() === '') {
                    isValid = false;
                    errors.countryCode = 'Please enter country code.';
                }
                if (this.state.userMobile.trim().length !== 10) {
                    isValid = false;
                    errors.userMobile = 'Please enter valid mobile no.';
                }
            }
            if (this.state.issueSummary.trim() === '') {
                isValid = false;
                errors.issueSummary = "Please enter issue summary.";
            }
            if (this.removeHtmlTags(this.state.issueDetails.trim()).trim() === '') {
                isValid = false;
                errors.issueDetails = "Please enter issue details.";
            }
            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    // End add new ticket

    // Start ticket view comment
    handleOnTicketViewComment = async (e, data) => {
        e.preventDefault();
        try {
            if (data.requestId === null || data.requestId === '') {
                await this.onfailure('error', 'Invalid request', null);
            }
            else {
                //this.props.history.push({
                //    pathname: '/ticketing/ticket/comment',
                //    state: { requestId: data.requestId, allowReply: data.allowReply, sourceUrl: "/ticketing/tickets" }
                //})
                this.setState({ ticketId: data.id, loadPanelVisible: true });
                const requestParams = getRequestParams('GET');
                const response = await fetch('ticketing-api/comments/' + data.requestId, requestParams);
                if (response.ok) {
                    const responseData = await response.json();
                    if (responseData.isSuccess) {
                        let viewTicketComments = this.state.viewTicketComments;
                        viewTicketComments.ticketNo = responseData.ticket.ticketNo;
                        viewTicketComments.userFullName = responseData.ticket.userFullName;
                        viewTicketComments.userPhone = responseData.ticket.userPhone;
                        viewTicketComments.userEmail = responseData.ticket.userEmail;
                        viewTicketComments.helpTopic = responseData.ticket.helpTopic;
                        viewTicketComments.priority = responseData.ticket.priority;
                        viewTicketComments.issueStatusId = responseData.ticket.issueStatusId;
                        viewTicketComments.status = responseData.ticket.status;
                        viewTicketComments.issueBy = responseData.ticket.issuedBy;
                        viewTicketComments.dateofOccurrence = this.convertUTCToLocal(responseData.ticket.dateofOccurrence);
                        viewTicketComments.locationName = responseData.ticket.locationName;
                        viewTicketComments.resolvedBy = responseData.ticket.resolvedBy;
                        viewTicketComments.resolvedDate = this.convertUTCToLocal(responseData.ticket.resolutionDate);
                        viewTicketComments.allowReply = data.allowReply;
                        viewTicketComments.isReplyClosed = responseData.ticket.isReplyClosed;
                        viewTicketComments.issueSummary = responseData.ticket.issueSummary;
                        viewTicketComments.issueDetails = responseData.ticket.issueDetails;
                        viewTicketComments.attachments = responseData.ticket.attachments;
                        viewTicketComments.comments = (responseData.comments !== null) ? responseData.comments.filter(el => { el.commentDate = this.convertUTCToLocal(el.commentDate); return el; }) : responseData.comments;
                        this.setState({ viewTicketComments: viewTicketComments, issueStatusId: responseData.ticket.issueStatusId });
                        this.setState({ isViewTicketCommentModal: true });
                    }
                    else {
                        await this.onfailure('error', null, responseData.resultMessage);
                    }
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    convertUTCToLocal = (utcDateTime) => {
        try {
            if (utcDateTime !== '') {
                if (!utcDateTime.toLowerCase().includes('utc')) {
                    utcDateTime = `${utcDateTime} UTC`;
                }
                utcDateTime = new Date(utcDateTime).toLocaleString();
            }
            return utcDateTime;
        } catch (ex) {
            console.log(ex);
            return '';
        }
    }
    handleOpenTicketViewCommentModa = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            const requestParams = getRequestParams('GET');
            const response = await fetch('ticketing-api/issuestatus-dropdown', requestParams);
            const data = await response.json();
            this.setState({
                issueStatusDropdownList: data.issueStatus
            });
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleCloseTicketViewCommentModal = async () => {
        try {
            let errors = this.state.errors;
            errors.comment = '';
            errors.issuestatus = '';
            this.setState({
                isViewTicketCommentModal: false,
                isCommentSubmitted: false,
                attachments: [],
                viewTicketComments: {
                    ticketNo: '',
                    userFullName: '',
                    userPhone: '',
                    userEmail: '',
                    helpTopic: '',
                    priority: '',
                    issueStatusId: 1,
                    status: '',
                    issueBy: '',
                    dateofOccurrence: '',
                    locationName: '',
                    resolvedBy: '',
                    resolvedDate: '',
                    allowReply: false,
                    isReplyClosed: false,
                    issueSummary: '',
                    issueDetails: '',
                    attachments: [],
                    comments: [],
                },
                ticketId: 0,
                issueStatusDropdownList: [],
                issueStatusId: 1,
                comment: '',
                errors: errors
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnCommentBodyChange = (e) => {
        try {
            let errors = this.state.errors;
            errors.comment = '';
            this.setState({ comment: e.value, errors: errors })
        } catch (ex) {
            console.log(ex);
        }
    }
    removeHtmlTags = (str) => {
        try {
            if ((str === null) || (str === ''))
                return '';
            else
                str = str.toString();
            // Regular expression to identify HTML tags in 
            // the input string. Replacing the identified 
            // HTML tag with a null string.
            return str.replace(/(<([^>]+)>)/ig, '');
        } catch (ex) {
            console.log(ex);
            return '';
        }
    }
    handleOnSaveCommentSubmitted = async event => {
        event.preventDefault();
        try {
            let isFormValid = true;
            let errors = this.state.errors;
            errors.comment = '';
            errors.issuestatus = '';
            //if (this.state.comment.getEditorState().getCurrentContent().getPlainText().trim() === '') {
            //    isFormValid = false;
            //    errors.comment = "Please enter comment.";
            //}
            if (this.removeHtmlTags(this.state.comment.trim()).trim() === '') {
                isFormValid = false;
                errors.comment = "Please enter comment.";
            }
            if (Number(this.state.issueStatusId) === 0) {
                isFormValid = false;
                errors.issuestatus = "Please select status.";
            }
            if (isFormValid === true) {
                this.setState({ isCommentSubmitted: true });
                let request = {};
                request.TicketId = Number(this.state.ticketId);
                request.IssueStatusId = Number(this.state.issueStatusId);
                request.ParentCommentId = 0;
                request.IsSystemComment = true;
                request.Comment = this.state.comment.trim();
                request.Attachments = this.state.attachments;
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('ticketing-api/save-comment', requestParams);
                if (response.ok) {
                    const data = await response.json();
                    await Swal.fire({
                        allowOutsideClick: false,
                        icon: data.isSuccess ? 'success' : 'error',
                        title: data.isSuccess ? 'Success' : 'Oops...',
                        html: (data.isSuccess === false && (data.resultMessage === null || data.resultMessage === "")) ? 'Unable to process the request.' : data.resultMessage,
                    }).then(async (result) => {
                        if (data.isSuccess) {
                            if (result.isConfirmed) {
                                await this.handleCloseTicketViewCommentModal();
                                await this.getTicketList();
                            }
                        }
                    })
                }
                else {
                    await this.onfailure('error', 'Failed!', null);
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isCommentSubmitted: false });
    }
    handleOnClearComment = async event => {
        event.preventDefault();
        try {
            let errors = this.state.errors;
            errors.comment = '';
            errors.issuestatus = '';
            this.setState({
                isCommentSubmitted: false,
                issueStatusId: this.state.viewTicketComments.issueStatusId,
                comment: '',
                attachments: [],
                errors: errors
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnShowAttachment = async (e, attachmentId, isCommentAttachment, commentId) => {
        e.preventDefault();
        try {
            let attachments = [];
            if (isCommentAttachment === true) {
                if (this.state.viewTicketComments.comments !== null) {
                    let comments = this.state.viewTicketComments.comments.filter(c => c.id === commentId);
                    if (comments !== null) {
                        attachments = comments[0].attachments;
                    }
                }
            }
            else {
                attachments = this.state.viewTicketComments.attachments;
            }
            if (attachments !== null) {
                let attachment = attachments.filter(a => a.id === attachmentId)
                if (attachment !== null) {
                    const requestParams = getRequestParams('GET');
                    const response = await fetch('ticketing-api/file-mimetype/' + attachment[0].fileExtension, requestParams);
                    if (response.ok) {
                        const data = await response.json();
                        if (data.isSuccess === true) {
                            let a = document.createElement('a');
                            a.href = data.resultMessage + attachment[0].fileData;
                            a.download = attachment[0].fileName;
                            a.click();
                        }
                        else {
                            await this.onfailure('error', 'Invalid File', null);
                        }
                    }
                }
            }
            else {
                await this.onfailure('error', 'File Not Found', null);
            }
        } catch (ex) {
            await this.onfailure('error', 'Download Failed!', null);
            console.log(ex);
        }
    }
    // End ticket view comment
    onfailure = async (icon, title, resultMessage) => {
        this.setState({ loadPanelVisible: false });
        await Swal.fire({
            allowOutsideClick: false,
            showCloseButton: false,
            icon: icon,
            title: (title === null || title.trim() === '') ? 'Oops...' : title,
            html: (resultMessage === null || resultMessage.trim() === '') ? 'Opps! something went wrong, unable to process the request.' : resultMessage.trim(),
        })
    }
    render() {
        const renderGridCell = (data) => {
            return <div style={{ textAlign: 'center' }}>
                <a href="#" title="View Comment" onClick={(e) => { this.handleOnTicketViewComment(e, data.data) }} >
                    <i className="fas fa-comment-dots"></i>
                </a>
            </div>;
        };
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-sm-6 col-md-6 col-lg-6">
                                <h1 className="m-0 text-dark">Ticketing</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem>
                                        <NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="#" title="Refresh"
                                            onClick={this.handleOnRefresh}>
                                            {this.state.isRefreshSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isRefreshSubmitted && <span title="Refresh"><i className="fas fa-sync-alt"></i> Refresh</span>}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink onClick={() => { this.setState({ isOpenAddNewTicketModal: true }) }}
                                            className="btn btn-block btn-outline-secondary btn-sm" title="Add Ticket"><i className="fas fa-plus"></i> Add Ticket</NavLink>
                                    </NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <form style={(this.state.isSubmitted || this.state.isRefreshSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="rez-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                name="isAllLocation"
                                                checked={this.state.isAllLocation}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isAllLocation"> All Location</label>
                                        </div>
                                        <SelectBox
                                            dataSource={this.state.locationDropdownList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select location"
                                            name="ddlocation"
                                            defaultValue={0}
                                            value={this.state.locationId}
                                            displayExpr='locationName'
                                            valueExpr='locationId'
                                            onSelectionChanged={this.handleOnLocationDropdownChange}
                                            showClearButton={true}
                                            disabled={this.state.isAllLocation}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["location"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["location"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>From Date (Ticket)</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            defaultValue={this.state.fromDate}
                                            value={this.state.fromDate}
                                            onValueChanged={this.handleOnFromDateChange}
                                            width={'100%'}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["fromDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["fromDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>To Date (Ticket)</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            defaultValue={this.state.toDate}
                                            value={this.state.toDate}
                                            min={this.state.fromDate}
                                            onValueChanged={this.handleOnToDateChange}
                                            width={'100%'}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["toDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["toDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label>Ticket No</label>
                                        <input type="text" className="form-control form-textbox form-control-sm"
                                            name="ticketNo" title="Ticket No" placeholder="Enter ticket no"
                                            value={this.state.ticketNo}
                                            onChange={this.handleOnInputChange}
                                            onBlur={this.handleOnInputLeave}
                                            onPaste={this.handleOnPaste}
                                            style={{ paddingLeft: '28px' }} />
                                        {this.state.isValidTicketNo
                                            && <span style={{ position: 'absolute', top: '25px', right: '17px', color: 'green' }}>
                                                <i className="fas fa-check"></i> </span>}
                                        <span style={{
                                            position: 'absolute', top: '25px', color: '#202147', fontWeight: 'bold', width: '28px'
                                            , paddingLeft: '3px', paddingTop: '3px', backgroundColor: '#C0C0C0', height: '29px', marginTop: '-3px'
                                        }}>
                                            <i className="fas fa-text">TK</i>
                                        </span>
                                        {this.state.errors["ticketNo"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["ticketNo"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2" >
                                    <div className="form-group">
                                        <label className="btn-block">&nbsp;</label>
                                        <button type="button" className="btn btn-success btn-sm" title="Search" onClick={this.handleOnSearchSubmit} >
                                            {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSubmitted && <span title="Search"><i className="fas fa-search"></i> Search</span>}
                                        </button>&nbsp;&nbsp;
                                           <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                            onClick={this.handleOnClearSearch}>
                                            <i className="fas fa-eraser"></i> Clear
                                        </button>
                                    </div>
                                </div>
                                <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1" >
                                    <div className="form-group custom-control custom-switch swicth-group-checkbox">
                                        <label className="btn-block">&nbsp;</label>
                                        <input type="checkbox" className="custom-control-input"
                                            id="isShowTicketClosedOnly"
                                            title="Show Closed Only"
                                            checked={this.state.isShowTicketClosedOnly}
                                            onChange={this.handleOnShowClosedFeedbck} />
                                        <label className="custom-control-label" style={{ paddingTop: 5 + "px", cursor: 'pointer' }}
                                            htmlFor="isShowTicketClosedOnly">Show Closed
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="content">
                    <div className="card">
                        <div className="card-body">
                            <DataGrid
                                ref={(ref) => this.dataGrid = ref}
                                dataSource={this.state.ticketGridDataSource}
                                keyExpr="id"
                                columnsAutoWidth={true}
                                showColumnLines={false}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                width='100%'
                                onToolbarPreparing={this.handleOnToolbarPreparing}
                                //repaintChangesOnly={true}
                                onCellPrepared={this.handleOnCellPrepared}
                            >
                                <Column dataField="id" width={30} caption="" alignment="center" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                <Column dataField="ticketNo" caption="Ticket No" width={'auto'} />
                                <Column dataField="locationName" caption="City" />
                                <Column dataField="userFullName" caption="User" />
                                <Column dataField="userPhone" caption="Phone" />
                                <Column dataField="userEmail" caption="Email" />
                                <Column dataField="helpTopic" caption="Help Topic" />
                                <Column dataField="priority" caption="Priority" />
                                <Column dataField="status" caption="Status" alignment="center" />
                                <Column dataField="issueSummary" caption="Issue" />
                                <Column dataField="issuedBy" caption="Issued By" />
                                <Column dataField="dateofOccurrence" caption="Occurrence Date" alignment='center' width={'auto'} />
                                <Column dataField="resolvedBy" caption="Resolved By" />
                                <Column dataField="resolutionDate" caption="Resolved Date" alignment='center' width={'auto'} />
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={false} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                <Export enabled={false} allowExportSelectedData={false} />
                                <ColumnChooser enabled={false} />
                                <ColumnFixing enabled={false} />
                            </DataGrid>
                        </div>
                    </div>
                </div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenAddNewTicketModal}
                    style={{ content: { width: '60%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOpenNewTicketModal}
                    onRequestClose={this.handleCloseNewTicketModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form style={this.state.isAddNewTicketSubmitted ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Open a new ticket</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCloseNewTicketModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Help Topic <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.helpTopicDropdownList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select help topic"
                                            name="ddhelptopic"
                                            defaultValue={0}
                                            value={this.state.helpTopicId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnHelpTopicDropdownChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["helpTopic"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["helpTopic"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>First Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm"
                                            name="userFirstName"
                                            placeholder="First name"
                                            title="First Name"
                                            value={this.state.userFirstName}
                                            onChange={this.handleOnInputChange} />
                                        {this.state.errors["userFirstName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["userFirstName"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Last Name</label>
                                        <input type="text" className="form-control form-control-sm"
                                            name="userLastName"
                                            placeholder="Last name"
                                            title="Last Name"
                                            value={this.state.userLastName}
                                            onChange={this.handleOnInputChange} />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Email <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm"
                                            name="userEmail"
                                            placeholder="example@domain.com"
                                            title="Email"
                                            value={this.state.userEmail}
                                            onChange={this.handleOnInputChange} />
                                        {this.state.errors["userEmail"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["userEmail"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                                    <div className="form-group">
                                        <label>Code</label>
                                        <NumberFormat className="form-control form-control-sm" name="countryCode" title="Country Code" format="+##" value={this.state.countryCode} onValueChange={(e) => {
                                            let err = this.state.errors;
                                            err.countryCode = '';
                                            this.setState({ countryCode: e.value, errors: err });
                                        }} placeholder="" />
                                        {this.state.errors["countryCode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["countryCode"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>Mobile Number</label>
                                        <NumberFormat className="form-control form-control-sm" name="userMobile" title="Mobile Number"
                                            placeholder="(000) 000-0000" format="(###) ###-####" value={this.state.userMobile}
                                            onValueChange={(e) => {
                                                let err = this.state.errors;
                                                err.userMobile = '';
                                                this.setState({ userMobile: e.value, errors: err });
                                            }} />
                                        {this.state.errors["userMobile"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["userMobile"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Priority <span className="text-danger">*</span></label>
                                        <select name="ddpriority" className="form-control form-control-sm"
                                            value={this.state.priorityId} onChange={(e) => { this.setState({ priorityId: e.target.value }) }}>
                                            {
                                                priorityDropdownLis &&
                                                priorityDropdownLis.map((item, i) =>
                                                    (<option key={i} value={item.id}>{item.name}</option>))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                                    <div className="form-group">
                                        <label>Issue Summary <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm"
                                            name="issueSummary"
                                            placeholder=""
                                            title="Issue Summary"
                                            value={this.state.issueSummary}
                                            onChange={this.handleOnInputChange} />
                                        {this.state.errors["issueSummary"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["issueSummary"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Issue Details <span className="text-danger">*</span></label>
                                        <HtmlEditor
                                            height={200}
                                            name="issuedetails"
                                            value={this.state.issueDetails}
                                            valueType={"html"}
                                            onValueChanged={this.handleOnIssueBodyChange}
                                        >
                                            <Toolbar>
                                                <Item name="undo" />
                                                <Item name="redo" />
                                                <Item name="separator" />
                                                <Item
                                                    name="size"
                                                    acceptedValues={sizeValues}
                                                />
                                                <Item
                                                    name="font"
                                                    acceptedValues={fontValues}
                                                />
                                                <Item name="separator" />
                                                <Item name="bold" />
                                                <Item name="italic" />
                                                <Item name="strike" />
                                                <Item name="underline" />
                                                <Item name="separator" />
                                                <Item name="alignLeft" />
                                                <Item name="alignCenter" />
                                                <Item name="alignRight" />
                                                <Item name="alignJustify" />
                                                <Item name="separator" />
                                                <Item name="color" />
                                                <Item name="background" />
                                            </Toolbar>
                                        </HtmlEditor>
                                        {this.state.errors["issueDetails"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["issueDetails"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <div className="custom-file-upload">
                                            <button type="button"
                                                className="btn btn-primary btn-sm"
                                                onClick={() => { this.fileInput.click() }}
                                            >Choose File</button>&nbsp;&nbsp;
                                            <span>
                                                {this.state.attachments.length === 0 ? 'No file attached' : this.state.attachments.length + ' file(s) attached'
                                                }
                                            </span>
                                            <input type="file" className="custom-file-input"
                                                ref={ref => this.fileInput = ref}
                                                name="Files" multiple
                                                style={{ display: 'none' }}
                                                onChange={(e) => { this.handleOnFileSelect(e, false) }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        {
                                            this.state.attachments &&
                                            this.state.attachments.map((item, i) =>
                                                (<label style={{ marginRight: '2px' }}
                                                    key={i}>{item.fileName}
                                                    <a onClick={(e) => { this.removeAttachmante(e, item.id) }}>
                                                        <span style={{ color: 'red', fontSize: '20px', cursor: 'pointer', marginRight: '6px', position: 'relative', top: '-8px' }}>
                                                            ×</span></a>
                                                </label>
                                                )
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" title="Save Ticket" onClick={this.handleOnSaveNewTicketSubmitted}>
                                {this.state.isAddNewTicketSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isAddNewTicketSubmitted && <span title="Save Ticket"><i className="fas fa-check"></i> Save Ticket</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleOnClearNewTicket}>
                                <i className="fas fa-eraser"></i> Clear
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Cancel"
                                onClick={this.handleCloseNewTicketModal}>
                                <i className="fas fa-times"></i> Cancel
                            </button>
                        </div>
                    </form>
                </Modal>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isViewTicketCommentModal}
                    style={{ content: { width: '60%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOpenTicketViewCommentModa}
                    onRequestClose={this.handleCloseTicketViewCommentModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form style={this.state.isCommentSubmitted ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Ticket comments</h6>
                            <h6 className="modal-title badge badge-pill"
                                style={{
                                    backgroundColor: (this.state.viewTicketComments.isReplyClosed && this.state.viewTicketComments.issueStatusId === 2) ? '#90EE90' :
                                        (this.state.viewTicketComments.isReplyClosed && this.state.viewTicketComments.issueStatusId === 3) ? "#FF7F7F" : 'green'
                                    , position: "absolute", right: '45px', zIndex: '999'
                                    , color: (this.state.viewTicketComments.isReplyClosed && this.state.viewTicketComments.issueStatusId === 2) ? '#000000' :
                                        (this.state.viewTicketComments.isReplyClosed && this.state.viewTicketComments.issueStatusId === 3) ? "#000000" : "#FFFFFF"
                                }}>
                                Status: {this.state.viewTicketComments.status}
                            </h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCloseTicketViewCommentModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body" style={{ height: '450px', overflow: 'auto' }}>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Name: <strong>{this.state.viewTicketComments.userFullName}</strong></label>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Phone: <strong>{this.state.viewTicketComments.userPhone}</strong></label>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Email: <strong>{this.state.viewTicketComments.userEmail}</strong></label>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Ticket No: <strong>{this.state.viewTicketComments.ticketNo}</strong></label>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Help Topic: <strong>{this.state.viewTicketComments.helpTopic}</strong></label>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Priority: <strong><span className="badge badge-pill badge-warning"
                                            style={{ fontSize: '12px' }}>{this.state.viewTicketComments.priority}</span>
                                        </strong>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Issue By: <strong>{this.state.viewTicketComments.issueBy}</strong></label>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Occurrence Date: <strong>{this.state.viewTicketComments.dateofOccurrence}</strong></label>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label>Citry: <strong>{this.state.viewTicketComments.locationName}</strong></label>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4" style={{ display: (this.state.viewTicketComments.isReplyClosed) || 'none' }}>
                                    <div className="form-group">
                                        <label>Resolved By: <strong>{this.state.viewTicketComments.resolvedBy}</strong></label>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4" style={{ display: (this.state.viewTicketComments.isReplyClosed) || 'none' }}>
                                    <div className="form-group">
                                        <label>Resolved Date: <strong>{this.state.viewTicketComments.resolvedDate}</strong></label>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Issue Summary: <strong>{this.state.viewTicketComments.issueSummary}</strong></label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <Comment
                                    isSystemReply={false}
                                    userName={this.state.viewTicketComments.userFullName}
                                    commentDate={this.state.viewTicketComments.dateofOccurrence}
                                    //status={this.state.viewTicketComments.status}
                                    status={'Open'} // This is master status , it is updated each time on comment  keep showing open as status because this section showing ticket issue details as comment
                                    comment={this.state.viewTicketComments.issueDetails}
                                    attachments={this.state.viewTicketComments.attachments}
                                    isCommentAttachment={false}
                                    commentId={0}
                                    handleOnShowAttachment={this.handleOnShowAttachment} />
                                {
                                    this.state.viewTicketComments.comments &&
                                    this.state.viewTicketComments.comments.map((comment, i) =>
                                        <Comment
                                            key={i}
                                            isSystemReply={comment.isSystemReply}
                                            userName={comment.commentBy}
                                            commentDate={comment.commentDate}
                                            status={comment.status}
                                            comment={comment.issueDetails}
                                            attachments={comment.attachments}
                                            isCommentAttachment={true}
                                            commentId={comment.id}
                                            handleOnShowAttachment={this.handleOnShowAttachment} />
                                    )
                                }
                            </div>
                            <div className="row" style={{ display: (!this.state.viewTicketComments.isReplyClosed) || 'none' }}>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Status <span className="text-danger">*</span></label>
                                        <select name="ddissuestatus" className="form-control form-control-sm"
                                            disabled={(this.state.viewTicketComments.isReplyClosed)}
                                            value={this.state.issueStatusId} onChange={(e) => {
                                                let errors = this.state.errors;
                                                errors.issuestatus = '';
                                                this.setState({
                                                    issueStatusId: e.target.value, errors: errors
                                                })
                                            }}>
                                            {
                                                this.state.issueStatusDropdownList &&
                                                this.state.issueStatusDropdownList.map((item, i) =>
                                                    (<option key={i} value={item.id}>{item.name}</option>))
                                            }
                                        </select>
                                        {this.state.errors["issuestatus"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["issuestatus"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Comment <span className="text-danger">*</span></label>
                                        <HtmlEditor
                                            height={200}
                                            name="comment"
                                            value={this.state.comment}
                                            valueType={"html"}
                                            onValueChanged={this.handleOnCommentBodyChange}
                                            disabled={(this.state.viewTicketComments.isReplyClosed)}
                                        >
                                            <Toolbar>
                                                <Item name="undo" />
                                                <Item name="redo" />
                                                <Item name="separator" />
                                                <Item
                                                    name="size"
                                                    acceptedValues={sizeValues}
                                                />
                                                <Item
                                                    name="font"
                                                    acceptedValues={fontValues}
                                                />
                                                <Item name="separator" />
                                                <Item name="bold" />
                                                <Item name="italic" />
                                                <Item name="strike" />
                                                <Item name="underline" />
                                                <Item name="separator" />
                                                <Item name="alignLeft" />
                                                <Item name="alignCenter" />
                                                <Item name="alignRight" />
                                                <Item name="alignJustify" />
                                                <Item name="separator" />
                                                <Item name="color" />
                                                <Item name="background" />
                                            </Toolbar>
                                        </HtmlEditor>
                                        {this.state.errors["comment"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["comment"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <div className="custom-file-upload">
                                            <button type="button"
                                                className="btn btn-primary btn-sm"
                                                onClick={() => { this.fileComment.click() }}
                                                disabled={(this.state.viewTicketComments.isReplyClosed)}
                                            >Choose File</button>&nbsp;&nbsp;
                                            <span>
                                                {this.state.attachments.length === 0 ? 'No file attached' : this.state.attachments.length + ' file(s) attached'
                                                }
                                            </span>
                                            <input type="file" className="custom-file-input"
                                                ref={ref => this.fileComment = ref}
                                                name="Files" multiple
                                                style={{ display: 'none' }}
                                                onChange={(e) => { this.handleOnFileSelect(e, true) }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ display: (!this.state.viewTicketComments.isReplyClosed) || 'none' }}>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        {
                                            this.state.attachments &&
                                            this.state.attachments.map((item, i) =>
                                                (<label style={{ marginRight: '2px' }}
                                                    key={i}>{item.fileName}
                                                    <a onClick={(e) => { this.removeAttachmante(e, item.id) }}>
                                                        <span style={{ color: 'red', fontSize: '20px', cursor: 'pointer', marginRight: '6px', position: 'relative', top: '-8px' }}>
                                                            ×</span></a>
                                                </label>
                                                )
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" title="Save Comment"
                                disabled={(this.state.viewTicketComments.isReplyClosed || !this.state.viewTicketComments.allowReply)}
                                onClick={this.handleOnSaveCommentSubmitted}>
                                {this.state.isCommentSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isCommentSubmitted && <span title="Save Comment"><i className="fas fa-check"></i> Save Comment</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                disabled={this.state.viewTicketComments.isReplyClosed}
                                onClick={this.handleOnClearComment}>
                                <i className="fas fa-eraser"></i> Clear
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Cancel"
                                onClick={this.handleCloseTicketViewCommentModal}>
                                <i className="fas fa-times"></i> Cancel
                            </button>
                        </div>
                    </form>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        );
    }
}
const systemCommentHeader = {
    backgroundColor: '#d9edf7', borderRadius: '4px', borderColor: '#bce8f1', padding: '10px', color: '#3a87ad'
};
const userCommentHeader = {
    backgroundColor: '#dff0d8', borderRadius: '4px', borderColor: '#d6e9c6', padding: '10px', color: '#468847'
};
function Comment(props) {
    return (<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div style={{
            border: '1px solid transparent', boxShadow: '0 1px 1px rgb(0 0 0 /5%)', borderRadius: '4px'
            , borderColor: '#d6e9c6', marginBottom: '15px'
        }}>
            <div style={(props.isSystemReply === true) ? systemCommentHeader : userCommentHeader}>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        &nbsp;&nbsp;{props.userName}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-right">
                        {props.commentDate} &nbsp;
                        <span className="badge badge-pill badge-dark"
                            style={{ backgroundColor: (props.isSystemReply === true) ? '#3a87ad' : '#58983b' }}>
                            {props.status}
                        </span>&nbsp;&nbsp;
                    </div>
                </div>
            </div>
            <div className="form-group" style={{ padding: '10px' }}>
                {ReactHtmlParser(props.comment)}
            </div>
            <div style={{
                display: (props.attachments &&
                    props.attachments.length > 0) || 'none',
                padding: '10px 15px', backgroundColor: '#ffffff', borderTop: '1px solid #ddd',
                borderBottomLeftRadius: '3px', borderBottomRightRadius: '3px'
            }}>
                {
                    props.attachments &&
                    props.attachments.map((item, i) =>
                        (<a className="text-xs" style={{ marginRight: '10px' }}
                            key={i}
                            onClick={(e) => { props.handleOnShowAttachment(e, item.id, props.isCommentAttachment, props.commentId) }}>
                            <i className="fas fa-paperclip"
                                style={{ color: 'cornflowerblue', marginRight: '2px' }}>
                            </i>{item.fileName}
                        </a>
                        )
                    )
                }
            </div>
        </div>
    </div >);
}