import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { getRequestParams, calcTimeDiff, formatDate } from '../../helpers/globalfunctions.js';
import { TripStatus, LogType } from '../../helpers/fixcodes';
import DatePicker from "react-datepicker"; // npm install react - datepicker --save
import { LoadPanel } from 'devextreme-react/load-panel';
const customStyles = {
    content: {
        width: '35%'
    }
};
const position = { of: '#historydiv' };

let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
const session_accountId = parseInt(sessionStorage.getItem("session_accountId"));
const session_mappedAccountId1 = parseInt(sessionStorage.getItem("session_mappedAccountId1"));
const session_mappedAccountId2 = parseInt(sessionStorage.getItem("session_mappedAccountId2"));

export class ReservationActualTimeComponent extends Component {
    static displayName = ReservationActualTimeComponent.name;
    
    constructor(props) {        
        super(props);        
        this.state = {
            isOpenModal: false,
            isSubmited: false,
            loadPanelVisible: false,
            reason: "",
            rideId: 0,
            pageName: '',
            refreshRideSource: props.refreshDataSource,
            handleRefresh: props.handleRefresh,
            isFlightSTA: false,
            isSiteTime: false,
            isLoadTime: false,
            isUnloadTime: false,
            flightSTA: currentTime,
            siteTime: currentTime,
            loadTime: currentTime,
            unloadTime: currentTime,
            airlineCode: "",
            errors: {
                flightSTA: '',
                siteTime: '',
                loadTime: '',
                unloadTime: '',
            }
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.closeActualTimeModal = this.closeActualTimeModal.bind(this);
        this.afterOpenActualTimeModal = this.afterOpenActualTimeModal.bind(this);
        this.handleActualTimeReservation = this.handleActualTimeReservation.bind(this);
        this.showModal = this.showModal.bind(this);

        this.handleFlightSTAChange = this.handleFlightSTAChange.bind(this);
        this.handleSiteTimeChange = this.handleSiteTimeChange.bind(this);
        this.handleLoadTimeChange = this.handleLoadTimeChange.bind(this);
        this.handleUnLoadTimeChange = this.handleUnLoadTimeChange.bind(this);
    }

    closeActualTimeModal() {
        this.setState({
            isOpenModal: false,
            isSubmited: false,
            reason: "",
            rideId: 0,
            pageName: '',
            tripDetails: null,

            isFlightSTA: false,
            isSiteTime: false,
            isLoadTime: false,
            isUnloadTime: false,
            flightSTA: currentTime,
            assignedTime: currentTime,
            siteTime: currentTime,
            loadTime: currentTime,
            unloadTime: currentTime,
            airlineCode: "",
            errors: {
                flightSTA: '',
                siteTime: '',
                loadTime: '',
                unloadTime: '',
            }
        });
    }

    async getGetTripDetails(rideId) {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('reservation-api/' + rideId + '/reservation-actual-time', requestParams);
        const data = await response.json();        
        //var assignedTime = data.assignedTime != null ? formatDate(data.assignedTime, "MM/dd/yyyy hh:mm") : formatDate(currentTime, "MM/dd/yyyy hh:mm");
        //var onLocationTime = data.onLocationTime != null ? formatDate(data.onLocationTime, "MM/dd/yyyy hh:mm") : formatDate(currentTime, "MM/dd/yyyy hh:mm");
        //var onLoadTime = data.onLoadTime != null ? formatDate(data.onLoadTime, "MM/dd/yyyy hh:mm") : formatDate(currentTime, "MM/dd/yyyy hh:mm");
        //var onUnLoadTime = data.onUnLoadTime != null ? formatDate(data.onUnLoadTime, "MM/dd/yyyy hh:mm") : formatDate(currentTime, "MM/dd/yyyy hh:mm");        
        this.setState({
            tripDetails: data,
            //assignedTime: assignedTime,
            //siteTime: onLocationTime,
            //loadTime: onLoadTime,
            //unloadTime: onUnLoadTime,
        });
    }

    async showModal(pageName, rideId, airlineCode) {        
        this.setState({
            isOpenModal: true,
            pageName: pageName,
            rideId: rideId,
            isFlightSTA: false,
            isSiteTime: false,
            isLoadTime: false,
            isUnloadTime: false,
            assignedTime: currentTime,
            flightSTA: currentTime,
            siteTime: currentTime,
            loadTime: currentTime,
            unloadTime: currentTime,
            airlineCode: airlineCode,
        });
        await this.getGetTripDetails(rideId);
        setTimeout(() => this.handleTripTime(), 500);
    }

    handleTripTime() {

        if (this.state.tripDetails != null) {
            if (this.state.tripDetails.flightSta != null) {
                this.setState({
                    flightSTA: new Date(this.state.tripDetails.flightSta),
                });
            } else {
                this.setState({
                    flightSTA: new Date(this.state.tripDetails.pickupDateTime),
                });
            }

            if (this.state.tripDetails.assignedTime != null) {
                this.setState({
                    assignedTime: new Date(this.state.tripDetails.assignedTime),
                });
            } else {
                this.setState({
                    assignedTime: new Date(this.state.tripDetails.pickupDateTime),
                });
            }

            if (this.state.tripDetails.onLocationTime != null) {
                this.setState({
                    siteTime: new Date(this.state.tripDetails.onLocationTime),
                });
            } else {
                this.setState({
                    siteTime: new Date(this.state.tripDetails.pickupDateTime),
                });
            }

            if (this.state.tripDetails.onLoadTime != null) {
                this.setState({
                    loadTime: new Date(this.state.tripDetails.onLoadTime),
                });
            } else {
                this.setState({
                    loadTime: currentTime,
                });
            }

            if (this.state.tripDetails.onUnLoadTime != null) {
                this.setState({
                    unloadTime: new Date(this.state.tripDetails.onUnLoadTime),
                });
            } else {
                this.setState({
                    unloadTime: currentTime,
                });
            }
        }
    }

    handleFlightSTAChange(date) {
        this.setState({ flightSTA: date });
        this.state.errors["flightSTA"] = "";
    }

    handleSiteTimeChange(date) {
        this.setState({ siteTime: date });
        this.state.errors["siteTime"] = "";
    }

    handleLoadTimeChange(date) {
        this.setState({ loadTime: date });
        this.state.errors["loadTime"] = "";
    }

    handleUnLoadTimeChange(date) {
        this.setState({ unloadTime: date });
        this.state.errors["unloadTime"] = "";
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        this.state.errors[name] = "";
    }

    handleValidation() {
        //1. Assigned time should be less than On-site time
        //2. On-Site time should be less than board time
        //3. Dropped time should be greater than board time
        //4. Validate the Board time or On - Site time based on passenger pickup geolocation  and vehicle geolocation during that time(+ or – 5 minutes)
        //5. Validate the Drop time based on passenger drop - off geolocation  and vehicle geolocation during that time(+ or – 5 minutes)
        //6. Upon time change system is calculating the distance but not calculating the trip fare.

        let errors = this.state.errors;
        let formIsValid = true;
        errors.flightSTA = "";
        errors.siteTime = "";
        errors.loadTime = "";
        errors.unloadTime = "";

        if (this.state.isFlightSTA == true && this.state.flightSTA == null) {
            formIsValid = false;
            errors.flightSTA = "Please enter flight STA.";
        }

        if (this.state.isSiteTime == true && this.state.siteTime == null) {
            formIsValid = false;
            errors.siteTime = "Please enter site time.";
        }
        else {
            var assignedTimeDiffInMinutes = calcTimeDiff(this.state.siteTime, this.state.assignedTime, "M");
            var onSiteTimeDiffInMinutes = calcTimeDiff(this.state.loadTime, this.state.siteTime, "M");            
            if (assignedTimeDiffInMinutes < 0) { //Assigned time should be less than On-site time
                formIsValid = false;
                errors.siteTime = "On-site time should be greater than Assigned time";
            }
            else if (onSiteTimeDiffInMinutes < 0) { //On-Site time should be less than assigned time
                formIsValid = false;
                errors.siteTime = "On-Site time should be less than board time";
            }
        }

        if (this.state.isLoadTime == true && this.state.loadTime == null) {
            formIsValid = false;
            errors.loadTime = "Please enter load time.";
        }        

        if (this.state.isUnloadTime == true && this.state.unloadTime == null) {
            formIsValid = false;
            errors.unloadTime = "Please enter unload time.";
        }
        else {
            var droppedTimeDiffInMinutes = calcTimeDiff(this.state.unloadTime, this.state.loadTime, "M");
            if (droppedTimeDiffInMinutes < 0) { //Dropped time should be greater than board time
                formIsValid = false;
                errors.unloadTime = "Dropped time should be less than board time"; 
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    async handleActualTimeReservation(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            if (this.state.isFlightSTA == false && this.state.isSiteTime == false && this.state.isLoadTime == false && this.state.isUnloadTime == false) {
                const isValid = await Swal.fire({
                    title: "<small>Time Selection</small>",
                    text: "Please select at least one time.",
                    icon: "info",
                    dangerMode: false,
                    width: "25%",
                    dangerMode: false,
                    allowOutsideClick: false
                });
                return;
            }

            var _siteTime = "", _timeLoad = "", _timeUnLoad = "", _flightSTA = "";
            var validSiteTime = false, validLoadTime = false, validUnloadTime = false, allTimeValid = false;

            if (this.state.isFlightSTA == true) {
                //_flightSTA = this.state.flightSTA.toLocaleDateString() + " " + this.state.flightSTA.toLocaleTimeString();
                _flightSTA = formatDate(this.state.flightSTA, "MM/dd/yyyy hh:mm");
            }
            if (this.state.isSiteTime == true) {
                //_siteTime = this.state.siteTime.toLocaleDateString() + " " + this.state.siteTime.toLocaleTimeString();
                _siteTime = formatDate(this.state.siteTime, "MM/dd/yyyy hh:mm");
            }
            if (this.state.isLoadTime == true) {
                //_timeLoad = this.state.loadTime.toLocaleDateString() + " " + this.state.loadTime.toLocaleTimeString();
                _timeLoad = formatDate(this.state.loadTime, "MM/dd/yyyy hh:mm");
            }
            if (this.state.isUnloadTime == true) {
                //_timeUnLoad = this.state.unloadTime.toLocaleDateString() + " " + this.state.unloadTime.toLocaleTimeString();
                _timeUnLoad = formatDate(this.state.unloadTime, "MM/dd/yyyy hh:mm");
            }

            this.setState({ loadPanelVisible: true });
            
            if (session_mappedAccountId1 === session_accountId || session_mappedAccountId2 === session_accountId) {
                if (this.state.isSiteTime == true) {
                    var radius = 100;
                    if (this.state.tripDetails.rideTypeId === 1) {
                        radius = 500;
                    }
                    else {
                        radius = 500;
                    }
                    var newObject = new Object();
                    newObject.VehicleId = parseInt(this.state.tripDetails.vehicleId);
                    newObject.VehicleDateTime = _siteTime;
                    newObject.Latitude = parseFloat(this.state.tripDetails.pickupLatitude);
                    newObject.Longitude = parseFloat(this.state.tripDetails.pickupLongitude);
                    newObject.Radius = parseInt(radius);
                    newObject.PickupTime = formatDate(this.state.tripDetails.pickupDateTime, "MM/dd/yyyy hh:mm");
                    const requestParams = getRequestParams('POST', newObject);
                    const response = await fetch('/reservation-api/validate-vehicle-position', requestParams);
                    const data = await response.json();

                    if (data.payload.valid) {
                        validSiteTime = true;
                        allTimeValid = true;
                    }
                    else {
                        allTimeValid = false;
                        let errors = this.state.errors;
                        errors.siteTime = "";
                        errors.siteTime = "Invalid onsite time.";                        
                        this.setState({ errors: errors });
                    }
                }

                if (this.state.isLoadTime == true) {
                    var radius = 100;
                    if (this.state.tripDetails.rideTypeId === 1) {
                        radius = 500;
                    }
                    else {
                        radius = 100;
                    }
                    var newObject = new Object();
                    newObject.VehicleId = parseInt(this.state.tripDetails.vehicleId);
                    newObject.VehicleDateTime = _timeLoad;
                    newObject.Latitude = parseFloat(this.state.tripDetails.pickupLatitude);
                    newObject.Longitude = parseFloat(this.state.tripDetails.pickupLongitude);
                    newObject.Radius = parseInt(radius);
                    newObject.PickupTime = formatDate(this.state.tripDetails.pickupDateTime, "MM/dd/yyyy hh:mm");
                    const requestParams = getRequestParams('POST', newObject);
                    const response = await fetch('/reservation-api/validate-vehicle-position', requestParams);
                    const data = await response.json();

                    if (data.payload.valid) {
                        validLoadTime = true;
                        allTimeValid = true;
                    }
                    else {
                        allTimeValid = false;                    
                        let errors = this.state.errors;
                        errors.loadTime = "";
                        errors.loadTime = "Invalid onsite time.";
                        this.setState({ errors: errors });
                    }
                }
            }
            else {
                validSiteTime = validLoadTime = validUnloadTime = true;
                allTimeValid = true;
            }

            if (this.state.isUnloadTime == true) {
                var radius = 100;
                if (this.state.tripDetails.rideTypeId === 1) {
                    radius = 100;
                }
                else {
                    radius = 500;
                }
                var newObject = new Object();
                newObject.VehicleId = parseInt(this.state.tripDetails.vehicleId);
                newObject.VehicleDateTime = _timeUnLoad;
                newObject.Latitude = parseFloat(this.state.tripDetails.dropoffLatitude);
                newObject.Longitude = parseFloat(this.state.tripDetails.dropoffLongitude);
                newObject.Radius = parseInt(radius);
                newObject.PickupTime = formatDate(this.state.tripDetails.pickupDateTime, "MM/dd/yyyy hh:mm");
                const requestParams = getRequestParams('POST', newObject);
                const response = await fetch('/reservation-api/validate-vehicle-position', requestParams);
                const data = await response.json();

                if (data.payload.valid) {
                    validUnloadTime = true;
                    allTimeValid = true;
                }
                else {
                    allTimeValid = false;
                    let errors = this.state.errors;
                    errors.unloadTime = "";
                    errors.unloadTime = "Invalid unload time.";
                    this.setState({ errors: errors });
                }
            }

            this.setState({ loadPanelVisible: false });

            if (allTimeValid) {
                var newObject = new Object();
                newObject.RideId = parseInt(this.state.rideId);
                newObject.OnSite = _siteTime;
                newObject.TimeLoad = _timeLoad;
                newObject.TimeUnload = _timeUnLoad;
                newObject.FlightSta = _flightSTA;
                newObject.LogTypeId = parseInt(LogType.Reservation);
                newObject.TrackingSourceId = this.state.tripDetails.trackingSourceId;
                newObject.VehicleId = this.state.tripDetails.vehicleId;
                newObject.VehicleNo = this.state.tripDetails.vehicleNo;

                this.setState({ isSubmited: true });
                const requestParams = getRequestParams('POST', newObject);
                const response = await fetch('/reservation-api/update-reservation-actualtime', requestParams);
                const data = await response.json();
                
                if (data.isSuccess) {
                    if (this.state.airlineCode === "QP") {
                        const requestRateParams = getRequestParams('GET');
                        const rateResponse = await fetch('/manage-rides-api/rate-calculate/' + this.state.rideId, requestRateParams);
                        const dataRate = await rateResponse.json();
                    }
                };

                Swal.fire({
                    icon: data.isSuccess ? 'success' : 'error',
                    title: data.isSuccess ? 'Update Trip Actual Time' : 'Oops...',
                    text: data.resultMessage,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            this.setState({ isSubmited: false });
                            this.closeActualTimeModal();
                            this.state.handleRefresh(true);
                        }
                    } else {
                        this.setState({ isSubmited: false });
                    }
                });
            }
        }
    }

    afterOpenActualTimeModal() {
        this.setState({
            reason: "",
        });
    }

    render() {
        const filterPassedTime = time => {
            const currentDate = currentTime;
            const selectedDate = new Date(time);
            return currentDate.getTime() < selectedDate.getTime();
        }
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={customStyles}
                    className={"react-modal"}
                    onAfterOpen={this.afterOpenActualTimeModal}
                    onRequestClose={this.closeActualTimeModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleActualTimeReservation} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Update Trip Actual Time - {this.state.rideId}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeActualTimeModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">

                                        <span className="form-check">
                                            <input className="form-check-input" type="checkbox" checked={this.state.isFlightSTA} onChange={this.handleInputChange} name="isFlightSTA" />
                                            <label className="form-check-label">Enter flight STA</label>
                                            <DatePicker
                                                className="form-control form-control-sm "
                                                placeholderText=""
                                                name="flightSTA"
                                                showTimeInput
                                                selected={this.state.flightSTA}
                                                dateFormat="MM/dd/yyyy HH:mm"
                                                onChange={this.handleFlightSTAChange}
                                                filterTime={filterPassedTime}
                                            />
                                            {this.state.errors["flightSTA"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["flightSTA"]}</span>}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="checkbox" checked={this.state.isSiteTime} onChange={this.handleInputChange} name="isSiteTime" />
                                            <label className="form-check-label">Enter site time</label>
                                            <DatePicker
                                                className="form-control form-control-sm "
                                                placeholderText=""
                                                name="siteTime"
                                                showTimeInput
                                                selected={this.state.siteTime}
                                                dateFormat="MM/dd/yyyy HH:mm"
                                                onChange={this.handleSiteTimeChange}
                                                filterTime={filterPassedTime}
                                            />
                                            {this.state.errors["siteTime"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["siteTime"]}</span>}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="checkbox" checked={this.state.isLoadTime} onChange={this.handleInputChange} name="isLoadTime" />
                                            <label className="form-check-label">Enter actual pickup time</label>
                                            <DatePicker
                                                className="form-control form-control-sm "
                                                placeholderText=""
                                                name="loadTime"
                                                showTimeInput
                                                selected={this.state.loadTime}
                                                dateFormat="MM/dd/yyyy HH:mm"
                                                onChange={this.handleLoadTimeChange}
                                                filterTime={filterPassedTime}
                                            />
                                            {this.state.errors["loadTime"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["loadTime"]}</span>}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="checkbox" checked={this.state.isUnloadTime} onChange={this.handleInputChange} name="isUnloadTime" />
                                            <label className="form-check-label">Enter actual dropoff time</label>
                                            <DatePicker
                                                className="form-control form-control-sm "
                                                placeholderText=""
                                                name="unloadTime"
                                                showTimeInput
                                                selected={this.state.unloadTime}
                                                dateFormat="MM/dd/yyyy HH:mm"
                                                onChange={this.handleUnLoadTimeChange}
                                                filterTime={filterPassedTime}
                                            />
                                            {this.state.errors["unloadTime"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["unloadTime"]}</span>}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success btn-sm" onClick={this.handleActualTimeReservation} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeActualTimeModal}><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}