import React, { Component } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getRequestParams } from '../../helpers/globalfunctions';
import { LoadPanel } from 'devextreme-react/load-panel';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import { SelectBox } from 'devextreme-react/select-box';
import DateBox from 'devextreme-react/date-box';
let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class PendingPostRidesComponent extends Component {
    constructor() {
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        super();
        this.state = {
            isSubmitted: false,
            loadPanelVisible: false,
            pendingPostRidesGridDataSource: null,
            customerTypeDropdownList: [],
            customerTypeDropdownData: null,
            customerTypeId: 0,
            fromDate: moment(currentTime).format("MM/DD/YYYY"),
            toDate: moment(currentTime).format("MM/DD/YYYY"),
            errors: {
                customerType: '',
                fromDate: '',
                toDate: ''
            }
        }
    }
    async componentDidMount() {
        await this.getCustomerTypeDropdownList();
    }
    getCustomerTypeDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('customer-type-api/', requestParams);
            const data = await response.json();
            if (data !== null && data.customerType.length > 0) {
                this.setState({
                    customerTypeDropdownList: data.customerType
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnClearSearch = async () => {
        try {
            this.setState({
                isSubmitted: false,
                loadPanelVisible: false,
                pendingPostRidesGridDataSource: null,
                customerTypeDropdownData: null,
                customerTypeId: 0,
                fromDate: moment(currentTime).format("MM/DD/YYYY"),
                toDate: moment(currentTime).format("MM/DD/YYYY"),
                errors: {
                    customerType: '',
                    fromDate: '',
                    toDate: ''
                }
            });
        } catch (ex) {
            console.log();
        }
    }
    handleOnCustomerTypeDropdownChange = async (selectedOption) => {
        try {
            let errors = this.state.errors;
            errors.customerType = '';
            let customerTypeId = 0;
            let selectcustomerTypeItem = [];
            if (selectedOption !== null && selectedOption.selectedItem !== null) {
                customerTypeId = (selectedOption.selectedItem.id !== null ? selectedOption.selectedItem.id : 0);
                selectcustomerTypeItem = selectedOption.selectedItem;
            }
            else {
                //errors.customerType = 'Please select customer type.';
            }
            this.setState({ customerTypeDropdownData: selectcustomerTypeItem, customerTypeId: customerTypeId, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnFromDateChange = async (event) => {
        try {
            let errors = this.state.errors;
            errors.fromDate = '';
            let fromDate = '';
            if (event.value !== null && event.value !== '') {
                fromDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.fromDate = 'Please select date.';
            }
            if (this.state.fromDate.trim() !== '') {
                errors.fromDate = '';
            }
            this.setState({ fromDate: fromDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnToDateChange = async (event) => {
        try {
            let errors = this.state.errors;
            errors.toDate = '';
            let toDate = '';
            if (event.value !== null && event.value !== '') {
                toDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.toDate = 'Please select date.';
            }
            if (this.state.toDate.trim() !== '') {
                errors.toDate = '';
            }
            this.setState({ toDate: toDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnSearchSubmit = async event => {
        event.preventDefault();
        try {
            let isValid = true;
            let errors = this.state.errors;
            errors.customerType = '';
            errors.fromDate = '';
            errors.toDate = '';
            let fromDate = '';
            let toDate = '';
            if (Number(this.state.customerTypeId) === 0) {
                errors.customerType = 'Please select customer type.';
                isValid = false;
            }
            if (this.state.fromDate.trim() === '') {
                errors.fromDate = 'Please select date.';
                isValid = false;
            }
            else {
                fromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00';
            }
            if (this.state.toDate.trim() === '') {
                errors.toDate = 'Please select date.';
                isValid = false;
            }
            else {
                toDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59'
            }
            this.setState({ errors: errors });
            if (isValid) {
                this.setState({ isSubmitted: true, loadPanelVisible: true });
                var request = new Object();
                request.CustomerTypeId = Number(this.state.customerTypeId);
                request.FromDate = fromDate;
                request.ToDate = toDate;
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('ridestobe-posted-api/list', requestParams);
                const data = await response.json();
                this.setState({
                    pendingPostRidesGridDataSource: data.ridesToBePosted
                });
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false, loadPanelVisible: false });
    }
    render() {
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <h1 className="m-0 text-dark">Pending Post Ride(s)</h1>
                            </div>
                            <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem>
                                        <NavLink tag={Link} to="/generate/payout" className="btn btn-block btn-outline-secondary btn-sm" title="Generate Payout">
                                            <i className="fas fa-file-invoice-dollar"></i> Generate Payout</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="/post/payout" className="btn btn-block btn-outline-secondary btn-sm" title="Post Ride(s)">
                                            <i className="fas fa-check"></i> Post Ride(s)</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="/reimbursement/view" className="btn btn-block btn-outline-secondary btn-sm" title="Reimbursement View">
                                            <i className="fas fa-car"></i> Reimb. View</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="/posted/rides" className="btn btn-block btn-outline-secondary btn-sm" title="Posted Ride(s)">
                                            <i className="fas fa-check-double"></i> Posted Ride(s)</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="/reimbursement/history" className="btn btn-block btn-outline-secondary btn-sm" title="Reimbursement History">
                                            <i className="fas fa-history"></i> Reimb. History</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="/reimbursement/payperiod" className="btn btn-block btn-outline-secondary btn-sm" title="Reimbursement Pay Period">
                                            <i className="fas fa-calendar-alt"></i> Reimb. Pay Period</NavLink>
                                    </NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <form method="post" onSubmit={this.handleOnSearchSubmit} style={this.state.isSubmitted ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="rez-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>Customer Type</label>
                                        {/*<Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select Customer Type"
                                            defaultValue={[]}
                                            value={this.state.customerTypeDropdownData}
                                            name="ddcustomertype"
                                            onChange={this.handleOnCustomerTypeDropdownChange}
                                            getOptionLabel={option => option.name}
                                            getOptionValue={option => option.id}
                                            options={this.state.customerTypeDropdownList}
                                            isMulti={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            isDisabled={this.state.isAllCustomerType}
                                        />*/}
                                        <SelectBox
                                            dataSource={this.state.customerTypeDropdownList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select Customer Type"
                                            name="ddcustomertype"
                                            defaultValue={0}
                                            value={this.state.customerTypeId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnCustomerTypeDropdownChange}
                                            showClearButton={true}
                                            disabled={this.state.isAllCustomerType}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["customerType"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["customerType"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>From Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            defaultValue={this.state.fromDate}
                                            value={this.state.fromDate}
                                            onValueChanged={this.handleOnFromDateChange}
                                            width={'100%'}
                                        />
                                        {this.state.errors["fromDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["fromDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label>To Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            defaultValue={this.state.toDate}
                                            value={this.state.toDate}
                                            min={this.state.fromDate}
                                            onValueChanged={this.handleOnToDateChange}
                                            width={'100%'}
                                        />
                                        {this.state.errors["toDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["toDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label className="btn-block">&nbsp;</label>
                                        <button type="submit" className="btn btn-success btn-sm">
                                            {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin" title="In process"></i> In process</span>}
                                            {!this.state.isSubmitted && <span title="Search"><i className="fas fa-search" title="Search"></i> Search</span>}
                                        </button>&nbsp; &nbsp;
                                        <button type="button" className="btn btn-custom-gray btn-sm" title="Clear" onClick={this.handleOnClearSearch}>
                                            <i className="fas fa-eraser"></i> Clear
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="content">
                    <div className="card">
                        <div className="card-body">
                            <DataGrid
                                dataSource={this.state.pendingPostRidesGridDataSource}
                                keyExpr="reservationId"
                                selection={{ mode: 'single' }}
                                columnsAutoWidth={false}
                                showColumnLines={false}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                showBorders={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                onToolbarPreparing={this.onToolbarPreparing}>
                                <Column dataField="rideApprovedStatus" caption="Aprv" allowFiltering={true} allowSorting={true} width="50" />
                                <Column dataField="vehicleNo" caption="Vehicle" allowFiltering={true} allowSorting={true} width="75" />
                                <Column dataField="rideId" caption="Trip#" width="100" />
                                <Column dataField="paxName" caption="Pax Name" allowFiltering={true} allowSorting={true} width="75" />
                                <Column dataField="accountName" caption="Account" allowFiltering={true} allowSorting={true} width="120" />
                                <Column dataField="pUAddress" caption="PU Address" allowFiltering={true} allowSorting={true} width="150" />
                                <Column dataField="dOAddress" caption="DO Address" allowFiltering={true} allowSorting={true} width="150" />
                                <Column dataField="rideDateStr" caption="Ride Date" allowFiltering={true} allowSorting={true} width="120" />
                                <Column dataField="adultNo" caption="Pax#" allowFiltering={true} allowSorting={true} width="90" />
                                <Column dataField="driverName" caption="Driver" allowFiltering={true} allowSorting={true} width="100" />
                                <Column dataField="rideAmount" caption="Base Comm." allowFiltering={true} allowSorting={true} width="120" />
                                <Column dataField="tollAmount" caption="Toll Amount" allowFiltering={true} allowSorting={true} width="120" />
                                <Column dataField="tipAmount" caption="Tip Amount" allowFiltering={true} allowSorting={true} width="120" />
                                <Column dataField="stop" caption="Stop" allowFiltering={true} allowSorting={true} width="90" />
                                <Column dataField="waitCharge" caption="Wait Charge" allowFiltering={true} allowSorting={true} width="120" />
                                <Column dataField="oT" caption="OT" allowFiltering={true} allowSorting={true} width="90" />
                                <Column dataField="serviceFee" caption="Service Fee" allowFiltering={true} allowSorting={true} width="120" />
                                <Column dataField="fuelSurCharge" caption="Fuel Sur Charge" allowFiltering={true} allowSorting={true} width="120" />
                                <Column dataField="parkingCharge" caption="Parking Charge" allowFiltering={true} allowSorting={true} width="120" />
                                <Column dataField="extraAmount" caption="Other" allowFiltering={true} allowSorting={true} width="90" />
                                <Column dataField="grossAmount" caption="Gross" allowFiltering={true} allowSorting={true} width="90" />
                                <Column dataField="totalFare" caption="Net" allowFiltering={true} allowSorting={true} width="80" />
                                <Column dataField="tripStatusName" caption="Status" allowFiltering={true} allowSorting={true} width="80" />
                                <Column dataField="meetGreetCharge" caption="Meet Greet Charge" allowFiltering={true} allowSorting={true} width="200" />
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                <Export enabled={true} allowExportSelectedData={false} />
                                <ColumnChooser enabled={true} />
                                <ColumnFixing enabled={true} />
                            </DataGrid>
                        </div>
                    </div>
                </div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>

        )
    }
}