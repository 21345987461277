import React, { Component } from 'react';
import DateBox from 'devextreme-react/date-box';
import { SelectBox } from 'devextreme-react/select-box';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions';
import { LoadPanel } from 'devextreme-react/load-panel';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'
const position = { of: '#historydiv' };

export class VehiclePayReportSummaryDetailsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitted: false,
            isSubmittedExcel: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            chkPaymentPeriodSelection: "1",
            chkVehicleSelection: "1",
            chkReportTypeSelection: "1",
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),
            reimbursementPeriodlist: [],
            reimbursementPeriodId: '',
            vehiclesList: [],
            vehicleId: 0,
            errors: {
                vehicleId: '',
                reimbursementPeriodId: '',
            }
        }

        this.getReimbursementPeriodList = this.getReimbursementPeriodList.bind(this);
        this.getVehicles = this.getVehicles.bind(this);
        this.handlePayPeriodChange = this.handlePayPeriodChange.bind(this);
        this.handleVehicleChange = this.handleVehicleChange.bind(this);
        this.handlePayPeriodSelectionChange = this.handlePayPeriodSelectionChange.bind(this);
        this.handleVehicleSelectionChange = this.handleVehicleSelectionChange.bind(this);
        this.handleReportTypeSelectionChange = this.handleReportTypeSelectionChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFromDateChanged = this.handleFromDateChanged.bind(this);
        this.handleToDateChanged = this.handleToDateChanged.bind(this);
        this.downloadAccountPaymentTypeSummaryAsPdf = this.downloadAccountPaymentTypeSummaryAsPdf.bind(this);
        this.handleSearchAccountPaySummaryReportSubmit = this.handleSearchAccountPaySummaryReportSubmit.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getReimbursementPeriodList();
            await this.getVehicles();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {

        this.setState({ isOpenModal: false });
        await this.handleClearSearch();
    }

    async getReimbursementPeriodList() {
        try {
            var request = new Object();
            request.VehicleId = 0;
            request.VehicleOwnerId = 0;
            request.PayDate = '';
            request.ChequeNo = '';
            request.IsTransactionType = true;
            request.PayrollType = 0;
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('vehicle-payment-api/payroll-pedriod-dropdown/', requestParams);
            const data = await response.json();
            if (data !== null && data.vehiclePayRollPeriods.length > 0) {
                this.setState({
                    reimbursementPeriodlist: data.vehiclePayRollPeriods
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    }

    async getVehicles() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('vehicle-api/dropdown/0/0', requestParams);
            const data = await response.json();
            if (data !== null && data.vehiclesDropdown.length > 0) {
                this.setState({
                    vehiclesList: data.vehiclesDropdown
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    handlePayPeriodChange(selectedOption) {
        try {
            let errors = this.state.errors;
            errors.reimbursementPeriodId = '';
            let reimbursementPeriodId = '';
            if (selectedOption !== null && selectedOption.selectedItem !== null) {
                reimbursementPeriodId = selectedOption.selectedItem.payDate === null ? '' : selectedOption.selectedItem.payDate;
            }
            this.setState({ reimbursementPeriodId: reimbursementPeriodId, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }

    handleVehicleChange(selectedOption) {
        try {
            let errors = this.state.errors;
            errors.vehicleId = '';
            let vehicleId = 0;
            if (selectedOption !== null && selectedOption.selectedItem !== null) {
                vehicleId = selectedOption.selectedItem.id === null ? '' : selectedOption.selectedItem.id;
            }
            this.setState({ vehicleId: vehicleId, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }

    handlePayPeriodSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkPaymentPeriodSelection: value, reimbursementPeriodId: '' });
        this.state.errors["reimbursementPeriodId"] = "";
    }

    handleVehicleSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkVehicleSelection: value, vehicleId: 0 });
        this.state.errors["vehicleId"] = "";
    }

    handleReportTypeSelectionChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ chkReportTypeSelection: value });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value });
    }

    handleFromDateChanged(event) {
        let fromDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ startDate: fromDateValue })
    }
    handleToDateChanged(event) {
        let toDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ endDate: toDateValue })
    }
    handleSearchAccountPaySummaryReportSubmit = async (e, fileFormat) => {
        e.preventDefault();
        if (this.handleValidation()) {
            await this.downloadAccountPaymentTypeSummaryAsPdf(fileFormat);
        }
    }
    handleClearSearch() {
        this.setState({
            isSubmitted: false,
            isSubmittedExcel: false,
            isEmailSubmitted: false,
            loadPanelVisible: false,
            chkPaymentPeriodSelection: "1",
            chkVehicleSelection: "1",
            chkReportTypeSelection: "1",
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),
            reimbursementPeriodId: '',
            vehicleId: 0,
            errors: {
                vehicleId: '',
                reimbursementPeriodId: '',
            }
        });
    }

    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.vehicleId = "";
        errors.reimbursementPeriodId = "";

        if (this.state.chkPaymentPeriodSelection === "1" && this.state.reimbursementPeriodId == "") {
            formIsValid = false;
            errors.reimbursementPeriodId = "Please select pay period.";
        }

        if (this.state.chkVehicleSelection === "1" && this.state.vehicleId == 0) {
            formIsValid = false;
            errors.vehicleId = "Please select vehicle.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    async downloadAccountPaymentTypeSummaryAsPdf(fileFormat) {
        try {
            if (fileFormat === 'xlsx') {
                this.setState({ isSubmittedExcel: true });
            }
            if (fileFormat === 'pdf') {
                this.setState({ isSubmitted: true });
            }
            var RptHead = "";
            var request = new Object();
            request.LocationId = Number(sessionStorage.getItem("session_locationId"));
            if (this.state.chkPaymentPeriodSelection == "1") {
                request.StartDate = this.state.reimbursementPeriodId;
                request.EndDate = null;
                let puZone = this.state.reimbursementPeriodlist.find(opt => opt.payDate == this.state.reimbursementPeriodId);

                RptHead = RptHead + "For date " + puZone.viewDate;
            } else {
                request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy");
                request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy");
                RptHead = RptHead + "For date range " + formatDate(this.state.startDate, "MM/dd/yyyy") + " to " + formatDate(this.state.endDate, "MM/dd/yyyy");
            }

            if (this.state.chkVehicleSelection == "1") {
                request.VehicleId = parseInt(this.state.vehicleId);
                let puZone = this.state.vehiclesList.find(opt => opt.id == this.state.vehicleId);
                RptHead = RptHead + ", for Vehicle " + puZone.name;
            } else {
                request.VehicleId = parseInt(0);
                RptHead = RptHead + ", for all Vehicles";
            }

            request.Criteria1 = RptHead;

            request.fileFormat = fileFormat;
            if (this.state.chkReportTypeSelection == "1") {
                request.MethodName = "GetVehiclePayReportSummary";
            } else {
                request.MethodName = "GetVehiclePayReportDetails";
            }
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            let a = document.createElement('a');
            a.href = dataResponse.reportBase64String;
            if (this.state.chkReportTypeSelection == "1") {
                a.download = 'VehiclePaySummary.' + fileFormat;
            } else {
                a.download = 'VehiclePayDetails.' + fileFormat;
            }
            a.click();
        } catch (e) {
            console.log(e)
        }
        this.setState({
            isSubmitted: false,
            isSubmittedExcel: false,
        });
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isEmailSubmitted: true });
                var RptHead = "";
                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                if (this.state.chkPaymentPeriodSelection == "1") {
                    request.StartDate = this.state.reimbursementPeriodId;
                    request.EndDate = null;
                    let puZone = this.state.reimbursementPeriodlist.find(opt => opt.payDate == this.state.reimbursementPeriodId);

                    RptHead = RptHead + "For date " + puZone.viewDate;
                } else {
                    request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy");
                    request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy");
                    RptHead = RptHead + "For date range " + formatDate(this.state.startDate, "MM/dd/yyyy") + " to " + formatDate(this.state.endDate, "MM/dd/yyyy");
                }

                if (this.state.chkVehicleSelection == "1") {
                    request.VehicleId = parseInt(this.state.vehicleId);
                    let puZone = this.state.vehiclesList.find(opt => opt.id == this.state.vehicleId);
                    RptHead = RptHead + ", for Vehicle " + puZone.name;
                } else {
                    request.VehicleId = parseInt(0);
                    RptHead = RptHead + ", for all Vehicles";
                }

                request.Criteria1 = RptHead;

                request.fileFormat = 'pdf';
                if (this.state.chkReportTypeSelection == "1") {
                    request.MethodName = "GetVehiclePayReportSummary";
                } else {
                    request.MethodName = "GetVehiclePayReportDetails";
                }

                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Vehicle Pay Report - Summary/Details';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = (Number(this.state.chkReportTypeSelection) === 1) ? 'VehiclePaySummary.pdf' : 'VehiclePayDetails.pdf';
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '45%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form style={(this.state.isSubmitted || this.state.isSubmittedExcel || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Vehicle Pay Report - Summary/Details</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">

                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdInSinglePeriod" value="1" checked={this.state.chkPaymentPeriodSelection === "1"} onChange={this.handlePayPeriodSelectionChange} />
                                            <label className="form-check-label">Single Period</label>
                                        </span>
                                        <SelectBox
                                            dataSource={this.state.reimbursementPeriodlist}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select period"
                                            name="ddpayperiod"
                                            defaultValue={''}
                                            value={this.state.reimbursementPeriodId}
                                            displayExpr='viewDate'
                                            valueExpr='payDate'
                                            onSelectionChanged={this.handlePayPeriodChange}
                                            showClearButton={true}
                                            disabled={(this.state.chkPaymentPeriodSelection === '2')}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["reimbursementPeriodId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["reimbursementPeriodId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">&nbsp;</label>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdInRange" value="2" checked={this.state.chkPaymentPeriodSelection === "2"} onChange={this.handlePayPeriodSelectionChange} />
                                            <label className="form-check-label">In Range</label>
                                        </span>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.startDate}
                                            value={this.state.startDate}
                                            onValueChanged={this.handleFromDateChanged}
                                            width="100%"
                                            disabled={this.state.chkPaymentPeriodSelection === "1"}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label><br /></label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.endDate}
                                            value={this.state.endDate}
                                            onValueChanged={this.handleToDateChanged}
                                            width="100%"
                                            disabled={this.state.chkPaymentPeriodSelection === "1"}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="singleVehicle" value="1" checked={this.state.chkVehicleSelection === "1"} onChange={this.handleVehicleSelectionChange} />
                                            <label className="form-check-label">Single Vehicle</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="allVehicle" value="2" checked={this.state.chkVehicleSelection === "2"} onChange={this.handleVehicleSelectionChange} />
                                            <label className="form-check-label">All Vehicle</label>
                                        </span>
                                        <SelectBox
                                            dataSource={this.state.vehiclesList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select Vehicle"
                                            name="ddvehicle"
                                            defaultValue={0}
                                            value={this.state.vehicleId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleVehicleChange}
                                            showClearButton={true}
                                            disabled={(this.state.chkVehicleSelection === '2')}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["vehicleId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["vehicleId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Report Type</label><br />
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdSummary" value="1" checked={this.state.chkReportTypeSelection === "1"} onChange={this.handleReportTypeSelectionChange} />
                                            <label className="form-check-label">Summary</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="rdDetails" value="2" checked={this.state.chkReportTypeSelection === "2"} onChange={this.handleReportTypeSelectionChange} />
                                            <label className="form-check-label">Details</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="button" className="btn btn-success btn-sm" title="Download excel" onClick={(e) => this.handleSearchAccountPaySummaryReportSubmit(e, 'xlsx')}>
                                {this.state.isSubmittedExcel && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmittedExcel && <span title="Download Excel"><i className="fas fa-file-download"></i> Download Excel</span>}
                            </button>
                            <button type="button" className="btn btn-success btn-sm" title="Download" onClick={(e) => this.handleSearchAccountPaySummaryReportSubmit(e, 'pdf')} style={this.state.isSubmitted ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Download PDF"><i className="fas fa-file-download"></i> Download PDF</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                                </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        )
    }
}