import {
    Chart,
    CommonSeriesSettings,
    Export,
    Label, Legend, Series,
    SeriesTemplate, Tooltip
} from 'devextreme-react/chart';
import {
    Connector, PieChart,
    Size
} from 'devextreme-react/pie-chart';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import { LoadPanel } from 'devextreme-react/load-panel';
import { formatDate, getRequestParams } from '../../helpers/globalfunctions.js';

let currencyIcon = sessionStorage.getItem("session_currencyIcon") === "" ? "fas fa-dollar-sign" : sessionStorage.getItem("session_currencyIcon");

export class AdminDashboardComponent extends Component {
    static displayName = AdminDashboardComponent.name;
    constructor(props) {
        super(props);
        this.state = {
            type: 'line',
            completeDashboard: [],
            totalBilledAmount: parseFloat(0),
            totalDuePayment: parseFloat(0),
            totalOverDuePayment: parseFloat(0),
            totalCollectedPayment: parseFloat(0),
            totalClient: parseInt(0),
            invoiced: parseFloat(0),
            received: parseFloat(0),
            oustanding: parseFloat(0),
            top10Debtors: [],
            top10Customers: [],
            invoiceDueSummary: [],
            locationId: 0,
            locationList: [],
            loadPanelVisible: false,
            minZoomLevel: "year",
            maxZoomLevel: "year",
            dateDisplayFormat: "MMM '-' yyyy",
        }
    }
    async bindAdminDashboard(dashboardRequest) {
        //console.log("AdminDashboard child component called");        
        await this.getCompleteDashboard(dashboardRequest);
    }
    async getCompleteDashboard(dashboardRequest) {
        try {
            this.setState({ loadPanelVisible: true });
            const completeDashBoardRequest = new Object();
            completeDashBoardRequest.FromDate = formatDate(dashboardRequest.FromDate, "MM/dd/yyyy");
            completeDashBoardRequest.ToDate = formatDate(dashboardRequest.ToDate, "MM/dd/yyyy");
            completeDashBoardRequest.LocationId = dashboardRequest.LocationId;
            completeDashBoardRequest.CustomerTypeId = dashboardRequest.CustomerTypeId;
            completeDashBoardRequest.TopN = dashboardRequest.TopN;
            completeDashBoardRequest.SortDirection = dashboardRequest.SortDirection;

            const requestParams = getRequestParams('POST', completeDashBoardRequest);
            const response = await fetch('/dashboard-api/complete-dashboard/', requestParams);
            const data = await response.json();
            
            if (data != null) {
                this.setState({ completeDashboard: data });
                this.bindGraph();
            }

            this.setState({ loadPanelVisible: false });
        } catch (e) {
            //debugger;
        }
    }
    bindGraph() {
        if (this.state.completeDashboard != null) {
            if (this.state.completeDashboard.dashboardPaymentSummary != null) {

                this.setState({
                    totalBilledAmount: parseFloat(this.state.completeDashboard.dashboardPaymentSummary[1].totalBilledAmount).toFixed(2),
                    totalCollectedPayment: parseFloat(this.state.completeDashboard.dashboardPaymentSummary[1].totalCollectedPayment).toFixed(2),
                    totalDuePayment: parseFloat(this.state.completeDashboard.dashboardPaymentSummary[1].totalDuePayment).toFixed(2),
                    totalOverDuePayment: parseFloat(this.state.completeDashboard.dashboardPaymentSummary[1].totalOverDuePayment).toFixed(2),
                    totalClient: parseInt(this.state.completeDashboard.dashboardPaymentSummary[1].totalClient),
                    invoiced: parseFloat(this.state.completeDashboard.dashboardPaymentSummary[0].totalBilledAmount).toFixed(2),
                    received: parseFloat(this.state.completeDashboard.dashboardPaymentSummary[0].totalCollectedPayment).toFixed(2),
                    outstanding: parseFloat(this.state.completeDashboard.dashboardPaymentSummary[0].totalDuePayment).toFixed(2),
                });
            }

            let finaldebtors = [];
            if (this.state.completeDashboard.dashBoardTopNDebtor != null) {
                for (let i = 0; i < this.state.completeDashboard.dashBoardTopNDebtor.length; i++) {
                    var debtor = new Object();
                    debtor.CustomerName = this.state.completeDashboard.dashBoardTopNDebtor[i].customerName;
                    debtor.BilledAmount = parseFloat(this.state.completeDashboard.dashBoardTopNDebtor[i].totalBilledAmount).toFixed(2);
                    finaldebtors.push(debtor);
                }

                if (this.state.completeDashboard.dashBoardTopNDebtor.length == 0) {
                    var debtor = new Object();
                    debtor.CustomerName = "Data not found";
                    debtor.BilledAmount = parseFloat(0).toFixed(2);
                    finaldebtors.push(debtor);
                }
            }
            this.setState({ top10Debtors: finaldebtors })

            let finalcustomers = [];
            if (this.state.completeDashboard.dashBoardTopNCustomer != null) {
                for (let i = this.state.completeDashboard.dashBoardTopNCustomer.length; i > 0; i--) {
                    var customer = new Object();
                    customer.CustomerName = this.state.completeDashboard.dashBoardTopNCustomer[i - 1].customerName;
                    customer.BilledAmount = parseFloat(this.state.completeDashboard.dashBoardTopNCustomer[i - 1].totalBilledAmount).toFixed(2);
                    finalcustomers.push(customer);
                }

                if (this.state.completeDashboard.dashBoardTopNCustomer.length == 0) {
                    var customer = new Object();
                    customer.CustomerName = "Data not found";
                    customer.BilledAmount = parseFloat(0).toFixed(2);
                    finalcustomers.push(customer);
                }
            }
            this.setState({ top10Customers: finalcustomers })

            let finalInvoiceDue = [];
            if (this.state.completeDashboard.dashBoardInvoiceDue != null) {
                for (let i = 0; i < this.state.completeDashboard.dashBoardInvoiceDue.length; i++) {
                    var invoiceDue = new Object();
                    invoiceDue.Period = this.state.completeDashboard.dashBoardInvoiceDue[i].period;
                    invoiceDue.TotalDueAmount = parseFloat(this.state.completeDashboard.dashBoardInvoiceDue[i].totalDueAmount).toFixed(2);
                    finalInvoiceDue.push(invoiceDue);
                }

                if (this.state.completeDashboard.dashBoardInvoiceDue.length == 0) {
                    var invoiceDue = new Object();
                    invoiceDue.Period = "Data not found";
                    invoiceDue.TotalDueAmount = parseFloat(0).toFixed(2);
                    finalInvoiceDue.push(invoiceDue);
                }
            }
            this.setState({ invoiceDueSummary: finalInvoiceDue })
        }
    }
    scheduleVsActualDataCustomizeSeries = (valueFromNameField) => {
        return valueFromNameField >= 0 ? { type: 'line', label: { visible: true }, color: '#ff3f7a' } : {};
    }
    gtPerformanceCustomizeSeries = (valueFromNameField) => {
        return valueFromNameField >= 0 ? { type: 'line', label: { visible: true }, color: '#ff3f7a' } : {};
    }
    upcomingRidesCustomizeSeries = (valueFromNameField) => {
        return valueFromNameField >= 0 ? { type: 'line', label: { visible: true }, color: '#ff3f7a' } : {};
    }

    render() {
        return (
            <div className="content-wrapper">                
                <div>
                    <div className="content dashboard">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="d-flex justify-content-between">
                                            <h3 className="card-title mb-0 font-weight-bold text-info">Invoice Summary</h3>
                                            <span className="text-sm text-muted">{this.state.searchDateRange}</span>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-4 text-center">
                                                <h4 className="m-0 text-info font-weight-bold"><i className={currencyIcon}></i>{this.state.invoiced}</h4><p className="text-sm text-muted">Invoiced</p>
                                            </div>
                                            <div className="col-4 text-center">
                                                <h4 className="m-0 text-success font-weight-bold"><i className={currencyIcon}></i>{this.state.received}</h4><p className="text-sm text-muted">Received</p>
                                            </div>
                                            <div className="col-4 text-center">
                                                <h4 className="m-0 text-danger font-weight-bold"><i className={currencyIcon}></i>{this.state.outstanding}</h4><p className="text-sm text-muted">Outstanding</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="d-flex justify-content-between">
                                            <h3 className="card-title mb-0 font-weight-bold text-info">At a Glance</h3>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-6 col-lg-3 col-md-3 col-sm-6 text-center">
                                                <h4 className="m-0 text-info font-weight-bold"><i className={currencyIcon}></i>{this.state.totalBilledAmount}</h4><p className="text-sm text-muted">Total Outstanding</p>
                                            </div>
                                            <div className="col-6 col-lg-3 col-md-3 col-sm-6 text-center">
                                                <h4 className="m-0 text-info font-weight-bold"><i className={currencyIcon}></i>{this.state.totalOverDuePayment}</h4><p className="text-sm text-muted">Total Overdue</p>
                                            </div>
                                            <div className="col-6 col-lg-3 col-md-3 col-sm-6 text-center">
                                                <h4 className="m-0 text-info font-weight-bold"><i className={currencyIcon}></i>{this.state.totalCollectedPayment}</h4><p className="text-sm text-muted">Total Collected</p>
                                            </div>
                                            <div className="col-6 col-lg-3 col-md-3 col-sm-6 text-center">
                                                <h4 className="m-0 text-info font-weight-bold">{this.state.totalClient}</h4><p className="text-sm text-muted">Client(s)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="d-flex justify-content-between">
                                            <h3 className="card-title mb-0 font-weight-bold text-info">Top 10 Customers</h3>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <Chart
                                            id="chart"
                                            palette="Soft"
                                            dataSource={this.state.top10Customers}>
                                            <CommonSeriesSettings
                                                argumentField="CustomerName"
                                                valueField="BilledAmount"
                                                type="bar"
                                                ignoreEmptyPoints={true}
                                            />
                                            <SeriesTemplate
                                                nameField="CustomerName"
                                                valueField="BilledAmount"
                                            />
                                            <Export enabled={true} />
                                            <Tooltip
                                                enabled={true}
                                                shared={true}
                                                customizeTooltip={this.customizeTooltip}
                                            />
                                            <Legend
                                                horizontalAlignment="center"
                                            />
                                        </Chart>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="d-flex justify-content-between">
                                            <h3 className="card-title mb-0 font-weight-bold text-info">Top 10 Debtors</h3>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <PieChart
                                            id="pie"
                                            dataSource={this.state.top10Debtors}
                                            palette="Bright"
                                            onPointClick={this.pointClickHandler}
                                            onLegendClick={this.legendClickHandler}
                                        >
                                            <Series
                                                argumentField="CustomerName"
                                                valueField="BilledAmount"
                                            >
                                                <Label visible={true}>
                                                    <Connector visible={true} width={1} />
                                                </Label>
                                            </Series>
                                            <Size width={730} />
                                            <Export enabled={true} />
                                            {/*<Tooltip*/}
                                            {/*    enabled={true}*/}
                                            {/*    shared={true}*/}
                                            {/*    customizeTooltip={this.customizeTooltip}*/}
                                            {/*/>*/}
                                            {/*<Legend*/}
                                            {/*    horizontalAlignment="center"*/}
                                            {/*/>*/}
                                        </PieChart>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="d-flex justify-content-between">
                                            <h3 className="card-title mb-0 font-weight-bold text-info">Trip/Ride Summary</h3>
                                            <span className="text-sm text-muted">{this.state.searchDateRange}</span>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Location</th>
                                                    <th>Approved</th>
                                                    <th>Unapproved</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.completeDashboard && this.state.completeDashboard.dashboardValidTripSummary &&
                                                    this.state.completeDashboard.dashboardValidTripSummary.map((item, i) => (
                                                        <tr id={i} key={i}>
                                                            <td id={i}>{item.locationName}</td>
                                                            <td id={i}><span className="badge bg-success">{item.approvedTripCount}</span></td>
                                                            <td id={i}><span className="badge bg-danger">{item.unApprovedTripCount}</span></td>
                                                            <td id={i}><span className="badge bg-info">{item.totalTripCount}</span></td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="d-flex justify-content-between">
                                            <h3 className="card-title mb-0 font-weight-bold text-info">Invoice Due Summary (30-60-90)</h3>
                                            <span className="text-sm text-muted text-right">{this.state.searchDateRange}</span>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <PieChart
                                            id="pie"
                                            type="doughnut"
                                            //title="Invoice Due Summary(30-60-90)"
                                            palette="Soft Pastel"
                                            dataSource={this.state.invoiceDueSummary}
                                        >
                                            <Series argumentField="Period" valueField="TotalDueAmount">
                                                {/*<SmallValuesGrouping mode="topN" topCount={"3"} />*/}
                                                <Label
                                                    visible={true}
                                                    customizeText={this.customizeLabel}
                                                    format={"fixedPoint"}
                                                >
                                                    <Connector visible={true} width={1} />
                                                </Label>
                                            </Series>
                                            {/*<Export enabled={true} />*/}
                                            <Legend horizontalAlignment="right" verticalAlignment="top" />
                                        </PieChart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }

    customizeTooltip(pointInfo) {
        return {
            html: `<div><div class="tooltip-body"><div class="series-name"><span class='top-series-name'>${pointInfo.seriesName}</span></div><div class="value-text">
                <span class='top-series-value'>${pointInfo.value}</span></div></div></div>`
        };
    }

    customizePercentageText({ valueText }) {
        return '${valueText}';
    }
}