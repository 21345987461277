import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import RadioGroup from 'devextreme-react/radio-group';
import { SelectBox } from 'devextreme-react/select-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'
const reportTypeRadio = [
    { id: 0, name: 'Summary' },
    { id: 1, name: 'Details' },
];

export class AgentCommissionReportComponent extends Component {
    constructor() {
        super()
        this.state = {
            isSubmitted: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            reportTypeList: reportTypeRadio,
            reportType: reportTypeRadio[0],
            radAgentType: "0",
            agent: [],
            agentCode: "",
            agentName: "",
            agentId: 0,
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),
            radDateType: "0",
            errors: {
                agentId: 0,
            }
        }

        this.getAgentCommissionList = this.getAgentCommissionList.bind(this);
        this.handleReportTypeChange = this.handleReportTypeChange.bind(this);
        this.handleAgentTypeChange = this.handleAgentTypeChange.bind(this);
        this.handleDateTypeChange = this.handleDateTypeChange.bind(this);
        this.handleAgentChange = this.handleAgentChange.bind(this);
        this.handleFromDateChanged = this.handleFromDateChanged.bind(this);
        this.handleToDateChanged = this.handleToDateChanged.bind(this);
        this.handleAgentCommissionReportSubmit = this.handleAgentCommissionReportSubmit.bind(this);
        this.downloadAgentCommissionAsPdf = this.downloadAgentCommissionAsPdf.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
    }
    async componentDidMount() {

    }
    async getAgentCommissionList() {
        try {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('commission-agent-api/commission-agent-dropdown/', requestParams);
            const data = await response.json();

            this.setState({
                agent: data.commissionAgentDropdownList
            });
        } catch (e) {
            console.log(e);
        }
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleReportTypeChange(e) {
        this.setState({
            reportType: e.value
        });
    }
    handleAgentTypeChange(e) {
        this.setState({
            radAgentType: e.target.value,
            agentId: parseInt(this.state.radAgentType) == 1 ? 0 : this.state.agentId,
        });
    }
    handleDateTypeChange(e) {
        this.setState({ radDateType: e.target.value });
    }
    handleAgentChange(e) {
        this.setState({
            agentId: (e.selectedItem != null ? e.selectedItem.id : 0),
            agentCode: (e.selectedItem != null ? e.selectedItem.agentCode : ""),
            agentName: (e.selectedItem != null ? e.selectedItem.name : ""),
        })
    }
    handleFromDateChanged(event) {
        let fromDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ startDate: fromDateValue })
    }
    handleToDateChanged(event) {
        let toDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ endDate: toDateValue })
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({
                loadPanelVisible: true,
            });
            await this.getAgentCommissionList();

        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isEmailSubmitted: false,
                isOpenModal: false,
                loadPanelVisible: false,
                reportTypeList: reportTypeRadio,
                reportType: reportTypeRadio[0],
                agentCode: "",
                agentId: 0,
                agentName: "",
                startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
                endDate: new Date(),
                radDateType: "0",
                errors: {
                    agentId: 0,
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnInputChange = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
        } catch (ex) {
            console.log(ex);
        }
    }
    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.agentId = 0;

        this.setState({ message: '' });

        switch (parseInt(this.state.radAgentType)) {
            case 1:
                if (this.state.agentId == 0) {
                    formIsValid = false;
                    errors.agentId = "Please select agent";
                }
                break;
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    handleAgentCommissionReportSubmit(e) {
        e.preventDefault();
        this.downloadAgentCommissionAsPdf(e);
    }
    async downloadAgentCommissionAsPdf(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            try {
                this.setState({
                    loadPanelVisible: true,
                    isSubmited: true,
                });

                let reportHeader = "", selectionCriteria = "";
                if (this.state.reportType == "0") {
                    selectionCriteria = "";
                }
                else {
                    if (this.state.radAgentType == "1") {
                        selectionCriteria = "{Proc_Select_Commission_Activity;1.Agent_Code} ='" + this.state.agentCode + "'";
                    }
                }

                if (this.state.radAgentType == "0") {
                    reportHeader = " All Agent's";
                }
                else {
                    reportHeader = "For Agent:" + this.state.agentName;
                }

                reportHeader = reportHeader + " , Date Range: " + formatDate(this.state.startDate, "MM/dd/yyyy") + " - " + formatDate(this.state.endDate, "MM/dd/yyyy");
                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.fileFormat = 'pdf';
                request.ReportType = parseInt(this.state.reportType.id);
                request.MethodName = "GetAgentCommission";
                request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy");
                request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy");
                request.AgentCode = this.state.agentCode;
                request.Header = reportHeader;
                request.SelectionCrieteria = selectionCriteria;

                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();

                let a = document.createElement('a');
                a.href = dataResponse.reportBase64String;
                a.download = 'AgentCommission.pdf';
                a.click();

                this.setState({
                    loadPanelVisible: false,
                    isSubmited: false,
                });
            } catch (e) {
                debugger;
            }
        }
    }
    handleClearSearch() {
        this.setState({
            isSubmitted: false,
            isEmailSubmitted: false,
            loadPanelVisible: false,
            reportTypeList: reportTypeRadio,
            reportType: reportTypeRadio[0],
            agentCode: "",
            agentId: 0,
            agentName: "",
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            endDate: new Date(),
            radDateType: "0",
            errors: {
                agentId: 0,
            }
        });
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (this.handleValidation()) {
                this.setState({ isEmailSubmitted: true });
                let reportHeader = "", selectionCriteria = "";
                if (this.state.reportType == "0") {
                    selectionCriteria = "";
                }
                else {
                    if (this.state.radAgentType == "1") {
                        selectionCriteria = "{Proc_Select_Commission_Activity;1.Agent_Code} ='" + this.state.agentCode + "'";
                    }
                }

                if (this.state.radAgentType == "0") {
                    reportHeader = " All Agent's";
                }
                else {
                    reportHeader = "For Agent:" + this.state.agentName;
                }

                reportHeader = reportHeader + " , Date Range: " + formatDate(this.state.startDate, "MM/dd/yyyy") + " - " + formatDate(this.state.endDate, "MM/dd/yyyy");
                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.fileFormat = 'pdf';
                request.ReportType = parseInt(this.state.reportType.id);
                request.MethodName = "GetAgentCommission";
                request.StartDate = formatDate(this.state.startDate, "MM/dd/yyyy");
                request.EndDate = formatDate(this.state.endDate, "MM/dd/yyyy");
                request.AgentCode = this.state.agentCode;
                request.Header = reportHeader;
                request.SelectionCrieteria = selectionCriteria;

                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Agent Commission';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = 'AgentCommission.pdf'
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '40%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleOnReportSubmit}
                        style={(this.state.isSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Agent Commission'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>Report Type</label>
                                        <RadioGroup id="radio-group-sortby" dataSource={this.state.reportTypeList} displayExpr="name" value={this.state.reportType} onValueChanged={this.handleReportTypeChange} layout="horizontal" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radAgentType" value="0" checked={this.state.radAgentType === "0"} onChange={this.handleAgentTypeChange} />
                                            <label className="form-check-label">All Agent</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radAgentType" value="1" checked={this.state.radAgentType === "1"} onChange={this.handleAgentTypeChange} />
                                            <label className="form-check-label">Single Agent</label>
                                        </span>
                                        <SelectBox
                                            dataSource={this.state.agent.length > 0 ? this.state.agent : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select agent"
                                            name="driverId"
                                            defaultValue={0}
                                            value={this.state.agentId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleAgentChange}
                                            showClearButton={true}
                                            disabled={this.state.radAgentType === "1" ? false : true}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["agentId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["agentId"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label htmlFor="">&nbsp;</label>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radDateType" value="0" checked={this.state.radDateType === "0"} onChange={this.handleDateTypeChange} />
                                            <label className="form-check-label">All Period</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="radDateType" value="1" checked={this.state.radDateType === "1"} onChange={this.handleDateTypeChange} />
                                            <label className="form-check-label">In Range</label>
                                        </span>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.startDate}
                                            value={this.state.startDate}
                                            onValueChanged={this.handleFromDateChanged}
                                            width="100%"
                                            disabled={this.state.radDateType === "1" ? false : true}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <label>To Date</label>
                                        <DateBox type="date"
                                            className="form-control form-control-sm"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            useMaskBehavior={true}
                                            defaultValue={this.state.endDate}
                                            value={this.state.endDate}
                                            onValueChanged={this.handleToDateChanged}
                                            width="100%"
                                            disabled={this.state.radDateType === "1" ? false : true}
                                            acceptCustomValue={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="submit" className="btn btn-success btn-sm" title="Download" onClick={this.handleAgentCommissionReportSubmit} >
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                                </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                                </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}