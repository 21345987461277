import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import { SelectBox } from 'devextreme-react/select-box';
import Swal from 'sweetalert2';
import { LoadPanel } from 'devextreme-react/load-panel';
import { getRequestParams } from '../../helpers/globalfunctions.js';
export class CloseUndispatchRideComponent extends Component {
    static displayName = CloseUndispatchRideComponent.name;
    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
            loadPanelVisible: false,
            isSubmitted: false,
            pageName: '',
            rideIds: [],
            statusId: 0,
            vehicleDropdownList: [],
            vehicleId: 0,
            driverDropdownList: [],
            driverId: 0,
            errors: {
                vehicle: '',
                driver: '',
            }
        }
    }

    getVehicleDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('/vehicle-api/dropdown/0/0', requestParams);
            const data = await response.json();
            this.setState({
                vehicleDropdownList: data.vehiclesDropdown
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    getDriverDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('/driver-api/dropdown/', requestParams);
            const data = await response.json();
            this.setState({
                driverDropdownList: data.driversDropdownList
            });
        } catch (ex) {
            console.log(ex);
        }
    }

    showModal = (pageName, rideIds, statusId) => {
        try {
            this.setState({
                isOpenModal: true,
                isSubmitted: false,
                pageName: pageName,
                rideIds: rideIds,
                statusId: statusId,
                errors: {
                    vehicle: '',
                    driver: '',
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handelOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: false });
            await this.getVehicleDropdownList();
            await this.getDriverDropdownList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = () => {
        try {
            this.setState({
                isOpenModal: false,
                loadPanelVisible: false,
                isSubmitted: false,
                pageName: '',
                rideIds: [],
                statusId: 0,
                vehicleDropdownList: [],
                vehicleId: 0,
                driverDropdownList: [],
                driverId: 0,
                errors: {
                    vehicle: '',
                    driver: '',
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }

    handleOnVehicleDropdownChange = async (selectedOption) => {
        try {
            let errors = this.state.errors;
            errors.vehicle = '';
            let vehicleId = 0;
            if (selectedOption === null || selectedOption.selectedItem === null) {
                //errors.vehicle = 'Please select vehicle.';
            }
            else {
                vehicleId = (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0);
            }
            this.setState({ vehicleId: vehicleId, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnDriverDropdownChange = async (selectedOption) => {
        try {
            let errors = this.state.errors;
            errors.driver = '';
            let driverId = 0;
            if (selectedOption === null || selectedOption.selectedItem === null) {
                //errors.driver = 'Please select driver.';
            }
            else {
                driverId = (selectedOption.selectedItem !== null ? selectedOption.selectedItem.id : 0);
            }
            this.setState({ driverId: driverId, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    isFormValid = async () => {
        let isValid = true;
        try {
            let errors = this.state.errors;
            errors.vehicle = '';
            errors.driver = '';
            if (this.state.rideIds === null || this.state.rideIds.length === 0) {
                isValid = false;
                await this.showMessage('info', 'Invalid ride(s)', '');
            }
            if (Number(this.state.vehicleId) === 0) {
                isValid = false;
                errors.vehicle = "Please select vehicle.";
            }

            if (Number(this.state.driverId) === 0) {
                isValid = false;
                errors.driver = "Please select driver.";
            }
            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    handleOnSubmitCloseRide = async event => {
        event.preventDefault();
        try {
            if (await this.isFormValid() === true) {                
                this.setState({ isSubmitted: true });
                var request = {};
                request.Rides = this.state.rideIds;
                request.VehicleId = this.state.vehicleId;
                request.DriverId = this.state.driverId;
                request.TripStatusId = this.state.statusId;
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('ride-api/save-unclosed-rides', requestParams);
                const data = await response.json();
                await Swal.fire({
                    icon: data.isSuccess ? 'success' : 'error',
                    title: data.isSuccess ? 'Success' : 'Oops...',
                    html: data.isSuccess ? 'Ride(s) closed as ' + (Number(this.state.statusId === 116) ? 'complete' : 'cancel')+' successfully.' : 'Failed! to process the request',
                    allowOutsideClick: false,
                }).then(async (result) => {
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            await this.props.refreshCallingDatasource();
                            await this.handleOnCloseModal()
                        }
                    }
                });
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false });
    }
    showMessage = async (icon, title, resultMessage) => {
        this.setState({ loadPanelVisible: false });
        await Swal.fire({
            allowOutsideClick: false,
            showCloseButton: false,
            icon: icon,
            title: (title === null || title.trim() === '') ? 'Oops...' : title,
            html: (resultMessage === null || resultMessage.trim() === '') ? 'Opps! something went wrong, unable to process the request.' : resultMessage.trim(),
        })
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '35%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handelOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form style={this.state.isSubmitted ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{this.state.pageName}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group">
                                        <label>
                                            <span className="text-danger fa-lg">
                                                {this.state.rideIds.length > 1 ? 'Ride Id('+this.state.rideIds.length+') ' + this.state.rideIds[0] + ', ' + this.state.rideIds[1] + '...' : (this.state.rideIds.length === 1 ? 'Ride Id #' + this.state.rideIds[0] : '')}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label>Select Vehicle <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.vehicleDropdownList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select vehicle"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.vehicleId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnVehicleDropdownChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                    </div>
                                    {this.state.errors["vehicle"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["vehicle"]}</span>}
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label>Select Driver <span className="text-danger">*</span></label>
                                        <SelectBox
                                            dataSource={this.state.driverDropdownList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select driver"
                                            name="id"
                                            defaultValue={0}
                                            value={this.state.driverId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnDriverDropdownChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                    </div>
                                    {this.state.errors["driver"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["driver"]}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSubmitCloseRide} >
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                            </button>
                        </div>
                    </form>
                </Modal>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}