import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2'
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import NumberFormat from 'react-number-format';
import { LoadPanel } from 'devextreme-react/load-panel';
import { MeetGreet } from '../../helpers/fixcodes.js'

const customStyles = {
    content: {
        width: '30%',
    }
};

export class MeetAndGreeterComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
            rideId: null,
            pageName: '',
            isSubmited: false,
            refreshRideSource: props.refreshDataSource,
            handleRefresh: props.handleRefresh,
            isRideDatafound: false,
            meetGreetRide:null,
            driver: false,
            company: false,
            greeter: false,
            isGreeterName: false,
            greeterList: [],
            greeterId: 0,
            name: '',
            meetGreetCharge: 0.000,
            payGreeterCharge: 0.000,
            fromDate: null,
            toDate: null,
            allowSocketConnection: sessionStorage.getItem("session_allowSocketConnection") === "true" ? true : false,
            errors: {
                greeterId: '',
                name: '',
                meetGreetCharge: '',
                payGreeterCharge: ''
            }
        }
        this.showModal = this.showModal.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);        
        this.handleMeetAndGreeterDataSubmit = this.handleMeetAndGreeterDataSubmit.bind(this);
        this.closeMeetAndGreeterDataModal = this.closeMeetAndGreeterDataModal.bind(this);
    }
    componentDidMount = () => {
    }
    async getGreeterList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('account-api/greeter-dropdown/', requestParams);
        const data = await response.json();
        if (data !== null && data.users.length > 0) {
            this.setState({
                greeterList: data.users
            });
        }
    }
    async getMeetGreet(rideId) {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('meet-greeter-api/getMeetGreet/' + rideId, requestParams);
        const data = await response.json();
        if (data !== null && data.meetGreet !== null) {
            if (data.meetGreet.rideId > 0) {
                this.setState({
                    name: data.meetGreet.meetGreetName,
                    meetGreetCharge: data.meetGreet.meetGreetCharge,
                    payGreeterCharge: data.meetGreet.meetGreetAmount,
                });
                if (data.meetGreet.mGPayTo === MeetGreet.Greeter) {
                    this.setState({
                        greeter: true,
                    });
                    this.getGreeterList();
                } else if (data.meetGreet.mGPayTo === MeetGreet.Driver) {
                    this.setState({
                        driver: true,
                    });
                } else if (data.meetGreet.mGPayTo === MeetGreet.Company) {
                    this.setState({
                        company: true,
                    });
                } else {
                    this.setState({
                        company: true,
                    });
                }
            } else {
                await Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Ride[' + rideId + '] not found.',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.setState({ isRideDatafound: true });
                    } else {
                        this.setState({ isSubmited: false, isRideDatafound: true });
                    }
                })
            }
        }
        else {
            await Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Record not found for ride[' + rideId + '].',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.setState({ isRideDatafound: true });
                } else {
                    this.setState({ isSubmited: false, isRideDatafound: true });
                }
            })
        }
    }
    async showModal(pageName, rideId) {
        this.setState({
            isOpenModal: true,
            pageName: pageName,
            rideId: rideId,
            meetGreetRide: this.getMeetGreet(rideId),
        });
    }
    handleRadioButtonCheckedOnChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' || target.type === 'radio' ? target.checked : target.value;
        const name = target.name;
        
        if (name === "driver") {
            if (event.target.checked === true) {
                this.setState({
                    driver: event.target.checked
                    ,company: false
                    , greeter: false
                    , name: '', isRideDatafound: false
                    , isGreeterName: false
                })
            }
        } else if (name === "company") {
            if (event.target.checked === true) {
                this.setState({
                    company: event.target.checked
                    , driver: false
                    , greeter: false
                    , name: ''
                    , isRideDatafound: false
                    , isGreeterName: false
                })
            }
        } else if (name === "greeter") {
            if (event.target.checked === true) {
                this.getGreeterList();
                this.setState({
                    greeter: event.target.checked
                    , driver: false
                    , company: false
                    , name: ''
                    , isRideDatafound: true
                    , isGreeterName: false
                })
            }
        }
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let errors = this.state.errors;
        
        switch (name) {
            case 'greeterId':
                errors.greeterId = "";
                if (Number(event.target.value) === 0) {
                    this.setState({
                        greeterId: 0
                        ,name:""
                        ,isGreeterName: false
                    })
                    errors.greeterId = "Please select Greeter Name.";
                }
                else {
                    this.setState({
                        greeterId: Number(event.target.value)
                        ,name: (this.state.greeterList.filter(x => x.id === Number(event.target.value)))[0].name
                        ,isGreeterName : true
                    })
                }
                break;
            case 'name':
                errors.name = "";
                if (event.target.value === '') {
                    this.setState({ name: '' })
                    errors.name = "Please enter Name.";
                }
                else {
                    this.setState({ name: event.target.value })
                }
                break;
            case 'meetGreetCharge':
                errors.meetGreetCharge = "";
                if (event.target.value === '' || parseFloat(event.target.value) === 0) {
                    this.setState({ meetGreetCharge: 0.000 })
                    errors.meetGreetCharge = "Please enter Meet Greet Charge.";
                } else {
                    this.setState({ payGreeterCharge: 0.000, meetGreetCharge: parseFloat(event.target.value).toFixed(3) })
                }
                break;
            case 'payGreeterCharge':
                errors.payGreeterCharge = "";
                if (event.target.value === '' || parseFloat(event.target.value) === 0) {
                    this.setState({ payGreeterCharge: 0.000 })
                    errors.payGreeterCharge = "Please enter Pay Greeter Charge.";
                }
                else if (parseFloat(event.target.value) > parseFloat(this.state.meetGreetCharge)) {
                    this.setState({ payGreeterCharge: 0.000 })
                    errors.payGreeterCharge = "Please enter pay greeter charge more than meet greet charge!";
                }
                else {
                    this.setState({ payGreeterCharge: parseFloat(event.target.value).toFixed(3) })
                }
                break;
            default:
                this.setState({
                    [name]: value
                });
                break;
        }
    }
    OpenMeetAndGreeterDataModal = () => {
    }
    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.greeterId = "";
        errors.name = "";
        errors.meetGreetCharge = "";
        errors.payGreeterCharge = "";

        if (this.state.company === true) {
            if (this.state.name === '') {
                formIsValid = false;
                errors.name = "Please enter company name!";
            }
        } else if (this.state.driver === true) {
            if (this.state.name === '') {
                formIsValid = false;
                errors.name = "Please enter driver's name!";
            }
        } else if (this.state.greeter == true) {
            if (this.state.greeterList.length > 0) {
                if (Number(this.state.greeterId) === 0) {
                    formIsValid = false;
                    errors.greeterId = "Please select Greeter name.";
                }
                if (this.state.name === '') {
                    formIsValid = false;
                    errors.name = "Please enter Greeter's name!";
                }
            } else {
                if (this.state.name === '') {
                    formIsValid = false;
                    errors.name = "Please enter Greeter's name!";
                }
            }

        }
        if (parseFloat(this.state.meetGreetCharge) === 0) {
            formIsValid = false;
            errors.meetGreetCharge = "Please enter Meet Greet Charge.";
        }
        if (parseFloat(this.state.payGreeterCharge) === 0) {
            formIsValid = false;
            errors.payGreeterCharge = "Please enter Pay Greeter Charge.";
        }
        return formIsValid;
    }
    async handleMeetAndGreeterDataSubmit(e) {
        e.preventDefault();
        this.setState({ isSubmited: true });
        e.preventDefault();
        if (this.handleValidation()) {

            let isResult = false;
            await Swal.fire({
                title: 'Set Meet & Greeter Name',
                html: 'Are you sure to set following details for this Ride. Meet & Greeter :"' + this.state.name + '" Amount to pay Greeter: $' + parseFloat(this.state.payGreeterCharge).toFixed(3),
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }).then((result) => {
                if (result.isConfirmed) {
                    isResult = true;
                } else {
                    this.setState({ isSubmited: false });
                }
            })

            if (isResult) {
                let MeetGreetId = 0;
                let MGPayTo = 0;

                if (this.state.greeter === true) {
                    MGPayTo = MeetGreet.Greeter;
                    if (this.state.greeterId > 0) {
                        MeetGreetId = this.state.greeterId;
                    }

                } else if (this.state.driver === true) {
                    MGPayTo = MeetGreet.Driver;

                } else if (this.state.company === true) {
                    MGPayTo = MeetGreet.Company;
                } else {
                    MGPayTo = MeetGreet.Company;
                }
                var newObject = new Object();
                newObject.RideId = this.state.rideId;
                newObject.MGPayTo = MGPayTo;
                newObject.MeetGreetId = MeetGreetId;
                newObject.MeetGreetName = this.state.name;
                newObject.MeetGreetAmount = parseFloat(this.state.payGreeterCharge);
                newObject.IsSetMeetGreet = true;
                newObject.DispatcherId = parseInt(sessionStorage.getItem("session_userId"));

                const requestParams = getRequestParams('POST', newObject);
                const response = await fetch('meet-greeter-api/SaveUpdate', requestParams);
                const dataResult = await response.json();
                Swal.fire({
                    icon: dataResult.isSuccess ? 'success' : 'error',
                    title: dataResult.isSuccess ? 'Success' : 'Oops...',
                    text: dataResult.resultMessage,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (dataResult.isSuccess) {
                        if (result.isConfirmed) {
                            this.state.refreshRideSource();
                            this.closeMeetAndGreeterDataModal();
                        }
                    } else {
                        this.setState({ isSubmited: false })
                        this.state.refreshRideSource();
                    }
                })
            } else {
                this.setState({ isSubmited: false })
            }
        } else {
            this.setState({ isSubmited: false })
        }
    }
    closeMeetAndGreeterDataModal = () => {        
        this.setState({
            isOpenModal: false,
            rideId: null,
            pageName: '',
            isSubmited: false,
            driver: false,
            company: false,
            greeter: false,
            greeterList: [],
            greeterId: 0,
            name: '',
            meetGreetCharge: 0.000,
            payGreeterCharge: 0.000,
            errors: {
                greeterId: '',
                name: '',
                meetGreetCharge: '',
                payGreeterCharge: ''
            }
        });

        if (!this.state.allowSocketConnection) {
            if (this.state.handleRefresh != undefined) {
                this.state.handleRefresh(true);
            }

            if (this.state.refreshRideSource != undefined) {
                this.state.refreshRideSource();
            }
        }
    }
    render() {
        return (<div>
            <Modal ariaHideApp={false}
                isOpen={this.state.isOpenModal}
                style={customStyles}
                className={"react-modal"}
                onAfterOpen={this.OpenMeetAndGreeterDataModal}
                onRequestClose={this.closeMeetAndGreeterDataModal}
                shouldCloseOnOverlayClick={false}
                contentLabel="Example Modal">
                <div className="modal-header">
                    <h6 className="modal-title">{this.state.pageName}</h6>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeMeetAndGreeterDataModal}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <form method="post" onSubmit={this.handleMeetAndGreeterDataSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="row">
                            <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                                <div className="form-group">
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input className="form-check-input" type="radio" style={{ marginTop: "0rem" }} name="driver" checked={this.state.driver} plassceholder="" onChange={this.handleRadioButtonCheckedOnChange} />
                                                                        Driver</label>
                                    </div>
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input className="form-check-input" type="radio" style={{ marginTop: "0rem" }} name="company" checked={this.state.company} plassceholder="" onChange={this.handleRadioButtonCheckedOnChange} />
                                                                        Company</label>
                                    </div>
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input className="form-check-input" type="radio" style={{ marginTop: "0rem" }} name="greeter" checked={this.state.greeter} plassceholder="" onChange={this.handleRadioButtonCheckedOnChange} />
                                                                        Greeter</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="form-group" style={{ display: this.state.greeter === true && this.state.greeterList.length > 0 ? "inline" : "none" }}>
                                    <label>Greeter</label>
                                    <select className="form-control form-control-sm" name="greeterId" value={this.state.greeterId} onChange={this.handleInputChange} >
                                        <option value={0}>---Select Greeter---</option>
                                        {
                                            this.state.greeterList && this.state.greeterList.map((item, i) =>
                                                <option key={i} value={item.id}>{item.name}</option>)
                                        }
                                    </select>
                                    {this.state.errors["greeterId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["greeterId"]}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="form-group">
                                    <label>Name</label>
                                    <input type="text" className="form-control form-control-sm" name="name" placeholder="Name" value={this.state.name} onChange={this.handleInputChange} disabled={this.state.isGreeterName} />
                                    {this.state.errors["name"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["name"]}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="form-group">
                                    <label>Meet Greet Charge</label>
                                    <NumberFormat className="form-control form-control-sm" name="meetGreetCharge" fixedDecimalScale={true} maxLength={8} decimalScale={3} title="Meet Greet Charge" value={this.state.meetGreetCharge} onChange={this.handleInputChange} disabled="true" />
                                    {this.state.errors["meetGreetCharge"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["meetGreetCharge"]}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="form-group">
                                    <label>Pay Greeter Charge</label>
                                    <NumberFormat className="form-control form-control-sm" name="payGreeterCharge" fixedDecimalScale={true} maxLength={8} decimalScale={3} title="Pay Greeter Charge" value={this.state.payGreeterCharge} onChange={this.handleInputChange} />
                                    {this.state.errors["payGreeterCharge"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["payGreeterCharge"]}</span>}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  text-right">
                            <button type="submit" className="btn btn-success btn-sm" onClick={this.handleMeetAndGreeterDataSubmit}>
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Save"><i className="fas fa-save"></i> Save</span>}
                            </button>&nbsp;&nbsp;
                                     <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeMeetAndGreeterDataModal} title="Close"><i className="fas fa-eraser"></i> Close</button>
                        </div>
                    </div>
                </div>
            </Modal></div>)
    }
}