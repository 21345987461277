import React, { Component, Fragment } from 'react';
import * as XLSX from 'xlsx';
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
}
    from 'devextreme-react/data-grid';
//import { NavItem, NavLink } from 'reactstrap';
//import { Link } from 'react-router-dom';
//import { DateFormat } from '../../helpers/fixcodes.js';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { LoadPanel } from 'devextreme-react/load-panel';
//import { SelectBox } from 'devextreme-react/select-box';
import Swal from 'sweetalert2';

const position = { of: '#historydiv' };
const fileType = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
const basedate = new Date(1899, 11, 30, 0, 0, 0);
const dnthresh = basedate.getTime() + (new Date().getTimezoneOffset() - basedate.getTimezoneOffset()) * 60000;
const day_ms = 24 * 60 * 60 * 1000;
const days_1462_ms = 1462 * day_ms;

export class VehicleBulkComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSubmited: false,
            excelFile: "",
            excelData: null,
            excelJsonData: "",
            isReservationDisabled: true,
            errors: {
                attachment: '',
                excelFileError: "",
            },
        }
    }

    handleFormValidation = () => {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.attachment = '';
        if (this.state.attachment === null) {
            formIsValid = false;
            errors.attachment = "Please select attachment.";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    handleFile = (e) => {
        e.preventDefault();
        let selectedFile = e.target.files[0];
        if (selectedFile) {
            if (selectedFile && fileType.includes(selectedFile.type)) {
                let reader = new FileReader();
                reader.readAsArrayBuffer(selectedFile);
                reader.onload = (e) => {
                    this.setState({
                        excelFile: e.target.result,
                        attachment: e.target.result
                    });
                }
            }
            else {
                // setExcelFileError('Please select only excel file types');
                this.setState({
                    excelFile: null,
                    attachment: ""
                });
            }
        }
        else {
            //console.log('plz select your file');
        }
    }

    // submit function
    handleSubmit = (e) => {
        e.preventDefault();
        if (this.handleFormValidation()) {
            if (this.state.excelFile !== null) {
                const workbook = XLSX.read(this.state.excelFile, { type: 'buffer', cellDates: true, });
                const worksheetName = workbook.SheetNames[0];                
                const worksheet = workbook.Sheets[worksheetName];
                const data = XLSX.utils.sheet_to_json(worksheet, { defval: "" });
                console.log(data);

                if (data != null) {
                    let jsonExcelData = JSON.stringify(data);
                    // console.log(data);
                    console.log(JSON.stringify(jsonExcelData));
                    this.setState({
                        excelData: data,
                        excelJsonData: jsonExcelData,
                        isReservationDisabled: false,
                    });
                }
            }
            else {
                this.setState({
                    excelData: null
                });
            }
        }
    }

    handleClearControls = (e) => {
        window.location.href = "vehicle/bulk";
    }

    handleSaveReservations = async (e) => {
        e.preventDefault();
        //if (this.handleValidation())
        {
            this.setState({
                isSubmited: true
            })
            if (this.state.excelData != null) {
                var request = new Object();
                request.AccountId = parseInt(sessionStorage.getItem('session_accountId'));
                request.LocationId = parseInt(sessionStorage.getItem('session_locationId'));
                request.LoginUserId = parseInt(sessionStorage.getItem('session_userId'));
                request.JsonData = this.state.excelJsonData;

                const requestParams = getRequestParams("POST", request);
                //console.log(requestParams);
                const response = await fetch('vehicle-api/bulk/insert', requestParams);
                const data = await response.json();
                Swal.fire({
                    icon: data.isSuccess ? 'success' : 'error',
                    title: data.isSuccess ? '<small>Import Vehicle</small>' : '<small>Oops...</small>',
                    text: data.resultMessage,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (data.isSuccess) {
                        //this.clearControls();
                        window.location.href = "/vehicle/list";
                    } else {
                        this.setState({ isSubmited: false })
                    }
                });
            }
        }
    }

    handleValidation = () => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.customerId = "";

            // if (this.state.serviceProviderId === 0) {
            //     formIsValid = false;
            //     errors.serviceProviderId = "Please select service provider";
            // }
            //if (this.state.customerId === 0) {
            //    formIsValid = false;
            //    errors.customerId = "Please select customer";
            //}
            this.setState({ errors: errors });
        } catch (err) {
            console.log("Error in " + this.displayName + ".isFormhandleValidationValid function", err);
            formIsValid = false;
        }
        return formIsValid;
    }

    render() {
        return (
            <Fragment>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <h1 className="m-0 text-dark">Import Vehicles</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="card card-grid">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label>Select File</label>
                                            <form className='form-group' autoComplete="off" onSubmit={this.handleSubmit}>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <input type='file' className='form-control' onChange={this.handleFile} required></input>
                                                        {this.state.errors["attachment"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["attachment"]}</span>}
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <button type='submit' className='btn btn-dark btn-sm'>Submit</button>&nbsp;&nbsp;
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label>&nbsp;</label><br />
                                            <button type='submit' className='btn btn-default btn-sm' disabled={this.state.isReservationDisabled} onClick={this.handleSaveReservations}>
                                                {this.state.isSubmited && <span><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                                {!this.state.isSubmited && <span><i className="fas fa-check"></i> Save </span>}
                                            </button>&nbsp;
                                            <button type='submit' className='btn btn-default btn-sm' onClick={this.handleClearControls}><i className="fas fa-eraser"></i> Clear</button>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <DataGrid
                                        dataSource={this.state.excelData}
                                        selection={{ mode: 'single' }}
                                        columnsAutoWidth={false}
                                        showColumnLines={false}
                                        showRowLines={true}
                                        showBorders={true}
                                        rowAlternationEnabled={true}
                                        allowColumnResizing={true}
                                        columnResizingMode='widget'
                                        onToolbarPreparing={this.onToolbarPreparing}>
                                        <Column dataField="Name" caption="Vehicle No" width={250} />
                                        <Column dataField="Gateway" caption="Gateway" width={200} />
                                        <Column dataField="GatewaySerial" caption="Gateway Serial" width={350} />
                                        <Column dataField="LicensePlate" caption="License Plate" width={400} />
                                        <Column dataField="VIN" caption="VIN" width={350} />
                                        <Column dataField="Tags" caption="Tags" width={200} />
                                        <Column dataField="VehicleType" caption="Vehicle Type" width={200} />
                                        <GroupPanel visible={true} />
                                        <HeaderFilter visible={false} />
                                        <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                        <Grouping autoExpandAll={true} />
                                        <Paging defaultPageSize={20} />
                                        <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true} />
                                        <Export enabled={true} allowExportSelectedData={false} fileName="ImportVehicle.xlsx" />
                                    </DataGrid>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </Fragment>
        )
    }
}