import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import { TripStatus, LogType } from '../../helpers/fixcodes';
const customStyles = {
    content: {
        width: '35%'
    }
};

export class VoidReservationComponent extends Component {
    static displayName = VoidReservationComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
            isSubmited: false,
            reason: "",
            voidRideId: 0,
            refreshRideSource: props.refreshDataSource,
            pageName: '',
            pageSource:'',
            isVoid: false,
            errors: {
                reason: '',
            }
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.closeVoidReservationModal = this.closeVoidReservationModal.bind(this);
        this.afterOpenVoidReservationModal = this.afterOpenVoidReservationModal.bind(this);
        this.handleVoidReservation = this.handleVoidReservation.bind(this);
    }

    closeVoidReservationModal() {
        this.setState({
            isOpenModal: false,
            isSubmited: false,
            reason: "",
            voidRideId: 0,
            pageName: '',
            pageSource: '',
            isVoid: false,
            errors: {
                reason: '',
            }
        });
    }

    showModal(pageName, rideId, pageSource) {
        this.setState({
            isOpenModal: true,
            pageName: pageName,
            voidRideId: rideId,
            isVoid: pageName === "Void Reservation" ? true : false,
            pageSource: pageSource
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        this.state.errors[name] = "";
    }

    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.reason = "";

        if (this.state.reason.trim() == '') {
            formIsValid = false;
            errors.reason = "Please enter reason.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    async handleVoidReservation(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            this.setState({ isSubmited: true });
            var rideStatusRequest = new Object();
            if (this.state.isVoid == true) {
                rideStatusRequest.TripStatusId = parseInt(TripStatus.CANCELLED);
            } else {
                rideStatusRequest.TripStatusId = parseInt(TripStatus.PENDING);
            }
           
            rideStatusRequest.CancelReason = this.state.reason;
            rideStatusRequest.LogTypeId = parseInt(LogType.Reservation);

            const requestParams = getRequestParams('PUT', rideStatusRequest);
            const response = await fetch('/ride-api/' + this.state.voidRideId + '/status', requestParams);
            const data = await response.json();

            Swal.fire({
                icon: data.isSuccess ? 'success' : 'error',
                title: data.isSuccess ? 'Void / Recall Reservation' : 'Oops...',
                text: data.resultMessage,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (data.isSuccess) {
                    if (result.isConfirmed) {
                        this.setState({ isSubmited: false });
                        if (this.state.pageSource === "New Reservation") {
                            this.props.goToBackPage();
                        } else if (this.state.pageSource === "Dispatch") {
                            this.state.refreshRideSource();
                        }
                        
                    }
                } else {
                    this.setState({ isSubmited: false });
                }
            });
        }
    }

    afterOpenVoidReservationModal() {
        this.setState({
            reason: "",
        });
    }

    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={customStyles}
                    className={"react-modal"}
                    onAfterOpen={this.afterOpenVoidReservationModal}
                    onRequestClose={this.closeVoidReservationModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleVoidReservation} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{this.state.pageName}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeVoidReservationModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group">
                                        <label>Reason <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="reason" placeholder="Reason" maxLength="250" title="Reason" value={this.state.reason} onChange={this.handleInputChange} />
                                        {this.state.errors["reason"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["reason"]}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success btn-sm" onClick={this.handleVoidReservation} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeVoidReservationModal} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
            </div>
        );
    }
}