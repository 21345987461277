import React, { Component } from 'react';

import { getRequestParams, formatDate } from '../../helpers/globalfunctions';

export class TrackingComponent extends Component {
    static displayName = TrackingComponent.name;

    constructor(props) {
        super(props);

        this.state = {
            loadPanelVisible: false,
            mapUrl: "",
        }

        this.getMapUrl = this.getMapUrl.bind(this);
    }

    componentDidMount() {
        this.getMapUrl();
    }

    async getMapUrl() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('helper/map-url', requestParams);
        const data = await response.json();

        this.setState({
            mapUrl: data.resultMessage,
        });
    }

    render() {
        const url = this.state.mapUrl + "?accountId=" + parseInt(sessionStorage.getItem('session_accountId')) +"&locationId=" + parseInt(sessionStorage.getItem('session_locationId'))+"&userId=0&vgTypeId=0&rideId=0";
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <h1 className="m-0 text-dark">Tracking</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content" style={{ height:79  + 'vh' }}>
                    <iframe width="100%" height="100%" frameBorder="0" scrolling="no" allowtransparency="true" src={url}></iframe>
                </div>
            </div>
        );
    }
}