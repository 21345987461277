import React, { Component } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import { SelectBox } from 'devextreme-react/select-box';
import DatePicker from "react-datepicker"; // npm install react - datepicker --save
import { DateFormat } from '../../helpers/fixcodes';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { LoadPanel } from 'devextreme-react/load-panel';
export class DriverWorkHourHistoryListComponent extends Component {
    constructor() {
        super()
        this.state = {
            isFilterSubmitted: false,
            loadPanelVisible: false,
            driverWorkHourGridDataSource: null,
            driversDropdownList: [],
            driverId: 0,
            searchFromDate: new Date(),
            searchToDate: new Date(),
            isDriverAllChecked: true,
            isDateAllChecked: false,
        }
    }
    componentDidMount() {
        this.fetchApiData();
    }
    getDriverWorkHourList = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            var request = new Object();
            if (this.state.isDateAllChecked === false) {
                let fromDate = formatDate(this.state.searchFromDate, DateFormat.DefaultDateTimeFormat) + " 00:00:00";
                let toDate = formatDate(this.state.searchToDate, DateFormat.DefaultDateTimeFormat) + " 23:59:59";
                request.FromDate = fromDate;
                request.ToDate = toDate;
            }
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('driver-api/' + parseInt(this.state.driverId) + '/working-hours', requestParams);
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    driverWorkHourGridDataSource: data.workingHours
                });
            }
            else {
                Swal.fire({
                    allowOutsideClick: false,
                    showCloseButton: true,
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Unable to process the request.',
                })
            }
        } catch (ex) {
            console.log(ex);
            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Unable to process the request.',
            })
        }
        this.setState({ loadPanelVisible: false });
    }
    getDriversDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/driver-api/dropdown/', requestParams);
            const data = await response.json();
            this.setState({
                driversDropdownList: data.driversDropdownList
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    fetchApiData = async () => {
        try {
            await this.getDriverWorkHourList();
            this.setState({ loadPanelVisible: true });
            await this.getDriversDropdownList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getDriverWorkHourList.bind(this)
            }
        });
    }
    handleFilterOnInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        switch (name) {
            case 'isDriverAllChecked':
                this.setState({ isDriverAllChecked: value, driverId: 0 });
                break;
            case 'isDateAllChecked':
                this.setState({ isDateAllChecked: value });
                break;
            default:
                this.setState({
                    [name]: value
                });
        }
    }
    handleSearchFromDateChange = (date) => {
        this.setState({ searchFromDate: date });
    }
    handleSearchToDateChange = (date) => {
        this.setState({ searchToDate: date });
    }
    handleDriverOnChange = (selectedOption) => {
        this.setState({ driverId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
    }
    handleSearch = async () => {
        try {
            this.setState({ isFilterSubmitted: true });
            await this.getDriverWorkHourList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isFilterSubmitted: false });
    }
    handleClearSearch = () => {
        this.setState({
            isFilterSubmitted: false,
            loadPanelVisible: false,
            driverId: 0,
            searchFromDate: new Date(),
            searchToDate: new Date(),
            isDriverAllChecked: true,
            isDateAllChecked: false,
        });
    }
    render() {
        const filterSearchFromDate = time => {
            const currentDate = new Date();
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }
        const filterSearchToDate = time => {
            const currentDate = new Date();
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <h1 className="m-0 text-dark">Driver Work Hour</h1>
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem><NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="#" onClick={this.getDriverWorkHourList} title="Refresh"><i className="fas fa-sync-alt"></i> Refresh</NavLink></NavItem>
                                    <NavItem><NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="/loggeddrivers/list" title="Dispatch"><i className="fas fa-check"></i> Logged Drivers</NavLink></NavItem>
                                    <NavItem><NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="/driver/login/history" title="Dispatch"><i className="fas fa-check"></i> Driver Login History</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rez-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <div className="form-group">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="isDriverAllChecked" name="isDriverAllChecked" checked={this.state.isDriverAllChecked} onChange={this.handleFilterOnInputChange} />
                                        <label className="form-check-label" htmlFor="isDriverAllChecked"> All Driver</label>
                                    </div>
                                    <SelectBox
                                        dataSource={this.state.driversDropdownList.length > 0 ? this.state.driversDropdownList : []}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select driver"
                                        name="id"
                                        defaultValue={0}
                                        value={this.state.driverId}
                                        displayExpr='name'
                                        valueExpr='id'
                                        onSelectionChanged={this.handleDriverOnChange}
                                        showClearButton={true}
                                        disabled={this.state.isDriverAllChecked}
                                        searchEnabled={true}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <div className="form-group">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="isDateAllChecked" name="isDateAllChecked" checked={this.state.isDateAllChecked} onChange={this.handleFilterOnInputChange} />
                                        <label className="form-check-label" htmlFor="isDateAllChecked"> All Date <i className="txt-xs">(If not select FROM and TO date)</i></label>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <DatePicker
                                                    className="form-control form-control-sm "
                                                    placeholderText="Click to select date"
                                                    name="searchFromDate"
                                                    //showTimeInput
                                                    selected={this.state.searchFromDate}
                                                    dateFormat={DateFormat.DefaultDateTimeFormat}
                                                    onChange={this.handleSearchFromDateChange}
                                                    filterTime={filterSearchFromDate}
                                                    disabled={this.state.isDateAllChecked}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <DatePicker
                                                    className="form-control form-control-sm "
                                                    placeholderText="Click to select date"
                                                    name="searchToDate"
                                                    //showTimeInput
                                                    selected={this.state.searchToDate}
                                                    dateFormat={DateFormat.DefaultDateTimeFormat}
                                                    onChange={this.handleSearchToDateChange}
                                                    filterTime={filterSearchToDate}
                                                    disabled={this.state.isDateAllChecked}
                                                    startDate={this.state.searchFromDate}
                                                    minDate={this.state.searchFromDate}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <br />
                                <button className="btn btn-success btn-sm" onClick={this.handleSearch}>
                                    {this.state.isFilterSubmitted && <span><i className="fas fa-spinner fa-spin"></i> In Process</span>}
                                    {!this.state.isFilterSubmitted && <span><i className="fas fa-search"></i> Search</span>}
                                </button>&nbsp;&nbsp;
                                    <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleClearSearch}><i className="fas fa-times"></i> Clear</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="card card-grid">
                        <div className="card-body">
                            <DataGrid
                                dataSource={this.state.driverWorkHourGridDataSource}
                                keyExpr="id"
                                selection={{ mode: 'single' }}
                                columnsAutoWidth={false}
                                showColumnLines={true}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                onToolbarPreparing={this.handleToolbarPreparing}
                            >
                                <Column dataField="driverName" caption="Driver Name" />
                                <Column dataField="workDateDisplay" caption="Work Day" />
                                <Column dataField="dayLastLoginDatetime" caption="Login Datetime" />
                                <Column dataField="dayLastLogoutDatetime" caption="Logout Datetime" />
                                <Column dataField="multipleLoginLogoutCount" caption="Multiple Login#" />
                                <Column dataField="dailyWorking" caption="Daily Work Hours" />
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                <Export enabled={true} allowExportSelectedData={false} />
                                <ColumnChooser enabled={true} />
                                <ColumnFixing enabled={true} />
                            </DataGrid>
                        </div>
                    </div>
                </div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}