import React, { Component } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import { LoadPanel } from 'devextreme-react/load-panel';
import Modal from 'react-modal'; //npm install --save react-modal
import { StatusType, DateFormat, TripStatus } from '../../helpers/fixcodes';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import { getRequestParams } from '../../helpers/globalfunctions.js';

const customStyles = {
    content: {
        width: '70%'
    }
};

const position = { of: '#historydiv' };

export class ReservationHistoryComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
            isSubmited: false,
            historydatasource: null,
            rideId: 0,
            pageName: '',
            loadPanelVisible: false,
            refreshRideSource: props.refreshDataSource,
            handleRefresh: props.handleRefresh,
            allowSocketConnection: sessionStorage.getItem("session_allowSocketConnection") === "true" ? true : false,
        }

        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.getReservationHistory = this.getReservationHistory.bind(this);
    }

    closeModal() {
        this.setState({
            isOpenModal: false,
            isSubmited: false,
            pageName: '',
        });        
        if (!this.state.allowSocketConnection) {
            if (this.state.refreshRideSource != undefined) {
                this.state.handleRefresh(true);
                this.state.refreshRideSource();
            }
        }
    }

    showModal(pageName, rideId) {        
        this.setState({
            isOpenModal: true,
            pageName: pageName,
            rideId: rideId,
            historydatasource: this.getReservationHistory(rideId, pageName),
        });
    }

    //componentDidUpdate(prevProps, prevState) {
    //    debugger;
    //    if (this.state.rideId !== prevState.rideId) {
    //        this.getReservationHistory();
    //    }
    //}

    //componentDidMount() {
    //    this.getReservationHistory();      
    //}

    async getReservationHistory(rideId, pageName) {
        try {
            this.setState({
                loadPanelVisible: true
            });
            var logtypeId = 0;
            var reservationId = 0;
            if (pageName === "Quotation") {
                logtypeId = 5;
            } else if (pageName === "Billing") {
                logtypeId = 3;
                reservationId = rideId;
                rideId = 0;
            }
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('reservation-api/' + reservationId + '/history/' + rideId + '/' + logtypeId, requestParams);
            const data = await response.json();

            this.setState({
                historydatasource: data.logs,
                loadPanelVisible: false
            });

        } catch (e) {
            console.log(e);
        }
    }

    render() {

        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={customStyles}
                    className={"react-modal"}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form>
                        <div className="modal-header">
                            <h6 className="modal-title">{this.state.pageName} - <strong><i>{this.state.rideId}</i></strong></h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body" style={{ padding: "1rem" }}>
                            <DataGrid
                                id="reservationhistory-grid-container"
                                dataSource={this.state.historydatasource}
                                columnsAutoWidth={true}
                                showColumnLines={true}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                showBorders={true}>

                                <Column dataField="description" caption="Description" width={550} allowFiltering={false} allowSorting={false} />
                                <Column dataField="logType" caption="Log Type" width={100} allowFiltering={false} allowSorting={false} />
                                <Column dataField="logDate" caption="Log Date" width={140} allowFiltering={true} allowSorting={true} format={DateFormat.DateTime24Format} dataType="datetime" />
                                <Column dataField="logByUser" caption="User" width={150} allowFiltering={false} allowSorting={false} />
                                <Column dataField="machineIp" caption="Machine Ip" width={140} allowFiltering={false} allowSorting={false} />

                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={false} />
                                <HeaderFilter visible={true} />
                                <SearchPanel visible={false} highlightCaseSensitive={false} width={600} placeholder="Search..." />
                                <Grouping autoExpandAll={false} />
                                <Paging defaultPageSize={10} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true} />
                                <Export enabled={false} allowExportSelectedData={false} />
                                <ColumnChooser enabled={false} />
                                <ColumnFixing enabled={true} />

                            </DataGrid>
                            {/*<div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <small>Below are the history for {this.state.pageName} <i><strong className="text-danger"></strong></i>.</small>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="widget-container">
                                            
                                        </div>
                                    </div>
                                </div>*/}

                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeModal}><i className="fas fa-times"></i> Close</button>
                        </div>
                        <LoadPanel
                            shadingColor="rgba(0,0,0,0.4)"
                            position={position}
                            visible={this.state.loadPanelVisible}
                            showIndicator={true}
                            shading={true}
                            showPane={true}
                            closeOnOutsideClick={false}
                        />
                    </form>
                </Modal>

            </div>
        );
    }
}