import DataGrid, {
    Column,
    FilterRow,
    Paging, Scrolling,
    Selection
} from 'devextreme-react/data-grid';
import DateBox from 'devextreme-react/date-box';
import DropDownBox from 'devextreme-react/drop-down-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import moment from 'moment/min/moment-with-locales'; //npm install --save moment react-moment
import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { SendEmailComponent } from '../Shared/SendEmailComponent';
let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class VehicleJobHistoryReportComponent extends Component {
    constructor() {
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        super()
        this.state = {
            isSubmitted: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            isAllVehicle: true,
            isSingleMultipleVehicle: false,
            vehicleDropdownList: [],
            vehicleDropdownData: null,
            isAllDate: true,
            isDateRange: false,
            fromDate: moment(currentTime).format("MM/DD/YYYY"),
            toDate: moment(currentTime).format("MM/DD/YYYY"),
            errors: {
                vehicle: '',
                fromDate: '',
                toDate: ''
            }
        }

        this.syncDataGridVehicleSelection = this.syncDataGridVehicleSelection.bind(this);
        this.dataGridVehicleRender = this.dataGridVehicleRender.bind(this);
    }
    getVehicleDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('vehicle-api/dropdown-all', requestParams);
            const data = await response.json();
            this.setState({
                vehicleDropdownList: data.vehiclesDropdown
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getVehicleDropdownList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isEmailSubmitted: false,
                isOpenModal: false,
                loadPanelVisible: false,
                isAllVehicle: true,
                isSingleMultipleVehicle: false,
                vehicleDropdownList: [],
                vehicleDropdownData: null,
                isAllDate: true,
                isDateRange: false,
                fromDate: moment(currentTime).format("MM/DD/YYYY"),
                toDate: moment(currentTime).format("MM/DD/YYYY"),
                errors: {
                    vehicle: '',
                    fromDate: '',
                    toDate: ''
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnVehicleDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.vehicle = '';
            if (data === null || data.length === 0) {
                //errors.vehicle = 'Please select vehicle.';
            }
            //this.setState({ vehicleDropdownData: data, errors: errors });
            this.setState({
                vehicleDropdownData: data.selectedRowKeys.length && data.selectedRowKeys || [],
                errors: errors
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    syncDataGridVehicleSelection(e) {
        this.setState({
            vehicleDropdownData: e.value || []
        });
    }
    handleOnFromDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.fromDate = '';
            let fromDate = '';
            if (event.value !== null && event.value !== '') {
                fromDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.fromDate = 'Please select date.';
            }
            if (this.state.toDate.trim() !== '') {
                errors.toDate = '';
            }
            this.setState({ fromDate: fromDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnToDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.toDate = '';
            let toDate = '';
            if (event.value !== null && event.value !== '') {
                toDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.toDate = 'Please select date.';
            }
            if (this.state.fromDate.trim() !== '') {
                errors.fromDate = '';
            }
            this.setState({ toDate: toDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnInputChange = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
            let errors = this.state.errors;
            switch (name) {
                case 'isAllDate':
                    errors.fromDate = '';
                    errors.toDate = '';
                    this.setState({ isAllDate: value, isDateRange: false });
                    break;
                case 'isDateRange':
                    errors.fromDate = '';
                    errors.toDate = '';
                    this.setState({ isAllDate: false, isDateRange: value });
                    break;
                case 'isAllVehicle':
                    errors.vehicle = '';
                    this.setState({ isAllVehicle: value, isSingleMultipleVehicle: false, vehicleDropdownData: null });
                    break;
                case 'isSingleMultipleVehicle':
                    errors.vehicle = '';
                    this.setState({ isAllVehicle: false, isSingleMultipleVehicle: value, vehicleDropdownData: null });
                    break;
                default:
                    break;

            }
            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnReportSubmit = async (event) => {
        event.preventDefault();
        try {
            this.setState({ isSubmitted: true });
            await this.downloadAsPdf();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false });
    }
    downloadAsPdf = async () => {
        try {
            if (await this.isFormValid()) {
                let fromDate = '';
                let toDate = '';
                let vehicleIds = '';
                if (this.state.isDateRange === true) {
                    if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                        fromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00'
                        toDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59'
                    }
                }
                if (this.state.isAllVehicle === false) {
                    if (this.state.vehicleDropdownData !== null) {
                        if (Object.keys(this.state.vehicleDropdownData).length > 0) {
                            for (var i = 0; i < this.state.vehicleDropdownData.length; i++) {
                                vehicleIds += vehicleIds === '' ? this.state.vehicleDropdownData[i].id : ',' + this.state.vehicleDropdownData[i].id
                            }
                        }
                    }
                }
                let reportHeader = await this.setSelectionCrieteria();
                await this.downloadReport(fromDate, toDate, vehicleIds, reportHeader, 'pdf');
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    downloadReport = async (fromDate, toDate, vehicleIds, reportHeader, fileFormat) => {
        try {
            var request = new Object();
            request.LocationId = Number(sessionStorage.getItem("session_locationId"));
            request.StartDate = fromDate;
            request.EndDate = toDate;
            request.VehicleIds = vehicleIds;
            request.SelectionCrieteria = reportHeader;
            request.fileFormat = fileFormat;
            request.MethodName = "GetVehicleJobHistoryReport";
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('crystal-report-api', requestParams);
            const dataResponse = await response.json();
            let a = document.createElement('a');
            a.href = dataResponse.reportBase64String;
            a.download = 'VehicleJobHistory.' + fileFormat;
            a.click();
        } catch (ex) {
            console.log(ex);
        }
    }
    isFormValid = async () => {
        let isValid = true;
        try {
            let errors = this.state.errors;
            errors.vehicle = '';
            errors.fromDate = '';
            errors.toDate = '';

            if (this.state.isAllVehicle === false) {
                if (this.state.vehicleDropdownData === null || this.state.vehicleDropdownData.length === 0) {
                    errors.vehicle = 'Please select vehicle.';
                    isValid = false;
                }
            }
            if (this.state.isDateRange === true) {
                if (this.state.fromDate.trim() === '') {
                    errors.fromDate = 'Please select date.';
                    isValid = false;
                }
                if (this.state.toDate.trim() === '') {
                    errors.toDate = 'Please select date.';
                    isValid = false;
                }
                if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                    if (moment(this.state.fromDate, 'MM/DD/YYYY').isAfter(this.state.toDate, 'MM/DD/YYYY')) {
                        errors.fromDate = 'Please enter a valid date range.';
                        errors.toDate = 'Please enter a valid date range.';
                        isValid = false;
                    }
                }
            }

            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    setSelectionCrieteria = async () => {
        let selectionCrieteria = 'Vehicle job history for';
        try {
            if (this.state.vehicleDropdownData === null || this.state.vehicleDropdownData.length === 0) {
                selectionCrieteria += ' all vehicles';
            }
            else {
                selectionCrieteria += ' vehicle - [ ';
                for (var i = 0; i < this.state.vehicleDropdownData.length; i++) {
                    selectionCrieteria += this.state.vehicleDropdownData[i].name;
                    if (i < this.state.vehicleDropdownData.length - 1) {
                        selectionCrieteria += ', ';
                    }
                }
                selectionCrieteria += ' ]';
            }
            if (this.state.isDateRange === true) {
                if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                    selectionCrieteria += ', date from - ' + this.state.fromDate + ' to ' + this.state.toDate;
                }
            }
            else {
                selectionCrieteria += ', all date';
            }
        } catch (ex) {
            selectionCrieteria = '';
            console.log(ex);
        }
        return selectionCrieteria;
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isEmailSubmitted: true });
                let fromDate = '';
                let toDate = '';
                let vehicleIds = '';
                if (this.state.isDateRange === true) {
                    if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                        fromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00'
                        toDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59'
                    }
                }
                if (this.state.isAllVehicle === false) {
                    if (this.state.vehicleDropdownData !== null) {
                        if (Object.keys(this.state.vehicleDropdownData).length > 0) {
                            for (var i = 0; i < this.state.vehicleDropdownData.length; i++) {
                                vehicleIds += vehicleIds === '' ? this.state.vehicleDropdownData[i].id : ',' + this.state.vehicleDropdownData[i].id
                            }
                        }
                    }
                }

                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.StartDate = fromDate;
                request.EndDate = toDate;
                request.VehicleIds = vehicleIds;
                request.SelectionCrieteria = await this.setSelectionCrieteria();
                request.fileFormat = 'pdf';
                request.MethodName = "GetVehicleJobHistoryReport";
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '') {
                    let emailSubject = 'Vehicle Job History';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = 'VehicleJobHistory.pdf'
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '35%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleOnReportSubmit}
                        style={(this.state.isSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Vehicle Job History'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="isAllVehicle" checked={this.state.isAllVehicle}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isAllVehicle"> All Vehicle</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="isSingleMultipleVehicle" checked={this.state.isSingleMultipleVehicle}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isSingleMultipleVehicle"> Single / Multiple</label>
                                        </span>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder="Select Vehicle"*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.vehicleDropdownData}*/}
                                        {/*    name="ddvehicle"*/}
                                        {/*    onChange={this.handleOnVehicleDropdownChange}*/}
                                        {/*    getOptionLabel={option => option.name}*/}
                                        {/*    getOptionValue={option => option.id}*/}
                                        {/*    options={this.state.vehicleDropdownList}*/}
                                        {/*    isMulti={true}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*    isDisabled={this.state.isAllVehicle}*/}
                                        {/*/>*/}
                                        <DropDownBox
                                            value={this.state.vehicleDropdownData}
                                            valueExpr="id"
                                            deferRendering={false}
                                            displayExpr="name"
                                            placeholder="Select vehicle type..."
                                            showClearButton={true}
                                            dataSource={this.state.vehicleDropdownList}
                                            onValueChanged={this.syncDataGridVehicleSelection}
                                            contentRender={this.dataGridVehicleRender}
                                            disabled={this.state.isAllVehicle}
                                        />
                                        {this.state.errors["vehicle"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["vehicle"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="isAllDate" checked={this.state.isAllDate}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isAllDate"> All Date (Pick-up)</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="radio" name="isDateRange" checked={this.state.isDateRange}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isDateRange"> In Range</label>
                                        </span>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="fromDate"
                                            placeholder="mm/dd/yyyy"
                                            defaultValue={this.state.fromDate}
                                            value={this.state.fromDate}
                                            onValueChanged={this.handleOnFromDateChange}
                                            width={'100%'}
                                            disabled={this.state.isAllDate}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["fromDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["fromDate"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <label className="form-check-label" htmlFor=""></label>
                                        </div>
                                        <DateBox type="date"
                                            className="form-control form-control-sm input-date"
                                            name="toDate"
                                            placeholder="mm/dd/yyyy"
                                            defaultValue={this.state.toDate}
                                            value={this.state.toDate}
                                            min={this.state.fromDate}
                                            onValueChanged={this.handleOnToDateChange}
                                            width={'100%'}
                                            disabled={this.state.isAllDate}
                                            acceptCustomValue={false}
                                        />
                                        {this.state.errors["toDate"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["toDate"]}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="submit" className="btn btn-success btn-sm" title="Download">
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                             </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }

    dataGridVehicleRender() {
        return (
            <DataGrid
                dataSource={this.state.vehicleDropdownList}
                id="grid-container"
                keyExpr="id"
                hoverStateEnabled={true}
                selectedRowKeys={this.state.vehicleDropdownData}
                onSelectionChanged={this.handleOnVehicleDropdownChange}
                ref={ref => this.dataGrid = ref}>
                <Column dataField="name" caption="Vehicle" />
                <Selection mode="multiple" />
                <Scrolling mode="virtual" />
                <Paging enabled={false} pageSize={50} />
                <FilterRow visible={true} />
            </DataGrid>
        );
    }
}