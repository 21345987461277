import DataGrid, {
    Column,
    ColumnChooser,
    Export,
    FilterRow, Grouping,
    GroupPanel,
    HeaderFilter,
    MasterDetail,
    Scrolling,
    SearchPanel,
    Selection,
    Paging,
    Pager,
    KeyboardNavigation,
} from 'devextreme-react/data-grid';
import { Template } from 'devextreme-react/core/template';
import DateBox from 'devextreme-react/date-box';
import DropDownBox from 'devextreme-react/drop-down-box';
import { SelectBox } from 'devextreme-react/select-box';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import 'react-day-picker/lib/style.css';
import { Link } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import { NavItem, NavLink } from 'reactstrap';
import Swal from 'sweetalert2';
import { LoadPanel } from 'devextreme-react/load-panel';
import { Workbook } from 'exceljs'; //npm i exceljs
import { saveAs } from 'file-saver-es';  //npm i file-saver-es
import { exportDataGrid } from 'devextreme/excel_exporter';
/*import { DateRangePicker } from 'rsuite';*/
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import { w3cwebsocket as W3CWebSocket } from "websocket"; //npm i --save websocket

import { DateFormat, StatusType, TripStatus } from '../../helpers/fixcodes';
import { formatDate, formatTime, formatFromDate, formatToDate, formatSearchDate, getRequestParams } from '../../helpers/globalfunctions';
import { LocateVehicleComponent } from '../Shared/LocateVehicleComponent';
import { NoShowReservationsComponent } from '../NoShow/NoShowReservationsComponent';
import { NotificationComponent } from '../Shared/NotificationComponent';
import { ReservationHistoryComponent } from '../Shared/ReservationHistoryComponent';
import { SendMessageComponent } from '../Shared/SendMessageComponent';
import { ViewReservationComponent } from '../Shared/ViewReservationComponent';
import { DispatchDetailComponent } from './DispatchDetailComponent';
import { SendAirlineCrewMessageComponent } from '../Shared/SendAirlineCrewMessageComponent'
import de from 'date-fns/esm/locale/de';

let currentDispatchDate = new Date();
export const reservationList = [];
let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));

const position = { of: '#historydiv' };
const dispatchDisplayDurationMin = parseInt(sessionStorage.getItem("session_dispatchDisplayDurationMin"));
const dispatchDisplayDurationMax = parseInt(sessionStorage.getItem("session_dispatchDisplayDurationMax"));
const allowToAddDriverWorkingHours = sessionStorage.getItem("session_allowToAddDriverWorkingHours");
const dispatchRefreshInterval = parseInt(sessionStorage.getItem("session_dispatchRefreshInterval"));
const session_accountId = parseInt(sessionStorage.getItem("session_accountId"));
const session_mappedAccountId1 = parseInt(sessionStorage.getItem("session_mappedAccountId1"));
const session_mappedAccountId2 = parseInt(sessionStorage.getItem("session_mappedAccountId2"));
const session_mappedAccountId4 = parseInt(sessionStorage.getItem("session_mappedAccountId4"));
const session_displayCompletedReservations = sessionStorage.getItem("session_displayCompletedReservations");
const session_displayCancelledReservations = sessionStorage.getItem("session_displayCancelledReservations");
const session_dispatchDisplayDurationMinSec = parseInt(sessionStorage.getItem("session_dispatchDisplayDurationMinSec"));
const session_dispatchDisplayDurationMaxSec = parseInt(sessionStorage.getItem("session_dispatchDisplayDurationMaxSec"));

let startTime = new Date(new Date(currentTime).setDate(currentTime.getDate() - dispatchDisplayDurationMin));
let endTime = new Date(new Date(currentTime).setDate(currentTime.getDate() + dispatchDisplayDurationMax));
let currentDate = formatDate("MM/dd/yyyy", sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime")));

//console.log("session_displayCompletedReservations: " + session_displayCompletedReservations);
//console.log("session_displayCancelledReservations: " + session_displayCancelledReservations);
//console.log("session_dispatchRefreshInterval: " + dispatchRefreshInterval);

let driverWorkingHoursobject = [
    { id: 1, name: "1" },
    { id: 2, name: "2" },
    { id: 3, name: "3" },
    { id: 4, name: "4" },
    { id: 5, name: "5" },
    { id: 6, name: "6" },
    { id: 7, name: "7" },
    { id: 8, name: "8" },
    { id: 9, name: "9" },
    { id: 10, name: "10" },
    { id: 11, name: "11" },
    { id: 12, name: "12" },
]

export class DispatchComponent extends Component {
    static displayName = DispatchComponent.name;

    constructor(props) {
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        currentDate = formatDate(sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime")), "MM/dd/yyyy");
        startTime = moment(currentDate, "MM-DD-YYYY HH:mm").subtract(dispatchDisplayDurationMin, 'days').format("MM/DD/YYYY HH:mm");        
        endTime = moment(currentDate, "MM-DD-YYYY HH:mm").add(dispatchDisplayDurationMax, 'days').format("MM/DD/YYYY HH:mm");
        //startTime = moment(startTime, "MM-DD-YYYY HH:mm").add(session_dispatchDisplayDurationMinSec, 'seconds').format("MM/DD/YYYY HH:mm");
        //endTime = moment(endTime, "MM-DD-YYYY HH:mm").add(session_dispatchDisplayDurationMaxSec, 'seconds').format("MM/DD/YYYY HH:mm");
        
        startTime = moment(startTime, "MM-DD-YYYY HH:mm").add(session_dispatchDisplayDurationMinSec, 'minutes').format("MM/DD/YYYY HH:mm");
        endTime = moment(endTime, "MM-DD-YYYY HH:mm").add(session_dispatchDisplayDurationMaxSec, 'minutes').format("MM/DD/YYYY HH:mm");

        //startTime = new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() - dispatchDisplayDurationMin + session_dispatchDisplayDurationMinSec));
        //endTime = new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() + dispatchDisplayDurationMax + session_dispatchDisplayDurationMaxSec));
        
        //console.log(session_dispatchDisplayDurationMinSec);
        //console.log(startTime);
        //console.log(session_dispatchDisplayDurationMaxSec);
        //console.log(endTime);

        let d = new Date(currentTime);
        currentDispatchDate = new Date(d.getFullYear(), d.getMonth(), d.getDate());        
        //let endTime = new Date(new Date(currentDispatchDate).setDate(currentDispatchDate.getDate() + 365));
        super(props);

        this.selectionChangedBySelectBox = false;
        this.state = {
            isSubmited: false,
            isAdvancedSubmited: false,
            reservationGridDataSource: null,
            alertNotificationsSource: null,            
            isGridHasRow: false,
            statusList: [],
            loadPanelEnabled: true,
            loadPanelVisible: false,
            vehicleGroupTypesList: [],
            filterVehicleGroupTypeList: [],
            vehicleTypeList: [],
            filterVehicleTypeList: [],
            //filterStatusList: [],
            filterVehicleList: [],
            serviceVehicles: [],
            serviceDrivers: [],
            vehicleList: [],
            driverList: [],
            locationList: [],
            isLive: false,
            isInHouse: false,
            isFarmIn: false,
            isFarmOut: false,
            isShowCompleted: session_displayCompletedReservations === "true" ? true : false,
            isShowCancelled: session_displayCancelledReservations === "true" ? true : false,
            isShowPending: false,
            isShowInProcess: false,
            isShowAll: false,
            gridBoxVehicleTypeValue: [],
            searchFromDate: formatFromDate(startTime, "MM/dd/yyyy hh:mm"),
            searchToDate: formatToDate(endTime, "MM/dd/yyyy hh:mm"),
            searchToGpsDate: formatFromDate(new Date(new Date(currentTime).setDate(currentTime.getDate() + 0)), "MM/dd/yyyy hh:mm"),
            seconds: dispatchRefreshInterval,
            rideId: "",
            vehicleTypeId: 0,
            statusId: 0,
            locationId: 0,
            vehicleId: 0,
            driverId: 0,
            isStopAutoRefresh: false,
            //rowChanged: this.rowChanged,
            isRestartRefresh: false,
            refreshDataSource: null,
            calRideVehicleList: [],
            calRideDriverList: [],
            calVehicleList: [],
            calDriverList: [],
            calServiceVehicles: [],
            calServiceDrivers: [],
            calAvailableVehicles: [],
            calAvailableDrivers: [],
            currentGPSDate: currentTime,
            currentGPSDisplayDate: formatFromDate(currentTime, "MMM/dd/yyyy hh:mm"),
            dateRange: "Date Range: " + formatFromDate(startTime, "MM/dd/yyyy hh:mm") + " to " + formatToDate(endTime, "MM/dd/yyyy hh:mm"),
            dispatchVehicleId: 0,
            dispatchDriverId: 0,
            isVehicleMultipleAssignedSubmited: false,
            isVehicleMultipleUnAssignedSubmited: false,
            isRideMultipleAttendSubmited: false,
            selectedRowKeys: [],
            trackmapUrl: "",
            callNoshowReservation: props.displayHeader,
            isExportToReport: false,
            //setDisplayHeaderValue: props.setDisplayHeaderValue,
            driverWorkingHours: driverWorkingHoursobject,
            driverWorkingHoursId: 0,
            driverWorkingHoursValue: "0",
            isSearch: false,
            wsurl: sessionStorage.getItem("session_socketApiUrl"),
            allowSocketConnection: sessionStorage.getItem("session_allowSocketConnection") === "true" ? true : false,
            //allowSocketConnection: false,
            isShowAlertNotificationsAll: false,
            errors: {
                dispatchVehicleId: 0,
                dispatchDriverId: 0,
            }
        }

        this.webSocketInterval = null;
        //this.onContentReady = this.onContentReady.bind(this);        
        this.getPreApprovedRideList = this.getPreApprovedRideList.bind(this);
        this.getPreApprovedGpsRideList = this.getPreApprovedGpsRideList.bind(this);
        this.getStatusList = this.getStatusList.bind(this);
        this.getLocationList = this.getLocationList.bind(this);
        this.getVehicleGroupTypeList = this.getVehicleGroupTypeList.bind(this);
        this.getVehicleTypeList = this.getVehicleTypeList.bind(this);
        this.getVehiclesList = this.getVehiclesList.bind(this);
        this.getDriversList = this.getDriversList.bind(this);
        this.renderDetail = this.renderDetail.bind(this);
        this.enterKeyActionChanged = this.enterKeyActionChanged.bind(this);
        this.getMapUrl = this.getMapUrl.bind(this);

        this.handleInputChange = this.handleInputChange.bind(this);
        //this.handleAlertNotificationInputChange = this.handleAlertNotificationInputChange.bind(this);
        this.handleVehicleTypeChange = this.handleVehicleTypeChange.bind(this);
        //this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.handleVehicleGroupTypeChange = this.handleVehicleGroupTypeChange.bind(this);
        this.handleVehicleChange = this.handleVehicleChange.bind(this);
        this.handleDriverChange = this.handleDriverChange.bind(this);
        this.handleSearchFromDateChange = this.handleSearchFromDateChange.bind(this);
        this.handleSearchToDateChange = this.handleSearchToDateChange.bind(this);
        this.sortByKeyAsc = this.sortByKeyAsc.bind(this);
        this.handleDispatchVehicleChange = this.handleDispatchVehicleChange.bind(this);
        this.handleDispatchDriverChange = this.handleDispatchDriverChange.bind(this);
        this.handleMultipleJobAssign = this.handleMultipleJobAssign.bind(this);
        this.handleMultipleJobAttend = this.handleMultipleJobAttend.bind(this);
        this.handleMultipleJobUnAssign = this.handleMultipleJobUnAssign.bind(this);
        this.handleMultipleRideSelectionChanged = this.handleMultipleRideSelectionChanged.bind(this);
        this.rowClick = this.rowClick.bind(this);
        //this.handleEditingStart = this.handleEditingStart.bind(this);
        this.handleCellPrepared = this.handleCellPrepared.bind(this);
        this.toolbarItemRender = this.toolbarItemRender.bind(this);
        this.toolbarAlertNotificationItemRender = this.toolbarAlertNotificationItemRender.bind(this);

        //this.syncDataGridStatusSelection = this.syncDataGridStatusSelection.bind(this);
        //this.dataGridStatusRender = this.dataGridStatusRender.bind(this);
        this.syncDataGridVehicleGroupSelection = this.syncDataGridVehicleGroupSelection.bind(this);
        this.dataGridVehicleGroupRender = this.dataGridVehicleGroupRender.bind(this);
        this.syncDataGridVehicleTypeSelection = this.syncDataGridVehicleTypeSelection.bind(this);
        this.dataGridVehicleTypeRender = this.dataGridVehicleTypeRender.bind(this);
        this.syncDataGridVehicleSelection = this.syncDataGridVehicleSelection.bind(this);
        this.dataGridVehicleRender = this.dataGridVehicleRender.bind(this);
        //this.setDisplayHeaderValue = this.setDisplayHeaderValue.bind(this);
        
        this.handleSearch = this.handleSearch.bind(this);
        this.handleDispatchSearch = this.handleDispatchSearch.bind(this);
        this.handleDaySearch = this.handleDaySearch.bind(this);
        this.handleRowPrepared = this.handleRowPrepared.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
        this.prevDate = this.prevDate.bind(this);
        this.nextDate = this.nextDate.bind(this);

        this.openViewReservationModel = this.openViewReservationModel.bind(this);
        this.openReservationHistoryModel = this.openReservationHistoryModel.bind(this);
        this.updateVehicleEtdEta = this.updateVehicleEtdEta.bind(this);
        // this.rowChanged = this.rowChanged.bind(this);
        this.editViewRide = this.editViewRide.bind(this);
        this.refreshDispatch = this.refreshDispatch.bind(this);
        this.sendDriverMessageControl = this.sendDriverMessageControl.bind(this);
        this.sendCrewAirlineMessageControl = this.sendCrewAirlineMessageControl.bind(this);
        this.handleNoshowReservations = this.handleNoshowReservations.bind(this);
        this.onExporting = this.onExporting.bind(this);
        this.readNotification = this.readNotification.bind(this);
    }

    async componentDidMount() {
        if (this.props.location.state != undefined) {
            this.setState({
                isLive: this.props.location.state.isLive,
                isInHouse: this.props.location.state.isInHouse,
                isFarmIn: this.props.location.state.isFarmIn,
                isFarmOut: this.props.location.state.isFarmOut,
                searchFromDate: this.props.location.state.searchFromDate,
                searchToDate: this.props.location.state.searchToDate,
                seconds: this.props.location.state.seconds,
                rideId: this.props.location.state.rideId,
                //vehicleTypeId: this.props.location.state.filterVehicleTypeList,
                filterVehicleGroupTypeList: this.props.location.state.filterVehicleGroupTypeList,
                filterVehicleTypeList: this.props.location.state.filterVehicleTypeList,
                //statusId: this.props.location.state.filterStatusList,
                //filterStatusList: this.props.location.state.filterStatusList,
                locationId: this.props.location.state.locationId,
                //vehicleId: this.props.location.state.filterVehicleList,
                filterVehicleList: this.props.location.state.filterVehicleList,
                //driverId: this.props.location.state.driverId,
            });
            let myVar = setTimeout(async () => await this.handleSearch(), 500)
        } else {
            this.setState({
                searchFromDate: formatFromDate(this.state.searchFromDate, "MM/dd/yyyy hh:mm"),
                searchToDate: formatToDate(this.state.searchToDate, "MM/dd/yyyy hh:mm")
            });
            let loadDispatch = await setTimeout(() => this.getPreApprovedRideList(), 500);
            //let loadDispatchGPS = setTimeout(() => this.getPreApprovedGpsRideList(), 500);
        }

        //console.log("allowSocketConnection: " + this.state.allowSocketConnection);
        console.log("allowSocketConnection: " + sessionStorage.getItem("session_allowSocketConnection"));

        if (this.state.allowSocketConnection) {
            this.setupDispatchSocket();
        }

        // If allowSocketConnection is true the auto refresh will not work. Dispatch data will be update using socket
        if (!this.state.allowSocketConnection) {
            this.dispatchInterval = setInterval(async () => {
                const { seconds } = this.state
                if (seconds > 0) {
                    this.setState(({ seconds }) => ({
                        seconds: seconds - 1
                    }))
                }
                else {
                    await this.handleSearch();
                }
            }, 1000);

            if (!this.state.isStopAutoRefresh) {
                //console.log("Interval 2: " + this.state.seconds);
                this.dispatchIntervalHandle = setInterval(async () => {
                    await this.handleSearch();
                }, this.state.seconds * 1000);
            }
        }

        //await this.getAlertNotifications(false);
        await this.getStatusList();
        await this.getVehiclesList();
        await this.getDriversList();
        //await this.getPreApprovedRideList();
        //await this.getPreApprovedGpsRideList();
        await this.getVehicleGroupTypeList();
        await this.getVehicleTypeList();
        await this.getLocationList();
        this.getMapUrl();

        //this.setState({
        //    loadPanelVisible: false
        //})
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    sendDriverMessageControl(e, data) {
        e.preventDefault();
        clearInterval(this.dispatchInterval);
        clearInterval(this.dispatchIntervalHandle);
        this.refs.SendMessageComponentModel.showModal(data);
    }

    sendCrewAirlineMessageControl(e, data) {
        e.preventDefault();
        clearInterval(this.dispatchInterval);
        clearInterval(this.dispatchIntervalHandle);
        this.refs.SendAirlineCrewMessageComponentModel.showAirlineCrewMessageModal(data);
    }

    handleNoshowReservations(e, data) {
        e.preventDefault();
        this.refs.noShowReservations.callNoshowReservation();
    }

    setupDispatchSocket() {
        try {
            if (this.state.wsurl != "") {
                var socket = new W3CWebSocket(this.state.wsurl + "Connect");
                socket.onopen = (event) => {
                    function establishValidConnection() {
                        console.log("Websocket client connected");                        
                        if (socket.readyState === socket.OPEN) {
                            let sessRequest = JSON.stringify({
                                user:
                                {
                                    UserName: sessionStorage.getItem("session_userName"),
                                    AccountId: parseInt(sessionStorage.getItem("session_accountId")),
                                    LocationId: parseInt(sessionStorage.getItem("session_locationId")),
                                }
                            });
                            console.log("WebSocket Client " + sessRequest);
                            socket.send(sessRequest);
                        } else {
                            setTimeout(establishValidConnection, 500);
                        }
                    }
                    establishValidConnection();
                };

                socket.onerror = (e) => {
                    console.log('Websocket Connection Error');
                };

                socket.onclose = (event) => {
                    this.webSocketInterval = setTimeout(() => { this.setupDispatchSocket(); }, 5000);
                    console.log('Websocket Client Closed ... reconnecting');
                };

                socket.onmessage = (message) => {
                    if (message.data != null) {
                        const notification = JSON.parse(message.data);
                        if (notification.DisableDisplayNotification) {
                            if (notification.AccountId === parseInt(sessionStorage.getItem("session_accountId"))) {
                                console.log("Websocket client message received");
                                //console.log(message.data);
                                this.onStatusUpdateBindTripData(notification);
                            }
                        }
                    }
                };
            }
        } catch (err) {
            console.log("Error in " + this.displayName + " setupDispatchSocket function", err);
        }
    }

    onStatusUpdateBindTripData = (tripData) => {
        try {
            let tripStatusDesc = "";
            let finalreservationGriData = [];
            if (this.state.reservationGridDataSource !== null) {
                let reservationGriData = this.state.reservationGridDataSource.filter(item => item != undefined);

                for (var i = 0; i < reservationGriData.length; i++) {
                    if (this.state.reservationGridDataSource[i] != undefined) {
                        if (tripData.RideId === this.state.reservationGridDataSource[i].rideId) {
                            if (tripData.DispatchReservationResponse != undefined) {
                                var updatedRide = tripData.DispatchReservationResponse.DispatchReservations[0];
                                if (updatedRide != undefined) {
                                    this.state.reservationGridDataSource[i].isSelected = updatedRide.IsSelected;
                                    this.state.reservationGridDataSource[i].reservationId = updatedRide.ReservationId;
                                    this.state.reservationGridDataSource[i].rideId = updatedRide.RideId;
                                    this.state.reservationGridDataSource[i].customerTypeId = updatedRide.CustomerTypeId;
                                    this.state.reservationGridDataSource[i].customerTypeName = updatedRide.CustomerTypeName;
                                    this.state.reservationGridDataSource[i].accountCode = updatedRide.AccountCode;
                                    this.state.reservationGridDataSource[i].flightInfo = updatedRide.FlightInfo;
                                    this.state.reservationGridDataSource[i].flightTime = updatedRide.FlightTime;
                                    this.state.reservationGridDataSource[i].flightETA = updatedRide.FlightETA;
                                    this.state.reservationGridDataSource[i].flightATA = updatedRide.FlightATA;
                                    this.state.reservationGridDataSource[i].flightSTA = updatedRide.FlightSTA;
                                    this.state.reservationGridDataSource[i].flightStatus = updatedRide.FlightStatus;
                                    this.state.reservationGridDataSource[i].flightTerminal = updatedRide.FlightTerminal;
                                    this.state.reservationGridDataSource[i].flightGate = updatedRide.FlightGate;
                                    this.state.reservationGridDataSource[i].flightCommentStatus = updatedRide.FlightCommentStatus;
                                    this.state.reservationGridDataSource[i].paxName = updatedRide.PaxName;
                                    this.state.reservationGridDataSource[i].puStreet = updatedRide.PuStreet;
                                    this.state.reservationGridDataSource[i].doStreet = updatedRide.DoStreet;
                                    this.state.reservationGridDataSource[i].pickupDateTime = updatedRide.PickupDateTime;
                                    this.state.reservationGridDataSource[i].tempPickupDateTime = updatedRide.TempPickupDateTime;
                                    this.state.reservationGridDataSource[i].assignedTime = updatedRide.AssignedTime;
                                    this.state.reservationGridDataSource[i].acceptedTime = updatedRide.AcceptedTime;
                                    this.state.reservationGridDataSource[i].onLocationTime = updatedRide.OnLocationTime;
                                    this.state.reservationGridDataSource[i].onLoadTime = updatedRide.OnLoadTime;
                                    this.state.reservationGridDataSource[i].onUnloadTime = updatedRide.OnUnloadTime;
                                    this.state.reservationGridDataSource[i].onCompletedTime = updatedRide.OnCompletedTime;
                                    this.state.reservationGridDataSource[i].noOfAdults = updatedRide.NoOfAdults;
                                    this.state.reservationGridDataSource[i].permanentRemark = updatedRide.PermanentRemark;
                                    this.state.reservationGridDataSource[i].specialInsruction = updatedRide.SpecialInsruction;
                                    this.state.reservationGridDataSource[i].driverId = updatedRide.DriverId;
                                    this.state.reservationGridDataSource[i].driverUserName = updatedRide.DriverUserName;
                                    this.state.reservationGridDataSource[i].driverName = updatedRide.DriverName;
                                    this.state.reservationGridDataSource[i].driverMobileNo = updatedRide.DriverMobileNo;
                                    this.state.reservationGridDataSource[i].dispatcherName = updatedRide.DispatcherName;
                                    this.state.reservationGridDataSource[i].vehicleId = updatedRide.VehicleId;
                                    this.state.reservationGridDataSource[i].vehicleNo = updatedRide.VehicleNo;
                                    this.state.reservationGridDataSource[i].vehicleTypeId = updatedRide.VehicleTypeId;
                                    this.state.reservationGridDataSource[i].vehicleTypeName = updatedRide.VehicleTypeName;
                                    this.state.reservationGridDataSource[i].vehicleTypeDescription = updatedRide.VehicleTypeDescription;
                                    this.state.reservationGridDataSource[i].sharedType = updatedRide.SharedType;
                                    this.state.reservationGridDataSource[i].tripStatusId = updatedRide.TripStatusId;
                                    this.state.reservationGridDataSource[i].tripStatusDesc = updatedRide.TripStatusDesc;
                                    this.state.reservationGridDataSource[i].isPreAssignedRide = updatedRide.IsPreAssignedRide;
                                    this.state.reservationGridDataSource[i].parentRideId = updatedRide.ParentRideId;
                                    this.state.reservationGridDataSource[i].noOfCharter = updatedRide.NoOfCharter;
                                    this.state.reservationGridDataSource[i].meetGreetName = updatedRide.MeetGreetName;
                                    this.state.reservationGridDataSource[i].meetGreetCharge = updatedRide.MeetGreetCharge;
                                    this.state.reservationGridDataSource[i].meetGreetAmount = updatedRide.MeetGreetAmount;
                                    this.state.reservationGridDataSource[i].isVIP = updatedRide.IsVIP;
                                    this.state.reservationGridDataSource[i].paymentTypeId = updatedRide.PaymentTypeId;
                                    this.state.reservationGridDataSource[i].paymentTypeName = updatedRide.PaymentTypeName;
                                    this.state.reservationGridDataSource[i].jobId = updatedRide.JobId;
                                    this.state.reservationGridDataSource[i].preAssignedAcknowledgeStatus = updatedRide.PreAssignedAcknowledgeStatus;
                                    this.state.reservationGridDataSource[i].preAssignedAcknowledgeDate = updatedRide.PreAssignedAcknowledgeDate;
                                    this.state.reservationGridDataSource[i].preAssignedAcknowledgeByDriver = updatedRide.PreAssignedAcknowledgeByDriver;
                                    this.state.reservationGridDataSource[i].spInfo = updatedRide.SpInfo;
                                    this.state.reservationGridDataSource[i].isVIPStatus = updatedRide.IsVIPStatus;
                                    this.state.reservationGridDataSource[i].wait = updatedRide.Wait;
                                    this.state.reservationGridDataSource[i].priority = updatedRide.Priority;
                                    this.state.reservationGridDataSource[i].accessibility = updatedRide.Accessibility;
                                    this.state.reservationGridDataSource[i].leadTime = updatedRide.LeadTime;
                                    this.state.reservationGridDataSource[i].paxFirstName = updatedRide.PaxFirstName;
                                    this.state.reservationGridDataSource[i].paxLastName = updatedRide.PaxLastName;
                                    this.state.reservationGridDataSource[i].bdo = updatedRide.Bdo;
                                    this.state.reservationGridDataSource[i].isBaggage = updatedRide.IsBaggage;
                                    this.state.reservationGridDataSource[i].exceededBoardedTime = updatedRide.ExceededBoardedTime;
                                    this.state.reservationGridDataSource[i].icaoFlightNumber = updatedRide.IcaoFlightNumber;
                                    this.state.reservationGridDataSource[i].preAssignedAcknowledgeId = updatedRide.PreAssignedAcknowledgeId;
                                    this.state.reservationGridDataSource[i].airlineLogoUrl = updatedRide.AirlineLogoUrl;
                                    this.state.reservationGridDataSource[i].vehicleEtaEtd = updatedRide.VehicleEtaEtd;
                                    this.state.reservationGridDataSource[i].isVehicleDeviceMapped = updatedRide.IsVehicleDeviceMapped;
                                    this.state.reservationGridDataSource[i].etaEtdMessage = updatedRide.EtaEtdMessage;
                                    this.state.reservationGridDataSource[i].lastRefreshTime = updatedRide.LastRefreshTime;
                                    this.state.reservationGridDataSource[i].vehicleDistance = updatedRide.VehicleDistance;
                                    this.state.reservationGridDataSource[i].hasAttachment = updatedRide.HasAttachment;
                                    this.state.reservationGridDataSource[i].allowClubbing = updatedRide.AllowClubbing;
                                    this.state.reservationGridDataSource[i].driverWorkingHours = updatedRide.DriverWorkingHours;
                                    this.state.reservationGridDataSource[i].trackingSourceId = updatedRide.TrackingSourceId;
                                    this.state.reservationGridDataSource[i].airlineCode = updatedRide.AirlineCode;
                                    this.state.reservationGridDataSource[i].createdDateTime = updatedRide.CreatedDateTime;
                                    this.state.reservationGridDataSource[i].flightDateTime = updatedRide.FlightDateTime;
                                    this.state.reservationGridDataSource[i].displayDayDifference = updatedRide.DisplayDayDifference;
                                    this.state.reservationGridDataSource[i].paxType = updatedRide.PaxType;
                                    this.state.reservationGridDataSource[i].dutyReportingTime = updatedRide.DutyReportingTime;
                                    this.state.reservationGridDataSource[i].deBriefingTime = updatedRide.DeBriefingTime;
                                    this.state.reservationGridDataSource[i].crewAddressChange = updatedRide.CrewAddressChange;
                                    this.state.reservationGridDataSource[i].flightTimeChange = updatedRide.FlightTimeChange;
                                    this.state.reservationGridDataSource[i].dutyReportTimeChange = updatedRide.DutyReportTimeChange;
                                    this.state.reservationGridDataSource[i].debriefingTimeChange = updatedRide.DebriefingTimeChange;
                                    this.state.reservationGridDataSource[i].rideTypeId = updatedRide.RideTypeId;
                                    this.state.reservationGridDataSource[i].pickupTimeChange = updatedRide.PickupTimeChange;
                                    this.state.reservationGridDataSource[i].rideType = updatedRide.RideType;
                                    this.state.reservationGridDataSource[i].startTime = updatedRide.StartTime;
                                    this.state.reservationGridDataSource[i].endTime = updatedRide.EndTime;
                                    this.state.reservationGridDataSource[i].fmVehicleId = updatedRide.FmVehicleId;
                                }
                            }
                            else {
                                if (tripData.PickupDateTime != undefined && tripData.PickupDateTime != '0001-01-01T00:00:00') {
                                    this.state.reservationGridDataSource[i].pickupDateTime = tripData.PickupDateTime;
                                }
                                if (tripData.FlightDateTime != undefined && tripData.FlightDateTime != '0001-01-01T00:00:00') {
                                    this.state.reservationGridDataSource[i].flightTime = formatTime(tripData.FlightDateTime, "hh:mm");
                                }
                                if (tripData.ReportingTime != undefined && tripData.ReportingTime != '0001-01-01T00:00:00') {
                                    this.state.reservationGridDataSource[i].dutyReportingTime = tripData.ReportingTime;
                                }
                                if (tripData.DebriefingTime != undefined && tripData.DebriefingTime != '0001-01-01T00:00:00') {
                                    this.state.reservationGridDataSource[i].deBriefingTime = tripData.DebriefingTime;
                                }
                                if (tripData.FlightActualTime != undefined && tripData.FlightActualTime != '0001-01-01T00:00:00') {
                                    this.state.reservationGridDataSource[i].flightTime = formatTime(tripData.FlightActualTime, "hh:mm");
                                }

                                if (tripData.PickupAddress != null && tripData.PickupAddress != '') {
                                    this.state.reservationGridDataSource[i].puStreet = tripData.PickupAddress;
                                }
                                if (tripData.DropoffAddress != null && tripData.DropoffAddress != '') {
                                    this.state.reservationGridDataSource[i].doStreet = tripData.DropoffAddress;
                                }
                            }
                        }
                    }
                }

                setTimeout(() => {
                    switch (parseInt(tripData.TripStatusId)) {
                        case parseInt(TripStatus.COMPLETED):
                        case parseInt(TripStatus.CANCELLED):
                        case parseInt(TripStatus.AUTOCANCELLED):
                        case parseInt(TripStatus.PAXUNLOAD):
                            reservationGriData = reservationGriData.filter(item => item != undefined && item.rideId !== tripData.RideId);
                            break;
                        default:
                            reservationGriData = reservationGriData.concat(reservationGriData[i]);
                            break;
                    }

                    this.setState({
                        reservationGridDataSource: reservationGriData
                    });
                }, 100);
            }
        } catch (e) {
            console.log("Error in " + this.displayName + ".onStatusUpdateBindTripData function", e);
        }
    }

    removeCompletedRide = (rideId) => {
        const updatedState = {
            data: {
                rideId: rideId,
            },
        };
        this.setState({ reservationGridDataSource: updatedState });
    };

    rowClick(e) {
        //e.component.collapseAll(-1);
        if (e.component.isRowExpanded(e.key) === false) {
            e.component.expandRow(e.key);
        }
        else {
            e.component.collapseRow(e.key);
        }
    }

    //onContentReady(e) {
    //    if (!e.component.getSelectedRowKeys().length) { e.component.selectRowsByIndexes(0); }
    //}

    onExporting(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
            customizeCell: ({ gridCell, excelCell }) => {
                if (gridCell.rowType === 'data') {
                    //if (gridCell.column.caption.replace(" ", "").toUpperCase() === 'CREWNAME') {
                    //    excelCell.value = gridCell.data.paxName;                        
                    //}
                    
                    if (gridCell.column.caption.replace(" ", "").toUpperCase() === 'PICK-UP') {
                        excelCell.value = gridCell.data.puStreet;
                    }
                    if (gridCell.column.caption.replace(" ", "").toUpperCase() === 'DROPOFF') {
                        excelCell.value = gridCell.data.doStreet;
                    }
                    if (gridCell.column.caption.replace(" ", "").toUpperCase() === 'FLIGHTTERMINAL') {
                        excelCell.value = gridCell.data.flightTerminal + " " + gridCell.data.flightGate;
                    }
                    if (gridCell.column.caption.replace(" ", "").toUpperCase() === 'RIDESTATUS') {
                        excelCell.value = gridCell.data.preAssignedAcknowledgeStatus === "Assigned" ? "Assigned" : gridCell.data.tripStatusDesc;                        
                    }
                    if (gridCell.column.caption.replace(" ", "").toUpperCase() === 'REPORTING/DEBRIEFINGTIME') {
                        var colValue = "";
                        if (gridCell.data.dutyReportingTime !== null) {
                            if (gridCell.data.dutyReportTimeChange === true) {
                                colValue = gridCell.data.dutyReportingTime;
                            }
                        }
                        else if (gridCell.data.deBriefingTime !== null) {
                            if (gridCell.data.debriefingTimeChange === true) {
                                colValue = gridCell.data.deBriefingTime;
                            }
                        }
                        excelCell.value = colValue;
                    }
                }
            }
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DispatchRide.xlsx');
            });
        });
        e.cancel = true;
    }

    toolbarItemRender() {
        return (
            /*<div>&nbsp;</div>*/
            <div className="row">
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 pr-0">
                    <div>
                        <SelectBox
                            dataSource={this.state.serviceVehicles.length > 0 ? this.state.serviceVehicles : []}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Select vehicle"
                            name="dispatchVehicleId"
                            defaultValue={0}
                            value={this.state.dispatchVehicleId}
                            displayExpr='name'
                            valueExpr='id'
                            onSelectionChanged={this.handleDispatchVehicleChange}
                            showClearButton={true}
                            disabled={false}
                            searchEnabled={true}
                            width="100%"
                        />
                    </div>
                    {this.state.errors["dispatchVehicleId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["dispatchVehicleId"]}</span>}
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 pr-0">
                    <div>
                        <SelectBox
                            dataSource={this.state.serviceDrivers.length > 0 ? this.state.serviceDrivers : []}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Select driver"
                            name="dispatchDriverId"
                            defaultValue={0}
                            value={this.state.dispatchDriverId}
                            displayExpr='name'
                            valueExpr='id'
                            onSelectionChanged={this.handleDispatchDriverChange}
                            showClearButton={true}
                            disabled={false}
                            searchEnabled={true}
                            width="100%"
                        />
                    </div>
                    {this.state.errors["dispatchDriverId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["dispatchDriverId"]}</span>}
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 pr-0">
                    <div>
                        <SelectBox
                            dataSource={this.state.driverWorkingHours.length > 0 ? this.state.driverWorkingHours : []}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Select working hour"
                            name="driverWorkingHoursId"
                            defaultValue={0}
                            value={this.state.driverWorkingHoursId}
                            displayExpr='name'
                            valueExpr='id'
                            onSelectionChanged={this.handleDriverWorkingHourChange}
                            showClearButton={true}
                            disabled={false}
                            searchEnabled={true}
                            width="100%"
                            visible={allowToAddDriverWorkingHours === "true" ? true : false}
                        />
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                    <button type="button" id="btnSaveAssign" className="btn btn-success btn-sm" title="Assign Ride" onClick={this.handleMultipleJobAssign}>
                        {this.state.isVehicleMultipleAssignedSubmited && <span><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                        {!this.state.isVehicleMultipleAssignedSubmited && <span><i className="fas fa-check"></i> Assign</span>}
                    </button>&nbsp;
                    <button type="button" id="btnSaveAssign" className="btn btn-warning btn-sm" title="Unassign Ride" onClick={this.handleMultipleJobUnAssign}>
                        {this.state.isVehicleMultipleUnAssignedSubmited && <span><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                        {!this.state.isVehicleMultipleUnAssignedSubmited && <span><i className="fas fa-history"></i> Unassign</span>}
                    </button>&nbsp;
                    {
                    (session_mappedAccountId1 !== session_accountId && session_mappedAccountId2 !== session_accountId
                    && session_mappedAccountId4 !== session_accountId) ? null :
                    <button type="button" id="btnAttendByTransporter" className="btn btn-success btn-sm" title="Confirm Ride" onClick={this.handleMultipleJobAttend}>
                        {this.state.isRideMultipleAttendSubmited && <span><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                        {!this.state.isRideMultipleAttendSubmited && <span><i className="fas fa-check"></i> Confirm Ride</span>}
                    </button>
                    }
                </div>
            </div>
        );
    }
    onToolbarPreparing = async (e) => {
        try {
            let isButtonDisable = !this.state.isGridHasRow;
            e.toolbarOptions.items.unshift({
                location: 'before',
                template: 'multipleDrivers'
            }, {
                location: 'after',
                widget: 'dxButton',
                showText: 'always',
                options: {
                    disabled: isButtonDisable,
                    //text: 'Export to Excel',
                    icon: 'export-excel-button',//'export',
                    elementAttr: {
                        "class": "dx-datagrid-export-button"
                    },
                    onClick: function () {
                        e.component.option("export.fileName", "DispatchRide");
                        e.component.exportToExcel(false);
                    },
                    hint: 'Export to Excel'
                }
            }, {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    onClick: this.handleOnRefreshGrid.bind(this),
                    hint: 'Refresh'
                }
            }
            )
        } catch (e) {
            console.log(e);
        }
    }
    onToolbarPreparingAlertNotification = async (e) => {
        try {
            e.toolbarOptions.items.unshift({
                location: 'before',
                template: 'multipleNotifications'
            });
        } catch (e) {
            console.log(e);
        }
    }
    toolbarAlertNotificationItemRender() {
        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pr-0">
                    <div className="form-group">
                        <span className="form-check form-group">
                            <input className="form-check-input" style={{ paddingTop: "3px" }} type="checkbox" id="isShowAlertNotificationsAll" name="isShowAlertNotificationsAll" checked={this.state.isShowAlertNotificationsAll} onChange={this.handleAlertNotificationInputChange} />
                            <label className="form-check-label" style={{ paddingTop: "3px" }} htmlFor="isShowAlertNotificationsAll">&nbsp;Show Read Alert & Notifications</label>
                        </span>
                    </div>
                </div>
            </div>
        );
    }    
    handleOnRefreshGrid = async (e) => {
        try {
            this.setState({ loadPanelVisible: true });
            e.component.option('icon', 'fas fa-spinner fa-spin');
            e.component.option('hint', 'In-process');
            clearInterval(this.dispatchInterval);
            clearInterval(this.dispatchIntervalHandle);
            await this.handleSearch();
            if (!this.state.allowSocketConnection) {
                this.dispatchInterval = setInterval(async() => {
                    const { seconds } = this.state
                    if (seconds > 0) {
                        this.setState(({ seconds }) => ({
                            seconds: seconds - 1
                        }))
                    }
                    else {
                        await this.handleSearch();
                    }
                }, 1000);
            }
        } catch (ex) {
            console.log(ex);
        }
        e.component.option('icon', 'refresh');
        e.component.option('hint', 'Refresh');
        this.setState({ loadPanelVisible: false });
    }

    enterKeyActionChanged(e) {
        ////debugger;
    }

    async refreshDispatch(e, data) {
        e.preventDefault();
        clearInterval(this.dispatchInterval);
        clearInterval(this.dispatchIntervalHandle);
        await this.handleSearch();
        if (!this.state.allowSocketConnection){
            this.dispatchInterval = setInterval(async() => {
                const { seconds } = this.state
                if (seconds > 0) {
                    this.setState(({ seconds }) => ({
                        seconds: seconds - 1
                    }))
                }
                else {
                    await this.handleSearch();
                }
            }, 1000);
        }
    }

    //async getPreApprovedRideList() {
    getPreApprovedRideList = async (e) => {        
        let tripStatus = "";
        try {
            for (let vt = 0; vt < this.state.statusList.length; vt++) {
                if (
                    parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.COMPLETED)
                    && parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.CANCELLED)
                    && parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.AUTOCANCELLED)
                    && parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.NOSHOWNONBILLABLE)
                    && parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.NOSHOW)
                ) {
                    tripStatus += this.state.statusList[vt].id + ",";
                    tripStatus.substring(0, tripStatus.length - 1);
                }
            }

            if (this.state.isShowCompleted === true) {
                tripStatus += parseInt(TripStatus.COMPLETED).toString() + ", " + parseInt(TripStatus.NOSHOW).toString() + ", ";
            }
            if (this.state.isShowCancelled === true) {
                tripStatus += parseInt(TripStatus.CANCELLED) + ", " + parseInt(TripStatus.AUTOCANCELLED) + ", ";
            }
            tripStatus.substring(0, tripStatus.length - 1);

            var dispatchReservationRequest = new Object();
            dispatchReservationRequest.fromDate = this.state.searchFromDate; //formatFromDate(this.state.searchFromDate, "MM/dd/yyyy");
            dispatchReservationRequest.toDate = this.state.searchToDate; //formatFromDate(this.state.searchToDate, "MM/dd/yyyy");
            dispatchReservationRequest.vehicleTypeGroupId = "";
            dispatchReservationRequest.StatusId = tripStatus == "" ? tripStatus : tripStatus.substring(0, tripStatus.length - 1);
            const requestParams = getRequestParams('POST', dispatchReservationRequest);
            await this.bindGrid(requestParams);
            //await this.bindGPSCalendar(requestParams);
        } catch (e) {
            ////debugger;
            console.log(e);
        }
    }

    async getPreApprovedGpsRideList() {
        let tripStatus = "";
        try {
            for (let vt = 0; vt < this.state.statusList.length; vt++) {
                if (parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.COMPLETED)
                    && parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.CANCELLED)
                    && parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.AUTOCANCELLED)
                    && parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.NOSHOWNONBILLABLE)
                    && parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.NOSHOW)
                ) {
                    tripStatus += this.state.statusList[vt].id + ",";
                    tripStatus.substring(0, tripStatus.length - 1);
                }
            }

            var dispatchReservationRequest = new Object();
            dispatchReservationRequest.fromDate = this.state.searchFromDate; //formatFromDate(this.state.searchFromDate, "MM/dd/yyyy");
            dispatchReservationRequest.toDate = this.state.searchToGpsDate; //formatFromDate(this.state.searchToGpsDate, "MM/dd/yyyy");
            dispatchReservationRequest.vehicleTypeGroupId = "";
            dispatchReservationRequest.StatusId = tripStatus == "" ? tripStatus : tripStatus.substring(0, tripStatus.length - 1);
            const requestParams = getRequestParams('POST', dispatchReservationRequest);
            //await this.bindGPSCalendar(requestParams);
        } catch (e) {
            ////debugger;
            console.log(e);
        }
    }

    async getMapUrl() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('helper/map-url', requestParams);
        const data = await response.json();
        this.setState({
            trackmapUrl: data.resultMessage + "?accountId=" + parseInt(sessionStorage.getItem('session_accountId')) + "&locationId=" + parseInt(sessionStorage.getItem('session_locationId')) + "&userId=0&vgTypeId=0&rideId=0",
        });
    }

    searchToggle = () => {
        var x = document.getElementById("advanceSearch");
        if (x.style.display === "none") {
            x.style.display = "block";
            this.setState({ isDateDisabled: true });
        } else {
            this.setState({ isDateDisabled: false });
            x.style.display = "none";
        }
    }
    sortByKeyAsc(array, key) {
        return array.sort(function (a, b) {
            var x = a[key]; var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }

    onDragStart(e) {
        e.itemData = this.state[e.fromData][e.fromIndex];
    }

    handleDragStart = (e, name) => {
        e.dataTransfer.setData("id", name);
    }

    handleDragOver = (e) => {
        e.preventDefault();
    }

    handleOnDrop = (e, item) => {
        let id = e.dataTransfer.getData("id");
        Swal.fire({
            title: "<small>Are you sure</small>",
            html: item.vehicleId > 0 ? "Do you want to Assign vehicle [" + item.displayName + "] to selected ride?" : "Do you want to Unassign vehicle to selected ride?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                let assigntask = new Object();
                assigntask.rideDetails = new Object();
                let list = this.state.calRideVehicleList.filter((task) => {
                    if (task.rideId == id) {
                        assigntask = task;
                        task.status = item.status;
                        task.vehicleId = item.vehicleId;
                        task.driverId = task.driverId;
                        task.driverName = task.driverName;
                        task.displayName = item.displayName;
                        task.vehicleNo = item.status == "pending" ? "" : item.displayName;
                        task.jobId = item.status == "pending" ? 0 : item.jobId;
                        task.isVehicleAssigned = item.vehicleId > 0 ? true : false;
                    }
                    return task;
                });
                this.handleJobAssignUnAssign(assigntask);
            }
        })
    }

    handleOnDriverDrop = (e, item) => {
        let id = e.dataTransfer.getData("id");
        Swal.fire({
            title: "<small>Are you sure</small>",
            html: item.driverId > 0 ? "Do you want to Assign driver [" + item.driverName + "] to selected ride?" : "Do you want to Unassign driver to selected ride?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                let assigntask = new Object();
                assigntask.rideDetails = new Object();
                let list = this.state.calRideDriverList.filter((task) => {
                    if (task.rideId == id) {
                        assigntask = task;
                        task.status = item.status;
                        task.vehicleId = task.vehicleId;
                        task.driverId = item.driverId;
                        task.driverName = item.driverName;
                        task.displayName = item.driverName;
                        task.vehicleNo = task.vehicleNo;
                        task.jobId = task.jobId;
                        task.isDriverAssigned = item.driverId > 0 ? true : false;
                    }
                    return task;
                });
                this.handleJobAssignUnAssign(assigntask);
            }
        })
    }

    handleMultipleJobAssignValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.dispatchVehicleId = "";
        errors.dispatchDriverId = "";
        if (this.state.dispatchVehicleId == 0 && this.state.dispatchDriverId == 0) {
            formIsValid = false;
            errors.dispatchVehicleId = "Please select vehicle/driver.";
        }

        if (parseInt(this.state.driverWorkingHoursValue) > 0) {
            if (this.state.dispatchDriverId == 0) {
                formIsValid = false;
                errors.dispatchDriverId = "Please select driver.";
            }
        }

        //if (this.state.dispatchDriverId == 0) {
        //    formIsValid = false;
        //    errors.dispatchDriverId = "Please select driver.";
        //}
        this.setState({ errors: errors });
        return formIsValid;
    }

    handleMultipleRideSelectionChanged(e) {
        this.setState({
            selectedRowKeys: e.selectedRowKeys
        });
    }

    //handleEditingStart(e) {
    //    debugger;
    //    if (parseInt(e.data.tripStatusId) === parseInt(TripStatus.COMPLETED)) {
    //        e.cancel = true;
    //    }
    //    else {
    //        e.cancel = true;
    //    }
    //}

    handleCellPrepared(e) {
        if (e.rowType === "data") {
            if (e.column.dataField === "flightInfo") {
                e.cellElement.innerHTML = "<span><img src=" + e.data.airlineLogoUrl + " class='img-icon' onerror=this.src='../dist/img/icon/ico_NoAirline.png' /></span>&nbsp;" + e.data.flightInfo;
            }
            if (e.column.dataField === "paxName") {
                if (e.data.hasAttachment === true) {
                    e.cellElement.innerHTML = "<span><i class='fa fa-paperclip text-danger' aria-hidden='true'></i></span>&nbsp;&nbsp;" + e.data.paxName;
                }
                else {
                    e.cellElement.innerHTML = e.data.paxName;
                }
            }
        }
    }

    handleMultipleJobAttend = async (e) => {
        e.preventDefault();
        let isResult = false;
        let jobrides = [];
        const selectedRowsData = await this.dataGrid.instance.getDataByKeys(this.state.selectedRowKeys);

        if (selectedRowsData.length <= 0) {
            const isValid = await Swal.fire({
                title: selectedRowsData.length > 1 ? "<small>Confirm Ride</small>" : "<small>Confirm Ride</small>",
                text: "Please select atleast one ride.",
                icon: "info",
                dangerMode: false,
                width: "25%",
                dangerMode: false,
                allowOutsideClick: false
            });
            return;
        }

        const isConfirm = await this.askMultipleRideAttend(true);
        if (!isConfirm) {
            return;
        }

        this.setState({ isRideMultipleAttendSubmited: true });
        for (let i = 0; i < selectedRowsData.length; i++) {
            jobrides.push(selectedRowsData[i].rideId);
        }

        var attendRideRequest = new Object();
        attendRideRequest.Rides = jobrides;
        attendRideRequest.IsAttendRide = true;
        
        const requestParams = getRequestParams('POST', attendRideRequest);
        const response = await fetch('/dispatch-api/attend-job', requestParams);
        const data = await response.json();

        await Swal.fire({
            icon: data.isSuccess ? 'success' : 'error',
            title: data.isSuccess ? 'Confirmed Ride' : 'Oops...',
            text: data.resultMessage,
        }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (data.isSuccess) {
                this.setState({
                    isRideMultipleAttendSubmited: false, selectedRowKeys: [],
                });
                await this.getPreApprovedRideList();
            } else {
                this.setState({ isRideMultipleAttendSubmited: false })
            }
        });
    }

    handleMultipleJobAssign = async (e) => {
        e.preventDefault();
        let isResult = false;
        let jobrides = [];
        const selectedRowsData = await this.dataGrid.instance.getDataByKeys(this.state.selectedRowKeys);

        if (selectedRowsData.length <= 0) {
            const isValid = await Swal.fire({
                title: selectedRowsData.length > 1 ? "<small>Multiple Ride Assignment</small>" : "<small>Ride Assignment</small>",
                text: "Please select atleast one ride.",
                icon: "info",
                dangerMode: false,
                width: "25%",
                dangerMode: false,
                allowOutsideClick: false
            });
            return;
        }

        const isConfirm = await this.askMultipleVehicleAssignment(true);
        if (!isConfirm) {
            return;
        }

        if (this.handleMultipleJobAssignValidation()) {

            this.setState({ isVehicleMultipleAssignedSubmited: true });
            for (let i = 0; i < selectedRowsData.length; i++) {
                //switch (parseInt(selectedRowsData[i].tripStatusId)) {
                //    case parseInt(TripStatus.PENDING):
                //    case parseInt(TripStatus.DRIVERREJECTED):
                //    case parseInt(TripStatus.VEHICLEUNASSIGNED):
                //        jobrides.push(selectedRowsData[i].rideId);
                //        break;
                //}
                jobrides.push(selectedRowsData[i].rideId);
            }

            let clubbingReservations = selectedRowsData.filter(e => e.allowClubbing === true);
            if (clubbingReservations.length > 0 && clubbingReservations.length !== selectedRowsData.length) {                
                await Swal.fire({
                    icon: 'question',
                    title: 'Clubbing Reservations',
                    html: 'Clubbing not allowed for these reservations. <br> Do you want to continue ..... ?',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Continue',
                    allowOutsideClick: false
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    this.setState({ isVehicleMultipleAssignedSubmited: false })
                    if (result.isConfirmed) {
                        isResult = true;
                    }
                });
            }
            else {
                isResult = true;
            }
            
            if (isResult) {
                if (this.state.dispatchVehicleNo != "") {
                    var preAssignUnAssignRideRequest = new Object();
                    preAssignUnAssignRideRequest.Rides = jobrides;
                    preAssignUnAssignRideRequest.VehicleId = this.state.dispatchVehicleId;
                    preAssignUnAssignRideRequest.DriverId = this.state.dispatchDriverId;
                    preAssignUnAssignRideRequest.IsAssignRide = true;
                    preAssignUnAssignRideRequest.DriverWorkingHours = parseInt(this.state.driverWorkingHoursValue);

                    const requestParams = getRequestParams('POST', preAssignUnAssignRideRequest);
                    //const response = await fetch('/dispatch-api/pre-assign-unassign-ride', requestParams);
                    const response = await fetch('/dispatch-api/assign-job', requestParams);
                    const data = await response.json();

                    await Swal.fire({
                        icon: data.isSuccess ? 'success' : 'error',
                        title: data.isSuccess ? 'Job Assigned' : 'Oops...',
                        text: data.resultMessage,
                    }).then(async (result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (data.isSuccess) {
                            this.setState({
                                isVehicleMultipleAssignedSubmited: false, selectedRowKeys: [], dispatchVehicleId: 0, dispatchDriverId: 0,
                                driverWorkingHoursId: 0,
                                driverWorkingHoursValue: "0",
                            });
                            await this.getVehiclesList();
                            await this.getPreApprovedRideList();
                            //this.getPreApprovedGpsRideList();
                        } else {
                            this.setState({ isVehicleMultipleAssignedSubmited: false })
                        }
                    });
                }
            }
        }
    }

    handleMultipleJobUnAssign = async (e) => {
        e.preventDefault();
        let jobRides = [];
        let preUnAssignRides = [];
        let jobIds = [];
        const selectedRowsData = await this.dataGrid.instance.getDataByKeys(this.state.selectedRowKeys);

        if (selectedRowsData.length <= 0) {
            const isValid = await Swal.fire({
                title: selectedRowsData.length > 1 ? "<small>Multiple Ride Unassignment</small>" : "<small>Ride Unassignment</small>",
                text: "Please select atleast one assigned ride.",
                icon: "info",
                dangerMode: false,
                width: "25%",
                dangerMode: false,
                allowOutsideClick: false
            });
            return;
        }

        const isConfirm = await this.askMultipleVehicleAssignment(false);
        if (!isConfirm) {
            return;
        }

        this.setState({ isVehicleMultipleUnAssignedSubmited: true });
        var selectedObj = "";
        for (let i = 0; i < selectedRowsData.length; i++) {
            //selectedObj = selectedObj + selectedRowsData[i].rideId + ",";                
            switch (parseInt(selectedRowsData[i].tripStatusId)) {                
                case parseInt(TripStatus.PENDING):
                case parseInt(TripStatus.DRIVERREJECTED):
                case parseInt(TripStatus.VEHICLEUNASSIGNED):
                    preUnAssignRides.push(selectedRowsData[i].rideId);
                    break;                
                case parseInt(TripStatus.ONLOCATION):
                case parseInt(TripStatus.VEHICLEASSIGNED):
                case parseInt(TripStatus.DRIVERACCEPTED):
                    jobRides.push(selectedRowsData[i].rideId);
                    //if (selectedRowsData[i].jobId <= 0) {
                    //    jobRides.push(selectedRowsData[i].rideId);
                    //}
                    //else {
                    //    jobIds.push(selectedRowsData[i].jobId);
                    //    jobRides.push(selectedRowsData[i].rideId);
                    //}
                    break;
            }
        }

        //if (jobIds.length > 0)
        {

            //let jobId = jobIds[k];
            var unassignJobRequest = new Object();
            unassignJobRequest.DispatcherId = 0;
            unassignJobRequest.JobRides = jobRides;
            unassignJobRequest.PreUnAssignRides = preUnAssignRides;
            unassignJobRequest.DriverWorkingHours = 0;
            const requestParams = getRequestParams('POST', unassignJobRequest);
            //const jobresponse = await fetch('/dispatch-api/' + jobId + '/unassign-job/', requestParams);
            const jobresponse = await fetch('/dispatch-api/unassign-jobtrip/', requestParams);
            const jobdata = await jobresponse.json();
            
            //for (let k = 0; k < jobRides.length; k++) {
            //    let jobId = jobIds[k];
            //    var unassignJobRequest = new Object();
            //    unassignJobRequest.DispatcherId = 0;
            //    unassignJobRequest.JobRides = jobRides;
            //    unassignJobRequest.PreUnAssignRides = preUnAssignRides;
            //    const requestParams = getRequestParams('POST', unassignJobRequest);
            //    //const jobresponse = await fetch('/dispatch-api/' + jobId + '/unassign-job/', requestParams);
            //    const jobresponse = await fetch('/dispatch-api/unassign-jobtrip/', requestParams);
            //    const jobdata = await jobresponse.json();
            //}

            //if (jobdata.isSuccess)
            {
                await Swal.fire({
                    icon: jobdata.isSuccess ? 'success' : 'error',
                    title: jobdata.isSuccess ? 'Job Unassigned' : 'Oops...',
                    text: jobdata.isSuccess ? "Job unassigned successful" : "",
                }).then(async (result) => {
                    /* Read more about isConfirmed, isDenied below */
                    this.setState({
                        isVehicleMultipleUnAssignedSubmited: false, selectedRowKeys: [], dispatchVehicleId: 0,
                        dispatchDriverId: 0,
                    });
                    await this.getVehiclesList();
                    await this.getPreApprovedRideList();
                    //this.getPreApprovedGpsRideList();
                });
            }
        }
        //else if (jobrides.length > 0) {
        //    if (this.state.dispatchVehicleNo != "") {
        //        var preAssignUnAssignRideRequest = new Object();
        //        preAssignUnAssignRideRequest.Rides = jobrides;
        //        preAssignUnAssignRideRequest.VehicleId = this.state.dispatchVehicleId;
        //        preAssignUnAssignRideRequest.DriverId = this.state.dispatchDriverId;
        //        preAssignUnAssignRideRequest.IsAssignRide = false;

        //        const requestParams = getRequestParams('POST', preAssignUnAssignRideRequest);
        //        const response = await fetch('/dispatch-api/pre-assign-unassign-ride', requestParams);
        //        const data = await response.json();

        //        Swal.fire({
        //            icon: data.isSuccess ? 'success' : 'error',
        //            title: data.isSuccess ? 'Job Unassigned' : 'Oops...',
        //            text: data.resultMessage,
        //        }).then((result) => {
        //            /* Read more about isConfirmed, isDenied below */
        //            if (data.isSuccess) {
        //                this.setState({
        //                    isVehicleMultipleUnAssignedSubmited: false, selectedRowKeys: [], dispatchVehicleId: 0,
        //                    dispatchDriverId: 0,
        //                });
        //                //this.handleRefresh(false);
        //                this.getVehiclesList();
        //                this.getPreApprovedRideList();
        //                //this.getPreApprovedGpsRideList();
        //            } else {
        //                this.setState({ isVehicleMultipleUnAssignedSubmited: false })
        //            }
        //        });
        //    }
        //}
    }

    async askMultipleVehicleAssignment(blflag) {
        let isResult = false;
        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: blflag === true ? "Do you want to Assign driver/vehicle to selected rides?" : "Do you want to Unassign driver/vehicle to selected rides?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    async askMultipleRideAttend(blflag) {
        let isResult = false;
        await Swal.fire({
            title: "<small>Are you sure</small>",
            html: blflag === true ? "Do you want to Attend selected rides?" : "Do you want to Unattend selected rides?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        return isResult;
    }

    handleDispatchVehicleChange(selectedOption) {
        let vehicleId = (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0);
        let vehicleNo = (selectedOption.selectedItem != null ? selectedOption.selectedItem.name : "");
        this.setState({
            dispatchVehicleId: vehicleId,
            dispatchVehicleNo: vehicleNo,
        });
    }

    handleDispatchDriverChange = (selectedOption) => {
        this.setState({ dispatchDriverId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
    }

    handleDriverWorkingHourChange = (selectedOption) => {
        this.setState({
            driverWorkingHoursId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0),
            driverWorkingHoursValue: (selectedOption.selectedItem != null ? selectedOption.selectedItem.name : "0"),
        });
    }   

    async bindGPSCalendar(requestParams) {
        this.setState({
            loadPanelVisible: true,
            isSubmited: true
        })

        try {
            const response = await fetch('/dispatch-api/pre-approved-rides', requestParams);
            const data = await response.json();

            if (data.isSuccess) {
                let rideVehicles = [];
                let rideDrivers = [];

                for (let k = 0; k < data.dispatchReservations.length; k++) {
                    var rideObject = new Object();
                    rideObject.rideDetails = new Object();

                    var rideDriverObject = new Object();
                    rideDriverObject.rideDrivers = new Object();

                    if (data.dispatchReservations[k].vehicleId === 0) {
                        rideObject.rideId = data.dispatchReservations[k].rideId;
                        rideObject.text = data.dispatchReservations[k].paxName + " " + data.dispatchReservations[k].vehicleTypeName + " [" + formatTime(data.dispatchReservations[k].pickupDateTime, "hh:mm") + "] ";
                        rideObject.status = "pending";
                        rideObject.vehicleId = 0;
                        rideObject.vehicleNo = "";
                        rideObject.driverId = data.dispatchReservations[k].driverId;
                        rideObject.driverName = data.dispatchReservations[k].driverName;
                        rideObject.jobId = 0;
                        rideObject.pickupDateTime = new Date(data.dispatchReservations[k].pickupDateTime);
                        rideObject.displayName = "Job(s)";
                        rideObject.rideDetails = data.dispatchReservations[k];
                    }
                    else {
                        rideObject.rideId = data.dispatchReservations[k].rideId;
                        rideObject.text = data.dispatchReservations[k].paxName + " " + data.dispatchReservations[k].vehicleTypeName + " [" + formatTime(data.dispatchReservations[k].pickupDateTime, "hh:mm") + "] ";
                        rideObject.status = data.dispatchReservations[k].vehicleNo;
                        rideObject.displayName = data.dispatchReservations[k].vehicleNo;
                        rideObject.vehicleId = data.dispatchReservations[k].vehicleId;
                        rideObject.vehicleNo = data.dispatchReservations[k].vehicleNo;
                        rideObject.driverId = data.dispatchReservations[k].driverId;
                        rideObject.driverName = data.dispatchReservations[k].driverName;
                        rideObject.jobId = data.dispatchReservations[k].jobId;
                        rideObject.rideDetails = data.dispatchReservations[k];
                    }
                    rideVehicles.push(rideObject);

                    if (data.dispatchReservations[k].driverId === 0) {
                        rideDriverObject.rideId = data.dispatchReservations[k].rideId;
                        rideDriverObject.text = data.dispatchReservations[k].paxName + " " + data.dispatchReservations[k].vehicleTypeName + " [" + formatTime(data.dispatchReservations[k].pickupDateTime, "hh:mm") + "] ";
                        rideDriverObject.status = "pending";
                        rideDriverObject.vehicleId = data.dispatchReservations[k].vehicleId;
                        rideDriverObject.vehicleNo = data.dispatchReservations[k].vehicleNo;
                        rideDriverObject.driverId = 0;
                        rideDriverObject.driverName = "Job(s)";
                        rideDriverObject.jobId = 0;
                        rideDriverObject.pickupDateTime = new Date(data.dispatchReservations[k].pickupDateTime);
                        rideDriverObject.displayName = "Job(s)";
                        rideDriverObject.rideDetails = data.dispatchReservations[k];
                    }
                    else {
                        rideDriverObject.rideId = data.dispatchReservations[k].rideId;
                        rideDriverObject.text = data.dispatchReservations[k].paxName + " " + data.dispatchReservations[k].vehicleTypeName + " [" + formatTime(data.dispatchReservations[k].pickupDateTime, "hh:mm") + "] ";
                        rideDriverObject.status = data.dispatchReservations[k].driverId;
                        rideDriverObject.displayName = data.dispatchReservations[k].driverName;
                        rideDriverObject.vehicleId = data.dispatchReservations[k].vehicleId;
                        rideDriverObject.vehicleNo = data.dispatchReservations[k].vehicleNo;
                        rideDriverObject.driverId = data.dispatchReservations[k].driverId;
                        rideDriverObject.driverName = data.dispatchReservations[k].driverName;
                        rideDriverObject.jobId = data.dispatchReservations[k].jobId;
                        rideDriverObject.rideDetails = data.dispatchReservations[k];
                    }
                    rideDrivers.push(rideDriverObject);
                }

                var vehicleFilter = rideVehicles.filter(function (result) { return result.vehicleId == 0; });
                if (vehicleFilter.length == 0) {
                    var rideObject = new Object();
                    rideObject.text = "";
                    rideObject.rideId = "";
                    rideObject.status = "pending";
                    rideObject.vehicleId = 0;
                    rideObject.vehicleNo = "";
                    rideObject.driverId = 0;
                    rideObject.driverName = "";
                    rideObject.jobId = 0;
                    rideObject.pickupDateTime = null;
                    rideObject.displayName = "Job(s)";
                    rideObject.rideDetails = new Object();
                    rideVehicles.push(rideObject);
                }

                var driverFilter = rideDrivers.filter(function (result) { return result.driverId == 0; });
                if (driverFilter.length == 0) {
                    var rideObject = new Object();
                    rideObject.text = "";
                    rideObject.rideId = "";
                    rideObject.status = "pending";
                    rideObject.vehicleId = 0;
                    rideObject.vehicleNo = "";
                    rideObject.driverId = 0;
                    rideObject.driverName = "";
                    rideObject.jobId = 0;
                    rideObject.pickupDateTime = null;
                    rideObject.displayName = "Job(s)";
                    rideObject.rideDetails = new Object();
                    rideDrivers.push(rideObject);
                }

                for (let v = 0; v < this.state.calServiceVehicles.length; v++) {
                    var vehicle = this.state.calServiceVehicles[v];
                    var vehicleFilter = rideVehicles.filter(function (result) { return vehicle.id === result.vehicleId; });
                    var vehicleObject = new Object();

                    if (vehicleFilter.length == 0) {
                        vehicleObject.rideId = "";
                        vehicleObject.text = "";
                        vehicleObject.vehicleNo = vehicle.name;
                        vehicleObject.vehicleId = vehicle.id;
                        vehicleObject.driverName = "";
                        vehicleObject.driverId = 0;
                        vehicleObject.jobId = 0;
                        vehicleObject.status = vehicle.name;
                        vehicleObject.displayName = vehicle.name;
                        vehicleObject.rideDetails = new Object();
                        rideVehicles.push(vehicleObject);
                    }
                }

                for (let v = 0; v < this.state.calServiceDrivers.length; v++) {
                    var driver = this.state.calServiceDrivers[v];
                    var driverFilter = rideDrivers.filter(function (result) { return driver.id === result.driverId; });
                    var driverObject = new Object();
                    if (driverFilter.length == 0) {
                        driverObject.rideId = "";
                        driverObject.text = "";
                        driverObject.vehicleNo = "";
                        driverObject.vehicleId = 0;
                        driverObject.driverName = driver.fullName;
                        driverObject.driverId = driver.id;
                        driverObject.jobId = 0;
                        driverObject.status = driver.name;
                        driverObject.displayName = driver.name;
                        driverObject.rideDetails = new Object();
                        rideDrivers.push(driverObject);
                    }
                }

                this.setState({
                    calRideVehicleList: rideVehicles,
                    calRideDriverList: rideDrivers,
                    calVehicleList: this.sortByKeyAsc(rideVehicles, "vehicleId"),
                    calDriverList: this.sortByKeyAsc(rideDrivers, "driverId"),
                });

                let allVehicleType = [];
                let allDrivers = [];
                let vehicleTypeList = [];
                let driverNameList = [];

                this.state.calVehicleList.forEach(task => {
                    allVehicleType.push(task);
                });

                this.state.calDriverList.forEach(task => {
                    allDrivers.push(task);
                });

                const map = new Map();
                for (const item of allVehicleType) {
                    if (!map.has(item.vehicleId)) {
                        map.set(item.vehicleId, true);
                        vehicleTypeList.push({
                            status: item.status,
                            displayName: item.displayName,
                            vehicleId: item.vehicleId,
                            driverId: item.driverId,
                        });
                    }
                }

                const drivermap = new Map();
                for (const item of allDrivers) {
                    if (!drivermap.has(item.driverId)) {
                        drivermap.set(item.driverId, true);
                        driverNameList.push({
                            driverId: item.driverId,
                            driverName: item.driverName,
                            vehicleId: item.vehicleId,
                        });
                    }
                }
                this.setState({ calAvailableVehicles: vehicleTypeList, calAvailableDrivers: driverNameList })
            }            
        } catch (e) {
            //debugger;
        }

        this.setState({
            loadPanelVisible: false,
            isSubmited: false
        })
    }

    async getStatusList() {
        try {
            const data = JSON.parse(sessionStorage.getItem("session_tripstatus"));
            if (data === null) {
                const requestParams = getRequestParams('GET', {});
                const response = await fetch('/status-api/' + StatusType.Booking, requestParams);
                const data = await response.json();
                sessionStorage.setItem("session_tripstatus", data != null ? JSON.stringify(data) : []);
                this.setState({
                    statusList: data.statusType
                });
            }
            else {
                this.setState({
                    statusList: data.statusType
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    async getVehiclesList() {
        try {           
            const data = JSON.parse(sessionStorage.getItem("session_vehicles"));
            if (data === null) {
                const requestParams = getRequestParams('GET', {});
                const response = await fetch('/vehicle-api/dropdown/0/0', requestParams);
                const data = await response.json();
                sessionStorage.setItem("session_vehicles", data != null ? JSON.stringify(data) : []);
                this.setState({
                    vehicleList: data.vehiclesDropdown,
                    calServiceVehicles: data.vehiclesDropdown,
                    serviceVehicles: data.vehiclesDropdown
                });
            }
            else {
                this.setState({
                    vehicleList: data.vehiclesDropdown,
                    calServiceVehicles: data.vehiclesDropdown,
                    serviceVehicles: data.vehiclesDropdown
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    async getDriversList() {
        try {
            const data = JSON.parse(sessionStorage.getItem("session_drivers"));
            if (data === null) {
                const requestParams = getRequestParams('GET', {});
                const response = await fetch('/driver-api/dropdown/', requestParams);
                data = await response.json();
                sessionStorage.setItem("session_drivers", data != null ? JSON.stringify(data) : []);
                this.setState({
                    driverList: data.driversDropdownList,
                    calServiceDrivers: data.driversDropdownList,
                    serviceDrivers: data.driversDropdownList,
                });
            }
            else {
                this.setState({
                    driverList: data.driversDropdownList,
                    calServiceDrivers: data.driversDropdownList,
                    serviceDrivers: data.driversDropdownList,
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    async getLocationList() {
        try {
            const data = JSON.parse(sessionStorage.getItem("session_locations"));
            if (data === null) {
                const requestParams = getRequestParams('GET', {});
                const response = await fetch('/account-api/locations', requestParams);
                data = await response.json();
                sessionStorage.setItem("session_locations", data != null ? data.locations : []);
                this.setState({
                    locationList: data.locations
                });                
            }
            else {
                this.setState({
                    locationList: data.locations
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    async getVehicleGroupTypeList() {
        try {
            const data = JSON.parse(sessionStorage.getItem("session_vehiclegrouptype"));
            if (data === null) {
                const requestParams = getRequestParams('GET', {});
                const response = await fetch('/vehicle-group-type-api/vehicle-group-type', requestParams);
                const data = await response.json();
                if (data.isSuccess) {
                    this.setState({
                        vehicleGroupTypesList: data.vehicleGroupTypes
                    });
                }
            }
            else {
                this.setState({
                    vehicleGroupTypesList: data.vehicleGroupTypes
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    async getVehicleTypeList() {
        try {
            const data = JSON.parse(sessionStorage.getItem("session_vehicletype"));
            if (data === null) {
                var apiVehicleTypeRequest = new Object();
                var apiVehicleTypeRequest = new Object();
                const requestParams = getRequestParams('POST', apiVehicleTypeRequest);
                const response = await fetch('/vehicletype-api/vehicletype', requestParams);
                const data = await response.json();
                if (data.isSuccess) {
                    this.setState({
                        vehicleTypeList: data.apiVehicleType
                    });
                }
            }
            else {
                this.setState({
                    vehicleTypeList: data.apiVehicleType
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }
    handleAlertNotificationInputChange = async (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        
        this.setState({
            [name]: value,         
        });

        await this.getAlertNotifications(value);
    }
    handleSearchFromDateChange(date) {
        //var dt = formatFromDate(date, "MM/dd/yyyy");
        //this.setState({
        //    searchFromDate: date,
        //    //currentGPSDate: date,
        //    //currentGPSDisplayDate: formatFromDate(date, "MMM/dd/yyyy"),
        //});
        let fromDateValue = formatFromDate(date.value, "MM/dd/yyyy hh:mm");
        this.setState({ searchFromDate: fromDateValue })
    }
    handleSearchToDateChange(date) {
        //var dt = formatFromDate(date, "MM/dd/yyyy");
        //this.setState({ searchToDate: date });
        let toDateValue = formatFromDate(date.value, "MM/dd/yyyy hh:mm");        
        this.setState({ searchToDate: toDateValue })
    }
    handleVehicleGroupTypeChange(e) {
        this.setState({
            filterVehicleGroupTypeList: e.selectedRowKeys.length && e.selectedRowKeys || []
        });
    }
    syncDataGridVehicleGroupSelection(e) {
        this.setState({
            filterVehicleGroupTypeList: e.value || []
        });
    }
    handleVehicleTypeChange(e) {
        this.setState({
            filterVehicleTypeList: e.selectedRowKeys.length && e.selectedRowKeys || []
        });
    }
    syncDataGridVehicleTypeSelection(e) {
        this.setState({
            filterVehicleTypeList: e.value || []
        });
    }
    //handleStatusChange(e) {
    //    this.setState({
    //        filterStatusList: e.selectedRowKeys.length && e.selectedRowKeys || []
    //    });
    //}
    //syncDataGridStatusSelection(e) {
    //    this.setState({
    //        filterStatusList: e.value || []
    //    });
    //}
    handleLocationChange(selectedOption) {
        this.setState({ locationId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.locationId : 0), })
    }
    handleVehicleChange(e) {
        this.setState({
            filterVehicleList: e.selectedRowKeys.length && e.selectedRowKeys || []
        });
    }
    syncDataGridVehicleSelection(e) {
        this.setState({
            filterVehicleList: e.value || []
        });
    }
    handleDriverChange(selectedOption) {
        this.setState({ driverId: (selectedOption.selectedItem != null ? selectedOption.selectedItem.id : 0), })
    }
    handleRowPrepared(e) {        
        if (e.rowType == 'data') {
            if (e.data.pickupUrgentAlert === true && (e.data.tripStatusId == (parseInt(TripStatus.PENDING)))) {
                e.rowElement.classList.add("bgPickupUrgentAlert");
            }
            else if (e.data.pickupAlert === true && (e.data.tripStatusId == (parseInt(TripStatus.PENDING)))) {
                e.rowElement.classList.add("bgPickupAlert");
            }
            else if (e.data.displayDayDifference === true && (e.data.tripStatusId == (parseInt(TripStatus.PENDING)))) {
                e.rowElement.classList.add("bgurgent");
            }    
            else {
                switch (e.data.tripStatusId) {
                    case (parseInt(TripStatus.PENDING)):
                        e.rowElement.classList.add("bgpending");
                        break;
                    case (parseInt(TripStatus.VEHICLEASSIGNED)):
                        e.rowElement.classList.add("bgassigned");
                        break;
                    case (parseInt(TripStatus.DRIVERACCEPTED)):
                        e.rowElement.classList.add("bgdriveraccepted");
                        break;
                    case (parseInt(TripStatus.DRIVERREJECTED)):
                        e.rowElement.classList.add("bgdriverrejected");
                        break;
                    case (parseInt(TripStatus.ONLOCATION)):
                        e.rowElement.classList.add("bgonlocation");
                        break;
                    case (parseInt(TripStatus.PAXLOAD)):
                        e.rowElement.classList.add("bgboarded");
                        break;
                    case (parseInt(TripStatus.COMPLETED)):
                        e.rowElement.classList.add("bgcompleted");
                        break;
                    case (parseInt(TripStatus.CANCELLED)):
                    case (parseInt(TripStatus.AUTOCANCELLED)):
                        e.rowElement.classList.add("bgcancelled");
                        break;
                    default:
                        e.rowElement.classList.add("bgpending");
                        break;
                }
            }
        }
    }
    openViewReservationModel(e, data) {
        e.preventDefault();
        this.refs.viewReservationModal.showModal("Reservation", data);
    }
    openReservationHistoryModel(e, data) {
        e.preventDefault();
        this.refs.reservationHistoryModal.showModal("Reservation", data.rideId);
    }
    async updateVehicleEtdEta(e, data) {
        e.preventDefault();
        try {
            const requestParams = getRequestParams('GET', "");
            const response = await fetch('/dispatch-api/update-eta-etd/' + data.rideId + '/' + data.tripStatusId, requestParams);
            const dataResult = await response.json();

            await this.getPreApprovedRideList();
        } catch (e) {
            console.log(e);
        }
    }
    async handleJobAssignUnAssign(assigntask) {
        try {
            let jobrides = [];
            if (assigntask.vehicleNo != "" || assigntask.driverId > 0) {
                jobrides.push(assigntask.rideDetails.rideId);
                var preAssignUnAssignRideRequest = new Object();
                preAssignUnAssignRideRequest.Rides = jobrides;
                preAssignUnAssignRideRequest.VehicleId = assigntask.vehicleId;
                preAssignUnAssignRideRequest.DriverId = assigntask.driverId;
                preAssignUnAssignRideRequest.IsAssignRide = true;

                const requestParams = getRequestParams('POST', preAssignUnAssignRideRequest);
                const response = await fetch('/dispatch-api/pre-assign-unassign-ride', requestParams);
                const data = await response.json();

                let successmessage = "", title = "";
                if (assigntask.isVehicleAssigned === true) {
                    title = "Vehicle Assignment";
                    successmessage = "Vehicle assigned successfully."
                }
                else if (assigntask.isVehicleAssigned === false) {
                    title = "Vehicle Unassignment";
                    successmessage = "Vehicle unassigned successfully."
                }
                else if (assigntask.isDriverAssigned === true) {
                    title = "Driver Assignment";
                    successmessage = "Driver assigned successfully."
                }
                else if (assigntask.isDriverAssigned === false) {
                    title = "Driver Unassignment";
                    successmessage = "Driver Unassigned successfully."
                }

                await Swal.fire({
                    icon: data.isSuccess ? 'success' : 'error',
                    title: data.isSuccess ? title : 'Oops...',
                    text: successmessage, //data.resultMessage,
                }).then(async(result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            await this.getVehiclesList();
                            await this.getPreApprovedRideList();
                            //this.getPreApprovedGpsRideList();
                        }
                    }
                    //else {
                    //    this.getPreApprovedGpsRideList();
                    //}
                });
            }
            else {
                if (assigntask.rideDetails.isPreAssignedRide == true) {
                    jobrides.push(assigntask.rideDetails.rideId);
                    var preAssignUnAssignRideRequest = new Object();
                    preAssignUnAssignRideRequest.Rides = jobrides;
                    preAssignUnAssignRideRequest.VehicleId = assigntask.rideDetails.vehicleId;
                    preAssignUnAssignRideRequest.DriverId = assigntask.rideDetails.driverId;
                    preAssignUnAssignRideRequest.IsAssignRide = false;

                    const requestParams = getRequestParams('POST', preAssignUnAssignRideRequest);
                    const response = await fetch('/dispatch-api/pre-assign-unassign-ride', requestParams);
                    const data = await response.json();

                    Swal.fire({
                        icon: data.isSuccess ? 'success' : 'error',
                        title: data.isSuccess ? 'Job Unassigned' : 'Oops...',
                        text: data.resultMessage,
                    }).then(async(result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (data.isSuccess) {
                            if (result.isConfirmed) {
                                await this.getVehiclesList();
                                await this.getPreApprovedRideList();
                                //this.getPreApprovedGpsRideList();
                            }
                        }
                        //else {
                        //    this.getPreApprovedGpsRideList();
                        //}
                    });
                }
                else {
                    let jobId = assigntask.jobId;
                    var unassignJobRequest = new Object();
                    unassignJobRequest.DispatcherId = 0;
                    const requestParams = getRequestParams('POST', unassignJobRequest);
                    const response = await fetch('/dispatch-api/' + jobId + '/unassign-job/', requestParams);
                    const data = await response.json();

                    await Swal.fire({
                        icon: data.isSuccess ? 'success' : 'error',
                        title: data.isSuccess ? 'Job Unassigned' : 'Oops...',
                        text: data.resultMessage,
                    }).then(async(result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (data.isSuccess) {
                            if (result.isConfirmed) {
                                await this.getVehiclesList();
                                await this.getPreApprovedRideList();
                                //this.getPreApprovedGpsRideList();
                            }
                        }
                        //else {
                        //    this.getPreApprovedGpsRideList();
                        //}
                    });
                }
            }
        } catch (e) {

        }
    }
    async handleRefresh(isRefresh) {
        debugger;
        if (!isRefresh) {
            this.setState({ seconds: dispatchRefreshInterval, refreshDataSource: this.handleSearch() });
            this.dispatchIntervalHandle = setInterval(async() => {
                await this.handleSearch()
            }, this.state.seconds * 1000);
        }
        else {
            clearInterval(this.dispatchInterval);

            this.setState({
                isSubmited: false
            });

            this.dispatchInterval = setInterval(() => {
                const { seconds } = this.state
                if (seconds > 0) {
                    this.setState(({ seconds }) => ({
                        seconds: seconds - 1
                    }))
                } else {
                    this.setState({ seconds: dispatchRefreshInterval, refreshDataSource: this.handleSearch() });
                }
            }, 1000);
        }
    }
    prevDate(e) {
        let currentDate = new Date(new Date(this.state.currentGPSDate).setDate(this.state.currentGPSDate.getDate() - 1));
        this.setState({
            currentGPSDate: currentDate,
            currentGPSDisplayDate: formatFromDate(currentDate, "MMM/dd/yyyy"),
        });
        this.handleDaySearch(e);
    }
    nextDate(e) {
        let currentDate = new Date(new Date(this.state.currentGPSDate).setDate(this.state.currentGPSDate.getDate() + 1));
        this.setState({
            currentGPSDate: currentDate,
            currentGPSDisplayDate: formatFromDate(currentDate, "MMM/dd/yyyy"),
        });
        this.handleDaySearch(e);
    }

    async handleDispatchSearch(e) {
        try {
            this.setState({ isSearch: true });
            let loadDispatch = setTimeout(async () => await this.handleSearch(), 500);
        } catch (e) {

        }
    }

    async handleSearch(e) {
        debugger;
        //console.log("search method called");
        try {
            this.setState({
                seconds: dispatchRefreshInterval,
                //loadPanelVisible: true,
                //isSubmited: true,
                isStopAutoRefresh: true
            });

            let tripVehicleGroupType = "";
            let tripVehicleType = "";
            let tripStatus = "";
            let tripVehicle = "";

            for (let vt = 0; vt < this.state.filterVehicleGroupTypeList.length; vt++) {
                tripVehicleGroupType += this.state.filterVehicleGroupTypeList[vt] + ",";
                tripVehicleGroupType.substring(0, tripVehicleGroupType.length - 1);
            }

            for (let vt = 0; vt < this.state.filterVehicleTypeList.length; vt++) {
                tripVehicleType += this.state.filterVehicleTypeList[vt] + ",";
                tripVehicleType.substring(0, tripVehicleType.length - 1);
            }

            if (this.state.isShowPending == true) {
                tripStatus += parseInt(TripStatus.PENDING) + ", " + parseInt(TripStatus.VEHICLEUNASSIGNED) + ", " + parseInt(TripStatus.DRIVERREJECTED) + ", ";
            }
            if (this.state.isShowInProcess == true) {
                tripStatus += parseInt(TripStatus.VEHICLEASSIGNED) + ", " + parseInt(TripStatus.DRIVERACCEPTED) + ", ";
                tripStatus += parseInt(TripStatus.ONLOCATION) + ", " + parseInt(TripStatus.PAXLOAD) + ", ";
            }
            if (this.state.isShowCompleted == true) {
                tripStatus += parseInt(TripStatus.COMPLETED) + ", " + parseInt(TripStatus.NOSHOW) + ", ";
            }
            if (this.state.isShowCancelled == true) {
                tripStatus += parseInt(TripStatus.CANCELLED) + ", ";
            }
            if (this.state.isShowAll == true) {
                for (let vt = 0; vt < this.state.statusList.length; vt++) {
                    if (tripStatus != parseInt(TripStatus.AUTOCANCELLED)) {
                        tripStatus += this.state.statusList[vt].id + ",";
                    }
                }
            }
            tripStatus.substring(0, tripStatus.length - 1);
            
            if (this.state.isSearch === false) {
                for (let vt = 0; vt < this.state.statusList.length; vt++) {
                    if (
                        parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.COMPLETED)
                        && parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.CANCELLED)
                        && parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.AUTOCANCELLED)
                        && parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.NOSHOWNONBILLABLE)
                        && parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.NOSHOW)
                    ) {
                        tripStatus += this.state.statusList[vt].id + ",";
                        tripStatus.substring(0, tripStatus.length - 1);
                    }
                }
            }

            if (tripStatus === "") {
                for (let vt = 0; vt < this.state.statusList.length; vt++) {
                    if (
                        parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.COMPLETED)
                        && parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.CANCELLED)
                        && parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.AUTOCANCELLED)
                        && parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.NOSHOWNONBILLABLE)
                        && parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.NOSHOW)
                    ) {
                        tripStatus += this.state.statusList[vt].id + ",";
                        tripStatus.substring(0, tripStatus.length - 1);
                    }
                }
            }

            for (let vt = 0; vt < this.state.filterVehicleList.length; vt++) {
                tripVehicle += this.state.filterVehicleList[vt] + ",";
                tripVehicle.substring(0, tripVehicle.length - 1);
            }

            var dispatchReservationRequest = new Object();
            dispatchReservationRequest.RideId = this.state.rideId == 0 ? 0 : parseInt(this.state.rideId);
            dispatchReservationRequest.FromDate = this.state.searchFromDate; //formatFromDate(this.state.searchFromDate, "MM/dd/yyyy");
            dispatchReservationRequest.ToDate = this.state.searchToDate; //formatSearchDate(this.state.searchFromDate, this.state.searchToDate, "MM/dd/yyyy hh:mm"); //formatFromDate(this.state.searchToDate, "MM/dd/yyyy");
            dispatchReservationRequest.VehicleTypeGroupId = tripVehicleGroupType == "" ? tripVehicleGroupType : tripVehicleGroupType.substring(0, tripVehicleGroupType.length - 1);
            dispatchReservationRequest.VehicleTypeId = tripVehicleType == "" ? tripVehicleType : tripVehicleType.substring(0, tripVehicleType.length - 1);
            dispatchReservationRequest.VehicleId = tripVehicle == "" ? tripVehicle : tripVehicle.substring(0, tripVehicle.length - 1);;
            dispatchReservationRequest.StatusId = tripStatus == "" ? tripStatus : tripStatus.substring(0, tripStatus.length - 1);;
            dispatchReservationRequest.DriverId = this.state.driverId;
            dispatchReservationRequest.IsLive = this.state.isLive;
            dispatchReservationRequest.IsInHouse = this.state.isInHouse;
            dispatchReservationRequest.IsFarmIn = this.state.isFarmIn;
            dispatchReservationRequest.IsFarmOut = this.state.isFarmOut;

            const requestParams = getRequestParams('POST', dispatchReservationRequest);
            await this.bindGrid(requestParams);

            this.setState({
                //loadPanelVisible: false,
                //isSubmited: false,
                searchFromDate: dispatchReservationRequest.FromDate,
                searchToDate: dispatchReservationRequest.ToDate,                
                dateRange: "Date Range: " + dispatchReservationRequest.FromDate + " to " + dispatchReservationRequest.ToDate,
                //isStopAutoRefresh: true,
            });
            //await this.bindGPSCalendar(requestParams);
        } catch (e) {

        }
    }

    async bindGrid(requestParams) {
        this.setState({
            loadPanelVisible: true,
            isSubmited: true
        });

        try {
            //const searchParams = JSON.parse(requestParams);
            const response = await fetch('/dispatch-api/pre-approved-rides', requestParams);
            const data = await response.json();
            let reservationCalendar = [];
            if (data.isSuccess) {
                this.setState({
                    reservationGridDataSource: data.dispatchReservations,
                    //dateRange: "Date Range: " + searchParams.FromDate + " to " + searchParams.ToDate,
                    //dateRange: "Date Range: " + requestParams.FromDate + " to " + requestParams.ToDate,
                    isGridHasRow: (data.dispatchReservations !== null && data.dispatchReservations.length > 0) || false
                });
                //this.dataGrid.instance.repaint();
            }
        } catch (e) {
            ////debugger;
        }

        this.setState({
            loadPanelVisible: false,
            isSubmited: false
        });

        //clearInterval(this.dispatchInterval);
        //clearInterval(this.dispatchIntervalHandle);
    }

    async getAlertNotifications(flag) {
        try {
            this.setState({
                loadPanelVisible: true,               
            });
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/notification-api/notification-list', requestParams);
            const data = await response.json();
            if (data.isSuccess) {
                this.setState({
                    alertNotificationsSource: data.alertNotifcations !== null ? data.alertNotifcations.filter(f => f.dataRead === flag) : [],
                });
            }
        } catch (e) {
            console.log(e);
        }
        this.setState({
            loadPanelVisible: false,
        });
    }

    async handleDaySearch(e) {
        let tripVehicleGroupType = "";
        let tripVehicleType = "";
        let tripStatus = "";
        let tripVehicle = "";

        for (let vt = 0; vt < this.state.filterVehicleGroupTypeList.length; vt++) {
            tripVehicleGroupType += this.state.filterVehicleGroupTypeList[vt] + ",";
            tripVehicleGroupType.substring(0, tripVehicleGroupType.length - 1);
        }

        for (let vt = 0; vt < this.state.filterVehicleTypeList.length; vt++) {
            tripVehicleType += this.state.filterVehicleTypeList[vt] + ",";
            tripVehicleType.substring(0, tripVehicleType.length - 1);
        }

        //for (let vt = 0; vt < this.state.filterStatusList.length; vt++) {
        //    tripStatus += this.state.filterStatusList[vt] + ",";
        //    tripStatus.substring(0, tripStatus.length - 1);
        //}

        if (this.state.filterStatusList.length == 0) {
            for (let vt = 0; vt < this.state.statusList.length; vt++) {
                if (this.state.isShowCompleted == false) {
                    if (this.state.isShowCompleted == false && this.state.isShowCancelled == false) {
                        if (parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.COMPLETED)
                            && parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.CANCELLED)
                            && parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.AUTOCANCELLED)
                            && parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.NOSHOWNONBILLABLE)
                        ) {
                            tripStatus += this.state.statusList[vt].id + ",";
                            tripStatus.substring(0, tripStatus.length - 1);
                        }
                    }
                    else if (this.state.isShowCompleted == true && this.state.isShowCancelled == false) {
                        if (parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.CANCELLED)
                            && parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.AUTOCANCELLED)
                        ) {
                            tripStatus += this.state.statusList[vt].id + ",";
                            tripStatus.substring(0, tripStatus.length - 1);
                        }
                    }
                    else if (this.state.isShowCompleted == false && this.state.isShowCancelled == true) {
                        if (parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.COMPLETED)
                            && parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.NOSHOW)
                        ) {
                            tripStatus += this.state.statusList[vt].id + ",";
                            tripStatus.substring(0, tripStatus.length - 1);
                        }
                    }
                    else {
                        tripStatus += this.state.statusList[vt].id + ",";
                        tripStatus.substring(0, tripStatus.length - 1);
                    }
                }
                else {
                    tripStatus += this.state.statusList[vt].id + ",";
                    tripStatus.substring(0, tripStatus.length - 1);
                }
            }
        }
        else {
            for (let vt = 0; vt < this.state.filterStatusList.length; vt++) {
                tripStatus += this.state.filterStatusList[vt] + ",";
            }
            if (this.state.isShowCompleted == true) {
                tripStatus += parseInt(TripStatus.COMPLETED) + ", " + parseInt(TripStatus.NOSHOW) + ", ";
            }
            if (this.state.isShowCancelled == true) {
                tripStatus += parseInt(TripStatus.CANCELLED) + ", " + parseInt(TripStatus.AUTOCANCELLED) + ", ";
            }
            tripStatus.substring(0, tripStatus.length - 1);
        }

        for (let vt = 0; vt < this.state.filterVehicleList.length; vt++) {
            tripVehicle += this.state.filterVehicleList[vt] + ",";
            tripVehicle.substring(0, tripVehicle.length - 1);
        }

        var dispatchReservationRequest = new Object();
        dispatchReservationRequest.RideId = this.state.rideId == 0 ? 0 : parseInt(this.state.rideId);
        dispatchReservationRequest.FromDate = formatFromDate(this.state.currentGPSDate, "MM/dd/yyyy");
        dispatchReservationRequest.ToDate = formatFromDate(this.state.currentGPSDate, "MM/dd/yyyy");
        dispatchReservationRequest.VehicleTypeGroupId = tripVehicleGroupType == "" ? tripVehicleGroupType : tripVehicleGroupType.substring(0, tripVehicleGroupType.length - 1);
        dispatchReservationRequest.VehicleTypeId = tripVehicleType == "" ? tripVehicleType : tripVehicleType.substring(0, tripVehicleType.length - 1);
        dispatchReservationRequest.VehicleId = tripVehicle == "" ? tripVehicle : tripVehicle.substring(0, tripVehicle.length - 1);;
        dispatchReservationRequest.StatusId = tripStatus == "" ? tripStatus : tripStatus.substring(0, tripStatus.length - 1);;
        dispatchReservationRequest.DriverId = this.state.driverId;
        dispatchReservationRequest.IsLive = this.state.isLive;
        dispatchReservationRequest.IsInHouse = this.state.isInHouse;
        dispatchReservationRequest.IsFarmIn = this.state.isFarmIn;
        dispatchReservationRequest.IsFarmOut = this.state.isFarmOut;
        const requestParamsGPS = getRequestParams('POST', dispatchReservationRequest);
        //await this.bindGPSCalendar(requestParamsGPS);
    }

    async handleClearSearch() {
        this.setState({
            rideId: "",
            driverId: 0,
            locationId: 0,
            filterVehicleList: [],
            filterVehicleGroupTypeList: [],
            filterVehicleTypeList: [],
            filterStatusList: [],
            searchFromDate: formatFromDate(this.state.searchFromDate, "MM/dd/yyyy hh:mm"),
            searchToDate: formatToDate(this.state.searchToDate, "MM/dd/yyyy hh:mm"),
        });
        //let myVar = setTimeout(() => this.getPreApprovedRideList(), 500)
    }

    async handleReset() {
        this.setState({
            isSubmited: false,
            isAdvancedSubmited: false,
            reservationGridDataSource: null,
            alertNotification: null,
            isGridHasRow: false,
            loadPanelEnabled: true,
            loadPanelVisible: false,
            isLive: false,
            isInHouse: false,
            isFarmIn: false,
            isFarmOut: false,
            isShowCompleted: session_displayCompletedReservations === "true" ? true : false,
            isShowCancelled: session_displayCancelledReservations === "true" ? true : false,
            isShowPending: false,
            isShowInProcess: false,
            isShowAll: false,
            searchFromDate: formatFromDate(startTime, "MM/dd/yyyy hh:mm"),
            searchToDate: formatToDate(endTime, "MM/dd/yyyy hh:mm"),
            searchToGpsDate: formatFromDate(new Date(new Date(currentTime).setDate(currentTime.getDate() + 0)), "MM/dd/yyyy hh:mm"),
            seconds: dispatchRefreshInterval,
            rideId: "",
            vehicleTypeId: 0,
            statusId: 0,
            locationId: 0,
            vehicleId: 0,
            driverId: 0,
            isStopAutoRefresh: false,
            //rowChanged: this.rowChanged,
            isRestartRefresh: false,
            refreshDataSource: null,
            currentGPSDate: currentTime,
            currentGPSDisplayDate: formatFromDate(currentTime, "MMM/dd/yyyy hh:mm"),
            dateRange: "Date Range: " + formatFromDate(startTime, "MM/dd/yyyy hh:mm") + " to " + formatToDate(endTime, "MM/dd/yyyy hh:mm"),
            dispatchVehicleId: 0,
            dispatchDriverId: 0,
            isVehicleMultipleAssignedSubmited: false,
            isVehicleMultipleUnAssignedSubmited: false,
            selectedRowKeys: [],
            trackmapUrl: "",
            isExportToReport: false,
            driverWorkingHours: driverWorkingHoursobject,
            driverWorkingHoursId: 0,
            driverWorkingHoursValue: "0",
            isSearch: false,
            errors: {
                dispatchVehicleId: 0,
                dispatchDriverId: 0,
            }
        });

        setTimeout(async () => await this.getPreApprovedRideList(), 500);
    }

    editViewRide(data) {
        clearInterval(this.dispatchInterval);
        clearInterval(this.dispatchIntervalHandle);

        this.props.history.push({
            pathname: '/crudride',
            //state: { detail: data }
            state: { rideId: data.rideId, sourceUrl: "/dispatch/list", sourceState: this.state }
        })
    }

    async readNotification(e, notification) {
        e.preventDefault();
        this.setState({
            loadPanelVisible: true
        });
        
        try {
            var newObject = new Object();
            newObject.Id = parseInt(notification.id);
            newObject.AccountId = parseInt(notification.accountId);
            newObject.LocationId = parseInt(notification.locationId);
            newObject.AirlineCode = notification.airlineCode;
            newObject.FlightNumber = notification.flightNumber;
            newObject.AccountName = notification.accountName;
            newObject.EmployeeNumber = notification.employeeNumber;
            newObject.CrewName = notification.crewName;
            newObject.Rank = notification.rank;
            newObject.ChangeType = notification.changeType;
            newObject.PreviousData = notification.previousData;
            newObject.CurrentData = notification.currentData;
            newObject.ChangeSource = notification.changeSource;
            newObject.ChangeTime = notification.changeTime;
            newObject.DataRead = true;
            newObject.ReadAt = currentTime;
            newObject.ReadBy = notification.readBy;
            newObject.TransactionId = notification.transactionId;
            newObject.FlightTime = notification.flightTime;
            newObject.RideId = notification.rideId;

            const requestParams = getRequestParams('POST', newObject);
            let path = "notification-api/notification-update";
            const response = await fetch(path, requestParams);
            const data = await response.json();

            if (data.isSuccess) {
                await this.getAlertNotifications(false);
            }
        } catch (e) {
            console.log(e);
        }
        this.setState({
            loadPanelVisible: false
        });
    }

    renderDetail(props) {
        return (<div><DispatchDetailComponent
            useRef="dipatchDetailRef"
            isDispatchRowIndex={props.rowIndex}
            dispatchRideId={props.data.rideId}
            singleRideSource={props.data}
            refreshDataSource={this.handleSearch}
            stopAutoRefresh={this.dispatchInterval}
            stopAutoRefreshHandle={this.dispatchIntervalHandle}
            isStopAutoRefresh={this.isStopAutoRefresh}
            handleRefresh={this.handleRefresh}
            //rowChanged={this.rowChanged}
            editViewRide={this.editViewRide}
        /></div>);
    }

    render() {
        const { seconds } = this.state;

        const filterSearchFromDate = time => {
            const currentDate = currentTime;
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }

        const filterSearchToDate = time => {
            const currentDate = currentTime;
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        }

        const renderGridCell = (data) => {
            let tripStatusId = data.data.tripStatusId;
            let isDisplayEtdEta = false;

            switch (tripStatusId) {
                case (parseInt(TripStatus.DRIVERACCEPTED)):
                case (parseInt(TripStatus.VEHICLEASSIGNED)):
                case (parseInt(TripStatus.ONLOCATION)):
                case (parseInt(TripStatus.PAXLOAD)):
                case (parseInt(TripStatus.PAXUNLOAD)):
                    isDisplayEtdEta = true;
                    break;
                default:
                    isDisplayEtdEta = false;
            }
            let etaEtdLink = isDisplayEtdEta == true ? <a href="#" className="text-info" title={data.data.etaEtdMessage} onClick={(e) => { this.updateVehicleEtdEta(e, data.data) }}>{data.data.vehicleEtaEtd}</a> : "";

            return <div>
                {etaEtdLink}&nbsp;&nbsp;&nbsp;&nbsp;
            </div>;
        }

        const renderPaxNameCell = (data) => {
            if (data.data.allowClubbing === true) {
                return <div className="paxNameColor"><i className="fas fa-search"></i>&nbsp;&nbsp;{data.data.paxName}</div>;
            }
            return <div>{data.data.paxName}</div>;
        }

        const renderFlightTimeCell = (data) => {
            if (data.data.flightTimeChange === true) {
                return <div className="flightTimeTextColor">&nbsp;&nbsp;{data.data.flightTime}</div>;
            }
            return <div>{data.data.flightTime}</div>;
        }

        const renderReatAtTimeCell = (data) => {
            if (data.data.dataRead === null || data.data.dataRead === false) {
                return <div>&nbsp;&nbsp;</div>;
            }
            return <div>{formatDate(data.data.readAt, "MM/dd/yyyy hh:mm:ss")}</div>;
        }

        const renderReatNotificationCell = (data) => {
            if (data.data.dataRead === null || data.data.dataRead === false) {
                return <div><a href="#" title="Read Notification" onClick={(e) => { this.readNotification(e, data.data) }} ><span>Read</span></a></div>;
            }
            return <div>&nbsp;&nbsp;</div>;
        }

        const renderPickupTimeCell = (data) => {
            if (data.data.pickupTimeChange === true) {
                return <div className="flightTimeTextColor">&nbsp;&nbsp;{formatDate(data.data.pickupDateTime,"MM/dd/yyyy hh:mm")}</div>;
            }
            return <div>{formatDate(data.data.pickupDateTime, "MM/dd/yyyy hh:mm")}</div>;
        }

        const renderPickupAddressCell = (data) => {
            if (data.data.crewAddressChange === true && data.data.rideTypeId === 2) {
                return <div className="crewAddressTextColor">&nbsp;&nbsp;{data.data.puStreet}</div>;
            }
            return <div>{data.data.puStreet}</div>;
        }

        const renderDropoffAddressCell = (data) => {
            if (data.data.crewAddressChange === true && data.data.rideTypeId === 1) {
                return <div className="crewAddressTextColor">&nbsp;&nbsp;{data.data.doStreet}</div>;
            }            
            return <div>{data.data.doStreet}</div>;
        }

        const renderReportingDeBriefingCell = (data) => {
            if (data.data.dutyReportingTime !== null) {
                if (data.data.dutyReportTimeChange === true) {
                    return <div className="reportTimeTextColor">{data.data.dutyReportingTime}</div>;
                }
                return <div>{data.data.dutyReportingTime}</div>;
            }
            else if (data.data.deBriefingTime !== null) {
                if (data.data.debriefingTimeChange === true) {
                    return <div className="reportTimeTextColor">{data.data.deBriefingTime}</div>;
                }
                return <div>{data.data.deBriefingTime}</div>;
            }
        }

        const renderDeBriefingCell = (data) => {
            if (data.data.deBriefingTime !== null) {
                if (data.data.debriefingTimeChange === true) {
                    return <div className="reportTimeTextColor">{data.data.deBriefingTime}</div>;
                }                
            }
            return <div>{data.data.deBriefingTime}</div>;
        }

        const renderReportingCell = (data) => {
            if (data.data.dutyReportingTime !== null) {
                if (data.data.dutyReportTimeChange === true) {
                    return <div className="reportTimeTextColor">{data.data.dutyReportingTime}</div>;
                }                
            }
            return <div>{data.data.dutyReportingTime}</div>;
        }

        const renderTripStatusCell = (data) => {
            if (data.data.preAssignedAcknowledgeStatus === "Assigned") {
                return <div>Assigned</div>;
            }
            return <div>{data.data.tripStatusDesc}</div>;
        }

        const renderFlightTerminalGateCell = (data) => {            
            return <div>{data.data.flightTerminal + " " + data.data.flightGate}</div>;
        }
        
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-2 col-sm-12">
                                <h1 className="m-0 text-dark">Dispatch</h1>
                            </div>
                            <div className="col-lg-9 col-md-10 col-sm-12 text-right">
                                <ol className="breadcrumb float-sm-right">
                                    {
                                        this.state.allowSocketConnection === false ?
                                            <li id="refreshStatus" className="text-md">
                                                <span className="align-middle"><i className="fas fa-sync fa-spin"></i>&nbsp;<label className="m-0 text-dark">Refresh in <strong className="text-red">{seconds}</strong> second(s)</label>&nbsp;&nbsp;</span>
                                            </li> : null
                                    }
                                    <li>
                                        <a className="nav-link btn btn-outline-secondary btn-sm" data-widget="control-sidebar" data-slide="true" href="#" role="button" title="Advanced Search"><i className="fas fa-th-large"></i></a>
                                    </li>
                                    <NavItem><NavLink tag={Link} to="/reservations" className="btn btn-block btn-outline-secondary btn-sm" title="New Reservation"><i className="fas fa-plus"></i> New Reservation</NavLink></NavItem>
                                    <li className="nav-item">
                                        <div className="btn-group">
                                            <button type="button" className="btn btn-outline-secondary btn-sm"><i className="fas fa-list"></i> More Action</button>
                                            <button type="button" className="btn btn-outline-secondary btn-sm dropdown-toggle dropdown-icon" data-toggle="dropdown">
                                                <span className="sr-only">Toggle Dropdown</span>
                                            </button>
                                            <div className="dropdown-menu" role="menu">
                                                <NavLink id="routeNoShow" tag={Link} to="/noshow/list" className="dropdown-item" title="No Show Request"><i className="fas fa-eye-slash"></i> No Show Request</NavLink>
                                                <NavLink id="routeDriverMessageList" tag={Link} to="/drivermessage/list" className="dropdown-item" title="Driver Message"><i className="fas fa-sms"></i> Driver Message</NavLink>
                                                <NavLink id="routeLoggedDrivers" tag={Link} to="/loggeddrivers/list" className="dropdown-item" title="Logged Drivers"><i className="fas fa-user"></i> Logged Drivers</NavLink>
                                                <NavLink id="routeLoggedVehicles" tag={Link} to="/loggedvehicles/list" className="dropdown-item" title="Logged Vehicles"><i className="fas fa-car"></i> Logged Vehicles</NavLink>
                                                <NavLink id="routeDriverMessge" tag={Link} to="#" className="dropdown-item" onClick={(e) => { this.sendDriverMessageControl(e, null) }} data-toggle="modal" title="Send Message"><i className="fas fa-sms"></i> Send Message</NavLink>
                                                <NavLink id="routeCrewAirlineMessage" tag={Link} to="#" className="dropdown-item" onClick={(e) => { this.sendCrewAirlineMessageControl(e, null) }} data-toggle="modal" title="Send Crew Message"><i className="fas fa-sms"></i> Send Crew Message</NavLink>
                                                <NavLink id="routeTrackLog" tag={Link} to="/gpstrack/list" className="dropdown-item" title="Vehicle Gps Log"><i className="fas fa-sms"></i> Track Log</NavLink>
                                            </div>
                                        </div>
                                    </li>
                                    <NavItem><NavLink id="routeDispatchRefresh" tag={Link} to="#" className="btn btn-block btn-outline-secondary btn-sm" title="Refresh" onClick={(e) => { this.refreshDispatch(e, null) }}><i className="fas fa-sync-alt"></i> Refresh</NavLink></NavItem>
                                    <NavItem><NavLink id="routeDispatchCalendar" tag={Link} to="/dispatch/calendar" className="btn btn-block btn-outline-secondary btn-sm" title="Dispatch Calendar"><i className="fas fa-calendar-alt"></i> Calendar</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>               
                <div className="content">
                    <ul className="nav nav-tabs" id="custom-content-below-tab" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" id="dispatcher-tab" data-toggle="pill" href="#dispatcher" role="tab" aria-controls="dispatcher" aria-selected="true">Dispatch</a>
                        </li>
                        {
                            (session_mappedAccountId1 !== session_accountId && session_mappedAccountId2 !== session_accountId
                                && session_mappedAccountId4 !== session_accountId) ? null :
                                <li className="nav-item">
                                    <a className="nav-link" id="alert-notification-tab" data-toggle="pill" href="#alert-notification" role="tab" aria-controls="alert-notification" aria-selected="false" onClick={(e) => { this.getAlertNotifications(false) }}>Alert & Notifications</a>
                                </li>
                        }
                        {/*<li className="nav-item">
                        {/*    <a className="nav-link" id="dispatcher-calendar-vehicle-tab" data-toggle="pill" href="#dispatcher-calendar-vehicle" role="tab" aria-controls="dispatcher-calendar-vehicle" aria-selected="false">Vehicle Assign</a>*/}
                        {/*</li>*/}
                        {/*<li className="nav-item">*/}
                        {/*    <a className="nav-link" id="dispatcher-calendar-driver-tab" data-toggle="pill" href="#dispatcher-calendar-driver" role="tab" aria-controls="dispatcher-calendar-driver" aria-selected="false">Driver Assign</a>*/}
                        {/*</li>*/}
                        {/*<li className="nav-item">*/}
                        {/*    <a className="nav-link" id="dispatcher-tracking-tab" data-toggle="pill" href="#dispatcher-tracking" role="tab" aria-controls="dispatcher-tracking" aria-selected="false">Tracking</a>*/}
                        {/*</li>*/}
                        {/*<li className="nav-item">*/}
                        {/*    <a className="nav-link" id="dispatcher-noshow-tab" data-toggle="pill" href="#dispatcher-noshow" role="tab" aria-controls="dispatcher-noshow" aria-selected="false" onClick={(e) => { this.handleNoshowReservations(e, null) }}>NoShow Requests</a>*/}
                        {/*</li>*/}
                        {/*<li className="nav-item">*/}
                        {/*    <a className="nav-link" id="dispatcher-notification-tab" data-toggle="pill" href="#dispatcher-notification" role="tab" aria-controls="dispatcher-notification" aria-selected="false" onClick={(e) => { this.handleNoshowReservations(e, null) }}>Notifications</a>*/}
                        {/*</li>*/}
                        <li>
                            <div className="dispatchFilter">
                                <div className="form-group"><label className="lbldispatchfilter">{this.state.dateRange}</label></div>
                                <div className="form-group">
                                    <label>From Date&nbsp;</label>
                                    <DateBox type="date"
                                        className="form-control form-control-sm"
                                        name="fromDate"
                                        placeholder="mm/dd/yyyy"
                                        useMaskBehavior={true}
                                        defaultValue={this.state.searchFromDate}
                                        value={this.state.searchFromDate}
                                        onValueChanged={this.handleSearchFromDateChange}
                                        width="140px"
                                        acceptCustomValue={false}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>To Date&nbsp;</label>
                                    <DateBox type="date"
                                        className="form-control form-control-sm"
                                        name="toDate"
                                        placeholder="mm/dd/yyyy"
                                        useMaskBehavior={true}
                                        defaultValue={this.state.searchToDate}
                                        value={this.state.searchToDate}
                                        onValueChanged={this.handleSearchToDateChange}
                                        width="140px"
                                        acceptCustomValue={false} />
                                </div>                            
                                <div className="form-group">
                                    {/*<label>&nbsp;</label><br />*/}
                                    <span className="form-check form-group">
                                        <input className="form-check-input" type="checkbox" id="isShowPending" name="isShowPending" checked={this.state.isShowPending} onChange={this.handleInputChange} />
                                        <label className="form-check-label" htmlFor="isShowPending">Pending Rides</label>
                                    </span>
                                </div>
                                <div className="form-group">
                                    {/*<label>&nbsp;</label><br />*/}
                                    <span className="form-check form-group">
                                        <input className="form-check-input" type="checkbox" id="isShowInProcess" name="isShowInProcess" checked={this.state.isShowInProcess} onChange={this.handleInputChange} />
                                        <label className="form-check-label" htmlFor="isShowInProcess">InProcess Rides</label>
                                    </span>
                                </div>
                                <div className="form-group">
                                    {/*<label>&nbsp;</label><br />*/}
                                    <span className="form-check form-group">
                                        <input className="form-check-input" type="checkbox" id="isShowCompleted" name="isShowCompleted" checked={this.state.isShowCompleted} onChange={this.handleInputChange} />
                                        <label className="form-check-label" htmlFor="isShowCompleted">Completed Rides</label>
                                    </span>
                                </div>
                                <div className="form-group">
                                    {/*<label>&nbsp;</label><br />*/}
                                    <span className="form-check form-group">
                                        <input className="form-check-input" type="checkbox" id="isShowCancelled" name="isShowCancelled" checked={this.state.isShowCancelled} onChange={this.handleInputChange} />
                                        <label className="form-check-label" htmlFor="isShowCancelled">Cancelled Rides</label>
                                    </span>
                                </div>
                                <div className="form-group">
                                    {/*<label>&nbsp;</label><br />*/}
                                    <span className="form-check form-group">
                                        <input className="form-check-input" type="checkbox" id="isShowAll" name="isShowAll" checked={this.state.isShowAll} onChange={this.handleInputChange} />
                                        <label className="form-check-label" htmlFor="isShowAll">All Rides</label>
                                    </span>
                                </div>
                                <div className="form-group">
                                    {/*<div>&nbsp;</div>*/}
                                    <button type="submit" className="btn btn-success btn-sm" onClick={this.handleDispatchSearch} data-slide="true">
                                        {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin" title="In-process"></i> In-process</span>}
                                        {!this.state.isSubmited && <span title="Search"><i className="fas fa-search" title="Search"></i> Search</span>}
                                    </button>&nbsp;&nbsp;
                                    <button type="submit" className="btn btn-default btn-sm" onClick={this.handleReset} data-slide="true">
                                        <span title="Reset"><i className="fas fa-eraser" title="Reset"></i> Reset</span>
                                    </button>&nbsp;&nbsp;
                                </div>
                            </div>
                        </li>                       
                    </ul>                    
                    <div className="tab-content" id="custom-content-below-tabContent" style={{ marginTop: 0 }}>
                        {/*<div className="card">*/}
                        {/*    <div className="card-body mb-0">*/}
                                
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="tab-pane fade show active" id="dispatcher" role="tabpanel" aria-labelledby="dispatcher-tab">
                            <div className="content">
                                <div className="card card-grid" style={{ marginTop: 0 }}>
                                    <div className="card-body">
                                        {
                                            (session_mappedAccountId1 !== session_accountId && session_mappedAccountId2 !== session_accountId
                                                && session_mappedAccountId4 !== session_accountId) ?
                                                <DataGrid
                                                    ref={(ref) => this.dataGrid = ref}
                                                    id="dispatch-grid-container"
                                                    name="gridDispatchedContainer"
                                                    dataSource={this.state.reservationGridDataSource}
                                                    className="dx-datagrid-height"
                                                    keyExpr="rideId"
                                                    columnsAutoWidth={true}
                                                    showColumnLines={true}
                                                    showRowLines={true}
                                                    showBorders={true}
                                                    rowAlternationEnabled={false}
                                                    allowColumnResizing={true}
                                                    columnResizingMode='widget'
                                                    //onSelectionChanged={this.rowChanged}
                                                    //onContentReady={this.onContentReady}
                                                    onToolbarPreparing={this.onToolbarPreparing}
                                                    onRowPrepared={this.handleRowPrepared}
                                                    selectedRowKeys={this.state.selectedRowKeys}
                                                    onSelectionChanged={this.handleMultipleRideSelectionChanged}
                                                    onCellPrepared={this.handleCellPrepared}
                                                    //onRowClick={this.rowClick}
                                                    //onEditingStart={this.handleEditingStart}
                                                    onOptionChanged={this.handleOptionChange}
                                                    ref={ref => this.dataGrid = ref}>
                                                    <KeyboardNavigation enterKeyAction={this.state.enterKeyAction} />
                                                    <MasterDetail
                                                        dispatchRideId="rideId"
                                                        enabled={true}
                                                        autoExpandAll={false}
                                                        //component={DispatchDetailComponent}
                                                        render={this.renderDetail}
                                                    />
                                                    <Column dataField="pickupDateTime" width={100} caption="Pick-up Time" width={120} allowFiltering={false} allowSorting={true} format={DateFormat.DateTime24Format} dataType="datetime" />
                                                    <Column dataField="driverName" width={100} caption="Driver Name" width={150} allowFiltering={true} allowSorting={true} />
                                                    <Column dataField="driverWorkingHours" width={100} caption="Driver working Hrs" width={120} allowFiltering={true} allowSorting={true} visible={allowToAddDriverWorkingHours === "true" ? true : false} />
                                                    <Column dataField="flightInfo" width={100} caption="Flight #" width={80} allowFiltering={true} allowSorting={true} />
                                                    <Column dataField="puStreet" width={100} caption="Pick-up" width={200} allowFiltering={true} allowSorting={true} />
                                                    <Column dataField="doStreet" width={100} caption="Drop Off" width={200} allowFiltering={true} allowSorting={true} />
                                                    <Column dataField="vehicleNo" width={100} caption="Vehicle #" width={100} allowFiltering={true} allowSorting={true} />
                                                    <Column dataField="vehicleTypeName" width={100} caption="V Type" width={80} allowFiltering={true} allowSorting={true} />
                                                    <Column dataField="paxName" width={100} caption="Pax Name" width={150} allowFiltering={true} allowSorting={true} />
                                                    {/*<Column dataField="paxMobileNo" caption="Pax Mobile" width={100} allowFiltering={true} allowSorting={true} />*/}
                                                    <Column dataField="rideId" width={140} caption="Ride Id" width={90} allowFiltering={true} allowSorting={true} />
                                                    <Column dataField="driverUserName" width={100} caption="Driver #" width={100} visible={false} allowFiltering={true} allowSorting={true} />
                                                    <Column dataField="noOfAdults" width={100} caption="Pax" width={70} allowFiltering={true} allowSorting={true} />
                                                    <Column dataField="tripStatusDesc" width={100} caption="Pax Status" width={150} allowFiltering={true} allowSorting={true} />
                                                    <Column dataField="" width={90} caption="ETA/ETD" allowFiltering={false} allowSorting={false} cellRender={renderGridCell} />
                                                    <Column dataField="flightStatus" width={100} caption="Flight Status" width={100} allowFiltering={true} allowSorting={true} />
                                                    <Column dataField="flightTime" width={100} caption="Flight Time" width={80} allowFiltering={false} allowSorting={true} />
                                                    <Column dataField="flightEST" width={100} caption="Flight Est. Time" width={150} allowFiltering={true} allowSorting={true} visible={false} />
                                                    <Column dataField="flightATA" width={100} caption="Flight Actual Time" width={150} allowFiltering={true} allowSorting={true} />
                                                    <Column dataField="flightTerminal" width={100} caption="Flight Term." width={100} allowFiltering={true} allowSorting={true} />
                                                    <Column dataField="flightGate" width={100} caption="Flight Gate" width={100} allowFiltering={true} allowSorting={true} />
                                                    <Column dataField="permanentRemark" width={100} caption="Note" width={150} allowFiltering={true} allowSorting={true} />
                                                    <Column dataField="specialInsruction" width={100} caption="Dispatch Note" width={150} allowFiltering={true} allowSorting={true} />
                                                    <Column dataField="dispatcherName" width={100} caption="Dispatcher" width={100} allowFiltering={true} allowSorting={true} />
                                                    <Column dataField="noOfCharter" width={100} caption="Charter" width={80} allowFiltering={true} allowSorting={true} />
                                                    <Column dataField="preAssignedAcknowledgeStatus" width={100} caption="PreAssigned Status" width={80} allowFiltering={true} allowSorting={true} />
                                                    <Column dataField="assignedTime" width={100} caption="Assign Time" width={100} allowFiltering={false} allowSorting={true} />
                                                    <Column dataField="onLocationTime" width={100} caption="On Site" width={80} allowFiltering={false} allowSorting={true} />
                                                    <Column dataField="onLoadTime" width={100} caption="Load Time" width={80} allowFiltering={false} allowSorting={true} />
                                                    <Column dataField="onCompletedTime" width={100} caption="Completion Time" width={125} allowFiltering={false} allowSorting={true} />
                                                    <Column dataField="meetGreetName" width={100} caption="Greeter Name" width={120} allowFiltering={false} allowSorting={true} />

                                                    <Template name="multipleDrivers" render={this.toolbarItemRender} />
                                                    <ColumnChooser enabled={false} />
                                                    <Grouping autoExpandAll={true} />
                                                    <HeaderFilter visible={true} />
                                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                                    <Export visible={true} enabled={false} allowExportSelectedData={false} />
                                                    <GroupPanel visible={false} />
                                                    {/*<Scrolling mode="virtual" />*/}
                                                    {/*<LoadPanel enabled={this.state.loadPanelEnabled} />*/}
                                                    <Paging defaultPageSize={session_accountId === 4139 ? 500 : 20} />
                                                    <Pager showPageSizeSelector={true} allowedPageSizes={session_accountId === 4139 ? [10, 20, 50, 500] : [10, 20, 50]} showInfo={true} visible={true} />
                                                    <Selection
                                                        mode="multiple"
                                                        selectAllMode={"allPages"}
                                                        showCheckBoxesMode={"always"}
                                                    />
                                                </DataGrid>
                                                :
                                                <DataGrid
                                                    ref={(ref) => this.dataGrid = ref}
                                                    id="dispatch-grid-container"
                                                    name="gridDispatchedContainer"
                                                    dataSource={this.state.reservationGridDataSource}
                                                    className="dx-datagrid-height"
                                                    keyExpr="rideId"
                                                    columnsAutoHeight={true}
                                                    columnsAutoWidth={true}
                                                    showColumnLines={true}
                                                    showRowLines={true}
                                                    showBorders={true}
                                                    rowAlternationEnabled={false}
                                                    allowColumnResizing={true}
                                                    columnResizingMode='widget'
                                                    //onSelectionChanged={this.rowChanged}
                                                    //onContentReady={this.onContentReady}
                                                    onToolbarPreparing={this.onToolbarPreparing}
                                                    onRowPrepared={this.handleRowPrepared}
                                                    selectedRowKeys={this.state.selectedRowKeys}
                                                    onSelectionChanged={this.handleMultipleRideSelectionChanged}
                                                    onCellPrepared={this.handleCellPrepared}
                                                    //onRowClick={this.rowClick}
                                                    //onEditingStart={this.handleEditingStart}
                                                    onOptionChanged={this.handleOptionChange}
                                                    ref={ref => this.dataGrid = ref}
                                                    onExporting={this.onExporting}
                                                >
                                                    <KeyboardNavigation enterKeyAction={this.state.enterKeyAction} />
                                                    <MasterDetail
                                                        dispatchRideId="rideId"
                                                        enabled={true}
                                                        autoExpandAll={false}
                                                        //component={DispatchDetailComponent}
                                                        render={this.renderDetail}
                                                    />

                                                    <Column dataField="flightInfo" caption="Flight #" width={80} allowFiltering={true} allowSorting={true} />
                                                    <Column dataField="flightTime" caption="Flight Time" width={80} allowFiltering={false} allowSorting={true} />  {/*cellRender={renderFlightTimeCell}*/}
                                                    <Column dataField="flightATA" caption="Flight Actual Time" width={110} allowFiltering={false} allowSorting={true} format={DateFormat.DateTime24Format} dataType="datetime" />
                                                    <Column dataField="dutyReportingTime" caption="Reporting Time" width={120} allowFiltering={false} allowSorting={true} />  {/*cellRender={renderReportingCell}*/}
                                                    <Column dataField="deBriefingTime" caption="DeBriefing Time" width={120} allowFiltering={false} allowSorting={true} /> {/*cellRender={renderDeBriefingCell}*/}
                                                    <Column dataField="pickupDateTime" caption="Pick-up Time" width={120} allowFiltering={false} allowSorting={true} format={DateFormat.DateTime24Format} dataType="datetime"
                                                        /> {/*cellRender={renderPickupTimeCell}*/}
                                                    <Column dataField="paxName" caption="Crew Name" width={150} allowFiltering={true} allowSorting={true} />  {/*cellRender={renderPaxNameCell}*/}
                                                    <Column dataField="paxMobileNo" caption="Crew Mobile" width={100} allowFiltering={true} allowSorting={true} />
                                                    <Column dataField="paxType" caption="Rank." width={70} allowFiltering={true} allowSorting={true} />
                                                    <Column dataField="rideType" caption="Trip Type" width={100} allowFiltering={true} allowSorting={true} />
                                                    <Column dataField="puStreet" caption="Pick-up" width={200} allowFiltering={true} allowSorting={true} />  {/*cellRender={renderPickupAddressCell}*/}
                                                    <Column dataField="doStreet" caption="Drop Off" width={200} allowFiltering={true} allowSorting={true} /> {/* cellRender={renderDropoffAddressCell}*/}
                                                    <Column dataField="vehicleNo" caption="Vehicle #" width={100} allowFiltering={true} allowSorting={true} />
                                                    {/*<Column dataField="jobId" caption="Job No" width={100} allowFiltering={true} allowSorting={true} />*/}
                                                    <Column dataField="allowClubbing" caption="Allow Clubbing" width={100} allowFiltering={true} allowSorting={true} />
                                                    <Column dataField="rideConfirmed" caption="Ride Confirmation" width={120} allowFiltering={true} allowSorting={true} />
                                                    <Column dataField="otpVerifiedStatus" caption="OTP Verify Status" width={120} allowFiltering={true} allowSorting={true} />
                                                    <Column dataField="clubbedStatus" caption="Clubbing Status" width={100} allowFiltering={true} allowSorting={true} />
                                                    <Column dataField="driverName" caption="Driver Name" width={150} allowFiltering={true} allowSorting={true} />
                                                    <Column dataField="driverWorkingHours" caption="Driver working Hrs" width={120} allowFiltering={true} allowSorting={true} visible={allowToAddDriverWorkingHours === "true" ? true : false} />
                                                    <Column dataField="tripStatusDesc" caption="Ride Status" width={150} allowFiltering={true} allowSorting={true} />
                                                    <Column dataField="flightTerminal" caption="Flight Terminal" width={100} allowFiltering={true} allowSorting={true} />  {/*cellRender={renderFlightTerminalGateCell}*/}
                                                    <Column dataField="dispatcherName" caption="Dispatcher" width={100} allowFiltering={true} allowSorting={true} />
                                                    <Column dataField="assignedTime" caption="Assign Time" width={100} allowFiltering={false} allowSorting={true} />
                                                    <Column dataField="onLocationTime" caption="On Location" width={80} allowFiltering={false} allowSorting={true} />
                                                    <Column dataField="onLoadTime" caption="Boarded" width={80} allowFiltering={false} allowSorting={true} />
                                                    <Column dataField="onUnloadTime" caption="Drop" width={80} allowFiltering={false} allowSorting={true} />
                                                    <Column dataField="onCompletedTime" caption="Completed" width={125} allowFiltering={false} allowSorting={true} />
                                                    <Column dataField="assignedStatus" caption="Assigned Status" width={125} allowFiltering={false} allowSorting={true} />
                                                    <Column dataField="rideId" caption="Ride Id" width={90} allowFiltering={true} allowSorting={true} />

                                                    <Template name="multipleDrivers" render={this.toolbarItemRender} />
                                                    <ColumnChooser enabled={false} />
                                                    <Grouping autoExpandAll={true} />
                                                    <HeaderFilter visible={true} />
                                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                                    <Export visible={true} enabled={true} allowExportSelectedData={true} fileName="Dispatch" />
                                                    <GroupPanel visible={false} />
                                                    {/*<Scrolling mode="virtual" />*/}
                                                    {/*<LoadPanel enabled={this.state.loadPanelEnabled} />*/}
                                                    <Paging defaultPageSize={session_accountId === 4139 ? 500 : 20} />
                                                    <Pager showPageSizeSelector={true} allowedPageSizes={session_accountId === 4139 ? [10, 20, 50, 500] : [10, 20, 50]}
                                                        showInfo={true} visible={true} />
                                                    <Selection
                                                        mode="multiple"
                                                        selectAllMode={"allPages"}
                                                        showCheckBoxesMode={"always"}
                                                    />
                                                </DataGrid>
                                        }
                                    </div>
                                </div>
                                <ViewReservationComponent ref="viewReservationModal" />
                                <ReservationHistoryComponent ref="reservationHistoryModal" />
                                <LocateVehicleComponent ref="locateVehicleModal" />
                                <SendMessageComponent ref="SendMessageComponentModel" onSendMessageClose={this.getPreApprovedRideList} refreshDataSource={this.handleSearch} handleRefresh={this.handleRefresh} />
                                <SendAirlineCrewMessageComponent ref="SendAirlineCrewMessageComponentModel"/>
                            </div>
                        </div>  
                        {
                            (session_mappedAccountId1 !== session_accountId && session_mappedAccountId2 !== session_accountId
                                && session_mappedAccountId4 !== session_accountId) ? null :
                                <div className="tab-pane fade show" id="alert-notification" role="tabpanel" aria-labelledby="alert-notification-tab">
                                    <div className="content">
                                        <div className="card card-grid" style={{ marginTop: 0 }}>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <DataGrid
                                                            ref={(ref) => this.dataAlertNotificationGrid = ref}
                                                            id="alert-notification-container"
                                                            name="alertNotificationContainer"
                                                            dataSource={this.state.alertNotificationsSource}
                                                            className="dx-datagrid-height"
                                                            keyExpr="id"
                                                            columnsAutoWidth={true}
                                                            showColumnLines={true}
                                                            showRowLines={true}
                                                            showBorders={true}
                                                            rowAlternationEnabled={false}
                                                            allowColumnResizing={true}
                                                            columnResizingMode='widget'
                                                            onToolbarPreparing={this.onToolbarPreparingAlertNotification}>

                                                            <Column dataField="id" width={50} caption="" allowFiltering={false} allowSorting={false} cellRender={renderReatNotificationCell} />
                                                            <Column dataField="flightNumber" width={75} caption="Flight #" allowFiltering={true} allowSorting={true} />
                                                            <Column dataField="flightTime" width={140} caption="Flight Time" allowFiltering={true} allowSorting={true} format={DateFormat.DateTime24Format} dataType="datetime" />
                                                            {/*<Column dataField="accountName" width={200} caption="Transport Company" allowFiltering={true} allowSorting={true} />*/}
                                                            <Column dataField="employeeNumber" width={100} caption="Employee No" allowFiltering={true} allowSorting={true} />
                                                            <Column dataField="crewName" width={150} caption="Crew" allowFiltering={true} allowSorting={true} />
                                                            <Column dataField="rank" width={65} acaption="Rank" allowFiltering={true} allowSorting={true} />
                                                            <Column dataField="changeType" width={100} caption="Change Type" allowFiltering={true} allowSorting={true} />
                                                            <Column dataField="previousData" width={300} caption="Previous Data" allowFiltering={true} allowSorting={true} />
                                                            <Column dataField="currentData" width={300} caption="Current Data" allowFiltering={true} allowSorting={true} />
                                                            {/*<Column dataField="changeSource" width={150} caption="Change Source" allowFiltering={true} allowSorting={true} />*/}
                                                            <Column dataField="changeTime" width={160} caption="Change Time" allowFiltering={true} allowSorting={true} format={DateFormat.DateTime24Format} dataType="datetime" />
                                                            <Column dataField="readAt" width={160} caption="Read At" allowFiltering={true} allowSorting={true} format={DateFormat.DateTime24Format} dataType="datetime" cellRender={renderReatAtTimeCell} />
                                                            <Column dataField="readByName" width={160} caption="Read By" allowFiltering={true} allowSorting={true} />

                                                            <Template name="multipleNotifications" render={this.toolbarAlertNotificationItemRender} />
                                                            <ColumnChooser enabled={false} />
                                                            <Grouping autoExpandAll={true} />
                                                            <HeaderFilter visible={true} />
                                                            {/*<Scrolling mode="virtual" />*/}
                                                            <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                                            <Export visible={true} enabled={true} allowExportSelectedData={false} fileName="NotificationLog" />
                                                            <GroupPanel visible={false} />                                                            
                                                            <Paging defaultPageSize={session_accountId === 4139 ? 500 : 20} />
                                                            <Pager showPageSizeSelector={true} allowedPageSizes={session_accountId === 4139 ? [10, 20, 50, 500] : [10, 20, 50]} showInfo={true} visible={true} />
                                                            {/*<Selection*/}
                                                            {/*    mode="none"*/}
                                                            {/*    selectAllMode={"allPages"}*/}
                                                            {/*    showCheckBoxesMode={"always"}*/}
                                                            {/*/>*/}
                                                        </DataGrid>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                        {/*<div className="tab-pane fade show" id="dispatcher-calendar-vehicle" role="tabpanel" aria-labelledby="dispatcher-calendar-vehicle-tab">*/}
                        {/*    <div className="content">*/}
                        {/*        <div className="card card-grid" style={{ marginTop: 0 }}>*/}
                        {/*            <div className="card-body">*/}
                        {/*                <div className="row">*/}
                        {/*                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">*/}
                        {/*                        <div className="form-group btn-group custom-date-navigator">*/}
                        {/*                            <button type="button" className="btn btn-default btn-sm" onClick={this.prevDate}><i className="fas fa-chevron-left"></i></button>*/}
                        {/*                            <input className="form-control form-control-sm" type="text" value={this.state.currentGPSDisplayDate} onChange={this.handleInputChange} />*/}
                        {/*                            <button type="button" className="btn btn-default btn-sm" onClick={this.nextDate}><i className="fas fa-chevron-right"></i></button>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">*/}

                        {/*                        <div className="gps-container">*/}
                        {/*                            {*/}
                        {/*                                this.state.calAvailableVehicles.map((vitem, i) => (*/}
                        {/*                                    <div*/}
                        {/*                                        onDragOver={(e) => { this.handleDragOver(e) }}*/}
                        {/*                                        onDrop={(e) => this.handleOnDrop(e, vitem)}*/}
                        {/*                                        key={i}*/}
                        {/*                                        className="pending-container">*/}
                        {/*                                        <h6><strong>{vitem.displayName}</strong></h6>*/}
                        {/*                                        {this.state.calRideVehicleList && this.state.calRideVehicleList.filter(function (entry) { return entry.status === vitem.status && entry.rideId != "" }).map((ritem, i) => (*/}
                        {/*                                            <div*/}
                        {/*                                                onDragStart={(e) => { this.handleDragStart(e, ritem.rideId) }}*/}
                        {/*                                                key={ritem.rideId}*/}
                        {/*                                                //key={i}*/}
                        {/*                                                draggable*/}
                        {/*                                                className="draggable ride-container">{ritem.text}</div>*/}
                        {/*                                        ))}*/}
                        {/*                                    </div>*/}
                        {/*                                ))*/}
                        {/*                            }*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="tab-pane fade show" id="dispatcher-calendar-driver" role="tabpanel" aria-labelledby="dispatcher-calendar-driver-tab">*/}
                        {/*    <div className="content">*/}
                        {/*        <div className="card card-grid" style={{ marginTop: 0 }}>*/}
                        {/*            <div className="card-body">*/}
                        {/*                <div className="row">*/}
                        {/*                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">*/}
                        {/*                        <div className="form-group btn-group custom-date-navigator">*/}
                        {/*                            <button type="button" className="btn btn-default btn-sm" onClick={this.prevDate}><i className="fas fa-chevron-left"></i></button>*/}
                        {/*                            <input className="form-control form-control-sm" type="text" value={this.state.currentGPSDisplayDate} onChange={this.handleInputChange} />*/}
                        {/*                            <button type="button" className="btn btn-default btn-sm" onClick={this.nextDate}><i className="fas fa-chevron-right"></i></button>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">*/}
                        {/*                        <div className="gps-container">*/}
                        {/*                            {*/}
                        {/*                                this.state.calAvailableDrivers.map((ditem, i) => (*/}
                        {/*                                    <div*/}
                        {/*                                        onDragOver={(e) => { this.handleDragOver(e) }}*/}
                        {/*                                        onDrop={(e) => this.handleOnDriverDrop(e, ditem)}*/}
                        {/*                                        key={i}*/}
                        {/*                                        className="pending-container">*/}
                        {/*                                        <h6><strong>{ditem.driverName}</strong></h6>*/}
                        {/*                                        {this.state.calRideDriverList && this.state.calRideDriverList.filter(function (entry) { return entry.driverId === ditem.driverId && entry.rideId != "" }).map((ritem, i) => (*/}
                        {/*                                            <div*/}
                        {/*                                                onDragStart={(e) => { this.handleDragStart(e, ritem.rideId) }}*/}
                        {/*                                                key={ritem.rideId}*/}
                        {/*                                                //key={i}*/}
                        {/*                                                draggable*/}
                        {/*                                                className="draggable ride-container">{ritem.text}</div>*/}
                        {/*                                        ))}*/}
                        {/*                                    </div>*/}
                        {/*                                ))*/}
                        {/*                            }*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="tab-pane fade show" id="dispatcher-tracking" role="tabpanel" aria-labelledby="dispatcher-tracking-tab">*/}
                        {/*    <div className="content-wrapper">*/}
                        {/*        <div className="content" style={{ height: 73 + 'vh' }}>*/}
                        {/*            <iframe width="100%" height="100%" frameBorder="0" scrolling="no" allowtransparency="false" src={this.state.trackmapUrl}></iframe>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="tab-pane fade show" id="dispatcher-noshow" role="tabpanel" aria-labelledby="dispatcher-noshow-tab">*/}
                        {/*    <NoShowReservationsComponent ref="noShowReservations" />*/}
                        {/*</div>*/}
                        {/*<div className="tab-pane fade show" id="dispatcher-notification" role="tabpanel" aria-labelledby="dispatcher-notification-tab">*/}
                        {/*    <NotificationComponent ref="notificationData" />*/}
                        {/*</div>*/}
                    </div>
                </div>
                <aside className="control-sidebar control-sidebar-light p-3">
                    <h5>Advanced Search</h5>
                    <div className="row">                        
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="form-group">
                                <label htmlFor="">Ride Id</label>
                                <input type="text" className="form-control form-control-sm" name="rideId" placeholder="Enter ride id" value={this.state.rideId} onChange={this.handleInputChange} />
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="form-group">
                                <label>Driver</label>
                                <SelectBox
                                    dataSource={this.state.driverList.length > 0 ? this.state.driverList : []}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Select driver"
                                    name="id"
                                    defaultValue={0}
                                    value={this.state.driverId}
                                    displayExpr='name'
                                    valueExpr='id'
                                    onSelectionChanged={this.handleDriverChange}
                                    showClearButton={true}
                                    disabled={false}
                                    searchEnabled={true}
                                />
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="form-group">
                                <label>Vehicle Type</label>
                                <DropDownBox
                                    value={this.state.filterVehicleTypeList}
                                    valueExpr="vehicleTypeId"
                                    deferRendering={false}
                                    displayExpr="apiVehicleTypeDescription"
                                    placeholder="Select vehicle type..."
                                    showClearButton={true}
                                    dataSource={this.state.vehicleTypeList}
                                    onValueChanged={this.syncDataGridVehicleTypeSelection}
                                    contentRender={this.dataGridVehicleTypeRender}
                                />
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="form-group">
                                <label>Vehicles</label>
                                <DropDownBox
                                    value={this.state.filterVehicleList}
                                    valueExpr="id"
                                    deferRendering={false}
                                    displayExpr="name"
                                    placeholder="Select vehicle ..."
                                    showClearButton={true}
                                    dataSource={this.state.vehicleList}
                                    onValueChanged={this.syncDataGridVehicleSelection}
                                    contentRender={this.dataGridVehicleRender}
                                />
                            </div>
                        </div>
                        <div id="advanceSearch" className="container">
                            <div className="row">
                                {/*<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">*/}
                                {/*    <div className="form-group">*/}
                                {/*        <label>Status</label>*/}
                                {/*        <DropDownBox*/}
                                {/*            value={this.state.filterStatusList}*/}
                                {/*            valueExpr="id"*/}
                                {/*            deferRendering={false}*/}
                                {/*            displayExpr="name"*/}
                                {/*            placeholder="Select status..."*/}
                                {/*            showClearButton={true}*/}
                                {/*            dataSource={this.state.statusList}*/}
                                {/*            onValueChanged={this.syncDataGridStatusSelection}*/}
                                {/*            contentRender={this.dataGridStatusRender}*/}
                                {/*        />*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Vehicle Group Type</label>
                                        <DropDownBox
                                            value={this.state.filterVehicleGroupTypeList}
                                            valueExpr="id"
                                            deferRendering={false}
                                            displayExpr="name"
                                            placeholder="Select vehicle group..."
                                            showClearButton={true}
                                            dataSource={this.state.vehicleGroupTypesList}
                                            onValueChanged={this.syncDataGridVehicleGroupSelection}
                                            contentRender={this.dataGridVehicleGroupRender}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Location</label>
                                        <SelectBox
                                            dataSource={this.state.locationList.length > 0 ? this.state.locationList : []}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select location"
                                            name="locationId"
                                            defaultValue={0}
                                            value={this.state.locationId}
                                            displayExpr='locationName'
                                            valueExpr='locationId'
                                            onSelectionChanged={this.handleLocationChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 displayNone">
                                    <div className="form-group">
                                        <br />
                                        <span className="form-check">
                                            <input className="form-check-input" type="checkbox" id="isLive" name="isLive" checked={this.state.isLive} onChange={this.handleInputChange} />
                                            <label className="form-check-label" htmlFor="isLive"> Live</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="checkbox" id="isInHouse" name="isInHouse" checked={this.state.isInHouse} onChange={this.handleInputChange} />
                                            <label className="form-check-label" htmlFor="isInHouse"> In-House</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="checkbox" id="isFarmIn" name="isFarmIn" checked={this.state.isFarmIn} onChange={this.handleInputChange} />
                                            <label className="form-check-label" htmlFor="isFarmIn"> Farm In</label>
                                        </span>
                                        <span className="form-check">
                                            <input className="form-check-input" type="checkbox" id="isFarmOut" name="isFarmOut" checked={this.state.isFarmOut} onChange={this.handleInputChange} />
                                            <label className="form-check-label" htmlFor="isFarmOut"> Farm out</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-left"><br />
                            <button type="submit" className="btn btn-success btn-sm" onClick={this.handleSearch} data-widget="control-sidebar" data-slide="true">
                                {this.state.isAdvancedSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin" title="In-process"></i> In-process</span>}
                                {!this.state.isAdvancedSubmited && <span title="Search"><i className="fas fa-search" title="Search"></i> Adavanced Search</span>}
                            </button>&nbsp;&nbsp;
                            <button type="submit" className="btn btn-custom-gray btn-sm" data-widget="control-sidebar" data-slide="true" onClick={this.handleClearSearch} title="Clear"><i className="fas fa-eraser"></i> Close</button>
                        </div>
                    </div>
                </aside>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }

    //dataGridStatusRender() {
    //    return (
    //        <DataGrid
    //            dataSource={this.state.statusList}
    //            id="grid-container"
    //            keyExpr="id"
    //            hoverStateEnabled={true}
    //            selectedRowKeys={this.state.filterStatusList}
    //            onSelectionChanged={this.handleStatusChange}
    //            ref={ref => this.dataGrid = ref}>
    //            <Column dataField="name" caption="Location" />
    //            <Selection mode="multiple" />
    //            <Scrolling mode="both" />
    //            <Paging enabled={true} pageSize={5} />
    //            <FilterRow visible={true} />
    //        </DataGrid>
    //    );
    //}

    dataGridVehicleGroupRender() {
        return (
            <DataGrid
                dataSource={this.state.vehicleGroupTypesList}
                id="grid-container"
                keyExpr="id"
                hoverStateEnabled={true}
                selectedRowKeys={this.state.filterVehicleGroupTypeList}
                onSelectionChanged={this.handleVehicleGroupTypeChange}
                ref={ref => this.dataGrid = ref}>
                <Column dataField="name" caption="Vehicle Group" />
                <Selection mode="multiple" />
                <Scrolling mode="both" />
                <Paging enabled={true} pageSize={5} />
                <FilterRow visible={true} />
            </DataGrid>
        );
    }

    dataGridVehicleTypeRender() {
        return (
            <DataGrid
                dataSource={this.state.vehicleTypeList}
                id="grid-container"
                keyExpr="vehicleTypeId"
                hoverStateEnabled={true}
                selectedRowKeys={this.state.filterVehicleTypeList}
                onSelectionChanged={this.handleVehicleTypeChange}
                ref={ref => this.dataGrid = ref}>
                <Column dataField="apiVehicleTypeDescription" caption="Vehicle Type" />
                <Selection mode="multiple" />
                <Scrolling mode="both" />
                <Paging enabled={true} pageSize={5} />
                <FilterRow visible={true} />
            </DataGrid>
        );
    }

    dataGridVehicleRender() {
        return (
            <DataGrid
                dataSource={this.state.vehicleList}
                id="grid-container"
                keyExpr="id"
                hoverStateEnabled={true}
                selectedRowKeys={this.state.filterVehicleList}
                onSelectionChanged={this.handleVehicleChange}
                ref={ref => this.dataGrid = ref}>
                <Column dataField="name" caption="Vehicle" />
                <Selection mode="multiple" />
                <Scrolling mode="both" />
                <Paging enabled={true} pageSize={8} />
                <FilterRow visible={true} />
            </DataGrid>
        );
    }
}