import React, { Component, useDebugValue } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
    Editing
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import { VehiclePayLogHistoryComponent } from './VehiclePayLogHistoryComponent';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions';
import { LoadPanel } from 'devextreme-react/load-panel';
import { SelectBox } from 'devextreme-react/select-box';

const position = { of: '#historydiv' };
let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));

export class ReimbursementHistoryComponent extends Component {
    constructor() {
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        super();
        this.state = {
            isSubmited: false,
            reimbursementPeriodlist: [],
            reimbursementPeriodPayDate: null,
            reimbursementHistoryList: [],
            updateEmailModalIsOpen: false,
            franchiseeId: 0,
            franchiseeEmail: null,
            loadPanelVisible: false,
            checkBoxesMode: 'onClick',
            emailLogModalIsOpen: false,
            emailLogList: [],
            payId: 0,
            errors: {
                franchiseeEmail: ''
            }
        }
        this.handleReimbursementPeriodChange = this.handleReimbursementPeriodChange.bind(this);
        this.handleSendReportsToFranchisee = this.handleSendReportsToFranchisee.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFormValidation = this.handleFormValidation.bind(this);
        this.handleUpdateEmailSubmit = this.handleUpdateEmailSubmit.bind(this);
        this.updateFranchiseeEmailControl = this.updateFranchiseeEmailControl.bind(this);
        this.emailLogControl = this.emailLogControl.bind(this);
    }

    async componentDidMount() {
        await this.getReimbursementPeriodList();
        await this.getReimbursementHistoryList(null);
    };
    async getReimbursementPeriodList() {
        try {
            var request = new Object();
            request.VehicleId = 0;
            request.VehicleOwnerId = 0;
            request.PayDate = '';
            request.ChequeNo = '';
            request.IsTransactionType = true;
            request.PayrollType = 1;
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('vehicle-payment-api/payroll-pedriod-dropdown/', requestParams);
            const data = await response.json();
            if (data !== null && data.vehiclePayRollPeriods.length > 0) {
                this.setState({
                    reimbursementPeriodlist: data.vehiclePayRollPeriods
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    async getReimbursementHistoryList(PeriodPayDate) {
        this.setState({
            loadPanelVisible: true
        });
        let reimbursmentPeriodEnd;
        if (PeriodPayDate !== null) {
            reimbursmentPeriodEnd = formatDate(PeriodPayDate, "MM/dd/yyyy").toString() + " 23:59:59";
        } else {
            reimbursmentPeriodEnd = formatDate(currentTime, "MM/dd/yyyy").toString() + " 23:59:59";
        }

        var request = new Object();
        request.VehicleOwnerId = 0;
        request.VehicleId = 0;
        request.PayDate = reimbursmentPeriodEnd;
        request.CheckNo = '';
        request.VehicleOwnerMapId = 0;

        const requestParams = getRequestParams('POST', request);
        const response = await fetch('reimbursement-history-api/list', requestParams);
        const data = await response.json();
        if (data !== null && data.vehiclePay.length > 0) {
            this.setState({
                reimbursementHistoryList: data.vehiclePay,
                loadPanelVisible: false
            });
        } else {
            this.setState({
                reimbursementHistoryList: [],
                loadPanelVisible: false
            });
        }
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getReimbursementHistoryList()
            }
        });
    }
    handleReimbursementPeriodChange = (selectedOption) => {
        try {
            let payPeriodDate = null;
            if (selectedOption !== null && selectedOption.selectedItem !== null) {
                payPeriodDate = selectedOption.selectedItem.payDate;
            }
            this.setState({ reimbursementPeriodPayDate: payPeriodDate })
        } catch (ex) {
            console.log(ex);
        }
    }

    async handleSendReportsToFranchisee(e) {
        e.preventDefault();
        const isValid = await Swal.fire({
            title: "<small>Pending Implementation</small>",
            text: "This feature is comming soon.",
            icon: "info",
            dangerMode: false,
            width: "25%",
            dangerMode: false,
            allowOutsideClick: false
        });
        return;
    }
    async handleSearch(e) {
        e.preventDefault();
        this.setState({ isSubmited: true });
        await this.getReimbursementHistoryList(this.state.reimbursementPeriodPayDate);
        this.setState({ isSubmited: false });
    }
    async handleClearSearch() {
        this.setState({
            reimbursementPeriodPayDate: null
        });
        await this.getReimbursementHistoryList(null);
    }
    updateFranchiseeEmailControl(e, data) {
        e.preventDefault();
        if (data != null) {
            this.setState({
                updateEmailModalIsOpen: true,
                franchiseeId: data.franchiseeId,
                franchiseeEmail: data.franchiseeEmail
            });
        }
        else {
            this.setState({
                updateEmailModalIsOpen: true,
                franchiseeEmail: null
            });
        }
    }
    handleReimbursementHistoryModal(e, data) {
        e.preventDefault();
        try {
            this.refs.vehiclePayLogHistoryComponent.showModal('reimbursement-history-api/', 'Vehicle', data.vehicleNo, Number(data.vehiclePayId), Number(data.vehicleId));
        } catch (ex) {
            console.log(ex);
        }
    };
    emailLogControl(e, data) {
        e.preventDefault();
        this.setState({
            emailLogModalIsOpen: true,
            payId: data.vehiclePayId
        });
    }
    emailLogDataModal = async () => {
        this.setState({
            loadPanelVisible: true
        });
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('reimbursement-history-api/vehiclePayEmailLoglist/' + this.state.payId, requestParams);
        const data = await response.json();
        if (data !== null && data.vehiclePayEmailLog.length > 0) {
            this.setState({
                emailLogList: data.vehiclePayEmailLog,
                loadPanelVisible: false
            });
        } else {
            this.setState({
                emailLogList: [],
                loadPanelVisible: false
            });
        }
    }
    closeEmailLogModal = () => {
        this.setState({
            emailLogModalIsOpen: false,
        });
    }
    updateEmailDataModal = () => {
    }
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let errors = this.state.errors;

        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        switch (name) {
            case 'franchiseeEmail':
                errors.franchiseeEmail = "";
                if (event.target.value === "") {
                    this.setState({ franchiseeEmail: event.target.value })
                    errors.franchiseeEmail = "Please enter email.";
                } else if (regEmail.test(event.target.value) !== true) {
                    this.setState({ franchiseeEmail: event.target.value })
                    errors.franchiseeEmail = "Please enter valid email.";
                } else {
                    this.setState({ franchiseeEmail: event.target.value })
                }
                break;
            default:
                this.setState({
                    [name]: value
                });
                break;
        }
    }
    handleFormValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.franchiseeEmail = "";
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (this.state.franchiseeEmail === "") {
            formIsValid = false;
            errors.franchiseeEmail = "Please enter email.";
        }
        if (this.state.franchiseeEmail !== "") {
            if (!regEmail.test(this.state.franchiseeEmail)) {
                formIsValid = false;
                errors.franchiseeEmail = "Please enter valid email.";
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    async handleUpdateEmailSubmit(e) {
        e.preventDefault();
        if (this.handleFormValidation()) {

            const requestParams = getRequestParams('POST', '');
            const response = await fetch('/reimbursement-history-api/updateEmail/' + this.state.franchiseeId + "/" + this.state.franchiseeEmail, requestParams);
            const data = await response.json();
            Swal.fire({
                icon: data.isSuccess ? 'success' : 'error',
                title: data.isSuccess ? 'Success' : 'Oops...',
                text: data.resultMessage,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (data.isSuccess) {
                    if (result.isConfirmed) {
                        this.closeUpdateEmailModal();
                        this.getReimbursementHistoryList(this.state.reimbursementPeriodPayDate);
                    }
                } else {
                    this.setState({ isSubmited: false })
                }
            })
        } else {
            this.setState({ isSubmited: false })
        }
    }

    closeUpdateEmailModal = () => {
        this.setState({
            isSubmited: false,
            updateEmailModalIsOpen: false,
            franchiseeEmail: null,
            franchiseeId: 0,
            errors: {
                franchiseeEmail: "",
            }
        });
    }
    render() {

        const checkBoxesMode = this.state.checkBoxesMode;
        const renderUpdateEmailCell = (data) => {
            return <div> <a title="Update Email" className="fas fa-pen icon text-info" onClick={(e) => this.updateFranchiseeEmailControl(e, data.data)}></a> &nbsp;&nbsp;
                <a title="Email Log" className="fas fa-envelope icon text-info" onClick={(e) => this.emailLogControl(e, data.data)}></a> &nbsp;&nbsp;
                <a href="#" className="fas fa-history icon text-info" title="History" onClick={(e) => { this.handleReimbursementHistoryModal(e, data.data) }}></a>

            </div>;
        }
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <h1 className="m-0 text-dark">Reimbursement History</h1>
                            </div>
                            <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem>
                                        <NavLink tag={Link} to="/generate/payout" className="btn btn-block btn-outline-secondary btn-sm" title="Generate Payout">
                                            <i className="fas fa-file-invoice-dollar"></i> Generate Payout</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="/post/payout" className="btn btn-block btn-outline-secondary btn-sm" title="Post Ride(s)">
                                            <i className="fas fa-check"></i> Post Ride(s)</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="/reimbursement/view" className="btn btn-block btn-outline-secondary btn-sm" title="Reimbursement View">
                                            <i className="fas fa-car"></i> Reimb. View</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="/peding/postrides" className="btn btn-block btn-outline-secondary btn-sm" title="Pending Post Ride(s)">
                                            <i className="fas fa-clock"></i> Pending Post Ride(s)</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="/posted/rides" className="btn btn-block btn-outline-secondary btn-sm" title="Posted Ride(s)">
                                            <i className="fas fa-check-double"></i> Posted Ride(s)</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="/reimbursement/payperiod" className="btn btn-block btn-outline-secondary btn-sm" title="Reimbursement Pay Period">
                                            <i className="fas fa-calendar-alt"></i> Reimb. Pay Period</NavLink>
                                    </NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <form method="post" onSubmit={this.handleSearch} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="rez-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <div className="form-group">
                                        <label>Reimbursement Period</label>
                                        {/*<Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select period"
                                            name="reimbursementPeriodId"
                                            options={this.state.reimbursementPeriodlist.length > 0 ? this.state.reimbursementPeriodlist : []}
                                            getOptionLabel={option => option.viewDate}
                                            getOptionValue={option => option.payDate}
                                            defaultValue={[]}
                                            value={this.state.reimbursementPeriodId === 0 ? [] : [this.state.reimbursementPeriodlist.find(opt => opt.payDate == this.state.reimbursementPeriodPayDate)]}
                                            onChange={this.handleReimbursementPeriodChange}
                                            isMulti={false}
                                            isClearable={true}
                                            isDisabled={false}
                                            isSearchable={true}
                                            isClearable={false}
                                        />*/}
                                        <SelectBox
                                            dataSource={this.state.reimbursementPeriodlist}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select period"
                                            name="ddpayperiod"
                                            defaultValue={''}
                                            value={this.state.reimbursementPeriodPayDate}
                                            displayExpr='viewDate'
                                            valueExpr='payDate'
                                            onSelectionChanged={this.handleReimbursementPeriodChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-left">
                                    <br />
                                    <button type="submit" className="btn btn-success btn-sm" onClick={this.handleSearch} >
                                        {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin" title="In-process"></i> In-process</span>}
                                        {!this.state.isSubmited && <span title="Search"><i className="fas fa-search" title="Search"></i> Search</span>}
                                    </button>&nbsp;&nbsp;
                                    <button type="submit" className="btn btn-custom-gray btn-sm" onClick={this.handleClearSearch} title="Clear"><i className="fas fa-search"></i> Clear</button>&nbsp;&nbsp;
                                    <button type="submit" className="btn btn-success btn-sm" onClick={this.handleSendReportsToFranchisee} title="Send Reports to Franchisee" disabled={this.state.reimbursementHistoryList.length > 0 ? false : true}><i className="fas fa-envelope"></i> Send Reports to Franchisee</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="content">
                    <div className="card card-grid">
                        <div className="card-body">
                            <DataGrid
                                dataSource={this.state.reimbursementHistoryList}
                                keyExpr="franchiseeId"
                                columnsAutoWidth={false}
                                showColumnLines={false}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                showBorders={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                onToolbarPreparing={this.onToolbarPreparing}>
                                <Editing
                                    mode="cell"
                                    allowUpdating={true} />

                                <Column dataField="isSendReport" width={50} caption="" allowFiltering={false} allowSorting={false} Selection={checkBoxesMode} mode="multiple" alignment="center" allowEditing={true} />
                                <Column dataField="vehiclePayId" width={80} caption="" allowFiltering={false} allowSorting={false} cellRender={renderUpdateEmailCell} />
                                <Column dataField="vehicleNo" caption="Vehicle Number" allowFiltering={true} allowSorting={true} width="120" allowEditing={false} />
                                <Column dataField="vehicleTypeName" caption="Vehicle Type" allowFiltering={true} allowSorting={true} width="100" allowEditing={false} />
                                <Column dataField="franchiseeName" caption="Franchisee Name" allowFiltering={true} allowSorting={true} width="150" allowEditing={false} />
                                <Column dataField="franchiseeEmail" caption="Franchisee Email" allowFiltering={true} allowSorting={true} width="150" allowEditing={false} />
                                <Column dataField="balanceForward" caption="Balance Forward ($)" allowFiltering={true} allowSorting={true} width="140" allowEditing={false} />
                                <Column dataField="rideNet" caption="Regular($)" allowFiltering={true} allowSorting={true} width="150" allowEditing={false} />
                                <Column dataField="dedTaken" caption="Deduction Charge" allowFiltering={true} allowSorting={true} width="100" allowEditing={false} />
                                <Column dataField="totalPaid" caption="Net Due" allowFiltering={true} allowSorting={true} width="100" allowEditing={false} />
                                <Column dataField="emailedOnStr" caption="Last Email Process Date" allowFiltering={true} allowSorting={true} width="150" allowEditing={false} />
                                <Column dataField="emailStatus" caption="Email Status" allowFiltering={true} allowSorting={true} width="150" allowEditing={false} />
                                <SortByGroupSummaryInfo summaryItem="count" />
                                <GroupPanel visible={true} />
                                <HeaderFilter visible={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                <Grouping autoExpandAll={true} />
                                <Paging defaultPageSize={20} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true}/>
                                <Export enabled={true} allowExportSelectedData={false} />
                                <ColumnChooser enabled={true} />
                                <ColumnFixing enabled={true} />

                            </DataGrid>
                        </div>
                    </div>
                </div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.updateEmailModalIsOpen}
                    className={"react-modal update-email-modal"}
                    onAfterOpen={this.updateEmailDataModal}
                    onRequestClose={this.closeUpdateEmailModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal"
                >
                    <form method="post" onSubmit={this.handleUpdateEmailSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Update Email</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeUpdateEmailModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group">
                                        <label>Email <span className="text-red">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="franchiseeEmail" placeholder="Enter email address" title="Email" value={this.state.franchiseeEmail} onChange={this.handleInputChange} />
                                        {this.state.errors["franchiseeEmail"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["franchiseeEmail"]}</span>}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success btn-sm" onClick={this.handleUpdateEmailSubmit} >
                                {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmited && <span title="Submit"><i className="fas fa-check"></i> Submit</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeUpdateEmailModal} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
                <Modal ariaHideApp={false}
                    isOpen={this.state.emailLogModalIsOpen}
                    className={"react-modal email-log-modal"}
                    onAfterOpen={this.emailLogDataModal}
                    onRequestClose={this.closeEmailLogModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal"
                >
                    <div className="modal-header">
                        <h6 className="modal-title">Email Log</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeEmailLogModal}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <DataGrid
                                    dataSource={this.state.emailLogList}
                                    columnsAutoWidth={false}
                                    showColumnLines={false}
                                    showRowLines={true}
                                    showBorders={true}
                                    rowAlternationEnabled={true}
                                    showBorders={true}
                                    allowColumnResizing={true}
                                >
                                    <Column dataField="emailId" caption="Email Id" allowFiltering={true} allowSorting={true} width="190" />
                                    <Column dataField="processedOn" caption="Process Date" allowFiltering={true} allowSorting={true} width="130" />
                                    <Column dataField="emailStatus" caption="Email Status" allowFiltering={true} allowSorting={true} width="130" />
                                    <Column dataField="failureReason" caption="Reason" allowFiltering={true} allowSorting={true} width="130" />
                                    <Column dataField="isManualSend" caption="Is Manual Send" allowFiltering={true} allowSorting={true} width="130" />
                                    <SortByGroupSummaryInfo summaryItem="count" />
                                    <GroupPanel visible={true} />
                                    <HeaderFilter visible={true} />
                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                    <Grouping autoExpandAll={true} />
                                    <Paging enabled={true} defaultPageSize={5} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20, 50]} showInfo={true} />
                                    <Export enabled={true} allowExportSelectedData={false} />
                                    <ColumnChooser enabled={true} />
                                    <ColumnFixing enabled={true} />
                                </DataGrid>
                            </div>

                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.closeEmailLogModal} title="Close"><i className="fas fa-times"></i> Close</button>
                    </div>
                </Modal>
                <VehiclePayLogHistoryComponent ref="vehiclePayLogHistoryComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        )
    }
}