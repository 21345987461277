import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import DateBox from 'devextreme-react/date-box';
import NumberFormat from 'react-number-format';
import { NotificationSettingComponent } from './NotificationSettingComponent'
import { HotelMappingComponent } from './HotelMappingComponent'
import { CommissionAgentComponent } from './CommissionAgentComponent'
import { TaxSettingComponent } from './TaxSettingComponent'
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import { PaymentType } from '../../helpers/fixcodes.js'
import { LoadPanel } from 'devextreme-react/load-panel';


const customStyles = {
    content: {
        width: '50%',
    }
};
const position = { of: '#historydiv' };

export class CustomerSettingComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rowId: 0,
            isOpenModal: false,
            isSubmited: false,
            customerDetails: null,
            wiringInstructionsList: null,
            notificationSettingGridDataSource: null,
            customerSettingDetails: null,
            activeTab: null,
            rateTypeList: null,
            rateTypeId: 0,
            isfixrateforallzone: false,
            wireInstructionId: 0,
            billingCycleList: null,
            billingCycleId: 0,
            creditCardTypeList: null,
            creditCardTypeId: 0,
            paymentTypeList: null,
            paymentTypeId: 0,
            fromDate: new Date(),
            toDate: new Date(),
            creditLimit: 0,
            creditCardNumber: "",
            expiryMonth: "",
            expiryYear: "",
            cvv2: "",
            auto: false,
            manual: false,
            giveReminder: false,
            email: false,
            print: false,
            fax: false,
            view: false,
            commissionpaytovehicle: false,
            salesTax: false,
            countryTax: false,
            congestionTax: false,
            utilityTax:false,
            gstTax: false,
            vehicleCommission: 0,
            discount: 0,
            taxOptionsIncludeinfare: false,
            taxOptionsAddtofare: false,
            taxOptionsExempted: false,
            discountPercentage: false,
            discountAmount: false,
            portAuthority: false,
            loadPanelVisible: false,
            errors: {
                rateTypeId: "",
                wireInstructionId: "",
                billingCycleId: "",
                paymentTypeId: "",
                creditCardNumber: ""
            },

        }
        this.showModal = this.showModal.bind(this);
        this.handleFromDateChange = this.handleFromDateChange.bind(this);
        this.handleToDateChange = this.handleToDateChange.bind(this);
        this.handleCustomerSettingSubmit = this.handleCustomerSettingSubmit.bind(this);
        this.OpenCutomerSettingDataModal = this.OpenCutomerSettingDataModal.bind(this);
    }
    componentDidMount = () => {
    }

    handleFromDateChange(event) {
        let fromDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ fromDate: fromDateValue })
    }
    handleToDateChange(event) {
        let toDateValue = formatDate(event.value, "MM/dd/yyyy");
        this.setState({ toDate: toDateValue })
    }


    async getRateTypeList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('ratetype-api/', requestParams);

        const data = await response.json();
        if (data !== null && data.rateType.length > 0) {
            this.setState({
                rateTypeList: data.rateType
            });
        }
    }
    async getwiringinstructions() {

        var request = new Object();
        request.isListView = true;
        request.accountCustomerSettingWireInstructionId = 0;

        const requestParams = getRequestParams('POST', request);
        const response = await fetch('customer-api/wiringInstruction/0', requestParams);
        const data = await response.json();
        this.setState({
            wiringInstructionsList: data.wiringInstructions
        });
    }
    async getBillingCycleList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('billingCycle-api/', requestParams);

        const data = await response.json();
        if (data !== null && data.billingCycle.length > 0) {
            this.setState({
                billingCycleList: data.billingCycle
            });
        }
    }
    async getCreditCardTypeList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('creditcardType-api/dropdownlist', requestParams);

        const data = await response.json();
        if (data !== null && data.creditcardType.length > 0) {
            this.setState({
                creditCardTypeList: data.creditcardType
            });
        }
    }
    async getPaymentTypeList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('paymenttype-api/dropdownlist', requestParams);

        const data = await response.json();
        if (data !== null && data.paymentType.length > 0) {
            this.setState({
                paymentTypeList: data.paymentType
            });
        }
    }

    showModal(data) {
        this.setState({ isOpenModal: true, customerDetails: data })
    }
    async OpenCutomerSettingDataModal() {
        this.setState({
            loadPanelVisible: true
        });
        this.getRateTypeList();
        this.getwiringinstructions();
        this.getBillingCycleList();
        this.getCreditCardTypeList();
        this.getPaymentTypeList();

        if (this.state.customerDetails !== null) {
            const requestParams = getRequestParams('GET', '');
            const response = await fetch('customer-api/customerSetting/' + this.state.customerDetails.accountId, requestParams);
            const data = await response.json();
            if (data.customerSetting !== null && data.customerSetting.length > 0) {
                debugger;
                let ExpYear = "";
                let month = ExpYear[0];
                let year = ExpYear[1];
                this.setState({
                    rateTypeId: data.customerSetting[0].rateType !== null ? data.customerSetting[0].rateType.rateTypeId : 0,
                    isfixrateforallzone: data.customerSetting[0].isfixedRate === true ? true : false,
                    wireInstructionId: data.customerSetting[0].wireInstructionId !== null ? data.customerSetting[0].wireInstructionId : 0,
                    billingCycleId: data.customerSetting[0].billingCycleId !== null ? data.customerSetting[0].billingCycleId : 0,
                    fromDate: data.customerSetting[0].billingCycleId === 5 ? new Date(data.customerSetting[0].billingFromDate) : new Date(),
                    toDate: data.customerSetting[0].billingCycleId === 5 ? new Date(data.customerSetting[0].billingToDate) : new Date(),
                    paymentTypeId: data.customerSetting[0].paymentTypeId !== null ? data.customerSetting[0].paymentTypeId : 0,
                    creditLimit: data.customerSetting[0].creditLimit === 0 ? 0 : data.customerSetting[0].creditLimit,
                    //creditCardNumber: data.customerSetting[0].ccNo !== null || data.customerSetting[0].ccNo !== "" ? data.customerSetting[0].ccNo : "",
                    //expiryMonth: month !== null || month !== "" ? month : "",
                    //expiryYear: year !== null || year !== "" ? year : "",
                    //cvv2: data.customerSetting[0].cccvV2 !== null || data.customerSetting[0].cccvV2 !== "" ? data.customerSetting[0].cccvV2 : "",
                    auto: data.customerSetting[0].billAuto === true ? true : false,
                    manual: data.customerSetting[0].billAuto === false ? true : false,
                    giveReminder: data.customerSetting[0].billReminder === true ? true : false,
                    email: data.customerSetting[0].billEmail === true ? true : false,
                    print: data.customerSetting[0].billPrint === true ? true : false,
                    fax: data.customerSetting[0].billFax === true ? true : false,
                    view: data.customerSetting[0].billView === true ? true : false,
                    vehicleCommission: data.customerSetting[0].vehicleCommission === 0 ? 0 : data.customerSetting[0].vehicleCommission,
                    commissionpaytovehicle: data.customerSetting[0].vehicleCommission === "" ? false : true,
                    discountPercentage: data.customerSetting[0].isPercent === true ? true : false,
                    discountAmount: data.customerSetting[0].isAmount === true ? true : data.customerSetting[0].isPercent === false ? true : false,//default discount amount checked
                    discount: data.customerSetting[0].discount === 0 ? 0 : data.customerSetting[0].discount,
                    salesTax: data.customerSetting[0].taxSalesApplicable === true ? true : false,
                    countryTax: data.customerSetting[0].taxCountyApplicable === true ? true : false,
                    congestionTax: data.customerSetting[0].taxCongestionApplicable === true ? true : false,
                    utilityTax: data.customerSetting[0].taxUtilityApplicable === true ? true : false,
                    gstTax: data.customerSetting[0].taxGstApplicable === true ? true : false,
                });

                this.setState({
                    taxOptionsAddtofare: false, taxOptionsIncludeinfare: false, taxOptionsAddtofare: false
                });

                if (data.customerSetting[0].taxInclude === 1) {
                    this.setState({
                        taxOptionsExempted: true,
                        portAuthority: false
                    });
                    document.getElementById("portAuthorityArea").style.display = "none";
                } else if (data.customerSetting[0].taxInclude === 2) {
                    this.setState({
                        taxOptionsIncludeinfare: true
                    });
                    document.getElementById("portAuthorityArea").style.display = "inline-block";
                } else if (data.customerSetting[0].taxInclude === 3) {
                    this.setState({
                        taxOptionsAddtofare: true
                    });
                    document.getElementById("portAuthorityArea").style.display = "inline-block";
                }

                if (data.customerSetting[0].taxApplicable === 1) {
                    this.setState({
                        portAuthority: true
                    });
                }
                else {
                    this.setState({
                        portAuthority: false
                    });
                }
            }
        }
        else {
            this.setState({
                rateTypeId: 0,
                isfixrateforallzone: false,
                wireInstructionId: 0,
                billingCycleId: 0,
                fromDate: new Date(),
                toDate: new Date(),
                paymentTypeId: 0,
                creditLimit: 0,
                creditCardNumber: "",
                expiryMonth: "",
                expiryYear: "",
                cvv2: "",
                auto: false,
                manual: false,
                giveReminder: false,
                email: false,
                print: false,
                fax: false,
                view: false,
                vehicleCommission: 0,
                commissionpaytovehicle: false,
                discountPercentage: false,
                discountAmount: true,
                discount: 0,
                salesTax: false,
                countryTax: false,
                congestionTax: false,
                utilityTax: false,
                gstTax: false,
                taxOptionsExempted: false,
                portAuthority: false,
                taxOptionsIncludeinfare: false,
                taxOptionsAddtofare: false,
                portAuthority: false
            })
        }
        this.setState({
            loadPanelVisible: false
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let errors = this.state.errors;

        switch (name) {
            case 'rateTypeId':
                errors.rateTypeId = "";
                if (Number(event.target.value) === 0) {
                    this.setState({ rateTypeId: event.target.value })
                    errors.rateTypeId = "Please select rate type.";
                }
                else {
                    this.setState({ rateTypeId: Number(event.target.value) })
                }
                break;
            case 'wireInstructionId':
                this.setState({ wireInstructionId: Number(event.target.value) })
            //errors.wireInstructionId = "";
            //if (Number(event.target.value) === 0) {
            //    this.setState({ wireInstructionId: event.target.value })
            //    errors.wireInstructionId = "Please select wiring Instruction.";
            //}
            //else {
            //    this.setState({ wireInstructionId: Number(event.target.value) })
            //}
            //break;
            case 'billingCycleId':
                errors.billingCycleId = "";
                if (Number(event.target.value) === 0) {
                    this.setState({ billingCycleId: event.target.value })
                    errors.billingCycleId = "Please select billing cycle.";
                }
                else {
                    this.setState({ billingCycleId: Number(event.target.value) })
                }
                break;
            case 'paymentTypeId':
                errors.paymentTypeId = "";
                if (Number(event.target.value) === 0) {
                    this.setState({ paymentTypeId: event.target.value })
                    errors.paymentTypeId = "Please select payment type.";
                }
                else {
                    this.setState({ paymentTypeId: Number(event.target.value) })
                }
                break;
            case 'cvv2':
                if (event.target.value !== "" && event.target.value !== null) {
                    this.setState({ cvv2: Number(event.target.value) })

                }
                break;
            case 'creditLimit':
                if (event.target.value !== "" && event.target.value !== null) {
                    this.setState({ creditLimit: parseFloat(event.target.value) })
                }
                break;
            case 'vehicleCommission':
                if (event.target.value !== "" && event.target.value !== null) {
                    this.setState({ vehicleCommission: parseFloat(event.target.value) })
                }
                break;
            case 'discount':
                if (event.target.value !== "" && event.target.value !== null) {
                    this.setState({ discount: parseFloat(event.target.value) })
                }
                break;

            //case 'creditCardNumber':
            //    errors.creditCardNumber = "";
            //    if (Number(this.state.paymentTypeId) === 2) {
            //        if (event.target.value === "") {
            //            this.setState({ creditCardNumber: event.target.value })
            //            errors.creditCardNumber = "Please enter Credit Card Number.";
            //        } else {
            //            if (event.target.value.trim().length !== 19) {
            //                this.setState({ creditCardNumber: event.target.value })
            //                errors.creditCardNumber = "Please enter valid Credit Card Number.";
            //            }
            //            else {
            //                this.setState({ creditCardNumber: event.target.value })
            //            }
            //        }
            //    }
            //    break;
            default:
                this.setState({
                    [name]: value
                });
                break;
        }
    }
    handleCheckBoxRadioOnChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' || target.type === 'radio' ? target.checked : target.value;
        const name = target.name;
        if (name === "taxOptionsIncludeinfare") {
            if (event.target.checked === true) {
                this.setState({ taxOptionsIncludeinfare: event.target.checked, taxOptionsAddtofare: false, taxOptionsExempted: false })
                document.getElementById("portAuthorityArea").style.display = "inline-block";
            }
        }
        else if (name === "taxOptionsAddtofare") {
            if (event.target.checked === true) {
                this.setState({ taxOptionsAddtofare: event.target.checked, taxOptionsIncludeinfare: false, taxOptionsExempted: false })
                document.getElementById("portAuthorityArea").style.display = "inline-block";
            }
        }
        else if (name === "taxOptionsExempted") {
            if (event.target.checked === true) {
                this.setState({ taxOptionsExempted: event.target.checked, taxOptionsIncludeinfare: false, taxOptionsAddtofare: false })
                document.getElementById("portAuthorityArea").style.display = "none";
                this.setState({ portAuthority: false });
            }
        }
        else if (name === "discountPercentage") {
            if (event.target.checked === true) {
                this.setState({ discountPercentage: event.target.checked, discountAmount: false })
            }
        }
        else if (name === "discountAmount") {
            if (event.target.checked === true) {
                this.setState({ discountAmount: event.target.checked, discountPercentage: false })
            }
        }
        else if (name === "auto") {
            if (event.target.checked === true) {
                this.setState({ auto: event.target.checked, manual: false })
            }
        }
        else if (name === "manual") {
            if (event.target.checked === true) {
                this.setState({ manual: event.target.checked, auto: false })
            }
        } else {
            this.setState({
                [name]: value
            });
        }
    }
    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.rateTypeId = "";
        errors.wireInstructionId = "";
        errors.billingCycleId = "";
        errors.paymentTypeId = "";

        if (this.state.rateTypeId === 0) {
            formIsValid = false;
            errors.rateTypeId = "Please select Rate type.";
        }
        //if (this.state.wireInstructionId === 0) {
        //    formIsValid = false;
        //    errors.wireInstructionId = "please enter Wiring Instructions.";
        //}
        if (this.state.billingCycleId === 0) {
            formIsValid = false;
            errors.billingCycleId = "Please select Billing Cycle.";
        }
        if (this.state.paymentTypeId === 0) {
            formIsValid = false;
            errors.paymentTypeId = "Please select Payment Type.";
        } else {
            //if (Number(this.state.paymentTypeId) === 2) {
            //    if (this.state.creditCardNumber !== null || this.state.creditCardNumber !== "") {
            //        if (this.state.creditCardNumber.trim().length !== 19) {
            //            formIsValid = false;
            //            errors.creditCardNumber = "Please enter valid Credit Card Number.";
            //        }
            //    }
            //    if (this.state.expiryMonth !== null && this.state.expiryMonth !== "") {
            //    }
            //    if (this.state.expiryYear !== null && this.state.expiryYear !== "") {
            //    }
            //}
            //this.setState({ errors: errors });            
        }
        return formIsValid;
    }
    async handleCustomerSettingSubmit(e) {
        this.setState({ isSubmited: true });
        e.preventDefault();
        if (this.handleValidation()) {
            var request = new Object();
            request.RateType = this.state.rateTypeId;
            request.BillingCycle = this.state.billingCycleId;
            request.BillingFromDate = this.state.fromDate;
            request.BillingToDate = this.state.toDate;
            request.BillAuto = this.state.auto;
            request.BillReminder = this.state.giveReminder;
            request.BillEmail = this.state.email;
            request.BillPrint = this.state.print;
            request.BillView = this.state.view;
            request.BillFax = this.state.fax;
            request.BillToCustomerId = 0; //Default 0 in current flow 
            request.BillToCustomerCode = "";
            request.PaymentType = this.state.paymentTypeId;
            //request.CCType = this.state.creditCardTypeId;
            //request.CCNo = this.state.creditCardNumber;
            //request.CCExp = (this.state.expiryMonth + '/' + this.state.expiryYear);
            //request.CCCVV2 = Number(this.state.cvv2); Last Solved problem when api call
            request.CreditLimit = this.state.creditLimit;
            if (this.state.taxOptionsIncludeinfare === true) {
                request.TaxInclude = 2;
            } else if (this.state.taxOptionsAddtofare === true) {
                request.TaxInclude = 3;
            } else if (this.state.taxOptionsExempted === true) {
                request.TaxInclude = 1;
            }
            if (this.state.portAuthority === true) {
                request.TaxApplicable = 1;
            } else {
                request.TaxApplicable = 0;
            }
            request.VehicleCommission = this.state.vehicleCommission;
            request.PercentDiscount = this.state.discountPercentage === true ? true : false;
            request.AmountDiscount = this.state.discountAmount === true ? true : false;
            request.Discount = this.state.discount;
            request.IsfixedRate = this.state.isfixrateforallzone === true ? 1 : 0;
            request.TaxSalesApplicable = this.state.salesTax === true ? true : false;
            request.TaxCountyApplicable = this.state.countryTax === true ? true : false;
            request.TaxCongestionApplicable = this.state.congestionTax === true ? true : false;
            request.WireInstructionId = this.state.wireInstructionId !== 0 ? this.state.wireInstructionId : 0;
            request.TaxUtilityApplicable = this.state.utilityTax === true ? true : false;
            request.TaxGstApplicable = this.state.gstTax === true ? true : false;
            
            const requestParams = getRequestParams('POST', request);

            const response = await fetch('customer-api/updateCustomerSetting/' + this.state.customerDetails.accountId, requestParams);
            const data = await response.json();
            Swal.fire({
                icon: data.isSuccess ? 'success' : 'error',
                title: data.isSuccess ? 'Success' : 'Oops...',
                text: data.resultMessage,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (data.isSuccess) {
                    if (result.isConfirmed) {
                        this.setState({ isSubmited: false, isOpenModal: false });
                        this.props.onCustomerSettingClose();
                    }
                } else {
                    this.setState({ isSubmited: false })
                }
            })
        }
        else {
            this.setState({ isSubmited: false })
        }
    }

    closeCustomerSettingModal = () => {
        this.setState({
            isOpenModal: false,
            loadPanelVisible: false
        });
        this.props.onCustomerSettingClose();
        this.clearCustomerSettingModal();

    }
    clearCustomerSettingModal = () => {
        this.setState({
            isSubmited: false,
            rateTypeId: 0,
            isfixrateforallzone: false,
            wireInstructionId: 0,
            billingCycleId: 0,
            paymentTypeId: 0,
            creditLimit: 0,
            creditCardNumber: "",
            expiryMonth: "",
            expiryYear: "",
            creditCardTypeId: 0,
            cvv2: null,
            auto: false,
            manual: false,
            giveReminder: false,
            email: false,
            print: false,
            fax: false,
            view: false,
            commissionpaytovehicle: false,
            salesTax: false,
            countryTax: false,
            congestionTax: false,
            utilityTax: false,
            gstTax: false,
            vehicleCommission: 0,
            discount: 0,
            taxOptionsIncludeinfare: false,
            taxOptionsAddtofare: false,
            taxOptionsExempted: false,
            discountPercentage: false,
            discountAmount: false,
            errors: {
                rateTypeId: "",
                wireInstructionId: "",
                billingCycleId: "",
                paymentTypeId: "",
                creditCardNumber: ""
            }
        });
    }
    setActiveTab = (tabName) => {
        this.setState({
            activeTab: tabName
        });
    }
    render() {
        const activerTab = this.state.activeTab === "NotificationSetting" ? <NotificationSettingComponent customerDetails={this.state.customerDetails} /> : this.state.activeTab === "HotelMapping" ? <HotelMappingComponent customerDetails={this.state.customerDetails} /> : this.state.activeTab === "SetCommissionAgent" ? <CommissionAgentComponent onCommissionSubmit={this.closeCustomerSettingModal} customerDetails={this.state.customerDetails} /> : this.state.activeTab === "TaxSetting" ? <TaxSettingComponent customerDetails={this.state.customerDetails} /> : "";
        return (<div>
            <Modal ariaHideApp={false}
                isOpen={this.state.isOpenModal}
                style={customStyles}
                className={"react-modal customer-setting-modal"}
                onAfterOpen={this.OpenCutomerSettingDataModal}
                onRequestClose={this.closeCustomerSettingModal}
                shouldCloseOnOverlayClick={false}
                contentLabel="Example Modal"
            >
                <div className="modal-header">
                    <h6 className="modal-title">Settings</h6>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeCustomerSettingModal}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body tabs-modal-body">

                    <div className="row">
                        <div className="col-1 col-sm-1 tab-left-link">
                            <div className="nav flex-column nav-tabs h-100" id="vert-tabs-tab" role="tablist" aria-orientation="vertical">
                                <a className="nav-link active" id="vert-tabs-CustomerSetting-tab" data-toggle="pill" href="#vert-tabs-CustomerSetting" role="tab" aria-controls="vert-tabs-CustomerSetting" title="Customer Setting" aria-selected="true"><i className="fas fa-user"></i></a>
                                <a className="nav-link" id="vert-tabs-NotificationSetting-tab" data-toggle="pill" href="#vert-tabs-NotificationSetting" role="tab" aria-controls="vert-tabs-NotificationSetting" title="Notification Setting" aria-selected="false" onClick={() => this.setActiveTab("NotificationSetting")}><i className="fas fa-envelope-open-text"></i></a>
                                <a className="nav-link" id="vert-tabs-HotelMapping-tab" data-toggle="pill" href="#vert-tabs-HotelMapping" role="tab" aria-controls="vert-tabs-HotelMapping" title="Hotel Mapping" aria-selected="false" onClick={() => this.setActiveTab("HotelMapping")}><i className="fas fa-hotel"></i></a>
                                <a className="nav-link" id="vert-tabs-SetCommissionAgent-tab" data-toggle="pill" href="#vert-tabs-SetCommissionAgent" role="tab" aria-controls="vert-tabs-SetCommissionAgent" title="Set Commission Agent" onClick={() => this.setActiveTab("SetCommissionAgent")} aria-selected="false"><i className="fas fa-user-tie"></i></a>
                                <a className="nav-link" id="vert-tabs-SetFranchiseeCommission-tab" style={{ display: "none" }} data-toggle="pill" href="#vert-tabs-SetFranchiseeCommission" role="tab" aria-controls="vert-tabs-SetFranchiseeCommission" title="Set Franchisee Commission" aria-selected="false"><i className="fas fa-user-tie"></i></a>
                                <a className="nav-link" id="vert-tabs-TaxSetting-tab" data-toggle="pill" href="#vert-tabs-TaxSetting" role="tab" aria-controls="vert-tabs-TaxSetting" title="Tax Setting" onClick={() => this.setActiveTab("TaxSetting")} aria-selected="false"><i className="fas fa-coins"></i></a>
                                <a className="nav-link" id="vert-tabs-SetStatus-tab" style={{ display: "none" }} data-toggle="pill" href="#vert-tabs-SetStatus" role="tab" aria-controls="vert-tabs-SetStatus" title="Set Status" aria-selected="false"><i className="fas fa-sticky-note"></i></a>
                                <a className="nav-link" id="vert-tabs-History-tab" data-toggle="pill" href="#vert-tabs-History" role="tab" aria-controls="vert-tabs-History" title="History" aria-selected="false"><i className="fas fa-history"></i></a>
                            </div>
                        </div>
                        <div className="col-11 col-sm-11 tab-right-content">
                            <div className="tab-content" id="vert-tabs-tabContent">
                                <div className="tab-pane text-left fade show active" id="vert-tabs-CustomerSetting" role="tabpanel" aria-labelledby="vert-tabs-CustomerSetting-tab">
                                    <div className="card card-default">
                                        <div className="card-header">
                                            <h3 className="card-title">Customer Setting</h3>
                                        </div>
                                        <form method="post" onSubmit={this.handleCustomerSettingSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <label className="text-info">Account Setting</label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                        <div className="form-group">
                                                            <label>Rate Type <span className="text-red">*</span></label>
                                                            <select name="rateTypeId" className="form-control form-control-sm" value={this.state.rateTypeId} onChange={this.handleInputChange}>
                                                                <option value={0}>Select rate type</option>
                                                                {
                                                                    this.state.rateTypeList && this.state.rateTypeList.map((item, i) =>
                                                                        <option key={i} value={item.id}>{item.name}</option>)
                                                                }
                                                            </select>
                                                            {this.state.errors["rateTypeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["rateTypeId"]}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                        <div className="form-group">
                                                            <br />
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="isfixrateforallzone" name="isfixrateforallzone" value={this.state.isfixrateforallzone} checked={this.state.isfixrateforallzone} onChange={this.handleCheckBoxRadioOnChange} />
                                                                <label className="form-check-label" htmlFor="isfixrateforallzone"> Is fix rate for all zone</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                        <div className="form-group">
                                                            <label>Wiring Instructions</label>
                                                            <select name="wireInstructionId" className="form-control form-control-sm" value={this.state.wireInstructionId} onChange={this.handleInputChange}>
                                                                <option value={0}>Select wiring instructions</option>
                                                                {
                                                                    this.state.wiringInstructionsList &&
                                                                    this.state.wiringInstructionsList.map((item, i) =>
                                                                        (<option key={i} value={item.wiringInstructionId}>{item.wiringName}</option>))
                                                                }
                                                            </select>
                                                            {this.state.errors["wireInstructionId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["wireInstructionId"]}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                        <div className="form-group">
                                                            <label>Billing Cycle <span className="text-red">*</span></label>
                                                            <select name="billingCycleId" className="form-control form-control-sm" value={this.state.billingCycleId} onChange={this.handleInputChange}>
                                                                <option value={0}>Select billing cycle</option>
                                                                {
                                                                    this.state.billingCycleList &&
                                                                    this.state.billingCycleList.map((item, i) =>
                                                                        (<option key={i} value={item.id}>{item.name}</option>))
                                                                }
                                                            </select>
                                                            {this.state.errors["billingCycleId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["billingCycleId"]}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                        <div className="form-group" style={{ display: this.state.billingCycleId !== null && this.state.billingCycleId === 5 ? "inline-block" : "none" }} >
                                                            <label>From</label><br />
                                                            <DateBox type="date"
                                                                className="form-control form-control-sm"
                                                                name="fromDate"
                                                                placeholder="mm/dd/yyyy"
                                                                useMaskBehavior={true}
                                                                defaultValue={this.state.fromDate}
                                                                onValueChanged={this.handleFromDateChange}
                                                                width="100%"
                                                                disabled={false}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                        <div className="form-group" style={{ display: this.state.billingCycleId !== null && this.state.billingCycleId === 5 ? "inline-block" : "none" }} >
                                                            <label>To</label><br />
                                                            <DateBox type="date"
                                                                className="form-control form-control-sm"
                                                                name="toDate"
                                                                placeholder="mm/dd/yyyy"
                                                                useMaskBehavior={true}
                                                                defaultValue={this.state.toDate}
                                                                onValueChanged={this.handleToDateChange}
                                                                width="100%"
                                                                disabled={false}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                        <div className="form-group">
                                                            <label>Payment Type <span className="text-red">*</span></label>
                                                            <select name="paymentTypeId" className="form-control form-control-sm" value={this.state.paymentTypeId} onChange={this.handleInputChange}>
                                                                <option value={0}>Select payment type</option>
                                                                {
                                                                    this.state.paymentTypeList &&
                                                                    this.state.paymentTypeList.map((item, i) =>
                                                                        (<option key={i} value={item.id}>{item.name}</option>))
                                                                }
                                                            </select>
                                                            {this.state.errors["paymentTypeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["paymentTypeId"]}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                        <div className="form-group">
                                                            <label>Credit Limit</label>
                                                            <NumberFormat className="form-control form-control-sm" name="creditLimit" fixedDecimalScale={true} maxLength={8} decimalScale={2} title="Credit Limit" value={this.state.creditLimit} onChange={this.handleInputChange} placeholder="Enter credit limit" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"><hr style={{ marginTop: 0.5 + 'rem', marginBottom: 0.5 + 'rem' }} /></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <label className="text-info">Credit Card Details</label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                        <div className="form-group">
                                                            <label>Credit Card Number</label>
                                                            <NumberFormat className="form-control form-control-sm" name="creditCardNumber" title="Credit Card Number" placeholder="Enter credit card number" format="#### #### #### ####" value={this.state.creditCardNumber} onChange={this.handleInputChange} />
                                                            {this.state.errors["creditCardNumber"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["creditCardNumber"]}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                        <div className="form-group">
                                                            <label>Expiry Month</label>
                                                            <NumberFormat className="form-control form-control-sm" name="expiryMonth" title="Expiry Month" placeholder="Enter month" format="##" value={this.state.expiryMonth} onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                        <div className="form-group">
                                                            <label>Expiry Year</label>
                                                            <NumberFormat className="form-control form-control-sm" name="expiryYear" title="Expiry Year" placeholder="Enter year" format="####" value={this.state.expiryYear} onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                        <div className="form-group">
                                                            <label>Credit Card Type</label>
                                                            <select name="creditCardTypeId" className="form-control form-control-sm" value={this.state.creditCardTypeId} onChange={this.handleInputChange}>
                                                                <option value={0}>Select credit card type</option>
                                                                {
                                                                    this.state.creditCardTypeList &&
                                                                    this.state.creditCardTypeList.map((item, i) =>
                                                                        (<option key={i} value={item.id}>{item.name}</option>))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                        <div className="form-group">
                                                            <label>CVV2</label>
                                                            <NumberFormat className="form-control form-control-sm" name="cvv2" placeholder="Enter cvv2" format="###" title="CVV2" value={this.state.cvv2} onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"><hr style={{ marginTop: 0.5 + 'rem', marginBottom: 0.5 + 'rem' }} /></div>
                                                </div>
                                                <div className="row two-col-row">
                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                        <div className="row">
                                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                <label className="text-info">Billing Type</label>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                                <div className="form-group">
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input className="form-check-input" type="radio" style={{ marginTop: "0rem" }} name="auto" checked={this.state.auto} plassceholder="" onChange={this.handleCheckBoxRadioOnChange} />
                                                                        Auto</label>
                                                                    </div>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input className="form-check-input" type="radio" style={{ marginTop: "0rem" }} name="manual" checked={this.state.manual} plassceholder="" onChange={this.handleCheckBoxRadioOnChange} />
                                                                        Manual</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                <div className="form-group">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" id="giveReminder" name="giveReminder" value={this.state.giveReminder} checked={this.state.giveReminder} placeholder="" onChange={this.handleCheckBoxRadioOnChange} />
                                                                        <label className="form-check-label" htmlFor="giveReminder"> Give Reminder</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                        <div className="row">
                                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                <label className="text-info">Billing View Option</label>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                <div className="form-group">
                                                                    <span className="form-check">
                                                                        <input className="form-check-input" type="checkbox" id="email" name="email" value={this.state.email} placeholder="email" checked={this.state.email} onChange={this.handleCheckBoxRadioOnChange} />
                                                                        <label className="form-check-label" htmlFor="email"> Email</label>
                                                                    </span>
                                                                    <span className="form-check">
                                                                        <input className="form-check-input" type="checkbox" id="print" name="print" value={this.state.print} placeholder="" checked={this.state.print} onChange={this.handleCheckBoxRadioOnChange} />
                                                                        <label className="form-check-label" htmlFor="print"> Print</label>
                                                                    </span>
                                                                    <span className="form-check">
                                                                        <input className="form-check-input" type="checkbox" id="fax" name="fax" value={this.state.fax} plassceholder="" checked={this.state.fax} onChange={this.handleCheckBoxRadioOnChange} />
                                                                        <label className="form-check-label" htmlFor="fax"> Fax</label>
                                                                    </span>
                                                                    <span className="form-check">
                                                                        <input className="form-check-input" type="checkbox" id="view" name="view" value={this.state.view} plassceholder="" checked={this.state.view} onChange={this.handleCheckBoxRadioOnChange} />
                                                                        <label className="form-check-label" htmlFor="view"> View</label>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"><hr style={{ marginTop: 0.5 + 'rem', marginBottom: 0.5 + 'rem' }} /></div>
                                                </div>
                                                <div className="row two-col-row">
                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                        <div className="row">
                                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                <label className="text-info">Revenue Sharing (Default)</label>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                                <div className="form-group">
                                                                    <NumberFormat className="form-control form-control-sm" name="vehicleCommission" fixedDecimalScale={true} maxLength={8} decimalScale={2} title="Vehicle Commission" value={this.state.vehicleCommission} onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                                <div className="form-group">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" id="commissionpaytovehicle" name="commissionpaytovehicle" value={this.state.commissionpaytovehicle} checked={this.state.commissionpaytovehicle} plassceholder="" onChange={this.handleCheckBoxRadioOnChange} />
                                                                        <label className="form-check-label" htmlFor="commissionpaytovehicle"> Commission pay to vehicle</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                        <div className="row">
                                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                <label className="text-info">Discount</label>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                                <div className="form-group">
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input className="form-check-input" type="radio" style={{ marginTop: "0rem" }} name="discountPercentage" id="discountPercentage" value={this.state.discountPercentage} checked={this.state.discountPercentage} plassceholder="" onChange={this.handleCheckBoxRadioOnChange} />
                                                                        Percentage (%)</label>
                                                                    </div>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input className="form-check-input" type="radio" style={{ marginTop: "0rem" }} name="discountAmount" id="discountAmount" value={this.state.discountAmount} checked={this.state.discountAmount} plassceholder="" onChange={this.handleCheckBoxRadioOnChange} />
                                                                        Amount ($)</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                                <div className="form-group">
                                                                    <NumberFormat className="form-control form-control-sm" name="discount" fixedDecimalScale={true} maxLength={8} decimalScale={2} title="Discount" value={this.state.discount} onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"><hr style={{ marginTop: 0.5 + 'rem', marginBottom: 0.5 + 'rem' }} /></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <label className="text-info">Tax Options</label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                        <div className="form-group">
                                                            <div className="form-check">
                                                                <label className="form-check-label">
                                                                    <input className="form-check-input" style={{ marginTop: "0rem" }} type="radio" name="taxOptionsIncludeinfare" id="taxOptionsIncludeinfare" value={this.state.taxOptionsIncludeinfare} checked={this.state.taxOptionsIncludeinfare} onChange={this.handleCheckBoxRadioOnChange} />
                                                               Include in fare</label>
                                                            </div>
                                                            <div className="form-check">
                                                                <label className="form-check-label">
                                                                    <input className="form-check-input" style={{ marginTop: "0rem" }} type="radio" name="taxOptionsAddtofare" id="taxOptionsAddtofare" value={this.state.taxOptionsAddtofare} checked={this.state.taxOptionsAddtofare} onChange={this.handleCheckBoxRadioOnChange} />
                                                                Add to fare</label>
                                                            </div>
                                                            <div className="form-check">
                                                                <label className="form-check-label">
                                                                    <input className="form-check-input" style={{ marginTop: "0rem" }} type="radio" name="taxOptionsExempted" id="taxOptionsExempted" value={this.state.taxOptionsExempted} checked={this.state.taxOptionsExempted} onChange={this.handleCheckBoxRadioOnChange} />
                                                                Exempted</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <div className="form-group">
                                                            <span className="form-check" id="portAuthorityArea">
                                                                <input className="form-check-input" type="checkbox" id="portAuthority" name="portAuthority" value={this.state.portAuthority} checked={this.state.portAuthority} plassceholder="" onChange={this.handleCheckBoxRadioOnChange} />
                                                                <label className="form-check-label" htmlFor="portAuthority"> Port Authority</label>
                                                            </span>
                                                            <span className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="utilityTax" name="utilityTax" values={this.state.utilityTax} plassceholder="" checked={this.state.utilityTax} onChange={this.handleCheckBoxRadioOnChange} />
                                                                <label className="form-check-label" htmlFor="utilityTax"> Utility Tax</label>
                                                            </span>
                                                            <span className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="salesTax" name="salesTax" values={this.state.salesTax} plassceholder="" checked={this.state.salesTax} onChange={this.handleCheckBoxRadioOnChange} />
                                                                <label className="form-check-label" htmlFor="salesTax"> Sales Tax</label>
                                                            </span>
                                                            <span className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="countryTax" name="countryTax" values={this.state.countryTax} plassceholder="" checked={this.state.countryTax} onChange={this.handleCheckBoxRadioOnChange} />
                                                                <label className="form-check-label" htmlFor="countryTax"> Country Tax</label>
                                                            </span>
                                                            <span className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="congestionTax" name="congestionTax" values={this.state.congestionTax} plassceholder="" checked={this.state.congestionTax} onChange={this.handleCheckBoxRadioOnChange} />
                                                                <label className="form-check-label" htmlFor="congestionTax"> Congestion Tax</label>
                                                            </span>
                                                            <span className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="gstTax" name="gstTax" values={this.state.gstTax} plassceholder="" checked={this.state.gstTax} onChange={this.handleCheckBoxRadioOnChange} />
                                                                <label className="form-check-label" htmlFor="gstTax"> Gst Tax</label>
                                                            </span>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  text-right">
                                                        <button type="submit" className="btn btn-success btn-sm" onClick={this.handleCustomerSettingSubmit} >
                                                            {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                                            {!this.state.isSubmited && <span title="Save"><i className="fas fa-save"></i> Save</span>}
                                                        </button>&nbsp;&nbsp;
                                                        <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.clearCustomerSettingModal} title="Clear"><i className="fas fa-eraser"></i> Clear</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="vert-tabs-NotificationSetting" role="tabpanel" aria-labelledby="vert-tabs-NotificationSetting-tab">
                                    {activerTab}
                                </div>
                                <div className="tab-pane fade" id="vert-tabs-HotelMapping" role="tabpanel" aria-labelledby="vert-tabs-HotelMapping-tab">
                                    {activerTab}
                                </div>
                                <div className="tab-pane fade" id="vert-tabs-SetCommissionAgent" role="tabpanel" aria-labelledby="vert-tabs-SetCommissionAgent-tab">
                                    {activerTab}
                                </div>
                                <div className="tab-pane fade" id="vert-tabs-SetFranchiseeCommission" role="tabpanel" aria-labelledby="vert-tabs-SetFranchiseeCommission-tab">
                                    <div className="card card-default">
                                        <div className="card-header">
                                            <h3 className="card-title">Set Franchisee Commission</h3>
                                        </div>

                                        <div className="card-body">

                                        </div>

                                    </div>
                                </div>
                                <div className="tab-pane fade" id="vert-tabs-TaxSetting" role="tabpanel" aria-labelledby="vert-tabs-TaxSetting-tab">
                                    {activerTab}
                                </div>
                                <div className="tab-pane fade" id="vert-tabs-SetStatus" role="tabpanel" aria-labelledby="vert-tabs-SetStatus-tab">
                                    <div className="card card-default">
                                        <div className="card-header">
                                            <h3 className="card-title">Set Status</h3>
                                        </div>

                                        <div className="card-body">



                                        </div>

                                    </div>
                                </div>
                                <div className="tab-pane fade" id="vert-tabs-History" role="tabpanel" aria-labelledby="vert-tabs-History-tab">
                                    <div className="card card-default">
                                        <div className="card-header">
                                            <h3 className="card-title">History</h3>
                                        </div>

                                        <div className="card-body">

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={position}
                visible={this.state.loadPanelVisible}
                showIndicator={true}
                shading={false}
                showPane={true}
                closeOnOutsideClick={false}
            /></div >);
    }
}