import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import "firebase/auth";

import $ from 'jquery';

var isLoggedIn = (sessionStorage.getItem('session_token') === '' || sessionStorage.getItem('session_token') === null) ? false : true;
//var isLoggedIn = false;


if (isLoggedIn) {
    try {
        $.ajax({
            type: "GET",
            async: false,
            url: "/helper/firebase-settings",
            data: {

            }
        }).done(
            function (parameterValue) {                
                let config = parameterValue;
                
                try {

                    firebase.initializeApp(config);                    
                    const messaging = firebase.messaging();

                    const tokenDivId = 'token_div';
                    const permissionDivId = 'permission_div';

                    messaging.onMessage((payload) => {
                        //console.log('Message received. ', payload);
                        // Update the UI to include the received message.
                        //appendMessage(payload);
                    });

                    firebase.messaging().onMessage(notification => {
                        //console.log('Notification received!');
                       //appendMessage(notification);
                    });

                    function updateToken(token) {                        
                        var userId = sessionStorage.getItem('session_userId');
                        var request = new Object();
                        request.UserId = parseInt(userId);
                        request.Token = token;

                        try {
                            $.ajax({
                                "type": "POST",
                                "contentType": 'application/json; charset=UTF-8',
                                "url": "/authentication/set-firebasetoken/",
                                "data": JSON.stringify(request),
                            }).done(
                                function (result) {
                                    //do what you need
                                    //debugger;
                                });

                        } catch (e) {
                            //debugger;
                        }
                    }

                    function resetUI() {
                        ////console.log("resetUI");
                        //clearMessages();
                        //showToken('loading...');
                        // Get registration token. Initially this makes a network call, once retrieved
                        // subsequent calls to getToken will return from cache.

                        messaging.getToken({
                            vapidKey: config.vapidKey
                        }).then((currentToken) => {
                            if (currentToken) {
                                sendTokenToServer(currentToken);
                                //updateUIForPushEnabled(currentToken);
                            } else {
                                // Show permission request.
                                //console.log('No registration token available. Request permission to generate one.');
                                // Show permission UI.
                                //updateUIForPushPermissionRequired();
                                setTokenSentToServer(false);
                            }
                        }).catch((err) => {
                            //console.log('An error occurred while retrieving token. ', err);
                            //showToken('Error retrieving registration token. ', err);
                            setTokenSentToServer(false);
                        });
                    }

                    //function showToken(currentToken) {
                    //    ////console.log("showToken");
                    //    // Show token in console and UI.
                    //    const tokenElement = document.querySelector('#token');
                    //    //tokenElement.textContent = currentToken;
                    //}

                    // Send the registration token your application server, so that it can:
                    // - send messages back to this app
                    // - subscribe/unsubscribe the token from topics
                    function sendTokenToServer(currentToken) {
                        ////console.log("sendTokenToServer");
                        if (!isTokenSentToServer()) {
                            //console.log('Sending token to server...');
                            // TODO(developer): Send the current token to your server.
                            setTokenSentToServer(true);
                            //console.log(currentToken);
                            updateToken(currentToken);
                        } else {
                            //console.log('Token already sent to server so won\'t send it again unless it changes');
                            deleteToken();
                            //console.log(currentToken);
                            //updateToken(currentToken);                        
                        }
                    }

                    function isTokenSentToServer() {
                        ////console.log("isTokenSentToServer");
                        return window.localStorage.getItem('sentToServer') === '1';
                    }

                    function setTokenSentToServer(sent) {
                        ////console.log("setTokenSentToServer");
                        window.localStorage.setItem('sentToServer', sent ? '1' : '0');
                    }

                    //function showHideDiv(divId, show) {
                    //    //console.log("showHideDiv");
                    //    const div = document.querySelector('#' + divId);
                    //    if (show) {
                    //        div.style = 'display: visible';
                    //    } else {
                    //        div.style = 'display: none';
                    //    }
                    //}

                    function requestPermission() {
                        ////console.log("requestPermission");
                        ////console.log('Requesting permission...');
                        Notification.requestPermission().then((permission) => {
                            if (permission === 'granted') {
                                ////console.log('Notification permission granted.');
                                // TODO(developer): Retrieve a registration token for use with FCM.
                                // In many cases once an app has been granted notification permission,
                                // it should update its UI reflecting this.
                                resetUI();
                            } else {
                                //console.log('Unable to get permission to notify.');
                            }
                        });
                    }

                    function deleteToken() {
                        ////console.log("deleteToken");
                        // Delete registration token.
                        messaging.getToken().then((currentToken) => {
                            messaging.deleteToken(currentToken).then(() => {
                                ////console.log('Token deleted.');
                                setTokenSentToServer(false);
                                // Once token is deleted update UI.
                                resetUI();
                            }).catch((err) => {
                                //console.log('Unable to delete token. ', err);
                                //requestPermission();
                            });
                        }).catch((err) => {
                            //console.log('Error retrieving registration token. ', err);
                            //showToken('Error retrieving registration token. ', err);
                        });
                    }

                    // Add a message to the messages element.
                    //function appendMessage(payload) {
                    //    //console.log("appendMessage");
                    //    const messagesElement = document.querySelector('#messages');
                    //    const dataHeaderElement = document.createElement('h5');
                    //    const dataElement = document.createElement('pre');
                    //    dataElement.style = 'overflow-x:hidden;';
                    //    dataHeaderElement.textContent = 'Received message:';
                    //    dataElement.textContent = JSON.stringify(payload, null, 2);
                    //    messagesElement.appendChild(dataHeaderElement);
                    //    messagesElement.appendChild(dataElement);
                    //}

                    // Clear the messages element of all children.
                    //function clearMessages() {
                    //    ////console.log("clearMessages");
                    //    const messagesElement = document.querySelector('#messages');
                    //    while (messagesElement.hasChildNodes()) {
                    //        messagesElement.removeChild(messagesElement.lastChild);
                    //    }
                    //}

                    //function updateUIForPushEnabled(currentToken) {
                    //    ////console.log("updateUIForPushEnabled");
                    //    showHideDiv(tokenDivId, true);
                    //    showHideDiv(permissionDivId, false);
                    //    showToken(currentToken);
                    //}

                    //function updateUIForPushPermissionRequired() {
                    //    ////console.log("updateUIForPushPermissionRequired");
                    //    showHideDiv(tokenDivId, false);
                    //    showHideDiv(permissionDivId, true);
                    //}

                    resetUI();
                } catch (e) {
                    //console.log("Firebase Error: " + e.message);
                }
            });
    } catch (e) {
        //debugger;
    }
}

export default firebase;