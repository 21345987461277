import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import { getRequestParams } from '../../helpers/globalfunctions.js';
import { SelectBox } from 'devextreme-react/select-box';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
import { LoadPanel } from 'devextreme-react/load-panel';
import Swal from 'sweetalert2';
import { SendEmailComponent } from '../Shared/SendEmailComponent'
let currentYear = (new Date()).getFullYear();
let maxYear = 10;
let minYear = -3
let financialYearList = [];
for (let i = maxYear; i >= minYear; i--) {
    let year = new Object();
    year.id = (currentYear - i);
    year.name = (currentYear - i);
    financialYearList.push(year);
}
export class VehiclePayReportPayrollPeriodwise extends Component {
    constructor() {
        super()
        this.state = {
            isSubmitted: false,
            isEmailSubmitted: false,
            isOpenModal: false,
            loadPanelVisible: false,
            payrollPeriodDropdownList: [],
            franchiseeDropdownList: [],
            vehiclesDropdownList: [],
            franchiseeDropdownData: null,
            vehicleDropdownData: null,
            franchiseeId: 0,
            vehicleId: 0,
            isFrachiseeAllChecked: true,
            isVehicleAllChecked: true,
            isFinancialYear: true,
            financialYearDropdownData: null,
            financialYearDropdownList: [],
            financialYear: 0,
            isChequesOnly: false,
            isAllPeriod: false,
            isSinglePeriod: false,
            isRangePeriod: false,
            fromPeriodDropdownData: null,
            fromPeriodDropdownList: [],
            fromPeriod: '',
            toPeriodDropdownData: null,
            toPeriodDropdownList: [],
            toPeriod: '',
            errors: {
                franchisee: '',
                vehicle: '',
                financialYear: '',
                fromPeriod: '',
                toPeriod: '',
            }
        }
    }
    getFranchiseeDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('vehicle-owner-api/owner-dropdown-list', requestParams);
            const data = await response.json();
            this.setState({
                franchiseeDropdownList: data.owners
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    getVehiclesDropdownList = async () => {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/vehicle-api/dropdown-all', requestParams);
            const data = await response.json();
            this.setState({
                vehiclesDropdownList: data.vehiclesDropdown
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    getFinancialYearDropdownList = async () => {
        try {
            this.setState({ financialYearDropdownList: financialYearList, financialYearDropdownData: null, financialYear: 0 });
        } catch (ex) {
            console.log(ex);
        }
    }
    getPayrollPeriodDropdownList = async () => {
        try {
            try {
                const requestParams = getRequestParams('GET');
                const response = await fetch('vehicle-payroll-period-api/dropdown-list', requestParams);
                const data = await response.json();
                this.setState({
                    payrollPeriodDropdownList: data.vehiclePayRollPeriods
                });
            } catch (ex) {
                console.log(ex);
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnAfterOpenModal = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getFranchiseeDropdownList();
            await this.getVehiclesDropdownList();
            await this.getFinancialYearDropdownList();
            await this.getPayrollPeriodDropdownList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isEmailSubmitted: false,
                isOpenModal: false,
                loadPanelVisible: false,
                payrollPeriodDropdownList: [],
                franchiseeDropdownList: [],
                vehiclesDropdownList: [],
                franchiseeDropdownData: null,
                vehicleDropdownData: null,
                franchiseeId: 0,
                vehicleId: 0,
                isFrachiseeAllChecked: true,
                isVehicleAllChecked: true,
                isFinancialYear: true,
                financialYearDropdownData: null,
                financialYearDropdownList: [],
                financialYear: 0,
                isChequesOnly: false,
                isAllPeriod: false,
                isSinglePeriod: false,
                isRangePeriod: false,
                fromPeriodDropdownData: null,
                fromPeriodDropdownList: [],
                fromPeriod: '',
                toPeriodDropdownData: null,
                toPeriodDropdownList: [],
                toPeriod: '',
                errors: {
                    franchisee: '',
                    vehicle: '',
                    financialYear: '',
                    fromPeriod: '',
                    toPeriod: '',
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnClearSearch = async () => {
        try {
            this.setState({
                isSubmitted: false,
                isEmailSubmitted: false,
                loadPanelVisible: false,
                franchiseeDropdownData: null,
                vehicleDropdownData: null,
                franchiseeId: 0,
                vehicleId: 0,
                isFrachiseeAllChecked: true,
                isVehicleAllChecked: true,
                isFinancialYear: true,
                financialYearDropdownData: null,
                financialYear: 0,
                isChequesOnly: false,
                isAllPeriod: false,
                isSinglePeriod: false,
                isRangePeriod: false,
                fromPeriodDropdownData: null,
                fromPeriodDropdownList: [],
                fromPeriod: '',
                toPeriodDropdownData: null,
                toPeriodDropdownList: [],
                toPeriod: '',
                errors: {
                    franchisee: '',
                    vehicle: '',
                    financialYear: '',
                    fromPeriod: '',
                    toPeriod: '',
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnInputChange = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
            let errors = this.state.errors;
            switch (name) {
                case 'isFrachiseeAllChecked':
                    errors.franchisee = '';
                    this.setState({ isFrachiseeAllChecked: value, franchiseeDropdownData: null, franchiseeId: 0 });
                    break;
                case 'isVehicleAllChecked':
                    errors.vehicle = '';
                    this.setState({ isVehicleAllChecked: value, vehicleDropdownData: null, vehicleId: 0 });
                    break;
                case 'isFinancialYear':
                    errors.financialYear = '';
                    errors.fromPeriod = '';
                    errors.toPeriod = '';
                    if (value === false) {
                        let financialCurrentYear = [];
                        let fcYear = 0;
                        if (this.state.payrollPeriodDropdownList !== null) {
                            if (this.state.payrollPeriodDropdownList.length > 0) {
                                let fcy = new Object();
                                fcy.id = currentYear;
                                fcy.name = currentYear;
                                financialCurrentYear.push(fcy);
                                fcYear = currentYear;
                            }
                        }
                        this.setState({ financialYearDropdownData: financialCurrentYear, financialYear: fcYear });
                        await this.setPayPeriodDropdown(fcYear);
                    }
                    else {
                        this.setState({ financialYearDropdownData: null, financialYear: 0 });
                        await this.setPayPeriodDropdown(0);
                    }
                    this.setState(
                        {
                            isFinancialYear: value,
                            isAllPeriod: !value,
                            isSinglePeriod: false,
                            isRangePeriod: false,
                            fromPeriodDropdownData: null,
                            fromPeriod: '',
                            toPeriodDropdownData: null,
                            toPeriod: '',
                        }
                    );
                    break;
                case 'isAllPeriod':
                    errors.fromPeriod = '';
                    errors.toPeriod = '';
                    this.setState({
                        isAllPeriod: value,
                        isSinglePeriod: false,
                        isRangePeriod: false,
                        fromPeriodDropdownData: null,
                        fromPeriod: '',
                        toPeriodDropdownData: null,
                        toPeriod: '',
                    });
                    break;
                case 'isSinglePeriod':
                    errors.fromPeriod = '';
                    errors.toPeriod = '';
                    this.setState({
                        isAllPeriod: false,
                        isSinglePeriod: value,
                        isRangePeriod: false,
                        fromPeriodDropdownData: null,
                        fromPeriod: '',
                        toPeriodDropdownData: null,
                        toPeriod: '',
                    });
                    break;
                case 'isRangePeriod':
                    errors.fromPeriod = '';
                    errors.toPeriod = '';
                    this.setState({
                        isAllPeriod: false,
                        isSinglePeriod: false,
                        isRangePeriod: value,
                        fromPeriodDropdownData: null,
                        fromPeriod: '',
                        toPeriodDropdownData: null,
                        toPeriod: '',
                    });
                    break;
                default:
                    break;
            }
            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnFranchiseeDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.franchisee = '';
            let franchiseeId = 0;
            let franchiseeDropdownData = null;
            if (data !== null && data.selectedItem !== null) {
                franchiseeId = (data.selectedItem !== null ? data.selectedItem.id : 0);
                franchiseeDropdownData = data.selectedItem;
            }
            else {
                //errors.franchisee = 'Please select franchisee.';
            }
            this.setState({ franchiseeDropdownData: franchiseeDropdownData, franchiseeId: franchiseeId, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnVehicleDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.vehicle = '';
            let vehicleId = 0;
            let vehicleDropdownData = null;
            if (data !== null && data.selectedItem !== null) {
                vehicleId = (data.selectedItem !== null ? data.selectedItem.id : 0)
                vehicleDropdownData = data.selectedItem;
            }
            else {
                //errors.vehicle = 'Please select vehicle.';
            }
            this.setState({ vehicleDropdownData: vehicleDropdownData, vehicleId: vehicleId, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnFYDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.financialYear = '';
            let financialYear = 0;
            let financialYearDropdownData = null;
            if (data !== null && data.selectedItem !== null) {
                financialYear = (data.selectedItem !== null ? data.selectedItem.id : 0)
                financialYearDropdownData = data.selectedItem;
            }
            else {
                //errors.financialYear = 'Please select financial year.';
            }
            this.setState({ financialYearDropdownData: financialYearDropdownData, financialYear: financialYear, errors: errors });
            await this.setPayPeriodDropdown(financialYear);
        } catch (ex) {
            console.log(ex);
        }
    }
    setPayPeriodDropdown = async (financialYear) => {
        try {
            let payPeriodList = [];
            if (this.state.payrollPeriodDropdownList !== null) {
                if (this.state.payrollPeriodDropdownList.length > 0) {
                    let fromPeriod = '';
                    let toPeriod = '';
                    if (financialYear !== 0) {
                        fromPeriod = '01/01/' + financialYear.toString();
                        toPeriod = '12/31/' + financialYear.toString();
                    }
                    if (fromPeriod === '' && toPeriod === '') {
                        payPeriodList = this.state.payrollPeriodDropdownList;
                    }
                    else {
                        payPeriodList = this.state.payrollPeriodDropdownList.filter(p =>
                            moment(p.payDate, 'MM/DD/YYYY') >= moment(fromPeriod, 'MM/DD/YYYY')
                            && moment(p.payDate, 'MM/DD/YYYY') <= moment(toPeriod, 'MM/DD/YYYY'))
                    }
                }
            }
            this.setState({
                fromPeriodDropdownList: payPeriodList,
                toPeriodDropdownList: payPeriodList
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnFromPeriodDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.fromPeriod = '';
            let fromPeriod = '';
            let fromPeriodDropdownData = null;
            if (data !== null && data.selectedItem !== null) {
                fromPeriod = (data.selectedItem !== null ? data.selectedItem.payDate : "");
                fromPeriodDropdownData = data.selectedItem;
            }
            else {
                //errors.fromPeriod = 'Please select period.';
            }
            this.setState({ fromPeriodDropdownData: fromPeriodDropdownData, fromPeriod: fromPeriod, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnToPeriodDropdownChange = async (data) => {
        try {
            let errors = this.state.errors;
            errors.toPeriod = '';
            let toPeriod = '';
            let toPeriodDropdownData = null;
            if (data !== null && data.selectedItem !== null) {
                toPeriod = (data.selectedItem !== null ? data.selectedItem.payDate : "");
                toPeriodDropdownData = data.selectedItem;
            }
            else {
                //errors.toPeriod = 'Please select period.';
            }
            this.setState({ toPeriodDropdownData: toPeriodDropdownData, toPeriod: toPeriod, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnReportSubmit = async (event) => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isSubmitted: true });
                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.FranchiseeId = Number(this.state.franchiseeId);
                request.VehicleId = Number(this.state.vehicleId);
                if (this.state.isSinglePeriod === true) {
                    request.CurrentDate = this.state.fromPeriod;
                }
                if (this.state.isRangePeriod === true) {
                    request.StartDate = this.state.fromPeriod;
                    request.EndDate = this.state.toPeriod;
                }
                if (this.state.isFinancialYear === false && this.state.isAllPeriod === true) {
                    if (this.state.financialYear !== 0) {
                        request.StartDate = '01/01/' + this.state.financialYear.toString();
                        request.EndDate = '12/31/' + this.state.financialYear.toString();
                    }
                }
                request.IsChecked = this.state.isChequesOnly
                request.SelectionCrieteria = await this.setSelectionCrieteria();
                request.fileFormat = 'pdf';
                request.MethodName = "GetVehiclePayReportPayrollPeriodwise";
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                let a = document.createElement('a');
                a.href = dataResponse.reportBase64String;
                a.download = 'VehiclePayReportPayrollPeriodwise.pdf';
                a.click();
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false });
    }
    isFormValid = async () => {
        let isValid = true;
        let errors = this.state.errors;
        errors.franchisee = '';
        errors.vehicle = '';
        errors.financialYear = '';
        errors.fromPeriod = '';
        errors.toPeriod = '';
        try {
            if (this.state.isFrachiseeAllChecked === false && Number(this.state.franchiseeId) === 0) {
                errors.franchisee = 'Please select franchisee.';
                isValid = false;
            }
            if (this.state.isVehicleAllChecked === false && Number(this.state.vehicleId) === 0) {
                errors.vehicle = 'Please select vehicle.';
                isValid = false;
            }
            if (this.state.isFinancialYear === false) {
                if (this.state.financialYear === 0) {
                    errors.financialYear = 'Please select financial year.';
                    isValid = false;
                }
                if (this.state.isAllPeriod === false) {
                    if (this.state.isSinglePeriod === true) {
                        if (this.state.fromPeriod.trim() === '') {
                            errors.fromPeriod = 'Please select period.';
                            isValid = false;
                        }
                    }
                    if (this.state.isRangePeriod === true) {
                        if (this.state.fromPeriod.trim() === '') {
                            errors.fromPeriod = 'Please select period.';
                            isValid = false;
                        }
                        if (this.state.toPeriod.trim() === '') {
                            errors.toPeriod = 'Please select period.';
                            isValid = false;
                        }
                    }
                    if (this.state.isSinglePeriod === false && this.state.isRangePeriod === false) {
                        errors.fromPeriod = 'Please select period option.';
                        errors.toPeriod = 'Please select period option.';
                        isValid = false;
                    }
                }
            }
            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    setSelectionCrieteria = async () => {
        let selectionCrieteria = 'Payment details for';
        try {
            if (this.state.isFrachiseeAllChecked === true) {
                selectionCrieteria += ' all franchisees';
            }
            else {
                if (this.state.franchiseeDropdownData !== null && Object.keys(this.state.franchiseeDropdownData).length > 0) {
                    selectionCrieteria += ' franchisee - ' + this.state.franchiseeDropdownData.name;
                }
            }
            if (this.state.isVehicleAllChecked === true) {
                selectionCrieteria += ', all vehicles';
            }
            else {
                if (this.state.vehicleDropdownData !== null && Object.keys(this.state.vehicleDropdownData).length > 0) {
                    selectionCrieteria += ', vehicle - ' + this.state.vehicleDropdownData.name;
                }
            }
            if (this.state.fromPeriod.trim() === '' && this.state.toPeriod.trim() === '') {
                if (this.state.isFinancialYear === false && this.state.isAllPeriod === true) {
                    if (this.state.financialYear !== 0) {
                        selectionCrieteria += ', financial year - ' + this.state.financialYear.toString();
                    }
                }
                selectionCrieteria += ', all payroll periods';
            }
            else {
                if (this.state.fromPeriod.trim() !== '' && this.state.toPeriod.trim() === '') {
                    selectionCrieteria += ', payroll period - ' + this.state.fromPeriod;
                }
                if (this.state.fromPeriod.trim() !== '' && this.state.toPeriod.trim() !== '') {
                    selectionCrieteria += ', payroll period from ' + this.state.fromPeriod + ' to ' + this.state.toPeriod;
                }
            }
            if (this.state.isChequesOnly === true) {
                selectionCrieteria += ', cheques'
            }
        } catch (ex) {
            selectionCrieteria = '';
            console.log(ex);
        }
        return selectionCrieteria;
    }
    handleOnSendEmail = async (event) => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isEmailSubmitted: true });
                var request = new Object();
                request.LocationId = Number(sessionStorage.getItem("session_locationId"));
                request.FranchiseeId = Number(this.state.franchiseeId);
                request.VehicleId = Number(this.state.vehicleId);
                if (this.state.isSinglePeriod === true) {
                    request.CurrentDate = this.state.fromPeriod;
                }
                if (this.state.isRangePeriod === true) {
                    request.StartDate = this.state.fromPeriod;
                    request.EndDate = this.state.toPeriod;
                }
                if (this.state.isFinancialYear === false && this.state.isAllPeriod === true) {
                    if (this.state.financialYear !== 0) {
                        request.StartDate = '01/01/' + this.state.financialYear.toString();
                        request.EndDate = '12/31/' + this.state.financialYear.toString();
                    }
                }
                request.IsChecked = this.state.isChequesOnly
                request.SelectionCrieteria = await this.setSelectionCrieteria();
                request.fileFormat = 'pdf';
                request.MethodName = "GetVehiclePayReportPayrollPeriodwise";
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('crystal-report-api', requestParams);
                const dataResponse = await response.json();
                this.setState({ isEmailSubmitted: false });
                let fileData = dataResponse.reportBase64String;
                if (fileData !== null && fileData !== '' && isNaN(fileData) === false) {
                    let emailSubject = 'Vehicle Pay Report - Payroll Periodwise';
                    let attachments = [];
                    let file = new Object();
                    file.fileData = fileData;
                    file.fileName = 'VehiclePayReportPayrollPeriodwise.pdf'
                    file.fileExtension = '.pdf';
                    attachments.push(file);
                    this.refs.sendEmailComponent.showModal('', '', emailSubject, '', attachments);
                }
                else {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong! Unable to process the email request.',
                    })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isEmailSubmitted: false });
    }
    render() {
        return (
            <div >
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '45%' } }}
                    className={"react-modal"}
                    onAfterOpen={this.handleOnAfterOpenModal}
                    onRequestClose={this.handleOnCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleOnReportSubmit}
                        style={(this.state.isSubmitted || this.state.isEmailSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">{'Vehicle Pay Report - Payroll Periodwise'}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                id="isFrachiseeAllChecked" name="isFrachiseeAllChecked"
                                                checked={this.state.isFrachiseeAllChecked}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isFrachiseeAllChecked"> All Franchisee</label>
                                        </div>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder="Select franchisee"*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.franchiseeDropdownData}*/}
                                        {/*    name="ddfranchisee"*/}
                                        {/*    onChange={this.handleOnFranchiseeDropdownChange}*/}
                                        {/*    getOptionLabel={option => option.name}*/}
                                        {/*    getOptionValue={option => option.id}*/}
                                        {/*    options={this.state.franchiseeDropdownList}*/}
                                        {/*    isMulti={false}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*    isDisabled={this.state.isFrachiseeAllChecked}*/}
                                        {/*/>*/}
                                        <SelectBox
                                            dataSource={this.state.franchiseeDropdownList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select franchisee"
                                            name="ddfranchisee"
                                            defaultValue={0}
                                            value={this.state.franchiseeId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnFranchiseeDropdownChange}
                                            showClearButton={true}
                                            disabled={this.state.isFrachiseeAllChecked}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["franchisee"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["franchisee"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                name="isVehicleAllChecked"
                                                checked={this.state.isVehicleAllChecked}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isVehicleAllChecked"> All Vehicle</label>
                                        </div>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder="Select vehicle"*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.vehicleDropdownData}*/}
                                        {/*    name="ddvehicle"*/}
                                        {/*    onChange={this.handleOnVehicleDropdownChange}*/}
                                        {/*    getOptionLabel={option => option.name}*/}
                                        {/*    getOptionValue={option => option.id}*/}
                                        {/*    options={this.state.vehiclesDropdownList}*/}
                                        {/*    isMulti={false}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*    isDisabled={this.state.isVehicleAllChecked}*/}
                                        {/*/>*/}
                                        <SelectBox
                                            dataSource={this.state.vehiclesDropdownList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select vehicle"
                                            name="ddvehicle"
                                            defaultValue={0}
                                            value={this.state.vehicleId}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnVehicleDropdownChange}
                                            showClearButton={true}
                                            disabled={this.state.isVehicleAllChecked}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["vehicle"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["vehicle"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                name="isFinancialYear"
                                                checked={this.state.isFinancialYear}
                                                onChange={this.handleOnInputChange} />
                                            <label className="form-check-label" htmlFor="isFinancialYear"> Financial Year</label>
                                        </div>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder="Select year"*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.financialYearDropdownData}*/}
                                        {/*    name="ddfinancialyear"*/}
                                        {/*    onChange={this.handleOnFYDropdownChange}*/}
                                        {/*    getOptionLabel={option => option.name}*/}
                                        {/*    getOptionValue={option => option.id}*/}
                                        {/*    options={this.state.financialYearDropdownList}*/}
                                        {/*    isMulti={false}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*    isDisabled={this.state.isFinancialYear}*/}
                                        {/*/>*/}
                                        <SelectBox
                                            dataSource={this.state.financialYearDropdownList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select year"
                                            name="ddfinancialyear"
                                            defaultValue={0}
                                            value={this.state.financialYear}
                                            displayExpr='name'
                                            valueExpr='id'
                                            onSelectionChanged={this.handleOnFYDropdownChange}
                                            showClearButton={true}
                                            disabled={this.state.isFinancialYear}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["financialYear"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["financialYear"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isAllPeriod"
                                                checked={this.state.isAllPeriod}
                                                onChange={this.handleOnInputChange}
                                                disabled={this.state.isFinancialYear} />
                                            <label className="form-check-label" htmlFor="isAllPeriod"> All Period</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isSinglePeriod"
                                                checked={this.state.isSinglePeriod}
                                                onChange={this.handleOnInputChange}
                                                disabled={this.state.isFinancialYear}
                                            />
                                            <label className="form-check-label" htmlFor="isSinglePeriod"> Single</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                name="isRangePeriod"
                                                checked={this.state.isRangePeriod}
                                                onChange={this.handleOnInputChange}
                                                disabled={this.state.isFinancialYear}
                                            />
                                            <label className="form-check-label" htmlFor="isRangePeriod"> In Range</label>
                                        </div>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder={(this.state.isRangePeriod) ? 'Select from period' :'Select period'}*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.fromPeriodDropdownData}*/}
                                        {/*    name="ddfromperiod"*/}
                                        {/*    onChange={this.handleOnFromPeriodDropdownChange}*/}
                                        {/*    getOptionLabel={option => option.viewDate}*/}
                                        {/*    getOptionValue={option => option.payDate}*/}
                                        {/*    options={this.state.fromPeriodDropdownList}*/}
                                        {/*    isMulti={false}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*    isDisabled={(this.state.isAllPeriod || this.state.isFinancialYear)}*/}
                                        {/*/>*/}
                                        <SelectBox
                                            dataSource={this.state.fromPeriodDropdownList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder={(this.state.isRangePeriod) ? 'Select from period' : 'Select period'}
                                            name="ddfromperiod"
                                            defaultValue={''}
                                            value={this.state.fromPeriod}
                                            displayExpr='viewDate'
                                            valueExpr='payDate'
                                            onSelectionChanged={this.handleOnFromPeriodDropdownChange}
                                            showClearButton={true}
                                            disabled={(this.state.isAllPeriod || this.state.isFinancialYear)}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["fromPeriod"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["fromPeriod"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <label htmlFor=""><br /></label>
                                        {/*<Select*/}
                                        {/*    className="basic-multi-select"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    placeholder={(this.state.isRangePeriod) ? 'Select to period' : 'Select period'}*/}
                                        {/*    defaultValue={[]}*/}
                                        {/*    value={this.state.toPeriodDropdownData}*/}
                                        {/*    name="ddtoperiod"*/}
                                        {/*    onChange={this.handleOnToPeriodDropdownChange}*/}
                                        {/*    getOptionLabel={option => option.viewDate}*/}
                                        {/*    getOptionValue={option => option.payDate}*/}
                                        {/*    options={this.state.toPeriodDropdownList}*/}
                                        {/*    isMulti={false}*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isSearchable={true}*/}
                                        {/*    isDisabled={(!this.state.isRangePeriod)}*/}
                                        {/*/>*/}

                                        <SelectBox
                                            dataSource={this.state.toPeriodDropdownList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder={(this.state.isRangePeriod) ? 'Select to period' : 'Select period'}
                                            name="ddtoperiod"
                                            defaultValue={''}
                                            value={this.state.toPeriod}
                                            displayExpr='viewDate'
                                            valueExpr='payDate'
                                            onSelectionChanged={this.handleOnToPeriodDropdownChange}
                                            showClearButton={true}
                                            disabled={(!this.state.isRangePeriod)}
                                            searchEnabled={true}
                                        />
                                        {this.state.errors["toPeriod"].length > 0 && <span className='error invalid-feedback'>
                                            {this.state.errors["toPeriod"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-4 col-xs-4">
                                    <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                id="isChequesOnly" name="isChequesOnly"
                                                checked={this.state.isChequesOnly}
                                                onChange={(e) => { this.setState({ isChequesOnly: e.target.checked }) }} />
                                            <label className="form-check-label" htmlFor="isChequesOnly"> Cheques Only</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={this.handleOnSendEmail} >
                                {this.state.isEmailSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isEmailSubmitted && <span title="Send Email"><i className="fas fa-envelope"></i> Send Email</span>}
                            </button>
                            <button type="submit" className="btn btn-success btn-sm">
                                {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                {!this.state.isSubmitted && <span title="Download"><i className="fas fa-file-download"></i> Download</span>}
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                onClick={this.handleOnClearSearch}>
                                <i className="fas fa-eraser"></i> Clear
                            </button>
                            <button type="button" className="btn btn-custom-gray btn-sm" title="Close"
                                onClick={this.handleOnCloseModal}>
                                <i className="fas fa-times"></i> Close
                             </button>
                        </div>
                    </form>
                </Modal>
                <SendEmailComponent ref="sendEmailComponent" />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        );
    }
}
