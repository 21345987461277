/// <reference path="../../helpers/globalfunctions.js" />
/// <reference path="../../helpers/globalfunctions.js" />
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
    Editing
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import { SelectBox } from 'devextreme-react/select-box';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ParentAccount } from '../../helpers/fixcodes.js';
import { CustomerListComponent } from './CustomerListComponent';
import { closeCustomerSettingModal } from './CustomerSettingComponent'
import { getRequestParams } from '../../helpers/globalfunctions.js';

export class CommissionAgentComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            customerDetails: props.customerDetails,
            agentList: [],
            agentId: 0,
            agentName: props.customerDetails.agentName,
            customerAgentId: props.customerDetails.agentId,
            errors: {
                agentId: "",
                agentName: ""
            }
        }
        this.handleSetCommissionAgentSubmit = this.handleSetCommissionAgentSubmit.bind(this);
        this.handleRemoveCommissionAgent = this.handleRemoveCommissionAgent.bind(this);
    }
    componentDidMount() {
        this.getAgentList();
    }
    async getAgentList() {
        const requestParams = getRequestParams('GET', '');
        const response = await fetch('commission-agent-api/commission-agent-dropdown', requestParams);
        const data = await response.json();
        if (data !== null && data.commissionAgentDropdownList.length > 0) {
            this.setState({
                agentList: data.commissionAgentDropdownList,
                agentId: this.state.customerDetails.agentId > 0 ? this.state.customerDetails.agentId: 0
            });
        }
    }
    handleAgentInputChange = (data) => {
      
        if (data.selectedItem != null) {
            var getactualaccname = this.state.agentList.filter(item => item.id === Number(data.selectedItem.id !== 0 ? data.selectedItem.id : "0"))
            this.setState({
                agentId: data.selectedItem.id,
                agentName: getactualaccname.length > 0 ? getactualaccname[0].name : ""
            });
        } else {
            this.setState({
                agentId: 0,
                agentName: ""
            });
        }
    }
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    }
    handleValidation() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.agentId = "";

        if (this.state.agentId == 0) {
            formIsValid = false;
            errors.agentId = "Please select Agent.";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    async handleSetCommissionAgentSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            if (this.state.customerDetails.accountId !== ParentAccount.UTWIZ) {
                this.setState({ isSubmited: true });

                var request = new Object();
                request.Id = this.state.customerDetails.accountId;
                request.AgentId = Number(this.state.agentId);

                const requestParams = getRequestParams('POST', request);
                const response = await fetch('commission-agent-api/update-commission-agent', requestParams);
                const data = await response.json();
                Swal.fire({
                    icon: data.isSuccess ? 'success' : 'error',
                    title: data.isSuccess ? 'Success' : 'Oops...',
                    text: data.resultMessage,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            this.clearCommissionAgentModal();
                        }
                    } else {
                        this.setState({ isSubmited: false })
                    }
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Agent are not allowed for UTS',
                })
            }
        }
        else {
            this.setState({ isSubmited: false })
        }
    }
    async handleRemoveCommissionAgent(e) {
        e.preventDefault();
        this.setState({ isSubmited: true });

        let isResult = false;

        await Swal.fire({
            title: "Confirmation",
            html: "Do you want remove customer agent?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                isResult = true;
            }
        })
        if (isResult) {
            var request = new Object();
            request.Id = this.state.customerDetails.accountId;
            request.AgentId = 0;
            const requestParams = getRequestParams('POST', request);

            const response = await fetch('commission-agent-api/update-commission-agent', requestParams);
            const data = await response.json();
            Swal.fire({
                icon: data.isSuccess ? 'success' : 'error',
                title: data.isSuccess ? 'Success' : 'Oops...',
                text: data.resultMessage,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (data.isSuccess) {
                    if (result.isConfirmed) {
                        this.clearCommissionAgentModal();
                    }
                } else {
                    this.setState({ isSubmited: false })
                }
            })
        }


        //var request = new Object();
        //request.Id = this.state.customerDetails.accountId;
        //request.AgentId = 0;

        //const requestParams = getRequestParams('POST', request);
        //const response = await fetch('commission-agent-api/update-commission-agent', requestParams);
        //const data = await response.json();
        //Swal.fire({
        //    icon: data.isSuccess ? 'success' : 'error',
        //    title: data.isSuccess ? 'Success' : 'Oops...',
        //    text: data.resultMessage,
        //}).then((result) => {
        //    /* Read more about isConfirmed, isDenied below */
        //    if (data.isSuccess) {
        //        if (result.isConfirmed) {
        //            this.setState({ isSubmited: false });
        //            this.clearCommissionAgentModal();
        //        }
        //    } else {
        //        this.setState({ isSubmited: false })
        //    }
        //})

    }
    clearCommissionAgentModal = () => {

        this.setState({
            isSubmited: false,
            agentId: this.state.customerDetails.agentId,
            agentName: this.state.customerDetails.agentName,
            errors: {
                agentId: "",
                agentName: ""
            }
        });
        this.props.onCommissionSubmit();
    }
    render() {
        return (
            <form method="post" onSubmit={this.handleSetCommissionAgentSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                <div className="card card-default">
                    <div className="card-header">
                        <h3 className="card-title">Set Commission Agent</h3>
                    </div>

                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <div className="form-group">
                                    <label>Customer Name</label>
                                    <input type="text" className="form-control form-control-sm" placeholder="Enter customer name" disabled="true" value={this.state.customerDetails !== null ? this.state.customerDetails.accountName : ""} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <div className="form-group">
                                    <label>Agent</label>
                                    <SelectBox
                                        dataSource={this.state.agentList.length > 0 ? this.state.agentList : []}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select agent"
                                        name="agentId"
                                        defaultValue={0}
                                        value={this.state.agentId}
                                        displayExpr='agentCode'
                                        valueExpr='id'
                                        onSelectionChanged={this.handleAgentInputChange}
                                        showClearButton={true}
                                        disabled={false}
                                        searchEnabled={true}
                                    />
                                    {this.state.errors["agentId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["agentId"]}</span>}
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <div className="form-group">
                                    <label>Agent Name</label>
                                    <input type="text" className="form-control form-control-sm" placeholder="Enter agent name" disabled="true" value={this.state.agentName} />
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  text-right">
                                <button type="button" className="btn btn-danger btn-sm" disabled={this.state.customerAgentId > 0 ? false : true} onClick={this.handleRemoveCommissionAgent} title="Remove"><i className="fas fa-times"></i> Remove</button>&nbsp;&nbsp;
                                <button type="button" className="btn btn-success btn-sm" onClick={this.handleSetCommissionAgentSubmit} title="Update"><i className="fas fa-check"></i> Update</button>
                            </div>
                        </div>
                    </div>

                </div>
            </form>
        )
    }
}