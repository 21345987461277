import React, { Component } from 'react';
import DataGrid, {
    Column,
    ColumnChooser,
    ColumnFixing, Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo,
    Scrolling,
    Selection, FilterRow,
}
    from 'devextreme-react/data-grid'; //npm install devextreme@19.1 devextreme-react@19.1
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import DropDownBox from 'devextreme-react/drop-down-box';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getRequestParams } from '../../helpers/globalfunctions';
import { DateFormat } from '../../helpers/fixcodes';
import DateBox from 'devextreme-react/date-box';
import moment from 'moment/min/moment-with-locales'; //npm install --save moment react-moment
import Swal from 'sweetalert2';
import { LoadPanel } from 'devextreme-react/load-panel';
import { ReservationHistoryComponent } from '../Shared/ReservationHistoryComponent';
import { CloseUndispatchRideComponent } from '../Shared/CloseUndispatchRideComponent';

let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class PendingDispatchComponent extends Component {
    constructor(props) {
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        super(props);
        this.state = {
            isSubmitted: false,
            loadPanelVisible: false,
            unclosedGriDatasource: null,
            startDate: moment(new Date(currentTime)).add('days', -15).format("MM/DD/YYYY"),//moment(new Date(new Date(currentTime).setDate(new Date(currentTime).getDate() - 1))).format("MM/DD/YYYY"),
            endDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
            vehicleGroupTypesList: [],
            vehicleGroupTypesDropdownData: null,
            gridRowDataKeys: [],
            rideIds: [],
        }
    }

    async componentDidMount() {
        await this.getVehicleGroupTypeList();
    }

    getVehicleGroupTypeList = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('/vehicle-group-type-api/vehicle-group-type', requestParams);
            const data = await response.json();
            if (data.isSuccess) {
                this.setState({
                    vehicleGroupTypesList: data.vehicleGroupTypes
                });
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }

    syncDataGridVehicleGroupSelection = (e) => {
        try {
            this.setState({
                vehicleGroupTypesDropdownData: e.value || []
            });
        } catch (ex) {
            console.log(ex);
        }
    }

    handleOnVehicleGroupDropdownChange = async (data) => {
        try {
            this.setState({
                vehicleGroupTypesDropdownData: data.selectedRowKeys.length && data.selectedRowKeys || []
            });
        } catch (ex) {
            console.log(ex);
        }
    }

    handleFromDateChanged = (event) => {
        try {
            let startDate = '';
            if (event.value !== null && event.value !== '') {
                startDate = moment(event.value).format("MM/DD/YYYY");
            }
            this.setState({ startDate: startDate });
        } catch (ex) {
            console.log(ex);
        }
    }

    handleToDateChanged = (event) => {
        try {
            let endDate = '';
            if (event.value !== null && event.value !== '') {
                endDate = moment(event.value).format("MM/DD/YYYY");
            }
            this.setState({ endDate: endDate });
        } catch (ex) {
            console.log(ex);
        }
    }

    handleOnSearchSubmit = async (e) => {
        e.preventDefault();
        try {
            await this.getUndispatchRideDetails();
        } catch (ex) {
            console.log(ex);
        }
    }

    handleOnRefreshGrid = async () => {
        try {
            await this.getUndispatchRideDetails();
        } catch (ex) {
            console.log(ex);
        }
    }

    handleClearSearch = async () => {
        try {
            currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
            this.setState({
                isSubmitted: false,
                loadPanelVisible: false,
                unclosedGriDatasource: null,
                startDate: moment(new Date(currentTime)).add('days', -15).format("MM/DD/YYYY"),//moment(new Date(new Date(currentTime).setDate(new Date(currentTime).getDate() - 1))).format("MM/DD/YYYY"),
                endDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
                vehicleGroupTypesDropdownData: null,
                gridRowDataKeys: [],
                rideIds: []
            });
            await new Promise(resolve => setTimeout(resolve, 1000)); // 1 sec
            this.dataGrid.instance.repaint();
        } catch (ex) {
            console.log(ex);
        }
    }

    getUndispatchRideDetails = async () => {
        try {
            this.setState({ loadPanelVisible: true, gridRowDataKeys: [], rideIds: [] });
            let vehicleGroupIds = '';
            let startDate = '';
            let endDate = '';
            if (this.state.vehicleGroupTypesDropdownData !== null) {
                if (Object.keys(this.state.vehicleGroupTypesDropdownData).length > 0) {
                    vehicleGroupIds = this.state.vehicleGroupTypesDropdownData.join(',');
                }
            }
            if (this.state.startDate.trim() !== '' && this.state.endDate.trim() !== '') {
                startDate = moment(this.state.startDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00'
                endDate = moment(this.state.endDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59'
            }
            var request = {};
            request.StartDate = startDate;
            request.EndDate = endDate;
            request.VehicleGroupIds = vehicleGroupIds;
            this.setState({ isSubmitted: true });
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('ride-api/get-unclosed-rides', requestParams);
            const data = await response.json();
            this.setState({
                unclosedGriDatasource: data.rides,
            });
            this.dataGrid.instance.repaint();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false, loadPanelVisible: false });
    }

    handleOnRowSelectionChanged = async (rowDataKeys) => {
        try {
            this.setState({ gridRowDataKeys: rowDataKeys.selectedRowKeys });
            let rideIds = [];
            let selectedRow = await this.dataGrid.instance.getDataByKeys(this.state.gridRowDataKeys);
            if (selectedRow.length > 0) {
                rideIds = [...new Set(selectedRow.map(item => item.rideId))];
            }
            this.setState({ rideIds: rideIds });
            //await new Promise(resolve => setTimeout(resolve, 1000)); // 1 sec
            this.dataGrid.instance.repaint();
        } catch (ex) {
            console.log(ex);
        }
    }

    handleOnClearSelection = async () => {
        try {
            //this.dataGrid.instance.clearSelection();

            currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
            this.setState({
                isSubmitted: false,
                loadPanelVisible: false,
                unclosedGriDatasource: null,
                startDate: moment(new Date(currentTime)).add('days', -15).format("MM/DD/YYYY"),//moment(new Date(new Date(currentTime).setDate(new Date(currentTime).getDate() - 1))).format("MM/DD/YYYY"),
                endDate: moment(new Date(currentTime)).format("MM/DD/YYYY"),
                vehicleGroupTypesDropdownData: null,
                gridRowDataKeys: [],
                rideIds: []
            });
            await new Promise(resolve => setTimeout(resolve, 1000)); // 1 sec
            this.dataGrid.instance.repaint();
        } catch (ex) {
            console.log(ex);
        }
    }

    handleOnToolbarPreparing = async (e) => {
        try {
            e.toolbarOptions.items.unshift({
                location: 'after',
                widget: 'dxButton',
                showText: 'always',
                options: {
                    disabled: false,// !this.state.rideIds.length,
                    text: 'Complete Ride(s)',                    
                    //icon: 'todo',//'export',
                    icon: 'fas fa-save text-success',
                    elementAttr: {
                        "class": "dx-datagrid-export-button"
                    },
                    onClick: this.handleOnCompleteRide.bind(this),
                    hint: 'Complete Ride(s)'
                }
            }, {
                location: 'after',
                widget: 'dxButton',
                showText: 'always',
                options: {
                    disabled: false,// !this.state.rideIds.length,
                    text: 'Cancel Ride(s)',
                    //icon: 'save',//'export',
                    icon: 'fas fa-save text-danger',
                    elementAttr: {
                        "class": "dx-datagrid-export-button"
                    },
                    onClick: this.handleOnCancelRide.bind(this),
                    hint: 'Cancel Ride(s)'
                }
            }, {
                location: 'after',
                widget: 'dxButton',
                showText: 'always',
                options: {
                    disabled: false,// !this.state.rideIds.length,
                    text: 'Clear Selection',
                    icon: 'clear',
                    onClick: this.handleOnClearSelection.bind(this),
                    hint: 'Clear Selection'
                }
            }, {
                location: 'after',
                widget: 'dxButton',
                showText: 'always',
                options: {
                    disabled: false,
                    text: 'Export to Excel',
                    icon: 'export-excel-button',//'export',
                    elementAttr: {
                        "class": "dx-datagrid-export-button"
                    },
                    onClick: function () {
                        e.component.option("export.fileName", "UnclosedRides");
                        e.component.exportToExcel(false);
                    },
                    hint: 'Export to Excel'
                }
            }, {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    onClick: this.handleOnRefreshGrid.bind(this),
                    hint: 'Refresh'
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }

    handleOnCompleteRide = async () => {
        try {
            if (this.state.unclosedGriDatasource !== null && this.state.unclosedGriDatasource.length > 0) {
                await this.closeRide(this.state.rideIds, 116);
            }
        } catch (ex) {
            console.log(ex);
        }
    }

    handleOnCancelRide = async () => {
        try {
            if (this.state.unclosedGriDatasource !== null && this.state.unclosedGriDatasource.length > 0) {
                await this.closeRide(this.state.rideIds, 111);
            }
        } catch (ex) {
            console.log(ex);
        }
    }

    closeRide = async (rideIds, statusId) => {
        try {
            if (rideIds === null || rideIds.length === 0) {
                await Swal.fire({
                    allowOutsideClick: false,
                    showCloseButton: false,
                    icon: 'info',
                    title: 'Invalid ride(s)',
                    html: 'Please select record.',
                })
            }
            else {
                let confirmMsg = statusId === 116 ? 'Complete' : 'Cancel';
                await Swal.fire({
                    title: '<small><b>Confirmation</b></small>',
                    html: "<span style='font- size: 20px;'> Are you sure want to <b>" + confirmMsg + "</b> the ride(s)? </span>",
                    icon: 'question',
                    allowOutsideClick: false,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'No',
                    confirmButtonText: 'Yes'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.refs.closeUndispatchRideComponent.showModal(confirmMsg + " Ride", rideIds, statusId);
                    }
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    }

    handleOnShowReservationModel = (e, data) => {
        e.preventDefault();
        try {
            this.props.history.push({
                pathname: '/crudride',
                //state: { detail: data }
                state: { rideId: data.rideId, sourceUrl: "/pendingdispatch/list", sourceState: this.state }
            })
        } catch (ex) {
            console.log(ex);
        }
    }

    handleOnShowReservationHistoryModel = (e, data) => {
        e.preventDefault();
        try {
            this.refs.reservationHistoryModal.showModal("Reservation", data.rideId);
        } catch (ex) {
            console.log(ex);
        }
    }

    handleOnClosedRideModel = async (e, data, statusId) => {
        e.preventDefault();
        try {
            let rideIds = [];
            rideIds.push(data.rideId);
            await this.closeRide(rideIds, statusId);
        } catch (ex) {
            console.log(ex);
        }
    }

    render() {
        const renderNewResQtCell = (data) => {
            return <div>
                <a href="#" title="View" onClick={(e) => { this.handleOnShowReservationModel(e, data.data) }}><i className="fas fa-pen"></i></a>
                <a href="#" title="History" style={{ paddingLeft: '8px' }} onClick={(e) => { this.handleOnShowReservationHistoryModel(e, data.data) }}><i className="fas fa-history"></i></a>
                <a href="#" title="Complete Ride" style={{ paddingLeft: '8px' }} onClick={(e) => { this.handleOnClosedRideModel(e, data.data, 116) }}><i className="fas fa-check text-success"></i></a>
                <a href="#" title="Cancel Ride" style={{ paddingLeft: '8px' }} onClick={(e) => { this.handleOnClosedRideModel(e, data.data, 111) }}><i className="fas fa-times text-danger"></i></a>
            </div>;
        }
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <h1 className="m-0 text-dark">Close Undispatch Ride(s)</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem><NavLink tag={Link} to="/quote" className="btn btn-block btn-outline-secondary btn-sm" title="New Quotation"><i className="fas fa-plus"></i> New Quotation</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/reservations" className="btn btn-block btn-outline-secondary btn-sm" title="New Reservation"><i className="fas fa-plus"></i> New Reservation</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/reservation/list" className="btn btn-block btn-outline-secondary btn-sm" title="Search Reservation"><i className="fas fa-search"></i> Search Reservation</NavLink></NavItem>
                                    <NavItem><NavLink tag={Link} to="/uploadschedule" className="btn btn-block btn-outline-secondary btn-sm" title="Upload Schedule"><i className="fas fa-clipboard-list"></i> Upload Schedule</NavLink></NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rez-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                <div className="form-group">
                                    <label>From Date</label>
                                    <DateBox type="date"
                                        className="form-control form-control-sm"
                                        name="fromDate"
                                        placeholder="mm/dd/yyyy"
                                        useMaskBehavior={true}
                                        defaultValue={this.state.startDate}
                                        value={this.state.startDate}
                                        onValueChanged={this.handleFromDateChanged}
                                        width="100%"
                                        acceptCustomValue={false}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                <div className="form-group">
                                    <label>To Date</label>
                                    <DateBox type="date"
                                        className="form-control form-control-sm"
                                        name="toDate"
                                        placeholder="mm/dd/yyyy"
                                        useMaskBehavior={true}
                                        defaultValue={this.state.endDate}
                                        value={this.state.endDate}
                                        min={this.state.startDate}
                                        onValueChanged={this.handleToDateChanged}
                                        width="100%"
                                        acceptCustomValue={false}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <div className="form-group">
                                    <label>Vehicle Group</label>
                                    <DropDownBox
                                        value={this.state.vehicleGroupTypesDropdownData}
                                        valueExpr="id"
                                        deferRendering={false}
                                        displayExpr="name"
                                        placeholder="Select vehicle group"
                                        showClearButton={true}
                                        dataSource={this.state.vehicleGroupTypesList}
                                        onValueChanged={this.syncDataGridVehicleGroupSelection}
                                        contentRender={this.dataGridVehicleGroupRender}
                                        disabled={false}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                <div className="form-group">
                                    <label className="btn-block">&nbsp;</label>
                                    <button type="submit" className="btn btn-success btn-sm" onClick={this.handleOnSearchSubmit} >
                                        {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                        {!this.state.isSubmitted && <span title="Search"><i className="fas fa-search"></i> Search</span>}
                                    </button>&nbsp; &nbsp;
                                    <button type="button" className="btn btn-custom-gray btn-sm" onClick={this.handleClearSearch} title="Clear"><i className="fas fa-eraser"></i> Clear</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="card card-grid">
                            <div className="card-body">
                                <DataGrid
                                    dataSource={this.state.unclosedGriDatasource}
                                    ref={(ref) => { this.dataGrid = ref; }}
                                    keyExpr="id"
                                    columnsAutoWidth={true}
                                    showColumnLines={true}
                                    showRowLines={true}
                                    rowAlternationEnabled={false}
                                    showBorders={true}
                                    allowColumnResizing={true}
                                    columnResizingMode='widget'
                                    width='100%'
                                    onToolbarPreparing={this.handleOnToolbarPreparing}
                                    selectedRowKeys={this.state.gridRowDataKeys}
                                    onSelectionChanged={this.handleOnRowSelectionChanged}
                                >
                                    <Selection
                                        mode="multiple"
                                        allowSelectAll={true}
                                        selectAllMode="page" // or "allPages"
                                        showCheckBoxesMode="always"
                                    />
                                    <Column dataField="id" width={85} caption="" fixed={false} allowFiltering={false} allowSorting={false} cellRender={renderNewResQtCell} />
                                    <Column dataField="rideId" allowEditing={false} caption="Ride Id" alignment='center' width={'auto'} />
                                    <Column dataField="vehicleTypeDesc" caption="Charter" />
                                    <Column dataField="pickupTime" caption="Pickup Time" alignment='center' width={'auto'} format={DateFormat.DateTime24Format} dataType="datetime" />
                                    <Column dataField="flightInfo" caption="Flight#" />
                                    <Column dataField="vehicleNo" caption="Vehicle #" />
                                    <Column dataField="driverName" caption="Driver Name" width={'auto' } />
                                    <Column dataField="flightTime" caption="Time" />
                                    <Column dataField="PaxName" caption="Pax Name" />
                                    <Column dataField="pickup" caption="Pickup" />
                                    <Column dataField="dropoff" caption="Dropoff" />
                                    <Column dataField="noOfPax" caption="Pax" width={'auto'} alignment={'center'} />
                                    <Column dataField="wait" caption="Wait" width={'auto'} alignment={'center'} />
                                    <Column dataField="lead" caption="Lead" width={'auto'} alignment={'center'} />
                                    <Column dataField="bdo" caption="BDO" width={'auto'} alignment={'center'} />
                                    <Column dataField="vip" caption="VIP" width={'auto'} alignment={'center'} />
                                    <Column dataField="statusDesc" caption="Trip Status" />
                                    <Column dataField="tripRemark" caption="Trip Remark" />
                                    <SortByGroupSummaryInfo summaryItem="count" />
                                    <GroupPanel visible={false} />
                                    <HeaderFilter visible={false} />
                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                                    <Grouping autoExpandAll={false} />
                                    <Paging defaultPageSize={20} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50, 500]} showInfo={true} visible={true} />
                                    <Export enabled={false} allowExportSelectedData={false} />
                                    <ColumnChooser enabled={false} />
                                    <ColumnFixing enabled={false} />

                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </div>
                <ReservationHistoryComponent ref="reservationHistoryModal" />
                <CloseUndispatchRideComponent ref="closeUndispatchRideComponent" refreshCallingDatasource={this.handleOnRefreshGrid} />
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        )

    }
    dataGridVehicleGroupRender = () => {
        return (
            <DataGrid
                dataSource={this.state.vehicleGroupTypesList}
                id="grid-container"
                keyExpr="id"
                hoverStateEnabled={true}
                selectedRowKeys={this.state.vehicleGroupTypesDropdownData}
                onSelectionChanged={this.handleOnVehicleGroupDropdownChange}
                ref={ref => this.dataGrid = ref}>
                <Column dataField="name" caption="Vehcile Group" />
                <Selection mode="multiple" />
                <Scrolling mode="virtual" />
                <Paging enabled={false} pageSize={50} />
                <FilterRow visible={true} />
            </DataGrid>
        );
    }
}