import React, { Component } from 'react';
import { VehicleListComponent } from './VehicleListComponent';

let currentYear = (new Date()).getFullYear();
let maxYear = 10;
let minYear = -10
let allYears = [];
for (let i = minYear; i <= maxYear; i++) {
    allYears.push(currentYear - i)
}
const yearList = allYears.map((y) => { return (<option key={y}>{y}</option>) });

export class VehicleFormComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isBackToListView: false,
            isSubmited: false,
            dropdownVehicleType: [],
            vehicleId: 0,
            vehicleNumber: '',
            vehicleTypeId: 0,
            vehicleType: '',
            model: '',
            vinNo: '',
            makeYear: currentYear,
            plateNo: '',
            inService: true,
            serviceNote: '',
            isPrevent: true,
            preventNote: '',
            trackingSourceId: 0,
            trackingSource: '',
            trackingVehicleId: 0,
            trackingVehicle: '',
            seat: '',
            permitNo: '',
            ownerTypeId: 0,
            ownerType: '',
            errors: {
                vehicleNumber: '',
                vehicleType: '',
                model: '',
                vinNo: '',
                makeYear: '',
                plateNo: '',
                serviceNote: '',
                preventNote: '',
                trackingSource: '',
                trackingVehicle: '',
                permitNo: '',
                ownerType: ''
            }
        };
        this.getVehicleType = this.getVehicleType.bind(this);
    }
    componentDidMount() {
        this.getVehicleType();
        this.asignControlValueOnEdit();
    }
    handleBakToListView(e) {
        e.preventDefault();
        this.setState({
            isBackToListView: true
        });
        window.history.replaceState(null, null, '/vehicle/list');
    }
    handleCheckBoxChecked = (event) => {
        const target = event.target;
        const name = target.name;
        //this.setState((state, props) => {
        //    state.inService = !state.inService;
        //    return {
        //        inService: state.inService
        //    }
        //});
        this.setState((prevProps, prevState) => {
            return {
                [name]: !(name === "inService" ? prevProps.inService : prevProps.isPrevent)
            }
        });
    }
    isFormValid() {
        let errors = this.state.errors;
        let formIsValid = true;
        errors.vehicleNumber = "";
        errors.vehicleType = '';
        errors.model = "";
        errors.vinNo = "";
        errors.makeYear = "";
        errors.plateNo = "";
        errors.serviceNote = "";
        errors.preventNote = "";
        errors.trackingSource = '';
        errors.trackingVehicle = '';
        errors.permitNo = "";
        errors.ownerType = '';

        if (this.state.vehicleNumber === '') {
            formIsValid = false;
            errors.vehicleNumber = "Please enter vehicle no.";
        }
        if (Number(this.state.vehicleTypeId) === 0) {
            formIsValid = false;
            errors.vehicleType = "Please select vehicle type.";
        }
        if (this.state.model === '') {
            formIsValid = false;
            errors.model = "Please enter model.";
        }
        if (this.state.vinNo === '') {
            formIsValid = false;
            errors.vinNo = "Please enter VIN No.";
        }
        if (this.state.makeYear === '') {
            formIsValid = false;
            errors.makeYear = "Please select year.";
        }
        if (this.state.plateNo === '') {
            formIsValid = false;
            errors.plateNo = "Please enter Plate No.";
        }
        if (!this.state.inService) {
            if (this.state.serviceNote === '') {
                formIsValid = false;
                errors.serviceNote = "Please enter service note.";
            }
        }
        if (this.state.isPrevent) {
            if (this.state.preventNote === '') {
                formIsValid = false;
                errors.preventNote = "Please enter prevent note.";
            }
        }
        if (Number(this.state.trackingSourceId) === 0) {
            if (this.state.trackingSource === '') {
                formIsValid = false;
                errors.trackingSource = "Please select tracking source.";
            }
        }
        if (Number(this.state.trackingSourceId) !== 1001) {
            if (Number(this.state.trackingVehicleId) === 0) {
                formIsValid = false;
                errors.trackingVehicle = "Please select tracking vehicle.";
            }
        }
        if (this.state.permitNo === '') {
            formIsValid = false;
            errors.permitNo = "Please enter radio no.";
        }
        if (this.state.permitNo === '') {
            formIsValid = false;
            errors.permitNo = "Please enter permit no.";
        }
        if (Number(this.state.ownerTypeId) === 0) {
            formIsValid = false;
            errors.ownerType = "Please select owner type.";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    asignControlValueOnEdit() {
        if (this.props.isEdit && this.props.data !== null) {
            const vehicleData = this.props.data;
            this.setState({
                vehicleId: vehicleData.vehicleId,
                vehicleNumber: vehicleData.vehicleNumber,
                vehicleTypeId: vehicleData.vehicleTypeId,
                vehicleType: vehicleData.vehicleType,
                model: vehicleData.model,
                vinNo: vehicleData.vinNo,
                makeYear: vehicleData.makeYear,
                plateNo: vehicleData.plateNo,
                inService: vehicleData.inService,
                serviceNote: vehicleData.serviceNote,
                isPrevent: vehicleData.isPrevent,
                preventNote: vehicleData.preventNote,
                trackingSourceId: vehicleData.trackingSourceId,
                trackingSource: vehicleData.trackingSource,
                trackingVehicleId: vehicleData.trackingVehicleId,
                trackingVehicle: vehicleData.trackingVehicle,
                seat: vehicleData.seat,
                permitNo: vehicleData.permitNo,
                ownerTypeId: vehicleData.ownerTypeId,
                ownerType: vehicleData.ownerType,
            });
        } 
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.isFormValid()) {
            this.setState({ isSubmited: true });
            window.history.replaceState(null, null, '/vehicle/list');
        }
    }

    async getVehicleType() {
        var newObject = new Object();
        const vTypeParams = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(newObject)
        }
        try {
            const vTypeResponse = await fetch('/vehicle-api/vehicletype', vTypeParams);
            const vTypeData = await vTypeResponse.json();
            this.setState({
                dropdownVehicleType: vTypeData.apiVehicleType
            });
        } catch (e) {
        }
    }

    render() {
        if (this.state.isSubmited || this.state.isBackToListView) {
            return <VehicleListComponent />
        }
        return (
            <div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="card">
                            <div className="component-card-header">
                                <h4 className="m-0 text-dark"> {this.props.data === null ? 'New vehicle' : 'Update vehicle'}</h4>
                                <button type="button" className="btn btn-default" onClick={(e) => { this.handleBakToListView(e) }} >
                                    <a title = "Back"> <i className="fas fa-arrow-left"> Back</i></a>
                                </button>
                            </div>
                            <div className="card-body">
                                <div style={{ display: 'grid', justifyContent: 'center' }}>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="card card-primary">
                                                <div className="card-header">
                                                    <h5 className="card-title m-0">Vehicle Details</h5>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-4">
                                                            <div className="form-group">
                                                                <label>Vehicle No <span className="text-red">*</span></label>
                                                                <input type="text" className="form-control form-textbox form-control-sm" name="vehicleNumber" title="Vehicle No" placeholder="Vehicle No" value={this.state.vehicleNumber} onChange={(e) => { this.setState({ vehicleNumber: e.target.value }) }} />
                                                                {this.state.errors["vehicleNumber"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["vehicleNumber"]}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className="form-group">
                                                                <label>Vehicle Type <span className="text-red">*</span></label>
                                                                <select name="vehicleType" className="form-control form-control-sm" value={this.state.vehicleTypeId} onChange={(e) => { this.setState({ vehicleTypeId: e.target.value }) }}>
                                                                    <option value="0">---Select Vehicle Type---</option>
                                                                    {
                                                                        this.state.dropdownVehicleType &&
                                                                        this.state.dropdownVehicleType.map((item, i) =>
                                                                            (<option key={i} value={item.rowIndex}>{item.apiVehicleTypeDescription}</option>))
                                                                    }
                                                                </select>
                                                                {this.state.errors["vehicleType"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["vehicleType"]}</span>}

                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className="form-group">
                                                                <label>Model <span className="text-red">*</span></label>
                                                                <input type="text" className="form-control form-textbox form-control-sm" name="model" title="Model" placeholder="Model" value={this.state.model} onChange={(e) => { this.setState({ model: e.target.value }) }} />
                                                                {this.state.errors["model"].length > 0 && <span className="error invalid-feedback">{this.state.errors["model"]}</span>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-4">
                                                            <div className="form-group">
                                                                <label>VIN No <span className="text-red">*</span></label>
                                                                <input type="text" className="form-control form-textbox form-control-sm" name="vinNo" title="VIN No" placeholder="VIN No" value={this.state.vinNo} onChange={(e) => { this.setState({ vinNo: e.target.value }) }} />
                                                                {this.state.errors["vinNo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["vinNo"]}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className="form-group">
                                                                <label>Year <span className="text-red">*</span></label>
                                                                <select className="form-control form-control-sm" name="makeYear" title="Year" placeholder="Select Year" value={this.state.makeYear} onChange={(e) => { this.setState({ makeYear: e.target.value }) }}>
                                                                    {
                                                                        yearList
                                                                    }
                                                                </select>
                                                                {this.state.errors["makeYear"].length > 0 && <span className="error invalid-feedback">{this.state.errors["makeYear"]}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className="form-group">
                                                                <label>Plate No <span className="text-red">*</span></label>
                                                                <input type="text" className="form-control form-textbox form-control-sm" name="plateNo" title="Plate No" placeholder="Plate No" value={this.state.plateNo} onChange={(e) => { this.setState({ plateNo: e.target.value }) }} />
                                                                {this.state.errors["plateNo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["plateNo"]}</span>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ display: this.props.isEdit ? 'block' : 'none' }}>
                                                        <div className="row">
                                                            <div className="col-4">
                                                                <div className="form-group">
                                                                    <span className="form-check">
                                                                        <input className="form-check-input" type="checkbox" id="inService" name="inService" value={this.state.inService} checked={this.state.inService} onChange={(e) => this.handleCheckBoxChecked(e)} />
                                                                        <label className="form-check-label" htmlFor="checkbox">In Service</label>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="form-group">
                                                                    <label>Service Note <span className="text-red">*</span></label>
                                                                    <textarea type="textarea" className="form-control form-textbox form-control-sm" name="serviceNote" title="Service Note" placeholder="Service Note" disabled={this.state.inService} value={this.state.inService ? '' : this.state.serviceNote} onChange={(e) => this.handleInputChange(e)} />
                                                                    {this.state.errors["serviceNote"].length > 0 && <span className="error invalid-feedback">{this.state.errors["serviceNote"]}</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-4">
                                                                <div className="form-group">
                                                                    <span className="form-check">
                                                                        <input className="form-check-input" type="checkbox" id="isPrevent" name="isPrevent" value={this.state.isPrevent} checked={this.state.isPrevent} onChange={(e) => this.handleCheckBoxChecked(e)} />
                                                                        <label className="form-check-label">Prevent Access</label>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="form-group">
                                                                    <label>Prevent Note <span className="text-red">*</span></label>
                                                                    <textarea type="textarea" className="form-control form-textbox form-control-sm" name="preventNote" title="Prevent Note" placeholder="Prevent Access Note" disabled={!this.state.isPrevent} value={this.state.isPrevent ? this.state.preventNote : ''} onChange={(e) => this.handleInputChange(e)} />
                                                                    {this.state.errors["preventNote"].length > 0 && <span className="error invalid-feedback">{this.state.errors["preventNote"]}</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="card card-primary">
                                                <div className="card-header">
                                                    <h5 className="card-title m-0">Other Details</h5>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-4">
                                                            <div className="form-group">
                                                                <span>Seat </span>
                                                                <input type="text" className="form-control form-textbox form-control-sm" name="seat" title="Seat" placeholder="Seat" pattern="[0-9]*" maxLength="2" value={this.state.seat} onChange={(e) => { this.setState({ seat: (e.target.validity.valid) ? e.target.value : this.state.seat }) }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className="form-group">
                                                                <span>Permit No <span className="text-red">*</span></span>
                                                                <input type="text" className="form-control form-textbox form-control-sm" name="permitNo" title="Permit No" placeholder="Permit No" value={this.state.permitNo} onChange={(e) => { this.setState({ permitNo: e.target.value }) }} />
                                                                {this.state.errors["permitNo"].length > 0 && <span className="error invalid-feedback">{this.state.errors["permitNo"]}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className="form-group">
                                                                <span>Owner Type <span className="text-red">*</span></span>
                                                                <select className="form-control form-control-sm" name="ownerType" title="Owner Type" placeholder="Owner Type" value={this.state.ownerTypeId} onChange={(e) => { this.setState({ ownerTypeId: e.target.value }) }}>
                                                                    <option value="0">---Select Owner Type---</option>
                                                                </select>
                                                                {this.state.errors["ownerType"].length > 0 && <span className="error invalid-feedback">{this.state.errors["ownerType"]}</span>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="card card-primary">
                                                <div className="card-header">
                                                    <h5 className="card-title m-0">Tracking</h5>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <span>Tracking Source <span className="text-red">*</span></span>
                                                                <select className="form-control form-control-sm" name="trackingSource" placeholder="Tracking Source" value={this.state.trackingSourceId} onChange={(e) => { this.setState({ trackingSourceId: e.target.value }) }}>
                                                                    <option value="0">---Select Tracking Source---</option>
                                                                </select>
                                                                {this.state.errors["trackingSource"].length > 0 && <span className="error invalid-feedback">{this.state.errors["trackingSource"]}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <span>Source Vehicle <span className="text-red">*</span></span>
                                                                <select className="form-control form-control-sm" name="trackingVehicle" placeholder="Tracking Vehicle" value={this.state.trackingVehicleId} onChange={(e) => { this.setState({ trackingVehicleId: e.target.value }) }}>
                                                                    <option value="0">---Select Tracking Vehicle---</option>
                                                                </select>
                                                                {this.state.errors["trackingVehicle"].length > 0 && <span className="error invalid-feedback">{this.state.errors["trackingVehicle"]}</span>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={(e) => this.handleSubmit(e)}><i className="fas fa-save"></i> {this.props.data === null ? 'Save' : 'Update'}</button>
                                            <button disabled={this.props.isEdit} type="button" className="btn btn-danger btn-sm"><i className="fas fa-eraser"></i> Clear</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

